import React from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import { ChevronRightIcon, ChevronDownIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
 import { BsFillBoxSeamFill } from "react-icons/bs";
 import { RiAddBoxFill } from "react-icons/ri";
 import { LuTruck } from "react-icons/lu";
 import { HiOutlineLocationMarker } from "react-icons/hi"
 import { PiCalculatorBold } from "react-icons/pi";
 import { FaWarehouse,FaTicket } from "react-icons/fa6";
import { SiMinutemailer } from "react-icons/si";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { MdSwitchAccount } from "react-icons/md";
import { FaPen } from "react-icons/fa6";
import { AiOutlineException } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userActions";
export function Sidebar() {
  const [open, setOpen] = React.useState(0);
  const { error, loading, isAuthenticated,users,userProfile } = useSelector(
    (state) => state.user
  );
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  const dispatch = useDispatch()
  const handleLogout = () =>{
      dispatch(logout())
  }
 
  return (
    <Card className="h-[calc(100vh-3rem)] hidden lg:block col-span-1 overflow-y-scroll overflow-x-hidden w-full max-w-[18rem] p-1 rounded-none shadow-xl shadow-blue-gray-900/5">
      <h4 className="mt-2 font-[GilroyBold] ml-4 text-[1.3rem]" >My Orders</h4>
      <List>
      <Link to="/orders" >
        <ListItem className=" py-3 font-[GilroyMedium]" >
          <ListItemPrefix>
            <BsFillBoxSeamFill className="h-[20px] w-[20px] mr-3" />
          </ListItemPrefix>
        <p className="text-[1.1rem]" >  Order List</p>
          
        </ListItem>
        </Link>
      {userProfile?.role==="admin" &&  <Link to="/create-order" >
       <ListItem className="py-3 font-[GilroyMedium]" >
          <ListItemPrefix>
            <RiAddBoxFill className="h-[20px] w-[20px] mr-3" />
          </ListItemPrefix>
        <p className="text-[1.1rem]" >Create Order</p>
          
        </ListItem>
        </Link>}
        <Link to="/request-pickup" >
          <ListItem className="py-3 font-[GilroyMedium]" >
          <ListItemPrefix>
            <LuTruck className="h-[20px] w-[20px] mr-3" />
          </ListItemPrefix>
        <p className="text-[1.1rem]" >Request Pickup</p>
          
        </ListItem>
        </Link>
      </List>
       <h4 className="mt-8 font-[GilroyBold] ml-4 text-[1.3rem]" >Services</h4>
       <List className="mt-1" >
       <Link to="/servicibility">
        <ListItem className=" py-3 font-[GilroyMedium]" >
          <ListItemPrefix>
            <HiOutlineLocationMarker className="h-[20px] w-[20px] mr-3" />
          </ListItemPrefix>
        <p className="text-[1.1rem]" > Check Servicibility</p>
          
        </ListItem>
        </Link>
        <Link to="/rate-calculator">
       <ListItem className="py-3 font-[GilroyMedium]" >
          <ListItemPrefix>
            <PiCalculatorBold className="h-[20px] w-[20px] mr-3" />
          </ListItemPrefix>
        <p className="text-[1.1rem]" >Rate Calculator</p>
          
        </ListItem>
        </Link>
        {userProfile?.role==="admin" &&  <Link to="/create-invoice" >
       <ListItem className="py-3 font-[GilroyMedium]" >
          <ListItemPrefix>
            <RiAddBoxFill className="h-[20px] w-[20px] mr-3" />
          </ListItemPrefix>
        <p className="text-[1.1rem]" >Create Invoice</p>
          
        </ListItem>
        </Link>}
        {userProfile?.role==="admin" &&  <Link to="/warehouse" >
         <ListItem className="py-3 font-[GilroyMedium]" >
          <ListItemPrefix>
            <FaWarehouse className="h-[20px] w-[20px] mr-3" />
          </ListItemPrefix>
        <p className="text-[1.1rem]" >Manage Warehouse</p>
          
        </ListItem>
        </Link> }
      </List>
       <h4 className="mt-8 font-[GilroyBold] ml-4 text-[1.3rem]" >Help & Support</h4>
       <List className="mt-1" >
        <ListItem className=" py-3 font-[GilroyMedium]" >
          <ListItemPrefix>
            <FaTicket className="h-[20px] w-[20px] mr-3" />
          </ListItemPrefix>
        <p className="text-[1.1rem]" >Raise a Ticket</p>
          
        </ListItem>
       <ListItem className="py-3 font-[GilroyMedium]" >
          <ListItemPrefix>
            <SiMinutemailer className="h-[20px] w-[20px] mr-3" />
          </ListItemPrefix>
        <p className="text-[1.1rem]" >Contact Us</p>
          
        </ListItem>
         <ListItem className="py-3 font-[GilroyMedium]" >
          <ListItemPrefix>
            <BsFillQuestionCircleFill className="h-[20px] w-[20px] mr-3" />
          </ListItemPrefix>
        <p className="text-[1.1rem]" >Faqs</p>
          
        </ListItem>
       
      </List>
  {userProfile?.role==="admin" &&  <>
      <h4 className="mt-8 font-[GilroyBold] ml-4 text-[1.3rem]" >User</h4>
       <List className="mt-1" >
       <Link to="/create-user">
        <ListItem className=" py-3 font-[GilroyMedium]" >
          <ListItemPrefix>
            <HiOutlineLocationMarker className="h-[20px] w-[20px] mr-3" />
          </ListItemPrefix>
        <p className="text-[1.1rem]" > Create User</p>
          
        </ListItem>
        </Link>
        <Link to="/create-staff">
        <ListItem className=" py-3 font-[GilroyMedium]" >
          <ListItemPrefix>
            <HiOutlineLocationMarker className="h-[20px] w-[20px] mr-3" />
          </ListItemPrefix>
        <p className="text-[1.1rem]" > Create Staff</p>
          
        </ListItem>
        </Link>
        <Link to="/users">
       <ListItem className="py-3 font-[GilroyMedium]" >
          <ListItemPrefix>
            <PiCalculatorBold className="h-[20px] w-[20px] mr-3" />
          </ListItemPrefix>
        <p className="text-[1.1rem]" >User List</p>
          
        </ListItem>
        </Link>
      
      </List>
        </>}

       <h4 className="mt-8 font-[GilroyBold] ml-4 text-[1.3rem]" >Profile</h4>
       <List className="mt-1 mb-6" >
        <ListItem className=" py-3 font-[GilroyMedium]" >
          <ListItemPrefix>
            <MdSwitchAccount className="h-[20px] w-[20px] mr-3" />
          </ListItemPrefix>
        <p className="text-[1.1rem]" >Account Details</p>
          
        </ListItem>
    <ListItem onClick={handleLogout} className="py-3 font-[GilroyMedium]" >
          <ListItemPrefix>
            <FaPen className="h-[20px] w-[20px] mr-3" />
          </ListItemPrefix>
        <p className="text-[1.1rem]" >Logout</p>
          
        </ListItem>
 
       
      </List>
    </Card>
  );
}