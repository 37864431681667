import { Button, Input } from '@material-tailwind/react'
import React from 'react'
import { InformationCircleIcon, EyeIcon,EyeSlashIcon, PhoneIcon } from '@heroicons/react/24/outline';
const LoginForm = ({password,setPassword,email,setEmail,showPassword,setShowPassword,handleLogin}) => {
  return (
    <div className='bg-white rounded-lg py-8 px-12' >
        <div>
            <h3 className='font-[GilroyBold] text-[1.2rem]' >Login using Email and Password</h3>
            <div className='my-4' >
                <Input value={email} onChange={(e)=>setEmail(e.target.value)} label='Email' />

            </div>
           
            <div className="relative flex w-full ">
      <Input
        
        label="Password"
        value={password} 
        
        onChange={(e)=>{setPassword(e.target.value)}}
        type={showPassword ? "text" : "password"}
        className="pr-20"
        containerProps={{
          className: "min-w-0",
        }}
      />
      <button
        
       
        disabled={!password}
        onClick={()=>setShowPassword(!showPassword)}
        className="!absolute  right-2 top-3 "
      >
        {showPassword ? <EyeIcon className='w-[15px]  text-blue-500' /> :<EyeSlashIcon className='w-[15px] text-blue-500' />}
      </button>
      
    </div>
            <div>
                <Button onClick={handleLogin} className='w-full mt-4' >Login</Button>
            </div>
        </div>
    </div>
  )
}

export default LoginForm