import React, { useEffect, useState } from 'react'
import { Sidebar } from '../components/Layout/Sidebar'
import ConfirmationModal from '../components/CreateOrder/ConfirmationModal'
import Topbar from '../components/Layout/Topbar'
import { addDoc, collection, doc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore'
import { db, storage } from '../firebase.config'
import { uploadBytesResumable, ref as storageRef, getDownloadURL } from 'firebase/storage'
import {pinCodeList} from "../data/pinCodeData"
import { locationPricing } from '../data/LocationPricing'
import { useDispatch, useSelector } from 'react-redux'
import { saveOrder } from '../actions/orderAction'
import { useNavigate, useParams } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import EditOrderPage from '../components/EditOrder/EditOrderPage'
const EditOrder = () => {
  const [step, setStep] = useState(1)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {id} = useParams()
  const {error, loading  } = useSelector(
    (state)=>state.order
  )
  const {isAuthenticated,users,userProfile  } = useSelector(
    (state)=>state.user
  )
  const [confirmModal, setConfirmModal] = useState(false)
  const [invoiceList, setInvoiceList] = useState([])
  const [warehouseList, setWarehouseList] = useState([])
  const [pickupLocation, setPickupLocation] = useState({})
  const [salesInsuranceCharge, setSalesInsuranceCharge] = useState(0)
  const [user, setUser] = useState({})
  const [dimension, setDimension] = useState([])
  const [lrDetails, setLrDetails] = useState("manual")
  const [lrNumber, setLrNumber] = useState("")
  const [orderID, setOrderID] = useState("")
 
  const [pickupZone, setPickupZone] = useState("")
  const [deliveryZone, setDeliveryZone] = useState("")
  const [baseAmount, setBaseAmount] = useState(0)
  const [userList, setUserList] = useState([])
  const [salesCharge, setSalesCharge] = useState(0)
  const [dimentionData, setDimentionData] = useState({
    name:"",
    qty:"",
    length:"",
    width:"",
    height:""
  })
  const [volumetricWeight, setVolumetricWeight] = useState(0)
  const handleConfirmModal = ()=>{
    setConfirmModal(!confirmModal)
  }
  const [subOrders, setSubOrders] = useState([])
  const [subOrder, setSubOrder] = useState({
    ident:"",
    count:"",
    description:"",
   
    
  })
  const [dropLocation, setDropLocation] = useState({
    address:"",
    zip:"",
    consignee:"",
    city:"",
    region:"",
    phone:""
  })
  const [mode, setMode] = useState("")
  const [invoiceDetail, setInvoiceDetail] = useState({
    ident:"",
    n_value:0,
    ewaybill:""
  })
  const [amount, setAmount] = useState(null)
  const [purchaseFuel, setPurchaseFuel] = useState(0)
  const [totalHeight, setTotalHeight] = useState(0)
  const [totalWidth, setTotalWidth] = useState(0)
  const [totalLength, setTotalLength] = useState(0)
  const [weight, setWeight] = useState(null)
  const [consigneeGST, setConsigneeGST] = useState("")
  const [salesTotalCharge, setSalesTotalCharge] = useState(0)
  const [sellerGST, setSellerGST] = useState("")
  const [invoiceDocument, setInvoiceDocument] = useState("")
  const [supportDocument, setSupportDocument] = useState("")
  const handleDimensionData = ()=>{
    setDimension([...dimension,{
      name:dimentionData.name,
      qty:Number(dimentionData.qty),
      length:Number(dimentionData.length),
      width:Number(dimentionData.width),
      height:Number(dimentionData.height)
    }])
    setSubOrders([
      ...subOrders,{
        ident:"GRC"+Number(dimension.length)+1,
        count:Number(dimentionData.qty),
        description:dimentionData.name,
      }
    ])
    setDimentionData({
    name:"",
    qty:"",
    length:"",
    width:"",
    height:""
    })
  }
  const removeDimesion = (name) =>{
    setDimension(
      dimension.filter((item) => item.name!==name)
    )
  }
  const handleShippingDetails = () => {
    if(weight === null){
      alert("Enter Total Weight")
    }
    else if(orderID.length === 0){
      alert("Please enter Order ID")
    }
    else if(dimension.length === 0){
      alert("Please enter Dimension")
    }
   
    else{
      if(Object.keys(pickupLocation).length !== 0){
        pinCodeList.map((zip)=>{
          if(zip.Pin === Number(pickupLocation.pinCode)){
            setPickupZone(zip.Zone)
          }
        })
       }
       if(dimension.length!==0){
        let Length = 0
        let Width = 0
        let Height = 0
        dimension.map((item)=>{
         Length = Length + (Number(item.length)*item.qty)
          Width = Width + (Number(item.width)*item.qty)
          Height = Height + (Number(item.height)*item.qty)
        })
        setTotalHeight(Height)
        setTotalWidth(Width)
        setTotalLength(Length)
       }
      setStep(2)
    }
  }
  const addInvoice = (e) =>{
    e.preventDefault()
const file = e.target[0]?.files[0]
if (!file) return;
const storeRef = storageRef(storage, `invoice/${file.name}`);
const uploadTask = uploadBytesResumable(storeRef, file);

uploadTask.on("state_changed",
 (snapshot) => {
   
 },
 (error) => {
   alert(error);
 },
 () => {
   getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    setInvoiceDocument(downloadURL)
     console.log(downloadURL)
   });
 }
);
}
const addSupport = (e) =>{
  e.preventDefault()
const file = e.target[0]?.files[0]
if (!file) return;
const storeRef = storageRef(storage, `invoice/${file.name}`);
const uploadTask = uploadBytesResumable(storeRef, file);

uploadTask.on("state_changed",
(snapshot) => {
 
},
(error) => {
 alert(error);
},
() => {
 getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  setSupportDocument(downloadURL)
   console.log(downloadURL)
 });
}
);
}
  const handleDeliveryInformation = () =>{
    if(dropLocation.consignee.length===0 || dropLocation.address.length===0 || dropLocation.zip.length===0 || dropLocation.region.length===0 || dropLocation.city.length===0 || dropLocation.phone.length===0) {
      toast.error("Please enter All Delivery Details")
    }
    else{
      pinCodeList.map((zip)=>{
        if(zip.Pin === Number(dropLocation.zip)){
          setDeliveryZone(zip.Zone)
        }
      })
      setStep(3)
     }
  }
  const handleBookOrder = () =>{
   if(invoiceDetail?.collectionType==="COD" && invoiceDetail?.amountCollection?.length===0){
    toast.error("Please add Amount to Collect ")
   }
   else if(invoiceDetail?.amount>=50000 && invoiceDetail?.ewaybill?.length===0 && invoiceDetail?.invoiceNo?.length===0){
    toast.error("Please Add Invoice Details ")
   }
   else{

   
    if(pickupZone.length!==0 && deliveryZone.length!==0){
      handleConfirmModal()
      locationPricing.map((location)=>{
        if(location.locationOne===pickupZone && location.locationTwo===deliveryZone){
          setBaseAmount(location.charge)
        }
      })
      user?.rateList?.map((item)=>{
      if(item?.deliveryLocation  === Number(dropLocation.zip) && item?.fromLocation === Number(pickupLocation.pinCode) ){
          setSalesCharge((((totalHeight*totalWidth*totalLength)/4500)*item?.rate)+((((totalHeight*totalWidth*totalLength)/4500)*item?.rate)*(item?.fuel/100))+item?.lrCharge+item?.fmCost+item?.greenTax+item?.handling+item?.oda)
          if(user?.insuranceType==="owner risk"){
            setSalesInsuranceCharge(item?.rovOWER)
          }
          else{
            const rateCarrier = (((totalHeight*totalWidth*totalLength)/4500)*item?.rate)*(item?.fuel/100)
            if(rateCarrier > item?.minRovValue){
              setSalesInsuranceCharge(Math.round(rateCarrier))
            }
            else{
              setSalesInsuranceCharge(item?.minRovValue)
            }
          }
        }
    })
     }
    }
    
   
  }
 const  handleConfirmOrder = () =>{
  setVolumetricWeight((totalHeight*totalWidth*totalLength)/4500)
  setAmount(((totalHeight*totalWidth*totalLength)/4500)*baseAmount)
  setPurchaseFuel((((totalHeight*totalWidth*totalLength)/4500)*baseAmount)*(20/100))
  setSalesTotalCharge(salesCharge+salesInsuranceCharge)
  const date = new Date()
  if(amount!==null){
   dispatch(saveOrder({
    ident: "",
    createdAt:date,
      pickup_location: pickupLocation,
      dropoff_location: dropLocation,
      return_address: dropLocation,
      d_mode:"Prepaid",
      purchaseCharge: amount + Number(purchaseFuel),
      salesCharge:salesTotalCharge,
      orderStatus:"new",
      payment_mode: "Prepaid",
      rov_insurance: user?.insuranceType,
      invoices: invoiceList,
      weight: weight,
      suborders: subOrders,
      dimensions: dimension,
      consignee_gst_tin: consigneeGST,
      seller_gst_tin: sellerGST,
      cb: {},
      orderID: orderID,
      lrno: lrNumber,
      totalLength: totalLength,
      totalWidth: totalWidth,
      totalHeight: totalHeight,
      masterAWB: "",
      uid:user?.uid,
      userName:user?.name
   },orderID))
   if(!error){
    const repairRef = doc(db, "users",user?.uid);
    updateDoc(repairRef, {
      billAmount:userProfile?.billAmount ? userProfile?.billAmount : 0 + salesTotalCharge,
      });
    navigate("/success")
   }
  }
  }
  
  const fetchWarehouse = async () =>{
   
 
      const q = query(collection(db, "warehouse"),where("userUid","==",user.uid)) 
      const querySnapshot = await getDocs(q);
       querySnapshot.forEach((doc) => {
        setWarehouseList((prev)=>[...prev,{
          id:doc.id,
          ...doc.data()
         }])
        
       });
    
 
  }
  console.log(user,warehouseList)
  const fetchUsers = async () =>{
    const q = query(collection(db, "users"), where("service","==","logistics")) 
      const querySnapshot = await getDocs(q);
       querySnapshot.forEach((doc) => {
        setUserList((prev)=>[...prev,{
            id:doc.id,
            ...doc.data()
           }])
        
       });
  }
  console.log(pickupZone,deliveryZone,baseAmount,amount)
  console.log(totalHeight,totalLength,totalWidth,volumetricWeight)
  const getUserFroID = async (id) =>{
    onSnapshot(doc(db, "users", id), (doc) => {
      const data = doc.data()
      setUser(data)
    })
  }
  const fetchOrderDetail = () =>{
  
    onSnapshot(doc(db, "logisticOrder", id), (doc) => {
      const data = doc.data()
    setPickupLocation(data?.pickup_location)
   setDropLocation(data?.dropoff_location)
 
   setLrNumber(data?.lrno)
   setOrderID(data?.orderID)
   setWeight(Number(data?.weight))
   setSubOrder(data?.suborders)
   getUserFroID(data?.uid)
      });
  }
  useEffect(() => {
    if(Object.keys(user).length !== 0){
      fetchWarehouse()
    }
    fetchOrderDetail()
    fetchUsers()
  } , [user,id]);
  console.log(salesCharge)
  return (
    <div>
    <Toaster />
        <Topbar />
        <ConfirmationModal confirmModal={confirmModal} handleBookOrder={handleConfirmOrder} handleConfirmModal={handleConfirmModal} />
        <div className='grid grid-cols-5 bg-gray-100 grid-flow-col' >
            <Sidebar />
        <EditOrderPage   invoiceList={invoiceList}
          setInvoiceList={setInvoiceList}
          userList={userList}
          setUserList={setUserList}
          user={user}
          setUser={setUser}
          handleBookOrder={handleBookOrder}
          handleDeliveryInformation={handleDeliveryInformation}
          addInvoice={addInvoice}
          addSupport={addSupport}
          supportDocument={supportDocument}
          invoiceDocument={invoiceDocument}
          invoiceDetail={invoiceDetail}
          setInvoiceDetail={setInvoiceDetail}
          warehouseList={warehouseList}
          pickupLocation={pickupLocation}
          setPickupLocation={setPickupLocation}
          handleShippingDetails={handleShippingDetails}
          removeDimesion={removeDimesion}
          handleDimensionData={handleDimensionData}
           dimentionData={dimentionData}
           setDimentionData={setDimentionData}
           setDimension={setDimension}
            dimension={dimension} 
            subOrders={subOrders}
            setSubOrders={setSubOrders}
            dropLocation={dropLocation}
            setDropLocation={setDropLocation}
            mode={mode}
            setMode={setMode}
            amount={amount}
            setAmount={setAmount}
            weight={weight}
            setWeight={setWeight}
            consigneeGST={consigneeGST}
            setConsigneeGST={setConsigneeGST}
            sellerGST={sellerGST}
            setSellerGST={setSellerGST}
        
            lrNumber={lrNumber}
            setLrNumber={setLrNumber}
            orderID={orderID}
            setOrderID={setOrderID}
            lrDetails={lrDetails}
            setLrDetails={setLrDetails}
            step={step} setStep={setStep}  />
        </div>
    </div>
  )
}

export default EditOrder