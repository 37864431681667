import { TrashIcon } from '@heroicons/react/24/outline'
import { Button } from '@material-tailwind/react'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const OrderTable = ({activeTab,orderList,handlePartnerModal,setOrderID,filteredOrderList,deleteOrder}) => {
  const {isAuthenticated,users,userProfile  } = useSelector(
    (state)=>state.user
  )
  return (
    <>
   <div  >
        <div className={`bg-gray-300  py-4 px-8 grid  gap-6 grid-flow-row  items-center justify-start grid-cols-2 lg:grid-cols-8  `}>
            <p className='col-span-1 font-[GilroyMedium]' >Lr No</p>
            <p className='col-span-1 font-[GilroyMedium]' >Created Date</p>
            {/* <p className='col-span-1 font-[GilroyMedium]' >Order ID</p> */}
            <p className='col-span-1 font-[GilroyMedium]' >Client</p>
            <p className='col-span-1 font-[GilroyMedium]' >Delivery</p>
            <p className='col-span-1 font-[GilroyMedium]' >Origin</p>
            <p className='col-span-1 font-[GilroyMedium]' >Destination</p>
           
            <p className='col-span-1 font-[GilroyMedium]' >Order Status</p>
            <p className='col-span-1 font-[GilroyMedium]' >Action</p>
        </div>
       
        {
            filteredOrderList?.map((item) =>(
                <div className={`bg-white border-b-[1px] border-gray-300 py-4 px-8 grid  gap-6 grid-flow-row grid-cols-2  items-center justify-start lg:grid-cols-8 `}>
          <Link to={`/orderDetails/${item?.lrno}`} > <p className='col-span-1 underline hover:text-blue-400 font-[GilroyMedium]' >{item.lrno}</p></Link> 
            {/* <p className='col-span-1 text-[0.8rem] font-[GilroyMedium]' >{item.orderID}</p> */}
            <div className='col-span-1 ' >
            <p className='text-[0.8rem] font-[GilroyMedium]' >{item?.createdAt?.toDate().toDateString()}</p>
            <p className='text-[0.8rem] font-[GilroyMedium]' >{item?.createdAt?.toDate().toLocaleTimeString()}</p>
            </div>
            <p className='col-span-1 text-[0.8rem] font-[GilroyMedium]' >{item?.userName}</p>
            <p className='col-span-1 text-[0.8rem] font-[GilroyMedium]' >
              <span>{item?.dropoff_location?.companyName}</span>
              <br/>
              <span className='text-[0.6rem]' >{item?.dropoff_location?.address}</span>

            </p>
            <p className='col-span-1 text-[0.8rem] font-[GilroyMedium]' >{item?.pickup_location?.pinCode}</p>
            <p className='col-span-1 text-[0.8rem] font-[GilroyMedium]' >{item?.dropoff_location?.zip}</p>
           
            <p className='col-span-1 text-[0.8rem] font-[GilroyMedium]' >{item?.orderStatus}</p>
            <div className='flex items-center justify-center ' >
           {item?.orderStatus === "new" && <Button  onClick={()=>{
            setOrderID(item?.id)
                handlePartnerModal()
            }} className='col-span-1 text-[.7rem] font-[GilroyMedium]' >Ship Now</Button>}
             {userProfile?.role === "admin" && <div  onClick={()=>{
           deleteOrder(item?.id)
            }} className='col-span-1 text-red-600 cursor-pointer w-[1.4rem] mx-2 font-[GilroyMedium]' ><TrashIcon /></div>}
              {/* {item?.orderStatus === "pickedUp" && <Button  onClick={()=>{
            setOrderID(item?.id)
                handlePartnerModal()
            }} className='col-span-1 text-[.7rem] font-[GilroyMedium]' >View Invoice</Button>} */}
             {item?.orderStatus === "delivered" && <a className='bg-black text-[0.7rem] py-2 px-4 font-[GilroyMedium] rounded-lg text-white' href={item?.pod} download>View POD</a>}
            </div>
        </div>
            ))
        }
        
    </div>
   
    </>
  )
}

export default OrderTable