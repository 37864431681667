import React from 'react'

const Notes = () => {
  return (
    <div>
          <section className='mb-5' >
            <p>Notes to client...</p>
        </section>
    </div>
  )
}

export default Notes