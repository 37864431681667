import React from 'react'
import TopArea from './TopArea'
import WarehouseTable from './WarehouseTable'

const WarehouseArea = ({handleWarehouseModal,warehouseList,deleteWarehouse}) => {
  return (
    <div className='col-span-4 mx-4 bg-gray-100' >
        <TopArea handleWarehouseModal={handleWarehouseModal} />
        <WarehouseTable deleteWarehouse={deleteWarehouse} warehouseList={warehouseList} handleWarehouseModal={handleWarehouseModal} />
    </div>
  )
}

export default WarehouseArea