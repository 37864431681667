export const locationPricing = [
    {
    locationOne:"N1",
    locationTwo:"N1",
    charge:7,

    },
    {
        locationOne:"N1",
        locationTwo:"N2",
        charge:8,
    
    },
    {
        locationOne:"N1",
        locationTwo:"E",
        charge:14,
    
    },
    {
        locationOne:"N1",
        locationTwo:"NE",
        charge:18,
    
    },
    {
        locationOne:"N1",
        locationTwo:"W1",
        charge:10,
    
    },
    {
        locationOne:"N1",
        locationTwo:"W2",
        charge:12,
    
    },
    {
        locationOne:"N1",
        locationTwo:"S1",
        charge:13,
    
    },
    {
        locationOne:"N1",
        locationTwo:"S2",
        charge:14,
    
    },
    {
        locationOne:"N1",
        locationTwo:"Central",
        charge:10,
    
    },
    {
        locationOne:"N2",
        locationTwo:"N1",
        charge:8,
    
        },
        {
            locationOne:"N2",
            locationTwo:"N2",
            charge:7,
        
        },
        {
            locationOne:"N2",
            locationTwo:"E",
            charge:15,
        
        },
        {
            locationOne:"N2",
            locationTwo:"NE",
            charge:21,
        
        },
        {
            locationOne:"N2",
            locationTwo:"W1",
            charge:12,
        
        },
        {
            locationOne:"N2",
            locationTwo:"W2",
            charge:12,
        
        },
        {
            locationOne:"N2",
            locationTwo:"S1",
            charge:15,
        
        },
        {
            locationOne:"N2",
            locationTwo:"S2",
            charge:15,
        
        },
        {
            locationOne:"N2",
            locationTwo:"Central",
            charge:12,
        
        },

        {
            locationOne:"E",
            locationTwo:"N1",
            charge:12,
        
            },
            {
                locationOne:"E",
                locationTwo:"N2",
                charge:14,
            
            },
            {
                locationOne:"E",
                locationTwo:"E",
                charge:8,
            
            },
            {
                locationOne:"E",
                locationTwo:"NE",
                charge:12,
            
            },
            {
                locationOne:"E",
                locationTwo:"W1",
                charge:12,
            
            },
            {
                locationOne:"E",
                locationTwo:"W2",
                charge:12,
            
            },
            {
                locationOne:"E",
                locationTwo:"S1",
                charge:12,
            
            },
            {
                locationOne:"E",
                locationTwo:"S2",
                charge:14,
            
            },
            {
                locationOne:"E",
                locationTwo:"Central",
                charge:10,
            
            },

        {
                locationOne:"NE",
                locationTwo:"N1",
                charge:12,
            
            },
                {
                    locationOne:"NE",
                    locationTwo:"N2",
                    charge:14,
                
                },
                {
                    locationOne:"NE",
                    locationTwo:"E",
                    charge:10,
                
                },
                {
                    locationOne:"NE",
                    locationTwo:"NE",
                    charge:8,
                
                },
                {
                    locationOne:"NE",
                    locationTwo:"W1",
                    charge:14,
                
                },
                {
                    locationOne:"NE",
                    locationTwo:"W2",
                    charge:14,
                
                },
                {
                    locationOne:"NE",
                    locationTwo:"S1",
                    charge:14,
                
                },
                {
                    locationOne:"NE",
                    locationTwo:"S2",
                    charge:16,
                
                },
                {
                    locationOne:"NE",
                    locationTwo:"Central",
                    charge:12,
                
                }, 


                {
                    locationOne:"W1",
                    locationTwo:"N1",
                    charge:11,
                
                },
                    {
                        locationOne:"W1",
                        locationTwo:"N2",
                        charge:12,
                    
                    },
                    {
                        locationOne:"W1",
                        locationTwo:"E",
                        charge:18,
                    
                    },
                    {
                        locationOne:"W1",
                        locationTwo:"NE",
                        charge:22,
                    
                    },
                    {
                        locationOne:"W1",
                        locationTwo:"W1",
                        charge:8,
                    
                    },
                    {
                        locationOne:"W1",
                        locationTwo:"W2",
                        charge:8,
                    
                    },
                    {
                        locationOne:"W1",
                        locationTwo:"S1",
                        charge:12,
                    
                    },
                    {
                        locationOne:"W1",
                        locationTwo:"S2",
                        charge:14,
                    
                    },
                    {
                        locationOne:"W1",
                        locationTwo:"Central",
                        charge:11,
                    
                    },  

                    {
                        locationOne:"W2",
                        locationTwo:"N1",
                        charge:12,
                    
                    },
                        {
                            locationOne:"W2",
                            locationTwo:"N2",
                            charge:13,
                        
                        },
                        {
                            locationOne:"W2",
                            locationTwo:"E",
                            charge:18,
                        
                        },
                        {
                            locationOne:"W2",
                            locationTwo:"NE",
                            charge:22,
                        
                        },
                        {
                            locationOne:"W2",
                            locationTwo:"W1",
                            charge:8,
                        
                        },
                        {
                            locationOne:"W2",
                            locationTwo:"W2",
                            charge:7,
                        
                        },
                        {
                            locationOne:"W2",
                            locationTwo:"S1",
                            charge:10,
                        
                        },
                        {
                            locationOne:"W2",
                            locationTwo:"S2",
                            charge:14,
                        
                        },
                        {
                            locationOne:"W1",
                            locationTwo:"Central",
                            charge:10,
                        
                        }, 

                        {
                            locationOne:"S1",
                            locationTwo:"N1",
                            charge:12,
                        
                        },
                            {
                                locationOne:"S1",
                                locationTwo:"N2",
                                charge:15,
                            
                            },
                            {
                                locationOne:"S1",
                                locationTwo:"E",
                                charge:15,
                            
                            },
                            {
                                locationOne:"S1",
                                locationTwo:"NE",
                                charge:20,
                            
                            },
                            {
                                locationOne:"S1",
                                locationTwo:"W1",
                                charge:12,
                            
                            },
                            {
                                locationOne:"S1",
                                locationTwo:"W2",
                                charge:10,
                            
                            },
                            {
                                locationOne:"S1",
                                locationTwo:"S1",
                                charge:8,
                            
                            },
                            {
                                locationOne:"S1",
                                locationTwo:"S2",
                                charge:10,
                            
                            },
                            {
                                locationOne:"S1",
                                locationTwo:"Central",
                                charge:10,
                            
                            },

                            {
                                locationOne:"S2",
                                locationTwo:"N1",
                                charge:15,
                            
                            },
                                {
                                    locationOne:"S2",
                                    locationTwo:"N2",
                                    charge:15,
                                
                                },
                                {
                                    locationOne:"S2",
                                    locationTwo:"E",
                                    charge:15,
                                
                                },
                                {
                                    locationOne:"S2",
                                    locationTwo:"NE",
                                    charge:20,
                                
                                },
                                {
                                    locationOne:"S2",
                                    locationTwo:"W1",
                                    charge:12,
                                
                                },
                                {
                                    locationOne:"S2",
                                    locationTwo:"W2",
                                    charge:12,
                                
                                },
                                {
                                    locationOne:"S2",
                                    locationTwo:"S1",
                                    charge:8,
                                
                                },
                                {
                                    locationOne:"S2",
                                    locationTwo:"S2",
                                    charge:8,
                                
                                },
                                {
                                    locationOne:"S2",
                                    locationTwo:"Central",
                                    charge:10,
                                
                                },
                                
                                {
                                    locationOne:"Central",
                                    locationTwo:"N1",
                                    charge:11,
                                
                                },
                                    {
                                        locationOne:"Central",
                                        locationTwo:"N2",
                                        charge:12,
                                    
                                    },
                                    {
                                        locationOne:"Central",
                                        locationTwo:"E",
                                        charge:12,
                                    
                                    },
                                    {
                                        locationOne:"Central",
                                        locationTwo:"NE",
                                        charge:16,
                                    
                                    },
                                    {
                                        locationOne:"Central",
                                        locationTwo:"W1",
                                        charge:9,
                                    
                                    },
                                    {
                                        locationOne:"Central",
                                        locationTwo:"W2",
                                        charge:10,
                                    
                                    },
                                    {
                                        locationOne:"Central",
                                        locationTwo:"S1",
                                        charge:10,
                                    
                                    },
                                    {
                                        locationOne:"Central",
                                        locationTwo:"S2",
                                        charge:12,
                                    
                                    },
                                    {
                                        locationOne:"Central",
                                        locationTwo:"Central",
                                        charge:8
                                    
                                    },  
]