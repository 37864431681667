import React, { useEffect, useState } from 'react'
import Topbar from "../components/Layout/Topbar"
import DeliveryListing from '../components/CreateDeliveryOrder.js/DeliveryListing'
import { collection, doc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore'
import { db } from '../firebase.config'
import { useSelector } from 'react-redux'
import UpdateDeliveryModal from '../components/CreateDeliveryOrder.js/UpdateDeliveryModal'
import {
  ref as storageRef,
  getDownloadURL,
  uploadBytesResumable,
  listAll,
} from "firebase/storage";
import { storage } from "../firebase.config";
const DeliveryBoyList = () => {
  const [pickupList, setPickupList] = useState([])
    const { error, loading, isAuthenticated,users,userProfile } = useSelector(
        (state) => state.user
      );
      const [updateModal, setUpdateModal] = useState(false)
      const [deliveryId, setDeliveryId] = useState("")
      const [orderDetail, setOrderDetail] = useState({})
      const [orderActivity, setOrderActivity] = useState([])
      const [status, setStatus] = useState("")
      const [pod, setPod] = useState("")
      const handleUpdateStatus = (id) =>{
        const repairRef = doc(db, "logisticOrder", deliveryId);
        const date = new Date()
        updateDoc(repairRef, {
          orderStatus:status,
         pod:pod,
          orderActivity:[...orderActivity,{
            status:status,
            remark:"Order Has been Delivered",
            location:"Delivery Location",
            time:date
          }],
          
          });
      }
      const handleUpdateModal = () =>{
        setUpdateModal(!updateModal)
      }
      
      const fetchOrderDetails = (id) =>{
        onSnapshot(doc(db, "logisticOrder", id), (doc) => {
          const data = doc.data()
        setOrderDetail(data)
        setOrderActivity(data?.orderActivity)
          });
      }
      const addPOD = (e) =>{
        e.preventDefault()
        const file = e.target[0]?.files[0]
        if (!file) return;
        const storeRef = storageRef(storage, `files/${file.name}`);
        const uploadTask = uploadBytesResumable(storeRef, file);
    
        uploadTask.on("state_changed",
          (snapshot) => {
            
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
             setPod(downloadURL)
              console.log(downloadURL)
            });
          }
        );
      }
    const fetchPickupList = async () =>{
        const q = query(collection(db, "logisticOrder"),where("deliveryBoyId","==",users),where("orderStatus","==","delivering")) 
        const querySnapshot = await getDocs(q);
         querySnapshot.forEach((doc) => {
            setPickupList((prev)=>[...prev,{
                id:doc.id,
                ...doc.data()
               }])
          
         });
      }
      console.log(pickupList)
      useEffect(() => {
        fetchPickupList()
      }, [])
  return (
    <div>
    <UpdateDeliveryModal addPOD={addPOD}  pod={pod} status={status} setStatus={setStatus} handleUpdateStatus={handleUpdateStatus}  updateModal={updateModal} handleUpdateModal={handleUpdateModal} setUpdateModal={setUpdateModal} />
        <Topbar />
        <DeliveryListing deliveryId={deliveryId} setDeliveryId={setDeliveryId} fetchOrderDetails={fetchOrderDetails} handleUpdateModal={handleUpdateModal} pickupList={pickupList} />
    </div>
  )
}

export default DeliveryBoyList