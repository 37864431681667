import React, { useState } from 'react'
import Topbar from '../components/Layout/Topbar'
import { Sidebar } from '../components/Layout/Sidebar'
import CreateInvoiceArea from '../components/Invoice/CreateInvoiceArea'

const CreateInvoice = () => {
 
  return (
    <div>
    <Topbar />
   
    <div className='grid grid-cols-5 bg-gray-100 grid-flow-col' >
        <Sidebar />
     <CreateInvoiceArea />
        </div>
    </div>
  )
}

export default CreateInvoice