
  export const  pinCodeList = [
      {
        SN: 0,
        Pin: 575012,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1,
        Pin: 121101,
        DispatchCenter: "Faridabad_Sector24_L (Haryana)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2,
        Pin: 333033,
        DispatchCenter: "Buhana_Puranabas_D (Rajasthan)",
        OriginCenter: "Buhana_Puranabas_D (Rajasthan)",
        FacilityCity: "Buhana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3,
        Pin: 790114,
        DispatchCenter: "Bhalukpong_Khenewa_D (Arunachal Pradesh)",
        OriginCenter: "Bhalukpong_Khenewa_D (Arunachal Pradesh)",
        FacilityCity: "Bhalukpong",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4,
        Pin: 182144,
        DispatchCenter: "Ramban_Sangaldan_D (Jammu & Kashmir)",
        OriginCenter: "Ramban_Sangaldan_D (Jammu & Kashmir)",
        FacilityCity: "Ramban",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5,
        Pin: 841213,
        DispatchCenter: "Chhapra_Ramnagar_I (Bihar)",
        OriginCenter: "Chhapra_Ramnagar_I (Bihar)",
        FacilityCity: "Chhapra",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6,
        Pin: 761102,
        DispatchCenter: "Hinjilicut_RegidiSt_D (Orissa)",
        OriginCenter: "Hinjilicut_RegidiSt_D (Orissa)",
        FacilityCity: "Hinjilicut",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7,
        Pin: 577115,
        DispatchCenter: "Shimoga_Eshwariah_I (Karnataka)",
        OriginCenter: "Shimoga_Eshwariah_I (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8,
        Pin: 624702,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9,
        Pin: 784513,
        DispatchCenter: "Udalguri_Dhansrighat_D (Assam)",
        OriginCenter: "Udalguri_Dhansrighat_D (Assam)",
        FacilityCity: "Udalguri",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10,
        Pin: 144025,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11,
        Pin: 141421,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12,
        Pin: 757039,
        DispatchCenter: "Raruan_Badararuan_DPP (Orissa)",
        OriginCenter: "Raruan_Badararuan_DPP (Orissa)",
        FacilityCity: "Raruan",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13,
        Pin: 248123,
        DispatchCenter: "Chakrata_Purodi_DPP (Uttarakhand)",
        OriginCenter: "Chakrata_Purodi_DPP (Uttarakhand)",
        FacilityCity: "Chakrata",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14,
        Pin: 486440,
        DispatchCenter: "Raipurkarchulion_Joginhai_D (Madhya Pradesh)",
        OriginCenter: "Raipurkarchulion_Joginhai_D (Madhya Pradesh)",
        FacilityCity: "Raipur Karchulion",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 15,
        Pin: 370145,
        DispatchCenter: "Dhrangadhra_Jogasar_DPP (Gujarat)",
        OriginCenter: "Dhrangadhra_Jogasar_DPP (Gujarat)",
        FacilityCity: "Dhrangadhra",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16,
        Pin: 382845,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17,
        Pin: 622515,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18,
        Pin: 401204,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19,
        Pin: 700058,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20,
        Pin: 600012,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21,
        Pin: 274409,
        DispatchCenter: "Sewarhi_SaragatiaKaran_D (Uttar Pradesh)",
        OriginCenter: "Sewarhi_SaragatiaKaran_D (Uttar Pradesh)",
        FacilityCity: "Sewarhi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 22,
        Pin: 516437,
        DispatchCenter: "Badvel_Vidyanagar_D (Andhra Pradesh)",
        OriginCenter: "Badvel_Vidyanagar_D (Andhra Pradesh)",
        FacilityCity: "Badvel",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 23,
        Pin: 262407,
        DispatchCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        OriginCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        FacilityCity: "Bareilly",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 24,
        Pin: 614805,
        DispatchCenter: "Pudukkottai_Lnpuram_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Lnpuram_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 25,
        Pin: 576117,
        DispatchCenter: "Karkala_JodursteJ_D (Karnataka)",
        OriginCenter: "Karkala_JodursteJ_D (Karnataka)",
        FacilityCity: "Karkala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 26,
        Pin: 713429,
        DispatchCenter: "Manteswar_MemariRD_D (West Bengal)",
        OriginCenter: "Manteswar_MemariRD_D (West Bengal)",
        FacilityCity: "Manteswar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 27,
        Pin: 756135,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 28,
        Pin: 530011,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 29,
        Pin: 341519,
        DispatchCenter: "Ramgarh_Khoor_DPP (Rajasthan)",
        OriginCenter: "Ramgarh_Khoor_DPP (Rajasthan)",
        FacilityCity: "Ramgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 30,
        Pin: 793108,
        DispatchCenter: "Cherrapunji_NhkalikaiRd_DPP (Meghalaya)",
        OriginCenter: "Cherrapunji_NhkalikaiRd_DPP (Meghalaya)",
        FacilityCity: "Cherrapunji",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 31,
        Pin: 625115,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 32,
        Pin: 731241,
        DispatchCenter: "Rampurhat_Mansubamore_D (West Bengal)",
        OriginCenter: "Rampurhat_Mansubamore_D (West Bengal)",
        FacilityCity: "Rampurhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 33,
        Pin: 847424,
        DispatchCenter: "Madhubani_Sagarpur_D (Bihar)",
        OriginCenter: "Madhubani_Sagarpur_D (Bihar)",
        FacilityCity: "Madhubani",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 34,
        Pin: 628809,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 35,
        Pin: 743613,
        DispatchCenter: "Baruipur_Bnsibtla_D (West Bengal)",
        OriginCenter: "Baruipur_Bnsibtla_D (West Bengal)",
        FacilityCity: "Baruipur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 36,
        Pin: 786146,
        DispatchCenter: "Tinsukia_Nokhroygaon2_D (Assam)",
        OriginCenter: "Tinsukia_Nokhroygaon2_D (Assam)",
        FacilityCity: "Tinsukia",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 37,
        Pin: 486775,
        DispatchCenter: "Rampurnaikin_Jhanjh_D (Madhya Pradesh)",
        OriginCenter: "Rampurnaikin_Jhanjh_D (Madhya Pradesh)",
        FacilityCity: "Rampur Naikin",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 38,
        Pin: 177112,
        DispatchCenter: "Chintpurni_Bharwain_D (Himachal Pradesh)",
        OriginCenter: "Chintpurni_Bharwain_D (Himachal Pradesh)",
        FacilityCity: "Chintpurni",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 39,
        Pin: 723149,
        DispatchCenter: "Bhaddi_Tanashi_DPP (West Bengal)",
        OriginCenter: "Bhaddi_Tanashi_DPP (West Bengal)",
        FacilityCity: "Bhaddi",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 40,
        Pin: 335024,
        DispatchCenter: "Raisinghnagar_Ward24_D (Rajasthan)",
        OriginCenter: "Raisinghnagar_Ward24_D (Rajasthan)",
        FacilityCity: "Raisinghnagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 41,
        Pin: 530028,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 42,
        Pin: 416531,
        DispatchCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        OriginCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        FacilityCity: "Sawantwadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 43,
        Pin: 841256,
        DispatchCenter: "Siwan_Atarsua_D (Bihar)",
        OriginCenter: "Siwan_Atarsua_D (Bihar)",
        FacilityCity: "Siwan",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 44,
        Pin: 261131,
        DispatchCenter: "Sitapur_Lachimanpur_D (Uttar Pradesh)",
        OriginCenter: "Sitapur_Lachimanpur_D (Uttar Pradesh)",
        FacilityCity: "Sitapur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 45,
        Pin: 682319,
        DispatchCenter: "Kanjiramattom_Piravom_D (Kerala)",
        OriginCenter: "Kanjiramattom_Piravom_D (Kerala)",
        FacilityCity: "Kanjiramattom",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 46,
        Pin: 388239,
        DispatchCenter: "Balasinor_Alnoor_DPP (Gujarat)",
        OriginCenter: "Balasinor_Alnoor_DPP (Gujarat)",
        FacilityCity: "Balasinor",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 47,
        Pin: 770020,
        DispatchCenter: "Sundargarh_BndhaPli_DPP (Orissa)",
        OriginCenter: "Sundargarh_BndhaPli_DPP (Orissa)",
        FacilityCity: "Sundargarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 48,
        Pin: 576108,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 49,
        Pin: 425102,
        DispatchCenter: "Jalgaon_MIDC_I (Maharashtra)",
        OriginCenter: "Jalgaon_MIDC_I (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 50,
        Pin: 636812,
        DispatchCenter: "Denkanikottai_AnchettyRd_D (Tamil Nadu)",
        OriginCenter: "Denkanikottai_AnchettyRd_D (Tamil Nadu)",
        FacilityCity: "Denkanikottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 51,
        Pin: 591318,
        DispatchCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        OriginCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        FacilityCity: "Gokak",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 52,
        Pin: 392026,
        DispatchCenter: "Songadh_Vyara_D (Gujarat)",
        OriginCenter: "Songadh_Vyara_D (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 53,
        Pin: 202002,
        DispatchCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        OriginCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 54,
        Pin: 769014,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 55,
        Pin: 431703,
        DispatchCenter: "Hingoli_Tapdiyanagar_D (Maharashtra)",
        OriginCenter: "Hingoli_Tapdiyanagar_D (Maharashtra)",
        FacilityCity: "Hingoli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 56,
        Pin: 637209,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 57,
        Pin: 628617,
        DispatchCenter: "Eral_Nazareth_D (Tamil Nadu)",
        OriginCenter: "Eral_Nazareth_D (Tamil Nadu)",
        FacilityCity: "Eral",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 58,
        Pin: 346002,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 59,
        Pin: 585416,
        DispatchCenter: "Basavakalyan_Morkhandi_DPP (Karnataka)",
        OriginCenter: "Basavakalyan_Morkhandi_DPP (Karnataka)",
        FacilityCity: "Basavakalyan",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 60,
        Pin: 676109,
        DispatchCenter: "Tirur_Talakkad_D (Kerala)",
        OriginCenter: "Tirur_Talakkad_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 61,
        Pin: 209871,
        DispatchCenter: "Miyanganj_Bangarmau_D (Uttar Pradesh)",
        OriginCenter: "Miyanganj_Bangarmau_D (Uttar Pradesh)",
        FacilityCity: "Pihani",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 62,
        Pin: 518346,
        DispatchCenter: "Alur_VaddeStreet_D (Andhra Pradesh)",
        OriginCenter: "Alur_VaddeStreet_D (Andhra Pradesh)",
        FacilityCity: "Alur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 63,
        Pin: 795138,
        DispatchCenter: "Imphal_Takyel_I (Manipur)",
        OriginCenter: "Imphal_Takyel_I (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 64,
        Pin: 731244,
        DispatchCenter: "Khargram_Parbatipur_D (West Bengal)",
        OriginCenter: "Khargram_Parbatipur_D (West Bengal)",
        FacilityCity: "Khargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 65,
        Pin: 431710,
        DispatchCenter: "Dharmabad_BalajiNgr_D (Maharashtra)",
        OriginCenter: "Dharmabad_BalajiNgr_D (Maharashtra)",
        FacilityCity: "Dharmabad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 66,
        Pin: 600042,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 67,
        Pin: 362630,
        DispatchCenter: "Manavadar_Ratanpara_DPP (Gujarat)",
        OriginCenter: "Manavadar_Ratanpara_DPP (Gujarat)",
        FacilityCity: "Manavadar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 68,
        Pin: 843101,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 69,
        Pin: 416008,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 70,
        Pin: 627102,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 71,
        Pin: 516130,
        DispatchCenter: "Rayachoti_Tsundupalli_D (Andhra Pradesh)",
        OriginCenter: "Rayachoti_Tsundupalli_D (Andhra Pradesh)",
        FacilityCity: "Rayachoti",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 72,
        Pin: 458771,
        DispatchCenter: "Bhanpura_Lod_D (Madhya Pradesh)",
        OriginCenter: "Bhanpura_Lod_D (Madhya Pradesh)",
        FacilityCity: "Bhanpura",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 73,
        Pin: 332714,
        DispatchCenter: "Neemkathana_Chhawani_D (Rajasthan)",
        OriginCenter: "Neemkathana_Chhawani_D (Rajasthan)",
        FacilityCity: "Neem ka Thana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 74,
        Pin: 841232,
        DispatchCenter: "Siwan_Barharia_D (Bihar)",
        OriginCenter: "Siwan_Barharia_D (Bihar)",
        FacilityCity: "Mirganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 75,
        Pin: 624003,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 76,
        Pin: 606001,
        DispatchCenter: "Virudhachalam_Tamilnagar_D (Tamil Nadu)",
        OriginCenter: "Virudhachalam_Tamilnagar_D (Tamil Nadu)",
        FacilityCity: "Virudhachalam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 77,
        Pin: 506365,
        DispatchCenter: "Rayaparthi_Busstand_D (Telangana)",
        OriginCenter: "Rayaparthi_Busstand_D (Telangana)",
        FacilityCity: "Rayaparthi",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 78,
        Pin: 143117,
        DispatchCenter: "Goindwal_Fatehabad_D (Punjab)",
        OriginCenter: "Goindwal_Fatehabad_D (Punjab)",
        FacilityCity: "Goindwal",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 79,
        Pin: 232110,
        DispatchCenter: "Chandauli_ShubsNGR_D (Uttar Pradesh)",
        OriginCenter: "Chandauli_ShubsNGR_D (Uttar Pradesh)",
        FacilityCity: "Chandauli",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 80,
        Pin: 387570,
        DispatchCenter: "Ahmedabad_Kanera_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 81,
        Pin: 476345,
        DispatchCenter: "Sheopur_Salapura_DPP (Madhya Pradesh)",
        OriginCenter: "Sheopur_Salapura_DPP (Madhya Pradesh)",
        FacilityCity: "Sheopur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 82,
        Pin: 781022,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 83,
        Pin: 412210,
        DispatchCenter: "Shirur_RanjangaonMIDC_L (Maharashtra)",
        OriginCenter: "Shirur_RanjangaonMIDC_L (Maharashtra)",
        FacilityCity: "Shirur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 84,
        Pin: 387610,
        DispatchCenter: "Kapadvanj_Dholikui_D (Gujarat)",
        OriginCenter: "Kapadvanj_Dholikui_D (Gujarat)",
        FacilityCity: "Kapadvanj",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 85,
        Pin: 814157,
        DispatchCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        OriginCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        FacilityCity: "Deoghar",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 86,
        Pin: 145105,
        DispatchCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        OriginCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 87,
        Pin: 302002,
        DispatchCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 88,
        Pin: 627354,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 89,
        Pin: 614618,
        DispatchCenter: "Pudukkottai_Lnpuram_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Lnpuram_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 90,
        Pin: 341505,
        DispatchCenter: "Makrana_Mathabhar_D (Rajasthan)",
        OriginCenter: "Makrana_Mathabhar_D (Rajasthan)",
        FacilityCity: "Makrana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 91,
        Pin: 843318,
        DispatchCenter: "Runnisaidpur_Madhvnagar_D (Bihar)",
        OriginCenter: "Runnisaidpur_Madhvnagar_D (Bihar)",
        FacilityCity: "Runnisaidpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 92,
        Pin: 844118,
        DispatchCenter: "Muzaffarpur_Chandrahati_D (Bihar)",
        OriginCenter: "Muzaffarpur_Chandrahati_D (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 93,
        Pin: 629809,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 94,
        Pin: 603311,
        DispatchCenter: "Chengalpattu_Cheyyur_D (Tamil Nadu)",
        OriginCenter: "Chengalpattu_Cheyyur_D (Tamil Nadu)",
        FacilityCity: "Melmaruvathur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 95,
        Pin: 847421,
        DispatchCenter: "Khutauna_ShastriChwk_D (Bihar)",
        OriginCenter: "Khutauna_ShastriChwk_D (Bihar)",
        FacilityCity: "Khutauna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 96,
        Pin: 788109,
        DispatchCenter: "Udarbond_Pangram_D (Assam)",
        OriginCenter: "Udarbond_Pangram_D (Assam)",
        FacilityCity: "Udarbond",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 97,
        Pin: 629161,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 98,
        Pin: 272203,
        DispatchCenter: "Naugarh_Trtribazar_D (Uttar Pradesh)",
        OriginCenter: "Naugarh_Trtribazar_D (Uttar Pradesh)",
        FacilityCity: "Naugarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 99,
        Pin: 530007,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 100,
        Pin: 506310,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 101,
        Pin: 136118,
        DispatchCenter: "Kurukshetra_DDColony_D (Haryana)",
        OriginCenter: "Kurukshetra_DDColony_D (Haryana)",
        FacilityCity: "Kurukshetra",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 102,
        Pin: 761217,
        DispatchCenter: "Mohana_Betarsingi_D (Orissa)",
        OriginCenter: "Mohana_Betarsingi_D (Orissa)",
        FacilityCity: "Mohana",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 103,
        Pin: 314037,
        DispatchCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        OriginCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        FacilityCity: "Sagwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 104,
        Pin: 734501,
        DispatchCenter: "Kalijhora_KljhraBazar_DPP (West Bengal)",
        OriginCenter: "Kalijhora_KljhraBazar_DPP (West Bengal)",
        FacilityCity: "Kalijhora",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 105,
        Pin: 768118,
        DispatchCenter: "Rairakhol_KachariRd_DPP (Orissa)",
        OriginCenter: "Rairakhol_KachariRd_DPP (Orissa)",
        FacilityCity: "Rairakhol",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 106,
        Pin: 416806,
        DispatchCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        OriginCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        FacilityCity: "Devgad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 107,
        Pin: 504202,
        DispatchCenter: "KhanapurTS_BusStand_DPP (Telangana)",
        OriginCenter: "KhanapurTS_BusStand_DPP (Telangana)",
        FacilityCity: "Khanapur-TS",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 108,
        Pin: 689649,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 109,
        Pin: 625526,
        DispatchCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        OriginCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        FacilityCity: "Cumbum",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 110,
        Pin: 591302,
        DispatchCenter: "Khanapur_StatinRD_D (Karnataka)",
        OriginCenter: "Khanapur_StatinRD_D (Karnataka)",
        FacilityCity: "Khanapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 111,
        Pin: 600071,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 112,
        Pin: 670334,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 113,
        Pin: 505471,
        DispatchCenter: "Husnabad_HanmakondaRd_D (Telangana)",
        OriginCenter: "Husnabad_HanmakondaRd_D (Telangana)",
        FacilityCity: "Husnabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 114,
        Pin: 638110,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 115,
        Pin: 576126,
        DispatchCenter: "Karkala_JodursteJ_D (Karnataka)",
        OriginCenter: "Karkala_JodursteJ_D (Karnataka)",
        FacilityCity: "Karkala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 116,
        Pin: 630709,
        DispatchCenter: "Paramakudi_River_D (Tamil Nadu)",
        OriginCenter: "Paramakudi_River_D (Tamil Nadu)",
        FacilityCity: "Paramakudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 117,
        Pin: 695307,
        DispatchCenter: "Attingal_Central_D_2 (Kerala)",
        OriginCenter: "Attingal_Central_D_2 (Kerala)",
        FacilityCity: "Attingal",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 118,
        Pin: 388270,
        DispatchCenter: "Lunawada_VrdhriRD_D (Gujarat)",
        OriginCenter: "Lunawada_VrdhriRD_D (Gujarat)",
        FacilityCity: "Lunawada",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 119,
        Pin: 534227,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 120,
        Pin: 326039,
        DispatchCenter: "Aklera_Mangta_D (Rajasthan)",
        OriginCenter: "Aklera_Mangta_D (Rajasthan)",
        FacilityCity: "Aklera",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 121,
        Pin: 628502,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 122,
        Pin: 171018,
        DispatchCenter: "Suni_Basantpur_DPP (Himachal Pradesh)",
        OriginCenter: "Suni_Basantpur_DPP (Himachal Pradesh)",
        FacilityCity: "Suni",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 123,
        Pin: 591126,
        DispatchCenter: "Saundatti_Bypassroad_D (Karnataka)",
        OriginCenter: "Saundatti_Bypassroad_D (Karnataka)",
        FacilityCity: "Saundatti",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 124,
        Pin: 572138,
        DispatchCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        OriginCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 125,
        Pin: 713206,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 126,
        Pin: 403704,
        DispatchCenter: "Sanvordem_PntmlDPP_D (Goa)",
        OriginCenter: "Sanvordem_PntmlDPP_D (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 127,
        Pin: 144038,
        DispatchCenter: "Nakodar_Goldenavenue_D (Punjab)",
        OriginCenter: "Nakodar_Goldenavenue_D (Punjab)",
        FacilityCity: "Nakodar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 128,
        Pin: 731204,
        DispatchCenter: "Bolpur_Surul_DPP (West Bengal)",
        OriginCenter: "Bolpur_Surul_DPP (West Bengal)",
        FacilityCity: "Bolpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 129,
        Pin: 686034,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 130,
        Pin: 141105,
        DispatchCenter: "Raikot_DC (Punjab)",
        OriginCenter: "Raikot_DC (Punjab)",
        FacilityCity: "Raikot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 131,
        Pin: 413519,
        DispatchCenter: "Udgir_NlgaonRd_D (Maharashtra)",
        OriginCenter: "Udgir_NlgaonRd_D (Maharashtra)",
        FacilityCity: "Udgir",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 132,
        Pin: 143417,
        DispatchCenter: "Patti_TarnTaran_D (Punjab)",
        OriginCenter: "Patti_TarnTaran_D (Punjab)",
        FacilityCity: "Patti",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 133,
        Pin: 574240,
        DispatchCenter: "Belthangady_UjreMnRd_DPP (Karnataka)",
        OriginCenter: "Belthangady_UjreMnRd_DPP (Karnataka)",
        FacilityCity: "Belthangady",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 134,
        Pin: 389001,
        DispatchCenter: "Godhra_Soniwada_D (Gujarat)",
        OriginCenter: "Godhra_Soniwada_D (Gujarat)",
        FacilityCity: "Godhra",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 135,
        Pin: 531002,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 136,
        Pin: 176311,
        DispatchCenter: "Bharmour_Kharamukh_D (Himachal Pradesh)",
        OriginCenter: "Bharmour_Kharamukh_D (Himachal Pradesh)",
        FacilityCity: "Bharmour",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 137,
        Pin: 770044,
        DispatchCenter: "Lahunipara_Gudhiali_D (Orissa)",
        OriginCenter: "Lahunipara_Gudhiali_D (Orissa)",
        FacilityCity: "Lahunipara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 138,
        Pin: 586142,
        DispatchCenter: "Sindagi_Bijapurrd_D (Karnataka)",
        OriginCenter: "Sindagi_Bijapurrd_D (Karnataka)",
        FacilityCity: "Sindagi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 139,
        Pin: 382245,
        DispatchCenter: "Limbdi_Ranpur_DPP (Gujarat)",
        OriginCenter: "Limbdi_Ranpur_DPP (Gujarat)",
        FacilityCity: "Limbdi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 140,
        Pin: 686121,
        DispatchCenter: "Pala_Elappunkal_D (Kerala)",
        OriginCenter: "Pala_Elappunkal_D (Kerala)",
        FacilityCity: "Pala",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 141,
        Pin: 608102,
        DispatchCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        OriginCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        FacilityCity: "Chidambaram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 142,
        Pin: 303506,
        DispatchCenter: "Dausa_Madhavnagar_D (Rajasthan)",
        OriginCenter: "Dausa_Madhavnagar_D (Rajasthan)",
        FacilityCity: "Dausa",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 143,
        Pin: 828309,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 144,
        Pin: 274402,
        DispatchCenter: "Kushinagar_Siswa_D (Uttar Pradesh)",
        OriginCenter: "Kushinagar_Siswa_D (Uttar Pradesh)",
        FacilityCity: "Kushinagar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 145,
        Pin: 732202,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 146,
        Pin: 535217,
        DispatchCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        OriginCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        FacilityCity: "Vizianagaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 147,
        Pin: 502278,
        DispatchCenter: "Pregnapur_Tharakaramanagar_D (Telangana)",
        OriginCenter: "Pregnapur_Tharakaramanagar_D (Telangana)",
        FacilityCity: "Pregnapur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 148,
        Pin: 768233,
        DispatchCenter: "Brajrajnagar_Jagannath_DPP (Orissa)",
        OriginCenter: "Brajrajnagar_Jagannath_DPP (Orissa)",
        FacilityCity: "Brajrajnagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 149,
        Pin: 365540,
        DispatchCenter: "Rajula_Anandnagar_D (Gujarat)",
        OriginCenter: "Rajula_Anandnagar_D (Gujarat)",
        FacilityCity: "Rajula",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 150,
        Pin: 360530,
        DispatchCenter: "Jamjodhpur_SubhashRd_DPP (Gujarat)",
        OriginCenter: "Jamjodhpur_SubhashRd_DPP (Gujarat)",
        FacilityCity: "Jamjodhpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 151,
        Pin: 144035,
        DispatchCenter: "Nakodar_Goldenavenue_D (Punjab)",
        OriginCenter: "Nakodar_Goldenavenue_D (Punjab)",
        FacilityCity: "Nakodar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 152,
        Pin: 333517,
        DispatchCenter: "Buhana_Rajota_D (Rajasthan)",
        OriginCenter: "Buhana_Rajota_D (Rajasthan)",
        FacilityCity: "Buhana",
        FacilityState: "Rajasthan",
        Zone : "N1"
      },
      {
        SN: 153,
        Pin: 321613,
        DispatchCenter: "Mahwa_Allapur_D (Rajasthan)",
        OriginCenter: "Mahwa_Allapur_D (Rajasthan)",
        FacilityCity: "Mahwa",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 154,
        Pin: 781318,
        DispatchCenter: "Barpeta_Bhera_D (Assam)",
        OriginCenter: "Barpeta_ShaktiNagar_C (Assam)",
        FacilityCity: "Barpeta",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 155,
        Pin: 325204,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 156,
        Pin: 207125,
        DispatchCenter: "Kasganj_GandhiKunj_D (Uttar Pradesh)",
        OriginCenter: "Kasganj_GandhiKunj_D (Uttar Pradesh)",
        FacilityCity: "Kasganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 157,
        Pin: 756024,
        DispatchCenter: "Basta_Darada_DPP (Orissa)",
        OriginCenter: "Basta_Darada_DPP (Orissa)",
        FacilityCity: "Basta",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 158,
        Pin: 732103,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 159,
        Pin: 695521,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 160,
        Pin: 247672,
        DispatchCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        OriginCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        FacilityCity: "Saharanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 161,
        Pin: 343022,
        DispatchCenter: "Jalore_Sayla_DPP (Rajasthan)",
        OriginCenter: "Jalore_Sayla_DPP (Rajasthan)",
        FacilityCity: "Jalore",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 162,
        Pin: 688557,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 163,
        Pin: 734503,
        DispatchCenter: "Jholung_JholungBazarDPP_D (West Bengal)",
        OriginCenter: "Jholung_JholungBazarDPP_D (West Bengal)",
        FacilityCity: "Jholung",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 164,
        Pin: 323024,
        DispatchCenter: "Bundi_Central_D_1 (Rajasthan)",
        OriginCenter: "Bundi_Central_D_1 (Rajasthan)",
        FacilityCity: "Bundi",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 165,
        Pin: 174102,
        DispatchCenter: "Kunihar_Diggal_DPP (Himachal Pradesh)",
        OriginCenter: "Kunihar_Diggal_DPP (Himachal Pradesh)",
        FacilityCity: "Kunihar",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 166,
        Pin: 532215,
        DispatchCenter: "Tekkali_Tolusurapalli_D (Andhra Pradesh)",
        OriginCenter: "Tekkali_Tolusurapalli_D (Andhra Pradesh)",
        FacilityCity: "Tekkali",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 167,
        Pin: 414609,
        DispatchCenter: "Newasa_Khurd_D (Maharashtra)",
        OriginCenter: "Newasa_Khurd_D (Maharashtra)",
        FacilityCity: "Newasa",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 168,
        Pin: 606301,
        DispatchCenter: "Chinnasalem_VKootRd_D (Tamil Nadu)",
        OriginCenter: "Chinnasalem_VKootRd_D (Tamil Nadu)",
        FacilityCity: "Chinnasalem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 169,
        Pin: 722168,
        DispatchCenter: "Barjora_PoliceStn_DPP (West Bengal)",
        OriginCenter: "Barjora_PoliceStn_DPP (West Bengal)",
        FacilityCity: "Barjora",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 170,
        Pin: 190021,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 171,
        Pin: 124142,
        DispatchCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        OriginCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        FacilityCity: "Charkhi Dadri",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 172,
        Pin: 125077,
        DispatchCenter: "Sirsa_Kaliawaliroad_D (Haryana)",
        OriginCenter: "Sirsa_Kaliawaliroad_D (Haryana)",
        FacilityCity: "Sirsa",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 173,
        Pin: 508253,
        DispatchCenter: "Choutuppal_Autonagar_D (Telangana)",
        OriginCenter: "Choutuppal_Autonagar_D (Telangana)",
        FacilityCity: "Choutuppal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 174,
        Pin: 505525,
        DispatchCenter: "Choppadandi_Gopalraopeta_D (Telangana)",
        OriginCenter: "Choppadandi_Gopalraopeta_D (Telangana)",
        FacilityCity: "Choppadandi",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 175,
        Pin: 464986,
        DispatchCenter: "Raisen_Gopalpur_DPP (Madhya Pradesh)",
        OriginCenter: "Raisen_Gopalpur_DPP (Madhya Pradesh)",
        FacilityCity: "Raisen",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 176,
        Pin: 413501,
        DispatchCenter: "Osmanabad_RajasabH_D (Maharashtra)",
        OriginCenter: "Osmanabad_RajasabH_D (Maharashtra)",
        FacilityCity: "Osmanabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 177,
        Pin: 392110,
        DispatchCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        OriginCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        FacilityCity: "Jambusar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 178,
        Pin: 700026,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 179,
        Pin: 361007,
        DispatchCenter: "Jamnagar_Chela_L (Gujarat)",
        OriginCenter: "Jamnagar_Chela_L (Gujarat)",
        FacilityCity: "Jamnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 180,
        Pin: 845501,
        DispatchCenter: "Bettiah_Manshatola_D (Bihar)",
        OriginCenter: "Bettiah_Manshatola_D (Bihar)",
        FacilityCity: "Bettiah",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 181,
        Pin: 506151,
        DispatchCenter: "Ghanpur_Palakrty_D (Telangana)",
        OriginCenter: "Ghanpur_Palakrty_D (Telangana)",
        FacilityCity: "Ghanpur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 182,
        Pin: 416220,
        DispatchCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        OriginCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        FacilityCity: "Gadhinglaj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 183,
        Pin: 425002,
        DispatchCenter: "Jalgaon_MIDC_I (Maharashtra)",
        OriginCenter: "Jalgaon_MIDC_I (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 184,
        Pin: 485773,
        DispatchCenter: "Maihar_Harnampur_D (Madhya Pradesh)",
        OriginCenter: "Maihar_Harnampur_D (Madhya Pradesh)",
        FacilityCity: "Maihar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 185,
        Pin: 585238,
        DispatchCenter: "Chittapur_GlbrgaRD_D (Karnataka)",
        OriginCenter: "Chittapur_GlbrgaRD_D (Karnataka)",
        FacilityCity: "Chittapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 186,
        Pin: 802125,
        DispatchCenter: "Malyabag_ArrahRd_D (Bihar)",
        OriginCenter: "Malyabag_ArrahRd_D (Bihar)",
        FacilityCity: "Malyabag",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 187,
        Pin: 828129,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 188,
        Pin: 306119,
        DispatchCenter: "Khinwara_Nadol_DPP (Rajasthan)",
        OriginCenter: "Khinwara_Nadol_DPP (Rajasthan)",
        FacilityCity: "Khinwara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 189,
        Pin: 500084,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 190,
        Pin: 422007,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 191,
        Pin: 612805,
        DispatchCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        OriginCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 192,
        Pin: 607002,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 193,
        Pin: 123301,
        DispatchCenter: "Rewari_Kosli_D (Haryana)",
        OriginCenter: "Rewari_Kosli_D (Haryana)",
        FacilityCity: "Jhajjar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 194,
        Pin: 518206,
        DispatchCenter: "Betamcherla_ChinnaSch_DPP (Andhra Pradesh)",
        OriginCenter: "Betamcherla_ChinnaSch_DPP (Andhra Pradesh)",
        FacilityCity: "Betamcherla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 195,
        Pin: 627858,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 196,
        Pin: 262727,
        DispatchCenter: "Maholi_Urdauli_D (Uttar Pradesh)",
        OriginCenter: "Maholi_Urdauli_D (Uttar Pradesh)",
        FacilityCity: "Maholi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 197,
        Pin: 455223,
        DispatchCenter: "Chapda_Ideacolony_DPP (Madhya Pradesh)",
        OriginCenter: "Chapda_Ideacolony_DPP (Madhya Pradesh)",
        FacilityCity: "Chapda",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 198,
        Pin: 625109,
        DispatchCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        OriginCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        FacilityCity: "Melur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 199,
        Pin: 490023,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 200,
        Pin: 670613,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 201,
        Pin: 563160,
        DispatchCenter: "Bangalore_Koralur_L (Karnataka)",
        OriginCenter: "Bangalore_Soukyaroad_GW (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 202,
        Pin: 400096,
        DispatchCenter: "Mumbai_Kurla_L (Maharashtra)",
        OriginCenter: "Mumbai_Kurla_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 203,
        Pin: 841214,
        DispatchCenter: "Chhapra_Ramnagar_I (Bihar)",
        OriginCenter: "Chhapra_Ramnagar_I (Bihar)",
        FacilityCity: "Chhapra",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 204,
        Pin: 676509,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 205,
        Pin: 144043,
        DispatchCenter: "Nakodar_Goldenavenue_D (Punjab)",
        OriginCenter: "Nakodar_Goldenavenue_D (Punjab)",
        FacilityCity: "Nakodar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 206,
        Pin: 733103,
        DispatchCenter: "Balurghat_AkhirDPP_D (West Bengal)",
        OriginCenter: "Balurghat_AkhirDPP_D (West Bengal)",
        FacilityCity: "Balurghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 207,
        Pin: 312618,
        DispatchCenter: "PratapgarhRJ_Badabag_DPP (Rajasthan)",
        OriginCenter: "PratapgarhRJ_Badabag_DPP (Rajasthan)",
        FacilityCity: "Pratapgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 208,
        Pin: 678551,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 209,
        Pin: 461005,
        DispatchCenter: "Hoshangabad_BabaiRd_DPP (Madhya Pradesh)",
        OriginCenter: "Hoshangabad_BabaiRd_DPP (Madhya Pradesh)",
        FacilityCity: "Hoshangabad",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 210,
        Pin: 460665,
        DispatchCenter: "Multai_Birulbzr_D (Madhya Pradesh)",
        OriginCenter: "Multai_Birulbzr_D (Madhya Pradesh)",
        FacilityCity: "Multai",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 211,
        Pin: 676501,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 212,
        Pin: 635306,
        DispatchCenter: "Uthangarai_Mathurpathi_D (Tamil Nadu)",
        OriginCenter: "Uthangarai_Mathurpathi_D (Tamil Nadu)",
        FacilityCity: "Uthangarai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 213,
        Pin: 210507,
        DispatchCenter: "HamirpurUP_Maudaha_D (Uttar Pradesh)",
        OriginCenter: "HamirpurUP_Maudaha_D (Uttar Pradesh)",
        FacilityCity: "Hamirpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 214,
        Pin: 333029,
        DispatchCenter: "Pilani (Rajasthan)",
        OriginCenter: "Pilani (Rajasthan)",
        FacilityCity: "Pilani",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 215,
        Pin: 792001,
        DispatchCenter: "Tezu_Farmnala_D (Arunachal Pradesh)",
        OriginCenter: "Tezu_Farmnala_D (Arunachal Pradesh)",
        FacilityCity: "Tezu",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 216,
        Pin: 641018,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 217,
        Pin: 690511,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 218,
        Pin: 627104,
        DispatchCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        OriginCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        FacilityCity: "Thisayanvilai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 219,
        Pin: 522354,
        DispatchCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        OriginCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 220,
        Pin: 624301,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 221,
        Pin: 722138,
        DispatchCenter: "MirzapurWB_VidyaDPP_D (West Bengal)",
        OriginCenter: "MirzapurWB_VidyaDPP_D (West Bengal)",
        FacilityCity: "Mirzapur-WB",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 222,
        Pin: 176402,
        DispatchCenter: "SurajpurJhikla_IndoraMore_DPP (Himachal Pradesh)",
        OriginCenter: "SurajpurJhikla_IndoraMore_DPP (Himachal Pradesh)",
        FacilityCity: "Surajpur jhikla",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 223,
        Pin: 673327,
        DispatchCenter: "Koyilandy_Komathukara_D (Kerala)",
        OriginCenter: "Koyilandy_Komathukara_D (Kerala)",
        FacilityCity: "Koyilandy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 224,
        Pin: 627401,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 225,
        Pin: 127045,
        DispatchCenter: "Siwani_Ward12_D (Haryana)",
        OriginCenter: "Siwani_Ward12_D (Haryana)",
        FacilityCity: "Siwani",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 226,
        Pin: 695142,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 227,
        Pin: 442703,
        DispatchCenter: "Aheri_ChinchgndiRd_DPP (Maharashtra)",
        OriginCenter: "Aheri_ChinchgndiRd_DPP (Maharashtra)",
        FacilityCity: "Aheri",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 228,
        Pin: 207403,
        DispatchCenter: "Kasganj_GandhiKunj_D (Uttar Pradesh)",
        OriginCenter: "Kasganj_GandhiKunj_D (Uttar Pradesh)",
        FacilityCity: "Kasganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 229,
        Pin: 784103,
        DispatchCenter: "Tezpur_Dipota_H (Assam)",
        OriginCenter: "Tezpur_Dipota_H (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 230,
        Pin: 758027,
        DispatchCenter: "Ghatgaon_Harichandanpur_D (Orissa)",
        OriginCenter: "Ghatgaon_Harichandanpur_D (Orissa)",
        FacilityCity: "Ghatgaon",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 231,
        Pin: 624002,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 232,
        Pin: 577511,
        DispatchCenter: "Hiriyur_Vedvthingr_D (Karnataka)",
        OriginCenter: "Hiriyur_Vedvthingr_D (Karnataka)",
        FacilityCity: "Hiriyur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 233,
        Pin: 415527,
        DispatchCenter: "Vaduj_Satewdi_D (Maharashtra)",
        OriginCenter: "Vaduj_Satewdi_D (Maharashtra)",
        FacilityCity: "Vaduj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 234,
        Pin: 686613,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 235,
        Pin: 585331,
        DispatchCenter: "Humnabad_ApmcYard_DPP (Karnataka)",
        OriginCenter: "Humnabad_ApmcYard_DPP (Karnataka)",
        FacilityCity: "Humnabad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 236,
        Pin: 803107,
        DispatchCenter: "Barbigha_Khetalpura_D (Bihar)",
        OriginCenter: "Barbigha_Khetalpura_D (Bihar)",
        FacilityCity: "Barbigha",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 237,
        Pin: 488333,
        DispatchCenter: "Panna_Devendranagar_DPP (Madhya Pradesh)",
        OriginCenter: "Panna_Devendranagar_DPP (Madhya Pradesh)",
        FacilityCity: "Panna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 238,
        Pin: 583219,
        DispatchCenter: "HoovinaHadagali_Holagundhi_D (Karnataka)",
        OriginCenter: "HoovinaHadagali_Holagundhi_D (Karnataka)",
        FacilityCity: "Hoovina Hadagali",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 239,
        Pin: 444503,
        DispatchCenter: "Washim_Malegaon_DPP (Maharashtra)",
        OriginCenter: "Washim_Malegaon_DPP (Maharashtra)",
        FacilityCity: "Washim",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 240,
        Pin: 695301,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 241,
        Pin: 303327,
        DispatchCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        OriginCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        FacilityCity: "Bandikui",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 242,
        Pin: 585443,
        DispatchCenter: "Aurad_CBColony_D (Karnataka)",
        OriginCenter: "Aurad_CBColony_D (Karnataka)",
        FacilityCity: "Aurad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 243,
        Pin: 577527,
        DispatchCenter: "Hosadurga_Vidyanagara_D (Karnataka)",
        OriginCenter: "Hosadurga_Vidyanagara_D (Karnataka)",
        FacilityCity: "Hosadurga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 244,
        Pin: 583120,
        DispatchCenter: "Kurugodu_Bellary_D (Karnataka)",
        OriginCenter: "Kurugodu_Bellary_D (Karnataka)",
        FacilityCity: "Kurugodu",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 245,
        Pin: 682032,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 246,
        Pin: 303120,
        DispatchCenter: "Achrol_Arvindcolony_D (Rajasthan)",
        OriginCenter: "Achrol_Arvindcolony_D (Rajasthan)",
        FacilityCity: "Achrol",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 247,
        Pin: 140603,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 248,
        Pin: 841502,
        DispatchCenter: "Siwan_Andar_D (Bihar)",
        OriginCenter: "Siwan_Andar_D (Bihar)",
        FacilityCity: "Siwan",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 249,
        Pin: 361345,
        DispatchCenter: "Dwarka_CharaklaRd_DPP (Gujarat)",
        OriginCenter: "Dwarka_CharaklaRd_DPP (Gujarat)",
        FacilityCity: "Dwarka",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 250,
        Pin: 784166,
        DispatchCenter: "Behali_PostOfc_D (Assam)",
        OriginCenter: "Behali_PostOfc_D (Assam)",
        FacilityCity: "Behali",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 251,
        Pin: 516350,
        DispatchCenter: "Vempalli_Kathaluru_D (Andhra Pradesh)",
        OriginCenter: "Vempalli_Kathaluru_D (Andhra Pradesh)",
        FacilityCity: "Vempalli",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 252,
        Pin: 326038,
        DispatchCenter: "Khanpur_JhalawarRd_DPP (Rajasthan)",
        OriginCenter: "Khanpur_JhalawarRd_DPP (Rajasthan)",
        FacilityCity: "Khanpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 253,
        Pin: 680316,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 254,
        Pin: 331403,
        DispatchCenter: "Sardarshahr_IndustrialArea_L (Rajasthan)",
        OriginCenter: "Sardarshahr_IndustrialArea_L (Rajasthan)",
        FacilityCity: "Sardarshahr",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 255,
        Pin: 575010,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 256,
        Pin: 785627,
        DispatchCenter: "Golaghat_BaruaRd_D (Assam)",
        OriginCenter: "Golaghat_BaruaRd_D (Assam)",
        FacilityCity: "Golaghat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 257,
        Pin: 312204,
        DispatchCenter: "Kapasan_AdarshNgr_DPP (Rajasthan)",
        OriginCenter: "Kapasan_AdarshNgr_DPP (Rajasthan)",
        FacilityCity: "Kapasan",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 258,
        Pin: 603402,
        DispatchCenter: "Vandavasi_SaltStreet_D (Tamil Nadu)",
        OriginCenter: "Vandavasi_SaltStreet_D (Tamil Nadu)",
        FacilityCity: "Vandavasi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 259,
        Pin: 494226,
        DispatchCenter: "Kondagaon_DNKColony_D (Chhattisgarh)",
        OriginCenter: "Kondagaon_DNKColony_D (Chhattisgarh)",
        FacilityCity: "Kondagaon",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 260,
        Pin: 641034,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 261,
        Pin: 585313,
        DispatchCenter: "Humnabad_Kamalapur_DPP (Karnataka)",
        OriginCenter: "Humnabad_Kamalapur_DPP (Karnataka)",
        FacilityCity: "Humnabad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 262,
        Pin: 591143,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 263,
        Pin: 247662,
        DispatchCenter: "Chhutmalpur_RathoreColony_DPP (Uttar Pradesh)",
        OriginCenter: "Chhutmalpur_RathoreColony_DPP (Uttar Pradesh)",
        FacilityCity: "Chhutmalpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 264,
        Pin: 423102,
        DispatchCenter: "Satana_MitraNagar_D (Maharashtra)",
        OriginCenter: "Satana_MitraNagar_D (Maharashtra)",
        FacilityCity: "Satana",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 265,
        Pin: 423703,
        DispatchCenter: "Vaijapur_Rajsarthi_D (Maharashtra)",
        OriginCenter: "Vaijapur_Rajsarthi_D (Maharashtra)",
        FacilityCity: "Vaijapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 266,
        Pin: 400106,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 267,
        Pin: 621014,
        DispatchCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        OriginCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        FacilityCity: "Thuraiyur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 268,
        Pin: 574198,
        DispatchCenter: "Belthangady_UjreMnRd_DPP (Karnataka)",
        OriginCenter: "Belthangady_UjreMnRd_DPP (Karnataka)",
        FacilityCity: "Belthangady",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 269,
        Pin: 630009,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 270,
        Pin: 643203,
        DispatchCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        OriginCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 271,
        Pin: 534209,
        DispatchCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        FacilityCity: "Bhimavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 272,
        Pin: 384205,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 273,
        Pin: 712411,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 274,
        Pin: 134115,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 275,
        Pin: 327601,
        DispatchCenter: "Banswara_Bagidora_D (Rajasthan)",
        OriginCenter: "Banswara_Bagidora_D (Rajasthan)",
        FacilityCity: "Banswara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 276,
        Pin: 122015,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 277,
        Pin: 700078,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 278,
        Pin: 713209,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 279,
        Pin: 824211,
        DispatchCenter: "Sherghati_GayaRd_D (Bihar)",
        OriginCenter: "Sherghati_GayaRd_D (Bihar)",
        FacilityCity: "Sherghati",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 280,
        Pin: 796070,
        DispatchCenter: "Kolasib_Kawnpui_D (Mizoram)",
        OriginCenter: "Kolasib_Kawnpui_D (Mizoram)",
        FacilityCity: "Kolasib",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 281,
        Pin: 503230,
        DispatchCenter: "Nizamabad_Sarangapur_I (Telangana)",
        OriginCenter: "Nizamabad_Sarangapur_I (Telangana)",
        FacilityCity: "Nizamabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 282,
        Pin: 335511,
        DispatchCenter: "Bhadra_GanapatiPlaza_D (Rajasthan)",
        OriginCenter: "Bhadra_GanapatiPlaza_D (Rajasthan)",
        FacilityCity: "Bhadra",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 283,
        Pin: 134117,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 284,
        Pin: 685608,
        DispatchCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        OriginCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        FacilityCity: "Thodupuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 285,
        Pin: 261141,
        DispatchCenter: "Maholi_Urdauli_D (Uttar Pradesh)",
        OriginCenter: "Maholi_Urdauli_D (Uttar Pradesh)",
        FacilityCity: "Maholi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 286,
        Pin: 753000,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 287,
        Pin: 515281,
        DispatchCenter: "Amarapuram_YSRCircle_D (Andhra Pradesh)",
        OriginCenter: "Amarapuram_YSRCircle_D (Andhra Pradesh)",
        FacilityCity: "Amarapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 288,
        Pin: 322028,
        DispatchCenter: "Bonli_Malarnachour_DPP (Rajasthan)",
        OriginCenter: "Bonli_Malarnachour_DPP (Rajasthan)",
        FacilityCity: "Bonli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 289,
        Pin: 244402,
        DispatchCenter: "Rampur_Suar_D (Uttar Pradesh)",
        OriginCenter: "Rampur_Suar_D (Uttar Pradesh)",
        FacilityCity: "Rampur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 290,
        Pin: 122107,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 291,
        Pin: 587206,
        DispatchCenter: "Kerur_WardNo2_D (Karnataka)",
        OriginCenter: "Kerur_WardNo2_D (Karnataka)",
        FacilityCity: "Kerur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 292,
        Pin: 632512,
        DispatchCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ranipet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 293,
        Pin: 251003,
        DispatchCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        OriginCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        FacilityCity: "Muzaffarnagar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 294,
        Pin: 638101,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 295,
        Pin: 209207,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 296,
        Pin: 678012,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 297,
        Pin: 799201,
        DispatchCenter: "Khowai_Officetilla_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Khowai",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 298,
        Pin: 577228,
        DispatchCenter: "Kadur_Ajjampura_D (Karnataka)",
        OriginCenter: "Kadur_Ajjampura_D (Karnataka)",
        FacilityCity: "Kadur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 299,
        Pin: 624711,
        DispatchCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        OriginCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        FacilityCity: "Oddanchatram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 300,
        Pin: 735214,
        DispatchCenter: "Alipurduar_Kalchini_DPP (West Bengal)",
        OriginCenter: "Alipurduar_Kalchini_DPP (West Bengal)",
        FacilityCity: "Alipurduar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 301,
        Pin: 509358,
        DispatchCenter: "Amangal_Adarshnagar_D (Telangana)",
        OriginCenter: "Amangal_Adarshnagar_D (Telangana)",
        FacilityCity: "Amangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 302,
        Pin: 688013,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 303,
        Pin: 502101,
        DispatchCenter: "Medak_RTCclny_D (Telangana)",
        OriginCenter: "Medak_RTCclny_D (Telangana)",
        FacilityCity: "Medak",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 304,
        Pin: 560061,
        DispatchCenter: "Bangalore_FCILayout_L (Karnataka)",
        OriginCenter: "Bangalore_FCILayout_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 305,
        Pin: 144408,
        DispatchCenter: "Phagwara_PurewalNgr_D (Punjab)",
        OriginCenter: "Phagwara_PurewalNgr_D (Punjab)",
        FacilityCity: "Phagwara",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 306,
        Pin: 506143,
        DispatchCenter: "Ghanpur_Palakrty_D (Telangana)",
        OriginCenter: "Ghanpur_Palakrty_D (Telangana)",
        FacilityCity: "Ghanpur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 307,
        Pin: 575013,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 308,
        Pin: 628503,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 309,
        Pin: 403602,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 310,
        Pin: 734312,
        DispatchCenter: "TistaBazar_TeestaBridge_DPP (West Bengal)",
        OriginCenter: "TistaBazar_TeestaBridge_DPP (West Bengal)",
        FacilityCity: "Tista Bazar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 311,
        Pin: 711403,
        DispatchCenter: "Howrah_Kamrangu_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 312,
        Pin: 110062,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 313,
        Pin: 226018,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 314,
        Pin: 680741,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 315,
        Pin: 382145,
        DispatchCenter: "Kadi_Jadavpura_L (Gujarat)",
        OriginCenter: "Kadi_Jadavpura_L (Gujarat)",
        FacilityCity: "Kadi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 316,
        Pin: 783126,
        DispatchCenter: "Goalpara_Dudhnoi_D (Assam)",
        OriginCenter: "Goalpara_Dudhnoi_D (Assam)",
        FacilityCity: "Goalpara",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 317,
        Pin: 175032,
        DispatchCenter: "JognderNgr_Dhelu_D (Himachal Pradesh)",
        OriginCenter: "JognderNgr_Dhelu_D (Himachal Pradesh)",
        FacilityCity: "Joginder Nagar",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 318,
        Pin: 415403,
        DispatchCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        OriginCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        FacilityCity: "Islampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 319,
        Pin: 401016,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 320,
        Pin: 504293,
        DispatchCenter: "Asifabad_Bank_D (Telangana)",
        OriginCenter: "Asifabad_Bank_D (Telangana)",
        FacilityCity: "Asifabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 321,
        Pin: 500019,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 322,
        Pin: 160068,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 323,
        Pin: 272175,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 324,
        Pin: 686029,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 325,
        Pin: 695023,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 326,
        Pin: 803203,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 327,
        Pin: 854304,
        DispatchCenter: "Purnia_Fatehpur_D (Bihar)",
        OriginCenter: "Purnia_Fatehpur_D (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 328,
        Pin: 504105,
        DispatchCenter: "Nirmal_AdarshNgr_D (Telangana)",
        OriginCenter: "Nirmal_AdarshNgr_D (Telangana)",
        FacilityCity: "Nirmal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 329,
        Pin: 757073,
        DispatchCenter: "Morada_Malihata_D (Orissa)",
        OriginCenter: "Morada_Malihata_D (Orissa)",
        FacilityCity: "Morada",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 330,
        Pin: 688556,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 331,
        Pin: 621212,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 332,
        Pin: 682001,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 333,
        Pin: 721636,
        DispatchCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        OriginCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        FacilityCity: "Tamluk",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 334,
        Pin: 788725,
        DispatchCenter: "Patharkandi_CntrlSchl_D (Assam)",
        OriginCenter: "Patharkandi_CntrlSchl_D (Assam)",
        FacilityCity: "Patharkandi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 335,
        Pin: 142048,
        DispatchCenter: "Moga_Duneke_I (Punjab)",
        OriginCenter: "Moga_Duneke_I (Punjab)",
        FacilityCity: "Moga",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 336,
        Pin: 680122,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 337,
        Pin: 248010,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 338,
        Pin: 743612,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 339,
        Pin: 382421,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 340,
        Pin: 333501,
        DispatchCenter: "Neemkathana_Chhawani_D (Rajasthan)",
        OriginCenter: "Neemkathana_Chhawani_D (Rajasthan)",
        FacilityCity: "Neem ka Thana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 341,
        Pin: 671123,
        DispatchCenter: "Kasaragod_Kudlu_D (Kerala)",
        OriginCenter: "Kasaragod_Kudlu_D (Kerala)",
        FacilityCity: "Kasaragod",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 342,
        Pin: 638502,
        DispatchCenter: "Anthiyur_Thavittuplym_DPP (Tamil Nadu)",
        OriginCenter: "Anthiyur_Thavittuplym_DPP (Tamil Nadu)",
        FacilityCity: "Anthiyour",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 343,
        Pin: 754010,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 344,
        Pin: 695007,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 345,
        Pin: 141413,
        DispatchCenter: "Khanna_Motinagar_D (Punjab)",
        OriginCenter: "Khanna_Motinagar_D (Punjab)",
        FacilityCity: "Khanna",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 346,
        Pin: 759123,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 347,
        Pin: 584135,
        DispatchCenter: "Raichur_Wadloor_I (Karnataka)",
        OriginCenter: "Raichur_Wadloor_I (Karnataka)",
        FacilityCity: "Raichur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 348,
        Pin: 392111,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 349,
        Pin: 443202,
        DispatchCenter: "Dusrabid_SugrFact_DPP (Maharashtra)",
        OriginCenter: "Dusrabid_SugrFact_DPP (Maharashtra)",
        FacilityCity: "Dusrabid",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 350,
        Pin: 273406,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 351,
        Pin: 490005,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 352,
        Pin: 637505,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 353,
        Pin: 442908,
        DispatchCenter: "Chandrapur_Gadchandur_DPP (Maharashtra)",
        OriginCenter: "Chandrapur_Gadchandur_DPP (Maharashtra)",
        FacilityCity: "Chandrapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 354,
        Pin: 629160,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 355,
        Pin: 413207,
        DispatchCenter: "Beed_Raimoha_DPP (Maharashtra)",
        OriginCenter: "Beed_Raimoha_DPP (Maharashtra)",
        FacilityCity: "Dongarkinhi",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 356,
        Pin: 586215,
        DispatchCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        OriginCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        FacilityCity: "Indi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 357,
        Pin: 342015,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 358,
        Pin: 494332,
        DispatchCenter: "Kanker_KrisnKunj_D (Chhattisgarh)",
        OriginCenter: "Kanker_KrisnKunj_D (Chhattisgarh)",
        FacilityCity: "Kanker",
        FacilityState: "Chhattisgarh",
        Zone : "E"
      },
      {
        SN: 359,
        Pin: 473774,
        DispatchCenter: "Shivpuri_Kolaras_D (Madhya Pradesh)",
        OriginCenter: "Shivpuri_Kolaras_D (Madhya Pradesh)",
        FacilityCity: "Shivpuri",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 360,
        Pin: 713385,
        DispatchCenter: "Dubrajpur_Pandaveswar_D (West Bengal)",
        OriginCenter: "Dubrajpur_Pandaveswar_D (West Bengal)",
        FacilityCity: "Dubrajpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 361,
        Pin: 241203,
        DispatchCenter: "Sandila_SubabDPP_D (Uttar Pradesh)",
        OriginCenter: "Sandila_SubabDPP_D (Uttar Pradesh)",
        FacilityCity: "Sandila",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 362,
        Pin: 509320,
        DispatchCenter: "Kalwakurthy_Margadarshi_D (Telangana)",
        OriginCenter: "Kalwakurthy_Margadarshi_D (Telangana)",
        FacilityCity: "Kalwakurthy",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 363,
        Pin: 491445,
        DispatchCenter: "Dongargarh_SouthColony_DPP (Chhattisgarh)",
        OriginCenter: "Dongargarh_SouthColony_DPP (Chhattisgarh)",
        FacilityCity: "Dongargarh",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 364,
        Pin: 683106,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 365,
        Pin: 229802,
        DispatchCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        OriginCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        FacilityCity: "Haidargarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 366,
        Pin: 160040,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 367,
        Pin: 401107,
        DispatchCenter: "Mumbai_Kandivali_L (Maharashtra)",
        OriginCenter: "Mumbai_Kandivali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 368,
        Pin: 614030,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 369,
        Pin: 680003,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 370,
        Pin: 835217,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 371,
        Pin: 560038,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 372,
        Pin: 431731,
        DispatchCenter: "Bodhan_VamshiSchl_D (Telangana)",
        OriginCenter: "Bodhan_VamshiSchl_D (Telangana)",
        FacilityCity: "Bodhan",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 373,
        Pin: 247771,
        DispatchCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        OriginCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        FacilityCity: "Baraut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 374,
        Pin: 686611,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 375,
        Pin: 415705,
        DispatchCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        OriginCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        FacilityCity: "Shringartali",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 376,
        Pin: 209303,
        DispatchCenter: "Akbarpur_NationalHospital_D (Uttar Pradesh)",
        OriginCenter: "Akbarpur_NationalHospital_D (Uttar Pradesh)",
        FacilityCity: "Akbarpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 377,
        Pin: 342002,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 378,
        Pin: 334603,
        DispatchCenter: "Lunkaransar_Ajadcolony_D (Rajasthan)",
        OriginCenter: "Lunkaransar_Ajadcolony_D (Rajasthan)",
        FacilityCity: "Lunkaransar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 379,
        Pin: 722170,
        DispatchCenter: "Bankura_KeranDPP_D (West Bengal)",
        OriginCenter: "Bankura_KeranDPP_D (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 380,
        Pin: 612605,
        DispatchCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        OriginCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 381,
        Pin: 391121,
        DispatchCenter: "Rajpipla_DSPOffice_DPP (Gujarat)",
        OriginCenter: "Rajpipla_DSPOffice_DPP (Gujarat)",
        FacilityCity: "Rajpipla",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 382,
        Pin: 400077,
        DispatchCenter: "Mumbai_Chembur_L (Maharashtra)",
        OriginCenter: "Mumbai_Chembur_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 383,
        Pin: 803111,
        DispatchCenter: "Pavapuri_Jalmandirrd_D (Bihar)",
        OriginCenter: "Pavapuri_Jalmandirrd_D (Bihar)",
        FacilityCity: "Pavapuri",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 384,
        Pin: 758044,
        DispatchCenter: "Kendujhar_Jhumpura_D (Orissa)",
        OriginCenter: "Kendujhar_Jhumpura_D (Orissa)",
        FacilityCity: "Kendujhar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 385,
        Pin: 671316,
        DispatchCenter: "Kanhangad_Ajanur_D (Kerala)",
        OriginCenter: "Kanhangad_Ajanur_D (Kerala)",
        FacilityCity: "Kasaragod",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 386,
        Pin: 843108,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 387,
        Pin: 743129,
        DispatchCenter: "Naihati_Jagatdal_DPP (West Bengal)",
        OriginCenter: "Naihati_Jagatdal_DPP (West Bengal)",
        FacilityCity: "Naihati",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 388,
        Pin: 226013,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 389,
        Pin: 636102,
        DispatchCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        OriginCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        FacilityCity: "Attur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 390,
        Pin: 283141,
        DispatchCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        OriginCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        FacilityCity: "Shikohabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 391,
        Pin: 232118,
        DispatchCenter: "ChakiaUP_Ward5_D (Uttar Pradesh)",
        OriginCenter: "ChakiaUP_Ward5_D (Uttar Pradesh)",
        FacilityCity: "ChakiaUP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 392,
        Pin: 799045,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 393,
        Pin: 769010,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 394,
        Pin: 670649,
        DispatchCenter: "Thalassery_TempleGt_D (Kerala)",
        OriginCenter: "Thalassery_TempleGt_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 395,
        Pin: 530023,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 396,
        Pin: 444006,
        DispatchCenter: "Akola_Midcphase3_H (Maharashtra)",
        OriginCenter: "Akola_Midcphase3_H (Maharashtra)",
        FacilityCity: "Akola",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 397,
        Pin: 843116,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 398,
        Pin: 424203,
        DispatchCenter: "Pachora_AadarshNagar_D (Maharashtra)",
        OriginCenter: "Pachora_AadarshNagar_D (Maharashtra)",
        FacilityCity: "Pachora",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 399,
        Pin: 520005,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 400,
        Pin: 562108,
        DispatchCenter: "Channapatna_2ndCross_D (Karnataka)",
        OriginCenter: "Channapatna_2ndCross_D (Karnataka)",
        FacilityCity: "Channapatna",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 401,
        Pin: 782450,
        DispatchCenter: "KampurTown_Baithalangso_D (Assam)",
        OriginCenter: "KampurTown_Baithalangso_D (Assam)",
        FacilityCity: "Kampur Town",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 402,
        Pin: 328029,
        DispatchCenter: "Dholpur_KamlaColony_D (Rajasthan)",
        OriginCenter: "Dholpur_KamlaColony_D (Rajasthan)",
        FacilityCity: "Dholpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 403,
        Pin: 606709,
        DispatchCenter: "Uthangarai_Singarapettai_D (Tamil Nadu)",
        OriginCenter: "Uthangarai_Singarapettai_D (Tamil Nadu)",
        FacilityCity: "Uthangarai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 404,
        Pin: 604204,
        DispatchCenter: "Chetpet_Savarapoondi_D (Tamil Nadu)",
        OriginCenter: "Chetpet_Savarapoondi_D (Tamil Nadu)",
        FacilityCity: "Chetpet",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 405,
        Pin: 486669,
        DispatchCenter: "Madwas_Majhigawan_D (Madhya Pradesh)",
        OriginCenter: "Madwas_Majhigawan_D (Madhya Pradesh)",
        FacilityCity: "Madwas",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 406,
        Pin: 829125,
        DispatchCenter: "Patratu_Saundabasti_D (Jharkhand)",
        OriginCenter: "Patratu_Saundabasti_D (Jharkhand)",
        FacilityCity: "Patratu",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 407,
        Pin: 110072,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 408,
        Pin: 600060,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 409,
        Pin: 781314,
        DispatchCenter: "Barpeta_Bhera_D (Assam)",
        OriginCenter: "Barpeta_ShaktiNagar_C (Assam)",
        FacilityCity: "Barpeta",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 410,
        Pin: 314031,
        DispatchCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        OriginCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        FacilityCity: "Sagwara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 411,
        Pin: 400612,
        DispatchCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 412,
        Pin: 209506,
        DispatchCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        OriginCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        FacilityCity: "Unnao",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 413,
        Pin: 535532,
        DispatchCenter: "Bobbili_Aguruvidi_D (Andhra Pradesh)",
        OriginCenter: "Bobbili_Aguruvidi_D (Andhra Pradesh)",
        FacilityCity: "Bobbili",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 414,
        Pin: 206125,
        DispatchCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        OriginCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        FacilityCity: "Etawah",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 415,
        Pin: 632101,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 416,
        Pin: 176086,
        DispatchCenter: "Palampur_Saulbanehr_DPP (Himachal Pradesh)",
        OriginCenter: "Palampur_Saulbanehr_DPP (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 417,
        Pin: 843333,
        DispatchCenter: "Pupri_SngihiRD_D (Bihar)",
        OriginCenter: "Pupri_SngihiRD_D (Bihar)",
        FacilityCity: "Pupri",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 418,
        Pin: 141119,
        DispatchCenter: "Malerkotla_Sajidacolony_D (Punjab)",
        OriginCenter: "Malerkotla_Sajidacolony_D (Punjab)",
        FacilityCity: "Malerkotla",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 419,
        Pin: 585330,
        DispatchCenter: "Humnabad_ApmcYard_DPP (Karnataka)",
        OriginCenter: "Humnabad_ApmcYard_DPP (Karnataka)",
        FacilityCity: "Humnabad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 420,
        Pin: 396380,
        DispatchCenter: "Bilimora_DC (Gujarat)",
        OriginCenter: "Bilimora_DC (Gujarat)",
        FacilityCity: "Bilimora",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 421,
        Pin: 285202,
        DispatchCenter: "Orai_Ajnari_D (Uttar Pradesh)",
        OriginCenter: "Orai_Ajnari_D (Uttar Pradesh)",
        FacilityCity: "Orai",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 422,
        Pin: 530041,
        DispatchCenter: "Visakhapatnam_PMPalem_D (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 423,
        Pin: 401103,
        DispatchCenter: "Palghar_Maan_L (Maharashtra)",
        OriginCenter: "Palghar_Maan_L (Maharashtra)",
        FacilityCity: "Palghar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 424,
        Pin: 415729,
        DispatchCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        OriginCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        FacilityCity: "Shringartali",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 425,
        Pin: 759121,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 426,
        Pin: 577436,
        DispatchCenter: "Thirthahalli_Bejjavali2_DPP (Karnataka)",
        OriginCenter: "Thirthahalli_Bejjavali2_DPP (Karnataka)",
        FacilityCity: "Thirthahalli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 427,
        Pin: 636705,
        DispatchCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        OriginCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        FacilityCity: "Dharmapuri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 428,
        Pin: 494229,
        DispatchCenter: "Kondagaon_DNKColony_D (Chhattisgarh)",
        OriginCenter: "Kondagaon_DNKColony_D (Chhattisgarh)",
        FacilityCity: "Kondagaon",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 429,
        Pin: 140301,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 430,
        Pin: 851133,
        DispatchCenter: "DalsinghSarai_Bachhawara_D (Bihar)",
        OriginCenter: "DalsinghSarai_Bachhawara_D (Bihar)",
        FacilityCity: "Dalsingh Sarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 431,
        Pin: 700020,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 432,
        Pin: 572143,
        DispatchCenter: "Hiriyur_Vedvthingr_D (Karnataka)",
        OriginCenter: "Hiriyur_Vedvthingr_D (Karnataka)",
        FacilityCity: "Hiriyur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 433,
        Pin: 423205,
        DispatchCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        OriginCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        FacilityCity: "Dhule",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 434,
        Pin: 133207,
        DispatchCenter: "Ambala_Barara_D (Haryana)",
        OriginCenter: "Ambala_Barara_D (Haryana)",
        FacilityCity: "Ambala",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 435,
        Pin: 686032,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 436,
        Pin: 680546,
        DispatchCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        OriginCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 437,
        Pin: 571107,
        DispatchCenter: "Piriyapatna_Ward2_D (Karnataka)",
        OriginCenter: "Piriyapatna_Ward2_D (Karnataka)",
        FacilityCity: "Piriyapatna",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 438,
        Pin: 783101,
        DispatchCenter: "Goalpara_Baladmri_D (Assam)",
        OriginCenter: "Goalpara_Baladmri_D (Assam)",
        FacilityCity: "Goalpara",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 439,
        Pin: 577229,
        DispatchCenter: "Shimoga_Eshwariah_I (Karnataka)",
        OriginCenter: "Shimoga_Eshwariah_I (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 440,
        Pin: 413228,
        DispatchCenter: "Solapur_Balepeth_I (Maharashtra)",
        OriginCenter: "Solapur_Balepeth_I (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 441,
        Pin: 382481,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 442,
        Pin: 670661,
        DispatchCenter: "Thalassery_TempleGt_D (Kerala)",
        OriginCenter: "Thalassery_TempleGt_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 443,
        Pin: 531087,
        DispatchCenter: "Koyyuru_KoyuruRd_DPP (Andhra Pradesh)",
        OriginCenter: "Koyyuru_KoyuruRd_DPP (Andhra Pradesh)",
        FacilityCity: "Koyyuru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 444,
        Pin: 501218,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 445,
        Pin: 690566,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 446,
        Pin: 382640,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 447,
        Pin: 205303,
        DispatchCenter: "Kishni_Shivmndr_D (Uttar Pradesh)",
        OriginCenter: "Kishni_Shivmndr_D (Uttar Pradesh)",
        FacilityCity: "Kishni",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 448,
        Pin: 193503,
        DispatchCenter: "Bandipora_Onagam_DPP (Jammu & Kashmir)",
        OriginCenter: "Bandipora_Onagam_DPP (Jammu & Kashmir)",
        FacilityCity: "Bandipora",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 449,
        Pin: 444812,
        DispatchCenter: "Morshi_Samarthacolony_D (Maharashtra)",
        OriginCenter: "Morshi_Samarthacolony_D (Maharashtra)",
        FacilityCity: "Morshi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 450,
        Pin: 737106,
        DispatchCenter: "Ranipool_Pakyong_DPP (Sikkim)",
        OriginCenter: "Ranipool_Pakyong_DPP (Sikkim)",
        FacilityCity: "Ranipool",
        FacilityState: "Sikkim",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 451,
        Pin: 604306,
        DispatchCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        OriginCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        FacilityCity: "Tindivanam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 452,
        Pin: 311024,
        DispatchCenter: "Raila_PoliceSt_DPP (Rajasthan)",
        OriginCenter: "Raila_PoliceSt_DPP (Rajasthan)",
        FacilityCity: "Raila",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 453,
        Pin: 416416,
        DispatchCenter: "Sangli_Dattanagar_D (Maharashtra)",
        OriginCenter: "Sangli_Dattanagar_D (Maharashtra)",
        FacilityCity: "Sangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 454,
        Pin: 680504,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 455,
        Pin: 127042,
        DispatchCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        OriginCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        FacilityCity: "Charkhi Dadri",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 456,
        Pin: 689591,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 457,
        Pin: 441217,
        DispatchCenter: "Kurkheda_Gandhiward_DPP (Maharashtra)",
        OriginCenter: "Kurkheda_Gandhiward_DPP (Maharashtra)",
        FacilityCity: "Kurkheda",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 458,
        Pin: 600061,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 459,
        Pin: 222170,
        DispatchCenter: "Kerakat_GasAgency_DPP (Uttar Pradesh)",
        OriginCenter: "Kerakat_GasAgency_DPP (Uttar Pradesh)",
        FacilityCity: "Kerakat",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 460,
        Pin: 412205,
        DispatchCenter: "Khandala_Kapurhol_D (Maharashtra)",
        OriginCenter: "Khandala_Kapurhol_D (Maharashtra)",
        FacilityCity: "Khandala",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 461,
        Pin: 415720,
        DispatchCenter: "Dapoli_Ghimhavane_D (Maharashtra)",
        OriginCenter: "Dapoli_Ghimhavane_D (Maharashtra)",
        FacilityCity: "Dapoli",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 462,
        Pin: 415626,
        DispatchCenter: "Ratnagiri_Mervi_DPP (Maharashtra)",
        OriginCenter: "Ratnagiri_Mervi_DPP (Maharashtra)",
        FacilityCity: "Ratnagiri",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 463,
        Pin: 251307,
        DispatchCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        OriginCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        FacilityCity: "Muzaffarnagar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 464,
        Pin: 396215,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 465,
        Pin: 307802,
        DispatchCenter: "Sirohi_Vedhaynathcolony_D (Rajasthan)",
        OriginCenter: "Sirohi_Vedhaynathcolony_D (Rajasthan)",
        FacilityCity: "Sirohi",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 466,
        Pin: 632203,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 467,
        Pin: 209621,
        DispatchCenter: "Farrukhabad_RamBanquet_D (Uttar Pradesh)",
        OriginCenter: "Farrukhabad_RamBanquet_D (Uttar Pradesh)",
        FacilityCity: "Farrukhabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 468,
        Pin: 441223,
        DispatchCenter: "Sindewahi_ZPSchlDPP_D (Maharashtra)",
        OriginCenter: "Sindewahi_ZPSchlDPP_D (Maharashtra)",
        FacilityCity: "Sindewahi",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 469,
        Pin: 793160,
        DispatchCenter: "Khliehriat_MrktDPP_D (Meghalaya)",
        OriginCenter: "Khliehriat_MrktDPP_D (Meghalaya)",
        FacilityCity: "Khliehriat",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 470,
        Pin: 305625,
        DispatchCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        OriginCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        FacilityCity: "Bijainagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 471,
        Pin: 600035,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 472,
        Pin: 752120,
        DispatchCenter: "Nimapara_Nuharakiri_DPP (Orissa)",
        OriginCenter: "Nimapara_Nuharakiri_DPP (Orissa)",
        FacilityCity: "Nimapara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 473,
        Pin: 623537,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 474,
        Pin: 680561,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 475,
        Pin: 585319,
        DispatchCenter: "Yadgir_Chiranjeevinagar_D (Karnataka)",
        OriginCenter: "Yadgir_Chiranjeevinagar_D (Karnataka)",
        FacilityCity: "Yadgir",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 476,
        Pin: 600115,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 477,
        Pin: 342008,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 478,
        Pin: 424109,
        DispatchCenter: "Manmad_Mokalnagar_DPP (Maharashtra)",
        OriginCenter: "Manmad_Mokalnagar_DPP (Maharashtra)",
        FacilityCity: "Manmad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 479,
        Pin: 202122,
        DispatchCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        OriginCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 480,
        Pin: 639207,
        DispatchCenter: "Gujiliamparai_Palayam_DPP (Tamil Nadu)",
        OriginCenter: "Gujiliamparai_Palayam_DPP (Tamil Nadu)",
        FacilityCity: "Gujiliamparai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 481,
        Pin: 490020,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 482,
        Pin: 680616,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 483,
        Pin: 207302,
        DispatchCenter: "Jalesar_MusiyarRoad_D (Uttar Pradesh)",
        OriginCenter: "Jalesar_MusiyarRoad_D (Uttar Pradesh)",
        FacilityCity: "Jalesar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 484,
        Pin: 580023,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 485,
        Pin: 691578,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 486,
        Pin: 713362,
        DispatchCenter: "Asansol_Addacolony_D (West Bengal)",
        OriginCenter: "Asansol_Addacolony_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 487,
        Pin: 824216,
        DispatchCenter: "AurangabadBR_Katharua_D (Bihar)",
        OriginCenter: "AurangabadBR_Katharua_D (Bihar)",
        FacilityCity: "Aurangabad-BR",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 488,
        Pin: 854202,
        DispatchCenter: "Purnia_Banmankhi_D (Bihar)",
        OriginCenter: "Purnia_Banmankhi_D (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 489,
        Pin: 494556,
        DispatchCenter: "Dantewada_Wardno5_D (Chhattisgarh)",
        OriginCenter: "Dantewada_Wardno5_D (Chhattisgarh)",
        FacilityCity: "Dantewada",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 490,
        Pin: 210424,
        DispatchCenter: "Mahoba_Kiratsagar_D (Uttar Pradesh)",
        OriginCenter: "Mahoba_Kiratsagar_D (Uttar Pradesh)",
        FacilityCity: "Mahoba",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 491,
        Pin: 516155,
        DispatchCenter: "Badvel_Vidyanagar_D (Andhra Pradesh)",
        OriginCenter: "Badvel_Vidyanagar_D (Andhra Pradesh)",
        FacilityCity: "Badvel",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 492,
        Pin: 781344,
        DispatchCenter: "Nalbari_Chokbazar_D (Assam)",
        OriginCenter: "Nalbari_Chokbazar_D (Assam)",
        FacilityCity: "Nalbari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 493,
        Pin: 533297,
        DispatchCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        OriginCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        FacilityCity: "Rajahmundry",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 494,
        Pin: 841426,
        DispatchCenter: "Bhore_Bank_D (Bihar)",
        OriginCenter: "Bhore_Bank_D (Bihar)",
        FacilityCity: "Bhore",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 495,
        Pin: 415410,
        DispatchCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        OriginCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        FacilityCity: "Islampur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 496,
        Pin: 731234,
        DispatchCenter: "Rampurhat_Suri_D (West Bengal)",
        OriginCenter: "Rampurhat_Suri_D (West Bengal)",
        FacilityCity: "Rampurhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 497,
        Pin: 370150,
        DispatchCenter: "Bhachau_Mandivichowk_D (Gujarat)",
        OriginCenter: "Bhachau_Mandivichowk_D (Gujarat)",
        FacilityCity: "Bhachau",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 498,
        Pin: 500049,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 499,
        Pin: 263131,
        DispatchCenter: "Nainital_Malital_D (Uttarakhand)",
        OriginCenter: "Nainital_Malital_D (Uttarakhand)",
        FacilityCity: "Nainital",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 500,
        Pin: 333307,
        DispatchCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        OriginCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        FacilityCity: "Udaipurwati",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 501,
        Pin: 494553,
        DispatchCenter: "Dantewada_Wardno5_D (Chhattisgarh)",
        OriginCenter: "Dantewada_Wardno5_D (Chhattisgarh)",
        FacilityCity: "Dantewada",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 502,
        Pin: 586203,
        DispatchCenter: "BasvanaBgewdi_PostOffice_DPP (Karnataka)",
        OriginCenter: "BasvanaBgewdi_PostOffice_DPP (Karnataka)",
        FacilityCity: "Basavana Bagewadi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 503,
        Pin: 421101,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 504,
        Pin: 145022,
        DispatchCenter: "Pathankot_Dunera_DPP (Punjab)",
        OriginCenter: "Pathankot_Dunera_DPP (Punjab)",
        FacilityCity: "Pathankot",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 505,
        Pin: 741181,
        DispatchCenter: "Plassey_Janakinagar_D (West Bengal)",
        OriginCenter: "Plassey_Janakinagar_D (West Bengal)",
        FacilityCity: "Plassey",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 506,
        Pin: 678558,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 507,
        Pin: 124501,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 508,
        Pin: 607112,
        DispatchCenter: "Panruti_Thiruvathigai_D (Tamil Nadu)",
        OriginCenter: "Panruti_Thiruvathigai_D (Tamil Nadu)",
        FacilityCity: "Panruti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 509,
        Pin: 841409,
        DispatchCenter: "Mohammadpur_DurgaMkt_DPP (Bihar)",
        OriginCenter: "Mohammadpur_DurgaMkt_DPP (Bihar)",
        FacilityCity: "Mohammadpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 510,
        Pin: 585401,
        DispatchCenter: "Bidar_AdarshClny_D (Karnataka)",
        OriginCenter: "Bidar_AdarshClny_D (Karnataka)",
        FacilityCity: "Bidar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 511,
        Pin: 680505,
        DispatchCenter: "Thrissur_Chavakkad_D (Kerala)",
        OriginCenter: "Thrissur_Chavakkad_D (Kerala)",
        FacilityCity: "Guruvayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 512,
        Pin: 560072,
        DispatchCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        OriginCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 513,
        Pin: 466445,
        DispatchCenter: "Hoshangabad_BabaiRd_DPP (Madhya Pradesh)",
        OriginCenter: "Hoshangabad_BabaiRd_DPP (Madhya Pradesh)",
        FacilityCity: "Hoshangabad",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 514,
        Pin: 680553,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 515,
        Pin: 636007,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 516,
        Pin: 847402,
        DispatchCenter: "Phulparas_GhoghardihaRoad_D (Bihar)",
        OriginCenter: "Phulparas_GhoghardihaRoad_D (Bihar)",
        FacilityCity: "Phulparas",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 517,
        Pin: 174320,
        DispatchCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        OriginCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        FacilityCity: "Bangana",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 518,
        Pin: 581234,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 519,
        Pin: 495334,
        DispatchCenter: "Mungeli_PandariaRd_DPP (Chhattisgarh)",
        OriginCenter: "Mungeli_PandariaRd_DPP (Chhattisgarh)",
        FacilityCity: "Mungeli",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 520,
        Pin: 210005,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 521,
        Pin: 614020,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 522,
        Pin: 504307,
        DispatchCenter: "Ichoda_Adegaon_DPP (Telangana)",
        OriginCenter: "Ichoda_Adegaon_DPP (Telangana)",
        FacilityCity: "Ichoda",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 523,
        Pin: 685531,
        DispatchCenter: "Mundakayam_Peermade_D (Kerala)",
        OriginCenter: "Mundakayam_Peermade_D (Kerala)",
        FacilityCity: "Mundakayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 524,
        Pin: 571403,
        DispatchCenter: "Mandya_Dc (Karnataka)",
        OriginCenter: "Mandya_Dc (Karnataka)",
        FacilityCity: "Mandya",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 525,
        Pin: 829150,
        DispatchCenter: "Ramgarh_Mandu_D (Jharkhand)",
        OriginCenter: "Ramgarh_Mandu_D (Jharkhand)",
        FacilityCity: "Ramgarh",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 526,
        Pin: 522646,
        DispatchCenter: "Vinukonda_Thimmayapalem_D (Andhra Pradesh)",
        OriginCenter: "Vinukonda_Thimmayapalem_D (Andhra Pradesh)",
        FacilityCity: "Vinukonda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 527,
        Pin: 781002,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 528,
        Pin: 629203,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 529,
        Pin: 689693,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 530,
        Pin: 532195,
        DispatchCenter: "Tekkali_Tolusurapalli_D (Andhra Pradesh)",
        OriginCenter: "Tekkali_Tolusurapalli_D (Andhra Pradesh)",
        FacilityCity: "Tekkali",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 531,
        Pin: 245205,
        DispatchCenter: "Gajraula_JawaharN_D (Uttar Pradesh)",
        OriginCenter: "Gajraula_JawaharN_D (Uttar Pradesh)",
        FacilityCity: "Gajraula",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 532,
        Pin: 574258,
        DispatchCenter: "Puttur_Darbe_D (Karnataka)",
        OriginCenter: "Puttur_Darbe_D (Karnataka)",
        FacilityCity: "Puttur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 533,
        Pin: 678507,
        DispatchCenter: "Nenmara_Vithanaserry_D (Kerala)",
        OriginCenter: "Nenmara_Vithanaserry_D (Kerala)",
        FacilityCity: "Nenmara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 534,
        Pin: 700076,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 535,
        Pin: 285130,
        DispatchCenter: "Jalaun_Sengarcol_D (Uttar Pradesh)",
        OriginCenter: "Jalaun_Sengarcol_D (Uttar Pradesh)",
        FacilityCity: "Jalaun",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 536,
        Pin: 756039,
        DispatchCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        OriginCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        FacilityCity: "Dantan",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 537,
        Pin: 734221,
        DispatchCenter: "Pokhriabong_PostOffice_DPP (West Bengal)",
        OriginCenter: "Pokhriabong_PostOffice_DPP (West Bengal)",
        FacilityCity: "Pokhriabong",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 538,
        Pin: 683112,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 539,
        Pin: 612302,
        DispatchCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        OriginCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 540,
        Pin: 233226,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 541,
        Pin: 841438,
        DispatchCenter: "Mirganj_Lalmrkt_D (Bihar)",
        OriginCenter: "Mirganj_Lalmrkt_D (Bihar)",
        FacilityCity: "Mirganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 542,
        Pin: 275306,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 543,
        Pin: 303006,
        DispatchCenter: "Dudu_Phagi2_D (Rajasthan)",
        OriginCenter: "Dudu_Phagi2_D (Rajasthan)",
        FacilityCity: "Dudu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 544,
        Pin: 222181,
        DispatchCenter: "Kerakat_GasAgency_DPP (Uttar Pradesh)",
        OriginCenter: "Kerakat_GasAgency_DPP (Uttar Pradesh)",
        FacilityCity: "Kerakat",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 545,
        Pin: 767048,
        DispatchCenter: "Patnagarh_Beherabandh_D (Orissa)",
        OriginCenter: "Patnagarh_Beherabandh_D (Orissa)",
        FacilityCity: "Patnagarh",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 546,
        Pin: 688540,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 547,
        Pin: 224190,
        DispatchCenter: "Tanda_HayatganjChowk_D (Uttar Pradesh)",
        OriginCenter: "Tanda_HayatganjChowk_D (Uttar Pradesh)",
        FacilityCity: "Tanda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 548,
        Pin: 387435,
        DispatchCenter: "Nadiad_Shantinagar_D (Gujarat)",
        OriginCenter: "Nadiad_Shantinagar_D (Gujarat)",
        FacilityCity: "Nadiad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 549,
        Pin: 686504,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 550,
        Pin: 606611,
        DispatchCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        OriginCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        FacilityCity: "Tiruvannamalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 551,
        Pin: 712417,
        DispatchCenter: "Khanakul_Antngr_D (West Bengal)",
        OriginCenter: "Khanakul_Antngr_D (West Bengal)",
        FacilityCity: "Khanakul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 552,
        Pin: 682303,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 553,
        Pin: 691515,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 554,
        Pin: 303907,
        DispatchCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 555,
        Pin: 232103,
        DispatchCenter: "ChakiaUP_Ward5_D (Uttar Pradesh)",
        OriginCenter: "ChakiaUP_Ward5_D (Uttar Pradesh)",
        FacilityCity: "ChakiaUP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 556,
        Pin: 343048,
        DispatchCenter: "Bhinmal_RaniwaraKhurd_D (Rajasthan)",
        OriginCenter: "Bhinmal_RaniwaraKhurd_D (Rajasthan)",
        FacilityCity: "Raniwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 557,
        Pin: 802215,
        DispatchCenter: "Sasaram_Karwandiya_I (Bihar)",
        OriginCenter: "Sasaram_Karwandiya_I (Bihar)",
        FacilityCity: "Sasaram",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 558,
        Pin: 813207,
        DispatchCenter: "Sangrampur_Bhawnipr_D (Bihar)",
        OriginCenter: "Sangrampur_Bhawnipr_D (Bihar)",
        FacilityCity: "Sangrampur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 559,
        Pin: 321024,
        DispatchCenter: "Nagar_BypassRoad_D (Rajasthan)",
        OriginCenter: "Nagar_BypassRoad_D (Rajasthan)",
        FacilityCity: "Nagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 560,
        Pin: 600090,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 561,
        Pin: 212213,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 562,
        Pin: 845454,
        DispatchCenter: "Bettiah_Manshatola_D (Bihar)",
        OriginCenter: "Bettiah_Manshatola_D (Bihar)",
        FacilityCity: "Bettiah",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 563,
        Pin: 627359,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 564,
        Pin: 585322,
        DispatchCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        OriginCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        FacilityCity: "Gulbarga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 565,
        Pin: 385520,
        DispatchCenter: "Palanpur_Esbipura1_D (Gujarat)",
        OriginCenter: "Palanpur_Esbipura1_D (Gujarat)",
        FacilityCity: "Palanpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 566,
        Pin: 636810,
        DispatchCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        OriginCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        FacilityCity: "Dharmapuri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 567,
        Pin: 695316,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 568,
        Pin: 473781,
        DispatchCenter: "Shivpuri_Kolaras_D (Madhya Pradesh)",
        OriginCenter: "Shivpuri_Kolaras_D (Madhya Pradesh)",
        FacilityCity: "Shivpuri",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 569,
        Pin: 505001,
        DispatchCenter: "KarimNagar_Alugunoor_I (Telangana)",
        OriginCenter: "KarimNagar_Alugunoor_I (Telangana)",
        FacilityCity: "Karim Nagar",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 570,
        Pin: 700035,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 571,
        Pin: 795132,
        DispatchCenter: "Imphal_MayangImphal_D (Manipur)",
        OriginCenter: "Imphal_MayangImphal_D (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 572,
        Pin: 632204,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 573,
        Pin: 847231,
        DispatchCenter: "Madhubani_NidhiChowk_D (Bihar)",
        OriginCenter: "Madhubani_NidhiChowk_D (Bihar)",
        FacilityCity: "Madhubani",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 574,
        Pin: 825137,
        DispatchCenter: "Domchanch_Jhumritelaiya_D (Jharkhand)",
        OriginCenter: "Domchanch_Jhumritelaiya_D (Jharkhand)",
        FacilityCity: "Domchanch",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 575,
        Pin: 423402,
        DispatchCenter: "Yeola_Angangaon_D (Maharashtra)",
        OriginCenter: "Yeola_Angangaon_D (Maharashtra)",
        FacilityCity: "Yeola",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 576,
        Pin: 683502,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 577,
        Pin: 563121,
        DispatchCenter: "Bethamangala_Sriramatemp_D (Karnataka)",
        OriginCenter: "Bethamangala_Sriramatemp_D (Karnataka)",
        FacilityCity: "Bethamangala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 578,
        Pin: 246155,
        DispatchCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        OriginCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        FacilityCity: "Gumkhal",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 579,
        Pin: 841428,
        DispatchCenter: "Gopalganj_HarsanHosp_D (Bihar)",
        OriginCenter: "Gopalganj_HarsanHosp_D (Bihar)",
        FacilityCity: "Gopalganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 580,
        Pin: 609401,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 581,
        Pin: 110033,
        DispatchCenter: "Delhi_Libaspur_L (Delhi)",
        OriginCenter: "Delhi_Libaspur_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 582,
        Pin: 522311,
        DispatchCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        OriginCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 583,
        Pin: 402116,
        DispatchCenter: "Nagothane_Balsaital_D (Maharashtra)",
        OriginCenter: "Nagothane_Balsaital_D (Maharashtra)",
        FacilityCity: "Nagothane",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 584,
        Pin: 751008,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 585,
        Pin: 679584,
        DispatchCenter: "Chiramanangad_Veliankode_D (Kerala)",
        OriginCenter: "Chiramanangad_Veliankode_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 586,
        Pin: 700061,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 587,
        Pin: 752094,
        DispatchCenter: "Nayagarh_Durgprsd_D (Orissa)",
        OriginCenter: "Nayagarh_Durgprsd_D (Orissa)",
        FacilityCity: "Nayagarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 588,
        Pin: 422101,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 589,
        Pin: 241501,
        DispatchCenter: "Miyanganj_Bangarmau_D (Uttar Pradesh)",
        OriginCenter: "Miyanganj_Bangarmau_D (Uttar Pradesh)",
        FacilityCity: "Pihani",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 590,
        Pin: 464770,
        DispatchCenter: "Udaipura_Wardno1_DPP (Madhya Pradesh)",
        OriginCenter: "Udaipura_Wardno1_DPP (Madhya Pradesh)",
        FacilityCity: "Udaipura",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 591,
        Pin: 517621,
        DispatchCenter: "Sriklahsti_SDKNgr_D (Andhra Pradesh)",
        OriginCenter: "Sriklahsti_SDKNgr_D (Andhra Pradesh)",
        FacilityCity: "Srikalahasti",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 592,
        Pin: 600010,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 593,
        Pin: 722160,
        DispatchCenter: "Khatra_Simlapal_DPP (West Bengal)",
        OriginCenter: "Khatra_Simlapal_DPP (West Bengal)",
        FacilityCity: "Khatra",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 594,
        Pin: 322702,
        DispatchCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        OriginCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        FacilityCity: "Sawai Madhopur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 595,
        Pin: 783314,
        DispatchCenter: "Dhubri_Tetultol_D (Assam)",
        OriginCenter: "Dhubri_Tetultol_D (Assam)",
        FacilityCity: "Dhubri",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 596,
        Pin: 212011,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 597,
        Pin: 175048,
        DispatchCenter: "Thunag_SBI_D (Himachal Pradesh)",
        OriginCenter: "Thunag_SBI_D (Himachal Pradesh)",
        FacilityCity: "Thunag",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 598,
        Pin: 242307,
        DispatchCenter: "Tilhar_Gurgawancol_D (Uttar Pradesh)",
        OriginCenter: "Tilhar_Gurgawancol_D (Uttar Pradesh)",
        FacilityCity: "Tilhar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 599,
        Pin: 151103,
        DispatchCenter: "RampuraPhul_DC (Punjab)",
        OriginCenter: "RampuraPhul_DC (Punjab)",
        FacilityCity: "Rampura Phul",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 600,
        Pin: 503108,
        DispatchCenter: "Yellareddy_Teachercolony_D (Telangana)",
        OriginCenter: "Yellareddy_Teachercolony_D (Telangana)",
        FacilityCity: "Yellareddy",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 601,
        Pin: 788005,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 602,
        Pin: 673639,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 603,
        Pin: 571126,
        DispatchCenter: "Gundlupet_ChamrajNagar_D (Karnataka)",
        OriginCenter: "Gundlupet_ChamrajNagar_D (Karnataka)",
        FacilityCity: "Gundlupet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 604,
        Pin: 335523,
        DispatchCenter: "Nohar_Professorcolony_DPP (Rajasthan)",
        OriginCenter: "Nohar_Professorcolony_DPP (Rajasthan)",
        FacilityCity: "Nohar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 605,
        Pin: 700028,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 606,
        Pin: 131310,
        DispatchCenter: "Gohana_Adarshnagar_D (Haryana)",
        OriginCenter: "Gohana_Adarshnagar_D (Haryana)",
        FacilityCity: "Gohana",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 607,
        Pin: 402304,
        DispatchCenter: "Nagothane_Balsaital_D (Maharashtra)",
        OriginCenter: "Nagothane_Balsaital_D (Maharashtra)",
        FacilityCity: "Nagothane",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 608,
        Pin: 571401,
        DispatchCenter: "Mandya_Dc (Karnataka)",
        OriginCenter: "Mandya_Dc (Karnataka)",
        FacilityCity: "Mandya",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 609,
        Pin: 246723,
        DispatchCenter: "Bijnor_Garhi_D (Uttar Pradesh)",
        OriginCenter: "Bijnor_Garhi_D (Uttar Pradesh)",
        FacilityCity: "Bijnor",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 610,
        Pin: 221313,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 611,
        Pin: 733210,
        DispatchCenter: "Islampure_Dharampur_D (West Bengal)",
        OriginCenter: "Islampure_Dharampur_D (West Bengal)",
        FacilityCity: "Islampure",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 612,
        Pin: 431131,
        DispatchCenter: "Majalgaon_SmbjiCwk_D (Maharashtra)",
        OriginCenter: "Majalgaon_SmbjiCwk_D (Maharashtra)",
        FacilityCity: "Manjlegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 613,
        Pin: 733130,
        DispatchCenter: "Raiganj_Gobindpur_D (West Bengal)",
        OriginCenter: "Raiganj_Gobindpur_D (West Bengal)",
        FacilityCity: "Raiganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 614,
        Pin: 785101,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 615,
        Pin: 781101,
        DispatchCenter: "Guwahati_Kaikchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 616,
        Pin: 442003,
        DispatchCenter: "Wardha_Alodi_D (Maharashtra)",
        OriginCenter: "Wardha_Alodi_D (Maharashtra)",
        FacilityCity: "Wardha",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 617,
        Pin: 627453,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 618,
        Pin: 602021,
        DispatchCenter: "Tiruvallur_MGMNagar_D (Tamil Nadu)",
        OriginCenter: "Tiruvallur_MGMNagar_D (Tamil Nadu)",
        FacilityCity: "Tiruvallur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 619,
        Pin: 802216,
        DispatchCenter: "Malyabag_ArrahRd_D (Bihar)",
        OriginCenter: "Malyabag_ArrahRd_D (Bihar)",
        FacilityCity: "Malyabag",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 620,
        Pin: 756132,
        DispatchCenter: "Chandbali_Binataro_D (Orissa)",
        OriginCenter: "Chandbali_Binataro_D (Orissa)",
        FacilityCity: "Chandbali",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 621,
        Pin: 695038,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 622,
        Pin: 521190,
        DispatchCenter: "Jaggayyapet_MainRd_DPP (Andhra Pradesh)",
        OriginCenter: "Jaggayyapet_MainRd_DPP (Andhra Pradesh)",
        FacilityCity: "Jaggayyapet",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 623,
        Pin: 800022,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 624,
        Pin: 691554,
        DispatchCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        OriginCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 625,
        Pin: 759104,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 626,
        Pin: 752109,
        DispatchCenter: "Konark_Central_DPP_2 (Orissa)",
        OriginCenter: "Konark_Central_DPP_2 (Orissa)",
        FacilityCity: "Konark",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 627,
        Pin: 785687,
        DispatchCenter: "Nazira_Amina_D (Assam)",
        OriginCenter: "Nazira_Amina_D (Assam)",
        FacilityCity: "Nazira",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 628,
        Pin: 816118,
        DispatchCenter: "Shikaripara_Karakata_D (Jharkhand)",
        OriginCenter: "Shikaripara_Karakata_D (Jharkhand)",
        FacilityCity: "Shikaripara",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 629,
        Pin: 583124,
        DispatchCenter: "Kudligi_Vidyanagar_D (Karnataka)",
        OriginCenter: "Kudligi_Vidyanagar_D (Karnataka)",
        FacilityCity: "Kudligi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 630,
        Pin: 573118,
        DispatchCenter: "Hassan_Nagathavalli_I (Karnataka)",
        OriginCenter: "Hassan_Nagathavalli_I (Karnataka)",
        FacilityCity: "Hassan",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 631,
        Pin: 388550,
        DispatchCenter: "Vatadara_Banejda_DPP (Gujarat)",
        OriginCenter: "Vatadara_Banejda_DPP (Gujarat)",
        FacilityCity: "Vatadara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 632,
        Pin: 203209,
        DispatchCenter: "Jewar_SJRoad_D (Uttar Pradesh)",
        OriginCenter: "Jewar_SJRoad_D (Uttar Pradesh)",
        FacilityCity: "Jewar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 633,
        Pin: 302010,
        DispatchCenter: "Jaipur_RingRoad_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 634,
        Pin: 346004,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 635,
        Pin: 517571,
        DispatchCenter: "Puttur_Artmclny_D (Andhra Pradesh)",
        OriginCenter: "Puttur_Artmclny_D (Andhra Pradesh)",
        FacilityCity: "Puttur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 636,
        Pin: 623202,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 637,
        Pin: 800111,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_PhulwariSharif_L (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 638,
        Pin: 362150,
        DispatchCenter: "Talala_SsnRdDPP_D (Gujarat)",
        OriginCenter: "Talala_SsnRdDPP_D (Gujarat)",
        FacilityCity: "Talala",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 639,
        Pin: 382021,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 640,
        Pin: 361011,
        DispatchCenter: "Dhrol_PGVCLOfc_D (Gujarat)",
        OriginCenter: "Dhrol_PGVCLOfc_D (Gujarat)",
        FacilityCity: "Dhrol",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 641,
        Pin: 506333,
        DispatchCenter: "Narsampet_PakhalRoad_D (Telangana)",
        OriginCenter: "Narsampet_PakhalRoad_D (Telangana)",
        FacilityCity: "Narsampet",
        FacilityState: "Telangana",
        Zone : "S1"
      },
      {
        SN: 642,
        Pin: 502130,
        DispatchCenter: "Medak_RTCclny_D (Telangana)",
        OriginCenter: "Medak_RTCclny_D (Telangana)",
        FacilityCity: "Medak",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 643,
        Pin: 628211,
        DispatchCenter: "Eral_Nazareth_D (Tamil Nadu)",
        OriginCenter: "Eral_Nazareth_D (Tamil Nadu)",
        FacilityCity: "Eral",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 644,
        Pin: 504205,
        DispatchCenter: "Jannaram_HeroMtrsDPP_D (Telangana)",
        OriginCenter: "Jannaram_HeroMtrsDPP_D (Telangana)",
        FacilityCity: "Jannaram",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 645,
        Pin: 458558,
        DispatchCenter: "Mandsaur_Sitamau_DPP (Madhya Pradesh)",
        OriginCenter: "Mandsaur_Sitamau_DPP (Madhya Pradesh)",
        FacilityCity: "Mandsaur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 646,
        Pin: 305406,
        DispatchCenter: "Kekri_LodhaChowk_DPP (Rajasthan)",
        OriginCenter: "Kekri_LodhaChowk_DPP (Rajasthan)",
        FacilityCity: "Kekri",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 647,
        Pin: 140604,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 648,
        Pin: 110502,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 649,
        Pin: 523369,
        DispatchCenter: "Giddalur_Nandyalroad_D (Andhra Pradesh)",
        OriginCenter: "Giddalur_Nandyalroad_D (Andhra Pradesh)",
        FacilityCity: "Giddalur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 650,
        Pin: 175040,
        DispatchCenter: "Sarkaghat_Khelag_D (Himachal Pradesh)",
        OriginCenter: "Sarkaghat_Khelag_D (Himachal Pradesh)",
        FacilityCity: "Sarkaghat",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 651,
        Pin: 473770,
        DispatchCenter: "Shivpuri_Kolaras_D (Madhya Pradesh)",
        OriginCenter: "Shivpuri_Kolaras_D (Madhya Pradesh)",
        FacilityCity: "Shivpuri",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 652,
        Pin: 122208,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 653,
        Pin: 613101,
        DispatchCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        OriginCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        FacilityCity: "Thirukkattupalli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 654,
        Pin: 233229,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 655,
        Pin: 802204,
        DispatchCenter: "Piro_Bachri_D (Bihar)",
        OriginCenter: "Piro_Bachri_D (Bihar)",
        FacilityCity: "Piro",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 656,
        Pin: 784144,
        DispatchCenter: "Mangaldoi_Patharughat_D (Assam)",
        OriginCenter: "Mangaldoi_Patharughat_D (Assam)",
        FacilityCity: "Mangaldoi",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 657,
        Pin: 335038,
        DispatchCenter: "Ganganagar_Ricco_D (Rajasthan)",
        OriginCenter: "Ganganagar_Ricco_D (Rajasthan)",
        FacilityCity: "Ganga Nagar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 658,
        Pin: 522002,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 659,
        Pin: 531126,
        DispatchCenter: "Tuni_Ramakrishnacolony_D (Andhra Pradesh)",
        OriginCenter: "Tuni_Ramakrishnacolony_D (Andhra Pradesh)",
        FacilityCity: "Tuni",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 660,
        Pin: 391170,
        DispatchCenter: "Nasvadi_Kavant_DPP (Gujarat)",
        OriginCenter: "Nasvadi_Kavant_DPP (Gujarat)",
        FacilityCity: "Nasvadi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 661,
        Pin: 723103,
        DispatchCenter: "Purulia_GariDPP_D (West Bengal)",
        OriginCenter: "Purulia_GariDPP_D (West Bengal)",
        FacilityCity: "Purulia",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 662,
        Pin: 262722,
        DispatchCenter: "Dhaurahara_TahsilRD_D (Uttar Pradesh)",
        OriginCenter: "Dhaurahara_TahsilRD_D (Uttar Pradesh)",
        FacilityCity: "Dhaurahara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 663,
        Pin: 676519,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 664,
        Pin: 711101,
        DispatchCenter: "Howrah_Kamrangu_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 665,
        Pin: 141033,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 666,
        Pin: 560044,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 667,
        Pin: 160028,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 668,
        Pin: 491995,
        DispatchCenter: "Kawardha_NndivhrClny_D (Chhattisgarh)",
        OriginCenter: "Kawardha_NndivhrClny_D (Chhattisgarh)",
        FacilityCity: "Kawardha",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 669,
        Pin: 412114,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 670,
        Pin: 391510,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 671,
        Pin: 700115,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 672,
        Pin: 414304,
        DispatchCenter: "Parner_Hindchowk_D (Maharashtra)",
        OriginCenter: "Parner_Hindchowk_D (Maharashtra)",
        FacilityCity: "Parner",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 673,
        Pin: 562158,
        DispatchCenter: "Bangalore_BmsdraPhase3_L (Karnataka)",
        OriginCenter: "Bangalore_BmsdraPhase3_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 674,
        Pin: 342027,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 675,
        Pin: 735229,
        DispatchCenter: "Chalsa_BusStand_D (West Bengal)",
        OriginCenter: "Chalsa_BusStand_D (West Bengal)",
        FacilityCity: "Chalsa",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 676,
        Pin: 443102,
        DispatchCenter: "Malkapur_DwarkaHosp_DPP (Maharashtra)",
        OriginCenter: "Malkapur_DwarkaHosp_DPP (Maharashtra)",
        FacilityCity: "Malkapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 677,
        Pin: 413409,
        DispatchCenter: "Barshi_Indiranagar_D (Maharashtra)",
        OriginCenter: "Barshi_Indiranagar_D (Maharashtra)",
        FacilityCity: "Barshi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 678,
        Pin: 587205,
        DispatchCenter: "Kerur_WardNo2_D (Karnataka)",
        OriginCenter: "Kerur_WardNo2_D (Karnataka)",
        FacilityCity: "Kerur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 679,
        Pin: 224176,
        DispatchCenter: "Rajesultanpur_PadumprMkt_D (Uttar Pradesh)",
        OriginCenter: "Rajesultanpur_PadumprMkt_D (Uttar Pradesh)",
        FacilityCity: "Rajesultanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 680,
        Pin: 713367,
        DispatchCenter: "Asansol_Gobindapur_D (West Bengal)",
        OriginCenter: "Asansol_Gobindapur_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 681,
        Pin: 456441,
        DispatchCenter: "Alote_PanchamViharColony_D (Madhya Pradesh)",
        OriginCenter: "Alote_PanchamViharColony_D (Madhya Pradesh)",
        FacilityCity: "Alote",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 682,
        Pin: 721232,
        DispatchCenter: "Ghatal_KrshDPP_D (West Bengal)",
        OriginCenter: "Ghatal_KrshDPP_D (West Bengal)",
        FacilityCity: "Ghatal",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 683,
        Pin: 441613,
        DispatchCenter: "Gondia_Mundipar_I (Maharashtra)",
        OriginCenter: "Gondia_Mundipar_I (Maharashtra)",
        FacilityCity: "Gondia",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 684,
        Pin: 721446,
        DispatchCenter: "Contai_Fatepur_DPP (West Bengal)",
        OriginCenter: "Contai_Fatepur_DPP (West Bengal)",
        FacilityCity: "RamnagarWB",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 685,
        Pin: 180006,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 686,
        Pin: 497331,
        DispatchCenter: "PatnaCH_Pipardand_D (Chhattisgarh)",
        OriginCenter: "PatnaCH_Pipardand_D (Chhattisgarh)",
        FacilityCity: "Patna-CH",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 687,
        Pin: 502103,
        DispatchCenter: "Siddipet_Vemula_D (Telangana)",
        OriginCenter: "Siddipet_Vemula_D (Telangana)",
        FacilityCity: "Siddipet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 688,
        Pin: 413582,
        DispatchCenter: "Barshi_Indiranagar_D (Maharashtra)",
        OriginCenter: "Barshi_Indiranagar_D (Maharashtra)",
        FacilityCity: "Barshi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 689,
        Pin: 500066,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 690,
        Pin: 122504,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 691,
        Pin: 711001,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 692,
        Pin: 450001,
        DispatchCenter: "Khandwa_TilakNagar_D (Madhya Pradesh)",
        OriginCenter: "Khandwa_TilakNagar_D (Madhya Pradesh)",
        FacilityCity: "Khandwa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 693,
        Pin: 530012,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 694,
        Pin: 444701,
        DispatchCenter: "Amravati_Sashinagar_D (Maharashtra)",
        OriginCenter: "Amravati_Sashinagar_D (Maharashtra)",
        FacilityCity: "Amravati",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 695,
        Pin: 689125,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 696,
        Pin: 431004,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 697,
        Pin: 515611,
        DispatchCenter: "Gooty_ATPRoad_D (Andhra Pradesh)",
        OriginCenter: "Gooty_ATPRoad_D (Andhra Pradesh)",
        FacilityCity: "Gooty",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 698,
        Pin: 224206,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 699,
        Pin: 486886,
        DispatchCenter: "Singrauli_Waidhan2_D (Madhya Pradesh)",
        OriginCenter: "Singrauli_Waidhan2_D (Madhya Pradesh)",
        FacilityCity: "Singrauli",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 700,
        Pin: 365560,
        DispatchCenter: "Rajula_Anandnagar_D (Gujarat)",
        OriginCenter: "Rajula_Anandnagar_D (Gujarat)",
        FacilityCity: "Rajula",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 701,
        Pin: 781305,
        DispatchCenter: "Sarthebari_Tmlichp_D (Assam)",
        OriginCenter: "Sarthebari_Tmlichp_D (Assam)",
        FacilityCity: "Sarthebari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 702,
        Pin: 360370,
        DispatchCenter: "Jetpur_Dasijivanpara_D (Gujarat)",
        OriginCenter: "Jetpur_Dasijivanpara_D (Gujarat)",
        FacilityCity: "Jetpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 703,
        Pin: 396065,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 704,
        Pin: 721242,
        DispatchCenter: "Kamarpukur_ChatiDPP_D (West Bengal)",
        OriginCenter: "Kamarpukur_ChatiDPP_D (West Bengal)",
        FacilityCity: "Kamarpukur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 705,
        Pin: 148107,
        DispatchCenter: "Barnala_DC (Punjab)",
        OriginCenter: "Barnala_DC (Punjab)",
        FacilityCity: "Barnala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 706,
        Pin: 754226,
        DispatchCenter: "Garjanpur_Gossei_D (Orissa)",
        OriginCenter: "Garjanpur_Gossei_D (Orissa)",
        FacilityCity: "Garjanpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 707,
        Pin: 388131,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 708,
        Pin: 733153,
        DispatchCenter: "Balurghat_AkhirDPP_D (West Bengal)",
        OriginCenter: "Balurghat_AkhirDPP_D (West Bengal)",
        FacilityCity: "Balurghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 709,
        Pin: 680103,
        DispatchCenter: "Thrissur_Koonammoochi_D (Kerala)",
        OriginCenter: "Thrissur_Koonammoochi_D (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 710,
        Pin: 522213,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 711,
        Pin: 516411,
        DispatchCenter: "Chagalamarri_TeluguGanga_DPP (Andhra Pradesh)",
        OriginCenter: "Chagalamarri_TeluguGanga_DPP (Andhra Pradesh)",
        FacilityCity: "Chagalamarri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 712,
        Pin: 521214,
        DispatchCenter: "Vissannapetaa_TiruvuruRd_D (Andhra Pradesh)",
        OriginCenter: "Vissannapetaa_TiruvuruRd_D (Andhra Pradesh)",
        FacilityCity: "Vissannapetaa",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 713,
        Pin: 686038,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 714,
        Pin: 401105,
        DispatchCenter: "Mumbai_Kandivali_L (Maharashtra)",
        OriginCenter: "Mumbai_Kandivali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 715,
        Pin: 482020,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 716,
        Pin: 177501,
        DispatchCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        OriginCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        FacilityCity: "Hamirpur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 717,
        Pin: 185131,
        DispatchCenter: "Rajouri_Chhabardhara_D (Jammu & Kashmir)",
        OriginCenter: "Rajouri_Chhabardhara_D (Jammu & Kashmir)",
        FacilityCity: "Rajouri",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 718,
        Pin: 303905,
        DispatchCenter: "Jaipur_Ramchandpura_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 719,
        Pin: 493891,
        DispatchCenter: "Gohrapadar_Chhaildongri_DPP (Chhattisgarh)",
        OriginCenter: "Gohrapadar_Chhaildongri_DPP (Chhattisgarh)",
        FacilityCity: "Gohrapadar",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 720,
        Pin: 721404,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 721,
        Pin: 211002,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 722,
        Pin: 603109,
        DispatchCenter: "Tirukalukundram_Kalpakkam_D (Tamil Nadu)",
        OriginCenter: "Tirukalukundram_Kalpakkam_D (Tamil Nadu)",
        FacilityCity: "Tirukalukundram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 723,
        Pin: 584140,
        DispatchCenter: "Raichur_Wadloor_I (Karnataka)",
        OriginCenter: "Raichur_Wadloor_I (Karnataka)",
        FacilityCity: "Raichur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 724,
        Pin: 854311,
        DispatchCenter: "Araria_BusStand_D (Bihar)",
        OriginCenter: "Araria_BusStand_D (Bihar)",
        FacilityCity: "Araria",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 725,
        Pin: 848101,
        DispatchCenter: "Samastipur_RhmprRdli_D (Bihar)",
        OriginCenter: "Samastipur_RhmprRdli_D (Bihar)",
        FacilityCity: "Samastipur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 726,
        Pin: 502371,
        DispatchCenter: "Narayankhed_Turkapally_D (Telangana)",
        OriginCenter: "Narayankhed_Turkapally_D (Telangana)",
        FacilityCity: "Narayankhed",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 727,
        Pin: 415709,
        DispatchCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        OriginCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        FacilityCity: "Khed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 728,
        Pin: 284406,
        DispatchCenter: "Mahrauni_Kuwanghosi_D (Uttar Pradesh)",
        OriginCenter: "Mahrauni_Kuwanghosi_D (Uttar Pradesh)",
        FacilityCity: "Mahrauni",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 729,
        Pin: 362269,
        DispatchCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        OriginCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        FacilityCity: "Veraval",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 730,
        Pin: 606804,
        DispatchCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        OriginCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        FacilityCity: "Tiruvannamalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 731,
        Pin: 503111,
        DispatchCenter: "Kamareddy_KPRcolony_D (Telangana)",
        OriginCenter: "Kamareddy_KPRcolony_D (Telangana)",
        FacilityCity: "Kamareddy",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 732,
        Pin: 642129,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 733,
        Pin: 273155,
        DispatchCenter: "Anandnagar_DhniDhla_DPP (Uttar Pradesh)",
        OriginCenter: "Anandnagar_DhniDhla_DPP (Uttar Pradesh)",
        FacilityCity: "Anandnagar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 734,
        Pin: 686016,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 735,
        Pin: 485771,
        DispatchCenter: "Maihar_Harnampur_D (Madhya Pradesh)",
        OriginCenter: "Maihar_Harnampur_D (Madhya Pradesh)",
        FacilityCity: "Maihar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 736,
        Pin: 641015,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 737,
        Pin: 396051,
        DispatchCenter: "Valsad_Dharampur_D (Gujarat)",
        OriginCenter: "Valsad_Dharampur_D (Gujarat)",
        FacilityCity: "Valsad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 738,
        Pin: 691001,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 739,
        Pin: 244302,
        DispatchCenter: "Sambhal_DurgaColny_D (Uttar Pradesh)",
        OriginCenter: "Sambhal_DurgaColny_D (Uttar Pradesh)",
        FacilityCity: "Sambhal",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 740,
        Pin: 673592,
        DispatchCenter: "SultanBathery_Kolagapara_D (Kerala)",
        OriginCenter: "SultanBathery_Kolagapara_D (Kerala)",
        FacilityCity: "Sultan Bathery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 741,
        Pin: 829114,
        DispatchCenter: "Phusro_RhmgjDPP_D (Jharkhand)",
        OriginCenter: "Phusro_RhmgjDPP_D (Jharkhand)",
        FacilityCity: "Phusro",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 742,
        Pin: 764063,
        DispatchCenter: "Nabarangpr_PhdofDPP_D (Orissa)",
        OriginCenter: "Nabarangpr_PhdofDPP_D (Orissa)",
        FacilityCity: "Nabarangapur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 743,
        Pin: 673542,
        DispatchCenter: "Vadakara_Veerancheri_D (Kerala)",
        OriginCenter: "Vadakara_Veerancheri_D (Kerala)",
        FacilityCity: "Vadakara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 744,
        Pin: 611104,
        DispatchCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        OriginCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        FacilityCity: "Nagapattinam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 745,
        Pin: 628712,
        DispatchCenter: "Vilathikulam_Thoothukudi_DPP (Tamil Nadu)",
        OriginCenter: "Vilathikulam_Thoothukudi_DPP (Tamil Nadu)",
        FacilityCity: "Vilathikulam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 746,
        Pin: 244235,
        DispatchCenter: "Gajraula_JawaharN_D (Uttar Pradesh)",
        OriginCenter: "Gajraula_JawaharN_D (Uttar Pradesh)",
        FacilityCity: "Gajraula",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 747,
        Pin: 752014,
        DispatchCenter: "Puri_Purussottamnagar_D (Orissa)",
        OriginCenter: "Puri_Purussottamnagar_D (Orissa)",
        FacilityCity: "Puri",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 748,
        Pin: 306902,
        DispatchCenter: "Sumerpur_RgukulColny_DPP (Rajasthan)",
        OriginCenter: "Sumerpur_RgukulColny_DPP (Rajasthan)",
        FacilityCity: "Sumerpur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 749,
        Pin: 832107,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 750,
        Pin: 690010,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 751,
        Pin: 229881,
        DispatchCenter: "Malihabad_Amanignj_DPP (Uttar Pradesh)",
        OriginCenter: "Malihabad_Amanignj_DPP (Uttar Pradesh)",
        FacilityCity: "Malihabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 752,
        Pin: 756129,
        DispatchCenter: "Tihidi_Hanspat_DPP (Orissa)",
        OriginCenter: "Tihidi_Hanspat_DPP (Orissa)",
        FacilityCity: "Tihidi",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 753,
        Pin: 441924,
        DispatchCenter: "Bhandara_RjnaiDPP_D (Maharashtra)",
        OriginCenter: "Bhandara_RjnaiDPP_D (Maharashtra)",
        FacilityCity: "Bhandara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 754,
        Pin: 452015,
        DispatchCenter: "Indore_BarodaArjun_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 755,
        Pin: 416603,
        DispatchCenter: "Oras_Ranbambuli_D (Maharashtra)",
        OriginCenter: "Oras_Ranbambuli_D (Maharashtra)",
        FacilityCity: "Oras",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 756,
        Pin: 761028,
        DispatchCenter: "Behrampur_Kanmna_D (Orissa)",
        OriginCenter: "Behrampur_Kanmna_D (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 757,
        Pin: 735231,
        DispatchCenter: "Jholung_Gorubathan_DPP (West Bengal)",
        OriginCenter: "Jholung_Gorubathan_DPP (West Bengal)",
        FacilityCity: "Jholung",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 758,
        Pin: 458669,
        DispatchCenter: "Mandsaur_Dalodarail_D (Madhya Pradesh)",
        OriginCenter: "Mandsaur_Dalodarail_D (Madhya Pradesh)",
        FacilityCity: "Mandsaur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 759,
        Pin: 403518,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 760,
        Pin: 445216,
        DispatchCenter: "Pusad_Central_DPP_2 (Maharashtra)",
        OriginCenter: "Pusad_Central_DPP_2 (Maharashtra)",
        FacilityCity: "Pusad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 761,
        Pin: 570014,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 762,
        Pin: 782135,
        DispatchCenter: "Puranaigudam_Gomotha_DPP (Assam)",
        OriginCenter: "Puranaigudam_Gomotha_DPP (Assam)",
        FacilityCity: "Puranaigudam",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 763,
        Pin: 341031,
        DispatchCenter: "Merta_Riyanbari_DPP (Rajasthan)",
        OriginCenter: "Merta_Riyanbari_DPP (Rajasthan)",
        FacilityCity: "Merta",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 764,
        Pin: 825315,
        DispatchCenter: "Phusro_Gumia_DPP (Jharkhand)",
        OriginCenter: "Phusro_Gumia_DPP (Jharkhand)",
        FacilityCity: "Phusro",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 765,
        Pin: 638052,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 766,
        Pin: 193198,
        DispatchCenter: "Srinagar_Pattan_DPP (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Pattan_DPP (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 767,
        Pin: 534186,
        DispatchCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        FacilityCity: "Bhimavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 768,
        Pin: 302027,
        DispatchCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        OriginCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 769,
        Pin: 305817,
        DispatchCenter: "Kishangarh_AirportRd_D (Rajasthan)",
        OriginCenter: "Kishangarh_AirportRd_D (Rajasthan)",
        FacilityCity: "Kishangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 770,
        Pin: 123504,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 771,
        Pin: 848102,
        DispatchCenter: "Samastipur_RhmprRdli_D (Bihar)",
        OriginCenter: "Samastipur_RhmprRdli_D (Bihar)",
        FacilityCity: "Samastipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 772,
        Pin: 571108,
        DispatchCenter: "Piriyapatna_Ward2_D (Karnataka)",
        OriginCenter: "Piriyapatna_Ward2_D (Karnataka)",
        FacilityCity: "Piriyapatna",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 773,
        Pin: 226022,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 774,
        Pin: 585412,
        DispatchCenter: "Humnabad_ApmcYard_DPP (Karnataka)",
        OriginCenter: "Humnabad_ApmcYard_DPP (Karnataka)",
        FacilityCity: "Humnabad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 775,
        Pin: 620023,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 776,
        Pin: 202170,
        DispatchCenter: "SikandraRao_RadhaNagar_D (Uttar Pradesh)",
        OriginCenter: "SikandraRao_RadhaNagar_D (Uttar Pradesh)",
        FacilityCity: "Sikandra Rao",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 777,
        Pin: 444402,
        DispatchCenter: "Mangrulpir_PostOffice_DPP (Maharashtra)",
        OriginCenter: "Mangrulpir_PostOffice_DPP (Maharashtra)",
        FacilityCity: "Mangrulpir",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 778,
        Pin: 822122,
        DispatchCenter: "Panki_Jarhichowk_D (Jharkhand)",
        OriginCenter: "Panki_Jarhichowk_D (Jharkhand)",
        FacilityCity: "Panki",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 779,
        Pin: 400611,
        DispatchCenter: "Mumbai_Turbhe_L (Maharashtra)",
        OriginCenter: "Mumbai_Turbhe_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 780,
        Pin: 313901,
        DispatchCenter: "Udaipur_Tidi_D (Rajasthan)",
        OriginCenter: "Udaipur_Tidi_D (Rajasthan)",
        FacilityCity: "Udaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 781,
        Pin: 212216,
        DispatchCenter: "SarayAnkil_Fkrbd_D (Uttar Pradesh)",
        OriginCenter: "SarayAnkil_Fkrbd_D (Uttar Pradesh)",
        FacilityCity: "Saray Ankil",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 782,
        Pin: 700123,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 783,
        Pin: 147111,
        DispatchCenter: "Patiala_UpkarNagar_D (Punjab)",
        OriginCenter: "Patiala_UpkarNagar_D (Punjab)",
        FacilityCity: "Patiala",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 784,
        Pin: 624204,
        DispatchCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        OriginCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        FacilityCity: "Vadipatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 785,
        Pin: 425413,
        DispatchCenter: "Rumkitalav_Kukarmunda1_DPP (Gujarat)",
        OriginCenter: "Rumkitalav_Kukarmunda1_DPP (Gujarat)",
        FacilityCity: "Rumkitalav",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 786,
        Pin: 803206,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 787,
        Pin: 571128,
        DispatchCenter: "Chamarajanagar_Galipur_D (Karnataka)",
        OriginCenter: "Chamarajanagar_Galipur_D (Karnataka)",
        FacilityCity: "Chamarajanagar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 788,
        Pin: 509201,
        DispatchCenter: "Achampet_Madhuranagar_D (Telangana)",
        OriginCenter: "Achampet_Madhuranagar_D (Telangana)",
        FacilityCity: "Achampet",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 789,
        Pin: 577140,
        DispatchCenter: "Kadur_UBRoad_D (Karnataka)",
        OriginCenter: "Kadur_UBRoad_D (Karnataka)",
        FacilityCity: "Kadur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 790,
        Pin: 262551,
        DispatchCenter: "Didihat_PantGaon_DPP (Uttarakhand)",
        OriginCenter: "Didihat_PantGaon_DPP (Uttarakhand)",
        FacilityCity: "Didihat",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 791,
        Pin: 172110,
        DispatchCenter: "Pooh_Spillowkhas_DPP (Himachal Pradesh)",
        OriginCenter: "Pooh_Spillowkhas_DPP (Himachal Pradesh)",
        FacilityCity: "Pooh",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 792,
        Pin: 530020,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 793,
        Pin: 523213,
        DispatchCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        OriginCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        FacilityCity: "Ongole",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 794,
        Pin: 201300,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 795,
        Pin: 492009,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 796,
        Pin: 521139,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 797,
        Pin: 721432,
        DispatchCenter: "Bajkul_Kismat_D (West Bengal)",
        OriginCenter: "Bajkul_Kismat_D (West Bengal)",
        FacilityCity: "Bajkul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 798,
        Pin: 577553,
        DispatchCenter: "Jagaluru_Tumatelayout_D (Karnataka)",
        OriginCenter: "Jagaluru_Tumatelayout_D (Karnataka)",
        FacilityCity: "Jagaluru",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 799,
        Pin: 723127,
        DispatchCenter: "Balarampur_Rangadih_D (West Bengal)",
        OriginCenter: "Balarampur_Rangadih_D (West Bengal)",
        FacilityCity: "Balarampur-WB",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 800,
        Pin: 522018,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 801,
        Pin: 700063,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 802,
        Pin: 683542,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 803,
        Pin: 452010,
        DispatchCenter: "Indore_BarodaArjun_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 804,
        Pin: 607103,
        DispatchCenter: "Panruti_Thiruvathigai_D (Tamil Nadu)",
        OriginCenter: "Panruti_Thiruvathigai_D (Tamil Nadu)",
        FacilityCity: "Panruti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 805,
        Pin: 453555,
        DispatchCenter: "Indore_BarodaArjun_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 806,
        Pin: 524142,
        DispatchCenter: "Kavali_Musunur_D (Andhra Pradesh)",
        OriginCenter: "Kavali_Musunur_D (Andhra Pradesh)",
        FacilityCity: "Kavali",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 807,
        Pin: 824114,
        DispatchCenter: "Goh_Golapar_D (Bihar)",
        OriginCenter: "Goh_Golapar_D (Bihar)",
        FacilityCity: "Goh",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 808,
        Pin: 611108,
        DispatchCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        OriginCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        FacilityCity: "Nagapattinam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 809,
        Pin: 614801,
        DispatchCenter: "Pudukkottai_Lnpuram_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Lnpuram_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 810,
        Pin: 121013,
        DispatchCenter: "Faridabad_MathuraRoad_L (Haryana)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 811,
        Pin: 342023,
        DispatchCenter: "Balesar_Unthwaliya_DPP (Rajasthan)",
        OriginCenter: "Balesar_Unthwaliya_DPP (Rajasthan)",
        FacilityCity: "Balesar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 812,
        Pin: 625205,
        DispatchCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        OriginCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        FacilityCity: "Vadipatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 813,
        Pin: 210206,
        DispatchCenter: "Chitrakoot_Khatawara_D (Uttar Pradesh)",
        OriginCenter: "Chitrakoot_Khatawara_D (Uttar Pradesh)",
        FacilityCity: "Chitrakoot",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 814,
        Pin: 360576,
        DispatchCenter: "Porbandar_Vadiplot_D (Gujarat)",
        OriginCenter: "Porbandar_Vadiplot_D (Gujarat)",
        FacilityCity: "Porbandar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 815,
        Pin: 782428,
        DispatchCenter: "KampurTown_Sorupathar_D (Assam)",
        OriginCenter: "KampurTown_Sorupathar_D (Assam)",
        FacilityCity: "Kampur Town",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 816,
        Pin: 753127,
        DispatchCenter: "Jalpaiguri_Gomostapara_D (West Bengal)",
        OriginCenter: "Jalpaiguri_Gomostapara_D (West Bengal)",
        FacilityCity: "Jalpaiguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 817,
        Pin: 172116,
        DispatchCenter: "Pooh_Spillowkhas_DPP (Himachal Pradesh)",
        OriginCenter: "Pooh_Spillowkhas_DPP (Himachal Pradesh)",
        FacilityCity: "Pooh",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 818,
        Pin: 614804,
        DispatchCenter: "Peravurani_Nattanikkottai_D (Tamil Nadu)",
        OriginCenter: "Peravurani_Nattanikkottai_D (Tamil Nadu)",
        FacilityCity: "Peravurani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 819,
        Pin: 635303,
        DispatchCenter: "Harur_EBOfc_D (Tamil Nadu)",
        OriginCenter: "Harur_EBOfc_D (Tamil Nadu)",
        FacilityCity: "Harur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 820,
        Pin: 392003,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 821,
        Pin: 124146,
        DispatchCenter: "Rewari_Kosli_D (Haryana)",
        OriginCenter: "Rewari_Kosli_D (Haryana)",
        FacilityCity: "Jhajjar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 822,
        Pin: 241121,
        DispatchCenter: "Hardoi_AvsVikasColny_D (Uttar Pradesh)",
        OriginCenter: "Hardoi_AvsVikasColny_D (Uttar Pradesh)",
        FacilityCity: "Hardoi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 823,
        Pin: 415613,
        DispatchCenter: "Ganpatipule_Rahataghar_DPP (Maharashtra)",
        OriginCenter: "Ganpatipule_Rahataghar_DPP (Maharashtra)",
        FacilityCity: "Ganpatipule",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 824,
        Pin: 788726,
        DispatchCenter: "Patharkandi_CntrlSchl_D (Assam)",
        OriginCenter: "Patharkandi_CntrlSchl_D (Assam)",
        FacilityCity: "Patharkandi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 825,
        Pin: 173208,
        DispatchCenter: "Darlaghat_Central_D_1 (Himachal Pradesh)",
        OriginCenter: "Darlaghat_Central_D_1 (Himachal Pradesh)",
        FacilityCity: "Darlaghat",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 826,
        Pin: 580027,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 827,
        Pin: 389352,
        DispatchCenter: "Halol_DhavalPark_D (Gujarat)",
        OriginCenter: "Halol_DhavalPark_D (Gujarat)",
        FacilityCity: "Halol",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 828,
        Pin: 462045,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 829,
        Pin: 496551,
        DispatchCenter: "Baramkela_ChndrprRd_DPP (Chhattisgarh)",
        OriginCenter: "Baramkela_ChndrprRd_DPP (Chhattisgarh)",
        FacilityCity: "Baramkela",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 830,
        Pin: 457002,
        DispatchCenter: "Ratlam_MhowRd_D (Madhya Pradesh)",
        OriginCenter: "Ratlam_MhowRd_D (Madhya Pradesh)",
        FacilityCity: "Ratlam",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 831,
        Pin: 532455,
        DispatchCenter: "KotturuAP_NivagamRd_DPP (Andhra Pradesh)",
        OriginCenter: "KotturuAP_NivagamRd_DPP (Andhra Pradesh)",
        FacilityCity: "Kotturu-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 832,
        Pin: 627131,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 833,
        Pin: 221010,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 834,
        Pin: 676309,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 835,
        Pin: 688554,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 836,
        Pin: 754001,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 837,
        Pin: 733134,
        DispatchCenter: "Raiganj_SubrtDPP_D (West Bengal)",
        OriginCenter: "Raiganj_SubrtDPP_D (West Bengal)",
        FacilityCity: "Raiganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 838,
        Pin: 761045,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 839,
        Pin: 639114,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 840,
        Pin: 685615,
        DispatchCenter: "Munnar_IkkaNgr_D (Kerala)",
        OriginCenter: "Munnar_IkkaNgr_D (Kerala)",
        FacilityCity: "Munnar",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 841,
        Pin: 607302,
        DispatchCenter: "Neyveli_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Neyveli_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Neyveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 842,
        Pin: 382423,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 843,
        Pin: 244242,
        DispatchCenter: "Sambhal_DurgaColny_D (Uttar Pradesh)",
        OriginCenter: "Sambhal_DurgaColny_D (Uttar Pradesh)",
        FacilityCity: "Sambhal",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 844,
        Pin: 413623,
        DispatchCenter: "Osmanabad_Tuljapur_D (Maharashtra)",
        OriginCenter: "Osmanabad_Tuljapur_D (Maharashtra)",
        FacilityCity: "Osmanabad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 845,
        Pin: 496242,
        DispatchCenter: "Kunkuri_Tapkara_D (Chhattisgarh)",
        OriginCenter: "Kunkuri_Tapkara_D (Chhattisgarh)",
        FacilityCity: "Kunkuri",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 846,
        Pin: 577230,
        DispatchCenter: "Honnali_TMroad_D (Karnataka)",
        OriginCenter: "Honnali_TMroad_D (Karnataka)",
        FacilityCity: "Honnali",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 847,
        Pin: 321023,
        DispatchCenter: "Kaman_BsstdDPP_D (Rajasthan)",
        OriginCenter: "Kaman_BsstdDPP_D (Rajasthan)",
        FacilityCity: "Kaman",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 848,
        Pin: 591262,
        DispatchCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        OriginCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        FacilityCity: "Athani",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 849,
        Pin: 462043,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 850,
        Pin: 635809,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 851,
        Pin: 686585,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 852,
        Pin: 713315,
        DispatchCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        OriginCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        FacilityCity: "Rupnarayanpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 853,
        Pin: 285223,
        DispatchCenter: "Konch_Central_D_2 (Uttar Pradesh)",
        OriginCenter: "Konch_Central_D_2 (Uttar Pradesh)",
        FacilityCity: "Konch",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 854,
        Pin: 474003,
        DispatchCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        OriginCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        FacilityCity: "Gwalior",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 855,
        Pin: 323601,
        DispatchCenter: "Keshoraipatan_Ishwar_DPP (Rajasthan)",
        OriginCenter: "Keshoraipatan_Ishwar_DPP (Rajasthan)",
        FacilityCity: "Keshoraipatan",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 856,
        Pin: 224227,
        DispatchCenter: "Akbarpur_AmbedkarNgr_D (Uttar Pradesh)",
        OriginCenter: "Akbarpur_AmbedkarNgr_D (Uttar Pradesh)",
        FacilityCity: "Akbarpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 857,
        Pin: 731240,
        DispatchCenter: "Nanoor_Central_DPP_1 (West Bengal)",
        OriginCenter: "Nanoor_Central_DPP_1 (West Bengal)",
        FacilityCity: "Nanoor",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 858,
        Pin: 126113,
        DispatchCenter: "Jind_SunderNagar_D (Haryana)",
        OriginCenter: "Jind_SunderNagar_D (Haryana)",
        FacilityCity: "Jind",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 859,
        Pin: 388315,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 860,
        Pin: 688511,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 861,
        Pin: 142036,
        DispatchCenter: "Jagraon_Grainmkt_D (Punjab)",
        OriginCenter: "Jagraon_Grainmkt_D (Punjab)",
        FacilityCity: "Jagraon",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 862,
        Pin: 614903,
        DispatchCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        OriginCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        FacilityCity: "Pattukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 863,
        Pin: 521202,
        DispatchCenter: "Nuzvid_NSPColony_D (Andhra Pradesh)",
        OriginCenter: "Nuzvid_NSPColony_D (Andhra Pradesh)",
        FacilityCity: "Nuzvid",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 864,
        Pin: 413503,
        DispatchCenter: "Bhoom_KusumNagar_D (Maharashtra)",
        OriginCenter: "Bhoom_KusumNagar_D (Maharashtra)",
        FacilityCity: "Bhoom",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 865,
        Pin: 302041,
        DispatchCenter: "Jaipur_RingRoad_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 866,
        Pin: 600117,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 867,
        Pin: 631003,
        DispatchCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 868,
        Pin: 581301,
        DispatchCenter: "Karwar_Sadashivgad_D (Karnataka)",
        OriginCenter: "Karwar_Sadashivgad_D (Karnataka)",
        FacilityCity: "Karwar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 869,
        Pin: 732206,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 870,
        Pin: 343027,
        DispatchCenter: "Sanchore_Lakshmingr_D (Rajasthan)",
        OriginCenter: "Sanchore_Lakshmingr_D (Rajasthan)",
        FacilityCity: "Sanchore",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 871,
        Pin: 515124,
        DispatchCenter: "Puttaprthi_Penukonda_D (Andhra Pradesh)",
        OriginCenter: "Puttaprthi_Penukonda_D (Andhra Pradesh)",
        FacilityCity: "Puttaparthi",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 872,
        Pin: 625122,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 873,
        Pin: 689531,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 874,
        Pin: 603202,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 875,
        Pin: 203391,
        DispatchCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        OriginCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        FacilityCity: "Anupshahar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 876,
        Pin: 721134,
        DispatchCenter: "Kolaghat_Central_DPP_2 (West Bengal)",
        OriginCenter: "Kolaghat_Central_DPP_2 (West Bengal)",
        FacilityCity: "Kolaghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 877,
        Pin: 442705,
        DispatchCenter: "Aheri_ChinchgndiRd_DPP (Maharashtra)",
        OriginCenter: "Aheri_ChinchgndiRd_DPP (Maharashtra)",
        FacilityCity: "Aheri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 878,
        Pin: 632527,
        DispatchCenter: "Cheyyar_NehruNGR_D (Tamil Nadu)",
        OriginCenter: "Cheyyar_NehruNGR_D (Tamil Nadu)",
        FacilityCity: "Cheyyar",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 879,
        Pin: 416117,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 880,
        Pin: 841401,
        DispatchCenter: "Marhowarah_StatinRD_D (Bihar)",
        OriginCenter: "Marhowarah_StatinRD_D (Bihar)",
        FacilityCity: "Marhowarah",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 881,
        Pin: 229311,
        DispatchCenter: "Bacchawaran_BhuyianTemple_D (Uttar Pradesh)",
        OriginCenter: "Bacchawaran_BhuyianTemple_D (Uttar Pradesh)",
        FacilityCity: "Bacchawaran",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 882,
        Pin: 612104,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 883,
        Pin: 571248,
        DispatchCenter: "Madikeri_Ranipet_D (Karnataka)",
        OriginCenter: "Madikeri_Ranipet_D (Karnataka)",
        FacilityCity: "Madikeri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 884,
        Pin: 621731,
        DispatchCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        OriginCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        FacilityCity: "Ariyalur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 885,
        Pin: 382024,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 886,
        Pin: 700079,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 887,
        Pin: 110092,
        DispatchCenter: "Delhi_RailYardRd_L (Delhi)",
        OriginCenter: "Delhi_RailYardRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 888,
        Pin: 517234,
        DispatchCenter: "Pileru_Plot14_D (Andhra Pradesh)",
        OriginCenter: "Pileru_Plot14_D (Andhra Pradesh)",
        FacilityCity: "Pileru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 889,
        Pin: 754228,
        DispatchCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        OriginCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 890,
        Pin: 641408,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 891,
        Pin: 228125,
        DispatchCenter: "Kumarganj_Siddhiganeshpur_D (Uttar Pradesh)",
        OriginCenter: "Kumarganj_Siddhiganeshpur_D (Uttar Pradesh)",
        FacilityCity: "Kumarganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 892,
        Pin: 673649,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 893,
        Pin: 385360,
        DispatchCenter: "Radhanpur_MasaliRd_DPP (Gujarat)",
        OriginCenter: "Radhanpur_MasaliRd_DPP (Gujarat)",
        FacilityCity: "Radhanpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 894,
        Pin: 802131,
        DispatchCenter: "Buxar_Ashaparari_DPP (Bihar)",
        OriginCenter: "Buxar_Ashaparari_DPP (Bihar)",
        FacilityCity: "Buxar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 895,
        Pin: 173023,
        DispatchCenter: "Haripurdhar_Bhallona_D (Himachal Pradesh)",
        OriginCenter: "Haripurdhar_Bhallona_D (Himachal Pradesh)",
        FacilityCity: "Haripurdhar",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 896,
        Pin: 802162,
        DispatchCenter: "Jagdishpur_Ward14_D (Bihar)",
        OriginCenter: "Jagdishpur_Ward14_D (Bihar)",
        FacilityCity: "Jagdishpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 897,
        Pin: 380053,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 898,
        Pin: 581319,
        DispatchCenter: "Ankola_Madangeri_D (Karnataka)",
        OriginCenter: "Ankola_Madangeri_D (Karnataka)",
        FacilityCity: "Ankola",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 899,
        Pin: 534206,
        DispatchCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        FacilityCity: "Bhimavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 900,
        Pin: 625021,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 901,
        Pin: 828116,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 902,
        Pin: 600097,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 903,
        Pin: 203145,
        DispatchCenter: "Bulndshahr_Ymunpurm_D (Uttar Pradesh)",
        OriginCenter: "Bulndshahr_Ymunpurm_D (Uttar Pradesh)",
        FacilityCity: "Bulandshahr",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 904,
        Pin: 627764,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 905,
        Pin: 713433,
        DispatchCenter: "Guskhara_DignagarRd_D (West Bengal)",
        OriginCenter: "Guskhara_DignagarRd_D (West Bengal)",
        FacilityCity: "Guskhara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 906,
        Pin: 611003,
        DispatchCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        OriginCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        FacilityCity: "Nagapattinam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 907,
        Pin: 211018,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 908,
        Pin: 517277,
        DispatchCenter: "Madanapalle_Vayalapadu_D (Andhra Pradesh)",
        OriginCenter: "Madanapalle_Vayalapadu_D (Andhra Pradesh)",
        FacilityCity: "Madanapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 909,
        Pin: 721154,
        DispatchCenter: "Kolaghat_GopalNgr_DPP (West Bengal)",
        OriginCenter: "Kolaghat_GopalNgr_DPP (West Bengal)",
        FacilityCity: "Kolaghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 910,
        Pin: 232107,
        DispatchCenter: "Chandauli_Chahaniya_D (Uttar Pradesh)",
        OriginCenter: "Chandauli_Chahaniya_D (Uttar Pradesh)",
        FacilityCity: "Mughal Sarai",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 911,
        Pin: 630602,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 912,
        Pin: 480224,
        DispatchCenter: "Amarwara_Ward14_D (Madhya Pradesh)",
        OriginCenter: "Amarwara_Ward14_D (Madhya Pradesh)",
        FacilityCity: "Amarwara",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 913,
        Pin: 396211,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 914,
        Pin: 414205,
        DispatchCenter: "Beed_Raimoha_DPP (Maharashtra)",
        OriginCenter: "Beed_Raimoha_DPP (Maharashtra)",
        FacilityCity: "Dongarkinhi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 915,
        Pin: 733141,
        DispatchCenter: "Gangarampr_Ramkrishnpaly_DPP (West Bengal)",
        OriginCenter: "Gangarampr_Ramkrishnpaly_DPP (West Bengal)",
        FacilityCity: "Gangarampur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 916,
        Pin: 522211,
        DispatchCenter: "Tenali_Pandurngpet_D (Andhra Pradesh)",
        OriginCenter: "Tenali_Pandurngpet_D (Andhra Pradesh)",
        FacilityCity: "Tenali",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 917,
        Pin: 321006,
        DispatchCenter: "Kherli_Doroli_D (Rajasthan)",
        OriginCenter: "Kherli_Doroli_D (Rajasthan)",
        FacilityCity: "Kherli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 918,
        Pin: 332021,
        DispatchCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        OriginCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 919,
        Pin: 325208,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 920,
        Pin: 713427,
        DispatchCenter: "Arambag_Bulchandrapur_DPP (West Bengal)",
        OriginCenter: "Arambag_Bulchandrapur_DPP (West Bengal)",
        FacilityCity: "Arambag",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 921,
        Pin: 629902,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 922,
        Pin: 413322,
        DispatchCenter: "Pandharpur_Mangalwedha_D (Maharashtra)",
        OriginCenter: "Pandharpur_Mangalwedha_D (Maharashtra)",
        FacilityCity: "Pandharpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 923,
        Pin: 445215,
        DispatchCenter: "Pusad_Central_DPP_2 (Maharashtra)",
        OriginCenter: "Pusad_Central_DPP_2 (Maharashtra)",
        FacilityCity: "Pusad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 924,
        Pin: 782142,
        DispatchCenter: "Nagaon_Bangthai_H (Assam)",
        OriginCenter: "Nagaon_Bangthai_H (Assam)",
        FacilityCity: "Nagaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 925,
        Pin: 585244,
        DispatchCenter: "Aland_Sherikarcolony_D (Karnataka)",
        OriginCenter: "Aland_Sherikarcolony_D (Karnataka)",
        FacilityCity: "Aland",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 926,
        Pin: 591218,
        DispatchCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        OriginCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        FacilityCity: "Gokak",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 927,
        Pin: 673655,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 928,
        Pin: 507157,
        DispatchCenter: "Khammam_Mudigonda_H (Telangana)",
        OriginCenter: "Khammam_Mudigonda_H (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 929,
        Pin: 123412,
        DispatchCenter: "Rewari_Chirhara_D (Haryana)",
        OriginCenter: "Rewari_Chirhara_D (Haryana)",
        FacilityCity: "Rewari",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 930,
        Pin: 522659,
        DispatchCenter: "Vinukonda_Thimmayapalem_D (Andhra Pradesh)",
        OriginCenter: "Vinukonda_Thimmayapalem_D (Andhra Pradesh)",
        FacilityCity: "Vinukonda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 931,
        Pin: 680586,
        DispatchCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        OriginCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        FacilityCity: "Pazhayannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 932,
        Pin: 274702,
        DispatchCenter: "Salempur_BhatparRani_D (Uttar Pradesh)",
        OriginCenter: "Salempur_BhatparRani_D (Uttar Pradesh)",
        FacilityCity: "Salempur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 933,
        Pin: 122210,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 934,
        Pin: 767045,
        DispatchCenter: "Dunguripali_Badnuapali_D (Orissa)",
        OriginCenter: "Dunguripali_Badnuapali_D (Orissa)",
        FacilityCity: "Dunguripali",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 935,
        Pin: 518345,
        DispatchCenter: "Yemignur_Timmappacolony_D (Andhra Pradesh)",
        OriginCenter: "Yemignur_Timmappacolony_D (Andhra Pradesh)",
        FacilityCity: "Yemmiganur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 936,
        Pin: 852112,
        DispatchCenter: "Kumarkhand_SihpurGadhiya_D (Bihar)",
        OriginCenter: "Kumarkhand_SihpurGadhiya_D (Bihar)",
        FacilityCity: "Kumarkhand",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 937,
        Pin: 383230,
        DispatchCenter: "Idar_GIDC_D (Gujarat)",
        OriginCenter: "Idar_GIDC_D (Gujarat)",
        FacilityCity: "Idar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 938,
        Pin: 685536,
        DispatchCenter: "Kumily_Attappallam_D (Kerala)",
        OriginCenter: "Kumily_Attappallam_D (Kerala)",
        FacilityCity: "Kumily",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 939,
        Pin: 211014,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 940,
        Pin: 734423,
        DispatchCenter: "Bagdogra_Budhkaran_D (West Bengal)",
        OriginCenter: "Bagdogra_Budhkaran_D (West Bengal)",
        FacilityCity: "Bagdogra",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 941,
        Pin: 392014,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 942,
        Pin: 500872,
        DispatchCenter: "Hyderabad_Medchal1_L (Telangana)",
        OriginCenter: "Hyderabad_Medchal_GW (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 943,
        Pin: 396220,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 944,
        Pin: 754206,
        DispatchCenter: "Cuttack_SliprDPP_DC (Orissa)",
        OriginCenter: "Cuttack_SliprDPP_DC (Orissa)",
        FacilityCity: "Salipur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 945,
        Pin: 521164,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 946,
        Pin: 788816,
        DispatchCenter: "Kalain_Tarapur_D (Assam)",
        OriginCenter: "Kalain_Tarapur_D (Assam)",
        FacilityCity: "Kalain",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 947,
        Pin: 635655,
        DispatchCenter: "Uthangarai_Mathurpathi_D (Tamil Nadu)",
        OriginCenter: "Uthangarai_Mathurpathi_D (Tamil Nadu)",
        FacilityCity: "Uthangarai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 948,
        Pin: 680306,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 949,
        Pin: 274309,
        DispatchCenter: "Padrauna_BauliChwk_D (Uttar Pradesh)",
        OriginCenter: "Padrauna_BauliChwk_D (Uttar Pradesh)",
        FacilityCity: "Padrauna",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 950,
        Pin: 678557,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 951,
        Pin: 431120,
        DispatchCenter: "Soegaon_Shivajinagar_DPP (Maharashtra)",
        OriginCenter: "Soegaon_Shivajinagar_DPP (Maharashtra)",
        FacilityCity: "Soegaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 952,
        Pin: 271301,
        DispatchCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        OriginCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        FacilityCity: "Gonda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 953,
        Pin: 686522,
        DispatchCenter: "Kottayam_Mulakunnath_D (Kerala)",
        OriginCenter: "Kottayam_Mulakunnath_D (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 954,
        Pin: 799271,
        DispatchCenter: "Kumarghat_Kanchanpur_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Kumarghat",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 955,
        Pin: 362257,
        DispatchCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        OriginCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        FacilityCity: "Veraval",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 956,
        Pin: 370040,
        DispatchCenter: "Bhuj_Madhapar_D (Gujarat)",
        OriginCenter: "Bhuj_Madhapar_D (Gujarat)",
        FacilityCity: "Bhuj",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 957,
        Pin: 680513,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 958,
        Pin: 680581,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 959,
        Pin: 496245,
        DispatchCenter: "Kunkuri_Tapkara_D (Chhattisgarh)",
        OriginCenter: "Kunkuri_Tapkara_D (Chhattisgarh)",
        FacilityCity: "Kunkuri",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 960,
        Pin: 534007,
        DispatchCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        OriginCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        FacilityCity: "Eluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 961,
        Pin: 799263,
        DispatchCenter: "Kailashahar_Kumarghat_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Kailashahar",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 962,
        Pin: 441101,
        DispatchCenter: "Savner_Raymondcolony_D (Maharashtra)",
        OriginCenter: "Savner_Raymondcolony_D (Maharashtra)",
        FacilityCity: "Savner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 963,
        Pin: 305004,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 964,
        Pin: 493448,
        DispatchCenter: "Mahasamund_Bagbahra_DPP (Chhattisgarh)",
        OriginCenter: "Mahasamund_Bagbahra_DPP (Chhattisgarh)",
        FacilityCity: "Mahasamund",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 965,
        Pin: 415712,
        DispatchCenter: "Dapoli_Ghimhavane_D (Maharashtra)",
        OriginCenter: "Dapoli_Ghimhavane_D (Maharashtra)",
        FacilityCity: "Dapoli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 966,
        Pin: 582211,
        DispatchCenter: "Rona_GadagRD_D (Karnataka)",
        OriginCenter: "Rona_GadagRD_D (Karnataka)",
        FacilityCity: "Rona",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 967,
        Pin: 171208,
        DispatchCenter: "ChirgaonHP_Sundha_D (Himachal Pradesh)",
        OriginCenter: "ChirgaonHP_Sundha_D (Himachal Pradesh)",
        FacilityCity: "Chirgaon-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 968,
        Pin: 331517,
        DispatchCenter: "Sujangarh_Dhneru_D (Rajasthan)",
        OriginCenter: "Sujangarh_Dhneru_D (Rajasthan)",
        FacilityCity: "Sujangarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 969,
        Pin: 571603,
        DispatchCenter: "Krishnarajngr_VlmkiNgr_D (Karnataka)",
        OriginCenter: "Krishnarajngr_VlmkiNgr_D (Karnataka)",
        FacilityCity: "Krishnarajngr",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 970,
        Pin: 384210,
        DispatchCenter: "Becharaji_Harijiroad_D (Gujarat)",
        OriginCenter: "Becharaji_Harijiroad_D (Gujarat)",
        FacilityCity: "Becharaji",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 971,
        Pin: 635107,
        DispatchCenter: "Denkanikottai_AnchettyRd_D (Tamil Nadu)",
        OriginCenter: "Denkanikottai_AnchettyRd_D (Tamil Nadu)",
        FacilityCity: "Denkanikottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 972,
        Pin: 835240,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 973,
        Pin: 583283,
        DispatchCenter: "Gangavathi_CBSGanj_DPP (Karnataka)",
        OriginCenter: "Gangavathi_CBSGanj_DPP (Karnataka)",
        FacilityCity: "Gangavathi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 974,
        Pin: 274407,
        DispatchCenter: "Sewarhi_SaragatiaKaran_D (Uttar Pradesh)",
        OriginCenter: "Sewarhi_SaragatiaKaran_D (Uttar Pradesh)",
        FacilityCity: "Sewarhi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 975,
        Pin: 683585,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 976,
        Pin: 415106,
        DispatchCenter: "Karad_Malkapur1_D (Maharashtra)",
        OriginCenter: "Karad_Malkapur1_D (Maharashtra)",
        FacilityCity: "Karad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 977,
        Pin: 262401,
        DispatchCenter: "Kashipur_Chakkarpur_D (Uttarakhand)",
        OriginCenter: "Kashipur_Chakkarpur_D (Uttarakhand)",
        FacilityCity: "Kashipur",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 978,
        Pin: 535592,
        DispatchCenter: "Salur_Eswar_D (Andhra Pradesh)",
        OriginCenter: "Salur_Eswar_D (Andhra Pradesh)",
        FacilityCity: "Salur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 979,
        Pin: 506002,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 980,
        Pin: 678005,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 981,
        Pin: 206253,
        DispatchCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        OriginCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        FacilityCity: "Etawah",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 982,
        Pin: 453332,
        DispatchCenter: "Pithampur_Ambedkarnagar_L (Madhya Pradesh)",
        OriginCenter: "Pithampur_Ambedkarnagar_L (Madhya Pradesh)",
        FacilityCity: "Pithampur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 983,
        Pin: 689652,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 984,
        Pin: 736171,
        DispatchCenter: "CoochBehar_Pundibari_D (West Bengal)",
        OriginCenter: "CoochBehar_Pundibari_D (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 985,
        Pin: 757107,
        DispatchCenter: "Baripada_KalikDPP_D (Orissa)",
        OriginCenter: "Baripada_KalikDPP_D (Orissa)",
        FacilityCity: "Baripada",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 986,
        Pin: 451225,
        DispatchCenter: "Mhshwr_ktrgn_DPP (Madhya Pradesh)",
        OriginCenter: "Mhshwr_ktrgn_DPP (Madhya Pradesh)",
        FacilityCity: "Maheshwar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 987,
        Pin: 735205,
        DispatchCenter: "Chalsa_BusStand_D (West Bengal)",
        OriginCenter: "Chalsa_BusStand_D (West Bengal)",
        FacilityCity: "Chalsa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 988,
        Pin: 721146,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 989,
        Pin: 814158,
        DispatchCenter: "Poriyahat_Jogia_D (Jharkhand)",
        OriginCenter: "Poriyahat_Jogia_D (Jharkhand)",
        FacilityCity: "Poriyahat",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 990,
        Pin: 413518,
        DispatchCenter: "Udgir_NlgaonRd_D (Maharashtra)",
        OriginCenter: "Udgir_NlgaonRd_D (Maharashtra)",
        FacilityCity: "Udgir",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 991,
        Pin: 413544,
        DispatchCenter: "Nilanga_Panchalcolony_D (Maharashtra)",
        OriginCenter: "Nilanga_Panchalcolony_D (Maharashtra)",
        FacilityCity: "Nilanga",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 992,
        Pin: 209208,
        DispatchCenter: "Ghatampur_StatinRD_D (Uttar Pradesh)",
        OriginCenter: "Ghatampur_StatinRD_D (Uttar Pradesh)",
        FacilityCity: "Ghatampur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 993,
        Pin: 621716,
        DispatchCenter: "Pennadam_Puthar_D (Tamil Nadu)",
        OriginCenter: "Pennadam_Puthar_D (Tamil Nadu)",
        FacilityCity: "Pennadam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 994,
        Pin: 451559,
        DispatchCenter: "Barwani_IGcolony_D (Madhya Pradesh)",
        OriginCenter: "Barwani_IGcolony_D (Madhya Pradesh)",
        FacilityCity: "Barwani",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 995,
        Pin: 625214,
        DispatchCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        OriginCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        FacilityCity: "Vadipatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 996,
        Pin: 360531,
        DispatchCenter: "Jamjodhpur_SubhashRd_DPP (Gujarat)",
        OriginCenter: "Jamjodhpur_SubhashRd_DPP (Gujarat)",
        FacilityCity: "Jamjodhpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 997,
        Pin: 124127,
        DispatchCenter: "Meham_Ward13_D (Haryana)",
        OriginCenter: "Meham_Ward13_D (Haryana)",
        FacilityCity: "Meham",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 998,
        Pin: 362231,
        DispatchCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        OriginCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        FacilityCity: "Keshod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 999,
        Pin: 679309,
        DispatchCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        OriginCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        FacilityCity: "Sankaramangalam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1000,
        Pin: 680312,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1001,
        Pin: 573130,
        DispatchCenter: "Ramanathapura_Koodalur_D (Karnataka)",
        OriginCenter: "Ramanathapura_Koodalur_D (Karnataka)",
        FacilityCity: "Ramanathapura",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1002,
        Pin: 752057,
        DispatchCenter: "Khurdha_MdhsnDPP_D (Orissa)",
        OriginCenter: "Khurdha_MdhsnDPP_D (Orissa)",
        FacilityCity: "Khurdha",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1003,
        Pin: 621116,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1004,
        Pin: 245201,
        DispatchCenter: "Hapur_Simbhaoli_D (Uttar Pradesh)",
        OriginCenter: "Hapur_Simbhaoli_D (Uttar Pradesh)",
        FacilityCity: "Hapur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1005,
        Pin: 673015,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1006,
        Pin: 713404,
        DispatchCenter: "Jamalpur_HalaraVlg_D (West Bengal)",
        OriginCenter: "Jamalpur_HalaraVlg_D (West Bengal)",
        FacilityCity: "Jamalpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1007,
        Pin: 327001,
        DispatchCenter: "Banswara_Baridaylan_D (Rajasthan)",
        OriginCenter: "Banswara_Baridaylan_D (Rajasthan)",
        FacilityCity: "Banswara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1008,
        Pin: 380006,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1009,
        Pin: 627418,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1010,
        Pin: 225124,
        DispatchCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        OriginCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        FacilityCity: "Haidargarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1011,
        Pin: 829146,
        DispatchCenter: "Ramgarh_Mandu_D (Jharkhand)",
        OriginCenter: "Ramgarh_Mandu_D (Jharkhand)",
        FacilityCity: "Ramgarh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1012,
        Pin: 431736,
        DispatchCenter: "Mukhed_Naigaon_DPP (Maharashtra)",
        OriginCenter: "Mukhed_Naigaon_DPP (Maharashtra)",
        FacilityCity: "Mukhed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1013,
        Pin: 845418,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1014,
        Pin: 612502,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1015,
        Pin: 585436,
        DispatchCenter: "Aurad_CBColony_D (Karnataka)",
        OriginCenter: "Aurad_CBColony_D (Karnataka)",
        FacilityCity: "Aurad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1016,
        Pin: 600053,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1017,
        Pin: 670353,
        DispatchCenter: "Nileshwar_Olayambadi_D (Kerala)",
        OriginCenter: "Nileshwar_Olayambadi_D (Kerala)",
        FacilityCity: "Nileshwar",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1018,
        Pin: 571232,
        DispatchCenter: "Kushalnagar_BapujiExtension_D (Karnataka)",
        OriginCenter: "Kushalnagar_BapujiExtension_D (Karnataka)",
        FacilityCity: "Kushalnagar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1019,
        Pin: 637021,
        DispatchCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        OriginCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        FacilityCity: "Namakkal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1020,
        Pin: 828201,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1021,
        Pin: 600128,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1022,
        Pin: 176208,
        DispatchCenter: "ShahpurHP_PoliceStn_DPP (Himachal Pradesh)",
        OriginCenter: "ShahpurHP_PoliceStn_DPP (Himachal Pradesh)",
        FacilityCity: "Shahpur-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1023,
        Pin: 517584,
        DispatchCenter: "Puttur_Artmclny_D (Andhra Pradesh)",
        OriginCenter: "Puttur_Artmclny_D (Andhra Pradesh)",
        FacilityCity: "Puttur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1024,
        Pin: 383315,
        DispatchCenter: "Modasa_Shyamnagar_D (Gujarat)",
        OriginCenter: "Modasa_Shyamnagar_D (Gujarat)",
        FacilityCity: "Modasa",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1025,
        Pin: 515291,
        DispatchCenter: "Madakasira_RTCStand_D (Andhra Pradesh)",
        OriginCenter: "Madakasira_RTCStand_D (Andhra Pradesh)",
        FacilityCity: "Madakasira",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1026,
        Pin: 360360,
        DispatchCenter: "Jetpur_Dasijivanpara_D (Gujarat)",
        OriginCenter: "Jetpur_Dasijivanpara_D (Gujarat)",
        FacilityCity: "Jetpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1027,
        Pin: 394355,
        DispatchCenter: "Bardoli_GopalNagar_D (Gujarat)",
        OriginCenter: "Bardoli_GopalNagar_D (Gujarat)",
        FacilityCity: "Bardoli",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1028,
        Pin: 342604,
        DispatchCenter: "Piparcity_JodhpurRd_DPP (Rajasthan)",
        OriginCenter: "Piparcity_JodhpurRd_DPP (Rajasthan)",
        FacilityCity: "Piparcity",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1029,
        Pin: 380057,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1030,
        Pin: 533463,
        DispatchCenter: "Yanam_DraksharamaRd_D (Andhra Pradesh)",
        OriginCenter: "Yanam_DraksharamaRd_D (Andhra Pradesh)",
        FacilityCity: "Draksharamam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1031,
        Pin: 176210,
        DispatchCenter: "NagrotaSurian_Saknra_DPP (Himachal Pradesh)",
        OriginCenter: "NagrotaSurian_Saknra_DPP (Himachal Pradesh)",
        FacilityCity: "Nagrota Surian",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1032,
        Pin: 370015,
        DispatchCenter: "Bhuj_Madhapar_D (Gujarat)",
        OriginCenter: "Bhuj_Madhapar_D (Gujarat)",
        FacilityCity: "Bhuj",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1033,
        Pin: 534329,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1034,
        Pin: 612901,
        DispatchCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        OriginCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        FacilityCity: "Jayankondam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1035,
        Pin: 765024,
        DispatchCenter: "Gunupur_Antarjholi_DPP (Orissa)",
        OriginCenter: "Gunupur_Antarjholi_DPP (Orissa)",
        FacilityCity: "Gunupur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1036,
        Pin: 680020,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1037,
        Pin: 321615,
        DispatchCenter: "Mahwa_Allapur_D (Rajasthan)",
        OriginCenter: "Mahwa_Allapur_D (Rajasthan)",
        FacilityCity: "Mahwa",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1038,
        Pin: 695040,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1039,
        Pin: 415520,
        DispatchCenter: "Patan_Ashram_D (Maharashtra)",
        OriginCenter: "Patan_Ashram_D (Maharashtra)",
        FacilityCity: "Patan-MH",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1040,
        Pin: 790101,
        DispatchCenter: "Dirang_ConaCounty_D (Arunachal Pradesh)",
        OriginCenter: "Dirang_ConaCounty_D (Arunachal Pradesh)",
        FacilityCity: "Dirang",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1041,
        Pin: 788118,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1042,
        Pin: 231226,
        DispatchCenter: "Dudhinagar_DevColony_D (Uttar Pradesh)",
        OriginCenter: "Dudhinagar_DevColony_D (Uttar Pradesh)",
        FacilityCity: "Dudhinagar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1043,
        Pin: 425001,
        DispatchCenter: "Jalgaon_MIDC_I (Maharashtra)",
        OriginCenter: "Jalgaon_MIDC_I (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1044,
        Pin: 314038,
        DispatchCenter: "Salumbar_Aspur2_D (Rajasthan)",
        OriginCenter: "Salumbar_Aspur2_D (Rajasthan)",
        FacilityCity: "Salumbar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1045,
        Pin: 673593,
        DispatchCenter: "SultanBathery_Kolagapara_D (Kerala)",
        OriginCenter: "SultanBathery_Kolagapara_D (Kerala)",
        FacilityCity: "Sultan Bathery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1046,
        Pin: 601102,
        DispatchCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        OriginCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1047,
        Pin: 768104,
        DispatchCenter: "Binka_Sindurpur_DPP (Orissa)",
        OriginCenter: "Binka_Sindurpur_DPP (Orissa)",
        FacilityCity: "Binka",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1048,
        Pin: 735235,
        DispatchCenter: "Mainaguri_Debinagar_DPP (West Bengal)",
        OriginCenter: "Mainaguri_Debinagar_DPP (West Bengal)",
        FacilityCity: "Mainaguri",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1049,
        Pin: 175012,
        DispatchCenter: "JognderNgr_Dhelu_D (Himachal Pradesh)",
        OriginCenter: "JognderNgr_Dhelu_D (Himachal Pradesh)",
        FacilityCity: "Joginder Nagar",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1050,
        Pin: 690552,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1051,
        Pin: 802302,
        DispatchCenter: "Arrah_Anaith_D (Bihar)",
        OriginCenter: "Arrah_Anaith_D (Bihar)",
        FacilityCity: "Arrah",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1052,
        Pin: 614619,
        DispatchCenter: "Manamelkudi_HondaShow_D (Tamil Nadu)",
        OriginCenter: "Manamelkudi_HondaShow_D (Tamil Nadu)",
        FacilityCity: "Manamelkudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1053,
        Pin: 229405,
        DispatchCenter: "Raebareli_Ratapur_D (Uttar Pradesh)",
        OriginCenter: "Raebareli_Ratapur_D (Uttar Pradesh)",
        FacilityCity: "Raebareli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1054,
        Pin: 440017,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Chikhali_L (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1055,
        Pin: 415111,
        DispatchCenter: "Karad_Malkapur1_D (Maharashtra)",
        OriginCenter: "Karad_Malkapur1_D (Maharashtra)",
        FacilityCity: "Karad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1056,
        Pin: 630210,
        DispatchCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1057,
        Pin: 530009,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1058,
        Pin: 246726,
        DispatchCenter: "Bijnor_Garhi_D (Uttar Pradesh)",
        OriginCenter: "Bijnor_Garhi_D (Uttar Pradesh)",
        FacilityCity: "Bijnor",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1059,
        Pin: 691508,
        DispatchCenter: "Adoor_Pathanapuram_D (Kerala)",
        OriginCenter: "Adoor_Pathanapuram_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1060,
        Pin: 364565,
        DispatchCenter: "Amreli_Bhojalpara_D (Gujarat)",
        OriginCenter: "Amreli_Bhojalpara_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1061,
        Pin: 584129,
        DispatchCenter: "Kowthal_UdayaNagar_D (Karnataka)",
        OriginCenter: "Kowthal_UdayaNagar_D (Karnataka)",
        FacilityCity: "Kowthal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1062,
        Pin: 508204,
        DispatchCenter: "Huzurnagar_Miryalaguda_D (Telangana)",
        OriginCenter: "Huzurnagar_Miryalaguda_D (Telangana)",
        FacilityCity: "Huzur Nagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1063,
        Pin: 203402,
        DispatchCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        OriginCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        FacilityCity: "Siyana",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1064,
        Pin: 229135,
        DispatchCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        OriginCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        FacilityCity: "Haidargarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1065,
        Pin: 785106,
        DispatchCenter: "Majuli_Kthnibri_D (Assam)",
        OriginCenter: "Majuli_Kthnibri_D (Assam)",
        FacilityCity: "Majuli",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1066,
        Pin: 202129,
        DispatchCenter: "Aligarh_Atrauli_D (Uttar Pradesh)",
        OriginCenter: "Aligarh_Atrauli_D (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1067,
        Pin: 122223,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1068,
        Pin: 695131,
        DispatchCenter: "Trivandrum_Neyatinkra_D (Kerala)",
        OriginCenter: "Trivandrum_Neyatinkra_D (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1069,
        Pin: 440025,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1070,
        Pin: 500482,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1071,
        Pin: 532221,
        DispatchCenter: "Palasa_Mandapam_D (Andhra Pradesh)",
        OriginCenter: "Palasa_Mandapam_D (Andhra Pradesh)",
        FacilityCity: "Palasa",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1072,
        Pin: 462015,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1073,
        Pin: 852216,
        DispatchCenter: "Saharsa_NayaBzar_D (Bihar)",
        OriginCenter: "Saharsa_NayaBzar_D (Bihar)",
        FacilityCity: "Saharsa",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1074,
        Pin: 691522,
        DispatchCenter: "Adoor_Pathanapuram_D (Kerala)",
        OriginCenter: "Adoor_Pathanapuram_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1075,
        Pin: 441806,
        DispatchCenter: "SadakArjuni_Central_D (Maharashtra)",
        OriginCenter: "SadakArjuni_Central_D (Maharashtra)",
        FacilityCity: "Sadak Arjuni",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1076,
        Pin: 500068,
        DispatchCenter: "Hyderabad_Autonagar_L (Telangana)",
        OriginCenter: "Hyderabad_Autonagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1077,
        Pin: 305628,
        DispatchCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        OriginCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        FacilityCity: "Bijainagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1078,
        Pin: 574111,
        DispatchCenter: "Mudarangady_Santhoor_DPP (Karnataka)",
        OriginCenter: "Mudarangady_Santhoor_DPP (Karnataka)",
        FacilityCity: "Mudarangady",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1079,
        Pin: 762010,
        DispatchCenter: "Phulbani_NipiditaOfc_D (Orissa)",
        OriginCenter: "Phulbani_NipiditaOfc_D (Orissa)",
        FacilityCity: "Phulbani",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1080,
        Pin: 273211,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1081,
        Pin: 841507,
        DispatchCenter: "Malmaliya_Piarepur_D (Bihar)",
        OriginCenter: "Malmaliya_Piarepur_D (Bihar)",
        FacilityCity: "Malmaliya",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1082,
        Pin: 680511,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1083,
        Pin: 400022,
        DispatchCenter: "Mumbai_Chembur_L (Maharashtra)",
        OriginCenter: "Mumbai_Chembur_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1084,
        Pin: 483775,
        DispatchCenter: "Vijayraghavgarh_KymoreRd_DPP (Madhya Pradesh)",
        OriginCenter: "Vijayraghavgarh_KymoreRd_DPP (Madhya Pradesh)",
        FacilityCity: "Vijayraghavgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1085,
        Pin: 682052,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        Zone : "S2"
      },
      {
        SN: 1086,
        Pin: 362510,
        DispatchCenter: "Una_StColony_D (Gujarat)",
        OriginCenter: "Una_StColony_D (Gujarat)",
        FacilityCity: "UNA",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1087,
        Pin: 636305,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1088,
        Pin: 782410,
        DispatchCenter: "Morigaon_Nakhulagrant_D (Assam)",
        OriginCenter: "Morigaon_Nakhulagrant_D (Assam)",
        FacilityCity: "Morigaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1089,
        Pin: 413717,
        DispatchCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        OriginCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        FacilityCity: "Shrirampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1090,
        Pin: 509349,
        DispatchCenter: "Kalwakurthy_Margadarshi_D (Telangana)",
        OriginCenter: "Kalwakurthy_Margadarshi_D (Telangana)",
        FacilityCity: "Kalwakurthy",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1091,
        Pin: 508278,
        DispatchCenter: "Haliya_VBNagar_D (Telangana)",
        OriginCenter: "Haliya_VBNagar_D (Telangana)",
        FacilityCity: "Haliya",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1092,
        Pin: 222107,
        DispatchCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        OriginCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        FacilityCity: "Jaunpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1093,
        Pin: 521263,
        DispatchCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        OriginCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        FacilityCity: "Gudivada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1094,
        Pin: 751014,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1095,
        Pin: 625052,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1096,
        Pin: 444606,
        DispatchCenter: "Amravati_Sashinagar_D (Maharashtra)",
        OriginCenter: "Amravati_Sashinagar_D (Maharashtra)",
        FacilityCity: "Amravati",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1097,
        Pin: 208005,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1098,
        Pin: 394150,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1099,
        Pin: 454221,
        DispatchCenter: "Kukshi_BurahaniColony_D (Madhya Pradesh)",
        OriginCenter: "Kukshi_BurahaniColony_D (Madhya Pradesh)",
        FacilityCity: "Kukshi",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 1100,
        Pin: 425326,
        DispatchCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        OriginCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        FacilityCity: "Bhusawal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1101,
        Pin: 211011,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1102,
        Pin: 712416,
        DispatchCenter: "Khanakul_Antngr_D (West Bengal)",
        OriginCenter: "Khanakul_Antngr_D (West Bengal)",
        FacilityCity: "Khanakul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1103,
        Pin: 688587,
        DispatchCenter: "Alappuzha_Nedumbrakkad_D (Kerala)",
        OriginCenter: "Alappuzha_Nedumbrakkad_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1104,
        Pin: 843321,
        DispatchCenter: "Aurai_College_D (Bihar)",
        OriginCenter: "Aurai_College_D (Bihar)",
        FacilityCity: "Aurai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1105,
        Pin: 515761,
        DispatchCenter: "Kalyandurg_JayNagar_D (Andhra Pradesh)",
        OriginCenter: "Kalyandurg_JayNagar_D (Andhra Pradesh)",
        FacilityCity: "Kalyandurg",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1106,
        Pin: 621717,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1107,
        Pin: 241201,
        DispatchCenter: "Sandila_SubabDPP_D (Uttar Pradesh)",
        OriginCenter: "Sandila_SubabDPP_D (Uttar Pradesh)",
        FacilityCity: "Sandila",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1108,
        Pin: 680566,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1109,
        Pin: 571201,
        DispatchCenter: "Madikeri_Ranipet_D (Karnataka)",
        OriginCenter: "Madikeri_Ranipet_D (Karnataka)",
        FacilityCity: "Madikeri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1110,
        Pin: 143408,
        DispatchCenter: "Goindwal_Fatehabad_D (Punjab)",
        OriginCenter: "Goindwal_Fatehabad_D (Punjab)",
        FacilityCity: "Goindwal",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1111,
        Pin: 734103,
        DispatchCenter: "Darjeeling_Limbugaon_D (West Bengal)",
        OriginCenter: "Darjeeling_Limbugaon_D (West Bengal)",
        FacilityCity: "Darjeeling",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1112,
        Pin: 385330,
        DispatchCenter: "Bhabhar_Deodar_DPP (Gujarat)",
        OriginCenter: "Bhabhar_Deodar_DPP (Gujarat)",
        FacilityCity: "Bhabhar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1113,
        Pin: 742184,
        DispatchCenter: "Morgram_Kantagoriya_D (West Bengal)",
        OriginCenter: "Morgram_Kantagoriya_D (West Bengal)",
        FacilityCity: "Morgram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1114,
        Pin: 625529,
        DispatchCenter: "Usilampatti_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Usilampatti_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Usilampatti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1115,
        Pin: 713513,
        DispatchCenter: "Nabadwip_Srirmpur_D (West Bengal)",
        OriginCenter: "Nabadwip_Srirmpur_D (West Bengal)",
        FacilityCity: "Nabadwip",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1116,
        Pin: 233221,
        DispatchCenter: "Aunrihar_JawahrNgr_D (Uttar Pradesh)",
        OriginCenter: "Aunrihar_JawahrNgr_D (Uttar Pradesh)",
        FacilityCity: "Aunrihar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1117,
        Pin: 141305,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1118,
        Pin: 362205,
        DispatchCenter: "Junagadh_Dolatpara_I (Gujarat)",
        OriginCenter: "Junagadh_Dolatpara_I (Gujarat)",
        FacilityCity: "Junagadh",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1119,
        Pin: 600059,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1120,
        Pin: 835324,
        DispatchCenter: "Gumla_Sisai_DPP (Jharkhand)",
        OriginCenter: "Gumla_Sisai_DPP (Jharkhand)",
        FacilityCity: "Gumla",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1121,
        Pin: 411043,
        DispatchCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        OriginCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1122,
        Pin: 637201,
        DispatchCenter: "Tiruchengode_Kailasmplym_D (Tamil Nadu)",
        OriginCenter: "Tiruchengode_Kailasmplym_D (Tamil Nadu)",
        FacilityCity: "Tiruchengode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1123,
        Pin: 416304,
        DispatchCenter: "Sangli_Dattanagar_D (Maharashtra)",
        OriginCenter: "Sangli_Dattanagar_D (Maharashtra)",
        FacilityCity: "Sangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1124,
        Pin: 393001,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1125,
        Pin: 625540,
        DispatchCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        OriginCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        FacilityCity: "Cumbum",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1126,
        Pin: 736122,
        DispatchCenter: "CoochBehar_khagrabari_I (West Bengal)",
        OriginCenter: "CoochBehar_khagrabari_I (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1127,
        Pin: 680542,
        DispatchCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        OriginCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1128,
        Pin: 410511,
        DispatchCenter: 37043.00011574074,
        OriginCenter: 37043.00011574074,
        FacilityCity: "Junnar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1129,
        Pin: 148109,
        DispatchCenter: "Barnala_DC (Punjab)",
        OriginCenter: "Barnala_DC (Punjab)",
        FacilityCity: "Barnala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1130,
        Pin: 223225,
        DispatchCenter: "Azamgarh_Saraimeer_DPP (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Saraimeer_DPP (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1131,
        Pin: 151105,
        DispatchCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        OriginCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        FacilityCity: "Bathinda",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1132,
        Pin: 507305,
        DispatchCenter: "Khammam_Mudigonda_H (Telangana)",
        OriginCenter: "Khammam_Mudigonda_H (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1133,
        Pin: 800021,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1134,
        Pin: 410220,
        DispatchCenter: "Mumbai_Ariwali_L (Maharashtra)",
        OriginCenter: "Mumbai_Ariwali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1135,
        Pin: 521345,
        DispatchCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        OriginCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        FacilityCity: "Eluru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1136,
        Pin: 413104,
        DispatchCenter: "Baramati_Pragatinagar_D (Maharashtra)",
        OriginCenter: "Baramati_Pragatinagar_D (Maharashtra)",
        FacilityCity: "Baramati",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1137,
        Pin: 143113,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1138,
        Pin: 412202,
        DispatchCenter: "UrliKanchan_Sortapwadi_D (Maharashtra)",
        OriginCenter: "UrliKanchan_Sortapwadi_D (Maharashtra)",
        FacilityCity: "UrliKanchan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1139,
        Pin: 783307,
        DispatchCenter: "Dhubri_Tetultol_D (Assam)",
        OriginCenter: "Dhubri_Tetultol_D (Assam)",
        FacilityCity: "Dhubri",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1140,
        Pin: 690502,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1141,
        Pin: 208008,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1142,
        Pin: 400053,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1143,
        Pin: 848113,
        DispatchCenter: "Tajpur_HsptlRd_D (Bihar)",
        OriginCenter: "Tajpur_HsptlRd_D (Bihar)",
        FacilityCity: "Tajpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1144,
        Pin: 534238,
        DispatchCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        FacilityCity: "Bhimavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1145,
        Pin: 824213,
        DispatchCenter: "Daudnagar_Tarar_D (Bihar)",
        OriginCenter: "Daudnagar_Tarar_D (Bihar)",
        FacilityCity: "Daudnagar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1146,
        Pin: 275305,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1147,
        Pin: 301020,
        DispatchCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        OriginCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        FacilityCity: "Neemrana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1148,
        Pin: 796751,
        DispatchCenter: "Tlabung_Vengchhak_D (Mizoram)",
        OriginCenter: "Tlabung_Vengchhak_D (Mizoram)",
        FacilityCity: "Tlabung",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1149,
        Pin: 762105,
        DispatchCenter: "MRampur_Tumudibandha_DPP (Orissa)",
        OriginCenter: "MRampur_Tumudibandha_DPP (Orissa)",
        FacilityCity: "M Rampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1150,
        Pin: 533101,
        DispatchCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        OriginCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        FacilityCity: "Rajahmundry",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1151,
        Pin: 721635,
        DispatchCenter: "Haldia_Basudevpur_D (West Bengal)",
        OriginCenter: "Haldia_Basudevpur_D (West Bengal)",
        FacilityCity: "Haldia",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1152,
        Pin: 517425,
        DispatchCenter: "Kuppam_KrishnagiriRd_D (Andhra Pradesh)",
        OriginCenter: "Kuppam_KrishnagiriRd_D (Andhra Pradesh)",
        FacilityCity: "Kuppam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1153,
        Pin: 584111,
        DispatchCenter: "Devadurga_APMC_D (Karnataka)",
        OriginCenter: "Devadurga_APMC_D (Karnataka)",
        FacilityCity: "Devadurga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1154,
        Pin: 842006,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1155,
        Pin: 735203,
        DispatchCenter: "Birpara_Binnaguri_D (West Bengal)",
        OriginCenter: "Birpara_Binnaguri_D (West Bengal)",
        FacilityCity: "Birpara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1156,
        Pin: 411016,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1157,
        Pin: 682015,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1158,
        Pin: 621306,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1159,
        Pin: 394116,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1160,
        Pin: 712406,
        DispatchCenter: "Khanakul_Antngr_D (West Bengal)",
        OriginCenter: "Khanakul_Antngr_D (West Bengal)",
        FacilityCity: "Khanakul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1161,
        Pin: 413411,
        DispatchCenter: "Barshi_Indiranagar_D (Maharashtra)",
        OriginCenter: "Barshi_Indiranagar_D (Maharashtra)",
        FacilityCity: "Barshi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1162,
        Pin: 695607,
        DispatchCenter: "Venjaramoodu_Palavila_D (Kerala)",
        OriginCenter: "Venjaramoodu_Palavila_D (Kerala)",
        FacilityCity: "Venjaramoodu",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1163,
        Pin: 577425,
        DispatchCenter: "Hosanagar_Shimogard_DPP (Karnataka)",
        OriginCenter: "Hosanagar_Shimogard_DPP (Karnataka)",
        FacilityCity: "Hosanagar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1164,
        Pin: 632406,
        DispatchCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ranipet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1165,
        Pin: 689548,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1166,
        Pin: 690104,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1167,
        Pin: 523330,
        DispatchCenter: "Markapur_Kankanalapalle_DPP (Andhra Pradesh)",
        OriginCenter: "Markapur_Kankanalapalle_DPP (Andhra Pradesh)",
        FacilityCity: "Markapur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1168,
        Pin: 411006,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1169,
        Pin: 263676,
        DispatchCenter: "Ramnagar_Central_D_2 (Uttarakhand)",
        OriginCenter: "Ramnagar_Central_D_2 (Uttarakhand)",
        FacilityCity: "Ramnagar",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1170,
        Pin: 683573,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1171,
        Pin: 700147,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1172,
        Pin: 785644,
        DispatchCenter: "Sivasagar_Pdinnagar_D (Assam)",
        OriginCenter: "Sivasagar_Pdinnagar_D (Assam)",
        FacilityCity: "Sivasagar",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1173,
        Pin: 732209,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1174,
        Pin: 382240,
        DispatchCenter: "Bavla_Rajoda_D (Gujarat)",
        OriginCenter: "Bavla_Rajoda_D (Gujarat)",
        FacilityCity: "Bavla",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1175,
        Pin: 147021,
        DispatchCenter: "Patiala_UpkarNagar_D (Punjab)",
        OriginCenter: "Patiala_UpkarNagar_D (Punjab)",
        FacilityCity: "Patiala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1176,
        Pin: 247663,
        DispatchCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        OriginCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        FacilityCity: "Haridwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1177,
        Pin: 626145,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1178,
        Pin: 670014,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1179,
        Pin: 680663,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1180,
        Pin: 766102,
        DispatchCenter: "MRampur_RisidaRd_D (Orissa)",
        OriginCenter: "MRampur_RisidaRd_D (Orissa)",
        FacilityCity: "M Rampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1181,
        Pin: 690519,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1182,
        Pin: 360452,
        DispatchCenter: "Kalavad_Khodiyarpara_D (Gujarat)",
        OriginCenter: "Kalavad_Khodiyarpara_D (Gujarat)",
        FacilityCity: "Kalavad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1183,
        Pin: 845423,
        DispatchCenter: "Jiwdhara_Kotwa_D (Bihar)",
        OriginCenter: "Jiwdhara_Kotwa_D (Bihar)",
        FacilityCity: "Jiwdhara",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1184,
        Pin: 110054,
        DispatchCenter: "Delhi_Libaspur_L (Delhi)",
        OriginCenter: "Delhi_Libaspur_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1185,
        Pin: 210431,
        DispatchCenter: "Rath_Saina_D (Uttar Pradesh)",
        OriginCenter: "Rath_Saina_D (Uttar Pradesh)",
        FacilityCity: "Rath",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1186,
        Pin: 307044,
        DispatchCenter: "AbuRoad_MMS_D (Rajasthan)",
        OriginCenter: "AbuRoad_MMS_D (Rajasthan)",
        FacilityCity: "Abu Road",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1187,
        Pin: 321203,
        DispatchCenter: "Deeg_Rambag_D (Rajasthan)",
        OriginCenter: "Deeg_Rambag_D (Rajasthan)",
        FacilityCity: "Deeg",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1188,
        Pin: 143505,
        DispatchCenter: "Batala_Simbel_D (Punjab)",
        OriginCenter: "Batala_Simbel_D (Punjab)",
        FacilityCity: "Batala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1189,
        Pin: 506342,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1190,
        Pin: 799007,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1191,
        Pin: 743377,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1192,
        Pin: 393130,
        DispatchCenter: "Dediapada_Kevdi_DPP (Gujarat)",
        OriginCenter: "Dediapada_Kevdi_DPP (Gujarat)",
        FacilityCity: "Dediapada",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1193,
        Pin: 506316,
        DispatchCenter: "Ghanpur_Palakrty_D (Telangana)",
        OriginCenter: "Ghanpur_Palakrty_D (Telangana)",
        FacilityCity: "Ghanpur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1194,
        Pin: 271309,
        DispatchCenter: "Nawabganj_Shbapr_D (Uttar Pradesh)",
        OriginCenter: "Nawabganj_Shbapr_D (Uttar Pradesh)",
        FacilityCity: "Nawabganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1195,
        Pin: 394405,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1196,
        Pin: 734436,
        DispatchCenter: "Bagdogra_Kadopani_D (West Bengal)",
        OriginCenter: "Bagdogra_Kadopani_D (West Bengal)",
        FacilityCity: "Bagdogra",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1197,
        Pin: 621801,
        DispatchCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        OriginCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        FacilityCity: "Jayankondam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1198,
        Pin: 431802,
        DispatchCenter: "Bhokar_Sydngr_D (Maharashtra)",
        OriginCenter: "Bhokar_Sydngr_D (Maharashtra)",
        FacilityCity: "Bhokar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1199,
        Pin: 263620,
        DispatchCenter: "Almora_Makedi_D (Uttarakhand)",
        OriginCenter: "Almora_Makedi_D (Uttarakhand)",
        FacilityCity: "Almora",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1200,
        Pin: 203203,
        DispatchCenter: "Sikandrabad_Khatriwara_D (Uttar Pradesh)",
        OriginCenter: "Sikandrabad_Khatriwara_D (Uttar Pradesh)",
        FacilityCity: "Sikandrabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1201,
        Pin: 673001,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1202,
        Pin: 506371,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1203,
        Pin: 445304,
        DispatchCenter: "Wani_GuruNGR_D (Maharashtra)",
        OriginCenter: "Wani_GuruNGR_D (Maharashtra)",
        FacilityCity: "Wani",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1204,
        Pin: 312622,
        DispatchCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        OriginCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        FacilityCity: "Chittaurgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1205,
        Pin: 325207,
        DispatchCenter: "Baran_Housingboard_D (Rajasthan)",
        OriginCenter: "Baran_Housingboard_D (Rajasthan)",
        FacilityCity: "Baran",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1206,
        Pin: 391350,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1207,
        Pin: 642005,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1208,
        Pin: 210426,
        DispatchCenter: "Mahoba_Kulpahar_DPP (Uttar Pradesh)",
        OriginCenter: "Mahoba_Kulpahar_DPP (Uttar Pradesh)",
        FacilityCity: "Mahoba",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1209,
        Pin: 180013,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1210,
        Pin: 206247,
        DispatchCenter: "Auraiya_Brahmngr_D (Uttar Pradesh)",
        OriginCenter: "Auraiya_Brahmngr_D (Uttar Pradesh)",
        FacilityCity: "Auraiya",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1211,
        Pin: 628208,
        DispatchCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        OriginCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        FacilityCity: "Tiruchendur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1212,
        Pin: 444723,
        DispatchCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        OriginCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        FacilityCity: "Paratwada",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1213,
        Pin: 854332,
        DispatchCenter: "Forbesganj_Kursakanta_D (Bihar)",
        OriginCenter: "Forbesganj_Kursakanta_D (Bihar)",
        FacilityCity: "Forbesganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1214,
        Pin: 370630,
        DispatchCenter: "Dayapar_MaaCmplx_DPP (Gujarat)",
        OriginCenter: "Dayapar_MaaCmplx_DPP (Gujarat)",
        FacilityCity: "Dayapar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1215,
        Pin: 638005,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1216,
        Pin: 757051,
        DispatchCenter: "Bangriposi_Majigan_D (Orissa)",
        OriginCenter: "Bangriposi_Majigan_D (Orissa)",
        FacilityCity: "Bangriposi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1217,
        Pin: 736118,
        DispatchCenter: "CoochBehar_khagrabari_I (West Bengal)",
        OriginCenter: "CoochBehar_khagrabari_I (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1218,
        Pin: 123003,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1219,
        Pin: 632106,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1220,
        Pin: 852107,
        DispatchCenter: "Madhepura_Azadnagar_D (Bihar)",
        OriginCenter: "Madhepura_Azadnagar_D (Bihar)",
        FacilityCity: "Madhepura",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1221,
        Pin: 761120,
        DispatchCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        OriginCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        FacilityCity: "Bhanjanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1222,
        Pin: 758043,
        DispatchCenter: "Anandapur_Ghasipura_D (Orissa)",
        OriginCenter: "Anandapur_Ghasipura_D (Orissa)",
        FacilityCity: "Anandapur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1223,
        Pin: 783390,
        DispatchCenter: "Bongaigaon_Chapaguri_I (Assam)",
        OriginCenter: "Bongaigaon_Chapaguri_I (Assam)",
        FacilityCity: "Bongaigaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1224,
        Pin: 425401,
        DispatchCenter: "Amalner_Sriramcolony_DPP (Maharashtra)",
        OriginCenter: "Amalner_Sriramcolony_DPP (Maharashtra)",
        FacilityCity: "Amalner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1225,
        Pin: 517619,
        DispatchCenter: "Sriklahsti_SDKNgr_D (Andhra Pradesh)",
        OriginCenter: "Sriklahsti_SDKNgr_D (Andhra Pradesh)",
        FacilityCity: "Srikalahasti",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1226,
        Pin: 721141,
        DispatchCenter: "Midnapore_Harishpur_D (West Bengal)",
        OriginCenter: "Midnapore_Harishpur_D (West Bengal)",
        FacilityCity: "Midnapore",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1227,
        Pin: 691505,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1228,
        Pin: 141110,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1229,
        Pin: 202136,
        DispatchCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        OriginCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        FacilityCity: "Khair",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1230,
        Pin: 623527,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1231,
        Pin: 700139,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1232,
        Pin: 125101,
        DispatchCenter: "Sangaria_Wardno16_D (Rajasthan)",
        OriginCenter: "Sangaria_Wardno16_D (Rajasthan)",
        FacilityCity: "Sangaria",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1233,
        Pin: 146107,
        DispatchCenter: "Garhshankar_BangaRoad_D (Punjab)",
        OriginCenter: "Garhshankar_BangaRoad_D (Punjab)",
        FacilityCity: "Garhshanker",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1234,
        Pin: 277401,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1235,
        Pin: 502312,
        DispatchCenter: "Pregnapur_Tharakaramanagar_D (Telangana)",
        OriginCenter: "Pregnapur_Tharakaramanagar_D (Telangana)",
        FacilityCity: "Pregnapur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1236,
        Pin: 451443,
        DispatchCenter: "Khargone_NutanNgr_D (Madhya Pradesh)",
        OriginCenter: "Khargone_NutanNgr_D (Madhya Pradesh)",
        FacilityCity: "Khargone",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1237,
        Pin: 202155,
        DispatchCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        OriginCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        FacilityCity: "Khair",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1238,
        Pin: 843114,
        DispatchCenter: "Dholi_WardNo4_D (Bihar)",
        OriginCenter: "Dholi_WardNo4_D (Bihar)",
        FacilityCity: "Dholi",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1239,
        Pin: 209825,
        DispatchCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        OriginCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        FacilityCity: "Unnao",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1240,
        Pin: 689545,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1241,
        Pin: 761054,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1242,
        Pin: 362226,
        DispatchCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        OriginCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        FacilityCity: "Keshod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1243,
        Pin: 461221,
        DispatchCenter: "SeoniMalwa_Bhlpra_DPP (Madhya Pradesh)",
        OriginCenter: "SeoniMalwa_Bhlpra_DPP (Madhya Pradesh)",
        FacilityCity: "Seoni Malwa",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1244,
        Pin: 797115,
        DispatchCenter: "Dimapur_Sovima_I (Nagaland)",
        OriginCenter: "Dimapur_Sovima_I (Nagaland)",
        FacilityCity: "Dimapur",
        FacilityState: "Nagaland",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1245,
        Pin: 686103,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1246,
        Pin: 307023,
        DispatchCenter: "Pindwara_AjariChwk_DPP (Rajasthan)",
        OriginCenter: "Pindwara_AjariChwk_DPP (Rajasthan)",
        FacilityCity: "Pindwara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1247,
        Pin: 627955,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1248,
        Pin: 504104,
        DispatchCenter: "Bhainsa_Pipri_D (Telangana)",
        OriginCenter: "Bhainsa_Pipri_D (Telangana)",
        FacilityCity: "Bhainsa",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1249,
        Pin: 416101,
        DispatchCenter: "Jaysingpur_Swapnanagari_D (Maharashtra)",
        OriginCenter: "Jaysingpur_Swapnanagari_D (Maharashtra)",
        FacilityCity: "Jaysingpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1250,
        Pin: 244002,
        DispatchCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        OriginCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        FacilityCity: "Moradabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1251,
        Pin: 524344,
        DispatchCenter: "Nellore_Bccolony_D (Andhra Pradesh)",
        OriginCenter: "Nellore_Bccolony_D (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1252,
        Pin: 221309,
        DispatchCenter: "Gopiganj_Gerai_D (Uttar Pradesh)",
        OriginCenter: "Gopiganj_Gerai_D (Uttar Pradesh)",
        FacilityCity: "Gopiganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1253,
        Pin: 262520,
        DispatchCenter: "Pithorgarh_Kumud_D (Uttarakhand)",
        OriginCenter: "Pithorgarh_Kumud_D (Uttarakhand)",
        FacilityCity: "Pithoragarh",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1254,
        Pin: 828121,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1255,
        Pin: 141414,
        DispatchCenter: "Khanna_Motinagar_D (Punjab)",
        OriginCenter: "Khanna_Motinagar_D (Punjab)",
        FacilityCity: "Khanna",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1256,
        Pin: 785681,
        DispatchCenter: "Teok_Wardno3_D (Assam)",
        OriginCenter: "Teok_Wardno3_D (Assam)",
        FacilityCity: "Teok",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1257,
        Pin: 802103,
        DispatchCenter: "Buxar_SohaniPatti_D (Bihar)",
        OriginCenter: "Buxar_SohaniPatti_D (Bihar)",
        FacilityCity: "Buxar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1258,
        Pin: 274506,
        DispatchCenter: "Salempur_Banjaria_D (Uttar Pradesh)",
        OriginCenter: "Salempur_Banjaria_D (Uttar Pradesh)",
        FacilityCity: "Salempur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1259,
        Pin: 761001,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1260,
        Pin: 361140,
        DispatchCenter: "Jamnagar_Chela_L (Gujarat)",
        OriginCenter: "Jamnagar_Chela_L (Gujarat)",
        FacilityCity: "Jamnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1261,
        Pin: 500026,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1262,
        Pin: 172031,
        DispatchCenter: "Nankhari_Blockoffice_DPP (Himachal Pradesh)",
        OriginCenter: "Nankhari_Blockoffice_DPP (Himachal Pradesh)",
        FacilityCity: "Nankhari",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1263,
        Pin: 641111,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1264,
        Pin: 670308,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1265,
        Pin: 561207,
        DispatchCenter: "Bagepalli_Chikballapur_D (Karnataka)",
        OriginCenter: "Bagepalli_Chikballapur_D (Karnataka)",
        FacilityCity: "Bagepalli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1266,
        Pin: 483220,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1267,
        Pin: 584127,
        DispatchCenter: "Lingasugur_Shivnagara_D (Karnataka)",
        OriginCenter: "Lingasugur_Shivnagara_D (Karnataka)",
        FacilityCity: "Lingasugur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1268,
        Pin: 413010,
        DispatchCenter: "Solapur_Balepeth_I (Maharashtra)",
        OriginCenter: "Solapur_Balepeth_I (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1269,
        Pin: 441905,
        DispatchCenter: "Bhandara_Mohadi_DPP (Maharashtra)",
        OriginCenter: "Bhandara_Mohadi_DPP (Maharashtra)",
        FacilityCity: "Bhandara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1270,
        Pin: 246148,
        DispatchCenter: "SrinagarUK_Bagwan_D (Uttarakhand)",
        OriginCenter: "SrinagarUK_Bagwan_D (Uttarakhand)",
        FacilityCity: "Srinagar-UK",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1271,
        Pin: 151108,
        DispatchCenter: "Barnala_Bhadaur_D (Punjab)",
        OriginCenter: "Barnala_Bhadaur_D (Punjab)",
        FacilityCity: "Bhadaur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1272,
        Pin: 333705,
        DispatchCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        OriginCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        FacilityCity: "Jhunjhunu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1273,
        Pin: 768204,
        DispatchCenter: "Jharsuguda_Ekatali_D (Orissa)",
        OriginCenter: "Jharsuguda_Ekatali_D (Orissa)",
        FacilityCity: "Jharsuguda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1274,
        Pin: 852138,
        DispatchCenter: "Supaul_SadrHsptl_D (Bihar)",
        OriginCenter: "Supaul_SadrHsptl_D (Bihar)",
        FacilityCity: "Supaul",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1275,
        Pin: 787057,
        DispatchCenter: "Dhemaji_Ward6_D (Assam)",
        OriginCenter: "Dhemaji_Ward6_D (Assam)",
        FacilityCity: "Dhemaji",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1276,
        Pin: 125004,
        DispatchCenter: "Hisar_Dabra_H (Haryana)",
        OriginCenter: "Hisar_Dabra_H (Haryana)",
        FacilityCity: "Hisar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1277,
        Pin: 585101,
        DispatchCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        OriginCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        FacilityCity: "Gulbarga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1278,
        Pin: 229201,
        DispatchCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        OriginCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        FacilityCity: "Lalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1279,
        Pin: 591182,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1280,
        Pin: 209726,
        DispatchCenter: "Kannauj_Kazitola_D (Uttar Pradesh)",
        OriginCenter: "Kannauj_Kazitola_D (Uttar Pradesh)",
        FacilityCity: "Kannauj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1281,
        Pin: 503301,
        DispatchCenter: "Banswada_Teacherscolony_D (Telangana)",
        OriginCenter: "Banswada_Teacherscolony_D (Telangana)",
        FacilityCity: "Banswada",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1282,
        Pin: 509351,
        DispatchCenter: "Mahabubnagar_Devarakadra_DPP (Telangana)",
        OriginCenter: "Mahabubnagar_Devarakadra_DPP (Telangana)",
        FacilityCity: "Mahabubnagar",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1283,
        Pin: 686610,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1284,
        Pin: 573121,
        DispatchCenter: "Belur_Halebeedu_D (Karnataka)",
        OriginCenter: "Belur_Halebeedu_D (Karnataka)",
        FacilityCity: "Belur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1285,
        Pin: 713204,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1286,
        Pin: 824237,
        DispatchCenter: "Sherghati_GayaRd_D (Bihar)",
        OriginCenter: "Sherghati_GayaRd_D (Bihar)",
        FacilityCity: "Sherghati",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1287,
        Pin: 743385,
        DispatchCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        OriginCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        FacilityCity: "Dakshin Barasat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1288,
        Pin: 141114,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1289,
        Pin: 415019,
        DispatchCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        OriginCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        FacilityCity: "Satara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1290,
        Pin: 226106,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1291,
        Pin: 321303,
        DispatchCenter: "Bharatpur_DurgaNagar_D (Rajasthan)",
        OriginCenter: "Bharatpur_DurgaNagar_D (Rajasthan)",
        FacilityCity: "Bharatpur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1292,
        Pin: 533229,
        DispatchCenter: "Mandapeta_Ravulapalem_DPP (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Ravulapalem_DPP (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1293,
        Pin: 630612,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        Zone : "S1"
      },
      {
        SN: 1294,
        Pin: 534442,
        DispatchCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        OriginCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        FacilityCity: "Eluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1295,
        Pin: 175124,
        DispatchCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        OriginCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        FacilityCity: "Mandi",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1296,
        Pin: 639108,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1297,
        Pin: 691551,
        DispatchCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        OriginCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1298,
        Pin: 321410,
        DispatchCenter: "Bayana_Shivcolony_D (Rajasthan)",
        OriginCenter: "Bayana_Shivcolony_D (Rajasthan)",
        FacilityCity: "Bayana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1299,
        Pin: 516126,
        DispatchCenter: "Rajampet_BypassRd_D (Andhra Pradesh)",
        OriginCenter: "Rajampet_BypassRd_D (Andhra Pradesh)",
        FacilityCity: "Rajampet",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1300,
        Pin: 400058,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1301,
        Pin: 441912,
        DispatchCenter: "Tumsar_Dewhadi_DPP (Maharashtra)",
        OriginCenter: "Tumsar_Dewhadi_DPP (Maharashtra)",
        FacilityCity: "Tumsar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1302,
        Pin: 326037,
        DispatchCenter: "Aklera_Mangta_D (Rajasthan)",
        OriginCenter: "Aklera_Mangta_D (Rajasthan)",
        FacilityCity: "Aklera",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1303,
        Pin: 627754,
        DispatchCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        OriginCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        FacilityCity: "Sankarankovil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1304,
        Pin: 160004,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1305,
        Pin: 670705,
        DispatchCenter: "Chavassery_Kelanpeedika_D (Kerala)",
        OriginCenter: "Chavassery_Kelanpeedika_D (Kerala)",
        FacilityCity: "Chavassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1306,
        Pin: 400008,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1307,
        Pin: 415010,
        DispatchCenter: "Koregaon_Lokmanyanagar_D (Maharashtra)",
        OriginCenter: "Koregaon_Lokmanyanagar_D (Maharashtra)",
        FacilityCity: "Koregaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1308,
        Pin: 516259,
        DispatchCenter: "Vempalli_Kathaluru_D (Andhra Pradesh)",
        OriginCenter: "Vempalli_Kathaluru_D (Andhra Pradesh)",
        FacilityCity: "Vempalli",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1309,
        Pin: 847223,
        DispatchCenter: "Benipatti_Sarisab_D (Bihar)",
        OriginCenter: "Benipatti_Sarisab_D (Bihar)",
        FacilityCity: "Benipatti",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1310,
        Pin: 160027,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1311,
        Pin: 689659,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1312,
        Pin: 506252,
        DispatchCenter: "Ghanpur_Palakrty_D (Telangana)",
        OriginCenter: "Ghanpur_Palakrty_D (Telangana)",
        FacilityCity: "Ghanpur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1313,
        Pin: 388510,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1314,
        Pin: 206126,
        DispatchCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        OriginCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        FacilityCity: "Etawah",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1315,
        Pin: 585414,
        DispatchCenter: "Humnabad_ApmcYard_DPP (Karnataka)",
        OriginCenter: "Humnabad_ApmcYard_DPP (Karnataka)",
        FacilityCity: "Humnabad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1316,
        Pin: 623530,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1317,
        Pin: 144411,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1318,
        Pin: 201002,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1319,
        Pin: 131103,
        DispatchCenter: "Sonipat_Sector51_L (Haryana)",
        OriginCenter: "Sonipat_WazidpurSaboli_P (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1320,
        Pin: 673522,
        DispatchCenter: "Koyilandy_Kizhur_D (Kerala)",
        OriginCenter: "Koyilandy_Kizhur_D (Kerala)",
        FacilityCity: "Koyilandy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1321,
        Pin: 800003,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1322,
        Pin: 732141,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1323,
        Pin: 208013,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1324,
        Pin: 575001,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1325,
        Pin: 493441,
        DispatchCenter: "Mahasamund_WardNo15_D (Chhattisgarh)",
        OriginCenter: "Mahasamund_WardNo15_D (Chhattisgarh)",
        FacilityCity: "Mahasamund",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1326,
        Pin: 250341,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1327,
        Pin: 125003,
        DispatchCenter: "Hisar_Dabra_H (Haryana)",
        OriginCenter: "Hisar_Dabra_H (Haryana)",
        FacilityCity: "Hisar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1328,
        Pin: 524411,
        DispatchCenter: "Vidyanagar_Kota_D (Andhra Pradesh)",
        OriginCenter: "Vidyanagar_Kota_D (Andhra Pradesh)",
        FacilityCity: "Vidyanagar",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1329,
        Pin: 391346,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1330,
        Pin: 623107,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1331,
        Pin: 835228,
        DispatchCenter: "Simdega_KhutiTola_D (Jharkhand)",
        OriginCenter: "Simdega_KhutiTola_D (Jharkhand)",
        FacilityCity: "Simdega",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1332,
        Pin: 403107,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1333,
        Pin: 484661,
        DispatchCenter: "Umaria_BadhvgarhRd_D (Madhya Pradesh)",
        OriginCenter: "Umaria_BadhvgarhRd_D (Madhya Pradesh)",
        FacilityCity: "Umaria",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 1334,
        Pin: 623106,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1335,
        Pin: 638211,
        DispatchCenter: "Tiruchengode_Kailasmplym_D (Tamil Nadu)",
        OriginCenter: "Tiruchengode_Kailasmplym_D (Tamil Nadu)",
        FacilityCity: "Tiruchengode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1336,
        Pin: 209112,
        DispatchCenter: "Pukhrayan_Arulimod_D (Uttar Pradesh)",
        OriginCenter: "Pukhrayan_Arulimod_D (Uttar Pradesh)",
        FacilityCity: "Pukhrayan",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1337,
        Pin: 466331,
        DispatchCenter: "Nasrullaganj_RujanKhedi_D (Madhya Pradesh)",
        OriginCenter: "Nasrullaganj_RujanKhedi_D (Madhya Pradesh)",
        FacilityCity: "Nasrullaganj",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 1338,
        Pin: 274301,
        DispatchCenter: "Kaptanganj_Subshngr_D (Uttar Pradesh)",
        OriginCenter: "Kaptanganj_Subshngr_D (Uttar Pradesh)",
        FacilityCity: "Kaptanganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1339,
        Pin: 623566,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1340,
        Pin: 530031,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1341,
        Pin: 462022,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1342,
        Pin: 285206,
        DispatchCenter: "Konch_Central_D_2 (Uttar Pradesh)",
        OriginCenter: "Konch_Central_D_2 (Uttar Pradesh)",
        FacilityCity: "Konch",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1343,
        Pin: 360018,
        DispatchCenter: "Rajkot_Amargadh_H (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1344,
        Pin: 813228,
        DispatchCenter: "Sultanganj_Dilgouri_D (Bihar)",
        OriginCenter: "Sultanganj_Dilgouri_D (Bihar)",
        FacilityCity: "Sultanganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1345,
        Pin: 822133,
        DispatchCenter: "Garhwa_Bishunpur_D (Jharkhand)",
        OriginCenter: "Garhwa_Bishunpur_D (Jharkhand)",
        FacilityCity: "Garhwa",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1346,
        Pin: 608702,
        DispatchCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        OriginCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        FacilityCity: "Chidambaram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1347,
        Pin: 246487,
        DispatchCenter: "Chamoli_Karnaprayag_D (Uttarakhand)",
        OriginCenter: "Chamoli_Karnaprayag_D (Uttarakhand)",
        FacilityCity: "Chamoli",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1348,
        Pin: 521156,
        DispatchCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        OriginCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        FacilityCity: "Pamarru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1349,
        Pin: 176055,
        DispatchCenter: "Khundian_Koka_DPP (Himachal Pradesh)",
        OriginCenter: "Khundian_Koka_DPP (Himachal Pradesh)",
        FacilityCity: "Khundian",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1350,
        Pin: 686698,
        DispatchCenter: "Perumbavoor_Kizhake_D (Kerala)",
        OriginCenter: "Perumbavoor_Kizhake_D (Kerala)",
        FacilityCity: "Perumbavoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1351,
        Pin: 171206,
        DispatchCenter: "Jubbal_Jhaldi_D (Himachal Pradesh)",
        OriginCenter: "Jubbal_Jhaldi_D (Himachal Pradesh)",
        FacilityCity: "Jubbal",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1352,
        Pin: 505460,
        DispatchCenter: "Koratla_PRBM_DPP (Telangana)",
        OriginCenter: "Koratla_PRBM_DPP (Telangana)",
        FacilityCity: "Koratla",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1353,
        Pin: 577427,
        DispatchCenter: "Shikaripur_Mandipet_D (Karnataka)",
        OriginCenter: "Shikaripur_Mandipet_D (Karnataka)",
        FacilityCity: "Shikaripur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1354,
        Pin: 521134,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1355,
        Pin: 605202,
        DispatchCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        OriginCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        FacilityCity: "Viluppuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1356,
        Pin: 685587,
        DispatchCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        OriginCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        FacilityCity: "Thodupuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1357,
        Pin: 391320,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1358,
        Pin: 781134,
        DispatchCenter: "Chhaygaon_Dhekenabari_D (Assam)",
        OriginCenter: "Chhaygaon_Dhekenabari_D (Assam)",
        FacilityCity: "Chhaygaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1359,
        Pin: 532401,
        DispatchCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        OriginCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        FacilityCity: "Srikakulam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1360,
        Pin: 247665,
        DispatchCenter: "Roorkee_Manglaur_D (Uttarakhand)",
        OriginCenter: "Roorkee_Manglaur_D (Uttarakhand)",
        FacilityCity: "Roorkee",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1361,
        Pin: 142055,
        DispatchCenter: "Barnala_Bhadaur_D (Punjab)",
        OriginCenter: "Barnala_Bhadaur_D (Punjab)",
        FacilityCity: "Bhadaur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1362,
        Pin: 385010,
        DispatchCenter: "Palanpur_Esbipura1_D (Gujarat)",
        OriginCenter: "Palanpur_Esbipura1_D (Gujarat)",
        FacilityCity: "Palanpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1363,
        Pin: 824203,
        DispatchCenter: "Goh_Golapar_D (Bihar)",
        OriginCenter: "Goh_Golapar_D (Bihar)",
        FacilityCity: "Goh",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1364,
        Pin: 508205,
        DispatchCenter: "Nalgonda_Duppalapally_I (Telangana)",
        OriginCenter: "Nalgonda_Duppalapally_I (Telangana)",
        FacilityCity: "Nalgonda",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1365,
        Pin: 759116,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1366,
        Pin: 493332,
        DispatchCenter: "BalodaBazar_Pahanda_D (Chhattisgarh)",
        OriginCenter: "BalodaBazar_Pahanda_D (Chhattisgarh)",
        FacilityCity: "Baloda Bazar",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1367,
        Pin: 443201,
        DispatchCenter: "Amdapur_Haralkhed_DPP (Maharashtra)",
        OriginCenter: "Amdapur_Haralkhed_DPP (Maharashtra)",
        FacilityCity: "Amdapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1368,
        Pin: 632104,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1369,
        Pin: 201019,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1370,
        Pin: 841446,
        DispatchCenter: "Siwan_Andar_D (Bihar)",
        OriginCenter: "Siwan_Andar_D (Bihar)",
        FacilityCity: "Siwan",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1371,
        Pin: 424003,
        DispatchCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        OriginCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        FacilityCity: "Dhule",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1372,
        Pin: 785697,
        DispatchCenter: "Sivasagar_Pdinnagar_D (Assam)",
        OriginCenter: "Sivasagar_Pdinnagar_D (Assam)",
        FacilityCity: "Sivasagar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1373,
        Pin: 786692,
        DispatchCenter: "Duliajan_StatonRd_D (Assam)",
        OriginCenter: "Duliajan_StatonRd_D (Assam)",
        FacilityCity: "Duliajan",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1374,
        Pin: 142035,
        DispatchCenter: "Jagraon_Grainmkt_D (Punjab)",
        OriginCenter: "Jagraon_Grainmkt_D (Punjab)",
        FacilityCity: "Jagraon",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1375,
        Pin: 518395,
        DispatchCenter: "Alur_VaddeStreet_D (Andhra Pradesh)",
        OriginCenter: "Alur_VaddeStreet_D (Andhra Pradesh)",
        FacilityCity: "Alur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1376,
        Pin: 175001,
        DispatchCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        OriginCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        FacilityCity: "Mandi",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1377,
        Pin: 689503,
        DispatchCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        OriginCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1378,
        Pin: 788709,
        DispatchCenter: "Karimganj_Sarisa_D (Assam)",
        OriginCenter: "Karimganj_Sarisa_D (Assam)",
        FacilityCity: "Karimganj",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1379,
        Pin: 768028,
        DispatchCenter: "Bargarh_Ambapali_D (Orissa)",
        OriginCenter: "Bargarh_Ambapali_D (Orissa)",
        FacilityCity: "Bargarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1380,
        Pin: 563130,
        DispatchCenter: "Bangalore_Koralur_L (Karnataka)",
        OriginCenter: "Bangalore_Soukyaroad_GW (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1381,
        Pin: 533448,
        DispatchCenter: "Kothapalli_Vaakathippa_DPP (Andhra Pradesh)",
        OriginCenter: "Kothapalli_Vaakathippa_DPP (Andhra Pradesh)",
        FacilityCity: "Kothapalli",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1382,
        Pin: 332315,
        DispatchCenter: "Laxmangarh_Wrd19DPP_D (Rajasthan)",
        OriginCenter: "Laxmangarh_Wrd19DPP_D (Rajasthan)",
        FacilityCity: "Laxmangarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1383,
        Pin: 110031,
        DispatchCenter: "Delhi_RailYardRd_L (Delhi)",
        OriginCenter: "Delhi_RailYardRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1384,
        Pin: 814120,
        DispatchCenter: "Deoghar_Taljhari_D (Jharkhand)",
        OriginCenter: "Deoghar_Taljhari_D (Jharkhand)",
        FacilityCity: "Deoghar",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1385,
        Pin: 497114,
        DispatchCenter: "Pathalgaon_Kargilchowk_D (Chhattisgarh)",
        OriginCenter: "Pathalgaon_Kargilchowk_D (Chhattisgarh)",
        FacilityCity: "Pathalgaon",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1386,
        Pin: 177021,
        DispatchCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        OriginCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        FacilityCity: "Jahu",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1387,
        Pin: 601302,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1388,
        Pin: 401502,
        DispatchCenter: "Palghar_Maan_L (Maharashtra)",
        OriginCenter: "Palghar_Maan_L (Maharashtra)",
        FacilityCity: "Palghar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1389,
        Pin: 613704,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1390,
        Pin: 575014,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1391,
        Pin: 688529,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1392,
        Pin: 494122,
        DispatchCenter: "Sukma_Dornapal_D (Chhattisgarh)",
        OriginCenter: "Sukma_Dornapal_D (Chhattisgarh)",
        FacilityCity: "Sukma",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1393,
        Pin: 127025,
        DispatchCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        OriginCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        FacilityCity: "Charkhi Dadri",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1394,
        Pin: 313211,
        DispatchCenter: "Fatehnagar_ITALI_DPP (Rajasthan)",
        OriginCenter: "Fatehnagar_ITALI_DPP (Rajasthan)",
        FacilityCity: "Fatehnagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1395,
        Pin: 170001,
        DispatchCenter: "shimla (Himachal Pradesh)",
        OriginCenter: "shimla (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1396,
        Pin: 690508,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1397,
        Pin: 534411,
        DispatchCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        OriginCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        FacilityCity: "Tadepalligudem",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1398,
        Pin: 768036,
        DispatchCenter: "Padampur_Shantinagar_D (Orissa)",
        OriginCenter: "Padampur_Shantinagar_D (Orissa)",
        FacilityCity: "Padampur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1399,
        Pin: 603102,
        DispatchCenter: "Tirukalukundram_Kalpakkam_D (Tamil Nadu)",
        OriginCenter: "Tirukalukundram_Kalpakkam_D (Tamil Nadu)",
        FacilityCity: "Tirukalukundram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1400,
        Pin: 782445,
        DispatchCenter: "Hojai_KrishnaNagar_D (Assam)",
        OriginCenter: "Hojai_KrishnaNagar_D (Assam)",
        FacilityCity: "Hojai",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1401,
        Pin: 521001,
        DispatchCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        OriginCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        FacilityCity: "Machilipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1402,
        Pin: 134110,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1403,
        Pin: 321025,
        DispatchCenter: "Bharatpur_DurgaNagar_D (Rajasthan)",
        OriginCenter: "Bharatpur_DurgaNagar_D (Rajasthan)",
        FacilityCity: "Bharatpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1404,
        Pin: 210100,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1405,
        Pin: 627302,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1406,
        Pin: 627121,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1407,
        Pin: 422206,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1408,
        Pin: 423603,
        DispatchCenter: "Kopargaon_Murshatpur_D (Maharashtra)",
        OriginCenter: "Kopargaon_Murshatpur_D (Maharashtra)",
        FacilityCity: "Kopargaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1409,
        Pin: 627951,
        DispatchCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        OriginCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        FacilityCity: "Sankarankovil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1410,
        Pin: 202398,
        DispatchCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        OriginCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        FacilityCity: "Anupshahar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1411,
        Pin: 577549,
        DispatchCenter: "Kadur_Ajjampura_D (Karnataka)",
        OriginCenter: "Kadur_Ajjampura_D (Karnataka)",
        FacilityCity: "Kadur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1412,
        Pin: 742301,
        DispatchCenter: "Khargram_Parbatipur_D (West Bengal)",
        OriginCenter: "Khargram_Parbatipur_D (West Bengal)",
        FacilityCity: "Khargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1413,
        Pin: 600109,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1414,
        Pin: 845433,
        DispatchCenter: "Raxaul_Nonea_D (Bihar)",
        OriginCenter: "Raxaul_Nonea_D (Bihar)",
        FacilityCity: "Raxaul",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1415,
        Pin: 201317,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        Zone : "N1"
      },
      {
        SN: 1416,
        Pin: 573125,
        DispatchCenter: "Belur_Halebeedu_D (Karnataka)",
        OriginCenter: "Belur_Halebeedu_D (Karnataka)",
        FacilityCity: "Belur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1417,
        Pin: 610202,
        DispatchCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        FacilityCity: "Thiruthuraipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1418,
        Pin: 560097,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1419,
        Pin: 686106,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1420,
        Pin: 303101,
        DispatchCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1421,
        Pin: 624709,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1422,
        Pin: 441118,
        DispatchCenter: "Wardha_Alodi_D (Maharashtra)",
        OriginCenter: "Wardha_Alodi_D (Maharashtra)",
        FacilityCity: "Wardha",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1423,
        Pin: 227107,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1424,
        Pin: 360003,
        DispatchCenter: "Rajkot_Amargadh_H (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1425,
        Pin: 431402,
        DispatchCenter: "Parbhani_Gajanannagar_D (Maharashtra)",
        OriginCenter: "Parbhani_Gajanannagar_D (Maharashtra)",
        FacilityCity: "Parbhani",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1426,
        Pin: 723212,
        DispatchCenter: "Jhalda_GMukrDPP_D (West Bengal)",
        OriginCenter: "Jhalda_GMukrDPP_D (West Bengal)",
        FacilityCity: "Jhalda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1427,
        Pin: 515571,
        DispatchCenter: "Kadiri_NagireddyPalli_D (Andhra Pradesh)",
        OriginCenter: "Kadiri_NagireddyPalli_D (Andhra Pradesh)",
        FacilityCity: "Kadiri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1428,
        Pin: 723101,
        DispatchCenter: "Purulia_GariDPP_D (West Bengal)",
        OriginCenter: "Purulia_GariDPP_D (West Bengal)",
        FacilityCity: "Purulia",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1429,
        Pin: 230131,
        DispatchCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        OriginCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        FacilityCity: "Pratapgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1430,
        Pin: 686507,
        DispatchCenter: "Kottayam_Mulakunnath_D (Kerala)",
        OriginCenter: "Kottayam_Mulakunnath_D (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1431,
        Pin: 281504,
        DispatchCenter: "Chhata_Krishnacolony_D (Uttar Pradesh)",
        OriginCenter: "Chhata_Krishnacolony_D (Uttar Pradesh)",
        FacilityCity: "Chhata",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1432,
        Pin: 192101,
        DispatchCenter: "Anantnag_QaziBagh_DPP (Jammu & Kashmir)",
        OriginCenter: "Anantnag_QaziBagh_DPP (Jammu & Kashmir)",
        FacilityCity: "Anantnag",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1433,
        Pin: 686547,
        DispatchCenter: "Ranni_Chethakhl_D (Kerala)",
        OriginCenter: "Ranni_Chethakhl_D (Kerala)",
        FacilityCity: "Ranni",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1434,
        Pin: 460002,
        DispatchCenter: "Betul_Bheemnagar_D (Madhya Pradesh)",
        OriginCenter: "Betul_Bheemnagar_D (Madhya Pradesh)",
        FacilityCity: "Betul",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 1435,
        Pin: 754144,
        DispatchCenter: "Paradip_Udyabata_D (Orissa)",
        OriginCenter: "Paradip_Udyabata_D (Orissa)",
        FacilityCity: "Paradip",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1436,
        Pin: 321204,
        DispatchCenter: "Kaman_BsstdDPP_D (Rajasthan)",
        OriginCenter: "Kaman_BsstdDPP_D (Rajasthan)",
        FacilityCity: "Kaman",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1437,
        Pin: 754134,
        DispatchCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        OriginCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1438,
        Pin: 600005,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1439,
        Pin: 247669,
        DispatchCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        OriginCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        FacilityCity: "Saharanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1440,
        Pin: 144625,
        DispatchCenter: "SultanpurLodhi_Gill_D (Punjab)",
        OriginCenter: "SultanpurLodhi_Gill_D (Punjab)",
        FacilityCity: "Sultanpur Lodhi",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1441,
        Pin: 221716,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1442,
        Pin: 713402,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1443,
        Pin: 415015,
        DispatchCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        OriginCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        FacilityCity: "Satara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1444,
        Pin: 686544,
        DispatchCenter: "Ranni_Chethakhl_D (Kerala)",
        OriginCenter: "Ranni_Chethakhl_D (Kerala)",
        FacilityCity: "Ranni",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1445,
        Pin: 422306,
        DispatchCenter: "Lasalgaon_TelephnExch_D (Maharashtra)",
        OriginCenter: "Lasalgaon_TelephnExch_D (Maharashtra)",
        FacilityCity: "Lasalgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1446,
        Pin: 243636,
        DispatchCenter: "Sahaswan_Mainmarket_D (Uttar Pradesh)",
        OriginCenter: "Sahaswan_Mainmarket_D (Uttar Pradesh)",
        FacilityCity: "Sahaswan",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1447,
        Pin: 624704,
        DispatchCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        OriginCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        FacilityCity: "Oddanchatram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1448,
        Pin: 262801,
        DispatchCenter: "Gola_BkgnRoad_D (Uttar Pradesh)",
        OriginCenter: "Gola_BkgnRoad_D (Uttar Pradesh)",
        FacilityCity: "Gola",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1449,
        Pin: 132110,
        DispatchCenter: "Panipat_Risalu_P (Haryana)",
        OriginCenter: "Panipat_Risalu_P (Haryana)",
        FacilityCity: "Panipat",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1450,
        Pin: 784521,
        DispatchCenter: "Udalguri_Milonpur_D (Assam)",
        OriginCenter: "Udalguri_Milonpur_D (Assam)",
        FacilityCity: "Mangaldoi",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1451,
        Pin: 505416,
        DispatchCenter: "KarimNagar_Alugunoor_I (Telangana)",
        OriginCenter: "KarimNagar_Alugunoor_I (Telangana)",
        FacilityCity: "Karim Nagar",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1452,
        Pin: 630215,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1453,
        Pin: 497778,
        DispatchCenter: "Janakpur_SichaiColony_D (Chhattisgarh)",
        OriginCenter: "Janakpur_SichaiColony_D (Chhattisgarh)",
        FacilityCity: "Janakpur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1454,
        Pin: 306421,
        DispatchCenter: "Pali_Transportnagar_I (Rajasthan)",
        OriginCenter: "Pali_Transportnagar_I (Rajasthan)",
        FacilityCity: "Pali",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1455,
        Pin: 521245,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1456,
        Pin: 755028,
        DispatchCenter: "Sukinda_Tamaka_DPP (Orissa)",
        OriginCenter: "Sukinda_Tamaka_DPP (Orissa)",
        FacilityCity: "Anandapur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1457,
        Pin: 523372,
        DispatchCenter: "CumbumAP_BhagyaNagar_D (Andhra Pradesh)",
        OriginCenter: "CumbumAP_BhagyaNagar_D (Andhra Pradesh)",
        FacilityCity: "CumbumAP",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1458,
        Pin: 208010,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1459,
        Pin: 625016,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1460,
        Pin: 757023,
        DispatchCenter: "Baripada_KalikDPP_D (Orissa)",
        OriginCenter: "Baripada_KalikDPP_D (Orissa)",
        FacilityCity: "Baripada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1461,
        Pin: 680642,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1462,
        Pin: 799002,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1463,
        Pin: 583278,
        DispatchCenter: "Kushtagi_Karimacolony_DPP (Karnataka)",
        OriginCenter: "Kushtagi_Karimacolony_DPP (Karnataka)",
        FacilityCity: "Kushtagi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1464,
        Pin: 246424,
        DispatchCenter: "Chamoli_CGRoad_D (Uttarakhand)",
        OriginCenter: "Chamoli_CGRoad_D (Uttarakhand)",
        FacilityCity: "Chamoli",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1465,
        Pin: 756125,
        DispatchCenter: "Bhadrak_Deuli_D (Orissa)",
        OriginCenter: "Bhadrak_Deuli_D (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1466,
        Pin: 673525,
        DispatchCenter: "Perambra_KayipramRd_D (Kerala)",
        OriginCenter: "Perambra_KayipramRd_D (Kerala)",
        FacilityCity: "Perambra",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1467,
        Pin: 600104,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1468,
        Pin: 141103,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1469,
        Pin: 494665,
        DispatchCenter: "Bhanupratappur_Antagarh_D (Chhattisgarh)",
        OriginCenter: "Bhanupratappur_Antagarh_D (Chhattisgarh)",
        FacilityCity: "Bhanupratappur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1470,
        Pin: 584143,
        DispatchCenter: "Manvi_Jayanagar_D (Karnataka)",
        OriginCenter: "Manvi_Jayanagar_D (Karnataka)",
        FacilityCity: "Manvi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1471,
        Pin: 712615,
        DispatchCenter: "Arambag_BalibDPP_D (West Bengal)",
        OriginCenter: "Arambag_BalibDPP_D (West Bengal)",
        FacilityCity: "Arambag",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1472,
        Pin: 515551,
        DispatchCenter: "Kadiri_NagireddyPalli_D (Andhra Pradesh)",
        OriginCenter: "Kadiri_NagireddyPalli_D (Andhra Pradesh)",
        FacilityCity: "Kadiri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1473,
        Pin: 670105,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1474,
        Pin: 413737,
        DispatchCenter: "Loni_BailBzr_DPP (Maharashtra)",
        OriginCenter: "Loni_BailBzr_DPP (Maharashtra)",
        FacilityCity: "Loni",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1475,
        Pin: 450119,
        DispatchCenter: "Mundi_Kanud_D (Madhya Pradesh)",
        OriginCenter: "Mundi_Kanud_D (Madhya Pradesh)",
        FacilityCity: "Mundi",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 1476,
        Pin: 621729,
        DispatchCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        OriginCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        FacilityCity: "Ariyalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1477,
        Pin: 151502,
        DispatchCenter: "Mansa_Budhlada2_D (Punjab)",
        OriginCenter: "Mansa_Budhlada2_D (Punjab)",
        FacilityCity: "Mansa",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1478,
        Pin: 533406,
        DispatchCenter: "Kathipudi_Shankhavaram_D (Andhra Pradesh)",
        OriginCenter: "Kathipudi_Shankhavaram_D (Andhra Pradesh)",
        FacilityCity: "Annavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1479,
        Pin: 421999,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1480,
        Pin: 174030,
        DispatchCenter: "Dulchehra_Rapar_DPP (Himachal Pradesh)",
        OriginCenter: "Dulchehra_Rapar_DPP (Himachal Pradesh)",
        FacilityCity: "Dulchehra",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1481,
        Pin: 382018,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1482,
        Pin: 799250,
        DispatchCenter: "Dharmanagar_Ward3_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Dharmanagar",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1483,
        Pin: 125006,
        DispatchCenter: "Hisar_Dabra_H (Haryana)",
        OriginCenter: "Hisar_Dabra_H (Haryana)",
        FacilityCity: "Hisar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1484,
        Pin: 721634,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1485,
        Pin: 415303,
        DispatchCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        OriginCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        FacilityCity: "Islampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1486,
        Pin: 722180,
        DispatchCenter: "Bankura_KeranDPP_D (West Bengal)",
        OriginCenter: "Bankura_KeranDPP_D (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1487,
        Pin: 402120,
        DispatchCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        OriginCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        FacilityCity: "Mangaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1488,
        Pin: 495554,
        DispatchCenter: "Akaltara_IndraUdyan_D (Chhattisgarh)",
        OriginCenter: "Akaltara_IndraUdyan_D (Chhattisgarh)",
        FacilityCity: "Akaltara",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1489,
        Pin: 700027,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1490,
        Pin: 581334,
        DispatchCenter: "Honnavar_ForestColony_D (Karnataka)",
        OriginCenter: "Honnavar_ForestColony_D (Karnataka)",
        FacilityCity: "Honnavar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1491,
        Pin: 577417,
        DispatchCenter: "Sagara_Hotel_D (Karnataka)",
        OriginCenter: "Sagara_Hotel_D (Karnataka)",
        FacilityCity: "Sagara",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1492,
        Pin: 314029,
        DispatchCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        OriginCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        FacilityCity: "Sagwara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1493,
        Pin: 591127,
        DispatchCenter: "Ramdurg_Busstnd_DPP (Karnataka)",
        OriginCenter: "Ramdurg_Busstnd_DPP (Karnataka)",
        FacilityCity: "Ramdurg",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1494,
        Pin: 627817,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1495,
        Pin: 803118,
        DispatchCenter: "BiharSharif_Sherpur_D (Bihar)",
        OriginCenter: "BiharSharif_Sherpur_D (Bihar)",
        FacilityCity: "Bihar Sharif",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1496,
        Pin: 641404,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1497,
        Pin: 445210,
        DispatchCenter: "Yavatmal_Ner_D (Maharashtra)",
        OriginCenter: "Yavatmal_Ner_D (Maharashtra)",
        FacilityCity: "Yavatmal",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1498,
        Pin: 572216,
        DispatchCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        OriginCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1499,
        Pin: 829117,
        DispatchCenter: "Ramgarh_Marar_D (Jharkhand)",
        OriginCenter: "Ramgarh_Marar_D (Jharkhand)",
        FacilityCity: "Ramgarh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1500,
        Pin: 690556,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1501,
        Pin: 613009,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1502,
        Pin: 791111,
        DispatchCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        OriginCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        FacilityCity: "Itanagar",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1503,
        Pin: 305816,
        DispatchCenter: "Kishangarh_AirportRd_D (Rajasthan)",
        OriginCenter: "Kishangarh_AirportRd_D (Rajasthan)",
        FacilityCity: "Kishangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1504,
        Pin: 632402,
        DispatchCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ranipet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1505,
        Pin: 700082,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1506,
        Pin: 587125,
        DispatchCenter: "Ilkal_Ward4_D (Karnataka)",
        OriginCenter: "Ilkal_Ward4_D (Karnataka)",
        FacilityCity: "Ilkal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1507,
        Pin: 768001,
        DispatchCenter: "Sambalpur_Tentelpara_H (Orissa)",
        OriginCenter: "Sambalpur_Tentelpara_H (Orissa)",
        FacilityCity: "Sambalpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1508,
        Pin: 124416,
        DispatchCenter: "Rohtak_IndustrialArea_D (Haryana)",
        OriginCenter: "Rohtak_IndustrialArea_D (Haryana)",
        FacilityCity: "Rohtak",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1509,
        Pin: 141104,
        DispatchCenter: "Raikot_DC (Punjab)",
        OriginCenter: "Raikot_DC (Punjab)",
        FacilityCity: "Raikot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1510,
        Pin: 804435,
        DispatchCenter: "Tekari_BaheliyaBigha_D (Bihar)",
        OriginCenter: "Tekari_BaheliyaBigha_D (Bihar)",
        FacilityCity: "Tekari",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1511,
        Pin: 203396,
        DispatchCenter: "Dibai_Pahasu_D (Uttar Pradesh)",
        OriginCenter: "Dibai_Pahasu_D (Uttar Pradesh)",
        FacilityCity: "Dibai",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1512,
        Pin: 230121,
        DispatchCenter: "Badlapur_Barauli_D (Uttar Pradesh)",
        OriginCenter: "Badlapur_Barauli_D (Uttar Pradesh)",
        FacilityCity: "Badlapur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1513,
        Pin: 516172,
        DispatchCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        OriginCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        FacilityCity: "Proddatur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1514,
        Pin: 841205,
        DispatchCenter: "Ekma_Hansrajpr_D (Bihar)",
        OriginCenter: "Ekma_Hansrajpr_D (Bihar)",
        FacilityCity: "Ekma",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1515,
        Pin: 761027,
        DispatchCenter: "Behrampur_Kanmna_D (Orissa)",
        OriginCenter: "Behrampur_Kanmna_D (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1516,
        Pin: 531151,
        DispatchCenter: "Araku_Ravvalaguda2_D (Andhra Pradesh)",
        OriginCenter: "Araku_Ravvalaguda2_D (Andhra Pradesh)",
        FacilityCity: "Araku",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1517,
        Pin: 142011,
        DispatchCenter: "Moga_Duneke_I (Punjab)",
        OriginCenter: "Moga_Duneke_I (Punjab)",
        FacilityCity: "Moga",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1518,
        Pin: 784177,
        DispatchCenter: "Biswanath_CharialiLIC_D (Assam)",
        OriginCenter: "Biswanath_CharialiLIC_D (Assam)",
        FacilityCity: "BiswanathCH",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1519,
        Pin: 695306,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1520,
        Pin: 590011,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1521,
        Pin: 691519,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1522,
        Pin: 522258,
        DispatchCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        OriginCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        FacilityCity: "Repalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1523,
        Pin: 678501,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1524,
        Pin: 123503,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1525,
        Pin: 521402,
        DispatchCenter: "Bonakal_SaiNagar_D (Telangana)",
        OriginCenter: "Bonakal_SaiNagar_D (Telangana)",
        FacilityCity: "Bonakal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1526,
        Pin: 756134,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1527,
        Pin: 609108,
        DispatchCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        OriginCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        FacilityCity: "Sirkazhi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1528,
        Pin: 516107,
        DispatchCenter: "Rajampet_BypassRd_D (Andhra Pradesh)",
        OriginCenter: "Rajampet_BypassRd_D (Andhra Pradesh)",
        FacilityCity: "Rajampet",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1529,
        Pin: 571438,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1530,
        Pin: 176324,
        DispatchCenter: "Bharmour_Kharamukh_D (Himachal Pradesh)",
        OriginCenter: "Bharmour_Kharamukh_D (Himachal Pradesh)",
        FacilityCity: "Bharmour",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1531,
        Pin: 148017,
        DispatchCenter: "Dhuri_Bhalwanroad_D (Punjab)",
        OriginCenter: "Dhuri_Bhalwanroad_D (Punjab)",
        FacilityCity: "Dhuri",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1532,
        Pin: 305412,
        DispatchCenter: "Nasirabad_RamlilaChowk_DPP (Rajasthan)",
        OriginCenter: "Nasirabad_RamlilaChowk_DPP (Rajasthan)",
        FacilityCity: "Nasirabad",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1533,
        Pin: 261404,
        DispatchCenter: "Sitapur_Lachimanpur_D (Uttar Pradesh)",
        OriginCenter: "Sitapur_Lachimanpur_D (Uttar Pradesh)",
        FacilityCity: "Sitapur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1534,
        Pin: 673029,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1535,
        Pin: 171210,
        DispatchCenter: "Nerwa_Chopal_D (Himachal Pradesh)",
        OriginCenter: "Nerwa_Chopal_D (Himachal Pradesh)",
        FacilityCity: "Nerwa",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1536,
        Pin: 458001,
        DispatchCenter: "Mandsaur_Sindpan_I (Madhya Pradesh)",
        OriginCenter: "Mandsaur_Sindpan_I (Madhya Pradesh)",
        FacilityCity: "Mandsaur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 1537,
        Pin: 585333,
        DispatchCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        OriginCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        FacilityCity: "Gulbarga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1538,
        Pin: 272271,
        DispatchCenter: "Menhdawal_Kabeernagar_D (Uttar Pradesh)",
        OriginCenter: "Menhdawal_Kabeernagar_D (Uttar Pradesh)",
        FacilityCity: "Menhdawal",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1539,
        Pin: 784105,
        DispatchCenter: "Tezpur_Dipota_H (Assam)",
        OriginCenter: "Tezpur_Dipota_H (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1540,
        Pin: 757050,
        DispatchCenter: "Rairangpur_Sanchampauda_D (Orissa)",
        OriginCenter: "Rairangpur_Sanchampauda_D (Orissa)",
        FacilityCity: "Rairangpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1541,
        Pin: 639102,
        DispatchCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        OriginCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        FacilityCity: "Kulithalai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1542,
        Pin: 571122,
        DispatchCenter: "Mysore_Tnarsipura_DPP (Karnataka)",
        OriginCenter: "Mysore_Tnarsipura_DPP (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1543,
        Pin: 212205,
        DispatchCenter: "Sirathu_Ward4_DPP (Uttar Pradesh)",
        OriginCenter: "Sirathu_Ward4_DPP (Uttar Pradesh)",
        FacilityCity: "Sirathu",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1544,
        Pin: 630005,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1545,
        Pin: 388450,
        DispatchCenter: "Anand_Borsad_D (Gujarat)",
        OriginCenter: "Anand_Borsad_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1546,
        Pin: 761043,
        DispatchCenter: "Kodala_Beguniapada_DPP (Orissa)",
        OriginCenter: "Kodala_Beguniapada_DPP (Orissa)",
        FacilityCity: "Kodala",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1547,
        Pin: 491666,
        DispatchCenter: "AmbagarhChowki_Ward14_D (Chhattisgarh)",
        OriginCenter: "AmbagarhChowki_Ward14_D (Chhattisgarh)",
        FacilityCity: "AmbagarhChowki",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1548,
        Pin: 415202,
        DispatchCenter: "Mandangad_Bhingaloli_D (Maharashtra)",
        OriginCenter: "Mandangad_Bhingaloli_D (Maharashtra)",
        FacilityCity: "Mandangad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1549,
        Pin: 505208,
        DispatchCenter: "Mancherial_Nuspur_I (Telangana)",
        OriginCenter: "Mancherial_Nuspur_I (Telangana)",
        FacilityCity: "Mancherial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1550,
        Pin: 625528,
        DispatchCenter: "Theni_Dharmathupatti_D (Tamil Nadu)",
        OriginCenter: "Theni_Dharmathupatti_D (Tamil Nadu)",
        FacilityCity: "Bodinayakanur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1551,
        Pin: 841405,
        DispatchCenter: "Gopalganj_HarsanHosp_D (Bihar)",
        OriginCenter: "Gopalganj_HarsanHosp_D (Bihar)",
        FacilityCity: "Gopalganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1552,
        Pin: 570008,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1553,
        Pin: 413132,
        DispatchCenter: "Indapur_ChaitanyaPlaza_D (Maharashtra)",
        OriginCenter: "Indapur_ChaitanyaPlaza_D (Maharashtra)",
        FacilityCity: "Indapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1554,
        Pin: 682023,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1555,
        Pin: 700036,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1556,
        Pin: 284129,
        DispatchCenter: "Jhansi_Nandanpura_I (Uttar Pradesh)",
        OriginCenter: "Jhansi_Nandanpura_I (Uttar Pradesh)",
        FacilityCity: "Jhansi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1557,
        Pin: 635120,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1558,
        Pin: 600075,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1559,
        Pin: 641107,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1560,
        Pin: 410203,
        DispatchCenter: "Mumbai_Ariwali_L (Maharashtra)",
        OriginCenter: "Mumbai_Ariwali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1561,
        Pin: 212207,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1562,
        Pin: 631152,
        DispatchCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1563,
        Pin: 587316,
        DispatchCenter: "Mahalingpur_Bagalkot_D (Karnataka)",
        OriginCenter: "Mahalingpur_Bagalkot_D (Karnataka)",
        FacilityCity: "Mahalingpur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1564,
        Pin: 222161,
        DispatchCenter: "Mariahu_RnprJmlpr_D (Uttar Pradesh)",
        OriginCenter: "Mariahu_RnprJmlpr_D (Uttar Pradesh)",
        FacilityCity: "Mariahu",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1565,
        Pin: 768103,
        DispatchCenter: "Kadobahal_Dulampur_D (Orissa)",
        OriginCenter: "Kadobahal_Dulampur_D (Orissa)",
        FacilityCity: "Kadobahal",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1566,
        Pin: 713124,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1567,
        Pin: 411107,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1568,
        Pin: 621105,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1569,
        Pin: 636351,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1570,
        Pin: 504203,
        DispatchCenter: "KhanapurTS_BusStand_DPP (Telangana)",
        OriginCenter: "KhanapurTS_BusStand_DPP (Telangana)",
        FacilityCity: "Khanapur-TS",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1571,
        Pin: 333040,
        DispatchCenter: "Pilani (Rajasthan)",
        OriginCenter: "Pilani (Rajasthan)",
        FacilityCity: "Pilani",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1572,
        Pin: 590006,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1573,
        Pin: 577533,
        DispatchCenter: "Hosadurga_Vidyanagara_D (Karnataka)",
        OriginCenter: "Hosadurga_Vidyanagara_D (Karnataka)",
        FacilityCity: "Hosadurga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1574,
        Pin: 391101,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1575,
        Pin: 246729,
        DispatchCenter: "Chandpur_NoorpurRd_D (Uttar Pradesh)",
        OriginCenter: "Chandpur_NoorpurRd_D (Uttar Pradesh)",
        FacilityCity: "Chandpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1576,
        Pin: 457331,
        DispatchCenter: "Jaora_AdityaNagar_D (Madhya Pradesh)",
        OriginCenter: "Jaora_AdityaNagar_D (Madhya Pradesh)",
        FacilityCity: "Jaora",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1577,
        Pin: 246735,
        DispatchCenter: "Chandpur_NoorpurRd_D (Uttar Pradesh)",
        OriginCenter: "Chandpur_NoorpurRd_D (Uttar Pradesh)",
        FacilityCity: "Chandpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1578,
        Pin: 686004,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1579,
        Pin: 441226,
        DispatchCenter: "Saoli_NagarPanchyt_DPP (Maharashtra)",
        OriginCenter: "Saoli_NagarPanchyt_DPP (Maharashtra)",
        FacilityCity: "Saoli",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1580,
        Pin: 121014,
        DispatchCenter: "Faridabad_MathuraRoad_L (Haryana)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1581,
        Pin: 176315,
        DispatchCenter: "Bharmour_Kharamukh_D (Himachal Pradesh)",
        OriginCenter: "Bharmour_Kharamukh_D (Himachal Pradesh)",
        FacilityCity: "Bharmour",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1582,
        Pin: 146002,
        DispatchCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        OriginCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1583,
        Pin: 690573,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1584,
        Pin: 411045,
        DispatchCenter: "Pune_Muktainagar_L (Maharashtra)",
        OriginCenter: "Pune_Muktainagar_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1585,
        Pin: 783320,
        DispatchCenter: "Dhubri_Tetultol_D (Assam)",
        OriginCenter: "Dhubri_Tetultol_D (Assam)",
        FacilityCity: "Dhubri",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1586,
        Pin: 682041,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1587,
        Pin: 191104,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1588,
        Pin: 394650,
        DispatchCenter: "Songadh_Vyara_D (Gujarat)",
        OriginCenter: "Songadh_Vyara_D (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1589,
        Pin: 382410,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1590,
        Pin: 626124,
        DispatchCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        OriginCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        FacilityCity: "Sivakasi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1591,
        Pin: 534225,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1592,
        Pin: 455459,
        DispatchCenter: "Khategaon_ChohanClny_D (Madhya Pradesh)",
        OriginCenter: "Khategaon_ChohanClny_D (Madhya Pradesh)",
        FacilityCity: "Khategaon",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 1593,
        Pin: 483336,
        DispatchCenter: "Sihora_Mahavirchk_D (Madhya Pradesh)",
        OriginCenter: "Sihora_Mahavirchk_D (Madhya Pradesh)",
        FacilityCity: "Sihora",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1594,
        Pin: 204105,
        DispatchCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        OriginCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        FacilityCity: "Hathras",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1595,
        Pin: 284136,
        DispatchCenter: "Talbahat_CityRoad_DPP (Uttar Pradesh)",
        OriginCenter: "Talbahat_CityRoad_DPP (Uttar Pradesh)",
        FacilityCity: "Talbahat",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1596,
        Pin: 752080,
        DispatchCenter: "Nayagarh_Mathakhajuria_D (Orissa)",
        OriginCenter: "Nayagarh_Mathakhajuria_D (Orissa)",
        FacilityCity: "Nayagarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1597,
        Pin: 462025,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 1598,
        Pin: 781001,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1599,
        Pin: 501503,
        DispatchCenter: "Shankarpalle_Chevella_D (Telangana)",
        OriginCenter: "Shankarpalle_Chevella_D (Telangana)",
        FacilityCity: "Shankarpalle",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1600,
        Pin: 209736,
        DispatchCenter: "Kannauj_Tirwaganj_D (Uttar Pradesh)",
        OriginCenter: "Kannauj_Tirwaganj_D (Uttar Pradesh)",
        FacilityCity: "Kannauj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1601,
        Pin: 828132,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1602,
        Pin: 332705,
        DispatchCenter: "Neemkathana_Chhawani_D (Rajasthan)",
        OriginCenter: "Neemkathana_Chhawani_D (Rajasthan)",
        FacilityCity: "Neem ka Thana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1603,
        Pin: 271205,
        DispatchCenter: "Balrampur_SahabNager_D (Uttar Pradesh)",
        OriginCenter: "Balrampur_SahabNager_D (Uttar Pradesh)",
        FacilityCity: "Balrampur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1604,
        Pin: 629302,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1605,
        Pin: 636407,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1606,
        Pin: 518134,
        DispatchCenter: "Betamcherla_Bngnpalle_D (Andhra Pradesh)",
        OriginCenter: "Betamcherla_Bngnpalle_D (Andhra Pradesh)",
        FacilityCity: "Betamcherla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1607,
        Pin: 203207,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1608,
        Pin: 500253,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1609,
        Pin: 508238,
        DispatchCenter: "Kodad_OldNH9_D (Telangana)",
        OriginCenter: "Kodad_OldNH9_D (Telangana)",
        FacilityCity: "Kodad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1610,
        Pin: 847407,
        DispatchCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        OriginCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        FacilityCity: "Jhanjharpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1611,
        Pin: 413229,
        DispatchCenter: "Beed_Raimoha_DPP (Maharashtra)",
        OriginCenter: "Beed_Raimoha_DPP (Maharashtra)",
        FacilityCity: "Dongarkinhi",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1612,
        Pin: 700103,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1613,
        Pin: 110097,
        DispatchCenter: "Delhi_BamnoliVillage_L (Delhi)",
        OriginCenter: "Delhi_BamnoliVillage_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1614,
        Pin: 586207,
        DispatchCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        OriginCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        FacilityCity: "Indi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1615,
        Pin: 609403,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1616,
        Pin: 695542,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1617,
        Pin: 221305,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1618,
        Pin: 394180,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1619,
        Pin: 670671,
        DispatchCenter: "Thalassery_TempleGt_D (Kerala)",
        OriginCenter: "Thalassery_TempleGt_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1620,
        Pin: 284304,
        DispatchCenter: "Poonchh_AranchRd_D (Uttar Pradesh)",
        OriginCenter: "Poonchh_AranchRd_D (Uttar Pradesh)",
        FacilityCity: "Poonchh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1621,
        Pin: 415307,
        DispatchCenter: "Vita_Adarshnagar_D (Maharashtra)",
        OriginCenter: "Vita_Adarshnagar_D (Maharashtra)",
        FacilityCity: "Vita",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1622,
        Pin: 845426,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1623,
        Pin: 689622,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1624,
        Pin: 761114,
        DispatchCenter: "Asika_DsarathiNgr_DPP (Orissa)",
        OriginCenter: "Asika_DsarathiNgr_DPP (Orissa)",
        FacilityCity: "Asika",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1625,
        Pin: 209865,
        DispatchCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        OriginCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        FacilityCity: "Unnao",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1626,
        Pin: 360380,
        DispatchCenter: "Jetpur_Dasijivanpara_D (Gujarat)",
        OriginCenter: "Jetpur_Dasijivanpara_D (Gujarat)",
        FacilityCity: "Jetpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1627,
        Pin: 700004,
        DispatchCenter: "Kolkata_Bagmari_L (West Bengal)",
        OriginCenter: "Kolkata_Bagmari_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1628,
        Pin: 736145,
        DispatchCenter: "Sitai_Khamarsitai_DPP (West Bengal)",
        OriginCenter: "Sitai_Khamarsitai_DPP (West Bengal)",
        FacilityCity: "Sitai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1629,
        Pin: 335037,
        DispatchCenter: "Ganganagar_Ricco_D (Rajasthan)",
        OriginCenter: "Ganganagar_Ricco_D (Rajasthan)",
        FacilityCity: "Ganga Nagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1630,
        Pin: 403521,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1631,
        Pin: 311802,
        DispatchCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        OriginCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        FacilityCity: "Bhilwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1632,
        Pin: 824103,
        DispatchCenter: "AurangabadBR_Katharua_D (Bihar)",
        OriginCenter: "AurangabadBR_Katharua_D (Bihar)",
        FacilityCity: "Aurangabad-BR",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1633,
        Pin: 721140,
        DispatchCenter: "Lutunia_Bholenthtmpl_D (West Bengal)",
        OriginCenter: "Lutunia_Bholenthtmpl_D (West Bengal)",
        FacilityCity: "Lutunia",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1634,
        Pin: 786156,
        DispatchCenter: "DoomDooma_Pthrgoan_D (Assam)",
        OriginCenter: "DoomDooma_Pthrgoan_D (Assam)",
        FacilityCity: "Doom Dooma",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1635,
        Pin: 560004,
        DispatchCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        OriginCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1636,
        Pin: 518217,
        DispatchCenter: "Kurnool_Chinnatekuru_D (Andhra Pradesh)",
        OriginCenter: "Kurnool_Chinnatekuru_D (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1637,
        Pin: 683583,
        DispatchCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        OriginCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        FacilityCity: "Muvattupuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1638,
        Pin: 571235,
        DispatchCenter: "Shanivarasanthe_Bank_D (Karnataka)",
        OriginCenter: "Shanivarasanthe_Bank_D (Karnataka)",
        FacilityCity: "Shanivarasanthe",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1639,
        Pin: 799261,
        DispatchCenter: "Dharmanagar_Churaibari_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Dharmanagar",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1640,
        Pin: 400009,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1641,
        Pin: 495117,
        DispatchCenter: "Pendra_Sarbahra_D (Chhattisgarh)",
        OriginCenter: "Pendra_Sarbahra_D (Chhattisgarh)",
        FacilityCity: "Pendra",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1642,
        Pin: 306901,
        DispatchCenter: "Sumerpur_RgukulColny_DPP (Rajasthan)",
        OriginCenter: "Sumerpur_RgukulColny_DPP (Rajasthan)",
        FacilityCity: "Sumerpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1643,
        Pin: 814112,
        DispatchCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        OriginCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        FacilityCity: "Deoghar",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1644,
        Pin: 134203,
        DispatchCenter: "Naraingarh_KrishnaColony_DPP (Haryana)",
        OriginCenter: "Naraingarh_KrishnaColony_DPP (Haryana)",
        FacilityCity: "Naraingarh",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1645,
        Pin: 627702,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1646,
        Pin: 143504,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1647,
        Pin: 206127,
        DispatchCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        OriginCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        FacilityCity: "Etawah",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1648,
        Pin: 825302,
        DispatchCenter: "Hazaribagh_Matwari_D (Jharkhand)",
        OriginCenter: "Hazaribagh_Matwari_D (Jharkhand)",
        FacilityCity: "hazaribagh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1649,
        Pin: 143531,
        DispatchCenter: "Dinanagar_KakaHosp_D (Punjab)",
        OriginCenter: "Dinanagar_KakaHosp_D (Punjab)",
        FacilityCity: "Dinanagar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1650,
        Pin: 574327,
        DispatchCenter: "Sullia_Kanthamangala_D (Karnataka)",
        OriginCenter: "Sullia_Kanthamangala_D (Karnataka)",
        FacilityCity: "Sullia",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1651,
        Pin: 144000,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1652,
        Pin: 364070,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1653,
        Pin: 502334,
        DispatchCenter: "Pregnapur_Tupran_D (Telangana)",
        OriginCenter: "Pregnapur_Tupran_D (Telangana)",
        FacilityCity: "Medak",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1654,
        Pin: 145027,
        DispatchCenter: "Dinanagar_KakaHosp_D (Punjab)",
        OriginCenter: "Dinanagar_KakaHosp_D (Punjab)",
        FacilityCity: "Dinanagar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1655,
        Pin: 314030,
        DispatchCenter: "Simalwara_PithRd_DPP (Rajasthan)",
        OriginCenter: "Simalwara_PithRd_DPP (Rajasthan)",
        FacilityCity: "Simalwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1656,
        Pin: 590001,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1657,
        Pin: 583224,
        DispatchCenter: "HoovinaHadagali_Holagundhi_D (Karnataka)",
        OriginCenter: "HoovinaHadagali_Holagundhi_D (Karnataka)",
        FacilityCity: "Hoovina Hadagali",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1658,
        Pin: 133205,
        DispatchCenter: "Ambala_Barara_D (Haryana)",
        OriginCenter: "Ambala_Barara_D (Haryana)",
        FacilityCity: "Ambala",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1659,
        Pin: 721402,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1660,
        Pin: 688561,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1661,
        Pin: 769016,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1662,
        Pin: 362520,
        DispatchCenter: "Una_StColony_D (Gujarat)",
        OriginCenter: "Una_StColony_D (Gujarat)",
        FacilityCity: "UNA",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1663,
        Pin: 411060,
        DispatchCenter: "Pune_Fursungi_L (Maharashtra)",
        OriginCenter: "Pune_Fursungi_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1664,
        Pin: 604101,
        DispatchCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        OriginCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        FacilityCity: "Tindivanam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1665,
        Pin: 679332,
        DispatchCenter: "Nilambur_KarulaiRd_DPP (Kerala)",
        OriginCenter: "Nilambur_KarulaiRd_DPP (Kerala)",
        FacilityCity: "Nilambur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1666,
        Pin: 636142,
        DispatchCenter: "Mettala_Rasipuram_D (Tamil Nadu)",
        OriginCenter: "Mettala_Rasipuram_D (Tamil Nadu)",
        FacilityCity: "Mettala",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1667,
        Pin: 754071,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1668,
        Pin: 431112,
        DispatchCenter: "Sillod_ZebaTWR_D (Maharashtra)",
        OriginCenter: "Sillod_ZebaTWR_D (Maharashtra)",
        FacilityCity: "Sillod",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1669,
        Pin: 151104,
        DispatchCenter: "RampuraPhul_DC (Punjab)",
        OriginCenter: "RampuraPhul_DC (Punjab)",
        FacilityCity: "Rampura Phul",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1670,
        Pin: 626144,
        DispatchCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        OriginCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        FacilityCity: "Sivakasi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1671,
        Pin: 814160,
        DispatchCenter: "Kahalgaon_Pirpainti_D (Bihar)",
        OriginCenter: "Kahalgaon_Pirpainti_D (Bihar)",
        FacilityCity: "Kahalgaon",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1672,
        Pin: 495551,
        DispatchCenter: "Bilaspur_Masturi_D (Chhattisgarh)",
        OriginCenter: "Bilaspur_Masturi_D (Chhattisgarh)",
        FacilityCity: "Bilaspur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1673,
        Pin: 643282,
        DispatchCenter: "Ooty_Manjoor_D (Tamil Nadu)",
        OriginCenter: "Ooty_Manjoor_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1674,
        Pin: 673527,
        DispatchCenter: "Perambra_KayipramRd_D (Kerala)",
        OriginCenter: "Perambra_KayipramRd_D (Kerala)",
        FacilityCity: "Perambra",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1675,
        Pin: 132036,
        DispatchCenter: "Karnal_Kutail_H (Haryana)",
        OriginCenter: "Karnal_Kutail_H (Haryana)",
        FacilityCity: "Karnal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1676,
        Pin: 691589,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1677,
        Pin: 612106,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1678,
        Pin: 560049,
        DispatchCenter: "Bangalore_Koralur_L (Karnataka)",
        OriginCenter: "Bangalore_Soukyaroad_GW (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1679,
        Pin: 173230,
        DispatchCenter: "Solan_Deothi_I (Himachal Pradesh)",
        OriginCenter: "Solan_Deothi_I (Himachal Pradesh)",
        FacilityCity: "Solan",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1680,
        Pin: 380063,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1681,
        Pin: 700104,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1682,
        Pin: 312024,
        DispatchCenter: "Mandphiya_BhadsodaRoad_D (Rajasthan)",
        OriginCenter: "Mandphiya_BhadsodaRoad_D (Rajasthan)",
        FacilityCity: "Mandphiya",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1683,
        Pin: 144507,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1684,
        Pin: 686018,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1685,
        Pin: 585421,
        DispatchCenter: "Aurad_CBColony_D (Karnataka)",
        OriginCenter: "Aurad_CBColony_D (Karnataka)",
        FacilityCity: "Aurad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1686,
        Pin: 515305,
        DispatchCenter: "Madakasira_RTCStand_D (Andhra Pradesh)",
        OriginCenter: "Madakasira_RTCStand_D (Andhra Pradesh)",
        FacilityCity: "Madakasira",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1687,
        Pin: 442304,
        DispatchCenter: "Hinganghat_DyanshwrWrd_D (Maharashtra)",
        OriginCenter: "Hinganghat_DyanshwrWrd_D (Maharashtra)",
        FacilityCity: "Hinganghat",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1688,
        Pin: 393016,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1689,
        Pin: 176077,
        DispatchCenter: "JognderNgr_Dhelu_D (Himachal Pradesh)",
        OriginCenter: "JognderNgr_Dhelu_D (Himachal Pradesh)",
        FacilityCity: "Joginder Nagar",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1690,
        Pin: 132024,
        DispatchCenter: "Karnal_Ramnagar_D (Haryana)",
        OriginCenter: "Karnal_Ramnagar_D (Haryana)",
        FacilityCity: "Karnal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1691,
        Pin: 534240,
        DispatchCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        FacilityCity: "Bhimavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1692,
        Pin: 472339,
        DispatchCenter: "Tikamgarh_Dhonga_D (Madhya Pradesh)",
        OriginCenter: "Tikamgarh_Dhonga_D (Madhya Pradesh)",
        FacilityCity: "Tikamgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 1693,
        Pin: 144603,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1694,
        Pin: 421308,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1695,
        Pin: 208002,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1696,
        Pin: 142056,
        DispatchCenter: "Bagha_Samalsar_D (Punjab)",
        OriginCenter: "Bagha_Samalsar_D (Punjab)",
        FacilityCity: "Bagha purana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1697,
        Pin: 382008,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1698,
        Pin: 782461,
        DispatchCenter: "Diphu_ShymColony_D (Assam)",
        OriginCenter: "Diphu_ShymColony_D (Assam)",
        FacilityCity: "Diphu",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1699,
        Pin: 630410,
        DispatchCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1700,
        Pin: 673313,
        DispatchCenter: "Mahe_Chokli_D (Kerala)",
        OriginCenter: "Mahe_Chokli_D (Kerala)",
        FacilityCity: "Mahe",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1701,
        Pin: 638004,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1702,
        Pin: 360410,
        DispatchCenter: "Dhoraji_StationRd_DPP (Gujarat)",
        OriginCenter: "Dhoraji_StationRd_DPP (Gujarat)",
        FacilityCity: "Dhoraji",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1703,
        Pin: 722183,
        DispatchCenter: "Bankura_Chhatna_DPP (West Bengal)",
        OriginCenter: "Bankura_Chhatna_DPP (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1704,
        Pin: 144303,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1705,
        Pin: 600054,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1706,
        Pin: 277202,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1707,
        Pin: 177203,
        DispatchCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        OriginCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        FacilityCity: "Amb",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1708,
        Pin: 442303,
        DispatchCenter: "Pulgaon_RajPP_DPP (Maharashtra)",
        OriginCenter: "Pulgaon_RajPP_DPP (Maharashtra)",
        FacilityCity: "Pulgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1709,
        Pin: 274808,
        DispatchCenter: "Salempur_Banjaria_D (Uttar Pradesh)",
        OriginCenter: "Salempur_Banjaria_D (Uttar Pradesh)",
        FacilityCity: "Salempur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1710,
        Pin: 326512,
        DispatchCenter: "BhwniMndi_RmngrDPP_D (Rajasthan)",
        OriginCenter: "BhwniMndi_RmngrDPP_D (Rajasthan)",
        FacilityCity: "Bhawani Mandi",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1711,
        Pin: 331031,
        DispatchCenter: "Churu_Stadium_DPP (Rajasthan)",
        OriginCenter: "Churu_Stadium_DPP (Rajasthan)",
        FacilityCity: "Churu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1712,
        Pin: 670110,
        DispatchCenter: "Thalassery_TempleGt_D (Kerala)",
        OriginCenter: "Thalassery_TempleGt_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1713,
        Pin: 845487,
        DispatchCenter: "Bettiah_Manshatola_D (Bihar)",
        OriginCenter: "Bettiah_Manshatola_D (Bihar)",
        FacilityCity: "Bettiah",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1714,
        Pin: 122218,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1715,
        Pin: 276138,
        DispatchCenter: "Jiyanpur_GorkhpurRd_D (Uttar Pradesh)",
        OriginCenter: "Jiyanpur_GorkhpurRd_D (Uttar Pradesh)",
        FacilityCity: "Jiyanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1716,
        Pin: 795113,
        DispatchCenter: "Imphal_Takyel_I (Manipur)",
        OriginCenter: "Imphal_Takyel_I (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1717,
        Pin: 623111,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1718,
        Pin: 333036,
        DispatchCenter: "Buhana_Rajota_D (Rajasthan)",
        OriginCenter: "Buhana_Rajota_D (Rajasthan)",
        FacilityCity: "Buhana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1719,
        Pin: 743437,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1720,
        Pin: 731219,
        DispatchCenter: "Murarai_Birbhumi_D (West Bengal)",
        OriginCenter: "Murarai_Birbhumi_D (West Bengal)",
        FacilityCity: "Murarai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1721,
        Pin: 173213,
        DispatchCenter: "Solan_Deothi_I (Himachal Pradesh)",
        OriginCenter: "Solan_Deothi_I (Himachal Pradesh)",
        FacilityCity: "Solan",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1722,
        Pin: 382120,
        DispatchCenter: "Kadi_Jadavpura_L (Gujarat)",
        OriginCenter: "Kadi_Jadavpura_L (Gujarat)",
        FacilityCity: "Kadi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1723,
        Pin: 176319,
        DispatchCenter: "Chamba_Sundla_D (Himachal Pradesh)",
        OriginCenter: "Chamba_Sundla_D (Himachal Pradesh)",
        FacilityCity: "Sundla",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1724,
        Pin: 442006,
        DispatchCenter: "Wardha_Alodi_D (Maharashtra)",
        OriginCenter: "Wardha_Alodi_D (Maharashtra)",
        FacilityCity: "Wardha",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1725,
        Pin: 491559,
        DispatchCenter: "Pandariya_HariNala_D (Chhattisgarh)",
        OriginCenter: "Pandariya_HariNala_D (Chhattisgarh)",
        FacilityCity: "Pandariya",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1726,
        Pin: 283206,
        DispatchCenter: "Firozabad_MahadeoNagar_D (Uttar Pradesh)",
        OriginCenter: "Firozabad_MahadeoNagar_D (Uttar Pradesh)",
        FacilityCity: "Firozabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1727,
        Pin: 673619,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1728,
        Pin: 632115,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1729,
        Pin: 587122,
        DispatchCenter: "Mudhol_Maharancol_D (Karnataka)",
        OriginCenter: "Mudhol_Maharancol_D (Karnataka)",
        FacilityCity: "Mudhol",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1730,
        Pin: 571617,
        DispatchCenter: "Krishnarajngr_VlmkiNgr_D (Karnataka)",
        OriginCenter: "Krishnarajngr_VlmkiNgr_D (Karnataka)",
        FacilityCity: "Krishnarajngr",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1731,
        Pin: 563103,
        DispatchCenter: "Kolar_Jayanagara_D (Karnataka)",
        OriginCenter: "Kolar_Jayanagara_D (Karnataka)",
        FacilityCity: "Kolar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1732,
        Pin: 395006,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1733,
        Pin: 485666,
        DispatchCenter: "Jaitwara_Kothi_DPP (Madhya Pradesh)",
        OriginCenter: "Jaitwara_Kothi_DPP (Madhya Pradesh)",
        FacilityCity: "Jaitwara",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1734,
        Pin: 110076,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1735,
        Pin: 202395,
        DispatchCenter: "Dibai_Pahasu_D (Uttar Pradesh)",
        OriginCenter: "Dibai_Pahasu_D (Uttar Pradesh)",
        FacilityCity: "Dibai",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1736,
        Pin: 382630,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1737,
        Pin: 821304,
        DispatchCenter: "Rohtas_Sundarganj_D (Bihar)",
        OriginCenter: "Rohtas_Sundarganj_D (Bihar)",
        FacilityCity: "Rohtas",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1738,
        Pin: 413209,
        DispatchCenter: "Kurduwadi_Dattanagar_D (Maharashtra)",
        OriginCenter: "Kurduwadi_Dattanagar_D (Maharashtra)",
        FacilityCity: "Kurduwadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1739,
        Pin: 768025,
        DispatchCenter: "Kadobahal_Dulampur_D (Orissa)",
        OriginCenter: "Kadobahal_Dulampur_D (Orissa)",
        FacilityCity: "Kadobahal",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1740,
        Pin: 695013,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1741,
        Pin: 792110,
        DispatchCenter: "Roing_Asali_D (Arunachal Pradesh)",
        OriginCenter: "Roing_Asali_D (Arunachal Pradesh)",
        FacilityCity: "Roing",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1742,
        Pin: 122017,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1743,
        Pin: 736131,
        DispatchCenter: "Barobisha_NatAimDPP_D (West Bengal)",
        OriginCenter: "Barobisha_NatAimDPP_D (West Bengal)",
        FacilityCity: "Barobisha",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1744,
        Pin: 735226,
        DispatchCenter: "CoochBehar_khagrabari_I (West Bengal)",
        OriginCenter: "CoochBehar_khagrabari_I (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1745,
        Pin: 251001,
        DispatchCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        OriginCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        FacilityCity: "Muzaffarnagar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1746,
        Pin: 784164,
        DispatchCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        OriginCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        FacilityCity: "Itanagar",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1747,
        Pin: 626114,
        DispatchCenter: "Perunali_Sayalkudi_D (Tamil Nadu)",
        OriginCenter: "Perunali_Sayalkudi_D (Tamil Nadu)",
        FacilityCity: "Perunali",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1748,
        Pin: 201303,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1749,
        Pin: 581307,
        DispatchCenter: "Karwar_Sadashivgad_D (Karnataka)",
        OriginCenter: "Karwar_Sadashivgad_D (Karnataka)",
        FacilityCity: "Karwar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1750,
        Pin: 630702,
        DispatchCenter: "Paramakudi_River_D (Tamil Nadu)",
        OriginCenter: "Paramakudi_River_D (Tamil Nadu)",
        FacilityCity: "Paramakudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1751,
        Pin: 797101,
        DispatchCenter: "Peren_Peletkie_D (Nagaland)",
        OriginCenter: "Peren_Peletkie_D (Nagaland)",
        FacilityCity: "Peren",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1752,
        Pin: 141201,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1753,
        Pin: 504273,
        DispatchCenter: "Dahegaon_Postoffice_DPP (Telangana)",
        OriginCenter: "Dahegaon_Postoffice_DPP (Telangana)",
        FacilityCity: "Dahegaon",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1754,
        Pin: 574242,
        DispatchCenter: "Moodbidri_Central_D (Karnataka)",
        OriginCenter: "Moodbidri_Central_D (Karnataka)",
        FacilityCity: "Moodabidri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1755,
        Pin: 182146,
        DispatchCenter: "Anantnag_Banihal_D (Jammu & Kashmir)",
        OriginCenter: "Anantnag_Banihal_D (Jammu & Kashmir)",
        FacilityCity: "Anantnag",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1756,
        Pin: 141417,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1757,
        Pin: 624054,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1758,
        Pin: 629802,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1759,
        Pin: 713104,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1760,
        Pin: 643003,
        DispatchCenter: "Ooty_Westmere_D (Tamil Nadu)",
        OriginCenter: "Ooty_Westmere_D (Tamil Nadu)",
        FacilityCity: "Ooty",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1761,
        Pin: 141121,
        DispatchCenter: "Machhiwara_MnBzrDPP_D (Punjab)",
        OriginCenter: "Machhiwara_MnBzrDPP_D (Punjab)",
        FacilityCity: "Machhiwara",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1762,
        Pin: 321205,
        DispatchCenter: "Nagar_BypassRoad_D (Rajasthan)",
        OriginCenter: "Nagar_BypassRoad_D (Rajasthan)",
        FacilityCity: "Nagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1763,
        Pin: 841425,
        DispatchCenter: "Kuchaikote_Bhathwaparshuram_D (Bihar)",
        OriginCenter: "Kuchaikote_Bhathwaparshuram_D (Bihar)",
        FacilityCity: "Kuchaikote",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1764,
        Pin: 801118,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_PhulwariSharif_L (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1765,
        Pin: 761020,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1766,
        Pin: 785648,
        DispatchCenter: "Sivasagar_Pdinnagar_D (Assam)",
        OriginCenter: "Sivasagar_Pdinnagar_D (Assam)",
        FacilityCity: "Sivasagar",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1767,
        Pin: 110093,
        DispatchCenter: "Delhi_JhilmilColony_L (Delhi)",
        OriginCenter: "Delhi_JhilmilColony_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1768,
        Pin: 383250,
        DispatchCenter: "Modasa_Shyamnagar_D (Gujarat)",
        OriginCenter: "Modasa_Shyamnagar_D (Gujarat)",
        FacilityCity: "Modasa",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1769,
        Pin: 431103,
        DispatchCenter: "Kannad_Pragaticolony_DPP (Maharashtra)",
        OriginCenter: "Kannad_Pragaticolony_DPP (Maharashtra)",
        FacilityCity: "Kannad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1770,
        Pin: 421003,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1771,
        Pin: 124107,
        DispatchCenter: "Jhajjar_Policeline_I (Haryana)",
        OriginCenter: "Jhajjar_Policeline_I (Haryana)",
        FacilityCity: "Jhajjar",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1772,
        Pin: 261205,
        DispatchCenter: "Biswan_PatelNGR_D (Uttar Pradesh)",
        OriginCenter: "Biswan_PatelNGR_D (Uttar Pradesh)",
        FacilityCity: "Biswan",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1773,
        Pin: 516002,
        DispatchCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1774,
        Pin: 761151,
        DispatchCenter: "Balliguda_Simanbadi_DPP (Orissa)",
        OriginCenter: "Balliguda_Simanbadi_DPP (Orissa)",
        FacilityCity: "Balliguda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1775,
        Pin: 607403,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1776,
        Pin: 530008,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1777,
        Pin: 600068,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1778,
        Pin: 520002,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1779,
        Pin: 713171,
        DispatchCenter: "Panagarh_Kanksa_D (West Bengal)",
        OriginCenter: "Panagarh_Kanksa_D (West Bengal)",
        FacilityCity: "Panagarh",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1780,
        Pin: 825327,
        DispatchCenter: "Hazaribagh_Matwari_D (Jharkhand)",
        OriginCenter: "Hazaribagh_Matwari_D (Jharkhand)",
        FacilityCity: "hazaribagh",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1781,
        Pin: 251327,
        DispatchCenter: "Deoband_Shivpuricolony_D (Uttar Pradesh)",
        OriginCenter: "Deoband_Shivpuricolony_D (Uttar Pradesh)",
        FacilityCity: "Deoband",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1782,
        Pin: 271825,
        DispatchCenter: "Bahraich_Dularpur_H (Uttar Pradesh)",
        OriginCenter: "Bahraich_Dularpur_H (Uttar Pradesh)",
        FacilityCity: "Bahraich",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1783,
        Pin: 143528,
        DispatchCenter: "Gurdaspur_Civillines_D (Punjab)",
        OriginCenter: "Gurdaspur_Civillines_D (Punjab)",
        FacilityCity: "Gurdaspur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1784,
        Pin: 601101,
        DispatchCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        OriginCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1785,
        Pin: 412306,
        DispatchCenter: "Nira_Central_D (Maharashtra)",
        OriginCenter: "Nira_Central_D (Maharashtra)",
        FacilityCity: "Nira",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1786,
        Pin: 221303,
        DispatchCenter: "Gopiganj_Gerai_D (Uttar Pradesh)",
        OriginCenter: "Gopiganj_Gerai_D (Uttar Pradesh)",
        FacilityCity: "Gopiganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1787,
        Pin: 335802,
        DispatchCenter: "Hanumangarh_DhaanMandi_I (Rajasthan)",
        OriginCenter: "Hanumangarh_DhaanMandi_I (Rajasthan)",
        FacilityCity: "Hanumangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1788,
        Pin: 610111,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1789,
        Pin: 786152,
        DispatchCenter: "DoomDooma_Kakopathar_D (Assam)",
        OriginCenter: "DoomDooma_Kakopathar_D (Assam)",
        FacilityCity: "Doom Dooma",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1790,
        Pin: 627751,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1791,
        Pin: 144520,
        DispatchCenter: "Garhshankar_BangaRoad_D (Punjab)",
        OriginCenter: "Garhshankar_BangaRoad_D (Punjab)",
        FacilityCity: "Garhshanker",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1792,
        Pin: 151102,
        DispatchCenter: "RampuraPhul_DC (Punjab)",
        OriginCenter: "RampuraPhul_DC (Punjab)",
        FacilityCity: "Rampura Phul",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1793,
        Pin: 841305,
        DispatchCenter: "Chhapra_Ramnagar_I (Bihar)",
        OriginCenter: "Chhapra_Ramnagar_I (Bihar)",
        FacilityCity: "Chhapra",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1794,
        Pin: 500060,
        DispatchCenter: "Hyderabad_Autonagar_L (Telangana)",
        OriginCenter: "Hyderabad_Autonagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1795,
        Pin: 638466,
        DispatchCenter: "Mettupalayam_Belladhi_D (Tamil Nadu)",
        OriginCenter: "Mettupalayam_Belladhi_D (Tamil Nadu)",
        FacilityCity: "Mettupalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1796,
        Pin: 781313,
        DispatchCenter: "Barpeta_NorthDPP_D (Assam)",
        OriginCenter: "Barpeta_ShaktiNagar_C (Assam)",
        FacilityCity: "Barpeta",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1797,
        Pin: 752083,
        DispatchCenter: "Daspalla_Andharkota_DPP (Orissa)",
        OriginCenter: "Daspalla_Andharkota_DPP (Orissa)",
        FacilityCity: "Daspalla",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1798,
        Pin: 464672,
        DispatchCenter: "Silwani_Padariyakalan_DPP (Madhya Pradesh)",
        OriginCenter: "Silwani_Padariyakalan_DPP (Madhya Pradesh)",
        FacilityCity: "Silwani",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1799,
        Pin: 585329,
        DispatchCenter: "Humnabad_ApmcYard_DPP (Karnataka)",
        OriginCenter: "Humnabad_ApmcYard_DPP (Karnataka)",
        FacilityCity: "Humnabad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1800,
        Pin: 622501,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1801,
        Pin: 591213,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1802,
        Pin: 143511,
        DispatchCenter: "Batala_Simbel_D (Punjab)",
        OriginCenter: "Batala_Simbel_D (Punjab)",
        FacilityCity: "Batala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1803,
        Pin: 384122,
        DispatchCenter: "Unjha_SatymComplx_D (Gujarat)",
        OriginCenter: "Unjha_SatymComplx_D (Gujarat)",
        FacilityCity: "Unjha",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1804,
        Pin: 441907,
        DispatchCenter: "Tumsar_Dewhadi_DPP (Maharashtra)",
        OriginCenter: "Tumsar_Dewhadi_DPP (Maharashtra)",
        FacilityCity: "Tumsar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1805,
        Pin: 614616,
        DispatchCenter: "Pudukkottai_Lnpuram_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Lnpuram_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1806,
        Pin: 688009,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1807,
        Pin: 151508,
        DispatchCenter: "Mansa_GreenVly_D (Punjab)",
        OriginCenter: "Mansa_GreenVly_D (Punjab)",
        FacilityCity: "Mansa",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1808,
        Pin: 532440,
        DispatchCenter: "Palakonda_VadaiDPP_D (Andhra Pradesh)",
        OriginCenter: "Palakonda_VadaiDPP_D (Andhra Pradesh)",
        FacilityCity: "Palakonda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1809,
        Pin: 516163,
        DispatchCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1810,
        Pin: 799010,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1811,
        Pin: 180011,
        DispatchCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1812,
        Pin: 412207,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1813,
        Pin: 829202,
        DispatchCenter: "Balumath_Basukinath_D (Jharkhand)",
        OriginCenter: "Balumath_Basukinath_D (Jharkhand)",
        FacilityCity: "Balumath",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1814,
        Pin: 695522,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1815,
        Pin: 370030,
        DispatchCenter: "Bhuj_Madhapar_D (Gujarat)",
        OriginCenter: "Bhuj_Madhapar_D (Gujarat)",
        FacilityCity: "Bhuj",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1816,
        Pin: 360055,
        DispatchCenter: "Jasdan_Aanandnagar_D (Gujarat)",
        OriginCenter: "Jasdan_Aanandnagar_D (Gujarat)",
        FacilityCity: "Jasdan",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1817,
        Pin: 680582,
        DispatchCenter: "Shoranur_Akamala_D (Kerala)",
        OriginCenter: "Shoranur_Akamala_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1818,
        Pin: 323301,
        DispatchCenter: "Keshoraipatan_Ishwar_DPP (Rajasthan)",
        OriginCenter: "Keshoraipatan_Ishwar_DPP (Rajasthan)",
        FacilityCity: "Keshoraipatan",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1819,
        Pin: 390020,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1820,
        Pin: 686508,
        DispatchCenter: "Kottayam_Mulakunnath_D (Kerala)",
        OriginCenter: "Kottayam_Mulakunnath_D (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1821,
        Pin: 140116,
        DispatchCenter: "AnandpurSahib_Dher_D (Punjab)",
        OriginCenter: "AnandpurSahib_Dher_D (Punjab)",
        FacilityCity: "Anandpur Sahib",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1822,
        Pin: 794106,
        DispatchCenter: "Mahendraganj_Malidaspara_D (Meghalaya)",
        OriginCenter: "Mahendraganj_Malidaspara_D (Meghalaya)",
        FacilityCity: "Mahendraganj",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1823,
        Pin: 793002,
        DispatchCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        OriginCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1824,
        Pin: 570013,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1825,
        Pin: 283136,
        DispatchCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        OriginCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        FacilityCity: "Shikohabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1826,
        Pin: 144505,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1827,
        Pin: 751004,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1828,
        Pin: 413109,
        DispatchCenter: "Walchandnagar_AshokNagar_D (Maharashtra)",
        OriginCenter: "Walchandnagar_AshokNagar_D (Maharashtra)",
        FacilityCity: "Walchandnagar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1829,
        Pin: 273403,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1830,
        Pin: 273209,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1831,
        Pin: 782485,
        DispatchCenter: "Hojai_KrishnaNagar_D (Assam)",
        OriginCenter: "Hojai_KrishnaNagar_D (Assam)",
        FacilityCity: "Hojai",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1832,
        Pin: 466116,
        DispatchCenter: "Ashta_Saicolony_D (Madhya Pradesh)",
        OriginCenter: "Ashta_Saicolony_D (Madhya Pradesh)",
        FacilityCity: "Ashta",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 1833,
        Pin: 591297,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1834,
        Pin: 486226,
        DispatchCenter: "Sohagi_PoliceThane_D (Madhya Pradesh)",
        OriginCenter: "Sohagi_PoliceThane_D (Madhya Pradesh)",
        FacilityCity: "Sohagi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1835,
        Pin: 229128,
        DispatchCenter: "Salon_Bazar_DPP (Uttar Pradesh)",
        OriginCenter: "Salon_Bazar_DPP (Uttar Pradesh)",
        FacilityCity: "Salon",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1836,
        Pin: 742225,
        DispatchCenter: "Raghunathganj_NH12_D (West Bengal)",
        OriginCenter: "Raghunathganj_NH12_D (West Bengal)",
        FacilityCity: "Raghunathganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1837,
        Pin: 326518,
        DispatchCenter: "RamganjMandi_AdarshNagar_D (Rajasthan)",
        OriginCenter: "RamganjMandi_AdarshNagar_D (Rajasthan)",
        FacilityCity: "Ramganj Mandi",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1838,
        Pin: 230132,
        DispatchCenter: "LalganjAjhara_HPPump_D (Uttar Pradesh)",
        OriginCenter: "LalganjAjhara_HPPump_D (Uttar Pradesh)",
        FacilityCity: "Lalganj Ajhara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1839,
        Pin: 854357,
        DispatchCenter: "Forbesganj_Jummanchowk_D (Bihar)",
        OriginCenter: "Forbesganj_Jummanchowk_D (Bihar)",
        FacilityCity: "Forbesganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1840,
        Pin: 243727,
        DispatchCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        OriginCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        FacilityCity: "Anupshahar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1841,
        Pin: 415517,
        DispatchCenter: "Wai_Panchwad_DPP (Maharashtra)",
        OriginCenter: "Wai_Panchwad_DPP (Maharashtra)",
        FacilityCity: "Wai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1842,
        Pin: 230402,
        DispatchCenter: "Pratapgarh_Mandhata_D (Uttar Pradesh)",
        OriginCenter: "Pratapgarh_Mandhata_D (Uttar Pradesh)",
        FacilityCity: "Pratapgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1843,
        Pin: 382009,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1844,
        Pin: 261204,
        DispatchCenter: "FatehpurUP_PatelNagar_D (Uttar Pradesh)",
        OriginCenter: "FatehpurUP_PatelNagar_D (Uttar Pradesh)",
        FacilityCity: "Fatehpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1845,
        Pin: 689624,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1846,
        Pin: 577002,
        DispatchCenter: "Davangere_Industrialarea_I (Karnataka)",
        OriginCenter: "Davangere_Industrialarea_I (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1847,
        Pin: 591135,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1848,
        Pin: 144302,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1849,
        Pin: 630101,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1850,
        Pin: 504297,
        DispatchCenter: "Tiryani_KomurambeemChk_DPP (Telangana)",
        OriginCenter: "Tiryani_KomurambeemChk_DPP (Telangana)",
        FacilityCity: "Tiryani",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1851,
        Pin: 695583,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1852,
        Pin: 686028,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1853,
        Pin: 504102,
        DispatchCenter: "Bhainsa_Pipri_D (Telangana)",
        OriginCenter: "Bhainsa_Pipri_D (Telangana)",
        FacilityCity: "Bhainsa",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1854,
        Pin: 110047,
        DispatchCenter: "Delhi_BamnoliVillage_L (Delhi)",
        OriginCenter: "Delhi_BamnoliVillage_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1855,
        Pin: 679512,
        DispatchCenter: "Akaloor_Lakdi_DPP (Kerala)",
        OriginCenter: "Akaloor_Lakdi_DPP (Kerala)",
        FacilityCity: "Akaloor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1856,
        Pin: 462020,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1857,
        Pin: 385210,
        DispatchCenter: "Palanpur_Esbipura1_D (Gujarat)",
        OriginCenter: "Palanpur_Esbipura1_D (Gujarat)",
        FacilityCity: "Palanpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1858,
        Pin: 382006,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1859,
        Pin: 201206,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1860,
        Pin: 713344,
        DispatchCenter: "Asansol_Addacolony_D (West Bengal)",
        OriginCenter: "Asansol_Addacolony_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1861,
        Pin: 422402,
        DispatchCenter: "Igatpuri_Phulenagar_D (Maharashtra)",
        OriginCenter: "Igatpuri_Phulenagar_D (Maharashtra)",
        FacilityCity: "Igatpuri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1862,
        Pin: 713321,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1863,
        Pin: 603309,
        DispatchCenter: "Melmaruvathur_IOBank_D (Tamil Nadu)",
        OriginCenter: "Melmaruvathur_IOBank_D (Tamil Nadu)",
        FacilityCity: "Melmaruvathur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1864,
        Pin: 636454,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1865,
        Pin: 401504,
        DispatchCenter: "Palghar_Maan_L (Maharashtra)",
        OriginCenter: "Palghar_Maan_L (Maharashtra)",
        FacilityCity: "Palghar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1866,
        Pin: 638152,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1867,
        Pin: 332307,
        DispatchCenter: "Jhunjhunu_Mandawa_DPP (Rajasthan)",
        OriginCenter: "Jhunjhunu_Mandawa_DPP (Rajasthan)",
        FacilityCity: "Jhunjhunu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1868,
        Pin: 758046,
        DispatchCenter: "Karanjia_Ankura_D (Orissa)",
        OriginCenter: "Karanjia_Ankura_D (Orissa)",
        FacilityCity: "Karanjia",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1869,
        Pin: 174201,
        DispatchCenter: "Tahliwal_Central_D (Himachal Pradesh)",
        OriginCenter: "Tahliwal_Central_D (Himachal Pradesh)",
        FacilityCity: "Tahliwal",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1870,
        Pin: 249408,
        DispatchCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        OriginCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        FacilityCity: "Haridwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1871,
        Pin: 695127,
        DispatchCenter: "Trivandrum_Neyatinkra_D (Kerala)",
        OriginCenter: "Trivandrum_Neyatinkra_D (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1872,
        Pin: 843311,
        DispatchCenter: "Runnisaidpur_Madhvnagar_D (Bihar)",
        OriginCenter: "Runnisaidpur_Madhvnagar_D (Bihar)",
        FacilityCity: "Runnisaidpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1873,
        Pin: 843110,
        DispatchCenter: "Tajpur_HsptlRd_D (Bihar)",
        OriginCenter: "Tajpur_HsptlRd_D (Bihar)",
        FacilityCity: "Tajpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1874,
        Pin: 752078,
        DispatchCenter: "Kalapathar_GoleiChhak_D (Orissa)",
        OriginCenter: "Kalapathar_GoleiChhak_D (Orissa)",
        FacilityCity: "Kalapathar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1875,
        Pin: 685602,
        DispatchCenter: "Iddukki_Thadiyampad_D (Kerala)",
        OriginCenter: "Iddukki_Thadiyampad_D (Kerala)",
        FacilityCity: "Iddukki",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1876,
        Pin: 494005,
        DispatchCenter: "Jagdalpur_Hatkchora_D (Chhattisgarh)",
        OriginCenter: "Jagdalpur_Hatkchora_D (Chhattisgarh)",
        FacilityCity: "Jagdalpur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1877,
        Pin: 761100,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1878,
        Pin: 413509,
        DispatchCenter: "Osmanabad_Murud_D (Maharashtra)",
        OriginCenter: "Osmanabad_Murud_D (Maharashtra)",
        FacilityCity: "Osmanabad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1879,
        Pin: 531060,
        DispatchCenter: "Yellamanchili_Chodapalle_D (Andhra Pradesh)",
        OriginCenter: "Yellamanchili_Chodapalle_D (Andhra Pradesh)",
        FacilityCity: "Yellamanchili",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1880,
        Pin: 803302,
        DispatchCenter: "Sheonor_Moldiyar_D (Bihar)",
        OriginCenter: "Sheonor_Moldiyar_D (Bihar)",
        FacilityCity: "Sheonar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1881,
        Pin: 471101,
        DispatchCenter: "Khajuraho_Sevagram_DPP (Madhya Pradesh)",
        OriginCenter: "Khajuraho_Sevagram_DPP (Madhya Pradesh)",
        FacilityCity: "Khajuraho",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1882,
        Pin: 591147,
        DispatchCenter: "Bailhongal_Azadnagar_D (Karnataka)",
        OriginCenter: "Bailhongal_Azadnagar_D (Karnataka)",
        FacilityCity: "Bailhongal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1883,
        Pin: 757105,
        DispatchCenter: "Baripada_KalikDPP_D (Orissa)",
        OriginCenter: "Baripada_KalikDPP_D (Orissa)",
        FacilityCity: "Baripada",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1884,
        Pin: 306707,
        DispatchCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        OriginCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        FacilityCity: "Sawai Madhopur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1885,
        Pin: 802312,
        DispatchCenter: "Arrah_Anaith_D (Bihar)",
        OriginCenter: "Arrah_Anaith_D (Bihar)",
        FacilityCity: "Arrah",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1886,
        Pin: 623315,
        DispatchCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        OriginCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        FacilityCity: "Thiruvadanai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1887,
        Pin: 517126,
        DispatchCenter: "Pakala_Gorpadu_D (Andhra Pradesh)",
        OriginCenter: "Pakala_Gorpadu_D (Andhra Pradesh)",
        FacilityCity: "Pakala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1888,
        Pin: 637019,
        DispatchCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        OriginCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        FacilityCity: "Namakkal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1889,
        Pin: 848131,
        DispatchCenter: "Tajpur_HsptlRd_D (Bihar)",
        OriginCenter: "Tajpur_HsptlRd_D (Bihar)",
        FacilityCity: "Tajpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1890,
        Pin: 560064,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1891,
        Pin: 472442,
        DispatchCenter: "Prithvipur_Niwari_D (Madhya Pradesh)",
        OriginCenter: "Prithvipur_Niwari_D (Madhya Pradesh)",
        FacilityCity: "Prithvipur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 1892,
        Pin: 723150,
        DispatchCenter: "Asansol_Gobindapur_D (West Bengal)",
        OriginCenter: "Asansol_Gobindapur_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1893,
        Pin: 757003,
        DispatchCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        OriginCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        FacilityCity: "Betnoti",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1894,
        Pin: 345022,
        DispatchCenter: "Jaisalmer_Gaffurbhatt_D (Rajasthan)",
        OriginCenter: "Jaisalmer_Gaffurbhatt_D (Rajasthan)",
        FacilityCity: "Jaisalmer",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1895,
        Pin: 271604,
        DispatchCenter: "Balrampur_Utraula_D (Uttar Pradesh)",
        OriginCenter: "Balrampur_Utraula_D (Uttar Pradesh)",
        FacilityCity: "Balrampur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1896,
        Pin: 493551,
        DispatchCenter: "Pithora_PostOffc_DPP (Chhattisgarh)",
        OriginCenter: "Pithora_PostOffc_DPP (Chhattisgarh)",
        FacilityCity: "Pithora",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1897,
        Pin: 190025,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1898,
        Pin: 400098,
        DispatchCenter: "Mumbai_Kurla_L (Maharashtra)",
        OriginCenter: "Mumbai_Kurla_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1899,
        Pin: 577535,
        DispatchCenter: "Molakalmuru_KnsgraRD_D (Karnataka)",
        OriginCenter: "Molakalmuru_KnsgraRD_D (Karnataka)",
        FacilityCity: "Molakalmuru",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1900,
        Pin: 743711,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1901,
        Pin: 177007,
        DispatchCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        OriginCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1902,
        Pin: 413738,
        DispatchCenter: "Sangamner_Ksarwdi_D (Maharashtra)",
        OriginCenter: "Sangamner_Ksarwdi_D (Maharashtra)",
        FacilityCity: "Sangamner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1903,
        Pin: 680541,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1904,
        Pin: 522411,
        DispatchCenter: "Piduguralla_Rasoolpet_D (Andhra Pradesh)",
        OriginCenter: "Piduguralla_Rasoolpet_D (Andhra Pradesh)",
        FacilityCity: "Piduguralla",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1905,
        Pin: 394620,
        DispatchCenter: "Bardoli_GopalNagar_D (Gujarat)",
        OriginCenter: "Bardoli_GopalNagar_D (Gujarat)",
        FacilityCity: "Bardoli",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1906,
        Pin: 363040,
        DispatchCenter: "Surendranagar_GIDC_D (Gujarat)",
        OriginCenter: "Surendranagar_GIDC_D (Gujarat)",
        FacilityCity: "Surendranagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1907,
        Pin: 394445,
        DispatchCenter: "Dediapada_Kevdi_DPP (Gujarat)",
        OriginCenter: "Dediapada_Kevdi_DPP (Gujarat)",
        FacilityCity: "Dediapada",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1908,
        Pin: 577547,
        DispatchCenter: "Kadur_Ajjampura_D (Karnataka)",
        OriginCenter: "Kadur_Ajjampura_D (Karnataka)",
        FacilityCity: "Kadur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1909,
        Pin: 828404,
        DispatchCenter: "Bokaro_Chas_D (Jharkhand)",
        OriginCenter: "Bokaro_Chas_D (Jharkhand)",
        FacilityCity: "Bokaro",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1910,
        Pin: 673312,
        DispatchCenter: "Mahe_Chokli_D (Kerala)",
        OriginCenter: "Mahe_Chokli_D (Kerala)",
        FacilityCity: "Mahe",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1911,
        Pin: 643214,
        DispatchCenter: "Kotagiri_CroslySRT_D (Tamil Nadu)",
        OriginCenter: "Kotagiri_CroslySRT_D (Tamil Nadu)",
        FacilityCity: "Kotagiri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1912,
        Pin: 586108,
        DispatchCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        OriginCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        FacilityCity: "Bijapur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1913,
        Pin: 144419,
        DispatchCenter: "Phillaur_Parkashnagar_D (Punjab)",
        OriginCenter: "Phillaur_Parkashnagar_D (Punjab)",
        FacilityCity: "Phillaur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1914,
        Pin: 177219,
        DispatchCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        OriginCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        FacilityCity: "Amb",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1915,
        Pin: 751030,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1916,
        Pin: 332721,
        DispatchCenter: "Udaipurwati_Khandela_D (Rajasthan)",
        OriginCenter: "Udaipurwati_Khandela_D (Rajasthan)",
        FacilityCity: "Khandela",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1917,
        Pin: 535463,
        DispatchCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        OriginCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        FacilityCity: "Parvathipuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1918,
        Pin: 627001,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1919,
        Pin: 424202,
        DispatchCenter: "Pachora_AadarshNagar_D (Maharashtra)",
        OriginCenter: "Pachora_AadarshNagar_D (Maharashtra)",
        FacilityCity: "Pachora",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1920,
        Pin: 530052,
        DispatchCenter: "Bheemunipatnam_PstOfc_D (Andhra Pradesh)",
        OriginCenter: "Bheemunipatnam_PstOfc_D (Andhra Pradesh)",
        FacilityCity: "Bheemunipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1921,
        Pin: 477117,
        DispatchCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        OriginCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        FacilityCity: "Gwalior",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1922,
        Pin: 507154,
        DispatchCenter: "Kothagudem_OldPaloncha_D (Telangana)",
        OriginCenter: "Kothagudem_OldPaloncha_D (Telangana)",
        FacilityCity: "Kothagudem",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1923,
        Pin: 124507,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1924,
        Pin: 763003,
        DispatchCenter: "Semiliguda_HimgiriNagar_D (Orissa)",
        OriginCenter: "Semiliguda_HimgiriNagar_D (Orissa)",
        FacilityCity: "Semiliguda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 1925,
        Pin: 400167,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1926,
        Pin: 241405,
        DispatchCenter: "Hardoi_AvsVikasColny_D (Uttar Pradesh)",
        OriginCenter: "Hardoi_AvsVikasColny_D (Uttar Pradesh)",
        FacilityCity: "Hardoi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1927,
        Pin: 172101,
        DispatchCenter: "Jeori_AadvikEng_DPP (Himachal Pradesh)",
        OriginCenter: "Jeori_AadvikEng_DPP (Himachal Pradesh)",
        FacilityCity: "Jeori",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1928,
        Pin: 522010,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1929,
        Pin: 472336,
        DispatchCenter: "Prithvipur_JeronRd_DPP (Madhya Pradesh)",
        OriginCenter: "Prithvipur_JeronRd_DPP (Madhya Pradesh)",
        FacilityCity: "Prithvipur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1930,
        Pin: 326033,
        DispatchCenter: "Aklera_Mangta_D (Rajasthan)",
        OriginCenter: "Aklera_Mangta_D (Rajasthan)",
        FacilityCity: "Aklera",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1931,
        Pin: 473440,
        DispatchCenter: "Mungaoli_BhadurpurRd_DPP (Madhya Pradesh)",
        OriginCenter: "Mungaoli_BhadurpurRd_DPP (Madhya Pradesh)",
        FacilityCity: "Mungaoli",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 1932,
        Pin: 683101,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1933,
        Pin: 141010,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1934,
        Pin: 680322,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1935,
        Pin: 764078,
        DispatchCenter: "Nabarangpr_PhdofDPP_D (Orissa)",
        OriginCenter: "Nabarangpr_PhdofDPP_D (Orissa)",
        FacilityCity: "Nabarangapur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1936,
        Pin: 636116,
        DispatchCenter: "Chinnasalem_VKootRd_D (Tamil Nadu)",
        OriginCenter: "Chinnasalem_VKootRd_D (Tamil Nadu)",
        FacilityCity: "Chinnasalem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1937,
        Pin: 312614,
        DispatchCenter: "Nimbahera_Chotisadri_DPP (Rajasthan)",
        OriginCenter: "Nimbahera_Chotisadri_DPP (Rajasthan)",
        FacilityCity: "Nimbahera",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1938,
        Pin: 741165,
        DispatchCenter: "Nazirpur_Thanarpara_D (West Bengal)",
        OriginCenter: "Nazirpur_Thanarpara_D (West Bengal)",
        FacilityCity: "Nazirpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1939,
        Pin: 457222,
        DispatchCenter: "Ratlam_MhowRd_D (Madhya Pradesh)",
        OriginCenter: "Ratlam_MhowRd_D (Madhya Pradesh)",
        FacilityCity: "Ratlam",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1940,
        Pin: 607209,
        DispatchCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        OriginCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        FacilityCity: "Viluppuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1941,
        Pin: 783394,
        DispatchCenter: "Bongaigaon_Chapaguri_I (Assam)",
        OriginCenter: "Bongaigaon_Chapaguri_I (Assam)",
        FacilityCity: "Bongaigaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1942,
        Pin: 307515,
        DispatchCenter: "Bhinmal_RaniwaraKhurd_D (Rajasthan)",
        OriginCenter: "Bhinmal_RaniwaraKhurd_D (Rajasthan)",
        FacilityCity: "Raniwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1943,
        Pin: 470119,
        DispatchCenter: "Rahatgarh_Ward13_DPP (Madhya Pradesh)",
        OriginCenter: "Rahatgarh_Ward13_DPP (Madhya Pradesh)",
        FacilityCity: "Rahatgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1944,
        Pin: 133004,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1945,
        Pin: 635203,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1946,
        Pin: 691503,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1947,
        Pin: 311602,
        DispatchCenter: "Mandalgarh_Vakilcolony_D (Rajasthan)",
        OriginCenter: "Mandalgarh_Vakilcolony_D (Rajasthan)",
        FacilityCity: "Mandalgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1948,
        Pin: 765015,
        DispatchCenter: "Tikiri_Ushpda_D (Orissa)",
        OriginCenter: "Tikiri_Ushpda_D (Orissa)",
        FacilityCity: "Tikiri",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1949,
        Pin: 680682,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1950,
        Pin: 637301,
        DispatchCenter: "Sankari_Idappadi_D (Tamil Nadu)",
        OriginCenter: "Sankari_Idappadi_D (Tamil Nadu)",
        FacilityCity: "Sankari",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1951,
        Pin: 508258,
        DispatchCenter: "Devarakonda_Karunacol_D (Telangana)",
        OriginCenter: "Devarakonda_Karunacol_D (Telangana)",
        FacilityCity: "Devarakonda",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1952,
        Pin: 209312,
        DispatchCenter: "Pukhrayan_Arulimod_D (Uttar Pradesh)",
        OriginCenter: "Pukhrayan_Arulimod_D (Uttar Pradesh)",
        FacilityCity: "Pukhrayan",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1953,
        Pin: 700126,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1954,
        Pin: 133007,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1955,
        Pin: 382045,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1956,
        Pin: 360480,
        DispatchCenter: "Jamjodhpur_SubhashRd_DPP (Gujarat)",
        OriginCenter: "Jamjodhpur_SubhashRd_DPP (Gujarat)",
        FacilityCity: "Jamjodhpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1957,
        Pin: 444110,
        DispatchCenter: "Murtizapur_Shivajinagar_DPP (Maharashtra)",
        OriginCenter: "Murtizapur_Shivajinagar_DPP (Maharashtra)",
        FacilityCity: "Murtizapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1958,
        Pin: 144301,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1959,
        Pin: 322215,
        DispatchCenter: "Nadoti_Ibrahimpur_D (Rajasthan)",
        OriginCenter: "Nadoti_Ibrahimpur_D (Rajasthan)",
        FacilityCity: "Nadoti",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1960,
        Pin: 133006,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1961,
        Pin: 603310,
        DispatchCenter: "Melmaruvathur_IOBank_D (Tamil Nadu)",
        OriginCenter: "Melmaruvathur_IOBank_D (Tamil Nadu)",
        FacilityCity: "Melmaruvathur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1962,
        Pin: 743252,
        DispatchCenter: "Joypul_Maniktala_D (West Bengal)",
        OriginCenter: "Joypul_Maniktala_D (West Bengal)",
        FacilityCity: "Joypul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1963,
        Pin: 803120,
        DispatchCenter: "Chandi_SH78_D (Bihar)",
        OriginCenter: "Chandi_SH78_D (Bihar)",
        FacilityCity: "Chandi",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1964,
        Pin: 396436,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1965,
        Pin: 225201,
        DispatchCenter: "Ramnagar_Burhwal_D (Uttar Pradesh)",
        OriginCenter: "Ramnagar_Burhwal_D (Uttar Pradesh)",
        FacilityCity: "Ramnagar-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1966,
        Pin: 523166,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1967,
        Pin: 396469,
        DispatchCenter: "Navsari_Vejalpore_D (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Navsari",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 1968,
        Pin: 783334,
        DispatchCenter: "Golakganj_SouthTokrerchara_DPP (Assam)",
        OriginCenter: "Golakganj_SouthTokrerchara_DPP (Assam)",
        FacilityCity: "Golakganj",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 1969,
        Pin: 244105,
        DispatchCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        OriginCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        FacilityCity: "Moradabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1970,
        Pin: 700119,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1971,
        Pin: 523302,
        DispatchCenter: "Martur_Nagarajupalli_D (Andhra Pradesh)",
        OriginCenter: "Martur_Nagarajupalli_D (Andhra Pradesh)",
        FacilityCity: "Martur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1972,
        Pin: 413403,
        DispatchCenter: "Barshi_Indiranagar_D (Maharashtra)",
        OriginCenter: "Barshi_Indiranagar_D (Maharashtra)",
        FacilityCity: "Barshi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 1973,
        Pin: 533210,
        DispatchCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        OriginCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        FacilityCity: "Amalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1974,
        Pin: 171202,
        DispatchCenter: "Kotkhai_Nehra_D (Himachal Pradesh)",
        OriginCenter: "Kotkhai_Nehra_D (Himachal Pradesh)",
        FacilityCity: "Kotkhai",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 1975,
        Pin: 250621,
        DispatchCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        OriginCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        FacilityCity: "Baraut",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1976,
        Pin: 688535,
        DispatchCenter: "Cochin_Aroor_D (Kerala)",
        OriginCenter: "Cochin_Aroor_D (Kerala)",
        FacilityCity: "Ezhupunna",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 1977,
        Pin: 507118,
        DispatchCenter: "Kothagudem_OldPaloncha_D (Telangana)",
        OriginCenter: "Kothagudem_OldPaloncha_D (Telangana)",
        FacilityCity: "Kothagudem",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1978,
        Pin: 841242,
        DispatchCenter: "Maharajganj_Sihauta_D (Bihar)",
        OriginCenter: "Maharajganj_Sihauta_D (Bihar)",
        FacilityCity: "Maharajganj-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1979,
        Pin: 695302,
        DispatchCenter: "Attingal_Central_D_2 (Kerala)",
        OriginCenter: "Attingal_Central_D_2 (Kerala)",
        FacilityCity: "Attingal",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 1980,
        Pin: 144012,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1981,
        Pin: 471411,
        DispatchCenter: "Chhatarpur_SagarRd_D (Madhya Pradesh)",
        OriginCenter: "Chhatarpur_SagarRd_D (Madhya Pradesh)",
        FacilityCity: "Chhatarpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1982,
        Pin: 530004,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1983,
        Pin: 486882,
        DispatchCenter: "Deosar_Samda_D (Madhya Pradesh)",
        OriginCenter: "Deosar_Samda_D (Madhya Pradesh)",
        FacilityCity: "Deosar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 1984,
        Pin: 572105,
        DispatchCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        OriginCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1985,
        Pin: 342028,
        DispatchCenter: "Balesar_Unthwaliya_DPP (Rajasthan)",
        OriginCenter: "Balesar_Unthwaliya_DPP (Rajasthan)",
        FacilityCity: "Balesar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 1986,
        Pin: 606111,
        DispatchCenter: "Pennadam_Puthar_D (Tamil Nadu)",
        OriginCenter: "Pennadam_Puthar_D (Tamil Nadu)",
        FacilityCity: "Pennadam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 1987,
        Pin: 505415,
        DispatchCenter: "KarimNagar_Alugunoor_I (Telangana)",
        OriginCenter: "KarimNagar_Alugunoor_I (Telangana)",
        FacilityCity: "Karim Nagar",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1988,
        Pin: 811309,
        DispatchCenter: "Lakhisarai_Central_I_2 (Bihar)",
        OriginCenter: "Lakhisarai_Central_I_2 (Bihar)",
        FacilityCity: "Lakhisarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1989,
        Pin: 524005,
        DispatchCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        OriginCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1990,
        Pin: 151106,
        DispatchCenter: "RampuraPhul_DC (Punjab)",
        OriginCenter: "RampuraPhul_DC (Punjab)",
        FacilityCity: "Rampura Phul",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 1991,
        Pin: 124504,
        DispatchCenter: "Jhajjar_Policeline_I (Haryana)",
        OriginCenter: "Jhajjar_Policeline_I (Haryana)",
        FacilityCity: "Jhajjar",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 1992,
        Pin: 500016,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1993,
        Pin: 741254,
        DispatchCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        OriginCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        FacilityCity: "Ranaghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1994,
        Pin: 712221,
        DispatchCenter: "Hooghly_DC (West Bengal)",
        OriginCenter: "Hooghly_DC (West Bengal)",
        FacilityCity: "Hooghly",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 1995,
        Pin: 382727,
        DispatchCenter: "Kadi_Jadavpura_L (Gujarat)",
        OriginCenter: "Kadi_Jadavpura_L (Gujarat)",
        FacilityCity: "Kadi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 1996,
        Pin: 431109,
        DispatchCenter: "Gangapur_RehnaClny_DPP (Maharashtra)",
        OriginCenter: "Gangapur_RehnaClny_DPP (Maharashtra)",
        FacilityCity: "Gangapur-MH",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 1997,
        Pin: 841318,
        DispatchCenter: "Hajipur_Hathsarganj_D (Bihar)",
        OriginCenter: "Hajipur_Hathsarganj_D (Bihar)",
        FacilityCity: "Hajipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 1998,
        Pin: 639007,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 1999,
        Pin: 628623,
        DispatchCenter: "Eral_Nazareth_D (Tamil Nadu)",
        OriginCenter: "Eral_Nazareth_D (Tamil Nadu)",
        FacilityCity: "Eral",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2000,
        Pin: 524306,
        DispatchCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        OriginCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2001,
        Pin: 281202,
        DispatchCenter: "Vrindavan_Jait_D (Uttar Pradesh)",
        OriginCenter: "Vrindavan_Jait_D (Uttar Pradesh)",
        FacilityCity: "Vrindavan",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2002,
        Pin: 144516,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2003,
        Pin: 522403,
        DispatchCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasaraopet",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2004,
        Pin: 788103,
        DispatchCenter: "Pailapool_Lagktlbocpr_D (Assam)",
        OriginCenter: "Pailapool_Lagktlbocpr_D (Assam)",
        FacilityCity: "Pailapool",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2005,
        Pin: 574233,
        DispatchCenter: "Bantwal_BambilRd_D (Karnataka)",
        OriginCenter: "Bantwal_BambilRd_D (Karnataka)",
        FacilityCity: "Bantwal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2006,
        Pin: 335027,
        DispatchCenter: "PadampurRJ_Srikaranpur_D (Rajasthan)",
        OriginCenter: "PadampurRJ_Srikaranpur_D (Rajasthan)",
        FacilityCity: "PadampurRJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2007,
        Pin: 689519,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2008,
        Pin: 609106,
        DispatchCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        OriginCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        FacilityCity: "Sirkazhi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2009,
        Pin: 334006,
        DispatchCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        OriginCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        FacilityCity: "Bikaner",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2010,
        Pin: 415107,
        DispatchCenter: "Rahimatpur_Wathar_DPP (Maharashtra)",
        OriginCenter: "Rahimatpur_Wathar_DPP (Maharashtra)",
        FacilityCity: "Rahimatpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2011,
        Pin: 341001,
        DispatchCenter: "Nagaur_Jantacomplex_D (Rajasthan)",
        OriginCenter: "Nagaur_Jantacomplex_D (Rajasthan)",
        FacilityCity: "Nagaur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2012,
        Pin: 523357,
        DispatchCenter: "Giddalur_Nandyalroad_D (Andhra Pradesh)",
        OriginCenter: "Giddalur_Nandyalroad_D (Andhra Pradesh)",
        FacilityCity: "Giddalur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2013,
        Pin: 516115,
        DispatchCenter: "Rajampet_BypassRd_D (Andhra Pradesh)",
        OriginCenter: "Rajampet_BypassRd_D (Andhra Pradesh)",
        FacilityCity: "Rajampet",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2014,
        Pin: 606104,
        DispatchCenter: "Ulundurpet_HarishNgr_D (Tamil Nadu)",
        OriginCenter: "Ulundurpet_HarishNgr_D (Tamil Nadu)",
        FacilityCity: "Ulundurpet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2015,
        Pin: 262908,
        DispatchCenter: "Nighasan_PaliaKalan_D (Uttar Pradesh)",
        OriginCenter: "Nighasan_PaliaKalan_D (Uttar Pradesh)",
        FacilityCity: "Nighasan",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2016,
        Pin: 689595,
        DispatchCenter: "Haripad_Thalavady_D (Kerala)",
        OriginCenter: "Haripad_Thalavady_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2017,
        Pin: 322218,
        DispatchCenter: "Karauli_Tursangpura_DPP (Rajasthan)",
        OriginCenter: "Karauli_Tursangpura_DPP (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2018,
        Pin: 193109,
        DispatchCenter: "Srinagar_Pattan_DPP (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Pattan_DPP (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2019,
        Pin: 786622,
        DispatchCenter: "Naharkatia_JaypurNagar_D (Assam)",
        OriginCenter: "Naharkatia_JaypurNagar_D (Assam)",
        FacilityCity: "Naharkatia",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2020,
        Pin: 680656,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2021,
        Pin: 394651,
        DispatchCenter: "Songadh_Vyara_D (Gujarat)",
        OriginCenter: "Songadh_Vyara_D (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2022,
        Pin: 221603,
        DispatchCenter: "Madhuban_Gayatripuram_DPP (Uttar Pradesh)",
        OriginCenter: "Madhuban_Gayatripuram_DPP (Uttar Pradesh)",
        FacilityCity: "Madhuban",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2023,
        Pin: 391160,
        DispatchCenter: "ChhotaUdaipur_Panjrapole_D (Gujarat)",
        OriginCenter: "ChhotaUdaipur_Panjrapole_D (Gujarat)",
        FacilityCity: "Chhota Udaipur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2024,
        Pin: 400070,
        DispatchCenter: "Mumbai_Kurla_L (Maharashtra)",
        OriginCenter: "Mumbai_Kurla_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2025,
        Pin: 685586,
        DispatchCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        OriginCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        FacilityCity: "Thodupuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2026,
        Pin: 783325,
        DispatchCenter: "Dhubri_Tetultol_D (Assam)",
        OriginCenter: "Dhubri_Tetultol_D (Assam)",
        FacilityCity: "Dhubri",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2027,
        Pin: 212660,
        DispatchCenter: "Fatehpur_Radhanagar_D (Uttar Pradesh)",
        OriginCenter: "Fatehpur_Radhanagar_D (Uttar Pradesh)",
        FacilityCity: "Fatehpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2028,
        Pin: 797001,
        DispatchCenter: "Kohima_Thizama_D (Nagaland)",
        OriginCenter: "Kohima_Thizama_D (Nagaland)",
        FacilityCity: "Tlabung",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2029,
        Pin: 683591,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2030,
        Pin: 422001,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2031,
        Pin: 517506,
        DispatchCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        OriginCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        FacilityCity: "Tirupati",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2032,
        Pin: 263140,
        DispatchCenter: "Haldwani_Kaladhungi_D (Uttarakhand)",
        OriginCenter: "Haldwani_Kaladhungi_D (Uttarakhand)",
        FacilityCity: "Haldwani",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2033,
        Pin: 504301,
        DispatchCenter: "Mancherial_Nuspur_I (Telangana)",
        OriginCenter: "Mancherial_Nuspur_I (Telangana)",
        FacilityCity: "Mancherial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2034,
        Pin: 533292,
        DispatchCenter: "Gokavaram_MuraliNagar_D (Andhra Pradesh)",
        OriginCenter: "Gokavaram_MuraliNagar_D (Andhra Pradesh)",
        FacilityCity: "Gokavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2035,
        Pin: 781028,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2036,
        Pin: 202391,
        DispatchCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        OriginCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        FacilityCity: "Anupshahar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2037,
        Pin: 134204,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2038,
        Pin: 392162,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2039,
        Pin: 575159,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2040,
        Pin: 415803,
        DispatchCenter: "Lanja_GondeSakhalRd_D (Maharashtra)",
        OriginCenter: "Lanja_GondeSakhalRd_D (Maharashtra)",
        FacilityCity: "Lanja",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2041,
        Pin: 387650,
        DispatchCenter: "Kapadvanj_Dholikui_D (Gujarat)",
        OriginCenter: "Kapadvanj_Dholikui_D (Gujarat)",
        FacilityCity: "Kapadvanj",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2042,
        Pin: 465669,
        DispatchCenter: "Narsinghgarh_Bypassroad_D (Madhya Pradesh)",
        OriginCenter: "Narsinghgarh_Bypassroad_D (Madhya Pradesh)",
        FacilityCity: "Narsinghgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2043,
        Pin: 627253,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2044,
        Pin: 781352,
        DispatchCenter: "Barpeta_Bhera_D (Assam)",
        OriginCenter: "Barpeta_ShaktiNagar_C (Assam)",
        FacilityCity: "Barpeta",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2045,
        Pin: 602026,
        DispatchCenter: "Gummudipoondi_Thandalam_D (Tamil Nadu)",
        OriginCenter: "Gummudipoondi_Thandalam_D (Tamil Nadu)",
        FacilityCity: "Gummudipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2046,
        Pin: 177118,
        DispatchCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        OriginCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        FacilityCity: "Jahu",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2047,
        Pin: 581337,
        DispatchCenter: "Yellapur_Tatagar_DPP (Karnataka)",
        OriginCenter: "Yellapur_Tatagar_DPP (Karnataka)",
        FacilityCity: "Yellapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2048,
        Pin: 222143,
        DispatchCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        OriginCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        FacilityCity: "Mungra Badshahpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2049,
        Pin: 420421,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2050,
        Pin: 788110,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2051,
        Pin: 713501,
        DispatchCenter: "Nabadwip_Srirmpur_D (West Bengal)",
        OriginCenter: "Nabadwip_Srirmpur_D (West Bengal)",
        FacilityCity: "Nabadwip",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2052,
        Pin: 413210,
        DispatchCenter: "Kurduwadi_Dattanagar_D (Maharashtra)",
        OriginCenter: "Kurduwadi_Dattanagar_D (Maharashtra)",
        FacilityCity: "Kurduwadi",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2053,
        Pin: 768042,
        DispatchCenter: "Nuapada_Dunguripali_DPP (Orissa)",
        OriginCenter: "Nuapada_Dunguripali_DPP (Orissa)",
        FacilityCity: "Nuapada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2054,
        Pin: 723154,
        DispatchCenter: "Bhaddi_Tanashi_DPP (West Bengal)",
        OriginCenter: "Bhaddi_Tanashi_DPP (West Bengal)",
        FacilityCity: "Bhaddi",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2055,
        Pin: 416102,
        DispatchCenter: "Jaysingpur_Swapnanagari_D (Maharashtra)",
        OriginCenter: "Jaysingpur_Swapnanagari_D (Maharashtra)",
        FacilityCity: "Jaysingpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2056,
        Pin: 326517,
        DispatchCenter: "RamganjMandi_AdarshNagar_D (Rajasthan)",
        OriginCenter: "RamganjMandi_AdarshNagar_D (Rajasthan)",
        FacilityCity: "Ramganj Mandi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2057,
        Pin: 533344,
        DispatchCenter: "Bikkavolu_Balabhadhrapuram_D (Andhra Pradesh)",
        OriginCenter: "Bikkavolu_Balabhadhrapuram_D (Andhra Pradesh)",
        FacilityCity: "Bikkavolu",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2058,
        Pin: 571311,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2059,
        Pin: 275304,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2060,
        Pin: 533346,
        DispatchCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2061,
        Pin: 755027,
        DispatchCenter: "Binjharpur_Ragunthapr_D (Orissa)",
        OriginCenter: "Binjharpur_Ragunthapr_D (Orissa)",
        FacilityCity: "Binjharpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2062,
        Pin: 577125,
        DispatchCenter: "Thirthahalli_Melinakuruvali2_DPP (Karnataka)",
        OriginCenter: "Thirthahalli_Melinakuruvali2_DPP (Karnataka)",
        FacilityCity: "Thirthahalli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2063,
        Pin: 756167,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2064,
        Pin: 535004,
        DispatchCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        OriginCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        FacilityCity: "Vizianagaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2065,
        Pin: 208004,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2066,
        Pin: 628006,
        DispatchCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        OriginCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        FacilityCity: "Tuticorin",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2067,
        Pin: 506132,
        DispatchCenter: "Narsampet_PakhalRoad_D (Telangana)",
        OriginCenter: "Narsampet_PakhalRoad_D (Telangana)",
        FacilityCity: "Narsampet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2068,
        Pin: 785104,
        DispatchCenter: "Majuli_Kthnibri_D (Assam)",
        OriginCenter: "Majuli_Kthnibri_D (Assam)",
        FacilityCity: "Majuli",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2069,
        Pin: 630310,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2070,
        Pin: 507163,
        DispatchCenter: "Khammam_Mudigonda_H (Telangana)",
        OriginCenter: "Khammam_Mudigonda_H (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2071,
        Pin: 768019,
        DispatchCenter: "Sambalpur_Tentelpara_H (Orissa)",
        OriginCenter: "Sambalpur_Tentelpara_H (Orissa)",
        FacilityCity: "Sambalpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2072,
        Pin: 721433,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2073,
        Pin: 521150,
        DispatchCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        OriginCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        FacilityCity: "Machilipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2074,
        Pin: 503217,
        DispatchCenter: "Armoor_Mamidipillay_D (Telangana)",
        OriginCenter: "Armoor_Mamidipillay_D (Telangana)",
        FacilityCity: "Armoor",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2075,
        Pin: 577528,
        DispatchCenter: "Jagaluru_Tumatelayout_D (Karnataka)",
        OriginCenter: "Jagaluru_Tumatelayout_D (Karnataka)",
        FacilityCity: "Jagaluru",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2076,
        Pin: 686572,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2077,
        Pin: 493221,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2078,
        Pin: 700124,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2079,
        Pin: 688526,
        DispatchCenter: "Cochin_Aroor_D (Kerala)",
        OriginCenter: "Cochin_Aroor_D (Kerala)",
        FacilityCity: "Ezhupunna",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2080,
        Pin: 610104,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2081,
        Pin: 400005,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2082,
        Pin: 764073,
        DispatchCenter: "Umerkote_Nabarangpur_DPP (Orissa)",
        OriginCenter: "Umerkote_Nabarangpur_DPP (Orissa)",
        FacilityCity: "Umerkote",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2083,
        Pin: 678685,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2084,
        Pin: 201000,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2085,
        Pin: 276135,
        DispatchCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2086,
        Pin: 174004,
        DispatchCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        OriginCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        FacilityCity: "Bilaspur-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2087,
        Pin: 735204,
        DispatchCenter: "Birpara_BirparaClg_DPP (West Bengal)",
        OriginCenter: "Birpara_BirparaClg_DPP (West Bengal)",
        FacilityCity: "Birpara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2088,
        Pin: 673514,
        DispatchCenter: "Thalassery_Iyyamkode_D (Kerala)",
        OriginCenter: "Thalassery_Iyyamkode_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2089,
        Pin: 686143,
        DispatchCenter: "Kottayam_Thalayazham_D (Kerala)",
        OriginCenter: "Kottayam_Thalayazham_D (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2090,
        Pin: 176097,
        DispatchCenter: "Palampur_Saulbanehr_DPP (Himachal Pradesh)",
        OriginCenter: "Palampur_Saulbanehr_DPP (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2091,
        Pin: 673508,
        DispatchCenter: "Kuttiady_ValayannoorMasjid_D (Kerala)",
        OriginCenter: "Kuttiady_ValayannoorMasjid_D (Kerala)",
        FacilityCity: "Kuttiady",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2092,
        Pin: 603306,
        DispatchCenter: "Melmaruvathur_IOBank_D (Tamil Nadu)",
        OriginCenter: "Melmaruvathur_IOBank_D (Tamil Nadu)",
        FacilityCity: "Melmaruvathur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2093,
        Pin: 241402,
        DispatchCenter: "Hardoi_Deorania_DPP (Uttar Pradesh)",
        OriginCenter: "Hardoi_Deorania_DPP (Uttar Pradesh)",
        FacilityCity: "Hardoi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2094,
        Pin: 141005,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2095,
        Pin: 576244,
        DispatchCenter: "Bhatkal_Sodigadde_D (Karnataka)",
        OriginCenter: "Bhatkal_Sodigadde_D (Karnataka)",
        FacilityCity: "Bhatkal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2096,
        Pin: 670674,
        DispatchCenter: "Chavassery_Peravoor_D (Kerala)",
        OriginCenter: "Chavassery_Peravoor_D (Kerala)",
        FacilityCity: "Chavassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2097,
        Pin: 284127,
        DispatchCenter: "Jhansi_Nandanpura_I (Uttar Pradesh)",
        OriginCenter: "Jhansi_Nandanpura_I (Uttar Pradesh)",
        FacilityCity: "Jhansi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2098,
        Pin: 500094,
        DispatchCenter: "Hyderabad_HemaNagar_L (Telangana)",
        OriginCenter: "Hyderabad_HemaNagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2099,
        Pin: 271206,
        DispatchCenter: "Balrampur_Tulsipur_D (Uttar Pradesh)",
        OriginCenter: "Balrampur_Tulsipur_D (Uttar Pradesh)",
        FacilityCity: "Balrampur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2100,
        Pin: 516474,
        DispatchCenter: "Tadipatri_Kondapuram_D (Andhra Pradesh)",
        OriginCenter: "Tadipatri_Kondapuram_D (Andhra Pradesh)",
        FacilityCity: "Tadipatri",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2101,
        Pin: 192125,
        DispatchCenter: "Anantnag_QaziBagh_DPP (Jammu & Kashmir)",
        OriginCenter: "Anantnag_QaziBagh_DPP (Jammu & Kashmir)",
        FacilityCity: "Anantnag",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2102,
        Pin: 761013,
        DispatchCenter: "Digapahandi_Punjikanya_D (Orissa)",
        OriginCenter: "Digapahandi_Punjikanya_D (Orissa)",
        FacilityCity: "Digapahandi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2103,
        Pin: 303314,
        DispatchCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        OriginCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        FacilityCity: "Bandikui",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2104,
        Pin: 606202,
        DispatchCenter: "Kallakurichi_Neelamangalam_D (Tamil Nadu)",
        OriginCenter: "Kallakurichi_Neelamangalam_D (Tamil Nadu)",
        FacilityCity: "Kallakurichi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2105,
        Pin: 852220,
        DispatchCenter: "Udakishanganj_ClgChwk_D (Bihar)",
        OriginCenter: "Udakishanganj_ClgChwk_D (Bihar)",
        FacilityCity: "Udakishanganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2106,
        Pin: 670502,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2107,
        Pin: 283115,
        DispatchCenter: "Kheragarh_Shivmndr_D (Uttar Pradesh)",
        OriginCenter: "Kheragarh_Shivmndr_D (Uttar Pradesh)",
        FacilityCity: "Kheragarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2108,
        Pin: 791119,
        DispatchCenter: "Yazali_MiniStadium_D (Arunachal Pradesh)",
        OriginCenter: "Yazali_MiniStadium_D (Arunachal Pradesh)",
        FacilityCity: "Yazali",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2109,
        Pin: 577121,
        DispatchCenter: "Kalasa_MahaveerRd_DPP (Karnataka)",
        OriginCenter: "Kalasa_MahaveerRd_DPP (Karnataka)",
        FacilityCity: "Kalasa",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2110,
        Pin: 629901,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2111,
        Pin: 814101,
        DispatchCenter: "Dumka_Dudhani_D (Jharkhand)",
        OriginCenter: "Dumka_Dudhani_D (Jharkhand)",
        FacilityCity: "Dumka",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2112,
        Pin: 152125,
        DispatchCenter: "Abohar_DaulatPura_D (Punjab)",
        OriginCenter: "Abohar_DaulatPura_D (Punjab)",
        FacilityCity: "Abohar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2113,
        Pin: 416807,
        DispatchCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        OriginCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        FacilityCity: "Devgad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2114,
        Pin: 577554,
        DispatchCenter: "Channagiri_Holalkere_D (Karnataka)",
        OriginCenter: "Channagiri_Holalkere_D (Karnataka)",
        FacilityCity: "Channagiri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2115,
        Pin: 679340,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2116,
        Pin: 577530,
        DispatchCenter: "Honnali_TMroad_D (Karnataka)",
        OriginCenter: "Honnali_TMroad_D (Karnataka)",
        FacilityCity: "Honnali",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2117,
        Pin: 474005,
        DispatchCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        OriginCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        FacilityCity: "Gwalior",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 2118,
        Pin: 451661,
        DispatchCenter: "Barwani_IGcolony_D (Madhya Pradesh)",
        OriginCenter: "Barwani_IGcolony_D (Madhya Pradesh)",
        FacilityCity: "Barwani",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2119,
        Pin: 785601,
        DispatchCenter: "Sarupathar_Golaght_D (Assam)",
        OriginCenter: "Sarupathar_Golaght_D (Assam)",
        FacilityCity: "Sarupathar",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2120,
        Pin: 639202,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2121,
        Pin: 534461,
        DispatchCenter: "Chintalpudi_KMRd_D (Andhra Pradesh)",
        OriginCenter: "Chintalpudi_KMRd_D (Andhra Pradesh)",
        FacilityCity: "Chintalpudi",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2122,
        Pin: 516267,
        DispatchCenter: "Galiveedu_Masjid_DPP (Andhra Pradesh)",
        OriginCenter: "Galiveedu_Masjid_DPP (Andhra Pradesh)",
        FacilityCity: "Galiveedu",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2123,
        Pin: 246162,
        DispatchCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        OriginCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        FacilityCity: "Gumkhal",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2124,
        Pin: 415528,
        DispatchCenter: "Phaltan_Kolki_D (Maharashtra)",
        OriginCenter: "Phaltan_Kolki_D (Maharashtra)",
        FacilityCity: "Phaltan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2125,
        Pin: 673502,
        DispatchCenter: "Vadakara_Orkkatteri_D (Kerala)",
        OriginCenter: "Vadakara_Orkkatteri_D (Kerala)",
        FacilityCity: "Vadakara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2126,
        Pin: 721129,
        DispatchCenter: "Midnapore_Harishpur_D (West Bengal)",
        OriginCenter: "Midnapore_Harishpur_D (West Bengal)",
        FacilityCity: "Midnapore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2127,
        Pin: 628802,
        DispatchCenter: "Eral_Nazareth_D (Tamil Nadu)",
        OriginCenter: "Eral_Nazareth_D (Tamil Nadu)",
        FacilityCity: "Eral",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2128,
        Pin: 481551,
        DispatchCenter: "Linga_Kurenda_DPP (Madhya Pradesh)",
        OriginCenter: "Linga_Kurenda_DPP (Madhya Pradesh)",
        FacilityCity: "Linga",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 2129,
        Pin: 480557,
        DispatchCenter: "Parasia_TekMohlla_DPP (Madhya Pradesh)",
        OriginCenter: "Parasia_TekMohlla_DPP (Madhya Pradesh)",
        FacilityCity: "Parasia",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2130,
        Pin: 332404,
        DispatchCenter: "Reengus_TegoreViharColony_D (Rajasthan)",
        OriginCenter: "Reengus_TegoreViharColony_D (Rajasthan)",
        FacilityCity: "Reengus",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2131,
        Pin: 712310,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2132,
        Pin: 531173,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2133,
        Pin: 731125,
        DispatchCenter: "Dubrajpur_Mansayer_D (West Bengal)",
        OriginCenter: "Dubrajpur_Mansayer_D (West Bengal)",
        FacilityCity: "Dubrajpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2134,
        Pin: 416314,
        DispatchCenter: "Tasgaon_Trimurticolony_D (Maharashtra)",
        OriginCenter: "Tasgaon_Trimurticolony_D (Maharashtra)",
        FacilityCity: "Tasgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2135,
        Pin: 500960,
        DispatchCenter: "Hyderabad_Medchal1_L (Telangana)",
        OriginCenter: "Hyderabad_Medchal_GW (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2136,
        Pin: 765017,
        DispatchCenter: "Raygada_GandhiNgr_DPP (Orissa)",
        OriginCenter: "Raygada_GandhiNgr_DPP (Orissa)",
        FacilityCity: "Raygada",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2137,
        Pin: 680124,
        DispatchCenter: "Kodungallur_Methala_D (Kerala)",
        OriginCenter: "Kodungallur_Methala_D (Kerala)",
        FacilityCity: "Kodungallur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2138,
        Pin: 587112,
        DispatchCenter: "Ilkal_Ward4_D (Karnataka)",
        OriginCenter: "Ilkal_Ward4_D (Karnataka)",
        FacilityCity: "Ilkal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2139,
        Pin: 311604,
        DispatchCenter: "Mandalgarh_Vakilcolony_D (Rajasthan)",
        OriginCenter: "Mandalgarh_Vakilcolony_D (Rajasthan)",
        FacilityCity: "Mandalgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2140,
        Pin: 534134,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2141,
        Pin: 581450,
        DispatchCenter: "Sirsi_Kasturbanagar_D (Karnataka)",
        OriginCenter: "Sirsi_Kasturbanagar_D (Karnataka)",
        FacilityCity: "Sirsi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2142,
        Pin: 413502,
        DispatchCenter: "Barshi_Indiranagar_D (Maharashtra)",
        OriginCenter: "Barshi_Indiranagar_D (Maharashtra)",
        FacilityCity: "Barshi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2143,
        Pin: 721668,
        DispatchCenter: "Kolaghat_Central_DPP_2 (West Bengal)",
        OriginCenter: "Kolaghat_Central_DPP_2 (West Bengal)",
        FacilityCity: "Kolaghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2144,
        Pin: 607301,
        DispatchCenter: "Cuddalore_FriendsNgr_D (Tamil Nadu)",
        OriginCenter: "Cuddalore_FriendsNgr_D (Tamil Nadu)",
        FacilityCity: "Cuddalore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2145,
        Pin: 524124,
        DispatchCenter: "Sullurupeta_Sainagar_D (Andhra Pradesh)",
        OriginCenter: "Sullurupeta_Sainagar_D (Andhra Pradesh)",
        FacilityCity: "Sullurupeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2146,
        Pin: 413113,
        DispatchCenter: "Akluj_SumitraNgr_D (Maharashtra)",
        OriginCenter: "Akluj_SumitraNgr_D (Maharashtra)",
        FacilityCity: "Akluj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2147,
        Pin: 577588,
        DispatchCenter: "Davangere_Industrialarea_I (Karnataka)",
        OriginCenter: "Davangere_Industrialarea_I (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2148,
        Pin: 384206,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2149,
        Pin: 333032,
        DispatchCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        OriginCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        FacilityCity: "Udaipurwati",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2150,
        Pin: 176089,
        DispatchCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        OriginCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2151,
        Pin: 642203,
        DispatchCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        OriginCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        FacilityCity: "Udumalaipettai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2152,
        Pin: 638456,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2153,
        Pin: 814110,
        DispatchCenter: "Dumka_Dudhani_D (Jharkhand)",
        OriginCenter: "Dumka_Dudhani_D (Jharkhand)",
        FacilityCity: "Dumka",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2154,
        Pin: 141124,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2155,
        Pin: 521183,
        DispatchCenter: "Nandigama_Mukkapatinagar_D (Andhra Pradesh)",
        OriginCenter: "Nandigama_Mukkapatinagar_D (Andhra Pradesh)",
        FacilityCity: "Nandigama",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2156,
        Pin: 825412,
        DispatchCenter: "Rajdhanwar_Mayaramtola_D (Jharkhand)",
        OriginCenter: "Rajdhanwar_Mayaramtola_D (Jharkhand)",
        FacilityCity: "Rajdhanwar",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2157,
        Pin: 504303,
        DispatchCenter: "Mancherial_Nuspur_I (Telangana)",
        OriginCenter: "Mancherial_Nuspur_I (Telangana)",
        FacilityCity: "Mancherial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2158,
        Pin: 756126,
        DispatchCenter: "Bhadrak_Deuli_D (Orissa)",
        OriginCenter: "Bhadrak_Deuli_D (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2159,
        Pin: 612503,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2160,
        Pin: 828134,
        DispatchCenter: "Chandankiyari_ITI_DPP (Jharkhand)",
        OriginCenter: "Chandankiyari_ITI_DPP (Jharkhand)",
        FacilityCity: "chandankiyari",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2161,
        Pin: 734008,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2162,
        Pin: 209745,
        DispatchCenter: "Farrukhabad_Shamsabad_D (Uttar Pradesh)",
        OriginCenter: "Farrukhabad_Shamsabad_D (Uttar Pradesh)",
        FacilityCity: "Farrukhabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2163,
        Pin: 600079,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2164,
        Pin: 563139,
        DispatchCenter: "Malur_AdarshNgr_D (Karnataka)",
        OriginCenter: "Malur_AdarshNgr_D (Karnataka)",
        FacilityCity: "Malur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2165,
        Pin: 757081,
        DispatchCenter: "Bangriposi_Majigan_D (Orissa)",
        OriginCenter: "Bangriposi_Majigan_D (Orissa)",
        FacilityCity: "Bangriposi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2166,
        Pin: 522317,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2167,
        Pin: 122106,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2168,
        Pin: 627152,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2169,
        Pin: 190007,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2170,
        Pin: 271210,
        DispatchCenter: "Balrampur_Tulsipur_D (Uttar Pradesh)",
        OriginCenter: "Balrampur_Tulsipur_D (Uttar Pradesh)",
        FacilityCity: "Balrampur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2171,
        Pin: 679534,
        DispatchCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        OriginCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        FacilityCity: "Sankaramangalam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2172,
        Pin: 736202,
        DispatchCenter: "Barobisha_NatAimDPP_D (West Bengal)",
        OriginCenter: "Barobisha_NatAimDPP_D (West Bengal)",
        FacilityCity: "Barobisha",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2173,
        Pin: 486771,
        DispatchCenter: "Sidhi_Ward11_D (Madhya Pradesh)",
        OriginCenter: "Sidhi_Ward11_D (Madhya Pradesh)",
        FacilityCity: "Sidhi",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2174,
        Pin: 151509,
        DispatchCenter: "TalwandiSabo_GillanWala_D (Punjab)",
        OriginCenter: "TalwandiSabo_GillanWala_D (Punjab)",
        FacilityCity: "Talwandi Sabo",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2175,
        Pin: 743204,
        DispatchCenter: "Barrackpore_Lalkuti_D (West Bengal)",
        OriginCenter: "Barrackpore_Lalkuti_D (West Bengal)",
        FacilityCity: "Barrackpore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2176,
        Pin: 721439,
        DispatchCenter: "Contai_Egra_D (West Bengal)",
        OriginCenter: "Contai_Egra_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2177,
        Pin: 224204,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2178,
        Pin: 509375,
        DispatchCenter: "Achampet_Madhuranagar_D (Telangana)",
        OriginCenter: "Achampet_Madhuranagar_D (Telangana)",
        FacilityCity: "Achampet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2179,
        Pin: 754253,
        DispatchCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        OriginCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2180,
        Pin: 160001,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2181,
        Pin: 207243,
        DispatchCenter: "Ganjdundwara_CanalRd_D (Uttar Pradesh)",
        OriginCenter: "Ganjdundwara_CanalRd_D (Uttar Pradesh)",
        FacilityCity: "Ganjdundwara",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2182,
        Pin: 425502,
        DispatchCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        OriginCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        FacilityCity: "Raver",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2183,
        Pin: 110018,
        DispatchCenter: "Delhi_KirtiNagar_L (Delhi)",
        OriginCenter: "Delhi_KirtiNagar_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2184,
        Pin: 209801,
        DispatchCenter: "Unnao_Shekhpur_D (Uttar Pradesh)",
        OriginCenter: "Unnao_Shekhpur_D (Uttar Pradesh)",
        FacilityCity: "Unnao",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2185,
        Pin: 768049,
        DispatchCenter: "Padampur_Shantinagar_D (Orissa)",
        OriginCenter: "Padampur_Shantinagar_D (Orissa)",
        FacilityCity: "Padampur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2186,
        Pin: 431108,
        DispatchCenter: "Paithan_SantaNGR_D (Maharashtra)",
        OriginCenter: "Paithan_SantaNGR_D (Maharashtra)",
        FacilityCity: "Paithan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2187,
        Pin: 303110,
        DispatchCenter: "Viratnagar_Shyamcolony_D (Rajasthan)",
        OriginCenter: "Viratnagar_Shyamcolony_D (Rajasthan)",
        FacilityCity: "Viratnagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2188,
        Pin: 415602,
        DispatchCenter: "Chiplun_Burumtali_D (Maharashtra)",
        OriginCenter: "Chiplun_Burumtali_D (Maharashtra)",
        FacilityCity: "Chiplun",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2189,
        Pin: 441224,
        DispatchCenter: "Mul_TahsilOfc_DPP (Maharashtra)",
        OriginCenter: "Mul_TahsilOfc_DPP (Maharashtra)",
        FacilityCity: "Mul",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2190,
        Pin: 575029,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2191,
        Pin: 508202,
        DispatchCenter: "Haliya_VBNagar_D (Telangana)",
        OriginCenter: "Haliya_VBNagar_D (Telangana)",
        FacilityCity: "Haliya",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2192,
        Pin: 124103,
        DispatchCenter: "Jhajjar_Policeline_I (Haryana)",
        OriginCenter: "Jhajjar_Policeline_I (Haryana)",
        FacilityCity: "Jhajjar",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2193,
        Pin: 382725,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2194,
        Pin: 394211,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2195,
        Pin: 181102,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2196,
        Pin: 394520,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2197,
        Pin: 642207,
        DispatchCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        OriginCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        FacilityCity: "Udumalaipettai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2198,
        Pin: 673032,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2199,
        Pin: 131302,
        DispatchCenter: "Gohana_Adarshnagar_D (Haryana)",
        OriginCenter: "Gohana_Adarshnagar_D (Haryana)",
        FacilityCity: "Gohana",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2200,
        Pin: 275105,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2201,
        Pin: 689668,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2202,
        Pin: 742304,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2203,
        Pin: 851131,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2204,
        Pin: 829106,
        DispatchCenter: "Patratu_Saundabasti_D (Jharkhand)",
        OriginCenter: "Patratu_Saundabasti_D (Jharkhand)",
        FacilityCity: "Patratu",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2205,
        Pin: 302021,
        DispatchCenter: "Jaipur_RingRoad_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2206,
        Pin: 210204,
        DispatchCenter: "Chitrakoot_Puranibazar_D (Uttar Pradesh)",
        OriginCenter: "Chitrakoot_Puranibazar_D (Uttar Pradesh)",
        FacilityCity: "Chitrakoot",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2207,
        Pin: 628722,
        DispatchCenter: "Vilathikulam_Thoothukudi_DPP (Tamil Nadu)",
        OriginCenter: "Vilathikulam_Thoothukudi_DPP (Tamil Nadu)",
        FacilityCity: "Vilathikulam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2208,
        Pin: 577525,
        DispatchCenter: "Davangere_Santhebennur_D (Karnataka)",
        OriginCenter: "Davangere_Santhebennur_D (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2209,
        Pin: 230305,
        DispatchCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        OriginCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        FacilityCity: "Pratapgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2210,
        Pin: 841501,
        DispatchCenter: "Kuchaikote_Bhathwaparshuram_D (Bihar)",
        OriginCenter: "Kuchaikote_Bhathwaparshuram_D (Bihar)",
        FacilityCity: "Kuchaikote",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2211,
        Pin: 630609,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2212,
        Pin: 636015,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2213,
        Pin: 671125,
        DispatchCenter: "Kasaragod_Kudlu_D (Kerala)",
        OriginCenter: "Kasaragod_Kudlu_D (Kerala)",
        FacilityCity: "Kasaragod",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2214,
        Pin: 125122,
        DispatchCenter: "Fatehabad_Kullanroad_D (Haryana)",
        OriginCenter: "Fatehabad_Kullanroad_D (Haryana)",
        FacilityCity: "Fatehabad",
        FacilityState: "Haryana",
        Zone : "N1"
      },
      {
        SN: 2215,
        Pin: 799285,
        DispatchCenter: "Kamalpur_Manikbhandar_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Kamalpur",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2216,
        Pin: 637103,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2217,
        Pin: 410503,
        DispatchCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        OriginCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        FacilityCity: "Ambegaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2218,
        Pin: 586122,
        DispatchCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        OriginCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        FacilityCity: "Bijapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2219,
        Pin: 403407,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2220,
        Pin: 530010,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2221,
        Pin: 490025,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2222,
        Pin: 391750,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2223,
        Pin: 796441,
        DispatchCenter: "Mamit_FieldVeng_D (Mizoram)",
        OriginCenter: "Mamit_FieldVeng_D (Mizoram)",
        FacilityCity: "Mamit",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2224,
        Pin: 502107,
        DispatchCenter: "Siddipet_Vemula_D (Telangana)",
        OriginCenter: "Siddipet_Vemula_D (Telangana)",
        FacilityCity: "Siddipet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2225,
        Pin: 274305,
        DispatchCenter: "Kaptanganj_Subshngr_D (Uttar Pradesh)",
        OriginCenter: "Kaptanganj_Subshngr_D (Uttar Pradesh)",
        FacilityCity: "Kaptanganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2226,
        Pin: 143521,
        DispatchCenter: "Gurdaspur_Civillines_D (Punjab)",
        OriginCenter: "Gurdaspur_Civillines_D (Punjab)",
        FacilityCity: "Gurdaspur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2227,
        Pin: 125133,
        DispatchCenter: "Ratia_Baslan_D (Haryana)",
        OriginCenter: "Ratia_Baslan_D (Haryana)",
        FacilityCity: "Ratia",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2228,
        Pin: 639107,
        DispatchCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        OriginCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        FacilityCity: "Kulithalai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2229,
        Pin: 503310,
        DispatchCenter: "Yellareddy_Teachercolony_D (Telangana)",
        OriginCenter: "Yellareddy_Teachercolony_D (Telangana)",
        FacilityCity: "Yellareddy",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2230,
        Pin: 132140,
        DispatchCenter: "Panipat_Risalu_P (Haryana)",
        OriginCenter: "Panipat_Risalu_P (Haryana)",
        FacilityCity: "Panipat",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2231,
        Pin: 690106,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2232,
        Pin: 486892,
        DispatchCenter: "Singrauli_Nehrunagar_D (Madhya Pradesh)",
        OriginCenter: "Singrauli_Nehrunagar_D (Madhya Pradesh)",
        FacilityCity: "Singrauli",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2233,
        Pin: 476115,
        DispatchCenter: "Morena_Ambah_D (Madhya Pradesh)",
        OriginCenter: "Morena_Ambah_D (Madhya Pradesh)",
        FacilityCity: "Morena",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2234,
        Pin: 629189,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2235,
        Pin: 632601,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2236,
        Pin: 576105,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2237,
        Pin: 793001,
        DispatchCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        OriginCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2238,
        Pin: 144622,
        DispatchCenter: "Begowal_PinkMrkt_D (Punjab)",
        OriginCenter: "Begowal_PinkMrkt_D (Punjab)",
        FacilityCity: "Begowal",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2239,
        Pin: 691333,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2240,
        Pin: 473338,
        DispatchCenter: "Ashoknagar_Durgacolony_D (Madhya Pradesh)",
        OriginCenter: "Ashoknagar_Durgacolony_D (Madhya Pradesh)",
        FacilityCity: "Ashok Nagar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2241,
        Pin: 534111,
        DispatchCenter: "Nallajerla_Anntpalli_D (Andhra Pradesh)",
        OriginCenter: "Nallajerla_Anntpalli_D (Andhra Pradesh)",
        FacilityCity: "Nallajerla",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2242,
        Pin: 273302,
        DispatchCenter: "Maharajganj_Shikarpur_D (Uttar Pradesh)",
        OriginCenter: "Maharajganj_Shikarpur_D (Uttar Pradesh)",
        FacilityCity: "Maharajganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2243,
        Pin: 370105,
        DispatchCenter: "Bhuj_Madhapar_D (Gujarat)",
        OriginCenter: "Bhuj_Madhapar_D (Gujarat)",
        FacilityCity: "Bhuj",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2244,
        Pin: 623524,
        DispatchCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        OriginCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        FacilityCity: "Rameswaram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2245,
        Pin: 132106,
        DispatchCenter: "Panipat_Risalu_P (Haryana)",
        OriginCenter: "Panipat_Risalu_P (Haryana)",
        FacilityCity: "Panipat",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2246,
        Pin: 531075,
        DispatchCenter: "Chodavaram_Narasayyapeta_D (Andhra Pradesh)",
        OriginCenter: "Chodavaram_Narasayyapeta_D (Andhra Pradesh)",
        FacilityCity: "Chodavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2247,
        Pin: 825326,
        DispatchCenter: "Kedla_Ghatotand_D (Jharkhand)",
        OriginCenter: "Kedla_Ghatotand_D (Jharkhand)",
        FacilityCity: "Kedla",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2248,
        Pin: 176093,
        DispatchCenter: "Dheera_Purba_DPP (Himachal Pradesh)",
        OriginCenter: "Dheera_Purba_DPP (Himachal Pradesh)",
        FacilityCity: "Dheera",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2249,
        Pin: 754208,
        DispatchCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        OriginCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2250,
        Pin: 518347,
        DispatchCenter: "Adoni_Mandagiri_D (Andhra Pradesh)",
        OriginCenter: "Adoni_Mandagiri_D (Andhra Pradesh)",
        FacilityCity: "Adoni",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2251,
        Pin: 331023,
        DispatchCenter: "Rajgarh_Taranagar_D (Rajasthan)",
        OriginCenter: "Rajgarh_Taranagar_D (Rajasthan)",
        FacilityCity: "Rajgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2252,
        Pin: 689665,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2253,
        Pin: 754032,
        DispatchCenter: "Narasinghpur_Paikapada_DPP (Orissa)",
        OriginCenter: "Narasinghpur_Paikapada_DPP (Orissa)",
        FacilityCity: "Narasinghpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2254,
        Pin: 794115,
        DispatchCenter: "Garobadha_Ampati_DPP (Meghalaya)",
        OriginCenter: "Garobadha_Ampati_DPP (Meghalaya)",
        FacilityCity: "Garobadha",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2255,
        Pin: 572104,
        DispatchCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        OriginCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2256,
        Pin: 486001,
        DispatchCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        OriginCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        FacilityCity: "Rewa",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 2257,
        Pin: 742167,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2258,
        Pin: 695512,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2259,
        Pin: 394352,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2260,
        Pin: 415104,
        DispatchCenter: "Karad_Malkapur1_D (Maharashtra)",
        OriginCenter: "Karad_Malkapur1_D (Maharashtra)",
        FacilityCity: "Karad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2261,
        Pin: 361001,
        DispatchCenter: "Jamnagar_Chela_L (Gujarat)",
        OriginCenter: "Jamnagar_Chela_L (Gujarat)",
        FacilityCity: "Jamnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2262,
        Pin: 517403,
        DispatchCenter: "Chittoor_Murukambattu_D (Andhra Pradesh)",
        OriginCenter: "Chittoor_Murukambattu_D (Andhra Pradesh)",
        FacilityCity: "Chittoor",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2263,
        Pin: 695527,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2264,
        Pin: 625534,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2265,
        Pin: 689680,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2266,
        Pin: 628216,
        DispatchCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        OriginCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        FacilityCity: "Tiruchendur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2267,
        Pin: 110042,
        DispatchCenter: "Delhi_Libaspur_L (Delhi)",
        OriginCenter: "Delhi_Libaspur_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2268,
        Pin: 751024,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2269,
        Pin: 275101,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2270,
        Pin: 202134,
        DispatchCenter: "Kasganj_GandhiKunj_D (Uttar Pradesh)",
        OriginCenter: "Kasganj_GandhiKunj_D (Uttar Pradesh)",
        FacilityCity: "Kasganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2271,
        Pin: 515561,
        DispatchCenter: "Kadiri_NagireddyPalli_D (Andhra Pradesh)",
        OriginCenter: "Kadiri_NagireddyPalli_D (Andhra Pradesh)",
        FacilityCity: "Kadiri",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2272,
        Pin: 523262,
        DispatchCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        OriginCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        FacilityCity: "Ongole",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2273,
        Pin: 312623,
        DispatchCenter: "PratapgarhRJ_Badabag_DPP (Rajasthan)",
        OriginCenter: "PratapgarhRJ_Badabag_DPP (Rajasthan)",
        FacilityCity: "Pratapgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2274,
        Pin: 803306,
        DispatchCenter: "Sheonor_Moldiyar_D (Bihar)",
        OriginCenter: "Sheonor_Moldiyar_D (Bihar)",
        FacilityCity: "Sheonar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2275,
        Pin: 143530,
        DispatchCenter: "Gurdaspur_Civillines_D (Punjab)",
        OriginCenter: "Gurdaspur_Civillines_D (Punjab)",
        FacilityCity: "Gurdaspur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2276,
        Pin: 212653,
        DispatchCenter: "Khaga_GTRoad_D (Uttar Pradesh)",
        OriginCenter: "Khaga_GTRoad_D (Uttar Pradesh)",
        FacilityCity: "Khaga",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2277,
        Pin: 632514,
        DispatchCenter: "Sholinghur_Ramkrishnarajapet_D (Tamil Nadu)",
        OriginCenter: "Sholinghur_Ramkrishnarajapet_D (Tamil Nadu)",
        FacilityCity: "Sholinghur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2278,
        Pin: 395011,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2279,
        Pin: 781150,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2280,
        Pin: 276406,
        DispatchCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2281,
        Pin: 263643,
        DispatchCenter: "Almora_Makedi_D (Uttarakhand)",
        OriginCenter: "Almora_Makedi_D (Uttarakhand)",
        FacilityCity: "Almora",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2282,
        Pin: 700110,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2283,
        Pin: 852215,
        DispatchCenter: "Simrahi_Bazar_D (Bihar)",
        OriginCenter: "Simrahi_Bazar_D (Bihar)",
        FacilityCity: "Simrahi Bazar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2284,
        Pin: 313038,
        DispatchCenter: "Salumbar_Khetawatwadi_DPP (Rajasthan)",
        OriginCenter: "Salumbar_Khetawatwadi_DPP (Rajasthan)",
        FacilityCity: "Salumbar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2285,
        Pin: 314027,
        DispatchCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        OriginCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        FacilityCity: "Sagwara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2286,
        Pin: 531033,
        DispatchCenter: "Anakapalle_Thumapala_D (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Anakapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2287,
        Pin: 421306,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2288,
        Pin: 431203,
        DispatchCenter: "Jalna_Yashodipnagar_D (Maharashtra)",
        OriginCenter: "Jalna_Yashodipnagar_D (Maharashtra)",
        FacilityCity: "Jalna",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2289,
        Pin: 400031,
        DispatchCenter: "Mumbai_Sewri_L (Maharashtra)",
        OriginCenter: "Mumbai_Sewri_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2290,
        Pin: 711204,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2291,
        Pin: 560076,
        DispatchCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        OriginCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2292,
        Pin: 734100,
        DispatchCenter: "Darjeeling_Limbugaon_D (West Bengal)",
        OriginCenter: "Darjeeling_Limbugaon_D (West Bengal)",
        FacilityCity: "Darjeeling",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2293,
        Pin: 277306,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2294,
        Pin: 626565,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2295,
        Pin: 171005,
        DispatchCenter: "Shimla_Tara_DC (Himachal Pradesh)",
        OriginCenter: "Shimla_Tara_DC (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2296,
        Pin: 301423,
        DispatchCenter: "Bhiwadi_Central_D_2 (Rajasthan)",
        OriginCenter: "Bhiwadi_Central_D_2 (Rajasthan)",
        FacilityCity: "Bhiwadi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2297,
        Pin: 690510,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2298,
        Pin: 393151,
        DispatchCenter: "Rajpipla_DSPOffice_DPP (Gujarat)",
        OriginCenter: "Rajpipla_DSPOffice_DPP (Gujarat)",
        FacilityCity: "Rajpipla",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2299,
        Pin: 534218,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2300,
        Pin: 173212,
        DispatchCenter: "Solan_Deothi_I (Himachal Pradesh)",
        OriginCenter: "Solan_Deothi_I (Himachal Pradesh)",
        FacilityCity: "Solan",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2301,
        Pin: 533232,
        DispatchCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2302,
        Pin: 631302,
        DispatchCenter: "Sholinghur_Pulanji_D (Tamil Nadu)",
        OriginCenter: "Sholinghur_Pulanji_D (Tamil Nadu)",
        FacilityCity: "Sholinghur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2303,
        Pin: 212206,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2304,
        Pin: 482056,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2305,
        Pin: 755037,
        DispatchCenter: "Dhamanagar_Temple_DPP (Orissa)",
        OriginCenter: "Dhamanagar_Temple_DPP (Orissa)",
        FacilityCity: "Dhamanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2306,
        Pin: 795150,
        DispatchCenter: "Tadubi_DohuColony_D (Manipur)",
        OriginCenter: "Tadubi_DohuColony_D (Manipur)",
        FacilityCity: "Tadubi",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2307,
        Pin: 312401,
        DispatchCenter: "Mandphiya_Kanore_DPP (Rajasthan)",
        OriginCenter: "Mandphiya_Kanore_DPP (Rajasthan)",
        FacilityCity: "Mandphiya",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2308,
        Pin: 609101,
        DispatchCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        OriginCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        FacilityCity: "Sirkazhi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2309,
        Pin: 431002,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2310,
        Pin: 422303,
        DispatchCenter: "Lasalgaon_TelephnExch_D (Maharashtra)",
        OriginCenter: "Lasalgaon_TelephnExch_D (Maharashtra)",
        FacilityCity: "Lasalgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2311,
        Pin: 135102,
        DispatchCenter: "Yamunanagar_Balachaur_D (Haryana)",
        OriginCenter: "Yamunanagar_Balachaur_D (Haryana)",
        FacilityCity: "Yamuna Nagar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2312,
        Pin: 801102,
        DispatchCenter: "Bihta_Naubatpur_D (Bihar)",
        OriginCenter: "Bihta_Naubatpur_D (Bihar)",
        FacilityCity: "Bihta",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2313,
        Pin: 232112,
        DispatchCenter: "ChakiaUP_Ward5_D (Uttar Pradesh)",
        OriginCenter: "ChakiaUP_Ward5_D (Uttar Pradesh)",
        FacilityCity: "ChakiaUP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2314,
        Pin: 721151,
        DispatchCenter: "Kolaghat_GopalNgr_DPP (West Bengal)",
        OriginCenter: "Kolaghat_GopalNgr_DPP (West Bengal)",
        FacilityCity: "Kolaghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2315,
        Pin: 695011,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2316,
        Pin: 509327,
        DispatchCenter: "Amangal_Adarshnagar_D (Telangana)",
        OriginCenter: "Amangal_Adarshnagar_D (Telangana)",
        FacilityCity: "Amangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2317,
        Pin: 412101,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2318,
        Pin: 306114,
        DispatchCenter: "Sojat_RendriCircle_DPP (Rajasthan)",
        OriginCenter: "Sojat_RendriCircle_DPP (Rajasthan)",
        FacilityCity: "Sojat",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2319,
        Pin: 751031,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2320,
        Pin: 630306,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2321,
        Pin: 177201,
        DispatchCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        OriginCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        FacilityCity: "Amb",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2322,
        Pin: 416316,
        DispatchCenter: "Ashta_GordeChwk_D (Maharashtra)",
        OriginCenter: "Ashta_GordeChwk_D (Maharashtra)",
        FacilityCity: "AshtaSangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2323,
        Pin: 383110,
        DispatchCenter: "Idar_GIDC_D (Gujarat)",
        OriginCenter: "Idar_GIDC_D (Gujarat)",
        FacilityCity: "Idar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2324,
        Pin: 591233,
        DispatchCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        OriginCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        FacilityCity: "Gokak",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2325,
        Pin: 691010,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2326,
        Pin: 811315,
        DispatchCenter: "Lakhisarai_Central_I_2 (Bihar)",
        OriginCenter: "Lakhisarai_Central_I_2 (Bihar)",
        FacilityCity: "Lakhisarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2327,
        Pin: 679553,
        DispatchCenter: "Edappal_Nellissery_D (Kerala)",
        OriginCenter: "Edappal_Nellissery_D (Kerala)",
        FacilityCity: "Edappal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2328,
        Pin: 422012,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2329,
        Pin: 160073,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2330,
        Pin: 414107,
        DispatchCenter: "Shrigonda_Adhalgaon_D (Maharashtra)",
        OriginCenter: "Shrigonda_Adhalgaon_D (Maharashtra)",
        FacilityCity: "Shrigonda",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2331,
        Pin: 495224,
        DispatchCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        OriginCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        FacilityCity: "Bilaspur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2332,
        Pin: 176108,
        DispatchCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        OriginCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2333,
        Pin: 224127,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2334,
        Pin: 607106,
        DispatchCenter: "Panruti_Thiruvathigai_D (Tamil Nadu)",
        OriginCenter: "Panruti_Thiruvathigai_D (Tamil Nadu)",
        FacilityCity: "Panruti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2335,
        Pin: 413726,
        DispatchCenter: "Shrigonda_Adhalgaon_D (Maharashtra)",
        OriginCenter: "Shrigonda_Adhalgaon_D (Maharashtra)",
        FacilityCity: "Shrigonda",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2336,
        Pin: 721514,
        DispatchCenter: "Jhargram_Raghunathpur_DPP (West Bengal)",
        OriginCenter: "Jhargram_Raghunathpur_DPP (West Bengal)",
        FacilityCity: "Jhargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2337,
        Pin: 110100,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2338,
        Pin: 782427,
        DispatchCenter: "Nagaon_Bangthai_H (Assam)",
        OriginCenter: "Nagaon_Bangthai_H (Assam)",
        FacilityCity: "Nagaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2339,
        Pin: 122102,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2340,
        Pin: 670562,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2341,
        Pin: 431130,
        DispatchCenter: "Georai_Jayakwadicolony_D (Maharashtra)",
        OriginCenter: "Georai_Jayakwadicolony_D (Maharashtra)",
        FacilityCity: "Georai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2342,
        Pin: 846006,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2343,
        Pin: 494776,
        DispatchCenter: "Pakhanjur_ForestColony_D (Chhattisgarh)",
        OriginCenter: "Pakhanjur_ForestColony_D (Chhattisgarh)",
        FacilityCity: "Pakhanjur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2344,
        Pin: 573122,
        DispatchCenter: "Arsikere_JenukalTemple_D (Karnataka)",
        OriginCenter: "Arsikere_JenukalTemple_D (Karnataka)",
        FacilityCity: "Arsikere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2345,
        Pin: 177401,
        DispatchCenter: "Bhota_BpassDPP_D (Himachal Pradesh)",
        OriginCenter: "Bhota_BpassDPP_D (Himachal Pradesh)",
        FacilityCity: "Bhota",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2346,
        Pin: 423301,
        DispatchCenter: "Satana_MitraNagar_D (Maharashtra)",
        OriginCenter: "Satana_MitraNagar_D (Maharashtra)",
        FacilityCity: "Satana",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2347,
        Pin: 500014,
        DispatchCenter: "Hyderabad_Medchal1_L (Telangana)",
        OriginCenter: "Hyderabad_Medchal_GW (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2348,
        Pin: 384212,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2349,
        Pin: 518411,
        DispatchCenter: "Nandikotkur_Nandyalroad_D (Andhra Pradesh)",
        OriginCenter: "Nandikotkur_Nandyalroad_D (Andhra Pradesh)",
        FacilityCity: "Nandikotkur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2350,
        Pin: 181132,
        DispatchCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2351,
        Pin: 517536,
        DispatchCenter: "Sriklahsti_SDKNgr_D (Andhra Pradesh)",
        OriginCenter: "Sriklahsti_SDKNgr_D (Andhra Pradesh)",
        FacilityCity: "Srikalahasti",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2352,
        Pin: 847120,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2353,
        Pin: 845459,
        DispatchCenter: "Bettiah_Manshatola_D (Bihar)",
        OriginCenter: "Bettiah_Manshatola_D (Bihar)",
        FacilityCity: "Bettiah",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2354,
        Pin: 621305,
        DispatchCenter: "Manapparai_AmmanNagar_D (Tamil Nadu)",
        OriginCenter: "Manapparai_AmmanNagar_D (Tamil Nadu)",
        FacilityCity: "Manapparai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2355,
        Pin: 262305,
        DispatchCenter: "Pilibhit_AvasVikas_D (Uttar Pradesh)",
        OriginCenter: "Pilibhit_AvasVikas_D (Uttar Pradesh)",
        FacilityCity: "Pilibhit",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2356,
        Pin: 522439,
        DispatchCenter: "Macherla_Ward15_D (Andhra Pradesh)",
        OriginCenter: "Macherla_Ward15_D (Andhra Pradesh)",
        FacilityCity: "Macherla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2357,
        Pin: 630309,
        DispatchCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2358,
        Pin: 382014,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2359,
        Pin: 333053,
        DispatchCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        OriginCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        FacilityCity: "Udaipurwati",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2360,
        Pin: 741126,
        DispatchCenter: "Nakashipara_Kanthalbaria_DPP (West Bengal)",
        OriginCenter: "Nakashipara_Kanthalbaria_DPP (West Bengal)",
        FacilityCity: "Nakashipara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2361,
        Pin: 524203,
        DispatchCenter: "Kavali_Musunur_D (Andhra Pradesh)",
        OriginCenter: "Kavali_Musunur_D (Andhra Pradesh)",
        FacilityCity: "Kavali",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2362,
        Pin: 734201,
        DispatchCenter: "Bijanbari_Pulbazar2_DPP (West Bengal)",
        OriginCenter: "Bijanbari_Pulbazar2_DPP (West Bengal)",
        FacilityCity: "Bijanbari",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2363,
        Pin: 591220,
        DispatchCenter: "Raibag_Sainagar_DPP (Karnataka)",
        OriginCenter: "Raibag_Sainagar_DPP (Karnataka)",
        FacilityCity: "Raibag",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2364,
        Pin: 416605,
        DispatchCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        OriginCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        FacilityCity: "Malvan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2365,
        Pin: 445110,
        DispatchCenter: "Digras_Darwa_D (Maharashtra)",
        OriginCenter: "Digras_Darwa_D (Maharashtra)",
        FacilityCity: "Digras",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2366,
        Pin: 517352,
        DispatchCenter: "Madanapalle_RNaiduClny_D (Andhra Pradesh)",
        OriginCenter: "Madanapalle_RNaiduClny_D (Andhra Pradesh)",
        FacilityCity: "Madanapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2367,
        Pin: 700054,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2368,
        Pin: 497235,
        DispatchCenter: "Bhatgaon_Bazarpara_D (Chhattisgarh)",
        OriginCenter: "Bhatgaon_Bazarpara_D (Chhattisgarh)",
        FacilityCity: "Bhatgaon",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2369,
        Pin: 781316,
        DispatchCenter: "Barpeta_Bhera_D (Assam)",
        OriginCenter: "Barpeta_ShaktiNagar_C (Assam)",
        FacilityCity: "Barpeta",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2370,
        Pin: 671541,
        DispatchCenter: "Poinachi_Kuttikol_D (Kerala)",
        OriginCenter: "Poinachi_Kuttikol_D (Kerala)",
        FacilityCity: "Kuttikol",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2371,
        Pin: 641032,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2372,
        Pin: 462100,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2373,
        Pin: 531082,
        DispatchCenter: "Koruprolu_Thimmapuram_D (Andhra Pradesh)",
        OriginCenter: "Koruprolu_Thimmapuram_D (Andhra Pradesh)",
        FacilityCity: "Koruprolu",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2374,
        Pin: 431104,
        DispatchCenter: "Kannad_Pishore1_DPP (Maharashtra)",
        OriginCenter: "Kannad_Pishore1_DPP (Maharashtra)",
        FacilityCity: "Kannad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2375,
        Pin: 125002,
        DispatchCenter: "Hisar_Dabra_H (Haryana)",
        OriginCenter: "Hisar_Dabra_H (Haryana)",
        FacilityCity: "Hisar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2376,
        Pin: 392215,
        DispatchCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        OriginCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        FacilityCity: "Jambusar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2377,
        Pin: 535215,
        DispatchCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        OriginCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        FacilityCity: "Vizianagaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2378,
        Pin: 509216,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2379,
        Pin: 451001,
        DispatchCenter: "Khargone_NutanNgr_D (Madhya Pradesh)",
        OriginCenter: "Khargone_NutanNgr_D (Madhya Pradesh)",
        FacilityCity: "Khargone",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2380,
        Pin: 623529,
        DispatchCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        OriginCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        FacilityCity: "Rameswaram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2381,
        Pin: 394635,
        DispatchCenter: "Songadh_Vyara_D (Gujarat)",
        OriginCenter: "Songadh_Vyara_D (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2382,
        Pin: 271310,
        DispatchCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        OriginCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        FacilityCity: "Gonda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2383,
        Pin: 682040,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2384,
        Pin: 689521,
        DispatchCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        OriginCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2385,
        Pin: 851120,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2386,
        Pin: 689551,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2387,
        Pin: 444801,
        DispatchCenter: "Amravati_Changapur_D (Maharashtra)",
        OriginCenter: "Amravati_Changapur_D (Maharashtra)",
        FacilityCity: "Amravati",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2388,
        Pin: 767062,
        DispatchCenter: "Birmaharajpur_Sandha_D (Orissa)",
        OriginCenter: "Birmaharajpur_Sandha_D (Orissa)",
        FacilityCity: "Birmaharajpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2389,
        Pin: 756040,
        DispatchCenter: "RajBerhampur_Gohira_DPP (Orissa)",
        OriginCenter: "RajBerhampur_Gohira_DPP (Orissa)",
        FacilityCity: "Raj Berhampur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2390,
        Pin: 680653,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2391,
        Pin: 643102,
        DispatchCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        OriginCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2392,
        Pin: 691003,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2393,
        Pin: 384001,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2394,
        Pin: 415402,
        DispatchCenter: "Shirala_PostOffice_DPP (Maharashtra)",
        OriginCenter: "Shirala_PostOffice_DPP (Maharashtra)",
        FacilityCity: "Shirala",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2395,
        Pin: 305207,
        DispatchCenter: "Beawar_Peesangan_DPP (Rajasthan)",
        OriginCenter: "Beawar_Peesangan_DPP (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2396,
        Pin: 689632,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2397,
        Pin: 180009,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2398,
        Pin: 841508,
        DispatchCenter: "Bhore_Bank_D (Bihar)",
        OriginCenter: "Bhore_Bank_D (Bihar)",
        FacilityCity: "Bhore",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2399,
        Pin: 701102,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2400,
        Pin: 609306,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2401,
        Pin: 788806,
        DispatchCenter: "Karimganj_Gorkapan_D (Assam)",
        OriginCenter: "Karimganj_Gorkapan_D (Assam)",
        FacilityCity: "Karimganj",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2402,
        Pin: 624615,
        DispatchCenter: "Palani_Sathyanagar_D (Tamil Nadu)",
        OriginCenter: "Palani_Sathyanagar_D (Tamil Nadu)",
        FacilityCity: "Palani",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2403,
        Pin: 410107,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2404,
        Pin: 403004,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2405,
        Pin: 413524,
        DispatchCenter: "Nilanga_Panchalcolony_D (Maharashtra)",
        OriginCenter: "Nilanga_Panchalcolony_D (Maharashtra)",
        FacilityCity: "Nilanga",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2406,
        Pin: 160103,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2407,
        Pin: 389310,
        DispatchCenter: "Halol_DhavalPark_D (Gujarat)",
        OriginCenter: "Halol_DhavalPark_D (Gujarat)",
        FacilityCity: "Halol",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2408,
        Pin: 628219,
        DispatchCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        OriginCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        FacilityCity: "Tiruchendur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2409,
        Pin: 641666,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2410,
        Pin: 805101,
        DispatchCenter: "Pakribarwan_Sarauni_DPP (Bihar)",
        OriginCenter: "Pakribarwan_Sarauni_DPP (Bihar)",
        FacilityCity: "Pakribarwan",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2411,
        Pin: 848301,
        DispatchCenter: "KalyanpurBR_Ladaura_D (Bihar)",
        OriginCenter: "KalyanpurBR_Ladaura_D (Bihar)",
        FacilityCity: "KalyanpurBR",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2412,
        Pin: 146115,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2413,
        Pin: 576106,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2414,
        Pin: 782429,
        DispatchCenter: "Hojai_KrishnaNagar_D (Assam)",
        OriginCenter: "Hojai_KrishnaNagar_D (Assam)",
        FacilityCity: "Hojai",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2415,
        Pin: 534341,
        DispatchCenter: "Gopalapuram_Koyyalgudem_D (Andhra Pradesh)",
        OriginCenter: "Gopalapuram_Koyyalgudem_D (Andhra Pradesh)",
        FacilityCity: "Gopalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2416,
        Pin: 488051,
        DispatchCenter: "Pawai_Gunaur_DPP (Madhya Pradesh)",
        OriginCenter: "Pawai_Gunaur_DPP (Madhya Pradesh)",
        FacilityCity: "Pawai",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 2417,
        Pin: 824115,
        DispatchCenter: "Daudnagar_Tarar_D (Bihar)",
        OriginCenter: "Daudnagar_Tarar_D (Bihar)",
        FacilityCity: "Daudnagar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2418,
        Pin: 176305,
        DispatchCenter: "Dalhousie_Banikhet_D (Himachal Pradesh)",
        OriginCenter: "Dalhousie_Banikhet_D (Himachal Pradesh)",
        FacilityCity: "Dalhousie",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2419,
        Pin: 695586,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2420,
        Pin: 180012,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2421,
        Pin: 160061,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2422,
        Pin: 125104,
        DispatchCenter: "Mandidabwali_Jawharnagar_D (Haryana)",
        OriginCenter: "Mandidabwali_Jawharnagar_D (Haryana)",
        FacilityCity: "Mandi Dabwali",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2423,
        Pin: 854115,
        DispatchCenter: "Katihar_SBIGuruBazar_D (Bihar)",
        OriginCenter: "Katihar_SBIGuruBazar_D (Bihar)",
        FacilityCity: "Katihar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2424,
        Pin: 274303,
        DispatchCenter: "Padrauna_BauliChwk_D (Uttar Pradesh)",
        OriginCenter: "Padrauna_BauliChwk_D (Uttar Pradesh)",
        FacilityCity: "Padrauna",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2425,
        Pin: 581423,
        DispatchCenter: "Honnavar_ForestColony_D (Karnataka)",
        OriginCenter: "Honnavar_ForestColony_D (Karnataka)",
        FacilityCity: "Honnavar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2426,
        Pin: 734222,
        DispatchCenter: "TistaBazar_TeestaBridge_DPP (West Bengal)",
        OriginCenter: "TistaBazar_TeestaBridge_DPP (West Bengal)",
        FacilityCity: "Tista Bazar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2427,
        Pin: 456003,
        DispatchCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        OriginCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        FacilityCity: "Ujjain",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 2428,
        Pin: 581351,
        DispatchCenter: "Kumta_Central_DPP_2 (Karnataka)",
        OriginCenter: "Kumta_Central_DPP_2 (Karnataka)",
        FacilityCity: "Kumta",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2429,
        Pin: 522504,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2430,
        Pin: 208021,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2431,
        Pin: 533260,
        DispatchCenter: "Draksharamam_Anaipeta_D (Andhra Pradesh)",
        OriginCenter: "Draksharamam_Anaipeta_D (Andhra Pradesh)",
        FacilityCity: "Draksharamam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2432,
        Pin: 679513,
        DispatchCenter: "Thachnttukra_Mangadn_D (Kerala)",
        OriginCenter: "Thachnttukra_Mangadn_D (Kerala)",
        FacilityCity: "Thachanattukara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2433,
        Pin: 273409,
        DispatchCenter: "Sikriganj_Sikaridihkhurd_D (Uttar Pradesh)",
        OriginCenter: "Sikriganj_Sikaridihkhurd_D (Uttar Pradesh)",
        FacilityCity: "Sikriganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2434,
        Pin: 581187,
        DispatchCenter: "Mallapur_ShtDRDPP_D (Karnataka)",
        OriginCenter: "Mallapur_ShtDRDPP_D (Karnataka)",
        FacilityCity: "Mallapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2435,
        Pin: 415308,
        DispatchCenter: "Atapadi_VidyaNagar_D (Maharashtra)",
        OriginCenter: "Atapadi_VidyaNagar_D (Maharashtra)",
        FacilityCity: "Atapadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2436,
        Pin: 783132,
        DispatchCenter: "Lakhipur_Ward2_D (Assam)",
        OriginCenter: "Lakhipur_Ward2_D (Assam)",
        FacilityCity: "Lakhipur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2437,
        Pin: 831011,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2438,
        Pin: 531025,
        DispatchCenter: "Chodavaram_Narasayyapeta_D (Andhra Pradesh)",
        OriginCenter: "Chodavaram_Narasayyapeta_D (Andhra Pradesh)",
        FacilityCity: "Chodavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2439,
        Pin: 623314,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2440,
        Pin: 638102,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2441,
        Pin: 477227,
        DispatchCenter: "Bhind_Mehgaon_D (Madhya Pradesh)",
        OriginCenter: "Bhind_Mehgaon_D (Madhya Pradesh)",
        FacilityCity: "Bhind",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 2442,
        Pin: 813107,
        DispatchCenter: "Punsia_Hansdiha_D (Bihar)",
        OriginCenter: "Punsia_Hansdiha_D (Bihar)",
        FacilityCity: "Punsia",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2443,
        Pin: 695021,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2444,
        Pin: 534196,
        DispatchCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        OriginCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        FacilityCity: "Tadepalligudem",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2445,
        Pin: 322219,
        DispatchCenter: "Karauli_Agrawalnagar_D (Rajasthan)",
        OriginCenter: "Karauli_Agrawalnagar_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2446,
        Pin: 492014,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2447,
        Pin: 700138,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2448,
        Pin: 766110,
        DispatchCenter: "MRampur_RisidaRd_D (Orissa)",
        OriginCenter: "MRampur_RisidaRd_D (Orissa)",
        FacilityCity: "M Rampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2449,
        Pin: 522237,
        DispatchCenter: "Tadikonda_Central_D_1 (Andhra Pradesh)",
        OriginCenter: "Tadikonda_Central_D_1 (Andhra Pradesh)",
        FacilityCity: "Tadikonda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2450,
        Pin: 623804,
        DispatchCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        OriginCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        FacilityCity: "Rameswaram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2451,
        Pin: 743425,
        DispatchCenter: "Minakhan_Amadpur_DPP (West Bengal)",
        OriginCenter: "Minakhan_Amadpur_DPP (West Bengal)",
        FacilityCity: "Minakhan",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2452,
        Pin: 190024,
        DispatchCenter: "Srinagar_Lalbazar_DPP (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Lalbazar_DPP (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2453,
        Pin: 626540,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2454,
        Pin: 686011,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2455,
        Pin: 563135,
        DispatchCenter: "Srinivaspur_KolarRD_D (Karnataka)",
        OriginCenter: "Srinivaspur_KolarRD_D (Karnataka)",
        FacilityCity: "Srinivaspur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2456,
        Pin: 627724,
        DispatchCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        OriginCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        FacilityCity: "Sankarankovil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2457,
        Pin: 144522,
        DispatchCenter: "Rupnagar_Towercolony_D (Punjab)",
        OriginCenter: "Rupnagar_Towercolony_D (Punjab)",
        FacilityCity: "Rupnagar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2458,
        Pin: 486675,
        DispatchCenter: "Amiliya_Ward15_D (Madhya Pradesh)",
        OriginCenter: "Amiliya_Ward15_D (Madhya Pradesh)",
        FacilityCity: "Amiliya",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 2459,
        Pin: 743332,
        DispatchCenter: "Fatepur_Majlishpur_D (West Bengal)",
        OriginCenter: "Fatepur_Majlishpur_D (West Bengal)",
        FacilityCity: "Fatepur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2460,
        Pin: 691500,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2461,
        Pin: 131024,
        DispatchCenter: "Sonipat_Sector51_L (Haryana)",
        OriginCenter: "Sonipat_WazidpurSaboli_P (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2462,
        Pin: 431715,
        DispatchCenter: "Mukhed_Mukramabad_DPP (Maharashtra)",
        OriginCenter: "Mukhed_Mukramabad_DPP (Maharashtra)",
        FacilityCity: "Mukhed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2463,
        Pin: 362135,
        DispatchCenter: "Talala_SsnRdDPP_D (Gujarat)",
        OriginCenter: "Talala_SsnRdDPP_D (Gujarat)",
        FacilityCity: "Talala",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2464,
        Pin: 431705,
        DispatchCenter: "Hingoli_Tapdiyanagar_D (Maharashtra)",
        OriginCenter: "Hingoli_Tapdiyanagar_D (Maharashtra)",
        FacilityCity: "Hingoli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2465,
        Pin: 302014,
        DispatchCenter: "Jaipur_Ramchandpura_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2466,
        Pin: 627502,
        DispatchCenter: "Tirunelveli_Pannankulam_D (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Pannankulam_D (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2467,
        Pin: 632518,
        DispatchCenter: "Arani_PolurRd_D (Tamil Nadu)",
        OriginCenter: "Arani_PolurRd_D (Tamil Nadu)",
        FacilityCity: "Arani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2468,
        Pin: 852114,
        DispatchCenter: "Madhepura_Azadnagar_D (Bihar)",
        OriginCenter: "Madhepura_Azadnagar_D (Bihar)",
        FacilityCity: "Madhepura",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2469,
        Pin: 231220,
        DispatchCenter: "Anpara_Parasi_D (Uttar Pradesh)",
        OriginCenter: "Anpara_Parasi_D (Uttar Pradesh)",
        FacilityCity: "Anpara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2470,
        Pin: 500457,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2471,
        Pin: 184144,
        DispatchCenter: "Hiranagar_NH44_D (Jammu & Kashmir)",
        OriginCenter: "Hiranagar_NH44_D (Jammu & Kashmir)",
        FacilityCity: "Hiranagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2472,
        Pin: 581302,
        DispatchCenter: "Karwar_Sadashivgad_D (Karnataka)",
        OriginCenter: "Karwar_Sadashivgad_D (Karnataka)",
        FacilityCity: "Karwar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2473,
        Pin: 387120,
        DispatchCenter: "Ahmedabad_Kanera_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2474,
        Pin: 201305,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2475,
        Pin: 523370,
        DispatchCenter: "CumbumAP_BhagyaNagar_D (Andhra Pradesh)",
        OriginCenter: "CumbumAP_BhagyaNagar_D (Andhra Pradesh)",
        FacilityCity: "CumbumAP",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2476,
        Pin: 606907,
        DispatchCenter: "Arani_PolurRd_D (Tamil Nadu)",
        OriginCenter: "Arani_PolurRd_D (Tamil Nadu)",
        FacilityCity: "Arani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2477,
        Pin: 122508,
        DispatchCenter: "Hodal_Hasanpurchowk_D (Haryana)",
        OriginCenter: "Hodal_Hasanpurchowk_D (Haryana)",
        FacilityCity: "Hodal",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2478,
        Pin: 621803,
        DispatchCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        OriginCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        FacilityCity: "Jayankondam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2479,
        Pin: 787035,
        DispatchCenter: "Gogamukh_Rupahi_D (Assam)",
        OriginCenter: "Gogamukh_Rupahi_D (Assam)",
        FacilityCity: "Gogamukh",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2480,
        Pin: 325205,
        DispatchCenter: "Baran_Housingboard_D (Rajasthan)",
        OriginCenter: "Baran_Housingboard_D (Rajasthan)",
        FacilityCity: "Baran",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2481,
        Pin: 322204,
        DispatchCenter: "Nadoti_Ibrahimpur_D (Rajasthan)",
        OriginCenter: "Nadoti_Ibrahimpur_D (Rajasthan)",
        FacilityCity: "Nadoti",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2482,
        Pin: 605104,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2483,
        Pin: 600077,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2484,
        Pin: 793126,
        DispatchCenter: "Riangdo_Pyndengrei_D (Meghalaya)",
        OriginCenter: "Riangdo_Pyndengrei_D (Meghalaya)",
        FacilityCity: "Riangdo",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2485,
        Pin: 444705,
        DispatchCenter: "Daryapur_WakodDPP_D (Maharashtra)",
        OriginCenter: "Daryapur_WakodDPP_D (Maharashtra)",
        FacilityCity: "Daryapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2486,
        Pin: 682033,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2487,
        Pin: 496109,
        DispatchCenter: "Raigarh_Gharghoda_D (Chhattisgarh)",
        OriginCenter: "Raigarh_Gharghoda_D (Chhattisgarh)",
        FacilityCity: "Raigarh",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2488,
        Pin: 828131,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2489,
        Pin: 636307,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2490,
        Pin: 586102,
        DispatchCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        OriginCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        FacilityCity: "Bijapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2491,
        Pin: 458336,
        DispatchCenter: "Mandsaur_Sindpan_I (Madhya Pradesh)",
        OriginCenter: "Mandsaur_Sindpan_I (Madhya Pradesh)",
        FacilityCity: "Mandsaur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 2492,
        Pin: 522410,
        DispatchCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasaraopet",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2493,
        Pin: 782425,
        DispatchCenter: "Chaparmukh_Roha_D (Assam)",
        OriginCenter: "Chaparmukh_Roha_D (Assam)",
        FacilityCity: "Chaparmukh",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2494,
        Pin: 682316,
        DispatchCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        OriginCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        FacilityCity: "Muvattupuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2495,
        Pin: 825401,
        DispatchCenter: "Chatra_Bhagatsinghcolony_D (Jharkhand)",
        OriginCenter: "Chatra_Bhagatsinghcolony_D (Jharkhand)",
        FacilityCity: "Chatra",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2496,
        Pin: 685589,
        DispatchCenter: "Mundakayam_Peermade_D (Kerala)",
        OriginCenter: "Mundakayam_Peermade_D (Kerala)",
        FacilityCity: "Mundakayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2497,
        Pin: 815359,
        DispatchCenter: "Kundahit_Bankati_D (Jharkhand)",
        OriginCenter: "Kundahit_Bankati_D (Jharkhand)",
        FacilityCity: "Kundahit",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2498,
        Pin: 523186,
        DispatchCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        OriginCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        FacilityCity: "Ongole",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2499,
        Pin: 125055,
        DispatchCenter: "Sirsa_Housingboardcol_D (Haryana)",
        OriginCenter: "Sirsa_Housingboardcol_D (Haryana)",
        FacilityCity: "Sirsa",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2500,
        Pin: 396409,
        DispatchCenter: "Bilimora_DC (Gujarat)",
        OriginCenter: "Bilimora_DC (Gujarat)",
        FacilityCity: "Bilimora",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2501,
        Pin: 205001,
        DispatchCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        OriginCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        FacilityCity: "Mainpuri",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2502,
        Pin: 814152,
        DispatchCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        OriginCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        FacilityCity: "Deoghar",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2503,
        Pin: 632004,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2504,
        Pin: 470229,
        DispatchCenter: "Rehli_Ward3_D (Madhya Pradesh)",
        OriginCenter: "Rehli_Ward3_D (Madhya Pradesh)",
        FacilityCity: "Rehli",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2505,
        Pin: 176084,
        DispatchCenter: "Dheera_Purba_DPP (Himachal Pradesh)",
        OriginCenter: "Dheera_Purba_DPP (Himachal Pradesh)",
        FacilityCity: "Dheera",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2506,
        Pin: 147202,
        DispatchCenter: "MandiGobindgarh_Nasrali_D (Punjab)",
        OriginCenter: "MandiGobindgarh_Nasrali_D (Punjab)",
        FacilityCity: "Mandi Gobindgarh",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2507,
        Pin: 741250,
        DispatchCenter: "Naihati_Kalyani_DPP (West Bengal)",
        OriginCenter: "Naihati_Kalyani_DPP (West Bengal)",
        FacilityCity: "Kalyani",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2508,
        Pin: 505215,
        DispatchCenter: "KarimNagar_Alugunoor_I (Telangana)",
        OriginCenter: "KarimNagar_Alugunoor_I (Telangana)",
        FacilityCity: "Karim Nagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2509,
        Pin: 530027,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2510,
        Pin: 721122,
        DispatchCenter: "Midnapore_Harishpur_D (West Bengal)",
        OriginCenter: "Midnapore_Harishpur_D (West Bengal)",
        FacilityCity: "Midnapore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2511,
        Pin: 723161,
        DispatchCenter: "Raghunathpur_Nutandih_D (West Bengal)",
        OriginCenter: "Raghunathpur_Nutandih_D (West Bengal)",
        FacilityCity: "Raghunathpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2512,
        Pin: 636806,
        DispatchCenter: "Palacode_Dandukaranahalli_D (Tamil Nadu)",
        OriginCenter: "Palacode_Dandukaranahalli_D (Tamil Nadu)",
        FacilityCity: "Palacode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2513,
        Pin: 227207,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2514,
        Pin: 226101,
        DispatchCenter: "Malihabad_Amanignj_DPP (Uttar Pradesh)",
        OriginCenter: "Malihabad_Amanignj_DPP (Uttar Pradesh)",
        FacilityCity: "Malihabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2515,
        Pin: 682320,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2516,
        Pin: 244601,
        DispatchCenter: "Thakurdwara_Ward8_D (Uttar Pradesh)",
        OriginCenter: "Thakurdwara_Ward8_D (Uttar Pradesh)",
        FacilityCity: "Thakurdwara",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2517,
        Pin: 524403,
        DispatchCenter: "Vidyanagar_Kota_D (Andhra Pradesh)",
        OriginCenter: "Vidyanagar_Kota_D (Andhra Pradesh)",
        FacilityCity: "Vidyanagar",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2518,
        Pin: 581109,
        DispatchCenter: "Hirekerur_Raghavendracolony_D (Karnataka)",
        OriginCenter: "Hirekerur_Raghavendracolony_D (Karnataka)",
        FacilityCity: "Hirekerur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2519,
        Pin: 604210,
        DispatchCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        OriginCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        FacilityCity: "Gingee",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2520,
        Pin: 247340,
        DispatchCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        OriginCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        FacilityCity: "Saharanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2521,
        Pin: 422113,
        DispatchCenter: "Sinnar_Pimpale_D (Maharashtra)",
        OriginCenter: "Sinnar_Pimpale_D (Maharashtra)",
        FacilityCity: "Sinnar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2522,
        Pin: 143205,
        DispatchCenter: "Tarsikka_Beas_D (Punjab)",
        OriginCenter: "Tarsikka_Beas_D (Punjab)",
        FacilityCity: "Beas",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2523,
        Pin: 761106,
        DispatchCenter: "Digapahandi_Punjikanya_D (Orissa)",
        OriginCenter: "Digapahandi_Punjikanya_D (Orissa)",
        FacilityCity: "Digapahandi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2524,
        Pin: 413402,
        DispatchCenter: "Vairag_ShradadeviNgr_D (Maharashtra)",
        OriginCenter: "Vairag_ShradadeviNgr_D (Maharashtra)",
        FacilityCity: "Vairag",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2525,
        Pin: 625572,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2526,
        Pin: 686519,
        DispatchCenter: "Kottayam_Mulakunnath_D (Kerala)",
        OriginCenter: "Kottayam_Mulakunnath_D (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2527,
        Pin: 324007,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2528,
        Pin: 471525,
        DispatchCenter: "LavkushNagar_Patti_D (Madhya Pradesh)",
        OriginCenter: "LavkushNagar_Patti_D (Madhya Pradesh)",
        FacilityCity: "Lavkush Nagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 2529,
        Pin: 383235,
        DispatchCenter: "Khedbrahma_SaraswtiCmplx_DPP (Gujarat)",
        OriginCenter: "Khedbrahma_SaraswtiCmplx_DPP (Gujarat)",
        FacilityCity: "Khedbrahma",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2530,
        Pin: 788107,
        DispatchCenter: "Pailapool_Lagktlbocpr_D (Assam)",
        OriginCenter: "Pailapool_Lagktlbocpr_D (Assam)",
        FacilityCity: "Pailapool",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2531,
        Pin: 670676,
        DispatchCenter: "Thalassery_Iyyamkode_D (Kerala)",
        OriginCenter: "Thalassery_Iyyamkode_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2532,
        Pin: 605702,
        DispatchCenter: "Sankarapuram_Vadasiruvalur_D (Tamil Nadu)",
        OriginCenter: "Sankarapuram_Vadasiruvalur_D (Tamil Nadu)",
        FacilityCity: "Sankarapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2533,
        Pin: 303008,
        DispatchCenter: "Dudu_PhagiRd_D (Rajasthan)",
        OriginCenter: "Dudu_PhagiRd_D (Rajasthan)",
        FacilityCity: "Dudu",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2534,
        Pin: 686602,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2535,
        Pin: 226011,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2536,
        Pin: 560043,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2537,
        Pin: 686539,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2538,
        Pin: 380002,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2539,
        Pin: 796290,
        DispatchCenter: "Ngopa_Chhimveng_D (Mizoram)",
        OriginCenter: "Ngopa_Chhimveng_D (Mizoram)",
        FacilityCity: "Ngopa",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2540,
        Pin: 504302,
        DispatchCenter: "Mancherial_Nuspur_I (Telangana)",
        OriginCenter: "Mancherial_Nuspur_I (Telangana)",
        FacilityCity: "Mancherial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2541,
        Pin: 303121,
        DispatchCenter: "Achrol_Arvindcolony_D (Rajasthan)",
        OriginCenter: "Achrol_Arvindcolony_D (Rajasthan)",
        FacilityCity: "Achrol",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2542,
        Pin: 584202,
        DispatchCenter: "Raichur_Wadloor_I (Karnataka)",
        OriginCenter: "Raichur_Wadloor_I (Karnataka)",
        FacilityCity: "Raichur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2543,
        Pin: 759144,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2544,
        Pin: 735132,
        DispatchCenter: "Jalpaiguri_Gomostapara_D (West Bengal)",
        OriginCenter: "Jalpaiguri_Gomostapara_D (West Bengal)",
        FacilityCity: "Jalpaiguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2545,
        Pin: 755004,
        DispatchCenter: "Binjharpur_Ragunthapr_D (Orissa)",
        OriginCenter: "Binjharpur_Ragunthapr_D (Orissa)",
        FacilityCity: "Binjharpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2546,
        Pin: 700064,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2547,
        Pin: 700041,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2548,
        Pin: 586118,
        DispatchCenter: "Talikoti_GaneshNagar_D (Karnataka)",
        OriginCenter: "Talikoti_GaneshNagar_D (Karnataka)",
        FacilityCity: "Talikoti",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2549,
        Pin: 148026,
        DispatchCenter: "Sangrur_DC (Punjab)",
        OriginCenter: "Sangrur_DC (Punjab)",
        FacilityCity: "Sangrur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2550,
        Pin: 424101,
        DispatchCenter: "Chalisgaon_HarjaiRd_DPP (Maharashtra)",
        OriginCenter: "Chalisgaon_HarjaiRd_DPP (Maharashtra)",
        FacilityCity: "Chalisgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2551,
        Pin: 204214,
        DispatchCenter: "SikandraRao_RadhaNagar_D (Uttar Pradesh)",
        OriginCenter: "SikandraRao_RadhaNagar_D (Uttar Pradesh)",
        FacilityCity: "Sikandra Rao",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2552,
        Pin: 411501,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2553,
        Pin: 403708,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2554,
        Pin: 207249,
        DispatchCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        OriginCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        FacilityCity: "Aliganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2555,
        Pin: 680311,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2556,
        Pin: 250619,
        DispatchCenter: "Sonipat_Sector51_L (Haryana)",
        OriginCenter: "Sonipat_WazidpurSaboli_P (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2557,
        Pin: 191132,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2558,
        Pin: 342306,
        DispatchCenter: "Mathania_Vishvakarmanagar_D (Rajasthan)",
        OriginCenter: "Mathania_Vishvakarmanagar_D (Rajasthan)",
        FacilityCity: "Mathania",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2559,
        Pin: 638103,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2560,
        Pin: 423108,
        DispatchCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        OriginCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        FacilityCity: "Malegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2561,
        Pin: 723121,
        DispatchCenter: "Raghunathpur_Nutandih_D (West Bengal)",
        OriginCenter: "Raghunathpur_Nutandih_D (West Bengal)",
        FacilityCity: "Raghunathpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2562,
        Pin: 307511,
        DispatchCenter: "Reodar_KarotiChorha_DPP (Rajasthan)",
        OriginCenter: "Reodar_KarotiChorha_DPP (Rajasthan)",
        FacilityCity: "Reodar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2563,
        Pin: 416307,
        DispatchCenter: "Tasgaon_Trimurticolony_D (Maharashtra)",
        OriginCenter: "Tasgaon_Trimurticolony_D (Maharashtra)",
        FacilityCity: "Tasgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2564,
        Pin: 248009,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2565,
        Pin: 452002,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 2566,
        Pin: 425303,
        DispatchCenter: "Chopda_CollegeRd_DPP (Maharashtra)",
        OriginCenter: "Chopda_CollegeRd_DPP (Maharashtra)",
        FacilityCity: "Chopda",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2567,
        Pin: 451111,
        DispatchCenter: "Sanawad_Azadnagar_DPP (Madhya Pradesh)",
        OriginCenter: "Sanawad_Azadnagar_DPP (Madhya Pradesh)",
        FacilityCity: "Sanawad",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2568,
        Pin: 228101,
        DispatchCenter: "Shahganj_RudhauliKalan_D (Uttar Pradesh)",
        OriginCenter: "Shahganj_RudhauliKalan_D (Uttar Pradesh)",
        FacilityCity: "Shahganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2569,
        Pin: 131021,
        DispatchCenter: "Sonipat_Sector51_L (Haryana)",
        OriginCenter: "Sonipat_WazidpurSaboli_P (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2570,
        Pin: 585310,
        DispatchCenter: "Jewargi_Omnagar_D (Karnataka)",
        OriginCenter: "Jewargi_Omnagar_D (Karnataka)",
        FacilityCity: "Jewargi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2571,
        Pin: 574108,
        DispatchCenter: "Karkala_JodursteJ_D (Karnataka)",
        OriginCenter: "Karkala_JodursteJ_D (Karnataka)",
        FacilityCity: "Karkala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2572,
        Pin: 440010,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2573,
        Pin: 643231,
        DispatchCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        OriginCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2574,
        Pin: 571416,
        DispatchCenter: "Nagamangala_TBExtension_D (Karnataka)",
        OriginCenter: "Nagamangala_TBExtension_D (Karnataka)",
        FacilityCity: "Nagamangala",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2575,
        Pin: 452017,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 2576,
        Pin: 621007,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2577,
        Pin: 783323,
        DispatchCenter: "Dhubri_Tetultol_D (Assam)",
        OriginCenter: "Dhubri_Tetultol_D (Assam)",
        FacilityCity: "Dhubri",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2578,
        Pin: 180005,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2579,
        Pin: 813101,
        DispatchCenter: "Banka_Shahpur_DPP (Bihar)",
        OriginCenter: "Banka_Shahpur_DPP (Bihar)",
        FacilityCity: "Banka",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2580,
        Pin: 782120,
        DispatchCenter: "Kaliabor_Hatiakhowa_DPP (Assam)",
        OriginCenter: "Kaliabor_Hatiakhowa_DPP (Assam)",
        FacilityCity: "Kaliabor",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2581,
        Pin: 396101,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2582,
        Pin: 458553,
        DispatchCenter: "Mandsaur_Sindpan_I (Madhya Pradesh)",
        OriginCenter: "Mandsaur_Sindpan_I (Madhya Pradesh)",
        FacilityCity: "Mandsaur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2583,
        Pin: 383225,
        DispatchCenter: "Idar_GIDC_D (Gujarat)",
        OriginCenter: "Idar_GIDC_D (Gujarat)",
        FacilityCity: "Idar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2584,
        Pin: 502032,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2585,
        Pin: 174308,
        DispatchCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        OriginCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        FacilityCity: "Bangana",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2586,
        Pin: 796004,
        DispatchCenter: "Aizawl_Sairang_I (Mizoram)",
        OriginCenter: "Aizawl_Sairang_I (Mizoram)",
        FacilityCity: "Aizawl",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2587,
        Pin: 825313,
        DispatchCenter: "Bagodar_Bishungarh_DPP (Jharkhand)",
        OriginCenter: "Bagodar_Bishungarh_DPP (Jharkhand)",
        FacilityCity: "Bagodar",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2588,
        Pin: 802201,
        DispatchCenter: "Sandesh_Agiaon_D (Bihar)",
        OriginCenter: "Sandesh_Agiaon_D (Bihar)",
        FacilityCity: "Sandesh",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2589,
        Pin: 583276,
        DispatchCenter: "Bellary_Mundargi_I (Karnataka)",
        OriginCenter: "Bellary_Mundargi_I (Karnataka)",
        FacilityCity: "Bellary",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2590,
        Pin: 625003,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2591,
        Pin: 190015,
        DispatchCenter: "Srinagar_Ladhoo_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Ladhoo_H (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2592,
        Pin: 193222,
        DispatchCenter: "Kupwara_PoliceStn_DPP (Jammu & Kashmir)",
        OriginCenter: "Kupwara_PoliceStn_DPP (Jammu & Kashmir)",
        FacilityCity: "Kupwara",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2593,
        Pin: 331505,
        DispatchCenter: "Ratangarh_Goldencolony_D (Rajasthan)",
        OriginCenter: "Ratangarh_Goldencolony_D (Rajasthan)",
        FacilityCity: "Ratangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2594,
        Pin: 636139,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2595,
        Pin: 223227,
        DispatchCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2596,
        Pin: 635207,
        DispatchCenter: "Uthangarai_Mathurpathi_D (Tamil Nadu)",
        OriginCenter: "Uthangarai_Mathurpathi_D (Tamil Nadu)",
        FacilityCity: "Uthangarai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2597,
        Pin: 382470,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2598,
        Pin: 495688,
        DispatchCenter: "Dabhra_Thangan_D (Chhattisgarh)",
        OriginCenter: "Dabhra_Thangan_D (Chhattisgarh)",
        FacilityCity: "Dabhra",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2599,
        Pin: 480338,
        DispatchCenter: "Pandhurna_Shastrinagar_D (Madhya Pradesh)",
        OriginCenter: "Pandhurna_Shastrinagar_D (Madhya Pradesh)",
        FacilityCity: "Pandhurna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2600,
        Pin: 184145,
        DispatchCenter: "Samba_Wardno13_D (Jammu & Kashmir)",
        OriginCenter: "Samba_Wardno13_D (Jammu & Kashmir)",
        FacilityCity: "Samba",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2601,
        Pin: 431302,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2602,
        Pin: 682314,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2603,
        Pin: 835214,
        DispatchCenter: "Khalari_Tangar_D (Jharkhand)",
        OriginCenter: "Khalari_Tangar_D (Jharkhand)",
        FacilityCity: "Khalari-JR",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2604,
        Pin: 505466,
        DispatchCenter: "Husnabad_HanmakondaRd_D (Telangana)",
        OriginCenter: "Husnabad_HanmakondaRd_D (Telangana)",
        FacilityCity: "Husnabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2605,
        Pin: 522649,
        DispatchCenter: "Vinukonda_Thimmayapalem_D (Andhra Pradesh)",
        OriginCenter: "Vinukonda_Thimmayapalem_D (Andhra Pradesh)",
        FacilityCity: "Vinukonda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2606,
        Pin: 400097,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2607,
        Pin: 131305,
        DispatchCenter: "Gohana_Adarshnagar_D (Haryana)",
        OriginCenter: "Gohana_Adarshnagar_D (Haryana)",
        FacilityCity: "Gohana",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2608,
        Pin: 400023,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2609,
        Pin: 413710,
        DispatchCenter: "Loni_BailBzr_DPP (Maharashtra)",
        OriginCenter: "Loni_BailBzr_DPP (Maharashtra)",
        FacilityCity: "Loni",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2610,
        Pin: 466117,
        DispatchCenter: "Sehore_Sherpur_D (Madhya Pradesh)",
        OriginCenter: "Sehore_Sherpur_D (Madhya Pradesh)",
        FacilityCity: "Sehore",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2611,
        Pin: 585222,
        DispatchCenter: "Sedam_Venkateshnagar_D (Karnataka)",
        OriginCenter: "Sedam_Venkateshnagar_D (Karnataka)",
        FacilityCity: "Sedam",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2612,
        Pin: 388105,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2613,
        Pin: 505452,
        DispatchCenter: "KarimNagar_Alugunoor_I (Telangana)",
        OriginCenter: "KarimNagar_Alugunoor_I (Telangana)",
        FacilityCity: "Karim Nagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2614,
        Pin: 841218,
        DispatchCenter: "Marhowarah_StatinRD_D (Bihar)",
        OriginCenter: "Marhowarah_StatinRD_D (Bihar)",
        FacilityCity: "Marhowarah",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2615,
        Pin: 686014,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2616,
        Pin: 160106,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2617,
        Pin: 482005,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 2618,
        Pin: 523223,
        DispatchCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        OriginCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        FacilityCity: "Kanigiri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2619,
        Pin: 754028,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2620,
        Pin: 846009,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2621,
        Pin: 761140,
        DispatchCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        OriginCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        FacilityCity: "Bhanjanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2622,
        Pin: 361120,
        DispatchCenter: "Jamnagar_Chela_L (Gujarat)",
        OriginCenter: "Jamnagar_Chela_L (Gujarat)",
        FacilityCity: "Jamnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2623,
        Pin: 734424,
        DispatchCenter: "Bagdogra_Kadopani_D (West Bengal)",
        OriginCenter: "Bagdogra_Kadopani_D (West Bengal)",
        FacilityCity: "Bagdogra",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2624,
        Pin: 247769,
        DispatchCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        OriginCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        FacilityCity: "Saharanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2625,
        Pin: 233304,
        DispatchCenter: "Aunrihar_JawahrNgr_D (Uttar Pradesh)",
        OriginCenter: "Aunrihar_JawahrNgr_D (Uttar Pradesh)",
        FacilityCity: "Aunrihar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2626,
        Pin: 410209,
        DispatchCenter: "Mumbai_Talojamidc_L (Maharashtra)",
        OriginCenter: "Mumbai_Talojamidc_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2627,
        Pin: 444306,
        DispatchCenter: "Khamgaon_Wamannagar_DPP (Maharashtra)",
        OriginCenter: "Khamgaon_Wamannagar_DPP (Maharashtra)",
        FacilityCity: "Khamgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2628,
        Pin: 503213,
        DispatchCenter: "Nizamabad_Sarangapur_I (Telangana)",
        OriginCenter: "Nizamabad_Sarangapur_I (Telangana)",
        FacilityCity: "Nizamabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2629,
        Pin: 581365,
        DispatchCenter: "Dandeli_RKComplx_D (Karnataka)",
        OriginCenter: "Dandeli_RKComplx_D (Karnataka)",
        FacilityCity: "Dandeli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2630,
        Pin: 680549,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2631,
        Pin: 413110,
        DispatchCenter: "Baramati_Pragatinagar_D (Maharashtra)",
        OriginCenter: "Baramati_Pragatinagar_D (Maharashtra)",
        FacilityCity: "Baramati",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2632,
        Pin: 600009,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2633,
        Pin: 533104,
        DispatchCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        OriginCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        FacilityCity: "Rajahmundry",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2634,
        Pin: 485116,
        DispatchCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        OriginCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        FacilityCity: "Rewa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2635,
        Pin: 795134,
        DispatchCenter: "Imphal_Nambol_D (Manipur)",
        OriginCenter: "Imphal_Nambol_D (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2636,
        Pin: 402126,
        DispatchCenter: "Nagothane_Balsaital_D (Maharashtra)",
        OriginCenter: "Nagothane_Balsaital_D (Maharashtra)",
        FacilityCity: "Nagothane",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2637,
        Pin: 462011,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 2638,
        Pin: 767035,
        DispatchCenter: "Kantabanji_Sindhekela_D (Orissa)",
        OriginCenter: "Kantabanji_Sindhekela_D (Orissa)",
        FacilityCity: "Kantabanji",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2639,
        Pin: 332601,
        DispatchCenter: "Ramgarh_Khoor_DPP (Rajasthan)",
        OriginCenter: "Ramgarh_Khoor_DPP (Rajasthan)",
        FacilityCity: "Ramgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2640,
        Pin: 691560,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2641,
        Pin: 208022,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2642,
        Pin: 173207,
        DispatchCenter: "Solan_SayriGhat_DPP (Himachal Pradesh)",
        OriginCenter: "Solan_SayriGhat_DPP (Himachal Pradesh)",
        FacilityCity: "Solan",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2643,
        Pin: 411011,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2644,
        Pin: 691332,
        DispatchCenter: "Adoor_Pathanapuram_D (Kerala)",
        OriginCenter: "Adoor_Pathanapuram_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2645,
        Pin: 621711,
        DispatchCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        OriginCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        FacilityCity: "Thirukkattupalli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2646,
        Pin: 573133,
        DispatchCenter: "Ramanathapura_Koodalur_D (Karnataka)",
        OriginCenter: "Ramanathapura_Koodalur_D (Karnataka)",
        FacilityCity: "Ramanathapura",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2647,
        Pin: 416309,
        DispatchCenter: "Vita_Adarshnagar_D (Maharashtra)",
        OriginCenter: "Vita_Adarshnagar_D (Maharashtra)",
        FacilityCity: "Vita",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2648,
        Pin: 531163,
        DispatchCenter: "Bheemunipatnam_PstOfc_D (Andhra Pradesh)",
        OriginCenter: "Bheemunipatnam_PstOfc_D (Andhra Pradesh)",
        FacilityCity: "Bheemunipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2649,
        Pin: 274304,
        DispatchCenter: "Padrauna_BauliChwk_D (Uttar Pradesh)",
        OriginCenter: "Padrauna_BauliChwk_D (Uttar Pradesh)",
        FacilityCity: "Padrauna",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2650,
        Pin: 444605,
        DispatchCenter: "Amravati_Sashinagar_D (Maharashtra)",
        OriginCenter: "Amravati_Sashinagar_D (Maharashtra)",
        FacilityCity: "Amravati",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2651,
        Pin: 518302,
        DispatchCenter: "Adoni_Mandagiri_D (Andhra Pradesh)",
        OriginCenter: "Adoni_Mandagiri_D (Andhra Pradesh)",
        FacilityCity: "Adoni",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2652,
        Pin: 494331,
        DispatchCenter: "Keshkal_Surdongar_D (Chhattisgarh)",
        OriginCenter: "Keshkal_Surdongar_D (Chhattisgarh)",
        FacilityCity: "keshkal",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2653,
        Pin: 628901,
        DispatchCenter: "Vilathikulam_Thoothukudi_DPP (Tamil Nadu)",
        OriginCenter: "Vilathikulam_Thoothukudi_DPP (Tamil Nadu)",
        FacilityCity: "Vilathikulam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2654,
        Pin: 742149,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2655,
        Pin: 400040,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2656,
        Pin: 689543,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2657,
        Pin: 627657,
        DispatchCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        OriginCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        FacilityCity: "Thisayanvilai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2658,
        Pin: 824102,
        DispatchCenter: "AurangabadBR_Katharua_D (Bihar)",
        OriginCenter: "AurangabadBR_Katharua_D (Bihar)",
        FacilityCity: "Aurangabad-BR",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2659,
        Pin: 741124,
        DispatchCenter: "Chapra_Elemnagar_DPP (West Bengal)",
        OriginCenter: "Chapra_Elemnagar_DPP (West Bengal)",
        FacilityCity: "Chapra",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2660,
        Pin: 185234,
        DispatchCenter: "Rajouri_Mehra_D (Jammu & Kashmir)",
        OriginCenter: "Rajouri_Mehra_D (Jammu & Kashmir)",
        FacilityCity: "Rajouri",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2661,
        Pin: 600096,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2662,
        Pin: 758076,
        DispatchCenter: "Telkoi_GaneshMarket_DPP (Orissa)",
        OriginCenter: "Telkoi_GaneshMarket_DPP (Orissa)",
        FacilityCity: "Telkoi",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2663,
        Pin: 460447,
        DispatchCenter: "Sarni_Ward36_D (Madhya Pradesh)",
        OriginCenter: "Sarni_Ward36_D (Madhya Pradesh)",
        FacilityCity: "Sarni",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2664,
        Pin: 122505,
        DispatchCenter: "Gurgaon_Farukhnagar_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2665,
        Pin: 743414,
        DispatchCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        OriginCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        FacilityCity: "Basirhat",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2666,
        Pin: 695008,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2667,
        Pin: 275103,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2668,
        Pin: 689630,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2669,
        Pin: 582119,
        DispatchCenter: "Rona_GadagRD_D (Karnataka)",
        OriginCenter: "Rona_GadagRD_D (Karnataka)",
        FacilityCity: "Rona",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2670,
        Pin: 624314,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2671,
        Pin: 690549,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2672,
        Pin: 695308,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2673,
        Pin: 394105,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2674,
        Pin: 572219,
        DispatchCenter: "Tumkur_Doddapete_DPP (Karnataka)",
        OriginCenter: "Tumkur_Doddapete_DPP (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2675,
        Pin: 457339,
        DispatchCenter: "Mandsaur_Dalodarail_D (Madhya Pradesh)",
        OriginCenter: "Mandsaur_Dalodarail_D (Madhya Pradesh)",
        FacilityCity: "Mandsaur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2676,
        Pin: 583104,
        DispatchCenter: "Bellary_Mundargi_I (Karnataka)",
        OriginCenter: "Bellary_Mundargi_I (Karnataka)",
        FacilityCity: "Bellary",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2677,
        Pin: 421001,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2678,
        Pin: 754137,
        DispatchCenter: "Tirtol_Central_D_2 (Orissa)",
        OriginCenter: "Tirtol_Central_D_2 (Orissa)",
        FacilityCity: "Tirtol",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2679,
        Pin: 624306,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2680,
        Pin: 125112,
        DispatchCenter: "Fatehabad_Kullanroad_D (Haryana)",
        OriginCenter: "Fatehabad_Kullanroad_D (Haryana)",
        FacilityCity: "Fatehabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2681,
        Pin: 247323,
        DispatchCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        OriginCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        FacilityCity: "Saharanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2682,
        Pin: 441113,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Chikhali_L (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2683,
        Pin: 416414,
        DispatchCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        OriginCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        FacilityCity: "Miraj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2684,
        Pin: 841412,
        DispatchCenter: "Chhapra_Ramnagar_I (Bihar)",
        OriginCenter: "Chhapra_Ramnagar_I (Bihar)",
        FacilityCity: "Chhapra",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2685,
        Pin: 305021,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2686,
        Pin: 311012,
        DispatchCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        OriginCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        FacilityCity: "Bhilwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2687,
        Pin: 184151,
        DispatchCenter: "Kathua_Kharote_D (Jammu & Kashmir)",
        OriginCenter: "Kathua_Kharote_D (Jammu & Kashmir)",
        FacilityCity: "Kathua",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2688,
        Pin: 761215,
        DispatchCenter: "Paralakhemundi_Gosani_DPP (Orissa)",
        OriginCenter: "Paralakhemundi_Gosani_DPP (Orissa)",
        FacilityCity: "Paralakhemundi",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2689,
        Pin: 630318,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2690,
        Pin: 623707,
        DispatchCenter: "Paramakudi_River_D (Tamil Nadu)",
        OriginCenter: "Paramakudi_River_D (Tamil Nadu)",
        FacilityCity: "Paramakudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2691,
        Pin: 621103,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2692,
        Pin: 785661,
        DispatchCenter: "Teok_Wardno3_D (Assam)",
        OriginCenter: "Teok_Wardno3_D (Assam)",
        FacilityCity: "Teok",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2693,
        Pin: 272003,
        DispatchCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        OriginCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        FacilityCity: "Basti",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2694,
        Pin: 676315,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2695,
        Pin: 756025,
        DispatchCenter: "Balasore_Ganeswarpur_D (Orissa)",
        OriginCenter: "Balasore_Ganeswarpur_D (Orissa)",
        FacilityCity: "Balasore",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2696,
        Pin: 690520,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2697,
        Pin: 688539,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2698,
        Pin: 517422,
        DispatchCenter: "Chittoor_Bangarupalyam_D (Andhra Pradesh)",
        OriginCenter: "Chittoor_Bangarupalyam_D (Andhra Pradesh)",
        FacilityCity: "Palamaner",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2699,
        Pin: 585327,
        DispatchCenter: "Basavakalyan_Morkhandi_DPP (Karnataka)",
        OriginCenter: "Basavakalyan_Morkhandi_DPP (Karnataka)",
        FacilityCity: "Basavakalyan",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2700,
        Pin: 303328,
        DispatchCenter: "Phulera_NarenaRD_D (Rajasthan)",
        OriginCenter: "Phulera_NarenaRD_D (Rajasthan)",
        FacilityCity: "Phulera",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2701,
        Pin: 480337,
        DispatchCenter: "Pandhurna_Shastrinagar_D (Madhya Pradesh)",
        OriginCenter: "Pandhurna_Shastrinagar_D (Madhya Pradesh)",
        FacilityCity: "Pandhurna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2702,
        Pin: 587101,
        DispatchCenter: "Bagalkot_NavaNgr_D (Karnataka)",
        OriginCenter: "Bagalkot_NavaNgr_D (Karnataka)",
        FacilityCity: "Bagalkot",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2703,
        Pin: 577005,
        DispatchCenter: "Davangere_Industrialarea_I (Karnataka)",
        OriginCenter: "Davangere_Industrialarea_I (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2704,
        Pin: 508243,
        DispatchCenter: "Devarakonda_Karunacol_D (Telangana)",
        OriginCenter: "Devarakonda_Karunacol_D (Telangana)",
        FacilityCity: "Devarakonda",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2705,
        Pin: 787055,
        DispatchCenter: "Dhakuakhana_Wardno2_D (Assam)",
        OriginCenter: "Dhakuakhana_Wardno2_D (Assam)",
        FacilityCity: "Dhakuakhana",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2706,
        Pin: 144104,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2707,
        Pin: 471516,
        DispatchCenter: "LavkushNagar_Patti_D (Madhya Pradesh)",
        OriginCenter: "LavkushNagar_Patti_D (Madhya Pradesh)",
        FacilityCity: "Lavkush Nagar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2708,
        Pin: 781347,
        DispatchCenter: "Nalbari_Chokbazar_D (Assam)",
        OriginCenter: "Nalbari_Chokbazar_D (Assam)",
        FacilityCity: "Nalbari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2709,
        Pin: 686666,
        DispatchCenter: "Perumbavoor_Kizhake_D (Kerala)",
        OriginCenter: "Perumbavoor_Kizhake_D (Kerala)",
        FacilityCity: "Perumbavoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2710,
        Pin: 414101,
        DispatchCenter: "Karjat_Mirajgaon_DPP (Maharashtra)",
        OriginCenter: "Karjat_Mirajgaon_DPP (Maharashtra)",
        FacilityCity: "Karjat-MH",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2711,
        Pin: 600029,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2712,
        Pin: 392163,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2713,
        Pin: 516310,
        DispatchCenter: "Cuddapah_Peddacheppalle_D (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Peddacheppalle_D (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2714,
        Pin: 766016,
        DispatchCenter: "Junagarh_Dharmagarh1_D (Orissa)",
        OriginCenter: "Junagarh_Dharmagarh1_D (Orissa)",
        FacilityCity: "Junagarh-OR",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2715,
        Pin: 324008,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2716,
        Pin: 630606,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2717,
        Pin: 361005,
        DispatchCenter: "Jamnagar_Chela_L (Gujarat)",
        OriginCenter: "Jamnagar_Chela_L (Gujarat)",
        FacilityCity: "Jamnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2718,
        Pin: 401405,
        DispatchCenter: "Palghar_Maan_L (Maharashtra)",
        OriginCenter: "Palghar_Maan_L (Maharashtra)",
        FacilityCity: "Palghar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2719,
        Pin: 629403,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2720,
        Pin: 391125,
        DispatchCenter: "Bodeli_SatyamPlaza_DPP (Gujarat)",
        OriginCenter: "Bodeli_SatyamPlaza_DPP (Gujarat)",
        FacilityCity: "Bodeli",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2721,
        Pin: 227412,
        DispatchCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        OriginCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        FacilityCity: "Amethi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2722,
        Pin: 835101,
        DispatchCenter: "Jhalda_GMukrDPP_D (West Bengal)",
        OriginCenter: "Jhalda_GMukrDPP_D (West Bengal)",
        FacilityCity: "Jhalda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2723,
        Pin: 193402,
        DispatchCenter: "Baramulla_Chandilora_DPP (Jammu & Kashmir)",
        OriginCenter: "Baramulla_Chandilora_DPP (Jammu & Kashmir)",
        FacilityCity: "Baramulla",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2724,
        Pin: 609110,
        DispatchCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        OriginCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        FacilityCity: "Sirkazhi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2725,
        Pin: 416513,
        DispatchCenter: "Goa_Pernem_D (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2726,
        Pin: 759141,
        DispatchCenter: "Chhendipada_Deogarh_DPP (Orissa)",
        OriginCenter: "Chhendipada_Deogarh_DPP (Orissa)",
        FacilityCity: "Chhendipada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2727,
        Pin: 622502,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2728,
        Pin: 576107,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2729,
        Pin: 276139,
        DispatchCenter: "Rajesultanpur_PadumprMkt_D (Uttar Pradesh)",
        OriginCenter: "Rajesultanpur_PadumprMkt_D (Uttar Pradesh)",
        FacilityCity: "Rajesultanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2730,
        Pin: 843334,
        DispatchCenter: "Sheohar_Ward13_D (Bihar)",
        OriginCenter: "Sheohar_Ward13_D (Bihar)",
        FacilityCity: "Sheohar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2731,
        Pin: 301705,
        DispatchCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        OriginCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        FacilityCity: "Neemrana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2732,
        Pin: 691523,
        DispatchCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        OriginCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2733,
        Pin: 140127,
        DispatchCenter: "AnandpurSahib_Dher_D (Punjab)",
        OriginCenter: "AnandpurSahib_Dher_D (Punjab)",
        FacilityCity: "Anandpur Sahib",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2734,
        Pin: 612903,
        DispatchCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        OriginCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        FacilityCity: "Jayankondam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2735,
        Pin: 175050,
        DispatchCenter: "Sarkaghat_Khelag_D (Himachal Pradesh)",
        OriginCenter: "Sarkaghat_Khelag_D (Himachal Pradesh)",
        FacilityCity: "Sarkaghat",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2736,
        Pin: 713338,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2737,
        Pin: 392240,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2738,
        Pin: 143109,
        DispatchCenter: "Amritsar_Ajnala_D (Punjab)",
        OriginCenter: "Amritsar_Ajnala_D (Punjab)",
        FacilityCity: "Ajnala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2739,
        Pin: 689694,
        DispatchCenter: "Adoor_Pathanapuram_D (Kerala)",
        OriginCenter: "Adoor_Pathanapuram_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2740,
        Pin: 759037,
        DispatchCenter: "Angul_Talcher_D (Orissa)",
        OriginCenter: "Angul_Talcher_D (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2741,
        Pin: 502372,
        DispatchCenter: "Cherial_Vchreni_D (Telangana)",
        OriginCenter: "Cherial_Vchreni_D (Telangana)",
        FacilityCity: "Cherial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2742,
        Pin: 788111,
        DispatchCenter: "Udarbond_Pangram_D (Assam)",
        OriginCenter: "Udarbond_Pangram_D (Assam)",
        FacilityCity: "Udarbond",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2743,
        Pin: 110056,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2744,
        Pin: 712611,
        DispatchCenter: "Kamarpukur_ChatiDPP_D (West Bengal)",
        OriginCenter: "Kamarpukur_ChatiDPP_D (West Bengal)",
        FacilityCity: "Kamarpukur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2745,
        Pin: 620102,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2746,
        Pin: 752068,
        DispatchCenter: "Nayagarh_Durgprsd_D (Orissa)",
        OriginCenter: "Nayagarh_Durgprsd_D (Orissa)",
        FacilityCity: "Nayagarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2747,
        Pin: 224198,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2748,
        Pin: 248012,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2749,
        Pin: 670331,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2750,
        Pin: 332742,
        DispatchCenter: "Renwal_Wardno4_D (Rajasthan)",
        OriginCenter: "Renwal_Wardno4_D (Rajasthan)",
        FacilityCity: "Renwal",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2751,
        Pin: 416504,
        DispatchCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        OriginCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        FacilityCity: "Gadhinglaj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2752,
        Pin: 783383,
        DispatchCenter: "Bongaigaon_Abhayapuri_D (Assam)",
        OriginCenter: "Bongaigaon_Abhayapuri_D (Assam)",
        FacilityCity: "Bongaigaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2753,
        Pin: 574141,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2754,
        Pin: 533309,
        DispatchCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2755,
        Pin: 143606,
        DispatchCenter: "Amritsar_Ajnala_D (Punjab)",
        OriginCenter: "Amritsar_Ajnala_D (Punjab)",
        FacilityCity: "Ajnala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2756,
        Pin: 231210,
        DispatchCenter: "Robertsganj_Sirsai_D (Uttar Pradesh)",
        OriginCenter: "Robertsganj_Sirsai_D (Uttar Pradesh)",
        FacilityCity: "Robertsganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2757,
        Pin: 600074,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2758,
        Pin: 688559,
        DispatchCenter: "Alappuzha_Nedumbrakkad_D (Kerala)",
        OriginCenter: "Alappuzha_Nedumbrakkad_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2759,
        Pin: 734005,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2760,
        Pin: 700046,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2761,
        Pin: 396155,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2762,
        Pin: 764049,
        DispatchCenter: "Nabarangpr_PhdofDPP_D (Orissa)",
        OriginCenter: "Nabarangpr_PhdofDPP_D (Orissa)",
        FacilityCity: "Nabarangapur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2763,
        Pin: 473660,
        DispatchCenter: "Karera_DenidaRd_D (Madhya Pradesh)",
        OriginCenter: "Karera_DenidaRd_D (Madhya Pradesh)",
        FacilityCity: "Karera-MP",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 2764,
        Pin: 624205,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2765,
        Pin: 686514,
        DispatchCenter: "Mundakayam_Varikkani_D (Kerala)",
        OriginCenter: "Mundakayam_Varikkani_D (Kerala)",
        FacilityCity: "Mundakayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2766,
        Pin: 444101,
        DispatchCenter: "Akot_CollegeRd_D (Maharashtra)",
        OriginCenter: "Akot_CollegeRd_D (Maharashtra)",
        FacilityCity: "Akot",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2767,
        Pin: 246425,
        DispatchCenter: "Rudraprayag_Dungri_DPP (Uttarakhand)",
        OriginCenter: "Rudraprayag_Dungri_DPP (Uttarakhand)",
        FacilityCity: "Rudraprayag",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2768,
        Pin: 670009,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2769,
        Pin: 784527,
        DispatchCenter: "Tamulpur_Kalakuchi_D (Assam)",
        OriginCenter: "Tamulpur_Kalakuchi_D (Assam)",
        FacilityCity: "Tamulpur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2770,
        Pin: 691583,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2771,
        Pin: 678632,
        DispatchCenter: "Karimba_Kaliyode_D (Kerala)",
        OriginCenter: "Karimba_Kaliyode_D (Kerala)",
        FacilityCity: "Karimba",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2772,
        Pin: 283105,
        DispatchCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        OriginCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        FacilityCity: "Agra",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2773,
        Pin: 752110,
        DispatchCenter: "Konark_Central_DPP_2 (Orissa)",
        OriginCenter: "Konark_Central_DPP_2 (Orissa)",
        FacilityCity: "Konark",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2774,
        Pin: 144105,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2775,
        Pin: 444603,
        DispatchCenter: "Amravati_Changapur_D (Maharashtra)",
        OriginCenter: "Amravati_Changapur_D (Maharashtra)",
        FacilityCity: "Amravati",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2776,
        Pin: 416310,
        DispatchCenter: "Vita_Adarshnagar_D (Maharashtra)",
        OriginCenter: "Vita_Adarshnagar_D (Maharashtra)",
        FacilityCity: "Vita",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2777,
        Pin: 248161,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2778,
        Pin: 584121,
        DispatchCenter: "Kushtagi_Karimacolony_DPP (Karnataka)",
        OriginCenter: "Kushtagi_Karimacolony_DPP (Karnataka)",
        FacilityCity: "Kushtagi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2779,
        Pin: 533444,
        DispatchCenter: "Kathipudi_YdvaSt_D (Andhra Pradesh)",
        OriginCenter: "Kathipudi_YdvaSt_D (Andhra Pradesh)",
        FacilityCity: "Kathipudi",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2780,
        Pin: 689581,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2781,
        Pin: 769007,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2782,
        Pin: 788736,
        DispatchCenter: "Ramkrishnanagar_Kalinagar_D (Assam)",
        OriginCenter: "Ramkrishnanagar_Kalinagar_D (Assam)",
        FacilityCity: "Ramkrishna Nagar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2783,
        Pin: 401407,
        DispatchCenter: "Palghar_Maan_L (Maharashtra)",
        OriginCenter: "Palghar_Maan_L (Maharashtra)",
        FacilityCity: "Palghar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2784,
        Pin: 276206,
        DispatchCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2785,
        Pin: 160010,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2786,
        Pin: 828205,
        DispatchCenter: "Chirkunda_Egarkunr_D (Jharkhand)",
        OriginCenter: "Chirkunda_Egarkunr_D (Jharkhand)",
        FacilityCity: "Chirkunda",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2787,
        Pin: 851128,
        DispatchCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        OriginCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        FacilityCity: "Dalsingh Sarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2788,
        Pin: 562107,
        DispatchCenter: "Bangalore_Bommasandra_L (Karnataka)",
        OriginCenter: "Bangalore_Bommasandra_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2789,
        Pin: 691580,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2790,
        Pin: 506172,
        DispatchCenter: "Tadvai_Akulavarighanpur_D (Telangana)",
        OriginCenter: "Tadvai_Akulavarighanpur_D (Telangana)",
        FacilityCity: "Tadvai",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2791,
        Pin: 736182,
        DispatchCenter: "Jaigaon_Manglabarey_D (West Bengal)",
        OriginCenter: "Jaigaon_Manglabarey_D (West Bengal)",
        FacilityCity: "Jaigaon",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2792,
        Pin: 396236,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2793,
        Pin: 786185,
        DispatchCenter: "Chabua_CollgeRD_D (Assam)",
        OriginCenter: "Chabua_CollgeRD_D (Assam)",
        FacilityCity: "Chabua",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2794,
        Pin: 700081,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2795,
        Pin: 752045,
        DispatchCenter: "Puri_Purussottamnagar_D (Orissa)",
        OriginCenter: "Puri_Purussottamnagar_D (Orissa)",
        FacilityCity: "Puri",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2796,
        Pin: 629151,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2797,
        Pin: 754006,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2798,
        Pin: 632008,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2799,
        Pin: 250406,
        DispatchCenter: "Mawana_Veernagar_D (Uttar Pradesh)",
        OriginCenter: "Mawana_Veernagar_D (Uttar Pradesh)",
        FacilityCity: "Mawana",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2800,
        Pin: 582118,
        DispatchCenter: "HoovinaHadagali_HMnagar_D (Karnataka)",
        OriginCenter: "HoovinaHadagali_HMnagar_D (Karnataka)",
        FacilityCity: "Hoovina Hadagali",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2801,
        Pin: 854114,
        DispatchCenter: "Korha_Zorabgani_D (Bihar)",
        OriginCenter: "Korha_Zorabgani_D (Bihar)",
        FacilityCity: "Korha",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2802,
        Pin: 210430,
        DispatchCenter: "Rath_Saina_D (Uttar Pradesh)",
        OriginCenter: "Rath_Saina_D (Uttar Pradesh)",
        FacilityCity: "Rath",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2803,
        Pin: 515865,
        DispatchCenter: "Rayadurgam_Nesepet_D (Andhra Pradesh)",
        OriginCenter: "Rayadurgam_Nesepet_D (Andhra Pradesh)",
        FacilityCity: "Rayadurgam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2804,
        Pin: 500053,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2805,
        Pin: 561202,
        DispatchCenter: "Pavagada_Banashankari_D (Karnataka)",
        OriginCenter: "Pavagada_Banashankari_D (Karnataka)",
        FacilityCity: "Pavagada",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2806,
        Pin: 768227,
        DispatchCenter: "Kuchinda_Pdrpada_D (Orissa)",
        OriginCenter: "Kuchinda_Pdrpada_D (Orissa)",
        FacilityCity: "Kuchinda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2807,
        Pin: 521107,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2808,
        Pin: 232109,
        DispatchCenter: "Chandauli_Chahaniya_D (Uttar Pradesh)",
        OriginCenter: "Chandauli_Chahaniya_D (Uttar Pradesh)",
        FacilityCity: "Mughal Sarai",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2809,
        Pin: 416534,
        DispatchCenter: "Oras_Ranbambuli_D (Maharashtra)",
        OriginCenter: "Oras_Ranbambuli_D (Maharashtra)",
        FacilityCity: "Oras",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2810,
        Pin: 711201,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2811,
        Pin: 844134,
        DispatchCenter: "Lalganj_Kharauna_D (Bihar)",
        OriginCenter: "Lalganj_Kharauna_D (Bihar)",
        FacilityCity: "Lalganj-BH",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2812,
        Pin: 591304,
        DispatchCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        OriginCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        FacilityCity: "Athani",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2813,
        Pin: 222132,
        DispatchCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        OriginCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        FacilityCity: "Jaunpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2814,
        Pin: 560110,
        DispatchCenter: "Bangalore_FCILayout_L (Karnataka)",
        OriginCenter: "Bangalore_FCILayout_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2815,
        Pin: 370625,
        DispatchCenter: "Dayapar_MaaCmplx_DPP (Gujarat)",
        OriginCenter: "Dayapar_MaaCmplx_DPP (Gujarat)",
        FacilityCity: "Dayapar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2816,
        Pin: 224202,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2817,
        Pin: 571415,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2818,
        Pin: 535005,
        DispatchCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        OriginCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        FacilityCity: "Vizianagaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2819,
        Pin: 626579,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2820,
        Pin: 626176,
        DispatchCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        OriginCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        FacilityCity: "Sivakasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2821,
        Pin: 384230,
        DispatchCenter: "Becharaji_Harijiroad_D (Gujarat)",
        OriginCenter: "Becharaji_Harijiroad_D (Gujarat)",
        FacilityCity: "Becharaji",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2822,
        Pin: 431502,
        DispatchCenter: "Selu_Vidyangr_D (Maharashtra)",
        OriginCenter: "Selu_Vidyangr_D (Maharashtra)",
        FacilityCity: "Selu",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2823,
        Pin: 638003,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2824,
        Pin: 831008,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2825,
        Pin: 464993,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2826,
        Pin: 679333,
        DispatchCenter: "Chungatra_KaithakodanBuilding_DPP (Kerala)",
        OriginCenter: "Chungatra_KaithakodanBuilding_DPP (Kerala)",
        FacilityCity: "chungatra",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2827,
        Pin: 824111,
        DispatchCenter: "AurangabadBR_Katharua_D (Bihar)",
        OriginCenter: "AurangabadBR_Katharua_D (Bihar)",
        FacilityCity: "Aurangabad-BR",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2828,
        Pin: 152118,
        DispatchCenter: "Abohar_DaulatPura_D (Punjab)",
        OriginCenter: "Abohar_DaulatPura_D (Punjab)",
        FacilityCity: "Abohar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2829,
        Pin: 500061,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2830,
        Pin: 781018,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2831,
        Pin: 509129,
        DispatchCenter: "Gadwal_Aiza_D (Telangana)",
        OriginCenter: "Gadwal_Aiza_D (Telangana)",
        FacilityCity: "Gadwal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2832,
        Pin: 682014,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2833,
        Pin: 124401,
        DispatchCenter: "Rohtak_IndustrialArea_D (Haryana)",
        OriginCenter: "Rohtak_IndustrialArea_D (Haryana)",
        FacilityCity: "Rohtak",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2834,
        Pin: 766108,
        DispatchCenter: "Sinapali_Snp_D (Orissa)",
        OriginCenter: "Sinapali_Snp_D (Orissa)",
        FacilityCity: "Sinapali",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2835,
        Pin: 173020,
        DispatchCenter: "PaontaSahib_Suryacolony_D (Himachal Pradesh)",
        OriginCenter: "PaontaSahib_Suryacolony_D (Himachal Pradesh)",
        FacilityCity: "Paonta Sahib",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2836,
        Pin: 461990,
        DispatchCenter: "Pipariya_Bankhedi_DPP (Madhya Pradesh)",
        OriginCenter: "Pipariya_Bankhedi_DPP (Madhya Pradesh)",
        FacilityCity: "Pipariya",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 2837,
        Pin: 847111,
        DispatchCenter: "Madhubani_NidhiChowk_D (Bihar)",
        OriginCenter: "Madhubani_NidhiChowk_D (Bihar)",
        FacilityCity: "Madhubani",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2838,
        Pin: 721602,
        DispatchCenter: "Haldia_Basudevpur_D (West Bengal)",
        OriginCenter: "Haldia_Basudevpur_D (West Bengal)",
        FacilityCity: "Haldia",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2839,
        Pin: 572102,
        DispatchCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        OriginCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2840,
        Pin: 127021,
        DispatchCenter: "Bhiwani_Bhagganpuri_D (Haryana)",
        OriginCenter: "Bhiwani_Bhagganpuri_D (Haryana)",
        FacilityCity: "Bhiwani",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2841,
        Pin: 752001,
        DispatchCenter: "Puri_Purussottamnagar_D (Orissa)",
        OriginCenter: "Puri_Purussottamnagar_D (Orissa)",
        FacilityCity: "Puri",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2842,
        Pin: 507125,
        DispatchCenter: "Manuguru_AshokN_D (Telangana)",
        OriginCenter: "Manuguru_AshokN_D (Telangana)",
        FacilityCity: "Manuguru",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2843,
        Pin: 670007,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2844,
        Pin: 134108,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2845,
        Pin: 396445,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2846,
        Pin: 636140,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2847,
        Pin: 695317,
        DispatchCenter: "Attingal_Central_D_2 (Kerala)",
        OriginCenter: "Attingal_Central_D_2 (Kerala)",
        FacilityCity: "Attingal",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2848,
        Pin: 209831,
        DispatchCenter: "Unnao_Nawabganj_D (Uttar Pradesh)",
        OriginCenter: "Unnao_Nawabganj_D (Uttar Pradesh)",
        FacilityCity: "Unnao",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2849,
        Pin: 627124,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2850,
        Pin: 524225,
        DispatchCenter: "Kavali_Pathuru_D (Andhra Pradesh)",
        OriginCenter: "Kavali_Pathuru_D (Andhra Pradesh)",
        FacilityCity: "Kavali",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2851,
        Pin: 603401,
        DispatchCenter: "Marakkanam_Kandadu_DPP (Tamil Nadu)",
        OriginCenter: "Marakkanam_Kandadu_DPP (Tamil Nadu)",
        FacilityCity: "Marakkanam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2852,
        Pin: 302043,
        DispatchCenter: "Jaipur_RingRoad_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2853,
        Pin: 788803,
        DispatchCenter: "Karimganj_Gorkapan_D (Assam)",
        OriginCenter: "Karimganj_Gorkapan_D (Assam)",
        FacilityCity: "Karimganj",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2854,
        Pin: 752055,
        DispatchCenter: "Khurdha_MdhsnDPP_D (Orissa)",
        OriginCenter: "Khurdha_MdhsnDPP_D (Orissa)",
        FacilityCity: "Khurdha",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2855,
        Pin: 365220,
        DispatchCenter: "Dhasa_Nolipara_D (Gujarat)",
        OriginCenter: "Dhasa_Nolipara_D (Gujarat)",
        FacilityCity: "Dhasa",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2856,
        Pin: 577138,
        DispatchCenter: "Kadur_UBRoad_D (Karnataka)",
        OriginCenter: "Kadur_UBRoad_D (Karnataka)",
        FacilityCity: "Kadur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2857,
        Pin: 430005,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2858,
        Pin: 563128,
        DispatchCenter: "Chintamani_Sidlaghatta_D (Karnataka)",
        OriginCenter: "Chintamani_Sidlaghatta_D (Karnataka)",
        FacilityCity: "Chintamani",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2859,
        Pin: 570021,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2860,
        Pin: 670003,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2861,
        Pin: 563118,
        DispatchCenter: "Bangarapet_AMPCClny_D (Karnataka)",
        OriginCenter: "Bangarapet_AMPCClny_D (Karnataka)",
        FacilityCity: "Bangarapet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2862,
        Pin: 676310,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2863,
        Pin: 141003,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2864,
        Pin: 506201,
        DispatchCenter: "Jangaon_Batkamakunta_D (Telangana)",
        OriginCenter: "Jangaon_Batkamakunta_D (Telangana)",
        FacilityCity: "Jangaon",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2865,
        Pin: 460551,
        DispatchCenter: "Amla_Bodhki_D (Madhya Pradesh)",
        OriginCenter: "Amla_Bodhki_D (Madhya Pradesh)",
        FacilityCity: "Amla",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2866,
        Pin: 821109,
        DispatchCenter: "Mohania_Shivpuricolony_D (Bihar)",
        OriginCenter: "Mohania_Shivpuricolony_D (Bihar)",
        FacilityCity: "Mohania",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2867,
        Pin: 382710,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2868,
        Pin: 431717,
        DispatchCenter: "Degloor_GouklNgr_DPP (Maharashtra)",
        OriginCenter: "Degloor_GouklNgr_DPP (Maharashtra)",
        FacilityCity: "Degloor",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2869,
        Pin: 788001,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2870,
        Pin: 754110,
        DispatchCenter: "Balikuda_Balisahi_D (Orissa)",
        OriginCenter: "Balikuda_Balisahi_D (Orissa)",
        FacilityCity: "Balikuda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2871,
        Pin: 243632,
        DispatchCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        OriginCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        FacilityCity: "Chandausi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2872,
        Pin: 624705,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2873,
        Pin: 581453,
        DispatchCenter: "Alnavar_Gharli_DPP (Karnataka)",
        OriginCenter: "Alnavar_Gharli_DPP (Karnataka)",
        FacilityCity: "Alnavar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2874,
        Pin: 110048,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2875,
        Pin: 393003,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2876,
        Pin: 767017,
        DispatchCenter: "Sonepur_BrezeDPP_D (Orissa)",
        OriginCenter: "Sonepur_BrezeDPP_D (Orissa)",
        FacilityCity: "Sonepur-OR",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2877,
        Pin: 323023,
        DispatchCenter: "Bundi_Central_D_1 (Rajasthan)",
        OriginCenter: "Bundi_Central_D_1 (Rajasthan)",
        FacilityCity: "Bundi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2878,
        Pin: 272164,
        DispatchCenter: "Khalilabad_SoniH_D (Uttar Pradesh)",
        OriginCenter: "Khalilabad_SoniH_D (Uttar Pradesh)",
        FacilityCity: "Khalilabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2879,
        Pin: 148038,
        DispatchCenter: "Sangrur_DC (Punjab)",
        OriginCenter: "Sangrur_DC (Punjab)",
        FacilityCity: "Sangrur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2880,
        Pin: 621219,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2881,
        Pin: 227132,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2882,
        Pin: 785685,
        DispatchCenter: "Nazira_Amina_D (Assam)",
        OriginCenter: "Nazira_Amina_D (Assam)",
        FacilityCity: "Nazira",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2883,
        Pin: 686552,
        DispatchCenter: "Changanacherry_Perunna_D (Kerala)",
        OriginCenter: "Changanacherry_Perunna_D (Kerala)",
        FacilityCity: "Changanacherry",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2884,
        Pin: 689113,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2885,
        Pin: 821305,
        DispatchCenter: "Sasaram_Manikpur_D (Bihar)",
        OriginCenter: "Sasaram_Manikpur_D (Bihar)",
        FacilityCity: "Sasaram",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2886,
        Pin: 244412,
        DispatchCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        OriginCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        FacilityCity: "Chandausi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2887,
        Pin: 322234,
        DispatchCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        OriginCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2888,
        Pin: 670678,
        DispatchCenter: "Thalassery_Panoor_D (Kerala)",
        OriginCenter: "Thalassery_Panoor_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2889,
        Pin: 586121,
        DispatchCenter: "Kolhar_ShivajiCircle_D (Karnataka)",
        OriginCenter: "Kolhar_ShivajiCircle_D (Karnataka)",
        FacilityCity: "Kolhar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2890,
        Pin: 144531,
        DispatchCenter: "Garhshankar_BangaRoad_D (Punjab)",
        OriginCenter: "Garhshankar_BangaRoad_D (Punjab)",
        FacilityCity: "Garhshanker",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2891,
        Pin: 207124,
        DispatchCenter: "Kasganj_GandhiKunj_D (Uttar Pradesh)",
        OriginCenter: "Kasganj_GandhiKunj_D (Uttar Pradesh)",
        FacilityCity: "Kasganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2892,
        Pin: 627807,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2893,
        Pin: 335065,
        DispatchCenter: "Sangaria_Wardno16_D (Rajasthan)",
        OriginCenter: "Sangaria_Wardno16_D (Rajasthan)",
        FacilityCity: "Sangaria",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2894,
        Pin: 583138,
        DispatchCenter: "Bellary_Mundargi_I (Karnataka)",
        OriginCenter: "Bellary_Mundargi_I (Karnataka)",
        FacilityCity: "Bellary",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2895,
        Pin: 181152,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2896,
        Pin: 388110,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2897,
        Pin: 686579,
        DispatchCenter: "Pala_Elappunkal_D (Kerala)",
        OriginCenter: "Pala_Elappunkal_D (Kerala)",
        FacilityCity: "Pala",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2898,
        Pin: 678547,
        DispatchCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        OriginCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        FacilityCity: "Pazhayannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2899,
        Pin: 243203,
        DispatchCenter: "Baheri_Mahadevpuram_D (Uttar Pradesh)",
        OriginCenter: "Baheri_Mahadevpuram_D (Uttar Pradesh)",
        FacilityCity: "Baheri",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2900,
        Pin: 413528,
        DispatchCenter: "Kaij_Knadichowk_DPP (Maharashtra)",
        OriginCenter: "Kaij_Knadichowk_DPP (Maharashtra)",
        FacilityCity: "Kaij",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2901,
        Pin: 415612,
        DispatchCenter: "Ratnagiri_GaneshNagar_D (Maharashtra)",
        OriginCenter: "Ratnagiri_GaneshNagar_D (Maharashtra)",
        FacilityCity: "Ratnagiri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2902,
        Pin: 691305,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2903,
        Pin: 212307,
        DispatchCenter: "Allahabad_Ghatwa_D (Uttar Pradesh)",
        OriginCenter: "Allahabad_Ghatwa_D (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2904,
        Pin: 621213,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2905,
        Pin: 516329,
        DispatchCenter: "Vempalli_Kathaluru_D (Andhra Pradesh)",
        OriginCenter: "Vempalli_Kathaluru_D (Andhra Pradesh)",
        FacilityCity: "Vempalli",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2906,
        Pin: 390019,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2907,
        Pin: 581355,
        DispatchCenter: "Siddapur_Rvndrangr_DPP (Karnataka)",
        OriginCenter: "Siddapur_Rvndrangr_DPP (Karnataka)",
        FacilityCity: "Siddapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2908,
        Pin: 124404,
        DispatchCenter: "Sonipat_Sector51_L (Haryana)",
        OriginCenter: "Sonipat_WazidpurSaboli_P (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2909,
        Pin: 523246,
        DispatchCenter: "Podili_Sravaniestate_D (Andhra Pradesh)",
        OriginCenter: "Podili_Sravaniestate_D (Andhra Pradesh)",
        FacilityCity: "Podili",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2910,
        Pin: 605766,
        DispatchCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        OriginCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        FacilityCity: "Thirukoilure",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2911,
        Pin: 637214,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2912,
        Pin: 852212,
        DispatchCenter: "Saharsa_NayaBzar_D (Bihar)",
        OriginCenter: "Saharsa_NayaBzar_D (Bihar)",
        FacilityCity: "Saharsa",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2913,
        Pin: 246444,
        DispatchCenter: "Chamoli_Karnaprayag_D (Uttarakhand)",
        OriginCenter: "Chamoli_Karnaprayag_D (Uttarakhand)",
        FacilityCity: "Chamoli",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2914,
        Pin: 844120,
        DispatchCenter: "Muzaffarpur_Chandrahati_D (Bihar)",
        OriginCenter: "Muzaffarpur_Chandrahati_D (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2915,
        Pin: 500034,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2916,
        Pin: 787033,
        DispatchCenter: "Narayanpur_Panbari_D (Assam)",
        OriginCenter: "Narayanpur_Panbari_D (Assam)",
        FacilityCity: "Narayanpur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2917,
        Pin: 143605,
        DispatchCenter: "Ajnala_Fatehgarhchurian_D (Punjab)",
        OriginCenter: "Ajnala_Fatehgarhchurian_D (Punjab)",
        FacilityCity: "Ajnala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2918,
        Pin: 629805,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2919,
        Pin: 221601,
        DispatchCenter: "Madhuban_Gayatripuram_DPP (Uttar Pradesh)",
        OriginCenter: "Madhuban_Gayatripuram_DPP (Uttar Pradesh)",
        FacilityCity: "Madhuban",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2920,
        Pin: 144022,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2921,
        Pin: 734104,
        DispatchCenter: "Darjeeling_Limbugaon_D (West Bengal)",
        OriginCenter: "Darjeeling_Limbugaon_D (West Bengal)",
        FacilityCity: "Darjeeling",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2922,
        Pin: 191123,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2923,
        Pin: 160005,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2924,
        Pin: 411001,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2925,
        Pin: 624210,
        DispatchCenter: "Kodaikanal_Mudliyrpurm_D (Tamil Nadu)",
        OriginCenter: "Kodaikanal_Mudliyrpurm_D (Tamil Nadu)",
        FacilityCity: "Kodaikanal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2926,
        Pin: 110098,
        DispatchCenter: "Delhi_RailYardRd_L (Delhi)",
        OriginCenter: "Delhi_RailYardRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2927,
        Pin: 521226,
        DispatchCenter: "Vissannapetaa_TiruvuruRd_D (Andhra Pradesh)",
        OriginCenter: "Vissannapetaa_TiruvuruRd_D (Andhra Pradesh)",
        FacilityCity: "Vissannapetaa",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2928,
        Pin: 788112,
        DispatchCenter: "Bhaga_Khashpur_D (Assam)",
        OriginCenter: "Bhaga_Khashpur_D (Assam)",
        FacilityCity: "Bhaga",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2929,
        Pin: 523205,
        DispatchCenter: "Markapur_VidyaNgr_D (Andhra Pradesh)",
        OriginCenter: "Markapur_VidyaNgr_D (Andhra Pradesh)",
        FacilityCity: "Markapur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2930,
        Pin: 389320,
        DispatchCenter: "Halol_DhavalPark_D (Gujarat)",
        OriginCenter: "Halol_DhavalPark_D (Gujarat)",
        FacilityCity: "Halol",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2931,
        Pin: 207001,
        DispatchCenter: "Etah_Dineshnagar_D (Uttar Pradesh)",
        OriginCenter: "Etah_Dineshnagar_D (Uttar Pradesh)",
        FacilityCity: "Etah",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2932,
        Pin: 689550,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2933,
        Pin: 621307,
        DispatchCenter: "Manapparai_AmmanNagar_D (Tamil Nadu)",
        OriginCenter: "Manapparai_AmmanNagar_D (Tamil Nadu)",
        FacilityCity: "Manapparai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2934,
        Pin: 209715,
        DispatchCenter: "Auraiya_Brahmngr_D (Uttar Pradesh)",
        OriginCenter: "Auraiya_Brahmngr_D (Uttar Pradesh)",
        FacilityCity: "Auraiya",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2935,
        Pin: 221204,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2936,
        Pin: 331026,
        DispatchCenter: "Malsisar_BsStdDPP_D (Rajasthan)",
        OriginCenter: "Malsisar_BsStdDPP_D (Rajasthan)",
        FacilityCity: "Malsisar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2937,
        Pin: 691312,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2938,
        Pin: 575019,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2939,
        Pin: 518010,
        DispatchCenter: "Kurnool_Viveknagar_D (Andhra Pradesh)",
        OriginCenter: "Kurnool_Viveknagar_D (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2940,
        Pin: 736180,
        DispatchCenter: "CoochBehar_GowalaPatti_D (West Bengal)",
        OriginCenter: "CoochBehar_GowalaPatti_D (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2941,
        Pin: 638106,
        DispatchCenter: "Dharapuram_Pirivu_D (Tamil Nadu)",
        OriginCenter: "Dharapuram_Pirivu_D (Tamil Nadu)",
        FacilityCity: "Dharapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2942,
        Pin: 311025,
        DispatchCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        OriginCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        FacilityCity: "Bhilwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2943,
        Pin: 495660,
        DispatchCenter: "Jaijaipur_BaradwarMarg_D (Chhattisgarh)",
        OriginCenter: "Jaijaipur_BaradwarMarg_D (Chhattisgarh)",
        FacilityCity: "Jaijaipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2944,
        Pin: 134101,
        DispatchCenter: "Parwanoo_Sector2_D (Himachal Pradesh)",
        OriginCenter: "Parwanoo_Sector2_D (Himachal Pradesh)",
        FacilityCity: "Parwanoo",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2945,
        Pin: 416520,
        DispatchCenter: "Oras_Ranbambuli_D (Maharashtra)",
        OriginCenter: "Oras_Ranbambuli_D (Maharashtra)",
        FacilityCity: "Oras",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 2946,
        Pin: 768222,
        DispatchCenter: "Kuchinda_Pdrpada_D (Orissa)",
        OriginCenter: "Kuchinda_Pdrpada_D (Orissa)",
        FacilityCity: "Kuchinda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2947,
        Pin: 173004,
        DispatchCenter: "PaontaSahib_Suryacolony_D (Himachal Pradesh)",
        OriginCenter: "PaontaSahib_Suryacolony_D (Himachal Pradesh)",
        FacilityCity: "Paonta Sahib",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2948,
        Pin: 388440,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2949,
        Pin: 712408,
        DispatchCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        OriginCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        FacilityCity: "Tarkeshwar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2950,
        Pin: 212502,
        DispatchCenter: "Soraon_Purabtola_D (Uttar Pradesh)",
        OriginCenter: "Soraon_Purabtola_D (Uttar Pradesh)",
        FacilityCity: "Soraon",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2951,
        Pin: 625102,
        DispatchCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        OriginCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        FacilityCity: "Melur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2952,
        Pin: 721159,
        DispatchCenter: "Jhargram_Gopiballavpur_DPP (West Bengal)",
        OriginCenter: "Jhargram_Gopiballavpur_DPP (West Bengal)",
        FacilityCity: "Jhargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2953,
        Pin: 680314,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2954,
        Pin: 680600,
        DispatchCenter: "Shoranur_Akamala_D (Kerala)",
        OriginCenter: "Shoranur_Akamala_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2955,
        Pin: 680711,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2956,
        Pin: 305401,
        DispatchCenter: "Nasirabad_RamlilaChowk_DPP (Rajasthan)",
        OriginCenter: "Nasirabad_RamlilaChowk_DPP (Rajasthan)",
        FacilityCity: "Nasirabad",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2957,
        Pin: 494635,
        DispatchCenter: "Bhanupratappur_Kanhargaon_D (Chhattisgarh)",
        OriginCenter: "Bhanupratappur_Kanhargaon_D (Chhattisgarh)",
        FacilityCity: "Bhanupratappur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 2958,
        Pin: 560105,
        DispatchCenter: "Bangalore_Bommasandra_L (Karnataka)",
        OriginCenter: "Bangalore_Bommasandra_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2959,
        Pin: 263621,
        DispatchCenter: "Dwarahat_Kalikholi_DPP (Uttarakhand)",
        OriginCenter: "Dwarahat_Kalikholi_DPP (Uttarakhand)",
        FacilityCity: "Dwarahat",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2960,
        Pin: 505504,
        DispatchCenter: "Mahadevpur_Nallagunta_D (Telangana)",
        OriginCenter: "Mahadevpur_Nallagunta_D (Telangana)",
        FacilityCity: "Mahadevpur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2961,
        Pin: 440033,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 2962,
        Pin: 271002,
        DispatchCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        OriginCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        FacilityCity: "Gonda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2963,
        Pin: 392005,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2964,
        Pin: 591120,
        DispatchCenter: "Khanapur_StatinRD_D (Karnataka)",
        OriginCenter: "Khanapur_StatinRD_D (Karnataka)",
        FacilityCity: "Khanapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2965,
        Pin: 342305,
        DispatchCenter: "Mathania_Central_DPP_2 (Rajasthan)",
        OriginCenter: "Mathania_Central_DPP_2 (Rajasthan)",
        FacilityCity: "Mathania",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2966,
        Pin: 796025,
        DispatchCenter: "Aizawl_Sairang_I (Mizoram)",
        OriginCenter: "Aizawl_Sairang_I (Mizoram)",
        FacilityCity: "Aizawl",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 2967,
        Pin: 679330,
        DispatchCenter: "Edavanna_MNRd_D (Kerala)",
        OriginCenter: "Edavanna_MNRd_D (Kerala)",
        FacilityCity: "Edavanna",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2968,
        Pin: 758038,
        DispatchCenter: "Barbil_Baspani_D (Orissa)",
        OriginCenter: "Barbil_Baspani_D (Orissa)",
        FacilityCity: "Barbil",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2969,
        Pin: 110401,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2970,
        Pin: 249193,
        DispatchCenter: "Uttarkashi_Kansen_D (Uttarakhand)",
        OriginCenter: "Uttarkashi_Kansen_D (Uttarakhand)",
        FacilityCity: "Uttarkashi",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2971,
        Pin: 670141,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2972,
        Pin: 312602,
        DispatchCenter: "Mandphiya_BhadsodaRoad_D (Rajasthan)",
        OriginCenter: "Mandphiya_BhadsodaRoad_D (Rajasthan)",
        FacilityCity: "Mandphiya",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2973,
        Pin: 622407,
        DispatchCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2974,
        Pin: 458775,
        DispatchCenter: "Bhanpura_Lod_D (Madhya Pradesh)",
        OriginCenter: "Bhanpura_Lod_D (Madhya Pradesh)",
        FacilityCity: "Bhanpura",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 2975,
        Pin: 328021,
        DispatchCenter: "Bari_MaharajBaghColony_D (Rajasthan)",
        OriginCenter: "Bari_MaharajBaghColony_D (Rajasthan)",
        FacilityCity: "Bari",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2976,
        Pin: 110504,
        DispatchCenter: "Delhi_RailYardRd_L (Delhi)",
        OriginCenter: "Delhi_RailYardRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2977,
        Pin: 628202,
        DispatchCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        OriginCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        FacilityCity: "Tiruchendur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2978,
        Pin: 786181,
        DispatchCenter: "Margherita_CivilHPL_D (Assam)",
        OriginCenter: "Margherita_CivilHPL_D (Assam)",
        FacilityCity: "Margherita",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2979,
        Pin: 500015,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2980,
        Pin: 507169,
        DispatchCenter: "Khammam_Mudigonda_H (Telangana)",
        OriginCenter: "Khammam_Mudigonda_H (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2981,
        Pin: 713172,
        DispatchCenter: "Panagarh_Kanksa_D (West Bengal)",
        OriginCenter: "Panagarh_Kanksa_D (West Bengal)",
        FacilityCity: "Panagarh",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 2982,
        Pin: 685505,
        DispatchCenter: "Mundakayam_Ellapara_D (Kerala)",
        OriginCenter: "Mundakayam_Ellapara_D (Kerala)",
        FacilityCity: "Mundakayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2983,
        Pin: 173202,
        DispatchCenter: "Dharampur_Kumarhatti_DPP (Himachal Pradesh)",
        OriginCenter: "Dharampur_Kumarhatti_DPP (Himachal Pradesh)",
        FacilityCity: "Dharampur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 2984,
        Pin: 482003,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 2985,
        Pin: 679588,
        DispatchCenter: "Valanchery_Central_D_1 (Kerala)",
        OriginCenter: "Valanchery_Central_D_1 (Kerala)",
        FacilityCity: "Valanchery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 2986,
        Pin: 506163,
        DispatchCenter: "Rayaparthi_Busstand_D (Telangana)",
        OriginCenter: "Rayaparthi_Busstand_D (Telangana)",
        FacilityCity: "Rayaparthi",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 2987,
        Pin: 285205,
        DispatchCenter: "Konch_Central_D_2 (Uttar Pradesh)",
        OriginCenter: "Konch_Central_D_2 (Uttar Pradesh)",
        FacilityCity: "Konch",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2988,
        Pin: 755003,
        DispatchCenter: "Chandipur_Keshpur_D (Orissa)",
        OriginCenter: "Chandipur_Keshpur_D (Orissa)",
        FacilityCity: "Chandipur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2989,
        Pin: 151005,
        DispatchCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        OriginCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        FacilityCity: "Bathinda",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2990,
        Pin: 670651,
        DispatchCenter: "Chavassery_Peravoor_D (Kerala)",
        OriginCenter: "Chavassery_Peravoor_D (Kerala)",
        FacilityCity: "Chavassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 2991,
        Pin: 387345,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 2992,
        Pin: 766027,
        DispatchCenter: "Muniguda_Ambodola_DPP (Orissa)",
        OriginCenter: "Muniguda_Ambodola_DPP (Orissa)",
        FacilityCity: "Muniguda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 2993,
        Pin: 643007,
        DispatchCenter: "Ooty_Westmere_D (Tamil Nadu)",
        OriginCenter: "Ooty_Westmere_D (Tamil Nadu)",
        FacilityCity: "Ooty",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2994,
        Pin: 324009,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 2995,
        Pin: 625107,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 2996,
        Pin: 192102,
        DispatchCenter: "Anantnag_QaziBagh_DPP (Jammu & Kashmir)",
        OriginCenter: "Anantnag_QaziBagh_DPP (Jammu & Kashmir)",
        FacilityCity: "Anantnag",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 2997,
        Pin: 273162,
        DispatchCenter: "Brijmanganj_Rattupur_DPP (Uttar Pradesh)",
        OriginCenter: "Brijmanganj_Rattupur_DPP (Uttar Pradesh)",
        FacilityCity: "Brijmanganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 2998,
        Pin: 382860,
        DispatchCenter: "Vadnagar_BsstdDPP_D (Gujarat)",
        OriginCenter: "Vadnagar_BsstdDPP_D (Gujarat)",
        FacilityCity: "Vadnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 2999,
        Pin: 624601,
        DispatchCenter: "Palani_Sathyanagar_D (Tamil Nadu)",
        OriginCenter: "Palani_Sathyanagar_D (Tamil Nadu)",
        FacilityCity: "Palani",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3000,
        Pin: 770042,
        DispatchCenter: "Koira_Bhanjapali_D (Orissa)",
        OriginCenter: "Koira_Bhanjapali_D (Orissa)",
        FacilityCity: "Koira",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3001,
        Pin: 827302,
        DispatchCenter: "Peterbar_GagiDPP_D (Jharkhand)",
        OriginCenter: "Peterbar_GagiDPP_D (Jharkhand)",
        FacilityCity: "Peterbar",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3002,
        Pin: 601203,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3003,
        Pin: 524230,
        DispatchCenter: "Udayagiri_Msjidcmplx_D (Andhra Pradesh)",
        OriginCenter: "Udayagiri_Msjidcmplx_D (Andhra Pradesh)",
        FacilityCity: "Udayagiri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3004,
        Pin: 627301,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3005,
        Pin: 517589,
        DispatchCenter: "PutturAP_Pichatur_D (Andhra Pradesh)",
        OriginCenter: "PutturAP_Pichatur_D (Andhra Pradesh)",
        FacilityCity: "Puttur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3006,
        Pin: 232221,
        DispatchCenter: "Renukoot_Murdhava_H (Uttar Pradesh)",
        OriginCenter: "Renukoot_Murdhava_H (Uttar Pradesh)",
        FacilityCity: "Renukoot",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3007,
        Pin: 249411,
        DispatchCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        OriginCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        FacilityCity: "Haridwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3008,
        Pin: 628102,
        DispatchCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        OriginCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        FacilityCity: "Tuticorin",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3009,
        Pin: 460666,
        DispatchCenter: "Multai_Birulbzr_D (Madhya Pradesh)",
        OriginCenter: "Multai_Birulbzr_D (Madhya Pradesh)",
        FacilityCity: "Multai",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3010,
        Pin: 530029,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3011,
        Pin: 496100,
        DispatchCenter: "Raigarh_Chhatamuda_D (Chhattisgarh)",
        OriginCenter: "Raigarh_Chhatamuda_D (Chhattisgarh)",
        FacilityCity: "Raigarh",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3012,
        Pin: 833102,
        DispatchCenter: "Chakradhar_Sitaram_D (Jharkhand)",
        OriginCenter: "Chakradhar_Sitaram_D (Jharkhand)",
        FacilityCity: "Chakradharpur",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3013,
        Pin: 384312,
        DispatchCenter: "Unjha_SatymComplx_D (Gujarat)",
        OriginCenter: "Unjha_SatymComplx_D (Gujarat)",
        FacilityCity: "Unjha",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3014,
        Pin: 535546,
        DispatchCenter: "Bobbili_Aguruvidi_D (Andhra Pradesh)",
        OriginCenter: "Bobbili_Aguruvidi_D (Andhra Pradesh)",
        FacilityCity: "Bobbili",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3015,
        Pin: 622301,
        DispatchCenter: "Karambakkudi_Central_D (Tamil Nadu)",
        OriginCenter: "Karambakkudi_Central_D (Tamil Nadu)",
        FacilityCity: "Karambakkudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3016,
        Pin: 591101,
        DispatchCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        OriginCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        FacilityCity: "Gokak",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3017,
        Pin: 522421,
        DispatchCenter: "Macherla_Ward15_D (Andhra Pradesh)",
        OriginCenter: "Macherla_Ward15_D (Andhra Pradesh)",
        FacilityCity: "Macherla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3018,
        Pin: 444803,
        DispatchCenter: "Daryapur_WakodDPP_D (Maharashtra)",
        OriginCenter: "Daryapur_WakodDPP_D (Maharashtra)",
        FacilityCity: "Daryapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3019,
        Pin: 801506,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3020,
        Pin: 754007,
        DispatchCenter: "Kalapathar_GoleiChhak_D (Orissa)",
        OriginCenter: "Kalapathar_GoleiChhak_D (Orissa)",
        FacilityCity: "Kalapathar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3021,
        Pin: 271209,
        DispatchCenter: "Itiathok_Birmapur_D (Uttar Pradesh)",
        OriginCenter: "Itiathok_Birmapur_D (Uttar Pradesh)",
        FacilityCity: "Itia Thok",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3022,
        Pin: 577440,
        DispatchCenter: "Thirthahalli_Melinakuruvali2_DPP (Karnataka)",
        OriginCenter: "Thirthahalli_Melinakuruvali2_DPP (Karnataka)",
        FacilityCity: "Thirthahalli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3023,
        Pin: 532445,
        DispatchCenter: "Palakonda_VadaiDPP_D (Andhra Pradesh)",
        OriginCenter: "Palakonda_VadaiDPP_D (Andhra Pradesh)",
        FacilityCity: "Palakonda",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3024,
        Pin: 363650,
        DispatchCenter: "Morbi_Tankara_D (Gujarat)",
        OriginCenter: "Morbi_Tankara_D (Gujarat)",
        FacilityCity: "Morbi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3025,
        Pin: 691331,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3026,
        Pin: 382016,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3027,
        Pin: 783347,
        DispatchCenter: "Dotma_Nawarbhita_DPP (Assam)",
        OriginCenter: "Dotma_Nawarbhita_DPP (Assam)",
        FacilityCity: "Dotma",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3028,
        Pin: 221004,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3029,
        Pin: 174033,
        DispatchCenter: "Jukhala_Rihan_DPP (Himachal Pradesh)",
        OriginCenter: "Jukhala_Rihan_DPP (Himachal Pradesh)",
        FacilityCity: "Jukhala",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3030,
        Pin: 413412,
        DispatchCenter: "Vairag_ShradadeviNgr_D (Maharashtra)",
        OriginCenter: "Vairag_ShradadeviNgr_D (Maharashtra)",
        FacilityCity: "Vairag",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3031,
        Pin: 303107,
        DispatchCenter: "Paota_HoliChowk_DPP (Rajasthan)",
        OriginCenter: "Paota_HoliChowk_DPP (Rajasthan)",
        FacilityCity: "Paota",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3032,
        Pin: 110118,
        DispatchCenter: "Delhi_RailYardRd_L (Delhi)",
        OriginCenter: "Delhi_RailYardRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3033,
        Pin: 804404,
        DispatchCenter: "Khizasarai_Lodipur_D (Bihar)",
        OriginCenter: "Khizasarai_Lodipur_D (Bihar)",
        FacilityCity: "Khizasarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3034,
        Pin: 500042,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3035,
        Pin: 673526,
        DispatchCenter: "Perambra_KayipramRd_D (Kerala)",
        OriginCenter: "Perambra_KayipramRd_D (Kerala)",
        FacilityCity: "Perambra",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3036,
        Pin: 121103,
        DispatchCenter: "Palwal_NewColony_D (Haryana)",
        OriginCenter: "Palwal_NewColony_D (Haryana)",
        FacilityCity: "Palwal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3037,
        Pin: 762104,
        DispatchCenter: "Balliguda_Simanbadi_DPP (Orissa)",
        OriginCenter: "Balliguda_Simanbadi_DPP (Orissa)",
        FacilityCity: "Balliguda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3038,
        Pin: 142030,
        DispatchCenter: "Jagraon_Grainmkt_D (Punjab)",
        OriginCenter: "Jagraon_Grainmkt_D (Punjab)",
        FacilityCity: "Jagraon",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3039,
        Pin: 480004,
        DispatchCenter: "Chhindwara_Linga_I (Madhya Pradesh)",
        OriginCenter: "Chhindwara_Linga_I (Madhya Pradesh)",
        FacilityCity: "Chhindwara",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3040,
        Pin: 781038,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3041,
        Pin: 431711,
        DispatchCenter: "Dharmabad_BalajiNgr_D (Maharashtra)",
        OriginCenter: "Dharmabad_BalajiNgr_D (Maharashtra)",
        FacilityCity: "Dharmabad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3042,
        Pin: 620101,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3043,
        Pin: 533241,
        DispatchCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        OriginCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        FacilityCity: "Amalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3044,
        Pin: 332036,
        DispatchCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        OriginCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3045,
        Pin: 581111,
        DispatchCenter: "Hirekerur_Raghavendracolony_D (Karnataka)",
        OriginCenter: "Hirekerur_Raghavendracolony_D (Karnataka)",
        FacilityCity: "Hirekerur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3046,
        Pin: 344001,
        DispatchCenter: "Barmer_Baldevnagar_DPP (Rajasthan)",
        OriginCenter: "Barmer_Baldevnagar_DPP (Rajasthan)",
        FacilityCity: "Barmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3047,
        Pin: 627421,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3048,
        Pin: 364005,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3049,
        Pin: 380051,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3050,
        Pin: 221706,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3051,
        Pin: 232108,
        DispatchCenter: "Chandauli_ShubsNGR_D (Uttar Pradesh)",
        OriginCenter: "Chandauli_ShubsNGR_D (Uttar Pradesh)",
        FacilityCity: "Chandauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3052,
        Pin: 522234,
        DispatchCenter: "Chilakaluripet_ChrlesChrch_DPP (Andhra Pradesh)",
        OriginCenter: "Chilakaluripet_ChrlesChrch_DPP (Andhra Pradesh)",
        FacilityCity: "Chilakaluripet",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3053,
        Pin: 676110,
        DispatchCenter: "Edappal_Nellissery_D (Kerala)",
        OriginCenter: "Edappal_Nellissery_D (Kerala)",
        FacilityCity: "Edappal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3054,
        Pin: 638512,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3055,
        Pin: 722112,
        DispatchCenter: "Sonamukhi_Central_DPP_1 (West Bengal)",
        OriginCenter: "Sonamukhi_Central_DPP_1 (West Bengal)",
        FacilityCity: "Sonamukhi",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3056,
        Pin: 152123,
        DispatchCenter: "Fazilka_Malkana_D (Punjab)",
        OriginCenter: "Fazilka_Malkana_D (Punjab)",
        FacilityCity: "Fazilka",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3057,
        Pin: 201003,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3058,
        Pin: 673528,
        DispatchCenter: "Perambra_Koorachundu_D (Kerala)",
        OriginCenter: "Perambra_Koorachundu_D (Kerala)",
        FacilityCity: "Perambra",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3059,
        Pin: 690563,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3060,
        Pin: 110026,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3061,
        Pin: 425519,
        DispatchCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        OriginCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        FacilityCity: "Bhusawal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3062,
        Pin: 621202,
        DispatchCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        OriginCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        FacilityCity: "Kulithalai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3063,
        Pin: 143526,
        DispatchCenter: "Gurdaspur_Civillines_D (Punjab)",
        OriginCenter: "Gurdaspur_Civillines_D (Punjab)",
        FacilityCity: "Gurdaspur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3064,
        Pin: 685501,
        DispatchCenter: "Mundakayam_Ellapara_D (Kerala)",
        OriginCenter: "Mundakayam_Ellapara_D (Kerala)",
        FacilityCity: "Mundakayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3065,
        Pin: 143114,
        DispatchCenter: "Tarsikka_Beas_D (Punjab)",
        OriginCenter: "Tarsikka_Beas_D (Punjab)",
        FacilityCity: "Beas",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3066,
        Pin: 625533,
        DispatchCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        OriginCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        FacilityCity: "Cumbum",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3067,
        Pin: 382840,
        DispatchCenter: "Vadnagar_BsstdDPP_D (Gujarat)",
        OriginCenter: "Vadnagar_BsstdDPP_D (Gujarat)",
        FacilityCity: "Vadnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3068,
        Pin: 246736,
        DispatchCenter: "Chandpur_NoorpurRd_D (Uttar Pradesh)",
        OriginCenter: "Chandpur_NoorpurRd_D (Uttar Pradesh)",
        FacilityCity: "Chandpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3069,
        Pin: 391130,
        DispatchCenter: "Bodeli_SatyamPlaza_DPP (Gujarat)",
        OriginCenter: "Bodeli_SatyamPlaza_DPP (Gujarat)",
        FacilityCity: "Bodeli",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3070,
        Pin: 629807,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3071,
        Pin: 689641,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3072,
        Pin: 412805,
        DispatchCenter: "Mahabaleshwar_BhilarTal_DPP (Maharashtra)",
        OriginCenter: "Mahabaleshwar_BhilarTal_DPP (Maharashtra)",
        FacilityCity: "Mahabaleshwar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3073,
        Pin: 413108,
        DispatchCenter: "Akluj_SumitraNgr_D (Maharashtra)",
        OriginCenter: "Akluj_SumitraNgr_D (Maharashtra)",
        FacilityCity: "Akluj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3074,
        Pin: 276306,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3075,
        Pin: 335062,
        DispatchCenter: "Sangaria_Wardno16_D (Rajasthan)",
        OriginCenter: "Sangaria_Wardno16_D (Rajasthan)",
        FacilityCity: "Sangaria",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3076,
        Pin: 518360,
        DispatchCenter: "Yemignur_Timmappacolony_D (Andhra Pradesh)",
        OriginCenter: "Yemignur_Timmappacolony_D (Andhra Pradesh)",
        FacilityCity: "Yemmiganur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3077,
        Pin: 534207,
        DispatchCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        FacilityCity: "Bhimavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3078,
        Pin: 788116,
        DispatchCenter: "Sonabarighat_Saidpur_DPP (Assam)",
        OriginCenter: "Sonabarighat_Saidpur_DPP (Assam)",
        FacilityCity: "Sonabarighat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3079,
        Pin: 382355,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3080,
        Pin: 473110,
        DispatchCenter: "Guna_Sadacolony_D (Madhya Pradesh)",
        OriginCenter: "Guna_Sadacolony_D (Madhya Pradesh)",
        FacilityCity: "Guna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3081,
        Pin: 243701,
        DispatchCenter: "Milak_Bhaisoori_D (Uttar Pradesh)",
        OriginCenter: "Milak_Bhaisoori_D (Uttar Pradesh)",
        FacilityCity: "Milak",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3082,
        Pin: 624101,
        DispatchCenter: "Kodaikanal_Mudliyrpurm_D (Tamil Nadu)",
        OriginCenter: "Kodaikanal_Mudliyrpurm_D (Tamil Nadu)",
        FacilityCity: "Kodaikanal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3083,
        Pin: 793003,
        DispatchCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        OriginCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3084,
        Pin: 455332,
        DispatchCenter: "Khategaon_ChohanClny_D (Madhya Pradesh)",
        OriginCenter: "Khategaon_ChohanClny_D (Madhya Pradesh)",
        FacilityCity: "Khategaon",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3085,
        Pin: 531162,
        DispatchCenter: "Bheemunipatnam_PstOfc_D (Andhra Pradesh)",
        OriginCenter: "Bheemunipatnam_PstOfc_D (Andhra Pradesh)",
        FacilityCity: "Bheemunipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3086,
        Pin: 486340,
        DispatchCenter: "Sohagi_Gambhirpur_D (Madhya Pradesh)",
        OriginCenter: "Sohagi_Gambhirpur_D (Madhya Pradesh)",
        FacilityCity: "Sohagi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3087,
        Pin: 390005,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3088,
        Pin: 176031,
        DispatchCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        OriginCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        FacilityCity: "Nadaun",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3089,
        Pin: 225208,
        DispatchCenter: "Ramnagar_Burhwal_D (Uttar Pradesh)",
        OriginCenter: "Ramnagar_Burhwal_D (Uttar Pradesh)",
        FacilityCity: "Ramnagar-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3090,
        Pin: 209217,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3091,
        Pin: 389240,
        DispatchCenter: "Santrampur_MahirCmplx_DPP (Gujarat)",
        OriginCenter: "Santrampur_MahirCmplx_DPP (Gujarat)",
        FacilityCity: "Santrampur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3092,
        Pin: 678010,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3093,
        Pin: 607802,
        DispatchCenter: "Neyveli_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Neyveli_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Neyveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3094,
        Pin: 622507,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3095,
        Pin: 142049,
        DispatchCenter: "Bagha_Samalsar_D (Punjab)",
        OriginCenter: "Bagha_Samalsar_D (Punjab)",
        FacilityCity: "Bagha purana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3096,
        Pin: 761052,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3097,
        Pin: 276205,
        DispatchCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3098,
        Pin: 333702,
        DispatchCenter: "Balaran_Ward2_DPP (Rajasthan)",
        OriginCenter: "Balaran_Ward2_DPP (Rajasthan)",
        FacilityCity: "Balaran",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3099,
        Pin: 835201,
        DispatchCenter: "Kolebira_Lachragarh_D (Jharkhand)",
        OriginCenter: "Kolebira_Lachragarh_D (Jharkhand)",
        FacilityCity: "Kolebira",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3100,
        Pin: 732143,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3101,
        Pin: 711108,
        DispatchCenter: "Howrah_Kamrangu_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3102,
        Pin: 143518,
        DispatchCenter: "Batala_Simbel_D (Punjab)",
        OriginCenter: "Batala_Simbel_D (Punjab)",
        FacilityCity: "Batala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3103,
        Pin: 625520,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3104,
        Pin: 636803,
        DispatchCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        OriginCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        FacilityCity: "Dharmapuri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3105,
        Pin: 416614,
        DispatchCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        OriginCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        FacilityCity: "Malvan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3106,
        Pin: 176082,
        DispatchCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        OriginCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3107,
        Pin: 580118,
        DispatchCenter: "Dharwad_Kamalpur_D (Karnataka)",
        OriginCenter: "Dharwad_Kamalpur_D (Karnataka)",
        FacilityCity: "Dharwad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3108,
        Pin: 841312,
        DispatchCenter: "Chhapra_Ramnagar_I (Bihar)",
        OriginCenter: "Chhapra_Ramnagar_I (Bihar)",
        FacilityCity: "Chhapra",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3109,
        Pin: 814115,
        DispatchCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        OriginCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        FacilityCity: "Deoghar",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3110,
        Pin: 695122,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3111,
        Pin: 393040,
        DispatchCenter: "Dediapada_SP_DPP (Gujarat)",
        OriginCenter: "Dediapada_SP_DPP (Gujarat)",
        FacilityCity: "Dediapada",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3112,
        Pin: 508223,
        DispatchCenter: "Thirumalagiri_MaliPuramRoad_D (Telangana)",
        OriginCenter: "Thirumalagiri_MaliPuramRoad_D (Telangana)",
        FacilityCity: "Thirumalagiri",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3113,
        Pin: 233301,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3114,
        Pin: 124513,
        DispatchCenter: "Meham_Ward13_D (Haryana)",
        OriginCenter: "Meham_Ward13_D (Haryana)",
        FacilityCity: "Meham",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3115,
        Pin: 505187,
        DispatchCenter: "Peddapalli_Rajivnagar_D (Telangana)",
        OriginCenter: "Peddapalli_Rajivnagar_D (Telangana)",
        FacilityCity: "Peddapalli",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3116,
        Pin: 182222,
        DispatchCenter: "Bhaderwah_Dandi_D (Jammu & Kashmir)",
        OriginCenter: "Bhaderwah_Dandi_D (Jammu & Kashmir)",
        FacilityCity: "Bhaderwah",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3117,
        Pin: 121106,
        DispatchCenter: "Hodal_Hasanpurchowk_D (Haryana)",
        OriginCenter: "Hodal_Hasanpurchowk_D (Haryana)",
        FacilityCity: "Hodal",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3118,
        Pin: 712249,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3119,
        Pin: 492015,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3120,
        Pin: 416713,
        DispatchCenter: "Rajapur_Sainagar_D (Maharashtra)",
        OriginCenter: "Rajapur_Sainagar_D (Maharashtra)",
        FacilityCity: "Rajapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3121,
        Pin: 843161,
        DispatchCenter: "Aurai_College_D (Bihar)",
        OriginCenter: "Aurai_College_D (Bihar)",
        FacilityCity: "Aurai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3122,
        Pin: 210502,
        DispatchCenter: "HamirpurUP_YamunaBdg_D (Uttar Pradesh)",
        OriginCenter: "HamirpurUP_YamunaBdg_D (Uttar Pradesh)",
        FacilityCity: "Hamirpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3123,
        Pin: 523190,
        DispatchCenter: "Addanki_Inkollu_D (Andhra Pradesh)",
        OriginCenter: "Addanki_Inkollu_D (Andhra Pradesh)",
        FacilityCity: "Addanki",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3124,
        Pin: 401102,
        DispatchCenter: "Saphale_NutanNgr_DPP (Maharashtra)",
        OriginCenter: "Saphale_NutanNgr_DPP (Maharashtra)",
        FacilityCity: "Saphale",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3125,
        Pin: 341509,
        DispatchCenter: "Kuchmancity_PratapNagar_D (Rajasthan)",
        OriginCenter: "Kuchmancity_PratapNagar_D (Rajasthan)",
        FacilityCity: "Kuchaman",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3126,
        Pin: 190020,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3127,
        Pin: 571450,
        DispatchCenter: "Mandya_Dc (Karnataka)",
        OriginCenter: "Mandya_Dc (Karnataka)",
        FacilityCity: "Mandya",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3128,
        Pin: 835213,
        DispatchCenter: "Lohardaga_Devcolony_D (Jharkhand)",
        OriginCenter: "Lohardaga_Devcolony_D (Jharkhand)",
        FacilityCity: "Lohardaga",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3129,
        Pin: 110501,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3130,
        Pin: 413521,
        DispatchCenter: "Nilanga_Panchalcolony_D (Maharashtra)",
        OriginCenter: "Nilanga_Panchalcolony_D (Maharashtra)",
        FacilityCity: "Nilanga",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3131,
        Pin: 625582,
        DispatchCenter: "Theni_Dharmathupatti_D (Tamil Nadu)",
        OriginCenter: "Theni_Dharmathupatti_D (Tamil Nadu)",
        FacilityCity: "Bodinayakanur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3132,
        Pin: 733121,
        DispatchCenter: "Kaliyaganj_Central_DPP_1 (West Bengal)",
        OriginCenter: "Kaliyaganj_Central_DPP_1 (West Bengal)",
        FacilityCity: "Kaliyaganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3133,
        Pin: 262308,
        DispatchCenter: "Khatima_Amaun_D (Uttarakhand)",
        OriginCenter: "Khatima_Amaun_D (Uttarakhand)",
        FacilityCity: "Khatima",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3134,
        Pin: 534435,
        DispatchCenter: "DwarakaTirumala_PoliceStn_D (Andhra Pradesh)",
        OriginCenter: "DwarakaTirumala_PoliceStn_D (Andhra Pradesh)",
        FacilityCity: "Dwaraka Tirumala",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3135,
        Pin: 227309,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3136,
        Pin: 414110,
        DispatchCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        OriginCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        FacilityCity: "Ahmednagar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3137,
        Pin: 305024,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3138,
        Pin: 248143,
        DispatchCenter: "Doiwala_BaksarWala_DPP (Uttarakhand)",
        OriginCenter: "Doiwala_BaksarWala_DPP (Uttarakhand)",
        FacilityCity: "Doiwala",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3139,
        Pin: 207502,
        DispatchCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        OriginCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        FacilityCity: "Aliganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3140,
        Pin: 608302,
        DispatchCenter: "Lalpet_Kattumannarkoil_D (Tamil Nadu)",
        OriginCenter: "Lalpet_Kattumannarkoil_D (Tamil Nadu)",
        FacilityCity: "Lalpet",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3141,
        Pin: 441807,
        DispatchCenter: "SadakArjuni_Central_D (Maharashtra)",
        OriginCenter: "SadakArjuni_Central_D (Maharashtra)",
        FacilityCity: "Sadak Arjuni",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3142,
        Pin: 229309,
        DispatchCenter: "Gauriganj_Jais_D (Uttar Pradesh)",
        OriginCenter: "Gauriganj_Jais_D (Uttar Pradesh)",
        FacilityCity: "Gauriganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3143,
        Pin: 574220,
        DispatchCenter: "Vitla_FawzBuldng_DPP (Karnataka)",
        OriginCenter: "Vitla_FawzBuldng_DPP (Karnataka)",
        FacilityCity: "Vitla",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3144,
        Pin: 303502,
        DispatchCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        OriginCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        FacilityCity: "Bandikui",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3145,
        Pin: 332028,
        DispatchCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        OriginCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3146,
        Pin: 180018,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3147,
        Pin: 360405,
        DispatchCenter: "Dhoraji_StationRd_DPP (Gujarat)",
        OriginCenter: "Dhoraji_StationRd_DPP (Gujarat)",
        FacilityCity: "Dhoraji",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3148,
        Pin: 403401,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3149,
        Pin: 431742,
        DispatchCenter: "Mukhed_AmbedkarChowk_DPP (Maharashtra)",
        OriginCenter: "Mukhed_AmbedkarChowk_DPP (Maharashtra)",
        FacilityCity: "Mukhed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3150,
        Pin: 389002,
        DispatchCenter: "Godhra_Soniwada_D (Gujarat)",
        OriginCenter: "Godhra_Soniwada_D (Gujarat)",
        FacilityCity: "Godhra",
        FacilityState: "Gujarat",
        Zone : "W1"
      },
      {
        SN: 3151,
        Pin: 360460,
        DispatchCenter: "Upleta_KolkiRd_DPP (Gujarat)",
        OriginCenter: "Upleta_KolkiRd_DPP (Gujarat)",
        FacilityCity: "Upleta",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3152,
        Pin: 680601,
        DispatchCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        OriginCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3153,
        Pin: 445206,
        DispatchCenter: "Umarkhed_HubeCplx_D (Maharashtra)",
        OriginCenter: "Umarkhed_HubeCplx_D (Maharashtra)",
        FacilityCity: "Umarkhed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3154,
        Pin: 422212,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3155,
        Pin: 587312,
        DispatchCenter: "Mahalingpur_Bagalkot_D (Karnataka)",
        OriginCenter: "Mahalingpur_Bagalkot_D (Karnataka)",
        FacilityCity: "Mahalingpur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3156,
        Pin: 251301,
        DispatchCenter: "Shamli_SalekChandVihar_D (Uttar Pradesh)",
        OriginCenter: "Shamli_SalekChandVihar_D (Uttar Pradesh)",
        FacilityCity: "Shamli",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3157,
        Pin: 600099,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3158,
        Pin: 700050,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3159,
        Pin: 636352,
        DispatchCenter: "Dharmapuri_Thoppur_D (Tamil Nadu)",
        OriginCenter: "Dharmapuri_Thoppur_D (Tamil Nadu)",
        FacilityCity: "Dharmapuri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3160,
        Pin: 500380,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3161,
        Pin: 144003,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3162,
        Pin: 277503,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3163,
        Pin: 509336,
        DispatchCenter: "Kosgi_Kodangal_D (Telangana)",
        OriginCenter: "Kosgi_Kodangal_D (Telangana)",
        FacilityCity: "Kosgi",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3164,
        Pin: 623407,
        DispatchCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        OriginCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        FacilityCity: "Thiruvadanai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3165,
        Pin: 686662,
        DispatchCenter: "Koothattukulam_Oliyappuram_D (Kerala)",
        OriginCenter: "Koothattukulam_Oliyappuram_D (Kerala)",
        FacilityCity: "Koothattukulam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3166,
        Pin: 641658,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3167,
        Pin: 583155,
        DispatchCenter: "Kurugodu_Bellary_D (Karnataka)",
        OriginCenter: "Kurugodu_Bellary_D (Karnataka)",
        FacilityCity: "Kurugodu",
        FacilityState: "Karnataka",
        Zone : "S1"
      },
      {
        SN: 3168,
        Pin: 722173,
        DispatchCenter: "Hura_Lalpur_DPP (West Bengal)",
        OriginCenter: "Hura_Lalpur_DPP (West Bengal)",
        FacilityCity: "Hura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3169,
        Pin: 768035,
        DispatchCenter: "Padampur_Melchhamunda_DPP (Orissa)",
        OriginCenter: "Padampur_Melchhamunda_DPP (Orissa)",
        FacilityCity: "Padampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3170,
        Pin: 585291,
        DispatchCenter: "Talikoti_Hunashagi_D (Karnataka)",
        OriginCenter: "Talikoti_Hunashagi_D (Karnataka)",
        FacilityCity: "Gulbarga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3171,
        Pin: 174002,
        DispatchCenter: "NainaDevi_Baroh_DPP (Himachal Pradesh)",
        OriginCenter: "NainaDevi_Baroh_DPP (Himachal Pradesh)",
        FacilityCity: "Naina Devi",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3172,
        Pin: 421004,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3173,
        Pin: 636104,
        DispatchCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        OriginCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        FacilityCity: "Attur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3174,
        Pin: 209725,
        DispatchCenter: "Kannauj_Kazitola_D (Uttar Pradesh)",
        OriginCenter: "Kannauj_Kazitola_D (Uttar Pradesh)",
        FacilityCity: "Kannauj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3175,
        Pin: 504294,
        DispatchCenter: "Tiryani_KomurambeemChk_DPP (Telangana)",
        OriginCenter: "Tiryani_KomurambeemChk_DPP (Telangana)",
        FacilityCity: "Tiryani",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3176,
        Pin: 691018,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3177,
        Pin: 742121,
        DispatchCenter: "Nowda_Amtala_DPP (West Bengal)",
        OriginCenter: "Nowda_Amtala_DPP (West Bengal)",
        FacilityCity: "Nowda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3178,
        Pin: 274806,
        DispatchCenter: "Deoria_Ramnathnagar_D (Uttar Pradesh)",
        OriginCenter: "Deoria_Ramnathnagar_D (Uttar Pradesh)",
        FacilityCity: "Deoria",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3179,
        Pin: 205151,
        DispatchCenter: "Shikohabad_Vijaynagar_D (Uttar Pradesh)",
        OriginCenter: "Shikohabad_Vijaynagar_D (Uttar Pradesh)",
        FacilityCity: "Shikohabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3180,
        Pin: 524304,
        DispatchCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        OriginCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        FacilityCity: "Atmakur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3181,
        Pin: 363110,
        DispatchCenter: "Surendranagar_GIDC_D (Gujarat)",
        OriginCenter: "Surendranagar_GIDC_D (Gujarat)",
        FacilityCity: "Surendranagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3182,
        Pin: 362215,
        DispatchCenter: "Mendarda_GopalNagar_DPP (Gujarat)",
        OriginCenter: "Mendarda_GopalNagar_DPP (Gujarat)",
        FacilityCity: "Mendarda",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3183,
        Pin: 110302,
        DispatchCenter: "Delhi_RailYardRd_L (Delhi)",
        OriginCenter: "Delhi_RailYardRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3184,
        Pin: 721166,
        DispatchCenter: "Lutunia_Bholenthtmpl_D (West Bengal)",
        OriginCenter: "Lutunia_Bholenthtmpl_D (West Bengal)",
        FacilityCity: "Lutunia",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3185,
        Pin: 723168,
        DispatchCenter: "Raghunathpur_Nutandih_D (West Bengal)",
        OriginCenter: "Raghunathpur_Nutandih_D (West Bengal)",
        FacilityCity: "Raghunathpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3186,
        Pin: 563162,
        DispatchCenter: "Bangarapet_AMPCClny_D (Karnataka)",
        OriginCenter: "Bangarapet_AMPCClny_D (Karnataka)",
        FacilityCity: "Bangarapet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3187,
        Pin: 227111,
        DispatchCenter: "Malihabad_Amanignj_DPP (Uttar Pradesh)",
        OriginCenter: "Malihabad_Amanignj_DPP (Uttar Pradesh)",
        FacilityCity: "Malihabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3188,
        Pin: 425004,
        DispatchCenter: "Jalgaon_MIDC_I (Maharashtra)",
        OriginCenter: "Jalgaon_MIDC_I (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3189,
        Pin: 845457,
        DispatchCenter: "Dhaka_PachpakriRd_D (Bihar)",
        OriginCenter: "Dhaka_PachpakriRd_D (Bihar)",
        FacilityCity: "Dhaka",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3190,
        Pin: 722165,
        DispatchCenter: "Bishnupur_StnRdDPP_D (West Bengal)",
        OriginCenter: "Bishnupur_StnRdDPP_D (West Bengal)",
        FacilityCity: "Bishnupur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3191,
        Pin: 761132,
        DispatchCenter: "Galleri_Central_DPP (Orissa)",
        OriginCenter: "Galleri_Central_DPP (Orissa)",
        FacilityCity: "Galleri",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3192,
        Pin: 441401,
        DispatchCenter: "Ramtek_ShivajiWard_D (Maharashtra)",
        OriginCenter: "Ramtek_ShivajiWard_D (Maharashtra)",
        FacilityCity: "Ramtek",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3193,
        Pin: 306021,
        DispatchCenter: "MarwarJunction_Karoliya_DPP (Rajasthan)",
        OriginCenter: "MarwarJunction_Karoliya_DPP (Rajasthan)",
        FacilityCity: "Marwar Junction",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3194,
        Pin: 607308,
        DispatchCenter: "Neyveli_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Neyveli_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Neyveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3195,
        Pin: 516129,
        DispatchCenter: "Rayachoti_Tsundupalli_D (Andhra Pradesh)",
        OriginCenter: "Rayachoti_Tsundupalli_D (Andhra Pradesh)",
        FacilityCity: "Rayachoti",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3196,
        Pin: 531081,
        DispatchCenter: "Koruprolu_Thimmapuram_D (Andhra Pradesh)",
        OriginCenter: "Koruprolu_Thimmapuram_D (Andhra Pradesh)",
        FacilityCity: "Koruprolu",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3197,
        Pin: 272206,
        DispatchCenter: "Naugarh_Trtribazar_D (Uttar Pradesh)",
        OriginCenter: "Naugarh_Trtribazar_D (Uttar Pradesh)",
        FacilityCity: "Naugarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3198,
        Pin: 690533,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3199,
        Pin: 782426,
        DispatchCenter: "Kampurtwn_Dknipht_D (Assam)",
        OriginCenter: "Kampurtwn_Dknipht_D (Assam)",
        FacilityCity: "Kampur Town",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3200,
        Pin: 799202,
        DispatchCenter: "Khowai_Officetilla_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Khowai",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3201,
        Pin: 796082,
        DispatchCenter: "Kolasib_DiakkawnVeng_D (Mizoram)",
        OriginCenter: "Kolasib_DiakkawnVeng_D (Mizoram)",
        FacilityCity: "Kolasib",
        FacilityState: "Mizoram",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3202,
        Pin: 500111,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3203,
        Pin: 204106,
        DispatchCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        OriginCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        FacilityCity: "Hathras",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3204,
        Pin: 712135,
        DispatchCenter: "Kalna_BsStdDPP_D (West Bengal)",
        OriginCenter: "Kalna_BsStdDPP_D (West Bengal)",
        FacilityCity: "Kalna",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3205,
        Pin: 360520,
        DispatchCenter: "Jamjodhpur_SubhashRd_DPP (Gujarat)",
        OriginCenter: "Jamjodhpur_SubhashRd_DPP (Gujarat)",
        FacilityCity: "Jamjodhpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3206,
        Pin: 391105,
        DispatchCenter: "Dabhoi_Central_DPP_3 (Gujarat)",
        OriginCenter: "Dabhoi_Central_DPP_3 (Gujarat)",
        FacilityCity: "Dabhoi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3207,
        Pin: 584126,
        DispatchCenter: "Devadurga_APMC_D (Karnataka)",
        OriginCenter: "Devadurga_APMC_D (Karnataka)",
        FacilityCity: "Devadurga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3208,
        Pin: 271306,
        DispatchCenter: "Balrampur_Utraula_D (Uttar Pradesh)",
        OriginCenter: "Balrampur_Utraula_D (Uttar Pradesh)",
        FacilityCity: "Balrampur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3209,
        Pin: 202394,
        DispatchCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        OriginCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        FacilityCity: "Anupshahar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3210,
        Pin: 423601,
        DispatchCenter: "Kopargaon_Murshatpur_D (Maharashtra)",
        OriginCenter: "Kopargaon_Murshatpur_D (Maharashtra)",
        FacilityCity: "Kopargaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3211,
        Pin: 482004,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3212,
        Pin: 203397,
        DispatchCenter: "Dibai_Galibpur_D (Uttar Pradesh)",
        OriginCenter: "Dibai_Galibpur_D (Uttar Pradesh)",
        FacilityCity: "Dibai",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3213,
        Pin: 688550,
        DispatchCenter: "Cochin_Aroor_D (Kerala)",
        OriginCenter: "Cochin_Aroor_D (Kerala)",
        FacilityCity: "Ezhupunna",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3214,
        Pin: 142052,
        DispatchCenter: "Ferozepur_DC (Punjab)",
        OriginCenter: "Ferozepur_DC (Punjab)",
        FacilityCity: "Firozpur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3215,
        Pin: 263158,
        DispatchCenter: "Bhimtal_BhowaliRd_D (Uttarakhand)",
        OriginCenter: "Bhimtal_BhowaliRd_D (Uttarakhand)",
        FacilityCity: "Bhimtal",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3216,
        Pin: 224139,
        DispatchCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        OriginCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        FacilityCity: "Jalalpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3217,
        Pin: 487771,
        DispatchCenter: "Gadarwara_MPward_D (Madhya Pradesh)",
        OriginCenter: "Gadarwara_MPward_D (Madhya Pradesh)",
        FacilityCity: "Gadarwara",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3218,
        Pin: 380009,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3219,
        Pin: 733208,
        DispatchCenter: "Islampure_Dharampur_D (West Bengal)",
        OriginCenter: "Islampure_Dharampur_D (West Bengal)",
        FacilityCity: "Islampure",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3220,
        Pin: 470442,
        DispatchCenter: "Barodia_Bandri_DPP (Madhya Pradesh)",
        OriginCenter: "Barodia_Bandri_DPP (Madhya Pradesh)",
        FacilityCity: "Barodia",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3221,
        Pin: 673326,
        DispatchCenter: "Koyilandy_Komathukara_D (Kerala)",
        OriginCenter: "Koyilandy_Komathukara_D (Kerala)",
        FacilityCity: "Koyilandy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3222,
        Pin: 691315,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3223,
        Pin: 176501,
        DispatchCenter: "GhatiBillan_Ban_DPP (Himachal Pradesh)",
        OriginCenter: "GhatiBillan_Ban_DPP (Himachal Pradesh)",
        FacilityCity: "Ghati Billan",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3224,
        Pin: 712247,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3225,
        Pin: 854108,
        DispatchCenter: "Korha_Zorabgani_D (Bihar)",
        OriginCenter: "Korha_Zorabgani_D (Bihar)",
        FacilityCity: "Korha",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3226,
        Pin: 454648,
        DispatchCenter: "Badnagar_Teacherscolony_D (Madhya Pradesh)",
        OriginCenter: "Badnagar_Teacherscolony_D (Madhya Pradesh)",
        FacilityCity: "Badnagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3227,
        Pin: 722122,
        DispatchCenter: "Bishnupur_StnRdDPP_D (West Bengal)",
        OriginCenter: "Bishnupur_StnRdDPP_D (West Bengal)",
        FacilityCity: "Bishnupur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3228,
        Pin: 604301,
        DispatchCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        OriginCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        FacilityCity: "Tindivanam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3229,
        Pin: 783370,
        DispatchCenter: "Kokrajhar_Borobhatarmari_DPP (Assam)",
        OriginCenter: "Kokrajhar_Borobhatarmari_DPP (Assam)",
        FacilityCity: "Kokrajhar",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3230,
        Pin: 502117,
        DispatchCenter: "Medak_RTCclny_D (Telangana)",
        OriginCenter: "Medak_RTCclny_D (Telangana)",
        FacilityCity: "Medak",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3231,
        Pin: 581143,
        DispatchCenter: "Ranebennur_MrthnjyaNgr_D (Karnataka)",
        OriginCenter: "Ranebennur_MrthnjyaNgr_D (Karnataka)",
        FacilityCity: "Ranebennur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3232,
        Pin: 110024,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3233,
        Pin: 507111,
        DispatchCenter: "Bhadrachalam_Srncolony_D (Telangana)",
        OriginCenter: "Bhadrachalam_Srncolony_D (Telangana)",
        FacilityCity: "Bhadrachalam",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3234,
        Pin: 262530,
        DispatchCenter: "Pithorgarh_Kumud_D (Uttarakhand)",
        OriginCenter: "Pithorgarh_Kumud_D (Uttarakhand)",
        FacilityCity: "Pithoragarh",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3235,
        Pin: 788804,
        DispatchCenter: "Kalain_Tarapur_D (Assam)",
        OriginCenter: "Kalain_Tarapur_D (Assam)",
        FacilityCity: "Kalain",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3236,
        Pin: 276125,
        DispatchCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3237,
        Pin: 754162,
        DispatchCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        OriginCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3238,
        Pin: 695501,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3239,
        Pin: 176060,
        DispatchCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        OriginCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        FacilityCity: "Kangra",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3240,
        Pin: 712614,
        DispatchCenter: "Arambag_BalibDPP_D (West Bengal)",
        OriginCenter: "Arambag_BalibDPP_D (West Bengal)",
        FacilityCity: "Arambag",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3241,
        Pin: 411036,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3242,
        Pin: 847115,
        DispatchCenter: "Madhubani_Sagarpur_D (Bihar)",
        OriginCenter: "Madhubani_Sagarpur_D (Bihar)",
        FacilityCity: "Madhubani",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3243,
        Pin: 413604,
        DispatchCenter: "Omerga_Ekondiroad_DPP (Maharashtra)",
        OriginCenter: "Omerga_Ekondiroad_DPP (Maharashtra)",
        FacilityCity: "Omerga",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3244,
        Pin: 752050,
        DispatchCenter: "Khurdha_Jatani_DPP (Orissa)",
        OriginCenter: "Khurdha_Jatani_DPP (Orissa)",
        FacilityCity: "Khurdha",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3245,
        Pin: 670107,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3246,
        Pin: 465687,
        DispatchCenter: "Pachore_Bhojpuriya_D (Madhya Pradesh)",
        OriginCenter: "Pachore_Bhojpuriya_D (Madhya Pradesh)",
        FacilityCity: "Pachore",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3247,
        Pin: 415616,
        DispatchCenter: "Ratnagiri_GaneshNagar_D (Maharashtra)",
        OriginCenter: "Ratnagiri_GaneshNagar_D (Maharashtra)",
        FacilityCity: "Ratnagiri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3248,
        Pin: 464224,
        DispatchCenter: "Bina_Srvdycwk_D (Madhya Pradesh)",
        OriginCenter: "Bina_Srvdycwk_D (Madhya Pradesh)",
        FacilityCity: "Bina",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3249,
        Pin: 383002,
        DispatchCenter: "Himmatnagar_Hadiyol_D (Gujarat)",
        OriginCenter: "Himmatnagar_Hadiyol_D (Gujarat)",
        FacilityCity: "Himmatnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3250,
        Pin: 678600,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3251,
        Pin: 638053,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3252,
        Pin: 691584,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3253,
        Pin: 305621,
        DispatchCenter: "Nasirabad_RamlilaChowk_DPP (Rajasthan)",
        OriginCenter: "Nasirabad_RamlilaChowk_DPP (Rajasthan)",
        FacilityCity: "Nasirabad",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3254,
        Pin: 342006,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3255,
        Pin: 520009,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3256,
        Pin: 516504,
        DispatchCenter: "Rayachoti_Chennamukkapalle_D (Andhra Pradesh)",
        OriginCenter: "Rayachoti_Chennamukkapalle_D (Andhra Pradesh)",
        FacilityCity: "Rayachoti",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3257,
        Pin: 632059,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3258,
        Pin: 523245,
        DispatchCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        OriginCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        FacilityCity: "Kanigiri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3259,
        Pin: 628704,
        DispatchCenter: "Eral_Nazareth_D (Tamil Nadu)",
        OriginCenter: "Eral_Nazareth_D (Tamil Nadu)",
        FacilityCity: "Eral",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3260,
        Pin: 110050,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3261,
        Pin: 788832,
        DispatchCenter: "Lumding_Langting_DPP (Assam)",
        OriginCenter: "Lumding_Langting_DPP (Assam)",
        FacilityCity: "Lumding",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3262,
        Pin: 700089,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3263,
        Pin: 411062,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3264,
        Pin: 785110,
        DispatchCenter: "Majuli_Kthnibri_D (Assam)",
        OriginCenter: "Majuli_Kthnibri_D (Assam)",
        FacilityCity: "Majuli",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3265,
        Pin: 611105,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3266,
        Pin: 277124,
        DispatchCenter: "Ballia_Sikandarpur_D (Uttar Pradesh)",
        OriginCenter: "Ballia_Sikandarpur_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3267,
        Pin: 721304,
        DispatchCenter: "Kharagpur_Nimpura_I (West Bengal)",
        OriginCenter: "Kharagpur_Nimpura_I (West Bengal)",
        FacilityCity: "Kharagpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3268,
        Pin: 148035,
        DispatchCenter: "Patran_Kahangarh_D (Punjab)",
        OriginCenter: "Patran_Kahangarh_D (Punjab)",
        FacilityCity: "Patran",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3269,
        Pin: 441214,
        DispatchCenter: "Umred_Narsala_D (Maharashtra)",
        OriginCenter: "Umred_Narsala_D (Maharashtra)",
        FacilityCity: "Umred",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3270,
        Pin: 462044,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3271,
        Pin: 225202,
        DispatchCenter: "Ramnagar_Burhwal_D (Uttar Pradesh)",
        OriginCenter: "Ramnagar_Burhwal_D (Uttar Pradesh)",
        FacilityCity: "Ramnagar-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3272,
        Pin: 591129,
        DispatchCenter: "Yaragatti_Teacherscol_DPP (Karnataka)",
        OriginCenter: "Yaragatti_Teacherscol_DPP (Karnataka)",
        FacilityCity: "Yaragatti",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3273,
        Pin: 177023,
        DispatchCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        OriginCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        FacilityCity: "Hamirpur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3274,
        Pin: 562117,
        DispatchCenter: "Kanakapura_Malagaalu_D (Karnataka)",
        OriginCenter: "Kanakapura_Malagaalu_D (Karnataka)",
        FacilityCity: "Kanakapura",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3275,
        Pin: 813205,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3276,
        Pin: 233223,
        DispatchCenter: "Aunrihar_JawahrNgr_D (Uttar Pradesh)",
        OriginCenter: "Aunrihar_JawahrNgr_D (Uttar Pradesh)",
        FacilityCity: "Aunrihar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3277,
        Pin: 688538,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3278,
        Pin: 804405,
        DispatchCenter: "Makhdumpur_Nawabganj_D (Bihar)",
        OriginCenter: "Makhdumpur_Nawabganj_D (Bihar)",
        FacilityCity: "Makhdumpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3279,
        Pin: 585411,
        DispatchCenter: "Bhalki_ShivajiChwk_DPP (Karnataka)",
        OriginCenter: "Bhalki_ShivajiChwk_DPP (Karnataka)",
        FacilityCity: "Bhalki",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3280,
        Pin: 389110,
        DispatchCenter: "Santrampur_MahirCmplx_DPP (Gujarat)",
        OriginCenter: "Santrampur_MahirCmplx_DPP (Gujarat)",
        FacilityCity: "Santrampur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3281,
        Pin: 144701,
        DispatchCenter: "Nakodar_Goldenavenue_D (Punjab)",
        OriginCenter: "Nakodar_Goldenavenue_D (Punjab)",
        FacilityCity: "Nakodar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3282,
        Pin: 413120,
        DispatchCenter: "Indapur_ChaitanyaPlaza_D (Maharashtra)",
        OriginCenter: "Indapur_ChaitanyaPlaza_D (Maharashtra)",
        FacilityCity: "Indapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3283,
        Pin: 262904,
        DispatchCenter: "Nighasan_PaliaKalan_D (Uttar Pradesh)",
        OriginCenter: "Nighasan_PaliaKalan_D (Uttar Pradesh)",
        FacilityCity: "Nighasan",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3284,
        Pin: 271602,
        DispatchCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        OriginCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        FacilityCity: "Gonda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3285,
        Pin: 384570,
        DispatchCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        OriginCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        FacilityCity: "Vijapur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3286,
        Pin: 493996,
        DispatchCenter: "Gariyaband_Dongari_D (Chhattisgarh)",
        OriginCenter: "Gariyaband_Dongari_D (Chhattisgarh)",
        FacilityCity: "Gariyaband",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3287,
        Pin: 387335,
        DispatchCenter: "Nadiad_Shantinagar_D (Gujarat)",
        OriginCenter: "Nadiad_Shantinagar_D (Gujarat)",
        FacilityCity: "Nadiad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3288,
        Pin: 222411,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3289,
        Pin: 758026,
        DispatchCenter: "Sukinda_Tamaka_DPP (Orissa)",
        OriginCenter: "Sukinda_Tamaka_DPP (Orissa)",
        FacilityCity: "Anandapur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3290,
        Pin: 246426,
        DispatchCenter: "Chamoli_CGRoad_D (Uttarakhand)",
        OriginCenter: "Chamoli_CGRoad_D (Uttarakhand)",
        FacilityCity: "Chamoli",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3291,
        Pin: 845315,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3292,
        Pin: 713337,
        DispatchCenter: "Asansol_Addacolony_D (West Bengal)",
        OriginCenter: "Asansol_Addacolony_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3293,
        Pin: 691579,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3294,
        Pin: 202527,
        DispatchCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        OriginCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        FacilityCity: "Anupshahar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3295,
        Pin: 495006,
        DispatchCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        OriginCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        FacilityCity: "Bilaspur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3296,
        Pin: 573215,
        DispatchCenter: "Belur_GendehalliRd_D (Karnataka)",
        OriginCenter: "Belur_GendehalliRd_D (Karnataka)",
        FacilityCity: "Belur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3297,
        Pin: 465674,
        DispatchCenter: "Narsinghgarh_Bypassroad_D (Madhya Pradesh)",
        OriginCenter: "Narsinghgarh_Bypassroad_D (Madhya Pradesh)",
        FacilityCity: "Narsinghgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3298,
        Pin: 500020,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3299,
        Pin: 688505,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3300,
        Pin: 506303,
        DispatchCenter: "Jangaon_Batkamakunta_D (Telangana)",
        OriginCenter: "Jangaon_Batkamakunta_D (Telangana)",
        FacilityCity: "Jangaon",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3301,
        Pin: 444903,
        DispatchCenter: "Morshi_Samarthacolony_D (Maharashtra)",
        OriginCenter: "Morshi_Samarthacolony_D (Maharashtra)",
        FacilityCity: "Morshi",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3302,
        Pin: 209625,
        DispatchCenter: "Farrukhabad_RamBanquet_D (Uttar Pradesh)",
        OriginCenter: "Farrukhabad_RamBanquet_D (Uttar Pradesh)",
        FacilityCity: "Farrukhabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3303,
        Pin: 629303,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3304,
        Pin: 416613,
        DispatchCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        OriginCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        FacilityCity: "Devgad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3305,
        Pin: 743357,
        DispatchCenter: "Kakdwip_KlngrDPP_D (West Bengal)",
        OriginCenter: "Kakdwip_KlngrDPP_D (West Bengal)",
        FacilityCity: "Kakdwip",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3306,
        Pin: 421501,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3307,
        Pin: 700016,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3308,
        Pin: 147103,
        DispatchCenter: "Patiala_UpkarNagar_D (Punjab)",
        OriginCenter: "Patiala_UpkarNagar_D (Punjab)",
        FacilityCity: "Patiala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3309,
        Pin: 440015,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3310,
        Pin: 683553,
        DispatchCenter: "Perumbavoor_Vattakattupady_D (Kerala)",
        OriginCenter: "Perumbavoor_Vattakattupady_D (Kerala)",
        FacilityCity: "Perumbavoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3311,
        Pin: 782403,
        DispatchCenter: "Morigaon_Nakhulagrant_D (Assam)",
        OriginCenter: "Morigaon_Nakhulagrant_D (Assam)",
        FacilityCity: "Morigaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3312,
        Pin: 144416,
        DispatchCenter: "Phillaur_Parkashnagar_D (Punjab)",
        OriginCenter: "Phillaur_Parkashnagar_D (Punjab)",
        FacilityCity: "Phillaur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3313,
        Pin: 444709,
        DispatchCenter: "Dhmngaon_Bypass_DPP (Maharashtra)",
        OriginCenter: "Dhmngaon_Bypass_DPP (Maharashtra)",
        FacilityCity: "Dhamangaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3314,
        Pin: 223107,
        DispatchCenter: "Shahganj_RudhauliKalan_D (Uttar Pradesh)",
        OriginCenter: "Shahganj_RudhauliKalan_D (Uttar Pradesh)",
        FacilityCity: "Shahganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3315,
        Pin: 192231,
        DispatchCenter: "Anantnag_Kulgam_DPP (Jammu & Kashmir)",
        OriginCenter: "Anantnag_Kulgam_DPP (Jammu & Kashmir)",
        FacilityCity: "Anantnag",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3316,
        Pin: 431720,
        DispatchCenter: "Gangakher_VithalNgr_D (Maharashtra)",
        OriginCenter: "Gangakher_VithalNgr_D (Maharashtra)",
        FacilityCity: "Gangakher",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3317,
        Pin: 303001,
        DispatchCenter: "Dausa_Aandhi_D (Rajasthan)",
        OriginCenter: "Dausa_Aandhi_D (Rajasthan)",
        FacilityCity: "Dausa",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3318,
        Pin: 534460,
        DispatchCenter: "Chintalpudi_KMRd_D (Andhra Pradesh)",
        OriginCenter: "Chintalpudi_KMRd_D (Andhra Pradesh)",
        FacilityCity: "Chintalpudi",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3319,
        Pin: 811112,
        DispatchCenter: "Lakhisarai_Central_I_2 (Bihar)",
        OriginCenter: "Lakhisarai_Central_I_2 (Bihar)",
        FacilityCity: "Lakhisarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3320,
        Pin: 570024,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3321,
        Pin: 245403,
        DispatchCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        OriginCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        FacilityCity: "Siyana",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3322,
        Pin: 451665,
        DispatchCenter: "Sendhwa_JawaharGanj_I (Madhya Pradesh)",
        OriginCenter: "Sendhwa_JawaharGanj_I (Madhya Pradesh)",
        FacilityCity: "Sendhwa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3323,
        Pin: 623002,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3324,
        Pin: 177027,
        DispatchCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        OriginCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        FacilityCity: "Hamirpur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3325,
        Pin: 638327,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3326,
        Pin: 516006,
        DispatchCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3327,
        Pin: 522332,
        DispatchCenter: "Guntur_Amaravati_D (Andhra Pradesh)",
        OriginCenter: "Guntur_Amaravati_D (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3328,
        Pin: 689101,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3329,
        Pin: 492008,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3330,
        Pin: 679583,
        DispatchCenter: "Tirur_Perunthallur_D (Kerala)",
        OriginCenter: "Tirur_Perunthallur_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3331,
        Pin: 524001,
        DispatchCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        OriginCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3332,
        Pin: 517132,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3333,
        Pin: 734009,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3334,
        Pin: 847234,
        DispatchCenter: "Madhubani_NidhiChowk_D (Bihar)",
        OriginCenter: "Madhubani_NidhiChowk_D (Bihar)",
        FacilityCity: "Madhubani",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3335,
        Pin: 442306,
        DispatchCenter: "Pulgaon_RajPP_DPP (Maharashtra)",
        OriginCenter: "Pulgaon_RajPP_DPP (Maharashtra)",
        FacilityCity: "Pulgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3336,
        Pin: 362002,
        DispatchCenter: "Junagadh_Dolatpara_I (Gujarat)",
        OriginCenter: "Junagadh_Dolatpara_I (Gujarat)",
        FacilityCity: "Junagadh",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3337,
        Pin: 394810,
        DispatchCenter: "Hansot_ChaudhryTmbr_DPP (Gujarat)",
        OriginCenter: "Hansot_ChaudhryTmbr_DPP (Gujarat)",
        FacilityCity: "Hansot",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3338,
        Pin: 241304,
        DispatchCenter: "Mishrikh_Naimishrnya_DPP (Uttar Pradesh)",
        OriginCenter: "Mishrikh_Naimishrnya_DPP (Uttar Pradesh)",
        FacilityCity: "Mishrikh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3339,
        Pin: 281002,
        DispatchCenter: "Mathura_Palikheda_D (Uttar Pradesh)",
        OriginCenter: "Mathura_Palikheda_D (Uttar Pradesh)",
        FacilityCity: "Mathura",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3340,
        Pin: 110027,
        DispatchCenter: "Delhi_KirtiNagar_L (Delhi)",
        OriginCenter: "Delhi_KirtiNagar_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3341,
        Pin: 281302,
        DispatchCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        OriginCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        FacilityCity: "Hathras",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3342,
        Pin: 391107,
        DispatchCenter: "Dabhoi_Central_DPP_3 (Gujarat)",
        OriginCenter: "Dabhoi_Central_DPP_3 (Gujarat)",
        FacilityCity: "Dabhoi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3343,
        Pin: 690514,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3344,
        Pin: 799280,
        DispatchCenter: "Kailashahar_Ward4_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Kailashahar",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3345,
        Pin: 224201,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3346,
        Pin: 580006,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3347,
        Pin: 523182,
        DispatchCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        OriginCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        FacilityCity: "Ongole",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3348,
        Pin: 224210,
        DispatchCenter: "Tanda_HayatganjChowk_D (Uttar Pradesh)",
        OriginCenter: "Tanda_HayatganjChowk_D (Uttar Pradesh)",
        FacilityCity: "Tanda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3349,
        Pin: 207242,
        DispatchCenter: "Ganjdundwara_CanalRd_D (Uttar Pradesh)",
        OriginCenter: "Ganjdundwara_CanalRd_D (Uttar Pradesh)",
        FacilityCity: "Ganjdundwara",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3350,
        Pin: 389340,
        DispatchCenter: "Godhra_Soniwada_D (Gujarat)",
        OriginCenter: "Godhra_Soniwada_D (Gujarat)",
        FacilityCity: "Godhra",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3351,
        Pin: 473665,
        DispatchCenter: "Karera_DenidaRd_D (Madhya Pradesh)",
        OriginCenter: "Karera_DenidaRd_D (Madhya Pradesh)",
        FacilityCity: "Karera-MP",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3352,
        Pin: 524224,
        DispatchCenter: "Kavali_Pathuru_D (Andhra Pradesh)",
        OriginCenter: "Kavali_Pathuru_D (Andhra Pradesh)",
        FacilityCity: "Kavali",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3353,
        Pin: 506324,
        DispatchCenter: "Danthalapally_AmbdkrSt_D (Telangana)",
        OriginCenter: "Danthalapally_AmbdkrSt_D (Telangana)",
        FacilityCity: "Danthalapally",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3354,
        Pin: 535221,
        DispatchCenter: "SKota_VizainagaramRd_DPP (Andhra Pradesh)",
        OriginCenter: "SKota_VizainagaramRd_DPP (Andhra Pradesh)",
        FacilityCity: "S Kota",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3355,
        Pin: 175038,
        DispatchCenter: "Nihri_Bahli_DPP (Himachal Pradesh)",
        OriginCenter: "Nihri_Bahli_DPP (Himachal Pradesh)",
        FacilityCity: "Nihri",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3356,
        Pin: 244928,
        DispatchCenter: "BilaspurUP_SainiColony_D (Uttar Pradesh)",
        OriginCenter: "BilaspurUP_SainiColony_D (Uttar Pradesh)",
        FacilityCity: "Bilaspur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3357,
        Pin: 387380,
        DispatchCenter: "Nadiad_Shantinagar_D (Gujarat)",
        OriginCenter: "Nadiad_Shantinagar_D (Gujarat)",
        FacilityCity: "Nadiad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3358,
        Pin: 412407,
        DispatchCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        OriginCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        FacilityCity: "Ambegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3359,
        Pin: 629504,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3360,
        Pin: 524123,
        DispatchCenter: "Sullurupeta_Sainagar_D (Andhra Pradesh)",
        OriginCenter: "Sullurupeta_Sainagar_D (Andhra Pradesh)",
        FacilityCity: "Sullurupeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3361,
        Pin: 332311,
        DispatchCenter: "Laxmangarh_Wrd19DPP_D (Rajasthan)",
        OriginCenter: "Laxmangarh_Wrd19DPP_D (Rajasthan)",
        FacilityCity: "Laxmangarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3362,
        Pin: 683582,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3363,
        Pin: 341542,
        DispatchCenter: "Makrana_Mathabhar_D (Rajasthan)",
        OriginCenter: "Makrana_Mathabhar_D (Rajasthan)",
        FacilityCity: "Makrana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3364,
        Pin: 680006,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3365,
        Pin: 782137,
        DispatchCenter: "Kaliabor_Hatiakhowa_DPP (Assam)",
        OriginCenter: "Kaliabor_Hatiakhowa_DPP (Assam)",
        FacilityCity: "Kaliabor",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3366,
        Pin: 507164,
        DispatchCenter: "Sathupally_Ayyagaripeta_D (Telangana)",
        OriginCenter: "Sathupally_Ayyagaripeta_D (Telangana)",
        FacilityCity: "Sathupally",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3367,
        Pin: 852115,
        DispatchCenter: "Udakishanganj_ClgChwk_D (Bihar)",
        OriginCenter: "Udakishanganj_ClgChwk_D (Bihar)",
        FacilityCity: "Udakishanganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3368,
        Pin: 686560,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3369,
        Pin: 754020,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3370,
        Pin: 402202,
        DispatchCenter: "Murud_Janjira_D (Maharashtra)",
        OriginCenter: "Murud_Janjira_D (Maharashtra)",
        FacilityCity: "Murud",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3371,
        Pin: 689691,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3372,
        Pin: 676111,
        DispatchCenter: "Tirur_Talakkad_D (Kerala)",
        OriginCenter: "Tirur_Talakkad_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3373,
        Pin: 360008,
        DispatchCenter: "Rajkot_Amargadh_H (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3374,
        Pin: 362610,
        DispatchCenter: "Junagadh_Dolatpara_I (Gujarat)",
        OriginCenter: "Junagadh_Dolatpara_I (Gujarat)",
        FacilityCity: "Junagadh",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3375,
        Pin: 175017,
        DispatchCenter: "SundarNgr_BhojpurRd_D (Himachal Pradesh)",
        OriginCenter: "SundarNgr_BhojpurRd_D (Himachal Pradesh)",
        FacilityCity: "Sundar Nagar",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3376,
        Pin: 273014,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3377,
        Pin: 605108,
        DispatchCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        OriginCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        FacilityCity: "Viluppuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3378,
        Pin: 207247,
        DispatchCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        OriginCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        FacilityCity: "Aliganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3379,
        Pin: 284120,
        DispatchCenter: "Jhansi_Nandanpura_I (Uttar Pradesh)",
        OriginCenter: "Jhansi_Nandanpura_I (Uttar Pradesh)",
        FacilityCity: "Jhansi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3380,
        Pin: 753295,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Cuttack_Bilteruan_L (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        Zone : "NE"
      },
      {
        SN: 3381,
        Pin: 532242,
        DispatchCenter: "Palasa_Mandapam_D (Andhra Pradesh)",
        OriginCenter: "Palasa_Mandapam_D (Andhra Pradesh)",
        FacilityCity: "Palasa",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3382,
        Pin: 486885,
        DispatchCenter: "Singrauli_Waidhan2_D (Madhya Pradesh)",
        OriginCenter: "Singrauli_Waidhan2_D (Madhya Pradesh)",
        FacilityCity: "Singrauli",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3383,
        Pin: 560093,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3384,
        Pin: 247231,
        DispatchCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        OriginCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        FacilityCity: "Saharanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3385,
        Pin: 124303,
        DispatchCenter: "Rohtak_IndustrialArea_D (Haryana)",
        OriginCenter: "Rohtak_IndustrialArea_D (Haryana)",
        FacilityCity: "Rohtak",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3386,
        Pin: 624312,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3387,
        Pin: 226003,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3388,
        Pin: 400007,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3389,
        Pin: 481224,
        DispatchCenter: "Balaghat_Kirnapur_DPP (Madhya Pradesh)",
        OriginCenter: "Balaghat_Kirnapur_DPP (Madhya Pradesh)",
        FacilityCity: "Balaghat",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3390,
        Pin: 722132,
        DispatchCenter: "Bankura_Chhatna_DPP (West Bengal)",
        OriginCenter: "Bankura_Chhatna_DPP (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3391,
        Pin: 515521,
        DispatchCenter: "Galiveedu_Masjid_DPP (Andhra Pradesh)",
        OriginCenter: "Galiveedu_Masjid_DPP (Andhra Pradesh)",
        FacilityCity: "Galiveedu",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3392,
        Pin: 637105,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3393,
        Pin: 395210,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3394,
        Pin: 251318,
        DispatchCenter: "Budhana_BhagatSinghPark_D (Uttar Pradesh)",
        OriginCenter: "Budhana_BhagatSinghPark_D (Uttar Pradesh)",
        FacilityCity: "Budhana",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3395,
        Pin: 304503,
        DispatchCenter: "Malpura_SadatMohalla_DPP (Rajasthan)",
        OriginCenter: "Malpura_SadatMohalla_DPP (Rajasthan)",
        FacilityCity: "Malpura",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3396,
        Pin: 628907,
        DispatchCenter: "Vilathikulam_Thoothukudi_DPP (Tamil Nadu)",
        OriginCenter: "Vilathikulam_Thoothukudi_DPP (Tamil Nadu)",
        FacilityCity: "Vilathikulam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3397,
        Pin: 341305,
        DispatchCenter: "Didwana_TransportNgr_DPP (Rajasthan)",
        OriginCenter: "Didwana_TransportNgr_DPP (Rajasthan)",
        FacilityCity: "Didwana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3398,
        Pin: 518101,
        DispatchCenter: "Srisailam_Sundipenta2_D (Andhra Pradesh)",
        OriginCenter: "Srisailam_Sundipenta2_D (Andhra Pradesh)",
        FacilityCity: "Srisailam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3399,
        Pin: 766118,
        DispatchCenter: "Sinapali_Snp_D (Orissa)",
        OriginCenter: "Sinapali_Snp_D (Orissa)",
        FacilityCity: "Sinapali",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3400,
        Pin: 493335,
        DispatchCenter: "Jaijaipur_Shivrinarayan_DPP (Chhattisgarh)",
        OriginCenter: "Jaijaipur_Shivrinarayan_DPP (Chhattisgarh)",
        FacilityCity: "Jaijaipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3401,
        Pin: 273008,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3402,
        Pin: 743336,
        DispatchCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        OriginCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        FacilityCity: "Dakshin Barasat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3403,
        Pin: 586124,
        DispatchCenter: "Muddebihal_Hudcocolony_D (Karnataka)",
        OriginCenter: "Muddebihal_Hudcocolony_D (Karnataka)",
        FacilityCity: "Muddebihal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3404,
        Pin: 516203,
        DispatchCenter: "Cuddapah_Peddacheppalle_D (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Peddacheppalle_D (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3405,
        Pin: 307021,
        DispatchCenter: "Sirohi_Vedhaynathcolony_D (Rajasthan)",
        OriginCenter: "Sirohi_Vedhaynathcolony_D (Rajasthan)",
        FacilityCity: "Sirohi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3406,
        Pin: 495450,
        DispatchCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        OriginCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        FacilityCity: "Korba",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3407,
        Pin: 825322,
        DispatchCenter: "Bagodar_Bishungarh_DPP (Jharkhand)",
        OriginCenter: "Bagodar_Bishungarh_DPP (Jharkhand)",
        FacilityCity: "Bagodar",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3408,
        Pin: 637203,
        DispatchCenter: "Tiruchengode_Kailasmplym_D (Tamil Nadu)",
        OriginCenter: "Tiruchengode_Kailasmplym_D (Tamil Nadu)",
        FacilityCity: "Tiruchengode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3409,
        Pin: 625004,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3410,
        Pin: 678631,
        DispatchCenter: "Karimba_Kaliyode_D (Kerala)",
        OriginCenter: "Karimba_Kaliyode_D (Kerala)",
        FacilityCity: "Karimba",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3411,
        Pin: 205152,
        DispatchCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        OriginCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        FacilityCity: "Shikohabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3412,
        Pin: 574313,
        DispatchCenter: "Sullia_Kanthamangala_D (Karnataka)",
        OriginCenter: "Sullia_Kanthamangala_D (Karnataka)",
        FacilityCity: "Sullia",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3413,
        Pin: 734011,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3414,
        Pin: 575018,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3415,
        Pin: 605009,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3416,
        Pin: 416507,
        DispatchCenter: "Halkarni_MajareKarve_D (Maharashtra)",
        OriginCenter: "Halkarni_MajareKarve_D (Maharashtra)",
        FacilityCity: "Halkarni",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3417,
        Pin: 160023,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3418,
        Pin: 583220,
        DispatchCenter: "HoovinaHadagali_Holagundhi_D (Karnataka)",
        OriginCenter: "HoovinaHadagali_Holagundhi_D (Karnataka)",
        FacilityCity: "Hoovina Hadagali",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3419,
        Pin: 560005,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3420,
        Pin: 676301,
        DispatchCenter: "Tirur_Perunthallur_D (Kerala)",
        OriginCenter: "Tirur_Perunthallur_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3421,
        Pin: 582204,
        DispatchCenter: "Gadag_Vidyanagar_D (Karnataka)",
        OriginCenter: "Gadag_Vidyanagar_D (Karnataka)",
        FacilityCity: "Gadag",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3422,
        Pin: 673047,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3423,
        Pin: 222111,
        DispatchCenter: "Badlapur_Barauli_D (Uttar Pradesh)",
        OriginCenter: "Badlapur_Barauli_D (Uttar Pradesh)",
        FacilityCity: "Badlapur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3424,
        Pin: 518385,
        DispatchCenter: "Guntakal_Central_D_2 (Andhra Pradesh)",
        OriginCenter: "Guntakal_Central_D_2 (Andhra Pradesh)",
        FacilityCity: "Guntakal",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3425,
        Pin: 563157,
        DispatchCenter: "Kolar_Vemagal_D (Karnataka)",
        OriginCenter: "Kolar_Vemagal_D (Karnataka)",
        FacilityCity: "Chintamani",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3426,
        Pin: 383330,
        DispatchCenter: "Bayad_PwnPlza_D (Gujarat)",
        OriginCenter: "Bayad_PwnPlza_D (Gujarat)",
        FacilityCity: "Bayad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3427,
        Pin: 431514,
        DispatchCenter: "Gangakher_VithalNgr_D (Maharashtra)",
        OriginCenter: "Gangakher_VithalNgr_D (Maharashtra)",
        FacilityCity: "Gangakher",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3428,
        Pin: 462028,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3429,
        Pin: 247776,
        DispatchCenter: "Shamli_SalekChandVihar_D (Uttar Pradesh)",
        OriginCenter: "Shamli_SalekChandVihar_D (Uttar Pradesh)",
        FacilityCity: "Shamli",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3430,
        Pin: 851211,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3431,
        Pin: 795003,
        DispatchCenter: "Imphal_Takyel_I (Manipur)",
        OriginCenter: "Imphal_Takyel_I (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3432,
        Pin: 176203,
        DispatchCenter: "Nurpur_Sadwan_DPP (Himachal Pradesh)",
        OriginCenter: "Nurpur_Sadwan_DPP (Himachal Pradesh)",
        FacilityCity: "Nurpur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3433,
        Pin: 425309,
        DispatchCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        OriginCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        FacilityCity: "Bhusawal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3434,
        Pin: 721437,
        DispatchCenter: "Kharagpur_Nimpura_I (West Bengal)",
        OriginCenter: "Kharagpur_Nimpura_I (West Bengal)",
        FacilityCity: "Kharagpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3435,
        Pin: 415506,
        DispatchCenter: "Vaduj_Satewdi_D (Maharashtra)",
        OriginCenter: "Vaduj_Satewdi_D (Maharashtra)",
        FacilityCity: "Vaduj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3436,
        Pin: 805102,
        DispatchCenter: "Nawada_Govindpur_D (Bihar)",
        OriginCenter: "Nawada_Govindpur_D (Bihar)",
        FacilityCity: "Nawada",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3437,
        Pin: 192233,
        DispatchCenter: "Anantnag_Dhpora_DPP (Jammu & Kashmir)",
        OriginCenter: "Anantnag_Dhpora_DPP (Jammu & Kashmir)",
        FacilityCity: "Pulwama",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3438,
        Pin: 225413,
        DispatchCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        OriginCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        FacilityCity: "Haidargarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3439,
        Pin: 686015,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3440,
        Pin: 271503,
        DispatchCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        OriginCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        FacilityCity: "Colonejganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3441,
        Pin: 500048,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3442,
        Pin: 737128,
        DispatchCenter: "TistaBazar_TeestaBridge_DPP (West Bengal)",
        OriginCenter: "TistaBazar_TeestaBridge_DPP (West Bengal)",
        FacilityCity: "Tista Bazar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3443,
        Pin: 670604,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3444,
        Pin: 673596,
        DispatchCenter: "Pulpally_Adikolli_D (Kerala)",
        OriginCenter: "Pulpally_Adikolli_D (Kerala)",
        FacilityCity: "Pulpally",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3445,
        Pin: 485446,
        DispatchCenter: "Nagod_Pipri_D (Madhya Pradesh)",
        OriginCenter: "Nagod_Pipri_D (Madhya Pradesh)",
        FacilityCity: "Nagod",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3446,
        Pin: 756128,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3447,
        Pin: 441801,
        DispatchCenter: "Gondia_Tirora_DPP (Maharashtra)",
        OriginCenter: "Gondia_Tirora_DPP (Maharashtra)",
        FacilityCity: "Gondia",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3448,
        Pin: 586113,
        DispatchCenter: "Bijapur_Bableshwar_D (Karnataka)",
        OriginCenter: "Bijapur_Bableshwar_D (Karnataka)",
        FacilityCity: "Bijapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3449,
        Pin: 731301,
        DispatchCenter: "Nanoor_Central_DPP_1 (West Bengal)",
        OriginCenter: "Nanoor_Central_DPP_1 (West Bengal)",
        FacilityCity: "Nanoor",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3450,
        Pin: 522619,
        DispatchCenter: "Chilakaluripet_ChrlesChrch_DPP (Andhra Pradesh)",
        OriginCenter: "Chilakaluripet_ChrlesChrch_DPP (Andhra Pradesh)",
        FacilityCity: "Chilakaluripet",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3451,
        Pin: 580114,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3452,
        Pin: 848134,
        DispatchCenter: "Samastipur_RhmprRdli_D (Bihar)",
        OriginCenter: "Samastipur_RhmprRdli_D (Bihar)",
        FacilityCity: "Samastipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3453,
        Pin: 635205,
        DispatchCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        OriginCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        FacilityCity: "Dharmapuri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3454,
        Pin: 507316,
        DispatchCenter: "Kothagudem_Sujathanagar_D (Telangana)",
        OriginCenter: "Kothagudem_Sujathanagar_D (Telangana)",
        FacilityCity: "Kothagudem",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3455,
        Pin: 504312,
        DispatchCenter: "Adilabad_KailashNagar_D (Telangana)",
        OriginCenter: "Adilabad_KailashNagar_D (Telangana)",
        FacilityCity: "Adilabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3456,
        Pin: 285201,
        DispatchCenter: "Konch_Central_D_2 (Uttar Pradesh)",
        OriginCenter: "Konch_Central_D_2 (Uttar Pradesh)",
        FacilityCity: "Konch",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3457,
        Pin: 626190,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3458,
        Pin: 475001,
        DispatchCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        OriginCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        FacilityCity: "Gwalior",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3459,
        Pin: 854333,
        DispatchCenter: "Araria_BusStand_D (Bihar)",
        OriginCenter: "Araria_BusStand_D (Bihar)",
        FacilityCity: "Araria",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3460,
        Pin: 829205,
        DispatchCenter: "Khalari_NayaDhauda_D (Jharkhand)",
        OriginCenter: "Khalari_NayaDhauda_D (Jharkhand)",
        FacilityCity: "Khalari-JR",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3461,
        Pin: 421006,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3462,
        Pin: 534407,
        DispatchCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        OriginCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        FacilityCity: "Tadepalligudem",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3463,
        Pin: 364522,
        DispatchCenter: "Savarkundla_Manibhai_DPP (Gujarat)",
        OriginCenter: "Savarkundla_Manibhai_DPP (Gujarat)",
        FacilityCity: "Savarkundla",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3464,
        Pin: 679322,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3465,
        Pin: 621009,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3466,
        Pin: 412106,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3467,
        Pin: 281204,
        DispatchCenter: "Vrindavan_Jait_D (Uttar Pradesh)",
        OriginCenter: "Vrindavan_Jait_D (Uttar Pradesh)",
        FacilityCity: "Vrindavan",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3468,
        Pin: 384311,
        DispatchCenter: "Unjha_SatymComplx_D (Gujarat)",
        OriginCenter: "Unjha_SatymComplx_D (Gujarat)",
        FacilityCity: "Unjha",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3469,
        Pin: 784176,
        DispatchCenter: "Biswanath_CharialiLIC_D (Assam)",
        OriginCenter: "Biswanath_CharialiLIC_D (Assam)",
        FacilityCity: "BiswanathCH",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3470,
        Pin: 273402,
        DispatchCenter: "Barhalganj_Lakhnapar_DPP (Uttar Pradesh)",
        OriginCenter: "Barhalganj_Lakhnapar_DPP (Uttar Pradesh)",
        FacilityCity: "Barhalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3471,
        Pin: 473001,
        DispatchCenter: "Guna_Shahpur_H (Madhya Pradesh)",
        OriginCenter: "Guna_Shahpur_H (Madhya Pradesh)",
        FacilityCity: "Guna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3472,
        Pin: 491558,
        DispatchCenter: "AmbagarhChowki_Ward14_D (Chhattisgarh)",
        OriginCenter: "AmbagarhChowki_Ward14_D (Chhattisgarh)",
        FacilityCity: "AmbagarhChowki",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3473,
        Pin: 843106,
        DispatchCenter: "Saraiya_Saraiya_D (Bihar)",
        OriginCenter: "Saraiya_Saraiya_D (Bihar)",
        FacilityCity: "Saraiya",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3474,
        Pin: 625562,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3475,
        Pin: 413213,
        DispatchCenter: "Solapur_Balepeth_I (Maharashtra)",
        OriginCenter: "Solapur_Balepeth_I (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3476,
        Pin: 143106,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3477,
        Pin: 416321,
        DispatchCenter: "Tasgaon_Trimurticolony_D (Maharashtra)",
        OriginCenter: "Tasgaon_Trimurticolony_D (Maharashtra)",
        FacilityCity: "Tasgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3478,
        Pin: 495555,
        DispatchCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        OriginCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        FacilityCity: "Bilaspur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3479,
        Pin: 403519,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3480,
        Pin: 335711,
        DispatchCenter: "Gharsana_Jaatcolony_D (Rajasthan)",
        OriginCenter: "Gharsana_Jaatcolony_D (Rajasthan)",
        FacilityCity: "Gharsana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3481,
        Pin: 307030,
        DispatchCenter: "Sumerpur_Dhorawas_DPP (Rajasthan)",
        OriginCenter: "Sumerpur_Dhorawas_DPP (Rajasthan)",
        FacilityCity: "Sumerpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3482,
        Pin: 638302,
        DispatchCenter: "Anthiyur_Thavittuplym_DPP (Tamil Nadu)",
        OriginCenter: "Anthiyur_Thavittuplym_DPP (Tamil Nadu)",
        FacilityCity: "Anthiyour",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3483,
        Pin: 670333,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3484,
        Pin: 641031,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3485,
        Pin: 533432,
        DispatchCenter: "Yerravaram_Somavaram_D (Andhra Pradesh)",
        OriginCenter: "Yerravaram_Somavaram_D (Andhra Pradesh)",
        FacilityCity: "Yerravaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3486,
        Pin: 303802,
        DispatchCenter: "Jaipur_VishwakarmaIndArea_L (Rajasthan)",
        OriginCenter: "Jaipur_VishwakarmaIndArea_L (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3487,
        Pin: 690101,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3488,
        Pin: 506102,
        DispatchCenter: "Mahabubabad_SnehaNagar_D (Telangana)",
        OriginCenter: "Mahabubabad_SnehaNagar_D (Telangana)",
        FacilityCity: "Mahabubabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3489,
        Pin: 306304,
        DispatchCenter: "Raipur_Mainmarket_D (Rajasthan)",
        OriginCenter: "Raipur_Mainmarket_D (Rajasthan)",
        FacilityCity: "Raipur-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3490,
        Pin: 712102,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3491,
        Pin: 679102,
        DispatchCenter: "Shoranur_Vaniyamkulam_D (Kerala)",
        OriginCenter: "Shoranur_Vaniyamkulam_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3492,
        Pin: 176217,
        DispatchCenter: "ShahpurHP_PoliceStn_DPP (Himachal Pradesh)",
        OriginCenter: "ShahpurHP_PoliceStn_DPP (Himachal Pradesh)",
        FacilityCity: "Shahpur-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3493,
        Pin: 741151,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3494,
        Pin: 785665,
        DispatchCenter: "Sivasagar_Pdinnagar_D (Assam)",
        OriginCenter: "Sivasagar_Pdinnagar_D (Assam)",
        FacilityCity: "Sivasagar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3495,
        Pin: 629702,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3496,
        Pin: 688570,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3497,
        Pin: 244715,
        DispatchCenter: "Ramnagar_Central_D_2 (Uttarakhand)",
        OriginCenter: "Ramnagar_Central_D_2 (Uttarakhand)",
        FacilityCity: "Ramnagar",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3498,
        Pin: 389235,
        DispatchCenter: "Lunawada_VrdhriRD_D (Gujarat)",
        OriginCenter: "Lunawada_VrdhriRD_D (Gujarat)",
        FacilityCity: "Lunawada",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3499,
        Pin: 586119,
        DispatchCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        OriginCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        FacilityCity: "Bijapur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3500,
        Pin: 380004,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3501,
        Pin: 180007,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3502,
        Pin: 491340,
        DispatchCenter: "Bhatapara_Nayaganj_D (Chhattisgarh)",
        OriginCenter: "Bhatapara_Nayaganj_D (Chhattisgarh)",
        FacilityCity: "Bhatapara",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3503,
        Pin: 628612,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3504,
        Pin: 614613,
        DispatchCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        OriginCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        FacilityCity: "Pattukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3505,
        Pin: 632605,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3506,
        Pin: 335021,
        DispatchCenter: "Raisinghnagar_Ward24_D (Rajasthan)",
        OriginCenter: "Raisinghnagar_Ward24_D (Rajasthan)",
        FacilityCity: "Raisinghnagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3507,
        Pin: 134152,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3508,
        Pin: 326036,
        DispatchCenter: "Jhalawar_BakaniDPP_D (Rajasthan)",
        OriginCenter: "Jhalawar_BakaniDPP_D (Rajasthan)",
        FacilityCity: "Jhalawar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3509,
        Pin: 821303,
        DispatchCenter: "Rohtas_Sundarganj_D (Bihar)",
        OriginCenter: "Rohtas_Sundarganj_D (Bihar)",
        FacilityCity: "Rohtas",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3510,
        Pin: 245304,
        DispatchCenter: "Noida_KheraDharmpura_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3511,
        Pin: 621204,
        DispatchCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        OriginCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        FacilityCity: "Kulithalai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3512,
        Pin: 585226,
        DispatchCenter: "Bidar_AdarshClny_D (Karnataka)",
        OriginCenter: "Bidar_AdarshClny_D (Karnataka)",
        FacilityCity: "Bidar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3513,
        Pin: 471606,
        DispatchCenter: "Khajuraho_Sevagram_DPP (Madhya Pradesh)",
        OriginCenter: "Khajuraho_Sevagram_DPP (Madhya Pradesh)",
        FacilityCity: "Khajuraho",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3514,
        Pin: 621220,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3515,
        Pin: 176088,
        DispatchCenter: "Kachhera_Timber_DPP (Himachal Pradesh)",
        OriginCenter: "Kachhera_Timber_DPP (Himachal Pradesh)",
        FacilityCity: "Kachhera",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3516,
        Pin: 451440,
        DispatchCenter: "Khargone_NutanNgr_D (Madhya Pradesh)",
        OriginCenter: "Khargone_NutanNgr_D (Madhya Pradesh)",
        FacilityCity: "Khargone",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3517,
        Pin: 121005,
        DispatchCenter: "Faridabad_Sector24_L (Haryana)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3518,
        Pin: 125049,
        DispatchCenter: "Hansi_HanumanColny_DPP (Haryana)",
        OriginCenter: "Hansi_HanumanColny_DPP (Haryana)",
        FacilityCity: "Hansi",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3519,
        Pin: 121102,
        DispatchCenter: "Faridabad_Sector24_L (Haryana)",
        OriginCenter: "Faridabad_Sector24_L (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3520,
        Pin: 509406,
        DispatchCenter: "Mahabubnagar_Zouqinagar_D (Telangana)",
        OriginCenter: "Mahabubnagar_Zouqinagar_D (Telangana)",
        FacilityCity: "Mahabubnagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3521,
        Pin: 695503,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3522,
        Pin: 110013,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3523,
        Pin: 825413,
        DispatchCenter: "Domchanch_Jhumritelaiya_D (Jharkhand)",
        OriginCenter: "Domchanch_Jhumritelaiya_D (Jharkhand)",
        FacilityCity: "Domchanch",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3524,
        Pin: 400067,
        DispatchCenter: "Mumbai_Kandivali_L (Maharashtra)",
        OriginCenter: "Mumbai_Kandivali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3525,
        Pin: 522005,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3526,
        Pin: 759101,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3527,
        Pin: 769002,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3528,
        Pin: 733209,
        DispatchCenter: "Dalkhola_Bhusamani_D (West Bengal)",
        OriginCenter: "Dalkhola_Bhusamani_D (West Bengal)",
        FacilityCity: "Dalkhola",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3529,
        Pin: 572136,
        DispatchCenter: "Pavagada_Banashankari_D (Karnataka)",
        OriginCenter: "Pavagada_Banashankari_D (Karnataka)",
        FacilityCity: "Pavagada",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3530,
        Pin: 509209,
        DispatchCenter: "Tadoor_Nagarkurnool_D (Telangana)",
        OriginCenter: "Tadoor_Nagarkurnool_D (Telangana)",
        FacilityCity: "Tadoor",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3531,
        Pin: 562121,
        DispatchCenter: "Harohalli_Gundurao_D (Karnataka)",
        OriginCenter: "Harohalli_Gundurao_D (Karnataka)",
        FacilityCity: "Harohalli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3532,
        Pin: 784010,
        DispatchCenter: "Tezpur_Dipota_H (Assam)",
        OriginCenter: "Tezpur_Dipota_H (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3533,
        Pin: 685571,
        DispatchCenter: "Adimali_AdimaliMkt_D (Kerala)",
        OriginCenter: "Adimali_AdimaliMkt_D (Kerala)",
        FacilityCity: "Adimali",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3534,
        Pin: 380007,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3535,
        Pin: 741139,
        DispatchCenter: "Nakashipara_Kanthalbaria_DPP (West Bengal)",
        OriginCenter: "Nakashipara_Kanthalbaria_DPP (West Bengal)",
        FacilityCity: "Nakashipara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3536,
        Pin: 416305,
        DispatchCenter: "Ashta_GordeChwk_D (Maharashtra)",
        OriginCenter: "Ashta_GordeChwk_D (Maharashtra)",
        FacilityCity: "AshtaSangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3537,
        Pin: 333023,
        DispatchCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        OriginCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        FacilityCity: "Jhunjhunu",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3538,
        Pin: 825325,
        DispatchCenter: "Kedla_Ghatotand_D (Jharkhand)",
        OriginCenter: "Kedla_Ghatotand_D (Jharkhand)",
        FacilityCity: "Kedla",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3539,
        Pin: 821004,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3540,
        Pin: 676106,
        DispatchCenter: "Tirur_Talakkad_D (Kerala)",
        OriginCenter: "Tirur_Talakkad_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3541,
        Pin: 396439,
        DispatchCenter: "Navsari_Vejalpore_D (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Navsari",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3542,
        Pin: 441906,
        DispatchCenter: "Bhandara_RjnaiDPP_D (Maharashtra)",
        OriginCenter: "Bhandara_RjnaiDPP_D (Maharashtra)",
        FacilityCity: "Bhandara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3543,
        Pin: 313207,
        DispatchCenter: "Kapasan_AdarshNgr_DPP (Rajasthan)",
        OriginCenter: "Kapasan_AdarshNgr_DPP (Rajasthan)",
        FacilityCity: "Kapasan",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3544,
        Pin: 142043,
        DispatchCenter: "Moga_Duneke_I (Punjab)",
        OriginCenter: "Moga_Duneke_I (Punjab)",
        FacilityCity: "Moga",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3545,
        Pin: 591110,
        DispatchCenter: "Saundatti_Bypassroad_D (Karnataka)",
        OriginCenter: "Saundatti_Bypassroad_D (Karnataka)",
        FacilityCity: "Saundatti",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3546,
        Pin: 416104,
        DispatchCenter: "Jaysingpur_Swapnanagari_D (Maharashtra)",
        OriginCenter: "Jaysingpur_Swapnanagari_D (Maharashtra)",
        FacilityCity: "Jaysingpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3547,
        Pin: 450661,
        DispatchCenter: "Khandwa_TilakNagar_D (Madhya Pradesh)",
        OriginCenter: "Khandwa_TilakNagar_D (Madhya Pradesh)",
        FacilityCity: "Khandwa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3548,
        Pin: 110006,
        DispatchCenter: "Delhi_KirtiNagar_L (Delhi)",
        OriginCenter: "Delhi_KirtiNagar_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3549,
        Pin: 390021,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3550,
        Pin: 141307,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3551,
        Pin: 531148,
        DispatchCenter: "Bheemunipatnam_PstOfc_D (Andhra Pradesh)",
        OriginCenter: "Bheemunipatnam_PstOfc_D (Andhra Pradesh)",
        FacilityCity: "Bheemunipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3552,
        Pin: 678571,
        DispatchCenter: "Akaloor_Lakdi_DPP (Kerala)",
        OriginCenter: "Akaloor_Lakdi_DPP (Kerala)",
        FacilityCity: "Akaloor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3553,
        Pin: 760006,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3554,
        Pin: 735216,
        DispatchCenter: "Birpara_BirparaClg_DPP (West Bengal)",
        OriginCenter: "Birpara_BirparaClg_DPP (West Bengal)",
        FacilityCity: "Birpara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3555,
        Pin: 560082,
        DispatchCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        OriginCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3556,
        Pin: 683105,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3557,
        Pin: 847112,
        DispatchCenter: "Madhubani_NidhiChowk_D (Bihar)",
        OriginCenter: "Madhubani_NidhiChowk_D (Bihar)",
        FacilityCity: "Madhubani",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3558,
        Pin: 362140,
        DispatchCenter: "Talala_SsnRdDPP_D (Gujarat)",
        OriginCenter: "Talala_SsnRdDPP_D (Gujarat)",
        FacilityCity: "Talala",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3559,
        Pin: 228178,
        DispatchCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        OriginCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        FacilityCity: "Jalalpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3560,
        Pin: 768038,
        DispatchCenter: "Bargarh_Sukuda_D (Orissa)",
        OriginCenter: "Bargarh_Sukuda_D (Orissa)",
        FacilityCity: "Bargarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3561,
        Pin: 396330,
        DispatchCenter: "Kadi_Jadavpura_L (Gujarat)",
        OriginCenter: "Kadi_Jadavpura_L (Gujarat)",
        FacilityCity: "Kadi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3562,
        Pin: 125037,
        DispatchCenter: "Hansi_HanumanColny_DPP (Haryana)",
        OriginCenter: "Hansi_HanumanColny_DPP (Haryana)",
        FacilityCity: "Hansi",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3563,
        Pin: 624005,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3564,
        Pin: 700052,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3565,
        Pin: 174021,
        DispatchCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        OriginCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        FacilityCity: "Bilaspur-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3566,
        Pin: 784507,
        DispatchCenter: "Udalguri_Dhansrighat_D (Assam)",
        OriginCenter: "Udalguri_Dhansrighat_D (Assam)",
        FacilityCity: "Udalguri",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3567,
        Pin: 614101,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3568,
        Pin: 461228,
        DispatchCenter: "Harda_Timarni_DPP (Madhya Pradesh)",
        OriginCenter: "Harda_Timarni_DPP (Madhya Pradesh)",
        FacilityCity: "Harda",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3569,
        Pin: 444805,
        DispatchCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        OriginCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        FacilityCity: "Paratwada",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3570,
        Pin: 444005,
        DispatchCenter: "Akola_Midcphase3_H (Maharashtra)",
        OriginCenter: "Akola_Midcphase3_H (Maharashtra)",
        FacilityCity: "Akola",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3571,
        Pin: 143506,
        DispatchCenter: "Batala_Simbel_D (Punjab)",
        OriginCenter: "Batala_Simbel_D (Punjab)",
        FacilityCity: "Batala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3572,
        Pin: 678555,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3573,
        Pin: 273013,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3574,
        Pin: 131030,
        DispatchCenter: "Sonipat_Sector51_L (Haryana)",
        OriginCenter: "Sonipat_WazidpurSaboli_P (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3575,
        Pin: 401702,
        DispatchCenter: "Dahanu_Ambemora_D (Maharashtra)",
        OriginCenter: "Dahanu_Ambemora_D (Maharashtra)",
        FacilityCity: "Dahanu",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3576,
        Pin: 784168,
        DispatchCenter: "Gohpur_Central_D_1 (Assam)",
        OriginCenter: "Gohpur_Central_D_1 (Assam)",
        FacilityCity: "Gohpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3577,
        Pin: 476221,
        DispatchCenter: "Morena_Sidhinagar_D (Madhya Pradesh)",
        OriginCenter: "Morena_Sidhinagar_D (Madhya Pradesh)",
        FacilityCity: "Morena",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3578,
        Pin: 425105,
        DispatchCenter: "Jalgaon_Omnagar_D (Maharashtra)",
        OriginCenter: "Jalgaon_Omnagar_D (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3579,
        Pin: 203412,
        DispatchCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        OriginCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        FacilityCity: "Siyana",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3580,
        Pin: 250001,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3581,
        Pin: 752105,
        DispatchCenter: "Pipili_BPRd_DPP (Orissa)",
        OriginCenter: "Pipili_BPRd_DPP (Orissa)",
        FacilityCity: "Pipili",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3582,
        Pin: 476444,
        DispatchCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        OriginCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        FacilityCity: "Gwalior",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3583,
        Pin: 171222,
        DispatchCenter: "Theog_Janogghat_D (Himachal Pradesh)",
        OriginCenter: "Theog_Janogghat_D (Himachal Pradesh)",
        FacilityCity: "Theog",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3584,
        Pin: 442404,
        DispatchCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        OriginCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        FacilityCity: "Chandrapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3585,
        Pin: 313902,
        DispatchCenter: "Sarada_Kachhota_DPP (Rajasthan)",
        OriginCenter: "Sarada_Kachhota_DPP (Rajasthan)",
        FacilityCity: "Sarada",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3586,
        Pin: 686540,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3587,
        Pin: 686670,
        DispatchCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        OriginCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        FacilityCity: "Muvattupuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3588,
        Pin: 785693,
        DispatchCenter: "Sonari_Nakachari_D (Assam)",
        OriginCenter: "Sonari_Nakachari_D (Assam)",
        FacilityCity: "Sonari",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3589,
        Pin: 364310,
        DispatchCenter: "Sihor_Vallabhipur2_DPP (Gujarat)",
        OriginCenter: "Sihor_Vallabhipur2_DPP (Gujarat)",
        FacilityCity: "Sihor",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3590,
        Pin: 735202,
        DispatchCenter: "Birpara_Binnaguri_D (West Bengal)",
        OriginCenter: "Birpara_Binnaguri_D (West Bengal)",
        FacilityCity: "Birpara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3591,
        Pin: 523316,
        DispatchCenter: "Markapur_VidyaNgr_D (Andhra Pradesh)",
        OriginCenter: "Markapur_VidyaNgr_D (Andhra Pradesh)",
        FacilityCity: "Markapur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3592,
        Pin: 534236,
        DispatchCenter: "Kaikaluru_Akividu_DPP (Andhra Pradesh)",
        OriginCenter: "Kaikaluru_Akividu_DPP (Andhra Pradesh)",
        FacilityCity: "Kaikaluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3593,
        Pin: 721139,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3594,
        Pin: 532005,
        DispatchCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        OriginCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        FacilityCity: "Srikakulam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3595,
        Pin: 146006,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3596,
        Pin: 670593,
        DispatchCenter: "Chavassery_Malapattam_D (Kerala)",
        OriginCenter: "Chavassery_Malapattam_D (Kerala)",
        FacilityCity: "Chavassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3597,
        Pin: 383120,
        DispatchCenter: "Himmatnagar_Hadiyol_D (Gujarat)",
        OriginCenter: "Himmatnagar_Hadiyol_D (Gujarat)",
        FacilityCity: "Himmatnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3598,
        Pin: 636141,
        DispatchCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        OriginCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        FacilityCity: "Attur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3599,
        Pin: 412217,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3600,
        Pin: 382740,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3601,
        Pin: 521138,
        DispatchCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        OriginCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        FacilityCity: "Pamarru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3602,
        Pin: 532591,
        DispatchCenter: "Salur_Eswar_D (Andhra Pradesh)",
        OriginCenter: "Salur_Eswar_D (Andhra Pradesh)",
        FacilityCity: "Salur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3603,
        Pin: 641106,
        DispatchCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        OriginCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3604,
        Pin: 691517,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3605,
        Pin: 362130,
        DispatchCenter: "Visavadar_JunagadhRd_DPP (Gujarat)",
        OriginCenter: "Visavadar_JunagadhRd_DPP (Gujarat)",
        FacilityCity: "Visavadar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3606,
        Pin: 786002,
        DispatchCenter: "Dibrugarh_Mohanbari_H (Assam)",
        OriginCenter: "Dibrugarh_Mohanbari_H (Assam)",
        FacilityCity: "Dibrugarh",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3607,
        Pin: 560092,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3608,
        Pin: 762103,
        DispatchCenter: "Balliguda_Patakhanda_DPP (Orissa)",
        OriginCenter: "Balliguda_Patakhanda_DPP (Orissa)",
        FacilityCity: "Balliguda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3609,
        Pin: 389250,
        DispatchCenter: "Santrampur_MahirCmplx_DPP (Gujarat)",
        OriginCenter: "Santrampur_MahirCmplx_DPP (Gujarat)",
        FacilityCity: "Santrampur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3610,
        Pin: 440024,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Chikhali_L (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3611,
        Pin: 304804,
        DispatchCenter: "DeoliRJ_AmbedkarColny_DPP (Rajasthan)",
        OriginCenter: "DeoliRJ_AmbedkarColny_DPP (Rajasthan)",
        FacilityCity: "Deoli-RJ",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3612,
        Pin: 508285,
        DispatchCenter: "Bhuvanagiri_Bhongir_D (Telangana)",
        OriginCenter: "Bhuvanagiri_Bhongir_D (Telangana)",
        FacilityCity: "Bhuvanagiri",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3613,
        Pin: 411030,
        DispatchCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        OriginCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3614,
        Pin: 591173,
        DispatchCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        OriginCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        FacilityCity: "Athani",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3615,
        Pin: 413701,
        DispatchCenter: "Shrigonda_Adhalgaon_D (Maharashtra)",
        OriginCenter: "Shrigonda_Adhalgaon_D (Maharashtra)",
        FacilityCity: "Shrigonda",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3616,
        Pin: 743456,
        DispatchCenter: "Minakhan_Amadpur_DPP (West Bengal)",
        OriginCenter: "Minakhan_Amadpur_DPP (West Bengal)",
        FacilityCity: "Minakhan",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3617,
        Pin: 570030,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3618,
        Pin: 400087,
        DispatchCenter: "Mumbai_Powai_L (Maharashtra)",
        OriginCenter: "Mumbai_Powai_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3619,
        Pin: 795127,
        DispatchCenter: "Kakching_Chandel_D (Manipur)",
        OriginCenter: "Kakching_Chandel_D (Manipur)",
        FacilityCity: "Kakching",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3620,
        Pin: 784165,
        DispatchCenter: "Narayanpur_Panbari_D (Assam)",
        OriginCenter: "Narayanpur_Panbari_D (Assam)",
        FacilityCity: "Narayanpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3621,
        Pin: 691581,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3622,
        Pin: 227808,
        DispatchCenter: "Sultanpur_Balpur_D (Uttar Pradesh)",
        OriginCenter: "Sultanpur_Balpur_D (Uttar Pradesh)",
        FacilityCity: "Sultanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3623,
        Pin: 396030,
        DispatchCenter: "Valsad_LimbaChwk_D (Gujarat)",
        OriginCenter: "Valsad_LimbaChwk_D (Gujarat)",
        FacilityCity: "Valsad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3624,
        Pin: 628720,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3625,
        Pin: 185152,
        DispatchCenter: "Sunderbani_Naushera_D (Jammu & Kashmir)",
        OriginCenter: "Sunderbani_Naushera_D (Jammu & Kashmir)",
        FacilityCity: "Sunderbani",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3626,
        Pin: 211009,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3627,
        Pin: 670101,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3628,
        Pin: 686515,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3629,
        Pin: 673315,
        DispatchCenter: "Balussery_Sivapuram_D (Kerala)",
        OriginCenter: "Balussery_Sivapuram_D (Kerala)",
        FacilityCity: "Balussery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3630,
        Pin: 500056,
        DispatchCenter: "Hyderabad_HemaNagar_L (Telangana)",
        OriginCenter: "Hyderabad_HemaNagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3631,
        Pin: 680026,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3632,
        Pin: 571807,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3633,
        Pin: 515001,
        DispatchCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        OriginCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3634,
        Pin: 629810,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3635,
        Pin: 533286,
        DispatchCenter: "Gokavaram_MuraliNagar_D (Andhra Pradesh)",
        OriginCenter: "Gokavaram_MuraliNagar_D (Andhra Pradesh)",
        FacilityCity: "Gokavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3636,
        Pin: 700023,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3637,
        Pin: 160053,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3638,
        Pin: 630411,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3639,
        Pin: 629855,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3640,
        Pin: 685503,
        DispatchCenter: "Mundakayam_Ellapara_D (Kerala)",
        OriginCenter: "Mundakayam_Ellapara_D (Kerala)",
        FacilityCity: "Mundakayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3641,
        Pin: 344705,
        DispatchCenter: "Sheo_Goonga_D (Rajasthan)",
        OriginCenter: "Sheo_Goonga_D (Rajasthan)",
        FacilityCity: "Sheo",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3642,
        Pin: 302032,
        DispatchCenter: "Jaipur_VishwakarmaIndArea_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3643,
        Pin: 628501,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3644,
        Pin: 441303,
        DispatchCenter: "Katol_Laxmingr_D (Maharashtra)",
        OriginCenter: "Katol_Laxmingr_D (Maharashtra)",
        FacilityCity: "Katol",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3645,
        Pin: 456005,
        DispatchCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        OriginCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        FacilityCity: "Ujjain",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3646,
        Pin: 262402,
        DispatchCenter: "Haldwani_Berapokhara_D (Uttarakhand)",
        OriginCenter: "Haldwani_Berapokhara_D (Uttarakhand)",
        FacilityCity: "Haldwani",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3647,
        Pin: 261501,
        DispatchCenter: "Lakhimpur_FunMall_D (Uttar Pradesh)",
        OriginCenter: "Lakhimpur_FunMall_D (Uttar Pradesh)",
        FacilityCity: "Lakhimpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3648,
        Pin: 431001,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3649,
        Pin: 395016,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3650,
        Pin: 301415,
        DispatchCenter: "RajgarhRJ_KarothRd_D (Rajasthan)",
        OriginCenter: "RajgarhRJ_KarothRd_D (Rajasthan)",
        FacilityCity: "Rajgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3651,
        Pin: 734429,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3652,
        Pin: 221108,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3653,
        Pin: 110402,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3654,
        Pin: 723201,
        DispatchCenter: "Bhaddi_Tanashi_DPP (West Bengal)",
        OriginCenter: "Bhaddi_Tanashi_DPP (West Bengal)",
        FacilityCity: "Bhaddi",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3655,
        Pin: 797103,
        DispatchCenter: "Dimapur_Sovima_I (Nagaland)",
        OriginCenter: "Dimapur_Sovima_I (Nagaland)",
        FacilityCity: "Dimapur",
        FacilityState: "Nagaland",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3656,
        Pin: 244222,
        DispatchCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        OriginCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        FacilityCity: "Moradabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3657,
        Pin: 608301,
        DispatchCenter: "Lalpet_Kattumannarkoil_D (Tamil Nadu)",
        OriginCenter: "Lalpet_Kattumannarkoil_D (Tamil Nadu)",
        FacilityCity: "Lalpet",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3658,
        Pin: 370201,
        DispatchCenter: "Gandhidham_BharatNagar_I (Gujarat)",
        OriginCenter: "Gandhidham_BharatNagar_I (Gujarat)",
        FacilityCity: "Gandhidham",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3659,
        Pin: 686612,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3660,
        Pin: 781104,
        DispatchCenter: "Hajo_Singimari_D (Assam)",
        OriginCenter: "Hajo_Singimari_D (Assam)",
        FacilityCity: "Hajo",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3661,
        Pin: 273004,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3662,
        Pin: 743313,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3663,
        Pin: 212652,
        DispatchCenter: "Khaga_GTRoad_D (Uttar Pradesh)",
        OriginCenter: "Khaga_GTRoad_D (Uttar Pradesh)",
        FacilityCity: "Khaga",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3664,
        Pin: 222142,
        DispatchCenter: "Kerakat_GasAgency_DPP (Uttar Pradesh)",
        OriginCenter: "Kerakat_GasAgency_DPP (Uttar Pradesh)",
        FacilityCity: "Kerakat",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3665,
        Pin: 689515,
        DispatchCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        OriginCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3666,
        Pin: 271001,
        DispatchCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        OriginCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        FacilityCity: "Gonda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3667,
        Pin: 624624,
        DispatchCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        OriginCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        FacilityCity: "Oddanchatram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3668,
        Pin: 784102,
        DispatchCenter: "Tezpur_Dipota_H (Assam)",
        OriginCenter: "Tezpur_Dipota_H (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3669,
        Pin: 524346,
        DispatchCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        OriginCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3670,
        Pin: 811311,
        DispatchCenter: "Lakhisarai_Central_I_2 (Bihar)",
        OriginCenter: "Lakhisarai_Central_I_2 (Bihar)",
        FacilityCity: "Lakhisarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3671,
        Pin: 222301,
        DispatchCenter: "Lambhua_Chaukiya_D (Uttar Pradesh)",
        OriginCenter: "Lambhua_Chaukiya_D (Uttar Pradesh)",
        FacilityCity: "Lambhua",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3672,
        Pin: 731233,
        DispatchCenter: "Rampurhat_Mansubamore_D (West Bengal)",
        OriginCenter: "Rampurhat_Mansubamore_D (West Bengal)",
        FacilityCity: "Rampurhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3673,
        Pin: 801111,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_PhulwariSharif_L (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3674,
        Pin: 583230,
        DispatchCenter: "Koppal_PragatiNagar_D (Karnataka)",
        OriginCenter: "Koppal_PragatiNagar_D (Karnataka)",
        FacilityCity: "Koppal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3675,
        Pin: 341508,
        DispatchCenter: "Kuchmancity_PratapNagar_D (Rajasthan)",
        OriginCenter: "Kuchmancity_PratapNagar_D (Rajasthan)",
        FacilityCity: "Kuchaman",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3676,
        Pin: 734315,
        DispatchCenter: "Kalimpong_Algarah_DPP (West Bengal)",
        OriginCenter: "Kalimpong_Algarah_DPP (West Bengal)",
        FacilityCity: "Kalimpong",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3677,
        Pin: 700017,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3678,
        Pin: 411021,
        DispatchCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        OriginCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3679,
        Pin: 755062,
        DispatchCenter: "Binjharpur_Ragunthapr_D (Orissa)",
        OriginCenter: "Binjharpur_Ragunthapr_D (Orissa)",
        FacilityCity: "Binjharpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3680,
        Pin: 248201,
        DispatchCenter: "Dehradun_Clement_L (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3681,
        Pin: 222303,
        DispatchCenter: "Lambhua_Chaukiya_D (Uttar Pradesh)",
        OriginCenter: "Lambhua_Chaukiya_D (Uttar Pradesh)",
        FacilityCity: "Lambhua",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3682,
        Pin: 843132,
        DispatchCenter: "Muzaffarpur_Motipur_D (Bihar)",
        OriginCenter: "Muzaffarpur_Motipur_D (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3683,
        Pin: 144040,
        DispatchCenter: "Nakodar_Goldenavenue_D (Punjab)",
        OriginCenter: "Nakodar_Goldenavenue_D (Punjab)",
        FacilityCity: "Nakodar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3684,
        Pin: 770038,
        DispatchCenter: "Lahunipara_Gudhiali_D (Orissa)",
        OriginCenter: "Lahunipara_Gudhiali_D (Orissa)",
        FacilityCity: "Lahunipara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3685,
        Pin: 678642,
        DispatchCenter: "Akaloor_Lakdi_DPP (Kerala)",
        OriginCenter: "Akaloor_Lakdi_DPP (Kerala)",
        FacilityCity: "Akaloor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3686,
        Pin: 742202,
        DispatchCenter: "Dhulian_RatanDPP_D (West Bengal)",
        OriginCenter: "Dhulian_RatanDPP_D (West Bengal)",
        FacilityCity: "Dhulian",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3687,
        Pin: 523169,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3688,
        Pin: 457550,
        DispatchCenter: "Ratlam_Sailana_D (Madhya Pradesh)",
        OriginCenter: "Ratlam_Sailana_D (Madhya Pradesh)",
        FacilityCity: "Ratlam",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3689,
        Pin: 800016,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3690,
        Pin: 523336,
        DispatchCenter: "CumbumAP_BhagyaNagar_D (Andhra Pradesh)",
        OriginCenter: "CumbumAP_BhagyaNagar_D (Andhra Pradesh)",
        FacilityCity: "CumbumAP",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3691,
        Pin: 473444,
        DispatchCenter: "Mungaoli_BhadurpurRd_DPP (Madhya Pradesh)",
        OriginCenter: "Mungaoli_BhadurpurRd_DPP (Madhya Pradesh)",
        FacilityCity: "Mungaoli",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3692,
        Pin: 685591,
        DispatchCenter: "Iddukki_Thadiyampad_D (Kerala)",
        OriginCenter: "Iddukki_Thadiyampad_D (Kerala)",
        FacilityCity: "Iddukki",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3693,
        Pin: 630554,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3694,
        Pin: 627411,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3695,
        Pin: 277502,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3696,
        Pin: 642117,
        DispatchCenter: "Valparai_MGRNagar_DPP (Tamil Nadu)",
        OriginCenter: "Valparai_MGRNagar_DPP (Tamil Nadu)",
        FacilityCity: "Valparai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3697,
        Pin: 581353,
        DispatchCenter: "Ankola_Baleguli_D (Karnataka)",
        OriginCenter: "Ankola_Baleguli_D (Karnataka)",
        FacilityCity: "Ankola",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3698,
        Pin: 612501,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3699,
        Pin: 805107,
        DispatchCenter: "Nawada_Govindpur_D (Bihar)",
        OriginCenter: "Nawada_Govindpur_D (Bihar)",
        FacilityCity: "Nawada",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3700,
        Pin: 723157,
        DispatchCenter: "Raghunathpur_Nutandih_D (West Bengal)",
        OriginCenter: "Raghunathpur_Nutandih_D (West Bengal)",
        FacilityCity: "Raghunathpur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3701,
        Pin: 603211,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3702,
        Pin: 302007,
        DispatchCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3703,
        Pin: 695510,
        DispatchCenter: "Neyatinkra_Kiliyoor_D (Kerala)",
        OriginCenter: "Neyatinkra_Kiliyoor_D (Kerala)",
        FacilityCity: "Neyyattinkara",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3704,
        Pin: 272105,
        DispatchCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        OriginCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        FacilityCity: "Basti",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3705,
        Pin: 576222,
        DispatchCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        OriginCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3706,
        Pin: 520015,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3707,
        Pin: 175042,
        DispatchCenter: "Sarkaghat_Khelag_D (Himachal Pradesh)",
        OriginCenter: "Sarkaghat_Khelag_D (Himachal Pradesh)",
        FacilityCity: "Sarkaghat",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3708,
        Pin: 603101,
        DispatchCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        OriginCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        FacilityCity: "Chengalpattu",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3709,
        Pin: 758085,
        DispatchCenter: "Kendujhar_Jamunalia_I (Orissa)",
        OriginCenter: "Kendujhar_Jamunalia_I (Orissa)",
        FacilityCity: "Kendujhar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3710,
        Pin: 396540,
        DispatchCenter: "Vansda_Chikatiya_D (Gujarat)",
        OriginCenter: "Vansda_Chikatiya_D (Gujarat)",
        FacilityCity: "Vansda",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3711,
        Pin: 695128,
        DispatchCenter: "Trivandrum_Neyatinkra_D (Kerala)",
        OriginCenter: "Trivandrum_Neyatinkra_D (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3712,
        Pin: 852113,
        DispatchCenter: "Madhepura_Azadnagar_D (Bihar)",
        OriginCenter: "Madhepura_Azadnagar_D (Bihar)",
        FacilityCity: "Madhepura",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3713,
        Pin: 143602,
        DispatchCenter: "Ajnala_Fatehgarhchurian_D (Punjab)",
        OriginCenter: "Ajnala_Fatehgarhchurian_D (Punjab)",
        FacilityCity: "Ajnala",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3714,
        Pin: 413222,
        DispatchCenter: "Vairag_ShradadeviNgr_D (Maharashtra)",
        OriginCenter: "Vairag_ShradadeviNgr_D (Maharashtra)",
        FacilityCity: "Vairag",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3715,
        Pin: 322220,
        DispatchCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        OriginCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3716,
        Pin: 625556,
        DispatchCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        OriginCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        FacilityCity: "Cumbum",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3717,
        Pin: 721451,
        DispatchCenter: "Belda_DntnRD_DPP (West Bengal)",
        OriginCenter: "Belda_DntnRD_DPP (West Bengal)",
        FacilityCity: "Belda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3718,
        Pin: 628751,
        DispatchCenter: "Eral_Nazareth_D (Tamil Nadu)",
        OriginCenter: "Eral_Nazareth_D (Tamil Nadu)",
        FacilityCity: "Eral",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3719,
        Pin: 630713,
        DispatchCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        OriginCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        FacilityCity: "Thiruvadanai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3720,
        Pin: 144805,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3721,
        Pin: 307024,
        DispatchCenter: "Aburoad_Rohida_DPP (Rajasthan)",
        OriginCenter: "Aburoad_Rohida_DPP (Rajasthan)",
        FacilityCity: "Abu Road",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3722,
        Pin: 560014,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3723,
        Pin: 502276,
        DispatchCenter: "Siddipet_Vemula_D (Telangana)",
        OriginCenter: "Siddipet_Vemula_D (Telangana)",
        FacilityCity: "Siddipet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3724,
        Pin: 221505,
        DispatchCenter: "Saidabad_Naibasti_D (Uttar Pradesh)",
        OriginCenter: "Saidabad_Naibasti_D (Uttar Pradesh)",
        FacilityCity: "Saidabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3725,
        Pin: 711115,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3726,
        Pin: 322024,
        DispatchCenter: "Bonli_Malarnachour_DPP (Rajasthan)",
        OriginCenter: "Bonli_Malarnachour_DPP (Rajasthan)",
        FacilityCity: "Bonli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3727,
        Pin: 361210,
        DispatchCenter: "Dhrol_PGVCLOfc_D (Gujarat)",
        OriginCenter: "Dhrol_PGVCLOfc_D (Gujarat)",
        FacilityCity: "Dhrol",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3728,
        Pin: 151203,
        DispatchCenter: "Faridkot_JatinderChwk_D (Punjab)",
        OriginCenter: "Faridkot_JatinderChwk_D (Punjab)",
        FacilityCity: "Faridkot",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3729,
        Pin: 534146,
        DispatchCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        OriginCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        FacilityCity: "Tadepalligudem",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3730,
        Pin: 461110,
        DispatchCenter: "Itarsi_AwnNgr_D (Madhya Pradesh)",
        OriginCenter: "Itarsi_AwnNgr_D (Madhya Pradesh)",
        FacilityCity: "Itarsi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3731,
        Pin: 841419,
        DispatchCenter: "Marhowarah_StatinRD_D (Bihar)",
        OriginCenter: "Marhowarah_StatinRD_D (Bihar)",
        FacilityCity: "Marhowarah",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3732,
        Pin: 781005,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3733,
        Pin: 757022,
        DispatchCenter: "Morada_Malihata_D (Orissa)",
        OriginCenter: "Morada_Malihata_D (Orissa)",
        FacilityCity: "Morada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3734,
        Pin: 600031,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3735,
        Pin: 688007,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3736,
        Pin: 244236,
        DispatchCenter: "Amroha_Kishangarh_D (Uttar Pradesh)",
        OriginCenter: "Amroha_Kishangarh_D (Uttar Pradesh)",
        FacilityCity: "Amroha",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3737,
        Pin: 591248,
        DispatchCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        OriginCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        FacilityCity: "Athani",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3738,
        Pin: 676556,
        DispatchCenter: "Tirur_Talakkad_D (Kerala)",
        OriginCenter: "Tirur_Talakkad_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3739,
        Pin: 757093,
        DispatchCenter: "Bangriposi_Majigan_D (Orissa)",
        OriginCenter: "Bangriposi_Majigan_D (Orissa)",
        FacilityCity: "Bangriposi",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3740,
        Pin: 342018,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3741,
        Pin: 630561,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3742,
        Pin: 843327,
        DispatchCenter: "Sitamarhi_Punaura_D (Bihar)",
        OriginCenter: "Sitamarhi_Punaura_D (Bihar)",
        FacilityCity: "Sitamarhi",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3743,
        Pin: 282005,
        DispatchCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        OriginCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        FacilityCity: "Agra",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3744,
        Pin: 799290,
        DispatchCenter: "Kailashahar_Kumarghat_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Kailashahar",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3745,
        Pin: 306301,
        DispatchCenter: "Bilara_Jaitaran_DPP (Rajasthan)",
        OriginCenter: "Bilara_Jaitaran_DPP (Rajasthan)",
        FacilityCity: "Bilara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3746,
        Pin: 563124,
        DispatchCenter: "Bagepalli_Chikballapur_D (Karnataka)",
        OriginCenter: "Bagepalli_Chikballapur_D (Karnataka)",
        FacilityCity: "Bagepalli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3747,
        Pin: 577321,
        DispatchCenter: "Shimoga_Bhandrahalli_D (Karnataka)",
        OriginCenter: "Shimoga_Bhandrahalli_D (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3748,
        Pin: 591305,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3749,
        Pin: 521170,
        DispatchCenter: "Madhira_Sunderaiahnagar_D (Telangana)",
        OriginCenter: "Madhira_Sunderaiahnagar_D (Telangana)",
        FacilityCity: "Madhira",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3750,
        Pin: 603201,
        DispatchCenter: "Melmaruvathur_IOBank_D (Tamil Nadu)",
        OriginCenter: "Melmaruvathur_IOBank_D (Tamil Nadu)",
        FacilityCity: "Melmaruvathur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3751,
        Pin: 152112,
        DispatchCenter: "Malout_DC (Punjab)",
        OriginCenter: "Malout_DC (Punjab)",
        FacilityCity: "Malout",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3752,
        Pin: 396130,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3753,
        Pin: 523371,
        DispatchCenter: "Markapur_VidyaNgr_D (Andhra Pradesh)",
        OriginCenter: "Markapur_VidyaNgr_D (Andhra Pradesh)",
        FacilityCity: "Markapur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3754,
        Pin: 816115,
        DispatchCenter: "Sahebganj_Ambadiha_D (Jharkhand)",
        OriginCenter: "Sahebganj_Ambadiha_D (Jharkhand)",
        FacilityCity: "Sahebganj",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3755,
        Pin: 642127,
        DispatchCenter: "Valparai_MGRNagar_DPP (Tamil Nadu)",
        OriginCenter: "Valparai_MGRNagar_DPP (Tamil Nadu)",
        FacilityCity: "Valparai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3756,
        Pin: 365645,
        DispatchCenter: "Visavadar_JunagadhRd_DPP (Gujarat)",
        OriginCenter: "Visavadar_JunagadhRd_DPP (Gujarat)",
        FacilityCity: "Visavadar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3757,
        Pin: 227106,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3758,
        Pin: 209729,
        DispatchCenter: "Chhibramau_TajpurRd_D (Uttar Pradesh)",
        OriginCenter: "Chhibramau_TajpurRd_D (Uttar Pradesh)",
        FacilityCity: "Chhibramau",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3759,
        Pin: 741152,
        DispatchCenter: "Nazirpur_Thanarpara_D (West Bengal)",
        OriginCenter: "Nazirpur_Thanarpara_D (West Bengal)",
        FacilityCity: "Nazirpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3760,
        Pin: 638656,
        DispatchCenter: "Dharapuram_Pirivu_D (Tamil Nadu)",
        OriginCenter: "Dharapuram_Pirivu_D (Tamil Nadu)",
        FacilityCity: "Dharapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3761,
        Pin: 711109,
        DispatchCenter: "Howrah_Kamrangu_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3762,
        Pin: 822120,
        DispatchCenter: "Kandi_Misraulia_D (Jharkhand)",
        OriginCenter: "Kandi_Misraulia_D (Jharkhand)",
        FacilityCity: "Kandi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3763,
        Pin: 607101,
        DispatchCenter: "Panruti_Thiruvathigai_D (Tamil Nadu)",
        OriginCenter: "Panruti_Thiruvathigai_D (Tamil Nadu)",
        FacilityCity: "Panruti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3764,
        Pin: 603303,
        DispatchCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        OriginCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        FacilityCity: "Chengalpattu",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3765,
        Pin: 502125,
        DispatchCenter: "Medak_RTCclny_D (Telangana)",
        OriginCenter: "Medak_RTCclny_D (Telangana)",
        FacilityCity: "Medak",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3766,
        Pin: 272151,
        DispatchCenter: "Rudhauli_Mudiyar_D (Uttar Pradesh)",
        OriginCenter: "Rudhauli_Mudiyar_D (Uttar Pradesh)",
        FacilityCity: "Rudhauli",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3767,
        Pin: 637303,
        DispatchCenter: "Sankari_Idappadi_D (Tamil Nadu)",
        OriginCenter: "Sankari_Idappadi_D (Tamil Nadu)",
        FacilityCity: "Sankari",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3768,
        Pin: 584170,
        DispatchCenter: "Raichur_Wadloor_I (Karnataka)",
        OriginCenter: "Raichur_Wadloor_I (Karnataka)",
        FacilityCity: "Raichur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3769,
        Pin: 277210,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3770,
        Pin: 532409,
        DispatchCenter: "Ranastalam_Seethamvalasa_D (Andhra Pradesh)",
        OriginCenter: "Ranastalam_Seethamvalasa_D (Andhra Pradesh)",
        FacilityCity: "Ranastalam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3771,
        Pin: 232330,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3772,
        Pin: 600072,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3773,
        Pin: 679572,
        DispatchCenter: "Valanchery_Central_D_1 (Kerala)",
        OriginCenter: "Valanchery_Central_D_1 (Kerala)",
        FacilityCity: "Valanchery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3774,
        Pin: 686603,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3775,
        Pin: 760011,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3776,
        Pin: 754013,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3777,
        Pin: 829126,
        DispatchCenter: "Patratu_Saundabasti_D (Jharkhand)",
        OriginCenter: "Patratu_Saundabasti_D (Jharkhand)",
        FacilityCity: "Patratu",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3778,
        Pin: 532404,
        DispatchCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        OriginCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        FacilityCity: "Srikakulam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3779,
        Pin: 843324,
        DispatchCenter: "Sitamarhi_Sursand_D (Bihar)",
        OriginCenter: "Sitamarhi_Sursand_D (Bihar)",
        FacilityCity: "Sitamarhi",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3780,
        Pin: 505304,
        DispatchCenter: "Sircilla_Gollapalli_D (Telangana)",
        OriginCenter: "Sircilla_Gollapalli_D (Telangana)",
        FacilityCity: "Yellareddipet",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3781,
        Pin: 273161,
        DispatchCenter: "Anandnagar_DhniDhla_DPP (Uttar Pradesh)",
        OriginCenter: "Anandnagar_DhniDhla_DPP (Uttar Pradesh)",
        FacilityCity: "Anandnagar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3782,
        Pin: 332042,
        DispatchCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        OriginCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3783,
        Pin: 787058,
        DispatchCenter: "Dhemaji_Ward6_D (Assam)",
        OriginCenter: "Dhemaji_Ward6_D (Assam)",
        FacilityCity: "Dhemaji",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3784,
        Pin: 226027,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3785,
        Pin: 841227,
        DispatchCenter: "Siwan_Atarsua_D (Bihar)",
        OriginCenter: "Siwan_Atarsua_D (Bihar)",
        FacilityCity: "Siwan",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3786,
        Pin: 506221,
        DispatchCenter: "Alair_Reddygudem_D (Telangana)",
        OriginCenter: "Alair_Reddygudem_D (Telangana)",
        FacilityCity: "Alair",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3787,
        Pin: 244504,
        DispatchCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        OriginCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        FacilityCity: "Moradabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3788,
        Pin: 470339,
        DispatchCenter: "Shahgarh_AsatiClc_DPP (Madhya Pradesh)",
        OriginCenter: "Shahgarh_AsatiClc_DPP (Madhya Pradesh)",
        FacilityCity: "Shahgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3789,
        Pin: 322202,
        DispatchCenter: "Karauli_Agrawalnagar_D (Rajasthan)",
        OriginCenter: "Karauli_Agrawalnagar_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3790,
        Pin: 673014,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3791,
        Pin: 754296,
        DispatchCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        OriginCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        FacilityCity: "Chandikhol",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3792,
        Pin: 795002,
        DispatchCenter: "Imphal_Takyel_I (Manipur)",
        OriginCenter: "Imphal_Takyel_I (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3793,
        Pin: 742185,
        DispatchCenter: "Raghunathganj_NH12_D (West Bengal)",
        OriginCenter: "Raghunathganj_NH12_D (West Bengal)",
        FacilityCity: "Raghunathganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3794,
        Pin: 413105,
        DispatchCenter: "Daund_Kurkumbh_L (Maharashtra)",
        OriginCenter: "Daund_Kurkumbh_L (Maharashtra)",
        FacilityCity: "Daund",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3795,
        Pin: 574248,
        DispatchCenter: "Sullia_Kanthamangala_D (Karnataka)",
        OriginCenter: "Sullia_Kanthamangala_D (Karnataka)",
        FacilityCity: "Sullia",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3796,
        Pin: 562122,
        DispatchCenter: "Bangalore_Koralur_L (Karnataka)",
        OriginCenter: "Bangalore_Soukyaroad_GW (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3797,
        Pin: 689643,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3798,
        Pin: 577244,
        DispatchCenter: "Sagara_Hotel_D (Karnataka)",
        OriginCenter: "Sagara_Hotel_D (Karnataka)",
        FacilityCity: "Sagara",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3799,
        Pin: 833217,
        DispatchCenter: "Noamundi_Lakhansai_D (Jharkhand)",
        OriginCenter: "Noamundi_Lakhansai_D (Jharkhand)",
        FacilityCity: "Noamundi",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3800,
        Pin: 533261,
        DispatchCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3801,
        Pin: 754218,
        DispatchCenter: "Kendrapara_Penthapal_D (Orissa)",
        OriginCenter: "Kendrapara_Penthapal_D (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3802,
        Pin: 243303,
        DispatchCenter: "Shahabad_Dhkriya_D (Uttar Pradesh)",
        OriginCenter: "Shahabad_Dhkriya_D (Uttar Pradesh)",
        FacilityCity: "Shahabad-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3803,
        Pin: 342603,
        DispatchCenter: "Gotan_Sadarbazar_DPP (Rajasthan)",
        OriginCenter: "Gotan_Sadarbazar_DPP (Rajasthan)",
        FacilityCity: "Gotan",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3804,
        Pin: 497116,
        DispatchCenter: "Ambikapur_Lakhanpur_DPP (Chhattisgarh)",
        OriginCenter: "Ambikapur_Lakhanpur_DPP (Chhattisgarh)",
        FacilityCity: "Ambikapur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3805,
        Pin: 532457,
        DispatchCenter: "KotturuAP_NivagamRd_DPP (Andhra Pradesh)",
        OriginCenter: "KotturuAP_NivagamRd_DPP (Andhra Pradesh)",
        FacilityCity: "Kotturu-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3806,
        Pin: 450337,
        DispatchCenter: "Khandwa_TilakNagar_D (Madhya Pradesh)",
        OriginCenter: "Khandwa_TilakNagar_D (Madhya Pradesh)",
        FacilityCity: "Khandwa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3807,
        Pin: 585423,
        DispatchCenter: "Bidar_AdarshClny_D (Karnataka)",
        OriginCenter: "Bidar_AdarshClny_D (Karnataka)",
        FacilityCity: "Bidar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3808,
        Pin: 212658,
        DispatchCenter: "Khaga_GTRoad_D (Uttar Pradesh)",
        OriginCenter: "Khaga_GTRoad_D (Uttar Pradesh)",
        FacilityCity: "Khaga",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3809,
        Pin: 425203,
        DispatchCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        OriginCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        FacilityCity: "Bhusawal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3810,
        Pin: 761208,
        DispatchCenter: "Paralakhemundi_Omcolony_D (Orissa)",
        OriginCenter: "Paralakhemundi_Omcolony_D (Orissa)",
        FacilityCity: "Paralakhemundi",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3811,
        Pin: 143533,
        DispatchCenter: "Dinanagar_KakaHosp_D (Punjab)",
        OriginCenter: "Dinanagar_KakaHosp_D (Punjab)",
        FacilityCity: "Dinanagar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3812,
        Pin: 678573,
        DispatchCenter: "Akaloor_Lakdi_DPP (Kerala)",
        OriginCenter: "Akaloor_Lakdi_DPP (Kerala)",
        FacilityCity: "Akaloor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3813,
        Pin: 639101,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3814,
        Pin: 686692,
        DispatchCenter: "Perumbavoor_Kizhake_D (Kerala)",
        OriginCenter: "Perumbavoor_Kizhake_D (Kerala)",
        FacilityCity: "Perumbavoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3815,
        Pin: 770039,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3816,
        Pin: 626003,
        DispatchCenter: "Virudhunagar_Allampatti_DPP (Tamil Nadu)",
        OriginCenter: "Virudhunagar_Allampatti_DPP (Tamil Nadu)",
        FacilityCity: "Virudhunagar",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3817,
        Pin: 382345,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3818,
        Pin: 843109,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3819,
        Pin: 134107,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3820,
        Pin: 207120,
        DispatchCenter: "Etah_Dineshnagar_D (Uttar Pradesh)",
        OriginCenter: "Etah_Dineshnagar_D (Uttar Pradesh)",
        FacilityCity: "Etah",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3821,
        Pin: 412804,
        DispatchCenter: "Wai_Bavdhan_DPP (Maharashtra)",
        OriginCenter: "Wai_Bavdhan_DPP (Maharashtra)",
        FacilityCity: "Wai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3822,
        Pin: 411033,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3823,
        Pin: 203401,
        DispatchCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        OriginCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        FacilityCity: "Siyana",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3824,
        Pin: 679304,
        DispatchCenter: "Valanchery_Central_D_1 (Kerala)",
        OriginCenter: "Valanchery_Central_D_1 (Kerala)",
        FacilityCity: "Valanchery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3825,
        Pin: 413214,
        DispatchCenter: "Solapur_Kalasenagar_D (Maharashtra)",
        OriginCenter: "Solapur_Kalasenagar_D (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3826,
        Pin: 301411,
        DispatchCenter: "Tijara_Ward3_D (Rajasthan)",
        OriginCenter: "Tijara_Ward3_D (Rajasthan)",
        FacilityCity: "Tijara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3827,
        Pin: 770017,
        DispatchCenter: "Rajgangpur_Central_D_2 (Orissa)",
        OriginCenter: "Rajgangpur_Central_D_2 (Orissa)",
        FacilityCity: "Rajgangpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3828,
        Pin: 244925,
        DispatchCenter: "Rampur_Suar_D (Uttar Pradesh)",
        OriginCenter: "Rampur_Suar_D (Uttar Pradesh)",
        FacilityCity: "Rampur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3829,
        Pin: 221001,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3830,
        Pin: 572135,
        DispatchCenter: "Sira_Hanumanthnagara_D (Karnataka)",
        OriginCenter: "Sira_Hanumanthnagara_D (Karnataka)",
        FacilityCity: "Sira",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3831,
        Pin: 142060,
        DispatchCenter: "Faridkot_JatinderChwk_D (Punjab)",
        OriginCenter: "Faridkot_JatinderChwk_D (Punjab)",
        FacilityCity: "Faridkot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3832,
        Pin: 500762,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3833,
        Pin: 250205,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3834,
        Pin: 572132,
        DispatchCenter: "Madhugiri_SBI_DPP (Karnataka)",
        OriginCenter: "Madhugiri_SBI_DPP (Karnataka)",
        FacilityCity: "Madhugiri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3835,
        Pin: 611110,
        DispatchCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        OriginCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        FacilityCity: "Nagapattinam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3836,
        Pin: 627716,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3837,
        Pin: 574201,
        DispatchCenter: "Puttur_Darbe_D (Karnataka)",
        OriginCenter: "Puttur_Darbe_D (Karnataka)",
        FacilityCity: "Puttur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3838,
        Pin: 641016,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3839,
        Pin: 642122,
        DispatchCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        OriginCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        FacilityCity: "Udumalaipettai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3840,
        Pin: 741248,
        DispatchCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        OriginCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        FacilityCity: "Ranaghat",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3841,
        Pin: 713518,
        DispatchCenter: "Katwa_Panchghara_D (West Bengal)",
        OriginCenter: "Katwa_Panchghara_D (West Bengal)",
        FacilityCity: "Katwa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3842,
        Pin: 454773,
        DispatchCenter: "Dhar_TirupatiNagar_D (Madhya Pradesh)",
        OriginCenter: "Dhar_TirupatiNagar_D (Madhya Pradesh)",
        FacilityCity: "Dhar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3843,
        Pin: 509217,
        DispatchCenter: "Shadnagar_Farooqnagar_D (Telangana)",
        OriginCenter: "Shadnagar_Farooqnagar_D (Telangana)",
        FacilityCity: "Shadnagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3844,
        Pin: 455001,
        DispatchCenter: "Indore_BarodaArjun_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3845,
        Pin: 743392,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3846,
        Pin: 643206,
        DispatchCenter: "Ooty_Westmere_D (Tamil Nadu)",
        OriginCenter: "Ooty_Westmere_D (Tamil Nadu)",
        FacilityCity: "Ooty",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3847,
        Pin: 614210,
        DispatchCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        OriginCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3848,
        Pin: 210425,
        DispatchCenter: "Muskara_KaliMataM_D (Uttar Pradesh)",
        OriginCenter: "Muskara_KaliMataM_D (Uttar Pradesh)",
        FacilityCity: "Muskara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3849,
        Pin: 815355,
        DispatchCenter: "Kundahit_Bankati_D (Jharkhand)",
        OriginCenter: "Kundahit_Bankati_D (Jharkhand)",
        FacilityCity: "Kundahit",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3850,
        Pin: 637402,
        DispatchCenter: "Rasipurm_Knrpati_D (Tamil Nadu)",
        OriginCenter: "Rasipurm_Knrpati_D (Tamil Nadu)",
        FacilityCity: "Rasipuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3851,
        Pin: 176053,
        DispatchCenter: "Jawali_BhaneiLubh_DPP (Himachal Pradesh)",
        OriginCenter: "Jawali_BhaneiLubh_DPP (Himachal Pradesh)",
        FacilityCity: "Jawali",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3852,
        Pin: 303704,
        DispatchCenter: "Jaipur_VishwakarmaIndArea_L (Rajasthan)",
        OriginCenter: "Jaipur_VishwakarmaIndArea_L (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3853,
        Pin: 673309,
        DispatchCenter: "Mahe_Chokli_D (Kerala)",
        OriginCenter: "Mahe_Chokli_D (Kerala)",
        FacilityCity: "Mahe",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3854,
        Pin: 500069,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3855,
        Pin: 742308,
        DispatchCenter: "Domkal_StateBank_D (West Bengal)",
        OriginCenter: "Domkal_StateBank_D (West Bengal)",
        FacilityCity: "Domkal",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3856,
        Pin: 495557,
        DispatchCenter: "Jaijaipur_Shivrinarayan_DPP (Chhattisgarh)",
        OriginCenter: "Jaijaipur_Shivrinarayan_DPP (Chhattisgarh)",
        FacilityCity: "Jaijaipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3857,
        Pin: 758025,
        DispatchCenter: "Anandapur_Ghasipura_D (Orissa)",
        OriginCenter: "Anandapur_Ghasipura_D (Orissa)",
        FacilityCity: "Anandapur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3858,
        Pin: 685565,
        DispatchCenter: "Munnar_IkkaNgr_D (Kerala)",
        OriginCenter: "Munnar_IkkaNgr_D (Kerala)",
        FacilityCity: "Munnar",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3859,
        Pin: 787053,
        DispatchCenter: "Dhakuakhana_Wardno2_D (Assam)",
        OriginCenter: "Dhakuakhana_Wardno2_D (Assam)",
        FacilityCity: "Dhakuakhana",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3860,
        Pin: 364720,
        DispatchCenter: "Botad_Paliyadrd_D (Gujarat)",
        OriginCenter: "Botad_Paliyadrd_D (Gujarat)",
        FacilityCity: "Botad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3861,
        Pin: 243641,
        DispatchCenter: "Budaun_Usawan_D (Uttar Pradesh)",
        OriginCenter: "Budaun_Usawan_D (Uttar Pradesh)",
        FacilityCity: "Budaun",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3862,
        Pin: 534145,
        DispatchCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        OriginCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        FacilityCity: "Tadepalligudem",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3863,
        Pin: 782121,
        DispatchCenter: "Morigaon_Bhuragaon_D (Assam)",
        OriginCenter: "Morigaon_Bhuragaon_D (Assam)",
        FacilityCity: "Morigaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3864,
        Pin: 522318,
        DispatchCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        OriginCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3865,
        Pin: 500036,
        DispatchCenter: "Hyderabad_Autonagar_L (Telangana)",
        OriginCenter: "Hyderabad_Autonagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3866,
        Pin: 799286,
        DispatchCenter: "Kamalpur_Manikbhandar_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Kamalpur",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3867,
        Pin: 770013,
        DispatchCenter: "Sundargarh_Duduka_D (Orissa)",
        OriginCenter: "Sundargarh_Duduka_D (Orissa)",
        FacilityCity: "Sundargarh",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3868,
        Pin: 758021,
        DispatchCenter: "Anandapur_Ghasipura_D (Orissa)",
        OriginCenter: "Anandapur_Ghasipura_D (Orissa)",
        FacilityCity: "Anandapur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3869,
        Pin: 415101,
        DispatchCenter: "Bambavade_SarudRd_DPP (Maharashtra)",
        OriginCenter: "Bambavade_SarudRd_DPP (Maharashtra)",
        FacilityCity: "Bambavade",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3870,
        Pin: 676510,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3871,
        Pin: 680564,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3872,
        Pin: 140201,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3873,
        Pin: 816101,
        DispatchCenter: "Barharwa_SahibDPP_D (Jharkhand)",
        OriginCenter: "Barharwa_SahibDPP_D (Jharkhand)",
        FacilityCity: "Barharwa",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3874,
        Pin: 322238,
        DispatchCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        OriginCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3875,
        Pin: 466651,
        DispatchCenter: "Sehore_Sherpur_D (Madhya Pradesh)",
        OriginCenter: "Sehore_Sherpur_D (Madhya Pradesh)",
        FacilityCity: "Sehore",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3876,
        Pin: 460443,
        DispatchCenter: "Sarni_Ward36_D (Madhya Pradesh)",
        OriginCenter: "Sarni_Ward36_D (Madhya Pradesh)",
        FacilityCity: "Sarni",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3877,
        Pin: 638311,
        DispatchCenter: "Anthiyur_Thavittuplym_DPP (Tamil Nadu)",
        OriginCenter: "Anthiyur_Thavittuplym_DPP (Tamil Nadu)",
        FacilityCity: "Anthiyour",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3878,
        Pin: 783331,
        DispatchCenter: "Dhubri_Tetultol_D (Assam)",
        OriginCenter: "Dhubri_Tetultol_D (Assam)",
        FacilityCity: "Dhubri",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3879,
        Pin: 271801,
        DispatchCenter: "Bahraich_Dularpur_H (Uttar Pradesh)",
        OriginCenter: "Bahraich_Dularpur_H (Uttar Pradesh)",
        FacilityCity: "Bahraich",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3880,
        Pin: 262001,
        DispatchCenter: "Pilibhit_AvasVikas_D (Uttar Pradesh)",
        OriginCenter: "Pilibhit_AvasVikas_D (Uttar Pradesh)",
        FacilityCity: "Pilibhit",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3881,
        Pin: 621210,
        DispatchCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        OriginCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        FacilityCity: "Thuraiyur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3882,
        Pin: 250221,
        DispatchCenter: "Daurala_Central_D_2 (Uttar Pradesh)",
        OriginCenter: "Daurala_Central_D_2 (Uttar Pradesh)",
        FacilityCity: "Daurala",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3883,
        Pin: 360045,
        DispatchCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        OriginCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        Zone : "W1"
      },
      {
        SN: 3884,
        Pin: 509130,
        DispatchCenter: "AtmakurTS_Srinivasa_DPP (Telangana)",
        OriginCenter: "AtmakurTS_Srinivasa_DPP (Telangana)",
        FacilityCity: "Atmakur-TS",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3885,
        Pin: 788152,
        DispatchCenter: "Hailakandi_GangprDhmkr_D (Assam)",
        OriginCenter: "Hailakandi_GangprDhmkr_D (Assam)",
        FacilityCity: "Hailakandi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3886,
        Pin: 222127,
        DispatchCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        OriginCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        FacilityCity: "Mungra Badshahpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3887,
        Pin: 134003,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3888,
        Pin: 457333,
        DispatchCenter: "Jaora_AdityaNagar_D (Madhya Pradesh)",
        OriginCenter: "Jaora_AdityaNagar_D (Madhya Pradesh)",
        FacilityCity: "Jaora",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3889,
        Pin: 847108,
        DispatchCenter: "Phulparas_Mahadeomath_D (Bihar)",
        OriginCenter: "Phulparas_Mahadeomath_D (Bihar)",
        FacilityCity: "Phulparas",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3890,
        Pin: 184101,
        DispatchCenter: "Kathua_Kharote_D (Jammu & Kashmir)",
        OriginCenter: "Kathua_Kharote_D (Jammu & Kashmir)",
        FacilityCity: "Kathua",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3891,
        Pin: 813206,
        DispatchCenter: "Kahalgaon_Budhuchak_D (Bihar)",
        OriginCenter: "Kahalgaon_Budhuchak_D (Bihar)",
        FacilityCity: "Kahalgaon",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3892,
        Pin: 382422,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3893,
        Pin: 312621,
        DispatchCenter: "PratapgarhRJ_Badabag_DPP (Rajasthan)",
        OriginCenter: "PratapgarhRJ_Badabag_DPP (Rajasthan)",
        FacilityCity: "Pratapgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3894,
        Pin: 522515,
        DispatchCenter: "Guntur_Amaravati_D (Andhra Pradesh)",
        OriginCenter: "Guntur_Amaravati_D (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3895,
        Pin: 686037,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3896,
        Pin: 487337,
        DispatchCenter: "Tendukheda_AadhaarCntr_D (Madhya Pradesh)",
        OriginCenter: "Tendukheda_AadhaarCntr_D (Madhya Pradesh)",
        FacilityCity: "Gadarwara",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3897,
        Pin: 518222,
        DispatchCenter: "Dhone_Autonagar_D (Andhra Pradesh)",
        OriginCenter: "Dhone_Autonagar_D (Andhra Pradesh)",
        FacilityCity: "Dhone",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3898,
        Pin: 416814,
        DispatchCenter: "Oras_Ranbambuli_D (Maharashtra)",
        OriginCenter: "Oras_Ranbambuli_D (Maharashtra)",
        FacilityCity: "Oras",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3899,
        Pin: 700096,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3900,
        Pin: 673617,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3901,
        Pin: 412208,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3902,
        Pin: 522549,
        DispatchCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasaraopet",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3903,
        Pin: 713363,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3904,
        Pin: 403718,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3905,
        Pin: 534126,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3906,
        Pin: 843317,
        DispatchCenter: "Sonbarsa_Sitamarhi_D (Bihar)",
        OriginCenter: "Sonbarsa_Sitamarhi_D (Bihar)",
        FacilityCity: "Sonbarsa",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3907,
        Pin: 495335,
        DispatchCenter: "Bilaspur_Hatarichowk_D (Chhattisgarh)",
        OriginCenter: "Bilaspur_Hatarichowk_D (Chhattisgarh)",
        FacilityCity: "Bilaspur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3908,
        Pin: 691303,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3909,
        Pin: 828128,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3910,
        Pin: 785636,
        DispatchCenter: "Teok_Wardno3_D (Assam)",
        OriginCenter: "Teok_Wardno3_D (Assam)",
        FacilityCity: "Teok",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3911,
        Pin: 384325,
        DispatchCenter: "Vadnagar_BsstdDPP_D (Gujarat)",
        OriginCenter: "Vadnagar_BsstdDPP_D (Gujarat)",
        FacilityCity: "Vadnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3912,
        Pin: 572120,
        DispatchCenter: "Kunigal_Vidyanagar_D (Karnataka)",
        OriginCenter: "Kunigal_Vidyanagar_D (Karnataka)",
        FacilityCity: "Kunigal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3913,
        Pin: 403111,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3914,
        Pin: 502290,
        DispatchCenter: "Narayankhed_Turkapally_D (Telangana)",
        OriginCenter: "Narayankhed_Turkapally_D (Telangana)",
        FacilityCity: "Narayankhed",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3915,
        Pin: 600121,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3916,
        Pin: 686642,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3917,
        Pin: 251310,
        DispatchCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        OriginCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        FacilityCity: "Muzaffarnagar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3918,
        Pin: 495553,
        DispatchCenter: "Akaltara_IndraUdyan_D (Chhattisgarh)",
        OriginCenter: "Akaltara_IndraUdyan_D (Chhattisgarh)",
        FacilityCity: "Akaltara",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3919,
        Pin: 521255,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3920,
        Pin: 421602,
        DispatchCenter: "Igatpuri_Phulenagar_D (Maharashtra)",
        OriginCenter: "Igatpuri_Phulenagar_D (Maharashtra)",
        FacilityCity: "Igatpuri",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 3921,
        Pin: 532428,
        DispatchCenter: "KotturuAP_NivagamRd_DPP (Andhra Pradesh)",
        OriginCenter: "KotturuAP_NivagamRd_DPP (Andhra Pradesh)",
        FacilityCity: "Kotturu-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3922,
        Pin: 160012,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3923,
        Pin: 624209,
        DispatchCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        OriginCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        FacilityCity: "Vadipatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3924,
        Pin: 743122,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3925,
        Pin: 521331,
        DispatchCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        OriginCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        FacilityCity: "Machilipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3926,
        Pin: 461122,
        DispatchCenter: "Itarsi_AwnNgr_D (Madhya Pradesh)",
        OriginCenter: "Itarsi_AwnNgr_D (Madhya Pradesh)",
        FacilityCity: "Itarsi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 3927,
        Pin: 134105,
        DispatchCenter: "Parwanoo_Sector2_D (Himachal Pradesh)",
        OriginCenter: "Parwanoo_Sector2_D (Himachal Pradesh)",
        FacilityCity: "Parwanoo",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 3928,
        Pin: 679533,
        DispatchCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        OriginCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        FacilityCity: "Sankaramangalam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3929,
        Pin: 804425,
        DispatchCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        OriginCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        FacilityCity: "Jehanabad",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3930,
        Pin: 383410,
        DispatchCenter: "Idar_GIDC_D (Gujarat)",
        OriginCenter: "Idar_GIDC_D (Gujarat)",
        FacilityCity: "Idar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3931,
        Pin: 385130,
        DispatchCenter: "Palanpur_Esbipura1_D (Gujarat)",
        OriginCenter: "Palanpur_Esbipura1_D (Gujarat)",
        FacilityCity: "Palanpur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3932,
        Pin: 302037,
        DispatchCenter: "Jaipur_RingRoad_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3933,
        Pin: 464668,
        DispatchCenter: "Bareli_SourvDPP_D (Madhya Pradesh)",
        OriginCenter: "Bareli_SourvDPP_D (Madhya Pradesh)",
        FacilityCity: "Bareli",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3934,
        Pin: 143532,
        DispatchCenter: "Dinanagar_KakaHosp_D (Punjab)",
        OriginCenter: "Dinanagar_KakaHosp_D (Punjab)",
        FacilityCity: "Dinanagar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3935,
        Pin: 800026,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3936,
        Pin: 321607,
        DispatchCenter: "Laxmangarh_Lilee_D (Rajasthan)",
        OriginCenter: "Laxmangarh_Lilee_D (Rajasthan)",
        FacilityCity: "Laxmangarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3937,
        Pin: 673305,
        DispatchCenter: "Koyilandy_Komathukara_D (Kerala)",
        OriginCenter: "Koyilandy_Komathukara_D (Kerala)",
        FacilityCity: "Koyilandy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3938,
        Pin: 518166,
        DispatchCenter: "Tadipatri_Kalavagadacol_D (Andhra Pradesh)",
        OriginCenter: "Tadipatri_Kalavagadacol_D (Andhra Pradesh)",
        FacilityCity: "Tadipatri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3939,
        Pin: 393030,
        DispatchCenter: "Hansot_ChaudhryTmbr_DPP (Gujarat)",
        OriginCenter: "Hansot_ChaudhryTmbr_DPP (Gujarat)",
        FacilityCity: "Hansot",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3940,
        Pin: 786145,
        DispatchCenter: "Tinsukia_Nokhroygaon2_D (Assam)",
        OriginCenter: "Tinsukia_Nokhroygaon2_D (Assam)",
        FacilityCity: "Tinsukia",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3941,
        Pin: 643209,
        DispatchCenter: "Ooty_Manjoor_D (Tamil Nadu)",
        OriginCenter: "Ooty_Manjoor_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3942,
        Pin: 731224,
        DispatchCenter: "Rampurhat_Mansubamore_D (West Bengal)",
        OriginCenter: "Rampurhat_Mansubamore_D (West Bengal)",
        FacilityCity: "Rampurhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3943,
        Pin: 312022,
        DispatchCenter: "BassiRJ_KhaniyStn_DPP (Rajasthan)",
        OriginCenter: "BassiRJ_KhaniyStn_DPP (Rajasthan)",
        FacilityCity: "BassiRJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3944,
        Pin: 282009,
        DispatchCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        OriginCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        FacilityCity: "Agra",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3945,
        Pin: 225119,
        DispatchCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        OriginCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        FacilityCity: "Haidargarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3946,
        Pin: 231312,
        DispatchCenter: "Mirzapur_Natwa_D (Uttar Pradesh)",
        OriginCenter: "Mirzapur_Natwa_D (Uttar Pradesh)",
        FacilityCity: "Mirzapur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 3947,
        Pin: 431135,
        DispatchCenter: "Sillod_ZebaTWR_D (Maharashtra)",
        OriginCenter: "Sillod_ZebaTWR_D (Maharashtra)",
        FacilityCity: "Sillod",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3948,
        Pin: 487221,
        DispatchCenter: "Narsinghpur_Stationganj_D (Madhya Pradesh)",
        OriginCenter: "Narsinghpur_Stationganj_D (Madhya Pradesh)",
        FacilityCity: "Narsinghpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3949,
        Pin: 335804,
        DispatchCenter: "Pilibanga_Suratgarh_D (Rajasthan)",
        OriginCenter: "Pilibanga_Suratgarh_D (Rajasthan)",
        FacilityCity: "Suratgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3950,
        Pin: 785614,
        DispatchCenter: "Dergaon_Rojabahar_DPP (Assam)",
        OriginCenter: "Dergaon_Rojabahar_DPP (Assam)",
        FacilityCity: "Dergaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3951,
        Pin: 695003,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3952,
        Pin: 384003,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 3953,
        Pin: 852126,
        DispatchCenter: "Sonbarsa_Soha_D (Bihar)",
        OriginCenter: "Sonbarsa_Soha_D (Bihar)",
        FacilityCity: "Sonbarsa-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3954,
        Pin: 301416,
        DispatchCenter: "Bansur_TransportNagar_D (Rajasthan)",
        OriginCenter: "Bansur_TransportNagar_D (Rajasthan)",
        FacilityCity: "Bansur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3955,
        Pin: 841203,
        DispatchCenter: "Raghunathpur_DhnwtiKhp_D (Bihar)",
        OriginCenter: "Raghunathpur_DhnwtiKhp_D (Bihar)",
        FacilityCity: "Raghunathpur-BR",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3956,
        Pin: 425419,
        DispatchCenter: "Akkalkuva_MorambaRd_DPP (Maharashtra)",
        OriginCenter: "Akkalkuva_MorambaRd_DPP (Maharashtra)",
        FacilityCity: "Akkalkuva",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3957,
        Pin: 786101,
        DispatchCenter: "Tengakhat_TngkhtBzaar_DPP (Assam)",
        OriginCenter: "Tengakhat_TngkhtBzaar_DPP (Assam)",
        FacilityCity: "Tengakhat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3958,
        Pin: 764004,
        DispatchCenter: "Jeypore_Bariniput_I (Orissa)",
        OriginCenter: "Jeypore_Bariniput_I (Orissa)",
        FacilityCity: "Jeypore",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3959,
        Pin: 731222,
        DispatchCenter: "Murarai_Birbhumi_D (West Bengal)",
        OriginCenter: "Murarai_Birbhumi_D (West Bengal)",
        FacilityCity: "Murarai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3960,
        Pin: 695616,
        DispatchCenter: "Pangodu_Bharathanoor_D (Kerala)",
        OriginCenter: "Pangodu_Bharathanoor_D (Kerala)",
        FacilityCity: "Pangodu",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3961,
        Pin: 413304,
        DispatchCenter: "Pandharpur_RajCmplx_D (Maharashtra)",
        OriginCenter: "Pandharpur_RajCmplx_D (Maharashtra)",
        FacilityCity: "Pandharpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3962,
        Pin: 788805,
        DispatchCenter: "Karimganj_Gorkapan_D (Assam)",
        OriginCenter: "Karimganj_Gorkapan_D (Assam)",
        FacilityCity: "Karimganj",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3963,
        Pin: 174017,
        DispatchCenter: "KalolHP_CsdCanteen_DPP (Himachal Pradesh)",
        OriginCenter: "KalolHP_CsdCanteen_DPP (Himachal Pradesh)",
        FacilityCity: "KalolHP",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3964,
        Pin: 678554,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3965,
        Pin: 582123,
        DispatchCenter: "Gadag_Vidyanagar_D (Karnataka)",
        OriginCenter: "Gadag_Vidyanagar_D (Karnataka)",
        FacilityCity: "Gadag",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3966,
        Pin: 629703,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3967,
        Pin: 485441,
        DispatchCenter: "Satna_Nazirabad_I (Madhya Pradesh)",
        OriginCenter: "Satna_Nazirabad_I (Madhya Pradesh)",
        FacilityCity: "Satna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3968,
        Pin: 763002,
        DispatchCenter: "Semiliguda_HimgiriNagar_D (Orissa)",
        OriginCenter: "Semiliguda_HimgiriNagar_D (Orissa)",
        FacilityCity: "Semiliguda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 3969,
        Pin: 733124,
        DispatchCenter: "Gangarampr_Ramkrishnpaly_DPP (West Bengal)",
        OriginCenter: "Gangarampr_Ramkrishnpaly_DPP (West Bengal)",
        FacilityCity: "Gangarampur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3970,
        Pin: 202165,
        DispatchCenter: "Jewar_SJRoad_D (Uttar Pradesh)",
        OriginCenter: "Jewar_SJRoad_D (Uttar Pradesh)",
        FacilityCity: "Jewar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3971,
        Pin: 431511,
        DispatchCenter: "Vasmat_Khajipura_D (Maharashtra)",
        OriginCenter: "Vasmat_Khajipura_D (Maharashtra)",
        FacilityCity: "Vasmat",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3972,
        Pin: 249196,
        DispatchCenter: "Uttarkashi_Sulithang_DPP (Uttarakhand)",
        OriginCenter: "Uttarkashi_Sulithang_DPP (Uttarakhand)",
        FacilityCity: "Uttarkashi",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3973,
        Pin: 612803,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3974,
        Pin: 678733,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3975,
        Pin: 678015,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3976,
        Pin: 670305,
        DispatchCenter: "Kannur_Aduthila_D (Kerala)",
        OriginCenter: "Kannur_Aduthila_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3977,
        Pin: 226103,
        DispatchCenter: "Malihabad_Amanignj_DPP (Uttar Pradesh)",
        OriginCenter: "Malihabad_Amanignj_DPP (Uttar Pradesh)",
        FacilityCity: "Malihabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3978,
        Pin: 394601,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3979,
        Pin: 691582,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3980,
        Pin: 534302,
        DispatchCenter: "Kovvur_Samisragudem_D (Andhra Pradesh)",
        OriginCenter: "Kovvur_Samisragudem_D (Andhra Pradesh)",
        FacilityCity: "Kovvur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3981,
        Pin: 456313,
        DispatchCenter: "Badnagar_Teacherscolony_D (Madhya Pradesh)",
        OriginCenter: "Badnagar_Teacherscolony_D (Madhya Pradesh)",
        FacilityCity: "Badnagar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3982,
        Pin: 603204,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3983,
        Pin: 721222,
        DispatchCenter: "Ghatal_KrshDPP_D (West Bengal)",
        OriginCenter: "Ghatal_KrshDPP_D (West Bengal)",
        FacilityCity: "Ghatal",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3984,
        Pin: 560104,
        DispatchCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        OriginCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 3985,
        Pin: 562101,
        DispatchCenter: "Chikkaballapura_WardNo6_D (Karnataka)",
        OriginCenter: "Chikkaballapura_WardNo6_D (Karnataka)",
        FacilityCity: "Chikkaballapura",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3986,
        Pin: 370001,
        DispatchCenter: "Bhuj_Madhapar_D (Gujarat)",
        OriginCenter: "Bhuj_Madhapar_D (Gujarat)",
        FacilityCity: "Bhuj",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 3987,
        Pin: 333516,
        DispatchCenter: "Buhana_Puranabas_D (Rajasthan)",
        OriginCenter: "Buhana_Puranabas_D (Rajasthan)",
        FacilityCity: "Buhana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 3988,
        Pin: 690103,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 3989,
        Pin: 682037,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3990,
        Pin: 480441,
        DispatchCenter: "Parasia_TekMohlla_DPP (Madhya Pradesh)",
        OriginCenter: "Parasia_TekMohlla_DPP (Madhya Pradesh)",
        FacilityCity: "Parasia",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 3991,
        Pin: 673577,
        DispatchCenter: "Kalpetta_Mandayapuram_D (Kerala)",
        OriginCenter: "Kalpetta_Mandayapuram_D (Kerala)",
        FacilityCity: "Kalpetta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 3992,
        Pin: 852105,
        DispatchCenter: "Simrahi_Bazar_D (Bihar)",
        OriginCenter: "Simrahi_Bazar_D (Bihar)",
        FacilityCity: "Simrahi Bazar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 3993,
        Pin: 628614,
        DispatchCenter: "Eral_Nazareth_D (Tamil Nadu)",
        OriginCenter: "Eral_Nazareth_D (Tamil Nadu)",
        FacilityCity: "Eral",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 3994,
        Pin: 762020,
        DispatchCenter: "Puranakatak_Athmallik_D (Orissa)",
        OriginCenter: "Puranakatak_Athmallik_D (Orissa)",
        FacilityCity: "Puranakatak",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 3995,
        Pin: 384460,
        DispatchCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        OriginCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        FacilityCity: "Vijapur",
        FacilityState: "Gujarat",
        Zone : "W1"
      },
      {
        SN: 3996,
        Pin: 712103,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 3997,
        Pin: 402106,
        DispatchCenter: "Nagothane_Balsaital_D (Maharashtra)",
        OriginCenter: "Nagothane_Balsaital_D (Maharashtra)",
        FacilityCity: "Nagothane",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 3998,
        Pin: 152021,
        DispatchCenter: "Zira_FriendsEncl_D (Punjab)",
        OriginCenter: "Zira_FriendsEncl_D (Punjab)",
        FacilityCity: "Zira",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 3999,
        Pin: 416403,
        DispatchCenter: "KMahankal_KM_D (Maharashtra)",
        OriginCenter: "KMahankal_KM_D (Maharashtra)",
        FacilityCity: "Kavathe Mahankal",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4000,
        Pin: 311805,
        DispatchCenter: "Gangapur_Sahara_DPP (Rajasthan)",
        OriginCenter: "Gangapur_Sahara_DPP (Rajasthan)",
        FacilityCity: "Gangapur-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4001,
        Pin: 274206,
        DispatchCenter: "Kushinagar_Hata_D (Uttar Pradesh)",
        OriginCenter: "Kushinagar_Hata_D (Uttar Pradesh)",
        FacilityCity: "Kushinagar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4002,
        Pin: 209749,
        DispatchCenter: "Farrukhabad_RamBanquet_D (Uttar Pradesh)",
        OriginCenter: "Farrukhabad_RamBanquet_D (Uttar Pradesh)",
        FacilityCity: "Farrukhabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4003,
        Pin: 271504,
        DispatchCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        OriginCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        FacilityCity: "Colonejganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4004,
        Pin: 413581,
        DispatchCenter: "Nilanga_Panchalcolony_D (Maharashtra)",
        OriginCenter: "Nilanga_Panchalcolony_D (Maharashtra)",
        FacilityCity: "Nilanga",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4005,
        Pin: 462039,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 4006,
        Pin: 174312,
        DispatchCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        OriginCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        FacilityCity: "Bangana",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4007,
        Pin: 422013,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4008,
        Pin: 627110,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4009,
        Pin: 609202,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4010,
        Pin: 518599,
        DispatchCenter: "Betamcherla_ChinnaSch_DPP (Andhra Pradesh)",
        OriginCenter: "Betamcherla_ChinnaSch_DPP (Andhra Pradesh)",
        FacilityCity: "Betamcherla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4011,
        Pin: 721156,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4012,
        Pin: 620022,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4013,
        Pin: 305003,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4014,
        Pin: 506008,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4015,
        Pin: 503165,
        DispatchCenter: "Sirikonda_NizmbdRd_DPP (Telangana)",
        OriginCenter: "Sirikonda_NizmbdRd_DPP (Telangana)",
        FacilityCity: "Sirikonda",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4016,
        Pin: 743312,
        DispatchCenter: "Canning_BaruipurRd_D (West Bengal)",
        OriginCenter: "Canning_BaruipurRd_D (West Bengal)",
        FacilityCity: "Canning",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4017,
        Pin: 160069,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4018,
        Pin: 273203,
        DispatchCenter: "Gorakhpur_Chaurichaura_D (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Chaurichaura_D (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4019,
        Pin: 713401,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4020,
        Pin: 796007,
        DispatchCenter: "Aizawl_Ainawnveng_D (Mizoram)",
        OriginCenter: "Aizawl_Ainawnveng_D (Mizoram)",
        FacilityCity: "Aizawl",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4021,
        Pin: 277214,
        DispatchCenter: "Bairia_Bhagwanpur_DPP (Uttar Pradesh)",
        OriginCenter: "Bairia_Bhagwanpur_DPP (Uttar Pradesh)",
        FacilityCity: "Bariya",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4022,
        Pin: 627115,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4023,
        Pin: 193411,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4024,
        Pin: 391241,
        DispatchCenter: "Karjan_Junabzr_DC (Gujarat)",
        OriginCenter: "Karjan_Junabzr_DC (Gujarat)",
        FacilityCity: "Karjan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4025,
        Pin: 793019,
        DispatchCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        OriginCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4026,
        Pin: 723155,
        DispatchCenter: "Para_Dubra_DPP (West Bengal)",
        OriginCenter: "Para_Dubra_DPP (West Bengal)",
        FacilityCity: "Para",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4027,
        Pin: 783330,
        DispatchCenter: "Lakhipur_Ward2_D (Assam)",
        OriginCenter: "Lakhipur_Ward2_D (Assam)",
        FacilityCity: "Lakhipur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4028,
        Pin: 679331,
        DispatchCenter: "Nilambur_KarulaiRd_DPP (Kerala)",
        OriginCenter: "Nilambur_KarulaiRd_DPP (Kerala)",
        FacilityCity: "Nilambur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4029,
        Pin: 322034,
        DispatchCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        OriginCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        FacilityCity: "Sawai Madhopur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4030,
        Pin: 813212,
        DispatchCenter: "Sangrampur_Bhawnipr_D (Bihar)",
        OriginCenter: "Sangrampur_Bhawnipr_D (Bihar)",
        FacilityCity: "Sangrampur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4031,
        Pin: 425503,
        DispatchCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        OriginCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        FacilityCity: "Bhusawal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4032,
        Pin: 431107,
        DispatchCenter: "Paithan_SantaNGR_D (Maharashtra)",
        OriginCenter: "Paithan_SantaNGR_D (Maharashtra)",
        FacilityCity: "Paithan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4033,
        Pin: 382150,
        DispatchCenter: "Viramgam_AxarNgr_DPP (Gujarat)",
        OriginCenter: "Viramgam_AxarNgr_DPP (Gujarat)",
        FacilityCity: "Viramgam",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4034,
        Pin: 176032,
        DispatchCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        OriginCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        FacilityCity: "Nadaun",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4035,
        Pin: 825317,
        DispatchCenter: "Hazaribagh_Matwari_D (Jharkhand)",
        OriginCenter: "Hazaribagh_Matwari_D (Jharkhand)",
        FacilityCity: "hazaribagh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4036,
        Pin: 444117,
        DispatchCenter: "Akot_CollegeRd_D (Maharashtra)",
        OriginCenter: "Akot_CollegeRd_D (Maharashtra)",
        FacilityCity: "Akot",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4037,
        Pin: 768218,
        DispatchCenter: "Brajrajnagar_Jagannath_DPP (Orissa)",
        OriginCenter: "Brajrajnagar_Jagannath_DPP (Orissa)",
        FacilityCity: "Brajrajnagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4038,
        Pin: 671319,
        DispatchCenter: "Poinachi_Central_D (Kerala)",
        OriginCenter: "Poinachi_Central_D (Kerala)",
        FacilityCity: "Poinachi",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4039,
        Pin: 144504,
        DispatchCenter: "Phagwara_PurewalNgr_D (Punjab)",
        OriginCenter: "Phagwara_PurewalNgr_D (Punjab)",
        FacilityCity: "Phagwara",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4040,
        Pin: 400084,
        DispatchCenter: "Mumbai_Chembur_L (Maharashtra)",
        OriginCenter: "Mumbai_Chembur_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4041,
        Pin: 847410,
        DispatchCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        OriginCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        FacilityCity: "Jhanjharpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4042,
        Pin: 843312,
        DispatchCenter: "Aurai_College_D (Bihar)",
        OriginCenter: "Aurai_College_D (Bihar)",
        FacilityCity: "Aurai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4043,
        Pin: 623526,
        DispatchCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        OriginCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        FacilityCity: "Rameswaram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4044,
        Pin: 517305,
        DispatchCenter: "Madanapalle_Gurramkonda_D (Andhra Pradesh)",
        OriginCenter: "Madanapalle_Gurramkonda_D (Andhra Pradesh)",
        FacilityCity: "Madanapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4045,
        Pin: 602108,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4046,
        Pin: 628152,
        DispatchCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        OriginCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        FacilityCity: "Tuticorin",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4047,
        Pin: 231221,
        DispatchCenter: "Renukoot_Murdhava_H (Uttar Pradesh)",
        OriginCenter: "Renukoot_Murdhava_H (Uttar Pradesh)",
        FacilityCity: "Renukoot",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4048,
        Pin: 614810,
        DispatchCenter: "Pushpavanam_Vedaranyam_D (Tamil Nadu)",
        OriginCenter: "Pushpavanam_Vedaranyam_D (Tamil Nadu)",
        FacilityCity: "Pushpavanam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4049,
        Pin: 573142,
        DispatchCenter: "Ramanathapura_Arakalgud_D (Karnataka)",
        OriginCenter: "Ramanathapura_Arakalgud_D (Karnataka)",
        FacilityCity: "Ramanathapura",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4050,
        Pin: 635801,
        DispatchCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ambur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4051,
        Pin: 731302,
        DispatchCenter: "Nanoor_Central_DPP_1 (West Bengal)",
        OriginCenter: "Nanoor_Central_DPP_1 (West Bengal)",
        FacilityCity: "Nanoor",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4052,
        Pin: 560028,
        DispatchCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        OriginCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4053,
        Pin: 770074,
        DispatchCenter: "Subdega_Gaibira_D (Orissa)",
        OriginCenter: "Subdega_Gaibira_D (Orissa)",
        FacilityCity: "Subdega",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4054,
        Pin: 600018,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4055,
        Pin: 782104,
        DispatchCenter: "Morigaon_Nabheti_D (Assam)",
        OriginCenter: "Morigaon_Nabheti_D (Assam)",
        FacilityCity: "Morigaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4056,
        Pin: 303108,
        DispatchCenter: "Kotputli_Amaicolony_DPP (Rajasthan)",
        OriginCenter: "Kotputli_Amaicolony_DPP (Rajasthan)",
        FacilityCity: "Kotputli",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4057,
        Pin: 383440,
        DispatchCenter: "Idar_GIDC_D (Gujarat)",
        OriginCenter: "Idar_GIDC_D (Gujarat)",
        FacilityCity: "Idar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4058,
        Pin: 600122,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4059,
        Pin: 680567,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4060,
        Pin: 370460,
        DispatchCenter: "Mandvi_Ashpura_DPP (Gujarat)",
        OriginCenter: "Mandvi_Ashpura_DPP (Gujarat)",
        FacilityCity: "Mandvi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4061,
        Pin: 756101,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4062,
        Pin: 272154,
        DispatchCenter: "Menhdawal_Kabeernagar_D (Uttar Pradesh)",
        OriginCenter: "Menhdawal_Kabeernagar_D (Uttar Pradesh)",
        FacilityCity: "Menhdawal",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4063,
        Pin: 495682,
        DispatchCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        OriginCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        FacilityCity: "Korba",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4064,
        Pin: 110508,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4065,
        Pin: 332703,
        DispatchCenter: "Ramgarh_Khoor_DPP (Rajasthan)",
        OriginCenter: "Ramgarh_Khoor_DPP (Rajasthan)",
        FacilityCity: "Ramgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4066,
        Pin: 313014,
        DispatchCenter: "Udaipur_Balicha_H (Rajasthan)",
        OriginCenter: "Udaipur_Balicha_H (Rajasthan)",
        FacilityCity: "Udaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4067,
        Pin: 621652,
        DispatchCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        OriginCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        FacilityCity: "Thirukkattupalli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4068,
        Pin: 574210,
        DispatchCenter: "Puttur_Darbe_D (Karnataka)",
        OriginCenter: "Puttur_Darbe_D (Karnataka)",
        FacilityCity: "Puttur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4069,
        Pin: 410102,
        DispatchCenter: "Karjat_NeralRd_D (Maharashtra)",
        OriginCenter: "Karjat_NeralRd_D (Maharashtra)",
        FacilityCity: "Karjat",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4070,
        Pin: 678661,
        DispatchCenter: "Pollachi_ThangamNagar_D (Tamil Nadu)",
        OriginCenter: "Pollachi_ThangamNagar_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4071,
        Pin: 641114,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4072,
        Pin: 743201,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4073,
        Pin: 689582,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4074,
        Pin: 712612,
        DispatchCenter: "Kamarpukur_ChatiDPP_D (West Bengal)",
        OriginCenter: "Kamarpukur_ChatiDPP_D (West Bengal)",
        FacilityCity: "Kamarpukur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4075,
        Pin: 627005,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4076,
        Pin: 224230,
        DispatchCenter: "Tanda_HayatganjChowk_D (Uttar Pradesh)",
        OriginCenter: "Tanda_HayatganjChowk_D (Uttar Pradesh)",
        FacilityCity: "Tanda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4077,
        Pin: 723148,
        DispatchCenter: "Hura_Lalpur_DPP (West Bengal)",
        OriginCenter: "Hura_Lalpur_DPP (West Bengal)",
        FacilityCity: "Hura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4078,
        Pin: 613406,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4079,
        Pin: 761110,
        DispatchCenter: "Asika_DsarathiNgr_DPP (Orissa)",
        OriginCenter: "Asika_DsarathiNgr_DPP (Orissa)",
        FacilityCity: "Asika",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4080,
        Pin: 221709,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4081,
        Pin: 852122,
        DispatchCenter: "Murliganj_JheelChowk_D (Bihar)",
        OriginCenter: "Murliganj_JheelChowk_D (Bihar)",
        FacilityCity: "Murliganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4082,
        Pin: 193224,
        DispatchCenter: "Kupwara_Kralpora_DPP (Jammu & Kashmir)",
        OriginCenter: "Kupwara_Kralpora_DPP (Jammu & Kashmir)",
        FacilityCity: "Kupwara",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4083,
        Pin: 517192,
        DispatchCenter: "Pileru_Chinnagottigallu_D (Andhra Pradesh)",
        OriginCenter: "Pileru_Chinnagottigallu_D (Andhra Pradesh)",
        FacilityCity: "Tirupati",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4084,
        Pin: 131002,
        DispatchCenter: "Sonipat_Sector51_L (Haryana)",
        OriginCenter: "Sonipat_WazidpurSaboli_P (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4085,
        Pin: 632404,
        DispatchCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ranipet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4086,
        Pin: 676561,
        DispatchCenter: "Tirur_Perunthallur_D (Kerala)",
        OriginCenter: "Tirur_Perunthallur_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4087,
        Pin: 700013,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4088,
        Pin: 622302,
        DispatchCenter: "Karambakkudi_Central_D (Tamil Nadu)",
        OriginCenter: "Karambakkudi_Central_D (Tamil Nadu)",
        FacilityCity: "Karambakkudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4089,
        Pin: 636005,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4090,
        Pin: 245301,
        DispatchCenter: "Pilkhuwa_Rajnivhr_D (Uttar Pradesh)",
        OriginCenter: "Pilkhuwa_Rajnivhr_D (Uttar Pradesh)",
        FacilityCity: "Pilkhuwa",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4091,
        Pin: 175022,
        DispatchCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        OriginCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        FacilityCity: "Mandi",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4092,
        Pin: 679579,
        DispatchCenter: "Chiramanangad_Veliankode_D (Kerala)",
        OriginCenter: "Chiramanangad_Veliankode_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4093,
        Pin: 560009,
        DispatchCenter: "Bangalore_FCILayout_L (Karnataka)",
        OriginCenter: "Bangalore_FCILayout_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4094,
        Pin: 673321,
        DispatchCenter: "Koyilandy_Komathukara_D (Kerala)",
        OriginCenter: "Koyilandy_Komathukara_D (Kerala)",
        FacilityCity: "Koyilandy",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4095,
        Pin: 403101,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4096,
        Pin: 364260,
        DispatchCenter: "Sihor_InfinityMall_D (Gujarat)",
        OriginCenter: "Sihor_InfinityMall_D (Gujarat)",
        FacilityCity: "Sihor",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4097,
        Pin: 360022,
        DispatchCenter: "Rajkot_Amargadh_H (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4098,
        Pin: 455112,
        DispatchCenter: "Dewas_TriweniNagar_D (Madhya Pradesh)",
        OriginCenter: "Dewas_TriweniNagar_D (Madhya Pradesh)",
        FacilityCity: "Dewas",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 4099,
        Pin: 273310,
        DispatchCenter: "Bhathat_Market_D (Uttar Pradesh)",
        OriginCenter: "Bhathat_Market_D (Uttar Pradesh)",
        FacilityCity: "Bhathat",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4100,
        Pin: 734422,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4101,
        Pin: 821124,
        DispatchCenter: "Mohania_Shivpuricolony_D (Bihar)",
        OriginCenter: "Mohania_Shivpuricolony_D (Bihar)",
        FacilityCity: "Mohania",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4102,
        Pin: 411049,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4103,
        Pin: 635119,
        DispatchCenter: "Hosur_SipcotPhase1_L (Tamil Nadu)",
        OriginCenter: "Hosur_SipcotPhase1_L (Tamil Nadu)",
        FacilityCity: "Hosur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4104,
        Pin: 506349,
        DispatchCenter: "Narsampet_PakhalRoad_D (Telangana)",
        OriginCenter: "Narsampet_PakhalRoad_D (Telangana)",
        FacilityCity: "Narsampet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4105,
        Pin: 177030,
        DispatchCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        OriginCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        FacilityCity: "Hamirpur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4106,
        Pin: 848127,
        DispatchCenter: "Samastipur_RhmprRdli_D (Bihar)",
        OriginCenter: "Samastipur_RhmprRdli_D (Bihar)",
        FacilityCity: "Samastipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4107,
        Pin: 344021,
        DispatchCenter: "Siwana_Natiyacolony_D (Rajasthan)",
        OriginCenter: "Siwana_Natiyacolony_D (Rajasthan)",
        FacilityCity: "Siwana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4108,
        Pin: 125001,
        DispatchCenter: "Hisar_Dabra_H (Haryana)",
        OriginCenter: "Hisar_Dabra_H (Haryana)",
        FacilityCity: "Hisar",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4109,
        Pin: 272195,
        DispatchCenter: "Domariyaganj_Belgari_D (Uttar Pradesh)",
        OriginCenter: "Domariyaganj_Belgari_D (Uttar Pradesh)",
        FacilityCity: "Domariyaganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4110,
        Pin: 571106,
        DispatchCenter: "Hunsur_IndustrialEstate_D (Karnataka)",
        OriginCenter: "Hunsur_IndustrialEstate_D (Karnataka)",
        FacilityCity: "Hunsur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4111,
        Pin: 847412,
        DispatchCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        OriginCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        FacilityCity: "Jhanjharpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4112,
        Pin: 582210,
        DispatchCenter: "Gadag_Laxmeshwar_D (Karnataka)",
        OriginCenter: "Gadag_Laxmeshwar_D (Karnataka)",
        FacilityCity: "Gadag",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4113,
        Pin: 805106,
        DispatchCenter: "Pakribarwan_Sarauni_DPP (Bihar)",
        OriginCenter: "Pakribarwan_Sarauni_DPP (Bihar)",
        FacilityCity: "Pakribarwan",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4114,
        Pin: 440012,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4115,
        Pin: 767061,
        DispatchCenter: "Loisingha_Omshanticolony_D (Orissa)",
        OriginCenter: "Loisingha_Omshanticolony_D (Orissa)",
        FacilityCity: "Loisingha",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4116,
        Pin: 586116,
        DispatchCenter: "Muddebihal_Hudcocolony_D (Karnataka)",
        OriginCenter: "Muddebihal_Hudcocolony_D (Karnataka)",
        FacilityCity: "Muddebihal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4117,
        Pin: 756019,
        DispatchCenter: "Balasore_Ganeswarpur_D (Orissa)",
        OriginCenter: "Balasore_Ganeswarpur_D (Orissa)",
        FacilityCity: "Balasore",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4118,
        Pin: 585301,
        DispatchCenter: "Afzalpur_Usmaniacolony_D (Karnataka)",
        OriginCenter: "Afzalpur_Usmaniacolony_D (Karnataka)",
        FacilityCity: "Afzalpur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4119,
        Pin: 562111,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4120,
        Pin: 722101,
        DispatchCenter: "Bankura_KeranDPP_D (West Bengal)",
        OriginCenter: "Bankura_KeranDPP_D (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4121,
        Pin: 828101,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4122,
        Pin: 737101,
        DispatchCenter: "Gangtok_Vishalgaon_D (Sikkim)",
        OriginCenter: "Gangtok_Vishalgaon_D (Sikkim)",
        FacilityCity: "Gangtok",
        FacilityState: "Sikkim",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4123,
        Pin: 636406,
        DispatchCenter: "Mettur_Samballi_D (Tamil Nadu)",
        OriginCenter: "Mettur_Samballi_D (Tamil Nadu)",
        FacilityCity: "Mettur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4124,
        Pin: 602022,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4125,
        Pin: 855113,
        DispatchCenter: "Katihar_Salmari_D (Bihar)",
        OriginCenter: "Katihar_Salmari_D (Bihar)",
        FacilityCity: "Katihar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4126,
        Pin: 712409,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4127,
        Pin: 736165,
        DispatchCenter: "CoochBehar_khagrabari_I (West Bengal)",
        OriginCenter: "CoochBehar_khagrabari_I (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4128,
        Pin: 384130,
        DispatchCenter: "Unjha_SatymComplx_D (Gujarat)",
        OriginCenter: "Unjha_SatymComplx_D (Gujarat)",
        FacilityCity: "Unjha",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4129,
        Pin: 721502,
        DispatchCenter: "Jhargram_Raghunathpur_DPP (West Bengal)",
        OriginCenter: "Jhargram_Raghunathpur_DPP (West Bengal)",
        FacilityCity: "Jhargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4130,
        Pin: 142023,
        DispatchCenter: "Jagraon_Grainmkt_D (Punjab)",
        OriginCenter: "Jagraon_Grainmkt_D (Punjab)",
        FacilityCity: "Jagraon",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4131,
        Pin: 517167,
        DispatchCenter: "Chittoor_Srpuram_D (Andhra Pradesh)",
        OriginCenter: "Chittoor_Srpuram_D (Andhra Pradesh)",
        FacilityCity: "Chittoor",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4132,
        Pin: 412215,
        DispatchCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        OriginCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        FacilityCity: "Mahad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4133,
        Pin: 571801,
        DispatchCenter: "Kollegala_IndiraClny_D (Karnataka)",
        OriginCenter: "Kollegala_IndiraClny_D (Karnataka)",
        FacilityCity: "Kollegala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4134,
        Pin: 671351,
        DispatchCenter: "Parappa_Balal_D (Kerala)",
        OriginCenter: "Parappa_Balal_D (Kerala)",
        FacilityCity: "Parappa",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4135,
        Pin: 441103,
        DispatchCenter: "Katol_Laxmingr_D (Maharashtra)",
        OriginCenter: "Katol_Laxmingr_D (Maharashtra)",
        FacilityCity: "Katol",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4136,
        Pin: 788801,
        DispatchCenter: "Karimganj_Gorkapan_D (Assam)",
        OriginCenter: "Karimganj_Gorkapan_D (Assam)",
        FacilityCity: "Karimganj",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4137,
        Pin: 250402,
        DispatchCenter: "Mawana_Veernagar_D (Uttar Pradesh)",
        OriginCenter: "Mawana_Veernagar_D (Uttar Pradesh)",
        FacilityCity: "Mawana",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4138,
        Pin: 491885,
        DispatchCenter: "Khairagarh_Mudpar_DPP (Chhattisgarh)",
        OriginCenter: "Khairagarh_Mudpar_DPP (Chhattisgarh)",
        FacilityCity: "Khairagarh",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4139,
        Pin: 273408,
        DispatchCenter: "Barhalganj_Lakhnapar_DPP (Uttar Pradesh)",
        OriginCenter: "Barhalganj_Lakhnapar_DPP (Uttar Pradesh)",
        FacilityCity: "Barhalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4140,
        Pin: 458667,
        DispatchCenter: "Mandsaur_Dalodarail_D (Madhya Pradesh)",
        OriginCenter: "Mandsaur_Dalodarail_D (Madhya Pradesh)",
        FacilityCity: "Mandsaur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 4141,
        Pin: 275202,
        DispatchCenter: "Dullahpur_Sarsena_D (Uttar Pradesh)",
        OriginCenter: "Dullahpur_Sarsena_D (Uttar Pradesh)",
        FacilityCity: "Dullahpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4142,
        Pin: 814148,
        DispatchCenter: "Rangalia_JibnprMore_DPP (Jharkhand)",
        OriginCenter: "Rangalia_JibnprMore_DPP (Jharkhand)",
        FacilityCity: "Rangalia",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4143,
        Pin: 743427,
        DispatchCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        OriginCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        FacilityCity: "Basirhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4144,
        Pin: 136130,
        DispatchCenter: "Pehowa_ModelTown_DPP (Haryana)",
        OriginCenter: "Pehowa_ModelTown_DPP (Haryana)",
        FacilityCity: "Pehowa",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4145,
        Pin: 230126,
        DispatchCenter: "LalganjAjhara_HPPump_D (Uttar Pradesh)",
        OriginCenter: "LalganjAjhara_HPPump_D (Uttar Pradesh)",
        FacilityCity: "Lalganj Ajhara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4146,
        Pin: 314801,
        DispatchCenter: "Dungarpur_Bhichhiwara_DPP (Rajasthan)",
        OriginCenter: "Dungarpur_Bhichhiwara_DPP (Rajasthan)",
        FacilityCity: "Simalwara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4147,
        Pin: 814143,
        DispatchCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        OriginCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        FacilityCity: "Deoghar",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4148,
        Pin: 391220,
        DispatchCenter: "Karjan_Junabzr_DC (Gujarat)",
        OriginCenter: "Karjan_Junabzr_DC (Gujarat)",
        FacilityCity: "Karjan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4149,
        Pin: 678596,
        DispatchCenter: "Karimba_Kaliyode_D (Kerala)",
        OriginCenter: "Karimba_Kaliyode_D (Kerala)",
        FacilityCity: "Karimba",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4150,
        Pin: 273301,
        DispatchCenter: "Kaptanganj_Subshngr_D (Uttar Pradesh)",
        OriginCenter: "Kaptanganj_Subshngr_D (Uttar Pradesh)",
        FacilityCity: "Kaptanganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4151,
        Pin: 321401,
        DispatchCenter: "Bayana_Shivcolony_D (Rajasthan)",
        OriginCenter: "Bayana_Shivcolony_D (Rajasthan)",
        FacilityCity: "Bayana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4152,
        Pin: 685533,
        DispatchCenter: "Kumily_Attappallam_D (Kerala)",
        OriginCenter: "Kumily_Attappallam_D (Kerala)",
        FacilityCity: "Kumily",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4153,
        Pin: 441605,
        DispatchCenter: "Gondia_Mundipar_I (Maharashtra)",
        OriginCenter: "Gondia_Mundipar_I (Maharashtra)",
        FacilityCity: "Gondia",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4154,
        Pin: 263601,
        DispatchCenter: "Almora_Makedi_D (Uttarakhand)",
        OriginCenter: "Almora_Makedi_D (Uttarakhand)",
        FacilityCity: "Almora",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4155,
        Pin: 821111,
        DispatchCenter: "Kudra_Wadar_D (Bihar)",
        OriginCenter: "Kudra_Wadar_D (Bihar)",
        FacilityCity: "Kudra",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4156,
        Pin: 132118,
        DispatchCenter: "Kurukshetra_DDColony_D (Haryana)",
        OriginCenter: "Kurukshetra_DDColony_D (Haryana)",
        FacilityCity: "Kurukshetra",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4157,
        Pin: 411061,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4158,
        Pin: 612902,
        DispatchCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        OriginCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        FacilityCity: "Jayankondam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4159,
        Pin: 691311,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4160,
        Pin: 635854,
        DispatchCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        OriginCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        FacilityCity: "Tiruppattur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4161,
        Pin: 442501,
        DispatchCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        OriginCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        FacilityCity: "Chandrapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4162,
        Pin: 788168,
        DispatchCenter: "Hailakandi_Lala_D (Assam)",
        OriginCenter: "Hailakandi_Lala_D (Assam)",
        FacilityCity: "Hailakandi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4163,
        Pin: 244241,
        DispatchCenter: "Gajraula_JawaharN_D (Uttar Pradesh)",
        OriginCenter: "Gajraula_JawaharN_D (Uttar Pradesh)",
        FacilityCity: "Gajraula",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4164,
        Pin: 415305,
        DispatchCenter: "Vita_Adarshnagar_D (Maharashtra)",
        OriginCenter: "Vita_Adarshnagar_D (Maharashtra)",
        FacilityCity: "Vita",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4165,
        Pin: 506343,
        DispatchCenter: "Mulugu_MaharshiClg_D (Telangana)",
        OriginCenter: "Mulugu_MaharshiClg_D (Telangana)",
        FacilityCity: "Mulugu",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4166,
        Pin: 799142,
        DispatchCenter: "Santirbazar_Charakbai_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Santirbazar",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4167,
        Pin: 638183,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4168,
        Pin: 796421,
        DispatchCenter: "Aizawl_Sairang_I (Mizoram)",
        OriginCenter: "Aizawl_Sairang_I (Mizoram)",
        FacilityCity: "Aizawl",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4169,
        Pin: 243630,
        DispatchCenter: "Budaun_Dataganj_D (Uttar Pradesh)",
        OriginCenter: "Budaun_Dataganj_D (Uttar Pradesh)",
        FacilityCity: "Dataganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4170,
        Pin: 361101,
        DispatchCenter: "Jamnagar_Chela_L (Gujarat)",
        OriginCenter: "Jamnagar_Chela_L (Gujarat)",
        FacilityCity: "Jamnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4171,
        Pin: 753004,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4172,
        Pin: 110005,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4173,
        Pin: 313013,
        DispatchCenter: "Udaipur_Balicha_H (Rajasthan)",
        OriginCenter: "Udaipur_Balicha_H (Rajasthan)",
        FacilityCity: "Udaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4174,
        Pin: 140117,
        DispatchCenter: "AnandpurSahib_Dher_D (Punjab)",
        OriginCenter: "AnandpurSahib_Dher_D (Punjab)",
        FacilityCity: "Anandpur Sahib",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4175,
        Pin: 686021,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4176,
        Pin: 493885,
        DispatchCenter: "Abhanpur_Gatapr_D (Chhattisgarh)",
        OriginCenter: "Abhanpur_Gatapr_D (Chhattisgarh)",
        FacilityCity: "Abhanpur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4177,
        Pin: 341520,
        DispatchCenter: "Makrana_Mathabhar_D (Rajasthan)",
        OriginCenter: "Makrana_Mathabhar_D (Rajasthan)",
        FacilityCity: "Makrana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4178,
        Pin: 621010,
        DispatchCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        OriginCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        FacilityCity: "Thuraiyur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4179,
        Pin: 221104,
        DispatchCenter: "Cholapur_Katari_D (Uttar Pradesh)",
        OriginCenter: "Cholapur_Katari_D (Uttar Pradesh)",
        FacilityCity: "Cholapur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4180,
        Pin: 561201,
        DispatchCenter: "Magadi_Hosapete_D (Karnataka)",
        OriginCenter: "Magadi_Hosapete_D (Karnataka)",
        FacilityCity: "Magadi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4181,
        Pin: 224158,
        DispatchCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        OriginCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        FacilityCity: "Kumarganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4182,
        Pin: 586130,
        DispatchCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        OriginCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        FacilityCity: "Bijapur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4183,
        Pin: 461114,
        DispatchCenter: "Itarsi_AwnNgr_D (Madhya Pradesh)",
        OriginCenter: "Itarsi_AwnNgr_D (Madhya Pradesh)",
        FacilityCity: "Itarsi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 4184,
        Pin: 768002,
        DispatchCenter: "Sambalpur_Tentelpara_H (Orissa)",
        OriginCenter: "Sambalpur_Tentelpara_H (Orissa)",
        FacilityCity: "Sambalpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4185,
        Pin: 500855,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_Medchal_GW (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4186,
        Pin: 713165,
        DispatchCenter: "Manteswar_MemariRD_D (West Bengal)",
        OriginCenter: "Manteswar_MemariRD_D (West Bengal)",
        FacilityCity: "Manteswar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4187,
        Pin: 209867,
        DispatchCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        OriginCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        FacilityCity: "Unnao",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4188,
        Pin: 577430,
        DispatchCenter: "Sagara_Hotel_D (Karnataka)",
        OriginCenter: "Sagara_Hotel_D (Karnataka)",
        FacilityCity: "Sagara",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4189,
        Pin: 757052,
        DispatchCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        OriginCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        FacilityCity: "Betnoti",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4190,
        Pin: 401401,
        DispatchCenter: "Palghar_Maan_L (Maharashtra)",
        OriginCenter: "Palghar_Maan_L (Maharashtra)",
        FacilityCity: "Palghar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4191,
        Pin: 224195,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4192,
        Pin: 391440,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4193,
        Pin: 463106,
        DispatchCenter: "Berasia_Busstand_DPP (Madhya Pradesh)",
        OriginCenter: "Berasia_Busstand_DPP (Madhya Pradesh)",
        FacilityCity: "Berasia",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 4194,
        Pin: 325001,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4195,
        Pin: 411010,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4196,
        Pin: 606203,
        DispatchCenter: "Kallakurichi_Neelamangalam_D (Tamil Nadu)",
        OriginCenter: "Kallakurichi_Neelamangalam_D (Tamil Nadu)",
        FacilityCity: "Kallakurichi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4197,
        Pin: 629102,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4198,
        Pin: 505405,
        DispatchCenter: "Sircilla_Gopalnagar_D (Telangana)",
        OriginCenter: "Sircilla_Gopalnagar_D (Telangana)",
        FacilityCity: "Sircilla",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4199,
        Pin: 576102,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4200,
        Pin: 425428,
        DispatchCenter: "Shirpur_KrantiNgr_D (Maharashtra)",
        OriginCenter: "Shirpur_KrantiNgr_D (Maharashtra)",
        FacilityCity: "Shirpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4201,
        Pin: 501121,
        DispatchCenter: "Vikarabad_OldGunj_D (Telangana)",
        OriginCenter: "Vikarabad_OldGunj_D (Telangana)",
        FacilityCity: "Vikarabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4202,
        Pin: 182311,
        DispatchCenter: "Reasi_Marhi_D (Jammu & Kashmir)",
        OriginCenter: "Reasi_Marhi_D (Jammu & Kashmir)",
        FacilityCity: "Reasi",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4203,
        Pin: 509385,
        DispatchCenter: "Tadoor_Nagarkurnool_D (Telangana)",
        OriginCenter: "Tadoor_Nagarkurnool_D (Telangana)",
        FacilityCity: "Tadoor",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4204,
        Pin: 786613,
        DispatchCenter: "Moranhat_TengaDollong_D (Assam)",
        OriginCenter: "Moranhat_TengaDollong_D (Assam)",
        FacilityCity: "Moranhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4205,
        Pin: 500486,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4206,
        Pin: 400033,
        DispatchCenter: "Mumbai_Sewri_L (Maharashtra)",
        OriginCenter: "Mumbai_Sewri_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4207,
        Pin: 799289,
        DispatchCenter: "Aambasa_Natunpalli_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Aambasa",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4208,
        Pin: 132145,
        DispatchCenter: "Panipat_Risalu_P (Haryana)",
        OriginCenter: "Panipat_Risalu_P (Haryana)",
        FacilityCity: "Panipat",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4209,
        Pin: 635109,
        DispatchCenter: "Hosur_SipcotPhase1_L (Tamil Nadu)",
        OriginCenter: "Hosur_SipcotPhase1_L (Tamil Nadu)",
        FacilityCity: "Hosur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4210,
        Pin: 686651,
        DispatchCenter: "Pala_Elappunkal_D (Kerala)",
        OriginCenter: "Pala_Elappunkal_D (Kerala)",
        FacilityCity: "Pala",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4211,
        Pin: 695029,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4212,
        Pin: 425310,
        DispatchCenter: "Malkapur_DwarkaHosp_DPP (Maharashtra)",
        OriginCenter: "Malkapur_DwarkaHosp_DPP (Maharashtra)",
        FacilityCity: "Malkapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4213,
        Pin: 176313,
        DispatchCenter: "Nurpur_Sadwan_DPP (Himachal Pradesh)",
        OriginCenter: "Nurpur_Sadwan_DPP (Himachal Pradesh)",
        FacilityCity: "Nurpur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4214,
        Pin: 387620,
        DispatchCenter: "Kapadvanj_Dholikui_D (Gujarat)",
        OriginCenter: "Kapadvanj_Dholikui_D (Gujarat)",
        FacilityCity: "Kapadvanj",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4215,
        Pin: 275203,
        DispatchCenter: "Dullahpur_Bhurkura_D (Uttar Pradesh)",
        OriginCenter: "Dullahpur_Bhurkura_D (Uttar Pradesh)",
        FacilityCity: "Dullahpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4216,
        Pin: 574110,
        DispatchCenter: "Karkala_JodursteJ_D (Karnataka)",
        OriginCenter: "Karkala_JodursteJ_D (Karnataka)",
        FacilityCity: "Karkala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4217,
        Pin: 171218,
        DispatchCenter: "Shimla_Chail_DPP (Himachal Pradesh)",
        OriginCenter: "Shimla_Chail_DPP (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4218,
        Pin: 505325,
        DispatchCenter: "Metpally_GunjRDPP_D (Telangana)",
        OriginCenter: "Metpally_GunjRDPP_D (Telangana)",
        FacilityCity: "Metpally",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4219,
        Pin: 743194,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4220,
        Pin: 263632,
        DispatchCenter: "Bageshwar_Kapkot_DPP (Uttarakhand)",
        OriginCenter: "Bageshwar_Kapkot_DPP (Uttarakhand)",
        FacilityCity: "Bageshwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4221,
        Pin: 326515,
        DispatchCenter: "Chaumahla_RapaKhra_DPP (Rajasthan)",
        OriginCenter: "Chaumahla_RapaKhra_DPP (Rajasthan)",
        FacilityCity: "Chaumahla",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4222,
        Pin: 425509,
        DispatchCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        OriginCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        FacilityCity: "Raver",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4223,
        Pin: 800008,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4224,
        Pin: 146106,
        DispatchCenter: "Garhshankar_BangaRoad_D (Punjab)",
        OriginCenter: "Garhshankar_BangaRoad_D (Punjab)",
        FacilityCity: "Garhshanker",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4225,
        Pin: 396007,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4226,
        Pin: 590004,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4227,
        Pin: 389365,
        DispatchCenter: "Halol_DhavalPark_D (Gujarat)",
        OriginCenter: "Halol_DhavalPark_D (Gujarat)",
        FacilityCity: "Halol",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4228,
        Pin: 494001,
        DispatchCenter: "Jagdalpur_Hatkchora_D (Chhattisgarh)",
        OriginCenter: "Jagdalpur_Hatkchora_D (Chhattisgarh)",
        FacilityCity: "Jagdalpur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4229,
        Pin: 689112,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4230,
        Pin: 686145,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4231,
        Pin: 691520,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4232,
        Pin: 574279,
        DispatchCenter: "Vitla_FawzBuldng_DPP (Karnataka)",
        OriginCenter: "Vitla_FawzBuldng_DPP (Karnataka)",
        FacilityCity: "Vitla",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4233,
        Pin: 396510,
        DispatchCenter: "Vansda_Chikatiya_D (Gujarat)",
        OriginCenter: "Vansda_Chikatiya_D (Gujarat)",
        FacilityCity: "Vansda",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4234,
        Pin: 313905,
        DispatchCenter: "Sarada_Kachhota_DPP (Rajasthan)",
        OriginCenter: "Sarada_Kachhota_DPP (Rajasthan)",
        FacilityCity: "Sarada",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4235,
        Pin: 423604,
        DispatchCenter: "Kopargaon_Murshatpur_D (Maharashtra)",
        OriginCenter: "Kopargaon_Murshatpur_D (Maharashtra)",
        FacilityCity: "Kopargaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4236,
        Pin: 784501,
        DispatchCenter: "Tezpur_Dipota_H (Assam)",
        OriginCenter: "Tezpur_Dipota_H (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4237,
        Pin: 700069,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4238,
        Pin: 854318,
        DispatchCenter: "Forbesganj_Jummanchowk_D (Bihar)",
        OriginCenter: "Forbesganj_Jummanchowk_D (Bihar)",
        FacilityCity: "Forbesganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4239,
        Pin: 314406,
        DispatchCenter: "Simalwara_PithRd_DPP (Rajasthan)",
        OriginCenter: "Simalwara_PithRd_DPP (Rajasthan)",
        FacilityCity: "Simalwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4240,
        Pin: 586127,
        DispatchCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        OriginCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        FacilityCity: "Bijapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4241,
        Pin: 623523,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4242,
        Pin: 177104,
        DispatchCenter: "DehraGopipur_Central_D_1 (Himachal Pradesh)",
        OriginCenter: "DehraGopipur_Central_D_1 (Himachal Pradesh)",
        FacilityCity: "Dehra Gopipur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4243,
        Pin: 506135,
        DispatchCenter: "Gudur_Chandrugudem_DPP (Telangana)",
        OriginCenter: "Gudur_Chandrugudem_DPP (Telangana)",
        FacilityCity: "Gudur-TS",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4244,
        Pin: 580021,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4245,
        Pin: 682021,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4246,
        Pin: 630006,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4247,
        Pin: 678504,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4248,
        Pin: 522306,
        DispatchCenter: "Tenali_Duggirala_D (Andhra Pradesh)",
        OriginCenter: "Tenali_Duggirala_D (Andhra Pradesh)",
        FacilityCity: "Tenali",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4249,
        Pin: 523104,
        DispatchCenter: "Singarayakonda_TrunkRd_D (Andhra Pradesh)",
        OriginCenter: "Singarayakonda_TrunkRd_D (Andhra Pradesh)",
        FacilityCity: "Singarayakonda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4250,
        Pin: 416503,
        DispatchCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        OriginCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        FacilityCity: "Gadhinglaj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4251,
        Pin: 174317,
        DispatchCenter: "UnaHP_NangalRd_D (Himachal Pradesh)",
        OriginCenter: "UnaHP_NangalRd_D (Himachal Pradesh)",
        FacilityCity: "Una-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4252,
        Pin: 313603,
        DispatchCenter: "Bhatewar_HoliChowk_DPP (Rajasthan)",
        OriginCenter: "Bhatewar_HoliChowk_DPP (Rajasthan)",
        FacilityCity: "Bhatewar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4253,
        Pin: 484116,
        DispatchCenter: "Anuppur_Chetnanagar_D (Madhya Pradesh)",
        OriginCenter: "Anuppur_Chetnanagar_D (Madhya Pradesh)",
        FacilityCity: "Anuppur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 4254,
        Pin: 825410,
        DispatchCenter: "Domchanch_Jhumritelaiya_D (Jharkhand)",
        OriginCenter: "Domchanch_Jhumritelaiya_D (Jharkhand)",
        FacilityCity: "Domchanch",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4255,
        Pin: 686638,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4256,
        Pin: 686664,
        DispatchCenter: "Kanjiramattom_Piravom_D (Kerala)",
        OriginCenter: "Kanjiramattom_Piravom_D (Kerala)",
        FacilityCity: "Kanjiramattom",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4257,
        Pin: 411022,
        DispatchCenter: "Pune_Fursungi_L (Maharashtra)",
        OriginCenter: "Pune_Fursungi_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4258,
        Pin: 486661,
        DispatchCenter: "Sidhi_Padra_D (Madhya Pradesh)",
        OriginCenter: "Sidhi_Padra_D (Madhya Pradesh)",
        FacilityCity: "Sidhi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 4259,
        Pin: 445203,
        DispatchCenter: "Digras_Virajcplx_DPP (Maharashtra)",
        OriginCenter: "Digras_Virajcplx_DPP (Maharashtra)",
        FacilityCity: "Digras",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4260,
        Pin: 401104,
        DispatchCenter: "Mumbai_Kandivali_L (Maharashtra)",
        OriginCenter: "Mumbai_Kandivali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4261,
        Pin: 277403,
        DispatchCenter: "Bairia_Bhagwanpur_DPP (Uttar Pradesh)",
        OriginCenter: "Bairia_Bhagwanpur_DPP (Uttar Pradesh)",
        FacilityCity: "Bariya",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4262,
        Pin: 461661,
        DispatchCenter: "Hoshangabad_BabaiRd_DPP (Madhya Pradesh)",
        OriginCenter: "Hoshangabad_BabaiRd_DPP (Madhya Pradesh)",
        FacilityCity: "Hoshangabad",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 4263,
        Pin: 722174,
        DispatchCenter: "Bankura_KeranDPP_D (West Bengal)",
        OriginCenter: "Bankura_KeranDPP_D (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4264,
        Pin: 532403,
        DispatchCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        OriginCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        FacilityCity: "Srikakulam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4265,
        Pin: 522262,
        DispatchCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        OriginCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        FacilityCity: "Repalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4266,
        Pin: 848210,
        DispatchCenter: "Rosera_HanumanTemple_D (Bihar)",
        OriginCenter: "Rosera_HanumanTemple_D (Bihar)",
        FacilityCity: "Rusera",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4267,
        Pin: 382002,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4268,
        Pin: 756033,
        DispatchCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        OriginCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        FacilityCity: "Dantan",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4269,
        Pin: 177209,
        DispatchCenter: "UnaHP_NangalRd_D (Himachal Pradesh)",
        OriginCenter: "UnaHP_NangalRd_D (Himachal Pradesh)",
        FacilityCity: "Una-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4270,
        Pin: 143110,
        DispatchCenter: "Amritsar_Ajnala_D (Punjab)",
        OriginCenter: "Amritsar_Ajnala_D (Punjab)",
        FacilityCity: "Ajnala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4271,
        Pin: 609307,
        DispatchCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        OriginCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        FacilityCity: "Karaikal",
        FacilityState: "Pondicherry",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4272,
        Pin: 462002,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 4273,
        Pin: 690503,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4274,
        Pin: 638451,
        DispatchCenter: "Sathyamangalam_VNSNagar_D (Tamil Nadu)",
        OriginCenter: "Sathyamangalam_VNSNagar_D (Tamil Nadu)",
        FacilityCity: "Sathyamangalam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4275,
        Pin: 362625,
        DispatchCenter: "Manavadar_Ratanpara_DPP (Gujarat)",
        OriginCenter: "Manavadar_Ratanpara_DPP (Gujarat)",
        FacilityCity: "Manavadar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4276,
        Pin: 509360,
        DispatchCenter: "Kalwakurthy_Margadarshi_D (Telangana)",
        OriginCenter: "Kalwakurthy_Margadarshi_D (Telangana)",
        FacilityCity: "Kalwakurthy",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4277,
        Pin: 344026,
        DispatchCenter: "Balesar_Unthwaliya_DPP (Rajasthan)",
        OriginCenter: "Balesar_Unthwaliya_DPP (Rajasthan)",
        FacilityCity: "Balesar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4278,
        Pin: 673607,
        DispatchCenter: "Areekode_Puthlam_D (Kerala)",
        OriginCenter: "Areekode_Puthlam_D (Kerala)",
        FacilityCity: "Areacode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4279,
        Pin: 272205,
        DispatchCenter: "Naugarh_Shohratgarh_D (Uttar Pradesh)",
        OriginCenter: "Naugarh_Shohratgarh_D (Uttar Pradesh)",
        FacilityCity: "Naugarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4280,
        Pin: 251317,
        DispatchCenter: "Deoband_Shivpuricolony_D (Uttar Pradesh)",
        OriginCenter: "Deoband_Shivpuricolony_D (Uttar Pradesh)",
        FacilityCity: "Deoband",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4281,
        Pin: 632505,
        DispatchCenter: "Sholinghur_Pulanji_D (Tamil Nadu)",
        OriginCenter: "Sholinghur_Pulanji_D (Tamil Nadu)",
        FacilityCity: "Sholinghur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4282,
        Pin: 193504,
        DispatchCenter: "Bandipora_Sumbal_D (Jammu & Kashmir)",
        OriginCenter: "Bandipora_Sumbal_D (Jammu & Kashmir)",
        FacilityCity: "Bandipora",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4283,
        Pin: 305402,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4284,
        Pin: 503306,
        DispatchCenter: "Banswada_Teacherscolony_D (Telangana)",
        OriginCenter: "Banswada_Teacherscolony_D (Telangana)",
        FacilityCity: "Banswada",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4285,
        Pin: 799144,
        DispatchCenter: "Santirbazar_Charakbai_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Santirbazar",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4286,
        Pin: 573129,
        DispatchCenter: "Hassan_Byrapura_D (Karnataka)",
        OriginCenter: "Hassan_Byrapura_D (Karnataka)",
        FacilityCity: "Hassan",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4287,
        Pin: 229413,
        DispatchCenter: "Lalgopalganj_Anandi_D (Uttar Pradesh)",
        OriginCenter: "Lalgopalganj_Anandi_D (Uttar Pradesh)",
        FacilityCity: "Lal Gopalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4288,
        Pin: 233300,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4289,
        Pin: 743101,
        DispatchCenter: "Barrackpore_Lalkuti_D (West Bengal)",
        OriginCenter: "Barrackpore_Lalkuti_D (West Bengal)",
        FacilityCity: "Barrackpore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4290,
        Pin: 313329,
        DispatchCenter: "Fatehnagar_Railmagra_DPP (Rajasthan)",
        OriginCenter: "Fatehnagar_Railmagra_DPP (Rajasthan)",
        FacilityCity: "Fatehnagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4291,
        Pin: 422201,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4292,
        Pin: 623806,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4293,
        Pin: 781360,
        DispatchCenter: "Tamulpur_Kalakuchi_D (Assam)",
        OriginCenter: "Tamulpur_Kalakuchi_D (Assam)",
        FacilityCity: "Tamulpur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4294,
        Pin: 173001,
        DispatchCenter: "Nahan_NarulaNagar_D (Himachal Pradesh)",
        OriginCenter: "Nahan_NarulaNagar_D (Himachal Pradesh)",
        FacilityCity: "Nahan",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4295,
        Pin: 560036,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4296,
        Pin: 743130,
        DispatchCenter: "Bongaon_SukntDPP_D (West Bengal)",
        OriginCenter: "Bongaon_SukntDPP_D (West Bengal)",
        FacilityCity: "Bongaon",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4297,
        Pin: 609314,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4298,
        Pin: 473118,
        DispatchCenter: "Binaganj_GoyaRd_DPP (Madhya Pradesh)",
        OriginCenter: "Binaganj_GoyaRd_DPP (Madhya Pradesh)",
        FacilityCity: "Binaganj",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 4299,
        Pin: 364465,
        DispatchCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        OriginCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4300,
        Pin: 761104,
        DispatchCenter: "Asika_DsarathiNgr_DPP (Orissa)",
        OriginCenter: "Asika_DsarathiNgr_DPP (Orissa)",
        FacilityCity: "Asika",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4301,
        Pin: 833214,
        DispatchCenter: "Jagannathpur_Hatgamhariya_D (Jharkhand)",
        OriginCenter: "Jagannathpur_Hatgamhariya_D (Jharkhand)",
        FacilityCity: "Jagannathpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4302,
        Pin: 673611,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4303,
        Pin: 243006,
        DispatchCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        OriginCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        FacilityCity: "Bareilly",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4304,
        Pin: 621018,
        DispatchCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        OriginCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        FacilityCity: "Thuraiyur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4305,
        Pin: 574264,
        DispatchCenter: "Bantwal_BambilRd_D (Karnataka)",
        OriginCenter: "Bantwal_BambilRd_D (Karnataka)",
        FacilityCity: "Bantwal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4306,
        Pin: 635116,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4307,
        Pin: 457887,
        DispatchCenter: "Alirajpur_Sorvanaka_DPP (Madhya Pradesh)",
        OriginCenter: "Alirajpur_Sorvanaka_DPP (Madhya Pradesh)",
        FacilityCity: "Alirajpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 4308,
        Pin: 803213,
        DispatchCenter: "Barh_Malahi_D (Bihar)",
        OriginCenter: "Barh_Malahi_D (Bihar)",
        FacilityCity: "Barh",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4309,
        Pin: 626129,
        DispatchCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        OriginCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        FacilityCity: "Aruppukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4310,
        Pin: 362715,
        DispatchCenter: "Kodinar_NCplxDPP_D (Gujarat)",
        OriginCenter: "Kodinar_NCplxDPP_D (Gujarat)",
        FacilityCity: "Kodinar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4311,
        Pin: 383275,
        DispatchCenter: "Khedbrahma_SaraswtiCmplx_DPP (Gujarat)",
        OriginCenter: "Khedbrahma_SaraswtiCmplx_DPP (Gujarat)",
        FacilityCity: "Khedbrahma",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4312,
        Pin: 629051,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4313,
        Pin: 802237,
        DispatchCenter: "Arrah_Anaith_D (Bihar)",
        OriginCenter: "Arrah_Anaith_D (Bihar)",
        FacilityCity: "Arrah",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4314,
        Pin: 152033,
        DispatchCenter: "Jalalabad_Gandhinagar_D (Punjab)",
        OriginCenter: "Jalalabad_Gandhinagar_D (Punjab)",
        FacilityCity: "Jalalabad",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4315,
        Pin: 841404,
        DispatchCenter: "Siwan_Aranda_D (Bihar)",
        OriginCenter: "Siwan_Aranda_D (Bihar)",
        FacilityCity: "Siwan",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4316,
        Pin: 391244,
        DispatchCenter: "Karjan_Junabzr_DC (Gujarat)",
        OriginCenter: "Karjan_Junabzr_DC (Gujarat)",
        FacilityCity: "Karjan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4317,
        Pin: 622404,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4318,
        Pin: 670721,
        DispatchCenter: "Pulpally_Adikolli_D (Kerala)",
        OriginCenter: "Pulpally_Adikolli_D (Kerala)",
        FacilityCity: "Pulpally",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4319,
        Pin: 462066,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 4320,
        Pin: 752012,
        DispatchCenter: "Puri_Purussottamnagar_D (Orissa)",
        OriginCenter: "Puri_Purussottamnagar_D (Orissa)",
        FacilityCity: "Puri",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4321,
        Pin: 623515,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4322,
        Pin: 689645,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4323,
        Pin: 624202,
        DispatchCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        OriginCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        FacilityCity: "Vadipatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4324,
        Pin: 591242,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4325,
        Pin: 764070,
        DispatchCenter: "Nabarangpr_PhdofDPP_D (Orissa)",
        OriginCenter: "Nabarangpr_PhdofDPP_D (Orissa)",
        FacilityCity: "Nabarangapur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4326,
        Pin: 501359,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4327,
        Pin: 591223,
        DispatchCenter: "Ugar_GopiHotel_D (Karnataka)",
        OriginCenter: "Ugar_GopiHotel_D (Karnataka)",
        FacilityCity: "Ugar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4328,
        Pin: 713339,
        DispatchCenter: "Asansol_Addacolony_D (West Bengal)",
        OriginCenter: "Asansol_Addacolony_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4329,
        Pin: 623520,
        DispatchCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        OriginCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        FacilityCity: "Rameswaram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4330,
        Pin: 577599,
        DispatchCenter: "Hiriyur_Vedvthingr_D (Karnataka)",
        OriginCenter: "Hiriyur_Vedvthingr_D (Karnataka)",
        FacilityCity: "Hiriyur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4331,
        Pin: 761014,
        DispatchCenter: "Digapahandi_Punjikanya_D (Orissa)",
        OriginCenter: "Digapahandi_Punjikanya_D (Orissa)",
        FacilityCity: "Digapahandi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4332,
        Pin: 506007,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4333,
        Pin: 641112,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4334,
        Pin: 781039,
        DispatchCenter: "Hajo_Singimari_D (Assam)",
        OriginCenter: "Hajo_Singimari_D (Assam)",
        FacilityCity: "Hajo",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4335,
        Pin: 311028,
        DispatchCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        OriginCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        FacilityCity: "Bhilwara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4336,
        Pin: 632010,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4337,
        Pin: 422308,
        DispatchCenter: "Lasalgaon_TelephnExch_D (Maharashtra)",
        OriginCenter: "Lasalgaon_TelephnExch_D (Maharashtra)",
        FacilityCity: "Lasalgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4338,
        Pin: 828301,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4339,
        Pin: 272170,
        DispatchCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        OriginCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        FacilityCity: "Basti",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4340,
        Pin: 781325,
        DispatchCenter: "Tihu_Barbari_D (Assam)",
        OriginCenter: "Tihu_Barbari_D (Assam)",
        FacilityCity: "Tihu",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4341,
        Pin: 262804,
        DispatchCenter: "Mohammadi_AdarshNagar_D (Uttar Pradesh)",
        OriginCenter: "Mohammadi_AdarshNagar_D (Uttar Pradesh)",
        FacilityCity: "Mohammadi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4342,
        Pin: 586201,
        DispatchCenter: "Muddebihal_Nekarcolony_D (Karnataka)",
        OriginCenter: "Muddebihal_Nekarcolony_D (Karnataka)",
        FacilityCity: "Muddebihal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4343,
        Pin: 682306,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4344,
        Pin: 515341,
        DispatchCenter: "Gorantla_Market_D (Andhra Pradesh)",
        OriginCenter: "Gorantla_Market_D (Andhra Pradesh)",
        FacilityCity: "Gorantla",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4345,
        Pin: 523291,
        DispatchCenter: "Kandukur_Gudlur_D (Andhra Pradesh)",
        OriginCenter: "Kandukur_Gudlur_D (Andhra Pradesh)",
        FacilityCity: "Kandukur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4346,
        Pin: 509408,
        DispatchCenter: "Kadthal_Masigandi_DPP (Telangana)",
        OriginCenter: "Kadthal_Masigandi_DPP (Telangana)",
        FacilityCity: "Kadthal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4347,
        Pin: 141202,
        DispatchCenter: "Raikot_DC (Punjab)",
        OriginCenter: "Raikot_DC (Punjab)",
        FacilityCity: "Raikot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4348,
        Pin: 586214,
        DispatchCenter: "Talikoti_GaneshNagar_D (Karnataka)",
        OriginCenter: "Talikoti_GaneshNagar_D (Karnataka)",
        FacilityCity: "Talikoti",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4349,
        Pin: 534239,
        DispatchCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        FacilityCity: "Bhimavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4350,
        Pin: 160071,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4351,
        Pin: 821301,
        DispatchCenter: "Sasaram_Manikpur_D (Bihar)",
        OriginCenter: "Sasaram_Manikpur_D (Bihar)",
        FacilityCity: "Sasaram",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4352,
        Pin: 613004,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4353,
        Pin: 680014,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4354,
        Pin: 571433,
        DispatchCenter: "Maddur_Malavalli_D (Karnataka)",
        OriginCenter: "Maddur_Malavalli_D (Karnataka)",
        FacilityCity: "Maddur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4355,
        Pin: 274603,
        DispatchCenter: "Barhaj_Nandnaward_D (Uttar Pradesh)",
        OriginCenter: "Barhaj_Nandnaward_D (Uttar Pradesh)",
        FacilityCity: "Barhaj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4356,
        Pin: 314404,
        DispatchCenter: "Dungarpur_IrolZoneCmplx_DPP (Rajasthan)",
        OriginCenter: "Dungarpur_IrolZoneCmplx_DPP (Rajasthan)",
        FacilityCity: "Dungarpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4357,
        Pin: 413705,
        DispatchCenter: "Rahuri_Mehetremala_D (Maharashtra)",
        OriginCenter: "Rahuri_Mehetremala_D (Maharashtra)",
        FacilityCity: "Rahuri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4358,
        Pin: 571189,
        DispatchCenter: "Hunsur_IndustrialEstate_D (Karnataka)",
        OriginCenter: "Hunsur_IndustrialEstate_D (Karnataka)",
        FacilityCity: "Hunsur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4359,
        Pin: 573219,
        DispatchCenter: "Hassan_Nagathavalli_I (Karnataka)",
        OriginCenter: "Hassan_Nagathavalli_I (Karnataka)",
        FacilityCity: "Hassan",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4360,
        Pin: 221207,
        DispatchCenter: "Babatpur_Pindra_D (Uttar Pradesh)",
        OriginCenter: "Babatpur_Pindra_D (Uttar Pradesh)",
        FacilityCity: "Jalalpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4361,
        Pin: 638702,
        DispatchCenter: "Dharapuram_Pirivu_D (Tamil Nadu)",
        OriginCenter: "Dharapuram_Pirivu_D (Tamil Nadu)",
        FacilityCity: "Dharapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4362,
        Pin: 845419,
        DispatchCenter: "Motihari_Lauriya_D (Bihar)",
        OriginCenter: "Motihari_Lauriya_D (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4363,
        Pin: 416515,
        DispatchCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        OriginCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        FacilityCity: "Sawantwadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4364,
        Pin: 531111,
        DispatchCenter: "Chintapalli_Sainagar_D (Andhra Pradesh)",
        OriginCenter: "Chintapalli_Sainagar_D (Andhra Pradesh)",
        FacilityCity: "Chintapalli",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4365,
        Pin: 522101,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4366,
        Pin: 387710,
        DispatchCenter: "Nadiad_Shantinagar_D (Gujarat)",
        OriginCenter: "Nadiad_Shantinagar_D (Gujarat)",
        FacilityCity: "Nadiad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4367,
        Pin: 411034,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4368,
        Pin: 851101,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4369,
        Pin: 626512,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4370,
        Pin: 799004,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4371,
        Pin: 574259,
        DispatchCenter: "Puttur_Darbe_D (Karnataka)",
        OriginCenter: "Puttur_Darbe_D (Karnataka)",
        FacilityCity: "Puttur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4372,
        Pin: 799211,
        DispatchCenter: "Agartala_Taranagar_DPP (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4373,
        Pin: 462034,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 4374,
        Pin: 518523,
        DispatchCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        OriginCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        FacilityCity: "Nandyal",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4375,
        Pin: 689122,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4376,
        Pin: 521137,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4377,
        Pin: 791123,
        DispatchCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        OriginCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        FacilityCity: "Itanagar",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4378,
        Pin: 788161,
        DispatchCenter: "Hailakandi_Lala_D (Assam)",
        OriginCenter: "Hailakandi_Lala_D (Assam)",
        FacilityCity: "Hailakandi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4379,
        Pin: 205262,
        DispatchCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        OriginCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        FacilityCity: "Mainpuri",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4380,
        Pin: 626122,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4381,
        Pin: 122013,
        DispatchCenter: "Gurgaon_Sector52_L (Haryana)",
        OriginCenter: "Gurgaon_Sector52_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4382,
        Pin: 244413,
        DispatchCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        OriginCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        FacilityCity: "Chandausi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4383,
        Pin: 769011,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4384,
        Pin: 515401,
        DispatchCenter: "Gooty_ATPRoad_D (Andhra Pradesh)",
        OriginCenter: "Gooty_ATPRoad_D (Andhra Pradesh)",
        FacilityCity: "Gooty",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4385,
        Pin: 502228,
        DispatchCenter: "Zahirabad_Mohim_D (Telangana)",
        OriginCenter: "Zahirabad_Mohim_D (Telangana)",
        FacilityCity: "Zahirabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4386,
        Pin: 229001,
        DispatchCenter: "Raebareli_Ratapur_D (Uttar Pradesh)",
        OriginCenter: "Raebareli_Ratapur_D (Uttar Pradesh)",
        FacilityCity: "Raebareli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4387,
        Pin: 639104,
        DispatchCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        OriginCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        FacilityCity: "Kulithalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4388,
        Pin: 176073,
        DispatchCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        OriginCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4389,
        Pin: 627802,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4390,
        Pin: 751021,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4391,
        Pin: 785001,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4392,
        Pin: 501505,
        DispatchCenter: "Hyderabad_Autonagar_L (Telangana)",
        OriginCenter: "Hyderabad_Autonagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4393,
        Pin: 682309,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4394,
        Pin: 184142,
        DispatchCenter: "Hiranagar_NH44_D (Jammu & Kashmir)",
        OriginCenter: "Hiranagar_NH44_D (Jammu & Kashmir)",
        FacilityCity: "Hiranagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4395,
        Pin: 721645,
        DispatchCenter: "Haldia_Basudevpur_D (West Bengal)",
        OriginCenter: "Haldia_Basudevpur_D (West Bengal)",
        FacilityCity: "Haldia",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4396,
        Pin: 504218,
        DispatchCenter: "Bellampalli_Rlwaycolny_DPP (Telangana)",
        OriginCenter: "Bellampalli_Rlwaycolny_DPP (Telangana)",
        FacilityCity: "Bellampalli",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4397,
        Pin: 304026,
        DispatchCenter: "Tonk_Bairwa_D (Rajasthan)",
        OriginCenter: "Tonk_Bairwa_D (Rajasthan)",
        FacilityCity: "Tonk",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4398,
        Pin: 415315,
        DispatchCenter: "Atapadi_VidyaNagar_D (Maharashtra)",
        OriginCenter: "Atapadi_VidyaNagar_D (Maharashtra)",
        FacilityCity: "Atapadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4399,
        Pin: 401601,
        DispatchCenter: "Dahanu_Ambemora_D (Maharashtra)",
        OriginCenter: "Dahanu_Ambemora_D (Maharashtra)",
        FacilityCity: "Dahanu",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4400,
        Pin: 400710,
        DispatchCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        OriginCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4401,
        Pin: 413511,
        DispatchCenter: "Osmanabad_Murud_D (Maharashtra)",
        OriginCenter: "Osmanabad_Murud_D (Maharashtra)",
        FacilityCity: "Osmanabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4402,
        Pin: 678548,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4403,
        Pin: 412213,
        DispatchCenter: "Khandala_Kapurhol_D (Maharashtra)",
        OriginCenter: "Khandala_Kapurhol_D (Maharashtra)",
        FacilityCity: "Khandala",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4404,
        Pin: 676306,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4405,
        Pin: 388220,
        DispatchCenter: "Umreth_PltinumPlza_DPP (Gujarat)",
        OriginCenter: "Umreth_PltinumPlza_DPP (Gujarat)",
        FacilityCity: "Umreth",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4406,
        Pin: 574216,
        DispatchCenter: "Belthangady_UjreMnRd_DPP (Karnataka)",
        OriginCenter: "Belthangady_UjreMnRd_DPP (Karnataka)",
        FacilityCity: "Belthangady",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4407,
        Pin: 533201,
        DispatchCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        OriginCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        FacilityCity: "Amalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4408,
        Pin: 382004,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4409,
        Pin: 152120,
        DispatchCenter: "Abohar_DaulatPura_D (Punjab)",
        OriginCenter: "Abohar_DaulatPura_D (Punjab)",
        FacilityCity: "Abohar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4410,
        Pin: 193302,
        DispatchCenter: "Kupwara_Chotipora_DPP (Jammu & Kashmir)",
        OriginCenter: "Kupwara_Chotipora_DPP (Jammu & Kashmir)",
        FacilityCity: "Kupwara",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4411,
        Pin: 177031,
        DispatchCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        OriginCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        FacilityCity: "Bangana",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4412,
        Pin: 273305,
        DispatchCenter: "Nichlaul_Gorahwa_D (Uttar Pradesh)",
        OriginCenter: "Nichlaul_Gorahwa_D (Uttar Pradesh)",
        FacilityCity: "Nichlaul",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4413,
        Pin: 635751,
        DispatchCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ambur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4414,
        Pin: 829121,
        DispatchCenter: "Peterbar_GagiDPP_D (Jharkhand)",
        OriginCenter: "Peterbar_GagiDPP_D (Jharkhand)",
        FacilityCity: "Peterbar",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4415,
        Pin: 182125,
        DispatchCenter: "Udhampur_BharatNagar_D (Jammu & Kashmir)",
        OriginCenter: "Udhampur_BharatNagar_D (Jammu & Kashmir)",
        FacilityCity: "Udhampur",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4416,
        Pin: 431111,
        DispatchCenter: "Phulambri_Oldcourt_DPP (Maharashtra)",
        OriginCenter: "Phulambri_Oldcourt_DPP (Maharashtra)",
        FacilityCity: "Phulambri",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4417,
        Pin: 635807,
        DispatchCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ambur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4418,
        Pin: 785105,
        DispatchCenter: "Majuli_Kthnibri_D (Assam)",
        OriginCenter: "Majuli_Kthnibri_D (Assam)",
        FacilityCity: "Majuli",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4419,
        Pin: 502287,
        DispatchCenter: "Narayankhed_Turkapally_D (Telangana)",
        OriginCenter: "Narayankhed_Turkapally_D (Telangana)",
        FacilityCity: "Narayankhed",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4420,
        Pin: 521328,
        DispatchCenter: "Avanigadda_Ramnagar_D (Andhra Pradesh)",
        OriginCenter: "Avanigadda_Ramnagar_D (Andhra Pradesh)",
        FacilityCity: "Avanigadda",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4421,
        Pin: 314023,
        DispatchCenter: "Salumbar_Aspur2_D (Rajasthan)",
        OriginCenter: "Salumbar_Aspur2_D (Rajasthan)",
        FacilityCity: "Salumbar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4422,
        Pin: 822101,
        DispatchCenter: "Daltonganj_SouthRedma_D (Jharkhand)",
        OriginCenter: "Daltonganj_SouthRedma_D (Jharkhand)",
        FacilityCity: "Daltonganj",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4423,
        Pin: 625101,
        DispatchCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        OriginCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        FacilityCity: "Melur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4424,
        Pin: 685612,
        DispatchCenter: "Munnar_IkkaNgr_D (Kerala)",
        OriginCenter: "Munnar_IkkaNgr_D (Kerala)",
        FacilityCity: "Munnar",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4425,
        Pin: 691322,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4426,
        Pin: 743443,
        DispatchCenter: "Joypul_Maniktala_D (West Bengal)",
        OriginCenter: "Joypul_Maniktala_D (West Bengal)",
        FacilityCity: "Joypul",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4427,
        Pin: 207503,
        DispatchCenter: "Farrukhabad_Shamsabad_D (Uttar Pradesh)",
        OriginCenter: "Farrukhabad_Shamsabad_D (Uttar Pradesh)",
        FacilityCity: "Farrukhabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4428,
        Pin: 431811,
        DispatchCenter: "Kinwat_Mahurrd_D (Maharashtra)",
        OriginCenter: "Kinwat_Mahurrd_D (Maharashtra)",
        FacilityCity: "Kinwat",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4429,
        Pin: 785611,
        DispatchCenter: "Dergaon_Rojabahar_DPP (Assam)",
        OriginCenter: "Dergaon_Rojabahar_DPP (Assam)",
        FacilityCity: "Dergaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4430,
        Pin: 689721,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4431,
        Pin: 764002,
        DispatchCenter: "Jeypore_Kundura1_DPP (Orissa)",
        OriginCenter: "Jeypore_Kundura1_DPP (Orissa)",
        FacilityCity: "Jeypore",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4432,
        Pin: 425311,
        DispatchCenter: "Malkapur_DwarkaHosp_DPP (Maharashtra)",
        OriginCenter: "Malkapur_DwarkaHosp_DPP (Maharashtra)",
        FacilityCity: "Malkapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4433,
        Pin: 680596,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4434,
        Pin: 782412,
        DispatchCenter: "Morigaon_Nabheti_D (Assam)",
        OriginCenter: "Morigaon_Nabheti_D (Assam)",
        FacilityCity: "Morigaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4435,
        Pin: 416004,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4436,
        Pin: 491557,
        DispatchCenter: "Dongargarh_SouthColony_DPP (Chhattisgarh)",
        OriginCenter: "Dongargarh_SouthColony_DPP (Chhattisgarh)",
        FacilityCity: "Dongargarh",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4437,
        Pin: 570034,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4438,
        Pin: 625106,
        DispatchCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        OriginCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        FacilityCity: "Melur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4439,
        Pin: 442702,
        DispatchCenter: "Gondpipri_Pombhurna_DPP (Maharashtra)",
        OriginCenter: "Gondpipri_Pombhurna_DPP (Maharashtra)",
        FacilityCity: "Gondpipri",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4440,
        Pin: 522034,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4441,
        Pin: 362225,
        DispatchCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        OriginCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        FacilityCity: "Keshod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4442,
        Pin: 392155,
        DispatchCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        OriginCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        FacilityCity: "Jambusar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4443,
        Pin: 515765,
        DispatchCenter: "Kalyandurg_Tenagallu_D (Andhra Pradesh)",
        OriginCenter: "Kalyandurg_Tenagallu_D (Andhra Pradesh)",
        FacilityCity: "Kambadur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4444,
        Pin: 177114,
        DispatchCenter: "DehraGopipur_Central_D_1 (Himachal Pradesh)",
        OriginCenter: "DehraGopipur_Central_D_1 (Himachal Pradesh)",
        FacilityCity: "Dehra Gopipur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4445,
        Pin: 752030,
        DispatchCenter: "Balugaon_Oldasianhighwy_D (Orissa)",
        OriginCenter: "Balugaon_Oldasianhighwy_D (Orissa)",
        FacilityCity: "Balugaon",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4446,
        Pin: 321201,
        DispatchCenter: "Deeg_Rambag_D (Rajasthan)",
        OriginCenter: "Deeg_Rambag_D (Rajasthan)",
        FacilityCity: "Deeg",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4447,
        Pin: 680686,
        DispatchCenter: "Irinjlkuda_Pothstherry_D (Kerala)",
        OriginCenter: "Irinjlkuda_Pothstherry_D (Kerala)",
        FacilityCity: "Irinjalakuda",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4448,
        Pin: 722151,
        DispatchCenter: "Khatra_Simlapal_DPP (West Bengal)",
        OriginCenter: "Khatra_Simlapal_DPP (West Bengal)",
        FacilityCity: "Khatra",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4449,
        Pin: 227301,
        DispatchCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        OriginCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        FacilityCity: "Haidargarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4450,
        Pin: 413718,
        DispatchCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        OriginCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        FacilityCity: "Shrirampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4451,
        Pin: 273413,
        DispatchCenter: "Kauriram_Sonbarsa_DPP (Uttar Pradesh)",
        OriginCenter: "Kauriram_Sonbarsa_DPP (Uttar Pradesh)",
        FacilityCity: "Kauriram",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4452,
        Pin: 852217,
        DispatchCenter: "SonbarsaBH_Mangwar_D (Bihar)",
        OriginCenter: "SonbarsaBH_Mangwar_D (Bihar)",
        FacilityCity: "Sonbarsa-BH",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4453,
        Pin: 799035,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4454,
        Pin: 144424,
        DispatchCenter: "Dasuya_Hajipur_D (Punjab)",
        OriginCenter: "Dasuya_Hajipur_D (Punjab)",
        FacilityCity: "Dasuya",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4455,
        Pin: 515202,
        DispatchCenter: "Hindupur_Sadlapalli_D (Andhra Pradesh)",
        OriginCenter: "Hindupur_Sadlapalli_D (Andhra Pradesh)",
        FacilityCity: "Hindupur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4456,
        Pin: 522240,
        DispatchCenter: "Guntur_Amaravati_D (Andhra Pradesh)",
        OriginCenter: "Guntur_Amaravati_D (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        Zone : "S1"
      },
      {
        SN: 4457,
        Pin: 143115,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4458,
        Pin: 303604,
        DispatchCenter: "Phulera_NarenaRD_D (Rajasthan)",
        OriginCenter: "Phulera_NarenaRD_D (Rajasthan)",
        FacilityCity: "Phulera",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4459,
        Pin: 822116,
        DispatchCenter: "Japla_Darua_D (Jharkhand)",
        OriginCenter: "Japla_Darua_D (Jharkhand)",
        FacilityCity: "Japla",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4460,
        Pin: 122019,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4461,
        Pin: 679328,
        DispatchCenter: "Edavanna_MNRd_D (Kerala)",
        OriginCenter: "Edavanna_MNRd_D (Kerala)",
        FacilityCity: "Edavanna",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4462,
        Pin: 815312,
        DispatchCenter: "Giridih_Mahthadih_D (Jharkhand)",
        OriginCenter: "Giridih_Mahthadih_D (Jharkhand)",
        FacilityCity: "Giridih",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4463,
        Pin: 752104,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4464,
        Pin: 387001,
        DispatchCenter: "Nadiad_Shantinagar_D (Gujarat)",
        OriginCenter: "Nadiad_Shantinagar_D (Gujarat)",
        FacilityCity: "Nadiad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4465,
        Pin: 205247,
        DispatchCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        OriginCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        FacilityCity: "Mainpuri",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4466,
        Pin: 767040,
        DispatchCenter: "Kantabanji_Sindhekela_D (Orissa)",
        OriginCenter: "Kantabanji_Sindhekela_D (Orissa)",
        FacilityCity: "Kantabanji",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4467,
        Pin: 612701,
        DispatchCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        OriginCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4468,
        Pin: 362650,
        DispatchCenter: "Kutiyana_jktnaka_DPP (Gujarat)",
        OriginCenter: "Kutiyana_jktnaka_DPP (Gujarat)",
        FacilityCity: "Kutiyana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4469,
        Pin: 797105,
        DispatchCenter: "Tseminyu_Zisunyu_D (Nagaland)",
        OriginCenter: "Tseminyu_Zisunyu_D (Nagaland)",
        FacilityCity: "Tseminyu",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4470,
        Pin: 493111,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4471,
        Pin: 801306,
        DispatchCenter: "Hilsa_Sryamndr_D (Bihar)",
        OriginCenter: "Hilsa_Sryamndr_D (Bihar)",
        FacilityCity: "Hilsa",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4472,
        Pin: 627422,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4473,
        Pin: 600110,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4474,
        Pin: 401703,
        DispatchCenter: "Dahanu_Ambemora_D (Maharashtra)",
        OriginCenter: "Dahanu_Ambemora_D (Maharashtra)",
        FacilityCity: "Dahanu",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4475,
        Pin: 147104,
        DispatchCenter: "SirhindFatehgarh_DC (Punjab)",
        OriginCenter: "SirhindFatehgarh_DC (Punjab)",
        FacilityCity: "Sirhind-Fategarh",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4476,
        Pin: 496005,
        DispatchCenter: "Raigarh_Kharsia_D (Chhattisgarh)",
        OriginCenter: "Raigarh_Kharsia_D (Chhattisgarh)",
        FacilityCity: "Raigarh",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4477,
        Pin: 486223,
        DispatchCenter: "Sohagi_PoliceThane_D (Madhya Pradesh)",
        OriginCenter: "Sohagi_PoliceThane_D (Madhya Pradesh)",
        FacilityCity: "Sohagi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 4478,
        Pin: 682009,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4479,
        Pin: 788719,
        DispatchCenter: "Karimganj_Andharibond_D (Assam)",
        OriginCenter: "Karimganj_Andharibond_D (Assam)",
        FacilityCity: "Karimganj",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4480,
        Pin: 561213,
        DispatchCenter: "Gauribidanur_Srinagara_D (Karnataka)",
        OriginCenter: "Gauribidanur_Srinagara_D (Karnataka)",
        FacilityCity: "Gauribidanur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4481,
        Pin: 734227,
        DispatchCenter: "Darjeeling_Mahendragaon_DPP (West Bengal)",
        OriginCenter: "Darjeeling_Mahendragaon_DPP (West Bengal)",
        FacilityCity: "Darjeeling",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4482,
        Pin: 851204,
        DispatchCenter: "Khagaria_Baluwahi_DPP (Bihar)",
        OriginCenter: "Khagaria_Baluwahi_DPP (Bihar)",
        FacilityCity: "Khagaria",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4483,
        Pin: 852108,
        DispatchCenter: "Supaul_SadrHsptl_D (Bihar)",
        OriginCenter: "Supaul_SadrHsptl_D (Bihar)",
        FacilityCity: "Supaul",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4484,
        Pin: 515832,
        DispatchCenter: "Uravakonda_GovtDegreeClg_D (Andhra Pradesh)",
        OriginCenter: "Uravakonda_GovtDegreeClg_D (Andhra Pradesh)",
        FacilityCity: "Uravakonda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4485,
        Pin: 781311,
        DispatchCenter: "Sarthebari_Tmlichp_D (Assam)",
        OriginCenter: "Sarthebari_Tmlichp_D (Assam)",
        FacilityCity: "Sarthebari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4486,
        Pin: 781017,
        DispatchCenter: "Guwahati_Kaikchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4487,
        Pin: 500095,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4488,
        Pin: 334402,
        DispatchCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        OriginCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        FacilityCity: "Bikaner",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4489,
        Pin: 581117,
        DispatchCenter: "Gadag_Laxmeshwar_D (Karnataka)",
        OriginCenter: "Gadag_Laxmeshwar_D (Karnataka)",
        FacilityCity: "Gadag",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4490,
        Pin: 509357,
        DispatchCenter: "Amangal_Adarshnagar_D (Telangana)",
        OriginCenter: "Amangal_Adarshnagar_D (Telangana)",
        FacilityCity: "Amangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4491,
        Pin: 641687,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4492,
        Pin: 456331,
        DispatchCenter: "Nagda_PrkashColny_D (Madhya Pradesh)",
        OriginCenter: "Nagda_PrkashColny_D (Madhya Pradesh)",
        FacilityCity: "Nagda",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 4493,
        Pin: 304025,
        DispatchCenter: "Bonli_Jamdoli_DPP (Rajasthan)",
        OriginCenter: "Bonli_Jamdoli_DPP (Rajasthan)",
        FacilityCity: "Bonli",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4494,
        Pin: 182117,
        DispatchCenter: "Udhampur_Sunal_D (Jammu & Kashmir)",
        OriginCenter: "Udhampur_Sunal_D (Jammu & Kashmir)",
        FacilityCity: "Udhampur",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4495,
        Pin: 533345,
        DispatchCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4496,
        Pin: 160064,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4497,
        Pin: 614626,
        DispatchCenter: "Thanjavur_Palampudur_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Palampudur_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4498,
        Pin: 524406,
        DispatchCenter: "Gudur_MalvyaNagar_D (Andhra Pradesh)",
        OriginCenter: "Gudur_MalvyaNagar_D (Andhra Pradesh)",
        FacilityCity: "Gudur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4499,
        Pin: 201315,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4500,
        Pin: 416412,
        DispatchCenter: "Jath_Laxmichowk_D (Maharashtra)",
        OriginCenter: "Jath_Laxmichowk_D (Maharashtra)",
        FacilityCity: "Jath",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4501,
        Pin: 312205,
        DispatchCenter: "Fatehnagar_ITALI_DPP (Rajasthan)",
        OriginCenter: "Fatehnagar_ITALI_DPP (Rajasthan)",
        FacilityCity: "Fatehnagar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4502,
        Pin: 484120,
        DispatchCenter: "Shahdol_Jamui_D (Madhya Pradesh)",
        OriginCenter: "Shahdol_Jamui_D (Madhya Pradesh)",
        FacilityCity: "Shahdol",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 4503,
        Pin: 560050,
        DispatchCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        OriginCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4504,
        Pin: 585105,
        DispatchCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        OriginCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        FacilityCity: "Gulbarga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4505,
        Pin: 175027,
        DispatchCenter: "SundarNgr_BhojpurRd_D (Himachal Pradesh)",
        OriginCenter: "SundarNgr_BhojpurRd_D (Himachal Pradesh)",
        FacilityCity: "Sundar Nagar",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4506,
        Pin: 591236,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4507,
        Pin: 621117,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4508,
        Pin: 636815,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4509,
        Pin: 532212,
        DispatchCenter: "Tekkali_Tolusurapalli_D (Andhra Pradesh)",
        OriginCenter: "Tekkali_Tolusurapalli_D (Andhra Pradesh)",
        FacilityCity: "Tekkali",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4510,
        Pin: 473443,
        DispatchCenter: "Mungaoli_BhadurpurRd_DPP (Madhya Pradesh)",
        OriginCenter: "Mungaoli_BhadurpurRd_DPP (Madhya Pradesh)",
        FacilityCity: "Mungaoli",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 4511,
        Pin: 627758,
        DispatchCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        OriginCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        FacilityCity: "Sankarankovil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4512,
        Pin: 802220,
        DispatchCenter: "Bikramgang_Dehrird_D (Bihar)",
        OriginCenter: "Bikramgang_Dehrird_D (Bihar)",
        FacilityCity: "Bikramganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4513,
        Pin: 604206,
        DispatchCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        OriginCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        FacilityCity: "Tindivanam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4514,
        Pin: 824206,
        DispatchCenter: "Imamganj_Bisrampur_D (Bihar)",
        OriginCenter: "Imamganj_Bisrampur_D (Bihar)",
        FacilityCity: "Imamganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4515,
        Pin: 700148,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4516,
        Pin: 833216,
        DispatchCenter: "Seraikella_Laharitola_D (Jharkhand)",
        OriginCenter: "Seraikella_Laharitola_D (Jharkhand)",
        FacilityCity: "Seraikella",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4517,
        Pin: 577180,
        DispatchCenter: "Kadur_UBRoad_D (Karnataka)",
        OriginCenter: "Kadur_UBRoad_D (Karnataka)",
        FacilityCity: "Kadur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4518,
        Pin: 444203,
        DispatchCenter: "Shegaon_Gjnantkies_DPP (Maharashtra)",
        OriginCenter: "Shegaon_Gjnantkies_DPP (Maharashtra)",
        FacilityCity: "Shegaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4519,
        Pin: 262203,
        DispatchCenter: "Pilibhit_AvasVikas_D (Uttar Pradesh)",
        OriginCenter: "Pilibhit_AvasVikas_D (Uttar Pradesh)",
        FacilityCity: "Pilibhit",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4520,
        Pin: 759148,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4521,
        Pin: 246747,
        DispatchCenter: "Dhampur_Jaitra_D (Uttar Pradesh)",
        OriginCenter: "Dhampur_Jaitra_D (Uttar Pradesh)",
        FacilityCity: "Dhampur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4522,
        Pin: 670581,
        DispatchCenter: "Alakode_Thavukunnu_D (Kerala)",
        OriginCenter: "Alakode_Thavukunnu_D (Kerala)",
        FacilityCity: "Alakode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4523,
        Pin: 638001,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4524,
        Pin: 227205,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4525,
        Pin: 413522,
        DispatchCenter: "Nilanga_Panchalcolony_D (Maharashtra)",
        OriginCenter: "Nilanga_Panchalcolony_D (Maharashtra)",
        FacilityCity: "Nilanga",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4526,
        Pin: 695610,
        DispatchCenter: "Pangodu_Bharathanoor_D (Kerala)",
        OriginCenter: "Pangodu_Bharathanoor_D (Kerala)",
        FacilityCity: "Pangodu",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4527,
        Pin: 815313,
        DispatchCenter: "Tisri_BhandariRd_DPP (Jharkhand)",
        OriginCenter: "Tisri_BhandariRd_DPP (Jharkhand)",
        FacilityCity: "Tisri",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4528,
        Pin: 132103,
        DispatchCenter: "Panipat_Risalu_P (Haryana)",
        OriginCenter: "Panipat_Risalu_P (Haryana)",
        FacilityCity: "Panipat",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4529,
        Pin: 306912,
        DispatchCenter: "Sumerpur_Dhorawas_DPP (Rajasthan)",
        OriginCenter: "Sumerpur_Dhorawas_DPP (Rajasthan)",
        FacilityCity: "Sumerpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4530,
        Pin: 360490,
        DispatchCenter: "Upleta_KolkiRd_DPP (Gujarat)",
        OriginCenter: "Upleta_KolkiRd_DPP (Gujarat)",
        FacilityCity: "Upleta",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4531,
        Pin: 851219,
        DispatchCenter: "Sahebpurkml_Mohnpr_D (Bihar)",
        OriginCenter: "Sahebpurkml_Mohnpr_D (Bihar)",
        FacilityCity: "Sahebpur Kamal",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4532,
        Pin: 123303,
        DispatchCenter: "Rewari_Kosli_D (Haryana)",
        OriginCenter: "Rewari_Kosli_D (Haryana)",
        FacilityCity: "Jhajjar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4533,
        Pin: 413723,
        DispatchCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        OriginCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        FacilityCity: "Shrirampur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4534,
        Pin: 828305,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4535,
        Pin: 788006,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4536,
        Pin: 325216,
        DispatchCenter: "Kelwara_Sitacolony_D (Rajasthan)",
        OriginCenter: "Kelwara_Sitacolony_D (Rajasthan)",
        FacilityCity: "Kelwara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4537,
        Pin: 799022,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4538,
        Pin: 274306,
        DispatchCenter: "Kaptanganj_Subshngr_D (Uttar Pradesh)",
        OriginCenter: "Kaptanganj_Subshngr_D (Uttar Pradesh)",
        FacilityCity: "Kaptanganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4539,
        Pin: 209205,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4540,
        Pin: 416120,
        DispatchCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        OriginCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        FacilityCity: "Miraj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4541,
        Pin: 335512,
        DispatchCenter: "Hanumangarh_DhaanMandi_I (Rajasthan)",
        OriginCenter: "Hanumangarh_DhaanMandi_I (Rajasthan)",
        FacilityCity: "Hanumangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4542,
        Pin: 635115,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4543,
        Pin: 431503,
        DispatchCenter: "Selu_Vidyangr_D (Maharashtra)",
        OriginCenter: "Selu_Vidyangr_D (Maharashtra)",
        FacilityCity: "Selu",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4544,
        Pin: 246495,
        DispatchCenter: "Rudraprayag_Gulabrai_D (Uttarakhand)",
        OriginCenter: "Rudraprayag_Gulabrai_D (Uttarakhand)",
        FacilityCity: "Rudraprayag",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4545,
        Pin: 517599,
        DispatchCenter: "Sholinghur_Pulanji_D (Tamil Nadu)",
        OriginCenter: "Sholinghur_Pulanji_D (Tamil Nadu)",
        FacilityCity: "Sholinghur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4546,
        Pin: 700156,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4547,
        Pin: 364620,
        DispatchCenter: "Amreli_Bhojalpara_D (Gujarat)",
        OriginCenter: "Amreli_Bhojalpara_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4548,
        Pin: 284001,
        DispatchCenter: "Jhansi_Nandanpura_I (Uttar Pradesh)",
        OriginCenter: "Jhansi_Nandanpura_I (Uttar Pradesh)",
        FacilityCity: "Jhansi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4549,
        Pin: 482001,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 4550,
        Pin: 635806,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4551,
        Pin: 422610,
        DispatchCenter: "Akole_Mahalxminagar_D (Maharashtra)",
        OriginCenter: "Akole_Mahalxminagar_D (Maharashtra)",
        FacilityCity: "Akole",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4552,
        Pin: 682003,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4553,
        Pin: 506122,
        DispatchCenter: "Narsampet_PakhalRoad_D (Telangana)",
        OriginCenter: "Narsampet_PakhalRoad_D (Telangana)",
        FacilityCity: "Narsampet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4554,
        Pin: 712307,
        DispatchCenter: "Pandua_NvplyDPP_D (West Bengal)",
        OriginCenter: "Pandua_NvplyDPP_D (West Bengal)",
        FacilityCity: "Pandua",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4555,
        Pin: 637205,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4556,
        Pin: 506011,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4557,
        Pin: 643002,
        DispatchCenter: "Ooty_Westmere_D (Tamil Nadu)",
        OriginCenter: "Ooty_Westmere_D (Tamil Nadu)",
        FacilityCity: "Ooty",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4558,
        Pin: 402108,
        DispatchCenter: "Pen_Chinchpada_D (Maharashtra)",
        OriginCenter: "Pen_Chinchpada_D (Maharashtra)",
        FacilityCity: "Pen",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4559,
        Pin: 517128,
        DispatchCenter: "Chittoor_Murukambattu_D (Andhra Pradesh)",
        OriginCenter: "Chittoor_Murukambattu_D (Andhra Pradesh)",
        FacilityCity: "Chittoor",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4560,
        Pin: 621129,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4561,
        Pin: 502285,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4562,
        Pin: 508117,
        DispatchCenter: "Bhuvanagiri_Keesararoad_D (Telangana)",
        OriginCenter: "Bhuvanagiri_Keesararoad_D (Telangana)",
        FacilityCity: "Bhuvanagiri",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4563,
        Pin: 847427,
        DispatchCenter: "Benipur_Magura_D (Bihar)",
        OriginCenter: "Benipur_Magura_D (Bihar)",
        FacilityCity: "Benipur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4564,
        Pin: 629401,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4565,
        Pin: 731127,
        DispatchCenter: "Rampurhat_Suri_D (West Bengal)",
        OriginCenter: "Rampurhat_Suri_D (West Bengal)",
        FacilityCity: "Rampurhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4566,
        Pin: 676108,
        DispatchCenter: "Tirur_Perunthallur_D (Kerala)",
        OriginCenter: "Tirur_Perunthallur_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4567,
        Pin: 689547,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4568,
        Pin: 734014,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4569,
        Pin: 614705,
        DispatchCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        OriginCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        FacilityCity: "Pattukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4570,
        Pin: 635305,
        DispatchCenter: "Harur_EBOfc_D (Tamil Nadu)",
        OriginCenter: "Harur_EBOfc_D (Tamil Nadu)",
        FacilityCity: "Harur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4571,
        Pin: 753025,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4572,
        Pin: 523181,
        DispatchCenter: "Addanki_Inkollu_D (Andhra Pradesh)",
        OriginCenter: "Addanki_Inkollu_D (Andhra Pradesh)",
        FacilityCity: "Addanki",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4573,
        Pin: 782486,
        DispatchCenter: "Hamren_Harlongjove_DPP (Assam)",
        OriginCenter: "Hamren_Harlongjove_DPP (Assam)",
        FacilityCity: "Hamren",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4574,
        Pin: 577421,
        DispatchCenter: "Siddapur_Kargal_D (Karnataka)",
        OriginCenter: "Siddapur_Kargal_D (Karnataka)",
        FacilityCity: "Siddapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4575,
        Pin: 396015,
        DispatchCenter: "Valsad_LimbaChwk_D (Gujarat)",
        OriginCenter: "Valsad_LimbaChwk_D (Gujarat)",
        FacilityCity: "Valsad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4576,
        Pin: 203202,
        DispatchCenter: "Sikandrabad_Khatriwara_D (Uttar Pradesh)",
        OriginCenter: "Sikandrabad_Khatriwara_D (Uttar Pradesh)",
        FacilityCity: "Sikandrabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4577,
        Pin: 785631,
        DispatchCenter: "Jorhat_Titabor_D (Assam)",
        OriginCenter: "Jorhat_Titabor_D (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4578,
        Pin: 676302,
        DispatchCenter: "Tirur_Talakkad_D (Kerala)",
        OriginCenter: "Tirur_Talakkad_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4579,
        Pin: 828107,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4580,
        Pin: 629157,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4581,
        Pin: 523274,
        DispatchCenter: "Singarayakonda_TrunkRd_D (Andhra Pradesh)",
        OriginCenter: "Singarayakonda_TrunkRd_D (Andhra Pradesh)",
        FacilityCity: "Singarayakonda",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4582,
        Pin: 586106,
        DispatchCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        OriginCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        FacilityCity: "Bijapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4583,
        Pin: 206246,
        DispatchCenter: "Rasulabad_ShastriNgr_D (Uttar Pradesh)",
        OriginCenter: "Rasulabad_ShastriNgr_D (Uttar Pradesh)",
        FacilityCity: "Rasulabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4584,
        Pin: 572139,
        DispatchCenter: "Sira_Hanumanthnagara_D (Karnataka)",
        OriginCenter: "Sira_Hanumanthnagara_D (Karnataka)",
        FacilityCity: "Sira",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4585,
        Pin: 249205,
        DispatchCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        OriginCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        FacilityCity: "Rishikesh",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4586,
        Pin: 454335,
        DispatchCenter: "Kukshi_BurahaniColony_D (Madhya Pradesh)",
        OriginCenter: "Kukshi_BurahaniColony_D (Madhya Pradesh)",
        FacilityCity: "Kukshi",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 4587,
        Pin: 271313,
        DispatchCenter: "Itava_Prsnga_D (Uttar Pradesh)",
        OriginCenter: "Itava_Prsnga_D (Uttar Pradesh)",
        FacilityCity: "Itava",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4588,
        Pin: 535502,
        DispatchCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        OriginCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        FacilityCity: "Parvathipuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4589,
        Pin: 484551,
        DispatchCenter: "PaliBirsinghpur_Goraiya_D (Madhya Pradesh)",
        OriginCenter: "PaliBirsinghpur_Goraiya_D (Madhya Pradesh)",
        FacilityCity: "Pali Birsinghpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 4590,
        Pin: 403404,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4591,
        Pin: 585367,
        DispatchCenter: "Aland_Sherikarcolony_D (Karnataka)",
        OriginCenter: "Aland_Sherikarcolony_D (Karnataka)",
        FacilityCity: "Aland",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4592,
        Pin: 274401,
        DispatchCenter: "Kushinagar_Siswa_D (Uttar Pradesh)",
        OriginCenter: "Kushinagar_Siswa_D (Uttar Pradesh)",
        FacilityCity: "Kushinagar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4593,
        Pin: 484776,
        DispatchCenter: "Beohari_Wardno13_D (Madhya Pradesh)",
        OriginCenter: "Beohari_Wardno13_D (Madhya Pradesh)",
        FacilityCity: "Beohari",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 4594,
        Pin: 396126,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4595,
        Pin: 382742,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4596,
        Pin: 676122,
        DispatchCenter: "Pandikkad_Thangalpadi_D (Kerala)",
        OriginCenter: "Pandikkad_Thangalpadi_D (Kerala)",
        FacilityCity: "Pandikkad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4597,
        Pin: 131304,
        DispatchCenter: "Gohana_Adarshnagar_D (Haryana)",
        OriginCenter: "Gohana_Adarshnagar_D (Haryana)",
        FacilityCity: "Gohana",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4598,
        Pin: 271601,
        DispatchCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        OriginCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        FacilityCity: "Gonda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4599,
        Pin: 629801,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4600,
        Pin: 575028,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4601,
        Pin: 770037,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4602,
        Pin: 457993,
        DispatchCenter: "Jhabua_Mojipada_D (Madhya Pradesh)",
        OriginCenter: "Jhabua_Mojipada_D (Madhya Pradesh)",
        FacilityCity: "Jhabua",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 4603,
        Pin: 678583,
        DispatchCenter: "Thachnttukra_Mangadn_D (Kerala)",
        OriginCenter: "Thachnttukra_Mangadn_D (Kerala)",
        FacilityCity: "Thachanattukara",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4604,
        Pin: 415726,
        DispatchCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        OriginCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        FacilityCity: "Shringartali",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4605,
        Pin: 685613,
        DispatchCenter: "Munnar_IkkaNgr_D (Kerala)",
        OriginCenter: "Munnar_IkkaNgr_D (Kerala)",
        FacilityCity: "Munnar",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4606,
        Pin: 522316,
        DispatchCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        OriginCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4607,
        Pin: 673511,
        DispatchCenter: "Kuttiady_ValayannoorMasjid_D (Kerala)",
        OriginCenter: "Kuttiady_ValayannoorMasjid_D (Kerala)",
        FacilityCity: "Kuttiady",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4608,
        Pin: 638154,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4609,
        Pin: 788106,
        DispatchCenter: "Pailapool_Lagktlbocpr_D (Assam)",
        OriginCenter: "Pailapool_Lagktlbocpr_D (Assam)",
        FacilityCity: "Pailapool",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4610,
        Pin: 761211,
        DispatchCenter: "Paralakhemundi_Omcolony_D (Orissa)",
        OriginCenter: "Paralakhemundi_Omcolony_D (Orissa)",
        FacilityCity: "Paralakhemundi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4611,
        Pin: 603001,
        DispatchCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        OriginCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        FacilityCity: "Chengalpattu",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4612,
        Pin: 228141,
        DispatchCenter: "Kumarganj_Siddhiganeshpur_D (Uttar Pradesh)",
        OriginCenter: "Kumarganj_Siddhiganeshpur_D (Uttar Pradesh)",
        FacilityCity: "Kumarganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4613,
        Pin: 785690,
        DispatchCenter: "Sonari_Nakachari_D (Assam)",
        OriginCenter: "Sonari_Nakachari_D (Assam)",
        FacilityCity: "Sonari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4614,
        Pin: 742223,
        DispatchCenter: "Raghunathganj_NH12_D (West Bengal)",
        OriginCenter: "Raghunathganj_NH12_D (West Bengal)",
        FacilityCity: "Raghunathganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4615,
        Pin: 600016,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4616,
        Pin: 401609,
        DispatchCenter: "Dahanu_Ambemora_D (Maharashtra)",
        OriginCenter: "Dahanu_Ambemora_D (Maharashtra)",
        FacilityCity: "Dahanu",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4617,
        Pin: 560068,
        DispatchCenter: "Bangalore_Temp1_L (Karnataka)",
        OriginCenter: "Bangalore_Temp1_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4618,
        Pin: 262528,
        DispatchCenter: "Lohaghat_RanjeetPalace_D (Uttarakhand)",
        OriginCenter: "Lohaghat_RanjeetPalace_D (Uttarakhand)",
        FacilityCity: "Lohaghat",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4619,
        Pin: 855116,
        DispatchCenter: "Thakurganj_ThanaColony_D (Bihar)",
        OriginCenter: "Thakurganj_ThanaColony_D (Bihar)",
        FacilityCity: "Thakurganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4620,
        Pin: 767003,
        DispatchCenter: "Balangir_PalaceLine_D (Orissa)",
        OriginCenter: "Balangir_PalaceLine_D (Orissa)",
        FacilityCity: "Balangir",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4621,
        Pin: 221712,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4622,
        Pin: 495012,
        DispatchCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        OriginCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        FacilityCity: "Bilaspur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4623,
        Pin: 303903,
        DispatchCenter: "Chaksu_Arihantkunj_D (Rajasthan)",
        OriginCenter: "Chaksu_Arihantkunj_D (Rajasthan)",
        FacilityCity: "Chaksu",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4624,
        Pin: 481996,
        DispatchCenter: "Bichhiya_ShishuMndir_DPP (Madhya Pradesh)",
        OriginCenter: "Bichhiya_ShishuMndir_DPP (Madhya Pradesh)",
        FacilityCity: "Bichhiya",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 4625,
        Pin: 182202,
        DispatchCenter: "Doda_GhatRoad_D (Jammu & Kashmir)",
        OriginCenter: "Doda_GhatRoad_D (Jammu & Kashmir)",
        FacilityCity: "Doda",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4626,
        Pin: 560112,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4627,
        Pin: 852139,
        DispatchCenter: "Triveninganj_Ward16_D (Bihar)",
        OriginCenter: "Triveninganj_Ward16_D (Bihar)",
        FacilityCity: "Triveninganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4628,
        Pin: 625502,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4629,
        Pin: 271851,
        DispatchCenter: "Bahraich_Mahasi_D (Uttar Pradesh)",
        OriginCenter: "Bahraich_Mahasi_D (Uttar Pradesh)",
        FacilityCity: "Bahraich",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4630,
        Pin: 342022,
        DispatchCenter: "Phalsund_Kajoi_D (Rajasthan)",
        OriginCenter: "Phalsund_Kajoi_D (Rajasthan)",
        FacilityCity: "Phalsund",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4631,
        Pin: 621314,
        DispatchCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4632,
        Pin: 673631,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4633,
        Pin: 631401,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4634,
        Pin: 571104,
        DispatchCenter: "Kushalnagar_BapujiExtension_D (Karnataka)",
        OriginCenter: "Kushalnagar_BapujiExtension_D (Karnataka)",
        FacilityCity: "Kushalnagar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4635,
        Pin: 630611,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4636,
        Pin: 603004,
        DispatchCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        OriginCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        FacilityCity: "Chengalpattu",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4637,
        Pin: 770076,
        DispatchCenter: "Sundargarh_Duduka_D (Orissa)",
        OriginCenter: "Sundargarh_Duduka_D (Orissa)",
        FacilityCity: "Sundargarh",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4638,
        Pin: 635703,
        DispatchCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ambur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4639,
        Pin: 690105,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4640,
        Pin: 384221,
        DispatchCenter: "Patan_Hansapur_D (Gujarat)",
        OriginCenter: "Patan_Hansapur_D (Gujarat)",
        FacilityCity: "Patan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4641,
        Pin: 713520,
        DispatchCenter: "Guskhara_DignagarRd_D (West Bengal)",
        OriginCenter: "Guskhara_DignagarRd_D (West Bengal)",
        FacilityCity: "Guskhara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4642,
        Pin: 362004,
        DispatchCenter: "Junagadh_Dolatpara_I (Gujarat)",
        OriginCenter: "Junagadh_Dolatpara_I (Gujarat)",
        FacilityCity: "Junagadh",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4643,
        Pin: 144513,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4644,
        Pin: 571440,
        DispatchCenter: "Kollegala_IndiraClny_D (Karnataka)",
        OriginCenter: "Kollegala_IndiraClny_D (Karnataka)",
        FacilityCity: "Kollegala",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4645,
        Pin: 605502,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4646,
        Pin: 284301,
        DispatchCenter: "Chirgaon_RamnagarRoad_D (Uttar Pradesh)",
        OriginCenter: "Chirgaon_RamnagarRoad_D (Uttar Pradesh)",
        FacilityCity: "Chirgaon",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4647,
        Pin: 721403,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4648,
        Pin: 144039,
        DispatchCenter: "Nakodar_Goldenavenue_D (Punjab)",
        OriginCenter: "Nakodar_Goldenavenue_D (Punjab)",
        FacilityCity: "Nakodar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4649,
        Pin: 380055,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4650,
        Pin: 632006,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4651,
        Pin: 502319,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4652,
        Pin: 380030,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4653,
        Pin: 501101,
        DispatchCenter: "Vikarabad_OldGunj_D (Telangana)",
        OriginCenter: "Vikarabad_OldGunj_D (Telangana)",
        FacilityCity: "Vikarabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4654,
        Pin: 581341,
        DispatchCenter: "Honnavar_ForestColony_D (Karnataka)",
        OriginCenter: "Honnavar_ForestColony_D (Karnataka)",
        FacilityCity: "Honnavar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4655,
        Pin: 802161,
        DispatchCenter: "Arrah_Sakaddi_D (Bihar)",
        OriginCenter: "Arrah_Sakaddi_D (Bihar)",
        FacilityCity: "Arrah",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4656,
        Pin: 160062,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4657,
        Pin: 639003,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4658,
        Pin: 507134,
        DispatchCenter: "Nellipaka_Buttaigudem_D (Andhra Pradesh)",
        OriginCenter: "Nellipaka_Buttaigudem_D (Andhra Pradesh)",
        FacilityCity: "Nellipaka",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4659,
        Pin: 591224,
        DispatchCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        OriginCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        FacilityCity: "Gokak",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4660,
        Pin: 523334,
        DispatchCenter: "CumbumAP_BhagyaNagar_D (Andhra Pradesh)",
        OriginCenter: "CumbumAP_BhagyaNagar_D (Andhra Pradesh)",
        FacilityCity: "CumbumAP",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4661,
        Pin: 768213,
        DispatchCenter: "Kuchinda_Pdrpada_D (Orissa)",
        OriginCenter: "Kuchinda_Pdrpada_D (Orissa)",
        FacilityCity: "Kuchinda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4662,
        Pin: 332025,
        DispatchCenter: "Ramgarh_Khoor_DPP (Rajasthan)",
        OriginCenter: "Ramgarh_Khoor_DPP (Rajasthan)",
        FacilityCity: "Ramgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4663,
        Pin: 327027,
        DispatchCenter: "Ghatol_Teliwada_DPP (Rajasthan)",
        OriginCenter: "Ghatol_Teliwada_DPP (Rajasthan)",
        FacilityCity: "Ghatol",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4664,
        Pin: 387110,
        DispatchCenter: "Mahemdavad_KrishnaPark_DPP (Gujarat)",
        OriginCenter: "Mahemdavad_KrishnaPark_DPP (Gujarat)",
        FacilityCity: "Mahemdavad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4665,
        Pin: 827006,
        DispatchCenter: "Bokaro_Chas_D (Jharkhand)",
        OriginCenter: "Bokaro_Chas_D (Jharkhand)",
        FacilityCity: "Bokaro",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4666,
        Pin: 364505,
        DispatchCenter: "Palitana_Jivapur_DPP (Gujarat)",
        OriginCenter: "Palitana_Jivapur_DPP (Gujarat)",
        FacilityCity: "Palitana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4667,
        Pin: 393050,
        DispatchCenter: "Dediapada_Sagbara_DPP (Gujarat)",
        OriginCenter: "Dediapada_Sagbara_DPP (Gujarat)",
        FacilityCity: "Dediapada",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4668,
        Pin: 501144,
        DispatchCenter: "Tandur_SnthiNGR_D (Telangana)",
        OriginCenter: "Tandur_SnthiNGR_D (Telangana)",
        FacilityCity: "Tandur-TL",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4669,
        Pin: 416410,
        DispatchCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        OriginCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        FacilityCity: "Miraj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4670,
        Pin: 486123,
        DispatchCenter: "Raipurkarchulion_Joginhai_D (Madhya Pradesh)",
        OriginCenter: "Raipurkarchulion_Joginhai_D (Madhya Pradesh)",
        FacilityCity: "Raipur Karchulion",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 4671,
        Pin: 160014,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4672,
        Pin: 492016,
        DispatchCenter: "Raipur_Telibandha_D (Chhattisgarh)",
        OriginCenter: "Raipur_Telibandha_D (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4673,
        Pin: 110088,
        DispatchCenter: "Delhi_Libaspur_L (Delhi)",
        OriginCenter: "Delhi_Libaspur_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4674,
        Pin: 491230,
        DispatchCenter: "Bhanupratappur_Kanhargaon_D (Chhattisgarh)",
        OriginCenter: "Bhanupratappur_Kanhargaon_D (Chhattisgarh)",
        FacilityCity: "Bhanupratappur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4675,
        Pin: 363621,
        DispatchCenter: "Wankaner_Railwaycolony_D (Gujarat)",
        OriginCenter: "Wankaner_Railwaycolony_D (Gujarat)",
        FacilityCity: "Wankaner",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4676,
        Pin: 741222,
        DispatchCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        OriginCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        FacilityCity: "Ranaghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4677,
        Pin: 508377,
        DispatchCenter: "Haliya_VBNagar_D (Telangana)",
        OriginCenter: "Haliya_VBNagar_D (Telangana)",
        FacilityCity: "Haliya",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4678,
        Pin: 160048,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4679,
        Pin: 631301,
        DispatchCenter: "Sholinghur_Pulanji_D (Tamil Nadu)",
        OriginCenter: "Sholinghur_Pulanji_D (Tamil Nadu)",
        FacilityCity: "Sholinghur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4680,
        Pin: 641022,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4681,
        Pin: 605107,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4682,
        Pin: 524310,
        DispatchCenter: "Udayagiri_Msjidcmplx_D (Andhra Pradesh)",
        OriginCenter: "Udayagiri_Msjidcmplx_D (Andhra Pradesh)",
        FacilityCity: "Udayagiri",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4683,
        Pin: 392310,
        DispatchCenter: "Karjan_Junabzr_DC (Gujarat)",
        OriginCenter: "Karjan_Junabzr_DC (Gujarat)",
        FacilityCity: "Karjan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4684,
        Pin: 202150,
        DispatchCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        OriginCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4685,
        Pin: 263651,
        DispatchCenter: "Ranikhet_KhariBazar_DPP (Uttarakhand)",
        OriginCenter: "Ranikhet_KhariBazar_DPP (Uttarakhand)",
        FacilityCity: "Ranikhet",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4686,
        Pin: 764052,
        DispatchCenter: "Balimela_MainRoad_DPP (Orissa)",
        OriginCenter: "Balimela_MainRoad_DPP (Orissa)",
        FacilityCity: "Balimela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4687,
        Pin: 127309,
        DispatchCenter: "Bhiwani_Bhagganpuri_D (Haryana)",
        OriginCenter: "Bhiwani_Bhagganpuri_D (Haryana)",
        FacilityCity: "Bhiwani",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4688,
        Pin: 400011,
        DispatchCenter: "Mumbai_Sewri_L (Maharashtra)",
        OriginCenter: "Mumbai_Sewri_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4689,
        Pin: 402112,
        DispatchCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        OriginCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        FacilityCity: "Mangaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4690,
        Pin: 797111,
        DispatchCenter: "Wokha_NST_D (Nagaland)",
        OriginCenter: "Wokha_NST_D (Nagaland)",
        FacilityCity: "Wokha",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4691,
        Pin: 515159,
        DispatchCenter: "Mudigubba_Meeseva_D (Andhra Pradesh)",
        OriginCenter: "Mudigubba_Meeseva_D (Andhra Pradesh)",
        FacilityCity: "Mudigubba",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4692,
        Pin: 221314,
        DispatchCenter: "Bhadohi_Vihana_D (Uttar Pradesh)",
        OriginCenter: "Bhadohi_Vihana_D (Uttar Pradesh)",
        FacilityCity: "Bhadohi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4693,
        Pin: 524308,
        DispatchCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        OriginCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        FacilityCity: "Atmakur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4694,
        Pin: 721145,
        DispatchCenter: "Kharagpur_Nimpura_I (West Bengal)",
        OriginCenter: "Kharagpur_Nimpura_I (West Bengal)",
        FacilityCity: "Kharagpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4695,
        Pin: 227005,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4696,
        Pin: 604205,
        DispatchCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        OriginCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        FacilityCity: "Gingee",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4697,
        Pin: 788009,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4698,
        Pin: 431603,
        DispatchCenter: "Nanded_Wajegaon_H (Maharashtra)",
        OriginCenter: "Nanded_Wajegaon_H (Maharashtra)",
        FacilityCity: "Nanded",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4699,
        Pin: 221310,
        DispatchCenter: "Gopiganj_Gerai_D (Uttar Pradesh)",
        OriginCenter: "Gopiganj_Gerai_D (Uttar Pradesh)",
        FacilityCity: "Gopiganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4700,
        Pin: 360070,
        DispatchCenter: "Gondal_Maninagar_D (Gujarat)",
        OriginCenter: "Gondal_Maninagar_D (Gujarat)",
        FacilityCity: "Gondal",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4701,
        Pin: 504295,
        DispatchCenter: "Asifabad_Bank_D (Telangana)",
        OriginCenter: "Asifabad_Bank_D (Telangana)",
        FacilityCity: "Asifabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4702,
        Pin: 209505,
        DispatchCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        OriginCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        FacilityCity: "Aliganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4703,
        Pin: 311013,
        DispatchCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        OriginCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        FacilityCity: "Bhilwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4704,
        Pin: 600084,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4705,
        Pin: 481051,
        DispatchCenter: "Malanjkhand_PouniSuji_D (Madhya Pradesh)",
        OriginCenter: "Malanjkhand_PouniSuji_D (Madhya Pradesh)",
        FacilityCity: "Malanjkhand",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 4706,
        Pin: 828112,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4707,
        Pin: 389160,
        DispatchCenter: "Dahod_Vanzarwada_I (Gujarat)",
        OriginCenter: "Dahod_Vanzarwada_I (Gujarat)",
        FacilityCity: "Dahod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4708,
        Pin: 754103,
        DispatchCenter: "Jagatsinghpur_Bnbrda_D (Orissa)",
        OriginCenter: "Jagatsinghpur_Bnbrda_D (Orissa)",
        FacilityCity: "Jagatsinghpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4709,
        Pin: 521144,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4710,
        Pin: 678684,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4711,
        Pin: 816102,
        DispatchCenter: "Barharwa_Berhait_D (Jharkhand)",
        OriginCenter: "Barharwa_Berhait_D (Jharkhand)",
        FacilityCity: "Barharwa",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4712,
        Pin: 533236,
        DispatchCenter: "Mandapeta_Ravulapalem_DPP (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Ravulapalem_DPP (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4713,
        Pin: 828105,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4714,
        Pin: 148100,
        DispatchCenter: "Barnala_Bhadaur_D (Punjab)",
        OriginCenter: "Barnala_Bhadaur_D (Punjab)",
        FacilityCity: "Bhadaur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4715,
        Pin: 767060,
        DispatchCenter: "Kantabanji_Ward4_D (Orissa)",
        OriginCenter: "Kantabanji_Ward4_D (Orissa)",
        FacilityCity: "Kantabanji",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4716,
        Pin: 605004,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4717,
        Pin: 691021,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4718,
        Pin: 822117,
        DispatchCenter: "Japla_Darua_D (Jharkhand)",
        OriginCenter: "Japla_Darua_D (Jharkhand)",
        FacilityCity: "Japla",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4719,
        Pin: 680004,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4720,
        Pin: 570020,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4721,
        Pin: 755018,
        DispatchCenter: "Sukinda_Jamuposi_DPP (Orissa)",
        OriginCenter: "Sukinda_Jamuposi_DPP (Orissa)",
        FacilityCity: "SUKINDA",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4722,
        Pin: 581328,
        DispatchCenter: "Karwar_Sadashivgad_D (Karnataka)",
        OriginCenter: "Karwar_Sadashivgad_D (Karnataka)",
        FacilityCity: "Karwar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4723,
        Pin: 743606,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4724,
        Pin: 175129,
        DispatchCenter: "Patlikuhal_Katrain_D (Himachal Pradesh)",
        OriginCenter: "Patlikuhal_Katrain_D (Himachal Pradesh)",
        FacilityCity: "Patlikuhal",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4725,
        Pin: 410062,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4726,
        Pin: 332039,
        DispatchCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        OriginCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4727,
        Pin: 614012,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4728,
        Pin: 522614,
        DispatchCenter: "Piduguralla_Rasoolpet_D (Andhra Pradesh)",
        OriginCenter: "Piduguralla_Rasoolpet_D (Andhra Pradesh)",
        FacilityCity: "Piduguralla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4729,
        Pin: 742403,
        DispatchCenter: "Dakshinkhanda_BusStand_DPP (West Bengal)",
        OriginCenter: "Dakshinkhanda_BusStand_DPP (West Bengal)",
        FacilityCity: "Katwa",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4730,
        Pin: 364001,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4731,
        Pin: 795130,
        DispatchCenter: "Imphal_Takyel_I (Manipur)",
        OriginCenter: "Imphal_Takyel_I (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4732,
        Pin: 263636,
        DispatchCenter: "Almora_Makedi_D (Uttarakhand)",
        OriginCenter: "Almora_Makedi_D (Uttarakhand)",
        FacilityCity: "Almora",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4733,
        Pin: 143413,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4734,
        Pin: 562128,
        DispatchCenter: "Ramanagara_KunigalRd_D (Karnataka)",
        OriginCenter: "Ramanagara_KunigalRd_D (Karnataka)",
        FacilityCity: "Ramanagara",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4735,
        Pin: 455048,
        DispatchCenter: "Indore_BarodaArjun_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 4736,
        Pin: 285121,
        DispatchCenter: "Jalaun_Madhogarh_D (Uttar Pradesh)",
        OriginCenter: "Jalaun_Madhogarh_D (Uttar Pradesh)",
        FacilityCity: "Jalaun",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4737,
        Pin: 690527,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4738,
        Pin: 752046,
        DispatchCenter: "Pipili_BPRd_DPP (Orissa)",
        OriginCenter: "Pipili_BPRd_DPP (Orissa)",
        FacilityCity: "Pipili",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4739,
        Pin: 583154,
        DispatchCenter: "Siruguppa_Yamaha_D (Karnataka)",
        OriginCenter: "Siruguppa_Yamaha_D (Karnataka)",
        FacilityCity: "Siruguppa",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4740,
        Pin: 742224,
        DispatchCenter: "Dhulian_RatanDPP_D (West Bengal)",
        OriginCenter: "Dhulian_RatanDPP_D (West Bengal)",
        FacilityCity: "Dhulian",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4741,
        Pin: 627117,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4742,
        Pin: 212635,
        DispatchCenter: "AmbujNagar_ShvrjprRd_D (Uttar Pradesh)",
        OriginCenter: "AmbujNagar_ShvrjprRd_D (Uttar Pradesh)",
        FacilityCity: "Ambuj Nagar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4743,
        Pin: 835220,
        DispatchCenter: "Gumla_Deepnagar_D (Jharkhand)",
        OriginCenter: "Gumla_Deepnagar_D (Jharkhand)",
        FacilityCity: "Gumla",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4744,
        Pin: 201006,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4745,
        Pin: 622408,
        DispatchCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4746,
        Pin: 110058,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4747,
        Pin: 788734,
        DispatchCenter: "Ramkrishnanagar_Kalinagar_D (Assam)",
        OriginCenter: "Ramkrishnanagar_Kalinagar_D (Assam)",
        FacilityCity: "Ramkrishna Nagar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4748,
        Pin: 583279,
        DispatchCenter: "Kushtagi_Karimacolony_DPP (Karnataka)",
        OriginCenter: "Kushtagi_Karimacolony_DPP (Karnataka)",
        FacilityCity: "Kushtagi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4749,
        Pin: 413226,
        DispatchCenter: "Akkalkot_SmrathNgr_DPP (Maharashtra)",
        OriginCenter: "Akkalkot_SmrathNgr_DPP (Maharashtra)",
        FacilityCity: "Akkalkot",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4750,
        Pin: 416308,
        DispatchCenter: "Ashta_GordeChwk_D (Maharashtra)",
        OriginCenter: "Ashta_GordeChwk_D (Maharashtra)",
        FacilityCity: "AshtaSangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4751,
        Pin: 382721,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4752,
        Pin: 424201,
        DispatchCenter: "Pachora_AadarshNagar_D (Maharashtra)",
        OriginCenter: "Pachora_AadarshNagar_D (Maharashtra)",
        FacilityCity: "Pachora",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4753,
        Pin: 176059,
        DispatchCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        OriginCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        FacilityCity: "Palampur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4754,
        Pin: 695305,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4755,
        Pin: 827015,
        DispatchCenter: "Bokaro_Chas_D (Jharkhand)",
        OriginCenter: "Bokaro_Chas_D (Jharkhand)",
        FacilityCity: "Bokaro",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4756,
        Pin: 110071,
        DispatchCenter: "Delhi_BamnoliVillage_L (Delhi)",
        OriginCenter: "Delhi_BamnoliVillage_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4757,
        Pin: 202396,
        DispatchCenter: "Dibai_Pahasu_D (Uttar Pradesh)",
        OriginCenter: "Dibai_Pahasu_D (Uttar Pradesh)",
        FacilityCity: "Dibai",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4758,
        Pin: 794101,
        DispatchCenter: "Tura_Matchakol_D (Meghalaya)",
        OriginCenter: "Tura_Matchakol_D (Meghalaya)",
        FacilityCity: "Tura",
        FacilityState: "Meghalaya",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4759,
        Pin: 126116,
        DispatchCenter: "Jind_Narwana_DPP (Haryana)",
        OriginCenter: "Jind_Narwana_DPP (Haryana)",
        FacilityCity: "Jind",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4760,
        Pin: 636122,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4761,
        Pin: 421102,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4762,
        Pin: 365620,
        DispatchCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4763,
        Pin: 722206,
        DispatchCenter: "Sonamukhi_Central_DPP_1 (West Bengal)",
        OriginCenter: "Sonamukhi_Central_DPP_1 (West Bengal)",
        FacilityCity: "Sonamukhi",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4764,
        Pin: 262310,
        DispatchCenter: "Tanakpur_Shardacolony_D (Uttarakhand)",
        OriginCenter: "Tanakpur_Shardacolony_D (Uttarakhand)",
        FacilityCity: "Tanakpur",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4765,
        Pin: 470335,
        DispatchCenter: "BandaMP_JLNWard_DPP (Madhya Pradesh)",
        OriginCenter: "BandaMP_JLNWard_DPP (Madhya Pradesh)",
        FacilityCity: "Banda-MP",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 4766,
        Pin: 249403,
        DispatchCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        OriginCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        FacilityCity: "Haridwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4767,
        Pin: 500107,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4768,
        Pin: 804424,
        DispatchCenter: "Makhdumpur_Nawabganj_D (Bihar)",
        OriginCenter: "Makhdumpur_Nawabganj_D (Bihar)",
        FacilityCity: "Makhdumpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4769,
        Pin: 573164,
        DispatchCenter: "Hassan_Dudda_D (Karnataka)",
        OriginCenter: "Hassan_Dudda_D (Karnataka)",
        FacilityCity: "Hassan",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4770,
        Pin: 723151,
        DispatchCenter: "Hura_Lalpur_DPP (West Bengal)",
        OriginCenter: "Hura_Lalpur_DPP (West Bengal)",
        FacilityCity: "Hura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4771,
        Pin: 384276,
        DispatchCenter: "Unjha_SatymComplx_D (Gujarat)",
        OriginCenter: "Unjha_SatymComplx_D (Gujarat)",
        FacilityCity: "Unjha",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4772,
        Pin: 711106,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4773,
        Pin: 246144,
        DispatchCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        OriginCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        FacilityCity: "Gumkhal",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4774,
        Pin: 491881,
        DispatchCenter: "Khairagarh_Mudpar_DPP (Chhattisgarh)",
        OriginCenter: "Khairagarh_Mudpar_DPP (Chhattisgarh)",
        FacilityCity: "Khairagarh",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4775,
        Pin: 641665,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4776,
        Pin: 395018,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4777,
        Pin: 713386,
        DispatchCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        OriginCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        FacilityCity: "Rupnarayanpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4778,
        Pin: 854315,
        DispatchCenter: "Purnia_Kauanagar_D (Bihar)",
        OriginCenter: "Purnia_Kauanagar_D (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4779,
        Pin: 464665,
        DispatchCenter: "Bareli_Wazirganj_DPP (Madhya Pradesh)",
        OriginCenter: "Bareli_Wazirganj_DPP (Madhya Pradesh)",
        FacilityCity: "Bareli",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 4780,
        Pin: 841247,
        DispatchCenter: "Hajipur_Hathsarganj_D (Bihar)",
        OriginCenter: "Hajipur_Hathsarganj_D (Bihar)",
        FacilityCity: "Hajipur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4781,
        Pin: 121107,
        DispatchCenter: "Hodal_Hasanpurchowk_D (Haryana)",
        OriginCenter: "Hodal_Hasanpurchowk_D (Haryana)",
        FacilityCity: "Hodal",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4782,
        Pin: 500011,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4783,
        Pin: 758016,
        DispatchCenter: "Karanjia_Ankura_D (Orissa)",
        OriginCenter: "Karanjia_Ankura_D (Orissa)",
        FacilityCity: "Karanjia",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4784,
        Pin: 600087,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4785,
        Pin: 281307,
        DispatchCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        OriginCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        FacilityCity: "Hathras",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4786,
        Pin: 591219,
        DispatchCenter: "Nipani_Sakharwadi_D (Karnataka)",
        OriginCenter: "Nipani_Sakharwadi_D (Karnataka)",
        FacilityCity: "Nipani",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4787,
        Pin: 212664,
        DispatchCenter: "AmbujNagar_ShvrjprRd_D (Uttar Pradesh)",
        OriginCenter: "AmbujNagar_ShvrjprRd_D (Uttar Pradesh)",
        FacilityCity: "Ambuj Nagar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4788,
        Pin: 522201,
        DispatchCenter: "Tenali_Pandurngpet_D (Andhra Pradesh)",
        OriginCenter: "Tenali_Pandurngpet_D (Andhra Pradesh)",
        FacilityCity: "Tenali",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4789,
        Pin: 533007,
        DispatchCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        OriginCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        FacilityCity: "Kakinada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4790,
        Pin: 721142,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4791,
        Pin: 680610,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4792,
        Pin: 722154,
        DispatchCenter: "MirzapurWB_VidyaDPP_D (West Bengal)",
        OriginCenter: "MirzapurWB_VidyaDPP_D (West Bengal)",
        FacilityCity: "Mirzapur-WB",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4793,
        Pin: 227409,
        DispatchCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        OriginCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        FacilityCity: "Amethi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4794,
        Pin: 443209,
        DispatchCenter: "Dusrabid_SugrFact_DPP (Maharashtra)",
        OriginCenter: "Dusrabid_SugrFact_DPP (Maharashtra)",
        FacilityCity: "Dusrabid",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4795,
        Pin: 790003,
        DispatchCenter: "Bomdila_Tengavalley_D (Arunachal Pradesh)",
        OriginCenter: "Bomdila_Tengavalley_D (Arunachal Pradesh)",
        FacilityCity: "Bomdila",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4796,
        Pin: 495002,
        DispatchCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        OriginCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        FacilityCity: "Bilaspur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4797,
        Pin: 688548,
        DispatchCenter: "Cochin_Aroor_D (Kerala)",
        OriginCenter: "Cochin_Aroor_D (Kerala)",
        FacilityCity: "Ezhupunna",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4798,
        Pin: 388120,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4799,
        Pin: 209111,
        DispatchCenter: "Pukhrayan_Arulimod_D (Uttar Pradesh)",
        OriginCenter: "Pukhrayan_Arulimod_D (Uttar Pradesh)",
        FacilityCity: "Pukhrayan",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4800,
        Pin: 246738,
        DispatchCenter: "Chandpur_NoorpurRd_D (Uttar Pradesh)",
        OriginCenter: "Chandpur_NoorpurRd_D (Uttar Pradesh)",
        FacilityCity: "Chandpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4801,
        Pin: 614615,
        DispatchCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        OriginCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        FacilityCity: "Pattukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4802,
        Pin: 841201,
        DispatchCenter: "Ekma_Hansrajpr_D (Bihar)",
        OriginCenter: "Ekma_Hansrajpr_D (Bihar)",
        FacilityCity: "Ekma",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4803,
        Pin: 784101,
        DispatchCenter: "Tezpur_Dipota_H (Assam)",
        OriginCenter: "Tezpur_Dipota_H (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4804,
        Pin: 768200,
        DispatchCenter: "Sambalpur_Tentelpara_H (Orissa)",
        OriginCenter: "Sambalpur_Tentelpara_H (Orissa)",
        FacilityCity: "Sambalpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4805,
        Pin: 394125,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4806,
        Pin: 670302,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4807,
        Pin: 331701,
        DispatchCenter: "Rajgarh_Taranagar_D (Rajasthan)",
        OriginCenter: "Rajgarh_Taranagar_D (Rajasthan)",
        FacilityCity: "Rajgarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4808,
        Pin: 631502,
        DispatchCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        OriginCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        FacilityCity: "Kanchipuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4809,
        Pin: 141401,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4810,
        Pin: 383255,
        DispatchCenter: "Khedbrahma_SaraswtiCmplx_DPP (Gujarat)",
        OriginCenter: "Khedbrahma_SaraswtiCmplx_DPP (Gujarat)",
        FacilityCity: "Khedbrahma",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4811,
        Pin: 313326,
        DispatchCenter: "Rajsamand_Riicoarea_D (Rajasthan)",
        OriginCenter: "Rajsamand_Riicoarea_D (Rajasthan)",
        FacilityCity: "Rajsamand",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4812,
        Pin: 277304,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4813,
        Pin: 521132,
        DispatchCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        OriginCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        FacilityCity: "Pamarru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4814,
        Pin: 577220,
        DispatchCenter: "Shimoga_Eshwariah_I (Karnataka)",
        OriginCenter: "Shimoga_Eshwariah_I (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4815,
        Pin: 609501,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4816,
        Pin: 788098,
        DispatchCenter: "Pailapool_Lagktlbocpr_D (Assam)",
        OriginCenter: "Pailapool_Lagktlbocpr_D (Assam)",
        FacilityCity: "Pailapool",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4817,
        Pin: 755001,
        DispatchCenter: "Jajpur_Panasa_D (Orissa)",
        OriginCenter: "Jajpur_Panasa_D (Orissa)",
        FacilityCity: "Jajpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4818,
        Pin: 762024,
        DispatchCenter: "Boudh_Jogiberini_D (Orissa)",
        OriginCenter: "Boudh_Jogiberini_D (Orissa)",
        FacilityCity: "Boudh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4819,
        Pin: 605002,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4820,
        Pin: 192301,
        DispatchCenter: "Srinagar_Ladhoo_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Ladhoo_H (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4821,
        Pin: 250103,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4822,
        Pin: 768212,
        DispatchCenter: "Sambalpur_Tentelpara_H (Orissa)",
        OriginCenter: "Sambalpur_Tentelpara_H (Orissa)",
        FacilityCity: "Sambalpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4823,
        Pin: 501158,
        DispatchCenter: "Tandur_SnthiNGR_D (Telangana)",
        OriginCenter: "Tandur_SnthiNGR_D (Telangana)",
        FacilityCity: "Tandur-TL",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4824,
        Pin: 796009,
        DispatchCenter: "Aizawl_Sairang_I (Mizoram)",
        OriginCenter: "Aizawl_Sairang_I (Mizoram)",
        FacilityCity: "Aizawl",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4825,
        Pin: 462003,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 4826,
        Pin: 502277,
        DispatchCenter: "Cherial_Vchreni_D (Telangana)",
        OriginCenter: "Cherial_Vchreni_D (Telangana)",
        FacilityCity: "Cherial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4827,
        Pin: 136043,
        DispatchCenter: "Kaithal_DefenceColony_D (Haryana)",
        OriginCenter: "Kaithal_DefenceColony_D (Haryana)",
        FacilityCity: "Kaithal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4828,
        Pin: 202151,
        DispatchCenter: "Jewar_SJRoad_D (Uttar Pradesh)",
        OriginCenter: "Jewar_SJRoad_D (Uttar Pradesh)",
        FacilityCity: "Jewar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4829,
        Pin: 846001,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4830,
        Pin: 312027,
        DispatchCenter: "Mandphiya_BhadsodaRoad_D (Rajasthan)",
        OriginCenter: "Mandphiya_BhadsodaRoad_D (Rajasthan)",
        FacilityCity: "Mandphiya",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4831,
        Pin: 327023,
        DispatchCenter: "Ghatol_Teliwada_DPP (Rajasthan)",
        OriginCenter: "Ghatol_Teliwada_DPP (Rajasthan)",
        FacilityCity: "Ghatol",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4832,
        Pin: 742237,
        DispatchCenter: "Raghunathganj_NH12_D (West Bengal)",
        OriginCenter: "Raghunathganj_NH12_D (West Bengal)",
        FacilityCity: "Raghunathganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4833,
        Pin: 574212,
        DispatchCenter: "Sullia_Kanthamangala_D (Karnataka)",
        OriginCenter: "Sullia_Kanthamangala_D (Karnataka)",
        FacilityCity: "Sullia",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4834,
        Pin: 493101,
        DispatchCenter: "Tilda_KhushiHsptlRd_D (Chhattisgarh)",
        OriginCenter: "Tilda_KhushiHsptlRd_D (Chhattisgarh)",
        FacilityCity: "Tilda",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4835,
        Pin: 210207,
        DispatchCenter: "Chitrakoot_Khatawara_D (Uttar Pradesh)",
        OriginCenter: "Chitrakoot_Khatawara_D (Uttar Pradesh)",
        FacilityCity: "Chitrakoot",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4836,
        Pin: 281121,
        DispatchCenter: "Vrindavan_Jait_D (Uttar Pradesh)",
        OriginCenter: "Vrindavan_Jait_D (Uttar Pradesh)",
        FacilityCity: "Vrindavan",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4837,
        Pin: 621126,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4838,
        Pin: 443308,
        DispatchCenter: "Dusrabid_SugrFact_DPP (Maharashtra)",
        OriginCenter: "Dusrabid_SugrFact_DPP (Maharashtra)",
        FacilityCity: "Dusrabid",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4839,
        Pin: 456668,
        DispatchCenter: "Tarana_Ward11_D (Madhya Pradesh)",
        OriginCenter: "Tarana_Ward11_D (Madhya Pradesh)",
        FacilityCity: "Tarana",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 4840,
        Pin: 451669,
        DispatchCenter: "Sendhwa_JawaharGanj_I (Madhya Pradesh)",
        OriginCenter: "Sendhwa_JawaharGanj_I (Madhya Pradesh)",
        FacilityCity: "Sendhwa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 4841,
        Pin: 831014,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4842,
        Pin: 670102,
        DispatchCenter: "Thalassery_TempleGt_D (Kerala)",
        OriginCenter: "Thalassery_TempleGt_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4843,
        Pin: 629193,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4844,
        Pin: 680503,
        DispatchCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        OriginCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4845,
        Pin: 396412,
        DispatchCenter: "Navsari_Vejalpore_D (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Navsari",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4846,
        Pin: 815301,
        DispatchCenter: "Giridih_Mahthadih_D (Jharkhand)",
        OriginCenter: "Giridih_Mahthadih_D (Jharkhand)",
        FacilityCity: "Giridih",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4847,
        Pin: 241126,
        DispatchCenter: "Sandila_SubabDPP_D (Uttar Pradesh)",
        OriginCenter: "Sandila_SubabDPP_D (Uttar Pradesh)",
        FacilityCity: "Sandila",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4848,
        Pin: 132117,
        DispatchCenter: "Karnal_Kutail_H (Haryana)",
        OriginCenter: "Karnal_Kutail_H (Haryana)",
        FacilityCity: "Karnal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4849,
        Pin: 581207,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4850,
        Pin: 249161,
        DispatchCenter: "SrinagarUK_Bagwan_D (Uttarakhand)",
        OriginCenter: "SrinagarUK_Bagwan_D (Uttarakhand)",
        FacilityCity: "Srinagar-UK",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4851,
        Pin: 712234,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4852,
        Pin: 577558,
        DispatchCenter: "Hiriyur_Vedvthingr_D (Karnataka)",
        OriginCenter: "Hiriyur_Vedvthingr_D (Karnataka)",
        FacilityCity: "Hiriyur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4853,
        Pin: 276288,
        DispatchCenter: "Shahganj_Kaudiya_D (Uttar Pradesh)",
        OriginCenter: "Shahganj_Kaudiya_D (Uttar Pradesh)",
        FacilityCity: "Shahganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4854,
        Pin: 250606,
        DispatchCenter: "Baghpat_Barout_D (Uttar Pradesh)",
        OriginCenter: "Baghpat_Barout_D (Uttar Pradesh)",
        FacilityCity: "Baghpat",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4855,
        Pin: 712136,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4856,
        Pin: 673510,
        DispatchCenter: "Thamarassery_Raoth_D (Kerala)",
        OriginCenter: "Thamarassery_Raoth_D (Kerala)",
        FacilityCity: "Thamarassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4857,
        Pin: 813105,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4858,
        Pin: 141415,
        DispatchCenter: "Khanna_Motinagar_D (Punjab)",
        OriginCenter: "Khanna_Motinagar_D (Punjab)",
        FacilityCity: "Khanna",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4859,
        Pin: 518343,
        DispatchCenter: "Yemignur_Timmappacolony_D (Andhra Pradesh)",
        OriginCenter: "Yemignur_Timmappacolony_D (Andhra Pradesh)",
        FacilityCity: "Yemmiganur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4860,
        Pin: 325224,
        DispatchCenter: "Kelwara_Sitacolony_D (Rajasthan)",
        OriginCenter: "Kelwara_Sitacolony_D (Rajasthan)",
        FacilityCity: "Kelwara",
        FacilityState: "Rajasthan",
        Zone : "N1"
      },
      {
        SN: 4861,
        Pin: 691532,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4862,
        Pin: 734301,
        DispatchCenter: "Kalimpong_Ward22_D (West Bengal)",
        OriginCenter: "Kalimpong_Ward22_D (West Bengal)",
        FacilityCity: "Kalimpong",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4863,
        Pin: 416146,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4864,
        Pin: 457775,
        DispatchCenter: "Petlawad_Unnai_D (Madhya Pradesh)",
        OriginCenter: "Petlawad_Unnai_D (Madhya Pradesh)",
        FacilityCity: "Petlawad",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 4865,
        Pin: 670650,
        DispatchCenter: "Kuthuparamba_IdstrlAr_D (Kerala)",
        OriginCenter: "Kuthuparamba_IdstrlAr_D (Kerala)",
        FacilityCity: "Kuthuparamba",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4866,
        Pin: 173223,
        DispatchCenter: "Solan_Deothi_I (Himachal Pradesh)",
        OriginCenter: "Solan_Deothi_I (Himachal Pradesh)",
        FacilityCity: "Solan",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4867,
        Pin: 793116,
        DispatchCenter: "Shillong_Bhoirymbong2_D (Meghalaya)",
        OriginCenter: "Shillong_Bhoirymbong2_D (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4868,
        Pin: 500195,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4869,
        Pin: 343002,
        DispatchCenter: "Jalore_RoopNgr_D (Rajasthan)",
        OriginCenter: "Jalore_RoopNgr_D (Rajasthan)",
        FacilityCity: "Jalore",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4870,
        Pin: 390025,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4871,
        Pin: 126102,
        DispatchCenter: "Jind_SunderNagar_D (Haryana)",
        OriginCenter: "Jind_SunderNagar_D (Haryana)",
        FacilityCity: "Jind",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4872,
        Pin: 841437,
        DispatchCenter: "Mohammadpur_DurgaMkt_DPP (Bihar)",
        OriginCenter: "Mohammadpur_DurgaMkt_DPP (Bihar)",
        FacilityCity: "Mohammadpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4873,
        Pin: 621302,
        DispatchCenter: "Manapparai_AmmanNagar_D (Tamil Nadu)",
        OriginCenter: "Manapparai_AmmanNagar_D (Tamil Nadu)",
        FacilityCity: "Manapparai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4874,
        Pin: 758023,
        DispatchCenter: "Bhadrak_Purusandha_D (Orissa)",
        OriginCenter: "Bhadrak_Purusandha_D (Orissa)",
        FacilityCity: "Agarpada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4875,
        Pin: 303102,
        DispatchCenter: "Viratnagar_Shyamcolony_D (Rajasthan)",
        OriginCenter: "Viratnagar_Shyamcolony_D (Rajasthan)",
        FacilityCity: "Viratnagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4876,
        Pin: 401610,
        DispatchCenter: "Talasari_Patlpda_D (Maharashtra)",
        OriginCenter: "Talasari_Patlpda_D (Maharashtra)",
        FacilityCity: "Talasari",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4877,
        Pin: 629501,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4878,
        Pin: 847236,
        DispatchCenter: "Madhubani_NidhiChowk_D (Bihar)",
        OriginCenter: "Madhubani_NidhiChowk_D (Bihar)",
        FacilityCity: "Madhubani",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4879,
        Pin: 841443,
        DispatchCenter: "Chhapra_Ramnagar_I (Bihar)",
        OriginCenter: "Chhapra_Ramnagar_I (Bihar)",
        FacilityCity: "Chhapra",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4880,
        Pin: 560018,
        DispatchCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        OriginCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4881,
        Pin: 507137,
        DispatchCenter: "Bhadrachalam_Lakshminagaram_DPP (Telangana)",
        OriginCenter: "Bhadrachalam_Lakshminagaram_DPP (Telangana)",
        FacilityCity: "Bhadrachalam",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4882,
        Pin: 445302,
        DispatchCenter: "Pandharkawada_AkhadaRd_DPP (Maharashtra)",
        OriginCenter: "Pandharkawada_AkhadaRd_DPP (Maharashtra)",
        FacilityCity: "Pandharkawada",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4883,
        Pin: 783345,
        DispatchCenter: "Dotma_Nawarbhita_DPP (Assam)",
        OriginCenter: "Dotma_Nawarbhita_DPP (Assam)",
        FacilityCity: "Dotma",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4884,
        Pin: 144011,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4885,
        Pin: 303509,
        DispatchCenter: "Mahwa_Allapur_D (Rajasthan)",
        OriginCenter: "Mahwa_Allapur_D (Rajasthan)",
        FacilityCity: "Mahwa",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4886,
        Pin: 271204,
        DispatchCenter: "Itiathok_Birmapur_D (Uttar Pradesh)",
        OriginCenter: "Itiathok_Birmapur_D (Uttar Pradesh)",
        FacilityCity: "Itia Thok",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4887,
        Pin: 175101,
        DispatchCenter: "Kullu_Mohal_D (Himachal Pradesh)",
        OriginCenter: "Kullu_Mohal_D (Himachal Pradesh)",
        FacilityCity: "Kullu",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 4888,
        Pin: 495448,
        DispatchCenter: "Korba_Katghora_D (Chhattisgarh)",
        OriginCenter: "Korba_Katghora_D (Chhattisgarh)",
        FacilityCity: "Korba",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4889,
        Pin: 680620,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4890,
        Pin: 231225,
        DispatchCenter: "Anpara_Parasi_D (Uttar Pradesh)",
        OriginCenter: "Anpara_Parasi_D (Uttar Pradesh)",
        FacilityCity: "Anpara",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4891,
        Pin: 636103,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4892,
        Pin: 572127,
        DispatchCenter: "Hindupur_Sadlapalli_D (Andhra Pradesh)",
        OriginCenter: "Hindupur_Sadlapalli_D (Andhra Pradesh)",
        FacilityCity: "Hindupur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4893,
        Pin: 851139,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4894,
        Pin: 673318,
        DispatchCenter: "Koyilandy_Komathukara_D (Kerala)",
        OriginCenter: "Koyilandy_Komathukara_D (Kerala)",
        FacilityCity: "Koyilandy",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4895,
        Pin: 583270,
        DispatchCenter: "Koppal_PragatiNagar_D (Karnataka)",
        OriginCenter: "Koppal_PragatiNagar_D (Karnataka)",
        FacilityCity: "Koppal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4896,
        Pin: 175025,
        DispatchCenter: "Sarkaghat_Khelag_D (Himachal Pradesh)",
        OriginCenter: "Sarkaghat_Khelag_D (Himachal Pradesh)",
        FacilityCity: "Sarkaghat",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4897,
        Pin: 206241,
        DispatchCenter: "Bidhuna_BharthnRd_D (Uttar Pradesh)",
        OriginCenter: "Bidhuna_BharthnRd_D (Uttar Pradesh)",
        FacilityCity: "Bidhuna",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4898,
        Pin: 250611,
        DispatchCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        OriginCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        FacilityCity: "Baraut",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4899,
        Pin: 134005,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4900,
        Pin: 327606,
        DispatchCenter: "Kushalgarh_Tandamangala_D (Rajasthan)",
        OriginCenter: "Kushalgarh_Tandamangala_D (Rajasthan)",
        FacilityCity: "Kushalgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4901,
        Pin: 323614,
        DispatchCenter: "Lakheri_KrsrdDPP_D (Rajasthan)",
        OriginCenter: "Lakheri_KrsrdDPP_D (Rajasthan)",
        FacilityCity: "Lakheri",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4902,
        Pin: 811308,
        DispatchCenter: "Jhajha_Hillrange_D (Bihar)",
        OriginCenter: "Jhajha_Hillrange_D (Bihar)",
        FacilityCity: "Jhajha",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4903,
        Pin: 534350,
        DispatchCenter: "Kovvur_Ramarao_D (Andhra Pradesh)",
        OriginCenter: "Kovvur_Ramarao_D (Andhra Pradesh)",
        FacilityCity: "Kovvur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4904,
        Pin: 743297,
        DispatchCenter: "Helencha_ColnyDPP_D (West Bengal)",
        OriginCenter: "Helencha_ColnyDPP_D (West Bengal)",
        FacilityCity: "Helencha",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4905,
        Pin: 385320,
        DispatchCenter: "Bhabhar_Deodar_DPP (Gujarat)",
        OriginCenter: "Bhabhar_Deodar_DPP (Gujarat)",
        FacilityCity: "Bhabhar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 4906,
        Pin: 791002,
        DispatchCenter: "Yingkiong_SimarColony_D (Arunachal Pradesh)",
        OriginCenter: "Yingkiong_SimarColony_D (Arunachal Pradesh)",
        FacilityCity: "Yingkiong",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4907,
        Pin: 522268,
        DispatchCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        OriginCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        FacilityCity: "Repalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4908,
        Pin: 312402,
        DispatchCenter: "Mandphiya_BhadsodaRoad_D (Rajasthan)",
        OriginCenter: "Mandphiya_BhadsodaRoad_D (Rajasthan)",
        FacilityCity: "Mandphiya",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4909,
        Pin: 518503,
        DispatchCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        OriginCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        FacilityCity: "Nandyal",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4910,
        Pin: 500030,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4911,
        Pin: 768223,
        DispatchCenter: "Kuchinda_Pdrpada_D (Orissa)",
        OriginCenter: "Kuchinda_Pdrpada_D (Orissa)",
        FacilityCity: "Kuchinda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4912,
        Pin: 484114,
        DispatchCenter: "Shahdol_Jamui_D (Madhya Pradesh)",
        OriginCenter: "Shahdol_Jamui_D (Madhya Pradesh)",
        FacilityCity: "Shahdol",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 4913,
        Pin: 678545,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4914,
        Pin: 272153,
        DispatchCenter: "Bansi_Shyamnagar_D (Uttar Pradesh)",
        OriginCenter: "Bansi_Shyamnagar_D (Uttar Pradesh)",
        FacilityCity: "Bansi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4915,
        Pin: 576113,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4916,
        Pin: 802222,
        DispatchCenter: "Piro_Bachri_D (Bihar)",
        OriginCenter: "Piro_Bachri_D (Bihar)",
        FacilityCity: "Piro",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4917,
        Pin: 607205,
        DispatchCenter: "Panruti_Thiruvathigai_D (Tamil Nadu)",
        OriginCenter: "Panruti_Thiruvathigai_D (Tamil Nadu)",
        FacilityCity: "Panruti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4918,
        Pin: 123102,
        DispatchCenter: "Rewari_Kosli_D (Haryana)",
        OriginCenter: "Rewari_Kosli_D (Haryana)",
        FacilityCity: "Jhajjar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4919,
        Pin: 638057,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4920,
        Pin: 711411,
        DispatchCenter: "Howrah_Kamrangu_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4921,
        Pin: 721306,
        DispatchCenter: "Kharagpur_Nimpura_I (West Bengal)",
        OriginCenter: "Kharagpur_Nimpura_I (West Bengal)",
        FacilityCity: "Kharagpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4922,
        Pin: 630314,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4923,
        Pin: 630105,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4924,
        Pin: 413212,
        DispatchCenter: "Indapur_ChaitanyaPlaza_D (Maharashtra)",
        OriginCenter: "Indapur_ChaitanyaPlaza_D (Maharashtra)",
        FacilityCity: "Indapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4925,
        Pin: 731132,
        DispatchCenter: "Rampurhat_Suri_D (West Bengal)",
        OriginCenter: "Rampurhat_Suri_D (West Bengal)",
        FacilityCity: "Rampurhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4926,
        Pin: 486115,
        DispatchCenter: "Sohagi_Gambhirpur_D (Madhya Pradesh)",
        OriginCenter: "Sohagi_Gambhirpur_D (Madhya Pradesh)",
        FacilityCity: "Sohagi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 4927,
        Pin: 723153,
        DispatchCenter: "Balarampur_Rangadih_D (West Bengal)",
        OriginCenter: "Balarampur_Rangadih_D (West Bengal)",
        FacilityCity: "Balarampur-WB",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4928,
        Pin: 509353,
        DispatchCenter: "Makthal_Shabaricolony_DPP (Telangana)",
        OriginCenter: "Makthal_Shabaricolony_DPP (Telangana)",
        FacilityCity: "Makthal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4929,
        Pin: 700098,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4930,
        Pin: 768037,
        DispatchCenter: "Padampur_Melchhamunda_DPP (Orissa)",
        OriginCenter: "Padampur_Melchhamunda_DPP (Orissa)",
        FacilityCity: "Padampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4931,
        Pin: 396463,
        DispatchCenter: "Navsari_Vejalpore_D (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Navsari",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4932,
        Pin: 416118,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4933,
        Pin: 680101,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4934,
        Pin: 572213,
        DispatchCenter: "Kunigal_Vidyanagar_D (Karnataka)",
        OriginCenter: "Kunigal_Vidyanagar_D (Karnataka)",
        FacilityCity: "Kunigal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4935,
        Pin: 508002,
        DispatchCenter: "Nalgonda_Duppalapally_I (Telangana)",
        OriginCenter: "Nalgonda_Duppalapally_I (Telangana)",
        FacilityCity: "Nalgonda",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4936,
        Pin: 680685,
        DispatchCenter: "Kodungallur_Methala_D (Kerala)",
        OriginCenter: "Kodungallur_Methala_D (Kerala)",
        FacilityCity: "Kodungallur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4937,
        Pin: 845429,
        DispatchCenter: "Jiwdhara_Kotwa_D (Bihar)",
        OriginCenter: "Jiwdhara_Kotwa_D (Bihar)",
        FacilityCity: "Jiwdhara",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4938,
        Pin: 516257,
        DispatchCenter: "Rayachoti_Chennamukkapalle_D (Andhra Pradesh)",
        OriginCenter: "Rayachoti_Chennamukkapalle_D (Andhra Pradesh)",
        FacilityCity: "Rayachoti",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4939,
        Pin: 416312,
        DispatchCenter: "Tasgaon_Trimurticolony_D (Maharashtra)",
        OriginCenter: "Tasgaon_Trimurticolony_D (Maharashtra)",
        FacilityCity: "Tasgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4940,
        Pin: 387635,
        DispatchCenter: "Kapadvanj_Dholikui_D (Gujarat)",
        OriginCenter: "Kapadvanj_Dholikui_D (Gujarat)",
        FacilityCity: "Kapadvanj",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4941,
        Pin: 110609,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4942,
        Pin: 132116,
        DispatchCenter: "Karnal_Kutail_H (Haryana)",
        OriginCenter: "Karnal_Kutail_H (Haryana)",
        FacilityCity: "Karnal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4943,
        Pin: 302013,
        DispatchCenter: "Jaipur_VishwakarmaIndArea_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4944,
        Pin: 700051,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4945,
        Pin: 532402,
        DispatchCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        OriginCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        FacilityCity: "Srikakulam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4946,
        Pin: 221602,
        DispatchCenter: "Madhuban_Gayatripuram_DPP (Uttar Pradesh)",
        OriginCenter: "Madhuban_Gayatripuram_DPP (Uttar Pradesh)",
        FacilityCity: "Madhuban",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4947,
        Pin: 852131,
        DispatchCenter: "Supaul_SadrHsptl_D (Bihar)",
        OriginCenter: "Supaul_SadrHsptl_D (Bihar)",
        FacilityCity: "Supaul",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4948,
        Pin: 835226,
        DispatchCenter: "Simdega_KhutiTola_D (Jharkhand)",
        OriginCenter: "Simdega_KhutiTola_D (Jharkhand)",
        FacilityCity: "Simdega",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4949,
        Pin: 691602,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4950,
        Pin: 623531,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4951,
        Pin: 680724,
        DispatchCenter: "Chalakudy_ForestChowk_D (Kerala)",
        OriginCenter: "Chalakudy_ForestChowk_D (Kerala)",
        FacilityCity: "Chalakudy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4952,
        Pin: 822124,
        DispatchCenter: "Bisrampur_Rehla_D (Jharkhand)",
        OriginCenter: "Bisrampur_Rehla_D (Jharkhand)",
        FacilityCity: "Bisrampur",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4953,
        Pin: 370427,
        DispatchCenter: "Bhuj_Madhapar_D (Gujarat)",
        OriginCenter: "Bhuj_Madhapar_D (Gujarat)",
        FacilityCity: "Bhuj",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4954,
        Pin: 110108,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4955,
        Pin: 360515,
        DispatchCenter: "Bhanvad_MadhavPlaza_DPP (Gujarat)",
        OriginCenter: "Bhanvad_MadhavPlaza_DPP (Gujarat)",
        FacilityCity: "Bhanvad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 4956,
        Pin: 144304,
        DispatchCenter: "Begowal_PinkMrkt_D (Punjab)",
        OriginCenter: "Begowal_PinkMrkt_D (Punjab)",
        FacilityCity: "Begowal",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4957,
        Pin: 732126,
        DispatchCenter: "Itahar_Kumedpur_D (West Bengal)",
        OriginCenter: "Itahar_Kumedpur_D (West Bengal)",
        FacilityCity: "Itahar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4958,
        Pin: 515414,
        DispatchCenter: "Tadipatri_Kalavagadacol_D (Andhra Pradesh)",
        OriginCenter: "Tadipatri_Kalavagadacol_D (Andhra Pradesh)",
        FacilityCity: "Tadipatri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4959,
        Pin: 768029,
        DispatchCenter: "Bargarh_Pada_D (Orissa)",
        OriginCenter: "Bargarh_Pada_D (Orissa)",
        FacilityCity: "Bargarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4960,
        Pin: 764055,
        DispatchCenter: "Jeypore_Bariniput_I (Orissa)",
        OriginCenter: "Jeypore_Bariniput_I (Orissa)",
        FacilityCity: "Jeypore",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4961,
        Pin: 505306,
        DispatchCenter: "Koratla_PRBM_DPP (Telangana)",
        OriginCenter: "Koratla_PRBM_DPP (Telangana)",
        FacilityCity: "Koratla",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4962,
        Pin: 831035,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4963,
        Pin: 799113,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        Zone : "NE"
      },
      {
        SN: 4964,
        Pin: 303010,
        DispatchCenter: "Phulera_NarenaRD_D (Rajasthan)",
        OriginCenter: "Phulera_NarenaRD_D (Rajasthan)",
        FacilityCity: "Phulera",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4965,
        Pin: 690540,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 4966,
        Pin: 505186,
        DispatchCenter: "KarimNagar_Alugunoor_I (Telangana)",
        OriginCenter: "KarimNagar_Alugunoor_I (Telangana)",
        FacilityCity: "Karim Nagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4967,
        Pin: 110113,
        DispatchCenter: "Delhi_RailYardRd_L (Delhi)",
        OriginCenter: "Delhi_RailYardRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4968,
        Pin: 712613,
        DispatchCenter: "Khanakul_Antngr_D (West Bengal)",
        OriginCenter: "Khanakul_Antngr_D (West Bengal)",
        FacilityCity: "Khanakul",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4969,
        Pin: 635601,
        DispatchCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        OriginCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        FacilityCity: "Tiruppattur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4970,
        Pin: 493662,
        DispatchCenter: "Gariyaband_Dongari_D (Chhattisgarh)",
        OriginCenter: "Gariyaband_Dongari_D (Chhattisgarh)",
        FacilityCity: "Gariyaband",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4971,
        Pin: 507306,
        DispatchCenter: "Sathupally_Ayyagaripeta_D (Telangana)",
        OriginCenter: "Sathupally_Ayyagaripeta_D (Telangana)",
        FacilityCity: "Sathupally",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4972,
        Pin: 122005,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4973,
        Pin: 737126,
        DispatchCenter: "Namchi_ChungRd_D (Sikkim)",
        OriginCenter: "Namchi_ChungRd_D (Sikkim)",
        FacilityCity: "Namchi",
        FacilityState: "Sikkim",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4974,
        Pin: 752067,
        DispatchCenter: "Puri_Purussottamnagar_D (Orissa)",
        OriginCenter: "Puri_Purussottamnagar_D (Orissa)",
        FacilityCity: "Puri",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4975,
        Pin: 141412,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4976,
        Pin: 606703,
        DispatchCenter: "Uthangarai_Singarapettai_D (Tamil Nadu)",
        OriginCenter: "Uthangarai_Singarapettai_D (Tamil Nadu)",
        FacilityCity: "Uthangarai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4977,
        Pin: 422102,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 4978,
        Pin: 695313,
        DispatchCenter: "Attingal_Central_D_2 (Kerala)",
        OriginCenter: "Attingal_Central_D_2 (Kerala)",
        FacilityCity: "Attingal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 4979,
        Pin: 626205,
        DispatchCenter: "Sattur_Thendralnagar_DPP (Tamil Nadu)",
        OriginCenter: "Sattur_Thendralnagar_DPP (Tamil Nadu)",
        FacilityCity: "Sattur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4980,
        Pin: 822102,
        DispatchCenter: "Daltonganj_SouthRedma_D (Jharkhand)",
        OriginCenter: "Daltonganj_SouthRedma_D (Jharkhand)",
        FacilityCity: "Daltonganj",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 4981,
        Pin: 743289,
        DispatchCenter: "Joypul_Maniktala_D (West Bengal)",
        OriginCenter: "Joypul_Maniktala_D (West Bengal)",
        FacilityCity: "Joypul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 4982,
        Pin: 223224,
        DispatchCenter: "Azamgarh_Martinganj_DPP (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Martinganj_DPP (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4983,
        Pin: 627304,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4984,
        Pin: 521158,
        DispatchCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        OriginCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        FacilityCity: "Pamarru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4985,
        Pin: 483113,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 4986,
        Pin: 620003,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4987,
        Pin: 181123,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 4988,
        Pin: 501143,
        DispatchCenter: "Tandur_SnthiNGR_D (Telangana)",
        OriginCenter: "Tandur_SnthiNGR_D (Telangana)",
        FacilityCity: "Tandur-TL",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4989,
        Pin: 332303,
        DispatchCenter: "Jhunjhunu_Mandawa_DPP (Rajasthan)",
        OriginCenter: "Jhunjhunu_Mandawa_DPP (Rajasthan)",
        FacilityCity: "Jhunjhunu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4990,
        Pin: 500038,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4991,
        Pin: 122211,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 4992,
        Pin: 413706,
        DispatchCenter: "Rahuri_Mehetremala_D (Maharashtra)",
        OriginCenter: "Rahuri_Mehetremala_D (Maharashtra)",
        FacilityCity: "Rahuri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 4993,
        Pin: 642130,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4994,
        Pin: 758028,
        DispatchCenter: "Ghatgaon_Harichandanpur_D (Orissa)",
        OriginCenter: "Ghatgaon_Harichandanpur_D (Orissa)",
        FacilityCity: "Ghatgaon",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 4995,
        Pin: 532290,
        DispatchCenter: "Sompeta_PostOffice_DPP (Andhra Pradesh)",
        OriginCenter: "Sompeta_PostOffice_DPP (Andhra Pradesh)",
        FacilityCity: "Sompeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 4996,
        Pin: 523226,
        DispatchCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        OriginCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        FacilityCity: "Ongole",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 4997,
        Pin: 329090,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 4998,
        Pin: 761025,
        DispatchCenter: "Behrampur_Kanmna_D (Orissa)",
        OriginCenter: "Behrampur_Kanmna_D (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 4999,
        Pin: 212641,
        DispatchCenter: "Sirathu_Ward4_DPP (Uttar Pradesh)",
        OriginCenter: "Sirathu_Ward4_DPP (Uttar Pradesh)",
        FacilityCity: "Sirathu",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5000,
        Pin: 517113,
        DispatchCenter: "Pileru_Plot14_D (Andhra Pradesh)",
        OriginCenter: "Pileru_Plot14_D (Andhra Pradesh)",
        FacilityCity: "Pileru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5001,
        Pin: 496118,
        DispatchCenter: "Pathalgaon_Kmkla_D (Chhattisgarh)",
        OriginCenter: "Pathalgaon_Kmkla_D (Chhattisgarh)",
        FacilityCity: "Pathalgaon",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5002,
        Pin: 591303,
        DispatchCenter: "Ugar_GopiHotel_D (Karnataka)",
        OriginCenter: "Ugar_GopiHotel_D (Karnataka)",
        FacilityCity: "Ugar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5003,
        Pin: 394710,
        DispatchCenter: "Ahwa_Panchlicolony_DPP (Gujarat)",
        OriginCenter: "Ahwa_Panchlicolony_DPP (Gujarat)",
        FacilityCity: "Ahwa",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5004,
        Pin: 781339,
        DispatchCenter: "Nalbari_Chokbazar_D (Assam)",
        OriginCenter: "Nalbari_Chokbazar_D (Assam)",
        FacilityCity: "Nalbari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5005,
        Pin: 410515,
        DispatchCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        OriginCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        FacilityCity: "Ambegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5006,
        Pin: 521312,
        DispatchCenter: "Gannavaram_SH298_D (Andhra Pradesh)",
        OriginCenter: "Gannavaram_SH298_D (Andhra Pradesh)",
        FacilityCity: "Gannavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5007,
        Pin: 695571,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5008,
        Pin: 734320,
        DispatchCenter: "Kalijhora_KljhraBazar_DPP (West Bengal)",
        OriginCenter: "Kalijhora_KljhraBazar_DPP (West Bengal)",
        FacilityCity: "Kalijhora",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5009,
        Pin: 225206,
        DispatchCenter: "Ramnagar_Burhwal_D (Uttar Pradesh)",
        OriginCenter: "Ramnagar_Burhwal_D (Uttar Pradesh)",
        FacilityCity: "Ramnagar-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5010,
        Pin: 581400,
        DispatchCenter: "Mallapur_ShtDRDPP_D (Karnataka)",
        OriginCenter: "Mallapur_ShtDRDPP_D (Karnataka)",
        FacilityCity: "Mallapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5011,
        Pin: 571254,
        DispatchCenter: "Gonikoppal_Patelnagar_D (Karnataka)",
        OriginCenter: "Gonikoppal_Patelnagar_D (Karnataka)",
        FacilityCity: "Gonikoppal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5012,
        Pin: 795129,
        DispatchCenter: "Senapati_WardNo1_D (Manipur)",
        OriginCenter: "Senapati_WardNo1_D (Manipur)",
        FacilityCity: "Kangpokpi",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5013,
        Pin: 509127,
        DispatchCenter: "Gadwal_Aiza_D (Telangana)",
        OriginCenter: "Gadwal_Aiza_D (Telangana)",
        FacilityCity: "Gadwal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5014,
        Pin: 508250,
        DispatchCenter: "Yacharam_Nallavelly_D (Telangana)",
        OriginCenter: "Yacharam_Nallavelly_D (Telangana)",
        FacilityCity: "Yacharam",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5015,
        Pin: 144632,
        DispatchCenter: "Phagwara_PurewalNgr_D (Punjab)",
        OriginCenter: "Phagwara_PurewalNgr_D (Punjab)",
        FacilityCity: "Phagwara",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5016,
        Pin: 425307,
        DispatchCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        OriginCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        FacilityCity: "Bhusawal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5017,
        Pin: 673602,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5018,
        Pin: 628701,
        DispatchCenter: "Eral_Nazareth_D (Tamil Nadu)",
        OriginCenter: "Eral_Nazareth_D (Tamil Nadu)",
        FacilityCity: "Eral",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5019,
        Pin: 582103,
        DispatchCenter: "Gadag_Vidyanagar_D (Karnataka)",
        OriginCenter: "Gadag_Vidyanagar_D (Karnataka)",
        FacilityCity: "Gadag",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5020,
        Pin: 795112,
        DispatchCenter: "Senapati_OldMarket_D (Manipur)",
        OriginCenter: "Senapati_OldMarket_D (Manipur)",
        FacilityCity: "Senapati",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5021,
        Pin: 572142,
        DispatchCenter: "Kunigal_Vidyanagar_D (Karnataka)",
        OriginCenter: "Kunigal_Vidyanagar_D (Karnataka)",
        FacilityCity: "Kunigal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5022,
        Pin: 754225,
        DispatchCenter: "Garjanpur_Gossei_D (Orissa)",
        OriginCenter: "Garjanpur_Gossei_D (Orissa)",
        FacilityCity: "Garjanpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5023,
        Pin: 480882,
        DispatchCenter: "Seoni_Dalsagar_D (Madhya Pradesh)",
        OriginCenter: "Seoni_Dalsagar_D (Madhya Pradesh)",
        FacilityCity: "Seoni",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5024,
        Pin: 204213,
        DispatchCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        OriginCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        FacilityCity: "Hathras",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5025,
        Pin: 641047,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5026,
        Pin: 841217,
        DispatchCenter: "Hajipur_Hathsarganj_D (Bihar)",
        OriginCenter: "Hajipur_Hathsarganj_D (Bihar)",
        FacilityCity: "Hajipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5027,
        Pin: 517414,
        DispatchCenter: "Punganur_Nspeta_D (Andhra Pradesh)",
        OriginCenter: "Punganur_Nspeta_D (Andhra Pradesh)",
        FacilityCity: "Punganur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5028,
        Pin: 622403,
        DispatchCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5029,
        Pin: 212301,
        DispatchCenter: "Allahabad_Ghatwa_D (Uttar Pradesh)",
        OriginCenter: "Allahabad_Ghatwa_D (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5030,
        Pin: 800009,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5031,
        Pin: 609805,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5032,
        Pin: 532427,
        DispatchCenter: "Narasannapeta_SridviNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasannapeta_SridviNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasannapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5033,
        Pin: 783301,
        DispatchCenter: "Dhubri_Tetultol_D (Assam)",
        OriginCenter: "Dhubri_Tetultol_D (Assam)",
        FacilityCity: "Dhubri",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5034,
        Pin: 505467,
        DispatchCenter: "Husnabad_HanmakondaRd_D (Telangana)",
        OriginCenter: "Husnabad_HanmakondaRd_D (Telangana)",
        FacilityCity: "Husnabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5035,
        Pin: 302005,
        DispatchCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5036,
        Pin: 122232,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5037,
        Pin: 736101,
        DispatchCenter: "CoochBehar_khagrabari_I (West Bengal)",
        OriginCenter: "CoochBehar_khagrabari_I (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5038,
        Pin: 632608,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5039,
        Pin: 127114,
        DispatchCenter: "Bhiwani_Bhagganpuri_D (Haryana)",
        OriginCenter: "Bhiwani_Bhagganpuri_D (Haryana)",
        FacilityCity: "Bhiwani",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5040,
        Pin: 425109,
        DispatchCenter: "Jalgaon_Omnagar_D (Maharashtra)",
        OriginCenter: "Jalgaon_Omnagar_D (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5041,
        Pin: 481115,
        DispatchCenter: "Balaghat_Kirnapur_DPP (Madhya Pradesh)",
        OriginCenter: "Balaghat_Kirnapur_DPP (Madhya Pradesh)",
        FacilityCity: "Balaghat",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 5042,
        Pin: 201307,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5043,
        Pin: 175105,
        DispatchCenter: "Jari_Jari_DPP (Himachal Pradesh)",
        OriginCenter: "Jari_Jari_DPP (Himachal Pradesh)",
        FacilityCity: "Jari",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5044,
        Pin: 140502,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5045,
        Pin: 273011,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5046,
        Pin: 272201,
        DispatchCenter: "Naugarh_Shohratgarh_D (Uttar Pradesh)",
        OriginCenter: "Naugarh_Shohratgarh_D (Uttar Pradesh)",
        FacilityCity: "Naugarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5047,
        Pin: 456222,
        DispatchCenter: "Badnagar_Teacherscolony_D (Madhya Pradesh)",
        OriginCenter: "Badnagar_Teacherscolony_D (Madhya Pradesh)",
        FacilityCity: "Badnagar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5048,
        Pin: 629003,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5049,
        Pin: 712701,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5050,
        Pin: 741404,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5051,
        Pin: 361310,
        DispatchCenter: "Khambhalia_Dharampur_D (Gujarat)",
        OriginCenter: "Khambhalia_Dharampur_D (Gujarat)",
        FacilityCity: "Khambhalia",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5052,
        Pin: 122413,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5053,
        Pin: 387240,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5054,
        Pin: 591232,
        DispatchCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        OriginCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        FacilityCity: "Athani",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5055,
        Pin: 673025,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5056,
        Pin: 403104,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5057,
        Pin: 181145,
        DispatchCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5058,
        Pin: 502115,
        DispatchCenter: "Medak_RTCclny_D (Telangana)",
        OriginCenter: "Medak_RTCclny_D (Telangana)",
        FacilityCity: "Medak",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5059,
        Pin: 641663,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5060,
        Pin: 843118,
        DispatchCenter: "Dholi_Etwarpurjointi_D (Bihar)",
        OriginCenter: "Dholi_Etwarpurjointi_D (Bihar)",
        FacilityCity: "Dholi",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5061,
        Pin: 627809,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5062,
        Pin: 846003,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5063,
        Pin: 424304,
        DispatchCenter: "Sakri_Arihantnagar_D (Maharashtra)",
        OriginCenter: "Sakri_Arihantnagar_D (Maharashtra)",
        FacilityCity: "Sakri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5064,
        Pin: 802159,
        DispatchCenter: "Piro_Bachri_D (Bihar)",
        OriginCenter: "Piro_Bachri_D (Bihar)",
        FacilityCity: "Piro",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5065,
        Pin: 721453,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5066,
        Pin: 785654,
        DispatchCenter: "Sivasagar_Pdinnagar_D (Assam)",
        OriginCenter: "Sivasagar_Pdinnagar_D (Assam)",
        FacilityCity: "Sivasagar",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5067,
        Pin: 723214,
        DispatchCenter: "Jhalda_GMukrDPP_D (West Bengal)",
        OriginCenter: "Jhalda_GMukrDPP_D (West Bengal)",
        FacilityCity: "Jhalda",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5068,
        Pin: 244714,
        DispatchCenter: "Kashipur_Khokhratal_D (Uttarakhand)",
        OriginCenter: "Kashipur_Khokhratal_D (Uttarakhand)",
        FacilityCity: "Kashipur",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5069,
        Pin: 835102,
        DispatchCenter: "Jhalda_GMukrDPP_D (West Bengal)",
        OriginCenter: "Jhalda_GMukrDPP_D (West Bengal)",
        FacilityCity: "Jhalda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5070,
        Pin: 396145,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5071,
        Pin: 393135,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5072,
        Pin: 421300,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5073,
        Pin: 581323,
        DispatchCenter: "Kumta_Central_DPP_2 (Karnataka)",
        OriginCenter: "Kumta_Central_DPP_2 (Karnataka)",
        FacilityCity: "Kumta",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5074,
        Pin: 574231,
        DispatchCenter: "Bantwal_BambilRd_D (Karnataka)",
        OriginCenter: "Bantwal_BambilRd_D (Karnataka)",
        FacilityCity: "Bantwal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5075,
        Pin: 785612,
        DispatchCenter: "Bokakhat_Kumaraniati_D (Assam)",
        OriginCenter: "Bokakhat_Kumaraniati_D (Assam)",
        FacilityCity: "Bokakhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5076,
        Pin: 416527,
        DispatchCenter: "Halkarni_MajareKarve_D (Maharashtra)",
        OriginCenter: "Halkarni_MajareKarve_D (Maharashtra)",
        FacilityCity: "Halkarni",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5077,
        Pin: 341021,
        DispatchCenter: "Nagaur_Jantacomplex_D (Rajasthan)",
        OriginCenter: "Nagaur_Jantacomplex_D (Rajasthan)",
        FacilityCity: "Nagaur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5078,
        Pin: 743269,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5079,
        Pin: 250002,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5080,
        Pin: 281205,
        DispatchCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        OriginCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        FacilityCity: "Khair",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5081,
        Pin: 765034,
        DispatchCenter: "Gunupur_GnprMkt_DPP (Orissa)",
        OriginCenter: "Gunupur_GnprMkt_DPP (Orissa)",
        FacilityCity: "Gunupur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5082,
        Pin: 335525,
        DispatchCenter: "Sangaria_Wardno16_D (Rajasthan)",
        OriginCenter: "Sangaria_Wardno16_D (Rajasthan)",
        FacilityCity: "Sangaria",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5083,
        Pin: 591115,
        DispatchCenter: "Kittur_Guruwarpeth_DPP (Karnataka)",
        OriginCenter: "Kittur_Guruwarpeth_DPP (Karnataka)",
        FacilityCity: "Kittur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5084,
        Pin: 231304,
        DispatchCenter: "Chunnar_Bharpur_D (Uttar Pradesh)",
        OriginCenter: "Chunnar_Bharpur_D (Uttar Pradesh)",
        FacilityCity: "Chunnar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5085,
        Pin: 181122,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5086,
        Pin: 695523,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5087,
        Pin: 560026,
        DispatchCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        OriginCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5088,
        Pin: 311404,
        DispatchCenter: "Shahpura_JhajpurRd_DPP (Rajasthan)",
        OriginCenter: "Shahpura_JhajpurRd_DPP (Rajasthan)",
        FacilityCity: "Shahpura",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5089,
        Pin: 388260,
        DispatchCenter: "Lunawada_VrdhriRD_D (Gujarat)",
        OriginCenter: "Lunawada_VrdhriRD_D (Gujarat)",
        FacilityCity: "Lunawada",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5090,
        Pin: 412209,
        DispatchCenter: "Shirur_RanjangaonMIDC_L (Maharashtra)",
        OriginCenter: "Shirur_RanjangaonMIDC_L (Maharashtra)",
        FacilityCity: "Shirur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5091,
        Pin: 503305,
        DispatchCenter: "Degloor_GouklNgr_DPP (Maharashtra)",
        OriginCenter: "Degloor_GouklNgr_DPP (Maharashtra)",
        FacilityCity: "Degloor",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5092,
        Pin: 221311,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5093,
        Pin: 171211,
        DispatchCenter: "Nerwa_Majholti_D (Himachal Pradesh)",
        OriginCenter: "Nerwa_Majholti_D (Himachal Pradesh)",
        FacilityCity: "Nerwa",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5094,
        Pin: 497118,
        DispatchCenter: "RajpurCH_Nawki_D (Chhattisgarh)",
        OriginCenter: "RajpurCH_Nawki_D (Chhattisgarh)",
        FacilityCity: "Rajpur-CH",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5095,
        Pin: 686632,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5096,
        Pin: 673604,
        DispatchCenter: "Areakode_Mukkam_D (Kerala)",
        OriginCenter: "Areakode_Mukkam_D (Kerala)",
        FacilityCity: "Areacode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5097,
        Pin: 175102,
        DispatchCenter: "Kullu_Mohal_D (Himachal Pradesh)",
        OriginCenter: "Kullu_Mohal_D (Himachal Pradesh)",
        FacilityCity: "Kullu",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5098,
        Pin: 403802,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5099,
        Pin: 532429,
        DispatchCenter: "Narasannapeta_SridviNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasannapeta_SridviNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasannapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5100,
        Pin: 450051,
        DispatchCenter: "Khandwa_TilakNagar_D (Madhya Pradesh)",
        OriginCenter: "Khandwa_TilakNagar_D (Madhya Pradesh)",
        FacilityCity: "Khandwa",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 5101,
        Pin: 391165,
        DispatchCenter: "ChhotaUdaipur_Panjrapole_D (Gujarat)",
        OriginCenter: "ChhotaUdaipur_Panjrapole_D (Gujarat)",
        FacilityCity: "Chhota Udaipur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5102,
        Pin: 625516,
        DispatchCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        OriginCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        FacilityCity: "Cumbum",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5103,
        Pin: 274003,
        DispatchCenter: "Deoria_Ramnathnagar_D (Uttar Pradesh)",
        OriginCenter: "Deoria_Ramnathnagar_D (Uttar Pradesh)",
        FacilityCity: "Deoria",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5104,
        Pin: 781016,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5105,
        Pin: 425431,
        DispatchCenter: "Shahada_Untavad_D (Maharashtra)",
        OriginCenter: "Shahada_Untavad_D (Maharashtra)",
        FacilityCity: "Shahada",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5106,
        Pin: 606807,
        DispatchCenter: "Chetpet_Savarapoondi_D (Tamil Nadu)",
        OriginCenter: "Chetpet_Savarapoondi_D (Tamil Nadu)",
        FacilityCity: "Chetpet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5107,
        Pin: 713370,
        DispatchCenter: "Asansol_Addacolony_D (West Bengal)",
        OriginCenter: "Asansol_Addacolony_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5108,
        Pin: 585224,
        DispatchCenter: "Shorapur_GandhiNagar_D (Karnataka)",
        OriginCenter: "Shorapur_GandhiNagar_D (Karnataka)",
        FacilityCity: "Shorapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5109,
        Pin: 173025,
        DispatchCenter: "PaontaSahib_Suryacolony_D (Himachal Pradesh)",
        OriginCenter: "PaontaSahib_Suryacolony_D (Himachal Pradesh)",
        FacilityCity: "Paonta Sahib",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5110,
        Pin: 786007,
        DispatchCenter: "Dibrugarh_Mohanbari_H (Assam)",
        OriginCenter: "Dibrugarh_Mohanbari_H (Assam)",
        FacilityCity: "Dibrugarh",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5111,
        Pin: 624103,
        DispatchCenter: "Kodaikanal_Mudliyrpurm_D (Tamil Nadu)",
        OriginCenter: "Kodaikanal_Mudliyrpurm_D (Tamil Nadu)",
        FacilityCity: "Kodaikanal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5112,
        Pin: 522615,
        DispatchCenter: "Piduguralla_Rasoolpet_D (Andhra Pradesh)",
        OriginCenter: "Piduguralla_Rasoolpet_D (Andhra Pradesh)",
        FacilityCity: "Piduguralla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5113,
        Pin: 402114,
        DispatchCenter: "Shriwardhan_Kherdi_D (Maharashtra)",
        OriginCenter: "Shriwardhan_Kherdi_D (Maharashtra)",
        FacilityCity: "Shriwardhan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5114,
        Pin: 393110,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5115,
        Pin: 755024,
        DispatchCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        OriginCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        FacilityCity: "Chandikhol",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5116,
        Pin: 222128,
        DispatchCenter: "Mariahu_RnprJmlpr_D (Uttar Pradesh)",
        OriginCenter: "Mariahu_RnprJmlpr_D (Uttar Pradesh)",
        FacilityCity: "Mariahu",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5117,
        Pin: 492002,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5118,
        Pin: 481879,
        DispatchCenter: "Dindori_Deoramal_D (Madhya Pradesh)",
        OriginCenter: "Dindori_Deoramal_D (Madhya Pradesh)",
        FacilityCity: "Dindori",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 5119,
        Pin: 755051,
        DispatchCenter: "Chandipur_Keshpur_D (Orissa)",
        OriginCenter: "Chandipur_Keshpur_D (Orissa)",
        FacilityCity: "Chandipur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5120,
        Pin: 177205,
        DispatchCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        OriginCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        FacilityCity: "Amb",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5121,
        Pin: 431809,
        DispatchCenter: "Dharmabad_BalajiNgr_D (Maharashtra)",
        OriginCenter: "Dharmabad_BalajiNgr_D (Maharashtra)",
        FacilityCity: "Dharmabad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5122,
        Pin: 608201,
        DispatchCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        OriginCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        FacilityCity: "Chidambaram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5123,
        Pin: 781369,
        DispatchCenter: "Nalbari_Chokbazar_D (Assam)",
        OriginCenter: "Nalbari_Chokbazar_D (Assam)",
        FacilityCity: "Nalbari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5124,
        Pin: 713148,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5125,
        Pin: 534217,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5126,
        Pin: 481441,
        DispatchCenter: "Waraseoni_Lalbarra_D (Madhya Pradesh)",
        OriginCenter: "Waraseoni_Lalbarra_D (Madhya Pradesh)",
        FacilityCity: "Waraseoni",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5127,
        Pin: 202133,
        DispatchCenter: "Aligarh_Atrauli_D (Uttar Pradesh)",
        OriginCenter: "Aligarh_Atrauli_D (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5128,
        Pin: 636404,
        DispatchCenter: "Mettur_Samballi_D (Tamil Nadu)",
        OriginCenter: "Mettur_Samballi_D (Tamil Nadu)",
        FacilityCity: "Mettur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5129,
        Pin: 603103,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5130,
        Pin: 143423,
        DispatchCenter: "TarnTaranSahib_BasantAvenue_D (Punjab)",
        OriginCenter: "TarnTaranSahib_BasantAvenue_D (Punjab)",
        FacilityCity: "Tarn Taran Sahib",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5131,
        Pin: 411051,
        DispatchCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        OriginCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5132,
        Pin: 784505,
        DispatchCenter: "Tezpur_Dipota_H (Assam)",
        OriginCenter: "Tezpur_Dipota_H (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5133,
        Pin: 491337,
        DispatchCenter: "Mungeli_PandariaRd_DPP (Chhattisgarh)",
        OriginCenter: "Mungeli_PandariaRd_DPP (Chhattisgarh)",
        FacilityCity: "Mungeli",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5134,
        Pin: 680731,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5135,
        Pin: 503311,
        DispatchCenter: "Armoor_Mamidipillay_D (Telangana)",
        OriginCenter: "Armoor_Mamidipillay_D (Telangana)",
        FacilityCity: "Armoor",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5136,
        Pin: 322255,
        DispatchCenter: "Karauli_Subhashnagar_D (Rajasthan)",
        OriginCenter: "Karauli_Subhashnagar_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5137,
        Pin: 768202,
        DispatchCenter: "Jharsuguda_Ekatali_D (Orissa)",
        OriginCenter: "Jharsuguda_Ekatali_D (Orissa)",
        FacilityCity: "Jharsuguda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5138,
        Pin: 221503,
        DispatchCenter: "Saidabad_Naibasti_D (Uttar Pradesh)",
        OriginCenter: "Saidabad_Naibasti_D (Uttar Pradesh)",
        FacilityCity: "Saidabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5139,
        Pin: 686730,
        DispatchCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        OriginCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        FacilityCity: "Muvattupuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5140,
        Pin: 627413,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5141,
        Pin: 583223,
        DispatchCenter: "Hospet_Vijayanagara_D (Karnataka)",
        OriginCenter: "Hospet_Vijayanagara_D (Karnataka)",
        FacilityCity: "Hospet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5142,
        Pin: 383421,
        DispatchCenter: "Idar_GIDC_D (Gujarat)",
        OriginCenter: "Idar_GIDC_D (Gujarat)",
        FacilityCity: "Idar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5143,
        Pin: 852202,
        DispatchCenter: "Saharsa_NayaBzar_D (Bihar)",
        OriginCenter: "Saharsa_NayaBzar_D (Bihar)",
        FacilityCity: "Saharsa",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5144,
        Pin: 505502,
        DispatchCenter: "Veenavanka_SBIBnk_DPP (Telangana)",
        OriginCenter: "Veenavanka_SBIBnk_DPP (Telangana)",
        FacilityCity: "Veenavanka",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5145,
        Pin: 584102,
        DispatchCenter: "Raichur_Wadloor_I (Karnataka)",
        OriginCenter: "Raichur_Wadloor_I (Karnataka)",
        FacilityCity: "Raichur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5146,
        Pin: 828106,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5147,
        Pin: 175034,
        DispatchCenter: "Ghumarwin_Gndhichk_D (Himachal Pradesh)",
        OriginCenter: "Ghumarwin_Gndhichk_D (Himachal Pradesh)",
        FacilityCity: "Ghumarwin",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5148,
        Pin: 721655,
        DispatchCenter: "Bajkul_Kismat_D (West Bengal)",
        OriginCenter: "Bajkul_Kismat_D (West Bengal)",
        FacilityCity: "Bajkul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5149,
        Pin: 680655,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5150,
        Pin: 413711,
        DispatchCenter: "Loni_BailBzr_DPP (Maharashtra)",
        OriginCenter: "Loni_BailBzr_DPP (Maharashtra)",
        FacilityCity: "Loni",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5151,
        Pin: 416201,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5152,
        Pin: 225207,
        DispatchCenter: "Ramnagar_Burhwal_D (Uttar Pradesh)",
        OriginCenter: "Ramnagar_Burhwal_D (Uttar Pradesh)",
        FacilityCity: "Ramnagar-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5153,
        Pin: 496115,
        DispatchCenter: "Lailunga_BanjariPara_D (Chhattisgarh)",
        OriginCenter: "Lailunga_BanjariPara_D (Chhattisgarh)",
        FacilityCity: "Lailunga",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5154,
        Pin: 768224,
        DispatchCenter: "Bamra_Govindpur_D (Orissa)",
        OriginCenter: "Bamra_Govindpur_D (Orissa)",
        FacilityCity: "Bamra",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5155,
        Pin: 110009,
        DispatchCenter: "Delhi_Libaspur_L (Delhi)",
        OriginCenter: "Delhi_Libaspur_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5156,
        Pin: 508248,
        DispatchCenter: "Devarakonda_Karunacol_D (Telangana)",
        OriginCenter: "Devarakonda_Karunacol_D (Telangana)",
        FacilityCity: "Devarakonda",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5157,
        Pin: 639115,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5158,
        Pin: 285126,
        DispatchCenter: "Jalaun_Madhogarh_D (Uttar Pradesh)",
        OriginCenter: "Jalaun_Madhogarh_D (Uttar Pradesh)",
        FacilityCity: "Jalaun",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5159,
        Pin: 638182,
        DispatchCenter: "Velur_ChakraNgr_DPP (Tamil Nadu)",
        OriginCenter: "Velur_ChakraNgr_DPP (Tamil Nadu)",
        FacilityCity: "Velur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5160,
        Pin: 802133,
        DispatchCenter: "Dumraon_MahvidylaRd_D (Bihar)",
        OriginCenter: "Dumraon_MahvidylaRd_D (Bihar)",
        FacilityCity: "Dumraon",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5161,
        Pin: 182127,
        DispatchCenter: "Udhampur_Sunal_D (Jammu & Kashmir)",
        OriginCenter: "Udhampur_Sunal_D (Jammu & Kashmir)",
        FacilityCity: "Udhampur",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5162,
        Pin: 522238,
        DispatchCenter: "Guntur_Amaravati_D (Andhra Pradesh)",
        OriginCenter: "Guntur_Amaravati_D (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5163,
        Pin: 673585,
        DispatchCenter: "Narikkuni_Padanilam_D (Kerala)",
        OriginCenter: "Narikkuni_Padanilam_D (Kerala)",
        FacilityCity: "Narikkuni",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5164,
        Pin: 627759,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5165,
        Pin: 172108,
        DispatchCenter: "ReckongPeo_Kalpa_D (Himachal Pradesh)",
        OriginCenter: "ReckongPeo_Kalpa_D (Himachal Pradesh)",
        FacilityCity: "Reckong Peo",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5166,
        Pin: 803212,
        DispatchCenter: "Bakhtiarpur_Raghopur_D (Bihar)",
        OriginCenter: "Bakhtiarpur_Raghopur_D (Bihar)",
        FacilityCity: "Bakhtiarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5167,
        Pin: 332024,
        DispatchCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        OriginCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5168,
        Pin: 176202,
        DispatchCenter: "Palion_MndiPthankotRd_DPP (Himachal Pradesh)",
        OriginCenter: "Palion_MndiPthankotRd_DPP (Himachal Pradesh)",
        FacilityCity: "Palion",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5169,
        Pin: 305624,
        DispatchCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        OriginCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        FacilityCity: "Bijainagar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5170,
        Pin: 124141,
        DispatchCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        OriginCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        FacilityCity: "Charkhi Dadri",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5171,
        Pin: 621115,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5172,
        Pin: 609104,
        DispatchCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        OriginCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        FacilityCity: "Sirkazhi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5173,
        Pin: 173220,
        DispatchCenter: "Parwanoo_Sector2_D (Himachal Pradesh)",
        OriginCenter: "Parwanoo_Sector2_D (Himachal Pradesh)",
        FacilityCity: "Parwanoo",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5174,
        Pin: 854204,
        DispatchCenter: "Bhawanipur_Thanachowk_D (Bihar)",
        OriginCenter: "Bhawanipur_Thanachowk_D (Bihar)",
        FacilityCity: "Bhawanipur Rajdham",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5175,
        Pin: 431134,
        DispatchCenter: "Phulambri_Oldcourt_DPP (Maharashtra)",
        OriginCenter: "Phulambri_Oldcourt_DPP (Maharashtra)",
        FacilityCity: "Phulambri",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5176,
        Pin: 110114,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5177,
        Pin: 442605,
        DispatchCenter: "Gadchiroli_Central_DPP_1 (Maharashtra)",
        OriginCenter: "Gadchiroli_Central_DPP_1 (Maharashtra)",
        FacilityCity: "Gadchiroli",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5178,
        Pin: 507140,
        DispatchCenter: "Bhadrachalam_Lakshminagaram_DPP (Telangana)",
        OriginCenter: "Bhadrachalam_Lakshminagaram_DPP (Telangana)",
        FacilityCity: "Bhadrachalam",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5179,
        Pin: 535534,
        DispatchCenter: "Kurupam_MainRd_DPP (Andhra Pradesh)",
        OriginCenter: "Kurupam_MainRd_DPP (Andhra Pradesh)",
        FacilityCity: "Kurupam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5180,
        Pin: 305403,
        DispatchCenter: "Kekri_LodhaChowk_DPP (Rajasthan)",
        OriginCenter: "Kekri_LodhaChowk_DPP (Rajasthan)",
        FacilityCity: "Kekri",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5181,
        Pin: 416506,
        DispatchCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        OriginCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        FacilityCity: "Gadhinglaj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5182,
        Pin: 591246,
        DispatchCenter: "Belgaum_Hukkeri_DPP (Karnataka)",
        OriginCenter: "Belgaum_Hukkeri_DPP (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5183,
        Pin: 581320,
        DispatchCenter: "Bhatkal_Sodigadde_D (Karnataka)",
        OriginCenter: "Bhatkal_Sodigadde_D (Karnataka)",
        FacilityCity: "Bhatkal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5184,
        Pin: 396191,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5185,
        Pin: 574164,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5186,
        Pin: 711120,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5187,
        Pin: 431117,
        DispatchCenter: "Soegaon_Shivajinagar_DPP (Maharashtra)",
        OriginCenter: "Soegaon_Shivajinagar_DPP (Maharashtra)",
        FacilityCity: "Soegaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5188,
        Pin: 753005,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5189,
        Pin: 811310,
        DispatchCenter: "Lakhisarai_Central_I_2 (Bihar)",
        OriginCenter: "Lakhisarai_Central_I_2 (Bihar)",
        FacilityCity: "Lakhisarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5190,
        Pin: 110060,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5191,
        Pin: 493225,
        DispatchCenter: "Tilda_Kharora_D (Chhattisgarh)",
        OriginCenter: "Tilda_Kharora_D (Chhattisgarh)",
        FacilityCity: "Tilda",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5192,
        Pin: 331022,
        DispatchCenter: "Sardarshahr_IndustrialArea_L (Rajasthan)",
        OriginCenter: "Sardarshahr_IndustrialArea_L (Rajasthan)",
        FacilityCity: "Sardarshahr",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5193,
        Pin: 756112,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5194,
        Pin: 683111,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5195,
        Pin: 803202,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5196,
        Pin: 486889,
        DispatchCenter: "Singrauli_Nehrunagar_D (Madhya Pradesh)",
        OriginCenter: "Singrauli_Nehrunagar_D (Madhya Pradesh)",
        FacilityCity: "Singrauli",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 5197,
        Pin: 752065,
        DispatchCenter: "Kalapathar_GoleiChhak_D (Orissa)",
        OriginCenter: "Kalapathar_GoleiChhak_D (Orissa)",
        FacilityCity: "Kalapathar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5198,
        Pin: 389350,
        DispatchCenter: "Halol_DhavalPark_D (Gujarat)",
        OriginCenter: "Halol_DhavalPark_D (Gujarat)",
        FacilityCity: "Halol",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5199,
        Pin: 583215,
        DispatchCenter: "Hospet_Vijayanagara_D (Karnataka)",
        OriginCenter: "Hospet_Vijayanagara_D (Karnataka)",
        FacilityCity: "Hospet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5200,
        Pin: 140405,
        DispatchCenter: "SirhindFatehgarh_DC (Punjab)",
        OriginCenter: "SirhindFatehgarh_DC (Punjab)",
        FacilityCity: "Sirhind-Fategarh",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5201,
        Pin: 680301,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5202,
        Pin: 639112,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5203,
        Pin: 504209,
        DispatchCenter: "Mancherial_Nuspur_I (Telangana)",
        OriginCenter: "Mancherial_Nuspur_I (Telangana)",
        FacilityCity: "Mancherial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5204,
        Pin: 395013,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5205,
        Pin: 834002,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5206,
        Pin: 522236,
        DispatchCenter: "Tadikonda_Central_D_1 (Andhra Pradesh)",
        OriginCenter: "Tadikonda_Central_D_1 (Andhra Pradesh)",
        FacilityCity: "Tadikonda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5207,
        Pin: 691521,
        DispatchCenter: "Kottarakkara_MythriNagar_D (Kerala)",
        OriginCenter: "Kottarakkara_MythriNagar_D (Kerala)",
        FacilityCity: "Kottarakkara",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5208,
        Pin: 606401,
        DispatchCenter: "Sankarapuram_Vadasiruvalur_D (Tamil Nadu)",
        OriginCenter: "Sankarapuram_Vadasiruvalur_D (Tamil Nadu)",
        FacilityCity: "Sankarapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5209,
        Pin: 822121,
        DispatchCenter: "Nagaruntari_Jangipur_DPP (Jharkhand)",
        OriginCenter: "Nagaruntari_Jangipur_DPP (Jharkhand)",
        FacilityCity: "Nagar Untari",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5210,
        Pin: 425111,
        DispatchCenter: "Jalgaon_Omnagar_D (Maharashtra)",
        OriginCenter: "Jalgaon_Omnagar_D (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5211,
        Pin: 176325,
        DispatchCenter: "Dalhousie_Banikhet_D (Himachal Pradesh)",
        OriginCenter: "Dalhousie_Banikhet_D (Himachal Pradesh)",
        FacilityCity: "Dalhousie",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5212,
        Pin: 685605,
        DispatchCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        OriginCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        FacilityCity: "Thodupuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5213,
        Pin: 470115,
        DispatchCenter: "Khurai_Ward11_DPP (Madhya Pradesh)",
        OriginCenter: "Khurai_Ward11_DPP (Madhya Pradesh)",
        FacilityCity: "Khurai",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5214,
        Pin: 284405,
        DispatchCenter: "Mahrauni_Kuwanghosi_D (Uttar Pradesh)",
        OriginCenter: "Mahrauni_Kuwanghosi_D (Uttar Pradesh)",
        FacilityCity: "Mahrauni",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5215,
        Pin: 585202,
        DispatchCenter: "Yadgir_Chiranjeevinagar_D (Karnataka)",
        OriginCenter: "Yadgir_Chiranjeevinagar_D (Karnataka)",
        FacilityCity: "Yadgir",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5216,
        Pin: 721503,
        DispatchCenter: "Jhargram_Gopiballavpur_DPP (West Bengal)",
        OriginCenter: "Jhargram_Gopiballavpur_DPP (West Bengal)",
        FacilityCity: "Jhargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5217,
        Pin: 364081,
        DispatchCenter: "Talaja_Shivajinagar_D (Gujarat)",
        OriginCenter: "Talaja_Shivajinagar_D (Gujarat)",
        FacilityCity: "Talaja",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5218,
        Pin: 560041,
        DispatchCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        OriginCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5219,
        Pin: 841241,
        DispatchCenter: "Siwan_Atarsua_D (Bihar)",
        OriginCenter: "Siwan_Atarsua_D (Bihar)",
        FacilityCity: "Siwan",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5220,
        Pin: 686637,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5221,
        Pin: 173211,
        DispatchCenter: "Solan_Deothi_I (Himachal Pradesh)",
        OriginCenter: "Solan_Deothi_I (Himachal Pradesh)",
        FacilityCity: "Solan",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5222,
        Pin: 623206,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5223,
        Pin: 785696,
        DispatchCenter: "Nazira_Amina_D (Assam)",
        OriginCenter: "Nazira_Amina_D (Assam)",
        FacilityCity: "Nazira",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5224,
        Pin: 390017,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5225,
        Pin: 322025,
        DispatchCenter: "Khandar_Kushalpur_D (Rajasthan)",
        OriginCenter: "Khandar_Kushalpur_D (Rajasthan)",
        FacilityCity: "Khandar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5226,
        Pin: 131409,
        DispatchCenter: "Sonipat_Adreshcolony_D (Haryana)",
        OriginCenter: "Sonipat_Adreshcolony_D (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5227,
        Pin: 754024,
        DispatchCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        OriginCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        FacilityCity: "Chandikhol",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5228,
        Pin: 440011,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Chikhali_L (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5229,
        Pin: 363631,
        DispatchCenter: "Morbi_MahavirNagar_D (Gujarat)",
        OriginCenter: "Morbi_MahavirNagar_D (Gujarat)",
        FacilityCity: "Morbi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5230,
        Pin: 734218,
        DispatchCenter: "Darjeeling_Mahendragaon_DPP (West Bengal)",
        OriginCenter: "Darjeeling_Mahendragaon_DPP (West Bengal)",
        FacilityCity: "Darjeeling",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5231,
        Pin: 175132,
        DispatchCenter: "Keylong_ManaliHwy_D (Himachal Pradesh)",
        OriginCenter: "Keylong_ManaliHwy_D (Himachal Pradesh)",
        FacilityCity: "Keylong",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5232,
        Pin: 472246,
        DispatchCenter: "Prithvipur_JeronRd_DPP (Madhya Pradesh)",
        OriginCenter: "Prithvipur_JeronRd_DPP (Madhya Pradesh)",
        FacilityCity: "Prithvipur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 5233,
        Pin: 743302,
        DispatchCenter: "Baruipur_Bnsibtla_D (West Bengal)",
        OriginCenter: "Baruipur_Bnsibtla_D (West Bengal)",
        FacilityCity: "Baruipur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5234,
        Pin: 451555,
        DispatchCenter: "Barwani_IGcolony_D (Madhya Pradesh)",
        OriginCenter: "Barwani_IGcolony_D (Madhya Pradesh)",
        FacilityCity: "Barwani",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5235,
        Pin: 416202,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5236,
        Pin: 606302,
        DispatchCenter: "Virudhachalam_Tamilnagar_D (Tamil Nadu)",
        OriginCenter: "Virudhachalam_Tamilnagar_D (Tamil Nadu)",
        FacilityCity: "Virudhachalam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5237,
        Pin: 416015,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5238,
        Pin: 679302,
        DispatchCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        OriginCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        FacilityCity: "Pazhayannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5239,
        Pin: 131403,
        DispatchCenter: "Sonipat_Sector51_L (Haryana)",
        OriginCenter: "Sonipat_WazidpurSaboli_P (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5240,
        Pin: 670692,
        DispatchCenter: "Thalassery_Panoor_D (Kerala)",
        OriginCenter: "Thalassery_Panoor_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5241,
        Pin: 680519,
        DispatchCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        OriginCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5242,
        Pin: 441102,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Chikhali_L (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5243,
        Pin: 283125,
        DispatchCenter: "Fatehabad_Rsulpr_D (Uttar Pradesh)",
        OriginCenter: "Fatehabad_Rsulpr_D (Uttar Pradesh)",
        FacilityCity: "Fatehabad-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5244,
        Pin: 277207,
        DispatchCenter: "Ballia_Sikandarpur_D (Uttar Pradesh)",
        OriginCenter: "Ballia_Sikandarpur_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5245,
        Pin: 673003,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5246,
        Pin: 133010,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5247,
        Pin: 413218,
        DispatchCenter: "Akkalkot_SmrathNgr_DPP (Maharashtra)",
        OriginCenter: "Akkalkot_SmrathNgr_DPP (Maharashtra)",
        FacilityCity: "Akkalkot",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5248,
        Pin: 389341,
        DispatchCenter: "Halol_DhavalPark_D (Gujarat)",
        OriginCenter: "Halol_DhavalPark_D (Gujarat)",
        FacilityCity: "Halol",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5249,
        Pin: 122204,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5250,
        Pin: 721654,
        DispatchCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        OriginCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        FacilityCity: "Tamluk",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5251,
        Pin: 272162,
        DispatchCenter: "Dhanghata_MarhaRaja_DPP (Uttar Pradesh)",
        OriginCenter: "Dhanghata_MarhaRaja_DPP (Uttar Pradesh)",
        FacilityCity: "Dhanghata",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5252,
        Pin: 229403,
        DispatchCenter: "Lalgopalganj_Anandi_D (Uttar Pradesh)",
        OriginCenter: "Lalgopalganj_Anandi_D (Uttar Pradesh)",
        FacilityCity: "Lal Gopalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5253,
        Pin: 246165,
        DispatchCenter: "Pauri_Khndyusn_D (Uttarakhand)",
        OriginCenter: "Pauri_Khndyusn_D (Uttarakhand)",
        FacilityCity: "Pauri",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5254,
        Pin: 825167,
        DispatchCenter: "Topchanchi_GomhRd_DPP (Jharkhand)",
        OriginCenter: "Topchanchi_GomhRd_DPP (Jharkhand)",
        FacilityCity: "Gomoh",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5255,
        Pin: 312603,
        DispatchCenter: "Mandphiya_BhadsodaRoad_D (Rajasthan)",
        OriginCenter: "Mandphiya_BhadsodaRoad_D (Rajasthan)",
        FacilityCity: "Mandphiya",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5256,
        Pin: 577102,
        DispatchCenter: "Chikmagalur_Jayanagara_D (Karnataka)",
        OriginCenter: "Chikmagalur_Jayanagara_D (Karnataka)",
        FacilityCity: "Chikkamagalur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5257,
        Pin: 574114,
        DispatchCenter: "Karkala_JodursteJ_D (Karnataka)",
        OriginCenter: "Karkala_JodursteJ_D (Karnataka)",
        FacilityCity: "Karkala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5258,
        Pin: 844113,
        DispatchCenter: "Lalganj_Kharauna_D (Bihar)",
        OriginCenter: "Lalganj_Kharauna_D (Bihar)",
        FacilityCity: "Lalganj-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5259,
        Pin: 678017,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5260,
        Pin: 743353,
        DispatchCenter: "Baruipur_Bnsibtla_D (West Bengal)",
        OriginCenter: "Baruipur_Bnsibtla_D (West Bengal)",
        FacilityCity: "Baruipur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5261,
        Pin: 686026,
        DispatchCenter: "Changanacherry_Perunna_D (Kerala)",
        OriginCenter: "Changanacherry_Perunna_D (Kerala)",
        FacilityCity: "Changanacherry",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5262,
        Pin: 466114,
        DispatchCenter: "Ashta_Saicolony_D (Madhya Pradesh)",
        OriginCenter: "Ashta_Saicolony_D (Madhya Pradesh)",
        FacilityCity: "Ashta",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5263,
        Pin: 306103,
        DispatchCenter: "Sojat_RendriCircle_DPP (Rajasthan)",
        OriginCenter: "Sojat_RendriCircle_DPP (Rajasthan)",
        FacilityCity: "Sojat",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5264,
        Pin: 210422,
        DispatchCenter: "Rath_Saina_D (Uttar Pradesh)",
        OriginCenter: "Rath_Saina_D (Uttar Pradesh)",
        FacilityCity: "Rath",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5265,
        Pin: 212654,
        DispatchCenter: "Fatehpur_Radhanagar_D (Uttar Pradesh)",
        OriginCenter: "Fatehpur_Radhanagar_D (Uttar Pradesh)",
        FacilityCity: "Fatehpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5266,
        Pin: 283201,
        DispatchCenter: "Firozabad_MahadeoNagar_D (Uttar Pradesh)",
        OriginCenter: "Firozabad_MahadeoNagar_D (Uttar Pradesh)",
        FacilityCity: "Firozabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5267,
        Pin: 813113,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5268,
        Pin: 600006,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5269,
        Pin: 605601,
        DispatchCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        OriginCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        FacilityCity: "Viluppuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5270,
        Pin: 572118,
        DispatchCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        OriginCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5271,
        Pin: 743203,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5272,
        Pin: 202132,
        DispatchCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        OriginCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        FacilityCity: "Khair",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5273,
        Pin: 758081,
        DispatchCenter: "Thakurmunda_Pandaposi_DPP (Orissa)",
        OriginCenter: "Thakurmunda_Pandaposi_DPP (Orissa)",
        FacilityCity: "Thakurmunda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5274,
        Pin: 584123,
        DispatchCenter: "Manvi_Jayanagar_D (Karnataka)",
        OriginCenter: "Manvi_Jayanagar_D (Karnataka)",
        FacilityCity: "Manvi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5275,
        Pin: 370007,
        DispatchCenter: "Bhuj_Madhapar_D (Gujarat)",
        OriginCenter: "Bhuj_Madhapar_D (Gujarat)",
        FacilityCity: "Bhuj",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5276,
        Pin: 506223,
        DispatchCenter: "Cherial_Vchreni_D (Telangana)",
        OriginCenter: "Cherial_Vchreni_D (Telangana)",
        FacilityCity: "Cherial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5277,
        Pin: 712203,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5278,
        Pin: 600013,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5279,
        Pin: 151004,
        DispatchCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        OriginCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        FacilityCity: "Bathinda",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5280,
        Pin: 627757,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5281,
        Pin: 532186,
        DispatchCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        OriginCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        FacilityCity: "Srikakulam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5282,
        Pin: 160006,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5283,
        Pin: 624006,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5284,
        Pin: 441811,
        DispatchCenter: "Sakoli_GaneshWard_D (Maharashtra)",
        OriginCenter: "Sakoli_GaneshWard_D (Maharashtra)",
        FacilityCity: "Sakoli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5285,
        Pin: 577231,
        DispatchCenter: "Davangere_Santhebennur_D (Karnataka)",
        OriginCenter: "Davangere_Santhebennur_D (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5286,
        Pin: 202421,
        DispatchCenter: "Sahaswan_Mainmarket_D (Uttar Pradesh)",
        OriginCenter: "Sahaswan_Mainmarket_D (Uttar Pradesh)",
        FacilityCity: "Sahaswan",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5287,
        Pin: 502102,
        DispatchCenter: "Siddipet_Repallewada_DPP (Telangana)",
        OriginCenter: "Siddipet_Repallewada_DPP (Telangana)",
        FacilityCity: "Siddipet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5288,
        Pin: 144523,
        DispatchCenter: "Garhshankar_BangaRoad_D (Punjab)",
        OriginCenter: "Garhshankar_BangaRoad_D (Punjab)",
        FacilityCity: "Garhshanker",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5289,
        Pin: 583111,
        DispatchCenter: "Bellary_Mundargi_I (Karnataka)",
        OriginCenter: "Bellary_Mundargi_I (Karnataka)",
        FacilityCity: "Bellary",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5290,
        Pin: 823000,
        DispatchCenter: "Gaya_Matihani_H (Bihar)",
        OriginCenter: "Gaya_Matihani_H (Bihar)",
        FacilityCity: "Gaya",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5291,
        Pin: 152115,
        DispatchCenter: "Malout_DC (Punjab)",
        OriginCenter: "Malout_DC (Punjab)",
        FacilityCity: "Malout",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5292,
        Pin: 534199,
        DispatchCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        FacilityCity: "Bhimavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5293,
        Pin: 845455,
        DispatchCenter: "Narkatiaganj_Ward19_D (Bihar)",
        OriginCenter: "Narkatiaganj_Ward19_D (Bihar)",
        FacilityCity: "Narkatiaganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5294,
        Pin: 505455,
        DispatchCenter: "Jagtial_TulsiNagar_D (Telangana)",
        OriginCenter: "Jagtial_TulsiNagar_D (Telangana)",
        FacilityCity: "Jagtial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5295,
        Pin: 177034,
        DispatchCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        OriginCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        FacilityCity: "Nadaun",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5296,
        Pin: 691309,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5297,
        Pin: 127040,
        DispatchCenter: "Siwani_Ward12_D (Haryana)",
        OriginCenter: "Siwani_Ward12_D (Haryana)",
        FacilityCity: "Siwani",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5298,
        Pin: 690109,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5299,
        Pin: 247122,
        DispatchCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        OriginCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        FacilityCity: "Saharanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5300,
        Pin: 110116,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5301,
        Pin: 742405,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5302,
        Pin: 635206,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5303,
        Pin: 302031,
        DispatchCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        OriginCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5304,
        Pin: 577139,
        DispatchCenter: "Sringeri_Kalmetlu_DPP (Karnataka)",
        OriginCenter: "Sringeri_Kalmetlu_DPP (Karnataka)",
        FacilityCity: "Sringeri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5305,
        Pin: 722155,
        DispatchCenter: "Bankura_KeranDPP_D (West Bengal)",
        OriginCenter: "Bankura_KeranDPP_D (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5306,
        Pin: 628702,
        DispatchCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        OriginCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        FacilityCity: "Thisayanvilai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5307,
        Pin: 581352,
        DispatchCenter: "Karwar_Sadashivgad_D (Karnataka)",
        OriginCenter: "Karwar_Sadashivgad_D (Karnataka)",
        FacilityCity: "Karwar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5308,
        Pin: 413512,
        DispatchCenter: "Latur_KhadgoanChowk_D (Maharashtra)",
        OriginCenter: "Latur_KhadgoanChowk_D (Maharashtra)",
        FacilityCity: "Latur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5309,
        Pin: 816120,
        DispatchCenter: "Barharwa_Berhait_D (Jharkhand)",
        OriginCenter: "Barharwa_Berhait_D (Jharkhand)",
        FacilityCity: "Barharwa",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5310,
        Pin: 770046,
        DispatchCenter: "Birmitrapur_Sailata_D (Orissa)",
        OriginCenter: "Birmitrapur_Sailata_D (Orissa)",
        FacilityCity: "Birmitrapur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5311,
        Pin: 394107,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5312,
        Pin: 612102,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5313,
        Pin: 451881,
        DispatchCenter: "Pansemal_Matrala_DPP (Madhya Pradesh)",
        OriginCenter: "Pansemal_Matrala_DPP (Madhya Pradesh)",
        FacilityCity: "Pansemal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 5314,
        Pin: 361336,
        DispatchCenter: "Dwarka_CharaklaRd_DPP (Gujarat)",
        OriginCenter: "Dwarka_CharaklaRd_DPP (Gujarat)",
        FacilityCity: "Dwarka",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5315,
        Pin: 533252,
        DispatchCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        OriginCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        FacilityCity: "Razole",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5316,
        Pin: 802129,
        DispatchCenter: "Malyabag_ArrahRd_D (Bihar)",
        OriginCenter: "Malyabag_ArrahRd_D (Bihar)",
        FacilityCity: "Malyabag",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5317,
        Pin: 192202,
        DispatchCenter: "Anantnag_Kokernag_DPP (Jammu & Kashmir)",
        OriginCenter: "Anantnag_Kokernag_DPP (Jammu & Kashmir)",
        FacilityCity: "Anantnag",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5318,
        Pin: 172028,
        DispatchCenter: "Nankhari_Blockoffice_DPP (Himachal Pradesh)",
        OriginCenter: "Nankhari_Blockoffice_DPP (Himachal Pradesh)",
        FacilityCity: "Nankhari",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5319,
        Pin: 753013,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5320,
        Pin: 790001,
        DispatchCenter: "Bomdila_Centrepoint_D (Arunachal Pradesh)",
        OriginCenter: "Bomdila_Centrepoint_D (Arunachal Pradesh)",
        FacilityCity: "Bomdila",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5321,
        Pin: 754207,
        DispatchCenter: "Cuttack_SliprDPP_DC (Orissa)",
        OriginCenter: "Cuttack_SliprDPP_DC (Orissa)",
        FacilityCity: "Salipur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5322,
        Pin: 632511,
        DispatchCenter: "Cheyyar_NehruNGR_D (Tamil Nadu)",
        OriginCenter: "Cheyyar_NehruNGR_D (Tamil Nadu)",
        FacilityCity: "Cheyyar",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5323,
        Pin: 678542,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5324,
        Pin: 822128,
        DispatchCenter: "Nagaruntari_Jangipur_DPP (Jharkhand)",
        OriginCenter: "Nagaruntari_Jangipur_DPP (Jharkhand)",
        FacilityCity: "Nagar Untari",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5325,
        Pin: 733207,
        DispatchCenter: "Islampure_Chopra_D (West Bengal)",
        OriginCenter: "Islampure_Chopra_D (West Bengal)",
        FacilityCity: "Islampure",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5326,
        Pin: 635814,
        DispatchCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ambur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5327,
        Pin: 400034,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5328,
        Pin: 281206,
        DispatchCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        OriginCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        FacilityCity: "Hathras",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5329,
        Pin: 382815,
        DispatchCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        OriginCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        FacilityCity: "Vijapur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5330,
        Pin: 713423,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5331,
        Pin: 845452,
        DispatchCenter: "Lauria_ChandanChowk_D (Bihar)",
        OriginCenter: "Lauria_ChandanChowk_D (Bihar)",
        FacilityCity: "Lauria Nandangarh",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5332,
        Pin: 577182,
        DispatchCenter: "Kadur_UBRoad_D (Karnataka)",
        OriginCenter: "Kadur_UBRoad_D (Karnataka)",
        FacilityCity: "Kadur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5333,
        Pin: 415634,
        DispatchCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        OriginCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        FacilityCity: "Shringartali",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5334,
        Pin: 679327,
        DispatchCenter: "Pandikkad_Thangalpadi_D (Kerala)",
        OriginCenter: "Pandikkad_Thangalpadi_D (Kerala)",
        FacilityCity: "Pandikkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5335,
        Pin: 442401,
        DispatchCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        OriginCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        FacilityCity: "Chandrapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5336,
        Pin: 411012,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5337,
        Pin: 680022,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5338,
        Pin: 201016,
        DispatchCenter: "Noida_KheraDharmpura_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5339,
        Pin: 532222,
        DispatchCenter: "Palasa_Mandapam_D (Andhra Pradesh)",
        OriginCenter: "Palasa_Mandapam_D (Andhra Pradesh)",
        FacilityCity: "Palasa",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5340,
        Pin: 686142,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5341,
        Pin: 144015,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5342,
        Pin: 530025,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5343,
        Pin: 518468,
        DispatchCenter: "Dhone_Autonagar_D (Andhra Pradesh)",
        OriginCenter: "Dhone_Autonagar_D (Andhra Pradesh)",
        FacilityCity: "Dhone",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5344,
        Pin: 497220,
        DispatchCenter: "Ramanujganj_Wadafnagarrd_D (Chhattisgarh)",
        OriginCenter: "Ramanujganj_Wadafnagarrd_D (Chhattisgarh)",
        FacilityCity: "Ramanujganj",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5345,
        Pin: 176318,
        DispatchCenter: "Chamba_Sultanpur_D (Himachal Pradesh)",
        OriginCenter: "Chamba_Sultanpur_D (Himachal Pradesh)",
        FacilityCity: "Chamba",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5346,
        Pin: 803211,
        DispatchCenter: "Bakhtiarpur_Raghopur_D (Bihar)",
        OriginCenter: "Bakhtiarpur_Raghopur_D (Bihar)",
        FacilityCity: "Bakhtiarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5347,
        Pin: 700088,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5348,
        Pin: 793120,
        DispatchCenter: "Mairang_Langstiehrim_D (Meghalaya)",
        OriginCenter: "Mairang_Langstiehrim_D (Meghalaya)",
        FacilityCity: "Mairang",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5349,
        Pin: 732138,
        DispatchCenter: "Malda_Kadubari_D (West Bengal)",
        OriginCenter: "Malda_Kadubari_D (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5350,
        Pin: 828203,
        DispatchCenter: "Chirkunda_Egarkunr_D (Jharkhand)",
        OriginCenter: "Chirkunda_Egarkunr_D (Jharkhand)",
        FacilityCity: "Chirkunda",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5351,
        Pin: 382435,
        DispatchCenter: "Ahmedabad_Kanera_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5352,
        Pin: 845414,
        DispatchCenter: "Chakia_Honda_D (Bihar)",
        OriginCenter: "Chakia_Honda_D (Bihar)",
        FacilityCity: "Chakia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5353,
        Pin: 274201,
        DispatchCenter: "Deoria_Ramnathnagar_D (Uttar Pradesh)",
        OriginCenter: "Deoria_Ramnathnagar_D (Uttar Pradesh)",
        FacilityCity: "Deoria",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5354,
        Pin: 637204,
        DispatchCenter: "Velur_ChakraNgr_DPP (Tamil Nadu)",
        OriginCenter: "Velur_ChakraNgr_DPP (Tamil Nadu)",
        FacilityCity: "Velur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5355,
        Pin: 700011,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5356,
        Pin: 673616,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5357,
        Pin: 387340,
        DispatchCenter: "Kapadvanj_Dholikui_D (Gujarat)",
        OriginCenter: "Kapadvanj_Dholikui_D (Gujarat)",
        FacilityCity: "Kapadvanj",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5358,
        Pin: 518573,
        DispatchCenter: "Sirvella_Sirivelmitta_DPP (Andhra Pradesh)",
        OriginCenter: "Sirvella_Sirivelmitta_DPP (Andhra Pradesh)",
        FacilityCity: "Sirvella",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5359,
        Pin: 583285,
        DispatchCenter: "Koppal_PragatiNagar_D (Karnataka)",
        OriginCenter: "Koppal_PragatiNagar_D (Karnataka)",
        FacilityCity: "Koppal",
        FacilityState: "Karnataka",
        Zone : "S1"
      },
      {
        SN: 5360,
        Pin: 768030,
        DispatchCenter: "Bargarh_Sukuda_D (Orissa)",
        OriginCenter: "Bargarh_Sukuda_D (Orissa)",
        FacilityCity: "Bargarh",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5361,
        Pin: 203208,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5362,
        Pin: 222129,
        DispatchCenter: "Kerakat_KaliMandirDPP_D (Uttar Pradesh)",
        OriginCenter: "Kerakat_KaliMandirDPP_D (Uttar Pradesh)",
        FacilityCity: "Kerakat",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5363,
        Pin: 424307,
        DispatchCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        OriginCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        FacilityCity: "Dhule",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5364,
        Pin: 174034,
        DispatchCenter: "Ghumarwin_Gndhichk_D (Himachal Pradesh)",
        OriginCenter: "Ghumarwin_Gndhichk_D (Himachal Pradesh)",
        FacilityCity: "Ghumarwin",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5365,
        Pin: 636014,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5366,
        Pin: 841238,
        DispatchCenter: "Maharajganj_Sihauta_D (Bihar)",
        OriginCenter: "Maharajganj_Sihauta_D (Bihar)",
        FacilityCity: "Maharajganj-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5367,
        Pin: 303511,
        DispatchCenter: "Lalsot_Ward34_D (Rajasthan)",
        OriginCenter: "Lalsot_Ward34_D (Rajasthan)",
        FacilityCity: "Lalsot",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5368,
        Pin: 680028,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5369,
        Pin: 215126,
        DispatchCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        OriginCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        FacilityCity: "Shikohabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5370,
        Pin: 605803,
        DispatchCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        OriginCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        FacilityCity: "Thirukoilure",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5371,
        Pin: 275102,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5372,
        Pin: 415806,
        DispatchCenter: "Ratnagiri_Mervi_DPP (Maharashtra)",
        OriginCenter: "Ratnagiri_Mervi_DPP (Maharashtra)",
        FacilityCity: "Ratnagiri",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5373,
        Pin: 571320,
        DispatchCenter: "Hanur_Maruthinagar_D (Karnataka)",
        OriginCenter: "Hanur_Maruthinagar_D (Karnataka)",
        FacilityCity: "Hanur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5374,
        Pin: 805123,
        DispatchCenter: "Nawada_Gondapur_D (Bihar)",
        OriginCenter: "Nawada_Gondapur_D (Bihar)",
        FacilityCity: "Nawada",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5375,
        Pin: 722202,
        DispatchCenter: "Barjora_PoliceStn_DPP (West Bengal)",
        OriginCenter: "Barjora_PoliceStn_DPP (West Bengal)",
        FacilityCity: "Barjora",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5376,
        Pin: 341027,
        DispatchCenter: "Nagaur_Kuchera_D (Rajasthan)",
        OriginCenter: "Nagaur_Kuchera_D (Rajasthan)",
        FacilityCity: "Nagaur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5377,
        Pin: 574105,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5378,
        Pin: 627851,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5379,
        Pin: 457779,
        DispatchCenter: "Jhabua_Mojipada_D (Madhya Pradesh)",
        OriginCenter: "Jhabua_Mojipada_D (Madhya Pradesh)",
        FacilityCity: "Jhabua",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5380,
        Pin: 713384,
        DispatchCenter: "Dubrajpur_Pandaveswar_D (West Bengal)",
        OriginCenter: "Dubrajpur_Pandaveswar_D (West Bengal)",
        FacilityCity: "Dubrajpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5381,
        Pin: 626501,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5382,
        Pin: 743330,
        DispatchCenter: "Baruipur_Bnsibtla_D (West Bengal)",
        OriginCenter: "Baruipur_Bnsibtla_D (West Bengal)",
        FacilityCity: "Baruipur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5383,
        Pin: 788099,
        DispatchCenter: "Sonabarighat_Saidpur_DPP (Assam)",
        OriginCenter: "Sonabarighat_Saidpur_DPP (Assam)",
        FacilityCity: "Sonabarighat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5384,
        Pin: 403801,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5385,
        Pin: 508244,
        DispatchCenter: "Chandur_ShivajiNagar_D (Telangana)",
        OriginCenter: "Chandur_ShivajiNagar_D (Telangana)",
        FacilityCity: "Chandur",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5386,
        Pin: 246473,
        DispatchCenter: "Chamoli_Karnaprayag_D (Uttarakhand)",
        OriginCenter: "Chamoli_Karnaprayag_D (Uttarakhand)",
        FacilityCity: "Chamoli",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5387,
        Pin: 691321,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5388,
        Pin: 741246,
        DispatchCenter: "Naihati_Kalyani_DPP (West Bengal)",
        OriginCenter: "Naihati_Kalyani_DPP (West Bengal)",
        FacilityCity: "Kalyani",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5389,
        Pin: 695012,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5390,
        Pin: 246194,
        DispatchCenter: "Pauri_Khndyusn_D (Uttarakhand)",
        OriginCenter: "Pauri_Khndyusn_D (Uttarakhand)",
        FacilityCity: "Pauri",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5391,
        Pin: 325007,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5392,
        Pin: 172024,
        DispatchCenter: "Kumarsain_Bharara_D (Himachal Pradesh)",
        OriginCenter: "Kumarsain_Bharara_D (Himachal Pradesh)",
        FacilityCity: "Kumarsain",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5393,
        Pin: 400078,
        DispatchCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        OriginCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        FacilityCity: "Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5394,
        Pin: 495447,
        DispatchCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        OriginCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        FacilityCity: "Korba",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5395,
        Pin: 517194,
        DispatchCenter: "Pileru_Chinnagottigallu_D (Andhra Pradesh)",
        OriginCenter: "Pileru_Chinnagottigallu_D (Andhra Pradesh)",
        FacilityCity: "Tirupati",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5396,
        Pin: 690531,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5397,
        Pin: 625602,
        DispatchCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        OriginCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        FacilityCity: "Vadipatti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5398,
        Pin: 560029,
        DispatchCenter: "Bangalore_Temp1_L (Karnataka)",
        OriginCenter: "Bangalore_Temp1_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5399,
        Pin: 283203,
        DispatchCenter: "Firozabad_MahadeoNagar_D (Uttar Pradesh)",
        OriginCenter: "Firozabad_MahadeoNagar_D (Uttar Pradesh)",
        FacilityCity: "Firozabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5400,
        Pin: 577539,
        DispatchCenter: "Channagiri_Holalkere_D (Karnataka)",
        OriginCenter: "Channagiri_Holalkere_D (Karnataka)",
        FacilityCity: "Channagiri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5401,
        Pin: 531055,
        DispatchCenter: "Yellamanchili_Chodapalle_D (Andhra Pradesh)",
        OriginCenter: "Yellamanchili_Chodapalle_D (Andhra Pradesh)",
        FacilityCity: "Yellamanchili",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5402,
        Pin: 577129,
        DispatchCenter: "Kadur_UBRoad_D (Karnataka)",
        OriginCenter: "Kadur_UBRoad_D (Karnataka)",
        FacilityCity: "Kadur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5403,
        Pin: 826001,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5404,
        Pin: 226301,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5405,
        Pin: 508376,
        DispatchCenter: "Suryapet_Krishnanagar_D (Telangana)",
        OriginCenter: "Suryapet_Krishnanagar_D (Telangana)",
        FacilityCity: "Suryapet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5406,
        Pin: 362571,
        DispatchCenter: "Una_StColony_D (Gujarat)",
        OriginCenter: "Una_StColony_D (Gujarat)",
        FacilityCity: "UNA",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5407,
        Pin: 787110,
        DispatchCenter: "Silapathar_Junakinagar_D (Assam)",
        OriginCenter: "Silapathar_Junakinagar_D (Assam)",
        FacilityCity: "Silapathar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5408,
        Pin: 766021,
        DispatchCenter: "Bhawanipatna_Dangaripadar_H (Orissa)",
        OriginCenter: "Bhawanipatna_Dangaripadar_H (Orissa)",
        FacilityCity: "Bhawanipatna",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5409,
        Pin: 501106,
        DispatchCenter: "Mominpet_Marpallekalan_D (Telangana)",
        OriginCenter: "Mominpet_Marpallekalan_D (Telangana)",
        FacilityCity: "Mominpet",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5410,
        Pin: 516356,
        DispatchCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        OriginCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        FacilityCity: "Proddatur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5411,
        Pin: 500108,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5412,
        Pin: 303338,
        DispatchCenter: "Phulera_NarenaRD_D (Rajasthan)",
        OriginCenter: "Phulera_NarenaRD_D (Rajasthan)",
        FacilityCity: "Phulera",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5413,
        Pin: 641671,
        DispatchCenter: "Palladam_Balajinagar_D (Tamil Nadu)",
        OriginCenter: "Palladam_Balajinagar_D (Tamil Nadu)",
        FacilityCity: "Palladam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5414,
        Pin: 517152,
        DispatchCenter: "Pakala_Gorpadu_D (Andhra Pradesh)",
        OriginCenter: "Pakala_Gorpadu_D (Andhra Pradesh)",
        FacilityCity: "Pakala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5415,
        Pin: 721647,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5416,
        Pin: 480880,
        DispatchCenter: "Katangi_Mattravihar_D (Madhya Pradesh)",
        OriginCenter: "Katangi_Mattravihar_D (Madhya Pradesh)",
        FacilityCity: "Katangi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 5417,
        Pin: 796005,
        DispatchCenter: "Aizawl_Ainawnveng_D (Mizoram)",
        OriginCenter: "Aizawl_Ainawnveng_D (Mizoram)",
        FacilityCity: "Aizawl",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5418,
        Pin: 332746,
        DispatchCenter: "Buhana_Rajota_D (Rajasthan)",
        OriginCenter: "Buhana_Rajota_D (Rajasthan)",
        FacilityCity: "Buhana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5419,
        Pin: 762101,
        DispatchCenter: "Balliguda_Simanbadi_DPP (Orissa)",
        OriginCenter: "Balliguda_Simanbadi_DPP (Orissa)",
        FacilityCity: "Balliguda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5420,
        Pin: 413704,
        DispatchCenter: "Rahuri_Mehetremala_D (Maharashtra)",
        OriginCenter: "Rahuri_Mehetremala_D (Maharashtra)",
        FacilityCity: "Rahuri",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5421,
        Pin: 431200,
        DispatchCenter: "Jalna_Yashodipnagar_D (Maharashtra)",
        OriginCenter: "Jalna_Yashodipnagar_D (Maharashtra)",
        FacilityCity: "Jalna",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5422,
        Pin: 413605,
        DispatchCenter: "Naldurg_RamliaNagar_D (Maharashtra)",
        OriginCenter: "Naldurg_RamliaNagar_D (Maharashtra)",
        FacilityCity: "Naldurg",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5423,
        Pin: 679339,
        DispatchCenter: "Edavanna_MNRd_D (Kerala)",
        OriginCenter: "Edavanna_MNRd_D (Kerala)",
        FacilityCity: "Edavanna",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5424,
        Pin: 247452,
        DispatchCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        OriginCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        FacilityCity: "Saharanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5425,
        Pin: 607104,
        DispatchCenter: "Panruti_Thiruvathigai_D (Tamil Nadu)",
        OriginCenter: "Panruti_Thiruvathigai_D (Tamil Nadu)",
        FacilityCity: "Panruti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5426,
        Pin: 361306,
        DispatchCenter: "Khambhalia_Dharampur_D (Gujarat)",
        OriginCenter: "Khambhalia_Dharampur_D (Gujarat)",
        FacilityCity: "Khambhalia",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5427,
        Pin: 610203,
        DispatchCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        FacilityCity: "Thiruthuraipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5428,
        Pin: 713126,
        DispatchCenter: "Guskhara_DignagarRd_D (West Bengal)",
        OriginCenter: "Guskhara_DignagarRd_D (West Bengal)",
        FacilityCity: "Guskhara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5429,
        Pin: 393125,
        DispatchCenter: "Dediapada_Kevdi_DPP (Gujarat)",
        OriginCenter: "Dediapada_Kevdi_DPP (Gujarat)",
        FacilityCity: "Dediapada",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5430,
        Pin: 202145,
        DispatchCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        OriginCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        FacilityCity: "Hathras",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5431,
        Pin: 754145,
        DispatchCenter: "Paradip_Udyabata_D (Orissa)",
        OriginCenter: "Paradip_Udyabata_D (Orissa)",
        FacilityCity: "Paradip",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5432,
        Pin: 781135,
        DispatchCenter: "Boko_Dhupdhara_D (Assam)",
        OriginCenter: "Boko_Dhupdhara_D (Assam)",
        FacilityCity: "Boko",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5433,
        Pin: 572112,
        DispatchCenter: "Madhugiri_SBI_DPP (Karnataka)",
        OriginCenter: "Madhugiri_SBI_DPP (Karnataka)",
        FacilityCity: "Madhugiri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5434,
        Pin: 822132,
        DispatchCenter: "Bisrampur_Rehla_D (Jharkhand)",
        OriginCenter: "Bisrampur_Rehla_D (Jharkhand)",
        FacilityCity: "Bisrampur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5435,
        Pin: 577536,
        DispatchCenter: "Challakere_Hosmane_D (Karnataka)",
        OriginCenter: "Challakere_Hosmane_D (Karnataka)",
        FacilityCity: "Challakere",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5436,
        Pin: 209731,
        DispatchCenter: "Chhibramau_TajpurRd_D (Uttar Pradesh)",
        OriginCenter: "Chhibramau_TajpurRd_D (Uttar Pradesh)",
        FacilityCity: "Chhibramau",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5437,
        Pin: 785705,
        DispatchCenter: "Golaghat_BaruaRd_D (Assam)",
        OriginCenter: "Golaghat_BaruaRd_D (Assam)",
        FacilityCity: "Golaghat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5438,
        Pin: 533306,
        DispatchCenter: "Draksharamam_Anaipeta_D (Andhra Pradesh)",
        OriginCenter: "Draksharamam_Anaipeta_D (Andhra Pradesh)",
        FacilityCity: "Draksharamam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5439,
        Pin: 768027,
        DispatchCenter: "Kadobahal_Dulampur_D (Orissa)",
        OriginCenter: "Kadobahal_Dulampur_D (Orissa)",
        FacilityCity: "Kadobahal",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5440,
        Pin: 560070,
        DispatchCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        OriginCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5441,
        Pin: 680771,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5442,
        Pin: 516309,
        DispatchCenter: "Cuddapah_Peddacheppalle_D (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Peddacheppalle_D (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5443,
        Pin: 577136,
        DispatchCenter: "Chikmagalur_Jayanagara_D (Karnataka)",
        OriginCenter: "Chikmagalur_Jayanagara_D (Karnataka)",
        FacilityCity: "Chikkamagalur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5444,
        Pin: 500104,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5445,
        Pin: 831010,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5446,
        Pin: 500991,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5447,
        Pin: 403505,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5448,
        Pin: 680571,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5449,
        Pin: 250502,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5450,
        Pin: 626008,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5451,
        Pin: 416215,
        DispatchCenter: "Bambavade_SarudRd_DPP (Maharashtra)",
        OriginCenter: "Bambavade_SarudRd_DPP (Maharashtra)",
        FacilityCity: "Bambavade",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5452,
        Pin: 522212,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5453,
        Pin: 524341,
        DispatchCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        OriginCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        FacilityCity: "Atmakur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5454,
        Pin: 221715,
        DispatchCenter: "Ballia_Sikandarpur_D (Uttar Pradesh)",
        OriginCenter: "Ballia_Sikandarpur_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5455,
        Pin: 670104,
        DispatchCenter: "Thalassery_TempleGt_D (Kerala)",
        OriginCenter: "Thalassery_TempleGt_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5456,
        Pin: 224141,
        DispatchCenter: "Faizabad_MayaBazar_D (Uttar Pradesh)",
        OriginCenter: "Faizabad_MayaBazar_D (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5457,
        Pin: 852109,
        DispatchCenter: "Simrahi_Bazar_D (Bihar)",
        OriginCenter: "Simrahi_Bazar_D (Bihar)",
        FacilityCity: "Simrahi Bazar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5458,
        Pin: 781350,
        DispatchCenter: "Hajo_Baruabari_D (Assam)",
        OriginCenter: "Hajo_Baruabari_D (Assam)",
        FacilityCity: "Hajo",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5459,
        Pin: 560099,
        DispatchCenter: "Bangalore_BmsdraPhase3_L (Karnataka)",
        OriginCenter: "Bangalore_BmsdraPhase3_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5460,
        Pin: 624303,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5461,
        Pin: 636453,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5462,
        Pin: 680587,
        DispatchCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        OriginCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        FacilityCity: "Pazhayannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5463,
        Pin: 415306,
        DispatchCenter: "Atapadi_VidyaNagar_D (Maharashtra)",
        OriginCenter: "Atapadi_VidyaNagar_D (Maharashtra)",
        FacilityCity: "Atapadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5464,
        Pin: 416009,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5465,
        Pin: 382211,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5466,
        Pin: 250105,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5467,
        Pin: 625706,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5468,
        Pin: 524410,
        DispatchCenter: "Gudur_MalvyaNagar_D (Andhra Pradesh)",
        OriginCenter: "Gudur_MalvyaNagar_D (Andhra Pradesh)",
        FacilityCity: "Gudur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5469,
        Pin: 671221,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5470,
        Pin: 327007,
        DispatchCenter: "Banswara_Baridaylan_D (Rajasthan)",
        OriginCenter: "Banswara_Baridaylan_D (Rajasthan)",
        FacilityCity: "Banswara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5471,
        Pin: 425119,
        DispatchCenter: "Jalgaon_Omnagar_D (Maharashtra)",
        OriginCenter: "Jalgaon_Omnagar_D (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5472,
        Pin: 673529,
        DispatchCenter: "Koyilandy_Kizhur_D (Kerala)",
        OriginCenter: "Koyilandy_Kizhur_D (Kerala)",
        FacilityCity: "Koyilandy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5473,
        Pin: 148033,
        DispatchCenter: "Patran_Kahangarh_D (Punjab)",
        OriginCenter: "Patran_Kahangarh_D (Punjab)",
        FacilityCity: "Patran",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5474,
        Pin: 502248,
        DispatchCenter: "Medak_Chegunta_D (Telangana)",
        OriginCenter: "Medak_Chegunta_D (Telangana)",
        FacilityCity: "Medak",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5475,
        Pin: 691587,
        DispatchCenter: "Kollam_Karunagappally_D (Kerala)",
        OriginCenter: "Kollam_Karunagappally_D (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5476,
        Pin: 205145,
        DispatchCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        OriginCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        FacilityCity: "Shikohabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5477,
        Pin: 713143,
        DispatchCenter: "Katwa_Panchghara_D (West Bengal)",
        OriginCenter: "Katwa_Panchghara_D (West Bengal)",
        FacilityCity: "Katwa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5478,
        Pin: 127110,
        DispatchCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        OriginCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        FacilityCity: "Charkhi Dadri",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5479,
        Pin: 770036,
        DispatchCenter: "Rourkela_KoelNagar_D (Orissa)",
        OriginCenter: "Rourkela_KoelNagar_D (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5480,
        Pin: 689692,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5481,
        Pin: 756041,
        DispatchCenter: "RajBerhampur_Gohira_DPP (Orissa)",
        OriginCenter: "RajBerhampur_Gohira_DPP (Orissa)",
        FacilityCity: "Raj Berhampur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5482,
        Pin: 788114,
        DispatchCenter: "Bhaga_Khashpur_D (Assam)",
        OriginCenter: "Bhaga_Khashpur_D (Assam)",
        FacilityCity: "Bhaga",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5483,
        Pin: 711112,
        DispatchCenter: "Howrah_Kamrangu_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5484,
        Pin: 361110,
        DispatchCenter: "Jamnagar_Chela_L (Gujarat)",
        OriginCenter: "Jamnagar_Chela_L (Gujarat)",
        FacilityCity: "Jamnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5485,
        Pin: 577431,
        DispatchCenter: "Shikaripur_Mandipet_D (Karnataka)",
        OriginCenter: "Shikaripur_Mandipet_D (Karnataka)",
        FacilityCity: "Shikaripur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5486,
        Pin: 531030,
        DispatchCenter: "Seetharampram_Lakkavarapukota_DPP (Andhra Pradesh)",
        OriginCenter: "Seetharampram_Lakkavarapukota_DPP (Andhra Pradesh)",
        FacilityCity: "Seetharampram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5487,
        Pin: 364320,
        DispatchCenter: "Sihor_InfinityMall_D (Gujarat)",
        OriginCenter: "Sihor_InfinityMall_D (Gujarat)",
        FacilityCity: "Sihor",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5488,
        Pin: 574122,
        DispatchCenter: "Karkala_JodursteJ_D (Karnataka)",
        OriginCenter: "Karkala_JodursteJ_D (Karnataka)",
        FacilityCity: "Karkala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5489,
        Pin: 274501,
        DispatchCenter: "Salempur_Banjaria_D (Uttar Pradesh)",
        OriginCenter: "Salempur_Banjaria_D (Uttar Pradesh)",
        FacilityCity: "Salempur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5490,
        Pin: 791101,
        DispatchCenter: "Basar_Oldmrkt_D (Arunachal Pradesh)",
        OriginCenter: "Basar_Oldmrkt_D (Arunachal Pradesh)",
        FacilityCity: "Basar",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5491,
        Pin: 224182,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5492,
        Pin: 695315,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        Zone : "S2"
      },
      {
        SN: 5493,
        Pin: 494004,
        DispatchCenter: "Jagdalpur_Hatkchora_D (Chhattisgarh)",
        OriginCenter: "Jagdalpur_Hatkchora_D (Chhattisgarh)",
        FacilityCity: "Jagdalpur",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5494,
        Pin: 680697,
        DispatchCenter: "Chalakudy_ForestChowk_D (Kerala)",
        OriginCenter: "Chalakudy_ForestChowk_D (Kerala)",
        FacilityCity: "Chalakudy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5495,
        Pin: 834005,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5496,
        Pin: 530015,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5497,
        Pin: 756023,
        DispatchCenter: "Basta_Darada_DPP (Orissa)",
        OriginCenter: "Basta_Darada_DPP (Orissa)",
        FacilityCity: "Basta",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5498,
        Pin: 783351,
        DispatchCenter: "Bilasipara_HnumnMndrRd_DPP (Assam)",
        OriginCenter: "Bilasipara_HnumnMndrRd_DPP (Assam)",
        FacilityCity: "Bilasipara",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5499,
        Pin: 262903,
        DispatchCenter: "Nighasan_Laudhri_D (Uttar Pradesh)",
        OriginCenter: "Nighasan_Laudhri_D (Uttar Pradesh)",
        FacilityCity: "Nighasan",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5500,
        Pin: 623502,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5501,
        Pin: 362565,
        DispatchCenter: "Una_StColony_D (Gujarat)",
        OriginCenter: "Una_StColony_D (Gujarat)",
        FacilityCity: "UNA",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5502,
        Pin: 761042,
        DispatchCenter: "Digapahandi_Punjikanya_D (Orissa)",
        OriginCenter: "Digapahandi_Punjikanya_D (Orissa)",
        FacilityCity: "Digapahandi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5503,
        Pin: 176048,
        DispatchCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        OriginCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        FacilityCity: "Jahu",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5504,
        Pin: 201012,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5505,
        Pin: 304005,
        DispatchCenter: "Tonk_Bairwa_D (Rajasthan)",
        OriginCenter: "Tonk_Bairwa_D (Rajasthan)",
        FacilityCity: "Tonk",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5506,
        Pin: 571213,
        DispatchCenter: "Gonikoppal_Patelnagar_D (Karnataka)",
        OriginCenter: "Gonikoppal_Patelnagar_D (Karnataka)",
        FacilityCity: "Gonikoppal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5507,
        Pin: 785686,
        DispatchCenter: "Nazira_Amina_D (Assam)",
        OriginCenter: "Nazira_Amina_D (Assam)",
        FacilityCity: "Nazira",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5508,
        Pin: 385535,
        DispatchCenter: "Deesa_CrystalCplx_D (Gujarat)",
        OriginCenter: "Deesa_CrystalCplx_D (Gujarat)",
        FacilityCity: "Deesa",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5509,
        Pin: 533239,
        DispatchCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        OriginCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        FacilityCity: "Amalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5510,
        Pin: 639005,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5511,
        Pin: 208003,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5512,
        Pin: 152113,
        DispatchCenter: "Gidderbaha_GuruNanakNagar_D (Punjab)",
        OriginCenter: "Gidderbaha_GuruNanakNagar_D (Punjab)",
        FacilityCity: "Giddarbaha",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5513,
        Pin: 752011,
        DispatchCenter: "Puri_Kathuaredi_DPP (Orissa)",
        OriginCenter: "Puri_Kathuaredi_DPP (Orissa)",
        FacilityCity: "Puri",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5514,
        Pin: 743249,
        DispatchCenter: "Joypul_Maniktala_D (West Bengal)",
        OriginCenter: "Joypul_Maniktala_D (West Bengal)",
        FacilityCity: "Joypul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5515,
        Pin: 224122,
        DispatchCenter: "Akbarpur_AmbedkarNgr_D (Uttar Pradesh)",
        OriginCenter: "Akbarpur_AmbedkarNgr_D (Uttar Pradesh)",
        FacilityCity: "Akbarpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5516,
        Pin: 382442,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5517,
        Pin: 628601,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5518,
        Pin: 389390,
        DispatchCenter: "Bodeli_SatyamPlaza_DPP (Gujarat)",
        OriginCenter: "Bodeli_SatyamPlaza_DPP (Gujarat)",
        FacilityCity: "Bodeli",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5519,
        Pin: 261208,
        DispatchCenter: "Laharpur_TehsilHQ_D (Uttar Pradesh)",
        OriginCenter: "Laharpur_TehsilHQ_D (Uttar Pradesh)",
        FacilityCity: "Laharpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5520,
        Pin: 584139,
        DispatchCenter: "Lingasugur_Shivnagara_D (Karnataka)",
        OriginCenter: "Lingasugur_Shivnagara_D (Karnataka)",
        FacilityCity: "Lingasugur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5521,
        Pin: 695544,
        DispatchCenter: "Nedumangad_Central_D (Kerala)",
        OriginCenter: "Nedumangad_Central_D (Kerala)",
        FacilityCity: "Nedumangad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5522,
        Pin: 250104,
        DispatchCenter: "Meerut_Shipravihar_D (Uttar Pradesh)",
        OriginCenter: "Meerut_Shipravihar_D (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5523,
        Pin: 477441,
        DispatchCenter: "Lahar_Beejasen_D (Madhya Pradesh)",
        OriginCenter: "Lahar_Beejasen_D (Madhya Pradesh)",
        FacilityCity: "Lahar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 5524,
        Pin: 331024,
        DispatchCenter: "Fatehpur-RJ_Sekhisar_DPP (Rajasthan)",
        OriginCenter: "Fatehpur-RJ_Sekhisar_DPP (Rajasthan)",
        FacilityCity: "Fatehpur-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5525,
        Pin: 144601,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5526,
        Pin: 795004,
        DispatchCenter: "Imphal_Takyel_I (Manipur)",
        OriginCenter: "Imphal_Takyel_I (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5527,
        Pin: 392012,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5528,
        Pin: 305415,
        DispatchCenter: "Kekri_LodhaChowk_DPP (Rajasthan)",
        OriginCenter: "Kekri_LodhaChowk_DPP (Rajasthan)",
        FacilityCity: "Kekri",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5529,
        Pin: 835218,
        DispatchCenter: "Mahuadanr_Dipatoli_D (Jharkhand)",
        OriginCenter: "Mahuadanr_Dipatoli_D (Jharkhand)",
        FacilityCity: "Mahuadanr",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5530,
        Pin: 416812,
        DispatchCenter: "Oras_Ranbambuli_D (Maharashtra)",
        OriginCenter: "Oras_Ranbambuli_D (Maharashtra)",
        FacilityCity: "Oras",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5531,
        Pin: 133005,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5532,
        Pin: 423302,
        DispatchCenter: "Satana_MitraNagar_D (Maharashtra)",
        OriginCenter: "Satana_MitraNagar_D (Maharashtra)",
        FacilityCity: "Satana",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5533,
        Pin: 854116,
        DispatchCenter: "Mahadeopur_NH131a_DPP (Bihar)",
        OriginCenter: "Mahadeopur_NH131a_DPP (Bihar)",
        FacilityCity: "Gauripur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5534,
        Pin: 493449,
        DispatchCenter: "Mahasamund_Bagbahra_DPP (Chhattisgarh)",
        OriginCenter: "Mahasamund_Bagbahra_DPP (Chhattisgarh)",
        FacilityCity: "Mahasamund",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5535,
        Pin: 637018,
        DispatchCenter: "Rasipurm_Knrpati_D (Tamil Nadu)",
        OriginCenter: "Rasipurm_Knrpati_D (Tamil Nadu)",
        FacilityCity: "Rasipuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5536,
        Pin: 503122,
        DispatchCenter: "Yellareddy_Teachercolony_D (Telangana)",
        OriginCenter: "Yellareddy_Teachercolony_D (Telangana)",
        FacilityCity: "Yellareddy",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5537,
        Pin: 621715,
        DispatchCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        OriginCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        FacilityCity: "Ariyalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5538,
        Pin: 781301,
        DispatchCenter: "Barpeta_Bhera_D (Assam)",
        OriginCenter: "Barpeta_ShaktiNagar_C (Assam)",
        FacilityCity: "Barpeta",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5539,
        Pin: 591214,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5540,
        Pin: 304505,
        DispatchCenter: "Malpura_Todaraisingh_DPP (Rajasthan)",
        OriginCenter: "Malpura_Todaraisingh_DPP (Rajasthan)",
        FacilityCity: "Malpura",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5541,
        Pin: 824202,
        DispatchCenter: "AurangabadBR_Katharua_D (Bihar)",
        OriginCenter: "AurangabadBR_Katharua_D (Bihar)",
        FacilityCity: "Aurangabad-BR",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5542,
        Pin: 636703,
        DispatchCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        OriginCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        FacilityCity: "Dharmapuri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5543,
        Pin: 713202,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5544,
        Pin: 152022,
        DispatchCenter: "Jalalabad_Gandhinagar_D (Punjab)",
        OriginCenter: "Jalalabad_Gandhinagar_D (Punjab)",
        FacilityCity: "Jalalabad",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5545,
        Pin: 110604,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5546,
        Pin: 841301,
        DispatchCenter: "Chhapra_Ramnagar_I (Bihar)",
        OriginCenter: "Chhapra_Ramnagar_I (Bihar)",
        FacilityCity: "Chhapra",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5547,
        Pin: 502273,
        DispatchCenter: "Jogipet_Andole_D (Telangana)",
        OriginCenter: "Jogipet_Andole_D (Telangana)",
        FacilityCity: "Jogipet",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5548,
        Pin: 721633,
        DispatchCenter: "Bajkul_Kismat_D (West Bengal)",
        OriginCenter: "Bajkul_Kismat_D (West Bengal)",
        FacilityCity: "Bajkul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5549,
        Pin: 211005,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5550,
        Pin: 845428,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5551,
        Pin: 425508,
        DispatchCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        OriginCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        FacilityCity: "Raver",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5552,
        Pin: 533407,
        DispatchCenter: "Tuni_Ramakrishnacolony_D (Andhra Pradesh)",
        OriginCenter: "Tuni_Ramakrishnacolony_D (Andhra Pradesh)",
        FacilityCity: "Tuni",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5553,
        Pin: 444601,
        DispatchCenter: "Amravati_Changapur_D (Maharashtra)",
        OriginCenter: "Amravati_Changapur_D (Maharashtra)",
        FacilityCity: "Amravati",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5554,
        Pin: 321603,
        DispatchCenter: "Bharatpur_DurgaNagar_D (Rajasthan)",
        OriginCenter: "Bharatpur_DurgaNagar_D (Rajasthan)",
        FacilityCity: "Bharatpur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5555,
        Pin: 576127,
        DispatchCenter: "Mudarangady_Santhoor_DPP (Karnataka)",
        OriginCenter: "Mudarangady_Santhoor_DPP (Karnataka)",
        FacilityCity: "Mudarangady",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5556,
        Pin: 637412,
        DispatchCenter: "Rasipurm_Knrpati_D (Tamil Nadu)",
        OriginCenter: "Rasipurm_Knrpati_D (Tamil Nadu)",
        FacilityCity: "Rasipuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5557,
        Pin: 584167,
        DispatchCenter: "Siruguppa_Yamaha_D (Karnataka)",
        OriginCenter: "Siruguppa_Yamaha_D (Karnataka)",
        FacilityCity: "Siruguppa",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5558,
        Pin: 247551,
        DispatchCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        OriginCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        FacilityCity: "Saharanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5559,
        Pin: 501502,
        DispatchCenter: "Vikarabad_Pargi1_D (Telangana)",
        OriginCenter: "Vikarabad_Pargi1_D (Telangana)",
        FacilityCity: "Vikarabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5560,
        Pin: 190014,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5561,
        Pin: 623401,
        DispatchCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        OriginCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        FacilityCity: "Thiruvadanai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5562,
        Pin: 770021,
        DispatchCenter: "Sundargarh_Duduka_D (Orissa)",
        OriginCenter: "Sundargarh_Duduka_D (Orissa)",
        FacilityCity: "Sundargarh",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5563,
        Pin: 504309,
        DispatchCenter: "Jainad_Dehegaon_DPP (Telangana)",
        OriginCenter: "Jainad_Dehegaon_DPP (Telangana)",
        FacilityCity: "Jainad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5564,
        Pin: 517112,
        DispatchCenter: "Pakala_Gorpadu_D (Andhra Pradesh)",
        OriginCenter: "Pakala_Gorpadu_D (Andhra Pradesh)",
        FacilityCity: "Pakala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5565,
        Pin: 413008,
        DispatchCenter: "Solapur_Balepeth_I (Maharashtra)",
        OriginCenter: "Solapur_Balepeth_I (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5566,
        Pin: 581202,
        DispatchCenter: "Haveri_ManjunathNagar_D (Karnataka)",
        OriginCenter: "Haveri_ManjunathNagar_D (Karnataka)",
        FacilityCity: "Haveri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5567,
        Pin: 431136,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5568,
        Pin: 228131,
        DispatchCenter: "Dostpur_Bhabbaltola_D (Uttar Pradesh)",
        OriginCenter: "Dostpur_Bhabbaltola_D (Uttar Pradesh)",
        FacilityCity: "Dostpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5569,
        Pin: 766104,
        DispatchCenter: "Nuapada_NilshlaNgr_D (Orissa)",
        OriginCenter: "Nuapada_NilshlaNgr_D (Orissa)",
        FacilityCity: "Nuapada",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5570,
        Pin: 143119,
        DispatchCenter: "Tarsikka_SaidoLehal_D (Punjab)",
        OriginCenter: "Tarsikka_SaidoLehal_D (Punjab)",
        FacilityCity: "Tarsikka",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5571,
        Pin: 770014,
        DispatchCenter: "Subdega_Gaibira_D (Orissa)",
        OriginCenter: "Subdega_Gaibira_D (Orissa)",
        FacilityCity: "Subdega",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5572,
        Pin: 326516,
        DispatchCenter: "Chaumahla_RapaKhra_DPP (Rajasthan)",
        OriginCenter: "Chaumahla_RapaKhra_DPP (Rajasthan)",
        FacilityCity: "Chaumahla",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5573,
        Pin: 586125,
        DispatchCenter: "Bijapur_Bableshwar_D (Karnataka)",
        OriginCenter: "Bijapur_Bableshwar_D (Karnataka)",
        FacilityCity: "Bijapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5574,
        Pin: 787029,
        DispatchCenter: "Gogamukh_Rupahi_D (Assam)",
        OriginCenter: "Gogamukh_Rupahi_D (Assam)",
        FacilityCity: "Gogamukh",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5575,
        Pin: 141203,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5576,
        Pin: 411015,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5577,
        Pin: 249126,
        DispatchCenter: "SrinagarUK_Bagwan_D (Uttarakhand)",
        OriginCenter: "SrinagarUK_Bagwan_D (Uttarakhand)",
        FacilityCity: "Srinagar-UK",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5578,
        Pin: 788706,
        DispatchCenter: "Karimganj_Sarisa_D (Assam)",
        OriginCenter: "Karimganj_Sarisa_D (Assam)",
        FacilityCity: "Karimganj",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5579,
        Pin: 713371,
        DispatchCenter: "Asansol_Gobindapur_D (West Bengal)",
        OriginCenter: "Asansol_Gobindapur_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5580,
        Pin: 125121,
        DispatchCenter: "Barwala_Madhubancolony_D (Haryana)",
        OriginCenter: "Barwala_Madhubancolony_D (Haryana)",
        FacilityCity: "Barwala",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5581,
        Pin: 641064,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5582,
        Pin: 614629,
        DispatchCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        OriginCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        FacilityCity: "Thiruvadanai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5583,
        Pin: 412308,
        DispatchCenter: "Pune_Fursungi_L (Maharashtra)",
        OriginCenter: "Pune_Fursungi_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5584,
        Pin: 521228,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5585,
        Pin: 635652,
        DispatchCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        OriginCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        FacilityCity: "Tiruppattur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5586,
        Pin: 742175,
        DispatchCenter: "Nowda_Amtala_DPP (West Bengal)",
        OriginCenter: "Nowda_Amtala_DPP (West Bengal)",
        FacilityCity: "Nowda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5587,
        Pin: 522647,
        DispatchCenter: "Vinukonda_Thimmayapalem_D (Andhra Pradesh)",
        OriginCenter: "Vinukonda_Thimmayapalem_D (Andhra Pradesh)",
        FacilityCity: "Vinukonda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5588,
        Pin: 585403,
        DispatchCenter: "Bidar_AdarshClny_D (Karnataka)",
        OriginCenter: "Bidar_AdarshClny_D (Karnataka)",
        FacilityCity: "Bidar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5589,
        Pin: 742164,
        DispatchCenter: "Murshidabad_Jiaganj_D (West Bengal)",
        OriginCenter: "Murshidabad_Jiaganj_D (West Bengal)",
        FacilityCity: "Murshidabad",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5590,
        Pin: 613053,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5591,
        Pin: 400111,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5592,
        Pin: 431510,
        DispatchCenter: "Mantha_Jintur_DPP (Maharashtra)",
        OriginCenter: "Mantha_Jintur_DPP (Maharashtra)",
        FacilityCity: "Mantha",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5593,
        Pin: 641010,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5594,
        Pin: 605103,
        DispatchCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        OriginCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        FacilityCity: "Viluppuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5595,
        Pin: 225301,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5596,
        Pin: 723131,
        DispatchCenter: "Manbazar_Central_DPP_1 (West Bengal)",
        OriginCenter: "Manbazar_Central_DPP_1 (West Bengal)",
        FacilityCity: "Manbazar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5597,
        Pin: 757047,
        DispatchCenter: "Jashipur_Chndposi_D (Orissa)",
        OriginCenter: "Jashipur_Chndposi_D (Orissa)",
        FacilityCity: "Jashipur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5598,
        Pin: 530044,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5599,
        Pin: 445402,
        DispatchCenter: "Wadki_Nandurkar_DPP (Maharashtra)",
        OriginCenter: "Wadki_Nandurkar_DPP (Maharashtra)",
        FacilityCity: "Wadki",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5600,
        Pin: 209738,
        DispatchCenter: "Kannauj_Tirwaganj_D (Uttar Pradesh)",
        OriginCenter: "Kannauj_Tirwaganj_D (Uttar Pradesh)",
        FacilityCity: "Kannauj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5601,
        Pin: 401100,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5602,
        Pin: 734002,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5603,
        Pin: 441909,
        DispatchCenter: "Bhandara_Mohadi_DPP (Maharashtra)",
        OriginCenter: "Bhandara_Mohadi_DPP (Maharashtra)",
        FacilityCity: "Bhandara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5604,
        Pin: 132123,
        DispatchCenter: "Karnal_Kutail_H (Haryana)",
        OriginCenter: "Karnal_Kutail_H (Haryana)",
        FacilityCity: "Karnal",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5605,
        Pin: 176211,
        DispatchCenter: "Nurpur_Sadwan_DPP (Himachal Pradesh)",
        OriginCenter: "Nurpur_Sadwan_DPP (Himachal Pradesh)",
        FacilityCity: "Nurpur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5606,
        Pin: 495690,
        DispatchCenter: "Jaijaipur_BaradwarMarg_D (Chhattisgarh)",
        OriginCenter: "Jaijaipur_BaradwarMarg_D (Chhattisgarh)",
        FacilityCity: "Jaijaipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5607,
        Pin: 614602,
        DispatchCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        OriginCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        FacilityCity: "Pattukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5608,
        Pin: 143538,
        DispatchCenter: "Pathankot_Kahanpur_H (Punjab)",
        OriginCenter: "Pathankot_Kahanpur_H (Punjab)",
        FacilityCity: "Pathankot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5609,
        Pin: 757026,
        DispatchCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        OriginCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        FacilityCity: "Betnoti",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5610,
        Pin: 508211,
        DispatchCenter: "Nalgonda_Duppalapally_I (Telangana)",
        OriginCenter: "Nalgonda_Duppalapally_I (Telangana)",
        FacilityCity: "Nalgonda",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5611,
        Pin: 454001,
        DispatchCenter: "Dhar_TirupatiNagar_D (Madhya Pradesh)",
        OriginCenter: "Dhar_TirupatiNagar_D (Madhya Pradesh)",
        FacilityCity: "Dhar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5612,
        Pin: 243001,
        DispatchCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        OriginCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        FacilityCity: "Bareilly",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5613,
        Pin: 671323,
        DispatchCenter: "Manjeshwar_Morathana_D (Kerala)",
        OriginCenter: "Manjeshwar_Morathana_D (Kerala)",
        FacilityCity: "Manjeshwar",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5614,
        Pin: 410502,
        DispatchCenter: 37043.00011574074,
        OriginCenter: 37043.00011574074,
        FacilityCity: "Junnar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5615,
        Pin: 854306,
        DispatchCenter: "Purnia_Fatehpur_D (Bihar)",
        OriginCenter: "Purnia_Fatehpur_D (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5616,
        Pin: 262550,
        DispatchCenter: "Didihat_PantGaon_DPP (Uttarakhand)",
        OriginCenter: "Didihat_PantGaon_DPP (Uttarakhand)",
        FacilityCity: "Didihat",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5617,
        Pin: 518508,
        DispatchCenter: "Velgodu_TVSShwrm_D (Andhra Pradesh)",
        OriginCenter: "Velgodu_TVSShwrm_D (Andhra Pradesh)",
        FacilityCity: "Velgodu",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5618,
        Pin: 413217,
        DispatchCenter: "Akkalkot_SmrathNgr_DPP (Maharashtra)",
        OriginCenter: "Akkalkot_SmrathNgr_DPP (Maharashtra)",
        FacilityCity: "Akkalkot",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5619,
        Pin: 731238,
        DispatchCenter: "Murarai_Birbhumi_D (West Bengal)",
        OriginCenter: "Murarai_Birbhumi_D (West Bengal)",
        FacilityCity: "Murarai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5620,
        Pin: 207505,
        DispatchCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        OriginCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        FacilityCity: "Aliganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5621,
        Pin: 759102,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5622,
        Pin: 743202,
        DispatchCenter: "Barrackpore_Lalkuti_D (West Bengal)",
        OriginCenter: "Barrackpore_Lalkuti_D (West Bengal)",
        FacilityCity: "Barrackpore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5623,
        Pin: 274801,
        DispatchCenter: "Khadda_Siswa_DPP (Uttar Pradesh)",
        OriginCenter: "Khadda_Siswa_DPP (Uttar Pradesh)",
        FacilityCity: "Khadda",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5624,
        Pin: 494336,
        DispatchCenter: "Charama_Nakapara_D (Chhattisgarh)",
        OriginCenter: "Charama_Nakapara_D (Chhattisgarh)",
        FacilityCity: "Charama",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5625,
        Pin: 301404,
        DispatchCenter: "Khairthal_HarsouliRoad_D (Rajasthan)",
        OriginCenter: "Khairthal_HarsouliRoad_D (Rajasthan)",
        FacilityCity: "Khairthal",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5626,
        Pin: 641113,
        DispatchCenter: "Mettupalayam_Belladhi_D (Tamil Nadu)",
        OriginCenter: "Mettupalayam_Belladhi_D (Tamil Nadu)",
        FacilityCity: "Mettupalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5627,
        Pin: 175131,
        DispatchCenter: "Manali_Prini_D (Himachal Pradesh)",
        OriginCenter: "Manali_Prini_D (Himachal Pradesh)",
        FacilityCity: "Manali",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5628,
        Pin: 125060,
        DispatchCenter: "Sirsa_Housingboardcol_D (Haryana)",
        OriginCenter: "Sirsa_Housingboardcol_D (Haryana)",
        FacilityCity: "Sirsa",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5629,
        Pin: 765021,
        DispatchCenter: "Muniguda_Ambodola_DPP (Orissa)",
        OriginCenter: "Muniguda_Ambodola_DPP (Orissa)",
        FacilityCity: "Muniguda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5630,
        Pin: 570019,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5631,
        Pin: 110057,
        DispatchCenter: "Delhi_BamnoliVillage_L (Delhi)",
        OriginCenter: "Delhi_BamnoliVillage_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5632,
        Pin: 391151,
        DispatchCenter: "Nasvadi_KNRd_DPP (Gujarat)",
        OriginCenter: "Nasvadi_KNRd_DPP (Gujarat)",
        FacilityCity: "Nasvadi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5633,
        Pin: 493118,
        DispatchCenter: "Bhatapara_Nayaganj_D (Chhattisgarh)",
        OriginCenter: "Bhatapara_Nayaganj_D (Chhattisgarh)",
        FacilityCity: "Bhatapara",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5634,
        Pin: 722177,
        DispatchCenter: "Bankura_Chhatna_DPP (West Bengal)",
        OriginCenter: "Bankura_Chhatna_DPP (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5635,
        Pin: 473249,
        DispatchCenter: "Guna_Shahpur_H (Madhya Pradesh)",
        OriginCenter: "Guna_Shahpur_H (Madhya Pradesh)",
        FacilityCity: "Guna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5636,
        Pin: 212631,
        DispatchCenter: "HamirpurUP_YamunaBdg_D (Uttar Pradesh)",
        OriginCenter: "HamirpurUP_YamunaBdg_D (Uttar Pradesh)",
        FacilityCity: "Hamirpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5637,
        Pin: 829135,
        DispatchCenter: "Patratu_Saundabasti_D (Jharkhand)",
        OriginCenter: "Patratu_Saundabasti_D (Jharkhand)",
        FacilityCity: "Patratu",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5638,
        Pin: 733215,
        DispatchCenter: "Dalkhola_Bhusamani_D (West Bengal)",
        OriginCenter: "Dalkhola_Bhusamani_D (West Bengal)",
        FacilityCity: "Dalkhola",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5639,
        Pin: 751015,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5640,
        Pin: 781033,
        DispatchCenter: "Guwahati_Kaikchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5641,
        Pin: 384310,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5642,
        Pin: 208025,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5643,
        Pin: 761007,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5644,
        Pin: 673515,
        DispatchCenter: "Kuttiady_ValayannoorMasjid_D (Kerala)",
        OriginCenter: "Kuttiady_ValayannoorMasjid_D (Kerala)",
        FacilityCity: "Kuttiady",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5645,
        Pin: 627651,
        DispatchCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        OriginCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        FacilityCity: "Thisayanvilai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5646,
        Pin: 486004,
        DispatchCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        OriginCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        FacilityCity: "Rewa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5647,
        Pin: 380011,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5648,
        Pin: 682028,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5649,
        Pin: 670310,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5650,
        Pin: 341028,
        DispatchCenter: "Khimsar_Hanumancolony_D (Rajasthan)",
        OriginCenter: "Khimsar_Hanumancolony_D (Rajasthan)",
        FacilityCity: "Khimsar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5651,
        Pin: 636402,
        DispatchCenter: "Mettur_Samballi_D (Tamil Nadu)",
        OriginCenter: "Mettur_Samballi_D (Tamil Nadu)",
        FacilityCity: "Mettur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5652,
        Pin: 713162,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5653,
        Pin: 629304,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5654,
        Pin: 425126,
        DispatchCenter: "Jalgaon_MIDC_I (Maharashtra)",
        OriginCenter: "Jalgaon_MIDC_I (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5655,
        Pin: 524343,
        DispatchCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        OriginCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        FacilityCity: "Atmakur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5656,
        Pin: 400079,
        DispatchCenter: "Mumbai_Powai_L (Maharashtra)",
        OriginCenter: "Mumbai_Powai_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5657,
        Pin: 305923,
        DispatchCenter: "Beawar_DC (Rajasthan)",
        OriginCenter: "Beawar_DC (Rajasthan)",
        FacilityCity: "Beawar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5658,
        Pin: 247777,
        DispatchCenter: "Shamli_SalekChandVihar_D (Uttar Pradesh)",
        OriginCenter: "Shamli_SalekChandVihar_D (Uttar Pradesh)",
        FacilityCity: "Shamli",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5659,
        Pin: 631101,
        DispatchCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5660,
        Pin: 518155,
        DispatchCenter: "Allagadda_Ramalayam_DPP (Andhra Pradesh)",
        OriginCenter: "Allagadda_Ramalayam_DPP (Andhra Pradesh)",
        FacilityCity: "Allagadda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5661,
        Pin: 534456,
        DispatchCenter: "Jangareddygudem_RTC_D (Andhra Pradesh)",
        OriginCenter: "Jangareddygudem_RTC_D (Andhra Pradesh)",
        FacilityCity: "Jangareddygudem",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5662,
        Pin: 638051,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5663,
        Pin: 500028,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5664,
        Pin: 712401,
        DispatchCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        OriginCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        FacilityCity: "Tarkeshwar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5665,
        Pin: 415012,
        DispatchCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        OriginCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        FacilityCity: "Satara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5666,
        Pin: 673121,
        DispatchCenter: "Kalpetta_Mandayapuram_D (Kerala)",
        OriginCenter: "Kalpetta_Mandayapuram_D (Kerala)",
        FacilityCity: "Kalpetta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5667,
        Pin: 146114,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5668,
        Pin: 144506,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5669,
        Pin: 573117,
        DispatchCenter: "Arsikere_JenukalTemple_D (Karnataka)",
        OriginCenter: "Arsikere_JenukalTemple_D (Karnataka)",
        FacilityCity: "Arsikere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5670,
        Pin: 416125,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5671,
        Pin: 584203,
        DispatchCenter: "Manvi_Jayanagar_D (Karnataka)",
        OriginCenter: "Manvi_Jayanagar_D (Karnataka)",
        FacilityCity: "Manvi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5672,
        Pin: 484444,
        DispatchCenter: "Kotma_BaniyaTola_D (Madhya Pradesh)",
        OriginCenter: "Kotma_BaniyaTola_D (Madhya Pradesh)",
        FacilityCity: "Kotma",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5673,
        Pin: 621805,
        DispatchCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        OriginCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        FacilityCity: "Jayankondam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5674,
        Pin: 226014,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5675,
        Pin: 689104,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5676,
        Pin: 631051,
        DispatchCenter: "Arakkonam_Ranipet_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ranipet_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5677,
        Pin: 148002,
        DispatchCenter: "Sangrur_DC (Punjab)",
        OriginCenter: "Sangrur_DC (Punjab)",
        FacilityCity: "Sangrur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5678,
        Pin: 560023,
        DispatchCenter: "Bangalore_FCILayout_L (Karnataka)",
        OriginCenter: "Bangalore_FCILayout_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5679,
        Pin: 480994,
        DispatchCenter: "Mandla_Nainpur_D (Madhya Pradesh)",
        OriginCenter: "Mandla_Nainpur_D (Madhya Pradesh)",
        FacilityCity: "Mandla",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 5680,
        Pin: 676506,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5681,
        Pin: 403112,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5682,
        Pin: 431507,
        DispatchCenter: "Majalgaon_SmbjiCwk_D (Maharashtra)",
        OriginCenter: "Majalgaon_SmbjiCwk_D (Maharashtra)",
        FacilityCity: "Manjlegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5683,
        Pin: 743507,
        DispatchCenter: "Ghatakpukur_Nalmuri_DPP (West Bengal)",
        OriginCenter: "Ghatakpukur_Nalmuri_DPP (West Bengal)",
        FacilityCity: "Ghatakpukur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5684,
        Pin: 623103,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5685,
        Pin: 416712,
        DispatchCenter: "Lanja_GondeSakhalRd_D (Maharashtra)",
        OriginCenter: "Lanja_GondeSakhalRd_D (Maharashtra)",
        FacilityCity: "Lanja",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5686,
        Pin: 755008,
        DispatchCenter: "Chandipur_Keshpur_D (Orissa)",
        OriginCenter: "Chandipur_Keshpur_D (Orissa)",
        FacilityCity: "Chandipur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5687,
        Pin: 533220,
        DispatchCenter: "Yanam_DraksharamaRd_D (Andhra Pradesh)",
        OriginCenter: "Yanam_DraksharamaRd_D (Andhra Pradesh)",
        FacilityCity: "Draksharamam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5688,
        Pin: 690550,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5689,
        Pin: 262201,
        DispatchCenter: "Bisalpur_Patelnagar_D (Uttar Pradesh)",
        OriginCenter: "Bisalpur_Patelnagar_D (Uttar Pradesh)",
        FacilityCity: "Bisalpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5690,
        Pin: 641019,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5691,
        Pin: 502251,
        DispatchCenter: "Zahirabad_Jharasangam_D (Telangana)",
        OriginCenter: "Zahirabad_Jharasangam_D (Telangana)",
        FacilityCity: "Zahirabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5692,
        Pin: 784504,
        DispatchCenter: "Thelamara_NilachalPath_D (Assam)",
        OriginCenter: "Thelamara_NilachalPath_D (Assam)",
        FacilityCity: "Thelamara",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5693,
        Pin: 431513,
        DispatchCenter: "Hingoli_Tapdiyanagar_D (Maharashtra)",
        OriginCenter: "Hingoli_Tapdiyanagar_D (Maharashtra)",
        FacilityCity: "Hingoli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5694,
        Pin: 785003,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5695,
        Pin: 581401,
        DispatchCenter: "Sirsi_Kasturbanagar_D (Karnataka)",
        OriginCenter: "Sirsi_Kasturbanagar_D (Karnataka)",
        FacilityCity: "Sirsi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5696,
        Pin: 263639,
        DispatchCenter: "Someshwar_Lakhnari_DPP (Uttarakhand)",
        OriginCenter: "Someshwar_Lakhnari_DPP (Uttarakhand)",
        FacilityCity: "Someshwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5697,
        Pin: 222125,
        DispatchCenter: "Badlapur_Barauli_D (Uttar Pradesh)",
        OriginCenter: "Badlapur_Barauli_D (Uttar Pradesh)",
        FacilityCity: "Badlapur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5698,
        Pin: 444301,
        DispatchCenter: "Amdapur_Haralkhed_DPP (Maharashtra)",
        OriginCenter: "Amdapur_Haralkhed_DPP (Maharashtra)",
        FacilityCity: "Amdapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5699,
        Pin: 394510,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5700,
        Pin: 530043,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5701,
        Pin: 147004,
        DispatchCenter: "Patiala_UpkarNagar_D (Punjab)",
        OriginCenter: "Patiala_UpkarNagar_D (Punjab)",
        FacilityCity: "Patiala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5702,
        Pin: 533295,
        DispatchCenter: "Rampachodavaram_Horeb_DPP (Andhra Pradesh)",
        OriginCenter: "Rampachodavaram_Horeb_DPP (Andhra Pradesh)",
        FacilityCity: "Rampachodavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5703,
        Pin: 854340,
        DispatchCenter: "Birpur_Basantpur_D (Bihar)",
        OriginCenter: "Birpur_Basantpur_D (Bihar)",
        FacilityCity: "Birpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5704,
        Pin: 816107,
        DispatchCenter: "Pakur_DurgaColny_D (Jharkhand)",
        OriginCenter: "Pakur_DurgaColny_D (Jharkhand)",
        FacilityCity: "Pakur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5705,
        Pin: 322230,
        DispatchCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        OriginCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5706,
        Pin: 804429,
        DispatchCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        OriginCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        FacilityCity: "Jehanabad",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5707,
        Pin: 629167,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5708,
        Pin: 845424,
        DispatchCenter: "Sahebganj_Goti_D (Bihar)",
        OriginCenter: "Sahebganj_Goti_D (Bihar)",
        FacilityCity: "Sahebganj-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5709,
        Pin: 341510,
        DispatchCenter: "Merta_Kalru_DPP (Rajasthan)",
        OriginCenter: "Merta_Kalru_DPP (Rajasthan)",
        FacilityCity: "Merta",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5710,
        Pin: 822110,
        DispatchCenter: "Daltonganj_SouthRedma_D (Jharkhand)",
        OriginCenter: "Daltonganj_SouthRedma_D (Jharkhand)",
        FacilityCity: "Daltonganj",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5711,
        Pin: 683565,
        DispatchCenter: "Kunnathunad_Pallikkara_D (Kerala)",
        OriginCenter: "Kunnathunad_Pallikkara_D (Kerala)",
        FacilityCity: "Kunnathunad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5712,
        Pin: 395017,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5713,
        Pin: 600101,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5714,
        Pin: 363622,
        DispatchCenter: "Wankaner_Railwaycolony_D (Gujarat)",
        OriginCenter: "Wankaner_Railwaycolony_D (Gujarat)",
        FacilityCity: "Wankaner",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5715,
        Pin: 171212,
        DispatchCenter: "Theog_Kaleenda_DPP (Himachal Pradesh)",
        OriginCenter: "Theog_Kaleenda_DPP (Himachal Pradesh)",
        FacilityCity: "Theog",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5716,
        Pin: 431145,
        DispatchCenter: "Majalgaon_SmbjiCwk_D (Maharashtra)",
        OriginCenter: "Majalgaon_SmbjiCwk_D (Maharashtra)",
        FacilityCity: "Manjlegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5717,
        Pin: 768205,
        DispatchCenter: "Jharsuguda_Ekatali_D (Orissa)",
        OriginCenter: "Jharsuguda_Ekatali_D (Orissa)",
        FacilityCity: "Jharsuguda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5718,
        Pin: 626125,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5719,
        Pin: 673513,
        DispatchCenter: "Kuttiady_ValayannoorMasjid_D (Kerala)",
        OriginCenter: "Kuttiady_ValayannoorMasjid_D (Kerala)",
        FacilityCity: "Kuttiady",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5720,
        Pin: 854203,
        DispatchCenter: "Bhawanipur_Barhari_D (Bihar)",
        OriginCenter: "Bhawanipur_Barhari_D (Bihar)",
        FacilityCity: "Bhawanipur Rajdham",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5721,
        Pin: 415511,
        DispatchCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        OriginCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        FacilityCity: "Satara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5722,
        Pin: 781334,
        DispatchCenter: "Nalbari_Chokbazar_D (Assam)",
        OriginCenter: "Nalbari_Chokbazar_D (Assam)",
        FacilityCity: "Nalbari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5723,
        Pin: 140123,
        DispatchCenter: "AnandpurSahib_Dher_D (Punjab)",
        OriginCenter: "AnandpurSahib_Dher_D (Punjab)",
        FacilityCity: "Anandpur Sahib",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5724,
        Pin: 276405,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5725,
        Pin: 455111,
        DispatchCenter: "Indore_BarodaArjun_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5726,
        Pin: 414011,
        DispatchCenter: "Shrigonda_Adhalgaon_D (Maharashtra)",
        OriginCenter: "Shrigonda_Adhalgaon_D (Maharashtra)",
        FacilityCity: "Shrigonda",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5727,
        Pin: 233222,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5728,
        Pin: 713361,
        DispatchCenter: "Asansol_Gobindapur_D (West Bengal)",
        OriginCenter: "Asansol_Gobindapur_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5729,
        Pin: 673308,
        DispatchCenter: "Mahe_Chokli_D (Kerala)",
        OriginCenter: "Mahe_Chokli_D (Kerala)",
        FacilityCity: "Mahe",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5730,
        Pin: 523264,
        DispatchCenter: "Chimakurthy_Siddavari_D (Andhra Pradesh)",
        OriginCenter: "Chimakurthy_Siddavari_D (Andhra Pradesh)",
        FacilityCity: "Chimakurthy",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5731,
        Pin: 835103,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5732,
        Pin: 752079,
        DispatchCenter: "Nayagarh_Durgprsd_D (Orissa)",
        OriginCenter: "Nayagarh_Durgprsd_D (Orissa)",
        FacilityCity: "Nayagarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5733,
        Pin: 204212,
        DispatchCenter: "SikandraRao_RadhaNagar_D (Uttar Pradesh)",
        OriginCenter: "SikandraRao_RadhaNagar_D (Uttar Pradesh)",
        FacilityCity: "Sikandra Rao",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5734,
        Pin: 754231,
        DispatchCenter: "Aul_Rasulpur_D (Orissa)",
        OriginCenter: "Aul_Rasulpur_D (Orissa)",
        FacilityCity: "Aul",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5735,
        Pin: 522124,
        DispatchCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        OriginCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5736,
        Pin: 679511,
        DispatchCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        OriginCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        FacilityCity: "Pazhayannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5737,
        Pin: 151212,
        DispatchCenter: "Faridkot_JatinderChwk_D (Punjab)",
        OriginCenter: "Faridkot_JatinderChwk_D (Punjab)",
        FacilityCity: "Faridkot",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5738,
        Pin: 581153,
        DispatchCenter: "Haveri_ManjunathNagar_D (Karnataka)",
        OriginCenter: "Haveri_ManjunathNagar_D (Karnataka)",
        FacilityCity: "Haveri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5739,
        Pin: 380024,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5740,
        Pin: 713335,
        DispatchCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        OriginCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        FacilityCity: "Rupnarayanpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5741,
        Pin: 326513,
        DispatchCenter: "BhwniMndi_RmngrDPP_D (Rajasthan)",
        OriginCenter: "BhwniMndi_RmngrDPP_D (Rajasthan)",
        FacilityCity: "Bhawani Mandi",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5742,
        Pin: 572217,
        DispatchCenter: "Tiptur_Chmundeshwri_D (Karnataka)",
        OriginCenter: "Tiptur_Chmundeshwri_D (Karnataka)",
        FacilityCity: "Tiptur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5743,
        Pin: 382415,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5744,
        Pin: 606908,
        DispatchCenter: "Polur_Kanji_D (Tamil Nadu)",
        OriginCenter: "Polur_Kanji_D (Tamil Nadu)",
        FacilityCity: "Polur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5745,
        Pin: 757031,
        DispatchCenter: "Bangriposi_Majigan_D (Orissa)",
        OriginCenter: "Bangriposi_Majigan_D (Orissa)",
        FacilityCity: "Bangriposi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5746,
        Pin: 517591,
        DispatchCenter: "PutturAP_Pichatur_D (Andhra Pradesh)",
        OriginCenter: "PutturAP_Pichatur_D (Andhra Pradesh)",
        FacilityCity: "Puttur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5747,
        Pin: 136033,
        DispatchCenter: "Kaithal_DefenceColony_D (Haryana)",
        OriginCenter: "Kaithal_DefenceColony_D (Haryana)",
        FacilityCity: "Kaithal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5748,
        Pin: 209748,
        DispatchCenter: "Kannauj_Tirwaganj_D (Uttar Pradesh)",
        OriginCenter: "Kannauj_Tirwaganj_D (Uttar Pradesh)",
        FacilityCity: "Kannauj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5749,
        Pin: 575024,
        DispatchCenter: "Ullal_Kallapu_D (Karnataka)",
        OriginCenter: "Ullal_Kallapu_D (Karnataka)",
        FacilityCity: "Ullal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5750,
        Pin: 185202,
        DispatchCenter: "Sunderbani_Kalakote_DPP (Jammu & Kashmir)",
        OriginCenter: "Sunderbani_Kalakote_DPP (Jammu & Kashmir)",
        FacilityCity: "Sunderbani",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5751,
        Pin: 362268,
        DispatchCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        OriginCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        FacilityCity: "Veraval",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5752,
        Pin: 641006,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5753,
        Pin: 614701,
        DispatchCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        OriginCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        FacilityCity: "Pattukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5754,
        Pin: 844507,
        DispatchCenter: "Hazratjandaha_Hirpur_D (Bihar)",
        OriginCenter: "Hazratjandaha_Hirpur_D (Bihar)",
        FacilityCity: "Hazrat Jandaha",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5755,
        Pin: 756119,
        DispatchCenter: "Mangalpur_GayatriColony_D (Orissa)",
        OriginCenter: "Mangalpur_GayatriColony_D (Orissa)",
        FacilityCity: "Mangalpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5756,
        Pin: 682022,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5757,
        Pin: 335901,
        DispatchCenter: "Ganganagar_Ricco_D (Rajasthan)",
        OriginCenter: "Ganganagar_Ricco_D (Rajasthan)",
        FacilityCity: "Ganga Nagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5758,
        Pin: 325222,
        DispatchCenter: "Baran_Housingboard_D (Rajasthan)",
        OriginCenter: "Baran_Housingboard_D (Rajasthan)",
        FacilityCity: "Baran",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5759,
        Pin: 389325,
        DispatchCenter: "Halol_DhavalPark_D (Gujarat)",
        OriginCenter: "Halol_DhavalPark_D (Gujarat)",
        FacilityCity: "Halol",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5760,
        Pin: 632009,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5761,
        Pin: 146108,
        DispatchCenter: "Garhshankar_BangaRoad_D (Punjab)",
        OriginCenter: "Garhshankar_BangaRoad_D (Punjab)",
        FacilityCity: "Garhshanker",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5762,
        Pin: 591339,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5763,
        Pin: 151302,
        DispatchCenter: "TalwandiSabo_GillanWala_D (Punjab)",
        OriginCenter: "TalwandiSabo_GillanWala_D (Punjab)",
        FacilityCity: "Talwandi Sabo",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5764,
        Pin: 801105,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5765,
        Pin: 211013,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5766,
        Pin: 313334,
        DispatchCenter: "Rajsamand_Riicoarea_D (Rajasthan)",
        OriginCenter: "Rajsamand_Riicoarea_D (Rajasthan)",
        FacilityCity: "Rajsamand",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5767,
        Pin: 635754,
        DispatchCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ambur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5768,
        Pin: 263127,
        DispatchCenter: "Nainital_Malital_D (Uttarakhand)",
        OriginCenter: "Nainital_Malital_D (Uttarakhand)",
        FacilityCity: "Nainital",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5769,
        Pin: 502267,
        DispatchCenter: "Siddipet_Vemula_D (Telangana)",
        OriginCenter: "Siddipet_Vemula_D (Telangana)",
        FacilityCity: "Siddipet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5770,
        Pin: 212665,
        DispatchCenter: "AmbujNagar_ShvrjprRd_D (Uttar Pradesh)",
        OriginCenter: "AmbujNagar_ShvrjprRd_D (Uttar Pradesh)",
        FacilityCity: "Ambuj Nagar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5771,
        Pin: 396375,
        DispatchCenter: "Valsad_LimbaChwk_D (Gujarat)",
        OriginCenter: "Valsad_LimbaChwk_D (Gujarat)",
        FacilityCity: "Valsad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5772,
        Pin: 402307,
        DispatchCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        OriginCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        FacilityCity: "Mahad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5773,
        Pin: 387370,
        DispatchCenter: "Nadiad_Shantinagar_D (Gujarat)",
        OriginCenter: "Nadiad_Shantinagar_D (Gujarat)",
        FacilityCity: "Nadiad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5774,
        Pin: 203121,
        DispatchCenter: "Bulndshahr_Ymunpurm_D (Uttar Pradesh)",
        OriginCenter: "Bulndshahr_Ymunpurm_D (Uttar Pradesh)",
        FacilityCity: "Bulandshahr",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5775,
        Pin: 635123,
        DispatchCenter: "Uthangarai_Mathurpathi_D (Tamil Nadu)",
        OriginCenter: "Uthangarai_Mathurpathi_D (Tamil Nadu)",
        FacilityCity: "Uthangarai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5776,
        Pin: 534210,
        DispatchCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        FacilityCity: "Bhimavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5777,
        Pin: 221302,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5778,
        Pin: 483331,
        DispatchCenter: "Raipura_Bharwara_D (Madhya Pradesh)",
        OriginCenter: "Raipura_Bharwara_D (Madhya Pradesh)",
        FacilityCity: "Raipura",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 5779,
        Pin: 583287,
        DispatchCenter: "Koppal_PragatiNagar_D (Karnataka)",
        OriginCenter: "Koppal_PragatiNagar_D (Karnataka)",
        FacilityCity: "Koppal",
        FacilityState: "Karnataka",
        Zone : "S1"
      },
      {
        SN: 5780,
        Pin: 341502,
        DispatchCenter: "Makrana_Mathabhar_D (Rajasthan)",
        OriginCenter: "Makrana_Mathabhar_D (Rajasthan)",
        FacilityCity: "Makrana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5781,
        Pin: 676528,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5782,
        Pin: 285001,
        DispatchCenter: "Orai_Ajnari_D (Uttar Pradesh)",
        OriginCenter: "Orai_Ajnari_D (Uttar Pradesh)",
        FacilityCity: "Orai",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5783,
        Pin: 384410,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5784,
        Pin: 721212,
        DispatchCenter: "Ghatal_KrshDPP_D (West Bengal)",
        OriginCenter: "Ghatal_KrshDPP_D (West Bengal)",
        FacilityCity: "Ghatal",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5785,
        Pin: 532243,
        DispatchCenter: "Palasa_Mandapam_D (Andhra Pradesh)",
        OriginCenter: "Palasa_Mandapam_D (Andhra Pradesh)",
        FacilityCity: "Palasa",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5786,
        Pin: 833215,
        DispatchCenter: "Chaibasa_NewClnyTungri_D (Jharkhand)",
        OriginCenter: "Chaibasa_NewClnyTungri_D (Jharkhand)",
        FacilityCity: "Chaibasa",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5787,
        Pin: 274601,
        DispatchCenter: "Barhaj_Nandnaward_D (Uttar Pradesh)",
        OriginCenter: "Barhaj_Nandnaward_D (Uttar Pradesh)",
        FacilityCity: "Barhaj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5788,
        Pin: 678559,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5789,
        Pin: 522016,
        DispatchCenter: "Tadikonda_Central_D_1 (Andhra Pradesh)",
        OriginCenter: "Tadikonda_Central_D_1 (Andhra Pradesh)",
        FacilityCity: "Tadikonda",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5790,
        Pin: 192401,
        DispatchCenter: "Anantnag_Bijbehara_D (Jammu & Kashmir)",
        OriginCenter: "Anantnag_Bijbehara_D (Jammu & Kashmir)",
        FacilityCity: "Anantnag",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5791,
        Pin: 581314,
        DispatchCenter: "Ankola_Baleguli_D (Karnataka)",
        OriginCenter: "Ankola_Baleguli_D (Karnataka)",
        FacilityCity: "Ankola",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5792,
        Pin: 752038,
        DispatchCenter: "Gopalapur_BusStop_DPP (Orissa)",
        OriginCenter: "Gopalapur_BusStop_DPP (Orissa)",
        FacilityCity: "Gopalapur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5793,
        Pin: 751025,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5794,
        Pin: 627352,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5795,
        Pin: 689111,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5796,
        Pin: 403706,
        DispatchCenter: "Sanvordem_PntmlDPP_D (Goa)",
        OriginCenter: "Sanvordem_PntmlDPP_D (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5797,
        Pin: 454111,
        DispatchCenter: "Sardarpur_Rajgarh_DPP (Madhya Pradesh)",
        OriginCenter: "Sardarpur_Rajgarh_DPP (Madhya Pradesh)",
        FacilityCity: "Sardarpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 5798,
        Pin: 700111,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5799,
        Pin: 743233,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5800,
        Pin: 145021,
        DispatchCenter: "Pathankot_Dunera_DPP (Punjab)",
        OriginCenter: "Pathankot_Dunera_DPP (Punjab)",
        FacilityCity: "Pathankot",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5801,
        Pin: 275205,
        DispatchCenter: "Dullahpur_Bhurkura_D (Uttar Pradesh)",
        OriginCenter: "Dullahpur_Bhurkura_D (Uttar Pradesh)",
        FacilityCity: "Dullahpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5802,
        Pin: 627006,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5803,
        Pin: 521149,
        DispatchCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        OriginCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        FacilityCity: "Machilipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5804,
        Pin: 261121,
        DispatchCenter: "Maholi_Urdauli_D (Uttar Pradesh)",
        OriginCenter: "Maholi_Urdauli_D (Uttar Pradesh)",
        FacilityCity: "Maholi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5805,
        Pin: 734001,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5806,
        Pin: 735213,
        DispatchCenter: "Birpara_BirparaClg_DPP (West Bengal)",
        OriginCenter: "Birpara_BirparaClg_DPP (West Bengal)",
        FacilityCity: "Birpara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5807,
        Pin: 142034,
        DispatchCenter: "Raikot_DC (Punjab)",
        OriginCenter: "Raikot_DC (Punjab)",
        FacilityCity: "Raikot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5808,
        Pin: 136035,
        DispatchCenter: "Pehowa_Cheeka_D (Haryana)",
        OriginCenter: "Pehowa_Cheeka_D (Haryana)",
        FacilityCity: "Pehowa",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5809,
        Pin: 630201,
        DispatchCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5810,
        Pin: 641028,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5811,
        Pin: 584125,
        DispatchCenter: "Lingasugur_Mudgal_D (Karnataka)",
        OriginCenter: "Lingasugur_Mudgal_D (Karnataka)",
        FacilityCity: "Lingasugur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5812,
        Pin: 805133,
        DispatchCenter: "Rajgir_Naipokhar_D (Bihar)",
        OriginCenter: "Rajgir_Naipokhar_D (Bihar)",
        FacilityCity: "Rajgir",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5813,
        Pin: 762023,
        DispatchCenter: "Puranakatak_Jajpur_DPP (Orissa)",
        OriginCenter: "Puranakatak_Jajpur_DPP (Orissa)",
        FacilityCity: "Puranakatak",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5814,
        Pin: 685507,
        DispatchCenter: "Kattappana_EratayarRoad_D (Kerala)",
        OriginCenter: "Kattappana_EratayarRoad_D (Kerala)",
        FacilityCity: "Kattappana",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5815,
        Pin: 757103,
        DispatchCenter: "Baripada_KalikDPP_D (Orissa)",
        OriginCenter: "Baripada_KalikDPP_D (Orissa)",
        FacilityCity: "Baripada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5816,
        Pin: 575003,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5817,
        Pin: 759131,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5818,
        Pin: 321606,
        DispatchCenter: "Kherli_Doroli_D (Rajasthan)",
        OriginCenter: "Kherli_Doroli_D (Rajasthan)",
        FacilityCity: "Kherli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5819,
        Pin: 144418,
        DispatchCenter: "Phagwara_PurewalNgr_D (Punjab)",
        OriginCenter: "Phagwara_PurewalNgr_D (Punjab)",
        FacilityCity: "Phagwara",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5820,
        Pin: 271605,
        DispatchCenter: "Balrampur_Utraula_D (Uttar Pradesh)",
        OriginCenter: "Balrampur_Utraula_D (Uttar Pradesh)",
        FacilityCity: "Balrampur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5821,
        Pin: 638109,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5822,
        Pin: 415020,
        DispatchCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        OriginCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        FacilityCity: "Satara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5823,
        Pin: 250404,
        DispatchCenter: "Mawana_Veernagar_D (Uttar Pradesh)",
        OriginCenter: "Mawana_Veernagar_D (Uttar Pradesh)",
        FacilityCity: "Mawana",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5824,
        Pin: 509125,
        DispatchCenter: "Gadwal_Venkatramana_D (Telangana)",
        OriginCenter: "Gadwal_Venkatramana_D (Telangana)",
        FacilityCity: "Gadwal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5825,
        Pin: 311204,
        DispatchCenter: "Badnaur_Asind_D (Rajasthan)",
        OriginCenter: "Badnaur_Asind_D (Rajasthan)",
        FacilityCity: "Asind",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5826,
        Pin: 470124,
        DispatchCenter: "Bina_Srvdycwk_D (Madhya Pradesh)",
        OriginCenter: "Bina_Srvdycwk_D (Madhya Pradesh)",
        FacilityCity: "Bina",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5827,
        Pin: 230141,
        DispatchCenter: "Kunda_KaretiRd_D (Uttar Pradesh)",
        OriginCenter: "Kunda_KaretiRd_D (Uttar Pradesh)",
        FacilityCity: "Kunda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5828,
        Pin: 534342,
        DispatchCenter: "Kovvur_Ramarao_D (Andhra Pradesh)",
        OriginCenter: "Kovvur_Ramarao_D (Andhra Pradesh)",
        FacilityCity: "Kovvur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5829,
        Pin: 791109,
        DispatchCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        OriginCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        FacilityCity: "Itanagar",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5830,
        Pin: 422205,
        DispatchCenter: "Nashik_Vani_DPP (Maharashtra)",
        OriginCenter: "Nashik_Vani_DPP (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5831,
        Pin: 825406,
        DispatchCenter: "Chouparan_Chaithimore_D (Jharkhand)",
        OriginCenter: "Chouparan_Chaithimore_D (Jharkhand)",
        FacilityCity: "Chouparan",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5832,
        Pin: 517503,
        DispatchCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        OriginCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        FacilityCity: "Tirupati",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5833,
        Pin: 688564,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5834,
        Pin: 324001,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5835,
        Pin: 523167,
        DispatchCenter: "Addanki_Inkollu_D (Andhra Pradesh)",
        OriginCenter: "Addanki_Inkollu_D (Andhra Pradesh)",
        FacilityCity: "Addanki",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5836,
        Pin: 628007,
        DispatchCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        OriginCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        FacilityCity: "Tuticorin",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5837,
        Pin: 627857,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5838,
        Pin: 509218,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5839,
        Pin: 225407,
        DispatchCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        OriginCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        FacilityCity: "Dariyabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5840,
        Pin: 560089,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5841,
        Pin: 518463,
        DispatchCenter: "Yemignur_Timmappacolony_D (Andhra Pradesh)",
        OriginCenter: "Yemignur_Timmappacolony_D (Andhra Pradesh)",
        FacilityCity: "Yemmiganur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5842,
        Pin: 400614,
        DispatchCenter: "Mumbai_Turbhe_L (Maharashtra)",
        OriginCenter: "Mumbai_Turbhe_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5843,
        Pin: 700010,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5844,
        Pin: 285122,
        DispatchCenter: "Orai_Ajnari_D (Uttar Pradesh)",
        OriginCenter: "Orai_Ajnari_D (Uttar Pradesh)",
        FacilityCity: "Orai",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5845,
        Pin: 796701,
        DispatchCenter: "Lunglei_Zotlang_D (Mizoram)",
        OriginCenter: "Lunglei_Zotlang_D (Mizoram)",
        FacilityCity: "Lunglei",
        FacilityState: "Mizoram",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5846,
        Pin: 690539,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5847,
        Pin: 680569,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5848,
        Pin: 524313,
        DispatchCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        OriginCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5849,
        Pin: 563136,
        DispatchCenter: "Mulbagal_Sunku_D (Karnataka)",
        OriginCenter: "Mulbagal_Sunku_D (Karnataka)",
        FacilityCity: "Mulbagal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5850,
        Pin: 206301,
        DispatchCenter: "Mainpuri_Bypassroad_D (Uttar Pradesh)",
        OriginCenter: "Mainpuri_Bypassroad_D (Uttar Pradesh)",
        FacilityCity: "Mainpuri",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5851,
        Pin: 628215,
        DispatchCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        OriginCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        FacilityCity: "Tiruchendur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5852,
        Pin: 322235,
        DispatchCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        OriginCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5853,
        Pin: 490026,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5854,
        Pin: 141308,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5855,
        Pin: 363530,
        DispatchCenter: "Chotila_Surendranagar_D (Gujarat)",
        OriginCenter: "Chotila_Surendranagar_D (Gujarat)",
        FacilityCity: "Chotila",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5856,
        Pin: 783371,
        DispatchCenter: "Chapar_Rowmari_DPP (Assam)",
        OriginCenter: "Chapar_Rowmari_DPP (Assam)",
        FacilityCity: "Chapar",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5857,
        Pin: 679344,
        DispatchCenter: "Nilambur_KarulaiRd_DPP (Kerala)",
        OriginCenter: "Nilambur_KarulaiRd_DPP (Kerala)",
        FacilityCity: "Nilambur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5858,
        Pin: 683514,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5859,
        Pin: 625111,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5860,
        Pin: 441803,
        DispatchCenter: "Brahmapuri_Pimpalgaon_DPP (Maharashtra)",
        OriginCenter: "Brahmapuri_Pimpalgaon_DPP (Maharashtra)",
        FacilityCity: "Brahmapuri",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5861,
        Pin: 625023,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5862,
        Pin: 560037,
        DispatchCenter: "Bangalore_Koralur_L (Karnataka)",
        OriginCenter: "Bangalore_Soukyaroad_GW (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5863,
        Pin: 591244,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5864,
        Pin: 799277,
        DispatchCenter: "Kailashahar_Ward4_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Kailashahar",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5865,
        Pin: 577415,
        DispatchCenter: "Thirthahalli_Melinakuruvali2_DPP (Karnataka)",
        OriginCenter: "Thirthahalli_Melinakuruvali2_DPP (Karnataka)",
        FacilityCity: "Thirthahalli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5866,
        Pin: 691562,
        DispatchCenter: "Kollam_Karunagappally_D (Kerala)",
        OriginCenter: "Kollam_Karunagappally_D (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5867,
        Pin: 736134,
        DispatchCenter: "CoochBehar_khagrabari_I (West Bengal)",
        OriginCenter: "CoochBehar_khagrabari_I (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5868,
        Pin: 505524,
        DispatchCenter: "Choppadandi_Gopalraopeta_D (Telangana)",
        OriginCenter: "Choppadandi_Gopalraopeta_D (Telangana)",
        FacilityCity: "Choppadandi",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5869,
        Pin: 741160,
        DispatchCenter: "Nazirpur_Thanarpara_D (West Bengal)",
        OriginCenter: "Nazirpur_Thanarpara_D (West Bengal)",
        FacilityCity: "Nazirpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5870,
        Pin: 487770,
        DispatchCenter: "Gadarwara_MPward_D (Madhya Pradesh)",
        OriginCenter: "Gadarwara_MPward_D (Madhya Pradesh)",
        FacilityCity: "Gadarwara",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5871,
        Pin: 182201,
        DispatchCenter: "Doda_Thathri_DPP (Jammu & Kashmir)",
        OriginCenter: "Doda_Thathri_DPP (Jammu & Kashmir)",
        FacilityCity: "Doda",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5872,
        Pin: 845425,
        DispatchCenter: "Motihari_Lauriya_D (Bihar)",
        OriginCenter: "Motihari_Lauriya_D (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5873,
        Pin: 201101,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5874,
        Pin: 832401,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5875,
        Pin: 721168,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5876,
        Pin: 576230,
        DispatchCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        OriginCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5877,
        Pin: 306701,
        DispatchCenter: "Falna_Khudala_DPP (Rajasthan)",
        OriginCenter: "Falna_Khudala_DPP (Rajasthan)",
        FacilityCity: "Falna",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5878,
        Pin: 140104,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5879,
        Pin: 799143,
        DispatchCenter: "ManuBazar_RajibNgr_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Manu bazar",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5880,
        Pin: 792104,
        DispatchCenter: "Hayuliang_Khupa_D (Arunachal Pradesh)",
        OriginCenter: "Hayuliang_Khupa_D (Arunachal Pradesh)",
        FacilityCity: "Hayuliang",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5881,
        Pin: 632201,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5882,
        Pin: 581123,
        DispatchCenter: "Davangere_Industrialarea_I (Karnataka)",
        OriginCenter: "Davangere_Industrialarea_I (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5883,
        Pin: 487334,
        DispatchCenter: "Tendukheda_AadhaarCntr_D (Madhya Pradesh)",
        OriginCenter: "Tendukheda_AadhaarCntr_D (Madhya Pradesh)",
        FacilityCity: "Gadarwara",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 5884,
        Pin: 485331,
        DispatchCenter: "Majhgawa_MPSEBofc_DPP (Madhya Pradesh)",
        OriginCenter: "Majhgawa_MPSEBofc_DPP (Madhya Pradesh)",
        FacilityCity: "Majhgawa",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 5885,
        Pin: 490006,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5886,
        Pin: 413112,
        DispatchCenter: "Akluj_SumitraNgr_D (Maharashtra)",
        OriginCenter: "Akluj_SumitraNgr_D (Maharashtra)",
        FacilityCity: "Akluj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5887,
        Pin: 576228,
        DispatchCenter: "Bhatkal_Sodigadde_D (Karnataka)",
        OriginCenter: "Bhatkal_Sodigadde_D (Karnataka)",
        FacilityCity: "Bhatkal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5888,
        Pin: 506001,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5889,
        Pin: 490002,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5890,
        Pin: 475673,
        DispatchCenter: "Datia_Indergarh_DPP (Madhya Pradesh)",
        OriginCenter: "Datia_Indergarh_DPP (Madhya Pradesh)",
        FacilityCity: "Datia",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 5891,
        Pin: 388051,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5892,
        Pin: 450114,
        DispatchCenter: "Mundi_Kanud_D (Madhya Pradesh)",
        OriginCenter: "Mundi_Kanud_D (Madhya Pradesh)",
        FacilityCity: "Mundi",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5893,
        Pin: 517124,
        DispatchCenter: "Pakala_Gorpadu_D (Andhra Pradesh)",
        OriginCenter: "Pakala_Gorpadu_D (Andhra Pradesh)",
        FacilityCity: "Pakala",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5894,
        Pin: 785649,
        DispatchCenter: "Sivasagar_Pdinnagar_D (Assam)",
        OriginCenter: "Sivasagar_Pdinnagar_D (Assam)",
        FacilityCity: "Sivasagar",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5895,
        Pin: 620008,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5896,
        Pin: 803301,
        DispatchCenter: "Sheonor_Moldiyar_D (Bihar)",
        OriginCenter: "Sheonor_Moldiyar_D (Bihar)",
        FacilityCity: "Sheonar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5897,
        Pin: 736205,
        DispatchCenter: "Kumargram_GovHpt_DPP (West Bengal)",
        OriginCenter: "Kumargram_GovHpt_DPP (West Bengal)",
        FacilityCity: "Kumargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5898,
        Pin: 786005,
        DispatchCenter: "Dibrugarh_Mohanbari_H (Assam)",
        OriginCenter: "Dibrugarh_Mohanbari_H (Assam)",
        FacilityCity: "Dibrugarh",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5899,
        Pin: 207002,
        DispatchCenter: "Etah_Dineshnagar_D (Uttar Pradesh)",
        OriginCenter: "Etah_Dineshnagar_D (Uttar Pradesh)",
        FacilityCity: "Etah",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5900,
        Pin: 142032,
        DispatchCenter: "Raikot_DC (Punjab)",
        OriginCenter: "Raikot_DC (Punjab)",
        FacilityCity: "Raikot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5901,
        Pin: 767046,
        DispatchCenter: "Saintala_Blockroad_DPP (Orissa)",
        OriginCenter: "Saintala_Blockroad_DPP (Orissa)",
        FacilityCity: "Saintala",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5902,
        Pin: 312023,
        DispatchCenter: "Mandalgarh_Vakilcolony_D (Rajasthan)",
        OriginCenter: "Mandalgarh_Vakilcolony_D (Rajasthan)",
        FacilityCity: "Mandalgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5903,
        Pin: 392035,
        DispatchCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        OriginCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        FacilityCity: "Jambusar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5904,
        Pin: 383355,
        DispatchCenter: "Bhiloda_NirSagarPlaza_D (Gujarat)",
        OriginCenter: "Bhiloda_NirSagarPlaza_D (Gujarat)",
        FacilityCity: "Bhiloda",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5905,
        Pin: 271312,
        DispatchCenter: "Domariyaganj_Belgari_D (Uttar Pradesh)",
        OriginCenter: "Domariyaganj_Belgari_D (Uttar Pradesh)",
        FacilityCity: "Domariyaganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5906,
        Pin: 768016,
        DispatchCenter: "Sambalpur_Tentelpara_H (Orissa)",
        OriginCenter: "Sambalpur_Tentelpara_H (Orissa)",
        FacilityCity: "Sambalpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5907,
        Pin: 302036,
        DispatchCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        OriginCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5908,
        Pin: 847409,
        DispatchCenter: "Phulparas_GhoghardihaRoad_D (Bihar)",
        OriginCenter: "Phulparas_GhoghardihaRoad_D (Bihar)",
        FacilityCity: "Phulparas",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5909,
        Pin: 450551,
        DispatchCenter: "Sanawad_Bediya_DPP (Madhya Pradesh)",
        OriginCenter: "Sanawad_Bediya_DPP (Madhya Pradesh)",
        FacilityCity: "Sanawad",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 5910,
        Pin: 571423,
        DispatchCenter: "Krishnarajpet_JayaNagar_D (Karnataka)",
        OriginCenter: "Krishnarajpet_JayaNagar_D (Karnataka)",
        FacilityCity: "Krishnarajpet",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5911,
        Pin: 767020,
        DispatchCenter: "Loisingha_Omshanticolony_D (Orissa)",
        OriginCenter: "Loisingha_Omshanticolony_D (Orissa)",
        FacilityCity: "Loisingha",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5912,
        Pin: 174005,
        DispatchCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        OriginCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        FacilityCity: "Bilaspur-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5913,
        Pin: 689588,
        DispatchCenter: "Karukachal_Nedumkunam_D (Kerala)",
        OriginCenter: "Karukachal_Nedumkunam_D (Kerala)",
        FacilityCity: "Karukachal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5914,
        Pin: 678556,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5915,
        Pin: 571119,
        DispatchCenter: "Nanjangud_Deviramanahalli_D (Karnataka)",
        OriginCenter: "Nanjangud_Deviramanahalli_D (Karnataka)",
        FacilityCity: "Nanjangud",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5916,
        Pin: 713358,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5917,
        Pin: 799269,
        DispatchCenter: "Kumarghat_Kanchanpur_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Kumarghat",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5918,
        Pin: 263135,
        DispatchCenter: "Nainital_Siltona_DPP (Uttarakhand)",
        OriginCenter: "Nainital_Siltona_DPP (Uttarakhand)",
        FacilityCity: "Nainital",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5919,
        Pin: 627112,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5920,
        Pin: 609203,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5921,
        Pin: 442710,
        DispatchCenter: "Aheri_Bhamragarh_DPP (Maharashtra)",
        OriginCenter: "Aheri_Bhamragarh_DPP (Maharashtra)",
        FacilityCity: "Aheri",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 5922,
        Pin: 788125,
        DispatchCenter: "Pailapool_Lagktlbocpr_D (Assam)",
        OriginCenter: "Pailapool_Lagktlbocpr_D (Assam)",
        FacilityCity: "Pailapool",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5923,
        Pin: 415304,
        DispatchCenter: "Vita_Adarshnagar_D (Maharashtra)",
        OriginCenter: "Vita_Adarshnagar_D (Maharashtra)",
        FacilityCity: "Vita",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5924,
        Pin: 473115,
        DispatchCenter: "Binaganj_GoyaRd_DPP (Madhya Pradesh)",
        OriginCenter: "Binaganj_GoyaRd_DPP (Madhya Pradesh)",
        FacilityCity: "Binaganj",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5925,
        Pin: 636701,
        DispatchCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        OriginCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        FacilityCity: "Dharmapuri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5926,
        Pin: 533342,
        DispatchCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5927,
        Pin: 781013,
        DispatchCenter: "Guwahati_Kaikchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5928,
        Pin: 788802,
        DispatchCenter: "Karimganj_Gorkapan_D (Assam)",
        OriginCenter: "Karimganj_Gorkapan_D (Assam)",
        FacilityCity: "Karimganj",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5929,
        Pin: 110020,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5930,
        Pin: 605652,
        DispatchCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        OriginCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        FacilityCity: "Viluppuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5931,
        Pin: 314401,
        DispatchCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        OriginCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        FacilityCity: "Sagwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5932,
        Pin: 691535,
        DispatchCenter: "Kilimanoor_AKNArcard_D (Kerala)",
        OriginCenter: "Kilimanoor_AKNArcard_D (Kerala)",
        FacilityCity: "Kilimanoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5933,
        Pin: 143107,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5934,
        Pin: 311804,
        DispatchCenter: "Gangapur_Raipur_DPP (Rajasthan)",
        OriginCenter: "Gangapur_Raipur_DPP (Rajasthan)",
        FacilityCity: "Gangapur-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5935,
        Pin: 341528,
        DispatchCenter: "Kuchmancity_PratapNagar_D (Rajasthan)",
        OriginCenter: "Kuchmancity_PratapNagar_D (Rajasthan)",
        FacilityCity: "Kuchaman",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5936,
        Pin: 691316,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5937,
        Pin: 679325,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5938,
        Pin: 638006,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5939,
        Pin: 522608,
        DispatchCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        OriginCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        FacilityCity: "Ongole",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5940,
        Pin: 803216,
        DispatchCenter: "BiharSharif_Sherpur_D (Bihar)",
        OriginCenter: "BiharSharif_Sherpur_D (Bihar)",
        FacilityCity: "Bihar Sharif",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5941,
        Pin: 410403,
        DispatchCenter: "Lonavala_NngrgnRd_D (Maharashtra)",
        OriginCenter: "Lonavala_NngrgnRd_D (Maharashtra)",
        FacilityCity: "Lonavala",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5942,
        Pin: 283104,
        DispatchCenter: "Fatehabad_Bah_D (Uttar Pradesh)",
        OriginCenter: "Fatehabad_Bah_D (Uttar Pradesh)",
        FacilityCity: "Fatehabad-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5943,
        Pin: 175002,
        DispatchCenter: "SundarNgr_BhojpurRd_D (Himachal Pradesh)",
        OriginCenter: "SundarNgr_BhojpurRd_D (Himachal Pradesh)",
        FacilityCity: "Sundar Nagar",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5944,
        Pin: 711306,
        DispatchCenter: "Howrah_Temp_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5945,
        Pin: 363020,
        DispatchCenter: "Surendranagar_GIDC_D (Gujarat)",
        OriginCenter: "Surendranagar_GIDC_D (Gujarat)",
        FacilityCity: "Surendranagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5946,
        Pin: 370410,
        DispatchCenter: "Mundra_Umiyanagar_D (Gujarat)",
        OriginCenter: "Mundra_Umiyanagar_D (Gujarat)",
        FacilityCity: "Mundra",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5947,
        Pin: 384246,
        DispatchCenter: "Sami_CityLgtComplx_DPP (Gujarat)",
        OriginCenter: "Sami_CityLgtComplx_DPP (Gujarat)",
        FacilityCity: "Sami",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5948,
        Pin: 533291,
        DispatchCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        OriginCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        FacilityCity: "Rajahmundry",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5949,
        Pin: 507117,
        DispatchCenter: "Manuguru_AshokN_D (Telangana)",
        OriginCenter: "Manuguru_AshokN_D (Telangana)",
        FacilityCity: "Manuguru",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5950,
        Pin: 571405,
        DispatchCenter: "Mandya_Dc (Karnataka)",
        OriginCenter: "Mandya_Dc (Karnataka)",
        FacilityCity: "Mandya",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5951,
        Pin: 854112,
        DispatchCenter: "Manihari_Bnrailwaycol_D (Bihar)",
        OriginCenter: "Manihari_Bnrailwaycol_D (Bihar)",
        FacilityCity: "Manihari",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5952,
        Pin: 761131,
        DispatchCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        OriginCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        FacilityCity: "Bhanjanagar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5953,
        Pin: 327026,
        DispatchCenter: "Banswara_Baridaylan_D (Rajasthan)",
        OriginCenter: "Banswara_Baridaylan_D (Rajasthan)",
        FacilityCity: "Banswara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5954,
        Pin: 643233,
        DispatchCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        OriginCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        FacilityCity: "Gudalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5955,
        Pin: 509128,
        DispatchCenter: "Manopad_Chennipadu_D (Telangana)",
        OriginCenter: "Manopad_Chennipadu_D (Telangana)",
        FacilityCity: "Manopad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5956,
        Pin: 521157,
        DispatchCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        OriginCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        FacilityCity: "Pamarru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5957,
        Pin: 676102,
        DispatchCenter: "Tirur_Perunthallur_D (Kerala)",
        OriginCenter: "Tirur_Perunthallur_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5958,
        Pin: 382426,
        DispatchCenter: "Ahmedabad_Kanera_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5959,
        Pin: 761122,
        DispatchCenter: "Asika_DsarathiNgr_DPP (Orissa)",
        OriginCenter: "Asika_DsarathiNgr_DPP (Orissa)",
        FacilityCity: "Asika",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5960,
        Pin: 743273,
        DispatchCenter: "Swarupnagar_MlangaPra_DPP (West Bengal)",
        OriginCenter: "Swarupnagar_MlangaPra_DPP (West Bengal)",
        FacilityCity: "Swarupnagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5961,
        Pin: 243724,
        DispatchCenter: "Sahaswan_Mainmarket_D (Uttar Pradesh)",
        OriginCenter: "Sahaswan_Mainmarket_D (Uttar Pradesh)",
        FacilityCity: "Sahaswan",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5962,
        Pin: 515122,
        DispatchCenter: "Puttaprthi_Penukonda_D (Andhra Pradesh)",
        OriginCenter: "Puttaprthi_Penukonda_D (Andhra Pradesh)",
        FacilityCity: "Puttaparthi",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5963,
        Pin: 453556,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 5964,
        Pin: 638452,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5965,
        Pin: 464331,
        DispatchCenter: "GanjBasoda_Gyaraspur_DPP (Madhya Pradesh)",
        OriginCenter: "GanjBasoda_Gyaraspur_DPP (Madhya Pradesh)",
        FacilityCity: "Ganj Basoda",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5966,
        Pin: 484110,
        DispatchCenter: "Shahdol_Jamui_D (Madhya Pradesh)",
        OriginCenter: "Shahdol_Jamui_D (Madhya Pradesh)",
        FacilityCity: "Shahdol",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5967,
        Pin: 627818,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5968,
        Pin: 382715,
        DispatchCenter: "Kadi_Jadavpura_L (Gujarat)",
        OriginCenter: "Kadi_Jadavpura_L (Gujarat)",
        FacilityCity: "Kadi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 5969,
        Pin: 184102,
        DispatchCenter: "Kathua_Kharote_D (Jammu & Kashmir)",
        OriginCenter: "Kathua_Kharote_D (Jammu & Kashmir)",
        FacilityCity: "Kathua",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 5970,
        Pin: 686144,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 5971,
        Pin: 576233,
        DispatchCenter: "Kundapura_Shankarnarayana_D (Karnataka)",
        OriginCenter: "Kundapura_Shankarnarayana_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5972,
        Pin: 585227,
        DispatchCenter: "Mannaekhelli_Faqruddin_DPP (Karnataka)",
        OriginCenter: "Mannaekhelli_Faqruddin_DPP (Karnataka)",
        FacilityCity: "Mannaekhelli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5973,
        Pin: 572168,
        DispatchCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        OriginCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5974,
        Pin: 247341,
        DispatchCenter: "Gangoh_BypassRd_DPP (Uttar Pradesh)",
        OriginCenter: "Gangoh_BypassRd_DPP (Uttar Pradesh)",
        FacilityCity: "Gangoh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5975,
        Pin: 177046,
        DispatchCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        OriginCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        FacilityCity: "Nadaun",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5976,
        Pin: 627003,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5977,
        Pin: 533213,
        DispatchCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        OriginCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        FacilityCity: "Amalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5978,
        Pin: 176403,
        DispatchCenter: "Pathankot_Kahanpur_H (Punjab)",
        OriginCenter: "Pathankot_Kahanpur_H (Punjab)",
        FacilityCity: "Pathankot",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5979,
        Pin: 571109,
        DispatchCenter: "Gundlupet_ChamrajNagar_D (Karnataka)",
        OriginCenter: "Gundlupet_ChamrajNagar_D (Karnataka)",
        FacilityCity: "Gundlupet",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5980,
        Pin: 431723,
        DispatchCenter: "Degloor_GouklNgr_DPP (Maharashtra)",
        OriginCenter: "Degloor_GouklNgr_DPP (Maharashtra)",
        FacilityCity: "Degloor",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 5981,
        Pin: 221111,
        DispatchCenter: "Cholapur_Katari_D (Uttar Pradesh)",
        OriginCenter: "Cholapur_Katari_D (Uttar Pradesh)",
        FacilityCity: "Cholapur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5982,
        Pin: 203398,
        DispatchCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        OriginCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        FacilityCity: "Anupshahar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5983,
        Pin: 523157,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 5984,
        Pin: 580028,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5985,
        Pin: 385566,
        DispatchCenter: "Tharad_Budhanpur_D (Gujarat)",
        OriginCenter: "Tharad_Budhanpur_D (Gujarat)",
        FacilityCity: "Tharad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 5986,
        Pin: 475686,
        DispatchCenter: "Datia_ShardaVihar_DPP (Madhya Pradesh)",
        OriginCenter: "Datia_ShardaVihar_DPP (Madhya Pradesh)",
        FacilityCity: "Datia",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 5987,
        Pin: 816103,
        DispatchCenter: "Shikaripara_Karakata_D (Jharkhand)",
        OriginCenter: "Shikaripara_Karakata_D (Jharkhand)",
        FacilityCity: "Shikaripara",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5988,
        Pin: 713144,
        DispatchCenter: "Panagarh_Kanksa_D (West Bengal)",
        OriginCenter: "Panagarh_Kanksa_D (West Bengal)",
        FacilityCity: "Panagarh",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 5989,
        Pin: 190006,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 5990,
        Pin: 764046,
        DispatchCenter: "Balimela_MainRoad_DPP (Orissa)",
        OriginCenter: "Balimela_MainRoad_DPP (Orissa)",
        FacilityCity: "Balimela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5991,
        Pin: 224203,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 5992,
        Pin: 458002,
        DispatchCenter: "Mandsaur_Sindpan_I (Madhya Pradesh)",
        OriginCenter: "Mandsaur_Sindpan_I (Madhya Pradesh)",
        FacilityCity: "Mandsaur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 5993,
        Pin: 625301,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 5994,
        Pin: 306601,
        DispatchCenter: "Khinwara_Nadol_DPP (Rajasthan)",
        OriginCenter: "Khinwara_Nadol_DPP (Rajasthan)",
        FacilityCity: "Khinwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 5995,
        Pin: 781124,
        DispatchCenter: "Chhaygaon_Dhekenabari_D (Assam)",
        OriginCenter: "Chhaygaon_Dhekenabari_D (Assam)",
        FacilityCity: "Chhaygaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 5996,
        Pin: 781304,
        DispatchCenter: "Sarthebari_Tmlichp_D (Assam)",
        OriginCenter: "Sarthebari_Tmlichp_D (Assam)",
        FacilityCity: "Sarthebari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 5997,
        Pin: 742190,
        DispatchCenter: "Raghunathganj_NH12_D (West Bengal)",
        OriginCenter: "Raghunathganj_NH12_D (West Bengal)",
        FacilityCity: "Raghunathganj",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 5998,
        Pin: 680668,
        DispatchCenter: "Kodungallur_Methala_D (Kerala)",
        OriginCenter: "Kodungallur_Methala_D (Kerala)",
        FacilityCity: "Kodungallur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 5999,
        Pin: 144009,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6000,
        Pin: 582222,
        DispatchCenter: "Dharwad_Kamalpur_D (Karnataka)",
        OriginCenter: "Dharwad_Kamalpur_D (Karnataka)",
        FacilityCity: "Dharwad",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6001,
        Pin: 673122,
        DispatchCenter: "Kalpetta_Mandayapuram_D (Kerala)",
        OriginCenter: "Kalpetta_Mandayapuram_D (Kerala)",
        FacilityCity: "Kalpetta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6002,
        Pin: 686104,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6003,
        Pin: 851205,
        DispatchCenter: "Khagaria_Baluwahi_DPP (Bihar)",
        OriginCenter: "Khagaria_Baluwahi_DPP (Bihar)",
        FacilityCity: "Khagaria",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6004,
        Pin: 801507,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6005,
        Pin: 847104,
        DispatchCenter: "Bharwara_AtarwelPath_D (Bihar)",
        OriginCenter: "Bharwara_AtarwelPath_D (Bihar)",
        FacilityCity: "Bharwara",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6006,
        Pin: 431106,
        DispatchCenter: "Selu_Vidyangr_D (Maharashtra)",
        OriginCenter: "Selu_Vidyangr_D (Maharashtra)",
        FacilityCity: "Selu",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6007,
        Pin: 671326,
        DispatchCenter: "Parappa_Balal_D (Kerala)",
        OriginCenter: "Parappa_Balal_D (Kerala)",
        FacilityCity: "Parappa",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6008,
        Pin: 151504,
        DispatchCenter: "Mansa_Budhlada2_D (Punjab)",
        OriginCenter: "Mansa_Budhlada2_D (Punjab)",
        FacilityCity: "Mansa",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6009,
        Pin: 530053,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6010,
        Pin: 271124,
        DispatchCenter: "Nawabganj_Shbapr_D (Uttar Pradesh)",
        OriginCenter: "Nawabganj_Shbapr_D (Uttar Pradesh)",
        FacilityCity: "Nawabganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6011,
        Pin: 695099,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6012,
        Pin: 835208,
        DispatchCenter: "Gumla_Sikwar_D (Jharkhand)",
        OriginCenter: "Gumla_Sikwar_D (Jharkhand)",
        FacilityCity: "Gumla",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6013,
        Pin: 743375,
        DispatchCenter: "Fatepur_Majlishpur_D (West Bengal)",
        OriginCenter: "Fatepur_Majlishpur_D (West Bengal)",
        FacilityCity: "Fatepur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6014,
        Pin: 605759,
        DispatchCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        OriginCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        FacilityCity: "Thirukoilure",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6015,
        Pin: 246731,
        DispatchCenter: "Bijnor_Garhi_D (Uttar Pradesh)",
        OriginCenter: "Bijnor_Garhi_D (Uttar Pradesh)",
        FacilityCity: "Bijnor",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6016,
        Pin: 682051,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        Zone : "S2"
      },
      {
        SN: 6017,
        Pin: 785621,
        DispatchCenter: "Golaghat_BaruaRd_D (Assam)",
        OriginCenter: "Golaghat_BaruaRd_D (Assam)",
        FacilityCity: "Golaghat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6018,
        Pin: 844125,
        DispatchCenter: "Patna_Chhotipahari_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6019,
        Pin: 283111,
        DispatchCenter: "Fatehabad_Rsulpr_D (Uttar Pradesh)",
        OriginCenter: "Fatehabad_Rsulpr_D (Uttar Pradesh)",
        FacilityCity: "Fatehabad-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6020,
        Pin: 508114,
        DispatchCenter: "Ramanapeta_Kurmawada_D (Telangana)",
        OriginCenter: "Ramanapeta_Kurmawada_D (Telangana)",
        FacilityCity: "Ramannapeta",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6021,
        Pin: 711405,
        DispatchCenter: "Howrah_Kamrangu_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6022,
        Pin: 413208,
        DispatchCenter: "Kurduwadi_Dattanagar_D (Maharashtra)",
        OriginCenter: "Kurduwadi_Dattanagar_D (Maharashtra)",
        FacilityCity: "Kurduwadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6023,
        Pin: 754246,
        DispatchCenter: "Garjanpur_Gossei_D (Orissa)",
        OriginCenter: "Garjanpur_Gossei_D (Orissa)",
        FacilityCity: "Garjanpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6024,
        Pin: 110040,
        DispatchCenter: "Delhi_Libaspur_L (Delhi)",
        OriginCenter: "Delhi_Libaspur_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6025,
        Pin: 560035,
        DispatchCenter: "Bangalore_BmsdraPhase3_L (Karnataka)",
        OriginCenter: "Bangalore_BmsdraPhase3_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6026,
        Pin: 123034,
        DispatchCenter: "MahendragarhHR_Khaira_D (Haryana)",
        OriginCenter: "MahendragarhHR_Khaira_D (Haryana)",
        FacilityCity: "Mahendragarh-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6027,
        Pin: 452003,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6028,
        Pin: 560125,
        DispatchCenter: "Bangalore_BmsdraPhase3_L (Karnataka)",
        OriginCenter: "Bangalore_BmsdraPhase3_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6029,
        Pin: 491229,
        DispatchCenter: "Manpur_Khairkatta_D (Chhattisgarh)",
        OriginCenter: "Manpur_Khairkatta_D (Chhattisgarh)",
        FacilityCity: "Manpur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6030,
        Pin: 572137,
        DispatchCenter: "Sira_Hanumanthnagara_D (Karnataka)",
        OriginCenter: "Sira_Hanumanthnagara_D (Karnataka)",
        FacilityCity: "Sira",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6031,
        Pin: 642201,
        DispatchCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        OriginCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        FacilityCity: "Udumalaipettai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6032,
        Pin: 630104,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6033,
        Pin: 206303,
        DispatchCenter: "Kishni_Shivmndr_D (Uttar Pradesh)",
        OriginCenter: "Kishni_Shivmndr_D (Uttar Pradesh)",
        FacilityCity: "Kishni",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6034,
        Pin: 244922,
        DispatchCenter: "Shahabad_Dhkriya_D (Uttar Pradesh)",
        OriginCenter: "Shahabad_Dhkriya_D (Uttar Pradesh)",
        FacilityCity: "Shahabad-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6035,
        Pin: 506012,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6036,
        Pin: 686501,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6037,
        Pin: 500101,
        DispatchCenter: "Hyderabad_Medchal1_L (Telangana)",
        OriginCenter: "Hyderabad_Medchal_GW (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6038,
        Pin: 784003,
        DispatchCenter: "Tezpur_Dipota_H (Assam)",
        OriginCenter: "Tezpur_Dipota_H (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6039,
        Pin: 637020,
        DispatchCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        OriginCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        FacilityCity: "Namakkal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6040,
        Pin: 676503,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6041,
        Pin: 783385,
        DispatchCenter: "Bongaigaon_Chapaguri_I (Assam)",
        OriginCenter: "Bongaigaon_Chapaguri_I (Assam)",
        FacilityCity: "Bongaigaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6042,
        Pin: 625519,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6043,
        Pin: 752093,
        DispatchCenter: "Daspalla_Andharkota_DPP (Orissa)",
        OriginCenter: "Daspalla_Andharkota_DPP (Orissa)",
        FacilityCity: "Daspalla",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6044,
        Pin: 629601,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6045,
        Pin: 415641,
        DispatchCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        OriginCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        FacilityCity: "Shringartali",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6046,
        Pin: 638503,
        DispatchCenter: "Sathyamangalam_VNSNagar_D (Tamil Nadu)",
        OriginCenter: "Sathyamangalam_VNSNagar_D (Tamil Nadu)",
        FacilityCity: "Sathyamangalam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6047,
        Pin: 272202,
        DispatchCenter: "Naugarh_Trtribazar_D (Uttar Pradesh)",
        OriginCenter: "Naugarh_Trtribazar_D (Uttar Pradesh)",
        FacilityCity: "Naugarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6048,
        Pin: 847232,
        DispatchCenter: "Khutauna_ShastriChwk_D (Bihar)",
        OriginCenter: "Khutauna_ShastriChwk_D (Bihar)",
        FacilityCity: "Khutauna",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6049,
        Pin: 262309,
        DispatchCenter: "Tanakpur_Shardacolony_D (Uttarakhand)",
        OriginCenter: "Tanakpur_Shardacolony_D (Uttarakhand)",
        FacilityCity: "Tanakpur",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 6050,
        Pin: 484552,
        DispatchCenter: "PaliBirsinghpur_Goraiya_D (Madhya Pradesh)",
        OriginCenter: "PaliBirsinghpur_Goraiya_D (Madhya Pradesh)",
        FacilityCity: "Pali Birsinghpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 6051,
        Pin: 824220,
        DispatchCenter: "Barachatti_DangraMore_D (Bihar)",
        OriginCenter: "Barachatti_DangraMore_D (Bihar)",
        FacilityCity: "Barachatti",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6052,
        Pin: 680584,
        DispatchCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        OriginCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6053,
        Pin: 364275,
        DispatchCenter: "Palitana_Jivapur_DPP (Gujarat)",
        OriginCenter: "Palitana_Jivapur_DPP (Gujarat)",
        FacilityCity: "Palitana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6054,
        Pin: 444125,
        DispatchCenter: "Akot_CollegeRd_D (Maharashtra)",
        OriginCenter: "Akot_CollegeRd_D (Maharashtra)",
        FacilityCity: "Akot",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6055,
        Pin: 271607,
        DispatchCenter: "Balrampur_Utraula_D (Uttar Pradesh)",
        OriginCenter: "Balrampur_Utraula_D (Uttar Pradesh)",
        FacilityCity: "Balrampur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6056,
        Pin: 682042,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6057,
        Pin: 500062,
        DispatchCenter: "Hyderabad_HemaNagar_L (Telangana)",
        OriginCenter: "Hyderabad_HemaNagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6058,
        Pin: 262728,
        DispatchCenter: "Lakhimpur_FunMall_D (Uttar Pradesh)",
        OriginCenter: "Lakhimpur_FunMall_D (Uttar Pradesh)",
        FacilityCity: "Lakhimpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6059,
        Pin: 146104,
        DispatchCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        OriginCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 6060,
        Pin: 380060,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6061,
        Pin: 500098,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6062,
        Pin: 151205,
        DispatchCenter: "Kotkapura_PremNgr_D (Punjab)",
        OriginCenter: "Kotkapura_PremNgr_D (Punjab)",
        FacilityCity: "Kotkapura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6063,
        Pin: 144518,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6064,
        Pin: 174029,
        DispatchCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        OriginCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        FacilityCity: "Bilaspur-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6065,
        Pin: 250342,
        DispatchCenter: "Daurala_Central_D_2 (Uttar Pradesh)",
        OriginCenter: "Daurala_Central_D_2 (Uttar Pradesh)",
        FacilityCity: "Daurala",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6066,
        Pin: 262124,
        DispatchCenter: "Puranpur_MdothdRD_D (Uttar Pradesh)",
        OriginCenter: "Puranpur_MdothdRD_D (Uttar Pradesh)",
        FacilityCity: "Puranpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6067,
        Pin: 522438,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6068,
        Pin: 522257,
        DispatchCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        OriginCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        FacilityCity: "Repalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6069,
        Pin: 322240,
        DispatchCenter: "Mahwa_Allapur_D (Rajasthan)",
        OriginCenter: "Mahwa_Allapur_D (Rajasthan)",
        FacilityCity: "Mahwa",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6070,
        Pin: 563138,
        DispatchCenter: "Srinivaspur_KolarRD_D (Karnataka)",
        OriginCenter: "Srinivaspur_KolarRD_D (Karnataka)",
        FacilityCity: "Srinivaspur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6071,
        Pin: 813109,
        DispatchCenter: "Punsia_Hansdiha_D (Bihar)",
        OriginCenter: "Punsia_Hansdiha_D (Bihar)",
        FacilityCity: "Punsia",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6072,
        Pin: 273163,
        DispatchCenter: "Nichlaul_Gorahwa_D (Uttar Pradesh)",
        OriginCenter: "Nichlaul_Gorahwa_D (Uttar Pradesh)",
        FacilityCity: "Nichlaul",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6073,
        Pin: 509350,
        DispatchCenter: "Kosgi_Kodangal_D (Telangana)",
        OriginCenter: "Kosgi_Kodangal_D (Telangana)",
        FacilityCity: "Kosgi",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6074,
        Pin: 842003,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6075,
        Pin: 700153,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6076,
        Pin: 600027,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6077,
        Pin: 532458,
        DispatchCenter: "Palakonda_VadaiDPP_D (Andhra Pradesh)",
        OriginCenter: "Palakonda_VadaiDPP_D (Andhra Pradesh)",
        FacilityCity: "Palakonda",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6078,
        Pin: 831003,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6079,
        Pin: 440036,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6080,
        Pin: 410302,
        DispatchCenter: "Lonavala_NngrgnRd_D (Maharashtra)",
        OriginCenter: "Lonavala_NngrgnRd_D (Maharashtra)",
        FacilityCity: "Lonavala",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6081,
        Pin: 143006,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6082,
        Pin: 517581,
        DispatchCenter: "Puttur_Artmclny_D (Andhra Pradesh)",
        OriginCenter: "Puttur_Artmclny_D (Andhra Pradesh)",
        FacilityCity: "Puttur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6083,
        Pin: 325601,
        DispatchCenter: "Baran_Housingboard_D (Rajasthan)",
        OriginCenter: "Baran_Housingboard_D (Rajasthan)",
        FacilityCity: "Baran",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6084,
        Pin: 362640,
        DispatchCenter: "Manavadar_Ratanpara_DPP (Gujarat)",
        OriginCenter: "Manavadar_Ratanpara_DPP (Gujarat)",
        FacilityCity: "Manavadar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6085,
        Pin: 452016,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6086,
        Pin: 756140,
        DispatchCenter: "Bhadrak_Deuli_D (Orissa)",
        OriginCenter: "Bhadrak_Deuli_D (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6087,
        Pin: 225401,
        DispatchCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        OriginCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        FacilityCity: "Dariyabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6088,
        Pin: 670008,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6089,
        Pin: 799266,
        DispatchCenter: "Kailashahar_Kumarghat_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Kailashahar",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6090,
        Pin: 423202,
        DispatchCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        OriginCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        FacilityCity: "Malegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6091,
        Pin: 487551,
        DispatchCenter: "Gadarwara_MPward_D (Madhya Pradesh)",
        OriginCenter: "Gadarwara_MPward_D (Madhya Pradesh)",
        FacilityCity: "Gadarwara",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6092,
        Pin: 685553,
        DispatchCenter: "Nedumkandam_Thannimoodu_D (Kerala)",
        OriginCenter: "Nedumkandam_Thannimoodu_D (Kerala)",
        FacilityCity: "Nedumkandam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6093,
        Pin: 678001,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6094,
        Pin: 145029,
        DispatchCenter: "Pathankot_Kahanpur_H (Punjab)",
        OriginCenter: "Pathankot_Kahanpur_H (Punjab)",
        FacilityCity: "Pathankot",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6095,
        Pin: 631206,
        DispatchCenter: "Sholinghur_Ramkrishnarajapet_D (Tamil Nadu)",
        OriginCenter: "Sholinghur_Ramkrishnarajapet_D (Tamil Nadu)",
        FacilityCity: "Sholinghur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6096,
        Pin: 680002,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6097,
        Pin: 612107,
        DispatchCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        OriginCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6098,
        Pin: 625530,
        DispatchCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        OriginCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        FacilityCity: "Cumbum",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6099,
        Pin: 608305,
        DispatchCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        OriginCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        FacilityCity: "Chidambaram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6100,
        Pin: 754106,
        DispatchCenter: "Jagatsinghpur_Bnbrda_D (Orissa)",
        OriginCenter: "Jagatsinghpur_Bnbrda_D (Orissa)",
        FacilityCity: "Jagatsinghpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6101,
        Pin: 321302,
        DispatchCenter: "Bayana_Shivcolony_D (Rajasthan)",
        OriginCenter: "Bayana_Shivcolony_D (Rajasthan)",
        FacilityCity: "Bayana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6102,
        Pin: 506112,
        DispatchCenter: "Mahabubabad_SnehaNagar_D (Telangana)",
        OriginCenter: "Mahabubabad_SnehaNagar_D (Telangana)",
        FacilityCity: "Mahabubabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6103,
        Pin: 361320,
        DispatchCenter: "Bhatiya_Shrijinagar_D (Gujarat)",
        OriginCenter: "Bhatiya_Shrijinagar_D (Gujarat)",
        FacilityCity: "Bhatiya",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6104,
        Pin: 249155,
        DispatchCenter: "Ghansali_Semli_D (Uttarakhand)",
        OriginCenter: "Ghansali_Semli_D (Uttarakhand)",
        FacilityCity: "Ghansali",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6105,
        Pin: 360570,
        DispatchCenter: "Kutiyana_jktnaka_DPP (Gujarat)",
        OriginCenter: "Kutiyana_jktnaka_DPP (Gujarat)",
        FacilityCity: "Kutiyana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6106,
        Pin: 560116,
        DispatchCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        OriginCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6107,
        Pin: 686635,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6108,
        Pin: 177106,
        DispatchCenter: "GhatiBillan_Ban_DPP (Himachal Pradesh)",
        OriginCenter: "GhatiBillan_Ban_DPP (Himachal Pradesh)",
        FacilityCity: "Ghati Billan",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6109,
        Pin: 829105,
        DispatchCenter: "Patratu_Saundabasti_D (Jharkhand)",
        OriginCenter: "Patratu_Saundabasti_D (Jharkhand)",
        FacilityCity: "Patratu",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6110,
        Pin: 393041,
        DispatchCenter: "Dediapada_SP_DPP (Gujarat)",
        OriginCenter: "Dediapada_SP_DPP (Gujarat)",
        FacilityCity: "Dediapada",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6111,
        Pin: 505470,
        DispatchCenter: "Huzurabad_Vivekanandanagar_D (Telangana)",
        OriginCenter: "Huzurabad_Vivekanandanagar_D (Telangana)",
        FacilityCity: "Huzurabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6112,
        Pin: 507119,
        DispatchCenter: "Kothagudem_Sujathanagar_D (Telangana)",
        OriginCenter: "Kothagudem_Sujathanagar_D (Telangana)",
        FacilityCity: "Kothagudem",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6113,
        Pin: 686506,
        DispatchCenter: "Kottayam_Mulakunnath_D (Kerala)",
        OriginCenter: "Kottayam_Mulakunnath_D (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6114,
        Pin: 770034,
        DispatchCenter: "Rajgangpur_Central_D_2 (Orissa)",
        OriginCenter: "Rajgangpur_Central_D_2 (Orissa)",
        FacilityCity: "Rajgangpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6115,
        Pin: 383434,
        DispatchCenter: "Satlasana_Hndutmpl_DPP (Gujarat)",
        OriginCenter: "Satlasana_Hndutmpl_DPP (Gujarat)",
        FacilityCity: "Satlasana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6116,
        Pin: 327603,
        DispatchCenter: "Banswara_Bagidora_D (Rajasthan)",
        OriginCenter: "Banswara_Bagidora_D (Rajasthan)",
        FacilityCity: "Banswara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6117,
        Pin: 782490,
        DispatchCenter: "Bokajan_Central_D (Assam)",
        OriginCenter: "Bokajan_Central_D (Assam)",
        FacilityCity: "Bokajan",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6118,
        Pin: 423208,
        DispatchCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        OriginCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        FacilityCity: "Malegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6119,
        Pin: 721157,
        DispatchCenter: "Midnapore_Sarbera_DPP (West Bengal)",
        OriginCenter: "Midnapore_Sarbera_DPP (West Bengal)",
        FacilityCity: "Midnapore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6120,
        Pin: 783120,
        DispatchCenter: "Goalpara_Baladmri_D (Assam)",
        OriginCenter: "Goalpara_Baladmri_D (Assam)",
        FacilityCity: "Goalpara",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6121,
        Pin: 801104,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_PhulwariSharif_L (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6122,
        Pin: 632012,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6123,
        Pin: 629152,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6124,
        Pin: 562130,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6125,
        Pin: 382320,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6126,
        Pin: 742303,
        DispatchCenter: "Domkal_StateBank_D (West Bengal)",
        OriginCenter: "Domkal_StateBank_D (West Bengal)",
        FacilityCity: "Domkal",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6127,
        Pin: 803110,
        DispatchCenter: "Bakhtiarpur_Raghopur_D (Bihar)",
        OriginCenter: "Bakhtiarpur_Raghopur_D (Bihar)",
        FacilityCity: "Bakhtiarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6128,
        Pin: 627127,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6129,
        Pin: 848129,
        DispatchCenter: "Samastipur_RhmprRdli_D (Bihar)",
        OriginCenter: "Samastipur_RhmprRdli_D (Bihar)",
        FacilityCity: "Samastipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6130,
        Pin: 327022,
        DispatchCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        OriginCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        FacilityCity: "Sagwara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6131,
        Pin: 500057,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6132,
        Pin: 795136,
        DispatchCenter: "Imphal_Takyel_I (Manipur)",
        OriginCenter: "Imphal_Takyel_I (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6133,
        Pin: 741502,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6134,
        Pin: 148106,
        DispatchCenter: "Sangrur_DC (Punjab)",
        OriginCenter: "Sangrur_DC (Punjab)",
        FacilityCity: "Sangrur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6135,
        Pin: 396140,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6136,
        Pin: 614624,
        DispatchCenter: "Peravurani_Nattanikkottai_D (Tamil Nadu)",
        OriginCenter: "Peravurani_Nattanikkottai_D (Tamil Nadu)",
        FacilityCity: "Peravurani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6137,
        Pin: 271882,
        DispatchCenter: "Nanpara_Dehati_D (Uttar Pradesh)",
        OriginCenter: "Nanpara_Dehati_D (Uttar Pradesh)",
        FacilityCity: "Nanpara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6138,
        Pin: 388235,
        DispatchCenter: "Balasinor_Alnoor_DPP (Gujarat)",
        OriginCenter: "Balasinor_Alnoor_DPP (Gujarat)",
        FacilityCity: "Balasinor",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6139,
        Pin: 802156,
        DispatchCenter: "Arrah_Anaith_D (Bihar)",
        OriginCenter: "Arrah_Anaith_D (Bihar)",
        FacilityCity: "Arrah",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6140,
        Pin: 764061,
        DispatchCenter: "Borigumma_Kotapad_DPP (Orissa)",
        OriginCenter: "Borigumma_Kotapad_DPP (Orissa)",
        FacilityCity: "Kotapad",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6141,
        Pin: 243720,
        DispatchCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        OriginCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        FacilityCity: "Chandausi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6142,
        Pin: 494661,
        DispatchCenter: "Narayanpur_Bakhrupara_D (Chhattisgarh)",
        OriginCenter: "Narayanpur_Bakhrupara_D (Chhattisgarh)",
        FacilityCity: "Narayanpur-CH",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6143,
        Pin: 583203,
        DispatchCenter: "Hospet_Vijayanagara_D (Karnataka)",
        OriginCenter: "Hospet_Vijayanagara_D (Karnataka)",
        FacilityCity: "Hospet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6144,
        Pin: 110052,
        DispatchCenter: "Delhi_Libaspur_L (Delhi)",
        OriginCenter: "Delhi_Libaspur_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6145,
        Pin: 402301,
        DispatchCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        OriginCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        FacilityCity: "Mahad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6146,
        Pin: 416006,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6147,
        Pin: 301027,
        DispatchCenter: "Viratnagar_Shyamcolony_D (Rajasthan)",
        OriginCenter: "Viratnagar_Shyamcolony_D (Rajasthan)",
        FacilityCity: "Viratnagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6148,
        Pin: 533106,
        DispatchCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        OriginCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        FacilityCity: "Rajahmundry",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6149,
        Pin: 606806,
        DispatchCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        OriginCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        FacilityCity: "Tiruvannamalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6150,
        Pin: 847451,
        DispatchCenter: "Phulparas_Mahadeomath_D (Bihar)",
        OriginCenter: "Phulparas_Mahadeomath_D (Bihar)",
        FacilityCity: "Phulparas",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6151,
        Pin: 272125,
        DispatchCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        OriginCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        FacilityCity: "Basti",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6152,
        Pin: 414305,
        DispatchCenter: "Alephata_BhrtPetrol_D (Maharashtra)",
        OriginCenter: "Alephata_BhrtPetrol_D (Maharashtra)",
        FacilityCity: "Alephata",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6153,
        Pin: 416205,
        DispatchCenter: "Kale_Harinaryan_D (Maharashtra)",
        OriginCenter: "Kale_Harinaryan_D (Maharashtra)",
        FacilityCity: "Kale",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6154,
        Pin: 227405,
        DispatchCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        OriginCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        FacilityCity: "Amethi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6155,
        Pin: 414606,
        DispatchCenter: "Newasa_Khurd_D (Maharashtra)",
        OriginCenter: "Newasa_Khurd_D (Maharashtra)",
        FacilityCity: "Newasa",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6156,
        Pin: 680585,
        DispatchCenter: "Shoranur_Akamala_D (Kerala)",
        OriginCenter: "Shoranur_Akamala_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6157,
        Pin: 712512,
        DispatchCenter: "Kalna_BsStdDPP_D (West Bengal)",
        OriginCenter: "Kalna_BsStdDPP_D (West Bengal)",
        FacilityCity: "Kalna",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6158,
        Pin: 571250,
        DispatchCenter: "Gonikoppal_Patelnagar_D (Karnataka)",
        OriginCenter: "Gonikoppal_Patelnagar_D (Karnataka)",
        FacilityCity: "Gonikoppal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6159,
        Pin: 641035,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6160,
        Pin: 829112,
        DispatchCenter: "Phusro_Gumia_DPP (Jharkhand)",
        OriginCenter: "Phusro_Gumia_DPP (Jharkhand)",
        FacilityCity: "Phusro",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6161,
        Pin: 621216,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6162,
        Pin: 781121,
        DispatchCenter: "Baihata_Agdala_D (Assam)",
        OriginCenter: "Baihata_Agdala_D (Assam)",
        FacilityCity: "Baihata",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6163,
        Pin: 332022,
        DispatchCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        OriginCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6164,
        Pin: 515282,
        DispatchCenter: "Anantapur_Dharmavaram_D (Andhra Pradesh)",
        OriginCenter: "Anantapur_Dharmavaram_D (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6165,
        Pin: 573120,
        DispatchCenter: "Hassan_Nagathavalli_I (Karnataka)",
        OriginCenter: "Hassan_Nagathavalli_I (Karnataka)",
        FacilityCity: "Hassan",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6166,
        Pin: 305203,
        DispatchCenter: "Beawar_Peesangan_DPP (Rajasthan)",
        OriginCenter: "Beawar_Peesangan_DPP (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6167,
        Pin: 764085,
        DispatchCenter: "Nabarangpr_PhdofDPP_D (Orissa)",
        OriginCenter: "Nabarangpr_PhdofDPP_D (Orissa)",
        FacilityCity: "Nabarangapur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6168,
        Pin: 673534,
        DispatchCenter: "Thamarassery_Raoth_D (Kerala)",
        OriginCenter: "Thamarassery_Raoth_D (Kerala)",
        FacilityCity: "Thamarassery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6169,
        Pin: 400162,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6170,
        Pin: 781326,
        DispatchCenter: "Tihu_Barbari_D (Assam)",
        OriginCenter: "Tihu_Barbari_D (Assam)",
        FacilityCity: "Tihu",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6171,
        Pin: 326520,
        DispatchCenter: "RamganjMandi_AdarshNagar_D (Rajasthan)",
        OriginCenter: "RamganjMandi_AdarshNagar_D (Rajasthan)",
        FacilityCity: "Ramganj Mandi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6172,
        Pin: 632105,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6173,
        Pin: 493555,
        DispatchCenter: "Saraipalli_Baitri_D (Chhattisgarh)",
        OriginCenter: "Saraipalli_Baitri_D (Chhattisgarh)",
        FacilityCity: "Saraipalli",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6174,
        Pin: 249201,
        DispatchCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        OriginCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        FacilityCity: "Rishikesh",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 6175,
        Pin: 671551,
        DispatchCenter: "Kasaragod_Kudlu_D (Kerala)",
        OriginCenter: "Kasaragod_Kudlu_D (Kerala)",
        FacilityCity: "Kasaragod",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6176,
        Pin: 811307,
        DispatchCenter: "Lakhisarai_Central_I_2 (Bihar)",
        OriginCenter: "Lakhisarai_Central_I_2 (Bihar)",
        FacilityCity: "Lakhisarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6177,
        Pin: 784112,
        DispatchCenter: "Thelamara_NilachalPath_D (Assam)",
        OriginCenter: "Thelamara_NilachalPath_D (Assam)",
        FacilityCity: "Thelamara",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6178,
        Pin: 521233,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6179,
        Pin: 757019,
        DispatchCenter: "Udala_Mayurbhanj_DPP (Orissa)",
        OriginCenter: "Udala_Mayurbhanj_DPP (Orissa)",
        FacilityCity: "Udala",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6180,
        Pin: 281003,
        DispatchCenter: "Mathura_Palikheda_D (Uttar Pradesh)",
        OriginCenter: "Mathura_Palikheda_D (Uttar Pradesh)",
        FacilityCity: "Mathura",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6181,
        Pin: 627602,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6182,
        Pin: 501508,
        DispatchCenter: "Ibrahimpatnam_Mbrnagar_D (Telangana)",
        OriginCenter: "Ibrahimpatnam_Mbrnagar_D (Telangana)",
        FacilityCity: "Ibrahimpatnam",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6183,
        Pin: 410204,
        DispatchCenter: "Mumbai_Ariwali_L (Maharashtra)",
        OriginCenter: "Mumbai_Ariwali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6184,
        Pin: 685585,
        DispatchCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        OriginCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        FacilityCity: "Thodupuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6185,
        Pin: 484440,
        DispatchCenter: "Kotma_BaniyaTola_D (Madhya Pradesh)",
        OriginCenter: "Kotma_BaniyaTola_D (Madhya Pradesh)",
        FacilityCity: "Kotma",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 6186,
        Pin: 743429,
        DispatchCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        OriginCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        FacilityCity: "Basirhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6187,
        Pin: 691006,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6188,
        Pin: 713168,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6189,
        Pin: 523240,
        DispatchCenter: "Podili_Sravaniestate_D (Andhra Pradesh)",
        OriginCenter: "Podili_Sravaniestate_D (Andhra Pradesh)",
        FacilityCity: "Podili",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6190,
        Pin: 423305,
        DispatchCenter: "Satana_MitraNagar_D (Maharashtra)",
        OriginCenter: "Satana_MitraNagar_D (Maharashtra)",
        FacilityCity: "Satana",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6191,
        Pin: 384315,
        DispatchCenter: "Vadnagar_BsstdDPP_D (Gujarat)",
        OriginCenter: "Vadnagar_BsstdDPP_D (Gujarat)",
        FacilityCity: "Vadnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6192,
        Pin: 603113,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6193,
        Pin: 248165,
        DispatchCenter: "Herbertpur_Dhkrni_D (Uttarakhand)",
        OriginCenter: "Herbertpur_Dhkrni_D (Uttarakhand)",
        FacilityCity: "Herbertpur",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 6194,
        Pin: 713359,
        DispatchCenter: "Asansol_Gobindapur_D (West Bengal)",
        OriginCenter: "Asansol_Gobindapur_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6195,
        Pin: 812004,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6196,
        Pin: 516173,
        DispatchCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        OriginCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        FacilityCity: "Proddatur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6197,
        Pin: 721605,
        DispatchCenter: "Haldia_Basudevpur_D (West Bengal)",
        OriginCenter: "Haldia_Basudevpur_D (West Bengal)",
        FacilityCity: "Haldia",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6198,
        Pin: 122503,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6199,
        Pin: 516218,
        DispatchCenter: "Vempalli_Kathaluru_D (Andhra Pradesh)",
        OriginCenter: "Vempalli_Kathaluru_D (Andhra Pradesh)",
        FacilityCity: "Vempalli",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6200,
        Pin: 680545,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6201,
        Pin: 781130,
        DispatchCenter: "Guwahati_Kaikchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6202,
        Pin: 577412,
        DispatchCenter: "Thirthahalli_Ripponpet_DPP (Karnataka)",
        OriginCenter: "Thirthahalli_Ripponpet_DPP (Karnataka)",
        FacilityCity: "Thirthahalli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6203,
        Pin: 192124,
        DispatchCenter: "Anantnag_Bijbehara_D (Jammu & Kashmir)",
        OriginCenter: "Anantnag_Bijbehara_D (Jammu & Kashmir)",
        FacilityCity: "Anantnag",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6204,
        Pin: 152126,
        DispatchCenter: "Abohar_DaulatPura_D (Punjab)",
        OriginCenter: "Abohar_DaulatPura_D (Punjab)",
        FacilityCity: "Abohar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 6205,
        Pin: 205119,
        DispatchCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        OriginCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        FacilityCity: "Mainpuri",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6206,
        Pin: 560008,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6207,
        Pin: 416616,
        DispatchCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        OriginCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        FacilityCity: "Malvan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6208,
        Pin: 788120,
        DispatchCenter: "Bhaga_Khashpur_D (Assam)",
        OriginCenter: "Bhaga_Khashpur_D (Assam)",
        FacilityCity: "Bhaga",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6209,
        Pin: 303119,
        DispatchCenter: "Paota_HoliChowk_DPP (Rajasthan)",
        OriginCenter: "Paota_HoliChowk_DPP (Rajasthan)",
        FacilityCity: "Paota",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6210,
        Pin: 685581,
        DispatchCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        OriginCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        FacilityCity: "Thodupuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6211,
        Pin: 466446,
        DispatchCenter: "Nasrullaganj_RujanKhedi_D (Madhya Pradesh)",
        OriginCenter: "Nasrullaganj_RujanKhedi_D (Madhya Pradesh)",
        FacilityCity: "Nasrullaganj",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 6212,
        Pin: 635852,
        DispatchCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        OriginCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        FacilityCity: "Tiruppattur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6213,
        Pin: 272182,
        DispatchCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        OriginCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        FacilityCity: "Basti",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6214,
        Pin: 691544,
        DispatchCenter: "Kottarakkara_MythriNagar_D (Kerala)",
        OriginCenter: "Kottarakkara_MythriNagar_D (Kerala)",
        FacilityCity: "Kottarakkara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6215,
        Pin: 456337,
        DispatchCenter: "Nagda_PrkashColny_D (Madhya Pradesh)",
        OriginCenter: "Nagda_PrkashColny_D (Madhya Pradesh)",
        FacilityCity: "Nagda",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6216,
        Pin: 624304,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6217,
        Pin: 763008,
        DispatchCenter: "Semiliguda_HimgiriNagar_D (Orissa)",
        OriginCenter: "Semiliguda_HimgiriNagar_D (Orissa)",
        FacilityCity: "Semiliguda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6218,
        Pin: 444201,
        DispatchCenter: "JalgaonJamod_Tatrpur_DPP (Maharashtra)",
        OriginCenter: "JalgaonJamod_Tatrpur_DPP (Maharashtra)",
        FacilityCity: "Jalgaon Jamod",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6219,
        Pin: 413115,
        DispatchCenter: "Baramati_Pragatinagar_D (Maharashtra)",
        OriginCenter: "Baramati_Pragatinagar_D (Maharashtra)",
        FacilityCity: "Baramati",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6220,
        Pin: 152001,
        DispatchCenter: "Ferozepur_DC (Punjab)",
        OriginCenter: "Ferozepur_DC (Punjab)",
        FacilityCity: "Firozpur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6221,
        Pin: 736208,
        DispatchCenter: "CoochBehar_khagrabari_I (West Bengal)",
        OriginCenter: "CoochBehar_khagrabari_I (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6222,
        Pin: 620019,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6223,
        Pin: 624305,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6224,
        Pin: 110091,
        DispatchCenter: "Delhi_RailYardRd_L (Delhi)",
        OriginCenter: "Delhi_RailYardRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6225,
        Pin: 732207,
        DispatchCenter: "Kaliachak_LICDPP_D (West Bengal)",
        OriginCenter: "Kaliachak_LICDPP_D (West Bengal)",
        FacilityCity: "Kaliachak",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6226,
        Pin: 383430,
        DispatchCenter: "Idar_GIDC_D (Gujarat)",
        OriginCenter: "Idar_GIDC_D (Gujarat)",
        FacilityCity: "Idar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6227,
        Pin: 853201,
        DispatchCenter: "Naugchia_Vijayght_D (Bihar)",
        OriginCenter: "Naugchia_Vijayght_D (Bihar)",
        FacilityCity: "Naugachia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6228,
        Pin: 506142,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6229,
        Pin: 610004,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6230,
        Pin: 786004,
        DispatchCenter: "Dibrugarh_Mohanbari_H (Assam)",
        OriginCenter: "Dibrugarh_Mohanbari_H (Assam)",
        FacilityCity: "Dibrugarh",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6231,
        Pin: 224155,
        DispatchCenter: "Tanda_HayatganjChowk_D (Uttar Pradesh)",
        OriginCenter: "Tanda_HayatganjChowk_D (Uttar Pradesh)",
        FacilityCity: "Tanda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6232,
        Pin: 506101,
        DispatchCenter: "Mahabubabad_SnehaNagar_D (Telangana)",
        OriginCenter: "Mahabubabad_SnehaNagar_D (Telangana)",
        FacilityCity: "Mahabubabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6233,
        Pin: 784178,
        DispatchCenter: "Gohpur_Central_D_1 (Assam)",
        OriginCenter: "Gohpur_Central_D_1 (Assam)",
        FacilityCity: "Gohpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6234,
        Pin: 136038,
        DispatchCenter: "Kurukshetra_DDColony_D (Haryana)",
        OriginCenter: "Kurukshetra_DDColony_D (Haryana)",
        FacilityCity: "Kurukshetra",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6235,
        Pin: 766107,
        DispatchCenter: "Komana_Raghupali_D (Orissa)",
        OriginCenter: "Komana_Raghupali_D (Orissa)",
        FacilityCity: "Komana",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6236,
        Pin: 752081,
        DispatchCenter: "Nayagarh_Mathakhajuria_D (Orissa)",
        OriginCenter: "Nayagarh_Mathakhajuria_D (Orissa)",
        FacilityCity: "Nayagarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6237,
        Pin: 824303,
        DispatchCenter: "Kanker_JaplaBarunRd_DPP (Bihar)",
        OriginCenter: "Kanker_JaplaBarunRd_DPP (Bihar)",
        FacilityCity: "KankerBR",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6238,
        Pin: 625110,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6239,
        Pin: 311603,
        DispatchCenter: "Shahpura_JhajpurRd_DPP (Rajasthan)",
        OriginCenter: "Shahpura_JhajpurRd_DPP (Rajasthan)",
        FacilityCity: "Shahpura",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6240,
        Pin: 781336,
        DispatchCenter: "Nalbari_Chokbazar_D (Assam)",
        OriginCenter: "Nalbari_Chokbazar_D (Assam)",
        FacilityCity: "Nalbari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6241,
        Pin: 332023,
        DispatchCenter: "Ramgarh_Khoor_DPP (Rajasthan)",
        OriginCenter: "Ramgarh_Khoor_DPP (Rajasthan)",
        FacilityCity: "Ramgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6242,
        Pin: 501301,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6243,
        Pin: 414703,
        DispatchCenter: "Daund_Kurkumbh_L (Maharashtra)",
        OriginCenter: "Daund_Kurkumbh_L (Maharashtra)",
        FacilityCity: "Daund",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6244,
        Pin: 627812,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6245,
        Pin: 572226,
        DispatchCenter: "Tiptur_Chmundeshwri_D (Karnataka)",
        OriginCenter: "Tiptur_Chmundeshwri_D (Karnataka)",
        FacilityCity: "Tiptur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6246,
        Pin: 451666,
        DispatchCenter: "Sendhwa_JawaharGanj_I (Madhya Pradesh)",
        OriginCenter: "Sendhwa_JawaharGanj_I (Madhya Pradesh)",
        FacilityCity: "Sendhwa",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6247,
        Pin: 721152,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6248,
        Pin: 518442,
        DispatchCenter: "Atmakur_KGRd_D (Andhra Pradesh)",
        OriginCenter: "Atmakur_KGRd_D (Andhra Pradesh)",
        FacilityCity: "Atmakur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6249,
        Pin: 414103,
        DispatchCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        OriginCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        FacilityCity: "Ahmednagar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6250,
        Pin: 754160,
        DispatchCenter: "Tirtol_Central_D_2 (Orissa)",
        OriginCenter: "Tirtol_Central_D_2 (Orissa)",
        FacilityCity: "Tirtol",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6251,
        Pin: 843360,
        DispatchCenter: "Aurai_College_D (Bihar)",
        OriginCenter: "Aurai_College_D (Bihar)",
        FacilityCity: "Aurai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6252,
        Pin: 400606,
        DispatchCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6253,
        Pin: 246401,
        DispatchCenter: "Chamoli_CGRoad_D (Uttarakhand)",
        OriginCenter: "Chamoli_CGRoad_D (Uttarakhand)",
        FacilityCity: "Chamoli",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 6254,
        Pin: 625218,
        DispatchCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        OriginCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        FacilityCity: "Vadipatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6255,
        Pin: 581119,
        DispatchCenter: "Hirekerur_Raghavendracolony_D (Karnataka)",
        OriginCenter: "Hirekerur_Raghavendracolony_D (Karnataka)",
        FacilityCity: "Hirekerur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6256,
        Pin: 781023,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6257,
        Pin: 628615,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6258,
        Pin: 580026,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6259,
        Pin: 225403,
        DispatchCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        OriginCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        FacilityCity: "Dariyabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6260,
        Pin: 491668,
        DispatchCenter: "AmbagarhChowki_Ward14_D (Chhattisgarh)",
        OriginCenter: "AmbagarhChowki_Ward14_D (Chhattisgarh)",
        FacilityCity: "AmbagarhChowki",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6261,
        Pin: 212651,
        DispatchCenter: "Fatehpur_Radhanagar_D (Uttar Pradesh)",
        OriginCenter: "Fatehpur_Radhanagar_D (Uttar Pradesh)",
        FacilityCity: "Fatehpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6262,
        Pin: 560084,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6263,
        Pin: 799204,
        DispatchCenter: "Aambasa_Natunpalli_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Aambasa",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6264,
        Pin: 382732,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6265,
        Pin: 202142,
        DispatchCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        OriginCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        FacilityCity: "Khair",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6266,
        Pin: 506105,
        DispatchCenter: "Mahabubabad_SnehaNagar_D (Telangana)",
        OriginCenter: "Mahabubabad_SnehaNagar_D (Telangana)",
        FacilityCity: "Mahabubabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6267,
        Pin: 160063,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6268,
        Pin: 452020,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6269,
        Pin: 784522,
        DispatchCenter: "Udalguri_Milonpur_D (Assam)",
        OriginCenter: "Udalguri_Milonpur_D (Assam)",
        FacilityCity: "Mangaldoi",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6270,
        Pin: 122052,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6271,
        Pin: 625105,
        DispatchCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        OriginCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        FacilityCity: "Melur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6272,
        Pin: 643224,
        DispatchCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        OriginCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        FacilityCity: "Gudalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6273,
        Pin: 523292,
        DispatchCenter: "Singarayakonda_TrunkRd_D (Andhra Pradesh)",
        OriginCenter: "Singarayakonda_TrunkRd_D (Andhra Pradesh)",
        FacilityCity: "Singarayakonda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6274,
        Pin: 370615,
        DispatchCenter: "Nakhatrana_Dhormnath_DPP (Gujarat)",
        OriginCenter: "Nakhatrana_Dhormnath_DPP (Gujarat)",
        FacilityCity: "Nakhatrana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6275,
        Pin: 362230,
        DispatchCenter: "Madhavpur_Osoashrm_DPP (Gujarat)",
        OriginCenter: "Madhavpur_Osoashrm_DPP (Gujarat)",
        FacilityCity: "Madhavpur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6276,
        Pin: 581327,
        DispatchCenter: "Honnavar_ForestColony_D (Karnataka)",
        OriginCenter: "Honnavar_ForestColony_D (Karnataka)",
        FacilityCity: "Honnavar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6277,
        Pin: 854329,
        DispatchCenter: "Araria_BusStand_D (Bihar)",
        OriginCenter: "Araria_BusStand_D (Bihar)",
        FacilityCity: "Araria",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6278,
        Pin: 688521,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6279,
        Pin: 638060,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        Zone : "S1"
      },
      {
        SN: 6280,
        Pin: 261506,
        DispatchCenter: "Lakhimpur_FunMall_D (Uttar Pradesh)",
        OriginCenter: "Lakhimpur_FunMall_D (Uttar Pradesh)",
        FacilityCity: "Lakhimpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6281,
        Pin: 723202,
        DispatchCenter: "Jhalda_GMukrDPP_D (West Bengal)",
        OriginCenter: "Jhalda_GMukrDPP_D (West Bengal)",
        FacilityCity: "Jhalda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6282,
        Pin: 574158,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6283,
        Pin: 508222,
        DispatchCenter: "Tungaturthi_CanaraBank_D (Telangana)",
        OriginCenter: "Tungaturthi_CanaraBank_D (Telangana)",
        FacilityCity: "Tungaturthi",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6284,
        Pin: 362222,
        DispatchCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        OriginCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        FacilityCity: "Keshod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6285,
        Pin: 521162,
        DispatchCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        OriginCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        FacilityCity: "Machilipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6286,
        Pin: 518594,
        DispatchCenter: "Allagadda_Ramalayam_DPP (Andhra Pradesh)",
        OriginCenter: "Allagadda_Ramalayam_DPP (Andhra Pradesh)",
        FacilityCity: "Allagadda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6287,
        Pin: 431519,
        DispatchCenter: "Ambajogai_Bansilalnagar_D (Maharashtra)",
        OriginCenter: "Ambajogai_Bansilalnagar_D (Maharashtra)",
        FacilityCity: "Ambajogai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6288,
        Pin: 800011,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6289,
        Pin: 577111,
        DispatchCenter: "Chikmagalur_Jayanagara_D (Karnataka)",
        OriginCenter: "Chikmagalur_Jayanagara_D (Karnataka)",
        FacilityCity: "Chikkamagalur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6290,
        Pin: 614711,
        DispatchCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        FacilityCity: "Thiruthuraipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6291,
        Pin: 501501,
        DispatchCenter: "Vikarabad_Pargi1_D (Telangana)",
        OriginCenter: "Vikarabad_Pargi1_D (Telangana)",
        FacilityCity: "Vikarabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6292,
        Pin: 671322,
        DispatchCenter: "Manjeshwar_Morathana_D (Kerala)",
        OriginCenter: "Manjeshwar_Morathana_D (Kerala)",
        FacilityCity: "Manjeshwar",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6293,
        Pin: 613502,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6294,
        Pin: 123023,
        DispatchCenter: "Narnaul_Keshavnagar_D (Haryana)",
        OriginCenter: "Narnaul_Keshavnagar_D (Haryana)",
        FacilityCity: "Narnaul",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6295,
        Pin: 533436,
        DispatchCenter: "Koyyuru_KoyuruRd_DPP (Andhra Pradesh)",
        OriginCenter: "Koyyuru_KoyuruRd_DPP (Andhra Pradesh)",
        FacilityCity: "Koyyuru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6296,
        Pin: 363310,
        DispatchCenter: "Dhrangadhra_Jogasar_DPP (Gujarat)",
        OriginCenter: "Dhrangadhra_Jogasar_DPP (Gujarat)",
        FacilityCity: "Dhrangadhra",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6297,
        Pin: 276404,
        DispatchCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6298,
        Pin: 210209,
        DispatchCenter: "Shankargarh_Mau_D (Uttar Pradesh)",
        OriginCenter: "Shankargarh_Mau_D (Uttar Pradesh)",
        FacilityCity: "Shankargarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6299,
        Pin: 574214,
        DispatchCenter: "Belthangady_UjreMnRd_DPP (Karnataka)",
        OriginCenter: "Belthangady_UjreMnRd_DPP (Karnataka)",
        FacilityCity: "Belthangady",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6300,
        Pin: 624621,
        DispatchCenter: "Palani_Sathyanagar_D (Tamil Nadu)",
        OriginCenter: "Palani_Sathyanagar_D (Tamil Nadu)",
        FacilityCity: "Palani",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6301,
        Pin: 304023,
        DispatchCenter: "Nainwa_Uniyara_D (Rajasthan)",
        OriginCenter: "Nainwa_Uniyara_D (Rajasthan)",
        FacilityCity: "Nainwa",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6302,
        Pin: 785699,
        DispatchCenter: "Bokakhat_Kumaraniati_D (Assam)",
        OriginCenter: "Bokakhat_Kumaraniati_D (Assam)",
        FacilityCity: "Bokakhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6303,
        Pin: 574162,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6304,
        Pin: 495552,
        DispatchCenter: "Akaltara_IndraUdyan_D (Chhattisgarh)",
        OriginCenter: "Akaltara_IndraUdyan_D (Chhattisgarh)",
        FacilityCity: "Akaltara",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6305,
        Pin: 574236,
        DispatchCenter: "Moodbidri_Central_D (Karnataka)",
        OriginCenter: "Moodbidri_Central_D (Karnataka)",
        FacilityCity: "Moodabidri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6306,
        Pin: 412204,
        DispatchCenter: "Daund_Kurkumbh_L (Maharashtra)",
        OriginCenter: "Daund_Kurkumbh_L (Maharashtra)",
        FacilityCity: "Daund",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6307,
        Pin: 205125,
        DispatchCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        OriginCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        FacilityCity: "Shikohabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6308,
        Pin: 767039,
        DispatchCenter: "Kantabanji_Ward4_D (Orissa)",
        OriginCenter: "Kantabanji_Ward4_D (Orissa)",
        FacilityCity: "Kantabanji",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6309,
        Pin: 442203,
        DispatchCenter: "Talegaon_Ramdara_D (Maharashtra)",
        OriginCenter: "Talegaon_Ramdara_D (Maharashtra)",
        FacilityCity: "Talegaon Sp",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6310,
        Pin: 587134,
        DispatchCenter: "Mahalingpur_Bagalkot_D (Karnataka)",
        OriginCenter: "Mahalingpur_Bagalkot_D (Karnataka)",
        FacilityCity: "Mahalingpur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6311,
        Pin: 363035,
        DispatchCenter: "Surendranagar_GIDC_D (Gujarat)",
        OriginCenter: "Surendranagar_GIDC_D (Gujarat)",
        FacilityCity: "Surendranagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6312,
        Pin: 202520,
        DispatchCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        OriginCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        FacilityCity: "Chandausi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6313,
        Pin: 628902,
        DispatchCenter: "Vilathikulam_Thoothukudi_DPP (Tamil Nadu)",
        OriginCenter: "Vilathikulam_Thoothukudi_DPP (Tamil Nadu)",
        FacilityCity: "Vilathikulam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6314,
        Pin: 585201,
        DispatchCenter: "Yadgir_Chiranjeevinagar_D (Karnataka)",
        OriginCenter: "Yadgir_Chiranjeevinagar_D (Karnataka)",
        FacilityCity: "Yadgir",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6315,
        Pin: 765001,
        DispatchCenter: "Raygada_GandhiNgr_DPP (Orissa)",
        OriginCenter: "Raygada_GandhiNgr_DPP (Orissa)",
        FacilityCity: "Raygada",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6316,
        Pin: 516108,
        DispatchCenter: "Koduru_Jagadampalli_D (Andhra Pradesh)",
        OriginCenter: "Koduru_Jagadampalli_D (Andhra Pradesh)",
        FacilityCity: "Koduru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6317,
        Pin: 638151,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6318,
        Pin: 637306,
        DispatchCenter: "Tiruchengode_Kailasmplym_D (Tamil Nadu)",
        OriginCenter: "Tiruchengode_Kailasmplym_D (Tamil Nadu)",
        FacilityCity: "Tiruchengode",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6319,
        Pin: 643242,
        DispatchCenter: "Ooty_Manjoor_D (Tamil Nadu)",
        OriginCenter: "Ooty_Manjoor_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6320,
        Pin: 497225,
        DispatchCenter: "Wadrafnagar_Ward10_D (Chhattisgarh)",
        OriginCenter: "Wadrafnagar_Ward10_D (Chhattisgarh)",
        FacilityCity: "Wadrafnagar",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6321,
        Pin: 757102,
        DispatchCenter: "RajBerhampur_Gohira_DPP (Orissa)",
        OriginCenter: "RajBerhampur_Gohira_DPP (Orissa)",
        FacilityCity: "Raj Berhampur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6322,
        Pin: 517325,
        DispatchCenter: "Madanapalle_RNaiduClny_D (Andhra Pradesh)",
        OriginCenter: "Madanapalle_RNaiduClny_D (Andhra Pradesh)",
        FacilityCity: "Madanapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6323,
        Pin: 414201,
        DispatchCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        OriginCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        FacilityCity: "Ahmednagar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6324,
        Pin: 283126,
        DispatchCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        OriginCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        FacilityCity: "Agra",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6325,
        Pin: 209743,
        DispatchCenter: "Chhibramau_TajpurRd_D (Uttar Pradesh)",
        OriginCenter: "Chhibramau_TajpurRd_D (Uttar Pradesh)",
        FacilityCity: "Chhibramau",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6326,
        Pin: 174302,
        DispatchCenter: "UnaHP_NangalRd_D (Himachal Pradesh)",
        OriginCenter: "UnaHP_NangalRd_D (Himachal Pradesh)",
        FacilityCity: "Una-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 6327,
        Pin: 382340,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6328,
        Pin: 688008,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6329,
        Pin: 583213,
        DispatchCenter: "Harpanahalli_KHBClny_D (Karnataka)",
        OriginCenter: "Harpanahalli_KHBClny_D (Karnataka)",
        FacilityCity: "Harpanahalli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6330,
        Pin: 360577,
        DispatchCenter: "Porbandar_Vadiplot_D (Gujarat)",
        OriginCenter: "Porbandar_Vadiplot_D (Gujarat)",
        FacilityCity: "Porbandar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6331,
        Pin: 416544,
        DispatchCenter: "Shrigonda_Adhalgaon_D (Maharashtra)",
        OriginCenter: "Shrigonda_Adhalgaon_D (Maharashtra)",
        FacilityCity: "Shrigonda",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6332,
        Pin: 304803,
        DispatchCenter: "DeoliRJ_AmbedkarColny_DPP (Rajasthan)",
        OriginCenter: "DeoliRJ_AmbedkarColny_DPP (Rajasthan)",
        FacilityCity: "Deoli-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6333,
        Pin: 673020,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6334,
        Pin: 314001,
        DispatchCenter: "Dungarpur_IrolZoneCmplx_DPP (Rajasthan)",
        OriginCenter: "Dungarpur_IrolZoneCmplx_DPP (Rajasthan)",
        FacilityCity: "Dungarpur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6335,
        Pin: 577548,
        DispatchCenter: "Kadur_UBRoad_D (Karnataka)",
        OriginCenter: "Kadur_UBRoad_D (Karnataka)",
        FacilityCity: "Kadur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6336,
        Pin: 560242,
        DispatchCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        OriginCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6337,
        Pin: 460001,
        DispatchCenter: "Betul_Bheemnagar_D (Madhya Pradesh)",
        OriginCenter: "Betul_Bheemnagar_D (Madhya Pradesh)",
        FacilityCity: "Betul",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 6338,
        Pin: 144034,
        DispatchCenter: "Phagwara_PurewalNgr_D (Punjab)",
        OriginCenter: "Phagwara_PurewalNgr_D (Punjab)",
        FacilityCity: "Phagwara",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 6339,
        Pin: 560045,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6340,
        Pin: 572215,
        DispatchCenter: "Turuvukere_Havala_D (Karnataka)",
        OriginCenter: "Turuvukere_Havala_D (Karnataka)",
        FacilityCity: "Turuvukere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6341,
        Pin: 445201,
        DispatchCenter: "Yavatmal_Ner_D (Maharashtra)",
        OriginCenter: "Yavatmal_Ner_D (Maharashtra)",
        FacilityCity: "Yavatmal",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6342,
        Pin: 628903,
        DispatchCenter: "Vilathikulam_Thoothukudi_DPP (Tamil Nadu)",
        OriginCenter: "Vilathikulam_Thoothukudi_DPP (Tamil Nadu)",
        FacilityCity: "Vilathikulam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6343,
        Pin: 591226,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6344,
        Pin: 364120,
        DispatchCenter: "Talaja_Shivajinagar_D (Gujarat)",
        OriginCenter: "Talaja_Shivajinagar_D (Gujarat)",
        FacilityCity: "Talaja",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6345,
        Pin: 562201,
        DispatchCenter: "Magadi_Hosapete_D (Karnataka)",
        OriginCenter: "Magadi_Hosapete_D (Karnataka)",
        FacilityCity: "Magadi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6346,
        Pin: 621722,
        DispatchCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        OriginCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        FacilityCity: "Thirukkattupalli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6347,
        Pin: 140406,
        DispatchCenter: "SirhindFatehgarh_DC (Punjab)",
        OriginCenter: "SirhindFatehgarh_DC (Punjab)",
        FacilityCity: "Sirhind-Fategarh",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 6348,
        Pin: 670017,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6349,
        Pin: 444304,
        DispatchCenter: "Nandura_Qureshinagar_DPP (Maharashtra)",
        OriginCenter: "Nandura_Qureshinagar_DPP (Maharashtra)",
        FacilityCity: "Nandura",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6350,
        Pin: 442406,
        DispatchCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        OriginCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        FacilityCity: "Chandrapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6351,
        Pin: 533461,
        DispatchCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        OriginCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        FacilityCity: "Kakinada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6352,
        Pin: 635211,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6353,
        Pin: 671317,
        DispatchCenter: "Poinachi_Central_D (Kerala)",
        OriginCenter: "Poinachi_Central_D (Kerala)",
        FacilityCity: "Poinachi",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6354,
        Pin: 785660,
        DispatchCenter: "Sivasagar_Pdinnagar_D (Assam)",
        OriginCenter: "Sivasagar_Pdinnagar_D (Assam)",
        FacilityCity: "Sivasagar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6355,
        Pin: 688547,
        DispatchCenter: "Cochin_Aroor_D (Kerala)",
        OriginCenter: "Cochin_Aroor_D (Kerala)",
        FacilityCity: "Ezhupunna",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6356,
        Pin: 572133,
        DispatchCenter: "Madhugiri_SBI_DPP (Karnataka)",
        OriginCenter: "Madhugiri_SBI_DPP (Karnataka)",
        FacilityCity: "Madhugiri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6357,
        Pin: 585103,
        DispatchCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        OriginCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        FacilityCity: "Gulbarga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6358,
        Pin: 630565,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6359,
        Pin: 209310,
        DispatchCenter: "Rasulabad_ShastriNgr_D (Uttar Pradesh)",
        OriginCenter: "Rasulabad_ShastriNgr_D (Uttar Pradesh)",
        FacilityCity: "Rasulabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6360,
        Pin: 605109,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6361,
        Pin: 686535,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6362,
        Pin: 247774,
        DispatchCenter: "Shamli_SalekChandVihar_D (Uttar Pradesh)",
        OriginCenter: "Shamli_SalekChandVihar_D (Uttar Pradesh)",
        FacilityCity: "Shamli",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6363,
        Pin: 152114,
        DispatchCenter: "Malout_DC (Punjab)",
        OriginCenter: "Malout_DC (Punjab)",
        FacilityCity: "Malout",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6364,
        Pin: 614716,
        DispatchCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        FacilityCity: "Thiruthuraipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6365,
        Pin: 322241,
        DispatchCenter: "Karauli_Subhashnagar_D (Rajasthan)",
        OriginCenter: "Karauli_Subhashnagar_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6366,
        Pin: 626166,
        DispatchCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        OriginCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        FacilityCity: "Sivakasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6367,
        Pin: 305201,
        DispatchCenter: "Beawar_Peesangan_DPP (Rajasthan)",
        OriginCenter: "Beawar_Peesangan_DPP (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6368,
        Pin: 627412,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6369,
        Pin: 415021,
        DispatchCenter: "Koregaon_Lokmanyanagar_D (Maharashtra)",
        OriginCenter: "Koregaon_Lokmanyanagar_D (Maharashtra)",
        FacilityCity: "Koregaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6370,
        Pin: 411014,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6371,
        Pin: 829119,
        DispatchCenter: "Patratu_Saundabasti_D (Jharkhand)",
        OriginCenter: "Patratu_Saundabasti_D (Jharkhand)",
        FacilityCity: "Patratu",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6372,
        Pin: 689678,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6373,
        Pin: 533339,
        DispatchCenter: "Rampachodavaram_Horeb_DPP (Andhra Pradesh)",
        OriginCenter: "Rampachodavaram_Horeb_DPP (Andhra Pradesh)",
        FacilityCity: "Rampachodavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6374,
        Pin: 411230,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6375,
        Pin: 673004,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6376,
        Pin: 415115,
        DispatchCenter: "Karad_Malkapur1_D (Maharashtra)",
        OriginCenter: "Karad_Malkapur1_D (Maharashtra)",
        FacilityCity: "Karad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6377,
        Pin: 768220,
        DispatchCenter: "Jharsuguda_Ekatali_D (Orissa)",
        OriginCenter: "Jharsuguda_Ekatali_D (Orissa)",
        FacilityCity: "Jharsuguda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6378,
        Pin: 790102,
        DispatchCenter: "Seppa_Rajawacolony_D (Arunachal Pradesh)",
        OriginCenter: "Seppa_Rajawacolony_D (Arunachal Pradesh)",
        FacilityCity: "Seppa",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6379,
        Pin: 523315,
        DispatchCenter: "Markapur_VidyaNgr_D (Andhra Pradesh)",
        OriginCenter: "Markapur_VidyaNgr_D (Andhra Pradesh)",
        FacilityCity: "Markapur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6380,
        Pin: 127026,
        DispatchCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        OriginCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        FacilityCity: "Charkhi Dadri",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6381,
        Pin: 639109,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6382,
        Pin: 712617,
        DispatchCenter: "Khanakul_Antngr_D (West Bengal)",
        OriginCenter: "Khanakul_Antngr_D (West Bengal)",
        FacilityCity: "Khanakul",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6383,
        Pin: 160050,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6384,
        Pin: 222165,
        DispatchCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        OriginCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        FacilityCity: "Mungra Badshahpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6385,
        Pin: 524003,
        DispatchCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        OriginCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6386,
        Pin: 403601,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6387,
        Pin: 506348,
        DispatchCenter: "Parkal_SrinivasaColony_D (Telangana)",
        OriginCenter: "Parkal_SrinivasaColony_D (Telangana)",
        FacilityCity: "Parkal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6388,
        Pin: 516505,
        DispatchCenter: "Badvel_CCcolony_D (Andhra Pradesh)",
        OriginCenter: "Badvel_CCcolony_D (Andhra Pradesh)",
        FacilityCity: "Badvel",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6389,
        Pin: 671318,
        DispatchCenter: "Poinachi_Central_D (Kerala)",
        OriginCenter: "Poinachi_Central_D (Kerala)",
        FacilityCity: "Poinachi",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6390,
        Pin: 802352,
        DispatchCenter: "Arrah_Sakaddi_D (Bihar)",
        OriginCenter: "Arrah_Sakaddi_D (Bihar)",
        FacilityCity: "Arrah",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6391,
        Pin: 124105,
        DispatchCenter: "Gurgaon_Farukhnagar_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6392,
        Pin: 387530,
        DispatchCenter: "Matar_CityCenter_DPP (Gujarat)",
        OriginCenter: "Matar_CityCenter_DPP (Gujarat)",
        FacilityCity: "Matar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6393,
        Pin: 700141,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6394,
        Pin: 460225,
        DispatchCenter: "Betul_Bheemnagar_D (Madhya Pradesh)",
        OriginCenter: "Betul_Bheemnagar_D (Madhya Pradesh)",
        FacilityCity: "Betul",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 6395,
        Pin: 713214,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6396,
        Pin: 302001,
        DispatchCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6397,
        Pin: 401106,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6398,
        Pin: 847212,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6399,
        Pin: 683511,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6400,
        Pin: 686007,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6401,
        Pin: 643218,
        DispatchCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        OriginCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6402,
        Pin: 203390,
        DispatchCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        OriginCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        FacilityCity: "Anupshahar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6403,
        Pin: 497226,
        DispatchCenter: "Surajapur_Chandarpur_D (Chhattisgarh)",
        OriginCenter: "Surajapur_Chandarpur_D (Chhattisgarh)",
        FacilityCity: "Surajapur",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6404,
        Pin: 845408,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6405,
        Pin: 531149,
        DispatchCenter: "Araku_Ravvalaguda2_D (Andhra Pradesh)",
        OriginCenter: "Araku_Ravvalaguda2_D (Andhra Pradesh)",
        FacilityCity: "Araku",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6406,
        Pin: 802119,
        DispatchCenter: "Dumraon_MahvidylaRd_D (Bihar)",
        OriginCenter: "Dumraon_MahvidylaRd_D (Bihar)",
        FacilityCity: "Dumraon",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6407,
        Pin: 174314,
        DispatchCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        OriginCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        FacilityCity: "Amb",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6408,
        Pin: 422615,
        DispatchCenter: "Sangamner_Ksarwdi_D (Maharashtra)",
        OriginCenter: "Sangamner_Ksarwdi_D (Maharashtra)",
        FacilityCity: "Sangamner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6409,
        Pin: 581186,
        DispatchCenter: "Dandeli_RKComplx_D (Karnataka)",
        OriginCenter: "Dandeli_RKComplx_D (Karnataka)",
        FacilityCity: "Dandeli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6410,
        Pin: 600112,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6411,
        Pin: 392030,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6412,
        Pin: 686677,
        DispatchCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        OriginCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        FacilityCity: "Muvattupuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6413,
        Pin: 761041,
        DispatchCenter: "Kodala_Beguniapada_DPP (Orissa)",
        OriginCenter: "Kodala_Beguniapada_DPP (Orissa)",
        FacilityCity: "Kodala",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6414,
        Pin: 614018,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6415,
        Pin: 680572,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6416,
        Pin: 632521,
        DispatchCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ranipet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6417,
        Pin: 683504,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6418,
        Pin: 586112,
        DispatchCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        OriginCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        FacilityCity: "Indi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6419,
        Pin: 630566,
        DispatchCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        OriginCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        FacilityCity: "Melur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6420,
        Pin: 413405,
        DispatchCenter: "Osmanabad_RajasabH_D (Maharashtra)",
        OriginCenter: "Osmanabad_RajasabH_D (Maharashtra)",
        FacilityCity: "Osmanabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6421,
        Pin: 110094,
        DispatchCenter: "Delhi_JhilmilColony_L (Delhi)",
        OriginCenter: "Delhi_JhilmilColony_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6422,
        Pin: 641014,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6423,
        Pin: 431152,
        DispatchCenter: "Aurangabad_Hamalwadi_DPP (Maharashtra)",
        OriginCenter: "Aurangabad_Hamalwadi_DPP (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6424,
        Pin: 331507,
        DispatchCenter: "Sujangarh_Khanpur_D (Rajasthan)",
        OriginCenter: "Sujangarh_Khanpur_D (Rajasthan)",
        FacilityCity: "Sujangarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6425,
        Pin: 516464,
        DispatchCenter: "Tadipatri_Kondapuram_D (Andhra Pradesh)",
        OriginCenter: "Tadipatri_Kondapuram_D (Andhra Pradesh)",
        FacilityCity: "Tadipatri",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6426,
        Pin: 335704,
        DispatchCenter: "Srivijaynagar_Ward8_DPP (Rajasthan)",
        OriginCenter: "Srivijaynagar_Ward8_DPP (Rajasthan)",
        FacilityCity: "Srivijaynagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6427,
        Pin: 571463,
        DispatchCenter: "Malavalli_KBHclny_D (Karnataka)",
        OriginCenter: "Malavalli_KBHclny_D (Karnataka)",
        FacilityCity: "Malavalli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6428,
        Pin: 791122,
        DispatchCenter: "Daporijo_TransportColny_D (Jharkhand)",
        OriginCenter: "Daporijo_TransportColny_D (Jharkhand)",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6429,
        Pin: 500007,
        DispatchCenter: "Hyderabad_HemaNagar_L (Telangana)",
        OriginCenter: "Hyderabad_HemaNagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6430,
        Pin: 500037,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6431,
        Pin: 695032,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6432,
        Pin: 624612,
        DispatchCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        OriginCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        FacilityCity: "Oddanchatram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6433,
        Pin: 711409,
        DispatchCenter: "Howrah_Kamrangu_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6434,
        Pin: 400108,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6435,
        Pin: 637405,
        DispatchCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        OriginCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        FacilityCity: "Namakkal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6436,
        Pin: 414003,
        DispatchCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        OriginCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        FacilityCity: "Ahmednagar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6437,
        Pin: 563116,
        DispatchCenter: "Bethamangala_Sriramatemp_D (Karnataka)",
        OriginCenter: "Bethamangala_Sriramatemp_D (Karnataka)",
        FacilityCity: "Bethamangala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6438,
        Pin: 360320,
        DispatchCenter: "Gondal_Maninagar_D (Gujarat)",
        OriginCenter: "Gondal_Maninagar_D (Gujarat)",
        FacilityCity: "Gondal",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6439,
        Pin: 574153,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6440,
        Pin: 416425,
        DispatchCenter: "Sangli_Dattanagar_D (Maharashtra)",
        OriginCenter: "Sangli_Dattanagar_D (Maharashtra)",
        FacilityCity: "Sangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6441,
        Pin: 190003,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6442,
        Pin: 585221,
        DispatchCenter: "Yadgir_Taicolony_DPP (Karnataka)",
        OriginCenter: "Yadgir_Taicolony_DPP (Karnataka)",
        FacilityCity: "Yadgir",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6443,
        Pin: 611002,
        DispatchCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        OriginCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        FacilityCity: "Nagapattinam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6444,
        Pin: 535250,
        DispatchCenter: "SKota_VizainagaramRd_DPP (Andhra Pradesh)",
        OriginCenter: "SKota_VizainagaramRd_DPP (Andhra Pradesh)",
        FacilityCity: "S Kota",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6445,
        Pin: 695311,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6446,
        Pin: 783321,
        DispatchCenter: "Dhubri_Tetultol_D (Assam)",
        OriginCenter: "Dhubri_Tetultol_D (Assam)",
        FacilityCity: "Dhubri",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6447,
        Pin: 262522,
        DispatchCenter: "Berinag_Kunjanpur_DPP (Uttarakhand)",
        OriginCenter: "Berinag_Kunjanpur_DPP (Uttarakhand)",
        FacilityCity: "Pithoragarh",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6448,
        Pin: 521130,
        DispatchCenter: "Avanigadda_Ramnagar_D (Andhra Pradesh)",
        OriginCenter: "Avanigadda_Ramnagar_D (Andhra Pradesh)",
        FacilityCity: "Avanigadda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6449,
        Pin: 743423,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6450,
        Pin: 535557,
        DispatchCenter: "Bobbili_Aguruvidi_D (Andhra Pradesh)",
        OriginCenter: "Bobbili_Aguruvidi_D (Andhra Pradesh)",
        FacilityCity: "Bobbili",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6451,
        Pin: 804430,
        DispatchCenter: "Arwal_SipahPnchayt_D (Bihar)",
        OriginCenter: "Arwal_SipahPnchayt_D (Bihar)",
        FacilityCity: "Arwal",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6452,
        Pin: 781312,
        DispatchCenter: "Sarthebari_Tmlichp_D (Assam)",
        OriginCenter: "Sarthebari_Tmlichp_D (Assam)",
        FacilityCity: "Sarthebari",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6453,
        Pin: 400401,
        DispatchCenter: "Mumbai_Kurla_L (Maharashtra)",
        OriginCenter: "Mumbai_Kurla_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6454,
        Pin: 679514,
        DispatchCenter: "Akaloor_Lakdi_DPP (Kerala)",
        OriginCenter: "Akaloor_Lakdi_DPP (Kerala)",
        FacilityCity: "Akaloor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6455,
        Pin: 172026,
        DispatchCenter: "Anni_Kanda_D (Himachal Pradesh)",
        OriginCenter: "Anni_Kanda_D (Himachal Pradesh)",
        FacilityCity: "Anni",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6456,
        Pin: 415411,
        DispatchCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        OriginCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        FacilityCity: "Islampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6457,
        Pin: 460444,
        DispatchCenter: "Betul_Bheemnagar_D (Madhya Pradesh)",
        OriginCenter: "Betul_Bheemnagar_D (Madhya Pradesh)",
        FacilityCity: "Betul",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 6458,
        Pin: 274203,
        DispatchCenter: "Kushinagar_Hata_D (Uttar Pradesh)",
        OriginCenter: "Kushinagar_Hata_D (Uttar Pradesh)",
        FacilityCity: "Kushinagar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6459,
        Pin: 212305,
        DispatchCenter: "Manda_Salia_D (Uttar Pradesh)",
        OriginCenter: "Manda_Salia_D (Uttar Pradesh)",
        FacilityCity: "Manda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6460,
        Pin: 421203,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6461,
        Pin: 841422,
        DispatchCenter: "Malmaliya_Piarepur_D (Bihar)",
        OriginCenter: "Malmaliya_Piarepur_D (Bihar)",
        FacilityCity: "Malmaliya",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6462,
        Pin: 782442,
        DispatchCenter: "Lanka_Sambari_D (Assam)",
        OriginCenter: "Lanka_Sambari_D (Assam)",
        FacilityCity: "Lanka",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6463,
        Pin: 411032,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6464,
        Pin: 246167,
        DispatchCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        OriginCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        FacilityCity: "Gumkhal",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6465,
        Pin: 403408,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6466,
        Pin: 517214,
        DispatchCenter: "Pileru_Plot14_D (Andhra Pradesh)",
        OriginCenter: "Pileru_Plot14_D (Andhra Pradesh)",
        FacilityCity: "Pileru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6467,
        Pin: 712402,
        DispatchCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        OriginCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        FacilityCity: "Tarkeshwar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6468,
        Pin: 364004,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6469,
        Pin: 515812,
        DispatchCenter: "Uravakonda_GovtDegreeClg_D (Andhra Pradesh)",
        OriginCenter: "Uravakonda_GovtDegreeClg_D (Andhra Pradesh)",
        FacilityCity: "Uravakonda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6470,
        Pin: 686538,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6471,
        Pin: 591235,
        DispatchCenter: "Mahalingpur_Bagalkot_D (Karnataka)",
        OriginCenter: "Mahalingpur_Bagalkot_D (Karnataka)",
        FacilityCity: "Mahalingpur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6472,
        Pin: 393133,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6473,
        Pin: 581305,
        DispatchCenter: "Karwar_Sadashivgad_D (Karnataka)",
        OriginCenter: "Karwar_Sadashivgad_D (Karnataka)",
        FacilityCity: "Karwar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6474,
        Pin: 583284,
        DispatchCenter: "Yelburga_SurabhiColony_D (Karnataka)",
        OriginCenter: "Yelburga_SurabhiColony_D (Karnataka)",
        FacilityCity: "Yelburga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6475,
        Pin: 625018,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6476,
        Pin: 796471,
        DispatchCenter: "Zawlnuam_Bawrai_D (Mizoram)",
        OriginCenter: "Zawlnuam_Bawrai_D (Mizoram)",
        FacilityCity: "Zawlnuam",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6477,
        Pin: 600129,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6478,
        Pin: 143535,
        DispatchCenter: "Pathankot_Kahanpur_H (Punjab)",
        OriginCenter: "Pathankot_Kahanpur_H (Punjab)",
        FacilityCity: "Pathankot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 6479,
        Pin: 389140,
        DispatchCenter: "Dahod_Vanzarwada_I (Gujarat)",
        OriginCenter: "Dahod_Vanzarwada_I (Gujarat)",
        FacilityCity: "Dahod",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6480,
        Pin: 144511,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6481,
        Pin: 175136,
        DispatchCenter: "Patlikuhal_Katrain_D (Himachal Pradesh)",
        OriginCenter: "Patlikuhal_Katrain_D (Himachal Pradesh)",
        FacilityCity: "Patlikuhal",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6482,
        Pin: 396433,
        DispatchCenter: "Navsari_Vejalpore_D (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Navsari",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6483,
        Pin: 125051,
        DispatchCenter: "Ratia_Baslan_D (Haryana)",
        OriginCenter: "Ratia_Baslan_D (Haryana)",
        FacilityCity: "Ratia",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6484,
        Pin: 323603,
        DispatchCenter: "Lakheri_KrsrdDPP_D (Rajasthan)",
        OriginCenter: "Lakheri_KrsrdDPP_D (Rajasthan)",
        FacilityCity: "Lakheri",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6485,
        Pin: 627761,
        DispatchCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        OriginCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        FacilityCity: "Sankarankovil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6486,
        Pin: 503145,
        DispatchCenter: "Kamareddy_KPRcolony_D (Telangana)",
        OriginCenter: "Kamareddy_KPRcolony_D (Telangana)",
        FacilityCity: "Kamareddy",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6487,
        Pin: 845318,
        DispatchCenter: "Forbesganj_Jummanchowk_D (Bihar)",
        OriginCenter: "Forbesganj_Jummanchowk_D (Bihar)",
        FacilityCity: "Forbesganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6488,
        Pin: 221304,
        DispatchCenter: "Gopiganj_Gerai_D (Uttar Pradesh)",
        OriginCenter: "Gopiganj_Gerai_D (Uttar Pradesh)",
        FacilityCity: "Gopiganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6489,
        Pin: 576112,
        DispatchCenter: "Yellare_Muniyal_DPP (Karnataka)",
        OriginCenter: "Yellare_Muniyal_DPP (Karnataka)",
        FacilityCity: "Yellare",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6490,
        Pin: 444202,
        DispatchCenter: "Akot_CollegeRd_D (Maharashtra)",
        OriginCenter: "Akot_CollegeRd_D (Maharashtra)",
        FacilityCity: "Akot",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6491,
        Pin: 201008,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6492,
        Pin: 482002,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6493,
        Pin: 571219,
        DispatchCenter: "Gonikoppal_Patelnagar_D (Karnataka)",
        OriginCenter: "Gonikoppal_Patelnagar_D (Karnataka)",
        FacilityCity: "Gonikoppal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6494,
        Pin: 281001,
        DispatchCenter: "Mathura_Palikheda_D (Uttar Pradesh)",
        OriginCenter: "Mathura_Palikheda_D (Uttar Pradesh)",
        FacilityCity: "Mathura",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6495,
        Pin: 700037,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6496,
        Pin: 313203,
        DispatchCenter: "Fatehnagar_ITALI_DPP (Rajasthan)",
        OriginCenter: "Fatehnagar_ITALI_DPP (Rajasthan)",
        FacilityCity: "Fatehnagar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6497,
        Pin: 632519,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6498,
        Pin: 502335,
        DispatchCenter: "Pregnapur_Tupran_D (Telangana)",
        OriginCenter: "Pregnapur_Tupran_D (Telangana)",
        FacilityCity: "Medak",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6499,
        Pin: 110012,
        DispatchCenter: "Delhi_KirtiNagar_L (Delhi)",
        OriginCenter: "Delhi_KirtiNagar_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6500,
        Pin: 246193,
        DispatchCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        OriginCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        FacilityCity: "Gumkhal",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 6501,
        Pin: 770073,
        DispatchCenter: "Sundargarh_BndhaPli_DPP (Orissa)",
        OriginCenter: "Sundargarh_BndhaPli_DPP (Orissa)",
        FacilityCity: "Sundargarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6502,
        Pin: 413513,
        DispatchCenter: "Ahmedpur_ITIclg_D (Maharashtra)",
        OriginCenter: "Ahmedpur_ITIclg_D (Maharashtra)",
        FacilityCity: "Ahmadpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6503,
        Pin: 786174,
        DispatchCenter: "Bordumsa_LakhangPlace_D (Arunachal Pradesh)",
        OriginCenter: "Bordumsa_LakhangPlace_D (Arunachal Pradesh)",
        FacilityCity: "Bordumsa",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6504,
        Pin: 680671,
        DispatchCenter: "Kodungallur_Methala_D (Kerala)",
        OriginCenter: "Kodungallur_Methala_D (Kerala)",
        FacilityCity: "Kodungallur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6505,
        Pin: 671314,
        DispatchCenter: "Nileshwar_Palathadam_D (Kerala)",
        OriginCenter: "Nileshwar_Palathadam_D (Kerala)",
        FacilityCity: "Nileshwar",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6506,
        Pin: 502221,
        DispatchCenter: "Zahirabad_Mohim_D (Telangana)",
        OriginCenter: "Zahirabad_Mohim_D (Telangana)",
        FacilityCity: "Zahirabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6507,
        Pin: 394518,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6508,
        Pin: 416119,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6509,
        Pin: 271803,
        DispatchCenter: "Bhinga_ShubhashNagar_D (Uttar Pradesh)",
        OriginCenter: "Bhinga_ShubhashNagar_D (Uttar Pradesh)",
        FacilityCity: "Bhinga",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6510,
        Pin: 344704,
        DispatchCenter: "Dhorimana_VishnuColony_D (Rajasthan)",
        OriginCenter: "Dhorimana_VishnuColony_D (Rajasthan)",
        FacilityCity: "Dhorimana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6511,
        Pin: 387115,
        DispatchCenter: "Umreth_PltinumPlza_DPP (Gujarat)",
        OriginCenter: "Umreth_PltinumPlza_DPP (Gujarat)",
        FacilityCity: "Umreth",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6512,
        Pin: 325223,
        DispatchCenter: "Khanpur_JhalawarRd_DPP (Rajasthan)",
        OriginCenter: "Khanpur_JhalawarRd_DPP (Rajasthan)",
        FacilityCity: "Khanpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6513,
        Pin: 636001,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6514,
        Pin: 821310,
        DispatchCenter: "Daudnagar_Tarar_D (Bihar)",
        OriginCenter: "Daudnagar_Tarar_D (Bihar)",
        FacilityCity: "Daudnagar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6515,
        Pin: 824122,
        DispatchCenter: "Goh_Golapar_D (Bihar)",
        OriginCenter: "Goh_Golapar_D (Bihar)",
        FacilityCity: "Goh",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6516,
        Pin: 680618,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6517,
        Pin: 506352,
        DispatchCenter: "Mulugu_MaharshiClg_D (Telangana)",
        OriginCenter: "Mulugu_MaharshiClg_D (Telangana)",
        FacilityCity: "Mulugu",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6518,
        Pin: 756018,
        DispatchCenter: "Balasore_Ganeswarpur_D (Orissa)",
        OriginCenter: "Balasore_Ganeswarpur_D (Orissa)",
        FacilityCity: "Balasore",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6519,
        Pin: 144002,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6520,
        Pin: 784114,
        DispatchCenter: "Udalguri_Dhansrighat_D (Assam)",
        OriginCenter: "Udalguri_Dhansrighat_D (Assam)",
        FacilityCity: "Udalguri",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6521,
        Pin: 517280,
        DispatchCenter: "Madanapalle_RNaiduClny_D (Andhra Pradesh)",
        OriginCenter: "Madanapalle_RNaiduClny_D (Andhra Pradesh)",
        FacilityCity: "Madanapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6522,
        Pin: 680317,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6523,
        Pin: 232119,
        DispatchCenter: "Chandauli_ShubsNGR_D (Uttar Pradesh)",
        OriginCenter: "Chandauli_ShubsNGR_D (Uttar Pradesh)",
        FacilityCity: "Chandauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6524,
        Pin: 680547,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6525,
        Pin: 284204,
        DispatchCenter: "Mauranipur_Roni_D (Uttar Pradesh)",
        OriginCenter: "Mauranipur_Roni_D (Uttar Pradesh)",
        FacilityCity: "Mauranipur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6526,
        Pin: 574234,
        DispatchCenter: "Sullia_Kanthamangala_D (Karnataka)",
        OriginCenter: "Sullia_Kanthamangala_D (Karnataka)",
        FacilityCity: "Sullia",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6527,
        Pin: 500123,
        DispatchCenter: "Hyderabad_Medchal1_L (Telangana)",
        OriginCenter: "Hyderabad_Medchal_GW (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6528,
        Pin: 441106,
        DispatchCenter: "Ramtek_ShivajiWard_D (Maharashtra)",
        OriginCenter: "Ramtek_ShivajiWard_D (Maharashtra)",
        FacilityCity: "Ramtek",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6529,
        Pin: 629101,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6530,
        Pin: 721424,
        DispatchCenter: "Belda_DntnRD_DPP (West Bengal)",
        OriginCenter: "Belda_DntnRD_DPP (West Bengal)",
        FacilityCity: "Belda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6531,
        Pin: 842101,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6532,
        Pin: 682019,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6533,
        Pin: 382250,
        DispatchCenter: "Dhandhuka_BhavnagarRd_DPP (Gujarat)",
        OriginCenter: "Dhandhuka_BhavnagarRd_DPP (Gujarat)",
        FacilityCity: "Dhandhuka",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6534,
        Pin: 625531,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6535,
        Pin: 452004,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6536,
        Pin: 211015,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6537,
        Pin: 363410,
        DispatchCenter: "Limbdi_Ranpur_DPP (Gujarat)",
        OriginCenter: "Limbdi_Ranpur_DPP (Gujarat)",
        FacilityCity: "Limbdi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6538,
        Pin: 591263,
        DispatchCenter: "Nipani_Sakharwadi_D (Karnataka)",
        OriginCenter: "Nipani_Sakharwadi_D (Karnataka)",
        FacilityCity: "Nipani",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6539,
        Pin: 801110,
        DispatchCenter: "Arwal_SipahPnchayt_D (Bihar)",
        OriginCenter: "Arwal_SipahPnchayt_D (Bihar)",
        FacilityCity: "Arwal",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6540,
        Pin: 495695,
        DispatchCenter: "Sakti_Siphaimda_D (Chhattisgarh)",
        OriginCenter: "Sakti_Siphaimda_D (Chhattisgarh)",
        FacilityCity: "Sakti",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6541,
        Pin: 415512,
        DispatchCenter: "Rahimatpur_Wathar_DPP (Maharashtra)",
        OriginCenter: "Rahimatpur_Wathar_DPP (Maharashtra)",
        FacilityCity: "Rahimatpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6542,
        Pin: 761111,
        DispatchCenter: "Asika_DsarathiNgr_DPP (Orissa)",
        OriginCenter: "Asika_DsarathiNgr_DPP (Orissa)",
        FacilityCity: "Asika",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6543,
        Pin: 844127,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6544,
        Pin: 743390,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6545,
        Pin: 416502,
        DispatchCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        OriginCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        FacilityCity: "Gadhinglaj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6546,
        Pin: 229207,
        DispatchCenter: "Lalganj_Dalmau_D (Uttar Pradesh)",
        OriginCenter: "Lalganj_Dalmau_D (Uttar Pradesh)",
        FacilityCity: "Lalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6547,
        Pin: 848203,
        DispatchCenter: "Singhia_Bithan2_DPP (Bihar)",
        OriginCenter: "Singhia_Bithan2_DPP (Bihar)",
        FacilityCity: "Singhia",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6548,
        Pin: 814144,
        DispatchCenter: "Rangalia_JibnprMore_DPP (Jharkhand)",
        OriginCenter: "Rangalia_JibnprMore_DPP (Jharkhand)",
        FacilityCity: "Rangalia",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6549,
        Pin: 343030,
        DispatchCenter: "Bhinmal_Kaleti_D (Rajasthan)",
        OriginCenter: "Bhinmal_Kaleti_D (Rajasthan)",
        FacilityCity: "Bhinmal",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6550,
        Pin: 360325,
        DispatchCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        OriginCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        Zone : "W1"
      },
      {
        SN: 6551,
        Pin: 603108,
        DispatchCenter: "Thiruporur_Rountana2_DPP (Tamil Nadu)",
        OriginCenter: "Thiruporur_Rountana2_DPP (Tamil Nadu)",
        FacilityCity: "Thiruporur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6552,
        Pin: 743347,
        DispatchCenter: "Kakdwip_KlngrDPP_D (West Bengal)",
        OriginCenter: "Kakdwip_KlngrDPP_D (West Bengal)",
        FacilityCity: "Kakdwip",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6553,
        Pin: 700073,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6554,
        Pin: 782001,
        DispatchCenter: "Nagaon_Bangthai_H (Assam)",
        OriginCenter: "Nagaon_Bangthai_H (Assam)",
        FacilityCity: "Nagaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6555,
        Pin: 734426,
        DispatchCenter: "Bagdogra_Kadopani_D (West Bengal)",
        OriginCenter: "Bagdogra_Kadopani_D (West Bengal)",
        FacilityCity: "Bagdogra",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6556,
        Pin: 628952,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6557,
        Pin: 712301,
        DispatchCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        OriginCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        FacilityCity: "Tarkeshwar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6558,
        Pin: 754021,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6559,
        Pin: 671552,
        DispatchCenter: "Kasaragod_Kudlu_D (Kerala)",
        OriginCenter: "Kasaragod_Kudlu_D (Kerala)",
        FacilityCity: "Kasaragod",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6560,
        Pin: 441208,
        DispatchCenter: "Brahmapuri_Tilaknagar_D (Maharashtra)",
        OriginCenter: "Brahmapuri_Tilaknagar_D (Maharashtra)",
        FacilityCity: "Brahmapuri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6561,
        Pin: 388540,
        DispatchCenter: "Anand_Borsad_D (Gujarat)",
        OriginCenter: "Anand_Borsad_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6562,
        Pin: 560062,
        DispatchCenter: "Bangalore_FCILayout_L (Karnataka)",
        OriginCenter: "Bangalore_FCILayout_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6563,
        Pin: 415509,
        DispatchCenter: "Mhaswad_Drgmtatpl_D (Maharashtra)",
        OriginCenter: "Mhaswad_Drgmtatpl_D (Maharashtra)",
        FacilityCity: "Mhaswad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6564,
        Pin: 533340,
        DispatchCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6565,
        Pin: 781381,
        DispatchCenter: "Baihata_Agdala_D (Assam)",
        OriginCenter: "Baihata_Agdala_D (Assam)",
        FacilityCity: "Baihata",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6566,
        Pin: 781343,
        DispatchCenter: "Tihu_Jalahgaon_D (Assam)",
        OriginCenter: "Tihu_Jalahgaon_D (Assam)",
        FacilityCity: "Tihu",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6567,
        Pin: 761119,
        DispatchCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        OriginCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        FacilityCity: "Bhanjanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6568,
        Pin: 160019,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6569,
        Pin: 431721,
        DispatchCenter: "Mahagaon_Central_DPP_1 (Maharashtra)",
        OriginCenter: "Mahagaon_Central_DPP_1 (Maharashtra)",
        FacilityCity: "Mahagaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6570,
        Pin: 770024,
        DispatchCenter: "Sundargarh_Duduka_D (Orissa)",
        OriginCenter: "Sundargarh_Duduka_D (Orissa)",
        FacilityCity: "Sundargarh",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6571,
        Pin: 628201,
        DispatchCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        OriginCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        FacilityCity: "Tiruchendur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6572,
        Pin: 110077,
        DispatchCenter: "Delhi_BamnoliVillage_L (Delhi)",
        OriginCenter: "Delhi_BamnoliVillage_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6573,
        Pin: 147007,
        DispatchCenter: "Patiala_UpkarNagar_D (Punjab)",
        OriginCenter: "Patiala_UpkarNagar_D (Punjab)",
        FacilityCity: "Patiala",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 6574,
        Pin: 396195,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6575,
        Pin: 303005,
        DispatchCenter: "Dudu_Phagi2_D (Rajasthan)",
        OriginCenter: "Dudu_Phagi2_D (Rajasthan)",
        FacilityCity: "Dudu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6576,
        Pin: 605752,
        DispatchCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        OriginCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        FacilityCity: "Thirukoilure",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6577,
        Pin: 804417,
        DispatchCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        OriginCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        FacilityCity: "Jehanabad",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6578,
        Pin: 781102,
        DispatchCenter: "Hajo_Baruabari_D (Assam)",
        OriginCenter: "Hajo_Baruabari_D (Assam)",
        FacilityCity: "Hajo",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6579,
        Pin: 843107,
        DispatchCenter: "Saraiya_Saraiya_D (Bihar)",
        OriginCenter: "Saraiya_Saraiya_D (Bihar)",
        FacilityCity: "Saraiya",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6580,
        Pin: 768203,
        DispatchCenter: "Jharsuguda_Ekatali_D (Orissa)",
        OriginCenter: "Jharsuguda_Ekatali_D (Orissa)",
        FacilityCity: "Jharsuguda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6581,
        Pin: 491223,
        DispatchCenter: "Balod_HaldharChowk_D (Chhattisgarh)",
        OriginCenter: "Balod_HaldharChowk_D (Chhattisgarh)",
        FacilityCity: "Balod",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6582,
        Pin: 678651,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6583,
        Pin: 635126,
        DispatchCenter: "Hosur_SipcotPhase1_L (Tamil Nadu)",
        OriginCenter: "Hosur_SipcotPhase1_L (Tamil Nadu)",
        FacilityCity: "Hosur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6584,
        Pin: 679505,
        DispatchCenter: "Cherpulassery_PryaPlda_D (Kerala)",
        OriginCenter: "Cherpulassery_PryaPlda_D (Kerala)",
        FacilityCity: "Cherpulassery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6585,
        Pin: 712514,
        DispatchCenter: "Balagarh_Police_D (West Bengal)",
        OriginCenter: "Balagarh_Police_D (West Bengal)",
        FacilityCity: "Balagarh",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6586,
        Pin: 847307,
        DispatchCenter: "Bharwara_AtarwelPath_D (Bihar)",
        OriginCenter: "Bharwara_AtarwelPath_D (Bihar)",
        FacilityCity: "Bharwara",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6587,
        Pin: 416524,
        DispatchCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        OriginCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        FacilityCity: "Malvan",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6588,
        Pin: 177038,
        DispatchCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        OriginCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        FacilityCity: "Nadaun",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 6589,
        Pin: 606213,
        DispatchCenter: "Kallakurichi_Neelamangalam_D (Tamil Nadu)",
        OriginCenter: "Kallakurichi_Neelamangalam_D (Tamil Nadu)",
        FacilityCity: "Kallakurichi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6590,
        Pin: 332602,
        DispatchCenter: "Reengus_TegoreViharColony_D (Rajasthan)",
        OriginCenter: "Reengus_TegoreViharColony_D (Rajasthan)",
        FacilityCity: "Reengus",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6591,
        Pin: 382455,
        DispatchCenter: "Dhandhuka_Dholera_DPP (Gujarat)",
        OriginCenter: "Dhandhuka_Dholera_DPP (Gujarat)",
        FacilityCity: "Dhandhuka",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6592,
        Pin: 603304,
        DispatchCenter: "Marakkanam_Kandadu_DPP (Tamil Nadu)",
        OriginCenter: "Marakkanam_Kandadu_DPP (Tamil Nadu)",
        FacilityCity: "Marakkanam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6593,
        Pin: 533263,
        DispatchCenter: "Draksharamam_Anaipeta_D (Andhra Pradesh)",
        OriginCenter: "Draksharamam_Anaipeta_D (Andhra Pradesh)",
        FacilityCity: "Draksharamam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6594,
        Pin: 454774,
        DispatchCenter: "Pithampur_Ambedkarnagar_L (Madhya Pradesh)",
        OriginCenter: "Pithampur_Ambedkarnagar_L (Madhya Pradesh)",
        FacilityCity: "Pithampur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6595,
        Pin: 796008,
        DispatchCenter: "Aizawl_Ainawnveng_D (Mizoram)",
        OriginCenter: "Aizawl_Ainawnveng_D (Mizoram)",
        FacilityCity: "Aizawl",
        FacilityState: "Mizoram",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6596,
        Pin: 244713,
        DispatchCenter: "Kashipur_Khokhratal_D (Uttarakhand)",
        OriginCenter: "Kashipur_Khokhratal_D (Uttarakhand)",
        FacilityCity: "Kashipur",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6597,
        Pin: 362240,
        DispatchCenter: "Madhavpur_Osoashrm_DPP (Gujarat)",
        OriginCenter: "Madhavpur_Osoashrm_DPP (Gujarat)",
        FacilityCity: "Madhavpur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6598,
        Pin: 262554,
        DispatchCenter: "Munsyari_Sarmoli_DPP (Uttarakhand)",
        OriginCenter: "Munsyari_Sarmoli_DPP (Uttarakhand)",
        FacilityCity: "Munsyari",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6599,
        Pin: 506170,
        DispatchCenter: "Bhupalpally_LaxmiNagar_DPP (Telangana)",
        OriginCenter: "Bhupalpally_LaxmiNagar_DPP (Telangana)",
        FacilityCity: "Bhupalpally",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6600,
        Pin: 231307,
        DispatchCenter: "Mirzapur_Natwa_D (Uttar Pradesh)",
        OriginCenter: "Mirzapur_Natwa_D (Uttar Pradesh)",
        FacilityCity: "Mirzapur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6601,
        Pin: 846005,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6602,
        Pin: 415537,
        DispatchCenter: "Phaltan_Kolki_D (Maharashtra)",
        OriginCenter: "Phaltan_Kolki_D (Maharashtra)",
        FacilityCity: "Phaltan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6603,
        Pin: 844501,
        DispatchCenter: "Hazratjandaha_Hirpur_D (Bihar)",
        OriginCenter: "Hazratjandaha_Hirpur_D (Bihar)",
        FacilityCity: "Hazrat Jandaha",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6604,
        Pin: 742162,
        DispatchCenter: "Nowda_Amtala_DPP (West Bengal)",
        OriginCenter: "Nowda_Amtala_DPP (West Bengal)",
        FacilityCity: "Nowda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6605,
        Pin: 523265,
        DispatchCenter: "Addanki_AbhyudayaNagar_D (Andhra Pradesh)",
        OriginCenter: "Addanki_AbhyudayaNagar_D (Andhra Pradesh)",
        FacilityCity: "Addanki",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6606,
        Pin: 827003,
        DispatchCenter: "Bokaro_Chas_D (Jharkhand)",
        OriginCenter: "Bokaro_Chas_D (Jharkhand)",
        FacilityCity: "Bokaro",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6607,
        Pin: 573225,
        DispatchCenter: "Chnnryptna_MysoreRd_D (Karnataka)",
        OriginCenter: "Chnnryptna_MysoreRd_D (Karnataka)",
        FacilityCity: "Channarayapatna",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6608,
        Pin: 132001,
        DispatchCenter: "Karnal_Kutail_H (Haryana)",
        OriginCenter: "Karnal_Kutail_H (Haryana)",
        FacilityCity: "Karnal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6609,
        Pin: 689115,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6610,
        Pin: 221202,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6611,
        Pin: 444108,
        DispatchCenter: "Akot_CollegeRd_D (Maharashtra)",
        OriginCenter: "Akot_CollegeRd_D (Maharashtra)",
        FacilityCity: "Akot",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6612,
        Pin: 492012,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6613,
        Pin: 391780,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6614,
        Pin: 518466,
        DispatchCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        OriginCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6615,
        Pin: 176062,
        DispatchCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        OriginCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        FacilityCity: "Palampur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6616,
        Pin: 210122,
        DispatchCenter: "Atarra_GangehiRd_D (Uttar Pradesh)",
        OriginCenter: "Atarra_GangehiRd_D (Uttar Pradesh)",
        FacilityCity: "Atarra",
        FacilityState: "Uttar Pradesh",
        Zone : "N1"
      },
      {
        SN: 6617,
        Pin: 524347,
        DispatchCenter: "Nellore_Bccolony_D (Andhra Pradesh)",
        OriginCenter: "Nellore_Bccolony_D (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6618,
        Pin: 494771,
        DispatchCenter: "Pakhanjur_ForestColony_D (Chhattisgarh)",
        OriginCenter: "Pakhanjur_ForestColony_D (Chhattisgarh)",
        FacilityCity: "Pakhanjur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6619,
        Pin: 202389,
        DispatchCenter: "Dibai_Galibpur_D (Uttar Pradesh)",
        OriginCenter: "Dibai_Galibpur_D (Uttar Pradesh)",
        FacilityCity: "Dibai",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6620,
        Pin: 737135,
        DispatchCenter: "Rangpo_Khanikhola_I (Sikkim)",
        OriginCenter: "Rangpo_Khanikhola_I (Sikkim)",
        FacilityCity: "Rangpo",
        FacilityState: "Sikkim",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6621,
        Pin: 533244,
        DispatchCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        OriginCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        FacilityCity: "Razole",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6622,
        Pin: 344035,
        DispatchCenter: "Barmer_Baldevnagar_DPP (Rajasthan)",
        OriginCenter: "Barmer_Baldevnagar_DPP (Rajasthan)",
        FacilityCity: "Barmer",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6623,
        Pin: 627813,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6624,
        Pin: 689102,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6625,
        Pin: 229211,
        DispatchCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        OriginCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        FacilityCity: "Lalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6626,
        Pin: 176039,
        DispatchCenter: "Dulchehra_Rapar_DPP (Himachal Pradesh)",
        OriginCenter: "Dulchehra_Rapar_DPP (Himachal Pradesh)",
        FacilityCity: "Dulchehra",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6627,
        Pin: 523002,
        DispatchCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        OriginCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        FacilityCity: "Ongole",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6628,
        Pin: 639103,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6629,
        Pin: 815352,
        DispatchCenter: "Jamtara_Karmatar_D (Jharkhand)",
        OriginCenter: "Jamtara_Karmatar_D (Jharkhand)",
        FacilityCity: "Jamtara",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6630,
        Pin: 176502,
        DispatchCenter: "GhatiBillan_Ban_DPP (Himachal Pradesh)",
        OriginCenter: "GhatiBillan_Ban_DPP (Himachal Pradesh)",
        FacilityCity: "Ghati Billan",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6631,
        Pin: 796431,
        DispatchCenter: "WestPhaileng_BlockVeng_D (Mizoram)",
        OriginCenter: "WestPhaileng_BlockVeng_D (Mizoram)",
        FacilityCity: "WestPhaileng",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6632,
        Pin: 402204,
        DispatchCenter: "Alibag_Shivanagar_D (Maharashtra)",
        OriginCenter: "Alibag_Shivanagar_D (Maharashtra)",
        FacilityCity: "Alibag",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6633,
        Pin: 743426,
        DispatchCenter: "Taki_Hasnabad_DPP (West Bengal)",
        OriginCenter: "Taki_Hasnabad_DPP (West Bengal)",
        FacilityCity: "Taki",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6634,
        Pin: 134008,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 6635,
        Pin: 500054,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6636,
        Pin: 365630,
        DispatchCenter: "Visavadar_Dhari_DPP (Gujarat)",
        OriginCenter: "Visavadar_Dhari_DPP (Gujarat)",
        FacilityCity: "Visavadar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6637,
        Pin: 228133,
        DispatchCenter: "Lambhua_Chaukiya_D (Uttar Pradesh)",
        OriginCenter: "Lambhua_Chaukiya_D (Uttar Pradesh)",
        FacilityCity: "Lambhua",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6638,
        Pin: 176098,
        DispatchCenter: "Khundian_Koka_DPP (Himachal Pradesh)",
        OriginCenter: "Khundian_Koka_DPP (Himachal Pradesh)",
        FacilityCity: "Khundian",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6639,
        Pin: 144621,
        DispatchCenter: "Begowal_PinkMrkt_D (Punjab)",
        OriginCenter: "Begowal_PinkMrkt_D (Punjab)",
        FacilityCity: "Begowal",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6640,
        Pin: 203206,
        DispatchCenter: "Sikandrabad_Khatriwara_D (Uttar Pradesh)",
        OriginCenter: "Sikandrabad_Khatriwara_D (Uttar Pradesh)",
        FacilityCity: "Sikandrabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6641,
        Pin: 695001,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6642,
        Pin: 400063,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6643,
        Pin: 535160,
        DispatchCenter: "SKota_VizainagaramRd_DPP (Andhra Pradesh)",
        OriginCenter: "SKota_VizainagaramRd_DPP (Andhra Pradesh)",
        FacilityCity: "S Kota",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6644,
        Pin: 411042,
        DispatchCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        OriginCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6645,
        Pin: 190001,
        DispatchCenter: "Srinagar_Ladhoo_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Ladhoo_H (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6646,
        Pin: 484224,
        DispatchCenter: "Anuppur_Chetnanagar_D (Madhya Pradesh)",
        OriginCenter: "Anuppur_Chetnanagar_D (Madhya Pradesh)",
        FacilityCity: "Anuppur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 6647,
        Pin: 210506,
        DispatchCenter: "Muskara_KaliMataM_D (Uttar Pradesh)",
        OriginCenter: "Muskara_KaliMataM_D (Uttar Pradesh)",
        FacilityCity: "Muskara",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6648,
        Pin: 621310,
        DispatchCenter: "Manapparai_AmmanNagar_D (Tamil Nadu)",
        OriginCenter: "Manapparai_AmmanNagar_D (Tamil Nadu)",
        FacilityCity: "Manapparai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6649,
        Pin: 762030,
        DispatchCenter: "Kantamal_Blockoffice_DPP (Orissa)",
        OriginCenter: "Kantamal_Blockoffice_DPP (Orissa)",
        FacilityCity: "Kantamal",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6650,
        Pin: 673311,
        DispatchCenter: "Mahe_Chokli_D (Kerala)",
        OriginCenter: "Mahe_Chokli_D (Kerala)",
        FacilityCity: "Mahe",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6651,
        Pin: 410512,
        DispatchCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        OriginCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        FacilityCity: "Ambegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6652,
        Pin: 493114,
        DispatchCenter: "Tilda_KhushiHsptlRd_D (Chhattisgarh)",
        OriginCenter: "Tilda_KhushiHsptlRd_D (Chhattisgarh)",
        FacilityCity: "Tilda",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6653,
        Pin: 441108,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6654,
        Pin: 841302,
        DispatchCenter: "Chhapra_Ramnagar_I (Bihar)",
        OriginCenter: "Chhapra_Ramnagar_I (Bihar)",
        FacilityCity: "Chhapra",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6655,
        Pin: 387810,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6656,
        Pin: 509401,
        DispatchCenter: "Achampet_Madhuranagar_D (Telangana)",
        OriginCenter: "Achampet_Madhuranagar_D (Telangana)",
        FacilityCity: "Achampet",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6657,
        Pin: 638459,
        DispatchCenter: "Avinashi_SeyurRoad_D (Tamil Nadu)",
        OriginCenter: "Avinashi_SeyurRoad_D (Tamil Nadu)",
        FacilityCity: "Avinashi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6658,
        Pin: 700092,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6659,
        Pin: 263126,
        DispatchCenter: "Haldwani_Kamaluaganja_D (Uttarakhand)",
        OriginCenter: "Haldwani_Kamaluaganja_D (Uttarakhand)",
        FacilityCity: "Haldwani",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6660,
        Pin: 273157,
        DispatchCenter: "Brijmanganj_Rattupur_DPP (Uttar Pradesh)",
        OriginCenter: "Brijmanganj_Rattupur_DPP (Uttar Pradesh)",
        FacilityCity: "Brijmanganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6661,
        Pin: 600033,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6662,
        Pin: 396385,
        DispatchCenter: "Valsad_LimbaChwk_D (Gujarat)",
        OriginCenter: "Valsad_LimbaChwk_D (Gujarat)",
        FacilityCity: "Valsad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6663,
        Pin: 332026,
        DispatchCenter: "Sikar_Phagalwa_D (Rajasthan)",
        OriginCenter: "Sikar_Phagalwa_D (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6664,
        Pin: 845422,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6665,
        Pin: 388180,
        DispatchCenter: "Tarapur_Darukhanu_DPP (Gujarat)",
        OriginCenter: "Tarapur_Darukhanu_DPP (Gujarat)",
        FacilityCity: "Tarapur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6666,
        Pin: 637502,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6667,
        Pin: 110017,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6668,
        Pin: 607804,
        DispatchCenter: "Neyveli_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Neyveli_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Neyveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6669,
        Pin: 191101,
        DispatchCenter: "Srinagar_Ladhoo_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Ladhoo_H (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6670,
        Pin: 636704,
        DispatchCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        OriginCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        FacilityCity: "Dharmapuri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6671,
        Pin: 813202,
        DispatchCenter: "Sangrampur_Bhawnipr_D (Bihar)",
        OriginCenter: "Sangrampur_Bhawnipr_D (Bihar)",
        FacilityCity: "Sangrampur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6672,
        Pin: 678593,
        DispatchCenter: "Karimba_Kaliyode_D (Kerala)",
        OriginCenter: "Karimba_Kaliyode_D (Kerala)",
        FacilityCity: "Karimba",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6673,
        Pin: 172201,
        DispatchCenter: "Rampur_Shingla_D (Himachal Pradesh)",
        OriginCenter: "Rampur_Shingla_D (Himachal Pradesh)",
        FacilityCity: "Rampur Bushahr",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 6674,
        Pin: 416525,
        DispatchCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        OriginCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        FacilityCity: "Sawantwadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6675,
        Pin: 601201,
        DispatchCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        OriginCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6676,
        Pin: 202141,
        DispatchCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        OriginCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        FacilityCity: "Khair",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6677,
        Pin: 221209,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6678,
        Pin: 171019,
        DispatchCenter: "Shimla_Jawaharcolony_D (Himachal Pradesh)",
        OriginCenter: "Shimla_Jawaharcolony_D (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6679,
        Pin: 244602,
        DispatchCenter: "Thakurdwara_Ward8_D (Uttar Pradesh)",
        OriginCenter: "Thakurdwara_Ward8_D (Uttar Pradesh)",
        FacilityCity: "Thakurdwara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6680,
        Pin: 841506,
        DispatchCenter: "Maharajganj_Sihauta_D (Bihar)",
        OriginCenter: "Maharajganj_Sihauta_D (Bihar)",
        FacilityCity: "Maharajganj-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6681,
        Pin: 574211,
        DispatchCenter: "Bantwal_BambilRd_D (Karnataka)",
        OriginCenter: "Bantwal_BambilRd_D (Karnataka)",
        FacilityCity: "Bantwal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6682,
        Pin: 110037,
        DispatchCenter: "Delhi_BamnoliVillage_L (Delhi)",
        OriginCenter: "Delhi_BamnoliVillage_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6683,
        Pin: 695143,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6684,
        Pin: 688546,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6685,
        Pin: 670566,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6686,
        Pin: 600107,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6687,
        Pin: 629162,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6688,
        Pin: 754009,
        DispatchCenter: "Kalapathar_GoleiChhak_D (Orissa)",
        OriginCenter: "Kalapathar_GoleiChhak_D (Orissa)",
        FacilityCity: "Kalapathar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6689,
        Pin: 132039,
        DispatchCenter: "Assandh_Dholchowk_D (Haryana)",
        OriginCenter: "Assandh_Dholchowk_D (Haryana)",
        FacilityCity: "Assandh",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6690,
        Pin: 431526,
        DispatchCenter: "Ambajogai_Bansilalnagar_D (Maharashtra)",
        OriginCenter: "Ambajogai_Bansilalnagar_D (Maharashtra)",
        FacilityCity: "Ambajogai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6691,
        Pin: 285124,
        DispatchCenter: "Jalaun_Madhogarh_D (Uttar Pradesh)",
        OriginCenter: "Jalaun_Madhogarh_D (Uttar Pradesh)",
        FacilityCity: "Jalaun",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6692,
        Pin: 797109,
        DispatchCenter: "Tseminyu_Zisunyu_D (Nagaland)",
        OriginCenter: "Tseminyu_Zisunyu_D (Nagaland)",
        FacilityCity: "Tseminyu",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6693,
        Pin: 470772,
        DispatchCenter: "Hatta_Gourishankar_D (Madhya Pradesh)",
        OriginCenter: "Hatta_Gourishankar_D (Madhya Pradesh)",
        FacilityCity: "Hatta",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 6694,
        Pin: 721651,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6695,
        Pin: 571404,
        DispatchCenter: "Mandya_Dc (Karnataka)",
        OriginCenter: "Mandya_Dc (Karnataka)",
        FacilityCity: "Mandya",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6696,
        Pin: 160058,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6697,
        Pin: 204101,
        DispatchCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        OriginCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        FacilityCity: "Hathras",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6698,
        Pin: 575023,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6699,
        Pin: 721449,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6700,
        Pin: 231206,
        DispatchCenter: "Robertsganj_Pakari_D (Uttar Pradesh)",
        OriginCenter: "Robertsganj_Pakari_D (Uttar Pradesh)",
        FacilityCity: "Robertsganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6701,
        Pin: 452056,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6702,
        Pin: 453331,
        DispatchCenter: "Pithampur_Ambedkarnagar_L (Madhya Pradesh)",
        OriginCenter: "Pithampur_Ambedkarnagar_L (Madhya Pradesh)",
        FacilityCity: "Pithampur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6703,
        Pin: 274701,
        DispatchCenter: "Salempur_BhatparRani_D (Uttar Pradesh)",
        OriginCenter: "Salempur_BhatparRani_D (Uttar Pradesh)",
        FacilityCity: "Salempur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6704,
        Pin: 630108,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6705,
        Pin: 422304,
        DispatchCenter: "Lasalgaon_TelephnExch_D (Maharashtra)",
        OriginCenter: "Lasalgaon_TelephnExch_D (Maharashtra)",
        FacilityCity: "Lasalgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6706,
        Pin: 762014,
        DispatchCenter: "Boudh_Jogiberini_D (Orissa)",
        OriginCenter: "Boudh_Jogiberini_D (Orissa)",
        FacilityCity: "Boudh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6707,
        Pin: 221101,
        DispatchCenter: "Cholapur_Katari_D (Uttar Pradesh)",
        OriginCenter: "Cholapur_Katari_D (Uttar Pradesh)",
        FacilityCity: "Cholapur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6708,
        Pin: 624801,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6709,
        Pin: 144103,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6710,
        Pin: 755013,
        DispatchCenter: "Mangalpur_GayatriColony_D (Orissa)",
        OriginCenter: "Mangalpur_GayatriColony_D (Orissa)",
        FacilityCity: "Mangalpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6711,
        Pin: 785691,
        DispatchCenter: "Sonari_Nakachari_D (Assam)",
        OriginCenter: "Sonari_Nakachari_D (Assam)",
        FacilityCity: "Sonari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6712,
        Pin: 638111,
        DispatchCenter: "Tirupur_Kangeyam_D (Tamil Nadu)",
        OriginCenter: "Tirupur_Kangeyam_D (Tamil Nadu)",
        FacilityCity: "Palladam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6713,
        Pin: 757043,
        DispatchCenter: "Rairangpur_Sanchampauda_D (Orissa)",
        OriginCenter: "Rairangpur_Sanchampauda_D (Orissa)",
        FacilityCity: "Rairangpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6714,
        Pin: 752027,
        DispatchCenter: "Tangi_SriDPP_D (Orissa)",
        OriginCenter: "Tangi_SriDPP_D (Orissa)",
        FacilityCity: "Tangi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6715,
        Pin: 284402,
        DispatchCenter: "Mahrauni_Kuwanghosi_D (Uttar Pradesh)",
        OriginCenter: "Mahrauni_Kuwanghosi_D (Uttar Pradesh)",
        FacilityCity: "Mahrauni",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6716,
        Pin: 272173,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6717,
        Pin: 413531,
        DispatchCenter: "Latur_KhadgoanChowk_D (Maharashtra)",
        OriginCenter: "Latur_KhadgoanChowk_D (Maharashtra)",
        FacilityCity: "Latur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6718,
        Pin: 700045,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6719,
        Pin: 835204,
        DispatchCenter: "Bundu_Collegemore_D (Jharkhand)",
        OriginCenter: "Bundu_Collegemore_D (Jharkhand)",
        FacilityCity: "Bundu",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6720,
        Pin: 627755,
        DispatchCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        OriginCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        FacilityCity: "Sankarankovil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6721,
        Pin: 560067,
        DispatchCenter: "Bangalore_Koralur_L (Karnataka)",
        OriginCenter: "Bangalore_Soukyaroad_GW (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6722,
        Pin: 505505,
        DispatchCenter: "KarimNagar_Alugunoor_I (Telangana)",
        OriginCenter: "KarimNagar_Alugunoor_I (Telangana)",
        FacilityCity: "Karim Nagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6723,
        Pin: 824101,
        DispatchCenter: "AurangabadBR_Katharua_D (Bihar)",
        OriginCenter: "AurangabadBR_Katharua_D (Bihar)",
        FacilityCity: "Aurangabad-BR",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6724,
        Pin: 250004,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6725,
        Pin: 431716,
        DispatchCenter: "Mukhed_AmbedkarChowk_DPP (Maharashtra)",
        OriginCenter: "Mukhed_AmbedkarChowk_DPP (Maharashtra)",
        FacilityCity: "Mukhed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6726,
        Pin: 632011,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6727,
        Pin: 854113,
        DispatchCenter: "Manihari_Bnrailwaycol_D (Bihar)",
        OriginCenter: "Manihari_Bnrailwaycol_D (Bihar)",
        FacilityCity: "Manihari",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6728,
        Pin: 685561,
        DispatchCenter: "Adimali_AdimaliMkt_D (Kerala)",
        OriginCenter: "Adimali_AdimaliMkt_D (Kerala)",
        FacilityCity: "Adimali",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6729,
        Pin: 682004,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6730,
        Pin: 531201,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6731,
        Pin: 834004,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6732,
        Pin: 416608,
        DispatchCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        OriginCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        FacilityCity: "Malvan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6733,
        Pin: 621313,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6734,
        Pin: 591301,
        DispatchCenter: "Alnavar_Gharli_DPP (Karnataka)",
        OriginCenter: "Alnavar_Gharli_DPP (Karnataka)",
        FacilityCity: "Alnavar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6735,
        Pin: 754101,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6736,
        Pin: 642104,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6737,
        Pin: 713365,
        DispatchCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        OriginCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        FacilityCity: "Rupnarayanpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6738,
        Pin: 180010,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6739,
        Pin: 786602,
        DispatchCenter: "Duliajan_StatonRd_D (Assam)",
        OriginCenter: "Duliajan_StatonRd_D (Assam)",
        FacilityCity: "Duliajan",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6740,
        Pin: 622503,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6741,
        Pin: 507135,
        DispatchCenter: "Nellipaka_Buttaigudem_D (Andhra Pradesh)",
        OriginCenter: "Nellipaka_Buttaigudem_D (Andhra Pradesh)",
        FacilityCity: "Nellipaka",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6742,
        Pin: 785641,
        DispatchCenter: "Golaghat_BaruaRd_D (Assam)",
        OriginCenter: "Golaghat_BaruaRd_D (Assam)",
        FacilityCity: "Golaghat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6743,
        Pin: 577522,
        DispatchCenter: "Challakere_Hosmane_D (Karnataka)",
        OriginCenter: "Challakere_Hosmane_D (Karnataka)",
        FacilityCity: "Challakere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6744,
        Pin: 391155,
        DispatchCenter: "Bodeli_SatyamPlaza_DPP (Gujarat)",
        OriginCenter: "Bodeli_SatyamPlaza_DPP (Gujarat)",
        FacilityCity: "Bodeli",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6745,
        Pin: 637002,
        DispatchCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        OriginCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        FacilityCity: "Namakkal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6746,
        Pin: 518002,
        DispatchCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        OriginCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6747,
        Pin: 322251,
        DispatchCenter: "Karauli_Subhashnagar_D (Rajasthan)",
        OriginCenter: "Karauli_Subhashnagar_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6748,
        Pin: 816104,
        DispatchCenter: "Pakur_DurgaColny_D (Jharkhand)",
        OriginCenter: "Pakur_DurgaColny_D (Jharkhand)",
        FacilityCity: "Pakur",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6749,
        Pin: 680661,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6750,
        Pin: 691005,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6751,
        Pin: 711304,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6752,
        Pin: 606705,
        DispatchCenter: "Uthangarai_Singarapettai_D (Tamil Nadu)",
        OriginCenter: "Uthangarai_Singarapettai_D (Tamil Nadu)",
        FacilityCity: "Uthangarai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6753,
        Pin: 522401,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6754,
        Pin: 711105,
        DispatchCenter: "Howrah_Kamrangu_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6755,
        Pin: 209293,
        DispatchCenter: "Kanpur_Shivrajpur_D (Uttar Pradesh)",
        OriginCenter: "Kanpur_Shivrajpur_D (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6756,
        Pin: 842002,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6757,
        Pin: 609103,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6758,
        Pin: 110507,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6759,
        Pin: 768011,
        DispatchCenter: "Sambalpur_Tentelpara_H (Orissa)",
        OriginCenter: "Sambalpur_Tentelpara_H (Orissa)",
        FacilityCity: "Sambalpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6760,
        Pin: 400109,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6761,
        Pin: 855117,
        DispatchCenter: "Thakurganj_Kaliyaganj_D (Bihar)",
        OriginCenter: "Thakurganj_Kaliyaganj_D (Bihar)",
        FacilityCity: "Thakurganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6762,
        Pin: 712705,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6763,
        Pin: 382830,
        DispatchCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        OriginCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        FacilityCity: "Vijapur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6764,
        Pin: 232114,
        DispatchCenter: "Chandauli_ShubsNGR_D (Uttar Pradesh)",
        OriginCenter: "Chandauli_ShubsNGR_D (Uttar Pradesh)",
        FacilityCity: "Chandauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6765,
        Pin: 209720,
        DispatchCenter: "Chhibramau_TajpurRd_D (Uttar Pradesh)",
        OriginCenter: "Chhibramau_TajpurRd_D (Uttar Pradesh)",
        FacilityCity: "Chhibramau",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6766,
        Pin: 686510,
        DispatchCenter: "Ranni_Chethakhl_D (Kerala)",
        OriginCenter: "Ranni_Chethakhl_D (Kerala)",
        FacilityCity: "Ranni",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6767,
        Pin: 794005,
        DispatchCenter: "Tura_Chokpot_D (Meghalaya)",
        OriginCenter: "Tura_Chokpot_D (Meghalaya)",
        FacilityCity: "Tura",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6768,
        Pin: 125050,
        DispatchCenter: "Fatehabad_SirsaRd_D (Haryana)",
        OriginCenter: "Fatehabad_SirsaRd_D (Haryana)",
        FacilityCity: "Fatehabad",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6769,
        Pin: 754295,
        DispatchCenter: "Athagarh_Karikol_D (Orissa)",
        OriginCenter: "Athagarh_Karikol_D (Orissa)",
        FacilityCity: "Athagarh",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6770,
        Pin: 679125,
        DispatchCenter: "Shoranur_Akamala_D (Kerala)",
        OriginCenter: "Shoranur_Akamala_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6771,
        Pin: 462047,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6772,
        Pin: 403726,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6773,
        Pin: 713140,
        DispatchCenter: "Katwa_Panchghara_D (West Bengal)",
        OriginCenter: "Katwa_Panchghara_D (West Bengal)",
        FacilityCity: "Katwa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6774,
        Pin: 733201,
        DispatchCenter: "Dalkhola_Bhusamani_D (West Bengal)",
        OriginCenter: "Dalkhola_Bhusamani_D (West Bengal)",
        FacilityCity: "Dalkhola",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6775,
        Pin: 785007,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6776,
        Pin: 383335,
        DispatchCenter: "Bayad_PwnPlza_D (Gujarat)",
        OriginCenter: "Bayad_PwnPlza_D (Gujarat)",
        FacilityCity: "Bayad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6777,
        Pin: 480449,
        DispatchCenter: "Parasia_TekMohlla_DPP (Madhya Pradesh)",
        OriginCenter: "Parasia_TekMohlla_DPP (Madhya Pradesh)",
        FacilityCity: "Parasia",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 6778,
        Pin: 493526,
        DispatchCenter: "BalodaBazar_Pahanda_D (Chhattisgarh)",
        OriginCenter: "BalodaBazar_Pahanda_D (Chhattisgarh)",
        FacilityCity: "Baloda Bazar",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6779,
        Pin: 177040,
        DispatchCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        OriginCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        FacilityCity: "Hamirpur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6780,
        Pin: 574203,
        DispatchCenter: "Puttur_Darbe_D (Karnataka)",
        OriginCenter: "Puttur_Darbe_D (Karnataka)",
        FacilityCity: "Puttur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6781,
        Pin: 577205,
        DispatchCenter: "Shimoga_Eshwariah_I (Karnataka)",
        OriginCenter: "Shimoga_Eshwariah_I (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6782,
        Pin: 232325,
        DispatchCenter: "Gahmar_KhuduraPthra_DPP (Uttar Pradesh)",
        OriginCenter: "Gahmar_KhuduraPthra_DPP (Uttar Pradesh)",
        FacilityCity: "Gahmar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6783,
        Pin: 248160,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6784,
        Pin: 486887,
        DispatchCenter: "Singrauli_Waidhan2_D (Madhya Pradesh)",
        OriginCenter: "Singrauli_Waidhan2_D (Madhya Pradesh)",
        FacilityCity: "Singrauli",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 6785,
        Pin: 403110,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6786,
        Pin: 415706,
        DispatchCenter: "Dapoli_Ghimhavane_D (Maharashtra)",
        OriginCenter: "Dapoli_Ghimhavane_D (Maharashtra)",
        FacilityCity: "Dapoli",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6787,
        Pin: 141009,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6788,
        Pin: 761143,
        DispatchCenter: "Jagannathprasad_Jirabadi_DPP (Orissa)",
        OriginCenter: "Jagannathprasad_Jirabadi_DPP (Orissa)",
        FacilityCity: "Jagannathprasad",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6789,
        Pin: 574260,
        DispatchCenter: "Vitla_FawzBuldng_DPP (Karnataka)",
        OriginCenter: "Vitla_FawzBuldng_DPP (Karnataka)",
        FacilityCity: "Vitla",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6790,
        Pin: 392170,
        DispatchCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        OriginCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        FacilityCity: "Jambusar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6791,
        Pin: 503123,
        DispatchCenter: "Kamareddy_KPRcolony_D (Telangana)",
        OriginCenter: "Kamareddy_KPRcolony_D (Telangana)",
        FacilityCity: "Kamareddy",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6792,
        Pin: 562109,
        DispatchCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        OriginCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6793,
        Pin: 743248,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6794,
        Pin: 480110,
        DispatchCenter: "Chhindwara_Chand_D (Madhya Pradesh)",
        OriginCenter: "Chhindwara_Chand_D (Madhya Pradesh)",
        FacilityCity: "Chhindwara",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6795,
        Pin: 509131,
        DispatchCenter: "AtmakurTS_Srinivasa_DPP (Telangana)",
        OriginCenter: "AtmakurTS_Srinivasa_DPP (Telangana)",
        FacilityCity: "Atmakur-TS",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6796,
        Pin: 382030,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6797,
        Pin: 486007,
        DispatchCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        OriginCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        FacilityCity: "Rewa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 6798,
        Pin: 451550,
        DispatchCenter: "Barwani_IGcolony_D (Madhya Pradesh)",
        OriginCenter: "Barwani_IGcolony_D (Madhya Pradesh)",
        FacilityCity: "Barwani",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 6799,
        Pin: 384225,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6800,
        Pin: 572211,
        DispatchCenter: "Turuvukere_Havala_D (Karnataka)",
        OriginCenter: "Turuvukere_Havala_D (Karnataka)",
        FacilityCity: "Turuvukere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6801,
        Pin: 410202,
        DispatchCenter: "Mumbai_Ariwali_L (Maharashtra)",
        OriginCenter: "Mumbai_Ariwali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6802,
        Pin: 391775,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6803,
        Pin: 415603,
        DispatchCenter: "Chiplun_Burumtali_D (Maharashtra)",
        OriginCenter: "Chiplun_Burumtali_D (Maharashtra)",
        FacilityCity: "Chiplun",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6804,
        Pin: 626134,
        DispatchCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        OriginCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        FacilityCity: "Aruppukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6805,
        Pin: 141014,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6806,
        Pin: 131102,
        DispatchCenter: "Panipat_Samalkha_D (Haryana)",
        OriginCenter: "Panipat_Samalkha_D (Haryana)",
        FacilityCity: "Panipat",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6807,
        Pin: 413252,
        DispatchCenter: "Kurduwadi_Dattanagar_D (Maharashtra)",
        OriginCenter: "Kurduwadi_Dattanagar_D (Maharashtra)",
        FacilityCity: "Kurduwadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6808,
        Pin: 273152,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6809,
        Pin: 584134,
        DispatchCenter: "Raichur_Wadloor_I (Karnataka)",
        OriginCenter: "Raichur_Wadloor_I (Karnataka)",
        FacilityCity: "Raichur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6810,
        Pin: 501506,
        DispatchCenter: "Ibrahimpatnam_Mbrnagar_D (Telangana)",
        OriginCenter: "Ibrahimpatnam_Mbrnagar_D (Telangana)",
        FacilityCity: "Ibrahimpatnam",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6811,
        Pin: 173022,
        DispatchCenter: "Nahan_Dadahu_D (Himachal Pradesh)",
        OriginCenter: "Nahan_Dadahu_D (Himachal Pradesh)",
        FacilityCity: "Nahan",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6812,
        Pin: 742188,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6813,
        Pin: 212214,
        DispatchCenter: "SarayAnkil_Fkrbd_D (Uttar Pradesh)",
        OriginCenter: "SarayAnkil_Fkrbd_D (Uttar Pradesh)",
        FacilityCity: "Saray Ankil",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6814,
        Pin: 387430,
        DispatchCenter: "Nadiad_Shantinagar_D (Gujarat)",
        OriginCenter: "Nadiad_Shantinagar_D (Gujarat)",
        FacilityCity: "Nadiad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6815,
        Pin: 713326,
        DispatchCenter: "Asansol_Gobindapur_D (West Bengal)",
        OriginCenter: "Asansol_Gobindapur_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6816,
        Pin: 202399,
        DispatchCenter: "Dibai_Galibpur_D (Uttar Pradesh)",
        OriginCenter: "Dibai_Galibpur_D (Uttar Pradesh)",
        FacilityCity: "Dibai",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6817,
        Pin: 390011,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6818,
        Pin: 712303,
        DispatchCenter: "Jamalpur_HalaraVlg_D (West Bengal)",
        OriginCenter: "Jamalpur_HalaraVlg_D (West Bengal)",
        FacilityCity: "Jamalpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6819,
        Pin: 524318,
        DispatchCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        OriginCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6820,
        Pin: 639008,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6821,
        Pin: 307001,
        DispatchCenter: "Sirohi_Vedhaynathcolony_D (Rajasthan)",
        OriginCenter: "Sirohi_Vedhaynathcolony_D (Rajasthan)",
        FacilityCity: "Sirohi",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6822,
        Pin: 626183,
        DispatchCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        OriginCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        FacilityCity: "Sivakasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6823,
        Pin: 233231,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6824,
        Pin: 621714,
        DispatchCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        OriginCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        FacilityCity: "Ariyalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6825,
        Pin: 723129,
        DispatchCenter: "Manbazar_Central_DPP_1 (West Bengal)",
        OriginCenter: "Manbazar_Central_DPP_1 (West Bengal)",
        FacilityCity: "Manbazar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6826,
        Pin: 246123,
        DispatchCenter: "Paithani_Siloli_DPP (Uttarakhand)",
        OriginCenter: "Paithani_Siloli_DPP (Uttarakhand)",
        FacilityCity: "Paithani",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6827,
        Pin: 854334,
        DispatchCenter: "Araria_BusStand_D (Bihar)",
        OriginCenter: "Araria_BusStand_D (Bihar)",
        FacilityCity: "Araria",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6828,
        Pin: 362256,
        DispatchCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        OriginCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        FacilityCity: "Veraval",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6829,
        Pin: 508255,
        DispatchCenter: "Chandur_ShivajiNagar_D (Telangana)",
        OriginCenter: "Chandur_ShivajiNagar_D (Telangana)",
        FacilityCity: "Chandur",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6830,
        Pin: 641302,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6831,
        Pin: 229122,
        DispatchCenter: "Bacchawaran_BhuyianTemple_D (Uttar Pradesh)",
        OriginCenter: "Bacchawaran_BhuyianTemple_D (Uttar Pradesh)",
        FacilityCity: "Bacchawaran",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6832,
        Pin: 759111,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6833,
        Pin: 414111,
        DispatchCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        OriginCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        FacilityCity: "Ahmednagar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6834,
        Pin: 415639,
        DispatchCenter: "Ratnagiri_GaneshNagar_D (Maharashtra)",
        OriginCenter: "Ratnagiri_GaneshNagar_D (Maharashtra)",
        FacilityCity: "Ratnagiri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6835,
        Pin: 272193,
        DispatchCenter: "Domariyaganj_Chaukhara_D (Uttar Pradesh)",
        OriginCenter: "Domariyaganj_Chaukhara_D (Uttar Pradesh)",
        FacilityCity: "Domariyaganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6836,
        Pin: 301414,
        DispatchCenter: "RajgarhRJ_KarothRd_D (Rajasthan)",
        OriginCenter: "RajgarhRJ_KarothRd_D (Rajasthan)",
        FacilityCity: "Rajgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6837,
        Pin: 614622,
        DispatchCenter: "Pudukkottai_Lnpuram_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Lnpuram_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6838,
        Pin: 828123,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6839,
        Pin: 500252,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6840,
        Pin: 524227,
        DispatchCenter: "Pamuru_ViruvoruRd_D (Andhra Pradesh)",
        OriginCenter: "Pamuru_ViruvoruRd_D (Andhra Pradesh)",
        FacilityCity: "Pamuru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6841,
        Pin: 735208,
        DispatchCenter: "Jaigaon_Manglabarey_D (West Bengal)",
        OriginCenter: "Jaigaon_Manglabarey_D (West Bengal)",
        FacilityCity: "Jaigaon",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6842,
        Pin: 122506,
        DispatchCenter: "Gurgaon_Farukhnagar_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6843,
        Pin: 627806,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6844,
        Pin: 486446,
        DispatchCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        OriginCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        FacilityCity: "Rewa",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6845,
        Pin: 695312,
        DispatchCenter: "Attingal_Central_D_2 (Kerala)",
        OriginCenter: "Attingal_Central_D_2 (Kerala)",
        FacilityCity: "Attingal",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6846,
        Pin: 686523,
        DispatchCenter: "Pala_PonadKaroor_D (Kerala)",
        OriginCenter: "Pala_PonadKaroor_D (Kerala)",
        FacilityCity: "Pala",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6847,
        Pin: 403514,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6848,
        Pin: 755014,
        DispatchCenter: "Mangalpur_GayatriColony_D (Orissa)",
        OriginCenter: "Mangalpur_GayatriColony_D (Orissa)",
        FacilityCity: "Mangalpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6849,
        Pin: 209728,
        DispatchCenter: "Chhibramau_TajpurRd_D (Uttar Pradesh)",
        OriginCenter: "Chhibramau_TajpurRd_D (Uttar Pradesh)",
        FacilityCity: "Chhibramau",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6850,
        Pin: 761006,
        DispatchCenter: "Chikiti_Bhlasingi_D (Orissa)",
        OriginCenter: "Chikiti_Bhlasingi_D (Orissa)",
        FacilityCity: "Chikiti",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6851,
        Pin: 517131,
        DispatchCenter: "Chittoor_Murukambattu_D (Andhra Pradesh)",
        OriginCenter: "Chittoor_Murukambattu_D (Andhra Pradesh)",
        FacilityCity: "Chittoor",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6852,
        Pin: 243506,
        DispatchCenter: "Faridpur_RanaClny_D (Uttar Pradesh)",
        OriginCenter: "Faridpur_RanaClny_D (Uttar Pradesh)",
        FacilityCity: "Faridpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6853,
        Pin: 695505,
        DispatchCenter: "Neyatinkra_Kiliyoor_D (Kerala)",
        OriginCenter: "Neyatinkra_Kiliyoor_D (Kerala)",
        FacilityCity: "Neyyattinkara",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6854,
        Pin: 209866,
        DispatchCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        OriginCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        FacilityCity: "Unnao",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6855,
        Pin: 490010,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6856,
        Pin: 497335,
        DispatchCenter: "Baikunthpur_Mahlpra_DPP (Chhattisgarh)",
        OriginCenter: "Baikunthpur_Mahlpra_DPP (Chhattisgarh)",
        FacilityCity: "Baikunthpur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6857,
        Pin: 629168,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6858,
        Pin: 124202,
        DispatchCenter: "Jhajjar_Policeline_I (Haryana)",
        OriginCenter: "Jhajjar_Policeline_I (Haryana)",
        FacilityCity: "Jhajjar",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6859,
        Pin: 312606,
        DispatchCenter: "Jawad_Vajihiwada_D (Madhya Pradesh)",
        OriginCenter: "Jawad_Vajihiwada_D (Madhya Pradesh)",
        FacilityCity: "Jawad",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6860,
        Pin: 627008,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6861,
        Pin: 670315,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6862,
        Pin: 151503,
        DispatchCenter: "Mansa_Budhlada2_D (Punjab)",
        OriginCenter: "Mansa_Budhlada2_D (Punjab)",
        FacilityCity: "Mansa",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6863,
        Pin: 456776,
        DispatchCenter: "Badnagar_Teacherscolony_D (Madhya Pradesh)",
        OriginCenter: "Badnagar_Teacherscolony_D (Madhya Pradesh)",
        FacilityCity: "Badnagar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 6864,
        Pin: 691553,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6865,
        Pin: 688536,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6866,
        Pin: 562162,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6867,
        Pin: 764043,
        DispatchCenter: "Jeypore_Bariniput_I (Orissa)",
        OriginCenter: "Jeypore_Bariniput_I (Orissa)",
        FacilityCity: "Jeypore",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6868,
        Pin: 577120,
        DispatchCenter: "Koppa_Haranduru_D (Karnataka)",
        OriginCenter: "Koppa_Haranduru_D (Karnataka)",
        FacilityCity: "Koppa-ka",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6869,
        Pin: 466554,
        DispatchCenter: "Hoshangabad_BabaiRd_DPP (Madhya Pradesh)",
        OriginCenter: "Hoshangabad_BabaiRd_DPP (Madhya Pradesh)",
        FacilityCity: "Hoshangabad",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6870,
        Pin: 229418,
        DispatchCenter: "Kunda_KaretiRd_D (Uttar Pradesh)",
        OriginCenter: "Kunda_KaretiRd_D (Uttar Pradesh)",
        FacilityCity: "Kunda",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6871,
        Pin: 600111,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6872,
        Pin: 581105,
        DispatchCenter: "Dharwad_Kamalpur_D (Karnataka)",
        OriginCenter: "Dharwad_Kamalpur_D (Karnataka)",
        FacilityCity: "Dharwad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6873,
        Pin: 534449,
        DispatchCenter: "DwarakaTirumala_PoliceStn_D (Andhra Pradesh)",
        OriginCenter: "DwarakaTirumala_PoliceStn_D (Andhra Pradesh)",
        FacilityCity: "Dwaraka Tirumala",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6874,
        Pin: 609109,
        DispatchCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        OriginCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        FacilityCity: "Sirkazhi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6875,
        Pin: 388210,
        DispatchCenter: "Umreth_PltinumPlza_DPP (Gujarat)",
        OriginCenter: "Umreth_PltinumPlza_DPP (Gujarat)",
        FacilityCity: "Umreth",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6876,
        Pin: 144202,
        DispatchCenter: "SriHargobindpur_Talwara_D (Punjab)",
        OriginCenter: "SriHargobindpur_Talwara_D (Punjab)",
        FacilityCity: "Sri Hargobindpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 6877,
        Pin: 607204,
        DispatchCenter: "Ulundurpet_HarishNgr_D (Tamil Nadu)",
        OriginCenter: "Ulundurpet_HarishNgr_D (Tamil Nadu)",
        FacilityCity: "Ulundurpet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6878,
        Pin: 752018,
        DispatchCenter: "Nirakarpur_Rameswar_D (Orissa)",
        OriginCenter: "Nirakarpur_Rameswar_D (Orissa)",
        FacilityCity: "Nirakarpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6879,
        Pin: 624712,
        DispatchCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        OriginCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        FacilityCity: "Oddanchatram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6880,
        Pin: 273002,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6881,
        Pin: 795009,
        DispatchCenter: "Imphal_Nambol_D (Manipur)",
        OriginCenter: "Imphal_Nambol_D (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6882,
        Pin: 683501,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6883,
        Pin: 614707,
        DispatchCenter: "Pushpavanam_Vedaranyam_D (Tamil Nadu)",
        OriginCenter: "Pushpavanam_Vedaranyam_D (Tamil Nadu)",
        FacilityCity: "Pushpavanam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6884,
        Pin: 641604,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6885,
        Pin: 127022,
        DispatchCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        OriginCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        FacilityCity: "Charkhi Dadri",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6886,
        Pin: 243503,
        DispatchCenter: "Faridpur_RanaClny_D (Uttar Pradesh)",
        OriginCenter: "Faridpur_RanaClny_D (Uttar Pradesh)",
        FacilityCity: "Faridpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6887,
        Pin: 583238,
        DispatchCenter: "Koppal_PragatiNagar_D (Karnataka)",
        OriginCenter: "Koppal_PragatiNagar_D (Karnataka)",
        FacilityCity: "Koppal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6888,
        Pin: 500097,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6889,
        Pin: 493888,
        DispatchCenter: "Gariyaband_Dongari_D (Chhattisgarh)",
        OriginCenter: "Gariyaband_Dongari_D (Chhattisgarh)",
        FacilityCity: "Gariyaband",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6890,
        Pin: 382443,
        DispatchCenter: "Ahmedabad_Kanera_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6891,
        Pin: 343041,
        DispatchCenter: "Sanchore_Lakshmingr_D (Rajasthan)",
        OriginCenter: "Sanchore_Lakshmingr_D (Rajasthan)",
        FacilityCity: "Sanchore",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6892,
        Pin: 635813,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6893,
        Pin: 400071,
        DispatchCenter: "Mumbai_Chembur_L (Maharashtra)",
        OriginCenter: "Mumbai_Chembur_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6894,
        Pin: 482008,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6895,
        Pin: 396125,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6896,
        Pin: 679308,
        DispatchCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        OriginCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        FacilityCity: "Sankaramangalam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6897,
        Pin: 845437,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6898,
        Pin: 606103,
        DispatchCenter: "Virudhachalam_Tamilnagar_D (Tamil Nadu)",
        OriginCenter: "Virudhachalam_Tamilnagar_D (Tamil Nadu)",
        FacilityCity: "Virudhachalam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6899,
        Pin: 678007,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6900,
        Pin: 609807,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6901,
        Pin: 516321,
        DispatchCenter: "Vempalli_Kathaluru_D (Andhra Pradesh)",
        OriginCenter: "Vempalli_Kathaluru_D (Andhra Pradesh)",
        FacilityCity: "Vempalli",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6902,
        Pin: 711323,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6903,
        Pin: 470771,
        DispatchCenter: "Damoh_Chouprakhurd_D (Madhya Pradesh)",
        OriginCenter: "Damoh_Chouprakhurd_D (Madhya Pradesh)",
        FacilityCity: "Damoh",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 6904,
        Pin: 577223,
        DispatchCenter: "Honnali_TMroad_D (Karnataka)",
        OriginCenter: "Honnali_TMroad_D (Karnataka)",
        FacilityCity: "Honnali",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6905,
        Pin: 311601,
        DispatchCenter: "Mandalgarh_Vakilcolony_D (Rajasthan)",
        OriginCenter: "Mandalgarh_Vakilcolony_D (Rajasthan)",
        FacilityCity: "Mandalgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6906,
        Pin: 700150,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6907,
        Pin: 143401,
        DispatchCenter: "TarnTaranSahib_BasantAvenue_D (Punjab)",
        OriginCenter: "TarnTaranSahib_BasantAvenue_D (Punjab)",
        FacilityCity: "Tarn Taran Sahib",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6908,
        Pin: 673027,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6909,
        Pin: 261136,
        DispatchCenter: "Laharpur_TehsilHQ_D (Uttar Pradesh)",
        OriginCenter: "Laharpur_TehsilHQ_D (Uttar Pradesh)",
        FacilityCity: "Laharpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6910,
        Pin: 572202,
        DispatchCenter: "Tiptur_Chmundeshwri_D (Karnataka)",
        OriginCenter: "Tiptur_Chmundeshwri_D (Karnataka)",
        FacilityCity: "Tiptur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6911,
        Pin: 524323,
        DispatchCenter: "Nellore_Bccolony_D (Andhra Pradesh)",
        OriginCenter: "Nellore_Bccolony_D (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6912,
        Pin: 142054,
        DispatchCenter: "Moga_Duneke_I (Punjab)",
        OriginCenter: "Moga_Duneke_I (Punjab)",
        FacilityCity: "Moga",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6913,
        Pin: 787060,
        DispatchCenter: "Jonai_Murkongselek_D (Assam)",
        OriginCenter: "Jonai_Murkongselek_D (Assam)",
        FacilityCity: "Jonai",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6914,
        Pin: 175138,
        DispatchCenter: "Kullu_Mohal_D (Himachal Pradesh)",
        OriginCenter: "Kullu_Mohal_D (Himachal Pradesh)",
        FacilityCity: "Kullu",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 6915,
        Pin: 765020,
        DispatchCenter: "Muniguda_TehsilOfcDPP_D (Orissa)",
        OriginCenter: "Muniguda_TehsilOfcDPP_D (Orissa)",
        FacilityCity: "Muniguda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6916,
        Pin: 580030,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6917,
        Pin: 389190,
        DispatchCenter: "Jhalod_Marutinandancolony_DPP (Gujarat)",
        OriginCenter: "Jhalod_Marutinandancolony_DPP (Gujarat)",
        FacilityCity: "Jhalod",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6918,
        Pin: 303505,
        DispatchCenter: "Lalsot_Ward34_D (Rajasthan)",
        OriginCenter: "Lalsot_Ward34_D (Rajasthan)",
        FacilityCity: "Lalsot",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6919,
        Pin: 814124,
        DispatchCenter: "Shikaripara_Karakata_D (Jharkhand)",
        OriginCenter: "Shikaripara_Karakata_D (Jharkhand)",
        FacilityCity: "Shikaripara",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6920,
        Pin: 736156,
        DispatchCenter: "CoochBehar_khagrabari_I (West Bengal)",
        OriginCenter: "CoochBehar_khagrabari_I (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6921,
        Pin: 689673,
        DispatchCenter: "Ranni_Chethakhl_D (Kerala)",
        OriginCenter: "Ranni_Chethakhl_D (Kerala)",
        FacilityCity: "Ranni",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6922,
        Pin: 641606,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6923,
        Pin: 143301,
        DispatchCenter: "TarnTaranSahib_BasantAvenue_D (Punjab)",
        OriginCenter: "TarnTaranSahib_BasantAvenue_D (Punjab)",
        FacilityCity: "Tarn Taran Sahib",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6924,
        Pin: 673023,
        DispatchCenter: "Thamarassery_Raoth_D (Kerala)",
        OriginCenter: "Thamarassery_Raoth_D (Kerala)",
        FacilityCity: "Thamarassery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6925,
        Pin: 689646,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6926,
        Pin: 464113,
        DispatchCenter: "Berasia_Mohanpura_DPP (Madhya Pradesh)",
        OriginCenter: "Berasia_Mohanpura_DPP (Madhya Pradesh)",
        FacilityCity: "Berasia",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6927,
        Pin: 396325,
        DispatchCenter: "Bilimora_DC (Gujarat)",
        OriginCenter: "Bilimora_DC (Gujarat)",
        FacilityCity: "Bilimora",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6928,
        Pin: 574274,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6929,
        Pin: 683574,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6930,
        Pin: 146110,
        DispatchCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        OriginCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 6931,
        Pin: 721301,
        DispatchCenter: "Kharagpur_Nimpura_I (West Bengal)",
        OriginCenter: "Kharagpur_Nimpura_I (West Bengal)",
        FacilityCity: "Kharagpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6932,
        Pin: 636030,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6933,
        Pin: 231219,
        DispatchCenter: "Chopan_Lokocly_DPP (Uttar Pradesh)",
        OriginCenter: "Chopan_Lokocly_DPP (Uttar Pradesh)",
        FacilityCity: "Chopan",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6934,
        Pin: 753007,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6935,
        Pin: 756034,
        DispatchCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        OriginCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        FacilityCity: "Dantan",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6936,
        Pin: 756164,
        DispatchCenter: "Bhadrak_Deuli_D (Orissa)",
        OriginCenter: "Bhadrak_Deuli_D (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6937,
        Pin: 691329,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6938,
        Pin: 445101,
        DispatchCenter: "Yavatmal_JajuDPP_D (Maharashtra)",
        OriginCenter: "Yavatmal_JajuDPP_D (Maharashtra)",
        FacilityCity: "Yavatmal",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6939,
        Pin: 632401,
        DispatchCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ranipet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6940,
        Pin: 737116,
        DispatchCenter: "Mangan_Barfok_D (Sikkim)",
        OriginCenter: "Mangan_Barfok_D (Sikkim)",
        FacilityCity: "Mangan",
        FacilityState: "Sikkim",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6941,
        Pin: 785667,
        DispatchCenter: "Sivasagar_Pdinnagar_D (Assam)",
        OriginCenter: "Sivasagar_Pdinnagar_D (Assam)",
        FacilityCity: "Sivasagar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6942,
        Pin: 621209,
        DispatchCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        OriginCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        FacilityCity: "Kulithalai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6943,
        Pin: 609601,
        DispatchCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        OriginCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        FacilityCity: "Karaikal",
        FacilityState: "Pondicherry",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6944,
        Pin: 230125,
        DispatchCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        OriginCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        FacilityCity: "Amethi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6945,
        Pin: 142027,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6946,
        Pin: 344031,
        DispatchCenter: "Dhorimana_VishnuColony_D (Rajasthan)",
        OriginCenter: "Dhorimana_VishnuColony_D (Rajasthan)",
        FacilityCity: "Dhorimana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6947,
        Pin: 471105,
        DispatchCenter: "Chhatarpur_SagarRd_D (Madhya Pradesh)",
        OriginCenter: "Chhatarpur_SagarRd_D (Madhya Pradesh)",
        FacilityCity: "Chhatarpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 6948,
        Pin: 413314,
        DispatchCenter: "Atapadi_VidyaNagar_D (Maharashtra)",
        OriginCenter: "Atapadi_VidyaNagar_D (Maharashtra)",
        FacilityCity: "Atapadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6949,
        Pin: 683561,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 6950,
        Pin: 383305,
        DispatchCenter: "Himmatnagar_Hadiyol_D (Gujarat)",
        OriginCenter: "Himmatnagar_Hadiyol_D (Gujarat)",
        FacilityCity: "Himmatnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6951,
        Pin: 793008,
        DispatchCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        OriginCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6952,
        Pin: 263638,
        DispatchCenter: "Someshwar_Lakhnari_DPP (Uttarakhand)",
        OriginCenter: "Someshwar_Lakhnari_DPP (Uttarakhand)",
        FacilityCity: "Someshwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 6953,
        Pin: 524316,
        DispatchCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        OriginCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6954,
        Pin: 752114,
        DispatchCenter: "Nimapara_Nuharakiri_DPP (Orissa)",
        OriginCenter: "Nimapara_Nuharakiri_DPP (Orissa)",
        FacilityCity: "Nimapara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6955,
        Pin: 424311,
        DispatchCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        OriginCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        FacilityCity: "Dhule",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 6956,
        Pin: 444809,
        DispatchCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        OriginCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        FacilityCity: "Paratwada",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6957,
        Pin: 126152,
        DispatchCenter: "Jind_Narwana_DPP (Haryana)",
        OriginCenter: "Jind_Narwana_DPP (Haryana)",
        FacilityCity: "Jind",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6958,
        Pin: 425302,
        DispatchCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        OriginCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        FacilityCity: "Bhusawal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6959,
        Pin: 785010,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6960,
        Pin: 752082,
        DispatchCenter: "Nayagarh_Mathakhajuria_D (Orissa)",
        OriginCenter: "Nayagarh_Mathakhajuria_D (Orissa)",
        FacilityCity: "Nayagarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6961,
        Pin: 742306,
        DispatchCenter: "Domkal_StateBank_D (West Bengal)",
        OriginCenter: "Domkal_StateBank_D (West Bengal)",
        FacilityCity: "Domkal",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 6962,
        Pin: 445307,
        DispatchCenter: "Wani_GuruNGR_D (Maharashtra)",
        OriginCenter: "Wani_GuruNGR_D (Maharashtra)",
        FacilityCity: "Wani",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6963,
        Pin: 570012,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6964,
        Pin: 782003,
        DispatchCenter: "Nagaon_Bangthai_H (Assam)",
        OriginCenter: "Nagaon_Bangthai_H (Assam)",
        FacilityCity: "Nagaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6965,
        Pin: 274405,
        DispatchCenter: "Deoria_Ramnathnagar_D (Uttar Pradesh)",
        OriginCenter: "Deoria_Ramnathnagar_D (Uttar Pradesh)",
        FacilityCity: "Deoria",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6966,
        Pin: 785112,
        DispatchCenter: "Teok_Wardno3_D (Assam)",
        OriginCenter: "Teok_Wardno3_D (Assam)",
        FacilityCity: "Teok",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6967,
        Pin: 680551,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6968,
        Pin: 695016,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6969,
        Pin: 303712,
        DispatchCenter: "Chomu_SamodRd_D (Rajasthan)",
        OriginCenter: "Chomu_SamodRd_D (Rajasthan)",
        FacilityCity: "Chomu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6970,
        Pin: 577215,
        DispatchCenter: "Channagiri_Taralabalu_D (Karnataka)",
        OriginCenter: "Channagiri_Taralabalu_D (Karnataka)",
        FacilityCity: "Channagiri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6971,
        Pin: 689518,
        DispatchCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        OriginCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 6972,
        Pin: 226001,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6973,
        Pin: 110115,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6974,
        Pin: 533352,
        DispatchCenter: "Nellipaka_Buttaigudem_D (Andhra Pradesh)",
        OriginCenter: "Nellipaka_Buttaigudem_D (Andhra Pradesh)",
        FacilityCity: "Nellipaka",
        FacilityState: "Andhra Pradesh",
        Zone : "S1"
      },
      {
        SN: 6975,
        Pin: 600094,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6976,
        Pin: 766029,
        DispatchCenter: "Junagarh_Dharmagarh1_D (Orissa)",
        OriginCenter: "Junagarh_Dharmagarh1_D (Orissa)",
        FacilityCity: "Junagarh-OR",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6977,
        Pin: 754250,
        DispatchCenter: "Kendrapara_Chhagharia_D (Orissa)",
        OriginCenter: "Kendrapara_Chhagharia_D (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6978,
        Pin: 800025,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6979,
        Pin: 370455,
        DispatchCenter: "Mandvi_Ashpura_DPP (Gujarat)",
        OriginCenter: "Mandvi_Ashpura_DPP (Gujarat)",
        FacilityCity: "Mandvi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 6980,
        Pin: 206242,
        DispatchCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        OriginCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        FacilityCity: "Etawah",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 6981,
        Pin: 641407,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6982,
        Pin: 521286,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6983,
        Pin: 505480,
        DispatchCenter: "Husnabad_HanmakondaRd_D (Telangana)",
        OriginCenter: "Husnabad_HanmakondaRd_D (Telangana)",
        FacilityCity: "Husnabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 6984,
        Pin: 757049,
        DispatchCenter: "Baripada_KalikDPP_D (Orissa)",
        OriginCenter: "Baripada_KalikDPP_D (Orissa)",
        FacilityCity: "Baripada",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 6985,
        Pin: 500010,
        DispatchCenter: "Hyderabad_Medchal1_L (Telangana)",
        OriginCenter: "Hyderabad_Medchal_GW (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6986,
        Pin: 481449,
        DispatchCenter: "Katangi_Mattravihar_D (Madhya Pradesh)",
        OriginCenter: "Katangi_Mattravihar_D (Madhya Pradesh)",
        FacilityCity: "Katangi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 6987,
        Pin: 764045,
        DispatchCenter: "Malkangiri_PatroGuda_DPP (Orissa)",
        OriginCenter: "Malkangiri_PatroGuda_DPP (Orissa)",
        FacilityCity: "Malkangiri",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6988,
        Pin: 581396,
        DispatchCenter: "Mallapur_ShtDRDPP_D (Karnataka)",
        OriginCenter: "Mallapur_ShtDRDPP_D (Karnataka)",
        FacilityCity: "Mallapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6989,
        Pin: 853202,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6990,
        Pin: 828125,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6991,
        Pin: 412304,
        DispatchCenter: "Supe_BusStop_D (Maharashtra)",
        OriginCenter: "Supe_BusStop_D (Maharashtra)",
        FacilityCity: "Supe",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6992,
        Pin: 127035,
        DispatchCenter: "Hansi_HanumanColny_DPP (Haryana)",
        OriginCenter: "Hansi_HanumanColny_DPP (Haryana)",
        FacilityCity: "Hansi",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6993,
        Pin: 577001,
        DispatchCenter: "Davangere_Industrialarea_I (Karnataka)",
        OriginCenter: "Davangere_Industrialarea_I (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 6994,
        Pin: 393002,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 6995,
        Pin: 110008,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 6996,
        Pin: 759106,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 6997,
        Pin: 711104,
        DispatchCenter: "Howrah_Kamrangu_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 6998,
        Pin: 442308,
        DispatchCenter: "Hinganghat_DyanshwrWrd_D (Maharashtra)",
        OriginCenter: "Hinganghat_DyanshwrWrd_D (Maharashtra)",
        FacilityCity: "Hinganghat",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 6999,
        Pin: 142033,
        DispatchCenter: "Jagraon_Grainmkt_D (Punjab)",
        OriginCenter: "Jagraon_Grainmkt_D (Punjab)",
        FacilityCity: "Jagraon",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7000,
        Pin: 678020,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7001,
        Pin: 261301,
        DispatchCenter: "Sidhauli_Rmdna_D (Uttar Pradesh)",
        OriginCenter: "Sidhauli_Rmdna_D (Uttar Pradesh)",
        FacilityCity: "Sidhauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7002,
        Pin: 561203,
        DispatchCenter: "Doddaballapura_Kuntanahalli_D (Karnataka)",
        OriginCenter: "Doddaballapura_Kuntanahalli_D (Karnataka)",
        FacilityCity: "Doddaballapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7003,
        Pin: 754034,
        DispatchCenter: "Badamba_Biswambharpur_D (Orissa)",
        OriginCenter: "Badamba_Biswambharpur_D (Orissa)",
        FacilityCity: "Badamba",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7004,
        Pin: 424004,
        DispatchCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        OriginCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        FacilityCity: "Dhule",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7005,
        Pin: 679523,
        DispatchCenter: "Shoranur_Vaniyamkulam_D (Kerala)",
        OriginCenter: "Shoranur_Vaniyamkulam_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7006,
        Pin: 402306,
        DispatchCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        OriginCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        FacilityCity: "Mahad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7007,
        Pin: 626201,
        DispatchCenter: "Sattur_Thendralnagar_DPP (Tamil Nadu)",
        OriginCenter: "Sattur_Thendralnagar_DPP (Tamil Nadu)",
        FacilityCity: "Sattur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7008,
        Pin: 676317,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7009,
        Pin: 400708,
        DispatchCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        OriginCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7010,
        Pin: 641202,
        DispatchCenter: "Pollachi_Kinathukadavu_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Kinathukadavu_D (Tamil Nadu)",
        FacilityCity: "Madukkarai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7011,
        Pin: 560032,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7012,
        Pin: 854335,
        DispatchCenter: "Birpur_Bhimpur_D (Bihar)",
        OriginCenter: "Birpur_Bhimpur_D (Bihar)",
        FacilityCity: "Birpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7013,
        Pin: 516106,
        DispatchCenter: "Koduru_Jagadampalli_D (Andhra Pradesh)",
        OriginCenter: "Koduru_Jagadampalli_D (Andhra Pradesh)",
        FacilityCity: "Koduru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7014,
        Pin: 628105,
        DispatchCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        OriginCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        FacilityCity: "Tuticorin",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7015,
        Pin: 533462,
        DispatchCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        OriginCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        FacilityCity: "Kakinada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7016,
        Pin: 590020,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7017,
        Pin: 752024,
        DispatchCenter: "Tangi_SriDPP_D (Orissa)",
        OriginCenter: "Tangi_SriDPP_D (Orissa)",
        FacilityCity: "Tangi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7018,
        Pin: 800017,
        DispatchCenter: "Patna_Chhotipahari_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7019,
        Pin: 601206,
        DispatchCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        OriginCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7020,
        Pin: 450881,
        DispatchCenter: "Nepanagar_Ward4_D (Madhya Pradesh)",
        OriginCenter: "Nepanagar_Ward4_D (Madhya Pradesh)",
        FacilityCity: "Nepanagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7021,
        Pin: 313031,
        DispatchCenter: "Jhadol_Leelawas_D (Rajasthan)",
        OriginCenter: "Jhadol_Leelawas_D (Rajasthan)",
        FacilityCity: "Jhadol",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7022,
        Pin: 585315,
        DispatchCenter: "Yadgir_Taicolony_DPP (Karnataka)",
        OriginCenter: "Yadgir_Taicolony_DPP (Karnataka)",
        FacilityCity: "Yadgir",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7023,
        Pin: 204216,
        DispatchCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        OriginCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        FacilityCity: "Hathras",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7024,
        Pin: 610206,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7025,
        Pin: 533240,
        DispatchCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        OriginCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        FacilityCity: "Razole",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7026,
        Pin: 793151,
        DispatchCenter: "Jowai_Ummulong_DPP (Meghalaya)",
        OriginCenter: "Jowai_Ummulong_DPP (Meghalaya)",
        FacilityCity: "Jowai",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7027,
        Pin: 243124,
        DispatchCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        OriginCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        FacilityCity: "Bareilly",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7028,
        Pin: 360006,
        DispatchCenter: "Rajkot_Amargadh_H (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7029,
        Pin: 792102,
        DispatchCenter: "Chowkham_Gunanagar_D (Arunachal Pradesh)",
        OriginCenter: "Chowkham_Gunanagar_D (Arunachal Pradesh)",
        FacilityCity: "Chowkham",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7030,
        Pin: 360510,
        DispatchCenter: "Bhanvad_MadhavPlaza_DPP (Gujarat)",
        OriginCenter: "Bhanvad_MadhavPlaza_DPP (Gujarat)",
        FacilityCity: "Bhanvad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7031,
        Pin: 307029,
        DispatchCenter: "Jalore_RoopNgr_D (Rajasthan)",
        OriginCenter: "Jalore_RoopNgr_D (Rajasthan)",
        FacilityCity: "Jalore",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7032,
        Pin: 332406,
        DispatchCenter: "Bajore_Sheeshyoo_DPP (Rajasthan)",
        OriginCenter: "Bajore_Sheeshyoo_DPP (Rajasthan)",
        FacilityCity: "Bajore",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7033,
        Pin: 341514,
        DispatchCenter: "Degana_GovtHospital_D (Rajasthan)",
        OriginCenter: "Degana_GovtHospital_D (Rajasthan)",
        FacilityCity: "Degana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7034,
        Pin: 140102,
        DispatchCenter: "Rupnagar_Towercolony_D (Punjab)",
        OriginCenter: "Rupnagar_Towercolony_D (Punjab)",
        FacilityCity: "Rupnagar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7035,
        Pin: 631204,
        DispatchCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7036,
        Pin: 262524,
        DispatchCenter: "Lohaghat_RanjeetPalace_D (Uttarakhand)",
        OriginCenter: "Lohaghat_RanjeetPalace_D (Uttarakhand)",
        FacilityCity: "Lohaghat",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7037,
        Pin: 454331,
        DispatchCenter: "Kukshi_BurahaniColony_D (Madhya Pradesh)",
        OriginCenter: "Kukshi_BurahaniColony_D (Madhya Pradesh)",
        FacilityCity: "Kukshi",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7038,
        Pin: 573141,
        DispatchCenter: "Turuvukere_Havala_D (Karnataka)",
        OriginCenter: "Turuvukere_Havala_D (Karnataka)",
        FacilityCity: "Turuvukere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7039,
        Pin: 735137,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7040,
        Pin: 226004,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7041,
        Pin: 222110,
        DispatchCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        OriginCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        FacilityCity: "Jaunpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7042,
        Pin: 387002,
        DispatchCenter: "Nadiad_Shantinagar_D (Gujarat)",
        OriginCenter: "Nadiad_Shantinagar_D (Gujarat)",
        FacilityCity: "Nadiad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7043,
        Pin: 605003,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7044,
        Pin: 444312,
        DispatchCenter: "Khamgaon_Wamannagar_DPP (Maharashtra)",
        OriginCenter: "Khamgaon_Wamannagar_DPP (Maharashtra)",
        FacilityCity: "Khamgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7045,
        Pin: 577224,
        DispatchCenter: "Honnali_TMroad_D (Karnataka)",
        OriginCenter: "Honnali_TMroad_D (Karnataka)",
        FacilityCity: "Honnali",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7046,
        Pin: 737111,
        DispatchCenter: "Geyzing_PandayClny_DPP (Sikkim)",
        OriginCenter: "Geyzing_PandayClny_DPP (Sikkim)",
        FacilityCity: "Geyzing",
        FacilityState: "Sikkim",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7047,
        Pin: 571315,
        DispatchCenter: "Nanjangud_Deviramanahalli_D (Karnataka)",
        OriginCenter: "Nanjangud_Deviramanahalli_D (Karnataka)",
        FacilityCity: "Nanjangud",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7048,
        Pin: 181121,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7049,
        Pin: 574118,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7050,
        Pin: 522050,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7051,
        Pin: 783335,
        DispatchCenter: "Agomani_Shenagar_DPP (Assam)",
        OriginCenter: "Agomani_Shenagar_DPP (Assam)",
        FacilityCity: "Agomani",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7052,
        Pin: 781377,
        DispatchCenter: "Tamulpur_Kalakuchi_D (Assam)",
        OriginCenter: "Tamulpur_Kalakuchi_D (Assam)",
        FacilityCity: "Tamulpur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7053,
        Pin: 244717,
        DispatchCenter: "Kashipur_Khokhratal_D (Uttarakhand)",
        OriginCenter: "Kashipur_Khokhratal_D (Uttarakhand)",
        FacilityCity: "Kashipur",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7054,
        Pin: 824235,
        DispatchCenter: "Tekari_BaheliyaBigha_D (Bihar)",
        OriginCenter: "Tekari_BaheliyaBigha_D (Bihar)",
        FacilityCity: "Tekari",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7055,
        Pin: 413310,
        DispatchCenter: "Mhaswad_Drgmtatpl_D (Maharashtra)",
        OriginCenter: "Mhaswad_Drgmtatpl_D (Maharashtra)",
        FacilityCity: "Mhaswad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7056,
        Pin: 768115,
        DispatchCenter: "Kadobahal_Dulampur_D (Orissa)",
        OriginCenter: "Kadobahal_Dulampur_D (Orissa)",
        FacilityCity: "Kadobahal",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7057,
        Pin: 713342,
        DispatchCenter: "Asansol_Addacolony_D (West Bengal)",
        OriginCenter: "Asansol_Addacolony_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7058,
        Pin: 504103,
        DispatchCenter: "Bhainsa_Pipri_D (Telangana)",
        OriginCenter: "Bhainsa_Pipri_D (Telangana)",
        FacilityCity: "Bhainsa",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7059,
        Pin: 247671,
        DispatchCenter: "Laksar_BalawliRd_D (Uttarakhand)",
        OriginCenter: "Laksar_BalawliRd_D (Uttarakhand)",
        FacilityCity: "Laksar",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7060,
        Pin: 394325,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7061,
        Pin: 791110,
        DispatchCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        OriginCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        FacilityCity: "Itanagar",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7062,
        Pin: 413530,
        DispatchCenter: "Nilanga_Panchalcolony_D (Maharashtra)",
        OriginCenter: "Nilanga_Panchalcolony_D (Maharashtra)",
        FacilityCity: "Nilanga",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7063,
        Pin: 363641,
        DispatchCenter: "Morbi_MahavirNagar_D (Gujarat)",
        OriginCenter: "Morbi_MahavirNagar_D (Gujarat)",
        FacilityCity: "Morbi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7064,
        Pin: 493211,
        DispatchCenter: "Raipur_Charoda_D (Chhattisgarh)",
        OriginCenter: "Raipur_Charoda_D (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7065,
        Pin: 492101,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7066,
        Pin: 785672,
        DispatchCenter: "Sivasagar_Pdinnagar_D (Assam)",
        OriginCenter: "Sivasagar_Pdinnagar_D (Assam)",
        FacilityCity: "Sivasagar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7067,
        Pin: 247773,
        DispatchCenter: "Shamli_SalekChandVihar_D (Uttar Pradesh)",
        OriginCenter: "Shamli_SalekChandVihar_D (Uttar Pradesh)",
        FacilityCity: "Shamli",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7068,
        Pin: 175046,
        DispatchCenter: "Karsog_Busstd_D (Himachal Pradesh)",
        OriginCenter: "Karsog_Busstd_D (Himachal Pradesh)",
        FacilityCity: "Karsog",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7069,
        Pin: 413714,
        DispatchCenter: "Sangamner_Ksarwdi_D (Maharashtra)",
        OriginCenter: "Sangamner_Ksarwdi_D (Maharashtra)",
        FacilityCity: "Sangamner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7070,
        Pin: 482053,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7071,
        Pin: 532219,
        DispatchCenter: "Palasa_Mandapam_D (Andhra Pradesh)",
        OriginCenter: "Palasa_Mandapam_D (Andhra Pradesh)",
        FacilityCity: "Palasa",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7072,
        Pin: 533233,
        DispatchCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7073,
        Pin: 821115,
        DispatchCenter: "Sasaram_Karwandiya_I (Bihar)",
        OriginCenter: "Sasaram_Karwandiya_I (Bihar)",
        FacilityCity: "Sasaram",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7074,
        Pin: 249124,
        DispatchCenter: "Tehri_NewTehri_DPP (Uttarakhand)",
        OriginCenter: "Tehri_NewTehri_DPP (Uttarakhand)",
        FacilityCity: "Tehri",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7075,
        Pin: 263150,
        DispatchCenter: "Kashipur_Chakkarpur_D (Uttarakhand)",
        OriginCenter: "Kashipur_Chakkarpur_D (Uttarakhand)",
        FacilityCity: "Kashipur",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7076,
        Pin: 392001,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7077,
        Pin: 483332,
        DispatchCenter: "Katni_Sleemanabad_D (Madhya Pradesh)",
        OriginCenter: "Katni_Sleemanabad_D (Madhya Pradesh)",
        FacilityCity: "Katni",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7078,
        Pin: 533006,
        DispatchCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        OriginCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        FacilityCity: "Kakinada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7079,
        Pin: 451335,
        DispatchCenter: "Khargone_NutanNgr_D (Madhya Pradesh)",
        OriginCenter: "Khargone_NutanNgr_D (Madhya Pradesh)",
        FacilityCity: "Khargone",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7080,
        Pin: 814146,
        DispatchCenter: "Madhupur_Baramsoli_DPP (Jharkhand)",
        OriginCenter: "Madhupur_Baramsoli_DPP (Jharkhand)",
        FacilityCity: "Madhupur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7081,
        Pin: 494449,
        DispatchCenter: "Dantewada_Chitalanka_D (Chhattisgarh)",
        OriginCenter: "Dantewada_Chitalanka_D (Chhattisgarh)",
        FacilityCity: "Dantewada",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7082,
        Pin: 683522,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7083,
        Pin: 201009,
        DispatchCenter: "Noida_KheraDharmpura_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7084,
        Pin: 609311,
        DispatchCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        OriginCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        FacilityCity: "Karaikal",
        FacilityState: "Pondicherry",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7085,
        Pin: 622103,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7086,
        Pin: 302028,
        DispatchCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        OriginCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7087,
        Pin: 502284,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        Zone : "S1"
      },
      {
        SN: 7088,
        Pin: 382225,
        DispatchCenter: "Dholka_Rampur_D (Gujarat)",
        OriginCenter: "Dholka_Rampur_D (Gujarat)",
        FacilityCity: "Dholka",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7089,
        Pin: 691304,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7090,
        Pin: 423107,
        DispatchCenter: "Shirdi_Sakori_D (Maharashtra)",
        OriginCenter: "Shirdi_Sakori_D (Maharashtra)",
        FacilityCity: "Shirdi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7091,
        Pin: 682506,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7092,
        Pin: 141112,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7093,
        Pin: 803114,
        DispatchCenter: "Chandi_SH78_D (Bihar)",
        OriginCenter: "Chandi_SH78_D (Bihar)",
        FacilityCity: "Chandi",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7094,
        Pin: 636904,
        DispatchCenter: "Harur_EBOfc_D (Tamil Nadu)",
        OriginCenter: "Harur_EBOfc_D (Tamil Nadu)",
        FacilityCity: "Harur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7095,
        Pin: 639111,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7096,
        Pin: 403806,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7097,
        Pin: 577418,
        DispatchCenter: "Hosanagar_Shimogard_DPP (Karnataka)",
        OriginCenter: "Hosanagar_Shimogard_DPP (Karnataka)",
        FacilityCity: "Hosanagar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7098,
        Pin: 721258,
        DispatchCenter: "Midnapore_Sarbera_DPP (West Bengal)",
        OriginCenter: "Midnapore_Sarbera_DPP (West Bengal)",
        FacilityCity: "Midnapore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7099,
        Pin: 410301,
        DispatchCenter: "Lonavala_NngrgnRd_D (Maharashtra)",
        OriginCenter: "Lonavala_NngrgnRd_D (Maharashtra)",
        FacilityCity: "Lonavala",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7100,
        Pin: 609309,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7101,
        Pin: 834012,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7102,
        Pin: 638752,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7103,
        Pin: 628753,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7104,
        Pin: 535006,
        DispatchCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        OriginCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        FacilityCity: "Vizianagaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7105,
        Pin: 412212,
        DispatchCenter: "Khandala_Kapurhol_D (Maharashtra)",
        OriginCenter: "Khandala_Kapurhol_D (Maharashtra)",
        FacilityCity: "Khandala",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7106,
        Pin: 246124,
        DispatchCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        OriginCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        FacilityCity: "Gumkhal",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7107,
        Pin: 802120,
        DispatchCenter: "Dumraon_MahvidylaRd_D (Bihar)",
        OriginCenter: "Dumraon_MahvidylaRd_D (Bihar)",
        FacilityCity: "Dumraon",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7108,
        Pin: 364060,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7109,
        Pin: 401607,
        DispatchCenter: "Dahanu_Ambemora_D (Maharashtra)",
        OriginCenter: "Dahanu_Ambemora_D (Maharashtra)",
        FacilityCity: "Dahanu",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7110,
        Pin: 396430,
        DispatchCenter: "Bilimora_DC (Gujarat)",
        OriginCenter: "Bilimora_DC (Gujarat)",
        FacilityCity: "Bilimora",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7111,
        Pin: 143021,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7112,
        Pin: 825407,
        DispatchCenter: "Domchanch_Koderma_D (Jharkhand)",
        OriginCenter: "Domchanch_Koderma_D (Jharkhand)",
        FacilityCity: "Domchanch",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7113,
        Pin: 501510,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7114,
        Pin: 591324,
        DispatchCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        OriginCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        FacilityCity: "Athani",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7115,
        Pin: 785666,
        DispatchCenter: "Nazira_Amina_D (Assam)",
        OriginCenter: "Nazira_Amina_D (Assam)",
        FacilityCity: "Nazira",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7116,
        Pin: 322022,
        DispatchCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        OriginCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        FacilityCity: "Sawai Madhopur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7117,
        Pin: 110083,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7118,
        Pin: 586103,
        DispatchCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        OriginCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        FacilityCity: "Bijapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7119,
        Pin: 362550,
        DispatchCenter: "Una_StColony_D (Gujarat)",
        OriginCenter: "Una_StColony_D (Gujarat)",
        FacilityCity: "UNA",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7120,
        Pin: 495001,
        DispatchCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        OriginCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        FacilityCity: "Bilaspur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7121,
        Pin: 209302,
        DispatchCenter: "Rasulabad_ShastriNgr_D (Uttar Pradesh)",
        OriginCenter: "Rasulabad_ShastriNgr_D (Uttar Pradesh)",
        FacilityCity: "Rasulabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7122,
        Pin: 323616,
        DispatchCenter: "Nainwa_Uniyara_D (Rajasthan)",
        OriginCenter: "Nainwa_Uniyara_D (Rajasthan)",
        FacilityCity: "Nainwa",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7123,
        Pin: 785111,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7124,
        Pin: 581339,
        DispatchCenter: "Karwar_Sadashivgad_D (Karnataka)",
        OriginCenter: "Karwar_Sadashivgad_D (Karnataka)",
        FacilityCity: "Karwar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7125,
        Pin: 586204,
        DispatchCenter: "Indi_Chadchan_D (Karnataka)",
        OriginCenter: "Indi_Chadchan_D (Karnataka)",
        FacilityCity: "Indi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7126,
        Pin: 504109,
        DispatchCenter: "Bhainsa_Pipri_D (Telangana)",
        OriginCenter: "Bhainsa_Pipri_D (Telangana)",
        FacilityCity: "Bhainsa",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7127,
        Pin: 140412,
        DispatchCenter: "SirhindFatehgarh_DC (Punjab)",
        OriginCenter: "SirhindFatehgarh_DC (Punjab)",
        FacilityCity: "Sirhind-Fategarh",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7128,
        Pin: 574169,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7129,
        Pin: 506244,
        DispatchCenter: "Ghanpur_Palakrty_D (Telangana)",
        OriginCenter: "Ghanpur_Palakrty_D (Telangana)",
        FacilityCity: "Ghanpur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7130,
        Pin: 520008,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7131,
        Pin: 848209,
        DispatchCenter: "Singhia_Shayampur_D (Bihar)",
        OriginCenter: "Singhia_Shayampur_D (Bihar)",
        FacilityCity: "Singhia",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7132,
        Pin: 577416,
        DispatchCenter: "Shimoga_Eshwariah_I (Karnataka)",
        OriginCenter: "Shimoga_Eshwariah_I (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7133,
        Pin: 250110,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7134,
        Pin: 623108,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7135,
        Pin: 493196,
        DispatchCenter: "Bhatapara_Nayaganj_D (Chhattisgarh)",
        OriginCenter: "Bhatapara_Nayaganj_D (Chhattisgarh)",
        FacilityCity: "Bhatapara",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7136,
        Pin: 176042,
        DispatchCenter: "Bhota_BpassDPP_D (Himachal Pradesh)",
        OriginCenter: "Bhota_BpassDPP_D (Himachal Pradesh)",
        FacilityCity: "Bhota",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7137,
        Pin: 507159,
        DispatchCenter: "Khammam_Mudigonda_H (Telangana)",
        OriginCenter: "Khammam_Mudigonda_H (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7138,
        Pin: 232106,
        DispatchCenter: "Chandauli_Chahaniya_D (Uttar Pradesh)",
        OriginCenter: "Chandauli_Chahaniya_D (Uttar Pradesh)",
        FacilityCity: "Mughal Sarai",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7139,
        Pin: 845434,
        DispatchCenter: "Jiwdhara_Kotwa_D (Bihar)",
        OriginCenter: "Jiwdhara_Kotwa_D (Bihar)",
        FacilityCity: "Jiwdhara",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7140,
        Pin: 244712,
        DispatchCenter: "Kashipur_Khokhratal_D (Uttarakhand)",
        OriginCenter: "Kashipur_Khokhratal_D (Uttarakhand)",
        FacilityCity: "Kashipur",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7141,
        Pin: 722145,
        DispatchCenter: "Khatra_Simlapal_DPP (West Bengal)",
        OriginCenter: "Khatra_Simlapal_DPP (West Bengal)",
        FacilityCity: "Khatra",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7142,
        Pin: 509371,
        DispatchCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        OriginCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        FacilityCity: "Mahabubnagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7143,
        Pin: 625535,
        DispatchCenter: "Usilampatti_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Usilampatti_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Usilampatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7144,
        Pin: 791125,
        DispatchCenter: "Likabali_AaloRd_D (Arunachal Pradesh)",
        OriginCenter: "Likabali_AaloRd_D (Arunachal Pradesh)",
        FacilityCity: "Likabali",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7145,
        Pin: 415610,
        DispatchCenter: "Sangameshwar_Burambi_DPP (Maharashtra)",
        OriginCenter: "Sangameshwar_Burambi_DPP (Maharashtra)",
        FacilityCity: "Sangameshwar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7146,
        Pin: 360375,
        DispatchCenter: "Jetpur_Dasijivanpara_D (Gujarat)",
        OriginCenter: "Jetpur_Dasijivanpara_D (Gujarat)",
        FacilityCity: "Jetpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7147,
        Pin: 224161,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7148,
        Pin: 144624,
        DispatchCenter: "Begowal_PinkMrkt_D (Punjab)",
        OriginCenter: "Begowal_PinkMrkt_D (Punjab)",
        FacilityCity: "Begowal",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7149,
        Pin: 734401,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7150,
        Pin: 764041,
        DispatchCenter: "Koraput_Balda_DPP (Orissa)",
        OriginCenter: "Koraput_Balda_DPP (Orissa)",
        FacilityCity: "Koraput",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7151,
        Pin: 754227,
        DispatchCenter: "Aul_Rasulpur_D (Orissa)",
        OriginCenter: "Aul_Rasulpur_D (Orissa)",
        FacilityCity: "Aul",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7152,
        Pin: 160059,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7153,
        Pin: 622305,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7154,
        Pin: 741166,
        DispatchCenter: "Chapra_Elemnagar_DPP (West Bengal)",
        OriginCenter: "Chapra_Elemnagar_DPP (West Bengal)",
        FacilityCity: "Chapra",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7155,
        Pin: 832402,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7156,
        Pin: 503125,
        DispatchCenter: "Siddipet_Repallewada_DPP (Telangana)",
        OriginCenter: "Siddipet_Repallewada_DPP (Telangana)",
        FacilityCity: "Siddipet",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7157,
        Pin: 712306,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7158,
        Pin: 507303,
        DispatchCenter: "Sathupally_Ayyagaripeta_D (Telangana)",
        OriginCenter: "Sathupally_Ayyagaripeta_D (Telangana)",
        FacilityCity: "Sathupally",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7159,
        Pin: 733154,
        DispatchCenter: "Gangarampr_Ramkrishnpaly_DPP (West Bengal)",
        OriginCenter: "Gangarampr_Ramkrishnpaly_DPP (West Bengal)",
        FacilityCity: "Gangarampur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7160,
        Pin: 517551,
        DispatchCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        OriginCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        FacilityCity: "Tirupati",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7161,
        Pin: 387515,
        DispatchCenter: "Umreth_PltinumPlza_DPP (Gujarat)",
        OriginCenter: "Umreth_PltinumPlza_DPP (Gujarat)",
        FacilityCity: "Umreth",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7162,
        Pin: 855114,
        DispatchCenter: "Katihar_Kantia_D (Bihar)",
        OriginCenter: "Katihar_Kantia_D (Bihar)",
        FacilityCity: "Katihar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7163,
        Pin: 611103,
        DispatchCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        OriginCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        FacilityCity: "Nagapattinam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7164,
        Pin: 683102,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7165,
        Pin: 124517,
        DispatchCenter: "Sonipat_Adreshcolony_D (Haryana)",
        OriginCenter: "Sonipat_Adreshcolony_D (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7166,
        Pin: 323615,
        DispatchCenter: "Lakheri_KrsrdDPP_D (Rajasthan)",
        OriginCenter: "Lakheri_KrsrdDPP_D (Rajasthan)",
        FacilityCity: "Lakheri",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7167,
        Pin: 321411,
        DispatchCenter: "Bayana_Shivcolony_D (Rajasthan)",
        OriginCenter: "Bayana_Shivcolony_D (Rajasthan)",
        FacilityCity: "Bayana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7168,
        Pin: 497001,
        DispatchCenter: "Ambikapur_Bouripara_I (Chhattisgarh)",
        OriginCenter: "Ambikapur_Bouripara_I (Chhattisgarh)",
        FacilityCity: "Ambikapur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7169,
        Pin: 389120,
        DispatchCenter: "Godhra_Soniwada_D (Gujarat)",
        OriginCenter: "Godhra_Soniwada_D (Gujarat)",
        FacilityCity: "Godhra",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7170,
        Pin: 802134,
        DispatchCenter: "Brahmapur_Shahpur_D (Bihar)",
        OriginCenter: "Brahmapur_Shahpur_D (Bihar)",
        FacilityCity: "Brahmapur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7171,
        Pin: 533305,
        DispatchCenter: "Draksharamam_Anaipeta_D (Andhra Pradesh)",
        OriginCenter: "Draksharamam_Anaipeta_D (Andhra Pradesh)",
        FacilityCity: "Draksharamam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7172,
        Pin: 201201,
        DispatchCenter: "Modinagar_SangamAutoAg_D (Uttar Pradesh)",
        OriginCenter: "Modinagar_SangamAutoAg_D (Uttar Pradesh)",
        FacilityCity: "Modinagar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7173,
        Pin: 342602,
        DispatchCenter: "Bilara_Central_DPP_2 (Rajasthan)",
        OriginCenter: "Bilara_Central_DPP_2 (Rajasthan)",
        FacilityCity: "Bilara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7174,
        Pin: 712106,
        DispatchCenter: "Hooghly_Bikramnagar_D (West Bengal)",
        OriginCenter: "Hooghly_Bikramnagar_D (West Bengal)",
        FacilityCity: "Hooghly",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7175,
        Pin: 441914,
        DispatchCenter: "Ramtek_ShivajiWard_D (Maharashtra)",
        OriginCenter: "Ramtek_ShivajiWard_D (Maharashtra)",
        FacilityCity: "Ramtek",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7176,
        Pin: 753015,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7177,
        Pin: 122215,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7178,
        Pin: 515672,
        DispatchCenter: "Anantapur_Dharmavaram_D (Andhra Pradesh)",
        OriginCenter: "Anantapur_Dharmavaram_D (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7179,
        Pin: 577303,
        DispatchCenter: "Shimoga_Eshwariah_I (Karnataka)",
        OriginCenter: "Shimoga_Eshwariah_I (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7180,
        Pin: 811303,
        DispatchCenter: "Chakai_Bheleraidih_D (Bihar)",
        OriginCenter: "Chakai_Bheleraidih_D (Bihar)",
        FacilityCity: "Chakai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7181,
        Pin: 700091,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7182,
        Pin: 335801,
        DispatchCenter: "Pilibanga_BsstdDPP_D (Rajasthan)",
        OriginCenter: "Pilibanga_BsstdDPP_D (Rajasthan)",
        FacilityCity: "Pilibanga",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7183,
        Pin: 632001,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7184,
        Pin: 591106,
        DispatchCenter: "Kittur_Guruwarpeth_DPP (Karnataka)",
        OriginCenter: "Kittur_Guruwarpeth_DPP (Karnataka)",
        FacilityCity: "Kittur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7185,
        Pin: 509376,
        DispatchCenter: "Achampet_Madhuranagar_D (Telangana)",
        OriginCenter: "Achampet_Madhuranagar_D (Telangana)",
        FacilityCity: "Achampet",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7186,
        Pin: 784182,
        DispatchCenter: "Biswanath_Jamugurihat_DPP (Assam)",
        OriginCenter: "Biswanath_Jamugurihat_DPP (Assam)",
        FacilityCity: "BiswanathCH",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7187,
        Pin: 571421,
        DispatchCenter: "Malavalli_KBHclny_D (Karnataka)",
        OriginCenter: "Malavalli_KBHclny_D (Karnataka)",
        FacilityCity: "Malavalli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7188,
        Pin: 828206,
        DispatchCenter: "Chirkunda_Egarkunr_D (Jharkhand)",
        OriginCenter: "Chirkunda_Egarkunr_D (Jharkhand)",
        FacilityCity: "Chirkunda",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7189,
        Pin: 223103,
        DispatchCenter: "Shahganj_Kaudiya_D (Uttar Pradesh)",
        OriginCenter: "Shahganj_Kaudiya_D (Uttar Pradesh)",
        FacilityCity: "Shahganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7190,
        Pin: 755043,
        DispatchCenter: "Jajpur_Panasa_D (Orissa)",
        OriginCenter: "Jajpur_Panasa_D (Orissa)",
        FacilityCity: "Jajpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7191,
        Pin: 334404,
        DispatchCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        OriginCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        FacilityCity: "Bikaner",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7192,
        Pin: 533446,
        DispatchCenter: "Kathipudi_YdvaSt_D (Andhra Pradesh)",
        OriginCenter: "Kathipudi_YdvaSt_D (Andhra Pradesh)",
        FacilityCity: "Kathipudi",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7193,
        Pin: 626101,
        DispatchCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        OriginCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        FacilityCity: "Aruppukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7194,
        Pin: 743200,
        DispatchCenter: "Barrackpore_Lalkuti_D (West Bengal)",
        OriginCenter: "Barrackpore_Lalkuti_D (West Bengal)",
        FacilityCity: "Barrackpore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7195,
        Pin: 394530,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7196,
        Pin: 735224,
        DispatchCenter: "Mainaguri_Debinagar_DPP (West Bengal)",
        OriginCenter: "Mainaguri_Debinagar_DPP (West Bengal)",
        FacilityCity: "Mainaguri",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7197,
        Pin: 623501,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7198,
        Pin: 486008,
        DispatchCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        OriginCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        FacilityCity: "Rewa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7199,
        Pin: 802226,
        DispatchCenter: "Bikramgang_Dehrird_D (Bihar)",
        OriginCenter: "Bikramgang_Dehrird_D (Bihar)",
        FacilityCity: "Bikramganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7200,
        Pin: 691552,
        DispatchCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        OriginCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7201,
        Pin: 481768,
        DispatchCenter: "Mandla_Nainpur_D (Madhya Pradesh)",
        OriginCenter: "Mandla_Nainpur_D (Madhya Pradesh)",
        FacilityCity: "Mandla",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7202,
        Pin: 273165,
        DispatchCenter: "Anandnagar_DhniDhla_DPP (Uttar Pradesh)",
        OriginCenter: "Anandnagar_DhniDhla_DPP (Uttar Pradesh)",
        FacilityCity: "Anandnagar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7203,
        Pin: 141002,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7204,
        Pin: 711316,
        DispatchCenter: "Howrah_Temp_L (West Bengal)",
        OriginCenter: "Howrah_Temp_L (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7205,
        Pin: 141004,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7206,
        Pin: 743287,
        DispatchCenter: "Joypul_Maniktala_D (West Bengal)",
        OriginCenter: "Joypul_Maniktala_D (West Bengal)",
        FacilityCity: "Joypul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7207,
        Pin: 477446,
        DispatchCenter: "Lahar_Beejasen_D (Madhya Pradesh)",
        OriginCenter: "Lahar_Beejasen_D (Madhya Pradesh)",
        FacilityCity: "Lahar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7208,
        Pin: 385001,
        DispatchCenter: "Palanpur_Esbipura1_D (Gujarat)",
        OriginCenter: "Palanpur_Esbipura1_D (Gujarat)",
        FacilityCity: "Palanpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7209,
        Pin: 570031,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7210,
        Pin: 843103,
        DispatchCenter: "Dholi_Etwarpurjointi_D (Bihar)",
        OriginCenter: "Dholi_Etwarpurjointi_D (Bihar)",
        FacilityCity: "Dholi",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7211,
        Pin: 844117,
        DispatchCenter: "Lalganj_Kharauna_D (Bihar)",
        OriginCenter: "Lalganj_Kharauna_D (Bihar)",
        FacilityCity: "Lalganj-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7212,
        Pin: 577513,
        DispatchCenter: "Jagaluru_Tumatelayout_D (Karnataka)",
        OriginCenter: "Jagaluru_Tumatelayout_D (Karnataka)",
        FacilityCity: "Jagaluru",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7213,
        Pin: 425523,
        DispatchCenter: "Phaltan_Kolki_D (Maharashtra)",
        OriginCenter: "Phaltan_Kolki_D (Maharashtra)",
        FacilityCity: "Phaltan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7214,
        Pin: 385421,
        DispatchCenter: "Palanpur_Esbipura1_D (Gujarat)",
        OriginCenter: "Palanpur_Esbipura1_D (Gujarat)",
        FacilityCity: "Palanpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7215,
        Pin: 824116,
        DispatchCenter: "Goh_Golapar_D (Bihar)",
        OriginCenter: "Goh_Golapar_D (Bihar)",
        FacilityCity: "Goh",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7216,
        Pin: 415628,
        DispatchCenter: "Chiplun_Burumtali_D (Maharashtra)",
        OriginCenter: "Chiplun_Burumtali_D (Maharashtra)",
        FacilityCity: "Chiplun",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7217,
        Pin: 261125,
        DispatchCenter: "Sitapur_Lachimanpur_D (Uttar Pradesh)",
        OriginCenter: "Sitapur_Lachimanpur_D (Uttar Pradesh)",
        FacilityCity: "Sitapur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7218,
        Pin: 332405,
        DispatchCenter: "Bajore_Sheeshyoo_DPP (Rajasthan)",
        OriginCenter: "Bajore_Sheeshyoo_DPP (Rajasthan)",
        FacilityCity: "Bajore",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7219,
        Pin: 733156,
        DispatchCenter: "Raiganj_Gobindpur_D (West Bengal)",
        OriginCenter: "Raiganj_Gobindpur_D (West Bengal)",
        FacilityCity: "Raiganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7220,
        Pin: 686010,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7221,
        Pin: 630307,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7222,
        Pin: 604201,
        DispatchCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        OriginCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        FacilityCity: "Gingee",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7223,
        Pin: 614201,
        DispatchCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        OriginCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7224,
        Pin: 413719,
        DispatchCenter: "Shirdi_Sakori_D (Maharashtra)",
        OriginCenter: "Shirdi_Sakori_D (Maharashtra)",
        FacilityCity: "Shirdi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7225,
        Pin: 500072,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7226,
        Pin: 207250,
        DispatchCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        OriginCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        FacilityCity: "Aliganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7227,
        Pin: 365440,
        DispatchCenter: "Amreli_Bhojalpara_D (Gujarat)",
        OriginCenter: "Amreli_Bhojalpara_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7228,
        Pin: 401209,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7229,
        Pin: 625527,
        DispatchCenter: "Usilampatti_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Usilampatti_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Usilampatti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7230,
        Pin: 515867,
        DispatchCenter: "Rayadurgam_Ramnagar_D (Andhra Pradesh)",
        OriginCenter: "Rayadurgam_Ramnagar_D (Andhra Pradesh)",
        FacilityCity: "Rayadurgam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7231,
        Pin: 175026,
        DispatchCenter: "Sarkaghat_Khelag_D (Himachal Pradesh)",
        OriginCenter: "Sarkaghat_Khelag_D (Himachal Pradesh)",
        FacilityCity: "Sarkaghat",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7232,
        Pin: 735206,
        DispatchCenter: "Chalsa_BusStand_D (West Bengal)",
        OriginCenter: "Chalsa_BusStand_D (West Bengal)",
        FacilityCity: "Chalsa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7233,
        Pin: 133302,
        DispatchCenter: "Parwanoo_Sector2_D (Himachal Pradesh)",
        OriginCenter: "Parwanoo_Sector2_D (Himachal Pradesh)",
        FacilityCity: "Parwanoo",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7234,
        Pin: 483119,
        DispatchCenter: "Tendukheda_Shahpura_D (Madhya Pradesh)",
        OriginCenter: "Tendukheda_Shahpura_D (Madhya Pradesh)",
        FacilityCity: "Tendukheda",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7235,
        Pin: 691603,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7236,
        Pin: 673618,
        DispatchCenter: "Koyilandy_Komathukara_D (Kerala)",
        OriginCenter: "Koyilandy_Komathukara_D (Kerala)",
        FacilityCity: "Koyilandy",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7237,
        Pin: 400210,
        DispatchCenter: "Mumbai_Talojamidc_L (Maharashtra)",
        OriginCenter: "Mumbai_Talojamidc_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7238,
        Pin: 712122,
        DispatchCenter: "Kamarpukur_ChatiDPP_D (West Bengal)",
        OriginCenter: "Kamarpukur_ChatiDPP_D (West Bengal)",
        FacilityCity: "Kamarpukur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7239,
        Pin: 585216,
        DispatchCenter: "Shorapur_GandhiNagar_D (Karnataka)",
        OriginCenter: "Shorapur_GandhiNagar_D (Karnataka)",
        FacilityCity: "Shorapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7240,
        Pin: 752119,
        DispatchCenter: "Astaranga_MainRd_DPP (Orissa)",
        OriginCenter: "Astaranga_MainRd_DPP (Orissa)",
        FacilityCity: "Astaranga",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7241,
        Pin: 334002,
        DispatchCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        OriginCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        FacilityCity: "Bikaner",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7242,
        Pin: 193502,
        DispatchCenter: "Bandipora_Onagam_DPP (Jammu & Kashmir)",
        OriginCenter: "Bandipora_Onagam_DPP (Jammu & Kashmir)",
        FacilityCity: "Bandipora",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7243,
        Pin: 845101,
        DispatchCenter: "Bagaha_Gandhinagar_D (Bihar)",
        OriginCenter: "Bagaha_Gandhinagar_D (Bihar)",
        FacilityCity: "Bagaha",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7244,
        Pin: 388130,
        DispatchCenter: "Anand_Borsad_D (Gujarat)",
        OriginCenter: "Anand_Borsad_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7245,
        Pin: 229404,
        DispatchCenter: "Unchahar_Khojanpur_D (Uttar Pradesh)",
        OriginCenter: "Unchahar_Khojanpur_D (Uttar Pradesh)",
        FacilityCity: "Unchahar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7246,
        Pin: 639001,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7247,
        Pin: 700117,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7248,
        Pin: 370465,
        DispatchCenter: "Mandvi_Ashpura_DPP (Gujarat)",
        OriginCenter: "Mandvi_Ashpura_DPP (Gujarat)",
        FacilityCity: "Mandvi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7249,
        Pin: 457778,
        DispatchCenter: "Jhabua_Mojipada_D (Madhya Pradesh)",
        OriginCenter: "Jhabua_Mojipada_D (Madhya Pradesh)",
        FacilityCity: "Jhabua",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7250,
        Pin: 742212,
        DispatchCenter: "Farakka_BngrmDPP_D (West Bengal)",
        OriginCenter: "Farakka_BngrmDPP_D (West Bengal)",
        FacilityCity: "Farakka",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7251,
        Pin: 276305,
        DispatchCenter: "Azamgarh_Saraimeer_DPP (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Saraimeer_DPP (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7252,
        Pin: 458888,
        DispatchCenter: "Garoth_Shamgarh2_DPP (Madhya Pradesh)",
        OriginCenter: "Garoth_Shamgarh2_DPP (Madhya Pradesh)",
        FacilityCity: "Garoth",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7253,
        Pin: 754029,
        DispatchCenter: "Athagarh_Karikol_D (Orissa)",
        OriginCenter: "Athagarh_Karikol_D (Orissa)",
        FacilityCity: "Athagarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7254,
        Pin: 483110,
        DispatchCenter: "ShahpuraMP_Kundam_D (Madhya Pradesh)",
        OriginCenter: "ShahpuraMP_Kundam_D (Madhya Pradesh)",
        FacilityCity: "ShahpuraMP",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7255,
        Pin: 797113,
        DispatchCenter: "Dimapur_Sovima_I (Nagaland)",
        OriginCenter: "Dimapur_Sovima_I (Nagaland)",
        FacilityCity: "Dimapur",
        FacilityState: "Nagaland",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7256,
        Pin: 403721,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7257,
        Pin: 796161,
        DispatchCenter: "Saitual_Thingsulthliah_D (Mizoram)",
        OriginCenter: "Saitual_Thingsulthliah_D (Mizoram)",
        FacilityCity: "Saitual",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7258,
        Pin: 273213,
        DispatchCenter: "Sikriganj_Sikaridihkhurd_D (Uttar Pradesh)",
        OriginCenter: "Sikriganj_Sikaridihkhurd_D (Uttar Pradesh)",
        FacilityCity: "Sikriganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7259,
        Pin: 332002,
        DispatchCenter: "Sikar_Dujod_DPP (Rajasthan)",
        OriginCenter: "Sikar_Dujod_DPP (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7260,
        Pin: 689517,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7261,
        Pin: 425424,
        DispatchCenter: "Shahada_Untavad_D (Maharashtra)",
        OriginCenter: "Shahada_Untavad_D (Maharashtra)",
        FacilityCity: "Shahada",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7262,
        Pin: 786008,
        DispatchCenter: "Dibrugarh_Mohanbari_H (Assam)",
        OriginCenter: "Dibrugarh_Mohanbari_H (Assam)",
        FacilityCity: "Dibrugarh",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7263,
        Pin: 502302,
        DispatchCenter: "Jogipet_Andole_D (Telangana)",
        OriginCenter: "Jogipet_Andole_D (Telangana)",
        FacilityCity: "Jogipet",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7264,
        Pin: 814150,
        DispatchCenter: "Madhupur_Sitarmrd_D (Jharkhand)",
        OriginCenter: "Madhupur_Sitarmrd_D (Jharkhand)",
        FacilityCity: "Madhupur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7265,
        Pin: 496661,
        DispatchCenter: "Raigarh_Kharsia_D (Chhattisgarh)",
        OriginCenter: "Raigarh_Kharsia_D (Chhattisgarh)",
        FacilityCity: "Raigarh",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7266,
        Pin: 756166,
        DispatchCenter: "Bhadrak_Deuli_D (Orissa)",
        OriginCenter: "Bhadrak_Deuli_D (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7267,
        Pin: 759028,
        DispatchCenter: "Kamakhyanagar_Mundideuli_D (Orissa)",
        OriginCenter: "Kamakhyanagar_Mundideuli_D (Orissa)",
        FacilityCity: "Kamakhyanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7268,
        Pin: 670012,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7269,
        Pin: 614207,
        DispatchCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        OriginCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7270,
        Pin: 768006,
        DispatchCenter: "Sambalpur_Tentelpara_H (Orissa)",
        OriginCenter: "Sambalpur_Tentelpara_H (Orissa)",
        FacilityCity: "Sambalpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7271,
        Pin: 410303,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7272,
        Pin: 521261,
        DispatchCenter: "Vuyyuru_BhogdDPP_D (Andhra Pradesh)",
        OriginCenter: "Vuyyuru_BhogdDPP_D (Andhra Pradesh)",
        FacilityCity: "Vuyyuru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7273,
        Pin: 191103,
        DispatchCenter: "Srinagar_Ladhoo_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Ladhoo_H (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7274,
        Pin: 523303,
        DispatchCenter: "Addanki_Kopperapadu_D (Andhra Pradesh)",
        OriginCenter: "Addanki_Kopperapadu_D (Andhra Pradesh)",
        FacilityCity: "Addanki",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7275,
        Pin: 799260,
        DispatchCenter: "Dharmanagar_Ward3_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Dharmanagar",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7276,
        Pin: 416522,
        DispatchCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        OriginCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        FacilityCity: "Malvan",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7277,
        Pin: 142047,
        DispatchCenter: "Zira_FriendsEncl_D (Punjab)",
        OriginCenter: "Zira_FriendsEncl_D (Punjab)",
        FacilityCity: "Zira",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7278,
        Pin: 516003,
        DispatchCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7279,
        Pin: 521343,
        DispatchCenter: "Kaikaluru_Gonepadu_D (Andhra Pradesh)",
        OriginCenter: "Kaikaluru_Gonepadu_D (Andhra Pradesh)",
        FacilityCity: "Kaikaluru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7280,
        Pin: 209652,
        DispatchCenter: "Chhibramau_Bhawalpur_D (Uttar Pradesh)",
        OriginCenter: "Chhibramau_Bhawalpur_D (Uttar Pradesh)",
        FacilityCity: "Mainpuri",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7281,
        Pin: 481884,
        DispatchCenter: "Bajag_DindoriRd_DPP (Madhya Pradesh)",
        OriginCenter: "Bajag_DindoriRd_DPP (Madhya Pradesh)",
        FacilityCity: "Bajag",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7282,
        Pin: 455227,
        DispatchCenter: "Chapda_Ideacolony_DPP (Madhya Pradesh)",
        OriginCenter: "Chapda_Ideacolony_DPP (Madhya Pradesh)",
        FacilityCity: "Chapda",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7283,
        Pin: 743442,
        DispatchCenter: "Minakhan_Amadpur_DPP (West Bengal)",
        OriginCenter: "Minakhan_Amadpur_DPP (West Bengal)",
        FacilityCity: "Minakhan",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7284,
        Pin: 606201,
        DispatchCenter: "Chinnasalem_VKootRd_D (Tamil Nadu)",
        OriginCenter: "Chinnasalem_VKootRd_D (Tamil Nadu)",
        FacilityCity: "Chinnasalem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7285,
        Pin: 752102,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7286,
        Pin: 322236,
        DispatchCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        OriginCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7287,
        Pin: 574326,
        DispatchCenter: "Puttur_Darbe_D (Karnataka)",
        OriginCenter: "Puttur_Darbe_D (Karnataka)",
        FacilityCity: "Puttur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7288,
        Pin: 454365,
        DispatchCenter: "Kukshi_BurahaniColony_D (Madhya Pradesh)",
        OriginCenter: "Kukshi_BurahaniColony_D (Madhya Pradesh)",
        FacilityCity: "Kukshi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7289,
        Pin: 577429,
        DispatchCenter: "Soraba_CourtDPP_D (Karnataka)",
        OriginCenter: "Soraba_CourtDPP_D (Karnataka)",
        FacilityCity: "Soraba",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7290,
        Pin: 603110,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7291,
        Pin: 523165,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7292,
        Pin: 847240,
        DispatchCenter: "Benipatti_Hathhapurparsa_D (Bihar)",
        OriginCenter: "Benipatti_Hathhapurparsa_D (Bihar)",
        FacilityCity: "Benipatti",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7293,
        Pin: 335702,
        DispatchCenter: "Srivijaynagar_Ward8_DPP (Rajasthan)",
        OriginCenter: "Srivijaynagar_Ward8_DPP (Rajasthan)",
        FacilityCity: "Srivijaynagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7294,
        Pin: 222126,
        DispatchCenter: "Badlapur_Barauli_D (Uttar Pradesh)",
        OriginCenter: "Badlapur_Barauli_D (Uttar Pradesh)",
        FacilityCity: "Badlapur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7295,
        Pin: 683515,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7296,
        Pin: 227105,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7297,
        Pin: 781030,
        DispatchCenter: "Guwahati_Kaikchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7298,
        Pin: 534311,
        DispatchCenter: "Jangareddygudem_RTC_D (Andhra Pradesh)",
        OriginCenter: "Jangareddygudem_RTC_D (Andhra Pradesh)",
        FacilityCity: "Jangareddygudem",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7299,
        Pin: 781014,
        DispatchCenter: "Guwahati_Kaikchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7300,
        Pin: 761125,
        DispatchCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        OriginCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        FacilityCity: "Bhanjanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7301,
        Pin: 577543,
        DispatchCenter: "Challakere_Hosmane_D (Karnataka)",
        OriginCenter: "Challakere_Hosmane_D (Karnataka)",
        FacilityCity: "Challakere",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7302,
        Pin: 500052,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7303,
        Pin: 788007,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7304,
        Pin: 721458,
        DispatchCenter: "Contai_Egra_D (West Bengal)",
        OriginCenter: "Contai_Egra_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7305,
        Pin: 503225,
        DispatchCenter: "Armoor_Mamidipillay_D (Telangana)",
        OriginCenter: "Armoor_Mamidipillay_D (Telangana)",
        FacilityCity: "Armoor",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7306,
        Pin: 521332,
        DispatchCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        OriginCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        FacilityCity: "Pamarru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7307,
        Pin: 795010,
        DispatchCenter: "Imphal_Takyel_I (Manipur)",
        OriginCenter: "Imphal_Takyel_I (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7308,
        Pin: 700012,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7309,
        Pin: 335051,
        DispatchCenter: "Raisinghnagar_Ward24_D (Rajasthan)",
        OriginCenter: "Raisinghnagar_Ward24_D (Rajasthan)",
        FacilityCity: "Raisinghnagar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7310,
        Pin: 403516,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7311,
        Pin: 323613,
        DispatchCenter: "Lakheri_KrsrdDPP_D (Rajasthan)",
        OriginCenter: "Lakheri_KrsrdDPP_D (Rajasthan)",
        FacilityCity: "Lakheri",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7312,
        Pin: 673010,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7313,
        Pin: 585290,
        DispatchCenter: "Shorapur_GandhiNagar_D (Karnataka)",
        OriginCenter: "Shorapur_GandhiNagar_D (Karnataka)",
        FacilityCity: "Shorapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7314,
        Pin: 382165,
        DispatchCenter: "Kadi_Jadavpura_L (Gujarat)",
        OriginCenter: "Kadi_Jadavpura_L (Gujarat)",
        FacilityCity: "Kadi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7315,
        Pin: 756171,
        DispatchCenter: "Dhamara_Jharakata_D (Orissa)",
        OriginCenter: "Dhamara_Jharakata_D (Orissa)",
        FacilityCity: "Dhamara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7316,
        Pin: 171205,
        DispatchCenter: "Jubbal_Jhaldi_D (Himachal Pradesh)",
        OriginCenter: "Jubbal_Jhaldi_D (Himachal Pradesh)",
        FacilityCity: "Jubbal",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7317,
        Pin: 416628,
        DispatchCenter: "Oras_Ranbambuli_D (Maharashtra)",
        OriginCenter: "Oras_Ranbambuli_D (Maharashtra)",
        FacilityCity: "Oras",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7318,
        Pin: 342007,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7319,
        Pin: 631602,
        DispatchCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        OriginCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        FacilityCity: "Kanchipuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7320,
        Pin: 144037,
        DispatchCenter: "Nakodar_Goldenavenue_D (Punjab)",
        OriginCenter: "Nakodar_Goldenavenue_D (Punjab)",
        FacilityCity: "Nakodar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7321,
        Pin: 521231,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        Zone : "S1"
      },
      {
        SN: 7322,
        Pin: 425511,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Chikhali_L (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7323,
        Pin: 444902,
        DispatchCenter: "Morshi_Samarthacolony_D (Maharashtra)",
        OriginCenter: "Morshi_Samarthacolony_D (Maharashtra)",
        FacilityCity: "Morshi",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7324,
        Pin: 171014,
        DispatchCenter: "Shimla_Dhami_DPP (Himachal Pradesh)",
        OriginCenter: "Shimla_Dhami_DPP (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7325,
        Pin: 583232,
        DispatchCenter: "Yelburga_SurabhiColony_D (Karnataka)",
        OriginCenter: "Yelburga_SurabhiColony_D (Karnataka)",
        FacilityCity: "Yelburga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7326,
        Pin: 737121,
        DispatchCenter: "Jorethang_Singling_DPP (Sikkim)",
        OriginCenter: "Jorethang_Singling_DPP (Sikkim)",
        FacilityCity: "Jorethang",
        FacilityState: "Sikkim",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7327,
        Pin: 685502,
        DispatchCenter: "Kumily_Attappallam_D (Kerala)",
        OriginCenter: "Kumily_Attappallam_D (Kerala)",
        FacilityCity: "Kumily",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7328,
        Pin: 686146,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7329,
        Pin: 388215,
        DispatchCenter: "Umreth_PltinumPlza_DPP (Gujarat)",
        OriginCenter: "Umreth_PltinumPlza_DPP (Gujarat)",
        FacilityCity: "Umreth",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7330,
        Pin: 761107,
        DispatchCenter: "Badagada_Kesharipatana_DPP (Orissa)",
        OriginCenter: "Badagada_Kesharipatana_DPP (Orissa)",
        FacilityCity: "Badagada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7331,
        Pin: 585228,
        DispatchCenter: "Chittapur_GlbrgaRD_D (Karnataka)",
        OriginCenter: "Chittapur_GlbrgaRD_D (Karnataka)",
        FacilityCity: "Chittapur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7332,
        Pin: 185151,
        DispatchCenter: "Sunderbani_Naushera_D (Jammu & Kashmir)",
        OriginCenter: "Sunderbani_Naushera_D (Jammu & Kashmir)",
        FacilityCity: "Sunderbani",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7333,
        Pin: 495119,
        DispatchCenter: "Pendra_Sarbahra_D (Chhattisgarh)",
        OriginCenter: "Pendra_Sarbahra_D (Chhattisgarh)",
        FacilityCity: "Pendra",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7334,
        Pin: 413534,
        DispatchCenter: "Bhoom_KusumNagar_D (Maharashtra)",
        OriginCenter: "Bhoom_KusumNagar_D (Maharashtra)",
        FacilityCity: "Bhoom",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7335,
        Pin: 441408,
        DispatchCenter: "Ramtek_ShivajiWard_D (Maharashtra)",
        OriginCenter: "Ramtek_ShivajiWard_D (Maharashtra)",
        FacilityCity: "Ramtek",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7336,
        Pin: 403002,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7337,
        Pin: 630103,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7338,
        Pin: 276201,
        DispatchCenter: "Azamgarh_Lalganj_DPP (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Lalganj_DPP (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7339,
        Pin: 441903,
        DispatchCenter: "Pauni_Wahi_D (Maharashtra)",
        OriginCenter: "Pauni_Wahi_D (Maharashtra)",
        FacilityCity: "Pauni",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7340,
        Pin: 207121,
        DispatchCenter: "Etah_Dineshnagar_D (Uttar Pradesh)",
        OriginCenter: "Etah_Dineshnagar_D (Uttar Pradesh)",
        FacilityCity: "Etah",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7341,
        Pin: 533125,
        DispatchCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        OriginCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        FacilityCity: "Rajahmundry",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7342,
        Pin: 761123,
        DispatchCenter: "Jagannathprasad_Jirabadi_DPP (Orissa)",
        OriginCenter: "Jagannathprasad_Jirabadi_DPP (Orissa)",
        FacilityCity: "Jagannathprasad",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7343,
        Pin: 560006,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7344,
        Pin: 841286,
        DispatchCenter: "Siwan_Aranda_D (Bihar)",
        OriginCenter: "Siwan_Aranda_D (Bihar)",
        FacilityCity: "Siwan",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7345,
        Pin: 412302,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7346,
        Pin: 721513,
        DispatchCenter: "Jhargram_Raghunathpur_DPP (West Bengal)",
        OriginCenter: "Jhargram_Raghunathpur_DPP (West Bengal)",
        FacilityCity: "Jhargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7347,
        Pin: 695041,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7348,
        Pin: 626135,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7349,
        Pin: 364421,
        DispatchCenter: "Amreli_Bhojalpara_D (Gujarat)",
        OriginCenter: "Amreli_Bhojalpara_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7350,
        Pin: 394185,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7351,
        Pin: 576254,
        DispatchCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        OriginCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7352,
        Pin: 629163,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7353,
        Pin: 470666,
        DispatchCenter: "Damoh_Chouprakhurd_D (Madhya Pradesh)",
        OriginCenter: "Damoh_Chouprakhurd_D (Madhya Pradesh)",
        FacilityCity: "Damoh",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7354,
        Pin: 263656,
        DispatchCenter: "Dwarahat_Chaukhutiya_DPP (Uttarakhand)",
        OriginCenter: "Dwarahat_Chaukhutiya_DPP (Uttarakhand)",
        FacilityCity: "Dwarahat",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7355,
        Pin: 608502,
        DispatchCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        OriginCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        FacilityCity: "Chidambaram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7356,
        Pin: 757053,
        DispatchCenter: "Bahalda_Nimdhi_DPP (Orissa)",
        OriginCenter: "Bahalda_Nimdhi_DPP (Orissa)",
        FacilityCity: "BAHALDA",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7357,
        Pin: 227813,
        DispatchCenter: "JagdishpurUP_Paliyapurab_D (Uttar Pradesh)",
        OriginCenter: "JagdishpurUP_Paliyapurab_D (Uttar Pradesh)",
        FacilityCity: "Jagdishpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7358,
        Pin: 370156,
        DispatchCenter: "Rapar_MalichokRd_DPP (Gujarat)",
        OriginCenter: "Rapar_MalichokRd_DPP (Gujarat)",
        FacilityCity: "Rapar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7359,
        Pin: 721405,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7360,
        Pin: 571115,
        DispatchCenter: "Chamarajanagar_Galipur_D (Karnataka)",
        OriginCenter: "Chamarajanagar_Galipur_D (Karnataka)",
        FacilityCity: "Chamarajanagar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7361,
        Pin: 415611,
        DispatchCenter: "Sangameshwar_Burambi_DPP (Maharashtra)",
        OriginCenter: "Sangameshwar_Burambi_DPP (Maharashtra)",
        FacilityCity: "Sangameshwar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7362,
        Pin: 390007,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7363,
        Pin: 793015,
        DispatchCenter: "Shillong_Smit_DPP (Meghalaya)",
        OriginCenter: "Shillong_Smit_DPP (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7364,
        Pin: 210202,
        DispatchCenter: "Atarra_GangehiRd_D (Uttar Pradesh)",
        OriginCenter: "Atarra_GangehiRd_D (Uttar Pradesh)",
        FacilityCity: "Atarra",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7365,
        Pin: 603104,
        DispatchCenter: "Tirukalukundram_Kalpakkam_D (Tamil Nadu)",
        OriginCenter: "Tirukalukundram_Kalpakkam_D (Tamil Nadu)",
        FacilityCity: "Tirukalukundram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7366,
        Pin: 321029,
        DispatchCenter: "Bayana_Shivcolony_D (Rajasthan)",
        OriginCenter: "Bayana_Shivcolony_D (Rajasthan)",
        FacilityCity: "Bayana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7367,
        Pin: 614802,
        DispatchCenter: "Peravurani_Nattanikkottai_D (Tamil Nadu)",
        OriginCenter: "Peravurani_Nattanikkottai_D (Tamil Nadu)",
        FacilityCity: "Peravurani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7368,
        Pin: 848216,
        DispatchCenter: "Rosera_HanumanTemple_D (Bihar)",
        OriginCenter: "Rosera_HanumanTemple_D (Bihar)",
        FacilityCity: "Rusera",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7369,
        Pin: 276131,
        DispatchCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7370,
        Pin: 754292,
        DispatchCenter: "Chandipur_Keshpur_D (Orissa)",
        OriginCenter: "Chandipur_Keshpur_D (Orissa)",
        FacilityCity: "Chandipur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7371,
        Pin: 205302,
        DispatchCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        OriginCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        FacilityCity: "Aliganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7372,
        Pin: 370130,
        DispatchCenter: "Anjar_ChitrakutCir_D (Gujarat)",
        OriginCenter: "Anjar_ChitrakutCir_D (Gujarat)",
        FacilityCity: "Anjar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7373,
        Pin: 206130,
        DispatchCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        OriginCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        FacilityCity: "Etawah",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7374,
        Pin: 181113,
        DispatchCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7375,
        Pin: 250223,
        DispatchCenter: "Daurala_Central_D_2 (Uttar Pradesh)",
        OriginCenter: "Daurala_Central_D_2 (Uttar Pradesh)",
        FacilityCity: "Daurala",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7376,
        Pin: 614203,
        DispatchCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        OriginCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7377,
        Pin: 517501,
        DispatchCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        OriginCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        FacilityCity: "Tirupati",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7378,
        Pin: 713302,
        DispatchCenter: "Asansol_Addacolony_D (West Bengal)",
        OriginCenter: "Asansol_Addacolony_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7379,
        Pin: 723133,
        DispatchCenter: "Raghunathpur_Nutandih_D (West Bengal)",
        OriginCenter: "Raghunathpur_Nutandih_D (West Bengal)",
        FacilityCity: "Raghunathpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7380,
        Pin: 573102,
        DispatchCenter: "Ramanathapura_Koodalur_D (Karnataka)",
        OriginCenter: "Ramanathapura_Koodalur_D (Karnataka)",
        FacilityCity: "Ramanathapura",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7381,
        Pin: 534406,
        DispatchCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        OriginCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        FacilityCity: "Tadepalligudem",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7382,
        Pin: 631151,
        DispatchCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7383,
        Pin: 630557,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7384,
        Pin: 614205,
        DispatchCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        OriginCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7385,
        Pin: 638706,
        DispatchCenter: "Dharapuram_Pirivu_D (Tamil Nadu)",
        OriginCenter: "Dharapuram_Pirivu_D (Tamil Nadu)",
        FacilityCity: "Dharapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7386,
        Pin: 835230,
        DispatchCenter: "ChainpurJH_Premnagar_D (Jharkhand)",
        OriginCenter: "ChainpurJH_Premnagar_D (Jharkhand)",
        FacilityCity: "ChainpurJH",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7387,
        Pin: 518112,
        DispatchCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        OriginCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        FacilityCity: "Nandyal",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7388,
        Pin: 261403,
        DispatchCenter: "Mishrikh_Naimishrnya_DPP (Uttar Pradesh)",
        OriginCenter: "Mishrikh_Naimishrnya_DPP (Uttar Pradesh)",
        FacilityCity: "Mishrikh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7389,
        Pin: 613504,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7390,
        Pin: 271824,
        DispatchCenter: "Bahraich_Mahasi_D (Uttar Pradesh)",
        OriginCenter: "Bahraich_Mahasi_D (Uttar Pradesh)",
        FacilityCity: "Bahraich",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7391,
        Pin: 470228,
        DispatchCenter: "Rehli_Ward3_D (Madhya Pradesh)",
        OriginCenter: "Rehli_Ward3_D (Madhya Pradesh)",
        FacilityCity: "Rehli",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7392,
        Pin: 847215,
        DispatchCenter: "Madhubani_NidhiChowk_D (Bihar)",
        OriginCenter: "Madhubani_NidhiChowk_D (Bihar)",
        FacilityCity: "Madhubani",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7393,
        Pin: 534475,
        DispatchCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        OriginCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        FacilityCity: "Eluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7394,
        Pin: 683518,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7395,
        Pin: 688503,
        DispatchCenter: "Haripad_Thalavady_D (Kerala)",
        OriginCenter: "Haripad_Thalavady_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7396,
        Pin: 192123,
        DispatchCenter: "Pulwama_Tral_DPP (Jammu & Kashmir)",
        OriginCenter: "Pulwama_Tral_DPP (Jammu & Kashmir)",
        FacilityCity: "Pulwama",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7397,
        Pin: 505497,
        DispatchCenter: "Husnabad_HanmakondaRd_D (Telangana)",
        OriginCenter: "Husnabad_HanmakondaRd_D (Telangana)",
        FacilityCity: "Husnabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7398,
        Pin: 248018,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7399,
        Pin: 571432,
        DispatchCenter: "Nagamangala_TBExtension_D (Karnataka)",
        OriginCenter: "Nagamangala_TBExtension_D (Karnataka)",
        FacilityCity: "Nagamangala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7400,
        Pin: 786171,
        DispatchCenter: "Digboi_Itabhata_DPP (Assam)",
        OriginCenter: "Digboi_Itabhata_DPP (Assam)",
        FacilityCity: "Digboi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7401,
        Pin: 471311,
        DispatchCenter: "Kanera_Badamalhera_DPP (Madhya Pradesh)",
        OriginCenter: "Kanera_Badamalhera_DPP (Madhya Pradesh)",
        FacilityCity: "Malhera",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7402,
        Pin: 203394,
        DispatchCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        OriginCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        FacilityCity: "Anupshahar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7403,
        Pin: 626154,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7404,
        Pin: 609304,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7405,
        Pin: 713368,
        DispatchCenter: "Asansol_Addacolony_D (West Bengal)",
        OriginCenter: "Asansol_Addacolony_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7406,
        Pin: 382333,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7407,
        Pin: 628203,
        DispatchCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        OriginCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        FacilityCity: "Tiruchendur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7408,
        Pin: 307020,
        DispatchCenter: "Sirohi_Vedhaynathcolony_D (Rajasthan)",
        OriginCenter: "Sirohi_Vedhaynathcolony_D (Rajasthan)",
        FacilityCity: "Sirohi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7409,
        Pin: 620027,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7410,
        Pin: 503175,
        DispatchCenter: "Nizamabad_Sarangapur_I (Telangana)",
        OriginCenter: "Nizamabad_Sarangapur_I (Telangana)",
        FacilityCity: "Nizamabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7411,
        Pin: 403501,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7412,
        Pin: 400701,
        DispatchCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        OriginCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7413,
        Pin: 209306,
        DispatchCenter: "Rasulabad_ShastriNgr_D (Uttar Pradesh)",
        OriginCenter: "Rasulabad_ShastriNgr_D (Uttar Pradesh)",
        FacilityCity: "Rasulabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7414,
        Pin: 212128,
        DispatchCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        OriginCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7415,
        Pin: 413002,
        DispatchCenter: "Solapur_Balepeth_I (Maharashtra)",
        OriginCenter: "Solapur_Balepeth_I (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7416,
        Pin: 144819,
        DispatchCenter: "Begowal_PinkMrkt_D (Punjab)",
        OriginCenter: "Begowal_PinkMrkt_D (Punjab)",
        FacilityCity: "Begowal",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7417,
        Pin: 251201,
        DispatchCenter: "Khatauli_GTRoad_D (Uttar Pradesh)",
        OriginCenter: "Khatauli_GTRoad_D (Uttar Pradesh)",
        FacilityCity: "Khatauli",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7418,
        Pin: 415108,
        DispatchCenter: "Karad_Malkapur1_D (Maharashtra)",
        OriginCenter: "Karad_Malkapur1_D (Maharashtra)",
        FacilityCity: "Karad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7419,
        Pin: 243504,
        DispatchCenter: "Milak_Bhaisoori_D (Uttar Pradesh)",
        OriginCenter: "Milak_Bhaisoori_D (Uttar Pradesh)",
        FacilityCity: "Milak",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7420,
        Pin: 563127,
        DispatchCenter: "Mulbagal_Sunku_D (Karnataka)",
        OriginCenter: "Mulbagal_Sunku_D (Karnataka)",
        FacilityCity: "Mulbagal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7421,
        Pin: 462033,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7422,
        Pin: 243638,
        DispatchCenter: "Sahaswan_Mainmarket_D (Uttar Pradesh)",
        OriginCenter: "Sahaswan_Mainmarket_D (Uttar Pradesh)",
        FacilityCity: "Sahaswan",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7423,
        Pin: 325602,
        DispatchCenter: "Baran_Housingboard_D (Rajasthan)",
        OriginCenter: "Baran_Housingboard_D (Rajasthan)",
        FacilityCity: "Baran",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7424,
        Pin: 394220,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7425,
        Pin: 845411,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7426,
        Pin: 393004,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7427,
        Pin: 523109,
        DispatchCenter: "Kandukur_SanthoshNagar_D (Andhra Pradesh)",
        OriginCenter: "Kandukur_SanthoshNagar_D (Andhra Pradesh)",
        FacilityCity: "Kandukur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7428,
        Pin: 274508,
        DispatchCenter: "Salempur_Banjaria_D (Uttar Pradesh)",
        OriginCenter: "Salempur_Banjaria_D (Uttar Pradesh)",
        FacilityCity: "Salempur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7429,
        Pin: 600078,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7430,
        Pin: 387320,
        DispatchCenter: "Nadiad_Shantinagar_D (Gujarat)",
        OriginCenter: "Nadiad_Shantinagar_D (Gujarat)",
        FacilityCity: "Nadiad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7431,
        Pin: 679307,
        DispatchCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        OriginCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        FacilityCity: "Sankaramangalam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7432,
        Pin: 226019,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7433,
        Pin: 229310,
        DispatchCenter: "Raebareli_Ratapur_D (Uttar Pradesh)",
        OriginCenter: "Raebareli_Ratapur_D (Uttar Pradesh)",
        FacilityCity: "Raebareli",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7434,
        Pin: 712152,
        DispatchCenter: "Jamalpur_HalaraVlg_D (West Bengal)",
        OriginCenter: "Jamalpur_HalaraVlg_D (West Bengal)",
        FacilityCity: "Jamalpur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7435,
        Pin: 766002,
        DispatchCenter: "Bhawanipatna_Dangaripadar_H (Orissa)",
        OriginCenter: "Bhawanipatna_Dangaripadar_H (Orissa)",
        FacilityCity: "Bhawanipatna",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7436,
        Pin: 832106,
        DispatchCenter: "Ghatshila_Ekramcolony_D (Jharkhand)",
        OriginCenter: "Ghatshila_Ekramcolony_D (Jharkhand)",
        FacilityCity: "Ghatshila",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7437,
        Pin: 757082,
        DispatchCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        OriginCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        FacilityCity: "Betnoti",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7438,
        Pin: 680524,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7439,
        Pin: 623538,
        DispatchCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        OriginCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        FacilityCity: "Thiruvadanai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7440,
        Pin: 206131,
        DispatchCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        OriginCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        FacilityCity: "Etawah",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7441,
        Pin: 680052,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7442,
        Pin: 403526,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7443,
        Pin: 370002,
        DispatchCenter: "Bhuj_Madhapar_D (Gujarat)",
        OriginCenter: "Bhuj_Madhapar_D (Gujarat)",
        FacilityCity: "Bhuj",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7444,
        Pin: 722149,
        DispatchCenter: "Khatra_Simlapal_DPP (West Bengal)",
        OriginCenter: "Khatra_Simlapal_DPP (West Bengal)",
        FacilityCity: "Khatra",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7445,
        Pin: 472005,
        DispatchCenter: "Tikamgarh_Dhonga_D (Madhya Pradesh)",
        OriginCenter: "Tikamgarh_Dhonga_D (Madhya Pradesh)",
        FacilityCity: "Tikamgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7446,
        Pin: 416301,
        DispatchCenter: "Ashta_GordeChwk_D (Maharashtra)",
        OriginCenter: "Ashta_GordeChwk_D (Maharashtra)",
        FacilityCity: "AshtaSangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7447,
        Pin: 413569,
        DispatchCenter: "Osmanabad_RajasabH_D (Maharashtra)",
        OriginCenter: "Osmanabad_RajasabH_D (Maharashtra)",
        FacilityCity: "Osmanabad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7448,
        Pin: 670016,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7449,
        Pin: 504208,
        DispatchCenter: "Mancherial_Nuspur_I (Telangana)",
        OriginCenter: "Mancherial_Nuspur_I (Telangana)",
        FacilityCity: "Mancherial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7450,
        Pin: 587207,
        DispatchCenter: "Muddebihal_Nekarcolony_D (Karnataka)",
        OriginCenter: "Muddebihal_Nekarcolony_D (Karnataka)",
        FacilityCity: "Muddebihal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7451,
        Pin: 303103,
        DispatchCenter: "Paota_SmClyDPP_D (Rajasthan)",
        OriginCenter: "Paota_SmClyDPP_D (Rajasthan)",
        FacilityCity: "Paota",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7452,
        Pin: 416415,
        DispatchCenter: "Sangli_Dattanagar_D (Maharashtra)",
        OriginCenter: "Sangli_Dattanagar_D (Maharashtra)",
        FacilityCity: "Sangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7453,
        Pin: 388620,
        DispatchCenter: "Khambhat_MotvdDPP_D (Gujarat)",
        OriginCenter: "Khambhat_MotvdDPP_D (Gujarat)",
        FacilityCity: "Khambhat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7454,
        Pin: 302006,
        DispatchCenter: "Jaipur_Ramchandpura_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7455,
        Pin: 586217,
        DispatchCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        OriginCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        FacilityCity: "Indi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7456,
        Pin: 533285,
        DispatchCenter: "Gokavaram_MuraliNagar_D (Andhra Pradesh)",
        OriginCenter: "Gokavaram_MuraliNagar_D (Andhra Pradesh)",
        FacilityCity: "Gokavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7457,
        Pin: 415618,
        DispatchCenter: "Ratnagiri_GaneshNagar_D (Maharashtra)",
        OriginCenter: "Ratnagiri_GaneshNagar_D (Maharashtra)",
        FacilityCity: "Ratnagiri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7458,
        Pin: 623110,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7459,
        Pin: 507120,
        DispatchCenter: "Kothagudem_Sujathanagar_D (Telangana)",
        OriginCenter: "Kothagudem_Sujathanagar_D (Telangana)",
        FacilityCity: "Kothagudem",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7460,
        Pin: 682016,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7461,
        Pin: 799287,
        DispatchCenter: "Kamalpur_Manikbhandar_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Kamalpur",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7462,
        Pin: 464226,
        DispatchCenter: "Vidisha_ShrprDPP_D (Madhya Pradesh)",
        OriginCenter: "Vidisha_ShrprDPP_D (Madhya Pradesh)",
        FacilityCity: "Vidisha",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7463,
        Pin: 683589,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7464,
        Pin: 506169,
        DispatchCenter: "Bhupalpally_LaxmiNagar_DPP (Telangana)",
        OriginCenter: "Bhupalpally_LaxmiNagar_DPP (Telangana)",
        FacilityCity: "Bhupalpally",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7465,
        Pin: 768102,
        DispatchCenter: "Kadobahal_Dulampur_D (Orissa)",
        OriginCenter: "Kadobahal_Dulampur_D (Orissa)",
        FacilityCity: "Kadobahal",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7466,
        Pin: 385350,
        DispatchCenter: "Rapar_Adesar_D (Gujarat)",
        OriginCenter: "Rapar_Adesar_D (Gujarat)",
        FacilityCity: "Rapar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7467,
        Pin: 312403,
        DispatchCenter: "BariSadri_SudhmaColny_DPP (Rajasthan)",
        OriginCenter: "BariSadri_SudhmaColny_DPP (Rajasthan)",
        FacilityCity: "Bari Sadri",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7468,
        Pin: 713519,
        DispatchCenter: "Nabadwip_Srirmpur_D (West Bengal)",
        OriginCenter: "Nabadwip_Srirmpur_D (West Bengal)",
        FacilityCity: "Nabadwip",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7469,
        Pin: 364002,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7470,
        Pin: 522022,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7471,
        Pin: 403720,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7472,
        Pin: 736157,
        DispatchCenter: "Mathabhanga_Pachagarh_D (West Bengal)",
        OriginCenter: "Mathabhanga_Pachagarh_D (West Bengal)",
        FacilityCity: "Mathabhanga",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7473,
        Pin: 500063,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7474,
        Pin: 143003,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7475,
        Pin: 721653,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7476,
        Pin: 686577,
        DispatchCenter: "Pala_PonadKaroor_D (Kerala)",
        OriginCenter: "Pala_PonadKaroor_D (Kerala)",
        FacilityCity: "Pala",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7477,
        Pin: 142053,
        DispatchCenter: "Moga_Duneke_I (Punjab)",
        OriginCenter: "Moga_Duneke_I (Punjab)",
        FacilityCity: "Moga",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7478,
        Pin: 575011,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7479,
        Pin: 845309,
        DispatchCenter: "Narkatiaganj_Sonkhar_D (Bihar)",
        OriginCenter: "Narkatiaganj_Sonkhar_D (Bihar)",
        FacilityCity: "Narkatiaganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7480,
        Pin: 391347,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7481,
        Pin: 160060,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7482,
        Pin: 505102,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7483,
        Pin: 614102,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7484,
        Pin: 742161,
        DispatchCenter: "Khargram_Parbatipur_D (West Bengal)",
        OriginCenter: "Khargram_Parbatipur_D (West Bengal)",
        FacilityCity: "Khargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7485,
        Pin: 362020,
        DispatchCenter: "Bhesan_STroad_DPP (Gujarat)",
        OriginCenter: "Bhesan_STroad_DPP (Gujarat)",
        FacilityCity: "Bhesan",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7486,
        Pin: 751027,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7487,
        Pin: 535001,
        DispatchCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        OriginCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        FacilityCity: "Vizianagaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7488,
        Pin: 673541,
        DispatchCenter: "Vadakara_Veerancheri_D (Kerala)",
        OriginCenter: "Vadakara_Veerancheri_D (Kerala)",
        FacilityCity: "Vadakara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7489,
        Pin: 571218,
        DispatchCenter: "Virajpet_TeluguSt_D (Karnataka)",
        OriginCenter: "Virajpet_TeluguSt_D (Karnataka)",
        FacilityCity: "Virajpet",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7490,
        Pin: 273007,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7491,
        Pin: 623001,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7492,
        Pin: 333704,
        DispatchCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        OriginCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        FacilityCity: "Jhunjhunu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7493,
        Pin: 413204,
        DispatchCenter: "Jamkhed_DaulatCmplex_DPP (Maharashtra)",
        OriginCenter: "Jamkhed_DaulatCmplex_DPP (Maharashtra)",
        FacilityCity: "Jamkhed",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7494,
        Pin: 333035,
        DispatchCenter: "Pilani (Rajasthan)",
        OriginCenter: "Pilani (Rajasthan)",
        FacilityCity: "Pilani",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7495,
        Pin: 847303,
        DispatchCenter: "Bharwara_AtarwelPath_D (Bihar)",
        OriginCenter: "Bharwara_AtarwelPath_D (Bihar)",
        FacilityCity: "Bharwara",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7496,
        Pin: 626113,
        DispatchCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        OriginCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        FacilityCity: "Aruppukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7497,
        Pin: 591216,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7498,
        Pin: 575017,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7499,
        Pin: 174306,
        DispatchCenter: "UnaHP_NangalRd_D (Himachal Pradesh)",
        OriginCenter: "UnaHP_NangalRd_D (Himachal Pradesh)",
        FacilityCity: "Una-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7500,
        Pin: 442905,
        DispatchCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        OriginCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        FacilityCity: "Chandrapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7501,
        Pin: 682007,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7502,
        Pin: 573103,
        DispatchCenter: "Arsikere_JenukalTemple_D (Karnataka)",
        OriginCenter: "Arsikere_JenukalTemple_D (Karnataka)",
        FacilityCity: "Arsikere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7503,
        Pin: 685566,
        DispatchCenter: "Kuthumkal_Matheckal_D (Kerala)",
        OriginCenter: "Kuthumkal_Matheckal_D (Kerala)",
        FacilityCity: "Kuthumkal",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7504,
        Pin: 207003,
        DispatchCenter: "Etah_Dineshnagar_D (Uttar Pradesh)",
        OriginCenter: "Etah_Dineshnagar_D (Uttar Pradesh)",
        FacilityCity: "Etah",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7505,
        Pin: 522663,
        DispatchCenter: "Piduguralla_Rasoolpet_D (Andhra Pradesh)",
        OriginCenter: "Piduguralla_Rasoolpet_D (Andhra Pradesh)",
        FacilityCity: "Piduguralla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7506,
        Pin: 606304,
        DispatchCenter: "Virudhachalam_Tamilnagar_D (Tamil Nadu)",
        OriginCenter: "Virudhachalam_Tamilnagar_D (Tamil Nadu)",
        FacilityCity: "Virudhachalam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7507,
        Pin: 341551,
        DispatchCenter: "Didwana_TransportNgr_DPP (Rajasthan)",
        OriginCenter: "Didwana_TransportNgr_DPP (Rajasthan)",
        FacilityCity: "Didwana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7508,
        Pin: 481880,
        DispatchCenter: "Dindori_Deoramal_D (Madhya Pradesh)",
        OriginCenter: "Dindori_Deoramal_D (Madhya Pradesh)",
        FacilityCity: "Dindori",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7509,
        Pin: 700132,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7510,
        Pin: 685506,
        DispatchCenter: "Kattappana_EratayarRoad_D (Kerala)",
        OriginCenter: "Kattappana_EratayarRoad_D (Kerala)",
        FacilityCity: "Kattappana",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7511,
        Pin: 574504,
        DispatchCenter: "Mudarangady_Santhoor_DPP (Karnataka)",
        OriginCenter: "Mudarangady_Santhoor_DPP (Karnataka)",
        FacilityCity: "Mudarangady",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7512,
        Pin: 232339,
        DispatchCenter: "Gahmar_KhuduraPthra_DPP (Uttar Pradesh)",
        OriginCenter: "Gahmar_KhuduraPthra_DPP (Uttar Pradesh)",
        FacilityCity: "Gahmar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7513,
        Pin: 799253,
        DispatchCenter: "Dharmanagar_Ward3_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Dharmanagar",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7514,
        Pin: 585317,
        DispatchCenter: "Chittapur_GlbrgaRD_D (Karnataka)",
        OriginCenter: "Chittapur_GlbrgaRD_D (Karnataka)",
        FacilityCity: "Chittapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7515,
        Pin: 502294,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7516,
        Pin: 577521,
        DispatchCenter: "Jagaluru_Tumatelayout_D (Karnataka)",
        OriginCenter: "Jagaluru_Tumatelayout_D (Karnataka)",
        FacilityCity: "Jagaluru",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7517,
        Pin: 620012,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7518,
        Pin: 841224,
        DispatchCenter: "Maharajganj_Sihauta_D (Bihar)",
        OriginCenter: "Maharajganj_Sihauta_D (Bihar)",
        FacilityCity: "Maharajganj-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7519,
        Pin: 441501,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7520,
        Pin: 411008,
        DispatchCenter: "Pune_Muktainagar_L (Maharashtra)",
        OriginCenter: "Pune_Muktainagar_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7521,
        Pin: 609603,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7522,
        Pin: 781137,
        DispatchCenter: "Chhaygaon_Dhekenabari_D (Assam)",
        OriginCenter: "Chhaygaon_Dhekenabari_D (Assam)",
        FacilityCity: "Chhaygaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7523,
        Pin: 782138,
        DispatchCenter: "Kaliabor_Hatiakhowa_DPP (Assam)",
        OriginCenter: "Kaliabor_Hatiakhowa_DPP (Assam)",
        FacilityCity: "Kaliabor",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7524,
        Pin: 360583,
        DispatchCenter: "Porbandar_Vadiplot_D (Gujarat)",
        OriginCenter: "Porbandar_Vadiplot_D (Gujarat)",
        FacilityCity: "Porbandar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7525,
        Pin: 201314,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7526,
        Pin: 241305,
        DispatchCenter: "Madhoganj_BaghuliRd_D (Uttar Pradesh)",
        OriginCenter: "Madhoganj_BaghuliRd_D (Uttar Pradesh)",
        FacilityCity: "Madhoganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7527,
        Pin: 191112,
        DispatchCenter: "Srinagar_Chadoora_DPP (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Chadoora_DPP (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7528,
        Pin: 604304,
        DispatchCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        OriginCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        FacilityCity: "Tindivanam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7529,
        Pin: 415526,
        DispatchCenter: "Nira_Central_D (Maharashtra)",
        OriginCenter: "Nira_Central_D (Maharashtra)",
        FacilityCity: "Nira",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7530,
        Pin: 627713,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7531,
        Pin: 670641,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7532,
        Pin: 767025,
        DispatchCenter: "Patnagarh_Beherabandh_D (Orissa)",
        OriginCenter: "Patnagarh_Beherabandh_D (Orissa)",
        FacilityCity: "Patnagarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7533,
        Pin: 686667,
        DispatchCenter: "Koothattukulam_Oliyappuram_D (Kerala)",
        OriginCenter: "Koothattukulam_Oliyappuram_D (Kerala)",
        FacilityCity: "Koothattukulam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7534,
        Pin: 524126,
        DispatchCenter: "Naidupeta_LASagaram_D (Andhra Pradesh)",
        OriginCenter: "Naidupeta_LASagaram_D (Andhra Pradesh)",
        FacilityCity: "Naidupeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7535,
        Pin: 444003,
        DispatchCenter: "Akola_Midcphase3_H (Maharashtra)",
        OriginCenter: "Akola_Midcphase3_H (Maharashtra)",
        FacilityCity: "Akola",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7536,
        Pin: 227015,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7537,
        Pin: 307028,
        DispatchCenter: "Sumerpur_Dhorawas_DPP (Rajasthan)",
        OriginCenter: "Sumerpur_Dhorawas_DPP (Rajasthan)",
        FacilityCity: "Sumerpur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7538,
        Pin: 572128,
        DispatchCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        OriginCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7539,
        Pin: 248126,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7540,
        Pin: 635113,
        DispatchCenter: "Denkanikottai_AnchettyRd_D (Tamil Nadu)",
        OriginCenter: "Denkanikottai_AnchettyRd_D (Tamil Nadu)",
        FacilityCity: "Denkanikottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7541,
        Pin: 403503,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7542,
        Pin: 251311,
        DispatchCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        OriginCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        FacilityCity: "Muzaffarnagar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7543,
        Pin: 452007,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7544,
        Pin: 530022,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7545,
        Pin: 628718,
        DispatchCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        OriginCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        FacilityCity: "Tuticorin",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7546,
        Pin: 411406,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7547,
        Pin: 533435,
        DispatchCenter: "Yerravaram_Somavaram_D (Andhra Pradesh)",
        OriginCenter: "Yerravaram_Somavaram_D (Andhra Pradesh)",
        FacilityCity: "Yerravaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7548,
        Pin: 385575,
        DispatchCenter: "Tharad_Budhanpur_D (Gujarat)",
        OriginCenter: "Tharad_Budhanpur_D (Gujarat)",
        FacilityCity: "Tharad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7549,
        Pin: 637207,
        DispatchCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        OriginCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        FacilityCity: "Namakkal",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7550,
        Pin: 224149,
        DispatchCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        OriginCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        FacilityCity: "Jalalpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7551,
        Pin: 466221,
        DispatchCenter: "Ashta_Saicolony_D (Madhya Pradesh)",
        OriginCenter: "Ashta_Saicolony_D (Madhya Pradesh)",
        FacilityCity: "Ashta",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7552,
        Pin: 382440,
        DispatchCenter: "Ahmedabad_Kanera_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7553,
        Pin: 370205,
        DispatchCenter: "Anjar_ChitrakutCir_D (Gujarat)",
        OriginCenter: "Anjar_ChitrakutCir_D (Gujarat)",
        FacilityCity: "Anjar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7554,
        Pin: 277506,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7555,
        Pin: 583231,
        DispatchCenter: "Koppal_PragatiNagar_D (Karnataka)",
        OriginCenter: "Koppal_PragatiNagar_D (Karnataka)",
        FacilityCity: "Koppal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7556,
        Pin: 535521,
        DispatchCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        OriginCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        FacilityCity: "Parvathipuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7557,
        Pin: 636805,
        DispatchCenter: "Palacode_Dandukaranahalli_D (Tamil Nadu)",
        OriginCenter: "Palacode_Dandukaranahalli_D (Tamil Nadu)",
        FacilityCity: "Palacode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7558,
        Pin: 232116,
        DispatchCenter: "Chandauli_Mughalsarai_D (Uttar Pradesh)",
        OriginCenter: "Chandauli_Mughalsarai_D (Uttar Pradesh)",
        FacilityCity: "Mughal Sarai",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7559,
        Pin: 223101,
        DispatchCenter: "Shahganj_Kaudiya_D (Uttar Pradesh)",
        OriginCenter: "Shahganj_Kaudiya_D (Uttar Pradesh)",
        FacilityCity: "Shahganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7560,
        Pin: 624316,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7561,
        Pin: 321202,
        DispatchCenter: "Deeg_Rambag_D (Rajasthan)",
        OriginCenter: "Deeg_Rambag_D (Rajasthan)",
        FacilityCity: "Deeg",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7562,
        Pin: 480105,
        DispatchCenter: "Sausar_BorgaonAKVN_DPP (Madhya Pradesh)",
        OriginCenter: "Sausar_BorgaonAKVN_DPP (Madhya Pradesh)",
        FacilityCity: "Sausar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7563,
        Pin: 700303,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7564,
        Pin: 394317,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7565,
        Pin: 833218,
        DispatchCenter: "Noamundi_Lakhansai_D (Jharkhand)",
        OriginCenter: "Noamundi_Lakhansai_D (Jharkhand)",
        FacilityCity: "Noamundi",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7566,
        Pin: 470125,
        DispatchCenter: "Sagar_Bilehra_DPP (Madhya Pradesh)",
        OriginCenter: "Sagar_Bilehra_DPP (Madhya Pradesh)",
        FacilityCity: "Sagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7567,
        Pin: 470232,
        DispatchCenter: "Rehli_Ward3_D (Madhya Pradesh)",
        OriginCenter: "Rehli_Ward3_D (Madhya Pradesh)",
        FacilityCity: "Rehli",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7568,
        Pin: 497231,
        DispatchCenter: "Bhatgaon_Bazarpara_D (Chhattisgarh)",
        OriginCenter: "Bhatgaon_Bazarpara_D (Chhattisgarh)",
        FacilityCity: "Bhatgaon",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7569,
        Pin: 461223,
        DispatchCenter: "SeoniMalwa_Bhlpra_DPP (Madhya Pradesh)",
        OriginCenter: "SeoniMalwa_Bhlpra_DPP (Madhya Pradesh)",
        FacilityCity: "Seoni Malwa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7570,
        Pin: 814114,
        DispatchCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        OriginCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        FacilityCity: "Deoghar",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7571,
        Pin: 424301,
        DispatchCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        OriginCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        FacilityCity: "Dhule",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7572,
        Pin: 388360,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7573,
        Pin: 631304,
        DispatchCenter: "Sholinghur_Pulanji_D (Tamil Nadu)",
        OriginCenter: "Sholinghur_Pulanji_D (Tamil Nadu)",
        FacilityCity: "Sholinghur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7574,
        Pin: 503144,
        DispatchCenter: "Kamareddy_KPRcolony_D (Telangana)",
        OriginCenter: "Kamareddy_KPRcolony_D (Telangana)",
        FacilityCity: "Kamareddy",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7575,
        Pin: 625501,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7576,
        Pin: 641046,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7577,
        Pin: 591344,
        DispatchCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        OriginCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        FacilityCity: "Gokak",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7578,
        Pin: 281005,
        DispatchCenter: "Mathura_Narsipuramrd_D (Uttar Pradesh)",
        OriginCenter: "Mathura_Narsipuramrd_D (Uttar Pradesh)",
        FacilityCity: "Mathura",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7579,
        Pin: 202143,
        DispatchCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        OriginCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        FacilityCity: "Khair",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7580,
        Pin: 530047,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7581,
        Pin: 414306,
        DispatchCenter: "Shirur_RanjangaonMIDC_L (Maharashtra)",
        OriginCenter: "Shirur_RanjangaonMIDC_L (Maharashtra)",
        FacilityCity: "Shirur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7582,
        Pin: 384160,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7583,
        Pin: 370650,
        DispatchCenter: "Naliya_Jlramcpx_D (Gujarat)",
        OriginCenter: "Naliya_Jlramcpx_D (Gujarat)",
        FacilityCity: "Naliya",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7584,
        Pin: 508373,
        DispatchCenter: "Chandur_ShivajiNagar_D (Telangana)",
        OriginCenter: "Chandur_ShivajiNagar_D (Telangana)",
        FacilityCity: "Chandur",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7585,
        Pin: 502307,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7586,
        Pin: 201005,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7587,
        Pin: 793114,
        DispatchCenter: "Marshillong_CHCHosp_D (Meghalaya)",
        OriginCenter: "Marshillong_CHCHosp_D (Meghalaya)",
        FacilityCity: "Marshillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7588,
        Pin: 600092,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7589,
        Pin: 585427,
        DispatchCenter: "Bidar_AdarshClny_D (Karnataka)",
        OriginCenter: "Bidar_AdarshClny_D (Karnataka)",
        FacilityCity: "Bidar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7590,
        Pin: 711202,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7591,
        Pin: 612201,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7592,
        Pin: 384360,
        DispatchCenter: "Satlasana_Hndutmpl_DPP (Gujarat)",
        OriginCenter: "Satlasana_Hndutmpl_DPP (Gujarat)",
        FacilityCity: "Satlasana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7593,
        Pin: 110125,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7594,
        Pin: 276401,
        DispatchCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7595,
        Pin: 673016,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7596,
        Pin: 695541,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7597,
        Pin: 637107,
        DispatchCenter: "Sankari_Idappadi_D (Tamil Nadu)",
        OriginCenter: "Sankari_Idappadi_D (Tamil Nadu)",
        FacilityCity: "Sankari",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7598,
        Pin: 497119,
        DispatchCenter: "Ramanujganj_Wadafnagarrd_D (Chhattisgarh)",
        OriginCenter: "Ramanujganj_Wadafnagarrd_D (Chhattisgarh)",
        FacilityCity: "Ramanujganj",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7599,
        Pin: 516214,
        DispatchCenter: "Rayachoti_Chennamukkapalle_D (Andhra Pradesh)",
        OriginCenter: "Rayachoti_Chennamukkapalle_D (Andhra Pradesh)",
        FacilityCity: "Rayachoti",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7600,
        Pin: 222146,
        DispatchCenter: "Jalalpur_BibanMau_D (Uttar Pradesh)",
        OriginCenter: "Jalalpur_BibanMau_D (Uttar Pradesh)",
        FacilityCity: "Jalalpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7601,
        Pin: 202131,
        DispatchCenter: "Aligarh_Atrauli_D (Uttar Pradesh)",
        OriginCenter: "Aligarh_Atrauli_D (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7602,
        Pin: 402401,
        DispatchCenter: "Murud_Janjira_D (Maharashtra)",
        OriginCenter: "Murud_Janjira_D (Maharashtra)",
        FacilityCity: "Murud",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7603,
        Pin: 174309,
        DispatchCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        OriginCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        FacilityCity: "Bangana",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7604,
        Pin: 332302,
        DispatchCenter: "Fatehpur-RJ_Sekhisar_DPP (Rajasthan)",
        OriginCenter: "Fatehpur-RJ_Sekhisar_DPP (Rajasthan)",
        FacilityCity: "Fatehpur-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7605,
        Pin: 602023,
        DispatchCenter: "Tiruvallur_MGMNagar_D (Tamil Nadu)",
        OriginCenter: "Tiruvallur_MGMNagar_D (Tamil Nadu)",
        FacilityCity: "Tiruvallur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7606,
        Pin: 382003,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7607,
        Pin: 222136,
        DispatchCenter: "Jalalpur_BibanMau_D (Uttar Pradesh)",
        OriginCenter: "Jalalpur_BibanMau_D (Uttar Pradesh)",
        FacilityCity: "Jalalpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7608,
        Pin: 690551,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7609,
        Pin: 362266,
        DispatchCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        OriginCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        FacilityCity: "Veraval",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7610,
        Pin: 583132,
        DispatchCenter: "Gangavathi_CBSGanj_DPP (Karnataka)",
        OriginCenter: "Gangavathi_CBSGanj_DPP (Karnataka)",
        FacilityCity: "Gangavathi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7611,
        Pin: 332316,
        DispatchCenter: "Balaran_Ward2_DPP (Rajasthan)",
        OriginCenter: "Balaran_Ward2_DPP (Rajasthan)",
        FacilityCity: "Balaran",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7612,
        Pin: 641009,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7613,
        Pin: 671315,
        DispatchCenter: "Kanhangad_Ajanur_D (Kerala)",
        OriginCenter: "Kanhangad_Ajanur_D (Kerala)",
        FacilityCity: "Kasaragod",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7614,
        Pin: 493661,
        DispatchCenter: "Abhanpur_Gatapr_D (Chhattisgarh)",
        OriginCenter: "Abhanpur_Gatapr_D (Chhattisgarh)",
        FacilityCity: "Abhanpur",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7615,
        Pin: 416510,
        DispatchCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        OriginCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        FacilityCity: "Sawantwadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7616,
        Pin: 144021,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7617,
        Pin: 574325,
        DispatchCenter: "Puttur_Darbe_D (Karnataka)",
        OriginCenter: "Puttur_Darbe_D (Karnataka)",
        FacilityCity: "Puttur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7618,
        Pin: 788163,
        DispatchCenter: "Hailakandi_Lala_D (Assam)",
        OriginCenter: "Hailakandi_Lala_D (Assam)",
        FacilityCity: "Hailakandi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7619,
        Pin: 391340,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7620,
        Pin: 307026,
        DispatchCenter: "AbuRoad_MMS_D (Rajasthan)",
        OriginCenter: "AbuRoad_MMS_D (Rajasthan)",
        FacilityCity: "Abu Road",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7621,
        Pin: 713430,
        DispatchCenter: "Manteswar_MemariRD_D (West Bengal)",
        OriginCenter: "Manteswar_MemariRD_D (West Bengal)",
        FacilityCity: "Manteswar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7622,
        Pin: 535273,
        DispatchCenter: "Gajapatinagaram_SaibabaTmpl_DPP (Andhra Pradesh)",
        OriginCenter: "Gajapatinagaram_SaibabaTmpl_DPP (Andhra Pradesh)",
        FacilityCity: "Gajapatinagaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7623,
        Pin: 812009,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7624,
        Pin: 160021,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7625,
        Pin: 474007,
        DispatchCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        OriginCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        FacilityCity: "Gwalior",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7626,
        Pin: 506332,
        DispatchCenter: "Narsampet_PakhalRoad_D (Telangana)",
        OriginCenter: "Narsampet_PakhalRoad_D (Telangana)",
        FacilityCity: "Narsampet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7627,
        Pin: 670143,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7628,
        Pin: 481665,
        DispatchCenter: "Mandla_Maharajpur_D (Madhya Pradesh)",
        OriginCenter: "Mandla_Maharajpur_D (Madhya Pradesh)",
        FacilityCity: "Mandla",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7629,
        Pin: 712410,
        DispatchCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        OriginCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        FacilityCity: "Tarkeshwar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7630,
        Pin: 421303,
        DispatchCenter: "Kudus_Shele_D (Maharashtra)",
        OriginCenter: "Kudus_Shele_D (Maharashtra)",
        FacilityCity: "Kudus",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7631,
        Pin: 713405,
        DispatchCenter: "Kalna_BsStdDPP_D (West Bengal)",
        OriginCenter: "Kalna_BsStdDPP_D (West Bengal)",
        FacilityCity: "Kalna",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7632,
        Pin: 248195,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7633,
        Pin: 835202,
        DispatchCenter: "Bero_Gumlaroad_D (Jharkhand)",
        OriginCenter: "Bero_Gumlaroad_D (Jharkhand)",
        FacilityCity: "Bero",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7634,
        Pin: 630214,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7635,
        Pin: 532213,
        DispatchCenter: "Pathapatnam_Kotaguddi_D (Andhra Pradesh)",
        OriginCenter: "Pathapatnam_Kotaguddi_D (Andhra Pradesh)",
        FacilityCity: "Pathapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7636,
        Pin: 794111,
        DispatchCenter: "Williamngr_BptistChrch_DPP (Meghalaya)",
        OriginCenter: "Williamngr_BptistChrch_DPP (Meghalaya)",
        FacilityCity: "Williamnagar",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7637,
        Pin: 517351,
        DispatchCenter: "BKothakota_RTCStop_D (Andhra Pradesh)",
        OriginCenter: "BKothakota_RTCStop_D (Andhra Pradesh)",
        FacilityCity: "B Kothakota",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7638,
        Pin: 142025,
        DispatchCenter: "Jagraon_Grainmkt_D (Punjab)",
        OriginCenter: "Jagraon_Grainmkt_D (Punjab)",
        FacilityCity: "Jagraon",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7639,
        Pin: 574243,
        DispatchCenter: "Vitla_FawzBuldng_DPP (Karnataka)",
        OriginCenter: "Vitla_FawzBuldng_DPP (Karnataka)",
        FacilityCity: "Vitla",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7640,
        Pin: 440008,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Chikhali_L (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7641,
        Pin: 783315,
        DispatchCenter: "Dhubri_Tetultol_D (Assam)",
        OriginCenter: "Dhubri_Tetultol_D (Assam)",
        FacilityCity: "Dhubri",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7642,
        Pin: 431126,
        DispatchCenter: "Beed_Jarud_D (Maharashtra)",
        OriginCenter: "Beed_Jarud_D (Maharashtra)",
        FacilityCity: "Beed",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7643,
        Pin: 691301,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7644,
        Pin: 577518,
        DispatchCenter: "Davangere_Santhebennur_D (Karnataka)",
        OriginCenter: "Davangere_Santhebennur_D (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7645,
        Pin: 736168,
        DispatchCenter: "Sitai_Khamarsitai_DPP (West Bengal)",
        OriginCenter: "Sitai_Khamarsitai_DPP (West Bengal)",
        FacilityCity: "Sitai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7646,
        Pin: 530032,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7647,
        Pin: 396530,
        DispatchCenter: "Bilimora_DC (Gujarat)",
        OriginCenter: "Bilimora_DC (Gujarat)",
        FacilityCity: "Bilimora",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7648,
        Pin: 110121,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7649,
        Pin: 301418,
        DispatchCenter: "Bhiwadi_Central_D_2 (Rajasthan)",
        OriginCenter: "Bhiwadi_Central_D_2 (Rajasthan)",
        FacilityCity: "Bhiwadi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7650,
        Pin: 440026,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Chikhali_L (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7651,
        Pin: 509105,
        DispatchCenter: "Kollapur_Vasavi_DPP (Telangana)",
        OriginCenter: "Kollapur_Vasavi_DPP (Telangana)",
        FacilityCity: "Kollapur",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7652,
        Pin: 731104,
        DispatchCenter: "Dubrajpur_Mansayer_D (West Bengal)",
        OriginCenter: "Dubrajpur_Mansayer_D (West Bengal)",
        FacilityCity: "Dubrajpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7653,
        Pin: 524201,
        DispatchCenter: "Kavali_Musunur_D (Andhra Pradesh)",
        OriginCenter: "Kavali_Musunur_D (Andhra Pradesh)",
        FacilityCity: "Kavali",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7654,
        Pin: 506356,
        DispatchCenter: "Mogullapally_ArrmCmplx_DPP (Telangana)",
        OriginCenter: "Mogullapally_ArrmCmplx_DPP (Telangana)",
        FacilityCity: "Mogullapally",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7655,
        Pin: 571129,
        DispatchCenter: "Nanjangud_Deviramanahalli_D (Karnataka)",
        OriginCenter: "Nanjangud_Deviramanahalli_D (Karnataka)",
        FacilityCity: "Nanjangud",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7656,
        Pin: 563113,
        DispatchCenter: "Bethamangala_Sriramatemp_D (Karnataka)",
        OriginCenter: "Bethamangala_Sriramatemp_D (Karnataka)",
        FacilityCity: "Bethamangala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7657,
        Pin: 202523,
        DispatchCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        OriginCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        FacilityCity: "Chandausi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7658,
        Pin: 685609,
        DispatchCenter: "Iddukki_Thadiyampad_D (Kerala)",
        OriginCenter: "Iddukki_Thadiyampad_D (Kerala)",
        FacilityCity: "Iddukki",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7659,
        Pin: 223223,
        DispatchCenter: "Rajesultanpur_PadumprMkt_D (Uttar Pradesh)",
        OriginCenter: "Rajesultanpur_PadumprMkt_D (Uttar Pradesh)",
        FacilityCity: "Rajesultanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7660,
        Pin: 413308,
        DispatchCenter: "Atapadi_VidyaNagar_D (Maharashtra)",
        OriginCenter: "Atapadi_VidyaNagar_D (Maharashtra)",
        FacilityCity: "Atapadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7661,
        Pin: 492112,
        DispatchCenter: "Basna_SSM_DPP (Chhattisgarh)",
        OriginCenter: "Basna_SSM_DPP (Chhattisgarh)",
        FacilityCity: "Basna",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7662,
        Pin: 759001,
        DispatchCenter: "Dhenkanal_Korian_D (Orissa)",
        OriginCenter: "Dhenkanal_Korian_D (Orissa)",
        FacilityCity: "Dhenkanal",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7663,
        Pin: 690529,
        DispatchCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        OriginCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7664,
        Pin: 761213,
        DispatchCenter: "Rayagada_MajhiStreet_DPP (Orissa)",
        OriginCenter: "Rayagada_MajhiStreet_DPP (Orissa)",
        FacilityCity: "Rayagada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7665,
        Pin: 388715,
        DispatchCenter: "Godhra_Soniwada_D (Gujarat)",
        OriginCenter: "Godhra_Soniwada_D (Gujarat)",
        FacilityCity: "Godhra",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7666,
        Pin: 464334,
        DispatchCenter: "GanjBasoda_Gyaraspur_DPP (Madhya Pradesh)",
        OriginCenter: "GanjBasoda_Gyaraspur_DPP (Madhya Pradesh)",
        FacilityCity: "Ganj Basoda",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7667,
        Pin: 145101,
        DispatchCenter: "Pathankot_Kahanpur_H (Punjab)",
        OriginCenter: "Pathankot_Kahanpur_H (Punjab)",
        FacilityCity: "Pathankot",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7668,
        Pin: 788030,
        DispatchCenter: "Udarbond_Pangram_D (Assam)",
        OriginCenter: "Udarbond_Pangram_D (Assam)",
        FacilityCity: "Udarbond",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7669,
        Pin: 400603,
        DispatchCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7670,
        Pin: 175018,
        DispatchCenter: "SundarNgr_BhojpurRd_D (Himachal Pradesh)",
        OriginCenter: "SundarNgr_BhojpurRd_D (Himachal Pradesh)",
        FacilityCity: "Sundar Nagar",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7671,
        Pin: 305008,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7672,
        Pin: 209407,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7673,
        Pin: 501507,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7674,
        Pin: 584113,
        DispatchCenter: "Devadurga_APMC_D (Karnataka)",
        OriginCenter: "Devadurga_APMC_D (Karnataka)",
        FacilityCity: "Devadurga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7675,
        Pin: 585444,
        DispatchCenter: "Bhalki_ShivajiChwk_DPP (Karnataka)",
        OriginCenter: "Bhalki_ShivajiChwk_DPP (Karnataka)",
        FacilityCity: "Bhalki",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7676,
        Pin: 500058,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7677,
        Pin: 623706,
        DispatchCenter: "Paramakudi_River_D (Tamil Nadu)",
        OriginCenter: "Paramakudi_River_D (Tamil Nadu)",
        FacilityCity: "Paramakudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7678,
        Pin: 403203,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7679,
        Pin: 621301,
        DispatchCenter: "Gujiliamparai_Palayam_DPP (Tamil Nadu)",
        OriginCenter: "Gujiliamparai_Palayam_DPP (Tamil Nadu)",
        FacilityCity: "Gujiliamparai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7680,
        Pin: 400114,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7681,
        Pin: 144630,
        DispatchCenter: "Nakodar_Goldenavenue_D (Punjab)",
        OriginCenter: "Nakodar_Goldenavenue_D (Punjab)",
        FacilityCity: "Nakodar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7682,
        Pin: 523117,
        DispatchCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        OriginCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        FacilityCity: "Kanigiri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7683,
        Pin: 621004,
        DispatchCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        OriginCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        FacilityCity: "Thuraiyur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7684,
        Pin: 571443,
        DispatchCenter: "Hanur_Maruthinagar_D (Karnataka)",
        OriginCenter: "Hanur_Maruthinagar_D (Karnataka)",
        FacilityCity: "Hanur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7685,
        Pin: 793107,
        DispatchCenter: "Cherrapunji_Ichamati_D (Meghalaya)",
        OriginCenter: "Cherrapunji_Ichamati_D (Meghalaya)",
        FacilityCity: "Cherrapunji",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7686,
        Pin: 799264,
        DispatchCenter: "Kailashahar_Kumarghat_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Kailashahar",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7687,
        Pin: 632318,
        DispatchCenter: "Arani_PolurRd_D (Tamil Nadu)",
        OriginCenter: "Arani_PolurRd_D (Tamil Nadu)",
        FacilityCity: "Arani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7688,
        Pin: 516110,
        DispatchCenter: "Koduru_Jagadampalli_D (Andhra Pradesh)",
        OriginCenter: "Koduru_Jagadampalli_D (Andhra Pradesh)",
        FacilityCity: "Koduru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7689,
        Pin: 638506,
        DispatchCenter: "Sathyamangalam_VNSNagar_D (Tamil Nadu)",
        OriginCenter: "Sathyamangalam_VNSNagar_D (Tamil Nadu)",
        FacilityCity: "Sathyamangalam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7690,
        Pin: 606002,
        DispatchCenter: "Virudhachalam_Tamilnagar_D (Tamil Nadu)",
        OriginCenter: "Virudhachalam_Tamilnagar_D (Tamil Nadu)",
        FacilityCity: "Virudhachalam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7691,
        Pin: 802311,
        DispatchCenter: "Arrah_Sakaddi_D (Bihar)",
        OriginCenter: "Arrah_Sakaddi_D (Bihar)",
        FacilityCity: "Arrah",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7692,
        Pin: 629180,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7693,
        Pin: 757037,
        DispatchCenter: "Karanjia_Ankura_D (Orissa)",
        OriginCenter: "Karanjia_Ankura_D (Orissa)",
        FacilityCity: "Karanjia",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7694,
        Pin: 341011,
        DispatchCenter: "Nagaur_Jantacomplex_D (Rajasthan)",
        OriginCenter: "Nagaur_Jantacomplex_D (Rajasthan)",
        FacilityCity: "Nagaur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7695,
        Pin: 453115,
        DispatchCenter: "Depalpur_Takipura_D (Madhya Pradesh)",
        OriginCenter: "Depalpur_Takipura_D (Madhya Pradesh)",
        FacilityCity: "Depalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7696,
        Pin: 392210,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7697,
        Pin: 638107,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7698,
        Pin: 507211,
        DispatchCenter: "Yellandu_Sudimala_D (Telangana)",
        OriginCenter: "Yellandu_Sudimala_D (Telangana)",
        FacilityCity: "Yellandu",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7699,
        Pin: 834008,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7700,
        Pin: 271207,
        DispatchCenter: "Balrampur_SahabNager_D (Uttar Pradesh)",
        OriginCenter: "Balrampur_SahabNager_D (Uttar Pradesh)",
        FacilityCity: "Balrampur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7701,
        Pin: 676318,
        DispatchCenter: "Malappuram_Kolappuram_D (Kerala)",
        OriginCenter: "Malappuram_Kolappuram_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7702,
        Pin: 679105,
        DispatchCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        OriginCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        FacilityCity: "Pazhayannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7703,
        Pin: 492005,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7704,
        Pin: 305005,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7705,
        Pin: 583130,
        DispatchCenter: "Molakalmuru_KnsgraRD_D (Karnataka)",
        OriginCenter: "Molakalmuru_KnsgraRD_D (Karnataka)",
        FacilityCity: "Molakalmuru",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7706,
        Pin: 755031,
        DispatchCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        OriginCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        FacilityCity: "Chandikhol",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7707,
        Pin: 490003,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7708,
        Pin: 802166,
        DispatchCenter: "Brahmapur_Shahpur_D (Bihar)",
        OriginCenter: "Brahmapur_Shahpur_D (Bihar)",
        FacilityCity: "Brahmapur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7709,
        Pin: 388345,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7710,
        Pin: 276142,
        DispatchCenter: "Rajesultanpur_PadumprMkt_D (Uttar Pradesh)",
        OriginCenter: "Rajesultanpur_PadumprMkt_D (Uttar Pradesh)",
        FacilityCity: "Rajesultanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7711,
        Pin: 688582,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7712,
        Pin: 766105,
        DispatchCenter: "Nuapada_NilshlaNgr_D (Orissa)",
        OriginCenter: "Nuapada_NilshlaNgr_D (Orissa)",
        FacilityCity: "Nuapada",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7713,
        Pin: 416130,
        DispatchCenter: "Ichalkaranji_TmbeMala_D (Maharashtra)",
        OriginCenter: "Ichalkaranji_TmbeMala_D (Maharashtra)",
        FacilityCity: "Ichalkaranji",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7714,
        Pin: 754131,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7715,
        Pin: 685604,
        DispatchCenter: "Iddukki_Thadiyampad_D (Kerala)",
        OriginCenter: "Iddukki_Thadiyampad_D (Kerala)",
        FacilityCity: "Iddukki",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7716,
        Pin: 813201,
        DispatchCenter: "Sultanganj_Dilgouri_D (Bihar)",
        OriginCenter: "Sultanganj_Dilgouri_D (Bihar)",
        FacilityCity: "Sultanganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7717,
        Pin: 815316,
        DispatchCenter: "Giridih_Mahthadih_D (Jharkhand)",
        OriginCenter: "Giridih_Mahthadih_D (Jharkhand)",
        FacilityCity: "Giridih",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7718,
        Pin: 246701,
        DispatchCenter: "Bijnor_Garhi_D (Uttar Pradesh)",
        OriginCenter: "Bijnor_Garhi_D (Uttar Pradesh)",
        FacilityCity: "Bijnor",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7719,
        Pin: 689505,
        DispatchCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        OriginCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7720,
        Pin: 576232,
        DispatchCenter: "Byndoor_Krmnjshwra_DPP (Karnataka)",
        OriginCenter: "Byndoor_Krmnjshwra_DPP (Karnataka)",
        FacilityCity: "Byndoor",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7721,
        Pin: 799100,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7722,
        Pin: 621706,
        DispatchCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        OriginCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        FacilityCity: "Thirukkattupalli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7723,
        Pin: 121011,
        DispatchCenter: "Faridabad_MathuraRoad_L (Haryana)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7724,
        Pin: 688003,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7725,
        Pin: 560025,
        DispatchCenter: "Bangalore_Shantinagar_L (Karnataka)",
        OriginCenter: "Bangalore_Shantinagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7726,
        Pin: 689586,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7727,
        Pin: 676105,
        DispatchCenter: "Tirur_Perunthallur_D (Kerala)",
        OriginCenter: "Tirur_Perunthallur_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7728,
        Pin: 784150,
        DispatchCenter: "Tezpur_Dipota_H (Assam)",
        OriginCenter: "Tezpur_Dipota_H (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7729,
        Pin: 444111,
        DispatchCenter: "Akot_CollegeRd_D (Maharashtra)",
        OriginCenter: "Akot_CollegeRd_D (Maharashtra)",
        FacilityCity: "Akot",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7730,
        Pin: 636119,
        DispatchCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        OriginCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        FacilityCity: "Attur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7731,
        Pin: 413506,
        DispatchCenter: "Osmanabad_RajasabH_D (Maharashtra)",
        OriginCenter: "Osmanabad_RajasabH_D (Maharashtra)",
        FacilityCity: "Osmanabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7732,
        Pin: 497117,
        DispatchCenter: "Premnagar_Sadakpara_D (Chhattisgarh)",
        OriginCenter: "Premnagar_Sadakpara_D (Chhattisgarh)",
        FacilityCity: "Premnagar",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7733,
        Pin: 461331,
        DispatchCenter: "Harda_HariOmNgr_DPP (Madhya Pradesh)",
        OriginCenter: "Harda_HariOmNgr_DPP (Madhya Pradesh)",
        FacilityCity: "Harda",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7734,
        Pin: 606110,
        DispatchCenter: "Virudhachalam_Tamilnagar_D (Tamil Nadu)",
        OriginCenter: "Virudhachalam_Tamilnagar_D (Tamil Nadu)",
        FacilityCity: "Virudhachalam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7735,
        Pin: 472115,
        DispatchCenter: "Jatara_Khargapur_DPP (Madhya Pradesh)",
        OriginCenter: "Jatara_Khargapur_DPP (Madhya Pradesh)",
        FacilityCity: "Jatara",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7736,
        Pin: 575030,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7737,
        Pin: 635204,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7738,
        Pin: 712201,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7739,
        Pin: 394660,
        DispatchCenter: "Songadh_Shivnagar2_DPP (Gujarat)",
        OriginCenter: "Songadh_Shivnagar2_DPP (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7740,
        Pin: 848142,
        DispatchCenter: "Tajpur_HsptlRd_D (Bihar)",
        OriginCenter: "Tajpur_HsptlRd_D (Bihar)",
        FacilityCity: "Tajpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7741,
        Pin: 110603,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7742,
        Pin: 284401,
        DispatchCenter: "Talbahat_CityRoad_DPP (Uttar Pradesh)",
        OriginCenter: "Talbahat_CityRoad_DPP (Uttar Pradesh)",
        FacilityCity: "Talbahat",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7743,
        Pin: 621311,
        DispatchCenter: "Gujiliamparai_Palayam_DPP (Tamil Nadu)",
        OriginCenter: "Gujiliamparai_Palayam_DPP (Tamil Nadu)",
        FacilityCity: "Gujiliamparai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7744,
        Pin: 721450,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7745,
        Pin: 689611,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7746,
        Pin: 416516,
        DispatchCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        OriginCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        FacilityCity: "Sawantwadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7747,
        Pin: 847227,
        DispatchCenter: "Khutauna_ShastriChwk_D (Bihar)",
        OriginCenter: "Khutauna_ShastriChwk_D (Bihar)",
        FacilityCity: "Khutauna",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7748,
        Pin: 500484,
        DispatchCenter: "Hyderabad_Medchal1_L (Telangana)",
        OriginCenter: "Hyderabad_Medchal_GW (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7749,
        Pin: 563122,
        DispatchCenter: "Bethamangala_Sriramatemp_D (Karnataka)",
        OriginCenter: "Bethamangala_Sriramatemp_D (Karnataka)",
        FacilityCity: "Bethamangala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7750,
        Pin: 678013,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7751,
        Pin: 577411,
        DispatchCenter: "Sringeri_Kalmetlu_DPP (Karnataka)",
        OriginCenter: "Sringeri_Kalmetlu_DPP (Karnataka)",
        FacilityCity: "Sringeri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7752,
        Pin: 403005,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7753,
        Pin: 560071,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7754,
        Pin: 679578,
        DispatchCenter: "Edappal_Nellissery_D (Kerala)",
        OriginCenter: "Edappal_Nellissery_D (Kerala)",
        FacilityCity: "Edappal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7755,
        Pin: 144401,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7756,
        Pin: 629171,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7757,
        Pin: 175052,
        DispatchCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        OriginCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        FacilityCity: "Mandi",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7758,
        Pin: 518422,
        DispatchCenter: "Atmakur_KGRd_D (Andhra Pradesh)",
        OriginCenter: "Atmakur_KGRd_D (Andhra Pradesh)",
        FacilityCity: "Atmakur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7759,
        Pin: 583153,
        DispatchCenter: "Bellary_Mundargi_I (Karnataka)",
        OriginCenter: "Bellary_Mundargi_I (Karnataka)",
        FacilityCity: "Bellary",
        FacilityState: "Karnataka",
        Zone : "S1"
      },
      {
        SN: 7760,
        Pin: 490013,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7761,
        Pin: 785663,
        DispatchCenter: "Sivasagar_Pdinnagar_D (Assam)",
        OriginCenter: "Sivasagar_Pdinnagar_D (Assam)",
        FacilityCity: "Sivasagar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7762,
        Pin: 700144,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7763,
        Pin: 328001,
        DispatchCenter: "Dholpur_KamlaColony_D (Rajasthan)",
        OriginCenter: "Dholpur_KamlaColony_D (Rajasthan)",
        FacilityCity: "Dholpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7764,
        Pin: 743127,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7765,
        Pin: 172025,
        DispatchCenter: "Anni_Dalash2_DPP (Himachal Pradesh)",
        OriginCenter: "Anni_Dalash2_DPP (Himachal Pradesh)",
        FacilityCity: "Anni",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7766,
        Pin: 416437,
        DispatchCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        OriginCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        FacilityCity: "Miraj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7767,
        Pin: 249410,
        DispatchCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        OriginCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        FacilityCity: "Haridwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7768,
        Pin: 627107,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7769,
        Pin: 305012,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7770,
        Pin: 334602,
        DispatchCenter: "Lunkaransar_Ajadcolony_D (Rajasthan)",
        OriginCenter: "Lunkaransar_Ajadcolony_D (Rajasthan)",
        FacilityCity: "Lunkaransar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7771,
        Pin: 683541,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7772,
        Pin: 761212,
        DispatchCenter: "Paralakhemundi_Omcolony_D (Orissa)",
        OriginCenter: "Paralakhemundi_Omcolony_D (Orissa)",
        FacilityCity: "Paralakhemundi",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7773,
        Pin: 585321,
        DispatchCenter: "Yadgir_Chiranjeevinagar_D (Karnataka)",
        OriginCenter: "Yadgir_Chiranjeevinagar_D (Karnataka)",
        FacilityCity: "Yadgir",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7774,
        Pin: 207123,
        DispatchCenter: "Kasganj_GandhiKunj_D (Uttar Pradesh)",
        OriginCenter: "Kasganj_GandhiKunj_D (Uttar Pradesh)",
        FacilityCity: "Kasganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7775,
        Pin: 223221,
        DispatchCenter: "NizamabadUP_UnionBnk_D (Uttar Pradesh)",
        OriginCenter: "NizamabadUP_UnionBnk_D (Uttar Pradesh)",
        FacilityCity: "Nizamabad-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7776,
        Pin: 122228,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7777,
        Pin: 685537,
        DispatchCenter: "Kumily_Attappallam_D (Kerala)",
        OriginCenter: "Kumily_Attappallam_D (Kerala)",
        FacilityCity: "Kumily",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7778,
        Pin: 192201,
        DispatchCenter: "Anantnag_Bonpora_DPP (Jammu & Kashmir)",
        OriginCenter: "Anantnag_Bonpora_DPP (Jammu & Kashmir)",
        FacilityCity: "Anantnag",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7779,
        Pin: 231218,
        DispatchCenter: "Anpara_Parasi_D (Uttar Pradesh)",
        OriginCenter: "Anpara_Parasi_D (Uttar Pradesh)",
        FacilityCity: "Anpara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7780,
        Pin: 571214,
        DispatchCenter: "Madikeri_Napoklu1_DPP (Karnataka)",
        OriginCenter: "Madikeri_Napoklu1_DPP (Karnataka)",
        FacilityCity: "Madikeri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7781,
        Pin: 122502,
        DispatchCenter: "Rewari_Gopalchowk_D (Haryana)",
        OriginCenter: "Rewari_Gopalchowk_D (Haryana)",
        FacilityCity: "Rewari",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7782,
        Pin: 635105,
        DispatchCenter: "Hosur_SipcotPhase1_L (Tamil Nadu)",
        OriginCenter: "Hosur_SipcotPhase1_L (Tamil Nadu)",
        FacilityCity: "Hosur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7783,
        Pin: 788121,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7784,
        Pin: 572114,
        DispatchCenter: "Tiptur_Chmundeshwri_D (Karnataka)",
        OriginCenter: "Tiptur_Chmundeshwri_D (Karnataka)",
        FacilityCity: "Tiptur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7785,
        Pin: 124514,
        DispatchCenter: "Meham_Ward13_D (Haryana)",
        OriginCenter: "Meham_Ward13_D (Haryana)",
        FacilityCity: "Meham",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7786,
        Pin: 758087,
        DispatchCenter: "Kendujhar_Jamunalia_I (Orissa)",
        OriginCenter: "Kendujhar_Jamunalia_I (Orissa)",
        FacilityCity: "Kendujhar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7787,
        Pin: 670611,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7788,
        Pin: 396060,
        DispatchCenter: "Vansda_Chikatiya_D (Gujarat)",
        OriginCenter: "Vansda_Chikatiya_D (Gujarat)",
        FacilityCity: "Vansda",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7789,
        Pin: 691529,
        DispatchCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        OriginCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7790,
        Pin: 460553,
        DispatchCenter: "Amla_Bodhki_D (Madhya Pradesh)",
        OriginCenter: "Amla_Bodhki_D (Madhya Pradesh)",
        FacilityCity: "Amla",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7791,
        Pin: 629602,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7792,
        Pin: 502305,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7793,
        Pin: 678002,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7794,
        Pin: 445102,
        DispatchCenter: "Yavatmal_Ner_D (Maharashtra)",
        OriginCenter: "Yavatmal_Ner_D (Maharashtra)",
        FacilityCity: "Yavatmal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7795,
        Pin: 691502,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7796,
        Pin: 632516,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7797,
        Pin: 534462,
        DispatchCenter: "Chintalpudi_KMRd_D (Andhra Pradesh)",
        OriginCenter: "Chintalpudi_KMRd_D (Andhra Pradesh)",
        FacilityCity: "Chintalpudi",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7798,
        Pin: 145025,
        DispatchCenter: "Pathankot_Kahanpur_H (Punjab)",
        OriginCenter: "Pathankot_Kahanpur_H (Punjab)",
        FacilityCity: "Pathankot",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7799,
        Pin: 422209,
        DispatchCenter: "Nashik_Ojhar_DPP (Maharashtra)",
        OriginCenter: "Nashik_Ojhar_DPP (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7800,
        Pin: 136026,
        DispatchCenter: "Kaithal_DefenceColony_D (Haryana)",
        OriginCenter: "Kaithal_DefenceColony_D (Haryana)",
        FacilityCity: "Kaithal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7801,
        Pin: 521301,
        DispatchCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        OriginCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        FacilityCity: "Gudivada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7802,
        Pin: 411037,
        DispatchCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        OriginCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7803,
        Pin: 752101,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7804,
        Pin: 570007,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7805,
        Pin: 754290,
        DispatchCenter: "Cuttack_SliprDPP_DC (Orissa)",
        OriginCenter: "Cuttack_SliprDPP_DC (Orissa)",
        FacilityCity: "Salipur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7806,
        Pin: 612001,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7807,
        Pin: 781306,
        DispatchCenter: "Sarthebari_Tmlichp_D (Assam)",
        OriginCenter: "Sarthebari_Tmlichp_D (Assam)",
        FacilityCity: "Sarthebari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7808,
        Pin: 110120,
        DispatchCenter: "Delhi_RailYardRd_L (Delhi)",
        OriginCenter: "Delhi_RailYardRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7809,
        Pin: 500082,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7810,
        Pin: 181143,
        DispatchCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7811,
        Pin: 225001,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7812,
        Pin: 608001,
        DispatchCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        OriginCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        FacilityCity: "Chidambaram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7813,
        Pin: 781382,
        DispatchCenter: "Baihata_Sundhia_D (Assam)",
        OriginCenter: "Baihata_Sundhia_D (Assam)",
        FacilityCity: "Baihata",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7814,
        Pin: 481001,
        DispatchCenter: "Balaghat_Sarekha_D (Madhya Pradesh)",
        OriginCenter: "Balaghat_Sarekha_D (Madhya Pradesh)",
        FacilityCity: "Balaghat",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7815,
        Pin: 171209,
        DispatchCenter: "Theog_Janogghat_D (Himachal Pradesh)",
        OriginCenter: "Theog_Janogghat_D (Himachal Pradesh)",
        FacilityCity: "Theog",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7816,
        Pin: 741201,
        DispatchCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        OriginCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        FacilityCity: "Ranaghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7817,
        Pin: 757091,
        DispatchCenter: "Jashipur_Chndposi_D (Orissa)",
        OriginCenter: "Jashipur_Chndposi_D (Orissa)",
        FacilityCity: "Jashipur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7818,
        Pin: 400089,
        DispatchCenter: "Mumbai_Chembur_L (Maharashtra)",
        OriginCenter: "Mumbai_Chembur_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7819,
        Pin: 360060,
        DispatchCenter: "Jasdan_Aanandnagar_D (Gujarat)",
        OriginCenter: "Jasdan_Aanandnagar_D (Gujarat)",
        FacilityCity: "Jasdan",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7820,
        Pin: 689627,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7821,
        Pin: 416405,
        DispatchCenter: "KMahankal_KM_D (Maharashtra)",
        OriginCenter: "KMahankal_KM_D (Maharashtra)",
        FacilityCity: "Kavathe Mahankal",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7822,
        Pin: 518323,
        DispatchCenter: "Adoni_Mandagiri_D (Andhra Pradesh)",
        OriginCenter: "Adoni_Mandagiri_D (Andhra Pradesh)",
        FacilityCity: "Adoni",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7823,
        Pin: 743355,
        DispatchCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        OriginCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        FacilityCity: "Dakshin Barasat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7824,
        Pin: 795011,
        DispatchCenter: "Moirang_Bishnupur_D (Manipur)",
        OriginCenter: "Moirang_Bishnupur_D (Manipur)",
        FacilityCity: "Moirang",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7825,
        Pin: 431528,
        DispatchCenter: "Parli_JirgeNgr_D (Maharashtra)",
        OriginCenter: "Parli_JirgeNgr_D (Maharashtra)",
        FacilityCity: "Parli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7826,
        Pin: 229206,
        DispatchCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        OriginCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        FacilityCity: "Lalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7827,
        Pin: 670643,
        DispatchCenter: "Kuthuparamba_IdstrlAr_D (Kerala)",
        OriginCenter: "Kuthuparamba_IdstrlAr_D (Kerala)",
        FacilityCity: "Kuthuparamba",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7828,
        Pin: 400090,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7829,
        Pin: 111120,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7830,
        Pin: 761115,
        DispatchCenter: "Asika_DsarathiNgr_DPP (Orissa)",
        OriginCenter: "Asika_DsarathiNgr_DPP (Orissa)",
        FacilityCity: "Asika",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7831,
        Pin: 243631,
        DispatchCenter: "Budaun_Usawan_D (Uttar Pradesh)",
        OriginCenter: "Budaun_Usawan_D (Uttar Pradesh)",
        FacilityCity: "Budaun",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7832,
        Pin: 574219,
        DispatchCenter: "Bantwal_BambilRd_D (Karnataka)",
        OriginCenter: "Bantwal_BambilRd_D (Karnataka)",
        FacilityCity: "Bantwal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7833,
        Pin: 496224,
        DispatchCenter: "Bargidih_Bagicha_D (Chhattisgarh)",
        OriginCenter: "Bargidih_Bagicha_D (Chhattisgarh)",
        FacilityCity: "Bargidih",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7834,
        Pin: 523373,
        DispatchCenter: "Giddalur_Nandyalroad_D (Andhra Pradesh)",
        OriginCenter: "Giddalur_Nandyalroad_D (Andhra Pradesh)",
        FacilityCity: "Giddalur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7835,
        Pin: 583129,
        DispatchCenter: "Kurugodu_Bellary_D (Karnataka)",
        OriginCenter: "Kurugodu_Bellary_D (Karnataka)",
        FacilityCity: "Kurugodu",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7836,
        Pin: 822111,
        DispatchCenter: "Barwadih_MainBazar_DPP (Jharkhand)",
        OriginCenter: "Barwadih_MainBazar_DPP (Jharkhand)",
        FacilityCity: "Barwadih",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7837,
        Pin: 734434,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7838,
        Pin: 673507,
        DispatchCenter: "Kuttiady_ValayannoorMasjid_D (Kerala)",
        OriginCenter: "Kuttiady_ValayannoorMasjid_D (Kerala)",
        FacilityCity: "Kuttiady",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7839,
        Pin: 853207,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7840,
        Pin: 144036,
        DispatchCenter: "Nakodar_Goldenavenue_D (Punjab)",
        OriginCenter: "Nakodar_Goldenavenue_D (Punjab)",
        FacilityCity: "Nakodar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7841,
        Pin: 141418,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7842,
        Pin: 636502,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7843,
        Pin: 642103,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7844,
        Pin: 797107,
        DispatchCenter: "Pfutsero_PWDcolony_D (Nagaland)",
        OriginCenter: "Pfutsero_PWDcolony_D (Nagaland)",
        FacilityCity: "Pfutsero",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7845,
        Pin: 752063,
        DispatchCenter: "Kalapathar_GoleiChhak_D (Orissa)",
        OriginCenter: "Kalapathar_GoleiChhak_D (Orissa)",
        FacilityCity: "Kalapathar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7846,
        Pin: 802206,
        DispatchCenter: "Sandesh_Agiaon_D (Bihar)",
        OriginCenter: "Sandesh_Agiaon_D (Bihar)",
        FacilityCity: "Sandesh",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7847,
        Pin: 533126,
        DispatchCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        OriginCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        FacilityCity: "Rajahmundry",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7848,
        Pin: 523331,
        DispatchCenter: "Doranala_KGRoad_DPP (Andhra Pradesh)",
        OriginCenter: "Doranala_KGRoad_DPP (Andhra Pradesh)",
        FacilityCity: "Doranala",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7849,
        Pin: 226104,
        DispatchCenter: "Malihabad_Amanignj_DPP (Uttar Pradesh)",
        OriginCenter: "Malihabad_Amanignj_DPP (Uttar Pradesh)",
        FacilityCity: "Malihabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7850,
        Pin: 700122,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7851,
        Pin: 364313,
        DispatchCenter: "Dhandhuka_Dholera_DPP (Gujarat)",
        OriginCenter: "Dhandhuka_Dholera_DPP (Gujarat)",
        FacilityCity: "Dhandhuka",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7852,
        Pin: 302038,
        DispatchCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7853,
        Pin: 313027,
        DispatchCenter: "Salumbar_Khetawatwadi_DPP (Rajasthan)",
        OriginCenter: "Salumbar_Khetawatwadi_DPP (Rajasthan)",
        FacilityCity: "Salumbar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7854,
        Pin: 505403,
        DispatchCenter: "Rudrangi_Busstand_DPP (Telangana)",
        OriginCenter: "Rudrangi_Busstand_DPP (Telangana)",
        FacilityCity: "Rudrangi",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7855,
        Pin: 743193,
        DispatchCenter: "Naihati_Rajendrapur_DPP (West Bengal)",
        OriginCenter: "Naihati_Rajendrapur_DPP (West Bengal)",
        FacilityCity: "Naihati",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7856,
        Pin: 174011,
        DispatchCenter: "NainaDevi_Baroh_DPP (Himachal Pradesh)",
        OriginCenter: "NainaDevi_Baroh_DPP (Himachal Pradesh)",
        FacilityCity: "Naina Devi",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7857,
        Pin: 577128,
        DispatchCenter: "Shimoga_Central_D_2 (Karnataka)",
        OriginCenter: "Shimoga_Central_D_2 (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7858,
        Pin: 531219,
        DispatchCenter: "Bheemunipatnam_PstOfc_D (Andhra Pradesh)",
        OriginCenter: "Bheemunipatnam_PstOfc_D (Andhra Pradesh)",
        FacilityCity: "Bheemunipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7859,
        Pin: 742305,
        DispatchCenter: "Domkal_StateBank_D (West Bengal)",
        OriginCenter: "Domkal_StateBank_D (West Bengal)",
        FacilityCity: "Domkal",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7860,
        Pin: 504220,
        DispatchCenter: "Tiryani_KomurambeemChk_DPP (Telangana)",
        OriginCenter: "Tiryani_KomurambeemChk_DPP (Telangana)",
        FacilityCity: "Tiryani",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7861,
        Pin: 322249,
        DispatchCenter: "Karauli_Subhashnagar_D (Rajasthan)",
        OriginCenter: "Karauli_Subhashnagar_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7862,
        Pin: 124406,
        DispatchCenter: "Rohtak_IndustrialArea_D (Haryana)",
        OriginCenter: "Rohtak_IndustrialArea_D (Haryana)",
        FacilityCity: "Rohtak",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7863,
        Pin: 360150,
        DispatchCenter: "Bhanvad_MadhavPlaza_DPP (Gujarat)",
        OriginCenter: "Bhanvad_MadhavPlaza_DPP (Gujarat)",
        FacilityCity: "Bhanvad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7864,
        Pin: 415608,
        DispatchCenter: "Sangameshwar_Burambi_DPP (Maharashtra)",
        OriginCenter: "Sangameshwar_Burambi_DPP (Maharashtra)",
        FacilityCity: "Sangameshwar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7865,
        Pin: 607404,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7866,
        Pin: 452001,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7867,
        Pin: 734431,
        DispatchCenter: "Bagdogra_Kadopani_D (West Bengal)",
        OriginCenter: "Bagdogra_Kadopani_D (West Bengal)",
        FacilityCity: "Bagdogra",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7868,
        Pin: 570010,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7869,
        Pin: 144533,
        DispatchCenter: "Rupnagar_Towercolony_D (Punjab)",
        OriginCenter: "Rupnagar_Towercolony_D (Punjab)",
        FacilityCity: "Rupnagar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7870,
        Pin: 229212,
        DispatchCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        OriginCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        FacilityCity: "Lalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7871,
        Pin: 226015,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7872,
        Pin: 635103,
        DispatchCenter: "Hosur_SipcotPhase1_L (Tamil Nadu)",
        OriginCenter: "Hosur_SipcotPhase1_L (Tamil Nadu)",
        FacilityCity: "Hosur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7873,
        Pin: 175030,
        DispatchCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        OriginCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        FacilityCity: "Bilaspur-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7874,
        Pin: 524314,
        DispatchCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        OriginCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7875,
        Pin: 335526,
        DispatchCenter: "Sangaria_Wardno16_D (Rajasthan)",
        OriginCenter: "Sangaria_Wardno16_D (Rajasthan)",
        FacilityCity: "Sangaria",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7876,
        Pin: 577515,
        DispatchCenter: "Hosadurga_Vidyanagara_D (Karnataka)",
        OriginCenter: "Hosadurga_Vidyanagara_D (Karnataka)",
        FacilityCity: "Hosadurga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7877,
        Pin: 171220,
        DispatchCenter: "Theog_Janogghat_D (Himachal Pradesh)",
        OriginCenter: "Theog_Janogghat_D (Himachal Pradesh)",
        FacilityCity: "Theog",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7878,
        Pin: 505425,
        DispatchCenter: "DharmapuriTS_Boyawada_D (Telangana)",
        OriginCenter: "DharmapuriTS_Boyawada_D (Telangana)",
        FacilityCity: "Dharmapuri-TS",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7879,
        Pin: 500018,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7880,
        Pin: 782448,
        DispatchCenter: "Lanka_Sambari_D (Assam)",
        OriginCenter: "Lanka_Sambari_D (Assam)",
        FacilityCity: "Lanka",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7881,
        Pin: 226002,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7882,
        Pin: 473287,
        DispatchCenter: "Maksudangarh_Rajivnagar_D (Madhya Pradesh)",
        OriginCenter: "Maksudangarh_Rajivnagar_D (Madhya Pradesh)",
        FacilityCity: "Maksudangarh",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7883,
        Pin: 191131,
        DispatchCenter: "Srinagar_Nagbal_D (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Nagbal_D (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7884,
        Pin: 422611,
        DispatchCenter: "Sangamner_Ksarwdi_D (Maharashtra)",
        OriginCenter: "Sangamner_Ksarwdi_D (Maharashtra)",
        FacilityCity: "Sangamner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7885,
        Pin: 461445,
        DispatchCenter: "Itarsi_AwnNgr_D (Madhya Pradesh)",
        OriginCenter: "Itarsi_AwnNgr_D (Madhya Pradesh)",
        FacilityCity: "Itarsi",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7886,
        Pin: 500009,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7887,
        Pin: 363440,
        DispatchCenter: "Limbdi_Sayla_D (Gujarat)",
        OriginCenter: "Limbdi_Sayla_D (Gujarat)",
        FacilityCity: "Limbdi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7888,
        Pin: 673612,
        DispatchCenter: "Balussery_Sivapuram_D (Kerala)",
        OriginCenter: "Balussery_Sivapuram_D (Kerala)",
        FacilityCity: "Balussery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7889,
        Pin: 333304,
        DispatchCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        OriginCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        FacilityCity: "Udaipurwati",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7890,
        Pin: 282004,
        DispatchCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        OriginCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        FacilityCity: "Agra",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7891,
        Pin: 848507,
        DispatchCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        OriginCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        FacilityCity: "Dalsingh Sarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7892,
        Pin: 496440,
        DispatchCenter: "Raigarh_Chhatamuda_D (Chhattisgarh)",
        OriginCenter: "Raigarh_Chhatamuda_D (Chhattisgarh)",
        FacilityCity: "Raigarh",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7893,
        Pin: 271401,
        DispatchCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        OriginCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        FacilityCity: "Gonda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7894,
        Pin: 845420,
        DispatchCenter: "Chakia_Honda_D (Bihar)",
        OriginCenter: "Chakia_Honda_D (Bihar)",
        FacilityCity: "Chakia",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7895,
        Pin: 473880,
        DispatchCenter: "Dabra_Bhitarwar_D (Madhya Pradesh)",
        OriginCenter: "Dabra_Bhitarwar_D (Madhya Pradesh)",
        FacilityCity: "Dabra",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7896,
        Pin: 784027,
        DispatchCenter: "Tezpur_Mahabhairab_D (Assam)",
        OriginCenter: "Tezpur_Mahabhairab_D (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7897,
        Pin: 262121,
        DispatchCenter: "Sitarganj_Bijti_D (Uttarakhand)",
        OriginCenter: "Sitarganj_Bijti_D (Uttarakhand)",
        FacilityCity: "Sitarganj",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7898,
        Pin: 395009,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7899,
        Pin: 786614,
        DispatchCenter: "Naharkatia_JaypurNagar_D (Assam)",
        OriginCenter: "Naharkatia_JaypurNagar_D (Assam)",
        FacilityCity: "Naharkatia",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7900,
        Pin: 531118,
        DispatchCenter: "Narsipatnam_Nathavaram_DPP (Andhra Pradesh)",
        OriginCenter: "Narsipatnam_Nathavaram_DPP (Andhra Pradesh)",
        FacilityCity: "Narsipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7901,
        Pin: 242042,
        DispatchCenter: "Khutar_Narayanpur_D (Uttar Pradesh)",
        OriginCenter: "Khutar_Narayanpur_D (Uttar Pradesh)",
        FacilityCity: "Khutar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7902,
        Pin: 362220,
        DispatchCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        OriginCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        FacilityCity: "Keshod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7903,
        Pin: 690526,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7904,
        Pin: 464337,
        DispatchCenter: "Khurai_Ward11_DPP (Madhya Pradesh)",
        OriginCenter: "Khurai_Ward11_DPP (Madhya Pradesh)",
        FacilityCity: "Khurai",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7905,
        Pin: 585212,
        DispatchCenter: "Afzalpur_Usmaniacolony_D (Karnataka)",
        OriginCenter: "Afzalpur_Usmaniacolony_D (Karnataka)",
        FacilityCity: "Afzalpur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7906,
        Pin: 700008,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7907,
        Pin: 742159,
        DispatchCenter: "Morgram_Kantagoriya_D (West Bengal)",
        OriginCenter: "Morgram_Kantagoriya_D (West Bengal)",
        FacilityCity: "Morgram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7908,
        Pin: 587128,
        DispatchCenter: "Bagalkot_NavaNgr_D (Karnataka)",
        OriginCenter: "Bagalkot_NavaNgr_D (Karnataka)",
        FacilityCity: "Bagalkot",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7909,
        Pin: 465679,
        DispatchCenter: "Rajgarh_Bhanwarcolony_D (Madhya Pradesh)",
        OriginCenter: "Rajgarh_Bhanwarcolony_D (Madhya Pradesh)",
        FacilityCity: "Rajgarh-MP",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 7910,
        Pin: 485447,
        DispatchCenter: "Nagod_Pipri_D (Madhya Pradesh)",
        OriginCenter: "Nagod_Pipri_D (Madhya Pradesh)",
        FacilityCity: "Nagod",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7911,
        Pin: 764074,
        DispatchCenter: "Raighar_PtrkutiChwk_DPP (Orissa)",
        OriginCenter: "Raighar_PtrkutiChwk_DPP (Orissa)",
        FacilityCity: "Raighar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7912,
        Pin: 277123,
        DispatchCenter: "Ballia_Sikandarpur_D (Uttar Pradesh)",
        OriginCenter: "Ballia_Sikandarpur_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7913,
        Pin: 784146,
        DispatchCenter: "Mangaldoi_Anandanagar_D (Assam)",
        OriginCenter: "Mangaldoi_Anandanagar_D (Assam)",
        FacilityCity: "Mangaldoi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7914,
        Pin: 690568,
        DispatchCenter: "Kollam_Karunagappally_D (Kerala)",
        OriginCenter: "Kollam_Karunagappally_D (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7915,
        Pin: 822125,
        DispatchCenter: "Ranka_Godarmana_DPP (Jharkhand)",
        OriginCenter: "Ranka_Godarmana_DPP (Jharkhand)",
        FacilityCity: "Ranka",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7916,
        Pin: 721628,
        DispatchCenter: "Haldia_Basudevpur_D (West Bengal)",
        OriginCenter: "Haldia_Basudevpur_D (West Bengal)",
        FacilityCity: "Haldia",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7917,
        Pin: 799262,
        DispatchCenter: "Dharmanagar_Churaibari_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Dharmanagar",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7918,
        Pin: 522329,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7919,
        Pin: 505184,
        DispatchCenter: "Manthani_Suraiahpalli_D (Telangana)",
        OriginCenter: "Manthani_Suraiahpalli_D (Telangana)",
        FacilityCity: "Manthani",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7920,
        Pin: 515722,
        DispatchCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        OriginCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7921,
        Pin: 231310,
        DispatchCenter: "Robertsganj_Sirsai_D (Uttar Pradesh)",
        OriginCenter: "Robertsganj_Sirsai_D (Uttar Pradesh)",
        FacilityCity: "Robertsganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7922,
        Pin: 392020,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7923,
        Pin: 110509,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7924,
        Pin: 787023,
        DispatchCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        OriginCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        FacilityCity: "Itanagar",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7925,
        Pin: 273306,
        DispatchCenter: "Bhathat_Market_D (Uttar Pradesh)",
        OriginCenter: "Bhathat_Market_D (Uttar Pradesh)",
        FacilityCity: "Bhathat",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7926,
        Pin: 574116,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7927,
        Pin: 413007,
        DispatchCenter: "Solapur_Balepeth_I (Maharashtra)",
        OriginCenter: "Solapur_Balepeth_I (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7928,
        Pin: 756030,
        DispatchCenter: "Basta_Darada_DPP (Orissa)",
        OriginCenter: "Basta_Darada_DPP (Orissa)",
        FacilityCity: "Basta",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7929,
        Pin: 283103,
        DispatchCenter: "Firozabad_MahadeoNagar_D (Uttar Pradesh)",
        OriginCenter: "Firozabad_MahadeoNagar_D (Uttar Pradesh)",
        FacilityCity: "Firozabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7930,
        Pin: 613007,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7931,
        Pin: 742157,
        DispatchCenter: "Khargram_Parbatipur_D (West Bengal)",
        OriginCenter: "Khargram_Parbatipur_D (West Bengal)",
        FacilityCity: "Khargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7932,
        Pin: 389382,
        DispatchCenter: "Dahod_Vanzarwada_I (Gujarat)",
        OriginCenter: "Dahod_Vanzarwada_I (Gujarat)",
        FacilityCity: "Dahod",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 7933,
        Pin: 389154,
        DispatchCenter: "Dahod_Vanzarwada_I (Gujarat)",
        OriginCenter: "Dahod_Vanzarwada_I (Gujarat)",
        FacilityCity: "Dahod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7934,
        Pin: 431116,
        DispatchCenter: "Vaijapur_Rajsarthi_D (Maharashtra)",
        OriginCenter: "Vaijapur_Rajsarthi_D (Maharashtra)",
        FacilityCity: "Vaijapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7935,
        Pin: 686536,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7936,
        Pin: 532407,
        DispatchCenter: "Ranastalam_Seethamvalasa_D (Andhra Pradesh)",
        OriginCenter: "Ranastalam_Seethamvalasa_D (Andhra Pradesh)",
        FacilityCity: "Ranastalam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7937,
        Pin: 509324,
        DispatchCenter: "Kalwakurthy_Margadarshi_D (Telangana)",
        OriginCenter: "Kalwakurthy_Margadarshi_D (Telangana)",
        FacilityCity: "Kalwakurthy",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7938,
        Pin: 754210,
        DispatchCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        OriginCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7939,
        Pin: 327032,
        DispatchCenter: "Banswara_Bagidora_D (Rajasthan)",
        OriginCenter: "Banswara_Bagidora_D (Rajasthan)",
        FacilityCity: "Banswara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7940,
        Pin: 464884,
        DispatchCenter: "Begumganj_ShyamNgr_DPP (Madhya Pradesh)",
        OriginCenter: "Begumganj_ShyamNgr_DPP (Madhya Pradesh)",
        FacilityCity: "Begumganj",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7941,
        Pin: 225408,
        DispatchCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        OriginCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        FacilityCity: "Dariyabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7942,
        Pin: 249407,
        DispatchCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        OriginCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        FacilityCity: "Haridwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7943,
        Pin: 284201,
        DispatchCenter: "Prithvipur_Niwari_D (Madhya Pradesh)",
        OriginCenter: "Prithvipur_Niwari_D (Madhya Pradesh)",
        FacilityCity: "Prithvipur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 7944,
        Pin: 515005,
        DispatchCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        OriginCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7945,
        Pin: 721121,
        DispatchCenter: "Midnapore_Sarbera_DPP (West Bengal)",
        OriginCenter: "Midnapore_Sarbera_DPP (West Bengal)",
        FacilityCity: "Midnapore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7946,
        Pin: 363520,
        DispatchCenter: "Chotila_Surendranagar_D (Gujarat)",
        OriginCenter: "Chotila_Surendranagar_D (Gujarat)",
        FacilityCity: "Chotila",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7947,
        Pin: 143116,
        DispatchCenter: "Tarsikka_SaidoLehal_D (Punjab)",
        OriginCenter: "Tarsikka_SaidoLehal_D (Punjab)",
        FacilityCity: "Tarsikka",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7948,
        Pin: 305408,
        DispatchCenter: "Kekri_LodhaChowk_DPP (Rajasthan)",
        OriginCenter: "Kekri_LodhaChowk_DPP (Rajasthan)",
        FacilityCity: "Kekri",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7949,
        Pin: 635808,
        DispatchCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ambur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7950,
        Pin: 843152,
        DispatchCenter: "Sahebganj_Goti_D (Bihar)",
        OriginCenter: "Sahebganj_Goti_D (Bihar)",
        FacilityCity: "Sahebganj-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 7951,
        Pin: 412203,
        DispatchCenter: "Daund_Kurkumbh_L (Maharashtra)",
        OriginCenter: "Daund_Kurkumbh_L (Maharashtra)",
        FacilityCity: "Daund",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7952,
        Pin: 191202,
        DispatchCenter: "Kangan_Daraduder_DPP (Jammu & Kashmir)",
        OriginCenter: "Kangan_Daraduder_DPP (Jammu & Kashmir)",
        FacilityCity: "Kangan",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 7953,
        Pin: 524226,
        DispatchCenter: "Udayagiri_Msjidcmplx_D (Andhra Pradesh)",
        OriginCenter: "Udayagiri_Msjidcmplx_D (Andhra Pradesh)",
        FacilityCity: "Udayagiri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7954,
        Pin: 688005,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7955,
        Pin: 246164,
        DispatchCenter: "Pauri_Khndyusn_D (Uttarakhand)",
        OriginCenter: "Pauri_Khndyusn_D (Uttarakhand)",
        FacilityCity: "Pauri",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7956,
        Pin: 335513,
        DispatchCenter: "Hanumangarh_DhaanMandi_I (Rajasthan)",
        OriginCenter: "Hanumangarh_DhaanMandi_I (Rajasthan)",
        FacilityCity: "Hanumangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7957,
        Pin: 613103,
        DispatchCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        OriginCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        FacilityCity: "Thirukkattupalli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7958,
        Pin: 754155,
        DispatchCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        OriginCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7959,
        Pin: 761116,
        DispatchCenter: "Asika_DsarathiNgr_DPP (Orissa)",
        OriginCenter: "Asika_DsarathiNgr_DPP (Orissa)",
        FacilityCity: "Asika",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7960,
        Pin: 140702,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7961,
        Pin: 425510,
        DispatchCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        OriginCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        FacilityCity: "Bhusawal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7962,
        Pin: 786124,
        DispatchCenter: "Tinsukia_Nokhroygaon2_D (Assam)",
        OriginCenter: "Tinsukia_Nokhroygaon2_D (Assam)",
        FacilityCity: "Tinsukia",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7963,
        Pin: 759143,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7964,
        Pin: 632403,
        DispatchCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ranipet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7965,
        Pin: 143409,
        DispatchCenter: "Goindwal_Fatehabad_D (Punjab)",
        OriginCenter: "Goindwal_Fatehabad_D (Punjab)",
        FacilityCity: "Goindwal",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7966,
        Pin: 442109,
        DispatchCenter: "Wardha_Alodi_D (Maharashtra)",
        OriginCenter: "Wardha_Alodi_D (Maharashtra)",
        FacilityCity: "Wardha",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7967,
        Pin: 627856,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7968,
        Pin: 605755,
        DispatchCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        OriginCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        FacilityCity: "Thirukoilure",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7969,
        Pin: 344501,
        DispatchCenter: "Barmer_Baldevnagar_DPP (Rajasthan)",
        OriginCenter: "Barmer_Baldevnagar_DPP (Rajasthan)",
        FacilityCity: "Barmer",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7970,
        Pin: 305813,
        DispatchCenter: "Kishangarh_AirportRd_D (Rajasthan)",
        OriginCenter: "Kishangarh_AirportRd_D (Rajasthan)",
        FacilityCity: "Kishangarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7971,
        Pin: 751029,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 7972,
        Pin: 713303,
        DispatchCenter: "Asansol_Addacolony_D (West Bengal)",
        OriginCenter: "Asansol_Addacolony_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7973,
        Pin: 639205,
        DispatchCenter: "Gujiliamparai_Palayam_DPP (Tamil Nadu)",
        OriginCenter: "Gujiliamparai_Palayam_DPP (Tamil Nadu)",
        FacilityCity: "Gujiliamparai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7974,
        Pin: 400001,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7975,
        Pin: 695027,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7976,
        Pin: 754037,
        DispatchCenter: "Badamba_Biswambharpur_D (Orissa)",
        OriginCenter: "Badamba_Biswambharpur_D (Orissa)",
        FacilityCity: "Badamba",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7977,
        Pin: 606205,
        DispatchCenter: "Thirukoilure_Memalur_DPP (Tamil Nadu)",
        OriginCenter: "Thirukoilure_Memalur_DPP (Tamil Nadu)",
        FacilityCity: "Memalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7978,
        Pin: 614717,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7979,
        Pin: 148105,
        DispatchCenter: "Barnala_DC (Punjab)",
        OriginCenter: "Barnala_DC (Punjab)",
        FacilityCity: "Barnala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7980,
        Pin: 534305,
        DispatchCenter: "Kovvur_Samisragudem_D (Andhra Pradesh)",
        OriginCenter: "Kovvur_Samisragudem_D (Andhra Pradesh)",
        FacilityCity: "Kovvur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7981,
        Pin: 493109,
        DispatchCenter: "Gariyaband_Dongari_D (Chhattisgarh)",
        OriginCenter: "Gariyaband_Dongari_D (Chhattisgarh)",
        FacilityCity: "Gariyaband",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7982,
        Pin: 690513,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 7983,
        Pin: 410207,
        DispatchCenter: "Mumbai_Ariwali_L (Maharashtra)",
        OriginCenter: "Mumbai_Ariwali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7984,
        Pin: 400064,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7985,
        Pin: 495445,
        DispatchCenter: "Korba_Katghora_D (Chhattisgarh)",
        OriginCenter: "Korba_Katghora_D (Chhattisgarh)",
        FacilityCity: "Korba",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 7986,
        Pin: 518135,
        DispatchCenter: "Betamcherla_Bngnpalle_D (Andhra Pradesh)",
        OriginCenter: "Betamcherla_Bngnpalle_D (Andhra Pradesh)",
        FacilityCity: "Betamcherla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7987,
        Pin: 788169,
        DispatchCenter: "Hailakandi_GangprDhmkr_D (Assam)",
        OriginCenter: "Hailakandi_GangprDhmkr_D (Assam)",
        FacilityCity: "Hailakandi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 7988,
        Pin: 416551,
        DispatchCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        OriginCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        FacilityCity: "Gadhinglaj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7989,
        Pin: 225122,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 7990,
        Pin: 610204,
        DispatchCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        FacilityCity: "Thiruthuraipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 7991,
        Pin: 507183,
        DispatchCenter: "Khammam_Mudigonda_H (Telangana)",
        OriginCenter: "Khammam_Mudigonda_H (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7992,
        Pin: 210501,
        DispatchCenter: "Muskara_KaliMataM_D (Uttar Pradesh)",
        OriginCenter: "Muskara_KaliMataM_D (Uttar Pradesh)",
        FacilityCity: "Muskara",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 7993,
        Pin: 382170,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 7994,
        Pin: 416549,
        DispatchCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        OriginCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        FacilityCity: "Sawantwadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 7995,
        Pin: 160045,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 7996,
        Pin: 637302,
        DispatchCenter: "Sankari_Idappadi_D (Tamil Nadu)",
        OriginCenter: "Sankari_Idappadi_D (Tamil Nadu)",
        FacilityCity: "Sankari",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 7997,
        Pin: 425116,
        DispatchCenter: "Jalgaon_MIDC_I (Maharashtra)",
        OriginCenter: "Jalgaon_MIDC_I (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 7998,
        Pin: 689672,
        DispatchCenter: "Ranni_Chethakhl_D (Kerala)",
        OriginCenter: "Ranni_Chethakhl_D (Kerala)",
        FacilityCity: "Ranni",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 7999,
        Pin: 325203,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8000,
        Pin: 670704,
        DispatchCenter: "Chavassery_Kelanpeedika_D (Kerala)",
        OriginCenter: "Chavassery_Kelanpeedika_D (Kerala)",
        FacilityCity: "Chavassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8001,
        Pin: 124106,
        DispatchCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        OriginCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        FacilityCity: "Charkhi Dadri",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8002,
        Pin: 229306,
        DispatchCenter: "Raebareli_Ratapur_D (Uttar Pradesh)",
        OriginCenter: "Raebareli_Ratapur_D (Uttar Pradesh)",
        FacilityCity: "Raebareli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8003,
        Pin: 638504,
        DispatchCenter: "Anthiyur_Thavittuplym_DPP (Tamil Nadu)",
        OriginCenter: "Anthiyur_Thavittuplym_DPP (Tamil Nadu)",
        FacilityCity: "Anthiyour",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8004,
        Pin: 610001,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8005,
        Pin: 221208,
        DispatchCenter: "Cholapur_Katari_D (Uttar Pradesh)",
        OriginCenter: "Cholapur_Katari_D (Uttar Pradesh)",
        FacilityCity: "Cholapur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8006,
        Pin: 484774,
        DispatchCenter: "Beohari_Wardno13_D (Madhya Pradesh)",
        OriginCenter: "Beohari_Wardno13_D (Madhya Pradesh)",
        FacilityCity: "Beohari",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 8007,
        Pin: 151003,
        DispatchCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        OriginCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        FacilityCity: "Bathinda",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8008,
        Pin: 828127,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8009,
        Pin: 505503,
        DispatchCenter: "Mahadevpur_Nallagunta_D (Telangana)",
        OriginCenter: "Mahadevpur_Nallagunta_D (Telangana)",
        FacilityCity: "Mahadevpur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8010,
        Pin: 770070,
        DispatchCenter: "Rajgangpur_Central_D_2 (Orissa)",
        OriginCenter: "Rajgangpur_Central_D_2 (Orissa)",
        FacilityCity: "Rajgangpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8011,
        Pin: 521121,
        DispatchCenter: "Avanigadda_Ramnagar_D (Andhra Pradesh)",
        OriginCenter: "Avanigadda_Ramnagar_D (Andhra Pradesh)",
        FacilityCity: "Avanigadda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8012,
        Pin: 732124,
        DispatchCenter: "Malda_Kadubari_D (West Bengal)",
        OriginCenter: "Malda_Kadubari_D (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8013,
        Pin: 202305,
        DispatchCenter: "Aligarh_Atrauli_D (Uttar Pradesh)",
        OriginCenter: "Aligarh_Atrauli_D (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8014,
        Pin: 132026,
        DispatchCenter: "Kaithal_DefenceColony_D (Haryana)",
        OriginCenter: "Kaithal_DefenceColony_D (Haryana)",
        FacilityCity: "Kaithal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8015,
        Pin: 851218,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8016,
        Pin: 521201,
        DispatchCenter: "Nuzvid_NSPColony_D (Andhra Pradesh)",
        OriginCenter: "Nuzvid_NSPColony_D (Andhra Pradesh)",
        FacilityCity: "Nuzvid",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8017,
        Pin: 209827,
        DispatchCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        OriginCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        FacilityCity: "Unnao",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8018,
        Pin: 209401,
        DispatchCenter: "Ambujnagar_Narwal_D (Uttar Pradesh)",
        OriginCenter: "Ambujnagar_Narwal_D (Uttar Pradesh)",
        FacilityCity: "Ambuj Nagar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8019,
        Pin: 516268,
        DispatchCenter: "Rayachoti_Ramapuram_D (Andhra Pradesh)",
        OriginCenter: "Rayachoti_Ramapuram_D (Andhra Pradesh)",
        FacilityCity: "Rayachoti",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8020,
        Pin: 176115,
        DispatchCenter: "Baijnath_Paprola_DPP (Himachal Pradesh)",
        OriginCenter: "Baijnath_Paprola_DPP (Himachal Pradesh)",
        FacilityCity: "Baijnath",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8021,
        Pin: 643103,
        DispatchCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        OriginCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8022,
        Pin: 670567,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8023,
        Pin: 209735,
        DispatchCenter: "Chhibramau_TajpurRd_D (Uttar Pradesh)",
        OriginCenter: "Chhibramau_TajpurRd_D (Uttar Pradesh)",
        FacilityCity: "Chhibramau",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8024,
        Pin: 141205,
        DispatchCenter: "Raikot_DC (Punjab)",
        OriginCenter: "Raikot_DC (Punjab)",
        FacilityCity: "Raikot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8025,
        Pin: 411026,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8026,
        Pin: 475115,
        DispatchCenter: "Dabra_Pichhoreroad_D (Madhya Pradesh)",
        OriginCenter: "Dabra_Pichhoreroad_D (Madhya Pradesh)",
        FacilityCity: "Dabra",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 8027,
        Pin: 400610,
        DispatchCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8028,
        Pin: 785638,
        DispatchCenter: "Teok_Wardno3_D (Assam)",
        OriginCenter: "Teok_Wardno3_D (Assam)",
        FacilityCity: "Teok",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8029,
        Pin: 458118,
        DispatchCenter: "Manasa_WardNo12_DPP (Madhya Pradesh)",
        OriginCenter: "Manasa_WardNo12_DPP (Madhya Pradesh)",
        FacilityCity: "Manasa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 8030,
        Pin: 276204,
        DispatchCenter: "Azamgarh_Lalganj_DPP (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Lalganj_DPP (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8031,
        Pin: 171304,
        DispatchCenter: "Karsog_Busstd_D (Himachal Pradesh)",
        OriginCenter: "Karsog_Busstd_D (Himachal Pradesh)",
        FacilityCity: "Karsog",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8032,
        Pin: 518467,
        DispatchCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        OriginCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8033,
        Pin: 742404,
        DispatchCenter: "Dakshinkhanda_BusStand_DPP (West Bengal)",
        OriginCenter: "Dakshinkhanda_BusStand_DPP (West Bengal)",
        FacilityCity: "Katwa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8034,
        Pin: 629204,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8035,
        Pin: 574241,
        DispatchCenter: "Puttur_Darbe_D (Karnataka)",
        OriginCenter: "Puttur_Darbe_D (Karnataka)",
        FacilityCity: "Puttur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8036,
        Pin: 680684,
        DispatchCenter: "Chalakudy_ForestChowk_D (Kerala)",
        OriginCenter: "Chalakudy_ForestChowk_D (Kerala)",
        FacilityCity: "Chalakudy",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8037,
        Pin: 670309,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8038,
        Pin: 303901,
        DispatchCenter: "Chaksu_Arihantkunj_D (Rajasthan)",
        OriginCenter: "Chaksu_Arihantkunj_D (Rajasthan)",
        FacilityCity: "Chaksu",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8039,
        Pin: 341025,
        DispatchCenter: "Khimsar_Hanumancolony_D (Rajasthan)",
        OriginCenter: "Khimsar_Hanumancolony_D (Rajasthan)",
        FacilityCity: "Khimsar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8040,
        Pin: 752121,
        DispatchCenter: "Nimapara_Nuharakiri_DPP (Orissa)",
        OriginCenter: "Nimapara_Nuharakiri_DPP (Orissa)",
        FacilityCity: "Nimapara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8041,
        Pin: 505303,
        DispatchCenter: "Sircilla_Gollapalli_D (Telangana)",
        OriginCenter: "Sircilla_Gollapalli_D (Telangana)",
        FacilityCity: "Yellareddipet",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8042,
        Pin: 412806,
        DispatchCenter: "Mahabaleshwar_BhilarTal_DPP (Maharashtra)",
        OriginCenter: "Mahabaleshwar_BhilarTal_DPP (Maharashtra)",
        FacilityCity: "Mahabaleshwar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8043,
        Pin: 416235,
        DispatchCenter: "Kolhapur_Vandur_D (Maharashtra)",
        OriginCenter: "Kolhapur_Vandur_D (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8044,
        Pin: 638012,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8045,
        Pin: 171204,
        DispatchCenter: "Kotkhai_Nehra_D (Himachal Pradesh)",
        OriginCenter: "Kotkhai_Nehra_D (Himachal Pradesh)",
        FacilityCity: "Kotkhai",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8046,
        Pin: 272171,
        DispatchCenter: "Dhanghata_MarhaRaja_DPP (Uttar Pradesh)",
        OriginCenter: "Dhanghata_MarhaRaja_DPP (Uttar Pradesh)",
        FacilityCity: "Dhanghata",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8047,
        Pin: 176028,
        DispatchCenter: "NagrotaSurian_Saknra_DPP (Himachal Pradesh)",
        OriginCenter: "NagrotaSurian_Saknra_DPP (Himachal Pradesh)",
        FacilityCity: "Nagrota Surian",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8048,
        Pin: 574223,
        DispatchCenter: "Sullia_Kanthamangala_D (Karnataka)",
        OriginCenter: "Sullia_Kanthamangala_D (Karnataka)",
        FacilityCity: "Sullia",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8049,
        Pin: 768221,
        DispatchCenter: "Bamra_Govindpur_D (Orissa)",
        OriginCenter: "Bamra_Govindpur_D (Orissa)",
        FacilityCity: "Bamra",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8050,
        Pin: 431105,
        DispatchCenter: "Paithan_SantaNGR_D (Maharashtra)",
        OriginCenter: "Paithan_SantaNGR_D (Maharashtra)",
        FacilityCity: "Paithan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8051,
        Pin: 456335,
        DispatchCenter: "Nagda_PrkashColny_D (Madhya Pradesh)",
        OriginCenter: "Nagda_PrkashColny_D (Madhya Pradesh)",
        FacilityCity: "Nagda",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8052,
        Pin: 847305,
        DispatchCenter: "Benipatti_Sarisab_D (Bihar)",
        OriginCenter: "Benipatti_Sarisab_D (Bihar)",
        FacilityCity: "Benipatti",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8053,
        Pin: 733140,
        DispatchCenter: "Gangarampr_Ramkrishnpaly_DPP (West Bengal)",
        OriginCenter: "Gangarampr_Ramkrishnpaly_DPP (West Bengal)",
        FacilityCity: "Gangarampur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8054,
        Pin: 832103,
        DispatchCenter: "Ghatshila_Ekramcolony_D (Jharkhand)",
        OriginCenter: "Ghatshila_Ekramcolony_D (Jharkhand)",
        FacilityCity: "Ghatshila",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8055,
        Pin: 431602,
        DispatchCenter: "Nanded_Wajegaon_H (Maharashtra)",
        OriginCenter: "Nanded_Wajegaon_H (Maharashtra)",
        FacilityCity: "Nanded",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8056,
        Pin: 177025,
        DispatchCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        OriginCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        FacilityCity: "Jahu",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8057,
        Pin: 828402,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8058,
        Pin: 403604,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8059,
        Pin: 384340,
        DispatchCenter: "Satlasana_Hndutmpl_DPP (Gujarat)",
        OriginCenter: "Satlasana_Hndutmpl_DPP (Gujarat)",
        FacilityCity: "Satlasana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8060,
        Pin: 686582,
        DispatchCenter: "Pala_Elappunkal_D (Kerala)",
        OriginCenter: "Pala_Elappunkal_D (Kerala)",
        FacilityCity: "Pala",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8061,
        Pin: 781015,
        DispatchCenter: "Guwahati_Kaikchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8062,
        Pin: 742147,
        DispatchCenter: "Khargram_Parbatipur_D (West Bengal)",
        OriginCenter: "Khargram_Parbatipur_D (West Bengal)",
        FacilityCity: "Khargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8063,
        Pin: 400092,
        DispatchCenter: "Mumbai_Kandivali_L (Maharashtra)",
        OriginCenter: "Mumbai_Kandivali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8064,
        Pin: 625302,
        DispatchCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        OriginCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        FacilityCity: "Melur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8065,
        Pin: 712708,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8066,
        Pin: 686013,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8067,
        Pin: 783393,
        DispatchCenter: "Bongaigaon_Chapaguri_I (Assam)",
        OriginCenter: "Bongaigaon_Chapaguri_I (Assam)",
        FacilityCity: "Bongaigaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8068,
        Pin: 680619,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8069,
        Pin: 387315,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8070,
        Pin: 181201,
        DispatchCenter: "Akhnoor_MiltryHosp_D (Jammu & Kashmir)",
        OriginCenter: "Akhnoor_MiltryHosp_D (Jammu & Kashmir)",
        FacilityCity: "Akhnoor",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8071,
        Pin: 481998,
        DispatchCenter: "Bichhiya_ShishuMndir_DPP (Madhya Pradesh)",
        OriginCenter: "Bichhiya_ShishuMndir_DPP (Madhya Pradesh)",
        FacilityCity: "Bichhiya",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8072,
        Pin: 524301,
        DispatchCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        OriginCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        FacilityCity: "Atmakur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8073,
        Pin: 535523,
        DispatchCenter: "Kurupam_MainRd_DPP (Andhra Pradesh)",
        OriginCenter: "Kurupam_MainRd_DPP (Andhra Pradesh)",
        FacilityCity: "Kurupam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8074,
        Pin: 561211,
        DispatchCenter: "Gauribidanur_Srinagara_D (Karnataka)",
        OriginCenter: "Gauribidanur_Srinagara_D (Karnataka)",
        FacilityCity: "Gauribidanur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8075,
        Pin: 627007,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8076,
        Pin: 422109,
        DispatchCenter: "Sinnar_Pimpale_D (Maharashtra)",
        OriginCenter: "Sinnar_Pimpale_D (Maharashtra)",
        FacilityCity: "Sinnar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8077,
        Pin: 793150,
        DispatchCenter: "Jowai_Ldthlabh_D (Meghalaya)",
        OriginCenter: "Jowai_Ldthlabh_D (Meghalaya)",
        FacilityCity: "Jowai",
        FacilityState: "Meghalaya",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8078,
        Pin: 248116,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8079,
        Pin: 485081,
        DispatchCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        OriginCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        FacilityCity: "Rewa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 8080,
        Pin: 396466,
        DispatchCenter: "Navsari_Vejalpore_D (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Navsari",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 8081,
        Pin: 824210,
        DispatchCenter: "Imamganj_Bisrampur_D (Bihar)",
        OriginCenter: "Imamganj_Bisrampur_D (Bihar)",
        FacilityCity: "Imamganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8082,
        Pin: 815303,
        DispatchCenter: "Giridih_Mahthadih_D (Jharkhand)",
        OriginCenter: "Giridih_Mahthadih_D (Jharkhand)",
        FacilityCity: "Giridih",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8083,
        Pin: 224143,
        DispatchCenter: "Tanda_HayatganjChowk_D (Uttar Pradesh)",
        OriginCenter: "Tanda_HayatganjChowk_D (Uttar Pradesh)",
        FacilityCity: "Tanda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8084,
        Pin: 673102,
        DispatchCenter: "Vadakara_Orkkatteri_D (Kerala)",
        OriginCenter: "Vadakara_Orkkatteri_D (Kerala)",
        FacilityCity: "Vadakara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8085,
        Pin: 471318,
        DispatchCenter: "Shahgarh_AsatiClc_DPP (Madhya Pradesh)",
        OriginCenter: "Shahgarh_AsatiClc_DPP (Madhya Pradesh)",
        FacilityCity: "Shahgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 8086,
        Pin: 571436,
        DispatchCenter: "Krishnarajpet_JayaNagar_D (Karnataka)",
        OriginCenter: "Krishnarajpet_JayaNagar_D (Karnataka)",
        FacilityCity: "Krishnarajpet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8087,
        Pin: 403603,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8088,
        Pin: 632506,
        DispatchCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ranipet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8089,
        Pin: 224153,
        DispatchCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        OriginCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        FacilityCity: "Kumarganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8090,
        Pin: 572101,
        DispatchCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        OriginCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8091,
        Pin: 852101,
        DispatchCenter: "Udakishanganj_ClgChwk_D (Bihar)",
        OriginCenter: "Udakishanganj_ClgChwk_D (Bihar)",
        FacilityCity: "Udakishanganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8092,
        Pin: 145023,
        DispatchCenter: "Pathankot_Kahanpur_H (Punjab)",
        OriginCenter: "Pathankot_Kahanpur_H (Punjab)",
        FacilityCity: "Pathankot",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8093,
        Pin: 313204,
        DispatchCenter: "Udaipur_Mavli_DPP (Rajasthan)",
        OriginCenter: "Udaipur_Mavli_DPP (Rajasthan)",
        FacilityCity: "Udaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8094,
        Pin: 583113,
        DispatchCenter: "Kurugodu_Bellary_D (Karnataka)",
        OriginCenter: "Kurugodu_Bellary_D (Karnataka)",
        FacilityCity: "Kurugodu",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8095,
        Pin: 384215,
        DispatchCenter: "Unjha_SatymComplx_D (Gujarat)",
        OriginCenter: "Unjha_SatymComplx_D (Gujarat)",
        FacilityCity: "Unjha",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 8096,
        Pin: 682304,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8097,
        Pin: 799205,
        DispatchCenter: "Teliamura_Gakulnagar_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Teliamura",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8098,
        Pin: 602002,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8099,
        Pin: 682008,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8100,
        Pin: 331305,
        DispatchCenter: "Pilani (Rajasthan)",
        OriginCenter: "Pilani (Rajasthan)",
        FacilityCity: "Pilani",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8101,
        Pin: 571811,
        DispatchCenter: "Nagamangala_TBExtension_D (Karnataka)",
        OriginCenter: "Nagamangala_TBExtension_D (Karnataka)",
        FacilityCity: "Nagamangala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8102,
        Pin: 576223,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8103,
        Pin: 212218,
        DispatchCenter: "SarayAnkil_Fkrbd_D (Uttar Pradesh)",
        OriginCenter: "SarayAnkil_Fkrbd_D (Uttar Pradesh)",
        FacilityCity: "Saray Ankil",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8104,
        Pin: 603048,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8105,
        Pin: 630004,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8106,
        Pin: 600093,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8107,
        Pin: 712139,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8108,
        Pin: 641044,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8109,
        Pin: 160049,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8110,
        Pin: 676551,
        DispatchCenter: "Tirur_Talakkad_D (Kerala)",
        OriginCenter: "Tirur_Talakkad_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8111,
        Pin: 811213,
        DispatchCenter: "Sultanganj_Tarapur_DPP (Bihar)",
        OriginCenter: "Sultanganj_Tarapur_DPP (Bihar)",
        FacilityCity: "Sultanganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8112,
        Pin: 756085,
        DispatchCenter: "Contai_Fatepur_DPP (West Bengal)",
        OriginCenter: "Contai_Fatepur_DPP (West Bengal)",
        FacilityCity: "RamnagarWB",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8113,
        Pin: 577447,
        DispatchCenter: "Shikaripur_Mandipet_D (Karnataka)",
        OriginCenter: "Shikaripur_Mandipet_D (Karnataka)",
        FacilityCity: "Shikaripur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8114,
        Pin: 831012,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8115,
        Pin: 606751,
        DispatchCenter: "Polur_Kanji_D (Tamil Nadu)",
        OriginCenter: "Polur_Kanji_D (Tamil Nadu)",
        FacilityCity: "Polur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8116,
        Pin: 621120,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8117,
        Pin: 591247,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8118,
        Pin: 462029,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8119,
        Pin: 515004,
        DispatchCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        OriginCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8120,
        Pin: 394155,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8121,
        Pin: 534006,
        DispatchCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        OriginCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        FacilityCity: "Eluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8122,
        Pin: 431517,
        DispatchCenter: "Ambajogai_Bansilalnagar_D (Maharashtra)",
        OriginCenter: "Ambajogai_Bansilalnagar_D (Maharashtra)",
        FacilityCity: "Ambajogai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8123,
        Pin: 722172,
        DispatchCenter: "Bankura_Chhatna_DPP (West Bengal)",
        OriginCenter: "Bankura_Chhatna_DPP (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8124,
        Pin: 682000,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8125,
        Pin: 828102,
        DispatchCenter: "Phusro_RhmgjDPP_D (Jharkhand)",
        OriginCenter: "Phusro_RhmgjDPP_D (Jharkhand)",
        FacilityCity: "Phusro",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8126,
        Pin: 678591,
        DispatchCenter: "Karimba_Kaliyode_D (Kerala)",
        OriginCenter: "Karimba_Kaliyode_D (Kerala)",
        FacilityCity: "Karimba",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8127,
        Pin: 636906,
        DispatchCenter: "Harur_EBOfc_D (Tamil Nadu)",
        OriginCenter: "Harur_EBOfc_D (Tamil Nadu)",
        FacilityCity: "Harur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8128,
        Pin: 509207,
        DispatchCenter: "Vikarabad_Pargi1_D (Telangana)",
        OriginCenter: "Vikarabad_Pargi1_D (Telangana)",
        FacilityCity: "Vikarabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8129,
        Pin: 756079,
        DispatchCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        OriginCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        FacilityCity: "Dantan",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8130,
        Pin: 533433,
        DispatchCenter: "Samarlakota_CHC_D (Andhra Pradesh)",
        OriginCenter: "Samarlakota_CHC_D (Andhra Pradesh)",
        FacilityCity: "Samarlakota",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8131,
        Pin: 689592,
        DispatchCenter: "Ranni_Chethakhl_D (Kerala)",
        OriginCenter: "Ranni_Chethakhl_D (Kerala)",
        FacilityCity: "Ranni",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8132,
        Pin: 224207,
        DispatchCenter: "Kumarganj_Siddhiganeshpur_D (Uttar Pradesh)",
        OriginCenter: "Kumarganj_Siddhiganeshpur_D (Uttar Pradesh)",
        FacilityCity: "Kumarganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8133,
        Pin: 517408,
        DispatchCenter: "Palamaner_Rangapuram_D (Andhra Pradesh)",
        OriginCenter: "Palamaner_Rangapuram_D (Andhra Pradesh)",
        FacilityCity: "Palamaner",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8134,
        Pin: 306703,
        DispatchCenter: "Khinwara_Nadol_DPP (Rajasthan)",
        OriginCenter: "Khinwara_Nadol_DPP (Rajasthan)",
        FacilityCity: "Khinwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8135,
        Pin: 521329,
        DispatchCenter: "Kaikaluru_Bantumilli_D (Andhra Pradesh)",
        OriginCenter: "Kaikaluru_Bantumilli_D (Andhra Pradesh)",
        FacilityCity: "Kaikaluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8136,
        Pin: 821108,
        DispatchCenter: "Kudra_Wadar_D (Bihar)",
        OriginCenter: "Kudra_Wadar_D (Bihar)",
        FacilityCity: "Kudra",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8137,
        Pin: 606903,
        DispatchCenter: "Arani_PolurRd_D (Tamil Nadu)",
        OriginCenter: "Arani_PolurRd_D (Tamil Nadu)",
        FacilityCity: "Arani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8138,
        Pin: 786121,
        DispatchCenter: "Golaghat_BaruaRd_D (Assam)",
        OriginCenter: "Golaghat_BaruaRd_D (Assam)",
        FacilityCity: "Golaghat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8139,
        Pin: 797121,
        DispatchCenter: "Kohima_Thizama_D (Nagaland)",
        OriginCenter: "Kohima_Thizama_D (Nagaland)",
        FacilityCity: "Tlabung",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8140,
        Pin: 396110,
        DispatchCenter: "Bilimora_DC (Gujarat)",
        OriginCenter: "Bilimora_DC (Gujarat)",
        FacilityCity: "Bilimora",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 8141,
        Pin: 628229,
        DispatchCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        OriginCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        FacilityCity: "Tiruchendur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8142,
        Pin: 678582,
        DispatchCenter: "Thachnttukra_Mangadn_D (Kerala)",
        OriginCenter: "Thachnttukra_Mangadn_D (Kerala)",
        FacilityCity: "Thachanattukara",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8143,
        Pin: 688543,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8144,
        Pin: 503003,
        DispatchCenter: "Nizamabad_Sarangapur_I (Telangana)",
        OriginCenter: "Nizamabad_Sarangapur_I (Telangana)",
        FacilityCity: "Nizamabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8145,
        Pin: 391135,
        DispatchCenter: "Bodeli_SatyamPlaza_DPP (Gujarat)",
        OriginCenter: "Bodeli_SatyamPlaza_DPP (Gujarat)",
        FacilityCity: "Bodeli",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8146,
        Pin: 232120,
        DispatchCenter: "Chandauli_Mughalsarai_D (Uttar Pradesh)",
        OriginCenter: "Chandauli_Mughalsarai_D (Uttar Pradesh)",
        FacilityCity: "Mughal Sarai",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8147,
        Pin: 423303,
        DispatchCenter: "Sakri_Arihantnagar_D (Maharashtra)",
        OriginCenter: "Sakri_Arihantnagar_D (Maharashtra)",
        FacilityCity: "Sakri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8148,
        Pin: 521457,
        DispatchCenter: "Jagyapet_Chillakallu_DPP (Andhra Pradesh)",
        OriginCenter: "Jagyapet_Chillakallu_DPP (Andhra Pradesh)",
        FacilityCity: "Jaggayyapet",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8149,
        Pin: 201017,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8150,
        Pin: 507166,
        DispatchCenter: "Kothagudem_Sujathanagar_D (Telangana)",
        OriginCenter: "Kothagudem_Sujathanagar_D (Telangana)",
        FacilityCity: "Kothagudem",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8151,
        Pin: 225305,
        DispatchCenter: "FatehpurUP_PatelNagar_D (Uttar Pradesh)",
        OriginCenter: "FatehpurUP_PatelNagar_D (Uttar Pradesh)",
        FacilityCity: "Fatehpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8152,
        Pin: 342005,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8153,
        Pin: 225204,
        DispatchCenter: "Barabanki_Pragyapuram_D (Uttar Pradesh)",
        OriginCenter: "Barabanki_Pragyapuram_D (Uttar Pradesh)",
        FacilityCity: "Barabanki",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8154,
        Pin: 560063,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8155,
        Pin: 614703,
        DispatchCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        FacilityCity: "Thiruthuraipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8156,
        Pin: 285127,
        DispatchCenter: "Jalaun_Madhogarh_D (Uttar Pradesh)",
        OriginCenter: "Jalaun_Madhogarh_D (Uttar Pradesh)",
        FacilityCity: "Jalaun",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8157,
        Pin: 686586,
        DispatchCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        OriginCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        FacilityCity: "Thodupuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8158,
        Pin: 481995,
        DispatchCenter: "Bichhiya_ShishuMndir_DPP (Madhya Pradesh)",
        OriginCenter: "Bichhiya_ShishuMndir_DPP (Madhya Pradesh)",
        FacilityCity: "Bichhiya",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8159,
        Pin: 231208,
        DispatchCenter: "Renukoot_Murdhava_H (Uttar Pradesh)",
        OriginCenter: "Renukoot_Murdhava_H (Uttar Pradesh)",
        FacilityCity: "Renukoot",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8160,
        Pin: 148007,
        DispatchCenter: "Sangrur_DC (Punjab)",
        OriginCenter: "Sangrur_DC (Punjab)",
        FacilityCity: "Sangrur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8161,
        Pin: 229417,
        DispatchCenter: "Kunda_KaretiRd_D (Uttar Pradesh)",
        OriginCenter: "Kunda_KaretiRd_D (Uttar Pradesh)",
        FacilityCity: "Kunda",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8162,
        Pin: 848135,
        DispatchCenter: "Samastipur_RhmprRdli_D (Bihar)",
        OriginCenter: "Samastipur_RhmprRdli_D (Bihar)",
        FacilityCity: "Samastipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8163,
        Pin: 246472,
        DispatchCenter: "Chamoli_CGRoad_D (Uttarakhand)",
        OriginCenter: "Chamoli_CGRoad_D (Uttarakhand)",
        FacilityCity: "Chamoli",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8164,
        Pin: 364265,
        DispatchCenter: "Palitana_Jivapur_DPP (Gujarat)",
        OriginCenter: "Palitana_Jivapur_DPP (Gujarat)",
        FacilityCity: "Palitana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8165,
        Pin: 443105,
        DispatchCenter: "Buldhana_Sanenagar_DPP (Maharashtra)",
        OriginCenter: "Buldhana_Sanenagar_DPP (Maharashtra)",
        FacilityCity: "Buldhana",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8166,
        Pin: 682024,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8167,
        Pin: 301713,
        DispatchCenter: "Narnaul_Keshavnagar_D (Haryana)",
        OriginCenter: "Narnaul_Keshavnagar_D (Haryana)",
        FacilityCity: "Narnaul",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8168,
        Pin: 281004,
        DispatchCenter: "Mathura_Palikheda_D (Uttar Pradesh)",
        OriginCenter: "Mathura_Palikheda_D (Uttar Pradesh)",
        FacilityCity: "Mathura",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8169,
        Pin: 802223,
        DispatchCenter: "Sandesh_Agiaon_D (Bihar)",
        OriginCenter: "Sandesh_Agiaon_D (Bihar)",
        FacilityCity: "Sandesh",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8170,
        Pin: 425405,
        DispatchCenter: "Shirpur_KrantiNgr_D (Maharashtra)",
        OriginCenter: "Shirpur_KrantiNgr_D (Maharashtra)",
        FacilityCity: "Shirpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8171,
        Pin: 410401,
        DispatchCenter: "Lonavala_NngrgnRd_D (Maharashtra)",
        OriginCenter: "Lonavala_NngrgnRd_D (Maharashtra)",
        FacilityCity: "Lonavala",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8172,
        Pin: 574109,
        DispatchCenter: "Eedu_Mallanja_DPP (Karnataka)",
        OriginCenter: "Eedu_Mallanja_DPP (Karnataka)",
        FacilityCity: "Eedu",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8173,
        Pin: 606107,
        DispatchCenter: "Ulundurpet_HarishNgr_D (Tamil Nadu)",
        OriginCenter: "Ulundurpet_HarishNgr_D (Tamil Nadu)",
        FacilityCity: "Ulundurpet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8174,
        Pin: 673521,
        DispatchCenter: "Koyilandy_Kizhur_D (Kerala)",
        OriginCenter: "Koyilandy_Kizhur_D (Kerala)",
        FacilityCity: "Koyilandy",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8175,
        Pin: 396150,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 8176,
        Pin: 759119,
        DispatchCenter: "Pallahara_Patabeda_D (Orissa)",
        OriginCenter: "Pallahara_Patabeda_D (Orissa)",
        FacilityCity: "Pallahara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8177,
        Pin: 600081,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8178,
        Pin: 211022,
        DispatchCenter: "Soraon_Purabtola_D (Uttar Pradesh)",
        OriginCenter: "Soraon_Purabtola_D (Uttar Pradesh)",
        FacilityCity: "Soraon",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8179,
        Pin: 673028,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8180,
        Pin: 793105,
        DispatchCenter: "Shillong_Umiam_D (Meghalaya)",
        OriginCenter: "Shillong_Umiam_D (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8181,
        Pin: 756036,
        DispatchCenter: "Contai_Fatepur_DPP (West Bengal)",
        OriginCenter: "Contai_Fatepur_DPP (West Bengal)",
        FacilityCity: "RamnagarWB",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8182,
        Pin: 206250,
        DispatchCenter: "Bidhuna_BharthnRd_D (Uttar Pradesh)",
        OriginCenter: "Bidhuna_BharthnRd_D (Uttar Pradesh)",
        FacilityCity: "Bidhuna",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8183,
        Pin: 140601,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8184,
        Pin: 571105,
        DispatchCenter: "Hunsur_IndustrialEstate_D (Karnataka)",
        OriginCenter: "Hunsur_IndustrialEstate_D (Karnataka)",
        FacilityCity: "Hunsur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8185,
        Pin: 143502,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8186,
        Pin: 444105,
        DispatchCenter: "Karanja_PrshantNgr_DPP (Maharashtra)",
        OriginCenter: "Karanja_PrshantNgr_DPP (Maharashtra)",
        FacilityCity: "Karanja",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8187,
        Pin: 753141,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8188,
        Pin: 520003,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8189,
        Pin: 421504,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8190,
        Pin: 584104,
        DispatchCenter: "Raichur_Wadloor_I (Karnataka)",
        OriginCenter: "Raichur_Wadloor_I (Karnataka)",
        FacilityCity: "Raichur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8191,
        Pin: 148019,
        DispatchCenter: "Malerkotla_Sajidacolony_D (Punjab)",
        OriginCenter: "Malerkotla_Sajidacolony_D (Punjab)",
        FacilityCity: "Malerkotla",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8192,
        Pin: 678704,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8193,
        Pin: 230140,
        DispatchCenter: "LalganjAjhara_HPPump_D (Uttar Pradesh)",
        OriginCenter: "LalganjAjhara_HPPump_D (Uttar Pradesh)",
        FacilityCity: "Lalganj Ajhara",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8194,
        Pin: 533238,
        DispatchCenter: "Mandapeta_Ravulapalem_DPP (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Ravulapalem_DPP (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8195,
        Pin: 416311,
        DispatchCenter: "KMahankal_KM_D (Maharashtra)",
        OriginCenter: "KMahankal_KM_D (Maharashtra)",
        FacilityCity: "Kavathe Mahankal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8196,
        Pin: 127306,
        DispatchCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        OriginCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        FacilityCity: "Charkhi Dadri",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8197,
        Pin: 827011,
        DispatchCenter: "Bokaro_Chas_D (Jharkhand)",
        OriginCenter: "Bokaro_Chas_D (Jharkhand)",
        FacilityCity: "Bokaro",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8198,
        Pin: 670015,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8199,
        Pin: 626137,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8200,
        Pin: 502108,
        DispatchCenter: "Siddipet_Repallewada_DPP (Telangana)",
        OriginCenter: "Siddipet_Repallewada_DPP (Telangana)",
        FacilityCity: "Siddipet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8201,
        Pin: 679536,
        DispatchCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        OriginCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        FacilityCity: "Sankaramangalam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8202,
        Pin: 635201,
        DispatchCenter: "Uthangarai_Mathurpathi_D (Tamil Nadu)",
        OriginCenter: "Uthangarai_Mathurpathi_D (Tamil Nadu)",
        FacilityCity: "Uthangarai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8203,
        Pin: 248147,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8204,
        Pin: 224129,
        DispatchCenter: "Tanda_HayatganjChowk_D (Uttar Pradesh)",
        OriginCenter: "Tanda_HayatganjChowk_D (Uttar Pradesh)",
        FacilityCity: "Tanda",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8205,
        Pin: 425108,
        DispatchCenter: "Chopda_CollegeRd_DPP (Maharashtra)",
        OriginCenter: "Chopda_CollegeRd_DPP (Maharashtra)",
        FacilityCity: "Chopda",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8206,
        Pin: 391530,
        DispatchCenter: "Vadodara_Savli_DPP (Gujarat)",
        OriginCenter: "Vadodara_Savli_DPP (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8207,
        Pin: 755009,
        DispatchCenter: "Chandipur_Keshpur_D (Orissa)",
        OriginCenter: "Chandipur_Keshpur_D (Orissa)",
        FacilityCity: "Chandipur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8208,
        Pin: 532264,
        DispatchCenter: "Sompeta_PostOffice_DPP (Andhra Pradesh)",
        OriginCenter: "Sompeta_PostOffice_DPP (Andhra Pradesh)",
        FacilityCity: "Sompeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8209,
        Pin: 753003,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8210,
        Pin: 673105,
        DispatchCenter: "Vadakara_Veerancheri_D (Kerala)",
        OriginCenter: "Vadakara_Veerancheri_D (Kerala)",
        FacilityCity: "Vadakara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8211,
        Pin: 680641,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8212,
        Pin: 636012,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8213,
        Pin: 394517,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8214,
        Pin: 691012,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8215,
        Pin: 123411,
        DispatchCenter: "Rewari_Kosli_D (Haryana)",
        OriginCenter: "Rewari_Kosli_D (Haryana)",
        FacilityCity: "Jhajjar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8216,
        Pin: 854337,
        DispatchCenter: "Purnia_Fatehpur_D (Bihar)",
        OriginCenter: "Purnia_Fatehpur_D (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8217,
        Pin: 609404,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8218,
        Pin: 524004,
        DispatchCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        OriginCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8219,
        Pin: 311402,
        DispatchCenter: "Gangapur_Raipur_DPP (Rajasthan)",
        OriginCenter: "Gangapur_Raipur_DPP (Rajasthan)",
        FacilityCity: "Gangapur-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8220,
        Pin: 741256,
        DispatchCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        OriginCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        FacilityCity: "Ranaghat",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8221,
        Pin: 251203,
        DispatchCenter: "Khatauli_GTRoad_D (Uttar Pradesh)",
        OriginCenter: "Khatauli_GTRoad_D (Uttar Pradesh)",
        FacilityCity: "Khatauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8222,
        Pin: 227304,
        DispatchCenter: "Lambhua_Chaukiya_D (Uttar Pradesh)",
        OriginCenter: "Lambhua_Chaukiya_D (Uttar Pradesh)",
        FacilityCity: "Lambhua",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8223,
        Pin: 227805,
        DispatchCenter: "Sultanpur_Balpur_D (Uttar Pradesh)",
        OriginCenter: "Sultanpur_Balpur_D (Uttar Pradesh)",
        FacilityCity: "Sultanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8224,
        Pin: 462036,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8225,
        Pin: 507209,
        DispatchCenter: "Wyra_Thallada_D (Telangana)",
        OriginCenter: "Wyra_Thallada_D (Telangana)",
        FacilityCity: "Wyra",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8226,
        Pin: 176033,
        DispatchCenter: "NagrotaSurian_Saknra_DPP (Himachal Pradesh)",
        OriginCenter: "NagrotaSurian_Saknra_DPP (Himachal Pradesh)",
        FacilityCity: "Nagrota Surian",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8227,
        Pin: 415313,
        DispatchCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        OriginCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        FacilityCity: "Islampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8228,
        Pin: 331306,
        DispatchCenter: "Pilani (Rajasthan)",
        OriginCenter: "Pilani (Rajasthan)",
        FacilityCity: "Pilani",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8229,
        Pin: 534437,
        DispatchCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        OriginCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        FacilityCity: "Eluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8230,
        Pin: 303304,
        DispatchCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        OriginCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        FacilityCity: "Bandikui",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8231,
        Pin: 626001,
        DispatchCenter: "Virudhunagar_Allampatti_DPP (Tamil Nadu)",
        OriginCenter: "Virudhunagar_Allampatti_DPP (Tamil Nadu)",
        FacilityCity: "Virudhunagar",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8232,
        Pin: 630212,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8233,
        Pin: 625019,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8234,
        Pin: 232326,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8235,
        Pin: 700084,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8236,
        Pin: 422103,
        DispatchCenter: "Sinnar_Pimpale_D (Maharashtra)",
        OriginCenter: "Sinnar_Pimpale_D (Maharashtra)",
        FacilityCity: "Sinnar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8237,
        Pin: 636903,
        DispatchCenter: "Harur_EBOfc_D (Tamil Nadu)",
        OriginCenter: "Harur_EBOfc_D (Tamil Nadu)",
        FacilityCity: "Harur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8238,
        Pin: 752037,
        DispatchCenter: "Balugaon_Oldasianhighwy_D (Orissa)",
        OriginCenter: "Balugaon_Oldasianhighwy_D (Orissa)",
        FacilityCity: "Balugaon",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8239,
        Pin: 491665,
        DispatchCenter: "AmbagarhChowki_Ward14_D (Chhattisgarh)",
        OriginCenter: "AmbagarhChowki_Ward14_D (Chhattisgarh)",
        FacilityCity: "AmbagarhChowki",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8240,
        Pin: 506318,
        DispatchCenter: "Thirumalagiri_MaliPuramRoad_D (Telangana)",
        OriginCenter: "Thirumalagiri_MaliPuramRoad_D (Telangana)",
        FacilityCity: "Thirumalagiri",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8241,
        Pin: 332043,
        DispatchCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        OriginCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8242,
        Pin: 506366,
        DispatchCenter: "Mogullapally_ArrmCmplx_DPP (Telangana)",
        OriginCenter: "Mogullapally_ArrmCmplx_DPP (Telangana)",
        FacilityCity: "Mogullapally",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8243,
        Pin: 202130,
        DispatchCenter: "Aligarh_Atrauli_D (Uttar Pradesh)",
        OriginCenter: "Aligarh_Atrauli_D (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8244,
        Pin: 848226,
        DispatchCenter: "Buxar_SohaniPatti_D (Bihar)",
        OriginCenter: "Buxar_SohaniPatti_D (Bihar)",
        FacilityCity: "Buxar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8245,
        Pin: 686024,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8246,
        Pin: 826007,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8247,
        Pin: 506010,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8248,
        Pin: 781011,
        DispatchCenter: "Guwahati_Kaikchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8249,
        Pin: 181008,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8250,
        Pin: 174024,
        DispatchCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        OriginCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        FacilityCity: "Bilaspur-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8251,
        Pin: 804428,
        DispatchCenter: "Arwal_SipahPnchayt_D (Bihar)",
        OriginCenter: "Arwal_SipahPnchayt_D (Bihar)",
        FacilityCity: "Arwal",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8252,
        Pin: 533254,
        DispatchCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        OriginCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        FacilityCity: "Razole",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8253,
        Pin: 246474,
        DispatchCenter: "Chamoli_Karnaprayag_D (Uttarakhand)",
        OriginCenter: "Chamoli_Karnaprayag_D (Uttarakhand)",
        FacilityCity: "Chamoli",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8254,
        Pin: 642111,
        DispatchCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        OriginCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        FacilityCity: "Udumalaipettai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8255,
        Pin: 273015,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8256,
        Pin: 577146,
        DispatchCenter: "Belur_Halebeedu_D (Karnataka)",
        OriginCenter: "Belur_Halebeedu_D (Karnataka)",
        FacilityCity: "Belur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8257,
        Pin: 500594,
        DispatchCenter: "Hyderabad_HemaNagar_L (Telangana)",
        OriginCenter: "Hyderabad_HemaNagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8258,
        Pin: 781364,
        DispatchCenter: "Udalguri_Milonpur_D (Assam)",
        OriginCenter: "Udalguri_Milonpur_D (Assam)",
        FacilityCity: "Mangaldoi",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8259,
        Pin: 571478,
        DispatchCenter: "Mandya_Dc (Karnataka)",
        OriginCenter: "Mandya_Dc (Karnataka)",
        FacilityCity: "Mandya",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8260,
        Pin: 591104,
        DispatchCenter: "Bailhongal_Azadnagar_D (Karnataka)",
        OriginCenter: "Bailhongal_Azadnagar_D (Karnataka)",
        FacilityCity: "Bailhongal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8261,
        Pin: 227308,
        DispatchCenter: "Lucknow_CanaraBank_D (Uttar Pradesh)",
        OriginCenter: "Lucknow_CanaraBank_D (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8262,
        Pin: 636808,
        DispatchCenter: "Palacode_Dandukaranahalli_D (Tamil Nadu)",
        OriginCenter: "Palacode_Dandukaranahalli_D (Tamil Nadu)",
        FacilityCity: "Palacode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8263,
        Pin: 814151,
        DispatchCenter: "Dumka_Hansdiha1_D (Jharkhand)",
        OriginCenter: "Dumka_Hansdiha1_D (Jharkhand)",
        FacilityCity: "Dumka",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8264,
        Pin: 585259,
        DispatchCenter: "Aurad_CBColony_D (Karnataka)",
        OriginCenter: "Aurad_CBColony_D (Karnataka)",
        FacilityCity: "Aurad",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8265,
        Pin: 583221,
        DispatchCenter: "Hospet_Vijayanagara_D (Karnataka)",
        OriginCenter: "Hospet_Vijayanagara_D (Karnataka)",
        FacilityCity: "Hospet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8266,
        Pin: 245405,
        DispatchCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        OriginCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        FacilityCity: "Siyana",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8267,
        Pin: 630204,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8268,
        Pin: 586123,
        DispatchCenter: "Sindagi_Bijapurrd_D (Karnataka)",
        OriginCenter: "Sindagi_Bijapurrd_D (Karnataka)",
        FacilityCity: "Sindagi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8269,
        Pin: 383006,
        DispatchCenter: "Himmatnagar_Hadiyol_D (Gujarat)",
        OriginCenter: "Himmatnagar_Hadiyol_D (Gujarat)",
        FacilityCity: "Himmatnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 8270,
        Pin: 212662,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8271,
        Pin: 602117,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8272,
        Pin: 533235,
        DispatchCenter: "Mandapeta_Ravulapalem_DPP (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Ravulapalem_DPP (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8273,
        Pin: 576231,
        DispatchCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        OriginCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8274,
        Pin: 845438,
        DispatchCenter: "Bettiah_Manshatola_D (Bihar)",
        OriginCenter: "Bettiah_Manshatola_D (Bihar)",
        FacilityCity: "Bettiah",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8275,
        Pin: 229126,
        DispatchCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        OriginCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        FacilityCity: "Haidargarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8276,
        Pin: 629707,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8277,
        Pin: 712248,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8278,
        Pin: 630312,
        DispatchCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        OriginCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        FacilityCity: "Thiruvadanai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8279,
        Pin: 174303,
        DispatchCenter: "UnaHP_NangalRd_D (Himachal Pradesh)",
        OriginCenter: "UnaHP_NangalRd_D (Himachal Pradesh)",
        FacilityCity: "Una-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8280,
        Pin: 731214,
        DispatchCenter: "Dubrajpur_Mansayer_D (West Bengal)",
        OriginCenter: "Dubrajpur_Mansayer_D (West Bengal)",
        FacilityCity: "Dubrajpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8281,
        Pin: 494010,
        DispatchCenter: "Bastar_Rajendranagar_D (Chhattisgarh)",
        OriginCenter: "Bastar_Rajendranagar_D (Chhattisgarh)",
        FacilityCity: "Bastar",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8282,
        Pin: 110089,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8283,
        Pin: 854109,
        DispatchCenter: "Purnia_Kasba_H (Bihar)",
        OriginCenter: "Purnia_Kasba_H (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8284,
        Pin: 534247,
        DispatchCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        FacilityCity: "Bhimavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8285,
        Pin: 678702,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8286,
        Pin: 242005,
        DispatchCenter: "Shahjahanpur_AvasVikas_D (Uttar Pradesh)",
        OriginCenter: "Shahjahanpur_AvasVikas_D (Uttar Pradesh)",
        FacilityCity: "Shahjahanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8287,
        Pin: 497111,
        DispatchCenter: "Pathalgaon_Kargilchowk_D (Chhattisgarh)",
        OriginCenter: "Pathalgaon_Kargilchowk_D (Chhattisgarh)",
        FacilityCity: "Pathalgaon",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8288,
        Pin: 403204,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8289,
        Pin: 742122,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8290,
        Pin: 302004,
        DispatchCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8291,
        Pin: 631213,
        DispatchCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8292,
        Pin: 390003,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8293,
        Pin: 638313,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8294,
        Pin: 415710,
        DispatchCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        OriginCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        FacilityCity: "Khed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8295,
        Pin: 305926,
        DispatchCenter: "Sojat_RendriCircle_DPP (Rajasthan)",
        OriginCenter: "Sojat_RendriCircle_DPP (Rajasthan)",
        FacilityCity: "Sojat",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8296,
        Pin: 570016,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8297,
        Pin: 802301,
        DispatchCenter: "Arrah_Anaith_D (Bihar)",
        OriginCenter: "Arrah_Anaith_D (Bihar)",
        FacilityCity: "Arrah",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8298,
        Pin: 824127,
        DispatchCenter: "Daudnagar_Tarar_D (Bihar)",
        OriginCenter: "Daudnagar_Tarar_D (Bihar)",
        FacilityCity: "Daudnagar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8299,
        Pin: 643234,
        DispatchCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        OriginCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8300,
        Pin: 844103,
        DispatchCenter: "Hajipur_Belkunda_D (Bihar)",
        OriginCenter: "Hajipur_Belkunda_D (Bihar)",
        FacilityCity: "Hajipur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8301,
        Pin: 572125,
        DispatchCenter: "Sira_Hanumanthnagara_D (Karnataka)",
        OriginCenter: "Sira_Hanumanthnagara_D (Karnataka)",
        FacilityCity: "Sira",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8302,
        Pin: 521227,
        DispatchCenter: "Vissannapetaa_TiruvuruRd_D (Andhra Pradesh)",
        OriginCenter: "Vissannapetaa_TiruvuruRd_D (Andhra Pradesh)",
        FacilityCity: "Vissannapetaa",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8303,
        Pin: 160002,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8304,
        Pin: 227806,
        DispatchCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        OriginCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        FacilityCity: "Kumarganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8305,
        Pin: 495691,
        DispatchCenter: "Dabhra_Thangan_D (Chhattisgarh)",
        OriginCenter: "Dabhra_Thangan_D (Chhattisgarh)",
        FacilityCity: "Dabhra",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8306,
        Pin: 628904,
        DispatchCenter: "Vilathikulam_Thoothukudi_DPP (Tamil Nadu)",
        OriginCenter: "Vilathikulam_Thoothukudi_DPP (Tamil Nadu)",
        FacilityCity: "Vilathikulam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8307,
        Pin: 626133,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8308,
        Pin: 440031,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8309,
        Pin: 636807,
        DispatchCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        OriginCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        FacilityCity: "Dharmapuri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8310,
        Pin: 272181,
        DispatchCenter: "Itava_Prsnga_D (Uttar Pradesh)",
        OriginCenter: "Itava_Prsnga_D (Uttar Pradesh)",
        FacilityCity: "Itava",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8311,
        Pin: 855107,
        DispatchCenter: "Kishanganj_Subhaspally_D (Bihar)",
        OriginCenter: "Kishanganj_Subhaspally_D (Bihar)",
        FacilityCity: "Kishanganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8312,
        Pin: 764048,
        DispatchCenter: "Malkangiri_PatroGuda_DPP (Orissa)",
        OriginCenter: "Malkangiri_PatroGuda_DPP (Orissa)",
        FacilityCity: "Malkangiri",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8313,
        Pin: 403530,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8314,
        Pin: 396235,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 8315,
        Pin: 713409,
        DispatchCenter: "Kalna_BsStdDPP_D (West Bengal)",
        OriginCenter: "Kalna_BsStdDPP_D (West Bengal)",
        FacilityCity: "Kalna",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8316,
        Pin: 781308,
        DispatchCenter: "Barpeta_Bhera_D (Assam)",
        OriginCenter: "Barpeta_ShaktiNagar_C (Assam)",
        FacilityCity: "Barpeta",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8317,
        Pin: 804453,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8318,
        Pin: 743195,
        DispatchCenter: "Naihati_Kalyani_DPP (West Bengal)",
        OriginCenter: "Naihati_Kalyani_DPP (West Bengal)",
        FacilityCity: "Kalyani",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8319,
        Pin: 391770,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8320,
        Pin: 394720,
        DispatchCenter: "Ahwa_Panchlicolony_DPP (Gujarat)",
        OriginCenter: "Ahwa_Panchlicolony_DPP (Gujarat)",
        FacilityCity: "Ahwa",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8321,
        Pin: 431508,
        DispatchCenter: "Mantha_Jintur_DPP (Maharashtra)",
        OriginCenter: "Mantha_Jintur_DPP (Maharashtra)",
        FacilityCity: "Mantha",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8322,
        Pin: 245401,
        DispatchCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        OriginCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        FacilityCity: "Siyana",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8323,
        Pin: 382013,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8324,
        Pin: 581196,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8325,
        Pin: 246001,
        DispatchCenter: "Pauri_Khndyusn_D (Uttarakhand)",
        OriginCenter: "Pauri_Khndyusn_D (Uttarakhand)",
        FacilityCity: "Pauri",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8326,
        Pin: 722153,
        DispatchCenter: "Meija_School_D (West Bengal)",
        OriginCenter: "Meija_School_D (West Bengal)",
        FacilityCity: "Mejia",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8327,
        Pin: 221717,
        DispatchCenter: "Ballia_Sikandarpur_D (Uttar Pradesh)",
        OriginCenter: "Ballia_Sikandarpur_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8328,
        Pin: 788101,
        DispatchCenter: "Pailapool_Lagktlbocpr_D (Assam)",
        OriginCenter: "Pailapool_Lagktlbocpr_D (Assam)",
        FacilityCity: "Pailapool",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8329,
        Pin: 425101,
        DispatchCenter: "Jalgaon_MIDC_I (Maharashtra)",
        OriginCenter: "Jalgaon_MIDC_I (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8330,
        Pin: 504304,
        DispatchCenter: "BoathBuzurg_Sainagar_DPP (Telangana)",
        OriginCenter: "BoathBuzurg_Sainagar_DPP (Telangana)",
        FacilityCity: "Boath Buzurg",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8331,
        Pin: 451447,
        DispatchCenter: "Barwani_IGcolony_D (Madhya Pradesh)",
        OriginCenter: "Barwani_IGcolony_D (Madhya Pradesh)",
        FacilityCity: "Barwani",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 8332,
        Pin: 344027,
        DispatchCenter: "Balotra_Ward45_D (Rajasthan)",
        OriginCenter: "Balotra_Ward45_D (Rajasthan)",
        FacilityCity: "Balotra",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8333,
        Pin: 271304,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8334,
        Pin: 629169,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8335,
        Pin: 700001,
        DispatchCenter: "Kolkata_Bagmari_L (West Bengal)",
        OriginCenter: "Kolkata_Bagmari_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8336,
        Pin: 591265,
        DispatchCenter: "Bijapur_Tikota1_DPP (Karnataka)",
        OriginCenter: "Bijapur_Tikota1_DPP (Karnataka)",
        FacilityCity: "Bijapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8337,
        Pin: 604407,
        DispatchCenter: "Cheyyar_NehruNGR_D (Tamil Nadu)",
        OriginCenter: "Cheyyar_NehruNGR_D (Tamil Nadu)",
        FacilityCity: "Cheyyar",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8338,
        Pin: 581303,
        DispatchCenter: "Karwar_Sadashivgad_D (Karnataka)",
        OriginCenter: "Karwar_Sadashivgad_D (Karnataka)",
        FacilityCity: "Karwar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8339,
        Pin: 440014,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8340,
        Pin: 680013,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8341,
        Pin: 673620,
        DispatchCenter: "Koyilandy_Komathukara_D (Kerala)",
        OriginCenter: "Koyilandy_Komathukara_D (Kerala)",
        FacilityCity: "Koyilandy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8342,
        Pin: 274509,
        DispatchCenter: "Salempur_Banjaria_D (Uttar Pradesh)",
        OriginCenter: "Salempur_Banjaria_D (Uttar Pradesh)",
        FacilityCity: "Salempur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8343,
        Pin: 486890,
        DispatchCenter: "Singrauli_Waidhan2_D (Madhya Pradesh)",
        OriginCenter: "Singrauli_Waidhan2_D (Madhya Pradesh)",
        FacilityCity: "Singrauli",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8344,
        Pin: 515286,
        DispatchCenter: "Amarapuram_YSRCircle_D (Andhra Pradesh)",
        OriginCenter: "Amarapuram_YSRCircle_D (Andhra Pradesh)",
        FacilityCity: "Amarapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8345,
        Pin: 753001,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8346,
        Pin: 712223,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8347,
        Pin: 695004,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8348,
        Pin: 431144,
        DispatchCenter: "Manjlegaon_Telgaon_DPP (Maharashtra)",
        OriginCenter: "Manjlegaon_Telgaon_DPP (Maharashtra)",
        FacilityCity: "Manjlegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8349,
        Pin: 802218,
        DispatchCenter: "Dinara_Wardno7_D (Bihar)",
        OriginCenter: "Dinara_Wardno7_D (Bihar)",
        FacilityCity: "Dinara",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8350,
        Pin: 243751,
        DispatchCenter: "Babrala_Teacherscolony_D (Uttar Pradesh)",
        OriginCenter: "Babrala_Teacherscolony_D (Uttar Pradesh)",
        FacilityCity: "Babrala",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8351,
        Pin: 574166,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8352,
        Pin: 711315,
        DispatchCenter: "Howrah_Temp_L (West Bengal)",
        OriginCenter: "Howrah_Temp_L (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8353,
        Pin: 110036,
        DispatchCenter: "Delhi_Libaspur_L (Delhi)",
        OriginCenter: "Delhi_Libaspur_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8354,
        Pin: 577501,
        DispatchCenter: "Chitradurga_Basaveshwar_D (Karnataka)",
        OriginCenter: "Chitradurga_Basaveshwar_D (Karnataka)",
        FacilityCity: "Chitradurga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8355,
        Pin: 627430,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8356,
        Pin: 465683,
        DispatchCenter: "Pachore_Bhojpuriya_D (Madhya Pradesh)",
        OriginCenter: "Pachore_Bhojpuriya_D (Madhya Pradesh)",
        FacilityCity: "Pachore",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 8357,
        Pin: 271841,
        DispatchCenter: "Nighasan_Laudhri_D (Uttar Pradesh)",
        OriginCenter: "Nighasan_Laudhri_D (Uttar Pradesh)",
        FacilityCity: "Nighasan",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8358,
        Pin: 244502,
        DispatchCenter: "Kanth_ModaPatti_DPP (Uttar Pradesh)",
        OriginCenter: "Kanth_ModaPatti_DPP (Uttar Pradesh)",
        FacilityCity: "Kanth",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8359,
        Pin: 144804,
        DispatchCenter: "Tarsikka_Beas_D (Punjab)",
        OriginCenter: "Tarsikka_Beas_D (Punjab)",
        FacilityCity: "Beas",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8360,
        Pin: 160037,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8361,
        Pin: 313024,
        DispatchCenter: "Udaipur_Balicha_H (Rajasthan)",
        OriginCenter: "Udaipur_Balicha_H (Rajasthan)",
        FacilityCity: "Udaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8362,
        Pin: 583137,
        DispatchCenter: "Harpanahalli_KHBClny_D (Karnataka)",
        OriginCenter: "Harpanahalli_KHBClny_D (Karnataka)",
        FacilityCity: "Harpanahalli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8363,
        Pin: 758040,
        DispatchCenter: "Barbil_Rungtacolony_D (Orissa)",
        OriginCenter: "Barbil_Rungtacolony_D (Orissa)",
        FacilityCity: "Barbil",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8364,
        Pin: 208023,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8365,
        Pin: 203389,
        DispatchCenter: "Dibai_Galibpur_D (Uttar Pradesh)",
        OriginCenter: "Dibai_Galibpur_D (Uttar Pradesh)",
        FacilityCity: "Dibai",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8366,
        Pin: 587117,
        DispatchCenter: "Mudhol_Maharancol_D (Karnataka)",
        OriginCenter: "Mudhol_Maharancol_D (Karnataka)",
        FacilityCity: "Mudhol",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8367,
        Pin: 160036,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8368,
        Pin: 110608,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8369,
        Pin: 400017,
        DispatchCenter: "Mumbai_Sewri_L (Maharashtra)",
        OriginCenter: "Mumbai_Sewri_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8370,
        Pin: 132101,
        DispatchCenter: "Panipat_Samalkha_D (Haryana)",
        OriginCenter: "Panipat_Samalkha_D (Haryana)",
        FacilityCity: "Panipat",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8371,
        Pin: 430006,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8372,
        Pin: 841418,
        DispatchCenter: "Chhapra_Ramnagar_I (Bihar)",
        OriginCenter: "Chhapra_Ramnagar_I (Bihar)",
        FacilityCity: "Chhapra",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8373,
        Pin: 184148,
        DispatchCenter: "Hiranagar_NH44_D (Jammu & Kashmir)",
        OriginCenter: "Hiranagar_NH44_D (Jammu & Kashmir)",
        FacilityCity: "Hiranagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8374,
        Pin: 833222,
        DispatchCenter: "Barbil_Rungtacolony_D (Orissa)",
        OriginCenter: "Barbil_Rungtacolony_D (Orissa)",
        FacilityCity: "Barbil",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8375,
        Pin: 673595,
        DispatchCenter: "SultanBathery_Kolagapara_D (Kerala)",
        OriginCenter: "SultanBathery_Kolagapara_D (Kerala)",
        FacilityCity: "Sultan Bathery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8376,
        Pin: 203002,
        DispatchCenter: "Bulndshahr_Ymunpurm_D (Uttar Pradesh)",
        OriginCenter: "Bulndshahr_Ymunpurm_D (Uttar Pradesh)",
        FacilityCity: "Bulandshahr",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8377,
        Pin: 362720,
        DispatchCenter: "Kodinar_NCplxDPP_D (Gujarat)",
        OriginCenter: "Kodinar_NCplxDPP_D (Gujarat)",
        FacilityCity: "Kodinar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8378,
        Pin: 586129,
        DispatchCenter: "Muddebihal_Hudcocolony_D (Karnataka)",
        OriginCenter: "Muddebihal_Hudcocolony_D (Karnataka)",
        FacilityCity: "Muddebihal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8379,
        Pin: 509410,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8380,
        Pin: 637213,
        DispatchCenter: "Velur_ChakraNgr_DPP (Tamil Nadu)",
        OriginCenter: "Velur_ChakraNgr_DPP (Tamil Nadu)",
        FacilityCity: "Velur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8381,
        Pin: 233225,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8382,
        Pin: 250403,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8383,
        Pin: 785692,
        DispatchCenter: "Sonari_Nakachari_D (Assam)",
        OriginCenter: "Sonari_Nakachari_D (Assam)",
        FacilityCity: "Sonari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8384,
        Pin: 144628,
        DispatchCenter: "SultanpurLodhi_Gill_D (Punjab)",
        OriginCenter: "SultanpurLodhi_Gill_D (Punjab)",
        FacilityCity: "Sultanpur Lodhi",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8385,
        Pin: 393025,
        DispatchCenter: "Dediapada_Sagbara_DPP (Gujarat)",
        OriginCenter: "Dediapada_Sagbara_DPP (Gujarat)",
        FacilityCity: "Dediapada",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8386,
        Pin: 521131,
        DispatchCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        OriginCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        FacilityCity: "Machilipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8387,
        Pin: 262202,
        DispatchCenter: "Bisalpur_Patelnagar_D (Uttar Pradesh)",
        OriginCenter: "Bisalpur_Patelnagar_D (Uttar Pradesh)",
        FacilityCity: "Bisalpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8388,
        Pin: 721155,
        DispatchCenter: "Lutunia_Bholenthtmpl_D (West Bengal)",
        OriginCenter: "Lutunia_Bholenthtmpl_D (West Bengal)",
        FacilityCity: "Lutunia",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8389,
        Pin: 753014,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8390,
        Pin: 829209,
        DispatchCenter: "Khalari_NayaDhauda_D (Jharkhand)",
        OriginCenter: "Khalari_NayaDhauda_D (Jharkhand)",
        FacilityCity: "Khalari-JR",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8391,
        Pin: 638673,
        DispatchCenter: "Dharapuram_Pirivu_D (Tamil Nadu)",
        OriginCenter: "Dharapuram_Pirivu_D (Tamil Nadu)",
        FacilityCity: "Dharapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8392,
        Pin: 723102,
        DispatchCenter: "Purulia_GariDPP_D (West Bengal)",
        OriginCenter: "Purulia_GariDPP_D (West Bengal)",
        FacilityCity: "Purulia",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8393,
        Pin: 431512,
        DispatchCenter: "Vasmat_Khajipura_D (Maharashtra)",
        OriginCenter: "Vasmat_Khajipura_D (Maharashtra)",
        FacilityCity: "Vasmat",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8394,
        Pin: 421304,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8395,
        Pin: 686511,
        DispatchCenter: "Ranni_Chethakhl_D (Kerala)",
        OriginCenter: "Ranni_Chethakhl_D (Kerala)",
        FacilityCity: "Ranni",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8396,
        Pin: 632520,
        DispatchCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ranipet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8397,
        Pin: 758013,
        DispatchCenter: "Kendujhar_Jamunalia_I (Orissa)",
        OriginCenter: "Kendujhar_Jamunalia_I (Orissa)",
        FacilityCity: "Kendujhar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8398,
        Pin: 753006,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8399,
        Pin: 516217,
        DispatchCenter: "Badvel_CCcolony_D (Andhra Pradesh)",
        OriginCenter: "Badvel_CCcolony_D (Andhra Pradesh)",
        FacilityCity: "Badvel",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8400,
        Pin: 410506,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8401,
        Pin: 411017,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8402,
        Pin: 224228,
        DispatchCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        OriginCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        FacilityCity: "Kumarganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8403,
        Pin: 361150,
        DispatchCenter: "Jamnagar_Chela_L (Gujarat)",
        OriginCenter: "Jamnagar_Chela_L (Gujarat)",
        FacilityCity: "Jamnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8404,
        Pin: 400066,
        DispatchCenter: "Mumbai_Kandivali_L (Maharashtra)",
        OriginCenter: "Mumbai_Kandivali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8405,
        Pin: 400075,
        DispatchCenter: "Mumbai_Chembur_L (Maharashtra)",
        OriginCenter: "Mumbai_Chembur_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8406,
        Pin: 797108,
        DispatchCenter: "Phek_Chesezumi_D (Nagaland)",
        OriginCenter: "Phek_Chesezumi_D (Nagaland)",
        FacilityCity: "Phek",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8407,
        Pin: 413404,
        DispatchCenter: "Barshi_Indiranagar_D (Maharashtra)",
        OriginCenter: "Barshi_Indiranagar_D (Maharashtra)",
        FacilityCity: "Barshi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8408,
        Pin: 140113,
        DispatchCenter: "Rupnagar_Towercolony_D (Punjab)",
        OriginCenter: "Rupnagar_Towercolony_D (Punjab)",
        FacilityCity: "Rupnagar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8409,
        Pin: 415022,
        DispatchCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        OriginCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        FacilityCity: "Satara",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8410,
        Pin: 581103,
        DispatchCenter: "Alnavar_BusStndDPP_D (Karnataka)",
        OriginCenter: "Alnavar_BusStndDPP_D (Karnataka)",
        FacilityCity: "Alnavar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8411,
        Pin: 152002,
        DispatchCenter: "Ferozepur_DC (Punjab)",
        OriginCenter: "Ferozepur_DC (Punjab)",
        FacilityCity: "Firozpur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8412,
        Pin: 612610,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8413,
        Pin: 785682,
        DispatchCenter: "Teok_Wardno3_D (Assam)",
        OriginCenter: "Teok_Wardno3_D (Assam)",
        FacilityCity: "Teok",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8414,
        Pin: 570025,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8415,
        Pin: 384330,
        DispatchCenter: "Satlasana_Hndutmpl_DPP (Gujarat)",
        OriginCenter: "Satlasana_Hndutmpl_DPP (Gujarat)",
        FacilityCity: "Satlasana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8416,
        Pin: 802221,
        DispatchCenter: "Bikramgang_Dehrird_D (Bihar)",
        OriginCenter: "Bikramgang_Dehrird_D (Bihar)",
        FacilityCity: "Bikramganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8417,
        Pin: 246428,
        DispatchCenter: "Gairsain_Salyana_D (Uttarakhand)",
        OriginCenter: "Gairsain_Salyana_D (Uttarakhand)",
        FacilityCity: "Gairsain",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8418,
        Pin: 712224,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8419,
        Pin: 385555,
        DispatchCenter: "Shihori_Amblivas_DPP (Gujarat)",
        OriginCenter: "Shihori_Amblivas_DPP (Gujarat)",
        FacilityCity: "Shihori",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8420,
        Pin: 136134,
        DispatchCenter: "Ambala_Mandli_D (Haryana)",
        OriginCenter: "Ambala_Mandli_D (Haryana)",
        FacilityCity: "Ambala",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8421,
        Pin: 143534,
        DispatchCenter: "Dinanagar_KakaHosp_D (Punjab)",
        OriginCenter: "Dinanagar_KakaHosp_D (Punjab)",
        FacilityCity: "Dinanagar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8422,
        Pin: 520011,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8423,
        Pin: 562112,
        DispatchCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        OriginCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8424,
        Pin: 481105,
        DispatchCenter: "Malanjkhand_PouniSuji_D (Madhya Pradesh)",
        OriginCenter: "Malanjkhand_PouniSuji_D (Madhya Pradesh)",
        FacilityCity: "Malanjkhand",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8425,
        Pin: 263160,
        DispatchCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        OriginCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        FacilityCity: "Rudrapur",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8426,
        Pin: 614715,
        DispatchCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        FacilityCity: "Thiruthuraipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8427,
        Pin: 127307,
        DispatchCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        OriginCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        FacilityCity: "Charkhi Dadri",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8428,
        Pin: 831021,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        Zone : "E"
      },
      {
        SN: 8429,
        Pin: 497448,
        DispatchCenter: "Manendragarh_JKDRoad_D (Chhattisgarh)",
        OriginCenter: "Manendragarh_JKDRoad_D (Chhattisgarh)",
        FacilityCity: "Manendragarh",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8430,
        Pin: 518023,
        DispatchCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        OriginCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8431,
        Pin: 824120,
        DispatchCenter: "Daudnagar_Tarar_D (Bihar)",
        OriginCenter: "Daudnagar_Tarar_D (Bihar)",
        FacilityCity: "Daudnagar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8432,
        Pin: 756021,
        DispatchCenter: "Balasore_Ganeswarpur_D (Orissa)",
        OriginCenter: "Balasore_Ganeswarpur_D (Orissa)",
        FacilityCity: "Balasore",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8433,
        Pin: 272172,
        DispatchCenter: "Dhanghata_MarhaRaja_DPP (Uttar Pradesh)",
        OriginCenter: "Dhanghata_MarhaRaja_DPP (Uttar Pradesh)",
        FacilityCity: "Dhanghata",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8434,
        Pin: 613307,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8435,
        Pin: 363427,
        DispatchCenter: "Limbdi_UmaiyaPark_DPP (Gujarat)",
        OriginCenter: "Limbdi_UmaiyaPark_DPP (Gujarat)",
        FacilityCity: "Limbdi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 8436,
        Pin: 577589,
        DispatchCenter: "Davangere_Industrialarea_I (Karnataka)",
        OriginCenter: "Davangere_Industrialarea_I (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8437,
        Pin: 630106,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8438,
        Pin: 508233,
        DispatchCenter: "Kodad_OldNH9_D (Telangana)",
        OriginCenter: "Kodad_OldNH9_D (Telangana)",
        FacilityCity: "Kodad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8439,
        Pin: 515261,
        DispatchCenter: "Hindupur_Sadlapalli_D (Andhra Pradesh)",
        OriginCenter: "Hindupur_Sadlapalli_D (Andhra Pradesh)",
        FacilityCity: "Hindupur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8440,
        Pin: 591254,
        DispatchCenter: "Belgaum_Hukkeri_DPP (Karnataka)",
        OriginCenter: "Belgaum_Hukkeri_DPP (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8441,
        Pin: 854303,
        DispatchCenter: "Purnia_Fatehpur_D (Bihar)",
        OriginCenter: "Purnia_Fatehpur_D (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8442,
        Pin: 533437,
        DispatchCenter: "Samarlakota_CHC_D (Andhra Pradesh)",
        OriginCenter: "Samarlakota_CHC_D (Andhra Pradesh)",
        FacilityCity: "Samarlakota",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8443,
        Pin: 522309,
        DispatchCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        OriginCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8444,
        Pin: 151401,
        DispatchCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        OriginCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        FacilityCity: "Bathinda",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8445,
        Pin: 854338,
        DispatchCenter: "Birpur_Basantpur_D (Bihar)",
        OriginCenter: "Birpur_Basantpur_D (Bihar)",
        FacilityCity: "Birpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8446,
        Pin: 502249,
        DispatchCenter: "Zahirabad_Mohim_D (Telangana)",
        OriginCenter: "Zahirabad_Mohim_D (Telangana)",
        FacilityCity: "Zahirabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8447,
        Pin: 193122,
        DispatchCenter: "Baramulla_Boniyar_DPP (Jammu & Kashmir)",
        OriginCenter: "Baramulla_Boniyar_DPP (Jammu & Kashmir)",
        FacilityCity: "Baramulla",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8448,
        Pin: 414401,
        DispatchCenter: "Karjat_Mirajgaon_DPP (Maharashtra)",
        OriginCenter: "Karjat_Mirajgaon_DPP (Maharashtra)",
        FacilityCity: "Karjat-MH",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8449,
        Pin: 396165,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 8450,
        Pin: 333037,
        DispatchCenter: "Balaran_Ward2_DPP (Rajasthan)",
        OriginCenter: "Balaran_Ward2_DPP (Rajasthan)",
        FacilityCity: "Balaran",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8451,
        Pin: 627901,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8452,
        Pin: 283204,
        DispatchCenter: "Firozabad_MahadeoNagar_D (Uttar Pradesh)",
        OriginCenter: "Firozabad_MahadeoNagar_D (Uttar Pradesh)",
        FacilityCity: "Firozabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8453,
        Pin: 821114,
        DispatchCenter: "Goh_Golapar_D (Bihar)",
        OriginCenter: "Goh_Golapar_D (Bihar)",
        FacilityCity: "Goh",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8454,
        Pin: 403531,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8455,
        Pin: 731236,
        DispatchCenter: "Bolpur_Surul_DPP (West Bengal)",
        OriginCenter: "Bolpur_Surul_DPP (West Bengal)",
        FacilityCity: "Bolpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8456,
        Pin: 756182,
        DispatchCenter: "Bhadrak_Purusandha_D (Orissa)",
        OriginCenter: "Bhadrak_Purusandha_D (Orissa)",
        FacilityCity: "Agarpada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8457,
        Pin: 641601,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8458,
        Pin: 560075,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8459,
        Pin: 271122,
        DispatchCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        OriginCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        FacilityCity: "Gonda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8460,
        Pin: 341319,
        DispatchCenter: "KhatuKhurd_MakranaRd_D (Rajasthan)",
        OriginCenter: "KhatuKhurd_MakranaRd_D (Rajasthan)",
        FacilityCity: "Khatu Khurd",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8461,
        Pin: 782413,
        DispatchCenter: "Nagaon_Bangthai_H (Assam)",
        OriginCenter: "Nagaon_Bangthai_H (Assam)",
        FacilityCity: "Nagaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8462,
        Pin: 500006,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8463,
        Pin: 700140,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8464,
        Pin: 246147,
        DispatchCenter: "Pauri_Khndyusn_D (Uttarakhand)",
        OriginCenter: "Pauri_Khndyusn_D (Uttarakhand)",
        FacilityCity: "Pauri",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8465,
        Pin: 671128,
        DispatchCenter: "Kasaragod_Kudlu_D (Kerala)",
        OriginCenter: "Kasaragod_Kudlu_D (Kerala)",
        FacilityCity: "Kasaragod",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8466,
        Pin: 521324,
        DispatchCenter: "Kaikaluru_Bantumilli_D (Andhra Pradesh)",
        OriginCenter: "Kaikaluru_Bantumilli_D (Andhra Pradesh)",
        FacilityCity: "Kaikaluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8467,
        Pin: 401403,
        DispatchCenter: "Palghar_Maan_L (Maharashtra)",
        OriginCenter: "Palghar_Maan_L (Maharashtra)",
        FacilityCity: "Palghar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8468,
        Pin: 132114,
        DispatchCenter: "Karnal_Kutail_H (Haryana)",
        OriginCenter: "Karnal_Kutail_H (Haryana)",
        FacilityCity: "Karnal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8469,
        Pin: 700018,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8470,
        Pin: 560012,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8471,
        Pin: 626177,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8472,
        Pin: 493113,
        DispatchCenter: "Bhatapara_Nayaganj_D (Chhattisgarh)",
        OriginCenter: "Bhatapara_Nayaganj_D (Chhattisgarh)",
        FacilityCity: "Bhatapara",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8473,
        Pin: 362037,
        DispatchCenter: "Junagadh_Dolatpara_I (Gujarat)",
        OriginCenter: "Junagadh_Dolatpara_I (Gujarat)",
        FacilityCity: "Junagadh",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8474,
        Pin: 814119,
        DispatchCenter: "Shikaripara_Karakata_D (Jharkhand)",
        OriginCenter: "Shikaripara_Karakata_D (Jharkhand)",
        FacilityCity: "Shikaripara",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8475,
        Pin: 621851,
        DispatchCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        OriginCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        FacilityCity: "Thirukkattupalli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8476,
        Pin: 641039,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8477,
        Pin: 637503,
        DispatchCenter: "Tiruchengode_Kailasmplym_D (Tamil Nadu)",
        OriginCenter: "Tiruchengode_Kailasmplym_D (Tamil Nadu)",
        FacilityCity: "Tiruchengode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8478,
        Pin: 490009,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8479,
        Pin: 486884,
        DispatchCenter: "Singrauli_Waidhan2_D (Madhya Pradesh)",
        OriginCenter: "Singrauli_Waidhan2_D (Madhya Pradesh)",
        FacilityCity: "Singrauli",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8480,
        Pin: 534202,
        DispatchCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        FacilityCity: "Bhimavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8481,
        Pin: 627765,
        DispatchCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        OriginCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        FacilityCity: "Sankarankovil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8482,
        Pin: 532421,
        DispatchCenter: "Narasannapeta_SridviNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasannapeta_SridviNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasannapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8483,
        Pin: 626121,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8484,
        Pin: 612101,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8485,
        Pin: 686036,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8486,
        Pin: 504201,
        DispatchCenter: "Chinnur_AsnsdhRD_D (Telangana)",
        OriginCenter: "Chinnur_AsnsdhRD_D (Telangana)",
        FacilityCity: "Chinnur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8487,
        Pin: 796014,
        DispatchCenter: "Aizawl_Bawngkawn2_D (Mizoram)",
        OriginCenter: "Aizawl_Bawngkawn2_D (Mizoram)",
        FacilityCity: "Aizawl",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8488,
        Pin: 641045,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8489,
        Pin: 642102,
        DispatchCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        OriginCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        FacilityCity: "Udumalaipettai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8490,
        Pin: 141123,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8491,
        Pin: 518123,
        DispatchCenter: "Betamcherla_Bngnpalle_D (Andhra Pradesh)",
        OriginCenter: "Betamcherla_Bngnpalle_D (Andhra Pradesh)",
        FacilityCity: "Betamcherla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8492,
        Pin: 843326,
        DispatchCenter: "Pupri_SngihiRD_D (Bihar)",
        OriginCenter: "Pupri_SngihiRD_D (Bihar)",
        FacilityCity: "Pupri",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8493,
        Pin: 608306,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8494,
        Pin: 143093,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8495,
        Pin: 263136,
        DispatchCenter: "Bhimtal_BhowaliRd_D (Uttarakhand)",
        OriginCenter: "Bhimtal_BhowaliRd_D (Uttarakhand)",
        FacilityCity: "Bhimtal",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8496,
        Pin: 457003,
        DispatchCenter: "Ratlam_MhowRd_D (Madhya Pradesh)",
        OriginCenter: "Ratlam_MhowRd_D (Madhya Pradesh)",
        FacilityCity: "Ratlam",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 8497,
        Pin: 273412,
        DispatchCenter: "Barhalganj_Lakhnapar_DPP (Uttar Pradesh)",
        OriginCenter: "Barhalganj_Lakhnapar_DPP (Uttar Pradesh)",
        FacilityCity: "Barhalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8498,
        Pin: 793014,
        DispatchCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        OriginCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8499,
        Pin: 231311,
        DispatchCenter: "Robertsganj_Sirsai_D (Uttar Pradesh)",
        OriginCenter: "Robertsganj_Sirsai_D (Uttar Pradesh)",
        FacilityCity: "Robertsganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8500,
        Pin: 182142,
        DispatchCenter: "Chenani_Ward3_D (Jammu & Kashmir)",
        OriginCenter: "Chenani_Ward3_D (Jammu & Kashmir)",
        FacilityCity: "Chenani",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8501,
        Pin: 413606,
        DispatchCenter: "Omerga_Ekondiroad_DPP (Maharashtra)",
        OriginCenter: "Omerga_Ekondiroad_DPP (Maharashtra)",
        FacilityCity: "Omerga",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8502,
        Pin: 690557,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8503,
        Pin: 484113,
        DispatchCenter: "Pendra_Marwahi_D (Chhattisgarh)",
        OriginCenter: "Pendra_Marwahi_D (Chhattisgarh)",
        FacilityCity: "Pendra",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8504,
        Pin: 144013,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8505,
        Pin: 110068,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8506,
        Pin: 741505,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8507,
        Pin: 422008,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8508,
        Pin: 360040,
        DispatchCenter: "Jasdan_Aanandnagar_D (Gujarat)",
        OriginCenter: "Jasdan_Aanandnagar_D (Gujarat)",
        FacilityCity: "Jasdan",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8509,
        Pin: 462012,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8510,
        Pin: 503218,
        DispatchCenter: "Armoor_Mamidipillay_D (Telangana)",
        OriginCenter: "Armoor_Mamidipillay_D (Telangana)",
        FacilityCity: "Armoor",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8511,
        Pin: 680666,
        DispatchCenter: "Kodungallur_Methala_D (Kerala)",
        OriginCenter: "Kodungallur_Methala_D (Kerala)",
        FacilityCity: "Kodungallur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8512,
        Pin: 628714,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8513,
        Pin: 802313,
        DispatchCenter: "Arrah_Anaith_D (Bihar)",
        OriginCenter: "Arrah_Anaith_D (Bihar)",
        FacilityCity: "Arrah",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8514,
        Pin: 122217,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8515,
        Pin: 389155,
        DispatchCenter: "Dahod_Vanzarwada_I (Gujarat)",
        OriginCenter: "Dahod_Vanzarwada_I (Gujarat)",
        FacilityCity: "Dahod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 8516,
        Pin: 425524,
        DispatchCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        OriginCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        FacilityCity: "Bhusawal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8517,
        Pin: 521325,
        DispatchCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        OriginCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        FacilityCity: "Gudivada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8518,
        Pin: 756114,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8519,
        Pin: 152032,
        DispatchCenter: "Muktsar_BathindaRd_D (Punjab)",
        OriginCenter: "Muktsar_BathindaRd_D (Punjab)",
        FacilityCity: "Muktsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8520,
        Pin: 670673,
        DispatchCenter: "Chavassery_Peravoor_D (Kerala)",
        OriginCenter: "Chavassery_Peravoor_D (Kerala)",
        FacilityCity: "Chavassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8521,
        Pin: 783369,
        DispatchCenter: "Kokrajhar_Ghiluguri_DPP (Assam)",
        OriginCenter: "Kokrajhar_Ghiluguri_DPP (Assam)",
        FacilityCity: "Kokrajhar",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8522,
        Pin: 402105,
        DispatchCenter: "Shriwardhan_Kherdi_D (Maharashtra)",
        OriginCenter: "Shriwardhan_Kherdi_D (Maharashtra)",
        FacilityCity: "Shriwardhan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8523,
        Pin: 721150,
        DispatchCenter: "Midnapore_Harishpur_D (West Bengal)",
        OriginCenter: "Midnapore_Harishpur_D (West Bengal)",
        FacilityCity: "Midnapore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8524,
        Pin: 753051,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8525,
        Pin: 144515,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8526,
        Pin: 507167,
        DispatchCenter: "Wyra_Thallada_D (Telangana)",
        OriginCenter: "Wyra_Thallada_D (Telangana)",
        FacilityCity: "Wyra",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8527,
        Pin: 480107,
        DispatchCenter: "Chhindwara_Linga_I (Madhya Pradesh)",
        OriginCenter: "Chhindwara_Linga_I (Madhya Pradesh)",
        FacilityCity: "Chhindwara",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 8528,
        Pin: 799006,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8529,
        Pin: 176056,
        DispatchCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        OriginCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        FacilityCity: "Kangra",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8530,
        Pin: 721102,
        DispatchCenter: "Midnapore_Harishpur_D (West Bengal)",
        OriginCenter: "Midnapore_Harishpur_D (West Bengal)",
        FacilityCity: "Midnapore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8531,
        Pin: 517004,
        DispatchCenter: "Chittoor_Murukambattu_D (Andhra Pradesh)",
        OriginCenter: "Chittoor_Murukambattu_D (Andhra Pradesh)",
        FacilityCity: "Chittoor",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8532,
        Pin: 207401,
        DispatchCenter: "Kasganj_GandhiKunj_D (Uttar Pradesh)",
        OriginCenter: "Kasganj_GandhiKunj_D (Uttar Pradesh)",
        FacilityCity: "Kasganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8533,
        Pin: 141107,
        DispatchCenter: "Raikot_DC (Punjab)",
        OriginCenter: "Raikot_DC (Punjab)",
        FacilityCity: "Raikot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8534,
        Pin: 246128,
        DispatchCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        OriginCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        FacilityCity: "Gumkhal",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8535,
        Pin: 572134,
        DispatchCenter: "Kunigal_Vidyanagar_D (Karnataka)",
        OriginCenter: "Kunigal_Vidyanagar_D (Karnataka)",
        FacilityCity: "Kunigal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8536,
        Pin: 517350,
        DispatchCenter: "Madanapalle_RNaiduClny_D (Andhra Pradesh)",
        OriginCenter: "Madanapalle_RNaiduClny_D (Andhra Pradesh)",
        FacilityCity: "Madanapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8537,
        Pin: 534447,
        DispatchCenter: "Jangareddygudem_RTC_D (Andhra Pradesh)",
        OriginCenter: "Jangareddygudem_RTC_D (Andhra Pradesh)",
        FacilityCity: "Jangareddygudem",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8538,
        Pin: 794105,
        DispatchCenter: "Garobadha_SBI_DPP (Meghalaya)",
        OriginCenter: "Garobadha_SBI_DPP (Meghalaya)",
        FacilityCity: "Garobadha",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8539,
        Pin: 507003,
        DispatchCenter: "Khammam_Mudigonda_H (Telangana)",
        OriginCenter: "Khammam_Mudigonda_H (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8540,
        Pin: 743268,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8541,
        Pin: 500267,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8542,
        Pin: 392150,
        DispatchCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        OriginCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        FacilityCity: "Jambusar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8543,
        Pin: 362255,
        DispatchCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        OriginCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        FacilityCity: "Veraval",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 8544,
        Pin: 600001,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8545,
        Pin: 761015,
        DispatchCenter: "Mohana_Betarsingi_D (Orissa)",
        OriginCenter: "Mohana_Betarsingi_D (Orissa)",
        FacilityCity: "Mohana",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8546,
        Pin: 623308,
        DispatchCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        OriginCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        FacilityCity: "Thiruvadanai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8547,
        Pin: 124112,
        DispatchCenter: "Meham_Ward13_D (Haryana)",
        OriginCenter: "Meham_Ward13_D (Haryana)",
        FacilityCity: "Meham",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8548,
        Pin: 147005,
        DispatchCenter: "Patiala_UpkarNagar_D (Punjab)",
        OriginCenter: "Patiala_UpkarNagar_D (Punjab)",
        FacilityCity: "Patiala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8549,
        Pin: 786624,
        DispatchCenter: "Sonari_Nakachari_D (Assam)",
        OriginCenter: "Sonari_Nakachari_D (Assam)",
        FacilityCity: "Sonari",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8550,
        Pin: 770072,
        DispatchCenter: "Sundargarh_Duduka_D (Orissa)",
        OriginCenter: "Sundargarh_Duduka_D (Orissa)",
        FacilityCity: "Sundargarh",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8551,
        Pin: 641668,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8552,
        Pin: 570004,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8553,
        Pin: 415301,
        DispatchCenter: "Atapadi_VidyaNagar_D (Maharashtra)",
        OriginCenter: "Atapadi_VidyaNagar_D (Maharashtra)",
        FacilityCity: "Atapadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8554,
        Pin: 151211,
        DispatchCenter: "Mandidabwali_Jawharnagar_D (Haryana)",
        OriginCenter: "Mandidabwali_Jawharnagar_D (Haryana)",
        FacilityCity: "Mandi Dabwali",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8555,
        Pin: 522013,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8556,
        Pin: 502331,
        DispatchCenter: "Medak_RTCclny_D (Telangana)",
        OriginCenter: "Medak_RTCclny_D (Telangana)",
        FacilityCity: "Medak",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8557,
        Pin: 785670,
        DispatchCenter: "Moranhat_TengaDollong_D (Assam)",
        OriginCenter: "Moranhat_TengaDollong_D (Assam)",
        FacilityCity: "Moranhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8558,
        Pin: 721663,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8559,
        Pin: 680588,
        DispatchCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        OriginCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        FacilityCity: "Pazhayannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8560,
        Pin: 132037,
        DispatchCenter: "Karnal_Kutail_H (Haryana)",
        OriginCenter: "Karnal_Kutail_H (Haryana)",
        FacilityCity: "Karnal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8561,
        Pin: 413580,
        DispatchCenter: "Osmanabad_RajasabH_D (Maharashtra)",
        OriginCenter: "Osmanabad_RajasabH_D (Maharashtra)",
        FacilityCity: "Osmanabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8562,
        Pin: 364071,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 8563,
        Pin: 282010,
        DispatchCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        OriginCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        FacilityCity: "Agra",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8564,
        Pin: 306705,
        DispatchCenter: "Falna_Khudala_DPP (Rajasthan)",
        OriginCenter: "Falna_Khudala_DPP (Rajasthan)",
        FacilityCity: "Falna",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8565,
        Pin: 210423,
        DispatchCenter: "Mahoba_Kulpahar_DPP (Uttar Pradesh)",
        OriginCenter: "Mahoba_Kulpahar_DPP (Uttar Pradesh)",
        FacilityCity: "Mahoba",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8566,
        Pin: 635810,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8567,
        Pin: 503219,
        DispatchCenter: "Nirmal_AdarshNgr_D (Telangana)",
        OriginCenter: "Nirmal_AdarshNgr_D (Telangana)",
        FacilityCity: "Nirmal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8568,
        Pin: 313904,
        DispatchCenter: "Sarada_Kachhota_DPP (Rajasthan)",
        OriginCenter: "Sarada_Kachhota_DPP (Rajasthan)",
        FacilityCity: "Sarada",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8569,
        Pin: 560077,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8570,
        Pin: 591117,
        DispatchCenter: "Saundatti_Bypassroad_D (Karnataka)",
        OriginCenter: "Saundatti_Bypassroad_D (Karnataka)",
        FacilityCity: "Saundatti",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8571,
        Pin: 761101,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8572,
        Pin: 577144,
        DispatchCenter: "Shimoga_Bhandrahalli_D (Karnataka)",
        OriginCenter: "Shimoga_Bhandrahalli_D (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8573,
        Pin: 415302,
        DispatchCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        OriginCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        FacilityCity: "Islampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8574,
        Pin: 768018,
        DispatchCenter: "Sambalpur_Tentelpara_H (Orissa)",
        OriginCenter: "Sambalpur_Tentelpara_H (Orissa)",
        FacilityCity: "Sambalpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8575,
        Pin: 631205,
        DispatchCenter: "Sholinghur_Ramkrishnarajapet_D (Tamil Nadu)",
        OriginCenter: "Sholinghur_Ramkrishnarajapet_D (Tamil Nadu)",
        FacilityCity: "Sholinghur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8576,
        Pin: 382712,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 8577,
        Pin: 813126,
        DispatchCenter: "Banka_Shahpur_DPP (Bihar)",
        OriginCenter: "Banka_Shahpur_DPP (Bihar)",
        FacilityCity: "Banka",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8578,
        Pin: 690525,
        DispatchCenter: "Kollam_Karunagappally_D (Kerala)",
        OriginCenter: "Kollam_Karunagappally_D (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8579,
        Pin: 414001,
        DispatchCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        OriginCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        FacilityCity: "Ahmednagar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8580,
        Pin: 796081,
        DispatchCenter: "Kolasib_DiakkawnVeng_D (Mizoram)",
        OriginCenter: "Kolasib_DiakkawnVeng_D (Mizoram)",
        FacilityCity: "Kolasib",
        FacilityState: "Mizoram",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8581,
        Pin: 251315,
        DispatchCenter: "Khatauli_GTRoad_D (Uttar Pradesh)",
        OriginCenter: "Khatauli_GTRoad_D (Uttar Pradesh)",
        FacilityCity: "Khatauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8582,
        Pin: 686691,
        DispatchCenter: "Perumbavoor_Kizhake_D (Kerala)",
        OriginCenter: "Perumbavoor_Kizhake_D (Kerala)",
        FacilityCity: "Perumbavoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8583,
        Pin: 400605,
        DispatchCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8584,
        Pin: 174026,
        DispatchCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        OriginCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        FacilityCity: "Bilaspur-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8585,
        Pin: 783125,
        DispatchCenter: "Goalpara_Baladmri_D (Assam)",
        OriginCenter: "Goalpara_Baladmri_D (Assam)",
        FacilityCity: "Goalpara",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8586,
        Pin: 580022,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8587,
        Pin: 734427,
        DispatchCenter: "Bagdogra_Budhkaran_D (West Bengal)",
        OriginCenter: "Bagdogra_Budhkaran_D (West Bengal)",
        FacilityCity: "Bagdogra",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8588,
        Pin: 643005,
        DispatchCenter: "Ooty_Westmere_D (Tamil Nadu)",
        OriginCenter: "Ooty_Westmere_D (Tamil Nadu)",
        FacilityCity: "Ooty",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8589,
        Pin: 502257,
        DispatchCenter: "Zahirabad_Jharasangam_D (Telangana)",
        OriginCenter: "Zahirabad_Jharasangam_D (Telangana)",
        FacilityCity: "Zahirabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8590,
        Pin: 192303,
        DispatchCenter: "Pulwama_Shopian_DPP (Jammu & Kashmir)",
        OriginCenter: "Pulwama_Shopian_DPP (Jammu & Kashmir)",
        FacilityCity: "Pulwama",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8591,
        Pin: 733145,
        DispatchCenter: "Balurghat_AkhirDPP_D (West Bengal)",
        OriginCenter: "Balurghat_AkhirDPP_D (West Bengal)",
        FacilityCity: "Balurghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8592,
        Pin: 464114,
        DispatchCenter: "Maksudangarh_Rajivnagar_D (Madhya Pradesh)",
        OriginCenter: "Maksudangarh_Rajivnagar_D (Madhya Pradesh)",
        FacilityCity: "Maksudangarh",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8593,
        Pin: 533450,
        DispatchCenter: "Samarlakota_CHC_D (Andhra Pradesh)",
        OriginCenter: "Samarlakota_CHC_D (Andhra Pradesh)",
        FacilityCity: "Samarlakota",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8594,
        Pin: 741315,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8595,
        Pin: 711301,
        DispatchCenter: "Howrah_Temp_L (West Bengal)",
        OriginCenter: "Howrah_Temp_L (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8596,
        Pin: 364485,
        DispatchCenter: "Jetpur_Dasijivanpara_D (Gujarat)",
        OriginCenter: "Jetpur_Dasijivanpara_D (Gujarat)",
        FacilityCity: "Jetpur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8597,
        Pin: 607001,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8598,
        Pin: 572129,
        DispatchCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        OriginCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8599,
        Pin: 752106,
        DispatchCenter: "Nimapara_Nuharakiri_DPP (Orissa)",
        OriginCenter: "Nimapara_Nuharakiri_DPP (Orissa)",
        FacilityCity: "Nimapara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8600,
        Pin: 470002,
        DispatchCenter: "Sagar_Pagara_P (Madhya Pradesh)",
        OriginCenter: "Sagar_Pagara_P (Madhya Pradesh)",
        FacilityCity: "Sagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8601,
        Pin: 690506,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8602,
        Pin: 759023,
        DispatchCenter: "Kamakhyanagar_Mundideuli_D (Orissa)",
        OriginCenter: "Kamakhyanagar_Mundideuli_D (Orissa)",
        FacilityCity: "Kamakhyanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8603,
        Pin: 531023,
        DispatchCenter: "Chodavaram_Narasayyapeta_D (Andhra Pradesh)",
        OriginCenter: "Chodavaram_Narasayyapeta_D (Andhra Pradesh)",
        FacilityCity: "Chodavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8604,
        Pin: 462010,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8605,
        Pin: 209868,
        DispatchCenter: "Miyanganj_Bangarmau_D (Uttar Pradesh)",
        OriginCenter: "Miyanganj_Bangarmau_D (Uttar Pradesh)",
        FacilityCity: "Pihani",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8606,
        Pin: 577204,
        DispatchCenter: "Shimoga_Eshwariah_I (Karnataka)",
        OriginCenter: "Shimoga_Eshwariah_I (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8607,
        Pin: 781024,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8608,
        Pin: 609602,
        DispatchCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        OriginCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        FacilityCity: "Karaikal",
        FacilityState: "Pondicherry",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8609,
        Pin: 572225,
        DispatchCenter: "Turuvukere_Havala_D (Karnataka)",
        OriginCenter: "Turuvukere_Havala_D (Karnataka)",
        FacilityCity: "Turuvukere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8610,
        Pin: 131001,
        DispatchCenter: "Sonipat_Sector51_L (Haryana)",
        OriginCenter: "Sonipat_WazidpurSaboli_P (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8611,
        Pin: 732214,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8612,
        Pin: 752113,
        DispatchCenter: "Nimapara_Nuharakiri_DPP (Orissa)",
        OriginCenter: "Nimapara_Nuharakiri_DPP (Orissa)",
        FacilityCity: "Nimapara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8613,
        Pin: 641104,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8614,
        Pin: 680001,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8615,
        Pin: 201102,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8616,
        Pin: 711311,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8617,
        Pin: 621208,
        DispatchCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        OriginCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        FacilityCity: "Kulithalai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8618,
        Pin: 520012,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8619,
        Pin: 508208,
        DispatchCenter: "Nereducharla_GaridepalliRoad_D (Telangana)",
        OriginCenter: "Nereducharla_GaridepalliRoad_D (Telangana)",
        FacilityCity: "Nereducharla",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8620,
        Pin: 110085,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8621,
        Pin: 341301,
        DispatchCenter: "KhatuKhurd_MakranaRd_D (Rajasthan)",
        OriginCenter: "KhatuKhurd_MakranaRd_D (Rajasthan)",
        FacilityCity: "Khatu Khurd",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8622,
        Pin: 471201,
        DispatchCenter: "Chhatarpur_SagarRd_D (Madhya Pradesh)",
        OriginCenter: "Chhatarpur_SagarRd_D (Madhya Pradesh)",
        FacilityCity: "Chhatarpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 8623,
        Pin: 802163,
        DispatchCenter: "Arrah_Sakaddi_D (Bihar)",
        OriginCenter: "Arrah_Sakaddi_D (Bihar)",
        FacilityCity: "Arrah",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8624,
        Pin: 560074,
        DispatchCenter: "Bangalore_FCILayout_L (Karnataka)",
        OriginCenter: "Bangalore_FCILayout_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8625,
        Pin: 176040,
        DispatchCenter: "Bhota_BpassDPP_D (Himachal Pradesh)",
        OriginCenter: "Bhota_BpassDPP_D (Himachal Pradesh)",
        FacilityCity: "Bhota",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8626,
        Pin: 176075,
        DispatchCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        OriginCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8627,
        Pin: 621012,
        DispatchCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        OriginCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        FacilityCity: "Thuraiyur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8628,
        Pin: 403701,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8629,
        Pin: 503212,
        DispatchCenter: "Navipet_Dharyapur_D (Telangana)",
        OriginCenter: "Navipet_Dharyapur_D (Telangana)",
        FacilityCity: "Navipet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8630,
        Pin: 385550,
        DispatchCenter: "Shihori_Amblivas_DPP (Gujarat)",
        OriginCenter: "Shihori_Amblivas_DPP (Gujarat)",
        FacilityCity: "Shihori",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8631,
        Pin: 508101,
        DispatchCenter: "Alair_Reddygudem_D (Telangana)",
        OriginCenter: "Alair_Reddygudem_D (Telangana)",
        FacilityCity: "Alair",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8632,
        Pin: 302034,
        DispatchCenter: "Jaipur_RingRoad_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8633,
        Pin: 632604,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8634,
        Pin: 534211,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8635,
        Pin: 462031,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8636,
        Pin: 585302,
        DispatchCenter: "Aland_Sherikarcolony_D (Karnataka)",
        OriginCenter: "Aland_Sherikarcolony_D (Karnataka)",
        FacilityCity: "Aland",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8637,
        Pin: 535204,
        DispatchCenter: "Bhogapuram_Thotaveedhi_D (Andhra Pradesh)",
        OriginCenter: "Bhogapuram_Thotaveedhi_D (Andhra Pradesh)",
        FacilityCity: "Bhogapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8638,
        Pin: 124001,
        DispatchCenter: "Rohtak_IndustrialArea_D (Haryana)",
        OriginCenter: "Rohtak_IndustrialArea_D (Haryana)",
        FacilityCity: "Rohtak",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8639,
        Pin: 233230,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8640,
        Pin: 612802,
        DispatchCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        OriginCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8641,
        Pin: 627010,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8642,
        Pin: 110505,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8643,
        Pin: 443301,
        DispatchCenter: "Mehkar_Irrigationcol_DPP (Maharashtra)",
        OriginCenter: "Mehkar_Irrigationcol_DPP (Maharashtra)",
        FacilityCity: "Mehkar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8644,
        Pin: 302012,
        DispatchCenter: "Jaipur_VishwakarmaIndArea_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8645,
        Pin: 628309,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8646,
        Pin: 516293,
        DispatchCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8647,
        Pin: 695525,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8648,
        Pin: 760005,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8649,
        Pin: 365456,
        DispatchCenter: "Amreli_Kunkavav_DPP (Gujarat)",
        OriginCenter: "Amreli_Kunkavav_DPP (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8650,
        Pin: 574189,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8651,
        Pin: 635117,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8652,
        Pin: 521323,
        DispatchCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        OriginCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        FacilityCity: "Gudivada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8653,
        Pin: 742189,
        DispatchCenter: "Plassey_Janakinagar_D (West Bengal)",
        OriginCenter: "Plassey_Janakinagar_D (West Bengal)",
        FacilityCity: "Plassey",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8654,
        Pin: 678505,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8655,
        Pin: 639105,
        DispatchCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        OriginCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        FacilityCity: "Kulithalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8656,
        Pin: 361220,
        DispatchCenter: "Dhrol_PGVCLOfc_D (Gujarat)",
        OriginCenter: "Dhrol_PGVCLOfc_D (Gujarat)",
        FacilityCity: "Dhrol",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8657,
        Pin: 731130,
        DispatchCenter: "Suri_Karidhya_D (West Bengal)",
        OriginCenter: "Suri_Karidhya_D (West Bengal)",
        FacilityCity: "Suri",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8658,
        Pin: 284419,
        DispatchCenter: "Jhansi_Nandanpura_I (Uttar Pradesh)",
        OriginCenter: "Jhansi_Nandanpura_I (Uttar Pradesh)",
        FacilityCity: "Jhansi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8659,
        Pin: 638661,
        DispatchCenter: "Dharapuram_Pirivu_D (Tamil Nadu)",
        OriginCenter: "Dharapuram_Pirivu_D (Tamil Nadu)",
        FacilityCity: "Dharapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8660,
        Pin: 756045,
        DispatchCenter: "Soro_Ashoknagar_D (Orissa)",
        OriginCenter: "Soro_Ashoknagar_D (Orissa)",
        FacilityCity: "Soro",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8661,
        Pin: 476554,
        DispatchCenter: "Morena_Ambah_D (Madhya Pradesh)",
        OriginCenter: "Morena_Ambah_D (Madhya Pradesh)",
        FacilityCity: "Morena",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 8662,
        Pin: 583211,
        DispatchCenter: "Hospet_Vijayanagara_D (Karnataka)",
        OriginCenter: "Hospet_Vijayanagara_D (Karnataka)",
        FacilityCity: "Hospet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8663,
        Pin: 495549,
        DispatchCenter: "Akaltara_IndraUdyan_D (Chhattisgarh)",
        OriginCenter: "Akaltara_IndraUdyan_D (Chhattisgarh)",
        FacilityCity: "Akaltara",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8664,
        Pin: 171008,
        DispatchCenter: "Shimla_Tara_DC (Himachal Pradesh)",
        OriginCenter: "Shimla_Tara_DC (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8665,
        Pin: 621203,
        DispatchCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        OriginCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        FacilityCity: "Kulithalai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8666,
        Pin: 146116,
        DispatchCenter: "Hoshiarpur_Hariana_D (Punjab)",
        OriginCenter: "Hoshiarpur_Hariana_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8667,
        Pin: 205130,
        DispatchCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        OriginCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        FacilityCity: "Shikohabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8668,
        Pin: 713218,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8669,
        Pin: 682510,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8670,
        Pin: 563134,
        DispatchCenter: "Srinivaspur_KolarRD_D (Karnataka)",
        OriginCenter: "Srinivaspur_KolarRD_D (Karnataka)",
        FacilityCity: "Srinivaspur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8671,
        Pin: 756029,
        DispatchCenter: "Basta_Darada_DPP (Orissa)",
        OriginCenter: "Basta_Darada_DPP (Orissa)",
        FacilityCity: "Basta",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8672,
        Pin: 400074,
        DispatchCenter: "Mumbai_Chembur_L (Maharashtra)",
        OriginCenter: "Mumbai_Chembur_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8673,
        Pin: 829208,
        DispatchCenter: "Khalari_NayaDhauda_D (Jharkhand)",
        OriginCenter: "Khalari_NayaDhauda_D (Jharkhand)",
        FacilityCity: "Khalari-JR",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8674,
        Pin: 491444,
        DispatchCenter: "Khairagarh_Mudpar_DPP (Chhattisgarh)",
        OriginCenter: "Khairagarh_Mudpar_DPP (Chhattisgarh)",
        FacilityCity: "Khairagarh",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8675,
        Pin: 411048,
        DispatchCenter: "Pune_Fursungi_L (Maharashtra)",
        OriginCenter: "Pune_Fursungi_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8676,
        Pin: 825330,
        DispatchCenter: "Ramgarh_Mandu_D (Jharkhand)",
        OriginCenter: "Ramgarh_Mandu_D (Jharkhand)",
        FacilityCity: "Ramgarh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8677,
        Pin: 822131,
        DispatchCenter: "Hariharganj_Central_DPP_1 (Jharkhand)",
        OriginCenter: "Hariharganj_Central_DPP_1 (Jharkhand)",
        FacilityCity: "Hariharganj",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8678,
        Pin: 148103,
        DispatchCenter: "Barnala_DC (Punjab)",
        OriginCenter: "Barnala_DC (Punjab)",
        FacilityCity: "Barnala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8679,
        Pin: 151301,
        DispatchCenter: "TalwandiSabo_GillanWala_D (Punjab)",
        OriginCenter: "TalwandiSabo_GillanWala_D (Punjab)",
        FacilityCity: "Talwandi Sabo",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8680,
        Pin: 505481,
        DispatchCenter: "KarimNagar_Alugunoor_I (Telangana)",
        OriginCenter: "KarimNagar_Alugunoor_I (Telangana)",
        FacilityCity: "Karim Nagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8681,
        Pin: 614702,
        DispatchCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        FacilityCity: "Thiruthuraipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8682,
        Pin: 733102,
        DispatchCenter: "Balurghat_AkhirDPP_D (West Bengal)",
        OriginCenter: "Balurghat_AkhirDPP_D (West Bengal)",
        FacilityCity: "Balurghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8683,
        Pin: 232331,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8684,
        Pin: 384241,
        DispatchCenter: "Harij_Denabank_DPP (Gujarat)",
        OriginCenter: "Harij_Denabank_DPP (Gujarat)",
        FacilityCity: "Harij",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8685,
        Pin: 380022,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8686,
        Pin: 797117,
        DispatchCenter: "Dimapur_Sovima_I (Nagaland)",
        OriginCenter: "Dimapur_Sovima_I (Nagaland)",
        FacilityCity: "Dimapur",
        FacilityState: "Nagaland",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8687,
        Pin: 491441,
        DispatchCenter: "Rajnandgaon_Lakholi_D (Chhattisgarh)",
        OriginCenter: "Rajnandgaon_Lakholi_D (Chhattisgarh)",
        FacilityCity: "Rajnandgaon",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8688,
        Pin: 322030,
        DispatchCenter: "Bonli_Malarnachour_DPP (Rajasthan)",
        OriginCenter: "Bonli_Malarnachour_DPP (Rajasthan)",
        FacilityCity: "Bonli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8689,
        Pin: 532443,
        DispatchCenter: "Palakonda_VadaiDPP_D (Andhra Pradesh)",
        OriginCenter: "Palakonda_VadaiDPP_D (Andhra Pradesh)",
        FacilityCity: "Palakonda",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8690,
        Pin: 532432,
        DispatchCenter: "Narasannapeta_SridviNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasannapeta_SridviNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasannapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8691,
        Pin: 494224,
        DispatchCenter: "Bastar_Rajendranagar_D (Chhattisgarh)",
        OriginCenter: "Bastar_Rajendranagar_D (Chhattisgarh)",
        FacilityCity: "Bastar",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8692,
        Pin: 143515,
        DispatchCenter: "SriHargobindpur_Talwara_D (Punjab)",
        OriginCenter: "SriHargobindpur_Talwara_D (Punjab)",
        FacilityCity: "Sri Hargobindpur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8693,
        Pin: 230001,
        DispatchCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        OriginCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        FacilityCity: "Pratapgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8694,
        Pin: 172001,
        DispatchCenter: "Rampur_Shingla_D (Himachal Pradesh)",
        OriginCenter: "Rampur_Shingla_D (Himachal Pradesh)",
        FacilityCity: "Rampur Bushahr",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8695,
        Pin: 626138,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8696,
        Pin: 416019,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8697,
        Pin: 635111,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8698,
        Pin: 604503,
        DispatchCenter: "Chetpet_Savarapoondi_D (Tamil Nadu)",
        OriginCenter: "Chetpet_Savarapoondi_D (Tamil Nadu)",
        FacilityCity: "Chetpet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8699,
        Pin: 477445,
        DispatchCenter: "Lahar_Beejasen_D (Madhya Pradesh)",
        OriginCenter: "Lahar_Beejasen_D (Madhya Pradesh)",
        FacilityCity: "Lahar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8700,
        Pin: 500003,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8701,
        Pin: 515661,
        DispatchCenter: "Anantapur_Dharmavaram_D (Andhra Pradesh)",
        OriginCenter: "Anantapur_Dharmavaram_D (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8702,
        Pin: 635702,
        DispatchCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        OriginCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        FacilityCity: "Tiruppattur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8703,
        Pin: 785623,
        DispatchCenter: "Golaghat_BaruaRd_D (Assam)",
        OriginCenter: "Golaghat_BaruaRd_D (Assam)",
        FacilityCity: "Golaghat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8704,
        Pin: 629253,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8705,
        Pin: 722107,
        DispatchCenter: "Bankura_KeranDPP_D (West Bengal)",
        OriginCenter: "Bankura_KeranDPP_D (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8706,
        Pin: 626143,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8707,
        Pin: 306504,
        DispatchCenter: "Pindwara_AjariChwk_DPP (Rajasthan)",
        OriginCenter: "Pindwara_AjariChwk_DPP (Rajasthan)",
        FacilityCity: "Pindwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8708,
        Pin: 792103,
        DispatchCenter: "Namsai_Tinali_D (Arunachal Pradesh)",
        OriginCenter: "Namsai_Tinali_D (Arunachal Pradesh)",
        FacilityCity: "Namsai",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8709,
        Pin: 402104,
        DispatchCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        OriginCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        FacilityCity: "Mangaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8710,
        Pin: 783336,
        DispatchCenter: "Gossaigaon_TnaliDPP_D (Assam)",
        OriginCenter: "Gossaigaon_TnaliDPP_D (Assam)",
        FacilityCity: "Gossaigaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8711,
        Pin: 335061,
        DispatchCenter: "Raisinghnagar_Ward24_D (Rajasthan)",
        OriginCenter: "Raisinghnagar_Ward24_D (Rajasthan)",
        FacilityCity: "Raisinghnagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8712,
        Pin: 385510,
        DispatchCenter: "Palanpur_Esbipura1_D (Gujarat)",
        OriginCenter: "Palanpur_Esbipura1_D (Gujarat)",
        FacilityCity: "Palanpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 8713,
        Pin: 679334,
        DispatchCenter: "Chungatra_KaithakodanBuilding_DPP (Kerala)",
        OriginCenter: "Chungatra_KaithakodanBuilding_DPP (Kerala)",
        FacilityCity: "chungatra",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8714,
        Pin: 560051,
        DispatchCenter: "Bangalore_Shantinagar_L (Karnataka)",
        OriginCenter: "Bangalore_Shantinagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8715,
        Pin: 680594,
        DispatchCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        OriginCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        FacilityCity: "Pazhayannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8716,
        Pin: 573218,
        DispatchCenter: "Hassan_Nagathavalli_I (Karnataka)",
        OriginCenter: "Hassan_Nagathavalli_I (Karnataka)",
        FacilityCity: "Hassan",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8717,
        Pin: 625305,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8718,
        Pin: 362001,
        DispatchCenter: "Junagadh_Dolatpara_I (Gujarat)",
        OriginCenter: "Junagadh_Dolatpara_I (Gujarat)",
        FacilityCity: "Junagadh",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8719,
        Pin: 465001,
        DispatchCenter: "Shajapur_Mahupura_D (Madhya Pradesh)",
        OriginCenter: "Shajapur_Mahupura_D (Madhya Pradesh)",
        FacilityCity: "Shajapur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8720,
        Pin: 515164,
        DispatchCenter: "Puttaprthi_Penukonda_D (Andhra Pradesh)",
        OriginCenter: "Puttaprthi_Penukonda_D (Andhra Pradesh)",
        FacilityCity: "Puttaparthi",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8721,
        Pin: 501404,
        DispatchCenter: "Hyderabad_Medchal1_L (Telangana)",
        OriginCenter: "Hyderabad_Medchal_GW (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8722,
        Pin: 242303,
        DispatchCenter: "Budaun_Dataganj_D (Uttar Pradesh)",
        OriginCenter: "Budaun_Dataganj_D (Uttar Pradesh)",
        FacilityCity: "Dataganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8723,
        Pin: 695081,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8724,
        Pin: 689616,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8725,
        Pin: 762016,
        DispatchCenter: "Sonepur_BrezeDPP_D (Orissa)",
        OriginCenter: "Sonepur_BrezeDPP_D (Orissa)",
        FacilityCity: "Sonepur-OR",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8726,
        Pin: 209651,
        DispatchCenter: "Chhibramau_Bhawalpur_D (Uttar Pradesh)",
        OriginCenter: "Chhibramau_Bhawalpur_D (Uttar Pradesh)",
        FacilityCity: "Mainpuri",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8727,
        Pin: 721435,
        DispatchCenter: "Belda_DntnRD_DPP (West Bengal)",
        OriginCenter: "Belda_DntnRD_DPP (West Bengal)",
        FacilityCity: "Belda",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8728,
        Pin: 765026,
        DispatchCenter: "Gunupur_Antarjholi_DPP (Orissa)",
        OriginCenter: "Gunupur_Antarjholi_DPP (Orissa)",
        FacilityCity: "Gunupur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8729,
        Pin: 563102,
        DispatchCenter: "Kolar_Jayanagara_D (Karnataka)",
        OriginCenter: "Kolar_Jayanagara_D (Karnataka)",
        FacilityCity: "Kolar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8730,
        Pin: 683517,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8731,
        Pin: 583201,
        DispatchCenter: "Hospet_Vijayanagara_D (Karnataka)",
        OriginCenter: "Hospet_Vijayanagara_D (Karnataka)",
        FacilityCity: "Hospet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8732,
        Pin: 787001,
        DispatchCenter: "NorthLakhimpur_Charaimoriya_D (Assam)",
        OriginCenter: "NorthLakhimpur_Charaimoriya_D (Assam)",
        FacilityCity: "North Lakhimpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8733,
        Pin: 636501,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8734,
        Pin: 628217,
        DispatchCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        OriginCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        FacilityCity: "Tiruchendur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8735,
        Pin: 229307,
        DispatchCenter: "Gauriganj_Jais_D (Uttar Pradesh)",
        OriginCenter: "Gauriganj_Jais_D (Uttar Pradesh)",
        FacilityCity: "Gauriganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8736,
        Pin: 700067,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8737,
        Pin: 110059,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8738,
        Pin: 688555,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8739,
        Pin: 764076,
        DispatchCenter: "Umerkote_Nabarangpur_DPP (Orissa)",
        OriginCenter: "Umerkote_Nabarangpur_DPP (Orissa)",
        FacilityCity: "Umerkote",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8740,
        Pin: 639006,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8741,
        Pin: 443401,
        DispatchCenter: "Nandura_Qureshinagar_DPP (Maharashtra)",
        OriginCenter: "Nandura_Qureshinagar_DPP (Maharashtra)",
        FacilityCity: "Nandura",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8742,
        Pin: 798626,
        DispatchCenter: "Noklak_Choklangan_D (Nagaland)",
        OriginCenter: "Noklak_Choklangan_D (Nagaland)",
        FacilityCity: "Noklak",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8743,
        Pin: 607803,
        DispatchCenter: "Neyveli_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Neyveli_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Neyveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8744,
        Pin: 505002,
        DispatchCenter: "KarimNagar_Alugunoor_I (Telangana)",
        OriginCenter: "KarimNagar_Alugunoor_I (Telangana)",
        FacilityCity: "Karim Nagar",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8745,
        Pin: 757084,
        DispatchCenter: "Baripada_KalikDPP_D (Orissa)",
        OriginCenter: "Baripada_KalikDPP_D (Orissa)",
        FacilityCity: "Baripada",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8746,
        Pin: 758017,
        DispatchCenter: "Kendujhar_Jhumpura_D (Orissa)",
        OriginCenter: "Kendujhar_Jhumpura_D (Orissa)",
        FacilityCity: "Kendujhar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8747,
        Pin: 611111,
        DispatchCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        OriginCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        FacilityCity: "Nagapattinam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8748,
        Pin: 827012,
        DispatchCenter: "Bokaro_Chas_D (Jharkhand)",
        OriginCenter: "Bokaro_Chas_D (Jharkhand)",
        FacilityCity: "Bokaro",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8749,
        Pin: 761037,
        DispatchCenter: "Chikiti_Bhlasingi_D (Orissa)",
        OriginCenter: "Chikiti_Bhlasingi_D (Orissa)",
        FacilityCity: "Chikiti",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8750,
        Pin: 307031,
        DispatchCenter: "Pindwara_AjariChwk_DPP (Rajasthan)",
        OriginCenter: "Pindwara_AjariChwk_DPP (Rajasthan)",
        FacilityCity: "Pindwara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8751,
        Pin: 249145,
        DispatchCenter: "Tehri_Chamba_D (Uttarakhand)",
        OriginCenter: "Tehri_Chamba_D (Uttarakhand)",
        FacilityCity: "Tehri",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8752,
        Pin: 331506,
        DispatchCenter: "Sujangarh_Salasar_D (Rajasthan)",
        OriginCenter: "Sujangarh_Salasar_D (Rajasthan)",
        FacilityCity: "Sujangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8753,
        Pin: 835206,
        DispatchCenter: "ChainpurJH_Premnagar_D (Jharkhand)",
        OriginCenter: "ChainpurJH_Premnagar_D (Jharkhand)",
        FacilityCity: "ChainpurJH",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8754,
        Pin: 560094,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8755,
        Pin: 370204,
        DispatchCenter: "Gandhidham_BharatNagar_I (Gujarat)",
        OriginCenter: "Gandhidham_BharatNagar_I (Gujarat)",
        FacilityCity: "Gandhidham",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 8756,
        Pin: 570006,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8757,
        Pin: 224159,
        DispatchCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        OriginCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        FacilityCity: "Jalalpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8758,
        Pin: 574224,
        DispatchCenter: "Belthangady_UjreMnRd_DPP (Karnataka)",
        OriginCenter: "Belthangady_UjreMnRd_DPP (Karnataka)",
        FacilityCity: "Belthangady",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8759,
        Pin: 509311,
        DispatchCenter: "Narayanpet_Ashoknagar_D (Telangana)",
        OriginCenter: "Narayanpet_Ashoknagar_D (Telangana)",
        FacilityCity: "Narayanpet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8760,
        Pin: 524312,
        DispatchCenter: "Udayagiri_Msjidcmplx_D (Andhra Pradesh)",
        OriginCenter: "Udayagiri_Msjidcmplx_D (Andhra Pradesh)",
        FacilityCity: "Udayagiri",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8761,
        Pin: 533293,
        DispatchCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        OriginCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        FacilityCity: "Rajahmundry",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8762,
        Pin: 415640,
        DispatchCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        OriginCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        FacilityCity: "Khed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8763,
        Pin: 627101,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8764,
        Pin: 122012,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8765,
        Pin: 673543,
        DispatchCenter: "Vadakara_Veerancheri_D (Kerala)",
        OriginCenter: "Vadakara_Veerancheri_D (Kerala)",
        FacilityCity: "Vadakara",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8766,
        Pin: 334003,
        DispatchCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        OriginCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        FacilityCity: "Bikaner",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8767,
        Pin: 785683,
        DispatchCenter: "Teok_Wardno3_D (Assam)",
        OriginCenter: "Teok_Wardno3_D (Assam)",
        FacilityCity: "Teok",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8768,
        Pin: 203403,
        DispatchCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        OriginCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        FacilityCity: "Siyana",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8769,
        Pin: 361004,
        DispatchCenter: "Jamnagar_Chela_L (Gujarat)",
        OriginCenter: "Jamnagar_Chela_L (Gujarat)",
        FacilityCity: "Jamnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8770,
        Pin: 695611,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8771,
        Pin: 531034,
        DispatchCenter: "Chodavaram_Narasayyapeta_D (Andhra Pradesh)",
        OriginCenter: "Chodavaram_Narasayyapeta_D (Andhra Pradesh)",
        FacilityCity: "Chodavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8772,
        Pin: 263001,
        DispatchCenter: "Nainital_Malital_D (Uttarakhand)",
        OriginCenter: "Nainital_Malital_D (Uttarakhand)",
        FacilityCity: "Nainital",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8773,
        Pin: 811202,
        DispatchCenter: "Munger_Kaura_D (Bihar)",
        OriginCenter: "Munger_Kaura_D (Bihar)",
        FacilityCity: "Munger",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8774,
        Pin: 466111,
        DispatchCenter: "Bhopal_Sarvdharmcol_D (Madhya Pradesh)",
        OriginCenter: "Bhopal_Sarvdharmcol_D (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 8775,
        Pin: 209734,
        DispatchCenter: "Kannauj_Tirwaganj_D (Uttar Pradesh)",
        OriginCenter: "Kannauj_Tirwaganj_D (Uttar Pradesh)",
        FacilityCity: "Kannauj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8776,
        Pin: 636458,
        DispatchCenter: "Mettur_Samballi_D (Tamil Nadu)",
        OriginCenter: "Mettur_Samballi_D (Tamil Nadu)",
        FacilityCity: "Mettur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8777,
        Pin: 202127,
        DispatchCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        OriginCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8778,
        Pin: 534303,
        DispatchCenter: "Kovvur_Samisragudem_D (Andhra Pradesh)",
        OriginCenter: "Kovvur_Samisragudem_D (Andhra Pradesh)",
        FacilityCity: "Kovvur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8779,
        Pin: 500046,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8780,
        Pin: 623317,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8781,
        Pin: 573119,
        DispatchCenter: "Tiptur_Chmundeshwri_D (Karnataka)",
        OriginCenter: "Tiptur_Chmundeshwri_D (Karnataka)",
        FacilityCity: "Tiptur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8782,
        Pin: 631605,
        DispatchCenter: "Kanchipuram_Molachur_D (Tamil Nadu)",
        OriginCenter: "Kanchipuram_Molachur_D (Tamil Nadu)",
        FacilityCity: "Kanchipuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8783,
        Pin: 743234,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8784,
        Pin: 400039,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8785,
        Pin: 815357,
        DispatchCenter: "Madhupur_Sitarmrd_D (Jharkhand)",
        OriginCenter: "Madhupur_Sitarmrd_D (Jharkhand)",
        FacilityCity: "Madhupur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8786,
        Pin: 190011,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8787,
        Pin: 322214,
        DispatchCenter: "Lalsot_Ward34_D (Rajasthan)",
        OriginCenter: "Lalsot_Ward34_D (Rajasthan)",
        FacilityCity: "Lalsot",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8788,
        Pin: 713425,
        DispatchCenter: "Nabadwip_Srirmpur_D (West Bengal)",
        OriginCenter: "Nabadwip_Srirmpur_D (West Bengal)",
        FacilityCity: "Nabadwip",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8789,
        Pin: 182204,
        DispatchCenter: "Kishtwar_Sangrambhata_D (Jammu & Kashmir)",
        OriginCenter: "Kishtwar_Sangrambhata_D (Jammu & Kashmir)",
        FacilityCity: "Kishtwar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8790,
        Pin: 177220,
        DispatchCenter: "Tahliwal_Central_D (Himachal Pradesh)",
        OriginCenter: "Tahliwal_Central_D (Himachal Pradesh)",
        FacilityCity: "Tahliwal",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8791,
        Pin: 628251,
        DispatchCenter: "Eral_Nazareth_D (Tamil Nadu)",
        OriginCenter: "Eral_Nazareth_D (Tamil Nadu)",
        FacilityCity: "Eral",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8792,
        Pin: 388340,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8793,
        Pin: 560001,
        DispatchCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        OriginCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8794,
        Pin: 415617,
        DispatchCenter: "Ganpatipule_Rahataghar_DPP (Maharashtra)",
        OriginCenter: "Ganpatipule_Rahataghar_DPP (Maharashtra)",
        FacilityCity: "Ganpatipule",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8795,
        Pin: 573131,
        DispatchCenter: "Chnnryptna_MysoreRd_D (Karnataka)",
        OriginCenter: "Chnnryptna_MysoreRd_D (Karnataka)",
        FacilityCity: "Channarayapatna",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8796,
        Pin: 562102,
        DispatchCenter: "Kolar_Vemagal_D (Karnataka)",
        OriginCenter: "Kolar_Vemagal_D (Karnataka)",
        FacilityCity: "Chintamani",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8797,
        Pin: 700014,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8798,
        Pin: 431007,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8799,
        Pin: 802183,
        DispatchCenter: "Brahmapur_Shahpur_D (Bihar)",
        OriginCenter: "Brahmapur_Shahpur_D (Bihar)",
        FacilityCity: "Brahmapur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8800,
        Pin: 533250,
        DispatchCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        OriginCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        FacilityCity: "Razole",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8801,
        Pin: 680018,
        DispatchCenter: "Thrissur_Pottore_D (Kerala)",
        OriginCenter: "Thrissur_Pottore_D (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8802,
        Pin: 176209,
        DispatchCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        OriginCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        FacilityCity: "Kangra",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8803,
        Pin: 148102,
        DispatchCenter: "Barnala_Bhadaur_D (Punjab)",
        OriginCenter: "Barnala_Bhadaur_D (Punjab)",
        FacilityCity: "Bhadaur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8804,
        Pin: 141117,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8805,
        Pin: 560111,
        DispatchCenter: "Bangalore_Temp1_L (Karnataka)",
        OriginCenter: "Bangalore_Temp1_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8806,
        Pin: 384261,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 8807,
        Pin: 243401,
        DispatchCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        OriginCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        FacilityCity: "Bareilly",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8808,
        Pin: 313903,
        DispatchCenter: "Sarada_Kachhota_DPP (Rajasthan)",
        OriginCenter: "Sarada_Kachhota_DPP (Rajasthan)",
        FacilityCity: "Sarada",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8809,
        Pin: 800010,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8810,
        Pin: 134012,
        DispatchCenter: "Ambala_Mandli_D (Haryana)",
        OriginCenter: "Ambala_Mandli_D (Haryana)",
        FacilityCity: "Ambala",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8811,
        Pin: 521124,
        DispatchCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        OriginCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        FacilityCity: "Gudivada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8812,
        Pin: 641043,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8813,
        Pin: 682511,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8814,
        Pin: 688531,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8815,
        Pin: 413607,
        DispatchCenter: "Nilanga_Panchalcolony_D (Maharashtra)",
        OriginCenter: "Nilanga_Panchalcolony_D (Maharashtra)",
        FacilityCity: "Nilanga",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8816,
        Pin: 442916,
        DispatchCenter: "Chandrapur_Gadchandur_DPP (Maharashtra)",
        OriginCenter: "Chandrapur_Gadchandur_DPP (Maharashtra)",
        FacilityCity: "Chandrapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8817,
        Pin: 517370,
        DispatchCenter: "BKothakota_RTCStop_D (Andhra Pradesh)",
        OriginCenter: "BKothakota_RTCStop_D (Andhra Pradesh)",
        FacilityCity: "B Kothakota",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8818,
        Pin: 245408,
        DispatchCenter: "Hapur_Sanjaycolony_D (Uttar Pradesh)",
        OriginCenter: "Hapur_Sanjaycolony_D (Uttar Pradesh)",
        FacilityCity: "Hapur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8819,
        Pin: 625020,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8820,
        Pin: 203409,
        DispatchCenter: "Bulndshahr_Ymunpurm_D (Uttar Pradesh)",
        OriginCenter: "Bulndshahr_Ymunpurm_D (Uttar Pradesh)",
        FacilityCity: "Bulandshahr",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8821,
        Pin: 140507,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8822,
        Pin: 283121,
        DispatchCenter: "Kheragarh_Shivmndr_D (Uttar Pradesh)",
        OriginCenter: "Kheragarh_Shivmndr_D (Uttar Pradesh)",
        FacilityCity: "Kheragarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8823,
        Pin: 638301,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8824,
        Pin: 140108,
        DispatchCenter: "Rupnagar_Towercolony_D (Punjab)",
        OriginCenter: "Rupnagar_Towercolony_D (Punjab)",
        FacilityCity: "Rupnagar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8825,
        Pin: 574103,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8826,
        Pin: 721656,
        DispatchCenter: "Bajkul_Kismat_D (West Bengal)",
        OriginCenter: "Bajkul_Kismat_D (West Bengal)",
        FacilityCity: "Bajkul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8827,
        Pin: 301030,
        DispatchCenter: "Alwar_TuleraRoad_I (Rajasthan)",
        OriginCenter: "Alwar_TuleraRoad_I (Rajasthan)",
        FacilityCity: "Alwar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8828,
        Pin: 695582,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8829,
        Pin: 500076,
        DispatchCenter: "Hyderabad_HemaNagar_L (Telangana)",
        OriginCenter: "Hyderabad_HemaNagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8830,
        Pin: 388230,
        DispatchCenter: "Umreth_PltinumPlza_DPP (Gujarat)",
        OriginCenter: "Umreth_PltinumPlza_DPP (Gujarat)",
        FacilityCity: "Umreth",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 8831,
        Pin: 209881,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8832,
        Pin: 695547,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8833,
        Pin: 421403,
        DispatchCenter: "Murbad_Shirgaon_D (Maharashtra)",
        OriginCenter: "Murbad_Shirgaon_D (Maharashtra)",
        FacilityCity: "Murbad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8834,
        Pin: 523320,
        DispatchCenter: "Markapur_VidyaNgr_D (Andhra Pradesh)",
        OriginCenter: "Markapur_VidyaNgr_D (Andhra Pradesh)",
        FacilityCity: "Markapur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8835,
        Pin: 173214,
        DispatchCenter: "Solan_Deothi_I (Himachal Pradesh)",
        OriginCenter: "Solan_Deothi_I (Himachal Pradesh)",
        FacilityCity: "Solan",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8836,
        Pin: 400602,
        DispatchCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8837,
        Pin: 314026,
        DispatchCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        OriginCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        FacilityCity: "Sagwara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8838,
        Pin: 532148,
        DispatchCenter: "Rajam_Varalaxminagar_D (Andhra Pradesh)",
        OriginCenter: "Rajam_Varalaxminagar_D (Andhra Pradesh)",
        FacilityCity: "Rajam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8839,
        Pin: 285196,
        DispatchCenter: "Orai_Ajnari_D (Uttar Pradesh)",
        OriginCenter: "Orai_Ajnari_D (Uttar Pradesh)",
        FacilityCity: "Orai",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8840,
        Pin: 416411,
        DispatchCenter: "KMahankal_KM_D (Maharashtra)",
        OriginCenter: "KMahankal_KM_D (Maharashtra)",
        FacilityCity: "Kavathe Mahankal",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8841,
        Pin: 848205,
        DispatchCenter: "Singhia_Bithan2_DPP (Bihar)",
        OriginCenter: "Singhia_Bithan2_DPP (Bihar)",
        FacilityCity: "Singhia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8842,
        Pin: 713156,
        DispatchCenter: "Guskhara_DignagarRd_D (West Bengal)",
        OriginCenter: "Guskhara_DignagarRd_D (West Bengal)",
        FacilityCity: "Guskhara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8843,
        Pin: 177024,
        DispatchCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        OriginCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        FacilityCity: "Jahu",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8844,
        Pin: 485774,
        DispatchCenter: "Maihar_Harnampur_D (Madhya Pradesh)",
        OriginCenter: "Maihar_Harnampur_D (Madhya Pradesh)",
        FacilityCity: "Maihar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 8845,
        Pin: 518501,
        DispatchCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        OriginCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        FacilityCity: "Nandyal",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8846,
        Pin: 713336,
        DispatchCenter: "Asansol_Addacolony_D (West Bengal)",
        OriginCenter: "Asansol_Addacolony_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8847,
        Pin: 503223,
        DispatchCenter: "Armoor_Mamidipillay_D (Telangana)",
        OriginCenter: "Armoor_Mamidipillay_D (Telangana)",
        FacilityCity: "Armoor",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8848,
        Pin: 324003,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8849,
        Pin: 790105,
        DispatchCenter: "Tawang_Jang_D (Arunachal Pradesh)",
        OriginCenter: "Tawang_Jang_D (Arunachal Pradesh)",
        FacilityCity: "Tawang",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8850,
        Pin: 609303,
        DispatchCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        OriginCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        FacilityCity: "Karaikal",
        FacilityState: "Pondicherry",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8851,
        Pin: 574237,
        DispatchCenter: "Moodbidri_Central_D (Karnataka)",
        OriginCenter: "Moodbidri_Central_D (Karnataka)",
        FacilityCity: "Moodabidri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8852,
        Pin: 370627,
        DispatchCenter: "Dayapar_MaaCmplx_DPP (Gujarat)",
        OriginCenter: "Dayapar_MaaCmplx_DPP (Gujarat)",
        FacilityCity: "Dayapar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8853,
        Pin: 518553,
        DispatchCenter: "Chagalamarri_TeluguGanga_DPP (Andhra Pradesh)",
        OriginCenter: "Chagalamarri_TeluguGanga_DPP (Andhra Pradesh)",
        FacilityCity: "Chagalamarri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8854,
        Pin: 638660,
        DispatchCenter: "Palladam_Balajinagar_D (Tamil Nadu)",
        OriginCenter: "Palladam_Balajinagar_D (Tamil Nadu)",
        FacilityCity: "Palladam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8855,
        Pin: 583212,
        DispatchCenter: "Hagaribommanahalli_Sadashiva_D (Karnataka)",
        OriginCenter: "Hagaribommanahalli_Sadashiva_D (Karnataka)",
        FacilityCity: "Hagaribommanahalli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8856,
        Pin: 605203,
        DispatchCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        OriginCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        FacilityCity: "Viluppuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8857,
        Pin: 360450,
        DispatchCenter: "Upleta_KolkiRd_DPP (Gujarat)",
        OriginCenter: "Upleta_KolkiRd_DPP (Gujarat)",
        FacilityCity: "Upleta",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 8858,
        Pin: 534123,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8859,
        Pin: 248171,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8860,
        Pin: 829113,
        DispatchCenter: "Phusro_RhmgjDPP_D (Jharkhand)",
        OriginCenter: "Phusro_RhmgjDPP_D (Jharkhand)",
        FacilityCity: "Phusro",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8861,
        Pin: 691330,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8862,
        Pin: 152132,
        DispatchCenter: "Abohar_DaulatPura_D (Punjab)",
        OriginCenter: "Abohar_DaulatPura_D (Punjab)",
        FacilityCity: "Abohar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8863,
        Pin: 277216,
        DispatchCenter: "Bairia_Bhagwanpur_DPP (Uttar Pradesh)",
        OriginCenter: "Bairia_Bhagwanpur_DPP (Uttar Pradesh)",
        FacilityCity: "Bariya",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8864,
        Pin: 518001,
        DispatchCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        OriginCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8865,
        Pin: 535558,
        DispatchCenter: "Bobbili_Aguruvidi_D (Andhra Pradesh)",
        OriginCenter: "Bobbili_Aguruvidi_D (Andhra Pradesh)",
        FacilityCity: "Bobbili",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8866,
        Pin: 144306,
        DispatchCenter: "Dasuya_Hajipur_D (Punjab)",
        OriginCenter: "Dasuya_Hajipur_D (Punjab)",
        FacilityCity: "Dasuya",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8867,
        Pin: 686033,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8868,
        Pin: 462004,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8869,
        Pin: 331504,
        DispatchCenter: "Ratangarh_Goldencolony_D (Rajasthan)",
        OriginCenter: "Ratangarh_Goldencolony_D (Rajasthan)",
        FacilityCity: "Ratangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8870,
        Pin: 571601,
        DispatchCenter: "Krishnarajngr_VlmkiNgr_D (Karnataka)",
        OriginCenter: "Krishnarajngr_VlmkiNgr_D (Karnataka)",
        FacilityCity: "Krishnarajngr",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8871,
        Pin: 642119,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8872,
        Pin: 631105,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8873,
        Pin: 191121,
        DispatchCenter: "Srinagar_Ladhoo_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Ladhoo_H (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8874,
        Pin: 577233,
        DispatchCenter: "Shimoga_Eshwariah_I (Karnataka)",
        OriginCenter: "Shimoga_Eshwariah_I (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8875,
        Pin: 123027,
        DispatchCenter: "MahendragarhHR_Khaira_D (Haryana)",
        OriginCenter: "MahendragarhHR_Khaira_D (Haryana)",
        FacilityCity: "Mahendragarh-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8876,
        Pin: 423110,
        DispatchCenter: "Chandwad_MarketYrd_DPP (Maharashtra)",
        OriginCenter: "Chandwad_MarketYrd_DPP (Maharashtra)",
        FacilityCity: "Chandwad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8877,
        Pin: 341511,
        DispatchCenter: "Merta_Kalru_DPP (Rajasthan)",
        OriginCenter: "Merta_Kalru_DPP (Rajasthan)",
        FacilityCity: "Merta",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8878,
        Pin: 623004,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8879,
        Pin: 441001,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Chikhali_L (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8880,
        Pin: 521101,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8881,
        Pin: 271201,
        DispatchCenter: "Itiathok_Birmapur_D (Uttar Pradesh)",
        OriginCenter: "Itiathok_Birmapur_D (Uttar Pradesh)",
        FacilityCity: "Itia Thok",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8882,
        Pin: 712414,
        DispatchCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        OriginCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        FacilityCity: "Tarkeshwar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8883,
        Pin: 560057,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8884,
        Pin: 500556,
        DispatchCenter: "Hyderabad_Medchal1_L (Telangana)",
        OriginCenter: "Hyderabad_Medchal_GW (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8885,
        Pin: 422401,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8886,
        Pin: 643223,
        DispatchCenter: "Gudalur_Devarshola_DPP (Tamil Nadu)",
        OriginCenter: "Gudalur_Devarshola_DPP (Tamil Nadu)",
        FacilityCity: "Gudalur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8887,
        Pin: 342801,
        DispatchCenter: "Balotra_Ward45_D (Rajasthan)",
        OriginCenter: "Balotra_Ward45_D (Rajasthan)",
        FacilityCity: "Balotra",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8888,
        Pin: 284203,
        DispatchCenter: "Chirgaon_RamnagarRoad_D (Uttar Pradesh)",
        OriginCenter: "Chirgaon_RamnagarRoad_D (Uttar Pradesh)",
        FacilityCity: "Chirgaon",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8889,
        Pin: 413725,
        DispatchCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        OriginCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        FacilityCity: "Shrirampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8890,
        Pin: 325214,
        DispatchCenter: "Itawa_GentaRd_DPP (Rajasthan)",
        OriginCenter: "Itawa_GentaRd_DPP (Rajasthan)",
        FacilityCity: "Itawa",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8891,
        Pin: 682013,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8892,
        Pin: 231309,
        DispatchCenter: "Mirzapur_Lalganj_D (Uttar Pradesh)",
        OriginCenter: "Mirzapur_Lalganj_D (Uttar Pradesh)",
        FacilityCity: "Mirzapur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8893,
        Pin: 441110,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8894,
        Pin: 383341,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8895,
        Pin: 415309,
        DispatchCenter: "Vita_Adarshnagar_D (Maharashtra)",
        OriginCenter: "Vita_Adarshnagar_D (Maharashtra)",
        FacilityCity: "Vita",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8896,
        Pin: 403517,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8897,
        Pin: 686679,
        DispatchCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        OriginCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        FacilityCity: "Muvattupuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8898,
        Pin: 627752,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8899,
        Pin: 313801,
        DispatchCenter: "Udaipur_Tidi_D (Rajasthan)",
        OriginCenter: "Udaipur_Tidi_D (Rajasthan)",
        FacilityCity: "Udaipur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8900,
        Pin: 341022,
        DispatchCenter: "Nagaur_Jantacomplex_D (Rajasthan)",
        OriginCenter: "Nagaur_Jantacomplex_D (Rajasthan)",
        FacilityCity: "Nagaur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8901,
        Pin: 792130,
        DispatchCenter: "Khonsa_EngineerColony_D (Arunachal Pradesh)",
        OriginCenter: "Khonsa_EngineerColony_D (Arunachal Pradesh)",
        FacilityCity: "Khonsa",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8902,
        Pin: 144023,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8903,
        Pin: 388308,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8904,
        Pin: 848502,
        DispatchCenter: "MohiuddinNagar_Manjha_D (Bihar)",
        OriginCenter: "MohiuddinNagar_Manjha_D (Bihar)",
        FacilityCity: "Mohiuddin Nagar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8905,
        Pin: 431810,
        DispatchCenter: "Kinwat_Mahurrd_D (Maharashtra)",
        OriginCenter: "Kinwat_Mahurrd_D (Maharashtra)",
        FacilityCity: "Kinwat",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8906,
        Pin: 609803,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8907,
        Pin: 625123,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8908,
        Pin: 689516,
        DispatchCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        OriginCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8909,
        Pin: 741316,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8910,
        Pin: 600036,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8911,
        Pin: 591107,
        DispatchCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        OriginCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        FacilityCity: "Gokak",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8912,
        Pin: 444002,
        DispatchCenter: "Akola_Midcphase3_H (Maharashtra)",
        OriginCenter: "Akola_Midcphase3_H (Maharashtra)",
        FacilityCity: "Akola",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8913,
        Pin: 584103,
        DispatchCenter: "Raichur_Wadloor_I (Karnataka)",
        OriginCenter: "Raichur_Wadloor_I (Karnataka)",
        FacilityCity: "Raichur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8914,
        Pin: 721172,
        DispatchCenter: "Kolaghat_Central_DPP_2 (West Bengal)",
        OriginCenter: "Kolaghat_Central_DPP_2 (West Bengal)",
        FacilityCity: "Kolaghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8915,
        Pin: 711410,
        DispatchCenter: "Howrah_Temp_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8916,
        Pin: 445109,
        DispatchCenter: "Ghatanji_ShivajiChowk_DPP (Maharashtra)",
        OriginCenter: "Ghatanji_ShivajiChowk_DPP (Maharashtra)",
        FacilityCity: "Ghatanji",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8917,
        Pin: 516152,
        DispatchCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8918,
        Pin: 403705,
        DispatchCenter: "Sanvordem_PntmlDPP_D (Goa)",
        OriginCenter: "Sanvordem_PntmlDPP_D (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8919,
        Pin: 461115,
        DispatchCenter: "Itarsi_AwnNgr_D (Madhya Pradesh)",
        OriginCenter: "Itarsi_AwnNgr_D (Madhya Pradesh)",
        FacilityCity: "Itarsi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8920,
        Pin: 630206,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8921,
        Pin: 506146,
        DispatchCenter: "Ghanpur_Palakrty_D (Telangana)",
        OriginCenter: "Ghanpur_Palakrty_D (Telangana)",
        FacilityCity: "Ghanpur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8922,
        Pin: 758002,
        DispatchCenter: "Kendujhar_Jamunalia_I (Orissa)",
        OriginCenter: "Kendujhar_Jamunalia_I (Orissa)",
        FacilityCity: "Kendujhar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8923,
        Pin: 185121,
        DispatchCenter: "Poonch_Potha_D (Jammu & Kashmir)",
        OriginCenter: "Poonch_Potha_D (Jammu & Kashmir)",
        FacilityCity: "Poonch",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8924,
        Pin: 695602,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8925,
        Pin: 621312,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8926,
        Pin: 673103,
        DispatchCenter: "Vadakara_Orkkatteri_D (Kerala)",
        OriginCenter: "Vadakara_Orkkatteri_D (Kerala)",
        FacilityCity: "Vadakara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8927,
        Pin: 465337,
        DispatchCenter: "Shujalpur_JNSCollege_D (Madhya Pradesh)",
        OriginCenter: "Shujalpur_JNSCollege_D (Madhya Pradesh)",
        FacilityCity: "Shujalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 8928,
        Pin: 637501,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8929,
        Pin: 250345,
        DispatchCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        OriginCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        FacilityCity: "Baraut",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8930,
        Pin: 532501,
        DispatchCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        OriginCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        FacilityCity: "Parvathipuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8931,
        Pin: 524412,
        DispatchCenter: "Gudur_MalvyaNagar_D (Andhra Pradesh)",
        OriginCenter: "Gudur_MalvyaNagar_D (Andhra Pradesh)",
        FacilityCity: "Gudur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8932,
        Pin: 192129,
        DispatchCenter: "Anantnag_QaziBagh_DPP (Jammu & Kashmir)",
        OriginCenter: "Anantnag_QaziBagh_DPP (Jammu & Kashmir)",
        FacilityCity: "Anantnag",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8933,
        Pin: 522426,
        DispatchCenter: "Macherla_Ward15_D (Andhra Pradesh)",
        OriginCenter: "Macherla_Ward15_D (Andhra Pradesh)",
        FacilityCity: "Macherla",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8934,
        Pin: 225415,
        DispatchCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        OriginCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        FacilityCity: "Dariyabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8935,
        Pin: 576221,
        DispatchCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        OriginCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8936,
        Pin: 276127,
        DispatchCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8937,
        Pin: 813104,
        DispatchCenter: "Punsia_Hansdiha_D (Bihar)",
        OriginCenter: "Punsia_Hansdiha_D (Bihar)",
        FacilityCity: "Punsia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8938,
        Pin: 411050,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8939,
        Pin: 176043,
        DispatchCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        OriginCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        FacilityCity: "Jahu",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8940,
        Pin: 600119,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8941,
        Pin: 521003,
        DispatchCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        OriginCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        FacilityCity: "Machilipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8942,
        Pin: 152101,
        DispatchCenter: "Gidderbaha_GuruNanakNagar_D (Punjab)",
        OriginCenter: "Gidderbaha_GuruNanakNagar_D (Punjab)",
        FacilityCity: "Giddarbaha",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8943,
        Pin: 743515,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8944,
        Pin: 173234,
        DispatchCenter: "Waknaghat_Rwli_DPP (Himachal Pradesh)",
        OriginCenter: "Waknaghat_Rwli_DPP (Himachal Pradesh)",
        FacilityCity: "Waknaghat",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8945,
        Pin: 333001,
        DispatchCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        OriginCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        FacilityCity: "Jhunjhunu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8946,
        Pin: 590008,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8947,
        Pin: 521356,
        DispatchCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        OriginCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        FacilityCity: "Gudivada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8948,
        Pin: 423206,
        DispatchCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        OriginCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        FacilityCity: "Malegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8949,
        Pin: 452014,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8950,
        Pin: 175143,
        DispatchCenter: "Manali_Prini_D (Himachal Pradesh)",
        OriginCenter: "Manali_Prini_D (Himachal Pradesh)",
        FacilityCity: "Manali",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8951,
        Pin: 627361,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8952,
        Pin: 785689,
        DispatchCenter: "Sonari_Nakachari_D (Assam)",
        OriginCenter: "Sonari_Nakachari_D (Assam)",
        FacilityCity: "Sonari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 8953,
        Pin: 766103,
        DispatchCenter: "Junagarh_Dharmagarh1_D (Orissa)",
        OriginCenter: "Junagarh_Dharmagarh1_D (Orissa)",
        FacilityCity: "Junagarh-OR",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8954,
        Pin: 788931,
        DispatchCenter: "Umrangso_Dimahasao_DPP (Assam)",
        OriginCenter: "Umrangso_Dimahasao_DPP (Assam)",
        FacilityCity: "Umrangso",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8955,
        Pin: 209101,
        DispatchCenter: "Akbarpur_NationalHospital_D (Uttar Pradesh)",
        OriginCenter: "Akbarpur_NationalHospital_D (Uttar Pradesh)",
        FacilityCity: "Akbarpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8956,
        Pin: 412413,
        DispatchCenter: 37043.00011574074,
        OriginCenter: 37043.00011574074,
        FacilityCity: "Junnar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8957,
        Pin: 678104,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8958,
        Pin: 516223,
        DispatchCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        OriginCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        FacilityCity: "Proddatur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8959,
        Pin: 673314,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8960,
        Pin: 624007,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8961,
        Pin: 180015,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8962,
        Pin: 636117,
        DispatchCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        OriginCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        FacilityCity: "Attur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8963,
        Pin: 395004,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 8964,
        Pin: 243123,
        DispatchCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        OriginCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        FacilityCity: "Bareilly",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8965,
        Pin: 695605,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 8966,
        Pin: 741235,
        DispatchCenter: "Naihati_Kalyani_DPP (West Bengal)",
        OriginCenter: "Naihati_Kalyani_DPP (West Bengal)",
        FacilityCity: "Kalyani",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8967,
        Pin: 574102,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8968,
        Pin: 683586,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8969,
        Pin: 630001,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8970,
        Pin: 762015,
        DispatchCenter: "Sonepur_BrezeDPP_D (Orissa)",
        OriginCenter: "Sonepur_BrezeDPP_D (Orissa)",
        FacilityCity: "Sonepur-OR",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8971,
        Pin: 134011,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8972,
        Pin: 562149,
        DispatchCenter: "Bangalore_Koralur_L (Karnataka)",
        OriginCenter: "Bangalore_Soukyaroad_GW (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8973,
        Pin: 410205,
        DispatchCenter: "Nagothane_Balsaital_D (Maharashtra)",
        OriginCenter: "Nagothane_Balsaital_D (Maharashtra)",
        FacilityCity: "Nagothane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 8974,
        Pin: 721131,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8975,
        Pin: 584136,
        DispatchCenter: "Raichur_Wadloor_I (Karnataka)",
        OriginCenter: "Raichur_Wadloor_I (Karnataka)",
        FacilityCity: "Raichur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8976,
        Pin: 134201,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8977,
        Pin: 689703,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 8978,
        Pin: 312601,
        DispatchCenter: "Nimbahera_Chotisadri_DPP (Rajasthan)",
        OriginCenter: "Nimbahera_Chotisadri_DPP (Rajasthan)",
        FacilityCity: "Nimbahera",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8979,
        Pin: 486338,
        DispatchCenter: "Deotalab_Mauganj_D (Madhya Pradesh)",
        OriginCenter: "Deotalab_Mauganj_D (Madhya Pradesh)",
        FacilityCity: "Deotalab",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 8980,
        Pin: 610107,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8981,
        Pin: 425112,
        DispatchCenter: "Chopda_CollegeRd_DPP (Maharashtra)",
        OriginCenter: "Chopda_CollegeRd_DPP (Maharashtra)",
        FacilityCity: "Chopda",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 8982,
        Pin: 627118,
        DispatchCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        OriginCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        FacilityCity: "Thisayanvilai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8983,
        Pin: 805108,
        DispatchCenter: "Pakribarwan_Warisaliganj_D (Bihar)",
        OriginCenter: "Pakribarwan_Warisaliganj_D (Bihar)",
        FacilityCity: "Pakribarwan",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8984,
        Pin: 475005,
        DispatchCenter: "Dabra_Pichhoreroad_D (Madhya Pradesh)",
        OriginCenter: "Dabra_Pichhoreroad_D (Madhya Pradesh)",
        FacilityCity: "Dabra",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 8985,
        Pin: 515863,
        DispatchCenter: "Rayadurgam_Nesepet_D (Andhra Pradesh)",
        OriginCenter: "Rayadurgam_Nesepet_D (Andhra Pradesh)",
        FacilityCity: "Rayadurgam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8986,
        Pin: 825336,
        DispatchCenter: "Kedla_Ghatotand_D (Jharkhand)",
        OriginCenter: "Kedla_Ghatotand_D (Jharkhand)",
        FacilityCity: "Kedla",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8987,
        Pin: 587116,
        DispatchCenter: "Kolhar_ShivajiCircle_D (Karnataka)",
        OriginCenter: "Kolhar_ShivajiCircle_D (Karnataka)",
        FacilityCity: "Kolhar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 8988,
        Pin: 811104,
        DispatchCenter: "Barbigha_Khetalpura_D (Bihar)",
        OriginCenter: "Barbigha_Khetalpura_D (Bihar)",
        FacilityCity: "Barbigha",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8989,
        Pin: 802213,
        DispatchCenter: "Dinara_Wardno7_D (Bihar)",
        OriginCenter: "Dinara_Wardno7_D (Bihar)",
        FacilityCity: "Dinara",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 8990,
        Pin: 229301,
        DispatchCenter: "Bacchawaran_BhuyianTemple_D (Uttar Pradesh)",
        OriginCenter: "Bacchawaran_BhuyianTemple_D (Uttar Pradesh)",
        FacilityCity: "Bacchawaran",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 8991,
        Pin: 312615,
        DispatchCenter: "PratapgarhRJ_Badabag_DPP (Rajasthan)",
        OriginCenter: "PratapgarhRJ_Badabag_DPP (Rajasthan)",
        FacilityCity: "Pratapgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8992,
        Pin: 700059,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 8993,
        Pin: 246455,
        DispatchCenter: "Tharali_Kulsari_D (Uttarakhand)",
        OriginCenter: "Tharali_Kulsari_D (Uttarakhand)",
        FacilityCity: "Tharali",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 8994,
        Pin: 152026,
        DispatchCenter: "Muktsar_BathindaRd_D (Punjab)",
        OriginCenter: "Muktsar_BathindaRd_D (Punjab)",
        FacilityCity: "Muktsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 8995,
        Pin: 582209,
        DispatchCenter: "Rona_GadagRD_D (Karnataka)",
        OriginCenter: "Rona_GadagRD_D (Karnataka)",
        FacilityCity: "Rona",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8996,
        Pin: 577531,
        DispatchCenter: "Channagiri_Holalkere_D (Karnataka)",
        OriginCenter: "Channagiri_Holalkere_D (Karnataka)",
        FacilityCity: "Channagiri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 8997,
        Pin: 791103,
        DispatchCenter: "Boleng_Pangin_D (Arunachal Pradesh)",
        OriginCenter: "Boleng_Pangin_D (Arunachal Pradesh)",
        FacilityCity: "Boleng",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 8998,
        Pin: 335041,
        DispatchCenter: "PadampurRJ_Gillcolony_D (Rajasthan)",
        OriginCenter: "PadampurRJ_Gillcolony_D (Rajasthan)",
        FacilityCity: "PadampurRJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 8999,
        Pin: 503188,
        DispatchCenter: "Bodhan_VamshiSchl_D (Telangana)",
        OriginCenter: "Bodhan_VamshiSchl_D (Telangana)",
        FacilityCity: "Bodhan",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9000,
        Pin: 628306,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9001,
        Pin: 608304,
        DispatchCenter: "Lalpet_Kattumannarkoil_D (Tamil Nadu)",
        OriginCenter: "Lalpet_Kattumannarkoil_D (Tamil Nadu)",
        FacilityCity: "Lalpet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9002,
        Pin: 176045,
        DispatchCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        OriginCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        FacilityCity: "Jahu",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9003,
        Pin: 421307,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9004,
        Pin: 517236,
        DispatchCenter: "Madanapalle_Gurramkonda_D (Andhra Pradesh)",
        OriginCenter: "Madanapalle_Gurramkonda_D (Andhra Pradesh)",
        FacilityCity: "Madanapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9005,
        Pin: 365421,
        DispatchCenter: "Dhasa_Nolipara_D (Gujarat)",
        OriginCenter: "Dhasa_Nolipara_D (Gujarat)",
        FacilityCity: "Dhasa",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9006,
        Pin: 571186,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9007,
        Pin: 205142,
        DispatchCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        OriginCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        FacilityCity: "Shikohabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9008,
        Pin: 673635,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9009,
        Pin: 212657,
        DispatchCenter: "AmbujNagar_ShvrjprRd_D (Uttar Pradesh)",
        OriginCenter: "AmbujNagar_ShvrjprRd_D (Uttar Pradesh)",
        FacilityCity: "Ambuj Nagar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9010,
        Pin: 392161,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9011,
        Pin: 230302,
        DispatchCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        OriginCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        FacilityCity: "Patti-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9012,
        Pin: 388710,
        DispatchCenter: "Balasinor_Alnoor_DPP (Gujarat)",
        OriginCenter: "Balasinor_Alnoor_DPP (Gujarat)",
        FacilityCity: "Balasinor",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9013,
        Pin: 723213,
        DispatchCenter: "Jhalda_GMukrDPP_D (West Bengal)",
        OriginCenter: "Jhalda_GMukrDPP_D (West Bengal)",
        FacilityCity: "Jhalda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9014,
        Pin: 132054,
        DispatchCenter: "Karnal_Kutail_H (Haryana)",
        OriginCenter: "Karnal_Kutail_H (Haryana)",
        FacilityCity: "Karnal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9015,
        Pin: 683578,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9016,
        Pin: 523263,
        DispatchCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        OriginCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        FacilityCity: "Ongole",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9017,
        Pin: 759107,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9018,
        Pin: 224189,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9019,
        Pin: 471625,
        DispatchCenter: "Khajuraho_Sevagram_DPP (Madhya Pradesh)",
        OriginCenter: "Khajuraho_Sevagram_DPP (Madhya Pradesh)",
        FacilityCity: "Khajuraho",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9020,
        Pin: 141301,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9021,
        Pin: 602025,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9022,
        Pin: 635901,
        DispatchCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        OriginCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        FacilityCity: "Tiruppattur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9023,
        Pin: 206129,
        DispatchCenter: "Auraiya_Brahmngr_D (Uttar Pradesh)",
        OriginCenter: "Auraiya_Brahmngr_D (Uttar Pradesh)",
        FacilityCity: "Auraiya",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9024,
        Pin: 507202,
        DispatchCenter: "Madhira_Sunderaiahnagar_D (Telangana)",
        OriginCenter: "Madhira_Sunderaiahnagar_D (Telangana)",
        FacilityCity: "Madhira",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9025,
        Pin: 503124,
        DispatchCenter: "Yellareddy_Teachercolony_D (Telangana)",
        OriginCenter: "Yellareddy_Teachercolony_D (Telangana)",
        FacilityCity: "Yellareddy",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9026,
        Pin: 172103,
        DispatchCenter: "Jeori_Tapri_D (Himachal Pradesh)",
        OriginCenter: "Jeori_Tapri_D (Himachal Pradesh)",
        FacilityCity: "Jeori",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9027,
        Pin: 690532,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9028,
        Pin: 700031,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9029,
        Pin: 577219,
        DispatchCenter: "Honnali_TMroad_D (Karnataka)",
        OriginCenter: "Honnali_TMroad_D (Karnataka)",
        FacilityCity: "Honnali",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9030,
        Pin: 483222,
        DispatchCenter: "Sihora_Mahavirchk_D (Madhya Pradesh)",
        OriginCenter: "Sihora_Mahavirchk_D (Madhya Pradesh)",
        FacilityCity: "Sihora",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 9031,
        Pin: 833203,
        DispatchCenter: "Jagannathpur_Hatgamhariya_D (Jharkhand)",
        OriginCenter: "Jagannathpur_Hatgamhariya_D (Jharkhand)",
        FacilityCity: "Jagannathpur",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9032,
        Pin: 306022,
        DispatchCenter: "Khinwara_Temple_DPP (Rajasthan)",
        OriginCenter: "Khinwara_Temple_DPP (Rajasthan)",
        FacilityCity: "Khinwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9033,
        Pin: 517504,
        DispatchCenter: "Tirupati_Balajicolony_D (Andhra Pradesh)",
        OriginCenter: "Tirupati_Balajicolony_D (Andhra Pradesh)",
        FacilityCity: "Tirupati",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9034,
        Pin: 502311,
        DispatchCenter: "Pregnapur_Tharakaramanagar_D (Telangana)",
        OriginCenter: "Pregnapur_Tharakaramanagar_D (Telangana)",
        FacilityCity: "Pregnapur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9035,
        Pin: 683520,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9036,
        Pin: 741508,
        DispatchCenter: "Hanskhali_Ashrampara_D (West Bengal)",
        OriginCenter: "Hanskhali_Ashrampara_D (West Bengal)",
        FacilityCity: "Hanskhali",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9037,
        Pin: 363655,
        DispatchCenter: "Dhrol_PGVCLOfc_D (Gujarat)",
        OriginCenter: "Dhrol_PGVCLOfc_D (Gujarat)",
        FacilityCity: "Dhrol",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9038,
        Pin: 581324,
        DispatchCenter: "Karwar_Sadashivgad_D (Karnataka)",
        OriginCenter: "Karwar_Sadashivgad_D (Karnataka)",
        FacilityCity: "Karwar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9039,
        Pin: 245411,
        DispatchCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        OriginCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        FacilityCity: "Siyana",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9040,
        Pin: 110606,
        DispatchCenter: "Delhi_RailYardRd_L (Delhi)",
        OriginCenter: "Delhi_RailYardRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9041,
        Pin: 517507,
        DispatchCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        OriginCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        FacilityCity: "Tirupati",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9042,
        Pin: 574113,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9043,
        Pin: 394110,
        DispatchCenter: "Kosamba_Palod_D (Gujarat)",
        OriginCenter: "Kosamba_Palod_D (Gujarat)",
        FacilityCity: "Kosamba",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9044,
        Pin: 344024,
        DispatchCenter: "Balotra_Ward45_D (Rajasthan)",
        OriginCenter: "Balotra_Ward45_D (Rajasthan)",
        FacilityCity: "Balotra",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9045,
        Pin: 571477,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9046,
        Pin: 301703,
        DispatchCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        OriginCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        FacilityCity: "Neemrana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9047,
        Pin: 227814,
        DispatchCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        OriginCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        FacilityCity: "Kumarganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9048,
        Pin: 507201,
        DispatchCenter: "Madhira_Sunderaiahnagar_D (Telangana)",
        OriginCenter: "Madhira_Sunderaiahnagar_D (Telangana)",
        FacilityCity: "Madhira",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9049,
        Pin: 621703,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9050,
        Pin: 127312,
        DispatchCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        OriginCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        FacilityCity: "Charkhi Dadri",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9051,
        Pin: 630610,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9052,
        Pin: 143503,
        DispatchCenter: "Batala_Simbel_D (Punjab)",
        OriginCenter: "Batala_Simbel_D (Punjab)",
        FacilityCity: "Batala",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9053,
        Pin: 495442,
        DispatchCenter: "Ratanpur_GandhiNagar_DPP (Chhattisgarh)",
        OriginCenter: "Ratanpur_GandhiNagar_DPP (Chhattisgarh)",
        FacilityCity: "Ratanpur",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9054,
        Pin: 637410,
        DispatchCenter: "Rasipurm_Knrpati_D (Tamil Nadu)",
        OriginCenter: "Rasipurm_Knrpati_D (Tamil Nadu)",
        FacilityCity: "Rasipuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9055,
        Pin: 302020,
        DispatchCenter: "Jaipur_RingRoad_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9056,
        Pin: 770023,
        DispatchCenter: "Rajgangpur_Central_D_2 (Orissa)",
        OriginCenter: "Rajgangpur_Central_D_2 (Orissa)",
        FacilityCity: "Rajgangpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9057,
        Pin: 303012,
        DispatchCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        OriginCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9058,
        Pin: 412802,
        DispatchCenter: "Khandala_Shrwal_D (Maharashtra)",
        OriginCenter: "Khandala_Shrwal_D (Maharashtra)",
        FacilityCity: "Khandala",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9059,
        Pin: 605754,
        DispatchCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        OriginCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        FacilityCity: "Thirukoilure",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9060,
        Pin: 786192,
        DispatchCenter: "Tinsukia_Nokhroygaon2_D (Assam)",
        OriginCenter: "Tinsukia_Nokhroygaon2_D (Assam)",
        FacilityCity: "Tinsukia",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9061,
        Pin: 754011,
        DispatchCenter: "Adaspur_Uradha_DPP (Orissa)",
        OriginCenter: "Adaspur_Uradha_DPP (Orissa)",
        FacilityCity: "Adaspur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9062,
        Pin: 762109,
        DispatchCenter: "Balliguda_Patakhanda_DPP (Orissa)",
        OriginCenter: "Balliguda_Patakhanda_DPP (Orissa)",
        FacilityCity: "Balliguda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9063,
        Pin: 761135,
        DispatchCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        OriginCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        FacilityCity: "Bhanjanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9064,
        Pin: 518510,
        DispatchCenter: "Sirvella_Sirivelmitta_DPP (Andhra Pradesh)",
        OriginCenter: "Sirvella_Sirivelmitta_DPP (Andhra Pradesh)",
        FacilityCity: "Sirvella",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9065,
        Pin: 609605,
        DispatchCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        OriginCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        FacilityCity: "Karaikal",
        FacilityState: "Pondicherry",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9066,
        Pin: 824232,
        DispatchCenter: "Gaya_Fatehpur_D (Bihar)",
        OriginCenter: "Gaya_Fatehpur_D (Bihar)",
        FacilityCity: "Gaya",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9067,
        Pin: 360012,
        DispatchCenter: "Rajkot_Amargadh_H (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9068,
        Pin: 625532,
        DispatchCenter: "Usilampatti_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Usilampatti_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Usilampatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9069,
        Pin: 841209,
        DispatchCenter: "Ekma_Hansrajpr_D (Bihar)",
        OriginCenter: "Ekma_Hansrajpr_D (Bihar)",
        FacilityCity: "Ekma",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9070,
        Pin: 799114,
        DispatchCenter: "Udaipur_Chanban_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Udaipur-TR",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9071,
        Pin: 522259,
        DispatchCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        OriginCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        FacilityCity: "Repalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9072,
        Pin: 782143,
        DispatchCenter: "Kaliabor_Hatiakhowa_DPP (Assam)",
        OriginCenter: "Kaliabor_Hatiakhowa_DPP (Assam)",
        FacilityCity: "Kaliabor",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9073,
        Pin: 394652,
        DispatchCenter: "Songadh_Vyara_D (Gujarat)",
        OriginCenter: "Songadh_Vyara_D (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9074,
        Pin: 509382,
        DispatchCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        OriginCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        FacilityCity: "Mahabubnagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9075,
        Pin: 202138,
        DispatchCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        OriginCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9076,
        Pin: 626139,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9077,
        Pin: 600076,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9078,
        Pin: 848501,
        DispatchCenter: "MohiuddinNagar_Manjha_D (Bihar)",
        OriginCenter: "MohiuddinNagar_Manjha_D (Bihar)",
        FacilityCity: "Mohiuddin Nagar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9079,
        Pin: 323303,
        DispatchCenter: "Rawatbhata_HarijanBasti_D (Rajasthan)",
        OriginCenter: "Rawatbhata_HarijanBasti_D (Rajasthan)",
        FacilityCity: "Rawatbhata",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9080,
        Pin: 333011,
        DispatchCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        OriginCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        FacilityCity: "Jhunjhunu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9081,
        Pin: 328023,
        DispatchCenter: "Kheragarh_Shivmndr_D (Uttar Pradesh)",
        OriginCenter: "Kheragarh_Shivmndr_D (Uttar Pradesh)",
        FacilityCity: "Kheragarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9082,
        Pin: 561209,
        DispatchCenter: "Bagepalli_Chikballapur_D (Karnataka)",
        OriginCenter: "Bagepalli_Chikballapur_D (Karnataka)",
        FacilityCity: "Bagepalli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9083,
        Pin: 600023,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9084,
        Pin: 754229,
        DispatchCenter: "Kendrapara_Chhagharia_D (Orissa)",
        OriginCenter: "Kendrapara_Chhagharia_D (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9085,
        Pin: 743165,
        DispatchCenter: "Naihati_Rajendrapur_DPP (West Bengal)",
        OriginCenter: "Naihati_Rajendrapur_DPP (West Bengal)",
        FacilityCity: "Naihati",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9086,
        Pin: 636902,
        DispatchCenter: "Uthangarai_Mathurpathi_D (Tamil Nadu)",
        OriginCenter: "Uthangarai_Mathurpathi_D (Tamil Nadu)",
        FacilityCity: "Uthangarai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9087,
        Pin: 784172,
        DispatchCenter: "Gohpur_Central_D_1 (Assam)",
        OriginCenter: "Gohpur_Central_D_1 (Assam)",
        FacilityCity: "Gohpur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9088,
        Pin: 614209,
        DispatchCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        OriginCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9089,
        Pin: 800015,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9090,
        Pin: 222139,
        DispatchCenter: "Shahganj_Kaudiya_D (Uttar Pradesh)",
        OriginCenter: "Shahganj_Kaudiya_D (Uttar Pradesh)",
        FacilityCity: "Shahganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9091,
        Pin: 332001,
        DispatchCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        OriginCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9092,
        Pin: 444303,
        DispatchCenter: "Khamgaon_Wamannagar_DPP (Maharashtra)",
        OriginCenter: "Khamgaon_Wamannagar_DPP (Maharashtra)",
        FacilityCity: "Khamgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9093,
        Pin: 370435,
        DispatchCenter: "Mandvi_Ashpura_DPP (Gujarat)",
        OriginCenter: "Mandvi_Ashpura_DPP (Gujarat)",
        FacilityCity: "Mandvi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9094,
        Pin: 370240,
        DispatchCenter: "Gandhidham_BharatNagar_I (Gujarat)",
        OriginCenter: "Gandhidham_BharatNagar_I (Gujarat)",
        FacilityCity: "Gandhidham",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9095,
        Pin: 334803,
        DispatchCenter: "Nokha_Ward17_D (Rajasthan)",
        OriginCenter: "Nokha_Ward17_D (Rajasthan)",
        FacilityCity: "Nokha",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9096,
        Pin: 441606,
        DispatchCenter: "Gondia_Mundipar_I (Maharashtra)",
        OriginCenter: "Gondia_Mundipar_I (Maharashtra)",
        FacilityCity: "Gondia",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9097,
        Pin: 577423,
        DispatchCenter: "Thirthahalli_Ripponpet_DPP (Karnataka)",
        OriginCenter: "Thirthahalli_Ripponpet_DPP (Karnataka)",
        FacilityCity: "Thirthahalli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9098,
        Pin: 847308,
        DispatchCenter: "Benipatti_Sarisab_D (Bihar)",
        OriginCenter: "Benipatti_Sarisab_D (Bihar)",
        FacilityCity: "Benipatti",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9099,
        Pin: 600120,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9100,
        Pin: 700155,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9101,
        Pin: 523105,
        DispatchCenter: "Kandukur_SanthoshNagar_D (Andhra Pradesh)",
        OriginCenter: "Kandukur_SanthoshNagar_D (Andhra Pradesh)",
        FacilityCity: "Kandukur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9102,
        Pin: 402305,
        DispatchCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        OriginCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        FacilityCity: "Mahad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9103,
        Pin: 736158,
        DispatchCenter: "Sitai_Khamarsitai_DPP (West Bengal)",
        OriginCenter: "Sitai_Khamarsitai_DPP (West Bengal)",
        FacilityCity: "Sitai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9104,
        Pin: 413703,
        DispatchCenter: "Shrigonda_Belwandi_D (Maharashtra)",
        OriginCenter: "Shrigonda_Belwandi_D (Maharashtra)",
        FacilityCity: "Shrigonda",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9105,
        Pin: 503164,
        DispatchCenter: "Padmajiwadi_Gandhari_D (Telangana)",
        OriginCenter: "Padmajiwadi_Gandhari_D (Telangana)",
        FacilityCity: "Padmajiwadi",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9106,
        Pin: 841316,
        DispatchCenter: "Chhapra_Ramnagar_I (Bihar)",
        OriginCenter: "Chhapra_Ramnagar_I (Bihar)",
        FacilityCity: "Chhapra",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9107,
        Pin: 795001,
        DispatchCenter: "Imphal_Takyel_I (Manipur)",
        OriginCenter: "Imphal_Takyel_I (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9108,
        Pin: 627151,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9109,
        Pin: 621001,
        DispatchCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        OriginCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        FacilityCity: "Thuraiyur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9110,
        Pin: 591234,
        DispatchCenter: "Ugar_GopiHotel_D (Karnataka)",
        OriginCenter: "Ugar_GopiHotel_D (Karnataka)",
        FacilityCity: "Ugar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9111,
        Pin: 676504,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9112,
        Pin: 400081,
        DispatchCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        OriginCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        FacilityCity: "Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9113,
        Pin: 122229,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9114,
        Pin: 636306,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9115,
        Pin: 203411,
        DispatchCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        OriginCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        FacilityCity: "Siyana",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9116,
        Pin: 444806,
        DispatchCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        OriginCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        FacilityCity: "Paratwada",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9117,
        Pin: 532263,
        DispatchCenter: "Sompeta_PostOffice_DPP (Andhra Pradesh)",
        OriginCenter: "Sompeta_PostOffice_DPP (Andhra Pradesh)",
        FacilityCity: "Sompeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9118,
        Pin: 431523,
        DispatchCenter: "Ambajogai_Bansilalnagar_D (Maharashtra)",
        OriginCenter: "Ambajogai_Bansilalnagar_D (Maharashtra)",
        FacilityCity: "Ambajogai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9119,
        Pin: 225303,
        DispatchCenter: "FatehpurUP_PatelNagar_D (Uttar Pradesh)",
        OriginCenter: "FatehpurUP_PatelNagar_D (Uttar Pradesh)",
        FacilityCity: "Fatehpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9120,
        Pin: 305006,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9121,
        Pin: 682311,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9122,
        Pin: 452005,
        DispatchCenter: "Indore_BarodaArjun_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9123,
        Pin: 509210,
        DispatchCenter: "Narayanpet_Ashoknagar_D (Telangana)",
        OriginCenter: "Narayanpet_Ashoknagar_D (Telangana)",
        FacilityCity: "Narayanpet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9124,
        Pin: 781019,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9125,
        Pin: 210429,
        DispatchCenter: "Rath_Saina_D (Uttar Pradesh)",
        OriginCenter: "Rath_Saina_D (Uttar Pradesh)",
        FacilityCity: "Rath",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9126,
        Pin: 689513,
        DispatchCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        OriginCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9127,
        Pin: 721420,
        DispatchCenter: "Contai_Egra_D (West Bengal)",
        OriginCenter: "Contai_Egra_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9128,
        Pin: 506367,
        DispatchCenter: "Cherial_Vchreni_D (Telangana)",
        OriginCenter: "Cherial_Vchreni_D (Telangana)",
        FacilityCity: "Cherial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9129,
        Pin: 682027,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9130,
        Pin: 403108,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9131,
        Pin: 303123,
        DispatchCenter: "Renwal_Wardno4_D (Rajasthan)",
        OriginCenter: "Renwal_Wardno4_D (Rajasthan)",
        FacilityCity: "Renwal",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9132,
        Pin: 796036,
        DispatchCenter: "Aizawl_Mualveng_D (Mizoram)",
        OriginCenter: "Aizawl_Mualveng_D (Mizoram)",
        FacilityCity: "Aizawl",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9133,
        Pin: 713334,
        DispatchCenter: "Asansol_Addacolony_D (West Bengal)",
        OriginCenter: "Asansol_Addacolony_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9134,
        Pin: 492099,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9135,
        Pin: 123501,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Rewari_Chirhara_D (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9136,
        Pin: 571123,
        DispatchCenter: "Gundlupet_ChamrajNagar_D (Karnataka)",
        OriginCenter: "Gundlupet_ChamrajNagar_D (Karnataka)",
        FacilityCity: "Gundlupet",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9137,
        Pin: 784502,
        DispatchCenter: "Thelamara_NilachalPath_D (Assam)",
        OriginCenter: "Thelamara_NilachalPath_D (Assam)",
        FacilityCity: "Thelamara",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9138,
        Pin: 826002,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9139,
        Pin: 481990,
        DispatchCenter: "ShahpuraMP_Kundam_D (Madhya Pradesh)",
        OriginCenter: "ShahpuraMP_Kundam_D (Madhya Pradesh)",
        FacilityCity: "ShahpuraMP",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9140,
        Pin: 176085,
        DispatchCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        OriginCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        FacilityCity: "Palampur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9141,
        Pin: 786012,
        DispatchCenter: "Dibrugarh_Mohanbari_H (Assam)",
        OriginCenter: "Dibrugarh_Mohanbari_H (Assam)",
        FacilityCity: "Dibrugarh",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9142,
        Pin: 854316,
        DispatchCenter: "Forbesganj_Jummanchowk_D (Bihar)",
        OriginCenter: "Forbesganj_Jummanchowk_D (Bihar)",
        FacilityCity: "Forbesganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9143,
        Pin: 301018,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9144,
        Pin: 835227,
        DispatchCenter: "Torpa_PowerHouse_DPP (Jharkhand)",
        OriginCenter: "Torpa_PowerHouse_DPP (Jharkhand)",
        FacilityCity: "Torpa",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9145,
        Pin: 631203,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9146,
        Pin: 504292,
        DispatchCenter: "Kaghaznagar_Bhatpalle_D (Telangana)",
        OriginCenter: "Kaghaznagar_Bhatpalle_D (Telangana)",
        FacilityCity: "Kaghaznagar",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9147,
        Pin: 301427,
        DispatchCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        OriginCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        FacilityCity: "Neemrana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9148,
        Pin: 110074,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9149,
        Pin: 642205,
        DispatchCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        OriginCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        FacilityCity: "Udumalaipettai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9150,
        Pin: 642113,
        DispatchCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        OriginCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        FacilityCity: "Udumalaipettai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9151,
        Pin: 416012,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9152,
        Pin: 623528,
        DispatchCenter: "Muthukulathur_Selvanygprm_D (Tamil Nadu)",
        OriginCenter: "Muthukulathur_Selvanygprm_D (Tamil Nadu)",
        FacilityCity: "Muthukulathur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9153,
        Pin: 742156,
        DispatchCenter: "Nowda_Amtala_DPP (West Bengal)",
        OriginCenter: "Nowda_Amtala_DPP (West Bengal)",
        FacilityCity: "Nowda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9154,
        Pin: 230401,
        DispatchCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        OriginCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        FacilityCity: "Pratapgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9155,
        Pin: 638202,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9156,
        Pin: 251309,
        DispatchCenter: "Budhana_BhagatSinghPark_D (Uttar Pradesh)",
        OriginCenter: "Budhana_BhagatSinghPark_D (Uttar Pradesh)",
        FacilityCity: "Budhana",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9157,
        Pin: 756100,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9158,
        Pin: 482021,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9159,
        Pin: 412211,
        DispatchCenter: "Shirur_RanjangaonMIDC_L (Maharashtra)",
        OriginCenter: "Shirur_RanjangaonMIDC_L (Maharashtra)",
        FacilityCity: "Shirur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9160,
        Pin: 680512,
        DispatchCenter: "Thrissur_Chavakkad_D (Kerala)",
        OriginCenter: "Thrissur_Chavakkad_D (Kerala)",
        FacilityCity: "Guruvayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9161,
        Pin: 678701,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9162,
        Pin: 522265,
        DispatchCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        OriginCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        FacilityCity: "Repalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9163,
        Pin: 824113,
        DispatchCenter: "Daudnagar_Tarar_D (Bihar)",
        OriginCenter: "Daudnagar_Tarar_D (Bihar)",
        FacilityCity: "Daudnagar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9164,
        Pin: 225003,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9165,
        Pin: 396001,
        DispatchCenter: "Valsad_LimbaChwk_D (Gujarat)",
        OriginCenter: "Valsad_LimbaChwk_D (Gujarat)",
        FacilityCity: "Valsad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9166,
        Pin: 577401,
        DispatchCenter: "Sagara_Hotel_D (Karnataka)",
        OriginCenter: "Sagara_Hotel_D (Karnataka)",
        FacilityCity: "Sagara",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9167,
        Pin: 624201,
        DispatchCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        OriginCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        FacilityCity: "Vadipatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9168,
        Pin: 400206,
        DispatchCenter: "Mumbai_Ariwali_L (Maharashtra)",
        OriginCenter: "Mumbai_Ariwali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9169,
        Pin: 711401,
        DispatchCenter: "Howrah_Temp_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9170,
        Pin: 322029,
        DispatchCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        OriginCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        FacilityCity: "Sawai Madhopur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9171,
        Pin: 370655,
        DispatchCenter: "Naliya_Jlramcpx_D (Gujarat)",
        OriginCenter: "Naliya_Jlramcpx_D (Gujarat)",
        FacilityCity: "Naliya",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9172,
        Pin: 782141,
        DispatchCenter: "Nagaon_Bangthai_H (Assam)",
        OriginCenter: "Nagaon_Bangthai_H (Assam)",
        FacilityCity: "Nagaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9173,
        Pin: 263148,
        DispatchCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        OriginCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        FacilityCity: "Rudrapur",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9174,
        Pin: 244251,
        DispatchCenter: "Amroha_Kishangarh_D (Uttar Pradesh)",
        OriginCenter: "Amroha_Kishangarh_D (Uttar Pradesh)",
        FacilityCity: "Amroha",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9175,
        Pin: 425410,
        DispatchCenter: "Shahada_Untavad_D (Maharashtra)",
        OriginCenter: "Shahada_Untavad_D (Maharashtra)",
        FacilityCity: "Shahada",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9176,
        Pin: 627202,
        DispatchCenter: "Tirunelveli_BalabagyaNagar_D (Tamil Nadu)",
        OriginCenter: "Tirunelveli_BalabagyaNagar_D (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9177,
        Pin: 628301,
        DispatchCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        OriginCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        FacilityCity: "Tuticorin",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9178,
        Pin: 520040,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9179,
        Pin: 491228,
        DispatchCenter: "Balod_HaldharChowk_D (Chhattisgarh)",
        OriginCenter: "Balod_HaldharChowk_D (Chhattisgarh)",
        FacilityCity: "Balod",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9180,
        Pin: 686008,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9181,
        Pin: 676517,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9182,
        Pin: 152004,
        DispatchCenter: "Ferozepur_DC (Punjab)",
        OriginCenter: "Ferozepur_DC (Punjab)",
        FacilityCity: "Firozpur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9183,
        Pin: 736201,
        DispatchCenter: "Kumargram_GovHpt_DPP (West Bengal)",
        OriginCenter: "Kumargram_GovHpt_DPP (West Bengal)",
        FacilityCity: "Kumargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9184,
        Pin: 443403,
        DispatchCenter: "Nandura_Qureshinagar_DPP (Maharashtra)",
        OriginCenter: "Nandura_Qureshinagar_DPP (Maharashtra)",
        FacilityCity: "Nandura",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9185,
        Pin: 626119,
        DispatchCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        OriginCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        FacilityCity: "Sivakasi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9186,
        Pin: 416626,
        DispatchCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        OriginCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        FacilityCity: "Malvan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9187,
        Pin: 781036,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9188,
        Pin: 767066,
        DispatchCenter: "Titilagarh_RSRDDPP_D (Orissa)",
        OriginCenter: "Titilagarh_RSRDDPP_D (Orissa)",
        FacilityCity: "Titilagarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9189,
        Pin: 110021,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9190,
        Pin: 829104,
        DispatchCenter: "Phusro_RhmgjDPP_D (Jharkhand)",
        OriginCenter: "Phusro_RhmgjDPP_D (Jharkhand)",
        FacilityCity: "Phusro",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9191,
        Pin: 781309,
        DispatchCenter: "Sarthebari_Tmlichp_D (Assam)",
        OriginCenter: "Sarthebari_Tmlichp_D (Assam)",
        FacilityCity: "Sarthebari",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9192,
        Pin: 382308,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9193,
        Pin: 484446,
        DispatchCenter: "Kotma_BaniyaTola_D (Madhya Pradesh)",
        OriginCenter: "Kotma_BaniyaTola_D (Madhya Pradesh)",
        FacilityCity: "Kotma",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 9194,
        Pin: 442903,
        DispatchCenter: "Chimur_KrushiBazar_DPP (Maharashtra)",
        OriginCenter: "Chimur_KrushiBazar_DPP (Maharashtra)",
        FacilityCity: "Chimur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9195,
        Pin: 754203,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9196,
        Pin: 841410,
        DispatchCenter: "Mashrakh_Benchapra_D (Bihar)",
        OriginCenter: "Mashrakh_Benchapra_D (Bihar)",
        FacilityCity: "Mashrakh",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9197,
        Pin: 625007,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9198,
        Pin: 600086,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9199,
        Pin: 443304,
        DispatchCenter: "Mehkar_Irrigationcol_DPP (Maharashtra)",
        OriginCenter: "Mehkar_Irrigationcol_DPP (Maharashtra)",
        FacilityCity: "Mehkar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9200,
        Pin: 752002,
        DispatchCenter: "Puri_Purussottamnagar_D (Orissa)",
        OriginCenter: "Puri_Purussottamnagar_D (Orissa)",
        FacilityCity: "Puri",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9201,
        Pin: 394540,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9202,
        Pin: 754082,
        DispatchCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        OriginCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        FacilityCity: "Chandikhol",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9203,
        Pin: 492010,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9204,
        Pin: 454446,
        DispatchCenter: "Manawar_MPEBOffice_DPP (Madhya Pradesh)",
        OriginCenter: "Manawar_MPEBOffice_DPP (Madhya Pradesh)",
        FacilityCity: "Manawar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 9205,
        Pin: 758047,
        DispatchCenter: "Champua_Sirapur_DPP (Orissa)",
        OriginCenter: "Champua_Sirapur_DPP (Orissa)",
        FacilityCity: "Champua",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9206,
        Pin: 576111,
        DispatchCenter: "Karkala_JodursteJ_D (Karnataka)",
        OriginCenter: "Karkala_JodursteJ_D (Karnataka)",
        FacilityCity: "Karkala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9207,
        Pin: 423702,
        DispatchCenter: "Gangapur_RehnaClny_DPP (Maharashtra)",
        OriginCenter: "Gangapur_RehnaClny_DPP (Maharashtra)",
        FacilityCity: "Gangapur-MH",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9208,
        Pin: 400024,
        DispatchCenter: "Mumbai_Chembur_L (Maharashtra)",
        OriginCenter: "Mumbai_Chembur_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9209,
        Pin: 122109,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9210,
        Pin: 765016,
        DispatchCenter: "Kalyansingpur_Talabal_DPP (Orissa)",
        OriginCenter: "Kalyansingpur_Talabal_DPP (Orissa)",
        FacilityCity: "Kalyansingpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9211,
        Pin: 388716,
        DispatchCenter: "Godhra_Soniwada_D (Gujarat)",
        OriginCenter: "Godhra_Soniwada_D (Gujarat)",
        FacilityCity: "Godhra",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9212,
        Pin: 422605,
        DispatchCenter: "Sangamner_Ksarwdi_D (Maharashtra)",
        OriginCenter: "Sangamner_Ksarwdi_D (Maharashtra)",
        FacilityCity: "Sangamner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9213,
        Pin: 680102,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9214,
        Pin: 852218,
        DispatchCenter: "Triveninganj_Ward16_D (Bihar)",
        OriginCenter: "Triveninganj_Ward16_D (Bihar)",
        FacilityCity: "Triveninganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9215,
        Pin: 411054,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9216,
        Pin: 604152,
        DispatchCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        OriginCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        FacilityCity: "Gingee",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9217,
        Pin: 562159,
        DispatchCenter: "Ramanagara_KunigalRd_D (Karnataka)",
        OriginCenter: "Ramanagara_KunigalRd_D (Karnataka)",
        FacilityCity: "Ramanagara",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9218,
        Pin: 602102,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9219,
        Pin: 673633,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9220,
        Pin: 625547,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9221,
        Pin: 585425,
        DispatchCenter: "Bidar_AdarshClny_D (Karnataka)",
        OriginCenter: "Bidar_AdarshClny_D (Karnataka)",
        FacilityCity: "Bidar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9222,
        Pin: 743399,
        DispatchCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        OriginCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        FacilityCity: "Dakshin Barasat",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9223,
        Pin: 445202,
        DispatchCenter: "Digras_Darwa_D (Maharashtra)",
        OriginCenter: "Digras_Darwa_D (Maharashtra)",
        FacilityCity: "Digras",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9224,
        Pin: 504101,
        DispatchCenter: "Bhainsa_Pipri_D (Telangana)",
        OriginCenter: "Bhainsa_Pipri_D (Telangana)",
        FacilityCity: "Bhainsa",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9225,
        Pin: 670675,
        DispatchCenter: "Mahe_Chokli_D (Kerala)",
        OriginCenter: "Mahe_Chokli_D (Kerala)",
        FacilityCity: "Mahe",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9226,
        Pin: 801103,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_PhulwariSharif_L (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9227,
        Pin: 629404,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9228,
        Pin: 506314,
        DispatchCenter: "Rayaparthi_Busstand_D (Telangana)",
        OriginCenter: "Rayaparthi_Busstand_D (Telangana)",
        FacilityCity: "Rayaparthi",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9229,
        Pin: 394240,
        DispatchCenter: "Bardoli_GopalNagar_D (Gujarat)",
        OriginCenter: "Bardoli_GopalNagar_D (Gujarat)",
        FacilityCity: "Bardoli",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9230,
        Pin: 503110,
        DispatchCenter: "Kamareddy_KPRcolony_D (Telangana)",
        OriginCenter: "Kamareddy_KPRcolony_D (Telangana)",
        FacilityCity: "Kamareddy",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9231,
        Pin: 486670,
        DispatchCenter: "Amiliya_Ward15_D (Madhya Pradesh)",
        OriginCenter: "Amiliya_Ward15_D (Madhya Pradesh)",
        FacilityCity: "Amiliya",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9232,
        Pin: 695123,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9233,
        Pin: 431006,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9234,
        Pin: 686681,
        DispatchCenter: "Perumbavoor_Kizhake_D (Kerala)",
        OriginCenter: "Perumbavoor_Kizhake_D (Kerala)",
        FacilityCity: "Perumbavoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9235,
        Pin: 247670,
        DispatchCenter: "Roorkee_Manglaur_D (Uttarakhand)",
        OriginCenter: "Roorkee_Manglaur_D (Uttarakhand)",
        FacilityCity: "Roorkee",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9236,
        Pin: 416408,
        DispatchCenter: "Tasgaon_Trimurticolony_D (Maharashtra)",
        OriginCenter: "Tasgaon_Trimurticolony_D (Maharashtra)",
        FacilityCity: "Tasgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9237,
        Pin: 621316,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9238,
        Pin: 491226,
        DispatchCenter: "Balod_HaldharChowk_D (Chhattisgarh)",
        OriginCenter: "Balod_HaldharChowk_D (Chhattisgarh)",
        FacilityCity: "Balod",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9239,
        Pin: 241503,
        DispatchCenter: "Miyanganj_FurkanMarket_DPP (Uttar Pradesh)",
        OriginCenter: "Miyanganj_FurkanMarket_DPP (Uttar Pradesh)",
        FacilityCity: "Miyanganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9240,
        Pin: 396415,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9241,
        Pin: 793113,
        DispatchCenter: "Marshillong_Mwsynrm_D (Meghalaya)",
        OriginCenter: "Marshillong_Mwsynrm_D (Meghalaya)",
        FacilityCity: "Marshillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9242,
        Pin: 321608,
        DispatchCenter: "Mahwa_Allapur_D (Rajasthan)",
        OriginCenter: "Mahwa_Allapur_D (Rajasthan)",
        FacilityCity: "Mahwa",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9243,
        Pin: 835221,
        DispatchCenter: "Khunti_JojoToli_DPP (Jharkhand)",
        OriginCenter: "Khunti_JojoToli_DPP (Jharkhand)",
        FacilityCity: "Khunti",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9244,
        Pin: 495668,
        DispatchCenter: "Champa_Sindhicolony_D (Chhattisgarh)",
        OriginCenter: "Champa_Sindhicolony_D (Chhattisgarh)",
        FacilityCity: "Champa",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9245,
        Pin: 852111,
        DispatchCenter: "Simrahi_Bazar_D (Bihar)",
        OriginCenter: "Simrahi_Bazar_D (Bihar)",
        FacilityCity: "Simrahi Bazar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9246,
        Pin: 400047,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9247,
        Pin: 522413,
        DispatchCenter: "Piduguralla_Rasoolpet_D (Andhra Pradesh)",
        OriginCenter: "Piduguralla_Rasoolpet_D (Andhra Pradesh)",
        FacilityCity: "Piduguralla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9248,
        Pin: 205263,
        DispatchCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        OriginCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        FacilityCity: "Mainpuri",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9249,
        Pin: 600039,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9250,
        Pin: 754202,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9251,
        Pin: 678101,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9252,
        Pin: 305811,
        DispatchCenter: "Kishangarh_AirportRd_D (Rajasthan)",
        OriginCenter: "Kishangarh_AirportRd_D (Rajasthan)",
        FacilityCity: "Kishangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9253,
        Pin: 394630,
        DispatchCenter: "Bardoli_GopalNagar_D (Gujarat)",
        OriginCenter: "Bardoli_GopalNagar_D (Gujarat)",
        FacilityCity: "Bardoli",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9254,
        Pin: 784525,
        DispatchCenter: "Udalguri_Milonpur_D (Assam)",
        OriginCenter: "Udalguri_Milonpur_D (Assam)",
        FacilityCity: "Mangaldoi",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9255,
        Pin: 560015,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9256,
        Pin: 462042,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9257,
        Pin: 801302,
        DispatchCenter: "Hilsa_Sryamndr_D (Bihar)",
        OriginCenter: "Hilsa_Sryamndr_D (Bihar)",
        FacilityCity: "Hilsa",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9258,
        Pin: 580031,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9259,
        Pin: 416419,
        DispatchCenter: "KMahankal_KM_D (Maharashtra)",
        OriginCenter: "KMahankal_KM_D (Maharashtra)",
        FacilityCity: "Kavathe Mahankal",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9260,
        Pin: 233306,
        DispatchCenter: "Aunrihar_JawahrNgr_D (Uttar Pradesh)",
        OriginCenter: "Aunrihar_JawahrNgr_D (Uttar Pradesh)",
        FacilityCity: "Aunrihar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9261,
        Pin: 413410,
        DispatchCenter: "Solapur_Kalasenagar_D (Maharashtra)",
        OriginCenter: "Solapur_Kalasenagar_D (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9262,
        Pin: 752003,
        DispatchCenter: "Puri_Purussottamnagar_D (Orissa)",
        OriginCenter: "Puri_Purussottamnagar_D (Orissa)",
        FacilityCity: "Puri",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9263,
        Pin: 250005,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9264,
        Pin: 854365,
        DispatchCenter: "Purnia_Kasba_H (Bihar)",
        OriginCenter: "Purnia_Kasba_H (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9265,
        Pin: 577601,
        DispatchCenter: "Davangere_Vidyanagara_D (Karnataka)",
        OriginCenter: "Davangere_Vidyanagara_D (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9266,
        Pin: 496338,
        DispatchCenter: "Jashpur_PuraniToli_D (Chhattisgarh)",
        OriginCenter: "Jashpur_PuraniToli_D (Chhattisgarh)",
        FacilityCity: "Jashpur Nagar",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9267,
        Pin: 495661,
        DispatchCenter: "Jaijaipur_BaradwarMarg_D (Chhattisgarh)",
        OriginCenter: "Jaijaipur_BaradwarMarg_D (Chhattisgarh)",
        FacilityCity: "Jaijaipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9268,
        Pin: 388160,
        DispatchCenter: "Anand_Borsad_D (Gujarat)",
        OriginCenter: "Anand_Borsad_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9269,
        Pin: 152025,
        DispatchCenter: "Muktsar_BathindaRd_D (Punjab)",
        OriginCenter: "Muktsar_BathindaRd_D (Punjab)",
        FacilityCity: "Muktsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9270,
        Pin: 313332,
        DispatchCenter: "DeogarhRJ_BapuNgr_DPP (Rajasthan)",
        OriginCenter: "DeogarhRJ_BapuNgr_DPP (Rajasthan)",
        FacilityCity: "DeogarhRJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9271,
        Pin: 585237,
        DispatchCenter: "Talikoti_GaneshNagar_D (Karnataka)",
        OriginCenter: "Talikoti_GaneshNagar_D (Karnataka)",
        FacilityCity: "Talikoti",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9272,
        Pin: 642110,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9273,
        Pin: 524202,
        DispatchCenter: "Kavali_Musunur_D (Andhra Pradesh)",
        OriginCenter: "Kavali_Musunur_D (Andhra Pradesh)",
        FacilityCity: "Kavali",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9274,
        Pin: 380015,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9275,
        Pin: 212212,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9276,
        Pin: 754200,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9277,
        Pin: 508284,
        DispatchCenter: "Choutuppal_Autonagar_D (Telangana)",
        OriginCenter: "Choutuppal_Autonagar_D (Telangana)",
        FacilityCity: "Choutuppal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9278,
        Pin: 735121,
        DispatchCenter: "Jalpaiguri_Gomostapara_D (West Bengal)",
        OriginCenter: "Jalpaiguri_Gomostapara_D (West Bengal)",
        FacilityCity: "Jalpaiguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9279,
        Pin: 781040,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9280,
        Pin: 497555,
        DispatchCenter: "Manendragarh_JKDRoad_D (Chhattisgarh)",
        OriginCenter: "Manendragarh_JKDRoad_D (Chhattisgarh)",
        FacilityCity: "Manendragarh",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9281,
        Pin: 333502,
        DispatchCenter: "Buhana_Puranabas_D (Rajasthan)",
        OriginCenter: "Buhana_Puranabas_D (Rajasthan)",
        FacilityCity: "Buhana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9282,
        Pin: 207246,
        DispatchCenter: "Ganjdundwara_CanalRd_D (Uttar Pradesh)",
        OriginCenter: "Ganjdundwara_CanalRd_D (Uttar Pradesh)",
        FacilityCity: "Ganjdundwara",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9283,
        Pin: 471501,
        DispatchCenter: "Chhatarpur_SagarRd_D (Madhya Pradesh)",
        OriginCenter: "Chhatarpur_SagarRd_D (Madhya Pradesh)",
        FacilityCity: "Chhatarpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 9284,
        Pin: 792056,
        DispatchCenter: "Bordumsa_LakhangPlace_D (Arunachal Pradesh)",
        OriginCenter: "Bordumsa_LakhangPlace_D (Arunachal Pradesh)",
        FacilityCity: "Bordumsa",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9285,
        Pin: 631001,
        DispatchCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9286,
        Pin: 249404,
        DispatchCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        OriginCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        FacilityCity: "Haridwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9287,
        Pin: 781370,
        DispatchCenter: "Nalbari_Chokbazar_D (Assam)",
        OriginCenter: "Nalbari_Chokbazar_D (Assam)",
        FacilityCity: "Nalbari",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9288,
        Pin: 690537,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9289,
        Pin: 630558,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9290,
        Pin: 571253,
        DispatchCenter: "Gonikoppal_Patelnagar_D (Karnataka)",
        OriginCenter: "Gonikoppal_Patelnagar_D (Karnataka)",
        FacilityCity: "Gonikoppal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9291,
        Pin: 623711,
        DispatchCenter: "Muthukulathur_Selvanygprm_D (Tamil Nadu)",
        OriginCenter: "Muthukulathur_Selvanygprm_D (Tamil Nadu)",
        FacilityCity: "Muthukulathur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9292,
        Pin: 670663,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9293,
        Pin: 454775,
        DispatchCenter: "Pithampur_Ambedkarnagar_L (Madhya Pradesh)",
        OriginCenter: "Pithampur_Ambedkarnagar_L (Madhya Pradesh)",
        FacilityCity: "Pithampur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9294,
        Pin: 781371,
        DispatchCenter: "Tihu_Barbari_D (Assam)",
        OriginCenter: "Tihu_Barbari_D (Assam)",
        FacilityCity: "Tihu",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9295,
        Pin: 391145,
        DispatchCenter: "Halol_DhavalPark_D (Gujarat)",
        OriginCenter: "Halol_DhavalPark_D (Gujarat)",
        FacilityCity: "Halol",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9296,
        Pin: 679594,
        DispatchCenter: "Edappal_Nellissery_D (Kerala)",
        OriginCenter: "Edappal_Nellissery_D (Kerala)",
        FacilityCity: "Edappal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9297,
        Pin: 457441,
        DispatchCenter: "Ratlam_MhowRd_D (Madhya Pradesh)",
        OriginCenter: "Ratlam_MhowRd_D (Madhya Pradesh)",
        FacilityCity: "Ratlam",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9298,
        Pin: 535124,
        DispatchCenter: "Rajam_Varalaxminagar_D (Andhra Pradesh)",
        OriginCenter: "Rajam_Varalaxminagar_D (Andhra Pradesh)",
        FacilityCity: "Rajam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9299,
        Pin: 425522,
        DispatchCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        OriginCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        FacilityCity: "Bhusawal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9300,
        Pin: 444802,
        DispatchCenter: "Amravati_Changapur_D (Maharashtra)",
        OriginCenter: "Amravati_Changapur_D (Maharashtra)",
        FacilityCity: "Amravati",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9301,
        Pin: 382012,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9302,
        Pin: 391450,
        DispatchCenter: "Vadodara_Tandalja_D (Gujarat)",
        OriginCenter: "Vadodara_Tandalja_D (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9303,
        Pin: 124506,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9304,
        Pin: 713166,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9305,
        Pin: 493773,
        DispatchCenter: "Dhamtari_VasiniWard_D (Chhattisgarh)",
        OriginCenter: "Dhamtari_VasiniWard_D (Chhattisgarh)",
        FacilityCity: "Dhamtari",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9306,
        Pin: 695035,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9307,
        Pin: 577160,
        DispatchCenter: "Mudigere_HesglDPP_D (Karnataka)",
        OriginCenter: "Mudigere_HesglDPP_D (Karnataka)",
        FacilityCity: "Mudigere",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9308,
        Pin: 721160,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9309,
        Pin: 516104,
        DispatchCenter: "Koduru_Jagadampalli_D (Andhra Pradesh)",
        OriginCenter: "Koduru_Jagadampalli_D (Andhra Pradesh)",
        FacilityCity: "Koduru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9310,
        Pin: 457340,
        DispatchCenter: "Jaora_AdityaNagar_D (Madhya Pradesh)",
        OriginCenter: "Jaora_AdityaNagar_D (Madhya Pradesh)",
        FacilityCity: "Jaora",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 9311,
        Pin: 628005,
        DispatchCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        OriginCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        FacilityCity: "Tuticorin",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9312,
        Pin: 344044,
        DispatchCenter: "Siwana_Natiyacolony_D (Rajasthan)",
        OriginCenter: "Siwana_Natiyacolony_D (Rajasthan)",
        FacilityCity: "Siwana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9313,
        Pin: 682006,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9314,
        Pin: 831016,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9315,
        Pin: 695146,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9316,
        Pin: 142003,
        DispatchCenter: "Moga_Duneke_I (Punjab)",
        OriginCenter: "Moga_Duneke_I (Punjab)",
        FacilityCity: "Moga",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9317,
        Pin: 577145,
        DispatchCenter: "Kadur_Ajjampura_D (Karnataka)",
        OriginCenter: "Kadur_Ajjampura_D (Karnataka)",
        FacilityCity: "Kadur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9318,
        Pin: 123502,
        DispatchCenter: "Rewari_Gopalchowk_D (Haryana)",
        OriginCenter: "Rewari_Gopalchowk_D (Haryana)",
        FacilityCity: "Rewari",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9319,
        Pin: 785622,
        DispatchCenter: "Golaghat_BaruaRd_D (Assam)",
        OriginCenter: "Golaghat_BaruaRd_D (Assam)",
        FacilityCity: "Golaghat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9320,
        Pin: 713155,
        DispatchCenter: "Panagarh_Kanksa_D (West Bengal)",
        OriginCenter: "Panagarh_Kanksa_D (West Bengal)",
        FacilityCity: "Panagarh",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9321,
        Pin: 628752,
        DispatchCenter: "Eral_Nazareth_D (Tamil Nadu)",
        OriginCenter: "Eral_Nazareth_D (Tamil Nadu)",
        FacilityCity: "Eral",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9322,
        Pin: 222148,
        DispatchCenter: "Kerakat_KaliMandirDPP_D (Uttar Pradesh)",
        OriginCenter: "Kerakat_KaliMandirDPP_D (Uttar Pradesh)",
        FacilityCity: "Kerakat",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9323,
        Pin: 476111,
        DispatchCenter: "Morena_Ambah_D (Madhya Pradesh)",
        OriginCenter: "Morena_Ambah_D (Madhya Pradesh)",
        FacilityCity: "Morena",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9324,
        Pin: 249301,
        DispatchCenter: "Pauri_Ghuret_DPP (Uttarakhand)",
        OriginCenter: "Pauri_Ghuret_DPP (Uttarakhand)",
        FacilityCity: "Pauri",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9325,
        Pin: 673579,
        DispatchCenter: "Pulpally_Adikolli_D (Kerala)",
        OriginCenter: "Pulpally_Adikolli_D (Kerala)",
        FacilityCity: "Pulpally",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9326,
        Pin: 635304,
        DispatchCenter: "Uthangarai_Mathurpathi_D (Tamil Nadu)",
        OriginCenter: "Uthangarai_Mathurpathi_D (Tamil Nadu)",
        FacilityCity: "Uthangarai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9327,
        Pin: 641050,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9328,
        Pin: 144629,
        DispatchCenter: "SultanpurLodhi_Gill_D (Punjab)",
        OriginCenter: "SultanpurLodhi_Gill_D (Punjab)",
        FacilityCity: "Sultanpur Lodhi",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9329,
        Pin: 676514,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9330,
        Pin: 614628,
        DispatchCenter: "Peravurani_Nattanikkottai_D (Tamil Nadu)",
        OriginCenter: "Peravurani_Nattanikkottai_D (Tamil Nadu)",
        FacilityCity: "Peravurani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9331,
        Pin: 824208,
        DispatchCenter: "AurangabadBR_Katharua_D (Bihar)",
        OriginCenter: "AurangabadBR_Katharua_D (Bihar)",
        FacilityCity: "Aurangabad-BR",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9332,
        Pin: 249302,
        DispatchCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        OriginCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        FacilityCity: "Rishikesh",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9333,
        Pin: 753121,
        DispatchCenter: "Jalpaiguri_Gomostapara_D (West Bengal)",
        OriginCenter: "Jalpaiguri_Gomostapara_D (West Bengal)",
        FacilityCity: "Jalpaiguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9334,
        Pin: 631551,
        DispatchCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        OriginCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        FacilityCity: "Kanchipuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9335,
        Pin: 627952,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9336,
        Pin: 603314,
        DispatchCenter: "Chengalpattu_Uthiramerur_D (Tamil Nadu)",
        OriginCenter: "Chengalpattu_Uthiramerur_D (Tamil Nadu)",
        FacilityCity: "Chengalpattu",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9337,
        Pin: 415002,
        DispatchCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        OriginCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        FacilityCity: "Satara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9338,
        Pin: 670594,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9339,
        Pin: 387411,
        DispatchCenter: "Ahmedabad_Kanera_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9340,
        Pin: 261201,
        DispatchCenter: "Biswan_PatelNGR_D (Uttar Pradesh)",
        OriginCenter: "Biswan_PatelNGR_D (Uttar Pradesh)",
        FacilityCity: "Biswan",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9341,
        Pin: 250007,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9342,
        Pin: 620014,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9343,
        Pin: 686606,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9344,
        Pin: 146024,
        DispatchCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        OriginCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9345,
        Pin: 576257,
        DispatchCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        OriginCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9346,
        Pin: 680733,
        DispatchCenter: "Kodungallur_Methala_D (Kerala)",
        OriginCenter: "Kodungallur_Methala_D (Kerala)",
        FacilityCity: "Kodungallur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9347,
        Pin: 575027,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9348,
        Pin: 742134,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9349,
        Pin: 515445,
        DispatchCenter: "Tadipatri_Kalavagadacol_D (Andhra Pradesh)",
        OriginCenter: "Tadipatri_Kalavagadacol_D (Andhra Pradesh)",
        FacilityCity: "Tadipatri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9350,
        Pin: 591229,
        DispatchCenter: "Nipani_Sakharwadi_D (Karnataka)",
        OriginCenter: "Nipani_Sakharwadi_D (Karnataka)",
        FacilityCity: "Nipani",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9351,
        Pin: 623204,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9352,
        Pin: 585223,
        DispatchCenter: "Shahapur_LxmiNgr_D (Karnataka)",
        OriginCenter: "Shahapur_LxmiNgr_D (Karnataka)",
        FacilityCity: "Shahapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9353,
        Pin: 572175,
        DispatchCenter: "Madhugiri_SBI_DPP (Karnataka)",
        OriginCenter: "Madhugiri_SBI_DPP (Karnataka)",
        FacilityCity: "Madhugiri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9354,
        Pin: 531113,
        DispatchCenter: "Narsipatnam_Sivapuram_D (Andhra Pradesh)",
        OriginCenter: "Narsipatnam_Sivapuram_D (Andhra Pradesh)",
        FacilityCity: "Narsipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9355,
        Pin: 212655,
        DispatchCenter: "Khaga_GTRoad_D (Uttar Pradesh)",
        OriginCenter: "Khaga_GTRoad_D (Uttar Pradesh)",
        FacilityCity: "Khaga",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9356,
        Pin: 144204,
        DispatchCenter: "Dasuya_AdarshNagar_D (Punjab)",
        OriginCenter: "Dasuya_AdarshNagar_D (Punjab)",
        FacilityCity: "Dasuya",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9357,
        Pin: 574221,
        DispatchCenter: "Kadaba_AnugrDPP_D (Karnataka)",
        OriginCenter: "Kadaba_AnugrDPP_D (Karnataka)",
        FacilityCity: "Kadaba",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9358,
        Pin: 624009,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9359,
        Pin: 577246,
        DispatchCenter: "Thirthahalli_Melinakuruvali2_DPP (Karnataka)",
        OriginCenter: "Thirthahalli_Melinakuruvali2_DPP (Karnataka)",
        FacilityCity: "Thirthahalli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9360,
        Pin: 176205,
        DispatchCenter: "Palion_MndiPthankotRd_DPP (Himachal Pradesh)",
        OriginCenter: "Palion_MndiPthankotRd_DPP (Himachal Pradesh)",
        FacilityCity: "Palion",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9361,
        Pin: 689585,
        DispatchCenter: "Karukachal_Nedumkunam_D (Kerala)",
        OriginCenter: "Karukachal_Nedumkunam_D (Kerala)",
        FacilityCity: "Karukachal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9362,
        Pin: 313803,
        DispatchCenter: "Kherwara_Banjariya_D (Rajasthan)",
        OriginCenter: "Kherwara_Banjariya_D (Rajasthan)",
        FacilityCity: "Kherwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9363,
        Pin: 585355,
        DispatchCenter: "Yadgir_Wadagera_DPP (Karnataka)",
        OriginCenter: "Yadgir_Wadagera_DPP (Karnataka)",
        FacilityCity: "Yadgir",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9364,
        Pin: 586111,
        DispatchCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        OriginCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        FacilityCity: "Indi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9365,
        Pin: 425432,
        DispatchCenter: "Shahada_Untavad_D (Maharashtra)",
        OriginCenter: "Shahada_Untavad_D (Maharashtra)",
        FacilityCity: "Shahada",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9366,
        Pin: 431133,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9367,
        Pin: 523228,
        DispatchCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        OriginCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        FacilityCity: "Kanigiri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9368,
        Pin: 831005,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9369,
        Pin: 600034,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9370,
        Pin: 232111,
        DispatchCenter: "ChakiaUP_Ward5_D (Uttar Pradesh)",
        OriginCenter: "ChakiaUP_Ward5_D (Uttar Pradesh)",
        FacilityCity: "ChakiaUP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9371,
        Pin: 263641,
        DispatchCenter: "BaijnathUK_Gagrigol_DPP (Uttarakhand)",
        OriginCenter: "BaijnathUK_Gagrigol_DPP (Uttarakhand)",
        FacilityCity: "Baijnath-UK",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9372,
        Pin: 629179,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9373,
        Pin: 203131,
        DispatchCenter: "Khurja_Pnjbiyon_D (Uttar Pradesh)",
        OriginCenter: "Khurja_Pnjbiyon_D (Uttar Pradesh)",
        FacilityCity: "Khurja",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9374,
        Pin: 530037,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9375,
        Pin: 679506,
        DispatchCenter: "Cherpulassery_PryaPlda_D (Kerala)",
        OriginCenter: "Cherpulassery_PryaPlda_D (Kerala)",
        FacilityCity: "Cherpulassery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9376,
        Pin: 324005,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9377,
        Pin: 227115,
        DispatchCenter: "Malihabad_Amanignj_DPP (Uttar Pradesh)",
        OriginCenter: "Malihabad_Amanignj_DPP (Uttar Pradesh)",
        FacilityCity: "Malihabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9378,
        Pin: 209622,
        DispatchCenter: "Jalalabad_Bareillyroad_D (Uttar Pradesh)",
        OriginCenter: "Jalalabad_Bareillyroad_D (Uttar Pradesh)",
        FacilityCity: "JalalabadUP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9379,
        Pin: 632507,
        DispatchCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ranipet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9380,
        Pin: 144626,
        DispatchCenter: "SultanpurLodhi_Gill_D (Punjab)",
        OriginCenter: "SultanpurLodhi_Gill_D (Punjab)",
        FacilityCity: "Sultanpur Lodhi",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9381,
        Pin: 332044,
        DispatchCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        OriginCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9382,
        Pin: 422502,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9383,
        Pin: 515456,
        DispatchCenter: "Guntakal_Central_D_2 (Andhra Pradesh)",
        OriginCenter: "Guntakal_Central_D_2 (Andhra Pradesh)",
        FacilityCity: "Guntakal",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9384,
        Pin: 416804,
        DispatchCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        OriginCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        FacilityCity: "Devgad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9385,
        Pin: 676522,
        DispatchCenter: "Pandikkad_Thangalpadi_D (Kerala)",
        OriginCenter: "Pandikkad_Thangalpadi_D (Kerala)",
        FacilityCity: "Pandikkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9386,
        Pin: 400025,
        DispatchCenter: "Mumbai_Sewri_L (Maharashtra)",
        OriginCenter: "Mumbai_Sewri_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9387,
        Pin: 176120,
        DispatchCenter: "JognderNgr_Dhelu_D (Himachal Pradesh)",
        OriginCenter: "JognderNgr_Dhelu_D (Himachal Pradesh)",
        FacilityCity: "Joginder Nagar",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9388,
        Pin: 491993,
        DispatchCenter: "Berla_CommunityCntr_DPP (Chhattisgarh)",
        OriginCenter: "Berla_CommunityCntr_DPP (Chhattisgarh)",
        FacilityCity: "Berla",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9389,
        Pin: 313003,
        DispatchCenter: "Udaipur_Balicha_H (Rajasthan)",
        OriginCenter: "Udaipur_Balicha_H (Rajasthan)",
        FacilityCity: "Udaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9390,
        Pin: 360013,
        DispatchCenter: "Rajkot_Amargadh_H (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9391,
        Pin: 670644,
        DispatchCenter: "Mananthavady_Central_D_1 (Kerala)",
        OriginCenter: "Mananthavady_Central_D_1 (Kerala)",
        FacilityCity: "Mananthavadi",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9392,
        Pin: 522324,
        DispatchCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        OriginCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        FacilityCity: "Repalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9393,
        Pin: 614807,
        DispatchCenter: "Pushpavanam_Vedaranyam_D (Tamil Nadu)",
        OriginCenter: "Pushpavanam_Vedaranyam_D (Tamil Nadu)",
        FacilityCity: "Pushpavanam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9394,
        Pin: 460220,
        DispatchCenter: "Athner_College_D (Madhya Pradesh)",
        OriginCenter: "Athner_College_D (Madhya Pradesh)",
        FacilityCity: "Athner",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 9395,
        Pin: 384415,
        DispatchCenter: "Becharaji_Harijiroad_D (Gujarat)",
        OriginCenter: "Becharaji_Harijiroad_D (Gujarat)",
        FacilityCity: "Becharaji",
        FacilityState: "Gujarat",
        Zone : "W1"
      },
      {
        SN: 9396,
        Pin: 326022,
        DispatchCenter: "Jhalawar_BakaniDPP_D (Rajasthan)",
        OriginCenter: "Jhalawar_BakaniDPP_D (Rajasthan)",
        FacilityCity: "Jhalawar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9397,
        Pin: 144027,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9398,
        Pin: 713121,
        DispatchCenter: "Guskhara_DignagarRd_D (West Bengal)",
        OriginCenter: "Guskhara_DignagarRd_D (West Bengal)",
        FacilityCity: "Guskhara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9399,
        Pin: 790106,
        DispatchCenter: "Lumla_Manpatmarket_D (Arunachal Pradesh)",
        OriginCenter: "Lumla_Manpatmarket_D (Arunachal Pradesh)",
        FacilityCity: "Lumla",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9400,
        Pin: 382735,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9401,
        Pin: 732203,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9402,
        Pin: 761206,
        DispatchCenter: "Paralakhemundi_Omcolony_D (Orissa)",
        OriginCenter: "Paralakhemundi_Omcolony_D (Orissa)",
        FacilityCity: "Paralakhemundi",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9403,
        Pin: 175013,
        DispatchCenter: "JognderNgr_Dhelu_D (Himachal Pradesh)",
        OriginCenter: "JognderNgr_Dhelu_D (Himachal Pradesh)",
        FacilityCity: "Joginder Nagar",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9404,
        Pin: 804418,
        DispatchCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        OriginCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        FacilityCity: "Jehanabad",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9405,
        Pin: 843130,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9406,
        Pin: 243302,
        DispatchCenter: "Aonla_KdidmCLY_D (Uttar Pradesh)",
        OriginCenter: "Aonla_KdidmCLY_D (Uttar Pradesh)",
        FacilityCity: "Aonla",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9407,
        Pin: 382465,
        DispatchCenter: "Dhandhuka_Dholera_DPP (Gujarat)",
        OriginCenter: "Dhandhuka_Dholera_DPP (Gujarat)",
        FacilityCity: "Dhandhuka",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9408,
        Pin: 614404,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9409,
        Pin: 673582,
        DispatchCenter: "Areakode_Mukkam_D (Kerala)",
        OriginCenter: "Areakode_Mukkam_D (Kerala)",
        FacilityCity: "Areacode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9410,
        Pin: 767005,
        DispatchCenter: "Balangir_PalaceLine_D (Orissa)",
        OriginCenter: "Balangir_PalaceLine_D (Orissa)",
        FacilityCity: "Balangir",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9411,
        Pin: 612006,
        DispatchCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        OriginCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9412,
        Pin: 673652,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9413,
        Pin: 142026,
        DispatchCenter: "Jagraon_Grainmkt_D (Punjab)",
        OriginCenter: "Jagraon_Grainmkt_D (Punjab)",
        FacilityCity: "Jagraon",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9414,
        Pin: 144213,
        DispatchCenter: "Dasuya_AdarshNagar_D (Punjab)",
        OriginCenter: "Dasuya_AdarshNagar_D (Punjab)",
        FacilityCity: "Dasuya",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9415,
        Pin: 561204,
        DispatchCenter: "Doddaballapura_Kuntanahalli_D (Karnataka)",
        OriginCenter: "Doddaballapura_Kuntanahalli_D (Karnataka)",
        FacilityCity: "Doddaballapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9416,
        Pin: 620025,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9417,
        Pin: 517424,
        DispatchCenter: "Venkatagirikota_Mulbaal_D (Andhra Pradesh)",
        OriginCenter: "Venkatagirikota_Mulbaal_D (Andhra Pradesh)",
        FacilityCity: "Venkatagirikota",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9418,
        Pin: 783123,
        DispatchCenter: "Boko_Dhupdhara_D (Assam)",
        OriginCenter: "Boko_Dhupdhara_D (Assam)",
        FacilityCity: "Boko",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9419,
        Pin: 781346,
        DispatchCenter: "Tihu_Barbari_D (Assam)",
        OriginCenter: "Tihu_Barbari_D (Assam)",
        FacilityCity: "Tihu",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9420,
        Pin: 757001,
        DispatchCenter: "Baripada_KalikDPP_D (Orissa)",
        OriginCenter: "Baripada_KalikDPP_D (Orissa)",
        FacilityCity: "Baripada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9421,
        Pin: 176110,
        DispatchCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        OriginCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9422,
        Pin: 140105,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9423,
        Pin: 321409,
        DispatchCenter: "Weir_Bhusawar_DPP (Rajasthan)",
        OriginCenter: "Weir_Bhusawar_DPP (Rajasthan)",
        FacilityCity: "Weir",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9424,
        Pin: 843332,
        DispatchCenter: "Sonbarsa_Sitamarhi_D (Bihar)",
        OriginCenter: "Sonbarsa_Sitamarhi_D (Bihar)",
        FacilityCity: "Sonbarsa",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9425,
        Pin: 505211,
        DispatchCenter: "Mancherial_Nuspur_I (Telangana)",
        OriginCenter: "Mancherial_Nuspur_I (Telangana)",
        FacilityCity: "Mancherial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9426,
        Pin: 396002,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9427,
        Pin: 835211,
        DispatchCenter: "Kolebira_Lachragarh_D (Jharkhand)",
        OriginCenter: "Kolebira_Lachragarh_D (Jharkhand)",
        FacilityCity: "Kolebira",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9428,
        Pin: 152104,
        DispatchCenter: "Muktsar_BathindaRd_D (Punjab)",
        OriginCenter: "Muktsar_BathindaRd_D (Punjab)",
        FacilityCity: "Muktsar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9429,
        Pin: 768032,
        DispatchCenter: "Bargarh_Pada_D (Orissa)",
        OriginCenter: "Bargarh_Pada_D (Orissa)",
        FacilityCity: "Bargarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9430,
        Pin: 611102,
        DispatchCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        OriginCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        FacilityCity: "Nagapattinam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9431,
        Pin: 721517,
        DispatchCenter: "Jhargram_Gopiballavpur_DPP (West Bengal)",
        OriginCenter: "Jhargram_Gopiballavpur_DPP (West Bengal)",
        FacilityCity: "Jhargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9432,
        Pin: 229120,
        DispatchCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        OriginCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        FacilityCity: "Lalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9433,
        Pin: 607201,
        DispatchCenter: "Thirukoilure_Memalur_DPP (Tamil Nadu)",
        OriginCenter: "Thirukoilure_Memalur_DPP (Tamil Nadu)",
        FacilityCity: "Memalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9434,
        Pin: 503187,
        DispatchCenter: "Banswada_Teacherscolony_D (Telangana)",
        OriginCenter: "Banswada_Teacherscolony_D (Telangana)",
        FacilityCity: "Banswada",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9435,
        Pin: 500605,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9436,
        Pin: 755012,
        DispatchCenter: "Binjharpur_Ragunthapr_D (Orissa)",
        OriginCenter: "Binjharpur_Ragunthapr_D (Orissa)",
        FacilityCity: "Binjharpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9437,
        Pin: 678687,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9438,
        Pin: 794002,
        DispatchCenter: "Tura_Megonggre_D (Meghalaya)",
        OriginCenter: "Tura_Megonggre_D (Meghalaya)",
        FacilityCity: "Tura",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9439,
        Pin: 509338,
        DispatchCenter: "Kosgi_Kodangal_D (Telangana)",
        OriginCenter: "Kosgi_Kodangal_D (Telangana)",
        FacilityCity: "Kosgi",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9440,
        Pin: 127032,
        DispatchCenter: "Bhiwani_Bhagganpuri_D (Haryana)",
        OriginCenter: "Bhiwani_Bhagganpuri_D (Haryana)",
        FacilityCity: "Bhiwani",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9441,
        Pin: 690543,
        DispatchCenter: "Kollam_Karunagappally_D (Kerala)",
        OriginCenter: "Kollam_Karunagappally_D (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9442,
        Pin: 327604,
        DispatchCenter: "Banswara_Bagidora_D (Rajasthan)",
        OriginCenter: "Banswara_Bagidora_D (Rajasthan)",
        FacilityCity: "Banswara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9443,
        Pin: 345001,
        DispatchCenter: "Jaisalmer_Gaffurbhatt_D (Rajasthan)",
        OriginCenter: "Jaisalmer_Gaffurbhatt_D (Rajasthan)",
        FacilityCity: "Jaisalmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9444,
        Pin: 151506,
        DispatchCenter: "Mansa_Budhlada2_D (Punjab)",
        OriginCenter: "Mansa_Budhlada2_D (Punjab)",
        FacilityCity: "Mansa",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9445,
        Pin: 614706,
        DispatchCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        FacilityCity: "Thiruthuraipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9446,
        Pin: 583228,
        DispatchCenter: "Hospet_Munirabad_D (Karnataka)",
        OriginCenter: "Hospet_Munirabad_D (Karnataka)",
        FacilityCity: "Hospet",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9447,
        Pin: 533255,
        DispatchCenter: "Draksharamam_Anaipeta_D (Andhra Pradesh)",
        OriginCenter: "Draksharamam_Anaipeta_D (Andhra Pradesh)",
        FacilityCity: "Draksharamam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9448,
        Pin: 224216,
        DispatchCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        OriginCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        FacilityCity: "Jalalpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9449,
        Pin: 110109,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9450,
        Pin: 144530,
        DispatchCenter: "Garhshankar_BangaRoad_D (Punjab)",
        OriginCenter: "Garhshankar_BangaRoad_D (Punjab)",
        FacilityCity: "Garhshanker",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9451,
        Pin: 382043,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9452,
        Pin: 785647,
        DispatchCenter: "Nazira_Amina_D (Assam)",
        OriginCenter: "Nazira_Amina_D (Assam)",
        FacilityCity: "Nazira",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9453,
        Pin: 302040,
        DispatchCenter: "Jaipur_VishwakarmaIndArea_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9454,
        Pin: 535281,
        DispatchCenter: "Seetharampram_Lakkavarapukota_DPP (Andhra Pradesh)",
        OriginCenter: "Seetharampram_Lakkavarapukota_DPP (Andhra Pradesh)",
        FacilityCity: "Seetharampram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9455,
        Pin: 600030,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9456,
        Pin: 827001,
        DispatchCenter: "Bokaro_Chas_D (Jharkhand)",
        OriginCenter: "Bokaro_Chas_D (Jharkhand)",
        FacilityCity: "Bokaro",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9457,
        Pin: 635112,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9458,
        Pin: 671543,
        DispatchCenter: "Poinachi_Kuttikol_D (Kerala)",
        OriginCenter: "Poinachi_Kuttikol_D (Kerala)",
        FacilityCity: "Kuttikol",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9459,
        Pin: 424312,
        DispatchCenter: "Kannad_Pragaticolony_DPP (Maharashtra)",
        OriginCenter: "Kannad_Pragaticolony_DPP (Maharashtra)",
        FacilityCity: "Kannad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9460,
        Pin: 444711,
        DispatchCenter: "Dhmngaon_Bypass_DPP (Maharashtra)",
        OriginCenter: "Dhmngaon_Bypass_DPP (Maharashtra)",
        FacilityCity: "Dhamangaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9461,
        Pin: 274805,
        DispatchCenter: "Deoria_Ramnathnagar_D (Uttar Pradesh)",
        OriginCenter: "Deoria_Ramnathnagar_D (Uttar Pradesh)",
        FacilityCity: "Deoria",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9462,
        Pin: 670501,
        DispatchCenter: "Kannur_Aduthila_D (Kerala)",
        OriginCenter: "Kannur_Aduthila_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9463,
        Pin: 574197,
        DispatchCenter: "Moodbidri_Central_D (Karnataka)",
        OriginCenter: "Moodbidri_Central_D (Karnataka)",
        FacilityCity: "Moodabidri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9464,
        Pin: 600118,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9465,
        Pin: 184147,
        DispatchCenter: "Kathua_Kharote_D (Jammu & Kashmir)",
        OriginCenter: "Kathua_Kharote_D (Jammu & Kashmir)",
        FacilityCity: "Kathua",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9466,
        Pin: 635701,
        DispatchCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        OriginCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        FacilityCity: "Tiruppattur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9467,
        Pin: 600055,
        DispatchCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        OriginCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9468,
        Pin: 799102,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9469,
        Pin: 591281,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9470,
        Pin: 431132,
        DispatchCenter: "Buldhana_Dhad_DPP (Maharashtra)",
        OriginCenter: "Buldhana_Dhad_DPP (Maharashtra)",
        FacilityCity: "Buldhana",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9471,
        Pin: 444710,
        DispatchCenter: "Amravati_Sashinagar_D (Maharashtra)",
        OriginCenter: "Amravati_Sashinagar_D (Maharashtra)",
        FacilityCity: "Amravati",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9472,
        Pin: 637404,
        DispatchCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        OriginCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        FacilityCity: "Namakkal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9473,
        Pin: 562105,
        DispatchCenter: "Chikkaballapura_WardNo6_D (Karnataka)",
        OriginCenter: "Chikkaballapura_WardNo6_D (Karnataka)",
        FacilityCity: "Chikkaballapura",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9474,
        Pin: 415105,
        DispatchCenter: "Karad_Malkapur1_D (Maharashtra)",
        OriginCenter: "Karad_Malkapur1_D (Maharashtra)",
        FacilityCity: "Karad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9475,
        Pin: 767024,
        DispatchCenter: "Balangir_PalaceLine_D (Orissa)",
        OriginCenter: "Balangir_PalaceLine_D (Orissa)",
        FacilityCity: "Balangir",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9476,
        Pin: 691015,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9477,
        Pin: 700072,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9478,
        Pin: 362275,
        DispatchCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        OriginCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        FacilityCity: "Veraval",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9479,
        Pin: 621114,
        DispatchCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        OriginCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        FacilityCity: "Thuraiyur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9480,
        Pin: 735222,
        DispatchCenter: "Chalsa_BusStand_D (West Bengal)",
        OriginCenter: "Chalsa_BusStand_D (West Bengal)",
        FacilityCity: "Chalsa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9481,
        Pin: 497553,
        DispatchCenter: "Manendragarh_JKDRoad_D (Chhattisgarh)",
        OriginCenter: "Manendragarh_JKDRoad_D (Chhattisgarh)",
        FacilityCity: "Manendragarh",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9482,
        Pin: 173026,
        DispatchCenter: "Nahan_NarulaNagar_D (Himachal Pradesh)",
        OriginCenter: "Nahan_NarulaNagar_D (Himachal Pradesh)",
        FacilityCity: "Nahan",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9483,
        Pin: 342009,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9484,
        Pin: 799203,
        DispatchCenter: "Teliamura_Gakulnagar_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Teliamura",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9485,
        Pin: 848236,
        DispatchCenter: "Rosera_HanumanTemple_D (Bihar)",
        OriginCenter: "Rosera_HanumanTemple_D (Bihar)",
        FacilityCity: "Rusera",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9486,
        Pin: 571501,
        DispatchCenter: "Channapatna_2ndCross_D (Karnataka)",
        OriginCenter: "Channapatna_2ndCross_D (Karnataka)",
        FacilityCity: "Channapatna",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9487,
        Pin: 212620,
        DispatchCenter: "Fatehpur_Radhanagar_D (Uttar Pradesh)",
        OriginCenter: "Fatehpur_Radhanagar_D (Uttar Pradesh)",
        FacilityCity: "Fatehpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9488,
        Pin: 743134,
        DispatchCenter: "Naihati_Rajendrapur_DPP (West Bengal)",
        OriginCenter: "Naihati_Rajendrapur_DPP (West Bengal)",
        FacilityCity: "Naihati",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9489,
        Pin: 125044,
        DispatchCenter: "Hisar_Dabra_H (Haryana)",
        OriginCenter: "Hisar_Dabra_H (Haryana)",
        FacilityCity: "Hisar",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9490,
        Pin: 758035,
        DispatchCenter: "Barbil_Rungtacolony_D (Orissa)",
        OriginCenter: "Barbil_Rungtacolony_D (Orissa)",
        FacilityCity: "Barbil",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9491,
        Pin: 495556,
        DispatchCenter: "Jaijaipur_Shivrinarayan_DPP (Chhattisgarh)",
        OriginCenter: "Jaijaipur_Shivrinarayan_DPP (Chhattisgarh)",
        FacilityCity: "Jaijaipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9492,
        Pin: 500044,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9493,
        Pin: 609806,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9494,
        Pin: 736166,
        DispatchCenter: "CoochBehar_khagrabari_I (West Bengal)",
        OriginCenter: "CoochBehar_khagrabari_I (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9495,
        Pin: 416417,
        DispatchCenter: "Ashta_GordeChwk_D (Maharashtra)",
        OriginCenter: "Ashta_GordeChwk_D (Maharashtra)",
        FacilityCity: "AshtaSangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9496,
        Pin: 583101,
        DispatchCenter: "Bellary_Mundargi_I (Karnataka)",
        OriginCenter: "Bellary_Mundargi_I (Karnataka)",
        FacilityCity: "Bellary",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9497,
        Pin: 770075,
        DispatchCenter: "Brajrajnagar_Jagannath_DPP (Orissa)",
        OriginCenter: "Brajrajnagar_Jagannath_DPP (Orissa)",
        FacilityCity: "Brajrajnagar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9498,
        Pin: 695526,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9499,
        Pin: 493663,
        DispatchCenter: "Abhanpur_Gatapr_D (Chhattisgarh)",
        OriginCenter: "Abhanpur_Gatapr_D (Chhattisgarh)",
        FacilityCity: "Abhanpur",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9500,
        Pin: 534320,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9501,
        Pin: 636109,
        DispatchCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        OriginCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        FacilityCity: "Attur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9502,
        Pin: 520004,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9503,
        Pin: 450221,
        DispatchCenter: "Nepanagar_Ward4_D (Madhya Pradesh)",
        OriginCenter: "Nepanagar_Ward4_D (Madhya Pradesh)",
        FacilityCity: "Nepanagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9504,
        Pin: 601212,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9505,
        Pin: 473105,
        DispatchCenter: "Guna_Shahpur_H (Madhya Pradesh)",
        OriginCenter: "Guna_Shahpur_H (Madhya Pradesh)",
        FacilityCity: "Guna",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9506,
        Pin: 144801,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9507,
        Pin: 160033,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9508,
        Pin: 679581,
        DispatchCenter: "Chiramanangad_Veliankode_D (Kerala)",
        OriginCenter: "Chiramanangad_Veliankode_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9509,
        Pin: 502293,
        DispatchCenter: "Jogipet_Andole_D (Telangana)",
        OriginCenter: "Jogipet_Andole_D (Telangana)",
        FacilityCity: "Jogipet",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9510,
        Pin: 480002,
        DispatchCenter: "Chhindwara_Linga_I (Madhya Pradesh)",
        OriginCenter: "Chhindwara_Linga_I (Madhya Pradesh)",
        FacilityCity: "Chhindwara",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 9511,
        Pin: 743128,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9512,
        Pin: 794102,
        DispatchCenter: "Baghmara_Gonjagre_D (Meghalaya)",
        OriginCenter: "Baghmara_Gonjagre_D (Meghalaya)",
        FacilityCity: "Baghmara",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9513,
        Pin: 638112,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9514,
        Pin: 505214,
        DispatchCenter: "Mancherial_Nuspur_I (Telangana)",
        OriginCenter: "Mancherial_Nuspur_I (Telangana)",
        FacilityCity: "Mancherial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9515,
        Pin: 848116,
        DispatchCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        OriginCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        FacilityCity: "Dalsingh Sarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9516,
        Pin: 609111,
        DispatchCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        OriginCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        FacilityCity: "Sirkazhi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9517,
        Pin: 362227,
        DispatchCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        OriginCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        FacilityCity: "Keshod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9518,
        Pin: 224152,
        DispatchCenter: "Akbarpur_AmbedkarNgr_D (Uttar Pradesh)",
        OriginCenter: "Akbarpur_AmbedkarNgr_D (Uttar Pradesh)",
        FacilityCity: "Akbarpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9519,
        Pin: 643228,
        DispatchCenter: "Gudalur_Devarshola_DPP (Tamil Nadu)",
        OriginCenter: "Gudalur_Devarshola_DPP (Tamil Nadu)",
        FacilityCity: "Gudalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9520,
        Pin: 741504,
        DispatchCenter: "Gopalnagar_ParaSttn_DPP (West Bengal)",
        OriginCenter: "Gopalnagar_ParaSttn_DPP (West Bengal)",
        FacilityCity: "Gopalnagar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9521,
        Pin: 751013,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9522,
        Pin: 481771,
        DispatchCenter: "Mandla_Maharajpur_D (Madhya Pradesh)",
        OriginCenter: "Mandla_Maharajpur_D (Madhya Pradesh)",
        FacilityCity: "Mandla",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9523,
        Pin: 673026,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9524,
        Pin: 683108,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9525,
        Pin: 248202,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9526,
        Pin: 768214,
        DispatchCenter: "Kuchinda_Pdrpada_D (Orissa)",
        OriginCenter: "Kuchinda_Pdrpada_D (Orissa)",
        FacilityCity: "Kuchinda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9527,
        Pin: 145003,
        DispatchCenter: "Pathankot_Kahanpur_H (Punjab)",
        OriginCenter: "Pathankot_Kahanpur_H (Punjab)",
        FacilityCity: "Pathankot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9528,
        Pin: 641017,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9529,
        Pin: 683503,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9530,
        Pin: 628906,
        DispatchCenter: "Vilathikulam_Thoothukudi_DPP (Tamil Nadu)",
        OriginCenter: "Vilathikulam_Thoothukudi_DPP (Tamil Nadu)",
        FacilityCity: "Vilathikulam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9531,
        Pin: 754211,
        DispatchCenter: "Kendrapara_Chhagharia_D (Orissa)",
        OriginCenter: "Kendrapara_Chhagharia_D (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9532,
        Pin: 613503,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9533,
        Pin: 380330,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9534,
        Pin: 342001,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9535,
        Pin: 585429,
        DispatchCenter: "Bidar_AdarshClny_D (Karnataka)",
        OriginCenter: "Bidar_AdarshClny_D (Karnataka)",
        FacilityCity: "Bidar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9536,
        Pin: 231212,
        DispatchCenter: "Dudhinagar_DevColony_D (Uttar Pradesh)",
        OriginCenter: "Dudhinagar_DevColony_D (Uttar Pradesh)",
        FacilityCity: "Dudhinagar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9537,
        Pin: 402115,
        DispatchCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        OriginCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        FacilityCity: "Mahad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9538,
        Pin: 440001,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9539,
        Pin: 762102,
        DispatchCenter: "Balliguda_Patakhanda_DPP (Orissa)",
        OriginCenter: "Balliguda_Patakhanda_DPP (Orissa)",
        FacilityCity: "Balliguda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9540,
        Pin: 470021,
        DispatchCenter: "Sagar_DeendayalNagar_D (Madhya Pradesh)",
        OriginCenter: "Sagar_DeendayalNagar_D (Madhya Pradesh)",
        FacilityCity: "Sagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9541,
        Pin: 141027,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9542,
        Pin: 700107,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9543,
        Pin: 403511,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9544,
        Pin: 396301,
        DispatchCenter: "Bilimora_DC (Gujarat)",
        OriginCenter: "Bilimora_DC (Gujarat)",
        FacilityCity: "Bilimora",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9545,
        Pin: 711313,
        DispatchCenter: "Howrah_Temp_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9546,
        Pin: 505153,
        DispatchCenter: "Peddapalli_Rajivnagar_D (Telangana)",
        OriginCenter: "Peddapalli_Rajivnagar_D (Telangana)",
        FacilityCity: "Peddapalli",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9547,
        Pin: 621133,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9548,
        Pin: 854330,
        DispatchCenter: "Purnia_Fatehpur_D (Bihar)",
        OriginCenter: "Purnia_Fatehpur_D (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9549,
        Pin: 622003,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9550,
        Pin: 523116,
        DispatchCenter: "Kandukur_SanthoshNagar_D (Andhra Pradesh)",
        OriginCenter: "Kandukur_SanthoshNagar_D (Andhra Pradesh)",
        FacilityCity: "Kandukur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9551,
        Pin: 629701,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9552,
        Pin: 497442,
        DispatchCenter: "Manendragarh_JKDRoad_D (Chhattisgarh)",
        OriginCenter: "Manendragarh_JKDRoad_D (Chhattisgarh)",
        FacilityCity: "Manendragarh",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9553,
        Pin: 460110,
        DispatchCenter: "Athner_College_D (Madhya Pradesh)",
        OriginCenter: "Athner_College_D (Madhya Pradesh)",
        FacilityCity: "Athner",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9554,
        Pin: 431207,
        DispatchCenter: "Partur_Watur_D (Maharashtra)",
        OriginCenter: "Partur_Watur_D (Maharashtra)",
        FacilityCity: "Partur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9555,
        Pin: 841406,
        DispatchCenter: "Malmaliya_Sipah_D (Bihar)",
        OriginCenter: "Malmaliya_Sipah_D (Bihar)",
        FacilityCity: "Malmaliya",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9556,
        Pin: 151204,
        DispatchCenter: "Kotkapura_PremNgr_D (Punjab)",
        OriginCenter: "Kotkapura_PremNgr_D (Punjab)",
        FacilityCity: "Kotkapura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9557,
        Pin: 623516,
        DispatchCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        OriginCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        FacilityCity: "Rameswaram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9558,
        Pin: 614808,
        DispatchCenter: "Pushpavanam_Vedaranyam_D (Tamil Nadu)",
        OriginCenter: "Pushpavanam_Vedaranyam_D (Tamil Nadu)",
        FacilityCity: "Pushpavanam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9559,
        Pin: 312620,
        DispatchCenter: "Nimbahera_Chotisadri_DPP (Rajasthan)",
        OriginCenter: "Nimbahera_Chotisadri_DPP (Rajasthan)",
        FacilityCity: "Nimbahera",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9560,
        Pin: 673583,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9561,
        Pin: 523114,
        DispatchCenter: "Pamuru_ViruvoruRd_D (Andhra Pradesh)",
        OriginCenter: "Pamuru_ViruvoruRd_D (Andhra Pradesh)",
        FacilityCity: "Pamuru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9562,
        Pin: 804422,
        DispatchCenter: "Makhdumpur_Nawabganj_D (Bihar)",
        OriginCenter: "Makhdumpur_Nawabganj_D (Bihar)",
        FacilityCity: "Makhdumpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9563,
        Pin: 394640,
        DispatchCenter: "Bardoli_GopalNagar_D (Gujarat)",
        OriginCenter: "Bardoli_GopalNagar_D (Gujarat)",
        FacilityCity: "Bardoli",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9564,
        Pin: 754111,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9565,
        Pin: 721158,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9566,
        Pin: 504219,
        DispatchCenter: "Tiryani_KomurambeemChk_DPP (Telangana)",
        OriginCenter: "Tiryani_KomurambeemChk_DPP (Telangana)",
        FacilityCity: "Tiryani",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9567,
        Pin: 414702,
        DispatchCenter: "Daund_Kurkumbh_L (Maharashtra)",
        OriginCenter: "Daund_Kurkumbh_L (Maharashtra)",
        FacilityCity: "Daund",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9568,
        Pin: 626189,
        DispatchCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        OriginCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        FacilityCity: "Sivakasi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9569,
        Pin: 362620,
        DispatchCenter: "Manavadar_Ratanpara_DPP (Gujarat)",
        OriginCenter: "Manavadar_Ratanpara_DPP (Gujarat)",
        FacilityCity: "Manavadar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9570,
        Pin: 411040,
        DispatchCenter: "Pune_Fursungi_L (Maharashtra)",
        OriginCenter: "Pune_Fursungi_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9571,
        Pin: 421311,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9572,
        Pin: 848208,
        DispatchCenter: "Rosera_HanumanTemple_D (Bihar)",
        OriginCenter: "Rosera_HanumanTemple_D (Bihar)",
        FacilityCity: "Rusera",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9573,
        Pin: 828103,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9574,
        Pin: 743504,
        DispatchCenter: "Fatepur_Majlishpur_D (West Bengal)",
        OriginCenter: "Fatepur_Majlishpur_D (West Bengal)",
        FacilityCity: "Fatepur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9575,
        Pin: 713424,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9576,
        Pin: 131040,
        DispatchCenter: "Sonipat_Adreshcolony_D (Haryana)",
        OriginCenter: "Sonipat_Adreshcolony_D (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9577,
        Pin: 689629,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9578,
        Pin: 503246,
        DispatchCenter: "Navipet_Dharyapur_D (Telangana)",
        OriginCenter: "Navipet_Dharyapur_D (Telangana)",
        FacilityCity: "Navipet",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9579,
        Pin: 572116,
        DispatchCenter: "Amarapuram_YSRCircle_D (Andhra Pradesh)",
        OriginCenter: "Amarapuram_YSRCircle_D (Andhra Pradesh)",
        FacilityCity: "Amarapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9580,
        Pin: 624008,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9581,
        Pin: 841434,
        DispatchCenter: "Siwan_Barharia_D (Bihar)",
        OriginCenter: "Siwan_Barharia_D (Bihar)",
        FacilityCity: "Mirganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9582,
        Pin: 321301,
        DispatchCenter: "Bharatpur_DurgaNagar_D (Rajasthan)",
        OriginCenter: "Bharatpur_DurgaNagar_D (Rajasthan)",
        FacilityCity: "Bharatpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9583,
        Pin: 614303,
        DispatchCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        OriginCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9584,
        Pin: 753002,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9585,
        Pin: 456662,
        DispatchCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        OriginCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        FacilityCity: "Ujjain",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 9586,
        Pin: 755016,
        DispatchCenter: "Binjharpur_Ragunthapr_D (Orissa)",
        OriginCenter: "Binjharpur_Ragunthapr_D (Orissa)",
        FacilityCity: "Binjharpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9587,
        Pin: 700093,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9588,
        Pin: 577168,
        DispatchCenter: "Chikmagalur_Jayanagara_D (Karnataka)",
        OriginCenter: "Chikmagalur_Jayanagara_D (Karnataka)",
        FacilityCity: "Chikkamagalur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9589,
        Pin: 721625,
        DispatchCenter: "Bajkul_Kismat_D (West Bengal)",
        OriginCenter: "Bajkul_Kismat_D (West Bengal)",
        FacilityCity: "Bajkul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9590,
        Pin: 305921,
        DispatchCenter: "Badnaur_Asind_D (Rajasthan)",
        OriginCenter: "Badnaur_Asind_D (Rajasthan)",
        FacilityCity: "Asind",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9591,
        Pin: 825403,
        DispatchCenter: "Chatra_Godabar_DPP (Jharkhand)",
        OriginCenter: "Chatra_Godabar_DPP (Jharkhand)",
        FacilityCity: "Godabar",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9592,
        Pin: 486450,
        DispatchCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        OriginCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        FacilityCity: "Rewa",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9593,
        Pin: 523260,
        DispatchCenter: "Martur_Nagarajupalli_D (Andhra Pradesh)",
        OriginCenter: "Martur_Nagarajupalli_D (Andhra Pradesh)",
        FacilityCity: "Martur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9594,
        Pin: 627356,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9595,
        Pin: 605014,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9596,
        Pin: 638108,
        DispatchCenter: "Tirupur_Kangeyam_D (Tamil Nadu)",
        OriginCenter: "Tirupur_Kangeyam_D (Tamil Nadu)",
        FacilityCity: "Palladam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9597,
        Pin: 142021,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9598,
        Pin: 689656,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9599,
        Pin: 516502,
        DispatchCenter: "Badvel_Vidyanagar_D (Andhra Pradesh)",
        OriginCenter: "Badvel_Vidyanagar_D (Andhra Pradesh)",
        FacilityCity: "Badvel",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9600,
        Pin: 302008,
        DispatchCenter: "Jaipur_Ramchandpura_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9601,
        Pin: 671311,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9602,
        Pin: 682301,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9603,
        Pin: 301032,
        DispatchCenter: "Alwar_TuleraRoad_I (Rajasthan)",
        OriginCenter: "Alwar_TuleraRoad_I (Rajasthan)",
        FacilityCity: "Alwar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9604,
        Pin: 365555,
        DispatchCenter: "Rajula_Anandnagar_D (Gujarat)",
        OriginCenter: "Rajula_Anandnagar_D (Gujarat)",
        FacilityCity: "Rajula",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9605,
        Pin: 361012,
        DispatchCenter: "Jamnagar_Chela_L (Gujarat)",
        OriginCenter: "Jamnagar_Chela_L (Gujarat)",
        FacilityCity: "Jamnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9606,
        Pin: 324002,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9607,
        Pin: 573113,
        DispatchCenter: "Ramanathapura_Koodalur_D (Karnataka)",
        OriginCenter: "Ramanathapura_Koodalur_D (Karnataka)",
        FacilityCity: "Ramanathapura",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9608,
        Pin: 313341,
        DispatchCenter: "DeogarhRJ_BapuNgr_DPP (Rajasthan)",
        OriginCenter: "DeogarhRJ_BapuNgr_DPP (Rajasthan)",
        FacilityCity: "DeogarhRJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9609,
        Pin: 673641,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9610,
        Pin: 625522,
        DispatchCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        OriginCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        FacilityCity: "Cumbum",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9611,
        Pin: 456002,
        DispatchCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        OriginCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        FacilityCity: "Ujjain",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 9612,
        Pin: 500032,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9613,
        Pin: 206248,
        DispatchCenter: "Bidhuna_BharthnRd_D (Uttar Pradesh)",
        OriginCenter: "Bidhuna_BharthnRd_D (Uttar Pradesh)",
        FacilityCity: "Bidhuna",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9614,
        Pin: 263129,
        DispatchCenter: "Haldwani_Kamaluaganja_D (Uttarakhand)",
        OriginCenter: "Haldwani_Kamaluaganja_D (Uttarakhand)",
        FacilityCity: "Haldwani",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9615,
        Pin: 380054,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9616,
        Pin: 533449,
        DispatchCenter: "Kathipudi_YdvaSt_D (Andhra Pradesh)",
        OriginCenter: "Kathipudi_YdvaSt_D (Andhra Pradesh)",
        FacilityCity: "Kathipudi",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9617,
        Pin: 679585,
        DispatchCenter: "Edappal_Nellissery_D (Kerala)",
        OriginCenter: "Edappal_Nellissery_D (Kerala)",
        FacilityCity: "Edappal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9618,
        Pin: 524223,
        DispatchCenter: "Kavali_Musunur_D (Andhra Pradesh)",
        OriginCenter: "Kavali_Musunur_D (Andhra Pradesh)",
        FacilityCity: "Kavali",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9619,
        Pin: 176037,
        DispatchCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        OriginCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        FacilityCity: "Kangra",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9620,
        Pin: 121008,
        DispatchCenter: "Faridabad_MathuraRoad_L (Haryana)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9621,
        Pin: 176109,
        DispatchCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        OriginCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9622,
        Pin: 843319,
        DispatchCenter: "Pupri_SngihiRD_D (Bihar)",
        OriginCenter: "Pupri_SngihiRD_D (Bihar)",
        FacilityCity: "Pupri",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9623,
        Pin: 841447,
        DispatchCenter: "Mashrakh_Benchapra_D (Bihar)",
        OriginCenter: "Mashrakh_Benchapra_D (Bihar)",
        FacilityCity: "Mashrakh",
        FacilityState: "Bihar",
        Zone : "E"
      },
      {
        SN: 9624,
        Pin: 712404,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9625,
        Pin: 734101,
        DispatchCenter: "Darjeeling_Limbugaon_D (West Bengal)",
        OriginCenter: "Darjeeling_Limbugaon_D (West Bengal)",
        FacilityCity: "Darjeeling",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9626,
        Pin: 626126,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9627,
        Pin: 713101,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9628,
        Pin: 382019,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9629,
        Pin: 848213,
        DispatchCenter: "Singhia_Shayampur_D (Bihar)",
        OriginCenter: "Singhia_Shayampur_D (Bihar)",
        FacilityCity: "Singhia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9630,
        Pin: 182143,
        DispatchCenter: "Chenani_Ward3_D (Jammu & Kashmir)",
        OriginCenter: "Chenani_Ward3_D (Jammu & Kashmir)",
        FacilityCity: "Chenani",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9631,
        Pin: 382427,
        DispatchCenter: "Ahmedabad_Kanera_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9632,
        Pin: 332709,
        DispatchCenter: "Udaipurwati_Khandela_D (Rajasthan)",
        OriginCenter: "Udaipurwati_Khandela_D (Rajasthan)",
        FacilityCity: "Khandela",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9633,
        Pin: 602106,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9634,
        Pin: 193401,
        DispatchCenter: "Srinagar_Draripora_DPP (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Draripora_DPP (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9635,
        Pin: 184204,
        DispatchCenter: "Billawar_Wardno7_D (Jammu & Kashmir)",
        OriginCenter: "Billawar_Wardno7_D (Jammu & Kashmir)",
        FacilityCity: "Billawar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9636,
        Pin: 502109,
        DispatchCenter: "Medak_RTCclny_D (Telangana)",
        OriginCenter: "Medak_RTCclny_D (Telangana)",
        FacilityCity: "Medak",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9637,
        Pin: 689107,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9638,
        Pin: 560069,
        DispatchCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        OriginCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9639,
        Pin: 614713,
        DispatchCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        FacilityCity: "Thiruthuraipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9640,
        Pin: 829120,
        DispatchCenter: "Patratu_Saundabasti_D (Jharkhand)",
        OriginCenter: "Patratu_Saundabasti_D (Jharkhand)",
        FacilityCity: "Patratu",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9641,
        Pin: 413801,
        DispatchCenter: "Daund_Kurkumbh_L (Maharashtra)",
        OriginCenter: "Daund_Kurkumbh_L (Maharashtra)",
        FacilityCity: "Daund",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9642,
        Pin: 607303,
        DispatchCenter: "Neyveli_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Neyveli_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Neyveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9643,
        Pin: 691574,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9644,
        Pin: 247342,
        DispatchCenter: "Gangoh_BypassRd_DPP (Uttar Pradesh)",
        OriginCenter: "Gangoh_BypassRd_DPP (Uttar Pradesh)",
        FacilityCity: "Gangoh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9645,
        Pin: 521260,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9646,
        Pin: 380017,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9647,
        Pin: 387360,
        DispatchCenter: "Nadiad_Shantinagar_D (Gujarat)",
        OriginCenter: "Nadiad_Shantinagar_D (Gujarat)",
        FacilityCity: "Nadiad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9648,
        Pin: 331801,
        DispatchCenter: "ShriDungargarh_RaniBzr_DPP (Rajasthan)",
        OriginCenter: "ShriDungargarh_RaniBzr_DPP (Rajasthan)",
        FacilityCity: "Shri Dungargarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9649,
        Pin: 314034,
        DispatchCenter: "Salumbar_Aspur2_D (Rajasthan)",
        OriginCenter: "Salumbar_Aspur2_D (Rajasthan)",
        FacilityCity: "Salumbar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9650,
        Pin: 802152,
        DispatchCenter: "Jagdishpur_Ward14_D (Bihar)",
        OriginCenter: "Jagdishpur_Ward14_D (Bihar)",
        FacilityCity: "Jagdishpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9651,
        Pin: 247772,
        DispatchCenter: "Shamli_SalekChandVihar_D (Uttar Pradesh)",
        OriginCenter: "Shamli_SalekChandVihar_D (Uttar Pradesh)",
        FacilityCity: "Shamli",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9652,
        Pin: 685583,
        DispatchCenter: "Koothattukulam_Oliyappuram_D (Kerala)",
        OriginCenter: "Koothattukulam_Oliyappuram_D (Kerala)",
        FacilityCity: "Koothattukulam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9653,
        Pin: 734015,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9654,
        Pin: 721132,
        DispatchCenter: "Midnapore_Harishpur_D (West Bengal)",
        OriginCenter: "Midnapore_Harishpur_D (West Bengal)",
        FacilityCity: "Midnapore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9655,
        Pin: 535240,
        DispatchCenter: "Pendurthi_Mudapaka_DPP (Andhra Pradesh)",
        OriginCenter: "Pendurthi_Mudapaka_DPP (Andhra Pradesh)",
        FacilityCity: "Pendurthi",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9656,
        Pin: 630208,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9657,
        Pin: 638113,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9658,
        Pin: 631503,
        DispatchCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        OriginCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        FacilityCity: "Kanchipuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9659,
        Pin: 517213,
        DispatchCenter: "Pileru_Plot14_D (Andhra Pradesh)",
        OriginCenter: "Pileru_Plot14_D (Andhra Pradesh)",
        FacilityCity: "Pileru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9660,
        Pin: 686668,
        DispatchCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        OriginCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        FacilityCity: "Muvattupuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9661,
        Pin: 497773,
        DispatchCenter: "Chirimiri_HaldiBadi_DPP (Chhattisgarh)",
        OriginCenter: "Chirimiri_HaldiBadi_DPP (Chhattisgarh)",
        FacilityCity: "Chirimiri",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9662,
        Pin: 451224,
        DispatchCenter: "Maheshwar_DhmnodRd_DPP (Madhya Pradesh)",
        OriginCenter: "Maheshwar_DhmnodRd_DPP (Madhya Pradesh)",
        FacilityCity: "Maheshwar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 9663,
        Pin: 445106,
        DispatchCenter: "Arni_Bhimnagar_DPP (Maharashtra)",
        OriginCenter: "Arni_Bhimnagar_DPP (Maharashtra)",
        FacilityCity: "Arni",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9664,
        Pin: 248197,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9665,
        Pin: 332708,
        DispatchCenter: "Udaipurwati_Khandela_D (Rajasthan)",
        OriginCenter: "Udaipurwati_Khandela_D (Rajasthan)",
        FacilityCity: "Khandela",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9666,
        Pin: 843314,
        DispatchCenter: "Sitamarhi_Punaura_D (Bihar)",
        OriginCenter: "Sitamarhi_Punaura_D (Bihar)",
        FacilityCity: "Sitamarhi",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9667,
        Pin: 581342,
        DispatchCenter: "Honnavar_ForestColony_D (Karnataka)",
        OriginCenter: "Honnavar_ForestColony_D (Karnataka)",
        FacilityCity: "Honnavar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9668,
        Pin: 221705,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9669,
        Pin: 642144,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9670,
        Pin: 491771,
        DispatchCenter: "Balod_HaldharChowk_D (Chhattisgarh)",
        OriginCenter: "Balod_HaldharChowk_D (Chhattisgarh)",
        FacilityCity: "Balod",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9671,
        Pin: 518196,
        DispatchCenter: "Betamcherla_Bngnpalle_D (Andhra Pradesh)",
        OriginCenter: "Betamcherla_Bngnpalle_D (Andhra Pradesh)",
        FacilityCity: "Betamcherla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9672,
        Pin: 734316,
        DispatchCenter: "Kalimpong_Ward22_D (West Bengal)",
        OriginCenter: "Kalimpong_Ward22_D (West Bengal)",
        FacilityCity: "Kalimpong",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9673,
        Pin: 191111,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9674,
        Pin: 853203,
        DispatchCenter: "Naugachia_Mahaddipur_DPP (Bihar)",
        OriginCenter: "Naugachia_Mahaddipur_DPP (Bihar)",
        FacilityCity: "Naugachia",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9675,
        Pin: 560096,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9676,
        Pin: 160038,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9677,
        Pin: 732208,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9678,
        Pin: 636204,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9679,
        Pin: 786001,
        DispatchCenter: "Dibrugarh_Mohanbari_H (Assam)",
        OriginCenter: "Dibrugarh_Mohanbari_H (Assam)",
        FacilityCity: "Dibrugarh",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9680,
        Pin: 382865,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9681,
        Pin: 332706,
        DispatchCenter: "Udaipurwati_Khandela_D (Rajasthan)",
        OriginCenter: "Udaipurwati_Khandela_D (Rajasthan)",
        FacilityCity: "Khandela",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9682,
        Pin: 686634,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9683,
        Pin: 682308,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9684,
        Pin: 246421,
        DispatchCenter: "Rudraprayag_Dungri_DPP (Uttarakhand)",
        OriginCenter: "Rudraprayag_Dungri_DPP (Uttarakhand)",
        FacilityCity: "Rudraprayag",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9685,
        Pin: 626206,
        DispatchCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        OriginCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        FacilityCity: "Sivakasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9686,
        Pin: 591222,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9687,
        Pin: 632502,
        DispatchCenter: "Arakkonam_Ranipet_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ranipet_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9688,
        Pin: 382775,
        DispatchCenter: "Surendranagar_GIDC_D (Gujarat)",
        OriginCenter: "Surendranagar_GIDC_D (Gujarat)",
        FacilityCity: "Surendranagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9689,
        Pin: 508212,
        DispatchCenter: "Kusumanchi_HydRd_D (Telangana)",
        OriginCenter: "Kusumanchi_HydRd_D (Telangana)",
        FacilityCity: "Kusumanchi",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9690,
        Pin: 202413,
        DispatchCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        OriginCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        FacilityCity: "Moradabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9691,
        Pin: 515465,
        DispatchCenter: "Tadipatri_Kalavagadacol_D (Andhra Pradesh)",
        OriginCenter: "Tadipatri_Kalavagadacol_D (Andhra Pradesh)",
        FacilityCity: "Tadipatri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9692,
        Pin: 734006,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9693,
        Pin: 612504,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9694,
        Pin: 141020,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9695,
        Pin: 767070,
        DispatchCenter: "Saintala_Blockroad_DPP (Orissa)",
        OriginCenter: "Saintala_Blockroad_DPP (Orissa)",
        FacilityCity: "Saintala",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9696,
        Pin: 193303,
        DispatchCenter: "Baramulla_Watergam_DPP (Jammu & Kashmir)",
        OriginCenter: "Baramulla_Watergam_DPP (Jammu & Kashmir)",
        FacilityCity: "Baramulla",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9697,
        Pin: 209204,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9698,
        Pin: 733126,
        DispatchCenter: "Balurghat_AkhirDPP_D (West Bengal)",
        OriginCenter: "Balurghat_AkhirDPP_D (West Bengal)",
        FacilityCity: "Balurghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9699,
        Pin: 676525,
        DispatchCenter: "Pandikkad_Thangalpadi_D (Kerala)",
        OriginCenter: "Pandikkad_Thangalpadi_D (Kerala)",
        FacilityCity: "Pandikkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9700,
        Pin: 521106,
        DispatchCenter: "HanumanJn_Appanaveedu_D (Andhra Pradesh)",
        OriginCenter: "HanumanJn_Appanaveedu_D (Andhra Pradesh)",
        FacilityCity: "Pedapadu",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9701,
        Pin: 575004,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9702,
        Pin: 841424,
        DispatchCenter: "Mashrakh_Benchapra_D (Bihar)",
        OriginCenter: "Mashrakh_Benchapra_D (Bihar)",
        FacilityCity: "Mashrakh",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9703,
        Pin: 767033,
        DispatchCenter: "Titilagarh_RSRDDPP_D (Orissa)",
        OriginCenter: "Titilagarh_RSRDDPP_D (Orissa)",
        FacilityCity: "Titilagarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9704,
        Pin: 713333,
        DispatchCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        OriginCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        FacilityCity: "Rupnarayanpur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9705,
        Pin: 685590,
        DispatchCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        OriginCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        FacilityCity: "Thodupuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9706,
        Pin: 627108,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9707,
        Pin: 273006,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9708,
        Pin: 394664,
        DispatchCenter: "Songadh_Vyara_D (Gujarat)",
        OriginCenter: "Songadh_Vyara_D (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9709,
        Pin: 678597,
        DispatchCenter: "Karimba_Kaliyode_D (Kerala)",
        OriginCenter: "Karimba_Kaliyode_D (Kerala)",
        FacilityCity: "Karimba",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9710,
        Pin: 476134,
        DispatchCenter: "Morena_Ambah_D (Madhya Pradesh)",
        OriginCenter: "Morena_Ambah_D (Madhya Pradesh)",
        FacilityCity: "Morena",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 9711,
        Pin: 533226,
        DispatchCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasaraopet",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9712,
        Pin: 678611,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9713,
        Pin: 202415,
        DispatchCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        OriginCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        FacilityCity: "Moradabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9714,
        Pin: 244401,
        DispatchCenter: "Thakurdwara_Ward8_D (Uttar Pradesh)",
        OriginCenter: "Thakurdwara_Ward8_D (Uttar Pradesh)",
        FacilityCity: "Thakurdwara",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9715,
        Pin: 229410,
        DispatchCenter: "LalganjAjhara_HPPump_D (Uttar Pradesh)",
        OriginCenter: "LalganjAjhara_HPPump_D (Uttar Pradesh)",
        FacilityCity: "Lalganj Ajhara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9716,
        Pin: 522314,
        DispatchCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        OriginCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9717,
        Pin: 302033,
        DispatchCenter: "Jaipur_Ramchandpura_L (Rajasthan)",
        OriginCenter: "Jaipur_Ramchandpura_L (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9718,
        Pin: 577571,
        DispatchCenter: "Chitradurga_Basaveshwar_D (Karnataka)",
        OriginCenter: "Chitradurga_Basaveshwar_D (Karnataka)",
        FacilityCity: "Chitradurga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9719,
        Pin: 394541,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9720,
        Pin: 382160,
        DispatchCenter: "Viramgam_AxarNgr_DPP (Gujarat)",
        OriginCenter: "Viramgam_AxarNgr_DPP (Gujarat)",
        FacilityCity: "Viramgam",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9721,
        Pin: 262906,
        DispatchCenter: "Nighasan_Laudhri_D (Uttar Pradesh)",
        OriginCenter: "Nighasan_Laudhri_D (Uttar Pradesh)",
        FacilityCity: "Nighasan",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9722,
        Pin: 192305,
        DispatchCenter: "Anantnag_Bijbehara_D (Jammu & Kashmir)",
        OriginCenter: "Anantnag_Bijbehara_D (Jammu & Kashmir)",
        FacilityCity: "Anantnag",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9723,
        Pin: 803307,
        DispatchCenter: "Sheonor_Moldiyar_D (Bihar)",
        OriginCenter: "Sheonor_Moldiyar_D (Bihar)",
        FacilityCity: "Sheonar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9724,
        Pin: 848125,
        DispatchCenter: "KalyanpurBR_Ladaura_D (Bihar)",
        OriginCenter: "KalyanpurBR_Ladaura_D (Bihar)",
        FacilityCity: "KalyanpurBR",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9725,
        Pin: 686575,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9726,
        Pin: 847102,
        DispatchCenter: "Benipatti_Sarisab_D (Bihar)",
        OriginCenter: "Benipatti_Sarisab_D (Bihar)",
        FacilityCity: "Benipatti",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9727,
        Pin: 193101,
        DispatchCenter: "Baramulla_YaseenDPP_D (Jammu & Kashmir)",
        OriginCenter: "Baramulla_YaseenDPP_D (Jammu & Kashmir)",
        FacilityCity: "Baramulla",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9728,
        Pin: 515601,
        DispatchCenter: "Gorantla_Market_D (Andhra Pradesh)",
        OriginCenter: "Gorantla_Market_D (Andhra Pradesh)",
        FacilityCity: "Gorantla",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9729,
        Pin: 345021,
        DispatchCenter: "Pokhran_Sundernagar_D (Rajasthan)",
        OriginCenter: "Pokhran_Sundernagar_D (Rajasthan)",
        FacilityCity: "Pokhran",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9730,
        Pin: 251319,
        DispatchCenter: "Shamli_SalekChandVihar_D (Uttar Pradesh)",
        OriginCenter: "Shamli_SalekChandVihar_D (Uttar Pradesh)",
        FacilityCity: "Shamli",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9731,
        Pin: 416211,
        DispatchCenter: "RashiwadeBk_Shelewadi_DPP (Maharashtra)",
        OriginCenter: "RashiwadeBk_Shelewadi_DPP (Maharashtra)",
        FacilityCity: "Rashiwade Bk",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9732,
        Pin: 741506,
        DispatchCenter: "Hanskhali_Ashrampara_D (West Bengal)",
        OriginCenter: "Hanskhali_Ashrampara_D (West Bengal)",
        FacilityCity: "Hanskhali",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9733,
        Pin: 362276,
        DispatchCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        OriginCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        FacilityCity: "Veraval",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9734,
        Pin: 609703,
        DispatchCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        OriginCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        FacilityCity: "Karaikal",
        FacilityState: "Pondicherry",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9735,
        Pin: 415311,
        DispatchCenter: "Vita_Adarshnagar_D (Maharashtra)",
        OriginCenter: "Vita_Adarshnagar_D (Maharashtra)",
        FacilityCity: "Vita",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9736,
        Pin: 470672,
        DispatchCenter: "Damoh_Chouprakhurd_D (Madhya Pradesh)",
        OriginCenter: "Damoh_Chouprakhurd_D (Madhya Pradesh)",
        FacilityCity: "Damoh",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 9737,
        Pin: 626149,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9738,
        Pin: 441228,
        DispatchCenter: "Saoli_NagarPanchyt_DPP (Maharashtra)",
        OriginCenter: "Saoli_NagarPanchyt_DPP (Maharashtra)",
        FacilityCity: "Saoli",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9739,
        Pin: 160003,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9740,
        Pin: 843131,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9741,
        Pin: 246727,
        DispatchCenter: "Kanth_ModaPatti_DPP (Uttar Pradesh)",
        OriginCenter: "Kanth_ModaPatti_DPP (Uttar Pradesh)",
        FacilityCity: "Kanth",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9742,
        Pin: 509409,
        DispatchCenter: "Mahabubnagar_Devarakadra_DPP (Telangana)",
        OriginCenter: "Mahabubnagar_Devarakadra_DPP (Telangana)",
        FacilityCity: "Mahabubnagar",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9743,
        Pin: 311401,
        DispatchCenter: "Raila_PoliceSt_DPP (Rajasthan)",
        OriginCenter: "Raila_PoliceSt_DPP (Rajasthan)",
        FacilityCity: "Raila",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9744,
        Pin: 507114,
        DispatchCenter: "Kukanoor_Kivvaka_D (Andhra Pradesh)",
        OriginCenter: "Kukanoor_Kivvaka_D (Andhra Pradesh)",
        FacilityCity: "Kukanoor",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9745,
        Pin: 133002,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9746,
        Pin: 641670,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9747,
        Pin: 800006,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9748,
        Pin: 673524,
        DispatchCenter: "Perambra_KayipramRd_D (Kerala)",
        OriginCenter: "Perambra_KayipramRd_D (Kerala)",
        FacilityCity: "Perambra",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9749,
        Pin: 755044,
        DispatchCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        OriginCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        FacilityCity: "Chandikhol",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9750,
        Pin: 190023,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9751,
        Pin: 671313,
        DispatchCenter: "Nileshwar_Palathadam_D (Kerala)",
        OriginCenter: "Nileshwar_Palathadam_D (Kerala)",
        FacilityCity: "Nileshwar",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9752,
        Pin: 362570,
        DispatchCenter: "Una_StColony_D (Gujarat)",
        OriginCenter: "Una_StColony_D (Gujarat)",
        FacilityCity: "UNA",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9753,
        Pin: 752116,
        DispatchCenter: "Konark_Central_DPP_2 (Orissa)",
        OriginCenter: "Konark_Central_DPP_2 (Orissa)",
        FacilityCity: "Konark",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9754,
        Pin: 122104,
        DispatchCenter: "Nuh_Hamidcolony_D (Haryana)",
        OriginCenter: "Nuh_Hamidcolony_D (Haryana)",
        FacilityCity: "Nuh",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9755,
        Pin: 312616,
        DispatchCenter: "PratapgarhRJ_Badabag_DPP (Rajasthan)",
        OriginCenter: "PratapgarhRJ_Badabag_DPP (Rajasthan)",
        FacilityCity: "Pratapgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9756,
        Pin: 534235,
        DispatchCenter: "Kaikaluru_Akividu_DPP (Andhra Pradesh)",
        OriginCenter: "Kaikaluru_Akividu_DPP (Andhra Pradesh)",
        FacilityCity: "Kaikaluru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9757,
        Pin: 277405,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9758,
        Pin: 210001,
        DispatchCenter: "Banda_GayatriN_D (Uttar Pradesh)",
        OriginCenter: "Banda_GayatriN_D (Uttar Pradesh)",
        FacilityCity: "Banda",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9759,
        Pin: 140602,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9760,
        Pin: 700152,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9761,
        Pin: 160054,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9762,
        Pin: 800007,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9763,
        Pin: 754297,
        DispatchCenter: "Athagarh_Karikol_D (Orissa)",
        OriginCenter: "Athagarh_Karikol_D (Orissa)",
        FacilityCity: "Athagarh",
        FacilityState: "Orissa",
        Zone : "NE"
      },
      {
        SN: 9764,
        Pin: 847411,
        DispatchCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        OriginCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        FacilityCity: "Jhanjharpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9765,
        Pin: 736170,
        DispatchCenter: "CoochBehar_khagrabari_I (West Bengal)",
        OriginCenter: "CoochBehar_khagrabari_I (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9766,
        Pin: 230145,
        DispatchCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        OriginCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        FacilityCity: "Patti-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9767,
        Pin: 822114,
        DispatchCenter: "Garhwa_Bishunpur_D (Jharkhand)",
        OriginCenter: "Garhwa_Bishunpur_D (Jharkhand)",
        FacilityCity: "Garhwa",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9768,
        Pin: 394715,
        DispatchCenter: "Songadh_Vyara_D (Gujarat)",
        OriginCenter: "Songadh_Vyara_D (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9769,
        Pin: 222001,
        DispatchCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        OriginCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        FacilityCity: "Jaunpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9770,
        Pin: 395005,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9771,
        Pin: 271821,
        DispatchCenter: "Ikauna_Hndutmple_D (Uttar Pradesh)",
        OriginCenter: "Ikauna_Hndutmple_D (Uttar Pradesh)",
        FacilityCity: "Ikauna",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9772,
        Pin: 605005,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9773,
        Pin: 415103,
        DispatchCenter: "Karad_Malkapur1_D (Maharashtra)",
        OriginCenter: "Karad_Malkapur1_D (Maharashtra)",
        FacilityCity: "Karad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9774,
        Pin: 680523,
        DispatchCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        OriginCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9775,
        Pin: 486006,
        DispatchCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        OriginCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        FacilityCity: "Rewa",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9776,
        Pin: 370425,
        DispatchCenter: "Mundra_Umiyanagar_D (Gujarat)",
        OriginCenter: "Mundra_Umiyanagar_D (Gujarat)",
        FacilityCity: "Mundra",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9777,
        Pin: 632316,
        DispatchCenter: "Arani_PolurRd_D (Tamil Nadu)",
        OriginCenter: "Arani_PolurRd_D (Tamil Nadu)",
        FacilityCity: "Arani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9778,
        Pin: 732102,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9779,
        Pin: 209311,
        DispatchCenter: "Akbarpur_NationalHospital_D (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Akbarpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9780,
        Pin: 384121,
        DispatchCenter: "Himmatnagar_Hadiyol_D (Gujarat)",
        OriginCenter: "Himmatnagar_Hadiyol_D (Gujarat)",
        FacilityCity: "Himmatnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9781,
        Pin: 322216,
        DispatchCenter: "Nadoti_Ibrahimpur_D (Rajasthan)",
        OriginCenter: "Nadoti_Ibrahimpur_D (Rajasthan)",
        FacilityCity: "Nadoti",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9782,
        Pin: 462046,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9783,
        Pin: 508126,
        DispatchCenter: "Bhuvanagiri_Keesararoad_D (Telangana)",
        OriginCenter: "Bhuvanagiri_Keesararoad_D (Telangana)",
        FacilityCity: "Bhuvanagiri",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9784,
        Pin: 624107,
        DispatchCenter: "Kodaikanal_Mudliyrpurm_D (Tamil Nadu)",
        OriginCenter: "Kodaikanal_Mudliyrpurm_D (Tamil Nadu)",
        FacilityCity: "Kodaikanal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9785,
        Pin: 572228,
        DispatchCenter: "Huliyar_Azadnagar_D (Karnataka)",
        OriginCenter: "Huliyar_Azadnagar_D (Karnataka)",
        FacilityCity: "Huliyar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9786,
        Pin: 691577,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9787,
        Pin: 800012,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9788,
        Pin: 212106,
        DispatchCenter: "JariBazar_Gambhirpur_D (Uttar Pradesh)",
        OriginCenter: "JariBazar_Gambhirpur_D (Uttar Pradesh)",
        FacilityCity: "JariBazar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9789,
        Pin: 626115,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9790,
        Pin: 190005,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9791,
        Pin: 421402,
        DispatchCenter: "Murbad_Shirgaon_D (Maharashtra)",
        OriginCenter: "Murbad_Shirgaon_D (Maharashtra)",
        FacilityCity: "Murbad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9792,
        Pin: 800024,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9793,
        Pin: 624212,
        DispatchCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        OriginCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        FacilityCity: "Oddanchatram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9794,
        Pin: 802208,
        DispatchCenter: "Arwal_SipahPnchayt_D (Bihar)",
        OriginCenter: "Arwal_SipahPnchayt_D (Bihar)",
        FacilityCity: "Arwal",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9795,
        Pin: 757027,
        DispatchCenter: "RajBerhampur_Gohira_DPP (Orissa)",
        OriginCenter: "RajBerhampur_Gohira_DPP (Orissa)",
        FacilityCity: "Raj Berhampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9796,
        Pin: 442105,
        DispatchCenter: "Wardha_Alodi_D (Maharashtra)",
        OriginCenter: "Wardha_Alodi_D (Maharashtra)",
        FacilityCity: "Wardha",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9797,
        Pin: 412409,
        DispatchCenter: 37043.00011574074,
        OriginCenter: 37043.00011574074,
        FacilityCity: "Junnar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9798,
        Pin: 407001,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9799,
        Pin: 333504,
        DispatchCenter: "Buhana_Rajota_D (Rajasthan)",
        OriginCenter: "Buhana_Rajota_D (Rajasthan)",
        FacilityCity: "Buhana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9800,
        Pin: 626203,
        DispatchCenter: "Sattur_Thendralnagar_DPP (Tamil Nadu)",
        OriginCenter: "Sattur_Thendralnagar_DPP (Tamil Nadu)",
        FacilityCity: "Sattur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9801,
        Pin: 144207,
        DispatchCenter: "Dasuya_AdarshNagar_D (Punjab)",
        OriginCenter: "Dasuya_AdarshNagar_D (Punjab)",
        FacilityCity: "Dasuya",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9802,
        Pin: 628218,
        DispatchCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        OriginCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        FacilityCity: "Tiruchendur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9803,
        Pin: 673310,
        DispatchCenter: "Mahe_Chokli_D (Kerala)",
        OriginCenter: "Mahe_Chokli_D (Kerala)",
        FacilityCity: "Mahe",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9804,
        Pin: 841225,
        DispatchCenter: "Chhapra_Ramnagar_I (Bihar)",
        OriginCenter: "Chhapra_Ramnagar_I (Bihar)",
        FacilityCity: "Chhapra",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9805,
        Pin: 321021,
        DispatchCenter: "Bharatpur_DurgaNagar_D (Rajasthan)",
        OriginCenter: "Bharatpur_DurgaNagar_D (Rajasthan)",
        FacilityCity: "Bharatpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9806,
        Pin: 177601,
        DispatchCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        OriginCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        FacilityCity: "Jahu",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9807,
        Pin: 670563,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9808,
        Pin: 614625,
        DispatchCenter: "Thanjavur_Palampudur_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Palampudur_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9809,
        Pin: 341533,
        DispatchCenter: "Kuchmancity_PratapNagar_D (Rajasthan)",
        OriginCenter: "Kuchmancity_PratapNagar_D (Rajasthan)",
        FacilityCity: "Kuchaman",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9810,
        Pin: 636008,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9811,
        Pin: 673325,
        DispatchCenter: "Koyilandy_Komathukara_D (Kerala)",
        OriginCenter: "Koyilandy_Komathukara_D (Kerala)",
        FacilityCity: "Koyilandy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9812,
        Pin: 515413,
        DispatchCenter: "Tadipatri_Kalavagadacol_D (Andhra Pradesh)",
        OriginCenter: "Tadipatri_Kalavagadacol_D (Andhra Pradesh)",
        FacilityCity: "Tadipatri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9813,
        Pin: 721501,
        DispatchCenter: "AmaDubi_Silda_DPP (West Bengal)",
        OriginCenter: "AmaDubi_Silda_DPP (West Bengal)",
        FacilityCity: "Ama Dubi",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9814,
        Pin: 422222,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9815,
        Pin: 792123,
        DispatchCenter: "Jairampur_Mdiclcly_D (Arunachal Pradesh)",
        OriginCenter: "Jairampur_Mdiclcly_D (Arunachal Pradesh)",
        FacilityCity: "Jairampur",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9816,
        Pin: 461441,
        DispatchCenter: "Harda_Khirkiya1_DPP (Madhya Pradesh)",
        OriginCenter: "Harda_Khirkiya1_DPP (Madhya Pradesh)",
        FacilityCity: "Harda",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9817,
        Pin: 458339,
        DispatchCenter: "Mandsaur_Sindpan_I (Madhya Pradesh)",
        OriginCenter: "Mandsaur_Sindpan_I (Madhya Pradesh)",
        FacilityCity: "Mandsaur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 9818,
        Pin: 152005,
        DispatchCenter: "Ferozepur_DC (Punjab)",
        OriginCenter: "Ferozepur_DC (Punjab)",
        FacilityCity: "Firozpur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9819,
        Pin: 332702,
        DispatchCenter: "Ramgarh_Khoor_DPP (Rajasthan)",
        OriginCenter: "Ramgarh_Khoor_DPP (Rajasthan)",
        FacilityCity: "Ramgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9820,
        Pin: 736121,
        DispatchCenter: "CoochBehar_khagrabari_I (West Bengal)",
        OriginCenter: "CoochBehar_khagrabari_I (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9821,
        Pin: 784148,
        DispatchCenter: "Mangaldoi_Patharughat_D (Assam)",
        OriginCenter: "Mangaldoi_Patharughat_D (Assam)",
        FacilityCity: "Mangaldoi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9822,
        Pin: 721260,
        DispatchCenter: "Midnapore_Sarbera_DPP (West Bengal)",
        OriginCenter: "Midnapore_Sarbera_DPP (West Bengal)",
        FacilityCity: "Midnapore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9823,
        Pin: 676304,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9824,
        Pin: 440032,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9825,
        Pin: 305204,
        DispatchCenter: "Beawar_Peesangan_DPP (Rajasthan)",
        OriginCenter: "Beawar_Peesangan_DPP (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9826,
        Pin: 680669,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9827,
        Pin: 676303,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9828,
        Pin: 232329,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9829,
        Pin: 412107,
        DispatchCenter: "Pirangut_PoliceChowki_D (Maharashtra)",
        OriginCenter: "Pirangut_PoliceChowki_D (Maharashtra)",
        FacilityCity: "Pirangut",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9830,
        Pin: 361009,
        DispatchCenter: "Jamnagar_Chela_L (Gujarat)",
        OriginCenter: "Jamnagar_Chela_L (Gujarat)",
        FacilityCity: "Jamnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9831,
        Pin: 678613,
        DispatchCenter: "Akaloor_Lakdi_DPP (Kerala)",
        OriginCenter: "Akaloor_Lakdi_DPP (Kerala)",
        FacilityCity: "Akaloor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9832,
        Pin: 799281,
        DispatchCenter: "Kailashahar_Ward4_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Kailashahar",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9833,
        Pin: 502329,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9834,
        Pin: 756162,
        DispatchCenter: "Bhadrak_Deuli_D (Orissa)",
        OriginCenter: "Bhadrak_Deuli_D (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9835,
        Pin: 609115,
        DispatchCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        OriginCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        FacilityCity: "Sirkazhi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9836,
        Pin: 385565,
        DispatchCenter: "Tharad_Budhanpur_D (Gujarat)",
        OriginCenter: "Tharad_Budhanpur_D (Gujarat)",
        FacilityCity: "Tharad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9837,
        Pin: 222002,
        DispatchCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        OriginCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        FacilityCity: "Jaunpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9838,
        Pin: 414204,
        DispatchCenter: "Jamkhed_Manjarshumbha_DPP (Maharashtra)",
        OriginCenter: "Jamkhed_Manjarshumbha_DPP (Maharashtra)",
        FacilityCity: "Jamkhed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9839,
        Pin: 712616,
        DispatchCenter: "Arambag_BalibDPP_D (West Bengal)",
        OriginCenter: "Arambag_BalibDPP_D (West Bengal)",
        FacilityCity: "Arambag",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9840,
        Pin: 826003,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9841,
        Pin: 576283,
        DispatchCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        OriginCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9842,
        Pin: 508286,
        DispatchCenter: "Alair_Reddygudem_D (Telangana)",
        OriginCenter: "Alair_Reddygudem_D (Telangana)",
        FacilityCity: "Alair",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9843,
        Pin: 414006,
        DispatchCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        OriginCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        FacilityCity: "Ahmednagar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9844,
        Pin: 485113,
        DispatchCenter: "Satna_Nazirabad_I (Madhya Pradesh)",
        OriginCenter: "Satna_Nazirabad_I (Madhya Pradesh)",
        FacilityCity: "Satna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 9845,
        Pin: 247667,
        DispatchCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        OriginCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        FacilityCity: "Haridwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9846,
        Pin: 477660,
        DispatchCenter: "Bhind_Mehgaon_D (Madhya Pradesh)",
        OriginCenter: "Bhind_Mehgaon_D (Madhya Pradesh)",
        FacilityCity: "Bhind",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 9847,
        Pin: 577221,
        DispatchCenter: "Channagiri_Taralabalu_D (Karnataka)",
        OriginCenter: "Channagiri_Taralabalu_D (Karnataka)",
        FacilityCity: "Channagiri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9848,
        Pin: 503309,
        DispatchCenter: "Degloor_GouklNgr_DPP (Maharashtra)",
        OriginCenter: "Degloor_GouklNgr_DPP (Maharashtra)",
        FacilityCity: "Degloor",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9849,
        Pin: 496001,
        DispatchCenter: "Raigarh_Chhatamuda_D (Chhattisgarh)",
        OriginCenter: "Raigarh_Chhatamuda_D (Chhattisgarh)",
        FacilityCity: "Raigarh",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9850,
        Pin: 813210,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9851,
        Pin: 122000,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9852,
        Pin: 722111,
        DispatchCenter: "Barjora_PoliceStn_DPP (West Bengal)",
        OriginCenter: "Barjora_PoliceStn_DPP (West Bengal)",
        FacilityCity: "Barjora",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9853,
        Pin: 560022,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9854,
        Pin: 800028,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9855,
        Pin: 625203,
        DispatchCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        OriginCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        FacilityCity: "Vadipatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9856,
        Pin: 425412,
        DispatchCenter: "Nandurbar_Saharatown_D (Maharashtra)",
        OriginCenter: "Nandurbar_Saharatown_D (Maharashtra)",
        FacilityCity: "Nandurbar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9857,
        Pin: 824233,
        DispatchCenter: "Khizasarai_Lodipur_D (Bihar)",
        OriginCenter: "Khizasarai_Lodipur_D (Bihar)",
        FacilityCity: "Khizasarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9858,
        Pin: 362245,
        DispatchCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        OriginCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        FacilityCity: "Keshod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9859,
        Pin: 396590,
        DispatchCenter: "Vansda_Chikatiya_D (Gujarat)",
        OriginCenter: "Vansda_Chikatiya_D (Gujarat)",
        FacilityCity: "Vansda",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9860,
        Pin: 413315,
        DispatchCenter: "Pandharpur_RajCmplx_D (Maharashtra)",
        OriginCenter: "Pandharpur_RajCmplx_D (Maharashtra)",
        FacilityCity: "Pandharpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9861,
        Pin: 562127,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9862,
        Pin: 679577,
        DispatchCenter: "Tirur_Perunthallur_D (Kerala)",
        OriginCenter: "Tirur_Perunthallur_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9863,
        Pin: 682501,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9864,
        Pin: 362315,
        DispatchCenter: "Jetpur_Dasijivanpara_D (Gujarat)",
        OriginCenter: "Jetpur_Dasijivanpara_D (Gujarat)",
        FacilityCity: "Jetpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9865,
        Pin: 635651,
        DispatchCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        OriginCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        FacilityCity: "Tiruppattur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9866,
        Pin: 691504,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9867,
        Pin: 396475,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9868,
        Pin: 756059,
        DispatchCenter: "Bhadrak_Purusandha_D (Orissa)",
        OriginCenter: "Bhadrak_Purusandha_D (Orissa)",
        FacilityCity: "Agarpada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9869,
        Pin: 230134,
        DispatchCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        OriginCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        FacilityCity: "Patti-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9870,
        Pin: 507001,
        DispatchCenter: "Khammam_Mudigonda_H (Telangana)",
        OriginCenter: "Khammam_Mudigonda_H (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9871,
        Pin: 492017,
        DispatchCenter: "Raipur_Charoda_D (Chhattisgarh)",
        OriginCenter: "Raipur_Charoda_D (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        Zone : "E"
      },
      {
        SN: 9872,
        Pin: 636203,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9873,
        Pin: 403716,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9874,
        Pin: 441304,
        DispatchCenter: "Katol_Laxmingr_D (Maharashtra)",
        OriginCenter: "Katol_Laxmingr_D (Maharashtra)",
        FacilityCity: "Katol",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9875,
        Pin: 313201,
        DispatchCenter: "Udaipur_Mavli_DPP (Rajasthan)",
        OriginCenter: "Udaipur_Mavli_DPP (Rajasthan)",
        FacilityCity: "Udaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9876,
        Pin: 624714,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9877,
        Pin: 416615,
        DispatchCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        OriginCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        FacilityCity: "Devgad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9878,
        Pin: 413001,
        DispatchCenter: "Solapur_Balepeth_I (Maharashtra)",
        OriginCenter: "Solapur_Balepeth_I (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9879,
        Pin: 770001,
        DispatchCenter: "Sundargarh_BndhaPli_DPP (Orissa)",
        OriginCenter: "Sundargarh_BndhaPli_DPP (Orissa)",
        FacilityCity: "Sundargarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9880,
        Pin: 365001,
        DispatchCenter: "Rajkot_Amargadh_H (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9881,
        Pin: 689671,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9882,
        Pin: 711302,
        DispatchCenter: "Howrah_Temp_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9883,
        Pin: 766031,
        DispatchCenter: "MRampur_RisidaRd_D (Orissa)",
        OriginCenter: "MRampur_RisidaRd_D (Orissa)",
        FacilityCity: "M Rampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9884,
        Pin: 201015,
        DispatchCenter: "Noida_KheraDharmpura_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9885,
        Pin: 425501,
        DispatchCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        OriginCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        FacilityCity: "Raver",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9886,
        Pin: 364530,
        DispatchCenter: "Savarkundla_Manibhai_DPP (Gujarat)",
        OriginCenter: "Savarkundla_Manibhai_DPP (Gujarat)",
        FacilityCity: "Savarkundla",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9887,
        Pin: 425422,
        DispatchCenter: "Shahada_Untavad_D (Maharashtra)",
        OriginCenter: "Shahada_Untavad_D (Maharashtra)",
        FacilityCity: "Shahada",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9888,
        Pin: 476224,
        DispatchCenter: "Sabalgarh_SunherraRd_D (Madhya Pradesh)",
        OriginCenter: "Sabalgarh_SunherraRd_D (Madhya Pradesh)",
        FacilityCity: "Sabalgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9889,
        Pin: 241502,
        DispatchCenter: "Madhoganj_BaghuliRd_D (Uttar Pradesh)",
        OriginCenter: "Madhoganj_BaghuliRd_D (Uttar Pradesh)",
        FacilityCity: "Madhoganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9890,
        Pin: 192122,
        DispatchCenter: "Srinagar_Ladhoo_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Ladhoo_H (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9891,
        Pin: 313604,
        DispatchCenter: "Mandphiya_Kanore_DPP (Rajasthan)",
        OriginCenter: "Mandphiya_Kanore_DPP (Rajasthan)",
        FacilityCity: "Mandphiya",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9892,
        Pin: 722171,
        DispatchCenter: "Khatra_KoralDPP_D (West Bengal)",
        OriginCenter: "Khatra_KoralDPP_D (West Bengal)",
        FacilityCity: "Khatra",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9893,
        Pin: 493778,
        DispatchCenter: "Nagri_Tengna_D (Chhattisgarh)",
        OriginCenter: "Nagri_Tengna_D (Chhattisgarh)",
        FacilityCity: "Nagri",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9894,
        Pin: 221404,
        DispatchCenter: "Bhadohi_Chedibeer_D (Uttar Pradesh)",
        OriginCenter: "Bhadohi_Chedibeer_D (Uttar Pradesh)",
        FacilityCity: "Bhadohi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9895,
        Pin: 422604,
        DispatchCenter: "Akole_Mahalxminagar_D (Maharashtra)",
        OriginCenter: "Akole_Mahalxminagar_D (Maharashtra)",
        FacilityCity: "Akole",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9896,
        Pin: 700097,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9897,
        Pin: 225002,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9898,
        Pin: 523168,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9899,
        Pin: 628103,
        DispatchCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        OriginCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        FacilityCity: "Tuticorin",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9900,
        Pin: 523180,
        DispatchCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        OriginCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        FacilityCity: "Ongole",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9901,
        Pin: 630553,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9902,
        Pin: 125058,
        DispatchCenter: "Fatehabad_SirsaRd_D (Haryana)",
        OriginCenter: "Fatehabad_SirsaRd_D (Haryana)",
        FacilityCity: "Fatehabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9903,
        Pin: 181134,
        DispatchCenter: "Jakh_Channi_D (Jammu & Kashmir)",
        OriginCenter: "Jakh_Channi_D (Jammu & Kashmir)",
        FacilityCity: "Jakh",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9904,
        Pin: 522304,
        DispatchCenter: "Tenali_Pandurngpet_D (Andhra Pradesh)",
        OriginCenter: "Tenali_Pandurngpet_D (Andhra Pradesh)",
        FacilityCity: "Tenali",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9905,
        Pin: 125054,
        DispatchCenter: "Sirsa_Housingboardcol_D (Haryana)",
        OriginCenter: "Sirsa_Housingboardcol_D (Haryana)",
        FacilityCity: "Sirsa",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9906,
        Pin: 123024,
        DispatchCenter: "Loharu_HsptlRd_D (Haryana)",
        OriginCenter: "Loharu_HsptlRd_D (Haryana)",
        FacilityCity: "Loharu",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9907,
        Pin: 631201,
        DispatchCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9908,
        Pin: 209723,
        DispatchCenter: "Kannauj_Tirwaganj_D (Uttar Pradesh)",
        OriginCenter: "Kannauj_Tirwaganj_D (Uttar Pradesh)",
        FacilityCity: "Kannauj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9909,
        Pin: 263642,
        DispatchCenter: "Bageshwar_Nadigaon_DPP (Uttarakhand)",
        OriginCenter: "Bageshwar_Nadigaon_DPP (Uttarakhand)",
        FacilityCity: "Bageshwar",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9910,
        Pin: 365490,
        DispatchCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        OriginCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        Zone : "W1"
      },
      {
        SN: 9911,
        Pin: 335064,
        DispatchCenter: "Hanumangarh_DhaanMandi_I (Rajasthan)",
        OriginCenter: "Hanumangarh_DhaanMandi_I (Rajasthan)",
        FacilityCity: "Hanumangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9912,
        Pin: 414501,
        DispatchCenter: "Pathardi_Shivshaktinagar_D (Maharashtra)",
        OriginCenter: "Pathardi_Shivshaktinagar_D (Maharashtra)",
        FacilityCity: "Pathardi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9913,
        Pin: 323802,
        DispatchCenter: "Nainwa_Ganeshpura_D (Rajasthan)",
        OriginCenter: "Nainwa_Ganeshpura_D (Rajasthan)",
        FacilityCity: "Nainwa",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9914,
        Pin: 629603,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9915,
        Pin: 413130,
        DispatchCenter: "Daund_Kurkumbh_L (Maharashtra)",
        OriginCenter: "Daund_Kurkumbh_L (Maharashtra)",
        FacilityCity: "Daund",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9916,
        Pin: 416705,
        DispatchCenter: "Rajapur_Sainagar_D (Maharashtra)",
        OriginCenter: "Rajapur_Sainagar_D (Maharashtra)",
        FacilityCity: "Rajapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9917,
        Pin: 756026,
        DispatchCenter: "Basta_Darada_DPP (Orissa)",
        OriginCenter: "Basta_Darada_DPP (Orissa)",
        FacilityCity: "Basta",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9918,
        Pin: 225412,
        DispatchCenter: "Barabanki_Pyaranpur_D (Uttar Pradesh)",
        OriginCenter: "Barabanki_Pyaranpur_D (Uttar Pradesh)",
        FacilityCity: "Haidargarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9919,
        Pin: 683577,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9920,
        Pin: 202001,
        DispatchCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        OriginCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9921,
        Pin: 713360,
        DispatchCenter: "Asansol_Gobindapur_D (West Bengal)",
        OriginCenter: "Asansol_Gobindapur_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9922,
        Pin: 695034,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9923,
        Pin: 795114,
        DispatchCenter: "Imphal_Lamlai_D (Manipur)",
        OriginCenter: "Imphal_Lamlai_D (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9924,
        Pin: 387355,
        DispatchCenter: "Nadiad_Shantinagar_D (Gujarat)",
        OriginCenter: "Nadiad_Shantinagar_D (Gujarat)",
        FacilityCity: "Nadiad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9925,
        Pin: 313804,
        DispatchCenter: "Kherwara_Banjariya_D (Rajasthan)",
        OriginCenter: "Kherwara_Banjariya_D (Rajasthan)",
        FacilityCity: "Kherwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9926,
        Pin: 686545,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9927,
        Pin: 845458,
        DispatchCenter: "Motihari_Lauriya_D (Bihar)",
        OriginCenter: "Motihari_Lauriya_D (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9928,
        Pin: 518350,
        DispatchCenter: "Yemignur_Timmappacolony_D (Andhra Pradesh)",
        OriginCenter: "Yemignur_Timmappacolony_D (Andhra Pradesh)",
        FacilityCity: "Yemmiganur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9929,
        Pin: 560103,
        DispatchCenter: "Bangalore_Koralur_L (Karnataka)",
        OriginCenter: "Bangalore_Soukyaroad_GW (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9930,
        Pin: 766001,
        DispatchCenter: "Bhawanipatna_Dangaripadar_H (Orissa)",
        OriginCenter: "Bhawanipatna_Dangaripadar_H (Orissa)",
        FacilityCity: "Bhawanipatna",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9931,
        Pin: 333043,
        DispatchCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        OriginCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9932,
        Pin: 193221,
        DispatchCenter: "Kupwara_Chotipora_DPP (Jammu & Kashmir)",
        OriginCenter: "Kupwara_Chotipora_DPP (Jammu & Kashmir)",
        FacilityCity: "Kupwara",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9933,
        Pin: 629172,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9934,
        Pin: 534112,
        DispatchCenter: "Nallajerla_Anntpalli_D (Andhra Pradesh)",
        OriginCenter: "Nallajerla_Anntpalli_D (Andhra Pradesh)",
        FacilityCity: "Nallajerla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9935,
        Pin: 496223,
        DispatchCenter: "Kunkuri_Dugdugiya_D (Chhattisgarh)",
        OriginCenter: "Kunkuri_Dugdugiya_D (Chhattisgarh)",
        FacilityCity: "Kunkuri",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9936,
        Pin: 636811,
        DispatchCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        OriginCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        FacilityCity: "Dharmapuri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9937,
        Pin: 623709,
        DispatchCenter: "Paramakudi_River_D (Tamil Nadu)",
        OriginCenter: "Paramakudi_River_D (Tamil Nadu)",
        FacilityCity: "Paramakudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9938,
        Pin: 759015,
        DispatchCenter: "Dhenkanal_Rasol_D (Orissa)",
        OriginCenter: "Dhenkanal_Rasol_D (Orissa)",
        FacilityCity: "Dhenkanal",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9939,
        Pin: 421104,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9940,
        Pin: 263637,
        DispatchCenter: "Someshwar_Lakhnari_DPP (Uttarakhand)",
        OriginCenter: "Someshwar_Lakhnari_DPP (Uttarakhand)",
        FacilityCity: "Someshwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9941,
        Pin: 518543,
        DispatchCenter: "Allagadda_Ramalayam_DPP (Andhra Pradesh)",
        OriginCenter: "Allagadda_Ramalayam_DPP (Andhra Pradesh)",
        FacilityCity: "Allagadda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9942,
        Pin: 402203,
        DispatchCenter: "Alibag_Shivanagar_D (Maharashtra)",
        OriginCenter: "Alibag_Shivanagar_D (Maharashtra)",
        FacilityCity: "Alibag",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9943,
        Pin: 505172,
        DispatchCenter: "KarimNagar_Alugunoor_I (Telangana)",
        OriginCenter: "KarimNagar_Alugunoor_I (Telangana)",
        FacilityCity: "Karim Nagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9944,
        Pin: 144212,
        DispatchCenter: "Dasuya_AdarshNagar_D (Punjab)",
        OriginCenter: "Dasuya_AdarshNagar_D (Punjab)",
        FacilityCity: "Dasuya",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 9945,
        Pin: 671320,
        DispatchCenter: "Kanhangad_Ajanur_D (Kerala)",
        OriginCenter: "Kanhangad_Ajanur_D (Kerala)",
        FacilityCity: "Kasaragod",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9946,
        Pin: 425402,
        DispatchCenter: "Amalner_Sriramcolony_DPP (Maharashtra)",
        OriginCenter: "Amalner_Sriramcolony_DPP (Maharashtra)",
        FacilityCity: "Amalner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9947,
        Pin: 577555,
        DispatchCenter: "Chitradurga_Basaveshwar_D (Karnataka)",
        OriginCenter: "Chitradurga_Basaveshwar_D (Karnataka)",
        FacilityCity: "Chitradurga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9948,
        Pin: 516361,
        DispatchCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        OriginCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        FacilityCity: "Proddatur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9949,
        Pin: 585413,
        DispatchCenter: "Bidar_AdarshClny_D (Karnataka)",
        OriginCenter: "Bidar_AdarshClny_D (Karnataka)",
        FacilityCity: "Bidar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9950,
        Pin: 382023,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9951,
        Pin: 524222,
        DispatchCenter: "Udayagiri_Msjidcmplx_D (Andhra Pradesh)",
        OriginCenter: "Udayagiri_Msjidcmplx_D (Andhra Pradesh)",
        FacilityCity: "Udayagiri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9952,
        Pin: 770015,
        DispatchCenter: "Subdega_Gaibira_D (Orissa)",
        OriginCenter: "Subdega_Gaibira_D (Orissa)",
        FacilityCity: "Subdega",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9953,
        Pin: 671122,
        DispatchCenter: "Kasaragod_Kudlu_D (Kerala)",
        OriginCenter: "Kasaragod_Kudlu_D (Kerala)",
        FacilityCity: "Kasaragod",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9954,
        Pin: 325221,
        DispatchCenter: "Kawai_KhanpurRd_DPP (Rajasthan)",
        OriginCenter: "Kawai_KhanpurRd_DPP (Rajasthan)",
        FacilityCity: "Kawai",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9955,
        Pin: 731124,
        DispatchCenter: "Dubrajpur_Mansayer_D (West Bengal)",
        OriginCenter: "Dubrajpur_Mansayer_D (West Bengal)",
        FacilityCity: "Dubrajpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9956,
        Pin: 695006,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9957,
        Pin: 630303,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9958,
        Pin: 508245,
        DispatchCenter: "Yacharam_Nallavelly_D (Telangana)",
        OriginCenter: "Yacharam_Nallavelly_D (Telangana)",
        FacilityCity: "Yacharam",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9959,
        Pin: 474001,
        DispatchCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        OriginCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        FacilityCity: "Gwalior",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9960,
        Pin: 396240,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9961,
        Pin: 626161,
        DispatchCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        OriginCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        FacilityCity: "Aruppukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9962,
        Pin: 848128,
        DispatchCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        OriginCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        FacilityCity: "Dalsingh Sarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9963,
        Pin: 424310,
        DispatchCenter: "Sakri_Arihantnagar_D (Maharashtra)",
        OriginCenter: "Sakri_Arihantnagar_D (Maharashtra)",
        FacilityCity: "Sakri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 9964,
        Pin: 534427,
        DispatchCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        OriginCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        FacilityCity: "Eluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9965,
        Pin: 143520,
        DispatchCenter: "Gurdaspur_Civillines_D (Punjab)",
        OriginCenter: "Gurdaspur_Civillines_D (Punjab)",
        FacilityCity: "Gurdaspur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9966,
        Pin: 233303,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9967,
        Pin: 384255,
        DispatchCenter: "Patan_Hansapur_D (Gujarat)",
        OriginCenter: "Patan_Hansapur_D (Gujarat)",
        FacilityCity: "Patan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9968,
        Pin: 788031,
        DispatchCenter: "Udarbond_Pangram_D (Assam)",
        OriginCenter: "Udarbond_Pangram_D (Assam)",
        FacilityCity: "Udarbond",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9969,
        Pin: 181131,
        DispatchCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 9970,
        Pin: 631603,
        DispatchCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        OriginCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        FacilityCity: "Kanchipuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9971,
        Pin: 311022,
        DispatchCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        OriginCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        FacilityCity: "Bijainagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9972,
        Pin: 396105,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 9973,
        Pin: 604102,
        DispatchCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        OriginCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        FacilityCity: "Tindivanam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9974,
        Pin: 691537,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 9975,
        Pin: 334401,
        DispatchCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        OriginCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        FacilityCity: "Bikaner",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9976,
        Pin: 784004,
        DispatchCenter: "Tezpur_Dipota_H (Assam)",
        OriginCenter: "Tezpur_Dipota_H (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9977,
        Pin: 788166,
        DispatchCenter: "Ramkrishnanagar_Kalinagar_D (Assam)",
        OriginCenter: "Ramkrishnanagar_Kalinagar_D (Assam)",
        FacilityCity: "Ramkrishna Nagar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 9978,
        Pin: 721670,
        DispatchCenter: "Haldia_Basudevpur_D (West Bengal)",
        OriginCenter: "Haldia_Basudevpur_D (West Bengal)",
        FacilityCity: "Haldia",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9979,
        Pin: 515762,
        DispatchCenter: "Kalyandurg_JayNagar_D (Andhra Pradesh)",
        OriginCenter: "Kalyandurg_JayNagar_D (Andhra Pradesh)",
        FacilityCity: "Kalyandurg",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9980,
        Pin: 395008,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9981,
        Pin: 480447,
        DispatchCenter: "Parasia_TekMohlla_DPP (Madhya Pradesh)",
        OriginCenter: "Parasia_TekMohlla_DPP (Madhya Pradesh)",
        FacilityCity: "Parasia",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 9982,
        Pin: 673570,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 9983,
        Pin: 700007,
        DispatchCenter: "Kolkata_Bagmari_L (West Bengal)",
        OriginCenter: "Kolkata_Bagmari_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9984,
        Pin: 571125,
        DispatchCenter: "Hunsur_HDKote_D (Karnataka)",
        OriginCenter: "Hunsur_HDKote_D (Karnataka)",
        FacilityCity: "Hunsur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 9985,
        Pin: 415711,
        DispatchCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        OriginCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        FacilityCity: "Khed",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9986,
        Pin: 577101,
        DispatchCenter: "Chikmagalur_Jayanagara_D (Karnataka)",
        OriginCenter: "Chikmagalur_Jayanagara_D (Karnataka)",
        FacilityCity: "Chikkamagalur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9987,
        Pin: 835224,
        DispatchCenter: "Simdega_KhutiTola_D (Jharkhand)",
        OriginCenter: "Simdega_KhutiTola_D (Jharkhand)",
        FacilityCity: "Simdega",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 9988,
        Pin: 743123,
        DispatchCenter: "Naihati_Jagatdal_DPP (West Bengal)",
        OriginCenter: "Naihati_Jagatdal_DPP (West Bengal)",
        FacilityCity: "Naihati",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 9989,
        Pin: 110102,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9990,
        Pin: 444510,
        DispatchCenter: "Risod_BusStand_DPP (Maharashtra)",
        OriginCenter: "Risod_BusStand_DPP (Maharashtra)",
        FacilityCity: "Risod",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9991,
        Pin: 756047,
        DispatchCenter: "Soro_Ashoknagar_D (Orissa)",
        OriginCenter: "Soro_Ashoknagar_D (Orissa)",
        FacilityCity: "Soro",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9992,
        Pin: 421103,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 9993,
        Pin: 756046,
        DispatchCenter: "Soro_Ashoknagar_D (Orissa)",
        OriginCenter: "Soro_Ashoknagar_D (Orissa)",
        FacilityCity: "Soro",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 9994,
        Pin: 302023,
        DispatchCenter: "Jaipur_VishwakarmaIndArea_L (Rajasthan)",
        OriginCenter: "Jaipur_VishwakarmaIndArea_L (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9995,
        Pin: 362030,
        DispatchCenter: "Bhesan_STroad_DPP (Gujarat)",
        OriginCenter: "Bhesan_STroad_DPP (Gujarat)",
        FacilityCity: "Bhesan",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 9996,
        Pin: 508111,
        DispatchCenter: "Mothkur_Mainroad_D (Telangana)",
        OriginCenter: "Mothkur_Mainroad_D (Telangana)",
        FacilityCity: "Mothkur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 9997,
        Pin: 242123,
        DispatchCenter: "Khutar_Narayanpur_D (Uttar Pradesh)",
        OriginCenter: "Khutar_Narayanpur_D (Uttar Pradesh)",
        FacilityCity: "Khutar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 9998,
        Pin: 246765,
        DispatchCenter: "Nagina_DhampurRd_DPP (Uttar Pradesh)",
        OriginCenter: "Nagina_DhampurRd_DPP (Uttar Pradesh)",
        FacilityCity: "Nagina",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 9999,
        Pin: 421605,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10000,
        Pin: 190008,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10001,
        Pin: 688525,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10002,
        Pin: 143112,
        DispatchCenter: "Tarsikka_Beas_D (Punjab)",
        OriginCenter: "Tarsikka_Beas_D (Punjab)",
        FacilityCity: "Beas",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10003,
        Pin: 136131,
        DispatchCenter: "Kurukshetra_DDColony_D (Haryana)",
        OriginCenter: "Kurukshetra_DDColony_D (Haryana)",
        FacilityCity: "Kurukshetra",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10004,
        Pin: 689509,
        DispatchCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        OriginCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10005,
        Pin: 643216,
        DispatchCenter: "Kotagiri_CroslySRT_D (Tamil Nadu)",
        OriginCenter: "Kotagiri_CroslySRT_D (Tamil Nadu)",
        FacilityCity: "Kotagiri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10006,
        Pin: 521369,
        DispatchCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        OriginCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        FacilityCity: "Machilipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10007,
        Pin: 721302,
        DispatchCenter: "Kharagpur_Nimpura_I (West Bengal)",
        OriginCenter: "Kharagpur_Nimpura_I (West Bengal)",
        FacilityCity: "Kharagpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10008,
        Pin: 535525,
        DispatchCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        OriginCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        FacilityCity: "Parvathipuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10009,
        Pin: 796190,
        DispatchCenter: "Aizawl_Aibawk_D (Mizoram)",
        OriginCenter: "Aizawl_Aibawk_D (Mizoram)",
        FacilityCity: "Aizawl",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10010,
        Pin: 423117,
        DispatchCenter: "Chandwad_MarketYrd_DPP (Maharashtra)",
        OriginCenter: "Chandwad_MarketYrd_DPP (Maharashtra)",
        FacilityCity: "Chandwad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10011,
        Pin: 848518,
        DispatchCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        OriginCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        FacilityCity: "Dalsingh Sarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10012,
        Pin: 272192,
        DispatchCenter: "Domariyaganj_Chaukhara_D (Uttar Pradesh)",
        OriginCenter: "Domariyaganj_Chaukhara_D (Uttar Pradesh)",
        FacilityCity: "Domariyaganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10013,
        Pin: 224168,
        DispatchCenter: "Akbarpur_AmbedkarNgr_D (Uttar Pradesh)",
        OriginCenter: "Akbarpur_AmbedkarNgr_D (Uttar Pradesh)",
        FacilityCity: "Akbarpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10014,
        Pin: 172106,
        DispatchCenter: "Kinnaur_Kamru_DPP (Himachal Pradesh)",
        OriginCenter: "Kinnaur_Kamru_DPP (Himachal Pradesh)",
        FacilityCity: "Kinnaur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10015,
        Pin: 626531,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10016,
        Pin: 735227,
        DispatchCenter: "Alipurduar_Kalchini_DPP (West Bengal)",
        OriginCenter: "Alipurduar_Kalchini_DPP (West Bengal)",
        FacilityCity: "Alipurduar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10017,
        Pin: 431212,
        DispatchCenter: "Georai_Jayakwadicolony_D (Maharashtra)",
        OriginCenter: "Georai_Jayakwadicolony_D (Maharashtra)",
        FacilityCity: "Georai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10018,
        Pin: 416550,
        DispatchCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        OriginCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        FacilityCity: "Sawantwadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10019,
        Pin: 735133,
        DispatchCenter: "Jalpaiguri_Gomostapara_D (West Bengal)",
        OriginCenter: "Jalpaiguri_Gomostapara_D (West Bengal)",
        FacilityCity: "Jalpaiguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10020,
        Pin: 686595,
        DispatchCenter: "Pala_PonadKaroor_D (Kerala)",
        OriginCenter: "Pala_PonadKaroor_D (Kerala)",
        FacilityCity: "Pala",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10021,
        Pin: 642206,
        DispatchCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        OriginCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        FacilityCity: "Udumalaipettai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10022,
        Pin: 416519,
        DispatchCenter: "Oras_Ranbambuli_D (Maharashtra)",
        OriginCenter: "Oras_Ranbambuli_D (Maharashtra)",
        FacilityCity: "Oras",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10023,
        Pin: 425423,
        DispatchCenter: "Shahada_Untavad_D (Maharashtra)",
        OriginCenter: "Shahada_Untavad_D (Maharashtra)",
        FacilityCity: "Shahada",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10024,
        Pin: 422003,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10025,
        Pin: 122110,
        DispatchCenter: "Bhiwadi_Central_D_2 (Rajasthan)",
        OriginCenter: "Bhiwadi_Central_D_2 (Rajasthan)",
        FacilityCity: "Bhiwadi",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10026,
        Pin: 221012,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10027,
        Pin: 388265,
        DispatchCenter: "Balasinor_Alnoor_DPP (Gujarat)",
        OriginCenter: "Balasinor_Alnoor_DPP (Gujarat)",
        FacilityCity: "Balasinor",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10028,
        Pin: 781027,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10029,
        Pin: 400073,
        DispatchCenter: "Mumbai_Chembur_L (Maharashtra)",
        OriginCenter: "Mumbai_Chembur_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10030,
        Pin: 250515,
        DispatchCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        OriginCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        FacilityCity: "Baraut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10031,
        Pin: 272189,
        DispatchCenter: "Domariyaganj_Chaukhara_D (Uttar Pradesh)",
        OriginCenter: "Domariyaganj_Chaukhara_D (Uttar Pradesh)",
        FacilityCity: "Domariyaganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10032,
        Pin: 535125,
        DispatchCenter: "Garividi_SriRamNagar_D (Andhra Pradesh)",
        OriginCenter: "Garividi_SriRamNagar_D (Andhra Pradesh)",
        FacilityCity: "Garividi",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10033,
        Pin: 680614,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10034,
        Pin: 425421,
        DispatchCenter: "Shirpur_KrantiNgr_D (Maharashtra)",
        OriginCenter: "Shirpur_KrantiNgr_D (Maharashtra)",
        FacilityCity: "Shirpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10035,
        Pin: 734313,
        DispatchCenter: "Mungpoo_Chawrasta_DPP (West Bengal)",
        OriginCenter: "Mungpoo_Chawrasta_DPP (West Bengal)",
        FacilityCity: "Mungpoo",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10036,
        Pin: 712202,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10037,
        Pin: 785018,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10038,
        Pin: 621101,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10039,
        Pin: 585353,
        DispatchCenter: "Humnabad_ApmcYard_DPP (Karnataka)",
        OriginCenter: "Humnabad_ApmcYard_DPP (Karnataka)",
        FacilityCity: "Humnabad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10040,
        Pin: 587201,
        DispatchCenter: "Badami_SarafBazar_DPP (Karnataka)",
        OriginCenter: "Badami_SarafBazar_DPP (Karnataka)",
        FacilityCity: "Badami",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10041,
        Pin: 232101,
        DispatchCenter: "Chandauli_Mughalsarai_D (Uttar Pradesh)",
        OriginCenter: "Chandauli_Mughalsarai_D (Uttar Pradesh)",
        FacilityCity: "Mughal Sarai",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10042,
        Pin: 416137,
        DispatchCenter: "Sangli_Dattanagar_D (Maharashtra)",
        OriginCenter: "Sangli_Dattanagar_D (Maharashtra)",
        FacilityCity: "Sangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10043,
        Pin: 670304,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10044,
        Pin: 636301,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10045,
        Pin: 643226,
        DispatchCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        OriginCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        FacilityCity: "Gudalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10046,
        Pin: 534176,
        DispatchCenter: "Nallajerla_Anntpalli_D (Andhra Pradesh)",
        OriginCenter: "Nallajerla_Anntpalli_D (Andhra Pradesh)",
        FacilityCity: "Nallajerla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10047,
        Pin: 813231,
        DispatchCenter: "Kahalgaon_Budhuchak_D (Bihar)",
        OriginCenter: "Kahalgaon_Budhuchak_D (Bihar)",
        FacilityCity: "Kahalgaon",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10048,
        Pin: 534281,
        DispatchCenter: "Narsapur_Rustumbada_D (Andhra Pradesh)",
        OriginCenter: "Narsapur_Rustumbada_D (Andhra Pradesh)",
        FacilityCity: "Narsapur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10049,
        Pin: 843113,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10050,
        Pin: 209859,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10051,
        Pin: 396170,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10052,
        Pin: 389322,
        DispatchCenter: "Halol_DhavalPark_D (Gujarat)",
        OriginCenter: "Halol_DhavalPark_D (Gujarat)",
        FacilityCity: "Halol",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10053,
        Pin: 314036,
        DispatchCenter: "Dungarpur_IrolZoneCmplx_DPP (Rajasthan)",
        OriginCenter: "Dungarpur_IrolZoneCmplx_DPP (Rajasthan)",
        FacilityCity: "Dungarpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10054,
        Pin: 799156,
        DispatchCenter: "ManuBazar_RajibNgr_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Manu bazar",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10055,
        Pin: 500050,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10056,
        Pin: 276129,
        DispatchCenter: "Dullahpur_Sarsena_D (Uttar Pradesh)",
        OriginCenter: "Dullahpur_Sarsena_D (Uttar Pradesh)",
        FacilityCity: "Dullahpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10057,
        Pin: 689573,
        DispatchCenter: "Haripad_Thalavady_D (Kerala)",
        OriginCenter: "Haripad_Thalavady_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10058,
        Pin: 400062,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10059,
        Pin: 515787,
        DispatchCenter: "Kalyandurg_JayNagar_D (Andhra Pradesh)",
        OriginCenter: "Kalyandurg_JayNagar_D (Andhra Pradesh)",
        FacilityCity: "Kalyandurg",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10060,
        Pin: 768017,
        DispatchCenter: "Sambalpur_Tentelpara_H (Orissa)",
        OriginCenter: "Sambalpur_Tentelpara_H (Orissa)",
        FacilityCity: "Sambalpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10061,
        Pin: 390022,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10062,
        Pin: 768228,
        DispatchCenter: "Bamra_Govindpur_D (Orissa)",
        OriginCenter: "Bamra_Govindpur_D (Orissa)",
        FacilityCity: "Bamra",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10063,
        Pin: 791121,
        DispatchCenter: "NorthLakhimpur_Charaimoriya_D (Assam)",
        OriginCenter: "NorthLakhimpur_Charaimoriya_D (Assam)",
        FacilityCity: "North Lakhimpur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10064,
        Pin: 364750,
        DispatchCenter: "Botad_Paliyadrd_D (Gujarat)",
        OriginCenter: "Botad_Paliyadrd_D (Gujarat)",
        FacilityCity: "Botad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10065,
        Pin: 577433,
        DispatchCenter: "Shiralakoppa_Shikarpur_DPP (Karnataka)",
        OriginCenter: "Shiralakoppa_Shikarpur_DPP (Karnataka)",
        FacilityCity: "Shiralakoppa",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10066,
        Pin: 301025,
        DispatchCenter: "Alwar_Maancolony_D (Rajasthan)",
        OriginCenter: "Alwar_Maancolony_D (Rajasthan)",
        FacilityCity: "Alwar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10067,
        Pin: 613301,
        DispatchCenter: "Karambakkudi_Central_D (Tamil Nadu)",
        OriginCenter: "Karambakkudi_Central_D (Tamil Nadu)",
        FacilityCity: "Karambakkudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10068,
        Pin: 591509,
        DispatchCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        OriginCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        FacilityCity: "Gokak",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10069,
        Pin: 141006,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10070,
        Pin: 795149,
        DispatchCenter: "Imphal_Takyel_I (Manipur)",
        OriginCenter: "Imphal_Takyel_I (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10071,
        Pin: 500409,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10072,
        Pin: 673648,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10073,
        Pin: 144208,
        DispatchCenter: "Hoshiarpur_Hariana_D (Punjab)",
        OriginCenter: "Hoshiarpur_Hariana_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10074,
        Pin: 208017,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10075,
        Pin: 784154,
        DispatchCenter: "Tezpur_Dipota_H (Assam)",
        OriginCenter: "Tezpur_Dipota_H (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10076,
        Pin: 313701,
        DispatchCenter: "Jhadol_Leelawas_D (Rajasthan)",
        OriginCenter: "Jhadol_Leelawas_D (Rajasthan)",
        FacilityCity: "Jhadol",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10077,
        Pin: 530039,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10078,
        Pin: 576114,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10079,
        Pin: 147204,
        DispatchCenter: "Dhuri_Bhalwanroad_D (Punjab)",
        OriginCenter: "Dhuri_Bhalwanroad_D (Punjab)",
        FacilityCity: "Dhuri",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10080,
        Pin: 531011,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10081,
        Pin: 174321,
        DispatchCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        OriginCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        FacilityCity: "Bangana",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10082,
        Pin: 125039,
        DispatchCenter: "Jind_SunderNagar_D (Haryana)",
        OriginCenter: "Jind_SunderNagar_D (Haryana)",
        FacilityCity: "Jind",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10083,
        Pin: 636138,
        DispatchCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        OriginCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        FacilityCity: "Attur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10084,
        Pin: 443302,
        DispatchCenter: "Lonar_Sahkarnagar_DPP (Maharashtra)",
        OriginCenter: "Lonar_Sahkarnagar_DPP (Maharashtra)",
        FacilityCity: "Lonar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10085,
        Pin: 796012,
        DispatchCenter: "Aizawl_Sairang_I (Mizoram)",
        OriginCenter: "Aizawl_Sairang_I (Mizoram)",
        FacilityCity: "Aizawl",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10086,
        Pin: 560098,
        DispatchCenter: "Bangalore_FCILayout_L (Karnataka)",
        OriginCenter: "Bangalore_FCILayout_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10087,
        Pin: 391310,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10088,
        Pin: 847404,
        DispatchCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        OriginCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        FacilityCity: "Jhanjharpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10089,
        Pin: 688528,
        DispatchCenter: "Alappuzha_Nedumbrakkad_D (Kerala)",
        OriginCenter: "Alappuzha_Nedumbrakkad_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10090,
        Pin: 691536,
        DispatchCenter: "Kilimanoor_AKNArcard_D (Kerala)",
        OriginCenter: "Kilimanoor_AKNArcard_D (Kerala)",
        FacilityCity: "Kilimanoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10091,
        Pin: 190017,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10092,
        Pin: 852125,
        DispatchCenter: "Simrahi_Bazar_D (Bihar)",
        OriginCenter: "Simrahi_Bazar_D (Bihar)",
        FacilityCity: "Simrahi Bazar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10093,
        Pin: 411055,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10094,
        Pin: 781365,
        DispatchCenter: "Baihata_Sundhia_D (Assam)",
        OriginCenter: "Baihata_Sundhia_D (Assam)",
        FacilityCity: "Baihata",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10095,
        Pin: 586245,
        DispatchCenter: "Muddebihal_Hudcocolony_D (Karnataka)",
        OriginCenter: "Muddebihal_Hudcocolony_D (Karnataka)",
        FacilityCity: "Muddebihal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10096,
        Pin: 175021,
        DispatchCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        OriginCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        FacilityCity: "Mandi",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10097,
        Pin: 577598,
        DispatchCenter: "Hiriyur_Vedvthingr_D (Karnataka)",
        OriginCenter: "Hiriyur_Vedvthingr_D (Karnataka)",
        FacilityCity: "Hiriyur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10098,
        Pin: 700070,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10099,
        Pin: 152131,
        DispatchCenter: "Fazilka_Malkana_D (Punjab)",
        OriginCenter: "Fazilka_Malkana_D (Punjab)",
        FacilityCity: "Fazilka",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10100,
        Pin: 246746,
        DispatchCenter: "Kanth_ModaPatti_DPP (Uttar Pradesh)",
        OriginCenter: "Kanth_ModaPatti_DPP (Uttar Pradesh)",
        FacilityCity: "Kanth",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10101,
        Pin: 785669,
        DispatchCenter: "Moranhat_TengaDollong_D (Assam)",
        OriginCenter: "Moranhat_TengaDollong_D (Assam)",
        FacilityCity: "Moranhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10102,
        Pin: 273164,
        DispatchCenter: "Nautanwa_GandhiChwkRd_D (Uttar Pradesh)",
        OriginCenter: "Nautanwa_GandhiChwkRd_D (Uttar Pradesh)",
        FacilityCity: "Nautanwa",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10103,
        Pin: 472337,
        DispatchCenter: "Prithvipur_JeronRd_DPP (Madhya Pradesh)",
        OriginCenter: "Prithvipur_JeronRd_DPP (Madhya Pradesh)",
        FacilityCity: "Prithvipur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 10104,
        Pin: 754002,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10105,
        Pin: 691543,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10106,
        Pin: 621106,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10107,
        Pin: 382743,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10108,
        Pin: 581348,
        DispatchCenter: "Honnavar_ForestColony_D (Karnataka)",
        OriginCenter: "Honnavar_ForestColony_D (Karnataka)",
        FacilityCity: "Honnavar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10109,
        Pin: 712501,
        DispatchCenter: "Balagarh_Police_D (West Bengal)",
        OriginCenter: "Balagarh_Police_D (West Bengal)",
        FacilityCity: "Balagarh",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10110,
        Pin: 691308,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10111,
        Pin: 735232,
        DispatchCenter: "Birpara_Binnaguri_D (West Bengal)",
        OriginCenter: "Birpara_Binnaguri_D (West Bengal)",
        FacilityCity: "Birpara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10112,
        Pin: 440016,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10113,
        Pin: 572224,
        DispatchCenter: "Tiptur_Chmundeshwri_D (Karnataka)",
        OriginCenter: "Tiptur_Chmundeshwri_D (Karnataka)",
        FacilityCity: "Tiptur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10114,
        Pin: 606706,
        DispatchCenter: "Uthangarai_Singarapettai_D (Tamil Nadu)",
        OriginCenter: "Uthangarai_Singarapettai_D (Tamil Nadu)",
        FacilityCity: "Uthangarai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10115,
        Pin: 364210,
        DispatchCenter: "Sihor_InfinityMall_D (Gujarat)",
        OriginCenter: "Sihor_InfinityMall_D (Gujarat)",
        FacilityCity: "Sihor",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10116,
        Pin: 851130,
        DispatchCenter: "Begusarai_Haibatpur_D (Bihar)",
        OriginCenter: "Begusarai_Haibatpur_D (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10117,
        Pin: 600026,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10118,
        Pin: 847230,
        DispatchCenter: "Benipatti_Hathhapurparsa_D (Bihar)",
        OriginCenter: "Benipatti_Hathhapurparsa_D (Bihar)",
        FacilityCity: "Benipatti",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10119,
        Pin: 263646,
        DispatchCenter: "Ranikhet_Bhatrojkhan_DPP (Uttarakhand)",
        OriginCenter: "Ranikhet_Bhatrojkhan_DPP (Uttarakhand)",
        FacilityCity: "Ranikhet",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10120,
        Pin: 507161,
        DispatchCenter: "Khammam_Pindiprolu_D (Telangana)",
        OriginCenter: "Khammam_Pindiprolu_D (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10121,
        Pin: 444107,
        DispatchCenter: "Murtizapur_Shivajinagar_DPP (Maharashtra)",
        OriginCenter: "Murtizapur_Shivajinagar_DPP (Maharashtra)",
        FacilityCity: "Murtizapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10122,
        Pin: 632207,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10123,
        Pin: 206001,
        DispatchCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        OriginCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        FacilityCity: "Etawah",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10124,
        Pin: 626123,
        DispatchCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        OriginCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        FacilityCity: "Sivakasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10125,
        Pin: 361335,
        DispatchCenter: "Dwarka_CharaklaRd_DPP (Gujarat)",
        OriginCenter: "Dwarka_CharaklaRd_DPP (Gujarat)",
        FacilityCity: "Dwarka",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10126,
        Pin: 500070,
        DispatchCenter: "Hyderabad_Autonagar_L (Telangana)",
        OriginCenter: "Hyderabad_Autonagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10127,
        Pin: 580007,
        DispatchCenter: "Dharwad_Kamalpur_D (Karnataka)",
        OriginCenter: "Dharwad_Kamalpur_D (Karnataka)",
        FacilityCity: "Dharwad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10128,
        Pin: 533253,
        DispatchCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        OriginCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        FacilityCity: "Razole",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10129,
        Pin: 422210,
        DispatchCenter: "Sinnar_Pimpale_D (Maharashtra)",
        OriginCenter: "Sinnar_Pimpale_D (Maharashtra)",
        FacilityCity: "Sinnar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10130,
        Pin: 314402,
        DispatchCenter: "Dungarpur_IrolZoneCmplx_DPP (Rajasthan)",
        OriginCenter: "Dungarpur_IrolZoneCmplx_DPP (Rajasthan)",
        FacilityCity: "Dungarpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10131,
        Pin: 847121,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10132,
        Pin: 601103,
        DispatchCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        OriginCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10133,
        Pin: 621710,
        DispatchCenter: "Pennadam_Puthar_D (Tamil Nadu)",
        OriginCenter: "Pennadam_Puthar_D (Tamil Nadu)",
        FacilityCity: "Pennadam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10134,
        Pin: 230204,
        DispatchCenter: "Kunda_KaretiRd_D (Uttar Pradesh)",
        OriginCenter: "Kunda_KaretiRd_D (Uttar Pradesh)",
        FacilityCity: "Kunda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10135,
        Pin: 495444,
        DispatchCenter: "Korba_Katghora_D (Chhattisgarh)",
        OriginCenter: "Korba_Katghora_D (Chhattisgarh)",
        FacilityCity: "Korba",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10136,
        Pin: 383310,
        DispatchCenter: "Bayad_PwnPlza_D (Gujarat)",
        OriginCenter: "Bayad_PwnPlza_D (Gujarat)",
        FacilityCity: "Bayad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10137,
        Pin: 202123,
        DispatchCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        OriginCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        FacilityCity: "Khair",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10138,
        Pin: 784149,
        DispatchCenter: "Thelamara_NilachalPath_D (Assam)",
        OriginCenter: "Thelamara_NilachalPath_D (Assam)",
        FacilityCity: "Thelamara",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10139,
        Pin: 679323,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10140,
        Pin: 678721,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10141,
        Pin: 444106,
        DispatchCenter: "Murtizapur_Shivajinagar_DPP (Maharashtra)",
        OriginCenter: "Murtizapur_Shivajinagar_DPP (Maharashtra)",
        FacilityCity: "Murtizapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10142,
        Pin: 574328,
        DispatchCenter: "Panja_SubrmnyRd_DPP (Karnataka)",
        OriginCenter: "Panja_SubrmnyRd_DPP (Karnataka)",
        FacilityCity: "Panja",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10143,
        Pin: 721445,
        DispatchCenter: "Contai_Egra_D (West Bengal)",
        OriginCenter: "Contai_Egra_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10144,
        Pin: 500177,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10145,
        Pin: 678762,
        DispatchCenter: "Thachnttukra_Mangadn_D (Kerala)",
        OriginCenter: "Thachnttukra_Mangadn_D (Kerala)",
        FacilityCity: "Thachanattukara",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10146,
        Pin: 682310,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10147,
        Pin: 389355,
        DispatchCenter: "Halol_DhavalPark_D (Gujarat)",
        OriginCenter: "Halol_DhavalPark_D (Gujarat)",
        FacilityCity: "Halol",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10148,
        Pin: 445301,
        DispatchCenter: "Ghatanji_ShivajiChowk_DPP (Maharashtra)",
        OriginCenter: "Ghatanji_ShivajiChowk_DPP (Maharashtra)",
        FacilityCity: "Ghatanji",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10149,
        Pin: 695037,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10150,
        Pin: 401101,
        DispatchCenter: "Mumbai_Kandivali_L (Maharashtra)",
        OriginCenter: "Mumbai_Kandivali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10151,
        Pin: 759103,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10152,
        Pin: 122022,
        DispatchCenter: "Gurgaon_Sector52_L (Haryana)",
        OriginCenter: "Gurgaon_Sector52_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10153,
        Pin: 586216,
        DispatchCenter: "Kolhar_ShivajiCircle_D (Karnataka)",
        OriginCenter: "Kolhar_ShivajiCircle_D (Karnataka)",
        FacilityCity: "Kolhar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10154,
        Pin: 700146,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10155,
        Pin: 627753,
        DispatchCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        OriginCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        FacilityCity: "Sankarankovil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10156,
        Pin: 147203,
        DispatchCenter: "MandiGobindgarh_Nasrali_D (Punjab)",
        OriginCenter: "MandiGobindgarh_Nasrali_D (Punjab)",
        FacilityCity: "Mandi Gobindgarh",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10157,
        Pin: 686605,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10158,
        Pin: 176051,
        DispatchCenter: "Jawali_BhaneiLubh_DPP (Himachal Pradesh)",
        OriginCenter: "Jawali_BhaneiLubh_DPP (Himachal Pradesh)",
        FacilityCity: "Jawali",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10159,
        Pin: 534444,
        DispatchCenter: "Kukanoor_Kivvaka_D (Andhra Pradesh)",
        OriginCenter: "Kukanoor_Kivvaka_D (Andhra Pradesh)",
        FacilityCity: "Kukanoor",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10160,
        Pin: 768219,
        DispatchCenter: "Brajrajnagar_Jagannath_DPP (Orissa)",
        OriginCenter: "Brajrajnagar_Jagannath_DPP (Orissa)",
        FacilityCity: "Brajrajnagar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10161,
        Pin: 422207,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10162,
        Pin: 754222,
        DispatchCenter: "Kendrapara_Penthapal_D (Orissa)",
        OriginCenter: "Kendrapara_Penthapal_D (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10163,
        Pin: 691518,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10164,
        Pin: 431148,
        DispatchCenter: "Paithan_SantaNGR_D (Maharashtra)",
        OriginCenter: "Paithan_SantaNGR_D (Maharashtra)",
        FacilityCity: "Paithan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10165,
        Pin: 600050,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10166,
        Pin: 509208,
        DispatchCenter: "Makthal_Shabaricolony_DPP (Telangana)",
        OriginCenter: "Makthal_Shabaricolony_DPP (Telangana)",
        FacilityCity: "Makthal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10167,
        Pin: 431520,
        DispatchCenter: "Parli_JirgeNgr_D (Maharashtra)",
        OriginCenter: "Parli_JirgeNgr_D (Maharashtra)",
        FacilityCity: "Parli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10168,
        Pin: 402110,
        DispatchCenter: "Shriwardhan_Kherdi_D (Maharashtra)",
        OriginCenter: "Shriwardhan_Kherdi_D (Maharashtra)",
        FacilityCity: "Shriwardhan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10169,
        Pin: 530026,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10170,
        Pin: 425114,
        DispatchCenter: "Jamner_BusStd_D (Maharashtra)",
        OriginCenter: "Jamner_BusStd_D (Maharashtra)",
        FacilityCity: "Jamner",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10171,
        Pin: 175125,
        DispatchCenter: "Kullu_Mohal_D (Himachal Pradesh)",
        OriginCenter: "Kullu_Mohal_D (Himachal Pradesh)",
        FacilityCity: "Kullu",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10172,
        Pin: 401604,
        DispatchCenter: "Igatpuri_Phulenagar_D (Maharashtra)",
        OriginCenter: "Igatpuri_Phulenagar_D (Maharashtra)",
        FacilityCity: "Igatpuri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10173,
        Pin: 226203,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10174,
        Pin: 624208,
        DispatchCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        OriginCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        FacilityCity: "Vadipatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10175,
        Pin: 122016,
        DispatchCenter: "Gurgaon_Sector52_L (Haryana)",
        OriginCenter: "Gurgaon_Sector52_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10176,
        Pin: 608601,
        DispatchCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        OriginCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        FacilityCity: "Chidambaram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10177,
        Pin: 484220,
        DispatchCenter: "Anuppur_Chetnanagar_D (Madhya Pradesh)",
        OriginCenter: "Anuppur_Chetnanagar_D (Madhya Pradesh)",
        FacilityCity: "Anuppur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 10178,
        Pin: 623704,
        DispatchCenter: "Muthukulathur_Selvanygprm_D (Tamil Nadu)",
        OriginCenter: "Muthukulathur_Selvanygprm_D (Tamil Nadu)",
        FacilityCity: "Muthukulathur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10179,
        Pin: 143525,
        DispatchCenter: "Dinanagar_KakaHosp_D (Punjab)",
        OriginCenter: "Dinanagar_KakaHosp_D (Punjab)",
        FacilityCity: "Dinanagar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10180,
        Pin: 743212,
        DispatchCenter: "Barrackpore_Lalkuti_D (West Bengal)",
        OriginCenter: "Barrackpore_Lalkuti_D (West Bengal)",
        FacilityCity: "Barrackpore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10181,
        Pin: 141801,
        DispatchCenter: "MandiGobindgarh_Nasrali_D (Punjab)",
        OriginCenter: "MandiGobindgarh_Nasrali_D (Punjab)",
        FacilityCity: "Mandi Gobindgarh",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10182,
        Pin: 534201,
        DispatchCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        FacilityCity: "Bhimavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10183,
        Pin: 600008,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10184,
        Pin: 614621,
        DispatchCenter: "Manamelkudi_HondaShow_D (Tamil Nadu)",
        OriginCenter: "Manamelkudi_HondaShow_D (Tamil Nadu)",
        FacilityCity: "Manamelkudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10185,
        Pin: 413601,
        DispatchCenter: "Osmanabad_Tuljapur_D (Maharashtra)",
        OriginCenter: "Osmanabad_Tuljapur_D (Maharashtra)",
        FacilityCity: "Osmanabad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10186,
        Pin: 250602,
        DispatchCenter: "Daurala_Central_D_2 (Uttar Pradesh)",
        OriginCenter: "Daurala_Central_D_2 (Uttar Pradesh)",
        FacilityCity: "Daurala",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10187,
        Pin: 844114,
        DispatchCenter: "Lalganj_Kharauna_D (Bihar)",
        OriginCenter: "Lalganj_Kharauna_D (Bihar)",
        FacilityCity: "Lalganj-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10188,
        Pin: 535579,
        DispatchCenter: "Salur_Eswar_D (Andhra Pradesh)",
        OriginCenter: "Salur_Eswar_D (Andhra Pradesh)",
        FacilityCity: "Salur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10189,
        Pin: 571475,
        DispatchCenter: "Malavalli_KBHclny_D (Karnataka)",
        OriginCenter: "Malavalli_KBHclny_D (Karnataka)",
        FacilityCity: "Malavalli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10190,
        Pin: 683556,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10191,
        Pin: 582113,
        DispatchCenter: "HoovinaHadagali_HMnagar_D (Karnataka)",
        OriginCenter: "HoovinaHadagali_HMnagar_D (Karnataka)",
        FacilityCity: "Hoovina Hadagali",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10192,
        Pin: 847214,
        DispatchCenter: "Madhubani_NidhiChowk_D (Bihar)",
        OriginCenter: "Madhubani_NidhiChowk_D (Bihar)",
        FacilityCity: "Madhubani",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10193,
        Pin: 123413,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10194,
        Pin: 416010,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10195,
        Pin: 177210,
        DispatchCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        OriginCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        FacilityCity: "Amb",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10196,
        Pin: 411013,
        DispatchCenter: "Pune_Fursungi_L (Maharashtra)",
        OriginCenter: "Pune_Fursungi_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10197,
        Pin: 221308,
        DispatchCenter: "Gopiganj_Gerai_D (Uttar Pradesh)",
        OriginCenter: "Gopiganj_Gerai_D (Uttar Pradesh)",
        FacilityCity: "Gopiganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10198,
        Pin: 143529,
        DispatchCenter: "Gurdaspur_Civillines_D (Punjab)",
        OriginCenter: "Gurdaspur_Civillines_D (Punjab)",
        FacilityCity: "Gurdaspur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10199,
        Pin: 802164,
        DispatchCenter: "Sandesh_Agiaon_D (Bihar)",
        OriginCenter: "Sandesh_Agiaon_D (Bihar)",
        FacilityCity: "Sandesh",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10200,
        Pin: 400055,
        DispatchCenter: "Mumbai_Kurla_L (Maharashtra)",
        OriginCenter: "Mumbai_Kurla_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10201,
        Pin: 682035,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10202,
        Pin: 534244,
        DispatchCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        FacilityCity: "Bhimavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10203,
        Pin: 193403,
        DispatchCenter: "Srinagar_Draripora_DPP (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Draripora_DPP (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10204,
        Pin: 384011,
        DispatchCenter: "Kadi_Jadavpura_L (Gujarat)",
        OriginCenter: "Kadi_Jadavpura_L (Gujarat)",
        FacilityCity: "Kadi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10205,
        Pin: 385340,
        DispatchCenter: "Radhanpur_MasaliRd_DPP (Gujarat)",
        OriginCenter: "Radhanpur_MasaliRd_DPP (Gujarat)",
        FacilityCity: "Radhanpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10206,
        Pin: 171303,
        DispatchCenter: "Karsog_Busstd_D (Himachal Pradesh)",
        OriginCenter: "Karsog_Busstd_D (Himachal Pradesh)",
        FacilityCity: "Karsog",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10207,
        Pin: 341518,
        DispatchCenter: "Merta_Kalru_DPP (Rajasthan)",
        OriginCenter: "Merta_Kalru_DPP (Rajasthan)",
        FacilityCity: "Merta",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10208,
        Pin: 712308,
        DispatchCenter: "Jamalpur_HalaraVlg_D (West Bengal)",
        OriginCenter: "Jamalpur_HalaraVlg_D (West Bengal)",
        FacilityCity: "Jamalpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10209,
        Pin: 606904,
        DispatchCenter: "Polur_Kanji_D (Tamil Nadu)",
        OriginCenter: "Polur_Kanji_D (Tamil Nadu)",
        FacilityCity: "Polur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10210,
        Pin: 382850,
        DispatchCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        OriginCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        FacilityCity: "Vijapur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10211,
        Pin: 683579,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10212,
        Pin: 462030,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 10213,
        Pin: 625704,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10214,
        Pin: 442104,
        DispatchCenter: "Wardha_Alodi_D (Maharashtra)",
        OriginCenter: "Wardha_Alodi_D (Maharashtra)",
        FacilityCity: "Wardha",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10215,
        Pin: 576218,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10216,
        Pin: 476228,
        DispatchCenter: "Sabalgarh_SunherraRd_D (Madhya Pradesh)",
        OriginCenter: "Sabalgarh_SunherraRd_D (Madhya Pradesh)",
        FacilityCity: "Sabalgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 10217,
        Pin: 679563,
        DispatchCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        OriginCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10218,
        Pin: 621713,
        DispatchCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        OriginCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        FacilityCity: "Ariyalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10219,
        Pin: 788713,
        DispatchCenter: "Karimganj_Sarisa_D (Assam)",
        OriginCenter: "Karimganj_Sarisa_D (Assam)",
        FacilityCity: "Karimganj",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10220,
        Pin: 680670,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10221,
        Pin: 689520,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10222,
        Pin: 412404,
        DispatchCenter: "Rajgurunagar_Chandoli2_D (Maharashtra)",
        OriginCenter: "Rajgurunagar_Chandoli2_D (Maharashtra)",
        FacilityCity: "Rajgurunagar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10223,
        Pin: 760004,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10224,
        Pin: 614901,
        DispatchCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        OriginCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        FacilityCity: "Pattukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10225,
        Pin: 752016,
        DispatchCenter: "Pipili_BPRd_DPP (Orissa)",
        OriginCenter: "Pipili_BPRd_DPP (Orissa)",
        FacilityCity: "Pipili",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10226,
        Pin: 226006,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10227,
        Pin: 754239,
        DispatchCenter: "Aul_Rasulpur_D (Orissa)",
        OriginCenter: "Aul_Rasulpur_D (Orissa)",
        FacilityCity: "Aul",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10228,
        Pin: 754003,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10229,
        Pin: 333034,
        DispatchCenter: "Buhana_Puranabas_D (Rajasthan)",
        OriginCenter: "Buhana_Puranabas_D (Rajasthan)",
        FacilityCity: "Buhana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10230,
        Pin: 241504,
        DispatchCenter: "Miyanganj_FurkanMarket_DPP (Uttar Pradesh)",
        OriginCenter: "Miyanganj_FurkanMarket_DPP (Uttar Pradesh)",
        FacilityCity: "Miyanganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10231,
        Pin: 344022,
        DispatchCenter: "Balotra_Ward45_D (Rajasthan)",
        OriginCenter: "Balotra_Ward45_D (Rajasthan)",
        FacilityCity: "Balotra",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10232,
        Pin: 222105,
        DispatchCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        OriginCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        FacilityCity: "Jaunpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10233,
        Pin: 627433,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10234,
        Pin: 507101,
        DispatchCenter: "Kothagudem_OldPaloncha_D (Telangana)",
        OriginCenter: "Kothagudem_OldPaloncha_D (Telangana)",
        FacilityCity: "Kothagudem",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10235,
        Pin: 691548,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10236,
        Pin: 742103,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10237,
        Pin: 171011,
        DispatchCenter: "Shimla_Tara_DC (Himachal Pradesh)",
        OriginCenter: "Shimla_Tara_DC (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10238,
        Pin: 518512,
        DispatchCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        OriginCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        FacilityCity: "Nandyal",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10239,
        Pin: 272141,
        DispatchCenter: "Basti_Maharajganj_D (Uttar Pradesh)",
        OriginCenter: "Basti_Maharajganj_D (Uttar Pradesh)",
        FacilityCity: "Itava",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10240,
        Pin: 735223,
        DispatchCenter: "Chalsa_BusStand_D (West Bengal)",
        OriginCenter: "Chalsa_BusStand_D (West Bengal)",
        FacilityCity: "Chalsa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10241,
        Pin: 721455,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10242,
        Pin: 262540,
        DispatchCenter: "Pithorgarh_Kumud_D (Uttarakhand)",
        OriginCenter: "Pithorgarh_Kumud_D (Uttarakhand)",
        FacilityCity: "Pithoragarh",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10243,
        Pin: 400042,
        DispatchCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        OriginCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        FacilityCity: "Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10244,
        Pin: 421401,
        DispatchCenter: "Murbad_Shirgaon_D (Maharashtra)",
        OriginCenter: "Murbad_Shirgaon_D (Maharashtra)",
        FacilityCity: "Murbad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10245,
        Pin: 500024,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10246,
        Pin: 144102,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10247,
        Pin: 415407,
        DispatchCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        OriginCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        FacilityCity: "Islampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10248,
        Pin: 441701,
        DispatchCenter: "Brahmapuri_Pimpalgaon_DPP (Maharashtra)",
        OriginCenter: "Brahmapuri_Pimpalgaon_DPP (Maharashtra)",
        FacilityCity: "Brahmapuri",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10249,
        Pin: 686040,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10250,
        Pin: 623503,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10251,
        Pin: 110084,
        DispatchCenter: "Delhi_Libaspur_L (Delhi)",
        OriginCenter: "Delhi_Libaspur_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10252,
        Pin: 785016,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10253,
        Pin: 756048,
        DispatchCenter: "Bhadrak_Purusandha_D (Orissa)",
        OriginCenter: "Bhadrak_Purusandha_D (Orissa)",
        FacilityCity: "Agarpada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10254,
        Pin: 201313,
        DispatchCenter: "Noida_KheraDharmpura_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10255,
        Pin: 682502,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10256,
        Pin: 206245,
        DispatchCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        OriginCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        FacilityCity: "Etawah",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10257,
        Pin: 384110,
        DispatchCenter: "Patan_Hansapur_D (Gujarat)",
        OriginCenter: "Patan_Hansapur_D (Gujarat)",
        FacilityCity: "Patan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10258,
        Pin: 638054,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10259,
        Pin: 370165,
        DispatchCenter: "Rapar_MalichokRd_DPP (Gujarat)",
        OriginCenter: "Rapar_MalichokRd_DPP (Gujarat)",
        FacilityCity: "Rapar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10260,
        Pin: 721448,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10261,
        Pin: 571418,
        DispatchCenter: "Nagamangala_TBExtension_D (Karnataka)",
        OriginCenter: "Nagamangala_TBExtension_D (Karnataka)",
        FacilityCity: "Nagamangala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10262,
        Pin: 332718,
        DispatchCenter: "Neemkathana_Chhawani_D (Rajasthan)",
        OriginCenter: "Neemkathana_Chhawani_D (Rajasthan)",
        FacilityCity: "Neem ka Thana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10263,
        Pin: 453446,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_Palda_L (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 10264,
        Pin: 591314,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10265,
        Pin: 827016,
        DispatchCenter: "Bokaro_Chas_D (Jharkhand)",
        OriginCenter: "Bokaro_Chas_D (Jharkhand)",
        FacilityCity: "Bokaro",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10266,
        Pin: 384565,
        DispatchCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        OriginCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        FacilityCity: "Vijapur",
        FacilityState: "Gujarat",
        Zone : "W1"
      },
      {
        SN: 10267,
        Pin: 768039,
        DispatchCenter: "Nuapada_Dunguripali_DPP (Orissa)",
        OriginCenter: "Nuapada_Dunguripali_DPP (Orissa)",
        FacilityCity: "Nuapada",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10268,
        Pin: 786126,
        DispatchCenter: "Tinsukia_Nokhroygaon2_D (Assam)",
        OriginCenter: "Tinsukia_Nokhroygaon2_D (Assam)",
        FacilityCity: "Tinsukia",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10269,
        Pin: 208039,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10270,
        Pin: 470664,
        DispatchCenter: "Hatta_Gourishankar_D (Madhya Pradesh)",
        OriginCenter: "Hatta_Gourishankar_D (Madhya Pradesh)",
        FacilityCity: "Hatta",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 10271,
        Pin: 628905,
        DispatchCenter: "Perunali_Sayalkudi_D (Tamil Nadu)",
        OriginCenter: "Perunali_Sayalkudi_D (Tamil Nadu)",
        FacilityCity: "Perunali",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10272,
        Pin: 754018,
        DispatchCenter: "Adaspur_Uradha_DPP (Orissa)",
        OriginCenter: "Adaspur_Uradha_DPP (Orissa)",
        FacilityCity: "Adaspur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10273,
        Pin: 523224,
        DispatchCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        OriginCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        FacilityCity: "Kanigiri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10274,
        Pin: 515870,
        DispatchCenter: "Uravakonda_GovtDegreeClg_D (Andhra Pradesh)",
        OriginCenter: "Uravakonda_GovtDegreeClg_D (Andhra Pradesh)",
        FacilityCity: "Uravakonda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10275,
        Pin: 532216,
        DispatchCenter: "Pathapatnam_Kotaguddi_D (Andhra Pradesh)",
        OriginCenter: "Pathapatnam_Kotaguddi_D (Andhra Pradesh)",
        FacilityCity: "Pathapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10276,
        Pin: 847222,
        DispatchCenter: "Benipatti_Sarisab_D (Bihar)",
        OriginCenter: "Benipatti_Sarisab_D (Bihar)",
        FacilityCity: "Benipatti",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10277,
        Pin: 383246,
        DispatchCenter: "Bhiloda_NirSagarPlaza_D (Gujarat)",
        OriginCenter: "Bhiloda_NirSagarPlaza_D (Gujarat)",
        FacilityCity: "Bhiloda",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10278,
        Pin: 563131,
        DispatchCenter: "Mulbagal_Sunku_D (Karnataka)",
        OriginCenter: "Mulbagal_Sunku_D (Karnataka)",
        FacilityCity: "Mulbagal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10279,
        Pin: 560040,
        DispatchCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        OriginCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10280,
        Pin: 680521,
        DispatchCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        OriginCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10281,
        Pin: 306105,
        DispatchCenter: "Raipur_Mainmarket_D (Rajasthan)",
        OriginCenter: "Raipur_Mainmarket_D (Rajasthan)",
        FacilityCity: "Raipur-RJ",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10282,
        Pin: 743166,
        DispatchCenter: "Naihati_Rajendrapur_DPP (West Bengal)",
        OriginCenter: "Naihati_Rajendrapur_DPP (West Bengal)",
        FacilityCity: "Naihati",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10283,
        Pin: 401602,
        DispatchCenter: "Dahanu_Ambemora_D (Maharashtra)",
        OriginCenter: "Dahanu_Ambemora_D (Maharashtra)",
        FacilityCity: "Dahanu",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10284,
        Pin: 411002,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10285,
        Pin: 680698,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10286,
        Pin: 756116,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10287,
        Pin: 221507,
        DispatchCenter: "Phulpur_BeerKaji_D (Uttar Pradesh)",
        OriginCenter: "Phulpur_BeerKaji_D (Uttar Pradesh)",
        FacilityCity: "Phulpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10288,
        Pin: 424206,
        DispatchCenter: "Jamner_BusStd_D (Maharashtra)",
        OriginCenter: "Jamner_BusStd_D (Maharashtra)",
        FacilityCity: "Jamner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10289,
        Pin: 110039,
        DispatchCenter: "Delhi_Libaspur_L (Delhi)",
        OriginCenter: "Delhi_Libaspur_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10290,
        Pin: 756163,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10291,
        Pin: 695563,
        DispatchCenter: "Pangodu_Bharathanoor_D (Kerala)",
        OriginCenter: "Pangodu_Bharathanoor_D (Kerala)",
        FacilityCity: "Pangodu",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10292,
        Pin: 700118,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10293,
        Pin: 412403,
        DispatchCenter: "Rajgurunagar_Chandoli2_D (Maharashtra)",
        OriginCenter: "Rajgurunagar_Chandoli2_D (Maharashtra)",
        FacilityCity: "Rajgurunagar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10294,
        Pin: 423213,
        DispatchCenter: "Satana_MitraNagar_D (Maharashtra)",
        OriginCenter: "Satana_MitraNagar_D (Maharashtra)",
        FacilityCity: "Satana",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10295,
        Pin: 263142,
        DispatchCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        OriginCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        FacilityCity: "Rudrapur",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10296,
        Pin: 686574,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10297,
        Pin: 412109,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10298,
        Pin: 363007,
        DispatchCenter: "Surendranagar_GIDC_D (Gujarat)",
        OriginCenter: "Surendranagar_GIDC_D (Gujarat)",
        FacilityCity: "Surendranagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10299,
        Pin: 203150,
        DispatchCenter: "Bulndshahr_Ymunpurm_D (Uttar Pradesh)",
        OriginCenter: "Bulndshahr_Ymunpurm_D (Uttar Pradesh)",
        FacilityCity: "Bulandshahr",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10300,
        Pin: 261135,
        DispatchCenter: "Laharpur_TehsilHQ_D (Uttar Pradesh)",
        OriginCenter: "Laharpur_TehsilHQ_D (Uttar Pradesh)",
        FacilityCity: "Laharpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10301,
        Pin: 413307,
        DispatchCenter: "Sangola_PatilChowk_DPP (Maharashtra)",
        OriginCenter: "Sangola_PatilChowk_DPP (Maharashtra)",
        FacilityCity: "Sangola",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10302,
        Pin: 841233,
        DispatchCenter: "Maharajganj_Sihauta_D (Bihar)",
        OriginCenter: "Maharajganj_Sihauta_D (Bihar)",
        FacilityCity: "Maharajganj-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10303,
        Pin: 713129,
        DispatchCenter: "Katwa_Panchghara_D (West Bengal)",
        OriginCenter: "Katwa_Panchghara_D (West Bengal)",
        FacilityCity: "Katwa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10304,
        Pin: 828308,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10305,
        Pin: 524303,
        DispatchCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        OriginCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        FacilityCity: "Atmakur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10306,
        Pin: 834006,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10307,
        Pin: 695019,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10308,
        Pin: 176302,
        DispatchCenter: "Nurpur_Sadwan_DPP (Himachal Pradesh)",
        OriginCenter: "Nurpur_Sadwan_DPP (Himachal Pradesh)",
        FacilityCity: "Nurpur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10309,
        Pin: 400046,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10310,
        Pin: 517130,
        DispatchCenter: "Pakala_Gorpadu_D (Andhra Pradesh)",
        OriginCenter: "Pakala_Gorpadu_D (Andhra Pradesh)",
        FacilityCity: "Pakala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10311,
        Pin: 326001,
        DispatchCenter: "Jhalawar_RTOOffice_D (Rajasthan)",
        OriginCenter: "Jhalawar_RTOOffice_D (Rajasthan)",
        FacilityCity: "Jhalawar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10312,
        Pin: 184120,
        DispatchCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10313,
        Pin: 713304,
        DispatchCenter: "Asansol_Gobindapur_D (West Bengal)",
        OriginCenter: "Asansol_Gobindapur_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10314,
        Pin: 590007,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10315,
        Pin: 144406,
        DispatchCenter: "Garhshankar_BangaRoad_D (Punjab)",
        OriginCenter: "Garhshankar_BangaRoad_D (Punjab)",
        FacilityCity: "Garhshanker",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10316,
        Pin: 231223,
        DispatchCenter: "Bijpur_RihandNgr_DPP (Uttar Pradesh)",
        OriginCenter: "Bijpur_RihandNgr_DPP (Uttar Pradesh)",
        FacilityCity: "Bijpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10317,
        Pin: 676511,
        DispatchCenter: "Malappuram_Kolappuram_D (Kerala)",
        OriginCenter: "Malappuram_Kolappuram_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10318,
        Pin: 403730,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10319,
        Pin: 121001,
        DispatchCenter: "Faridabad_MathuraRoad_L (Haryana)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10320,
        Pin: 425135,
        DispatchCenter: "Jalgaon_MIDC_I (Maharashtra)",
        OriginCenter: "Jalgaon_MIDC_I (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10321,
        Pin: 794004,
        DispatchCenter: "Resubelpara_Belpara_D (Meghalaya)",
        OriginCenter: "Resubelpara_Belpara_D (Meghalaya)",
        FacilityCity: "Resubelpara",
        FacilityState: "Meghalaya",
        Zone : "NE"
      },
      {
        SN: 10322,
        Pin: 275303,
        DispatchCenter: "Barhalganj_Lakhnapar_DPP (Uttar Pradesh)",
        OriginCenter: "Barhalganj_Lakhnapar_DPP (Uttar Pradesh)",
        FacilityCity: "Barhalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10323,
        Pin: 400061,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10324,
        Pin: 402209,
        DispatchCenter: "Alibag_Shivanagar_D (Maharashtra)",
        OriginCenter: "Alibag_Shivanagar_D (Maharashtra)",
        FacilityCity: "Alibag",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10325,
        Pin: 176021,
        DispatchCenter: "Jawali_BhaneiLubh_DPP (Himachal Pradesh)",
        OriginCenter: "Jawali_BhaneiLubh_DPP (Himachal Pradesh)",
        FacilityCity: "Jawali",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10326,
        Pin: 181203,
        DispatchCenter: "Akhnoor_MiltryHosp_D (Jammu & Kashmir)",
        OriginCenter: "Akhnoor_MiltryHosp_D (Jammu & Kashmir)",
        FacilityCity: "Akhnoor",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10327,
        Pin: 700083,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10328,
        Pin: 682020,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10329,
        Pin: 638505,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10330,
        Pin: 784189,
        DispatchCenter: "Biswanath_Jamugurihat_DPP (Assam)",
        OriginCenter: "Biswanath_Jamugurihat_DPP (Assam)",
        FacilityCity: "BiswanathCH",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10331,
        Pin: 781327,
        DispatchCenter: "Tihu_Jalahgaon_D (Assam)",
        OriginCenter: "Tihu_Jalahgaon_D (Assam)",
        FacilityCity: "Tihu",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10332,
        Pin: 786102,
        DispatchCenter: "Chabua_CollgeRD_D (Assam)",
        OriginCenter: "Chabua_CollgeRD_D (Assam)",
        FacilityCity: "Chabua",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10333,
        Pin: 425117,
        DispatchCenter: "Jalgaon_Omnagar_D (Maharashtra)",
        OriginCenter: "Jalgaon_Omnagar_D (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10334,
        Pin: 757055,
        DispatchCenter: "Morada_Malihata_D (Orissa)",
        OriginCenter: "Morada_Malihata_D (Orissa)",
        FacilityCity: "Morada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10335,
        Pin: 249137,
        DispatchCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        OriginCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        FacilityCity: "Rishikesh",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10336,
        Pin: 700034,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10337,
        Pin: 151001,
        DispatchCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        OriginCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        FacilityCity: "Bathinda",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10338,
        Pin: 532558,
        DispatchCenter: "Bobbili_Aguruvidi_D (Andhra Pradesh)",
        OriginCenter: "Bobbili_Aguruvidi_D (Andhra Pradesh)",
        FacilityCity: "Bobbili",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10339,
        Pin: 680313,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10340,
        Pin: 781321,
        DispatchCenter: "Kalgachia_PoliceStn_DPP (Assam)",
        OriginCenter: "Kalgachia_PoliceStn_DPP (Assam)",
        FacilityCity: "Kalgachia",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10341,
        Pin: 505527,
        DispatchCenter: "KarimNagar_Alugunoor_I (Telangana)",
        OriginCenter: "KarimNagar_Alugunoor_I (Telangana)",
        FacilityCity: "Karim Nagar",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10342,
        Pin: 411005,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10343,
        Pin: 248005,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10344,
        Pin: 797116,
        DispatchCenter: "Dimapur_Sovima_I (Nagaland)",
        OriginCenter: "Dimapur_Sovima_I (Nagaland)",
        FacilityCity: "Dimapur",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10345,
        Pin: 382729,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10346,
        Pin: 276202,
        DispatchCenter: "Azamgarh_Lalganj_DPP (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Lalganj_DPP (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10347,
        Pin: 793005,
        DispatchCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        OriginCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10348,
        Pin: 453551,
        DispatchCenter: "Ujjain_Lalakheda_D (Madhya Pradesh)",
        OriginCenter: "Ujjain_Lalakheda_D (Madhya Pradesh)",
        FacilityCity: "Ujjain",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 10349,
        Pin: 390004,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10350,
        Pin: 690560,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10351,
        Pin: 456770,
        DispatchCenter: "Shajapur_Mahupura_D (Madhya Pradesh)",
        OriginCenter: "Shajapur_Mahupura_D (Madhya Pradesh)",
        FacilityCity: "Shajapur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 10352,
        Pin: 413223,
        DispatchCenter: "Kurduwadi_Dattanagar_D (Maharashtra)",
        OriginCenter: "Kurduwadi_Dattanagar_D (Maharashtra)",
        FacilityCity: "Kurduwadi",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10353,
        Pin: 785113,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10354,
        Pin: 483503,
        DispatchCenter: "Katni_ShantiNagar_D (Madhya Pradesh)",
        OriginCenter: "Katni_ShantiNagar_D (Madhya Pradesh)",
        FacilityCity: "Katni",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 10355,
        Pin: 587155,
        DispatchCenter: "Ramdurg_Busstnd_DPP (Karnataka)",
        OriginCenter: "Ramdurg_Busstnd_DPP (Karnataka)",
        FacilityCity: "Ramdurg",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10356,
        Pin: 333024,
        DispatchCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        OriginCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        FacilityCity: "Jhunjhunu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10357,
        Pin: 394114,
        DispatchCenter: "Songadh_Vyara_D (Gujarat)",
        OriginCenter: "Songadh_Vyara_D (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10358,
        Pin: 126110,
        DispatchCenter: "Jind_SunderNagar_D (Haryana)",
        OriginCenter: "Jind_SunderNagar_D (Haryana)",
        FacilityCity: "Jind",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10359,
        Pin: 795133,
        DispatchCenter: "Moirang_TongbramKolup_D (Manipur)",
        OriginCenter: "Moirang_TongbramKolup_D (Manipur)",
        FacilityCity: "Moirang",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10360,
        Pin: 233305,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10361,
        Pin: 226017,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10362,
        Pin: 795115,
        DispatchCenter: "Imphal_Takyel_I (Manipur)",
        OriginCenter: "Imphal_Takyel_I (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10363,
        Pin: 516421,
        DispatchCenter: "Pulivendula_Bakarapuram_D (Andhra Pradesh)",
        OriginCenter: "Pulivendula_Bakarapuram_D (Andhra Pradesh)",
        FacilityCity: "Pulivendula",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10364,
        Pin: 212308,
        DispatchCenter: "Koraon_AmbedkarNagar_D (Uttar Pradesh)",
        OriginCenter: "Koraon_AmbedkarNagar_D (Uttar Pradesh)",
        FacilityCity: "Koraon",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10365,
        Pin: 323304,
        DispatchCenter: "Rawatbhata_HarijanBasti_D (Rajasthan)",
        OriginCenter: "Rawatbhata_HarijanBasti_D (Rajasthan)",
        FacilityCity: "Rawatbhata",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10366,
        Pin: 410501,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10367,
        Pin: 522613,
        DispatchCenter: "Macherla_Ward15_D (Andhra Pradesh)",
        OriginCenter: "Macherla_Ward15_D (Andhra Pradesh)",
        FacilityCity: "Macherla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10368,
        Pin: 614904,
        DispatchCenter: "Thanjavur_Palampudur_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Palampudur_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10369,
        Pin: 628210,
        DispatchCenter: "Eral_Nazareth_D (Tamil Nadu)",
        OriginCenter: "Eral_Nazareth_D (Tamil Nadu)",
        FacilityCity: "Eral",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10370,
        Pin: 581208,
        DispatchCenter: "Ranebennur_MrthnjyaNgr_D (Karnataka)",
        OriginCenter: "Ranebennur_MrthnjyaNgr_D (Karnataka)",
        FacilityCity: "Ranebennur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10371,
        Pin: 441305,
        DispatchCenter: "Katol_Laxmingr_D (Maharashtra)",
        OriginCenter: "Katol_Laxmingr_D (Maharashtra)",
        FacilityCity: "Katol",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10372,
        Pin: 344703,
        DispatchCenter: "Barmer_Baldevnagar_DPP (Rajasthan)",
        OriginCenter: "Barmer_Baldevnagar_DPP (Rajasthan)",
        FacilityCity: "Barmer",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10373,
        Pin: 610207,
        DispatchCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        FacilityCity: "Thiruthuraipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10374,
        Pin: 362551,
        DispatchCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        OriginCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        FacilityCity: "Veraval",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10375,
        Pin: 641653,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10376,
        Pin: 455116,
        DispatchCenter: "Dewas_Rolupipalya_DPP (Madhya Pradesh)",
        OriginCenter: "Dewas_Rolupipalya_DPP (Madhya Pradesh)",
        FacilityCity: "Dewas",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 10377,
        Pin: 581211,
        DispatchCenter: "Ranebennur_MrthnjyaNgr_D (Karnataka)",
        OriginCenter: "Ranebennur_MrthnjyaNgr_D (Karnataka)",
        FacilityCity: "Ranebennur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10378,
        Pin: 388001,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10379,
        Pin: 762011,
        DispatchCenter: "Phulbani_Sarangada_D (Orissa)",
        OriginCenter: "Phulbani_Sarangada_D (Orissa)",
        FacilityCity: "Phulbani",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10380,
        Pin: 444814,
        DispatchCenter: "Amravati_Sashinagar_D (Maharashtra)",
        OriginCenter: "Amravati_Sashinagar_D (Maharashtra)",
        FacilityCity: "Amravati",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10381,
        Pin: 756022,
        DispatchCenter: "Basta_Darada_DPP (Orissa)",
        OriginCenter: "Basta_Darada_DPP (Orissa)",
        FacilityCity: "Basta",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10382,
        Pin: 343024,
        DispatchCenter: "Sirohi_Vedhaynathcolony_D (Rajasthan)",
        OriginCenter: "Sirohi_Vedhaynathcolony_D (Rajasthan)",
        FacilityCity: "Sirohi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10383,
        Pin: 152117,
        DispatchCenter: "Abohar_DaulatPura_D (Punjab)",
        OriginCenter: "Abohar_DaulatPura_D (Punjab)",
        FacilityCity: "Abohar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10384,
        Pin: 140001,
        DispatchCenter: "Rupnagar_Towercolony_D (Punjab)",
        OriginCenter: "Rupnagar_Towercolony_D (Punjab)",
        FacilityCity: "Rupnagar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10385,
        Pin: 637215,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10386,
        Pin: 135004,
        DispatchCenter: "Yamunanagar_Veerngrcly_D (Haryana)",
        OriginCenter: "Yamunanagar_Veerngrcly_D (Haryana)",
        FacilityCity: "Yamuna Nagar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10387,
        Pin: 723128,
        DispatchCenter: "Manbazar_Central_DPP_1 (West Bengal)",
        OriginCenter: "Manbazar_Central_DPP_1 (West Bengal)",
        FacilityCity: "Manbazar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10388,
        Pin: 380001,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10389,
        Pin: 832301,
        DispatchCenter: "Chakulia_Jogipara_D (Jharkhand)",
        OriginCenter: "Chakulia_Jogipara_D (Jharkhand)",
        FacilityCity: "Chakulia",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10390,
        Pin: 690567,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10391,
        Pin: 509337,
        DispatchCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        OriginCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        FacilityCity: "Mahabubnagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10392,
        Pin: 641301,
        DispatchCenter: "Mettupalayam_Belladhi_D (Tamil Nadu)",
        OriginCenter: "Mettupalayam_Belladhi_D (Tamil Nadu)",
        FacilityCity: "Mettupalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10393,
        Pin: 415643,
        DispatchCenter: "Lanja_GondeSakhalRd_D (Maharashtra)",
        OriginCenter: "Lanja_GondeSakhalRd_D (Maharashtra)",
        FacilityCity: "Lanja",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10394,
        Pin: 671212,
        DispatchCenter: "Kasaragod_Kudlu_D (Kerala)",
        OriginCenter: "Kasaragod_Kudlu_D (Kerala)",
        FacilityCity: "Kasaragod",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10395,
        Pin: 768005,
        DispatchCenter: "Sambalpur_Tentelpara_H (Orissa)",
        OriginCenter: "Sambalpur_Tentelpara_H (Orissa)",
        FacilityCity: "Sambalpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10396,
        Pin: 144203,
        DispatchCenter: "Dasuya_AdarshNagar_D (Punjab)",
        OriginCenter: "Dasuya_AdarshNagar_D (Punjab)",
        FacilityCity: "Dasuya",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10397,
        Pin: 174311,
        DispatchCenter: "Bhota_BpassDPP_D (Himachal Pradesh)",
        OriginCenter: "Bhota_BpassDPP_D (Himachal Pradesh)",
        FacilityCity: "Bhota",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10398,
        Pin: 485772,
        DispatchCenter: "Maihar_Harnampur_D (Madhya Pradesh)",
        OriginCenter: "Maihar_Harnampur_D (Madhya Pradesh)",
        FacilityCity: "Maihar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 10399,
        Pin: 695551,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10400,
        Pin: 451556,
        DispatchCenter: "Barwani_IGcolony_D (Madhya Pradesh)",
        OriginCenter: "Barwani_IGcolony_D (Madhya Pradesh)",
        FacilityCity: "Barwani",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 10401,
        Pin: 765018,
        DispatchCenter: "Raygada_GandhiNgr_DPP (Orissa)",
        OriginCenter: "Raygada_GandhiNgr_DPP (Orissa)",
        FacilityCity: "Raygada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10402,
        Pin: 382140,
        DispatchCenter: "Kadi_Jadavpura_L (Gujarat)",
        OriginCenter: "Kadi_Jadavpura_L (Gujarat)",
        FacilityCity: "Kadi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10403,
        Pin: 641110,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10404,
        Pin: 680568,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10405,
        Pin: 622204,
        DispatchCenter: "Pudukkottai_Lnpuram_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Lnpuram_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10406,
        Pin: 411018,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10407,
        Pin: 743502,
        DispatchCenter: "Ghatakpukur_Nalmuri_DPP (West Bengal)",
        OriginCenter: "Ghatakpukur_Nalmuri_DPP (West Bengal)",
        FacilityCity: "Ghatakpukur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10408,
        Pin: 614302,
        DispatchCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        OriginCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10409,
        Pin: 574146,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10410,
        Pin: 360024,
        DispatchCenter: "Rajkot_Amargadh_H (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10411,
        Pin: 305007,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10412,
        Pin: 841416,
        DispatchCenter: "Malmaliya_Sipah_D (Bihar)",
        OriginCenter: "Malmaliya_Sipah_D (Bihar)",
        FacilityCity: "Malmaliya",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10413,
        Pin: 444704,
        DispatchCenter: "Morshi_Samarthacolony_D (Maharashtra)",
        OriginCenter: "Morshi_Samarthacolony_D (Maharashtra)",
        FacilityCity: "Morshi",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10414,
        Pin: 609105,
        DispatchCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        OriginCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        FacilityCity: "Sirkazhi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10415,
        Pin: 442002,
        DispatchCenter: "Wardha_Alodi_D (Maharashtra)",
        OriginCenter: "Wardha_Alodi_D (Maharashtra)",
        FacilityCity: "Wardha",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10416,
        Pin: 524002,
        DispatchCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        OriginCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10417,
        Pin: 221007,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10418,
        Pin: 305927,
        DispatchCenter: "Beawar_DC (Rajasthan)",
        OriginCenter: "Beawar_DC (Rajasthan)",
        FacilityCity: "Beawar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10419,
        Pin: 208027,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10420,
        Pin: 581350,
        DispatchCenter: "Bhatkal_Sodigadde_D (Karnataka)",
        OriginCenter: "Bhatkal_Sodigadde_D (Karnataka)",
        FacilityCity: "Bhatkal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10421,
        Pin: 574199,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10422,
        Pin: 425411,
        DispatchCenter: "Nandurbar_Saharatown_D (Maharashtra)",
        OriginCenter: "Nandurbar_Saharatown_D (Maharashtra)",
        FacilityCity: "Nandurbar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10423,
        Pin: 841215,
        DispatchCenter: "Saraiya_Saraiya_D (Bihar)",
        OriginCenter: "Saraiya_Saraiya_D (Bihar)",
        FacilityCity: "Saraiya",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10424,
        Pin: 680667,
        DispatchCenter: "Kodungallur_Methala_D (Kerala)",
        OriginCenter: "Kodungallur_Methala_D (Kerala)",
        FacilityCity: "Kodungallur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10425,
        Pin: 625603,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10426,
        Pin: 507124,
        DispatchCenter: "Yellandu_Sudimala_D (Telangana)",
        OriginCenter: "Yellandu_Sudimala_D (Telangana)",
        FacilityCity: "Yellandu",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10427,
        Pin: 761005,
        DispatchCenter: "Chikiti_Bhlasingi_D (Orissa)",
        OriginCenter: "Chikiti_Bhlasingi_D (Orissa)",
        FacilityCity: "Chikiti",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10428,
        Pin: 517123,
        DispatchCenter: "Pileru_Plot14_D (Andhra Pradesh)",
        OriginCenter: "Pileru_Plot14_D (Andhra Pradesh)",
        FacilityCity: "Pileru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10429,
        Pin: 249409,
        DispatchCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        OriginCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        FacilityCity: "Haridwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10430,
        Pin: 228132,
        DispatchCenter: "Lambhua_Chaukiya_D (Uttar Pradesh)",
        OriginCenter: "Lambhua_Chaukiya_D (Uttar Pradesh)",
        FacilityCity: "Lambhua",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10431,
        Pin: 796310,
        DispatchCenter: "Khawzawl_Dinthar_D (Mizoram)",
        OriginCenter: "Khawzawl_Dinthar_D (Mizoram)",
        FacilityCity: "Khawzawl",
        FacilityState: "Mizoram",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10432,
        Pin: 533287,
        DispatchCenter: "Gokavaram_MuraliNagar_D (Andhra Pradesh)",
        OriginCenter: "Gokavaram_MuraliNagar_D (Andhra Pradesh)",
        FacilityCity: "Gokavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10433,
        Pin: 344702,
        DispatchCenter: "Dhorimana_VishnuColony_D (Rajasthan)",
        OriginCenter: "Dhorimana_VishnuColony_D (Rajasthan)",
        FacilityCity: "Dhorimana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10434,
        Pin: 764001,
        DispatchCenter: "Jeypore_Bariniput_I (Orissa)",
        OriginCenter: "Jeypore_Bariniput_I (Orissa)",
        FacilityCity: "Jeypore",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10435,
        Pin: 415614,
        DispatchCenter: "Ganpatipule_Rahataghar_DPP (Maharashtra)",
        OriginCenter: "Ganpatipule_Rahataghar_DPP (Maharashtra)",
        FacilityCity: "Ganpatipule",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10436,
        Pin: 742133,
        DispatchCenter: "Nowda_Amtala_DPP (West Bengal)",
        OriginCenter: "Nowda_Amtala_DPP (West Bengal)",
        FacilityCity: "Nowda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10437,
        Pin: 313704,
        DispatchCenter: "Gogunda_Bypass_DPP (Rajasthan)",
        OriginCenter: "Gogunda_Bypass_DPP (Rajasthan)",
        FacilityCity: "Gogunda",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10438,
        Pin: 144032,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10439,
        Pin: 531127,
        DispatchCenter: "Tuni_Ramakrishnacolony_D (Andhra Pradesh)",
        OriginCenter: "Tuni_Ramakrishnacolony_D (Andhra Pradesh)",
        FacilityCity: "Tuni",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10440,
        Pin: 591317,
        DispatchCenter: "Raibag_Sainagar_DPP (Karnataka)",
        OriginCenter: "Raibag_Sainagar_DPP (Karnataka)",
        FacilityCity: "Raibag",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10441,
        Pin: 382475,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10442,
        Pin: 571446,
        DispatchCenter: "Mandya_Dc (Karnataka)",
        OriginCenter: "Mandya_Dc (Karnataka)",
        FacilityCity: "Mandya",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10443,
        Pin: 845303,
        DispatchCenter: "Dhaka_PachpakriRd_D (Bihar)",
        OriginCenter: "Dhaka_PachpakriRd_D (Bihar)",
        FacilityCity: "Dhaka",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10444,
        Pin: 613705,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10445,
        Pin: 247002,
        DispatchCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        OriginCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        FacilityCity: "Saharanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10446,
        Pin: 712504,
        DispatchCenter: "Hooghly_Bikramnagar_D (West Bengal)",
        OriginCenter: "Hooghly_Bikramnagar_D (West Bengal)",
        FacilityCity: "Hooghly",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10447,
        Pin: 713408,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10448,
        Pin: 193501,
        DispatchCenter: "Bandipora_Sumbal_D (Jammu & Kashmir)",
        OriginCenter: "Bandipora_Sumbal_D (Jammu & Kashmir)",
        FacilityCity: "Bandipora",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10449,
        Pin: 670005,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10450,
        Pin: 758032,
        DispatchCenter: "Kendujhar_Jhumpura_D (Orissa)",
        OriginCenter: "Kendujhar_Jhumpura_D (Orissa)",
        FacilityCity: "Kendujhar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10451,
        Pin: 621707,
        DispatchCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        OriginCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        FacilityCity: "Ariyalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10452,
        Pin: 854104,
        DispatchCenter: "Kursela_Tingharia_D (Bihar)",
        OriginCenter: "Kursela_Tingharia_D (Bihar)",
        FacilityCity: "Kursela",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10453,
        Pin: 690574,
        DispatchCenter: "Kollam_Karunagappally_D (Kerala)",
        OriginCenter: "Kollam_Karunagappally_D (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10454,
        Pin: 402103,
        DispatchCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        OriginCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        FacilityCity: "Mangaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10455,
        Pin: 174031,
        DispatchCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        OriginCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        FacilityCity: "Bilaspur-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10456,
        Pin: 621730,
        DispatchCenter: "Pennadam_Puthar_D (Tamil Nadu)",
        OriginCenter: "Pennadam_Puthar_D (Tamil Nadu)",
        FacilityCity: "Pennadam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10457,
        Pin: 524421,
        DispatchCenter: "Naidupeta_LASagaram_D (Andhra Pradesh)",
        OriginCenter: "Naidupeta_LASagaram_D (Andhra Pradesh)",
        FacilityCity: "Naidupeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10458,
        Pin: 791112,
        DispatchCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        OriginCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        FacilityCity: "Itanagar",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10459,
        Pin: 387350,
        DispatchCenter: "Nadiad_Shantinagar_D (Gujarat)",
        OriginCenter: "Nadiad_Shantinagar_D (Gujarat)",
        FacilityCity: "Nadiad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10460,
        Pin: 441203,
        DispatchCenter: "Umred_Narsala_D (Maharashtra)",
        OriginCenter: "Umred_Narsala_D (Maharashtra)",
        FacilityCity: "Umred",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10461,
        Pin: 413514,
        DispatchCenter: "Ahmedpur_ITIclg_D (Maharashtra)",
        OriginCenter: "Ahmedpur_ITIclg_D (Maharashtra)",
        FacilityCity: "Ahmadpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10462,
        Pin: 110510,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10463,
        Pin: 854305,
        DispatchCenter: "Purnia_Fatehpur_D (Bihar)",
        OriginCenter: "Purnia_Fatehpur_D (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10464,
        Pin: 765013,
        DispatchCenter: "Koraput_Kakiriguma_D (Orissa)",
        OriginCenter: "Koraput_Kakiriguma_D (Orissa)",
        FacilityCity: "Koraput",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10465,
        Pin: 577123,
        DispatchCenter: "Sringeri_Kalmetlu_DPP (Karnataka)",
        OriginCenter: "Sringeri_Kalmetlu_DPP (Karnataka)",
        FacilityCity: "Sringeri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10466,
        Pin: 251202,
        DispatchCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        OriginCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        FacilityCity: "Muzaffarnagar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10467,
        Pin: 814122,
        DispatchCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        OriginCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        FacilityCity: "Deoghar",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10468,
        Pin: 581359,
        DispatchCenter: "Yellapur_Tatagar_DPP (Karnataka)",
        OriginCenter: "Yellapur_Tatagar_DPP (Karnataka)",
        FacilityCity: "Yellapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10469,
        Pin: 506222,
        DispatchCenter: "Thirumalagiri_MaliPuramRoad_D (Telangana)",
        OriginCenter: "Thirumalagiri_MaliPuramRoad_D (Telangana)",
        FacilityCity: "Thirumalagiri",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10470,
        Pin: 225121,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10471,
        Pin: 852137,
        DispatchCenter: "Birpur_Bhimpur_D (Bihar)",
        OriginCenter: "Birpur_Bhimpur_D (Bihar)",
        FacilityCity: "Birpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10472,
        Pin: 284126,
        DispatchCenter: "Talbahat_CityRoad_DPP (Uttar Pradesh)",
        OriginCenter: "Talbahat_CityRoad_DPP (Uttar Pradesh)",
        FacilityCity: "Talbahat",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10473,
        Pin: 700065,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10474,
        Pin: 734204,
        DispatchCenter: "Kurseong_PankhabariR_D (West Bengal)",
        OriginCenter: "Kurseong_PankhabariR_D (West Bengal)",
        FacilityCity: "Kurseong",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10475,
        Pin: 423101,
        DispatchCenter: "Chandwad_MarketYrd_DPP (Maharashtra)",
        OriginCenter: "Chandwad_MarketYrd_DPP (Maharashtra)",
        FacilityCity: "Chandwad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10476,
        Pin: 700159,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10477,
        Pin: 787054,
        DispatchCenter: "Narayanpur_Bangalmarathengal_D (Assam)",
        OriginCenter: "Narayanpur_Bangalmarathengal_D (Assam)",
        FacilityCity: "Narayanpur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10478,
        Pin: 586115,
        DispatchCenter: "Sindagi_Bijapurrd_D (Karnataka)",
        OriginCenter: "Sindagi_Bijapurrd_D (Karnataka)",
        FacilityCity: "Sindagi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10479,
        Pin: 515822,
        DispatchCenter: "Uravakonda_GovtDegreeClg_D (Andhra Pradesh)",
        OriginCenter: "Uravakonda_GovtDegreeClg_D (Andhra Pradesh)",
        FacilityCity: "Uravakonda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10480,
        Pin: 828130,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10481,
        Pin: 518006,
        DispatchCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        OriginCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10482,
        Pin: 500075,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10483,
        Pin: 416423,
        DispatchCenter: "Sangli_Dattanagar_D (Maharashtra)",
        OriginCenter: "Sangli_Dattanagar_D (Maharashtra)",
        FacilityCity: "Sangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10484,
        Pin: 451551,
        DispatchCenter: "Barwani_IGcolony_D (Madhya Pradesh)",
        OriginCenter: "Barwani_IGcolony_D (Madhya Pradesh)",
        FacilityCity: "Barwani",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 10485,
        Pin: 583235,
        DispatchCenter: "Gangavathi_CBSGanj_DPP (Karnataka)",
        OriginCenter: "Gangavathi_CBSGanj_DPP (Karnataka)",
        FacilityCity: "Gangavathi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10486,
        Pin: 585308,
        DispatchCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        OriginCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        FacilityCity: "Gulbarga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10487,
        Pin: 321602,
        DispatchCenter: "Kherli_Doroli_D (Rajasthan)",
        OriginCenter: "Kherli_Doroli_D (Rajasthan)",
        FacilityCity: "Kherli",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10488,
        Pin: 415523,
        DispatchCenter: "Phaltan_Kolki_D (Maharashtra)",
        OriginCenter: "Phaltan_Kolki_D (Maharashtra)",
        FacilityCity: "Phaltan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10489,
        Pin: 423701,
        DispatchCenter: "Vaijapur_Rajsarthi_D (Maharashtra)",
        OriginCenter: "Vaijapur_Rajsarthi_D (Maharashtra)",
        FacilityCity: "Vaijapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10490,
        Pin: 210003,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10491,
        Pin: 521153,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10492,
        Pin: 691573,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10493,
        Pin: 192211,
        DispatchCenter: "Anantnag_Qazigund_D (Jammu & Kashmir)",
        OriginCenter: "Anantnag_Qazigund_D (Jammu & Kashmir)",
        FacilityCity: "Anantnag",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10494,
        Pin: 262527,
        DispatchCenter: "Lohaghat_RanjeetPalace_D (Uttarakhand)",
        OriginCenter: "Lohaghat_RanjeetPalace_D (Uttarakhand)",
        FacilityCity: "Lohaghat",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10495,
        Pin: 622303,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10496,
        Pin: 440004,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10497,
        Pin: 583152,
        DispatchCenter: "Bellary_Mundargi_I (Karnataka)",
        OriginCenter: "Bellary_Mundargi_I (Karnataka)",
        FacilityCity: "Bellary",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10498,
        Pin: 613401,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10499,
        Pin: 416003,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10500,
        Pin: 224183,
        DispatchCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        OriginCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        FacilityCity: "Jalalpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10501,
        Pin: 613302,
        DispatchCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        OriginCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10502,
        Pin: 361170,
        DispatchCenter: "Khambhalia_Dharampur_D (Gujarat)",
        OriginCenter: "Khambhalia_Dharampur_D (Gujarat)",
        FacilityCity: "Khambhalia",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10503,
        Pin: 174013,
        DispatchCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        OriginCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        FacilityCity: "Bilaspur-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10504,
        Pin: 416601,
        DispatchCenter: "Kankavali_NrdawDPP_D (Maharashtra)",
        OriginCenter: "Kankavali_NrdawDPP_D (Maharashtra)",
        FacilityCity: "Kankavali",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10505,
        Pin: 400076,
        DispatchCenter: "Mumbai_Powai_L (Maharashtra)",
        OriginCenter: "Mumbai_Powai_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10506,
        Pin: 577201,
        DispatchCenter: "Shimoga_Eshwariah_I (Karnataka)",
        OriginCenter: "Shimoga_Eshwariah_I (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10507,
        Pin: 673591,
        DispatchCenter: "SultanBathery_Kolagapara_D (Kerala)",
        OriginCenter: "SultanBathery_Kolagapara_D (Kerala)",
        FacilityCity: "Sultan Bathery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10508,
        Pin: 613006,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10509,
        Pin: 686652,
        DispatchCenter: "Pala_Elappunkal_D (Kerala)",
        OriginCenter: "Pala_Elappunkal_D (Kerala)",
        FacilityCity: "Pala",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10510,
        Pin: 304802,
        DispatchCenter: "Nainwa_Ward12_DPP (Rajasthan)",
        OriginCenter: "Nainwa_Ward12_DPP (Rajasthan)",
        FacilityCity: "Nainwa",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10511,
        Pin: 560065,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10512,
        Pin: 695102,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10513,
        Pin: 382745,
        DispatchCenter: "Patdi_Laxminagar_DPP (Gujarat)",
        OriginCenter: "Patdi_Laxminagar_DPP (Gujarat)",
        FacilityCity: "Patdi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10514,
        Pin: 250626,
        DispatchCenter: "Baghpat_Barout_D (Uttar Pradesh)",
        OriginCenter: "Baghpat_Barout_D (Uttar Pradesh)",
        FacilityCity: "Baghpat",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10515,
        Pin: 515531,
        DispatchCenter: "Kadiri_NagireddyPalli_D (Andhra Pradesh)",
        OriginCenter: "Kadiri_NagireddyPalli_D (Andhra Pradesh)",
        FacilityCity: "Kadiri",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10516,
        Pin: 172029,
        DispatchCenter: "Kumarsain_Bharara_D (Himachal Pradesh)",
        OriginCenter: "Kumarsain_Bharara_D (Himachal Pradesh)",
        FacilityCity: "Kumarsain",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10517,
        Pin: 612103,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10518,
        Pin: 415807,
        DispatchCenter: "Lanja_GondeSakhalRd_D (Maharashtra)",
        OriginCenter: "Lanja_GondeSakhalRd_D (Maharashtra)",
        FacilityCity: "Lanja",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10519,
        Pin: 271903,
        DispatchCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        OriginCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        FacilityCity: "Colonejganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10520,
        Pin: 416223,
        DispatchCenter: "RashiwadeBk_Bhogavati_DPP (Maharashtra)",
        OriginCenter: "RashiwadeBk_Bhogavati_DPP (Maharashtra)",
        FacilityCity: "Rashiwade Bk",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10521,
        Pin: 413219,
        DispatchCenter: "Mandrup_SH149_D (Maharashtra)",
        OriginCenter: "Mandrup_SH149_D (Maharashtra)",
        FacilityCity: "Mandrup",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10522,
        Pin: 576220,
        DispatchCenter: "Byndoor_Krmnjshwra_DPP (Karnataka)",
        OriginCenter: "Byndoor_Krmnjshwra_DPP (Karnataka)",
        FacilityCity: "Byndoor",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10523,
        Pin: 382330,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10524,
        Pin: 743126,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10525,
        Pin: 781123,
        DispatchCenter: "Boko_Barpara_D (Assam)",
        OriginCenter: "Boko_Barpara_D (Assam)",
        FacilityCity: "Boko",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10526,
        Pin: 522112,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10527,
        Pin: 473995,
        DispatchCenter: "Pichhore_Rajivnagar_D (Madhya Pradesh)",
        OriginCenter: "Pichhore_Rajivnagar_D (Madhya Pradesh)",
        FacilityCity: "Pichhore",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 10528,
        Pin: 721665,
        DispatchCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        OriginCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        FacilityCity: "Tamluk",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10529,
        Pin: 722182,
        DispatchCenter: "Bankura_Chhatna_DPP (West Bengal)",
        OriginCenter: "Bankura_Chhatna_DPP (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10530,
        Pin: 844131,
        DispatchCenter: "Lalganj_Kharauna_D (Bihar)",
        OriginCenter: "Lalganj_Kharauna_D (Bihar)",
        FacilityCity: "Lalganj-BH",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10531,
        Pin: 641040,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10532,
        Pin: 743704,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10533,
        Pin: 382001,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10534,
        Pin: 769012,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10535,
        Pin: 641008,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10536,
        Pin: 782480,
        DispatchCenter: "Bokajan_Central_D (Assam)",
        OriginCenter: "Bokajan_Central_D (Assam)",
        FacilityCity: "Bokajan",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10537,
        Pin: 758001,
        DispatchCenter: "Kendujhar_Jamunalia_I (Orissa)",
        OriginCenter: "Kendujhar_Jamunalia_I (Orissa)",
        FacilityCity: "Kendujhar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10538,
        Pin: 757042,
        DispatchCenter: "Rairangpur_Sanchampauda_D (Orissa)",
        OriginCenter: "Rairangpur_Sanchampauda_D (Orissa)",
        FacilityCity: "Rairangpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10539,
        Pin: 523101,
        DispatchCenter: "Singarayakonda_TrunkRd_D (Andhra Pradesh)",
        OriginCenter: "Singarayakonda_TrunkRd_D (Andhra Pradesh)",
        FacilityCity: "Singarayakonda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10540,
        Pin: 741140,
        DispatchCenter: "Nakashipara_Kanthalbaria_DPP (West Bengal)",
        OriginCenter: "Nakashipara_Kanthalbaria_DPP (West Bengal)",
        FacilityCity: "Nakashipara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10541,
        Pin: 423607,
        DispatchCenter: "Kopargaon_Murshatpur_D (Maharashtra)",
        OriginCenter: "Kopargaon_Murshatpur_D (Maharashtra)",
        FacilityCity: "Kopargaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10542,
        Pin: 722163,
        DispatchCenter: "Khatra_Simlapal_DPP (West Bengal)",
        OriginCenter: "Khatra_Simlapal_DPP (West Bengal)",
        FacilityCity: "Khatra",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10543,
        Pin: 285134,
        DispatchCenter: "Jalaun_Madhogarh_D (Uttar Pradesh)",
        OriginCenter: "Jalaun_Madhogarh_D (Uttar Pradesh)",
        FacilityCity: "Jalaun",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10544,
        Pin: 393105,
        DispatchCenter: "Karjan_Junabzr_DC (Gujarat)",
        OriginCenter: "Karjan_Junabzr_DC (Gujarat)",
        FacilityCity: "Karjan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10545,
        Pin: 783131,
        DispatchCenter: "Fekamari_Hatsingimari_DPP (Assam)",
        OriginCenter: "Fekamari_Hatsingimari_DPP (Assam)",
        FacilityCity: "Fekamari",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10546,
        Pin: 629004,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10547,
        Pin: 249202,
        DispatchCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        OriginCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        FacilityCity: "Rishikesh",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10548,
        Pin: 628619,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10549,
        Pin: 151507,
        DispatchCenter: "Ratia_Baslan_D (Haryana)",
        OriginCenter: "Ratia_Baslan_D (Haryana)",
        FacilityCity: "Ratia",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10550,
        Pin: 585324,
        DispatchCenter: "Chincholi_CKRd_D (Karnataka)",
        OriginCenter: "Chincholi_CKRd_D (Karnataka)",
        FacilityCity: "Chincholi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10551,
        Pin: 600062,
        DispatchCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        OriginCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10552,
        Pin: 246429,
        DispatchCenter: "Chamoli_Karnaprayag_D (Uttarakhand)",
        OriginCenter: "Chamoli_Karnaprayag_D (Uttarakhand)",
        FacilityCity: "Chamoli",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10553,
        Pin: 754008,
        DispatchCenter: "Kalapathar_GoleiChhak_D (Orissa)",
        OriginCenter: "Kalapathar_GoleiChhak_D (Orissa)",
        FacilityCity: "Kalapathar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10554,
        Pin: 783310,
        DispatchCenter: "Dhubri_Tetultol_D (Assam)",
        OriginCenter: "Dhubri_Tetultol_D (Assam)",
        FacilityCity: "Dhubri",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10555,
        Pin: 501401,
        DispatchCenter: "Hyderabad_Medchal1_L (Telangana)",
        OriginCenter: "Hyderabad_Medchal_GW (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10556,
        Pin: 571212,
        DispatchCenter: "Virajpet_TeluguSt_D (Karnataka)",
        OriginCenter: "Virajpet_TeluguSt_D (Karnataka)",
        FacilityCity: "Virajpet",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10557,
        Pin: 341513,
        DispatchCenter: "Merta_Riyanbari_DPP (Rajasthan)",
        OriginCenter: "Merta_Riyanbari_DPP (Rajasthan)",
        FacilityCity: "Merta",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10558,
        Pin: 241204,
        DispatchCenter: "Sandila_SubabDPP_D (Uttar Pradesh)",
        OriginCenter: "Sandila_SubabDPP_D (Uttar Pradesh)",
        FacilityCity: "Sandila",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10559,
        Pin: 180003,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10560,
        Pin: 712503,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10561,
        Pin: 609608,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10562,
        Pin: 176054,
        DispatchCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        OriginCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        FacilityCity: "Kangra",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10563,
        Pin: 160082,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10564,
        Pin: 123028,
        DispatchCenter: "Narnaul_Keshavnagar_D (Haryana)",
        OriginCenter: "Narnaul_Keshavnagar_D (Haryana)",
        FacilityCity: "Narnaul",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10565,
        Pin: 396570,
        DispatchCenter: "Vansda_Chikatiya_D (Gujarat)",
        OriginCenter: "Vansda_Chikatiya_D (Gujarat)",
        FacilityCity: "Vansda",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10566,
        Pin: 682018,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10567,
        Pin: 143537,
        DispatchCenter: "Gurdaspur_Civillines_D (Punjab)",
        OriginCenter: "Gurdaspur_Civillines_D (Punjab)",
        FacilityCity: "Gurdaspur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10568,
        Pin: 679303,
        DispatchCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        OriginCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        FacilityCity: "Sankaramangalam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10569,
        Pin: 314804,
        DispatchCenter: "Dungarpur_Bhichhiwara_DPP (Rajasthan)",
        OriginCenter: "Dungarpur_Bhichhiwara_DPP (Rajasthan)",
        FacilityCity: "Simalwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10570,
        Pin: 643243,
        DispatchCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        OriginCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10571,
        Pin: 424305,
        DispatchCenter: "Sakri_Arihantnagar_D (Maharashtra)",
        OriginCenter: "Sakri_Arihantnagar_D (Maharashtra)",
        FacilityCity: "Sakri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10572,
        Pin: 600004,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10573,
        Pin: 795142,
        DispatchCenter: "Ukhrul_Dungrei_D (Manipur)",
        OriginCenter: "Ukhrul_Dungrei_D (Manipur)",
        FacilityCity: "Ukhrul",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10574,
        Pin: 576235,
        DispatchCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        OriginCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10575,
        Pin: 416521,
        DispatchCenter: "Oras_Ranbambuli_D (Maharashtra)",
        OriginCenter: "Oras_Ranbambuli_D (Maharashtra)",
        FacilityCity: "Oras",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10576,
        Pin: 852128,
        DispatchCenter: "Madhepura_Azadnagar_D (Bihar)",
        OriginCenter: "Madhepura_Azadnagar_D (Bihar)",
        FacilityCity: "Madhepura",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10577,
        Pin: 383251,
        DispatchCenter: "Bhiloda_NirSagarPlaza_D (Gujarat)",
        OriginCenter: "Bhiloda_NirSagarPlaza_D (Gujarat)",
        FacilityCity: "Bhiloda",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10578,
        Pin: 560024,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10579,
        Pin: 392040,
        DispatchCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        OriginCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        FacilityCity: "Jambusar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10580,
        Pin: 670327,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10581,
        Pin: 523121,
        DispatchCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        OriginCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        FacilityCity: "Kanigiri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10582,
        Pin: 682305,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10583,
        Pin: 783348,
        DispatchCenter: "Bilasipara_HnumnMndrRd_DPP (Assam)",
        OriginCenter: "Bilasipara_HnumnMndrRd_DPP (Assam)",
        FacilityCity: "Bilasipara",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10584,
        Pin: 402208,
        DispatchCenter: "Alibag_Shivanagar_D (Maharashtra)",
        OriginCenter: "Alibag_Shivanagar_D (Maharashtra)",
        FacilityCity: "Alibag",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10585,
        Pin: 175033,
        DispatchCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        OriginCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        FacilityCity: "Jahu",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10586,
        Pin: 682315,
        DispatchCenter: "Kanjiramattom_Piravom_D (Kerala)",
        OriginCenter: "Kanjiramattom_Piravom_D (Kerala)",
        FacilityCity: "Kanjiramattom",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10587,
        Pin: 495113,
        DispatchCenter: "Ratanpur_GandhiNagar_DPP (Chhattisgarh)",
        OriginCenter: "Ratanpur_GandhiNagar_DPP (Chhattisgarh)",
        FacilityCity: "Ratanpur",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10588,
        Pin: 324004,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10589,
        Pin: 641662,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10590,
        Pin: 305622,
        DispatchCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        OriginCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        FacilityCity: "Bijainagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10591,
        Pin: 533296,
        DispatchCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        OriginCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        FacilityCity: "Rajahmundry",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10592,
        Pin: 600056,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10593,
        Pin: 384290,
        DispatchCenter: "Unjha_SatymComplx_D (Gujarat)",
        OriginCenter: "Unjha_SatymComplx_D (Gujarat)",
        FacilityCity: "Unjha",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10594,
        Pin: 586101,
        DispatchCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        OriginCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        FacilityCity: "Bijapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10595,
        Pin: 695042,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10596,
        Pin: 629301,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10597,
        Pin: 144521,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10598,
        Pin: 451115,
        DispatchCenter: "Sanawad_Azadnagar_DPP (Madhya Pradesh)",
        OriginCenter: "Sanawad_Azadnagar_DPP (Madhya Pradesh)",
        FacilityCity: "Sanawad",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 10599,
        Pin: 182128,
        DispatchCenter: "Billawar_Wardno7_D (Jammu & Kashmir)",
        OriginCenter: "Billawar_Wardno7_D (Jammu & Kashmir)",
        FacilityCity: "Billawar",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10600,
        Pin: 422403,
        DispatchCenter: "Igatpuri_Phulenagar_D (Maharashtra)",
        OriginCenter: "Igatpuri_Phulenagar_D (Maharashtra)",
        FacilityCity: "Igatpuri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10601,
        Pin: 503245,
        DispatchCenter: "Nizamabad_Sarangapur_I (Telangana)",
        OriginCenter: "Nizamabad_Sarangapur_I (Telangana)",
        FacilityCity: "Nizamabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10602,
        Pin: 785703,
        DispatchCenter: "Dergaon_Rojabahar_DPP (Assam)",
        OriginCenter: "Dergaon_Rojabahar_DPP (Assam)",
        FacilityCity: "Dergaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10603,
        Pin: 637003,
        DispatchCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        OriginCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        FacilityCity: "Namakkal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10604,
        Pin: 208014,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10605,
        Pin: 148001,
        DispatchCenter: "Sangrur_DC (Punjab)",
        OriginCenter: "Sangrur_DC (Punjab)",
        FacilityCity: "Sangrur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10606,
        Pin: 784110,
        DispatchCenter: "Thelamara_NilachalPath_D (Assam)",
        OriginCenter: "Thelamara_NilachalPath_D (Assam)",
        FacilityCity: "Thelamara",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10607,
        Pin: 250609,
        DispatchCenter: "Baghpat_Barout_D (Uttar Pradesh)",
        OriginCenter: "Baghpat_Barout_D (Uttar Pradesh)",
        FacilityCity: "Baghpat",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10608,
        Pin: 226021,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10609,
        Pin: 443303,
        DispatchCenter: "Mehkar_Irrigationcol_DPP (Maharashtra)",
        OriginCenter: "Mehkar_Irrigationcol_DPP (Maharashtra)",
        FacilityCity: "Mehkar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10610,
        Pin: 192212,
        DispatchCenter: "Anantnag_Qazigund_D (Jammu & Kashmir)",
        OriginCenter: "Anantnag_Qazigund_D (Jammu & Kashmir)",
        FacilityCity: "Anantnag",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10611,
        Pin: 577131,
        DispatchCenter: "Koppa_Haranduru_D (Karnataka)",
        OriginCenter: "Koppa_Haranduru_D (Karnataka)",
        FacilityCity: "Koppa-ka",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10612,
        Pin: 229304,
        DispatchCenter: "Gauriganj_Jais_D (Uttar Pradesh)",
        OriginCenter: "Gauriganj_Jais_D (Uttar Pradesh)",
        FacilityCity: "Gauriganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10613,
        Pin: 636201,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10614,
        Pin: 506313,
        DispatchCenter: "Rayaparthi_Busstand_D (Telangana)",
        OriginCenter: "Rayaparthi_Busstand_D (Telangana)",
        FacilityCity: "Rayaparthi",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10615,
        Pin: 573165,
        DispatchCenter: "Sakleshpur_Nudarahalli_D (Karnataka)",
        OriginCenter: "Sakleshpur_Nudarahalli_D (Karnataka)",
        FacilityCity: "Sakleshpur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10616,
        Pin: 524415,
        DispatchCenter: "Vidyanagar_Kota_D (Andhra Pradesh)",
        OriginCenter: "Vidyanagar_Kota_D (Andhra Pradesh)",
        FacilityCity: "Vidyanagar",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10617,
        Pin: 403106,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10618,
        Pin: 581344,
        DispatchCenter: "Ankola_Madangeri_D (Karnataka)",
        OriginCenter: "Ankola_Madangeri_D (Karnataka)",
        FacilityCity: "Ankola",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10619,
        Pin: 581340,
        DispatchCenter: "Sirsi_Kasturbanagar_D (Karnataka)",
        OriginCenter: "Sirsi_Kasturbanagar_D (Karnataka)",
        FacilityCity: "Sirsi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10620,
        Pin: 522019,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10621,
        Pin: 134102,
        DispatchCenter: "Parwanoo_Sector2_D (Himachal Pradesh)",
        OriginCenter: "Parwanoo_Sector2_D (Himachal Pradesh)",
        FacilityCity: "Parwanoo",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10622,
        Pin: 431142,
        DispatchCenter: "Majalgaon_SmbjiCwk_D (Maharashtra)",
        OriginCenter: "Majalgaon_SmbjiCwk_D (Maharashtra)",
        FacilityCity: "Manjlegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10623,
        Pin: 416234,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10624,
        Pin: 481101,
        DispatchCenter: "Balaghat_Sarekha_D (Madhya Pradesh)",
        OriginCenter: "Balaghat_Sarekha_D (Madhya Pradesh)",
        FacilityCity: "Balaghat",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 10625,
        Pin: 682317,
        DispatchCenter: "Kanjiramattom_Piravom_D (Kerala)",
        OriginCenter: "Kanjiramattom_Piravom_D (Kerala)",
        FacilityCity: "Kanjiramattom",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10626,
        Pin: 341030,
        DispatchCenter: "Nagaur_Kuchera_D (Rajasthan)",
        OriginCenter: "Nagaur_Kuchera_D (Rajasthan)",
        FacilityCity: "Nagaur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10627,
        Pin: 756002,
        DispatchCenter: "Balasore_Ganeswarpur_D (Orissa)",
        OriginCenter: "Balasore_Ganeswarpur_D (Orissa)",
        FacilityCity: "Balasore",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10628,
        Pin: 422405,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10629,
        Pin: 621214,
        DispatchCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        OriginCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        FacilityCity: "Thuraiyur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10630,
        Pin: 741302,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10631,
        Pin: 141101,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10632,
        Pin: 638153,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10633,
        Pin: 495449,
        DispatchCenter: "Ratanpur_Pali_D (Chhattisgarh)",
        OriginCenter: "Ratanpur_Pali_D (Chhattisgarh)",
        FacilityCity: "Ratanpur",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10634,
        Pin: 614202,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10635,
        Pin: 576213,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10636,
        Pin: 413507,
        DispatchCenter: "Kaij_Kalamb_DPP (Maharashtra)",
        OriginCenter: "Kaij_Kalamb_DPP (Maharashtra)",
        FacilityCity: "Kaij",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10637,
        Pin: 325202,
        DispatchCenter: "Baran_Housingboard_D (Rajasthan)",
        OriginCenter: "Baran_Housingboard_D (Rajasthan)",
        FacilityCity: "Baran",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10638,
        Pin: 331301,
        DispatchCenter: "Bahal_Gdccolony_D (Haryana)",
        OriginCenter: "Bahal_Gdccolony_D (Haryana)",
        FacilityCity: "Bahal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10639,
        Pin: 204102,
        DispatchCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        OriginCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        FacilityCity: "Hathras",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10640,
        Pin: 561208,
        DispatchCenter: "Gauribidanur_Srinagara_D (Karnataka)",
        OriginCenter: "Gauribidanur_Srinagara_D (Karnataka)",
        FacilityCity: "Gauribidanur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10641,
        Pin: 416208,
        DispatchCenter: "RashiwadeBk_Shelewadi_DPP (Maharashtra)",
        OriginCenter: "RashiwadeBk_Shelewadi_DPP (Maharashtra)",
        FacilityCity: "Rashiwade Bk",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10642,
        Pin: 334001,
        DispatchCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        OriginCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        FacilityCity: "Bikaner",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10643,
        Pin: 689650,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10644,
        Pin: 742236,
        DispatchCenter: "Farakka_BngrmDPP_D (West Bengal)",
        OriginCenter: "Farakka_BngrmDPP_D (West Bengal)",
        FacilityCity: "Farakka",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10645,
        Pin: 421505,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10646,
        Pin: 174035,
        DispatchCenter: "KalolHP_CsdCanteen_DPP (Himachal Pradesh)",
        OriginCenter: "KalolHP_CsdCanteen_DPP (Himachal Pradesh)",
        FacilityCity: "KalolHP",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10647,
        Pin: 444404,
        DispatchCenter: "Digras_Virajcplx_DPP (Maharashtra)",
        OriginCenter: "Digras_Virajcplx_DPP (Maharashtra)",
        FacilityCity: "Digras",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10648,
        Pin: 736135,
        DispatchCenter: "Dinhata_BhdiKhda_DPP (West Bengal)",
        OriginCenter: "Dinhata_BhdiKhda_DPP (West Bengal)",
        FacilityCity: "Dinhata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10649,
        Pin: 492011,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10650,
        Pin: 413736,
        DispatchCenter: "Loni_BailBzr_DPP (Maharashtra)",
        OriginCenter: "Loni_BailBzr_DPP (Maharashtra)",
        FacilityCity: "Loni",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10651,
        Pin: 620016,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10652,
        Pin: 721125,
        DispatchCenter: "Jhargram_Gopiballavpur_DPP (West Bengal)",
        OriginCenter: "Jhargram_Gopiballavpur_DPP (West Bengal)",
        FacilityCity: "Jhargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10653,
        Pin: 752031,
        DispatchCenter: "Balugaon_Oldasianhighwy_D (Orissa)",
        OriginCenter: "Balugaon_Oldasianhighwy_D (Orissa)",
        FacilityCity: "Balugaon",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10654,
        Pin: 262901,
        DispatchCenter: "Nighasan_PaliaKalan_D (Uttar Pradesh)",
        OriginCenter: "Nighasan_PaliaKalan_D (Uttar Pradesh)",
        FacilityCity: "Nighasan",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10655,
        Pin: 680681,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10656,
        Pin: 521333,
        DispatchCenter: "Kaikaluru_Gonepadu_D (Andhra Pradesh)",
        OriginCenter: "Kaikaluru_Gonepadu_D (Andhra Pradesh)",
        FacilityCity: "Kaikaluru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10657,
        Pin: 144517,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10658,
        Pin: 731235,
        DispatchCenter: "Bolpur_Surul_DPP (West Bengal)",
        OriginCenter: "Bolpur_Surul_DPP (West Bengal)",
        FacilityCity: "Bolpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10659,
        Pin: 444506,
        DispatchCenter: "Risod_BusStand_DPP (Maharashtra)",
        OriginCenter: "Risod_BusStand_DPP (Maharashtra)",
        FacilityCity: "Risod",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10660,
        Pin: 621201,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10661,
        Pin: 412803,
        DispatchCenter: "Wai_Bavdhan_DPP (Maharashtra)",
        OriginCenter: "Wai_Bavdhan_DPP (Maharashtra)",
        FacilityCity: "Wai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10662,
        Pin: 835301,
        DispatchCenter: "Bero_Gumlaroad_D (Jharkhand)",
        OriginCenter: "Bero_Gumlaroad_D (Jharkhand)",
        FacilityCity: "Bero",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10663,
        Pin: 627420,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10664,
        Pin: 608401,
        DispatchCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        OriginCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        FacilityCity: "Chidambaram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10665,
        Pin: 786188,
        DispatchCenter: "Margherita_CivilHPL_D (Assam)",
        OriginCenter: "Margherita_CivilHPL_D (Assam)",
        FacilityCity: "Margherita",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10666,
        Pin: 515321,
        DispatchCenter: "Madakasira_RTCStand_D (Andhra Pradesh)",
        OriginCenter: "Madakasira_RTCStand_D (Andhra Pradesh)",
        FacilityCity: "Madakasira",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10667,
        Pin: 712405,
        DispatchCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        OriginCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        FacilityCity: "Tarkeshwar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10668,
        Pin: 848204,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10669,
        Pin: 321001,
        DispatchCenter: "Bharatpur_DurgaNagar_D (Rajasthan)",
        OriginCenter: "Bharatpur_DurgaNagar_D (Rajasthan)",
        FacilityCity: "Bharatpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10670,
        Pin: 410516,
        DispatchCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        OriginCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        FacilityCity: "Ambegaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10671,
        Pin: 175037,
        DispatchCenter: "Sarkaghat_Khelag_D (Himachal Pradesh)",
        OriginCenter: "Sarkaghat_Khelag_D (Himachal Pradesh)",
        FacilityCity: "Sarkaghat",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10672,
        Pin: 679338,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10673,
        Pin: 203395,
        DispatchCenter: "Dibai_Pahasu_D (Uttar Pradesh)",
        OriginCenter: "Dibai_Pahasu_D (Uttar Pradesh)",
        FacilityCity: "Dibai",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10674,
        Pin: 582101,
        DispatchCenter: "Gadag_Vidyanagar_D (Karnataka)",
        OriginCenter: "Gadag_Vidyanagar_D (Karnataka)",
        FacilityCity: "Gadag",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10675,
        Pin: 518598,
        DispatchCenter: "Betamcherla_ChinnaSch_DPP (Andhra Pradesh)",
        OriginCenter: "Betamcherla_ChinnaSch_DPP (Andhra Pradesh)",
        FacilityCity: "Betamcherla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10676,
        Pin: 671329,
        DispatchCenter: "Kanhangad_Ajanur_D (Kerala)",
        OriginCenter: "Kanhangad_Ajanur_D (Kerala)",
        FacilityCity: "Kasaragod",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10677,
        Pin: 680543,
        DispatchCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        OriginCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10678,
        Pin: 713426,
        DispatchCenter: "Manteswar_MemariRD_D (West Bengal)",
        OriginCenter: "Manteswar_MemariRD_D (West Bengal)",
        FacilityCity: "Manteswar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10679,
        Pin: 636202,
        DispatchCenter: "Mettala_Rasipuram_D (Tamil Nadu)",
        OriginCenter: "Mettala_Rasipuram_D (Tamil Nadu)",
        FacilityCity: "Mettala",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10680,
        Pin: 425506,
        DispatchCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        OriginCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        FacilityCity: "Raver",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10681,
        Pin: 343026,
        DispatchCenter: "Jalore_RoopNgr_D (Rajasthan)",
        OriginCenter: "Jalore_RoopNgr_D (Rajasthan)",
        FacilityCity: "Jalore",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10682,
        Pin: 281405,
        DispatchCenter: "Chhata_Krishnacolony_D (Uttar Pradesh)",
        OriginCenter: "Chhata_Krishnacolony_D (Uttar Pradesh)",
        FacilityCity: "Chhata",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10683,
        Pin: 842005,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10684,
        Pin: 683519,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10685,
        Pin: 465667,
        DispatchCenter: "Narsinghgarh_Bypassroad_D (Madhya Pradesh)",
        OriginCenter: "Narsinghgarh_Bypassroad_D (Madhya Pradesh)",
        FacilityCity: "Narsinghgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 10686,
        Pin: 175008,
        DispatchCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        OriginCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        FacilityCity: "Mandi",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10687,
        Pin: 722140,
        DispatchCenter: "Khatra_KoralDPP_D (West Bengal)",
        OriginCenter: "Khatra_KoralDPP_D (West Bengal)",
        FacilityCity: "Khatra",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10688,
        Pin: 804408,
        DispatchCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        OriginCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        FacilityCity: "Jehanabad",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10689,
        Pin: 492006,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10690,
        Pin: 841460,
        DispatchCenter: "Marhowarah_StatinRD_D (Bihar)",
        OriginCenter: "Marhowarah_StatinRD_D (Bihar)",
        FacilityCity: "Marhowarah",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10691,
        Pin: 521117,
        DispatchCenter: "Kaikaluru_Gonepadu_D (Andhra Pradesh)",
        OriginCenter: "Kaikaluru_Gonepadu_D (Andhra Pradesh)",
        FacilityCity: "Kaikaluru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10692,
        Pin: 671124,
        DispatchCenter: "Kasaragod_Kudlu_D (Kerala)",
        OriginCenter: "Kasaragod_Kudlu_D (Kerala)",
        FacilityCity: "Kasaragod",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10693,
        Pin: 532322,
        DispatchCenter: "Sompeta_PostOffice_DPP (Andhra Pradesh)",
        OriginCenter: "Sompeta_PostOffice_DPP (Andhra Pradesh)",
        FacilityCity: "Sompeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10694,
        Pin: 124102,
        DispatchCenter: "Jhajjar_Policeline_I (Haryana)",
        OriginCenter: "Jhajjar_Policeline_I (Haryana)",
        FacilityCity: "Jhajjar",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10695,
        Pin: 301604,
        DispatchCenter: "Laxmangarh_Lilee_D (Rajasthan)",
        OriginCenter: "Laxmangarh_Lilee_D (Rajasthan)",
        FacilityCity: "Laxmangarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10696,
        Pin: 761108,
        DispatchCenter: "Badagada_Kesharipatana_DPP (Orissa)",
        OriginCenter: "Badagada_Kesharipatana_DPP (Orissa)",
        FacilityCity: "Badagada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10697,
        Pin: 754081,
        DispatchCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        OriginCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        FacilityCity: "Chandikhol",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10698,
        Pin: 628620,
        DispatchCenter: "Eral_Nazareth_D (Tamil Nadu)",
        OriginCenter: "Eral_Nazareth_D (Tamil Nadu)",
        FacilityCity: "Eral",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10699,
        Pin: 816109,
        DispatchCenter: "Sahebganj_Ambadiha_D (Jharkhand)",
        OriginCenter: "Sahebganj_Ambadiha_D (Jharkhand)",
        FacilityCity: "Sahebganj",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10700,
        Pin: 491225,
        DispatchCenter: "Balod_HaldharChowk_D (Chhattisgarh)",
        OriginCenter: "Balod_HaldharChowk_D (Chhattisgarh)",
        FacilityCity: "Balod",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10701,
        Pin: 788010,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10702,
        Pin: 325215,
        DispatchCenter: "Baran_Housingboard_D (Rajasthan)",
        OriginCenter: "Baran_Housingboard_D (Rajasthan)",
        FacilityCity: "Baran",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10703,
        Pin: 848207,
        DispatchCenter: "Singhia_Bithan2_DPP (Bihar)",
        OriginCenter: "Singhia_Bithan2_DPP (Bihar)",
        FacilityCity: "Singhia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10704,
        Pin: 623512,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10705,
        Pin: 627414,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10706,
        Pin: 283205,
        DispatchCenter: "Firozabad_MahadeoNagar_D (Uttar Pradesh)",
        OriginCenter: "Firozabad_MahadeoNagar_D (Uttar Pradesh)",
        FacilityCity: "Firozabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10707,
        Pin: 362014,
        DispatchCenter: "Junagadh_Dolatpara_I (Gujarat)",
        OriginCenter: "Junagadh_Dolatpara_I (Gujarat)",
        FacilityCity: "Junagadh",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10708,
        Pin: 509326,
        DispatchCenter: "Achampet_Madhuranagar_D (Telangana)",
        OriginCenter: "Achampet_Madhuranagar_D (Telangana)",
        FacilityCity: "Achampet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10709,
        Pin: 851217,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10710,
        Pin: 174301,
        DispatchCenter: "Tahliwal_Central_D (Himachal Pradesh)",
        OriginCenter: "Tahliwal_Central_D (Himachal Pradesh)",
        FacilityCity: "Tahliwal",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10711,
        Pin: 713420,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10712,
        Pin: 732128,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10713,
        Pin: 678761,
        DispatchCenter: "Thachnttukra_Mangadn_D (Kerala)",
        OriginCenter: "Thachnttukra_Mangadn_D (Kerala)",
        FacilityCity: "Thachanattukara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10714,
        Pin: 341012,
        DispatchCenter: "Nagaur_Jantacomplex_D (Rajasthan)",
        OriginCenter: "Nagaur_Jantacomplex_D (Rajasthan)",
        FacilityCity: "Nagaur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10715,
        Pin: 799046,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10716,
        Pin: 741167,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10717,
        Pin: 509340,
        DispatchCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        OriginCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        FacilityCity: "Mahabubnagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10718,
        Pin: 785618,
        DispatchCenter: "Dergaon_Rojabahar_DPP (Assam)",
        OriginCenter: "Dergaon_Rojabahar_DPP (Assam)",
        FacilityCity: "Dergaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10719,
        Pin: 700106,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10720,
        Pin: 500041,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10721,
        Pin: 249306,
        DispatchCenter: "Haridwar_IndustrialArea_D (Uttarakhand)",
        OriginCenter: "Haridwar_IndustrialArea_D (Uttarakhand)",
        FacilityCity: "Haridwar",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10722,
        Pin: 415629,
        DispatchCenter: "Ratnagiri_GaneshNagar_D (Maharashtra)",
        OriginCenter: "Ratnagiri_GaneshNagar_D (Maharashtra)",
        FacilityCity: "Ratnagiri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10723,
        Pin: 388460,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10724,
        Pin: 560086,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10725,
        Pin: 384271,
        DispatchCenter: "Patan_Hansapur_D (Gujarat)",
        OriginCenter: "Patan_Hansapur_D (Gujarat)",
        FacilityCity: "Patan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10726,
        Pin: 301036,
        DispatchCenter: "Laxmangarh_Lilee_D (Rajasthan)",
        OriginCenter: "Laxmangarh_Lilee_D (Rajasthan)",
        FacilityCity: "Laxmangarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10727,
        Pin: 712703,
        DispatchCenter: "Ilipur_Dhanyahana_D (West Bengal)",
        OriginCenter: "Ilipur_Dhanyahana_D (West Bengal)",
        FacilityCity: "Ilipur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10728,
        Pin: 761010,
        DispatchCenter: "Chikiti_Bhlasingi_D (Orissa)",
        OriginCenter: "Chikiti_Bhlasingi_D (Orissa)",
        FacilityCity: "Chikiti",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10729,
        Pin: 754141,
        DispatchCenter: "Paradip_Udyabata_D (Orissa)",
        OriginCenter: "Paradip_Udyabata_D (Orissa)",
        FacilityCity: "Paradip",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10730,
        Pin: 762012,
        DispatchCenter: "Phulbani_NipiditaOfc_D (Orissa)",
        OriginCenter: "Phulbani_NipiditaOfc_D (Orissa)",
        FacilityCity: "Phulbani",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10731,
        Pin: 639110,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10732,
        Pin: 848506,
        DispatchCenter: "MohiuddinNagar_Manjha_D (Bihar)",
        OriginCenter: "MohiuddinNagar_Manjha_D (Bihar)",
        FacilityCity: "Mohiuddin Nagar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10733,
        Pin: 755045,
        DispatchCenter: "Chandipur_Keshpur_D (Orissa)",
        OriginCenter: "Chandipur_Keshpur_D (Orissa)",
        FacilityCity: "Chandipur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10734,
        Pin: 533002,
        DispatchCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        OriginCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        FacilityCity: "Kakinada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10735,
        Pin: 509412,
        DispatchCenter: "Tadoor_Nagarkurnool_D (Telangana)",
        OriginCenter: "Tadoor_Nagarkurnool_D (Telangana)",
        FacilityCity: "Tadoor",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10736,
        Pin: 621132,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10737,
        Pin: 441112,
        DispatchCenter: "Savner_Raymondcolony_D (Maharashtra)",
        OriginCenter: "Savner_Raymondcolony_D (Maharashtra)",
        FacilityCity: "Savner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10738,
        Pin: 311201,
        DispatchCenter: "DeoliRJ_AmbedkarColny_DPP (Rajasthan)",
        OriginCenter: "DeoliRJ_AmbedkarColny_DPP (Rajasthan)",
        FacilityCity: "Deoli-RJ",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10739,
        Pin: 394330,
        DispatchCenter: "Bardoli_GopalNagar_D (Gujarat)",
        OriginCenter: "Bardoli_GopalNagar_D (Gujarat)",
        FacilityCity: "Bardoli",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10740,
        Pin: 400021,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10741,
        Pin: 613002,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10742,
        Pin: 212304,
        DispatchCenter: "Manda_Salia_D (Uttar Pradesh)",
        OriginCenter: "Manda_Salia_D (Uttar Pradesh)",
        FacilityCity: "Manda",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10743,
        Pin: 445211,
        DispatchCenter: "Umarkhed_HubeCplx_D (Maharashtra)",
        OriginCenter: "Umarkhed_HubeCplx_D (Maharashtra)",
        FacilityCity: "Umarkhed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10744,
        Pin: 636017,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10745,
        Pin: 783380,
        DispatchCenter: "Bongaigaon_Chapaguri_I (Assam)",
        OriginCenter: "Bongaigaon_Chapaguri_I (Assam)",
        FacilityCity: "Bongaigaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10746,
        Pin: 271840,
        DispatchCenter: "Bhinga_ShubhashNagar_D (Uttar Pradesh)",
        OriginCenter: "Bhinga_ShubhashNagar_D (Uttar Pradesh)",
        FacilityCity: "Bhinga",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10747,
        Pin: 400049,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10748,
        Pin: 415211,
        DispatchCenter: "Patan_Ashram_D (Maharashtra)",
        OriginCenter: "Patan_Ashram_D (Maharashtra)",
        FacilityCity: "Patan-MH",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10749,
        Pin: 686617,
        DispatchCenter: "Kottayam_Manjoor_D (Kerala)",
        OriginCenter: "Kottayam_Manjoor_D (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10750,
        Pin: 395007,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10751,
        Pin: 841101,
        DispatchCenter: "Hajipur_Hathsarganj_D (Bihar)",
        OriginCenter: "Hajipur_Hathsarganj_D (Bihar)",
        FacilityCity: "Hajipur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10752,
        Pin: 144525,
        DispatchCenter: "Garhshankar_BangaRoad_D (Punjab)",
        OriginCenter: "Garhshankar_BangaRoad_D (Punjab)",
        FacilityCity: "Garhshanker",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10753,
        Pin: 515271,
        DispatchCenter: "Madakasira_RTCStand_D (Andhra Pradesh)",
        OriginCenter: "Madakasira_RTCStand_D (Andhra Pradesh)",
        FacilityCity: "Madakasira",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10754,
        Pin: 305623,
        DispatchCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        OriginCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        FacilityCity: "Bijainagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10755,
        Pin: 480887,
        DispatchCenter: "Lakhnadon_Ward5_D (Madhya Pradesh)",
        OriginCenter: "Lakhnadon_Ward5_D (Madhya Pradesh)",
        FacilityCity: "Lakhnadon",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 10756,
        Pin: 212413,
        DispatchCenter: "Lalgopalganj_Anandi_D (Uttar Pradesh)",
        OriginCenter: "Lalgopalganj_Anandi_D (Uttar Pradesh)",
        FacilityCity: "Lal Gopalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10757,
        Pin: 414503,
        DispatchCenter: "Shevgaon_MiriRd_DPP (Maharashtra)",
        OriginCenter: "Shevgaon_MiriRd_DPP (Maharashtra)",
        FacilityCity: "Shevgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10758,
        Pin: 494444,
        DispatchCenter: "BijapurCH_Rajivnagar_D (Chhattisgarh)",
        OriginCenter: "BijapurCH_Rajivnagar_D (Chhattisgarh)",
        FacilityCity: "BijapurCH",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10759,
        Pin: 110095,
        DispatchCenter: "Delhi_JhilmilColony_L (Delhi)",
        OriginCenter: "Delhi_JhilmilColony_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10760,
        Pin: 582202,
        DispatchCenter: "Rona_GadagRD_D (Karnataka)",
        OriginCenter: "Rona_GadagRD_D (Karnataka)",
        FacilityCity: "Rona",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10761,
        Pin: 628656,
        DispatchCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        OriginCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        FacilityCity: "Thisayanvilai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10762,
        Pin: 581304,
        DispatchCenter: "Karwar_Sadashivgad_D (Karnataka)",
        OriginCenter: "Karwar_Sadashivgad_D (Karnataka)",
        FacilityCity: "Karwar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10763,
        Pin: 585210,
        DispatchCenter: "Sedam_Venkateshnagar_D (Karnataka)",
        OriginCenter: "Sedam_Venkateshnagar_D (Karnataka)",
        FacilityCity: "Sedam",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10764,
        Pin: 621015,
        DispatchCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        OriginCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        FacilityCity: "Thuraiyur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10765,
        Pin: 444104,
        DispatchCenter: "Akola_Midcphase3_H (Maharashtra)",
        OriginCenter: "Akola_Midcphase3_H (Maharashtra)",
        FacilityCity: "Akola",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10766,
        Pin: 757074,
        DispatchCenter: "Udala_Mayurbhanj_DPP (Orissa)",
        OriginCenter: "Udala_Mayurbhanj_DPP (Orissa)",
        FacilityCity: "Udala",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10767,
        Pin: 144222,
        DispatchCenter: "Dasuya_Hajipur_D (Punjab)",
        OriginCenter: "Dasuya_Hajipur_D (Punjab)",
        FacilityCity: "Dasuya",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10768,
        Pin: 845460,
        DispatchCenter: "Bettiah_Manshatola_D (Bihar)",
        OriginCenter: "Bettiah_Manshatola_D (Bihar)",
        FacilityCity: "Bettiah",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10769,
        Pin: 624001,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10770,
        Pin: 815314,
        DispatchCenter: "Giridih_Kharagdiha_D (Jharkhand)",
        OriginCenter: "Giridih_Kharagdiha_D (Jharkhand)",
        FacilityCity: "Giridih",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10771,
        Pin: 804419,
        DispatchCenter: "Arwal_SipahPnchayt_D (Bihar)",
        OriginCenter: "Arwal_SipahPnchayt_D (Bihar)",
        FacilityCity: "Arwal",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10772,
        Pin: 636113,
        DispatchCenter: "Mettala_Rasipuram_D (Tamil Nadu)",
        OriginCenter: "Mettala_Rasipuram_D (Tamil Nadu)",
        FacilityCity: "Mettala",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10773,
        Pin: 804403,
        DispatchCenter: "Makhdumpur_Nawabganj_D (Bihar)",
        OriginCenter: "Makhdumpur_Nawabganj_D (Bihar)",
        FacilityCity: "Makhdumpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10774,
        Pin: 143201,
        DispatchCenter: "Tarsikka_Beas_D (Punjab)",
        OriginCenter: "Tarsikka_Beas_D (Punjab)",
        FacilityCity: "Beas",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10775,
        Pin: 462023,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 10776,
        Pin: 222141,
        DispatchCenter: "Badlapur_Barauli_D (Uttar Pradesh)",
        OriginCenter: "Badlapur_Barauli_D (Uttar Pradesh)",
        FacilityCity: "Badlapur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10777,
        Pin: 465693,
        DispatchCenter: "RajgarhMP_Jirapur_D (Madhya Pradesh)",
        OriginCenter: "RajgarhMP_Jirapur_D (Madhya Pradesh)",
        FacilityCity: "Rajgarh-MP",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 10778,
        Pin: 638009,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10779,
        Pin: 759120,
        DispatchCenter: "Kamakhyanagar_Mundideuli_D (Orissa)",
        OriginCenter: "Kamakhyanagar_Mundideuli_D (Orissa)",
        FacilityCity: "Kamakhyanagar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10780,
        Pin: 584138,
        DispatchCenter: "Kowthal_UdayaNagar_D (Karnataka)",
        OriginCenter: "Kowthal_UdayaNagar_D (Karnataka)",
        FacilityCity: "Kowthal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10781,
        Pin: 250010,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10782,
        Pin: 133202,
        DispatchCenter: "Ambala_Barara_D (Haryana)",
        OriginCenter: "Ambala_Barara_D (Haryana)",
        FacilityCity: "Ambala",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10783,
        Pin: 757025,
        DispatchCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        OriginCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        FacilityCity: "Betnoti",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10784,
        Pin: 516131,
        DispatchCenter: "Rajampet_BypassRd_D (Andhra Pradesh)",
        OriginCenter: "Rajampet_BypassRd_D (Andhra Pradesh)",
        FacilityCity: "Rajampet",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10785,
        Pin: 639106,
        DispatchCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        OriginCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        FacilityCity: "Kulithalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10786,
        Pin: 643204,
        DispatchCenter: "Ooty_Manjoor_D (Tamil Nadu)",
        OriginCenter: "Ooty_Manjoor_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10787,
        Pin: 824123,
        DispatchCenter: "AurangabadBR_Katharua_D (Bihar)",
        OriginCenter: "AurangabadBR_Katharua_D (Bihar)",
        FacilityCity: "Aurangabad-BR",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10788,
        Pin: 754221,
        DispatchCenter: "Cuttack_SliprDPP_DC (Orissa)",
        OriginCenter: "Cuttack_SliprDPP_DC (Orissa)",
        FacilityCity: "Salipur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10789,
        Pin: 495550,
        DispatchCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        OriginCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        FacilityCity: "Bilaspur",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10790,
        Pin: 301707,
        DispatchCenter: "Tijara_Ward3_D (Rajasthan)",
        OriginCenter: "Tijara_Ward3_D (Rajasthan)",
        FacilityCity: "Tijara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10791,
        Pin: 312202,
        DispatchCenter: "Kapasan_AdarshNgr_DPP (Rajasthan)",
        OriginCenter: "Kapasan_AdarshNgr_DPP (Rajasthan)",
        FacilityCity: "Kapasan",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10792,
        Pin: 313322,
        DispatchCenter: "Rajsamand_Khamnore_DPP (Rajasthan)",
        OriginCenter: "Rajsamand_Khamnore_DPP (Rajasthan)",
        FacilityCity: "Rajsamand",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10793,
        Pin: 813221,
        DispatchCenter: "Sultanganj_Tarapur_DPP (Bihar)",
        OriginCenter: "Sultanganj_Tarapur_DPP (Bihar)",
        FacilityCity: "Sultanganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10794,
        Pin: 233234,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10795,
        Pin: 244231,
        DispatchCenter: "Gajraula_JawaharN_D (Uttar Pradesh)",
        OriginCenter: "Gajraula_JawaharN_D (Uttar Pradesh)",
        FacilityCity: "Gajraula",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10796,
        Pin: 673021,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10797,
        Pin: 785009,
        DispatchCenter: "Jorhat_Titabor_D (Assam)",
        OriginCenter: "Jorhat_Titabor_D (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10798,
        Pin: 464221,
        DispatchCenter: "GanjBasoda_Mahavirvihar_DPP (Madhya Pradesh)",
        OriginCenter: "GanjBasoda_Mahavirvihar_DPP (Madhya Pradesh)",
        FacilityCity: "Ganj Basoda",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 10799,
        Pin: 673306,
        DispatchCenter: "Koyilandy_Komathukara_D (Kerala)",
        OriginCenter: "Koyilandy_Komathukara_D (Kerala)",
        FacilityCity: "Koyilandy",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10800,
        Pin: 361013,
        DispatchCenter: "Kalavad_Khodiyarpara_D (Gujarat)",
        OriginCenter: "Kalavad_Khodiyarpara_D (Gujarat)",
        FacilityCity: "Kalavad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10801,
        Pin: 606753,
        DispatchCenter: "Sankarapuram_Thanipadi_D (Tamil Nadu)",
        OriginCenter: "Sankarapuram_Thanipadi_D (Tamil Nadu)",
        FacilityCity: "Sankarapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10802,
        Pin: 342301,
        DispatchCenter: "Phalodi_NagarPalika_DPP (Rajasthan)",
        OriginCenter: "Phalodi_NagarPalika_DPP (Rajasthan)",
        FacilityCity: "Phalodi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10803,
        Pin: 591123,
        DispatchCenter: "Ramdurg_Busstnd_DPP (Karnataka)",
        OriginCenter: "Ramdurg_Busstnd_DPP (Karnataka)",
        FacilityCity: "Ramdurg",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10804,
        Pin: 515154,
        DispatchCenter: "Mudigubba_Meeseva_D (Andhra Pradesh)",
        OriginCenter: "Mudigubba_Meeseva_D (Andhra Pradesh)",
        FacilityCity: "Mudigubba",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10805,
        Pin: 413713,
        DispatchCenter: "Loni_BailBzr_DPP (Maharashtra)",
        OriginCenter: "Loni_BailBzr_DPP (Maharashtra)",
        FacilityCity: "Loni",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10806,
        Pin: 382730,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10807,
        Pin: 756038,
        DispatchCenter: "Contai_Fatepur_DPP (West Bengal)",
        OriginCenter: "Contai_Fatepur_DPP (West Bengal)",
        FacilityCity: "RamnagarWB",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10808,
        Pin: 365435,
        DispatchCenter: "Dhasa_Nolipara_D (Gujarat)",
        OriginCenter: "Dhasa_Nolipara_D (Gujarat)",
        FacilityCity: "Dhasa",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10809,
        Pin: 524320,
        DispatchCenter: "Nellore_Bhakthavatsala_D (Andhra Pradesh)",
        OriginCenter: "Nellore_Bhakthavatsala_D (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10810,
        Pin: 805135,
        DispatchCenter: "Khizasarai_Lodipur_D (Bihar)",
        OriginCenter: "Khizasarai_Lodipur_D (Bihar)",
        FacilityCity: "Khizasarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10811,
        Pin: 601303,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10812,
        Pin: 685552,
        DispatchCenter: "Nedumkandam_Thannimoodu_D (Kerala)",
        OriginCenter: "Nedumkandam_Thannimoodu_D (Kerala)",
        FacilityCity: "Nedumkandam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10813,
        Pin: 306305,
        DispatchCenter: "Raipur_Mainmarket_D (Rajasthan)",
        OriginCenter: "Raipur_Mainmarket_D (Rajasthan)",
        FacilityCity: "Raipur-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10814,
        Pin: 591230,
        DispatchCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        OriginCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        FacilityCity: "Athani",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10815,
        Pin: 636010,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10816,
        Pin: 583275,
        DispatchCenter: "Bellary_Mundargi_I (Karnataka)",
        OriginCenter: "Bellary_Mundargi_I (Karnataka)",
        FacilityCity: "Bellary",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10817,
        Pin: 752025,
        DispatchCenter: "Tangi_SriDPP_D (Orissa)",
        OriginCenter: "Tangi_SriDPP_D (Orissa)",
        FacilityCity: "Tangi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10818,
        Pin: 144206,
        DispatchCenter: "Hoshiarpur_Hariana_D (Punjab)",
        OriginCenter: "Hoshiarpur_Hariana_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10819,
        Pin: 473226,
        DispatchCenter: "Guna_Shahpur_H (Madhya Pradesh)",
        OriginCenter: "Guna_Shahpur_H (Madhya Pradesh)",
        FacilityCity: "Guna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 10820,
        Pin: 141416,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10821,
        Pin: 785701,
        DispatchCenter: "Nazira_Amina_D (Assam)",
        OriginCenter: "Nazira_Amina_D (Assam)",
        FacilityCity: "Nazira",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10822,
        Pin: 396406,
        DispatchCenter: "Navsari_Vejalpore_D (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Navsari",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10823,
        Pin: 786173,
        DispatchCenter: "Digboi_Itabhata_DPP (Assam)",
        OriginCenter: "Digboi_Itabhata_DPP (Assam)",
        FacilityCity: "Digboi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10824,
        Pin: 341516,
        DispatchCenter: "Kuchmancity_PratapNagar_D (Rajasthan)",
        OriginCenter: "Kuchmancity_PratapNagar_D (Rajasthan)",
        FacilityCity: "Kuchaman",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10825,
        Pin: 802165,
        DispatchCenter: "Brahmapur_Shahpur_D (Bihar)",
        OriginCenter: "Brahmapur_Shahpur_D (Bihar)",
        FacilityCity: "Brahmapur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10826,
        Pin: 533284,
        DispatchCenter: "Addateegala_Thimapuram_D (Andhra Pradesh)",
        OriginCenter: "Addateegala_Thimapuram_D (Andhra Pradesh)",
        FacilityCity: "Addateegala",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10827,
        Pin: 385515,
        DispatchCenter: "Palanpur_Esbipura1_D (Gujarat)",
        OriginCenter: "Palanpur_Esbipura1_D (Gujarat)",
        FacilityCity: "Palanpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10828,
        Pin: 560102,
        DispatchCenter: "Bangalore_Temp1_L (Karnataka)",
        OriginCenter: "Bangalore_Temp1_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10829,
        Pin: 457226,
        DispatchCenter: "Jaora_AdityaNagar_D (Madhya Pradesh)",
        OriginCenter: "Jaora_AdityaNagar_D (Madhya Pradesh)",
        FacilityCity: "Jaora",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 10830,
        Pin: 605758,
        DispatchCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        OriginCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        FacilityCity: "Thirukoilure",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10831,
        Pin: 342024,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10832,
        Pin: 210504,
        DispatchCenter: "HamirpurUP_Maudaha_D (Uttar Pradesh)",
        OriginCenter: "HamirpurUP_Maudaha_D (Uttar Pradesh)",
        FacilityCity: "Hamirpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10833,
        Pin: 761109,
        DispatchCenter: "Badagada_Kesharipatana_DPP (Orissa)",
        OriginCenter: "Badagada_Kesharipatana_DPP (Orissa)",
        FacilityCity: "Badagada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10834,
        Pin: 523211,
        DispatchCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        OriginCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        FacilityCity: "Ongole",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10835,
        Pin: 422608,
        DispatchCenter: "Sangamner_Ksarwdi_D (Maharashtra)",
        OriginCenter: "Sangamner_Ksarwdi_D (Maharashtra)",
        FacilityCity: "Sangamner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10836,
        Pin: 690512,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10837,
        Pin: 388543,
        DispatchCenter: "Anand_Borsad_D (Gujarat)",
        OriginCenter: "Anand_Borsad_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10838,
        Pin: 783373,
        DispatchCenter: "Kokrajhar_Ghiluguri_DPP (Assam)",
        OriginCenter: "Kokrajhar_Ghiluguri_DPP (Assam)",
        FacilityCity: "Kokrajhar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10839,
        Pin: 221402,
        DispatchCenter: "Bhadohi_Vihana_D (Uttar Pradesh)",
        OriginCenter: "Bhadohi_Vihana_D (Uttar Pradesh)",
        FacilityCity: "Bhadohi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10840,
        Pin: 151505,
        DispatchCenter: "Mansa_GreenVly_D (Punjab)",
        OriginCenter: "Mansa_GreenVly_D (Punjab)",
        FacilityCity: "Mansa",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10841,
        Pin: 786623,
        DispatchCenter: "Naharkatia_JaypurNagar_D (Assam)",
        OriginCenter: "Naharkatia_JaypurNagar_D (Assam)",
        FacilityCity: "Naharkatia",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10842,
        Pin: 307510,
        DispatchCenter: "AbuRoad_MMS_D (Rajasthan)",
        OriginCenter: "AbuRoad_MMS_D (Rajasthan)",
        FacilityCity: "Abu Road",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10843,
        Pin: 534343,
        DispatchCenter: "Kovvur_Ramarao_D (Andhra Pradesh)",
        OriginCenter: "Kovvur_Ramarao_D (Andhra Pradesh)",
        FacilityCity: "Kovvur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10844,
        Pin: 521340,
        DispatchCenter: "Kaikaluru_Gonepadu_D (Andhra Pradesh)",
        OriginCenter: "Kaikaluru_Gonepadu_D (Andhra Pradesh)",
        FacilityCity: "Kaikaluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10845,
        Pin: 402308,
        DispatchCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        OriginCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        FacilityCity: "Mangaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10846,
        Pin: 281104,
        DispatchCenter: "Jalesar_MusiyarRoad_D (Uttar Pradesh)",
        OriginCenter: "Jalesar_MusiyarRoad_D (Uttar Pradesh)",
        FacilityCity: "Jalesar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10847,
        Pin: 690538,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10848,
        Pin: 688504,
        DispatchCenter: "Changanacherry_Perunna_D (Kerala)",
        OriginCenter: "Changanacherry_Perunna_D (Kerala)",
        FacilityCity: "Changanacherry",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10849,
        Pin: 271901,
        DispatchCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        OriginCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        FacilityCity: "Colonejganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10850,
        Pin: 222113,
        DispatchCenter: "Shahganj_RudhauliKalan_D (Uttar Pradesh)",
        OriginCenter: "Shahganj_RudhauliKalan_D (Uttar Pradesh)",
        FacilityCity: "Shahganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10851,
        Pin: 457112,
        DispatchCenter: "Ratlam_MhowRd_D (Madhya Pradesh)",
        OriginCenter: "Ratlam_MhowRd_D (Madhya Pradesh)",
        FacilityCity: "Ratlam",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 10852,
        Pin: 304024,
        DispatchCenter: "Nainwa_Uniyara_D (Rajasthan)",
        OriginCenter: "Nainwa_Uniyara_D (Rajasthan)",
        FacilityCity: "Nainwa",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10853,
        Pin: 391345,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10854,
        Pin: 246761,
        DispatchCenter: "Dhampur_Jaitra_D (Uttar Pradesh)",
        OriginCenter: "Dhampur_Jaitra_D (Uttar Pradesh)",
        FacilityCity: "Dhampur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10855,
        Pin: 793110,
        DispatchCenter: "Pynursla_Urksew_DPP (Meghalaya)",
        OriginCenter: "Pynursla_Urksew_DPP (Meghalaya)",
        FacilityCity: "Pynursla",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10856,
        Pin: 759025,
        DispatchCenter: "Dhenkanal_Korian_D (Orissa)",
        OriginCenter: "Dhenkanal_Korian_D (Orissa)",
        FacilityCity: "Dhenkanal",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10857,
        Pin: 171103,
        DispatchCenter: "Shimla_Dhami_DPP (Himachal Pradesh)",
        OriginCenter: "Shimla_Dhami_DPP (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10858,
        Pin: 783134,
        DispatchCenter: "Goalpara_Dudhnoi_D (Assam)",
        OriginCenter: "Goalpara_Dudhnoi_D (Assam)",
        FacilityCity: "Goalpara",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10859,
        Pin: 517505,
        DispatchCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        OriginCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        FacilityCity: "Tirupati",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10860,
        Pin: 140901,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10861,
        Pin: 769009,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10862,
        Pin: 604305,
        DispatchCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        OriginCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        FacilityCity: "Tindivanam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10863,
        Pin: 600105,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10864,
        Pin: 176090,
        DispatchCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        OriginCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10865,
        Pin: 451448,
        DispatchCenter: "Sendhwa_JawaharGanj_I (Madhya Pradesh)",
        OriginCenter: "Sendhwa_JawaharGanj_I (Madhya Pradesh)",
        FacilityCity: "Sendhwa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 10866,
        Pin: 458330,
        DispatchCenter: "Jawad_Vajihiwada_D (Madhya Pradesh)",
        OriginCenter: "Jawad_Vajihiwada_D (Madhya Pradesh)",
        FacilityCity: "Jawad",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 10867,
        Pin: 480554,
        DispatchCenter: "Junnardeo_DamuaRd_DPP (Madhya Pradesh)",
        OriginCenter: "Junnardeo_DamuaRd_DPP (Madhya Pradesh)",
        FacilityCity: "Junnardeo",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 10868,
        Pin: 361347,
        DispatchCenter: "Dwarka_CharaklaRd_DPP (Gujarat)",
        OriginCenter: "Dwarka_CharaklaRd_DPP (Gujarat)",
        FacilityCity: "Dwarka",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10869,
        Pin: 384272,
        DispatchCenter: "Patan_Hansapur_D (Gujarat)",
        OriginCenter: "Patan_Hansapur_D (Gujarat)",
        FacilityCity: "Patan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10870,
        Pin: 801106,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_PhulwariSharif_L (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10871,
        Pin: 624052,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10872,
        Pin: 522611,
        DispatchCenter: "Chilakaluripet_ChrlesChrch_DPP (Andhra Pradesh)",
        OriginCenter: "Chilakaluripet_ChrlesChrch_DPP (Andhra Pradesh)",
        FacilityCity: "Chilakaluripet",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10873,
        Pin: 788830,
        DispatchCenter: "Mahur_Maibang1_D (Assam)",
        OriginCenter: "Mahur_Maibang1_D (Assam)",
        FacilityCity: "Mahur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10874,
        Pin: 722176,
        DispatchCenter: "Bankura_Chhatna_DPP (West Bengal)",
        OriginCenter: "Bankura_Chhatna_DPP (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10875,
        Pin: 403808,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10876,
        Pin: 496554,
        DispatchCenter: "Baramkela_ChndrprRd_DPP (Chhattisgarh)",
        OriginCenter: "Baramkela_ChndrprRd_DPP (Chhattisgarh)",
        FacilityCity: "Baramkela",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10877,
        Pin: 700090,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10878,
        Pin: 414004,
        DispatchCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        OriginCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        FacilityCity: "Ahmednagar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10879,
        Pin: 680575,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10880,
        Pin: 277122,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10881,
        Pin: 784160,
        DispatchCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        OriginCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        FacilityCity: "Itanagar",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10882,
        Pin: 425003,
        DispatchCenter: "Jalgaon_MIDC_I (Maharashtra)",
        OriginCenter: "Jalgaon_MIDC_I (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10883,
        Pin: 224001,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10884,
        Pin: 802217,
        DispatchCenter: "Sasaram_Rajpur1_DPP (Bihar)",
        OriginCenter: "Sasaram_Rajpur1_DPP (Bihar)",
        FacilityCity: "Sasaram",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10885,
        Pin: 760002,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10886,
        Pin: 462005,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 10887,
        Pin: 632602,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10888,
        Pin: 782144,
        DispatchCenter: "Nagaon_Bangthai_H (Assam)",
        OriginCenter: "Nagaon_Bangthai_H (Assam)",
        FacilityCity: "Nagaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10889,
        Pin: 805105,
        DispatchCenter: "Pavapuri_Jalmandirrd_D (Bihar)",
        OriginCenter: "Pavapuri_Jalmandirrd_D (Bihar)",
        FacilityCity: "Pavapuri",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10890,
        Pin: 400208,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10891,
        Pin: 571439,
        DispatchCenter: "Hanur_Maruthinagar_D (Karnataka)",
        OriginCenter: "Hanur_Maruthinagar_D (Karnataka)",
        FacilityCity: "Hanur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10892,
        Pin: 678574,
        DispatchCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        OriginCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        FacilityCity: "Pazhayannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10893,
        Pin: 274802,
        DispatchCenter: "Khadda_Siswa_DPP (Uttar Pradesh)",
        OriginCenter: "Khadda_Siswa_DPP (Uttar Pradesh)",
        FacilityCity: "Khadda",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10894,
        Pin: 712204,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10895,
        Pin: 175133,
        DispatchCenter: "Keylong_ManaliHwy_D (Himachal Pradesh)",
        OriginCenter: "Keylong_ManaliHwy_D (Himachal Pradesh)",
        FacilityCity: "Keylong",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10896,
        Pin: 625524,
        DispatchCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        OriginCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        FacilityCity: "Cumbum",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10897,
        Pin: 364280,
        DispatchCenter: "Mahuva_Bagdana_DPP (Gujarat)",
        OriginCenter: "Mahuva_Bagdana_DPP (Gujarat)",
        FacilityCity: "Mahuva",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10898,
        Pin: 533468,
        DispatchCenter: "Draksharamam_Anaipeta_D (Andhra Pradesh)",
        OriginCenter: "Draksharamam_Anaipeta_D (Andhra Pradesh)",
        FacilityCity: "Draksharamam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10899,
        Pin: 848512,
        DispatchCenter: "Samastipur_RhmprRdli_D (Bihar)",
        OriginCenter: "Samastipur_RhmprRdli_D (Bihar)",
        FacilityCity: "Samastipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10900,
        Pin: 140119,
        DispatchCenter: "Rupnagar_Towercolony_D (Punjab)",
        OriginCenter: "Rupnagar_Towercolony_D (Punjab)",
        FacilityCity: "Rupnagar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10901,
        Pin: 313702,
        DispatchCenter: "Jhadol_Leelawas_D (Rajasthan)",
        OriginCenter: "Jhadol_Leelawas_D (Rajasthan)",
        FacilityCity: "Jhadol",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10902,
        Pin: 243723,
        DispatchCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        OriginCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        FacilityCity: "Chandausi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10903,
        Pin: 734223,
        DispatchCenter: "Kurseong_PankhabariR_D (West Bengal)",
        OriginCenter: "Kurseong_PankhabariR_D (West Bengal)",
        FacilityCity: "Kurseong",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10904,
        Pin: 678671,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10905,
        Pin: 442102,
        DispatchCenter: "Wardha_Alodi_D (Maharashtra)",
        OriginCenter: "Wardha_Alodi_D (Maharashtra)",
        FacilityCity: "Wardha",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10906,
        Pin: 306126,
        DispatchCenter: "Sumerpur_RgukulColny_DPP (Rajasthan)",
        OriginCenter: "Sumerpur_RgukulColny_DPP (Rajasthan)",
        FacilityCity: "Sumerpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10907,
        Pin: 412001,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10908,
        Pin: 572109,
        DispatchCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10909,
        Pin: 400702,
        DispatchCenter: "Jasai_Vidhane_L (Maharashtra)",
        OriginCenter: "Jasai_Vidhane_L (Maharashtra)",
        FacilityCity: "Jasai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10910,
        Pin: 176200,
        DispatchCenter: "Palion_MndiPthankotRd_DPP (Himachal Pradesh)",
        OriginCenter: "Palion_MndiPthankotRd_DPP (Himachal Pradesh)",
        FacilityCity: "Palion",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10911,
        Pin: 786621,
        DispatchCenter: "Naharkatia_JaypurNagar_D (Assam)",
        OriginCenter: "Naharkatia_JaypurNagar_D (Assam)",
        FacilityCity: "Naharkatia",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10912,
        Pin: 685607,
        DispatchCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        OriginCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        FacilityCity: "Thodupuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10913,
        Pin: 148020,
        DispatchCenter: "Malerkotla_Sajidacolony_D (Punjab)",
        OriginCenter: "Malerkotla_Sajidacolony_D (Punjab)",
        FacilityCity: "Malerkotla",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10914,
        Pin: 413516,
        DispatchCenter: "Nilanga_Panchalcolony_D (Maharashtra)",
        OriginCenter: "Nilanga_Panchalcolony_D (Maharashtra)",
        FacilityCity: "Nilanga",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10915,
        Pin: 813225,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10916,
        Pin: 843316,
        DispatchCenter: "Runnisaidpur_Madhvnagar_D (Bihar)",
        OriginCenter: "Runnisaidpur_Madhvnagar_D (Bihar)",
        FacilityCity: "Runnisaidpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10917,
        Pin: 843123,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10918,
        Pin: 480111,
        DispatchCenter: "Chhindwara_Chand_D (Madhya Pradesh)",
        OriginCenter: "Chhindwara_Chand_D (Madhya Pradesh)",
        FacilityCity: "Chhindwara",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 10919,
        Pin: 462024,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 10920,
        Pin: 670693,
        DispatchCenter: "Thalassery_Panoor_D (Kerala)",
        OriginCenter: "Thalassery_Panoor_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10921,
        Pin: 364470,
        DispatchCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        OriginCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10922,
        Pin: 686517,
        DispatchCenter: "Kottayam_Mulakunnath_D (Kerala)",
        OriginCenter: "Kottayam_Mulakunnath_D (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10923,
        Pin: 391810,
        DispatchCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        OriginCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        FacilityCity: "Jambusar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10924,
        Pin: 611112,
        DispatchCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        OriginCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        FacilityCity: "Nagapattinam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10925,
        Pin: 431008,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10926,
        Pin: 173217,
        DispatchCenter: "Shimla_Chail_DPP (Himachal Pradesh)",
        OriginCenter: "Shimla_Chail_DPP (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10927,
        Pin: 742171,
        DispatchCenter: "Murshidabad_Jiaganj_D (West Bengal)",
        OriginCenter: "Murshidabad_Jiaganj_D (West Bengal)",
        FacilityCity: "Murshidabad",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10928,
        Pin: 643238,
        DispatchCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        OriginCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10929,
        Pin: 722158,
        DispatchCenter: "Meija_School_D (West Bengal)",
        OriginCenter: "Meija_School_D (West Bengal)",
        FacilityCity: "Mejia",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10930,
        Pin: 574227,
        DispatchCenter: "Moodbidri_Central_D (Karnataka)",
        OriginCenter: "Moodbidri_Central_D (Karnataka)",
        FacilityCity: "Moodabidri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10931,
        Pin: 382428,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10932,
        Pin: 828119,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10933,
        Pin: 835215,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10934,
        Pin: 695411,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10935,
        Pin: 841223,
        DispatchCenter: "Ekma_Hansrajpr_D (Bihar)",
        OriginCenter: "Ekma_Hansrajpr_D (Bihar)",
        FacilityCity: "Ekma",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10936,
        Pin: 676313,
        DispatchCenter: "Tirur_Talakkad_D (Kerala)",
        OriginCenter: "Tirur_Talakkad_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10937,
        Pin: 743301,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10938,
        Pin: 209206,
        DispatchCenter: "Ghatampur_StatinRD_D (Uttar Pradesh)",
        OriginCenter: "Ghatampur_StatinRD_D (Uttar Pradesh)",
        FacilityCity: "Ghatampur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10939,
        Pin: 784002,
        DispatchCenter: "Tezpur_Dipota_H (Assam)",
        OriginCenter: "Tezpur_Dipota_H (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10940,
        Pin: 411053,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10941,
        Pin: 613205,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10942,
        Pin: 534313,
        DispatchCenter: "Gopalapuram_Koyyalgudem_D (Andhra Pradesh)",
        OriginCenter: "Gopalapuram_Koyyalgudem_D (Andhra Pradesh)",
        FacilityCity: "Gopalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10943,
        Pin: 603308,
        DispatchCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        OriginCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        FacilityCity: "Chengalpattu",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10944,
        Pin: 401303,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10945,
        Pin: 571234,
        DispatchCenter: "Kushalnagar_BapujiExtension_D (Karnataka)",
        OriginCenter: "Kushalnagar_BapujiExtension_D (Karnataka)",
        FacilityCity: "Kushalnagar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10946,
        Pin: 334201,
        DispatchCenter: "Bikaner_Deshnok_D (Rajasthan)",
        OriginCenter: "Bikaner_Deshnok_D (Rajasthan)",
        FacilityCity: "Bikaner",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10947,
        Pin: 721167,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10948,
        Pin: 243726,
        DispatchCenter: "Aonla_KdidmCLY_D (Uttar Pradesh)",
        OriginCenter: "Aonla_KdidmCLY_D (Uttar Pradesh)",
        FacilityCity: "Aonla",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10949,
        Pin: 581322,
        DispatchCenter: "Siddapur_Kargal_D (Karnataka)",
        OriginCenter: "Siddapur_Kargal_D (Karnataka)",
        FacilityCity: "Siddapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10950,
        Pin: 584128,
        DispatchCenter: "Sindhanur_JanathaGunj_D (Karnataka)",
        OriginCenter: "Sindhanur_JanathaGunj_D (Karnataka)",
        FacilityCity: "Sindhanur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10951,
        Pin: 364510,
        DispatchCenter: "Mahuva_Bagdana_DPP (Gujarat)",
        OriginCenter: "Mahuva_Bagdana_DPP (Gujarat)",
        FacilityCity: "Mahuva",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10952,
        Pin: 282007,
        DispatchCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        OriginCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        FacilityCity: "Agra",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10953,
        Pin: 784523,
        DispatchCenter: "Udalguri_Ramjanakichowk_D (Assam)",
        OriginCenter: "Udalguri_Ramjanakichowk_D (Assam)",
        FacilityCity: "Udalguri",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10954,
        Pin: 781329,
        DispatchCenter: "Tihu_Jalahgaon_D (Assam)",
        OriginCenter: "Tihu_Jalahgaon_D (Assam)",
        FacilityCity: "Tihu",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10955,
        Pin: 802203,
        DispatchCenter: "Sandesh_Agiaon_D (Bihar)",
        OriginCenter: "Sandesh_Agiaon_D (Bihar)",
        FacilityCity: "Sandesh",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10956,
        Pin: 415519,
        DispatchCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        OriginCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        FacilityCity: "Satara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10957,
        Pin: 209862,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10958,
        Pin: 691509,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10959,
        Pin: 414105,
        DispatchCenter: "Newasa_Khurd_D (Maharashtra)",
        OriginCenter: "Newasa_Khurd_D (Maharashtra)",
        FacilityCity: "Newasa",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 10960,
        Pin: 630102,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10961,
        Pin: 231205,
        DispatchCenter: "Chopan_Lokocly_DPP (Uttar Pradesh)",
        OriginCenter: "Chopan_Lokocly_DPP (Uttar Pradesh)",
        FacilityCity: "Chopan",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10962,
        Pin: 679301,
        DispatchCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        OriginCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        FacilityCity: "Pazhayannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10963,
        Pin: 811101,
        DispatchCenter: "Barbigha_Khetalpura_D (Bihar)",
        OriginCenter: "Barbigha_Khetalpura_D (Bihar)",
        FacilityCity: "Barbigha",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10964,
        Pin: 244415,
        DispatchCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        OriginCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        FacilityCity: "Chandausi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10965,
        Pin: 713380,
        DispatchCenter: "Nanoor_Central_DPP_1 (West Bengal)",
        OriginCenter: "Nanoor_Central_DPP_1 (West Bengal)",
        FacilityCity: "Nanoor",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10966,
        Pin: 480003,
        DispatchCenter: "Chhindwara_Linga_I (Madhya Pradesh)",
        OriginCenter: "Chhindwara_Linga_I (Madhya Pradesh)",
        FacilityCity: "Chhindwara",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 10967,
        Pin: 690547,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10968,
        Pin: 274149,
        DispatchCenter: "Kaptanganj_Subshngr_D (Uttar Pradesh)",
        OriginCenter: "Kaptanganj_Subshngr_D (Uttar Pradesh)",
        FacilityCity: "Kaptanganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 10969,
        Pin: 847238,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10970,
        Pin: 637208,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10971,
        Pin: 396209,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10972,
        Pin: 396424,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 10973,
        Pin: 670337,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10974,
        Pin: 803109,
        DispatchCenter: "Pavapuri_Jalmandirrd_D (Bihar)",
        OriginCenter: "Pavapuri_Jalmandirrd_D (Bihar)",
        FacilityCity: "Pavapuri",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10975,
        Pin: 755011,
        DispatchCenter: "Mangalpur_GayatriColony_D (Orissa)",
        OriginCenter: "Mangalpur_GayatriColony_D (Orissa)",
        FacilityCity: "Mangalpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 10976,
        Pin: 670646,
        DispatchCenter: "Mananthavady_Central_D_1 (Kerala)",
        OriginCenter: "Mananthavady_Central_D_1 (Kerala)",
        FacilityCity: "Mananthavadi",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10977,
        Pin: 382716,
        DispatchCenter: "Kadi_Jadavpura_L (Gujarat)",
        OriginCenter: "Kadi_Jadavpura_L (Gujarat)",
        FacilityCity: "Kadi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10978,
        Pin: 695022,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10979,
        Pin: 485112,
        DispatchCenter: "Satna_Nazirabad_I (Madhya Pradesh)",
        OriginCenter: "Satna_Nazirabad_I (Madhya Pradesh)",
        FacilityCity: "Satna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 10980,
        Pin: 201013,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10981,
        Pin: 134151,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 10982,
        Pin: 110028,
        DispatchCenter: "Delhi_KirtiNagar_L (Delhi)",
        OriginCenter: "Delhi_KirtiNagar_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10983,
        Pin: 370115,
        DispatchCenter: "Bhachau_Mandivichowk_D (Gujarat)",
        OriginCenter: "Bhachau_Mandivichowk_D (Gujarat)",
        FacilityCity: "Bhachau",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 10984,
        Pin: 685539,
        DispatchCenter: "Kumily_Attappallam_D (Kerala)",
        OriginCenter: "Kumily_Attappallam_D (Kerala)",
        FacilityCity: "Kumily",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 10985,
        Pin: 533102,
        DispatchCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        OriginCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        FacilityCity: "Rajahmundry",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10986,
        Pin: 561210,
        DispatchCenter: "Gauribidanur_Srinagara_D (Karnataka)",
        OriginCenter: "Gauribidanur_Srinagara_D (Karnataka)",
        FacilityCity: "Gauribidanur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10987,
        Pin: 152121,
        DispatchCenter: "Fazilka_Malkana_D (Punjab)",
        OriginCenter: "Fazilka_Malkana_D (Punjab)",
        FacilityCity: "Fazilka",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 10988,
        Pin: 518221,
        DispatchCenter: "Dhone_Autonagar_D (Andhra Pradesh)",
        OriginCenter: "Dhone_Autonagar_D (Andhra Pradesh)",
        FacilityCity: "Dhone",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10989,
        Pin: 678623,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 10990,
        Pin: 712415,
        DispatchCenter: "Khanakul_Antngr_D (West Bengal)",
        OriginCenter: "Khanakul_Antngr_D (West Bengal)",
        FacilityCity: "Khanakul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 10991,
        Pin: 751011,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 10992,
        Pin: 332716,
        DispatchCenter: "Neemkathana_Chhawani_D (Rajasthan)",
        OriginCenter: "Neemkathana_Chhawani_D (Rajasthan)",
        FacilityCity: "Neem ka Thana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10993,
        Pin: 521212,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10994,
        Pin: 444706,
        DispatchCenter: "Daryapur_WakodDPP_D (Maharashtra)",
        OriginCenter: "Daryapur_WakodDPP_D (Maharashtra)",
        FacilityCity: "Daryapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 10995,
        Pin: 621207,
        DispatchCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        OriginCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        FacilityCity: "Kulithalai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 10996,
        Pin: 575152,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 10997,
        Pin: 313906,
        DispatchCenter: "Jhadol_Leelawas_D (Rajasthan)",
        OriginCenter: "Jhadol_Leelawas_D (Rajasthan)",
        FacilityCity: "Jhadol",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 10998,
        Pin: 825421,
        DispatchCenter: "Domchanch_Jhumritelaiya_D (Jharkhand)",
        OriginCenter: "Domchanch_Jhumritelaiya_D (Jharkhand)",
        FacilityCity: "Domchanch",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 10999,
        Pin: 600082,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11000,
        Pin: 516380,
        DispatchCenter: "Tadipatri_Kondapuram_D (Andhra Pradesh)",
        OriginCenter: "Tadipatri_Kondapuram_D (Andhra Pradesh)",
        FacilityCity: "Tadipatri",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11001,
        Pin: 577226,
        DispatchCenter: "Thirthahalli_Bejjavali2_DPP (Karnataka)",
        OriginCenter: "Thirthahalli_Bejjavali2_DPP (Karnataka)",
        FacilityCity: "Thirthahalli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11002,
        Pin: 212306,
        DispatchCenter: "Koraon_AmbedkarNagar_D (Uttar Pradesh)",
        OriginCenter: "Koraon_AmbedkarNagar_D (Uttar Pradesh)",
        FacilityCity: "Koraon",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11003,
        Pin: 713167,
        DispatchCenter: "Guskhara_DignagarRd_D (West Bengal)",
        OriginCenter: "Guskhara_DignagarRd_D (West Bengal)",
        FacilityCity: "Guskhara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11004,
        Pin: 629166,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11005,
        Pin: 144602,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11006,
        Pin: 686663,
        DispatchCenter: "Kanjiramattom_Piravom_D (Kerala)",
        OriginCenter: "Kanjiramattom_Piravom_D (Kerala)",
        FacilityCity: "Kanjiramattom",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11007,
        Pin: 743294,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11008,
        Pin: 713428,
        DispatchCenter: "Guskhara_DignagarRd_D (West Bengal)",
        OriginCenter: "Guskhara_DignagarRd_D (West Bengal)",
        FacilityCity: "Guskhara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11009,
        Pin: 787056,
        DispatchCenter: "Gogamukh_Rupahi_D (Assam)",
        OriginCenter: "Gogamukh_Rupahi_D (Assam)",
        FacilityCity: "Gogamukh",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11010,
        Pin: 679305,
        DispatchCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        OriginCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        FacilityCity: "Sankaramangalam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11011,
        Pin: 673316,
        DispatchCenter: "Mahe_Chokli_D (Kerala)",
        OriginCenter: "Mahe_Chokli_D (Kerala)",
        FacilityCity: "Mahe",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11012,
        Pin: 712245,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11013,
        Pin: 835207,
        DispatchCenter: "Gumla_Deepnagar_D (Jharkhand)",
        OriginCenter: "Gumla_Deepnagar_D (Jharkhand)",
        FacilityCity: "Gumla",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11014,
        Pin: 321405,
        DispatchCenter: "Bayana_Shivcolony_D (Rajasthan)",
        OriginCenter: "Bayana_Shivcolony_D (Rajasthan)",
        FacilityCity: "Bayana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11015,
        Pin: 735218,
        DispatchCenter: "Jalpaiguri_Lataguri_DPP (West Bengal)",
        OriginCenter: "Jalpaiguri_Lataguri_DPP (West Bengal)",
        FacilityCity: "Jalpaiguri",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11016,
        Pin: 365545,
        DispatchCenter: "Rajula_Anandnagar_D (Gujarat)",
        OriginCenter: "Rajula_Anandnagar_D (Gujarat)",
        FacilityCity: "Rajula",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11017,
        Pin: 743398,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11018,
        Pin: 176094,
        DispatchCenter: "Palampur_Saulbanehr_DPP (Himachal Pradesh)",
        OriginCenter: "Palampur_Saulbanehr_DPP (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11019,
        Pin: 571476,
        DispatchCenter: "Maddur_Malavalli_D (Karnataka)",
        OriginCenter: "Maddur_Malavalli_D (Karnataka)",
        FacilityCity: "Maddur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11020,
        Pin: 181141,
        DispatchCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11021,
        Pin: 517401,
        DispatchCenter: "Venkatagirikota_Mulbaal_D (Andhra Pradesh)",
        OriginCenter: "Venkatagirikota_Mulbaal_D (Andhra Pradesh)",
        FacilityCity: "Venkatagirikota",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11022,
        Pin: 462026,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 11023,
        Pin: 643225,
        DispatchCenter: "Gudalur_Devarshola_DPP (Tamil Nadu)",
        OriginCenter: "Gudalur_Devarshola_DPP (Tamil Nadu)",
        FacilityCity: "Gudalur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11024,
        Pin: 848201,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11025,
        Pin: 636009,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11026,
        Pin: 122214,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11027,
        Pin: 277201,
        DispatchCenter: "Bairia_Bhagwanpur_DPP (Uttar Pradesh)",
        OriginCenter: "Bairia_Bhagwanpur_DPP (Uttar Pradesh)",
        FacilityCity: "Bariya",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11028,
        Pin: 721406,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11029,
        Pin: 380059,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11030,
        Pin: 793109,
        DispatchCenter: "Pynursla_Dawki_DPP (Meghalaya)",
        OriginCenter: "Pynursla_Dawki_DPP (Meghalaya)",
        FacilityCity: "Pynursla",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11031,
        Pin: 585109,
        DispatchCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        OriginCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        FacilityCity: "Gulbarga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11032,
        Pin: 734213,
        DispatchCenter: "TistaBazar_TeestaBridge_DPP (West Bengal)",
        OriginCenter: "TistaBazar_TeestaBridge_DPP (West Bengal)",
        FacilityCity: "Tista Bazar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11033,
        Pin: 721641,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11034,
        Pin: 122213,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11035,
        Pin: 761029,
        DispatchCenter: "Kodala_Beguniapada_DPP (Orissa)",
        OriginCenter: "Kodala_Beguniapada_DPP (Orissa)",
        FacilityCity: "Kodala",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11036,
        Pin: 517520,
        DispatchCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        OriginCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        FacilityCity: "Tirupati",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11037,
        Pin: 532405,
        DispatchCenter: "Srikakulam_Amadavalasa_DPP (Andhra Pradesh)",
        OriginCenter: "Srikakulam_Amadavalasa_DPP (Andhra Pradesh)",
        FacilityCity: "Srikakulam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11038,
        Pin: 515556,
        DispatchCenter: "Kadiri_NagireddyPalli_D (Andhra Pradesh)",
        OriginCenter: "Kadiri_NagireddyPalli_D (Andhra Pradesh)",
        FacilityCity: "Kadiri",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11039,
        Pin: 577514,
        DispatchCenter: "Davangere_Anagodu_D (Karnataka)",
        OriginCenter: "Davangere_Anagodu_D (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11040,
        Pin: 678553,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11041,
        Pin: 491661,
        DispatchCenter: "Rajnandgaon_Lakholi_D (Chhattisgarh)",
        OriginCenter: "Rajnandgaon_Lakholi_D (Chhattisgarh)",
        FacilityCity: "Rajnandgaon",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11042,
        Pin: 202524,
        DispatchCenter: "Sahaswan_Mainmarket_D (Uttar Pradesh)",
        OriginCenter: "Sahaswan_Mainmarket_D (Uttar Pradesh)",
        FacilityCity: "Sahaswan",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11043,
        Pin: 562164,
        DispatchCenter: "Chikkaballapura_WardNo6_D (Karnataka)",
        OriginCenter: "Chikkaballapura_WardNo6_D (Karnataka)",
        FacilityCity: "Chikkaballapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11044,
        Pin: 847228,
        DispatchCenter: "Basopatti_Jainagar_D (Bihar)",
        OriginCenter: "Basopatti_Jainagar_D (Bihar)",
        FacilityCity: "Basopatti",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11045,
        Pin: 226008,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11046,
        Pin: 388122,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11047,
        Pin: 845105,
        DispatchCenter: "Bagaha_Gandhinagar_D (Bihar)",
        OriginCenter: "Bagaha_Gandhinagar_D (Bihar)",
        FacilityCity: "Bagaha",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11048,
        Pin: 689512,
        DispatchCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        OriginCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11049,
        Pin: 176063,
        DispatchCenter: "Baijnath_Paprola_DPP (Himachal Pradesh)",
        OriginCenter: "Baijnath_Paprola_DPP (Himachal Pradesh)",
        FacilityCity: "Baijnath",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11050,
        Pin: 534198,
        DispatchCenter: "Bhimavaram_Saripalle_DPP (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Saripalle_DPP (Andhra Pradesh)",
        FacilityCity: "Ganapavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11051,
        Pin: 384335,
        DispatchCenter: "Vadnagar_BsstdDPP_D (Gujarat)",
        OriginCenter: "Vadnagar_BsstdDPP_D (Gujarat)",
        FacilityCity: "Vadnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11052,
        Pin: 713157,
        DispatchCenter: "Kalna_BsStdDPP_D (West Bengal)",
        OriginCenter: "Kalna_BsStdDPP_D (West Bengal)",
        FacilityCity: "Kalna",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11053,
        Pin: 305601,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11054,
        Pin: 801501,
        DispatchCenter: "Bihta_Naubatpur_D (Bihar)",
        OriginCenter: "Bihta_Naubatpur_D (Bihar)",
        FacilityCity: "Bihta",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11055,
        Pin: 424106,
        DispatchCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        OriginCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        FacilityCity: "Dhule",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11056,
        Pin: 784184,
        DispatchCenter: "Biswanath_CharialiLIC_D (Assam)",
        OriginCenter: "Biswanath_CharialiLIC_D (Assam)",
        FacilityCity: "BiswanathCH",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11057,
        Pin: 521185,
        DispatchCenter: "Nandigama_Mukkapatinagar_D (Andhra Pradesh)",
        OriginCenter: "Nandigama_Mukkapatinagar_D (Andhra Pradesh)",
        FacilityCity: "Nandigama",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11058,
        Pin: 415801,
        DispatchCenter: "Lanja_GondeSakhalRd_D (Maharashtra)",
        OriginCenter: "Lanja_GondeSakhalRd_D (Maharashtra)",
        FacilityCity: "Lanja",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11059,
        Pin: 762100,
        DispatchCenter: "GUdayagiri_ClubSahi_DPP (Orissa)",
        OriginCenter: "GUdayagiri_ClubSahi_DPP (Orissa)",
        FacilityCity: "G. Udayagiri",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11060,
        Pin: 415605,
        DispatchCenter: "Chiplun_Burumtali_D (Maharashtra)",
        OriginCenter: "Chiplun_Burumtali_D (Maharashtra)",
        FacilityCity: "Chiplun",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11061,
        Pin: 403803,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11062,
        Pin: 175023,
        DispatchCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        OriginCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        FacilityCity: "Mandi",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11063,
        Pin: 508374,
        DispatchCenter: "Madgulapally_MROOffice_DPP (Telangana)",
        OriginCenter: "Madgulapally_MROOffice_DPP (Telangana)",
        FacilityCity: "Madgulapally",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11064,
        Pin: 413117,
        DispatchCenter: "Daund_Kurkumbh_L (Maharashtra)",
        OriginCenter: "Daund_Kurkumbh_L (Maharashtra)",
        FacilityCity: "Daund",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11065,
        Pin: 110067,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11066,
        Pin: 272131,
        DispatchCenter: "Basti_Maharajganj_D (Uttar Pradesh)",
        OriginCenter: "Basti_Maharajganj_D (Uttar Pradesh)",
        FacilityCity: "Itava",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11067,
        Pin: 673574,
        DispatchCenter: "Thamarassery_Raoth_D (Kerala)",
        OriginCenter: "Thamarassery_Raoth_D (Kerala)",
        FacilityCity: "Thamarassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11068,
        Pin: 416113,
        DispatchCenter: "Shirala_PostOffice_DPP (Maharashtra)",
        OriginCenter: "Shirala_PostOffice_DPP (Maharashtra)",
        FacilityCity: "Shirala",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11069,
        Pin: 382210,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11070,
        Pin: 676557,
        DispatchCenter: "Valanchery_Central_D_1 (Kerala)",
        OriginCenter: "Valanchery_Central_D_1 (Kerala)",
        FacilityCity: "Valanchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11071,
        Pin: 122055,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11072,
        Pin: 276128,
        DispatchCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11073,
        Pin: 523155,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11074,
        Pin: 321404,
        DispatchCenter: "Rupbas_GangaColony_DPP (Rajasthan)",
        OriginCenter: "Rupbas_GangaColony_DPP (Rajasthan)",
        FacilityCity: "Rupbas",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11075,
        Pin: 274604,
        DispatchCenter: "Barhaj_Nandnaward_D (Uttar Pradesh)",
        OriginCenter: "Barhaj_Nandnaward_D (Uttar Pradesh)",
        FacilityCity: "Barhaj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11076,
        Pin: 574160,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11077,
        Pin: 622104,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11078,
        Pin: 679124,
        DispatchCenter: "Shoranur_Vaniyamkulam_D (Kerala)",
        OriginCenter: "Shoranur_Vaniyamkulam_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11079,
        Pin: 302024,
        DispatchCenter: "Jaipur_RingRoad_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11080,
        Pin: 174307,
        DispatchCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        OriginCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        FacilityCity: "Bangana",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11081,
        Pin: 582234,
        DispatchCenter: "Bagalkot_NavaNgr_D (Karnataka)",
        OriginCenter: "Bagalkot_NavaNgr_D (Karnataka)",
        FacilityCity: "Bagalkot",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11082,
        Pin: 620005,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11083,
        Pin: 581343,
        DispatchCenter: "Kumta_Central_DPP_2 (Karnataka)",
        OriginCenter: "Kumta_Central_DPP_2 (Karnataka)",
        FacilityCity: "Kumta",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11084,
        Pin: 721669,
        DispatchCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        OriginCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        FacilityCity: "Tamluk",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11085,
        Pin: 147002,
        DispatchCenter: "Patiala_UpkarNagar_D (Punjab)",
        OriginCenter: "Patiala_UpkarNagar_D (Punjab)",
        FacilityCity: "Patiala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11086,
        Pin: 431209,
        DispatchCenter: "Partur_Watur_D (Maharashtra)",
        OriginCenter: "Partur_Watur_D (Maharashtra)",
        FacilityCity: "Partur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11087,
        Pin: 500265,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11088,
        Pin: 207501,
        DispatchCenter: "Farrukhabad_RamBanquet_D (Uttar Pradesh)",
        OriginCenter: "Farrukhabad_RamBanquet_D (Uttar Pradesh)",
        FacilityCity: "Farrukhabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11089,
        Pin: 306602,
        DispatchCenter: "Sumerpur_Dhorawas_DPP (Rajasthan)",
        OriginCenter: "Sumerpur_Dhorawas_DPP (Rajasthan)",
        FacilityCity: "Sumerpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11090,
        Pin: 782136,
        DispatchCenter: "Kaliabor_Hatiakhowa_DPP (Assam)",
        OriginCenter: "Kaliabor_Hatiakhowa_DPP (Assam)",
        FacilityCity: "Kaliabor",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11091,
        Pin: 722209,
        DispatchCenter: "Sonamukhi_Central_DPP_1 (West Bengal)",
        OriginCenter: "Sonamukhi_Central_DPP_1 (West Bengal)",
        FacilityCity: "Sonamukhi",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11092,
        Pin: 608704,
        DispatchCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        OriginCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        FacilityCity: "Chidambaram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11093,
        Pin: 174103,
        DispatchCenter: "Baddi_Barotiwala_L (Himachal Pradesh)",
        OriginCenter: "Baddi_Barotiwala_L (Himachal Pradesh)",
        FacilityCity: "Baddi",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11094,
        Pin: 621008,
        DispatchCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        OriginCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        FacilityCity: "Thuraiyur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11095,
        Pin: 761124,
        DispatchCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        OriginCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        FacilityCity: "Bhanjanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11096,
        Pin: 175007,
        DispatchCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        OriginCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        FacilityCity: "Jahu",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11097,
        Pin: 473332,
        DispatchCenter: "Ashoknagar_Durgacolony_D (Madhya Pradesh)",
        OriginCenter: "Ashoknagar_Durgacolony_D (Madhya Pradesh)",
        FacilityCity: "Ashok Nagar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11098,
        Pin: 416704,
        DispatchCenter: "Rajapur_Sainagar_D (Maharashtra)",
        OriginCenter: "Rajapur_Sainagar_D (Maharashtra)",
        FacilityCity: "Rajapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11099,
        Pin: 520006,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11100,
        Pin: 271127,
        DispatchCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        OriginCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        FacilityCity: "Colonejganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11101,
        Pin: 122230,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11102,
        Pin: 673024,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11103,
        Pin: 400080,
        DispatchCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        OriginCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        FacilityCity: "Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11104,
        Pin: 743389,
        DispatchCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        OriginCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        FacilityCity: "Dakshin Barasat",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11105,
        Pin: 221403,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11106,
        Pin: 177045,
        DispatchCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        OriginCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        FacilityCity: "Nadaun",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11107,
        Pin: 782462,
        DispatchCenter: "Diphu_ShymColony_D (Assam)",
        OriginCenter: "Diphu_ShymColony_D (Assam)",
        FacilityCity: "Diphu",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11108,
        Pin: 431807,
        DispatchCenter: "Bhokar_Sydngr_D (Maharashtra)",
        OriginCenter: "Bhokar_Sydngr_D (Maharashtra)",
        FacilityCity: "Bhokar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11109,
        Pin: 577451,
        DispatchCenter: "Shimoga_Eshwariah_I (Karnataka)",
        OriginCenter: "Shimoga_Eshwariah_I (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11110,
        Pin: 763004,
        DispatchCenter: "Semiliguda_HimgiriNagar_D (Orissa)",
        OriginCenter: "Semiliguda_HimgiriNagar_D (Orissa)",
        FacilityCity: "Semiliguda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11111,
        Pin: 173104,
        DispatchCenter: "Haripurdhar_Bhallona_D (Himachal Pradesh)",
        OriginCenter: "Haripurdhar_Bhallona_D (Himachal Pradesh)",
        FacilityCity: "Haripurdhar",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11112,
        Pin: 695608,
        DispatchCenter: "Pangodu_Bharathanoor_D (Kerala)",
        OriginCenter: "Pangodu_Bharathanoor_D (Kerala)",
        FacilityCity: "Pangodu",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11113,
        Pin: 230404,
        DispatchCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        OriginCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        FacilityCity: "Pratapgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11114,
        Pin: 204211,
        DispatchCenter: "SikandraRao_RadhaNagar_D (Uttar Pradesh)",
        OriginCenter: "SikandraRao_RadhaNagar_D (Uttar Pradesh)",
        FacilityCity: "Sikandra Rao",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11115,
        Pin: 515581,
        DispatchCenter: "Vempalli_Kathaluru_D (Andhra Pradesh)",
        OriginCenter: "Vempalli_Kathaluru_D (Andhra Pradesh)",
        FacilityCity: "Vempalli",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11116,
        Pin: 361002,
        DispatchCenter: "Jamnagar_Chela_L (Gujarat)",
        OriginCenter: "Jamnagar_Chela_L (Gujarat)",
        FacilityCity: "Jamnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11117,
        Pin: 577524,
        DispatchCenter: "Chitradurga_Basaveshwar_D (Karnataka)",
        OriginCenter: "Chitradurga_Basaveshwar_D (Karnataka)",
        FacilityCity: "Chitradurga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11118,
        Pin: 362530,
        DispatchCenter: "Girgadhada_Mamlatdr_DPP (Gujarat)",
        OriginCenter: "Girgadhada_Mamlatdr_DPP (Gujarat)",
        FacilityCity: "Girgadhada",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11119,
        Pin: 507122,
        DispatchCenter: "Yellandu_Sudimala_D (Telangana)",
        OriginCenter: "Yellandu_Sudimala_D (Telangana)",
        FacilityCity: "Yellandu",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11120,
        Pin: 712311,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11121,
        Pin: 345024,
        DispatchCenter: "Phalsund_Kajoi_D (Rajasthan)",
        OriginCenter: "Phalsund_Kajoi_D (Rajasthan)",
        FacilityCity: "Phalsund",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11122,
        Pin: 712702,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11123,
        Pin: 146105,
        DispatchCenter: "Garhshankar_BangaRoad_D (Punjab)",
        OriginCenter: "Garhshankar_BangaRoad_D (Punjab)",
        FacilityCity: "Garhshanker",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11124,
        Pin: 144421,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11125,
        Pin: 363320,
        DispatchCenter: "Dhrangadhra_Jogasar_DPP (Gujarat)",
        OriginCenter: "Dhrangadhra_Jogasar_DPP (Gujarat)",
        FacilityCity: "Dhrangadhra",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11126,
        Pin: 627954,
        DispatchCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        OriginCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        FacilityCity: "Sankarankovil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11127,
        Pin: 585287,
        DispatchCenter: "Shahapur_LxmiNgr_D (Karnataka)",
        OriginCenter: "Shahapur_LxmiNgr_D (Karnataka)",
        FacilityCity: "Shahapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11128,
        Pin: 625108,
        DispatchCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        OriginCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        FacilityCity: "Melur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11129,
        Pin: 110104,
        DispatchCenter: "Delhi_RailYardRd_L (Delhi)",
        OriginCenter: "Delhi_RailYardRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11130,
        Pin: 523356,
        DispatchCenter: "Giddalur_Nandyalroad_D (Andhra Pradesh)",
        OriginCenter: "Giddalur_Nandyalroad_D (Andhra Pradesh)",
        FacilityCity: "Giddalur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11131,
        Pin: 334021,
        DispatchCenter: "Gharsana_Jaatcolony_D (Rajasthan)",
        OriginCenter: "Gharsana_Jaatcolony_D (Rajasthan)",
        FacilityCity: "Gharsana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11132,
        Pin: 227202,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11133,
        Pin: 583204,
        DispatchCenter: "Hospet_Vijayanagara_D (Karnataka)",
        OriginCenter: "Hospet_Vijayanagara_D (Karnataka)",
        FacilityCity: "Hospet",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11134,
        Pin: 769043,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11135,
        Pin: 680623,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11136,
        Pin: 110103,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11137,
        Pin: 721434,
        DispatchCenter: "Contai_Egra_D (West Bengal)",
        OriginCenter: "Contai_Egra_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11138,
        Pin: 628703,
        DispatchCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        OriginCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        FacilityCity: "Thisayanvilai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11139,
        Pin: 533237,
        DispatchCenter: "Mandapeta_Ravulapalem_DPP (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Ravulapalem_DPP (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11140,
        Pin: 480990,
        DispatchCenter: "Seoni_Dalsagar_D (Madhya Pradesh)",
        OriginCenter: "Seoni_Dalsagar_D (Madhya Pradesh)",
        FacilityCity: "Seoni",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11141,
        Pin: 831004,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11142,
        Pin: 788115,
        DispatchCenter: "Sonabarighat_Saidpur_DPP (Assam)",
        OriginCenter: "Sonabarighat_Saidpur_DPP (Assam)",
        FacilityCity: "Sonabarighat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11143,
        Pin: 798620,
        DispatchCenter: "Zunheboto_DCHillColony_D (Nagaland)",
        OriginCenter: "Zunheboto_DCHillColony_D (Nagaland)",
        FacilityCity: "Zunheboto",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11144,
        Pin: 455115,
        DispatchCenter: "Dewas_TriweniNagar_D (Madhya Pradesh)",
        OriginCenter: "Dewas_TriweniNagar_D (Madhya Pradesh)",
        FacilityCity: "Dewas",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11145,
        Pin: 328041,
        DispatchCenter: "Dholpur_KamlaColony_D (Rajasthan)",
        OriginCenter: "Dholpur_KamlaColony_D (Rajasthan)",
        FacilityCity: "Dholpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11146,
        Pin: 416540,
        DispatchCenter: "Sangli_Dattanagar_D (Maharashtra)",
        OriginCenter: "Sangli_Dattanagar_D (Maharashtra)",
        FacilityCity: "Sangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11147,
        Pin: 670603,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11148,
        Pin: 581321,
        DispatchCenter: "Ankola_Baleguli_D (Karnataka)",
        OriginCenter: "Ankola_Baleguli_D (Karnataka)",
        FacilityCity: "Ankola",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11149,
        Pin: 224157,
        DispatchCenter: "Faizabad_MayaBazar_D (Uttar Pradesh)",
        OriginCenter: "Faizabad_MayaBazar_D (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11150,
        Pin: 560059,
        DispatchCenter: "Bangalore_FCILayout_L (Karnataka)",
        OriginCenter: "Bangalore_FCILayout_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11151,
        Pin: 762013,
        DispatchCenter: "Puranakatak_Jajpur_DPP (Orissa)",
        OriginCenter: "Puranakatak_Jajpur_DPP (Orissa)",
        FacilityCity: "Puranakatak",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11152,
        Pin: 131306,
        DispatchCenter: "Gohana_Adarshnagar_D (Haryana)",
        OriginCenter: "Gohana_Adarshnagar_D (Haryana)",
        FacilityCity: "Gohana",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11153,
        Pin: 416409,
        DispatchCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        OriginCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        FacilityCity: "Miraj",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11154,
        Pin: 756009,
        DispatchCenter: "Balasore_Ganeswarpur_D (Orissa)",
        OriginCenter: "Balasore_Ganeswarpur_D (Orissa)",
        FacilityCity: "Balasore",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11155,
        Pin: 673647,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11156,
        Pin: 415213,
        DispatchCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        OriginCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        FacilityCity: "Mahad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11157,
        Pin: 508279,
        DispatchCenter: "Tungaturthi_CanaraBank_D (Telangana)",
        OriginCenter: "Tungaturthi_CanaraBank_D (Telangana)",
        FacilityCity: "Tungaturthi",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11158,
        Pin: 683104,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11159,
        Pin: 175126,
        DispatchCenter: "Kullu_Mohal_D (Himachal Pradesh)",
        OriginCenter: "Kullu_Mohal_D (Himachal Pradesh)",
        FacilityCity: "Kullu",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11160,
        Pin: 224188,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11161,
        Pin: 518432,
        DispatchCenter: "Nandikotkur_Nandyalroad_D (Andhra Pradesh)",
        OriginCenter: "Nandikotkur_Nandyalroad_D (Andhra Pradesh)",
        FacilityCity: "Nandikotkur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11162,
        Pin: 695132,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11163,
        Pin: 621218,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11164,
        Pin: 733127,
        DispatchCenter: "Gangarampr_Ramkrishnpaly_DPP (West Bengal)",
        OriginCenter: "Gangarampr_Ramkrishnpaly_DPP (West Bengal)",
        FacilityCity: "Gangarampur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11165,
        Pin: 502270,
        DispatchCenter: "Jogipet_Andole_D (Telangana)",
        OriginCenter: "Jogipet_Andole_D (Telangana)",
        FacilityCity: "Jogipet",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11166,
        Pin: 690535,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11167,
        Pin: 759146,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11168,
        Pin: 209301,
        DispatchCenter: "Akbarpur_NationalHospital_D (Uttar Pradesh)",
        OriginCenter: "Akbarpur_NationalHospital_D (Uttar Pradesh)",
        FacilityCity: "Akbarpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11169,
        Pin: 690548,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11170,
        Pin: 389122,
        DispatchCenter: "Godhra_Soniwada_D (Gujarat)",
        OriginCenter: "Godhra_Soniwada_D (Gujarat)",
        FacilityCity: "Godhra",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11171,
        Pin: 562103,
        DispatchCenter: "Chikkaballapura_WardNo6_D (Karnataka)",
        OriginCenter: "Chikkaballapura_WardNo6_D (Karnataka)",
        FacilityCity: "Chikkaballapura",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11172,
        Pin: 284122,
        DispatchCenter: "Lalitpur_Govindnagar_D (Uttar Pradesh)",
        OriginCenter: "Lalitpur_Govindnagar_D (Uttar Pradesh)",
        FacilityCity: "Lalitpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11173,
        Pin: 491338,
        DispatchCenter: "Bemetara_Pikari_D (Chhattisgarh)",
        OriginCenter: "Bemetara_Pikari_D (Chhattisgarh)",
        FacilityCity: "Bemetara",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11174,
        Pin: 604505,
        DispatchCenter: "Vandavasi_SaltStreet_D (Tamil Nadu)",
        OriginCenter: "Vandavasi_SaltStreet_D (Tamil Nadu)",
        FacilityCity: "Vandavasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11175,
        Pin: 110014,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11176,
        Pin: 741159,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11177,
        Pin: 532522,
        DispatchCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        OriginCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        FacilityCity: "Parvathipuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11178,
        Pin: 793112,
        DispatchCenter: "Cherrapunji_Ichamati_D (Meghalaya)",
        OriginCenter: "Cherrapunji_Ichamati_D (Meghalaya)",
        FacilityCity: "Cherrapunji",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11179,
        Pin: 586120,
        DispatchCenter: "Sindagi_Bijapurrd_D (Karnataka)",
        OriginCenter: "Sindagi_Bijapurrd_D (Karnataka)",
        FacilityCity: "Sindagi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11180,
        Pin: 753010,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11181,
        Pin: 756035,
        DispatchCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        OriginCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        FacilityCity: "Dantan",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11182,
        Pin: 175014,
        DispatchCenter: "JognderNgr_Dhelu_D (Himachal Pradesh)",
        OriginCenter: "JognderNgr_Dhelu_D (Himachal Pradesh)",
        FacilityCity: "Joginder Nagar",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11183,
        Pin: 841402,
        DispatchCenter: "Marhowarah_StatinRD_D (Bihar)",
        OriginCenter: "Marhowarah_StatinRD_D (Bihar)",
        FacilityCity: "Marhowarah",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11184,
        Pin: 363030,
        DispatchCenter: "Surendranagar_GIDC_D (Gujarat)",
        OriginCenter: "Surendranagar_GIDC_D (Gujarat)",
        FacilityCity: "Surendranagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11185,
        Pin: 796186,
        DispatchCenter: "Thenzawl_Vengthlng_D (Mizoram)",
        OriginCenter: "Thenzawl_Vengthlng_D (Mizoram)",
        FacilityCity: "Thenzawl",
        FacilityState: "Mizoram",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11186,
        Pin: 679591,
        DispatchCenter: "Edappal_Nellissery_D (Kerala)",
        OriginCenter: "Edappal_Nellissery_D (Kerala)",
        FacilityCity: "Edappal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11187,
        Pin: 363031,
        DispatchCenter: "Surendranagar_GIDC_D (Gujarat)",
        OriginCenter: "Surendranagar_GIDC_D (Gujarat)",
        FacilityCity: "Surendranagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11188,
        Pin: 522414,
        DispatchCenter: "Piduguralla_Rasoolpet_D (Andhra Pradesh)",
        OriginCenter: "Piduguralla_Rasoolpet_D (Andhra Pradesh)",
        FacilityCity: "Piduguralla",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11189,
        Pin: 509001,
        DispatchCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        OriginCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        FacilityCity: "Mahabubnagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11190,
        Pin: 383030,
        DispatchCenter: "Himmatnagar_Hadiyol_D (Gujarat)",
        OriginCenter: "Himmatnagar_Hadiyol_D (Gujarat)",
        FacilityCity: "Himmatnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11191,
        Pin: 403719,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11192,
        Pin: 431009,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11193,
        Pin: 205267,
        DispatchCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        OriginCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        FacilityCity: "Mainpuri",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11194,
        Pin: 764075,
        DispatchCenter: "Pappadahandi_Jatabal_DPP (Orissa)",
        OriginCenter: "Pappadahandi_Jatabal_DPP (Orissa)",
        FacilityCity: "Pappadahandi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11195,
        Pin: 411031,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11196,
        Pin: 786151,
        DispatchCenter: "DoomDooma_Pthrgoan_D (Assam)",
        OriginCenter: "DoomDooma_Pthrgoan_D (Assam)",
        FacilityCity: "Doom Dooma",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11197,
        Pin: 721149,
        DispatchCenter: "Kharagpur_Nimpura_I (West Bengal)",
        OriginCenter: "Kharagpur_Nimpura_I (West Bengal)",
        FacilityCity: "Kharagpur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11198,
        Pin: 732122,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11199,
        Pin: 311026,
        DispatchCenter: "Badnaur_Asind_D (Rajasthan)",
        OriginCenter: "Badnaur_Asind_D (Rajasthan)",
        FacilityCity: "Asind",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11200,
        Pin: 813233,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11201,
        Pin: 574150,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11202,
        Pin: 641061,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11203,
        Pin: 495446,
        DispatchCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        OriginCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        FacilityCity: "Korba",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11204,
        Pin: 501202,
        DispatchCenter: "Mominpet_Marpallekalan_D (Telangana)",
        OriginCenter: "Mominpet_Marpallekalan_D (Telangana)",
        FacilityCity: "Mominpet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11205,
        Pin: 263645,
        DispatchCenter: "Ranikhet_KhariBazar_DPP (Uttarakhand)",
        OriginCenter: "Ranikhet_KhariBazar_DPP (Uttarakhand)",
        FacilityCity: "Ranikhet",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11206,
        Pin: 627756,
        DispatchCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        OriginCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        FacilityCity: "Sankarankovil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11207,
        Pin: 506319,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11208,
        Pin: 494228,
        DispatchCenter: "Kondagaon_DNKColony_D (Chhattisgarh)",
        OriginCenter: "Kondagaon_DNKColony_D (Chhattisgarh)",
        FacilityCity: "Kondagaon",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11209,
        Pin: 177207,
        DispatchCenter: "UnaHP_NangalRd_D (Himachal Pradesh)",
        OriginCenter: "UnaHP_NangalRd_D (Himachal Pradesh)",
        FacilityCity: "Una-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11210,
        Pin: 627428,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11211,
        Pin: 721123,
        DispatchCenter: "Midnapore_Sarbera_DPP (West Bengal)",
        OriginCenter: "Midnapore_Sarbera_DPP (West Bengal)",
        FacilityCity: "Midnapore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11212,
        Pin: 607109,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11213,
        Pin: 383205,
        DispatchCenter: "Prantij_Vavdichokdi_DPP (Gujarat)",
        OriginCenter: "Prantij_Vavdichokdi_DPP (Gujarat)",
        FacilityCity: "Prantij",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11214,
        Pin: 212501,
        DispatchCenter: "Soraon_Purabtola_D (Uttar Pradesh)",
        OriginCenter: "Soraon_Purabtola_D (Uttar Pradesh)",
        FacilityCity: "Soraon",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11215,
        Pin: 414202,
        DispatchCenter: "Ashti_Murshadpur_DPP (Maharashtra)",
        OriginCenter: "Ashti_Murshadpur_DPP (Maharashtra)",
        FacilityCity: "Ashti",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11216,
        Pin: 272129,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11217,
        Pin: 770035,
        DispatchCenter: "Birmitrapur_Sailata_D (Orissa)",
        OriginCenter: "Birmitrapur_Sailata_D (Orissa)",
        FacilityCity: "Birmitrapur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11218,
        Pin: 631601,
        DispatchCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        OriginCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        FacilityCity: "Kanchipuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11219,
        Pin: 781310,
        DispatchCenter: "Nalbari_Chokbazar_D (Assam)",
        OriginCenter: "Nalbari_Chokbazar_D (Assam)",
        FacilityCity: "Nalbari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11220,
        Pin: 831009,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11221,
        Pin: 683571,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11222,
        Pin: 673124,
        DispatchCenter: "Kalpetta_Mandayapuram_D (Kerala)",
        OriginCenter: "Kalpetta_Mandayapuram_D (Kerala)",
        FacilityCity: "Kalpetta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11223,
        Pin: 400601,
        DispatchCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11224,
        Pin: 400705,
        DispatchCenter: "Mumbai_Turbhe_L (Maharashtra)",
        OriginCenter: "Mumbai_Turbhe_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11225,
        Pin: 700024,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11226,
        Pin: 515814,
        DispatchCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        OriginCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11227,
        Pin: 211020,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        Zone : "N1"
      },
      {
        SN: 11228,
        Pin: 742135,
        DispatchCenter: "Murshidabad_Jiaganj_D (West Bengal)",
        OriginCenter: "Murshidabad_Jiaganj_D (West Bengal)",
        FacilityCity: "Murshidabad",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11229,
        Pin: 394210,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11230,
        Pin: 691326,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11231,
        Pin: 484002,
        DispatchCenter: "Shahdol_Jamui_D (Madhya Pradesh)",
        OriginCenter: "Shahdol_Jamui_D (Madhya Pradesh)",
        FacilityCity: "Shahdol",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11232,
        Pin: 723143,
        DispatchCenter: "Balarampur_Rangadih_D (West Bengal)",
        OriginCenter: "Balarampur_Rangadih_D (West Bengal)",
        FacilityCity: "Balarampur-WB",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11233,
        Pin: 814116,
        DispatchCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        OriginCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        FacilityCity: "Deoghar",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11234,
        Pin: 581206,
        DispatchCenter: "Kittur_Guruwarpeth_DPP (Karnataka)",
        OriginCenter: "Kittur_Guruwarpeth_DPP (Karnataka)",
        FacilityCity: "Kittur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11235,
        Pin: 321614,
        DispatchCenter: "Kherli_Doroli_D (Rajasthan)",
        OriginCenter: "Kherli_Doroli_D (Rajasthan)",
        FacilityCity: "Kherli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11236,
        Pin: 627355,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11237,
        Pin: 206002,
        DispatchCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        OriginCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        FacilityCity: "Etawah",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11238,
        Pin: 173030,
        DispatchCenter: "Nahan_NarulaNagar_D (Himachal Pradesh)",
        OriginCenter: "Nahan_NarulaNagar_D (Himachal Pradesh)",
        FacilityCity: "Nahan",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11239,
        Pin: 571211,
        DispatchCenter: "Virajpet_TeluguSt_D (Karnataka)",
        OriginCenter: "Virajpet_TeluguSt_D (Karnataka)",
        FacilityCity: "Virajpet",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11240,
        Pin: 632052,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11241,
        Pin: 248198,
        DispatchCenter: "Herbertpur_Dhkrni_D (Uttarakhand)",
        OriginCenter: "Herbertpur_Dhkrni_D (Uttarakhand)",
        FacilityCity: "Herbertpur",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11242,
        Pin: 535573,
        DispatchCenter: "Bobbili_Aguruvidi_D (Andhra Pradesh)",
        OriginCenter: "Bobbili_Aguruvidi_D (Andhra Pradesh)",
        FacilityCity: "Bobbili",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11243,
        Pin: 522315,
        DispatchCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        OriginCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11244,
        Pin: 508213,
        DispatchCenter: "Suryapet_Krishnanagar_D (Telangana)",
        OriginCenter: "Suryapet_Krishnanagar_D (Telangana)",
        FacilityCity: "Suryapet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11245,
        Pin: 712407,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11246,
        Pin: 522509,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11247,
        Pin: 843117,
        DispatchCenter: "Aurai_College_D (Bihar)",
        OriginCenter: "Aurai_College_D (Bihar)",
        FacilityCity: "Aurai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11248,
        Pin: 305205,
        DispatchCenter: "Nasirabad_RamlilaChowk_DPP (Rajasthan)",
        OriginCenter: "Nasirabad_RamlilaChowk_DPP (Rajasthan)",
        FacilityCity: "Nasirabad",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11249,
        Pin: 783333,
        DispatchCenter: "Tamarhat_Natabari_DPP (Assam)",
        OriginCenter: "Tamarhat_Natabari_DPP (Assam)",
        FacilityCity: "Agomani",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11250,
        Pin: 226302,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11251,
        Pin: 480102,
        DispatchCenter: "Chhindwara_Linga_I (Madhya Pradesh)",
        OriginCenter: "Chhindwara_Linga_I (Madhya Pradesh)",
        FacilityCity: "Chhindwara",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11252,
        Pin: 515402,
        DispatchCenter: "Gooty_ATPRoad_D (Andhra Pradesh)",
        OriginCenter: "Gooty_ATPRoad_D (Andhra Pradesh)",
        FacilityCity: "Gooty",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11253,
        Pin: 496331,
        DispatchCenter: "Jashpur_PuraniToli_D (Chhattisgarh)",
        OriginCenter: "Jashpur_PuraniToli_D (Chhattisgarh)",
        FacilityCity: "Jashpur Nagar",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11254,
        Pin: 815315,
        DispatchCenter: "Giridih_Kharagdiha_D (Jharkhand)",
        OriginCenter: "Giridih_Kharagdiha_D (Jharkhand)",
        FacilityCity: "Giridih",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11255,
        Pin: 313703,
        DispatchCenter: "Salumbar_Bambora_DPP (Rajasthan)",
        OriginCenter: "Salumbar_Bambora_DPP (Rajasthan)",
        FacilityCity: "Salumbar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11256,
        Pin: 783388,
        DispatchCenter: "Bongaigaon_Abhayapuri_D (Assam)",
        OriginCenter: "Bongaigaon_Abhayapuri_D (Assam)",
        FacilityCity: "Bongaigaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11257,
        Pin: 412311,
        DispatchCenter: "Pune_Fursungi_L (Maharashtra)",
        OriginCenter: "Pune_Fursungi_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11258,
        Pin: 821104,
        DispatchCenter: "Kudra_Wadar_D (Bihar)",
        OriginCenter: "Kudra_Wadar_D (Bihar)",
        FacilityCity: "Kudra",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11259,
        Pin: 140103,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11260,
        Pin: 796321,
        DispatchCenter: "Champhai_Vengsang_D (Mizoram)",
        OriginCenter: "Champhai_Vengsang_D (Mizoram)",
        FacilityCity: "Champhai",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11261,
        Pin: 627773,
        DispatchCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        OriginCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        FacilityCity: "Sankarankovil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11262,
        Pin: 743235,
        DispatchCenter: "Bongaon_SukntDPP_D (West Bengal)",
        OriginCenter: "Bongaon_SukntDPP_D (West Bengal)",
        FacilityCity: "Bongaon",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11263,
        Pin: 271307,
        DispatchCenter: "Domariyaganj_Belgari_D (Uttar Pradesh)",
        OriginCenter: "Domariyaganj_Belgari_D (Uttar Pradesh)",
        FacilityCity: "Domariyaganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11264,
        Pin: 444910,
        DispatchCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        OriginCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        FacilityCity: "Paratwada",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11265,
        Pin: 766013,
        DispatchCenter: "Jaipatna_PradhniChwk_D (Orissa)",
        OriginCenter: "Jaipatna_PradhniChwk_D (Orissa)",
        FacilityCity: "Jaipatna",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11266,
        Pin: 457661,
        DispatchCenter: "Jhabua_Mojipada_D (Madhya Pradesh)",
        OriginCenter: "Jhabua_Mojipada_D (Madhya Pradesh)",
        FacilityCity: "Jhabua",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 11267,
        Pin: 523110,
        DispatchCenter: "Pamuru_ViruvoruRd_D (Andhra Pradesh)",
        OriginCenter: "Pamuru_ViruvoruRd_D (Andhra Pradesh)",
        FacilityCity: "Pamuru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11268,
        Pin: 140110,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11269,
        Pin: 577124,
        DispatchCenter: "Kalasa_MahaveerRd_DPP (Karnataka)",
        OriginCenter: "Kalasa_MahaveerRd_DPP (Karnataka)",
        FacilityCity: "Kalasa",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11270,
        Pin: 460663,
        DispatchCenter: "Multai_Birulbzr_D (Madhya Pradesh)",
        OriginCenter: "Multai_Birulbzr_D (Madhya Pradesh)",
        FacilityCity: "Multai",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11271,
        Pin: 783127,
        DispatchCenter: "Fekamari_Hatsingimari_DPP (Assam)",
        OriginCenter: "Fekamari_Hatsingimari_DPP (Assam)",
        FacilityCity: "Fekamari",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11272,
        Pin: 431213,
        DispatchCenter: "Jalna_Yashodipnagar_D (Maharashtra)",
        OriginCenter: "Jalna_Yashodipnagar_D (Maharashtra)",
        FacilityCity: "Jalna",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11273,
        Pin: 821105,
        DispatchCenter: "Mohania_Shivpuricolony_D (Bihar)",
        OriginCenter: "Mohania_Shivpuricolony_D (Bihar)",
        FacilityCity: "Mohania",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11274,
        Pin: 321612,
        DispatchCenter: "Mahwa_Allapur_D (Rajasthan)",
        OriginCenter: "Mahwa_Allapur_D (Rajasthan)",
        FacilityCity: "Mahwa",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11275,
        Pin: 762001,
        DispatchCenter: "Phulbani_NipiditaOfc_D (Orissa)",
        OriginCenter: "Phulbani_NipiditaOfc_D (Orissa)",
        FacilityCity: "Phulbani",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11276,
        Pin: 503002,
        DispatchCenter: "Nizamabad_Sarangapur_I (Telangana)",
        OriginCenter: "Nizamabad_Sarangapur_I (Telangana)",
        FacilityCity: "Nizamabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11277,
        Pin: 415408,
        DispatchCenter: "Shirala_PostOffice_DPP (Maharashtra)",
        OriginCenter: "Shirala_PostOffice_DPP (Maharashtra)",
        FacilityCity: "Shirala",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11278,
        Pin: 721456,
        DispatchCenter: "Contai_Egra_D (West Bengal)",
        OriginCenter: "Contai_Egra_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11279,
        Pin: 743291,
        DispatchCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        OriginCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        FacilityCity: "Basirhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11280,
        Pin: 394221,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11281,
        Pin: 505174,
        DispatchCenter: "Peddapalli_Rajivnagar_D (Telangana)",
        OriginCenter: "Peddapalli_Rajivnagar_D (Telangana)",
        FacilityCity: "Peddapalli",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11282,
        Pin: 585437,
        DispatchCenter: "Basavakalyan_Morkhandi_DPP (Karnataka)",
        OriginCenter: "Basavakalyan_Morkhandi_DPP (Karnataka)",
        FacilityCity: "Basavakalyan",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11283,
        Pin: 380025,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11284,
        Pin: 416002,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11285,
        Pin: 388421,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11286,
        Pin: 500027,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11287,
        Pin: 722136,
        DispatchCenter: "Bankura_KeranDPP_D (West Bengal)",
        OriginCenter: "Bankura_KeranDPP_D (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11288,
        Pin: 413401,
        DispatchCenter: "Barshi_Indiranagar_D (Maharashtra)",
        OriginCenter: "Barshi_Indiranagar_D (Maharashtra)",
        FacilityCity: "Barshi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11289,
        Pin: 505498,
        DispatchCenter: "Huzurabad_Vivekanandanagar_D (Telangana)",
        OriginCenter: "Huzurabad_Vivekanandanagar_D (Telangana)",
        FacilityCity: "Huzurabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11290,
        Pin: 735102,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11291,
        Pin: 673632,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11292,
        Pin: 735215,
        DispatchCenter: "Jaigaon_Manglabarey_D (West Bengal)",
        OriginCenter: "Jaigaon_Manglabarey_D (West Bengal)",
        FacilityCity: "Jaigaon",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11293,
        Pin: 799001,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11294,
        Pin: 326035,
        DispatchCenter: "Khanpur_JhalawarRd_DPP (Rajasthan)",
        OriginCenter: "Khanpur_JhalawarRd_DPP (Rajasthan)",
        FacilityCity: "Khanpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11295,
        Pin: 573211,
        DispatchCenter: "Chnnryptna_MysoreRd_D (Karnataka)",
        OriginCenter: "Chnnryptna_MysoreRd_D (Karnataka)",
        FacilityCity: "Channarayapatna",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11296,
        Pin: 454660,
        DispatchCenter: "Badnagar_Teacherscolony_D (Madhya Pradesh)",
        OriginCenter: "Badnagar_Teacherscolony_D (Madhya Pradesh)",
        FacilityCity: "Badnagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 11297,
        Pin: 603127,
        DispatchCenter: "Tirukalukundram_Kalpakkam_D (Tamil Nadu)",
        OriginCenter: "Tirukalukundram_Kalpakkam_D (Tamil Nadu)",
        FacilityCity: "Tirukalukundram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11298,
        Pin: 691559,
        DispatchCenter: "Pangodu_Bharathanoor_D (Kerala)",
        OriginCenter: "Pangodu_Bharathanoor_D (Kerala)",
        FacilityCity: "Pangodu",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11299,
        Pin: 412103,
        DispatchCenter: "Baramati_Pragatinagar_D (Maharashtra)",
        OriginCenter: "Baramati_Pragatinagar_D (Maharashtra)",
        FacilityCity: "Baramati",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11300,
        Pin: 458116,
        DispatchCenter: "Manasa_WardNo12_DPP (Madhya Pradesh)",
        OriginCenter: "Manasa_WardNo12_DPP (Madhya Pradesh)",
        FacilityCity: "Manasa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11301,
        Pin: 488443,
        DispatchCenter: "Raipura_Bharwara_D (Madhya Pradesh)",
        OriginCenter: "Raipura_Bharwara_D (Madhya Pradesh)",
        FacilityCity: "Raipura",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 11302,
        Pin: 533434,
        DispatchCenter: "Samarlakota_CHC_D (Andhra Pradesh)",
        OriginCenter: "Samarlakota_CHC_D (Andhra Pradesh)",
        FacilityCity: "Samarlakota",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11303,
        Pin: 713376,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11304,
        Pin: 415209,
        DispatchCenter: "Patan_Ashram_D (Maharashtra)",
        OriginCenter: "Patan_Ashram_D (Maharashtra)",
        FacilityCity: "Patan-MH",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11305,
        Pin: 756117,
        DispatchCenter: "Dhamanagar_Temple_DPP (Orissa)",
        OriginCenter: "Dhamanagar_Temple_DPP (Orissa)",
        FacilityCity: "Dhamanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11306,
        Pin: 835209,
        DispatchCenter: "Khunti_Rehargara_DPP (Jharkhand)",
        OriginCenter: "Khunti_Rehargara_DPP (Jharkhand)",
        FacilityCity: "Khunti",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11307,
        Pin: 303339,
        DispatchCenter: "Phulera_NarenaRD_D (Rajasthan)",
        OriginCenter: "Phulera_NarenaRD_D (Rajasthan)",
        FacilityCity: "Phulera",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11308,
        Pin: 577218,
        DispatchCenter: "Davangere_Santhebennur_D (Karnataka)",
        OriginCenter: "Davangere_Santhebennur_D (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11309,
        Pin: 203141,
        DispatchCenter: "Jewar_SJRoad_D (Uttar Pradesh)",
        OriginCenter: "Jewar_SJRoad_D (Uttar Pradesh)",
        FacilityCity: "Jewar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11310,
        Pin: 813108,
        DispatchCenter: "Sultanganj_Dilgouri_D (Bihar)",
        OriginCenter: "Sultanganj_Dilgouri_D (Bihar)",
        FacilityCity: "Sultanganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11311,
        Pin: 600037,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11312,
        Pin: 814155,
        DispatchCenter: "Mahagama_Teachercolony_DPP (Jharkhand)",
        OriginCenter: "Mahagama_Teachercolony_DPP (Jharkhand)",
        FacilityCity: "Mahagama",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11313,
        Pin: 690546,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11314,
        Pin: 802154,
        DispatchCenter: "Brahmapur_Shahpur_D (Bihar)",
        OriginCenter: "Brahmapur_Shahpur_D (Bihar)",
        FacilityCity: "Brahmapur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11315,
        Pin: 854336,
        DispatchCenter: "Birpur_Basantpur_D (Bihar)",
        OriginCenter: "Birpur_Basantpur_D (Bihar)",
        FacilityCity: "Birpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11316,
        Pin: 140501,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11317,
        Pin: 122051,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11318,
        Pin: 177110,
        DispatchCenter: "Chintpurni_Bharwain_D (Himachal Pradesh)",
        OriginCenter: "Chintpurni_Bharwain_D (Himachal Pradesh)",
        FacilityCity: "Chintpurni",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11319,
        Pin: 732121,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11320,
        Pin: 160047,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11321,
        Pin: 530006,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11322,
        Pin: 505402,
        DispatchCenter: "Bejjanki_Badanki_D (Telangana)",
        OriginCenter: "Bejjanki_Badanki_D (Telangana)",
        FacilityCity: "Bejjanki",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11323,
        Pin: 173222,
        DispatchCenter: "Solan_SayriGhat_DPP (Himachal Pradesh)",
        OriginCenter: "Solan_SayriGhat_DPP (Himachal Pradesh)",
        FacilityCity: "Solan",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11324,
        Pin: 574173,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11325,
        Pin: 638402,
        DispatchCenter: "Sathyamangalam_VNSNagar_D (Tamil Nadu)",
        OriginCenter: "Sathyamangalam_VNSNagar_D (Tamil Nadu)",
        FacilityCity: "Sathyamangalam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11326,
        Pin: 314035,
        DispatchCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        OriginCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        FacilityCity: "Sagwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11327,
        Pin: 246737,
        DispatchCenter: "Chandpur_NoorpurRd_D (Uttar Pradesh)",
        OriginCenter: "Chandpur_NoorpurRd_D (Uttar Pradesh)",
        FacilityCity: "Chandpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11328,
        Pin: 303004,
        DispatchCenter: "Dausa_Madhavnagar_D (Rajasthan)",
        OriginCenter: "Dausa_Madhavnagar_D (Rajasthan)",
        FacilityCity: "Dausa",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11329,
        Pin: 211007,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11330,
        Pin: 492013,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11331,
        Pin: 813227,
        DispatchCenter: "Sultanganj_Dilgouri_D (Bihar)",
        OriginCenter: "Sultanganj_Dilgouri_D (Bihar)",
        FacilityCity: "Sultanganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11332,
        Pin: 631209,
        DispatchCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11333,
        Pin: 583268,
        DispatchCenter: "Gangavathi_CBSGanj_DPP (Karnataka)",
        OriginCenter: "Gangavathi_CBSGanj_DPP (Karnataka)",
        FacilityCity: "Gangavathi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11334,
        Pin: 276124,
        DispatchCenter: "Jiyanpur_GorkhpurRd_D (Uttar Pradesh)",
        OriginCenter: "Jiyanpur_GorkhpurRd_D (Uttar Pradesh)",
        FacilityCity: "Jiyanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11335,
        Pin: 635602,
        DispatchCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        OriginCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        FacilityCity: "Tiruppattur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11336,
        Pin: 492018,
        DispatchCenter: "Abhanpur_Gatapr_D (Chhattisgarh)",
        OriginCenter: "Abhanpur_Gatapr_D (Chhattisgarh)",
        FacilityCity: "Abhanpur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11337,
        Pin: 799008,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11338,
        Pin: 301712,
        DispatchCenter: "Khairthal_HarsouliRoad_D (Rajasthan)",
        OriginCenter: "Khairthal_HarsouliRoad_D (Rajasthan)",
        FacilityCity: "Khairthal",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11339,
        Pin: 146112,
        DispatchCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        OriginCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11340,
        Pin: 571605,
        DispatchCenter: "Krishnarajpet_JayaNagar_D (Karnataka)",
        OriginCenter: "Krishnarajpet_JayaNagar_D (Karnataka)",
        FacilityCity: "Krishnarajpet",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11341,
        Pin: 160015,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11342,
        Pin: 626127,
        DispatchCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        OriginCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        FacilityCity: "Sivakasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11343,
        Pin: 844101,
        DispatchCenter: "Patna_Chhotipahari_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11344,
        Pin: 755019,
        DispatchCenter: "Jajpur_Chandama_D (Orissa)",
        OriginCenter: "Jajpur_Chandama_D (Orissa)",
        FacilityCity: "Jajpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11345,
        Pin: 722161,
        DispatchCenter: "MirzapurWB_VidyaDPP_D (West Bengal)",
        OriginCenter: "MirzapurWB_VidyaDPP_D (West Bengal)",
        FacilityCity: "Mirzapur-WB",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11346,
        Pin: 535594,
        DispatchCenter: "Kurupam_MainRd_DPP (Andhra Pradesh)",
        OriginCenter: "Kurupam_MainRd_DPP (Andhra Pradesh)",
        FacilityCity: "Kurupam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11347,
        Pin: 689653,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11348,
        Pin: 261001,
        DispatchCenter: "Sitapur_Lachimanpur_D (Uttar Pradesh)",
        OriginCenter: "Sitapur_Lachimanpur_D (Uttar Pradesh)",
        FacilityCity: "Sitapur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11349,
        Pin: 225306,
        DispatchCenter: "FatehpurUP_PatelNagar_D (Uttar Pradesh)",
        OriginCenter: "FatehpurUP_PatelNagar_D (Uttar Pradesh)",
        FacilityCity: "Fatehpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11350,
        Pin: 422501,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11351,
        Pin: 388245,
        DispatchCenter: "Balasinor_Alnoor_DPP (Gujarat)",
        OriginCenter: "Balasinor_Alnoor_DPP (Gujarat)",
        FacilityCity: "Balasinor",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11352,
        Pin: 577214,
        DispatchCenter: "Shikaripur_Mandipet_D (Karnataka)",
        OriginCenter: "Shikaripur_Mandipet_D (Karnataka)",
        FacilityCity: "Shikaripur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11353,
        Pin: 835232,
        DispatchCenter: "Gumla_Deepnagar_D (Jharkhand)",
        OriginCenter: "Gumla_Deepnagar_D (Jharkhand)",
        FacilityCity: "Gumla",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11354,
        Pin: 273158,
        DispatchCenter: "Anandnagar_DhniDhla_DPP (Uttar Pradesh)",
        OriginCenter: "Anandnagar_DhniDhla_DPP (Uttar Pradesh)",
        FacilityCity: "Anandnagar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11355,
        Pin: 485551,
        DispatchCenter: "Nagod_Pipri_D (Madhya Pradesh)",
        OriginCenter: "Nagod_Pipri_D (Madhya Pradesh)",
        FacilityCity: "Nagod",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 11356,
        Pin: 272150,
        DispatchCenter: "Rudhauli_Mudiyar_D (Uttar Pradesh)",
        OriginCenter: "Rudhauli_Mudiyar_D (Uttar Pradesh)",
        FacilityCity: "Rudhauli",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11357,
        Pin: 732205,
        DispatchCenter: "Malda_Araidanga_D (West Bengal)",
        OriginCenter: "Malda_Araidanga_D (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11358,
        Pin: 454334,
        DispatchCenter: "Barwani_IGcolony_D (Madhya Pradesh)",
        OriginCenter: "Barwani_IGcolony_D (Madhya Pradesh)",
        FacilityCity: "Barwani",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11359,
        Pin: 603318,
        DispatchCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        OriginCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        FacilityCity: "Kanchipuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11360,
        Pin: 785668,
        DispatchCenter: "Sivasagar_Pdinnagar_D (Assam)",
        OriginCenter: "Sivasagar_Pdinnagar_D (Assam)",
        FacilityCity: "Sivasagar",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11361,
        Pin: 580002,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11362,
        Pin: 713103,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11363,
        Pin: 571103,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11364,
        Pin: 821316,
        DispatchCenter: "Sasaram_Karwandiya_I (Bihar)",
        OriginCenter: "Sasaram_Karwandiya_I (Bihar)",
        FacilityCity: "Sasaram",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11365,
        Pin: 177032,
        DispatchCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        OriginCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        FacilityCity: "Bangana",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11366,
        Pin: 503307,
        DispatchCenter: "Armoor_Mamidipillay_D (Telangana)",
        OriginCenter: "Armoor_Mamidipillay_D (Telangana)",
        FacilityCity: "Armoor",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11367,
        Pin: 431202,
        DispatchCenter: "Jalna_Yashodipnagar_D (Maharashtra)",
        OriginCenter: "Jalna_Yashodipnagar_D (Maharashtra)",
        FacilityCity: "Jalna",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11368,
        Pin: 305925,
        DispatchCenter: "Beawar_DC (Rajasthan)",
        OriginCenter: "Beawar_DC (Rajasthan)",
        FacilityCity: "Beawar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11369,
        Pin: 313324,
        DispatchCenter: "Rajsamand_Riicoarea_D (Rajasthan)",
        OriginCenter: "Rajsamand_Riicoarea_D (Rajasthan)",
        FacilityCity: "Rajsamand",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11370,
        Pin: 625119,
        DispatchCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        OriginCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        FacilityCity: "Melur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11371,
        Pin: 201021,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11372,
        Pin: 585215,
        DispatchCenter: "Talikoti_Hunashagi_D (Karnataka)",
        OriginCenter: "Talikoti_Hunashagi_D (Karnataka)",
        FacilityCity: "Gulbarga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11373,
        Pin: 743293,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11374,
        Pin: 845102,
        DispatchCenter: "Narkatiaganj_Sonkhar_D (Bihar)",
        OriginCenter: "Narkatiaganj_Sonkhar_D (Bihar)",
        FacilityCity: "Narkatiaganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11375,
        Pin: 781380,
        DispatchCenter: "Baihata_Sundhia_D (Assam)",
        OriginCenter: "Baihata_Sundhia_D (Assam)",
        FacilityCity: "Baihata",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11376,
        Pin: 493889,
        DispatchCenter: "Gariyaband_Dongari_D (Chhattisgarh)",
        OriginCenter: "Gariyaband_Dongari_D (Chhattisgarh)",
        FacilityCity: "Gariyaband",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11377,
        Pin: 517421,
        DispatchCenter: "Chittoor_Srpuram_D (Andhra Pradesh)",
        OriginCenter: "Chittoor_Srpuram_D (Andhra Pradesh)",
        FacilityCity: "Chittoor",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11378,
        Pin: 333028,
        DispatchCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        OriginCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        FacilityCity: "Jhunjhunu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11379,
        Pin: 623409,
        DispatchCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        OriginCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        FacilityCity: "Thiruvadanai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11380,
        Pin: 689510,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11381,
        Pin: 603301,
        DispatchCenter: "Melmaruvathur_IOBank_D (Tamil Nadu)",
        OriginCenter: "Melmaruvathur_IOBank_D (Tamil Nadu)",
        FacilityCity: "Melmaruvathur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11382,
        Pin: 412309,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11383,
        Pin: 678532,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11384,
        Pin: 249405,
        DispatchCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        OriginCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        FacilityCity: "Haridwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11385,
        Pin: 431707,
        DispatchCenter: "Loha_Sidharthnagar_D (Maharashtra)",
        OriginCenter: "Loha_Sidharthnagar_D (Maharashtra)",
        FacilityCity: "Loha",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11386,
        Pin: 384153,
        DispatchCenter: "Unjha_SatymComplx_D (Gujarat)",
        OriginCenter: "Unjha_SatymComplx_D (Gujarat)",
        FacilityCity: "Unjha",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11387,
        Pin: 621702,
        DispatchCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        OriginCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        FacilityCity: "Thirukkattupalli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11388,
        Pin: 507133,
        DispatchCenter: "Cherla_TrikutaDevalayamRd_DPP (Telangana)",
        OriginCenter: "Cherla_TrikutaDevalayamRd_DPP (Telangana)",
        FacilityCity: "CHERLA",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11389,
        Pin: 743422,
        DispatchCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        OriginCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        FacilityCity: "Basirhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11390,
        Pin: 768048,
        DispatchCenter: "Padampur_Melchhamunda_DPP (Orissa)",
        OriginCenter: "Padampur_Melchhamunda_DPP (Orissa)",
        FacilityCity: "Padampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11391,
        Pin: 713207,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11392,
        Pin: 384170,
        DispatchCenter: "Unjha_SatymComplx_D (Gujarat)",
        OriginCenter: "Unjha_SatymComplx_D (Gujarat)",
        FacilityCity: "Unjha",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11393,
        Pin: 826004,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11394,
        Pin: 606204,
        DispatchCenter: "Kallakurichi_Neelamangalam_D (Tamil Nadu)",
        OriginCenter: "Kallakurichi_Neelamangalam_D (Tamil Nadu)",
        FacilityCity: "Kallakurichi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11395,
        Pin: 600002,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11396,
        Pin: 784180,
        DispatchCenter: "Biswanath_Jamugurihat_DPP (Assam)",
        OriginCenter: "Biswanath_Jamugurihat_DPP (Assam)",
        FacilityCity: "BiswanathCH",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11397,
        Pin: 752032,
        DispatchCenter: "Maludkhas_Rajanagar_DPP (Orissa)",
        OriginCenter: "Maludkhas_Rajanagar_DPP (Orissa)",
        FacilityCity: "Maludkhas",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11398,
        Pin: 142039,
        DispatchCenter: "Barnala_Bhadaur_D (Punjab)",
        OriginCenter: "Barnala_Bhadaur_D (Punjab)",
        FacilityCity: "Bhadaur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11399,
        Pin: 174316,
        DispatchCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        OriginCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        FacilityCity: "Amb",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11400,
        Pin: 825109,
        DispatchCenter: "Domchanch_Gaira_DPP (Jharkhand)",
        OriginCenter: "Domchanch_Gaira_DPP (Jharkhand)",
        FacilityCity: "Domchanch",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11401,
        Pin: 629173,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11402,
        Pin: 680510,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11403,
        Pin: 821101,
        DispatchCenter: "Mohania_Shivpuricolony_D (Bihar)",
        OriginCenter: "Mohania_Shivpuricolony_D (Bihar)",
        FacilityCity: "Mohania",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11404,
        Pin: 516150,
        DispatchCenter: "Rajampet_BypassRd_D (Andhra Pradesh)",
        OriginCenter: "Rajampet_BypassRd_D (Andhra Pradesh)",
        FacilityCity: "Rajampet",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11405,
        Pin: 505453,
        DispatchCenter: "Koratla_PRBM_DPP (Telangana)",
        OriginCenter: "Koratla_PRBM_DPP (Telangana)",
        FacilityCity: "Koratla",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11406,
        Pin: 700029,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11407,
        Pin: 606708,
        DispatchCenter: "Sankarapuram_Thanipadi_D (Tamil Nadu)",
        OriginCenter: "Sankarapuram_Thanipadi_D (Tamil Nadu)",
        FacilityCity: "Sankarapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11408,
        Pin: 401301,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11409,
        Pin: 520007,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11410,
        Pin: 306302,
        DispatchCenter: "Bilara_Central_DPP_2 (Rajasthan)",
        OriginCenter: "Bilara_Central_DPP_2 (Rajasthan)",
        FacilityCity: "Bilara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11411,
        Pin: 688552,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11412,
        Pin: 581129,
        DispatchCenter: "Dandeli_RKComplx_D (Karnataka)",
        OriginCenter: "Dandeli_RKComplx_D (Karnataka)",
        FacilityCity: "Dandeli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11413,
        Pin: 364061,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11414,
        Pin: 190018,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11415,
        Pin: 683543,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11416,
        Pin: 271870,
        DispatchCenter: "Bahraich_Dularpur_H (Uttar Pradesh)",
        OriginCenter: "Bahraich_Dularpur_H (Uttar Pradesh)",
        FacilityCity: "Bahraich",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11417,
        Pin: 202280,
        DispatchCenter: "Aligarh_Atrauli_D (Uttar Pradesh)",
        OriginCenter: "Aligarh_Atrauli_D (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11418,
        Pin: 211010,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11419,
        Pin: 402117,
        DispatchCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        OriginCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        FacilityCity: "Mangaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11420,
        Pin: 712206,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11421,
        Pin: 673638,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11422,
        Pin: 140118,
        DispatchCenter: "AnandpurSahib_Dher_D (Punjab)",
        OriginCenter: "AnandpurSahib_Dher_D (Punjab)",
        FacilityCity: "Anandpur Sahib",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11423,
        Pin: 606208,
        DispatchCenter: "Sankarapuram_Vadasiruvalur_D (Tamil Nadu)",
        OriginCenter: "Sankarapuram_Vadasiruvalur_D (Tamil Nadu)",
        FacilityCity: "Sankarapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11424,
        Pin: 689623,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11425,
        Pin: 847107,
        DispatchCenter: "Dholi_Etwarpurjointi_D (Bihar)",
        OriginCenter: "Dholi_Etwarpurjointi_D (Bihar)",
        FacilityCity: "Dholi",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11426,
        Pin: 713381,
        DispatchCenter: "Dubrajpur_Pandaveswar_D (West Bengal)",
        OriginCenter: "Dubrajpur_Pandaveswar_D (West Bengal)",
        FacilityCity: "Dubrajpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11427,
        Pin: 522102,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11428,
        Pin: 610109,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11429,
        Pin: 761146,
        DispatchCenter: "Hinjilicut_RegidiSt_D (Orissa)",
        OriginCenter: "Hinjilicut_RegidiSt_D (Orissa)",
        FacilityCity: "Hinjilicut",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11430,
        Pin: 148028,
        DispatchCenter: "Sunam_Indrabasti_D (Punjab)",
        OriginCenter: "Sunam_Indrabasti_D (Punjab)",
        FacilityCity: "Sunam",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11431,
        Pin: 711406,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11432,
        Pin: 638812,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11433,
        Pin: 628205,
        DispatchCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        OriginCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        FacilityCity: "Tiruchendur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11434,
        Pin: 583202,
        DispatchCenter: "Hospet_Vijayanagara_D (Karnataka)",
        OriginCenter: "Hospet_Vijayanagara_D (Karnataka)",
        FacilityCity: "Hospet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11435,
        Pin: 678732,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11436,
        Pin: 522408,
        DispatchCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasaraopet",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11437,
        Pin: 185203,
        DispatchCenter: "Sunderbani_Ward5_D (Jammu & Kashmir)",
        OriginCenter: "Sunderbani_Ward5_D (Jammu & Kashmir)",
        FacilityCity: "Sunderbani",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11438,
        Pin: 490022,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11439,
        Pin: 587114,
        DispatchCenter: "Rona_GadagRD_D (Karnataka)",
        OriginCenter: "Rona_GadagRD_D (Karnataka)",
        FacilityCity: "Rona",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11440,
        Pin: 783332,
        DispatchCenter: "Tamarhat_Natabari_DPP (Assam)",
        OriginCenter: "Tamarhat_Natabari_DPP (Assam)",
        FacilityCity: "Agomani",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11441,
        Pin: 184202,
        DispatchCenter: "Billawar_Wardno7_D (Jammu & Kashmir)",
        OriginCenter: "Billawar_Wardno7_D (Jammu & Kashmir)",
        FacilityCity: "Billawar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11442,
        Pin: 641103,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11443,
        Pin: 134116,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11444,
        Pin: 508115,
        DispatchCenter: "Bhuvanagiri_Bhongir_D (Telangana)",
        OriginCenter: "Bhuvanagiri_Bhongir_D (Telangana)",
        FacilityCity: "Bhuvanagiri",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11445,
        Pin: 572221,
        DispatchCenter: "Turuvukere_Havala_D (Karnataka)",
        OriginCenter: "Turuvukere_Havala_D (Karnataka)",
        FacilityCity: "Turuvukere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11446,
        Pin: 742138,
        DispatchCenter: "Khargram_Parbatipur_D (West Bengal)",
        OriginCenter: "Khargram_Parbatipur_D (West Bengal)",
        FacilityCity: "Khargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11447,
        Pin: 385570,
        DispatchCenter: "Tharad_Budhanpur_D (Gujarat)",
        OriginCenter: "Tharad_Budhanpur_D (Gujarat)",
        FacilityCity: "Tharad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11448,
        Pin: 577213,
        DispatchCenter: "Channagiri_Taralabalu_D (Karnataka)",
        OriginCenter: "Channagiri_Taralabalu_D (Karnataka)",
        FacilityCity: "Channagiri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11449,
        Pin: 176001,
        DispatchCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        OriginCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        FacilityCity: "Kangra",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11450,
        Pin: 276301,
        DispatchCenter: "Azamgarh_Martinganj_DPP (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Martinganj_DPP (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11451,
        Pin: 396560,
        DispatchCenter: "Vansda_Chikatiya_D (Gujarat)",
        OriginCenter: "Vansda_Chikatiya_D (Gujarat)",
        FacilityCity: "Vansda",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11452,
        Pin: 477105,
        DispatchCenter: "Bhind_EtwahDPP_D (Madhya Pradesh)",
        OriginCenter: "Bhind_EtwahDPP_D (Madhya Pradesh)",
        FacilityCity: "Bhind",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11453,
        Pin: 533105,
        DispatchCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        OriginCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        FacilityCity: "Rajahmundry",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11454,
        Pin: 400703,
        DispatchCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        OriginCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11455,
        Pin: 632103,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11456,
        Pin: 795103,
        DispatchCenter: "Kakching_SumakLeikai_D (Manipur)",
        OriginCenter: "Kakching_SumakLeikai_D (Manipur)",
        FacilityCity: "Kakching",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11457,
        Pin: 151101,
        DispatchCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        OriginCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        FacilityCity: "Bathinda",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11458,
        Pin: 574235,
        DispatchCenter: "Vitla_FawzBuldng_DPP (Karnataka)",
        OriginCenter: "Vitla_FawzBuldng_DPP (Karnataka)",
        FacilityCity: "Vitla",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11459,
        Pin: 440029,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11460,
        Pin: 756121,
        DispatchCenter: "Jajpur_Panasa_D (Orissa)",
        OriginCenter: "Jajpur_Panasa_D (Orissa)",
        FacilityCity: "Jajpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11461,
        Pin: 302055,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11462,
        Pin: 583281,
        DispatchCenter: "Ilkal_Ward4_D (Karnataka)",
        OriginCenter: "Ilkal_Ward4_D (Karnataka)",
        FacilityCity: "Ilkal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11463,
        Pin: 735304,
        DispatchCenter: "Mekhliganj_WardNo7_DPP (West Bengal)",
        OriginCenter: "Mekhliganj_WardNo7_DPP (West Bengal)",
        FacilityCity: "Mekhliganj",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11464,
        Pin: 713170,
        DispatchCenter: "Kalna_BsStdDPP_D (West Bengal)",
        OriginCenter: "Kalna_BsStdDPP_D (West Bengal)",
        FacilityCity: "Kalna",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11465,
        Pin: 770032,
        DispatchCenter: "Rourkela (Orissa)",
        OriginCenter: "Rourkela (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11466,
        Pin: 361003,
        DispatchCenter: "Jamnagar_Chela_L (Gujarat)",
        OriginCenter: "Jamnagar_Chela_L (Gujarat)",
        FacilityCity: "Jamnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11467,
        Pin: 613202,
        DispatchCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        OriginCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        FacilityCity: "Thirukkattupalli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11468,
        Pin: 627012,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11469,
        Pin: 411035,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11470,
        Pin: 327021,
        DispatchCenter: "Ghatol_Teliwada_DPP (Rajasthan)",
        OriginCenter: "Ghatol_Teliwada_DPP (Rajasthan)",
        FacilityCity: "Ghatol",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11471,
        Pin: 683575,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11472,
        Pin: 691541,
        DispatchCenter: "Pangodu_Bharathanoor_D (Kerala)",
        OriginCenter: "Pangodu_Bharathanoor_D (Kerala)",
        FacilityCity: "Pangodu",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11473,
        Pin: 302030,
        DispatchCenter: "Jaipur_Ramchandpura_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11474,
        Pin: 769015,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11475,
        Pin: 494337,
        DispatchCenter: "Charama_Nakapara_D (Chhattisgarh)",
        OriginCenter: "Charama_Nakapara_D (Chhattisgarh)",
        FacilityCity: "Charama",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11476,
        Pin: 731202,
        DispatchCenter: "Rampurhat_Mansubamore_D (West Bengal)",
        OriginCenter: "Rampurhat_Mansubamore_D (West Bengal)",
        FacilityCity: "Rampurhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11477,
        Pin: 531117,
        DispatchCenter: "Narsipatnam_Sivapuram_D (Andhra Pradesh)",
        OriginCenter: "Narsipatnam_Sivapuram_D (Andhra Pradesh)",
        FacilityCity: "Narsipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11478,
        Pin: 685532,
        DispatchCenter: "Mundakayam_Varikkani_D (Kerala)",
        OriginCenter: "Mundakayam_Varikkani_D (Kerala)",
        FacilityCity: "Mundakayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11479,
        Pin: 591308,
        DispatchCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        OriginCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        FacilityCity: "Gokak",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11480,
        Pin: 673517,
        DispatchCenter: "Thalassery_Iyyamkode_D (Kerala)",
        OriginCenter: "Thalassery_Iyyamkode_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11481,
        Pin: 476332,
        DispatchCenter: "Sabalgarh_Bhainsai_DPP (Madhya Pradesh)",
        OriginCenter: "Sabalgarh_Bhainsai_DPP (Madhya Pradesh)",
        FacilityCity: "Sabalgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11482,
        Pin: 685582,
        DispatchCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        OriginCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        FacilityCity: "Thodupuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11483,
        Pin: 678689,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11484,
        Pin: 133101,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11485,
        Pin: 502280,
        DispatchCenter: "Cherial_Vchreni_D (Telangana)",
        OriginCenter: "Cherial_Vchreni_D (Telangana)",
        FacilityCity: "Cherial",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11486,
        Pin: 396310,
        DispatchCenter: "Bilimora_DC (Gujarat)",
        OriginCenter: "Bilimora_DC (Gujarat)",
        FacilityCity: "Bilimora",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11487,
        Pin: 586202,
        DispatchCenter: "Sindagi_Bijapurrd_D (Karnataka)",
        OriginCenter: "Sindagi_Bijapurrd_D (Karnataka)",
        FacilityCity: "Sindagi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11488,
        Pin: 461775,
        DispatchCenter: "Pipariya_PachmrhiRd_DPP (Madhya Pradesh)",
        OriginCenter: "Pipariya_PachmrhiRd_DPP (Madhya Pradesh)",
        FacilityCity: "Pipariya",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11489,
        Pin: 560010,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11490,
        Pin: 410507,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11491,
        Pin: 608602,
        DispatchCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        OriginCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        FacilityCity: "Chidambaram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11492,
        Pin: 403722,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11493,
        Pin: 814103,
        DispatchCenter: "Shikaripara_Karakata_D (Jharkhand)",
        OriginCenter: "Shikaripara_Karakata_D (Jharkhand)",
        FacilityCity: "Shikaripara",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11494,
        Pin: 518533,
        DispatchCenter: "Velgodu_TVSShwrm_D (Andhra Pradesh)",
        OriginCenter: "Velgodu_TVSShwrm_D (Andhra Pradesh)",
        FacilityCity: "Velgodu",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11495,
        Pin: 845107,
        DispatchCenter: "ValmikiNagar_TankiBazar_D (Bihar)",
        OriginCenter: "ValmikiNagar_TankiBazar_D (Bihar)",
        FacilityCity: "Valmiki Nagar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11496,
        Pin: 623514,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11497,
        Pin: 227125,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11498,
        Pin: 271308,
        DispatchCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        OriginCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        FacilityCity: "Gonda",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11499,
        Pin: 412415,
        DispatchCenter: 37043.00011574074,
        OriginCenter: 37043.00011574074,
        FacilityCity: "Junnar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11500,
        Pin: 431719,
        DispatchCenter: "Udgir_NlgaonRd_D (Maharashtra)",
        OriginCenter: "Udgir_NlgaonRd_D (Maharashtra)",
        FacilityCity: "Udgir",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11501,
        Pin: 846004,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11502,
        Pin: 396440,
        DispatchCenter: "Navsari_Vejalpore_D (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Navsari",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11503,
        Pin: 382433,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11504,
        Pin: 522233,
        DispatchCenter: "Chilakaluripet_ChrlesChrch_DPP (Andhra Pradesh)",
        OriginCenter: "Chilakaluripet_ChrlesChrch_DPP (Andhra Pradesh)",
        FacilityCity: "Chilakaluripet",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11505,
        Pin: 394375,
        DispatchCenter: "Songadh_Shivnagar2_DPP (Gujarat)",
        OriginCenter: "Songadh_Shivnagar2_DPP (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11506,
        Pin: 394230,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11507,
        Pin: 641118,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11508,
        Pin: 573212,
        DispatchCenter: "Hassan_Nagathavalli_I (Karnataka)",
        OriginCenter: "Hassan_Nagathavalli_I (Karnataka)",
        FacilityCity: "Hassan",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11509,
        Pin: 691572,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11510,
        Pin: 172034,
        DispatchCenter: "Jeori_AadvikEng_DPP (Himachal Pradesh)",
        OriginCenter: "Jeori_AadvikEng_DPP (Himachal Pradesh)",
        FacilityCity: "Jeori",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11511,
        Pin: 627551,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11512,
        Pin: 785674,
        DispatchCenter: "Nazira_Amina_D (Assam)",
        OriginCenter: "Nazira_Amina_D (Assam)",
        FacilityCity: "Nazira",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11513,
        Pin: 673575,
        DispatchCenter: "Kalpetta_Mandayapuram_D (Kerala)",
        OriginCenter: "Kalpetta_Mandayapuram_D (Kerala)",
        FacilityCity: "Kalpetta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11514,
        Pin: 609107,
        DispatchCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        OriginCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        FacilityCity: "Sirkazhi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11515,
        Pin: 413003,
        DispatchCenter: "Solapur_Balepeth_I (Maharashtra)",
        OriginCenter: "Solapur_Balepeth_I (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11516,
        Pin: 444602,
        DispatchCenter: "Amravati_Changapur_D (Maharashtra)",
        OriginCenter: "Amravati_Changapur_D (Maharashtra)",
        FacilityCity: "Amravati",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11517,
        Pin: 607807,
        DispatchCenter: "Neyveli_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Neyveli_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Neyveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11518,
        Pin: 843127,
        DispatchCenter: "Muzaffarpur_Motipur_D (Bihar)",
        OriginCenter: "Muzaffarpur_Motipur_D (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11519,
        Pin: 811102,
        DispatchCenter: "Lakhisarai_Central_I_2 (Bihar)",
        OriginCenter: "Lakhisarai_Central_I_2 (Bihar)",
        FacilityCity: "Lakhisarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11520,
        Pin: 813213,
        DispatchCenter: "Sultanganj_Dilgouri_D (Bihar)",
        OriginCenter: "Sultanganj_Dilgouri_D (Bihar)",
        FacilityCity: "Sultanganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11521,
        Pin: 628008,
        DispatchCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        OriginCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        FacilityCity: "Tuticorin",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11522,
        Pin: 743345,
        DispatchCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        OriginCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        FacilityCity: "Dakshin Barasat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11523,
        Pin: 581361,
        DispatchCenter: "Honnavar_ForestColony_D (Karnataka)",
        OriginCenter: "Honnavar_ForestColony_D (Karnataka)",
        FacilityCity: "Honnavar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11524,
        Pin: 700145,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11525,
        Pin: 614614,
        DispatchCenter: "Karambakkudi_Central_D (Tamil Nadu)",
        OriginCenter: "Karambakkudi_Central_D (Tamil Nadu)",
        FacilityCity: "Karambakkudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11526,
        Pin: 413005,
        DispatchCenter: "Solapur_Balepeth_I (Maharashtra)",
        OriginCenter: "Solapur_Balepeth_I (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11527,
        Pin: 743376,
        DispatchCenter: "Canning_BaruipurRd_D (West Bengal)",
        OriginCenter: "Canning_BaruipurRd_D (West Bengal)",
        FacilityCity: "Canning",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11528,
        Pin: 535002,
        DispatchCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        OriginCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        FacilityCity: "Vizianagaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11529,
        Pin: 273012,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11530,
        Pin: 441902,
        DispatchCenter: "Gondia_Mundipar_I (Maharashtra)",
        OriginCenter: "Gondia_Mundipar_I (Maharashtra)",
        FacilityCity: "Gondia",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11531,
        Pin: 851132,
        DispatchCenter: "Begusarai_Haibatpur_D (Bihar)",
        OriginCenter: "Begusarai_Haibatpur_D (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11532,
        Pin: 143105,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11533,
        Pin: 517003,
        DispatchCenter: "Chittoor_Murukambattu_D (Andhra Pradesh)",
        OriginCenter: "Chittoor_Murukambattu_D (Andhra Pradesh)",
        FacilityCity: "Chittoor",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11534,
        Pin: 605801,
        DispatchCenter: "Sankarapuram_Vadasiruvalur_D (Tamil Nadu)",
        OriginCenter: "Sankarapuram_Vadasiruvalur_D (Tamil Nadu)",
        FacilityCity: "Sankarapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11535,
        Pin: 754104,
        DispatchCenter: "Jagatsinghpur_Bnbrda_D (Orissa)",
        OriginCenter: "Jagatsinghpur_Bnbrda_D (Orissa)",
        FacilityCity: "Jagatsinghpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11536,
        Pin: 303007,
        DispatchCenter: "Jaipur_RingRoad_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11537,
        Pin: 456443,
        DispatchCenter: "Agar_ShetlaTemple_D (Madhya Pradesh)",
        OriginCenter: "Agar_ShetlaTemple_D (Madhya Pradesh)",
        FacilityCity: "Agar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11538,
        Pin: 175035,
        DispatchCenter: "Thunag_SBI_D (Himachal Pradesh)",
        OriginCenter: "Thunag_SBI_D (Himachal Pradesh)",
        FacilityCity: "Thunag",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11539,
        Pin: 577133,
        DispatchCenter: "Chikmagalur_Jayanagara_D (Karnataka)",
        OriginCenter: "Chikmagalur_Jayanagara_D (Karnataka)",
        FacilityCity: "Chikkamagalur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11540,
        Pin: 431504,
        DispatchCenter: "Mantha_SugandhaNgr_DPP (Maharashtra)",
        OriginCenter: "Mantha_SugandhaNgr_DPP (Maharashtra)",
        FacilityCity: "Mantha",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11541,
        Pin: 788722,
        DispatchCenter: "Karimganj_Andharibond_D (Assam)",
        OriginCenter: "Karimganj_Andharibond_D (Assam)",
        FacilityCity: "Karimganj",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11542,
        Pin: 271208,
        DispatchCenter: "Balrampur_Tulsipur_D (Uttar Pradesh)",
        OriginCenter: "Balrampur_Tulsipur_D (Uttar Pradesh)",
        FacilityCity: "Balrampur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11543,
        Pin: 689123,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11544,
        Pin: 737113,
        DispatchCenter: "Pelling_Dentam_DPP (Sikkim)",
        OriginCenter: "Pelling_Dentam_DPP (Sikkim)",
        FacilityCity: "Pelling",
        FacilityState: "Sikkim",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11545,
        Pin: 689648,
        DispatchCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        OriginCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11546,
        Pin: 721443,
        DispatchCenter: "Contai_Egra_D (West Bengal)",
        OriginCenter: "Contai_Egra_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11547,
        Pin: 413510,
        DispatchCenter: "Osmanabad_Murud_D (Maharashtra)",
        OriginCenter: "Osmanabad_Murud_D (Maharashtra)",
        FacilityCity: "Osmanabad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11548,
        Pin: 305202,
        DispatchCenter: "Beawar_DC (Rajasthan)",
        OriginCenter: "Beawar_DC (Rajasthan)",
        FacilityCity: "Beawar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11549,
        Pin: 691014,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11550,
        Pin: 400051,
        DispatchCenter: "Mumbai_Kurla_L (Maharashtra)",
        OriginCenter: "Mumbai_Kurla_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11551,
        Pin: 171207,
        DispatchCenter: "Rohru_Samala_D (Himachal Pradesh)",
        OriginCenter: "Rohru_Samala_D (Himachal Pradesh)",
        FacilityCity: "Rohru",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11552,
        Pin: 533242,
        DispatchCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        OriginCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        FacilityCity: "Razole",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11553,
        Pin: 741509,
        DispatchCenter: "Hanskhali_Ashrampara_D (West Bengal)",
        OriginCenter: "Hanskhali_Ashrampara_D (West Bengal)",
        FacilityCity: "Hanskhali",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11554,
        Pin: 523184,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11555,
        Pin: 843141,
        DispatchCenter: "Muzaffarpur_Motipur_D (Bihar)",
        OriginCenter: "Muzaffarpur_Motipur_D (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11556,
        Pin: 680562,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11557,
        Pin: 174503,
        DispatchCenter: "Tahliwal_Central_D (Himachal Pradesh)",
        OriginCenter: "Tahliwal_Central_D (Himachal Pradesh)",
        FacilityCity: "Tahliwal",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11558,
        Pin: 502286,
        DispatchCenter: "Narayankhed_Turkapally_D (Telangana)",
        OriginCenter: "Narayankhed_Turkapally_D (Telangana)",
        FacilityCity: "Narayankhed",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11559,
        Pin: 670521,
        DispatchCenter: "Nileshwar_Palathadam_D (Kerala)",
        OriginCenter: "Nileshwar_Palathadam_D (Kerala)",
        FacilityCity: "Nileshwar",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11560,
        Pin: 686534,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11561,
        Pin: 637415,
        DispatchCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        OriginCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        FacilityCity: "Namakkal",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11562,
        Pin: 802112,
        DispatchCenter: "Brahmapur_Shahpur_D (Bihar)",
        OriginCenter: "Brahmapur_Shahpur_D (Bihar)",
        FacilityCity: "Brahmapur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11563,
        Pin: 782105,
        DispatchCenter: "Morigaon_Nabheti_D (Assam)",
        OriginCenter: "Morigaon_Nabheti_D (Assam)",
        FacilityCity: "Morigaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11564,
        Pin: 250102,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11565,
        Pin: 844123,
        DispatchCenter: "Lalganj_Kharauna_D (Bihar)",
        OriginCenter: "Lalganj_Kharauna_D (Bihar)",
        FacilityCity: "Lalganj-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11566,
        Pin: 786612,
        DispatchCenter: "Naharkatia_JaypurNagar_D (Assam)",
        OriginCenter: "Naharkatia_JaypurNagar_D (Assam)",
        FacilityCity: "Naharkatia",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11567,
        Pin: 681029,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11568,
        Pin: 440030,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11569,
        Pin: 500085,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11570,
        Pin: 271881,
        DispatchCenter: "Nanpara_Dehati_D (Uttar Pradesh)",
        OriginCenter: "Nanpara_Dehati_D (Uttar Pradesh)",
        FacilityCity: "Nanpara",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11571,
        Pin: 790116,
        DispatchCenter: "Bomdila_Tengavalley_D (Arunachal Pradesh)",
        OriginCenter: "Bomdila_Tengavalley_D (Arunachal Pradesh)",
        FacilityCity: "Bomdila",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11572,
        Pin: 784514,
        DispatchCenter: "Udalguri_Dhansrighat_D (Assam)",
        OriginCenter: "Udalguri_Dhansrighat_D (Assam)",
        FacilityCity: "Udalguri",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11573,
        Pin: 629806,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11574,
        Pin: 230137,
        DispatchCenter: "LalganjAjhara_HPPump_D (Uttar Pradesh)",
        OriginCenter: "LalganjAjhara_HPPump_D (Uttar Pradesh)",
        FacilityCity: "Lalganj Ajhara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11575,
        Pin: 841436,
        DispatchCenter: "Mirganj_Lalmrkt_D (Bihar)",
        OriginCenter: "Mirganj_Lalmrkt_D (Bihar)",
        FacilityCity: "Mirganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11576,
        Pin: 122226,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11577,
        Pin: 770051,
        DispatchCenter: "Lahunipara_Gudhiali_D (Orissa)",
        OriginCenter: "Lahunipara_Gudhiali_D (Orissa)",
        FacilityCity: "Lahunipara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11578,
        Pin: 805128,
        DispatchCenter: "Wazirganj_Usawa_D (Bihar)",
        OriginCenter: "Wazirganj_Usawa_D (Bihar)",
        FacilityCity: "Wazirganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11579,
        Pin: 231302,
        DispatchCenter: "Chunnar_Bharpur_D (Uttar Pradesh)",
        OriginCenter: "Chunnar_Bharpur_D (Uttar Pradesh)",
        FacilityCity: "Chunnar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11580,
        Pin: 506175,
        DispatchCenter: "Parkal_SrinivasaColony_D (Telangana)",
        OriginCenter: "Parkal_SrinivasaColony_D (Telangana)",
        FacilityCity: "Parkal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11581,
        Pin: 191102,
        DispatchCenter: "Srinagar_Ladhoo_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Ladhoo_H (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11582,
        Pin: 754153,
        DispatchCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        OriginCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11583,
        Pin: 493558,
        DispatchCenter: "Saraipalli_Baitri_D (Chhattisgarh)",
        OriginCenter: "Saraipalli_Baitri_D (Chhattisgarh)",
        FacilityCity: "Saraipalli",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11584,
        Pin: 401207,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11585,
        Pin: 461251,
        DispatchCenter: "Harda_Timarni_DPP (Madhya Pradesh)",
        OriginCenter: "Harda_Timarni_DPP (Madhya Pradesh)",
        FacilityCity: "Harda",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 11586,
        Pin: 625514,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11587,
        Pin: 122231,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11588,
        Pin: 824219,
        DispatchCenter: "Sherghati_GayaRd_D (Bihar)",
        OriginCenter: "Sherghati_GayaRd_D (Bihar)",
        FacilityCity: "Sherghati",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11589,
        Pin: 262405,
        DispatchCenter: "Sitarganj_Bijti_D (Uttarakhand)",
        OriginCenter: "Sitarganj_Bijti_D (Uttarakhand)",
        FacilityCity: "Sitarganj",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11590,
        Pin: 224145,
        DispatchCenter: "Faizabad_MayaBazar_D (Uttar Pradesh)",
        OriginCenter: "Faizabad_MayaBazar_D (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11591,
        Pin: 851118,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11592,
        Pin: 678103,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11593,
        Pin: 691534,
        DispatchCenter: "Kilimanoor_AKNArcard_D (Kerala)",
        OriginCenter: "Kilimanoor_AKNArcard_D (Kerala)",
        FacilityCity: "Kilimanoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11594,
        Pin: 321609,
        DispatchCenter: "Mahwa_Allapur_D (Rajasthan)",
        OriginCenter: "Mahwa_Allapur_D (Rajasthan)",
        FacilityCity: "Mahwa",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11595,
        Pin: 845417,
        DispatchCenter: "Motihari_Lauriya_D (Bihar)",
        OriginCenter: "Motihari_Lauriya_D (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11596,
        Pin: 133001,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11597,
        Pin: 143402,
        DispatchCenter: "TarnTaranSahib_BasantAvenue_D (Punjab)",
        OriginCenter: "TarnTaranSahib_BasantAvenue_D (Punjab)",
        FacilityCity: "Tarn Taran Sahib",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11598,
        Pin: 517419,
        DispatchCenter: "Chittoor_Murukambattu_D (Andhra Pradesh)",
        OriginCenter: "Chittoor_Murukambattu_D (Andhra Pradesh)",
        FacilityCity: "Chittoor",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11599,
        Pin: 680621,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11600,
        Pin: 713217,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11601,
        Pin: 515331,
        DispatchCenter: "Hindupur_Sadlapalli_D (Andhra Pradesh)",
        OriginCenter: "Hindupur_Sadlapalli_D (Andhra Pradesh)",
        FacilityCity: "Hindupur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11602,
        Pin: 440009,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Chikhali_L (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11603,
        Pin: 225120,
        DispatchCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        OriginCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        FacilityCity: "Haidargarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11604,
        Pin: 843122,
        DispatchCenter: "Saraiya_Saraiya_D (Bihar)",
        OriginCenter: "Saraiya_Saraiya_D (Bihar)",
        FacilityCity: "Saraiya",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11605,
        Pin: 177042,
        DispatchCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        OriginCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        FacilityCity: "Nadaun",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11606,
        Pin: 841504,
        DispatchCenter: "Raghunathpur_DhnwtiKhp_D (Bihar)",
        OriginCenter: "Raghunathpur_DhnwtiKhp_D (Bihar)",
        FacilityCity: "Raghunathpur-BR",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11607,
        Pin: 781317,
        DispatchCenter: "Barpeta_NorthDPP_D (Assam)",
        OriginCenter: "Barpeta_ShaktiNagar_C (Assam)",
        FacilityCity: "Barpeta",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11608,
        Pin: 250625,
        DispatchCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        OriginCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        FacilityCity: "Baraut",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11609,
        Pin: 332719,
        DispatchCenter: "Reengus_TegoreViharColony_D (Rajasthan)",
        OriginCenter: "Reengus_TegoreViharColony_D (Rajasthan)",
        FacilityCity: "Reengus",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11610,
        Pin: 229841,
        DispatchCenter: "Malihabad_Amanignj_DPP (Uttar Pradesh)",
        OriginCenter: "Malihabad_Amanignj_DPP (Uttar Pradesh)",
        FacilityCity: "Malihabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11611,
        Pin: 533248,
        DispatchCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        OriginCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        FacilityCity: "Razole",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11612,
        Pin: 412214,
        DispatchCenter: "UrliKanchan_Sortapwadi_D (Maharashtra)",
        OriginCenter: "UrliKanchan_Sortapwadi_D (Maharashtra)",
        FacilityCity: "UrliKanchan",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11613,
        Pin: 628302,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11614,
        Pin: 224171,
        DispatchCenter: "Faizabad_MayaBazar_D (Uttar Pradesh)",
        OriginCenter: "Faizabad_MayaBazar_D (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11615,
        Pin: 403206,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11616,
        Pin: 792129,
        DispatchCenter: "Deomali_SumiButyPrlr_D (Arunachal Pradesh)",
        OriginCenter: "Deomali_SumiButyPrlr_D (Arunachal Pradesh)",
        FacilityCity: "Deomali",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11617,
        Pin: 523135,
        DispatchCenter: "Addanki_Inkollu_D (Andhra Pradesh)",
        OriginCenter: "Addanki_Inkollu_D (Andhra Pradesh)",
        FacilityCity: "Addanki",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11618,
        Pin: 322033,
        DispatchCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        OriginCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        FacilityCity: "Sawai Madhopur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11619,
        Pin: 713210,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11620,
        Pin: 627701,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11621,
        Pin: 444908,
        DispatchCenter: "Warud_Policestn_DPP (Maharashtra)",
        OriginCenter: "Warud_Policestn_DPP (Maharashtra)",
        FacilityCity: "Warud",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11622,
        Pin: 517237,
        DispatchCenter: "Madanapalle_Vayalapadu_D (Andhra Pradesh)",
        OriginCenter: "Madanapalle_Vayalapadu_D (Andhra Pradesh)",
        FacilityCity: "Madanapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11623,
        Pin: 503186,
        DispatchCenter: "Nizamabad_Sarangapur_I (Telangana)",
        OriginCenter: "Nizamabad_Sarangapur_I (Telangana)",
        FacilityCity: "Nizamabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11624,
        Pin: 301706,
        DispatchCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        OriginCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        FacilityCity: "Neemrana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11625,
        Pin: 581384,
        DispatchCenter: "Honnavar_ForestColony_D (Karnataka)",
        OriginCenter: "Honnavar_ForestColony_D (Karnataka)",
        FacilityCity: "Honnavar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11626,
        Pin: 481332,
        DispatchCenter: "Waraseoni_Lalbarra_D (Madhya Pradesh)",
        OriginCenter: "Waraseoni_Lalbarra_D (Madhya Pradesh)",
        FacilityCity: "Waraseoni",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 11627,
        Pin: 403717,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11628,
        Pin: 712331,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11629,
        Pin: 614402,
        DispatchCenter: "Thanjavur_Palampudur_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Palampudur_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11630,
        Pin: 571342,
        DispatchCenter: "Chamarajanagar_Galipur_D (Karnataka)",
        OriginCenter: "Chamarajanagar_Galipur_D (Karnataka)",
        FacilityCity: "Chamarajanagar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11631,
        Pin: 734010,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11632,
        Pin: 834007,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11633,
        Pin: 742401,
        DispatchCenter: "Dakshinkhanda_BusStand_DPP (West Bengal)",
        OriginCenter: "Dakshinkhanda_BusStand_DPP (West Bengal)",
        FacilityCity: "Katwa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11634,
        Pin: 604303,
        DispatchCenter: "Marakkanam_Kandadu_DPP (Tamil Nadu)",
        OriginCenter: "Marakkanam_Kandadu_DPP (Tamil Nadu)",
        FacilityCity: "Marakkanam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11635,
        Pin: 493344,
        DispatchCenter: "Jaijaipur_Shivrinarayan_DPP (Chhattisgarh)",
        OriginCenter: "Jaijaipur_Shivrinarayan_DPP (Chhattisgarh)",
        FacilityCity: "Jaijaipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11636,
        Pin: 251320,
        DispatchCenter: "Mawana_Veernagar_D (Uttar Pradesh)",
        OriginCenter: "Mawana_Veernagar_D (Uttar Pradesh)",
        FacilityCity: "Mawana",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11637,
        Pin: 582217,
        DispatchCenter: "Rona_GadagRD_D (Karnataka)",
        OriginCenter: "Rona_GadagRD_D (Karnataka)",
        FacilityCity: "Rona",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11638,
        Pin: 622102,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11639,
        Pin: 828133,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11640,
        Pin: 713516,
        DispatchCenter: "Katwa_Panchghara_D (West Bengal)",
        OriginCenter: "Katwa_Panchghara_D (West Bengal)",
        FacilityCity: "Katwa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11641,
        Pin: 314032,
        DispatchCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        OriginCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        FacilityCity: "Sagwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11642,
        Pin: 626612,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11643,
        Pin: 600116,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11644,
        Pin: 441123,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Chikhali_L (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11645,
        Pin: 828104,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11646,
        Pin: 743145,
        DispatchCenter: "Naihati_Kalyani_DPP (West Bengal)",
        OriginCenter: "Naihati_Kalyani_DPP (West Bengal)",
        FacilityCity: "Kalyani",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11647,
        Pin: 562132,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11648,
        Pin: 400095,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11649,
        Pin: 475682,
        DispatchCenter: "Lahar_Beejasen_D (Madhya Pradesh)",
        OriginCenter: "Lahar_Beejasen_D (Madhya Pradesh)",
        FacilityCity: "Lahar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 11650,
        Pin: 614623,
        DispatchCenter: "Peravurani_Nattanikkottai_D (Tamil Nadu)",
        OriginCenter: "Peravurani_Nattanikkottai_D (Tamil Nadu)",
        FacilityCity: "Peravurani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11651,
        Pin: 802126,
        DispatchCenter: "Dumraon_MahvidylaRd_D (Bihar)",
        OriginCenter: "Dumraon_MahvidylaRd_D (Bihar)",
        FacilityCity: "Dumraon",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11652,
        Pin: 176303,
        DispatchCenter: "Dalhousie_Banikhet_D (Himachal Pradesh)",
        OriginCenter: "Dalhousie_Banikhet_D (Himachal Pradesh)",
        FacilityCity: "Dalhousie",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11653,
        Pin: 761141,
        DispatchCenter: "Galleri_Central_DPP (Orissa)",
        OriginCenter: "Galleri_Central_DPP (Orissa)",
        FacilityCity: "Galleri",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11654,
        Pin: 382728,
        DispatchCenter: "Kadi_Jadavpura_L (Gujarat)",
        OriginCenter: "Kadi_Jadavpura_L (Gujarat)",
        FacilityCity: "Kadi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11655,
        Pin: 741249,
        DispatchCenter: "Naihati_Kalyani_DPP (West Bengal)",
        OriginCenter: "Naihati_Kalyani_DPP (West Bengal)",
        FacilityCity: "Kalyani",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11656,
        Pin: 227408,
        DispatchCenter: "Sultanpur_Balpur_D (Uttar Pradesh)",
        OriginCenter: "Sultanpur_Balpur_D (Uttar Pradesh)",
        FacilityCity: "Sultanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11657,
        Pin: 462019,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 11658,
        Pin: 721658,
        DispatchCenter: "Haldia_Basudevpur_D (West Bengal)",
        OriginCenter: "Haldia_Basudevpur_D (West Bengal)",
        FacilityCity: "Haldia",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11659,
        Pin: 488220,
        DispatchCenter: "Panna_MPEBColony_DPP (Madhya Pradesh)",
        OriginCenter: "Panna_MPEBColony_DPP (Madhya Pradesh)",
        FacilityCity: "Panna",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 11660,
        Pin: 192221,
        DispatchCenter: "Anantnag_Qazigund_D (Jammu & Kashmir)",
        OriginCenter: "Anantnag_Qazigund_D (Jammu & Kashmir)",
        FacilityCity: "Anantnag",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11661,
        Pin: 515701,
        DispatchCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        OriginCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11662,
        Pin: 457118,
        DispatchCenter: "Alote_PanchamViharColony_D (Madhya Pradesh)",
        OriginCenter: "Alote_PanchamViharColony_D (Madhya Pradesh)",
        FacilityCity: "Alote",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11663,
        Pin: 571435,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11664,
        Pin: 624620,
        DispatchCenter: "Gujiliamparai_Palayam_DPP (Tamil Nadu)",
        OriginCenter: "Gujiliamparai_Palayam_DPP (Tamil Nadu)",
        FacilityCity: "Gujiliamparai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11665,
        Pin: 631208,
        DispatchCenter: "Sholinghur_Ramkrishnarajapet_D (Tamil Nadu)",
        OriginCenter: "Sholinghur_Ramkrishnarajapet_D (Tamil Nadu)",
        FacilityCity: "Sholinghur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11666,
        Pin: 606402,
        DispatchCenter: "Sankarapuram_Vadasiruvalur_D (Tamil Nadu)",
        OriginCenter: "Sankarapuram_Vadasiruvalur_D (Tamil Nadu)",
        FacilityCity: "Sankarapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11667,
        Pin: 614013,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11668,
        Pin: 679336,
        DispatchCenter: "Cherpulassery_PryaPlda_D (Kerala)",
        OriginCenter: "Cherpulassery_PryaPlda_D (Kerala)",
        FacilityCity: "Cherpulassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11669,
        Pin: 758037,
        DispatchCenter: "Barbil_Rungtacolony_D (Orissa)",
        OriginCenter: "Barbil_Rungtacolony_D (Orissa)",
        FacilityCity: "Barbil",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11670,
        Pin: 522529,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11671,
        Pin: 516228,
        DispatchCenter: "Badvel_Vidyanagar_D (Andhra Pradesh)",
        OriginCenter: "Badvel_Vidyanagar_D (Andhra Pradesh)",
        FacilityCity: "Badvel",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11672,
        Pin: 765022,
        DispatchCenter: "Gunupur_GnprMkt_DPP (Orissa)",
        OriginCenter: "Gunupur_GnprMkt_DPP (Orissa)",
        FacilityCity: "Gunupur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11673,
        Pin: 632531,
        DispatchCenter: "Arakkonam_Ranipet_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ranipet_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11674,
        Pin: 362120,
        DispatchCenter: "Visavadar_JunagadhRd_DPP (Gujarat)",
        OriginCenter: "Visavadar_JunagadhRd_DPP (Gujarat)",
        FacilityCity: "Visavadar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11675,
        Pin: 305022,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11676,
        Pin: 229125,
        DispatchCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        OriginCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        FacilityCity: "Lalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11677,
        Pin: 507318,
        DispatchCenter: "Khammam_Mudigonda_H (Telangana)",
        OriginCenter: "Khammam_Mudigonda_H (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11678,
        Pin: 500146,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11679,
        Pin: 524121,
        DispatchCenter: "Sullurupeta_Sainagar_D (Andhra Pradesh)",
        OriginCenter: "Sullurupeta_Sainagar_D (Andhra Pradesh)",
        FacilityCity: "Sullurupeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11680,
        Pin: 431536,
        DispatchCenter: "Gangakher_VithalNgr_D (Maharashtra)",
        OriginCenter: "Gangakher_VithalNgr_D (Maharashtra)",
        FacilityCity: "Gangakher",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11681,
        Pin: 456008,
        DispatchCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        OriginCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        FacilityCity: "Ujjain",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 11682,
        Pin: 629305,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11683,
        Pin: 413205,
        DispatchCenter: "Jamkhed_DaulatCmplex_DPP (Maharashtra)",
        OriginCenter: "Jamkhed_DaulatCmplex_DPP (Maharashtra)",
        FacilityCity: "Jamkhed",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11684,
        Pin: 621205,
        DispatchCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        OriginCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        FacilityCity: "Kulithalai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11685,
        Pin: 248006,
        DispatchCenter: "Dehradun_Clement_L (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11686,
        Pin: 683110,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11687,
        Pin: 625503,
        DispatchCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        OriginCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        FacilityCity: "Vadipatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11688,
        Pin: 344037,
        DispatchCenter: "Phalsund_Kajoi_D (Rajasthan)",
        OriginCenter: "Phalsund_Kajoi_D (Rajasthan)",
        FacilityCity: "Phalsund",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11689,
        Pin: 500659,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11690,
        Pin: 562131,
        DispatchCenter: "Kunigal_Vidyanagar_D (Karnataka)",
        OriginCenter: "Kunigal_Vidyanagar_D (Karnataka)",
        FacilityCity: "Kunigal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11691,
        Pin: 601207,
        DispatchCenter: "Gummudipoondi_Kvraiptai_D (Tamil Nadu)",
        OriginCenter: "Gummudipoondi_Kvraiptai_D (Tamil Nadu)",
        FacilityCity: "Gummudipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11692,
        Pin: 306501,
        DispatchCenter: "Pali_Transportnagar_I (Rajasthan)",
        OriginCenter: "Pali_Transportnagar_I (Rajasthan)",
        FacilityCity: "Pali",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11693,
        Pin: 326510,
        DispatchCenter: "RamganjMandi_AdarshNagar_D (Rajasthan)",
        OriginCenter: "RamganjMandi_AdarshNagar_D (Rajasthan)",
        FacilityCity: "Ramganj Mandi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11694,
        Pin: 792131,
        DispatchCenter: "Longding_Niaunyu_D (Arunachal Pradesh)",
        OriginCenter: "Longding_Niaunyu_D (Arunachal Pradesh)",
        FacilityCity: "Longding",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11695,
        Pin: 143412,
        DispatchCenter: "Patti_TarnTaran_D (Punjab)",
        OriginCenter: "Patti_TarnTaran_D (Punjab)",
        FacilityCity: "Patti",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11696,
        Pin: 573162,
        DispatchCenter: "Hassan_Dudda_D (Karnataka)",
        OriginCenter: "Hassan_Dudda_D (Karnataka)",
        FacilityCity: "Hassan",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11697,
        Pin: 522036,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11698,
        Pin: 401605,
        DispatchCenter: "Kudus_Shele_D (Maharashtra)",
        OriginCenter: "Kudus_Shele_D (Maharashtra)",
        FacilityCity: "Kudus",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11699,
        Pin: 491331,
        DispatchCenter: "Berla_CommunityCntr_DPP (Chhattisgarh)",
        OriginCenter: "Berla_CommunityCntr_DPP (Chhattisgarh)",
        FacilityCity: "Berla",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11700,
        Pin: 361250,
        DispatchCenter: "Dhrol_PGVCLOfc_D (Gujarat)",
        OriginCenter: "Dhrol_PGVCLOfc_D (Gujarat)",
        FacilityCity: "Dhrol",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11701,
        Pin: 362260,
        DispatchCenter: "Mendarda_GopalNagar_DPP (Gujarat)",
        OriginCenter: "Mendarda_GopalNagar_DPP (Gujarat)",
        FacilityCity: "Mendarda",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11702,
        Pin: 251316,
        DispatchCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        OriginCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        FacilityCity: "Muzaffarnagar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11703,
        Pin: 403508,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11704,
        Pin: 274703,
        DispatchCenter: "Salempur_BhatparRani_D (Uttar Pradesh)",
        OriginCenter: "Salempur_BhatparRani_D (Uttar Pradesh)",
        FacilityCity: "Salempur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11705,
        Pin: 695010,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11706,
        Pin: 500078,
        DispatchCenter: "Hyderabad_Medchal1_L (Telangana)",
        OriginCenter: "Hyderabad_Medchal_GW (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11707,
        Pin: 400004,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11708,
        Pin: 781012,
        DispatchCenter: "Guwahati_Kaikchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11709,
        Pin: 793119,
        DispatchCenter: "Nongstoin_Postoffice_D (Meghalaya)",
        OriginCenter: "Nongstoin_Postoffice_D (Meghalaya)",
        FacilityCity: "Nongstoin",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11710,
        Pin: 583126,
        DispatchCenter: "Kudligi_Chikkajogihalli_D (Karnataka)",
        OriginCenter: "Kudligi_Chikkajogihalli_D (Karnataka)",
        FacilityCity: "Kudligi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11711,
        Pin: 783124,
        DispatchCenter: "Goalpara_Dudhnoi_D (Assam)",
        OriginCenter: "Goalpara_Dudhnoi_D (Assam)",
        FacilityCity: "Goalpara",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11712,
        Pin: 854312,
        DispatchCenter: "Araria_BusStand_D (Bihar)",
        OriginCenter: "Araria_BusStand_D (Bihar)",
        FacilityCity: "Araria",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11713,
        Pin: 360578,
        DispatchCenter: "Porbandar_Vadiplot_D (Gujarat)",
        OriginCenter: "Porbandar_Vadiplot_D (Gujarat)",
        FacilityCity: "Porbandar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11714,
        Pin: 306104,
        DispatchCenter: "Sojat_RendriCircle_DPP (Rajasthan)",
        OriginCenter: "Sojat_RendriCircle_DPP (Rajasthan)",
        FacilityCity: "Sojat",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11715,
        Pin: 670702,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11716,
        Pin: 441201,
        DispatchCenter: "Pauni_Wahi_D (Maharashtra)",
        OriginCenter: "Pauni_Wahi_D (Maharashtra)",
        FacilityCity: "Pauni",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11717,
        Pin: 110503,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11718,
        Pin: 283114,
        DispatchCenter: "Fatehabad_Bah_D (Uttar Pradesh)",
        OriginCenter: "Fatehabad_Bah_D (Uttar Pradesh)",
        FacilityCity: "Fatehabad-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11719,
        Pin: 734216,
        DispatchCenter: "Pokhriabong_PostOffice_DPP (West Bengal)",
        OriginCenter: "Pokhriabong_PostOffice_DPP (West Bengal)",
        FacilityCity: "Pokhriabong",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11720,
        Pin: 465661,
        DispatchCenter: "Rajgarh_Bhanwarcolony_D (Madhya Pradesh)",
        OriginCenter: "Rajgarh_Bhanwarcolony_D (Madhya Pradesh)",
        FacilityCity: "Rajgarh-MP",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11721,
        Pin: 627423,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11722,
        Pin: 423203,
        DispatchCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        OriginCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        FacilityCity: "Malegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11723,
        Pin: 580025,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11724,
        Pin: 845302,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11725,
        Pin: 403713,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11726,
        Pin: 410613,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11727,
        Pin: 591125,
        DispatchCenter: "Bailhongal_Azadnagar_D (Karnataka)",
        OriginCenter: "Bailhongal_Azadnagar_D (Karnataka)",
        FacilityCity: "Bailhongal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11728,
        Pin: 229124,
        DispatchCenter: "Salon_Bazar_DPP (Uttar Pradesh)",
        OriginCenter: "Salon_Bazar_DPP (Uttar Pradesh)",
        FacilityCity: "Salon",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11729,
        Pin: 454448,
        DispatchCenter: "Manawar_MPEBOffice_DPP (Madhya Pradesh)",
        OriginCenter: "Manawar_MPEBOffice_DPP (Madhya Pradesh)",
        FacilityCity: "Manawar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11730,
        Pin: 497559,
        DispatchCenter: "Chirimiri_HaldiBadi_DPP (Chhattisgarh)",
        OriginCenter: "Chirimiri_HaldiBadi_DPP (Chhattisgarh)",
        FacilityCity: "Chirimiri",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11731,
        Pin: 600022,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11732,
        Pin: 788126,
        DispatchCenter: "Pailapool_Lagktlbocpr_D (Assam)",
        OriginCenter: "Pailapool_Lagktlbocpr_D (Assam)",
        FacilityCity: "Pailapool",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11733,
        Pin: 400102,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11734,
        Pin: 631212,
        DispatchCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11735,
        Pin: 832304,
        DispatchCenter: "Ghatshila_Ekramcolony_D (Jharkhand)",
        OriginCenter: "Ghatshila_Ekramcolony_D (Jharkhand)",
        FacilityCity: "Ghatshila",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11736,
        Pin: 303908,
        DispatchCenter: "Chaksu_Arihantkunj_D (Rajasthan)",
        OriginCenter: "Chaksu_Arihantkunj_D (Rajasthan)",
        FacilityCity: "Chaksu",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11737,
        Pin: 142046,
        DispatchCenter: "Bagha_Samalsar_D (Punjab)",
        OriginCenter: "Bagha_Samalsar_D (Punjab)",
        FacilityCity: "Bagha purana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11738,
        Pin: 824230,
        DispatchCenter: "Gaya_Matihani_H (Bihar)",
        OriginCenter: "Gaya_Matihani_H (Bihar)",
        FacilityCity: "Gaya",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11739,
        Pin: 570015,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11740,
        Pin: 678683,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11741,
        Pin: 277219,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11742,
        Pin: 160018,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11743,
        Pin: 411004,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11744,
        Pin: 394716,
        DispatchCenter: "Ahwa_Panchlicolony_DPP (Gujarat)",
        OriginCenter: "Ahwa_Panchlicolony_DPP (Gujarat)",
        FacilityCity: "Ahwa",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11745,
        Pin: 470227,
        DispatchCenter: "Rehli_Ward3_D (Madhya Pradesh)",
        OriginCenter: "Rehli_Ward3_D (Madhya Pradesh)",
        FacilityCity: "Rehli",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11746,
        Pin: 761016,
        DispatchCenter: "Mohana_Betarsingi_D (Orissa)",
        OriginCenter: "Mohana_Betarsingi_D (Orissa)",
        FacilityCity: "Mohana",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11747,
        Pin: 445401,
        DispatchCenter: "Yavatmal_JajuDPP_D (Maharashtra)",
        OriginCenter: "Yavatmal_JajuDPP_D (Maharashtra)",
        FacilityCity: "Yavatmal",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11748,
        Pin: 713205,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11749,
        Pin: 785643,
        DispatchCenter: "Sivasagar_Pdinnagar_D (Assam)",
        OriginCenter: "Sivasagar_Pdinnagar_D (Assam)",
        FacilityCity: "Sivasagar",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11750,
        Pin: 574143,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11751,
        Pin: 682039,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11752,
        Pin: 573135,
        DispatchCenter: "Chnnryptna_MysoreRd_D (Karnataka)",
        OriginCenter: "Chnnryptna_MysoreRd_D (Karnataka)",
        FacilityCity: "Channarayapatna",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11753,
        Pin: 160079,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11754,
        Pin: 623211,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11755,
        Pin: 612804,
        DispatchCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        OriginCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11756,
        Pin: 144620,
        DispatchCenter: "Kapurthala_GreaterKailsh_D (Punjab)",
        OriginCenter: "Kapurthala_GreaterKailsh_D (Punjab)",
        FacilityCity: "Kapurthala",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11757,
        Pin: 678102,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11758,
        Pin: 283207,
        DispatchCenter: "Firozabad_MahadeoNagar_D (Uttar Pradesh)",
        OriginCenter: "Firozabad_MahadeoNagar_D (Uttar Pradesh)",
        FacilityCity: "Firozabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11759,
        Pin: 783302,
        DispatchCenter: "Dhubri_Tetultol_D (Assam)",
        OriginCenter: "Dhubri_Tetultol_D (Assam)",
        FacilityCity: "Dhubri",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11760,
        Pin: 394410,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11761,
        Pin: 516227,
        DispatchCenter: "Badvel_Vidyanagar_D (Andhra Pradesh)",
        OriginCenter: "Badvel_Vidyanagar_D (Andhra Pradesh)",
        FacilityCity: "Badvel",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11762,
        Pin: 580001,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11763,
        Pin: 623406,
        DispatchCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        OriginCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        FacilityCity: "Thiruvadanai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11764,
        Pin: 247453,
        DispatchCenter: "Nanauta_MaharanaPratapColony_DPP (Uttar Pradesh)",
        OriginCenter: "Nanauta_MaharanaPratapColony_DPP (Uttar Pradesh)",
        FacilityCity: "Nanauta",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11765,
        Pin: 431808,
        DispatchCenter: "Dharmabad_BalajiNgr_D (Maharashtra)",
        OriginCenter: "Dharmabad_BalajiNgr_D (Maharashtra)",
        FacilityCity: "Dharmabad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11766,
        Pin: 532214,
        DispatchCenter: "Pathapatnam_Kotaguddi_D (Andhra Pradesh)",
        OriginCenter: "Pathapatnam_Kotaguddi_D (Andhra Pradesh)",
        FacilityCity: "Pathapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11767,
        Pin: 516162,
        DispatchCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11768,
        Pin: 431101,
        DispatchCenter: "Aurangabad_Hamalwadi_DPP (Maharashtra)",
        OriginCenter: "Aurangabad_Hamalwadi_DPP (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11769,
        Pin: 473865,
        DispatchCenter: "Dabra_Bhitarwar_D (Madhya Pradesh)",
        OriginCenter: "Dabra_Bhitarwar_D (Madhya Pradesh)",
        FacilityCity: "Dabra",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 11770,
        Pin: 783381,
        DispatchCenter: "Bongaigaon_Chapaguri_I (Assam)",
        OriginCenter: "Bongaigaon_Chapaguri_I (Assam)",
        FacilityCity: "Bongaigaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11771,
        Pin: 781373,
        DispatchCenter: "Tamulpur_Kalakuchi_D (Assam)",
        OriginCenter: "Tamulpur_Kalakuchi_D (Assam)",
        FacilityCity: "Tamulpur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11772,
        Pin: 370405,
        DispatchCenter: "Mundra_Umiyanagar_D (Gujarat)",
        OriginCenter: "Mundra_Umiyanagar_D (Gujarat)",
        FacilityCity: "Mundra",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11773,
        Pin: 477116,
        DispatchCenter: "Bhind_Mehgaon_D (Madhya Pradesh)",
        OriginCenter: "Bhind_Mehgaon_D (Madhya Pradesh)",
        FacilityCity: "Bhind",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11774,
        Pin: 612301,
        DispatchCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        OriginCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11775,
        Pin: 173021,
        DispatchCenter: "PaontaSahib_Suryacolony_D (Himachal Pradesh)",
        OriginCenter: "PaontaSahib_Suryacolony_D (Himachal Pradesh)",
        FacilityCity: "Paonta Sahib",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11776,
        Pin: 712426,
        DispatchCenter: "Ilipur_Dhanyahana_D (West Bengal)",
        OriginCenter: "Ilipur_Dhanyahana_D (West Bengal)",
        FacilityCity: "Ilipur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11777,
        Pin: 413249,
        DispatchCenter: "Beed_Raimoha_DPP (Maharashtra)",
        OriginCenter: "Beed_Raimoha_DPP (Maharashtra)",
        FacilityCity: "Dongarkinhi",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11778,
        Pin: 670507,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11779,
        Pin: 473101,
        DispatchCenter: "Guna_Aron_DPP (Madhya Pradesh)",
        OriginCenter: "Guna_Aron_DPP (Madhya Pradesh)",
        FacilityCity: "Guna",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 11780,
        Pin: 583103,
        DispatchCenter: "Bellary_Mundargi_I (Karnataka)",
        OriginCenter: "Bellary_Mundargi_I (Karnataka)",
        FacilityCity: "Bellary",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11781,
        Pin: 835304,
        DispatchCenter: "Lohardaga_Devcolony_D (Jharkhand)",
        OriginCenter: "Lohardaga_Devcolony_D (Jharkhand)",
        FacilityCity: "Lohardaga",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11782,
        Pin: 247778,
        DispatchCenter: "Shamli_SalekChandVihar_D (Uttar Pradesh)",
        OriginCenter: "Shamli_SalekChandVihar_D (Uttar Pradesh)",
        FacilityCity: "Shamli",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11783,
        Pin: 497224,
        DispatchCenter: "Kusmi_Kanjiya_D (Chhattisgarh)",
        OriginCenter: "Kusmi_Kanjiya_D (Chhattisgarh)",
        FacilityCity: "Kusmi",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11784,
        Pin: 247666,
        DispatchCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        OriginCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        FacilityCity: "Haridwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11785,
        Pin: 768101,
        DispatchCenter: "Sambalpur_Tentelpara_H (Orissa)",
        OriginCenter: "Sambalpur_Tentelpara_H (Orissa)",
        FacilityCity: "Sambalpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11786,
        Pin: 133301,
        DispatchCenter: "Parwanoo_Sector2_D (Himachal Pradesh)",
        OriginCenter: "Parwanoo_Sector2_D (Himachal Pradesh)",
        FacilityCity: "Parwanoo",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11787,
        Pin: 141015,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11788,
        Pin: 301408,
        DispatchCenter: "RajgarhRJ_KarothRd_D (Rajasthan)",
        OriginCenter: "RajgarhRJ_KarothRd_D (Rajasthan)",
        FacilityCity: "Rajgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11789,
        Pin: 686513,
        DispatchCenter: "Mundakayam_Varikkani_D (Kerala)",
        OriginCenter: "Mundakayam_Varikkani_D (Kerala)",
        FacilityCity: "Mundakayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11790,
        Pin: 515134,
        DispatchCenter: "Puttaprthi_Gokulam_D (Andhra Pradesh)",
        OriginCenter: "Puttaprthi_Gokulam_D (Andhra Pradesh)",
        FacilityCity: "Puttaparthi",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11791,
        Pin: 713375,
        DispatchCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        OriginCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        FacilityCity: "Rupnarayanpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11792,
        Pin: 751010,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11793,
        Pin: 690565,
        DispatchCenter: "Irinjlkuda_Pothstherry_D (Kerala)",
        OriginCenter: "Irinjlkuda_Pothstherry_D (Kerala)",
        FacilityCity: "Irinjalakuda",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11794,
        Pin: 713201,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11795,
        Pin: 798612,
        DispatchCenter: "Tuensang_NSTColony_D (Nagaland)",
        OriginCenter: "Tuensang_NSTColony_D (Nagaland)",
        FacilityCity: "Tuensang",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11796,
        Pin: 416005,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11797,
        Pin: 262531,
        DispatchCenter: "Berinag_Dhanoli_DPP (Uttarakhand)",
        OriginCenter: "Berinag_Dhanoli_DPP (Uttarakhand)",
        FacilityCity: "Berinag",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11798,
        Pin: 586220,
        DispatchCenter: "Sindagi_Bijapurrd_D (Karnataka)",
        OriginCenter: "Sindagi_Bijapurrd_D (Karnataka)",
        FacilityCity: "Sindagi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11799,
        Pin: 303508,
        DispatchCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        OriginCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        FacilityCity: "Bandikui",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11800,
        Pin: 623603,
        DispatchCenter: "Perunali_Sayalkudi_D (Tamil Nadu)",
        OriginCenter: "Perunali_Sayalkudi_D (Tamil Nadu)",
        FacilityCity: "Perunali",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11801,
        Pin: 400032,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11802,
        Pin: 211001,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11803,
        Pin: 815305,
        DispatchCenter: "Giridih_Mahthadih_D (Jharkhand)",
        OriginCenter: "Giridih_Mahthadih_D (Jharkhand)",
        FacilityCity: "Giridih",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11804,
        Pin: 243639,
        DispatchCenter: "Budaun_KrishnaPuriRd_D (Uttar Pradesh)",
        OriginCenter: "Budaun_KrishnaPuriRd_D (Uttar Pradesh)",
        FacilityCity: "Budaun",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11805,
        Pin: 416112,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11806,
        Pin: 380023,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11807,
        Pin: 176213,
        DispatchCenter: "Dharamshala_GrudwrRd_D (Himachal Pradesh)",
        OriginCenter: "Dharamshala_GrudwrRd_D (Himachal Pradesh)",
        FacilityCity: "Dharamshala",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11808,
        Pin: 110078,
        DispatchCenter: "Delhi_BamnoliVillage_L (Delhi)",
        OriginCenter: "Delhi_BamnoliVillage_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11809,
        Pin: 403723,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11810,
        Pin: 700163,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11811,
        Pin: 400082,
        DispatchCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        OriginCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        FacilityCity: "Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11812,
        Pin: 713169,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11813,
        Pin: 148104,
        DispatchCenter: "Raikot_DC (Punjab)",
        OriginCenter: "Raikot_DC (Punjab)",
        FacilityCity: "Raikot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11814,
        Pin: 506104,
        DispatchCenter: "Mahabubabad_SnehaNagar_D (Telangana)",
        OriginCenter: "Mahabubabad_SnehaNagar_D (Telangana)",
        FacilityCity: "Mahabubabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11815,
        Pin: 535003,
        DispatchCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        OriginCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        FacilityCity: "Vizianagaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11816,
        Pin: 638115,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11817,
        Pin: 783121,
        DispatchCenter: "Goalpara_Baladmri_D (Assam)",
        OriginCenter: "Goalpara_Baladmri_D (Assam)",
        FacilityCity: "Goalpara",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11818,
        Pin: 534003,
        DispatchCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        OriginCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        FacilityCity: "Eluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11819,
        Pin: 848109,
        DispatchCenter: "Samastipur_RhmprRdli_D (Bihar)",
        OriginCenter: "Samastipur_RhmprRdli_D (Bihar)",
        FacilityCity: "Samastipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11820,
        Pin: 262725,
        DispatchCenter: "Lakhimpur_FunMall_D (Uttar Pradesh)",
        OriginCenter: "Lakhimpur_FunMall_D (Uttar Pradesh)",
        FacilityCity: "Lakhimpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11821,
        Pin: 517583,
        DispatchCenter: "Puttur_Artmclny_D (Andhra Pradesh)",
        OriginCenter: "Puttur_Artmclny_D (Andhra Pradesh)",
        FacilityCity: "Puttur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11822,
        Pin: 413712,
        DispatchCenter: "Loni_BailBzr_DPP (Maharashtra)",
        OriginCenter: "Loni_BailBzr_DPP (Maharashtra)",
        FacilityCity: "Loni",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11823,
        Pin: 522009,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11824,
        Pin: 683557,
        DispatchCenter: "Perumbavoor_Vattakattupady_D (Kerala)",
        OriginCenter: "Perumbavoor_Vattakattupady_D (Kerala)",
        FacilityCity: "Perumbavoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11825,
        Pin: 581317,
        DispatchCenter: "Karwar_Sadashivgad_D (Karnataka)",
        OriginCenter: "Karwar_Sadashivgad_D (Karnataka)",
        FacilityCity: "Karwar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11826,
        Pin: 711205,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11827,
        Pin: 673634,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11828,
        Pin: 680565,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11829,
        Pin: 283142,
        DispatchCenter: "Shikohabad_Vijaynagar_D (Uttar Pradesh)",
        OriginCenter: "Shikohabad_Vijaynagar_D (Uttar Pradesh)",
        FacilityCity: "Shikohabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11830,
        Pin: 679537,
        DispatchCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        OriginCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        FacilityCity: "Sankaramangalam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11831,
        Pin: 531028,
        DispatchCenter: "Chodavaram_Narasayyapeta_D (Andhra Pradesh)",
        OriginCenter: "Chodavaram_Narasayyapeta_D (Andhra Pradesh)",
        FacilityCity: "Chodavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11832,
        Pin: 622505,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11833,
        Pin: 247232,
        DispatchCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        OriginCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        FacilityCity: "Saharanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11834,
        Pin: 312207,
        DispatchCenter: "Chittaurgarh_Singhpur_DPP (Rajasthan)",
        OriginCenter: "Chittaurgarh_Singhpur_DPP (Rajasthan)",
        FacilityCity: "Chittaurgarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11835,
        Pin: 804427,
        DispatchCenter: "Makhdumpur_Nawabganj_D (Bihar)",
        OriginCenter: "Makhdumpur_Nawabganj_D (Bihar)",
        FacilityCity: "Makhdumpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11836,
        Pin: 843126,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11837,
        Pin: 224181,
        DispatchCenter: "Rajesultanpur_PadumprMkt_D (Uttar Pradesh)",
        OriginCenter: "Rajesultanpur_PadumprMkt_D (Uttar Pradesh)",
        FacilityCity: "Rajesultanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11838,
        Pin: 177039,
        DispatchCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        OriginCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        FacilityCity: "Bangana",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11839,
        Pin: 691300,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11840,
        Pin: 517644,
        DispatchCenter: "Sriklahsti_SDKNgr_D (Andhra Pradesh)",
        OriginCenter: "Sriklahsti_SDKNgr_D (Andhra Pradesh)",
        FacilityCity: "Srikalahasti",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11841,
        Pin: 670018,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11842,
        Pin: 793102,
        DispatchCenter: "Nongpoh_Phmsyiem_D (Meghalaya)",
        OriginCenter: "Nongpoh_Phmsyiem_D (Meghalaya)",
        FacilityCity: "Nongpoh",
        FacilityState: "Meghalaya",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11843,
        Pin: 796015,
        DispatchCenter: "Aizawl_Sairang_I (Mizoram)",
        OriginCenter: "Aizawl_Sairang_I (Mizoram)",
        FacilityCity: "Aizawl",
        FacilityState: "Mizoram",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11844,
        Pin: 223105,
        DispatchCenter: "Shahganj_RudhauliKalan_D (Uttar Pradesh)",
        OriginCenter: "Shahganj_RudhauliKalan_D (Uttar Pradesh)",
        FacilityCity: "Shahganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11845,
        Pin: 743221,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11846,
        Pin: 400609,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11847,
        Pin: 680615,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11848,
        Pin: 305629,
        DispatchCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        OriginCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        FacilityCity: "Bijainagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11849,
        Pin: 283151,
        DispatchCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        OriginCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        FacilityCity: "Shikohabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11850,
        Pin: 781126,
        DispatchCenter: "Hajo_Baruabari_D (Assam)",
        OriginCenter: "Hajo_Baruabari_D (Assam)",
        FacilityCity: "Hajo",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11851,
        Pin: 457551,
        DispatchCenter: "Ratlam_Sailana_D (Madhya Pradesh)",
        OriginCenter: "Ratlam_Sailana_D (Madhya Pradesh)",
        FacilityCity: "Ratlam",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 11852,
        Pin: 391152,
        DispatchCenter: "Nasvadi_KNRd_DPP (Gujarat)",
        OriginCenter: "Nasvadi_KNRd_DPP (Gujarat)",
        FacilityCity: "Nasvadi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11853,
        Pin: 486005,
        DispatchCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        OriginCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        FacilityCity: "Rewa",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 11854,
        Pin: 441202,
        DispatchCenter: "Umred_Narsala_D (Maharashtra)",
        OriginCenter: "Umred_Narsala_D (Maharashtra)",
        FacilityCity: "Umred",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11855,
        Pin: 600057,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11856,
        Pin: 603111,
        DispatchCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        OriginCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        FacilityCity: "Chengalpattu",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11857,
        Pin: 507170,
        DispatchCenter: "Khammam_Mudigonda_H (Telangana)",
        OriginCenter: "Khammam_Mudigonda_H (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11858,
        Pin: 784028,
        DispatchCenter: "Tezpur_Dipota_H (Assam)",
        OriginCenter: "Tezpur_Dipota_H (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11859,
        Pin: 444707,
        DispatchCenter: "Morshi_Samarthacolony_D (Maharashtra)",
        OriginCenter: "Morshi_Samarthacolony_D (Maharashtra)",
        FacilityCity: "Morshi",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11860,
        Pin: 688530,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11861,
        Pin: 517390,
        DispatchCenter: "BKothakota_RTCStop_D (Andhra Pradesh)",
        OriginCenter: "BKothakota_RTCStop_D (Andhra Pradesh)",
        FacilityCity: "B Kothakota",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11862,
        Pin: 141008,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11863,
        Pin: 786170,
        DispatchCenter: "Tinsukia_Nokhroygaon2_D (Assam)",
        OriginCenter: "Tinsukia_Nokhroygaon2_D (Assam)",
        FacilityCity: "Tinsukia",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11864,
        Pin: 587154,
        DispatchCenter: "Ilkal_Ward4_D (Karnataka)",
        OriginCenter: "Ilkal_Ward4_D (Karnataka)",
        FacilityCity: "Ilkal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11865,
        Pin: 781032,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11866,
        Pin: 486448,
        DispatchCenter: "Semariya_Shuklacolony_DPP (Madhya Pradesh)",
        OriginCenter: "Semariya_Shuklacolony_DPP (Madhya Pradesh)",
        FacilityCity: "Semariya",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 11867,
        Pin: 201316,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11868,
        Pin: 522330,
        DispatchCenter: "Tenali_Duggirala_D (Andhra Pradesh)",
        OriginCenter: "Tenali_Duggirala_D (Andhra Pradesh)",
        FacilityCity: "Tenali",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11869,
        Pin: 700112,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11870,
        Pin: 620002,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11871,
        Pin: 560081,
        DispatchCenter: "Bangalore_BmsdraPhase3_L (Karnataka)",
        OriginCenter: "Bangalore_BmsdraPhase3_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11872,
        Pin: 263151,
        DispatchCenter: "Sitarganj_Bijti_D (Uttarakhand)",
        OriginCenter: "Sitarganj_Bijti_D (Uttarakhand)",
        FacilityCity: "Sitarganj",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11873,
        Pin: 231306,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11874,
        Pin: 416105,
        DispatchCenter: "Jaysingpur_Swapnanagari_D (Maharashtra)",
        OriginCenter: "Jaysingpur_Swapnanagari_D (Maharashtra)",
        FacilityCity: "Jaysingpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11875,
        Pin: 522501,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11876,
        Pin: 523113,
        DispatchCenter: "Kandukur_SanthoshNagar_D (Andhra Pradesh)",
        OriginCenter: "Kandukur_SanthoshNagar_D (Andhra Pradesh)",
        FacilityCity: "Kandukur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11877,
        Pin: 591212,
        DispatchCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        OriginCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        FacilityCity: "Athani",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11878,
        Pin: 638501,
        DispatchCenter: "Anthiyur_Thavittuplym_DPP (Tamil Nadu)",
        OriginCenter: "Anthiyur_Thavittuplym_DPP (Tamil Nadu)",
        FacilityCity: "Anthiyour",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11879,
        Pin: 841243,
        DispatchCenter: "Mairwa_StationRd_D (Bihar)",
        OriginCenter: "Mairwa_StationRd_D (Bihar)",
        FacilityCity: "Mairwa",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11880,
        Pin: 110051,
        DispatchCenter: "Delhi_RailYardRd_L (Delhi)",
        OriginCenter: "Delhi_RailYardRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11881,
        Pin: 248142,
        DispatchCenter: "Herbertpur_Dhkrni_D (Uttarakhand)",
        OriginCenter: "Herbertpur_Dhkrni_D (Uttarakhand)",
        FacilityCity: "Herbertpur",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11882,
        Pin: 577175,
        DispatchCenter: "Kadur_UBRoad_D (Karnataka)",
        OriginCenter: "Kadur_UBRoad_D (Karnataka)",
        FacilityCity: "Kadur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11883,
        Pin: 801108,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_PhulwariSharif_L (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11884,
        Pin: 522616,
        DispatchCenter: "Chilakaluripet_ChrlesChrch_DPP (Andhra Pradesh)",
        OriginCenter: "Chilakaluripet_ChrlesChrch_DPP (Andhra Pradesh)",
        FacilityCity: "Chilakaluripet",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11885,
        Pin: 785014,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11886,
        Pin: 403507,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11887,
        Pin: 754022,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11888,
        Pin: 795008,
        DispatchCenter: "Imphal_Takyel_I (Manipur)",
        OriginCenter: "Imphal_Takyel_I (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11889,
        Pin: 759125,
        DispatchCenter: "Puranakatak_Athmallik_D (Orissa)",
        OriginCenter: "Puranakatak_Athmallik_D (Orissa)",
        FacilityCity: "Puranakatak",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11890,
        Pin: 581338,
        DispatchCenter: "Honnavar_ForestColony_D (Karnataka)",
        OriginCenter: "Honnavar_ForestColony_D (Karnataka)",
        FacilityCity: "Honnavar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11891,
        Pin: 518596,
        DispatchCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        OriginCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        FacilityCity: "Nandyal",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11892,
        Pin: 670691,
        DispatchCenter: "Kuthuparamba_IdstrlAr_D (Kerala)",
        OriginCenter: "Kuthuparamba_IdstrlAr_D (Kerala)",
        FacilityCity: "Kuthuparamba",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11893,
        Pin: 764038,
        DispatchCenter: "Koraput_Balda_DPP (Orissa)",
        OriginCenter: "Koraput_Balda_DPP (Orissa)",
        FacilityCity: "Koraput",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11894,
        Pin: 768216,
        DispatchCenter: "Brajrajnagar_Jagannath_DPP (Orissa)",
        OriginCenter: "Brajrajnagar_Jagannath_DPP (Orissa)",
        FacilityCity: "Brajrajnagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11895,
        Pin: 441502,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11896,
        Pin: 736176,
        DispatchCenter: "Dinhata_BhdiKhda_DPP (West Bengal)",
        OriginCenter: "Dinhata_BhdiKhda_DPP (West Bengal)",
        FacilityCity: "Dinhata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11897,
        Pin: 521110,
        DispatchCenter: "HanumanJn_Appanaveedu_D (Andhra Pradesh)",
        OriginCenter: "HanumanJn_Appanaveedu_D (Andhra Pradesh)",
        FacilityCity: "Pedapadu",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11898,
        Pin: 394307,
        DispatchCenter: "Valsad_LimbaChwk_D (Gujarat)",
        OriginCenter: "Valsad_LimbaChwk_D (Gujarat)",
        FacilityCity: "Valsad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11899,
        Pin: 788011,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11900,
        Pin: 761003,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11901,
        Pin: 786184,
        DispatchCenter: "Chabua_CollgeRD_D (Assam)",
        OriginCenter: "Chabua_CollgeRD_D (Assam)",
        FacilityCity: "Chabua",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11902,
        Pin: 110003,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11903,
        Pin: 201018,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        Zone : "N1"
      },
      {
        SN: 11904,
        Pin: 751009,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11905,
        Pin: 770022,
        DispatchCenter: "Bamra_Govindpur_D (Orissa)",
        OriginCenter: "Bamra_Govindpur_D (Orissa)",
        FacilityCity: "Bamra",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11906,
        Pin: 146022,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 11907,
        Pin: 533429,
        DispatchCenter: "Yerravaram_Somavaram_D (Andhra Pradesh)",
        OriginCenter: "Yerravaram_Somavaram_D (Andhra Pradesh)",
        FacilityCity: "Yerravaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11908,
        Pin: 271311,
        DispatchCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        OriginCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        FacilityCity: "Colonejganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11909,
        Pin: 509202,
        DispatchCenter: "Shadnagar_Balanagar_D (Telangana)",
        OriginCenter: "Shadnagar_Balanagar_D (Telangana)",
        FacilityCity: "Shadnagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11910,
        Pin: 627360,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11911,
        Pin: 582203,
        DispatchCenter: "Rona_GadagRD_D (Karnataka)",
        OriginCenter: "Rona_GadagRD_D (Karnataka)",
        FacilityCity: "Rona",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11912,
        Pin: 734317,
        DispatchCenter: "Kalimpong_Ward22_D (West Bengal)",
        OriginCenter: "Kalimpong_Ward22_D (West Bengal)",
        FacilityCity: "Kalimpong",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11913,
        Pin: 364110,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11914,
        Pin: 756131,
        DispatchCenter: "Tihidi_Hanspat_DPP (Orissa)",
        OriginCenter: "Tihidi_Hanspat_DPP (Orissa)",
        FacilityCity: "Tihidi",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11915,
        Pin: 503322,
        DispatchCenter: "Nizamabad_Sarangapur_I (Telangana)",
        OriginCenter: "Nizamabad_Sarangapur_I (Telangana)",
        FacilityCity: "Nizamabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11916,
        Pin: 670701,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11917,
        Pin: 471515,
        DispatchCenter: "LavkushNagar_Patti_D (Madhya Pradesh)",
        OriginCenter: "LavkushNagar_Patti_D (Madhya Pradesh)",
        FacilityCity: "Lavkush Nagar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11918,
        Pin: 400085,
        DispatchCenter: "Mumbai_Chembur_L (Maharashtra)",
        OriginCenter: "Mumbai_Chembur_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11919,
        Pin: 695126,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11920,
        Pin: 585306,
        DispatchCenter: "Chincholi_CKRd_D (Karnataka)",
        OriginCenter: "Chincholi_CKRd_D (Karnataka)",
        FacilityCity: "Chincholi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11921,
        Pin: 127041,
        DispatchCenter: "Meham_Ward13_D (Haryana)",
        OriginCenter: "Meham_Ward13_D (Haryana)",
        FacilityCity: "Meham",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11922,
        Pin: 686555,
        DispatchCenter: "Karukachal_Nedumkunam_D (Kerala)",
        OriginCenter: "Karukachal_Nedumkunam_D (Kerala)",
        FacilityCity: "Karukachal",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11923,
        Pin: 508254,
        DispatchCenter: "Nalgonda_Duppalapally_I (Telangana)",
        OriginCenter: "Nalgonda_Duppalapally_I (Telangana)",
        FacilityCity: "Nalgonda",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11924,
        Pin: 680518,
        DispatchCenter: "Thrissur_Chavakkad_D (Kerala)",
        OriginCenter: "Thrissur_Chavakkad_D (Kerala)",
        FacilityCity: "Guruvayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11925,
        Pin: 788815,
        DispatchCenter: "Kalain_Tarapur_D (Assam)",
        OriginCenter: "Kalain_Tarapur_D (Assam)",
        FacilityCity: "Kalain",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11926,
        Pin: 591118,
        DispatchCenter: "Kittur_Guruwarpeth_DPP (Karnataka)",
        OriginCenter: "Kittur_Guruwarpeth_DPP (Karnataka)",
        FacilityCity: "Kittur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11927,
        Pin: 801505,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11928,
        Pin: 743232,
        DispatchCenter: "Helencha_ColnyDPP_D (West Bengal)",
        OriginCenter: "Helencha_ColnyDPP_D (West Bengal)",
        FacilityCity: "Helencha",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11929,
        Pin: 712304,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11930,
        Pin: 670111,
        DispatchCenter: "Thalassery_TempleGt_D (Kerala)",
        OriginCenter: "Thalassery_TempleGt_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11931,
        Pin: 174101,
        DispatchCenter: "Baddi_Barotiwala_L (Himachal Pradesh)",
        OriginCenter: "Baddi_Barotiwala_L (Himachal Pradesh)",
        FacilityCity: "Baddi",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11932,
        Pin: 209747,
        DispatchCenter: "Chhibramau_TajpurRd_D (Uttar Pradesh)",
        OriginCenter: "Chhibramau_TajpurRd_D (Uttar Pradesh)",
        FacilityCity: "Chhibramau",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11933,
        Pin: 607401,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11934,
        Pin: 487225,
        DispatchCenter: "Narsinghpur_Kareli_DPP (Madhya Pradesh)",
        OriginCenter: "Narsinghpur_Kareli_DPP (Madhya Pradesh)",
        FacilityCity: "Kareli",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 11935,
        Pin: 852124,
        DispatchCenter: "Saharsa_NayaBzar_D (Bihar)",
        OriginCenter: "Saharsa_NayaBzar_D (Bihar)",
        FacilityCity: "Saharsa",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11936,
        Pin: 570032,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11937,
        Pin: 222104,
        DispatchCenter: "Shahganj_Kaudiya_D (Uttar Pradesh)",
        OriginCenter: "Shahganj_Kaudiya_D (Uttar Pradesh)",
        FacilityCity: "Shahganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11938,
        Pin: 632209,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11939,
        Pin: 516312,
        DispatchCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        OriginCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        FacilityCity: "Proddatur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11940,
        Pin: 734403,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11941,
        Pin: 621719,
        DispatchCenter: "Pennadam_Puthar_D (Tamil Nadu)",
        OriginCenter: "Pennadam_Puthar_D (Tamil Nadu)",
        FacilityCity: "Pennadam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11942,
        Pin: 505326,
        DispatchCenter: "Koratla_PRBM_DPP (Telangana)",
        OriginCenter: "Koratla_PRBM_DPP (Telangana)",
        FacilityCity: "Koratla",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11943,
        Pin: 415505,
        DispatchCenter: "Vaduj_Satewdi_D (Maharashtra)",
        OriginCenter: "Vaduj_Satewdi_D (Maharashtra)",
        FacilityCity: "Vaduj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11944,
        Pin: 262802,
        DispatchCenter: "Gola_BkgnRoad_D (Uttar Pradesh)",
        OriginCenter: "Gola_BkgnRoad_D (Uttar Pradesh)",
        FacilityCity: "Gola",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11945,
        Pin: 136119,
        DispatchCenter: "Kurukshetra_DDColony_D (Haryana)",
        OriginCenter: "Kurukshetra_DDColony_D (Haryana)",
        FacilityCity: "Kurukshetra",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11946,
        Pin: 614617,
        DispatchCenter: "Manamelkudi_HondaShow_D (Tamil Nadu)",
        OriginCenter: "Manamelkudi_HondaShow_D (Tamil Nadu)",
        FacilityCity: "Manamelkudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11947,
        Pin: 758014,
        DispatchCenter: "Kendujhar_Jamunalia_I (Orissa)",
        OriginCenter: "Kendujhar_Jamunalia_I (Orissa)",
        FacilityCity: "Kendujhar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11948,
        Pin: 841427,
        DispatchCenter: "Gopalganj_HarsanHosp_D (Bihar)",
        OriginCenter: "Gopalganj_HarsanHosp_D (Bihar)",
        FacilityCity: "Gopalganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11949,
        Pin: 680308,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11950,
        Pin: 412115,
        DispatchCenter: "Pune_Muktainagar_L (Maharashtra)",
        OriginCenter: "Pune_Muktainagar_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11951,
        Pin: 403410,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11952,
        Pin: 388545,
        DispatchCenter: "Anand_Borsad_D (Gujarat)",
        OriginCenter: "Anand_Borsad_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11953,
        Pin: 835229,
        DispatchCenter: "Torpa_Bourgudichowk_DPP (Jharkhand)",
        OriginCenter: "Torpa_Bourgudichowk_DPP (Jharkhand)",
        FacilityCity: "Torpa",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11954,
        Pin: 638458,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11955,
        Pin: 181207,
        DispatchCenter: "Akhnoor_MiltryHosp_D (Jammu & Kashmir)",
        OriginCenter: "Akhnoor_MiltryHosp_D (Jammu & Kashmir)",
        FacilityCity: "Akhnoor",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11956,
        Pin: 713151,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11957,
        Pin: 700133,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11958,
        Pin: 331402,
        DispatchCenter: "Sardarshahr_IndustrialArea_L (Rajasthan)",
        OriginCenter: "Sardarshahr_IndustrialArea_L (Rajasthan)",
        FacilityCity: "Sardarshahr",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11959,
        Pin: 533288,
        DispatchCenter: "Rampachodavaram_Horeb_DPP (Andhra Pradesh)",
        OriginCenter: "Rampachodavaram_Horeb_DPP (Andhra Pradesh)",
        FacilityCity: "Rampachodavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11960,
        Pin: 638116,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11961,
        Pin: 679335,
        DispatchCenter: "Cherpulassery_PryaPlda_D (Kerala)",
        OriginCenter: "Cherpulassery_PryaPlda_D (Kerala)",
        FacilityCity: "Cherpulassery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11962,
        Pin: 641669,
        DispatchCenter: "Palladam_Balajinagar_D (Tamil Nadu)",
        OriginCenter: "Palladam_Balajinagar_D (Tamil Nadu)",
        FacilityCity: "Palladam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11963,
        Pin: 751006,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 11964,
        Pin: 574222,
        DispatchCenter: "Bantwal_BambilRd_D (Karnataka)",
        OriginCenter: "Bantwal_BambilRd_D (Karnataka)",
        FacilityCity: "Bantwal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11965,
        Pin: 303105,
        DispatchCenter: "Kotputli_Amaicolony_DPP (Rajasthan)",
        OriginCenter: "Kotputli_Amaicolony_DPP (Rajasthan)",
        FacilityCity: "Kotputli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11966,
        Pin: 761018,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11967,
        Pin: 686537,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11968,
        Pin: 364710,
        DispatchCenter: "Botad_Paliyadrd_D (Gujarat)",
        OriginCenter: "Botad_Paliyadrd_D (Gujarat)",
        FacilityCity: "Botad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 11969,
        Pin: 276122,
        DispatchCenter: "Jiyanpur_GorkhpurRd_D (Uttar Pradesh)",
        OriginCenter: "Jiyanpur_GorkhpurRd_D (Uttar Pradesh)",
        FacilityCity: "Jiyanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 11970,
        Pin: 391442,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11971,
        Pin: 678706,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11972,
        Pin: 781376,
        DispatchCenter: "Baihata_Sundhia_D (Assam)",
        OriginCenter: "Baihata_Sundhia_D (Assam)",
        FacilityCity: "Baihata",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11973,
        Pin: 854205,
        DispatchCenter: "Bhawanipur_Thanachowk_D (Bihar)",
        OriginCenter: "Bhawanipur_Thanachowk_D (Bihar)",
        FacilityCity: "Bhawanipur Rajdham",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11974,
        Pin: 530030,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11975,
        Pin: 453220,
        DispatchCenter: "Badnagar_Teacherscolony_D (Madhya Pradesh)",
        OriginCenter: "Badnagar_Teacherscolony_D (Madhya Pradesh)",
        FacilityCity: "Badnagar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 11976,
        Pin: 670330,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11977,
        Pin: 394516,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 11978,
        Pin: 403703,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 11979,
        Pin: 209724,
        DispatchCenter: "Farrukhabad_RamBanquet_D (Uttar Pradesh)",
        OriginCenter: "Farrukhabad_RamBanquet_D (Uttar Pradesh)",
        FacilityCity: "Farrukhabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11980,
        Pin: 482010,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 11981,
        Pin: 518165,
        DispatchCenter: "Allagadda_Ramalayam_DPP (Andhra Pradesh)",
        OriginCenter: "Allagadda_Ramalayam_DPP (Andhra Pradesh)",
        FacilityCity: "Allagadda",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11982,
        Pin: 262534,
        DispatchCenter: "Berinag_Dhanoli_DPP (Uttarakhand)",
        OriginCenter: "Berinag_Dhanoli_DPP (Uttarakhand)",
        FacilityCity: "Berinag",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 11983,
        Pin: 843119,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11984,
        Pin: 441302,
        DispatchCenter: "Katol_Laxmingr_D (Maharashtra)",
        OriginCenter: "Katol_Laxmingr_D (Maharashtra)",
        FacilityCity: "Katol",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 11985,
        Pin: 686600,
        DispatchCenter: "Kottayam_Manjoor_D (Kerala)",
        OriginCenter: "Kottayam_Manjoor_D (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11986,
        Pin: 501203,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 11987,
        Pin: 688567,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 11988,
        Pin: 713357,
        DispatchCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        OriginCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        FacilityCity: "Rupnarayanpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11989,
        Pin: 686686,
        DispatchCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        OriginCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        FacilityCity: "Muvattupuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11990,
        Pin: 802316,
        DispatchCenter: "Arrah_Anaith_D (Bihar)",
        OriginCenter: "Arrah_Anaith_D (Bihar)",
        FacilityCity: "Arrah",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 11991,
        Pin: 533249,
        DispatchCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        OriginCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        FacilityCity: "Razole",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11992,
        Pin: 635710,
        DispatchCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        OriginCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        FacilityCity: "Tiruppattur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11993,
        Pin: 743245,
        DispatchCenter: "Bongaon_SukntDPP_D (West Bengal)",
        OriginCenter: "Bongaon_SukntDPP_D (West Bengal)",
        FacilityCity: "Bongaon",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11994,
        Pin: 679552,
        DispatchCenter: "Edappal_Nellissery_D (Kerala)",
        OriginCenter: "Edappal_Nellissery_D (Kerala)",
        FacilityCity: "Edappal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 11995,
        Pin: 712302,
        DispatchCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        OriginCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        FacilityCity: "Tarkeshwar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 11996,
        Pin: 608701,
        DispatchCenter: "Lalpet_Kattumannarkoil_D (Tamil Nadu)",
        OriginCenter: "Lalpet_Kattumannarkoil_D (Tamil Nadu)",
        FacilityCity: "Lalpet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 11997,
        Pin: 761030,
        DispatchCenter: "Kodala_Beguniapada_DPP (Orissa)",
        OriginCenter: "Kodala_Beguniapada_DPP (Orissa)",
        FacilityCity: "Kodala",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 11998,
        Pin: 221306,
        DispatchCenter: "Gopiganj_Gerai_D (Uttar Pradesh)",
        OriginCenter: "Gopiganj_Gerai_D (Uttar Pradesh)",
        FacilityCity: "Gopiganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 11999,
        Pin: 425427,
        DispatchCenter: "Shirpur_KrantiNgr_D (Maharashtra)",
        OriginCenter: "Shirpur_KrantiNgr_D (Maharashtra)",
        FacilityCity: "Shirpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12000,
        Pin: 421503,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12001,
        Pin: 581128,
        DispatchCenter: "Haveri_ManjunathNagar_D (Karnataka)",
        OriginCenter: "Haveri_ManjunathNagar_D (Karnataka)",
        FacilityCity: "Haveri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12002,
        Pin: 209722,
        DispatchCenter: "Kannauj_Kazitola_D (Uttar Pradesh)",
        OriginCenter: "Kannauj_Kazitola_D (Uttar Pradesh)",
        FacilityCity: "Kannauj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12003,
        Pin: 226023,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12004,
        Pin: 400072,
        DispatchCenter: "Mumbai_Powai_L (Maharashtra)",
        OriginCenter: "Mumbai_Powai_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12005,
        Pin: 229216,
        DispatchCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        OriginCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        FacilityCity: "Lalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12006,
        Pin: 581347,
        DispatchCenter: "Yellapur_Tatagar_DPP (Karnataka)",
        OriginCenter: "Yellapur_Tatagar_DPP (Karnataka)",
        FacilityCity: "Yellapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12007,
        Pin: 781348,
        DispatchCenter: "Nalbari_Chokbazar_D (Assam)",
        OriginCenter: "Nalbari_Chokbazar_D (Assam)",
        FacilityCity: "Nalbari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12008,
        Pin: 505490,
        DispatchCenter: "Huzurabad_Vivekanandanagar_D (Telangana)",
        OriginCenter: "Huzurabad_Vivekanandanagar_D (Telangana)",
        FacilityCity: "Huzurabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12009,
        Pin: 415518,
        DispatchCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        OriginCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        FacilityCity: "Satara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12010,
        Pin: 688512,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12011,
        Pin: 768003,
        DispatchCenter: "Sambalpur_Tentelpara_H (Orissa)",
        OriginCenter: "Sambalpur_Tentelpara_H (Orissa)",
        FacilityCity: "Sambalpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12012,
        Pin: 412201,
        DispatchCenter: "Pune_Fursungi_L (Maharashtra)",
        OriginCenter: "Pune_Fursungi_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12013,
        Pin: 690515,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12014,
        Pin: 581332,
        DispatchCenter: "Kumta_Central_DPP_2 (Karnataka)",
        OriginCenter: "Kumta_Central_DPP_2 (Karnataka)",
        FacilityCity: "Kumta",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12015,
        Pin: 756017,
        DispatchCenter: "Balasore_Ganeswarpur_D (Orissa)",
        OriginCenter: "Balasore_Ganeswarpur_D (Orissa)",
        FacilityCity: "Balasore",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12016,
        Pin: 462101,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12017,
        Pin: 415515,
        DispatchCenter: "Wai_Bavdhan_DPP (Maharashtra)",
        OriginCenter: "Wai_Bavdhan_DPP (Maharashtra)",
        FacilityCity: "Wai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12018,
        Pin: 832101,
        DispatchCenter: "Chakulia_Jogipara_D (Jharkhand)",
        OriginCenter: "Chakulia_Jogipara_D (Jharkhand)",
        FacilityCity: "Chakulia",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12019,
        Pin: 583237,
        DispatchCenter: "Kushtagi_Karimacolony_DPP (Karnataka)",
        OriginCenter: "Kushtagi_Karimacolony_DPP (Karnataka)",
        FacilityCity: "Kushtagi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12020,
        Pin: 800030,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12021,
        Pin: 690107,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12022,
        Pin: 731223,
        DispatchCenter: "Rampurhat_Mansubamore_D (West Bengal)",
        OriginCenter: "Rampurhat_Mansubamore_D (West Bengal)",
        FacilityCity: "Rampurhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12023,
        Pin: 127308,
        DispatchCenter: "Loharu_HsptlRd_D (Haryana)",
        OriginCenter: "Loharu_HsptlRd_D (Haryana)",
        FacilityCity: "Loharu",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12024,
        Pin: 209841,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12025,
        Pin: 415622,
        DispatchCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        OriginCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        FacilityCity: "Khed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12026,
        Pin: 761002,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12027,
        Pin: 325219,
        DispatchCenter: "Kawai_KhanpurRd_DPP (Rajasthan)",
        OriginCenter: "Kawai_KhanpurRd_DPP (Rajasthan)",
        FacilityCity: "Kawai",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12028,
        Pin: 312902,
        DispatchCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        OriginCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        FacilityCity: "Chittaurgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12029,
        Pin: 852213,
        DispatchCenter: "SonbarsaBH_Pirnagra_DPP (Bihar)",
        OriginCenter: "SonbarsaBH_Pirnagra_DPP (Bihar)",
        FacilityCity: "Sonbarsa-BH",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12030,
        Pin: 306115,
        DispatchCenter: "Falna_Khudala_DPP (Rajasthan)",
        OriginCenter: "Falna_Khudala_DPP (Rajasthan)",
        FacilityCity: "Falna",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12031,
        Pin: 423211,
        DispatchCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        OriginCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        FacilityCity: "Malegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12032,
        Pin: 689597,
        DispatchCenter: "Karukachal_Nedumkunam_D (Kerala)",
        OriginCenter: "Karukachal_Nedumkunam_D (Kerala)",
        FacilityCity: "Karukachal",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12033,
        Pin: 263143,
        DispatchCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        OriginCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        FacilityCity: "Rudrapur",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12034,
        Pin: 180002,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12035,
        Pin: 176025,
        DispatchCenter: "Jawali_BhaneiLubh_DPP (Himachal Pradesh)",
        OriginCenter: "Jawali_BhaneiLubh_DPP (Himachal Pradesh)",
        FacilityCity: "Jawali",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12036,
        Pin: 361160,
        DispatchCenter: "Kalavad_Khodiyarpara_D (Gujarat)",
        OriginCenter: "Kalavad_Khodiyarpara_D (Gujarat)",
        FacilityCity: "Kalavad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12037,
        Pin: 364140,
        DispatchCenter: "Talaja_Shivajinagar_D (Gujarat)",
        OriginCenter: "Talaja_Shivajinagar_D (Gujarat)",
        FacilityCity: "Talaja",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12038,
        Pin: 824205,
        DispatchCenter: "Sherghati_GayaRd_D (Bihar)",
        OriginCenter: "Sherghati_GayaRd_D (Bihar)",
        FacilityCity: "Sherghati",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12039,
        Pin: 244410,
        DispatchCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        OriginCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        FacilityCity: "Chandausi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12040,
        Pin: 389210,
        DispatchCenter: "Lunawada_VrdhriRD_D (Gujarat)",
        OriginCenter: "Lunawada_VrdhriRD_D (Gujarat)",
        FacilityCity: "Lunawada",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12041,
        Pin: 283119,
        DispatchCenter: "Kheragarh_Shivmndr_D (Uttar Pradesh)",
        OriginCenter: "Kheragarh_Shivmndr_D (Uttar Pradesh)",
        FacilityCity: "Kheragarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12042,
        Pin: 313206,
        DispatchCenter: "Fatehnagar_ITALI_DPP (Rajasthan)",
        OriginCenter: "Fatehnagar_ITALI_DPP (Rajasthan)",
        FacilityCity: "Fatehnagar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12043,
        Pin: 312404,
        DispatchCenter: "BariSadri_SudhmaColny_DPP (Rajasthan)",
        OriginCenter: "BariSadri_SudhmaColny_DPP (Rajasthan)",
        FacilityCity: "Bari Sadri",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12044,
        Pin: 273311,
        DispatchCenter: "Nichlaul_Gorahwa_D (Uttar Pradesh)",
        OriginCenter: "Nichlaul_Gorahwa_D (Uttar Pradesh)",
        FacilityCity: "Nichlaul",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12045,
        Pin: 632313,
        DispatchCenter: "Arani_PolurRd_D (Tamil Nadu)",
        OriginCenter: "Arani_PolurRd_D (Tamil Nadu)",
        FacilityCity: "Arani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12046,
        Pin: 521215,
        DispatchCenter: "Vissannapetaa_TiruvuruRd_D (Andhra Pradesh)",
        OriginCenter: "Vissannapetaa_TiruvuruRd_D (Andhra Pradesh)",
        FacilityCity: "Vissannapetaa",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12047,
        Pin: 262803,
        DispatchCenter: "Khutar_Narayanpur_D (Uttar Pradesh)",
        OriginCenter: "Khutar_Narayanpur_D (Uttar Pradesh)",
        FacilityCity: "Khutar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12048,
        Pin: 627129,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12049,
        Pin: 221406,
        DispatchCenter: "Bhadohi_Chedibeer_D (Uttar Pradesh)",
        OriginCenter: "Bhadohi_Chedibeer_D (Uttar Pradesh)",
        FacilityCity: "Bhadohi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12050,
        Pin: 691604,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12051,
        Pin: 174003,
        DispatchCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        OriginCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        FacilityCity: "Bilaspur-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12052,
        Pin: 413116,
        DispatchCenter: "Baramati_Pragatinagar_D (Maharashtra)",
        OriginCenter: "Baramati_Pragatinagar_D (Maharashtra)",
        FacilityCity: "Baramati",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12053,
        Pin: 504231,
        DispatchCenter: "Bellampalli_Rlwaycolny_DPP (Telangana)",
        OriginCenter: "Bellampalli_Rlwaycolny_DPP (Telangana)",
        FacilityCity: "Bellampalli",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12054,
        Pin: 686020,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12055,
        Pin: 642154,
        DispatchCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        OriginCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        FacilityCity: "Udumalaipettai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12056,
        Pin: 211006,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12057,
        Pin: 532484,
        DispatchCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        OriginCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        FacilityCity: "Srikakulam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12058,
        Pin: 721428,
        DispatchCenter: "Contai_Fatepur_DPP (West Bengal)",
        OriginCenter: "Contai_Fatepur_DPP (West Bengal)",
        FacilityCity: "RamnagarWB",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12059,
        Pin: 456006,
        DispatchCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        OriginCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        FacilityCity: "Ujjain",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12060,
        Pin: 611101,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12061,
        Pin: 333302,
        DispatchCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        OriginCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        FacilityCity: "Udaipurwati",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12062,
        Pin: 812006,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12063,
        Pin: 754135,
        DispatchCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        OriginCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12064,
        Pin: 759105,
        DispatchCenter: "Chhendipada_Lodhabandha_DPP (Orissa)",
        OriginCenter: "Chhendipada_Lodhabandha_DPP (Orissa)",
        FacilityCity: "Chhendipada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12065,
        Pin: 334403,
        DispatchCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        OriginCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        FacilityCity: "Bikaner",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12066,
        Pin: 415502,
        DispatchCenter: "Vaduj_Satewdi_D (Maharashtra)",
        OriginCenter: "Vaduj_Satewdi_D (Maharashtra)",
        FacilityCity: "Vaduj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12067,
        Pin: 678008,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12068,
        Pin: 576216,
        DispatchCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        OriginCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12069,
        Pin: 587103,
        DispatchCenter: "Bagalkot_NavaNgr_D (Karnataka)",
        OriginCenter: "Bagalkot_NavaNgr_D (Karnataka)",
        FacilityCity: "Bagalkot",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12070,
        Pin: 572121,
        DispatchCenter: "Koratagere_BodbndnaHlli_D (Karnataka)",
        OriginCenter: "Koratagere_BodbndnaHlli_D (Karnataka)",
        FacilityCity: "Koratagere",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12071,
        Pin: 623807,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12072,
        Pin: 612904,
        DispatchCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        OriginCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        FacilityCity: "Jayankondam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12073,
        Pin: 743132,
        DispatchCenter: "Naihati_Jagatdal_DPP (West Bengal)",
        OriginCenter: "Naihati_Jagatdal_DPP (West Bengal)",
        FacilityCity: "Naihati",
        FacilityState: "West Bengal",
        Zone : "E"
      },
      {
        SN: 12074,
        Pin: 834001,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12075,
        Pin: 523326,
        DispatchCenter: "Markapur_Kankanalapalle_DPP (Andhra Pradesh)",
        OriginCenter: "Markapur_Kankanalapalle_DPP (Andhra Pradesh)",
        FacilityCity: "Markapur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12076,
        Pin: 273411,
        DispatchCenter: "Barhalganj_Lakhnapar_DPP (Uttar Pradesh)",
        OriginCenter: "Barhalganj_Lakhnapar_DPP (Uttar Pradesh)",
        FacilityCity: "Barhalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12077,
        Pin: 124109,
        DispatchCenter: "Jhajjar_Policeline_I (Haryana)",
        OriginCenter: "Jhajjar_Policeline_I (Haryana)",
        FacilityCity: "Jhajjar",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12078,
        Pin: 781007,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12079,
        Pin: 277218,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12080,
        Pin: 485661,
        DispatchCenter: "Maihar_Harnampur_D (Madhya Pradesh)",
        OriginCenter: "Maihar_Harnampur_D (Madhya Pradesh)",
        FacilityCity: "Maihar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 12081,
        Pin: 690524,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12082,
        Pin: 695044,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12083,
        Pin: 144001,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12084,
        Pin: 441306,
        DispatchCenter: "Katol_Laxmingr_D (Maharashtra)",
        OriginCenter: "Katol_Laxmingr_D (Maharashtra)",
        FacilityCity: "Katol",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12085,
        Pin: 110016,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12086,
        Pin: 757035,
        DispatchCenter: "Raruan_Badararuan_DPP (Orissa)",
        OriginCenter: "Raruan_Badararuan_DPP (Orissa)",
        FacilityCity: "Raruan",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12087,
        Pin: 783391,
        DispatchCenter: "Bongaigaon_Chapaguri_I (Assam)",
        OriginCenter: "Bongaigaon_Chapaguri_I (Assam)",
        FacilityCity: "Bongaigaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12088,
        Pin: 523328,
        DispatchCenter: "Yrrgondplm_MainRd_DPP (Andhra Pradesh)",
        OriginCenter: "Yrrgondplm_MainRd_DPP (Andhra Pradesh)",
        FacilityCity: "Yerragonda Palem",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12089,
        Pin: 686017,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12090,
        Pin: 712137,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12091,
        Pin: 743401,
        DispatchCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        OriginCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        FacilityCity: "Basirhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12092,
        Pin: 143108,
        DispatchCenter: "Amritsar_KhurManian_D (Punjab)",
        OriginCenter: "Amritsar_KhurManian_D (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12093,
        Pin: 124508,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12094,
        Pin: 632311,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12095,
        Pin: 785673,
        DispatchCenter: "Moranhat_TengaDollong_D (Assam)",
        OriginCenter: "Moranhat_TengaDollong_D (Assam)",
        FacilityCity: "Moranhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12096,
        Pin: 458556,
        DispatchCenter: "Mandsaur_Sindpan_I (Madhya Pradesh)",
        OriginCenter: "Mandsaur_Sindpan_I (Madhya Pradesh)",
        FacilityCity: "Mandsaur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12097,
        Pin: 742165,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12098,
        Pin: 600058,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12099,
        Pin: 323025,
        DispatchCenter: "Bundi_Central_D_1 (Rajasthan)",
        OriginCenter: "Bundi_Central_D_1 (Rajasthan)",
        FacilityCity: "Bundi",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12100,
        Pin: 582114,
        DispatchCenter: "Yelburga_SurabhiColony_D (Karnataka)",
        OriginCenter: "Yelburga_SurabhiColony_D (Karnataka)",
        FacilityCity: "Yelburga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12101,
        Pin: 700100,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12102,
        Pin: 273401,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12103,
        Pin: 144211,
        DispatchCenter: "Dasuya_Hajipur_D (Punjab)",
        OriginCenter: "Dasuya_Hajipur_D (Punjab)",
        FacilityCity: "Dasuya",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12104,
        Pin: 741252,
        DispatchCenter: "Naihati_Kalyani_DPP (West Bengal)",
        OriginCenter: "Naihati_Kalyani_DPP (West Bengal)",
        FacilityCity: "Kalyani",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12105,
        Pin: 600089,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12106,
        Pin: 577573,
        DispatchCenter: "Davangere_Industrialarea_I (Karnataka)",
        OriginCenter: "Davangere_Industrialarea_I (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12107,
        Pin: 524236,
        DispatchCenter: "Pamuru_ViruvoruRd_D (Andhra Pradesh)",
        OriginCenter: "Pamuru_ViruvoruRd_D (Andhra Pradesh)",
        FacilityCity: "Pamuru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12108,
        Pin: 228001,
        DispatchCenter: "Sultanpur_Balpur_D (Uttar Pradesh)",
        OriginCenter: "Sultanpur_Balpur_D (Uttar Pradesh)",
        FacilityCity: "Sultanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12109,
        Pin: 488441,
        DispatchCenter: "Amanganj_WardNo14_DPP (Madhya Pradesh)",
        OriginCenter: "Amanganj_WardNo14_DPP (Madhya Pradesh)",
        FacilityCity: "Amanganj",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12110,
        Pin: 180016,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12111,
        Pin: 515741,
        DispatchCenter: "Kalyandurg_JayNagar_D (Andhra Pradesh)",
        OriginCenter: "Kalyandurg_JayNagar_D (Andhra Pradesh)",
        FacilityCity: "Kalyandurg",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12112,
        Pin: 766101,
        DispatchCenter: "Kesinga_Bogor_D (Orissa)",
        OriginCenter: "Kesinga_Bogor_D (Orissa)",
        FacilityCity: "Kesinga",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12113,
        Pin: 474004,
        DispatchCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        OriginCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        FacilityCity: "Gwalior",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12114,
        Pin: 636503,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12115,
        Pin: 515311,
        DispatchCenter: "Madakasira_RTCStand_D (Andhra Pradesh)",
        OriginCenter: "Madakasira_RTCStand_D (Andhra Pradesh)",
        FacilityCity: "Madakasira",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12116,
        Pin: 802351,
        DispatchCenter: "Brahmapur_Shahpur_D (Bihar)",
        OriginCenter: "Brahmapur_Shahpur_D (Bihar)",
        FacilityCity: "Brahmapur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12117,
        Pin: 209115,
        DispatchCenter: "Pukhrayan_Arulimod_D (Uttar Pradesh)",
        OriginCenter: "Pukhrayan_Arulimod_D (Uttar Pradesh)",
        FacilityCity: "Pukhrayan",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12118,
        Pin: 835205,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12119,
        Pin: 682010,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12120,
        Pin: 571174,
        DispatchCenter: "Chamarajanagar_Galipur_D (Karnataka)",
        OriginCenter: "Chamarajanagar_Galipur_D (Karnataka)",
        FacilityCity: "Chamarajanagar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12121,
        Pin: 833219,
        DispatchCenter: "Seraikella_Laharitola_D (Jharkhand)",
        OriginCenter: "Seraikella_Laharitola_D (Jharkhand)",
        FacilityCity: "Seraikella",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12122,
        Pin: 122220,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12123,
        Pin: 759021,
        DispatchCenter: "Dhenkanal_Rasol_D (Orissa)",
        OriginCenter: "Dhenkanal_Rasol_D (Orissa)",
        FacilityCity: "Dhenkanal",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12124,
        Pin: 144407,
        DispatchCenter: "Phagwara_PurewalNgr_D (Punjab)",
        OriginCenter: "Phagwara_PurewalNgr_D (Punjab)",
        FacilityCity: "Phagwara",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12125,
        Pin: 383276,
        DispatchCenter: "Modasa_Shyamnagar_D (Gujarat)",
        OriginCenter: "Modasa_Shyamnagar_D (Gujarat)",
        FacilityCity: "Modasa",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12126,
        Pin: 305404,
        DispatchCenter: "Kekri_LodhaChowk_DPP (Rajasthan)",
        OriginCenter: "Kekri_LodhaChowk_DPP (Rajasthan)",
        FacilityCity: "Kekri",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12127,
        Pin: 626213,
        DispatchCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        OriginCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        FacilityCity: "Sivakasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12128,
        Pin: 431714,
        DispatchCenter: "Loha_Sidharthnagar_D (Maharashtra)",
        OriginCenter: "Loha_Sidharthnagar_D (Maharashtra)",
        FacilityCity: "Loha",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12129,
        Pin: 670013,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12130,
        Pin: 415802,
        DispatchCenter: "Lanja_GondeSakhalRd_D (Maharashtra)",
        OriginCenter: "Lanja_GondeSakhalRd_D (Maharashtra)",
        FacilityCity: "Lanja",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12131,
        Pin: 585283,
        DispatchCenter: "Shorapur_GandhiNagar_D (Karnataka)",
        OriginCenter: "Shorapur_GandhiNagar_D (Karnataka)",
        FacilityCity: "Shorapur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12132,
        Pin: 305812,
        DispatchCenter: "Kishangarh_AirportRd_D (Rajasthan)",
        OriginCenter: "Kishangarh_AirportRd_D (Rajasthan)",
        FacilityCity: "Kishangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12133,
        Pin: 627106,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12134,
        Pin: 505327,
        DispatchCenter: "Jagtial_TulsiNagar_D (Telangana)",
        OriginCenter: "Jagtial_TulsiNagar_D (Telangana)",
        FacilityCity: "Jagtial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12135,
        Pin: 700071,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12136,
        Pin: 742174,
        DispatchCenter: "Nowda_Amtala_DPP (West Bengal)",
        OriginCenter: "Nowda_Amtala_DPP (West Bengal)",
        FacilityCity: "Nowda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12137,
        Pin: 824218,
        DispatchCenter: "AurangabadBR_Katharua_D (Bihar)",
        OriginCenter: "AurangabadBR_Katharua_D (Bihar)",
        FacilityCity: "Aurangabad-BR",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12138,
        Pin: 331518,
        DispatchCenter: "Sujangarh_Dhneru_D (Rajasthan)",
        OriginCenter: "Sujangarh_Dhneru_D (Rajasthan)",
        FacilityCity: "Sujangarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12139,
        Pin: 624207,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12140,
        Pin: 485775,
        DispatchCenter: "Amarpatan_Rigra_D (Madhya Pradesh)",
        OriginCenter: "Amarpatan_Rigra_D (Madhya Pradesh)",
        FacilityCity: "Amarpatan",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 12141,
        Pin: 523327,
        DispatchCenter: "Yrrgondplm_MainRd_DPP (Andhra Pradesh)",
        OriginCenter: "Yrrgondplm_MainRd_DPP (Andhra Pradesh)",
        FacilityCity: "Yerragonda Palem",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12142,
        Pin: 262701,
        DispatchCenter: "Lakhimpur_FunMall_D (Uttar Pradesh)",
        OriginCenter: "Lakhimpur_FunMall_D (Uttar Pradesh)",
        FacilityCity: "Lakhimpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12143,
        Pin: 363115,
        DispatchCenter: "Surendranagar_GIDC_D (Gujarat)",
        OriginCenter: "Surendranagar_GIDC_D (Gujarat)",
        FacilityCity: "Surendranagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12144,
        Pin: 680665,
        DispatchCenter: "Kodungallur_Methala_D (Kerala)",
        OriginCenter: "Kodungallur_Methala_D (Kerala)",
        FacilityCity: "Kodungallur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12145,
        Pin: 274249,
        DispatchCenter: "Kaptanganj_Subshngr_D (Uttar Pradesh)",
        OriginCenter: "Kaptanganj_Subshngr_D (Uttar Pradesh)",
        FacilityCity: "Kaptanganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12146,
        Pin: 768026,
        DispatchCenter: "Kadobahal_Dulampur_D (Orissa)",
        OriginCenter: "Kadobahal_Dulampur_D (Orissa)",
        FacilityCity: "Kadobahal",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12147,
        Pin: 600017,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12148,
        Pin: 522313,
        DispatchCenter: "Tenali_Pandurngpet_D (Andhra Pradesh)",
        OriginCenter: "Tenali_Pandurngpet_D (Andhra Pradesh)",
        FacilityCity: "Tenali",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12149,
        Pin: 642125,
        DispatchCenter: "Valparai_MGRNagar_DPP (Tamil Nadu)",
        OriginCenter: "Valparai_MGRNagar_DPP (Tamil Nadu)",
        FacilityCity: "Valparai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12150,
        Pin: 680125,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12151,
        Pin: 756123,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12152,
        Pin: 534195,
        DispatchCenter: "Bhimavaram_Saripalle_DPP (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Saripalle_DPP (Andhra Pradesh)",
        FacilityCity: "Ganapavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12153,
        Pin: 711310,
        DispatchCenter: "Howrah_Temp_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12154,
        Pin: 695581,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12155,
        Pin: 246166,
        DispatchCenter: "Pauri_Khndyusn_D (Uttarakhand)",
        OriginCenter: "Pauri_Khndyusn_D (Uttarakhand)",
        FacilityCity: "Pauri",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12156,
        Pin: 614712,
        DispatchCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        FacilityCity: "Thiruthuraipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12157,
        Pin: 243301,
        DispatchCenter: "Aonla_KdidmCLY_D (Uttar Pradesh)",
        OriginCenter: "Aonla_KdidmCLY_D (Uttar Pradesh)",
        FacilityCity: "Aonla",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12158,
        Pin: 825323,
        DispatchCenter: "Domchanch_Gaira_DPP (Jharkhand)",
        OriginCenter: "Domchanch_Gaira_DPP (Jharkhand)",
        FacilityCity: "Domchanch",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12159,
        Pin: 722147,
        DispatchCenter: "Bishnupur_StnRdDPP_D (West Bengal)",
        OriginCenter: "Bishnupur_StnRdDPP_D (West Bengal)",
        FacilityCity: "Bishnupur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12160,
        Pin: 627009,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12161,
        Pin: 521246,
        DispatchCenter: "Vuyyuru_BhogdDPP_D (Andhra Pradesh)",
        OriginCenter: "Vuyyuru_BhogdDPP_D (Andhra Pradesh)",
        FacilityCity: "Vuyyuru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12162,
        Pin: 394140,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12163,
        Pin: 360311,
        DispatchCenter: "Gondal_Maninagar_D (Gujarat)",
        OriginCenter: "Gondal_Maninagar_D (Gujarat)",
        FacilityCity: "Gondal",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12164,
        Pin: 412219,
        DispatchCenter: "Daund_Kurkumbh_L (Maharashtra)",
        OriginCenter: "Daund_Kurkumbh_L (Maharashtra)",
        FacilityCity: "Daund",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12165,
        Pin: 229411,
        DispatchCenter: "Lalgopalganj_Anandi_D (Uttar Pradesh)",
        OriginCenter: "Lalgopalganj_Anandi_D (Uttar Pradesh)",
        FacilityCity: "Lal Gopalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12166,
        Pin: 570003,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12167,
        Pin: 382051,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12168,
        Pin: 221407,
        DispatchCenter: "Bhadohi_Chedibeer_D (Uttar Pradesh)",
        OriginCenter: "Bhadohi_Chedibeer_D (Uttar Pradesh)",
        FacilityCity: "Bhadohi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12169,
        Pin: 824217,
        DispatchCenter: "Imamganj_Bisrampur_D (Bihar)",
        OriginCenter: "Imamganj_Bisrampur_D (Bihar)",
        FacilityCity: "Imamganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12170,
        Pin: 534216,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12171,
        Pin: 160051,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12172,
        Pin: 795106,
        DispatchCenter: "Senapati_OldMarket_D (Manipur)",
        OriginCenter: "Senapati_OldMarket_D (Manipur)",
        FacilityCity: "Senapati",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12173,
        Pin: 249203,
        DispatchCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        OriginCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        FacilityCity: "Rishikesh",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12174,
        Pin: 431750,
        DispatchCenter: "Vasmat_Khajipura_D (Maharashtra)",
        OriginCenter: "Vasmat_Khajipura_D (Maharashtra)",
        FacilityCity: "Vasmat",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12175,
        Pin: 841413,
        DispatchCenter: "Malmaliya_Sipah_D (Bihar)",
        OriginCenter: "Malmaliya_Sipah_D (Bihar)",
        FacilityCity: "Malmaliya",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12176,
        Pin: 571417,
        DispatchCenter: "Malavalli_KBHclny_D (Karnataka)",
        OriginCenter: "Malavalli_KBHclny_D (Karnataka)",
        FacilityCity: "Malavalli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12177,
        Pin: 600108,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12178,
        Pin: 689124,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12179,
        Pin: 247129,
        DispatchCenter: "Behat_ChkrtaMrg_D (Uttar Pradesh)",
        OriginCenter: "Behat_ChkrtaMrg_D (Uttar Pradesh)",
        FacilityCity: "Behat",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12180,
        Pin: 811212,
        DispatchCenter: "Munger_Kaura_D (Bihar)",
        OriginCenter: "Munger_Kaura_D (Bihar)",
        FacilityCity: "Munger",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12181,
        Pin: 360330,
        DispatchCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        OriginCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12182,
        Pin: 742140,
        DispatchCenter: "Khargram_Parbatipur_D (West Bengal)",
        OriginCenter: "Khargram_Parbatipur_D (West Bengal)",
        FacilityCity: "Khargram",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12183,
        Pin: 636006,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12184,
        Pin: 462032,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12185,
        Pin: 600063,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12186,
        Pin: 845301,
        DispatchCenter: "Raxaul_Nonea_D (Bihar)",
        OriginCenter: "Raxaul_Nonea_D (Bihar)",
        FacilityCity: "Raxaul",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12187,
        Pin: 383316,
        DispatchCenter: "Modasa_Shyamnagar_D (Gujarat)",
        OriginCenter: "Modasa_Shyamnagar_D (Gujarat)",
        FacilityCity: "Modasa",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12188,
        Pin: 581345,
        DispatchCenter: "Karwar_Sadashivgad_D (Karnataka)",
        OriginCenter: "Karwar_Sadashivgad_D (Karnataka)",
        FacilityCity: "Karwar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12189,
        Pin: 175028,
        DispatchCenter: "Chachyot_Chailchowk_DPP (Himachal Pradesh)",
        OriginCenter: "Chachyot_Chailchowk_DPP (Himachal Pradesh)",
        FacilityCity: "Chachyot",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12190,
        Pin: 829301,
        DispatchCenter: "Peterbar_GagiDPP_D (Jharkhand)",
        OriginCenter: "Peterbar_GagiDPP_D (Jharkhand)",
        FacilityCity: "Peterbar",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12191,
        Pin: 791113,
        DispatchCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        OriginCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        FacilityCity: "Itanagar",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12192,
        Pin: 201007,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12193,
        Pin: 721650,
        DispatchCenter: "Bajkul_Kismat_D (West Bengal)",
        OriginCenter: "Bajkul_Kismat_D (West Bengal)",
        FacilityCity: "Bajkul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12194,
        Pin: 272130,
        DispatchCenter: "Itava_Prsnga_D (Uttar Pradesh)",
        OriginCenter: "Itava_Prsnga_D (Uttar Pradesh)",
        FacilityCity: "Itava",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12195,
        Pin: 460557,
        DispatchCenter: "Athner_College_D (Madhya Pradesh)",
        OriginCenter: "Athner_College_D (Madhya Pradesh)",
        FacilityCity: "Athner",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12196,
        Pin: 804432,
        DispatchCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        OriginCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        FacilityCity: "Jehanabad",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12197,
        Pin: 642123,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12198,
        Pin: 741503,
        DispatchCenter: "Hanskhali_Ashrampara_D (West Bengal)",
        OriginCenter: "Hanskhali_Ashrampara_D (West Bengal)",
        FacilityCity: "Hanskhali",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12199,
        Pin: 841236,
        DispatchCenter: "Siwan_Aranda_D (Bihar)",
        OriginCenter: "Siwan_Aranda_D (Bihar)",
        FacilityCity: "Siwan",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12200,
        Pin: 784524,
        DispatchCenter: "Udalguri_Ramjanakichowk_D (Assam)",
        OriginCenter: "Udalguri_Ramjanakichowk_D (Assam)",
        FacilityCity: "Udalguri",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12201,
        Pin: 403114,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12202,
        Pin: 781335,
        DispatchCenter: "Nalbari_Chokbazar_D (Assam)",
        OriginCenter: "Nalbari_Chokbazar_D (Assam)",
        FacilityCity: "Nalbari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12203,
        Pin: 734013,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12204,
        Pin: 389230,
        DispatchCenter: "Lunawada_VrdhriRD_D (Gujarat)",
        OriginCenter: "Lunawada_VrdhriRD_D (Gujarat)",
        FacilityCity: "Lunawada",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12205,
        Pin: 443112,
        DispatchCenter: "Malkapur_DwarkaHosp_DPP (Maharashtra)",
        OriginCenter: "Malkapur_DwarkaHosp_DPP (Maharashtra)",
        FacilityCity: "Malkapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12206,
        Pin: 416707,
        DispatchCenter: "Ratnagiri_Mervi_DPP (Maharashtra)",
        OriginCenter: "Ratnagiri_Mervi_DPP (Maharashtra)",
        FacilityCity: "Ratnagiri",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12207,
        Pin: 383320,
        DispatchCenter: "Modasa_Shyamnagar_D (Gujarat)",
        OriginCenter: "Modasa_Shyamnagar_D (Gujarat)",
        FacilityCity: "Modasa",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12208,
        Pin: 670142,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12209,
        Pin: 606811,
        DispatchCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        OriginCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        FacilityCity: "Tiruvannamalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12210,
        Pin: 421201,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12211,
        Pin: 631702,
        DispatchCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        OriginCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        FacilityCity: "Kanchipuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12212,
        Pin: 852129,
        DispatchCenter: "Sonbarsa_Soha_D (Bihar)",
        OriginCenter: "Sonbarsa_Soha_D (Bihar)",
        FacilityCity: "Sonbarsa-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12213,
        Pin: 403001,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12214,
        Pin: 176128,
        DispatchCenter: "Kachhera_Timber_DPP (Himachal Pradesh)",
        OriginCenter: "Kachhera_Timber_DPP (Himachal Pradesh)",
        FacilityCity: "Kachhera",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12215,
        Pin: 509215,
        DispatchCenter: "Tadoor_Nagarkurnool_D (Telangana)",
        OriginCenter: "Tadoor_Nagarkurnool_D (Telangana)",
        FacilityCity: "Tadoor",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12216,
        Pin: 581356,
        DispatchCenter: "Haliyal_Anegundicolony_DPP (Karnataka)",
        OriginCenter: "Haliyal_Anegundicolony_DPP (Karnataka)",
        FacilityCity: "Haliyal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12217,
        Pin: 131303,
        DispatchCenter: "Gohana_Adarshnagar_D (Haryana)",
        OriginCenter: "Gohana_Adarshnagar_D (Haryana)",
        FacilityCity: "Gohana",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12218,
        Pin: 673576,
        DispatchCenter: "Kalpetta_Mandayapuram_D (Kerala)",
        OriginCenter: "Kalpetta_Mandayapuram_D (Kerala)",
        FacilityCity: "Kalpetta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12219,
        Pin: 125103,
        DispatchCenter: "Sirsa_Kaliawaliroad_D (Haryana)",
        OriginCenter: "Sirsa_Kaliawaliroad_D (Haryana)",
        FacilityCity: "Sirsa",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12220,
        Pin: 132108,
        DispatchCenter: "Panipat_Risalu_P (Haryana)",
        OriginCenter: "Panipat_Risalu_P (Haryana)",
        FacilityCity: "Panipat",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12221,
        Pin: 134205,
        DispatchCenter: "Naraingarh_KrishnaColony_DPP (Haryana)",
        OriginCenter: "Naraingarh_KrishnaColony_DPP (Haryana)",
        FacilityCity: "Naraingarh",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12222,
        Pin: 627803,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12223,
        Pin: 835238,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12224,
        Pin: 761035,
        DispatchCenter: "Kodala_Beguniapada_DPP (Orissa)",
        OriginCenter: "Kodala_Beguniapada_DPP (Orissa)",
        FacilityCity: "Kodala",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12225,
        Pin: 144214,
        DispatchCenter: "Dasuya_AdarshNagar_D (Punjab)",
        OriginCenter: "Dasuya_AdarshNagar_D (Punjab)",
        FacilityCity: "Dasuya",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12226,
        Pin: 831015,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12227,
        Pin: 243202,
        DispatchCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        OriginCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        FacilityCity: "Bareilly",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12228,
        Pin: 691516,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12229,
        Pin: 142057,
        DispatchCenter: "Bagha_Samalsar_D (Punjab)",
        OriginCenter: "Bagha_Samalsar_D (Punjab)",
        FacilityCity: "Bagha purana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12230,
        Pin: 848138,
        DispatchCenter: "Samastipur_RhmprRdli_D (Bihar)",
        OriginCenter: "Samastipur_RhmprRdli_D (Bihar)",
        FacilityCity: "Samastipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12231,
        Pin: 244004,
        DispatchCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        OriginCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        FacilityCity: "Moradabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12232,
        Pin: 673106,
        DispatchCenter: "Vadakara_Orkkatteri_D (Kerala)",
        OriginCenter: "Vadakara_Orkkatteri_D (Kerala)",
        FacilityCity: "Vadakara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12233,
        Pin: 782124,
        DispatchCenter: "Nagaon_Bangthai_H (Assam)",
        OriginCenter: "Nagaon_Bangthai_H (Assam)",
        FacilityCity: "Nagaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12234,
        Pin: 530005,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12235,
        Pin: 508252,
        DispatchCenter: "Choutuppal_Autonagar_D (Telangana)",
        OriginCenter: "Choutuppal_Autonagar_D (Telangana)",
        FacilityCity: "Choutuppal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12236,
        Pin: 403709,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12237,
        Pin: 605101,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12238,
        Pin: 502279,
        DispatchCenter: "Pregnapur_Tharakaramanagar_D (Telangana)",
        OriginCenter: "Pregnapur_Tharakaramanagar_D (Telangana)",
        FacilityCity: "Pregnapur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12239,
        Pin: 173235,
        DispatchCenter: "Darlaghat_Central_D_1 (Himachal Pradesh)",
        OriginCenter: "Darlaghat_Central_D_1 (Himachal Pradesh)",
        FacilityCity: "Darlaghat",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12240,
        Pin: 816108,
        DispatchCenter: "Rajmahal_Bamangawan_D (Jharkhand)",
        OriginCenter: "Rajmahal_Bamangawan_D (Jharkhand)",
        FacilityCity: "Rajmahal",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12241,
        Pin: 144041,
        DispatchCenter: "Nakodar_Goldenavenue_D (Punjab)",
        OriginCenter: "Nakodar_Goldenavenue_D (Punjab)",
        FacilityCity: "Nakodar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12242,
        Pin: 261402,
        DispatchCenter: "Mishrikh_Naimishrnya_DPP (Uttar Pradesh)",
        OriginCenter: "Mishrikh_Naimishrnya_DPP (Uttar Pradesh)",
        FacilityCity: "Mishrikh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12243,
        Pin: 600123,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12244,
        Pin: 173206,
        DispatchCenter: "Solan_SayriGhat_DPP (Himachal Pradesh)",
        OriginCenter: "Solan_SayriGhat_DPP (Himachal Pradesh)",
        FacilityCity: "Solan",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12245,
        Pin: 606801,
        DispatchCenter: "Chetpet_Savarapoondi_D (Tamil Nadu)",
        OriginCenter: "Chetpet_Savarapoondi_D (Tamil Nadu)",
        FacilityCity: "Chetpet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12246,
        Pin: 502255,
        DispatchCenter: "Medak_Chegunta_D (Telangana)",
        OriginCenter: "Medak_Chegunta_D (Telangana)",
        FacilityCity: "Medak",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12247,
        Pin: 627357,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12248,
        Pin: 413220,
        DispatchCenter: "Akkalkot_SmrathNgr_DPP (Maharashtra)",
        OriginCenter: "Akkalkot_SmrathNgr_DPP (Maharashtra)",
        FacilityCity: "Akkalkot",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12249,
        Pin: 680590,
        DispatchCenter: "Shoranur_Akamala_D (Kerala)",
        OriginCenter: "Shoranur_Akamala_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12250,
        Pin: 496227,
        DispatchCenter: "Kunkuri_Tapkara_D (Chhattisgarh)",
        OriginCenter: "Kunkuri_Tapkara_D (Chhattisgarh)",
        FacilityCity: "Kunkuri",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12251,
        Pin: 799003,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12252,
        Pin: 583115,
        DispatchCenter: "Bellary_Mundargi_I (Karnataka)",
        OriginCenter: "Bellary_Mundargi_I (Karnataka)",
        FacilityCity: "Bellary",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12253,
        Pin: 125048,
        DispatchCenter: "Fatehabad_SirsaRd_D (Haryana)",
        OriginCenter: "Fatehabad_SirsaRd_D (Haryana)",
        FacilityCity: "Fatehabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12254,
        Pin: 632312,
        DispatchCenter: "Arani_PolurRd_D (Tamil Nadu)",
        OriginCenter: "Arani_PolurRd_D (Tamil Nadu)",
        FacilityCity: "Arani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12255,
        Pin: 767001,
        DispatchCenter: "Balangir_PalaceLine_D (Orissa)",
        OriginCenter: "Balangir_PalaceLine_D (Orissa)",
        FacilityCity: "Balangir",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12256,
        Pin: 224116,
        DispatchCenter: "Rudauli_Ghosaiyana_D (Uttar Pradesh)",
        OriginCenter: "Rudauli_Ghosaiyana_D (Uttar Pradesh)",
        FacilityCity: "Rudauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12257,
        Pin: 722162,
        DispatchCenter: "Khatra_Simlapal_DPP (West Bengal)",
        OriginCenter: "Khatra_Simlapal_DPP (West Bengal)",
        FacilityCity: "Khatra",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12258,
        Pin: 343025,
        DispatchCenter: "Jalore_RoopNgr_D (Rajasthan)",
        OriginCenter: "Jalore_RoopNgr_D (Rajasthan)",
        FacilityCity: "Jalore",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12259,
        Pin: 485115,
        DispatchCenter: "Satna_Tapa_D (Madhya Pradesh)",
        OriginCenter: "Satna_Tapa_D (Madhya Pradesh)",
        FacilityCity: "Satna",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12260,
        Pin: 248158,
        DispatchCenter: "Chakrata_Purodi_DPP (Uttarakhand)",
        OriginCenter: "Chakrata_Purodi_DPP (Uttarakhand)",
        FacilityCity: "Chakrata",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12261,
        Pin: 600098,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12262,
        Pin: 695506,
        DispatchCenter: "Trivandrum_Neyatinkra_D (Kerala)",
        OriginCenter: "Trivandrum_Neyatinkra_D (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12263,
        Pin: 678009,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12264,
        Pin: 396108,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12265,
        Pin: 743387,
        DispatchCenter: "Baruipur_Bnsibtla_D (West Bengal)",
        OriginCenter: "Baruipur_Bnsibtla_D (West Bengal)",
        FacilityCity: "Baruipur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12266,
        Pin: 333041,
        DispatchCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        OriginCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        FacilityCity: "Jhunjhunu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12267,
        Pin: 505476,
        DispatchCenter: "Huzurabad_Vivekanandanagar_D (Telangana)",
        OriginCenter: "Huzurabad_Vivekanandanagar_D (Telangana)",
        FacilityCity: "Huzurabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12268,
        Pin: 689625,
        DispatchCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        OriginCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12269,
        Pin: 122414,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12270,
        Pin: 585417,
        DispatchCenter: "Aurad_CBColony_D (Karnataka)",
        OriginCenter: "Aurad_CBColony_D (Karnataka)",
        FacilityCity: "Aurad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12271,
        Pin: 721128,
        DispatchCenter: "Midnapore_Sarbera_DPP (West Bengal)",
        OriginCenter: "Midnapore_Sarbera_DPP (West Bengal)",
        FacilityCity: "Midnapore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12272,
        Pin: 623518,
        DispatchCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        OriginCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        FacilityCity: "Rameswaram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12273,
        Pin: 796891,
        DispatchCenter: "Lawngtlai_Vengpui_D (Mizoram)",
        OriginCenter: "Lawngtlai_Vengpui_D (Mizoram)",
        FacilityCity: "Lawngtlai",
        FacilityState: "Mizoram",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12274,
        Pin: 631102,
        DispatchCenter: "Sholinghur_Pulanji_D (Tamil Nadu)",
        OriginCenter: "Sholinghur_Pulanji_D (Tamil Nadu)",
        FacilityCity: "Sholinghur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12275,
        Pin: 757029,
        DispatchCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        OriginCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        FacilityCity: "Betnoti",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12276,
        Pin: 627351,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12277,
        Pin: 301028,
        DispatchCenter: "Alwar_TuleraRoad_I (Rajasthan)",
        OriginCenter: "Alwar_TuleraRoad_I (Rajasthan)",
        FacilityCity: "Alwar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12278,
        Pin: 133203,
        DispatchCenter: "Ambala_Barara_D (Haryana)",
        OriginCenter: "Ambala_Barara_D (Haryana)",
        FacilityCity: "Ambala",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12279,
        Pin: 803201,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12280,
        Pin: 624501,
        DispatchCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        OriginCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        FacilityCity: "Melur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12281,
        Pin: 671534,
        DispatchCenter: "Parappa_Balal_D (Kerala)",
        OriginCenter: "Parappa_Balal_D (Kerala)",
        FacilityCity: "Parappa",
        FacilityState: "Kerala",
        Zone : "S2"
      },
      {
        SN: 12282,
        Pin: 793103,
        DispatchCenter: "Shillong_Bhoirymbong2_D (Meghalaya)",
        OriginCenter: "Shillong_Bhoirymbong2_D (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12283,
        Pin: 362710,
        DispatchCenter: "Kodinar_NCplxDPP_D (Gujarat)",
        OriginCenter: "Kodinar_NCplxDPP_D (Gujarat)",
        FacilityCity: "Kodinar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12284,
        Pin: 384305,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12285,
        Pin: 713341,
        DispatchCenter: "Asansol_Gobindapur_D (West Bengal)",
        OriginCenter: "Asansol_Gobindapur_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12286,
        Pin: 506347,
        DispatchCenter: "Mulugu_MaharshiClg_D (Telangana)",
        OriginCenter: "Mulugu_MaharshiClg_D (Telangana)",
        FacilityCity: "Mulugu",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12287,
        Pin: 608901,
        DispatchCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        OriginCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        FacilityCity: "Jayankondam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12288,
        Pin: 246763,
        DispatchCenter: "Najibabad_Pathanpura_D (Uttar Pradesh)",
        OriginCenter: "Najibabad_Pathanpura_D (Uttar Pradesh)",
        FacilityCity: "Najibabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12289,
        Pin: 627854,
        DispatchCenter: "Tirunelveli_BalabagyaNagar_D (Tamil Nadu)",
        OriginCenter: "Tirunelveli_BalabagyaNagar_D (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12290,
        Pin: 786159,
        DispatchCenter: "DoomDooma_Pthrgoan_D (Assam)",
        OriginCenter: "DoomDooma_Pthrgoan_D (Assam)",
        FacilityCity: "Doom Dooma",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12291,
        Pin: 276126,
        DispatchCenter: "Dullahpur_Bhurkura_D (Uttar Pradesh)",
        OriginCenter: "Dullahpur_Bhurkura_D (Uttar Pradesh)",
        FacilityCity: "Dullahpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12292,
        Pin: 577301,
        DispatchCenter: "Shimoga_Bhandrahalli_D (Karnataka)",
        OriginCenter: "Shimoga_Bhandrahalli_D (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12293,
        Pin: 621704,
        DispatchCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        OriginCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        FacilityCity: "Ariyalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12294,
        Pin: 764037,
        DispatchCenter: "Koraput_Balda_DPP (Orissa)",
        OriginCenter: "Koraput_Balda_DPP (Orissa)",
        FacilityCity: "Koraput",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12295,
        Pin: 388170,
        DispatchCenter: "Tarapur_Darukhanu_DPP (Gujarat)",
        OriginCenter: "Tarapur_Darukhanu_DPP (Gujarat)",
        FacilityCity: "Tarapur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12296,
        Pin: 624211,
        DispatchCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        OriginCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        FacilityCity: "Vadipatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12297,
        Pin: 246172,
        DispatchCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        OriginCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        FacilityCity: "Gumkhal",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12298,
        Pin: 212303,
        DispatchCenter: "Manda_Salia_D (Uttar Pradesh)",
        OriginCenter: "Manda_Salia_D (Uttar Pradesh)",
        FacilityCity: "Manda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12299,
        Pin: 586227,
        DispatchCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        OriginCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        FacilityCity: "Indi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12300,
        Pin: 736123,
        DispatchCenter: "CoochBehar_khagrabari_I (West Bengal)",
        OriginCenter: "CoochBehar_khagrabari_I (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12301,
        Pin: 766026,
        DispatchCenter: "Jaipatna_PradhniChwk_D (Orissa)",
        OriginCenter: "Jaipatna_PradhniChwk_D (Orissa)",
        FacilityCity: "Jaipatna",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12302,
        Pin: 394365,
        DispatchCenter: "Songadh_Shivnagar2_DPP (Gujarat)",
        OriginCenter: "Songadh_Shivnagar2_DPP (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12303,
        Pin: 148025,
        DispatchCenter: "Dhuri_Bhalwanroad_D (Punjab)",
        OriginCenter: "Dhuri_Bhalwanroad_D (Punjab)",
        FacilityCity: "Dhuri",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12304,
        Pin: 110029,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12305,
        Pin: 281502,
        DispatchCenter: "Mathura_Govardhan_D (Uttar Pradesh)",
        OriginCenter: "Mathura_Govardhan_D (Uttar Pradesh)",
        FacilityCity: "Mathura",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12306,
        Pin: 415521,
        DispatchCenter: "Nira_Central_D (Maharashtra)",
        OriginCenter: "Nira_Central_D (Maharashtra)",
        FacilityCity: "Nira",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12307,
        Pin: 584133,
        DispatchCenter: "Raichur_Wadloor_I (Karnataka)",
        OriginCenter: "Raichur_Wadloor_I (Karnataka)",
        FacilityCity: "Raichur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12308,
        Pin: 623307,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12309,
        Pin: 271305,
        DispatchCenter: "Itava_Prsnga_D (Uttar Pradesh)",
        OriginCenter: "Itava_Prsnga_D (Uttar Pradesh)",
        FacilityCity: "Itava",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12310,
        Pin: 207244,
        DispatchCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        OriginCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        FacilityCity: "Aliganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12311,
        Pin: 517001,
        DispatchCenter: "Chittoor_Murukambattu_D (Andhra Pradesh)",
        OriginCenter: "Chittoor_Murukambattu_D (Andhra Pradesh)",
        FacilityCity: "Chittoor",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12312,
        Pin: 313002,
        DispatchCenter: "Udaipur_Balicha_H (Rajasthan)",
        OriginCenter: "Udaipur_Balicha_H (Rajasthan)",
        FacilityCity: "Udaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12313,
        Pin: 679571,
        DispatchCenter: "Valanchery_Central_D_1 (Kerala)",
        OriginCenter: "Valanchery_Central_D_1 (Kerala)",
        FacilityCity: "Valanchery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12314,
        Pin: 742139,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12315,
        Pin: 573216,
        DispatchCenter: "Hassan_Nagathavalli_I (Karnataka)",
        OriginCenter: "Hassan_Nagathavalli_I (Karnataka)",
        FacilityCity: "Hassan",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12316,
        Pin: 533431,
        DispatchCenter: "Yerravaram_Somavaram_D (Andhra Pradesh)",
        OriginCenter: "Yerravaram_Somavaram_D (Andhra Pradesh)",
        FacilityCity: "Yerravaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12317,
        Pin: 244223,
        DispatchCenter: "Gajraula_JawaharN_D (Uttar Pradesh)",
        OriginCenter: "Gajraula_JawaharN_D (Uttar Pradesh)",
        FacilityCity: "Gajraula",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12318,
        Pin: 691601,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12319,
        Pin: 425201,
        DispatchCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        OriginCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        FacilityCity: "Bhusawal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12320,
        Pin: 627760,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12321,
        Pin: 691588,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12322,
        Pin: 629201,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12323,
        Pin: 396020,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12324,
        Pin: 676554,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12325,
        Pin: 143604,
        DispatchCenter: "Ajnala_Fatehgarhchurian_D (Punjab)",
        OriginCenter: "Ajnala_Fatehgarhchurian_D (Punjab)",
        FacilityCity: "Ajnala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12326,
        Pin: 794110,
        DispatchCenter: "Rongjeng_Naringgiri_DPP (Meghalaya)",
        OriginCenter: "Rongjeng_Naringgiri_DPP (Meghalaya)",
        FacilityCity: "Rongjeng",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12327,
        Pin: 110053,
        DispatchCenter: "Delhi_JhilmilColony_L (Delhi)",
        OriginCenter: "Delhi_JhilmilColony_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12328,
        Pin: 176064,
        DispatchCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        OriginCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        FacilityCity: "Palampur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12329,
        Pin: 841226,
        DispatchCenter: "Siwan_Atarsua_D (Bihar)",
        OriginCenter: "Siwan_Atarsua_D (Bihar)",
        FacilityCity: "Siwan",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12330,
        Pin: 335039,
        DispatchCenter: "Raisinghnagar_Ward24_D (Rajasthan)",
        OriginCenter: "Raisinghnagar_Ward24_D (Rajasthan)",
        FacilityCity: "Raisinghnagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12331,
        Pin: 585402,
        DispatchCenter: "Bidar_AdarshClny_D (Karnataka)",
        OriginCenter: "Bidar_AdarshClny_D (Karnataka)",
        FacilityCity: "Bidar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12332,
        Pin: 400607,
        DispatchCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12333,
        Pin: 803204,
        DispatchCenter: "Chandi_SH78_D (Bihar)",
        OriginCenter: "Chandi_SH78_D (Bihar)",
        FacilityCity: "Chandi",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12334,
        Pin: 577007,
        DispatchCenter: "Davangere_Anagodu_D (Karnataka)",
        OriginCenter: "Davangere_Anagodu_D (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12335,
        Pin: 673621,
        DispatchCenter: "Thamarassery_Raoth_D (Kerala)",
        OriginCenter: "Thamarassery_Raoth_D (Kerala)",
        FacilityCity: "Thamarassery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12336,
        Pin: 673302,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12337,
        Pin: 412801,
        DispatchCenter: "Khandala_Shrwal_D (Maharashtra)",
        OriginCenter: "Khandala_Shrwal_D (Maharashtra)",
        FacilityCity: "Khandala",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12338,
        Pin: 394641,
        DispatchCenter: "Bardoli_GopalNagar_D (Gujarat)",
        OriginCenter: "Bardoli_GopalNagar_D (Gujarat)",
        FacilityCity: "Bardoli",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12339,
        Pin: 591311,
        DispatchCenter: "Raibag_Kudachi_DPP (Karnataka)",
        OriginCenter: "Raibag_Kudachi_DPP (Karnataka)",
        FacilityCity: "Raibag",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12340,
        Pin: 365610,
        DispatchCenter: "Amreli_Kunkavav_DPP (Gujarat)",
        OriginCenter: "Amreli_Kunkavav_DPP (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12341,
        Pin: 364241,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12342,
        Pin: 751003,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12343,
        Pin: 230002,
        DispatchCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        OriginCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        FacilityCity: "Pratapgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12344,
        Pin: 393150,
        DispatchCenter: "Rajpipla_DSPOffice_DPP (Gujarat)",
        OriginCenter: "Rajpipla_DSPOffice_DPP (Gujarat)",
        FacilityCity: "Rajpipla",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12345,
        Pin: 464228,
        DispatchCenter: "Sironj_Hajipur_DPP (Madhya Pradesh)",
        OriginCenter: "Sironj_Hajipur_DPP (Madhya Pradesh)",
        FacilityCity: "Sironj",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12346,
        Pin: 246171,
        DispatchCenter: "Rudraprayag_Gulabrai_D (Uttarakhand)",
        OriginCenter: "Rudraprayag_Gulabrai_D (Uttarakhand)",
        FacilityCity: "Rudraprayag",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12347,
        Pin: 577112,
        DispatchCenter: "Sringeri_Kalmetlu_DPP (Karnataka)",
        OriginCenter: "Sringeri_Kalmetlu_DPP (Karnataka)",
        FacilityCity: "Sringeri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12348,
        Pin: 176216,
        DispatchCenter: "Dharamshala_GrudwrRd_D (Himachal Pradesh)",
        OriginCenter: "Dharamshala_GrudwrRd_D (Himachal Pradesh)",
        FacilityCity: "Dharamshala",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12349,
        Pin: 591131,
        DispatchCenter: "Khanapur_StatinRD_D (Karnataka)",
        OriginCenter: "Khanapur_StatinRD_D (Karnataka)",
        FacilityCity: "Khanapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12350,
        Pin: 176049,
        DispatchCenter: "Bhota_BpassDPP_D (Himachal Pradesh)",
        OriginCenter: "Bhota_BpassDPP_D (Himachal Pradesh)",
        FacilityCity: "Bhota",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12351,
        Pin: 743412,
        DispatchCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        OriginCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        FacilityCity: "Basirhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12352,
        Pin: 761055,
        DispatchCenter: "Digapahandi_Punjikanya_D (Orissa)",
        OriginCenter: "Digapahandi_Punjikanya_D (Orissa)",
        FacilityCity: "Digapahandi",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12353,
        Pin: 713407,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12354,
        Pin: 676103,
        DispatchCenter: "Tirur_Talakkad_D (Kerala)",
        OriginCenter: "Tirur_Talakkad_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12355,
        Pin: 121012,
        DispatchCenter: "Faridabad_MathuraRoad_L (Haryana)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12356,
        Pin: 571101,
        DispatchCenter: "Mysore_Tnarsipura_DPP (Karnataka)",
        OriginCenter: "Mysore_Tnarsipura_DPP (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12357,
        Pin: 683512,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12358,
        Pin: 721144,
        DispatchCenter: "Lutunia_Bholenthtmpl_D (West Bengal)",
        OriginCenter: "Lutunia_Bholenthtmpl_D (West Bengal)",
        FacilityCity: "Lutunia",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12359,
        Pin: 791102,
        DispatchCenter: "Pasighat_MissonRd_D (Arunachal Pradesh)",
        OriginCenter: "Pasighat_MissonRd_D (Arunachal Pradesh)",
        FacilityCity: "Pasighat",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12360,
        Pin: 262311,
        DispatchCenter: "Sitarganj_Bijti_D (Uttarakhand)",
        OriginCenter: "Sitarganj_Bijti_D (Uttarakhand)",
        FacilityCity: "Sitarganj",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12361,
        Pin: 390018,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12362,
        Pin: 143517,
        DispatchCenter: "SriHargobindpur_Talwara_D (Punjab)",
        OriginCenter: "SriHargobindpur_Talwara_D (Punjab)",
        FacilityCity: "Sri Hargobindpur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12363,
        Pin: 243406,
        DispatchCenter: "Nawabganj_Bhairav_D (Uttar Pradesh)",
        OriginCenter: "Nawabganj_Bhairav_D (Uttar Pradesh)",
        FacilityCity: "Nawabganj-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12364,
        Pin: 502313,
        DispatchCenter: "NarsapurTS_MarketRd_D (Telangana)",
        OriginCenter: "NarsapurTS_MarketRd_D (Telangana)",
        FacilityCity: "Narsapur-TS",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12365,
        Pin: 231224,
        DispatchCenter: "Anpara_Parasi_D (Uttar Pradesh)",
        OriginCenter: "Anpara_Parasi_D (Uttar Pradesh)",
        FacilityCity: "Anpara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12366,
        Pin: 781368,
        DispatchCenter: "Tamulpur_Kalakuchi_D (Assam)",
        OriginCenter: "Tamulpur_Kalakuchi_D (Assam)",
        FacilityCity: "Tamulpur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12367,
        Pin: 713158,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12368,
        Pin: 624219,
        DispatchCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        OriginCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        FacilityCity: "Vadipatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12369,
        Pin: 637403,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12370,
        Pin: 689696,
        DispatchCenter: "Adoor_Pathanapuram_D (Kerala)",
        OriginCenter: "Adoor_Pathanapuram_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12371,
        Pin: 364270,
        DispatchCenter: "Palitana_Jivapur_DPP (Gujarat)",
        OriginCenter: "Palitana_Jivapur_DPP (Gujarat)",
        FacilityCity: "Palitana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12372,
        Pin: 451411,
        DispatchCenter: "Khargone_NutanNgr_D (Madhya Pradesh)",
        OriginCenter: "Khargone_NutanNgr_D (Madhya Pradesh)",
        FacilityCity: "Khargone",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 12373,
        Pin: 181111,
        DispatchCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12374,
        Pin: 676552,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12375,
        Pin: 829203,
        DispatchCenter: "Latehar_Chandwa_DPP (Jharkhand)",
        OriginCenter: "Latehar_Chandwa_DPP (Jharkhand)",
        FacilityCity: "Latehar",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12376,
        Pin: 518122,
        DispatchCenter: "Betamcherla_Bngnpalle_D (Andhra Pradesh)",
        OriginCenter: "Betamcherla_Bngnpalle_D (Andhra Pradesh)",
        FacilityCity: "Betamcherla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12377,
        Pin: 534316,
        DispatchCenter: "Gopalapuram_Koyyalgudem_D (Andhra Pradesh)",
        OriginCenter: "Gopalapuram_Koyyalgudem_D (Andhra Pradesh)",
        FacilityCity: "Gopalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12378,
        Pin: 843320,
        DispatchCenter: "Pupri_SngihiRD_D (Bihar)",
        OriginCenter: "Pupri_SngihiRD_D (Bihar)",
        FacilityCity: "Pupri",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12379,
        Pin: 577556,
        DispatchCenter: "Davangere_Anagodu_D (Karnataka)",
        OriginCenter: "Davangere_Anagodu_D (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12380,
        Pin: 799207,
        DispatchCenter: "Khowai_Officetilla_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Khowai",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12381,
        Pin: 523270,
        DispatchCenter: "Kondapi_Anakarlapudi_D (Andhra Pradesh)",
        OriginCenter: "Kondapi_Anakarlapudi_D (Andhra Pradesh)",
        FacilityCity: "Kondapi",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12382,
        Pin: 382011,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12383,
        Pin: 493992,
        DispatchCenter: "Mahasamund_WardNo15_D (Chhattisgarh)",
        OriginCenter: "Mahasamund_WardNo15_D (Chhattisgarh)",
        FacilityCity: "Mahasamund",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12384,
        Pin: 822134,
        DispatchCenter: "Garhwa_Bishunpur_D (Jharkhand)",
        OriginCenter: "Garhwa_Bishunpur_D (Jharkhand)",
        FacilityCity: "Garhwa",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12385,
        Pin: 571111,
        DispatchCenter: "Gundlupet_ChamrajNagar_D (Karnataka)",
        OriginCenter: "Gundlupet_ChamrajNagar_D (Karnataka)",
        FacilityCity: "Gundlupet",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12386,
        Pin: 380061,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12387,
        Pin: 400105,
        DispatchCenter: "Mumbai_Kurla_L (Maharashtra)",
        OriginCenter: "Mumbai_Kurla_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12388,
        Pin: 852201,
        DispatchCenter: "Saharsa_NayaBzar_D (Bihar)",
        OriginCenter: "Saharsa_NayaBzar_D (Bihar)",
        FacilityCity: "Saharsa",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12389,
        Pin: 364490,
        DispatchCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        OriginCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12390,
        Pin: 303603,
        DispatchCenter: "Renwal_Wardno4_D (Rajasthan)",
        OriginCenter: "Renwal_Wardno4_D (Rajasthan)",
        FacilityCity: "Renwal",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12391,
        Pin: 412218,
        DispatchCenter: "Shirur_RanjangaonMIDC_L (Maharashtra)",
        OriginCenter: "Shirur_RanjangaonMIDC_L (Maharashtra)",
        FacilityCity: "Shirur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12392,
        Pin: 670731,
        DispatchCenter: "Mananthavady_Central_D_1 (Kerala)",
        OriginCenter: "Mananthavady_Central_D_1 (Kerala)",
        FacilityCity: "Mananthavadi",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12393,
        Pin: 500096,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12394,
        Pin: 202124,
        DispatchCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        OriginCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        FacilityCity: "Hathras",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12395,
        Pin: 326530,
        DispatchCenter: "RamganjMandi_AdarshNagar_D (Rajasthan)",
        OriginCenter: "RamganjMandi_AdarshNagar_D (Rajasthan)",
        FacilityCity: "Ramganj Mandi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12396,
        Pin: 630305,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12397,
        Pin: 362011,
        DispatchCenter: "Dhoraji_StationRd_DPP (Gujarat)",
        OriginCenter: "Dhoraji_StationRd_DPP (Gujarat)",
        FacilityCity: "Dhoraji",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12398,
        Pin: 828114,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12399,
        Pin: 480999,
        DispatchCenter: "Lakhnadon_Dhanora_DPP (Madhya Pradesh)",
        OriginCenter: "Lakhnadon_Dhanora_DPP (Madhya Pradesh)",
        FacilityCity: "Lakhnadon",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12400,
        Pin: 686009,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12401,
        Pin: 788710,
        DispatchCenter: "Karimganj_Sarisa_D (Assam)",
        OriginCenter: "Karimganj_Sarisa_D (Assam)",
        FacilityCity: "Karimganj",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12402,
        Pin: 752056,
        DispatchCenter: "Khurdha_MdhsnDPP_D (Orissa)",
        OriginCenter: "Khurdha_MdhsnDPP_D (Orissa)",
        FacilityCity: "Khurdha",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12403,
        Pin: 752115,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12404,
        Pin: 391245,
        DispatchCenter: "Karjan_Junabzr_DC (Gujarat)",
        OriginCenter: "Karjan_Junabzr_DC (Gujarat)",
        FacilityCity: "Karjan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12405,
        Pin: 241401,
        DispatchCenter: "Hardoi_Deorania_DPP (Uttar Pradesh)",
        OriginCenter: "Hardoi_Deorania_DPP (Uttar Pradesh)",
        FacilityCity: "Hardoi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12406,
        Pin: 835223,
        DispatchCenter: "Simdega_KhutiTola_D (Jharkhand)",
        OriginCenter: "Simdega_KhutiTola_D (Jharkhand)",
        FacilityCity: "Simdega",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12407,
        Pin: 221307,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12408,
        Pin: 415637,
        DispatchCenter: "Sangameshwar_Burambi_DPP (Maharashtra)",
        OriginCenter: "Sangameshwar_Burambi_DPP (Maharashtra)",
        FacilityCity: "Sangameshwar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12409,
        Pin: 380210,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12410,
        Pin: 761031,
        DispatchCenter: "Kodala_Beguniapada_DPP (Orissa)",
        OriginCenter: "Kodala_Beguniapada_DPP (Orissa)",
        FacilityCity: "Kodala",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12411,
        Pin: 822119,
        DispatchCenter: "Mahuadanr_Dipatoli_D (Jharkhand)",
        OriginCenter: "Mahuadanr_Dipatoli_D (Jharkhand)",
        FacilityCity: "Mahuadanr",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12412,
        Pin: 700154,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12413,
        Pin: 532425,
        DispatchCenter: "Narasannapeta_SridviNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasannapeta_SridviNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasannapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12414,
        Pin: 415004,
        DispatchCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        OriginCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        FacilityCity: "Satara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12415,
        Pin: 272123,
        DispatchCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        OriginCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        FacilityCity: "Basti",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12416,
        Pin: 612401,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12417,
        Pin: 629708,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12418,
        Pin: 110073,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12419,
        Pin: 641004,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12420,
        Pin: 517129,
        DispatchCenter: "Chittoor_Bangarupalyam_D (Andhra Pradesh)",
        OriginCenter: "Chittoor_Bangarupalyam_D (Andhra Pradesh)",
        FacilityCity: "Palamaner",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12421,
        Pin: 695507,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12422,
        Pin: 585303,
        DispatchCenter: "Jewargi_Omnagar_D (Karnataka)",
        OriginCenter: "Jewargi_Omnagar_D (Karnataka)",
        FacilityCity: "Jewargi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12423,
        Pin: 521256,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12424,
        Pin: 414102,
        DispatchCenter: "Pathardi_Shivshaktinagar_D (Maharashtra)",
        OriginCenter: "Pathardi_Shivshaktinagar_D (Maharashtra)",
        FacilityCity: "Pathardi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12425,
        Pin: 413101,
        DispatchCenter: "Akluj_SumitraNgr_D (Maharashtra)",
        OriginCenter: "Akluj_SumitraNgr_D (Maharashtra)",
        FacilityCity: "Akluj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12426,
        Pin: 577541,
        DispatchCenter: "Chitradurga_Basaveshwar_D (Karnataka)",
        OriginCenter: "Chitradurga_Basaveshwar_D (Karnataka)",
        FacilityCity: "Chitradurga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12427,
        Pin: 768201,
        DispatchCenter: "Jharsuguda_Ekatali_D (Orissa)",
        OriginCenter: "Jharsuguda_Ekatali_D (Orissa)",
        FacilityCity: "Jharsuguda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12428,
        Pin: 306107,
        DispatchCenter: "Beawar_DC (Rajasthan)",
        OriginCenter: "Beawar_DC (Rajasthan)",
        FacilityCity: "Beawar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12429,
        Pin: 799009,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12430,
        Pin: 110086,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12431,
        Pin: 813110,
        DispatchCenter: "Punsia_Hansdiha_D (Bihar)",
        OriginCenter: "Punsia_Hansdiha_D (Bihar)",
        FacilityCity: "Punsia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12432,
        Pin: 271603,
        DispatchCenter: "Itiathok_Birmapur_D (Uttar Pradesh)",
        OriginCenter: "Itiathok_Birmapur_D (Uttar Pradesh)",
        FacilityCity: "Itia Thok",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12433,
        Pin: 722148,
        DispatchCenter: "Khatra_KoralDPP_D (West Bengal)",
        OriginCenter: "Khatra_KoralDPP_D (West Bengal)",
        FacilityCity: "Khatra",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12434,
        Pin: 535578,
        DispatchCenter: "Salur_Eswar_D (Andhra Pradesh)",
        OriginCenter: "Salur_Eswar_D (Andhra Pradesh)",
        FacilityCity: "Salur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12435,
        Pin: 670614,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12436,
        Pin: 343049,
        DispatchCenter: "Bhinmal_Kaleti_D (Rajasthan)",
        OriginCenter: "Bhinmal_Kaleti_D (Rajasthan)",
        FacilityCity: "Bhinmal",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12437,
        Pin: 175005,
        DispatchCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        OriginCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        FacilityCity: "Mandi",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12438,
        Pin: 311023,
        DispatchCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        OriginCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        FacilityCity: "Bijainagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12439,
        Pin: 785675,
        DispatchCenter: "Moranhat_TengaDollong_D (Assam)",
        OriginCenter: "Moranhat_TengaDollong_D (Assam)",
        FacilityCity: "Moranhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12440,
        Pin: 497229,
        DispatchCenter: "Surajapur_Chandarpur_D (Chhattisgarh)",
        OriginCenter: "Surajapur_Chandarpur_D (Chhattisgarh)",
        FacilityCity: "Surajapur",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12441,
        Pin: 690522,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12442,
        Pin: 248121,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12443,
        Pin: 610003,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12444,
        Pin: 785002,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12445,
        Pin: 508246,
        DispatchCenter: "Huzurnagar_Miryalaguda_D (Telangana)",
        OriginCenter: "Huzurnagar_Miryalaguda_D (Telangana)",
        FacilityCity: "Huzur Nagar",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12446,
        Pin: 140128,
        DispatchCenter: "AnandpurSahib_Dher_D (Punjab)",
        OriginCenter: "AnandpurSahib_Dher_D (Punjab)",
        FacilityCity: "Anandpur Sahib",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12447,
        Pin: 261145,
        DispatchCenter: "Biswan_PatelNGR_D (Uttar Pradesh)",
        OriginCenter: "Biswan_PatelNGR_D (Uttar Pradesh)",
        FacilityCity: "Biswan",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12448,
        Pin: 786125,
        DispatchCenter: "Tinsukia_Nokhroygaon2_D (Assam)",
        OriginCenter: "Tinsukia_Nokhroygaon2_D (Assam)",
        FacilityCity: "Tinsukia",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12449,
        Pin: 142037,
        DispatchCenter: "Moga_Duneke_I (Punjab)",
        OriginCenter: "Moga_Duneke_I (Punjab)",
        FacilityCity: "Moga",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12450,
        Pin: 689572,
        DispatchCenter: "Haripad_Thalavady_D (Kerala)",
        OriginCenter: "Haripad_Thalavady_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12451,
        Pin: 365455,
        DispatchCenter: "Amreli_Bhojalpara_D (Gujarat)",
        OriginCenter: "Amreli_Bhojalpara_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12452,
        Pin: 585218,
        DispatchCenter: "Chittapur_GlbrgaRD_D (Karnataka)",
        OriginCenter: "Chittapur_GlbrgaRD_D (Karnataka)",
        FacilityCity: "Chittapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12453,
        Pin: 635653,
        DispatchCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        OriginCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        FacilityCity: "Tiruppattur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12454,
        Pin: 828304,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12455,
        Pin: 769008,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12456,
        Pin: 370485,
        DispatchCenter: "Mandvi_Gadhsisa_DPP (Gujarat)",
        OriginCenter: "Mandvi_Gadhsisa_DPP (Gujarat)",
        FacilityCity: "Mandvi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12457,
        Pin: 736179,
        DispatchCenter: "CoochBehar_khagrabari_I (West Bengal)",
        OriginCenter: "CoochBehar_khagrabari_I (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12458,
        Pin: 370202,
        DispatchCenter: "Gandhidham_BharatNagar_I (Gujarat)",
        OriginCenter: "Gandhidham_BharatNagar_I (Gujarat)",
        FacilityCity: "Gandhidham",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12459,
        Pin: 585214,
        DispatchCenter: "Gurmatkal_Mominpur_DPP (Karnataka)",
        OriginCenter: "Gurmatkal_Mominpur_DPP (Karnataka)",
        FacilityCity: "Gurmatkal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12460,
        Pin: 410505,
        DispatchCenter: "Rajgurunagar_Chandoli2_D (Maharashtra)",
        OriginCenter: "Rajgurunagar_Chandoli2_D (Maharashtra)",
        FacilityCity: "Rajgurunagar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12461,
        Pin: 363660,
        DispatchCenter: "Gandhidham_BharatNagar_D (Gujarat)",
        OriginCenter: "Gandhidham_BharatNagar_D (Gujarat)",
        FacilityCity: "Gandhidham",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12462,
        Pin: 741223,
        DispatchCenter: "Gopalnagar_ParaSttn_DPP (West Bengal)",
        OriginCenter: "Gopalnagar_ParaSttn_DPP (West Bengal)",
        FacilityCity: "Gopalnagar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12463,
        Pin: 523115,
        DispatchCenter: "Kandukur_SanthoshNagar_D (Andhra Pradesh)",
        OriginCenter: "Kandukur_SanthoshNagar_D (Andhra Pradesh)",
        FacilityCity: "Kandukur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12464,
        Pin: 580008,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12465,
        Pin: 144502,
        DispatchCenter: "Phagwara_PurewalNgr_D (Punjab)",
        OriginCenter: "Phagwara_PurewalNgr_D (Punjab)",
        FacilityCity: "Phagwara",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12466,
        Pin: 403725,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12467,
        Pin: 721463,
        DispatchCenter: "Contai_Fatepur_DPP (West Bengal)",
        OriginCenter: "Contai_Fatepur_DPP (West Bengal)",
        FacilityCity: "RamnagarWB",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12468,
        Pin: 532430,
        DispatchCenter: "Narasannapeta_SridviNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasannapeta_SridviNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasannapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12469,
        Pin: 625402,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12470,
        Pin: 125075,
        DispatchCenter: "Ellenabad_UdhamSinghChowk_D (Haryana)",
        OriginCenter: "Ellenabad_UdhamSinghChowk_D (Haryana)",
        FacilityCity: "Ellenabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12471,
        Pin: 732139,
        DispatchCenter: "Chanchal_Nayatola_DPP (West Bengal)",
        OriginCenter: "Chanchal_Nayatola_DPP (West Bengal)",
        FacilityCity: "Chanchal",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12472,
        Pin: 303301,
        DispatchCenter: "Jaipur_Ramchandpura_L (Rajasthan)",
        OriginCenter: "Jaipur_Ramchandpura_L (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12473,
        Pin: 505152,
        DispatchCenter: "Peddapalli_Rajivnagar_D (Telangana)",
        OriginCenter: "Peddapalli_Rajivnagar_D (Telangana)",
        FacilityCity: "Peddapalli",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12474,
        Pin: 713145,
        DispatchCenter: "Manteswar_MemariRD_D (West Bengal)",
        OriginCenter: "Manteswar_MemariRD_D (West Bengal)",
        FacilityCity: "Manteswar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12475,
        Pin: 600073,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12476,
        Pin: 754205,
        DispatchCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        OriginCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        FacilityCity: "Chandikhol",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12477,
        Pin: 742402,
        DispatchCenter: "Dakshinkhanda_BusStand_DPP (West Bengal)",
        OriginCenter: "Dakshinkhanda_BusStand_DPP (West Bengal)",
        FacilityCity: "Katwa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12478,
        Pin: 331501,
        DispatchCenter: "Sujangarh_Dhneru_D (Rajasthan)",
        OriginCenter: "Sujangarh_Dhneru_D (Rajasthan)",
        FacilityCity: "Sujangarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12479,
        Pin: 735220,
        DispatchCenter: "Birpara_Madarihat_DPP (West Bengal)",
        OriginCenter: "Birpara_Madarihat_DPP (West Bengal)",
        FacilityCity: "Birpara",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12480,
        Pin: 832119,
        DispatchCenter: "Seraikella_Laharitola_D (Jharkhand)",
        OriginCenter: "Seraikella_Laharitola_D (Jharkhand)",
        FacilityCity: "Seraikella",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12481,
        Pin: 522307,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12482,
        Pin: 577512,
        DispatchCenter: "Davangere_Anagodu_D (Karnataka)",
        OriginCenter: "Davangere_Anagodu_D (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12483,
        Pin: 416611,
        DispatchCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        OriginCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        FacilityCity: "Devgad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12484,
        Pin: 802132,
        DispatchCenter: "Nuaon_Block_D (Bihar)",
        OriginCenter: "Nuaon_Block_D (Bihar)",
        FacilityCity: "Nuaon",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12485,
        Pin: 143406,
        DispatchCenter: "TarnTaranSahib_BasantAvenue_D (Punjab)",
        OriginCenter: "TarnTaranSahib_BasantAvenue_D (Punjab)",
        FacilityCity: "Tarn Taran Sahib",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12486,
        Pin: 271129,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12487,
        Pin: 785688,
        DispatchCenter: "Sonari_Nakachari_D (Assam)",
        OriginCenter: "Sonari_Nakachari_D (Assam)",
        FacilityCity: "Sonari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12488,
        Pin: 679324,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12489,
        Pin: 331027,
        DispatchCenter: "Churu_Bissau_DPP (Rajasthan)",
        OriginCenter: "Churu_Bissau_DPP (Rajasthan)",
        FacilityCity: "Churu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12490,
        Pin: 591139,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12491,
        Pin: 396418,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12492,
        Pin: 625051,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12493,
        Pin: 320006,
        DispatchCenter: "Jaipur_RingRoad_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12494,
        Pin: 759026,
        DispatchCenter: "Kamakhyanagar_Mundideuli_D (Orissa)",
        OriginCenter: "Kamakhyanagar_Mundideuli_D (Orissa)",
        FacilityCity: "Kamakhyanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12495,
        Pin: 382625,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12496,
        Pin: 781340,
        DispatchCenter: "Nalbari_Chokbazar_D (Assam)",
        OriginCenter: "Nalbari_Chokbazar_D (Assam)",
        FacilityCity: "Nalbari",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12497,
        Pin: 495674,
        DispatchCenter: "Korba_Urga_D (Chhattisgarh)",
        OriginCenter: "Korba_Urga_D (Chhattisgarh)",
        FacilityCity: "Korba",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12498,
        Pin: 233310,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12499,
        Pin: 226025,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12500,
        Pin: 125102,
        DispatchCenter: "Ellenabad_UdhamSinghChowk_D (Haryana)",
        OriginCenter: "Ellenabad_UdhamSinghChowk_D (Haryana)",
        FacilityCity: "Ellenabad",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12501,
        Pin: 517432,
        DispatchCenter: "Palamaner_Rangapuram_D (Andhra Pradesh)",
        OriginCenter: "Palamaner_Rangapuram_D (Andhra Pradesh)",
        FacilityCity: "Palamaner",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12502,
        Pin: 756144,
        DispatchCenter: "Bhadrak_Purusandha_D (Orissa)",
        OriginCenter: "Bhadrak_Purusandha_D (Orissa)",
        FacilityCity: "Agarpada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12503,
        Pin: 813106,
        DispatchCenter: "Banka_Kathoun_D (Bihar)",
        OriginCenter: "Banka_Kathoun_D (Bihar)",
        FacilityCity: "Banka",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12504,
        Pin: 847306,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12505,
        Pin: 241202,
        DispatchCenter: "Sandila_SubabDPP_D (Uttar Pradesh)",
        OriginCenter: "Sandila_SubabDPP_D (Uttar Pradesh)",
        FacilityCity: "Sandila",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12506,
        Pin: 440021,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12507,
        Pin: 365460,
        DispatchCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        OriginCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12508,
        Pin: 781103,
        DispatchCenter: "Hajo_Baruabari_D (Assam)",
        OriginCenter: "Hajo_Baruabari_D (Assam)",
        FacilityCity: "Hajo",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12509,
        Pin: 680121,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12510,
        Pin: 613201,
        DispatchCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        OriginCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        FacilityCity: "Thirukkattupalli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12511,
        Pin: 207248,
        DispatchCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        OriginCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        FacilityCity: "Aliganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12512,
        Pin: 678581,
        DispatchCenter: "Agali_Thavalam_D (Kerala)",
        OriginCenter: "Agali_Thavalam_D (Kerala)",
        FacilityCity: "Agali",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12513,
        Pin: 148006,
        DispatchCenter: "Sangrur_DC (Punjab)",
        OriginCenter: "Sangrur_DC (Punjab)",
        FacilityCity: "Sangrur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12514,
        Pin: 212107,
        DispatchCenter: "JariBazar_Gambhirpur_D (Uttar Pradesh)",
        OriginCenter: "JariBazar_Gambhirpur_D (Uttar Pradesh)",
        FacilityCity: "JariBazar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12515,
        Pin: 841421,
        DispatchCenter: "Mashrakh_Benchapra_D (Bihar)",
        OriginCenter: "Mashrakh_Benchapra_D (Bihar)",
        FacilityCity: "Mashrakh",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12516,
        Pin: 571422,
        DispatchCenter: "Malavalli_KBHclny_D (Karnataka)",
        OriginCenter: "Malavalli_KBHclny_D (Karnataka)",
        FacilityCity: "Malavalli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12517,
        Pin: 441802,
        DispatchCenter: "Sakoli_GaneshWard_D (Maharashtra)",
        OriginCenter: "Sakoli_GaneshWard_D (Maharashtra)",
        FacilityCity: "Sakoli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12518,
        Pin: 173101,
        DispatchCenter: "Rajgarh_mdr3_D (Himachal Pradesh)",
        OriginCenter: "Rajgarh_mdr3_D (Himachal Pradesh)",
        FacilityCity: "Rajgarh-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12519,
        Pin: 754012,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12520,
        Pin: 848121,
        DispatchCenter: "Samastipur_RhmprRdli_D (Bihar)",
        OriginCenter: "Samastipur_RhmprRdli_D (Bihar)",
        FacilityCity: "Samastipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12521,
        Pin: 682036,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12522,
        Pin: 576120,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12523,
        Pin: 533294,
        DispatchCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        OriginCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        FacilityCity: "Rajahmundry",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12524,
        Pin: 591227,
        DispatchCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        OriginCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        FacilityCity: "Gokak",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12525,
        Pin: 626053,
        DispatchCenter: "Virudhunagar_Allampatti_DPP (Tamil Nadu)",
        OriginCenter: "Virudhunagar_Allampatti_DPP (Tamil Nadu)",
        FacilityCity: "Virudhunagar",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12526,
        Pin: 323602,
        DispatchCenter: "Keshoraipatan_Ishwar_DPP (Rajasthan)",
        OriginCenter: "Keshoraipatan_Ishwar_DPP (Rajasthan)",
        FacilityCity: "Keshoraipatan",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12527,
        Pin: 581421,
        DispatchCenter: "Bhatkal_Sodigadde_D (Karnataka)",
        OriginCenter: "Bhatkal_Sodigadde_D (Karnataka)",
        FacilityCity: "Bhatkal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12528,
        Pin: 816117,
        DispatchCenter: "Maheshpur_Grmnbank_D (Jharkhand)",
        OriginCenter: "Maheshpur_Grmnbank_D (Jharkhand)",
        FacilityCity: "Maheshpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12529,
        Pin: 609402,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12530,
        Pin: 507208,
        DispatchCenter: "Bonakal_SaiNagar_D (Telangana)",
        OriginCenter: "Bonakal_SaiNagar_D (Telangana)",
        FacilityCity: "Bonakal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12531,
        Pin: 845451,
        DispatchCenter: "Narkatiaganj_Ward19_D (Bihar)",
        OriginCenter: "Narkatiaganj_Ward19_D (Bihar)",
        FacilityCity: "Narkatiaganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12532,
        Pin: 332032,
        DispatchCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        OriginCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12533,
        Pin: 689699,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12534,
        Pin: 824143,
        DispatchCenter: "Daudnagar_Tarar_D (Bihar)",
        OriginCenter: "Daudnagar_Tarar_D (Bihar)",
        FacilityCity: "Daudnagar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12535,
        Pin: 482050,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 12536,
        Pin: 723104,
        DispatchCenter: "Purulia_GariDPP_D (West Bengal)",
        OriginCenter: "Purulia_GariDPP_D (West Bengal)",
        FacilityCity: "Purulia",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12537,
        Pin: 572119,
        DispatchCenter: "Huliyar_Azadnagar_D (Karnataka)",
        OriginCenter: "Huliyar_Azadnagar_D (Karnataka)",
        FacilityCity: "Huliyar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12538,
        Pin: 222138,
        DispatchCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        OriginCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        FacilityCity: "Jaunpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12539,
        Pin: 462041,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12540,
        Pin: 176029,
        DispatchCenter: "DehraGopipur_Central_D_1 (Himachal Pradesh)",
        OriginCenter: "DehraGopipur_Central_D_1 (Himachal Pradesh)",
        FacilityCity: "Dehra Gopipur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12541,
        Pin: 609313,
        DispatchCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        OriginCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        FacilityCity: "Karaikal",
        FacilityState: "Pondicherry",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12542,
        Pin: 583134,
        DispatchCenter: "Kotturu_Kudligi_D (Karnataka)",
        OriginCenter: "Kotturu_Kudligi_D (Karnataka)",
        FacilityCity: "Kotturu",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12543,
        Pin: 221405,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12544,
        Pin: 151002,
        DispatchCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        OriginCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        FacilityCity: "Bathinda",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12545,
        Pin: 635301,
        DispatchCenter: "Dharmapuri_Thoppur_D (Tamil Nadu)",
        OriginCenter: "Dharmapuri_Thoppur_D (Tamil Nadu)",
        FacilityCity: "Dharmapuri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12546,
        Pin: 722166,
        DispatchCenter: "Bankura_Chhatna_DPP (West Bengal)",
        OriginCenter: "Bankura_Chhatna_DPP (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12547,
        Pin: 533307,
        DispatchCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12548,
        Pin: 442204,
        DispatchCenter: "Talegaon_Ramdara_D (Maharashtra)",
        OriginCenter: "Talegaon_Ramdara_D (Maharashtra)",
        FacilityCity: "Talegaon Sp",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12549,
        Pin: 431806,
        DispatchCenter: "Bhokar_Sydngr_D (Maharashtra)",
        OriginCenter: "Bhokar_Sydngr_D (Maharashtra)",
        FacilityCity: "Bhokar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12550,
        Pin: 670601,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12551,
        Pin: 141304,
        DispatchCenter: "Phagwara_PurewalNgr_D (Punjab)",
        OriginCenter: "Phagwara_PurewalNgr_D (Punjab)",
        FacilityCity: "Phagwara",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12552,
        Pin: 226303,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12553,
        Pin: 271831,
        DispatchCenter: "Bhinga_ShubhashNagar_D (Uttar Pradesh)",
        OriginCenter: "Bhinga_ShubhashNagar_D (Uttar Pradesh)",
        FacilityCity: "Bhinga",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12554,
        Pin: 824129,
        DispatchCenter: "Goh_Golapar_D (Bihar)",
        OriginCenter: "Goh_Golapar_D (Bihar)",
        FacilityCity: "Goh",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12555,
        Pin: 713125,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12556,
        Pin: 493195,
        DispatchCenter: "Bhatapara_Nayaganj_D (Chhattisgarh)",
        OriginCenter: "Bhatapara_Nayaganj_D (Chhattisgarh)",
        FacilityCity: "Bhatapara",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12557,
        Pin: 516005,
        DispatchCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12558,
        Pin: 570011,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12559,
        Pin: 799103,
        DispatchCenter: "Udaipur_Chanban_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Udaipur-TR",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12560,
        Pin: 741158,
        DispatchCenter: "Nazirpur_Thanarpara_D (West Bengal)",
        OriginCenter: "Nazirpur_Thanarpara_D (West Bengal)",
        FacilityCity: "Nazirpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12561,
        Pin: 623319,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12562,
        Pin: 243201,
        DispatchCenter: "Baheri_Mahadevpuram_D (Uttar Pradesh)",
        OriginCenter: "Baheri_Mahadevpuram_D (Uttar Pradesh)",
        FacilityCity: "Baheri",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12563,
        Pin: 172033,
        DispatchCenter: "Anni_Dalash2_DPP (Himachal Pradesh)",
        OriginCenter: "Anni_Dalash2_DPP (Himachal Pradesh)",
        FacilityCity: "Anni",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12564,
        Pin: 211008,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12565,
        Pin: 523183,
        DispatchCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        OriginCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        FacilityCity: "Ongole",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12566,
        Pin: 142040,
        DispatchCenter: "Moga_Duneke_I (Punjab)",
        OriginCenter: "Moga_Duneke_I (Punjab)",
        FacilityCity: "Moga",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12567,
        Pin: 768211,
        DispatchCenter: "Brajrajnagar_Jagannath_DPP (Orissa)",
        OriginCenter: "Brajrajnagar_Jagannath_DPP (Orissa)",
        FacilityCity: "Brajrajnagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12568,
        Pin: 222162,
        DispatchCenter: "Mariahu_RnprJmlpr_D (Uttar Pradesh)",
        OriginCenter: "Mariahu_RnprJmlpr_D (Uttar Pradesh)",
        FacilityCity: "Mariahu",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12569,
        Pin: 622422,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12570,
        Pin: 846007,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12571,
        Pin: 614905,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12572,
        Pin: 591340,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12573,
        Pin: 625234,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12574,
        Pin: 230501,
        DispatchCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        OriginCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        FacilityCity: "Amethi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12575,
        Pin: 475661,
        DispatchCenter: "Datia_ShardaVihar_DPP (Madhya Pradesh)",
        OriginCenter: "Datia_ShardaVihar_DPP (Madhya Pradesh)",
        FacilityCity: "Datia",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 12576,
        Pin: 804454,
        DispatchCenter: "Masaurhi_Biranchi_D (Bihar)",
        OriginCenter: "Masaurhi_Biranchi_D (Bihar)",
        FacilityCity: "Masaurhi",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12577,
        Pin: 628552,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12578,
        Pin: 679535,
        DispatchCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        OriginCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        FacilityCity: "Sankaramangalam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12579,
        Pin: 811106,
        DispatchCenter: "Lakhisarai_Central_I_2 (Bihar)",
        OriginCenter: "Lakhisarai_Central_I_2 (Bihar)",
        FacilityCity: "Lakhisarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12580,
        Pin: 276207,
        DispatchCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12581,
        Pin: 623504,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12582,
        Pin: 583121,
        DispatchCenter: "Siruguppa_Yamaha_D (Karnataka)",
        OriginCenter: "Siruguppa_Yamaha_D (Karnataka)",
        FacilityCity: "Siruguppa",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12583,
        Pin: 571602,
        DispatchCenter: "Krishnarajngr_VlmkiNgr_D (Karnataka)",
        OriginCenter: "Krishnarajngr_VlmkiNgr_D (Karnataka)",
        FacilityCity: "Krishnarajngr",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12584,
        Pin: 680699,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12585,
        Pin: 182313,
        DispatchCenter: "Reasi_Marhi_D (Jammu & Kashmir)",
        OriginCenter: "Reasi_Marhi_D (Jammu & Kashmir)",
        FacilityCity: "Reasi",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12586,
        Pin: 638008,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12587,
        Pin: 301709,
        DispatchCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        OriginCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        FacilityCity: "Neemrana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12588,
        Pin: 311403,
        DispatchCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        OriginCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        FacilityCity: "Bhilwara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12589,
        Pin: 221206,
        DispatchCenter: "Babatpur_Pindra_D (Uttar Pradesh)",
        OriginCenter: "Babatpur_Pindra_D (Uttar Pradesh)",
        FacilityCity: "Jalalpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12590,
        Pin: 518220,
        DispatchCenter: "Dhone_Autonagar_D (Andhra Pradesh)",
        OriginCenter: "Dhone_Autonagar_D (Andhra Pradesh)",
        FacilityCity: "Dhone",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12591,
        Pin: 222201,
        DispatchCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        OriginCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        FacilityCity: "Mungra Badshahpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12592,
        Pin: 110063,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12593,
        Pin: 201205,
        DispatchCenter: "Modinagar_SangamAutoAg_D (Uttar Pradesh)",
        OriginCenter: "Modinagar_SangamAutoAg_D (Uttar Pradesh)",
        FacilityCity: "Modinagar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12594,
        Pin: 689511,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12595,
        Pin: 591124,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12596,
        Pin: 241403,
        DispatchCenter: "Hardoi_Deorania_DPP (Uttar Pradesh)",
        OriginCenter: "Hardoi_Deorania_DPP (Uttar Pradesh)",
        FacilityCity: "Hardoi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12597,
        Pin: 604151,
        DispatchCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        OriginCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        FacilityCity: "Gingee",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12598,
        Pin: 224284,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12599,
        Pin: 231215,
        DispatchCenter: "Robertsganj_Pakari_D (Uttar Pradesh)",
        OriginCenter: "Robertsganj_Pakari_D (Uttar Pradesh)",
        FacilityCity: "Robertsganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12600,
        Pin: 531116,
        DispatchCenter: "Narsipatnam_Sivapuram_D (Andhra Pradesh)",
        OriginCenter: "Narsipatnam_Sivapuram_D (Andhra Pradesh)",
        FacilityCity: "Narsipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12601,
        Pin: 829143,
        DispatchCenter: "Patratu_Saundabasti_D (Jharkhand)",
        OriginCenter: "Patratu_Saundabasti_D (Jharkhand)",
        FacilityCity: "Patratu",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12602,
        Pin: 176306,
        DispatchCenter: "Dalhousie_Banikhet_D (Himachal Pradesh)",
        OriginCenter: "Dalhousie_Banikhet_D (Himachal Pradesh)",
        FacilityCity: "Dalhousie",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12603,
        Pin: 388336,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12604,
        Pin: 764047,
        DispatchCenter: "Kalimela_Blockoffice_D (Orissa)",
        OriginCenter: "Kalimela_Blockoffice_D (Orissa)",
        FacilityCity: "Kalimela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12605,
        Pin: 322242,
        DispatchCenter: "Karauli_Subhashnagar_D (Rajasthan)",
        OriginCenter: "Karauli_Subhashnagar_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12606,
        Pin: 415112,
        DispatchCenter: "Karad_Malkapur1_D (Maharashtra)",
        OriginCenter: "Karad_Malkapur1_D (Maharashtra)",
        FacilityCity: "Karad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12607,
        Pin: 171302,
        DispatchCenter: "Karsog_Busstd_D (Himachal Pradesh)",
        OriginCenter: "Karsog_Busstd_D (Himachal Pradesh)",
        FacilityCity: "Karsog",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12608,
        Pin: 394101,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12609,
        Pin: 721136,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12610,
        Pin: 393020,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12611,
        Pin: 246724,
        DispatchCenter: "Nagina_DhampurRd_DPP (Uttar Pradesh)",
        OriginCenter: "Nagina_DhampurRd_DPP (Uttar Pradesh)",
        FacilityCity: "Nagina",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12612,
        Pin: 451337,
        DispatchCenter: "Khargone_NutanNgr_D (Madhya Pradesh)",
        OriginCenter: "Khargone_NutanNgr_D (Madhya Pradesh)",
        FacilityCity: "Khargone",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 12613,
        Pin: 383325,
        DispatchCenter: "Bayad_PwnPlza_D (Gujarat)",
        OriginCenter: "Bayad_PwnPlza_D (Gujarat)",
        FacilityCity: "Bayad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12614,
        Pin: 151501,
        DispatchCenter: "Mansa_Budhlada2_D (Punjab)",
        OriginCenter: "Mansa_Budhlada2_D (Punjab)",
        FacilityCity: "Mansa",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12615,
        Pin: 632317,
        DispatchCenter: "Arani_PolurRd_D (Tamil Nadu)",
        OriginCenter: "Arani_PolurRd_D (Tamil Nadu)",
        FacilityCity: "Arani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12616,
        Pin: 695603,
        DispatchCenter: "Parippally_Chvarcode_D (Kerala)",
        OriginCenter: "Parippally_Chvarcode_D (Kerala)",
        FacilityCity: "Parippally",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12617,
        Pin: 689514,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12618,
        Pin: 712138,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12619,
        Pin: 713122,
        DispatchCenter: "Kalna_BsStdDPP_D (West Bengal)",
        OriginCenter: "Kalna_BsStdDPP_D (West Bengal)",
        FacilityCity: "Kalna",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12620,
        Pin: 642114,
        DispatchCenter: "Pollachi_ThangamNagar_D (Tamil Nadu)",
        OriginCenter: "Pollachi_ThangamNagar_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12621,
        Pin: 515123,
        DispatchCenter: "Puttaprthi_Penukonda_D (Andhra Pradesh)",
        OriginCenter: "Puttaprthi_Penukonda_D (Andhra Pradesh)",
        FacilityCity: "Puttaparthi",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12622,
        Pin: 854302,
        DispatchCenter: "Purnia_Fatehpur_D (Bihar)",
        OriginCenter: "Purnia_Fatehpur_D (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12623,
        Pin: 143007,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12624,
        Pin: 304504,
        DispatchCenter: "Malpura_SadatMohalla_DPP (Rajasthan)",
        OriginCenter: "Malpura_SadatMohalla_DPP (Rajasthan)",
        FacilityCity: "Malpura",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12625,
        Pin: 756058,
        DispatchCenter: "RajBerhampur_Gohira_DPP (Orissa)",
        OriginCenter: "RajBerhampur_Gohira_DPP (Orissa)",
        FacilityCity: "Raj Berhampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12626,
        Pin: 793007,
        DispatchCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        OriginCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12627,
        Pin: 495003,
        DispatchCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        OriginCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        FacilityCity: "Bilaspur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12628,
        Pin: 490027,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12629,
        Pin: 535527,
        DispatchCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        OriginCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        FacilityCity: "Parvathipuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12630,
        Pin: 627424,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12631,
        Pin: 641037,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12632,
        Pin: 471803,
        DispatchCenter: "Tarana_Ward11_D (Madhya Pradesh)",
        OriginCenter: "Tarana_Ward11_D (Madhya Pradesh)",
        FacilityCity: "Tarana",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12633,
        Pin: 384165,
        DispatchCenter: "Unjha_SatymComplx_D (Gujarat)",
        OriginCenter: "Unjha_SatymComplx_D (Gujarat)",
        FacilityCity: "Unjha",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12634,
        Pin: 614906,
        DispatchCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        OriginCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        FacilityCity: "Pattukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12635,
        Pin: 713173,
        DispatchCenter: "Guskhara_DignagarRd_D (West Bengal)",
        OriginCenter: "Guskhara_DignagarRd_D (West Bengal)",
        FacilityCity: "Guskhara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12636,
        Pin: 273001,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12637,
        Pin: 445207,
        DispatchCenter: "Umarkhed_HubeCplx_D (Maharashtra)",
        OriginCenter: "Umarkhed_HubeCplx_D (Maharashtra)",
        FacilityCity: "Umarkhed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12638,
        Pin: 627719,
        DispatchCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        OriginCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        FacilityCity: "Sankarankovil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12639,
        Pin: 627120,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12640,
        Pin: 493222,
        DispatchCenter: "Jaijaipur_Bhatgaon_DPP (Chhattisgarh)",
        OriginCenter: "Jaijaipur_Bhatgaon_DPP (Chhattisgarh)",
        FacilityCity: "Jaijaipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12641,
        Pin: 202282,
        DispatchCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        OriginCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12642,
        Pin: 802155,
        DispatchCenter: "Jagdishpur_Ward14_D (Bihar)",
        OriginCenter: "Jagdishpur_Ward14_D (Bihar)",
        FacilityCity: "Jagdishpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12643,
        Pin: 174032,
        DispatchCenter: "Jukhala_Rihan_DPP (Himachal Pradesh)",
        OriginCenter: "Jukhala_Rihan_DPP (Himachal Pradesh)",
        FacilityCity: "Jukhala",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12644,
        Pin: 222203,
        DispatchCenter: "Mariahu_RnprJmlpr_D (Uttar Pradesh)",
        OriginCenter: "Mariahu_RnprJmlpr_D (Uttar Pradesh)",
        FacilityCity: "Mariahu",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12645,
        Pin: 443106,
        DispatchCenter: "Buldhana_Dhad_DPP (Maharashtra)",
        OriginCenter: "Buldhana_Dhad_DPP (Maharashtra)",
        FacilityCity: "Buldhana",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12646,
        Pin: 487110,
        DispatchCenter: "Narsinghpur_Stationganj_D (Madhya Pradesh)",
        OriginCenter: "Narsinghpur_Stationganj_D (Madhya Pradesh)",
        FacilityCity: "Narsinghpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 12647,
        Pin: 502247,
        DispatchCenter: "Medak_Chegunta_D (Telangana)",
        OriginCenter: "Medak_Chegunta_D (Telangana)",
        FacilityCity: "Medak",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12648,
        Pin: 847226,
        DispatchCenter: "Basopatti_Jainagar_D (Bihar)",
        OriginCenter: "Basopatti_Jainagar_D (Bihar)",
        FacilityCity: "Basopatti",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12649,
        Pin: 843014,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12650,
        Pin: 508234,
        DispatchCenter: "Kusumanchi_HydRd_D (Telangana)",
        OriginCenter: "Kusumanchi_HydRd_D (Telangana)",
        FacilityCity: "Kusumanchi",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12651,
        Pin: 441117,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12652,
        Pin: 173215,
        DispatchCenter: "Waknaghat_Rwli_DPP (Himachal Pradesh)",
        OriginCenter: "Waknaghat_Rwli_DPP (Himachal Pradesh)",
        FacilityCity: "Waknaghat",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12653,
        Pin: 686035,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12654,
        Pin: 303325,
        DispatchCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        OriginCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        FacilityCity: "Bandikui",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12655,
        Pin: 343028,
        DispatchCenter: "Jalore_RoopNgr_D (Rajasthan)",
        OriginCenter: "Jalore_RoopNgr_D (Rajasthan)",
        FacilityCity: "Jalore",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12656,
        Pin: 533445,
        DispatchCenter: "Kathipudi_YdvaSt_D (Andhra Pradesh)",
        OriginCenter: "Kathipudi_YdvaSt_D (Andhra Pradesh)",
        FacilityCity: "Kathipudi",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12657,
        Pin: 632102,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12658,
        Pin: 788002,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12659,
        Pin: 415508,
        DispatchCenter: "Vaduj_Satewdi_D (Maharashtra)",
        OriginCenter: "Vaduj_Satewdi_D (Maharashtra)",
        FacilityCity: "Vaduj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12660,
        Pin: 403513,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12661,
        Pin: 212109,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12662,
        Pin: 307027,
        DispatchCenter: "Sumerpur_RgukulColny_DPP (Rajasthan)",
        OriginCenter: "Sumerpur_RgukulColny_DPP (Rajasthan)",
        FacilityCity: "Sumerpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12663,
        Pin: 176201,
        DispatchCenter: "Palion_MndiPthankotRd_DPP (Himachal Pradesh)",
        OriginCenter: "Palion_MndiPthankotRd_DPP (Himachal Pradesh)",
        FacilityCity: "Palion",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12664,
        Pin: 210203,
        DispatchCenter: "Atarra_GangehiRd_D (Uttar Pradesh)",
        OriginCenter: "Atarra_GangehiRd_D (Uttar Pradesh)",
        FacilityCity: "Atarra",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12665,
        Pin: 574180,
        DispatchCenter: "Surathkal_Mukka2_D (Karnataka)",
        OriginCenter: "Surathkal_Mukka2_D (Karnataka)",
        FacilityCity: "Surathkal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12666,
        Pin: 686102,
        DispatchCenter: "Changanacherry_Perunna_D (Kerala)",
        OriginCenter: "Changanacherry_Perunna_D (Kerala)",
        FacilityCity: "Changanacherry",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12667,
        Pin: 841221,
        DispatchCenter: "Dighwara_AmbikaSchl_D (Bihar)",
        OriginCenter: "Dighwara_AmbikaSchl_D (Bihar)",
        FacilityCity: "Dighwara",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12668,
        Pin: 621104,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12669,
        Pin: 452023,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_Palda_L (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 12670,
        Pin: 230138,
        DispatchCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        OriginCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        FacilityCity: "Patti-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12671,
        Pin: 508277,
        DispatchCenter: "Mothkur_Mainroad_D (Telangana)",
        OriginCenter: "Mothkur_Mainroad_D (Telangana)",
        FacilityCity: "Mothkur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12672,
        Pin: 522006,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12673,
        Pin: 581213,
        DispatchCenter: "Haveri_Guttal_D (Karnataka)",
        OriginCenter: "Haveri_Guttal_D (Karnataka)",
        FacilityCity: "Haveri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12674,
        Pin: 574107,
        DispatchCenter: "Karkala_JodursteJ_D (Karnataka)",
        OriginCenter: "Karkala_JodursteJ_D (Karnataka)",
        FacilityCity: "Karkala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12675,
        Pin: 395003,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12676,
        Pin: 757018,
        DispatchCenter: "Morada_Malihata_D (Orissa)",
        OriginCenter: "Morada_Malihata_D (Orissa)",
        FacilityCity: "Morada",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12677,
        Pin: 400608,
        DispatchCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12678,
        Pin: 689571,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12679,
        Pin: 322205,
        DispatchCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        OriginCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12680,
        Pin: 802118,
        DispatchCenter: "Buxar_Ashaparari_DPP (Bihar)",
        OriginCenter: "Buxar_Ashaparari_DPP (Bihar)",
        FacilityCity: "Buxar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12681,
        Pin: 250344,
        DispatchCenter: "Daurala_Central_D_2 (Uttar Pradesh)",
        OriginCenter: "Daurala_Central_D_2 (Uttar Pradesh)",
        FacilityCity: "Daurala",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12682,
        Pin: 221502,
        DispatchCenter: "Saidabad_Naibasti_D (Uttar Pradesh)",
        OriginCenter: "Saidabad_Naibasti_D (Uttar Pradesh)",
        FacilityCity: "Saidabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12683,
        Pin: 535218,
        DispatchCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        OriginCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        FacilityCity: "Vizianagaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12684,
        Pin: 400706,
        DispatchCenter: "Mumbai_Turbhe_L (Maharashtra)",
        OriginCenter: "Mumbai_Turbhe_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12685,
        Pin: 127029,
        DispatchCenter: "Bahal_Gdccolony_D (Haryana)",
        OriginCenter: "Bahal_Gdccolony_D (Haryana)",
        FacilityCity: "Bahal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12686,
        Pin: 202393,
        DispatchCenter: "Dibai_Galibpur_D (Uttar Pradesh)",
        OriginCenter: "Dibai_Galibpur_D (Uttar Pradesh)",
        FacilityCity: "Dibai",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12687,
        Pin: 500017,
        DispatchCenter: "Hyderabad_HemaNagar_L (Telangana)",
        OriginCenter: "Hyderabad_HemaNagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12688,
        Pin: 431110,
        DispatchCenter: "Gangapur_RehnaClny_DPP (Maharashtra)",
        OriginCenter: "Gangapur_RehnaClny_DPP (Maharashtra)",
        FacilityCity: "Gangapur-MH",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12689,
        Pin: 181224,
        DispatchCenter: "Katra_Central_D_1 (Jammu & Kashmir)",
        OriginCenter: "Katra_Central_D_1 (Jammu & Kashmir)",
        FacilityCity: "Katra",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12690,
        Pin: 485778,
        DispatchCenter: "Amarpatan_Rigra_D (Madhya Pradesh)",
        OriginCenter: "Amarpatan_Rigra_D (Madhya Pradesh)",
        FacilityCity: "Amarpatan",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 12691,
        Pin: 502256,
        DispatchCenter: "Narayankhed_Turkapally_D (Telangana)",
        OriginCenter: "Narayankhed_Turkapally_D (Telangana)",
        FacilityCity: "Narayankhed",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12692,
        Pin: 370640,
        DispatchCenter: "Naliya_Jlramcpx_D (Gujarat)",
        OriginCenter: "Naliya_Jlramcpx_D (Gujarat)",
        FacilityCity: "Naliya",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12693,
        Pin: 313601,
        DispatchCenter: "Bhatewar_HoliChowk_DPP (Rajasthan)",
        OriginCenter: "Bhatewar_HoliChowk_DPP (Rajasthan)",
        FacilityCity: "Bhatewar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12694,
        Pin: 680016,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12695,
        Pin: 516001,
        DispatchCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12696,
        Pin: 462008,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12697,
        Pin: 244245,
        DispatchCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        OriginCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        FacilityCity: "Moradabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12698,
        Pin: 396055,
        DispatchCenter: "Valsad_Dharampur_D (Gujarat)",
        OriginCenter: "Valsad_Dharampur_D (Gujarat)",
        FacilityCity: "Valsad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12699,
        Pin: 854102,
        DispatchCenter: "Purnia_Banmankhi_D (Bihar)",
        OriginCenter: "Purnia_Banmankhi_D (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12700,
        Pin: 824112,
        DispatchCenter: "Sasaram_Manikpur_D (Bihar)",
        OriginCenter: "Sasaram_Manikpur_D (Bihar)",
        FacilityCity: "Sasaram",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12701,
        Pin: 843134,
        DispatchCenter: "Samastipur_RhmprRdli_D (Bihar)",
        OriginCenter: "Samastipur_RhmprRdli_D (Bihar)",
        FacilityCity: "Samastipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12702,
        Pin: 711114,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12703,
        Pin: 121003,
        DispatchCenter: "Faridabad_MathuraRoad_L (Haryana)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12704,
        Pin: 517291,
        DispatchCenter: "Madanapalle_Gurramkonda_D (Andhra Pradesh)",
        OriginCenter: "Madanapalle_Gurramkonda_D (Andhra Pradesh)",
        FacilityCity: "Madanapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12705,
        Pin: 802123,
        DispatchCenter: "Buxar_SohaniPatti_D (Bihar)",
        OriginCenter: "Buxar_SohaniPatti_D (Bihar)",
        FacilityCity: "Buxar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12706,
        Pin: 424108,
        DispatchCenter: "Chalisgaon_HarjaiRd_DPP (Maharashtra)",
        OriginCenter: "Chalisgaon_HarjaiRd_DPP (Maharashtra)",
        FacilityCity: "Chalisgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12707,
        Pin: 391240,
        DispatchCenter: "Karjan_Junabzr_DC (Gujarat)",
        OriginCenter: "Karjan_Junabzr_DC (Gujarat)",
        FacilityCity: "Karjan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12708,
        Pin: 695574,
        DispatchCenter: "Kallikkad_Mutyvila_D (Kerala)",
        OriginCenter: "Kallikkad_Mutyvila_D (Kerala)",
        FacilityCity: "Kallikkad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12709,
        Pin: 614208,
        DispatchCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        OriginCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12710,
        Pin: 847103,
        DispatchCenter: "Benipur_Karhari_D (Bihar)",
        OriginCenter: "Benipur_Karhari_D (Bihar)",
        FacilityCity: "Benipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12711,
        Pin: 574104,
        DispatchCenter: "Karkala_JodursteJ_D (Karnataka)",
        OriginCenter: "Karkala_JodursteJ_D (Karnataka)",
        FacilityCity: "Karkala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12712,
        Pin: 686576,
        DispatchCenter: "Pala_PonadKaroor_D (Kerala)",
        OriginCenter: "Pala_PonadKaroor_D (Kerala)",
        FacilityCity: "Pala",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12713,
        Pin: 501141,
        DispatchCenter: "Tandur_SnthiNGR_D (Telangana)",
        OriginCenter: "Tandur_SnthiNGR_D (Telangana)",
        FacilityCity: "Tandur-TL",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12714,
        Pin: 577502,
        DispatchCenter: "Chitradurga_Basaveshwar_D (Karnataka)",
        OriginCenter: "Chitradurga_Basaveshwar_D (Karnataka)",
        FacilityCity: "Chitradurga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12715,
        Pin: 686520,
        DispatchCenter: "Kottayam_Mulakunnath_D (Kerala)",
        OriginCenter: "Kottayam_Mulakunnath_D (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12716,
        Pin: 472010,
        DispatchCenter: "Tikamgarh_Dhonga_D (Madhya Pradesh)",
        OriginCenter: "Tikamgarh_Dhonga_D (Madhya Pradesh)",
        FacilityCity: "Tikamgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 12717,
        Pin: 762002,
        DispatchCenter: "Phulbani_NipiditaOfc_D (Orissa)",
        OriginCenter: "Phulbani_NipiditaOfc_D (Orissa)",
        FacilityCity: "Phulbani",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12718,
        Pin: 686568,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12719,
        Pin: 523156,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12720,
        Pin: 680323,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12721,
        Pin: 144024,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12722,
        Pin: 533464,
        DispatchCenter: "Yanam_DraksharamaRd_D (Andhra Pradesh)",
        OriginCenter: "Yanam_DraksharamaRd_D (Andhra Pradesh)",
        FacilityCity: "Draksharamam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12723,
        Pin: 494221,
        DispatchCenter: "Jagdalpur_Hatkchora_D (Chhattisgarh)",
        OriginCenter: "Jagdalpur_Hatkchora_D (Chhattisgarh)",
        FacilityCity: "Jagdalpur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12724,
        Pin: 575002,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12725,
        Pin: 209504,
        DispatchCenter: "Farrukhabad_Shamsabad_D (Uttar Pradesh)",
        OriginCenter: "Farrukhabad_Shamsabad_D (Uttar Pradesh)",
        FacilityCity: "Farrukhabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12726,
        Pin: 756115,
        DispatchCenter: "Bhadrak_Purusandha_D (Orissa)",
        OriginCenter: "Bhadrak_Purusandha_D (Orissa)",
        FacilityCity: "Agarpada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12727,
        Pin: 175019,
        DispatchCenter: "SundarNgr_BhojpurRd_D (Himachal Pradesh)",
        OriginCenter: "SundarNgr_BhojpurRd_D (Himachal Pradesh)",
        FacilityCity: "Sundar Nagar",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12728,
        Pin: 712123,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12729,
        Pin: 627861,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12730,
        Pin: 244921,
        DispatchCenter: "BilaspurUP_SainiColony_D (Uttar Pradesh)",
        OriginCenter: "BilaspurUP_SainiColony_D (Uttar Pradesh)",
        FacilityCity: "Bilaspur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12731,
        Pin: 722207,
        DispatchCenter: "Sonamukhi_Central_DPP_1 (West Bengal)",
        OriginCenter: "Sonamukhi_Central_DPP_1 (West Bengal)",
        FacilityCity: "Sonamukhi",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12732,
        Pin: 226000,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12733,
        Pin: 733101,
        DispatchCenter: "Balurghat_AkhirDPP_D (West Bengal)",
        OriginCenter: "Balurghat_AkhirDPP_D (West Bengal)",
        FacilityCity: "Balurghat",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12734,
        Pin: 754109,
        DispatchCenter: "Jagatsinghpur_Bnbrda_D (Orissa)",
        OriginCenter: "Jagatsinghpur_Bnbrda_D (Orissa)",
        FacilityCity: "Jagatsinghpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12735,
        Pin: 515511,
        DispatchCenter: "Mudigubba_Meeseva_D (Andhra Pradesh)",
        OriginCenter: "Mudigubba_Meeseva_D (Andhra Pradesh)",
        FacilityCity: "Mudigubba",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12736,
        Pin: 202390,
        DispatchCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        OriginCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        FacilityCity: "Anupshahar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12737,
        Pin: 575016,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12738,
        Pin: 500091,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12739,
        Pin: 572122,
        DispatchCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        OriginCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12740,
        Pin: 144702,
        DispatchCenter: "Nakodar_Goldenavenue_D (Punjab)",
        OriginCenter: "Nakodar_Goldenavenue_D (Punjab)",
        FacilityCity: "Nakodar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12741,
        Pin: 480997,
        DispatchCenter: "Lakhnadon_Dhanora_DPP (Madhya Pradesh)",
        OriginCenter: "Lakhnadon_Dhanora_DPP (Madhya Pradesh)",
        FacilityCity: "Lakhnadon",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12742,
        Pin: 626111,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12743,
        Pin: 577114,
        DispatchCenter: "Kalasa_MahaveerRd_DPP (Karnataka)",
        OriginCenter: "Kalasa_MahaveerRd_DPP (Karnataka)",
        FacilityCity: "Kalasa",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12744,
        Pin: 639116,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12745,
        Pin: 507115,
        DispatchCenter: "Kothagudem_OldPaloncha_D (Telangana)",
        OriginCenter: "Kothagudem_OldPaloncha_D (Telangana)",
        FacilityCity: "Kothagudem",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12746,
        Pin: 571110,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12747,
        Pin: 396360,
        DispatchCenter: "Bilimora_DC (Gujarat)",
        OriginCenter: "Bilimora_DC (Gujarat)",
        FacilityCity: "Bilimora",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12748,
        Pin: 679586,
        DispatchCenter: "Chiramanangad_Veliankode_D (Kerala)",
        OriginCenter: "Chiramanangad_Veliankode_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12749,
        Pin: 625217,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12750,
        Pin: 609114,
        DispatchCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        OriginCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        FacilityCity: "Sirkazhi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12751,
        Pin: 604207,
        DispatchCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        OriginCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        FacilityCity: "Tindivanam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12752,
        Pin: 421305,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12753,
        Pin: 184104,
        DispatchCenter: "Kathua_Kharote_D (Jammu & Kashmir)",
        OriginCenter: "Kathua_Kharote_D (Jammu & Kashmir)",
        FacilityCity: "Kathua",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12754,
        Pin: 522008,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12755,
        Pin: 678622,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12756,
        Pin: 470337,
        DispatchCenter: "Sagar_Pagara_P (Madhya Pradesh)",
        OriginCenter: "Sagar_Pagara_P (Madhya Pradesh)",
        FacilityCity: "Sagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12757,
        Pin: 380050,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12758,
        Pin: 494114,
        DispatchCenter: "Konta_Blockcolony_D (Chhattisgarh)",
        OriginCenter: "Konta_Blockcolony_D (Chhattisgarh)",
        FacilityCity: "Konta",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12759,
        Pin: 500110,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12760,
        Pin: 680570,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12761,
        Pin: 580009,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12762,
        Pin: 251002,
        DispatchCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        OriginCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        FacilityCity: "Muzaffarnagar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12763,
        Pin: 201020,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12764,
        Pin: 752061,
        DispatchCenter: "Khurdha_MdhsnDPP_D (Orissa)",
        OriginCenter: "Khurdha_MdhsnDPP_D (Orissa)",
        FacilityCity: "Khurdha",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12765,
        Pin: 132046,
        DispatchCenter: "Assandh_Dholchowk_D (Haryana)",
        OriginCenter: "Assandh_Dholchowk_D (Haryana)",
        FacilityCity: "Assandh",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12766,
        Pin: 413301,
        DispatchCenter: "Pandharpur_RajCmplx_D (Maharashtra)",
        OriginCenter: "Pandharpur_RajCmplx_D (Maharashtra)",
        FacilityCity: "Pandharpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12767,
        Pin: 274001,
        DispatchCenter: "Deoria_Ramnathnagar_D (Uttar Pradesh)",
        OriginCenter: "Deoria_Ramnathnagar_D (Uttar Pradesh)",
        FacilityCity: "Deoria",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12768,
        Pin: 401304,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12769,
        Pin: 712146,
        DispatchCenter: "Pandua_NvplyDPP_D (West Bengal)",
        OriginCenter: "Pandua_NvplyDPP_D (West Bengal)",
        FacilityCity: "Pandua",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12770,
        Pin: 686556,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12771,
        Pin: 603319,
        DispatchCenter: "Melmaruvathur_IOBank_D (Tamil Nadu)",
        OriginCenter: "Melmaruvathur_IOBank_D (Tamil Nadu)",
        FacilityCity: "Melmaruvathur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12772,
        Pin: 110015,
        DispatchCenter: "Delhi_KirtiNagar_L (Delhi)",
        OriginCenter: "Delhi_KirtiNagar_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12773,
        Pin: 431531,
        DispatchCenter: "Gangakher_VithalNgr_D (Maharashtra)",
        OriginCenter: "Gangakher_VithalNgr_D (Maharashtra)",
        FacilityCity: "Gangakher",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12774,
        Pin: 281401,
        DispatchCenter: "Chhata_Krishnacolony_D (Uttar Pradesh)",
        OriginCenter: "Chhata_Krishnacolony_D (Uttar Pradesh)",
        FacilityCity: "Chhata",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12775,
        Pin: 453112,
        DispatchCenter: "Indore_BarodaArjun_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12776,
        Pin: 755021,
        DispatchCenter: "Chandipur_Keshpur_D (Orissa)",
        OriginCenter: "Chandipur_Keshpur_D (Orissa)",
        FacilityCity: "Chandipur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12777,
        Pin: 621217,
        DispatchCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        OriginCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        FacilityCity: "Kulithalai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12778,
        Pin: 759145,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12779,
        Pin: 713331,
        DispatchCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        OriginCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        FacilityCity: "Rupnarayanpur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12780,
        Pin: 680104,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12781,
        Pin: 184206,
        DispatchCenter: "Chamba_Sundla_D (Himachal Pradesh)",
        OriginCenter: "Chamba_Sundla_D (Himachal Pradesh)",
        FacilityCity: "Sundla",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12782,
        Pin: 415724,
        DispatchCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        OriginCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        FacilityCity: "Shringartali",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12783,
        Pin: 805111,
        DispatchCenter: "Nawada_Gondapur_D (Bihar)",
        OriginCenter: "Nawada_Gondapur_D (Bihar)",
        FacilityCity: "Nawada",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12784,
        Pin: 847123,
        DispatchCenter: "Bharwara_AtarwelPath_D (Bihar)",
        OriginCenter: "Bharwara_AtarwelPath_D (Bihar)",
        FacilityCity: "Bharwara",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12785,
        Pin: 785006,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12786,
        Pin: 142058,
        DispatchCenter: "Dharamkot_Moga_D (Punjab)",
        OriginCenter: "Dharamkot_Moga_D (Punjab)",
        FacilityCity: "Dharamkot",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12787,
        Pin: 411028,
        DispatchCenter: "Pune_Fursungi_L (Maharashtra)",
        OriginCenter: "Pune_Fursungi_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12788,
        Pin: 206249,
        DispatchCenter: "Bidhuna_BharthnRd_D (Uttar Pradesh)",
        OriginCenter: "Bidhuna_BharthnRd_D (Uttar Pradesh)",
        FacilityCity: "Bidhuna",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12789,
        Pin: 722102,
        DispatchCenter: "Bankura_KeranDPP_D (West Bengal)",
        OriginCenter: "Bankura_KeranDPP_D (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12790,
        Pin: 515455,
        DispatchCenter: "Gooty_ATPRoad_D (Andhra Pradesh)",
        OriginCenter: "Gooty_ATPRoad_D (Andhra Pradesh)",
        FacilityCity: "Gooty",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12791,
        Pin: 827009,
        DispatchCenter: "Bokaro_Chas_D (Jharkhand)",
        OriginCenter: "Bokaro_Chas_D (Jharkhand)",
        FacilityCity: "Bokaro",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12792,
        Pin: 763001,
        DispatchCenter: "Semiliguda_HimgiriNagar_D (Orissa)",
        OriginCenter: "Semiliguda_HimgiriNagar_D (Orissa)",
        FacilityCity: "Semiliguda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12793,
        Pin: 679538,
        DispatchCenter: "Thrissur_Koonammoochi_D (Kerala)",
        OriginCenter: "Thrissur_Koonammoochi_D (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12794,
        Pin: 122105,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12795,
        Pin: 488058,
        DispatchCenter: "Pawai_Gunaur_DPP (Madhya Pradesh)",
        OriginCenter: "Pawai_Gunaur_DPP (Madhya Pradesh)",
        FacilityCity: "Pawai",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12796,
        Pin: 174012,
        DispatchCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        OriginCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        FacilityCity: "Bilaspur-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12797,
        Pin: 635812,
        DispatchCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ambur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12798,
        Pin: 394670,
        DispatchCenter: "Songadh_Vyara_D (Gujarat)",
        OriginCenter: "Songadh_Vyara_D (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12799,
        Pin: 614708,
        DispatchCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        FacilityCity: "Thiruthuraipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12800,
        Pin: 721642,
        DispatchCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        OriginCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        FacilityCity: "Tamluk",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12801,
        Pin: 303002,
        DispatchCenter: "Achrol_Arvindcolony_D (Rajasthan)",
        OriginCenter: "Achrol_Arvindcolony_D (Rajasthan)",
        FacilityCity: "Achrol",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12802,
        Pin: 689677,
        DispatchCenter: "Ranni_Chethakhl_D (Kerala)",
        OriginCenter: "Ranni_Chethakhl_D (Kerala)",
        FacilityCity: "Ranni",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12803,
        Pin: 767026,
        DispatchCenter: "Kantabanji_Ward4_D (Orissa)",
        OriginCenter: "Kantabanji_Ward4_D (Orissa)",
        FacilityCity: "Kantabanji",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12804,
        Pin: 324021,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12805,
        Pin: 490007,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12806,
        Pin: 250622,
        DispatchCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        OriginCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        FacilityCity: "Baraut",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12807,
        Pin: 835306,
        DispatchCenter: "Lohardaga_Devcolony_D (Jharkhand)",
        OriginCenter: "Lohardaga_Devcolony_D (Jharkhand)",
        FacilityCity: "Lohardaga",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12808,
        Pin: 571606,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12809,
        Pin: 122006,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12810,
        Pin: 453001,
        DispatchCenter: "Depalpur_Takipura_D (Madhya Pradesh)",
        OriginCenter: "Depalpur_Takipura_D (Madhya Pradesh)",
        FacilityCity: "Depalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12811,
        Pin: 285204,
        DispatchCenter: "Pukhrayan_Ganeshganj_D (Uttar Pradesh)",
        OriginCenter: "Pukhrayan_Ganeshganj_D (Uttar Pradesh)",
        FacilityCity: "Pukhrayan",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12812,
        Pin: 388121,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12813,
        Pin: 535501,
        DispatchCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        OriginCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        FacilityCity: "Parvathipuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12814,
        Pin: 125111,
        DispatchCenter: "Fatehabad_Kullanroad_D (Haryana)",
        OriginCenter: "Fatehabad_Kullanroad_D (Haryana)",
        FacilityCity: "Fatehabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12815,
        Pin: 123110,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12816,
        Pin: 473670,
        DispatchCenter: "Karera_DenidaRd_D (Madhya Pradesh)",
        OriginCenter: "Karera_DenidaRd_D (Madhya Pradesh)",
        FacilityCity: "Karera-MP",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12817,
        Pin: 691527,
        DispatchCenter: "Kottarakkara_MythriNagar_D (Kerala)",
        OriginCenter: "Kottarakkara_MythriNagar_D (Kerala)",
        FacilityCity: "Kottarakkara",
        FacilityState: "Kerala",
        Zone : "S2"
      },
      {
        SN: 12818,
        Pin: 795124,
        DispatchCenter: "Moirang_Bishnupur_D (Manipur)",
        OriginCenter: "Moirang_Bishnupur_D (Manipur)",
        FacilityCity: "Moirang",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12819,
        Pin: 841433,
        DispatchCenter: "Mashrakh_Benchapra_D (Bihar)",
        OriginCenter: "Mashrakh_Benchapra_D (Bihar)",
        FacilityCity: "Mashrakh",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12820,
        Pin: 171001,
        DispatchCenter: "Shimla_Tara_DC (Himachal Pradesh)",
        OriginCenter: "Shimla_Tara_DC (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12821,
        Pin: 370110,
        DispatchCenter: "Anjar_ChitrakutCir_D (Gujarat)",
        OriginCenter: "Anjar_ChitrakutCir_D (Gujarat)",
        FacilityCity: "Anjar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12822,
        Pin: 689594,
        DispatchCenter: "Karukachal_Nedumkunam_D (Kerala)",
        OriginCenter: "Karukachal_Nedumkunam_D (Kerala)",
        FacilityCity: "Karukachal",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12823,
        Pin: 680508,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12824,
        Pin: 627452,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12825,
        Pin: 441915,
        DispatchCenter: "Tumsar_Dewhadi_DPP (Maharashtra)",
        OriginCenter: "Tumsar_Dewhadi_DPP (Maharashtra)",
        FacilityCity: "Tumsar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12826,
        Pin: 628252,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12827,
        Pin: 690516,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12828,
        Pin: 144209,
        DispatchCenter: "Hoshiarpur_Hariana_D (Punjab)",
        OriginCenter: "Hoshiarpur_Hariana_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12829,
        Pin: 192232,
        DispatchCenter: "Anantnag_Bijbehara_D (Jammu & Kashmir)",
        OriginCenter: "Anantnag_Bijbehara_D (Jammu & Kashmir)",
        FacilityCity: "Anantnag",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12830,
        Pin: 424303,
        DispatchCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        OriginCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        FacilityCity: "Dhule",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12831,
        Pin: 673012,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12832,
        Pin: 425416,
        DispatchCenter: "Nandurbar_Saharatown_D (Maharashtra)",
        OriginCenter: "Nandurbar_Saharatown_D (Maharashtra)",
        FacilityCity: "Nandurbar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12833,
        Pin: 695589,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12834,
        Pin: 770052,
        DispatchCenter: "Koira_Bhanjapali_D (Orissa)",
        OriginCenter: "Koira_Bhanjapali_D (Orissa)",
        FacilityCity: "Koira",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12835,
        Pin: 768105,
        DispatchCenter: "Rairakhol_KachariRd_DPP (Orissa)",
        OriginCenter: "Rairakhol_KachariRd_DPP (Orissa)",
        FacilityCity: "Rairakhol",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12836,
        Pin: 500023,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12837,
        Pin: 680303,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12838,
        Pin: 444808,
        DispatchCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        OriginCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        FacilityCity: "Paratwada",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12839,
        Pin: 521321,
        DispatchCenter: "HanumanJn_Appanaveedu_D (Andhra Pradesh)",
        OriginCenter: "HanumanJn_Appanaveedu_D (Andhra Pradesh)",
        FacilityCity: "Pedapadu",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12840,
        Pin: 306704,
        DispatchCenter: "Khinwara_Nadol_DPP (Rajasthan)",
        OriginCenter: "Khinwara_Nadol_DPP (Rajasthan)",
        FacilityCity: "Khinwara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12841,
        Pin: 342016,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12842,
        Pin: 803121,
        DispatchCenter: "Rajgir_Naipokhar_D (Bihar)",
        OriginCenter: "Rajgir_Naipokhar_D (Bihar)",
        FacilityCity: "Rajgir",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12843,
        Pin: 384430,
        DispatchCenter: "Kadi_Jadavpura_L (Gujarat)",
        OriginCenter: "Kadi_Jadavpura_L (Gujarat)",
        FacilityCity: "Kadi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12844,
        Pin: 741163,
        DispatchCenter: "Nazirpur_Thanarpara_D (West Bengal)",
        OriginCenter: "Nazirpur_Thanarpara_D (West Bengal)",
        FacilityCity: "Nazirpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12845,
        Pin: 246734,
        DispatchCenter: "Chandpur_NoorpurRd_D (Uttar Pradesh)",
        OriginCenter: "Chandpur_NoorpurRd_D (Uttar Pradesh)",
        FacilityCity: "Chandpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12846,
        Pin: 841509,
        DispatchCenter: "Raghunathpur_DhnwtiKhp_D (Bihar)",
        OriginCenter: "Raghunathpur_DhnwtiKhp_D (Bihar)",
        FacilityCity: "Raghunathpur-BR",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12847,
        Pin: 560088,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12848,
        Pin: 673601,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12849,
        Pin: 322252,
        DispatchCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        OriginCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12850,
        Pin: 461335,
        DispatchCenter: "Harda_Khirkiya1_DPP (Madhya Pradesh)",
        OriginCenter: "Harda_Khirkiya1_DPP (Madhya Pradesh)",
        FacilityCity: "Harda",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12851,
        Pin: 841210,
        DispatchCenter: "Raghunathpur_DhnwtiKhp_D (Bihar)",
        OriginCenter: "Raghunathpur_DhnwtiKhp_D (Bihar)",
        FacilityCity: "Raghunathpur-BR",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12852,
        Pin: 832303,
        DispatchCenter: "Ghatshila_Ekramcolony_D (Jharkhand)",
        OriginCenter: "Ghatshila_Ekramcolony_D (Jharkhand)",
        FacilityCity: "Ghatshila",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12853,
        Pin: 190002,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12854,
        Pin: 209202,
        DispatchCenter: "Rasulabad_ShastriNgr_D (Uttar Pradesh)",
        OriginCenter: "Rasulabad_ShastriNgr_D (Uttar Pradesh)",
        FacilityCity: "Rasulabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12855,
        Pin: 262302,
        DispatchCenter: "Pilibhit_AvasVikas_D (Uttar Pradesh)",
        OriginCenter: "Pilibhit_AvasVikas_D (Uttar Pradesh)",
        FacilityCity: "Pilibhit",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12856,
        Pin: 606109,
        DispatchCenter: "Pennadam_Puthar_D (Tamil Nadu)",
        OriginCenter: "Pennadam_Puthar_D (Tamil Nadu)",
        FacilityCity: "Pennadam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12857,
        Pin: 786153,
        DispatchCenter: "DoomDooma_Pthrgoan_D (Assam)",
        OriginCenter: "DoomDooma_Pthrgoan_D (Assam)",
        FacilityCity: "Doom Dooma",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12858,
        Pin: 382755,
        DispatchCenter: "Patdi_Laxminagar_DPP (Gujarat)",
        OriginCenter: "Patdi_Laxminagar_DPP (Gujarat)",
        FacilityCity: "Patdi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12859,
        Pin: 222131,
        DispatchCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        OriginCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        FacilityCity: "Mungra Badshahpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12860,
        Pin: 723147,
        DispatchCenter: "Bhaddi_Tanashi_DPP (West Bengal)",
        OriginCenter: "Bhaddi_Tanashi_DPP (West Bengal)",
        FacilityCity: "Bhaddi",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12861,
        Pin: 735221,
        DispatchCenter: "Chalsa_BusStand_D (West Bengal)",
        OriginCenter: "Chalsa_BusStand_D (West Bengal)",
        FacilityCity: "Chalsa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12862,
        Pin: 125078,
        DispatchCenter: "Sirsa_Kaliawaliroad_D (Haryana)",
        OriginCenter: "Sirsa_Kaliawaliroad_D (Haryana)",
        FacilityCity: "Sirsa",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12863,
        Pin: 851116,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12864,
        Pin: 841252,
        DispatchCenter: "Siwan_Atarsua_D (Bihar)",
        OriginCenter: "Siwan_Atarsua_D (Bihar)",
        FacilityCity: "Siwan",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12865,
        Pin: 172023,
        DispatchCenter: "Rampur_Nirmand_DPP (Himachal Pradesh)",
        OriginCenter: "Rampur_Nirmand_DPP (Himachal Pradesh)",
        FacilityCity: "Rampur Bushahr",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12866,
        Pin: 160029,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12867,
        Pin: 174028,
        DispatchCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        OriginCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        FacilityCity: "Jahu",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12868,
        Pin: 612108,
        DispatchCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        OriginCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12869,
        Pin: 343032,
        DispatchCenter: "Jalore_Sayla_DPP (Rajasthan)",
        OriginCenter: "Jalore_Sayla_DPP (Rajasthan)",
        FacilityCity: "Jalore",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12870,
        Pin: 521175,
        DispatchCenter: "Jaggayyapet_MainRd_DPP (Andhra Pradesh)",
        OriginCenter: "Jaggayyapet_MainRd_DPP (Andhra Pradesh)",
        FacilityCity: "Jaggayyapet",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12871,
        Pin: 676523,
        DispatchCenter: "Pandikkad_Thangalpadi_D (Kerala)",
        OriginCenter: "Pandikkad_Thangalpadi_D (Kerala)",
        FacilityCity: "Pandikkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12872,
        Pin: 848132,
        DispatchCenter: "Samastipur_RhmprRdli_D (Bihar)",
        OriginCenter: "Samastipur_RhmprRdli_D (Bihar)",
        FacilityCity: "Samastipur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12873,
        Pin: 481337,
        DispatchCenter: "Waraseoni_Lalbarra_D (Madhya Pradesh)",
        OriginCenter: "Waraseoni_Lalbarra_D (Madhya Pradesh)",
        FacilityCity: "Waraseoni",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12874,
        Pin: 673642,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12875,
        Pin: 422215,
        DispatchCenter: "Nashik_Vani_DPP (Maharashtra)",
        OriginCenter: "Nashik_Vani_DPP (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12876,
        Pin: 803214,
        DispatchCenter: "Barh_Malahi_D (Bihar)",
        OriginCenter: "Barh_Malahi_D (Bihar)",
        FacilityCity: "Barh",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12877,
        Pin: 533215,
        DispatchCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        OriginCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        FacilityCity: "Amalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12878,
        Pin: 322021,
        DispatchCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        OriginCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        FacilityCity: "Sawai Madhopur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12879,
        Pin: 642112,
        DispatchCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        OriginCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        FacilityCity: "Udumalaipettai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12880,
        Pin: 673571,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12881,
        Pin: 835225,
        DispatchCenter: "Tamar_Raidihmore_DPP (Jharkhand)",
        OriginCenter: "Tamar_Raidihmore_DPP (Jharkhand)",
        FacilityCity: "Tamar",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12882,
        Pin: 509228,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12883,
        Pin: 734105,
        DispatchCenter: "Darjeeling_Limbugaon_D (West Bengal)",
        OriginCenter: "Darjeeling_Limbugaon_D (West Bengal)",
        FacilityCity: "Darjeeling",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12884,
        Pin: 755061,
        DispatchCenter: "Aul_Rasulpur_D (Orissa)",
        OriginCenter: "Aul_Rasulpur_D (Orissa)",
        FacilityCity: "Aul",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12885,
        Pin: 505101,
        DispatchCenter: "Huzurabad_Vivekanandanagar_D (Telangana)",
        OriginCenter: "Huzurabad_Vivekanandanagar_D (Telangana)",
        FacilityCity: "Huzurabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12886,
        Pin: 210428,
        DispatchCenter: "Rath_Saina_D (Uttar Pradesh)",
        OriginCenter: "Rath_Saina_D (Uttar Pradesh)",
        FacilityCity: "Rath",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12887,
        Pin: 517125,
        DispatchCenter: "Chittoor_Srpuram_D (Andhra Pradesh)",
        OriginCenter: "Chittoor_Srpuram_D (Andhra Pradesh)",
        FacilityCity: "Chittoor",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12888,
        Pin: 671121,
        DispatchCenter: "Kasaragod_Kudlu_D (Kerala)",
        OriginCenter: "Kasaragod_Kudlu_D (Kerala)",
        FacilityCity: "Kasaragod",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12889,
        Pin: 143102,
        DispatchCenter: "Amritsar_Ajnala_D (Punjab)",
        OriginCenter: "Amritsar_Ajnala_D (Punjab)",
        FacilityCity: "Ajnala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12890,
        Pin: 585431,
        DispatchCenter: "Bidar_AdarshClny_D (Karnataka)",
        OriginCenter: "Bidar_AdarshClny_D (Karnataka)",
        FacilityCity: "Bidar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12891,
        Pin: 521104,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12892,
        Pin: 721127,
        DispatchCenter: "Midnapore_Sarbera_DPP (West Bengal)",
        OriginCenter: "Midnapore_Sarbera_DPP (West Bengal)",
        FacilityCity: "Midnapore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12893,
        Pin: 821309,
        DispatchCenter: "Piro_Bachri_D (Bihar)",
        OriginCenter: "Piro_Bachri_D (Bihar)",
        FacilityCity: "Piro",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12894,
        Pin: 560052,
        DispatchCenter: "Bangalore_Shantinagar_L (Karnataka)",
        OriginCenter: "Bangalore_Shantinagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12895,
        Pin: 855106,
        DispatchCenter: "Thakurganj_ThanaColony_D (Bihar)",
        OriginCenter: "Thakurganj_ThanaColony_D (Bihar)",
        FacilityCity: "Thakurganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12896,
        Pin: 491002,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12897,
        Pin: 721516,
        DispatchCenter: "Midnapore_Harishpur_D (West Bengal)",
        OriginCenter: "Midnapore_Harishpur_D (West Bengal)",
        FacilityCity: "Midnapore",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12898,
        Pin: 632503,
        DispatchCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ranipet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12899,
        Pin: 246483,
        DispatchCenter: "Joshimath_Singhdhar_D (Uttarakhand)",
        OriginCenter: "Joshimath_Singhdhar_D (Uttarakhand)",
        FacilityCity: "Joshimath",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12900,
        Pin: 229010,
        DispatchCenter: "Raebareli_Ratapur_D (Uttar Pradesh)",
        OriginCenter: "Raebareli_Ratapur_D (Uttar Pradesh)",
        FacilityCity: "Raebareli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12901,
        Pin: 496220,
        DispatchCenter: "Pathalgaon_Kmkla_D (Chhattisgarh)",
        OriginCenter: "Pathalgaon_Kmkla_D (Chhattisgarh)",
        FacilityCity: "Pathalgaon",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12902,
        Pin: 441221,
        DispatchCenter: "Sindewahi_ZPSchlDPP_D (Maharashtra)",
        OriginCenter: "Sindewahi_ZPSchlDPP_D (Maharashtra)",
        FacilityCity: "Sindewahi",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12903,
        Pin: 185153,
        DispatchCenter: "Sunderbani_Ward5_D (Jammu & Kashmir)",
        OriginCenter: "Sunderbani_Ward5_D (Jammu & Kashmir)",
        FacilityCity: "Sunderbani",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12904,
        Pin: 673580,
        DispatchCenter: "Thamarassery_Raoth_D (Kerala)",
        OriginCenter: "Thamarassery_Raoth_D (Kerala)",
        FacilityCity: "Thamarassery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12905,
        Pin: 361350,
        DispatchCenter: "Dwarka_CharaklaRd_DPP (Gujarat)",
        OriginCenter: "Dwarka_CharaklaRd_DPP (Gujarat)",
        FacilityCity: "Dwarka",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12906,
        Pin: 333308,
        DispatchCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        OriginCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        FacilityCity: "Udaipurwati",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12907,
        Pin: 400014,
        DispatchCenter: "Mumbai_Sewri_L (Maharashtra)",
        OriginCenter: "Mumbai_Sewri_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12908,
        Pin: 620021,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12909,
        Pin: 390002,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12910,
        Pin: 676121,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12911,
        Pin: 363002,
        DispatchCenter: "Surendranagar_GIDC_D (Gujarat)",
        OriginCenter: "Surendranagar_GIDC_D (Gujarat)",
        FacilityCity: "Surendranagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12912,
        Pin: 176317,
        DispatchCenter: "Chamba_Sundla_D (Himachal Pradesh)",
        OriginCenter: "Chamba_Sundla_D (Himachal Pradesh)",
        FacilityCity: "Sundla",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12913,
        Pin: 431501,
        DispatchCenter: "Partur_Watur_D (Maharashtra)",
        OriginCenter: "Partur_Watur_D (Maharashtra)",
        FacilityCity: "Partur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12914,
        Pin: 689533,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12915,
        Pin: 494552,
        DispatchCenter: "Dantewada_Wardno5_D (Chhattisgarh)",
        OriginCenter: "Dantewada_Wardno5_D (Chhattisgarh)",
        FacilityCity: "Dantewada",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12916,
        Pin: 642007,
        DispatchCenter: "Pollachi_ThangamNagar_D (Tamil Nadu)",
        OriginCenter: "Pollachi_ThangamNagar_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12917,
        Pin: 384229,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12918,
        Pin: 815302,
        DispatchCenter: "Giridih_Mahthadih_D (Jharkhand)",
        OriginCenter: "Giridih_Mahthadih_D (Jharkhand)",
        FacilityCity: "Giridih",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12919,
        Pin: 760007,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12920,
        Pin: 431722,
        DispatchCenter: "Mukhed_Naigaon_DPP (Maharashtra)",
        OriginCenter: "Mukhed_Naigaon_DPP (Maharashtra)",
        FacilityCity: "Mukhed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12921,
        Pin: 517526,
        DispatchCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        OriginCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        FacilityCity: "Tirupati",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12922,
        Pin: 271804,
        DispatchCenter: "Bhinga_ShubhashNagar_D (Uttar Pradesh)",
        OriginCenter: "Bhinga_ShubhashNagar_D (Uttar Pradesh)",
        FacilityCity: "Bhinga",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12923,
        Pin: 415203,
        DispatchCenter: "Mandangad_Bhingaloli_D (Maharashtra)",
        OriginCenter: "Mandangad_Bhingaloli_D (Maharashtra)",
        FacilityCity: "Mandangad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12924,
        Pin: 815353,
        DispatchCenter: "Madhupur_Sitarmrd_D (Jharkhand)",
        OriginCenter: "Madhupur_Sitarmrd_D (Jharkhand)",
        FacilityCity: "Madhupur",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12925,
        Pin: 380019,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12926,
        Pin: 524413,
        DispatchCenter: "Vidyanagar_Kota_D (Andhra Pradesh)",
        OriginCenter: "Vidyanagar_Kota_D (Andhra Pradesh)",
        FacilityCity: "Vidyanagar",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12927,
        Pin: 248002,
        DispatchCenter: "Dehradun_Clement_L (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12928,
        Pin: 192306,
        DispatchCenter: "Srinagar_Ladhoo_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Ladhoo_H (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 12929,
        Pin: 516114,
        DispatchCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        OriginCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        FacilityCity: "Proddatur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12930,
        Pin: 606108,
        DispatchCenter: "Chinnasalem_VKootRd_D (Tamil Nadu)",
        OriginCenter: "Chinnasalem_VKootRd_D (Tamil Nadu)",
        FacilityCity: "Chinnasalem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12931,
        Pin: 690559,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12932,
        Pin: 388713,
        DispatchCenter: "Godhra_Soniwada_D (Gujarat)",
        OriginCenter: "Godhra_Soniwada_D (Gujarat)",
        FacilityCity: "Godhra",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12933,
        Pin: 785616,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12934,
        Pin: 226201,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12935,
        Pin: 144029,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12936,
        Pin: 800013,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12937,
        Pin: 450331,
        DispatchCenter: "Burhanpur_StRoad_D (Madhya Pradesh)",
        OriginCenter: "Burhanpur_StRoad_D (Madhya Pradesh)",
        FacilityCity: "Burhanpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12938,
        Pin: 800002,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12939,
        Pin: 502220,
        DispatchCenter: "Zahirabad_Mohim_D (Telangana)",
        OriginCenter: "Zahirabad_Mohim_D (Telangana)",
        FacilityCity: "Zahirabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12940,
        Pin: 415716,
        DispatchCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        OriginCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        FacilityCity: "Khed",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12941,
        Pin: 507116,
        DispatchCenter: "Manuguru_AshokN_D (Telangana)",
        OriginCenter: "Manuguru_AshokN_D (Telangana)",
        FacilityCity: "Manuguru",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12942,
        Pin: 631552,
        DispatchCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        OriginCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        FacilityCity: "Kanchipuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12943,
        Pin: 143036,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12944,
        Pin: 384285,
        DispatchCenter: "Patan_Hansapur_D (Gujarat)",
        OriginCenter: "Patan_Hansapur_D (Gujarat)",
        FacilityCity: "Patan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12945,
        Pin: 835216,
        DispatchCenter: "Khunti_JojoToli_DPP (Jharkhand)",
        OriginCenter: "Khunti_JojoToli_DPP (Jharkhand)",
        FacilityCity: "Khunti",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12946,
        Pin: 535593,
        DispatchCenter: "Salur_Eswar_D (Andhra Pradesh)",
        OriginCenter: "Salur_Eswar_D (Andhra Pradesh)",
        FacilityCity: "Salur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12947,
        Pin: 735225,
        DispatchCenter: "Chalsa_BusStand_D (West Bengal)",
        OriginCenter: "Chalsa_BusStand_D (West Bengal)",
        FacilityCity: "Chalsa",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12948,
        Pin: 686669,
        DispatchCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        OriginCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        FacilityCity: "Muvattupuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12949,
        Pin: 110301,
        DispatchCenter: "Delhi_RailYardRd_L (Delhi)",
        OriginCenter: "Delhi_RailYardRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12950,
        Pin: 700075,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12951,
        Pin: 160046,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 12952,
        Pin: 480223,
        DispatchCenter: "Amarwara_Ward14_D (Madhya Pradesh)",
        OriginCenter: "Amarwara_Ward14_D (Madhya Pradesh)",
        FacilityCity: "Amarwara",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 12953,
        Pin: 845435,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12954,
        Pin: 609118,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12955,
        Pin: 695508,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12956,
        Pin: 570033,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12957,
        Pin: 403714,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12958,
        Pin: 202126,
        DispatchCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        OriginCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12959,
        Pin: 851114,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12960,
        Pin: 500065,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12961,
        Pin: 532201,
        DispatchCenter: "Tekkali_Tolusurapalli_D (Andhra Pradesh)",
        OriginCenter: "Tekkali_Tolusurapalli_D (Andhra Pradesh)",
        FacilityCity: "Tekkali",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12962,
        Pin: 415708,
        DispatchCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        OriginCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        FacilityCity: "Khed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 12963,
        Pin: 110403,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12964,
        Pin: 522301,
        DispatchCenter: "Tenali_Pandurngpet_D (Andhra Pradesh)",
        OriginCenter: "Tenali_Pandurngpet_D (Andhra Pradesh)",
        FacilityCity: "Tenali",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12965,
        Pin: 360470,
        DispatchCenter: "Upleta_KolkiRd_DPP (Gujarat)",
        OriginCenter: "Upleta_KolkiRd_DPP (Gujarat)",
        FacilityCity: "Upleta",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 12966,
        Pin: 581363,
        DispatchCenter: "Dandeli_RKComplx_D (Karnataka)",
        OriginCenter: "Dandeli_RKComplx_D (Karnataka)",
        FacilityCity: "Dandeli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12967,
        Pin: 815318,
        DispatchCenter: "Rajdhanwar_Mayaramtola_D (Jharkhand)",
        OriginCenter: "Rajdhanwar_Mayaramtola_D (Jharkhand)",
        FacilityCity: "Rajdhanwar",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12968,
        Pin: 632301,
        DispatchCenter: "Arani_PolurRd_D (Tamil Nadu)",
        OriginCenter: "Arani_PolurRd_D (Tamil Nadu)",
        FacilityCity: "Arani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12969,
        Pin: 531084,
        DispatchCenter: "Narsipatnam_Sivapuram_D (Andhra Pradesh)",
        OriginCenter: "Narsipatnam_Sivapuram_D (Andhra Pradesh)",
        FacilityCity: "Narsipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12970,
        Pin: 403509,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12971,
        Pin: 636107,
        DispatchCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        OriginCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        FacilityCity: "Attur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12972,
        Pin: 788165,
        DispatchCenter: "Lalapur_Shyampur_D (Assam)",
        OriginCenter: "Lalapur_Shyampur_D (Assam)",
        FacilityCity: "Lalapur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12973,
        Pin: 416231,
        DispatchCenter: "Mudal_Adamapur_DPP (Maharashtra)",
        OriginCenter: "Mudal_Adamapur_DPP (Maharashtra)",
        FacilityCity: "Mudal",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12974,
        Pin: 768111,
        DispatchCenter: "Kadobahal_Dulampur_D (Orissa)",
        OriginCenter: "Kadobahal_Dulampur_D (Orissa)",
        FacilityCity: "Kadobahal",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12975,
        Pin: 843331,
        DispatchCenter: "Sitamarhi_Sursand_D (Bihar)",
        OriginCenter: "Sitamarhi_Sursand_D (Bihar)",
        FacilityCity: "Sitamarhi",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 12976,
        Pin: 281122,
        DispatchCenter: "Mathura_Narsipuramrd_D (Uttar Pradesh)",
        OriginCenter: "Mathura_Narsipuramrd_D (Uttar Pradesh)",
        FacilityCity: "Mathura",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12977,
        Pin: 756142,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12978,
        Pin: 767042,
        DispatchCenter: "Titilagarh_RSRDDPP_D (Orissa)",
        OriginCenter: "Titilagarh_RSRDDPP_D (Orissa)",
        FacilityCity: "Titilagarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12979,
        Pin: 415504,
        DispatchCenter: "Koregaon_Lokmanyanagar_D (Maharashtra)",
        OriginCenter: "Koregaon_Lokmanyanagar_D (Maharashtra)",
        FacilityCity: "Koregaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12980,
        Pin: 752085,
        DispatchCenter: "Daspalla_Andharkota_DPP (Orissa)",
        OriginCenter: "Daspalla_Andharkota_DPP (Orissa)",
        FacilityCity: "Daspalla",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12981,
        Pin: 680309,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12982,
        Pin: 283124,
        DispatchCenter: "Kheragarh_Shivmndr_D (Uttar Pradesh)",
        OriginCenter: "Kheragarh_Shivmndr_D (Uttar Pradesh)",
        FacilityCity: "Kheragarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 12983,
        Pin: 603302,
        DispatchCenter: "Chengalpattu_Cheyyur_D (Tamil Nadu)",
        OriginCenter: "Chengalpattu_Cheyyur_D (Tamil Nadu)",
        FacilityCity: "Melmaruvathur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12984,
        Pin: 690507,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 12985,
        Pin: 500264,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12986,
        Pin: 341503,
        DispatchCenter: "Degana_GovtHospital_D (Rajasthan)",
        OriginCenter: "Degana_GovtHospital_D (Rajasthan)",
        FacilityCity: "Degana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12987,
        Pin: 445103,
        DispatchCenter: "Arni_Bhimnagar_DPP (Maharashtra)",
        OriginCenter: "Arni_Bhimnagar_DPP (Maharashtra)",
        FacilityCity: "Arni",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 12988,
        Pin: 587203,
        DispatchCenter: "Kerur_WardNo2_D (Karnataka)",
        OriginCenter: "Kerur_WardNo2_D (Karnataka)",
        FacilityCity: "Kerur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 12989,
        Pin: 390006,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 12990,
        Pin: 679312,
        DispatchCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        OriginCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        FacilityCity: "Sankaramangalam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 12991,
        Pin: 767029,
        DispatchCenter: "Saintala_Blockroad_DPP (Orissa)",
        OriginCenter: "Saintala_Blockroad_DPP (Orissa)",
        FacilityCity: "Saintala",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 12992,
        Pin: 504214,
        DispatchCenter: "Chinnur_AsnsdhRD_D (Telangana)",
        OriginCenter: "Chinnur_AsnsdhRD_D (Telangana)",
        FacilityCity: "Chinnur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 12993,
        Pin: 742148,
        DispatchCenter: "Raghunathganj_NH12_D (West Bengal)",
        OriginCenter: "Raghunathganj_NH12_D (West Bengal)",
        FacilityCity: "Raghunathganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12994,
        Pin: 344043,
        DispatchCenter: "Siwana_Natiyacolony_D (Rajasthan)",
        OriginCenter: "Siwana_Natiyacolony_D (Rajasthan)",
        FacilityCity: "Siwana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12995,
        Pin: 341304,
        DispatchCenter: "Sujangarh_Khanpur_D (Rajasthan)",
        OriginCenter: "Sujangarh_Khanpur_D (Rajasthan)",
        FacilityCity: "Sujangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12996,
        Pin: 231301,
        DispatchCenter: "ChakiaUP_Ward5_D (Uttar Pradesh)",
        OriginCenter: "ChakiaUP_Ward5_D (Uttar Pradesh)",
        FacilityCity: "ChakiaUP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 12997,
        Pin: 754244,
        DispatchCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        OriginCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 12998,
        Pin: 742406,
        DispatchCenter: "Domkal_StateBank_D (West Bengal)",
        OriginCenter: "Domkal_StateBank_D (West Bengal)",
        FacilityCity: "Domkal",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 12999,
        Pin: 636108,
        DispatchCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        OriginCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        FacilityCity: "Attur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13000,
        Pin: 403527,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13001,
        Pin: 382650,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13002,
        Pin: 364760,
        DispatchCenter: "Botad_Paliyadrd_D (Gujarat)",
        OriginCenter: "Botad_Paliyadrd_D (Gujarat)",
        FacilityCity: "Botad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13003,
        Pin: 283123,
        DispatchCenter: "Fatehabad_Bah_D (Uttar Pradesh)",
        OriginCenter: "Fatehabad_Bah_D (Uttar Pradesh)",
        FacilityCity: "Fatehabad-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13004,
        Pin: 571124,
        DispatchCenter: "Mysore_Tnarsipura_DPP (Karnataka)",
        OriginCenter: "Mysore_Tnarsipura_DPP (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13005,
        Pin: 843323,
        DispatchCenter: "Sitamarhi_Punaura_D (Bihar)",
        OriginCenter: "Sitamarhi_Punaura_D (Bihar)",
        FacilityCity: "Sitamarhi",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13006,
        Pin: 110046,
        DispatchCenter: "Delhi_BamnoliVillage_L (Delhi)",
        OriginCenter: "Delhi_BamnoliVillage_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13007,
        Pin: 688544,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13008,
        Pin: 229129,
        DispatchCenter: "Salon_Bazar_DPP (Uttar Pradesh)",
        OriginCenter: "Salon_Bazar_DPP (Uttar Pradesh)",
        FacilityCity: "Salon",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13009,
        Pin: 713152,
        DispatchCenter: "Guskhara_DignagarRd_D (West Bengal)",
        OriginCenter: "Guskhara_DignagarRd_D (West Bengal)",
        FacilityCity: "Guskhara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13010,
        Pin: 210433,
        DispatchCenter: "Mahoba_Kiratsagar_D (Uttar Pradesh)",
        OriginCenter: "Mahoba_Kiratsagar_D (Uttar Pradesh)",
        FacilityCity: "Mahoba",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13011,
        Pin: 412406,
        DispatchCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        OriginCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        FacilityCity: "Ambegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13012,
        Pin: 201304,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13013,
        Pin: 176023,
        DispatchCenter: "Jawali_BhaneiLubh_DPP (Himachal Pradesh)",
        OriginCenter: "Jawali_BhaneiLubh_DPP (Himachal Pradesh)",
        FacilityCity: "Jawali",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13014,
        Pin: 577222,
        DispatchCenter: "Shimoga_Eshwariah_I (Karnataka)",
        OriginCenter: "Shimoga_Eshwariah_I (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13015,
        Pin: 743603,
        DispatchCenter: "DakshinBarasat_Raidighi_DPP (West Bengal)",
        OriginCenter: "DakshinBarasat_Raidighi_DPP (West Bengal)",
        FacilityCity: "Dakshin Barasat",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13016,
        Pin: 500008,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13017,
        Pin: 676320,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13018,
        Pin: 833220,
        DispatchCenter: "Seraikella_Laharitola_D (Jharkhand)",
        OriginCenter: "Seraikella_Laharitola_D (Jharkhand)",
        FacilityCity: "Seraikella",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13019,
        Pin: 643207,
        DispatchCenter: "Gudalur_Devarshola_DPP (Tamil Nadu)",
        OriginCenter: "Gudalur_Devarshola_DPP (Tamil Nadu)",
        FacilityCity: "Gudalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13020,
        Pin: 811317,
        DispatchCenter: "Lakhisarai_Central_I_2 (Bihar)",
        OriginCenter: "Lakhisarai_Central_I_2 (Bihar)",
        FacilityCity: "Lakhisarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13021,
        Pin: 303009,
        DispatchCenter: "Dudu_PhagiRd_D (Rajasthan)",
        OriginCenter: "Dudu_PhagiRd_D (Rajasthan)",
        FacilityCity: "Dudu",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13022,
        Pin: 813222,
        DispatchCenter: "Kahalgaon_Budhuchak_D (Bihar)",
        OriginCenter: "Kahalgaon_Budhuchak_D (Bihar)",
        FacilityCity: "Kahalgaon",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13023,
        Pin: 760009,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13024,
        Pin: 388205,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13025,
        Pin: 415412,
        DispatchCenter: "Shirala_PostOffice_DPP (Maharashtra)",
        OriginCenter: "Shirala_PostOffice_DPP (Maharashtra)",
        FacilityCity: "Shirala",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13026,
        Pin: 700042,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13027,
        Pin: 382115,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13028,
        Pin: 591211,
        DispatchCenter: "Nipani_Sakharwadi_D (Karnataka)",
        OriginCenter: "Nipani_Sakharwadi_D (Karnataka)",
        FacilityCity: "Nipani",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13029,
        Pin: 632014,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13030,
        Pin: 842004,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13031,
        Pin: 641038,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13032,
        Pin: 680310,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13033,
        Pin: 584115,
        DispatchCenter: "Lingasugur_Shivnagara_D (Karnataka)",
        OriginCenter: "Lingasugur_Shivnagara_D (Karnataka)",
        FacilityCity: "Lingasugur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13034,
        Pin: 637001,
        DispatchCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        OriginCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        FacilityCity: "Namakkal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13035,
        Pin: 695561,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13036,
        Pin: 628621,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13037,
        Pin: 625515,
        DispatchCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        OriginCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        FacilityCity: "Cumbum",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13038,
        Pin: 757083,
        DispatchCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        OriginCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        FacilityCity: "Betnoti",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13039,
        Pin: 843153,
        DispatchCenter: "Muzaffarpur_Motipur_D (Bihar)",
        OriginCenter: "Muzaffarpur_Motipur_D (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13040,
        Pin: 690509,
        DispatchCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        OriginCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13041,
        Pin: 841231,
        DispatchCenter: "Siwan_Andar_D (Bihar)",
        OriginCenter: "Siwan_Andar_D (Bihar)",
        FacilityCity: "Siwan",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13042,
        Pin: 144806,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13043,
        Pin: 402201,
        DispatchCenter: "Alibag_Shivanagar_D (Maharashtra)",
        OriginCenter: "Alibag_Shivanagar_D (Maharashtra)",
        FacilityCity: "Alibag",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13044,
        Pin: 761004,
        DispatchCenter: "Chikiti_Bhlasingi_D (Orissa)",
        OriginCenter: "Chikiti_Bhlasingi_D (Orissa)",
        FacilityCity: "Chikiti",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13045,
        Pin: 388254,
        DispatchCenter: "Balasinor_Alnoor_DPP (Gujarat)",
        OriginCenter: "Balasinor_Alnoor_DPP (Gujarat)",
        FacilityCity: "Balasinor",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13046,
        Pin: 571431,
        DispatchCenter: "Nagamangala_TBExtension_D (Karnataka)",
        OriginCenter: "Nagamangala_TBExtension_D (Karnataka)",
        FacilityCity: "Nagamangala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13047,
        Pin: 230144,
        DispatchCenter: "LalganjAjhara_HPPump_D (Uttar Pradesh)",
        OriginCenter: "LalganjAjhara_HPPump_D (Uttar Pradesh)",
        FacilityCity: "Lalganj Ajhara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13048,
        Pin: 431201,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13049,
        Pin: 641036,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13050,
        Pin: 690558,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13051,
        Pin: 401203,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13052,
        Pin: 680654,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13053,
        Pin: 465106,
        DispatchCenter: "Shajapur_Mahupura_D (Madhya Pradesh)",
        OriginCenter: "Shajapur_Mahupura_D (Madhya Pradesh)",
        FacilityCity: "Shajapur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 13054,
        Pin: 442301,
        DispatchCenter: "Hinganghat_DyanshwrWrd_D (Maharashtra)",
        OriginCenter: "Hinganghat_DyanshwrWrd_D (Maharashtra)",
        FacilityCity: "Hinganghat",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13055,
        Pin: 313323,
        DispatchCenter: "Rajsamand_Riicoarea_D (Rajasthan)",
        OriginCenter: "Rajsamand_Riicoarea_D (Rajasthan)",
        FacilityCity: "Rajsamand",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13056,
        Pin: 414302,
        DispatchCenter: "Parner_Hindchowk_D (Maharashtra)",
        OriginCenter: "Parner_Hindchowk_D (Maharashtra)",
        FacilityCity: "Parner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13057,
        Pin: 576104,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13058,
        Pin: 363623,
        DispatchCenter: "Wankaner_Railwaycolony_D (Gujarat)",
        OriginCenter: "Wankaner_Railwaycolony_D (Gujarat)",
        FacilityCity: "Wankaner",
        FacilityState: "Gujarat",
        Zone : "W1"
      },
      {
        SN: 13059,
        Pin: 711103,
        DispatchCenter: "Howrah_Kamrangu_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13060,
        Pin: 829108,
        DispatchCenter: "Ramgarh_Marar_D (Jharkhand)",
        OriginCenter: "Ramgarh_Marar_D (Jharkhand)",
        FacilityCity: "Ramgarh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13061,
        Pin: 679326,
        DispatchCenter: "Pandikkad_Thangalpadi_D (Kerala)",
        OriginCenter: "Pandikkad_Thangalpadi_D (Kerala)",
        FacilityCity: "Pandikkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13062,
        Pin: 835233,
        DispatchCenter: "Gumla_Deepnagar_D (Jharkhand)",
        OriginCenter: "Gumla_Deepnagar_D (Jharkhand)",
        FacilityCity: "Gumla",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13063,
        Pin: 680683,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13064,
        Pin: 221009,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13065,
        Pin: 605051,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13066,
        Pin: 678019,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13067,
        Pin: 272199,
        DispatchCenter: "Menhdawal_Kabeernagar_D (Uttar Pradesh)",
        OriginCenter: "Menhdawal_Kabeernagar_D (Uttar Pradesh)",
        FacilityCity: "Menhdawal",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13068,
        Pin: 577426,
        DispatchCenter: "Thirthahalli_Ripponpet_DPP (Karnataka)",
        OriginCenter: "Thirthahalli_Ripponpet_DPP (Karnataka)",
        FacilityCity: "Thirthahalli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13069,
        Pin: 176225,
        DispatchCenter: "Sihunta_Dramman_DPP (Himachal Pradesh)",
        OriginCenter: "Sihunta_Dramman_DPP (Himachal Pradesh)",
        FacilityCity: "Sihunta",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13070,
        Pin: 226029,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13071,
        Pin: 497339,
        DispatchCenter: "Baikunthpur_Mahlpra_DPP (Chhattisgarh)",
        OriginCenter: "Baikunthpur_Mahlpra_DPP (Chhattisgarh)",
        FacilityCity: "Baikunthpur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13072,
        Pin: 765019,
        DispatchCenter: "Muniguda_TehsilOfcDPP_D (Orissa)",
        OriginCenter: "Muniguda_TehsilOfcDPP_D (Orissa)",
        FacilityCity: "Muniguda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13073,
        Pin: 209602,
        DispatchCenter: "Farrukhabad_RamBanquet_D (Uttar Pradesh)",
        OriginCenter: "Farrukhabad_RamBanquet_D (Uttar Pradesh)",
        FacilityCity: "Farrukhabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13074,
        Pin: 624703,
        DispatchCenter: "Gujiliamparai_Palayam_DPP (Tamil Nadu)",
        OriginCenter: "Gujiliamparai_Palayam_DPP (Tamil Nadu)",
        FacilityCity: "Gujiliamparai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13075,
        Pin: 522502,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13076,
        Pin: 570017,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13077,
        Pin: 370415,
        DispatchCenter: "Mundra_Umiyanagar_D (Gujarat)",
        OriginCenter: "Mundra_Umiyanagar_D (Gujarat)",
        FacilityCity: "Mundra",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13078,
        Pin: 122108,
        DispatchCenter: "Nuh_Hamidcolony_D (Haryana)",
        OriginCenter: "Nuh_Hamidcolony_D (Haryana)",
        FacilityCity: "Nuh",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13079,
        Pin: 641605,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13080,
        Pin: 686516,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13081,
        Pin: 571118,
        DispatchCenter: "Nanjangud_Deviramanahalli_D (Karnataka)",
        OriginCenter: "Nanjangud_Deviramanahalli_D (Karnataka)",
        FacilityCity: "Nanjangud",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13082,
        Pin: 560300,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13083,
        Pin: 247554,
        DispatchCenter: "Deoband_Shivpuricolony_D (Uttar Pradesh)",
        OriginCenter: "Deoband_Shivpuricolony_D (Uttar Pradesh)",
        FacilityCity: "Deoband",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13084,
        Pin: 457893,
        DispatchCenter: "Alirajpur_Sorvanaka_DPP (Madhya Pradesh)",
        OriginCenter: "Alirajpur_Sorvanaka_DPP (Madhya Pradesh)",
        FacilityCity: "Alirajpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13085,
        Pin: 560073,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13086,
        Pin: 493229,
        DispatchCenter: "Tilda_Kharora_D (Chhattisgarh)",
        OriginCenter: "Tilda_Kharora_D (Chhattisgarh)",
        FacilityCity: "Tilda",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13087,
        Pin: 471405,
        DispatchCenter: "Chhatarpur_Bijawar_DPP (Madhya Pradesh)",
        OriginCenter: "Chhatarpur_Bijawar_DPP (Madhya Pradesh)",
        FacilityCity: "Malhera",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13088,
        Pin: 396045,
        DispatchCenter: "Valsad_LimbaChwk_D (Gujarat)",
        OriginCenter: "Valsad_LimbaChwk_D (Gujarat)",
        FacilityCity: "Valsad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13089,
        Pin: 388305,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13090,
        Pin: 505530,
        DispatchCenter: "KarimNagar_Alugunoor_I (Telangana)",
        OriginCenter: "KarimNagar_Alugunoor_I (Telangana)",
        FacilityCity: "Karim Nagar",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13091,
        Pin: 700086,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13092,
        Pin: 759022,
        DispatchCenter: "Dhenkanal_Rasol_D (Orissa)",
        OriginCenter: "Dhenkanal_Rasol_D (Orissa)",
        FacilityCity: "Dhenkanal",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13093,
        Pin: 486341,
        DispatchCenter: "Deotalab_Mauganj_D (Madhya Pradesh)",
        OriginCenter: "Deotalab_Mauganj_D (Madhya Pradesh)",
        FacilityCity: "Deotalab",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 13094,
        Pin: 743609,
        DispatchCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        OriginCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        FacilityCity: "Dakshin Barasat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13095,
        Pin: 577545,
        DispatchCenter: "Hiriyur_Vedvthingr_D (Karnataka)",
        OriginCenter: "Hiriyur_Vedvthingr_D (Karnataka)",
        FacilityCity: "Hiriyur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13096,
        Pin: 572145,
        DispatchCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        OriginCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13097,
        Pin: 250612,
        DispatchCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        OriginCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        FacilityCity: "Baraut",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13098,
        Pin: 743348,
        DispatchCenter: "Kakdwip_KlngrDPP_D (West Bengal)",
        OriginCenter: "Kakdwip_KlngrDPP_D (West Bengal)",
        FacilityCity: "Kakdwip",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13099,
        Pin: 751002,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13100,
        Pin: 621708,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13101,
        Pin: 632315,
        DispatchCenter: "Arani_PolurRd_D (Tamil Nadu)",
        OriginCenter: "Arani_PolurRd_D (Tamil Nadu)",
        FacilityCity: "Arani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13102,
        Pin: 756056,
        DispatchCenter: "Balasore_Ganeswarpur_D (Orissa)",
        OriginCenter: "Balasore_Ganeswarpur_D (Orissa)",
        FacilityCity: "Balasore",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13103,
        Pin: 480115,
        DispatchCenter: "Chhindwara_Chand_D (Madhya Pradesh)",
        OriginCenter: "Chhindwara_Chand_D (Madhya Pradesh)",
        FacilityCity: "Chhindwara",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13104,
        Pin: 812002,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13105,
        Pin: 500067,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13106,
        Pin: 517590,
        DispatchCenter: "Puttur_Artmclny_D (Andhra Pradesh)",
        OriginCenter: "Puttur_Artmclny_D (Andhra Pradesh)",
        FacilityCity: "Puttur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13107,
        Pin: 394310,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13108,
        Pin: 518216,
        DispatchCenter: "Kurnool_Chinnatekuru_D (Andhra Pradesh)",
        OriginCenter: "Kurnool_Chinnatekuru_D (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13109,
        Pin: 743710,
        DispatchCenter: "Bongaon_SukntDPP_D (West Bengal)",
        OriginCenter: "Bongaon_SukntDPP_D (West Bengal)",
        FacilityCity: "Bongaon",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13110,
        Pin: 824234,
        DispatchCenter: "Gaya_Matihani_H (Bihar)",
        OriginCenter: "Gaya_Matihani_H (Bihar)",
        FacilityCity: "Gaya",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13111,
        Pin: 277213,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13112,
        Pin: 390008,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13113,
        Pin: 788162,
        DispatchCenter: "Lalapur_Shyampur_D (Assam)",
        OriginCenter: "Lalapur_Shyampur_D (Assam)",
        FacilityCity: "Lalapur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13114,
        Pin: 462007,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13115,
        Pin: 224223,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13116,
        Pin: 626118,
        DispatchCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        OriginCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        FacilityCity: "Aruppukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13117,
        Pin: 132113,
        DispatchCenter: "Safidon_Urlana_D (Haryana)",
        OriginCenter: "Safidon_Urlana_D (Haryana)",
        FacilityCity: "Safidon",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13118,
        Pin: 624051,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13119,
        Pin: 811314,
        DispatchCenter: "Jhajha_Hillrange_D (Bihar)",
        OriginCenter: "Jhajha_Hillrange_D (Bihar)",
        FacilityCity: "Jhajha",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13120,
        Pin: 500059,
        DispatchCenter: "Hyderabad_Autonagar_L (Telangana)",
        OriginCenter: "Hyderabad_Autonagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13121,
        Pin: 804406,
        DispatchCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        OriginCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        FacilityCity: "Jehanabad",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13122,
        Pin: 585219,
        DispatchCenter: "Muddebihal_Nekarcolony_D (Karnataka)",
        OriginCenter: "Muddebihal_Nekarcolony_D (Karnataka)",
        FacilityCity: "Muddebihal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13123,
        Pin: 224135,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13124,
        Pin: 245206,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13125,
        Pin: 416418,
        DispatchCenter: "KMahankal_KM_D (Maharashtra)",
        OriginCenter: "KMahankal_KM_D (Maharashtra)",
        FacilityCity: "Kavathe Mahankal",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13126,
        Pin: 609604,
        DispatchCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        OriginCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        FacilityCity: "Karaikal",
        FacilityState: "Pondicherry",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13127,
        Pin: 141411,
        DispatchCenter: "MandiGobindgarh_Nasrali_D (Punjab)",
        OriginCenter: "MandiGobindgarh_Nasrali_D (Punjab)",
        FacilityCity: "Mandi Gobindgarh",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13128,
        Pin: 173209,
        DispatchCenter: "Dharampur_Kumarhatti_DPP (Himachal Pradesh)",
        OriginCenter: "Dharampur_Kumarhatti_DPP (Himachal Pradesh)",
        FacilityCity: "Dharampur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13129,
        Pin: 671545,
        DispatchCenter: "Poinachi_Central_D (Kerala)",
        OriginCenter: "Poinachi_Central_D (Kerala)",
        FacilityCity: "Poinachi",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13130,
        Pin: 388355,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13131,
        Pin: 680008,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13132,
        Pin: 623105,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13133,
        Pin: 689722,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13134,
        Pin: 843125,
        DispatchCenter: "Sahebganj_Goti_D (Bihar)",
        OriginCenter: "Sahebganj_Goti_D (Bihar)",
        FacilityCity: "Sahebganj-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13135,
        Pin: 620120,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13136,
        Pin: 785632,
        DispatchCenter: "Jorhat_Titabor_D (Assam)",
        OriginCenter: "Jorhat_Titabor_D (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13137,
        Pin: 382121,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13138,
        Pin: 782139,
        DispatchCenter: "Kaliabor_Hatiakhowa_DPP (Assam)",
        OriginCenter: "Kaliabor_Hatiakhowa_DPP (Assam)",
        FacilityCity: "Kaliabor",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13139,
        Pin: 757038,
        DispatchCenter: "Thakurmunda_Pandaposi_DPP (Orissa)",
        OriginCenter: "Thakurmunda_Pandaposi_DPP (Orissa)",
        FacilityCity: "Thakurmunda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13140,
        Pin: 122007,
        DispatchCenter: "Gurgaon_Sector52_L (Haryana)",
        OriginCenter: "Gurgaon_Sector52_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13141,
        Pin: 680555,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13142,
        Pin: 625006,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13143,
        Pin: 454341,
        DispatchCenter: "Barwani_IGcolony_D (Madhya Pradesh)",
        OriginCenter: "Barwani_IGcolony_D (Madhya Pradesh)",
        FacilityCity: "Barwani",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 13144,
        Pin: 600066,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13145,
        Pin: 457770,
        DispatchCenter: "Petlawad_Unnai_D (Madhya Pradesh)",
        OriginCenter: "Petlawad_Unnai_D (Madhya Pradesh)",
        FacilityCity: "Petlawad",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 13146,
        Pin: 585418,
        DispatchCenter: "Humnabad_ApmcYard_DPP (Karnataka)",
        OriginCenter: "Humnabad_ApmcYard_DPP (Karnataka)",
        FacilityCity: "Humnabad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13147,
        Pin: 814165,
        DispatchCenter: "Mahagama_Teachercolony_DPP (Jharkhand)",
        OriginCenter: "Mahagama_Teachercolony_DPP (Jharkhand)",
        FacilityCity: "Mahagama",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13148,
        Pin: 416509,
        DispatchCenter: "Halkarni_MajareKarve_D (Maharashtra)",
        OriginCenter: "Halkarni_MajareKarve_D (Maharashtra)",
        FacilityCity: "Halkarni",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13149,
        Pin: 122207,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13150,
        Pin: 400901,
        DispatchCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        OriginCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13151,
        Pin: 400006,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13152,
        Pin: 303902,
        DispatchCenter: "Jaipur_Ramchandpura_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13153,
        Pin: 382425,
        DispatchCenter: "Ahmedabad_Kanera_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13154,
        Pin: 613010,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13155,
        Pin: 458778,
        DispatchCenter: "Bhanpura_Lod_D (Madhya Pradesh)",
        OriginCenter: "Bhanpura_Lod_D (Madhya Pradesh)",
        FacilityCity: "Bhanpura",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 13156,
        Pin: 456661,
        DispatchCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        OriginCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        FacilityCity: "Ujjain",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13157,
        Pin: 495116,
        DispatchCenter: "Ratanpur_GandhiNagar_DPP (Chhattisgarh)",
        OriginCenter: "Ratanpur_GandhiNagar_DPP (Chhattisgarh)",
        FacilityCity: "Ratanpur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13158,
        Pin: 331304,
        DispatchCenter: "Taranagar_PoliceStation_DPP (Rajasthan)",
        OriginCenter: "Taranagar_PoliceStation_DPP (Rajasthan)",
        FacilityCity: "Taranagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13159,
        Pin: 851126,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13160,
        Pin: 678703,
        DispatchCenter: "Nenmara_Vithanaserry_D (Kerala)",
        OriginCenter: "Nenmara_Vithanaserry_D (Kerala)",
        FacilityCity: "Nenmara",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13161,
        Pin: 416022,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13162,
        Pin: 505529,
        DispatchCenter: "Jagtial_TulsiNagar_D (Telangana)",
        OriginCenter: "Jagtial_TulsiNagar_D (Telangana)",
        FacilityCity: "Jagtial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13163,
        Pin: 360001,
        DispatchCenter: "Rajkot_Amargadh_H (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13164,
        Pin: 742235,
        DispatchCenter: "Raghunathganj_NH12_D (West Bengal)",
        OriginCenter: "Raghunathganj_NH12_D (West Bengal)",
        FacilityCity: "Raghunathganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13165,
        Pin: 841287,
        DispatchCenter: "Siwan_Andar_D (Bihar)",
        OriginCenter: "Siwan_Andar_D (Bihar)",
        FacilityCity: "Siwan",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13166,
        Pin: 752013,
        DispatchCenter: "Puri_Purussottamnagar_D (Orissa)",
        OriginCenter: "Puri_Purussottamnagar_D (Orissa)",
        FacilityCity: "Puri",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13167,
        Pin: 610105,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13168,
        Pin: 173028,
        DispatchCenter: "PaontaSahib_Suryacolony_D (Himachal Pradesh)",
        OriginCenter: "PaontaSahib_Suryacolony_D (Himachal Pradesh)",
        FacilityCity: "Paonta Sahib",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13169,
        Pin: 628151,
        DispatchCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        OriginCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        FacilityCity: "Tiruchendur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13170,
        Pin: 384550,
        DispatchCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        OriginCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        FacilityCity: "Vijapur",
        FacilityState: "Gujarat",
        Zone : "W1"
      },
      {
        SN: 13171,
        Pin: 583122,
        DispatchCenter: "Siruguppa_Yamaha_D (Karnataka)",
        OriginCenter: "Siruguppa_Yamaha_D (Karnataka)",
        FacilityCity: "Siruguppa",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13172,
        Pin: 532127,
        DispatchCenter: "Rajam_Varalaxminagar_D (Andhra Pradesh)",
        OriginCenter: "Rajam_Varalaxminagar_D (Andhra Pradesh)",
        FacilityCity: "Rajam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13173,
        Pin: 743144,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13174,
        Pin: 533274,
        DispatchCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        OriginCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        FacilityCity: "Amalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13175,
        Pin: 625014,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13176,
        Pin: 464886,
        DispatchCenter: "Silwani_Padariyakalan_DPP (Madhya Pradesh)",
        OriginCenter: "Silwani_Padariyakalan_DPP (Madhya Pradesh)",
        FacilityCity: "Silwani",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13177,
        Pin: 416014,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13178,
        Pin: 845427,
        DispatchCenter: "Dhaka_PachpakriRd_D (Bihar)",
        OriginCenter: "Dhaka_PachpakriRd_D (Bihar)",
        FacilityCity: "Dhaka",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13179,
        Pin: 723156,
        DispatchCenter: "Raghunathpur_Nutandih_D (West Bengal)",
        OriginCenter: "Raghunathpur_Nutandih_D (West Bengal)",
        FacilityCity: "Raghunathpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13180,
        Pin: 486114,
        DispatchCenter: "Raipurkarchulion_Joginhai_D (Madhya Pradesh)",
        OriginCenter: "Raipurkarchulion_Joginhai_D (Madhya Pradesh)",
        FacilityCity: "Raipur Karchulion",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13181,
        Pin: 496111,
        DispatchCenter: "Raigarh_Gharghoda_D (Chhattisgarh)",
        OriginCenter: "Raigarh_Gharghoda_D (Chhattisgarh)",
        FacilityCity: "Raigarh",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13182,
        Pin: 585304,
        DispatchCenter: "Shorapur_GandhiNagar_D (Karnataka)",
        OriginCenter: "Shorapur_GandhiNagar_D (Karnataka)",
        FacilityCity: "Shorapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13183,
        Pin: 577118,
        DispatchCenter: "Koppa_Haranduru_D (Karnataka)",
        OriginCenter: "Koppa_Haranduru_D (Karnataka)",
        FacilityCity: "Koppa-ka",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13184,
        Pin: 306706,
        DispatchCenter: "Falna_Khudala_DPP (Rajasthan)",
        OriginCenter: "Falna_Khudala_DPP (Rajasthan)",
        FacilityCity: "Falna",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13185,
        Pin: 787034,
        DispatchCenter: "Gogamukh_Rupahi_D (Assam)",
        OriginCenter: "Gogamukh_Rupahi_D (Assam)",
        FacilityCity: "Gogamukh",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13186,
        Pin: 711412,
        DispatchCenter: "Khanakul_Antngr_D (West Bengal)",
        OriginCenter: "Khanakul_Antngr_D (West Bengal)",
        FacilityCity: "Khanakul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13187,
        Pin: 600038,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13188,
        Pin: 680517,
        DispatchCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        OriginCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13189,
        Pin: 784111,
        DispatchCenter: "Thelamara_NilachalPath_D (Assam)",
        OriginCenter: "Thelamara_NilachalPath_D (Assam)",
        FacilityCity: "Thelamara",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13190,
        Pin: 768020,
        DispatchCenter: "Sambalpur_Tentelpara_H (Orissa)",
        OriginCenter: "Sambalpur_Tentelpara_H (Orissa)",
        FacilityCity: "Sambalpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13191,
        Pin: 507203,
        DispatchCenter: "Madhira_Sunderaiahnagar_D (Telangana)",
        OriginCenter: "Madhira_Sunderaiahnagar_D (Telangana)",
        FacilityCity: "Madhira",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13192,
        Pin: 425304,
        DispatchCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        OriginCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        FacilityCity: "Bhusawal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13193,
        Pin: 613203,
        DispatchCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        OriginCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        FacilityCity: "Thirukkattupalli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13194,
        Pin: 690504,
        DispatchCenter: "Kayamkulam_Thamarakulam_D (Kerala)",
        OriginCenter: "Kayamkulam_Thamarakulam_D (Kerala)",
        FacilityCity: "Kayamkulam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13195,
        Pin: 458113,
        DispatchCenter: "Manasa_WardNo12_DPP (Madhya Pradesh)",
        OriginCenter: "Manasa_WardNo12_DPP (Madhya Pradesh)",
        FacilityCity: "Manasa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 13196,
        Pin: 455440,
        DispatchCenter: "Khategaon_ChohanClny_D (Madhya Pradesh)",
        OriginCenter: "Khategaon_ChohanClny_D (Madhya Pradesh)",
        FacilityCity: "Khategaon",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13197,
        Pin: 522601,
        DispatchCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasaraopet",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13198,
        Pin: 768110,
        DispatchCenter: "Pallahara_Patabeda_D (Orissa)",
        OriginCenter: "Pallahara_Patabeda_D (Orissa)",
        FacilityCity: "Pallahara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13199,
        Pin: 574106,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13200,
        Pin: 784118,
        DispatchCenter: "Thelamara_NilachalPath_D (Assam)",
        OriginCenter: "Thelamara_NilachalPath_D (Assam)",
        FacilityCity: "Thelamara",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13201,
        Pin: 535559,
        DispatchCenter: "Bobbili_Aguruvidi_D (Andhra Pradesh)",
        OriginCenter: "Bobbili_Aguruvidi_D (Andhra Pradesh)",
        FacilityCity: "Bobbili",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13202,
        Pin: 735134,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13203,
        Pin: 781021,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13204,
        Pin: 176218,
        DispatchCenter: "Dharamshala_GrudwrRd_D (Himachal Pradesh)",
        OriginCenter: "Dharamshala_GrudwrRd_D (Himachal Pradesh)",
        FacilityCity: "Dharamshala",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13205,
        Pin: 574230,
        DispatchCenter: "Kadaba_AnugrDPP_D (Karnataka)",
        OriginCenter: "Kadaba_AnugrDPP_D (Karnataka)",
        FacilityCity: "Kadaba",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13206,
        Pin: 276402,
        DispatchCenter: "Dullahpur_Sarsena_D (Uttar Pradesh)",
        OriginCenter: "Dullahpur_Sarsena_D (Uttar Pradesh)",
        FacilityCity: "Dullahpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13207,
        Pin: 534450,
        DispatchCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        OriginCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        FacilityCity: "Eluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13208,
        Pin: 755050,
        DispatchCenter: "Chandipur_Keshpur_D (Orissa)",
        OriginCenter: "Chandipur_Keshpur_D (Orissa)",
        FacilityCity: "Chandipur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13209,
        Pin: 794003,
        DispatchCenter: "Tura_Rongchugre_D (Meghalaya)",
        OriginCenter: "Tura_Rongchugre_D (Meghalaya)",
        FacilityCity: "Tura",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13210,
        Pin: 841415,
        DispatchCenter: "Marhowarah_StatinRD_D (Bihar)",
        OriginCenter: "Marhowarah_StatinRD_D (Bihar)",
        FacilityCity: "Marhowarah",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13211,
        Pin: 202120,
        DispatchCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        OriginCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13212,
        Pin: 273308,
        DispatchCenter: "Nautanwa_GandhiChwkRd_D (Uttar Pradesh)",
        OriginCenter: "Nautanwa_GandhiChwkRd_D (Uttar Pradesh)",
        FacilityCity: "Nautanwa",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13213,
        Pin: 133206,
        DispatchCenter: "Yamunanagar_Balachaur_D (Haryana)",
        OriginCenter: "Yamunanagar_Balachaur_D (Haryana)",
        FacilityCity: "Yamuna Nagar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13214,
        Pin: 430507,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13215,
        Pin: 385530,
        DispatchCenter: "Deesa_CrystalCplx_D (Gujarat)",
        OriginCenter: "Deesa_CrystalCplx_D (Gujarat)",
        FacilityCity: "Deesa",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13216,
        Pin: 495223,
        DispatchCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        OriginCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        FacilityCity: "Bilaspur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13217,
        Pin: 395023,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13218,
        Pin: 722169,
        DispatchCenter: "Bankura_Chhatna_DPP (West Bengal)",
        OriginCenter: "Bankura_Chhatna_DPP (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13219,
        Pin: 410402,
        DispatchCenter: "Lonavala_NngrgnRd_D (Maharashtra)",
        OriginCenter: "Lonavala_NngrgnRd_D (Maharashtra)",
        FacilityCity: "Lonavala",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13220,
        Pin: 847101,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13221,
        Pin: 475671,
        DispatchCenter: "Datia_BhanderSarfai_DPP (Madhya Pradesh)",
        OriginCenter: "Datia_BhanderSarfai_DPP (Madhya Pradesh)",
        FacilityCity: "Datia",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13222,
        Pin: 500029,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13223,
        Pin: 600040,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13224,
        Pin: 332722,
        DispatchCenter: "Udaipurwati_Khandela_D (Rajasthan)",
        OriginCenter: "Udaipurwati_Khandela_D (Rajasthan)",
        FacilityCity: "Khandela",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13225,
        Pin: 441209,
        DispatchCenter: "Kurkheda_Gandhiward_DPP (Maharashtra)",
        OriginCenter: "Kurkheda_Gandhiward_DPP (Maharashtra)",
        FacilityCity: "Kurkheda",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13226,
        Pin: 574115,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13227,
        Pin: 328028,
        DispatchCenter: "Dholpur_KamlaColony_D (Rajasthan)",
        OriginCenter: "Dholpur_KamlaColony_D (Rajasthan)",
        FacilityCity: "Dholpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13228,
        Pin: 841219,
        DispatchCenter: "Dighwara_AmbikaSchl_D (Bihar)",
        OriginCenter: "Dighwara_AmbikaSchl_D (Bihar)",
        FacilityCity: "Dighwara",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13229,
        Pin: 535148,
        DispatchCenter: "SKota_VizainagaramRd_DPP (Andhra Pradesh)",
        OriginCenter: "SKota_VizainagaramRd_DPP (Andhra Pradesh)",
        FacilityCity: "S Kota",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13230,
        Pin: 144005,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13231,
        Pin: 502001,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13232,
        Pin: 518003,
        DispatchCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        OriginCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13233,
        Pin: 581132,
        DispatchCenter: "Haveri_ManjunathNagar_D (Karnataka)",
        OriginCenter: "Haveri_ManjunathNagar_D (Karnataka)",
        FacilityCity: "Haveri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13234,
        Pin: 832404,
        DispatchCenter: "Chandil_Chawlibasa_DPP (Jharkhand)",
        OriginCenter: "Chandil_Chawlibasa_DPP (Jharkhand)",
        FacilityCity: "Chandil",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13235,
        Pin: 734121,
        DispatchCenter: "Darjeeling_Sonada_DPP (West Bengal)",
        OriginCenter: "Darjeeling_Sonada_DPP (West Bengal)",
        FacilityCity: "Darjeeling",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13236,
        Pin: 516116,
        DispatchCenter: "Rajampet_BypassRd_D (Andhra Pradesh)",
        OriginCenter: "Rajampet_BypassRd_D (Andhra Pradesh)",
        FacilityCity: "Rajampet",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13237,
        Pin: 246733,
        DispatchCenter: "Dhampur_Jaitra_D (Uttar Pradesh)",
        OriginCenter: "Dhampur_Jaitra_D (Uttar Pradesh)",
        FacilityCity: "Dhampur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13238,
        Pin: 221002,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13239,
        Pin: 686661,
        DispatchCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        OriginCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        FacilityCity: "Muvattupuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13240,
        Pin: 171201,
        DispatchCenter: "Theog_Janogghat_D (Himachal Pradesh)",
        OriginCenter: "Theog_Janogghat_D (Himachal Pradesh)",
        FacilityCity: "Theog",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13241,
        Pin: 388480,
        DispatchCenter: "Anand_Borsad_D (Gujarat)",
        OriginCenter: "Anand_Borsad_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13242,
        Pin: 764028,
        DispatchCenter: "Tikiri_Dasmantpur1_DPP (Orissa)",
        OriginCenter: "Tikiri_Dasmantpur1_DPP (Orissa)",
        FacilityCity: "Tikiri",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13243,
        Pin: 314021,
        DispatchCenter: "Salumbar_Aspur2_D (Rajasthan)",
        OriginCenter: "Salumbar_Aspur2_D (Rajasthan)",
        FacilityCity: "Salumbar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13244,
        Pin: 752064,
        DispatchCenter: "Kalapathar_GoleiChhak_D (Orissa)",
        OriginCenter: "Kalapathar_GoleiChhak_D (Orissa)",
        FacilityCity: "Kalapathar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13245,
        Pin: 743411,
        DispatchCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        OriginCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        FacilityCity: "Basirhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13246,
        Pin: 591243,
        DispatchCenter: "Belgaum_Hukkeri_DPP (Karnataka)",
        OriginCenter: "Belgaum_Hukkeri_DPP (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13247,
        Pin: 627862,
        DispatchCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        OriginCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        FacilityCity: "Sankarankovil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13248,
        Pin: 389360,
        DispatchCenter: "Halol_DhavalPark_D (Gujarat)",
        OriginCenter: "Halol_DhavalPark_D (Gujarat)",
        FacilityCity: "Halol",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13249,
        Pin: 805112,
        DispatchCenter: "Nawada_Govindpur_D (Bihar)",
        OriginCenter: "Nawada_Govindpur_D (Bihar)",
        FacilityCity: "Nawada",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13250,
        Pin: 307801,
        DispatchCenter: "Sirohi_Vedhaynathcolony_D (Rajasthan)",
        OriginCenter: "Sirohi_Vedhaynathcolony_D (Rajasthan)",
        FacilityCity: "Sirohi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13251,
        Pin: 444907,
        DispatchCenter: "Warud_Policestn_DPP (Maharashtra)",
        OriginCenter: "Warud_Policestn_DPP (Maharashtra)",
        FacilityCity: "Warud",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13252,
        Pin: 495112,
        DispatchCenter: "Ratanpur_GandhiNagar_DPP (Chhattisgarh)",
        OriginCenter: "Ratanpur_GandhiNagar_DPP (Chhattisgarh)",
        FacilityCity: "Ratanpur",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13253,
        Pin: 721143,
        DispatchCenter: "Jhargram_Gopiballavpur_DPP (West Bengal)",
        OriginCenter: "Jhargram_Gopiballavpur_DPP (West Bengal)",
        FacilityCity: "Jhargram",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13254,
        Pin: 673661,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13255,
        Pin: 587311,
        DispatchCenter: "Jamkhandi_Laxmingr_D (Karnataka)",
        OriginCenter: "Jamkhandi_Laxmingr_D (Karnataka)",
        FacilityCity: "Jamkhandi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13256,
        Pin: 761136,
        DispatchCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        OriginCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        FacilityCity: "Bhanjanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13257,
        Pin: 758036,
        DispatchCenter: "Barbil_Rungtacolony_D (Orissa)",
        OriginCenter: "Barbil_Rungtacolony_D (Orissa)",
        FacilityCity: "Barbil",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13258,
        Pin: 670741,
        DispatchCenter: "Thalassery_TempleGt_D (Kerala)",
        OriginCenter: "Thalassery_TempleGt_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13259,
        Pin: 785694,
        DispatchCenter: "Sonari_Nakachari_D (Assam)",
        OriginCenter: "Sonari_Nakachari_D (Assam)",
        FacilityCity: "Sonari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13260,
        Pin: 603210,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13261,
        Pin: 689621,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13262,
        Pin: 458883,
        DispatchCenter: "Garoth_Shamgarh2_DPP (Madhya Pradesh)",
        OriginCenter: "Garoth_Shamgarh2_DPP (Madhya Pradesh)",
        FacilityCity: "Garoth",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13263,
        Pin: 464881,
        DispatchCenter: "Begumganj_ShyamNgr_DPP (Madhya Pradesh)",
        OriginCenter: "Begumganj_ShyamNgr_DPP (Madhya Pradesh)",
        FacilityCity: "Begumganj",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13264,
        Pin: 384355,
        DispatchCenter: "Vadnagar_BsstdDPP_D (Gujarat)",
        OriginCenter: "Vadnagar_BsstdDPP_D (Gujarat)",
        FacilityCity: "Vadnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13265,
        Pin: 335705,
        DispatchCenter: "Pilibanga_Suratgarh_D (Rajasthan)",
        OriginCenter: "Pilibanga_Suratgarh_D (Rajasthan)",
        FacilityCity: "Suratgarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13266,
        Pin: 759147,
        DispatchCenter: "Chhendipada_Lodhabandha_DPP (Orissa)",
        OriginCenter: "Chhendipada_Lodhabandha_DPP (Orissa)",
        FacilityCity: "Chhendipada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13267,
        Pin: 485004,
        DispatchCenter: "Satna_Nazirabad_I (Madhya Pradesh)",
        OriginCenter: "Satna_Nazirabad_I (Madhya Pradesh)",
        FacilityCity: "Satna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 13268,
        Pin: 160032,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13269,
        Pin: 313706,
        DispatchCenter: "Salumbar_Bambora_DPP (Rajasthan)",
        OriginCenter: "Salumbar_Bambora_DPP (Rajasthan)",
        FacilityCity: "Salumbar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13270,
        Pin: 689502,
        DispatchCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        OriginCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13271,
        Pin: 581440,
        DispatchCenter: "Kumta_Central_DPP_2 (Karnataka)",
        OriginCenter: "Kumta_Central_DPP_2 (Karnataka)",
        FacilityCity: "Kumta",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13272,
        Pin: 560079,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13273,
        Pin: 741122,
        DispatchCenter: "Nazirpur_Thanarpara_D (West Bengal)",
        OriginCenter: "Nazirpur_Thanarpara_D (West Bengal)",
        FacilityCity: "Nazirpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13274,
        Pin: 312613,
        DispatchCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        OriginCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        FacilityCity: "Chittaurgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13275,
        Pin: 587319,
        DispatchCenter: "Mudhol_Maharancol_D (Karnataka)",
        OriginCenter: "Mudhol_Maharancol_D (Karnataka)",
        FacilityCity: "Mudhol",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13276,
        Pin: 530013,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13277,
        Pin: 302017,
        DispatchCenter: "Jaipur_Ramchandpura_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13278,
        Pin: 623532,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13279,
        Pin: 686609,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13280,
        Pin: 621303,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13281,
        Pin: 532211,
        DispatchCenter: "Tekkali_Tolusurapalli_D (Andhra Pradesh)",
        OriginCenter: "Tekkali_Tolusurapalli_D (Andhra Pradesh)",
        FacilityCity: "Tekkali",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13282,
        Pin: 782441,
        DispatchCenter: "Howraghat_Samaguri_D (Assam)",
        OriginCenter: "Howraghat_Samaguri_D (Assam)",
        FacilityCity: "Howraghat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13283,
        Pin: 757075,
        DispatchCenter: "Udala_Mayurbhanj_DPP (Orissa)",
        OriginCenter: "Udala_Mayurbhanj_DPP (Orissa)",
        FacilityCity: "Udala",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13284,
        Pin: 470120,
        DispatchCenter: "Rahatgarh_Ward13_DPP (Madhya Pradesh)",
        OriginCenter: "Rahatgarh_Ward13_DPP (Madhya Pradesh)",
        FacilityCity: "Rahatgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13285,
        Pin: 577003,
        DispatchCenter: "Davangere_Industrialarea_I (Karnataka)",
        OriginCenter: "Davangere_Industrialarea_I (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13286,
        Pin: 141204,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13287,
        Pin: 751016,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13288,
        Pin: 401206,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13289,
        Pin: 505404,
        DispatchCenter: "Siddipet_Repallewada_DPP (Telangana)",
        OriginCenter: "Siddipet_Repallewada_DPP (Telangana)",
        FacilityCity: "Siddipet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13290,
        Pin: 380003,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13291,
        Pin: 384175,
        DispatchCenter: "Unjha_SatymComplx_D (Gujarat)",
        OriginCenter: "Unjha_SatymComplx_D (Gujarat)",
        FacilityCity: "Unjha",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13292,
        Pin: 630107,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13293,
        Pin: 414208,
        DispatchCenter: "Ashti_Murshadpur_DPP (Maharashtra)",
        OriginCenter: "Ashti_Murshadpur_DPP (Maharashtra)",
        FacilityCity: "Ashti",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13294,
        Pin: 230135,
        DispatchCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        OriginCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        FacilityCity: "Patti-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13295,
        Pin: 761201,
        DispatchCenter: "Paralakhemundi_Omcolony_D (Orissa)",
        OriginCenter: "Paralakhemundi_Omcolony_D (Orissa)",
        FacilityCity: "Paralakhemundi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13296,
        Pin: 600046,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13297,
        Pin: 743247,
        DispatchCenter: "Swarupnagar_MlangaPra_DPP (West Bengal)",
        OriginCenter: "Swarupnagar_MlangaPra_DPP (West Bengal)",
        FacilityCity: "Swarupnagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13298,
        Pin: 621121,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13299,
        Pin: 143010,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13300,
        Pin: 416322,
        DispatchCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        OriginCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        FacilityCity: "Islampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13301,
        Pin: 613003,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13302,
        Pin: 400054,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13303,
        Pin: 784104,
        DispatchCenter: "Tezpur_Dipota_H (Assam)",
        OriginCenter: "Tezpur_Dipota_H (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13304,
        Pin: 444607,
        DispatchCenter: "Amravati_Sashinagar_D (Maharashtra)",
        OriginCenter: "Amravati_Sashinagar_D (Maharashtra)",
        FacilityCity: "Amravati",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13305,
        Pin: 500043,
        DispatchCenter: "Hyderabad_Medchal1_L (Telangana)",
        OriginCenter: "Hyderabad_Medchal_GW (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13306,
        Pin: 801301,
        DispatchCenter: "Hilsa_Sryamndr_D (Bihar)",
        OriginCenter: "Hilsa_Sryamndr_D (Bihar)",
        FacilityCity: "Hilsa",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13307,
        Pin: 571604,
        DispatchCenter: "Krishnarajngr_VlmkiNgr_D (Karnataka)",
        OriginCenter: "Krishnarajngr_VlmkiNgr_D (Karnataka)",
        FacilityCity: "Krishnarajngr",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13308,
        Pin: 732211,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13309,
        Pin: 585428,
        DispatchCenter: "Aurad_CBColony_D (Karnataka)",
        OriginCenter: "Aurad_CBColony_D (Karnataka)",
        FacilityCity: "Aurad",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13310,
        Pin: 455339,
        DispatchCenter: "Khategaon_ChohanClny_D (Madhya Pradesh)",
        OriginCenter: "Khategaon_ChohanClny_D (Madhya Pradesh)",
        FacilityCity: "Khategaon",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 13311,
        Pin: 413227,
        DispatchCenter: "Akkalkot_SmrathNgr_DPP (Maharashtra)",
        OriginCenter: "Akkalkot_SmrathNgr_DPP (Maharashtra)",
        FacilityCity: "Akkalkot",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13312,
        Pin: 814133,
        DispatchCenter: "Godda_Central_D_2 (Jharkhand)",
        OriginCenter: "Godda_Central_D_2 (Jharkhand)",
        FacilityCity: "Godda",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13313,
        Pin: 306101,
        DispatchCenter: "Ras_LambiyaRd_DPP (Rajasthan)",
        OriginCenter: "Ras_LambiyaRd_DPP (Rajasthan)",
        FacilityCity: "Ras",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13314,
        Pin: 636809,
        DispatchCenter: "Palacode_Dandukaranahalli_D (Tamil Nadu)",
        OriginCenter: "Palacode_Dandukaranahalli_D (Tamil Nadu)",
        FacilityCity: "Palacode",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13315,
        Pin: 516101,
        DispatchCenter: "Koduru_Jagadampalli_D (Andhra Pradesh)",
        OriginCenter: "Koduru_Jagadampalli_D (Andhra Pradesh)",
        FacilityCity: "Koduru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13316,
        Pin: 451770,
        DispatchCenter: "Pansemal_Matrala_DPP (Madhya Pradesh)",
        OriginCenter: "Pansemal_Matrala_DPP (Madhya Pradesh)",
        FacilityCity: "Pansemal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13317,
        Pin: 285251,
        DispatchCenter: "Konch_Central_D_2 (Uttar Pradesh)",
        OriginCenter: "Konch_Central_D_2 (Uttar Pradesh)",
        FacilityCity: "Konch",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13318,
        Pin: 733144,
        DispatchCenter: "Raiganj_Gobindpur_D (West Bengal)",
        OriginCenter: "Raiganj_Gobindpur_D (West Bengal)",
        FacilityCity: "Raiganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13319,
        Pin: 306116,
        DispatchCenter: "Falna_Khudala_DPP (Rajasthan)",
        OriginCenter: "Falna_Khudala_DPP (Rajasthan)",
        FacilityCity: "Falna",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13320,
        Pin: 785102,
        DispatchCenter: "Majuli_Kthnibri_D (Assam)",
        OriginCenter: "Majuli_Kthnibri_D (Assam)",
        FacilityCity: "Majuli",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13321,
        Pin: 625521,
        DispatchCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        OriginCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        FacilityCity: "Cumbum",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13322,
        Pin: 756081,
        DispatchCenter: "Basta_Darada_DPP (Orissa)",
        OriginCenter: "Basta_Darada_DPP (Orissa)",
        FacilityCity: "Basta",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13323,
        Pin: 502281,
        DispatchCenter: "Pregnapur_Tharakaramanagar_D (Telangana)",
        OriginCenter: "Pregnapur_Tharakaramanagar_D (Telangana)",
        FacilityCity: "Pregnapur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13324,
        Pin: 131022,
        DispatchCenter: "Sonipat_Sector51_L (Haryana)",
        OriginCenter: "Sonipat_WazidpurSaboli_P (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13325,
        Pin: 822115,
        DispatchCenter: "Japla_Darua_D (Jharkhand)",
        OriginCenter: "Japla_Darua_D (Jharkhand)",
        FacilityCity: "Japla",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13326,
        Pin: 610103,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13327,
        Pin: 415116,
        DispatchCenter: "Rahimatpur_Wathar_DPP (Maharashtra)",
        OriginCenter: "Rahimatpur_Wathar_DPP (Maharashtra)",
        FacilityCity: "Rahimatpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13328,
        Pin: 689501,
        DispatchCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        OriginCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13329,
        Pin: 410509,
        DispatchCenter: 37043.00011574074,
        OriginCenter: 37043.00011574074,
        FacilityCity: "Junnar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13330,
        Pin: 416222,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13331,
        Pin: 465223,
        DispatchCenter: "Shujalpur_JNSCollege_D (Madhya Pradesh)",
        OriginCenter: "Shujalpur_JNSCollege_D (Madhya Pradesh)",
        FacilityCity: "Shujalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 13332,
        Pin: 695033,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13333,
        Pin: 228155,
        DispatchCenter: "Sultanpur_Balpur_D (Uttar Pradesh)",
        OriginCenter: "Sultanpur_Balpur_D (Uttar Pradesh)",
        FacilityCity: "Sultanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13334,
        Pin: 301413,
        DispatchCenter: "RajgarhRJ_KarothRd_D (Rajasthan)",
        OriginCenter: "RajgarhRJ_KarothRd_D (Rajasthan)",
        FacilityCity: "Rajgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13335,
        Pin: 521163,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13336,
        Pin: 737137,
        DispatchCenter: "Rangpo_Khanikhola_I (Sikkim)",
        OriginCenter: "Rangpo_Khanikhola_I (Sikkim)",
        FacilityCity: "Rangpo",
        FacilityState: "Sikkim",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13337,
        Pin: 643101,
        DispatchCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        OriginCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13338,
        Pin: 840001,
        DispatchCenter: "Gumla_Deepnagar_D (Jharkhand)",
        OriginCenter: "Gumla_Deepnagar_D (Jharkhand)",
        FacilityCity: "Gumla",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13339,
        Pin: 263652,
        DispatchCenter: "Ranikhet_KhariBazar_DPP (Uttarakhand)",
        OriginCenter: "Ranikhet_KhariBazar_DPP (Uttarakhand)",
        FacilityCity: "Ranikhet",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13340,
        Pin: 334604,
        DispatchCenter: "Lunkaransar_Ajadcolony_D (Rajasthan)",
        OriginCenter: "Lunkaransar_Ajadcolony_D (Rajasthan)",
        FacilityCity: "Lunkaransar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13341,
        Pin: 144417,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13342,
        Pin: 678011,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13343,
        Pin: 248145,
        DispatchCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        OriginCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        FacilityCity: "Rishikesh",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13344,
        Pin: 678003,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13345,
        Pin: 140308,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13346,
        Pin: 571251,
        DispatchCenter: "Madikeri_Ranipet_D (Karnataka)",
        OriginCenter: "Madikeri_Ranipet_D (Karnataka)",
        FacilityCity: "Madikeri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13347,
        Pin: 243127,
        DispatchCenter: "Faridpur_RanaCLNY_D (Uttar Pradesh)",
        OriginCenter: "Faridpur_RanaCLNY_D (Uttar Pradesh)",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13348,
        Pin: 521456,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13349,
        Pin: 562300,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13350,
        Pin: 401701,
        DispatchCenter: "Bhilad_Umargaon_DC (Gujarat)",
        OriginCenter: "Bhilad_Umargaon_DC (Gujarat)",
        FacilityCity: "Bhilad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13351,
        Pin: 695121,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13352,
        Pin: 764069,
        DispatchCenter: "Nabarangpr_PhdofDPP_D (Orissa)",
        OriginCenter: "Nabarangpr_PhdofDPP_D (Orissa)",
        FacilityCity: "Nabarangapur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13353,
        Pin: 441225,
        DispatchCenter: "Saoli_NagarPanchyt_DPP (Maharashtra)",
        OriginCenter: "Saoli_NagarPanchyt_DPP (Maharashtra)",
        FacilityCity: "Saoli",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13354,
        Pin: 522508,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13355,
        Pin: 274602,
        DispatchCenter: "Salempur_Banjaria_D (Uttar Pradesh)",
        OriginCenter: "Salempur_Banjaria_D (Uttar Pradesh)",
        FacilityCity: "Salempur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13356,
        Pin: 413248,
        DispatchCenter: "Solapur_Kalasenagar_D (Maharashtra)",
        OriginCenter: "Solapur_Kalasenagar_D (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13357,
        Pin: 518390,
        DispatchCenter: "Gooty_ATPRoad_D (Andhra Pradesh)",
        OriginCenter: "Gooty_ATPRoad_D (Andhra Pradesh)",
        FacilityCity: "Gooty",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13358,
        Pin: 629158,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13359,
        Pin: 841417,
        DispatchCenter: "Mashrakh_Benchapra_D (Bihar)",
        OriginCenter: "Mashrakh_Benchapra_D (Bihar)",
        FacilityCity: "Mashrakh",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13360,
        Pin: 671350,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13361,
        Pin: 301023,
        DispatchCenter: "Alwar_TuleraRoad_I (Rajasthan)",
        OriginCenter: "Alwar_TuleraRoad_I (Rajasthan)",
        FacilityCity: "Alwar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13362,
        Pin: 160102,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13363,
        Pin: 343029,
        DispatchCenter: "Bhinmal_Kaleti_D (Rajasthan)",
        OriginCenter: "Bhinmal_Kaleti_D (Rajasthan)",
        FacilityCity: "Bhinmal",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13364,
        Pin: 276143,
        DispatchCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        OriginCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        FacilityCity: "Jalalpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13365,
        Pin: 422601,
        DispatchCenter: "Akole_Mahalxminagar_D (Maharashtra)",
        OriginCenter: "Akole_Mahalxminagar_D (Maharashtra)",
        FacilityCity: "Akole",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13366,
        Pin: 621112,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13367,
        Pin: 415513,
        DispatchCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        OriginCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        FacilityCity: "Satara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13368,
        Pin: 413250,
        DispatchCenter: "Kurduwadi_Dattanagar_D (Maharashtra)",
        OriginCenter: "Kurduwadi_Dattanagar_D (Maharashtra)",
        FacilityCity: "Kurduwadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13369,
        Pin: 639002,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13370,
        Pin: 303307,
        DispatchCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13371,
        Pin: 142029,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13372,
        Pin: 362235,
        DispatchCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        OriginCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        FacilityCity: "Keshod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13373,
        Pin: 415620,
        DispatchCenter: "Ganpatipule_Rahataghar_DPP (Maharashtra)",
        OriginCenter: "Ganpatipule_Rahataghar_DPP (Maharashtra)",
        FacilityCity: "Ganpatipule",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13374,
        Pin: 581148,
        DispatchCenter: "Haveri_ManjunathNagar_D (Karnataka)",
        OriginCenter: "Haveri_ManjunathNagar_D (Karnataka)",
        FacilityCity: "Haveri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13375,
        Pin: 761207,
        DispatchCenter: "Paralakhemundi_Gumma_DPP (Orissa)",
        OriginCenter: "Paralakhemundi_Gumma_DPP (Orissa)",
        FacilityCity: "Paralakhemundi",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13376,
        Pin: 344012,
        DispatchCenter: "Barmer_Baldevnagar_DPP (Rajasthan)",
        OriginCenter: "Barmer_Baldevnagar_DPP (Rajasthan)",
        FacilityCity: "Barmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13377,
        Pin: 410201,
        DispatchCenter: "Karjat_NeralRd_D (Maharashtra)",
        OriginCenter: "Karjat_NeralRd_D (Maharashtra)",
        FacilityCity: "Karjat",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13378,
        Pin: 828204,
        DispatchCenter: "Chirkunda_Egarkunr_D (Jharkhand)",
        OriginCenter: "Chirkunda_Egarkunr_D (Jharkhand)",
        FacilityCity: "Chirkunda",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13379,
        Pin: 276403,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13380,
        Pin: 673104,
        DispatchCenter: "Vadakara_Veerancheri_D (Kerala)",
        OriginCenter: "Vadakara_Veerancheri_D (Kerala)",
        FacilityCity: "Vadakara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13381,
        Pin: 522325,
        DispatchCenter: "Tenali_Pandurngpet_D (Andhra Pradesh)",
        OriginCenter: "Tenali_Pandurngpet_D (Andhra Pradesh)",
        FacilityCity: "Tenali",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13382,
        Pin: 144631,
        DispatchCenter: "Begowal_PinkMrkt_D (Punjab)",
        OriginCenter: "Begowal_PinkMrkt_D (Punjab)",
        FacilityCity: "Begowal",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13383,
        Pin: 794103,
        DispatchCenter: "Dalu_Barengapra_D (Meghalaya)",
        OriginCenter: "Dalu_Barengapra_D (Meghalaya)",
        FacilityCity: "Dalu",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13384,
        Pin: 721515,
        DispatchCenter: "Jhargram_Raghunathpur_DPP (West Bengal)",
        OriginCenter: "Jhargram_Raghunathpur_DPP (West Bengal)",
        FacilityCity: "Jhargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13385,
        Pin: 563117,
        DispatchCenter: "Bethamangala_Sriramatemp_D (Karnataka)",
        OriginCenter: "Bethamangala_Sriramatemp_D (Karnataka)",
        FacilityCity: "Bethamangala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13386,
        Pin: 695573,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13387,
        Pin: 680657,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13388,
        Pin: 533460,
        DispatchCenter: "Samarlakota_CHC_D (Andhra Pradesh)",
        OriginCenter: "Samarlakota_CHC_D (Andhra Pradesh)",
        FacilityCity: "Samarlakota",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13389,
        Pin: 392015,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13390,
        Pin: 425513,
        DispatchCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        OriginCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        FacilityCity: "Raver",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13391,
        Pin: 382610,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13392,
        Pin: 755039,
        DispatchCenter: "Mangalpur_GayatriColony_D (Orissa)",
        OriginCenter: "Mangalpur_GayatriColony_D (Orissa)",
        FacilityCity: "Mangalpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13393,
        Pin: 360005,
        DispatchCenter: "Rajkot_Amargadh_H (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13394,
        Pin: 604307,
        DispatchCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        OriginCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        FacilityCity: "Tindivanam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13395,
        Pin: 210208,
        DispatchCenter: "Shankargarh_Mau_D (Uttar Pradesh)",
        OriginCenter: "Shankargarh_Mau_D (Uttar Pradesh)",
        FacilityCity: "Shankargarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13396,
        Pin: 456440,
        DispatchCenter: "Nagda_PrkashColny_D (Madhya Pradesh)",
        OriginCenter: "Nagda_PrkashColny_D (Madhya Pradesh)",
        FacilityCity: "Nagda",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 13397,
        Pin: 400030,
        DispatchCenter: "Mumbai_Sewri_L (Maharashtra)",
        OriginCenter: "Mumbai_Sewri_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13398,
        Pin: 632508,
        DispatchCenter: "Arakkonam_Ranipet_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ranipet_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13399,
        Pin: 788711,
        DispatchCenter: "Karimganj_Sarisa_D (Assam)",
        OriginCenter: "Karimganj_Sarisa_D (Assam)",
        FacilityCity: "Karimganj",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13400,
        Pin: 472111,
        DispatchCenter: "Tikamgarh_Dhonga_D (Madhya Pradesh)",
        OriginCenter: "Tikamgarh_Dhonga_D (Madhya Pradesh)",
        FacilityCity: "Tikamgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 13401,
        Pin: 700040,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13402,
        Pin: 845453,
        DispatchCenter: "Lauria_ChandanChowk_D (Bihar)",
        OriginCenter: "Lauria_ChandanChowk_D (Bihar)",
        FacilityCity: "Lauria Nandangarh",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13403,
        Pin: 723145,
        DispatchCenter: "Para_Dubra_DPP (West Bengal)",
        OriginCenter: "Para_Dubra_DPP (West Bengal)",
        FacilityCity: "Para",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13404,
        Pin: 635106,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13405,
        Pin: 679313,
        DispatchCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        OriginCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        FacilityCity: "Sankaramangalam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13406,
        Pin: 361315,
        DispatchCenter: "Bhatiya_Shrijinagar_D (Gujarat)",
        OriginCenter: "Bhatiya_Shrijinagar_D (Gujarat)",
        FacilityCity: "Bhatiya",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13407,
        Pin: 686608,
        DispatchCenter: "Kottayam_Thalayazham_D (Kerala)",
        OriginCenter: "Kottayam_Thalayazham_D (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13408,
        Pin: 764086,
        DispatchCenter: "Kalimela_Blockoffice_D (Orissa)",
        OriginCenter: "Kalimela_Blockoffice_D (Orissa)",
        FacilityCity: "Kalimela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13409,
        Pin: 521111,
        DispatchCenter: "Nuzvid_NSPColony_D (Andhra Pradesh)",
        OriginCenter: "Nuzvid_NSPColony_D (Andhra Pradesh)",
        FacilityCity: "Nuzvid",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13410,
        Pin: 346005,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13411,
        Pin: 173225,
        DispatchCenter: "Kunihar_Diggal_DPP (Himachal Pradesh)",
        OriginCenter: "Kunihar_Diggal_DPP (Himachal Pradesh)",
        FacilityCity: "Kunihar",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13412,
        Pin: 222149,
        DispatchCenter: "Kerakat_KaliMandirDPP_D (Uttar Pradesh)",
        OriginCenter: "Kerakat_KaliMandirDPP_D (Uttar Pradesh)",
        FacilityCity: "Kerakat",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13413,
        Pin: 576214,
        DispatchCenter: "Bhatkal_Sodigadde_D (Karnataka)",
        OriginCenter: "Bhatkal_Sodigadde_D (Karnataka)",
        FacilityCity: "Bhatkal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13414,
        Pin: 110069,
        DispatchCenter: "Delhi_KirtiNagar_L (Delhi)",
        OriginCenter: "Delhi_KirtiNagar_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13415,
        Pin: 625605,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13416,
        Pin: 700134,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13417,
        Pin: 847428,
        DispatchCenter: "Bharwara_AtarwelPath_D (Bihar)",
        OriginCenter: "Bharwara_AtarwelPath_D (Bihar)",
        FacilityCity: "Bharwara",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13418,
        Pin: 758030,
        DispatchCenter: "Karanjia_Ankura_D (Orissa)",
        OriginCenter: "Karanjia_Ankura_D (Orissa)",
        FacilityCity: "Karanjia",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13419,
        Pin: 495559,
        DispatchCenter: "Champa_Sindhicolony_D (Chhattisgarh)",
        OriginCenter: "Champa_Sindhicolony_D (Chhattisgarh)",
        FacilityCity: "Champa",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13420,
        Pin: 413103,
        DispatchCenter: "Akluj_SumitraNgr_D (Maharashtra)",
        OriginCenter: "Akluj_SumitraNgr_D (Maharashtra)",
        FacilityCity: "Akluj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13421,
        Pin: 795126,
        DispatchCenter: "Moirang_Bishnupur_D (Manipur)",
        OriginCenter: "Moirang_Bishnupur_D (Manipur)",
        FacilityCity: "Moirang",
        FacilityState: "Manipur",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13422,
        Pin: 301101,
        DispatchCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        OriginCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        FacilityCity: "Bhilwara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13423,
        Pin: 713203,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13424,
        Pin: 141315,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13425,
        Pin: 759128,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13426,
        Pin: 680017,
        DispatchCenter: "Arimbur_Elthuruth_D (Kerala)",
        OriginCenter: "Arimbur_Elthuruth_D (Kerala)",
        FacilityCity: "Arimbur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13427,
        Pin: 642108,
        DispatchCenter: "Valparai_MGRNagar_DPP (Tamil Nadu)",
        OriginCenter: "Valparai_MGRNagar_DPP (Tamil Nadu)",
        FacilityCity: "Valparai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13428,
        Pin: 743396,
        DispatchCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        OriginCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        FacilityCity: "Dakshin Barasat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13429,
        Pin: 700142,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13430,
        Pin: 621111,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13431,
        Pin: 686665,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13432,
        Pin: 232340,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13433,
        Pin: 440002,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Chikhali_L (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13434,
        Pin: 764059,
        DispatchCenter: "Nabarangpr_PhdofDPP_D (Orissa)",
        OriginCenter: "Nabarangpr_PhdofDPP_D (Orissa)",
        FacilityCity: "Nabarangapur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13435,
        Pin: 263128,
        DispatchCenter: "Nainital_Malital_D (Uttarakhand)",
        OriginCenter: "Nainital_Malital_D (Uttarakhand)",
        FacilityCity: "Nainital",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13436,
        Pin: 487555,
        DispatchCenter: "Gadarwara_MPward_D (Madhya Pradesh)",
        OriginCenter: "Gadarwara_MPward_D (Madhya Pradesh)",
        FacilityCity: "Gadarwara",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 13437,
        Pin: 273201,
        DispatchCenter: "Gorakhpur_Chaurichaura_D (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Chaurichaura_D (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13438,
        Pin: 277303,
        DispatchCenter: "Ballia_Sikandarpur_D (Uttar Pradesh)",
        OriginCenter: "Ballia_Sikandarpur_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13439,
        Pin: 641033,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13440,
        Pin: 121000,
        DispatchCenter: "Faridabad_MathuraRoad_L (Haryana)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13441,
        Pin: 735219,
        DispatchCenter: "Jalpaiguri_Lataguri_DPP (West Bengal)",
        OriginCenter: "Jalpaiguri_Lataguri_DPP (West Bengal)",
        FacilityCity: "Jalpaiguri",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13442,
        Pin: 246125,
        DispatchCenter: "SrinagarUK_Sandre_D (Uttarakhand)",
        OriginCenter: "SrinagarUK_Sandre_D (Uttarakhand)",
        FacilityCity: "Srinagar-UK",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13443,
        Pin: 781003,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13444,
        Pin: 225203,
        DispatchCenter: "Barabanki_Pragyapuram_D (Uttar Pradesh)",
        OriginCenter: "Barabanki_Pragyapuram_D (Uttar Pradesh)",
        FacilityCity: "Barabanki",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13445,
        Pin: 680516,
        DispatchCenter: "Thrissur_Chavakkad_D (Kerala)",
        OriginCenter: "Thrissur_Chavakkad_D (Kerala)",
        FacilityCity: "Guruvayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13446,
        Pin: 394655,
        DispatchCenter: "Songadh_Vyara_D (Gujarat)",
        OriginCenter: "Songadh_Vyara_D (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13447,
        Pin: 751019,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13448,
        Pin: 761008,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13449,
        Pin: 642140,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13450,
        Pin: 583116,
        DispatchCenter: "Bellary_Mundargi_I (Karnataka)",
        OriginCenter: "Bellary_Mundargi_I (Karnataka)",
        FacilityCity: "Bellary",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13451,
        Pin: 762028,
        DispatchCenter: "Galleri_Central_DPP (Orissa)",
        OriginCenter: "Galleri_Central_DPP (Orissa)",
        FacilityCity: "Galleri",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13452,
        Pin: 600070,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13453,
        Pin: 445308,
        DispatchCenter: "Wadki_Nandurkar_DPP (Maharashtra)",
        OriginCenter: "Wadki_Nandurkar_DPP (Maharashtra)",
        FacilityCity: "Wadki",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13454,
        Pin: 424308,
        DispatchCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        OriginCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        FacilityCity: "Dhule",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13455,
        Pin: 587313,
        DispatchCenter: "Mudhol_Maharancol_D (Karnataka)",
        OriginCenter: "Mudhol_Maharancol_D (Karnataka)",
        FacilityCity: "Mudhol",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13456,
        Pin: 590010,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13457,
        Pin: 631402,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13458,
        Pin: 670706,
        DispatchCenter: "Chavassery_Kelanpeedika_D (Kerala)",
        OriginCenter: "Chavassery_Kelanpeedika_D (Kerala)",
        FacilityCity: "Chavassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13459,
        Pin: 415730,
        DispatchCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        OriginCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        FacilityCity: "Khed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13460,
        Pin: 680672,
        DispatchCenter: "Irinjlkuda_Pothstherry_D (Kerala)",
        OriginCenter: "Irinjlkuda_Pothstherry_D (Kerala)",
        FacilityCity: "Irinjalakuda",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13461,
        Pin: 533216,
        DispatchCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        OriginCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        FacilityCity: "Amalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13462,
        Pin: 680021,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13463,
        Pin: 305025,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13464,
        Pin: 522412,
        DispatchCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasaraopet",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13465,
        Pin: 584120,
        DispatchCenter: "Kowthal_UdayaNagar_D (Karnataka)",
        OriginCenter: "Kowthal_UdayaNagar_D (Karnataka)",
        FacilityCity: "Kowthal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13466,
        Pin: 401210,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13467,
        Pin: 600024,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13468,
        Pin: 277307,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13469,
        Pin: 143101,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13470,
        Pin: 805141,
        DispatchCenter: "Nawada_Govindpur_D (Bihar)",
        OriginCenter: "Nawada_Govindpur_D (Bihar)",
        FacilityCity: "Nawada",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13471,
        Pin: 223222,
        DispatchCenter: "Shahganj_Kaudiya_D (Uttar Pradesh)",
        OriginCenter: "Shahganj_Kaudiya_D (Uttar Pradesh)",
        FacilityCity: "Shahganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13472,
        Pin: 851214,
        DispatchCenter: "Khagaria_Baluwahi_DPP (Bihar)",
        OriginCenter: "Khagaria_Baluwahi_DPP (Bihar)",
        FacilityCity: "Khagaria",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13473,
        Pin: 321642,
        DispatchCenter: "Kherli_Doroli_D (Rajasthan)",
        OriginCenter: "Kherli_Doroli_D (Rajasthan)",
        FacilityCity: "Kherli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13474,
        Pin: 423502,
        DispatchCenter: "Nashik_Vani_DPP (Maharashtra)",
        OriginCenter: "Nashik_Vani_DPP (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13475,
        Pin: 534230,
        DispatchCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        FacilityCity: "Bhimavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13476,
        Pin: 731133,
        DispatchCenter: "Dubrajpur_Mansayer_D (West Bengal)",
        OriginCenter: "Dubrajpur_Mansayer_D (West Bengal)",
        FacilityCity: "Dubrajpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13477,
        Pin: 685554,
        DispatchCenter: "Kuthumkal_Matheckal_D (Kerala)",
        OriginCenter: "Kuthumkal_Matheckal_D (Kerala)",
        FacilityCity: "Kuthumkal",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13478,
        Pin: 415703,
        DispatchCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        OriginCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        FacilityCity: "Shringartali",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13479,
        Pin: 563147,
        DispatchCenter: "Bangarapet_AMPCClny_D (Karnataka)",
        OriginCenter: "Bangarapet_AMPCClny_D (Karnataka)",
        FacilityCity: "Bangarapet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13480,
        Pin: 829132,
        DispatchCenter: "Bokaro_Chas_D (Jharkhand)",
        OriginCenter: "Bokaro_Chas_D (Jharkhand)",
        FacilityCity: "Bokaro",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13481,
        Pin: 503201,
        DispatchCenter: "Bodhan_VamshiSchl_D (Telangana)",
        OriginCenter: "Bodhan_VamshiSchl_D (Telangana)",
        FacilityCity: "Bodhan",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13482,
        Pin: 172115,
        DispatchCenter: "Jeori_Tapri_D (Himachal Pradesh)",
        OriginCenter: "Jeori_Tapri_D (Himachal Pradesh)",
        FacilityCity: "Jeori",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13483,
        Pin: 632513,
        DispatchCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ranipet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13484,
        Pin: 577422,
        DispatchCenter: "Thirthahalli_Bejjavali2_DPP (Karnataka)",
        OriginCenter: "Thirthahalli_Bejjavali2_DPP (Karnataka)",
        FacilityCity: "Thirthahalli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13485,
        Pin: 516215,
        DispatchCenter: "Rayachoti_Chennamukkapalle_D (Andhra Pradesh)",
        OriginCenter: "Rayachoti_Chennamukkapalle_D (Andhra Pradesh)",
        FacilityCity: "Rayachoti",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13486,
        Pin: 621701,
        DispatchCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        OriginCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        FacilityCity: "Ariyalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13487,
        Pin: 783382,
        DispatchCenter: "Goalpara_Baladmri_D (Assam)",
        OriginCenter: "Goalpara_Baladmri_D (Assam)",
        FacilityCity: "Goalpara",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13488,
        Pin: 516355,
        DispatchCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        OriginCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        FacilityCity: "Proddatur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13489,
        Pin: 312617,
        DispatchCenter: "Nimbahera_Chotisadri_DPP (Rajasthan)",
        OriginCenter: "Nimbahera_Chotisadri_DPP (Rajasthan)",
        FacilityCity: "Nimbahera",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13490,
        Pin: 281123,
        DispatchCenter: "Mathura_Palikheda_D (Uttar Pradesh)",
        OriginCenter: "Mathura_Palikheda_D (Uttar Pradesh)",
        FacilityCity: "Mathura",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13491,
        Pin: 384120,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13492,
        Pin: 277208,
        DispatchCenter: "Bairia_Bhagwanpur_DPP (Uttar Pradesh)",
        OriginCenter: "Bairia_Bhagwanpur_DPP (Uttar Pradesh)",
        FacilityCity: "Bariya",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13493,
        Pin: 501111,
        DispatchCenter: "Vikarabad_OldGunj_D (Telangana)",
        OriginCenter: "Vikarabad_OldGunj_D (Telangana)",
        FacilityCity: "Vikarabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13494,
        Pin: 591119,
        DispatchCenter: "Bailhongal_Azadnagar_D (Karnataka)",
        OriginCenter: "Bailhongal_Azadnagar_D (Karnataka)",
        FacilityCity: "Bailhongal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13495,
        Pin: 788167,
        DispatchCenter: "Hailakandi_Lala_D (Assam)",
        OriginCenter: "Hailakandi_Lala_D (Assam)",
        FacilityCity: "Hailakandi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13496,
        Pin: 759132,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13497,
        Pin: 735135,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13498,
        Pin: 175103,
        DispatchCenter: "Manali_Prini_D (Himachal Pradesh)",
        OriginCenter: "Manali_Prini_D (Himachal Pradesh)",
        FacilityCity: "Manali",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13499,
        Pin: 181101,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13500,
        Pin: 833213,
        DispatchCenter: "Noamundi_Lakhansai_D (Jharkhand)",
        OriginCenter: "Noamundi_Lakhansai_D (Jharkhand)",
        FacilityCity: "Noamundi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13501,
        Pin: 686542,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13502,
        Pin: 384421,
        DispatchCenter: "Kadi_Jadavpura_L (Gujarat)",
        OriginCenter: "Kadi_Jadavpura_L (Gujarat)",
        FacilityCity: "Kadi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13503,
        Pin: 713403,
        DispatchCenter: "Panagarh_Kanksa_D (West Bengal)",
        OriginCenter: "Panagarh_Kanksa_D (West Bengal)",
        FacilityCity: "Panagarh",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13504,
        Pin: 143414,
        DispatchCenter: "Amritsar_KhurManian_D (Punjab)",
        OriginCenter: "Amritsar_KhurManian_D (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13505,
        Pin: 585229,
        DispatchCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        OriginCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        FacilityCity: "Gulbarga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13506,
        Pin: 392221,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13507,
        Pin: 416001,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13508,
        Pin: 671532,
        DispatchCenter: "Parappa_Balal_D (Kerala)",
        OriginCenter: "Parappa_Balal_D (Kerala)",
        FacilityCity: "Parappa",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13509,
        Pin: 481885,
        DispatchCenter: "ShahpuraMP_Kundam_D (Madhya Pradesh)",
        OriginCenter: "ShahpuraMP_Kundam_D (Madhya Pradesh)",
        FacilityCity: "ShahpuraMP",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 13510,
        Pin: 751018,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13511,
        Pin: 110119,
        DispatchCenter: "Delhi_RailYardRd_L (Delhi)",
        OriginCenter: "Delhi_RailYardRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13512,
        Pin: 843146,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13513,
        Pin: 135001,
        DispatchCenter: "Yamunanagar_Veerngrcly_D (Haryana)",
        OriginCenter: "Yamunanagar_Veerngrcly_D (Haryana)",
        FacilityCity: "Yamuna Nagar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13514,
        Pin: 731249,
        DispatchCenter: "Murarai_Birbhumi_D (West Bengal)",
        OriginCenter: "Murarai_Birbhumi_D (West Bengal)",
        FacilityCity: "Murarai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13515,
        Pin: 462040,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13516,
        Pin: 624802,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13517,
        Pin: 608801,
        DispatchCenter: "Cuddalore_FriendsNgr_D (Tamil Nadu)",
        OriginCenter: "Cuddalore_FriendsNgr_D (Tamil Nadu)",
        FacilityCity: "Cuddalore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13518,
        Pin: 472445,
        DispatchCenter: "Prithvipur_Niwari_D (Madhya Pradesh)",
        OriginCenter: "Prithvipur_Niwari_D (Madhya Pradesh)",
        FacilityCity: "Prithvipur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 13519,
        Pin: 673019,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13520,
        Pin: 473335,
        DispatchCenter: "Ashoknagar_Durgacolony_D (Madhya Pradesh)",
        OriginCenter: "Ashoknagar_Durgacolony_D (Madhya Pradesh)",
        FacilityCity: "Ashok Nagar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 13521,
        Pin: 403115,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13522,
        Pin: 843165,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13523,
        Pin: 791120,
        DispatchCenter: "Ziro_Hapoli_D (Arunachal Pradesh)",
        OriginCenter: "Ziro_Hapoli_D (Arunachal Pradesh)",
        FacilityCity: "Ziro",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13524,
        Pin: 755017,
        DispatchCenter: "Chandipur_Keshpur_D (Orissa)",
        OriginCenter: "Chandipur_Keshpur_D (Orissa)",
        FacilityCity: "Chandipur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13525,
        Pin: 522503,
        DispatchCenter: "Guntur_Amaravati_D (Andhra Pradesh)",
        OriginCenter: "Guntur_Amaravati_D (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13526,
        Pin: 208019,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13527,
        Pin: 737131,
        DispatchCenter: "Rongli_Changeylakha_DPP (Sikkim)",
        OriginCenter: "Rongli_Changeylakha_DPP (Sikkim)",
        FacilityCity: "Rongli",
        FacilityState: "Sikkim",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13528,
        Pin: 274307,
        DispatchCenter: "Padrauna_BauliChwk_D (Uttar Pradesh)",
        OriginCenter: "Padrauna_BauliChwk_D (Uttar Pradesh)",
        FacilityCity: "Padrauna",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13529,
        Pin: 388520,
        DispatchCenter: "Anand_Borsad_D (Gujarat)",
        OriginCenter: "Anand_Borsad_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13530,
        Pin: 176125,
        DispatchCenter: "Baijnath_Paprola_DPP (Himachal Pradesh)",
        OriginCenter: "Baijnath_Paprola_DPP (Himachal Pradesh)",
        FacilityCity: "Baijnath",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13531,
        Pin: 627427,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13532,
        Pin: 731218,
        DispatchCenter: "Rampurhat_Suri_D (West Bengal)",
        OriginCenter: "Rampurhat_Suri_D (West Bengal)",
        FacilityCity: "Rampurhat",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13533,
        Pin: 184121,
        DispatchCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13534,
        Pin: 691512,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13535,
        Pin: 505474,
        DispatchCenter: "Huzurabad_Vivekanandanagar_D (Telangana)",
        OriginCenter: "Huzurabad_Vivekanandanagar_D (Telangana)",
        FacilityCity: "Huzurabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13536,
        Pin: 141012,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13537,
        Pin: 695587,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13538,
        Pin: 400048,
        DispatchCenter: "Mumbai_Kurla_L (Maharashtra)",
        OriginCenter: "Mumbai_Kurla_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13539,
        Pin: 827010,
        DispatchCenter: "Bokaro_Chas_D (Jharkhand)",
        OriginCenter: "Bokaro_Chas_D (Jharkhand)",
        FacilityCity: "Bokaro",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13540,
        Pin: 678546,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13541,
        Pin: 474009,
        DispatchCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        OriginCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        FacilityCity: "Gwalior",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13542,
        Pin: 425308,
        DispatchCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        OriginCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        FacilityCity: "Bhusawal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13543,
        Pin: 504110,
        DispatchCenter: "Nirmal_AdarshNgr_D (Telangana)",
        OriginCenter: "Nirmal_AdarshNgr_D (Telangana)",
        FacilityCity: "Nirmal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13544,
        Pin: 762107,
        DispatchCenter: "MRampur_Tumudibandha_DPP (Orissa)",
        OriginCenter: "MRampur_Tumudibandha_DPP (Orissa)",
        FacilityCity: "M Rampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13545,
        Pin: 521182,
        DispatchCenter: "Nandigama_Mukkapatinagar_D (Andhra Pradesh)",
        OriginCenter: "Nandigama_Mukkapatinagar_D (Andhra Pradesh)",
        FacilityCity: "Nandigama",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13546,
        Pin: 622005,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13547,
        Pin: 416138,
        DispatchCenter: "Ichalkaranji_TmbeMala_D (Maharashtra)",
        OriginCenter: "Ichalkaranji_TmbeMala_D (Maharashtra)",
        FacilityCity: "Ichalkaranji",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13548,
        Pin: 800018,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13549,
        Pin: 797005,
        DispatchCenter: "Kohima_AGColony_D (Nagaland)",
        OriginCenter: "Kohima_AGColony_D (Nagaland)",
        FacilityCity: "Kohima",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13550,
        Pin: 533503,
        DispatchCenter: "Rajahmundry_Tummalava_D (Andhra Pradesh)",
        OriginCenter: "Rajahmundry_Tummalava_D (Andhra Pradesh)",
        FacilityCity: "Rajahmundry",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13551,
        Pin: 562106,
        DispatchCenter: "Bangalore_Bommasandra_L (Karnataka)",
        OriginCenter: "Bangalore_Bommasandra_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13552,
        Pin: 585314,
        DispatchCenter: "Aland_Sherikarcolony_D (Karnataka)",
        OriginCenter: "Aland_Sherikarcolony_D (Karnataka)",
        FacilityCity: "Aland",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13553,
        Pin: 721147,
        DispatchCenter: "Midnapore_Harishpur_D (West Bengal)",
        OriginCenter: "Midnapore_Harishpur_D (West Bengal)",
        FacilityCity: "Midnapore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13554,
        Pin: 431741,
        DispatchCenter: "Aurad_CBColony_D (Karnataka)",
        OriginCenter: "Aurad_CBColony_D (Karnataka)",
        FacilityCity: "Aurad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13555,
        Pin: 799012,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13556,
        Pin: 421032,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13557,
        Pin: 673503,
        DispatchCenter: "Vadakara_Orkkatteri_D (Kerala)",
        OriginCenter: "Vadakara_Orkkatteri_D (Kerala)",
        FacilityCity: "Vadakara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13558,
        Pin: 245207,
        DispatchCenter: "Hapur_Simbhaoli_D (Uttar Pradesh)",
        OriginCenter: "Hapur_Simbhaoli_D (Uttar Pradesh)",
        FacilityCity: "Hapur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13559,
        Pin: 209863,
        DispatchCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        OriginCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        FacilityCity: "Unnao",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13560,
        Pin: 382855,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13561,
        Pin: 222101,
        DispatchCenter: "Shahganj_RudhauliKalan_D (Uttar Pradesh)",
        OriginCenter: "Shahganj_RudhauliKalan_D (Uttar Pradesh)",
        FacilityCity: "Shahganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13562,
        Pin: 756003,
        DispatchCenter: "Balasore_Ganeswarpur_D (Orissa)",
        OriginCenter: "Balasore_Ganeswarpur_D (Orissa)",
        FacilityCity: "Balasore",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13563,
        Pin: 523253,
        DispatchCenter: "Chimakurthy_Siddavari_D (Andhra Pradesh)",
        OriginCenter: "Chimakurthy_Siddavari_D (Andhra Pradesh)",
        FacilityCity: "Chimakurthy",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13564,
        Pin: 523279,
        DispatchCenter: "Kondapi_Anakarlapudi_D (Andhra Pradesh)",
        OriginCenter: "Kondapi_Anakarlapudi_D (Andhra Pradesh)",
        FacilityCity: "Kondapi",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13565,
        Pin: 802157,
        DispatchCenter: "Brahmapur_Shahpur_D (Bihar)",
        OriginCenter: "Brahmapur_Shahpur_D (Bihar)",
        FacilityCity: "Brahmapur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13566,
        Pin: 306502,
        DispatchCenter: "Khinwara_Temple_DPP (Rajasthan)",
        OriginCenter: "Khinwara_Temple_DPP (Rajasthan)",
        FacilityCity: "Khinwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13567,
        Pin: 175130,
        DispatchCenter: "Patlikuhal_Katrain_D (Himachal Pradesh)",
        OriginCenter: "Patlikuhal_Katrain_D (Himachal Pradesh)",
        FacilityCity: "Patlikuhal",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13568,
        Pin: 636455,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13569,
        Pin: 581140,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13570,
        Pin: 148000,
        DispatchCenter: "Sangrur_DC (Punjab)",
        OriginCenter: "Sangrur_DC (Punjab)",
        FacilityCity: "Sangrur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13571,
        Pin: 743356,
        DispatchCenter: "Kakdwip_KlngrDPP_D (West Bengal)",
        OriginCenter: "Kakdwip_KlngrDPP_D (West Bengal)",
        FacilityCity: "Kakdwip",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13572,
        Pin: 581333,
        DispatchCenter: "Kumta_Central_DPP_2 (Karnataka)",
        OriginCenter: "Kumta_Central_DPP_2 (Karnataka)",
        FacilityCity: "Kumta",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13573,
        Pin: 524101,
        DispatchCenter: "Gudur_MalvyaNagar_D (Andhra Pradesh)",
        OriginCenter: "Gudur_MalvyaNagar_D (Andhra Pradesh)",
        FacilityCity: "Gudur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13574,
        Pin: 175024,
        DispatchCenter: "Sarkaghat_Khelag_D (Himachal Pradesh)",
        OriginCenter: "Sarkaghat_Khelag_D (Himachal Pradesh)",
        FacilityCity: "Sarkaghat",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13575,
        Pin: 502324,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13576,
        Pin: 382015,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13577,
        Pin: 695043,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13578,
        Pin: 631561,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13579,
        Pin: 224132,
        DispatchCenter: "Kumarganj_Siddhiganeshpur_D (Uttar Pradesh)",
        OriginCenter: "Kumarganj_Siddhiganeshpur_D (Uttar Pradesh)",
        FacilityCity: "Kumarganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13580,
        Pin: 695585,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13581,
        Pin: 754201,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13582,
        Pin: 125033,
        DispatchCenter: "Hansi_HanumanColny_DPP (Haryana)",
        OriginCenter: "Hansi_HanumanColny_DPP (Haryana)",
        FacilityCity: "Hansi",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13583,
        Pin: 509110,
        DispatchCenter: "AtmakurTS_Srinivasa_DPP (Telangana)",
        OriginCenter: "AtmakurTS_Srinivasa_DPP (Telangana)",
        FacilityCity: "Atmakur-TS",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13584,
        Pin: 491001,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13585,
        Pin: 833106,
        DispatchCenter: "Manoharpur_ColRd_DPP (Jharkhand)",
        OriginCenter: "Manoharpur_ColRd_DPP (Jharkhand)",
        FacilityCity: "Manoharpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13586,
        Pin: 642105,
        DispatchCenter: "Valparai_MGRNagar_DPP (Tamil Nadu)",
        OriginCenter: "Valparai_MGRNagar_DPP (Tamil Nadu)",
        FacilityCity: "Valparai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13587,
        Pin: 244926,
        DispatchCenter: "Rampur_RoshanBagh_D (Uttar Pradesh)",
        OriginCenter: "Rampur_RoshanBagh_D (Uttar Pradesh)",
        FacilityCity: "Rampur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13588,
        Pin: 686636,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13589,
        Pin: 143422,
        DispatchCenter: "Goindwal_Fatehabad_D (Punjab)",
        OriginCenter: "Goindwal_Fatehabad_D (Punjab)",
        FacilityCity: "Goindwal",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13590,
        Pin: 394241,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13591,
        Pin: 613702,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13592,
        Pin: 533251,
        DispatchCenter: "Narsapur_Sakhinetipalli_DPP (Andhra Pradesh)",
        OriginCenter: "Narsapur_Sakhinetipalli_DPP (Andhra Pradesh)",
        FacilityCity: "Narsapur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13593,
        Pin: 152003,
        DispatchCenter: "Ferozepur_DC (Punjab)",
        OriginCenter: "Ferozepur_DC (Punjab)",
        FacilityCity: "Firozpur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13594,
        Pin: 425120,
        DispatchCenter: "Jalgaon_MIDC_I (Maharashtra)",
        OriginCenter: "Jalgaon_MIDC_I (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13595,
        Pin: 535524,
        DispatchCenter: "Kurupam_MainRd_DPP (Andhra Pradesh)",
        OriginCenter: "Kurupam_MainRd_DPP (Andhra Pradesh)",
        FacilityCity: "Kurupam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13596,
        Pin: 695601,
        DispatchCenter: "Kilimanoor_AKNArcard_D (Kerala)",
        OriginCenter: "Kilimanoor_AKNArcard_D (Kerala)",
        FacilityCity: "Kilimanoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13597,
        Pin: 284124,
        DispatchCenter: "Lalitpur_Govindnagar_D (Uttar Pradesh)",
        OriginCenter: "Lalitpur_Govindnagar_D (Uttar Pradesh)",
        FacilityCity: "Lalitpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13598,
        Pin: 627654,
        DispatchCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        OriginCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        FacilityCity: "Thisayanvilai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13599,
        Pin: 695502,
        DispatchCenter: "Trivandrum_Neyatinkra_D (Kerala)",
        OriginCenter: "Trivandrum_Neyatinkra_D (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13600,
        Pin: 454345,
        DispatchCenter: "Kukshi_BurahaniColony_D (Madhya Pradesh)",
        OriginCenter: "Kukshi_BurahaniColony_D (Madhya Pradesh)",
        FacilityCity: "Kukshi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13601,
        Pin: 530017,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13602,
        Pin: 793115,
        DispatchCenter: "Shillong_Mawngap_D (Meghalaya)",
        OriginCenter: "Shillong_Mawngap_D (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13603,
        Pin: 491221,
        DispatchCenter: "Patan_MahamayaChwk_DPP (Chhattisgarh)",
        OriginCenter: "Patan_MahamayaChwk_DPP (Chhattisgarh)",
        FacilityCity: "Patan-CG",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13604,
        Pin: 176071,
        DispatchCenter: "Kachhera_Timber_DPP (Himachal Pradesh)",
        OriginCenter: "Kachhera_Timber_DPP (Himachal Pradesh)",
        FacilityCity: "Kachhera",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13605,
        Pin: 144216,
        DispatchCenter: "Dasuya_Hajipur_D (Punjab)",
        OriginCenter: "Dasuya_Hajipur_D (Punjab)",
        FacilityCity: "Dasuya",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13606,
        Pin: 686023,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13607,
        Pin: 629177,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13608,
        Pin: 711413,
        DispatchCenter: "Howrah_Temp_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13609,
        Pin: 641401,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13610,
        Pin: 522001,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13611,
        Pin: 241407,
        DispatchCenter: "Pihani_Katrabzr_D (Uttar Pradesh)",
        OriginCenter: "Pihani_Katrabzr_D (Uttar Pradesh)",
        FacilityCity: "Pihani",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13612,
        Pin: 389150,
        DispatchCenter: "Dahod_Vanzarwada_I (Gujarat)",
        OriginCenter: "Dahod_Vanzarwada_I (Gujarat)",
        FacilityCity: "Dahod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13613,
        Pin: 402125,
        DispatchCenter: "Nagothane_Balsaital_D (Maharashtra)",
        OriginCenter: "Nagothane_Balsaital_D (Maharashtra)",
        FacilityCity: "Nagothane",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13614,
        Pin: 364145,
        DispatchCenter: "Mahuva_Bagdana_DPP (Gujarat)",
        OriginCenter: "Mahuva_Bagdana_DPP (Gujarat)",
        FacilityCity: "Mahuva",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13615,
        Pin: 757085,
        DispatchCenter: "Udala_Mayurbhanj_DPP (Orissa)",
        OriginCenter: "Udala_Mayurbhanj_DPP (Orissa)",
        FacilityCity: "Udala",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13616,
        Pin: 723132,
        DispatchCenter: "Raghunathpur_Nutandih_D (West Bengal)",
        OriginCenter: "Raghunathpur_Nutandih_D (West Bengal)",
        FacilityCity: "Raghunathpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13617,
        Pin: 391115,
        DispatchCenter: "Dabhoi_Central_DPP_3 (Gujarat)",
        OriginCenter: "Dabhoi_Central_DPP_3 (Gujarat)",
        FacilityCity: "Dabhoi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13618,
        Pin: 226020,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13619,
        Pin: 311021,
        DispatchCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        OriginCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        FacilityCity: "Bijainagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13620,
        Pin: 193108,
        DispatchCenter: "Srinagar_Pattan_DPP (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Pattan_DPP (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13621,
        Pin: 400038,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13622,
        Pin: 410213,
        DispatchCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        OriginCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13623,
        Pin: 680583,
        DispatchCenter: "Shoranur_Akamala_D (Kerala)",
        OriginCenter: "Shoranur_Akamala_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13624,
        Pin: 533401,
        DispatchCenter: "Tuni_Ramakrishnacolony_D (Andhra Pradesh)",
        OriginCenter: "Tuni_Ramakrishnacolony_D (Andhra Pradesh)",
        FacilityCity: "Tuni",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13625,
        Pin: 212202,
        DispatchCenter: "SarayAnkil_Fkrbd_D (Uttar Pradesh)",
        OriginCenter: "SarayAnkil_Fkrbd_D (Uttar Pradesh)",
        FacilityCity: "Saray Ankil",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13626,
        Pin: 487001,
        DispatchCenter: "Narsinghpur_Stationganj_D (Madhya Pradesh)",
        OriginCenter: "Narsinghpur_Stationganj_D (Madhya Pradesh)",
        FacilityCity: "Narsinghpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 13627,
        Pin: 305924,
        DispatchCenter: "DeogarhRJ_BapuNgr_DPP (Rajasthan)",
        OriginCenter: "DeogarhRJ_BapuNgr_DPP (Rajasthan)",
        FacilityCity: "DeogarhRJ",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13628,
        Pin: 383220,
        DispatchCenter: "Himmatnagar_Hadiyol_D (Gujarat)",
        OriginCenter: "Himmatnagar_Hadiyol_D (Gujarat)",
        FacilityCity: "Himmatnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13629,
        Pin: 841423,
        DispatchCenter: "Mohammadpur_DurgaMkt_DPP (Bihar)",
        OriginCenter: "Mohammadpur_DurgaMkt_DPP (Bihar)",
        FacilityCity: "Mohammadpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13630,
        Pin: 332407,
        DispatchCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        OriginCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13631,
        Pin: 607805,
        DispatchCenter: "Neyveli_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Neyveli_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Neyveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13632,
        Pin: 224121,
        DispatchCenter: "Rudauli_Ghosaiyana_D (Uttar Pradesh)",
        OriginCenter: "Rudauli_Ghosaiyana_D (Uttar Pradesh)",
        FacilityCity: "Rudauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13633,
        Pin: 847452,
        DispatchCenter: "Phulparas_GhoghardihaRoad_D (Bihar)",
        OriginCenter: "Phulparas_GhoghardihaRoad_D (Bihar)",
        FacilityCity: "Phulparas",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13634,
        Pin: 303601,
        DispatchCenter: "Ajeetgarh_Jugrajpura_D (Rajasthan)",
        OriginCenter: "Ajeetgarh_Jugrajpura_D (Rajasthan)",
        FacilityCity: "Ajeetgarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13635,
        Pin: 581318,
        DispatchCenter: "Sirsi_Kasturbanagar_D (Karnataka)",
        OriginCenter: "Sirsi_Kasturbanagar_D (Karnataka)",
        FacilityCity: "Sirsi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13636,
        Pin: 801304,
        DispatchCenter: "Hilsa_Sryamndr_D (Bihar)",
        OriginCenter: "Hilsa_Sryamndr_D (Bihar)",
        FacilityCity: "Hilsa",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13637,
        Pin: 629851,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13638,
        Pin: 209308,
        DispatchCenter: "Ghatampur_StatinRD_D (Uttar Pradesh)",
        OriginCenter: "Ghatampur_StatinRD_D (Uttar Pradesh)",
        FacilityCity: "Ghatampur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13639,
        Pin: 245208,
        DispatchCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        OriginCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        FacilityCity: "Siyana",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13640,
        Pin: 248003,
        DispatchCenter: "Dehradun_Clement_L (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13641,
        Pin: 816106,
        DispatchCenter: "Maheshpur_Grmnbank_D (Jharkhand)",
        OriginCenter: "Maheshpur_Grmnbank_D (Jharkhand)",
        FacilityCity: "Maheshpur",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13642,
        Pin: 630556,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13643,
        Pin: 574144,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13644,
        Pin: 605602,
        DispatchCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        OriginCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        FacilityCity: "Viluppuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13645,
        Pin: 731221,
        DispatchCenter: "Murarai_Birbhumi_D (West Bengal)",
        OriginCenter: "Murarai_Birbhumi_D (West Bengal)",
        FacilityCity: "Murarai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13646,
        Pin: 360020,
        DispatchCenter: "Rajkot_Amargadh_H (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13647,
        Pin: 507103,
        DispatchCenter: "Kothagudem_Sujathanagar_D (Telangana)",
        OriginCenter: "Kothagudem_Sujathanagar_D (Telangana)",
        FacilityCity: "Kothagudem",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13648,
        Pin: 795101,
        DispatchCenter: "Kakching_SumakLeikai_D (Manipur)",
        OriginCenter: "Kakching_SumakLeikai_D (Manipur)",
        FacilityCity: "Kakching",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13649,
        Pin: 328030,
        DispatchCenter: "Dholpur_KamlaColony_D (Rajasthan)",
        OriginCenter: "Dholpur_KamlaColony_D (Rajasthan)",
        FacilityCity: "Dholpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13650,
        Pin: 273404,
        DispatchCenter: "Sikriganj_Sikaridihkhurd_D (Uttar Pradesh)",
        OriginCenter: "Sikriganj_Sikaridihkhurd_D (Uttar Pradesh)",
        FacilityCity: "Sikriganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13651,
        Pin: 361240,
        DispatchCenter: "Dhrol_PGVCLOfc_D (Gujarat)",
        OriginCenter: "Dhrol_PGVCLOfc_D (Gujarat)",
        FacilityCity: "Dhrol",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13652,
        Pin: 843147,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13653,
        Pin: 431210,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13654,
        Pin: 203129,
        DispatchCenter: "Khurja_Pnjbiyon_D (Uttar Pradesh)",
        OriginCenter: "Khurja_Pnjbiyon_D (Uttar Pradesh)",
        FacilityCity: "Khurja",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13655,
        Pin: 833221,
        DispatchCenter: "Noamundi_Lakhansai_D (Jharkhand)",
        OriginCenter: "Noamundi_Lakhansai_D (Jharkhand)",
        FacilityCity: "Noamundi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13656,
        Pin: 676541,
        DispatchCenter: "Edavanna_MNRd_D (Kerala)",
        OriginCenter: "Edavanna_MNRd_D (Kerala)",
        FacilityCity: "Edavanna",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13657,
        Pin: 530040,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13658,
        Pin: 243403,
        DispatchCenter: "Aonla_KdidmCLY_D (Uttar Pradesh)",
        OriginCenter: "Aonla_KdidmCLY_D (Uttar Pradesh)",
        FacilityCity: "Aonla",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13659,
        Pin: 226024,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13660,
        Pin: 262525,
        DispatchCenter: "Lohaghat_RanjeetPalace_D (Uttarakhand)",
        OriginCenter: "Lohaghat_RanjeetPalace_D (Uttarakhand)",
        FacilityCity: "Lohaghat",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13661,
        Pin: 638454,
        DispatchCenter: "Sathyamangalam_VNSNagar_D (Tamil Nadu)",
        OriginCenter: "Sathyamangalam_VNSNagar_D (Tamil Nadu)",
        FacilityCity: "Sathyamangalam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13662,
        Pin: 470880,
        DispatchCenter: "Tendukheda_GovtSchool_DPP (Madhya Pradesh)",
        OriginCenter: "Tendukheda_GovtSchool_DPP (Madhya Pradesh)",
        FacilityCity: "Tendukheda",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13663,
        Pin: 678682,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13664,
        Pin: 801307,
        DispatchCenter: "Chandi_SH78_D (Bihar)",
        OriginCenter: "Chandi_SH78_D (Bihar)",
        FacilityCity: "Chandi",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13665,
        Pin: 735234,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13666,
        Pin: 560031,
        DispatchCenter: "Bangalore_Temp1_L (Karnataka)",
        OriginCenter: "Bangalore_Temp1_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13667,
        Pin: 534101,
        DispatchCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        OriginCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        FacilityCity: "Tadepalligudem",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13668,
        Pin: 641108,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13669,
        Pin: 603112,
        DispatchCenter: "Chennai_Sholinganallur_Dc (Tamil Nadu)",
        OriginCenter: "Chennai_Sholinganallur_Dc (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13670,
        Pin: 480661,
        DispatchCenter: "Seoni_Dalsagar_D (Madhya Pradesh)",
        OriginCenter: "Seoni_Dalsagar_D (Madhya Pradesh)",
        FacilityCity: "Seoni",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13671,
        Pin: 700130,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13672,
        Pin: 803113,
        DispatchCenter: "Chandi_SH78_D (Bihar)",
        OriginCenter: "Chandi_SH78_D (Bihar)",
        FacilityCity: "Chandi",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13673,
        Pin: 425301,
        DispatchCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        OriginCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        FacilityCity: "Bhusawal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13674,
        Pin: 466120,
        DispatchCenter: "Ashta_Saicolony_D (Madhya Pradesh)",
        OriginCenter: "Ashta_Saicolony_D (Madhya Pradesh)",
        FacilityCity: "Ashta",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13675,
        Pin: 411052,
        DispatchCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        OriginCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13676,
        Pin: 562126,
        DispatchCenter: "Kanakapura_Malagaalu_D (Karnataka)",
        OriginCenter: "Kanakapura_Malagaalu_D (Karnataka)",
        FacilityCity: "Kanakapura",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13677,
        Pin: 574229,
        DispatchCenter: "Kadaba_AnugrDPP_D (Karnataka)",
        OriginCenter: "Kadaba_AnugrDPP_D (Karnataka)",
        FacilityCity: "Kadaba",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13678,
        Pin: 335063,
        DispatchCenter: "Sangaria_Wardno16_D (Rajasthan)",
        OriginCenter: "Sangaria_Wardno16_D (Rajasthan)",
        FacilityCity: "Sangaria",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13679,
        Pin: 628004,
        DispatchCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        OriginCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        FacilityCity: "Tuticorin",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13680,
        Pin: 304020,
        DispatchCenter: "Tonk_Bairwa_D (Rajasthan)",
        OriginCenter: "Tonk_Bairwa_D (Rajasthan)",
        FacilityCity: "Tonk",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13681,
        Pin: 141402,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13682,
        Pin: 734220,
        DispatchCenter: "Kurseong_PankhabariR_D (West Bengal)",
        OriginCenter: "Kurseong_PankhabariR_D (West Bengal)",
        FacilityCity: "Kurseong",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13683,
        Pin: 388560,
        DispatchCenter: "Anand_Borsad_D (Gujarat)",
        OriginCenter: "Anand_Borsad_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13684,
        Pin: 688533,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13685,
        Pin: 210128,
        DispatchCenter: "Baberu_FaujiColony_D (Uttar Pradesh)",
        OriginCenter: "Baberu_FaujiColony_D (Uttar Pradesh)",
        FacilityCity: "Baberu",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13686,
        Pin: 683547,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13687,
        Pin: 176026,
        DispatchCenter: "NagrotaSurian_Saknra_DPP (Himachal Pradesh)",
        OriginCenter: "NagrotaSurian_Saknra_DPP (Himachal Pradesh)",
        FacilityCity: "Nagrota Surian",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13688,
        Pin: 700053,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13689,
        Pin: 144031,
        DispatchCenter: "Phagwara_PurewalNgr_D (Punjab)",
        OriginCenter: "Phagwara_PurewalNgr_D (Punjab)",
        FacilityCity: "Phagwara",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13690,
        Pin: 695101,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13691,
        Pin: 828307,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13692,
        Pin: 415570,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13693,
        Pin: 814145,
        DispatchCenter: "Dumka_Hansdiha1_D (Jharkhand)",
        OriginCenter: "Dumka_Hansdiha1_D (Jharkhand)",
        FacilityCity: "Dumka",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13694,
        Pin: 733132,
        DispatchCenter: "Kaliyaganj_Central_DPP_1 (West Bengal)",
        OriginCenter: "Kaliyaganj_Central_DPP_1 (West Bengal)",
        FacilityCity: "Kaliyaganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13695,
        Pin: 362310,
        DispatchCenter: "Junagadh_Dolatpara_I (Gujarat)",
        OriginCenter: "Junagadh_Dolatpara_I (Gujarat)",
        FacilityCity: "Junagadh",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13696,
        Pin: 685603,
        DispatchCenter: "Iddukki_Thadiyampad_D (Kerala)",
        OriginCenter: "Iddukki_Thadiyampad_D (Kerala)",
        FacilityCity: "Iddukki",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13697,
        Pin: 758020,
        DispatchCenter: "Anandapur_Ghasipura_D (Orissa)",
        OriginCenter: "Anandapur_Ghasipura_D (Orissa)",
        FacilityCity: "Anandapur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13698,
        Pin: 160052,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13699,
        Pin: 625525,
        DispatchCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        OriginCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        FacilityCity: "Cumbum",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13700,
        Pin: 700129,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13701,
        Pin: 180001,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13702,
        Pin: 473551,
        DispatchCenter: "Shivpuri_GwliorBypas_D (Madhya Pradesh)",
        OriginCenter: "Shivpuri_GwliorBypas_D (Madhya Pradesh)",
        FacilityCity: "Shivpuri",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13703,
        Pin: 400029,
        DispatchCenter: "Mumbai_Kurla_L (Maharashtra)",
        OriginCenter: "Mumbai_Kurla_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13704,
        Pin: 759124,
        DispatchCenter: "Chhendipada_Deogarh_DPP (Orissa)",
        OriginCenter: "Chhendipada_Deogarh_DPP (Orissa)",
        FacilityCity: "Chhendipada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13705,
        Pin: 623207,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13706,
        Pin: 127027,
        DispatchCenter: "Meham_Ward13_D (Haryana)",
        OriginCenter: "Meham_Ward13_D (Haryana)",
        FacilityCity: "Meham",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13707,
        Pin: 531026,
        DispatchCenter: "Chodavaram_Narasayyapeta_D (Andhra Pradesh)",
        OriginCenter: "Chodavaram_Narasayyapeta_D (Andhra Pradesh)",
        FacilityCity: "Chodavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13708,
        Pin: 785700,
        DispatchCenter: "Teok_Wardno3_D (Assam)",
        OriginCenter: "Teok_Wardno3_D (Assam)",
        FacilityCity: "Teok",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13709,
        Pin: 533221,
        DispatchCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        OriginCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        FacilityCity: "Amalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13710,
        Pin: 829134,
        DispatchCenter: "Kedla_Ghatotand_D (Jharkhand)",
        OriginCenter: "Kedla_Ghatotand_D (Jharkhand)",
        FacilityCity: "Kedla",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13711,
        Pin: 333310,
        DispatchCenter: "Neemkathana_Chhawani_D (Rajasthan)",
        OriginCenter: "Neemkathana_Chhawani_D (Rajasthan)",
        FacilityCity: "Neem ka Thana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13712,
        Pin: 530021,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13713,
        Pin: 570009,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13714,
        Pin: 201204,
        DispatchCenter: "Modinagar_SangamAutoAg_D (Uttar Pradesh)",
        OriginCenter: "Modinagar_SangamAutoAg_D (Uttar Pradesh)",
        FacilityCity: "Modinagar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13715,
        Pin: 678544,
        DispatchCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        OriginCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        FacilityCity: "Pazhayannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13716,
        Pin: 133201,
        DispatchCenter: "Ambala_Barara_D (Haryana)",
        OriginCenter: "Ambala_Barara_D (Haryana)",
        FacilityCity: "Ambala",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13717,
        Pin: 388370,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13718,
        Pin: 222109,
        DispatchCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        OriginCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        FacilityCity: "Jaunpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13719,
        Pin: 758029,
        DispatchCenter: "Ghatgaon_Harichandanpur_D (Orissa)",
        OriginCenter: "Ghatgaon_Harichandanpur_D (Orissa)",
        FacilityCity: "Ghatgaon",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13720,
        Pin: 627436,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13721,
        Pin: 518218,
        DispatchCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        OriginCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13722,
        Pin: 600048,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13723,
        Pin: 560056,
        DispatchCenter: "Bangalore_FCILayout_L (Karnataka)",
        OriginCenter: "Bangalore_FCILayout_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13724,
        Pin: 442307,
        DispatchCenter: "Hinganghat_DyanshwrWrd_D (Maharashtra)",
        OriginCenter: "Hinganghat_DyanshwrWrd_D (Maharashtra)",
        FacilityCity: "Hinganghat",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13725,
        Pin: 494551,
        DispatchCenter: "Dantewada_Chitalanka_D (Chhattisgarh)",
        OriginCenter: "Dantewada_Chitalanka_D (Chhattisgarh)",
        FacilityCity: "Dantewada",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13726,
        Pin: 851203,
        DispatchCenter: "Maheshkhunt_PashchimTola_D (Bihar)",
        OriginCenter: "Maheshkhunt_PashchimTola_D (Bihar)",
        FacilityCity: "Maheshkhunt",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13727,
        Pin: 673323,
        DispatchCenter: "Koyilandy_Komathukara_D (Kerala)",
        OriginCenter: "Koyilandy_Komathukara_D (Kerala)",
        FacilityCity: "Koyilandy",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13728,
        Pin: 396006,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13729,
        Pin: 814142,
        DispatchCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        OriginCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        FacilityCity: "Deoghar",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13730,
        Pin: 416110,
        DispatchCenter: "Ichalkaranji_TmbeMala_D (Maharashtra)",
        OriginCenter: "Ichalkaranji_TmbeMala_D (Maharashtra)",
        FacilityCity: "Ichalkaranji",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13731,
        Pin: 713324,
        DispatchCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        OriginCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        FacilityCity: "Rupnarayanpur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13732,
        Pin: 781315,
        DispatchCenter: "Barpeta_NorthDPP_D (Assam)",
        OriginCenter: "Barpeta_ShaktiNagar_C (Assam)",
        FacilityCity: "Barpeta",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13733,
        Pin: 140417,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13734,
        Pin: 276137,
        DispatchCenter: "Rajesultanpur_PadumprMkt_D (Uttar Pradesh)",
        OriginCenter: "Rajesultanpur_PadumprMkt_D (Uttar Pradesh)",
        FacilityCity: "Rajesultanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13735,
        Pin: 734102,
        DispatchCenter: "Darjeeling_Limbugaon_D (West Bengal)",
        OriginCenter: "Darjeeling_Limbugaon_D (West Bengal)",
        FacilityCity: "Darjeeling",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13736,
        Pin: 787026,
        DispatchCenter: "Gogamukh_Rupahi_D (Assam)",
        OriginCenter: "Gogamukh_Rupahi_D (Assam)",
        FacilityCity: "Gogamukh",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13737,
        Pin: 231313,
        DispatchCenter: "Manda_Salia_D (Uttar Pradesh)",
        OriginCenter: "Manda_Salia_D (Uttar Pradesh)",
        FacilityCity: "Manda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13738,
        Pin: 421507,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13739,
        Pin: 456007,
        DispatchCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        OriginCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        FacilityCity: "Ujjain",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13740,
        Pin: 688523,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13741,
        Pin: 770031,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13742,
        Pin: 425409,
        DispatchCenter: "Shahada_Untavad_D (Maharashtra)",
        OriginCenter: "Shahada_Untavad_D (Maharashtra)",
        FacilityCity: "Shahada",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13743,
        Pin: 563115,
        DispatchCenter: "Bangarapet_AMPCClny_D (Karnataka)",
        OriginCenter: "Bangarapet_AMPCClny_D (Karnataka)",
        FacilityCity: "Bangarapet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13744,
        Pin: 683562,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13745,
        Pin: 321633,
        DispatchCenter: "Laxmangarh_Lilee_D (Rajasthan)",
        OriginCenter: "Laxmangarh_Lilee_D (Rajasthan)",
        FacilityCity: "Laxmangarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13746,
        Pin: 509334,
        DispatchCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        OriginCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        FacilityCity: "Mahabubnagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13747,
        Pin: 534328,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13748,
        Pin: 731129,
        DispatchCenter: "Suri_Karidhya_D (West Bengal)",
        OriginCenter: "Suri_Karidhya_D (West Bengal)",
        FacilityCity: "Suri",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13749,
        Pin: 181204,
        DispatchCenter: "Akhnoor_MiltryHosp_D (Jammu & Kashmir)",
        OriginCenter: "Akhnoor_MiltryHosp_D (Jammu & Kashmir)",
        FacilityCity: "Akhnoor",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13750,
        Pin: 401202,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13751,
        Pin: 410520,
        DispatchCenter: "Daund_Kurkumbh_L (Maharashtra)",
        OriginCenter: "Daund_Kurkumbh_L (Maharashtra)",
        FacilityCity: "Daund",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13752,
        Pin: 273207,
        DispatchCenter: "Nichlaul_Gorahwa_D (Uttar Pradesh)",
        OriginCenter: "Nichlaul_Gorahwa_D (Uttar Pradesh)",
        FacilityCity: "Nichlaul",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13753,
        Pin: 743290,
        DispatchCenter: "Naihati_Kalyani_DPP (West Bengal)",
        OriginCenter: "Naihati_Kalyani_DPP (West Bengal)",
        FacilityCity: "Kalyani",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13754,
        Pin: 401404,
        DispatchCenter: "Palghar_Maan_L (Maharashtra)",
        OriginCenter: "Palghar_Maan_L (Maharashtra)",
        FacilityCity: "Palghar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13755,
        Pin: 509205,
        DispatchCenter: "Narayanpet_Ashoknagar_D (Telangana)",
        OriginCenter: "Narayanpet_Ashoknagar_D (Telangana)",
        FacilityCity: "Narayanpet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13756,
        Pin: 755025,
        DispatchCenter: "Jajpur_Chandama_D (Orissa)",
        OriginCenter: "Jajpur_Chandama_D (Orissa)",
        FacilityCity: "Jajpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13757,
        Pin: 805110,
        DispatchCenter: "Nawada_Gondapur_D (Bihar)",
        OriginCenter: "Nawada_Gondapur_D (Bihar)",
        FacilityCity: "Nawada",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13758,
        Pin: 632113,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13759,
        Pin: 522612,
        DispatchCenter: "Macherla_Ward15_D (Andhra Pradesh)",
        OriginCenter: "Macherla_Ward15_D (Andhra Pradesh)",
        FacilityCity: "Macherla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13760,
        Pin: 491888,
        DispatchCenter: "Berla_Gandai_D (Chhattisgarh)",
        OriginCenter: "Berla_Gandai_D (Chhattisgarh)",
        FacilityCity: "Berla",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13761,
        Pin: 736206,
        DispatchCenter: "CoochBehar_khagrabari_I (West Bengal)",
        OriginCenter: "CoochBehar_khagrabari_I (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13762,
        Pin: 721519,
        DispatchCenter: "Jhargram_Raghunathpur_DPP (West Bengal)",
        OriginCenter: "Jhargram_Raghunathpur_DPP (West Bengal)",
        FacilityCity: "Jhargram",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13763,
        Pin: 752020,
        DispatchCenter: "Nirakarpur_Rameswar_D (Orissa)",
        OriginCenter: "Nirakarpur_Rameswar_D (Orissa)",
        FacilityCity: "Nirakarpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13764,
        Pin: 733202,
        DispatchCenter: "Islampure_Shibdangepara_D (West Bengal)",
        OriginCenter: "Islampure_Shibdangepara_D (West Bengal)",
        FacilityCity: "Islampure",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13765,
        Pin: 312000,
        DispatchCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        OriginCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        FacilityCity: "Chittaurgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13766,
        Pin: 680673,
        DispatchCenter: "Kodungallur_Methala_D (Kerala)",
        OriginCenter: "Kodungallur_Methala_D (Kerala)",
        FacilityCity: "Kodungallur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13767,
        Pin: 712208,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13768,
        Pin: 712105,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13769,
        Pin: 743372,
        DispatchCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        OriginCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        FacilityCity: "Dakshin Barasat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13770,
        Pin: 248159,
        DispatchCenter: "Herbertpur_Dhkrni_D (Uttarakhand)",
        OriginCenter: "Herbertpur_Dhkrni_D (Uttarakhand)",
        FacilityCity: "Herbertpur",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13771,
        Pin: 143411,
        DispatchCenter: "Goindwal_Fatehabad_D (Punjab)",
        OriginCenter: "Goindwal_Fatehabad_D (Punjab)",
        FacilityCity: "Goindwal",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13772,
        Pin: 623104,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13773,
        Pin: 624309,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13774,
        Pin: 441109,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Chikhali_L (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13775,
        Pin: 804452,
        DispatchCenter: "Masaurhi_Biranchi_D (Bihar)",
        OriginCenter: "Masaurhi_Biranchi_D (Bihar)",
        FacilityCity: "Masaurhi",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13776,
        Pin: 387330,
        DispatchCenter: "Nadiad_Shantinagar_D (Gujarat)",
        OriginCenter: "Nadiad_Shantinagar_D (Gujarat)",
        FacilityCity: "Nadiad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13777,
        Pin: 533202,
        DispatchCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        OriginCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        FacilityCity: "Amalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13778,
        Pin: 382026,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13779,
        Pin: 176601,
        DispatchCenter: "Tahliwal_Central_D (Himachal Pradesh)",
        OriginCenter: "Tahliwal_Central_D (Himachal Pradesh)",
        FacilityCity: "Tahliwal",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13780,
        Pin: 182312,
        DispatchCenter: "Reasi_Marhi_D (Jammu & Kashmir)",
        OriginCenter: "Reasi_Marhi_D (Jammu & Kashmir)",
        FacilityCity: "Reasi",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13781,
        Pin: 600100,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13782,
        Pin: 136117,
        DispatchCenter: "Kaithal_DefenceColony_D (Haryana)",
        OriginCenter: "Kaithal_DefenceColony_D (Haryana)",
        FacilityCity: "Kaithal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13783,
        Pin: 140101,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13784,
        Pin: 581198,
        DispatchCenter: "Byadagi_Basaveshwara_D (Karnataka)",
        OriginCenter: "Byadagi_Basaveshwara_D (Karnataka)",
        FacilityCity: "Byadagi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13785,
        Pin: 624618,
        DispatchCenter: "Palani_Sathyanagar_D (Tamil Nadu)",
        OriginCenter: "Palani_Sathyanagar_D (Tamil Nadu)",
        FacilityCity: "Palani",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13786,
        Pin: 722152,
        DispatchCenter: "Bankura_KeranDPP_D (West Bengal)",
        OriginCenter: "Bankura_KeranDPP_D (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13787,
        Pin: 402403,
        DispatchCenter: "Shriwardhan_Kherdi_D (Maharashtra)",
        OriginCenter: "Shriwardhan_Kherdi_D (Maharashtra)",
        FacilityCity: "Shriwardhan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13788,
        Pin: 451220,
        DispatchCenter: "Mhshwr_ktrgn_DPP (Madhya Pradesh)",
        OriginCenter: "Mhshwr_ktrgn_DPP (Madhya Pradesh)",
        FacilityCity: "Maheshwar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13789,
        Pin: 624307,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13790,
        Pin: 602101,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13791,
        Pin: 700114,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13792,
        Pin: 756118,
        DispatchCenter: "Dhamanagar_Temple_DPP (Orissa)",
        OriginCenter: "Dhamanagar_Temple_DPP (Orissa)",
        FacilityCity: "Dhamanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13793,
        Pin: 629704,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13794,
        Pin: 143103,
        DispatchCenter: "Amritsar_Ajnala_D (Punjab)",
        OriginCenter: "Amritsar_Ajnala_D (Punjab)",
        FacilityCity: "Ajnala",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13795,
        Pin: 689614,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13796,
        Pin: 577181,
        DispatchCenter: "Kalasa_MahaveerRd_DPP (Karnataka)",
        OriginCenter: "Kalasa_MahaveerRd_DPP (Karnataka)",
        FacilityCity: "Kalasa",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13797,
        Pin: 363630,
        DispatchCenter: "Halvad_SaraCross_DPP (Gujarat)",
        OriginCenter: "Halvad_SaraCross_DPP (Gujarat)",
        FacilityCity: "Halvad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13798,
        Pin: 784175,
        DispatchCenter: "Biswanath_CharialiLIC_D (Assam)",
        OriginCenter: "Biswanath_CharialiLIC_D (Assam)",
        FacilityCity: "BiswanathCH",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13799,
        Pin: 250645,
        DispatchCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        OriginCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        FacilityCity: "Baraut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13800,
        Pin: 570028,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13801,
        Pin: 387550,
        DispatchCenter: "Ahmedabad_Kanera_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13802,
        Pin: 821110,
        DispatchCenter: "Nuaon_Block_D (Bihar)",
        OriginCenter: "Nuaon_Block_D (Bihar)",
        FacilityCity: "Nuaon",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13803,
        Pin: 562129,
        DispatchCenter: "Bangalore_Koralur_L (Karnataka)",
        OriginCenter: "Bangalore_Soukyaroad_GW (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13804,
        Pin: 506350,
        DispatchCenter: "Narsampet_PakhalRoad_D (Telangana)",
        OriginCenter: "Narsampet_PakhalRoad_D (Telangana)",
        FacilityCity: "Narsampet",
        FacilityState: "Telangana",
        Zone : "S1"
      },
      {
        SN: 13805,
        Pin: 643215,
        DispatchCenter: "Ooty_Westmere_D (Tamil Nadu)",
        OriginCenter: "Ooty_Westmere_D (Tamil Nadu)",
        FacilityCity: "Ooty",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13806,
        Pin: 587314,
        DispatchCenter: "Mahalingpur_Bagalkot_D (Karnataka)",
        OriginCenter: "Mahalingpur_Bagalkot_D (Karnataka)",
        FacilityCity: "Mahalingpur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13807,
        Pin: 227208,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_KhandakMarg_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13808,
        Pin: 416407,
        DispatchCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        OriginCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        FacilityCity: "Miraj",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13809,
        Pin: 491332,
        DispatchCenter: "Berla_CommunityCntr_DPP (Chhattisgarh)",
        OriginCenter: "Berla_CommunityCntr_DPP (Chhattisgarh)",
        FacilityCity: "Berla",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13810,
        Pin: 321408,
        DispatchCenter: "Weir_Bhusawar_DPP (Rajasthan)",
        OriginCenter: "Weir_Bhusawar_DPP (Rajasthan)",
        FacilityCity: "Weir",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13811,
        Pin: 396223,
        DispatchCenter: "Daman_DC (Daman & Diu)",
        OriginCenter: "Daman_DC (Daman & Diu)",
        FacilityCity: "Daman",
        FacilityState: "Daman & Diu",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13812,
        Pin: 244303,
        DispatchCenter: "Sambhal_DurgaColny_D (Uttar Pradesh)",
        OriginCenter: "Sambhal_DurgaColny_D (Uttar Pradesh)",
        FacilityCity: "Sambhal",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13813,
        Pin: 576234,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13814,
        Pin: 695120,
        DispatchCenter: "Kottarakkara_MythriNagar_D (Kerala)",
        OriginCenter: "Kottarakkara_MythriNagar_D (Kerala)",
        FacilityCity: "Kottarakkara",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13815,
        Pin: 416134,
        DispatchCenter: "Jaysingpur_Swapnanagari_D (Maharashtra)",
        OriginCenter: "Jaysingpur_Swapnanagari_D (Maharashtra)",
        FacilityCity: "Jaysingpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13816,
        Pin: 364130,
        DispatchCenter: "Mahuva_Aambavadi_DPP (Gujarat)",
        OriginCenter: "Mahuva_Aambavadi_DPP (Gujarat)",
        FacilityCity: "Mahuva",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13817,
        Pin: 691571,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13818,
        Pin: 403403,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13819,
        Pin: 581335,
        DispatchCenter: "Honnavar_ForestColony_D (Karnataka)",
        OriginCenter: "Honnavar_ForestColony_D (Karnataka)",
        FacilityCity: "Honnavar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13820,
        Pin: 600015,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13821,
        Pin: 390016,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13822,
        Pin: 516213,
        DispatchCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13823,
        Pin: 370155,
        DispatchCenter: "Rapar_Adesar_D (Gujarat)",
        OriginCenter: "Rapar_Adesar_D (Gujarat)",
        FacilityCity: "Rapar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13824,
        Pin: 572103,
        DispatchCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        OriginCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13825,
        Pin: 500055,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13826,
        Pin: 614601,
        DispatchCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        OriginCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        FacilityCity: "Pattukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13827,
        Pin: 275307,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13828,
        Pin: 793121,
        DispatchCenter: "Shillong_Mawngap_D (Meghalaya)",
        OriginCenter: "Shillong_Mawngap_D (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13829,
        Pin: 124412,
        DispatchCenter: "Rohtak_IndustrialArea_D (Haryana)",
        OriginCenter: "Rohtak_IndustrialArea_D (Haryana)",
        FacilityCity: "Rohtak",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13830,
        Pin: 824201,
        DispatchCenter: "Barachatti_DangraMore_D (Bihar)",
        OriginCenter: "Barachatti_DangraMore_D (Bihar)",
        FacilityCity: "Barachatti",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13831,
        Pin: 416552,
        DispatchCenter: "Halkarni_MajareKarve_D (Maharashtra)",
        OriginCenter: "Halkarni_MajareKarve_D (Maharashtra)",
        FacilityCity: "Halkarni",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13832,
        Pin: 784162,
        DispatchCenter: "Narayanpur_Panbari_D (Assam)",
        OriginCenter: "Narayanpur_Panbari_D (Assam)",
        FacilityCity: "Narayanpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13833,
        Pin: 576217,
        DispatchCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        OriginCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13834,
        Pin: 811302,
        DispatchCenter: "Lakhisarai_Central_I_2 (Bihar)",
        OriginCenter: "Lakhisarai_Central_I_2 (Bihar)",
        FacilityCity: "Lakhisarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13835,
        Pin: 490012,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13836,
        Pin: 624215,
        DispatchCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        OriginCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        FacilityCity: "Vadipatti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13837,
        Pin: 133103,
        DispatchCenter: "Yamunanagar_Veerngrcly_D (Haryana)",
        OriginCenter: "Yamunanagar_Veerngrcly_D (Haryana)",
        FacilityCity: "Yamuna Nagar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13838,
        Pin: 370210,
        DispatchCenter: "Gandhidham_BharatNagar_I (Gujarat)",
        OriginCenter: "Gandhidham_BharatNagar_I (Gujarat)",
        FacilityCity: "Gandhidham",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13839,
        Pin: 493331,
        DispatchCenter: "BalodaBazar_Pahanda_D (Chhattisgarh)",
        OriginCenter: "BalodaBazar_Pahanda_D (Chhattisgarh)",
        FacilityCity: "Baloda Bazar",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13840,
        Pin: 506006,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13841,
        Pin: 364150,
        DispatchCenter: "Talaja_Shivajinagar_D (Gujarat)",
        OriginCenter: "Talaja_Shivajinagar_D (Gujarat)",
        FacilityCity: "Talaja",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13842,
        Pin: 140307,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13843,
        Pin: 110096,
        DispatchCenter: "Delhi_JhilmilColony_L (Delhi)",
        OriginCenter: "Delhi_JhilmilColony_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13844,
        Pin: 441122,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13845,
        Pin: 602027,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13846,
        Pin: 144101,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13847,
        Pin: 764051,
        DispatchCenter: "Balimela_MainRoad_DPP (Orissa)",
        OriginCenter: "Balimela_MainRoad_DPP (Orissa)",
        FacilityCity: "Balimela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13848,
        Pin: 798611,
        DispatchCenter: "Kiphire_Dccolony_D (Nagaland)",
        OriginCenter: "Kiphire_Dccolony_D (Nagaland)",
        FacilityCity: "Kiphire",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13849,
        Pin: 493445,
        DispatchCenter: "Mahasamund_WardNo15_D (Chhattisgarh)",
        OriginCenter: "Mahasamund_WardNo15_D (Chhattisgarh)",
        FacilityCity: "Mahasamund",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13850,
        Pin: 700019,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13851,
        Pin: 402207,
        DispatchCenter: "Alibag_Shivanagar_D (Maharashtra)",
        OriginCenter: "Alibag_Shivanagar_D (Maharashtra)",
        FacilityCity: "Alibag",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13852,
        Pin: 282002,
        DispatchCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        OriginCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        FacilityCity: "Agra",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13853,
        Pin: 388225,
        DispatchCenter: "Umreth_PltinumPlza_DPP (Gujarat)",
        OriginCenter: "Umreth_PltinumPlza_DPP (Gujarat)",
        FacilityCity: "Umreth",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13854,
        Pin: 521322,
        DispatchCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        OriginCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        FacilityCity: "Gudivada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13855,
        Pin: 277302,
        DispatchCenter: "Ballia_Sikandarpur_D (Uttar Pradesh)",
        OriginCenter: "Ballia_Sikandarpur_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13856,
        Pin: 560100,
        DispatchCenter: "Bangalore_BmsdraPhase3_L (Karnataka)",
        OriginCenter: "Bangalore_BmsdraPhase3_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13857,
        Pin: 141109,
        DispatchCenter: "Raikot_DC (Punjab)",
        OriginCenter: "Raikot_DC (Punjab)",
        FacilityCity: "Raikot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13858,
        Pin: 424001,
        DispatchCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        OriginCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        FacilityCity: "Dhule",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13859,
        Pin: 523304,
        DispatchCenter: "Markapur_Vabbapuram_D (Andhra Pradesh)",
        OriginCenter: "Markapur_Vabbapuram_D (Andhra Pradesh)",
        FacilityCity: "Markapur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13860,
        Pin: 670358,
        DispatchCenter: "Kannur_Aduthila_D (Kerala)",
        OriginCenter: "Kannur_Aduthila_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13861,
        Pin: 722139,
        DispatchCenter: "Bankura_Chhatna_DPP (West Bengal)",
        OriginCenter: "Bankura_Chhatna_DPP (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13862,
        Pin: 721148,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13863,
        Pin: 380028,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13864,
        Pin: 624616,
        DispatchCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        OriginCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        FacilityCity: "Oddanchatram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13865,
        Pin: 515306,
        DispatchCenter: "Madakasira_RTCStand_D (Andhra Pradesh)",
        OriginCenter: "Madakasira_RTCStand_D (Andhra Pradesh)",
        FacilityCity: "Madakasira",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13866,
        Pin: 491107,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13867,
        Pin: 535547,
        DispatchCenter: "Bobbili_Aguruvidi_D (Andhra Pradesh)",
        OriginCenter: "Bobbili_Aguruvidi_D (Andhra Pradesh)",
        FacilityCity: "Bobbili",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13868,
        Pin: 415501,
        DispatchCenter: "Koregaon_Lokmanyanagar_D (Maharashtra)",
        OriginCenter: "Koregaon_Lokmanyanagar_D (Maharashtra)",
        FacilityCity: "Koregaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13869,
        Pin: 785635,
        DispatchCenter: "Jorhat_Titabor_D (Assam)",
        OriginCenter: "Jorhat_Titabor_D (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13870,
        Pin: 494441,
        DispatchCenter: "Dantewada_Chitalanka_D (Chhattisgarh)",
        OriginCenter: "Dantewada_Chitalanka_D (Chhattisgarh)",
        FacilityCity: "Dantewada",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13871,
        Pin: 844504,
        DispatchCenter: "ManharBazar_NayaGaon_D (Bihar)",
        OriginCenter: "ManharBazar_NayaGaon_D (Bihar)",
        FacilityCity: "Mahnar Bazar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13872,
        Pin: 690572,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13873,
        Pin: 344033,
        DispatchCenter: "Barmer_Baldevnagar_DPP (Rajasthan)",
        OriginCenter: "Barmer_Baldevnagar_DPP (Rajasthan)",
        FacilityCity: "Barmer",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13874,
        Pin: 500090,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13875,
        Pin: 332401,
        DispatchCenter: "Balaran_Ward2_DPP (Rajasthan)",
        OriginCenter: "Balaran_Ward2_DPP (Rajasthan)",
        FacilityCity: "Balaran",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13876,
        Pin: 143419,
        DispatchCenter: "Patti_TarnTaran_D (Punjab)",
        OriginCenter: "Patti_TarnTaran_D (Punjab)",
        FacilityCity: "Patti",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13877,
        Pin: 140408,
        DispatchCenter: "SirhindFatehgarh_DC (Punjab)",
        OriginCenter: "SirhindFatehgarh_DC (Punjab)",
        FacilityCity: "Sirhind-Fategarh",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13878,
        Pin: 622203,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13879,
        Pin: 854103,
        DispatchCenter: "Purnia_Kasba_H (Bihar)",
        OriginCenter: "Purnia_Kasba_H (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13880,
        Pin: 743702,
        DispatchCenter: "Joypul_Maniktala_D (West Bengal)",
        OriginCenter: "Joypul_Maniktala_D (West Bengal)",
        FacilityCity: "Joypul",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13881,
        Pin: 583282,
        DispatchCenter: "Gangavathi_CBSGanj_DPP (Karnataka)",
        OriginCenter: "Gangavathi_CBSGanj_DPP (Karnataka)",
        FacilityCity: "Gangavathi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13882,
        Pin: 782002,
        DispatchCenter: "Nagaon_Bangthai_H (Assam)",
        OriginCenter: "Nagaon_Bangthai_H (Assam)",
        FacilityCity: "Nagaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13883,
        Pin: 624701,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13884,
        Pin: 799254,
        DispatchCenter: "Dharmanagar_Ward3_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Dharmanagar",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13885,
        Pin: 711402,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13886,
        Pin: 811304,
        DispatchCenter: "Sheikhpura_Lalbagh_D (Bihar)",
        OriginCenter: "Sheikhpura_Lalbagh_D (Bihar)",
        FacilityCity: "Sheikhpura",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13887,
        Pin: 851216,
        DispatchCenter: "Naugachia_Mahaddipur_DPP (Bihar)",
        OriginCenter: "Naugachia_Mahaddipur_DPP (Bihar)",
        FacilityCity: "Naugachia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13888,
        Pin: 142001,
        DispatchCenter: "Moga_Duneke_I (Punjab)",
        OriginCenter: "Moga_Duneke_I (Punjab)",
        FacilityCity: "Moga",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13889,
        Pin: 416526,
        DispatchCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        OriginCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        FacilityCity: "Gadhinglaj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13890,
        Pin: 824302,
        DispatchCenter: "Kanker_JaplaBarunRd_DPP (Bihar)",
        OriginCenter: "Kanker_JaplaBarunRd_DPP (Bihar)",
        FacilityCity: "KankerBR",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13891,
        Pin: 314028,
        DispatchCenter: "Dungarpur_IrolZoneCmplx_DPP (Rajasthan)",
        OriginCenter: "Dungarpur_IrolZoneCmplx_DPP (Rajasthan)",
        FacilityCity: "Dungarpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13892,
        Pin: 752066,
        DispatchCenter: "Nayagarh_Durgprsd_D (Orissa)",
        OriginCenter: "Nayagarh_Durgprsd_D (Orissa)",
        FacilityCity: "Nayagarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13893,
        Pin: 757045,
        DispatchCenter: "Rairangpur_Sanchampauda_D (Orissa)",
        OriginCenter: "Rairangpur_Sanchampauda_D (Orissa)",
        FacilityCity: "Rairangpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13894,
        Pin: 670679,
        DispatchCenter: "Thalassery_Panoor_D (Kerala)",
        OriginCenter: "Thalassery_Panoor_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13895,
        Pin: 560090,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13896,
        Pin: 230128,
        DispatchCenter: "Kunda_KaretiRd_D (Uttar Pradesh)",
        OriginCenter: "Kunda_KaretiRd_D (Uttar Pradesh)",
        FacilityCity: "Kunda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13897,
        Pin: 400093,
        DispatchCenter: "Mumbai_Kurla_L (Maharashtra)",
        OriginCenter: "Mumbai_Kurla_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13898,
        Pin: 805129,
        DispatchCenter: "Wazirganj_Usawa_D (Bihar)",
        OriginCenter: "Wazirganj_Usawa_D (Bihar)",
        FacilityCity: "Wazirganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13899,
        Pin: 743272,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13900,
        Pin: 522261,
        DispatchCenter: "Tenali_Pandurngpet_D (Andhra Pradesh)",
        OriginCenter: "Tenali_Pandurngpet_D (Andhra Pradesh)",
        FacilityCity: "Tenali",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13901,
        Pin: 721441,
        DispatchCenter: "Contai_Fatepur_DPP (West Bengal)",
        OriginCenter: "Contai_Fatepur_DPP (West Bengal)",
        FacilityCity: "RamnagarWB",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13902,
        Pin: 743338,
        DispatchCenter: "DakshinBarasat_Raidighi_DPP (West Bengal)",
        OriginCenter: "DakshinBarasat_Raidighi_DPP (West Bengal)",
        FacilityCity: "Dakshin Barasat",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13903,
        Pin: 683545,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13904,
        Pin: 532123,
        DispatchCenter: "Srikakulam_Ponduru_D (Andhra Pradesh)",
        OriginCenter: "Srikakulam_Ponduru_D (Andhra Pradesh)",
        FacilityCity: "Srikakulam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13905,
        Pin: 609502,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13906,
        Pin: 785008,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13907,
        Pin: 416607,
        DispatchCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        OriginCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        FacilityCity: "Malvan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13908,
        Pin: 413508,
        DispatchCenter: "Osmanabad_Murud_D (Maharashtra)",
        OriginCenter: "Osmanabad_Murud_D (Maharashtra)",
        FacilityCity: "Osmanabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13909,
        Pin: 249204,
        DispatchCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        OriginCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        FacilityCity: "Rishikesh",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13910,
        Pin: 721662,
        DispatchCenter: "Haldia_Basudevpur_D (West Bengal)",
        OriginCenter: "Haldia_Basudevpur_D (West Bengal)",
        FacilityCity: "Haldia",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13911,
        Pin: 628213,
        DispatchCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        OriginCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        FacilityCity: "Tiruchendur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13912,
        Pin: 440020,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13913,
        Pin: 233302,
        DispatchCenter: "Aunrihar_JawahrNgr_D (Uttar Pradesh)",
        OriginCenter: "Aunrihar_JawahrNgr_D (Uttar Pradesh)",
        FacilityCity: "Aunrihar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13914,
        Pin: 678006,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13915,
        Pin: 402205,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13916,
        Pin: 678598,
        DispatchCenter: "Thachnttukra_Mangadn_D (Kerala)",
        OriginCenter: "Thachnttukra_Mangadn_D (Kerala)",
        FacilityCity: "Thachanattukara",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 13917,
        Pin: 332712,
        DispatchCenter: "Ajeetgarh_Jugrajpura_D (Rajasthan)",
        OriginCenter: "Ajeetgarh_Jugrajpura_D (Rajasthan)",
        FacilityCity: "Ajeetgarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13918,
        Pin: 276203,
        DispatchCenter: "Aunrihar_Mehnajpur_DPP (Uttar Pradesh)",
        OriginCenter: "Aunrihar_Mehnajpur_DPP (Uttar Pradesh)",
        FacilityCity: "Aunrihar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13919,
        Pin: 131402,
        DispatchCenter: "Sonipat_Sector51_L (Haryana)",
        OriginCenter: "Sonipat_WazidpurSaboli_P (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13920,
        Pin: 722179,
        DispatchCenter: "Bankura_KeranDPP_D (West Bengal)",
        OriginCenter: "Bankura_KeranDPP_D (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13921,
        Pin: 855102,
        DispatchCenter: "Katihar_Barsoi_D (Bihar)",
        OriginCenter: "Katihar_Barsoi_D (Bihar)",
        FacilityCity: "Katihar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13922,
        Pin: 635128,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13923,
        Pin: 577591,
        DispatchCenter: "Davangere_Industrialarea_I (Karnataka)",
        OriginCenter: "Davangere_Industrialarea_I (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13924,
        Pin: 480888,
        DispatchCenter: "Lakhnadon_Ward5_D (Madhya Pradesh)",
        OriginCenter: "Lakhnadon_Ward5_D (Madhya Pradesh)",
        FacilityCity: "Lakhnadon",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 13925,
        Pin: 673573,
        DispatchCenter: "Thamarassery_Raoth_D (Kerala)",
        OriginCenter: "Thamarassery_Raoth_D (Kerala)",
        FacilityCity: "Thamarassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13926,
        Pin: 205265,
        DispatchCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        OriginCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        FacilityCity: "Mainpuri",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13927,
        Pin: 275301,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13928,
        Pin: 123103,
        DispatchCenter: "Rewari_Kosli_D (Haryana)",
        OriginCenter: "Rewari_Kosli_D (Haryana)",
        FacilityCity: "Jhajjar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13929,
        Pin: 262721,
        DispatchCenter: "Lakhimpur_FunMall_D (Uttar Pradesh)",
        OriginCenter: "Lakhimpur_FunMall_D (Uttar Pradesh)",
        FacilityCity: "Lakhimpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13930,
        Pin: 243501,
        DispatchCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        OriginCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        FacilityCity: "Bareilly",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13931,
        Pin: 396521,
        DispatchCenter: "Bilimora_DC (Gujarat)",
        OriginCenter: "Bilimora_DC (Gujarat)",
        FacilityCity: "Bilimora",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 13932,
        Pin: 531114,
        DispatchCenter: "Narsipatnam_Sivapuram_D (Andhra Pradesh)",
        OriginCenter: "Narsipatnam_Sivapuram_D (Andhra Pradesh)",
        FacilityCity: "Narsipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13933,
        Pin: 781372,
        DispatchCenter: "Tihu_Jalahgaon_D (Assam)",
        OriginCenter: "Tihu_Jalahgaon_D (Assam)",
        FacilityCity: "Tihu",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13934,
        Pin: 212208,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13935,
        Pin: 851102,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13936,
        Pin: 678541,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13937,
        Pin: 682011,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13938,
        Pin: 394010,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 13939,
        Pin: 755015,
        DispatchCenter: "Jajpur_Chandama_D (Orissa)",
        OriginCenter: "Jajpur_Chandama_D (Orissa)",
        FacilityCity: "Jajpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13940,
        Pin: 522626,
        DispatchCenter: "Chilakaluripet_ChrlesChrch_DPP (Andhra Pradesh)",
        OriginCenter: "Chilakaluripet_ChrlesChrch_DPP (Andhra Pradesh)",
        FacilityCity: "Chilakaluripet",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13941,
        Pin: 721467,
        DispatchCenter: "Lutunia_Bholenthtmpl_D (West Bengal)",
        OriginCenter: "Lutunia_Bholenthtmpl_D (West Bengal)",
        FacilityCity: "Lutunia",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13942,
        Pin: 685616,
        DispatchCenter: "Munnar_IkkaNgr_D (Kerala)",
        OriginCenter: "Munnar_IkkaNgr_D (Kerala)",
        FacilityCity: "Munnar",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13943,
        Pin: 610101,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13944,
        Pin: 522024,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13945,
        Pin: 852116,
        DispatchCenter: "Udakishanganj_ClgChwk_D (Bihar)",
        OriginCenter: "Udakishanganj_ClgChwk_D (Bihar)",
        FacilityCity: "Udakishanganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13946,
        Pin: 824301,
        DispatchCenter: "AurangabadBR_Katharua_D (Bihar)",
        OriginCenter: "AurangabadBR_Katharua_D (Bihar)",
        FacilityCity: "Aurangabad-BR",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13947,
        Pin: 205135,
        DispatchCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        OriginCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        FacilityCity: "Shikohabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13948,
        Pin: 800014,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13949,
        Pin: 627201,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13950,
        Pin: 754107,
        DispatchCenter: "Balikuda_Balisahi_D (Orissa)",
        OriginCenter: "Balikuda_Balisahi_D (Orissa)",
        FacilityCity: "Balikuda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13951,
        Pin: 413529,
        DispatchCenter: "Ahmedpur_ITIclg_D (Maharashtra)",
        OriginCenter: "Ahmedpur_ITIclg_D (Maharashtra)",
        FacilityCity: "Ahmadpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13952,
        Pin: 400035,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13953,
        Pin: 123106,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13954,
        Pin: 642101,
        DispatchCenter: "Pollachi_ThangamNagar_D (Tamil Nadu)",
        OriginCenter: "Pollachi_ThangamNagar_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13955,
        Pin: 486553,
        DispatchCenter: "Raipurkarchulion_Joginhai_D (Madhya Pradesh)",
        OriginCenter: "Raipurkarchulion_Joginhai_D (Madhya Pradesh)",
        FacilityCity: "Raipur Karchulion",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 13956,
        Pin: 691556,
        DispatchCenter: "Adoor_Pathanapuram_D (Kerala)",
        OriginCenter: "Adoor_Pathanapuram_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13957,
        Pin: 591111,
        DispatchCenter: "Saundatti_Bypassroad_D (Karnataka)",
        OriginCenter: "Saundatti_Bypassroad_D (Karnataka)",
        FacilityCity: "Saundatti",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13958,
        Pin: 143512,
        DispatchCenter: "Gurdaspur_Civillines_D (Punjab)",
        OriginCenter: "Gurdaspur_Civillines_D (Punjab)",
        FacilityCity: "Gurdaspur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13959,
        Pin: 742238,
        DispatchCenter: "Morgram_Kantagoriya_D (West Bengal)",
        OriginCenter: "Morgram_Kantagoriya_D (West Bengal)",
        FacilityCity: "Morgram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 13960,
        Pin: 586213,
        DispatchCenter: "Muddebihal_Nekarcolony_D (Karnataka)",
        OriginCenter: "Muddebihal_Nekarcolony_D (Karnataka)",
        FacilityCity: "Muddebihal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13961,
        Pin: 401200,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13962,
        Pin: 335023,
        DispatchCenter: "PadampurRJ_Srikaranpur_D (Rajasthan)",
        OriginCenter: "PadampurRJ_Srikaranpur_D (Rajasthan)",
        FacilityCity: "PadampurRJ",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13963,
        Pin: 785004,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13964,
        Pin: 624401,
        DispatchCenter: "Natham_CPCmplx_DPP (Tamil Nadu)",
        OriginCenter: "Natham_CPCmplx_DPP (Tamil Nadu)",
        FacilityCity: "Natham",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13965,
        Pin: 682038,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 13966,
        Pin: 609301,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13967,
        Pin: 415406,
        DispatchCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        OriginCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        FacilityCity: "Islampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13968,
        Pin: 245407,
        DispatchCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        OriginCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        FacilityCity: "Siyana",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13969,
        Pin: 788117,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13970,
        Pin: 766015,
        DispatchCenter: "Junagarh_Dharmagarh1_D (Orissa)",
        OriginCenter: "Junagarh_Dharmagarh1_D (Orissa)",
        FacilityCity: "Junagarh-OR",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13971,
        Pin: 413106,
        DispatchCenter: "Indapur_ChaitanyaPlaza_D (Maharashtra)",
        OriginCenter: "Indapur_ChaitanyaPlaza_D (Maharashtra)",
        FacilityCity: "Indapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13972,
        Pin: 515591,
        DispatchCenter: "Kadiri_NagireddyPalli_D (Andhra Pradesh)",
        OriginCenter: "Kadiri_NagireddyPalli_D (Andhra Pradesh)",
        FacilityCity: "Kadiri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13973,
        Pin: 335504,
        DispatchCenter: "Bhadra_GanapatiPlaza_D (Rajasthan)",
        OriginCenter: "Bhadra_GanapatiPlaza_D (Rajasthan)",
        FacilityCity: "Bhadra",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13974,
        Pin: 263156,
        DispatchCenter: "Bhimtal_BhowaliRd_D (Uttarakhand)",
        OriginCenter: "Bhimtal_BhowaliRd_D (Uttarakhand)",
        FacilityCity: "Bhimtal",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 13975,
        Pin: 202392,
        DispatchCenter: "Dibai_Pahasu_D (Uttar Pradesh)",
        OriginCenter: "Dibai_Pahasu_D (Uttar Pradesh)",
        FacilityCity: "Dibai",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13976,
        Pin: 561206,
        DispatchCenter: "Gauribidanur_Srinagara_D (Karnataka)",
        OriginCenter: "Gauribidanur_Srinagara_D (Karnataka)",
        FacilityCity: "Gauribidanur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13977,
        Pin: 768116,
        DispatchCenter: "Sambalpur_Tentelpara_H (Orissa)",
        OriginCenter: "Sambalpur_Tentelpara_H (Orissa)",
        FacilityCity: "Sambalpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 13978,
        Pin: 605802,
        DispatchCenter: "Sankarapuram_Vadasiruvalur_D (Tamil Nadu)",
        OriginCenter: "Sankarapuram_Vadasiruvalur_D (Tamil Nadu)",
        FacilityCity: "Sankarapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13979,
        Pin: 524240,
        DispatchCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        OriginCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        FacilityCity: "Atmakur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13980,
        Pin: 175010,
        DispatchCenter: "Karsog_Busstd_D (Himachal Pradesh)",
        OriginCenter: "Karsog_Busstd_D (Himachal Pradesh)",
        FacilityCity: "Karsog",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13981,
        Pin: 144529,
        DispatchCenter: "Garhshankar_BangaRoad_D (Punjab)",
        OriginCenter: "Garhshankar_BangaRoad_D (Punjab)",
        FacilityCity: "Garhshanker",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13982,
        Pin: 767022,
        DispatchCenter: "Padampur_Melchhamunda_DPP (Orissa)",
        OriginCenter: "Padampur_Melchhamunda_DPP (Orissa)",
        FacilityCity: "Padampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13983,
        Pin: 311801,
        DispatchCenter: "Gangapur_Sahara_DPP (Rajasthan)",
        OriginCenter: "Gangapur_Sahara_DPP (Rajasthan)",
        FacilityCity: "Gangapur-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13984,
        Pin: 799104,
        DispatchCenter: "AmarpurTP_Nutanbazar_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Amarpur TP",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13985,
        Pin: 600052,
        DispatchCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        OriginCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13986,
        Pin: 160022,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13987,
        Pin: 207241,
        DispatchCenter: "Kasganj_GandhiKunj_D (Uttar Pradesh)",
        OriginCenter: "Kasganj_GandhiKunj_D (Uttar Pradesh)",
        FacilityCity: "Kasganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 13988,
        Pin: 641201,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 13989,
        Pin: 506168,
        DispatchCenter: "Bhupalpally_LaxmiNagar_DPP (Telangana)",
        OriginCenter: "Bhupalpally_LaxmiNagar_DPP (Telangana)",
        FacilityCity: "Bhupalpally",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 13990,
        Pin: 442201,
        DispatchCenter: "Talegaon_Ramdara_D (Maharashtra)",
        OriginCenter: "Talegaon_Ramdara_D (Maharashtra)",
        FacilityCity: "Talegaon Sp",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 13991,
        Pin: 190012,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13992,
        Pin: 831017,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 13993,
        Pin: 431205,
        DispatchCenter: "Georai_Jayakwadicolony_D (Maharashtra)",
        OriginCenter: "Georai_Jayakwadicolony_D (Maharashtra)",
        FacilityCity: "Georai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13994,
        Pin: 110002,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 13995,
        Pin: 411000,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13996,
        Pin: 249401,
        DispatchCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        OriginCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        FacilityCity: "Haridwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 13997,
        Pin: 445001,
        DispatchCenter: "Yavatmal_JajuDPP_D (Maharashtra)",
        OriginCenter: "Yavatmal_JajuDPP_D (Maharashtra)",
        FacilityCity: "Yavatmal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 13998,
        Pin: 761121,
        DispatchCenter: "Jagannathprasad_Jirabadi_DPP (Orissa)",
        OriginCenter: "Jagannathprasad_Jirabadi_DPP (Orissa)",
        FacilityCity: "Jagannathprasad",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 13999,
        Pin: 500361,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14000,
        Pin: 642120,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14001,
        Pin: 573217,
        DispatchCenter: "Hassan_Nagathavalli_I (Karnataka)",
        OriginCenter: "Hassan_Nagathavalli_I (Karnataka)",
        FacilityCity: "Hassan",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14002,
        Pin: 581403,
        DispatchCenter: "Sirsi_Kasturbanagar_D (Karnataka)",
        OriginCenter: "Sirsi_Kasturbanagar_D (Karnataka)",
        FacilityCity: "Sirsi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14003,
        Pin: 688558,
        DispatchCenter: "Alappuzha_Nedumbrakkad_D (Kerala)",
        OriginCenter: "Alappuzha_Nedumbrakkad_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14004,
        Pin: 626117,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14005,
        Pin: 581121,
        DispatchCenter: "Alnavar_Gharli_DPP (Karnataka)",
        OriginCenter: "Alnavar_Gharli_DPP (Karnataka)",
        FacilityCity: "Alnavar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14006,
        Pin: 176101,
        DispatchCenter: "Dheera_Purba_DPP (Himachal Pradesh)",
        OriginCenter: "Dheera_Purba_DPP (Himachal Pradesh)",
        FacilityCity: "Dheera",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14007,
        Pin: 423401,
        DispatchCenter: "Yeola_Angangaon_D (Maharashtra)",
        OriginCenter: "Yeola_Angangaon_D (Maharashtra)",
        FacilityCity: "Yeola",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14008,
        Pin: 487118,
        DispatchCenter: "Tendukheda_Shahpura_D (Madhya Pradesh)",
        OriginCenter: "Tendukheda_Shahpura_D (Madhya Pradesh)",
        FacilityCity: "Tendukheda",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 14009,
        Pin: 465118,
        DispatchCenter: "PolayKalan_Himmatpura_DPP (Madhya Pradesh)",
        OriginCenter: "PolayKalan_Himmatpura_DPP (Madhya Pradesh)",
        FacilityCity: "Polay Kalan",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 14010,
        Pin: 635654,
        DispatchCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        OriginCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        FacilityCity: "Tiruppattur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14011,
        Pin: 575021,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14012,
        Pin: 144409,
        DispatchCenter: "Phillaur_Parkashnagar_D (Punjab)",
        OriginCenter: "Phillaur_Parkashnagar_D (Punjab)",
        FacilityCity: "Phillaur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14013,
        Pin: 500660,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14014,
        Pin: 416221,
        DispatchCenter: "RashiwadeBk_Shelewadi_DPP (Maharashtra)",
        OriginCenter: "RashiwadeBk_Shelewadi_DPP (Maharashtra)",
        FacilityCity: "Rashiwade Bk",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14015,
        Pin: 277501,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14016,
        Pin: 752006,
        DispatchCenter: "Puri_Purussottamnagar_D (Orissa)",
        OriginCenter: "Puri_Purussottamnagar_D (Orissa)",
        FacilityCity: "Puri",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14017,
        Pin: 431003,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14018,
        Pin: 583125,
        DispatchCenter: "Harpanahalli_KHBClny_D (Karnataka)",
        OriginCenter: "Harpanahalli_KHBClny_D (Karnataka)",
        FacilityCity: "Harpanahalli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14019,
        Pin: 685614,
        DispatchCenter: "Kattappana_EratayarRoad_D (Kerala)",
        OriginCenter: "Kattappana_EratayarRoad_D (Kerala)",
        FacilityCity: "Kattappana",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14020,
        Pin: 370430,
        DispatchCenter: "Bhuj_Madhapar_D (Gujarat)",
        OriginCenter: "Bhuj_Madhapar_D (Gujarat)",
        FacilityCity: "Bhuj",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14021,
        Pin: 638104,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14022,
        Pin: 431537,
        DispatchCenter: "Parbhani_Gajanannagar_D (Maharashtra)",
        OriginCenter: "Parbhani_Gajanannagar_D (Maharashtra)",
        FacilityCity: "Parbhani",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14023,
        Pin: 700074,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14024,
        Pin: 333707,
        DispatchCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        OriginCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        FacilityCity: "Jhunjhunu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14025,
        Pin: 244227,
        DispatchCenter: "Kanth_ModaPatti_DPP (Uttar Pradesh)",
        OriginCenter: "Kanth_ModaPatti_DPP (Uttar Pradesh)",
        FacilityCity: "Kanth",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14026,
        Pin: 523230,
        DispatchCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        OriginCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        FacilityCity: "Kanigiri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14027,
        Pin: 632515,
        DispatchCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ranipet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14028,
        Pin: 843156,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14029,
        Pin: 263132,
        DispatchCenter: "Bhimtal_BhowaliRd_D (Uttarakhand)",
        OriginCenter: "Bhimtal_BhowaliRd_D (Uttarakhand)",
        FacilityCity: "Bhimtal",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14030,
        Pin: 272161,
        DispatchCenter: "Itava_Prsnga_D (Uttar Pradesh)",
        OriginCenter: "Itava_Prsnga_D (Uttar Pradesh)",
        FacilityCity: "Itava",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14031,
        Pin: 584124,
        DispatchCenter: "Lingasugur_Mudgal_D (Karnataka)",
        OriginCenter: "Lingasugur_Mudgal_D (Karnataka)",
        FacilityCity: "Lingasugur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14032,
        Pin: 242407,
        DispatchCenter: "Shahjahanpur_AvasVikas_D (Uttar Pradesh)",
        OriginCenter: "Shahjahanpur_AvasVikas_D (Uttar Pradesh)",
        FacilityCity: "Shahjahanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14033,
        Pin: 470663,
        DispatchCenter: "Damoh_Chouprakhurd_D (Madhya Pradesh)",
        OriginCenter: "Damoh_Chouprakhurd_D (Madhya Pradesh)",
        FacilityCity: "Damoh",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 14034,
        Pin: 816129,
        DispatchCenter: "Rajmahal_Bamangawan_D (Jharkhand)",
        OriginCenter: "Rajmahal_Bamangawan_D (Jharkhand)",
        FacilityCity: "Rajmahal",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14035,
        Pin: 686521,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14036,
        Pin: 621215,
        DispatchCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        OriginCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        FacilityCity: "Kulithalai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14037,
        Pin: 585318,
        DispatchCenter: "Sedam_Venkateshnagar_D (Karnataka)",
        OriginCenter: "Sedam_Venkateshnagar_D (Karnataka)",
        FacilityCity: "Sedam",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14038,
        Pin: 312901,
        DispatchCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        OriginCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        FacilityCity: "Chittaurgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14039,
        Pin: 203135,
        DispatchCenter: "Jewar_SJRoad_D (Uttar Pradesh)",
        OriginCenter: "Jewar_SJRoad_D (Uttar Pradesh)",
        FacilityCity: "Jewar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14040,
        Pin: 574112,
        DispatchCenter: "Mudarangady_Santhoor_DPP (Karnataka)",
        OriginCenter: "Mudarangady_Santhoor_DPP (Karnataka)",
        FacilityCity: "Mudarangady",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14041,
        Pin: 609606,
        DispatchCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        OriginCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        FacilityCity: "Karaikal",
        FacilityState: "Pondicherry",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14042,
        Pin: 700149,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14043,
        Pin: 476339,
        DispatchCenter: "Sheopur_Salapura_DPP (Madhya Pradesh)",
        OriginCenter: "Sheopur_Salapura_DPP (Madhya Pradesh)",
        FacilityCity: "Sheopur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 14044,
        Pin: 110025,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14045,
        Pin: 383350,
        DispatchCenter: "Modasa_Shyamnagar_D (Gujarat)",
        OriginCenter: "Modasa_Shyamnagar_D (Gujarat)",
        FacilityCity: "Modasa",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14046,
        Pin: 571252,
        DispatchCenter: "Madikeri_Napoklu1_DPP (Karnataka)",
        OriginCenter: "Madikeri_Napoklu1_DPP (Karnataka)",
        FacilityCity: "Madikeri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14047,
        Pin: 765029,
        DispatchCenter: "Gunupur_Antarjholi_DPP (Orissa)",
        OriginCenter: "Gunupur_Antarjholi_DPP (Orissa)",
        FacilityCity: "Gunupur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14048,
        Pin: 844102,
        DispatchCenter: "Patna_Chhotipahari_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14049,
        Pin: 441611,
        DispatchCenter: "Gondia_Mundipar_I (Maharashtra)",
        OriginCenter: "Gondia_Mundipar_I (Maharashtra)",
        FacilityCity: "Gondia",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14050,
        Pin: 518674,
        DispatchCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        OriginCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        FacilityCity: "Nandyal",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14051,
        Pin: 627805,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14052,
        Pin: 700162,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14053,
        Pin: 678503,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14054,
        Pin: 516151,
        DispatchCenter: "Rajampet_BypassRd_D (Andhra Pradesh)",
        OriginCenter: "Rajampet_BypassRd_D (Andhra Pradesh)",
        FacilityCity: "Rajampet",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14055,
        Pin: 788724,
        DispatchCenter: "Patharkandi_CntrlSchl_D (Assam)",
        OriginCenter: "Patharkandi_CntrlSchl_D (Assam)",
        FacilityCity: "Patharkandi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14056,
        Pin: 721644,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14057,
        Pin: 246768,
        DispatchCenter: "Najibabad_Pathanpura_D (Uttar Pradesh)",
        OriginCenter: "Najibabad_Pathanpura_D (Uttar Pradesh)",
        FacilityCity: "Najibabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14058,
        Pin: 341317,
        DispatchCenter: "Didwana_Ratau_DPP (Rajasthan)",
        OriginCenter: "Didwana_Ratau_DPP (Rajasthan)",
        FacilityCity: "Nagaur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14059,
        Pin: 814131,
        DispatchCenter: "Banka_Kathoun_D (Bihar)",
        OriginCenter: "Banka_Kathoun_D (Bihar)",
        FacilityCity: "Banka",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14060,
        Pin: 360579,
        DispatchCenter: "Porbandar_Vadiplot_D (Gujarat)",
        OriginCenter: "Porbandar_Vadiplot_D (Gujarat)",
        FacilityCity: "Porbandar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14061,
        Pin: 415122,
        DispatchCenter: "Karad_Malkapur1_D (Maharashtra)",
        OriginCenter: "Karad_Malkapur1_D (Maharashtra)",
        FacilityCity: "Karad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14062,
        Pin: 388350,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14063,
        Pin: 829102,
        DispatchCenter: "Ramgarh_Marar_D (Jharkhand)",
        OriginCenter: "Ramgarh_Marar_D (Jharkhand)",
        FacilityCity: "Ramgarh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14064,
        Pin: 581197,
        DispatchCenter: "Hangal_PalaRd_D (Karnataka)",
        OriginCenter: "Hangal_PalaRd_D (Karnataka)",
        FacilityCity: "Hangal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14065,
        Pin: 600091,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14066,
        Pin: 690518,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14067,
        Pin: 700127,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14068,
        Pin: 507123,
        DispatchCenter: "Yellandu_Sudimala_D (Telangana)",
        OriginCenter: "Yellandu_Sudimala_D (Telangana)",
        FacilityCity: "Yellandu",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14069,
        Pin: 620015,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14070,
        Pin: 392165,
        DispatchCenter: "Dahej_Vadadla_D (Gujarat)",
        OriginCenter: "Dahej_Vadadla_D (Gujarat)",
        FacilityCity: "Dahej",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14071,
        Pin: 638002,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14072,
        Pin: 261206,
        DispatchCenter: "Biswan_PatelNGR_D (Uttar Pradesh)",
        OriginCenter: "Biswan_PatelNGR_D (Uttar Pradesh)",
        FacilityCity: "Biswan",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14073,
        Pin: 758079,
        DispatchCenter: "Ghatgaon_Harichandanpur_D (Orissa)",
        OriginCenter: "Ghatgaon_Harichandanpur_D (Orissa)",
        FacilityCity: "Ghatgaon",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14074,
        Pin: 700025,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14075,
        Pin: 491222,
        DispatchCenter: "Balod_HaldharChowk_D (Chhattisgarh)",
        OriginCenter: "Balod_HaldharChowk_D (Chhattisgarh)",
        FacilityCity: "Balod",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14076,
        Pin: 365410,
        DispatchCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        OriginCenter: "Amreli_Derdikumbhaji_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14077,
        Pin: 382213,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14078,
        Pin: 622402,
        DispatchCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14079,
        Pin: 799275,
        DispatchCenter: "Aambasa_Manu_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Aambasa",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14080,
        Pin: 745010,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14081,
        Pin: 174305,
        DispatchCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        OriginCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        FacilityCity: "Bangana",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14082,
        Pin: 443103,
        DispatchCenter: "Malkapur_DwarkaHosp_DPP (Maharashtra)",
        OriginCenter: "Malkapur_DwarkaHosp_DPP (Maharashtra)",
        FacilityCity: "Malkapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14083,
        Pin: 688527,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14084,
        Pin: 767041,
        DispatchCenter: "Patnagarh_Beherabandh_D (Orissa)",
        OriginCenter: "Patnagarh_Beherabandh_D (Orissa)",
        FacilityCity: "Patnagarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14085,
        Pin: 424104,
        DispatchCenter: "Pachora_Kajgaon_DPP (Maharashtra)",
        OriginCenter: "Pachora_Kajgaon_DPP (Maharashtra)",
        FacilityCity: "Chalisgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14086,
        Pin: 781354,
        DispatchCenter: "Baihata_Sundhia_D (Assam)",
        OriginCenter: "Baihata_Sundhia_D (Assam)",
        FacilityCity: "Baihata",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14087,
        Pin: 786186,
        DispatchCenter: "Digboi_Itabhata_DPP (Assam)",
        OriginCenter: "Digboi_Itabhata_DPP (Assam)",
        FacilityCity: "Digboi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14088,
        Pin: 171006,
        DispatchCenter: "Shimla_Jawaharcolony_D (Himachal Pradesh)",
        OriginCenter: "Shimla_Jawaharcolony_D (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14089,
        Pin: 276303,
        DispatchCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14090,
        Pin: 793200,
        DispatchCenter: "Khliehriat_MrktDPP_D (Meghalaya)",
        OriginCenter: "Khliehriat_MrktDPP_D (Meghalaya)",
        FacilityCity: "Khliehriat",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14091,
        Pin: 721427,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14092,
        Pin: 370645,
        DispatchCenter: "Naliya_Jlramcpx_D (Gujarat)",
        OriginCenter: "Naliya_Jlramcpx_D (Gujarat)",
        FacilityCity: "Naliya",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14093,
        Pin: 502271,
        DispatchCenter: "Narayankhed_Turkapally_D (Telangana)",
        OriginCenter: "Narayankhed_Turkapally_D (Telangana)",
        FacilityCity: "Narayankhed",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14094,
        Pin: 442111,
        DispatchCenter: "Wardha_Alodi_D (Maharashtra)",
        OriginCenter: "Wardha_Alodi_D (Maharashtra)",
        FacilityCity: "Wardha",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14095,
        Pin: 757024,
        DispatchCenter: "Baripada_KalikDPP_D (Orissa)",
        OriginCenter: "Baripada_KalikDPP_D (Orissa)",
        FacilityCity: "Baripada",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14096,
        Pin: 515405,
        DispatchCenter: "Gooty_ATPRoad_D (Andhra Pradesh)",
        OriginCenter: "Gooty_ATPRoad_D (Andhra Pradesh)",
        FacilityCity: "Gooty",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14097,
        Pin: 788013,
        DispatchCenter: "Sonabarighat_Saidpur_DPP (Assam)",
        OriginCenter: "Sonabarighat_Saidpur_DPP (Assam)",
        FacilityCity: "Sonabarighat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14098,
        Pin: 122103,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14099,
        Pin: 445322,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14100,
        Pin: 516128,
        DispatchCenter: "Rajampet_BypassRd_D (Andhra Pradesh)",
        OriginCenter: "Rajampet_BypassRd_D (Andhra Pradesh)",
        FacilityCity: "Rajampet",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14101,
        Pin: 221301,
        DispatchCenter: "Bhadohi_Chedibeer_D (Uttar Pradesh)",
        OriginCenter: "Bhadohi_Chedibeer_D (Uttar Pradesh)",
        FacilityCity: "Bhadohi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14102,
        Pin: 623712,
        DispatchCenter: "Muthukulathur_Selvanygprm_D (Tamil Nadu)",
        OriginCenter: "Muthukulathur_Selvanygprm_D (Tamil Nadu)",
        FacilityCity: "Muthukulathur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14103,
        Pin: 695141,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14104,
        Pin: 424209,
        DispatchCenter: "Jamner_BusStd_D (Maharashtra)",
        OriginCenter: "Jamner_BusStd_D (Maharashtra)",
        FacilityCity: "Jamner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14105,
        Pin: 845413,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14106,
        Pin: 530045,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14107,
        Pin: 284404,
        DispatchCenter: "Mahrauni_Kuwanghosi_D (Uttar Pradesh)",
        OriginCenter: "Mahrauni_Kuwanghosi_D (Uttar Pradesh)",
        FacilityCity: "Mahrauni",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14108,
        Pin: 623109,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14109,
        Pin: 172030,
        DispatchCenter: "Kumarsain_Bharara_D (Himachal Pradesh)",
        OriginCenter: "Kumarsain_Bharara_D (Himachal Pradesh)",
        FacilityCity: "Kumarsain",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14110,
        Pin: 586114,
        DispatchCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        OriginCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        FacilityCity: "Bijapur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14111,
        Pin: 690530,
        DispatchCenter: "Kayamkulam_Thamarakulam_D (Kerala)",
        OriginCenter: "Kayamkulam_Thamarakulam_D (Kerala)",
        FacilityCity: "Kayamkulam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14112,
        Pin: 143407,
        DispatchCenter: "Goindwal_Fatehabad_D (Punjab)",
        OriginCenter: "Goindwal_Fatehabad_D (Punjab)",
        FacilityCity: "Goindwal",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14113,
        Pin: 205264,
        DispatchCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        OriginCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        FacilityCity: "Mainpuri",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14114,
        Pin: 795140,
        DispatchCenter: "Imphal_Takyel_I (Manipur)",
        OriginCenter: "Imphal_Takyel_I (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14115,
        Pin: 248141,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14116,
        Pin: 441910,
        DispatchCenter: "Pauni_Wahi_D (Maharashtra)",
        OriginCenter: "Pauni_Wahi_D (Maharashtra)",
        FacilityCity: "Pauni",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14117,
        Pin: 402102,
        DispatchCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        OriginCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        FacilityCity: "Mangaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14118,
        Pin: 691002,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14119,
        Pin: 142041,
        DispatchCenter: "Moga_Duneke_I (Punjab)",
        OriginCenter: "Moga_Duneke_I (Punjab)",
        FacilityCity: "Moga",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14120,
        Pin: 413201,
        DispatchCenter: "Jamkhed_DaulatCmplex_DPP (Maharashtra)",
        OriginCenter: "Jamkhed_DaulatCmplex_DPP (Maharashtra)",
        FacilityCity: "Jamkhed",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14121,
        Pin: 416803,
        DispatchCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        OriginCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        FacilityCity: "Devgad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14122,
        Pin: 518186,
        DispatchCenter: "Betamcherla_Bngnpalle_D (Andhra Pradesh)",
        OriginCenter: "Betamcherla_Bngnpalle_D (Andhra Pradesh)",
        FacilityCity: "Betamcherla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14123,
        Pin: 682507,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14124,
        Pin: 144004,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14125,
        Pin: 575006,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14126,
        Pin: 586117,
        DispatchCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        OriginCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        FacilityCity: "Indi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14127,
        Pin: 121006,
        DispatchCenter: "Faridabad_Sector24_L (Haryana)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14128,
        Pin: 835222,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14129,
        Pin: 246446,
        DispatchCenter: "Chamoli_Karnaprayag_D (Uttarakhand)",
        OriginCenter: "Chamoli_Karnaprayag_D (Uttarakhand)",
        FacilityCity: "Chamoli",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14130,
        Pin: 384260,
        DispatchCenter: "Patan_Hansapur_D (Gujarat)",
        OriginCenter: "Patan_Hansapur_D (Gujarat)",
        FacilityCity: "Patan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14131,
        Pin: 460440,
        DispatchCenter: "Sarni_Ward36_D (Madhya Pradesh)",
        OriginCenter: "Sarni_Ward36_D (Madhya Pradesh)",
        FacilityCity: "Sarni",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 14132,
        Pin: 443101,
        DispatchCenter: "Malkapur_DwarkaHosp_DPP (Maharashtra)",
        OriginCenter: "Malkapur_DwarkaHosp_DPP (Maharashtra)",
        FacilityCity: "Malkapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14133,
        Pin: 723130,
        DispatchCenter: "Hura_Lalpur_DPP (West Bengal)",
        OriginCenter: "Hura_Lalpur_DPP (West Bengal)",
        FacilityCity: "Hura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14134,
        Pin: 416805,
        DispatchCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        OriginCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        FacilityCity: "Devgad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14135,
        Pin: 622409,
        DispatchCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14136,
        Pin: 262907,
        DispatchCenter: "Nighasan_Laudhri_D (Uttar Pradesh)",
        OriginCenter: "Nighasan_Laudhri_D (Uttar Pradesh)",
        FacilityCity: "Nighasan",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14137,
        Pin: 402309,
        DispatchCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        OriginCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        FacilityCity: "Mahad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14138,
        Pin: 141021,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14139,
        Pin: 182121,
        DispatchCenter: "Udhampur_BharatNagar_D (Jammu & Kashmir)",
        OriginCenter: "Udhampur_BharatNagar_D (Jammu & Kashmir)",
        FacilityCity: "Udhampur",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14140,
        Pin: 794112,
        DispatchCenter: "Resubelpara_Belpara_D (Meghalaya)",
        OriginCenter: "Resubelpara_Belpara_D (Meghalaya)",
        FacilityCity: "Resubelpara",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14141,
        Pin: 583118,
        DispatchCenter: "Sandur_Smiorecolony_DPP (Karnataka)",
        OriginCenter: "Sandur_Smiorecolony_DPP (Karnataka)",
        FacilityCity: "Sandur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14142,
        Pin: 230146,
        DispatchCenter: "Soraon_Purabtola_D (Uttar Pradesh)",
        OriginCenter: "Soraon_Purabtola_D (Uttar Pradesh)",
        FacilityCity: "Soraon",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14143,
        Pin: 591238,
        DispatchCenter: "Nipani_Sakharwadi_D (Karnataka)",
        OriginCenter: "Nipani_Sakharwadi_D (Karnataka)",
        FacilityCity: "Nipani",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14144,
        Pin: 475336,
        DispatchCenter: "Datia_Indergarh_DPP (Madhya Pradesh)",
        OriginCenter: "Datia_Indergarh_DPP (Madhya Pradesh)",
        FacilityCity: "Datia",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 14145,
        Pin: 791001,
        DispatchCenter: "Aalo_Puakgumin_D (Arunachal Pradesh)",
        OriginCenter: "Aalo_Puakgumin_D (Arunachal Pradesh)",
        FacilityCity: "Aalo",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14146,
        Pin: 711203,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14147,
        Pin: 621107,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14148,
        Pin: 123506,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14149,
        Pin: 516175,
        DispatchCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        OriginCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        FacilityCity: "Proddatur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14150,
        Pin: 143516,
        DispatchCenter: "Batala_Simbel_D (Punjab)",
        OriginCenter: "Batala_Simbel_D (Punjab)",
        FacilityCity: "Batala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14151,
        Pin: 442403,
        DispatchCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        OriginCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        FacilityCity: "Chandrapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14152,
        Pin: 605007,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14153,
        Pin: 585328,
        DispatchCenter: "Bhalki_ShivajiChwk_DPP (Karnataka)",
        OriginCenter: "Bhalki_ShivajiChwk_DPP (Karnataka)",
        FacilityCity: "Bhalki",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14154,
        Pin: 382324,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14155,
        Pin: 580010,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14156,
        Pin: 282003,
        DispatchCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        OriginCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        FacilityCity: "Agra",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14157,
        Pin: 212507,
        DispatchCenter: "Soraon_Purabtola_D (Uttar Pradesh)",
        OriginCenter: "Soraon_Purabtola_D (Uttar Pradesh)",
        FacilityCity: "Soraon",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14158,
        Pin: 273005,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14159,
        Pin: 431118,
        DispatchCenter: "Soegaon_Shivajinagar_DPP (Maharashtra)",
        OriginCenter: "Soegaon_Shivajinagar_DPP (Maharashtra)",
        FacilityCity: "Soegaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14160,
        Pin: 785634,
        DispatchCenter: "Jorhat_Titabor_D (Assam)",
        OriginCenter: "Jorhat_Titabor_D (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14161,
        Pin: 741202,
        DispatchCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        OriginCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        FacilityCity: "Ranaghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14162,
        Pin: 764088,
        DispatchCenter: "Raighar_PtrkutiChwk_DPP (Orissa)",
        OriginCenter: "Raighar_PtrkutiChwk_DPP (Orissa)",
        FacilityCity: "Raighar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14163,
        Pin: 181206,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14164,
        Pin: 575015,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14165,
        Pin: 713502,
        DispatchCenter: "Katwa_Panchghara_D (West Bengal)",
        OriginCenter: "Katwa_Panchghara_D (West Bengal)",
        FacilityCity: "Katwa",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14166,
        Pin: 505462,
        DispatchCenter: "Koratla_PRBM_DPP (Telangana)",
        OriginCenter: "Koratla_PRBM_DPP (Telangana)",
        FacilityCity: "Koratla",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14167,
        Pin: 614710,
        DispatchCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        FacilityCity: "Thiruthuraipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14168,
        Pin: 683109,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14169,
        Pin: 502303,
        DispatchCenter: "Medak_RTCclny_D (Telangana)",
        OriginCenter: "Medak_RTCclny_D (Telangana)",
        FacilityCity: "Medak",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14170,
        Pin: 794104,
        DispatchCenter: "Phulbari_Bhaitbariold_DPP (Meghalaya)",
        OriginCenter: "Phulbari_Bhaitbariold_DPP (Meghalaya)",
        FacilityCity: "Phulbari",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14171,
        Pin: 135105,
        DispatchCenter: "Yamunanagar_Balachaur_D (Haryana)",
        OriginCenter: "Yamunanagar_Balachaur_D (Haryana)",
        FacilityCity: "Yamuna Nagar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14172,
        Pin: 782439,
        DispatchCenter: "Howraghat_Samaguri_D (Assam)",
        OriginCenter: "Howraghat_Samaguri_D (Assam)",
        FacilityCity: "Howraghat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14173,
        Pin: 504204,
        DispatchCenter: "Chinnur_AsnsdhRD_D (Telangana)",
        OriginCenter: "Chinnur_AsnsdhRD_D (Telangana)",
        FacilityCity: "Chinnur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14174,
        Pin: 768232,
        DispatchCenter: "Jharsuguda_Ekatali_D (Orissa)",
        OriginCenter: "Jharsuguda_Ekatali_D (Orissa)",
        FacilityCity: "Jharsuguda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14175,
        Pin: 623006,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14176,
        Pin: 484886,
        DispatchCenter: "Anuppur_Kirgi_D (Madhya Pradesh)",
        OriginCenter: "Anuppur_Kirgi_D (Madhya Pradesh)",
        FacilityCity: "Anuppur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 14177,
        Pin: 474006,
        DispatchCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        OriginCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        FacilityCity: "Gwalior",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 14178,
        Pin: 393010,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14179,
        Pin: 627353,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14180,
        Pin: 283152,
        DispatchCenter: "Etah_Dineshnagar_D (Uttar Pradesh)",
        OriginCenter: "Etah_Dineshnagar_D (Uttar Pradesh)",
        FacilityCity: "Etah",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14181,
        Pin: 835234,
        DispatchCenter: "Khunti_Rehargara_DPP (Jharkhand)",
        OriginCenter: "Khunti_Rehargara_DPP (Jharkhand)",
        FacilityCity: "Khunti",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14182,
        Pin: 752069,
        DispatchCenter: "Nayagarh_Durgprsd_D (Orissa)",
        OriginCenter: "Nayagarh_Durgprsd_D (Orissa)",
        FacilityCity: "Nayagarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14183,
        Pin: 762112,
        DispatchCenter: "GUdayagiri_ClubSahi_DPP (Orissa)",
        OriginCenter: "GUdayagiri_ClubSahi_DPP (Orissa)",
        FacilityCity: "G. Udayagiri",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14184,
        Pin: 854331,
        DispatchCenter: "Forbesganj_Kursakanta_D (Bihar)",
        OriginCenter: "Forbesganj_Kursakanta_D (Bihar)",
        FacilityCity: "Forbesganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14185,
        Pin: 271006,
        DispatchCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        OriginCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        FacilityCity: "Gonda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14186,
        Pin: 387640,
        DispatchCenter: "Kapadvanj_Dholikui_D (Gujarat)",
        OriginCenter: "Kapadvanj_Dholikui_D (Gujarat)",
        FacilityCity: "Kapadvanj",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14187,
        Pin: 413306,
        DispatchCenter: "Atapadi_VidyaNagar_D (Maharashtra)",
        OriginCenter: "Atapadi_VidyaNagar_D (Maharashtra)",
        FacilityCity: "Atapadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14188,
        Pin: 721442,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14189,
        Pin: 848303,
        DispatchCenter: "Samastipur_RhmprRdli_D (Bihar)",
        OriginCenter: "Samastipur_RhmprRdli_D (Bihar)",
        FacilityCity: "Samastipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14190,
        Pin: 576215,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14191,
        Pin: 799120,
        DispatchCenter: "Udaipur_Chanban_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Udaipur-TR",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14192,
        Pin: 627810,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14193,
        Pin: 803115,
        DispatchCenter: "Pavapuri_Jalmandirrd_D (Bihar)",
        OriginCenter: "Pavapuri_Jalmandirrd_D (Bihar)",
        FacilityCity: "Pavapuri",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14194,
        Pin: 678705,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14195,
        Pin: 384140,
        DispatchCenter: "Unjha_SatymComplx_D (Gujarat)",
        OriginCenter: "Unjha_SatymComplx_D (Gujarat)",
        FacilityCity: "Unjha",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14196,
        Pin: 686561,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14197,
        Pin: 302019,
        DispatchCenter: "Jaipur_RingRoad_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14198,
        Pin: 249175,
        DispatchCenter: "Rishikesh_Kumarkheda_DPP (Uttarakhand)",
        OriginCenter: "Rishikesh_Kumarkheda_DPP (Uttarakhand)",
        FacilityCity: "Rishikesh",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14199,
        Pin: 603003,
        DispatchCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        OriginCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        FacilityCity: "Chengalpattu",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14200,
        Pin: 573137,
        DispatchCenter: "Shanivarasanthe_Bank_D (Karnataka)",
        OriginCenter: "Shanivarasanthe_Bank_D (Karnataka)",
        FacilityCity: "Shanivarasanthe",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14201,
        Pin: 327801,
        DispatchCenter: "Kushalgarh_Tandamangala_D (Rajasthan)",
        OriginCenter: "Kushalgarh_Tandamangala_D (Rajasthan)",
        FacilityCity: "Kushalgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14202,
        Pin: 561205,
        DispatchCenter: "Doddaballapura_Kuntanahalli_D (Karnataka)",
        OriginCenter: "Doddaballapura_Kuntanahalli_D (Karnataka)",
        FacilityCity: "Doddaballapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14203,
        Pin: 122224,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14204,
        Pin: 342019,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14205,
        Pin: 577529,
        DispatchCenter: "Molakalmuru_KnsgraRD_D (Karnataka)",
        OriginCenter: "Molakalmuru_KnsgraRD_D (Karnataka)",
        FacilityCity: "Molakalmuru",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14206,
        Pin: 761032,
        DispatchCenter: "Kodala_Beguniapada_DPP (Orissa)",
        OriginCenter: "Kodala_Beguniapada_DPP (Orissa)",
        FacilityCity: "Kodala",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14207,
        Pin: 385521,
        DispatchCenter: "Palanpur_Esbipura1_D (Gujarat)",
        OriginCenter: "Palanpur_Esbipura1_D (Gujarat)",
        FacilityCity: "Palanpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14208,
        Pin: 625517,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14209,
        Pin: 262533,
        DispatchCenter: "Berinag_Dhanoli_DPP (Uttarakhand)",
        OriginCenter: "Berinag_Dhanoli_DPP (Uttarakhand)",
        FacilityCity: "Berinag",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14210,
        Pin: 518018,
        DispatchCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        OriginCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14211,
        Pin: 416402,
        DispatchCenter: "Jath_Rajaramnagar_D (Maharashtra)",
        OriginCenter: "Jath_Rajaramnagar_D (Maharashtra)",
        FacilityCity: "Jath",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14212,
        Pin: 635851,
        DispatchCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        OriginCenter: "Tiruppattur_KrishagiriRd_D (Tamil Nadu)",
        FacilityCity: "Tiruppattur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14213,
        Pin: 581110,
        DispatchCenter: "Haveri_ManjunathNagar_D (Karnataka)",
        OriginCenter: "Haveri_ManjunathNagar_D (Karnataka)",
        FacilityCity: "Haveri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14214,
        Pin: 136021,
        DispatchCenter: "Kaithal_DefenceColony_D (Haryana)",
        OriginCenter: "Kaithal_DefenceColony_D (Haryana)",
        FacilityCity: "Kaithal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14215,
        Pin: 523003,
        DispatchCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        OriginCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        FacilityCity: "Kakinada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14216,
        Pin: 414301,
        DispatchCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        OriginCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        FacilityCity: "Ahmednagar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14217,
        Pin: 228118,
        DispatchCenter: "Sultanpur_Balpur_D (Uttar Pradesh)",
        OriginCenter: "Sultanpur_Balpur_D (Uttar Pradesh)",
        FacilityCity: "Sultanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14218,
        Pin: 533211,
        DispatchCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        OriginCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        FacilityCity: "Amalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14219,
        Pin: 635118,
        DispatchCenter: "Denkanikottai_AnchettyRd_D (Tamil Nadu)",
        OriginCenter: "Denkanikottai_AnchettyRd_D (Tamil Nadu)",
        FacilityCity: "Denkanikottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14220,
        Pin: 844506,
        DispatchCenter: "ManharBazar_NayaGaon_D (Bihar)",
        OriginCenter: "ManharBazar_NayaGaon_D (Bihar)",
        FacilityCity: "Mahnar Bazar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14221,
        Pin: 126115,
        DispatchCenter: "Jind_SunderNagar_D (Haryana)",
        OriginCenter: "Jind_SunderNagar_D (Haryana)",
        FacilityCity: "Jind",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14222,
        Pin: 793004,
        DispatchCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        OriginCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14223,
        Pin: 518502,
        DispatchCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        OriginCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        FacilityCity: "Nandyal",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14224,
        Pin: 679348,
        DispatchCenter: "Edavanna_MNRd_D (Kerala)",
        OriginCenter: "Edavanna_MNRd_D (Kerala)",
        FacilityCity: "Edavanna",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14225,
        Pin: 174315,
        DispatchCenter: "UnaHP_NangalRd_D (Himachal Pradesh)",
        OriginCenter: "UnaHP_NangalRd_D (Himachal Pradesh)",
        FacilityCity: "Una-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14226,
        Pin: 713323,
        DispatchCenter: "Meija_School_D (West Bengal)",
        OriginCenter: "Meija_School_D (West Bengal)",
        FacilityCity: "Mejia",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14227,
        Pin: 686620,
        DispatchCenter: "Palani_Sathyanagar_D (Tamil Nadu)",
        OriginCenter: "Palani_Sathyanagar_D (Tamil Nadu)",
        FacilityCity: "Palani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14228,
        Pin: 782140,
        DispatchCenter: "Puranaigudam_Gomotha_DPP (Assam)",
        OriginCenter: "Puranaigudam_Gomotha_DPP (Assam)",
        FacilityCity: "Puranaigudam",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14229,
        Pin: 494670,
        DispatchCenter: "Bhanupratappur_Kanhargaon_D (Chhattisgarh)",
        OriginCenter: "Bhanupratappur_Kanhargaon_D (Chhattisgarh)",
        FacilityCity: "Bhanupratappur",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14230,
        Pin: 361008,
        DispatchCenter: "Jamnagar_Chela_L (Gujarat)",
        OriginCenter: "Jamnagar_Chela_L (Gujarat)",
        FacilityCity: "Jamnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14231,
        Pin: 414106,
        DispatchCenter: "Pathardi_Shivshaktinagar_D (Maharashtra)",
        OriginCenter: "Pathardi_Shivshaktinagar_D (Maharashtra)",
        FacilityCity: "Pathardi",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14232,
        Pin: 713142,
        DispatchCenter: "Arambag_Bulchandrapur_DPP (West Bengal)",
        OriginCenter: "Arambag_Bulchandrapur_DPP (West Bengal)",
        FacilityCity: "Arambag",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14233,
        Pin: 403515,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14234,
        Pin: 171301,
        DispatchCenter: "Suni_Basantpur_DPP (Himachal Pradesh)",
        OriginCenter: "Suni_Basantpur_DPP (Himachal Pradesh)",
        FacilityCity: "Suni",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14235,
        Pin: 502210,
        DispatchCenter: "Mominpet_Marpallekalan_D (Telangana)",
        OriginCenter: "Mominpet_Marpallekalan_D (Telangana)",
        FacilityCity: "Mominpet",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14236,
        Pin: 757077,
        DispatchCenter: "Udala_Mayurbhanj_DPP (Orissa)",
        OriginCenter: "Udala_Mayurbhanj_DPP (Orissa)",
        FacilityCity: "Udala",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14237,
        Pin: 574232,
        DispatchCenter: "Panja_SubrmnyRd_DPP (Karnataka)",
        OriginCenter: "Panja_SubrmnyRd_DPP (Karnataka)",
        FacilityCity: "Panja",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14238,
        Pin: 607307,
        DispatchCenter: "Cuddalore_FriendsNgr_D (Tamil Nadu)",
        OriginCenter: "Cuddalore_FriendsNgr_D (Tamil Nadu)",
        FacilityCity: "Cuddalore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14239,
        Pin: 577526,
        DispatchCenter: "Channagiri_Holalkere_D (Karnataka)",
        OriginCenter: "Channagiri_Holalkere_D (Karnataka)",
        FacilityCity: "Channagiri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14240,
        Pin: 143104,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14241,
        Pin: 630202,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14242,
        Pin: 632504,
        DispatchCenter: "Arani_PolurRd_D (Tamil Nadu)",
        OriginCenter: "Arani_PolurRd_D (Tamil Nadu)",
        FacilityCity: "Arani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14243,
        Pin: 805131,
        DispatchCenter: "Wazirganj_Usawa_D (Bihar)",
        OriginCenter: "Wazirganj_Usawa_D (Bihar)",
        FacilityCity: "Wazirganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14244,
        Pin: 370170,
        DispatchCenter: "Botad_Paliyadrd_D (Gujarat)",
        OriginCenter: "Botad_Paliyadrd_D (Gujarat)",
        FacilityCity: "Botad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14245,
        Pin: 695036,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14246,
        Pin: 322243,
        DispatchCenter: "Karauli_Subhashnagar_D (Rajasthan)",
        OriginCenter: "Karauli_Subhashnagar_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14247,
        Pin: 141115,
        DispatchCenter: "Machhiwara_MnBzrDPP_D (Punjab)",
        OriginCenter: "Machhiwara_MnBzrDPP_D (Punjab)",
        FacilityCity: "Machhiwara",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14248,
        Pin: 191201,
        DispatchCenter: "Srinagar_Nagbal_D (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Nagbal_D (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14249,
        Pin: 743706,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14250,
        Pin: 754138,
        DispatchCenter: "Tirtol_Central_D_2 (Orissa)",
        OriginCenter: "Tirtol_Central_D_2 (Orissa)",
        FacilityCity: "Tirtol",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14251,
        Pin: 442505,
        DispatchCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        OriginCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        FacilityCity: "Chandrapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14252,
        Pin: 767068,
        DispatchCenter: "Loisingha_Omshanticolony_D (Orissa)",
        OriginCenter: "Loisingha_Omshanticolony_D (Orissa)",
        FacilityCity: "Loisingha",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14253,
        Pin: 784125,
        DispatchCenter: "Mangaldoi_Anandanagar_D (Assam)",
        OriginCenter: "Mangaldoi_Anandanagar_D (Assam)",
        FacilityCity: "Mangaldoi",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14254,
        Pin: 141122,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14255,
        Pin: 713434,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14256,
        Pin: 202125,
        DispatchCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        OriginCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14257,
        Pin: 226028,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14258,
        Pin: 629205,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14259,
        Pin: 360030,
        DispatchCenter: "Gondal_Maninagar_D (Gujarat)",
        OriginCenter: "Gondal_Maninagar_D (Gujarat)",
        FacilityCity: "Gondal",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14260,
        Pin: 444001,
        DispatchCenter: "Akola_Midcphase3_H (Maharashtra)",
        OriginCenter: "Akola_Midcphase3_H (Maharashtra)",
        FacilityCity: "Akola",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14261,
        Pin: 204215,
        DispatchCenter: "SikandraRao_RadhaNagar_D (Uttar Pradesh)",
        OriginCenter: "SikandraRao_RadhaNagar_D (Uttar Pradesh)",
        FacilityCity: "Sikandra Rao",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14262,
        Pin: 571419,
        DispatchCenter: "Maddur_Malavalli_D (Karnataka)",
        OriginCenter: "Maddur_Malavalli_D (Karnataka)",
        FacilityCity: "Maddur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14263,
        Pin: 148048,
        DispatchCenter: "Sangrur_DC (Punjab)",
        OriginCenter: "Sangrur_DC (Punjab)",
        FacilityCity: "Sangrur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14264,
        Pin: 802116,
        DispatchCenter: "Buxar_SohaniPatti_D (Bihar)",
        OriginCenter: "Buxar_SohaniPatti_D (Bihar)",
        FacilityCity: "Buxar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14265,
        Pin: 389180,
        DispatchCenter: "Dahod_Vanzarwada_I (Gujarat)",
        OriginCenter: "Dahod_Vanzarwada_I (Gujarat)",
        FacilityCity: "Dahod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14266,
        Pin: 227101,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14267,
        Pin: 626140,
        DispatchCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        OriginCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        FacilityCity: "Sivakasi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14268,
        Pin: 276121,
        DispatchCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14269,
        Pin: 143507,
        DispatchCenter: "Batala_Simbel_D (Punjab)",
        OriginCenter: "Batala_Simbel_D (Punjab)",
        FacilityCity: "Batala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14270,
        Pin: 326003,
        DispatchCenter: "Aklera_Mangta_D (Rajasthan)",
        OriginCenter: "Aklera_Mangta_D (Rajasthan)",
        FacilityCity: "Aklera",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14271,
        Pin: 670631,
        DispatchCenter: "Chavassery_Malapattam_D (Kerala)",
        OriginCenter: "Chavassery_Malapattam_D (Kerala)",
        FacilityCity: "Chavassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14272,
        Pin: 272208,
        DispatchCenter: "Naugarh_Trtribazar_D (Uttar Pradesh)",
        OriginCenter: "Naugarh_Trtribazar_D (Uttar Pradesh)",
        FacilityCity: "Naugarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14273,
        Pin: 638056,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14274,
        Pin: 691623,
        DispatchCenter: "Kottarakkara_MythriNagar_D (Kerala)",
        OriginCenter: "Kottarakkara_MythriNagar_D (Kerala)",
        FacilityCity: "Kottarakkara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14275,
        Pin: 457888,
        DispatchCenter: "Alirajpur_Sorvanaka_DPP (Madhya Pradesh)",
        OriginCenter: "Alirajpur_Sorvanaka_DPP (Madhya Pradesh)",
        FacilityCity: "Alirajpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 14276,
        Pin: 425104,
        DispatchCenter: "Jalgaon_Omnagar_D (Maharashtra)",
        OriginCenter: "Jalgaon_Omnagar_D (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14277,
        Pin: 795005,
        DispatchCenter: "Imphal_Takyel_I (Manipur)",
        OriginCenter: "Imphal_Takyel_I (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14278,
        Pin: 388410,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14279,
        Pin: 671324,
        DispatchCenter: "Kasaragod_Kudlu_D (Kerala)",
        OriginCenter: "Kasaragod_Kudlu_D (Kerala)",
        FacilityCity: "Kasaragod",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14280,
        Pin: 272126,
        DispatchCenter: "Basti_Pairi_D (Uttar Pradesh)",
        OriginCenter: "Basti_Pairi_D (Uttar Pradesh)",
        FacilityCity: "Basti",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14281,
        Pin: 517172,
        DispatchCenter: "Pileru_Chinnagottigallu_D (Andhra Pradesh)",
        OriginCenter: "Pileru_Chinnagottigallu_D (Andhra Pradesh)",
        FacilityCity: "Tirupati",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14282,
        Pin: 531032,
        DispatchCenter: "Anakapalle_Thumapala_D (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Anakapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14283,
        Pin: 673644,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14284,
        Pin: 395650,
        DispatchCenter: "Songadh_Vyara_D (Gujarat)",
        OriginCenter: "Songadh_Vyara_D (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14285,
        Pin: 624617,
        DispatchCenter: "Palani_Sathyanagar_D (Tamil Nadu)",
        OriginCenter: "Palani_Sathyanagar_D (Tamil Nadu)",
        FacilityCity: "Palani",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14286,
        Pin: 581116,
        DispatchCenter: "Hirekerur_Raghavendracolony_D (Karnataka)",
        OriginCenter: "Hirekerur_Raghavendracolony_D (Karnataka)",
        FacilityCity: "Hirekerur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14287,
        Pin: 454010,
        DispatchCenter: "Dhar_TirupatiNagar_D (Madhya Pradesh)",
        OriginCenter: "Dhar_TirupatiNagar_D (Madhya Pradesh)",
        FacilityCity: "Dhar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 14288,
        Pin: 629402,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14289,
        Pin: 332408,
        DispatchCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        OriginCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14290,
        Pin: 733129,
        DispatchCenter: "Kaliyaganj_Central_DPP_1 (West Bengal)",
        OriginCenter: "Kaliyaganj_Central_DPP_1 (West Bengal)",
        FacilityCity: "Kaliyaganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14291,
        Pin: 505501,
        DispatchCenter: "Choppadandi_Gopalraopeta_D (Telangana)",
        OriginCenter: "Choppadandi_Gopalraopeta_D (Telangana)",
        FacilityCity: "Choppadandi",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14292,
        Pin: 583102,
        DispatchCenter: "Bellary_Mundargi_I (Karnataka)",
        OriginCenter: "Bellary_Mundargi_I (Karnataka)",
        FacilityCity: "Bellary",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14293,
        Pin: 480001,
        DispatchCenter: "Chhindwara_Linga_I (Madhya Pradesh)",
        OriginCenter: "Chhindwara_Linga_I (Madhya Pradesh)",
        FacilityCity: "Chhindwara",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 14294,
        Pin: 583222,
        DispatchCenter: "Hospet_Munirabad_D (Karnataka)",
        OriginCenter: "Hospet_Munirabad_D (Karnataka)",
        FacilityCity: "Hospet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14295,
        Pin: 241127,
        DispatchCenter: "Sandila_SubabDPP_D (Uttar Pradesh)",
        OriginCenter: "Sandila_SubabDPP_D (Uttar Pradesh)",
        FacilityCity: "Sandila",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14296,
        Pin: 174310,
        DispatchCenter: "NainaDevi_Baroh_DPP (Himachal Pradesh)",
        OriginCenter: "NainaDevi_Baroh_DPP (Himachal Pradesh)",
        FacilityCity: "Naina Devi",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14297,
        Pin: 411068,
        DispatchCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        OriginCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14298,
        Pin: 494669,
        DispatchCenter: "Bhanupratappur_Kanhargaon_D (Chhattisgarh)",
        OriginCenter: "Bhanupratappur_Kanhargaon_D (Chhattisgarh)",
        FacilityCity: "Bhanupratappur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14299,
        Pin: 535526,
        DispatchCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        OriginCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        FacilityCity: "Parvathipuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14300,
        Pin: 423704,
        DispatchCenter: "Vaijapur_Rajsarthi_D (Maharashtra)",
        OriginCenter: "Vaijapur_Rajsarthi_D (Maharashtra)",
        FacilityCity: "Vaijapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14301,
        Pin: 560003,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14302,
        Pin: 712704,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14303,
        Pin: 394360,
        DispatchCenter: "Songadh_Vyara_D (Gujarat)",
        OriginCenter: "Songadh_Vyara_D (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14304,
        Pin: 415522,
        DispatchCenter: "Phaltan_Kolki_D (Maharashtra)",
        OriginCenter: "Phaltan_Kolki_D (Maharashtra)",
        FacilityCity: "Phaltan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14305,
        Pin: 711312,
        DispatchCenter: "Bagnan_Harop_D (West Bengal)",
        OriginCenter: "Bagnan_Harop_D (West Bengal)",
        FacilityCity: "Bagnan",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14306,
        Pin: 712157,
        DispatchCenter: "Pandua_NvplyDPP_D (West Bengal)",
        OriginCenter: "Pandua_NvplyDPP_D (West Bengal)",
        FacilityCity: "Pandua",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14307,
        Pin: 622201,
        DispatchCenter: "Pudukkottai_Lnpuram_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Lnpuram_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14308,
        Pin: 225302,
        DispatchCenter: "FatehpurUP_PatelNagar_D (Uttar Pradesh)",
        OriginCenter: "FatehpurUP_PatelNagar_D (Uttar Pradesh)",
        FacilityCity: "Fatehpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14309,
        Pin: 843115,
        DispatchCenter: "Dholi_Etwarpurjointi_D (Bihar)",
        OriginCenter: "Dholi_Etwarpurjointi_D (Bihar)",
        FacilityCity: "Dholi",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14310,
        Pin: 680688,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14311,
        Pin: 333515,
        DispatchCenter: "Buhana_Puranabas_D (Rajasthan)",
        OriginCenter: "Buhana_Puranabas_D (Rajasthan)",
        FacilityCity: "Buhana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14312,
        Pin: 146111,
        DispatchCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        OriginCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14313,
        Pin: 814154,
        DispatchCenter: "Mahagama_Teachercolony_DPP (Jharkhand)",
        OriginCenter: "Mahagama_Teachercolony_DPP (Jharkhand)",
        FacilityCity: "Mahagama",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14314,
        Pin: 799013,
        DispatchCenter: "Udaipur_Chanban_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Udaipur-TR",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14315,
        Pin: 422006,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14316,
        Pin: 691531,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14317,
        Pin: 424306,
        DispatchCenter: "Sakri_Arihantnagar_D (Maharashtra)",
        OriginCenter: "Sakri_Arihantnagar_D (Maharashtra)",
        FacilityCity: "Sakri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14318,
        Pin: 695031,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14319,
        Pin: 521247,
        DispatchCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        OriginCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        FacilityCity: "Pamarru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14320,
        Pin: 752107,
        DispatchCenter: "Konark_Central_DPP_2 (Orissa)",
        OriginCenter: "Konark_Central_DPP_2 (Orissa)",
        FacilityCity: "Konark",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14321,
        Pin: 176076,
        DispatchCenter: "Kachhera_Timber_DPP (Himachal Pradesh)",
        OriginCenter: "Kachhera_Timber_DPP (Himachal Pradesh)",
        FacilityCity: "Kachhera",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14322,
        Pin: 603312,
        DispatchCenter: "Chengalpattu_Cheyyur_D (Tamil Nadu)",
        OriginCenter: "Chengalpattu_Cheyyur_D (Tamil Nadu)",
        FacilityCity: "Melmaruvathur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14323,
        Pin: 825102,
        DispatchCenter: "Phusro_RhmgjDPP_D (Jharkhand)",
        OriginCenter: "Phusro_RhmgjDPP_D (Jharkhand)",
        FacilityCity: "Phusro",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14324,
        Pin: 590017,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14325,
        Pin: 605751,
        DispatchCenter: "Thirukoilure_Memalur_DPP (Tamil Nadu)",
        OriginCenter: "Thirukoilure_Memalur_DPP (Tamil Nadu)",
        FacilityCity: "Memalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14326,
        Pin: 506345,
        DispatchCenter: "Bhupalpally_LaxmiNagar_DPP (Telangana)",
        OriginCenter: "Bhupalpally_LaxmiNagar_DPP (Telangana)",
        FacilityCity: "Bhupalpally",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14327,
        Pin: 403105,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14328,
        Pin: 680304,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14329,
        Pin: 754031,
        DispatchCenter: "Badamba_Biswambharpur_D (Orissa)",
        OriginCenter: "Badamba_Biswambharpur_D (Orissa)",
        FacilityCity: "Badamba",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14330,
        Pin: 743445,
        DispatchCenter: "Joypul_Maniktala_D (West Bengal)",
        OriginCenter: "Joypul_Maniktala_D (West Bengal)",
        FacilityCity: "Joypul",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14331,
        Pin: 591112,
        DispatchCenter: "Kittur_Guruwarpeth_DPP (Karnataka)",
        OriginCenter: "Kittur_Guruwarpeth_DPP (Karnataka)",
        FacilityCity: "Kittur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14332,
        Pin: 670301,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14333,
        Pin: 532461,
        DispatchCenter: "Palakonda_VadaiDPP_D (Andhra Pradesh)",
        OriginCenter: "Palakonda_VadaiDPP_D (Andhra Pradesh)",
        FacilityCity: "Palakonda",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14334,
        Pin: 841408,
        DispatchCenter: "Malmaliya_Sipah_D (Bihar)",
        OriginCenter: "Malmaliya_Sipah_D (Bihar)",
        FacilityCity: "Malmaliya",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14335,
        Pin: 231217,
        DispatchCenter: "Renukoot_Murdhava_H (Uttar Pradesh)",
        OriginCenter: "Renukoot_Murdhava_H (Uttar Pradesh)",
        FacilityCity: "Renukoot",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14336,
        Pin: 782101,
        DispatchCenter: "Nagaon_Bangthai_H (Assam)",
        OriginCenter: "Nagaon_Bangthai_H (Assam)",
        FacilityCity: "Nagaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14337,
        Pin: 741238,
        DispatchCenter: "Gopalnagar_ParaSttn_DPP (West Bengal)",
        OriginCenter: "Gopalnagar_ParaSttn_DPP (West Bengal)",
        FacilityCity: "Gopalnagar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14338,
        Pin: 481776,
        DispatchCenter: "Mandla_Nainpur_D (Madhya Pradesh)",
        OriginCenter: "Mandla_Nainpur_D (Madhya Pradesh)",
        FacilityCity: "Mandla",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 14339,
        Pin: 312206,
        DispatchCenter: "Gangapur_Sahara_DPP (Rajasthan)",
        OriginCenter: "Gangapur_Sahara_DPP (Rajasthan)",
        FacilityCity: "Gangapur-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14340,
        Pin: 577245,
        DispatchCenter: "Shimoga_Eshwariah_I (Karnataka)",
        OriginCenter: "Shimoga_Eshwariah_I (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14341,
        Pin: 415013,
        DispatchCenter: "Satara_VithaiEnter_D (Maharashtra)",
        OriginCenter: "Satara_VithaiEnter_D (Maharashtra)",
        FacilityCity: "Satara",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14342,
        Pin: 848218,
        DispatchCenter: "Tajpur_HsptlRd_D (Bihar)",
        OriginCenter: "Tajpur_HsptlRd_D (Bihar)",
        FacilityCity: "Tajpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14343,
        Pin: 382305,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14344,
        Pin: 303510,
        DispatchCenter: "Lalsot_Ward34_D (Rajasthan)",
        OriginCenter: "Lalsot_Ward34_D (Rajasthan)",
        FacilityCity: "Lalsot",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14345,
        Pin: 848214,
        DispatchCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        OriginCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        FacilityCity: "Dalsingh Sarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14346,
        Pin: 283101,
        DispatchCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        OriginCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        FacilityCity: "Agra",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14347,
        Pin: 261151,
        DispatchCenter: "Maholi_Urdauli_D (Uttar Pradesh)",
        OriginCenter: "Maholi_Urdauli_D (Uttar Pradesh)",
        FacilityCity: "Maholi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14348,
        Pin: 131039,
        DispatchCenter: "Sonipat_Sector51_L (Haryana)",
        OriginCenter: "Sonipat_WazidpurSaboli_P (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14349,
        Pin: 495453,
        DispatchCenter: "Korba_Katghora_D (Chhattisgarh)",
        OriginCenter: "Korba_Katghora_D (Chhattisgarh)",
        FacilityCity: "Korba",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14350,
        Pin: 416709,
        DispatchCenter: "Rajapur_Sainagar_D (Maharashtra)",
        OriginCenter: "Rajapur_Sainagar_D (Maharashtra)",
        FacilityCity: "Rajapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14351,
        Pin: 495684,
        DispatchCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        OriginCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        FacilityCity: "Korba",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14352,
        Pin: 458664,
        DispatchCenter: "Mandsaur_Sindpan_I (Madhya Pradesh)",
        OriginCenter: "Mandsaur_Sindpan_I (Madhya Pradesh)",
        FacilityCity: "Mandsaur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 14353,
        Pin: 508207,
        DispatchCenter: "Miryalguda_Ragvendr_D (Telangana)",
        OriginCenter: "Miryalguda_Ragvendr_D (Telangana)",
        FacilityCity: "Miryalaguda",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14354,
        Pin: 422004,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14355,
        Pin: 711224,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14356,
        Pin: 831018,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14357,
        Pin: 516503,
        DispatchCenter: "Badvel_CCcolony_D (Andhra Pradesh)",
        OriginCenter: "Badvel_CCcolony_D (Andhra Pradesh)",
        FacilityCity: "Badvel",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14358,
        Pin: 522305,
        DispatchCenter: "Guntur_Amaravati_D (Andhra Pradesh)",
        OriginCenter: "Guntur_Amaravati_D (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14359,
        Pin: 305023,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14360,
        Pin: 845406,
        DispatchCenter: "Muzaffarpur_Minapur_D (Bihar)",
        OriginCenter: "Muzaffarpur_Minapur_D (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14361,
        Pin: 560106,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14362,
        Pin: 144514,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14363,
        Pin: 414607,
        DispatchCenter: "Newasa_Khurd_D (Maharashtra)",
        OriginCenter: "Newasa_Khurd_D (Maharashtra)",
        FacilityCity: "Newasa",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14364,
        Pin: 625015,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14365,
        Pin: 224125,
        DispatchCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        OriginCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        FacilityCity: "Jalalpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14366,
        Pin: 250615,
        DispatchCenter: "Baghpat_Barout_D (Uttar Pradesh)",
        OriginCenter: "Baghpat_Barout_D (Uttar Pradesh)",
        FacilityCity: "Baghpat",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14367,
        Pin: 483504,
        DispatchCenter: "Katni_ShantiNagar_D (Madhya Pradesh)",
        OriginCenter: "Katni_ShantiNagar_D (Madhya Pradesh)",
        FacilityCity: "Katni",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 14368,
        Pin: 722135,
        DispatchCenter: "Khatra_KoralDPP_D (West Bengal)",
        OriginCenter: "Khatra_KoralDPP_D (West Bengal)",
        FacilityCity: "Khatra",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14369,
        Pin: 679589,
        DispatchCenter: "Tirur_Perunthallur_D (Kerala)",
        OriginCenter: "Tirur_Perunthallur_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14370,
        Pin: 272002,
        DispatchCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        OriginCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        FacilityCity: "Basti",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14371,
        Pin: 414303,
        DispatchCenter: "Parner_Hindchowk_D (Maharashtra)",
        OriginCenter: "Parner_Hindchowk_D (Maharashtra)",
        FacilityCity: "Parner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14372,
        Pin: 521135,
        DispatchCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        OriginCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        FacilityCity: "Pamarru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14373,
        Pin: 202410,
        DispatchCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        OriginCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        FacilityCity: "Chandausi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14374,
        Pin: 577227,
        DispatchCenter: "Shimoga_Eshwariah_I (Karnataka)",
        OriginCenter: "Shimoga_Eshwariah_I (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14375,
        Pin: 457882,
        DispatchCenter: "Dahod_Vanzarwada_I (Gujarat)",
        OriginCenter: "Dahod_Vanzarwada_I (Gujarat)",
        FacilityCity: "Dahod",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14376,
        Pin: 233228,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14377,
        Pin: 431214,
        DispatchCenter: "Jalna_Yashodipnagar_D (Maharashtra)",
        OriginCenter: "Jalna_Yashodipnagar_D (Maharashtra)",
        FacilityCity: "Jalna",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14378,
        Pin: 144223,
        DispatchCenter: "Dasuya_Hajipur_D (Punjab)",
        OriginCenter: "Dasuya_Hajipur_D (Punjab)",
        FacilityCity: "Dasuya",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14379,
        Pin: 680011,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14380,
        Pin: 673615,
        DispatchCenter: "Perambra_Koorachundu_D (Kerala)",
        OriginCenter: "Perambra_Koorachundu_D (Kerala)",
        FacilityCity: "Perambra",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14381,
        Pin: 829122,
        DispatchCenter: "Ramgarh_Marar_D (Jharkhand)",
        OriginCenter: "Ramgarh_Marar_D (Jharkhand)",
        FacilityCity: "Ramgarh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14382,
        Pin: 160101,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14383,
        Pin: 700055,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14384,
        Pin: 606209,
        DispatchCenter: "Kallakurichi_Neelamangalam_D (Tamil Nadu)",
        OriginCenter: "Kallakurichi_Neelamangalam_D (Tamil Nadu)",
        FacilityCity: "Kallakurichi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14385,
        Pin: 272165,
        DispatchCenter: "Sikriganj_Sikaridihkhurd_D (Uttar Pradesh)",
        OriginCenter: "Sikriganj_Sikaridihkhurd_D (Uttar Pradesh)",
        FacilityCity: "Sikriganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14386,
        Pin: 173229,
        DispatchCenter: "Dharampur_Kumarhatti_DPP (Himachal Pradesh)",
        OriginCenter: "Dharampur_Kumarhatti_DPP (Himachal Pradesh)",
        FacilityCity: "Dharampur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14387,
        Pin: 400060,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14388,
        Pin: 695005,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14389,
        Pin: 670543,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14390,
        Pin: 754026,
        DispatchCenter: "Cuttack_Bilteruan_D (Orissa)",
        OriginCenter: "Cuttack_Bilteruan_D (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14391,
        Pin: 824231,
        DispatchCenter: "Gaya_Matihani_H (Bihar)",
        OriginCenter: "Gaya_Matihani_H (Bihar)",
        FacilityCity: "Gaya",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14392,
        Pin: 688006,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14393,
        Pin: 531115,
        DispatchCenter: "Narsipatnam_Nathavaram_DPP (Andhra Pradesh)",
        OriginCenter: "Narsipatnam_Nathavaram_DPP (Andhra Pradesh)",
        FacilityCity: "Narsipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14394,
        Pin: 232333,
        DispatchCenter: "Gahmar_KhuduraPthra_DPP (Uttar Pradesh)",
        OriginCenter: "Gahmar_KhuduraPthra_DPP (Uttar Pradesh)",
        FacilityCity: "Gahmar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14395,
        Pin: 788834,
        DispatchCenter: "Mahur_Maibang1_D (Assam)",
        OriginCenter: "Mahur_Maibang1_D (Assam)",
        FacilityCity: "Mahur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14396,
        Pin: 141011,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14397,
        Pin: 524317,
        DispatchCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        OriginCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14398,
        Pin: 362110,
        DispatchCenter: "Visavadar_JunagadhRd_DPP (Gujarat)",
        OriginCenter: "Visavadar_JunagadhRd_DPP (Gujarat)",
        FacilityCity: "Visavadar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14399,
        Pin: 783384,
        DispatchCenter: "Bongaigaon_Chapaguri_I (Assam)",
        OriginCenter: "Bongaigaon_Chapaguri_I (Assam)",
        FacilityCity: "Bongaigaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14400,
        Pin: 383010,
        DispatchCenter: "Idar_GIDC_D (Gujarat)",
        OriginCenter: "Idar_GIDC_D (Gujarat)",
        FacilityCity: "Idar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14401,
        Pin: 414504,
        DispatchCenter: "Shevgaon_MiriRd_DPP (Maharashtra)",
        OriginCenter: "Shevgaon_MiriRd_DPP (Maharashtra)",
        FacilityCity: "Shevgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14402,
        Pin: 635811,
        DispatchCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ambur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14403,
        Pin: 412301,
        DispatchCenter: "Pune_Fursungi_L (Maharashtra)",
        OriginCenter: "Pune_Fursungi_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14404,
        Pin: 382480,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14405,
        Pin: 577517,
        DispatchCenter: "Jagaluru_Tumatelayout_D (Karnataka)",
        OriginCenter: "Jagaluru_Tumatelayout_D (Karnataka)",
        FacilityCity: "Jagaluru",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14406,
        Pin: 571314,
        DispatchCenter: "Nanjangud_Deviramanahalli_D (Karnataka)",
        OriginCenter: "Nanjangud_Deviramanahalli_D (Karnataka)",
        FacilityCity: "Nanjangud",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14407,
        Pin: 143002,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14408,
        Pin: 643211,
        DispatchCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        OriginCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        FacilityCity: "Gudalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14409,
        Pin: 580213,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14410,
        Pin: 403523,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14411,
        Pin: 560027,
        DispatchCenter: "Bangalore_Shantinagar_L (Karnataka)",
        OriginCenter: "Bangalore_Shantinagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14412,
        Pin: 227817,
        DispatchCenter: "Jagdishpur_Canbank_D (Uttar Pradesh)",
        OriginCenter: "Jagdishpur_Canbank_D (Uttar Pradesh)",
        FacilityCity: "Jagdishpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14413,
        Pin: 755023,
        DispatchCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        OriginCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        FacilityCity: "Chandikhol",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14414,
        Pin: 160066,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14415,
        Pin: 713301,
        DispatchCenter: "Asansol_Addacolony_D (West Bengal)",
        OriginCenter: "Asansol_Addacolony_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14416,
        Pin: 532001,
        DispatchCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        OriginCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        FacilityCity: "Srikakulam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14417,
        Pin: 495115,
        DispatchCenter: "Mungeli_PandariaRd_DPP (Chhattisgarh)",
        OriginCenter: "Mungeli_PandariaRd_DPP (Chhattisgarh)",
        FacilityCity: "Mungeli",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14418,
        Pin: 676562,
        DispatchCenter: "Tirur_Perunthallur_D (Kerala)",
        OriginCenter: "Tirur_Perunthallur_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14419,
        Pin: 394680,
        DispatchCenter: "Songadh_Vyara_D (Gujarat)",
        OriginCenter: "Songadh_Vyara_D (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14420,
        Pin: 495687,
        DispatchCenter: "Sakti_Siphaimda_D (Chhattisgarh)",
        OriginCenter: "Sakti_Siphaimda_D (Chhattisgarh)",
        FacilityCity: "Sakti",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14421,
        Pin: 424002,
        DispatchCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        OriginCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        FacilityCity: "Dhule",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14422,
        Pin: 522308,
        DispatchCenter: "Tenali_Duggirala_D (Andhra Pradesh)",
        OriginCenter: "Tenali_Duggirala_D (Andhra Pradesh)",
        FacilityCity: "Tenali",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14423,
        Pin: 272163,
        DispatchCenter: "Itava_Prsnga_D (Uttar Pradesh)",
        OriginCenter: "Itava_Prsnga_D (Uttar Pradesh)",
        FacilityCity: "Itava",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14424,
        Pin: 678731,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14425,
        Pin: 244501,
        DispatchCenter: "Kanth_ModaPatti_DPP (Uttar Pradesh)",
        OriginCenter: "Kanth_ModaPatti_DPP (Uttar Pradesh)",
        FacilityCity: "Kanth",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14426,
        Pin: 844128,
        DispatchCenter: "Saraiya_Saraiya_D (Bihar)",
        OriginCenter: "Saraiya_Saraiya_D (Bihar)",
        FacilityCity: "Saraiya",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14427,
        Pin: 682505,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14428,
        Pin: 606303,
        DispatchCenter: "Chinnasalem_VKootRd_D (Tamil Nadu)",
        OriginCenter: "Chinnasalem_VKootRd_D (Tamil Nadu)",
        FacilityCity: "Chinnasalem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14429,
        Pin: 412111,
        DispatchCenter: "Pune_Muktainagar_L (Maharashtra)",
        OriginCenter: "Pune_Muktainagar_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14430,
        Pin: 700048,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14431,
        Pin: 110075,
        DispatchCenter: "Delhi_BamnoliVillage_L (Delhi)",
        OriginCenter: "Delhi_BamnoliVillage_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14432,
        Pin: 422602,
        DispatchCenter: "Alephata_BhrtPetrol_D (Maharashtra)",
        OriginCenter: "Alephata_BhrtPetrol_D (Maharashtra)",
        FacilityCity: "Alephata",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14433,
        Pin: 515002,
        DispatchCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        OriginCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14434,
        Pin: 630551,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14435,
        Pin: 560108,
        DispatchCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        OriginCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14436,
        Pin: 689642,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14437,
        Pin: 735211,
        DispatchCenter: "CoochBehar_Pundibari_D (West Bengal)",
        OriginCenter: "CoochBehar_Pundibari_D (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14438,
        Pin: 621653,
        DispatchCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        OriginCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        FacilityCity: "Ariyalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14439,
        Pin: 509321,
        DispatchCenter: "Amangal_Adarshnagar_D (Telangana)",
        OriginCenter: "Amangal_Adarshnagar_D (Telangana)",
        FacilityCity: "Amangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14440,
        Pin: 212663,
        DispatchCenter: "Fatehpur_Gandhinagar_D (Uttar Pradesh)",
        OriginCenter: "Fatehpur_Gandhinagar_D (Uttar Pradesh)",
        FacilityCity: "Fatehpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14441,
        Pin: 209307,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14442,
        Pin: 466118,
        DispatchCenter: "Ashta_Saicolony_D (Madhya Pradesh)",
        OriginCenter: "Ashta_Saicolony_D (Madhya Pradesh)",
        FacilityCity: "Ashta",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 14443,
        Pin: 272149,
        DispatchCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        OriginCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        FacilityCity: "Basti",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14444,
        Pin: 689103,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14445,
        Pin: 345002,
        DispatchCenter: "Jaisalmer_Gaffurbhatt_D (Rajasthan)",
        OriginCenter: "Jaisalmer_Gaffurbhatt_D (Rajasthan)",
        FacilityCity: "Jaisalmer",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14446,
        Pin: 713419,
        DispatchCenter: "Panagarh_Kanksa_D (West Bengal)",
        OriginCenter: "Panagarh_Kanksa_D (West Bengal)",
        FacilityCity: "Panagarh",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14447,
        Pin: 561101,
        DispatchCenter: "Dabaspete_Chandanahosahalli_D (Karnataka)",
        OriginCenter: "Dabaspete_Chandanahosahalli_D (Karnataka)",
        FacilityCity: "Dabaspete",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14448,
        Pin: 201310,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14449,
        Pin: 521102,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14450,
        Pin: 600045,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14451,
        Pin: 385545,
        DispatchCenter: "Dhanera_Tulsinagar_DPP (Gujarat)",
        OriginCenter: "Dhanera_Tulsinagar_DPP (Gujarat)",
        FacilityCity: "Dhanera",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14452,
        Pin: 141125,
        DispatchCenter: "Machhiwara_MnBzrDPP_D (Punjab)",
        OriginCenter: "Machhiwara_MnBzrDPP_D (Punjab)",
        FacilityCity: "Machhiwara",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14453,
        Pin: 602003,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14454,
        Pin: 635124,
        DispatchCenter: "Hosur_SipcotPhase1_L (Tamil Nadu)",
        OriginCenter: "Hosur_SipcotPhase1_L (Tamil Nadu)",
        FacilityCity: "Hosur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14455,
        Pin: 742101,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14456,
        Pin: 396370,
        DispatchCenter: "Bilimora_DC (Gujarat)",
        OriginCenter: "Bilimora_DC (Gujarat)",
        FacilityCity: "Bilimora",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14457,
        Pin: 769005,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14458,
        Pin: 470221,
        DispatchCenter: "Sagar_Pagara_P (Madhya Pradesh)",
        OriginCenter: "Sagar_Pagara_P (Madhya Pradesh)",
        FacilityCity: "Sagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 14459,
        Pin: 531083,
        DispatchCenter: "Koruprolu_Thimmapuram_D (Andhra Pradesh)",
        OriginCenter: "Koruprolu_Thimmapuram_D (Andhra Pradesh)",
        FacilityCity: "Koruprolu",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14460,
        Pin: 732140,
        DispatchCenter: "Chanchal_Tulsihata_D (West Bengal)",
        OriginCenter: "Chanchal_Tulsihata_D (West Bengal)",
        FacilityCity: "Chanchal",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14461,
        Pin: 505331,
        DispatchCenter: "Metpally_GunjRDPP_D (Telangana)",
        OriginCenter: "Metpally_GunjRDPP_D (Telangana)",
        FacilityCity: "Metpally",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14462,
        Pin: 504323,
        DispatchCenter: "BoathBuzurg_Sainagar_DPP (Telangana)",
        OriginCenter: "BoathBuzurg_Sainagar_DPP (Telangana)",
        FacilityCity: "Boath Buzurg",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14463,
        Pin: 612604,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14464,
        Pin: 522203,
        DispatchCenter: "Tenali_Pandurngpet_D (Andhra Pradesh)",
        OriginCenter: "Tenali_Pandurngpet_D (Andhra Pradesh)",
        FacilityCity: "Tenali",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14465,
        Pin: 486335,
        DispatchCenter: "Deotalab_Mauganj_D (Madhya Pradesh)",
        OriginCenter: "Deotalab_Mauganj_D (Madhya Pradesh)",
        FacilityCity: "Deotalab",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 14466,
        Pin: 391168,
        DispatchCenter: "ChhotaUdaipur_Panjrapole_D (Gujarat)",
        OriginCenter: "ChhotaUdaipur_Panjrapole_D (Gujarat)",
        FacilityCity: "Chhota Udaipur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14467,
        Pin: 206451,
        DispatchCenter: "Chhibramau_Bhawalpur_D (Uttar Pradesh)",
        OriginCenter: "Chhibramau_Bhawalpur_D (Uttar Pradesh)",
        FacilityCity: "Mainpuri",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14468,
        Pin: 606701,
        DispatchCenter: "Uthangarai_Singarapettai_D (Tamil Nadu)",
        OriginCenter: "Uthangarai_Singarapettai_D (Tamil Nadu)",
        FacilityCity: "Uthangarai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14469,
        Pin: 122209,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14470,
        Pin: 735210,
        DispatchCenter: "Mainaguri_Debinagar_DPP (West Bengal)",
        OriginCenter: "Mainaguri_Debinagar_DPP (West Bengal)",
        FacilityCity: "Mainaguri",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14471,
        Pin: 689583,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14472,
        Pin: 802202,
        DispatchCenter: "Piro_Bachri_D (Bihar)",
        OriginCenter: "Piro_Bachri_D (Bihar)",
        FacilityCity: "Piro",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14473,
        Pin: 523170,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14474,
        Pin: 224208,
        DispatchCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        OriginCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        FacilityCity: "Kumarganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14475,
        Pin: 283145,
        DispatchCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        OriginCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        FacilityCity: "Shikohabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14476,
        Pin: 415206,
        DispatchCenter: "Patan_Ashram_D (Maharashtra)",
        OriginCenter: "Patan_Ashram_D (Maharashtra)",
        FacilityCity: "Patan-MH",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14477,
        Pin: 756122,
        DispatchCenter: "Jajpur_Panasa_D (Orissa)",
        OriginCenter: "Jajpur_Panasa_D (Orissa)",
        FacilityCity: "Jajpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14478,
        Pin: 625523,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14479,
        Pin: 441205,
        DispatchCenter: "Brahmapuri_Tilaknagar_D (Maharashtra)",
        OriginCenter: "Brahmapuri_Tilaknagar_D (Maharashtra)",
        FacilityCity: "Brahmapuri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14480,
        Pin: 413255,
        DispatchCenter: "Solapur_Balepeth_I (Maharashtra)",
        OriginCenter: "Solapur_Balepeth_I (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14481,
        Pin: 364560,
        DispatchCenter: "Rajula_Anandnagar_D (Gujarat)",
        OriginCenter: "Rajula_Anandnagar_D (Gujarat)",
        FacilityCity: "Rajula",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14482,
        Pin: 753132,
        DispatchCenter: "Jalpaiguri_Gomostapara_D (West Bengal)",
        OriginCenter: "Jalpaiguri_Gomostapara_D (West Bengal)",
        FacilityCity: "Jalpaiguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14483,
        Pin: 844124,
        DispatchCenter: "Hajipur_Belkunda_D (Bihar)",
        OriginCenter: "Hajipur_Belkunda_D (Bihar)",
        FacilityCity: "Hajipur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14484,
        Pin: 626515,
        DispatchCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        OriginCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        FacilityCity: "Cumbum",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14485,
        Pin: 121015,
        DispatchCenter: "Faridabad_MathuraRoad_L (Haryana)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14486,
        Pin: 607004,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14487,
        Pin: 813125,
        DispatchCenter: "Banka_Shahpur_DPP (Bihar)",
        OriginCenter: "Banka_Shahpur_DPP (Bihar)",
        FacilityCity: "Banka",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14488,
        Pin: 124021,
        DispatchCenter: "Rohtak_IndustrialArea_D (Haryana)",
        OriginCenter: "Rohtak_IndustrialArea_D (Haryana)",
        FacilityCity: "Rohtak",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14489,
        Pin: 222302,
        DispatchCenter: "Lambhua_Chaukiya_D (Uttar Pradesh)",
        OriginCenter: "Lambhua_Chaukiya_D (Uttar Pradesh)",
        FacilityCity: "Lambhua",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14490,
        Pin: 571120,
        DispatchCenter: "Mysore_Tnarsipura_DPP (Karnataka)",
        OriginCenter: "Mysore_Tnarsipura_DPP (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14491,
        Pin: 391230,
        DispatchCenter: "Karjan_Junabzr_DC (Gujarat)",
        OriginCenter: "Karjan_Junabzr_DC (Gujarat)",
        FacilityCity: "Karjan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14492,
        Pin: 854328,
        DispatchCenter: "Forbesganj_Jummanchowk_D (Bihar)",
        OriginCenter: "Forbesganj_Jummanchowk_D (Bihar)",
        FacilityCity: "Forbesganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14493,
        Pin: 581112,
        DispatchCenter: "Haveri_ManjunathNagar_D (Karnataka)",
        OriginCenter: "Haveri_ManjunathNagar_D (Karnataka)",
        FacilityCity: "Haveri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14494,
        Pin: 271845,
        DispatchCenter: "Ikauna_Hndutmple_D (Uttar Pradesh)",
        OriginCenter: "Ikauna_Hndutmple_D (Uttar Pradesh)",
        FacilityCity: "Ikauna",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14495,
        Pin: 781006,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14496,
        Pin: 148108,
        DispatchCenter: "RampuraPhul_DC (Punjab)",
        OriginCenter: "RampuraPhul_DC (Punjab)",
        FacilityCity: "Rampura Phul",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14497,
        Pin: 700032,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14498,
        Pin: 625604,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14499,
        Pin: 600127,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14500,
        Pin: 271402,
        DispatchCenter: "Gonda_Mukundpur_D (Uttar Pradesh)",
        OriginCenter: "Gonda_Mukundpur_D (Uttar Pradesh)",
        FacilityCity: "Gonda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14501,
        Pin: 230123,
        DispatchCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        OriginCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        FacilityCity: "Patti-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14502,
        Pin: 411038,
        DispatchCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        OriginCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14503,
        Pin: 733133,
        DispatchCenter: "Gangarampr_Ramkrishnpaly_DPP (West Bengal)",
        OriginCenter: "Gangarampr_Ramkrishnpaly_DPP (West Bengal)",
        FacilityCity: "Gangarampur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14504,
        Pin: 515241,
        DispatchCenter: "Gorantla_Market_D (Andhra Pradesh)",
        OriginCenter: "Gorantla_Market_D (Andhra Pradesh)",
        FacilityCity: "Gorantla",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14505,
        Pin: 244902,
        DispatchCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        OriginCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        FacilityCity: "Moradabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14506,
        Pin: 735101,
        DispatchCenter: "Jalpaiguri_Gomostapara_D (West Bengal)",
        OriginCenter: "Jalpaiguri_Gomostapara_D (West Bengal)",
        FacilityCity: "Jalpaiguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14507,
        Pin: 767067,
        DispatchCenter: "Saintala_Blockroad_DPP (Orissa)",
        OriginCenter: "Saintala_Blockroad_DPP (Orissa)",
        FacilityCity: "Saintala",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14508,
        Pin: 680589,
        DispatchCenter: "Shoranur_Akamala_D (Kerala)",
        OriginCenter: "Shoranur_Akamala_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14509,
        Pin: 622202,
        DispatchCenter: "Pudukkottai_Lnpuram_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Lnpuram_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14510,
        Pin: 641303,
        DispatchCenter: "Mettupalayam_Belladhi_D (Tamil Nadu)",
        OriginCenter: "Mettupalayam_Belladhi_D (Tamil Nadu)",
        FacilityCity: "Mettupalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14511,
        Pin: 423109,
        DispatchCenter: "Shirdi_Sakori_D (Maharashtra)",
        OriginCenter: "Shirdi_Sakori_D (Maharashtra)",
        FacilityCity: "Shirdi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14512,
        Pin: 625103,
        DispatchCenter: "Ponamarvathi_Singampunari_D (Tamil Nadu)",
        OriginCenter: "Ponamarvathi_Singampunari_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14513,
        Pin: 221718,
        DispatchCenter: "Ballia_Sikandarpur_D (Uttar Pradesh)",
        OriginCenter: "Ballia_Sikandarpur_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14514,
        Pin: 700077,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14515,
        Pin: 761017,
        DispatchCenter: "Mohana_Betarsingi_D (Orissa)",
        OriginCenter: "Mohana_Betarsingi_D (Orissa)",
        FacilityCity: "Mohana",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14516,
        Pin: 431505,
        DispatchCenter: "Selu_Vidyangr_D (Maharashtra)",
        OriginCenter: "Selu_Vidyangr_D (Maharashtra)",
        FacilityCity: "Selu",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14517,
        Pin: 230405,
        DispatchCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        OriginCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        FacilityCity: "Patti-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14518,
        Pin: 231222,
        DispatchCenter: "Anpara_Parasi_D (Uttar Pradesh)",
        OriginCenter: "Anpara_Parasi_D (Uttar Pradesh)",
        FacilityCity: "Anpara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14519,
        Pin: 365480,
        DispatchCenter: "Bhesan_STroad_DPP (Gujarat)",
        OriginCenter: "Bhesan_STroad_DPP (Gujarat)",
        FacilityCity: "Bhesan",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14520,
        Pin: 143005,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14521,
        Pin: 365541,
        DispatchCenter: "Rajula_Anandnagar_D (Gujarat)",
        OriginCenter: "Rajula_Anandnagar_D (Gujarat)",
        FacilityCity: "Rajula",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14522,
        Pin: 700038,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14523,
        Pin: 845431,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14524,
        Pin: 518593,
        DispatchCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        OriginCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        FacilityCity: "Nandyal",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14525,
        Pin: 606906,
        DispatchCenter: "Polur_Kanji_D (Tamil Nadu)",
        OriginCenter: "Polur_Kanji_D (Tamil Nadu)",
        FacilityCity: "Polur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14526,
        Pin: 403707,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14527,
        Pin: 303330,
        DispatchCenter: "Phulera_NarenaRD_D (Rajasthan)",
        OriginCenter: "Phulera_NarenaRD_D (Rajasthan)",
        FacilityCity: "Phulera",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14528,
        Pin: 461771,
        DispatchCenter: "Pipariya_PachmrhiRd_DPP (Madhya Pradesh)",
        OriginCenter: "Pipariya_PachmrhiRd_DPP (Madhya Pradesh)",
        FacilityCity: "Pipariya",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 14529,
        Pin: 142051,
        DispatchCenter: "Dharamkot_Moga_D (Punjab)",
        OriginCenter: "Dharamkot_Moga_D (Punjab)",
        FacilityCity: "Dharamkot",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14530,
        Pin: 685508,
        DispatchCenter: "Kattappana_EratayarRoad_D (Kerala)",
        OriginCenter: "Kattappana_EratayarRoad_D (Kerala)",
        FacilityCity: "Kattappana",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14531,
        Pin: 521213,
        DispatchCenter: "Vissannapetaa_TiruvuruRd_D (Andhra Pradesh)",
        OriginCenter: "Vissannapetaa_TiruvuruRd_D (Andhra Pradesh)",
        FacilityCity: "Vissannapetaa",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14532,
        Pin: 571114,
        DispatchCenter: "Hunsur_HDKote_D (Karnataka)",
        OriginCenter: "Hunsur_HDKote_D (Karnataka)",
        FacilityCity: "Hunsur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14533,
        Pin: 401205,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14534,
        Pin: 444720,
        DispatchCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        OriginCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        FacilityCity: "Paratwada",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14535,
        Pin: 410211,
        DispatchCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        OriginCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14536,
        Pin: 630203,
        DispatchCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14537,
        Pin: 506166,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14538,
        Pin: 224137,
        DispatchCenter: "Rajesultanpur_PadumprMkt_D (Uttar Pradesh)",
        OriginCenter: "Rajesultanpur_PadumprMkt_D (Uttar Pradesh)",
        FacilityCity: "Rajesultanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14539,
        Pin: 124101,
        DispatchCenter: "Jhajjar_Policeline_I (Haryana)",
        OriginCenter: "Jhajjar_Policeline_I (Haryana)",
        FacilityCity: "Jhajjar",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14540,
        Pin: 423403,
        DispatchCenter: "Yeola_Angangaon_D (Maharashtra)",
        OriginCenter: "Yeola_Angangaon_D (Maharashtra)",
        FacilityCity: "Yeola",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14541,
        Pin: 606754,
        DispatchCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        OriginCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        FacilityCity: "Tiruvannamalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14542,
        Pin: 722175,
        DispatchCenter: "Bankura_Chhatna_DPP (West Bengal)",
        OriginCenter: "Bankura_Chhatna_DPP (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14543,
        Pin: 781068,
        DispatchCenter: "Guwahati_Kaikchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14544,
        Pin: 301026,
        DispatchCenter: "Alwar_Maancolony_D (Rajasthan)",
        OriginCenter: "Alwar_Maancolony_D (Rajasthan)",
        FacilityCity: "Alwar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14545,
        Pin: 506164,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14546,
        Pin: 782126,
        DispatchCenter: "Nagaon_Bangthai_H (Assam)",
        OriginCenter: "Nagaon_Bangthai_H (Assam)",
        FacilityCity: "Nagaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14547,
        Pin: 712707,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14548,
        Pin: 680701,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14549,
        Pin: 637408,
        DispatchCenter: "Rasipurm_Knrpati_D (Tamil Nadu)",
        OriginCenter: "Rasipurm_Knrpati_D (Tamil Nadu)",
        FacilityCity: "Rasipuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14550,
        Pin: 524221,
        DispatchCenter: "Pamuru_ViruvoruRd_D (Andhra Pradesh)",
        OriginCenter: "Pamuru_ViruvoruRd_D (Andhra Pradesh)",
        FacilityCity: "Pamuru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14551,
        Pin: 140126,
        DispatchCenter: "AnandpurSahib_Dher_D (Punjab)",
        OriginCenter: "AnandpurSahib_Dher_D (Punjab)",
        FacilityCity: "Anandpur Sahib",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14552,
        Pin: 401606,
        DispatchCenter: "Talasari_Patlpda_D (Maharashtra)",
        OriginCenter: "Talasari_Patlpda_D (Maharashtra)",
        FacilityCity: "Talasari",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14553,
        Pin: 361162,
        DispatchCenter: "Kalavad_Khodiyarpara_D (Gujarat)",
        OriginCenter: "Kalavad_Khodiyarpara_D (Gujarat)",
        FacilityCity: "Kalavad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14554,
        Pin: 182200,
        DispatchCenter: "Bhaderwah_Dandi_D (Jammu & Kashmir)",
        OriginCenter: "Bhaderwah_Dandi_D (Jammu & Kashmir)",
        FacilityCity: "Bhaderwah",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14555,
        Pin: 176081,
        DispatchCenter: "Baijnath_Paprola_DPP (Himachal Pradesh)",
        OriginCenter: "Baijnath_Paprola_DPP (Himachal Pradesh)",
        FacilityCity: "Baijnath",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14556,
        Pin: 811305,
        DispatchCenter: "Jhajha_Hillrange_D (Bihar)",
        OriginCenter: "Jhajha_Hillrange_D (Bihar)",
        FacilityCity: "Jhajha",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14557,
        Pin: 754213,
        DispatchCenter: "Kendrapara_Chhagharia_D (Orissa)",
        OriginCenter: "Kendrapara_Chhagharia_D (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14558,
        Pin: 244924,
        DispatchCenter: "Rampur_Suar_D (Uttar Pradesh)",
        OriginCenter: "Rampur_Suar_D (Uttar Pradesh)",
        FacilityCity: "Rampur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14559,
        Pin: 363423,
        DispatchCenter: "Limbdi_UmaiyaPark_DPP (Gujarat)",
        OriginCenter: "Limbdi_UmaiyaPark_DPP (Gujarat)",
        FacilityCity: "Limbdi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14560,
        Pin: 624622,
        DispatchCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        OriginCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        FacilityCity: "Oddanchatram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14561,
        Pin: 609704,
        DispatchCenter: "Peralam_Kaduvangudi_D (Tamil Nadu)",
        OriginCenter: "Peralam_Kaduvangudi_D (Tamil Nadu)",
        FacilityCity: "Peralam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14562,
        Pin: 679501,
        DispatchCenter: "Shoranur_Vaniyamkulam_D (Kerala)",
        OriginCenter: "Shoranur_Vaniyamkulam_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14563,
        Pin: 411029,
        DispatchCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        OriginCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14564,
        Pin: 581201,
        DispatchCenter: "Dharwad_Kamalpur_D (Karnataka)",
        OriginCenter: "Dharwad_Kamalpur_D (Karnataka)",
        FacilityCity: "Dharwad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14565,
        Pin: 383260,
        DispatchCenter: "Bayad_PwnPlza_D (Gujarat)",
        OriginCenter: "Bayad_PwnPlza_D (Gujarat)",
        FacilityCity: "Bayad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14566,
        Pin: 700095,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14567,
        Pin: 152128,
        DispatchCenter: "Abohar_DaulatPura_D (Punjab)",
        OriginCenter: "Abohar_DaulatPura_D (Punjab)",
        FacilityCity: "Abohar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14568,
        Pin: 206255,
        DispatchCenter: "Rasulabad_ShastriNgr_D (Uttar Pradesh)",
        OriginCenter: "Rasulabad_ShastriNgr_D (Uttar Pradesh)",
        FacilityCity: "Rasulabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14569,
        Pin: 122233,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14570,
        Pin: 605302,
        DispatchCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        OriginCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        FacilityCity: "Viluppuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14571,
        Pin: 535568,
        DispatchCenter: "Bobbili_Aguruvidi_D (Andhra Pradesh)",
        OriginCenter: "Bobbili_Aguruvidi_D (Andhra Pradesh)",
        FacilityCity: "Bobbili",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14572,
        Pin: 303106,
        DispatchCenter: "Paota_HoliChowk_DPP (Rajasthan)",
        OriginCenter: "Paota_HoliChowk_DPP (Rajasthan)",
        FacilityCity: "Paota",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14573,
        Pin: 495678,
        DispatchCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        OriginCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        FacilityCity: "Korba",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14574,
        Pin: 226007,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14575,
        Pin: 843329,
        DispatchCenter: "Sheohar_Ward13_D (Bihar)",
        OriginCenter: "Sheohar_Ward13_D (Bihar)",
        FacilityCity: "Sheohar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14576,
        Pin: 412206,
        DispatchCenter: "Khandala_Bhor_DPP (Maharashtra)",
        OriginCenter: "Khandala_Bhor_DPP (Maharashtra)",
        FacilityCity: "Khandala",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14577,
        Pin: 390001,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14578,
        Pin: 142630,
        DispatchCenter: "Moga_Duneke_I (Punjab)",
        OriginCenter: "Moga_Duneke_I (Punjab)",
        FacilityCity: "Moga",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14579,
        Pin: 572220,
        DispatchCenter: "Turuvukere_Havala_D (Karnataka)",
        OriginCenter: "Turuvukere_Havala_D (Karnataka)",
        FacilityCity: "Turuvukere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14580,
        Pin: 781034,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14581,
        Pin: 800019,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14582,
        Pin: 673544,
        DispatchCenter: "Vadakara_Veerancheri_D (Kerala)",
        OriginCenter: "Vadakara_Veerancheri_D (Kerala)",
        FacilityCity: "Vadakara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14583,
        Pin: 756080,
        DispatchCenter: "Basta_Darada_DPP (Orissa)",
        OriginCenter: "Basta_Darada_DPP (Orissa)",
        FacilityCity: "Basta",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14584,
        Pin: 731102,
        DispatchCenter: "Suri_Karidhya_D (West Bengal)",
        OriginCenter: "Suri_Karidhya_D (West Bengal)",
        FacilityCity: "Suri",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14585,
        Pin: 755005,
        DispatchCenter: "Chandipur_Keshpur_D (Orissa)",
        OriginCenter: "Chandipur_Keshpur_D (Orissa)",
        FacilityCity: "Chandipur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14586,
        Pin: 642006,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14587,
        Pin: 721436,
        DispatchCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        OriginCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        FacilityCity: "Dantan",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14588,
        Pin: 531133,
        DispatchCenter: "Chintapalli_Sainagar_D (Andhra Pradesh)",
        OriginCenter: "Chintapalli_Sainagar_D (Andhra Pradesh)",
        FacilityCity: "Chintapalli",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14589,
        Pin: 756051,
        DispatchCenter: "Soro_Ashoknagar_D (Orissa)",
        OriginCenter: "Soro_Ashoknagar_D (Orissa)",
        FacilityCity: "Soro",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14590,
        Pin: 444109,
        DispatchCenter: "Akola_Midcphase3_H (Maharashtra)",
        OriginCenter: "Akola_Midcphase3_H (Maharashtra)",
        FacilityCity: "Akola",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14591,
        Pin: 370230,
        DispatchCenter: "Gandhidham_BharatNagar_I (Gujarat)",
        OriginCenter: "Gandhidham_BharatNagar_I (Gujarat)",
        FacilityCity: "Gandhidham",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14592,
        Pin: 224231,
        DispatchCenter: "Akbarpur_AmbedkarNgr_D (Uttar Pradesh)",
        OriginCenter: "Akbarpur_AmbedkarNgr_D (Uttar Pradesh)",
        FacilityCity: "Akbarpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14593,
        Pin: 670343,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14594,
        Pin: 431606,
        DispatchCenter: "Nanded_Wajegaon_H (Maharashtra)",
        OriginCenter: "Nanded_Wajegaon_H (Maharashtra)",
        FacilityCity: "Nanded",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14595,
        Pin: 614211,
        DispatchCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        OriginCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14596,
        Pin: 110070,
        DispatchCenter: "Delhi_BamnoliVillage_L (Delhi)",
        OriginCenter: "Delhi_BamnoliVillage_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14597,
        Pin: 422221,
        DispatchCenter: "Nashik_Ojhar_DPP (Maharashtra)",
        OriginCenter: "Nashik_Ojhar_DPP (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14598,
        Pin: 249001,
        DispatchCenter: "Tehri_NewTehri_DPP (Uttarakhand)",
        OriginCenter: "Tehri_NewTehri_DPP (Uttarakhand)",
        FacilityCity: "Tehri",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14599,
        Pin: 591516,
        DispatchCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        OriginCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        FacilityCity: "Gokak",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14600,
        Pin: 848139,
        DispatchCenter: "Rosera_HanumanTemple_D (Bihar)",
        OriginCenter: "Rosera_HanumanTemple_D (Bihar)",
        FacilityCity: "Rusera",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14601,
        Pin: 524405,
        DispatchCenter: "Gudur_MalvyaNagar_D (Andhra Pradesh)",
        OriginCenter: "Gudur_MalvyaNagar_D (Andhra Pradesh)",
        FacilityCity: "Gudur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14602,
        Pin: 673624,
        DispatchCenter: "Perambra_KayipramRd_D (Kerala)",
        OriginCenter: "Perambra_KayipramRd_D (Kerala)",
        FacilityCity: "Perambra",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14603,
        Pin: 680652,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14604,
        Pin: 400020,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14605,
        Pin: 851213,
        DispatchCenter: "Maheshkhunt_PashchimTola_D (Bihar)",
        OriginCenter: "Maheshkhunt_PashchimTola_D (Bihar)",
        FacilityCity: "Maheshkhunt",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14606,
        Pin: 573226,
        DispatchCenter: "Hassan_Dudda_D (Karnataka)",
        OriginCenter: "Hassan_Dudda_D (Karnataka)",
        FacilityCity: "Hassan",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14607,
        Pin: 243126,
        DispatchCenter: "Faridpur_RanaClny_D (Uttar Pradesh)",
        OriginCenter: "Faridpur_RanaClny_D (Uttar Pradesh)",
        FacilityCity: "Faridpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14608,
        Pin: 305001,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14609,
        Pin: 393017,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14610,
        Pin: 676123,
        DispatchCenter: "Edavanna_MNRd_D (Kerala)",
        OriginCenter: "Edavanna_MNRd_D (Kerala)",
        FacilityCity: "Edavanna",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14611,
        Pin: 621005,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14612,
        Pin: 700085,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14613,
        Pin: 581104,
        DispatchCenter: "Hangal_PalaRd_D (Karnataka)",
        OriginCenter: "Hangal_PalaRd_D (Karnataka)",
        FacilityCity: "Hangal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14614,
        Pin: 620020,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14615,
        Pin: 713432,
        DispatchCenter: "Guskhara_DignagarRd_D (West Bengal)",
        OriginCenter: "Guskhara_DignagarRd_D (West Bengal)",
        FacilityCity: "Guskhara",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14616,
        Pin: 801303,
        DispatchCenter: "Khodaganj_Panhar_D (Bihar)",
        OriginCenter: "Khodaganj_Panhar_D (Bihar)",
        FacilityCity: "Khodaganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14617,
        Pin: 444405,
        DispatchCenter: "Akola_Midcphase3_H (Maharashtra)",
        OriginCenter: "Akola_Midcphase3_H (Maharashtra)",
        FacilityCity: "Akola",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14618,
        Pin: 401400,
        DispatchCenter: "Palghar_Maan_L (Maharashtra)",
        OriginCenter: "Palghar_Maan_L (Maharashtra)",
        FacilityCity: "Palghar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14619,
        Pin: 781138,
        DispatchCenter: "Sarthebari_Tmlichp_D (Assam)",
        OriginCenter: "Sarthebari_Tmlichp_D (Assam)",
        FacilityCity: "Sarthebari",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14620,
        Pin: 516237,
        DispatchCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14621,
        Pin: 412401,
        DispatchCenter: 37043.00011574074,
        OriginCenter: 37043.00011574074,
        FacilityCity: "Junnar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14622,
        Pin: 517391,
        DispatchCenter: "BKothakota_RTCStop_D (Andhra Pradesh)",
        OriginCenter: "BKothakota_RTCStop_D (Andhra Pradesh)",
        FacilityCity: "B Kothakota",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14623,
        Pin: 581106,
        DispatchCenter: "Byadagi_Basaveshwara_D (Karnataka)",
        OriginCenter: "Byadagi_Basaveshwara_D (Karnataka)",
        FacilityCity: "Byadagi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14624,
        Pin: 841235,
        DispatchCenter: "Mairwa_StationRd_D (Bihar)",
        OriginCenter: "Mairwa_StationRd_D (Bihar)",
        FacilityCity: "Mairwa",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14625,
        Pin: 517426,
        DispatchCenter: "Kuppam_KrishnagiriRd_D (Andhra Pradesh)",
        OriginCenter: "Kuppam_KrishnagiriRd_D (Andhra Pradesh)",
        FacilityCity: "Kuppam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14626,
        Pin: 412411,
        DispatchCenter: "Alephata_BhrtPetrol_D (Maharashtra)",
        OriginCenter: "Alephata_BhrtPetrol_D (Maharashtra)",
        FacilityCity: "Alephata",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14627,
        Pin: 342010,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14628,
        Pin: 334804,
        DispatchCenter: "Kolayat_Ward3_D (Rajasthan)",
        OriginCenter: "Kolayat_Ward3_D (Rajasthan)",
        FacilityCity: "Kolayat",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14629,
        Pin: 307501,
        DispatchCenter: "AbuRoad_MMS_D (Rajasthan)",
        OriginCenter: "AbuRoad_MMS_D (Rajasthan)",
        FacilityCity: "Abu Road",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14630,
        Pin: 175051,
        DispatchCenter: "Sarkaghat_Khelag_D (Himachal Pradesh)",
        OriginCenter: "Sarkaghat_Khelag_D (Himachal Pradesh)",
        FacilityCity: "Sarkaghat",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14631,
        Pin: 500088,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14632,
        Pin: 333042,
        DispatchCenter: "Nawalgarh_Jhunjhnun_DPP (Rajasthan)",
        OriginCenter: "Nawalgarh_Jhunjhnun_DPP (Rajasthan)",
        FacilityCity: "Nawalgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14633,
        Pin: 394370,
        DispatchCenter: "Rumkitalav_Kukarmunda1_DPP (Gujarat)",
        OriginCenter: "Rumkitalav_Kukarmunda1_DPP (Gujarat)",
        FacilityCity: "Rumkitalav",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14634,
        Pin: 535220,
        DispatchCenter: "Garividi_SriRamNagar_D (Andhra Pradesh)",
        OriginCenter: "Garividi_SriRamNagar_D (Andhra Pradesh)",
        FacilityCity: "Garividi",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14635,
        Pin: 741127,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14636,
        Pin: 110064,
        DispatchCenter: "Delhi_KirtiNagar_L (Delhi)",
        OriginCenter: "Delhi_KirtiNagar_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14637,
        Pin: 621712,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14638,
        Pin: 532218,
        DispatchCenter: "Palasa_Mandapam_D (Andhra Pradesh)",
        OriginCenter: "Palasa_Mandapam_D (Andhra Pradesh)",
        FacilityCity: "Palasa",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14639,
        Pin: 465685,
        DispatchCenter: "Pachore_Bhojpuriya_D (Madhya Pradesh)",
        OriginCenter: "Pachore_Bhojpuriya_D (Madhya Pradesh)",
        FacilityCity: "Pachore",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 14640,
        Pin: 391745,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14641,
        Pin: 670303,
        DispatchCenter: "Kannur_Aduthila_D (Kerala)",
        OriginCenter: "Kannur_Aduthila_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14642,
        Pin: 516444,
        DispatchCenter: "Tadipatri_Kondapuram_D (Andhra Pradesh)",
        OriginCenter: "Tadipatri_Kondapuram_D (Andhra Pradesh)",
        FacilityCity: "Tadipatri",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14643,
        Pin: 425420,
        DispatchCenter: "Amalner_Sriramcolony_DPP (Maharashtra)",
        OriginCenter: "Amalner_Sriramcolony_DPP (Maharashtra)",
        FacilityCity: "Amalner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14644,
        Pin: 362229,
        DispatchCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        OriginCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        FacilityCity: "Keshod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14645,
        Pin: 825320,
        DispatchCenter: "BarkiSaria_KalaliRd_DPP (Jharkhand)",
        OriginCenter: "BarkiSaria_KalaliRd_DPP (Jharkhand)",
        FacilityCity: "Barki Saria",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14646,
        Pin: 600011,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14647,
        Pin: 752108,
        DispatchCenter: "Konark_Central_DPP_2 (Orissa)",
        OriginCenter: "Konark_Central_DPP_2 (Orissa)",
        FacilityCity: "Konark",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14648,
        Pin: 389220,
        DispatchCenter: "Lunawada_VrdhriRD_D (Gujarat)",
        OriginCenter: "Lunawada_VrdhriRD_D (Gujarat)",
        FacilityCity: "Lunawada",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14649,
        Pin: 630405,
        DispatchCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14650,
        Pin: 226026,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14651,
        Pin: 322026,
        DispatchCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        OriginCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        FacilityCity: "Sawai Madhopur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14652,
        Pin: 793101,
        DispatchCenter: "Jorabat_Japesiagn_D (Assam)",
        OriginCenter: "Jorabat_Japesiagn_D (Assam)",
        FacilityCity: "Jorabat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14653,
        Pin: 321601,
        DispatchCenter: "Kherli_Doroli_D (Rajasthan)",
        OriginCenter: "Kherli_Doroli_D (Rajasthan)",
        FacilityCity: "Kherli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14654,
        Pin: 382346,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14655,
        Pin: 303104,
        DispatchCenter: "Achrol_Arvindcolony_D (Rajasthan)",
        OriginCenter: "Achrol_Arvindcolony_D (Rajasthan)",
        FacilityCity: "Achrol",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14656,
        Pin: 202281,
        DispatchCenter: "Aligarh_Atrauli_D (Uttar Pradesh)",
        OriginCenter: "Aligarh_Atrauli_D (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14657,
        Pin: 585213,
        DispatchCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        OriginCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        FacilityCity: "Gulbarga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14658,
        Pin: 440104,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14659,
        Pin: 143304,
        DispatchCenter: "Patti_TarnTaran_D (Punjab)",
        OriginCenter: "Patti_TarnTaran_D (Punjab)",
        FacilityCity: "Patti",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14660,
        Pin: 591315,
        DispatchCenter: "Ugar_GopiHotel_D (Karnataka)",
        OriginCenter: "Ugar_GopiHotel_D (Karnataka)",
        FacilityCity: "Ugar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14661,
        Pin: 571444,
        DispatchCenter: "Hanur_Maruthinagar_D (Karnataka)",
        OriginCenter: "Hanur_Maruthinagar_D (Karnataka)",
        FacilityCity: "Hanur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14662,
        Pin: 531105,
        DispatchCenter: "Balimela_MainRoad_DPP (Orissa)",
        OriginCenter: "Balimela_MainRoad_DPP (Orissa)",
        FacilityCity: "Balimela",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14663,
        Pin: 322254,
        DispatchCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        OriginCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14664,
        Pin: 365601,
        DispatchCenter: "Amreli_Bhojalpara_D (Gujarat)",
        OriginCenter: "Amreli_Bhojalpara_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14665,
        Pin: 451449,
        DispatchCenter: "Sendhwa_JawaharGanj_I (Madhya Pradesh)",
        OriginCenter: "Sendhwa_JawaharGanj_I (Madhya Pradesh)",
        FacilityCity: "Sendhwa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 14666,
        Pin: 682012,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14667,
        Pin: 440022,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14668,
        Pin: 394218,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14669,
        Pin: 321416,
        DispatchCenter: "Bayana_Shivcolony_D (Rajasthan)",
        OriginCenter: "Bayana_Shivcolony_D (Rajasthan)",
        FacilityCity: "Bayana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14670,
        Pin: 641406,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14671,
        Pin: 695562,
        DispatchCenter: "Pangodu_Bharathanoor_D (Kerala)",
        OriginCenter: "Pangodu_Bharathanoor_D (Kerala)",
        FacilityCity: "Pangodu",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14672,
        Pin: 623533,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14673,
        Pin: 415719,
        DispatchCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        OriginCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        FacilityCity: "Shringartali",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14674,
        Pin: 680563,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14675,
        Pin: 572223,
        DispatchCenter: "Tumkur_Doddapete_DPP (Karnataka)",
        OriginCenter: "Tumkur_Doddapete_DPP (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14676,
        Pin: 682031,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14677,
        Pin: 509235,
        DispatchCenter: "Tadoor_Nagarkurnool_D (Telangana)",
        OriginCenter: "Tadoor_Nagarkurnool_D (Telangana)",
        FacilityCity: "Tadoor",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14678,
        Pin: 631006,
        DispatchCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14679,
        Pin: 580024,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14680,
        Pin: 781338,
        DispatchCenter: "Sarthebari_Tmlichp_D (Assam)",
        OriginCenter: "Sarthebari_Tmlichp_D (Assam)",
        FacilityCity: "Sarthebari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14681,
        Pin: 524402,
        DispatchCenter: "Gudur_MalvyaNagar_D (Andhra Pradesh)",
        OriginCenter: "Gudur_MalvyaNagar_D (Andhra Pradesh)",
        FacilityCity: "Gudur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14682,
        Pin: 621113,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14683,
        Pin: 798625,
        DispatchCenter: "Longleng_AgriWard_D (Nagaland)",
        OriginCenter: "Longleng_AgriWard_D (Nagaland)",
        FacilityCity: "Longleng",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14684,
        Pin: 638461,
        DispatchCenter: "Thalavadi_Annanagar_D (Tamil Nadu)",
        OriginCenter: "Thalavadi_Annanagar_D (Tamil Nadu)",
        FacilityCity: "Thalavadi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14685,
        Pin: 670306,
        DispatchCenter: "Nileshwar_Olayambadi_D (Kerala)",
        OriginCenter: "Nileshwar_Olayambadi_D (Kerala)",
        FacilityCity: "Nileshwar",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14686,
        Pin: 609810,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14687,
        Pin: 695604,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14688,
        Pin: 244221,
        DispatchCenter: "Amroha_Kishangarh_D (Uttar Pradesh)",
        OriginCenter: "Amroha_Kishangarh_D (Uttar Pradesh)",
        FacilityCity: "Amroha",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14689,
        Pin: 742168,
        DispatchCenter: "Khargram_Parbatipur_D (West Bengal)",
        OriginCenter: "Khargram_Parbatipur_D (West Bengal)",
        FacilityCity: "Khargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14690,
        Pin: 331302,
        DispatchCenter: "Taranagar_PoliceStation_DPP (Rajasthan)",
        OriginCenter: "Taranagar_PoliceStation_DPP (Rajasthan)",
        FacilityCity: "Taranagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14691,
        Pin: 679351,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14692,
        Pin: 396107,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14693,
        Pin: 209860,
        DispatchCenter: "Unnao_Shekhpur_D (Uttar Pradesh)",
        OriginCenter: "Unnao_Shekhpur_D (Uttar Pradesh)",
        FacilityCity: "Unnao",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14694,
        Pin: 152116,
        DispatchCenter: "Abohar_DaulatPura_D (Punjab)",
        OriginCenter: "Abohar_DaulatPura_D (Punjab)",
        FacilityCity: "Abohar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14695,
        Pin: 431115,
        DispatchCenter: "Aurangabad_Hamalwadi_DPP (Maharashtra)",
        OriginCenter: "Aurangabad_Hamalwadi_DPP (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14696,
        Pin: 631004,
        DispatchCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14697,
        Pin: 577516,
        DispatchCenter: "Davangere_Vidyanagara_D (Karnataka)",
        OriginCenter: "Davangere_Vidyanagara_D (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14698,
        Pin: 754294,
        DispatchCenter: "Jagatsinghpur_Bnbrda_D (Orissa)",
        OriginCenter: "Jagatsinghpur_Bnbrda_D (Orissa)",
        FacilityCity: "Jagatsinghpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14699,
        Pin: 638476,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14700,
        Pin: 787031,
        DispatchCenter: "NorthLakhimpur_Charaimoriya_D (Assam)",
        OriginCenter: "NorthLakhimpur_Charaimoriya_D (Assam)",
        FacilityCity: "North Lakhimpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14701,
        Pin: 713154,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14702,
        Pin: 414112,
        DispatchCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        OriginCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        FacilityCity: "Ahmednagar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14703,
        Pin: 673501,
        DispatchCenter: "Vadakara_Orkkatteri_D (Kerala)",
        OriginCenter: "Vadakara_Orkkatteri_D (Kerala)",
        FacilityCity: "Vadakara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14704,
        Pin: 691013,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14705,
        Pin: 678594,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14706,
        Pin: 632054,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14707,
        Pin: 136044,
        DispatchCenter: "Assandh_Dholchowk_D (Haryana)",
        OriginCenter: "Assandh_Dholchowk_D (Haryana)",
        FacilityCity: "Assandh",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14708,
        Pin: 743121,
        DispatchCenter: "Barrackpore_Lalkuti_D (West Bengal)",
        OriginCenter: "Barrackpore_Lalkuti_D (West Bengal)",
        FacilityCity: "Barrackpore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14709,
        Pin: 685562,
        DispatchCenter: "Adimali_AdimaliMkt_D (Kerala)",
        OriginCenter: "Adimali_AdimaliMkt_D (Kerala)",
        FacilityCity: "Adimali",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14710,
        Pin: 585292,
        DispatchCenter: "Sedam_Venkateshnagar_D (Karnataka)",
        OriginCenter: "Sedam_Venkateshnagar_D (Karnataka)",
        FacilityCity: "Sedam",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14711,
        Pin: 212201,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14712,
        Pin: 160017,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14713,
        Pin: 151208,
        DispatchCenter: "Kotkapura_PremNgr_D (Punjab)",
        OriginCenter: "Kotkapura_PremNgr_D (Punjab)",
        FacilityCity: "Kotkapura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14714,
        Pin: 206003,
        DispatchCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        OriginCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        FacilityCity: "Etawah",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14715,
        Pin: 248110,
        DispatchCenter: "Dehradun_Clement_L (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14716,
        Pin: 770048,
        DispatchCenter: "Koira_Bhanjapali_D (Orissa)",
        OriginCenter: "Koira_Bhanjapali_D (Orissa)",
        FacilityCity: "Koira",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14717,
        Pin: 852127,
        DispatchCenter: "Sonbarsa_Soha_D (Bihar)",
        OriginCenter: "Sonbarsa_Soha_D (Bihar)",
        FacilityCity: "Sonbarsa-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14718,
        Pin: 124111,
        DispatchCenter: "Meham_Ward13_D (Haryana)",
        OriginCenter: "Meham_Ward13_D (Haryana)",
        FacilityCity: "Meham",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14719,
        Pin: 829128,
        DispatchCenter: "Peterbar_GagiDPP_D (Jharkhand)",
        OriginCenter: "Peterbar_GagiDPP_D (Jharkhand)",
        FacilityCity: "Peterbar",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14720,
        Pin: 563125,
        DispatchCenter: "Chintamani_Prabhakarlayout_D (Karnataka)",
        OriginCenter: "Chintamani_Prabhakarlayout_D (Karnataka)",
        FacilityCity: "Chintamani",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14721,
        Pin: 415701,
        DispatchCenter: "Chiplun_Burumtali_D (Maharashtra)",
        OriginCenter: "Chiplun_Burumtali_D (Maharashtra)",
        FacilityCity: "Chiplun",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14722,
        Pin: 227407,
        DispatchCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        OriginCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        FacilityCity: "Amethi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14723,
        Pin: 531021,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14724,
        Pin: 144633,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14725,
        Pin: 416108,
        DispatchCenter: "Jaysingpur_Swapnanagari_D (Maharashtra)",
        OriginCenter: "Jaysingpur_Swapnanagari_D (Maharashtra)",
        FacilityCity: "Jaysingpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14726,
        Pin: 481226,
        DispatchCenter: "Balaghat_Sarekha_D (Madhya Pradesh)",
        OriginCenter: "Balaghat_Sarekha_D (Madhya Pradesh)",
        FacilityCity: "Balaghat",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 14727,
        Pin: 829130,
        DispatchCenter: "Kedla_Ghatotand_D (Jharkhand)",
        OriginCenter: "Kedla_Ghatotand_D (Jharkhand)",
        FacilityCity: "Kedla",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14728,
        Pin: 441614,
        DispatchCenter: "Gondia_Mundipar_I (Maharashtra)",
        OriginCenter: "Gondia_Mundipar_I (Maharashtra)",
        FacilityCity: "Gondia",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14729,
        Pin: 743428,
        DispatchCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        OriginCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        FacilityCity: "Basirhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14730,
        Pin: 848130,
        DispatchCenter: "Tajpur_HsptlRd_D (Bihar)",
        OriginCenter: "Tajpur_HsptlRd_D (Bihar)",
        FacilityCity: "Tajpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14731,
        Pin: 251308,
        DispatchCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        OriginCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        FacilityCity: "Muzaffarnagar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14732,
        Pin: 416632,
        DispatchCenter: "Oras_Ranbambuli_D (Maharashtra)",
        OriginCenter: "Oras_Ranbambuli_D (Maharashtra)",
        FacilityCity: "Oras",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14733,
        Pin: 624216,
        DispatchCenter: "Kodaikanal_Mudliyrpurm_D (Tamil Nadu)",
        OriginCenter: "Kodaikanal_Mudliyrpurm_D (Tamil Nadu)",
        FacilityCity: "Kodaikanal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14734,
        Pin: 517640,
        DispatchCenter: "Sriklahsti_SDKNgr_D (Andhra Pradesh)",
        OriginCenter: "Sriklahsti_SDKNgr_D (Andhra Pradesh)",
        FacilityCity: "Srikalahasti",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14735,
        Pin: 683103,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14736,
        Pin: 203001,
        DispatchCenter: "Bulndshahr_Ymunpurm_D (Uttar Pradesh)",
        OriginCenter: "Bulndshahr_Ymunpurm_D (Uttar Pradesh)",
        FacilityCity: "Bulandshahr",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14737,
        Pin: 700003,
        DispatchCenter: "Kolkata_Bagmari_L (West Bengal)",
        OriginCenter: "Kolkata_Bagmari_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14738,
        Pin: 174001,
        DispatchCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        OriginCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        FacilityCity: "Bilaspur-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14739,
        Pin: 313022,
        DispatchCenter: "Udaipur_Balicha_H (Rajasthan)",
        OriginCenter: "Udaipur_Balicha_H (Rajasthan)",
        FacilityCity: "Udaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14740,
        Pin: 606115,
        DispatchCenter: "Ulundurpet_HarishNgr_D (Tamil Nadu)",
        OriginCenter: "Ulundurpet_HarishNgr_D (Tamil Nadu)",
        FacilityCity: "Ulundurpet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14741,
        Pin: 303504,
        DispatchCenter: "Lalsot_Ward34_D (Rajasthan)",
        OriginCenter: "Lalsot_Ward34_D (Rajasthan)",
        FacilityCity: "Lalsot",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14742,
        Pin: 423106,
        DispatchCenter: "Manmad_Mokalnagar_DPP (Maharashtra)",
        OriginCenter: "Manmad_Mokalnagar_DPP (Maharashtra)",
        FacilityCity: "Manmad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14743,
        Pin: 146109,
        DispatchCenter: "Garhshankar_BangaRoad_D (Punjab)",
        OriginCenter: "Garhshankar_BangaRoad_D (Punjab)",
        FacilityCity: "Garhshanker",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14744,
        Pin: 262905,
        DispatchCenter: "Nighasan_Laudhri_D (Uttar Pradesh)",
        OriginCenter: "Nighasan_Laudhri_D (Uttar Pradesh)",
        FacilityCity: "Nighasan",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14745,
        Pin: 700002,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14746,
        Pin: 210205,
        DispatchCenter: "Chitrakoot_Puranibazar_D (Uttar Pradesh)",
        OriginCenter: "Chitrakoot_Puranibazar_D (Uttar Pradesh)",
        FacilityCity: "Chitrakoot",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14747,
        Pin: 630555,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14748,
        Pin: 272190,
        DispatchCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        OriginCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        FacilityCity: "Basti",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14749,
        Pin: 515621,
        DispatchCenter: "Kalyandurg_Tenagallu_D (Andhra Pradesh)",
        OriginCenter: "Kalyandurg_Tenagallu_D (Andhra Pradesh)",
        FacilityCity: "Kambadur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14750,
        Pin: 521229,
        DispatchCenter: "Mylavaram_NuzviduRd_D (Andhra Pradesh)",
        OriginCenter: "Mylavaram_NuzviduRd_D (Andhra Pradesh)",
        FacilityCity: "Mylavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14751,
        Pin: 670561,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14752,
        Pin: 143508,
        DispatchCenter: "Batala_Simbel_D (Punjab)",
        OriginCenter: "Batala_Simbel_D (Punjab)",
        FacilityCity: "Batala",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14753,
        Pin: 759100,
        DispatchCenter: "Angul_Talcher_D (Orissa)",
        OriginCenter: "Angul_Talcher_D (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14754,
        Pin: 734421,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14755,
        Pin: 732127,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14756,
        Pin: 781378,
        DispatchCenter: "Tihu_Barbari_D (Assam)",
        OriginCenter: "Tihu_Barbari_D (Assam)",
        FacilityCity: "Tihu",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14757,
        Pin: 226030,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14758,
        Pin: 151207,
        DispatchCenter: "Kotkapura_PremNgr_D (Punjab)",
        OriginCenter: "Kotkapura_PremNgr_D (Punjab)",
        FacilityCity: "Kotkapura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14759,
        Pin: 783349,
        DispatchCenter: "Bilasipara_HnumnMndrRd_DPP (Assam)",
        OriginCenter: "Bilasipara_HnumnMndrRd_DPP (Assam)",
        FacilityCity: "Bilasipara",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14760,
        Pin: 416508,
        DispatchCenter: "Halkarni_MajareKarve_D (Maharashtra)",
        OriginCenter: "Halkarni_MajareKarve_D (Maharashtra)",
        FacilityCity: "Halkarni",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14761,
        Pin: 362267,
        DispatchCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        OriginCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        FacilityCity: "Veraval",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14762,
        Pin: 562138,
        DispatchCenter: "Channapatna_2ndCross_D (Karnataka)",
        OriginCenter: "Channapatna_2ndCross_D (Karnataka)",
        FacilityCity: "Channapatna",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14763,
        Pin: 431518,
        DispatchCenter: "Kaij_Knadichowk_DPP (Maharashtra)",
        OriginCenter: "Kaij_Knadichowk_DPP (Maharashtra)",
        FacilityCity: "Kaij",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14764,
        Pin: 834011,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14765,
        Pin: 688566,
        DispatchCenter: "Alappuzha_Nedumbrakkad_D (Kerala)",
        OriginCenter: "Alappuzha_Nedumbrakkad_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14766,
        Pin: 303805,
        DispatchCenter: "Jaipur_VishwakarmaIndArea_L (Rajasthan)",
        OriginCenter: "Jaipur_VishwakarmaIndArea_L (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14767,
        Pin: 700006,
        DispatchCenter: "Kolkata_Bagmari_L (West Bengal)",
        OriginCenter: "Kolkata_Bagmari_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14768,
        Pin: 382404,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14769,
        Pin: 247668,
        DispatchCenter: "Roorkee_Dc (Uttarakhand)",
        OriginCenter: "Roorkee_Dc (Uttarakhand)",
        FacilityCity: "Roorkee",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14770,
        Pin: 360545,
        DispatchCenter: "Porbandar_Vadiplot_D (Gujarat)",
        OriginCenter: "Porbandar_Vadiplot_D (Gujarat)",
        FacilityCity: "Porbandar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14771,
        Pin: 416303,
        DispatchCenter: "Tasgaon_Trimurticolony_D (Maharashtra)",
        OriginCenter: "Tasgaon_Trimurticolony_D (Maharashtra)",
        FacilityCity: "Tasgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14772,
        Pin: 273303,
        DispatchCenter: "Maharajganj_Shikarpur_D (Uttar Pradesh)",
        OriginCenter: "Maharajganj_Shikarpur_D (Uttar Pradesh)",
        FacilityCity: "Maharajganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14773,
        Pin: 642109,
        DispatchCenter: "Pollachi_Kinathukadavu_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Kinathukadavu_D (Tamil Nadu)",
        FacilityCity: "Madukkarai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14774,
        Pin: 813103,
        DispatchCenter: "Banka_Barahat_DPP (Bihar)",
        OriginCenter: "Banka_Barahat_DPP (Bihar)",
        FacilityCity: "Banka",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14775,
        Pin: 797112,
        DispatchCenter: "Dimapur_Sovima_I (Nagaland)",
        OriginCenter: "Dimapur_Sovima_I (Nagaland)",
        FacilityCity: "Dimapur",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14776,
        Pin: 515872,
        DispatchCenter: "Chitradurga_Pota_DPP (Karnataka)",
        OriginCenter: "Chitradurga_Pota_DPP (Karnataka)",
        FacilityCity: "Molakalmuru",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14777,
        Pin: 400103,
        DispatchCenter: "Mumbai_Kandivali_L (Maharashtra)",
        OriginCenter: "Mumbai_Kandivali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14778,
        Pin: 636105,
        DispatchCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        OriginCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        FacilityCity: "Attur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14779,
        Pin: 701111,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14780,
        Pin: 413305,
        DispatchCenter: "Pandharpur_Mangalwedha_D (Maharashtra)",
        OriginCenter: "Pandharpur_Mangalwedha_D (Maharashtra)",
        FacilityCity: "Pandharpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14781,
        Pin: 391445,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14782,
        Pin: 534331,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14783,
        Pin: 691506,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14784,
        Pin: 686633,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14785,
        Pin: 683107,
        DispatchCenter: "Perumbavoor_Vattakattupady_D (Kerala)",
        OriginCenter: "Perumbavoor_Vattakattupady_D (Kerala)",
        FacilityCity: "Perumbavoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14786,
        Pin: 829149,
        DispatchCenter: "Phusro_Gumia_DPP (Jharkhand)",
        OriginCenter: "Phusro_Gumia_DPP (Jharkhand)",
        FacilityCity: "Phusro",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14787,
        Pin: 521120,
        DispatchCenter: "Avanigadda_Ramnagar_D (Andhra Pradesh)",
        OriginCenter: "Avanigadda_Ramnagar_D (Andhra Pradesh)",
        FacilityCity: "Avanigadda",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14788,
        Pin: 609001,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14789,
        Pin: 441121,
        DispatchCenter: "Wardha_Alodi_D (Maharashtra)",
        OriginCenter: "Wardha_Alodi_D (Maharashtra)",
        FacilityCity: "Wardha",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14790,
        Pin: 212402,
        DispatchCenter: "Phulpur_BeerKaji_D (Uttar Pradesh)",
        OriginCenter: "Phulpur_BeerKaji_D (Uttar Pradesh)",
        FacilityCity: "Phulpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14791,
        Pin: 680735,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14792,
        Pin: 731121,
        DispatchCenter: "Bolpur_Surul_DPP (West Bengal)",
        OriginCenter: "Bolpur_Surul_DPP (West Bengal)",
        FacilityCity: "Bolpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14793,
        Pin: 833201,
        DispatchCenter: "Chaibasa_NewClnyTungri_D (Jharkhand)",
        OriginCenter: "Chaibasa_NewClnyTungri_D (Jharkhand)",
        FacilityCity: "Chaibasa",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14794,
        Pin: 244701,
        DispatchCenter: "Milak_Bhaisoori_D (Uttar Pradesh)",
        OriginCenter: "Milak_Bhaisoori_D (Uttar Pradesh)",
        FacilityCity: "Milak",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14795,
        Pin: 670677,
        DispatchCenter: "Chavassery_Peravoor_D (Kerala)",
        OriginCenter: "Chavassery_Peravoor_D (Kerala)",
        FacilityCity: "Chavassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14796,
        Pin: 508113,
        DispatchCenter: "Ramanapeta_Kurmawada_D (Telangana)",
        OriginCenter: "Ramanapeta_Kurmawada_D (Telangana)",
        FacilityCity: "Ramannapeta",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14797,
        Pin: 500092,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14798,
        Pin: 382020,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14799,
        Pin: 532122,
        DispatchCenter: "Rajam_Varalaxminagar_D (Andhra Pradesh)",
        OriginCenter: "Rajam_Varalaxminagar_D (Andhra Pradesh)",
        FacilityCity: "Rajam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14800,
        Pin: 682005,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14801,
        Pin: 382835,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14802,
        Pin: 695030,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14803,
        Pin: 688562,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14804,
        Pin: 691016,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14805,
        Pin: 743384,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14806,
        Pin: 757040,
        DispatchCenter: "Udala_Mayurbhanj_DPP (Orissa)",
        OriginCenter: "Udala_Mayurbhanj_DPP (Orissa)",
        FacilityCity: "Udala",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14807,
        Pin: 209601,
        DispatchCenter: "Farrukhabad_RamBanquet_D (Uttar Pradesh)",
        OriginCenter: "Farrukhabad_RamBanquet_D (Uttar Pradesh)",
        FacilityCity: "Farrukhabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14808,
        Pin: 736207,
        DispatchCenter: "Barobisha_NatAimDPP_D (West Bengal)",
        OriginCenter: "Barobisha_NatAimDPP_D (West Bengal)",
        FacilityCity: "Barobisha",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14809,
        Pin: 400013,
        DispatchCenter: "Mumbai_Sewri_L (Maharashtra)",
        OriginCenter: "Mumbai_Sewri_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14810,
        Pin: 122004,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14811,
        Pin: 843128,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14812,
        Pin: 209732,
        DispatchCenter: "Kannauj_Tirwaganj_D (Uttar Pradesh)",
        OriginCenter: "Kannauj_Tirwaganj_D (Uttar Pradesh)",
        FacilityCity: "Kannauj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14813,
        Pin: 636013,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14814,
        Pin: 508210,
        DispatchCenter: "Nalgonda_Duppalapally_I (Telangana)",
        OriginCenter: "Nalgonda_Duppalapally_I (Telangana)",
        FacilityCity: "Nalgonda",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14815,
        Pin: 283102,
        DispatchCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        OriginCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        FacilityCity: "Agra",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14816,
        Pin: 509132,
        DispatchCenter: "Gadwal_Venkatramana_D (Telangana)",
        OriginCenter: "Gadwal_Venkatramana_D (Telangana)",
        FacilityCity: "Gadwal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14817,
        Pin: 755036,
        DispatchCenter: "Mangalpur_GayatriColony_D (Orissa)",
        OriginCenter: "Mangalpur_GayatriColony_D (Orissa)",
        FacilityCity: "Mangalpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14818,
        Pin: 144210,
        DispatchCenter: "Hoshiarpur_Hariana_D (Punjab)",
        OriginCenter: "Hoshiarpur_Hariana_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14819,
        Pin: 182124,
        DispatchCenter: "Udhampur_Sunal_D (Jammu & Kashmir)",
        OriginCenter: "Udhampur_Sunal_D (Jammu & Kashmir)",
        FacilityCity: "Udhampur",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14820,
        Pin: 713377,
        DispatchCenter: "Dubrajpur_Pandaveswar_D (West Bengal)",
        OriginCenter: "Dubrajpur_Pandaveswar_D (West Bengal)",
        FacilityCity: "Dubrajpur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14821,
        Pin: 171214,
        DispatchCenter: "ChirgaonHP_Sundha_D (Himachal Pradesh)",
        OriginCenter: "ChirgaonHP_Sundha_D (Himachal Pradesh)",
        FacilityCity: "Chirgaon-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14822,
        Pin: 325004,
        DispatchCenter: "Itawa_GentaRd_DPP (Rajasthan)",
        OriginCenter: "Itawa_GentaRd_DPP (Rajasthan)",
        FacilityCity: "Itawa",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14823,
        Pin: 722222,
        DispatchCenter: "Barrackpore_Lalkuti_D (West Bengal)",
        OriginCenter: "Barrackpore_Lalkuti_D (West Bengal)",
        FacilityCity: "Barrackpore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14824,
        Pin: 571217,
        DispatchCenter: "Gonikoppal_Patelnagar_D (Karnataka)",
        OriginCenter: "Gonikoppal_Patelnagar_D (Karnataka)",
        FacilityCity: "Gonikoppal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14825,
        Pin: 125110,
        DispatchCenter: "Sirsa_Housingboardcol_D (Haryana)",
        OriginCenter: "Sirsa_Housingboardcol_D (Haryana)",
        FacilityCity: "Sirsa",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14826,
        Pin: 331021,
        DispatchCenter: "Churu_Stadium_DPP (Rajasthan)",
        OriginCenter: "Churu_Stadium_DPP (Rajasthan)",
        FacilityCity: "Churu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14827,
        Pin: 483334,
        DispatchCenter: "Sihora_Mahavirchk_D (Madhya Pradesh)",
        OriginCenter: "Sihora_Mahavirchk_D (Madhya Pradesh)",
        FacilityCity: "Sihora",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 14828,
        Pin: 686503,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14829,
        Pin: 683548,
        DispatchCenter: "Perumbavoor_Vattakattupady_D (Kerala)",
        OriginCenter: "Perumbavoor_Vattakattupady_D (Kerala)",
        FacilityCity: "Perumbavoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14830,
        Pin: 721130,
        DispatchCenter: "Kolaghat_GopalNgr_DPP (West Bengal)",
        OriginCenter: "Kolaghat_GopalNgr_DPP (West Bengal)",
        FacilityCity: "Kolaghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14831,
        Pin: 683554,
        DispatchCenter: "Perumbavoor_Vattakattupady_D (Kerala)",
        OriginCenter: "Perumbavoor_Vattakattupady_D (Kerala)",
        FacilityCity: "Perumbavoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14832,
        Pin: 185211,
        DispatchCenter: "Poonch_Mendhar_DPP (Jammu & Kashmir)",
        OriginCenter: "Poonch_Mendhar_DPP (Jammu & Kashmir)",
        FacilityCity: "Poonch",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14833,
        Pin: 721666,
        DispatchCenter: "Haldia_Basudevpur_D (West Bengal)",
        OriginCenter: "Haldia_Basudevpur_D (West Bengal)",
        FacilityCity: "Haldia",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14834,
        Pin: 474010,
        DispatchCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        OriginCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        FacilityCity: "Gwalior",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 14835,
        Pin: 208026,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14836,
        Pin: 243005,
        DispatchCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        OriginCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        FacilityCity: "Bareilly",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14837,
        Pin: 577247,
        DispatchCenter: "Shimoga_Bhandrahalli_D (Karnataka)",
        OriginCenter: "Shimoga_Bhandrahalli_D (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14838,
        Pin: 394246,
        DispatchCenter: "Bardoli_GopalNagar_D (Gujarat)",
        OriginCenter: "Bardoli_GopalNagar_D (Gujarat)",
        FacilityCity: "Bardoli",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14839,
        Pin: 828120,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14840,
        Pin: 571427,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14841,
        Pin: 140124,
        DispatchCenter: "AnandpurSahib_Dher_D (Punjab)",
        OriginCenter: "AnandpurSahib_Dher_D (Punjab)",
        FacilityCity: "Anandpur Sahib",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14842,
        Pin: 497557,
        DispatchCenter: "Chirimiri_HaldiBadi_DPP (Chhattisgarh)",
        OriginCenter: "Chirimiri_HaldiBadi_DPP (Chhattisgarh)",
        FacilityCity: "Chirimiri",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14843,
        Pin: 410221,
        DispatchCenter: "Mumbai_Ariwali_L (Maharashtra)",
        OriginCenter: "Mumbai_Ariwali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14844,
        Pin: 509339,
        DispatchCenter: "Kosgi_Chennaram_D (Telangana)",
        OriginCenter: "Kosgi_Chennaram_D (Telangana)",
        FacilityCity: "Kosgi",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14845,
        Pin: 177048,
        DispatchCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        OriginCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        FacilityCity: "Hamirpur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14846,
        Pin: 522312,
        DispatchCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        OriginCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14847,
        Pin: 136034,
        DispatchCenter: "Pehowa_Cheeka_D (Haryana)",
        OriginCenter: "Pehowa_Cheeka_D (Haryana)",
        FacilityCity: "Pehowa",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14848,
        Pin: 457772,
        DispatchCenter: "Petlawad_Unnai_D (Madhya Pradesh)",
        OriginCenter: "Petlawad_Unnai_D (Madhya Pradesh)",
        FacilityCity: "Petlawad",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 14849,
        Pin: 380027,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14850,
        Pin: 752084,
        DispatchCenter: "Daspalla_Andharkota_DPP (Orissa)",
        OriginCenter: "Daspalla_Andharkota_DPP (Orissa)",
        FacilityCity: "Daspalla",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14851,
        Pin: 364240,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14852,
        Pin: 110090,
        DispatchCenter: "Delhi_JhilmilColony_L (Delhi)",
        OriginCenter: "Delhi_JhilmilColony_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14853,
        Pin: 401406,
        DispatchCenter: "Palghar_Maan_L (Maharashtra)",
        OriginCenter: "Palghar_Maan_L (Maharashtra)",
        FacilityCity: "Palghar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14854,
        Pin: 680734,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14855,
        Pin: 480221,
        DispatchCenter: "Amarwara_Ward14_D (Madhya Pradesh)",
        OriginCenter: "Amarwara_Ward14_D (Madhya Pradesh)",
        FacilityCity: "Amarwara",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 14856,
        Pin: 440028,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14857,
        Pin: 224234,
        DispatchCenter: "Faizabad_MayaBazar_D (Uttar Pradesh)",
        OriginCenter: "Faizabad_MayaBazar_D (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14858,
        Pin: 801503,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14859,
        Pin: 327605,
        DispatchCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        OriginCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        FacilityCity: "Sagwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14860,
        Pin: 193404,
        DispatchCenter: "Srinagar_Draripora_DPP (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Draripora_DPP (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14861,
        Pin: 713422,
        DispatchCenter: "Manteswar_MemariRD_D (West Bengal)",
        OriginCenter: "Manteswar_MemariRD_D (West Bengal)",
        FacilityCity: "Manteswar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14862,
        Pin: 625002,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14863,
        Pin: 284135,
        DispatchCenter: "Jhansi_Nandanpura_I (Uttar Pradesh)",
        OriginCenter: "Jhansi_Nandanpura_I (Uttar Pradesh)",
        FacilityCity: "Jhansi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14864,
        Pin: 303331,
        DispatchCenter: "Phulera_NarenaRD_D (Rajasthan)",
        OriginCenter: "Phulera_NarenaRD_D (Rajasthan)",
        FacilityCity: "Phulera",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14865,
        Pin: 625114,
        DispatchCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        OriginCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        FacilityCity: "Melur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14866,
        Pin: 301704,
        DispatchCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        OriginCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        FacilityCity: "Neemrana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14867,
        Pin: 210126,
        DispatchCenter: "HamirpurUP_Maudaha_D (Uttar Pradesh)",
        OriginCenter: "HamirpurUP_Maudaha_D (Uttar Pradesh)",
        FacilityCity: "Hamirpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14868,
        Pin: 173210,
        DispatchCenter: "Dharampur_Kumarhatti_DPP (Himachal Pradesh)",
        OriginCenter: "Dharampur_Kumarhatti_DPP (Himachal Pradesh)",
        FacilityCity: "Dharampur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14869,
        Pin: 370140,
        DispatchCenter: "Bhachau_Mandivichowk_D (Gujarat)",
        OriginCenter: "Bhachau_Mandivichowk_D (Gujarat)",
        FacilityCity: "Bhachau",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14870,
        Pin: 490042,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14871,
        Pin: 621705,
        DispatchCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        OriginCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        FacilityCity: "Ariyalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14872,
        Pin: 458110,
        DispatchCenter: "Manasa_WardNo12_DPP (Madhya Pradesh)",
        OriginCenter: "Manasa_WardNo12_DPP (Madhya Pradesh)",
        FacilityCity: "Manasa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 14873,
        Pin: 679573,
        DispatchCenter: "Tirur_Perunthallur_D (Kerala)",
        OriginCenter: "Tirur_Perunthallur_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14874,
        Pin: 802219,
        DispatchCenter: "Sasaram_Rajpur1_DPP (Bihar)",
        OriginCenter: "Sasaram_Rajpur1_DPP (Bihar)",
        FacilityCity: "Sasaram",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14875,
        Pin: 275302,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14876,
        Pin: 508256,
        DispatchCenter: "Chandur_ShivajiNagar_D (Telangana)",
        OriginCenter: "Chandur_ShivajiNagar_D (Telangana)",
        FacilityCity: "Chandur",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14877,
        Pin: 425504,
        DispatchCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        OriginCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        FacilityCity: "Raver",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14878,
        Pin: 788150,
        DispatchCenter: "Hailakandi_GangprDhmkr_D (Assam)",
        OriginCenter: "Hailakandi_GangprDhmkr_D (Assam)",
        FacilityCity: "Hailakandi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14879,
        Pin: 172107,
        DispatchCenter: "ReckongPeo_Kalpa_D (Himachal Pradesh)",
        OriginCenter: "ReckongPeo_Kalpa_D (Himachal Pradesh)",
        FacilityCity: "Reckong Peo",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14880,
        Pin: 480551,
        DispatchCenter: "Junnardeo_DamuaRd_DPP (Madhya Pradesh)",
        OriginCenter: "Junnardeo_DamuaRd_DPP (Madhya Pradesh)",
        FacilityCity: "Junnardeo",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 14881,
        Pin: 327024,
        DispatchCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        OriginCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        FacilityCity: "Sagwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14882,
        Pin: 623003,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14883,
        Pin: 591128,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14884,
        Pin: 683576,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14885,
        Pin: 626004,
        DispatchCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        OriginCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        FacilityCity: "Aruppukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14886,
        Pin: 751023,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14887,
        Pin: 506103,
        DispatchCenter: "Mahabubabad_SnehaNagar_D (Telangana)",
        OriginCenter: "Mahabubabad_SnehaNagar_D (Telangana)",
        FacilityCity: "Mahabubabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14888,
        Pin: 847239,
        DispatchCenter: "Madhubani_Sagarpur_D (Bihar)",
        OriginCenter: "Madhubani_Sagarpur_D (Bihar)",
        FacilityCity: "Madhubani",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14889,
        Pin: 415207,
        DispatchCenter: "Patan_Ashram_D (Maharashtra)",
        OriginCenter: "Patan_Ashram_D (Maharashtra)",
        FacilityCity: "Patan-MH",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14890,
        Pin: 680012,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14891,
        Pin: 851210,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14892,
        Pin: 624708,
        DispatchCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        OriginCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        FacilityCity: "Vadipatti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14893,
        Pin: 517102,
        DispatchCenter: "Tirupati_Thondavada_D (Andhra Pradesh)",
        OriginCenter: "Tirupati_Thondavada_D (Andhra Pradesh)",
        FacilityCity: "Tirupati",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14894,
        Pin: 625009,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14895,
        Pin: 828109,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14896,
        Pin: 793009,
        DispatchCenter: "Shillong_Mawngap_D (Meghalaya)",
        OriginCenter: "Shillong_Mawngap_D (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14897,
        Pin: 678533,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14898,
        Pin: 679592,
        DispatchCenter: "Valanchery_Central_D_1 (Kerala)",
        OriginCenter: "Valanchery_Central_D_1 (Kerala)",
        FacilityCity: "Valanchery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14899,
        Pin: 582112,
        DispatchCenter: "Gadag_Laxmeshwar_D (Karnataka)",
        OriginCenter: "Gadag_Laxmeshwar_D (Karnataka)",
        FacilityCity: "Gadag",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14900,
        Pin: 713421,
        DispatchCenter: "Jamalpur_HalaraVlg_D (West Bengal)",
        OriginCenter: "Jamalpur_HalaraVlg_D (West Bengal)",
        FacilityCity: "Jamalpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14901,
        Pin: 686105,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14902,
        Pin: 762017,
        DispatchCenter: "Kantamal_Blockoffice_DPP (Orissa)",
        OriginCenter: "Kantamal_Blockoffice_DPP (Orissa)",
        FacilityCity: "Kantamal",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14903,
        Pin: 143044,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14904,
        Pin: 607102,
        DispatchCenter: "Panruti_Thiruvathigai_D (Tamil Nadu)",
        OriginCenter: "Panruti_Thiruvathigai_D (Tamil Nadu)",
        FacilityCity: "Panruti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14905,
        Pin: 689506,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14906,
        Pin: 147301,
        DispatchCenter: "MandiGobindgarh_Nasrali_D (Punjab)",
        OriginCenter: "MandiGobindgarh_Nasrali_D (Punjab)",
        FacilityCity: "Mandi Gobindgarh",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 14907,
        Pin: 517326,
        DispatchCenter: "Madanapalle_RNaiduClny_D (Andhra Pradesh)",
        OriginCenter: "Madanapalle_RNaiduClny_D (Andhra Pradesh)",
        FacilityCity: "Madanapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14908,
        Pin: 470235,
        DispatchCenter: "Kesali_Vivekanandchowk_DPP (Madhya Pradesh)",
        OriginCenter: "Kesali_Vivekanandchowk_DPP (Madhya Pradesh)",
        FacilityCity: "Kesali",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 14909,
        Pin: 802304,
        DispatchCenter: "Arrah_Anaith_D (Bihar)",
        OriginCenter: "Arrah_Anaith_D (Bihar)",
        FacilityCity: "Arrah",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14910,
        Pin: 303904,
        DispatchCenter: "Dudu_Phagi2_D (Rajasthan)",
        OriginCenter: "Dudu_Phagi2_D (Rajasthan)",
        FacilityCity: "Dudu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14911,
        Pin: 445209,
        DispatchCenter: "Pusad_Central_DPP_2 (Maharashtra)",
        OriginCenter: "Pusad_Central_DPP_2 (Maharashtra)",
        FacilityCity: "Pusad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14912,
        Pin: 841435,
        DispatchCenter: "Mairwa_StationRd_D (Bihar)",
        OriginCenter: "Mairwa_StationRd_D (Bihar)",
        FacilityCity: "Mairwa",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14913,
        Pin: 713146,
        DispatchCenter: "Memari_IchprDPP_D (West Bengal)",
        OriginCenter: "Memari_IchprDPP_D (West Bengal)",
        FacilityCity: "Memari",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14914,
        Pin: 341029,
        DispatchCenter: "Nokha_Ward17_D (Rajasthan)",
        OriginCenter: "Nokha_Ward17_D (Rajasthan)",
        FacilityCity: "Nokha",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14915,
        Pin: 670312,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14916,
        Pin: 691318,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14917,
        Pin: 229209,
        DispatchCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        OriginCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        FacilityCity: "Lalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14918,
        Pin: 332027,
        DispatchCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        OriginCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14919,
        Pin: 688014,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14920,
        Pin: 682509,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14921,
        Pin: 271609,
        DispatchCenter: "Balrampur_Utraula_D (Uttar Pradesh)",
        OriginCenter: "Balrampur_Utraula_D (Uttar Pradesh)",
        FacilityCity: "Balrampur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14922,
        Pin: 431601,
        DispatchCenter: "Nanded_Wajegaon_H (Maharashtra)",
        OriginCenter: "Nanded_Wajegaon_H (Maharashtra)",
        FacilityCity: "Nanded",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14923,
        Pin: 454552,
        DispatchCenter: "Maheshwar_DhmnodRd_DPP (Madhya Pradesh)",
        OriginCenter: "Maheshwar_DhmnodRd_DPP (Madhya Pradesh)",
        FacilityCity: "Maheshwar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 14924,
        Pin: 760010,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14925,
        Pin: 242223,
        DispatchCenter: "Shahjahanpur_AvasVikas_D (Uttar Pradesh)",
        OriginCenter: "Shahjahanpur_AvasVikas_D (Uttar Pradesh)",
        FacilityCity: "Shahjahanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14926,
        Pin: 741125,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14927,
        Pin: 502325,
        DispatchCenter: "Hyderabad_Medchal1_L (Telangana)",
        OriginCenter: "Hyderabad_Medchal_GW (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14928,
        Pin: 523305,
        DispatchCenter: "Markapur_Vabbapuram_D (Andhra Pradesh)",
        OriginCenter: "Markapur_Vabbapuram_D (Andhra Pradesh)",
        FacilityCity: "Markapur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14929,
        Pin: 670504,
        DispatchCenter: "Kannur_Aduthila_D (Kerala)",
        OriginCenter: "Kannur_Aduthila_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14930,
        Pin: 424006,
        DispatchCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        OriginCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        FacilityCity: "Dhule",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14931,
        Pin: 638457,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14932,
        Pin: 577142,
        DispatchCenter: "Kalasa_MahaveerRd_DPP (Karnataka)",
        OriginCenter: "Kalasa_MahaveerRd_DPP (Karnataka)",
        FacilityCity: "Kalasa",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14933,
        Pin: 465230,
        DispatchCenter: "Nalkheda_Kanad_D (Madhya Pradesh)",
        OriginCenter: "Nalkheda_Kanad_D (Madhya Pradesh)",
        FacilityCity: "Nalkheda",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 14934,
        Pin: 673509,
        DispatchCenter: "Thalassery_Iyyamkode_D (Kerala)",
        OriginCenter: "Thalassery_Iyyamkode_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14935,
        Pin: 562135,
        DispatchCenter: "Devanahalli_Santhinagar_D (Karnataka)",
        OriginCenter: "Devanahalli_Santhinagar_D (Karnataka)",
        FacilityCity: "Devanahalli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14936,
        Pin: 625207,
        DispatchCenter: "Usilampatti_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Usilampatti_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Usilampatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14937,
        Pin: 673328,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14938,
        Pin: 752111,
        DispatchCenter: "Konark_Central_DPP_2 (Orissa)",
        OriginCenter: "Konark_Central_DPP_2 (Orissa)",
        FacilityCity: "Konark",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14939,
        Pin: 517415,
        DispatchCenter: "Venkatagirikota_Mulbaal_D (Andhra Pradesh)",
        OriginCenter: "Venkatagirikota_Mulbaal_D (Andhra Pradesh)",
        FacilityCity: "Venkatagirikota",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14940,
        Pin: 322212,
        DispatchCenter: "Nadoti_Ibrahimpur_D (Rajasthan)",
        OriginCenter: "Nadoti_Ibrahimpur_D (Rajasthan)",
        FacilityCity: "Nadoti",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14941,
        Pin: 824121,
        DispatchCenter: "AurangabadBR_Katharua_D (Bihar)",
        OriginCenter: "AurangabadBR_Katharua_D (Bihar)",
        FacilityCity: "Aurangabad-BR",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14942,
        Pin: 713149,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14943,
        Pin: 673506,
        DispatchCenter: "Thalassery_Iyyamkode_D (Kerala)",
        OriginCenter: "Thalassery_Iyyamkode_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14944,
        Pin: 591108,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14945,
        Pin: 271203,
        DispatchCenter: "Balrampur_Tulsipur_D (Uttar Pradesh)",
        OriginCenter: "Balrampur_Tulsipur_D (Uttar Pradesh)",
        FacilityCity: "Balrampur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14946,
        Pin: 385506,
        DispatchCenter: "Deesa_CrystalCplx_D (Gujarat)",
        OriginCenter: "Deesa_CrystalCplx_D (Gujarat)",
        FacilityCity: "Deesa",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14947,
        Pin: 403506,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14948,
        Pin: 424105,
        DispatchCenter: "Pachora_AadarshNagar_D (Maharashtra)",
        OriginCenter: "Pachora_AadarshNagar_D (Maharashtra)",
        FacilityCity: "Pachora",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14949,
        Pin: 110506,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14950,
        Pin: 742151,
        DispatchCenter: "Raghunathganj_NH12_D (West Bengal)",
        OriginCenter: "Raghunathganj_NH12_D (West Bengal)",
        FacilityCity: "Raghunathganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14951,
        Pin: 229127,
        DispatchCenter: "Salon_Bazar_DPP (Uttar Pradesh)",
        OriginCenter: "Salon_Bazar_DPP (Uttar Pradesh)",
        FacilityCity: "Salon",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14952,
        Pin: 424103,
        DispatchCenter: "Pachora_Kajgaon_DPP (Maharashtra)",
        OriginCenter: "Pachora_Kajgaon_DPP (Maharashtra)",
        FacilityCity: "Chalisgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14953,
        Pin: 751028,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14954,
        Pin: 411057,
        DispatchCenter: "Pune_Muktainagar_L (Maharashtra)",
        OriginCenter: "Pune_Muktainagar_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14955,
        Pin: 392230,
        DispatchCenter: "Karjan_Junabzr_DC (Gujarat)",
        OriginCenter: "Karjan_Junabzr_DC (Gujarat)",
        FacilityCity: "Karjan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14956,
        Pin: 176096,
        DispatchCenter: "Palampur_Saulbanehr_DPP (Himachal Pradesh)",
        OriginCenter: "Palampur_Saulbanehr_DPP (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14957,
        Pin: 612905,
        DispatchCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        OriginCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        FacilityCity: "Jayankondam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14958,
        Pin: 242226,
        DispatchCenter: "Shahjahanpur_AvasVikas_D (Uttar Pradesh)",
        OriginCenter: "Shahjahanpur_AvasVikas_D (Uttar Pradesh)",
        FacilityCity: "Shahjahanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14959,
        Pin: 403102,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14960,
        Pin: 453771,
        DispatchCenter: "Indore_BarodaArjun_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 14961,
        Pin: 384151,
        DispatchCenter: "Unjha_SatymComplx_D (Gujarat)",
        OriginCenter: "Unjha_SatymComplx_D (Gujarat)",
        FacilityCity: "Unjha",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 14962,
        Pin: 524305,
        DispatchCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        OriginCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14963,
        Pin: 431123,
        DispatchCenter: "Kaij_Knadichowk_DPP (Maharashtra)",
        OriginCenter: "Kaij_Knadichowk_DPP (Maharashtra)",
        FacilityCity: "Kaij",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14964,
        Pin: 334801,
        DispatchCenter: "Bikaner_Deshnok_D (Rajasthan)",
        OriginCenter: "Bikaner_Deshnok_D (Rajasthan)",
        FacilityCity: "Bikaner",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14965,
        Pin: 679551,
        DispatchCenter: "Edappal_Nellissery_D (Kerala)",
        OriginCenter: "Edappal_Nellissery_D (Kerala)",
        FacilityCity: "Edappal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 14966,
        Pin: 734007,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 14967,
        Pin: 141013,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 14968,
        Pin: 505528,
        DispatchCenter: "KarimNagar_Alugunoor_I (Telangana)",
        OriginCenter: "KarimNagar_Alugunoor_I (Telangana)",
        FacilityCity: "Karim Nagar",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14969,
        Pin: 413715,
        DispatchCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        OriginCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        FacilityCity: "Shrirampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14970,
        Pin: 613105,
        DispatchCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        OriginCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        FacilityCity: "Thirukkattupalli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14971,
        Pin: 686614,
        DispatchCenter: "Kanjiramattom_Piravom_D (Kerala)",
        OriginCenter: "Kanjiramattom_Piravom_D (Kerala)",
        FacilityCity: "Kanjiramattom",
        FacilityState: "Kerala",
        Zone : "S2"
      },
      {
        SN: 14972,
        Pin: 571457,
        DispatchCenter: "Hanur_Maruthinagar_D (Karnataka)",
        OriginCenter: "Hanur_Maruthinagar_D (Karnataka)",
        FacilityCity: "Hanur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14973,
        Pin: 713127,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14974,
        Pin: 411009,
        DispatchCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        OriginCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14975,
        Pin: 848117,
        DispatchCenter: "Rosera_HanumanTemple_D (Bihar)",
        OriginCenter: "Rosera_HanumanTemple_D (Bihar)",
        FacilityCity: "Rusera",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14976,
        Pin: 752026,
        DispatchCenter: "Gopalapur_BusStop_DPP (Orissa)",
        OriginCenter: "Gopalapur_BusStop_DPP (Orissa)",
        FacilityCity: "Gopalapur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 14977,
        Pin: 756044,
        DispatchCenter: "Soro_Ashoknagar_D (Orissa)",
        OriginCenter: "Soro_Ashoknagar_D (Orissa)",
        FacilityCity: "Soro",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 14978,
        Pin: 387540,
        DispatchCenter: "Ahmedabad_Kanera_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14979,
        Pin: 413523,
        DispatchCenter: "Ahmedpur_ITIclg_D (Maharashtra)",
        OriginCenter: "Ahmedpur_ITIclg_D (Maharashtra)",
        FacilityCity: "Ahmadpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14980,
        Pin: 445002,
        DispatchCenter: "Yavatmal_JajuDPP_D (Maharashtra)",
        OriginCenter: "Yavatmal_JajuDPP_D (Maharashtra)",
        FacilityCity: "Yavatmal",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 14981,
        Pin: 502375,
        DispatchCenter: "Siddipet_Vemula_D (Telangana)",
        OriginCenter: "Siddipet_Vemula_D (Telangana)",
        FacilityCity: "Siddipet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14982,
        Pin: 365635,
        DispatchCenter: "Savarkundla_Manibhai_DPP (Gujarat)",
        OriginCenter: "Savarkundla_Manibhai_DPP (Gujarat)",
        FacilityCity: "Savarkundla",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 14983,
        Pin: 121004,
        DispatchCenter: "Faridabad_Sector24_L (Haryana)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14984,
        Pin: 206252,
        DispatchCenter: "Bidhuna_BharthnRd_D (Uttar Pradesh)",
        OriginCenter: "Bidhuna_BharthnRd_D (Uttar Pradesh)",
        FacilityCity: "Bidhuna",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14985,
        Pin: 225414,
        DispatchCenter: "Barabanki_Pyaranpur_D (Uttar Pradesh)",
        OriginCenter: "Barabanki_Pyaranpur_D (Uttar Pradesh)",
        FacilityCity: "Haidargarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 14986,
        Pin: 804426,
        DispatchCenter: "Arwal_SipahPnchayt_D (Bihar)",
        OriginCenter: "Arwal_SipahPnchayt_D (Bihar)",
        FacilityCity: "Arwal",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 14987,
        Pin: 583225,
        DispatchCenter: "Hospet_Munirabad_D (Karnataka)",
        OriginCenter: "Hospet_Munirabad_D (Karnataka)",
        FacilityCity: "Hospet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14988,
        Pin: 515110,
        DispatchCenter: "Puttaprthi_Penukonda_D (Andhra Pradesh)",
        OriginCenter: "Puttaprthi_Penukonda_D (Andhra Pradesh)",
        FacilityCity: "Puttaparthi",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14989,
        Pin: 416116,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14990,
        Pin: 626103,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14991,
        Pin: 415109,
        DispatchCenter: "Karad_Malkapur1_D (Maharashtra)",
        OriginCenter: "Karad_Malkapur1_D (Maharashtra)",
        FacilityCity: "Karad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 14992,
        Pin: 630003,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14993,
        Pin: 673303,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14994,
        Pin: 244414,
        DispatchCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        OriginCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        FacilityCity: "Chandausi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 14995,
        Pin: 679502,
        DispatchCenter: "Cherpulassery_PryaPlda_D (Kerala)",
        OriginCenter: "Cherpulassery_PryaPlda_D (Kerala)",
        FacilityCity: "Cherpulassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 14996,
        Pin: 605105,
        DispatchCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        OriginCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        FacilityCity: "Viluppuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 14997,
        Pin: 484336,
        DispatchCenter: "Kotma_BaniyaTola_D (Madhya Pradesh)",
        OriginCenter: "Kotma_BaniyaTola_D (Madhya Pradesh)",
        FacilityCity: "Kotma",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 14998,
        Pin: 627852,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 14999,
        Pin: 422214,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15000,
        Pin: 522113,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15001,
        Pin: 689661,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15002,
        Pin: 248102,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15003,
        Pin: 232327,
        DispatchCenter: "Gahmar_KhuduraPthra_DPP (Uttar Pradesh)",
        OriginCenter: "Gahmar_KhuduraPthra_DPP (Uttar Pradesh)",
        FacilityCity: "Gahmar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15004,
        Pin: 365640,
        DispatchCenter: "Visavadar_Dhari_DPP (Gujarat)",
        OriginCenter: "Visavadar_Dhari_DPP (Gujarat)",
        FacilityCity: "Visavadar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15005,
        Pin: 247121,
        DispatchCenter: "Behat_ChkrtaMrg_D (Uttar Pradesh)",
        OriginCenter: "Behat_ChkrtaMrg_D (Uttar Pradesh)",
        FacilityCity: "Behat",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15006,
        Pin: 175123,
        DispatchCenter: "Banjar_Pujaliroad_D (Himachal Pradesh)",
        OriginCenter: "Banjar_Pujaliroad_D (Himachal Pradesh)",
        FacilityCity: "Banjar",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15007,
        Pin: 713215,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15008,
        Pin: 691334,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15009,
        Pin: 535216,
        DispatchCenter: "Bhogapuram_Thotaveedhi_D (Andhra Pradesh)",
        OriginCenter: "Bhogapuram_Thotaveedhi_D (Andhra Pradesh)",
        FacilityCity: "Bhogapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15010,
        Pin: 689110,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15011,
        Pin: 731243,
        DispatchCenter: "Rampurhat_Mansubamore_D (West Bengal)",
        OriginCenter: "Rampurhat_Mansubamore_D (West Bengal)",
        FacilityCity: "Rampurhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15012,
        Pin: 282006,
        DispatchCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        OriginCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        FacilityCity: "Agra",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15013,
        Pin: 243635,
        DispatchCenter: "Budaun_Dataganj_D (Uttar Pradesh)",
        OriginCenter: "Budaun_Dataganj_D (Uttar Pradesh)",
        FacilityCity: "Dataganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15014,
        Pin: 680502,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15015,
        Pin: 402601,
        DispatchCenter: "Shrigonda_Adhalgaon_D (Maharashtra)",
        OriginCenter: "Shrigonda_Adhalgaon_D (Maharashtra)",
        FacilityCity: "Shrigonda",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15016,
        Pin: 205304,
        DispatchCenter: "Kishni_Shivmndr_D (Uttar Pradesh)",
        OriginCenter: "Kishni_Shivmndr_D (Uttar Pradesh)",
        FacilityCity: "Kishni",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15017,
        Pin: 628204,
        DispatchCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        OriginCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        FacilityCity: "Tiruchendur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15018,
        Pin: 676508,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15019,
        Pin: 788156,
        DispatchCenter: "Hailakandi_GangprDhmkr_D (Assam)",
        OriginCenter: "Hailakandi_GangprDhmkr_D (Assam)",
        FacilityCity: "Hailakandi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15020,
        Pin: 768106,
        DispatchCenter: "Rairakhol_KachariRd_DPP (Orissa)",
        OriginCenter: "Rairakhol_KachariRd_DPP (Orissa)",
        FacilityCity: "Rairakhol",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15021,
        Pin: 488059,
        DispatchCenter: "Pawai_Gunaur_DPP (Madhya Pradesh)",
        OriginCenter: "Pawai_Gunaur_DPP (Madhya Pradesh)",
        FacilityCity: "Pawai",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 15022,
        Pin: 713141,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15023,
        Pin: 713514,
        DispatchCenter: "Katwa_Panchghara_D (West Bengal)",
        OriginCenter: "Katwa_Panchghara_D (West Bengal)",
        FacilityCity: "Katwa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15024,
        Pin: 144020,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15025,
        Pin: 821302,
        DispatchCenter: "Rohtas_Sundarganj_D (Bihar)",
        OriginCenter: "Rohtas_Sundarganj_D (Bihar)",
        FacilityCity: "Rohtas",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15026,
        Pin: 641667,
        DispatchCenter: "Palladam_Balajinagar_D (Tamil Nadu)",
        OriginCenter: "Palladam_Balajinagar_D (Tamil Nadu)",
        FacilityCity: "Palladam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15027,
        Pin: 500013,
        DispatchCenter: "Hyderabad_HemaNagar_L (Telangana)",
        OriginCenter: "Hyderabad_HemaNagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15028,
        Pin: 444502,
        DispatchCenter: "Akola_Midcphase2_D (Maharashtra)",
        OriginCenter: "Akola_Midcphase2_D (Maharashtra)",
        FacilityCity: "Akola",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15029,
        Pin: 624053,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15030,
        Pin: 784167,
        DispatchCenter: "Biswanath_CharialiLIC_D (Assam)",
        OriginCenter: "Biswanath_CharialiLIC_D (Assam)",
        FacilityCity: "BiswanathCH",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15031,
        Pin: 767021,
        DispatchCenter: "Dunguripali_Badnuapali_D (Orissa)",
        OriginCenter: "Dunguripali_Badnuapali_D (Orissa)",
        FacilityCity: "Dunguripali",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15032,
        Pin: 364740,
        DispatchCenter: "Dhasa_Nolipara_D (Gujarat)",
        OriginCenter: "Dhasa_Nolipara_D (Gujarat)",
        FacilityCity: "Dhasa",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15033,
        Pin: 679532,
        DispatchCenter: "Shoranur_Akamala_D (Kerala)",
        OriginCenter: "Shoranur_Akamala_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15034,
        Pin: 122098,
        DispatchCenter: "Gurgaon_Sector52_L (Haryana)",
        OriginCenter: "Gurgaon_Sector52_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15035,
        Pin: 572140,
        DispatchCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        OriginCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15036,
        Pin: 757021,
        DispatchCenter: "Baripada_KalikDPP_D (Orissa)",
        OriginCenter: "Baripada_KalikDPP_D (Orissa)",
        FacilityCity: "Baripada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15037,
        Pin: 431804,
        DispatchCenter: "Kinwat_Mahurrd_D (Maharashtra)",
        OriginCenter: "Kinwat_Mahurrd_D (Maharashtra)",
        FacilityCity: "Kinwat",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15038,
        Pin: 184205,
        DispatchCenter: "Billawar_Chohan_DPP (Jammu & Kashmir)",
        OriginCenter: "Billawar_Chohan_DPP (Jammu & Kashmir)",
        FacilityCity: "Billawar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15039,
        Pin: 360021,
        DispatchCenter: "Rajkot_Amargadh_H (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15040,
        Pin: 517641,
        DispatchCenter: "Sriklahsti_SDKNgr_D (Andhra Pradesh)",
        OriginCenter: "Sriklahsti_SDKNgr_D (Andhra Pradesh)",
        FacilityCity: "Srikalahasti",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15041,
        Pin: 132151,
        DispatchCenter: "Panipat_Risalu_P (Haryana)",
        OriginCenter: "Panipat_Risalu_P (Haryana)",
        FacilityCity: "Panipat",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15042,
        Pin: 301409,
        DispatchCenter: "RajgarhRJ_KarothRd_D (Rajasthan)",
        OriginCenter: "RajgarhRJ_KarothRd_D (Rajasthan)",
        FacilityCity: "Rajgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15043,
        Pin: 325201,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15044,
        Pin: 521327,
        DispatchCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        OriginCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        FacilityCity: "Gudivada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15045,
        Pin: 143009,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15046,
        Pin: 395010,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15047,
        Pin: 781037,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15048,
        Pin: 212601,
        DispatchCenter: "Fatehpur_Radhanagar_D (Uttar Pradesh)",
        OriginCenter: "Fatehpur_Radhanagar_D (Uttar Pradesh)",
        FacilityCity: "Fatehpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15049,
        Pin: 689711,
        DispatchCenter: "Ranni_Chethakhl_D (Kerala)",
        OriginCenter: "Ranni_Chethakhl_D (Kerala)",
        FacilityCity: "Ranni",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15050,
        Pin: 505305,
        DispatchCenter: "Sircilla_Gollapalli_D (Telangana)",
        OriginCenter: "Sircilla_Gollapalli_D (Telangana)",
        FacilityCity: "Yellareddipet",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15051,
        Pin: 233002,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15052,
        Pin: 761133,
        DispatchCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        OriginCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        FacilityCity: "Bhanjanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15053,
        Pin: 415114,
        DispatchCenter: "Karad_Malkapur1_D (Maharashtra)",
        OriginCenter: "Karad_Malkapur1_D (Maharashtra)",
        FacilityCity: "Karad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15054,
        Pin: 785640,
        DispatchCenter: "Sivasagar_Pdinnagar_D (Assam)",
        OriginCenter: "Sivasagar_Pdinnagar_D (Assam)",
        FacilityCity: "Sivasagar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15055,
        Pin: 210120,
        DispatchCenter: "Atarra_GangehiRd_D (Uttar Pradesh)",
        OriginCenter: "Atarra_GangehiRd_D (Uttar Pradesh)",
        FacilityCity: "Atarra",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15056,
        Pin: 523227,
        DispatchCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        OriginCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        FacilityCity: "Kanigiri",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15057,
        Pin: 801113,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_PhulwariSharif_L (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15058,
        Pin: 571215,
        DispatchCenter: "Gonikoppal_Patelnagar_D (Karnataka)",
        OriginCenter: "Gonikoppal_Patelnagar_D (Karnataka)",
        FacilityCity: "Gonikoppal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15059,
        Pin: 786190,
        DispatchCenter: "DoomDooma_Pthrgoan_D (Assam)",
        OriginCenter: "DoomDooma_Pthrgoan_D (Assam)",
        FacilityCity: "Doom Dooma",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15060,
        Pin: 444401,
        DispatchCenter: "Akola_Midcphase3_H (Maharashtra)",
        OriginCenter: "Akola_Midcphase3_H (Maharashtra)",
        FacilityCity: "Akola",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15061,
        Pin: 585320,
        DispatchCenter: "Sedam_Venkateshnagar_D (Karnataka)",
        OriginCenter: "Sedam_Venkateshnagar_D (Karnataka)",
        FacilityCity: "Sedam",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15062,
        Pin: 503001,
        DispatchCenter: "Nizamabad_Sarangapur_I (Telangana)",
        OriginCenter: "Nizamabad_Sarangapur_I (Telangana)",
        FacilityCity: "Nizamabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15063,
        Pin: 560060,
        DispatchCenter: "Bangalore_FCILayout_L (Karnataka)",
        OriginCenter: "Bangalore_FCILayout_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15064,
        Pin: 620011,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15065,
        Pin: 531024,
        DispatchCenter: "Paderu_MainRd_DPP (Andhra Pradesh)",
        OriginCenter: "Paderu_MainRd_DPP (Andhra Pradesh)",
        FacilityCity: "Paderu",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15066,
        Pin: 686693,
        DispatchCenter: "Perumbavoor_Kizhake_D (Kerala)",
        OriginCenter: "Perumbavoor_Kizhake_D (Kerala)",
        FacilityCity: "Perumbavoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15067,
        Pin: 303340,
        DispatchCenter: "Phulera_NarenaRD_D (Rajasthan)",
        OriginCenter: "Phulera_NarenaRD_D (Rajasthan)",
        FacilityCity: "Phulera",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15068,
        Pin: 680651,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15069,
        Pin: 754025,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15070,
        Pin: 450554,
        DispatchCenter: "Sanawad_Azadnagar_DPP (Madhya Pradesh)",
        OriginCenter: "Sanawad_Azadnagar_DPP (Madhya Pradesh)",
        FacilityCity: "Sanawad",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 15071,
        Pin: 532426,
        DispatchCenter: "Narasannapeta_SridviNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasannapeta_SridviNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasannapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15072,
        Pin: 360034,
        DispatchCenter: "Rajkot_Amargadh_H (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15073,
        Pin: 284306,
        DispatchCenter: "Poonchh_AranchRd_D (Uttar Pradesh)",
        OriginCenter: "Poonchh_AranchRd_D (Uttar Pradesh)",
        FacilityCity: "Poonchh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15074,
        Pin: 263149,
        DispatchCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        OriginCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        FacilityCity: "Rudrapur",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15075,
        Pin: 788735,
        DispatchCenter: "Ramkrishnanagar_Kalinagar_D (Assam)",
        OriginCenter: "Ramkrishnanagar_Kalinagar_D (Assam)",
        FacilityCity: "Ramkrishna Nagar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15076,
        Pin: 362003,
        DispatchCenter: "Junagadh_Dolatpara_I (Gujarat)",
        OriginCenter: "Junagadh_Dolatpara_I (Gujarat)",
        FacilityCity: "Junagadh",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15077,
        Pin: 686003,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15078,
        Pin: 679341,
        DispatchCenter: "Thachnttukra_Mangadn_D (Kerala)",
        OriginCenter: "Thachnttukra_Mangadn_D (Kerala)",
        FacilityCity: "Thachanattukara",
        FacilityState: "Kerala",
        Zone : "S2"
      },
      {
        SN: 15079,
        Pin: 641029,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15080,
        Pin: 321402,
        DispatchCenter: "Bayana_Shivcolony_D (Rajasthan)",
        OriginCenter: "Bayana_Shivcolony_D (Rajasthan)",
        FacilityCity: "Bayana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15081,
        Pin: 712222,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15082,
        Pin: 581357,
        DispatchCenter: "Ankola_Baleguli_D (Karnataka)",
        OriginCenter: "Ankola_Baleguli_D (Karnataka)",
        FacilityCity: "Ankola",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15083,
        Pin: 600132,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15084,
        Pin: 380058,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15085,
        Pin: 490024,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15086,
        Pin: 785615,
        DispatchCenter: "Bokakhat_Kumaraniati_D (Assam)",
        OriginCenter: "Bokakhat_Kumaraniati_D (Assam)",
        FacilityCity: "Bokakhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15087,
        Pin: 533003,
        DispatchCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        OriginCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        FacilityCity: "Kakinada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15088,
        Pin: 394327,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15089,
        Pin: 689105,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15090,
        Pin: 486888,
        DispatchCenter: "Singrauli_Waidhan2_D (Madhya Pradesh)",
        OriginCenter: "Singrauli_Waidhan2_D (Madhya Pradesh)",
        FacilityCity: "Singrauli",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 15091,
        Pin: 743395,
        DispatchCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        OriginCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        FacilityCity: "Dakshin Barasat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15092,
        Pin: 695014,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15093,
        Pin: 502113,
        DispatchCenter: "Medak_RTCclny_D (Telangana)",
        OriginCenter: "Medak_RTCclny_D (Telangana)",
        FacilityCity: "Medak",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15094,
        Pin: 560083,
        DispatchCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        OriginCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15095,
        Pin: 785695,
        DispatchCenter: "Sonari_Nakachari_D (Assam)",
        OriginCenter: "Sonari_Nakachari_D (Assam)",
        FacilityCity: "Sonari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15096,
        Pin: 314025,
        DispatchCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        OriginCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        FacilityCity: "Sagwara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15097,
        Pin: 628616,
        DispatchCenter: "Eral_Nazareth_D (Tamil Nadu)",
        OriginCenter: "Eral_Nazareth_D (Tamil Nadu)",
        FacilityCity: "Eral",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15098,
        Pin: 134202,
        DispatchCenter: "Naraingarh_KrishnaColony_DPP (Haryana)",
        OriginCenter: "Naraingarh_KrishnaColony_DPP (Haryana)",
        FacilityCity: "Naraingarh",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15099,
        Pin: 201311,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15100,
        Pin: 532185,
        DispatchCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        OriginCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        FacilityCity: "Srikakulam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15101,
        Pin: 573116,
        DispatchCenter: "Chnnryptna_MysoreRd_D (Karnataka)",
        OriginCenter: "Chnnryptna_MysoreRd_D (Karnataka)",
        FacilityCity: "Channarayapatna",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15102,
        Pin: 638703,
        DispatchCenter: "Dharapuram_Pirivu_D (Tamil Nadu)",
        OriginCenter: "Dharapuram_Pirivu_D (Tamil Nadu)",
        FacilityCity: "Dharapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15103,
        Pin: 737102,
        DispatchCenter: "Gangtok_Tadong_D (Sikkim)",
        OriginCenter: "Gangtok_Tadong_D (Sikkim)",
        FacilityCity: "Gangtok",
        FacilityState: "Sikkim",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15104,
        Pin: 788151,
        DispatchCenter: "Hailakandi_GangprDhmkr_D (Assam)",
        OriginCenter: "Hailakandi_GangprDhmkr_D (Assam)",
        FacilityCity: "Hailakandi",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15105,
        Pin: 311405,
        DispatchCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        OriginCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        FacilityCity: "Bhilwara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15106,
        Pin: 832102,
        DispatchCenter: "Ghatshila_Ekramcolony_D (Jharkhand)",
        OriginCenter: "Ghatshila_Ekramcolony_D (Jharkhand)",
        FacilityCity: "Ghatshila",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15107,
        Pin: 643237,
        DispatchCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        OriginCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        FacilityCity: "Gudalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15108,
        Pin: 759016,
        DispatchCenter: "Bhuban_Sadangi_D (Orissa)",
        OriginCenter: "Bhuban_Sadangi_D (Orissa)",
        FacilityCity: "Bhuban",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15109,
        Pin: 148022,
        DispatchCenter: "Malerkotla_Sajidacolony_D (Punjab)",
        OriginCenter: "Malerkotla_Sajidacolony_D (Punjab)",
        FacilityCity: "Malerkotla",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15110,
        Pin: 625537,
        DispatchCenter: "Usilampatti_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Usilampatti_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Usilampatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15111,
        Pin: 784026,
        DispatchCenter: "Tezpur_Dipota_H (Assam)",
        OriginCenter: "Tezpur_Dipota_H (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15112,
        Pin: 823004,
        DispatchCenter: "Gaya_Matihani_H (Bihar)",
        OriginCenter: "Gaya_Matihani_H (Bihar)",
        FacilityCity: "Gaya",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15113,
        Pin: 421202,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15114,
        Pin: 493890,
        DispatchCenter: "Gohrapadar_Chhaildongri_DPP (Chhattisgarh)",
        OriginCenter: "Gohrapadar_Chhaildongri_DPP (Chhattisgarh)",
        FacilityCity: "Gohrapadar",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15115,
        Pin: 424302,
        DispatchCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        OriginCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        FacilityCity: "Dhule",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15116,
        Pin: 125052,
        DispatchCenter: "Hisar_Dabra_H (Haryana)",
        OriginCenter: "Hisar_Dabra_H (Haryana)",
        FacilityCity: "Hisar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15117,
        Pin: 416426,
        DispatchCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        OriginCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        FacilityCity: "Miraj",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15118,
        Pin: 712134,
        DispatchCenter: "Memari_IchprDPP_D (West Bengal)",
        OriginCenter: "Memari_IchprDPP_D (West Bengal)",
        FacilityCity: "Memari",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15119,
        Pin: 627004,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15120,
        Pin: 847301,
        DispatchCenter: "KalyanpurBR_Ladaura_D (Bihar)",
        OriginCenter: "KalyanpurBR_Ladaura_D (Bihar)",
        FacilityCity: "KalyanpurBR",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15121,
        Pin: 764087,
        DispatchCenter: "Kalimela_Blockoffice_D (Orissa)",
        OriginCenter: "Kalimela_Blockoffice_D (Orissa)",
        FacilityCity: "Kalimela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15122,
        Pin: 624302,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15123,
        Pin: 799278,
        DispatchCenter: "Aambasa_Natunpalli_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Aambasa",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15124,
        Pin: 758031,
        DispatchCenter: "Kendujhar_Jhumpura_D (Orissa)",
        OriginCenter: "Kendujhar_Jhumpura_D (Orissa)",
        FacilityCity: "Kendujhar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15125,
        Pin: 484555,
        DispatchCenter: "PaliBirsinghpur_Goraiya_D (Madhya Pradesh)",
        OriginCenter: "PaliBirsinghpur_Goraiya_D (Madhya Pradesh)",
        FacilityCity: "Pali Birsinghpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 15126,
        Pin: 143204,
        DispatchCenter: "Tarsikka_Beas_D (Punjab)",
        OriginCenter: "Tarsikka_Beas_D (Punjab)",
        FacilityCity: "Beas",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15127,
        Pin: 521126,
        DispatchCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        OriginCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        FacilityCity: "Machilipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15128,
        Pin: 741247,
        DispatchCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        OriginCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        FacilityCity: "Ranaghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15129,
        Pin: 623521,
        DispatchCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        OriginCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        FacilityCity: "Rameswaram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15130,
        Pin: 851113,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15131,
        Pin: 532474,
        DispatchCenter: "Narasannapeta_SridviNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasannapeta_SridviNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasannapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15132,
        Pin: 786187,
        DispatchCenter: "Margherita_CivilHPL_D (Assam)",
        OriginCenter: "Margherita_CivilHPL_D (Assam)",
        FacilityCity: "Margherita",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15133,
        Pin: 679582,
        DispatchCenter: "Edappal_Nellissery_D (Kerala)",
        OriginCenter: "Edappal_Nellissery_D (Kerala)",
        FacilityCity: "Edappal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15134,
        Pin: 535145,
        DispatchCenter: "SKota_VizainagaramRd_DPP (Andhra Pradesh)",
        OriginCenter: "SKota_VizainagaramRd_DPP (Andhra Pradesh)",
        FacilityCity: "S Kota",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15135,
        Pin: 466113,
        DispatchCenter: "Ashta_Saicolony_D (Madhya Pradesh)",
        OriginCenter: "Ashta_Saicolony_D (Madhya Pradesh)",
        FacilityCity: "Ashta",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 15136,
        Pin: 534197,
        DispatchCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        OriginCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        FacilityCity: "Tadepalligudem",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15137,
        Pin: 841505,
        DispatchCenter: "Kuchaikote_Bhathwaparshuram_D (Bihar)",
        OriginCenter: "Kuchaikote_Bhathwaparshuram_D (Bihar)",
        FacilityCity: "Kuchaikote",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15138,
        Pin: 636002,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15139,
        Pin: 481663,
        DispatchCenter: "Ghugri_Ward3_DPP (Madhya Pradesh)",
        OriginCenter: "Ghugri_Ward3_DPP (Madhya Pradesh)",
        FacilityCity: "Ghugri",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 15140,
        Pin: 686580,
        DispatchCenter: "Pala_Elappunkal_D (Kerala)",
        OriginCenter: "Pala_Elappunkal_D (Kerala)",
        FacilityCity: "Pala",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15141,
        Pin: 506315,
        DispatchCenter: "Danthalapally_AmbdkrSt_D (Telangana)",
        OriginCenter: "Danthalapally_AmbdkrSt_D (Telangana)",
        FacilityCity: "Danthalapally",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15142,
        Pin: 711303,
        DispatchCenter: "Howrah_Temp_L (West Bengal)",
        OriginCenter: "Howrah_Temp_L (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15143,
        Pin: 517569,
        DispatchCenter: "Pakala_Gorpadu_D (Andhra Pradesh)",
        OriginCenter: "Pakala_Gorpadu_D (Andhra Pradesh)",
        FacilityCity: "Pakala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15144,
        Pin: 812007,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15145,
        Pin: 464661,
        DispatchCenter: "Raisen_Gopalpur_DPP (Madhya Pradesh)",
        OriginCenter: "Raisen_Gopalpur_DPP (Madhya Pradesh)",
        FacilityCity: "Raisen",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 15146,
        Pin: 301406,
        DispatchCenter: "RajgarhRJ_KarothRd_D (Rajasthan)",
        OriginCenter: "RajgarhRJ_KarothRd_D (Rajasthan)",
        FacilityCity: "Rajgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15147,
        Pin: 832108,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15148,
        Pin: 387421,
        DispatchCenter: "Nadiad_Shantinagar_D (Gujarat)",
        OriginCenter: "Nadiad_Shantinagar_D (Gujarat)",
        FacilityCity: "Nadiad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15149,
        Pin: 584147,
        DispatchCenter: "Manvi_Jayanagar_D (Karnataka)",
        OriginCenter: "Manvi_Jayanagar_D (Karnataka)",
        FacilityCity: "Manvi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15150,
        Pin: 841216,
        DispatchCenter: "Dighwara_AmbikaSchl_D (Bihar)",
        OriginCenter: "Dighwara_AmbikaSchl_D (Bihar)",
        FacilityCity: "Dighwara",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15151,
        Pin: 444604,
        DispatchCenter: "Amravati_Changapur_D (Maharashtra)",
        OriginCenter: "Amravati_Changapur_D (Maharashtra)",
        FacilityCity: "Amravati",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15152,
        Pin: 814118,
        DispatchCenter: "Deoghar_Taljhari_D (Jharkhand)",
        OriginCenter: "Deoghar_Taljhari_D (Jharkhand)",
        FacilityCity: "Deoghar",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15153,
        Pin: 464551,
        DispatchCenter: "Raisen_Gopalpur_DPP (Madhya Pradesh)",
        OriginCenter: "Raisen_Gopalpur_DPP (Madhya Pradesh)",
        FacilityCity: "Raisen",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 15154,
        Pin: 848505,
        DispatchCenter: "Tajpur_HsptlRd_D (Bihar)",
        OriginCenter: "Tajpur_HsptlRd_D (Bihar)",
        FacilityCity: "Tajpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15155,
        Pin: 627113,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15156,
        Pin: 576225,
        DispatchCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        OriginCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15157,
        Pin: 753027,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15158,
        Pin: 632517,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15159,
        Pin: 689587,
        DispatchCenter: "Karukachal_Nedumkunam_D (Kerala)",
        OriginCenter: "Karukachal_Nedumkunam_D (Kerala)",
        FacilityCity: "Karukachal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15160,
        Pin: 246449,
        DispatchCenter: "Chamoli_CGRoad_D (Uttarakhand)",
        OriginCenter: "Chamoli_CGRoad_D (Uttarakhand)",
        FacilityCity: "Chamoli",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15161,
        Pin: 679576,
        DispatchCenter: "Edappal_Nellissery_D (Kerala)",
        OriginCenter: "Edappal_Nellissery_D (Kerala)",
        FacilityCity: "Edappal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15162,
        Pin: 416144,
        DispatchCenter: "Sangli_Dattanagar_D (Maharashtra)",
        OriginCenter: "Sangli_Dattanagar_D (Maharashtra)",
        FacilityCity: "Sangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15163,
        Pin: 573111,
        DispatchCenter: "Chnnryptna_MysoreRd_D (Karnataka)",
        OriginCenter: "Chnnryptna_MysoreRd_D (Karnataka)",
        FacilityCity: "Channarayapatna",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15164,
        Pin: 341318,
        DispatchCenter: "KhatuKhurd_MakranaRd_D (Rajasthan)",
        OriginCenter: "KhatuKhurd_MakranaRd_D (Rajasthan)",
        FacilityCity: "Khatu Khurd",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15165,
        Pin: 231209,
        DispatchCenter: "Robertsganj_Pakari_D (Uttar Pradesh)",
        OriginCenter: "Robertsganj_Pakari_D (Uttar Pradesh)",
        FacilityCity: "Robertsganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15166,
        Pin: 712232,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15167,
        Pin: 847229,
        DispatchCenter: "Benipatti_Hathhapurparsa_D (Bihar)",
        OriginCenter: "Benipatti_Hathhapurparsa_D (Bihar)",
        FacilityCity: "Benipatti",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15168,
        Pin: 695026,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15169,
        Pin: 132023,
        DispatchCenter: "Karnal_Kutail_H (Haryana)",
        OriginCenter: "Karnal_Kutail_H (Haryana)",
        FacilityCity: "Karnal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15170,
        Pin: 802212,
        DispatchCenter: "Bikramgang_Dehrird_D (Bihar)",
        OriginCenter: "Bikramgang_Dehrird_D (Bihar)",
        FacilityCity: "Bikramganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15171,
        Pin: 387510,
        DispatchCenter: "Nadiad_Shantinagar_D (Gujarat)",
        OriginCenter: "Nadiad_Shantinagar_D (Gujarat)",
        FacilityCity: "Nadiad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15172,
        Pin: 754014,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15173,
        Pin: 208001,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15174,
        Pin: 533001,
        DispatchCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        OriginCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        FacilityCity: "Kakinada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15175,
        Pin: 643241,
        DispatchCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        OriginCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        FacilityCity: "Gudalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15176,
        Pin: 673005,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15177,
        Pin: 622101,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15178,
        Pin: 431125,
        DispatchCenter: "Beed_Jarud_D (Maharashtra)",
        OriginCenter: "Beed_Jarud_D (Maharashtra)",
        FacilityCity: "Beed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15179,
        Pin: 803123,
        DispatchCenter: "Chandi_SH78_D (Bihar)",
        OriginCenter: "Chandi_SH78_D (Bihar)",
        FacilityCity: "Chandi",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15180,
        Pin: 524322,
        DispatchCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        OriginCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        FacilityCity: "Atmakur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15181,
        Pin: 563146,
        DispatchCenter: "Chintamani_Prabhakarlayout_D (Karnataka)",
        OriginCenter: "Chintamani_Prabhakarlayout_D (Karnataka)",
        FacilityCity: "Chintamani",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15182,
        Pin: 786154,
        DispatchCenter: "DoomDooma_Kakopathar_D (Assam)",
        OriginCenter: "DoomDooma_Kakopathar_D (Assam)",
        FacilityCity: "Doom Dooma",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15183,
        Pin: 581358,
        DispatchCenter: "Sirsi_Kasturbanagar_D (Karnataka)",
        OriginCenter: "Sirsi_Kasturbanagar_D (Karnataka)",
        FacilityCity: "Sirsi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15184,
        Pin: 311605,
        DispatchCenter: "Mandalgarh_Vakilcolony_D (Rajasthan)",
        OriginCenter: "Mandalgarh_Vakilcolony_D (Rajasthan)",
        FacilityCity: "Mandalgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15185,
        Pin: 690001,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15186,
        Pin: 582102,
        DispatchCenter: "Gadag_Vidyanagar_D (Karnataka)",
        OriginCenter: "Gadag_Vidyanagar_D (Karnataka)",
        FacilityCity: "Gadag",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15187,
        Pin: 392115,
        DispatchCenter: "Karjan_Junabzr_DC (Gujarat)",
        OriginCenter: "Karjan_Junabzr_DC (Gujarat)",
        FacilityCity: "Karjan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15188,
        Pin: 788702,
        DispatchCenter: "Karimganj_Gorkapan_D (Assam)",
        OriginCenter: "Karimganj_Gorkapan_D (Assam)",
        FacilityCity: "Karimganj",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15189,
        Pin: 833104,
        DispatchCenter: "Manoharpur_ColRd_DPP (Jharkhand)",
        OriginCenter: "Manoharpur_ColRd_DPP (Jharkhand)",
        FacilityCity: "Manoharpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15190,
        Pin: 626607,
        DispatchCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        OriginCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        FacilityCity: "Aruppukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15191,
        Pin: 600124,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15192,
        Pin: 522437,
        DispatchCenter: "Piduguralla_Rasoolpet_D (Andhra Pradesh)",
        OriginCenter: "Piduguralla_Rasoolpet_D (Andhra Pradesh)",
        FacilityCity: "Piduguralla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15193,
        Pin: 605008,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15194,
        Pin: 621315,
        DispatchCenter: "Manapparai_AmmanNagar_D (Tamil Nadu)",
        OriginCenter: "Manapparai_AmmanNagar_D (Tamil Nadu)",
        FacilityCity: "Manapparai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15195,
        Pin: 670595,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15196,
        Pin: 624803,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15197,
        Pin: 202146,
        DispatchCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        OriginCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        FacilityCity: "Hathras",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15198,
        Pin: 591240,
        DispatchCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        OriginCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        FacilityCity: "Athani",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15199,
        Pin: 211012,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15200,
        Pin: 781010,
        DispatchCenter: "Guwahati_Kaikchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15201,
        Pin: 643202,
        DispatchCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        OriginCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15202,
        Pin: 571313,
        DispatchCenter: "Chamarajanagar_Galipur_D (Karnataka)",
        OriginCenter: "Chamarajanagar_Galipur_D (Karnataka)",
        FacilityCity: "Chamarajanagar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15203,
        Pin: 523272,
        DispatchCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        OriginCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        FacilityCity: "Ongole",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15204,
        Pin: 416606,
        DispatchCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        OriginCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        FacilityCity: "Malvan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15205,
        Pin: 636457,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15206,
        Pin: 782435,
        DispatchCenter: "Hojai_KrishnaNagar_D (Assam)",
        OriginCenter: "Hojai_KrishnaNagar_D (Assam)",
        FacilityCity: "Hojai",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15207,
        Pin: 245409,
        DispatchCenter: "Bulndshahr_Ymunpurm_D (Uttar Pradesh)",
        OriginCenter: "Bulndshahr_Ymunpurm_D (Uttar Pradesh)",
        FacilityCity: "Bulandshahr",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15208,
        Pin: 413707,
        DispatchCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        OriginCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        FacilityCity: "Shrirampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15209,
        Pin: 695303,
        DispatchCenter: "Attingal_Central_D_2 (Kerala)",
        OriginCenter: "Attingal_Central_D_2 (Kerala)",
        FacilityCity: "Attingal",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15210,
        Pin: 764021,
        DispatchCenter: "Jeypore_Koraput_D (Orissa)",
        OriginCenter: "Jeypore_Koraput_D (Orissa)",
        FacilityCity: "Jeypore",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15211,
        Pin: 679575,
        DispatchCenter: "Edappal_Nellissery_D (Kerala)",
        OriginCenter: "Edappal_Nellissery_D (Kerala)",
        FacilityCity: "Edappal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15212,
        Pin: 385560,
        DispatchCenter: "Shihori_Amblivas_DPP (Gujarat)",
        OriginCenter: "Shihori_Amblivas_DPP (Gujarat)",
        FacilityCity: "Shihori",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15213,
        Pin: 799105,
        DispatchCenter: "Udaipur_Chanban_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Udaipur-TR",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15214,
        Pin: 641062,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15215,
        Pin: 362265,
        DispatchCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        OriginCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        FacilityCity: "Veraval",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15216,
        Pin: 761026,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15217,
        Pin: 410208,
        DispatchCenter: "Mumbai_Talojamidc_L (Maharashtra)",
        OriginCenter: "Mumbai_Talojamidc_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15218,
        Pin: 752054,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15219,
        Pin: 632326,
        DispatchCenter: "Arani_PolurRd_D (Tamil Nadu)",
        OriginCenter: "Arani_PolurRd_D (Tamil Nadu)",
        FacilityCity: "Arani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15220,
        Pin: 600043,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15221,
        Pin: 534124,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15222,
        Pin: 134001,
        DispatchCenter: "Ambala_Mandli_D (Haryana)",
        OriginCenter: "Ambala_Mandli_D (Haryana)",
        FacilityCity: "Ambala",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15223,
        Pin: 800020,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15224,
        Pin: 140133,
        DispatchCenter: "AnandpurSahib_Dher_D (Punjab)",
        OriginCenter: "AnandpurSahib_Dher_D (Punjab)",
        FacilityCity: "Anandpur Sahib",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15225,
        Pin: 610205,
        DispatchCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        FacilityCity: "Thiruthuraipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15226,
        Pin: 784506,
        DispatchCenter: "Thelamara_NilachalPath_D (Assam)",
        OriginCenter: "Thelamara_NilachalPath_D (Assam)",
        FacilityCity: "Thelamara",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15227,
        Pin: 425132,
        DispatchCenter: "Jalgaon_MIDC_I (Maharashtra)",
        OriginCenter: "Jalgaon_MIDC_I (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15228,
        Pin: 431204,
        DispatchCenter: "Jalna_Yashodipnagar_D (Maharashtra)",
        OriginCenter: "Jalna_Yashodipnagar_D (Maharashtra)",
        FacilityCity: "Jalna",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15229,
        Pin: 561212,
        DispatchCenter: "Bagepalli_Chikballapur_D (Karnataka)",
        OriginCenter: "Bagepalli_Chikballapur_D (Karnataka)",
        FacilityCity: "Bagepalli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15230,
        Pin: 609504,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15231,
        Pin: 571321,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15232,
        Pin: 504107,
        DispatchCenter: "Navipet_Dharyapur_D (Telangana)",
        OriginCenter: "Navipet_Dharyapur_D (Telangana)",
        FacilityCity: "Navipet",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15233,
        Pin: 517002,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15234,
        Pin: 262122,
        DispatchCenter: "Puranpur_MdothdRD_D (Uttar Pradesh)",
        OriginCenter: "Puranpur_MdothdRD_D (Uttar Pradesh)",
        FacilityCity: "Puranpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15235,
        Pin: 734217,
        DispatchCenter: "Kurseong_PankhabariR_D (West Bengal)",
        OriginCenter: "Kurseong_PankhabariR_D (West Bengal)",
        FacilityCity: "Kurseong",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15236,
        Pin: 788014,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15237,
        Pin: 620026,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15238,
        Pin: 400041,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15239,
        Pin: 144305,
        DispatchCenter: "Dasuya_AdarshNagar_D (Punjab)",
        OriginCenter: "Dasuya_AdarshNagar_D (Punjab)",
        FacilityCity: "Dasuya",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15240,
        Pin: 711404,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15241,
        Pin: 227411,
        DispatchCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        OriginCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        FacilityCity: "Amethi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15242,
        Pin: 577225,
        DispatchCenter: "Honnali_TMroad_D (Karnataka)",
        OriginCenter: "Honnali_TMroad_D (Karnataka)",
        FacilityCity: "Honnali",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15243,
        Pin: 637211,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15244,
        Pin: 441212,
        DispatchCenter: "Mul_TahsilOfc_DPP (Maharashtra)",
        OriginCenter: "Mul_TahsilOfc_DPP (Maharashtra)",
        FacilityCity: "Mul",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15245,
        Pin: 122021,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15246,
        Pin: 233233,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15247,
        Pin: 132157,
        DispatchCenter: "Karnal_Kutail_H (Haryana)",
        OriginCenter: "Karnal_Kutail_H (Haryana)",
        FacilityCity: "Karnal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15248,
        Pin: 614015,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15249,
        Pin: 700057,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15250,
        Pin: 110124,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15251,
        Pin: 326021,
        DispatchCenter: "Jhalawar_RTOOffice_D (Rajasthan)",
        OriginCenter: "Jhalawar_RTOOffice_D (Rajasthan)",
        FacilityCity: "Jhalawar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15252,
        Pin: 416604,
        DispatchCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        OriginCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        FacilityCity: "Malvan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15253,
        Pin: 303323,
        DispatchCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        OriginCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        FacilityCity: "Bandikui",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15254,
        Pin: 521002,
        DispatchCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        OriginCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        FacilityCity: "Machilipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15255,
        Pin: 841407,
        DispatchCenter: "Mohammadpur_DurgaMkt_DPP (Bihar)",
        OriginCenter: "Mohammadpur_DurgaMkt_DPP (Bihar)",
        FacilityCity: "Mohammadpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15256,
        Pin: 756049,
        DispatchCenter: "Soro_Ashoknagar_D (Orissa)",
        OriginCenter: "Soro_Ashoknagar_D (Orissa)",
        FacilityCity: "Soro",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15257,
        Pin: 277504,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15258,
        Pin: 176057,
        DispatchCenter: "Dharamshala_GrudwrRd_D (Himachal Pradesh)",
        OriginCenter: "Dharamshala_GrudwrRd_D (Himachal Pradesh)",
        FacilityCity: "Dharamshala",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15259,
        Pin: 680507,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15260,
        Pin: 732142,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15261,
        Pin: 641109,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15262,
        Pin: 825311,
        DispatchCenter: "Barkagaon_Ntrjngr_D (Jharkhand)",
        OriginCenter: "Barkagaon_Ntrjngr_D (Jharkhand)",
        FacilityCity: "Barkagaon",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15263,
        Pin: 231314,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15264,
        Pin: 416313,
        DispatchCenter: "Ashta_GordeChwk_D (Maharashtra)",
        OriginCenter: "Ashta_GordeChwk_D (Maharashtra)",
        FacilityCity: "AshtaSangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15265,
        Pin: 262406,
        DispatchCenter: "Nawabganj_Bhairav_D (Uttar Pradesh)",
        OriginCenter: "Nawabganj_Bhairav_D (Uttar Pradesh)",
        FacilityCity: "Nawabganj-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15266,
        Pin: 685551,
        DispatchCenter: "Kattappana_Nettithozhu_D (Kerala)",
        OriginCenter: "Kattappana_Nettithozhu_D (Kerala)",
        FacilityCity: "Kattappana",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15267,
        Pin: 626108,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15268,
        Pin: 515651,
        DispatchCenter: "Anantapur_Dharmavaram_D (Andhra Pradesh)",
        OriginCenter: "Anantapur_Dharmavaram_D (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15269,
        Pin: 232341,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15270,
        Pin: 628801,
        DispatchCenter: "Eral_Nazareth_D (Tamil Nadu)",
        OriginCenter: "Eral_Nazareth_D (Tamil Nadu)",
        FacilityCity: "Eral",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15271,
        Pin: 323306,
        DispatchCenter: "Rawatbhata_HarijanBasti_D (Rajasthan)",
        OriginCenter: "Rawatbhata_HarijanBasti_D (Rajasthan)",
        FacilityCity: "Rawatbhata",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15272,
        Pin: 425505,
        DispatchCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        OriginCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        FacilityCity: "Raver",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15273,
        Pin: 502110,
        DispatchCenter: "Medak_RTCclny_D (Telangana)",
        OriginCenter: "Medak_RTCclny_D (Telangana)",
        FacilityCity: "Medak",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15274,
        Pin: 603403,
        DispatchCenter: "Vandavasi_SaltStreet_D (Tamil Nadu)",
        OriginCenter: "Vandavasi_SaltStreet_D (Tamil Nadu)",
        FacilityCity: "Vandavasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15275,
        Pin: 574151,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15276,
        Pin: 415514,
        DispatchCenter: "Wai_Panchwad_DPP (Maharashtra)",
        OriginCenter: "Wai_Panchwad_DPP (Maharashtra)",
        FacilityCity: "Wai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15277,
        Pin: 524315,
        DispatchCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        OriginCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15278,
        Pin: 132107,
        DispatchCenter: "Safidon_Urlana_D (Haryana)",
        OriginCenter: "Safidon_Urlana_D (Haryana)",
        FacilityCity: "Safidon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15279,
        Pin: 229801,
        DispatchCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        OriginCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        FacilityCity: "Haidargarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15280,
        Pin: 141309,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15281,
        Pin: 452011,
        DispatchCenter: "Indore_BarodaArjun_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 15282,
        Pin: 722144,
        DispatchCenter: "Bishnupur_StnRdDPP_D (West Bengal)",
        OriginCenter: "Bishnupur_StnRdDPP_D (West Bengal)",
        FacilityCity: "Bishnupur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15283,
        Pin: 380045,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15284,
        Pin: 680694,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15285,
        Pin: 764020,
        DispatchCenter: "Jeypore_Koraput_D (Orissa)",
        OriginCenter: "Jeypore_Koraput_D (Orissa)",
        FacilityCity: "Jeypore",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15286,
        Pin: 148005,
        DispatchCenter: "Sangrur_DC (Punjab)",
        OriginCenter: "Sangrur_DC (Punjab)",
        FacilityCity: "Sangrur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15287,
        Pin: 262502,
        DispatchCenter: "Pithorgarh_Kumud_D (Uttarakhand)",
        OriginCenter: "Pithorgarh_Kumud_D (Uttarakhand)",
        FacilityCity: "Pithoragarh",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15288,
        Pin: 793006,
        DispatchCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        OriginCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15289,
        Pin: 801117,
        DispatchCenter: "Bihta_Naubatpur_D (Bihar)",
        OriginCenter: "Bihta_Naubatpur_D (Bihar)",
        FacilityCity: "Bihta",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15290,
        Pin: 795007,
        DispatchCenter: "Senapati_OldMarket_D (Manipur)",
        OriginCenter: "Senapati_OldMarket_D (Manipur)",
        FacilityCity: "Senapati",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15291,
        Pin: 284002,
        DispatchCenter: "Jhansi_Nandanpura_I (Uttar Pradesh)",
        OriginCenter: "Jhansi_Nandanpura_I (Uttar Pradesh)",
        FacilityCity: "Jhansi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15292,
        Pin: 495692,
        DispatchCenter: "Dabhra_Thangan_D (Chhattisgarh)",
        OriginCenter: "Dabhra_Thangan_D (Chhattisgarh)",
        FacilityCity: "Dabhra",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15293,
        Pin: 534102,
        DispatchCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        OriginCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        FacilityCity: "Tadepalligudem",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15294,
        Pin: 731220,
        DispatchCenter: "Morgram_Kantagoriya_D (West Bengal)",
        OriginCenter: "Morgram_Kantagoriya_D (West Bengal)",
        FacilityCity: "Morgram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15295,
        Pin: 277211,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15296,
        Pin: 785645,
        DispatchCenter: "Nazira_Amina_D (Assam)",
        OriginCenter: "Nazira_Amina_D (Assam)",
        FacilityCity: "Nazira",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15297,
        Pin: 160008,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15298,
        Pin: 700094,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15299,
        Pin: 110122,
        DispatchCenter: "Delhi_RailYardRd_L (Delhi)",
        OriginCenter: "Delhi_RailYardRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15300,
        Pin: 458474,
        DispatchCenter: "Neemuch_Jhanjharwada_D (Madhya Pradesh)",
        OriginCenter: "Neemuch_Jhanjharwada_D (Madhya Pradesh)",
        FacilityCity: "Neemuch",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 15301,
        Pin: 691004,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15302,
        Pin: 574145,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15303,
        Pin: 222137,
        DispatchCenter: "Mariahu_RnprJmlpr_D (Uttar Pradesh)",
        OriginCenter: "Mariahu_RnprJmlpr_D (Uttar Pradesh)",
        FacilityCity: "Mariahu",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15304,
        Pin: 430007,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15305,
        Pin: 577202,
        DispatchCenter: "Shimoga_Eshwariah_I (Karnataka)",
        OriginCenter: "Shimoga_Eshwariah_I (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15306,
        Pin: 530042,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15307,
        Pin: 754156,
        DispatchCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        OriginCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15308,
        Pin: 486117,
        DispatchCenter: "Sohagi_Gambhirpur_D (Madhya Pradesh)",
        OriginCenter: "Sohagi_Gambhirpur_D (Madhya Pradesh)",
        FacilityCity: "Sohagi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 15309,
        Pin: 221105,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15310,
        Pin: 274502,
        DispatchCenter: "Salempur_Banjaria_D (Uttar Pradesh)",
        OriginCenter: "Salempur_Banjaria_D (Uttar Pradesh)",
        FacilityCity: "Salempur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15311,
        Pin: 560048,
        DispatchCenter: "Bangalore_Koralur_L (Karnataka)",
        OriginCenter: "Bangalore_Soukyaroad_GW (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15312,
        Pin: 495220,
        DispatchCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        OriginCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        FacilityCity: "Bilaspur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15313,
        Pin: 302018,
        DispatchCenter: "Jaipur_Ramchandpura_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15314,
        Pin: 614027,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15315,
        Pin: 365660,
        DispatchCenter: "Visavadar_Dhari_DPP (Gujarat)",
        OriginCenter: "Visavadar_Dhari_DPP (Gujarat)",
        FacilityCity: "Visavadar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15316,
        Pin: 575022,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15317,
        Pin: 802117,
        DispatchCenter: "Dinara_Wardno7_D (Bihar)",
        OriginCenter: "Dinara_Wardno7_D (Bihar)",
        FacilityCity: "Dinara",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15318,
        Pin: 736167,
        DispatchCenter: "Sitai_Khamarsitai_DPP (West Bengal)",
        OriginCenter: "Sitai_Khamarsitai_DPP (West Bengal)",
        FacilityCity: "Sitai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15319,
        Pin: 212656,
        DispatchCenter: "Khaga_GTRoad_D (Uttar Pradesh)",
        OriginCenter: "Khaga_GTRoad_D (Uttar Pradesh)",
        FacilityCity: "Khaga",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15320,
        Pin: 261302,
        DispatchCenter: "Sidhauli_Rmdna_D (Uttar Pradesh)",
        OriginCenter: "Sidhauli_Rmdna_D (Uttar Pradesh)",
        FacilityCity: "Sidhauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15321,
        Pin: 515411,
        DispatchCenter: "Tadipatri_Kalavagadacol_D (Andhra Pradesh)",
        OriginCenter: "Tadipatri_Kalavagadacol_D (Andhra Pradesh)",
        FacilityCity: "Tadipatri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15322,
        Pin: 628622,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15323,
        Pin: 501403,
        DispatchCenter: "Hyderabad_Medchal1_L (Telangana)",
        OriginCenter: "Hyderabad_Medchal_GW (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15324,
        Pin: 829144,
        DispatchCenter: "Bokaro_Chas_D (Jharkhand)",
        OriginCenter: "Bokaro_Chas_D (Jharkhand)",
        FacilityCity: "Bokaro",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15325,
        Pin: 609804,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15326,
        Pin: 571426,
        DispatchCenter: "Krishnarajpet_JayaNagar_D (Karnataka)",
        OriginCenter: "Krishnarajpet_JayaNagar_D (Karnataka)",
        FacilityCity: "Krishnarajpet",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15327,
        Pin: 847225,
        DispatchCenter: "Benipatti_Hathhapurparsa_D (Bihar)",
        OriginCenter: "Benipatti_Hathhapurparsa_D (Bihar)",
        FacilityCity: "Benipatti",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15328,
        Pin: 759040,
        DispatchCenter: "Dhenkanal_Rasol_D (Orissa)",
        OriginCenter: "Dhenkanal_Rasol_D (Orissa)",
        FacilityCity: "Dhenkanal",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15329,
        Pin: 360007,
        DispatchCenter: "Rajkot_Amargadh_H (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15330,
        Pin: 721627,
        DispatchCenter: "Kolaghat_Central_DPP_2 (West Bengal)",
        OriginCenter: "Kolaghat_Central_DPP_2 (West Bengal)",
        FacilityCity: "Kolaghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15331,
        Pin: 608303,
        DispatchCenter: "Lalpet_Kattumannarkoil_D (Tamil Nadu)",
        OriginCenter: "Lalpet_Kattumannarkoil_D (Tamil Nadu)",
        FacilityCity: "Lalpet",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15332,
        Pin: 534237,
        DispatchCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        FacilityCity: "Bhimavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15333,
        Pin: 522004,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15334,
        Pin: 224119,
        DispatchCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        OriginCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        FacilityCity: "Dariyabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15335,
        Pin: 142042,
        DispatchCenter: "Moga_Duneke_I (Punjab)",
        OriginCenter: "Moga_Duneke_I (Punjab)",
        FacilityCity: "Moga",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15336,
        Pin: 221109,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15337,
        Pin: 515671,
        DispatchCenter: "Anantapur_Dharmavaram_D (Andhra Pradesh)",
        OriginCenter: "Anantapur_Dharmavaram_D (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15338,
        Pin: 577434,
        DispatchCenter: "Sagara_Hotel_D (Karnataka)",
        OriginCenter: "Sagara_Hotel_D (Karnataka)",
        FacilityCity: "Sagara",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15339,
        Pin: 274207,
        DispatchCenter: "Kushinagar_Hata_D (Uttar Pradesh)",
        OriginCenter: "Kushinagar_Hata_D (Uttar Pradesh)",
        FacilityCity: "Kushinagar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15340,
        Pin: 415604,
        DispatchCenter: "Chiplun_Burumtali_D (Maharashtra)",
        OriginCenter: "Chiplun_Burumtali_D (Maharashtra)",
        FacilityCity: "Chiplun",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15341,
        Pin: 509135,
        DispatchCenter: "Gadwal_Aiza_D (Telangana)",
        OriginCenter: "Gadwal_Aiza_D (Telangana)",
        FacilityCity: "Gadwal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15342,
        Pin: 788723,
        DispatchCenter: "Karimganj_Andharibond_D (Assam)",
        OriginCenter: "Karimganj_Andharibond_D (Assam)",
        FacilityCity: "Karimganj",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15343,
        Pin: 226031,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15344,
        Pin: 492004,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15345,
        Pin: 503174,
        DispatchCenter: "Nizamabad_Sarangapur_I (Telangana)",
        OriginCenter: "Nizamabad_Sarangapur_I (Telangana)",
        FacilityCity: "Nizamabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15346,
        Pin: 518225,
        DispatchCenter: "Dhone_Autonagar_D (Andhra Pradesh)",
        OriginCenter: "Dhone_Autonagar_D (Andhra Pradesh)",
        FacilityCity: "Dhone",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15347,
        Pin: 623605,
        DispatchCenter: "Perunali_Sayalkudi_D (Tamil Nadu)",
        OriginCenter: "Perunali_Sayalkudi_D (Tamil Nadu)",
        FacilityCity: "Perunali",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15348,
        Pin: 534005,
        DispatchCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        OriginCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        FacilityCity: "Eluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15349,
        Pin: 760020,
        DispatchCenter: "Kantamal_Blockoffice_DPP (Orissa)",
        OriginCenter: "Kantamal_Blockoffice_DPP (Orissa)",
        FacilityCity: "Kantamal",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15350,
        Pin: 401305,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15351,
        Pin: 637304,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15352,
        Pin: 230403,
        DispatchCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        OriginCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        FacilityCity: "Pratapgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15353,
        Pin: 678572,
        DispatchCenter: "Akaloor_Lakdi_DPP (Kerala)",
        OriginCenter: "Akaloor_Lakdi_DPP (Kerala)",
        FacilityCity: "Akaloor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15354,
        Pin: 800027,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15355,
        Pin: 382825,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15356,
        Pin: 604410,
        DispatchCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        OriginCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        FacilityCity: "Kanchipuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15357,
        Pin: 313342,
        DispatchCenter: "Rajsamand_Riicoarea_D (Rajasthan)",
        OriginCenter: "Rajsamand_Riicoarea_D (Rajasthan)",
        FacilityCity: "Rajsamand",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15358,
        Pin: 761012,
        DispatchCenter: "Digapahandi_Punjikanya_D (Orissa)",
        OriginCenter: "Digapahandi_Punjikanya_D (Orissa)",
        FacilityCity: "Digapahandi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15359,
        Pin: 380062,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15360,
        Pin: 636112,
        DispatchCenter: "Chinnasalem_VKootRd_D (Tamil Nadu)",
        OriginCenter: "Chinnasalem_VKootRd_D (Tamil Nadu)",
        FacilityCity: "Chinnasalem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15361,
        Pin: 609609,
        DispatchCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        OriginCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        FacilityCity: "Karaikal",
        FacilityState: "Pondicherry",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15362,
        Pin: 605402,
        DispatchCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        OriginCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        FacilityCity: "Viluppuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15363,
        Pin: 768112,
        DispatchCenter: "Sambalpur_Tentelpara_H (Orissa)",
        OriginCenter: "Sambalpur_Tentelpara_H (Orissa)",
        FacilityCity: "Sambalpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15364,
        Pin: 413624,
        DispatchCenter: "Osmanabad_Tuljapur_D (Maharashtra)",
        OriginCenter: "Osmanabad_Tuljapur_D (Maharashtra)",
        FacilityCity: "Osmanabad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15365,
        Pin: 416302,
        DispatchCenter: "Ashta_GordeChwk_D (Maharashtra)",
        OriginCenter: "Ashta_GordeChwk_D (Maharashtra)",
        FacilityCity: "AshtaSangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15366,
        Pin: 231216,
        DispatchCenter: "Robertsganj_Pakari_D (Uttar Pradesh)",
        OriginCenter: "Robertsganj_Pakari_D (Uttar Pradesh)",
        FacilityCity: "Robertsganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15367,
        Pin: 626601,
        DispatchCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        OriginCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        FacilityCity: "Vadipatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15368,
        Pin: 410206,
        DispatchCenter: "Mumbai_Ariwali_L (Maharashtra)",
        OriginCenter: "Mumbai_Ariwali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15369,
        Pin: 573220,
        DispatchCenter: "Hassan_Nagathavalli_I (Karnataka)",
        OriginCenter: "Hassan_Nagathavalli_I (Karnataka)",
        FacilityCity: "Hassan",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15370,
        Pin: 442603,
        DispatchCenter: "Chamorshi_Ganeshnagar_DPP (Maharashtra)",
        OriginCenter: "Chamorshi_Ganeshnagar_DPP (Maharashtra)",
        FacilityCity: "Chamorshi",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15371,
        Pin: 680544,
        DispatchCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        OriginCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15372,
        Pin: 642004,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15373,
        Pin: 632405,
        DispatchCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ranipet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15374,
        Pin: 383215,
        DispatchCenter: "Prantij_Vavdichokdi_DPP (Gujarat)",
        OriginCenter: "Prantij_Vavdichokdi_DPP (Gujarat)",
        FacilityCity: "Prantij",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15375,
        Pin: 384002,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15376,
        Pin: 505330,
        DispatchCenter: "Koratla_PRBM_DPP (Telangana)",
        OriginCenter: "Koratla_PRBM_DPP (Telangana)",
        FacilityCity: "Koratla",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15377,
        Pin: 323307,
        DispatchCenter: "Rawatbhata_HarijanBasti_D (Rajasthan)",
        OriginCenter: "Rawatbhata_HarijanBasti_D (Rajasthan)",
        FacilityCity: "Rawatbhata",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15378,
        Pin: 192210,
        DispatchCenter: "Anantnag_QaziBagh_DPP (Jammu & Kashmir)",
        OriginCenter: "Anantnag_QaziBagh_DPP (Jammu & Kashmir)",
        FacilityCity: "Anantnag",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15379,
        Pin: 576122,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15380,
        Pin: 682508,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15381,
        Pin: 782102,
        DispatchCenter: "Nagaon_Bangthai_H (Assam)",
        OriginCenter: "Nagaon_Bangthai_H (Assam)",
        FacilityCity: "Nagaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15382,
        Pin: 388307,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15383,
        Pin: 125106,
        DispatchCenter: "Ratia_Baslan_D (Haryana)",
        OriginCenter: "Ratia_Baslan_D (Haryana)",
        FacilityCity: "Ratia",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15384,
        Pin: 754217,
        DispatchCenter: "Garjanpur_Gossei_D (Orissa)",
        OriginCenter: "Garjanpur_Gossei_D (Orissa)",
        FacilityCity: "Garjanpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15385,
        Pin: 144623,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15386,
        Pin: 274204,
        DispatchCenter: "Deoria_Indupur_D (Uttar Pradesh)",
        OriginCenter: "Deoria_Indupur_D (Uttar Pradesh)",
        FacilityCity: "Deoria",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15387,
        Pin: 134112,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15388,
        Pin: 225416,
        DispatchCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        OriginCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        FacilityCity: "Haidargarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15389,
        Pin: 685595,
        DispatchCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        OriginCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        FacilityCity: "Thodupuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15390,
        Pin: 767023,
        DispatchCenter: "Dunguripali_Badnuapali_D (Orissa)",
        OriginCenter: "Dunguripali_Badnuapali_D (Orissa)",
        FacilityCity: "Dunguripali",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15391,
        Pin: 759017,
        DispatchCenter: "Bhuban_Sadangi_D (Orissa)",
        OriginCenter: "Bhuban_Sadangi_D (Orissa)",
        FacilityCity: "Bhuban",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15392,
        Pin: 421601,
        DispatchCenter: "Asangaon_Adarshnagar_D (Maharashtra)",
        OriginCenter: "Asangaon_Adarshnagar_D (Maharashtra)",
        FacilityCity: "Asangaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15393,
        Pin: 246762,
        DispatchCenter: "Nagina_DhampurRd_DPP (Uttar Pradesh)",
        OriginCenter: "Nagina_DhampurRd_DPP (Uttar Pradesh)",
        FacilityCity: "Nagina",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15394,
        Pin: 585104,
        DispatchCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        OriginCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        FacilityCity: "Gulbarga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15395,
        Pin: 756124,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15396,
        Pin: 756055,
        DispatchCenter: "Soro_Ashoknagar_D (Orissa)",
        OriginCenter: "Soro_Ashoknagar_D (Orissa)",
        FacilityCity: "Soro",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15397,
        Pin: 607202,
        DispatchCenter: "Ulundurpet_HarishNgr_D (Tamil Nadu)",
        OriginCenter: "Ulundurpet_HarishNgr_D (Tamil Nadu)",
        FacilityCity: "Ulundurpet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15398,
        Pin: 140112,
        DispatchCenter: "Rupnagar_Towercolony_D (Punjab)",
        OriginCenter: "Rupnagar_Towercolony_D (Punjab)",
        FacilityCity: "Rupnagar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15399,
        Pin: 321026,
        DispatchCenter: "Bharatpur_DurgaNagar_D (Rajasthan)",
        OriginCenter: "Bharatpur_DurgaNagar_D (Rajasthan)",
        FacilityCity: "Bharatpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15400,
        Pin: 799210,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15401,
        Pin: 311803,
        DispatchCenter: "Gangapur_Raipur_DPP (Rajasthan)",
        OriginCenter: "Gangapur_Raipur_DPP (Rajasthan)",
        FacilityCity: "Gangapur-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15402,
        Pin: 211023,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        Zone : "N1"
      },
      {
        SN: 15403,
        Pin: 403711,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15404,
        Pin: 754027,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15405,
        Pin: 335503,
        DispatchCenter: "Bhadra_GanapatiPlaza_D (Rajasthan)",
        OriginCenter: "Bhadra_GanapatiPlaza_D (Rajasthan)",
        FacilityCity: "Bhadra",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15406,
        Pin: 160067,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15407,
        Pin: 625017,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15408,
        Pin: 583119,
        DispatchCenter: "Sandur_Smiorecolony_DPP (Karnataka)",
        OriginCenter: "Sandur_Smiorecolony_DPP (Karnataka)",
        FacilityCity: "Sandur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15409,
        Pin: 799251,
        DispatchCenter: "Dharmanagar_Ward3_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Dharmanagar",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15410,
        Pin: 689589,
        DispatchCenter: "Karukachal_Nedumkunam_D (Kerala)",
        OriginCenter: "Karukachal_Nedumkunam_D (Kerala)",
        FacilityCity: "Karukachal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15411,
        Pin: 271003,
        DispatchCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        OriginCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        FacilityCity: "Gonda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15412,
        Pin: 768108,
        DispatchCenter: "Deogarh_Jagnathtpl_D (Orissa)",
        OriginCenter: "Deogarh_Jagnathtpl_D (Orissa)",
        FacilityCity: "Deogarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15413,
        Pin: 721648,
        DispatchCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        OriginCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        FacilityCity: "Tamluk",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15414,
        Pin: 303310,
        DispatchCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        OriginCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        FacilityCity: "Bandikui",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15415,
        Pin: 202526,
        DispatchCenter: "Aonla_KdidmCLY_D (Uttar Pradesh)",
        OriginCenter: "Aonla_KdidmCLY_D (Uttar Pradesh)",
        FacilityCity: "Aonla",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15416,
        Pin: 125053,
        DispatchCenter: "Fatehabad_SirsaRd_D (Haryana)",
        OriginCenter: "Fatehabad_SirsaRd_D (Haryana)",
        FacilityCity: "Fatehabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15417,
        Pin: 627501,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15418,
        Pin: 629803,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15419,
        Pin: 332403,
        DispatchCenter: "Bajore_Sheeshyoo_DPP (Rajasthan)",
        OriginCenter: "Bajore_Sheeshyoo_DPP (Rajasthan)",
        FacilityCity: "Bajore",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15420,
        Pin: 422202,
        DispatchCenter: "DindoriMH_Nilwandi_D (Maharashtra)",
        OriginCenter: "DindoriMH_Nilwandi_D (Maharashtra)",
        FacilityCity: "Dindori-MH",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15421,
        Pin: 243004,
        DispatchCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        OriginCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        FacilityCity: "Bareilly",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15422,
        Pin: 577116,
        DispatchCenter: "Kadur_UBRoad_D (Karnataka)",
        OriginCenter: "Kadur_UBRoad_D (Karnataka)",
        FacilityCity: "Kadur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15423,
        Pin: 670703,
        DispatchCenter: "Chavassery_Kelanpeedika_D (Kerala)",
        OriginCenter: "Chavassery_Kelanpeedika_D (Kerala)",
        FacilityCity: "Chavassery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15424,
        Pin: 574228,
        DispatchCenter: "Belthangady_UjreMnRd_DPP (Karnataka)",
        OriginCenter: "Belthangady_UjreMnRd_DPP (Karnataka)",
        FacilityCity: "Belthangady",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15425,
        Pin: 261203,
        DispatchCenter: "FatehpurUP_PatelNagar_D (Uttar Pradesh)",
        OriginCenter: "FatehpurUP_PatelNagar_D (Uttar Pradesh)",
        FacilityCity: "Fatehpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15426,
        Pin: 523333,
        DispatchCenter: "CumbumAP_BhagyaNagar_D (Andhra Pradesh)",
        OriginCenter: "CumbumAP_BhagyaNagar_D (Andhra Pradesh)",
        FacilityCity: "CumbumAP",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15427,
        Pin: 458990,
        DispatchCenter: "Mandsaur_Sitamau_DPP (Madhya Pradesh)",
        OriginCenter: "Mandsaur_Sitamau_DPP (Madhya Pradesh)",
        FacilityCity: "Mandsaur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 15428,
        Pin: 722205,
        DispatchCenter: "Arambag_Bulchandrapur_DPP (West Bengal)",
        OriginCenter: "Arambag_Bulchandrapur_DPP (West Bengal)",
        FacilityCity: "Arambag",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15429,
        Pin: 496113,
        DispatchCenter: "Lailunga_BanjariPara_D (Chhattisgarh)",
        OriginCenter: "Lailunga_BanjariPara_D (Chhattisgarh)",
        FacilityCity: "Lailunga",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15430,
        Pin: 605301,
        DispatchCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        OriginCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        FacilityCity: "Viluppuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15431,
        Pin: 203132,
        DispatchCenter: "Khurja_Pnjbiyon_D (Uttar Pradesh)",
        OriginCenter: "Khurja_Pnjbiyon_D (Uttar Pradesh)",
        FacilityCity: "Khurja",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15432,
        Pin: 636804,
        DispatchCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        OriginCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        FacilityCity: "Dharmapuri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15433,
        Pin: 422104,
        DispatchCenter: "Sinnar_Pimpale_D (Maharashtra)",
        OriginCenter: "Sinnar_Pimpale_D (Maharashtra)",
        FacilityCity: "Sinnar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15434,
        Pin: 496334,
        DispatchCenter: "Kunkuri_Dugdugiya_D (Chhattisgarh)",
        OriginCenter: "Kunkuri_Dugdugiya_D (Chhattisgarh)",
        FacilityCity: "Kunkuri",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15435,
        Pin: 454449,
        DispatchCenter: "Manawar_MPEBOffice_DPP (Madhya Pradesh)",
        OriginCenter: "Manawar_MPEBOffice_DPP (Madhya Pradesh)",
        FacilityCity: "Manawar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 15436,
        Pin: 144014,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15437,
        Pin: 843120,
        DispatchCenter: "Sahebganj_Goti_D (Bihar)",
        OriginCenter: "Sahebganj_Goti_D (Bihar)",
        FacilityCity: "Sahebganj-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15438,
        Pin: 762026,
        DispatchCenter: "Boudh_Jogiberini_D (Orissa)",
        OriginCenter: "Boudh_Jogiberini_D (Orissa)",
        FacilityCity: "Boudh",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15439,
        Pin: 821106,
        DispatchCenter: "Chainpur_Bhabhua_D (Bihar)",
        OriginCenter: "Chainpur_Bhabhua_D (Bihar)",
        FacilityCity: "Chainpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15440,
        Pin: 127030,
        DispatchCenter: "Loharu_HsptlRd_D (Haryana)",
        OriginCenter: "Loharu_HsptlRd_D (Haryana)",
        FacilityCity: "Loharu",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15441,
        Pin: 332031,
        DispatchCenter: "Nawalgarh_Jhunjhnun_DPP (Rajasthan)",
        OriginCenter: "Nawalgarh_Jhunjhnun_DPP (Rajasthan)",
        FacilityCity: "Nawalgarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15442,
        Pin: 575009,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15443,
        Pin: 341026,
        DispatchCenter: "Nagaur_Jantacomplex_D (Rajasthan)",
        OriginCenter: "Nagaur_Jantacomplex_D (Rajasthan)",
        FacilityCity: "Nagaur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15444,
        Pin: 518396,
        DispatchCenter: "Guntakal_Central_D_2 (Andhra Pradesh)",
        OriginCenter: "Guntakal_Central_D_2 (Andhra Pradesh)",
        FacilityCity: "Guntakal",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15445,
        Pin: 247001,
        DispatchCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        OriginCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        FacilityCity: "Saharanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15446,
        Pin: 843105,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15447,
        Pin: 574179,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15448,
        Pin: 302044,
        DispatchCenter: "Kalwar_Bhagwatinagar_D (Rajasthan)",
        OriginCenter: "Kalwar_Bhagwatinagar_D (Rajasthan)",
        FacilityCity: "Kalwar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15449,
        Pin: 301402,
        DispatchCenter: "Bansur_TransportNagar_D (Rajasthan)",
        OriginCenter: "Bansur_TransportNagar_D (Rajasthan)",
        FacilityCity: "Bansur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15450,
        Pin: 754130,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15451,
        Pin: 175015,
        DispatchCenter: "JognderNgr_Dhelu_D (Himachal Pradesh)",
        OriginCenter: "JognderNgr_Dhelu_D (Himachal Pradesh)",
        FacilityCity: "Joginder Nagar",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15452,
        Pin: 803205,
        DispatchCenter: "Patna_Bankipurgorakh_D (Bihar)",
        OriginCenter: "Patna_Bankipurgorakh_D (Bihar)",
        FacilityCity: "Fatuha",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15453,
        Pin: 509411,
        DispatchCenter: "Kosgi_Chennaram_D (Telangana)",
        OriginCenter: "Kosgi_Chennaram_D (Telangana)",
        FacilityCity: "Kosgi",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15454,
        Pin: 400704,
        DispatchCenter: "Jasai_Vidhane_L (Maharashtra)",
        OriginCenter: "Jasai_Vidhane_L (Maharashtra)",
        FacilityCity: "Jasai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15455,
        Pin: 382820,
        DispatchCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        OriginCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        FacilityCity: "Vijapur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15456,
        Pin: 623601,
        DispatchCenter: "Muthukulathur_Selvanygprm_D (Tamil Nadu)",
        OriginCenter: "Muthukulathur_Selvanygprm_D (Tamil Nadu)",
        FacilityCity: "Muthukulathur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15457,
        Pin: 393115,
        DispatchCenter: "Jhagadia_Ranipara_DPP (Gujarat)",
        OriginCenter: "Jhagadia_Ranipara_DPP (Gujarat)",
        FacilityCity: "Jhagadia",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15458,
        Pin: 788015,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15459,
        Pin: 642152,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15460,
        Pin: 583127,
        DispatchCenter: "Harpanahalli_KHBClny_D (Karnataka)",
        OriginCenter: "Harpanahalli_KHBClny_D (Karnataka)",
        FacilityCity: "Harpanahalli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15461,
        Pin: 591239,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15462,
        Pin: 444810,
        DispatchCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        OriginCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        FacilityCity: "Paratwada",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15463,
        Pin: 802115,
        DispatchCenter: "Dumraon_MahvidylaRd_D (Bihar)",
        OriginCenter: "Dumraon_MahvidylaRd_D (Bihar)",
        FacilityCity: "Dumraon",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15464,
        Pin: 333701,
        DispatchCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        OriginCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        FacilityCity: "Jhunjhunu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15465,
        Pin: 360430,
        DispatchCenter: "Upleta_KolkiRd_DPP (Gujarat)",
        OriginCenter: "Upleta_KolkiRd_DPP (Gujarat)",
        FacilityCity: "Upleta",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15466,
        Pin: 125113,
        DispatchCenter: "Fatehabad_Kullanroad_D (Haryana)",
        OriginCenter: "Fatehabad_Kullanroad_D (Haryana)",
        FacilityCity: "Fatehabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15467,
        Pin: 262580,
        DispatchCenter: "Lohaghat_RanjeetPalace_D (Uttarakhand)",
        OriginCenter: "Lohaghat_RanjeetPalace_D (Uttarakhand)",
        FacilityCity: "Lohaghat",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15468,
        Pin: 311029,
        DispatchCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        OriginCenter: "Bijainagar_Rajdarbarcolony_D (Rajasthan)",
        FacilityCity: "Bijainagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15469,
        Pin: 425115,
        DispatchCenter: "Pachora_AadarshNagar_D (Maharashtra)",
        OriginCenter: "Pachora_AadarshNagar_D (Maharashtra)",
        FacilityCity: "Pachora",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15470,
        Pin: 431516,
        DispatchCenter: "Parli_JirgeNgr_D (Maharashtra)",
        OriginCenter: "Parli_JirgeNgr_D (Maharashtra)",
        FacilityCity: "Parli",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15471,
        Pin: 680751,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15472,
        Pin: 585225,
        DispatchCenter: "Chittapur_GlbrgaRD_D (Karnataka)",
        OriginCenter: "Chittapur_GlbrgaRD_D (Karnataka)",
        FacilityCity: "Chittapur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15473,
        Pin: 600125,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15474,
        Pin: 781171,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15475,
        Pin: 788123,
        DispatchCenter: "Sonabarighat_Saidpur_DPP (Assam)",
        OriginCenter: "Sonabarighat_Saidpur_DPP (Assam)",
        FacilityCity: "Sonabarighat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15476,
        Pin: 274505,
        DispatchCenter: "Salempur_Banjaria_D (Uttar Pradesh)",
        OriginCenter: "Salempur_Banjaria_D (Uttar Pradesh)",
        FacilityCity: "Salempur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15477,
        Pin: 743505,
        DispatchCenter: "Baruipur_Bnsibtla_D (West Bengal)",
        OriginCenter: "Baruipur_Bnsibtla_D (West Bengal)",
        FacilityCity: "Baruipur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15478,
        Pin: 440027,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15479,
        Pin: 388544,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15480,
        Pin: 346110,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15481,
        Pin: 327034,
        DispatchCenter: "Banswara_Bagidora_D (Rajasthan)",
        OriginCenter: "Banswara_Bagidora_D (Rajasthan)",
        FacilityCity: "Banswara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15482,
        Pin: 516359,
        DispatchCenter: "Chagalamarri_TeluguGanga_DPP (Andhra Pradesh)",
        OriginCenter: "Chagalamarri_TeluguGanga_DPP (Andhra Pradesh)",
        FacilityCity: "Chagalamarri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15483,
        Pin: 823003,
        DispatchCenter: "Gaya_Matihani_H (Bihar)",
        OriginCenter: "Gaya_Matihani_H (Bihar)",
        FacilityCity: "Gaya",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15484,
        Pin: 627426,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15485,
        Pin: 110010,
        DispatchCenter: "Delhi_BamnoliVillage_L (Delhi)",
        OriginCenter: "Delhi_BamnoliVillage_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15486,
        Pin: 303501,
        DispatchCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        OriginCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        FacilityCity: "Bandikui",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15487,
        Pin: 600126,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15488,
        Pin: 802315,
        DispatchCenter: "Arrah_Sakaddi_D (Bihar)",
        OriginCenter: "Arrah_Sakaddi_D (Bihar)",
        FacilityCity: "Arrah",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15489,
        Pin: 534448,
        DispatchCenter: "Jangareddygudem_RTC_D (Andhra Pradesh)",
        OriginCenter: "Jangareddygudem_RTC_D (Andhra Pradesh)",
        FacilityCity: "Jangareddygudem",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15490,
        Pin: 607105,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15491,
        Pin: 700009,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15492,
        Pin: 484771,
        DispatchCenter: "Jaisinghnagar_Khusarwah_DPP (Madhya Pradesh)",
        OriginCenter: "Jaisinghnagar_Khusarwah_DPP (Madhya Pradesh)",
        FacilityCity: "Jaisinghnagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 15493,
        Pin: 732210,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15494,
        Pin: 306303,
        DispatchCenter: "Raipur_Mainmarket_D (Rajasthan)",
        OriginCenter: "Raipur_Mainmarket_D (Rajasthan)",
        FacilityCity: "Raipur-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15495,
        Pin: 812005,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15496,
        Pin: 232104,
        DispatchCenter: "Chandauli_ShubsNGR_D (Uttar Pradesh)",
        OriginCenter: "Chandauli_ShubsNGR_D (Uttar Pradesh)",
        FacilityCity: "Chandauli",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15497,
        Pin: 140401,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15498,
        Pin: 631303,
        DispatchCenter: "Sholinghur_Pulanji_D (Tamil Nadu)",
        OriginCenter: "Sholinghur_Pulanji_D (Tamil Nadu)",
        FacilityCity: "Sholinghur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15499,
        Pin: 533124,
        DispatchCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        OriginCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        FacilityCity: "Rajahmundry",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15500,
        Pin: 783324,
        DispatchCenter: "Dhubri_Tetultol_D (Assam)",
        OriginCenter: "Dhubri_Tetultol_D (Assam)",
        FacilityCity: "Dhubri",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15501,
        Pin: 121002,
        DispatchCenter: "Faridabad_MathuraRoad_L (Haryana)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15502,
        Pin: 390014,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15503,
        Pin: 638105,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15504,
        Pin: 414604,
        DispatchCenter: "Newasa_Khurd_D (Maharashtra)",
        OriginCenter: "Newasa_Khurd_D (Maharashtra)",
        FacilityCity: "Newasa",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15505,
        Pin: 395021,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15506,
        Pin: 360421,
        DispatchCenter: "Dhoraji_StationRd_DPP (Gujarat)",
        OriginCenter: "Dhoraji_StationRd_DPP (Gujarat)",
        FacilityCity: "Dhoraji",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15507,
        Pin: 272127,
        DispatchCenter: "Itava_Prsnga_D (Uttar Pradesh)",
        OriginCenter: "Itava_Prsnga_D (Uttar Pradesh)",
        FacilityCity: "Itava",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15508,
        Pin: 686673,
        DispatchCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        OriginCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        FacilityCity: "Muvattupuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15509,
        Pin: 206244,
        DispatchCenter: "Auraiya_Brahmngr_D (Uttar Pradesh)",
        OriginCenter: "Auraiya_Brahmngr_D (Uttar Pradesh)",
        FacilityCity: "Auraiya",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15510,
        Pin: 679504,
        DispatchCenter: "Cherpulassery_PryaPlda_D (Kerala)",
        OriginCenter: "Cherpulassery_PryaPlda_D (Kerala)",
        FacilityCity: "Cherpulassery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15511,
        Pin: 741123,
        DispatchCenter: "Chapra_Elemnagar_DPP (West Bengal)",
        OriginCenter: "Chapra_Elemnagar_DPP (West Bengal)",
        FacilityCity: "Chapra",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15512,
        Pin: 670325,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15513,
        Pin: 174505,
        DispatchCenter: "Bhota_BpassDPP_D (Himachal Pradesh)",
        OriginCenter: "Bhota_BpassDPP_D (Himachal Pradesh)",
        FacilityCity: "Bhota",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15514,
        Pin: 636118,
        DispatchCenter: "Mettala_Rasipuram_D (Tamil Nadu)",
        OriginCenter: "Mettala_Rasipuram_D (Tamil Nadu)",
        FacilityCity: "Mettala",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15515,
        Pin: 502291,
        DispatchCenter: "Sadashivpet_Qasimnagar_D (Telangana)",
        OriginCenter: "Sadashivpet_Qasimnagar_D (Telangana)",
        FacilityCity: "Sadashivpet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15516,
        Pin: 455221,
        DispatchCenter: "Chapda_Ideacolony_DPP (Madhya Pradesh)",
        OriginCenter: "Chapda_Ideacolony_DPP (Madhya Pradesh)",
        FacilityCity: "Chapda",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 15517,
        Pin: 673584,
        DispatchCenter: "Thamarassery_Raoth_D (Kerala)",
        OriginCenter: "Thamarassery_Raoth_D (Kerala)",
        FacilityCity: "Thamarassery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15518,
        Pin: 517643,
        DispatchCenter: "PutturAP_Pichatur_D (Andhra Pradesh)",
        OriginCenter: "PutturAP_Pichatur_D (Andhra Pradesh)",
        FacilityCity: "Puttur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15519,
        Pin: 854117,
        DispatchCenter: "Manihari_Bnrailwaycol_D (Bihar)",
        OriginCenter: "Manihari_Bnrailwaycol_D (Bihar)",
        FacilityCity: "Manihari",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15520,
        Pin: 277209,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15521,
        Pin: 392013,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15522,
        Pin: 172111,
        DispatchCenter: "Pooh_Spillowkhas_DPP (Himachal Pradesh)",
        OriginCenter: "Pooh_Spillowkhas_DPP (Himachal Pradesh)",
        FacilityCity: "Pooh",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15523,
        Pin: 781375,
        DispatchCenter: "Nalbari_Chokbazar_D (Assam)",
        OriginCenter: "Nalbari_Chokbazar_D (Assam)",
        FacilityCity: "Nalbari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15524,
        Pin: 812003,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15525,
        Pin: 628908,
        DispatchCenter: "Vilathikulam_Thoothukudi_DPP (Tamil Nadu)",
        OriginCenter: "Vilathikulam_Thoothukudi_DPP (Tamil Nadu)",
        FacilityCity: "Vilathikulam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15526,
        Pin: 670006,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15527,
        Pin: 591215,
        DispatchCenter: "Nipani_Sakharwadi_D (Karnataka)",
        OriginCenter: "Nipani_Sakharwadi_D (Karnataka)",
        FacilityCity: "Nipani",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15528,
        Pin: 416406,
        DispatchCenter: "Sangli_Dattanagar_D (Maharashtra)",
        OriginCenter: "Sangli_Dattanagar_D (Maharashtra)",
        FacilityCity: "Sangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15529,
        Pin: 410510,
        DispatchCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        OriginCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        FacilityCity: "Ambegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15530,
        Pin: 843151,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15531,
        Pin: 577006,
        DispatchCenter: "Davangere_Industrialarea_I (Karnataka)",
        OriginCenter: "Davangere_Industrialarea_I (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15532,
        Pin: 416232,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15533,
        Pin: 584122,
        DispatchCenter: "Lingasugur_Shivnagara_D (Karnataka)",
        OriginCenter: "Lingasugur_Shivnagara_D (Karnataka)",
        FacilityCity: "Lingasugur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15534,
        Pin: 147101,
        DispatchCenter: "Pehowa_Cheeka_D (Haryana)",
        OriginCenter: "Pehowa_Cheeka_D (Haryana)",
        FacilityCity: "Pehowa",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15535,
        Pin: 734003,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15536,
        Pin: 691020,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15537,
        Pin: 743363,
        DispatchCenter: "Baruipur_Bnsibtla_D (West Bengal)",
        OriginCenter: "Baruipur_Bnsibtla_D (West Bengal)",
        FacilityCity: "Baruipur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15538,
        Pin: 534000,
        DispatchCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        OriginCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        FacilityCity: "Eluru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15539,
        Pin: 609102,
        DispatchCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        OriginCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        FacilityCity: "Sirkazhi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15540,
        Pin: 229408,
        DispatchCenter: "Unchahar_Khojanpur_D (Uttar Pradesh)",
        OriginCenter: "Unchahar_Khojanpur_D (Uttar Pradesh)",
        FacilityCity: "Unchahar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15541,
        Pin: 312025,
        DispatchCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        OriginCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        FacilityCity: "Chittaurgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15542,
        Pin: 632509,
        DispatchCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ranipet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15543,
        Pin: 533347,
        DispatchCenter: "Chintoor_PoliceStn_DPP (Telangana)",
        OriginCenter: "Chintoor_PoliceStn_DPP (Telangana)",
        FacilityCity: "Chintoor",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15544,
        Pin: 414602,
        DispatchCenter: "Newasa_Khurd_D (Maharashtra)",
        OriginCenter: "Newasa_Khurd_D (Maharashtra)",
        FacilityCity: "Newasa",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15545,
        Pin: 181208,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15546,
        Pin: 413319,
        DispatchCenter: "Pandharpur_Mangalwedha_D (Maharashtra)",
        OriginCenter: "Pandharpur_Mangalwedha_D (Maharashtra)",
        FacilityCity: "Pandharpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15547,
        Pin: 147001,
        DispatchCenter: "Patiala_UpkarNagar_D (Punjab)",
        OriginCenter: "Patiala_UpkarNagar_D (Punjab)",
        FacilityCity: "Patiala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15548,
        Pin: 534002,
        DispatchCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        OriginCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        FacilityCity: "Eluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15549,
        Pin: 670001,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15550,
        Pin: 781349,
        DispatchCenter: "Nalbari_Chokbazar_D (Assam)",
        OriginCenter: "Nalbari_Chokbazar_D (Assam)",
        FacilityCity: "Nalbari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15551,
        Pin: 141120,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15552,
        Pin: 396067,
        DispatchCenter: "Valsad_Dharampur_D (Gujarat)",
        OriginCenter: "Valsad_Dharampur_D (Gujarat)",
        FacilityCity: "Valsad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15553,
        Pin: 273009,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15554,
        Pin: 273309,
        DispatchCenter: "Brijmanganj_Rattupur_DPP (Uttar Pradesh)",
        OriginCenter: "Brijmanganj_Rattupur_DPP (Uttar Pradesh)",
        FacilityCity: "Brijmanganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15555,
        Pin: 494445,
        DispatchCenter: "Jagdalpur_Hatkchora_D (Chhattisgarh)",
        OriginCenter: "Jagdalpur_Hatkchora_D (Chhattisgarh)",
        FacilityCity: "Jagdalpur",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15556,
        Pin: 604103,
        DispatchCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        OriginCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        FacilityCity: "Tindivanam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15557,
        Pin: 743271,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15558,
        Pin: 413111,
        DispatchCenter: "Mhaswad_Drgmtatpl_D (Maharashtra)",
        OriginCenter: "Mhaswad_Drgmtatpl_D (Maharashtra)",
        FacilityCity: "Mhaswad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15559,
        Pin: 713373,
        DispatchCenter: "Asansol_Addacolony_D (West Bengal)",
        OriginCenter: "Asansol_Addacolony_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15560,
        Pin: 782401,
        DispatchCenter: "Jorabat_Japesiagn_D (Assam)",
        OriginCenter: "Jorabat_Japesiagn_D (Assam)",
        FacilityCity: "Jorabat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15561,
        Pin: 473330,
        DispatchCenter: "Ashoknagar_Durgacolony_D (Madhya Pradesh)",
        OriginCenter: "Ashoknagar_Durgacolony_D (Madhya Pradesh)",
        FacilityCity: "Ashok Nagar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 15562,
        Pin: 576201,
        DispatchCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        OriginCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15563,
        Pin: 176044,
        DispatchCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        OriginCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        FacilityCity: "Jahu",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15564,
        Pin: 700033,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15565,
        Pin: 813127,
        DispatchCenter: "Banka_Shahpur_DPP (Bihar)",
        OriginCenter: "Banka_Shahpur_DPP (Bihar)",
        FacilityCity: "Banka",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15566,
        Pin: 209121,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15567,
        Pin: 629191,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15568,
        Pin: 506013,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15569,
        Pin: 673009,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15570,
        Pin: 431141,
        DispatchCenter: "Majalgaon_SmbjiCwk_D (Maharashtra)",
        OriginCenter: "Majalgaon_SmbjiCwk_D (Maharashtra)",
        FacilityCity: "Manjlegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15571,
        Pin: 574509,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15572,
        Pin: 734012,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15573,
        Pin: 642128,
        DispatchCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        OriginCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        FacilityCity: "Udumalaipettai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15574,
        Pin: 370510,
        DispatchCenter: "Bhuj_Madhapar_D (Gujarat)",
        OriginCenter: "Bhuj_Madhapar_D (Gujarat)",
        FacilityCity: "Bhuj",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15575,
        Pin: 614103,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15576,
        Pin: 813203,
        DispatchCenter: "Kahalgaon_Budhuchak_D (Bihar)",
        OriginCenter: "Kahalgaon_Budhuchak_D (Bihar)",
        FacilityCity: "Kahalgaon",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15577,
        Pin: 754119,
        DispatchCenter: "Balikuda_Balisahi_D (Orissa)",
        OriginCenter: "Balikuda_Balisahi_D (Orissa)",
        FacilityCity: "Balikuda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15578,
        Pin: 632202,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15579,
        Pin: 686533,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15580,
        Pin: 474015,
        DispatchCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        OriginCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        FacilityCity: "Gwalior",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 15581,
        Pin: 391150,
        DispatchCenter: "Nasvadi_KNRd_DPP (Gujarat)",
        OriginCenter: "Nasvadi_KNRd_DPP (Gujarat)",
        FacilityCity: "Nasvadi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15582,
        Pin: 174027,
        DispatchCenter: "Ghumarwin_Gndhichk_D (Himachal Pradesh)",
        OriginCenter: "Ghumarwin_Gndhichk_D (Himachal Pradesh)",
        FacilityCity: "Ghumarwin",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15583,
        Pin: 305929,
        DispatchCenter: "Beawar_DC (Rajasthan)",
        OriginCenter: "Beawar_DC (Rajasthan)",
        FacilityCity: "Beawar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15584,
        Pin: 679516,
        DispatchCenter: "Akaloor_Lakdi_DPP (Kerala)",
        OriginCenter: "Akaloor_Lakdi_DPP (Kerala)",
        FacilityCity: "Akaloor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15585,
        Pin: 246481,
        DispatchCenter: "Tharali_Kulsari_D (Uttarakhand)",
        OriginCenter: "Tharali_Kulsari_D (Uttarakhand)",
        FacilityCity: "Tharali",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15586,
        Pin: 400088,
        DispatchCenter: "Mumbai_Chembur_L (Maharashtra)",
        OriginCenter: "Mumbai_Chembur_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15587,
        Pin: 272191,
        DispatchCenter: "Domariyaganj_Chaukhara_D (Uttar Pradesh)",
        OriginCenter: "Domariyaganj_Chaukhara_D (Uttar Pradesh)",
        FacilityCity: "Domariyaganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15588,
        Pin: 829207,
        DispatchCenter: "Latehar_Sasang_DPP (Jharkhand)",
        OriginCenter: "Latehar_Sasang_DPP (Jharkhand)",
        FacilityCity: "Latehar",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15589,
        Pin: 735305,
        DispatchCenter: "Mainaguri_Debinagar_DPP (West Bengal)",
        OriginCenter: "Mainaguri_Debinagar_DPP (West Bengal)",
        FacilityCity: "Mainaguri",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15590,
        Pin: 241303,
        DispatchCenter: "Madhoganj_BaghuliRd_D (Uttar Pradesh)",
        OriginCenter: "Madhoganj_BaghuliRd_D (Uttar Pradesh)",
        FacilityCity: "Madhoganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15591,
        Pin: 143118,
        DispatchCenter: "Goindwal_Fatehabad_D (Punjab)",
        OriginCenter: "Goindwal_Fatehabad_D (Punjab)",
        FacilityCity: "Goindwal",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15592,
        Pin: 305814,
        DispatchCenter: "Makrana_Parbatsar_D (Rajasthan)",
        OriginCenter: "Makrana_Parbatsar_D (Rajasthan)",
        FacilityCity: "Makrana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15593,
        Pin: 415503,
        DispatchCenter: "Koregaon_Lokmanyanagar_D (Maharashtra)",
        OriginCenter: "Koregaon_Lokmanyanagar_D (Maharashtra)",
        FacilityCity: "Koregaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15594,
        Pin: 783350,
        DispatchCenter: "Gossaigaon_TnaliDPP_D (Assam)",
        OriginCenter: "Gossaigaon_TnaliDPP_D (Assam)",
        FacilityCity: "Gossaigaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15595,
        Pin: 641048,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15596,
        Pin: 177204,
        DispatchCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        OriginCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        FacilityCity: "Amb",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15597,
        Pin: 370203,
        DispatchCenter: "Gandhidham_BharatNagar_I (Gujarat)",
        OriginCenter: "Gandhidham_BharatNagar_I (Gujarat)",
        FacilityCity: "Gandhidham",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15598,
        Pin: 343042,
        DispatchCenter: "Jalore_RoopNgr_D (Rajasthan)",
        OriginCenter: "Jalore_RoopNgr_D (Rajasthan)",
        FacilityCity: "Jalore",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15599,
        Pin: 458470,
        DispatchCenter: "Neemuch_Jhanjharwada_D (Madhya Pradesh)",
        OriginCenter: "Neemuch_Jhanjharwada_D (Madhya Pradesh)",
        FacilityCity: "Neemuch",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 15600,
        Pin: 392002,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15601,
        Pin: 413708,
        DispatchCenter: "Kopargaon_Murshatpur_D (Maharashtra)",
        OriginCenter: "Kopargaon_Murshatpur_D (Maharashtra)",
        FacilityCity: "Kopargaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15602,
        Pin: 821308,
        DispatchCenter: "Sasaram_Manikpur_D (Bihar)",
        OriginCenter: "Sasaram_Manikpur_D (Bihar)",
        FacilityCity: "Sasaram",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15603,
        Pin: 583216,
        DispatchCenter: "Haveri_Guttal_D (Karnataka)",
        OriginCenter: "Haveri_Guttal_D (Karnataka)",
        FacilityCity: "Haveri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15604,
        Pin: 609113,
        DispatchCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        OriginCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        FacilityCity: "Sirkazhi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15605,
        Pin: 788831,
        DispatchCenter: "Mahur_Maibang1_D (Assam)",
        OriginCenter: "Mahur_Maibang1_D (Assam)",
        FacilityCity: "Mahur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15606,
        Pin: 841212,
        DispatchCenter: "Ekma_Hansrajpr_D (Bihar)",
        OriginCenter: "Ekma_Hansrajpr_D (Bihar)",
        FacilityCity: "Ekma",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15607,
        Pin: 415205,
        DispatchCenter: "Patan_Ashram_D (Maharashtra)",
        OriginCenter: "Patan_Ashram_D (Maharashtra)",
        FacilityCity: "Patan-MH",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15608,
        Pin: 580020,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15609,
        Pin: 313202,
        DispatchCenter: "Rajsamand_Khamnore_DPP (Rajasthan)",
        OriginCenter: "Rajsamand_Khamnore_DPP (Rajasthan)",
        FacilityCity: "Rajsamand",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15610,
        Pin: 732215,
        DispatchCenter: "Farakka_BngrmDPP_D (West Bengal)",
        OriginCenter: "Farakka_BngrmDPP_D (West Bengal)",
        FacilityCity: "Farakka",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15611,
        Pin: 813124,
        DispatchCenter: "Banka_Shahpur_DPP (Bihar)",
        OriginCenter: "Banka_Shahpur_DPP (Bihar)",
        FacilityCity: "Banka",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15612,
        Pin: 518462,
        DispatchCenter: "Yemignur_Timmappacolony_D (Andhra Pradesh)",
        OriginCenter: "Yemignur_Timmappacolony_D (Andhra Pradesh)",
        FacilityCity: "Yemmiganur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15613,
        Pin: 682030,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15614,
        Pin: 228161,
        DispatchCenter: "Shahganj_RudhauliKalan_D (Uttar Pradesh)",
        OriginCenter: "Shahganj_RudhauliKalan_D (Uttar Pradesh)",
        FacilityCity: "Shahganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15615,
        Pin: 464776,
        DispatchCenter: "Udaipura_Wardno1_DPP (Madhya Pradesh)",
        OriginCenter: "Udaipura_Wardno1_DPP (Madhya Pradesh)",
        FacilityCity: "Udaipura",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 15616,
        Pin: 600049,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15617,
        Pin: 721649,
        DispatchCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        OriginCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        FacilityCity: "Tamluk",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15618,
        Pin: 847106,
        DispatchCenter: "Bharwara_AtarwelPath_D (Bihar)",
        OriginCenter: "Bharwara_AtarwelPath_D (Bihar)",
        FacilityCity: "Bharwara",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15619,
        Pin: 641001,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15620,
        Pin: 160007,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15621,
        Pin: 414505,
        DispatchCenter: "Pathardi_Shivshaktinagar_D (Maharashtra)",
        OriginCenter: "Pathardi_Shivshaktinagar_D (Maharashtra)",
        FacilityCity: "Pathardi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15622,
        Pin: 690110,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15623,
        Pin: 829109,
        DispatchCenter: "Ramgarh_Mandu_D (Jharkhand)",
        OriginCenter: "Ramgarh_Mandu_D (Jharkhand)",
        FacilityCity: "Ramgarh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15624,
        Pin: 635815,
        DispatchCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ambur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15625,
        Pin: 500025,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15626,
        Pin: 783312,
        DispatchCenter: "Dhubri_Tetultol_D (Assam)",
        OriginCenter: "Dhubri_Tetultol_D (Assam)",
        FacilityCity: "Dhubri",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15627,
        Pin: 193202,
        DispatchCenter: "Sopore_BadamBagh_DPP (Jammu & Kashmir)",
        OriginCenter: "Sopore_BadamBagh_DPP (Jammu & Kashmir)",
        FacilityCity: "Sopore",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15628,
        Pin: 364230,
        DispatchCenter: "Dhasa_Nolipara_D (Gujarat)",
        OriginCenter: "Dhasa_Nolipara_D (Gujarat)",
        FacilityCity: "Dhasa",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15629,
        Pin: 627002,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15630,
        Pin: 606603,
        DispatchCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        OriginCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        FacilityCity: "Tiruvannamalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15631,
        Pin: 425415,
        DispatchCenter: "Akkalkuva_MorambaRd_DPP (Maharashtra)",
        OriginCenter: "Akkalkuva_MorambaRd_DPP (Maharashtra)",
        FacilityCity: "Akkalkuva",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15632,
        Pin: 515801,
        DispatchCenter: "Guntakal_Central_D_2 (Andhra Pradesh)",
        OriginCenter: "Guntakal_Central_D_2 (Andhra Pradesh)",
        FacilityCity: "Guntakal",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15633,
        Pin: 110087,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15634,
        Pin: 686019,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15635,
        Pin: 781307,
        DispatchCenter: "Sarthebari_Tmlichp_D (Assam)",
        OriginCenter: "Sarthebari_Tmlichp_D (Assam)",
        FacilityCity: "Sarthebari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15636,
        Pin: 505307,
        DispatchCenter: "Rudrangi_Busstand_DPP (Telangana)",
        OriginCenter: "Rudrangi_Busstand_DPP (Telangana)",
        FacilityCity: "Rudrangi",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15637,
        Pin: 680123,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15638,
        Pin: 276141,
        DispatchCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15639,
        Pin: 176066,
        DispatchCenter: "Khundian_Koka_DPP (Himachal Pradesh)",
        OriginCenter: "Khundian_Koka_DPP (Himachal Pradesh)",
        FacilityCity: "Khundian",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15640,
        Pin: 562163,
        DispatchCenter: "Doddaballapura_Rajanukunte_D (Karnataka)",
        OriginCenter: "Doddaballapura_Rajanukunte_D (Karnataka)",
        FacilityCity: "Doddaballapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15641,
        Pin: 798617,
        DispatchCenter: "Mokokchung_Aongza_D (Nagaland)",
        OriginCenter: "Mokokchung_Aongza_D (Nagaland)",
        FacilityCity: "Mokokchung",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15642,
        Pin: 533103,
        DispatchCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        OriginCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        FacilityCity: "Rajahmundry",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15643,
        Pin: 370670,
        DispatchCenter: "Nakhatrana_Dhormnath_DPP (Gujarat)",
        OriginCenter: "Nakhatrana_Dhormnath_DPP (Gujarat)",
        FacilityCity: "Nakhatrana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15644,
        Pin: 173205,
        DispatchCenter: "Baddi_Barotiwala_L (Himachal Pradesh)",
        OriginCenter: "Baddi_Barotiwala_L (Himachal Pradesh)",
        FacilityCity: "Baddi",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15645,
        Pin: 274404,
        DispatchCenter: "Kushinagar_Patherdewa_D (Uttar Pradesh)",
        OriginCenter: "Kushinagar_Patherdewa_D (Uttar Pradesh)",
        FacilityCity: "Kushinagar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15646,
        Pin: 396421,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15647,
        Pin: 686006,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15648,
        Pin: 262523,
        DispatchCenter: "Lohaghat_Champawat_D (Uttarakhand)",
        OriginCenter: "Lohaghat_Champawat_D (Uttarakhand)",
        FacilityCity: "Lohaghat",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15649,
        Pin: 639117,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15650,
        Pin: 630501,
        DispatchCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15651,
        Pin: 794107,
        DispatchCenter: "Williamnagar_Nogalbibra_DPP (Meghalaya)",
        OriginCenter: "Williamnagar_Nogalbibra_DPP (Meghalaya)",
        FacilityCity: "Williamnagar",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15652,
        Pin: 695572,
        DispatchCenter: "Kallikkad_Mutyvila_D (Kerala)",
        OriginCenter: "Kallikkad_Mutyvila_D (Kerala)",
        FacilityCity: "Kallikkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15653,
        Pin: 822212,
        DispatchCenter: "Bikramgang_Dehrird_D (Bihar)",
        OriginCenter: "Bikramgang_Dehrird_D (Bihar)",
        FacilityCity: "Bikramganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15654,
        Pin: 575008,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15655,
        Pin: 457114,
        DispatchCenter: "Alote_PanchamViharColony_D (Madhya Pradesh)",
        OriginCenter: "Alote_PanchamViharColony_D (Madhya Pradesh)",
        FacilityCity: "Alote",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 15656,
        Pin: 534339,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15657,
        Pin: 487661,
        DispatchCenter: "Gadarwara_MPward_D (Madhya Pradesh)",
        OriginCenter: "Gadarwara_MPward_D (Madhya Pradesh)",
        FacilityCity: "Gadarwara",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 15658,
        Pin: 759039,
        DispatchCenter: "Kamakhyanagar_Mundideuli_D (Orissa)",
        OriginCenter: "Kamakhyanagar_Mundideuli_D (Orissa)",
        FacilityCity: "Kamakhyanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15659,
        Pin: 591322,
        DispatchCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        OriginCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        FacilityCity: "Athani",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15660,
        Pin: 590019,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15661,
        Pin: 176036,
        DispatchCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        OriginCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        FacilityCity: "Nadaun",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15662,
        Pin: 641021,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15663,
        Pin: 641041,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15664,
        Pin: 641654,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15665,
        Pin: 630552,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15666,
        Pin: 781366,
        DispatchCenter: "Baihata_Agdala_D (Assam)",
        OriginCenter: "Baihata_Agdala_D (Assam)",
        FacilityCity: "Baihata",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15667,
        Pin: 515721,
        DispatchCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        OriginCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15668,
        Pin: 342606,
        DispatchCenter: "Gotan_Sadarbazar_DPP (Rajasthan)",
        OriginCenter: "Gotan_Sadarbazar_DPP (Rajasthan)",
        FacilityCity: "Gotan",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15669,
        Pin: 609305,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15670,
        Pin: 530002,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15671,
        Pin: 613052,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15672,
        Pin: 678681,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15673,
        Pin: 751020,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15674,
        Pin: 612204,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15675,
        Pin: 623703,
        DispatchCenter: "Muthukulathur_Selvanygprm_D (Tamil Nadu)",
        OriginCenter: "Muthukulathur_Selvanygprm_D (Tamil Nadu)",
        FacilityCity: "Muthukulathur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15676,
        Pin: 811301,
        DispatchCenter: "Lakhisarai_Central_I_2 (Bihar)",
        OriginCenter: "Lakhisarai_Central_I_2 (Bihar)",
        FacilityCity: "Lakhisarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15677,
        Pin: 416401,
        DispatchCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        OriginCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        FacilityCity: "Miraj",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15678,
        Pin: 533228,
        DispatchCenter: "Mandapeta_Ravulapalem_DPP (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Ravulapalem_DPP (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15679,
        Pin: 246443,
        DispatchCenter: "Joshimath_Singhdhar_D (Uttarakhand)",
        OriginCenter: "Joshimath_Singhdhar_D (Uttarakhand)",
        FacilityCity: "Joshimath",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15680,
        Pin: 685509,
        DispatchCenter: "Kumily_Attappallam_D (Kerala)",
        OriginCenter: "Kumily_Attappallam_D (Kerala)",
        FacilityCity: "Kumily",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15681,
        Pin: 481556,
        DispatchCenter: "Linga_Kurenda_DPP (Madhya Pradesh)",
        OriginCenter: "Linga_Kurenda_DPP (Madhya Pradesh)",
        FacilityCity: "Linga",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 15682,
        Pin: 261303,
        DispatchCenter: "Sidhauli_Rmdna_D (Uttar Pradesh)",
        OriginCenter: "Sidhauli_Rmdna_D (Uttar Pradesh)",
        FacilityCity: "Sidhauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15683,
        Pin: 383327,
        DispatchCenter: "Bayad_PwnPlza_D (Gujarat)",
        OriginCenter: "Bayad_PwnPlza_D (Gujarat)",
        FacilityCity: "Bayad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15684,
        Pin: 522111,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15685,
        Pin: 284501,
        DispatchCenter: "Lalitpur_Govindnagar_D (Uttar Pradesh)",
        OriginCenter: "Lalitpur_Govindnagar_D (Uttar Pradesh)",
        FacilityCity: "Lalitpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15686,
        Pin: 600069,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15687,
        Pin: 700109,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15688,
        Pin: 458668,
        DispatchCenter: "Mandsaur_Dalodarail_D (Madhya Pradesh)",
        OriginCenter: "Mandsaur_Dalodarail_D (Madhya Pradesh)",
        FacilityCity: "Mandsaur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 15689,
        Pin: 222202,
        DispatchCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        OriginCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        FacilityCity: "Mungra Badshahpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15690,
        Pin: 465220,
        DispatchCenter: "Shajapur_Mahupura_D (Madhya Pradesh)",
        OriginCenter: "Shajapur_Mahupura_D (Madhya Pradesh)",
        FacilityCity: "Shajapur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 15691,
        Pin: 313331,
        DispatchCenter: "DeogarhRJ_BapuNgr_DPP (Rajasthan)",
        OriginCenter: "DeogarhRJ_BapuNgr_DPP (Rajasthan)",
        FacilityCity: "DeogarhRJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15692,
        Pin: 421506,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15693,
        Pin: 431005,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15694,
        Pin: 623701,
        DispatchCenter: "Paramakudi_River_D (Tamil Nadu)",
        OriginCenter: "Paramakudi_River_D (Tamil Nadu)",
        FacilityCity: "Paramakudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15695,
        Pin: 230201,
        DispatchCenter: "Lalgopalganj_Anandi_D (Uttar Pradesh)",
        OriginCenter: "Lalgopalganj_Anandi_D (Uttar Pradesh)",
        FacilityCity: "Lal Gopalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15696,
        Pin: 571437,
        DispatchCenter: "Malavalli_KBHclny_D (Karnataka)",
        OriginCenter: "Malavalli_KBHclny_D (Karnataka)",
        FacilityCity: "Malavalli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15697,
        Pin: 121105,
        DispatchCenter: "Palwal_NewColony_D (Haryana)",
        OriginCenter: "Palwal_NewColony_D (Haryana)",
        FacilityCity: "Palwal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15698,
        Pin: 274308,
        DispatchCenter: "Padrauna_BauliChwk_D (Uttar Pradesh)",
        OriginCenter: "Padrauna_BauliChwk_D (Uttar Pradesh)",
        FacilityCity: "Padrauna",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15699,
        Pin: 621804,
        DispatchCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        OriginCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        FacilityCity: "Jayankondam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15700,
        Pin: 586109,
        DispatchCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        OriginCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        FacilityCity: "Bijapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15701,
        Pin: 230136,
        DispatchCenter: "LalganjAjhara_HPPump_D (Uttar Pradesh)",
        OriginCenter: "LalganjAjhara_HPPump_D (Uttar Pradesh)",
        FacilityCity: "Lalganj Ajhara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15702,
        Pin: 370006,
        DispatchCenter: "Bhuj_Madhapar_D (Gujarat)",
        OriginCenter: "Bhuj_Madhapar_D (Gujarat)",
        FacilityCity: "Bhuj",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15703,
        Pin: 643236,
        DispatchCenter: "Kotagiri_CroslySRT_D (Tamil Nadu)",
        OriginCenter: "Kotagiri_CroslySRT_D (Tamil Nadu)",
        FacilityCity: "Kotagiri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15704,
        Pin: 400010,
        DispatchCenter: "Mumbai_Sewri_L (Maharashtra)",
        OriginCenter: "Mumbai_Sewri_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15705,
        Pin: 695103,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15706,
        Pin: 788127,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15707,
        Pin: 689507,
        DispatchCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        OriginCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15708,
        Pin: 402303,
        DispatchCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        OriginCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        FacilityCity: "Mahad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15709,
        Pin: 571424,
        DispatchCenter: "Malavalli_KBHclny_D (Karnataka)",
        OriginCenter: "Malavalli_KBHclny_D (Karnataka)",
        FacilityCity: "Malavalli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15710,
        Pin: 410219,
        DispatchCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        OriginCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15711,
        Pin: 431137,
        DispatchCenter: "Paithan_SantaNGR_D (Maharashtra)",
        OriginCenter: "Paithan_SantaNGR_D (Maharashtra)",
        FacilityCity: "Paithan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15712,
        Pin: 604501,
        DispatchCenter: "Chetpet_Savarapoondi_D (Tamil Nadu)",
        OriginCenter: "Chetpet_Savarapoondi_D (Tamil Nadu)",
        FacilityCity: "Chetpet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15713,
        Pin: 700108,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15714,
        Pin: 141116,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15715,
        Pin: 500002,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15716,
        Pin: 600044,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15717,
        Pin: 532312,
        DispatchCenter: "Ichchapuram_Srikakulam_DPP (Andhra Pradesh)",
        OriginCenter: "Ichchapuram_Srikakulam_DPP (Andhra Pradesh)",
        FacilityCity: "Ichchapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15718,
        Pin: 721657,
        DispatchCenter: "Haldia_Basudevpur_D (West Bengal)",
        OriginCenter: "Haldia_Basudevpur_D (West Bengal)",
        FacilityCity: "Haldia",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15719,
        Pin: 382463,
        DispatchCenter: "Dhandhuka_Dholera_DPP (Gujarat)",
        OriginCenter: "Dhandhuka_Dholera_DPP (Gujarat)",
        FacilityCity: "Dhandhuka",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15720,
        Pin: 637013,
        DispatchCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        OriginCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        FacilityCity: "Namakkal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15721,
        Pin: 635122,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15722,
        Pin: 152107,
        DispatchCenter: "Malout_DC (Punjab)",
        OriginCenter: "Malout_DC (Punjab)",
        FacilityCity: "Malout",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15723,
        Pin: 823005,
        DispatchCenter: "Gaya_Matihani_H (Bihar)",
        OriginCenter: "Gaya_Matihani_H (Bihar)",
        FacilityCity: "Gaya",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15724,
        Pin: 841204,
        DispatchCenter: "Ekma_Hansrajpr_D (Bihar)",
        OriginCenter: "Ekma_Hansrajpr_D (Bihar)",
        FacilityCity: "Ekma",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15725,
        Pin: 413406,
        DispatchCenter: "Vairag_ShradadeviNgr_D (Maharashtra)",
        OriginCenter: "Vairag_ShradadeviNgr_D (Maharashtra)",
        FacilityCity: "Vairag",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15726,
        Pin: 335502,
        DispatchCenter: "Bhadra_GanapatiPlaza_D (Rajasthan)",
        OriginCenter: "Bhadra_GanapatiPlaza_D (Rajasthan)",
        FacilityCity: "Bhadra",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15727,
        Pin: 175106,
        DispatchCenter: "Banjar_Pujaliroad_D (Himachal Pradesh)",
        OriginCenter: "Banjar_Pujaliroad_D (Himachal Pradesh)",
        FacilityCity: "Banjar",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15728,
        Pin: 208007,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15729,
        Pin: 144224,
        DispatchCenter: "Dasuya_Hajipur_D (Punjab)",
        OriginCenter: "Dasuya_Hajipur_D (Punjab)",
        FacilityCity: "Dasuya",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15730,
        Pin: 392025,
        DispatchCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        OriginCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        FacilityCity: "Jambusar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15731,
        Pin: 473993,
        DispatchCenter: "Pichhore_Rajivnagar_D (Madhya Pradesh)",
        OriginCenter: "Pichhore_Rajivnagar_D (Madhya Pradesh)",
        FacilityCity: "Pichhore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 15732,
        Pin: 623525,
        DispatchCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        OriginCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        FacilityCity: "Thiruvadanai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15733,
        Pin: 495009,
        DispatchCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        OriginCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        FacilityCity: "Bilaspur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15734,
        Pin: 424318,
        DispatchCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        OriginCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        FacilityCity: "Dhule",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15735,
        Pin: 416203,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15736,
        Pin: 711305,
        DispatchCenter: "Howrah_Temp_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15737,
        Pin: 518004,
        DispatchCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        OriginCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15738,
        Pin: 518405,
        DispatchCenter: "Nandikotkur_Nandyalroad_D (Andhra Pradesh)",
        OriginCenter: "Nandikotkur_Nandyalroad_D (Andhra Pradesh)",
        FacilityCity: "Nandikotkur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15739,
        Pin: 497447,
        DispatchCenter: "Manendragarh_JKDRoad_D (Chhattisgarh)",
        OriginCenter: "Manendragarh_JKDRoad_D (Chhattisgarh)",
        FacilityCity: "Manendragarh",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15740,
        Pin: 226501,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15741,
        Pin: 249122,
        DispatchCenter: "Pauri_Ghuret_DPP (Uttarakhand)",
        OriginCenter: "Pauri_Ghuret_DPP (Uttarakhand)",
        FacilityCity: "Pauri",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15742,
        Pin: 609312,
        DispatchCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        OriginCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        FacilityCity: "Karaikal",
        FacilityState: "Pondicherry",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15743,
        Pin: 335701,
        DispatchCenter: "Anupgarh_BikanerRd_DPP (Rajasthan)",
        OriginCenter: "Anupgarh_BikanerRd_DPP (Rajasthan)",
        FacilityCity: "Anupgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15744,
        Pin: 764006,
        DispatchCenter: "Jeypore_Bariniput_I (Orissa)",
        OriginCenter: "Jeypore_Bariniput_I (Orissa)",
        FacilityCity: "Jeypore",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15745,
        Pin: 141126,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15746,
        Pin: 491111,
        DispatchCenter: "Patan_MahamayaChwk_DPP (Chhattisgarh)",
        OriginCenter: "Patan_MahamayaChwk_DPP (Chhattisgarh)",
        FacilityCity: "Patan-CG",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15747,
        Pin: 676308,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15748,
        Pin: 762022,
        DispatchCenter: "GUdayagiri_ClubSahi_DPP (Orissa)",
        OriginCenter: "GUdayagiri_ClubSahi_DPP (Orissa)",
        FacilityCity: "G. Udayagiri",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15749,
        Pin: 700056,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15750,
        Pin: 203155,
        DispatchCenter: "Jewar_SJRoad_D (Uttar Pradesh)",
        OriginCenter: "Jewar_SJRoad_D (Uttar Pradesh)",
        FacilityCity: "Jewar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15751,
        Pin: 533262,
        DispatchCenter: "Draksharamam_Anaipeta_D (Andhra Pradesh)",
        OriginCenter: "Draksharamam_Anaipeta_D (Andhra Pradesh)",
        FacilityCity: "Draksharamam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15752,
        Pin: 521366,
        DispatchCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        OriginCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        FacilityCity: "Machilipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15753,
        Pin: 855115,
        DispatchCenter: "Bahadurganj_KrsnaMndr_D (Bihar)",
        OriginCenter: "Bahadurganj_KrsnaMndr_D (Bihar)",
        FacilityCity: "Bahadurganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15754,
        Pin: 176038,
        DispatchCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        OriginCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        FacilityCity: "Kangra",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15755,
        Pin: 160039,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15756,
        Pin: 502336,
        DispatchCenter: "Hyderabad_Medchal1_L (Telangana)",
        OriginCenter: "Hyderabad_Medchal_GW (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15757,
        Pin: 413608,
        DispatchCenter: "Naldurg_RamliaNagar_D (Maharashtra)",
        OriginCenter: "Naldurg_RamliaNagar_D (Maharashtra)",
        FacilityCity: "Naldurg",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15758,
        Pin: 758083,
        DispatchCenter: "Anandapur_Ghasipura_D (Orissa)",
        OriginCenter: "Anandapur_Ghasipura_D (Orissa)",
        FacilityCity: "Anandapur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15759,
        Pin: 328027,
        DispatchCenter: "Dholpur_KamlaColony_D (Rajasthan)",
        OriginCenter: "Dholpur_KamlaColony_D (Rajasthan)",
        FacilityCity: "Dholpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15760,
        Pin: 224229,
        DispatchCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        OriginCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        FacilityCity: "Kumarganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15761,
        Pin: 392220,
        DispatchCenter: "Karjan_Junabzr_DC (Gujarat)",
        OriginCenter: "Karjan_Junabzr_DC (Gujarat)",
        FacilityCity: "Karjan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15762,
        Pin: 743319,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15763,
        Pin: 642126,
        DispatchCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        OriginCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        FacilityCity: "Udumalaipettai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15764,
        Pin: 166031,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15765,
        Pin: 563159,
        DispatchCenter: "Chintamani_Prabhakarlayout_D (Karnataka)",
        OriginCenter: "Chintamani_Prabhakarlayout_D (Karnataka)",
        FacilityCity: "Chintamani",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15766,
        Pin: 361280,
        DispatchCenter: "Khambhalia_Dharampur_D (Gujarat)",
        OriginCenter: "Khambhalia_Dharampur_D (Gujarat)",
        FacilityCity: "Khambhalia",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15767,
        Pin: 627720,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15768,
        Pin: 222145,
        DispatchCenter: "Badlapur_Barauli_D (Uttar Pradesh)",
        OriginCenter: "Badlapur_Barauli_D (Uttar Pradesh)",
        FacilityCity: "Badlapur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15769,
        Pin: 799270,
        DispatchCenter: "Kumarghat_Kanchanpur_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Kumarghat",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15770,
        Pin: 574165,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15771,
        Pin: 801109,
        DispatchCenter: "Bihta_Naubatpur_D (Bihar)",
        OriginCenter: "Bihta_Naubatpur_D (Bihar)",
        FacilityCity: "Bihta",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15772,
        Pin: 431718,
        DispatchCenter: "Degloor_GouklNgr_DPP (Maharashtra)",
        OriginCenter: "Degloor_GouklNgr_DPP (Maharashtra)",
        FacilityCity: "Degloor",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15773,
        Pin: 491996,
        DispatchCenter: "Kawardha_NndivhrClny_D (Chhattisgarh)",
        OriginCenter: "Kawardha_NndivhrClny_D (Chhattisgarh)",
        FacilityCity: "Kawardha",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15774,
        Pin: 273003,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15775,
        Pin: 581113,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15776,
        Pin: 141422,
        DispatchCenter: "Raikot_DC (Punjab)",
        OriginCenter: "Raikot_DC (Punjab)",
        FacilityCity: "Raikot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15777,
        Pin: 415536,
        DispatchCenter: "Wai_Panchwad_DPP (Maharashtra)",
        OriginCenter: "Wai_Panchwad_DPP (Maharashtra)",
        FacilityCity: "Wai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15778,
        Pin: 387325,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15779,
        Pin: 624220,
        DispatchCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        OriginCenter: "Vadipatti_Pallapatti_D (Tamil Nadu)",
        FacilityCity: "Vadipatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15780,
        Pin: 396201,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15781,
        Pin: 813204,
        DispatchCenter: "Kahalgaon_Budhuchak_D (Bihar)",
        OriginCenter: "Kahalgaon_Budhuchak_D (Bihar)",
        FacilityCity: "Kahalgaon",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15782,
        Pin: 641102,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15783,
        Pin: 423105,
        DispatchCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        OriginCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        FacilityCity: "Malegaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15784,
        Pin: 624106,
        DispatchCenter: "Kodaikanal_Mudliyrpurm_D (Tamil Nadu)",
        OriginCenter: "Kodaikanal_Mudliyrpurm_D (Tamil Nadu)",
        FacilityCity: "Kodaikanal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15785,
        Pin: 695105,
        DispatchCenter: "Attingal_Central_D_2 (Kerala)",
        OriginCenter: "Attingal_Central_D_2 (Kerala)",
        FacilityCity: "Attingal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15786,
        Pin: 431401,
        DispatchCenter: "Parbhani_Gajanannagar_D (Maharashtra)",
        OriginCenter: "Parbhani_Gajanannagar_D (Maharashtra)",
        FacilityCity: "Parbhani",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15787,
        Pin: 735301,
        DispatchCenter: "Mekhliganj_WardNo7_DPP (West Bengal)",
        OriginCenter: "Mekhliganj_WardNo7_DPP (West Bengal)",
        FacilityCity: "Mekhliganj",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15788,
        Pin: 847408,
        DispatchCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        OriginCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        FacilityCity: "Jhanjharpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15789,
        Pin: 626116,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15790,
        Pin: 609702,
        DispatchCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        OriginCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        FacilityCity: "Karaikal",
        FacilityState: "Pondicherry",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15791,
        Pin: 227801,
        DispatchCenter: "JagdishpurUP_Paliyapurab_D (Uttar Pradesh)",
        OriginCenter: "JagdishpurUP_Paliyapurab_D (Uttar Pradesh)",
        FacilityCity: "Jagdishpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15792,
        Pin: 700157,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15793,
        Pin: 583226,
        DispatchCenter: "HoovinaHadagali_HMnagar_D (Karnataka)",
        OriginCenter: "HoovinaHadagali_HMnagar_D (Karnataka)",
        FacilityCity: "Hoovina Hadagali",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15794,
        Pin: 403201,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15795,
        Pin: 503180,
        DispatchCenter: "Bodhan_VamshiSchl_D (Telangana)",
        OriginCenter: "Bodhan_VamshiSchl_D (Telangana)",
        FacilityCity: "Bodhan",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15796,
        Pin: 673018,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15797,
        Pin: 622209,
        DispatchCenter: "Pudukkottai_Lnpuram_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Lnpuram_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15798,
        Pin: 140306,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15799,
        Pin: 623120,
        DispatchCenter: "Perunali_Sayalkudi_D (Tamil Nadu)",
        OriginCenter: "Perunali_Sayalkudi_D (Tamil Nadu)",
        FacilityCity: "Perunali",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15800,
        Pin: 825409,
        DispatchCenter: "Domchanch_Jhumritelaiya_D (Jharkhand)",
        OriginCenter: "Domchanch_Jhumritelaiya_D (Jharkhand)",
        FacilityCity: "Domchanch",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15801,
        Pin: 843104,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15802,
        Pin: 250617,
        DispatchCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        OriginCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        FacilityCity: "Baraut",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15803,
        Pin: 176206,
        DispatchCenter: "ShahpurHP_PoliceStn_DPP (Himachal Pradesh)",
        OriginCenter: "ShahpurHP_PoliceStn_DPP (Himachal Pradesh)",
        FacilityCity: "Shahpur-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15804,
        Pin: 243125,
        DispatchCenter: "Faridpur_RanaClny_D (Uttar Pradesh)",
        OriginCenter: "Faridpur_RanaClny_D (Uttar Pradesh)",
        FacilityCity: "Faridpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15805,
        Pin: 686531,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15806,
        Pin: 831013,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15807,
        Pin: 609808,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15808,
        Pin: 736203,
        DispatchCenter: "Kumargram_GovHpt_DPP (West Bengal)",
        OriginCenter: "Kumargram_GovHpt_DPP (West Bengal)",
        FacilityCity: "Kumargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15809,
        Pin: 402122,
        DispatchCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        OriginCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        FacilityCity: "Mangaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15810,
        Pin: 177005,
        DispatchCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        OriginCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        FacilityCity: "Hamirpur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15811,
        Pin: 416505,
        DispatchCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        OriginCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        FacilityCity: "Gadhinglaj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15812,
        Pin: 573115,
        DispatchCenter: "Belur_GendehalliRd_D (Karnataka)",
        OriginCenter: "Belur_GendehalliRd_D (Karnataka)",
        FacilityCity: "Belur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15813,
        Pin: 322704,
        DispatchCenter: "Bonli_Shiwar_DPP (Rajasthan)",
        OriginCenter: "Bonli_Shiwar_DPP (Rajasthan)",
        FacilityCity: "Tonk",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15814,
        Pin: 791114,
        DispatchCenter: "Sagalee_MiniSecretariat_D (Arunachal Pradesh)",
        OriginCenter: "Sagalee_MiniSecretariat_D (Arunachal Pradesh)",
        FacilityCity: "Sagalee",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15815,
        Pin: 387210,
        DispatchCenter: "Tarapur_Darukhanu_DPP (Gujarat)",
        OriginCenter: "Tarapur_Darukhanu_DPP (Gujarat)",
        FacilityCity: "Tarapur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15816,
        Pin: 524311,
        DispatchCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        OriginCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15817,
        Pin: 160031,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15818,
        Pin: 560058,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15819,
        Pin: 761011,
        DispatchCenter: "Chikiti_Bapujeenagar_DPP (Orissa)",
        OriginCenter: "Chikiti_Bapujeenagar_DPP (Orissa)",
        FacilityCity: "Chikiti",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15820,
        Pin: 531001,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15821,
        Pin: 843315,
        DispatchCenter: "Sonbarsa_Sitamarhi_D (Bihar)",
        OriginCenter: "Sonbarsa_Sitamarhi_D (Bihar)",
        FacilityCity: "Sonbarsa",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15822,
        Pin: 636303,
        DispatchCenter: "Mettur_Samballi_D (Tamil Nadu)",
        OriginCenter: "Mettur_Samballi_D (Tamil Nadu)",
        FacilityCity: "Mettur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15823,
        Pin: 843328,
        DispatchCenter: "Runnisaidpur_Madhvnagar_D (Bihar)",
        OriginCenter: "Runnisaidpur_Madhvnagar_D (Bihar)",
        FacilityCity: "Runnisaidpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15824,
        Pin: 700060,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15825,
        Pin: 531020,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15826,
        Pin: 212203,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15827,
        Pin: 562110,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15828,
        Pin: 756130,
        DispatchCenter: "Tihidi_Hanspat_DPP (Orissa)",
        OriginCenter: "Tihidi_Hanspat_DPP (Orissa)",
        FacilityCity: "Tihidi",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15829,
        Pin: 572113,
        DispatchCenter: "Sira_Hanumanthnagara_D (Karnataka)",
        OriginCenter: "Sira_Hanumanthnagara_D (Karnataka)",
        FacilityCity: "Sira",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15830,
        Pin: 124113,
        DispatchCenter: "Rohtak_IndustrialArea_D (Haryana)",
        OriginCenter: "Rohtak_IndustrialArea_D (Haryana)",
        FacilityCity: "Rohtak",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15831,
        Pin: 721601,
        DispatchCenter: "Bajkul_Kismat_D (West Bengal)",
        OriginCenter: "Bajkul_Kismat_D (West Bengal)",
        FacilityCity: "Bajkul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15832,
        Pin: 332304,
        DispatchCenter: "Ratangarh_Goldencolony_D (Rajasthan)",
        OriginCenter: "Ratangarh_Goldencolony_D (Rajasthan)",
        FacilityCity: "Ratangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15833,
        Pin: 623536,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15834,
        Pin: 586205,
        DispatchCenter: "Indi_Chadchan_D (Karnataka)",
        OriginCenter: "Indi_Chadchan_D (Karnataka)",
        FacilityCity: "Indi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15835,
        Pin: 585236,
        DispatchCenter: "Afzalpur_Usmaniacolony_D (Karnataka)",
        OriginCenter: "Afzalpur_Usmaniacolony_D (Karnataka)",
        FacilityCity: "Afzalpur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15836,
        Pin: 802127,
        DispatchCenter: "Dumraon_MahvidylaRd_D (Bihar)",
        OriginCenter: "Dumraon_MahvidylaRd_D (Bihar)",
        FacilityCity: "Dumraon",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15837,
        Pin: 303348,
        DispatchCenter: "Phulera_NarenaRD_D (Rajasthan)",
        OriginCenter: "Phulera_NarenaRD_D (Rajasthan)",
        FacilityCity: "Phulera",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15838,
        Pin: 147008,
        DispatchCenter: "Patiala_UpkarNagar_D (Punjab)",
        OriginCenter: "Patiala_UpkarNagar_D (Punjab)",
        FacilityCity: "Patiala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15839,
        Pin: 600131,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15840,
        Pin: 586210,
        DispatchCenter: "Kolhar_ShivajiCircle_D (Karnataka)",
        OriginCenter: "Kolhar_ShivajiCircle_D (Karnataka)",
        FacilityCity: "Kolhar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15841,
        Pin: 248013,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15842,
        Pin: 411003,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15843,
        Pin: 370660,
        DispatchCenter: "Naliya_Jlramcpx_D (Gujarat)",
        OriginCenter: "Naliya_Jlramcpx_D (Gujarat)",
        FacilityCity: "Naliya",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15844,
        Pin: 181205,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15845,
        Pin: 333025,
        DispatchCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        OriginCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        FacilityCity: "Jhunjhunu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15846,
        Pin: 515301,
        DispatchCenter: "Madakasira_RTCStand_D (Andhra Pradesh)",
        OriginCenter: "Madakasira_RTCStand_D (Andhra Pradesh)",
        FacilityCity: "Madakasira",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15847,
        Pin: 623102,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15848,
        Pin: 626107,
        DispatchCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        OriginCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        FacilityCity: "Aruppukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15849,
        Pin: 560115,
        DispatchCenter: "Bangalore_Koralur_L (Karnataka)",
        OriginCenter: "Bangalore_Soukyaroad_GW (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15850,
        Pin: 758034,
        DispatchCenter: "Barbil_Baspani_D (Orissa)",
        OriginCenter: "Barbil_Baspani_D (Orissa)",
        FacilityCity: "Barbil",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15851,
        Pin: 442901,
        DispatchCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        OriginCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        FacilityCity: "Chandrapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15852,
        Pin: 796770,
        DispatchCenter: "Chawngte_Randhakpur_D (Mizoram)",
        OriginCenter: "Chawngte_Randhakpur_D (Mizoram)",
        FacilityCity: "Chawngte",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15853,
        Pin: 363642,
        DispatchCenter: "Morbi_MahendraNagar_D (Gujarat)",
        OriginCenter: "Morbi_MahendraNagar_D (Gujarat)",
        FacilityCity: "Morbi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15854,
        Pin: 521230,
        DispatchCenter: "Mylavaram_NuzviduRd_D (Andhra Pradesh)",
        OriginCenter: "Mylavaram_NuzviduRd_D (Andhra Pradesh)",
        FacilityCity: "Mylavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15855,
        Pin: 443002,
        DispatchCenter: "Buldhana_Sanenagar_DPP (Maharashtra)",
        OriginCenter: "Buldhana_Sanenagar_DPP (Maharashtra)",
        FacilityCity: "Buldhana",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15856,
        Pin: 581346,
        DispatchCenter: "Hangal_PalaRd_D (Karnataka)",
        OriginCenter: "Hangal_PalaRd_D (Karnataka)",
        FacilityCity: "Hangal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15857,
        Pin: 805130,
        DispatchCenter: "Pakribarwan_Warisaliganj_D (Bihar)",
        OriginCenter: "Pakribarwan_Warisaliganj_D (Bihar)",
        FacilityCity: "Pakribarwan",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15858,
        Pin: 360011,
        DispatchCenter: "Rajkot_Amargadh_H (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15859,
        Pin: 411039,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15860,
        Pin: 781004,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15861,
        Pin: 534416,
        DispatchCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        OriginCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        FacilityCity: "Tadepalligudem",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15862,
        Pin: 575025,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15863,
        Pin: 802211,
        DispatchCenter: "Malyabag_ArrahRd_D (Bihar)",
        OriginCenter: "Malyabag_ArrahRd_D (Bihar)",
        FacilityCity: "Malyabag",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15864,
        Pin: 630302,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15865,
        Pin: 735209,
        DispatchCenter: "Chalsa_BusStand_D (West Bengal)",
        OriginCenter: "Chalsa_BusStand_D (West Bengal)",
        FacilityCity: "Chalsa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15866,
        Pin: 385011,
        DispatchCenter: "Palanpur_Esbipura1_D (Gujarat)",
        OriginCenter: "Palanpur_Esbipura1_D (Gujarat)",
        FacilityCity: "Palanpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15867,
        Pin: 604502,
        DispatchCenter: "Chetpet_Savarapoondi_D (Tamil Nadu)",
        OriginCenter: "Chetpet_Savarapoondi_D (Tamil Nadu)",
        FacilityCity: "Chetpet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15868,
        Pin: 245101,
        DispatchCenter: "Hapur_Sanjaycolony_D (Uttar Pradesh)",
        OriginCenter: "Hapur_Sanjaycolony_D (Uttar Pradesh)",
        FacilityCity: "Hapur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15869,
        Pin: 631002,
        DispatchCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15870,
        Pin: 721170,
        DispatchCenter: "Kolaghat_GopalNgr_DPP (West Bengal)",
        OriginCenter: "Kolaghat_GopalNgr_DPP (West Bengal)",
        FacilityCity: "Kolaghat",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15871,
        Pin: 786150,
        DispatchCenter: "DoomDooma_Pthrgoan_D (Assam)",
        OriginCenter: "DoomDooma_Pthrgoan_D (Assam)",
        FacilityCity: "Doom Dooma",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15872,
        Pin: 737136,
        DispatchCenter: "Rangpo_Khanikhola_I (Sikkim)",
        OriginCenter: "Rangpo_Khanikhola_I (Sikkim)",
        FacilityCity: "Rangpo",
        FacilityState: "Sikkim",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15873,
        Pin: 621128,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15874,
        Pin: 577113,
        DispatchCenter: "Mudigere_HesglDPP_D (Karnataka)",
        OriginCenter: "Mudigere_HesglDPP_D (Karnataka)",
        FacilityCity: "Mudigere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15875,
        Pin: 741221,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15876,
        Pin: 517127,
        DispatchCenter: "Chittoor_Murukambattu_D (Andhra Pradesh)",
        OriginCenter: "Chittoor_Murukambattu_D (Andhra Pradesh)",
        FacilityCity: "Chittoor",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15877,
        Pin: 364315,
        DispatchCenter: "Botad_Paliyadrd_D (Gujarat)",
        OriginCenter: "Botad_Paliyadrd_D (Gujarat)",
        FacilityCity: "Botad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15878,
        Pin: 403006,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15879,
        Pin: 271875,
        DispatchCenter: "Bahraich_Dularpur_H (Uttar Pradesh)",
        OriginCenter: "Bahraich_Dularpur_H (Uttar Pradesh)",
        FacilityCity: "Bahraich",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15880,
        Pin: 678601,
        DispatchCenter: "Thachnttukra_Mangadn_D (Kerala)",
        OriginCenter: "Thachnttukra_Mangadn_D (Kerala)",
        FacilityCity: "Thachanattukara",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15881,
        Pin: 143203,
        DispatchCenter: "Tarsikka_Beas_D (Punjab)",
        OriginCenter: "Tarsikka_Beas_D (Punjab)",
        FacilityCity: "Beas",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15882,
        Pin: 733151,
        DispatchCenter: "Balurghat_AkhirDPP_D (West Bengal)",
        OriginCenter: "Balurghat_AkhirDPP_D (West Bengal)",
        FacilityCity: "Balurghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15883,
        Pin: 401505,
        DispatchCenter: "Palghar_Maan_L (Maharashtra)",
        OriginCenter: "Palghar_Maan_L (Maharashtra)",
        FacilityCity: "Palghar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15884,
        Pin: 824125,
        DispatchCenter: "Goh_Golapar_D (Bihar)",
        OriginCenter: "Goh_Golapar_D (Bihar)",
        FacilityCity: "Goh",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15885,
        Pin: 123035,
        DispatchCenter: "Rewari_Gopalchowk_D (Haryana)",
        OriginCenter: "Rewari_Gopalchowk_D (Haryana)",
        FacilityCity: "Rewari",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15886,
        Pin: 143306,
        DispatchCenter: "Patti_TarnTaran_D (Punjab)",
        OriginCenter: "Patti_TarnTaran_D (Punjab)",
        FacilityCity: "Patti",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15887,
        Pin: 416602,
        DispatchCenter: "Kankavali_NrdawDPP_D (Maharashtra)",
        OriginCenter: "Kankavali_NrdawDPP_D (Maharashtra)",
        FacilityCity: "Kankavali",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15888,
        Pin: 743438,
        DispatchCenter: "Swarupnagar_MlangaPra_DPP (West Bengal)",
        OriginCenter: "Swarupnagar_MlangaPra_DPP (West Bengal)",
        FacilityCity: "Swarupnagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15889,
        Pin: 230130,
        DispatchCenter: "LalganjAjhara_HPPump_D (Uttar Pradesh)",
        OriginCenter: "LalganjAjhara_HPPump_D (Uttar Pradesh)",
        FacilityCity: "Lalganj Ajhara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15890,
        Pin: 685515,
        DispatchCenter: "Kattappana_EratayarRoad_D (Kerala)",
        OriginCenter: "Kattappana_EratayarRoad_D (Kerala)",
        FacilityCity: "Kattappana",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15891,
        Pin: 679521,
        DispatchCenter: "Shoranur_Vaniyamkulam_D (Kerala)",
        OriginCenter: "Shoranur_Vaniyamkulam_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15892,
        Pin: 415601,
        DispatchCenter: "Chiplun_Burumtali_D (Maharashtra)",
        OriginCenter: "Chiplun_Burumtali_D (Maharashtra)",
        FacilityCity: "Chiplun",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15893,
        Pin: 332707,
        DispatchCenter: "Ajeetgarh_Jugrajpura_D (Rajasthan)",
        OriginCenter: "Ajeetgarh_Jugrajpura_D (Rajasthan)",
        FacilityCity: "Ajeetgarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15894,
        Pin: 431122,
        DispatchCenter: "Beed_Jarud_D (Maharashtra)",
        OriginCenter: "Beed_Jarud_D (Maharashtra)",
        FacilityCity: "Beed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15895,
        Pin: 678641,
        DispatchCenter: "Akaloor_Lakdi_DPP (Kerala)",
        OriginCenter: "Akaloor_Lakdi_DPP (Kerala)",
        FacilityCity: "Akaloor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15896,
        Pin: 571490,
        DispatchCenter: "Hanur_Maruthinagar_D (Karnataka)",
        OriginCenter: "Hanur_Maruthinagar_D (Karnataka)",
        FacilityCity: "Hanur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15897,
        Pin: 768234,
        DispatchCenter: "Brajrajnagar_Jagannath_DPP (Orissa)",
        OriginCenter: "Brajrajnagar_Jagannath_DPP (Orissa)",
        FacilityCity: "Brajrajnagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15898,
        Pin: 341507,
        DispatchCenter: "Renwal_Wardno4_D (Rajasthan)",
        OriginCenter: "Renwal_Wardno4_D (Rajasthan)",
        FacilityCity: "Renwal",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15899,
        Pin: 494334,
        DispatchCenter: "Kanker_KrisnKunj_D (Chhattisgarh)",
        OriginCenter: "Kanker_KrisnKunj_D (Chhattisgarh)",
        FacilityCity: "Kanker",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15900,
        Pin: 756181,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15901,
        Pin: 134002,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15902,
        Pin: 785656,
        DispatchCenter: "Nazira_Amina_D (Assam)",
        OriginCenter: "Nazira_Amina_D (Assam)",
        FacilityCity: "Nazira",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15903,
        Pin: 841245,
        DispatchCenter: "Siwan_Andar_D (Bihar)",
        OriginCenter: "Siwan_Andar_D (Bihar)",
        FacilityCity: "Siwan",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15904,
        Pin: 307019,
        DispatchCenter: "Pindwara_AjariChwk_DPP (Rajasthan)",
        OriginCenter: "Pindwara_AjariChwk_DPP (Rajasthan)",
        FacilityCity: "Pindwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15905,
        Pin: 532291,
        DispatchCenter: "Sompeta_PostOffice_DPP (Andhra Pradesh)",
        OriginCenter: "Sompeta_PostOffice_DPP (Andhra Pradesh)",
        FacilityCity: "Sompeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15906,
        Pin: 851212,
        DispatchCenter: "Naugachia_Mahaddipur_DPP (Bihar)",
        OriginCenter: "Naugachia_Mahaddipur_DPP (Bihar)",
        FacilityCity: "Naugachia",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15907,
        Pin: 798601,
        DispatchCenter: "Mokokchung_Aongza_D (Nagaland)",
        OriginCenter: "Mokokchung_Aongza_D (Nagaland)",
        FacilityCity: "Mokokchung",
        FacilityState: "Nagaland",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15908,
        Pin: 627111,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15909,
        Pin: 416623,
        DispatchCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        OriginCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        FacilityCity: "Devgad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15910,
        Pin: 413603,
        DispatchCenter: "Naldurg_RamliaNagar_D (Maharashtra)",
        OriginCenter: "Naldurg_RamliaNagar_D (Maharashtra)",
        FacilityCity: "Naldurg",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15911,
        Pin: 509204,
        DispatchCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        OriginCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        FacilityCity: "Mahabubnagar",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15912,
        Pin: 384265,
        DispatchCenter: "Patan_Hansapur_D (Gujarat)",
        OriginCenter: "Patan_Hansapur_D (Gujarat)",
        FacilityCity: "Patan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15913,
        Pin: 322703,
        DispatchCenter: "Bonli_Shiwar_DPP (Rajasthan)",
        OriginCenter: "Bonli_Shiwar_DPP (Rajasthan)",
        FacilityCity: "Tonk",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15914,
        Pin: 229208,
        DispatchCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        OriginCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        FacilityCity: "Lalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15915,
        Pin: 122216,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15916,
        Pin: 415510,
        DispatchCenter: "Vaduj_Satewdi_D (Maharashtra)",
        OriginCenter: "Vaduj_Satewdi_D (Maharashtra)",
        FacilityCity: "Vaduj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15917,
        Pin: 638010,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15918,
        Pin: 226202,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15919,
        Pin: 229210,
        DispatchCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        OriginCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        FacilityCity: "Lalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15920,
        Pin: 762106,
        DispatchCenter: "Phulbani_Sarangada_D (Orissa)",
        OriginCenter: "Phulbani_Sarangada_D (Orissa)",
        FacilityCity: "Phulbani",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15921,
        Pin: 392004,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15922,
        Pin: 835210,
        DispatchCenter: "Khunti_JojoToli_DPP (Jharkhand)",
        OriginCenter: "Khunti_JojoToli_DPP (Jharkhand)",
        FacilityCity: "Khunti",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15923,
        Pin: 796730,
        DispatchCenter: "Chawngte_Randhakpur_D (Mizoram)",
        OriginCenter: "Chawngte_Randhakpur_D (Mizoram)",
        FacilityCity: "Chawngte",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15924,
        Pin: 370135,
        DispatchCenter: "Bhachau_Mandivichowk_D (Gujarat)",
        OriginCenter: "Bhachau_Mandivichowk_D (Gujarat)",
        FacilityCity: "Bhachau",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15925,
        Pin: 581360,
        DispatchCenter: "Karwar_Sadashivgad_D (Karnataka)",
        OriginCenter: "Karwar_Sadashivgad_D (Karnataka)",
        FacilityCity: "Karwar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15926,
        Pin: 127046,
        DispatchCenter: "Siwani_Ward12_D (Haryana)",
        OriginCenter: "Siwani_Ward12_D (Haryana)",
        FacilityCity: "Siwani",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15927,
        Pin: 176047,
        DispatchCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        OriginCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        FacilityCity: "Kangra",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15928,
        Pin: 283112,
        DispatchCenter: "Kheragarh_Shivmndr_D (Uttar Pradesh)",
        OriginCenter: "Kheragarh_Shivmndr_D (Uttar Pradesh)",
        FacilityCity: "Kheragarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15929,
        Pin: 306102,
        DispatchCenter: "Beawar_DC (Rajasthan)",
        OriginCenter: "Beawar_DC (Rajasthan)",
        FacilityCity: "Beawar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15930,
        Pin: 573150,
        DispatchCenter: "Ramanathapura_Koodalur_D (Karnataka)",
        OriginCenter: "Ramanathapura_Koodalur_D (Karnataka)",
        FacilityCity: "Ramanathapura",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15931,
        Pin: 800004,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15932,
        Pin: 757046,
        DispatchCenter: "Rairangpur_Sanchampauda_D (Orissa)",
        OriginCenter: "Rairangpur_Sanchampauda_D (Orissa)",
        FacilityCity: "Rairangpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15933,
        Pin: 383460,
        DispatchCenter: "Bhiloda_NirSagarPlaza_D (Gujarat)",
        OriginCenter: "Bhiloda_NirSagarPlaza_D (Gujarat)",
        FacilityCity: "Bhiloda",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15934,
        Pin: 312208,
        DispatchCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        OriginCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        FacilityCity: "Chittaurgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15935,
        Pin: 380020,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15936,
        Pin: 416214,
        DispatchCenter: "Bambavade_SarudRd_DPP (Maharashtra)",
        OriginCenter: "Bambavade_SarudRd_DPP (Maharashtra)",
        FacilityCity: "Bambavade",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15937,
        Pin: 679564,
        DispatchCenter: "Thrissur_Chavakkad_D (Kerala)",
        OriginCenter: "Thrissur_Chavakkad_D (Kerala)",
        FacilityCity: "Guruvayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 15938,
        Pin: 123414,
        DispatchCenter: "Rewari_Gopalchowk_D (Haryana)",
        OriginCenter: "Rewari_Gopalchowk_D (Haryana)",
        FacilityCity: "Rewari",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15939,
        Pin: 627431,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15940,
        Pin: 134109,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15941,
        Pin: 854317,
        DispatchCenter: "Katihar_Barsoi_D (Bihar)",
        OriginCenter: "Katihar_Barsoi_D (Bihar)",
        FacilityCity: "Katihar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15942,
        Pin: 416518,
        DispatchCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        OriginCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        FacilityCity: "Sawantwadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15943,
        Pin: 712233,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15944,
        Pin: 638701,
        DispatchCenter: "Tirupur_Kangeyam_D (Tamil Nadu)",
        OriginCenter: "Tirupur_Kangeyam_D (Tamil Nadu)",
        FacilityCity: "Palladam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15945,
        Pin: 460668,
        DispatchCenter: "Athner_College_D (Madhya Pradesh)",
        OriginCenter: "Athner_College_D (Madhya Pradesh)",
        FacilityCity: "Athner",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 15946,
        Pin: 505526,
        DispatchCenter: "DharmapuriTS_Boyawada_D (Telangana)",
        OriginCenter: "DharmapuriTS_Boyawada_D (Telangana)",
        FacilityCity: "Dharmapuri-TS",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15947,
        Pin: 303701,
        DispatchCenter: "Kalwar_Bhagwatinagar_D (Rajasthan)",
        OriginCenter: "Kalwar_Bhagwatinagar_D (Rajasthan)",
        FacilityCity: "Kalwar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15948,
        Pin: 332713,
        DispatchCenter: "Neemkathana_Chhawani_D (Rajasthan)",
        OriginCenter: "Neemkathana_Chhawani_D (Rajasthan)",
        FacilityCity: "Neem ka Thana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15949,
        Pin: 713102,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 15950,
        Pin: 612801,
        DispatchCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        OriginCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15951,
        Pin: 783361,
        DispatchCenter: "Gossaigaon_TnaliDPP_D (Assam)",
        OriginCenter: "Gossaigaon_TnaliDPP_D (Assam)",
        FacilityCity: "Gossaigaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15952,
        Pin: 462013,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 15953,
        Pin: 415530,
        DispatchCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        OriginCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        FacilityCity: "Satara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 15954,
        Pin: 610201,
        DispatchCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        FacilityCity: "Thiruthuraipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15955,
        Pin: 572214,
        DispatchCenter: "Chikkanayakanahalli_PoliceStn_DPP (Karnataka)",
        OriginCenter: "Chikkanayakanahalli_PoliceStn_DPP (Karnataka)",
        FacilityCity: "Chikkanayakanahalli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15956,
        Pin: 689114,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15957,
        Pin: 754114,
        DispatchCenter: "Jagatsinghpur_Bnbrda_D (Orissa)",
        OriginCenter: "Jagatsinghpur_Bnbrda_D (Orissa)",
        FacilityCity: "Jagatsinghpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15958,
        Pin: 614001,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15959,
        Pin: 682002,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 15960,
        Pin: 585106,
        DispatchCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        OriginCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        FacilityCity: "Gulbarga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15961,
        Pin: 571127,
        DispatchCenter: "Chamarajanagar_Galipur_D (Karnataka)",
        OriginCenter: "Chamarajanagar_Galipur_D (Karnataka)",
        FacilityCity: "Chamarajanagar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15962,
        Pin: 176061,
        DispatchCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        OriginCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        FacilityCity: "Palampur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15963,
        Pin: 848136,
        DispatchCenter: "Samastipur_RhmprRdli_D (Bihar)",
        OriginCenter: "Samastipur_RhmprRdli_D (Bihar)",
        FacilityCity: "Samastipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15964,
        Pin: 248014,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15965,
        Pin: 431812,
        DispatchCenter: "Kinwat_Mahurrd_D (Maharashtra)",
        OriginCenter: "Kinwat_Mahurrd_D (Maharashtra)",
        FacilityCity: "Kinwat",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 15966,
        Pin: 364290,
        DispatchCenter: "Mahuva_Aambavadi_DPP (Gujarat)",
        OriginCenter: "Mahuva_Aambavadi_DPP (Gujarat)",
        FacilityCity: "Mahuva",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15967,
        Pin: 382007,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15968,
        Pin: 365430,
        DispatchCenter: "Amreli_Bhojalpara_D (Gujarat)",
        OriginCenter: "Amreli_Bhojalpara_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15969,
        Pin: 147105,
        DispatchCenter: "Patran_Kahangarh_D (Punjab)",
        OriginCenter: "Patran_Kahangarh_D (Punjab)",
        FacilityCity: "Patran",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15970,
        Pin: 805104,
        DispatchCenter: "Nawada_Gondapur_D (Bihar)",
        OriginCenter: "Nawada_Gondapur_D (Bihar)",
        FacilityCity: "Nawada",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15971,
        Pin: 625104,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15972,
        Pin: 243640,
        DispatchCenter: "Sahaswan_Mainmarket_D (Uttar Pradesh)",
        OriginCenter: "Sahaswan_Mainmarket_D (Uttar Pradesh)",
        FacilityCity: "Sahaswan",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15973,
        Pin: 571428,
        DispatchCenter: "Maddur_Malavalli_D (Karnataka)",
        OriginCenter: "Maddur_Malavalli_D (Karnataka)",
        FacilityCity: "Maddur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15974,
        Pin: 742104,
        DispatchCenter: "Murshidabad_Jiaganj_D (West Bengal)",
        OriginCenter: "Murshidabad_Jiaganj_D (West Bengal)",
        FacilityCity: "Murshidabad",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15975,
        Pin: 176111,
        DispatchCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        OriginCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15976,
        Pin: 563120,
        DispatchCenter: "Bethamangala_Sriramatemp_D (Karnataka)",
        OriginCenter: "Bethamangala_Sriramatemp_D (Karnataka)",
        FacilityCity: "Bethamangala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15977,
        Pin: 380021,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15978,
        Pin: 767002,
        DispatchCenter: "Balangir_PalaceLine_D (Orissa)",
        OriginCenter: "Balangir_PalaceLine_D (Orissa)",
        FacilityCity: "Balangir",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 15979,
        Pin: 585265,
        DispatchCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        OriginCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        FacilityCity: "Gulbarga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15980,
        Pin: 614704,
        DispatchCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        OriginCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        FacilityCity: "Pattukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15981,
        Pin: 621709,
        DispatchCenter: "Pennadam_Puthar_D (Tamil Nadu)",
        OriginCenter: "Pennadam_Puthar_D (Tamil Nadu)",
        FacilityCity: "Pennadam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15982,
        Pin: 387230,
        DispatchCenter: "Nadiad_Shantinagar_D (Gujarat)",
        OriginCenter: "Nadiad_Shantinagar_D (Gujarat)",
        FacilityCity: "Nadiad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 15983,
        Pin: 462120,
        DispatchCenter: "Berasia_Mohanpura_DPP (Madhya Pradesh)",
        OriginCenter: "Berasia_Mohanpura_DPP (Madhya Pradesh)",
        FacilityCity: "Berasia",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 15984,
        Pin: 160042,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 15985,
        Pin: 507113,
        DispatchCenter: "Chintoor_PoliceStn_DPP (Telangana)",
        OriginCenter: "Chintoor_PoliceStn_DPP (Telangana)",
        FacilityCity: "Chintoor",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15986,
        Pin: 585260,
        DispatchCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        OriginCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        FacilityCity: "Gulbarga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15987,
        Pin: 505514,
        DispatchCenter: "Mancherial_Nuspur_I (Telangana)",
        OriginCenter: "Mancherial_Nuspur_I (Telangana)",
        FacilityCity: "Mancherial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15988,
        Pin: 821311,
        DispatchCenter: "Rohtas_Sundarganj_D (Bihar)",
        OriginCenter: "Rohtas_Sundarganj_D (Bihar)",
        FacilityCity: "Rohtas",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15989,
        Pin: 247470,
        DispatchCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        OriginCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        FacilityCity: "Haridwar",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 15990,
        Pin: 785704,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15991,
        Pin: 388255,
        DispatchCenter: "Balasinor_Alnoor_DPP (Gujarat)",
        OriginCenter: "Balasinor_Alnoor_DPP (Gujarat)",
        FacilityCity: "Balasinor",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 15992,
        Pin: 631210,
        DispatchCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 15993,
        Pin: 759013,
        DispatchCenter: "Dhenkanal_Korian_D (Orissa)",
        OriginCenter: "Dhenkanal_Korian_D (Orissa)",
        FacilityCity: "Dhenkanal",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 15994,
        Pin: 246745,
        DispatchCenter: "Kanth_ModaPatti_DPP (Uttar Pradesh)",
        OriginCenter: "Kanth_ModaPatti_DPP (Uttar Pradesh)",
        FacilityCity: "Kanth",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 15995,
        Pin: 577132,
        DispatchCenter: "Mudigere_HesglDPP_D (Karnataka)",
        OriginCenter: "Mudigere_HesglDPP_D (Karnataka)",
        FacilityCity: "Mudigere",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 15996,
        Pin: 477001,
        DispatchCenter: "Bhind_EtwahDPP_D (Madhya Pradesh)",
        OriginCenter: "Bhind_EtwahDPP_D (Madhya Pradesh)",
        FacilityCity: "Bhind",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 15997,
        Pin: 313705,
        DispatchCenter: "Gogunda_Bypass_DPP (Rajasthan)",
        OriginCenter: "Gogunda_Bypass_DPP (Rajasthan)",
        FacilityCity: "Gogunda",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 15998,
        Pin: 845432,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 15999,
        Pin: 742201,
        DispatchCenter: "Raghunathganj_NH12_D (West Bengal)",
        OriginCenter: "Raghunathganj_NH12_D (West Bengal)",
        FacilityCity: "Raghunathganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16000,
        Pin: 202411,
        DispatchCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        OriginCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        FacilityCity: "Chandausi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16001,
        Pin: 626549,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16002,
        Pin: 282001,
        DispatchCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        OriginCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        FacilityCity: "Agra",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16003,
        Pin: 331803,
        DispatchCenter: "ShriDungargarh_RaniBzr_DPP (Rajasthan)",
        OriginCenter: "ShriDungargarh_RaniBzr_DPP (Rajasthan)",
        FacilityCity: "Shri Dungargarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16004,
        Pin: 683549,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16005,
        Pin: 182122,
        DispatchCenter: "Ramnagar_Nouji_D (Jammu & Kashmir)",
        OriginCenter: "Ramnagar_Nouji_D (Jammu & Kashmir)",
        FacilityCity: "Ramnagar-JK",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16006,
        Pin: 700015,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16007,
        Pin: 227811,
        DispatchCenter: "Jagdishpur_Canbank_D (Uttar Pradesh)",
        OriginCenter: "Jagdishpur_Canbank_D (Uttar Pradesh)",
        FacilityCity: "Jagdishpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16008,
        Pin: 686615,
        DispatchCenter: "Kanjiramattom_Piravom_D (Kerala)",
        OriginCenter: "Kanjiramattom_Piravom_D (Kerala)",
        FacilityCity: "Kanjiramattom",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16009,
        Pin: 560019,
        DispatchCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        OriginCenter: "Bangalore_SatelliteTwn_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16010,
        Pin: 456224,
        DispatchCenter: "Nagda_PrkashColny_D (Madhya Pradesh)",
        OriginCenter: "Nagda_PrkashColny_D (Madhya Pradesh)",
        FacilityCity: "Nagda",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 16011,
        Pin: 176320,
        DispatchCenter: "Chamba_Sundla_D (Himachal Pradesh)",
        OriginCenter: "Chamba_Sundla_D (Himachal Pradesh)",
        FacilityCity: "Sundla",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16012,
        Pin: 284302,
        DispatchCenter: "Poonchh_AranchRd_D (Uttar Pradesh)",
        OriginCenter: "Poonchh_AranchRd_D (Uttar Pradesh)",
        FacilityCity: "Poonchh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16013,
        Pin: 416413,
        DispatchCenter: "Jath_Laxmichowk_D (Maharashtra)",
        OriginCenter: "Jath_Laxmichowk_D (Maharashtra)",
        FacilityCity: "Jath",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16014,
        Pin: 670621,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16015,
        Pin: 396230,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16016,
        Pin: 752087,
        DispatchCenter: "Nayagarh_Durgprsd_D (Orissa)",
        OriginCenter: "Nayagarh_Durgprsd_D (Orissa)",
        FacilityCity: "Nayagarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16017,
        Pin: 333801,
        DispatchCenter: "Neemkathana_Chhawani_D (Rajasthan)",
        OriginCenter: "Neemkathana_Chhawani_D (Rajasthan)",
        FacilityCity: "Neem ka Thana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16018,
        Pin: 503321,
        DispatchCenter: "Banswada_Teacherscolony_D (Telangana)",
        OriginCenter: "Banswada_Teacherscolony_D (Telangana)",
        FacilityCity: "Banswada",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16019,
        Pin: 680617,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16020,
        Pin: 413215,
        DispatchCenter: "Solapur_Balepeth_I (Maharashtra)",
        OriginCenter: "Solapur_Balepeth_I (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16021,
        Pin: 177212,
        DispatchCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        OriginCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        FacilityCity: "Amb",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16022,
        Pin: 636452,
        DispatchCenter: "Mettur_Samballi_D (Tamil Nadu)",
        OriginCenter: "Mettur_Samballi_D (Tamil Nadu)",
        FacilityCity: "Mettur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16023,
        Pin: 442604,
        DispatchCenter: "Chamorshi_Ganeshnagar_DPP (Maharashtra)",
        OriginCenter: "Chamorshi_Ganeshnagar_DPP (Maharashtra)",
        FacilityCity: "Chamorshi",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16024,
        Pin: 209203,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16025,
        Pin: 531061,
        DispatchCenter: "Yellamanchili_Chodapalle_D (Andhra Pradesh)",
        OriginCenter: "Yellamanchili_Chodapalle_D (Andhra Pradesh)",
        FacilityCity: "Yellamanchili",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16026,
        Pin: 502318,
        DispatchCenter: "Zahirabad_Mohim_D (Telangana)",
        OriginCenter: "Zahirabad_Mohim_D (Telangana)",
        FacilityCity: "Zahirabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16027,
        Pin: 604302,
        DispatchCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        OriginCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        FacilityCity: "Tindivanam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16028,
        Pin: 281306,
        DispatchCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        OriginCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        FacilityCity: "Hathras",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16029,
        Pin: 325003,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16030,
        Pin: 431746,
        DispatchCenter: "Mukhed_Mukramabad_DPP (Maharashtra)",
        OriginCenter: "Mukhed_Mukramabad_DPP (Maharashtra)",
        FacilityCity: "Mukhed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16031,
        Pin: 607003,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16032,
        Pin: 851202,
        DispatchCenter: "Maheshkhunt_PashchimTola_D (Bihar)",
        OriginCenter: "Maheshkhunt_PashchimTola_D (Bihar)",
        FacilityCity: "Maheshkhunt",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16033,
        Pin: 388250,
        DispatchCenter: "Umreth_PltinumPlza_DPP (Gujarat)",
        OriginCenter: "Umreth_PltinumPlza_DPP (Gujarat)",
        FacilityCity: "Umreth",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16034,
        Pin: 711226,
        DispatchCenter: "Khanakul_Antngr_D (West Bengal)",
        OriginCenter: "Khanakul_Antngr_D (West Bengal)",
        FacilityCity: "Khanakul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16035,
        Pin: 821103,
        DispatchCenter: "Chainpur_Bhabhua_D (Bihar)",
        OriginCenter: "Chainpur_Bhabhua_D (Bihar)",
        FacilityCity: "Chainpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16036,
        Pin: 394320,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16037,
        Pin: 721133,
        DispatchCenter: "Belda_DntnRD_DPP (West Bengal)",
        OriginCenter: "Belda_DntnRD_DPP (West Bengal)",
        FacilityCity: "Belda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16038,
        Pin: 845436,
        DispatchCenter: "Chakia_Honda_D (Bihar)",
        OriginCenter: "Chakia_Honda_D (Bihar)",
        FacilityCity: "Chakia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16039,
        Pin: 571231,
        DispatchCenter: "Shanivarasanthe_Bank_D (Karnataka)",
        OriginCenter: "Shanivarasanthe_Bank_D (Karnataka)",
        FacilityCity: "Shanivarasanthe",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16040,
        Pin: 767037,
        DispatchCenter: "Kantabanji_Sindhekela_D (Orissa)",
        OriginCenter: "Kantabanji_Sindhekela_D (Orissa)",
        FacilityCity: "Kantabanji",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16041,
        Pin: 741153,
        DispatchCenter: "Katwa_Panchghara_D (West Bengal)",
        OriginCenter: "Katwa_Panchghara_D (West Bengal)",
        FacilityCity: "Katwa",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16042,
        Pin: 500093,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16043,
        Pin: 277301,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16044,
        Pin: 402111,
        DispatchCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        OriginCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        FacilityCity: "Mangaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16045,
        Pin: 788004,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16046,
        Pin: 621102,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16047,
        Pin: 854339,
        DispatchCenter: "Birpur_Bhimpur_D (Bihar)",
        OriginCenter: "Birpur_Bhimpur_D (Bihar)",
        FacilityCity: "Birpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16048,
        Pin: 416528,
        DispatchCenter: "Oras_Ranbambuli_D (Maharashtra)",
        OriginCenter: "Oras_Ranbambuli_D (Maharashtra)",
        FacilityCity: "Oras",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16049,
        Pin: 144532,
        DispatchCenter: "Garhshankar_BangaRoad_D (Punjab)",
        OriginCenter: "Garhshankar_BangaRoad_D (Punjab)",
        FacilityCity: "Garhshanker",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16050,
        Pin: 495118,
        DispatchCenter: "Pendra_Marwahi_D (Chhattisgarh)",
        OriginCenter: "Pendra_Marwahi_D (Chhattisgarh)",
        FacilityCity: "Pendra",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16051,
        Pin: 815317,
        DispatchCenter: "Tisri_BhandariRd_DPP (Jharkhand)",
        OriginCenter: "Tisri_BhandariRd_DPP (Jharkhand)",
        FacilityCity: "Tisri",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16052,
        Pin: 571325,
        DispatchCenter: "Nanjangud_Deviramanahalli_D (Karnataka)",
        OriginCenter: "Nanjangud_Deviramanahalli_D (Karnataka)",
        FacilityCity: "Nanjangud",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16053,
        Pin: 271319,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16054,
        Pin: 670591,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16055,
        Pin: 250601,
        DispatchCenter: "Baghpat_Barout_D (Uttar Pradesh)",
        OriginCenter: "Baghpat_Barout_D (Uttar Pradesh)",
        FacilityCity: "Baghpat",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16056,
        Pin: 451113,
        DispatchCenter: "Sanawad_Bediya_DPP (Madhya Pradesh)",
        OriginCenter: "Sanawad_Bediya_DPP (Madhya Pradesh)",
        FacilityCity: "Sanawad",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 16057,
        Pin: 143501,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16058,
        Pin: 759129,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16059,
        Pin: 604001,
        DispatchCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        OriginCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        FacilityCity: "Tindivanam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16060,
        Pin: 248146,
        DispatchCenter: "Dehradun_Clement_L (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16061,
        Pin: 577216,
        DispatchCenter: "Shimoga_Eshwariah_I (Karnataka)",
        OriginCenter: "Shimoga_Eshwariah_I (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16062,
        Pin: 224118,
        DispatchCenter: "Rudauli_Ghosaiyana_D (Uttar Pradesh)",
        OriginCenter: "Rudauli_Ghosaiyana_D (Uttar Pradesh)",
        FacilityCity: "Rudauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16063,
        Pin: 389347,
        DispatchCenter: "Godhra_Soniwada_D (Gujarat)",
        OriginCenter: "Godhra_Soniwada_D (Gujarat)",
        FacilityCity: "Godhra",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16064,
        Pin: 573124,
        DispatchCenter: "Chnnryptna_MysoreRd_D (Karnataka)",
        OriginCenter: "Chnnryptna_MysoreRd_D (Karnataka)",
        FacilityCity: "Channarayapatna",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16065,
        Pin: 611114,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16066,
        Pin: 501102,
        DispatchCenter: "Vikarabad_OldGunj_D (Telangana)",
        OriginCenter: "Vikarabad_OldGunj_D (Telangana)",
        FacilityCity: "Vikarabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16067,
        Pin: 621118,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16068,
        Pin: 571511,
        DispatchCenter: "Ramanagara_KunigalRd_D (Karnataka)",
        OriginCenter: "Ramanagara_KunigalRd_D (Karnataka)",
        FacilityCity: "Ramanagara",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16069,
        Pin: 313001,
        DispatchCenter: "Udaipur_Balicha_H (Rajasthan)",
        OriginCenter: "Udaipur_Balicha_H (Rajasthan)",
        FacilityCity: "Udaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16070,
        Pin: 695024,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16071,
        Pin: 441406,
        DispatchCenter: "Ramtek_ShivajiWard_D (Maharashtra)",
        OriginCenter: "Ramtek_ShivajiWard_D (Maharashtra)",
        FacilityCity: "Ramtek",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16072,
        Pin: 761118,
        DispatchCenter: "Jagannathprasad_Jirabadi_DPP (Orissa)",
        OriginCenter: "Jagannathprasad_Jirabadi_DPP (Orissa)",
        FacilityCity: "Jagannathprasad",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16073,
        Pin: 641101,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16074,
        Pin: 752060,
        DispatchCenter: "Khurdha_MdhsnDPP_D (Orissa)",
        OriginCenter: "Khurdha_MdhsnDPP_D (Orissa)",
        FacilityCity: "Khurdha",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16075,
        Pin: 515843,
        DispatchCenter: "Guntakal_Central_D_2 (Andhra Pradesh)",
        OriginCenter: "Guntakal_Central_D_2 (Andhra Pradesh)",
        FacilityCity: "Guntakal",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16076,
        Pin: 742169,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16077,
        Pin: 431605,
        DispatchCenter: "Nanded_Wajegaon_H (Maharashtra)",
        OriginCenter: "Nanded_Wajegaon_H (Maharashtra)",
        FacilityCity: "Nanded",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16078,
        Pin: 221102,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16079,
        Pin: 382315,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16080,
        Pin: 721506,
        DispatchCenter: "Jhargram_Gopiballavpur_DPP (West Bengal)",
        OriginCenter: "Jhargram_Gopiballavpur_DPP (West Bengal)",
        FacilityCity: "Jhargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16081,
        Pin: 422603,
        DispatchCenter: "Sangamner_Ksarwdi_D (Maharashtra)",
        OriginCenter: "Sangamner_Ksarwdi_D (Maharashtra)",
        FacilityCity: "Sangamner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16082,
        Pin: 785680,
        DispatchCenter: "Teok_Wardno3_D (Assam)",
        OriginCenter: "Teok_Wardno3_D (Assam)",
        FacilityCity: "Teok",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16083,
        Pin: 742227,
        DispatchCenter: "Raghunathganj_NH12_D (West Bengal)",
        OriginCenter: "Raghunathganj_NH12_D (West Bengal)",
        FacilityCity: "Raghunathganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16084,
        Pin: 242305,
        DispatchCenter: "Tilhar_Gurgawancol_D (Uttar Pradesh)",
        OriginCenter: "Tilhar_Gurgawancol_D (Uttar Pradesh)",
        FacilityCity: "Tilhar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16085,
        Pin: 700087,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16086,
        Pin: 638403,
        DispatchCenter: "Palladam_Balajinagar_D (Tamil Nadu)",
        OriginCenter: "Palladam_Balajinagar_D (Tamil Nadu)",
        FacilityCity: "Palladam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16087,
        Pin: 231501,
        DispatchCenter: "Bhadohi_Vihana_D (Uttar Pradesh)",
        OriginCenter: "Bhadohi_Vihana_D (Uttar Pradesh)",
        FacilityCity: "Bhadohi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16088,
        Pin: 628212,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16089,
        Pin: 759130,
        DispatchCenter: "Chhendipada_Deogarh_DPP (Orissa)",
        OriginCenter: "Chhendipada_Deogarh_DPP (Orissa)",
        FacilityCity: "Chhendipada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16090,
        Pin: 624619,
        DispatchCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        OriginCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        FacilityCity: "Oddanchatram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16091,
        Pin: 505473,
        DispatchCenter: "Bejjanki_Badanki_D (Telangana)",
        OriginCenter: "Bejjanki_Badanki_D (Telangana)",
        FacilityCity: "Bejjanki",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16092,
        Pin: 629002,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16093,
        Pin: 831019,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16094,
        Pin: 500105,
        DispatchCenter: "Hyderabad_Medchal1_L (Telangana)",
        OriginCenter: "Hyderabad_Medchal_GW (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16095,
        Pin: 713369,
        DispatchCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        OriginCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        FacilityCity: "Rupnarayanpur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16096,
        Pin: 208011,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16097,
        Pin: 263159,
        DispatchCenter: "Ramnagar_Central_D_2 (Uttarakhand)",
        OriginCenter: "Ramnagar_Central_D_2 (Uttarakhand)",
        FacilityCity: "Ramnagar",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16098,
        Pin: 506009,
        DispatchCenter: "Warangal_Indiranagar_D (Telangana)",
        OriginCenter: "Warangal_Indiranagar_D (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16099,
        Pin: 221106,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16100,
        Pin: 415606,
        DispatchCenter: "Chiplun_Burumtali_D (Maharashtra)",
        OriginCenter: "Chiplun_Burumtali_D (Maharashtra)",
        FacilityCity: "Chiplun",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16101,
        Pin: 230129,
        DispatchCenter: "Lalgopalganj_Anandi_D (Uttar Pradesh)",
        OriginCenter: "Lalgopalganj_Anandi_D (Uttar Pradesh)",
        FacilityCity: "Lal Gopalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16102,
        Pin: 805121,
        DispatchCenter: "Nawada_Govindpur_D (Bihar)",
        OriginCenter: "Nawada_Govindpur_D (Bihar)",
        FacilityCity: "Nawada",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16103,
        Pin: 461551,
        DispatchCenter: "Itarsi_AwnNgr_D (Madhya Pradesh)",
        OriginCenter: "Itarsi_AwnNgr_D (Madhya Pradesh)",
        FacilityCity: "Itarsi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 16104,
        Pin: 497449,
        DispatchCenter: "Chirimiri_HaldiBadi_DPP (Chhattisgarh)",
        OriginCenter: "Chirimiri_HaldiBadi_DPP (Chhattisgarh)",
        FacilityCity: "Chirimiri",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16105,
        Pin: 400091,
        DispatchCenter: "Mumbai_Kandivali_L (Maharashtra)",
        OriginCenter: "Mumbai_Kandivali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16106,
        Pin: 761103,
        DispatchCenter: "Hinjilicut_RegidiSt_D (Orissa)",
        OriginCenter: "Hinjilicut_RegidiSt_D (Orissa)",
        FacilityCity: "Hinjilicut",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16107,
        Pin: 388365,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16108,
        Pin: 811214,
        DispatchCenter: "Munger_Kaura_D (Bihar)",
        OriginCenter: "Munger_Kaura_D (Bihar)",
        FacilityCity: "Munger",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16109,
        Pin: 713128,
        DispatchCenter: "Guskhara_DignagarRd_D (West Bengal)",
        OriginCenter: "Guskhara_DignagarRd_D (West Bengal)",
        FacilityCity: "Guskhara",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16110,
        Pin: 500087,
        DispatchCenter: "Hyderabad_HemaNagar_L (Telangana)",
        OriginCenter: "Hyderabad_HemaNagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16111,
        Pin: 396403,
        DispatchCenter: "Navsari_Vejalpore_D (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Navsari",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16112,
        Pin: 250401,
        DispatchCenter: "Mawana_Veernagar_D (Uttar Pradesh)",
        OriginCenter: "Mawana_Veernagar_D (Uttar Pradesh)",
        FacilityCity: "Mawana",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16113,
        Pin: 177033,
        DispatchCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        OriginCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        FacilityCity: "Nadaun",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16114,
        Pin: 741401,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16115,
        Pin: 396050,
        DispatchCenter: "Valsad_Dharampur_D (Gujarat)",
        OriginCenter: "Valsad_Dharampur_D (Gujarat)",
        FacilityCity: "Valsad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16116,
        Pin: 788712,
        DispatchCenter: "Karimganj_Sarisa_D (Assam)",
        OriginCenter: "Karimganj_Sarisa_D (Assam)",
        FacilityCity: "Karimganj",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16117,
        Pin: 209502,
        DispatchCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        OriginCenter: "Aliganj_Bypassroad_D (Uttar Pradesh)",
        FacilityCity: "Aliganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16118,
        Pin: 414405,
        DispatchCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        OriginCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        FacilityCity: "Ahmednagar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16119,
        Pin: 742163,
        DispatchCenter: "Plassey_Janakinagar_D (West Bengal)",
        OriginCenter: "Plassey_Janakinagar_D (West Bengal)",
        FacilityCity: "Plassey",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16120,
        Pin: 712413,
        DispatchCenter: "Arambag_BalibDPP_D (West Bengal)",
        OriginCenter: "Arambag_BalibDPP_D (West Bengal)",
        FacilityCity: "Arambag",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16121,
        Pin: 604401,
        DispatchCenter: "Cheyyar_NehruNGR_D (Tamil Nadu)",
        OriginCenter: "Cheyyar_NehruNGR_D (Tamil Nadu)",
        FacilityCity: "Cheyyar",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16122,
        Pin: 571455,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16123,
        Pin: 643221,
        DispatchCenter: "Ooty_Manjoor_D (Tamil Nadu)",
        OriginCenter: "Ooty_Manjoor_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16124,
        Pin: 133003,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16125,
        Pin: 731237,
        DispatchCenter: "Morgram_Kantagoriya_D (West Bengal)",
        OriginCenter: "Morgram_Kantagoriya_D (West Bengal)",
        FacilityCity: "Morgram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16126,
        Pin: 233232,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16127,
        Pin: 781355,
        DispatchCenter: "Tihu_Barbari_D (Assam)",
        OriginCenter: "Tihu_Barbari_D (Assam)",
        FacilityCity: "Tihu",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16128,
        Pin: 572141,
        DispatchCenter: "Pavagada_Banashankari_D (Karnataka)",
        OriginCenter: "Pavagada_Banashankari_D (Karnataka)",
        FacilityCity: "Pavagada",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16129,
        Pin: 177029,
        DispatchCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        OriginCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        FacilityCity: "Hamirpur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16130,
        Pin: 781353,
        DispatchCenter: "Nalbari_Chokbazar_D (Assam)",
        OriginCenter: "Nalbari_Chokbazar_D (Assam)",
        FacilityCity: "Nalbari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16131,
        Pin: 825408,
        DispatchCenter: "Chouparan_Chaithimore_D (Jharkhand)",
        OriginCenter: "Chouparan_Chaithimore_D (Jharkhand)",
        FacilityCity: "Chouparan",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16132,
        Pin: 271806,
        DispatchCenter: "Bahraich_Dularpur_H (Uttar Pradesh)",
        OriginCenter: "Bahraich_Dularpur_H (Uttar Pradesh)",
        FacilityCity: "Bahraich",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16133,
        Pin: 210421,
        DispatchCenter: "Mahoba_Kulpahar_DPP (Uttar Pradesh)",
        OriginCenter: "Mahoba_Kulpahar_DPP (Uttar Pradesh)",
        FacilityCity: "Mahoba",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16134,
        Pin: 636304,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16135,
        Pin: 686564,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16136,
        Pin: 312604,
        DispatchCenter: "ChhotiSadri_NakodaNgr_DPP (Rajasthan)",
        OriginCenter: "ChhotiSadri_NakodaNgr_DPP (Rajasthan)",
        FacilityCity: "Chhoti Sadri",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16137,
        Pin: 695145,
        DispatchCenter: "Parippally_Chvarcode_D (Kerala)",
        OriginCenter: "Parippally_Chvarcode_D (Kerala)",
        FacilityCity: "Parippally",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16138,
        Pin: 471510,
        DispatchCenter: "LavkushNagar_Patti_D (Madhya Pradesh)",
        OriginCenter: "LavkushNagar_Patti_D (Madhya Pradesh)",
        FacilityCity: "Lavkush Nagar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 16139,
        Pin: 413133,
        DispatchCenter: "Daund_Kurkumbh_L (Maharashtra)",
        OriginCenter: "Daund_Kurkumbh_L (Maharashtra)",
        FacilityCity: "Daund",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16140,
        Pin: 613108,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16141,
        Pin: 695612,
        DispatchCenter: "Kilimanoor_AKNArcard_D (Kerala)",
        OriginCenter: "Kilimanoor_AKNArcard_D (Kerala)",
        FacilityCity: "Kilimanoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16142,
        Pin: 321206,
        DispatchCenter: "Nagar_BypassRoad_D (Rajasthan)",
        OriginCenter: "Nagar_BypassRoad_D (Rajasthan)",
        FacilityCity: "Nagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16143,
        Pin: 581212,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16144,
        Pin: 271123,
        DispatchCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        OriginCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        FacilityCity: "Gonda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16145,
        Pin: 431745,
        DispatchCenter: "Nanded_Wajegaon_H (Maharashtra)",
        OriginCenter: "Nanded_Wajegaon_H (Maharashtra)",
        FacilityCity: "Nanded",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16146,
        Pin: 517247,
        DispatchCenter: "Punganur_Nspeta_D (Andhra Pradesh)",
        OriginCenter: "Punganur_Nspeta_D (Andhra Pradesh)",
        FacilityCity: "Punganur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16147,
        Pin: 625404,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16148,
        Pin: 475110,
        DispatchCenter: "Dabra_Pichhoreroad_D (Madhya Pradesh)",
        OriginCenter: "Dabra_Pichhoreroad_D (Madhya Pradesh)",
        FacilityCity: "Dabra",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 16149,
        Pin: 344023,
        DispatchCenter: "Balotra_Ward45_D (Rajasthan)",
        OriginCenter: "Balotra_Ward45_D (Rajasthan)",
        FacilityCity: "Balotra",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16150,
        Pin: 848114,
        DispatchCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        OriginCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        FacilityCity: "Dalsingh Sarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16151,
        Pin: 857225,
        DispatchCenter: "Benipatti_Sarisab_D (Bihar)",
        OriginCenter: "Benipatti_Sarisab_D (Bihar)",
        FacilityCity: "Benipatti",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16152,
        Pin: 516269,
        DispatchCenter: "Rayachoti_Chennamukkapalle_D (Andhra Pradesh)",
        OriginCenter: "Rayachoti_Chennamukkapalle_D (Andhra Pradesh)",
        FacilityCity: "Rayachoti",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16153,
        Pin: 573210,
        DispatchCenter: "Ramanathapura_Arakalgud_D (Karnataka)",
        OriginCenter: "Ramanathapura_Arakalgud_D (Karnataka)",
        FacilityCity: "Ramanathapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16154,
        Pin: 311806,
        DispatchCenter: "Gangapur_Sahara_DPP (Rajasthan)",
        OriginCenter: "Gangapur_Sahara_DPP (Rajasthan)",
        FacilityCity: "Gangapur-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16155,
        Pin: 757054,
        DispatchCenter: "Rairangpur_Sanchampauda_D (Orissa)",
        OriginCenter: "Rairangpur_Sanchampauda_D (Orissa)",
        FacilityCity: "Rairangpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16156,
        Pin: 821113,
        DispatchCenter: "Sasaram_Karwandiya_I (Bihar)",
        OriginCenter: "Sasaram_Karwandiya_I (Bihar)",
        FacilityCity: "Sasaram",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16157,
        Pin: 534351,
        DispatchCenter: "Kovvur_Ramarao_D (Andhra Pradesh)",
        OriginCenter: "Kovvur_Ramarao_D (Andhra Pradesh)",
        FacilityCity: "Kovvur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16158,
        Pin: 206243,
        DispatchCenter: "Bidhuna_BharthnRd_D (Uttar Pradesh)",
        OriginCenter: "Bidhuna_BharthnRd_D (Uttar Pradesh)",
        FacilityCity: "Bidhuna",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16159,
        Pin: 643105,
        DispatchCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        OriginCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16160,
        Pin: 248004,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16161,
        Pin: 224164,
        DispatchCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        OriginCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        FacilityCity: "Kumarganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16162,
        Pin: 480991,
        DispatchCenter: "Mandla_Nainpur_D (Madhya Pradesh)",
        OriginCenter: "Mandla_Nainpur_D (Madhya Pradesh)",
        FacilityCity: "Mandla",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 16163,
        Pin: 393140,
        DispatchCenter: "Rajpipla_DSPOffice_DPP (Gujarat)",
        OriginCenter: "Rajpipla_DSPOffice_DPP (Gujarat)",
        FacilityCity: "Rajpipla",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16164,
        Pin: 796261,
        DispatchCenter: "Saitual_Keifan_D (Mizoram)",
        OriginCenter: "Saitual_Keifan_D (Mizoram)",
        FacilityCity: "Saitual",
        FacilityState: "Mizoram",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16165,
        Pin: 799014,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16166,
        Pin: 689126,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16167,
        Pin: 493881,
        DispatchCenter: "Abhanpur_Gatapr_D (Chhattisgarh)",
        OriginCenter: "Abhanpur_Gatapr_D (Chhattisgarh)",
        FacilityCity: "Abhanpur",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16168,
        Pin: 382352,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16169,
        Pin: 847203,
        DispatchCenter: "Benipur_Magura_D (Bihar)",
        OriginCenter: "Benipur_Magura_D (Bihar)",
        FacilityCity: "Benipur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16170,
        Pin: 413221,
        DispatchCenter: "Mandrup_SH149_D (Maharashtra)",
        OriginCenter: "Mandrup_SH149_D (Maharashtra)",
        FacilityCity: "Mandrup",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16171,
        Pin: 516364,
        DispatchCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        OriginCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        FacilityCity: "Proddatur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16172,
        Pin: 700113,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16173,
        Pin: 465226,
        DispatchCenter: "Nalkheda_Kanad_D (Madhya Pradesh)",
        OriginCenter: "Nalkheda_Kanad_D (Madhya Pradesh)",
        FacilityCity: "Nalkheda",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 16174,
        Pin: 825319,
        DispatchCenter: "Hazaribagh_Matwari_D (Jharkhand)",
        OriginCenter: "Hazaribagh_Matwari_D (Jharkhand)",
        FacilityCity: "hazaribagh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16175,
        Pin: 732001,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16176,
        Pin: 458880,
        DispatchCenter: "Garoth_Shamgarh2_DPP (Madhya Pradesh)",
        OriginCenter: "Garoth_Shamgarh2_DPP (Madhya Pradesh)",
        FacilityCity: "Garoth",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 16177,
        Pin: 788733,
        DispatchCenter: "Ramkrishnanagar_Kalinagar_D (Assam)",
        OriginCenter: "Ramkrishnanagar_Kalinagar_D (Assam)",
        FacilityCity: "Ramkrishna Nagar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16178,
        Pin: 465550,
        DispatchCenter: "Alote_PanchamViharColony_D (Madhya Pradesh)",
        OriginCenter: "Alote_PanchamViharColony_D (Madhya Pradesh)",
        FacilityCity: "Alote",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 16179,
        Pin: 637206,
        DispatchCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        OriginCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        FacilityCity: "Namakkal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16180,
        Pin: 390012,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16181,
        Pin: 143603,
        DispatchCenter: "Ajnala_Fatehgarhchurian_D (Punjab)",
        OriginCenter: "Ajnala_Fatehgarhchurian_D (Punjab)",
        FacilityCity: "Ajnala",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16182,
        Pin: 507304,
        DispatchCenter: "Bonakal_SaiNagar_D (Telangana)",
        OriginCenter: "Bonakal_SaiNagar_D (Telangana)",
        FacilityCity: "Bonakal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16183,
        Pin: 754204,
        DispatchCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        OriginCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        FacilityCity: "Chandikhol",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16184,
        Pin: 516401,
        DispatchCenter: "Pulivendula_Bakarapuram_D (Andhra Pradesh)",
        OriginCenter: "Pulivendula_Bakarapuram_D (Andhra Pradesh)",
        FacilityCity: "Pulivendula",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16185,
        Pin: 801305,
        DispatchCenter: "Chandi_SH78_D (Bihar)",
        OriginCenter: "Chandi_SH78_D (Bihar)",
        FacilityCity: "Chandi",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16186,
        Pin: 759122,
        DispatchCenter: "Angul_Durgavihar_I (Orissa)",
        OriginCenter: "Angul_Durgavihar_I (Orissa)",
        FacilityCity: "Angul",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16187,
        Pin: 148029,
        DispatchCenter: "Sunam_Indrabasti_D (Punjab)",
        OriginCenter: "Sunam_Indrabasti_D (Punjab)",
        FacilityCity: "Sunam",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16188,
        Pin: 423605,
        DispatchCenter: "Kopargaon_Murshatpur_D (Maharashtra)",
        OriginCenter: "Kopargaon_Murshatpur_D (Maharashtra)",
        FacilityCity: "Kopargaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16189,
        Pin: 577217,
        DispatchCenter: "Honnali_TMroad_D (Karnataka)",
        OriginCenter: "Honnali_TMroad_D (Karnataka)",
        FacilityCity: "Honnali",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16190,
        Pin: 224123,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16191,
        Pin: 327025,
        DispatchCenter: "Banswara_Baridaylan_D (Rajasthan)",
        OriginCenter: "Banswara_Baridaylan_D (Rajasthan)",
        FacilityCity: "Banswara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16192,
        Pin: 509335,
        DispatchCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        OriginCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        FacilityCity: "Mahabubnagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16193,
        Pin: 380005,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16194,
        Pin: 416620,
        DispatchCenter: "Oras_Ranbambuli_D (Maharashtra)",
        OriginCenter: "Oras_Ranbambuli_D (Maharashtra)",
        FacilityCity: "Oras",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16195,
        Pin: 172105,
        DispatchCenter: "Kinnaur_Kamru_DPP (Himachal Pradesh)",
        OriginCenter: "Kinnaur_Kamru_DPP (Himachal Pradesh)",
        FacilityCity: "Kinnaur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16196,
        Pin: 396450,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16197,
        Pin: 274807,
        DispatchCenter: "Deoria_Ramnathnagar_D (Uttar Pradesh)",
        OriginCenter: "Deoria_Ramnathnagar_D (Uttar Pradesh)",
        FacilityCity: "Deoria",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16198,
        Pin: 795116,
        DispatchCenter: "Jiribam_Bidyanagar_D (Manipur)",
        OriginCenter: "Jiribam_Bidyanagar_D (Manipur)",
        FacilityCity: "Jiribam",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16199,
        Pin: 389146,
        DispatchCenter: "Limkheda_VjyCmplx_DPP (Gujarat)",
        OriginCenter: "Limkheda_VjyCmplx_DPP (Gujarat)",
        FacilityCity: "Limkheda",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16200,
        Pin: 574265,
        DispatchCenter: "Bantwal_BambilRd_D (Karnataka)",
        OriginCenter: "Bantwal_BambilRd_D (Karnataka)",
        FacilityCity: "Bantwal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16201,
        Pin: 333031,
        DispatchCenter: "Pilani (Rajasthan)",
        OriginCenter: "Pilani (Rajasthan)",
        FacilityCity: "Pilani",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16202,
        Pin: 535126,
        DispatchCenter: "Rajam_Varalaxminagar_D (Andhra Pradesh)",
        OriginCenter: "Rajam_Varalaxminagar_D (Andhra Pradesh)",
        FacilityCity: "Rajam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16203,
        Pin: 713212,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16204,
        Pin: 534165,
        DispatchCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        OriginCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        FacilityCity: "Tadepalligudem",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16205,
        Pin: 802130,
        DispatchCenter: "Brahmapur_Shahpur_D (Bihar)",
        OriginCenter: "Brahmapur_Shahpur_D (Bihar)",
        FacilityCity: "Brahmapur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16206,
        Pin: 695020,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16207,
        Pin: 326023,
        DispatchCenter: "Jhalawar_RTOOffice_D (Rajasthan)",
        OriginCenter: "Jhalawar_RTOOffice_D (Rajasthan)",
        FacilityCity: "Jhalawar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16208,
        Pin: 755042,
        DispatchCenter: "Jajpur_Chandama_D (Orissa)",
        OriginCenter: "Jajpur_Chandama_D (Orissa)",
        FacilityCity: "Jajpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16209,
        Pin: 825402,
        DispatchCenter: "Hazaribagh_Padma_DPP (Jharkhand)",
        OriginCenter: "Hazaribagh_Padma_DPP (Jharkhand)",
        FacilityCity: "hazaribagh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16210,
        Pin: 444906,
        DispatchCenter: "Warud_Policestn_DPP (Maharashtra)",
        OriginCenter: "Warud_Policestn_DPP (Maharashtra)",
        FacilityCity: "Warud",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16211,
        Pin: 402109,
        DispatchCenter: "Nagothane_Balsaital_D (Maharashtra)",
        OriginCenter: "Nagothane_Balsaital_D (Maharashtra)",
        FacilityCity: "Nagothane",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16212,
        Pin: 416236,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16213,
        Pin: 202121,
        DispatchCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        OriginCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16214,
        Pin: 177117,
        DispatchCenter: "DehraGopipur_Central_D_1 (Himachal Pradesh)",
        OriginCenter: "DehraGopipur_Central_D_1 (Himachal Pradesh)",
        FacilityCity: "Dehra Gopipur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16215,
        Pin: 416610,
        DispatchCenter: "Kankavali_NrdawDPP_D (Maharashtra)",
        OriginCenter: "Kankavali_NrdawDPP_D (Maharashtra)",
        FacilityCity: "Kankavali",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16216,
        Pin: 389157,
        DispatchCenter: "Dahod_Vanzarwada_I (Gujarat)",
        OriginCenter: "Dahod_Vanzarwada_I (Gujarat)",
        FacilityCity: "Dahod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16217,
        Pin: 591313,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16218,
        Pin: 639201,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16219,
        Pin: 785005,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16220,
        Pin: 202412,
        DispatchCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        OriginCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        FacilityCity: "Chandausi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16221,
        Pin: 516362,
        DispatchCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        OriginCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        FacilityCity: "Proddatur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16222,
        Pin: 416317,
        DispatchCenter: "Tasgaon_Trimurticolony_D (Maharashtra)",
        OriginCenter: "Tasgaon_Trimurticolony_D (Maharashtra)",
        FacilityCity: "Tasgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16223,
        Pin: 241404,
        DispatchCenter: "Pihani_Katrabzr_D (Uttar Pradesh)",
        OriginCenter: "Pihani_Katrabzr_D (Uttar Pradesh)",
        FacilityCity: "Pihani",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16224,
        Pin: 759151,
        DispatchCenter: "Chhendipada_Lodhabandha_DPP (Orissa)",
        OriginCenter: "Chhendipada_Lodhabandha_DPP (Orissa)",
        FacilityCity: "Chhendipada",
        FacilityState: "Orissa",
        Zone : "NE"
      },
      {
        SN: 16225,
        Pin: 722203,
        DispatchCenter: "Bankura_KeranDPP_D (West Bengal)",
        OriginCenter: "Bankura_KeranDPP_D (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16226,
        Pin: 571812,
        DispatchCenter: "Krishnarajpet_JayaNagar_D (Karnataka)",
        OriginCenter: "Krishnarajpet_JayaNagar_D (Karnataka)",
        FacilityCity: "Krishnarajpet",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16227,
        Pin: 625512,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16228,
        Pin: 606602,
        DispatchCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        OriginCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        FacilityCity: "Tiruvannamalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16229,
        Pin: 732201,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16230,
        Pin: 410005,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16231,
        Pin: 712403,
        DispatchCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        OriginCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        FacilityCity: "Tarkeshwar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16232,
        Pin: 262702,
        DispatchCenter: "Lakhimpur_FunMall_D (Uttar Pradesh)",
        OriginCenter: "Lakhimpur_FunMall_D (Uttar Pradesh)",
        FacilityCity: "Lakhimpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16233,
        Pin: 493116,
        DispatchCenter: "Tilda_KhushiHsptlRd_D (Chhattisgarh)",
        OriginCenter: "Tilda_KhushiHsptlRd_D (Chhattisgarh)",
        FacilityCity: "Tilda",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16234,
        Pin: 754143,
        DispatchCenter: "Paradip_Udyabata_D (Orissa)",
        OriginCenter: "Paradip_Udyabata_D (Orissa)",
        FacilityCity: "Paradip",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16235,
        Pin: 690570,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16236,
        Pin: 604402,
        DispatchCenter: "Cheyyar_NehruNGR_D (Tamil Nadu)",
        OriginCenter: "Cheyyar_NehruNGR_D (Tamil Nadu)",
        FacilityCity: "Cheyyar",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16237,
        Pin: 263655,
        DispatchCenter: "Ranikhet_Majkhali_DPP (Uttarakhand)",
        OriginCenter: "Ranikhet_Majkhali_DPP (Uttarakhand)",
        FacilityCity: "Ranikhet",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16238,
        Pin: 364003,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16239,
        Pin: 785602,
        DispatchCenter: "Sarupathar_Golaght_D (Assam)",
        OriginCenter: "Sarupathar_Golaght_D (Assam)",
        FacilityCity: "Sarupathar",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16240,
        Pin: 600114,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16241,
        Pin: 788104,
        DispatchCenter: "Pailapool_Lagktlbocpr_D (Assam)",
        OriginCenter: "Pailapool_Lagktlbocpr_D (Assam)",
        FacilityCity: "Pailapool",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16242,
        Pin: 828126,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16243,
        Pin: 678575,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16244,
        Pin: 580032,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16245,
        Pin: 823311,
        DispatchCenter: "Khizasarai_Lodipur_D (Bihar)",
        OriginCenter: "Khizasarai_Lodipur_D (Bihar)",
        FacilityCity: "Khizasarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16246,
        Pin: 532292,
        DispatchCenter: "Sompeta_PostOffice_DPP (Andhra Pradesh)",
        OriginCenter: "Sompeta_PostOffice_DPP (Andhra Pradesh)",
        FacilityCity: "Sompeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16247,
        Pin: 821118,
        DispatchCenter: "Thakurganj_ThanaColony_D (Bihar)",
        OriginCenter: "Thakurganj_ThanaColony_D (Bihar)",
        FacilityCity: "Thakurganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16248,
        Pin: 622504,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16249,
        Pin: 388625,
        DispatchCenter: "Khambhat_MotvdDPP_D (Gujarat)",
        OriginCenter: "Khambhat_MotvdDPP_D (Gujarat)",
        FacilityCity: "Khambhat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16250,
        Pin: 628002,
        DispatchCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        OriginCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        FacilityCity: "Tuticorin",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16251,
        Pin: 382041,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16252,
        Pin: 173201,
        DispatchCenter: "Baddi_Barotiwala_L (Himachal Pradesh)",
        OriginCenter: "Baddi_Barotiwala_L (Himachal Pradesh)",
        FacilityCity: "Baddi",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16253,
        Pin: 302009,
        DispatchCenter: "Jaipur_RingRoad_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16254,
        Pin: 312612,
        DispatchCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        OriginCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        FacilityCity: "Chittaurgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16255,
        Pin: 825101,
        DispatchCenter: "Ramgarh_Marar_D (Jharkhand)",
        OriginCenter: "Ramgarh_Marar_D (Jharkhand)",
        FacilityCity: "Ramgarh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16256,
        Pin: 609701,
        DispatchCenter: "Peralam_Kaduvangudi_D (Tamil Nadu)",
        OriginCenter: "Peralam_Kaduvangudi_D (Tamil Nadu)",
        FacilityCity: "Peralam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16257,
        Pin: 110110,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16258,
        Pin: 303503,
        DispatchCenter: "Lalsot_Ward34_D (Rajasthan)",
        OriginCenter: "Lalsot_Ward34_D (Rajasthan)",
        FacilityCity: "Lalsot",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16259,
        Pin: 523271,
        DispatchCenter: "Kondapi_Anakarlapudi_D (Andhra Pradesh)",
        OriginCenter: "Kondapi_Anakarlapudi_D (Andhra Pradesh)",
        FacilityCity: "Kondapi",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16260,
        Pin: 686583,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16261,
        Pin: 281406,
        DispatchCenter: "Vrindavan_Jait_D (Uttar Pradesh)",
        OriginCenter: "Vrindavan_Jait_D (Uttar Pradesh)",
        FacilityCity: "Vrindavan",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16262,
        Pin: 303807,
        DispatchCenter: "Jaipur_VishwakarmaIndArea_L (Rajasthan)",
        OriginCenter: "Jaipur_VishwakarmaIndArea_L (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16263,
        Pin: 627119,
        DispatchCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        OriginCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        FacilityCity: "Thisayanvilai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16264,
        Pin: 442302,
        DispatchCenter: "Pulgaon_RajPP_DPP (Maharashtra)",
        OriginCenter: "Pulgaon_RajPP_DPP (Maharashtra)",
        FacilityCity: "Pulgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16265,
        Pin: 643006,
        DispatchCenter: "Ooty_Westmere_D (Tamil Nadu)",
        OriginCenter: "Ooty_Westmere_D (Tamil Nadu)",
        FacilityCity: "Ooty",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16266,
        Pin: 825324,
        DispatchCenter: "BarkiSaria_KalaliRd_DPP (Jharkhand)",
        OriginCenter: "BarkiSaria_KalaliRd_DPP (Jharkhand)",
        FacilityCity: "Barki Saria",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16267,
        Pin: 700137,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16268,
        Pin: 393005,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16269,
        Pin: 182101,
        DispatchCenter: "Udhampur_BharatNagar_D (Jammu & Kashmir)",
        OriginCenter: "Udhampur_BharatNagar_D (Jammu & Kashmir)",
        FacilityCity: "Udhampur",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16270,
        Pin: 829129,
        DispatchCenter: "Ramgarh_Marar_D (Jharkhand)",
        OriginCenter: "Ramgarh_Marar_D (Jharkhand)",
        FacilityCity: "Ramgarh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16271,
        Pin: 712419,
        DispatchCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        OriginCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        FacilityCity: "Tarkeshwar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16272,
        Pin: 734209,
        DispatchCenter: "Darjeeling_Sonada_DPP (West Bengal)",
        OriginCenter: "Darjeeling_Sonada_DPP (West Bengal)",
        FacilityCity: "Darjeeling",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16273,
        Pin: 249402,
        DispatchCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        OriginCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        FacilityCity: "Haridwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16274,
        Pin: 620007,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16275,
        Pin: 781128,
        DispatchCenter: "Guwahati_Kaikchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16276,
        Pin: 244003,
        DispatchCenter: "Sambhal_DurgaColny_D (Uttar Pradesh)",
        OriginCenter: "Sambhal_DurgaColny_D (Uttar Pradesh)",
        FacilityCity: "Sambhal",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16277,
        Pin: 385007,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16278,
        Pin: 689549,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16279,
        Pin: 281006,
        DispatchCenter: "Mathura_Narsipuramrd_D (Uttar Pradesh)",
        OriginCenter: "Mathura_Narsipuramrd_D (Uttar Pradesh)",
        FacilityCity: "Mathura",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16280,
        Pin: 500086,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16281,
        Pin: 142045,
        DispatchCenter: "Bagha_Samalsar_D (Punjab)",
        OriginCenter: "Bagha_Samalsar_D (Punjab)",
        FacilityCity: "Bagha purana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16282,
        Pin: 741402,
        DispatchCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        OriginCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        FacilityCity: "Ranaghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16283,
        Pin: 632053,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16284,
        Pin: 673008,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16285,
        Pin: 401603,
        DispatchCenter: "Kudus_Shele_D (Maharashtra)",
        OriginCenter: "Kudus_Shele_D (Maharashtra)",
        FacilityCity: "Kudus",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16286,
        Pin: 741317,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16287,
        Pin: 632013,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16288,
        Pin: 796772,
        DispatchCenter: "Chawngte_Randhakpur_D (Mizoram)",
        OriginCenter: "Chawngte_Randhakpur_D (Mizoram)",
        FacilityCity: "Chawngte",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16289,
        Pin: 847237,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16290,
        Pin: 506368,
        DispatchCenter: "Danthalapally_AmbdkrSt_D (Telangana)",
        OriginCenter: "Danthalapally_AmbdkrSt_D (Telangana)",
        FacilityCity: "Danthalapally",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16291,
        Pin: 586104,
        DispatchCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        OriginCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        FacilityCity: "Bijapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16292,
        Pin: 505301,
        DispatchCenter: "Sircilla_Gopalnagar_D (Telangana)",
        OriginCenter: "Sircilla_Gopalnagar_D (Telangana)",
        FacilityCity: "Sircilla",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16293,
        Pin: 465110,
        DispatchCenter: "Shajapur_Mahupura_D (Madhya Pradesh)",
        OriginCenter: "Shajapur_Mahupura_D (Madhya Pradesh)",
        FacilityCity: "Shajapur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 16294,
        Pin: 689662,
        DispatchCenter: "Ranni_Chethakhl_D (Kerala)",
        OriginCenter: "Ranni_Chethakhl_D (Kerala)",
        FacilityCity: "Ranni",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16295,
        Pin: 627603,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16296,
        Pin: 785011,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16297,
        Pin: 246732,
        DispatchCenter: "Najibabad_Pathanpura_D (Uttar Pradesh)",
        OriginCenter: "Najibabad_Pathanpura_D (Uttar Pradesh)",
        FacilityCity: "Najibabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16298,
        Pin: 670011,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16299,
        Pin: 422305,
        DispatchCenter: "Lasalgaon_TelephnExch_D (Maharashtra)",
        OriginCenter: "Lasalgaon_TelephnExch_D (Maharashtra)",
        FacilityCity: "Lasalgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16300,
        Pin: 151006,
        DispatchCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        OriginCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        FacilityCity: "Bathinda",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16301,
        Pin: 504001,
        DispatchCenter: "Adilabad_KailashNagar_D (Telangana)",
        OriginCenter: "Adilabad_KailashNagar_D (Telangana)",
        FacilityCity: "Adilabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16302,
        Pin: 206123,
        DispatchCenter: "Kishni_Shivmndr_D (Uttar Pradesh)",
        OriginCenter: "Kishni_Shivmndr_D (Uttar Pradesh)",
        FacilityCity: "Kishni",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16303,
        Pin: 804402,
        DispatchCenter: "Arwal_SipahPnchayt_D (Bihar)",
        OriginCenter: "Arwal_SipahPnchayt_D (Bihar)",
        FacilityCity: "Arwal",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16304,
        Pin: 403409,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16305,
        Pin: 516360,
        DispatchCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        OriginCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        FacilityCity: "Proddatur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16306,
        Pin: 614738,
        DispatchCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        OriginCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        FacilityCity: "Pattukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16307,
        Pin: 574138,
        DispatchCenter: "Mudarangady_Santhoor_DPP (Karnataka)",
        OriginCenter: "Mudarangady_Santhoor_DPP (Karnataka)",
        FacilityCity: "Mudarangady",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16308,
        Pin: 642003,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16309,
        Pin: 522017,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16310,
        Pin: 506302,
        DispatchCenter: "Thirumalagiri_MaliPuramRoad_D (Telangana)",
        OriginCenter: "Thirumalagiri_MaliPuramRoad_D (Telangana)",
        FacilityCity: "Thirumalagiri",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16311,
        Pin: 803108,
        DispatchCenter: "Chandi_SH78_D (Bihar)",
        OriginCenter: "Chandi_SH78_D (Bihar)",
        FacilityCity: "Chandi",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16312,
        Pin: 263634,
        DispatchCenter: "Bageshwar_Kapkot_DPP (Uttarakhand)",
        OriginCenter: "Bageshwar_Kapkot_DPP (Uttarakhand)",
        FacilityCity: "Bageshwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16313,
        Pin: 781020,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16314,
        Pin: 508112,
        DispatchCenter: "Ramanapeta_Kurmawada_D (Telangana)",
        OriginCenter: "Ramanapeta_Kurmawada_D (Telangana)",
        FacilityCity: "Ramannapeta",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16315,
        Pin: 766100,
        DispatchCenter: "MRampur_RisidaRd_D (Orissa)",
        OriginCenter: "MRampur_RisidaRd_D (Orissa)",
        FacilityCity: "M Rampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16316,
        Pin: 622412,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16317,
        Pin: 410101,
        DispatchCenter: "Karjat_NeralRd_D (Maharashtra)",
        OriginCenter: "Karjat_NeralRd_D (Maharashtra)",
        FacilityCity: "Karjat",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16318,
        Pin: 680307,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16319,
        Pin: 533440,
        DispatchCenter: "Samarlakota_CHC_D (Andhra Pradesh)",
        OriginCenter: "Samarlakota_CHC_D (Andhra Pradesh)",
        FacilityCity: "Samarlakota",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16320,
        Pin: 458220,
        DispatchCenter: "Jawad_Vajihiwada_D (Madhya Pradesh)",
        OriginCenter: "Jawad_Vajihiwada_D (Madhya Pradesh)",
        FacilityCity: "Jawad",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 16321,
        Pin: 517299,
        DispatchCenter: "Madanapalle_Vayalapadu_D (Andhra Pradesh)",
        OriginCenter: "Madanapalle_Vayalapadu_D (Andhra Pradesh)",
        FacilityCity: "Madanapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16322,
        Pin: 743354,
        DispatchCenter: "DakshinBarasat_Raidighi_DPP (West Bengal)",
        OriginCenter: "DakshinBarasat_Raidighi_DPP (West Bengal)",
        FacilityCity: "Dakshin Barasat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16323,
        Pin: 761144,
        DispatchCenter: "Digapahandi_Punjikanya_D (Orissa)",
        OriginCenter: "Digapahandi_Punjikanya_D (Orissa)",
        FacilityCity: "Digapahandi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16324,
        Pin: 341303,
        DispatchCenter: "Didwana_TransportNgr_DPP (Rajasthan)",
        OriginCenter: "Didwana_TransportNgr_DPP (Rajasthan)",
        FacilityCity: "Didwana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16325,
        Pin: 691540,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16326,
        Pin: 680612,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16327,
        Pin: 229402,
        DispatchCenter: "Unchahar_Khojanpur_D (Uttar Pradesh)",
        OriginCenter: "Unchahar_Khojanpur_D (Uttar Pradesh)",
        FacilityCity: "Unchahar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16328,
        Pin: 533107,
        DispatchCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        OriginCenter: "Rajahmundry_Rajanagaram_H (Andhra Pradesh)",
        FacilityCity: "Rajahmundry",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16329,
        Pin: 534204,
        DispatchCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        FacilityCity: "Bhimavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16330,
        Pin: 853204,
        DispatchCenter: "Naugchia_Vijayght_D (Bihar)",
        OriginCenter: "Naugchia_Vijayght_D (Bihar)",
        FacilityCity: "Naugachia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16331,
        Pin: 144703,
        DispatchCenter: "Dharamkot_Moga_D (Punjab)",
        OriginCenter: "Dharamkot_Moga_D (Punjab)",
        FacilityCity: "Dharamkot",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16332,
        Pin: 273151,
        DispatchCenter: "Kaptanganj_Subshngr_D (Uttar Pradesh)",
        OriginCenter: "Kaptanganj_Subshngr_D (Uttar Pradesh)",
        FacilityCity: "Kaptanganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16333,
        Pin: 625001,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16334,
        Pin: 403804,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16335,
        Pin: 625601,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16336,
        Pin: 403109,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16337,
        Pin: 843139,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16338,
        Pin: 585316,
        DispatchCenter: "Humnabad_Kamalapur_DPP (Karnataka)",
        OriginCenter: "Humnabad_Kamalapur_DPP (Karnataka)",
        FacilityCity: "Humnabad",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16339,
        Pin: 495686,
        DispatchCenter: "Champa_Sindhicolony_D (Chhattisgarh)",
        OriginCenter: "Champa_Sindhicolony_D (Chhattisgarh)",
        FacilityCity: "Champa",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16340,
        Pin: 365535,
        DispatchCenter: "Amreli_Bhojalpara_D (Gujarat)",
        OriginCenter: "Amreli_Bhojalpara_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16341,
        Pin: 202135,
        DispatchCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        OriginCenter: "NSZ",
        FacilityCity: "Khair",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16342,
        Pin: 582117,
        DispatchCenter: "Gadag_Laxmeshwar_D (Karnataka)",
        OriginCenter: "Gadag_Laxmeshwar_D (Karnataka)",
        FacilityCity: "Gadag",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16343,
        Pin: 413527,
        DispatchCenter: "Latur_KhadgoanChowk_D (Maharashtra)",
        OriginCenter: "Latur_KhadgoanChowk_D (Maharashtra)",
        FacilityCity: "Latur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16344,
        Pin: 303602,
        DispatchCenter: "Renwal_Wardno4_D (Rajasthan)",
        OriginCenter: "Renwal_Wardno4_D (Rajasthan)",
        FacilityCity: "Renwal",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16345,
        Pin: 480106,
        DispatchCenter: "Sausar_BorgaonAKVN_DPP (Madhya Pradesh)",
        OriginCenter: "Sausar_BorgaonAKVN_DPP (Madhya Pradesh)",
        FacilityCity: "Sausar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 16346,
        Pin: 422607,
        DispatchCenter: "Sinnar_Pimpale_D (Maharashtra)",
        OriginCenter: "Sinnar_Pimpale_D (Maharashtra)",
        FacilityCity: "Sinnar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16347,
        Pin: 321022,
        DispatchCenter: "Kaman_BsstdDPP_D (Rajasthan)",
        OriginCenter: "Kaman_BsstdDPP_D (Rajasthan)",
        FacilityCity: "Kaman",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16348,
        Pin: 392160,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16349,
        Pin: 210129,
        DispatchCenter: "Atarra_GangehiRd_D (Uttar Pradesh)",
        OriginCenter: "Atarra_GangehiRd_D (Uttar Pradesh)",
        FacilityCity: "Atarra",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16350,
        Pin: 303706,
        DispatchCenter: "Kalwar_Bhagwatinagar_D (Rajasthan)",
        OriginCenter: "Kalwar_Bhagwatinagar_D (Rajasthan)",
        FacilityCity: "Kalwar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16351,
        Pin: 425327,
        DispatchCenter: "Malkapur_DwarkaHosp_DPP (Maharashtra)",
        OriginCenter: "Malkapur_DwarkaHosp_DPP (Maharashtra)",
        FacilityCity: "Malkapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16352,
        Pin: 577127,
        DispatchCenter: "Koppa_Haranduru_D (Karnataka)",
        OriginCenter: "Koppa_Haranduru_D (Karnataka)",
        FacilityCity: "Koppa-ka",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16353,
        Pin: 495005,
        DispatchCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        OriginCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        FacilityCity: "Bilaspur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16354,
        Pin: 171225,
        DispatchCenter: "Kotkhai_Kalbog_DPP (Himachal Pradesh)",
        OriginCenter: "Kotkhai_Kalbog_DPP (Himachal Pradesh)",
        FacilityCity: "Kotkhai",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16355,
        Pin: 110117,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16356,
        Pin: 176091,
        DispatchCenter: "Palampur_Saulbanehr_DPP (Himachal Pradesh)",
        OriginCenter: "Palampur_Saulbanehr_DPP (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16357,
        Pin: 712235,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16358,
        Pin: 135133,
        DispatchCenter: "Kurukshetra_Ladwa_D (Haryana)",
        OriginCenter: "Kurukshetra_Ladwa_D (Haryana)",
        FacilityCity: "Kurukshetra",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16359,
        Pin: 342026,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16360,
        Pin: 591109,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16361,
        Pin: 202417,
        DispatchCenter: "Shahabad_Dhkriya_D (Uttar Pradesh)",
        OriginCenter: "Shahabad_Dhkriya_D (Uttar Pradesh)",
        FacilityCity: "Shahabad-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16362,
        Pin: 517646,
        DispatchCenter: "Tada_Sriharikota_D (Andhra Pradesh)",
        OriginCenter: "Tada_Sriharikota_D (Andhra Pradesh)",
        FacilityCity: "Tada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16363,
        Pin: 518513,
        DispatchCenter: "Velgodu_TVSShwrm_D (Andhra Pradesh)",
        OriginCenter: "Velgodu_TVSShwrm_D (Andhra Pradesh)",
        FacilityCity: "Velgodu",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16364,
        Pin: 160013,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16365,
        Pin: 631553,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16366,
        Pin: 623705,
        DispatchCenter: "Paramakudi_River_D (Tamil Nadu)",
        OriginCenter: "Paramakudi_River_D (Tamil Nadu)",
        FacilityCity: "Paramakudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16367,
        Pin: 570023,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16368,
        Pin: 262501,
        DispatchCenter: "Pithorgarh_Kumud_D (Uttarakhand)",
        OriginCenter: "Pithorgarh_Kumud_D (Uttarakhand)",
        FacilityCity: "Pithoragarh",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16369,
        Pin: 636354,
        DispatchCenter: "Dharmapuri_Thoppur_D (Tamil Nadu)",
        OriginCenter: "Dharmapuri_Thoppur_D (Tamil Nadu)",
        FacilityCity: "Dharmapuri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16370,
        Pin: 844122,
        DispatchCenter: "Hazratjandaha_Hirpur_D (Bihar)",
        OriginCenter: "Hazratjandaha_Hirpur_D (Bihar)",
        FacilityCity: "Hazrat Jandaha",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16371,
        Pin: 387130,
        DispatchCenter: "Ahmedabad_Kanera_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16372,
        Pin: 685620,
        DispatchCenter: "Munnar_IkkaNgr_D (Kerala)",
        OriginCenter: "Munnar_IkkaNgr_D (Kerala)",
        FacilityCity: "Munnar",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16373,
        Pin: 523273,
        DispatchCenter: "Kondapi_Anakarlapudi_D (Andhra Pradesh)",
        OriginCenter: "Kondapi_Anakarlapudi_D (Andhra Pradesh)",
        FacilityCity: "Kondapi",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16374,
        Pin: 515541,
        DispatchCenter: "Kadiri_NagireddyPalli_D (Andhra Pradesh)",
        OriginCenter: "Kadiri_NagireddyPalli_D (Andhra Pradesh)",
        FacilityCity: "Kadiri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16375,
        Pin: 799005,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16376,
        Pin: 583136,
        DispatchCenter: "Kotturu_Kudligi_D (Karnataka)",
        OriginCenter: "Kotturu_Kudligi_D (Karnataka)",
        FacilityCity: "Kotturu",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16377,
        Pin: 271302,
        DispatchCenter: "Nawabganj_Shbapr_D (Uttar Pradesh)",
        OriginCenter: "Nawabganj_Shbapr_D (Uttar Pradesh)",
        FacilityCity: "Nawabganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16378,
        Pin: 466666,
        DispatchCenter: "Narsinghgarh_Bypassroad_D (Madhya Pradesh)",
        OriginCenter: "Narsinghgarh_Bypassroad_D (Madhya Pradesh)",
        FacilityCity: "Narsinghgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 16379,
        Pin: 583229,
        DispatchCenter: "Sindhanur_Karatagi_D (Karnataka)",
        OriginCenter: "Sindhanur_Karatagi_D (Karnataka)",
        FacilityCity: "Sindhanur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16380,
        Pin: 680320,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16381,
        Pin: 301017,
        DispatchCenter: "Tijara_Ward3_D (Rajasthan)",
        OriginCenter: "Tijara_Ward3_D (Rajasthan)",
        FacilityCity: "Tijara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16382,
        Pin: 422110,
        DispatchCenter: "Satana_MitraNagar_D (Maharashtra)",
        OriginCenter: "Satana_MitraNagar_D (Maharashtra)",
        FacilityCity: "Satana",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16383,
        Pin: 277204,
        DispatchCenter: "Bairia_Bhagwanpur_DPP (Uttar Pradesh)",
        OriginCenter: "Bairia_Bhagwanpur_DPP (Uttar Pradesh)",
        FacilityCity: "Bariya",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16384,
        Pin: 457001,
        DispatchCenter: "Ratlam_MhowRd_D (Madhya Pradesh)",
        OriginCenter: "Ratlam_MhowRd_D (Madhya Pradesh)",
        FacilityCity: "Ratlam",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 16385,
        Pin: 627434,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16386,
        Pin: 388211,
        DispatchCenter: "Umreth_PltinumPlza_DPP (Gujarat)",
        OriginCenter: "Umreth_PltinumPlza_DPP (Gujarat)",
        FacilityCity: "Umreth",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16387,
        Pin: 768040,
        DispatchCenter: "Bargarh_Ambapali_D (Orissa)",
        OriginCenter: "Bargarh_Ambapali_D (Orissa)",
        FacilityCity: "Bargarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16388,
        Pin: 212621,
        DispatchCenter: "Fatehpur_Gandhinagar_D (Uttar Pradesh)",
        OriginCenter: "Fatehpur_Gandhinagar_D (Uttar Pradesh)",
        FacilityCity: "Fatehpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16389,
        Pin: 828113,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16390,
        Pin: 415401,
        DispatchCenter: "Shirala_PostOffice_DPP (Maharashtra)",
        OriginCenter: "Shirala_PostOffice_DPP (Maharashtra)",
        FacilityCity: "Shirala",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16391,
        Pin: 782446,
        DispatchCenter: "Lanka_Sambari_D (Assam)",
        OriginCenter: "Lanka_Sambari_D (Assam)",
        FacilityCity: "Lanka",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16392,
        Pin: 576227,
        DispatchCenter: "Kundapura_Shankarnarayana_D (Karnataka)",
        OriginCenter: "Kundapura_Shankarnarayana_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16393,
        Pin: 785015,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16394,
        Pin: 607801,
        DispatchCenter: "Neyveli_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Neyveli_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Neyveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16395,
        Pin: 731303,
        DispatchCenter: "Nanoor_Central_DPP_1 (West Bengal)",
        OriginCenter: "Nanoor_Central_DPP_1 (West Bengal)",
        FacilityCity: "Nanoor",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16396,
        Pin: 262724,
        DispatchCenter: "Dhaurahara_TahsilRD_D (Uttar Pradesh)",
        OriginCenter: "Dhaurahara_TahsilRD_D (Uttar Pradesh)",
        FacilityCity: "Dhaurahara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16397,
        Pin: 140402,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16398,
        Pin: 246728,
        DispatchCenter: "Bijnor_Garhi_D (Uttar Pradesh)",
        OriginCenter: "Bijnor_Garhi_D (Uttar Pradesh)",
        FacilityCity: "Bijnor",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16399,
        Pin: 722141,
        DispatchCenter: "MirzapurWB_VidyaDPP_D (West Bengal)",
        OriginCenter: "MirzapurWB_VidyaDPP_D (West Bengal)",
        FacilityCity: "Mirzapur-WB",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16400,
        Pin: 575026,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16401,
        Pin: 670004,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16402,
        Pin: 584130,
        DispatchCenter: "Gangavathi_CBSGanj_DPP (Karnataka)",
        OriginCenter: "Gangavathi_CBSGanj_DPP (Karnataka)",
        FacilityCity: "Gangavathi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16403,
        Pin: 208018,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16404,
        Pin: 574285,
        DispatchCenter: "Kadaba_AnugrDPP_D (Karnataka)",
        OriginCenter: "Kadaba_AnugrDPP_D (Karnataka)",
        FacilityCity: "Kadaba",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16405,
        Pin: 500890,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16406,
        Pin: 333048,
        DispatchCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        OriginCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16407,
        Pin: 473662,
        DispatchCenter: "Karera_DenidaRd_D (Madhya Pradesh)",
        OriginCenter: "Karera_DenidaRd_D (Madhya Pradesh)",
        FacilityCity: "Karera-MP",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 16408,
        Pin: 444311,
        DispatchCenter: "Akola_Midcphase2_D (Maharashtra)",
        OriginCenter: "Akola_Midcphase2_D (Maharashtra)",
        FacilityCity: "Akola",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16409,
        Pin: 504310,
        DispatchCenter: "Nirmal_AdarshNgr_D (Telangana)",
        OriginCenter: "Nirmal_AdarshNgr_D (Telangana)",
        FacilityCity: "Nirmal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16410,
        Pin: 785613,
        DispatchCenter: "Golaghat_BaruaRd_D (Assam)",
        OriginCenter: "Golaghat_BaruaRd_D (Assam)",
        FacilityCity: "Golaghat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16411,
        Pin: 283110,
        DispatchCenter: "Kirauli_AchneraRD_D (Uttar Pradesh)",
        OriginCenter: "Kirauli_AchneraRD_D (Uttar Pradesh)",
        FacilityCity: "Kirauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16412,
        Pin: 577179,
        DispatchCenter: "Kalasa_MahaveerRd_DPP (Karnataka)",
        OriginCenter: "Kalasa_MahaveerRd_DPP (Karnataka)",
        FacilityCity: "Kalasa",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16413,
        Pin: 273304,
        DispatchCenter: "Nichlaul_Gorahwa_D (Uttar Pradesh)",
        OriginCenter: "Nichlaul_Gorahwa_D (Uttar Pradesh)",
        FacilityCity: "Nichlaul",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16414,
        Pin: 742123,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16415,
        Pin: 177108,
        DispatchCenter: "DehraGopipur_Central_D_1 (Himachal Pradesh)",
        OriginCenter: "DehraGopipur_Central_D_1 (Himachal Pradesh)",
        FacilityCity: "Dehra Gopipur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16416,
        Pin: 741155,
        DispatchCenter: "Plassey_Janakinagar_D (West Bengal)",
        OriginCenter: "Plassey_Janakinagar_D (West Bengal)",
        FacilityCity: "Plassey",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16417,
        Pin: 212105,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16418,
        Pin: 678624,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16419,
        Pin: 209304,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16420,
        Pin: 638314,
        DispatchCenter: "Anthiyur_Thavittuplym_DPP (Tamil Nadu)",
        OriginCenter: "Anthiyur_Thavittuplym_DPP (Tamil Nadu)",
        FacilityCity: "Anthiyour",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16421,
        Pin: 271125,
        DispatchCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        OriginCenter: "Gonda_Babhanikanoongo_D (Uttar Pradesh)",
        FacilityCity: "Gonda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16422,
        Pin: 845412,
        DispatchCenter: "Chakia_Honda_D (Bihar)",
        OriginCenter: "Chakia_Honda_D (Bihar)",
        FacilityCity: "Chakia",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16423,
        Pin: 804420,
        DispatchCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        OriginCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        FacilityCity: "Jehanabad",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16424,
        Pin: 600085,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16425,
        Pin: 621006,
        DispatchCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        OriginCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        FacilityCity: "Kulithalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16426,
        Pin: 301403,
        DispatchCenter: "Khairthal_HarsouliRoad_D (Rajasthan)",
        OriginCenter: "Khairthal_HarsouliRoad_D (Rajasthan)",
        FacilityCity: "Khairthal",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16427,
        Pin: 471408,
        DispatchCenter: "Chhatarpur_SagarRd_D (Madhya Pradesh)",
        OriginCenter: "Chhatarpur_SagarRd_D (Madhya Pradesh)",
        FacilityCity: "Chhatarpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 16428,
        Pin: 474042,
        DispatchCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        OriginCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        FacilityCity: "Gwalior",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 16429,
        Pin: 854344,
        DispatchCenter: "Forbesganj_Parwaha_D (Bihar)",
        OriginCenter: "Forbesganj_Parwaha_D (Bihar)",
        FacilityCity: "Forbesganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16430,
        Pin: 679106,
        DispatchCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        OriginCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        FacilityCity: "Pazhayannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16431,
        Pin: 642052,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16432,
        Pin: 148047,
        DispatchCenter: "Sangrur_DC (Punjab)",
        OriginCenter: "Sangrur_DC (Punjab)",
        FacilityCity: "Sangrur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16433,
        Pin: 416421,
        DispatchCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        OriginCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        FacilityCity: "Miraj",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16434,
        Pin: 733128,
        DispatchCenter: "Itahar_Kumedpur_D (West Bengal)",
        OriginCenter: "Itahar_Kumedpur_D (West Bengal)",
        FacilityCity: "Itahar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16435,
        Pin: 122227,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16436,
        Pin: 226012,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16437,
        Pin: 577552,
        DispatchCenter: "Davangere_Santhebennur_D (Karnataka)",
        OriginCenter: "Davangere_Santhebennur_D (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16438,
        Pin: 641012,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16439,
        Pin: 803215,
        DispatchCenter: "Barh_Malahi_D (Bihar)",
        OriginCenter: "Barh_Malahi_D (Bihar)",
        FacilityCity: "Barh",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16440,
        Pin: 474020,
        DispatchCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        OriginCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        FacilityCity: "Gwalior",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 16441,
        Pin: 396350,
        DispatchCenter: "Bilimora_DC (Gujarat)",
        OriginCenter: "Bilimora_DC (Gujarat)",
        FacilityCity: "Bilimora",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16442,
        Pin: 111112,
        DispatchCenter: "Faridabad_MathuraRoad_L (Haryana)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16443,
        Pin: 464774,
        DispatchCenter: "Tendukheda_AadhaarCntr_D (Madhya Pradesh)",
        OriginCenter: "Tendukheda_AadhaarCntr_D (Madhya Pradesh)",
        FacilityCity: "Gadarwara",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 16444,
        Pin: 244901,
        DispatchCenter: "Rampur_RoshanBagh_D (Uttar Pradesh)",
        OriginCenter: "Rampur_RoshanBagh_D (Uttar Pradesh)",
        FacilityCity: "Rampur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16445,
        Pin: 825312,
        DispatchCenter: "Bagodar_Bishungarh_DPP (Jharkhand)",
        OriginCenter: "Bagodar_Bishungarh_DPP (Jharkhand)",
        FacilityCity: "Bagodar",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16446,
        Pin: 110019,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16447,
        Pin: 141108,
        DispatchCenter: "Raikot_DC (Punjab)",
        OriginCenter: "Raikot_DC (Punjab)",
        FacilityCity: "Raikot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16448,
        Pin: 388306,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16449,
        Pin: 800029,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16450,
        Pin: 851115,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16451,
        Pin: 144802,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16452,
        Pin: 785630,
        DispatchCenter: "Jorhat_Titabor_D (Assam)",
        OriginCenter: "Jorhat_Titabor_D (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16453,
        Pin: 394633,
        DispatchCenter: "Songadh_Vyara_D (Gujarat)",
        OriginCenter: "Songadh_Vyara_D (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16454,
        Pin: 396427,
        DispatchCenter: "Navsari_Vejalpore_D (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Navsari",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16455,
        Pin: 626571,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16456,
        Pin: 573202,
        DispatchCenter: "Hassan_Nagathavalli_I (Karnataka)",
        OriginCenter: "Hassan_Nagathavalli_I (Karnataka)",
        FacilityCity: "Hassan",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16457,
        Pin: 389130,
        DispatchCenter: "Limkheda_VjyCmplx_DPP (Gujarat)",
        OriginCenter: "Limkheda_VjyCmplx_DPP (Gujarat)",
        FacilityCity: "Limkheda",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16458,
        Pin: 424208,
        DispatchCenter: "Jamner_BusStd_D (Maharashtra)",
        OriginCenter: "Jamner_BusStd_D (Maharashtra)",
        FacilityCity: "Jamner",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16459,
        Pin: 521207,
        DispatchCenter: "Nuzvid_NSPColony_D (Andhra Pradesh)",
        OriginCenter: "Nuzvid_NSPColony_D (Andhra Pradesh)",
        FacilityCity: "Nuzvid",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16460,
        Pin: 801112,
        DispatchCenter: "Bihta_Kateshwar_D (Bihar)",
        OriginCenter: "Bihta_Kateshwar_D (Bihar)",
        FacilityCity: "Bihta",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16461,
        Pin: 686141,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16462,
        Pin: 671348,
        DispatchCenter: "Manjeshwar_Morathana_D (Kerala)",
        OriginCenter: "Manjeshwar_Morathana_D (Kerala)",
        FacilityCity: "Manjeshwar",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16463,
        Pin: 678592,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16464,
        Pin: 621206,
        DispatchCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        OriginCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        FacilityCity: "Thuraiyur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16465,
        Pin: 741154,
        DispatchCenter: "Nakashipara_Kanthalbaria_DPP (West Bengal)",
        OriginCenter: "Nakashipara_Kanthalbaria_DPP (West Bengal)",
        FacilityCity: "Nakashipara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16466,
        Pin: 590014,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16467,
        Pin: 570029,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16468,
        Pin: 503202,
        DispatchCenter: "Bodhan_VamshiSchl_D (Telangana)",
        OriginCenter: "Bodhan_VamshiSchl_D (Telangana)",
        FacilityCity: "Bodhan",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16469,
        Pin: 413317,
        DispatchCenter: "Pandharpur_RajCmplx_D (Maharashtra)",
        OriginCenter: "Pandharpur_RajCmplx_D (Maharashtra)",
        FacilityCity: "Pandharpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16470,
        Pin: 622001,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16471,
        Pin: 680722,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16472,
        Pin: 585211,
        DispatchCenter: "Chittapur_GlbrgaRD_D (Karnataka)",
        OriginCenter: "Chittapur_GlbrgaRD_D (Karnataka)",
        FacilityCity: "Chittapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16473,
        Pin: 450116,
        DispatchCenter: "Harda_Khirkiya1_DPP (Madhya Pradesh)",
        OriginCenter: "Harda_Khirkiya1_DPP (Madhya Pradesh)",
        FacilityCity: "Harda",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 16474,
        Pin: 802114,
        DispatchCenter: "Rajpur_Devdhiya_D (Bihar)",
        OriginCenter: "Rajpur_Devdhiya_D (Bihar)",
        FacilityCity: "Rajpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16475,
        Pin: 691302,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16476,
        Pin: 394515,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16477,
        Pin: 311302,
        DispatchCenter: "Badnaur_Asind_D (Rajasthan)",
        OriginCenter: "Badnaur_Asind_D (Rajasthan)",
        FacilityCity: "Asind",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16478,
        Pin: 635803,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16479,
        Pin: 171102,
        DispatchCenter: "Darlaghat_Central_D_1 (Himachal Pradesh)",
        OriginCenter: "Darlaghat_Central_D_1 (Himachal Pradesh)",
        FacilityCity: "Darlaghat",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16480,
        Pin: 396031,
        DispatchCenter: "Valsad_LimbaChwk_D (Gujarat)",
        OriginCenter: "Valsad_LimbaChwk_D (Gujarat)",
        FacilityCity: "Valsad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16481,
        Pin: 364540,
        DispatchCenter: "Amreli_Bhojalpara_D (Gujarat)",
        OriginCenter: "Amreli_Bhojalpara_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16482,
        Pin: 431215,
        DispatchCenter: "Jalna_Yashodipnagar_D (Maharashtra)",
        OriginCenter: "Jalna_Yashodipnagar_D (Maharashtra)",
        FacilityCity: "Jalna",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16483,
        Pin: 700135,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16484,
        Pin: 587115,
        DispatchCenter: "Muddebihal_Nekarcolony_D (Karnataka)",
        OriginCenter: "Muddebihal_Nekarcolony_D (Karnataka)",
        FacilityCity: "Muddebihal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16485,
        Pin: 325220,
        DispatchCenter: "Kawai_KhanpurRd_DPP (Rajasthan)",
        OriginCenter: "Kawai_KhanpurRd_DPP (Rajasthan)",
        FacilityCity: "Kawai",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16486,
        Pin: 271835,
        DispatchCenter: "Ikauna_Hndutmple_D (Uttar Pradesh)",
        OriginCenter: "Ikauna_Hndutmple_D (Uttar Pradesh)",
        FacilityCity: "Ikauna",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16487,
        Pin: 607108,
        DispatchCenter: "Panruti_Thiruvathigai_D (Tamil Nadu)",
        OriginCenter: "Panruti_Thiruvathigai_D (Tamil Nadu)",
        FacilityCity: "Panruti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16488,
        Pin: 680514,
        DispatchCenter: "Thrissur_Koonammoochi_D (Kerala)",
        OriginCenter: "Thrissur_Koonammoochi_D (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16489,
        Pin: 344801,
        DispatchCenter: "Jalore_Sayla_DPP (Rajasthan)",
        OriginCenter: "Jalore_Sayla_DPP (Rajasthan)",
        FacilityCity: "Jalore",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16490,
        Pin: 606207,
        DispatchCenter: "Kallakurichi_Neelamangalam_D (Tamil Nadu)",
        OriginCenter: "Kallakurichi_Neelamangalam_D (Tamil Nadu)",
        FacilityCity: "Kallakurichi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16491,
        Pin: 396460,
        DispatchCenter: "Bilimora_DC (Gujarat)",
        OriginCenter: "Bilimora_DC (Gujarat)",
        FacilityCity: "Bilimora",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16492,
        Pin: 605001,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16493,
        Pin: 782010,
        DispatchCenter: "Nagaon_Bangthai_H (Assam)",
        OriginCenter: "Nagaon_Bangthai_H (Assam)",
        FacilityCity: "Nagaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16494,
        Pin: 636114,
        DispatchCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        OriginCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        FacilityCity: "Attur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16495,
        Pin: 361142,
        DispatchCenter: "Jamnagar_Chela_L (Gujarat)",
        OriginCenter: "Jamnagar_Chela_L (Gujarat)",
        FacilityCity: "Jamnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16496,
        Pin: 412216,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16497,
        Pin: 175128,
        DispatchCenter: "Patlikuhal_Katrain_D (Himachal Pradesh)",
        OriginCenter: "Patlikuhal_Katrain_D (Himachal Pradesh)",
        FacilityCity: "Patlikuhal",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16498,
        Pin: 152023,
        DispatchCenter: "Ferozepur_DC (Punjab)",
        OriginCenter: "Ferozepur_DC (Punjab)",
        FacilityCity: "Firozpur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16499,
        Pin: 515003,
        DispatchCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        OriginCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16500,
        Pin: 341316,
        DispatchCenter: "Sujangarh_Khanpur_D (Rajasthan)",
        OriginCenter: "Sujangarh_Khanpur_D (Rajasthan)",
        FacilityCity: "Sujangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16501,
        Pin: 230143,
        DispatchCenter: "Kunda_KaretiRd_D (Uttar Pradesh)",
        OriginCenter: "Kunda_KaretiRd_D (Uttar Pradesh)",
        FacilityCity: "Kunda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16502,
        Pin: 415718,
        DispatchCenter: "Chiplun_Burumtali_D (Maharashtra)",
        OriginCenter: "Chiplun_Burumtali_D (Maharashtra)",
        FacilityCity: "Chiplun",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16503,
        Pin: 623402,
        DispatchCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        OriginCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        FacilityCity: "Thiruvadanai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16504,
        Pin: 847337,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16505,
        Pin: 212104,
        DispatchCenter: "Manda_Salia_D (Uttar Pradesh)",
        OriginCenter: "Manda_Salia_D (Uttar Pradesh)",
        FacilityCity: "Manda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16506,
        Pin: 384470,
        DispatchCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        OriginCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        FacilityCity: "Vijapur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16507,
        Pin: 383317,
        DispatchCenter: "Modasa_Shyamnagar_D (Gujarat)",
        OriginCenter: "Modasa_Shyamnagar_D (Gujarat)",
        FacilityCity: "Modasa",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16508,
        Pin: 690534,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16509,
        Pin: 824207,
        DispatchCenter: "Tekari_BaheliyaBigha_D (Bihar)",
        OriginCenter: "Tekari_BaheliyaBigha_D (Bihar)",
        FacilityCity: "Tekari",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16510,
        Pin: 591310,
        DispatchCenter: "Mahalingpur_Bagalkot_D (Karnataka)",
        OriginCenter: "Mahalingpur_Bagalkot_D (Karnataka)",
        FacilityCity: "Mahalingpur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16511,
        Pin: 606901,
        DispatchCenter: "Polur_Kanji_D (Tamil Nadu)",
        OriginCenter: "Polur_Kanji_D (Tamil Nadu)",
        FacilityCity: "Polur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16512,
        Pin: 741103,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16513,
        Pin: 247656,
        DispatchCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        OriginCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        FacilityCity: "Haridwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16514,
        Pin: 560078,
        DispatchCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        OriginCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16515,
        Pin: 229406,
        DispatchCenter: "Unchahar_Khojanpur_D (Uttar Pradesh)",
        OriginCenter: "Unchahar_Khojanpur_D (Uttar Pradesh)",
        FacilityCity: "Unchahar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16516,
        Pin: 828110,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16517,
        Pin: 276136,
        DispatchCenter: "Jiyanpur_GorkhpurRd_D (Uttar Pradesh)",
        OriginCenter: "Jiyanpur_GorkhpurRd_D (Uttar Pradesh)",
        FacilityCity: "Jiyanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16518,
        Pin: 600095,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16519,
        Pin: 629808,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16520,
        Pin: 416207,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16521,
        Pin: 501142,
        DispatchCenter: "Tandur_SnthiNGR_D (Telangana)",
        OriginCenter: "Tandur_SnthiNGR_D (Telangana)",
        FacilityCity: "Tandur-TL",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16522,
        Pin: 637504,
        DispatchCenter: "Sankari_Idappadi_D (Tamil Nadu)",
        OriginCenter: "Sankari_Idappadi_D (Tamil Nadu)",
        FacilityCity: "Sankari",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16523,
        Pin: 122101,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16524,
        Pin: 152129,
        DispatchCenter: "Abohar_DaulatPura_D (Punjab)",
        OriginCenter: "Abohar_DaulatPura_D (Punjab)",
        FacilityCity: "Abohar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16525,
        Pin: 741313,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16526,
        Pin: 680010,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16527,
        Pin: 360575,
        DispatchCenter: "Porbandar_Vadiplot_D (Gujarat)",
        OriginCenter: "Porbandar_Vadiplot_D (Gujarat)",
        FacilityCity: "Porbandar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16528,
        Pin: 396040,
        DispatchCenter: "Bilimora_DC (Gujarat)",
        OriginCenter: "Bilimora_DC (Gujarat)",
        FacilityCity: "Bilimora",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16529,
        Pin: 571121,
        DispatchCenter: "Hunsur_HDKote_D (Karnataka)",
        OriginCenter: "Hunsur_HDKote_D (Karnataka)",
        FacilityCity: "Hunsur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16530,
        Pin: 272301,
        DispatchCenter: "Tanda_HayatganjChowk_D (Uttar Pradesh)",
        OriginCenter: "Tanda_HayatganjChowk_D (Uttar Pradesh)",
        FacilityCity: "Tanda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16531,
        Pin: 843031,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16532,
        Pin: 686039,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16533,
        Pin: 803101,
        DispatchCenter: "BiharSharif_Sherpur_D (Bihar)",
        OriginCenter: "BiharSharif_Sherpur_D (Bihar)",
        FacilityCity: "Bihar Sharif",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16534,
        Pin: 676507,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16535,
        Pin: 802214,
        DispatchCenter: "Bikramgang_Dehrird_D (Bihar)",
        OriginCenter: "Bikramgang_Dehrird_D (Bihar)",
        FacilityCity: "Bikramganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16536,
        Pin: 743222,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16537,
        Pin: 332305,
        DispatchCenter: "Jhunjhunu_Mandawa_DPP (Rajasthan)",
        OriginCenter: "Jhunjhunu_Mandawa_DPP (Rajasthan)",
        FacilityCity: "Jhunjhunu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16538,
        Pin: 577540,
        DispatchCenter: "Chitradurga_Pota_DPP (Karnataka)",
        OriginCenter: "Chitradurga_Pota_DPP (Karnataka)",
        FacilityCity: "Molakalmuru",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16539,
        Pin: 480555,
        DispatchCenter: "Junnardeo_DamuaRd_DPP (Madhya Pradesh)",
        OriginCenter: "Junnardeo_DamuaRd_DPP (Madhya Pradesh)",
        FacilityCity: "Junnardeo",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 16540,
        Pin: 678014,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16541,
        Pin: 224133,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16542,
        Pin: 670109,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16543,
        Pin: 470675,
        DispatchCenter: "Damoh_Chouprakhurd_D (Madhya Pradesh)",
        OriginCenter: "Damoh_Chouprakhurd_D (Madhya Pradesh)",
        FacilityCity: "Damoh",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 16544,
        Pin: 600019,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16545,
        Pin: 271126,
        DispatchCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        OriginCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        FacilityCity: "Colonejganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16546,
        Pin: 221003,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16547,
        Pin: 683555,
        DispatchCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        OriginCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        FacilityCity: "Muvattupuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16548,
        Pin: 176041,
        DispatchCenter: "Bhota_BpassDPP_D (Himachal Pradesh)",
        OriginCenter: "Bhota_BpassDPP_D (Himachal Pradesh)",
        FacilityCity: "Bhota",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16549,
        Pin: 144527,
        DispatchCenter: "Garhshankar_BangaRoad_D (Punjab)",
        OriginCenter: "Garhshankar_BangaRoad_D (Punjab)",
        FacilityCity: "Garhshanker",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16550,
        Pin: 602024,
        DispatchCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        OriginCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16551,
        Pin: 385410,
        DispatchCenter: "Ambaji_Khodiyarchowk_DPP (Gujarat)",
        OriginCenter: "Ambaji_Khodiyarchowk_DPP (Gujarat)",
        FacilityCity: "Ambaji",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16552,
        Pin: 401201,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16553,
        Pin: 604504,
        DispatchCenter: "Arani_PolurRd_D (Tamil Nadu)",
        OriginCenter: "Arani_PolurRd_D (Tamil Nadu)",
        FacilityCity: "Arani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16554,
        Pin: 742137,
        DispatchCenter: "Khargram_Parbatipur_D (West Bengal)",
        OriginCenter: "Khargram_Parbatipur_D (West Bengal)",
        FacilityCity: "Khargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16555,
        Pin: 799131,
        DispatchCenter: "Udaipur_Sonamura_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Udaipur-TR",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16556,
        Pin: 591113,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16557,
        Pin: 827014,
        DispatchCenter: "Bokaro_Chas_D (Jharkhand)",
        OriginCenter: "Bokaro_Chas_D (Jharkhand)",
        FacilityCity: "Bokaro",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16558,
        Pin: 487330,
        DispatchCenter: "Narsinghpur_Kareli_DPP (Madhya Pradesh)",
        OriginCenter: "Narsinghpur_Kareli_DPP (Madhya Pradesh)",
        FacilityCity: "Kareli",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 16559,
        Pin: 562120,
        DispatchCenter: "Magadi_Hosapete_D (Karnataka)",
        OriginCenter: "Magadi_Hosapete_D (Karnataka)",
        FacilityCity: "Magadi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16560,
        Pin: 285203,
        DispatchCenter: "Pukhrayan_Ganeshganj_D (Uttar Pradesh)",
        OriginCenter: "Pukhrayan_Ganeshganj_D (Uttar Pradesh)",
        FacilityCity: "Pukhrayan",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16561,
        Pin: 641652,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16562,
        Pin: 391520,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16563,
        Pin: 388310,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16564,
        Pin: 751012,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16565,
        Pin: 680325,
        DispatchCenter: "Chalakudy_ForestChowk_D (Kerala)",
        OriginCenter: "Chalakudy_ForestChowk_D (Kerala)",
        FacilityCity: "Chalakudy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16566,
        Pin: 414113,
        DispatchCenter: "Pathardi_Shivshaktinagar_D (Maharashtra)",
        OriginCenter: "Pathardi_Shivshaktinagar_D (Maharashtra)",
        FacilityCity: "Pathardi",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16567,
        Pin: 606710,
        DispatchCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        OriginCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        FacilityCity: "Tiruvannamalai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16568,
        Pin: 586209,
        DispatchCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        OriginCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        FacilityCity: "Indi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16569,
        Pin: 623135,
        DispatchCenter: "Perunali_Sayalkudi_D (Tamil Nadu)",
        OriginCenter: "Perunali_Sayalkudi_D (Tamil Nadu)",
        FacilityCity: "Perunali",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16570,
        Pin: 712148,
        DispatchCenter: "Hooghly_Bikramnagar_D (West Bengal)",
        OriginCenter: "Hooghly_Bikramnagar_D (West Bengal)",
        FacilityCity: "Hooghly",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16571,
        Pin: 341512,
        DispatchCenter: "Makrana_Parbatsar_D (Rajasthan)",
        OriginCenter: "Makrana_Parbatsar_D (Rajasthan)",
        FacilityCity: "Makrana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16572,
        Pin: 162630,
        DispatchCenter: "Moga_Duneke_I (Punjab)",
        OriginCenter: "Moga_Duneke_I (Punjab)",
        FacilityCity: "Moga",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16573,
        Pin: 679122,
        DispatchCenter: "Shoranur_Vaniyamkulam_D (Kerala)",
        OriginCenter: "Shoranur_Vaniyamkulam_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16574,
        Pin: 201309,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16575,
        Pin: 758080,
        DispatchCenter: "Ghatgaon_Harichandanpur_D (Orissa)",
        OriginCenter: "Ghatgaon_Harichandanpur_D (Orissa)",
        FacilityCity: "Ghatgaon",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16576,
        Pin: 144422,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16577,
        Pin: 143303,
        DispatchCenter: "Patti_TarnTaran_D (Punjab)",
        OriginCenter: "Patti_TarnTaran_D (Punjab)",
        FacilityCity: "Patti",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16578,
        Pin: 274403,
        DispatchCenter: "Kushinagar_Siswa_D (Uttar Pradesh)",
        OriginCenter: "Kushinagar_Siswa_D (Uttar Pradesh)",
        FacilityCity: "Kushinagar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16579,
        Pin: 325009,
        DispatchCenter: "Itawa_GentaRd_DPP (Rajasthan)",
        OriginCenter: "Itawa_GentaRd_DPP (Rajasthan)",
        FacilityCity: "Itawa",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16580,
        Pin: 221506,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16581,
        Pin: 515201,
        DispatchCenter: "Hindupur_Sadlapalli_D (Andhra Pradesh)",
        OriginCenter: "Hindupur_Sadlapalli_D (Andhra Pradesh)",
        FacilityCity: "Hindupur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16582,
        Pin: 301019,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16583,
        Pin: 673519,
        DispatchCenter: "Kozhikode_WestHill_D (Kerala)",
        OriginCenter: "Kozhikode_WestHill_D (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16584,
        Pin: 144508,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16585,
        Pin: 244716,
        DispatchCenter: "Kashipur_Khokhratal_D (Uttarakhand)",
        OriginCenter: "Kashipur_Khokhratal_D (Uttarakhand)",
        FacilityCity: "Kashipur",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16586,
        Pin: 383307,
        DispatchCenter: "Bayad_PwnPlza_D (Gujarat)",
        OriginCenter: "Bayad_PwnPlza_D (Gujarat)",
        FacilityCity: "Bayad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16587,
        Pin: 422203,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16588,
        Pin: 177022,
        DispatchCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        OriginCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        FacilityCity: "Hamirpur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16589,
        Pin: 152020,
        DispatchCenter: "Fazilka_Malkana_D (Punjab)",
        OriginCenter: "Fazilka_Malkana_D (Punjab)",
        FacilityCity: "Fazilka",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16590,
        Pin: 416011,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16591,
        Pin: 621806,
        DispatchCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        OriginCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        FacilityCity: "Jayankondam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16592,
        Pin: 627425,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16593,
        Pin: 327002,
        DispatchCenter: "Banswara_Baridaylan_D (Rajasthan)",
        OriginCenter: "Banswara_Baridaylan_D (Rajasthan)",
        FacilityCity: "Banswara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16594,
        Pin: 560017,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16595,
        Pin: 388610,
        DispatchCenter: "Vatadara_Banejda_DPP (Gujarat)",
        OriginCenter: "Vatadara_Banejda_DPP (Gujarat)",
        FacilityCity: "Vatadara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16596,
        Pin: 636601,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16597,
        Pin: 322701,
        DispatchCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        OriginCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        FacilityCity: "Sawai Madhopur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16598,
        Pin: 609308,
        DispatchCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        OriginCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        FacilityCity: "Karaikal",
        FacilityState: "Pondicherry",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16599,
        Pin: 574148,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16600,
        Pin: 531029,
        DispatchCenter: "Paderu_Madugula_DPP (Andhra Pradesh)",
        OriginCenter: "Paderu_Madugula_DPP (Andhra Pradesh)",
        FacilityCity: "Paderu",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16601,
        Pin: 786611,
        DispatchCenter: "Sonari_Nakachari_D (Assam)",
        OriginCenter: "Sonari_Nakachari_D (Assam)",
        FacilityCity: "Sonari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16602,
        Pin: 784001,
        DispatchCenter: "Tezpur_Dipota_H (Assam)",
        OriginCenter: "Tezpur_Dipota_H (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16603,
        Pin: 388640,
        DispatchCenter: "Khambhat_MotvdDPP_D (Gujarat)",
        OriginCenter: "Khambhat_MotvdDPP_D (Gujarat)",
        FacilityCity: "Khambhat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16604,
        Pin: 211017,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16605,
        Pin: 689612,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16606,
        Pin: 501509,
        DispatchCenter: "Yacharam_Nallavelly_D (Telangana)",
        OriginCenter: "Yacharam_Nallavelly_D (Telangana)",
        FacilityCity: "Yacharam",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16607,
        Pin: 122009,
        DispatchCenter: "Gurgaon_Sector52_L (Haryana)",
        OriginCenter: "Gurgaon_Sector52_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16608,
        Pin: 796001,
        DispatchCenter: "Aizawl_Sairang_I (Mizoram)",
        OriginCenter: "Aizawl_Sairang_I (Mizoram)",
        FacilityCity: "Aizawl",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16609,
        Pin: 384275,
        DispatchCenter: "Patan_Hansapur_D (Gujarat)",
        OriginCenter: "Patan_Hansapur_D (Gujarat)",
        FacilityCity: "Patan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16610,
        Pin: 136128,
        DispatchCenter: "Pehowa_ModelTown_DPP (Haryana)",
        OriginCenter: "Pehowa_ModelTown_DPP (Haryana)",
        FacilityCity: "Pehowa",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16611,
        Pin: 630311,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16612,
        Pin: 821312,
        DispatchCenter: "Sasaram_Manikpur_D (Bihar)",
        OriginCenter: "Sasaram_Manikpur_D (Bihar)",
        FacilityCity: "Sasaram",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16613,
        Pin: 171010,
        DispatchCenter: "Shimla_Tara_DC (Himachal Pradesh)",
        OriginCenter: "Shimla_Tara_DC (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16614,
        Pin: 802209,
        DispatchCenter: "Piro_Bachri_D (Bihar)",
        OriginCenter: "Piro_Bachri_D (Bihar)",
        FacilityCity: "Piro",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16615,
        Pin: 285125,
        DispatchCenter: "Auraiya_Brahmngr_D (Uttar Pradesh)",
        OriginCenter: "Auraiya_Brahmngr_D (Uttar Pradesh)",
        FacilityCity: "Auraiya",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16616,
        Pin: 613602,
        DispatchCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        OriginCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        FacilityCity: "Thirukkattupalli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16617,
        Pin: 627804,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16618,
        Pin: 413526,
        DispatchCenter: "Bhoom_KusumNagar_D (Maharashtra)",
        OriginCenter: "Bhoom_KusumNagar_D (Maharashtra)",
        FacilityCity: "Bhoom",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16619,
        Pin: 224126,
        DispatchCenter: "Rudauli_Ghosaiyana_D (Uttar Pradesh)",
        OriginCenter: "Rudauli_Ghosaiyana_D (Uttar Pradesh)",
        FacilityCity: "Rudauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16620,
        Pin: 803221,
        DispatchCenter: "Barh_Malahi_D (Bihar)",
        OriginCenter: "Barh_Malahi_D (Bihar)",
        FacilityCity: "Barh",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16621,
        Pin: 400410,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16622,
        Pin: 685535,
        DispatchCenter: "Kumily_Attappallam_D (Kerala)",
        OriginCenter: "Kumily_Attappallam_D (Kerala)",
        FacilityCity: "Kumily",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16623,
        Pin: 431128,
        DispatchCenter: "Manjlegaon_Telgaon_DPP (Maharashtra)",
        OriginCenter: "Manjlegaon_Telgaon_DPP (Maharashtra)",
        FacilityCity: "Manjlegaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16624,
        Pin: 421204,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16625,
        Pin: 767065,
        DispatchCenter: "Balangir_PalaceLine_D (Orissa)",
        OriginCenter: "Balangir_PalaceLine_D (Orissa)",
        FacilityCity: "Balangir",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16626,
        Pin: 516233,
        DispatchCenter: "Badvel_Vidyanagar_D (Andhra Pradesh)",
        OriginCenter: "Badvel_Vidyanagar_D (Andhra Pradesh)",
        FacilityCity: "Badvel",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16627,
        Pin: 518583,
        DispatchCenter: "Sirvella_Sirivelmitta_DPP (Andhra Pradesh)",
        OriginCenter: "Sirvella_Sirivelmitta_DPP (Andhra Pradesh)",
        FacilityCity: "Sirvella",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16628,
        Pin: 400036,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16629,
        Pin: 508221,
        DispatchCenter: "Tungaturthi_CanaraBank_D (Telangana)",
        OriginCenter: "Tungaturthi_CanaraBank_D (Telangana)",
        FacilityCity: "Tungaturthi",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16630,
        Pin: 414605,
        DispatchCenter: "Newasa_Khurd_D (Maharashtra)",
        OriginCenter: "Newasa_Khurd_D (Maharashtra)",
        FacilityCity: "Newasa",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16631,
        Pin: 263137,
        DispatchCenter: "Bhimtal_BhowaliRd_D (Uttarakhand)",
        OriginCenter: "Bhimtal_BhowaliRd_D (Uttarakhand)",
        FacilityCity: "Bhimtal",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16632,
        Pin: 534275,
        DispatchCenter: "Narsapur_Rustumbada_D (Andhra Pradesh)",
        OriginCenter: "Narsapur_Rustumbada_D (Andhra Pradesh)",
        FacilityCity: "Narsapur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16633,
        Pin: 223104,
        DispatchCenter: "Shahganj_Kaudiya_D (Uttar Pradesh)",
        OriginCenter: "Shahganj_Kaudiya_D (Uttar Pradesh)",
        FacilityCity: "Shahganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16634,
        Pin: 831007,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16635,
        Pin: 600065,
        DispatchCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        OriginCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16636,
        Pin: 765023,
        DispatchCenter: "Gunupur_GnprMkt_DPP (Orissa)",
        OriginCenter: "Gunupur_GnprMkt_DPP (Orissa)",
        FacilityCity: "Gunupur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16637,
        Pin: 680595,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16638,
        Pin: 671544,
        DispatchCenter: "Kasaragod_Kudlu_D (Kerala)",
        OriginCenter: "Kasaragod_Kudlu_D (Kerala)",
        FacilityCity: "Kasaragod",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16639,
        Pin: 229121,
        DispatchCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        OriginCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        FacilityCity: "Lalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16640,
        Pin: 457227,
        DispatchCenter: "Jaora_AdityaNagar_D (Madhya Pradesh)",
        OriginCenter: "Jaora_AdityaNagar_D (Madhya Pradesh)",
        FacilityCity: "Jaora",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 16641,
        Pin: 396580,
        DispatchCenter: "Vansda_Chikatiya_D (Gujarat)",
        OriginCenter: "Vansda_Chikatiya_D (Gujarat)",
        FacilityCity: "Vansda",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16642,
        Pin: 523280,
        DispatchCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        OriginCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        FacilityCity: "Ongole",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16643,
        Pin: 690505,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16644,
        Pin: 796410,
        DispatchCenter: "Aizawl_Sairang_I (Mizoram)",
        OriginCenter: "Aizawl_Sairang_I (Mizoram)",
        FacilityCity: "Aizawl",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16645,
        Pin: 813223,
        DispatchCenter: "Sultanganj_Dilgouri_D (Bihar)",
        OriginCenter: "Sultanganj_Dilgouri_D (Bihar)",
        FacilityCity: "Sultanganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16646,
        Pin: 591510,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16647,
        Pin: 523281,
        DispatchCenter: "Kandukur_Gudlur_D (Andhra Pradesh)",
        OriginCenter: "Kandukur_Gudlur_D (Andhra Pradesh)",
        FacilityCity: "Kandukur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16648,
        Pin: 673304,
        DispatchCenter: "Koyilandy_Komathukara_D (Kerala)",
        OriginCenter: "Koyilandy_Komathukara_D (Kerala)",
        FacilityCity: "Koyilandy",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16649,
        Pin: 500004,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16650,
        Pin: 303326,
        DispatchCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        OriginCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        FacilityCity: "Bandikui",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16651,
        Pin: 523241,
        DispatchCenter: "Podili_Sravaniestate_D (Andhra Pradesh)",
        OriginCenter: "Podili_Sravaniestate_D (Andhra Pradesh)",
        FacilityCity: "Podili",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16652,
        Pin: 401501,
        DispatchCenter: "Palghar_Maan_L (Maharashtra)",
        OriginCenter: "Palghar_Maan_L (Maharashtra)",
        FacilityCity: "Palghar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16653,
        Pin: 456665,
        DispatchCenter: "Tarana_Ward11_D (Madhya Pradesh)",
        OriginCenter: "Tarana_Ward11_D (Madhya Pradesh)",
        FacilityCity: "Tarana",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 16654,
        Pin: 743374,
        DispatchCenter: "Kakdwip_KlngrDPP_D (West Bengal)",
        OriginCenter: "Kakdwip_KlngrDPP_D (West Bengal)",
        FacilityCity: "Kakdwip",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16655,
        Pin: 570002,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16656,
        Pin: 689574,
        DispatchCenter: "Haripad_Thalavady_D (Kerala)",
        OriginCenter: "Haripad_Thalavady_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16657,
        Pin: 396193,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16658,
        Pin: 370421,
        DispatchCenter: "Mundra_Umiyanagar_D (Gujarat)",
        OriginCenter: "Mundra_Umiyanagar_D (Gujarat)",
        FacilityCity: "Mundra",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16659,
        Pin: 641049,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16660,
        Pin: 122203,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16661,
        Pin: 522661,
        DispatchCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasaraopet",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16662,
        Pin: 713130,
        DispatchCenter: "Katwa_Panchghara_D (West Bengal)",
        OriginCenter: "Katwa_Panchghara_D (West Bengal)",
        FacilityCity: "Katwa",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16663,
        Pin: 533430,
        DispatchCenter: "Yerravaram_Somavaram_D (Andhra Pradesh)",
        OriginCenter: "Yerravaram_Somavaram_D (Andhra Pradesh)",
        FacilityCity: "Yerravaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16664,
        Pin: 182320,
        DispatchCenter: "Katra_Central_D_1 (Jammu & Kashmir)",
        OriginCenter: "Katra_Central_D_1 (Jammu & Kashmir)",
        FacilityCity: "Katra",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16665,
        Pin: 281501,
        DispatchCenter: "Mathura_Palikheda_D (Uttar Pradesh)",
        OriginCenter: "Mathura_Palikheda_D (Uttar Pradesh)",
        FacilityCity: "Mathura",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16666,
        Pin: 444708,
        DispatchCenter: "Yavatmal_Ner_D (Maharashtra)",
        OriginCenter: "Yavatmal_Ner_D (Maharashtra)",
        FacilityCity: "Yavatmal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16667,
        Pin: 496336,
        DispatchCenter: "Kusmi_Kanjiya_D (Chhattisgarh)",
        OriginCenter: "Kusmi_Kanjiya_D (Chhattisgarh)",
        FacilityCity: "Kusmi",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16668,
        Pin: 676104,
        DispatchCenter: "Tirur_Talakkad_D (Kerala)",
        OriginCenter: "Tirur_Talakkad_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16669,
        Pin: 600041,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16670,
        Pin: 574101,
        DispatchCenter: "Karkala_JodursteJ_D (Karnataka)",
        OriginCenter: "Karkala_JodursteJ_D (Karnataka)",
        FacilityCity: "Karkala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16671,
        Pin: 758084,
        DispatchCenter: "Pallahara_Patabeda_D (Orissa)",
        OriginCenter: "Pallahara_Patabeda_D (Orissa)",
        FacilityCity: "Pallahara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16672,
        Pin: 394170,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16673,
        Pin: 176219,
        DispatchCenter: "Dharamshala_GrudwrRd_D (Himachal Pradesh)",
        OriginCenter: "Dharamshala_GrudwrRd_D (Himachal Pradesh)",
        FacilityCity: "Dharamshala",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16674,
        Pin: 182104,
        DispatchCenter: "Udhampur_BharatNagar_D (Jammu & Kashmir)",
        OriginCenter: "Udhampur_BharatNagar_D (Jammu & Kashmir)",
        FacilityCity: "Udhampur",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16675,
        Pin: 786189,
        DispatchCenter: "Chabua_CollgeRD_D (Assam)",
        OriginCenter: "Chabua_CollgeRD_D (Assam)",
        FacilityCity: "Chabua",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16676,
        Pin: 741255,
        DispatchCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        OriginCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        FacilityCity: "Ranaghat",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16677,
        Pin: 691528,
        DispatchCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        OriginCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16678,
        Pin: 473111,
        DispatchCenter: "Guna_Sadacolony_D (Madhya Pradesh)",
        OriginCenter: "Guna_Sadacolony_D (Madhya Pradesh)",
        FacilityCity: "Guna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 16679,
        Pin: 383340,
        DispatchCenter: "Bayad_PwnPlza_D (Gujarat)",
        OriginCenter: "Bayad_PwnPlza_D (Gujarat)",
        FacilityCity: "Bayad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16680,
        Pin: 413004,
        DispatchCenter: "Solapur_Balepeth_I (Maharashtra)",
        OriginCenter: "Solapur_Balepeth_I (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16681,
        Pin: 732213,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16682,
        Pin: 313328,
        DispatchCenter: "Fatehnagar_Railmagra_DPP (Rajasthan)",
        OriginCenter: "Fatehnagar_Railmagra_DPP (Rajasthan)",
        FacilityCity: "Fatehnagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16683,
        Pin: 518308,
        DispatchCenter: "Adoni_Mandagiri_D (Andhra Pradesh)",
        OriginCenter: "Adoni_Mandagiri_D (Andhra Pradesh)",
        FacilityCity: "Adoni",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16684,
        Pin: 695130,
        DispatchCenter: "Kallikkad_Mutyvila_D (Kerala)",
        OriginCenter: "Kallikkad_Mutyvila_D (Kerala)",
        FacilityCity: "Kallikkad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16685,
        Pin: 314011,
        DispatchCenter: "Dungarpur_IrolZoneCmplx_DPP (Rajasthan)",
        OriginCenter: "Dungarpur_IrolZoneCmplx_DPP (Rajasthan)",
        FacilityCity: "Dungarpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16686,
        Pin: 711314,
        DispatchCenter: "Bagnan_Harop_D (West Bengal)",
        OriginCenter: "Bagnan_Harop_D (West Bengal)",
        FacilityCity: "Bagnan",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16687,
        Pin: 124411,
        DispatchCenter: "Rohtak_IndustrialArea_D (Haryana)",
        OriginCenter: "Rohtak_IndustrialArea_D (Haryana)",
        FacilityCity: "Rohtak",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16688,
        Pin: 441901,
        DispatchCenter: "SadakArjuni_Central_D (Maharashtra)",
        OriginCenter: "SadakArjuni_Central_D (Maharashtra)",
        FacilityCity: "Sadak Arjuni",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16689,
        Pin: 246722,
        DispatchCenter: "Dhampur_Jaitra_D (Uttar Pradesh)",
        OriginCenter: "Dhampur_Jaitra_D (Uttar Pradesh)",
        FacilityCity: "Dhampur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16690,
        Pin: 505468,
        DispatchCenter: "Huzurabad_Vivekanandanagar_D (Telangana)",
        OriginCenter: "Huzurabad_Vivekanandanagar_D (Telangana)",
        FacilityCity: "Huzurabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16691,
        Pin: 231303,
        DispatchCenter: "Mirzapur_Lalganj_D (Uttar Pradesh)",
        OriginCenter: "Mirzapur_Lalganj_D (Uttar Pradesh)",
        FacilityCity: "Mirzapur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16692,
        Pin: 132105,
        DispatchCenter: "Panipat_Risalu_P (Haryana)",
        OriginCenter: "Panipat_Risalu_P (Haryana)",
        FacilityCity: "Panipat",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16693,
        Pin: 521122,
        DispatchCenter: "Avanigadda_Ramnagar_D (Andhra Pradesh)",
        OriginCenter: "Avanigadda_Ramnagar_D (Andhra Pradesh)",
        FacilityCity: "Avanigadda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16694,
        Pin: 176083,
        DispatchCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        OriginCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        FacilityCity: "Palampur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16695,
        Pin: 587119,
        DispatchCenter: "Jamkhandi_Laxmingr_D (Karnataka)",
        OriginCenter: "Jamkhandi_Laxmingr_D (Karnataka)",
        FacilityCity: "Jamkhandi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16696,
        Pin: 110045,
        DispatchCenter: "Delhi_BamnoliVillage_L (Delhi)",
        OriginCenter: "Delhi_BamnoliVillage_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16697,
        Pin: 473113,
        DispatchCenter: "Guna_Shahpur_H (Madhya Pradesh)",
        OriginCenter: "Guna_Shahpur_H (Madhya Pradesh)",
        FacilityCity: "Guna",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 16698,
        Pin: 765033,
        DispatchCenter: "Gunupur_GnprMkt_DPP (Orissa)",
        OriginCenter: "Gunupur_GnprMkt_DPP (Orissa)",
        FacilityCity: "Gunupur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16699,
        Pin: 180020,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16700,
        Pin: 641664,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16701,
        Pin: 600067,
        DispatchCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        OriginCenter: "Chennai_Palavoyal_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16702,
        Pin: 122225,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16703,
        Pin: 630559,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16704,
        Pin: 244304,
        DispatchCenter: "Sambhal_DurgaColny_D (Uttar Pradesh)",
        OriginCenter: "Sambhal_DurgaColny_D (Uttar Pradesh)",
        FacilityCity: "Sambhal",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16705,
        Pin: 784145,
        DispatchCenter: "Mangaldoi_Patharughat_D (Assam)",
        OriginCenter: "Mangaldoi_Patharughat_D (Assam)",
        FacilityCity: "Mangaldoi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16706,
        Pin: 160025,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16707,
        Pin: 574226,
        DispatchCenter: "Moodbidri_Central_D (Karnataka)",
        OriginCenter: "Moodbidri_Central_D (Karnataka)",
        FacilityCity: "Moodabidri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16708,
        Pin: 502314,
        DispatchCenter: "Jogipet_Andole_D (Telangana)",
        OriginCenter: "Jogipet_Andole_D (Telangana)",
        FacilityCity: "Jogipet",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16709,
        Pin: 764039,
        DispatchCenter: "Semiliguda_Pottangi_DPP (Orissa)",
        OriginCenter: "Semiliguda_Pottangi_DPP (Orissa)",
        FacilityCity: "Semiliguda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16710,
        Pin: 691590,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16711,
        Pin: 506370,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16712,
        Pin: 211004,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16713,
        Pin: 574217,
        DispatchCenter: "Belthangady_UjreMnRd_DPP (Karnataka)",
        OriginCenter: "Belthangady_UjreMnRd_DPP (Karnataka)",
        FacilityCity: "Belthangady",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16714,
        Pin: 574213,
        DispatchCenter: "Moodbidri_Central_D (Karnataka)",
        OriginCenter: "Moodbidri_Central_D (Karnataka)",
        FacilityCity: "Moodabidri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16715,
        Pin: 177006,
        DispatchCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        OriginCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        FacilityCity: "Bangana",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16716,
        Pin: 496330,
        DispatchCenter: "Jashpur_PuraniToli_D (Chhattisgarh)",
        OriginCenter: "Jashpur_PuraniToli_D (Chhattisgarh)",
        FacilityCity: "Jashpur Nagar",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16717,
        Pin: 518563,
        DispatchCenter: "Sirvella_Sirivelmitta_DPP (Andhra Pradesh)",
        OriginCenter: "Sirvella_Sirivelmitta_DPP (Andhra Pradesh)",
        FacilityCity: "Sirvella",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16718,
        Pin: 331411,
        DispatchCenter: "Sardarshahr_IndustrialArea_L (Rajasthan)",
        OriginCenter: "Sardarshahr_IndustrialArea_L (Rajasthan)",
        FacilityCity: "Sardarshahr",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16719,
        Pin: 302016,
        DispatchCenter: "Jaipur_VishwakarmaIndArea_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16720,
        Pin: 221409,
        DispatchCenter: "Bhadohi_Chedibeer_D (Uttar Pradesh)",
        OriginCenter: "Bhadohi_Chedibeer_D (Uttar Pradesh)",
        FacilityCity: "Bhadohi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16721,
        Pin: 110081,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16722,
        Pin: 632055,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16723,
        Pin: 713347,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16724,
        Pin: 827004,
        DispatchCenter: "Bokaro_Chas_D (Jharkhand)",
        OriginCenter: "Bokaro_Chas_D (Jharkhand)",
        FacilityCity: "Bokaro",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16725,
        Pin: 841202,
        DispatchCenter: "Dighwara_AmbikaSchl_D (Bihar)",
        OriginCenter: "Dighwara_AmbikaSchl_D (Bihar)",
        FacilityCity: "Dighwara",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16726,
        Pin: 686541,
        DispatchCenter: "Karukachal_Nedumkunam_D (Kerala)",
        OriginCenter: "Karukachal_Nedumkunam_D (Kerala)",
        FacilityCity: "Karukachal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16727,
        Pin: 226401,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16728,
        Pin: 670592,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16729,
        Pin: 695018,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16730,
        Pin: 342802,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16731,
        Pin: 508105,
        DispatchCenter: "Alair_Reddygudem_D (Telangana)",
        OriginCenter: "Alair_Reddygudem_D (Telangana)",
        FacilityCity: "Alair",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16732,
        Pin: 700131,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16733,
        Pin: 123505,
        DispatchCenter: "Gurgaon_Farukhnagar_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16734,
        Pin: 508355,
        DispatchCenter: "Miryalguda_Ragvendr_D (Telangana)",
        OriginCenter: "Miryalguda_Ragvendr_D (Telangana)",
        FacilityCity: "Miryalaguda",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16735,
        Pin: 388630,
        DispatchCenter: "Khambhat_MotvdDPP_D (Gujarat)",
        OriginCenter: "Khambhat_MotvdDPP_D (Gujarat)",
        FacilityCity: "Khambhat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16736,
        Pin: 411023,
        DispatchCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        OriginCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16737,
        Pin: 483501,
        DispatchCenter: "Katni_ShantiNagar_D (Madhya Pradesh)",
        OriginCenter: "Katni_ShantiNagar_D (Madhya Pradesh)",
        FacilityCity: "Katni",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 16738,
        Pin: 732144,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16739,
        Pin: 370475,
        DispatchCenter: "Mandvi_Ashpura_DPP (Gujarat)",
        OriginCenter: "Mandvi_Ashpura_DPP (Gujarat)",
        FacilityCity: "Mandvi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16740,
        Pin: 606752,
        DispatchCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        OriginCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        FacilityCity: "Tiruvannamalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16741,
        Pin: 332317,
        DispatchCenter: "Laxmangarh_Wrd19DPP_D (Rajasthan)",
        OriginCenter: "Laxmangarh_Wrd19DPP_D (Rajasthan)",
        FacilityCity: "Laxmangarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16742,
        Pin: 484330,
        DispatchCenter: "Anuppur_Chetnanagar_D (Madhya Pradesh)",
        OriginCenter: "Anuppur_Chetnanagar_D (Madhya Pradesh)",
        FacilityCity: "Anuppur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 16743,
        Pin: 534315,
        DispatchCenter: "Polavaram_Collageroad_DPP (Andhra Pradesh)",
        OriginCenter: "Polavaram_Collageroad_DPP (Andhra Pradesh)",
        FacilityCity: "Polavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16744,
        Pin: 671531,
        DispatchCenter: "Kanhangad_Ajanur_D (Kerala)",
        OriginCenter: "Kanhangad_Ajanur_D (Kerala)",
        FacilityCity: "Kasaragod",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16745,
        Pin: 722142,
        DispatchCenter: "Barjora_PoliceStn_DPP (West Bengal)",
        OriginCenter: "Barjora_PoliceStn_DPP (West Bengal)",
        FacilityCity: "Barjora",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16746,
        Pin: 191113,
        DispatchCenter: "Srinagar_Ladhoo_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Ladhoo_H (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16747,
        Pin: 590002,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16748,
        Pin: 570022,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16749,
        Pin: 400094,
        DispatchCenter: "Mumbai_Chembur_L (Maharashtra)",
        OriginCenter: "Mumbai_Chembur_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16750,
        Pin: 802135,
        DispatchCenter: "Buxar_Ashaparari_DPP (Bihar)",
        OriginCenter: "Buxar_Ashaparari_DPP (Bihar)",
        FacilityCity: "Buxar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16751,
        Pin: 620004,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16752,
        Pin: 584118,
        DispatchCenter: "Raichur_Wadloor_I (Karnataka)",
        OriginCenter: "Raichur_Wadloor_I (Karnataka)",
        FacilityCity: "Raichur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16753,
        Pin: 384245,
        DispatchCenter: "Sami_CityLgtComplx_DPP (Gujarat)",
        OriginCenter: "Sami_CityLgtComplx_DPP (Gujarat)",
        FacilityCity: "Sami",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16754,
        Pin: 306308,
        DispatchCenter: "Bilara_Jaitaran_DPP (Rajasthan)",
        OriginCenter: "Bilara_Jaitaran_DPP (Rajasthan)",
        FacilityCity: "Bilara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16755,
        Pin: 171223,
        DispatchCenter: "Rohru_Samala_D (Himachal Pradesh)",
        OriginCenter: "Rohru_Samala_D (Himachal Pradesh)",
        FacilityCity: "Rohru",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16756,
        Pin: 643201,
        DispatchCenter: "Kotagiri_CroslySRT_D (Tamil Nadu)",
        OriginCenter: "Kotagiri_CroslySRT_D (Tamil Nadu)",
        FacilityCity: "Kotagiri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16757,
        Pin: 416404,
        DispatchCenter: "Jath_Rajaramnagar_D (Maharashtra)",
        OriginCenter: "Jath_Rajaramnagar_D (Maharashtra)",
        FacilityCity: "Jath",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16758,
        Pin: 125201,
        DispatchCenter: "Sirsa_Kaliawaliroad_D (Haryana)",
        OriginCenter: "Sirsa_Kaliawaliroad_D (Haryana)",
        FacilityCity: "Sirsa",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16759,
        Pin: 761209,
        DispatchCenter: "Chikiti_Bapujeenagar_DPP (Orissa)",
        OriginCenter: "Chikiti_Bapujeenagar_DPP (Orissa)",
        FacilityCity: "Chikiti",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16760,
        Pin: 380013,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16761,
        Pin: 743446,
        DispatchCenter: "Hingalganj_Nebukhali_DPP (West Bengal)",
        OriginCenter: "Hingalganj_Nebukhali_DPP (West Bengal)",
        FacilityCity: "Hingalganj",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16762,
        Pin: 124201,
        DispatchCenter: "Jhajjar_Policeline_I (Haryana)",
        OriginCenter: "Jhajjar_Policeline_I (Haryana)",
        FacilityCity: "Jhajjar",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16763,
        Pin: 400069,
        DispatchCenter: "Mumbai_Kurla_L (Maharashtra)",
        OriginCenter: "Mumbai_Kurla_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16764,
        Pin: 587111,
        DispatchCenter: "Bagalkot_NavaNgr_D (Karnataka)",
        OriginCenter: "Bagalkot_NavaNgr_D (Karnataka)",
        FacilityCity: "Bagalkot",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16765,
        Pin: 691510,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16766,
        Pin: 147006,
        DispatchCenter: "Patiala_UpkarNagar_D (Punjab)",
        OriginCenter: "Patiala_UpkarNagar_D (Punjab)",
        FacilityCity: "Patiala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16767,
        Pin: 422606,
        DispatchCenter: "Sinnar_Pimpale_D (Maharashtra)",
        OriginCenter: "Sinnar_Pimpale_D (Maharashtra)",
        FacilityCity: "Sinnar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16768,
        Pin: 600088,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16769,
        Pin: 614016,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16770,
        Pin: 313004,
        DispatchCenter: "Udaipur_Balicha_H (Rajasthan)",
        OriginCenter: "Udaipur_Balicha_H (Rajasthan)",
        FacilityCity: "Udaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16771,
        Pin: 587301,
        DispatchCenter: "Jamkhandi_Laxmingr_D (Karnataka)",
        OriginCenter: "Jamkhandi_Laxmingr_D (Karnataka)",
        FacilityCity: "Jamkhandi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16772,
        Pin: 577004,
        DispatchCenter: "Davangere_Industrialarea_I (Karnataka)",
        OriginCenter: "Davangere_Industrialarea_I (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16773,
        Pin: 246179,
        DispatchCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        OriginCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        FacilityCity: "Gumkhal",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16774,
        Pin: 700102,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16775,
        Pin: 627417,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16776,
        Pin: 623534,
        DispatchCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        OriginCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        FacilityCity: "Rameswaram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16777,
        Pin: 580003,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16778,
        Pin: 342304,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16779,
        Pin: 411067,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16780,
        Pin: 301424,
        DispatchCenter: "Bhiwadi_Central_D_2 (Rajasthan)",
        OriginCenter: "Bhiwadi_Central_D_2 (Rajasthan)",
        FacilityCity: "Bhiwadi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16781,
        Pin: 678595,
        DispatchCenter: "Karimba_Kaliyode_D (Kerala)",
        OriginCenter: "Karimba_Kaliyode_D (Kerala)",
        FacilityCity: "Karimba",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16782,
        Pin: 678633,
        DispatchCenter: "Akaloor_Lakdi_DPP (Kerala)",
        OriginCenter: "Akaloor_Lakdi_DPP (Kerala)",
        FacilityCity: "Akaloor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16783,
        Pin: 560055,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16784,
        Pin: 224186,
        DispatchCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        OriginCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        FacilityCity: "Jalalpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16785,
        Pin: 721426,
        DispatchCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        OriginCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        FacilityCity: "Dantan",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16786,
        Pin: 756139,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16787,
        Pin: 384270,
        DispatchCenter: "Unjha_SatymComplx_D (Gujarat)",
        OriginCenter: "Unjha_SatymComplx_D (Gujarat)",
        FacilityCity: "Unjha",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16788,
        Pin: 144524,
        DispatchCenter: "Garhshankar_BangaRoad_D (Punjab)",
        OriginCenter: "Garhshankar_BangaRoad_D (Punjab)",
        FacilityCity: "Garhshanker",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16789,
        Pin: 246482,
        DispatchCenter: "Tharali_Kulsari_D (Uttarakhand)",
        OriginCenter: "Tharali_Kulsari_D (Uttarakhand)",
        FacilityCity: "Tharali",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16790,
        Pin: 322232,
        DispatchCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        OriginCenter: "Karauli_Krishnacolony_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16791,
        Pin: 625013,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16792,
        Pin: 441808,
        DispatchCenter: "Sakoli_GaneshWard_D (Maharashtra)",
        OriginCenter: "Sakoli_GaneshWard_D (Maharashtra)",
        FacilityCity: "Sakoli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16793,
        Pin: 843330,
        DispatchCenter: "Sonbarsa_Sitamarhi_D (Bihar)",
        OriginCenter: "Sonbarsa_Sitamarhi_D (Bihar)",
        FacilityCity: "Sonbarsa",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16794,
        Pin: 450017,
        DispatchCenter: "Khandwa_TilakNagar_D (Madhya Pradesh)",
        OriginCenter: "Khandwa_TilakNagar_D (Madhya Pradesh)",
        FacilityCity: "Khandwa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 16795,
        Pin: 767019,
        DispatchCenter: "Binka_Sindurpur_DPP (Orissa)",
        OriginCenter: "Binka_Sindurpur_DPP (Orissa)",
        FacilityCity: "Binka",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16796,
        Pin: 743292,
        DispatchCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        OriginCenter: "Basirhat_MaitrDPP_D (West Bengal)",
        FacilityCity: "Basirhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16797,
        Pin: 721315,
        DispatchCenter: "Kharagpur_Nimpura_I (West Bengal)",
        OriginCenter: "Kharagpur_Nimpura_I (West Bengal)",
        FacilityCity: "Kharagpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16798,
        Pin: 461111,
        DispatchCenter: "Itarsi_AwnNgr_D (Madhya Pradesh)",
        OriginCenter: "Itarsi_AwnNgr_D (Madhya Pradesh)",
        FacilityCity: "Itarsi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 16799,
        Pin: 637101,
        DispatchCenter: "Sankari_Idappadi_D (Tamil Nadu)",
        OriginCenter: "Sankari_Idappadi_D (Tamil Nadu)",
        FacilityCity: "Sankari",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16800,
        Pin: 691324,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16801,
        Pin: 722133,
        DispatchCenter: "Bankura_KeranDPP_D (West Bengal)",
        OriginCenter: "Bankura_KeranDPP_D (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16802,
        Pin: 148018,
        DispatchCenter: "Nabha_Jaspalcolony_D (Punjab)",
        OriginCenter: "Nabha_Jaspalcolony_D (Punjab)",
        FacilityCity: "Nabha",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16803,
        Pin: 577534,
        DispatchCenter: "Davangere_Industrialarea_I (Karnataka)",
        OriginCenter: "Davangere_Industrialarea_I (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16804,
        Pin: 177020,
        DispatchCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        OriginCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        FacilityCity: "Hamirpur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16805,
        Pin: 364295,
        DispatchCenter: "Mahuva_Aambavadi_DPP (Gujarat)",
        OriginCenter: "Mahuva_Aambavadi_DPP (Gujarat)",
        FacilityCity: "Mahuva",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16806,
        Pin: 621016,
        DispatchCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        OriginCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        FacilityCity: "Thuraiyur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16807,
        Pin: 524307,
        DispatchCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        OriginCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        FacilityCity: "Atmakur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16808,
        Pin: 577546,
        DispatchCenter: "Amarapuram_YSRCircle_D (Andhra Pradesh)",
        OriginCenter: "Amarapuram_YSRCircle_D (Andhra Pradesh)",
        FacilityCity: "Amarapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16809,
        Pin: 465677,
        DispatchCenter: "Maksudangarh_Rajivnagar_D (Madhya Pradesh)",
        OriginCenter: "Maksudangarh_Rajivnagar_D (Madhya Pradesh)",
        FacilityCity: "Maksudangarh",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 16810,
        Pin: 171215,
        DispatchCenter: "Jubbal_Jhaldi_D (Himachal Pradesh)",
        OriginCenter: "Jubbal_Jhaldi_D (Himachal Pradesh)",
        FacilityCity: "Jubbal",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16811,
        Pin: 686653,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16812,
        Pin: 673572,
        DispatchCenter: "Narikkuni_Padanilam_D (Kerala)",
        OriginCenter: "Narikkuni_Padanilam_D (Kerala)",
        FacilityCity: "Narikkuni",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16813,
        Pin: 423212,
        DispatchCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        OriginCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        FacilityCity: "Malegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16814,
        Pin: 689723,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16815,
        Pin: 486333,
        DispatchCenter: "Deotalab_Mauganj_D (Madhya Pradesh)",
        OriginCenter: "Deotalab_Mauganj_D (Madhya Pradesh)",
        FacilityCity: "Deotalab",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 16816,
        Pin: 391410,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16817,
        Pin: 783130,
        DispatchCenter: "Boko_Dhupdhara_D (Assam)",
        OriginCenter: "Boko_Dhupdhara_D (Assam)",
        FacilityCity: "Boko",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16818,
        Pin: 560114,
        DispatchCenter: "Bangalore_Temp1_L (Karnataka)",
        OriginCenter: "Bangalore_Temp1_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16819,
        Pin: 141017,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16820,
        Pin: 140114,
        DispatchCenter: "AnandpurSahib_Dher_D (Punjab)",
        OriginCenter: "AnandpurSahib_Dher_D (Punjab)",
        FacilityCity: "Anandpur Sahib",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16821,
        Pin: 516311,
        DispatchCenter: "Cuddapah_Peddacheppalle_D (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Peddacheppalle_D (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16822,
        Pin: 670672,
        DispatchCenter: "Mahe_Chokli_D (Kerala)",
        OriginCenter: "Mahe_Chokli_D (Kerala)",
        FacilityCity: "Mahe",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16823,
        Pin: 518007,
        DispatchCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        OriginCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16824,
        Pin: 743368,
        DispatchCenter: "Fatepur_Majlishpur_D (West Bengal)",
        OriginCenter: "Fatepur_Majlishpur_D (West Bengal)",
        FacilityCity: "Fatepur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16825,
        Pin: 613402,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16826,
        Pin: 442101,
        DispatchCenter: "Pulgaon_RajPP_DPP (Maharashtra)",
        OriginCenter: "Pulgaon_RajPP_DPP (Maharashtra)",
        FacilityCity: "Pulgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16827,
        Pin: 572222,
        DispatchCenter: "Tumkur_Doddapete_DPP (Karnataka)",
        OriginCenter: "Tumkur_Doddapete_DPP (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16828,
        Pin: 414403,
        DispatchCenter: "Karjat_Gaykarwadi_D (Maharashtra)",
        OriginCenter: "Karjat_Gaykarwadi_D (Maharashtra)",
        FacilityCity: "Karjat-MH",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16829,
        Pin: 212659,
        DispatchCenter: "Ghatampur_StatinRD_D (Uttar Pradesh)",
        OriginCenter: "Ghatampur_StatinRD_D (Uttar Pradesh)",
        FacilityCity: "Ghatampur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16830,
        Pin: 781330,
        DispatchCenter: "Tihu_Jalahgaon_D (Assam)",
        OriginCenter: "Tihu_Jalahgaon_D (Assam)",
        FacilityCity: "Tihu",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16831,
        Pin: 388580,
        DispatchCenter: "Vatadara_Banejda_DPP (Gujarat)",
        OriginCenter: "Vatadara_Banejda_DPP (Gujarat)",
        FacilityCity: "Vatadara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16832,
        Pin: 851119,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16833,
        Pin: 584114,
        DispatchCenter: "Ilkal_Ward4_D (Karnataka)",
        OriginCenter: "Ilkal_Ward4_D (Karnataka)",
        FacilityCity: "Ilkal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16834,
        Pin: 431127,
        DispatchCenter: "Georai_Jayakwadicolony_D (Maharashtra)",
        OriginCenter: "Georai_Jayakwadicolony_D (Maharashtra)",
        FacilityCity: "Georai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16835,
        Pin: 500033,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16836,
        Pin: 277203,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16837,
        Pin: 761214,
        DispatchCenter: "Rayagada_MajhiStreet_DPP (Orissa)",
        OriginCenter: "Rayagada_MajhiStreet_DPP (Orissa)",
        FacilityCity: "Rayagada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16838,
        Pin: 400113,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16839,
        Pin: 641607,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16840,
        Pin: 691019,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16841,
        Pin: 800001,
        DispatchCenter: "Patna_Chhotipahari_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16842,
        Pin: 851215,
        DispatchCenter: "Naugachia_Mahaddipur_DPP (Bihar)",
        OriginCenter: "Naugachia_Mahaddipur_DPP (Bihar)",
        FacilityCity: "Naugachia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16843,
        Pin: 713208,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16844,
        Pin: 572212,
        DispatchCenter: "Turuvukere_Havala_D (Karnataka)",
        OriginCenter: "Turuvukere_Havala_D (Karnataka)",
        FacilityCity: "Turuvukere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16845,
        Pin: 680613,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16846,
        Pin: 678552,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16847,
        Pin: 516484,
        DispatchCenter: "Pulivendula_Bakarapuram_D (Andhra Pradesh)",
        OriginCenter: "Pulivendula_Bakarapuram_D (Andhra Pradesh)",
        FacilityCity: "Pulivendula",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16848,
        Pin: 424205,
        DispatchCenter: "Jamner_BusStd_D (Maharashtra)",
        OriginCenter: "Jamner_BusStd_D (Maharashtra)",
        FacilityCity: "Jamner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16849,
        Pin: 585243,
        DispatchCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        OriginCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        FacilityCity: "Gulbarga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16850,
        Pin: 123021,
        DispatchCenter: "Narnaul_Keshavnagar_D (Haryana)",
        OriginCenter: "Narnaul_Keshavnagar_D (Haryana)",
        FacilityCity: "Narnaul",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16851,
        Pin: 141419,
        DispatchCenter: "Rarasahib_Katahri_D (Punjab)",
        OriginCenter: "Rarasahib_Katahri_D (Punjab)",
        FacilityCity: "Malerkotla",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16852,
        Pin: 226009,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16853,
        Pin: 670612,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16854,
        Pin: 242406,
        DispatchCenter: "Shahjahanpur_AvasVikas_D (Uttar Pradesh)",
        OriginCenter: "Shahjahanpur_AvasVikas_D (Uttar Pradesh)",
        FacilityCity: "Shahjahanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16855,
        Pin: 757002,
        DispatchCenter: "Baripada_KalikDPP_D (Orissa)",
        OriginCenter: "Baripada_KalikDPP_D (Orissa)",
        FacilityCity: "Baripada",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16856,
        Pin: 177101,
        DispatchCenter: "DehraGopipur_Central_D_1 (Himachal Pradesh)",
        OriginCenter: "DehraGopipur_Central_D_1 (Himachal Pradesh)",
        FacilityCity: "Dehra Gopipur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16857,
        Pin: 583128,
        DispatchCenter: "Kudligi_Chikkajogihalli_D (Karnataka)",
        OriginCenter: "Kudligi_Chikkajogihalli_D (Karnataka)",
        FacilityCity: "Kudligi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16858,
        Pin: 415003,
        DispatchCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        OriginCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        FacilityCity: "Satara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16859,
        Pin: 782402,
        DispatchCenter: "Jorabat_Japesiagn_D (Assam)",
        OriginCenter: "Jorabat_Japesiagn_D (Assam)",
        FacilityCity: "Jorabat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16860,
        Pin: 160024,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16861,
        Pin: 605010,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16862,
        Pin: 421301,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16863,
        Pin: 638668,
        DispatchCenter: "Annur_Kattabommannagar_D (Tamil Nadu)",
        OriginCenter: "Annur_Kattabommannagar_D (Tamil Nadu)",
        FacilityCity: "Annur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16864,
        Pin: 825335,
        DispatchCenter: "Hazaribagh_Matwari_D (Jharkhand)",
        OriginCenter: "Hazaribagh_Matwari_D (Jharkhand)",
        FacilityCity: "hazaribagh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16865,
        Pin: 583218,
        DispatchCenter: "Kudligi_Chikkajogihalli_D (Karnataka)",
        OriginCenter: "Kudligi_Chikkajogihalli_D (Karnataka)",
        FacilityCity: "Kudligi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16866,
        Pin: 574244,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16867,
        Pin: 222133,
        DispatchCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        OriginCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        FacilityCity: "Jaunpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16868,
        Pin: 833212,
        DispatchCenter: "Jagannathpur_Hatgamhariya_D (Jharkhand)",
        OriginCenter: "Jagannathpur_Hatgamhariya_D (Jharkhand)",
        FacilityCity: "Jagannathpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16869,
        Pin: 160016,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16870,
        Pin: 641697,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16871,
        Pin: 201312,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16872,
        Pin: 627808,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16873,
        Pin: 609503,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16874,
        Pin: 455118,
        DispatchCenter: "Dewas_Rolupipalya_DPP (Madhya Pradesh)",
        OriginCenter: "Dewas_Rolupipalya_DPP (Madhya Pradesh)",
        FacilityCity: "Dewas",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 16875,
        Pin: 605604,
        DispatchCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        OriginCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        FacilityCity: "Viluppuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16876,
        Pin: 680302,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16877,
        Pin: 396192,
        DispatchCenter: "SilvassaDNH_AmliEstate2_D (Dadra and Nagar Haveli)",
        OriginCenter: "SilvassaDNH_AmliEstate2_D (Dadra and Nagar Haveli)",
        FacilityCity: "Silvassa-DNH",
        FacilityState: "Dadra and Nagar Haveli",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16878,
        Pin: 843112,
        DispatchCenter: "Saraiya_Saraiya_D (Bihar)",
        OriginCenter: "Saraiya_Saraiya_D (Bihar)",
        FacilityCity: "Saraiya",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16879,
        Pin: 321305,
        DispatchCenter: "Bharatpur_DurgaNagar_D (Rajasthan)",
        OriginCenter: "Bharatpur_DurgaNagar_D (Rajasthan)",
        FacilityCity: "Bharatpur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16880,
        Pin: 712101,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16881,
        Pin: 712147,
        DispatchCenter: "Pandua_NvplyDPP_D (West Bengal)",
        OriginCenter: "Pandua_NvplyDPP_D (West Bengal)",
        FacilityCity: "Pandua",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16882,
        Pin: 176103,
        DispatchCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        OriginCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        FacilityCity: "Palampur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16883,
        Pin: 695039,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16884,
        Pin: 506224,
        DispatchCenter: "Narmetta_Maddur_DPP (Telangana)",
        OriginCenter: "Narmetta_Maddur_DPP (Telangana)",
        FacilityCity: "Narmetta",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16885,
        Pin: 229202,
        DispatchCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        OriginCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        FacilityCity: "Unnao",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16886,
        Pin: 248196,
        DispatchCenter: "Chakrata_Purodi_DPP (Uttarakhand)",
        OriginCenter: "Chakrata_Purodi_DPP (Uttarakhand)",
        FacilityCity: "Chakrata",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16887,
        Pin: 151007,
        DispatchCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        OriginCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        FacilityCity: "Bathinda",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16888,
        Pin: 496004,
        DispatchCenter: "Raigarh_Chhatamuda_D (Chhattisgarh)",
        OriginCenter: "Raigarh_Chhatamuda_D (Chhattisgarh)",
        FacilityCity: "Raigarh",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16889,
        Pin: 757017,
        DispatchCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        OriginCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        FacilityCity: "Betnoti",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16890,
        Pin: 621601,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16891,
        Pin: 788108,
        DispatchCenter: "Udarbond_Pangram_D (Assam)",
        OriginCenter: "Udarbond_Pangram_D (Assam)",
        FacilityCity: "Udarbond",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16892,
        Pin: 301412,
        DispatchCenter: "Bansur_TransportNagar_D (Rajasthan)",
        OriginCenter: "Bansur_TransportNagar_D (Rajasthan)",
        FacilityCity: "Bansur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16893,
        Pin: 781367,
        DispatchCenter: "Baihata_Sundhia_D (Assam)",
        OriginCenter: "Baihata_Sundhia_D (Assam)",
        FacilityCity: "Baihata",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16894,
        Pin: 781374,
        DispatchCenter: "Sarthebari_Tmlichp_D (Assam)",
        OriginCenter: "Sarthebari_Tmlichp_D (Assam)",
        FacilityCity: "Sarthebari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16895,
        Pin: 363421,
        DispatchCenter: "Limbdi_UmaiyaPark_DPP (Gujarat)",
        OriginCenter: "Limbdi_UmaiyaPark_DPP (Gujarat)",
        FacilityCity: "Limbdi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16896,
        Pin: 534004,
        DispatchCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        OriginCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        FacilityCity: "Eluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16897,
        Pin: 493228,
        DispatchCenter: "BalodaBazar_Pahanda_D (Chhattisgarh)",
        OriginCenter: "BalodaBazar_Pahanda_D (Chhattisgarh)",
        FacilityCity: "Baloda Bazar",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16898,
        Pin: 643217,
        DispatchCenter: "Kotagiri_CroslySRT_D (Tamil Nadu)",
        OriginCenter: "Kotagiri_CroslySRT_D (Tamil Nadu)",
        FacilityCity: "Kotagiri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16899,
        Pin: 230503,
        DispatchCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        OriginCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        FacilityCity: "Amethi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16900,
        Pin: 843143,
        DispatchCenter: "Saraiya_Saraiya_D (Bihar)",
        OriginCenter: "Saraiya_Saraiya_D (Bihar)",
        FacilityCity: "Saraiya",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16901,
        Pin: 509302,
        DispatchCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        OriginCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        FacilityCity: "Mahabubnagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16902,
        Pin: 842008,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16903,
        Pin: 516193,
        DispatchCenter: "Badvel_CCcolony_D (Andhra Pradesh)",
        OriginCenter: "Badvel_CCcolony_D (Andhra Pradesh)",
        FacilityCity: "Badvel",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16904,
        Pin: 233311,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16905,
        Pin: 416514,
        DispatchCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        OriginCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        FacilityCity: "Sawantwadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16906,
        Pin: 403202,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16907,
        Pin: 251314,
        DispatchCenter: "Khatauli_GTRoad_D (Uttar Pradesh)",
        OriginCenter: "Khatauli_GTRoad_D (Uttar Pradesh)",
        FacilityCity: "Khatauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16908,
        Pin: 140115,
        DispatchCenter: "AnandpurSahib_Dher_D (Punjab)",
        OriginCenter: "AnandpurSahib_Dher_D (Punjab)",
        FacilityCity: "Anandpur Sahib",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16909,
        Pin: 686581,
        DispatchCenter: "Pala_Elappunkal_D (Kerala)",
        OriginCenter: "Pala_Elappunkal_D (Kerala)",
        FacilityCity: "Pala",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16910,
        Pin: 695614,
        DispatchCenter: "Kilimanoor_AKNArcard_D (Kerala)",
        OriginCenter: "Kilimanoor_AKNArcard_D (Kerala)",
        FacilityCity: "Kilimanoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 16911,
        Pin: 754219,
        DispatchCenter: "Aul_Rasulpur_D (Orissa)",
        OriginCenter: "Aul_Rasulpur_D (Orissa)",
        FacilityCity: "Aul",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16912,
        Pin: 742181,
        DispatchCenter: "Morgram_Kantagoriya_D (West Bengal)",
        OriginCenter: "Morgram_Kantagoriya_D (West Bengal)",
        FacilityCity: "Morgram",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16913,
        Pin: 515763,
        DispatchCenter: "Kalyandurg_JayNagar_D (Andhra Pradesh)",
        OriginCenter: "Kalyandurg_JayNagar_D (Andhra Pradesh)",
        FacilityCity: "Kalyandurg",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16914,
        Pin: 560113,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16915,
        Pin: 591153,
        DispatchCenter: "Kittur_Guruwarpeth_DPP (Karnataka)",
        OriginCenter: "Kittur_Guruwarpeth_DPP (Karnataka)",
        FacilityCity: "Kittur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16916,
        Pin: 503302,
        DispatchCenter: "Yellareddy_Teachercolony_D (Telangana)",
        OriginCenter: "Yellareddy_Teachercolony_D (Telangana)",
        FacilityCity: "Yellareddy",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16917,
        Pin: 441404,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Chikhali_L (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16918,
        Pin: 122011,
        DispatchCenter: "Gurgaon_Sector52_L (Haryana)",
        OriginCenter: "Gurgaon_Sector52_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16919,
        Pin: 394235,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16920,
        Pin: 602103,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16921,
        Pin: 614620,
        DispatchCenter: "Manamelkudi_HondaShow_D (Tamil Nadu)",
        OriginCenter: "Manamelkudi_HondaShow_D (Tamil Nadu)",
        FacilityCity: "Manamelkudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16922,
        Pin: 591103,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16923,
        Pin: 221115,
        DispatchCenter: "Aunrihar_JawahrNgr_D (Uttar Pradesh)",
        OriginCenter: "Aunrihar_JawahrNgr_D (Uttar Pradesh)",
        FacilityCity: "Aunrihar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16924,
        Pin: 686505,
        DispatchCenter: "Kottayam_Mulakunnath_D (Kerala)",
        OriginCenter: "Kottayam_Mulakunnath_D (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16925,
        Pin: 606702,
        DispatchCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        OriginCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        FacilityCity: "Tiruvannamalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16926,
        Pin: 680009,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16927,
        Pin: 530051,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16928,
        Pin: 311001,
        DispatchCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        OriginCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        FacilityCity: "Bhilwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16929,
        Pin: 609310,
        DispatchCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        OriginCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        FacilityCity: "Karaikal",
        FacilityState: "Pondicherry",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16930,
        Pin: 828401,
        DispatchCenter: "Topchanchi_GomhRd_DPP (Jharkhand)",
        OriginCenter: "Topchanchi_GomhRd_DPP (Jharkhand)",
        FacilityCity: "Gomoh",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16931,
        Pin: 851111,
        DispatchCenter: "DalsinghSarai_Bachhawara_D (Bihar)",
        OriginCenter: "DalsinghSarai_Bachhawara_D (Bihar)",
        FacilityCity: "Dalsingh Sarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16932,
        Pin: 534401,
        DispatchCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        OriginCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        FacilityCity: "Eluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16933,
        Pin: 248011,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16934,
        Pin: 174405,
        DispatchCenter: "Bhota_BpassDPP_D (Himachal Pradesh)",
        OriginCenter: "Bhota_BpassDPP_D (Himachal Pradesh)",
        FacilityCity: "Bhota",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16935,
        Pin: 521123,
        DispatchCenter: "Nuzvid_NSPColony_D (Andhra Pradesh)",
        OriginCenter: "Nuzvid_NSPColony_D (Andhra Pradesh)",
        FacilityCity: "Nuzvid",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16936,
        Pin: 135002,
        DispatchCenter: "Yamunanagar_Veerngrcly_D (Haryana)",
        OriginCenter: "Yamunanagar_Veerngrcly_D (Haryana)",
        FacilityCity: "Yamuna Nagar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16937,
        Pin: 600106,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16938,
        Pin: 431801,
        DispatchCenter: "Bhokar_Sydngr_D (Maharashtra)",
        OriginCenter: "Bhokar_Sydngr_D (Maharashtra)",
        FacilityCity: "Bhokar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16939,
        Pin: 695017,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16940,
        Pin: 182148,
        DispatchCenter: "Ramban_Govindpura_D (Jammu & Kashmir)",
        OriginCenter: "Ramban_Govindpura_D (Jammu & Kashmir)",
        FacilityCity: "Ramban",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16941,
        Pin: 507136,
        DispatchCenter: "Tadvai_Akulavarighanpur_D (Telangana)",
        OriginCenter: "Tadvai_Akulavarighanpur_D (Telangana)",
        FacilityCity: "Tadvai",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16942,
        Pin: 306401,
        DispatchCenter: "Pali_Transportnagar_I (Rajasthan)",
        OriginCenter: "Pali_Transportnagar_I (Rajasthan)",
        FacilityCity: "Pali",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16943,
        Pin: 507210,
        DispatchCenter: "Khammam_Dornakal_D (Telangana)",
        OriginCenter: "Khammam_Dornakal_D (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16944,
        Pin: 207126,
        DispatchCenter: "Etah_Dineshnagar_D (Uttar Pradesh)",
        OriginCenter: "Etah_Dineshnagar_D (Uttar Pradesh)",
        FacilityCity: "Etah",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16945,
        Pin: 517645,
        DispatchCenter: "Sriklahsti_SDKNgr_D (Andhra Pradesh)",
        OriginCenter: "Sriklahsti_SDKNgr_D (Andhra Pradesh)",
        FacilityCity: "Srikalahasti",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16946,
        Pin: 609302,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16947,
        Pin: 403406,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16948,
        Pin: 670564,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16949,
        Pin: 222144,
        DispatchCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        OriginCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        FacilityCity: "Mungra Badshahpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16950,
        Pin: 133105,
        DispatchCenter: "Yamunanagar_Veerngrcly_D (Haryana)",
        OriginCenter: "Yamunanagar_Veerngrcly_D (Haryana)",
        FacilityCity: "Yamuna Nagar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16951,
        Pin: 608703,
        DispatchCenter: "Lalpet_Kattumannarkoil_D (Tamil Nadu)",
        OriginCenter: "Lalpet_Kattumannarkoil_D (Tamil Nadu)",
        FacilityCity: "Lalpet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16952,
        Pin: 623403,
        DispatchCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        OriginCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        FacilityCity: "Thiruvadanai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16953,
        Pin: 532462,
        DispatchCenter: "Palakonda_VadaiDPP_D (Andhra Pradesh)",
        OriginCenter: "Palakonda_VadaiDPP_D (Andhra Pradesh)",
        FacilityCity: "Palakonda",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16954,
        Pin: 442503,
        DispatchCenter: "Wani_GuruNGR_D (Maharashtra)",
        OriginCenter: "Wani_GuruNGR_D (Maharashtra)",
        FacilityCity: "Wani",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16955,
        Pin: 400027,
        DispatchCenter: "Mumbai_Sewri_L (Maharashtra)",
        OriginCenter: "Mumbai_Sewri_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 16956,
        Pin: 743125,
        DispatchCenter: "Naihati_Jagatdal_DPP (West Bengal)",
        OriginCenter: "Naihati_Jagatdal_DPP (West Bengal)",
        FacilityCity: "Naihati",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16957,
        Pin: 530016,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16958,
        Pin: 141113,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 16959,
        Pin: 695015,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16960,
        Pin: 431143,
        DispatchCenter: "Georai_Jayakwadicolony_D (Maharashtra)",
        OriginCenter: "Georai_Jayakwadicolony_D (Maharashtra)",
        FacilityCity: "Georai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16961,
        Pin: 700121,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16962,
        Pin: 502296,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16963,
        Pin: 824118,
        DispatchCenter: "Tekari_BaheliyaBigha_D (Bihar)",
        OriginCenter: "Tekari_BaheliyaBigha_D (Bihar)",
        FacilityCity: "Tekari",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16964,
        Pin: 623312,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16965,
        Pin: 835303,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16966,
        Pin: 224146,
        DispatchCenter: "Akbarpur_AmbedkarNgr_D (Uttar Pradesh)",
        OriginCenter: "Akbarpur_AmbedkarNgr_D (Uttar Pradesh)",
        FacilityCity: "Akbarpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 16967,
        Pin: 788012,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16968,
        Pin: 577596,
        DispatchCenter: "Hiriyur_Vedvthingr_D (Karnataka)",
        OriginCenter: "Hiriyur_Vedvthingr_D (Karnataka)",
        FacilityCity: "Hiriyur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16969,
        Pin: 110607,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16970,
        Pin: 822118,
        DispatchCenter: "Chhatarpur_Patan_D (Jharkhand)",
        OriginCenter: "Chhatarpur_Patan_D (Jharkhand)",
        FacilityCity: "Bisrampur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16971,
        Pin: 841211,
        DispatchCenter: "Chhapra_Ramnagar_I (Bihar)",
        OriginCenter: "Chhapra_Ramnagar_I (Bihar)",
        FacilityCity: "Chhapra",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16972,
        Pin: 492001,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16973,
        Pin: 577597,
        DispatchCenter: "Hosadurga_Vidyanagara_D (Karnataka)",
        OriginCenter: "Hosadurga_Vidyanagara_D (Karnataka)",
        FacilityCity: "Hosadurga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16974,
        Pin: 574139,
        DispatchCenter: "Mudarangady_Santhoor_DPP (Karnataka)",
        OriginCenter: "Mudarangady_Santhoor_DPP (Karnataka)",
        FacilityCity: "Mudarangady",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16975,
        Pin: 249304,
        DispatchCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        OriginCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        FacilityCity: "Rishikesh",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16976,
        Pin: 414603,
        DispatchCenter: "Newasa_Khurd_D (Maharashtra)",
        OriginCenter: "Newasa_Khurd_D (Maharashtra)",
        FacilityCity: "Newasa",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16977,
        Pin: 535101,
        DispatchCenter: "Garividi_SriRamNagar_D (Andhra Pradesh)",
        OriginCenter: "Garividi_SriRamNagar_D (Andhra Pradesh)",
        FacilityCity: "Garividi",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16978,
        Pin: 141016,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 16979,
        Pin: 577413,
        DispatchCenter: "Shiralakoppa_Anavatti_DPP (Karnataka)",
        OriginCenter: "Shiralakoppa_Anavatti_DPP (Karnataka)",
        FacilityCity: "Shiralakoppa",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16980,
        Pin: 505212,
        DispatchCenter: "Manthani_Suraiahpalli_D (Telangana)",
        OriginCenter: "Manthani_Suraiahpalli_D (Telangana)",
        FacilityCity: "Manthani",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16981,
        Pin: 825304,
        DispatchCenter: "Hazaribagh_Matwari_D (Jharkhand)",
        OriginCenter: "Hazaribagh_Matwari_D (Jharkhand)",
        FacilityCity: "hazaribagh",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16982,
        Pin: 533008,
        DispatchCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        OriginCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        FacilityCity: "Kakinada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 16983,
        Pin: 363001,
        DispatchCenter: "Surendranagar_GIDC_D (Gujarat)",
        OriginCenter: "Surendranagar_GIDC_D (Gujarat)",
        FacilityCity: "Surendranagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 16984,
        Pin: 680305,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16985,
        Pin: 722150,
        DispatchCenter: "Khatra_Simlapal_DPP (West Bengal)",
        OriginCenter: "Khatra_Simlapal_DPP (West Bengal)",
        FacilityCity: "Khatra",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16986,
        Pin: 504306,
        DispatchCenter: "Nirmal_AdarshNgr_D (Telangana)",
        OriginCenter: "Nirmal_AdarshNgr_D (Telangana)",
        FacilityCity: "Nirmal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16987,
        Pin: 522657,
        DispatchCenter: "Vinukonda_Thimmayapalem_D (Andhra Pradesh)",
        OriginCenter: "Vinukonda_Thimmayapalem_D (Andhra Pradesh)",
        FacilityCity: "Vinukonda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 16988,
        Pin: 691525,
        DispatchCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        OriginCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 16989,
        Pin: 734405,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16990,
        Pin: 796901,
        DispatchCenter: "Siaha_NewColony_D (Mizoram)",
        OriginCenter: "Siaha_NewColony_D (Mizoram)",
        FacilityCity: "Siaha",
        FacilityState: "Mizoram",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16991,
        Pin: 122206,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 16992,
        Pin: 442004,
        DispatchCenter: "Wardha_Alodi_D (Maharashtra)",
        OriginCenter: "Wardha_Alodi_D (Maharashtra)",
        FacilityCity: "Wardha",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 16993,
        Pin: 362263,
        DispatchCenter: "Mendarda_GopalNagar_DPP (Gujarat)",
        OriginCenter: "Mendarda_GopalNagar_DPP (Gujarat)",
        FacilityCity: "Mendarda",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 16994,
        Pin: 799101,
        DispatchCenter: "AmarpurTP_Sankarpalli_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Amarpur TP",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16995,
        Pin: 757020,
        DispatchCenter: "Morada_Malihata_D (Orissa)",
        OriginCenter: "Morada_Malihata_D (Orissa)",
        FacilityCity: "Morada",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 16996,
        Pin: 743351,
        DispatchCenter: "Fatepur_Majlishpur_D (West Bengal)",
        OriginCenter: "Fatepur_Majlishpur_D (West Bengal)",
        FacilityCity: "Fatepur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 16997,
        Pin: 766028,
        DispatchCenter: "JunagarhOR_Santinagar_D (Orissa)",
        OriginCenter: "JunagarhOR_Santinagar_D (Orissa)",
        FacilityCity: "Junagarh-OR",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 16998,
        Pin: 735122,
        DispatchCenter: "Mekhliganj_WardNo7_DPP (West Bengal)",
        OriginCenter: "Mekhliganj_WardNo7_DPP (West Bengal)",
        FacilityCity: "Mekhliganj",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 16999,
        Pin: 847405,
        DispatchCenter: "Benipur_Magura_D (Bihar)",
        OriginCenter: "Benipur_Magura_D (Bihar)",
        FacilityCity: "Benipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17000,
        Pin: 441805,
        DispatchCenter: "Sakoli_GaneshWard_D (Maharashtra)",
        OriginCenter: "Sakoli_GaneshWard_D (Maharashtra)",
        FacilityCity: "Sakoli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17001,
        Pin: 506330,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17002,
        Pin: 530018,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17003,
        Pin: 685584,
        DispatchCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        OriginCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        FacilityCity: "Thodupuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17004,
        Pin: 581101,
        DispatchCenter: "Haveri_ManjunathNagar_D (Karnataka)",
        OriginCenter: "Haveri_ManjunathNagar_D (Karnataka)",
        FacilityCity: "Haveri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17005,
        Pin: 360110,
        DispatchCenter: "Dhrol_PGVCLOfc_D (Gujarat)",
        OriginCenter: "Dhrol_PGVCLOfc_D (Gujarat)",
        FacilityCity: "Dhrol",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17006,
        Pin: 673589,
        DispatchCenter: "Mananthavady_Central_D_1 (Kerala)",
        OriginCenter: "Mananthavady_Central_D_1 (Kerala)",
        FacilityCity: "Mananthavadi",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17007,
        Pin: 805126,
        DispatchCenter: "Nawada_Govindpur_D (Bihar)",
        OriginCenter: "Nawada_Govindpur_D (Bihar)",
        FacilityCity: "Nawada",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17008,
        Pin: 626002,
        DispatchCenter: "Virudhunagar_Allampatti_DPP (Tamil Nadu)",
        OriginCenter: "Virudhunagar_Allampatti_DPP (Tamil Nadu)",
        FacilityCity: "Virudhunagar",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17009,
        Pin: 636011,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17010,
        Pin: 632319,
        DispatchCenter: "Arani_PolurRd_D (Tamil Nadu)",
        OriginCenter: "Arani_PolurRd_D (Tamil Nadu)",
        FacilityCity: "Arani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17011,
        Pin: 413520,
        DispatchCenter: "Latur_KhadgoanChowk_D (Maharashtra)",
        OriginCenter: "Latur_KhadgoanChowk_D (Maharashtra)",
        FacilityCity: "Latur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17012,
        Pin: 272194,
        DispatchCenter: "Rudhauli_Mudiyar_D (Uttar Pradesh)",
        OriginCenter: "Rudhauli_Mudiyar_D (Uttar Pradesh)",
        FacilityCity: "Rudhauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17013,
        Pin: 604153,
        DispatchCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        OriginCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        FacilityCity: "Gingee",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17014,
        Pin: 507130,
        DispatchCenter: "Addateegala_Thimapuram_D (Andhra Pradesh)",
        OriginCenter: "Addateegala_Thimapuram_D (Andhra Pradesh)",
        FacilityCity: "Addateegala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17015,
        Pin: 207122,
        DispatchCenter: "Etah_Dineshnagar_D (Uttar Pradesh)",
        OriginCenter: "Etah_Dineshnagar_D (Uttar Pradesh)",
        FacilityCity: "Etah",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17016,
        Pin: 627303,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17017,
        Pin: 620024,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17018,
        Pin: 445306,
        DispatchCenter: "Ghatanji_ShivajiChowk_DPP (Maharashtra)",
        OriginCenter: "Ghatanji_ShivajiChowk_DPP (Maharashtra)",
        FacilityCity: "Ghatanji",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17019,
        Pin: 176314,
        DispatchCenter: "Chamba_Sultanpur_D (Himachal Pradesh)",
        OriginCenter: "Chamba_Sultanpur_D (Himachal Pradesh)",
        FacilityCity: "Chamba",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17020,
        Pin: 673603,
        DispatchCenter: "Areakode_Mukkam_D (Kerala)",
        OriginCenter: "Areakode_Mukkam_D (Kerala)",
        FacilityCity: "Areacode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17021,
        Pin: 486220,
        DispatchCenter: "Sohagi_PoliceThane_D (Madhya Pradesh)",
        OriginCenter: "Sohagi_PoliceThane_D (Madhya Pradesh)",
        FacilityCity: "Sohagi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17022,
        Pin: 848112,
        DispatchCenter: "Rosera_HanumanTemple_D (Bihar)",
        OriginCenter: "Rosera_HanumanTemple_D (Bihar)",
        FacilityCity: "Rusera",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17023,
        Pin: 151201,
        DispatchCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        OriginCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        FacilityCity: "Bathinda",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17024,
        Pin: 768119,
        DispatchCenter: "Deogarh_Jagnathtpl_D (Orissa)",
        OriginCenter: "Deogarh_Jagnathtpl_D (Orissa)",
        FacilityCity: "Deogarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17025,
        Pin: 805127,
        DispatchCenter: "Sirdala_ChandrikaNagar_D (Bihar)",
        OriginCenter: "Sirdala_ChandrikaNagar_D (Bihar)",
        FacilityCity: "Sirdala",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17026,
        Pin: 560053,
        DispatchCenter: "Bangalore_Shantinagar_L (Karnataka)",
        OriginCenter: "Bangalore_Shantinagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17027,
        Pin: 690571,
        DispatchCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        OriginCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17028,
        Pin: 392130,
        DispatchCenter: "Dahej_Vadadla_D (Gujarat)",
        OriginCenter: "Dahej_Vadadla_D (Gujarat)",
        FacilityCity: "Dahej",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 17029,
        Pin: 141111,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17030,
        Pin: 743610,
        DispatchCenter: "Baruipur_Bnsibtla_D (West Bengal)",
        OriginCenter: "Baruipur_Bnsibtla_D (West Bengal)",
        FacilityCity: "Baruipur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17031,
        Pin: 207245,
        DispatchCenter: "Ganjdundwara_CanalRd_D (Uttar Pradesh)",
        OriginCenter: "Ganjdundwara_CanalRd_D (Uttar Pradesh)",
        FacilityCity: "Ganjdundwara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17032,
        Pin: 441210,
        DispatchCenter: "Umred_Narsala_D (Maharashtra)",
        OriginCenter: "Umred_Narsala_D (Maharashtra)",
        FacilityCity: "Umred",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17033,
        Pin: 784528,
        DispatchCenter: "Udalguri_Milonpur_D (Assam)",
        OriginCenter: "Udalguri_Milonpur_D (Assam)",
        FacilityCity: "Mangaldoi",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17034,
        Pin: 206121,
        DispatchCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        OriginCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        FacilityCity: "Etawah",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17035,
        Pin: 334023,
        DispatchCenter: "Khajuwala_Ward18_DPP (Rajasthan)",
        OriginCenter: "Khajuwala_Ward18_DPP (Rajasthan)",
        FacilityCity: "Khajuwala",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17036,
        Pin: 362540,
        DispatchCenter: "Una_StColony_D (Gujarat)",
        OriginCenter: "Una_StColony_D (Gujarat)",
        FacilityCity: "UNA",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 17037,
        Pin: 573101,
        DispatchCenter: "Belur_GendehalliRd_D (Karnataka)",
        OriginCenter: "Belur_GendehalliRd_D (Karnataka)",
        FacilityCity: "Belur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17038,
        Pin: 313330,
        DispatchCenter: "Rajsamand_Riicoarea_D (Rajasthan)",
        OriginCenter: "Rajsamand_Riicoarea_D (Rajasthan)",
        FacilityCity: "Rajsamand",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17039,
        Pin: 505450,
        DispatchCenter: "Metpally_GunjRDPP_D (Telangana)",
        OriginCenter: "Metpally_GunjRDPP_D (Telangana)",
        FacilityCity: "Metpally",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17040,
        Pin: 766023,
        DispatchCenter: "Junagarh_Dharmagarh1_D (Orissa)",
        OriginCenter: "Junagarh_Dharmagarh1_D (Orissa)",
        FacilityCity: "Junagarh-OR",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17041,
        Pin: 517586,
        DispatchCenter: "PutturAP_Pichatur_D (Andhra Pradesh)",
        OriginCenter: "PutturAP_Pichatur_D (Andhra Pradesh)",
        FacilityCity: "Puttur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17042,
        Pin: 444719,
        DispatchCenter: "Dharni_Bank_D (Maharashtra)",
        OriginCenter: "Dharni_Bank_D (Maharashtra)",
        FacilityCity: "Dharni",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17043,
        Pin: 580112,
        DispatchCenter: "Dharwad_Kamalpur_D (Karnataka)",
        OriginCenter: "Dharwad_Kamalpur_D (Karnataka)",
        FacilityCity: "Dharwad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17044,
        Pin: 110044,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17045,
        Pin: 500077,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17046,
        Pin: 844141,
        DispatchCenter: "Tajpur_HsptlRd_D (Bihar)",
        OriginCenter: "Tajpur_HsptlRd_D (Bihar)",
        FacilityCity: "Tajpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17047,
        Pin: 795122,
        DispatchCenter: "Senapati_WardNo1_D (Manipur)",
        OriginCenter: "Senapati_WardNo1_D (Manipur)",
        FacilityCity: "Kangpokpi",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17048,
        Pin: 523335,
        DispatchCenter: "CumbumAP_BhagyaNagar_D (Andhra Pradesh)",
        OriginCenter: "CumbumAP_BhagyaNagar_D (Andhra Pradesh)",
        FacilityCity: "CumbumAP",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17049,
        Pin: 272152,
        DispatchCenter: "Bansi_Shyamnagar_D (Uttar Pradesh)",
        OriginCenter: "Bansi_Shyamnagar_D (Uttar Pradesh)",
        FacilityCity: "Bansi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17050,
        Pin: 444302,
        DispatchCenter: "Akola_Midcphase3_H (Maharashtra)",
        OriginCenter: "Akola_Midcphase3_H (Maharashtra)",
        FacilityCity: "Akola",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17051,
        Pin: 175003,
        DispatchCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        OriginCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        FacilityCity: "Mandi",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17052,
        Pin: 182147,
        DispatchCenter: "Doda_GhatRoad_D (Jammu & Kashmir)",
        OriginCenter: "Doda_GhatRoad_D (Jammu & Kashmir)",
        FacilityCity: "Doda",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17053,
        Pin: 411056,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17054,
        Pin: 734123,
        DispatchCenter: "Darjeeling_Limbugaon_D (West Bengal)",
        OriginCenter: "Darjeeling_Limbugaon_D (West Bengal)",
        FacilityCity: "Darjeeling",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17055,
        Pin: 689532,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17056,
        Pin: 769042,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17057,
        Pin: 110112,
        DispatchCenter: "Delhi_RailYardRd_L (Delhi)",
        OriginCenter: "Delhi_RailYardRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17058,
        Pin: 641002,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17059,
        Pin: 490011,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17060,
        Pin: 201103,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17061,
        Pin: 783372,
        DispatchCenter: "Kokrajhar_Borobhatarmari_DPP (Assam)",
        OriginCenter: "Kokrajhar_Borobhatarmari_DPP (Assam)",
        FacilityCity: "Kokrajhar",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17062,
        Pin: 413728,
        DispatchCenter: "Shrigonda_Adhalgaon_D (Maharashtra)",
        OriginCenter: "Shrigonda_Adhalgaon_D (Maharashtra)",
        FacilityCity: "Shrigonda",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17063,
        Pin: 470441,
        DispatchCenter: "Barodia_Bandri_DPP (Madhya Pradesh)",
        OriginCenter: "Barodia_Bandri_DPP (Madhya Pradesh)",
        FacilityCity: "Barodia",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17064,
        Pin: 172021,
        DispatchCenter: "Nankhari_Blockoffice_DPP (Himachal Pradesh)",
        OriginCenter: "Nankhari_Blockoffice_DPP (Himachal Pradesh)",
        FacilityCity: "Nankhari",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17065,
        Pin: 590012,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17066,
        Pin: 814102,
        DispatchCenter: "Poriyahat_Jogia_D (Jharkhand)",
        OriginCenter: "Poriyahat_Jogia_D (Jharkhand)",
        FacilityCity: "Poriyahat",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17067,
        Pin: 788155,
        DispatchCenter: "Hailakandi_GangprDhmkr_D (Assam)",
        OriginCenter: "Hailakandi_GangprDhmkr_D (Assam)",
        FacilityCity: "Hailakandi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17068,
        Pin: 328026,
        DispatchCenter: "Bari_MaharajBaghColony_D (Rajasthan)",
        OriginCenter: "Bari_MaharajBaghColony_D (Rajasthan)",
        FacilityCity: "Bari",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17069,
        Pin: 766011,
        DispatchCenter: "Bhawanipatna_Dangaripadar_H (Orissa)",
        OriginCenter: "Bhawanipatna_Dangaripadar_H (Orissa)",
        FacilityCity: "Bhawanipatna",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17070,
        Pin: 517592,
        DispatchCenter: "Puttur_Artmclny_D (Andhra Pradesh)",
        OriginCenter: "Puttur_Artmclny_D (Andhra Pradesh)",
        FacilityCity: "Puttur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17071,
        Pin: 441204,
        DispatchCenter: "Umred_Narsala_D (Maharashtra)",
        OriginCenter: "Umred_Narsala_D (Maharashtra)",
        FacilityCity: "Umred",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17072,
        Pin: 144026,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17073,
        Pin: 441904,
        DispatchCenter: "Bhandara_RjnaiDPP_D (Maharashtra)",
        OriginCenter: "Bhandara_RjnaiDPP_D (Maharashtra)",
        FacilityCity: "Bhandara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17074,
        Pin: 475675,
        DispatchCenter: "Datia_Indergarh_DPP (Madhya Pradesh)",
        OriginCenter: "Datia_Indergarh_DPP (Madhya Pradesh)",
        FacilityCity: "Datia",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 17075,
        Pin: 732125,
        DispatchCenter: "Chanchal_Tulsihata_D (West Bengal)",
        OriginCenter: "Chanchal_Tulsihata_D (West Bengal)",
        FacilityCity: "Chanchal",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17076,
        Pin: 431151,
        DispatchCenter: "Phulambri_Oldcourt_DPP (Maharashtra)",
        OriginCenter: "Phulambri_Oldcourt_DPP (Maharashtra)",
        FacilityCity: "Phulambri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17077,
        Pin: 246163,
        DispatchCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        OriginCenter: "Gumkhal_RishikeshRd_D (Uttarakhand)",
        FacilityCity: "Gumkhal",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17078,
        Pin: 673523,
        DispatchCenter: "Koyilandy_Kizhur_D (Kerala)",
        OriginCenter: "Koyilandy_Kizhur_D (Kerala)",
        FacilityCity: "Koyilandy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17079,
        Pin: 413504,
        DispatchCenter: "Bhoom_KusumNagar_D (Maharashtra)",
        OriginCenter: "Bhoom_KusumNagar_D (Maharashtra)",
        FacilityCity: "Bhoom",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17080,
        Pin: 504272,
        DispatchCenter: "Tiryani_KomurambeemChk_DPP (Telangana)",
        OriginCenter: "Tiryani_KomurambeemChk_DPP (Telangana)",
        FacilityCity: "Tiryani",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17081,
        Pin: 326502,
        DispatchCenter: "BhwniMndi_RmngrDPP_D (Rajasthan)",
        OriginCenter: "BhwniMndi_RmngrDPP_D (Rajasthan)",
        FacilityCity: "Bhawani Mandi",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17082,
        Pin: 623522,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17083,
        Pin: 245412,
        DispatchCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        OriginCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        FacilityCity: "Siyana",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17084,
        Pin: 689695,
        DispatchCenter: "Adoor_Pathanapuram_D (Kerala)",
        OriginCenter: "Adoor_Pathanapuram_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17085,
        Pin: 782127,
        DispatchCenter: "Morigaon_Bhuragaon_D (Assam)",
        OriginCenter: "Morigaon_Bhuragaon_D (Assam)",
        FacilityCity: "Morigaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17086,
        Pin: 831006,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17087,
        Pin: 518005,
        DispatchCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        OriginCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17088,
        Pin: 507128,
        DispatchCenter: "Bhadrachalam_Srncolony_D (Telangana)",
        OriginCenter: "Bhadrachalam_Srncolony_D (Telangana)",
        FacilityCity: "Bhadrachalam",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17089,
        Pin: 571236,
        DispatchCenter: "Shanivarasanthe_Bank_D (Karnataka)",
        OriginCenter: "Shanivarasanthe_Bank_D (Karnataka)",
        FacilityCity: "Shanivarasanthe",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17090,
        Pin: 332029,
        DispatchCenter: "Sujangarh_Salasar_D (Rajasthan)",
        OriginCenter: "Sujangarh_Salasar_D (Rajasthan)",
        FacilityCity: "Sujangarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17091,
        Pin: 603305,
        DispatchCenter: "Chengalpattu_Cheyyur_D (Tamil Nadu)",
        OriginCenter: "Chengalpattu_Cheyyur_D (Tamil Nadu)",
        FacilityCity: "Melmaruvathur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17092,
        Pin: 523212,
        DispatchCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        OriginCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        FacilityCity: "Ongole",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17093,
        Pin: 683594,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17094,
        Pin: 212405,
        DispatchCenter: "Phulpur_BeerKaji_D (Uttar Pradesh)",
        OriginCenter: "Phulpur_BeerKaji_D (Uttar Pradesh)",
        FacilityCity: "Phulpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17095,
        Pin: 621110,
        DispatchCenter: "Chinnasalem_VKootRd_D (Tamil Nadu)",
        OriginCenter: "Chinnasalem_VKootRd_D (Tamil Nadu)",
        FacilityCity: "Chinnasalem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17096,
        Pin: 683563,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17097,
        Pin: 742113,
        DispatchCenter: "Murshidabad_Jiaganj_D (West Bengal)",
        OriginCenter: "Murshidabad_Jiaganj_D (West Bengal)",
        FacilityCity: "Murshidabad",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17098,
        Pin: 143601,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17099,
        Pin: 700005,
        DispatchCenter: "Kolkata_Bagmari_L (West Bengal)",
        OriginCenter: "Kolkata_Bagmari_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17100,
        Pin: 532410,
        DispatchCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        OriginCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        FacilityCity: "Srikakulam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17101,
        Pin: 190019,
        DispatchCenter: "Srinagar_Ladhoo_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Ladhoo_H (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17102,
        Pin: 143022,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17103,
        Pin: 442918,
        DispatchCenter: "Gondpipri_Pombhurna_DPP (Maharashtra)",
        OriginCenter: "Gondpipri_Pombhurna_DPP (Maharashtra)",
        FacilityCity: "Gondpipri",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17104,
        Pin: 735228,
        DispatchCenter: "Birpara_BirparaClg_DPP (West Bengal)",
        OriginCenter: "Birpara_BirparaClg_DPP (West Bengal)",
        FacilityCity: "Birpara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17105,
        Pin: 505445,
        DispatchCenter: "Choppadandi_Gopalraopeta_D (Telangana)",
        OriginCenter: "Choppadandi_Gopalraopeta_D (Telangana)",
        FacilityCity: "Choppadandi",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17106,
        Pin: 388430,
        DispatchCenter: "Khambhat_MotvdDPP_D (Gujarat)",
        OriginCenter: "Khambhat_MotvdDPP_D (Gujarat)",
        FacilityCity: "Khambhat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17107,
        Pin: 211003,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17108,
        Pin: 523111,
        DispatchCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        OriginCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        FacilityCity: "Kanigiri",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17109,
        Pin: 415715,
        DispatchCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        OriginCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        FacilityCity: "Khed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17110,
        Pin: 847233,
        DispatchCenter: "Benipur_Karhari_D (Bihar)",
        OriginCenter: "Benipur_Karhari_D (Bihar)",
        FacilityCity: "Benipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17111,
        Pin: 342013,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17112,
        Pin: 851122,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17113,
        Pin: 523185,
        DispatchCenter: "Addanki_Inkollu_D (Andhra Pradesh)",
        OriginCenter: "Addanki_Inkollu_D (Andhra Pradesh)",
        FacilityCity: "Addanki",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17114,
        Pin: 600020,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17115,
        Pin: 382044,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17116,
        Pin: 143527,
        DispatchCenter: "SriHargobindpur_Talwara_D (Punjab)",
        OriginCenter: "SriHargobindpur_Talwara_D (Punjab)",
        FacilityCity: "Sri Hargobindpur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17117,
        Pin: 700049,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17118,
        Pin: 515101,
        DispatchCenter: "Anantapur_Dharmavaram_D (Andhra Pradesh)",
        OriginCenter: "Anantapur_Dharmavaram_D (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17119,
        Pin: 335524,
        DispatchCenter: "Rawatsar_Ward18_D (Rajasthan)",
        OriginCenter: "Rawatsar_Ward18_D (Rajasthan)",
        FacilityCity: "Rawatsar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17120,
        Pin: 788738,
        DispatchCenter: "Ramkrishnanagar_Kalinagar_D (Assam)",
        OriginCenter: "Ramkrishnanagar_Kalinagar_D (Assam)",
        FacilityCity: "Ramkrishna Nagar",
        FacilityState: "Assam",
        Zone : "NE"
      },
      {
        SN: 17121,
        Pin: 132115,
        DispatchCenter: "Panipat_Samalkha_D (Haryana)",
        OriginCenter: "Panipat_Samalkha_D (Haryana)",
        FacilityCity: "Panipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17122,
        Pin: 600083,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17123,
        Pin: 560033,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17124,
        Pin: 140701,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17125,
        Pin: 614902,
        DispatchCenter: "Thanjavur_Palampudur_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Palampudur_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17126,
        Pin: 532408,
        DispatchCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        OriginCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        FacilityCity: "Srikakulam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17127,
        Pin: 244001,
        DispatchCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        OriginCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        FacilityCity: "Moradabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17128,
        Pin: 688501,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17129,
        Pin: 626109,
        DispatchCenter: "Virudhunagar_Allampatti_DPP (Tamil Nadu)",
        OriginCenter: "Virudhunagar_Allampatti_DPP (Tamil Nadu)",
        FacilityCity: "Virudhunagar",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17130,
        Pin: 626513,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17131,
        Pin: 415609,
        DispatchCenter: "Sangameshwar_Burambi_DPP (Maharashtra)",
        OriginCenter: "Sangameshwar_Burambi_DPP (Maharashtra)",
        FacilityCity: "Sangameshwar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17132,
        Pin: 262723,
        DispatchCenter: "Dhaurahara_TahsilRD_D (Uttar Pradesh)",
        OriginCenter: "Dhaurahara_TahsilRD_D (Uttar Pradesh)",
        FacilityCity: "Dhaurahara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17133,
        Pin: 741161,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17134,
        Pin: 495222,
        DispatchCenter: "Bilaspur_Hatarichowk_D (Chhattisgarh)",
        OriginCenter: "Bilaspur_Hatarichowk_D (Chhattisgarh)",
        FacilityCity: "Bilaspur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17135,
        Pin: 126112,
        DispatchCenter: "Safidon_Urlana_D (Haryana)",
        OriginCenter: "Safidon_Urlana_D (Haryana)",
        FacilityCity: "Safidon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17136,
        Pin: 363425,
        DispatchCenter: "Dhandhuka_BhavnagarRd_DPP (Gujarat)",
        OriginCenter: "Dhandhuka_BhavnagarRd_DPP (Gujarat)",
        FacilityCity: "Dhandhuka",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 17137,
        Pin: 305819,
        DispatchCenter: "Makrana_Parbatsar_D (Rajasthan)",
        OriginCenter: "Makrana_Parbatsar_D (Rajasthan)",
        FacilityCity: "Makrana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17138,
        Pin: 276123,
        DispatchCenter: "Aunrihar_Mehnajpur_DPP (Uttar Pradesh)",
        OriginCenter: "Aunrihar_Mehnajpur_DPP (Uttar Pradesh)",
        FacilityCity: "Aunrihar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17139,
        Pin: 673516,
        DispatchCenter: "Kuttiady_ValayannoorMasjid_D (Kerala)",
        OriginCenter: "Kuttiady_ValayannoorMasjid_D (Kerala)",
        FacilityCity: "Kuttiady",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17140,
        Pin: 412402,
        DispatchCenter: "Rajgurunagar_Chandoli2_D (Maharashtra)",
        OriginCenter: "Rajgurunagar_Chandoli2_D (Maharashtra)",
        FacilityCity: "Rajgurunagar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17141,
        Pin: 678722,
        DispatchCenter: "Akaloor_Lakdi_DPP (Kerala)",
        OriginCenter: "Akaloor_Lakdi_DPP (Kerala)",
        FacilityCity: "Akaloor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17142,
        Pin: 334601,
        DispatchCenter: "Lunkaransar_Ajadcolony_D (Rajasthan)",
        OriginCenter: "Lunkaransar_Ajadcolony_D (Rajasthan)",
        FacilityCity: "Lunkaransar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17143,
        Pin: 821306,
        DispatchCenter: "Sasaram_Manikpur_D (Bihar)",
        OriginCenter: "Sasaram_Manikpur_D (Bihar)",
        FacilityCity: "Sasaram",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17144,
        Pin: 413722,
        DispatchCenter: "Rahuri_Mehetremala_D (Maharashtra)",
        OriginCenter: "Rahuri_Mehetremala_D (Maharashtra)",
        FacilityCity: "Rahuri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17145,
        Pin: 624102,
        DispatchCenter: "Kodaikanal_Mudliyrpurm_D (Tamil Nadu)",
        OriginCenter: "Kodaikanal_Mudliyrpurm_D (Tamil Nadu)",
        FacilityCity: "Kodaikanal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17146,
        Pin: 470113,
        DispatchCenter: "Bina_Srvdycwk_D (Madhya Pradesh)",
        OriginCenter: "Bina_Srvdycwk_D (Madhya Pradesh)",
        FacilityCity: "Bina",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 17147,
        Pin: 756169,
        DispatchCenter: "Bhadrak_Deuli_D (Orissa)",
        OriginCenter: "Bhadrak_Deuli_D (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17148,
        Pin: 841441,
        DispatchCenter: "Bhore_Bank_D (Bihar)",
        OriginCenter: "Bhore_Bank_D (Bihar)",
        FacilityCity: "Bhore",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17149,
        Pin: 143416,
        DispatchCenter: "Patti_TarnTaran_D (Punjab)",
        OriginCenter: "Patti_TarnTaran_D (Punjab)",
        FacilityCity: "Patti",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17150,
        Pin: 500103,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17151,
        Pin: 682503,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17152,
        Pin: 323305,
        DispatchCenter: "Rawatbhata_HarijanBasti_D (Rajasthan)",
        OriginCenter: "Rawatbhata_HarijanBasti_D (Rajasthan)",
        FacilityCity: "Rawatbhata",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17153,
        Pin: 534326,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17154,
        Pin: 636106,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17155,
        Pin: 110034,
        DispatchCenter: "Delhi_Libaspur_L (Delhi)",
        OriginCenter: "Delhi_Libaspur_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17156,
        Pin: 261401,
        DispatchCenter: "Mishrikh_Naimishrnya_DPP (Uttar Pradesh)",
        OriginCenter: "Mishrikh_Naimishrnya_DPP (Uttar Pradesh)",
        FacilityCity: "Mishrikh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17157,
        Pin: 711225,
        DispatchCenter: "Khanakul_Antngr_D (West Bengal)",
        OriginCenter: "Khanakul_Antngr_D (West Bengal)",
        FacilityCity: "Khanakul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17158,
        Pin: 364730,
        DispatchCenter: "Dhasa_Nolipara_D (Gujarat)",
        OriginCenter: "Dhasa_Nolipara_D (Gujarat)",
        FacilityCity: "Dhasa",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17159,
        Pin: 323026,
        DispatchCenter: "Bundi_Central_D_1 (Rajasthan)",
        OriginCenter: "Bundi_Central_D_1 (Rajasthan)",
        FacilityCity: "Bundi",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17160,
        Pin: 721135,
        DispatchCenter: "Kharagpur_Nimpura_I (West Bengal)",
        OriginCenter: "Kharagpur_Nimpura_I (West Bengal)",
        FacilityCity: "Kharagpur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17161,
        Pin: 786103,
        DispatchCenter: "Tengakhat_TngkhtBzaar_DPP (Assam)",
        OriginCenter: "Tengakhat_TngkhtBzaar_DPP (Assam)",
        FacilityCity: "Tengakhat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17162,
        Pin: 571441,
        DispatchCenter: "Kollegala_IndiraClny_D (Karnataka)",
        OriginCenter: "Kollegala_IndiraClny_D (Karnataka)",
        FacilityCity: "Kollegala",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17163,
        Pin: 389260,
        DispatchCenter: "Santrampur_MahirCmplx_DPP (Gujarat)",
        OriginCenter: "Santrampur_MahirCmplx_DPP (Gujarat)",
        FacilityCity: "Santrampur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17164,
        Pin: 360035,
        DispatchCenter: "Rajkot_Shapar_D (Gujarat)",
        OriginCenter: "Rajkot_Shapar_D (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17165,
        Pin: 533214,
        DispatchCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        OriginCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        FacilityCity: "Amalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17166,
        Pin: 493338,
        DispatchCenter: "Jaijaipur_Shivrinarayan_DPP (Chhattisgarh)",
        OriginCenter: "Jaijaipur_Shivrinarayan_DPP (Chhattisgarh)",
        FacilityCity: "Jaijaipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17167,
        Pin: 152024,
        DispatchCenter: "Jalalabad_Gandhinagar_D (Punjab)",
        OriginCenter: "Jalalabad_Gandhinagar_D (Punjab)",
        FacilityCity: "Jalalabad",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17168,
        Pin: 691022,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17169,
        Pin: 691530,
        DispatchCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        OriginCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17170,
        Pin: 844111,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17171,
        Pin: 311408,
        DispatchCenter: "Shahpura_JhajpurRd_DPP (Rajasthan)",
        OriginCenter: "Shahpura_JhajpurRd_DPP (Rajasthan)",
        FacilityCity: "Shahpura",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17172,
        Pin: 712601,
        DispatchCenter: "Arambag_BalibDPP_D (West Bengal)",
        OriginCenter: "Arambag_BalibDPP_D (West Bengal)",
        FacilityCity: "Arambag",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17173,
        Pin: 262726,
        DispatchCenter: "Lakhimpur_FunMall_D (Uttar Pradesh)",
        OriginCenter: "Lakhimpur_FunMall_D (Uttar Pradesh)",
        FacilityCity: "Lakhimpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17174,
        Pin: 695609,
        DispatchCenter: "Pangodu_Bharathanoor_D (Kerala)",
        OriginCenter: "Pangodu_Bharathanoor_D (Kerala)",
        FacilityCity: "Pangodu",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17175,
        Pin: 462017,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17176,
        Pin: 517193,
        DispatchCenter: "Pileru_Chinnagottigallu_D (Andhra Pradesh)",
        OriginCenter: "Pileru_Chinnagottigallu_D (Andhra Pradesh)",
        FacilityCity: "Tirupati",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17177,
        Pin: 342011,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17178,
        Pin: 515835,
        DispatchCenter: "Guntakal_Central_D_2 (Andhra Pradesh)",
        OriginCenter: "Guntakal_Central_D_2 (Andhra Pradesh)",
        FacilityCity: "Guntakal",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17179,
        Pin: 784117,
        DispatchCenter: "Thelamara_NilachalPath_D (Assam)",
        OriginCenter: "Thelamara_NilachalPath_D (Assam)",
        FacilityCity: "Thelamara",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17180,
        Pin: 422112,
        DispatchCenter: "Sinnar_Pimpale_D (Maharashtra)",
        OriginCenter: "Sinnar_Pimpale_D (Maharashtra)",
        FacilityCity: "Sinnar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17181,
        Pin: 737132,
        DispatchCenter: "Rangpo_Khanikhola_I (Sikkim)",
        OriginCenter: "Rangpo_Khanikhola_I (Sikkim)",
        FacilityCity: "Rangpo",
        FacilityState: "Sikkim",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17182,
        Pin: 441104,
        DispatchCenter: "Ramtek_ShivajiWard_D (Maharashtra)",
        OriginCenter: "Ramtek_ShivajiWard_D (Maharashtra)",
        FacilityCity: "Ramtek",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17183,
        Pin: 506005,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17184,
        Pin: 695543,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17185,
        Pin: 110105,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17186,
        Pin: 591228,
        DispatchCenter: "Nipani_Sakharwadi_D (Karnataka)",
        OriginCenter: "Nipani_Sakharwadi_D (Karnataka)",
        FacilityCity: "Nipani",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17187,
        Pin: 230306,
        DispatchCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        OriginCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        FacilityCity: "Mungra Badshahpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17188,
        Pin: 852110,
        DispatchCenter: "Supaul_SadrHsptl_D (Bihar)",
        OriginCenter: "Supaul_SadrHsptl_D (Bihar)",
        FacilityCity: "Supaul",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17189,
        Pin: 613703,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17190,
        Pin: 465697,
        DispatchCenter: "Shajapur_Mahupura_D (Madhya Pradesh)",
        OriginCenter: "Shajapur_Mahupura_D (Madhya Pradesh)",
        FacilityCity: "Shajapur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 17191,
        Pin: 361230,
        DispatchCenter: "Dhrol_PGVCLOfc_D (Gujarat)",
        OriginCenter: "Dhrol_PGVCLOfc_D (Gujarat)",
        FacilityCity: "Dhrol",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17192,
        Pin: 799288,
        DispatchCenter: "Kailashahar_Kumarghat_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Kailashahar",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17193,
        Pin: 632007,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17194,
        Pin: 832109,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17195,
        Pin: 151510,
        DispatchCenter: "Mansa_GreenVly_D (Punjab)",
        OriginCenter: "Mansa_GreenVly_D (Punjab)",
        FacilityCity: "Mansa",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17196,
        Pin: 621109,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17197,
        Pin: 507138,
        DispatchCenter: "Kothagudem_OldPaloncha_D (Telangana)",
        OriginCenter: "Kothagudem_OldPaloncha_D (Telangana)",
        FacilityCity: "Kothagudem",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17198,
        Pin: 683564,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17199,
        Pin: 732147,
        DispatchCenter: "Malda_Kadubari_D (West Bengal)",
        OriginCenter: "Malda_Kadubari_D (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17200,
        Pin: 800023,
        DispatchCenter: "Patna_PhulwariSharif_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17201,
        Pin: 685601,
        DispatchCenter: "Iddukki_Thadiyampad_D (Kerala)",
        OriginCenter: "Iddukki_Thadiyampad_D (Kerala)",
        FacilityCity: "Iddukki",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17202,
        Pin: 688010,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17203,
        Pin: 626106,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17204,
        Pin: 394326,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17205,
        Pin: 416517,
        DispatchCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        OriginCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        FacilityCity: "Sawantwadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17206,
        Pin: 629502,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17207,
        Pin: 209861,
        DispatchCenter: "Unnao_Shekhpur_D (Uttar Pradesh)",
        OriginCenter: "Unnao_Shekhpur_D (Uttar Pradesh)",
        FacilityCity: "Unnao",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17208,
        Pin: 620001,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17209,
        Pin: 577532,
        DispatchCenter: "Chitradurga_Basaveshwar_D (Karnataka)",
        OriginCenter: "Chitradurga_Basaveshwar_D (Karnataka)",
        FacilityCity: "Chitradurga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17210,
        Pin: 736169,
        DispatchCenter: "Sitai_Khamarsitai_DPP (West Bengal)",
        OriginCenter: "Sitai_Khamarsitai_DPP (West Bengal)",
        FacilityCity: "Sitai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17211,
        Pin: 576210,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17212,
        Pin: 678510,
        DispatchCenter: "Nenmara_Vithanaserry_D (Kerala)",
        OriginCenter: "Nenmara_Vithanaserry_D (Kerala)",
        FacilityCity: "Nenmara",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17213,
        Pin: 782108,
        DispatchCenter: "Nagaon_Bangthai_H (Assam)",
        OriginCenter: "Nagaon_Bangthai_H (Assam)",
        FacilityCity: "Nagaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17214,
        Pin: 734203,
        DispatchCenter: "Kurseong_PankhabariR_D (West Bengal)",
        OriginCenter: "Kurseong_PankhabariR_D (West Bengal)",
        FacilityCity: "Kurseong",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17215,
        Pin: 462016,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17216,
        Pin: 587120,
        DispatchCenter: "Bagalkot_NavaNgr_D (Karnataka)",
        OriginCenter: "Bagalkot_NavaNgr_D (Karnataka)",
        FacilityCity: "Bagalkot",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17217,
        Pin: 230406,
        DispatchCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        OriginCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        FacilityCity: "Patti-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17218,
        Pin: 766018,
        DispatchCenter: "Jaipatna_PradhniChwk_D (Orissa)",
        OriginCenter: "Jaipatna_PradhniChwk_D (Orissa)",
        FacilityCity: "Jaipatna",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17219,
        Pin: 784116,
        DispatchCenter: "Mangaldoi_Anandanagar_D (Assam)",
        OriginCenter: "Mangaldoi_Anandanagar_D (Assam)",
        FacilityCity: "Mangaldoi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17220,
        Pin: 572115,
        DispatchCenter: "Sira_Hanumanthnagara_D (Karnataka)",
        OriginCenter: "Sira_Hanumanthnagara_D (Karnataka)",
        FacilityCity: "Sira",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17221,
        Pin: 576247,
        DispatchCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        OriginCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17222,
        Pin: 209864,
        DispatchCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        OriginCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        FacilityCity: "Unnao",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17223,
        Pin: 679104,
        DispatchCenter: "Shoranur_Vaniyamkulam_D (Kerala)",
        OriginCenter: "Shoranur_Vaniyamkulam_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17224,
        Pin: 781025,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17225,
        Pin: 110512,
        DispatchCenter: "Delhi_RailYardRd_L (Delhi)",
        OriginCenter: "Delhi_RailYardRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17226,
        Pin: 210427,
        DispatchCenter: "Mahoba_Kiratsagar_D (Uttar Pradesh)",
        OriginCenter: "Mahoba_Kiratsagar_D (Uttar Pradesh)",
        FacilityCity: "Mahoba",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17227,
        Pin: 670565,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17228,
        Pin: 521211,
        DispatchCenter: "Nuzvid_NSPColony_D (Andhra Pradesh)",
        OriginCenter: "Nuzvid_NSPColony_D (Andhra Pradesh)",
        FacilityCity: "Nuzvid",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17229,
        Pin: 685618,
        DispatchCenter: "Kuthumkal_Matheckal_D (Kerala)",
        OriginCenter: "Kuthumkal_Matheckal_D (Kerala)",
        FacilityCity: "Kuthumkal",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17230,
        Pin: 580005,
        DispatchCenter: "Dharwad_Kamalpur_D (Karnataka)",
        OriginCenter: "Dharwad_Kamalpur_D (Karnataka)",
        FacilityCity: "Dharwad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17231,
        Pin: 400057,
        DispatchCenter: "Mumbai_Kurla_L (Maharashtra)",
        OriginCenter: "Mumbai_Kurla_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17232,
        Pin: 591241,
        DispatchCenter: "Nipani_Sakharwadi_D (Karnataka)",
        OriginCenter: "Nipani_Sakharwadi_D (Karnataka)",
        FacilityCity: "Nipani",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17233,
        Pin: 394440,
        DispatchCenter: "Mandvi_Tekro_DPP (Gujarat)",
        OriginCenter: "Mandvi_Tekro_DPP (Gujarat)",
        FacilityCity: "Mandvi (Surat)",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17234,
        Pin: 612702,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17235,
        Pin: 221508,
        DispatchCenter: "Saidabad_Naibasti_D (Uttar Pradesh)",
        OriginCenter: "Saidabad_Naibasti_D (Uttar Pradesh)",
        FacilityCity: "Saidabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17236,
        Pin: 641655,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17237,
        Pin: 762029,
        DispatchCenter: "Phulbani_NipiditaOfc_D (Orissa)",
        OriginCenter: "Phulbani_NipiditaOfc_D (Orissa)",
        FacilityCity: "Phulbani",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17238,
        Pin: 786191,
        DispatchCenter: "Duliajan_StatonRd_D (Assam)",
        OriginCenter: "Duliajan_StatonRd_D (Assam)",
        FacilityCity: "Duliajan",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17239,
        Pin: 627109,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17240,
        Pin: 334305,
        DispatchCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        OriginCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        FacilityCity: "Bikaner",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17241,
        Pin: 246161,
        DispatchCenter: "Ramnagar_Central_D_2 (Uttarakhand)",
        OriginCenter: "Ramnagar_Central_D_2 (Uttarakhand)",
        FacilityCity: "Ramnagar",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17242,
        Pin: 605501,
        DispatchCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        OriginCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        FacilityCity: "Viluppuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17243,
        Pin: 841414,
        DispatchCenter: "Chhapra_Ramnagar_I (Bihar)",
        OriginCenter: "Chhapra_Ramnagar_I (Bihar)",
        FacilityCity: "Chhapra",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17244,
        Pin: 341522,
        DispatchCenter: "Phulera_NarenaRD_D (Rajasthan)",
        OriginCenter: "Phulera_NarenaRD_D (Rajasthan)",
        FacilityCity: "Phulera",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17245,
        Pin: 370511,
        DispatchCenter: "Naliya_Jlramcpx_D (Gujarat)",
        OriginCenter: "Naliya_Jlramcpx_D (Gujarat)",
        FacilityCity: "Naliya",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 17246,
        Pin: 766014,
        DispatchCenter: "Bhawanipatna_Dangaripadar_H (Orissa)",
        OriginCenter: "Bhawanipatna_Dangaripadar_H (Orissa)",
        FacilityCity: "Bhawanipatna",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17247,
        Pin: 262545,
        DispatchCenter: "Dharchula_Towngate_DPP (Uttarakhand)",
        OriginCenter: "Dharchula_Towngate_DPP (Uttarakhand)",
        FacilityCity: "Dharchula",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17248,
        Pin: 691501,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17249,
        Pin: 140111,
        DispatchCenter: "Rupnagar_Towercolony_D (Punjab)",
        OriginCenter: "Rupnagar_Towercolony_D (Punjab)",
        FacilityCity: "Rupnagar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17250,
        Pin: 764072,
        DispatchCenter: "Pappadahandi_Jatabal_DPP (Orissa)",
        OriginCenter: "Pappadahandi_Jatabal_DPP (Orissa)",
        FacilityCity: "Pappadahandi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17251,
        Pin: 515766,
        DispatchCenter: "Kalyandurg_Tenagallu_D (Andhra Pradesh)",
        OriginCenter: "Kalyandurg_Tenagallu_D (Andhra Pradesh)",
        FacilityCity: "Kambadur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17252,
        Pin: 524407,
        DispatchCenter: "Gudur_MalvyaNagar_D (Andhra Pradesh)",
        OriginCenter: "Gudur_MalvyaNagar_D (Andhra Pradesh)",
        FacilityCity: "Gudur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17253,
        Pin: 400709,
        DispatchCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        OriginCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17254,
        Pin: 735302,
        DispatchCenter: "Mainaguri_Debinagar_DPP (West Bengal)",
        OriginCenter: "Mainaguri_Debinagar_DPP (West Bengal)",
        FacilityCity: "Mainaguri",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17255,
        Pin: 802101,
        DispatchCenter: "Buxar_SohaniPatti_D (Bihar)",
        OriginCenter: "Buxar_SohaniPatti_D (Bihar)",
        FacilityCity: "Buxar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17256,
        Pin: 603405,
        DispatchCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        OriginCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        FacilityCity: "Chengalpattu",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17257,
        Pin: 229130,
        DispatchCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        OriginCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        FacilityCity: "Lalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17258,
        Pin: 444103,
        DispatchCenter: "Akot_CollegeRd_D (Maharashtra)",
        OriginCenter: "Akot_CollegeRd_D (Maharashtra)",
        FacilityCity: "Akot",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17259,
        Pin: 402402,
        DispatchCenter: "Shriwardhan_Kherdi_D (Maharashtra)",
        OriginCenter: "Shriwardhan_Kherdi_D (Maharashtra)",
        FacilityCity: "Shriwardhan",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17260,
        Pin: 463111,
        DispatchCenter: "Berasia_Busstand_DPP (Madhya Pradesh)",
        OriginCenter: "Berasia_Busstand_DPP (Madhya Pradesh)",
        FacilityCity: "Berasia",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 17261,
        Pin: 676311,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17262,
        Pin: 480109,
        DispatchCenter: "Sausar_BorgaonAKVN_DPP (Madhya Pradesh)",
        OriginCenter: "Sausar_BorgaonAKVN_DPP (Madhya Pradesh)",
        FacilityCity: "Sausar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 17263,
        Pin: 641023,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17264,
        Pin: 754291,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17265,
        Pin: 846002,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17266,
        Pin: 755006,
        DispatchCenter: "Mangalpur_GayatriColony_D (Orissa)",
        OriginCenter: "Mangalpur_GayatriColony_D (Orissa)",
        FacilityCity: "Mangalpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17267,
        Pin: 458441,
        DispatchCenter: "Neemuch_Jhanjharwada_D (Madhya Pradesh)",
        OriginCenter: "Neemuch_Jhanjharwada_D (Madhya Pradesh)",
        FacilityCity: "Neemuch",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17268,
        Pin: 571146,
        DispatchCenter: "Chamarajanagar_Galipur_D (Karnataka)",
        OriginCenter: "Chamarajanagar_Galipur_D (Karnataka)",
        FacilityCity: "Chamarajanagar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17269,
        Pin: 431211,
        DispatchCenter: "Partur_Watur_D (Maharashtra)",
        OriginCenter: "Partur_Watur_D (Maharashtra)",
        FacilityCity: "Partur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17270,
        Pin: 828111,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17271,
        Pin: 577117,
        DispatchCenter: "Chikmagalur_Jayanagara_D (Karnataka)",
        OriginCenter: "Chikmagalur_Jayanagara_D (Karnataka)",
        FacilityCity: "Chikkamagalur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17272,
        Pin: 465441,
        DispatchCenter: "Agar_ShetlaTemple_D (Madhya Pradesh)",
        OriginCenter: "Agar_ShetlaTemple_D (Madhya Pradesh)",
        FacilityCity: "Agar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 17273,
        Pin: 612303,
        DispatchCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        OriginCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17274,
        Pin: 326529,
        DispatchCenter: "RamganjMandi_AdarshNagar_D (Rajasthan)",
        OriginCenter: "RamganjMandi_AdarshNagar_D (Rajasthan)",
        FacilityCity: "Ramganj Mandi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17275,
        Pin: 132102,
        DispatchCenter: "Panipat_Samalkha_D (Haryana)",
        OriginCenter: "Panipat_Samalkha_D (Haryana)",
        FacilityCity: "Panipat",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17276,
        Pin: 441207,
        DispatchCenter: "Brahmapuri_Tilaknagar_D (Maharashtra)",
        OriginCenter: "Brahmapuri_Tilaknagar_D (Maharashtra)",
        FacilityCity: "Brahmapuri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17277,
        Pin: 602107,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17278,
        Pin: 395002,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17279,
        Pin: 172104,
        DispatchCenter: "Jeori_Tapri_D (Himachal Pradesh)",
        OriginCenter: "Jeori_Tapri_D (Himachal Pradesh)",
        FacilityCity: "Jeori",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17280,
        Pin: 621011,
        DispatchCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        OriginCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        FacilityCity: "Thuraiyur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17281,
        Pin: 472118,
        DispatchCenter: "Jatara_Baiwarkhas_D (Madhya Pradesh)",
        OriginCenter: "Jatara_Baiwarkhas_D (Madhya Pradesh)",
        FacilityCity: "Jatara",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17282,
        Pin: 416315,
        DispatchCenter: "Ashta_GordeChwk_D (Maharashtra)",
        OriginCenter: "Ashta_GordeChwk_D (Maharashtra)",
        FacilityCity: "AshtaSangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17283,
        Pin: 742150,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17284,
        Pin: 209210,
        DispatchCenter: "Kanpur_Shivrajpur_D (Uttar Pradesh)",
        OriginCenter: "Kanpur_Shivrajpur_D (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17285,
        Pin: 415011,
        DispatchCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        OriginCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        FacilityCity: "Satara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17286,
        Pin: 828303,
        DispatchCenter: "Chandankiyari_ITI_DPP (Jharkhand)",
        OriginCenter: "Chandankiyari_ITI_DPP (Jharkhand)",
        FacilityCity: "chandankiyari",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17287,
        Pin: 700143,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17288,
        Pin: 563161,
        DispatchCenter: "Srinivaspur_KolarRD_D (Karnataka)",
        OriginCenter: "Srinivaspur_KolarRD_D (Karnataka)",
        FacilityCity: "Srinivaspur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17289,
        Pin: 400043,
        DispatchCenter: "Mumbai_Chembur_L (Maharashtra)",
        OriginCenter: "Mumbai_Chembur_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17290,
        Pin: 695304,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17291,
        Pin: 671312,
        DispatchCenter: "Nileshwar_Palathadam_D (Kerala)",
        OriginCenter: "Nileshwar_Palathadam_D (Kerala)",
        FacilityCity: "Nileshwar",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17292,
        Pin: 244102,
        DispatchCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        OriginCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        FacilityCity: "Moradabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17293,
        Pin: 243407,
        DispatchCenter: "Nawabganj_Bhairav_D (Uttar Pradesh)",
        OriginCenter: "Nawabganj_Bhairav_D (Uttar Pradesh)",
        FacilityCity: "Nawabganj-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17294,
        Pin: 642202,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17295,
        Pin: 711113,
        DispatchCenter: "Howrah_Kamrangu_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17296,
        Pin: 700125,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17297,
        Pin: 360050,
        DispatchCenter: "Jasdan_Aanandnagar_D (Gujarat)",
        OriginCenter: "Jasdan_Aanandnagar_D (Gujarat)",
        FacilityCity: "Jasdan",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17298,
        Pin: 721603,
        DispatchCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        OriginCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        FacilityCity: "Tamluk",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17299,
        Pin: 743383,
        DispatchCenter: "DakshinBarasat_Raidighi_DPP (West Bengal)",
        OriginCenter: "DakshinBarasat_Raidighi_DPP (West Bengal)",
        FacilityCity: "Dakshin Barasat",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17300,
        Pin: 382029,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17301,
        Pin: 721401,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17302,
        Pin: 700080,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17303,
        Pin: 627415,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17304,
        Pin: 224238,
        DispatchCenter: "Tanda_HayatganjChowk_D (Uttar Pradesh)",
        OriginCenter: "Tanda_HayatganjChowk_D (Uttar Pradesh)",
        FacilityCity: "Tanda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17305,
        Pin: 721507,
        DispatchCenter: "Jhargram_Raghunathpur_DPP (West Bengal)",
        OriginCenter: "Jhargram_Raghunathpur_DPP (West Bengal)",
        FacilityCity: "Jhargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17306,
        Pin: 231207,
        DispatchCenter: "Chopan_Lokocly_DPP (Uttar Pradesh)",
        OriginCenter: "Chopan_Lokocly_DPP (Uttar Pradesh)",
        FacilityCity: "Chopan",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17307,
        Pin: 441002,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Chikhali_L (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17308,
        Pin: 416529,
        DispatchCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        OriginCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        FacilityCity: "Sawantwadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17309,
        Pin: 263154,
        DispatchCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        OriginCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        FacilityCity: "Rudrapur",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17310,
        Pin: 852214,
        DispatchCenter: "Triveninganj_Ward16_D (Bihar)",
        OriginCenter: "Triveninganj_Ward16_D (Bihar)",
        FacilityCity: "Triveninganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17311,
        Pin: 686022,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17312,
        Pin: 177202,
        DispatchCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        OriginCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        FacilityCity: "Amb",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17313,
        Pin: 388570,
        DispatchCenter: "Vatadara_Banejda_DPP (Gujarat)",
        OriginCenter: "Vatadara_Banejda_DPP (Gujarat)",
        FacilityCity: "Vatadara",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 17314,
        Pin: 440037,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17315,
        Pin: 733143,
        DispatchCenter: "Itahar_Kumedpur_D (West Bengal)",
        OriginCenter: "Itahar_Kumedpur_D (West Bengal)",
        FacilityCity: "Itahar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17316,
        Pin: 131029,
        DispatchCenter: "Sonipat_Sector51_L (Haryana)",
        OriginCenter: "Sonipat_WazidpurSaboli_P (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17317,
        Pin: 382445,
        DispatchCenter: "Ahmedabad_Kanera_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17318,
        Pin: 643004,
        DispatchCenter: "Ooty_Westmere_D (Tamil Nadu)",
        OriginCenter: "Ooty_Westmere_D (Tamil Nadu)",
        FacilityCity: "Ooty",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17319,
        Pin: 283113,
        DispatchCenter: "Fatehabad_Bah_D (Uttar Pradesh)",
        OriginCenter: "Fatehabad_Bah_D (Uttar Pradesh)",
        FacilityCity: "Fatehabad-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17320,
        Pin: 400065,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17321,
        Pin: 415804,
        DispatchCenter: "Sangameshwar_Burambi_DPP (Maharashtra)",
        OriginCenter: "Sangameshwar_Burambi_DPP (Maharashtra)",
        FacilityCity: "Sangameshwar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17322,
        Pin: 416436,
        DispatchCenter: "Sangli_Dattanagar_D (Maharashtra)",
        OriginCenter: "Sangli_Dattanagar_D (Maharashtra)",
        FacilityCity: "Sangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17323,
        Pin: 444901,
        DispatchCenter: "Amravati_Changapur_D (Maharashtra)",
        OriginCenter: "Amravati_Changapur_D (Maharashtra)",
        FacilityCity: "Amravati",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17324,
        Pin: 758086,
        DispatchCenter: "Kendujhar_Jhumpura_D (Orissa)",
        OriginCenter: "Kendujhar_Jhumpura_D (Orissa)",
        FacilityCity: "Kendujhar",
        FacilityState: "Orissa",
        Zone : "NE"
      },
      {
        SN: 17325,
        Pin: 389172,
        DispatchCenter: "Santrampur_MahirCmplx_DPP (Gujarat)",
        OriginCenter: "Santrampur_MahirCmplx_DPP (Gujarat)",
        FacilityCity: "Santrampur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17326,
        Pin: 126125,
        DispatchCenter: "Jind_SunderNagar_D (Haryana)",
        OriginCenter: "Jind_SunderNagar_D (Haryana)",
        FacilityCity: "Jind",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17327,
        Pin: 751022,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17328,
        Pin: 192302,
        DispatchCenter: "Pulwama_JKBank_DPP (Jammu & Kashmir)",
        OriginCenter: "Pulwama_JKBank_DPP (Jammu & Kashmir)",
        FacilityCity: "Pulwama",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17329,
        Pin: 276001,
        DispatchCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17330,
        Pin: 757086,
        DispatchCenter: "Bangriposi_Majigan_D (Orissa)",
        OriginCenter: "Bangriposi_Majigan_D (Orissa)",
        FacilityCity: "Bangriposi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17331,
        Pin: 631202,
        DispatchCenter: "Sholinghur_Ramkrishnarajapet_D (Tamil Nadu)",
        OriginCenter: "Sholinghur_Ramkrishnarajapet_D (Tamil Nadu)",
        FacilityCity: "Sholinghur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17332,
        Pin: 678612,
        DispatchCenter: "Akaloor_Lakdi_DPP (Kerala)",
        OriginCenter: "Akaloor_Lakdi_DPP (Kerala)",
        FacilityCity: "Akaloor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17333,
        Pin: 502269,
        DispatchCenter: "Narayankhed_Turkapally_D (Telangana)",
        OriginCenter: "Narayankhed_Turkapally_D (Telangana)",
        FacilityCity: "Narayankhed",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17334,
        Pin: 263401,
        DispatchCenter: "Kashipur_Chakkarpur_D (Uttarakhand)",
        OriginCenter: "Kashipur_Chakkarpur_D (Uttarakhand)",
        FacilityCity: "Kashipur",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17335,
        Pin: 416501,
        DispatchCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        OriginCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        FacilityCity: "Gadhinglaj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17336,
        Pin: 570018,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17337,
        Pin: 321030,
        DispatchCenter: "Bayana_Shivcolony_D (Rajasthan)",
        OriginCenter: "Bayana_Shivcolony_D (Rajasthan)",
        FacilityCity: "Bayana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17338,
        Pin: 847224,
        DispatchCenter: "Khutauna_ShastriChwk_D (Bihar)",
        OriginCenter: "Khutauna_ShastriChwk_D (Bihar)",
        FacilityCity: "Khutauna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17339,
        Pin: 741164,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17340,
        Pin: 244103,
        DispatchCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        OriginCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        FacilityCity: "Moradabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17341,
        Pin: 263145,
        DispatchCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        OriginCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        FacilityCity: "Rudrapur",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17342,
        Pin: 402101,
        DispatchCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        OriginCenter: "Mangaon_ChaitanyaHotel_D (Maharashtra)",
        FacilityCity: "Mangaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17343,
        Pin: 576124,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17344,
        Pin: 246448,
        DispatchCenter: "Rudraprayag_Dungri_DPP (Uttarakhand)",
        OriginCenter: "Rudraprayag_Dungri_DPP (Uttarakhand)",
        FacilityCity: "Rudraprayag",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17345,
        Pin: 284206,
        DispatchCenter: "Chirgaon_RamnagarRoad_D (Uttar Pradesh)",
        OriginCenter: "Chirgaon_RamnagarRoad_D (Uttar Pradesh)",
        FacilityCity: "Chirgaon",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17346,
        Pin: 624707,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17347,
        Pin: 229401,
        DispatchCenter: "Unchahar_Khojanpur_D (Uttar Pradesh)",
        OriginCenter: "Unchahar_Khojanpur_D (Uttar Pradesh)",
        FacilityCity: "Unchahar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17348,
        Pin: 221103,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17349,
        Pin: 689667,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17350,
        Pin: 393236,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 17351,
        Pin: 686041,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17352,
        Pin: 524234,
        DispatchCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        OriginCenter: "Atmakur_NellorePalem_D (Andhra Pradesh)",
        FacilityCity: "Atmakur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17353,
        Pin: 177119,
        DispatchCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        OriginCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        FacilityCity: "Bangana",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17354,
        Pin: 227812,
        DispatchCenter: "Sultanpur_Balpur_D (Uttar Pradesh)",
        OriginCenter: "Sultanpur_Balpur_D (Uttar Pradesh)",
        FacilityCity: "Sultanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17355,
        Pin: 814113,
        DispatchCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        OriginCenter: "Deoghar_Bmpstwn_D (Jharkhand)",
        FacilityCity: "Deoghar",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17356,
        Pin: 785625,
        DispatchCenter: "Golaghat_BaruaRd_D (Assam)",
        OriginCenter: "Golaghat_BaruaRd_D (Assam)",
        FacilityCity: "Golaghat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17357,
        Pin: 713515,
        DispatchCenter: "Manteswar_MemariRD_D (West Bengal)",
        OriginCenter: "Manteswar_MemariRD_D (West Bengal)",
        FacilityCity: "Manteswar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17358,
        Pin: 636121,
        DispatchCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        OriginCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        FacilityCity: "Attur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17359,
        Pin: 412220,
        DispatchCenter: "Shirur_RanjangaonMIDC_L (Maharashtra)",
        OriginCenter: "Shirur_RanjangaonMIDC_L (Maharashtra)",
        FacilityCity: "Shirur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17360,
        Pin: 805103,
        DispatchCenter: "Nawada_Gondapur_D (Bihar)",
        OriginCenter: "Nawada_Gondapur_D (Bihar)",
        FacilityCity: "Nawada",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17361,
        Pin: 627116,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17362,
        Pin: 604601,
        DispatchCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        OriginCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        FacilityCity: "Tiruvannamalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17363,
        Pin: 848110,
        DispatchCenter: "Samastipur_RhmprRdli_D (Bihar)",
        OriginCenter: "Samastipur_RhmprRdli_D (Bihar)",
        FacilityCity: "Samastipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17364,
        Pin: 635001,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17365,
        Pin: 509407,
        DispatchCenter: "Narayanpet_Ashoknagar_D (Telangana)",
        OriginCenter: "Narayanpet_Ashoknagar_D (Telangana)",
        FacilityCity: "Narayanpet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17366,
        Pin: 752021,
        DispatchCenter: "Nirakarpur_Rameswar_D (Orissa)",
        OriginCenter: "Nirakarpur_Rameswar_D (Orissa)",
        FacilityCity: "Nirakarpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17367,
        Pin: 360540,
        DispatchCenter: "Kalavad_Khodiyarpara_D (Gujarat)",
        OriginCenter: "Kalavad_Khodiyarpara_D (Gujarat)",
        FacilityCity: "Kalavad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17368,
        Pin: 722156,
        DispatchCenter: "Khatra_Simlapal_DPP (West Bengal)",
        OriginCenter: "Khatra_Simlapal_DPP (West Bengal)",
        FacilityCity: "Khatra",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17369,
        Pin: 403524,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17370,
        Pin: 743349,
        DispatchCenter: "DakshinBarasat_Raidighi_DPP (West Bengal)",
        OriginCenter: "DakshinBarasat_Raidighi_DPP (West Bengal)",
        FacilityCity: "Dakshin Barasat",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17371,
        Pin: 452012,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17372,
        Pin: 333514,
        DispatchCenter: "Buhana_Rajota_D (Rajasthan)",
        OriginCenter: "Buhana_Rajota_D (Rajasthan)",
        FacilityCity: "Buhana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17373,
        Pin: 682017,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17374,
        Pin: 177107,
        DispatchCenter: "DehraGopipur_Central_D_1 (Himachal Pradesh)",
        OriginCenter: "DehraGopipur_Central_D_1 (Himachal Pradesh)",
        FacilityCity: "Dehra Gopipur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17375,
        Pin: 533247,
        DispatchCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        OriginCenter: "Razole_Mattaparru_D (Andhra Pradesh)",
        FacilityCity: "Razole",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17376,
        Pin: 230304,
        DispatchCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        OriginCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        FacilityCity: "Pratapgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17377,
        Pin: 212645,
        DispatchCenter: "Fatehpur_Radhanagar_D (Uttar Pradesh)",
        OriginCenter: "Fatehpur_Radhanagar_D (Uttar Pradesh)",
        FacilityCity: "Fatehpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17378,
        Pin: 134007,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17379,
        Pin: 416511,
        DispatchCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        OriginCenter: "Sawantwadi_Khaskilwada_D (Maharashtra)",
        FacilityCity: "Sawantwadi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17380,
        Pin: 686025,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17381,
        Pin: 641305,
        DispatchCenter: "Mettupalayam_Belladhi_D (Tamil Nadu)",
        OriginCenter: "Mettupalayam_Belladhi_D (Tamil Nadu)",
        FacilityCity: "Mettupalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17382,
        Pin: 679306,
        DispatchCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        OriginCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        FacilityCity: "Sankaramangalam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17383,
        Pin: 626104,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17384,
        Pin: 523329,
        DispatchCenter: "Markapur_VidyaNgr_D (Andhra Pradesh)",
        OriginCenter: "Markapur_VidyaNgr_D (Andhra Pradesh)",
        FacilityCity: "Markapur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17385,
        Pin: 626142,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17386,
        Pin: 691576,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17387,
        Pin: 227816,
        DispatchCenter: "Jagdishpur_Canbank_D (Uttar Pradesh)",
        OriginCenter: "Jagdishpur_Canbank_D (Uttar Pradesh)",
        FacilityCity: "Jagdishpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17388,
        Pin: 637102,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17389,
        Pin: 759020,
        DispatchCenter: "Dhenkanal_Rasol_D (Orissa)",
        OriginCenter: "Dhenkanal_Rasol_D (Orissa)",
        FacilityCity: "Dhenkanal",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17390,
        Pin: 636401,
        DispatchCenter: "Mettur_Samballi_D (Tamil Nadu)",
        OriginCenter: "Mettur_Samballi_D (Tamil Nadu)",
        FacilityCity: "Mettur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17391,
        Pin: 221110,
        DispatchCenter: "Chandauli_Mughalsarai_D (Uttar Pradesh)",
        OriginCenter: "Chandauli_Mughalsarai_D (Uttar Pradesh)",
        FacilityCity: "Mughal Sarai",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17392,
        Pin: 521129,
        DispatchCenter: "Avanigadda_Ramnagar_D (Andhra Pradesh)",
        OriginCenter: "Avanigadda_Ramnagar_D (Andhra Pradesh)",
        FacilityCity: "Avanigadda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17393,
        Pin: 444507,
        DispatchCenter: "Washim_Patilnagar_DPP (Maharashtra)",
        OriginCenter: "Washim_Patilnagar_DPP (Maharashtra)",
        FacilityCity: "Washim",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17394,
        Pin: 802136,
        DispatchCenter: "Dumraon_MahvidylaRd_D (Bihar)",
        OriginCenter: "Dumraon_MahvidylaRd_D (Bihar)",
        FacilityCity: "Dumraon",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17395,
        Pin: 843102,
        DispatchCenter: "Dholi_WardNo4_D (Bihar)",
        OriginCenter: "Dholi_WardNo4_D (Bihar)",
        FacilityCity: "Dholi",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17396,
        Pin: 481445,
        DispatchCenter: "Katangi_Mattravihar_D (Madhya Pradesh)",
        OriginCenter: "Katangi_Mattravihar_D (Madhya Pradesh)",
        FacilityCity: "Katangi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17397,
        Pin: 686548,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17398,
        Pin: 685512,
        DispatchCenter: "Kumily_Attappallam_D (Kerala)",
        OriginCenter: "Kumily_Attappallam_D (Kerala)",
        FacilityCity: "Kumily",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17399,
        Pin: 689590,
        DispatchCenter: "Changanacherry_Perunna_D (Kerala)",
        OriginCenter: "Changanacherry_Perunna_D (Kerala)",
        FacilityCity: "Changanacherry",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17400,
        Pin: 530049,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17401,
        Pin: 682504,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17402,
        Pin: 571445,
        DispatchCenter: "Nagamangala_TBExtension_D (Karnataka)",
        OriginCenter: "Nagamangala_TBExtension_D (Karnataka)",
        FacilityCity: "Nagamangala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17403,
        Pin: 736146,
        DispatchCenter: "Mathabhanga_Pachagarh_D (West Bengal)",
        OriginCenter: "Mathabhanga_Pachagarh_D (West Bengal)",
        FacilityCity: "Mathabhanga",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17404,
        Pin: 759019,
        DispatchCenter: "Dhenkanal_Balaramprasad_D (Orissa)",
        OriginCenter: "Dhenkanal_Balaramprasad_D (Orissa)",
        FacilityCity: "Dhenkanal",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17405,
        Pin: 758045,
        DispatchCenter: "Karanjia_Ankura_D (Orissa)",
        OriginCenter: "Karanjia_Ankura_D (Orissa)",
        FacilityCity: "Karanjia",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17406,
        Pin: 145002,
        DispatchCenter: "Pathankot_Kahanpur_H (Punjab)",
        OriginCenter: "Pathankot_Kahanpur_H (Punjab)",
        FacilityCity: "Pathankot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17407,
        Pin: 500080,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17408,
        Pin: 509325,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17409,
        Pin: 457773,
        DispatchCenter: "Petlawad_Unnai_D (Madhya Pradesh)",
        OriginCenter: "Petlawad_Unnai_D (Madhya Pradesh)",
        FacilityCity: "Petlawad",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 17410,
        Pin: 734215,
        DispatchCenter: "Darjeeling_Mahendragaon_DPP (West Bengal)",
        OriginCenter: "Darjeeling_Mahendragaon_DPP (West Bengal)",
        FacilityCity: "Darjeeling",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17411,
        Pin: 380041,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 17412,
        Pin: 735217,
        DispatchCenter: "Alipurduar_Kalchini_DPP (West Bengal)",
        OriginCenter: "Alipurduar_Kalchini_DPP (West Bengal)",
        FacilityCity: "Alipurduar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17413,
        Pin: 581107,
        DispatchCenter: "Gadag_Laxmeshwar_D (Karnataka)",
        OriginCenter: "Gadag_Laxmeshwar_D (Karnataka)",
        FacilityCity: "Gadag",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17414,
        Pin: 244104,
        DispatchCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        OriginCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        FacilityCity: "Moradabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17415,
        Pin: 411046,
        DispatchCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        OriginCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17416,
        Pin: 144402,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17417,
        Pin: 722110,
        DispatchCenter: "Sonamukhi_Central_DPP_1 (West Bengal)",
        OriginCenter: "Sonamukhi_Central_DPP_1 (West Bengal)",
        FacilityCity: "Sonamukhi",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17418,
        Pin: 387420,
        DispatchCenter: "Nadiad_Shantinagar_D (Gujarat)",
        OriginCenter: "Nadiad_Shantinagar_D (Gujarat)",
        FacilityCity: "Nadiad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 17419,
        Pin: 783339,
        DispatchCenter: "Dhubri_Tetultol_D (Assam)",
        OriginCenter: "Dhubri_Tetultol_D (Assam)",
        FacilityCity: "Dhubri",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17420,
        Pin: 440023,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17421,
        Pin: 242301,
        DispatchCenter: "Tilhar_Gurgawancol_D (Uttar Pradesh)",
        OriginCenter: "Tilhar_Gurgawancol_D (Uttar Pradesh)",
        FacilityCity: "Tilhar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17422,
        Pin: 700099,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17423,
        Pin: 303702,
        DispatchCenter: "Jaipur_VishwakarmaIndArea_L (Rajasthan)",
        OriginCenter: "Jaipur_VishwakarmaIndArea_L (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17424,
        Pin: 146102,
        DispatchCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        OriginCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17425,
        Pin: 736172,
        DispatchCenter: "Mathabhanga_Pachagarh_D (West Bengal)",
        OriginCenter: "Mathabhanga_Pachagarh_D (West Bengal)",
        FacilityCity: "Mathabhanga",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17426,
        Pin: 230124,
        DispatchCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        OriginCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        FacilityCity: "Patti-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17427,
        Pin: 824124,
        DispatchCenter: "AurangabadBR_Katharua_D (Bihar)",
        OriginCenter: "AurangabadBR_Katharua_D (Bihar)",
        FacilityCity: "Aurangabad-BR",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17428,
        Pin: 136136,
        DispatchCenter: "Pehowa_ModelTown_DPP (Haryana)",
        OriginCenter: "Pehowa_ModelTown_DPP (Haryana)",
        FacilityCity: "Pehowa",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17429,
        Pin: 713305,
        DispatchCenter: "Asansol_Gobindapur_D (West Bengal)",
        OriginCenter: "Asansol_Gobindapur_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17430,
        Pin: 192304,
        DispatchCenter: "Srinagar_Ladhoo_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Ladhoo_H (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17431,
        Pin: 642106,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17432,
        Pin: 755022,
        DispatchCenter: "Jajpur_Chandama_D (Orissa)",
        OriginCenter: "Jajpur_Chandama_D (Orissa)",
        FacilityCity: "Jajpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17433,
        Pin: 796571,
        DispatchCenter: "Hnahthial_Thiltlang_D (Mizoram)",
        OriginCenter: "Hnahthial_Thiltlang_D (Mizoram)",
        FacilityCity: "Hnahthial",
        FacilityState: "Mizoram",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17434,
        Pin: 700105,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17435,
        Pin: 524127,
        DispatchCenter: "Naidupeta_LASagaram_D (Andhra Pradesh)",
        OriginCenter: "Naidupeta_LASagaram_D (Andhra Pradesh)",
        FacilityCity: "Naidupeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17436,
        Pin: 177206,
        DispatchCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        OriginCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        FacilityCity: "Amb",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17437,
        Pin: 415539,
        DispatchCenter: "Karad_Malkapur1_D (Maharashtra)",
        OriginCenter: "Karad_Malkapur1_D (Maharashtra)",
        FacilityCity: "Karad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17438,
        Pin: 761009,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17439,
        Pin: 515501,
        DispatchCenter: "Mudigubba_Meeseva_D (Andhra Pradesh)",
        OriginCenter: "Mudigubba_Meeseva_D (Andhra Pradesh)",
        FacilityCity: "Mudigubba",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17440,
        Pin: 844119,
        DispatchCenter: "Lalganj_Kharauna_D (Bihar)",
        OriginCenter: "Lalganj_Kharauna_D (Bihar)",
        FacilityCity: "Lalganj-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17441,
        Pin: 281301,
        DispatchCenter: "Mathura_Narsipuramrd_D (Uttar Pradesh)",
        OriginCenter: "Mathura_Narsipuramrd_D (Uttar Pradesh)",
        FacilityCity: "Mathura",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17442,
        Pin: 843352,
        DispatchCenter: "Sitamarhi_Punaura_D (Bihar)",
        OriginCenter: "Sitamarhi_Punaura_D (Bihar)",
        FacilityCity: "Sitamarhi",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17443,
        Pin: 626128,
        DispatchCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        OriginCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        FacilityCity: "Sivakasi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17444,
        Pin: 303806,
        DispatchCenter: "Chomu_SamodRd_D (Rajasthan)",
        OriginCenter: "Chomu_SamodRd_D (Rajasthan)",
        FacilityCity: "Chomu",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17445,
        Pin: 391774,
        DispatchCenter: "Vadodara_Savli_DPP (Gujarat)",
        OriginCenter: "Vadodara_Savli_DPP (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17446,
        Pin: 524239,
        DispatchCenter: "Kavali_Pathuru_D (Andhra Pradesh)",
        OriginCenter: "Kavali_Pathuru_D (Andhra Pradesh)",
        FacilityCity: "Kavali",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17447,
        Pin: 680631,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17448,
        Pin: 516289,
        DispatchCenter: "Cuddapah_Peddacheppalle_D (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Peddacheppalle_D (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17449,
        Pin: 229308,
        DispatchCenter: "Bacchawaran_BhuyianTemple_D (Uttar Pradesh)",
        OriginCenter: "Bacchawaran_BhuyianTemple_D (Uttar Pradesh)",
        FacilityCity: "Bacchawaran",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17450,
        Pin: 176022,
        DispatchCenter: "Jawali_BhaneiLubh_DPP (Himachal Pradesh)",
        OriginCenter: "Jawali_BhaneiLubh_DPP (Himachal Pradesh)",
        FacilityCity: "Jawali",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17451,
        Pin: 520016,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17452,
        Pin: 457990,
        DispatchCenter: "Alirajpur_GhandhiMG_DPP (Madhya Pradesh)",
        OriginCenter: "Alirajpur_GhandhiMG_DPP (Madhya Pradesh)",
        FacilityCity: "Alirajpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 17453,
        Pin: 814141,
        DispatchCenter: "Deoghar_Taljhari_D (Jharkhand)",
        OriginCenter: "Deoghar_Taljhari_D (Jharkhand)",
        FacilityCity: "Deoghar",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17454,
        Pin: 441111,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Chikhali_L (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17455,
        Pin: 621308,
        DispatchCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17456,
        Pin: 222112,
        DispatchCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        OriginCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        FacilityCity: "Jaunpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17457,
        Pin: 784163,
        DispatchCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        OriginCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        FacilityCity: "Itanagar",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17458,
        Pin: 560042,
        DispatchCenter: "Bangalore_Shantinagar_L (Karnataka)",
        OriginCenter: "Bangalore_Shantinagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17459,
        Pin: 171012,
        DispatchCenter: "Shimla_Jawaharcolony_D (Himachal Pradesh)",
        OriginCenter: "Shimla_Jawaharcolony_D (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17460,
        Pin: 303303,
        DispatchCenter: "Dausa_Madhavnagar_D (Rajasthan)",
        OriginCenter: "Dausa_Madhavnagar_D (Rajasthan)",
        FacilityCity: "Dausa",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17461,
        Pin: 524401,
        DispatchCenter: "Tada_Sriharikota_D (Andhra Pradesh)",
        OriginCenter: "Tada_Sriharikota_D (Andhra Pradesh)",
        FacilityCity: "Tada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17462,
        Pin: 743286,
        DispatchCenter: "Swarupnagar_MlangaPra_DPP (West Bengal)",
        OriginCenter: "Swarupnagar_MlangaPra_DPP (West Bengal)",
        FacilityCity: "Swarupnagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17463,
        Pin: 534208,
        DispatchCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        FacilityCity: "Bhimavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17464,
        Pin: 723146,
        DispatchCenter: "Para_Dubra_DPP (West Bengal)",
        OriginCenter: "Para_Dubra_DPP (West Bengal)",
        FacilityCity: "Para",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17465,
        Pin: 343043,
        DispatchCenter: "Balotra_Ward45_D (Rajasthan)",
        OriginCenter: "Balotra_Ward45_D (Rajasthan)",
        FacilityCity: "Balotra",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17466,
        Pin: 416218,
        DispatchCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        OriginCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        FacilityCity: "Gadhinglaj",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17467,
        Pin: 484664,
        DispatchCenter: "Ranchha_Khutar_DPP (Madhya Pradesh)",
        OriginCenter: "Ranchha_Khutar_DPP (Madhya Pradesh)",
        FacilityCity: "Ranchha",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17468,
        Pin: 517642,
        DispatchCenter: "Sriklahsti_SDKNgr_D (Andhra Pradesh)",
        OriginCenter: "Sriklahsti_SDKNgr_D (Andhra Pradesh)",
        FacilityCity: "Srikalahasti",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17469,
        Pin: 431701,
        DispatchCenter: "WarangaPhata_Kalamnuri_D (Maharashtra)",
        OriginCenter: "WarangaPhata_Kalamnuri_D (Maharashtra)",
        FacilityCity: "Waranga Phata",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17470,
        Pin: 454116,
        DispatchCenter: "Sardarpur_Rajgarh_DPP (Madhya Pradesh)",
        OriginCenter: "Sardarpur_Rajgarh_DPP (Madhya Pradesh)",
        FacilityCity: "Sardarpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17471,
        Pin: 444204,
        DispatchCenter: "JalgaonJamod_Tatrpur_DPP (Maharashtra)",
        OriginCenter: "JalgaonJamod_Tatrpur_DPP (Maharashtra)",
        FacilityCity: "Jalgaon Jamod",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17472,
        Pin: 333012,
        DispatchCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        OriginCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        FacilityCity: "Udaipurwati",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17473,
        Pin: 689544,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17474,
        Pin: 609117,
        DispatchCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        OriginCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        FacilityCity: "Sirkazhi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17475,
        Pin: 606905,
        DispatchCenter: "Arani_PolurRd_D (Tamil Nadu)",
        OriginCenter: "Arani_PolurRd_D (Tamil Nadu)",
        FacilityCity: "Arani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17476,
        Pin: 133102,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17477,
        Pin: 805122,
        DispatchCenter: "Sirdala_ChandrikaNagar_D (Bihar)",
        OriginCenter: "Sirdala_ChandrikaNagar_D (Bihar)",
        FacilityCity: "Sirdala",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17478,
        Pin: 388530,
        DispatchCenter: "Vatadara_Banejda_DPP (Gujarat)",
        OriginCenter: "Vatadara_Banejda_DPP (Gujarat)",
        FacilityCity: "Vatadara",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 17479,
        Pin: 503101,
        DispatchCenter: "Kamareddy_KPRcolony_D (Telangana)",
        OriginCenter: "Kamareddy_KPRcolony_D (Telangana)",
        FacilityCity: "Kamareddy",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17480,
        Pin: 152102,
        DispatchCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        OriginCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        FacilityCity: "Bathinda",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17481,
        Pin: 847403,
        DispatchCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        OriginCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        FacilityCity: "Jhanjharpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17482,
        Pin: 582207,
        DispatchCenter: "Navalgund_Yamnur_DPP (Karnataka)",
        OriginCenter: "Navalgund_Yamnur_DPP (Karnataka)",
        FacilityCity: "Navalgund",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17483,
        Pin: 623203,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17484,
        Pin: 181221,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17485,
        Pin: 713340,
        DispatchCenter: "Asansol_Addacolony_D (West Bengal)",
        OriginCenter: "Asansol_Addacolony_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17486,
        Pin: 758022,
        DispatchCenter: "Anandapur_Ghasipura_D (Orissa)",
        OriginCenter: "Anandapur_Ghasipura_D (Orissa)",
        FacilityCity: "Anandapur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17487,
        Pin: 261207,
        DispatchCenter: "Laharpur_TehsilHQ_D (Uttar Pradesh)",
        OriginCenter: "Laharpur_TehsilHQ_D (Uttar Pradesh)",
        FacilityCity: "Laharpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17488,
        Pin: 686002,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17489,
        Pin: 637212,
        DispatchCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        OriginCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        FacilityCity: "Namakkal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17490,
        Pin: 243601,
        DispatchCenter: "Budaun_KrishnaPuriRd_D (Uttar Pradesh)",
        OriginCenter: "Budaun_KrishnaPuriRd_D (Uttar Pradesh)",
        FacilityCity: "Budaun",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17491,
        Pin: 482051,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 17492,
        Pin: 135106,
        DispatchCenter: "Yamunanagar_Balachaur_D (Haryana)",
        OriginCenter: "Yamunanagar_Balachaur_D (Haryana)",
        FacilityCity: "Yamuna Nagar",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17493,
        Pin: 395012,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17494,
        Pin: 636813,
        DispatchCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        OriginCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        FacilityCity: "Dharmapuri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17495,
        Pin: 451228,
        DispatchCenter: "Thikri_DrbrVilla_DPP (Madhya Pradesh)",
        OriginCenter: "Thikri_DrbrVilla_DPP (Madhya Pradesh)",
        FacilityCity: "Thikri",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17496,
        Pin: 443402,
        DispatchCenter: "JalgaonJamod_Tatrpur_DPP (Maharashtra)",
        OriginCenter: "JalgaonJamod_Tatrpur_DPP (Maharashtra)",
        FacilityCity: "Jalgaon Jamod",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17497,
        Pin: 424204,
        DispatchCenter: "Soegaon_Shivajinagar_DPP (Maharashtra)",
        OriginCenter: "Soegaon_Shivajinagar_DPP (Maharashtra)",
        FacilityCity: "Soegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17498,
        Pin: 323022,
        DispatchCenter: "Bundi_Central_D_1 (Rajasthan)",
        OriginCenter: "Bundi_Central_D_1 (Rajasthan)",
        FacilityCity: "Bundi",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17499,
        Pin: 642134,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17500,
        Pin: 444126,
        DispatchCenter: "Akot_CollegeRd_D (Maharashtra)",
        OriginCenter: "Akot_CollegeRd_D (Maharashtra)",
        FacilityCity: "Akot",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17501,
        Pin: 783346,
        DispatchCenter: "Dotma_Nawarbhita_DPP (Assam)",
        OriginCenter: "Dotma_Nawarbhita_DPP (Assam)",
        FacilityCity: "Dotma",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17502,
        Pin: 305011,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17503,
        Pin: 331502,
        DispatchCenter: "Sujangarh_Khanpur_D (Rajasthan)",
        OriginCenter: "Sujangarh_Khanpur_D (Rajasthan)",
        FacilityCity: "Sujangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17504,
        Pin: 735306,
        DispatchCenter: "Mekhliganj_WardNo7_DPP (West Bengal)",
        OriginCenter: "Mekhliganj_WardNo7_DPP (West Bengal)",
        FacilityCity: "Mekhliganj",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17505,
        Pin: 415702,
        DispatchCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        OriginCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        FacilityCity: "Shringartali",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17506,
        Pin: 804434,
        DispatchCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        OriginCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        FacilityCity: "Jehanabad",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17507,
        Pin: 411024,
        DispatchCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        OriginCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17508,
        Pin: 591309,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17509,
        Pin: 821307,
        DispatchCenter: "Sasaram_Manikpur_D (Bihar)",
        OriginCenter: "Sasaram_Manikpur_D (Bihar)",
        FacilityCity: "Sasaram",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17510,
        Pin: 342601,
        DispatchCenter: "Piparcity_JodhpurRd_DPP (Rajasthan)",
        OriginCenter: "Piparcity_JodhpurRd_DPP (Rajasthan)",
        FacilityCity: "Piparcity",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17511,
        Pin: 814166,
        DispatchCenter: "Madhupur_Baramsoli_DPP (Jharkhand)",
        OriginCenter: "Madhupur_Baramsoli_DPP (Jharkhand)",
        FacilityCity: "Madhupur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17512,
        Pin: 785633,
        DispatchCenter: "Jorhat_Titabor_D (Assam)",
        OriginCenter: "Jorhat_Titabor_D (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17513,
        Pin: 721659,
        DispatchCenter: "Bajkul_Kismat_D (West Bengal)",
        OriginCenter: "Bajkul_Kismat_D (West Bengal)",
        FacilityCity: "Bajkul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17514,
        Pin: 695104,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17515,
        Pin: 781026,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17516,
        Pin: 333026,
        DispatchCenter: "Pilani (Rajasthan)",
        OriginCenter: "Pilani (Rajasthan)",
        FacilityCity: "Pilani",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17517,
        Pin: 230133,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17518,
        Pin: 741501,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17519,
        Pin: 573213,
        DispatchCenter: "Hassan_Nagathavalli_I (Karnataka)",
        OriginCenter: "Hassan_Nagathavalli_I (Karnataka)",
        FacilityCity: "Hassan",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17520,
        Pin: 786182,
        DispatchCenter: "Margherita_CivilHPL_D (Assam)",
        OriginCenter: "Margherita_CivilHPL_D (Assam)",
        FacilityCity: "Margherita",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17521,
        Pin: 383001,
        DispatchCenter: "Himmatnagar_Hadiyol_D (Gujarat)",
        OriginCenter: "Himmatnagar_Hadiyol_D (Gujarat)",
        FacilityCity: "Himmatnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 17522,
        Pin: 151209,
        DispatchCenter: "Kotkapura_PremNgr_D (Punjab)",
        OriginCenter: "Kotkapura_PremNgr_D (Punjab)",
        FacilityCity: "Kotkapura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17523,
        Pin: 184210,
        DispatchCenter: "Billawar_Chohan_DPP (Jammu & Kashmir)",
        OriginCenter: "Billawar_Chohan_DPP (Jammu & Kashmir)",
        FacilityCity: "Billawar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17524,
        Pin: 629202,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17525,
        Pin: 284202,
        DispatchCenter: "Chirgaon_RamnagarRoad_D (Uttar Pradesh)",
        OriginCenter: "Chirgaon_RamnagarRoad_D (Uttar Pradesh)",
        FacilityCity: "Chirgaon",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17526,
        Pin: 424107,
        DispatchCenter: "Pachora_Kajgaon_DPP (Maharashtra)",
        OriginCenter: "Pachora_Kajgaon_DPP (Maharashtra)",
        FacilityCity: "Chalisgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17527,
        Pin: 623802,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17528,
        Pin: 735303,
        DispatchCenter: "Mathabhanga_Pachagarh_D (West Bengal)",
        OriginCenter: "Mathabhanga_Pachagarh_D (West Bengal)",
        FacilityCity: "Mathabhanga",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17529,
        Pin: 621002,
        DispatchCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        OriginCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        FacilityCity: "Thuraiyur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17530,
        Pin: 673006,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17531,
        Pin: 500051,
        DispatchCenter: "Hyderabad_HemaNagar_L (Telangana)",
        OriginCenter: "Hyderabad_HemaNagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17532,
        Pin: 326034,
        DispatchCenter: "BhwniMndi_RmngrDPP_D (Rajasthan)",
        OriginCenter: "BhwniMndi_RmngrDPP_D (Rajasthan)",
        FacilityCity: "Bhawani Mandi",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17533,
        Pin: 143202,
        DispatchCenter: "Tarsikka_Beas_D (Punjab)",
        OriginCenter: "Tarsikka_Beas_D (Punjab)",
        FacilityCity: "Beas",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17534,
        Pin: 626130,
        DispatchCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        OriginCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        FacilityCity: "Sivakasi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17535,
        Pin: 382430,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17536,
        Pin: 382321,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17537,
        Pin: 560095,
        DispatchCenter: "Bangalore_Temp1_L (Karnataka)",
        OriginCenter: "Bangalore_Temp1_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17538,
        Pin: 415119,
        DispatchCenter: "Karad_Malkapur1_D (Maharashtra)",
        OriginCenter: "Karad_Malkapur1_D (Maharashtra)",
        FacilityCity: "Karad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17539,
        Pin: 506145,
        DispatchCenter: "Ghanpur_Palakrty_D (Telangana)",
        OriginCenter: "Ghanpur_Palakrty_D (Telangana)",
        FacilityCity: "Ghanpur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17540,
        Pin: 577538,
        DispatchCenter: "Challakere_Hosmane_D (Karnataka)",
        OriginCenter: "Challakere_Hosmane_D (Karnataka)",
        FacilityCity: "Challakere",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17541,
        Pin: 577550,
        DispatchCenter: "Kadur_Ajjampura_D (Karnataka)",
        OriginCenter: "Kadur_Ajjampura_D (Karnataka)",
        FacilityCity: "Kadur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17542,
        Pin: 700022,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17543,
        Pin: 222204,
        DispatchCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        OriginCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        FacilityCity: "Mungra Badshahpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17544,
        Pin: 251305,
        DispatchCenter: "Budhana_BhagatSinghPark_D (Uttar Pradesh)",
        OriginCenter: "Budhana_BhagatSinghPark_D (Uttar Pradesh)",
        FacilityCity: "Budhana",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17545,
        Pin: 755007,
        DispatchCenter: "Jajpur_Panasa_D (Orissa)",
        OriginCenter: "Jajpur_Panasa_D (Orissa)",
        FacilityCity: "Jajpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17546,
        Pin: 851129,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17547,
        Pin: 207402,
        DispatchCenter: "Kasganj_GandhiKunj_D (Uttar Pradesh)",
        OriginCenter: "Kasganj_GandhiKunj_D (Uttar Pradesh)",
        FacilityCity: "Kasganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17548,
        Pin: 110082,
        DispatchCenter: "Delhi_Libaspur_L (Delhi)",
        OriginCenter: "Delhi_Libaspur_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17549,
        Pin: 331029,
        DispatchCenter: "Taranagar_PoliceStation_DPP (Rajasthan)",
        OriginCenter: "Taranagar_PoliceStation_DPP (Rajasthan)",
        FacilityCity: "Taranagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17550,
        Pin: 741245,
        DispatchCenter: "Naihati_Kalyani_DPP (West Bengal)",
        OriginCenter: "Naihati_Kalyani_DPP (West Bengal)",
        FacilityCity: "Kalyani",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17551,
        Pin: 176207,
        DispatchCenter: "Sihunta_Dramman_DPP (Himachal Pradesh)",
        OriginCenter: "Sihunta_Dramman_DPP (Himachal Pradesh)",
        FacilityCity: "Sihunta",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17552,
        Pin: 143004,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17553,
        Pin: 135021,
        DispatchCenter: "Yamunanagar_Balachaur_D (Haryana)",
        OriginCenter: "Yamunanagar_Balachaur_D (Haryana)",
        FacilityCity: "Yamuna Nagar",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17554,
        Pin: 442005,
        DispatchCenter: "Wardha_Alodi_D (Maharashtra)",
        OriginCenter: "Wardha_Alodi_D (Maharashtra)",
        FacilityCity: "Wardha",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17555,
        Pin: 497333,
        DispatchCenter: "Premnagar_Sadakpara_D (Chhattisgarh)",
        OriginCenter: "Premnagar_Sadakpara_D (Chhattisgarh)",
        FacilityCity: "Premnagar",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17556,
        Pin: 451660,
        DispatchCenter: "Thikri_DrbrVilla_DPP (Madhya Pradesh)",
        OriginCenter: "Thikri_DrbrVilla_DPP (Madhya Pradesh)",
        FacilityCity: "Thikri",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17557,
        Pin: 753012,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17558,
        Pin: 412408,
        DispatchCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        OriginCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        FacilityCity: "Ambegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17559,
        Pin: 307512,
        DispatchCenter: "Reodar_KarotiChorha_DPP (Rajasthan)",
        OriginCenter: "Reodar_KarotiChorha_DPP (Rajasthan)",
        FacilityCity: "Reodar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17560,
        Pin: 785664,
        DispatchCenter: "Sivasagar_Pdinnagar_D (Assam)",
        OriginCenter: "Sivasagar_Pdinnagar_D (Assam)",
        FacilityCity: "Sivasagar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17561,
        Pin: 442701,
        DispatchCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        OriginCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        FacilityCity: "Chandrapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17562,
        Pin: 136020,
        DispatchCenter: "Kaithal_DefenceColony_D (Haryana)",
        OriginCenter: "Kaithal_DefenceColony_D (Haryana)",
        FacilityCity: "Kaithal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17563,
        Pin: 301002,
        DispatchCenter: "Alwar_TuleraRoad_I (Rajasthan)",
        OriginCenter: "Alwar_TuleraRoad_I (Rajasthan)",
        FacilityCity: "Alwar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17564,
        Pin: 334004,
        DispatchCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        OriginCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        FacilityCity: "Bikaner",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17565,
        Pin: 783360,
        DispatchCenter: "Gossaigaon_TnaliDPP_D (Assam)",
        OriginCenter: "Gossaigaon_TnaliDPP_D (Assam)",
        FacilityCity: "Gossaigaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17566,
        Pin: 110511,
        DispatchCenter: "Delhi_RailYardRd_L (Delhi)",
        OriginCenter: "Delhi_RailYardRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17567,
        Pin: 505185,
        DispatchCenter: "KarimNagar_Alugunoor_I (Telangana)",
        OriginCenter: "KarimNagar_Alugunoor_I (Telangana)",
        FacilityCity: "Karim Nagar",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17568,
        Pin: 673125,
        DispatchCenter: "SultanBathery_Kolagapara_D (Kerala)",
        OriginCenter: "SultanBathery_Kolagapara_D (Kerala)",
        FacilityCity: "Sultan Bathery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17569,
        Pin: 627956,
        DispatchCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        OriginCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        FacilityCity: "Sankarankovil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17570,
        Pin: 613601,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17571,
        Pin: 473446,
        DispatchCenter: "Chanderi_ShreeKunj_DPP (Madhya Pradesh)",
        OriginCenter: "Chanderi_ShreeKunj_DPP (Madhya Pradesh)",
        FacilityCity: "Chanderi",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 17572,
        Pin: 761105,
        DispatchCenter: "Kodala_Lunighati_DPP (Orissa)",
        OriginCenter: "Kodala_Lunighati_DPP (Orissa)",
        FacilityCity: "Polasara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17573,
        Pin: 271805,
        DispatchCenter: "Ikauna_Hndutmple_D (Uttar Pradesh)",
        OriginCenter: "Ikauna_Hndutmple_D (Uttar Pradesh)",
        FacilityCity: "Ikauna",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17574,
        Pin: 638315,
        DispatchCenter: "Anthiyur_Thavittuplym_DPP (Tamil Nadu)",
        OriginCenter: "Anthiyur_Thavittuplym_DPP (Tamil Nadu)",
        FacilityCity: "Anthiyour",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17575,
        Pin: 177105,
        DispatchCenter: "DehraGopipur_Central_D_1 (Himachal Pradesh)",
        OriginCenter: "DehraGopipur_Central_D_1 (Himachal Pradesh)",
        FacilityCity: "Dehra Gopipur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17576,
        Pin: 110099,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17577,
        Pin: 591136,
        DispatchCenter: "Mudhol_Maharancol_D (Karnataka)",
        OriginCenter: "Mudhol_Maharancol_D (Karnataka)",
        FacilityCity: "Mudhol",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17578,
        Pin: 482009,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17579,
        Pin: 403103,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17580,
        Pin: 758015,
        DispatchCenter: "Anandapur_Ghasipura_D (Orissa)",
        OriginCenter: "Anandapur_Ghasipura_D (Orissa)",
        FacilityCity: "Anandapur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17581,
        Pin: 387100,
        DispatchCenter: "Nadiad_Shantinagar_D (Gujarat)",
        OriginCenter: "Nadiad_Shantinagar_D (Gujarat)",
        FacilityCity: "Nadiad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 17582,
        Pin: 741137,
        DispatchCenter: "Nakashipara_Kanthalbaria_DPP (West Bengal)",
        OriginCenter: "Nakashipara_Kanthalbaria_DPP (West Bengal)",
        FacilityCity: "Nakashipara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17583,
        Pin: 624010,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17584,
        Pin: 782411,
        DispatchCenter: "Morigaon_Nakhulagrant_D (Assam)",
        OriginCenter: "Morigaon_Nakhulagrant_D (Assam)",
        FacilityCity: "Morigaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17585,
        Pin: 792122,
        DispatchCenter: "Kharsang_GeoEnproRd_D (Arunachal Pradesh)",
        OriginCenter: "Kharsang_GeoEnproRd_D (Arunachal Pradesh)",
        FacilityCity: "Kharsang",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17586,
        Pin: 481672,
        DispatchCenter: "Shahpura_Mehandwani1_DPP (Madhya Pradesh)",
        OriginCenter: "Shahpura_Mehandwani1_DPP (Madhya Pradesh)",
        FacilityCity: "ShahpuraMP",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17587,
        Pin: 190004,
        DispatchCenter: "Srinagar_Ladhoo_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Ladhoo_H (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17588,
        Pin: 802207,
        DispatchCenter: "Piro_Bachri_D (Bihar)",
        OriginCenter: "Piro_Bachri_D (Bihar)",
        FacilityCity: "Piro",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17589,
        Pin: 410405,
        DispatchCenter: "Lonavala_Maval_D (Maharashtra)",
        OriginCenter: "Lonavala_Maval_D (Maharashtra)",
        FacilityCity: "Lonavala",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17590,
        Pin: 766017,
        DispatchCenter: "JunagarhOR_Santinagar_D (Orissa)",
        OriginCenter: "JunagarhOR_Santinagar_D (Orissa)",
        FacilityCity: "Junagarh-OR",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17591,
        Pin: 602109,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17592,
        Pin: 680319,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17593,
        Pin: 690108,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17594,
        Pin: 241125,
        DispatchCenter: "Shahabad_Jogipur_D (Uttar Pradesh)",
        OriginCenter: "Shahabad_Jogipur_D (Uttar Pradesh)",
        FacilityCity: "Shahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17595,
        Pin: 638460,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17596,
        Pin: 741257,
        DispatchCenter: "Naihati_Kalyani_DPP (West Bengal)",
        OriginCenter: "Naihati_Kalyani_DPP (West Bengal)",
        FacilityCity: "Kalyani",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17597,
        Pin: 445105,
        DispatchCenter: "Arni_Bhimnagar_DPP (Maharashtra)",
        OriginCenter: "Arni_Bhimnagar_DPP (Maharashtra)",
        FacilityCity: "Arni",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17598,
        Pin: 147003,
        DispatchCenter: "Patiala_UpkarNagar_D (Punjab)",
        OriginCenter: "Patiala_UpkarNagar_D (Punjab)",
        FacilityCity: "Patiala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17599,
        Pin: 307022,
        DispatchCenter: "Pindwara_AjariChwk_DPP (Rajasthan)",
        OriginCenter: "Pindwara_AjariChwk_DPP (Rajasthan)",
        FacilityCity: "Pindwara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17600,
        Pin: 581145,
        DispatchCenter: "Ranebennur_MrthnjyaNgr_D (Karnataka)",
        OriginCenter: "Ranebennur_MrthnjyaNgr_D (Karnataka)",
        FacilityCity: "Ranebennur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17601,
        Pin: 534001,
        DispatchCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        OriginCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        FacilityCity: "Eluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17602,
        Pin: 813112,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17603,
        Pin: 754113,
        DispatchCenter: "Balikuda_Balisahi_D (Orissa)",
        OriginCenter: "Balikuda_Balisahi_D (Orissa)",
        FacilityCity: "Balikuda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17604,
        Pin: 581402,
        DispatchCenter: "Sirsi_Kasturbanagar_D (Karnataka)",
        OriginCenter: "Sirsi_Kasturbanagar_D (Karnataka)",
        FacilityCity: "Sirsi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17605,
        Pin: 184201,
        DispatchCenter: "Pathankot_Dunera_DPP (Punjab)",
        OriginCenter: "Pathankot_Dunera_DPP (Punjab)",
        FacilityCity: "Pathankot",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17606,
        Pin: 609204,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17607,
        Pin: 628716,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17608,
        Pin: 341024,
        DispatchCenter: "Nagaur_Kuchera_D (Rajasthan)",
        OriginCenter: "Nagaur_Kuchera_D (Rajasthan)",
        FacilityCity: "Nagaur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17609,
        Pin: 829111,
        DispatchCenter: "Phusro_Gumia_DPP (Jharkhand)",
        OriginCenter: "Phusro_Gumia_DPP (Jharkhand)",
        FacilityCity: "Phusro",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17610,
        Pin: 765002,
        DispatchCenter: "Raygada_GandhiNgr_DPP (Orissa)",
        OriginCenter: "Raygada_GandhiNgr_DPP (Orissa)",
        FacilityCity: "Raygada",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17611,
        Pin: 680609,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17612,
        Pin: 576226,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17613,
        Pin: 688502,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17614,
        Pin: 515303,
        DispatchCenter: "Madakasira_RTCStand_D (Andhra Pradesh)",
        OriginCenter: "Madakasira_RTCStand_D (Andhra Pradesh)",
        FacilityCity: "Madakasira",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17615,
        Pin: 362250,
        DispatchCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        OriginCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        FacilityCity: "Keshod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 17616,
        Pin: 531085,
        DispatchCenter: "Narsipatnam_Sivapuram_D (Andhra Pradesh)",
        OriginCenter: "Narsipatnam_Sivapuram_D (Andhra Pradesh)",
        FacilityCity: "Narsipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17617,
        Pin: 643240,
        DispatchCenter: "Gudalur_Devarshola_DPP (Tamil Nadu)",
        OriginCenter: "Gudalur_Devarshola_DPP (Tamil Nadu)",
        FacilityCity: "Gudalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17618,
        Pin: 182161,
        DispatchCenter: "Chenani_Ward3_D (Jammu & Kashmir)",
        OriginCenter: "Chenani_Ward3_D (Jammu & Kashmir)",
        FacilityCity: "Chenani",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17619,
        Pin: 440006,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17620,
        Pin: 144008,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17621,
        Pin: 344502,
        DispatchCenter: "Ramsar_Jangidcolony_DPP (Rajasthan)",
        OriginCenter: "Ramsar_Jangidcolony_DPP (Rajasthan)",
        FacilityCity: "Ramsar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17622,
        Pin: 521344,
        DispatchCenter: "Kaikaluru_Gonepadu_D (Andhra Pradesh)",
        OriginCenter: "Kaikaluru_Gonepadu_D (Andhra Pradesh)",
        FacilityCity: "Kaikaluru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17623,
        Pin: 470004,
        DispatchCenter: "Sagar_Pagara_P (Madhya Pradesh)",
        OriginCenter: "Sagar_Pagara_P (Madhya Pradesh)",
        FacilityCity: "Sagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17624,
        Pin: 121009,
        DispatchCenter: "Faridabad_MathuraRoad_L (Haryana)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17625,
        Pin: 176204,
        DispatchCenter: "Palion_MndiPthankotRd_DPP (Himachal Pradesh)",
        OriginCenter: "Palion_MndiPthankotRd_DPP (Himachal Pradesh)",
        FacilityCity: "Palion",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17626,
        Pin: 845416,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17627,
        Pin: 516391,
        DispatchCenter: "Pulivendula_Bakarapuram_D (Andhra Pradesh)",
        OriginCenter: "Pulivendula_Bakarapuram_D (Andhra Pradesh)",
        FacilityCity: "Pulivendula",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17628,
        Pin: 614612,
        DispatchCenter: "Peravurani_Nattanikkottai_D (Tamil Nadu)",
        OriginCenter: "Peravurani_Nattanikkottai_D (Tamil Nadu)",
        FacilityCity: "Peravurani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17629,
        Pin: 576229,
        DispatchCenter: "Kundapura_Shankarnarayana_D (Karnataka)",
        OriginCenter: "Kundapura_Shankarnarayana_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17630,
        Pin: 632002,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17631,
        Pin: 632003,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17632,
        Pin: 277402,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17633,
        Pin: 146023,
        DispatchCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        OriginCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17634,
        Pin: 627051,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17635,
        Pin: 828302,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17636,
        Pin: 341504,
        DispatchCenter: "Degana_GovtHospital_D (Rajasthan)",
        OriginCenter: "Degana_GovtHospital_D (Rajasthan)",
        FacilityCity: "Degana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17637,
        Pin: 571610,
        DispatchCenter: "Krishnarajngr_VlmkiNgr_D (Karnataka)",
        OriginCenter: "Krishnarajngr_VlmkiNgr_D (Karnataka)",
        FacilityCity: "Krishnarajngr",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17638,
        Pin: 227807,
        DispatchCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        OriginCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        FacilityCity: "Amethi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17639,
        Pin: 180004,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17640,
        Pin: 394190,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17641,
        Pin: 686001,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17642,
        Pin: 722204,
        DispatchCenter: "Barjora_PoliceStn_DPP (West Bengal)",
        OriginCenter: "Barjora_PoliceStn_DPP (West Bengal)",
        FacilityCity: "Barjora",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17643,
        Pin: 361006,
        DispatchCenter: "Jamnagar_Chela_L (Gujarat)",
        OriginCenter: "Jamnagar_Chela_L (Gujarat)",
        FacilityCity: "Jamnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17644,
        Pin: 462014,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 17645,
        Pin: 202004,
        DispatchCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        OriginCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17646,
        Pin: 691307,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17647,
        Pin: 160011,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17648,
        Pin: 333030,
        DispatchCenter: "Pilani (Rajasthan)",
        OriginCenter: "Pilani (Rajasthan)",
        FacilityCity: "Pilani",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17649,
        Pin: 631211,
        DispatchCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17650,
        Pin: 624614,
        DispatchCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        OriginCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        FacilityCity: "Oddanchatram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17651,
        Pin: 638401,
        DispatchCenter: "Sathyamangalam_VNSNagar_D (Tamil Nadu)",
        OriginCenter: "Sathyamangalam_VNSNagar_D (Tamil Nadu)",
        FacilityCity: "Sathyamangalam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17652,
        Pin: 148004,
        DispatchCenter: "Sangrur_DC (Punjab)",
        OriginCenter: "Sangrur_DC (Punjab)",
        FacilityCity: "Sangrur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17653,
        Pin: 700136,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17654,
        Pin: 465113,
        DispatchCenter: "PolayKalan_Himmatpura_DPP (Madhya Pradesh)",
        OriginCenter: "PolayKalan_Himmatpura_DPP (Madhya Pradesh)",
        FacilityCity: "Polay Kalan",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17655,
        Pin: 533343,
        DispatchCenter: "Bikkavolu_Balabhadhrapuram_D (Andhra Pradesh)",
        OriginCenter: "Bikkavolu_Balabhadhrapuram_D (Andhra Pradesh)",
        FacilityCity: "Bikkavolu",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17656,
        Pin: 680689,
        DispatchCenter: "Chalakudy_ForestChowk_D (Kerala)",
        OriginCenter: "Chalakudy_ForestChowk_D (Kerala)",
        FacilityCity: "Chalakudy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17657,
        Pin: 713160,
        DispatchCenter: "Dubrajpur_Pandaveswar_D (West Bengal)",
        OriginCenter: "Dubrajpur_Pandaveswar_D (West Bengal)",
        FacilityCity: "Dubrajpur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17658,
        Pin: 403502,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17659,
        Pin: 342902,
        DispatchCenter: "Gotan_Jkroad_DPP (Rajasthan)",
        OriginCenter: "Gotan_Jkroad_DPP (Rajasthan)",
        FacilityCity: "Gotan",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17660,
        Pin: 491336,
        DispatchCenter: "Kawardha_NndivhrClny_D (Chhattisgarh)",
        OriginCenter: "Kawardha_NndivhrClny_D (Chhattisgarh)",
        FacilityCity: "Kawardha",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17661,
        Pin: 518021,
        DispatchCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        OriginCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17662,
        Pin: 144221,
        DispatchCenter: "Dasuya_Hajipur_D (Punjab)",
        OriginCenter: "Dasuya_Hajipur_D (Punjab)",
        FacilityCity: "Dasuya",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17663,
        Pin: 382418,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17664,
        Pin: 614714,
        DispatchCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        FacilityCity: "Thiruthuraipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17665,
        Pin: 686695,
        DispatchCenter: "Perumbavoor_Kizhake_D (Kerala)",
        OriginCenter: "Perumbavoor_Kizhake_D (Kerala)",
        FacilityCity: "Perumbavoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17666,
        Pin: 752035,
        DispatchCenter: "Balugaon_Oldasianhighwy_D (Orissa)",
        OriginCenter: "Balugaon_Oldasianhighwy_D (Orissa)",
        FacilityCity: "Balugaon",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17667,
        Pin: 276304,
        DispatchCenter: "Azamgarh_Saraimeer_DPP (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Saraimeer_DPP (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17668,
        Pin: 502321,
        DispatchCenter: "Zahirabad_Mohim_D (Telangana)",
        OriginCenter: "Zahirabad_Mohim_D (Telangana)",
        FacilityCity: "Zahirabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17669,
        Pin: 530001,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17670,
        Pin: 734425,
        DispatchCenter: "Bagdogra_Kadopani_D (West Bengal)",
        OriginCenter: "Bagdogra_Kadopani_D (West Bengal)",
        FacilityCity: "Bagdogra",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17671,
        Pin: 334302,
        DispatchCenter: "Kolayat_Ward3_D (Rajasthan)",
        OriginCenter: "Kolayat_Ward3_D (Rajasthan)",
        FacilityCity: "Kolayat",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17672,
        Pin: 755026,
        DispatchCenter: "Jajpur_Chandama_D (Orissa)",
        OriginCenter: "Jajpur_Chandama_D (Orissa)",
        FacilityCity: "Jajpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17673,
        Pin: 628206,
        DispatchCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        OriginCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        FacilityCity: "Tiruchendur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17674,
        Pin: 521225,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17675,
        Pin: 176401,
        DispatchCenter: "SurajpurJhikla_IndoraMore_DPP (Himachal Pradesh)",
        OriginCenter: "SurajpurJhikla_IndoraMore_DPP (Himachal Pradesh)",
        FacilityCity: "Surajpur jhikla",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17676,
        Pin: 453441,
        DispatchCenter: "Pithampur_Ambedkarnagar_L (Madhya Pradesh)",
        OriginCenter: "Pithampur_Ambedkarnagar_L (Madhya Pradesh)",
        FacilityCity: "Pithampur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17677,
        Pin: 757104,
        DispatchCenter: "Udala_Mayurbhanj_DPP (Orissa)",
        OriginCenter: "Udala_Mayurbhanj_DPP (Orissa)",
        FacilityCity: "Udala",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17678,
        Pin: 695524,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17679,
        Pin: 415722,
        DispatchCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        OriginCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        FacilityCity: "Khed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17680,
        Pin: 415413,
        DispatchCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        OriginCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        FacilityCity: "Islampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17681,
        Pin: 174304,
        DispatchCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        OriginCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        FacilityCity: "Bangana",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17682,
        Pin: 814149,
        DispatchCenter: "Madhupur_Sitarmrd_D (Jharkhand)",
        OriginCenter: "Madhupur_Sitarmrd_D (Jharkhand)",
        FacilityCity: "Madhupur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17683,
        Pin: 228411,
        DispatchCenter: "Lalgopalganj_Anandi_D (Uttar Pradesh)",
        OriginCenter: "Lalgopalganj_Anandi_D (Uttar Pradesh)",
        FacilityCity: "Lal Gopalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17684,
        Pin: 132104,
        DispatchCenter: "Panipat_Risalu_P (Haryana)",
        OriginCenter: "Panipat_Risalu_P (Haryana)",
        FacilityCity: "Panipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17685,
        Pin: 690561,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17686,
        Pin: 210123,
        DispatchCenter: "Fatehpur_Gandhinagar_D (Uttar Pradesh)",
        OriginCenter: "Fatehpur_Gandhinagar_D (Uttar Pradesh)",
        FacilityCity: "Fatehpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17687,
        Pin: 753011,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17688,
        Pin: 673017,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17689,
        Pin: 392011,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17690,
        Pin: 461116,
        DispatchCenter: "Hoshangabad_BabaiRd_DPP (Madhya Pradesh)",
        OriginCenter: "Hoshangabad_BabaiRd_DPP (Madhya Pradesh)",
        FacilityCity: "Hoshangabad",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 17691,
        Pin: 274208,
        DispatchCenter: "Barhalganj_Lakhnapar_DPP (Uttar Pradesh)",
        OriginCenter: "Barhalganj_Lakhnapar_DPP (Uttar Pradesh)",
        FacilityCity: "Barhalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17692,
        Pin: 284003,
        DispatchCenter: "Jhansi_Nandanpura_I (Uttar Pradesh)",
        OriginCenter: "Jhansi_Nandanpura_I (Uttar Pradesh)",
        FacilityCity: "Jhansi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17693,
        Pin: 335707,
        DispatchCenter: "Gharsana_Jaatcolony_D (Rajasthan)",
        OriginCenter: "Gharsana_Jaatcolony_D (Rajasthan)",
        FacilityCity: "Gharsana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17694,
        Pin: 712246,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17695,
        Pin: 242127,
        DispatchCenter: "Jalalabad_Bareillyroad_D (Uttar Pradesh)",
        OriginCenter: "Jalalabad_Bareillyroad_D (Uttar Pradesh)",
        FacilityCity: "JalalabadUP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17696,
        Pin: 682025,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17697,
        Pin: 752017,
        DispatchCenter: "Nirakarpur_Rameswar_D (Orissa)",
        OriginCenter: "Nirakarpur_Rameswar_D (Orissa)",
        FacilityCity: "Nirakarpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17698,
        Pin: 533341,
        DispatchCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17699,
        Pin: 384345,
        DispatchCenter: "Vadnagar_BsstdDPP_D (Gujarat)",
        OriginCenter: "Vadnagar_BsstdDPP_D (Gujarat)",
        FacilityCity: "Vadnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17700,
        Pin: 360550,
        DispatchCenter: "Porbandar_Vadiplot_D (Gujarat)",
        OriginCenter: "Porbandar_Vadiplot_D (Gujarat)",
        FacilityCity: "Porbandar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 17701,
        Pin: 741101,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17702,
        Pin: 110106,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17703,
        Pin: 636451,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17704,
        Pin: 571429,
        DispatchCenter: "Maddur_Malavalli_D (Karnataka)",
        OriginCenter: "Maddur_Malavalli_D (Karnataka)",
        FacilityCity: "Maddur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17705,
        Pin: 641405,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17706,
        Pin: 522007,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17707,
        Pin: 577232,
        DispatchCenter: "Thirthahalli_Bejjavali2_DPP (Karnataka)",
        OriginCenter: "Thirthahalli_Bejjavali2_DPP (Karnataka)",
        FacilityCity: "Thirthahalli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17708,
        Pin: 431114,
        DispatchCenter: "Sillod_ZebaTWR_D (Maharashtra)",
        OriginCenter: "Sillod_ZebaTWR_D (Maharashtra)",
        FacilityCity: "Sillod",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17709,
        Pin: 523367,
        DispatchCenter: "Giddalur_Nandyalroad_D (Andhra Pradesh)",
        OriginCenter: "Giddalur_Nandyalroad_D (Andhra Pradesh)",
        FacilityCity: "Giddalur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17710,
        Pin: 843121,
        DispatchCenter: "Dholi_WardNo4_D (Bihar)",
        OriginCenter: "Dholi_WardNo4_D (Bihar)",
        FacilityCity: "Dholi",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17711,
        Pin: 228151,
        DispatchCenter: "Kumarganj_Siddhiganeshpur_D (Uttar Pradesh)",
        OriginCenter: "Kumarganj_Siddhiganeshpur_D (Uttar Pradesh)",
        FacilityCity: "Kumarganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17712,
        Pin: 516004,
        DispatchCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17713,
        Pin: 602001,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17714,
        Pin: 415524,
        DispatchCenter: "Koregaon_Lokmanyanagar_D (Maharashtra)",
        OriginCenter: "Koregaon_Lokmanyanagar_D (Maharashtra)",
        FacilityCity: "Koregaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17715,
        Pin: 532284,
        DispatchCenter: "Sompeta_PostOffice_DPP (Andhra Pradesh)",
        OriginCenter: "Sompeta_PostOffice_DPP (Andhra Pradesh)",
        FacilityCity: "Sompeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17716,
        Pin: 614803,
        DispatchCenter: "Peravurani_Nattanikkottai_D (Tamil Nadu)",
        OriginCenter: "Peravurani_Nattanikkottai_D (Tamil Nadu)",
        FacilityCity: "Peravurani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17717,
        Pin: 574218,
        DispatchCenter: "Sullia_Kanthamangala_D (Karnataka)",
        OriginCenter: "Sullia_Kanthamangala_D (Karnataka)",
        FacilityCity: "Sullia",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17718,
        Pin: 841234,
        DispatchCenter: "Siwan_Andar_D (Bihar)",
        OriginCenter: "Siwan_Andar_D (Bihar)",
        FacilityCity: "Siwan",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17719,
        Pin: 583105,
        DispatchCenter: "Bellary_Mundargi_I (Karnataka)",
        OriginCenter: "Bellary_Mundargi_I (Karnataka)",
        FacilityCity: "Bellary",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17720,
        Pin: 742152,
        DispatchCenter: "Murshidabad_Jiaganj_D (West Bengal)",
        OriginCenter: "Murshidabad_Jiaganj_D (West Bengal)",
        FacilityCity: "Murshidabad",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17721,
        Pin: 205268,
        DispatchCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        OriginCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        FacilityCity: "Mainpuri",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17722,
        Pin: 391740,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17723,
        Pin: 768217,
        DispatchCenter: "Brajrajnagar_Jagannath_DPP (Orissa)",
        OriginCenter: "Brajrajnagar_Jagannath_DPP (Orissa)",
        FacilityCity: "Brajrajnagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17724,
        Pin: 441908,
        DispatchCenter: "Pauni_Wahi_D (Maharashtra)",
        OriginCenter: "Pauni_Wahi_D (Maharashtra)",
        FacilityCity: "Pauni",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17725,
        Pin: 515731,
        DispatchCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        OriginCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17726,
        Pin: 828135,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17727,
        Pin: 794109,
        DispatchCenter: "Tura_Jengjal_D (Meghalaya)",
        OriginCenter: "Tura_Jengjal_D (Meghalaya)",
        FacilityCity: "Tura",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17728,
        Pin: 741507,
        DispatchCenter: "Hanskhali_Ashrampara_D (West Bengal)",
        OriginCenter: "Hanskhali_Ashrampara_D (West Bengal)",
        FacilityCity: "Hanskhali",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17729,
        Pin: 229416,
        DispatchCenter: "Kunda_KaretiRd_D (Uttar Pradesh)",
        OriginCenter: "Kunda_KaretiRd_D (Uttar Pradesh)",
        FacilityCity: "Kunda",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17730,
        Pin: 590015,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17731,
        Pin: 471301,
        DispatchCenter: "Chhatarpur_Bijawar_DPP (Madhya Pradesh)",
        OriginCenter: "Chhatarpur_Bijawar_DPP (Madhya Pradesh)",
        FacilityCity: "Malhera",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17732,
        Pin: 341302,
        DispatchCenter: "KhatuKhurd_MakranaRd_D (Rajasthan)",
        OriginCenter: "KhatuKhurd_MakranaRd_D (Rajasthan)",
        FacilityCity: "Khatu Khurd",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17733,
        Pin: 713211,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17734,
        Pin: 271502,
        DispatchCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        OriginCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        FacilityCity: "Colonejganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17735,
        Pin: 225205,
        DispatchCenter: "Ramnagar_Burhwal_D (Uttar Pradesh)",
        OriginCenter: "Ramnagar_Burhwal_D (Uttar Pradesh)",
        FacilityCity: "Ramnagar-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17736,
        Pin: 507301,
        DispatchCenter: "Aswaraopeta_Asrnagar_D (Telangana)",
        OriginCenter: "Aswaraopeta_Asrnagar_D (Telangana)",
        FacilityCity: "Aswaraopeta",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17737,
        Pin: 601202,
        DispatchCenter: "Gummudipoondi_Thandalam_D (Tamil Nadu)",
        OriginCenter: "Gummudipoondi_Thandalam_D (Tamil Nadu)",
        FacilityCity: "Gummudipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17738,
        Pin: 176092,
        DispatchCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        OriginCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        FacilityCity: "Palampur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17739,
        Pin: 587118,
        DispatchCenter: "Ilkal_Ward4_D (Karnataka)",
        OriginCenter: "Ilkal_Ward4_D (Karnataka)",
        FacilityCity: "Ilkal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17740,
        Pin: 491227,
        DispatchCenter: "Dhamtari_VasiniWard_D (Chhattisgarh)",
        OriginCenter: "Dhamtari_VasiniWard_D (Chhattisgarh)",
        FacilityCity: "Dhamtari",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17741,
        Pin: 262521,
        DispatchCenter: "Pithorgarh_Kumud_D (Uttarakhand)",
        OriginCenter: "Pithorgarh_Kumud_D (Uttarakhand)",
        FacilityCity: "Pithoragarh",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17742,
        Pin: 587102,
        DispatchCenter: "Bagalkot_NavaNgr_D (Karnataka)",
        OriginCenter: "Bagalkot_NavaNgr_D (Karnataka)",
        FacilityCity: "Bagalkot",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17743,
        Pin: 844126,
        DispatchCenter: "Hazratjandaha_Hirpur_D (Bihar)",
        OriginCenter: "Hazratjandaha_Hirpur_D (Bihar)",
        FacilityCity: "Hazrat Jandaha",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17744,
        Pin: 560047,
        DispatchCenter: "Bangalore_Temp1_L (Karnataka)",
        OriginCenter: "Bangalore_Temp1_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17745,
        Pin: 785702,
        DispatchCenter: "Golaghat_BaruaRd_D (Assam)",
        OriginCenter: "Golaghat_BaruaRd_D (Assam)",
        FacilityCity: "Golaghat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17746,
        Pin: 522256,
        DispatchCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        OriginCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        FacilityCity: "Repalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17747,
        Pin: 332301,
        DispatchCenter: "Fatehpur-RJ_Sekhisar_DPP (Rajasthan)",
        OriginCenter: "Fatehpur-RJ_Sekhisar_DPP (Rajasthan)",
        FacilityCity: "Fatehpur-RJ",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17748,
        Pin: 679554,
        DispatchCenter: "Edappal_Nellissery_D (Kerala)",
        OriginCenter: "Edappal_Nellissery_D (Kerala)",
        FacilityCity: "Edappal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17749,
        Pin: 686616,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17750,
        Pin: 517502,
        DispatchCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        OriginCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        FacilityCity: "Tirupati",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17751,
        Pin: 503185,
        DispatchCenter: "Bodhan_VamshiSchl_D (Telangana)",
        OriginCenter: "Bodhan_VamshiSchl_D (Telangana)",
        FacilityCity: "Bodhan",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17752,
        Pin: 246127,
        DispatchCenter: "Kotdwara_GovindNagar_D (Uttarakhand)",
        OriginCenter: "Kotdwara_GovindNagar_D (Uttarakhand)",
        FacilityCity: "Kotdwara",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17753,
        Pin: 443405,
        DispatchCenter: "Nandura_Qureshinagar_DPP (Maharashtra)",
        OriginCenter: "Nandura_Qureshinagar_DPP (Maharashtra)",
        FacilityCity: "Nandura",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17754,
        Pin: 604405,
        DispatchCenter: "Vandavasi_SaltStreet_D (Tamil Nadu)",
        OriginCenter: "Vandavasi_SaltStreet_D (Tamil Nadu)",
        FacilityCity: "Vandavasi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17755,
        Pin: 416123,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17756,
        Pin: 629165,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17757,
        Pin: 612603,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17758,
        Pin: 628618,
        DispatchCenter: "Eral_Nazareth_D (Tamil Nadu)",
        OriginCenter: "Eral_Nazareth_D (Tamil Nadu)",
        FacilityCity: "Eral",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17759,
        Pin: 175036,
        DispatchCenter: "SundarNgr_BhojpurRd_D (Himachal Pradesh)",
        OriginCenter: "SundarNgr_BhojpurRd_D (Himachal Pradesh)",
        FacilityCity: "Sundar Nagar",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17760,
        Pin: 391110,
        DispatchCenter: "Dabhoi_Central_DPP_3 (Gujarat)",
        OriginCenter: "Dabhoi_Central_DPP_3 (Gujarat)",
        FacilityCity: "Dabhoi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17761,
        Pin: 424102,
        DispatchCenter: "Pachora_Kajgaon_DPP (Maharashtra)",
        OriginCenter: "Pachora_Kajgaon_DPP (Maharashtra)",
        FacilityCity: "Chalisgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17762,
        Pin: 441603,
        DispatchCenter: "Gondia_Mundipar_I (Maharashtra)",
        OriginCenter: "Gondia_Mundipar_I (Maharashtra)",
        FacilityCity: "Gondia",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17763,
        Pin: 845402,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17764,
        Pin: 522660,
        DispatchCenter: "Vinukonda_Thimmayapalem_D (Andhra Pradesh)",
        OriginCenter: "Vinukonda_Thimmayapalem_D (Andhra Pradesh)",
        FacilityCity: "Vinukonda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17765,
        Pin: 305630,
        DispatchCenter: "Nasirabad_RamlilaChowk_DPP (Rajasthan)",
        OriginCenter: "Nasirabad_RamlilaChowk_DPP (Rajasthan)",
        FacilityCity: "Nasirabad",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17766,
        Pin: 570027,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17767,
        Pin: 590016,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17768,
        Pin: 304501,
        DispatchCenter: "Malpura_SadatMohalla_DPP (Rajasthan)",
        OriginCenter: "Malpura_SadatMohalla_DPP (Rajasthan)",
        FacilityCity: "Malpura",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17769,
        Pin: 333503,
        DispatchCenter: "Buhana_Rajota_D (Rajasthan)",
        OriginCenter: "Buhana_Rajota_D (Rajasthan)",
        FacilityCity: "Buhana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17770,
        Pin: 842007,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17771,
        Pin: 759027,
        DispatchCenter: "Dhenkanal_Korian_D (Orissa)",
        OriginCenter: "Dhenkanal_Korian_D (Orissa)",
        FacilityCity: "Dhenkanal",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17772,
        Pin: 585415,
        DispatchCenter: "Humnabad_ApmcYard_DPP (Karnataka)",
        OriginCenter: "Humnabad_ApmcYard_DPP (Karnataka)",
        FacilityCity: "Humnabad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17773,
        Pin: 829107,
        DispatchCenter: "Phusro_RhmgjDPP_D (Jharkhand)",
        OriginCenter: "Phusro_RhmgjDPP_D (Jharkhand)",
        FacilityCity: "Phusro",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17774,
        Pin: 625707,
        DispatchCenter: "Virudhunagar_Allampatti_DPP (Tamil Nadu)",
        OriginCenter: "Virudhunagar_Allampatti_DPP (Tamil Nadu)",
        FacilityCity: "Virudhunagar",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17775,
        Pin: 203399,
        DispatchCenter: "Dibai_Galibpur_D (Uttar Pradesh)",
        OriginCenter: "Dibai_Galibpur_D (Uttar Pradesh)",
        FacilityCity: "Dibai",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17776,
        Pin: 587204,
        DispatchCenter: "Bagalkot_NavaNgr_D (Karnataka)",
        OriginCenter: "Bagalkot_NavaNgr_D (Karnataka)",
        FacilityCity: "Bagalkot",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17777,
        Pin: 226016,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17778,
        Pin: 209727,
        DispatchCenter: "Kannauj_Kazitola_D (Uttar Pradesh)",
        OriginCenter: "Kannauj_Kazitola_D (Uttar Pradesh)",
        FacilityCity: "Kannauj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17779,
        Pin: 495004,
        DispatchCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        OriginCenter: "Bilaspur_Transpotnagar_H (Chhattisgarh)",
        FacilityCity: "Bilaspur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17780,
        Pin: 854327,
        DispatchCenter: "Purnia_Fatehpur_D (Bihar)",
        OriginCenter: "Purnia_Fatehpur_D (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17781,
        Pin: 691547,
        DispatchCenter: "Kottarakkara_MythriNagar_D (Kerala)",
        OriginCenter: "Kottarakkara_MythriNagar_D (Kerala)",
        FacilityCity: "Kottarakkara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17782,
        Pin: 323803,
        DispatchCenter: "Lakheri_KrsrdDPP_D (Rajasthan)",
        OriginCenter: "Lakheri_KrsrdDPP_D (Rajasthan)",
        FacilityCity: "Lakheri",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17783,
        Pin: 790002,
        DispatchCenter: "Kalaktang_Bankcolony_D (Arunachal Pradesh)",
        OriginCenter: "Kalaktang_Bankcolony_D (Arunachal Pradesh)",
        FacilityCity: "Kalaktang",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17784,
        Pin: 620017,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17785,
        Pin: 481222,
        DispatchCenter: "Balaghat_Kirnapur_DPP (Madhya Pradesh)",
        OriginCenter: "Balaghat_Kirnapur_DPP (Madhya Pradesh)",
        FacilityCity: "Balaghat",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17786,
        Pin: 574266,
        DispatchCenter: "Moodbidri_Central_D (Karnataka)",
        OriginCenter: "Moodbidri_Central_D (Karnataka)",
        FacilityCity: "Moodabidri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17787,
        Pin: 505188,
        DispatchCenter: "Mancherial_Nuspur_I (Telangana)",
        OriginCenter: "Mancherial_Nuspur_I (Telangana)",
        FacilityCity: "Mancherial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17788,
        Pin: 272270,
        DispatchCenter: "Menhdawal_Kabeernagar_D (Uttar Pradesh)",
        OriginCenter: "Menhdawal_Kabeernagar_D (Uttar Pradesh)",
        FacilityCity: "Menhdawal",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17789,
        Pin: 384320,
        DispatchCenter: "Vadnagar_BsstdDPP_D (Gujarat)",
        OriginCenter: "Vadnagar_BsstdDPP_D (Gujarat)",
        FacilityCity: "Vadnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17790,
        Pin: 732101,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17791,
        Pin: 613207,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17792,
        Pin: 143026,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17793,
        Pin: 620006,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17794,
        Pin: 741301,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17795,
        Pin: 625022,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17796,
        Pin: 683546,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17797,
        Pin: 805109,
        DispatchCenter: "Rajgir_Naipokhar_D (Bihar)",
        OriginCenter: "Rajgir_Naipokhar_D (Bihar)",
        FacilityCity: "Rajgir",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17798,
        Pin: 131101,
        DispatchCenter: "Sonipat_Sector51_L (Haryana)",
        OriginCenter: "Sonipat_WazidpurSaboli_P (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17799,
        Pin: 110001,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17800,
        Pin: 609801,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17801,
        Pin: 175137,
        DispatchCenter: "SundarNgr_BhojpurRd_D (Himachal Pradesh)",
        OriginCenter: "SundarNgr_BhojpurRd_D (Himachal Pradesh)",
        FacilityCity: "Sundar Nagar",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17802,
        Pin: 841313,
        DispatchCenter: "Ekma_Hansrajpr_D (Bihar)",
        OriginCenter: "Ekma_Hansrajpr_D (Bihar)",
        FacilityCity: "Ekma",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17803,
        Pin: 185111,
        DispatchCenter: "Poonch_Mendhar_DPP (Jammu & Kashmir)",
        OriginCenter: "Poonch_Mendhar_DPP (Jammu & Kashmir)",
        FacilityCity: "Poonch",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17804,
        Pin: 759014,
        DispatchCenter: "Bhuban_Sadangi_D (Orissa)",
        OriginCenter: "Bhuban_Sadangi_D (Orissa)",
        FacilityCity: "Bhuban",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17805,
        Pin: 781029,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17806,
        Pin: 506391,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17807,
        Pin: 586212,
        DispatchCenter: "Muddebihal_Hudcocolony_D (Karnataka)",
        OriginCenter: "Muddebihal_Hudcocolony_D (Karnataka)",
        FacilityCity: "Muddebihal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17808,
        Pin: 571430,
        DispatchCenter: "Malavalli_KBHclny_D (Karnataka)",
        OriginCenter: "Malavalli_KBHclny_D (Karnataka)",
        FacilityCity: "Malavalli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17809,
        Pin: 636308,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17810,
        Pin: 224225,
        DispatchCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        OriginCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        FacilityCity: "Kumarganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17811,
        Pin: 680695,
        DispatchCenter: "Irinjlkuda_Pothstherry_D (Kerala)",
        OriginCenter: "Irinjlkuda_Pothstherry_D (Kerala)",
        FacilityCity: "Irinjalakuda",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17812,
        Pin: 792121,
        DispatchCenter: "Jairampur_Mdiclcly_D (Arunachal Pradesh)",
        OriginCenter: "Jairampur_Mdiclcly_D (Arunachal Pradesh)",
        FacilityCity: "Jairampur",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17813,
        Pin: 783129,
        DispatchCenter: "Lakhipur_Ward2_D (Assam)",
        OriginCenter: "Lakhipur_Ward2_D (Assam)",
        FacilityCity: "Lakhipur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17814,
        Pin: 638672,
        DispatchCenter: "Dharapuram_Pirivu_D (Tamil Nadu)",
        OriginCenter: "Dharapuram_Pirivu_D (Tamil Nadu)",
        FacilityCity: "Dharapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17815,
        Pin: 785626,
        DispatchCenter: "Dergaon_Rojabahar_DPP (Assam)",
        OriginCenter: "Dergaon_Rojabahar_DPP (Assam)",
        FacilityCity: "Dergaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17816,
        Pin: 784170,
        DispatchCenter: "Behali_PostOfc_D (Assam)",
        OriginCenter: "Behali_PostOfc_D (Assam)",
        FacilityCity: "Behali",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17817,
        Pin: 344032,
        DispatchCenter: "Balotra_Ward45_D (Rajasthan)",
        OriginCenter: "Balotra_Ward45_D (Rajasthan)",
        FacilityCity: "Balotra",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17818,
        Pin: 577126,
        DispatchCenter: "Koppa_Haranduru_D (Karnataka)",
        OriginCenter: "Koppa_Haranduru_D (Karnataka)",
        FacilityCity: "Koppa-ka",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17819,
        Pin: 148030,
        DispatchCenter: "Sunam_Indrabasti_D (Punjab)",
        OriginCenter: "Sunam_Indrabasti_D (Punjab)",
        FacilityCity: "Sunam",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17820,
        Pin: 307514,
        DispatchCenter: "Reodar_KarotiChorha_DPP (Rajasthan)",
        OriginCenter: "Reodar_KarotiChorha_DPP (Rajasthan)",
        FacilityCity: "Reodar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17821,
        Pin: 600047,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17822,
        Pin: 643220,
        DispatchCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        OriginCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        FacilityCity: "Gudalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17823,
        Pin: 144028,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17824,
        Pin: 500100,
        DispatchCenter: "Hyderabad_Medchal1_L (Telangana)",
        OriginCenter: "Hyderabad_Medchal_GW (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17825,
        Pin: 637202,
        DispatchCenter: "Tiruchengode_Kailasmplym_D (Tamil Nadu)",
        OriginCenter: "Tiruchengode_Kailasmplym_D (Tamil Nadu)",
        FacilityCity: "Tiruchengode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17826,
        Pin: 614403,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17827,
        Pin: 764056,
        DispatchCenter: "Jeypore_Bariniput_I (Orissa)",
        OriginCenter: "Jeypore_Bariniput_I (Orissa)",
        FacilityCity: "Jeypore",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17828,
        Pin: 125011,
        DispatchCenter: "Hisar_Dabra_H (Haryana)",
        OriginCenter: "Hisar_Dabra_H (Haryana)",
        FacilityCity: "Hisar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17829,
        Pin: 312605,
        DispatchCenter: "PratapgarhRJ_Badabag_DPP (Rajasthan)",
        OriginCenter: "PratapgarhRJ_Badabag_DPP (Rajasthan)",
        FacilityCity: "Pratapgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17830,
        Pin: 795146,
        DispatchCenter: "Imphal_Heibongpokpi_D (Manipur)",
        OriginCenter: "Imphal_Heibongpokpi_D (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17831,
        Pin: 521148,
        DispatchCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        OriginCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        FacilityCity: "Gudivada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17832,
        Pin: 394160,
        DispatchCenter: "Mandvi_Tekro_DPP (Gujarat)",
        OriginCenter: "Mandvi_Tekro_DPP (Gujarat)",
        FacilityCity: "Mandvi (Surat)",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17833,
        Pin: 721606,
        DispatchCenter: "Haldia_Basudevpur_D (West Bengal)",
        OriginCenter: "Haldia_Basudevpur_D (West Bengal)",
        FacilityCity: "Haldia",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17834,
        Pin: 580004,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17835,
        Pin: 844116,
        DispatchCenter: "Hajipur_Belkunda_D (Bihar)",
        OriginCenter: "Hajipur_Belkunda_D (Bihar)",
        FacilityCity: "Hajipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17836,
        Pin: 500081,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17837,
        Pin: 737139,
        DispatchCenter: "Ravangla_HospRd_D (Sikkim)",
        OriginCenter: "Ravangla_HospRd_D (Sikkim)",
        FacilityCity: "Ravangla",
        FacilityState: "Sikkim",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17838,
        Pin: 782470,
        DispatchCenter: "Golaghat_Silonijan_D (Assam)",
        OriginCenter: "Golaghat_Silonijan_D (Assam)",
        FacilityCity: "Golaghat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17839,
        Pin: 425516,
        DispatchCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        OriginCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        FacilityCity: "Raver",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17840,
        Pin: 678004,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17841,
        Pin: 784151,
        DispatchCenter: "Sivasagar_Pdinnagar_D (Assam)",
        OriginCenter: "Sivasagar_Pdinnagar_D (Assam)",
        FacilityCity: "Sivasagar",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17842,
        Pin: 464111,
        DispatchCenter: "Berasia_Mohanpura_DPP (Madhya Pradesh)",
        OriginCenter: "Berasia_Mohanpura_DPP (Madhya Pradesh)",
        FacilityCity: "Berasia",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17843,
        Pin: 180019,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17844,
        Pin: 635804,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17845,
        Pin: 798621,
        DispatchCenter: "Mon_ChingaiWard_D (Nagaland)",
        OriginCenter: "Mon_ChingaiWard_D (Nagaland)",
        FacilityCity: "Mon",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17846,
        Pin: 523254,
        DispatchCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        OriginCenter: "Kanigiri_Tkriplm_D (Andhra Pradesh)",
        FacilityCity: "Kanigiri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17847,
        Pin: 517257,
        DispatchCenter: "Punganur_Nspeta_D (Andhra Pradesh)",
        OriginCenter: "Punganur_Nspeta_D (Andhra Pradesh)",
        FacilityCity: "Punganur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17848,
        Pin: 762019,
        DispatchCenter: "Phulbani_NipiditaOfc_D (Orissa)",
        OriginCenter: "Phulbani_NipiditaOfc_D (Orissa)",
        FacilityCity: "Phulbani",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17849,
        Pin: 610210,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17850,
        Pin: 688011,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17851,
        Pin: 506004,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17852,
        Pin: 690554,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17853,
        Pin: 415615,
        DispatchCenter: "Ganpatipule_Rahataghar_DPP (Maharashtra)",
        OriginCenter: "Ganpatipule_Rahataghar_DPP (Maharashtra)",
        FacilityCity: "Ganpatipule",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17854,
        Pin: 335805,
        DispatchCenter: "Pilibanga_Suratgarh_D (Rajasthan)",
        OriginCenter: "Pilibanga_Suratgarh_D (Rajasthan)",
        FacilityCity: "Suratgarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17855,
        Pin: 721454,
        DispatchCenter: "Contai_Egra_D (West Bengal)",
        OriginCenter: "Contai_Egra_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17856,
        Pin: 246749,
        DispatchCenter: "Najibabad_Pathanpura_D (Uttar Pradesh)",
        OriginCenter: "Najibabad_Pathanpura_D (Uttar Pradesh)",
        FacilityCity: "Najibabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17857,
        Pin: 246178,
        DispatchCenter: "SrinagarUK_Sandre_D (Uttarakhand)",
        OriginCenter: "SrinagarUK_Sandre_D (Uttarakhand)",
        FacilityCity: "Srinagar-UK",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17858,
        Pin: 799015,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17859,
        Pin: 690517,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17860,
        Pin: 302011,
        DispatchCenter: "Jaipur_Ramchandpura_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17861,
        Pin: 736160,
        DispatchCenter: "CoochBehar_khagrabari_I (West Bengal)",
        OriginCenter: "CoochBehar_khagrabari_I (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17862,
        Pin: 682312,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17863,
        Pin: 201308,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17864,
        Pin: 244226,
        DispatchCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        OriginCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        FacilityCity: "Moradabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17865,
        Pin: 743331,
        DispatchCenter: "Fatepur_Majlishpur_D (West Bengal)",
        OriginCenter: "Fatepur_Majlishpur_D (West Bengal)",
        FacilityCity: "Fatepur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17866,
        Pin: 144519,
        DispatchCenter: "Garhshankar_BangaRoad_D (Punjab)",
        OriginCenter: "Garhshankar_BangaRoad_D (Punjab)",
        FacilityCity: "Garhshanker",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17867,
        Pin: 394550,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 17868,
        Pin: 285128,
        DispatchCenter: "Jalaun_Sengarcol_D (Uttar Pradesh)",
        OriginCenter: "Jalaun_Sengarcol_D (Uttar Pradesh)",
        FacilityCity: "Jalaun",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17869,
        Pin: 143305,
        DispatchCenter: "Patti_TarnTaran_D (Punjab)",
        OriginCenter: "Patti_TarnTaran_D (Punjab)",
        FacilityCity: "Patti",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17870,
        Pin: 754030,
        DispatchCenter: "Badamba_Biswambharpur_D (Orissa)",
        OriginCenter: "Badamba_Biswambharpur_D (Orissa)",
        FacilityCity: "Badamba",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17871,
        Pin: 322027,
        DispatchCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        OriginCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        FacilityCity: "Sawai Madhopur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17872,
        Pin: 795107,
        DispatchCenter: "Senapati_WardNo1_D (Manipur)",
        OriginCenter: "Senapati_WardNo1_D (Manipur)",
        FacilityCity: "Kangpokpi",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17873,
        Pin: 752091,
        DispatchCenter: "Daspalla_Andharkota_DPP (Orissa)",
        OriginCenter: "Daspalla_Andharkota_DPP (Orissa)",
        FacilityCity: "Daspalla",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17874,
        Pin: 491335,
        DispatchCenter: "Bemetara_Pikari_D (Chhattisgarh)",
        OriginCenter: "Bemetara_Pikari_D (Chhattisgarh)",
        FacilityCity: "Bemetara",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17875,
        Pin: 389152,
        DispatchCenter: "Dahod_Vanzarwada_I (Gujarat)",
        OriginCenter: "Dahod_Vanzarwada_I (Gujarat)",
        FacilityCity: "Dahod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 17876,
        Pin: 782482,
        DispatchCenter: "Bokolia_Atigaon_DPP (Assam)",
        OriginCenter: "Bokolia_Atigaon_DPP (Assam)",
        FacilityCity: "Bokolia",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17877,
        Pin: 804451,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17878,
        Pin: 583217,
        DispatchCenter: "Haveri_Guttal_D (Karnataka)",
        OriginCenter: "Haveri_Guttal_D (Karnataka)",
        FacilityCity: "Haveri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17879,
        Pin: 307803,
        DispatchCenter: "Bhinmal_Kaleti_D (Rajasthan)",
        OriginCenter: "Bhinmal_Kaleti_D (Rajasthan)",
        FacilityCity: "Bhinmal",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17880,
        Pin: 361141,
        DispatchCenter: "Jamnagar_Chela_L (Gujarat)",
        OriginCenter: "Jamnagar_Chela_L (Gujarat)",
        FacilityCity: "Jamnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17881,
        Pin: 391243,
        DispatchCenter: "Karjan_Junabzr_DC (Gujarat)",
        OriginCenter: "Karjan_Junabzr_DC (Gujarat)",
        FacilityCity: "Karjan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 17882,
        Pin: 224235,
        DispatchCenter: "Akbarpur_AmbedkarNgr_D (Uttar Pradesh)",
        OriginCenter: "Akbarpur_AmbedkarNgr_D (Uttar Pradesh)",
        FacilityCity: "Akbarpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17883,
        Pin: 583123,
        DispatchCenter: "Bellary_Mundargi_I (Karnataka)",
        OriginCenter: "Bellary_Mundargi_I (Karnataka)",
        FacilityCity: "Bellary",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17884,
        Pin: 411041,
        DispatchCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        OriginCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17885,
        Pin: 471313,
        DispatchCenter: "Shahgarh_AsatiClc_DPP (Madhya Pradesh)",
        OriginCenter: "Shahgarh_AsatiClc_DPP (Madhya Pradesh)",
        FacilityCity: "Shahgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 17886,
        Pin: 176310,
        DispatchCenter: "Chamba_Sultanpur_D (Himachal Pradesh)",
        OriginCenter: "Chamba_Sultanpur_D (Himachal Pradesh)",
        FacilityCity: "Chamba",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17887,
        Pin: 754105,
        DispatchCenter: "Adaspur_Uradha_DPP (Orissa)",
        OriginCenter: "Adaspur_Uradha_DPP (Orissa)",
        FacilityCity: "Adaspur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17888,
        Pin: 846008,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17889,
        Pin: 416106,
        DispatchCenter: "Jaysingpur_Swapnanagari_D (Maharashtra)",
        OriginCenter: "Jaysingpur_Swapnanagari_D (Maharashtra)",
        FacilityCity: "Jaysingpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17890,
        Pin: 532459,
        DispatchCenter: "KotturuAP_NivagamRd_DPP (Andhra Pradesh)",
        OriginCenter: "KotturuAP_NivagamRd_DPP (Andhra Pradesh)",
        FacilityCity: "Kotturu-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17891,
        Pin: 133008,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17892,
        Pin: 456010,
        DispatchCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        OriginCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        FacilityCity: "Ujjain",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17893,
        Pin: 742213,
        DispatchCenter: "Raghunathganj_NH12_D (West Bengal)",
        OriginCenter: "Raghunathganj_NH12_D (West Bengal)",
        FacilityCity: "Raghunathganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17894,
        Pin: 731213,
        DispatchCenter: "Rampurhat_Suri_D (West Bengal)",
        OriginCenter: "Rampurhat_Suri_D (West Bengal)",
        FacilityCity: "Rampurhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17895,
        Pin: 691625,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17896,
        Pin: 848122,
        DispatchCenter: "Tajpur_HsptlRd_D (Bihar)",
        OriginCenter: "Tajpur_HsptlRd_D (Bihar)",
        FacilityCity: "Tajpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17897,
        Pin: 481661,
        DispatchCenter: "Mandla_Maharajpur_D (Madhya Pradesh)",
        OriginCenter: "Mandla_Maharajpur_D (Madhya Pradesh)",
        FacilityCity: "Mandla",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17898,
        Pin: 756113,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17899,
        Pin: 535202,
        DispatchCenter: "Kovvur_Ramarao_D (Andhra Pradesh)",
        OriginCenter: "Kovvur_Ramarao_D (Andhra Pradesh)",
        FacilityCity: "Kovvur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17900,
        Pin: 343021,
        DispatchCenter: "Jalore_Sayla_DPP (Rajasthan)",
        OriginCenter: "Jalore_Sayla_DPP (Rajasthan)",
        FacilityCity: "Jalore",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17901,
        Pin: 721629,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17902,
        Pin: 826015,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 17903,
        Pin: 450006,
        DispatchCenter: "Khandwa_TilakNagar_D (Madhya Pradesh)",
        OriginCenter: "Khandwa_TilakNagar_D (Madhya Pradesh)",
        FacilityCity: "Khandwa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 17904,
        Pin: 388330,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17905,
        Pin: 695133,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17906,
        Pin: 414601,
        DispatchCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        OriginCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        FacilityCity: "Ahmednagar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17907,
        Pin: 518124,
        DispatchCenter: "Betamcherla_Bngnpalle_D (Andhra Pradesh)",
        OriginCenter: "Betamcherla_Bngnpalle_D (Andhra Pradesh)",
        FacilityCity: "Betamcherla",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17908,
        Pin: 143149,
        DispatchCenter: "Tarsikka_SaidoLehal_D (Punjab)",
        OriginCenter: "Tarsikka_SaidoLehal_D (Punjab)",
        FacilityCity: "Tarsikka",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17909,
        Pin: 521330,
        DispatchCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        OriginCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        FacilityCity: "Gudivada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17910,
        Pin: 501512,
        DispatchCenter: "Hyderabad_Autonagar_L (Telangana)",
        OriginCenter: "Hyderabad_Autonagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17911,
        Pin: 743379,
        DispatchCenter: "Baruipur_Bnsibtla_D (West Bengal)",
        OriginCenter: "Baruipur_Bnsibtla_D (West Bengal)",
        FacilityCity: "Baruipur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17912,
        Pin: 400028,
        DispatchCenter: "Mumbai_Sewri_L (Maharashtra)",
        OriginCenter: "Mumbai_Sewri_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17913,
        Pin: 396035,
        DispatchCenter: "Valsad_LimbaChwk_D (Gujarat)",
        OriginCenter: "Valsad_LimbaChwk_D (Gujarat)",
        FacilityCity: "Valsad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 17914,
        Pin: 732123,
        DispatchCenter: "Chanchal_Nayatola_DPP (West Bengal)",
        OriginCenter: "Chanchal_Nayatola_DPP (West Bengal)",
        FacilityCity: "Chanchal",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17915,
        Pin: 612601,
        DispatchCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        OriginCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17916,
        Pin: 691539,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17917,
        Pin: 431604,
        DispatchCenter: "Nanded_Wajegaon_H (Maharashtra)",
        OriginCenter: "Nanded_Wajegaon_H (Maharashtra)",
        FacilityCity: "Nanded",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17918,
        Pin: 281403,
        DispatchCenter: "Chhata_Krishnacolony_D (Uttar Pradesh)",
        OriginCenter: "Chhata_Krishnacolony_D (Uttar Pradesh)",
        FacilityCity: "Chhata",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17919,
        Pin: 411007,
        DispatchCenter: "Pune_Muktainagar_L (Maharashtra)",
        OriginCenter: "Pune_Muktainagar_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17920,
        Pin: 628209,
        DispatchCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        OriginCenter: "Tiruchendur_VeerapandianP_D (Tamil Nadu)",
        FacilityCity: "Tiruchendur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17921,
        Pin: 563123,
        DispatchCenter: "BKothakota_RTCStop_D (Andhra Pradesh)",
        OriginCenter: "BKothakota_RTCStop_D (Andhra Pradesh)",
        FacilityCity: "B Kothakota",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17922,
        Pin: 431803,
        DispatchCenter: "Kinwat_Mahurrd_D (Maharashtra)",
        OriginCenter: "Kinwat_Mahurrd_D (Maharashtra)",
        FacilityCity: "Kinwat",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17923,
        Pin: 262532,
        DispatchCenter: "Berinag_Dhanoli_DPP (Uttarakhand)",
        OriginCenter: "Berinag_Dhanoli_DPP (Uttarakhand)",
        FacilityCity: "Berinag",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17924,
        Pin: 673504,
        DispatchCenter: "Thalassery_Iyyamkode_D (Kerala)",
        OriginCenter: "Thalassery_Iyyamkode_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17925,
        Pin: 753021,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17926,
        Pin: 731123,
        DispatchCenter: "Dubrajpur_Mansayer_D (West Bengal)",
        OriginCenter: "Dubrajpur_Mansayer_D (West Bengal)",
        FacilityCity: "Dubrajpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17927,
        Pin: 583233,
        DispatchCenter: "Hospet_Vijayanagara_D (Karnataka)",
        OriginCenter: "Hospet_Vijayanagara_D (Karnataka)",
        FacilityCity: "Hospet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17928,
        Pin: 605757,
        DispatchCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        OriginCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        FacilityCity: "Thirukoilure",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17929,
        Pin: 324010,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17930,
        Pin: 242001,
        DispatchCenter: "Shahjahanpur_AvasVikas_D (Uttar Pradesh)",
        OriginCenter: "Shahjahanpur_AvasVikas_D (Uttar Pradesh)",
        FacilityCity: "Shahjahanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17931,
        Pin: 176304,
        DispatchCenter: "Dalhousie_Banikhet_D (Himachal Pradesh)",
        OriginCenter: "Dalhousie_Banikhet_D (Himachal Pradesh)",
        FacilityCity: "Dalhousie",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 17932,
        Pin: 641402,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17933,
        Pin: 679503,
        DispatchCenter: "Cherpulassery_PryaPlda_D (Kerala)",
        OriginCenter: "Cherpulassery_PryaPlda_D (Kerala)",
        FacilityCity: "Cherpulassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17934,
        Pin: 733155,
        DispatchCenter: "Balurghat_AkhirDPP_D (West Bengal)",
        OriginCenter: "Balurghat_AkhirDPP_D (West Bengal)",
        FacilityCity: "Balurghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17935,
        Pin: 383450,
        DispatchCenter: "Idar_GIDC_D (Gujarat)",
        OriginCenter: "Idar_GIDC_D (Gujarat)",
        FacilityCity: "Idar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17936,
        Pin: 477222,
        DispatchCenter: "Bhind_Mehgaon_D (Madhya Pradesh)",
        OriginCenter: "Bhind_Mehgaon_D (Madhya Pradesh)",
        FacilityCity: "Bhind",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17937,
        Pin: 784153,
        DispatchCenter: "Tezpur_Dipota_H (Assam)",
        OriginCenter: "Tezpur_Dipota_H (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 17938,
        Pin: 632314,
        DispatchCenter: "Arani_PolurRd_D (Tamil Nadu)",
        OriginCenter: "Arani_PolurRd_D (Tamil Nadu)",
        FacilityCity: "Arani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17939,
        Pin: 226005,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17940,
        Pin: 743124,
        DispatchCenter: "Bongaon_SukntDPP_D (West Bengal)",
        OriginCenter: "Bongaon_SukntDPP_D (West Bengal)",
        FacilityCity: "Bongaon",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17941,
        Pin: 736159,
        DispatchCenter: "CoochBehar_khagrabari_I (West Bengal)",
        OriginCenter: "CoochBehar_khagrabari_I (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17942,
        Pin: 342307,
        DispatchCenter: "Ghator_Jaitrasar_DPP (Rajasthan)",
        OriginCenter: "Ghator_Jaitrasar_DPP (Rajasthan)",
        FacilityCity: "Ghator",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17943,
        Pin: 799145,
        DispatchCenter: "ManuBazar_RajibNgr_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Manu bazar",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17944,
        Pin: 271802,
        DispatchCenter: "Bahraich_Dularpur_H (Uttar Pradesh)",
        OriginCenter: "Bahraich_Dularpur_H (Uttar Pradesh)",
        FacilityCity: "Bahraich",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17945,
        Pin: 796810,
        DispatchCenter: "Sangau_Zionveng_D (Mizoram)",
        OriginCenter: "Sangau_Zionveng_D (Mizoram)",
        FacilityCity: "Sangau",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 17946,
        Pin: 678688,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17947,
        Pin: 145026,
        DispatchCenter: "Dinanagar_KakaHosp_D (Punjab)",
        OriginCenter: "Dinanagar_KakaHosp_D (Punjab)",
        FacilityCity: "Dinanagar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17948,
        Pin: 424207,
        DispatchCenter: "Jamner_BusStd_D (Maharashtra)",
        OriginCenter: "Jamner_BusStd_D (Maharashtra)",
        FacilityCity: "Jamner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17949,
        Pin: 841411,
        DispatchCenter: "Marhowarah_StatinRD_D (Bihar)",
        OriginCenter: "Marhowarah_StatinRD_D (Bihar)",
        FacilityCity: "Marhowarah",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17950,
        Pin: 571216,
        DispatchCenter: "Gonikoppal_Patelnagar_D (Karnataka)",
        OriginCenter: "Gonikoppal_Patelnagar_D (Karnataka)",
        FacilityCity: "Gonikoppal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17951,
        Pin: 614809,
        DispatchCenter: "Pushpavanam_Vedaranyam_D (Tamil Nadu)",
        OriginCenter: "Pushpavanam_Vedaranyam_D (Tamil Nadu)",
        FacilityCity: "Pushpavanam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17952,
        Pin: 627859,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17953,
        Pin: 244923,
        DispatchCenter: "BilaspurUP_SainiColony_D (Uttar Pradesh)",
        OriginCenter: "BilaspurUP_SainiColony_D (Uttar Pradesh)",
        FacilityCity: "Bilaspur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17954,
        Pin: 416210,
        DispatchCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        OriginCenter: "Gadhinglaj_Gijawane_D (Maharashtra)",
        FacilityCity: "Gadhinglaj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17955,
        Pin: 425107,
        DispatchCenter: "Chopda_CollegeRd_DPP (Maharashtra)",
        OriginCenter: "Chopda_CollegeRd_DPP (Maharashtra)",
        FacilityCity: "Chopda",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17956,
        Pin: 515231,
        DispatchCenter: "Gorantla_Market_D (Andhra Pradesh)",
        OriginCenter: "Gorantla_Market_D (Andhra Pradesh)",
        FacilityCity: "Gorantla",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17957,
        Pin: 152031,
        DispatchCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        OriginCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        FacilityCity: "Bathinda",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17958,
        Pin: 523108,
        DispatchCenter: "Pamuru_ViruvoruRd_D (Andhra Pradesh)",
        OriginCenter: "Pamuru_ViruvoruRd_D (Andhra Pradesh)",
        FacilityCity: "Pamuru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17959,
        Pin: 210010,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17960,
        Pin: 689654,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 17961,
        Pin: 670319,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17962,
        Pin: 481116,
        DispatchCenter: "Malanjkhand_PouniSuji_D (Madhya Pradesh)",
        OriginCenter: "Malanjkhand_PouniSuji_D (Madhya Pradesh)",
        FacilityCity: "Malanjkhand",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17963,
        Pin: 224232,
        DispatchCenter: "Rajesultanpur_PadumprMkt_D (Uttar Pradesh)",
        OriginCenter: "Rajesultanpur_PadumprMkt_D (Uttar Pradesh)",
        FacilityCity: "Rajesultanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17964,
        Pin: 480771,
        DispatchCenter: "Katangi_Mattravihar_D (Madhya Pradesh)",
        OriginCenter: "Katangi_Mattravihar_D (Madhya Pradesh)",
        FacilityCity: "Katangi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17965,
        Pin: 731242,
        DispatchCenter: "Morgram_Kantagoriya_D (West Bengal)",
        OriginCenter: "Morgram_Kantagoriya_D (West Bengal)",
        FacilityCity: "Morgram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17966,
        Pin: 360002,
        DispatchCenter: "Rajkot_Amargadh_H (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 17967,
        Pin: 486003,
        DispatchCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        OriginCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        FacilityCity: "Rewa",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17968,
        Pin: 614019,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17969,
        Pin: 534156,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17970,
        Pin: 691533,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17971,
        Pin: 233224,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17972,
        Pin: 629155,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17973,
        Pin: 497101,
        DispatchCenter: "Ambikapur_Bouripara_I (Chhattisgarh)",
        OriginCenter: "Ambikapur_Bouripara_I (Chhattisgarh)",
        FacilityCity: "Ambikapur",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 17974,
        Pin: 562160,
        DispatchCenter: "Channapatna_2ndCross_D (Karnataka)",
        OriginCenter: "Channapatna_2ndCross_D (Karnataka)",
        FacilityCity: "Channapatna",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17975,
        Pin: 614204,
        DispatchCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        OriginCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17976,
        Pin: 690102,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17977,
        Pin: 642133,
        DispatchCenter: "Pollachi_ThangamNagar_D (Tamil Nadu)",
        OriginCenter: "Pollachi_ThangamNagar_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17978,
        Pin: 583236,
        DispatchCenter: "Yelburga_SurabhiColony_D (Karnataka)",
        OriginCenter: "Yelburga_SurabhiColony_D (Karnataka)",
        FacilityCity: "Yelburga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17979,
        Pin: 271865,
        DispatchCenter: "Nanpara_Dehati_D (Uttar Pradesh)",
        OriginCenter: "Nanpara_Dehati_D (Uttar Pradesh)",
        FacilityCity: "Nanpara",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17980,
        Pin: 321611,
        DispatchCenter: "Mahwa_Allapur_D (Rajasthan)",
        OriginCenter: "Mahwa_Allapur_D (Rajasthan)",
        FacilityCity: "Mahwa",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17981,
        Pin: 304001,
        DispatchCenter: "Tonk_Bairwa_D (Rajasthan)",
        OriginCenter: "Tonk_Bairwa_D (Rajasthan)",
        FacilityCity: "Tonk",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17982,
        Pin: 642115,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17983,
        Pin: 683521,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 17984,
        Pin: 515415,
        DispatchCenter: "Tadipatri_Kalavagadacol_D (Andhra Pradesh)",
        OriginCenter: "Tadipatri_Kalavagadacol_D (Andhra Pradesh)",
        FacilityCity: "Tadipatri",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17985,
        Pin: 201004,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17986,
        Pin: 212111,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 17987,
        Pin: 600007,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17988,
        Pin: 144404,
        DispatchCenter: "Phagwara_PurewalNgr_D (Punjab)",
        OriginCenter: "Phagwara_PurewalNgr_D (Punjab)",
        FacilityCity: "Phagwara",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17989,
        Pin: 416219,
        DispatchCenter: "Mudal_Adamapur_DPP (Maharashtra)",
        OriginCenter: "Mudal_Adamapur_DPP (Maharashtra)",
        FacilityCity: "Mudal",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17990,
        Pin: 462038,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 17991,
        Pin: 400016,
        DispatchCenter: "Mumbai_Sewri_L (Maharashtra)",
        OriginCenter: "Mumbai_Sewri_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 17992,
        Pin: 110602,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17993,
        Pin: 333305,
        DispatchCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        OriginCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        FacilityCity: "Udaipurwati",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 17994,
        Pin: 500047,
        DispatchCenter: "Hyderabad_HemaNagar_L (Telangana)",
        OriginCenter: "Hyderabad_HemaNagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17995,
        Pin: 141206,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 17996,
        Pin: 572227,
        DispatchCenter: "Turuvukere_Havala_D (Karnataka)",
        OriginCenter: "Turuvukere_Havala_D (Karnataka)",
        FacilityCity: "Turuvukere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 17997,
        Pin: 522263,
        DispatchCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        OriginCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        FacilityCity: "Repalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 17998,
        Pin: 400112,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 17999,
        Pin: 583239,
        DispatchCenter: "Hospet_Vijayanagara_D (Karnataka)",
        OriginCenter: "Hospet_Vijayanagara_D (Karnataka)",
        FacilityCity: "Hospet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18000,
        Pin: 753008,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18001,
        Pin: 335040,
        DispatchCenter: "PadampurRJ_Gillcolony_D (Rajasthan)",
        OriginCenter: "PadampurRJ_Gillcolony_D (Rajasthan)",
        FacilityCity: "PadampurRJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18002,
        Pin: 223226,
        DispatchCenter: "Azamgarh_Saraimeer_DPP (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Saraimeer_DPP (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18003,
        Pin: 177001,
        DispatchCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        OriginCenter: "Hamirpur_Dosarka_D (Himachal Pradesh)",
        FacilityCity: "Hamirpur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18004,
        Pin: 722137,
        DispatchCenter: "Bankura_Chhatna_DPP (West Bengal)",
        OriginCenter: "Bankura_Chhatna_DPP (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18005,
        Pin: 620009,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18006,
        Pin: 244411,
        DispatchCenter: "Moradabad_BicholaK_D (Uttar Pradesh)",
        OriginCenter: "Moradabad_BicholaK_D (Uttar Pradesh)",
        FacilityCity: "Moradabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18007,
        Pin: 683523,
        DispatchCenter: "Kodungallur_Methala_D (Kerala)",
        OriginCenter: "Kodungallur_Methala_D (Kerala)",
        FacilityCity: "Kodungallur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18008,
        Pin: 226102,
        DispatchCenter: "Malihabad_Amanignj_DPP (Uttar Pradesh)",
        OriginCenter: "Malihabad_Amanignj_DPP (Uttar Pradesh)",
        FacilityCity: "Malihabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18009,
        Pin: 273010,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18010,
        Pin: 456771,
        DispatchCenter: "Badnagar_Teacherscolony_D (Madhya Pradesh)",
        OriginCenter: "Badnagar_Teacherscolony_D (Madhya Pradesh)",
        FacilityCity: "Badnagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18011,
        Pin: 311407,
        DispatchCenter: "Shahpura_JhajpurRd_DPP (Rajasthan)",
        OriginCenter: "Shahpura_JhajpurRd_DPP (Rajasthan)",
        FacilityCity: "Shahpura",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18012,
        Pin: 575020,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18013,
        Pin: 382010,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18014,
        Pin: 834010,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18015,
        Pin: 401302,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18016,
        Pin: 491003,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18017,
        Pin: 695318,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18018,
        Pin: 722143,
        DispatchCenter: "Meija_School_D (West Bengal)",
        OriginCenter: "Meija_School_D (West Bengal)",
        FacilityCity: "Mejia",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18019,
        Pin: 534455,
        DispatchCenter: "Jangareddygudem_RTC_D (Andhra Pradesh)",
        OriginCenter: "Jangareddygudem_RTC_D (Andhra Pradesh)",
        FacilityCity: "Jangareddygudem",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18020,
        Pin: 415310,
        DispatchCenter: "Vita_Adarshnagar_D (Maharashtra)",
        OriginCenter: "Vita_Adarshnagar_D (Maharashtra)",
        FacilityCity: "Vita",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18021,
        Pin: 241001,
        DispatchCenter: "Hardoi_AvsVikasColny_D (Uttar Pradesh)",
        OriginCenter: "Hardoi_AvsVikasColny_D (Uttar Pradesh)",
        FacilityCity: "Hardoi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18022,
        Pin: 110035,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18023,
        Pin: 680702,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18024,
        Pin: 334022,
        DispatchCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        OriginCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        FacilityCity: "Bikaner",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18025,
        Pin: 507160,
        DispatchCenter: "Khammam_Mudigonda_H (Telangana)",
        OriginCenter: "Khammam_Mudigonda_H (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18026,
        Pin: 680603,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18027,
        Pin: 177111,
        DispatchCenter: "Chintpurni_Bharwain_D (Himachal Pradesh)",
        OriginCenter: "Chintpurni_Bharwain_D (Himachal Pradesh)",
        FacilityCity: "Chintpurni",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18028,
        Pin: 756127,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18029,
        Pin: 754023,
        DispatchCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        OriginCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        FacilityCity: "Chandikhol",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18030,
        Pin: 752090,
        DispatchCenter: "Nayagarh_Mathakhajuria_D (Orissa)",
        OriginCenter: "Nayagarh_Mathakhajuria_D (Orissa)",
        FacilityCity: "Nayagarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18031,
        Pin: 175016,
        DispatchCenter: "Baijnath_Paprola_DPP (Himachal Pradesh)",
        OriginCenter: "Baijnath_Paprola_DPP (Himachal Pradesh)",
        FacilityCity: "Baijnath",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18032,
        Pin: 275201,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18033,
        Pin: 277305,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18034,
        Pin: 841440,
        DispatchCenter: "Gopalganj_HarsanHosp_D (Bihar)",
        OriginCenter: "Gopalganj_HarsanHosp_D (Bihar)",
        FacilityCity: "Gopalganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18035,
        Pin: 788164,
        DispatchCenter: "Hailakandi_Lala_D (Assam)",
        OriginCenter: "Hailakandi_Lala_D (Assam)",
        FacilityCity: "Hailakandi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18036,
        Pin: 680506,
        DispatchCenter: "Thrissur_Chavakkad_D (Kerala)",
        OriginCenter: "Thrissur_Chavakkad_D (Kerala)",
        FacilityCity: "Guruvayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18037,
        Pin: 413324,
        DispatchCenter: "Solapur_Kalasenagar_D (Maharashtra)",
        OriginCenter: "Solapur_Kalasenagar_D (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18038,
        Pin: 504346,
        DispatchCenter: "Utnoor_MNCLRd_D (Telangana)",
        OriginCenter: "Utnoor_MNCLRd_D (Telangana)",
        FacilityCity: "Utnoor",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18039,
        Pin: 731103,
        DispatchCenter: "Suri_Karidhya_D (West Bengal)",
        OriginCenter: "Suri_Karidhya_D (West Bengal)",
        FacilityCity: "Suri",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18040,
        Pin: 734321,
        DispatchCenter: "TistaBazar_TeestaBridge_DPP (West Bengal)",
        OriginCenter: "TistaBazar_TeestaBridge_DPP (West Bengal)",
        FacilityCity: "Tista Bazar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18041,
        Pin: 680604,
        DispatchCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        OriginCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18042,
        Pin: 679562,
        DispatchCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        OriginCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18043,
        Pin: 496450,
        DispatchCenter: "Sarangarh_Prtpgrh_DPP (Chhattisgarh)",
        OriginCenter: "Sarangarh_Prtpgrh_DPP (Chhattisgarh)",
        FacilityCity: "Sarangarh",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18044,
        Pin: 400083,
        DispatchCenter: "Mumbai_Powai_L (Maharashtra)",
        OriginCenter: "Mumbai_Powai_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18045,
        Pin: 671533,
        DispatchCenter: "Parappa_Balal_D (Kerala)",
        OriginCenter: "Parappa_Balal_D (Kerala)",
        FacilityCity: "Parappa",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18046,
        Pin: 585326,
        DispatchCenter: "Aurad_CBColony_D (Karnataka)",
        OriginCenter: "Aurad_CBColony_D (Karnataka)",
        FacilityCity: "Aurad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18047,
        Pin: 125005,
        DispatchCenter: "Hisar_Dabra_H (Haryana)",
        OriginCenter: "Hisar_Dabra_H (Haryana)",
        FacilityCity: "Hisar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18048,
        Pin: 500040,
        DispatchCenter: "Hyderabad_HemaNagar_L (Telangana)",
        OriginCenter: "Hyderabad_HemaNagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18049,
        Pin: 847105,
        DispatchCenter: "Singhia_Shayampur_D (Bihar)",
        OriginCenter: "Singhia_Shayampur_D (Bihar)",
        FacilityCity: "Singhia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18050,
        Pin: 444505,
        DispatchCenter: "Washim_Patilnagar_DPP (Maharashtra)",
        OriginCenter: "Washim_Patilnagar_DPP (Maharashtra)",
        FacilityCity: "Washim",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18051,
        Pin: 125021,
        DispatchCenter: "Bhiwani_Bhagganpuri_D (Haryana)",
        OriginCenter: "Bhiwani_Bhagganpuri_D (Haryana)",
        FacilityCity: "Bhiwani",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18052,
        Pin: 210125,
        DispatchCenter: "Baberu_FaujiColony_D (Uttar Pradesh)",
        OriginCenter: "Baberu_FaujiColony_D (Uttar Pradesh)",
        FacilityCity: "Baberu",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18053,
        Pin: 534432,
        DispatchCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        OriginCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        FacilityCity: "Eluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18054,
        Pin: 624610,
        DispatchCenter: "Palani_Sathyanagar_D (Tamil Nadu)",
        OriginCenter: "Palani_Sathyanagar_D (Tamil Nadu)",
        FacilityCity: "Palani",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18055,
        Pin: 673653,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18056,
        Pin: 403510,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18057,
        Pin: 721664,
        DispatchCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        OriginCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        FacilityCity: "Tamluk",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18058,
        Pin: 713322,
        DispatchCenter: "Dubrajpur_Pandaveswar_D (West Bengal)",
        OriginCenter: "Dubrajpur_Pandaveswar_D (West Bengal)",
        FacilityCity: "Dubrajpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18059,
        Pin: 721425,
        DispatchCenter: "Bajkul_Kismat_D (West Bengal)",
        OriginCenter: "Bajkul_Kismat_D (West Bengal)",
        FacilityCity: "Bajkul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18060,
        Pin: 627103,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18061,
        Pin: 384131,
        DispatchCenter: "Unjha_SatymComplx_D (Gujarat)",
        OriginCenter: "Unjha_SatymComplx_D (Gujarat)",
        FacilityCity: "Unjha",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18062,
        Pin: 690528,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18063,
        Pin: 572218,
        DispatchCenter: "Huliyar_Azadnagar_D (Karnataka)",
        OriginCenter: "Huliyar_Azadnagar_D (Karnataka)",
        FacilityCity: "Huliyar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18064,
        Pin: 700021,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18065,
        Pin: 416121,
        DispatchCenter: "Ichalkaranji_TmbeMala_D (Maharashtra)",
        OriginCenter: "Ichalkaranji_TmbeMala_D (Maharashtra)",
        FacilityCity: "Ichalkaranji",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18066,
        Pin: 402302,
        DispatchCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        OriginCenter: "Mahad_ShivajiNagar_D (Maharashtra)",
        FacilityCity: "Mahad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18067,
        Pin: 272177,
        DispatchCenter: "Dhanghata_MarhaRaja_DPP (Uttar Pradesh)",
        OriginCenter: "Dhanghata_MarhaRaja_DPP (Uttar Pradesh)",
        FacilityCity: "Dhanghata",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18068,
        Pin: 461446,
        DispatchCenter: "Itarsi_AwnNgr_D (Madhya Pradesh)",
        OriginCenter: "Itarsi_AwnNgr_D (Madhya Pradesh)",
        FacilityCity: "Itarsi",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18069,
        Pin: 690523,
        DispatchCenter: "Kollam_Karunagappally_D (Kerala)",
        OriginCenter: "Kollam_Karunagappally_D (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18070,
        Pin: 411044,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18071,
        Pin: 712121,
        DispatchCenter: "Hooghly_Bikramnagar_D (West Bengal)",
        OriginCenter: "Hooghly_Bikramnagar_D (West Bengal)",
        FacilityCity: "Hooghly",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18072,
        Pin: 411027,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18073,
        Pin: 721430,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18074,
        Pin: 396171,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18075,
        Pin: 606105,
        DispatchCenter: "Pennadam_Puthar_D (Tamil Nadu)",
        OriginCenter: "Pennadam_Puthar_D (Tamil Nadu)",
        FacilityCity: "Pennadam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18076,
        Pin: 686674,
        DispatchCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        OriginCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        FacilityCity: "Muvattupuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18077,
        Pin: 312002,
        DispatchCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        OriginCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        FacilityCity: "Chittaurgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18078,
        Pin: 415409,
        DispatchCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        OriginCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        FacilityCity: "Islampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18079,
        Pin: 712424,
        DispatchCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        OriginCenter: "Tarkeshwar_Gopinagar_D (West Bengal)",
        FacilityCity: "Tarkeshwar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18080,
        Pin: 490021,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18081,
        Pin: 828142,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18082,
        Pin: 332312,
        DispatchCenter: "Sikar_Phagalwa_D (Rajasthan)",
        OriginCenter: "Sikar_Phagalwa_D (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18083,
        Pin: 581315,
        DispatchCenter: "Kumta_Central_DPP_2 (Karnataka)",
        OriginCenter: "Kumta_Central_DPP_2 (Karnataka)",
        FacilityCity: "Kumta",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18084,
        Pin: 576282,
        DispatchCenter: "Kundapura_Shankarnarayana_D (Karnataka)",
        OriginCenter: "Kundapura_Shankarnarayana_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18085,
        Pin: 431129,
        DispatchCenter: "Majalgaon_SmbjiCwk_D (Maharashtra)",
        OriginCenter: "Majalgaon_SmbjiCwk_D (Maharashtra)",
        FacilityCity: "Manjlegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18086,
        Pin: 585422,
        DispatchCenter: "Bidar_AdarshClny_D (Karnataka)",
        OriginCenter: "Bidar_AdarshClny_D (Karnataka)",
        FacilityCity: "Bidar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18087,
        Pin: 627908,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18088,
        Pin: 621651,
        DispatchCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        OriginCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        FacilityCity: "Thirukkattupalli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18089,
        Pin: 573123,
        DispatchCenter: "Shanivarasanthe_Bank_D (Karnataka)",
        OriginCenter: "Shanivarasanthe_Bank_D (Karnataka)",
        FacilityCity: "Shanivarasanthe",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18090,
        Pin: 346050,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18091,
        Pin: 181124,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18092,
        Pin: 201010,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18093,
        Pin: 673645,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18094,
        Pin: 532579,
        DispatchCenter: "Salur_Eswar_D (Andhra Pradesh)",
        OriginCenter: "Salur_Eswar_D (Andhra Pradesh)",
        FacilityCity: "Salur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18095,
        Pin: 425444,
        DispatchCenter: "Shahada_Untavad_D (Maharashtra)",
        OriginCenter: "Shahada_Untavad_D (Maharashtra)",
        FacilityCity: "Shahada",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18096,
        Pin: 781136,
        DispatchCenter: "Boko_Barpara_D (Assam)",
        OriginCenter: "Boko_Barpara_D (Assam)",
        FacilityCity: "Boko",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18097,
        Pin: 678621,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18098,
        Pin: 385505,
        DispatchCenter: "Deesa_CrystalCplx_D (Gujarat)",
        OriginCenter: "Deesa_CrystalCplx_D (Gujarat)",
        FacilityCity: "Deesa",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18099,
        Pin: 802210,
        DispatchCenter: "Arrah_Anaith_D (Bihar)",
        OriginCenter: "Arrah_Anaith_D (Bihar)",
        FacilityCity: "Arrah",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18100,
        Pin: 574119,
        DispatchCenter: "Mudarangady_Santhoor_DPP (Karnataka)",
        OriginCenter: "Mudarangady_Santhoor_DPP (Karnataka)",
        FacilityCity: "Mudarangady",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18101,
        Pin: 474008,
        DispatchCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        OriginCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        FacilityCity: "Gwalior",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18102,
        Pin: 733125,
        DispatchCenter: "Kaliyaganj_Central_DPP_1 (West Bengal)",
        OriginCenter: "Kaliyaganj_Central_DPP_1 (West Bengal)",
        FacilityCity: "Kaliyaganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18103,
        Pin: 522235,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18104,
        Pin: 229215,
        DispatchCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        OriginCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        FacilityCity: "Lalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18105,
        Pin: 680522,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18106,
        Pin: 590009,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18107,
        Pin: 140407,
        DispatchCenter: "SirhindFatehgarh_DC (Punjab)",
        OriginCenter: "SirhindFatehgarh_DC (Punjab)",
        FacilityCity: "Sirhind-Fategarh",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18108,
        Pin: 205301,
        DispatchCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        OriginCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        FacilityCity: "Mainpuri",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18109,
        Pin: 342004,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18110,
        Pin: 828115,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18111,
        Pin: 577134,
        DispatchCenter: "Koppa_Haranduru_D (Karnataka)",
        OriginCenter: "Koppa_Haranduru_D (Karnataka)",
        FacilityCity: "Koppa-ka",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18112,
        Pin: 628851,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18113,
        Pin: 533223,
        DispatchCenter: "Mandapeta_Ravulapalem_DPP (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Ravulapalem_DPP (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18114,
        Pin: 227305,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18115,
        Pin: 742187,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18116,
        Pin: 416103,
        DispatchCenter: "Jaysingpur_Swapnanagari_D (Maharashtra)",
        OriginCenter: "Jaysingpur_Swapnanagari_D (Maharashtra)",
        FacilityCity: "Jaysingpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18117,
        Pin: 522510,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18118,
        Pin: 600103,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18119,
        Pin: 424005,
        DispatchCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        OriginCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        FacilityCity: "Dhule",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18120,
        Pin: 425507,
        DispatchCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        OriginCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        FacilityCity: "Raver",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18121,
        Pin: 591306,
        DispatchCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        OriginCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        FacilityCity: "Gokak",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18122,
        Pin: 250620,
        DispatchCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        OriginCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        FacilityCity: "Baraut",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18123,
        Pin: 228159,
        DispatchCenter: "Lambhua_Chaukiya_D (Uttar Pradesh)",
        OriginCenter: "Lambhua_Chaukiya_D (Uttar Pradesh)",
        FacilityCity: "Lambhua",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18124,
        Pin: 767032,
        DispatchCenter: "Saintala_Blockroad_DPP (Orissa)",
        OriginCenter: "Saintala_Blockroad_DPP (Orissa)",
        FacilityCity: "Saintala",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18125,
        Pin: 396194,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18126,
        Pin: 141316,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18127,
        Pin: 346240,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18128,
        Pin: 444608,
        DispatchCenter: "Amravati_Changapur_D (Maharashtra)",
        OriginCenter: "Amravati_Changapur_D (Maharashtra)",
        FacilityCity: "Amravati",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18129,
        Pin: 522303,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18130,
        Pin: 762018,
        DispatchCenter: "Kantamal_Blockoffice_DPP (Orissa)",
        OriginCenter: "Kantamal_Blockoffice_DPP (Orissa)",
        FacilityCity: "Kantamal",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18131,
        Pin: 496445,
        DispatchCenter: "Saraipalli_Baitri_D (Chhattisgarh)",
        OriginCenter: "Saraipalli_Baitri_D (Chhattisgarh)",
        FacilityCity: "Saraipalli",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18132,
        Pin: 627128,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18133,
        Pin: 144509,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18134,
        Pin: 271303,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18135,
        Pin: 415124,
        DispatchCenter: "Karad_Malkapur1_D (Maharashtra)",
        OriginCenter: "Karad_Malkapur1_D (Maharashtra)",
        FacilityCity: "Karad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18136,
        Pin: 440018,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18137,
        Pin: 712305,
        DispatchCenter: "Hooghly_Bikramnagar_D (West Bengal)",
        OriginCenter: "Hooghly_Bikramnagar_D (West Bengal)",
        FacilityCity: "Hooghly",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18138,
        Pin: 562123,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18139,
        Pin: 333021,
        DispatchCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        OriginCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        FacilityCity: "Jhunjhunu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18140,
        Pin: 782125,
        DispatchCenter: "Nagaon_Bangthai_H (Assam)",
        OriginCenter: "Nagaon_Bangthai_H (Assam)",
        FacilityCity: "Nagaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18141,
        Pin: 415212,
        DispatchCenter: "Patan_Ashram_D (Maharashtra)",
        OriginCenter: "Patan_Ashram_D (Maharashtra)",
        FacilityCity: "Patan-MH",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18142,
        Pin: 853205,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18143,
        Pin: 686573,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18144,
        Pin: 676312,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18145,
        Pin: 576211,
        DispatchCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        OriginCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18146,
        Pin: 743424,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18147,
        Pin: 711408,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18148,
        Pin: 607005,
        DispatchCenter: "Cuddalore_FriendsNgr_D (Tamil Nadu)",
        OriginCenter: "Cuddalore_FriendsNgr_D (Tamil Nadu)",
        FacilityCity: "Cuddalore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18149,
        Pin: 301021,
        DispatchCenter: "Laxmangarh_Lilee_D (Rajasthan)",
        OriginCenter: "Laxmangarh_Lilee_D (Rajasthan)",
        FacilityCity: "Laxmangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18150,
        Pin: 583135,
        DispatchCenter: "Kudligi_Vidyanagar_D (Karnataka)",
        OriginCenter: "Kudligi_Vidyanagar_D (Karnataka)",
        FacilityCity: "Kudligi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18151,
        Pin: 723152,
        DispatchCenter: "Balarampur_Rangadih_D (West Bengal)",
        OriginCenter: "Balarampur_Rangadih_D (West Bengal)",
        FacilityCity: "Balarampur-WB",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18152,
        Pin: 412412,
        DispatchCenter: "Alephata_BhrtPetrol_D (Maharashtra)",
        OriginCenter: "Alephata_BhrtPetrol_D (Maharashtra)",
        FacilityCity: "Alephata",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18153,
        Pin: 741253,
        DispatchCenter: "Gopalnagar_ParaSttn_DPP (West Bengal)",
        OriginCenter: "Gopalnagar_ParaSttn_DPP (West Bengal)",
        FacilityCity: "Gopalnagar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18154,
        Pin: 483330,
        DispatchCenter: "Katni_Sleemanabad_D (Madhya Pradesh)",
        OriginCenter: "Katni_Sleemanabad_D (Madhya Pradesh)",
        FacilityCity: "Katni",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18155,
        Pin: 148101,
        DispatchCenter: "Barnala_DC (Punjab)",
        OriginCenter: "Barnala_DC (Punjab)",
        FacilityCity: "Barnala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18156,
        Pin: 480667,
        DispatchCenter: "Seoni_Dalsagar_D (Madhya Pradesh)",
        OriginCenter: "Seoni_Dalsagar_D (Madhya Pradesh)",
        FacilityCity: "Seoni",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 18157,
        Pin: 363510,
        DispatchCenter: "Limbdi_Sayla_D (Gujarat)",
        OriginCenter: "Limbdi_Sayla_D (Gujarat)",
        FacilityCity: "Limbdi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18158,
        Pin: 742132,
        DispatchCenter: "Khargram_Parbatipur_D (West Bengal)",
        OriginCenter: "Khargram_Parbatipur_D (West Bengal)",
        FacilityCity: "Khargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18159,
        Pin: 382450,
        DispatchCenter: "Botad_Paliyadrd_D (Gujarat)",
        OriginCenter: "Botad_Paliyadrd_D (Gujarat)",
        FacilityCity: "Botad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18160,
        Pin: 384207,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18161,
        Pin: 631207,
        DispatchCenter: "Sholinghur_Ramkrishnarajapet_D (Tamil Nadu)",
        OriginCenter: "Sholinghur_Ramkrishnarajapet_D (Tamil Nadu)",
        FacilityCity: "Sholinghur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18162,
        Pin: 768034,
        DispatchCenter: "Bargarh_Pada_D (Orissa)",
        OriginCenter: "Bargarh_Pada_D (Orissa)",
        FacilityCity: "Bargarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18163,
        Pin: 841208,
        DispatchCenter: "Ekma_Hansrajpr_D (Bihar)",
        OriginCenter: "Ekma_Hansrajpr_D (Bihar)",
        FacilityCity: "Ekma",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18164,
        Pin: 177028,
        DispatchCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        OriginCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18165,
        Pin: 229415,
        DispatchCenter: "Lalgopalganj_Anandi_D (Uttar Pradesh)",
        OriginCenter: "Lalgopalganj_Anandi_D (Uttar Pradesh)",
        FacilityCity: "Lal Gopalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18166,
        Pin: 413118,
        DispatchCenter: "Akluj_SumitraNgr_D (Maharashtra)",
        OriginCenter: "Akluj_SumitraNgr_D (Maharashtra)",
        FacilityCity: "Akluj",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18167,
        Pin: 847204,
        DispatchCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        OriginCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        FacilityCity: "Jhanjharpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18168,
        Pin: 176095,
        DispatchCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        OriginCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18169,
        Pin: 781141,
        DispatchCenter: "Chhaygaon_Dhekenabari_D (Assam)",
        OriginCenter: "Chhaygaon_Dhekenabari_D (Assam)",
        FacilityCity: "Chhaygaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18170,
        Pin: 224166,
        DispatchCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        OriginCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        FacilityCity: "Jalalpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18171,
        Pin: 636302,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18172,
        Pin: 752019,
        DispatchCenter: "Nirakarpur_Rameswar_D (Orissa)",
        OriginCenter: "Nirakarpur_Rameswar_D (Orissa)",
        FacilityCity: "Nirakarpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18173,
        Pin: 144503,
        DispatchCenter: "Phagwara_PurewalNgr_D (Punjab)",
        OriginCenter: "Phagwara_PurewalNgr_D (Punjab)",
        FacilityCity: "Phagwara",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18174,
        Pin: 160065,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18175,
        Pin: 676553,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18176,
        Pin: 606704,
        DispatchCenter: "Uthangarai_Singarapettai_D (Tamil Nadu)",
        OriginCenter: "Uthangarai_Singarapettai_D (Tamil Nadu)",
        FacilityCity: "Uthangarai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18177,
        Pin: 757106,
        DispatchCenter: "RajBerhampur_Gohira_DPP (Orissa)",
        OriginCenter: "RajBerhampur_Gohira_DPP (Orissa)",
        FacilityCity: "Raj Berhampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18178,
        Pin: 321028,
        DispatchCenter: "Bayana_Shivcolony_D (Rajasthan)",
        OriginCenter: "Bayana_Shivcolony_D (Rajasthan)",
        FacilityCity: "Bayana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18179,
        Pin: 713517,
        DispatchCenter: "Katwa_Panchghara_D (West Bengal)",
        OriginCenter: "Katwa_Panchghara_D (West Bengal)",
        FacilityCity: "Katwa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18180,
        Pin: 382705,
        DispatchCenter: "Kadi_Jadavpura_L (Gujarat)",
        OriginCenter: "Kadi_Jadavpura_L (Gujarat)",
        FacilityCity: "Kadi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18181,
        Pin: 800005,
        DispatchCenter: "Patna_Sonagopalpur_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18182,
        Pin: 622506,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18183,
        Pin: 627435,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18184,
        Pin: 248115,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18185,
        Pin: 485111,
        DispatchCenter: "Satna_Tapa_D (Madhya Pradesh)",
        OriginCenter: "Satna_Tapa_D (Madhya Pradesh)",
        FacilityCity: "Satna",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18186,
        Pin: 174036,
        DispatchCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        OriginCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        FacilityCity: "Bilaspur-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18187,
        Pin: 573214,
        DispatchCenter: "Sakleshpur_Nudarahalli_D (Karnataka)",
        OriginCenter: "Sakleshpur_Nudarahalli_D (Karnataka)",
        FacilityCity: "Sakleshpur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18188,
        Pin: 582111,
        DispatchCenter: "Rona_GadagRD_D (Karnataka)",
        OriginCenter: "Rona_GadagRD_D (Karnataka)",
        FacilityCity: "Rona",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18189,
        Pin: 323801,
        DispatchCenter: "Nainwa_Ward12_DPP (Rajasthan)",
        OriginCenter: "Nainwa_Ward12_DPP (Rajasthan)",
        FacilityCity: "Nainwa",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18190,
        Pin: 400615,
        DispatchCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18191,
        Pin: 629174,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18192,
        Pin: 518452,
        DispatchCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        OriginCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18193,
        Pin: 635121,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18194,
        Pin: 825314,
        DispatchCenter: "Kedla_Ghatotand_D (Jharkhand)",
        OriginCenter: "Kedla_Ghatotand_D (Jharkhand)",
        FacilityCity: "Kedla",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18195,
        Pin: 623708,
        DispatchCenter: "Muthukulathur_Selvanygprm_D (Tamil Nadu)",
        OriginCenter: "Muthukulathur_Selvanygprm_D (Tamil Nadu)",
        FacilityCity: "Muthukulathur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18196,
        Pin: 403710,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18197,
        Pin: 600032,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18198,
        Pin: 515213,
        DispatchCenter: "Hindupur_Sadlapalli_D (Andhra Pradesh)",
        OriginCenter: "Hindupur_Sadlapalli_D (Andhra Pradesh)",
        FacilityCity: "Hindupur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18199,
        Pin: 848211,
        DispatchCenter: "Rosera_HanumanTemple_D (Bihar)",
        OriginCenter: "Rosera_HanumanTemple_D (Bihar)",
        FacilityCity: "Rusera",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18200,
        Pin: 321605,
        DispatchCenter: "Kherli_Doroli_D (Rajasthan)",
        OriginCenter: "Kherli_Doroli_D (Rajasthan)",
        FacilityCity: "Kherli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18201,
        Pin: 581330,
        DispatchCenter: "Kumta_Central_DPP_2 (Karnataka)",
        OriginCenter: "Kumta_Central_DPP_2 (Karnataka)",
        FacilityCity: "Kumta",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18202,
        Pin: 713406,
        DispatchCenter: "Bardhaman_Ulhas_I (West Bengal)",
        OriginCenter: "Bardhaman_Ulhas_I (West Bengal)",
        FacilityCity: "Bardhaman",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18203,
        Pin: 508257,
        DispatchCenter: "Devarakonda_Karunacol_D (Telangana)",
        OriginCenter: "Devarakonda_Karunacol_D (Telangana)",
        FacilityCity: "Devarakonda",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18204,
        Pin: 229412,
        DispatchCenter: "Soraon_Purabtola_D (Uttar Pradesh)",
        OriginCenter: "Soraon_Purabtola_D (Uttar Pradesh)",
        FacilityCity: "Soraon",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18205,
        Pin: 533264,
        DispatchCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18206,
        Pin: 413114,
        DispatchCenter: "Walchandnagar_AshokNagar_D (Maharashtra)",
        OriginCenter: "Walchandnagar_AshokNagar_D (Maharashtra)",
        FacilityCity: "Walchandnagar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18207,
        Pin: 227406,
        DispatchCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        OriginCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        FacilityCity: "Amethi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18208,
        Pin: 759018,
        DispatchCenter: "Kamakhyanagar_Mundideuli_D (Orissa)",
        OriginCenter: "Kamakhyanagar_Mundideuli_D (Orissa)",
        FacilityCity: "Kamakhyanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18209,
        Pin: 671310,
        DispatchCenter: "Nileshwar_Palathadam_D (Kerala)",
        OriginCenter: "Nileshwar_Palathadam_D (Kerala)",
        FacilityCity: "Nileshwar",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18210,
        Pin: 743391,
        DispatchCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        OriginCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        FacilityCity: "Dakshin Barasat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18211,
        Pin: 523261,
        DispatchCenter: "Addanki_AbhyudayaNagar_D (Andhra Pradesh)",
        OriginCenter: "Addanki_AbhyudayaNagar_D (Andhra Pradesh)",
        FacilityCity: "Addanki",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18212,
        Pin: 132041,
        DispatchCenter: "Karnal_Sounkraroad_D (Haryana)",
        OriginCenter: "Karnal_Sounkraroad_D (Haryana)",
        FacilityCity: "Karnal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18213,
        Pin: 756083,
        DispatchCenter: "Basta_Darada_DPP (Orissa)",
        OriginCenter: "Basta_Darada_DPP (Orissa)",
        FacilityCity: "Basta",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18214,
        Pin: 533222,
        DispatchCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        OriginCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        FacilityCity: "Amalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18215,
        Pin: 414002,
        DispatchCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        OriginCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        FacilityCity: "Ahmednagar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18216,
        Pin: 481882,
        DispatchCenter: "Bajag_DindoriRd_DPP (Madhya Pradesh)",
        OriginCenter: "Bajag_DindoriRd_DPP (Madhya Pradesh)",
        FacilityCity: "Bajag",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18217,
        Pin: 785013,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18218,
        Pin: 637017,
        DispatchCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        OriginCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        FacilityCity: "Namakkal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18219,
        Pin: 627114,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18220,
        Pin: 757048,
        DispatchCenter: "Rairangpur_Sanchampauda_D (Orissa)",
        OriginCenter: "Rairangpur_Sanchampauda_D (Orissa)",
        FacilityCity: "Rairangpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18221,
        Pin: 122010,
        DispatchCenter: "Gurgaon_Sector52_L (Haryana)",
        OriginCenter: "Gurgaon_Sector52_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18222,
        Pin: 202128,
        DispatchCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        OriginCenter: "Aligarh_IndustrialEstate_I (Uttar Pradesh)",
        FacilityCity: "Aligarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18223,
        Pin: 803102,
        DispatchCenter: "Hilsa_Sryamndr_D (Bihar)",
        OriginCenter: "Hilsa_Sryamndr_D (Bihar)",
        FacilityCity: "Hilsa",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18224,
        Pin: 562161,
        DispatchCenter: "Channapatna_2ndCross_D (Karnataka)",
        OriginCenter: "Channapatna_2ndCross_D (Karnataka)",
        FacilityCity: "Channapatna",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18225,
        Pin: 721423,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18226,
        Pin: 303803,
        DispatchCenter: "Chomu_SamodRd_D (Rajasthan)",
        OriginCenter: "Chomu_SamodRd_D (Rajasthan)",
        FacilityCity: "Chomu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18227,
        Pin: 560013,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18228,
        Pin: 212204,
        DispatchCenter: "Sirathu_Ward4_DPP (Uttar Pradesh)",
        OriginCenter: "Sirathu_Ward4_DPP (Uttar Pradesh)",
        FacilityCity: "Sirathu",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18229,
        Pin: 743270,
        DispatchCenter: "Helencha_ColnyDPP_D (West Bengal)",
        OriginCenter: "Helencha_ColnyDPP_D (West Bengal)",
        FacilityCity: "Helencha",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18230,
        Pin: 323001,
        DispatchCenter: "Bundi_Central_D_1 (Rajasthan)",
        OriginCenter: "Bundi_Central_D_1 (Rajasthan)",
        FacilityCity: "Bundi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18231,
        Pin: 464220,
        DispatchCenter: "GanjBasoda_Mahavirvihar_DPP (Madhya Pradesh)",
        OriginCenter: "GanjBasoda_Mahavirvihar_DPP (Madhya Pradesh)",
        FacilityCity: "Ganj Basoda",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 18232,
        Pin: 385120,
        DispatchCenter: "Ambaji_Khodiyarchowk_DPP (Gujarat)",
        OriginCenter: "Ambaji_Khodiyarchowk_DPP (Gujarat)",
        FacilityCity: "Ambaji",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18233,
        Pin: 425110,
        DispatchCenter: "Jalgaon_Omnagar_D (Maharashtra)",
        OriginCenter: "Jalgaon_Omnagar_D (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18234,
        Pin: 686532,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18235,
        Pin: 842001,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18236,
        Pin: 507182,
        DispatchCenter: "Khammam_Mudigonda_H (Telangana)",
        OriginCenter: "Khammam_Mudigonda_H (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18237,
        Pin: 122003,
        DispatchCenter: "Gurgaon_Sector52_L (Haryana)",
        OriginCenter: "Gurgaon_Sector52_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18238,
        Pin: 502300,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18239,
        Pin: 829116,
        DispatchCenter: "Phusro_RhmgjDPP_D (Jharkhand)",
        OriginCenter: "Phusro_RhmgjDPP_D (Jharkhand)",
        FacilityCity: "Phusro",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18240,
        Pin: 416209,
        DispatchCenter: "Mudal_Adamapur_DPP (Maharashtra)",
        OriginCenter: "Mudal_Adamapur_DPP (Maharashtra)",
        FacilityCity: "Mudal",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18241,
        Pin: 431709,
        DispatchCenter: "Mukhed_Naigaon_DPP (Maharashtra)",
        OriginCenter: "Mukhed_Naigaon_DPP (Maharashtra)",
        FacilityCity: "Mukhed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18242,
        Pin: 380026,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18243,
        Pin: 415001,
        DispatchCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        OriginCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        FacilityCity: "Satara",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18244,
        Pin: 606803,
        DispatchCenter: "Polur_Kanji_D (Tamil Nadu)",
        OriginCenter: "Polur_Kanji_D (Tamil Nadu)",
        FacilityCity: "Polur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18245,
        Pin: 177113,
        DispatchCenter: "Chintpurni_Bharwain_D (Himachal Pradesh)",
        OriginCenter: "Chintpurni_Bharwain_D (Himachal Pradesh)",
        FacilityCity: "Chintpurni",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18246,
        Pin: 480998,
        DispatchCenter: "Lakhnadon_Dhanora_DPP (Madhya Pradesh)",
        OriginCenter: "Lakhnadon_Dhanora_DPP (Madhya Pradesh)",
        FacilityCity: "Lakhnadon",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18247,
        Pin: 517582,
        DispatchCenter: "Puttur_Artmclny_D (Andhra Pradesh)",
        OriginCenter: "Puttur_Artmclny_D (Andhra Pradesh)",
        FacilityCity: "Puttur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18248,
        Pin: 782103,
        DispatchCenter: "Nagaon_Bangthai_H (Assam)",
        OriginCenter: "Nagaon_Bangthai_H (Assam)",
        FacilityCity: "Nagaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18249,
        Pin: 403405,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18250,
        Pin: 496116,
        DispatchCenter: "Dharamjaigarh_ParaRd_D (Chhattisgarh)",
        OriginCenter: "Dharamjaigarh_ParaRd_D (Chhattisgarh)",
        FacilityCity: "Dharamjaigarh",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18251,
        Pin: 334802,
        DispatchCenter: "Nokha_Ward17_D (Rajasthan)",
        OriginCenter: "Nokha_Ward17_D (Rajasthan)",
        FacilityCity: "Nokha",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18252,
        Pin: 173002,
        DispatchCenter: "shimla (Himachal Pradesh)",
        OriginCenter: "shimla (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18253,
        Pin: 274202,
        DispatchCenter: "Deoria_Indupur_D (Uttar Pradesh)",
        OriginCenter: "Deoria_Indupur_D (Uttar Pradesh)",
        FacilityCity: "Deoria",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18254,
        Pin: 614401,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18255,
        Pin: 388052,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18256,
        Pin: 835305,
        DispatchCenter: "Lohardaga_Devcolony_D (Jharkhand)",
        OriginCenter: "Lohardaga_Devcolony_D (Jharkhand)",
        FacilityCity: "Lohardaga",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18257,
        Pin: 221116,
        DispatchCenter: "Aunrihar_JawahrNgr_D (Uttar Pradesh)",
        OriginCenter: "Aunrihar_JawahrNgr_D (Uttar Pradesh)",
        FacilityCity: "Aunrihar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18258,
        Pin: 523252,
        DispatchCenter: "Podili_Sravaniestate_D (Andhra Pradesh)",
        OriginCenter: "Podili_Sravaniestate_D (Andhra Pradesh)",
        FacilityCity: "Podili",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18259,
        Pin: 413309,
        DispatchCenter: "Sangola_PatilChowk_DPP (Maharashtra)",
        OriginCenter: "Sangola_PatilChowk_DPP (Maharashtra)",
        FacilityCity: "Sangola",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18260,
        Pin: 695564,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18261,
        Pin: 691585,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18262,
        Pin: 241406,
        DispatchCenter: "Pihani_Katrabzr_D (Uttar Pradesh)",
        OriginCenter: "Pihani_Katrabzr_D (Uttar Pradesh)",
        FacilityCity: "Pihani",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18263,
        Pin: 756168,
        DispatchCenter: "Bhadrak_Deuli_D (Orissa)",
        OriginCenter: "Bhadrak_Deuli_D (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18264,
        Pin: 212108,
        DispatchCenter: "JariBazar_Gambhirpur_D (Uttar Pradesh)",
        OriginCenter: "JariBazar_Gambhirpur_D (Uttar Pradesh)",
        FacilityCity: "JariBazar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18265,
        Pin: 177301,
        DispatchCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        OriginCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        FacilityCity: "Nadaun",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18266,
        Pin: 721153,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18267,
        Pin: 766020,
        DispatchCenter: "Muniguda_Ambodola_DPP (Orissa)",
        OriginCenter: "Muniguda_Ambodola_DPP (Orissa)",
        FacilityCity: "Muniguda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18268,
        Pin: 392135,
        DispatchCenter: "Dahej_Vadadla_D (Gujarat)",
        OriginCenter: "Dahej_Vadadla_D (Gujarat)",
        FacilityCity: "Dahej",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18269,
        Pin: 639136,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18270,
        Pin: 516349,
        DispatchCenter: "Pulivendula_Bakarapuram_D (Andhra Pradesh)",
        OriginCenter: "Pulivendula_Bakarapuram_D (Andhra Pradesh)",
        FacilityCity: "Pulivendula",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18271,
        Pin: 786183,
        DispatchCenter: "Chabua_CollgeRD_D (Assam)",
        OriginCenter: "Chabua_CollgeRD_D (Assam)",
        FacilityCity: "Chabua",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18272,
        Pin: 387310,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18273,
        Pin: 844139,
        DispatchCenter: "Hazratjandaha_Hirpur_D (Bihar)",
        OriginCenter: "Hazratjandaha_Hirpur_D (Bihar)",
        FacilityCity: "Hazrat Jandaha",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18274,
        Pin: 754293,
        DispatchCenter: "Cuttack_SliprDPP_DC (Orissa)",
        OriginCenter: "Cuttack_SliprDPP_DC (Orissa)",
        FacilityCity: "Salipur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18275,
        Pin: 462006,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18276,
        Pin: 591181,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18277,
        Pin: 184203,
        DispatchCenter: "Billawar_Wardno7_D (Jammu & Kashmir)",
        OriginCenter: "Billawar_Wardno7_D (Jammu & Kashmir)",
        FacilityCity: "Billawar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18278,
        Pin: 571402,
        DispatchCenter: "Mandya_Dc (Karnataka)",
        OriginCenter: "Mandya_Dc (Karnataka)",
        FacilityCity: "Mandya",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18279,
        Pin: 834009,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18280,
        Pin: 835302,
        DispatchCenter: "Lohardaga_Devcolony_D (Jharkhand)",
        OriginCenter: "Lohardaga_Devcolony_D (Jharkhand)",
        FacilityCity: "Lohardaga",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18281,
        Pin: 481331,
        DispatchCenter: "Waraseoni_Lalbarra_D (Madhya Pradesh)",
        OriginCenter: "Waraseoni_Lalbarra_D (Madhya Pradesh)",
        FacilityCity: "Waraseoni",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 18282,
        Pin: 523201,
        DispatchCenter: "Addanki_AbhyudayaNagar_D (Andhra Pradesh)",
        OriginCenter: "Addanki_AbhyudayaNagar_D (Andhra Pradesh)",
        FacilityCity: "Addanki",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18283,
        Pin: 788720,
        DispatchCenter: "Karimganj_Andharibond_D (Assam)",
        OriginCenter: "Karimganj_Andharibond_D (Assam)",
        FacilityCity: "Karimganj",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18284,
        Pin: 124108,
        DispatchCenter: "Gurgaon_Farukhnagar_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18285,
        Pin: 767027,
        DispatchCenter: "Patnagarh_Beherabandh_D (Orissa)",
        OriginCenter: "Patnagarh_Beherabandh_D (Orissa)",
        FacilityCity: "Patnagarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18286,
        Pin: 741138,
        DispatchCenter: "Nakashipara_Kanthalbaria_DPP (West Bengal)",
        OriginCenter: "Nakashipara_Kanthalbaria_DPP (West Bengal)",
        FacilityCity: "Nakashipara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18287,
        Pin: 629852,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18288,
        Pin: 802314,
        DispatchCenter: "Arrah_Sakaddi_D (Bihar)",
        OriginCenter: "Arrah_Sakaddi_D (Bihar)",
        FacilityCity: "Arrah",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18289,
        Pin: 415414,
        DispatchCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        OriginCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        FacilityCity: "Islampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18290,
        Pin: 480884,
        DispatchCenter: "Lakhnadon_Ward5_D (Madhya Pradesh)",
        OriginCenter: "Lakhnadon_Ward5_D (Madhya Pradesh)",
        FacilityCity: "Lakhnadon",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 18291,
        Pin: 502316,
        DispatchCenter: "NarsapurTS_MarketRd_D (Telangana)",
        OriginCenter: "NarsapurTS_MarketRd_D (Telangana)",
        FacilityCity: "Narsapur-TS",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18292,
        Pin: 825303,
        DispatchCenter: "Hazaribagh_Matwari_D (Jharkhand)",
        OriginCenter: "Hazaribagh_Matwari_D (Jharkhand)",
        FacilityCity: "hazaribagh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18293,
        Pin: 425448,
        DispatchCenter: "Shahada_Untavad_D (Maharashtra)",
        OriginCenter: "Shahada_Untavad_D (Maharashtra)",
        FacilityCity: "Shahada",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18294,
        Pin: 609112,
        DispatchCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        OriginCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        FacilityCity: "Sirkazhi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18295,
        Pin: 670307,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18296,
        Pin: 531027,
        DispatchCenter: "Chodavaram_Narasayyapeta_D (Andhra Pradesh)",
        OriginCenter: "Chodavaram_Narasayyapeta_D (Andhra Pradesh)",
        FacilityCity: "Chodavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18297,
        Pin: 760001,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18298,
        Pin: 466002,
        DispatchCenter: "Sehore_Sherpur_D (Madhya Pradesh)",
        OriginCenter: "Sehore_Sherpur_D (Madhya Pradesh)",
        FacilityCity: "Sehore",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 18299,
        Pin: 700030,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18300,
        Pin: 413716,
        DispatchCenter: "Rahuri_Mehetremala_D (Maharashtra)",
        OriginCenter: "Rahuri_Mehetremala_D (Maharashtra)",
        FacilityCity: "Rahuri",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18301,
        Pin: 360585,
        DispatchCenter: "Porbandar_Vadiplot_D (Gujarat)",
        OriginCenter: "Porbandar_Vadiplot_D (Gujarat)",
        FacilityCity: "Porbandar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18302,
        Pin: 754285,
        DispatchCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        OriginCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        FacilityCity: "Chandikhol",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18303,
        Pin: 182301,
        DispatchCenter: "Katra_Central_D_1 (Jammu & Kashmir)",
        OriginCenter: "Katra_Central_D_1 (Jammu & Kashmir)",
        FacilityCity: "Katra",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18304,
        Pin: 796181,
        DispatchCenter: "Serchhip_FireStation_D (Mizoram)",
        OriginCenter: "Serchhip_FireStation_D (Mizoram)",
        FacilityCity: "Serchhip",
        FacilityState: "Mizoram",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18305,
        Pin: 832104,
        DispatchCenter: "Ghatshila_Ekramcolony_D (Jharkhand)",
        OriginCenter: "Ghatshila_Ekramcolony_D (Jharkhand)",
        FacilityCity: "Ghatshila",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18306,
        Pin: 383345,
        DispatchCenter: "Modasa_Shyamnagar_D (Gujarat)",
        OriginCenter: "Modasa_Shyamnagar_D (Gujarat)",
        FacilityCity: "Modasa",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18307,
        Pin: 332411,
        DispatchCenter: "Reengus_TegoreViharColony_D (Rajasthan)",
        OriginCenter: "Reengus_TegoreViharColony_D (Rajasthan)",
        FacilityCity: "Reengus",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18308,
        Pin: 632114,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18309,
        Pin: 535580,
        DispatchCenter: "Gajapatinagaram_SaibabaTmpl_DPP (Andhra Pradesh)",
        OriginCenter: "Gajapatinagaram_SaibabaTmpl_DPP (Andhra Pradesh)",
        FacilityCity: "Gajapatinagaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18310,
        Pin: 577542,
        DispatchCenter: "Hosadurga_Vidyanagara_D (Karnataka)",
        OriginCenter: "Hosadurga_Vidyanagara_D (Karnataka)",
        FacilityCity: "Hosadurga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18311,
        Pin: 581203,
        DispatchCenter: "Hangal_PalaRd_D (Karnataka)",
        OriginCenter: "Hangal_PalaRd_D (Karnataka)",
        FacilityCity: "Hangal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18312,
        Pin: 387630,
        DispatchCenter: "Kapadvanj_Dholikui_D (Gujarat)",
        OriginCenter: "Kapadvanj_Dholikui_D (Gujarat)",
        FacilityCity: "Kapadvanj",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18313,
        Pin: 612203,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18314,
        Pin: 571134,
        DispatchCenter: "Hunsur_IndustrialEstate_D (Karnataka)",
        OriginCenter: "Hunsur_IndustrialEstate_D (Karnataka)",
        FacilityCity: "Hunsur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18315,
        Pin: 531022,
        DispatchCenter: "Seetharampram_Lakkavarapukota_DPP (Andhra Pradesh)",
        OriginCenter: "Seetharampram_Lakkavarapukota_DPP (Andhra Pradesh)",
        FacilityCity: "Seetharampram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18316,
        Pin: 626716,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18317,
        Pin: 415727,
        DispatchCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        OriginCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        FacilityCity: "Khed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18318,
        Pin: 411019,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18319,
        Pin: 760008,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18320,
        Pin: 626105,
        DispatchCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        OriginCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        FacilityCity: "Aruppukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18321,
        Pin: 629154,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18322,
        Pin: 743700,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18323,
        Pin: 636602,
        DispatchCenter: "Dharmapuri_Thoppur_D (Tamil Nadu)",
        OriginCenter: "Dharmapuri_Thoppur_D (Tamil Nadu)",
        FacilityCity: "Dharmapuri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18324,
        Pin: 400707,
        DispatchCenter: "Jasai_Vidhane_L (Maharashtra)",
        OriginCenter: "Jasai_Vidhane_L (Maharashtra)",
        FacilityCity: "Jasai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18325,
        Pin: 521250,
        DispatchCenter: "Vuyyuru_BhogdDPP_D (Andhra Pradesh)",
        OriginCenter: "Vuyyuru_BhogdDPP_D (Andhra Pradesh)",
        FacilityCity: "Vuyyuru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18326,
        Pin: 792105,
        DispatchCenter: "Namsai_Mahadevpur_D (Arunachal Pradesh)",
        OriginCenter: "Namsai_Mahadevpur_D (Arunachal Pradesh)",
        FacilityCity: "Namsai",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18327,
        Pin: 246130,
        DispatchCenter: "Paithani_Siloli_DPP (Uttarakhand)",
        OriginCenter: "Paithani_Siloli_DPP (Uttarakhand)",
        FacilityCity: "Paithani",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18328,
        Pin: 110043,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18329,
        Pin: 679561,
        DispatchCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        OriginCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18330,
        Pin: 344708,
        DispatchCenter: "Dhorimana_VishnuColony_D (Rajasthan)",
        OriginCenter: "Dhorimana_VishnuColony_D (Rajasthan)",
        FacilityCity: "Dhorimana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18331,
        Pin: 625008,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18332,
        Pin: 606604,
        DispatchCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        OriginCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        FacilityCity: "Tiruvannamalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18333,
        Pin: 176312,
        DispatchCenter: "Chamba_Sundla_D (Himachal Pradesh)",
        OriginCenter: "Chamba_Sundla_D (Himachal Pradesh)",
        FacilityCity: "Sundla",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18334,
        Pin: 274406,
        DispatchCenter: "Sewarhi_SaragatiaKaran_D (Uttar Pradesh)",
        OriginCenter: "Sewarhi_SaragatiaKaran_D (Uttar Pradesh)",
        FacilityCity: "Sewarhi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18335,
        Pin: 695504,
        DispatchCenter: "Trivandrum_Neyatinkra_D (Kerala)",
        OriginCenter: "Trivandrum_Neyatinkra_D (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18336,
        Pin: 333022,
        DispatchCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        OriginCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        FacilityCity: "Udaipurwati",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18337,
        Pin: 691310,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18338,
        Pin: 636111,
        DispatchCenter: "Mettala_Rasipuram_D (Tamil Nadu)",
        OriginCenter: "Mettala_Rasipuram_D (Tamil Nadu)",
        FacilityCity: "Mettala",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18339,
        Pin: 751054,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18340,
        Pin: 301701,
        DispatchCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        OriginCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        FacilityCity: "Neemrana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18341,
        Pin: 232336,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18342,
        Pin: 110605,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18343,
        Pin: 388150,
        DispatchCenter: "Tarapur_Darukhanu_DPP (Gujarat)",
        OriginCenter: "Tarapur_Darukhanu_DPP (Gujarat)",
        FacilityCity: "Tarapur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18344,
        Pin: 476337,
        DispatchCenter: "Sheopur_Salapura_DPP (Madhya Pradesh)",
        OriginCenter: "Sheopur_Salapura_DPP (Madhya Pradesh)",
        FacilityCity: "Sheopur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18345,
        Pin: 442402,
        DispatchCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        OriginCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        FacilityCity: "Chandrapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18346,
        Pin: 382424,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18347,
        Pin: 370020,
        DispatchCenter: "Bhuj_Madhapar_D (Gujarat)",
        OriginCenter: "Bhuj_Madhapar_D (Gujarat)",
        FacilityCity: "Bhuj",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18348,
        Pin: 284121,
        DispatchCenter: "Chirgaon_RamnagarRoad_D (Uttar Pradesh)",
        OriginCenter: "Chirgaon_RamnagarRoad_D (Uttar Pradesh)",
        FacilityCity: "Chirgaon",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18349,
        Pin: 391430,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18350,
        Pin: 516270,
        DispatchCenter: "Rayachoti_Chennamukkapalle_D (Andhra Pradesh)",
        OriginCenter: "Rayachoti_Chennamukkapalle_D (Andhra Pradesh)",
        FacilityCity: "Rayachoti",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18351,
        Pin: 502345,
        DispatchCenter: "Zahirabad_Jharasangam_D (Telangana)",
        OriginCenter: "Zahirabad_Jharasangam_D (Telangana)",
        FacilityCity: "Zahirabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18352,
        Pin: 788701,
        DispatchCenter: "Karimganj_Gorkapan_D (Assam)",
        OriginCenter: "Karimganj_Gorkapan_D (Assam)",
        FacilityCity: "Karimganj",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18353,
        Pin: 272204,
        DispatchCenter: "Naugarh_Trtribazar_D (Uttar Pradesh)",
        OriginCenter: "Naugarh_Trtribazar_D (Uttar Pradesh)",
        FacilityCity: "Naugarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18354,
        Pin: 689504,
        DispatchCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        OriginCenter: "Pathanamthitta_Kulanada_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18355,
        Pin: 481668,
        DispatchCenter: "Mandla_Nainpur_D (Madhya Pradesh)",
        OriginCenter: "Mandla_Nainpur_D (Madhya Pradesh)",
        FacilityCity: "Mandla",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18356,
        Pin: 670633,
        DispatchCenter: "Chavassery_Malapattam_D (Kerala)",
        OriginCenter: "Chavassery_Malapattam_D (Kerala)",
        FacilityCity: "Chavassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18357,
        Pin: 583114,
        DispatchCenter: "Siruguppa_Yamaha_D (Karnataka)",
        OriginCenter: "Siruguppa_Yamaha_D (Karnataka)",
        FacilityCity: "Siruguppa",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18358,
        Pin: 847213,
        DispatchCenter: "Madhubani_NidhiChowk_D (Bihar)",
        OriginCenter: "Madhubani_NidhiChowk_D (Bihar)",
        FacilityCity: "Madhubani",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18359,
        Pin: 277001,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18360,
        Pin: 203205,
        DispatchCenter: "Sikandrabad_Khatriwara_D (Uttar Pradesh)",
        OriginCenter: "Sikandrabad_Khatriwara_D (Uttar Pradesh)",
        FacilityCity: "Sikandrabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18361,
        Pin: 563132,
        DispatchCenter: "Mulbagal_Sunku_D (Karnataka)",
        OriginCenter: "Mulbagal_Sunku_D (Karnataka)",
        FacilityCity: "Mulbagal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18362,
        Pin: 522341,
        DispatchCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        OriginCenter: "Guntur_Gajulapalem_D (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18363,
        Pin: 577419,
        DispatchCenter: "Shiralakoppa_Anavatti_DPP (Karnataka)",
        OriginCenter: "Shiralakoppa_Anavatti_DPP (Karnataka)",
        FacilityCity: "Shiralakoppa",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18364,
        Pin: 721504,
        DispatchCenter: "Jhargram_Raghunathpur_DPP (West Bengal)",
        OriginCenter: "Jhargram_Raghunathpur_DPP (West Bengal)",
        FacilityCity: "Jhargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18365,
        Pin: 532456,
        DispatchCenter: "KotturuAP_NivagamRd_DPP (Andhra Pradesh)",
        OriginCenter: "KotturuAP_NivagamRd_DPP (Andhra Pradesh)",
        FacilityCity: "Kotturu-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18366,
        Pin: 456664,
        DispatchCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        OriginCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        FacilityCity: "Ujjain",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18367,
        Pin: 524366,
        DispatchCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        OriginCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18368,
        Pin: 505401,
        DispatchCenter: "KarimNagar_Alugunoor_I (Telangana)",
        OriginCenter: "KarimNagar_Alugunoor_I (Telangana)",
        FacilityCity: "Karim Nagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18369,
        Pin: 470661,
        DispatchCenter: "Damoh_Chouprakhurd_D (Madhya Pradesh)",
        OriginCenter: "Damoh_Chouprakhurd_D (Madhya Pradesh)",
        FacilityCity: "Damoh",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 18370,
        Pin: 535270,
        DispatchCenter: "Gajapatinagaram_SaibabaTmpl_DPP (Andhra Pradesh)",
        OriginCenter: "Gajapatinagaram_SaibabaTmpl_DPP (Andhra Pradesh)",
        FacilityCity: "Gajapatinagaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18371,
        Pin: 412312,
        DispatchCenter: "Nira_Central_D (Maharashtra)",
        OriginCenter: "Nira_Central_D (Maharashtra)",
        FacilityCity: "Nira",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18372,
        Pin: 627451,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18373,
        Pin: 462001,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18374,
        Pin: 491009,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18375,
        Pin: 781328,
        DispatchCenter: "Tihu_Jalahgaon_D (Assam)",
        OriginCenter: "Tihu_Jalahgaon_D (Assam)",
        FacilityCity: "Tihu",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18376,
        Pin: 642001,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18377,
        Pin: 273405,
        DispatchCenter: "Kauriram_Sonbarsa_DPP (Uttar Pradesh)",
        OriginCenter: "Kauriram_Sonbarsa_DPP (Uttar Pradesh)",
        FacilityCity: "Kauriram",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18378,
        Pin: 534467,
        DispatchCenter: "Chintalpudi_KMRd_D (Andhra Pradesh)",
        OriginCenter: "Chintalpudi_KMRd_D (Andhra Pradesh)",
        FacilityCity: "Chintalpudi",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18379,
        Pin: 444501,
        DispatchCenter: "Akola_Midcphase2_D (Maharashtra)",
        OriginCenter: "Akola_Midcphase2_D (Maharashtra)",
        FacilityCity: "Akola",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18380,
        Pin: 673007,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18381,
        Pin: 582115,
        DispatchCenter: "Gadag_Vidyanagar_D (Karnataka)",
        OriginCenter: "Gadag_Vidyanagar_D (Karnataka)",
        FacilityCity: "Gadag",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18382,
        Pin: 784025,
        DispatchCenter: "Tezpur_Dipota_H (Assam)",
        OriginCenter: "Tezpur_Dipota_H (Assam)",
        FacilityCity: "Tezpur",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18383,
        Pin: 585372,
        DispatchCenter: "Chincholi_CKRd_D (Karnataka)",
        OriginCenter: "Chincholi_CKRd_D (Karnataka)",
        FacilityCity: "Chincholi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18384,
        Pin: 581108,
        DispatchCenter: "Haveri_Guttal_D (Karnataka)",
        OriginCenter: "Haveri_Guttal_D (Karnataka)",
        FacilityCity: "Haveri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18385,
        Pin: 123001,
        DispatchCenter: "Narnaul_Keshavnagar_D (Haryana)",
        OriginCenter: "Narnaul_Keshavnagar_D (Haryana)",
        FacilityCity: "Narnaul",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18386,
        Pin: 522310,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18387,
        Pin: 814147,
        DispatchCenter: "Mahagama_Teachercolony_DPP (Jharkhand)",
        OriginCenter: "Mahagama_Teachercolony_DPP (Jharkhand)",
        FacilityCity: "Mahagama",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18388,
        Pin: 754133,
        DispatchCenter: "Jagatsinghpur_Bnbrda_D (Orissa)",
        OriginCenter: "Jagatsinghpur_Bnbrda_D (Orissa)",
        FacilityCity: "Jagatsinghpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18389,
        Pin: 443001,
        DispatchCenter: "Buldhana_Sanenagar_DPP (Maharashtra)",
        OriginCenter: "Buldhana_Sanenagar_DPP (Maharashtra)",
        FacilityCity: "Buldhana",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18390,
        Pin: 614017,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18391,
        Pin: 829127,
        DispatchCenter: "Phusro_RhmgjDPP_D (Jharkhand)",
        OriginCenter: "Phusro_RhmgjDPP_D (Jharkhand)",
        FacilityCity: "Phusro",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18392,
        Pin: 627821,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18393,
        Pin: 635752,
        DispatchCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ambur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18394,
        Pin: 209305,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18395,
        Pin: 225405,
        DispatchCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        OriginCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        FacilityCity: "Dariyabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18396,
        Pin: 622411,
        DispatchCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18397,
        Pin: 670571,
        DispatchCenter: "Alakode_Thavukunnu_D (Kerala)",
        OriginCenter: "Alakode_Thavukunnu_D (Kerala)",
        FacilityCity: "Alakode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18398,
        Pin: 676505,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18399,
        Pin: 403728,
        DispatchCenter: "Canacona_Shristal_D (Goa)",
        OriginCenter: "Canacona_Shristal_D (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18400,
        Pin: 825418,
        DispatchCenter: "Domchanch_Koderma_D (Jharkhand)",
        OriginCenter: "Domchanch_Koderma_D (Jharkhand)",
        FacilityCity: "Domchanch",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18401,
        Pin: 721305,
        DispatchCenter: "Kharagpur_Nimpura_I (West Bengal)",
        OriginCenter: "Kharagpur_Nimpura_I (West Bengal)",
        FacilityCity: "Kharagpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18402,
        Pin: 533016,
        DispatchCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        OriginCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        FacilityCity: "Kakinada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18403,
        Pin: 151214,
        DispatchCenter: "Faridkot_JatinderChwk_D (Punjab)",
        OriginCenter: "Faridkot_JatinderChwk_D (Punjab)",
        FacilityCity: "Faridkot",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18404,
        Pin: 606601,
        DispatchCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        OriginCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        FacilityCity: "Tiruvannamalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18405,
        Pin: 390015,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18406,
        Pin: 627855,
        DispatchCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        OriginCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        FacilityCity: "Sankarankovil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18407,
        Pin: 630205,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18408,
        Pin: 201301,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18409,
        Pin: 208009,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18410,
        Pin: 416306,
        DispatchCenter: "Sangli_Dattanagar_D (Maharashtra)",
        OriginCenter: "Sangli_Dattanagar_D (Maharashtra)",
        FacilityCity: "Sangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18411,
        Pin: 736133,
        DispatchCenter: "CoochBehar_khagrabari_I (West Bengal)",
        OriginCenter: "CoochBehar_khagrabari_I (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18412,
        Pin: 246441,
        DispatchCenter: "Tharali_Kulsari_D (Uttarakhand)",
        OriginCenter: "Tharali_Kulsari_D (Uttarakhand)",
        FacilityCity: "Tharali",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18413,
        Pin: 247451,
        DispatchCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        OriginCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        FacilityCity: "Saharanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18414,
        Pin: 673613,
        DispatchCenter: "Balussery_Sivapuram_D (Kerala)",
        OriginCenter: "Balussery_Sivapuram_D (Kerala)",
        FacilityCity: "Balussery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18415,
        Pin: 123031,
        DispatchCenter: "MahendragarhHR_Khaira_D (Haryana)",
        OriginCenter: "MahendragarhHR_Khaira_D (Haryana)",
        FacilityCity: "Mahendragarh-HR",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18416,
        Pin: 784179,
        DispatchCenter: "Behali_PostOfc_D (Assam)",
        OriginCenter: "Behali_PostOfc_D (Assam)",
        FacilityCity: "Behali",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18417,
        Pin: 148023,
        DispatchCenter: "Malerkotla_Sajidacolony_D (Punjab)",
        OriginCenter: "Malerkotla_Sajidacolony_D (Punjab)",
        FacilityCity: "Malerkotla",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18418,
        Pin: 767071,
        DispatchCenter: "Balangir_PalaceLine_D (Orissa)",
        OriginCenter: "Balangir_PalaceLine_D (Orissa)",
        FacilityCity: "Balangir",
        FacilityState: "Orissa",
        Zone : "NE"
      },
      {
        SN: 18419,
        Pin: 504308,
        DispatchCenter: "Adilabad_KailashNagar_D (Telangana)",
        OriginCenter: "Adilabad_KailashNagar_D (Telangana)",
        FacilityCity: "Adilabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18420,
        Pin: 343040,
        DispatchCenter: "Bhinmal_RaniwaraKhurd_D (Rajasthan)",
        OriginCenter: "Bhinmal_RaniwaraKhurd_D (Rajasthan)",
        FacilityCity: "Raniwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18421,
        Pin: 842009,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18422,
        Pin: 854206,
        DispatchCenter: "Purnia_Banmankhi_D (Bihar)",
        OriginCenter: "Purnia_Banmankhi_D (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18423,
        Pin: 342014,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18424,
        Pin: 470117,
        DispatchCenter: "Khurai_Ward11_DPP (Madhya Pradesh)",
        OriginCenter: "Khurai_Ward11_DPP (Madhya Pradesh)",
        FacilityCity: "Khurai",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 18425,
        Pin: 321610,
        DispatchCenter: "Mahwa_Allapur_D (Rajasthan)",
        OriginCenter: "Mahwa_Allapur_D (Rajasthan)",
        FacilityCity: "Mahwa",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18426,
        Pin: 605012,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18427,
        Pin: 802113,
        DispatchCenter: "Buxar_Ashaparari_DPP (Bihar)",
        OriginCenter: "Buxar_Ashaparari_DPP (Bihar)",
        FacilityCity: "Buxar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18428,
        Pin: 756084,
        DispatchCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        OriginCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        FacilityCity: "Dantan",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18429,
        Pin: 625125,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18430,
        Pin: 767030,
        DispatchCenter: "Saintala_Blockroad_DPP (Orissa)",
        OriginCenter: "Saintala_Blockroad_DPP (Orissa)",
        FacilityCity: "Saintala",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18431,
        Pin: 673623,
        DispatchCenter: "Koyilandy_Komathukara_D (Kerala)",
        OriginCenter: "Koyilandy_Komathukara_D (Kerala)",
        FacilityCity: "Koyilandy",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18432,
        Pin: 586206,
        DispatchCenter: "Sindagi_Bijapurrd_D (Karnataka)",
        OriginCenter: "Sindagi_Bijapurrd_D (Karnataka)",
        FacilityCity: "Sindagi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18433,
        Pin: 393145,
        DispatchCenter: "Rajpipla_DSPOffice_DPP (Gujarat)",
        OriginCenter: "Rajpipla_DSPOffice_DPP (Gujarat)",
        FacilityCity: "Rajpipla",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18434,
        Pin: 175122,
        DispatchCenter: "Nagwain_Nalagarh_DPP (Himachal Pradesh)",
        OriginCenter: "Nagwain_Nalagarh_DPP (Himachal Pradesh)",
        FacilityCity: "Nagwain",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18435,
        Pin: 400050,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18436,
        Pin: 670622,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18437,
        Pin: 225404,
        DispatchCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        OriginCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        FacilityCity: "Dariyabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18438,
        Pin: 494777,
        DispatchCenter: "Pakhanjur_ForestColony_D (Chhattisgarh)",
        OriginCenter: "Pakhanjur_ForestColony_D (Chhattisgarh)",
        FacilityCity: "Pakhanjur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18439,
        Pin: 403512,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18440,
        Pin: 301024,
        DispatchCenter: "Bansur_TransportNagar_D (Rajasthan)",
        OriginCenter: "Bansur_TransportNagar_D (Rajasthan)",
        FacilityCity: "Bansur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18441,
        Pin: 814111,
        DispatchCenter: "Maheshpur_Grmnbank_D (Jharkhand)",
        OriginCenter: "Maheshpur_Grmnbank_D (Jharkhand)",
        FacilityCity: "Maheshpur",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18442,
        Pin: 641105,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18443,
        Pin: 561228,
        DispatchCenter: "Gauribidanur_Srinagara_D (Karnataka)",
        OriginCenter: "Gauribidanur_Srinagara_D (Karnataka)",
        FacilityCity: "Gauribidanur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18444,
        Pin: 695606,
        DispatchCenter: "Venjaramoodu_Palavila_D (Kerala)",
        OriginCenter: "Venjaramoodu_Palavila_D (Kerala)",
        FacilityCity: "Venjaramoodu",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18445,
        Pin: 627801,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18446,
        Pin: 403715,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18447,
        Pin: 228172,
        DispatchCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        OriginCenter: "Jalalpur_Jaukabad_D (Uttar Pradesh)",
        FacilityCity: "Jalalpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18448,
        Pin: 623205,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18449,
        Pin: 700044,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18450,
        Pin: 362725,
        DispatchCenter: "Kodinar_NCplxDPP_D (Gujarat)",
        OriginCenter: "Kodinar_NCplxDPP_D (Gujarat)",
        FacilityCity: "Kodinar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18451,
        Pin: 711308,
        DispatchCenter: "Howrah_Temp_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18452,
        Pin: 505472,
        DispatchCenter: "Husnabad_HanmakondaRd_D (Telangana)",
        OriginCenter: "Husnabad_HanmakondaRd_D (Telangana)",
        FacilityCity: "Husnabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18453,
        Pin: 382760,
        DispatchCenter: "Patdi_Laxminagar_DPP (Gujarat)",
        OriginCenter: "Patdi_Laxminagar_DPP (Gujarat)",
        FacilityCity: "Patdi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18454,
        Pin: 833105,
        DispatchCenter: "Chakradhar_Sitaram_D (Jharkhand)",
        OriginCenter: "Chakradhar_Sitaram_D (Jharkhand)",
        FacilityCity: "Chakradharpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18455,
        Pin: 494347,
        DispatchCenter: "Keshkal_Surdongar_D (Chhattisgarh)",
        OriginCenter: "Keshkal_Surdongar_D (Chhattisgarh)",
        FacilityCity: "keshkal",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18456,
        Pin: 678531,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18457,
        Pin: 460554,
        DispatchCenter: "Amla_Bodhki_D (Madhya Pradesh)",
        OriginCenter: "Amla_Bodhki_D (Madhya Pradesh)",
        FacilityCity: "Amla",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 18458,
        Pin: 233001,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18459,
        Pin: 722201,
        DispatchCenter: "Arambag_Bulchandrapur_DPP (West Bengal)",
        OriginCenter: "Arambag_Bulchandrapur_DPP (West Bengal)",
        FacilityCity: "Arambag",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18460,
        Pin: 335002,
        DispatchCenter: "Ganganagar_Ricco_D (Rajasthan)",
        OriginCenter: "Ganganagar_Ricco_D (Rajasthan)",
        FacilityCity: "Ganga Nagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18461,
        Pin: 721126,
        DispatchCenter: "Lutunia_Bholenthtmpl_D (West Bengal)",
        OriginCenter: "Lutunia_Bholenthtmpl_D (West Bengal)",
        FacilityCity: "Lutunia",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18462,
        Pin: 560030,
        DispatchCenter: "Bangalore_Temp1_L (Karnataka)",
        OriginCenter: "Bangalore_Temp1_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18463,
        Pin: 626132,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18464,
        Pin: 400086,
        DispatchCenter: "Mumbai_Chembur_L (Maharashtra)",
        OriginCenter: "Mumbai_Chembur_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18465,
        Pin: 623404,
        DispatchCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        OriginCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        FacilityCity: "Thiruvadanai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18466,
        Pin: 360560,
        DispatchCenter: "Porbandar_Vadiplot_D (Gujarat)",
        OriginCenter: "Porbandar_Vadiplot_D (Gujarat)",
        FacilityCity: "Porbandar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18467,
        Pin: 831002,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18468,
        Pin: 641603,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18469,
        Pin: 382327,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18470,
        Pin: 303109,
        DispatchCenter: "Dausa_Aandhi_D (Rajasthan)",
        OriginCenter: "Dausa_Aandhi_D (Rajasthan)",
        FacilityCity: "Dausa",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18471,
        Pin: 638007,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18472,
        Pin: 574301,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18473,
        Pin: 400613,
        DispatchCenter: "Mumbai_Turbhe_L (Maharashtra)",
        OriginCenter: "Mumbai_Turbhe_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18474,
        Pin: 229305,
        DispatchCenter: "Gauriganj_Jais_D (Uttar Pradesh)",
        OriginCenter: "Gauriganj_Jais_D (Uttar Pradesh)",
        FacilityCity: "Gauriganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18475,
        Pin: 458389,
        DispatchCenter: "Mandsaur_Sitamau_DPP (Madhya Pradesh)",
        OriginCenter: "Mandsaur_Sitamau_DPP (Madhya Pradesh)",
        FacilityCity: "Mandsaur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18476,
        Pin: 442907,
        DispatchCenter: "Wani_GuruNGR_D (Maharashtra)",
        OriginCenter: "Wani_GuruNGR_D (Maharashtra)",
        FacilityCity: "Wani",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18477,
        Pin: 711322,
        DispatchCenter: "Howrah_Temp_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18478,
        Pin: 605756,
        DispatchCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        OriginCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        FacilityCity: "Thirukoilure",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18479,
        Pin: 535213,
        DispatchCenter: "Bhogapuram_Thotaveedhi_D (Andhra Pradesh)",
        OriginCenter: "Bhogapuram_Thotaveedhi_D (Andhra Pradesh)",
        FacilityCity: "Bhogapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18480,
        Pin: 523301,
        DispatchCenter: "Martur_Nagarajupalli_D (Andhra Pradesh)",
        OriginCenter: "Martur_Nagarajupalli_D (Andhra Pradesh)",
        FacilityCity: "Martur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18481,
        Pin: 212503,
        DispatchCenter: "Soraon_Purabtola_D (Uttar Pradesh)",
        OriginCenter: "Soraon_Purabtola_D (Uttar Pradesh)",
        FacilityCity: "Soraon",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18482,
        Pin: 229123,
        DispatchCenter: "Bacchawaran_BhuyianTemple_D (Uttar Pradesh)",
        OriginCenter: "Bacchawaran_BhuyianTemple_D (Uttar Pradesh)",
        FacilityCity: "Bacchawaran",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18483,
        Pin: 413102,
        DispatchCenter: "Daund_Kurkumbh_L (Maharashtra)",
        OriginCenter: "Daund_Kurkumbh_L (Maharashtra)",
        FacilityCity: "Daund",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18484,
        Pin: 176052,
        DispatchCenter: "Dharamshala_GrudwrRd_D (Himachal Pradesh)",
        OriginCenter: "Dharamshala_GrudwrRd_D (Himachal Pradesh)",
        FacilityCity: "Dharamshala",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18485,
        Pin: 841222,
        DispatchCenter: "Chhapra_Ramnagar_I (Bihar)",
        OriginCenter: "Chhapra_Ramnagar_I (Bihar)",
        FacilityCity: "Chhapra",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18486,
        Pin: 635805,
        DispatchCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ambur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18487,
        Pin: 754108,
        DispatchCenter: "Balikuda_Balisahi_D (Orissa)",
        OriginCenter: "Balikuda_Balisahi_D (Orissa)",
        FacilityCity: "Balikuda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18488,
        Pin: 679123,
        DispatchCenter: "Shoranur_Akamala_D (Kerala)",
        OriginCenter: "Shoranur_Akamala_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18489,
        Pin: 848133,
        DispatchCenter: "KalyanpurBR_Ladaura_D (Bihar)",
        OriginCenter: "KalyanpurBR_Ladaura_D (Bihar)",
        FacilityCity: "KalyanpurBR",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18490,
        Pin: 689542,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18491,
        Pin: 231001,
        DispatchCenter: "Mirzapur_Natwa_D (Uttar Pradesh)",
        OriginCenter: "Mirzapur_Natwa_D (Uttar Pradesh)",
        FacilityCity: "Mirzapur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18492,
        Pin: 415805,
        DispatchCenter: "Rajapur_Sainagar_D (Maharashtra)",
        OriginCenter: "Rajapur_Sainagar_D (Maharashtra)",
        FacilityCity: "Rajapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18493,
        Pin: 627902,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18494,
        Pin: 686607,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18495,
        Pin: 580011,
        DispatchCenter: "Dharwad_Kamalpur_D (Karnataka)",
        OriginCenter: "Dharwad_Kamalpur_D (Karnataka)",
        FacilityCity: "Dharwad",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18496,
        Pin: 495677,
        DispatchCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        OriginCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        FacilityCity: "Korba",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18497,
        Pin: 301401,
        DispatchCenter: "Rewari_Chirhara_D (Haryana)",
        OriginCenter: "Rewari_Chirhara_D (Haryana)",
        FacilityCity: "Rewari",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18498,
        Pin: 394305,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18499,
        Pin: 573112,
        DispatchCenter: "Arsikere_JenukalTemple_D (Karnataka)",
        OriginCenter: "Arsikere_JenukalTemple_D (Karnataka)",
        FacilityCity: "Arsikere",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18500,
        Pin: 680005,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18501,
        Pin: 171003,
        DispatchCenter: "Shimla_Tara_DC (Himachal Pradesh)",
        OriginCenter: "Shimla_Tara_DC (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18502,
        Pin: 571434,
        DispatchCenter: "Mandya_Srirangapatna_D (Karnataka)",
        OriginCenter: "Mandya_Srirangapatna_D (Karnataka)",
        FacilityCity: "Mandya",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18503,
        Pin: 636110,
        DispatchCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        OriginCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        FacilityCity: "Thuraiyur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18504,
        Pin: 828122,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18505,
        Pin: 721161,
        DispatchCenter: "Lutunia_Bholenthtmpl_D (West Bengal)",
        OriginCenter: "Lutunia_Bholenthtmpl_D (West Bengal)",
        FacilityCity: "Lutunia",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18506,
        Pin: 228120,
        DispatchCenter: "Lambhua_Chaukiya_D (Uttar Pradesh)",
        OriginCenter: "Lambhua_Chaukiya_D (Uttar Pradesh)",
        FacilityCity: "Lambhua",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18507,
        Pin: 754157,
        DispatchCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        OriginCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18508,
        Pin: 627652,
        DispatchCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        OriginCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        FacilityCity: "Thisayanvilai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18509,
        Pin: 691011,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18510,
        Pin: 272178,
        DispatchCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        OriginCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        FacilityCity: "Basti",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18511,
        Pin: 472446,
        DispatchCenter: "Prithvipur_Niwari_D (Madhya Pradesh)",
        OriginCenter: "Prithvipur_Niwari_D (Madhya Pradesh)",
        FacilityCity: "Prithvipur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18512,
        Pin: 700116,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18513,
        Pin: 585305,
        DispatchCenter: "Chincholi_CKRd_D (Karnataka)",
        OriginCenter: "Chincholi_CKRd_D (Karnataka)",
        FacilityCity: "Chincholi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18514,
        Pin: 221008,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18515,
        Pin: 396120,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18516,
        Pin: 416206,
        DispatchCenter: "Kale_Harinaryan_D (Maharashtra)",
        OriginCenter: "Kale_Harinaryan_D (Maharashtra)",
        FacilityCity: "Kale",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18517,
        Pin: 505469,
        DispatchCenter: "KarimNagar_Alugunoor_I (Telangana)",
        OriginCenter: "KarimNagar_Alugunoor_I (Telangana)",
        FacilityCity: "Karim Nagar",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18518,
        Pin: 303305,
        DispatchCenter: "Bassi_Thana_D (Rajasthan)",
        OriginCenter: "Bassi_Thana_D (Rajasthan)",
        FacilityCity: "Bassi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18519,
        Pin: 413720,
        DispatchCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        OriginCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        FacilityCity: "Shrirampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18520,
        Pin: 630002,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18521,
        Pin: 122014,
        DispatchCenter: "Gurgaon_Sector52_L (Haryana)",
        OriginCenter: "Gurgaon_Sector52_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18522,
        Pin: 731131,
        DispatchCenter: "Dubrajpur_Mansayer_D (West Bengal)",
        OriginCenter: "Dubrajpur_Mansayer_D (West Bengal)",
        FacilityCity: "Dubrajpur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18523,
        Pin: 388515,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18524,
        Pin: 854107,
        DispatchCenter: "Purnia_Kasba_H (Bihar)",
        OriginCenter: "Purnia_Kasba_H (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18525,
        Pin: 844121,
        DispatchCenter: "Lalganj_Kharauna_D (Bihar)",
        OriginCenter: "Lalganj_Kharauna_D (Bihar)",
        FacilityCity: "Lalganj-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18526,
        Pin: 271830,
        DispatchCenter: "Nanpara_Dehati_D (Uttar Pradesh)",
        OriginCenter: "Nanpara_Dehati_D (Uttar Pradesh)",
        FacilityCity: "Nanpara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18527,
        Pin: 635110,
        DispatchCenter: "Hosur_SipcotPhase1_L (Tamil Nadu)",
        OriginCenter: "Hosur_SipcotPhase1_L (Tamil Nadu)",
        FacilityCity: "Hosur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18528,
        Pin: 607203,
        DispatchCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        OriginCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        FacilityCity: "Viluppuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18529,
        Pin: 135103,
        DispatchCenter: "Yamunanagar_Balachaur_D (Haryana)",
        OriginCenter: "Yamunanagar_Balachaur_D (Haryana)",
        FacilityCity: "Yamuna Nagar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18530,
        Pin: 416213,
        DispatchCenter: "Bambavade_SarudRd_DPP (Maharashtra)",
        OriginCenter: "Bambavade_SarudRd_DPP (Maharashtra)",
        FacilityCity: "Bambavade",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18531,
        Pin: 209201,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18532,
        Pin: 784190,
        DispatchCenter: "Udalguri_Milonpur_D (Assam)",
        OriginCenter: "Udalguri_Milonpur_D (Assam)",
        FacilityCity: "Mangaldoi",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18533,
        Pin: 327602,
        DispatchCenter: "Kushalgarh_Tandamangala_D (Rajasthan)",
        OriginCenter: "Kushalgarh_Tandamangala_D (Rajasthan)",
        FacilityCity: "Kushalgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18534,
        Pin: 721505,
        DispatchCenter: "Jhargram_Raghunathpur_DPP (West Bengal)",
        OriginCenter: "Jhargram_Raghunathpur_DPP (West Bengal)",
        FacilityCity: "Jhargram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18535,
        Pin: 262902,
        DispatchCenter: "Nighasan_PaliaKalan_D (Uttar Pradesh)",
        OriginCenter: "Nighasan_PaliaKalan_D (Uttar Pradesh)",
        FacilityCity: "Nighasan",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18536,
        Pin: 472331,
        DispatchCenter: "Jatara_Baiwarkhas_D (Madhya Pradesh)",
        OriginCenter: "Jatara_Baiwarkhas_D (Madhya Pradesh)",
        FacilityCity: "Jatara",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18537,
        Pin: 629153,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18538,
        Pin: 828108,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18539,
        Pin: 502301,
        DispatchCenter: "Pregnapur_Tharakaramanagar_D (Telangana)",
        OriginCenter: "Pregnapur_Tharakaramanagar_D (Telangana)",
        FacilityCity: "Pregnapur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18540,
        Pin: 508206,
        DispatchCenter: "Khammam_Mudigonda_H (Telangana)",
        OriginCenter: "Khammam_Mudigonda_H (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18541,
        Pin: 560066,
        DispatchCenter: "Bangalore_Koralur_L (Karnataka)",
        OriginCenter: "Bangalore_Soukyaroad_GW (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18542,
        Pin: 135003,
        DispatchCenter: "Yamunanagar_Veerngrcly_D (Haryana)",
        OriginCenter: "Yamunanagar_Veerngrcly_D (Haryana)",
        FacilityCity: "Yamuna Nagar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18543,
        Pin: 768033,
        DispatchCenter: "Bargarh_Pada_D (Orissa)",
        OriginCenter: "Bargarh_Pada_D (Orissa)",
        FacilityCity: "Bargarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18544,
        Pin: 415525,
        DispatchCenter: "Wai_Panchwad_DPP (Maharashtra)",
        OriginCenter: "Wai_Panchwad_DPP (Maharashtra)",
        FacilityCity: "Wai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18545,
        Pin: 612202,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18546,
        Pin: 754238,
        DispatchCenter: "Kendrapara_Penthapal_D (Orissa)",
        OriginCenter: "Kendrapara_Penthapal_D (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18547,
        Pin: 643270,
        DispatchCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        OriginCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        FacilityCity: "Gudalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18548,
        Pin: 243002,
        DispatchCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        OriginCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        FacilityCity: "Bareilly",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18549,
        Pin: 243633,
        DispatchCenter: "Budaun_KrishnaPuriRd_D (Uttar Pradesh)",
        OriginCenter: "Budaun_KrishnaPuriRd_D (Uttar Pradesh)",
        FacilityCity: "Budaun",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18550,
        Pin: 700161,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18551,
        Pin: 246149,
        DispatchCenter: "Kotdwara_GovindNagar_D (Uttarakhand)",
        OriginCenter: "Kotdwara_GovindNagar_D (Uttarakhand)",
        FacilityCity: "Kotdwara",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18552,
        Pin: 192230,
        DispatchCenter: "Srinagar_Ladhoo_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Ladhoo_H (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18553,
        Pin: 741157,
        DispatchCenter: "Plassey_Janakinagar_D (West Bengal)",
        OriginCenter: "Plassey_Janakinagar_D (West Bengal)",
        FacilityCity: "Plassey",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18554,
        Pin: 841403,
        DispatchCenter: "Malmaliya_Piarepur_D (Bihar)",
        OriginCenter: "Malmaliya_Piarepur_D (Bihar)",
        FacilityCity: "Malmaliya",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18555,
        Pin: 462035,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 18556,
        Pin: 670662,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18557,
        Pin: 412305,
        DispatchCenter: "Nira_Central_D (Maharashtra)",
        OriginCenter: "Nira_Central_D (Maharashtra)",
        FacilityCity: "Nira",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18558,
        Pin: 643253,
        DispatchCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        OriginCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        FacilityCity: "Gudalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18559,
        Pin: 331025,
        DispatchCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        OriginCenter: "Jhunjhunu_ShastriNagar_I (Rajasthan)",
        FacilityCity: "Jhunjhunu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18560,
        Pin: 769013,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18561,
        Pin: 585307,
        DispatchCenter: "Chincholi_CKRd_D (Karnataka)",
        OriginCenter: "Chincholi_CKRd_D (Karnataka)",
        FacilityCity: "Chincholi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18562,
        Pin: 560080,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18563,
        Pin: 533483,
        DispatchCenter: "Addateegala_Thimapuram_D (Andhra Pradesh)",
        OriginCenter: "Addateegala_Thimapuram_D (Andhra Pradesh)",
        FacilityCity: "Addateegala",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18564,
        Pin: 731201,
        DispatchCenter: "Bolpur_Surul_DPP (West Bengal)",
        OriginCenter: "Bolpur_Surul_DPP (West Bengal)",
        FacilityCity: "Bolpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18565,
        Pin: 585441,
        DispatchCenter: "Bidar_AdarshClny_D (Karnataka)",
        OriginCenter: "Bidar_AdarshClny_D (Karnataka)",
        FacilityCity: "Bidar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18566,
        Pin: 152028,
        DispatchCenter: "Moga_Duneke_I (Punjab)",
        OriginCenter: "Moga_Duneke_I (Punjab)",
        FacilityCity: "Moga",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18567,
        Pin: 639113,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18568,
        Pin: 283130,
        DispatchCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        OriginCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        FacilityCity: "Shikohabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18569,
        Pin: 440019,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18570,
        Pin: 625536,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18571,
        Pin: 781127,
        DispatchCenter: "Boko_Dhupdhara_D (Assam)",
        OriginCenter: "Boko_Dhupdhara_D (Assam)",
        FacilityCity: "Boko",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18572,
        Pin: 281201,
        DispatchCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        OriginCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        FacilityCity: "Khair",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18573,
        Pin: 781337,
        DispatchCenter: "Nalbari_Chokbazar_D (Assam)",
        OriginCenter: "Nalbari_Chokbazar_D (Assam)",
        FacilityCity: "Nalbari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18574,
        Pin: 638657,
        DispatchCenter: "Dharapuram_Pirivu_D (Tamil Nadu)",
        OriginCenter: "Dharapuram_Pirivu_D (Tamil Nadu)",
        FacilityCity: "Dharapuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18575,
        Pin: 306709,
        DispatchCenter: "Ras_LambiyaRd_DPP (Rajasthan)",
        OriginCenter: "Ras_LambiyaRd_DPP (Rajasthan)",
        FacilityCity: "Ras",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18576,
        Pin: 670144,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18577,
        Pin: 585217,
        DispatchCenter: "Afzalpur_Usmaniacolony_D (Karnataka)",
        OriginCenter: "Afzalpur_Usmaniacolony_D (Karnataka)",
        FacilityCity: "Afzalpur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18578,
        Pin: 764044,
        DispatchCenter: "Boipariguda_Mathili_DPP (Orissa)",
        OriginCenter: "Boipariguda_Mathili_DPP (Orissa)",
        FacilityCity: "Boipariguda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18579,
        Pin: 620013,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18580,
        Pin: 124010,
        DispatchCenter: "Rohtak_IndustrialArea_D (Haryana)",
        OriginCenter: "Rohtak_IndustrialArea_D (Haryana)",
        FacilityCity: "Rohtak",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18581,
        Pin: 785698,
        DispatchCenter: "Nazira_Amina_D (Assam)",
        OriginCenter: "Nazira_Amina_D (Assam)",
        FacilityCity: "Nazira",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18582,
        Pin: 781303,
        DispatchCenter: "Nalbari_Chokbazar_D (Assam)",
        OriginCenter: "Nalbari_Chokbazar_D (Assam)",
        FacilityCity: "Nalbari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18583,
        Pin: 835203,
        DispatchCenter: "Bero_Gumlaroad_D (Jharkhand)",
        OriginCenter: "Bero_Gumlaroad_D (Jharkhand)",
        FacilityCity: "Bero",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18584,
        Pin: 812010,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18585,
        Pin: 144606,
        DispatchCenter: "SultanpurLodhi_Gill_D (Punjab)",
        OriginCenter: "SultanpurLodhi_Gill_D (Punjab)",
        FacilityCity: "Sultanpur Lodhi",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18586,
        Pin: 756028,
        DispatchCenter: "Basta_Darada_DPP (Orissa)",
        OriginCenter: "Basta_Darada_DPP (Orissa)",
        FacilityCity: "Basta",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18587,
        Pin: 416143,
        DispatchCenter: "Ichalkaranji_TmbeMala_D (Maharashtra)",
        OriginCenter: "Ichalkaranji_TmbeMala_D (Maharashtra)",
        FacilityCity: "Ichalkaranji",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18588,
        Pin: 731216,
        DispatchCenter: "Rampurhat_Mansubamore_D (West Bengal)",
        OriginCenter: "Rampurhat_Mansubamore_D (West Bengal)",
        FacilityCity: "Rampurhat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18589,
        Pin: 523286,
        DispatchCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        OriginCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        FacilityCity: "Ongole",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18590,
        Pin: 768109,
        DispatchCenter: "Riamal_Badbahal_D (Orissa)",
        OriginCenter: "Riamal_Badbahal_D (Orissa)",
        FacilityCity: "Riamal",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18591,
        Pin: 793104,
        DispatchCenter: "Shillong_Umiam_D (Meghalaya)",
        OriginCenter: "Shillong_Umiam_D (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18592,
        Pin: 389370,
        DispatchCenter: "Halol_DhavalPark_D (Gujarat)",
        OriginCenter: "Halol_DhavalPark_D (Gujarat)",
        FacilityCity: "Halol",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18593,
        Pin: 332030,
        DispatchCenter: "Ramgarh_Khoor_DPP (Rajasthan)",
        OriginCenter: "Ramgarh_Khoor_DPP (Rajasthan)",
        FacilityCity: "Ramgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18594,
        Pin: 624308,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18595,
        Pin: 313301,
        DispatchCenter: "Rajsamand_Riicoarea_D (Rajasthan)",
        OriginCenter: "Rajsamand_Riicoarea_D (Rajasthan)",
        FacilityCity: "Rajsamand",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18596,
        Pin: 209214,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18597,
        Pin: 623604,
        DispatchCenter: "Perunali_Sayalkudi_D (Tamil Nadu)",
        OriginCenter: "Perunali_Sayalkudi_D (Tamil Nadu)",
        FacilityCity: "Perunali",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18598,
        Pin: 563137,
        DispatchCenter: "Malur_AdarshNgr_D (Karnataka)",
        OriginCenter: "Malur_AdarshNgr_D (Karnataka)",
        FacilityCity: "Malur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18599,
        Pin: 274705,
        DispatchCenter: "Salempur_BhatparRani_D (Uttar Pradesh)",
        OriginCenter: "Salempur_BhatparRani_D (Uttar Pradesh)",
        FacilityCity: "Salempur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18600,
        Pin: 229419,
        DispatchCenter: "Unchahar_Khojanpur_D (Uttar Pradesh)",
        OriginCenter: "Unchahar_Khojanpur_D (Uttar Pradesh)",
        FacilityCity: "Unchahar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18601,
        Pin: 385543,
        DispatchCenter: "Deesa_CrystalCplx_D (Gujarat)",
        OriginCenter: "Deesa_CrystalCplx_D (Gujarat)",
        FacilityCity: "Deesa",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18602,
        Pin: 411220,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18603,
        Pin: 734004,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18604,
        Pin: 573128,
        DispatchCenter: "Hassan_Nagathavalli_I (Karnataka)",
        OriginCenter: "Hassan_Nagathavalli_I (Karnataka)",
        FacilityCity: "Hassan",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18605,
        Pin: 442902,
        DispatchCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        OriginCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        FacilityCity: "Chandrapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18606,
        Pin: 221107,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18607,
        Pin: 829133,
        DispatchCenter: "Patratu_Saundabasti_D (Jharkhand)",
        OriginCenter: "Patratu_Saundabasti_D (Jharkhand)",
        FacilityCity: "Patratu",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18608,
        Pin: 490001,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18609,
        Pin: 802102,
        DispatchCenter: "Buxar_SohaniPatti_D (Bihar)",
        OriginCenter: "Buxar_SohaniPatti_D (Bihar)",
        FacilityCity: "Buxar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18610,
        Pin: 507158,
        DispatchCenter: "Khammam_Mudigonda_H (Telangana)",
        OriginCenter: "Khammam_Mudigonda_H (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18611,
        Pin: 581325,
        DispatchCenter: "Dandeli_RKComplx_D (Karnataka)",
        OriginCenter: "Dandeli_RKComplx_D (Karnataka)",
        FacilityCity: "Dandeli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18612,
        Pin: 394350,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18613,
        Pin: 146103,
        DispatchCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        OriginCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18614,
        Pin: 518401,
        DispatchCenter: "Nandikotkur_Nandyalroad_D (Andhra Pradesh)",
        OriginCenter: "Nandikotkur_Nandyalroad_D (Andhra Pradesh)",
        FacilityCity: "Nandikotkur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18615,
        Pin: 682034,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18616,
        Pin: 151210,
        DispatchCenter: "Malout_DC (Punjab)",
        OriginCenter: "Malout_DC (Punjab)",
        FacilityCity: "Malout",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18617,
        Pin: 431154,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18618,
        Pin: 625221,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18619,
        Pin: 520010,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18620,
        Pin: 361330,
        DispatchCenter: "Dwarka_CharaklaRd_DPP (Gujarat)",
        OriginCenter: "Dwarka_CharaklaRd_DPP (Gujarat)",
        FacilityCity: "Dwarka",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18621,
        Pin: 146113,
        DispatchCenter: "Hoshiarpur_Hariana_D (Punjab)",
        OriginCenter: "Hoshiarpur_Hariana_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18622,
        Pin: 752022,
        DispatchCenter: "Tangi_SriDPP_D (Orissa)",
        OriginCenter: "Tangi_SriDPP_D (Orissa)",
        FacilityCity: "Tangi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18623,
        Pin: 844502,
        DispatchCenter: "Patna_Chhotipahari_L (Bihar)",
        OriginCenter: "Patna_Sonagopalpur_H (Bihar)",
        FacilityCity: "Patna",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18624,
        Pin: 604154,
        DispatchCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        OriginCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        FacilityCity: "Tindivanam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18625,
        Pin: 184141,
        DispatchCenter: "Samba_Wardno13_D (Jammu & Kashmir)",
        OriginCenter: "Samba_Wardno13_D (Jammu & Kashmir)",
        FacilityCity: "Samba",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18626,
        Pin: 755033,
        DispatchCenter: "Jajpur_Chandama_D (Orissa)",
        OriginCenter: "Jajpur_Chandama_D (Orissa)",
        FacilityCity: "Jajpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18627,
        Pin: 250222,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18628,
        Pin: 754005,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18629,
        Pin: 613403,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18630,
        Pin: 271215,
        DispatchCenter: "Balrampur_Tulsipur_D (Uttar Pradesh)",
        OriginCenter: "Balrampur_Tulsipur_D (Uttar Pradesh)",
        FacilityCity: "Balrampur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18631,
        Pin: 364250,
        DispatchCenter: "Sihor_InfinityMall_D (Gujarat)",
        OriginCenter: "Sihor_InfinityMall_D (Gujarat)",
        FacilityCity: "Sihor",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18632,
        Pin: 603002,
        DispatchCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        OriginCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        FacilityCity: "Chengalpattu",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18633,
        Pin: 385310,
        DispatchCenter: "Dhanera_Tulsinagar_DPP (Gujarat)",
        OriginCenter: "Dhanera_Tulsinagar_DPP (Gujarat)",
        FacilityCity: "Dhanera",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18634,
        Pin: 530035,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18635,
        Pin: 507204,
        DispatchCenter: "Bonakal_SaiNagar_D (Telangana)",
        OriginCenter: "Bonakal_SaiNagar_D (Telangana)",
        FacilityCity: "Bonakal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18636,
        Pin: 641020,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18637,
        Pin: 688001,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18638,
        Pin: 534340,
        DispatchCenter: "Kovvur_Ramarao_D (Andhra Pradesh)",
        OriginCenter: "Kovvur_Ramarao_D (Andhra Pradesh)",
        FacilityCity: "Kovvur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18639,
        Pin: 572201,
        DispatchCenter: "Tiptur_Chmundeshwri_D (Karnataka)",
        OriginCenter: "Tiptur_Chmundeshwri_D (Karnataka)",
        FacilityCity: "Tiptur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18640,
        Pin: 509153,
        DispatchCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        OriginCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18641,
        Pin: 518465,
        DispatchCenter: "Alur_Pattikonda_D (Andhra Pradesh)",
        OriginCenter: "Alur_Pattikonda_D (Andhra Pradesh)",
        FacilityCity: "Alur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18642,
        Pin: 326519,
        DispatchCenter: "RamganjMandi_AdarshNagar_D (Rajasthan)",
        OriginCenter: "RamganjMandi_AdarshNagar_D (Rajasthan)",
        FacilityCity: "Ramganj Mandi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18643,
        Pin: 695309,
        DispatchCenter: "Attingal_Central_D_2 (Kerala)",
        OriginCenter: "Attingal_Central_D_2 (Kerala)",
        FacilityCity: "Attingal",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18644,
        Pin: 516454,
        DispatchCenter: "Tadipatri_Kondapuram_D (Andhra Pradesh)",
        OriginCenter: "Tadipatri_Kondapuram_D (Andhra Pradesh)",
        FacilityCity: "Tadipatri",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18645,
        Pin: 721124,
        DispatchCenter: "Lutunia_Bholenthtmpl_D (West Bengal)",
        OriginCenter: "Lutunia_Bholenthtmpl_D (West Bengal)",
        FacilityCity: "Lutunia",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18646,
        Pin: 793022,
        DispatchCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        OriginCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18647,
        Pin: 313026,
        DispatchCenter: "Salumbar_Khetawatwadi_DPP (Rajasthan)",
        OriginCenter: "Salumbar_Khetawatwadi_DPP (Rajasthan)",
        FacilityCity: "Salumbar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18648,
        Pin: 788727,
        DispatchCenter: "Patharkandi_CntrlSchl_D (Assam)",
        OriginCenter: "Patharkandi_CntrlSchl_D (Assam)",
        FacilityCity: "Patharkandi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18649,
        Pin: 742136,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18650,
        Pin: 591237,
        DispatchCenter: "Nipani_Sakharwadi_D (Karnataka)",
        OriginCenter: "Nipani_Sakharwadi_D (Karnataka)",
        FacilityCity: "Nipani",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18651,
        Pin: 121007,
        DispatchCenter: "Faridabad_Sector24_L (Haryana)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18652,
        Pin: 394212,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18653,
        Pin: 307513,
        DispatchCenter: "Reodar_KarotiChorha_DPP (Rajasthan)",
        OriginCenter: "Reodar_KarotiChorha_DPP (Rajasthan)",
        FacilityCity: "Reodar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18654,
        Pin: 176027,
        DispatchCenter: "NagrotaSurian_Saknra_DPP (Himachal Pradesh)",
        OriginCenter: "NagrotaSurian_Saknra_DPP (Himachal Pradesh)",
        FacilityCity: "Nagrota Surian",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18655,
        Pin: 581308,
        DispatchCenter: "Karwar_Sadashivgad_D (Karnataka)",
        OriginCenter: "Karwar_Sadashivgad_D (Karnataka)",
        FacilityCity: "Karwar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18656,
        Pin: 691566,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18657,
        Pin: 345023,
        DispatchCenter: "Pokhran_Sundernagar_D (Rajasthan)",
        OriginCenter: "Pokhran_Sundernagar_D (Rajasthan)",
        FacilityCity: "Pokhran",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18658,
        Pin: 756110,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18659,
        Pin: 410406,
        DispatchCenter: "Pune_Sudhwadi_L (Maharashtra)",
        OriginCenter: "Pune_Chimbali_H (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18660,
        Pin: 560109,
        DispatchCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        OriginCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18661,
        Pin: 695528,
        DispatchCenter: "Trivandrum_Neyatinkra_D (Kerala)",
        OriginCenter: "Trivandrum_Neyatinkra_D (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18662,
        Pin: 400026,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18663,
        Pin: 323021,
        DispatchCenter: "Bundi_Central_D_1 (Rajasthan)",
        OriginCenter: "Bundi_Central_D_1 (Rajasthan)",
        FacilityCity: "Bundi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18664,
        Pin: 389330,
        DispatchCenter: "Halol_DhavalPark_D (Gujarat)",
        OriginCenter: "Halol_DhavalPark_D (Gujarat)",
        FacilityCity: "Halol",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18665,
        Pin: 229502,
        DispatchCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        OriginCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        FacilityCity: "Unnao",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18666,
        Pin: 274408,
        DispatchCenter: "Kushinagar_Siswa_D (Uttar Pradesh)",
        OriginCenter: "Kushinagar_Siswa_D (Uttar Pradesh)",
        FacilityCity: "Kushinagar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18667,
        Pin: 531031,
        DispatchCenter: "Anakapalle_Thumapala_D (Andhra Pradesh)",
        OriginCenter: "Anakapalle_Thumapala_D (Andhra Pradesh)",
        FacilityCity: "Anakapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18668,
        Pin: 635202,
        DispatchCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        OriginCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        FacilityCity: "Dharmapuri",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18669,
        Pin: 741232,
        DispatchCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        OriginCenter: "Ranaghat_Panthapara_DPP (West Bengal)",
        FacilityCity: "Ranaghat",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18670,
        Pin: 624104,
        DispatchCenter: "Kodaikanal_Mudliyrpurm_D (Tamil Nadu)",
        OriginCenter: "Kodaikanal_Mudliyrpurm_D (Tamil Nadu)",
        FacilityCity: "Kodaikanal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18671,
        Pin: 193201,
        DispatchCenter: "Sopore_BadamBagh_DPP (Jammu & Kashmir)",
        OriginCenter: "Sopore_BadamBagh_DPP (Jammu & Kashmir)",
        FacilityCity: "Sopore",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18672,
        Pin: 841311,
        DispatchCenter: "Chhapra_Ramnagar_I (Bihar)",
        OriginCenter: "Chhapra_Ramnagar_I (Bihar)",
        FacilityCity: "Chhapra",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18673,
        Pin: 450332,
        DispatchCenter: "Nepanagar_Ward4_D (Madhya Pradesh)",
        OriginCenter: "Nepanagar_Ward4_D (Madhya Pradesh)",
        FacilityCity: "Nepanagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18674,
        Pin: 387365,
        DispatchCenter: "Kapadvanj_Dholikui_D (Gujarat)",
        OriginCenter: "Kapadvanj_Dholikui_D (Gujarat)",
        FacilityCity: "Kapadvanj",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18675,
        Pin: 521390,
        DispatchCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        OriginCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        FacilityCity: "Pamarru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18676,
        Pin: 142002,
        DispatchCenter: "Moga_Duneke_I (Punjab)",
        OriginCenter: "Moga_Duneke_I (Punjab)",
        FacilityCity: "Moga",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18677,
        Pin: 423104,
        DispatchCenter: "Manmad_Malegaon_D (Maharashtra)",
        OriginCenter: "Manmad_Malegaon_D (Maharashtra)",
        FacilityCity: "Manmad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18678,
        Pin: 623208,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18679,
        Pin: 690541,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18680,
        Pin: 781341,
        DispatchCenter: "Nalbari_Chokbazar_D (Assam)",
        OriginCenter: "Nalbari_Chokbazar_D (Assam)",
        FacilityCity: "Nalbari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18681,
        Pin: 563101,
        DispatchCenter: "Kolar_Jayanagara_D (Karnataka)",
        OriginCenter: "Kolar_Jayanagara_D (Karnataka)",
        FacilityCity: "Kolar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18682,
        Pin: 679329,
        DispatchCenter: "Edavanna_MNRd_D (Kerala)",
        OriginCenter: "Edavanna_MNRd_D (Kerala)",
        FacilityCity: "Edavanna",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18683,
        Pin: 208016,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18684,
        Pin: 361130,
        DispatchCenter: "Dhrol_PGVCLOfc_D (Gujarat)",
        OriginCenter: "Dhrol_PGVCLOfc_D (Gujarat)",
        FacilityCity: "Dhrol",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18685,
        Pin: 638664,
        DispatchCenter: "Palladam_Balajinagar_D (Tamil Nadu)",
        OriginCenter: "Palladam_Balajinagar_D (Tamil Nadu)",
        FacilityCity: "Palladam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18686,
        Pin: 689705,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18687,
        Pin: 686597,
        DispatchCenter: "Pala_PonadKaroor_D (Kerala)",
        OriginCenter: "Pala_PonadKaroor_D (Kerala)",
        FacilityCity: "Pala",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18688,
        Pin: 688542,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18689,
        Pin: 472338,
        DispatchCenter: "Prithvipur_JeronRd_DPP (Madhya Pradesh)",
        OriginCenter: "Prithvipur_JeronRd_DPP (Madhya Pradesh)",
        FacilityCity: "Prithvipur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18690,
        Pin: 193123,
        DispatchCenter: "Uri_Mainmarket_DPP (Jammu & Kashmir)",
        OriginCenter: "Uri_Mainmarket_DPP (Jammu & Kashmir)",
        FacilityCity: "Uri",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18691,
        Pin: 573127,
        DispatchCenter: "Sakleshpur_Nudarahalli_D (Karnataka)",
        OriginCenter: "Sakleshpur_Nudarahalli_D (Karnataka)",
        FacilityCity: "Sakleshpur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18692,
        Pin: 416122,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18693,
        Pin: 581326,
        DispatchCenter: "Ankola_Madangeri_D (Karnataka)",
        OriginCenter: "Ankola_Madangeri_D (Karnataka)",
        FacilityCity: "Ankola",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18694,
        Pin: 695588,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18695,
        Pin: 230127,
        DispatchCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        OriginCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        FacilityCity: "Patti-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18696,
        Pin: 754289,
        DispatchCenter: "Kendrapara_Chhagharia_D (Orissa)",
        OriginCenter: "Kendrapara_Chhagharia_D (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18697,
        Pin: 606102,
        DispatchCenter: "Ulundurpet_HarishNgr_D (Tamil Nadu)",
        OriginCenter: "Ulundurpet_HarishNgr_D (Tamil Nadu)",
        FacilityCity: "Ulundurpet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18698,
        Pin: 482007,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18699,
        Pin: 221711,
        DispatchCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        OriginCenter: "Mau_Chriayakot_D (Uttar Pradesh)",
        FacilityCity: "Mau",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18700,
        Pin: 403003,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18701,
        Pin: 628653,
        DispatchCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        OriginCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        FacilityCity: "Thisayanvilai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18702,
        Pin: 321406,
        DispatchCenter: "Weir_Bhusawar_DPP (Rajasthan)",
        OriginCenter: "Weir_Bhusawar_DPP (Rajasthan)",
        FacilityCity: "Weir",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18703,
        Pin: 627770,
        DispatchCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        OriginCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        FacilityCity: "Sankarankovil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18704,
        Pin: 390013,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18705,
        Pin: 679314,
        DispatchCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        OriginCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        FacilityCity: "Sankaramangalam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18706,
        Pin: 314022,
        DispatchCenter: "Salumbar_Aspur2_D (Rajasthan)",
        OriginCenter: "Salumbar_Aspur2_D (Rajasthan)",
        FacilityCity: "Salumbar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18707,
        Pin: 208024,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18708,
        Pin: 311011,
        DispatchCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        OriginCenter: "Bhilwara_RIICOIndArea_I (Rajasthan)",
        FacilityCity: "Bhilwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18709,
        Pin: 845440,
        DispatchCenter: "Sagauli_IndianOil_D (Bihar)",
        OriginCenter: "Sagauli_IndianOil_D (Bihar)",
        FacilityCity: "Sugauli",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18710,
        Pin: 639119,
        DispatchCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        OriginCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        FacilityCity: "Kulithalai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18711,
        Pin: 712205,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18712,
        Pin: 712149,
        DispatchCenter: "Pandua_NvplyDPP_D (West Bengal)",
        OriginCenter: "Pandua_NvplyDPP_D (West Bengal)",
        FacilityCity: "Pandua",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18713,
        Pin: 122008,
        DispatchCenter: "Gurgaon_Sector52_L (Haryana)",
        OriginCenter: "Gurgaon_Sector52_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18714,
        Pin: 721303,
        DispatchCenter: "Kharagpur_Nimpura_I (West Bengal)",
        OriginCenter: "Kharagpur_Nimpura_I (West Bengal)",
        FacilityCity: "Kharagpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18715,
        Pin: 341306,
        DispatchCenter: "Sujangarh_Khanpur_D (Rajasthan)",
        OriginCenter: "Sujangarh_Khanpur_D (Rajasthan)",
        FacilityCity: "Sujangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18716,
        Pin: 582116,
        DispatchCenter: "Gadag_Laxmeshwar_D (Karnataka)",
        OriginCenter: "Gadag_Laxmeshwar_D (Karnataka)",
        FacilityCity: "Gadag",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18717,
        Pin: 142031,
        DispatchCenter: "Jagraon_Grainmkt_D (Punjab)",
        OriginCenter: "Jagraon_Grainmkt_D (Punjab)",
        FacilityCity: "Jagraon",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18718,
        Pin: 805236,
        DispatchCenter: "Wazirganj_Usawa_D (Bihar)",
        OriginCenter: "Wazirganj_Usawa_D (Bihar)",
        FacilityCity: "Wazirganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18719,
        Pin: 641042,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18720,
        Pin: 382230,
        DispatchCenter: "Dholka_Rampur_D (Gujarat)",
        OriginCenter: "Dholka_Rampur_D (Gujarat)",
        FacilityCity: "Dholka",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18721,
        Pin: 787051,
        DispatchCenter: "NorthLakhimpur_Charaimoriya_D (Assam)",
        OriginCenter: "NorthLakhimpur_Charaimoriya_D (Assam)",
        FacilityCity: "North Lakhimpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18722,
        Pin: 783354,
        DispatchCenter: "Dotma_Nawarbhita_DPP (Assam)",
        OriginCenter: "Dotma_Nawarbhita_DPP (Assam)",
        FacilityCity: "Dotma",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18723,
        Pin: 271861,
        DispatchCenter: "Balrampur_SahabNager_D (Uttar Pradesh)",
        OriginCenter: "Balrampur_SahabNager_D (Uttar Pradesh)",
        FacilityCity: "Balrampur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18724,
        Pin: 785107,
        DispatchCenter: "Teok_Wardno3_D (Assam)",
        OriginCenter: "Teok_Wardno3_D (Assam)",
        FacilityCity: "Teok",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18725,
        Pin: 638453,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18726,
        Pin: 713123,
        DispatchCenter: "Dakshinkhanda_BusStand_DPP (West Bengal)",
        OriginCenter: "Dakshinkhanda_BusStand_DPP (West Bengal)",
        FacilityCity: "Katwa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18727,
        Pin: 695615,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18728,
        Pin: 621108,
        DispatchCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        OriginCenter: "Perambalur_Supernagar_D (Tamil Nadu)",
        FacilityCity: "Perambalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18729,
        Pin: 680721,
        DispatchCenter: "Chalakudy_ForestChowk_D (Kerala)",
        OriginCenter: "Chalakudy_ForestChowk_D (Kerala)",
        FacilityCity: "Chalakudy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18730,
        Pin: 608501,
        DispatchCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        OriginCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        FacilityCity: "Chidambaram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18731,
        Pin: 302039,
        DispatchCenter: "Jaipur_VishwakarmaIndArea_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18732,
        Pin: 483440,
        DispatchCenter: "Katni_Sleemanabad_D (Madhya Pradesh)",
        OriginCenter: "Katni_Sleemanabad_D (Madhya Pradesh)",
        FacilityCity: "Katni",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 18733,
        Pin: 441105,
        DispatchCenter: "Savner_Raymondcolony_D (Maharashtra)",
        OriginCenter: "Savner_Raymondcolony_D (Maharashtra)",
        FacilityCity: "Savner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18734,
        Pin: 364050,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18735,
        Pin: 605110,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18736,
        Pin: 148034,
        DispatchCenter: "Dhuri_Bhalwanroad_D (Punjab)",
        OriginCenter: "Dhuri_Bhalwanroad_D (Punjab)",
        FacilityCity: "Dhuri",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18737,
        Pin: 591312,
        DispatchCenter: "Mahalingpur_Bagalkot_D (Karnataka)",
        OriginCenter: "Mahalingpur_Bagalkot_D (Karnataka)",
        FacilityCity: "Mahalingpur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18738,
        Pin: 784526,
        DispatchCenter: "Udalguri_Milonpur_D (Assam)",
        OriginCenter: "Udalguri_Milonpur_D (Assam)",
        FacilityCity: "Mangaldoi",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18739,
        Pin: 403731,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18740,
        Pin: 523112,
        DispatchCenter: "Pamuru_ViruvoruRd_D (Andhra Pradesh)",
        OriginCenter: "Pamuru_ViruvoruRd_D (Andhra Pradesh)",
        FacilityCity: "Pamuru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18741,
        Pin: 754100,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18742,
        Pin: 331001,
        DispatchCenter: "Churu_Stadium_DPP (Rajasthan)",
        OriginCenter: "Churu_Stadium_DPP (Rajasthan)",
        FacilityCity: "Churu",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18743,
        Pin: 344025,
        DispatchCenter: "Balotra_Ward45_D (Rajasthan)",
        OriginCenter: "Balotra_Ward45_D (Rajasthan)",
        FacilityCity: "Balotra",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18744,
        Pin: 854101,
        DispatchCenter: "Kursela_Tingharia_D (Bihar)",
        OriginCenter: "Kursela_Tingharia_D (Bihar)",
        FacilityCity: "Kursela",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18745,
        Pin: 143513,
        DispatchCenter: "Ajnala_Fatehgarhchurian_D (Punjab)",
        OriginCenter: "Ajnala_Fatehgarhchurian_D (Punjab)",
        FacilityCity: "Ajnala",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18746,
        Pin: 637401,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18747,
        Pin: 686030,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18748,
        Pin: 394111,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18749,
        Pin: 504299,
        DispatchCenter: "Babasagar_Koutala2_DPP (Telangana)",
        OriginCenter: "Babasagar_Koutala2_DPP (Telangana)",
        FacilityCity: "Babasagar",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18750,
        Pin: 415621,
        DispatchCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        OriginCenter: "Khed_TulshiCmplx_D (Maharashtra)",
        FacilityCity: "Khed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18751,
        Pin: 160070,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18752,
        Pin: 848504,
        DispatchCenter: "MohiuddinNagar_Manjha_D (Bihar)",
        OriginCenter: "MohiuddinNagar_Manjha_D (Bihar)",
        FacilityCity: "Mohiuddin Nagar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18753,
        Pin: 768107,
        DispatchCenter: "Kuchinda_Pdrpada_D (Orissa)",
        OriginCenter: "Kuchinda_Pdrpada_D (Orissa)",
        FacilityCity: "Kuchinda",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18754,
        Pin: 140109,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18755,
        Pin: 741234,
        DispatchCenter: "Naihati_Kalyani_DPP (West Bengal)",
        OriginCenter: "Naihati_Kalyani_DPP (West Bengal)",
        FacilityCity: "Kalyani",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18756,
        Pin: 782440,
        DispatchCenter: "KampurTown_Sorupathar_D (Assam)",
        OriginCenter: "KampurTown_Sorupathar_D (Assam)",
        FacilityCity: "Kampur Town",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18757,
        Pin: 700158,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18758,
        Pin: 571442,
        DispatchCenter: "Kollegala_IndiraClny_D (Karnataka)",
        OriginCenter: "Kollegala_IndiraClny_D (Karnataka)",
        FacilityCity: "Kollegala",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18759,
        Pin: 181103,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18760,
        Pin: 484117,
        DispatchCenter: "Anuppur_Chetnanagar_D (Madhya Pradesh)",
        OriginCenter: "Anuppur_Chetnanagar_D (Madhya Pradesh)",
        FacilityCity: "Anuppur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 18761,
        Pin: 416204,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18762,
        Pin: 494230,
        DispatchCenter: "Keshkal_Surdongar_D (Chhattisgarh)",
        OriginCenter: "Keshkal_Surdongar_D (Chhattisgarh)",
        FacilityCity: "keshkal",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18763,
        Pin: 636003,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18764,
        Pin: 829110,
        DispatchCenter: "Peterbar_GagiDPP_D (Jharkhand)",
        OriginCenter: "Peterbar_GagiDPP_D (Jharkhand)",
        FacilityCity: "Peterbar",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18765,
        Pin: 673640,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18766,
        Pin: 392105,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18767,
        Pin: 506144,
        DispatchCenter: "Ghanpur_Palakrty_D (Telangana)",
        OriginCenter: "Ghanpur_Palakrty_D (Telangana)",
        FacilityCity: "Ghanpur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18768,
        Pin: 177211,
        DispatchCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        OriginCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        FacilityCity: "Amb",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18769,
        Pin: 825301,
        DispatchCenter: "Hazaribagh_Matwari_D (Jharkhand)",
        OriginCenter: "Hazaribagh_Matwari_D (Jharkhand)",
        FacilityCity: "hazaribagh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18770,
        Pin: 508224,
        DispatchCenter: "Tungaturthi_CanaraBank_D (Telangana)",
        OriginCenter: "Tungaturthi_CanaraBank_D (Telangana)",
        FacilityCity: "Tungaturthi",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18771,
        Pin: 509352,
        DispatchCenter: "Makthal_Shabaricolony_DPP (Telangana)",
        OriginCenter: "Makthal_Shabaricolony_DPP (Telangana)",
        FacilityCity: "Makthal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18772,
        Pin: 731101,
        DispatchCenter: "Suri_Karidhya_D (West Bengal)",
        OriginCenter: "Suri_Karidhya_D (West Bengal)",
        FacilityCity: "Suri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18773,
        Pin: 756032,
        DispatchCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        OriginCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        FacilityCity: "Dantan",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18774,
        Pin: 847304,
        DispatchCenter: "Bharwara_AtarwelPath_D (Bihar)",
        OriginCenter: "Bharwara_AtarwelPath_D (Bihar)",
        FacilityCity: "Bharwara",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18775,
        Pin: 756165,
        DispatchCenter: "Bhadrak_Deuli_D (Orissa)",
        OriginCenter: "Bhadrak_Deuli_D (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18776,
        Pin: 768031,
        DispatchCenter: "Bargarh_Sukuda_D (Orissa)",
        OriginCenter: "Bargarh_Sukuda_D (Orissa)",
        FacilityCity: "Bargarh",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18777,
        Pin: 600113,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18778,
        Pin: 396238,
        DispatchCenter: "SilvassaDNH_AmliEstate2_D (Dadra and Nagar Haveli)",
        OriginCenter: "SilvassaDNH_AmliEstate2_D (Dadra and Nagar Haveli)",
        FacilityCity: "Silvassa-DNH",
        FacilityState: "Dadra and Nagar Haveli",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18779,
        Pin: 630710,
        DispatchCenter: "Paramakudi_River_D (Tamil Nadu)",
        OriginCenter: "Paramakudi_River_D (Tamil Nadu)",
        FacilityCity: "Paramakudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18780,
        Pin: 301001,
        DispatchCenter: "Alwar_TuleraRoad_I (Rajasthan)",
        OriginCenter: "Alwar_TuleraRoad_I (Rajasthan)",
        FacilityCity: "Alwar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18781,
        Pin: 829124,
        DispatchCenter: "Patratu_Saundabasti_D (Jharkhand)",
        OriginCenter: "Patratu_Saundabasti_D (Jharkhand)",
        FacilityCity: "Patratu",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18782,
        Pin: 686584,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18783,
        Pin: 624402,
        DispatchCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        OriginCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        FacilityCity: "Melur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18784,
        Pin: 636016,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18785,
        Pin: 630408,
        DispatchCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        OriginCenter: "Thiruvadanai_CKMangalm_D (Tamil Nadu)",
        FacilityCity: "Thiruvadanai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18786,
        Pin: 743503,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18787,
        Pin: 582205,
        DispatchCenter: "Gadag_Vidyanagar_D (Karnataka)",
        OriginCenter: "Gadag_Vidyanagar_D (Karnataka)",
        FacilityCity: "Gadag",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18788,
        Pin: 506167,
        DispatchCenter: "Jangaon_Batkamakunta_D (Telangana)",
        OriginCenter: "Jangaon_Batkamakunta_D (Telangana)",
        FacilityCity: "Jangaon",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18789,
        Pin: 231305,
        DispatchCenter: "Chunnar_Bharpur_D (Uttar Pradesh)",
        OriginCenter: "Chunnar_Bharpur_D (Uttar Pradesh)",
        FacilityCity: "Chunnar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18790,
        Pin: 851134,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18791,
        Pin: 783337,
        DispatchCenter: "Dotma_Nawarbhita_DPP (Assam)",
        OriginCenter: "Dotma_Nawarbhita_DPP (Assam)",
        FacilityCity: "Dotma",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18792,
        Pin: 362560,
        DispatchCenter: "Una_StColony_D (Gujarat)",
        OriginCenter: "Una_StColony_D (Gujarat)",
        FacilityCity: "UNA",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18793,
        Pin: 841206,
        DispatchCenter: "Ekma_Hansrajpr_D (Bihar)",
        OriginCenter: "Ekma_Hansrajpr_D (Bihar)",
        FacilityCity: "Ekma",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18794,
        Pin: 500001,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18795,
        Pin: 504002,
        DispatchCenter: "Adilabad_KailashNagar_D (Telangana)",
        OriginCenter: "Adilabad_KailashNagar_D (Telangana)",
        FacilityCity: "Adilabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18796,
        Pin: 753071,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18797,
        Pin: 182141,
        DispatchCenter: "Chenani_Ward3_D (Jammu & Kashmir)",
        OriginCenter: "Chenani_Ward3_D (Jammu & Kashmir)",
        FacilityCity: "Chenani",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18798,
        Pin: 721253,
        DispatchCenter: "Midnapore_Sarbera_DPP (West Bengal)",
        OriginCenter: "Midnapore_Sarbera_DPP (West Bengal)",
        FacilityCity: "Midnapore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18799,
        Pin: 695025,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18800,
        Pin: 246488,
        DispatchCenter: "Chamoli_Karnaprayag_D (Uttarakhand)",
        OriginCenter: "Chamoli_Karnaprayag_D (Uttarakhand)",
        FacilityCity: "Chamoli",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18801,
        Pin: 825321,
        DispatchCenter: "Tandwa_Garilong_D (Jharkhand)",
        OriginCenter: "Tandwa_Garilong_D (Jharkhand)",
        FacilityCity: "Tandwa",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18802,
        Pin: 641304,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18803,
        Pin: 626005,
        DispatchCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        OriginCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        FacilityCity: "Sivakasi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18804,
        Pin: 631606,
        DispatchCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        OriginCenter: "Chengalpattu_AnnaNagar_D (Tamil Nadu)",
        FacilityCity: "Chengalpattu",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18805,
        Pin: 605013,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18806,
        Pin: 382620,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18807,
        Pin: 573201,
        DispatchCenter: "Hassan_Nagathavalli_I (Karnataka)",
        OriginCenter: "Hassan_Nagathavalli_I (Karnataka)",
        FacilityCity: "Hassan",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18808,
        Pin: 805125,
        DispatchCenter: "Sirdala_ChandrikaNagar_D (Bihar)",
        OriginCenter: "Sirdala_ChandrikaNagar_D (Bihar)",
        FacilityCity: "Sirdala",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18809,
        Pin: 673532,
        DispatchCenter: "Perambra_KayipramRd_D (Kerala)",
        OriginCenter: "Perambra_KayipramRd_D (Kerala)",
        FacilityCity: "Perambra",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18810,
        Pin: 695028,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18811,
        Pin: 843124,
        DispatchCenter: "Muzaffarpur_Motipur_D (Bihar)",
        OriginCenter: "Muzaffarpur_Motipur_D (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18812,
        Pin: 144042,
        DispatchCenter: "Nakodar_Goldenavenue_D (Punjab)",
        OriginCenter: "Nakodar_Goldenavenue_D (Punjab)",
        FacilityCity: "Nakodar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18813,
        Pin: 532168,
        DispatchCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        OriginCenter: "Srikakulam_Etcherla_I (Andhra Pradesh)",
        FacilityCity: "Srikakulam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18814,
        Pin: 176030,
        DispatchCenter: "Khundian_Koka_DPP (Himachal Pradesh)",
        OriginCenter: "Khundian_Koka_DPP (Himachal Pradesh)",
        FacilityCity: "Khundian",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18815,
        Pin: 508116,
        DispatchCenter: "Bhuvanagiri_Bhongir_D (Telangana)",
        OriginCenter: "Bhuvanagiri_Bhongir_D (Telangana)",
        FacilityCity: "Bhuvanagiri",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18816,
        Pin: 412108,
        DispatchCenter: "Pirangut_PoliceChowki_D (Maharashtra)",
        OriginCenter: "Pirangut_PoliceChowki_D (Maharashtra)",
        FacilityCity: "Pirangut",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18817,
        Pin: 768225,
        DispatchCenter: "Brajrajnagar_Jagannath_DPP (Orissa)",
        OriginCenter: "Brajrajnagar_Jagannath_DPP (Orissa)",
        FacilityCity: "Brajrajnagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18818,
        Pin: 389380,
        DispatchCenter: "Limkheda_VjyCmplx_DPP (Gujarat)",
        OriginCenter: "Limkheda_VjyCmplx_DPP (Gujarat)",
        FacilityCity: "Limkheda",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18819,
        Pin: 639206,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18820,
        Pin: 686578,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18821,
        Pin: 781132,
        DispatchCenter: "Guwahati_Kaikchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18822,
        Pin: 326514,
        DispatchCenter: "Chaumahla_Dag_DPP (Rajasthan)",
        OriginCenter: "Chaumahla_Dag_DPP (Rajasthan)",
        FacilityCity: "Chaumahla",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18823,
        Pin: 141007,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18824,
        Pin: 248001,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18825,
        Pin: 803119,
        DispatchCenter: "BiharSharif_Sherpur_D (Bihar)",
        OriginCenter: "BiharSharif_Sherpur_D (Bihar)",
        FacilityCity: "Bihar Sharif",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18826,
        Pin: 577566,
        DispatchCenter: "Davangere_Industrialarea_I (Karnataka)",
        OriginCenter: "Davangere_Industrialarea_I (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18827,
        Pin: 671321,
        DispatchCenter: "Kasaragod_Kudlu_D (Kerala)",
        OriginCenter: "Kasaragod_Kudlu_D (Kerala)",
        FacilityCity: "Kasaragod",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18828,
        Pin: 686631,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18829,
        Pin: 637014,
        DispatchCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        OriginCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        FacilityCity: "Namakkal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18830,
        Pin: 364006,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18831,
        Pin: 517319,
        DispatchCenter: "Madanapalle_RNaiduClny_D (Andhra Pradesh)",
        OriginCenter: "Madanapalle_RNaiduClny_D (Andhra Pradesh)",
        FacilityCity: "Madanapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18832,
        Pin: 534166,
        DispatchCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        OriginCenter: "Tadpligudm_Seetaram_D (Andhra Pradesh)",
        FacilityCity: "Tadepalligudem",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18833,
        Pin: 143008,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18834,
        Pin: 841432,
        DispatchCenter: "Mirganj_Lalmrkt_D (Bihar)",
        OriginCenter: "Mirganj_Lalmrkt_D (Bihar)",
        FacilityCity: "Mirganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18835,
        Pin: 303011,
        DispatchCenter: "Jaipur_RingRoad_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18836,
        Pin: 211016,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18837,
        Pin: 600014,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18838,
        Pin: 443404,
        DispatchCenter: "Nandura_Qureshinagar_DPP (Maharashtra)",
        OriginCenter: "Nandura_Qureshinagar_DPP (Maharashtra)",
        FacilityCity: "Nandura",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18839,
        Pin: 686509,
        DispatchCenter: "Mundakayam_Varikkani_D (Kerala)",
        OriginCenter: "Mundakayam_Varikkani_D (Kerala)",
        FacilityCity: "Mundakayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18840,
        Pin: 613701,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18841,
        Pin: 500021,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18842,
        Pin: 335073,
        DispatchCenter: "PadampurRJ_Srikaranpur_D (Rajasthan)",
        OriginCenter: "PadampurRJ_Srikaranpur_D (Rajasthan)",
        FacilityCity: "PadampurRJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18843,
        Pin: 689584,
        DispatchCenter: "Karukachal_Nedumkunam_D (Kerala)",
        OriginCenter: "Karukachal_Nedumkunam_D (Kerala)",
        FacilityCity: "Karukachal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18844,
        Pin: 416007,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18845,
        Pin: 743329,
        DispatchCenter: "Canning_BaruipurRd_D (West Bengal)",
        OriginCenter: "Canning_BaruipurRd_D (West Bengal)",
        FacilityCity: "Canning",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18846,
        Pin: 110041,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18847,
        Pin: 613008,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18848,
        Pin: 522658,
        DispatchCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasaraopet",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18849,
        Pin: 380014,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18850,
        Pin: 143536,
        DispatchCenter: "Pathankot_Kahanpur_H (Punjab)",
        OriginCenter: "Pathankot_Kahanpur_H (Punjab)",
        FacilityCity: "Pathankot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18851,
        Pin: 144205,
        DispatchCenter: "Dasuya_AdarshNagar_D (Punjab)",
        OriginCenter: "Dasuya_AdarshNagar_D (Punjab)",
        FacilityCity: "Dasuya",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18852,
        Pin: 341506,
        DispatchCenter: "Didwana_TransportNgr_DPP (Rajasthan)",
        OriginCenter: "Didwana_TransportNgr_DPP (Rajasthan)",
        FacilityCity: "Didwana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18853,
        Pin: 641007,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18854,
        Pin: 206302,
        DispatchCenter: "Kishni_Shivmndr_D (Uttar Pradesh)",
        OriginCenter: "Kishni_Shivmndr_D (Uttar Pradesh)",
        FacilityCity: "Kishni",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18855,
        Pin: 404404,
        DispatchCenter: "Shrigonda_Adhalgaon_D (Maharashtra)",
        OriginCenter: "Shrigonda_Adhalgaon_D (Maharashtra)",
        FacilityCity: "Shrigonda",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18856,
        Pin: 202397,
        DispatchCenter: "Dibai_Pahasu_D (Uttar Pradesh)",
        OriginCenter: "Dibai_Pahasu_D (Uttar Pradesh)",
        FacilityCity: "Dibai",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18857,
        Pin: 210301,
        DispatchCenter: "HamirpurUP_YamunaBdg_D (Uttar Pradesh)",
        OriginCenter: "HamirpurUP_YamunaBdg_D (Uttar Pradesh)",
        FacilityCity: "Hamirpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18858,
        Pin: 689626,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18859,
        Pin: 732150,
        DispatchCenter: "Itahar_Kumedpur_D (West Bengal)",
        OriginCenter: "Itahar_Kumedpur_D (West Bengal)",
        FacilityCity: "Itahar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18860,
        Pin: 411025,
        DispatchCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        OriginCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18861,
        Pin: 410217,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18862,
        Pin: 423201,
        DispatchCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        OriginCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        FacilityCity: "Malegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18863,
        Pin: 628613,
        DispatchCenter: "Eral_Nazareth_D (Tamil Nadu)",
        OriginCenter: "Eral_Nazareth_D (Tamil Nadu)",
        FacilityCity: "Eral",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18864,
        Pin: 134104,
        DispatchCenter: "Parwanoo_Sector2_D (Himachal Pradesh)",
        OriginCenter: "Parwanoo_Sector2_D (Himachal Pradesh)",
        FacilityCity: "Parwanoo",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18865,
        Pin: 796101,
        DispatchCenter: "Kolasib_Vairengte_D (Mizoram)",
        OriginCenter: "Kolasib_Vairengte_D (Mizoram)",
        FacilityCity: "Kolasib",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18866,
        Pin: 465689,
        DispatchCenter: "RajgarhMP_Jirapur_D (Madhya Pradesh)",
        OriginCenter: "RajgarhMP_Jirapur_D (Madhya Pradesh)",
        FacilityCity: "Rajgarh-MP",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18867,
        Pin: 632510,
        DispatchCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ramdasnagar_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18868,
        Pin: 606206,
        DispatchCenter: "Kallakurichi_Neelamangalam_D (Tamil Nadu)",
        OriginCenter: "Kallakurichi_Neelamangalam_D (Tamil Nadu)",
        FacilityCity: "Kallakurichi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18869,
        Pin: 581412,
        DispatchCenter: "Yellapur_Tatagar_DPP (Karnataka)",
        OriginCenter: "Yellapur_Tatagar_DPP (Karnataka)",
        FacilityCity: "Yellapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18870,
        Pin: 250501,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18871,
        Pin: 495454,
        DispatchCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        OriginCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        FacilityCity: "Korba",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18872,
        Pin: 783135,
        DispatchCenter: "Fekamari_Hatsingimari_DPP (Assam)",
        OriginCenter: "Fekamari_Hatsingimari_DPP (Assam)",
        FacilityCity: "Fekamari",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18873,
        Pin: 843144,
        DispatchCenter: "Dholi_WardNo4_D (Bihar)",
        OriginCenter: "Dholi_WardNo4_D (Bihar)",
        FacilityCity: "Dholi",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18874,
        Pin: 587113,
        DispatchCenter: "Mudhol_Maharancol_D (Karnataka)",
        OriginCenter: "Mudhol_Maharancol_D (Karnataka)",
        FacilityCity: "Mudhol",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18875,
        Pin: 452018,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18876,
        Pin: 614630,
        DispatchCenter: "Manamelkudi_HondaShow_D (Tamil Nadu)",
        OriginCenter: "Manamelkudi_HondaShow_D (Tamil Nadu)",
        FacilityCity: "Manamelkudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18877,
        Pin: 683544,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18878,
        Pin: 591122,
        DispatchCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        OriginCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        FacilityCity: "Gokak",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18879,
        Pin: 629156,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18880,
        Pin: 627254,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18881,
        Pin: 521151,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18882,
        Pin: 683592,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18883,
        Pin: 638751,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18884,
        Pin: 223106,
        DispatchCenter: "Shahganj_RudhauliKalan_D (Uttar Pradesh)",
        OriginCenter: "Shahganj_RudhauliKalan_D (Uttar Pradesh)",
        FacilityCity: "Shahganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18885,
        Pin: 753009,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18886,
        Pin: 832302,
        DispatchCenter: "Chakulia_Jogipara_D (Jharkhand)",
        OriginCenter: "Chakulia_Jogipara_D (Jharkhand)",
        FacilityCity: "Chakulia",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18887,
        Pin: 261502,
        DispatchCenter: "Dhaurahara_TahsilRD_D (Uttar Pradesh)",
        OriginCenter: "Dhaurahara_TahsilRD_D (Uttar Pradesh)",
        FacilityCity: "Dhaurahara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18888,
        Pin: 442001,
        DispatchCenter: "Wardha_Alodi_D (Maharashtra)",
        OriginCenter: "Wardha_Alodi_D (Maharashtra)",
        FacilityCity: "Wardha",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18889,
        Pin: 584101,
        DispatchCenter: "Raichur_Wadloor_I (Karnataka)",
        OriginCenter: "Raichur_Wadloor_I (Karnataka)",
        FacilityCity: "Raichur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18890,
        Pin: 769004,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18891,
        Pin: 625708,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18892,
        Pin: 497451,
        DispatchCenter: "Manendragarh_JKDRoad_D (Chhattisgarh)",
        OriginCenter: "Manendragarh_JKDRoad_D (Chhattisgarh)",
        FacilityCity: "Manendragarh",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18893,
        Pin: 629159,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18894,
        Pin: 712422,
        DispatchCenter: "Khanakul_Antngr_D (West Bengal)",
        OriginCenter: "Khanakul_Antngr_D (West Bengal)",
        FacilityCity: "Khanakul",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18895,
        Pin: 534451,
        DispatchCenter: "Jangareddygudem_RTC_D (Andhra Pradesh)",
        OriginCenter: "Jangareddygudem_RTC_D (Andhra Pradesh)",
        FacilityCity: "Jangareddygudem",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18896,
        Pin: 441206,
        DispatchCenter: "Brahmapuri_Tilaknagar_D (Maharashtra)",
        OriginCenter: "Brahmapuri_Tilaknagar_D (Maharashtra)",
        FacilityCity: "Brahmapuri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18897,
        Pin: 532460,
        DispatchCenter: "Palakonda_VadaiDPP_D (Andhra Pradesh)",
        OriginCenter: "Palakonda_VadaiDPP_D (Andhra Pradesh)",
        FacilityCity: "Palakonda",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18898,
        Pin: 713372,
        DispatchCenter: "Asansol_Gobindapur_D (West Bengal)",
        OriginCenter: "Asansol_Gobindapur_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18899,
        Pin: 613204,
        DispatchCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        OriginCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        FacilityCity: "Thirukkattupalli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18900,
        Pin: 273017,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18901,
        Pin: 610106,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18902,
        Pin: 413216,
        DispatchCenter: "Akkalkot_SmrathNgr_DPP (Maharashtra)",
        OriginCenter: "Akkalkot_SmrathNgr_DPP (Maharashtra)",
        FacilityCity: "Akkalkot",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18903,
        Pin: 784161,
        DispatchCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        OriginCenter: "Itanagar_Banderdewa_I (Arunachal Pradesh)",
        FacilityCity: "Itanagar",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18904,
        Pin: 591307,
        DispatchCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        OriginCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        FacilityCity: "Gokak",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18905,
        Pin: 431713,
        DispatchCenter: "Bhokar_Sydngr_D (Maharashtra)",
        OriginCenter: "Bhokar_Sydngr_D (Maharashtra)",
        FacilityCity: "Bhokar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18906,
        Pin: 600021,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18907,
        Pin: 576103,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18908,
        Pin: 457552,
        DispatchCenter: "Ratlam_MhowRd_D (Madhya Pradesh)",
        OriginCenter: "Ratlam_MhowRd_D (Madhya Pradesh)",
        FacilityCity: "Ratlam",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 18909,
        Pin: 834003,
        DispatchCenter: "Ranchi_Sidroll_H (Jharkhand)",
        OriginCenter: "Ranchi_Sidroll_H (Jharkhand)",
        FacilityCity: "Ranchi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18910,
        Pin: 829101,
        DispatchCenter: "Ramgarh_Marar_D (Jharkhand)",
        OriginCenter: "Ramgarh_Marar_D (Jharkhand)",
        FacilityCity: "Ramgarh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18911,
        Pin: 230142,
        DispatchCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        OriginCenter: "Patti_Baheliyapur_D (Uttar Pradesh)",
        FacilityCity: "Patti-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18912,
        Pin: 389351,
        DispatchCenter: "Halol_DhavalPark_D (Gujarat)",
        OriginCenter: "Halol_DhavalPark_D (Gujarat)",
        FacilityCity: "Halol",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 18913,
        Pin: 754142,
        DispatchCenter: "Paradip_Udyabata_D (Orissa)",
        OriginCenter: "Paradip_Udyabata_D (Orissa)",
        FacilityCity: "Paradip",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18914,
        Pin: 679101,
        DispatchCenter: "Shoranur_Vaniyamkulam_D (Kerala)",
        OriginCenter: "Shoranur_Vaniyamkulam_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18915,
        Pin: 769041,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18916,
        Pin: 828209,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18917,
        Pin: 585102,
        DispatchCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        OriginCenter: "Gulbarga_Kapnoor_I (Karnataka)",
        FacilityCity: "Gulbarga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18918,
        Pin: 492007,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18919,
        Pin: 413721,
        DispatchCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        OriginCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        FacilityCity: "Shrirampur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18920,
        Pin: 331030,
        DispatchCenter: "Churu_Bissau_DPP (Rajasthan)",
        OriginCenter: "Churu_Bissau_DPP (Rajasthan)",
        FacilityCity: "Churu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18921,
        Pin: 757092,
        DispatchCenter: "Bangriposi_Majigan_D (Orissa)",
        OriginCenter: "Bangriposi_Majigan_D (Orissa)",
        FacilityCity: "Bangriposi",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18922,
        Pin: 412303,
        DispatchCenter: "Saswad_Sopannagar_D (Maharashtra)",
        OriginCenter: "Saswad_Sopannagar_D (Maharashtra)",
        FacilityCity: "Saswad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18923,
        Pin: 182126,
        DispatchCenter: "Udhampur_Sunal_D (Jammu & Kashmir)",
        OriginCenter: "Udhampur_Sunal_D (Jammu & Kashmir)",
        FacilityCity: "Udhampur",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 18924,
        Pin: 600051,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18925,
        Pin: 828207,
        DispatchCenter: "Chirkunda_Egarkunr_D (Jharkhand)",
        OriginCenter: "Chirkunda_Egarkunr_D (Jharkhand)",
        FacilityCity: "Chirkunda",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18926,
        Pin: 700047,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18927,
        Pin: 793018,
        DispatchCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        OriginCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18928,
        Pin: 695575,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18929,
        Pin: 122219,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18930,
        Pin: 414502,
        DispatchCenter: "Shevgaon_MiriRd_DPP (Maharashtra)",
        OriginCenter: "Shevgaon_MiriRd_DPP (Maharashtra)",
        FacilityCity: "Shevgaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18931,
        Pin: 625012,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18932,
        Pin: 502295,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18933,
        Pin: 586128,
        DispatchCenter: "Sindagi_Bijapurrd_D (Karnataka)",
        OriginCenter: "Sindagi_Bijapurrd_D (Karnataka)",
        FacilityCity: "Sindagi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18934,
        Pin: 517417,
        DispatchCenter: "Punganur_Nspeta_D (Andhra Pradesh)",
        OriginCenter: "Punganur_Nspeta_D (Andhra Pradesh)",
        FacilityCity: "Punganur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18935,
        Pin: 465335,
        DispatchCenter: "Shujalpur_JNSCollege_D (Madhya Pradesh)",
        OriginCenter: "Shujalpur_JNSCollege_D (Madhya Pradesh)",
        FacilityCity: "Shujalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 18936,
        Pin: 700066,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18937,
        Pin: 604408,
        DispatchCenter: "Vandavasi_SaltStreet_D (Tamil Nadu)",
        OriginCenter: "Vandavasi_SaltStreet_D (Tamil Nadu)",
        FacilityCity: "Vandavasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18938,
        Pin: 847235,
        DispatchCenter: "Khutauna_ShastriChwk_D (Bihar)",
        OriginCenter: "Khutauna_ShastriChwk_D (Bihar)",
        FacilityCity: "Khutauna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18939,
        Pin: 628104,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18940,
        Pin: 814153,
        DispatchCenter: "Dumka_Hansdiha1_D (Jharkhand)",
        OriginCenter: "Dumka_Hansdiha1_D (Jharkhand)",
        FacilityCity: "Dumka",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18941,
        Pin: 781125,
        DispatchCenter: "Chhaygaon_Bijoynagar_D (Assam)",
        OriginCenter: "Chhaygaon_Bijoynagar_D (Assam)",
        FacilityCity: "Chhaygaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18942,
        Pin: 609811,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18943,
        Pin: 209503,
        DispatchCenter: "Farrukhabad_Shamsabad_D (Uttar Pradesh)",
        OriginCenter: "Farrukhabad_Shamsabad_D (Uttar Pradesh)",
        FacilityCity: "Farrukhabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18944,
        Pin: 604203,
        DispatchCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        OriginCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        FacilityCity: "Gingee",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18945,
        Pin: 523214,
        DispatchCenter: "Addanki_Inkollu_D (Andhra Pradesh)",
        OriginCenter: "Addanki_Inkollu_D (Andhra Pradesh)",
        FacilityCity: "Addanki",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18946,
        Pin: 123302,
        DispatchCenter: "Rewari_Kosli_D (Haryana)",
        OriginCenter: "Rewari_Kosli_D (Haryana)",
        FacilityCity: "Jhajjar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18947,
        Pin: 452008,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18948,
        Pin: 673123,
        DispatchCenter: "Kalpetta_Mandayapuram_D (Kerala)",
        OriginCenter: "Kalpetta_Mandayapuram_D (Kerala)",
        FacilityCity: "Kalpetta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18949,
        Pin: 403724,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 18950,
        Pin: 721431,
        DispatchCenter: "Bajkul_Kismat_D (West Bengal)",
        OriginCenter: "Bajkul_Kismat_D (West Bengal)",
        FacilityCity: "Bajkul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18951,
        Pin: 742302,
        DispatchCenter: "Murshidabad_Jiaganj_D (West Bengal)",
        OriginCenter: "Murshidabad_Jiaganj_D (West Bengal)",
        FacilityCity: "Murshidabad",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18952,
        Pin: 848503,
        DispatchCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        OriginCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        FacilityCity: "Dalsingh Sarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18953,
        Pin: 456009,
        DispatchCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        OriginCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        FacilityCity: "Ujjain",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18954,
        Pin: 766106,
        DispatchCenter: "Komana_Raghupali_D (Orissa)",
        OriginCenter: "Komana_Raghupali_D (Orissa)",
        FacilityCity: "Komana",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18955,
        Pin: 176215,
        DispatchCenter: "Dharamshala_GrudwrRd_D (Himachal Pradesh)",
        OriginCenter: "Dharamshala_GrudwrRd_D (Himachal Pradesh)",
        FacilityCity: "Dharamshala",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18956,
        Pin: 144007,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18957,
        Pin: 612002,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18958,
        Pin: 304022,
        DispatchCenter: "Chaksu_Arihantkunj_D (Rajasthan)",
        OriginCenter: "Chaksu_Arihantkunj_D (Rajasthan)",
        FacilityCity: "Chaksu",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18959,
        Pin: 122050,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18960,
        Pin: 175121,
        DispatchCenter: "Kullu_Nagwain_D (Himachal Pradesh)",
        OriginCenter: "Kullu_Nagwain_D (Himachal Pradesh)",
        FacilityCity: "Kullu",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18961,
        Pin: 142022,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18962,
        Pin: 517235,
        DispatchCenter: "Madanapalle_Vayalapadu_D (Andhra Pradesh)",
        OriginCenter: "Madanapalle_Vayalapadu_D (Andhra Pradesh)",
        FacilityCity: "Madanapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18963,
        Pin: 843302,
        DispatchCenter: "Sitamarhi_Punaura_D (Bihar)",
        OriginCenter: "Sitamarhi_Punaura_D (Bihar)",
        FacilityCity: "Sitamarhi",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18964,
        Pin: 606305,
        DispatchCenter: "Ulundurpet_HarishNgr_D (Tamil Nadu)",
        OriginCenter: "Ulundurpet_HarishNgr_D (Tamil Nadu)",
        FacilityCity: "Ulundurpet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18965,
        Pin: 636115,
        DispatchCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        OriginCenter: "Attur_KaaduRd_D (Tamil Nadu)",
        FacilityCity: "Attur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18966,
        Pin: 563126,
        DispatchCenter: "Kolar_Jayanagara_D (Karnataka)",
        OriginCenter: "Kolar_Jayanagara_D (Karnataka)",
        FacilityCity: "Kolar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18967,
        Pin: 816116,
        DispatchCenter: "Rajmahal_Bamangawan_D (Jharkhand)",
        OriginCenter: "Rajmahal_Bamangawan_D (Jharkhand)",
        FacilityCity: "Rajmahal",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18968,
        Pin: 782447,
        DispatchCenter: "Lumding_Central_DPP_2 (Assam)",
        OriginCenter: "Lumding_Central_DPP_2 (Assam)",
        FacilityCity: "Lumding",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18969,
        Pin: 712258,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18970,
        Pin: 332041,
        DispatchCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        OriginCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18971,
        Pin: 133104,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18972,
        Pin: 627814,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18973,
        Pin: 691007,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18974,
        Pin: 721652,
        DispatchCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        OriginCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        FacilityCity: "Tamluk",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18975,
        Pin: 712513,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18976,
        Pin: 682313,
        DispatchCenter: "Kanjiramattom_Piravom_D (Kerala)",
        OriginCenter: "Kanjiramattom_Piravom_D (Kerala)",
        FacilityCity: "Kanjiramattom",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 18977,
        Pin: 754035,
        DispatchCenter: "Athagarh_Karikol_D (Orissa)",
        OriginCenter: "Athagarh_Karikol_D (Orissa)",
        FacilityCity: "Athagarh",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 18978,
        Pin: 466115,
        DispatchCenter: "Sehore_Sherpur_D (Madhya Pradesh)",
        OriginCenter: "Sehore_Sherpur_D (Madhya Pradesh)",
        FacilityCity: "Sehore",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 18979,
        Pin: 482006,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 18980,
        Pin: 206120,
        DispatchCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        OriginCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        FacilityCity: "Etawah",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18981,
        Pin: 201011,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18982,
        Pin: 855105,
        DispatchCenter: "Katihar_Kantia_D (Bihar)",
        OriginCenter: "Katihar_Kantia_D (Bihar)",
        FacilityCity: "Katihar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 18983,
        Pin: 233227,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18984,
        Pin: 422204,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18985,
        Pin: 134113,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 18986,
        Pin: 313602,
        DispatchCenter: "Bhatewar_HoliChowk_DPP (Rajasthan)",
        OriginCenter: "Bhatewar_HoliChowk_DPP (Rajasthan)",
        FacilityCity: "Bhatewar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18987,
        Pin: 230139,
        DispatchCenter: "LalganjAjhara_HPPump_D (Uttar Pradesh)",
        OriginCenter: "LalganjAjhara_HPPump_D (Uttar Pradesh)",
        FacilityCity: "Lalganj Ajhara",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18988,
        Pin: 752015,
        DispatchCenter: "Pipili_BPRd_DPP (Orissa)",
        OriginCenter: "Pipili_BPRd_DPP (Orissa)",
        FacilityCity: "Pipili",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18989,
        Pin: 688506,
        DispatchCenter: "Changanacherry_Perunna_D (Kerala)",
        OriginCenter: "Changanacherry_Perunna_D (Kerala)",
        FacilityCity: "Changanacherry",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 18990,
        Pin: 759024,
        DispatchCenter: "Kamakhyanagar_Mundideuli_D (Orissa)",
        OriginCenter: "Kamakhyanagar_Mundideuli_D (Orissa)",
        FacilityCity: "Kamakhyanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 18991,
        Pin: 272001,
        DispatchCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        OriginCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        FacilityCity: "Basti",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 18992,
        Pin: 722146,
        DispatchCenter: "Bankura_KeranDPP_D (West Bengal)",
        OriginCenter: "Bankura_KeranDPP_D (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 18993,
        Pin: 391760,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 18994,
        Pin: 622401,
        DispatchCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 18995,
        Pin: 623513,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18996,
        Pin: 224117,
        DispatchCenter: "Rudauli_Ghosaiyana_D (Uttar Pradesh)",
        OriginCenter: "Rudauli_Ghosaiyana_D (Uttar Pradesh)",
        FacilityCity: "Rudauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 18997,
        Pin: 416230,
        DispatchCenter: "Kale_Harinaryan_D (Maharashtra)",
        OriginCenter: "Kale_Harinaryan_D (Maharashtra)",
        FacilityCity: "Kale",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 18998,
        Pin: 517588,
        DispatchCenter: "Tada_Sriharikota_D (Andhra Pradesh)",
        OriginCenter: "Tada_Sriharikota_D (Andhra Pradesh)",
        FacilityCity: "Tada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 18999,
        Pin: 507129,
        DispatchCenter: "Nellipaka_Buttaigudem_D (Andhra Pradesh)",
        OriginCenter: "Nellipaka_Buttaigudem_D (Andhra Pradesh)",
        FacilityCity: "Nellipaka",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19000,
        Pin: 152127,
        DispatchCenter: "Abohar_DaulatPura_D (Punjab)",
        OriginCenter: "Abohar_DaulatPura_D (Punjab)",
        FacilityCity: "Abohar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19001,
        Pin: 802111,
        DispatchCenter: "Dumraon_MahvidylaRd_D (Bihar)",
        OriginCenter: "Dumraon_MahvidylaRd_D (Bihar)",
        FacilityCity: "Dumraon",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19002,
        Pin: 416229,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19003,
        Pin: 174023,
        DispatchCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        OriginCenter: "BilaspurHP_Khurari_I (Himachal Pradesh)",
        FacilityCity: "Bilaspur-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19004,
        Pin: 477557,
        DispatchCenter: "Bhind_Mehgaon_D (Madhya Pradesh)",
        OriginCenter: "Bhind_Mehgaon_D (Madhya Pradesh)",
        FacilityCity: "Bhind",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19005,
        Pin: 451331,
        DispatchCenter: "Khargone_NutanNgr_D (Madhya Pradesh)",
        OriginCenter: "Khargone_NutanNgr_D (Madhya Pradesh)",
        FacilityCity: "Khargone",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19006,
        Pin: 344601,
        DispatchCenter: "Barmer_Baldevnagar_DPP (Rajasthan)",
        OriginCenter: "Barmer_Baldevnagar_DPP (Rajasthan)",
        FacilityCity: "Barmer",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19007,
        Pin: 423602,
        DispatchCenter: "Kopargaon_Murshatpur_D (Maharashtra)",
        OriginCenter: "Kopargaon_Murshatpur_D (Maharashtra)",
        FacilityCity: "Kopargaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19008,
        Pin: 414701,
        DispatchCenter: "Daund_Kurkumbh_L (Maharashtra)",
        OriginCenter: "Daund_Kurkumbh_L (Maharashtra)",
        FacilityCity: "Daund",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19009,
        Pin: 764003,
        DispatchCenter: "Jeypore_Bariniput_I (Orissa)",
        OriginCenter: "Jeypore_Bariniput_I (Orissa)",
        FacilityCity: "Jeypore",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19010,
        Pin: 781320,
        DispatchCenter: "Goalpara_Baladmri_D (Assam)",
        OriginCenter: "Goalpara_Baladmri_D (Assam)",
        FacilityCity: "Goalpara",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19011,
        Pin: 431646,
        DispatchCenter: "Mukhed_Mukramabad_DPP (Maharashtra)",
        OriginCenter: "Mukhed_Mukramabad_DPP (Maharashtra)",
        FacilityCity: "Mukhed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19012,
        Pin: 560020,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19013,
        Pin: 530046,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19014,
        Pin: 791104,
        DispatchCenter: "Pasighat_MissonRd_D (Arunachal Pradesh)",
        OriginCenter: "Pasighat_MissonRd_D (Arunachal Pradesh)",
        FacilityCity: "Pasighat",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19015,
        Pin: 517416,
        DispatchCenter: "Chittoor_Bangarupalyam_D (Andhra Pradesh)",
        OriginCenter: "Chittoor_Bangarupalyam_D (Andhra Pradesh)",
        FacilityCity: "Palamaner",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19016,
        Pin: 587330,
        DispatchCenter: "Jamkhandi_Laxmingr_D (Karnataka)",
        OriginCenter: "Jamkhandi_Laxmingr_D (Karnataka)",
        FacilityCity: "Jamkhandi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19017,
        Pin: 722134,
        DispatchCenter: "Khatra_Simlapal_DPP (West Bengal)",
        OriginCenter: "Khatra_Simlapal_DPP (West Bengal)",
        FacilityCity: "Khatra",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19018,
        Pin: 201001,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19019,
        Pin: 591282,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19020,
        Pin: 494222,
        DispatchCenter: "Bastar_Rajendranagar_D (Chhattisgarh)",
        OriginCenter: "Bastar_Rajendranagar_D (Chhattisgarh)",
        FacilityCity: "Bastar",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19021,
        Pin: 627718,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19022,
        Pin: 799130,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19023,
        Pin: 516501,
        DispatchCenter: "Badvel_Vidyanagar_D (Andhra Pradesh)",
        OriginCenter: "Badvel_Vidyanagar_D (Andhra Pradesh)",
        FacilityCity: "Badvel",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19024,
        Pin: 721137,
        DispatchCenter: "Kolaghat_Central_DPP_2 (West Bengal)",
        OriginCenter: "Kolaghat_Central_DPP_2 (West Bengal)",
        FacilityCity: "Kolaghat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19025,
        Pin: 805124,
        DispatchCenter: "Pakribarwan_Warisaliganj_D (Bihar)",
        OriginCenter: "Pakribarwan_Warisaliganj_D (Bihar)",
        FacilityCity: "Pakribarwan",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19026,
        Pin: 792111,
        DispatchCenter: "Chowkham_Gunanagar_D (Arunachal Pradesh)",
        OriginCenter: "Chowkham_Gunanagar_D (Arunachal Pradesh)",
        FacilityCity: "Chowkham",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19027,
        Pin: 411100,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19028,
        Pin: 274182,
        DispatchCenter: "Barhaj_Nandnaward_D (Uttar Pradesh)",
        OriginCenter: "Barhaj_Nandnaward_D (Uttar Pradesh)",
        FacilityCity: "Barhaj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19029,
        Pin: 241301,
        DispatchCenter: "Madhoganj_BaghuliRd_D (Uttar Pradesh)",
        OriginCenter: "Madhoganj_BaghuliRd_D (Uttar Pradesh)",
        FacilityCity: "Madhoganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19030,
        Pin: 464001,
        DispatchCenter: "Vidisha_ShrprDPP_D (Madhya Pradesh)",
        OriginCenter: "Vidisha_ShrprDPP_D (Madhya Pradesh)",
        FacilityCity: "Vidisha",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19031,
        Pin: 713364,
        DispatchCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        OriginCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        FacilityCity: "Rupnarayanpur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19032,
        Pin: 171219,
        DispatchCenter: "Waknaghat_Rwli_DPP (Himachal Pradesh)",
        OriginCenter: "Waknaghat_Rwli_DPP (Himachal Pradesh)",
        FacilityCity: "Waknaghat",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19033,
        Pin: 440005,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19034,
        Pin: 622004,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19035,
        Pin: 518301,
        DispatchCenter: "Adoni_Mandagiri_D (Andhra Pradesh)",
        OriginCenter: "Adoni_Mandagiri_D (Andhra Pradesh)",
        FacilityCity: "Adoni",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19036,
        Pin: 400207,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19037,
        Pin: 524228,
        DispatchCenter: "Kavali_Pathuru_D (Andhra Pradesh)",
        OriginCenter: "Kavali_Pathuru_D (Andhra Pradesh)",
        FacilityCity: "Kavali",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19038,
        Pin: 831001,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19039,
        Pin: 503112,
        DispatchCenter: "Kamareddy_KPRcolony_D (Telangana)",
        OriginCenter: "Kamareddy_KPRcolony_D (Telangana)",
        FacilityCity: "Kamareddy",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19040,
        Pin: 627251,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19041,
        Pin: 506329,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19042,
        Pin: 679337,
        DispatchCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        OriginCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        FacilityCity: "Sankaramangalam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19043,
        Pin: 624706,
        DispatchCenter: "Gujiliamparai_Palayam_DPP (Tamil Nadu)",
        OriginCenter: "Gujiliamparai_Palayam_DPP (Tamil Nadu)",
        FacilityCity: "Gujiliamparai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19044,
        Pin: 679580,
        DispatchCenter: "Chiramanangad_Veliankode_D (Kerala)",
        OriginCenter: "Chiramanangad_Veliankode_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19045,
        Pin: 486776,
        DispatchCenter: "Rampurnaikin_Jhanjh_D (Madhya Pradesh)",
        OriginCenter: "Rampurnaikin_Jhanjh_D (Madhya Pradesh)",
        FacilityCity: "Rampur Naikin",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19046,
        Pin: 605401,
        DispatchCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        OriginCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        FacilityCity: "Viluppuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19047,
        Pin: 757056,
        DispatchCenter: "Bahalda_Nimdhi_DPP (Orissa)",
        OriginCenter: "Bahalda_Nimdhi_DPP (Orissa)",
        FacilityCity: "BAHALDA",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19048,
        Pin: 144006,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19049,
        Pin: 533234,
        DispatchCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19050,
        Pin: 229303,
        DispatchCenter: "Bacchawaran_BhuyianTemple_D (Uttar Pradesh)",
        OriginCenter: "Bacchawaran_BhuyianTemple_D (Uttar Pradesh)",
        FacilityCity: "Bacchawaran",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19051,
        Pin: 732212,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19052,
        Pin: 755020,
        DispatchCenter: "Jajpur_Chandama_D (Orissa)",
        OriginCenter: "Jajpur_Chandama_D (Orissa)",
        FacilityCity: "Jajpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19053,
        Pin: 590013,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19054,
        Pin: 531019,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19055,
        Pin: 783303,
        DispatchCenter: "Dhubri_Tetultol_D (Assam)",
        OriginCenter: "Dhubri_Tetultol_D (Assam)",
        FacilityCity: "Dhubri",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19056,
        Pin: 422105,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19057,
        Pin: 833202,
        DispatchCenter: "Chaibasa_NewClnyTungri_D (Jharkhand)",
        OriginCenter: "Chaibasa_NewClnyTungri_D (Jharkhand)",
        FacilityCity: "Chaibasa",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19058,
        Pin: 171213,
        DispatchCenter: "Kumarsain_Bharara_D (Himachal Pradesh)",
        OriginCenter: "Kumarsain_Bharara_D (Himachal Pradesh)",
        FacilityCity: "Kumarsain",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19059,
        Pin: 131301,
        DispatchCenter: "Gohana_Adarshnagar_D (Haryana)",
        OriginCenter: "Gohana_Adarshnagar_D (Haryana)",
        FacilityCity: "Gohana",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19060,
        Pin: 533410,
        DispatchCenter: "Tuni_Ramakrishnacolony_D (Andhra Pradesh)",
        OriginCenter: "Tuni_Ramakrishnacolony_D (Andhra Pradesh)",
        FacilityCity: "Tuni",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19061,
        Pin: 829201,
        DispatchCenter: "Khalari_NayaDhauda_D (Jharkhand)",
        OriginCenter: "Khalari_NayaDhauda_D (Jharkhand)",
        FacilityCity: "Khalari-JR",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19062,
        Pin: 560046,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19063,
        Pin: 721440,
        DispatchCenter: "Contai_Egra_D (West Bengal)",
        OriginCenter: "Contai_Egra_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19064,
        Pin: 502306,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19065,
        Pin: 518348,
        DispatchCenter: "Alur_VaddeStreet_D (Andhra Pradesh)",
        OriginCenter: "Alur_VaddeStreet_D (Andhra Pradesh)",
        FacilityCity: "Alur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19066,
        Pin: 636101,
        DispatchCenter: "Chinnasalem_VKootRd_D (Tamil Nadu)",
        OriginCenter: "Chinnasalem_VKootRd_D (Tamil Nadu)",
        FacilityCity: "Chinnasalem",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19067,
        Pin: 341013,
        DispatchCenter: "Nagaur_Jantacomplex_D (Rajasthan)",
        OriginCenter: "Nagaur_Jantacomplex_D (Rajasthan)",
        FacilityCity: "Nagaur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19068,
        Pin: 123029,
        DispatchCenter: "MahendragarhHR_Khaira_D (Haryana)",
        OriginCenter: "MahendragarhHR_Khaira_D (Haryana)",
        FacilityCity: "Mahendragarh-HR",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19069,
        Pin: 509102,
        DispatchCenter: "Kollapur_Vasavi_DPP (Telangana)",
        OriginCenter: "Kollapur_Vasavi_DPP (Telangana)",
        FacilityCity: "Kollapur",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19070,
        Pin: 322213,
        DispatchCenter: "Nadoti_Ibrahimpur_D (Rajasthan)",
        OriginCenter: "Nadoti_Ibrahimpur_D (Rajasthan)",
        FacilityCity: "Nadoti",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19071,
        Pin: 147201,
        DispatchCenter: "Nabha_Jaspalcolony_D (Punjab)",
        OriginCenter: "Nabha_Jaspalcolony_D (Punjab)",
        FacilityCity: "Nabha",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19072,
        Pin: 562125,
        DispatchCenter: "Bangalore_Koralur_L (Karnataka)",
        OriginCenter: "Bangalore_Soukyaroad_GW (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19073,
        Pin: 505209,
        DispatchCenter: "Mancherial_Nuspur_I (Telangana)",
        OriginCenter: "Mancherial_Nuspur_I (Telangana)",
        FacilityCity: "Mancherial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19074,
        Pin: 171002,
        DispatchCenter: "shimla (Himachal Pradesh)",
        OriginCenter: "shimla (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19075,
        Pin: 209209,
        DispatchCenter: "Ghatampur_StatinRD_D (Uttar Pradesh)",
        OriginCenter: "Ghatampur_StatinRD_D (Uttar Pradesh)",
        FacilityCity: "Ghatampur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19076,
        Pin: 247661,
        DispatchCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        OriginCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        FacilityCity: "Haridwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19077,
        Pin: 683550,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19078,
        Pin: 787059,
        DispatchCenter: "Silapathar_Junakinagar_D (Assam)",
        OriginCenter: "Silapathar_Junakinagar_D (Assam)",
        FacilityCity: "Silapathar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19079,
        Pin: 854301,
        DispatchCenter: "Purnia_Fatehpur_D (Bihar)",
        OriginCenter: "Purnia_Fatehpur_D (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19080,
        Pin: 401608,
        DispatchCenter: "Dahanu_Ambemora_D (Maharashtra)",
        OriginCenter: "Dahanu_Ambemora_D (Maharashtra)",
        FacilityCity: "Dahanu",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19081,
        Pin: 131028,
        DispatchCenter: "Sonipat_Sector51_L (Haryana)",
        OriginCenter: "Sonipat_WazidpurSaboli_P (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19082,
        Pin: 641011,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19083,
        Pin: 380029,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19084,
        Pin: 535591,
        DispatchCenter: "Salur_Eswar_D (Andhra Pradesh)",
        OriginCenter: "Salur_Eswar_D (Andhra Pradesh)",
        FacilityCity: "Salur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19085,
        Pin: 484887,
        DispatchCenter: "Anuppur_Kirgi_D (Madhya Pradesh)",
        OriginCenter: "Anuppur_Kirgi_D (Madhya Pradesh)",
        FacilityCity: "Anuppur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19086,
        Pin: 515871,
        DispatchCenter: "Rayadurgam_Ramnagar_D (Andhra Pradesh)",
        OriginCenter: "Rayadurgam_Ramnagar_D (Andhra Pradesh)",
        FacilityCity: "Rayadurgam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19087,
        Pin: 758082,
        DispatchCenter: "Ghatgaon_Harichandanpur_D (Orissa)",
        OriginCenter: "Ghatgaon_Harichandanpur_D (Orissa)",
        FacilityCity: "Ghatgaon",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19088,
        Pin: 689508,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19089,
        Pin: 201106,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19090,
        Pin: 605011,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19091,
        Pin: 841220,
        DispatchCenter: "Ekma_Hansrajpr_D (Bihar)",
        OriginCenter: "Ekma_Hansrajpr_D (Bihar)",
        FacilityCity: "Ekma",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19092,
        Pin: 623115,
        DispatchCenter: "Perunali_Sayalkudi_D (Tamil Nadu)",
        OriginCenter: "Perunali_Sayalkudi_D (Tamil Nadu)",
        FacilityCity: "Perunali",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19093,
        Pin: 752118,
        DispatchCenter: "Konark_Central_DPP_2 (Orissa)",
        OriginCenter: "Konark_Central_DPP_2 (Orissa)",
        FacilityCity: "Konark",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19094,
        Pin: 764058,
        DispatchCenter: "Borigumma_Kotapad_DPP (Orissa)",
        OriginCenter: "Borigumma_Kotapad_DPP (Orissa)",
        FacilityCity: "Kotapad",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19095,
        Pin: 193301,
        DispatchCenter: "Baramulla_Watergam_DPP (Jammu & Kashmir)",
        OriginCenter: "Baramulla_Watergam_DPP (Jammu & Kashmir)",
        FacilityCity: "Baramulla",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19096,
        Pin: 742102,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19097,
        Pin: 689682,
        DispatchCenter: "Ranni_Chethakhl_D (Kerala)",
        OriginCenter: "Ranni_Chethakhl_D (Kerala)",
        FacilityCity: "Ranni",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19098,
        Pin: 301410,
        DispatchCenter: "RajgarhRJ_KarothRd_D (Rajasthan)",
        OriginCenter: "RajgarhRJ_KarothRd_D (Rajasthan)",
        FacilityCity: "Rajgarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19099,
        Pin: 682307,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19100,
        Pin: 411020,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19101,
        Pin: 691319,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19102,
        Pin: 641027,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19103,
        Pin: 515662,
        DispatchCenter: "Anantapur_Dharmavaram_D (Andhra Pradesh)",
        OriginCenter: "Anantapur_Dharmavaram_D (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19104,
        Pin: 686123,
        DispatchCenter: "Kottayam_Mulakunnath_D (Kerala)",
        OriginCenter: "Kottayam_Mulakunnath_D (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19105,
        Pin: 425113,
        DispatchCenter: "Amalner_Sriramcolony_DPP (Maharashtra)",
        OriginCenter: "Amalner_Sriramcolony_DPP (Maharashtra)",
        FacilityCity: "Amalner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19106,
        Pin: 302015,
        DispatchCenter: "Jaipur_Ramchandpura_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19107,
        Pin: 754102,
        DispatchCenter: "Jagatsinghpur_Bnbrda_D (Orissa)",
        OriginCenter: "Jagatsinghpur_Bnbrda_D (Orissa)",
        FacilityCity: "Jagatsinghpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19108,
        Pin: 495663,
        DispatchCenter: "Akaltara_IndraUdyan_D (Chhattisgarh)",
        OriginCenter: "Akaltara_IndraUdyan_D (Chhattisgarh)",
        FacilityCity: "Akaltara",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19109,
        Pin: 160057,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19110,
        Pin: 301714,
        DispatchCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        OriginCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        FacilityCity: "Neemrana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19111,
        Pin: 679522,
        DispatchCenter: "Shoranur_Vaniyamkulam_D (Kerala)",
        OriginCenter: "Shoranur_Vaniyamkulam_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19112,
        Pin: 184152,
        DispatchCenter: "Kathua_Kharote_D (Jammu & Kashmir)",
        OriginCenter: "Kathua_Kharote_D (Jammu & Kashmir)",
        FacilityCity: "Kathua",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19113,
        Pin: 521108,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19114,
        Pin: 431743,
        DispatchCenter: "Umarkhed_HubeCplx_D (Maharashtra)",
        OriginCenter: "Umarkhed_HubeCplx_D (Maharashtra)",
        FacilityCity: "Umarkhed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19115,
        Pin: 670632,
        DispatchCenter: "Chavassery_Malapattam_D (Kerala)",
        OriginCenter: "Chavassery_Malapattam_D (Kerala)",
        FacilityCity: "Chavassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19116,
        Pin: 721201,
        DispatchCenter: "Midnapore_Sarbera_DPP (West Bengal)",
        OriginCenter: "Midnapore_Sarbera_DPP (West Bengal)",
        FacilityCity: "Midnapore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19117,
        Pin: 604409,
        DispatchCenter: "Cheyyar_NehruNGR_D (Tamil Nadu)",
        OriginCenter: "Cheyyar_NehruNGR_D (Tamil Nadu)",
        FacilityCity: "Cheyyar",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19118,
        Pin: 227413,
        DispatchCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        OriginCenter: "Amethi_Kherauna_D (Uttar Pradesh)",
        FacilityCity: "Amethi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19119,
        Pin: 431515,
        DispatchCenter: "Parli_JirgeNgr_D (Maharashtra)",
        OriginCenter: "Parli_JirgeNgr_D (Maharashtra)",
        FacilityCity: "Parli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19120,
        Pin: 522035,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19121,
        Pin: 176309,
        DispatchCenter: "Bharmour_Kharamukh_D (Himachal Pradesh)",
        OriginCenter: "Bharmour_Kharamukh_D (Himachal Pradesh)",
        FacilityCity: "Bharmour",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19122,
        Pin: 523225,
        DispatchCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        OriginCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        FacilityCity: "Ongole",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19123,
        Pin: 302025,
        DispatchCenter: "Jaipur_Ramchandpura_L (Rajasthan)",
        OriginCenter: "Jaipur_Ramchandpura_L (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19124,
        Pin: 587315,
        DispatchCenter: "Mahalingpur_Bagalkot_D (Karnataka)",
        OriginCenter: "Mahalingpur_Bagalkot_D (Karnataka)",
        FacilityCity: "Mahalingpur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19125,
        Pin: 573134,
        DispatchCenter: "Sakleshpur_Nudarahalli_D (Karnataka)",
        OriginCenter: "Sakleshpur_Nudarahalli_D (Karnataka)",
        FacilityCity: "Sakleshpur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19126,
        Pin: 383240,
        DispatchCenter: "Bhiloda_NirSagarPlaza_D (Gujarat)",
        OriginCenter: "Bhiloda_NirSagarPlaza_D (Gujarat)",
        FacilityCity: "Bhiloda",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19127,
        Pin: 734122,
        DispatchCenter: "Bijanbari_Pulbazar2_DPP (West Bengal)",
        OriginCenter: "Bijanbari_Pulbazar2_DPP (West Bengal)",
        FacilityCity: "Bijanbari",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19128,
        Pin: 524302,
        DispatchCenter: "Badvel_Vidyanagar_D (Andhra Pradesh)",
        OriginCenter: "Badvel_Vidyanagar_D (Andhra Pradesh)",
        FacilityCity: "Badvel",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19129,
        Pin: 205261,
        DispatchCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        OriginCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        FacilityCity: "Mainpuri",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19130,
        Pin: 402206,
        DispatchCenter: "Alibag_Shivanagar_D (Maharashtra)",
        OriginCenter: "Alibag_Shivanagar_D (Maharashtra)",
        FacilityCity: "Alibag",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19131,
        Pin: 670645,
        DispatchCenter: "Mananthavady_Central_D_1 (Kerala)",
        OriginCenter: "Mananthavady_Central_D_1 (Kerala)",
        FacilityCity: "Mananthavadi",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19132,
        Pin: 474012,
        DispatchCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        OriginCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        FacilityCity: "Gwalior",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19133,
        Pin: 473331,
        DispatchCenter: "Ashoknagar_Durgacolony_D (Madhya Pradesh)",
        OriginCenter: "Ashoknagar_Durgacolony_D (Madhya Pradesh)",
        FacilityCity: "Ashok Nagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19134,
        Pin: 627853,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19135,
        Pin: 221005,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19136,
        Pin: 416145,
        DispatchCenter: "Ichalkaranji_TmbeMala_D (Maharashtra)",
        OriginCenter: "Ichalkaranji_TmbeMala_D (Maharashtra)",
        FacilityCity: "Ichalkaranji",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19137,
        Pin: 690501,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19138,
        Pin: 678506,
        DispatchCenter: "Nenmara_Vithanaserry_D (Kerala)",
        OriginCenter: "Nenmara_Vithanaserry_D (Kerala)",
        FacilityCity: "Nenmara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19139,
        Pin: 391421,
        DispatchCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        OriginCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        FacilityCity: "Jambusar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19140,
        Pin: 784510,
        DispatchCenter: "Udalguri_Dhansrighat_D (Assam)",
        OriginCenter: "Udalguri_Dhansrighat_D (Assam)",
        FacilityCity: "Udalguri",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19141,
        Pin: 688524,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19142,
        Pin: 431147,
        DispatchCenter: "Kannad_Pishore1_DPP (Maharashtra)",
        OriginCenter: "Kannad_Pishore1_DPP (Maharashtra)",
        FacilityCity: "Kannad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19143,
        Pin: 761127,
        DispatchCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        OriginCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        FacilityCity: "Bhanjanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19144,
        Pin: 783389,
        DispatchCenter: "Chapar_Rowmari_DPP (Assam)",
        OriginCenter: "Chapar_Rowmari_DPP (Assam)",
        FacilityCity: "Chapar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19145,
        Pin: 229103,
        DispatchCenter: "Bacchawaran_BhuyianTemple_D (Uttar Pradesh)",
        OriginCenter: "Bacchawaran_BhuyianTemple_D (Uttar Pradesh)",
        FacilityCity: "Bacchawaran",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19146,
        Pin: 680712,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19147,
        Pin: 628401,
        DispatchCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        OriginCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        FacilityCity: "Tuticorin",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19148,
        Pin: 263002,
        DispatchCenter: "Nainital_Malital_D (Uttarakhand)",
        OriginCenter: "Nainital_Malital_D (Uttarakhand)",
        FacilityCity: "Nainital",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19149,
        Pin: 530014,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19150,
        Pin: 208012,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19151,
        Pin: 303804,
        DispatchCenter: "Chomu_SamodRd_D (Rajasthan)",
        OriginCenter: "Chomu_SamodRd_D (Rajasthan)",
        FacilityCity: "Chomu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19152,
        Pin: 480112,
        DispatchCenter: "Chhindwara_Linga_I (Madhya Pradesh)",
        OriginCenter: "Chhindwara_Linga_I (Madhya Pradesh)",
        FacilityCity: "Chhindwara",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19153,
        Pin: 843313,
        DispatchCenter: "Dhaka_PachpakriRd_D (Bihar)",
        OriginCenter: "Dhaka_PachpakriRd_D (Bihar)",
        FacilityCity: "Dhaka",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19154,
        Pin: 574117,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19155,
        Pin: 793122,
        DispatchCenter: "Shillong_Umiam_D (Meghalaya)",
        OriginCenter: "Shillong_Umiam_D (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19156,
        Pin: 636456,
        DispatchCenter: "Mettur_Samballi_D (Tamil Nadu)",
        OriginCenter: "Mettur_Samballi_D (Tamil Nadu)",
        FacilityCity: "Mettur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19157,
        Pin: 209501,
        DispatchCenter: "Farrukhabad_RamBanquet_D (Uttar Pradesh)",
        OriginCenter: "Farrukhabad_RamBanquet_D (Uttar Pradesh)",
        FacilityCity: "Farrukhabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19158,
        Pin: 563129,
        DispatchCenter: "Bangarapet_AMPCClny_D (Karnataka)",
        OriginCenter: "Bangarapet_AMPCClny_D (Karnataka)",
        FacilityCity: "Bangarapet",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19159,
        Pin: 787052,
        DispatchCenter: "NorthLakhimpur_Charaimoriya_D (Assam)",
        OriginCenter: "NorthLakhimpur_Charaimoriya_D (Assam)",
        FacilityCity: "North Lakhimpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19160,
        Pin: 524129,
        DispatchCenter: "Naidupeta_LASagaram_D (Andhra Pradesh)",
        OriginCenter: "Naidupeta_LASagaram_D (Andhra Pradesh)",
        FacilityCity: "Naidupeta",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19161,
        Pin: 794108,
        DispatchCenter: "Resubelpara_Belpara_D (Meghalaya)",
        OriginCenter: "Resubelpara_Belpara_D (Meghalaya)",
        FacilityCity: "Resubelpara",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19162,
        Pin: 688534,
        DispatchCenter: "Cochin_Aroor_D (Kerala)",
        OriginCenter: "Cochin_Aroor_D (Kerala)",
        FacilityCity: "Ezhupunna",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19163,
        Pin: 431805,
        DispatchCenter: "Kinwat_Mahurrd_D (Maharashtra)",
        OriginCenter: "Kinwat_Mahurrd_D (Maharashtra)",
        FacilityCity: "Kinwat",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19164,
        Pin: 495455,
        DispatchCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        OriginCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        FacilityCity: "Korba",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19165,
        Pin: 691524,
        DispatchCenter: "Adoor_Pathanapuram_D (Kerala)",
        OriginCenter: "Adoor_Pathanapuram_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19166,
        Pin: 742166,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19167,
        Pin: 574154,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19168,
        Pin: 534215,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19169,
        Pin: 691017,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19170,
        Pin: 508001,
        DispatchCenter: "Nalgonda_Duppalapally_I (Telangana)",
        OriginCenter: "Nalgonda_Duppalapally_I (Telangana)",
        FacilityCity: "Nalgonda",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19171,
        Pin: 481111,
        DispatchCenter: "Malanjkhand_PouniSuji_D (Madhya Pradesh)",
        OriginCenter: "Malanjkhand_PouniSuji_D (Madhya Pradesh)",
        FacilityCity: "Malanjkhand",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19172,
        Pin: 431540,
        DispatchCenter: "Parbhani_Gajanannagar_D (Maharashtra)",
        OriginCenter: "Parbhani_Gajanannagar_D (Maharashtra)",
        FacilityCity: "Parbhani",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19173,
        Pin: 333045,
        DispatchCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        OriginCenter: "Sikar_IndustrialArea_I (Rajasthan)",
        FacilityCity: "Sikar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19174,
        Pin: 686630,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19175,
        Pin: 222108,
        DispatchCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        OriginCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        FacilityCity: "Jaunpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19176,
        Pin: 768045,
        DispatchCenter: "Bargarh_Sukuda_D (Orissa)",
        OriginCenter: "Bargarh_Sukuda_D (Orissa)",
        FacilityCity: "Bargarh",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19177,
        Pin: 272155,
        DispatchCenter: "Basti_Maharajganj_D (Uttar Pradesh)",
        OriginCenter: "Basti_Maharajganj_D (Uttar Pradesh)",
        FacilityCity: "Itava",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19178,
        Pin: 133021,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19179,
        Pin: 743601,
        DispatchCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        OriginCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        FacilityCity: "Dakshin Barasat",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19180,
        Pin: 284303,
        DispatchCenter: "Poonchh_AranchRd_D (Uttar Pradesh)",
        OriginCenter: "Poonchh_AranchRd_D (Uttar Pradesh)",
        FacilityCity: "Poonchh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19181,
        Pin: 389151,
        DispatchCenter: "Dahod_Vanzarwada_I (Gujarat)",
        OriginCenter: "Dahod_Vanzarwada_I (Gujarat)",
        FacilityCity: "Dahod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19182,
        Pin: 562119,
        DispatchCenter: "Kanakapura_Malagaalu_D (Karnataka)",
        OriginCenter: "Kanakapura_Malagaalu_D (Karnataka)",
        FacilityCity: "Kanakapura",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19183,
        Pin: 441809,
        DispatchCenter: "Sakoli_GaneshWard_D (Maharashtra)",
        OriginCenter: "Sakoli_GaneshWard_D (Maharashtra)",
        FacilityCity: "Sakoli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19184,
        Pin: 494223,
        DispatchCenter: "Bastar_Rajendranagar_D (Chhattisgarh)",
        OriginCenter: "Bastar_Rajendranagar_D (Chhattisgarh)",
        FacilityCity: "Bastar",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19185,
        Pin: 177208,
        DispatchCenter: "UnaHP_NangalRd_D (Himachal Pradesh)",
        OriginCenter: "UnaHP_NangalRd_D (Himachal Pradesh)",
        FacilityCity: "Una-HP",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19186,
        Pin: 460330,
        DispatchCenter: "Betul_Chicholi1_D (Madhya Pradesh)",
        OriginCenter: "Betul_Chicholi1_D (Madhya Pradesh)",
        FacilityCity: "Betul",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19187,
        Pin: 422005,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19188,
        Pin: 441215,
        DispatchCenter: "Saoli_NagarPanchyt_DPP (Maharashtra)",
        OriginCenter: "Saoli_NagarPanchyt_DPP (Maharashtra)",
        FacilityCity: "Saoli",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19189,
        Pin: 517587,
        DispatchCenter: "PutturAP_Pichatur_D (Andhra Pradesh)",
        OriginCenter: "PutturAP_Pichatur_D (Andhra Pradesh)",
        FacilityCity: "Puttur-AP",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19190,
        Pin: 523247,
        DispatchCenter: "Podili_Sravaniestate_D (Andhra Pradesh)",
        OriginCenter: "Podili_Sravaniestate_D (Andhra Pradesh)",
        FacilityCity: "Podili",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19191,
        Pin: 581354,
        DispatchCenter: "Bhatkal_Sodigadde_D (Karnataka)",
        OriginCenter: "Bhatkal_Sodigadde_D (Karnataka)",
        FacilityCity: "Bhatkal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19192,
        Pin: 515144,
        DispatchCenter: "Puttaprthi_Gokulam_D (Andhra Pradesh)",
        OriginCenter: "Puttaprthi_Gokulam_D (Andhra Pradesh)",
        FacilityCity: "Puttaparthi",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19193,
        Pin: 509223,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19194,
        Pin: 743262,
        DispatchCenter: "Bongaon_SukntDPP_D (West Bengal)",
        OriginCenter: "Bongaon_SukntDPP_D (West Bengal)",
        FacilityCity: "Bongaon",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19195,
        Pin: 695134,
        DispatchCenter: "Trivandrum_Neyatinkra_D (Kerala)",
        OriginCenter: "Trivandrum_Neyatinkra_D (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19196,
        Pin: 743701,
        DispatchCenter: "Bongaon_SukntDPP_D (West Bengal)",
        OriginCenter: "Bongaon_SukntDPP_D (West Bengal)",
        FacilityCity: "Bongaon",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19197,
        Pin: 396210,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19198,
        Pin: 533447,
        DispatchCenter: "Kothapalli_Vaakathippa_DPP (Andhra Pradesh)",
        OriginCenter: "Kothapalli_Vaakathippa_DPP (Andhra Pradesh)",
        FacilityCity: "Kothapalli",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19199,
        Pin: 230202,
        DispatchCenter: "Kunda_KaretiRd_D (Uttar Pradesh)",
        OriginCenter: "Kunda_KaretiRd_D (Uttar Pradesh)",
        FacilityCity: "Kunda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19200,
        Pin: 686502,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19201,
        Pin: 844115,
        DispatchCenter: "Hajipur_Belkunda_D (Bihar)",
        OriginCenter: "Hajipur_Belkunda_D (Bihar)",
        FacilityCity: "Hajipur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19202,
        Pin: 731304,
        DispatchCenter: "Nanoor_Central_DPP_1 (West Bengal)",
        OriginCenter: "Nanoor_Central_DPP_1 (West Bengal)",
        FacilityCity: "Nanoor",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19203,
        Pin: 574142,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19204,
        Pin: 680591,
        DispatchCenter: "Shoranur_Akamala_D (Kerala)",
        OriginCenter: "Shoranur_Akamala_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19205,
        Pin: 503120,
        DispatchCenter: "Kamareddy_KPRcolony_D (Telangana)",
        OriginCenter: "Kamareddy_KPRcolony_D (Telangana)",
        FacilityCity: "Kamareddy",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19206,
        Pin: 585419,
        DispatchCenter: "Basavakalyan_Morkhandi_DPP (Karnataka)",
        OriginCenter: "Basavakalyan_Morkhandi_DPP (Karnataka)",
        FacilityCity: "Basavakalyan",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19207,
        Pin: 790104,
        DispatchCenter: "Tawang_Cona_D (Arunachal Pradesh)",
        OriginCenter: "Tawang_Cona_D (Arunachal Pradesh)",
        FacilityCity: "Tawang",
        FacilityState: "Arunachal Pradesh",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19208,
        Pin: 532406,
        DispatchCenter: "Narasannapeta_SridviNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasannapeta_SridviNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasannapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19209,
        Pin: 641024,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19210,
        Pin: 572108,
        DispatchCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19211,
        Pin: 481102,
        DispatchCenter: "Balaghat_Sarekha_D (Madhya Pradesh)",
        OriginCenter: "Balaghat_Sarekha_D (Madhya Pradesh)",
        FacilityCity: "Balaghat",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19212,
        Pin: 680509,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19213,
        Pin: 410218,
        DispatchCenter: "Mumbai_Talojamidc_L (Maharashtra)",
        OriginCenter: "Mumbai_Talojamidc_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19214,
        Pin: 695002,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19215,
        Pin: 485005,
        DispatchCenter: "Satna_Nazirabad_I (Madhya Pradesh)",
        OriginCenter: "Satna_Nazirabad_I (Madhya Pradesh)",
        FacilityCity: "Satna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19216,
        Pin: 332711,
        DispatchCenter: "Neemkathana_Chhawani_D (Rajasthan)",
        OriginCenter: "Neemkathana_Chhawani_D (Rajasthan)",
        FacilityCity: "Neem ka Thana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19217,
        Pin: 689602,
        DispatchCenter: "Karukachal_Nedumkunam_D (Kerala)",
        OriginCenter: "Karukachal_Nedumkunam_D (Kerala)",
        FacilityCity: "Karukachal",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19218,
        Pin: 600130,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19219,
        Pin: 794001,
        DispatchCenter: "Tura_Matchakol_D (Meghalaya)",
        OriginCenter: "Tura_Matchakol_D (Meghalaya)",
        FacilityCity: "Tura",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19220,
        Pin: 388470,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19221,
        Pin: 560007,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19222,
        Pin: 770019,
        DispatchCenter: "Sundargarh_BndhaPli_DPP (Orissa)",
        OriginCenter: "Sundargarh_BndhaPli_DPP (Orissa)",
        FacilityCity: "Sundargarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19223,
        Pin: 247120,
        DispatchCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        OriginCenter: "Saharanpur_Madhonagar_P (Uttar Pradesh)",
        FacilityCity: "Saharanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19224,
        Pin: 606805,
        DispatchCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        OriginCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        FacilityCity: "Tiruvannamalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19225,
        Pin: 247775,
        DispatchCenter: "Shamli_SalekChandVihar_D (Uttar Pradesh)",
        OriginCenter: "Shamli_SalekChandVihar_D (Uttar Pradesh)",
        FacilityCity: "Shamli",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19226,
        Pin: 625566,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19227,
        Pin: 734224,
        DispatchCenter: "Kurseong_PankhabariR_D (West Bengal)",
        OriginCenter: "Kurseong_PankhabariR_D (West Bengal)",
        FacilityCity: "Kurseong",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19228,
        Pin: 144405,
        DispatchCenter: "Phagwara_PurewalNgr_D (Punjab)",
        OriginCenter: "Phagwara_PurewalNgr_D (Punjab)",
        FacilityCity: "Phagwara",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19229,
        Pin: 394163,
        DispatchCenter: "Mandvi_Tekro_DPP (Gujarat)",
        OriginCenter: "Mandvi_Tekro_DPP (Gujarat)",
        FacilityCity: "Mandvi (Surat)",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19230,
        Pin: 572126,
        DispatchCenter: "Kunigal_Vidyanagar_D (Karnataka)",
        OriginCenter: "Kunigal_Vidyanagar_D (Karnataka)",
        FacilityCity: "Kunigal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19231,
        Pin: 581336,
        DispatchCenter: "Sirsi_Kasturbanagar_D (Karnataka)",
        OriginCenter: "Sirsi_Kasturbanagar_D (Karnataka)",
        FacilityCity: "Sirsi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19232,
        Pin: 273407,
        DispatchCenter: "Sikriganj_Sikaridihkhurd_D (Uttar Pradesh)",
        OriginCenter: "Sikriganj_Sikaridihkhurd_D (Uttar Pradesh)",
        FacilityCity: "Sikriganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19233,
        Pin: 394130,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19234,
        Pin: 523001,
        DispatchCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        OriginCenter: "Ongole_Tangutur_H (Andhra Pradesh)",
        FacilityCity: "Ongole",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19235,
        Pin: 441913,
        DispatchCenter: "Tumsar_Dewhadi_DPP (Maharashtra)",
        OriginCenter: "Tumsar_Dewhadi_DPP (Maharashtra)",
        FacilityCity: "Tumsar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19236,
        Pin: 583117,
        DispatchCenter: "Bellary_Mundargi_I (Karnataka)",
        OriginCenter: "Bellary_Mundargi_I (Karnataka)",
        FacilityCity: "Bellary",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19237,
        Pin: 625011,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19238,
        Pin: 613501,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19239,
        Pin: 785610,
        DispatchCenter: "Golaghat_BaruaRd_D (Assam)",
        OriginCenter: "Golaghat_BaruaRd_D (Assam)",
        FacilityCity: "Golaghat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19240,
        Pin: 496665,
        DispatchCenter: "Raigarh_Kharsia_D (Chhattisgarh)",
        OriginCenter: "Raigarh_Kharsia_D (Chhattisgarh)",
        FacilityCity: "Raigarh",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19241,
        Pin: 641003,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19242,
        Pin: 695570,
        DispatchCenter: "Kallikkad_Mutyvila_D (Kerala)",
        OriginCenter: "Kallikkad_Mutyvila_D (Kerala)",
        FacilityCity: "Kallikkad",
        FacilityState: "Kerala",
        Zone : "S2"
      },
      {
        SN: 19243,
        Pin: 181133,
        DispatchCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19244,
        Pin: 110080,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19245,
        Pin: 606707,
        DispatchCenter: "Sankarapuram_Thanipadi_D (Tamil Nadu)",
        OriginCenter: "Sankarapuram_Thanipadi_D (Tamil Nadu)",
        FacilityCity: "Sankarapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19246,
        Pin: 361325,
        DispatchCenter: "Bhatiya_Shrijinagar_D (Gujarat)",
        OriginCenter: "Bhatiya_Shrijinagar_D (Gujarat)",
        FacilityCity: "Bhatiya",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19247,
        Pin: 131023,
        DispatchCenter: "Sonipat_Sector51_L (Haryana)",
        OriginCenter: "Sonipat_WazidpurSaboli_P (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19248,
        Pin: 606003,
        DispatchCenter: "Virudhachalam_Tamilnagar_D (Tamil Nadu)",
        OriginCenter: "Virudhachalam_Tamilnagar_D (Tamil Nadu)",
        FacilityCity: "Virudhachalam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19249,
        Pin: 585311,
        DispatchCenter: "Aland_Sherikarcolony_D (Karnataka)",
        OriginCenter: "Aland_Sherikarcolony_D (Karnataka)",
        FacilityCity: "Aland",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19250,
        Pin: 517297,
        DispatchCenter: "Madanapalle_Gurramkonda_D (Andhra Pradesh)",
        OriginCenter: "Madanapalle_Gurramkonda_D (Andhra Pradesh)",
        FacilityCity: "Madanapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19251,
        Pin: 686549,
        DispatchCenter: "Changanacherry_Perunna_D (Kerala)",
        OriginCenter: "Changanacherry_Perunna_D (Kerala)",
        FacilityCity: "Changanacherry",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19252,
        Pin: 518511,
        DispatchCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        OriginCenter: "Nandyal_SaleemNgr_D (Andhra Pradesh)",
        FacilityCity: "Nandyal",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19253,
        Pin: 812008,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19254,
        Pin: 611109,
        DispatchCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        OriginCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        FacilityCity: "Nagapattinam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19255,
        Pin: 492109,
        DispatchCenter: "Gariyaband_Dongari_D (Chhattisgarh)",
        OriginCenter: "Gariyaband_Dongari_D (Chhattisgarh)",
        FacilityCity: "Gariyaband",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19256,
        Pin: 134119,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19257,
        Pin: 125056,
        DispatchCenter: "Sirsa_Housingboardcol_D (Haryana)",
        OriginCenter: "Sirsa_Housingboardcol_D (Haryana)",
        FacilityCity: "Sirsa",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19258,
        Pin: 421603,
        DispatchCenter: "Asangaon_Adarshnagar_D (Maharashtra)",
        OriginCenter: "Asangaon_Adarshnagar_D (Maharashtra)",
        FacilityCity: "Asangaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19259,
        Pin: 587202,
        DispatchCenter: "Ilkal_Ward4_D (Karnataka)",
        OriginCenter: "Ilkal_Ward4_D (Karnataka)",
        FacilityCity: "Ilkal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19260,
        Pin: 431010,
        DispatchCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        OriginCenter: "Aurangabad_Gngapr_H (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19261,
        Pin: 762110,
        DispatchCenter: "Balliguda_Patakhanda_DPP (Orissa)",
        OriginCenter: "Balliguda_Patakhanda_DPP (Orissa)",
        FacilityCity: "Balliguda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19262,
        Pin: 851201,
        DispatchCenter: "Maheshkhunt_PashchimTola_D (Bihar)",
        OriginCenter: "Maheshkhunt_PashchimTola_D (Bihar)",
        FacilityCity: "Maheshkhunt",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19263,
        Pin: 609003,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19264,
        Pin: 394120,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19265,
        Pin: 223102,
        DispatchCenter: "Shahganj_RudhauliKalan_D (Uttar Pradesh)",
        OriginCenter: "Shahganj_RudhauliKalan_D (Uttar Pradesh)",
        FacilityCity: "Shahganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19266,
        Pin: 221210,
        DispatchCenter: "Cholapur_Katari_D (Uttar Pradesh)",
        OriginCenter: "Cholapur_Katari_D (Uttar Pradesh)",
        FacilityCity: "Cholapur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19267,
        Pin: 721422,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19268,
        Pin: 571607,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19269,
        Pin: 225406,
        DispatchCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        OriginCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        FacilityCity: "Dariyabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19270,
        Pin: 764005,
        DispatchCenter: "Jeypore_Bariniput_I (Orissa)",
        OriginCenter: "Jeypore_Bariniput_I (Orissa)",
        FacilityCity: "Jeypore",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19271,
        Pin: 670332,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19272,
        Pin: 577302,
        DispatchCenter: "Shimoga_Eshwariah_I (Karnataka)",
        OriginCenter: "Shimoga_Eshwariah_I (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19273,
        Pin: 450003,
        DispatchCenter: "Indore_BarodaArjun_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19274,
        Pin: 110079,
        DispatchCenter: "Delhi_BamnoliVillage_L (Delhi)",
        OriginCenter: "Delhi_BamnoliVillage_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19275,
        Pin: 686512,
        DispatchCenter: "Mundakayam_Varikkani_D (Kerala)",
        OriginCenter: "Mundakayam_Varikkani_D (Kerala)",
        FacilityCity: "Mundakayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19276,
        Pin: 144403,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19277,
        Pin: 638316,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19278,
        Pin: 206122,
        DispatchCenter: "Auraiya_Brahmngr_D (Uttar Pradesh)",
        OriginCenter: "Auraiya_Brahmngr_D (Uttar Pradesh)",
        FacilityCity: "Auraiya",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19279,
        Pin: 281308,
        DispatchCenter: "Mathura_Narsipuramrd_D (Uttar Pradesh)",
        OriginCenter: "Mathura_Narsipuramrd_D (Uttar Pradesh)",
        FacilityCity: "Mathura",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19280,
        Pin: 423207,
        DispatchCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        OriginCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        FacilityCity: "Malegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19281,
        Pin: 631703,
        DispatchCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        OriginCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        FacilityCity: "Kanchipuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19282,
        Pin: 248015,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19283,
        Pin: 206128,
        DispatchCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        OriginCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        FacilityCity: "Etawah",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19284,
        Pin: 225402,
        DispatchCenter: "Rudauli_Ghosaiyana_D (Uttar Pradesh)",
        OriginCenter: "Rudauli_Ghosaiyana_D (Uttar Pradesh)",
        FacilityCity: "Rudauli",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19285,
        Pin: 209821,
        DispatchCenter: "Bacchawaran_BhuyianTemple_D (Uttar Pradesh)",
        OriginCenter: "Bacchawaran_BhuyianTemple_D (Uttar Pradesh)",
        FacilityCity: "Bacchawaran",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19286,
        Pin: 754220,
        DispatchCenter: "Chandbali_Binataro_D (Orissa)",
        OriginCenter: "Chandbali_Binataro_D (Orissa)",
        FacilityCity: "Chandbali",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19287,
        Pin: 626136,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19288,
        Pin: 574225,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19289,
        Pin: 500073,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19290,
        Pin: 756120,
        DispatchCenter: "Dhamanagar_Temple_DPP (Orissa)",
        OriginCenter: "Dhamanagar_Temple_DPP (Orissa)",
        FacilityCity: "Dhamanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19291,
        Pin: 387560,
        DispatchCenter: "Matar_CityCenter_DPP (Gujarat)",
        OriginCenter: "Matar_CityCenter_DPP (Gujarat)",
        FacilityCity: "Matar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19292,
        Pin: 713343,
        DispatchCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        OriginCenter: "Rupnarayanpur_Salanpur_D (West Bengal)",
        FacilityCity: "Rupnarayanpur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19293,
        Pin: 400052,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19294,
        Pin: 141127,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19295,
        Pin: 680662,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19296,
        Pin: 462027,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19297,
        Pin: 176308,
        DispatchCenter: "Chamba_Sundla_D (Himachal Pradesh)",
        OriginCenter: "Chamba_Sundla_D (Himachal Pradesh)",
        FacilityCity: "Sundla",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19298,
        Pin: 848155,
        DispatchCenter: "Samastipur_RhmprRdli_D (Bihar)",
        OriginCenter: "Samastipur_RhmprRdli_D (Bihar)",
        FacilityCity: "Samastipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19299,
        Pin: 380012,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19300,
        Pin: 425305,
        DispatchCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        OriginCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        FacilityCity: "Bhusawal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19301,
        Pin: 385540,
        DispatchCenter: "Deesa_CrystalCplx_D (Gujarat)",
        OriginCenter: "Deesa_CrystalCplx_D (Gujarat)",
        FacilityCity: "Deesa",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19302,
        Pin: 679121,
        DispatchCenter: "Shoranur_Akamala_D (Kerala)",
        OriginCenter: "Shoranur_Akamala_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19303,
        Pin: 676502,
        DispatchCenter: "Tirur_Perunthallur_D (Kerala)",
        OriginCenter: "Tirur_Perunthallur_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19304,
        Pin: 636702,
        DispatchCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        OriginCenter: "Dharmapuri_Agrinagar_D (Tamil Nadu)",
        FacilityCity: "Dharmapuri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19305,
        Pin: 751005,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19306,
        Pin: 202140,
        DispatchCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        OriginCenter: "Khair_DhundhiNagla_D (Uttar Pradesh)",
        FacilityCity: "Khair",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19307,
        Pin: 243402,
        DispatchCenter: "Budaun_Dataganj_D (Uttar Pradesh)",
        OriginCenter: "Budaun_Dataganj_D (Uttar Pradesh)",
        FacilityCity: "Dataganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19308,
        Pin: 685511,
        DispatchCenter: "Kattappana_EratayarRoad_D (Kerala)",
        OriginCenter: "Kattappana_EratayarRoad_D (Kerala)",
        FacilityCity: "Kattappana",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19309,
        Pin: 382110,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19310,
        Pin: 754004,
        DispatchCenter: "Nimapara_Nuharakiri_DPP (Orissa)",
        OriginCenter: "Nimapara_Nuharakiri_DPP (Orissa)",
        FacilityCity: "Nimapara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19311,
        Pin: 629178,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19312,
        Pin: 802122,
        DispatchCenter: "Rajpur_Devdhiya_D (Bihar)",
        OriginCenter: "Rajpur_Devdhiya_D (Bihar)",
        FacilityCity: "Rajpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19313,
        Pin: 734311,
        DispatchCenter: "Kalimpong_Algarah_DPP (West Bengal)",
        OriginCenter: "Kalimpong_Algarah_DPP (West Bengal)",
        FacilityCity: "Kalimpong",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19314,
        Pin: 212404,
        DispatchCenter: "Phulpur_BeerKaji_D (Uttar Pradesh)",
        OriginCenter: "Phulpur_BeerKaji_D (Uttar Pradesh)",
        FacilityCity: "Phulpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19315,
        Pin: 212650,
        DispatchCenter: "Khaga_GTRoad_D (Uttar Pradesh)",
        OriginCenter: "Khaga_GTRoad_D (Uttar Pradesh)",
        FacilityCity: "Khaga",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19316,
        Pin: 263152,
        DispatchCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        OriginCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        FacilityCity: "Rudrapur",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19317,
        Pin: 530048,
        DispatchCenter: "Visakhapatnam_PMPalem_D (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19318,
        Pin: 322023,
        DispatchCenter: "Bonli_Jamdoli_DPP (Rajasthan)",
        OriginCenter: "Bonli_Jamdoli_DPP (Rajasthan)",
        FacilityCity: "Bonli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19319,
        Pin: 144501,
        DispatchCenter: "Phagwara_PurewalNgr_D (Punjab)",
        OriginCenter: "Phagwara_PurewalNgr_D (Punjab)",
        FacilityCity: "Phagwara",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19320,
        Pin: 691545,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19321,
        Pin: 261202,
        DispatchCenter: "Biswan_PatelNGR_D (Uttar Pradesh)",
        OriginCenter: "Biswan_PatelNGR_D (Uttar Pradesh)",
        FacilityCity: "Biswan",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19322,
        Pin: 752062,
        DispatchCenter: "Khurdha_MdhsnDPP_D (Orissa)",
        OriginCenter: "Khurdha_MdhsnDPP_D (Orissa)",
        FacilityCity: "Khurdha",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19323,
        Pin: 843322,
        DispatchCenter: "Sonbarsa_Sitamarhi_D (Bihar)",
        OriginCenter: "Sonbarsa_Sitamarhi_D (Bihar)",
        FacilityCity: "Sonbarsa",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19324,
        Pin: 445204,
        DispatchCenter: "Pusad_Central_DPP_2 (Maharashtra)",
        OriginCenter: "Pusad_Central_DPP_2 (Maharashtra)",
        FacilityCity: "Pusad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19325,
        Pin: 422213,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19326,
        Pin: 500074,
        DispatchCenter: "Hyderabad_Autonagar_L (Telangana)",
        OriginCenter: "Hyderabad_Autonagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19327,
        Pin: 623005,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19328,
        Pin: 273307,
        DispatchCenter: "Nichlaul_Gorahwa_D (Uttar Pradesh)",
        OriginCenter: "Nichlaul_Gorahwa_D (Uttar Pradesh)",
        FacilityCity: "Nichlaul",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19329,
        Pin: 756060,
        DispatchCenter: "Balasore_Ganeswarpur_D (Orissa)",
        OriginCenter: "Balasore_Ganeswarpur_D (Orissa)",
        FacilityCity: "Balasore",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19330,
        Pin: 193103,
        DispatchCenter: "Baramulla_YaseenDPP_D (Jammu & Kashmir)",
        OriginCenter: "Baramulla_YaseenDPP_D (Jammu & Kashmir)",
        FacilityCity: "Baramulla",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19331,
        Pin: 262805,
        DispatchCenter: "Gola_BkgnRoad_D (Uttar Pradesh)",
        OriginCenter: "Gola_BkgnRoad_D (Uttar Pradesh)",
        FacilityCity: "Gola",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19332,
        Pin: 678018,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19333,
        Pin: 313802,
        DispatchCenter: "Kherwara_Banjariya_D (Rajasthan)",
        OriginCenter: "Kherwara_Banjariya_D (Rajasthan)",
        FacilityCity: "Kherwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19334,
        Pin: 171004,
        DispatchCenter: "Shimla_Tara_DC (Himachal Pradesh)",
        OriginCenter: "Shimla_Tara_DC (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19335,
        Pin: 825405,
        DispatchCenter: "Chouparan_Chaithimore_D (Jharkhand)",
        OriginCenter: "Chouparan_Chaithimore_D (Jharkhand)",
        FacilityCity: "Chouparan",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19336,
        Pin: 683525,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19337,
        Pin: 470118,
        DispatchCenter: "Khurai_Ward11_DPP (Madhya Pradesh)",
        OriginCenter: "Khurai_Ward11_DPP (Madhya Pradesh)",
        FacilityCity: "Khurai",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19338,
        Pin: 560011,
        DispatchCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        OriginCenter: "Bengaluru_Bnnrghta_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19339,
        Pin: 517541,
        DispatchCenter: "Tada_Sriharikota_D (Andhra Pradesh)",
        OriginCenter: "Tada_Sriharikota_D (Andhra Pradesh)",
        FacilityCity: "Tada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19340,
        Pin: 534425,
        DispatchCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        OriginCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        FacilityCity: "Eluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19341,
        Pin: 301417,
        DispatchCenter: "Bhiwadi_Central_D_2 (Rajasthan)",
        OriginCenter: "Bhiwadi_Central_D_2 (Rajasthan)",
        FacilityCity: "Bhiwadi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19342,
        Pin: 305009,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19343,
        Pin: 301702,
        DispatchCenter: "Tijara_Ward3_D (Rajasthan)",
        OriginCenter: "Tijara_Ward3_D (Rajasthan)",
        FacilityCity: "Tijara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19344,
        Pin: 209721,
        DispatchCenter: "Chhibramau_TajpurRd_D (Uttar Pradesh)",
        OriginCenter: "Chhibramau_TajpurRd_D (Uttar Pradesh)",
        FacilityCity: "Chhibramau",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19345,
        Pin: 245402,
        DispatchCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        OriginCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        FacilityCity: "Siyana",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19346,
        Pin: 797004,
        DispatchCenter: "Kohima_Thizama_D (Nagaland)",
        OriginCenter: "Kohima_Thizama_D (Nagaland)",
        FacilityCity: "Tlabung",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19347,
        Pin: 505532,
        DispatchCenter: "Jagtial_Gollapally_DPP (Telangana)",
        OriginCenter: "Jagtial_Gollapally_DPP (Telangana)",
        FacilityCity: "Jagtial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19348,
        Pin: 695310,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19349,
        Pin: 626204,
        DispatchCenter: "Virudhunagar_Allampatti_DPP (Tamil Nadu)",
        OriginCenter: "Virudhunagar_Allampatti_DPP (Tamil Nadu)",
        FacilityCity: "Virudhunagar",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19350,
        Pin: 636004,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19351,
        Pin: 614301,
        DispatchCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        OriginCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19352,
        Pin: 755010,
        DispatchCenter: "Kendrapara_Penthapal_D (Orissa)",
        OriginCenter: "Kendrapara_Penthapal_D (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19353,
        Pin: 225126,
        DispatchCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        OriginCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        FacilityCity: "Haidargarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19354,
        Pin: 743223,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19355,
        Pin: 506301,
        DispatchCenter: "Ghanpur_Palakrty_D (Telangana)",
        OriginCenter: "Ghanpur_Palakrty_D (Telangana)",
        FacilityCity: "Ghanpur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19356,
        Pin: 473112,
        DispatchCenter: "Guna_Sadacolony_D (Madhya Pradesh)",
        OriginCenter: "Guna_Sadacolony_D (Madhya Pradesh)",
        FacilityCity: "Guna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19357,
        Pin: 431509,
        DispatchCenter: "Mantha_Jintur_DPP (Maharashtra)",
        OriginCenter: "Mantha_Jintur_DPP (Maharashtra)",
        FacilityCity: "Mantha",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19358,
        Pin: 388132,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19359,
        Pin: 242405,
        DispatchCenter: "Khutar_Narayanpur_D (Uttar Pradesh)",
        OriginCenter: "Khutar_Narayanpur_D (Uttar Pradesh)",
        FacilityCity: "Khutar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19360,
        Pin: 140203,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19361,
        Pin: 152122,
        DispatchCenter: "Abohar_DaulatPura_D (Punjab)",
        OriginCenter: "Abohar_DaulatPura_D (Punjab)",
        FacilityCity: "Abohar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19362,
        Pin: 679311,
        DispatchCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        OriginCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        FacilityCity: "Sankaramangalam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19363,
        Pin: 670708,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19364,
        Pin: 504311,
        DispatchCenter: "Utnoor_MNCLRd_D (Telangana)",
        OriginCenter: "Utnoor_MNCLRd_D (Telangana)",
        FacilityCity: "Utnoor",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19365,
        Pin: 828124,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19366,
        Pin: 583112,
        DispatchCenter: "Kudligi_Vidyanagar_D (Karnataka)",
        OriginCenter: "Kudligi_Vidyanagar_D (Karnataka)",
        FacilityCity: "Kudligi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19367,
        Pin: 530019,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19368,
        Pin: 177109,
        DispatchCenter: "Chintpurni_Bharwain_D (Himachal Pradesh)",
        OriginCenter: "Chintpurni_Bharwain_D (Himachal Pradesh)",
        FacilityCity: "Chintpurni",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19369,
        Pin: 503224,
        DispatchCenter: "Armoor_Mamidipillay_D (Telangana)",
        OriginCenter: "Armoor_Mamidipillay_D (Telangana)",
        FacilityCity: "Armoor",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19370,
        Pin: 144010,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19371,
        Pin: 126101,
        DispatchCenter: "Meham_Ward13_D (Haryana)",
        OriginCenter: "Meham_Ward13_D (Haryana)",
        FacilityCity: "Meham",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19372,
        Pin: 614806,
        DispatchCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Thiruthuraipoondi_D (Tamil Nadu)",
        FacilityCity: "Thiruthuraipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19373,
        Pin: 732216,
        DispatchCenter: "Malda_Tiakati_I (West Bengal)",
        OriginCenter: "Malda_Tiakati_I (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19374,
        Pin: 761126,
        DispatchCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        OriginCenter: "Bhanjanagar_Laxmivihar_D (Orissa)",
        FacilityCity: "Bhanjanagar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19375,
        Pin: 392140,
        DispatchCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        OriginCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        FacilityCity: "Jambusar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19376,
        Pin: 451221,
        DispatchCenter: "Maheshwar_DhmnodRd_DPP (Madhya Pradesh)",
        OriginCenter: "Maheshwar_DhmnodRd_DPP (Madhya Pradesh)",
        FacilityCity: "Maheshwar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19377,
        Pin: 847201,
        DispatchCenter: "Benipur_Karhari_D (Bihar)",
        OriginCenter: "Benipur_Karhari_D (Bihar)",
        FacilityCity: "Benipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19378,
        Pin: 535214,
        DispatchCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        OriginCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        FacilityCity: "Vizianagaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19379,
        Pin: 574323,
        DispatchCenter: "Bantwal_BambilRd_D (Karnataka)",
        OriginCenter: "Bantwal_BambilRd_D (Karnataka)",
        FacilityCity: "Bantwal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19380,
        Pin: 385110,
        DispatchCenter: "Ambaji_Khodiyarchowk_DPP (Gujarat)",
        OriginCenter: "Ambaji_Khodiyarchowk_DPP (Gujarat)",
        FacilityCity: "Ambaji",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19381,
        Pin: 160104,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19382,
        Pin: 576219,
        DispatchCenter: "Byndoor_Krmnjshwra_DPP (Karnataka)",
        OriginCenter: "Byndoor_Krmnjshwra_DPP (Karnataka)",
        FacilityCity: "Byndoor",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19383,
        Pin: 628003,
        DispatchCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        OriginCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        FacilityCity: "Tuticorin",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19384,
        Pin: 689666,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19385,
        Pin: 488050,
        DispatchCenter: "Pawai_Gunaur_DPP (Madhya Pradesh)",
        OriginCenter: "Pawai_Gunaur_DPP (Madhya Pradesh)",
        FacilityCity: "Pawai",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19386,
        Pin: 689644,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19387,
        Pin: 701101,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19388,
        Pin: 695557,
        DispatchCenter: "Nedumangad_Central_D (Kerala)",
        OriginCenter: "Nedumangad_Central_D (Kerala)",
        FacilityCity: "Nedumangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19389,
        Pin: 676521,
        DispatchCenter: "Pandikkad_Thangalpadi_D (Kerala)",
        OriginCenter: "Pandikkad_Thangalpadi_D (Kerala)",
        FacilityCity: "Pandikkad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19390,
        Pin: 690521,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19391,
        Pin: 627416,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19392,
        Pin: 628001,
        DispatchCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        OriginCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        FacilityCity: "Tuticorin",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19393,
        Pin: 151213,
        DispatchCenter: "Faridkot_JatinderChwk_D (Punjab)",
        OriginCenter: "Faridkot_JatinderChwk_D (Punjab)",
        FacilityCity: "Faridkot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19394,
        Pin: 788102,
        DispatchCenter: "Pailapool_Lagktlbocpr_D (Assam)",
        OriginCenter: "Pailapool_Lagktlbocpr_D (Assam)",
        FacilityCity: "Pailapool",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19395,
        Pin: 382460,
        DispatchCenter: "Dhandhuka_BhavnagarRd_DPP (Gujarat)",
        OriginCenter: "Dhandhuka_BhavnagarRd_DPP (Gujarat)",
        FacilityCity: "Dhandhuka",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19396,
        Pin: 712502,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19397,
        Pin: 605651,
        DispatchCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        OriginCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        FacilityCity: "Gingee",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19398,
        Pin: 508217,
        DispatchCenter: "Miryalguda_Ragvendr_D (Telangana)",
        OriginCenter: "Miryalguda_Ragvendr_D (Telangana)",
        FacilityCity: "Miryalaguda",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19399,
        Pin: 400218,
        DispatchCenter: "Mumbai_Talojamidc_L (Maharashtra)",
        OriginCenter: "Mumbai_Talojamidc_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19400,
        Pin: 629706,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19401,
        Pin: 224205,
        DispatchCenter: "Kumarganj_Siddhiganeshpur_D (Uttar Pradesh)",
        OriginCenter: "Kumarganj_Siddhiganeshpur_D (Uttar Pradesh)",
        FacilityCity: "Kumarganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19402,
        Pin: 516319,
        DispatchCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        OriginCenter: "Proddatur_Bollavaram_D (Andhra Pradesh)",
        FacilityCity: "Proddatur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19403,
        Pin: 518464,
        DispatchCenter: "Yemignur_Timmappacolony_D (Andhra Pradesh)",
        OriginCenter: "Yemignur_Timmappacolony_D (Andhra Pradesh)",
        FacilityCity: "Yemmiganur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19404,
        Pin: 412112,
        DispatchCenter: "Pirangut_PoliceChowki_D (Maharashtra)",
        OriginCenter: "Pirangut_PoliceChowki_D (Maharashtra)",
        FacilityCity: "Pirangut",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19405,
        Pin: 516127,
        DispatchCenter: "Rajampet_BypassRd_D (Andhra Pradesh)",
        OriginCenter: "Rajampet_BypassRd_D (Andhra Pradesh)",
        FacilityCity: "Rajampet",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19406,
        Pin: 784529,
        DispatchCenter: "Mangaldoi_Anandanagar_D (Assam)",
        OriginCenter: "Mangaldoi_Anandanagar_D (Assam)",
        FacilityCity: "Mangaldoi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19407,
        Pin: 285123,
        DispatchCenter: "Jalaun_Sengarcol_D (Uttar Pradesh)",
        OriginCenter: "Jalaun_Sengarcol_D (Uttar Pradesh)",
        FacilityCity: "Jalaun",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19408,
        Pin: 799279,
        DispatchCenter: "Kailashahar_Ward4_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Kailashahar",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19409,
        Pin: 421302,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19410,
        Pin: 185212,
        DispatchCenter: "Rajouri_Majhur_D (Jammu & Kashmir)",
        OriginCenter: "Rajouri_Majhur_D (Jammu & Kashmir)",
        FacilityCity: "Rajouri",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19411,
        Pin: 534330,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19412,
        Pin: 484881,
        DispatchCenter: "Anuppur_Kirgi_D (Madhya Pradesh)",
        OriginCenter: "Anuppur_Kirgi_D (Madhya Pradesh)",
        FacilityCity: "Anuppur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19413,
        Pin: 331303,
        DispatchCenter: "Rajgarh_Taranagar_D (Rajasthan)",
        OriginCenter: "Rajgarh_Taranagar_D (Rajasthan)",
        FacilityCity: "Rajgarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19414,
        Pin: 686687,
        DispatchCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        OriginCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        FacilityCity: "Muvattupuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19415,
        Pin: 390010,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19416,
        Pin: 614206,
        DispatchCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        OriginCenter: "Ayyampettai_Papiredipati_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19417,
        Pin: 670339,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19418,
        Pin: 581362,
        DispatchCenter: "Kumta_Central_DPP_2 (Karnataka)",
        OriginCenter: "Kumta_Central_DPP_2 (Karnataka)",
        FacilityCity: "Kumta",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19419,
        Pin: 382042,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19420,
        Pin: 673013,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19421,
        Pin: 680752,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19422,
        Pin: 851112,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19423,
        Pin: 571301,
        DispatchCenter: "Nanjangud_Deviramanahalli_D (Karnataka)",
        OriginCenter: "Nanjangud_Deviramanahalli_D (Karnataka)",
        FacilityCity: "Nanjangud",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19424,
        Pin: 394248,
        DispatchCenter: "Vansda_Chikatiya_D (Gujarat)",
        OriginCenter: "Vansda_Chikatiya_D (Gujarat)",
        FacilityCity: "Vansda",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19425,
        Pin: 243637,
        DispatchCenter: "Budaun_KrishnaPuriRd_D (Uttar Pradesh)",
        OriginCenter: "Budaun_KrishnaPuriRd_D (Uttar Pradesh)",
        FacilityCity: "Budaun",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19426,
        Pin: 735207,
        DispatchCenter: "Birpara_Binnaguri_D (West Bengal)",
        OriginCenter: "Birpara_Binnaguri_D (West Bengal)",
        FacilityCity: "Birpara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19427,
        Pin: 125042,
        DispatchCenter: "Meham_Ward13_D (Haryana)",
        OriginCenter: "Meham_Ward13_D (Haryana)",
        FacilityCity: "Meham",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19428,
        Pin: 141118,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19429,
        Pin: 414402,
        DispatchCenter: "Karjat_Gaykarwadi_D (Maharashtra)",
        OriginCenter: "Karjat_Gaykarwadi_D (Maharashtra)",
        FacilityCity: "Karjat-MH",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19430,
        Pin: 533308,
        DispatchCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        OriginCenter: "Mandapeta_Gandhinagar_D (Andhra Pradesh)",
        FacilityCity: "Mandapeta",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19431,
        Pin: 829103,
        DispatchCenter: "Ramgarh_Marar_D (Jharkhand)",
        OriginCenter: "Ramgarh_Marar_D (Jharkhand)",
        FacilityCity: "Ramgarh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19432,
        Pin: 144033,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19433,
        Pin: 822113,
        DispatchCenter: "Chhatarpur_JaplaRd_D (Jharkhand)",
        OriginCenter: "Chhatarpur_JaplaRd_D (Jharkhand)",
        FacilityCity: "Chhatarpur-JR",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19434,
        Pin: 533218,
        DispatchCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        OriginCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        FacilityCity: "Amalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19435,
        Pin: 786610,
        DispatchCenter: "Naharkatia_JaypurNagar_D (Assam)",
        OriginCenter: "Naharkatia_JaypurNagar_D (Assam)",
        FacilityCity: "Naharkatia",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19436,
        Pin: 508201,
        DispatchCenter: "Nereducharla_GaridepalliRoad_D (Telangana)",
        OriginCenter: "Nereducharla_GaridepalliRoad_D (Telangana)",
        FacilityCity: "Nereducharla",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19437,
        Pin: 410210,
        DispatchCenter: "Mumbai_Talojamidc_L (Maharashtra)",
        OriginCenter: "Mumbai_Talojamidc_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19438,
        Pin: 630207,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19439,
        Pin: 624206,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19440,
        Pin: 784115,
        DispatchCenter: "Mangaldoi_Anandanagar_D (Assam)",
        OriginCenter: "Mangaldoi_Anandanagar_D (Assam)",
        FacilityCity: "Mangaldoi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19441,
        Pin: 175104,
        DispatchCenter: "Patlikuhal_Katrain_D (Himachal Pradesh)",
        OriginCenter: "Patlikuhal_Katrain_D (Himachal Pradesh)",
        FacilityCity: "Patlikuhal",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19442,
        Pin: 403702,
        DispatchCenter: "Canacona_Shristal_D (Goa)",
        OriginCenter: "Canacona_Shristal_D (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19443,
        Pin: 382350,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19444,
        Pin: 465691,
        DispatchCenter: "RajgarhMP_Jirapur_D (Madhya Pradesh)",
        OriginCenter: "RajgarhMP_Jirapur_D (Madhya Pradesh)",
        FacilityCity: "Rajgarh-MP",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19445,
        Pin: 272124,
        DispatchCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        OriginCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        FacilityCity: "Basti",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19446,
        Pin: 680608,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19447,
        Pin: 627604,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19448,
        Pin: 688586,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19449,
        Pin: 722181,
        DispatchCenter: "Bankura_Chhatna_DPP (West Bengal)",
        OriginCenter: "Bankura_Chhatna_DPP (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19450,
        Pin: 127028,
        DispatchCenter: "Bahal_Gdccolony_D (Haryana)",
        OriginCenter: "Bahal_Gdccolony_D (Haryana)",
        FacilityCity: "Bahal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19451,
        Pin: 764036,
        DispatchCenter: "Semiliguda_HimgiriNagar_D (Orissa)",
        OriginCenter: "Semiliguda_HimgiriNagar_D (Orissa)",
        FacilityCity: "Semiliguda",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19452,
        Pin: 412104,
        DispatchCenter: "Saswad_Sopannagar_D (Maharashtra)",
        OriginCenter: "Saswad_Sopannagar_D (Maharashtra)",
        FacilityCity: "Saswad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19453,
        Pin: 171007,
        DispatchCenter: "Shimla_Jawaharcolony_D (Himachal Pradesh)",
        OriginCenter: "Shimla_Jawaharcolony_D (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19454,
        Pin: 388590,
        DispatchCenter: "Vatadara_Banejda_DPP (Gujarat)",
        OriginCenter: "Vatadara_Banejda_DPP (Gujarat)",
        FacilityCity: "Vatadara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19455,
        Pin: 636905,
        DispatchCenter: "Harur_EBOfc_D (Tamil Nadu)",
        OriginCenter: "Harur_EBOfc_D (Tamil Nadu)",
        FacilityCity: "Harur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19456,
        Pin: 673505,
        DispatchCenter: "Thalassery_Iyyamkode_D (Kerala)",
        OriginCenter: "Thalassery_Iyyamkode_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19457,
        Pin: 507126,
        DispatchCenter: "Chintoor_PoliceStn_DPP (Telangana)",
        OriginCenter: "Chintoor_PoliceStn_DPP (Telangana)",
        FacilityCity: "Chintoor",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19458,
        Pin: 586242,
        DispatchCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        OriginCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        FacilityCity: "Indi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19459,
        Pin: 177043,
        DispatchCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        OriginCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        FacilityCity: "Nadaun",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19460,
        Pin: 221713,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19461,
        Pin: 284403,
        DispatchCenter: "Lalitpur_Govindnagar_D (Uttar Pradesh)",
        OriginCenter: "Lalitpur_Govindnagar_D (Uttar Pradesh)",
        FacilityCity: "Lalitpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19462,
        Pin: 344034,
        DispatchCenter: "Barmer_Baldevnagar_DPP (Rajasthan)",
        OriginCenter: "Barmer_Baldevnagar_DPP (Rajasthan)",
        FacilityCity: "Barmer",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19463,
        Pin: 185156,
        DispatchCenter: "Sunderbani_Ward5_D (Jammu & Kashmir)",
        OriginCenter: "Sunderbani_Ward5_D (Jammu & Kashmir)",
        FacilityCity: "Sunderbani",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19464,
        Pin: 303801,
        DispatchCenter: "Chomu_SamodRd_D (Rajasthan)",
        OriginCenter: "Chomu_SamodRd_D (Rajasthan)",
        FacilityCity: "Chomu",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19465,
        Pin: 581329,
        DispatchCenter: "Haliyal_Anegundicolony_DPP (Karnataka)",
        OriginCenter: "Haliyal_Anegundicolony_DPP (Karnataka)",
        FacilityCity: "Haliyal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19466,
        Pin: 313327,
        DispatchCenter: "Fatehnagar_Railmagra_DPP (Rajasthan)",
        OriginCenter: "Fatehnagar_Railmagra_DPP (Rajasthan)",
        FacilityCity: "Fatehnagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19467,
        Pin: 141102,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19468,
        Pin: 495452,
        DispatchCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        OriginCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        FacilityCity: "Korba",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19469,
        Pin: 228121,
        DispatchCenter: "Kumarganj_Siddhiganeshpur_D (Uttar Pradesh)",
        OriginCenter: "Kumarganj_Siddhiganeshpur_D (Uttar Pradesh)",
        FacilityCity: "Kumarganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19470,
        Pin: 342020,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19471,
        Pin: 370160,
        DispatchCenter: "Rapar_MalichokRd_DPP (Gujarat)",
        OriginCenter: "Rapar_MalichokRd_DPP (Gujarat)",
        FacilityCity: "Rapar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19472,
        Pin: 743435,
        DispatchCenter: "Taki_Hasnabad_DPP (West Bengal)",
        OriginCenter: "Taki_Hasnabad_DPP (West Bengal)",
        FacilityCity: "Taki",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19473,
        Pin: 535280,
        DispatchCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        OriginCenter: "Vizianagaram_Ward4_D (Andhra Pradesh)",
        FacilityCity: "Vizianagaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19474,
        Pin: 635104,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19475,
        Pin: 712712,
        DispatchCenter: "Hooghly_Bikramnagar_D (West Bengal)",
        OriginCenter: "Hooghly_Bikramnagar_D (West Bengal)",
        FacilityCity: "Hooghly",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19476,
        Pin: 127201,
        DispatchCenter: "Loharu_HsptlRd_D (Haryana)",
        OriginCenter: "Loharu_HsptlRd_D (Haryana)",
        FacilityCity: "Loharu",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19477,
        Pin: 229205,
        DispatchCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        OriginCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        FacilityCity: "Lalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19478,
        Pin: 363435,
        DispatchCenter: "Surendranagar_GIDC_D (Gujarat)",
        OriginCenter: "Surendranagar_GIDC_D (Gujarat)",
        FacilityCity: "Surendranagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19479,
        Pin: 484660,
        DispatchCenter: "Umaria_BadhvgarhRd_D (Madhya Pradesh)",
        OriginCenter: "Umaria_BadhvgarhRd_D (Madhya Pradesh)",
        FacilityCity: "Umaria",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19480,
        Pin: 757041,
        DispatchCenter: "Udala_Mayurbhanj_DPP (Orissa)",
        OriginCenter: "Udala_Mayurbhanj_DPP (Orissa)",
        FacilityCity: "Udala",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19481,
        Pin: 414709,
        DispatchCenter: "Daund_Kurkumbh_L (Maharashtra)",
        OriginCenter: "Daund_Kurkumbh_L (Maharashtra)",
        FacilityCity: "Daund",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19482,
        Pin: 142028,
        DispatchCenter: "Moga_Duneke_I (Punjab)",
        OriginCenter: "Moga_Duneke_I (Punjab)",
        FacilityCity: "Moga",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19483,
        Pin: 380049,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19484,
        Pin: 455336,
        DispatchCenter: "Khategaon_ChohanClny_D (Madhya Pradesh)",
        OriginCenter: "Khategaon_ChohanClny_D (Madhya Pradesh)",
        FacilityCity: "Khategaon",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19485,
        Pin: 581165,
        DispatchCenter: "Rona_GadagRD_D (Karnataka)",
        OriginCenter: "Rona_GadagRD_D (Karnataka)",
        FacilityCity: "Rona",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19486,
        Pin: 390023,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19487,
        Pin: 506381,
        DispatchCenter: "Khammam_Mudigonda_H (Telangana)",
        OriginCenter: "Khammam_Mudigonda_H (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19488,
        Pin: 523187,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19489,
        Pin: 160041,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19490,
        Pin: 504106,
        DispatchCenter: "Nirmal_AdarshNgr_D (Telangana)",
        OriginCenter: "Nirmal_AdarshNgr_D (Telangana)",
        FacilityCity: "Nirmal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19491,
        Pin: 416422,
        DispatchCenter: "Tasgaon_Trimurticolony_D (Maharashtra)",
        OriginCenter: "Tasgaon_Trimurticolony_D (Maharashtra)",
        FacilityCity: "Tasgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19492,
        Pin: 110022,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19493,
        Pin: 636403,
        DispatchCenter: "Mettur_Samballi_D (Tamil Nadu)",
        OriginCenter: "Mettur_Samballi_D (Tamil Nadu)",
        FacilityCity: "Mettur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19494,
        Pin: 682302,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19495,
        Pin: 342021,
        DispatchCenter: "Balesar_Unthwaliya_DPP (Rajasthan)",
        OriginCenter: "Balesar_Unthwaliya_DPP (Rajasthan)",
        FacilityCity: "Balesar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19496,
        Pin: 851225,
        DispatchCenter: "Begusarai_Haibatpur_D (Bihar)",
        OriginCenter: "Begusarai_Haibatpur_D (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19497,
        Pin: 306708,
        DispatchCenter: "Sumerpur_RgukulColny_DPP (Rajasthan)",
        OriginCenter: "Sumerpur_RgukulColny_DPP (Rajasthan)",
        FacilityCity: "Sumerpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19498,
        Pin: 770040,
        DispatchCenter: "Lahunipara_Gudhiali_D (Orissa)",
        OriginCenter: "Lahunipara_Gudhiali_D (Orissa)",
        FacilityCity: "Lahunipara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19499,
        Pin: 172027,
        DispatchCenter: "Kumarsain_Bharara_D (Himachal Pradesh)",
        OriginCenter: "Kumarsain_Bharara_D (Himachal Pradesh)",
        FacilityCity: "Kumarsain",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19500,
        Pin: 242021,
        DispatchCenter: "Babrala_Teacherscolony_D (Uttar Pradesh)",
        OriginCenter: "Babrala_Teacherscolony_D (Uttar Pradesh)",
        FacilityCity: "Babrala",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19501,
        Pin: 411058,
        DispatchCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        OriginCenter: "Pune_PandurangIndArea_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19502,
        Pin: 445305,
        DispatchCenter: "Wani_GuruNGR_D (Maharashtra)",
        OriginCenter: "Wani_GuruNGR_D (Maharashtra)",
        FacilityCity: "Wani",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19503,
        Pin: 683516,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19504,
        Pin: 577450,
        DispatchCenter: "Shikaripur_Mandipet_D (Karnataka)",
        OriginCenter: "Shikaripur_Mandipet_D (Karnataka)",
        FacilityCity: "Shikaripur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19505,
        Pin: 394345,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19506,
        Pin: 452006,
        DispatchCenter: "Indore_BarodaArjun_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19507,
        Pin: 591192,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19508,
        Pin: 686005,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19509,
        Pin: 201014,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19510,
        Pin: 312203,
        DispatchCenter: "Kapasan_AdarshNgr_DPP (Rajasthan)",
        OriginCenter: "Kapasan_AdarshNgr_DPP (Rajasthan)",
        FacilityCity: "Kapasan",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19511,
        Pin: 135101,
        DispatchCenter: "Yamunanagar_Balachaur_D (Haryana)",
        OriginCenter: "Yamunanagar_Balachaur_D (Haryana)",
        FacilityCity: "Yamuna Nagar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19512,
        Pin: 262552,
        DispatchCenter: "Didihat_Thal_DPP (Uttarakhand)",
        OriginCenter: "Didihat_Thal_DPP (Uttarakhand)",
        FacilityCity: "Didihat",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19513,
        Pin: 685606,
        DispatchCenter: "Iddukki_Thadiyampad_D (Kerala)",
        OriginCenter: "Iddukki_Thadiyampad_D (Kerala)",
        FacilityCity: "Iddukki",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19514,
        Pin: 415014,
        DispatchCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        OriginCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        FacilityCity: "Satara",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19515,
        Pin: 382220,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19516,
        Pin: 473002,
        DispatchCenter: "Guna_Shahpur_H (Madhya Pradesh)",
        OriginCenter: "Guna_Shahpur_H (Madhya Pradesh)",
        FacilityCity: "Guna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19517,
        Pin: 577544,
        DispatchCenter: "Davangere_Santhebennur_D (Karnataka)",
        OriginCenter: "Davangere_Santhebennur_D (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19518,
        Pin: 626102,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19519,
        Pin: 845106,
        DispatchCenter: "Narkatiaganj_Sonkhar_D (Bihar)",
        OriginCenter: "Narkatiaganj_Sonkhar_D (Bihar)",
        FacilityCity: "Narkatiaganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19520,
        Pin: 177044,
        DispatchCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        OriginCenter: "Nadaun_Bela_D (Himachal Pradesh)",
        FacilityCity: "Nadaun",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19521,
        Pin: 244927,
        DispatchCenter: "Rampur_Suar_D (Uttar Pradesh)",
        OriginCenter: "Rampur_Suar_D (Uttar Pradesh)",
        FacilityCity: "Rampur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19522,
        Pin: 210121,
        DispatchCenter: "Baberu_FaujiColony_D (Uttar Pradesh)",
        OriginCenter: "Baberu_FaujiColony_D (Uttar Pradesh)",
        FacilityCity: "Baberu",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19523,
        Pin: 577519,
        DispatchCenter: "Davangere_Anagodu_D (Karnataka)",
        OriginCenter: "Davangere_Anagodu_D (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19524,
        Pin: 638011,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19525,
        Pin: 500022,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19526,
        Pin: 160009,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19527,
        Pin: 621718,
        DispatchCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        OriginCenter: "Ariyalur_AlagappaNagar_D (Tamil Nadu)",
        FacilityCity: "Ariyalur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19528,
        Pin: 757034,
        DispatchCenter: "Jashipur_Chndposi_D (Orissa)",
        OriginCenter: "Jashipur_Chndposi_D (Orissa)",
        FacilityCity: "Jashipur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19529,
        Pin: 686518,
        DispatchCenter: "Kottayam_Mulakunnath_D (Kerala)",
        OriginCenter: "Kottayam_Mulakunnath_D (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19530,
        Pin: 711107,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19531,
        Pin: 721632,
        DispatchCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        OriginCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        FacilityCity: "Tamluk",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19532,
        Pin: 757036,
        DispatchCenter: "Raruan_Badararuan_DPP (Orissa)",
        OriginCenter: "Raruan_Badararuan_DPP (Orissa)",
        FacilityCity: "Raruan",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19533,
        Pin: 457336,
        DispatchCenter: "Jaora_AdityaNagar_D (Madhya Pradesh)",
        OriginCenter: "Jaora_AdityaNagar_D (Madhya Pradesh)",
        FacilityCity: "Jaora",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19534,
        Pin: 272148,
        DispatchCenter: "Rudhauli_Mudiyar_D (Uttar Pradesh)",
        OriginCenter: "Rudhauli_Mudiyar_D (Uttar Pradesh)",
        FacilityCity: "Rudhauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19535,
        Pin: 394245,
        DispatchCenter: "Navsari_Vejalpore_D (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Navsari",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19536,
        Pin: 110007,
        DispatchCenter: "Delhi_Libaspur_L (Delhi)",
        OriginCenter: "Delhi_Libaspur_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19537,
        Pin: 122002,
        DispatchCenter: "Gurgaon_Sector52_L (Haryana)",
        OriginCenter: "Gurgaon_Sector52_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19538,
        Pin: 523332,
        DispatchCenter: "Markapur_VidyaNgr_D (Andhra Pradesh)",
        OriginCenter: "Markapur_VidyaNgr_D (Andhra Pradesh)",
        FacilityCity: "Markapur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19539,
        Pin: 403529,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19540,
        Pin: 614405,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19541,
        Pin: 854326,
        DispatchCenter: "Purnia_Fatehpur_D (Bihar)",
        OriginCenter: "Purnia_Fatehpur_D (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19542,
        Pin: 416702,
        DispatchCenter: "Rajapur_Sainagar_D (Maharashtra)",
        OriginCenter: "Rajapur_Sainagar_D (Maharashtra)",
        FacilityCity: "Rajapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19543,
        Pin: 396180,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19544,
        Pin: 629170,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19545,
        Pin: 444504,
        DispatchCenter: "Washim_Malegaon_DPP (Maharashtra)",
        OriginCenter: "Washim_Malegaon_DPP (Maharashtra)",
        FacilityCity: "Washim",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19546,
        Pin: 509152,
        DispatchCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        OriginCenter: "Kurnool_Dinnedevrpdu_I (Andhra Pradesh)",
        FacilityCity: "Kurnool",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19547,
        Pin: 796017,
        DispatchCenter: "Aizawl_Zemabawk_D (Mizoram)",
        OriginCenter: "Aizawl_Zemabawk_D (Mizoram)",
        FacilityCity: "Aizawl",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19548,
        Pin: 124505,
        DispatchCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        OriginCenter: "Delhi_MundkaIndustArea_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19549,
        Pin: 737133,
        DispatchCenter: "Rongli_Rorathang_DPP (Sikkim)",
        OriginCenter: "Rongli_Rorathang_DPP (Sikkim)",
        FacilityCity: "Rongli",
        FacilityState: "Sikkim",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19550,
        Pin: 741319,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19551,
        Pin: 146001,
        DispatchCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        OriginCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19552,
        Pin: 470051,
        DispatchCenter: "Sagar_Pagara_P (Madhya Pradesh)",
        OriginCenter: "Sagar_Pagara_P (Madhya Pradesh)",
        FacilityCity: "Sagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19553,
        Pin: 741121,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19554,
        Pin: 571608,
        DispatchCenter: "Krishnarajngr_VlmkiNgr_D (Karnataka)",
        OriginCenter: "Krishnarajngr_VlmkiNgr_D (Karnataka)",
        FacilityCity: "Krishnarajngr",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19555,
        Pin: 483880,
        DispatchCenter: "Vijayraghavgarh_KymoreRd_DPP (Madhya Pradesh)",
        OriginCenter: "Vijayraghavgarh_KymoreRd_DPP (Madhya Pradesh)",
        FacilityCity: "Vijayraghavgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19556,
        Pin: 642002,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19557,
        Pin: 410222,
        DispatchCenter: "Mumbai_Ariwali_L (Maharashtra)",
        OriginCenter: "Mumbai_Ariwali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19558,
        Pin: 225123,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19559,
        Pin: 637411,
        DispatchCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        OriginCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        FacilityCity: "Thuraiyur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19560,
        Pin: 686012,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19561,
        Pin: 401402,
        DispatchCenter: "Palghar_Maan_L (Maharashtra)",
        OriginCenter: "Palghar_Maan_L (Maharashtra)",
        FacilityCity: "Palghar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19562,
        Pin: 335803,
        DispatchCenter: "Pilibanga_BsstdDPP_D (Rajasthan)",
        OriginCenter: "Pilibanga_BsstdDPP_D (Rajasthan)",
        FacilityCity: "Pilibanga",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19563,
        Pin: 244225,
        DispatchCenter: "Gajraula_JawaharN_D (Uttar Pradesh)",
        OriginCenter: "Gajraula_JawaharN_D (Uttar Pradesh)",
        FacilityCity: "Gajraula",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19564,
        Pin: 148008,
        DispatchCenter: "Sangrur_DC (Punjab)",
        OriginCenter: "Sangrur_DC (Punjab)",
        FacilityCity: "Sangrur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19565,
        Pin: 711227,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19566,
        Pin: 341517,
        DispatchCenter: "Kuchmancity_PratapNagar_D (Rajasthan)",
        OriginCenter: "Kuchmancity_PratapNagar_D (Rajasthan)",
        FacilityCity: "Kuchaman",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19567,
        Pin: 424309,
        DispatchCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        OriginCenter: "Dhule_MohadiUpnagar_I (Maharashtra)",
        FacilityCity: "Dhule",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19568,
        Pin: 502310,
        DispatchCenter: "Siddipet_Vemula_D (Telangana)",
        OriginCenter: "Siddipet_Vemula_D (Telangana)",
        FacilityCity: "Siddipet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19569,
        Pin: 764071,
        DispatchCenter: "Pappadahandi_Jatabal_DPP (Orissa)",
        OriginCenter: "Pappadahandi_Jatabal_DPP (Orissa)",
        FacilityCity: "Pappadahandi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19570,
        Pin: 221011,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19571,
        Pin: 458895,
        DispatchCenter: "Mandsaur_Sindpan_I (Madhya Pradesh)",
        OriginCenter: "Mandsaur_Sindpan_I (Madhya Pradesh)",
        FacilityCity: "Mandsaur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19572,
        Pin: 440035,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Chikhali_L (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19573,
        Pin: 396472,
        DispatchCenter: "Navsari_Vejalpore_D (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Navsari",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19574,
        Pin: 125047,
        DispatchCenter: "Hisar_Dabra_H (Haryana)",
        OriginCenter: "Hisar_Dabra_H (Haryana)",
        FacilityCity: "Hisar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19575,
        Pin: 673614,
        DispatchCenter: "Balussery_Sivapuram_D (Kerala)",
        OriginCenter: "Balussery_Sivapuram_D (Kerala)",
        FacilityCity: "Balussery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19576,
        Pin: 500035,
        DispatchCenter: "Hyderabad_Autonagar_L (Telangana)",
        OriginCenter: "Hyderabad_Autonagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19577,
        Pin: 847202,
        DispatchCenter: "Benipur_Karhari_D (Bihar)",
        OriginCenter: "Benipur_Karhari_D (Bihar)",
        FacilityCity: "Benipur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19578,
        Pin: 695568,
        DispatchCenter: "Nedumangad_Central_D (Kerala)",
        OriginCenter: "Nedumangad_Central_D (Kerala)",
        FacilityCity: "Nedumangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19579,
        Pin: 689121,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19580,
        Pin: 382025,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19581,
        Pin: 282008,
        DispatchCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        OriginCenter: "Agra_Jaupura_H (Uttar Pradesh)",
        FacilityCity: "Agra",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19582,
        Pin: 141106,
        DispatchCenter: "Raikot_DC (Punjab)",
        OriginCenter: "Raikot_DC (Punjab)",
        FacilityCity: "Raikot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19583,
        Pin: 441609,
        DispatchCenter: "Gondia_Mundipar_I (Maharashtra)",
        OriginCenter: "Gondia_Mundipar_I (Maharashtra)",
        FacilityCity: "Gondia",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19584,
        Pin: 769003,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19585,
        Pin: 421604,
        DispatchCenter: "Bhiwandi_Lonad_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19586,
        Pin: 331503,
        DispatchCenter: "Sujangarh_Khanpur_D (Rajasthan)",
        OriginCenter: "Sujangarh_Khanpur_D (Rajasthan)",
        FacilityCity: "Sujangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19587,
        Pin: 630321,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19588,
        Pin: 827002,
        DispatchCenter: "Bokaro_Chas_D (Jharkhand)",
        OriginCenter: "Bokaro_Chas_D (Jharkhand)",
        FacilityCity: "Bokaro",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19589,
        Pin: 765025,
        DispatchCenter: "Gunupur_Antarjholi_DPP (Orissa)",
        OriginCenter: "Gunupur_Antarjholi_DPP (Orissa)",
        FacilityCity: "Gunupur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19590,
        Pin: 626141,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19591,
        Pin: 314403,
        DispatchCenter: "Simalwara_PithRd_DPP (Rajasthan)",
        OriginCenter: "Simalwara_PithRd_DPP (Rajasthan)",
        FacilityCity: "Simalwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19592,
        Pin: 263134,
        DispatchCenter: "Ranikhet_Bhatrojkhan_DPP (Uttarakhand)",
        OriginCenter: "Ranikhet_Bhatrojkhan_DPP (Uttarakhand)",
        FacilityCity: "Ranikhet",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19593,
        Pin: 522264,
        DispatchCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        OriginCenter: "Repalle_Ward7_D (Andhra Pradesh)",
        FacilityCity: "Repalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19594,
        Pin: 382028,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19595,
        Pin: 209869,
        DispatchCenter: "Miyanganj_Bangarmau_D (Uttar Pradesh)",
        OriginCenter: "Miyanganj_Bangarmau_D (Uttar Pradesh)",
        FacilityCity: "Pihani",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19596,
        Pin: 788026,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19597,
        Pin: 532220,
        DispatchCenter: "Palasa_Mandapam_D (Andhra Pradesh)",
        OriginCenter: "Palasa_Mandapam_D (Andhra Pradesh)",
        FacilityCity: "Palasa",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19598,
        Pin: 456550,
        DispatchCenter: "Agar_ShetlaTemple_D (Madhya Pradesh)",
        OriginCenter: "Agar_ShetlaTemple_D (Madhya Pradesh)",
        FacilityCity: "Agar",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19599,
        Pin: 641602,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19600,
        Pin: 571302,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19601,
        Pin: 841503,
        DispatchCenter: "Kuchaikote_Bhathwaparshuram_D (Bihar)",
        OriginCenter: "Kuchaikote_Bhathwaparshuram_D (Bihar)",
        FacilityCity: "Kuchaikote",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19602,
        Pin: 500031,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19603,
        Pin: 847311,
        DispatchCenter: "Rosera_HanumanTemple_D (Bihar)",
        OriginCenter: "Rosera_HanumanTemple_D (Bihar)",
        FacilityCity: "Rusera",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19604,
        Pin: 629251,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19605,
        Pin: 614723,
        DispatchCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        OriginCenter: "Pattukotai_anthniyr_D (Tamil Nadu)",
        FacilityCity: "Pattukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19606,
        Pin: 822112,
        DispatchCenter: "Nagaruntari_Kharaundhi_DPP (Jharkhand)",
        OriginCenter: "Nagaruntari_Kharaundhi_DPP (Jharkhand)",
        FacilityCity: "Nagar Untari",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19607,
        Pin: 793111,
        DispatchCenter: "Cherrapunji_NhkalikaiRd_DPP (Meghalaya)",
        OriginCenter: "Cherrapunji_NhkalikaiRd_DPP (Meghalaya)",
        FacilityCity: "Cherrapunji",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19608,
        Pin: 456221,
        DispatchCenter: "Nagda_PrkashColny_D (Madhya Pradesh)",
        OriginCenter: "Nagda_PrkashColny_D (Madhya Pradesh)",
        FacilityCity: "Nagda",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19609,
        Pin: 713131,
        DispatchCenter: "Guskhara_DignagarRd_D (West Bengal)",
        OriginCenter: "Guskhara_DignagarRd_D (West Bengal)",
        FacilityCity: "Guskhara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19610,
        Pin: 712124,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19611,
        Pin: 577243,
        DispatchCenter: "Channagiri_Taralabalu_D (Karnataka)",
        OriginCenter: "Channagiri_Taralabalu_D (Karnataka)",
        FacilityCity: "Channagiri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19612,
        Pin: 752023,
        DispatchCenter: "Tangi_SriDPP_D (Orissa)",
        OriginCenter: "Tangi_SriDPP_D (Orissa)",
        FacilityCity: "Tangi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19613,
        Pin: 686563,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19614,
        Pin: 343039,
        DispatchCenter: "Bhinmal_RaniwaraKhurd_D (Rajasthan)",
        OriginCenter: "Bhinmal_RaniwaraKhurd_D (Rajasthan)",
        FacilityCity: "Raniwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19615,
        Pin: 757030,
        DispatchCenter: "Baripada_KalikDPP_D (Orissa)",
        OriginCenter: "Baripada_KalikDPP_D (Orissa)",
        FacilityCity: "Baripada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19616,
        Pin: 695584,
        DispatchCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        OriginCenter: "Trivandrum_Mukkolakal_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19617,
        Pin: 231211,
        DispatchCenter: "Mirzapur_Lalganj_D (Uttar Pradesh)",
        OriginCenter: "Mirzapur_Lalganj_D (Uttar Pradesh)",
        FacilityCity: "Mirzapur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19618,
        Pin: 495683,
        DispatchCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        OriginCenter: "Korba_Laluramnagar_D (Chhattisgarh)",
        FacilityCity: "Korba",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19619,
        Pin: 854105,
        DispatchCenter: "Purnia_Kasba_H (Bihar)",
        OriginCenter: "Purnia_Kasba_H (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19620,
        Pin: 136132,
        DispatchCenter: "Kurukshetra_Ladwa_D (Haryana)",
        OriginCenter: "Kurukshetra_Ladwa_D (Haryana)",
        FacilityCity: "Kurukshetra",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19621,
        Pin: 382255,
        DispatchCenter: "Botad_Paliyadrd_D (Gujarat)",
        OriginCenter: "Botad_Paliyadrd_D (Gujarat)",
        FacilityCity: "Botad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19622,
        Pin: 611001,
        DispatchCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        OriginCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        FacilityCity: "Nagapattinam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19623,
        Pin: 306603,
        DispatchCenter: "Khinwara_Nadol_DPP (Rajasthan)",
        OriginCenter: "Khinwara_Nadol_DPP (Rajasthan)",
        FacilityCity: "Khinwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19624,
        Pin: 388335,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19625,
        Pin: 400019,
        DispatchCenter: "Mumbai_Sewri_L (Maharashtra)",
        OriginCenter: "Mumbai_Sewri_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19626,
        Pin: 752034,
        DispatchCenter: "Balugaon_Oldasianhighwy_D (Orissa)",
        OriginCenter: "Balugaon_Oldasianhighwy_D (Orissa)",
        FacilityCity: "Balugaon",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19627,
        Pin: 458226,
        DispatchCenter: "RatangarhMP_Ward12_D (Madhya Pradesh)",
        OriginCenter: "RatangarhMP_Ward12_D (Madhya Pradesh)",
        FacilityCity: "Ratangarh-MP",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19628,
        Pin: 152130,
        DispatchCenter: "Abohar_DaulatPura_D (Punjab)",
        OriginCenter: "Abohar_DaulatPura_D (Punjab)",
        FacilityCity: "Abohar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19629,
        Pin: 452013,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19630,
        Pin: 735233,
        DispatchCenter: "Birpara_Binnaguri_D (West Bengal)",
        OriginCenter: "Birpara_Binnaguri_D (West Bengal)",
        FacilityCity: "Birpara",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19631,
        Pin: 475335,
        DispatchCenter: "Datia_BhanderSarfai_DPP (Madhya Pradesh)",
        OriginCenter: "Datia_BhanderSarfai_DPP (Madhya Pradesh)",
        FacilityCity: "Datia",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19632,
        Pin: 517423,
        DispatchCenter: "Kuppam_KrishnagiriRd_D (Andhra Pradesh)",
        OriginCenter: "Kuppam_KrishnagiriRd_D (Andhra Pradesh)",
        FacilityCity: "Kuppam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19633,
        Pin: 629164,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19634,
        Pin: 509002,
        DispatchCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        OriginCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        FacilityCity: "Mahabubnagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19635,
        Pin: 713332,
        DispatchCenter: "Asansol_Addacolony_D (West Bengal)",
        OriginCenter: "Asansol_Addacolony_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19636,
        Pin: 700062,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19637,
        Pin: 465447,
        DispatchCenter: "RajgarhMP_Jirapur_D (Madhya Pradesh)",
        OriginCenter: "RajgarhMP_Jirapur_D (Madhya Pradesh)",
        FacilityCity: "Rajgarh-MP",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19638,
        Pin: 521424,
        DispatchCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        OriginCenter: "Gudivada_Satyanarayanapuram_D (Andhra Pradesh)",
        FacilityCity: "Gudivada",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19639,
        Pin: 521105,
        DispatchCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        OriginCenter: "Eluru_AutoNagar_I (Andhra Pradesh)",
        FacilityCity: "Eluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19640,
        Pin: 785603,
        DispatchCenter: "Golaghat_BaruaRd_D (Assam)",
        OriginCenter: "Golaghat_BaruaRd_D (Assam)",
        FacilityCity: "Golaghat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19641,
        Pin: 144030,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19642,
        Pin: 732204,
        DispatchCenter: "Malda_Araidanga_D (West Bengal)",
        OriginCenter: "Malda_Araidanga_D (West Bengal)",
        FacilityCity: "Malda",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19643,
        Pin: 577523,
        DispatchCenter: "Channagiri_Holalkere_D (Karnataka)",
        OriginCenter: "Channagiri_Holalkere_D (Karnataka)",
        FacilityCity: "Channagiri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19644,
        Pin: 691555,
        DispatchCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        OriginCenter: "Adoor_Vadakkadathukavu_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19645,
        Pin: 143066,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19646,
        Pin: 175047,
        DispatchCenter: "Thunag_SBI_D (Himachal Pradesh)",
        OriginCenter: "Thunag_SBI_D (Himachal Pradesh)",
        FacilityCity: "Thunag",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19647,
        Pin: 453661,
        DispatchCenter: "Pithampur_Ambedkarnagar_L (Madhya Pradesh)",
        OriginCenter: "Pithampur_Ambedkarnagar_L (Madhya Pradesh)",
        FacilityCity: "Pithampur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19648,
        Pin: 799011,
        DispatchCenter: "Agartala_UttarChampamura_I (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Agartala",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19649,
        Pin: 689663,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19650,
        Pin: 393155,
        DispatchCenter: "Rajpipla_DSPOffice_DPP (Gujarat)",
        OriginCenter: "Rajpipla_DSPOffice_DPP (Gujarat)",
        FacilityCity: "Rajpipla",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19651,
        Pin: 688522,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19652,
        Pin: 506134,
        DispatchCenter: "Gudur_Chandrugudem_DPP (Telangana)",
        OriginCenter: "Gudur_Chandrugudem_DPP (Telangana)",
        FacilityCity: "Gudur-TS",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19653,
        Pin: 251306,
        DispatchCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        OriginCenter: "Muzaffarnagar_Transportnagar_D (Uttar Pradesh)",
        FacilityCity: "Muzaffarnagar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19654,
        Pin: 127020,
        DispatchCenter: "Bhiwani_Bhagganpuri_D (Haryana)",
        OriginCenter: "Bhiwani_Bhagganpuri_D (Haryana)",
        FacilityCity: "Bhiwani",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19655,
        Pin: 494335,
        DispatchCenter: "Kanker_KrisnKunj_D (Chhattisgarh)",
        OriginCenter: "Kanker_KrisnKunj_D (Chhattisgarh)",
        FacilityCity: "Kanker",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19656,
        Pin: 583277,
        DispatchCenter: "Kushtagi_Karimacolony_DPP (Karnataka)",
        OriginCenter: "Kushtagi_Karimacolony_DPP (Karnataka)",
        FacilityCity: "Kushtagi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19657,
        Pin: 811107,
        DispatchCenter: "Sheikhpura_Lalbagh_D (Bihar)",
        OriginCenter: "Sheikhpura_Lalbagh_D (Bihar)",
        FacilityCity: "Sheikhpura",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19658,
        Pin: 421002,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19659,
        Pin: 524321,
        DispatchCenter: "Nellore_Bccolony_D (Andhra Pradesh)",
        OriginCenter: "Nellore_Bccolony_D (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19660,
        Pin: 148021,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19661,
        Pin: 285211,
        DispatchCenter: "Pukhrayan_Ganeshganj_D (Uttar Pradesh)",
        OriginCenter: "Pukhrayan_Ganeshganj_D (Uttar Pradesh)",
        FacilityCity: "Pukhrayan",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19662,
        Pin: 673317,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19663,
        Pin: 628207,
        DispatchCenter: "Eral_Nazareth_D (Tamil Nadu)",
        OriginCenter: "Eral_Nazareth_D (Tamil Nadu)",
        FacilityCity: "Eral",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19664,
        Pin: 203393,
        DispatchCenter: "Dibai_Galibpur_D (Uttar Pradesh)",
        OriginCenter: "Dibai_Galibpur_D (Uttar Pradesh)",
        FacilityCity: "Dibai",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19665,
        Pin: 396217,
        DispatchCenter: "Daman_DC (Daman & Diu)",
        OriginCenter: "Daman_DC (Daman & Diu)",
        FacilityCity: "Daman",
        FacilityState: "Daman & Diu",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19666,
        Pin: 272207,
        DispatchCenter: "Naugarh_Trtribazar_D (Uttar Pradesh)",
        OriginCenter: "Naugarh_Trtribazar_D (Uttar Pradesh)",
        FacilityCity: "Naugarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19667,
        Pin: 711110,
        DispatchCenter: "Howrah_Kamrangu_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19668,
        Pin: 843301,
        DispatchCenter: "Sitamarhi_Punaura_D (Bihar)",
        OriginCenter: "Sitamarhi_Punaura_D (Bihar)",
        FacilityCity: "Sitamarhi",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19669,
        Pin: 500071,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19670,
        Pin: 788003,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19671,
        Pin: 413224,
        DispatchCenter: "Solapur_Balepeth_I (Maharashtra)",
        OriginCenter: "Solapur_Balepeth_I (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19672,
        Pin: 232105,
        DispatchCenter: "Chandauli_Chahaniya_D (Uttar Pradesh)",
        OriginCenter: "Chandauli_Chahaniya_D (Uttar Pradesh)",
        FacilityCity: "Mughal Sarai",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19673,
        Pin: 508214,
        DispatchCenter: "Suryapet_Krishnanagar_D (Telangana)",
        OriginCenter: "Suryapet_Krishnanagar_D (Telangana)",
        FacilityCity: "Suryapet",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19674,
        Pin: 384435,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19675,
        Pin: 451667,
        DispatchCenter: "Pansemal_Matrala_DPP (Madhya Pradesh)",
        OriginCenter: "Pansemal_Matrala_DPP (Madhya Pradesh)",
        FacilityCity: "Pansemal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19676,
        Pin: 690544,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19677,
        Pin: 500012,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19678,
        Pin: 110065,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19679,
        Pin: 328024,
        DispatchCenter: "Dholpur_KamlaColony_D (Rajasthan)",
        OriginCenter: "Dholpur_KamlaColony_D (Rajasthan)",
        FacilityCity: "Dholpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19680,
        Pin: 829131,
        DispatchCenter: "Ramgarh_Marar_D (Jharkhand)",
        OriginCenter: "Ramgarh_Marar_D (Jharkhand)",
        FacilityCity: "Ramgarh",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19681,
        Pin: 306307,
        DispatchCenter: "Raipur_Mainmarket_D (Rajasthan)",
        OriginCenter: "Raipur_Mainmarket_D (Rajasthan)",
        FacilityCity: "Raipur-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19682,
        Pin: 781122,
        DispatchCenter: "Guwahati_Kaikchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19683,
        Pin: 766032,
        DispatchCenter: "Kesinga_Bogor_D (Orissa)",
        OriginCenter: "Kesinga_Bogor_D (Orissa)",
        FacilityCity: "Kesinga",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19684,
        Pin: 485226,
        DispatchCenter: "Semariya_Shuklacolony_DPP (Madhya Pradesh)",
        OriginCenter: "Semariya_Shuklacolony_DPP (Madhya Pradesh)",
        FacilityCity: "Semariya",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19685,
        Pin: 582206,
        DispatchCenter: "Kerur_WardNo2_D (Karnataka)",
        OriginCenter: "Kerur_WardNo2_D (Karnataka)",
        FacilityCity: "Kerur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19686,
        Pin: 572130,
        DispatchCenter: "Kunigal_Vidyanagar_D (Karnataka)",
        OriginCenter: "Kunigal_Vidyanagar_D (Karnataka)",
        FacilityCity: "Kunigal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19687,
        Pin: 804407,
        DispatchCenter: "Khodaganj_Panhar_D (Bihar)",
        OriginCenter: "Khodaganj_Panhar_D (Bihar)",
        FacilityCity: "Khodaganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19688,
        Pin: 412110,
        DispatchCenter: "Pune_Fursungi_L (Maharashtra)",
        OriginCenter: "Pune_Fursungi_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19689,
        Pin: 811313,
        DispatchCenter: "Lakhisarai_Central_I_2 (Bihar)",
        OriginCenter: "Lakhisarai_Central_I_2 (Bihar)",
        FacilityCity: "Lakhisarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19690,
        Pin: 136027,
        DispatchCenter: "Kaithal_DefenceColony_D (Haryana)",
        OriginCenter: "Kaithal_DefenceColony_D (Haryana)",
        FacilityCity: "Kaithal",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19691,
        Pin: 415717,
        DispatchCenter: "Dapoli_Ghimhavane_D (Maharashtra)",
        OriginCenter: "Dapoli_Ghimhavane_D (Maharashtra)",
        FacilityCity: "Dapoli",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19692,
        Pin: 631501,
        DispatchCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        OriginCenter: "Kanchipuram_ThrvllvrNgr_D (Tamil Nadu)",
        FacilityCity: "Kanchipuram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19693,
        Pin: 700043,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19694,
        Pin: 172102,
        DispatchCenter: "Jeori_AadvikEng_DPP (Himachal Pradesh)",
        OriginCenter: "Jeori_AadvikEng_DPP (Himachal Pradesh)",
        FacilityCity: "Jeori",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19695,
        Pin: 122100,
        DispatchCenter: "Gurgaon_Sihi_L (Haryana)",
        OriginCenter: "Gurgaon_Sihi_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19696,
        Pin: 601301,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19697,
        Pin: 470775,
        DispatchCenter: "Hatta_Gourishankar_D (Madhya Pradesh)",
        OriginCenter: "Hatta_Gourishankar_D (Madhya Pradesh)",
        FacilityCity: "Hatta",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19698,
        Pin: 600102,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19699,
        Pin: 576212,
        DispatchCenter: "Kundapura_Shankarnarayana_D (Karnataka)",
        OriginCenter: "Kundapura_Shankarnarayana_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19700,
        Pin: 678686,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19701,
        Pin: 484665,
        DispatchCenter: "Ranchha_Khutar_DPP (Madhya Pradesh)",
        OriginCenter: "Ranchha_Khutar_DPP (Madhya Pradesh)",
        FacilityCity: "Ranchha",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19702,
        Pin: 412307,
        DispatchCenter: "Pune_Fursungi_L (Maharashtra)",
        OriginCenter: "Pune_Fursungi_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19703,
        Pin: 573144,
        DispatchCenter: "Arsikere_JenukalTemple_D (Karnataka)",
        OriginCenter: "Arsikere_JenukalTemple_D (Karnataka)",
        FacilityCity: "Arsikere",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19704,
        Pin: 613005,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19705,
        Pin: 811211,
        DispatchCenter: "Munger_Kaura_D (Bihar)",
        OriginCenter: "Munger_Kaura_D (Bihar)",
        FacilityCity: "Munger",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19706,
        Pin: 613102,
        DispatchCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        OriginCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        FacilityCity: "Thirukkattupalli",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19707,
        Pin: 642107,
        DispatchCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        OriginCenter: "Pollachi_Srlayout_D (Tamil Nadu)",
        FacilityCity: "Pollachi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19708,
        Pin: 133204,
        DispatchCenter: "Yamunanagar_Balachaur_D (Haryana)",
        OriginCenter: "Yamunanagar_Balachaur_D (Haryana)",
        FacilityCity: "Yamuna Nagar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19709,
        Pin: 123101,
        DispatchCenter: "Rewari_Gopalchowk_D (Haryana)",
        OriginCenter: "Rewari_Gopalchowk_D (Haryana)",
        FacilityCity: "Rewari",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19710,
        Pin: 410504,
        DispatchCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        OriginCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        FacilityCity: "Ambegaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19711,
        Pin: 225409,
        DispatchCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        OriginCenter: "Dariyabad_MathuraNagar_D (Uttar Pradesh)",
        FacilityCity: "Dariyabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19712,
        Pin: 625702,
        DispatchCenter: "Madurai_Ganpatinagar_D (Tamil Nadu)",
        OriginCenter: "Madurai_Ganpatinagar_D (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19713,
        Pin: 382027,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19714,
        Pin: 689613,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19715,
        Pin: 394340,
        DispatchCenter: "Songadh_Vyara_D (Gujarat)",
        OriginCenter: "Songadh_Vyara_D (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19716,
        Pin: 533212,
        DispatchCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        OriginCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        FacilityCity: "Amalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19717,
        Pin: 121010,
        DispatchCenter: "Faridabad_MathuraRoad_L (Haryana)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Faridabad",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19718,
        Pin: 804423,
        DispatchCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        OriginCenter: "Jehanabad_Gaurakshini_D (Bihar)",
        FacilityCity: "Jehanabad",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19719,
        Pin: 804433,
        DispatchCenter: "Makhdumpur_Nawabganj_D (Bihar)",
        OriginCenter: "Makhdumpur_Nawabganj_D (Bihar)",
        FacilityCity: "Makhdumpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19720,
        Pin: 501504,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19721,
        Pin: 110011,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19722,
        Pin: 171009,
        DispatchCenter: "shimla (Himachal Pradesh)",
        OriginCenter: "shimla (Himachal Pradesh)",
        FacilityCity: "Shimla",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19723,
        Pin: 175011,
        DispatchCenter: "Karsog_Busstd_D (Himachal Pradesh)",
        OriginCenter: "Karsog_Busstd_D (Himachal Pradesh)",
        FacilityCity: "Karsog",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19724,
        Pin: 141001,
        DispatchCenter: "Ludhiana_Pawa_L (Punjab)",
        OriginCenter: "Ludhiana_Khakat_H (Punjab)",
        FacilityCity: "Ludhiana",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19725,
        Pin: 673307,
        DispatchCenter: "Koyilandy_Komathukara_D (Kerala)",
        OriginCenter: "Koyilandy_Komathukara_D (Kerala)",
        FacilityCity: "Koyilandy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19726,
        Pin: 416111,
        DispatchCenter: "Ichalkaranji_TmbeMala_D (Maharashtra)",
        OriginCenter: "Ichalkaranji_TmbeMala_D (Maharashtra)",
        FacilityCity: "Ichalkaranji",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19727,
        Pin: 413739,
        DispatchCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        OriginCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        FacilityCity: "Shrirampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19728,
        Pin: 743263,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19729,
        Pin: 573147,
        DispatchCenter: "Ramanathapura_Arakalgud_D (Karnataka)",
        OriginCenter: "Ramanathapura_Arakalgud_D (Karnataka)",
        FacilityCity: "Ramanathapura",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19730,
        Pin: 722208,
        DispatchCenter: "Barjora_PoliceStn_DPP (West Bengal)",
        OriginCenter: "Barjora_PoliceStn_DPP (West Bengal)",
        FacilityCity: "Barjora",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19731,
        Pin: 754209,
        DispatchCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        OriginCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19732,
        Pin: 303302,
        DispatchCenter: "Bassi_Thana_D (Rajasthan)",
        OriginCenter: "Bassi_Thana_D (Rajasthan)",
        FacilityCity: "Bassi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19733,
        Pin: 225411,
        DispatchCenter: "Rudauli_Ghosaiyana_D (Uttar Pradesh)",
        OriginCenter: "Rudauli_Ghosaiyana_D (Uttar Pradesh)",
        FacilityCity: "Rudauli",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19734,
        Pin: 606106,
        DispatchCenter: "Pennadam_Puthar_D (Tamil Nadu)",
        OriginCenter: "Pennadam_Puthar_D (Tamil Nadu)",
        FacilityCity: "Pennadam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19735,
        Pin: 416107,
        DispatchCenter: "Ichalkaranji_TmbeMala_D (Maharashtra)",
        OriginCenter: "Ichalkaranji_TmbeMala_D (Maharashtra)",
        FacilityCity: "Ichalkaranji",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19736,
        Pin: 571116,
        DispatchCenter: "Hunsur_HDKote_D (Karnataka)",
        OriginCenter: "Hunsur_HDKote_D (Karnataka)",
        FacilityCity: "Hunsur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19737,
        Pin: 304801,
        DispatchCenter: "Tonk_Bairwa_D (Rajasthan)",
        OriginCenter: "Tonk_Bairwa_D (Rajasthan)",
        FacilityCity: "Tonk",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19738,
        Pin: 470226,
        DispatchCenter: "Jhunku_Deori_DPP (Madhya Pradesh)",
        OriginCenter: "Jhunku_Deori_DPP (Madhya Pradesh)",
        FacilityCity: "Jhunku",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19739,
        Pin: 431704,
        DispatchCenter: "Nanded_Wajegaon_H (Maharashtra)",
        OriginCenter: "Nanded_Wajegaon_H (Maharashtra)",
        FacilityCity: "Nanded",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19740,
        Pin: 127111,
        DispatchCenter: "Bhiwani_Bhagganpuri_D (Haryana)",
        OriginCenter: "Bhiwani_Bhagganpuri_D (Haryana)",
        FacilityCity: "Bhiwani",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19741,
        Pin: 343001,
        DispatchCenter: "Jalore_RoopNgr_D (Rajasthan)",
        OriginCenter: "Jalore_RoopNgr_D (Rajasthan)",
        FacilityCity: "Jalore",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19742,
        Pin: 828202,
        DispatchCenter: "Chirkunda_Egarkunr_D (Jharkhand)",
        OriginCenter: "Chirkunda_Egarkunr_D (Jharkhand)",
        FacilityCity: "Chirkunda",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19743,
        Pin: 686124,
        DispatchCenter: "Pala_Elappunkal_D (Kerala)",
        OriginCenter: "Pala_Elappunkal_D (Kerala)",
        FacilityCity: "Pala",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19744,
        Pin: 370605,
        DispatchCenter: "Nakhatrana_Dhormnath_DPP (Gujarat)",
        OriginCenter: "Nakhatrana_Dhormnath_DPP (Gujarat)",
        FacilityCity: "Nakhatrana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19745,
        Pin: 781035,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19746,
        Pin: 754140,
        DispatchCenter: "Tirtol_Central_D_2 (Orissa)",
        OriginCenter: "Tirtol_Central_D_2 (Orissa)",
        FacilityCity: "Tirtol",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19747,
        Pin: 500045,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19748,
        Pin: 450110,
        DispatchCenter: "Mundi_Kanud_D (Madhya Pradesh)",
        OriginCenter: "Mundi_Kanud_D (Madhya Pradesh)",
        FacilityCity: "Mundi",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19749,
        Pin: 533217,
        DispatchCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        OriginCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        FacilityCity: "Amalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19750,
        Pin: 639004,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19751,
        Pin: 243304,
        DispatchCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        OriginCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        FacilityCity: "Bareilly",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19752,
        Pin: 795104,
        DispatchCenter: "Tadubi_DohuColony_D (Manipur)",
        OriginCenter: "Tadubi_DohuColony_D (Manipur)",
        FacilityCity: "Tadubi",
        FacilityState: "Manipur",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19753,
        Pin: 571165,
        DispatchCenter: "Chamarajanagar_Galipur_D (Karnataka)",
        OriginCenter: "Chamarajanagar_Galipur_D (Karnataka)",
        FacilityCity: "Chamarajanagar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19754,
        Pin: 517561,
        DispatchCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        OriginCenter: "Tirupati_RAgraharam_H (Andhra Pradesh)",
        FacilityCity: "Tirupati",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19755,
        Pin: 680573,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19756,
        Pin: 370665,
        DispatchCenter: "Nakhatrana_Dhormnath_DPP (Gujarat)",
        OriginCenter: "Nakhatrana_Dhormnath_DPP (Gujarat)",
        FacilityCity: "Nakhatrana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19757,
        Pin: 799141,
        DispatchCenter: "Santirbazar_Charakbai_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Santirbazar",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19758,
        Pin: 691550,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19759,
        Pin: 604202,
        DispatchCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        OriginCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        FacilityCity: "Gingee",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19760,
        Pin: 212401,
        DispatchCenter: "Phulpur_BeerKaji_D (Uttar Pradesh)",
        OriginCenter: "Phulpur_BeerKaji_D (Uttar Pradesh)",
        FacilityCity: "Phulpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19761,
        Pin: 344706,
        DispatchCenter: "Dhorimana_VishnuColony_D (Rajasthan)",
        OriginCenter: "Dhorimana_VishnuColony_D (Rajasthan)",
        FacilityCity: "Dhorimana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19762,
        Pin: 312201,
        DispatchCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        OriginCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        FacilityCity: "Chittaurgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19763,
        Pin: 175006,
        DispatchCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        OriginCenter: "Mandi_Nerchowk_I (Himachal Pradesh)",
        FacilityCity: "Mandi",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19764,
        Pin: 700128,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19765,
        Pin: 632005,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19766,
        Pin: 788819,
        DispatchCenter: "Haflong_Fisherycolony_D (Assam)",
        OriginCenter: "Haflong_Fisherycolony_D (Assam)",
        FacilityCity: "Haflong",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19767,
        Pin: 535102,
        DispatchCenter: "Rajam_Varalaxminagar_D (Andhra Pradesh)",
        OriginCenter: "Rajam_Varalaxminagar_D (Andhra Pradesh)",
        FacilityCity: "Rajam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19768,
        Pin: 241122,
        DispatchCenter: "Hardoi_AvsVikasColny_D (Uttar Pradesh)",
        OriginCenter: "Hardoi_AvsVikasColny_D (Uttar Pradesh)",
        FacilityCity: "Hardoi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19769,
        Pin: 276140,
        DispatchCenter: "Jiyanpur_GorkhpurRd_D (Uttar Pradesh)",
        OriginCenter: "Jiyanpur_GorkhpurRd_D (Uttar Pradesh)",
        FacilityCity: "Jiyanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19770,
        Pin: 415404,
        DispatchCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        OriginCenter: "Islampur_KrushiBzr_D (Maharashtra)",
        FacilityCity: "Islampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19771,
        Pin: 423204,
        DispatchCenter: "Satana_MitraNagar_D (Maharashtra)",
        OriginCenter: "Satana_MitraNagar_D (Maharashtra)",
        FacilityCity: "Satana",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19772,
        Pin: 416109,
        DispatchCenter: "Ichalkaranji_TmbeMala_D (Maharashtra)",
        OriginCenter: "Ichalkaranji_TmbeMala_D (Maharashtra)",
        FacilityCity: "Ichalkaranji",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19773,
        Pin: 271403,
        DispatchCenter: "Nawabganj_Shbapr_D (Uttar Pradesh)",
        OriginCenter: "Nawabganj_Shbapr_D (Uttar Pradesh)",
        FacilityCity: "Nawabganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19774,
        Pin: 334005,
        DispatchCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        OriginCenter: "Bikaner_MurlidharColony_I (Rajasthan)",
        FacilityCity: "Bikaner",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19775,
        Pin: 174507,
        DispatchCenter: "Tahliwal_Central_D (Himachal Pradesh)",
        OriginCenter: "Tahliwal_Central_D (Himachal Pradesh)",
        FacilityCity: "Tahliwal",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19776,
        Pin: 413709,
        DispatchCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        OriginCenter: "Shrirampur_Gondhavni_D (Maharashtra)",
        FacilityCity: "Shrirampur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19777,
        Pin: 144106,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19778,
        Pin: 491004,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19779,
        Pin: 673101,
        DispatchCenter: "Vadakara_Orkkatteri_D (Kerala)",
        OriginCenter: "Vadakara_Orkkatteri_D (Kerala)",
        FacilityCity: "Vadakara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19780,
        Pin: 760003,
        DispatchCenter: "Behrampur_Mandiapalli_I (Orissa)",
        OriginCenter: "Behrampur_Mandiapalli_I (Orissa)",
        FacilityCity: "Behrampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19781,
        Pin: 243003,
        DispatchCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        OriginCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        FacilityCity: "Bareilly",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19782,
        Pin: 384411,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19783,
        Pin: 303507,
        DispatchCenter: "Dausa_Madhavnagar_D (Rajasthan)",
        OriginCenter: "Dausa_Madhavnagar_D (Rajasthan)",
        FacilityCity: "Dausa",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19784,
        Pin: 228142,
        DispatchCenter: "Dostpur_Baharpur_D (Uttar Pradesh)",
        OriginCenter: "Dostpur_Baharpur_D (Uttar Pradesh)",
        FacilityCity: "Dostpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19785,
        Pin: 627727,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19786,
        Pin: 711102,
        DispatchCenter: "Howrah_Kamrangu_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19787,
        Pin: 643219,
        DispatchCenter: "Ooty_Manjoor_D (Tamil Nadu)",
        OriginCenter: "Ooty_Manjoor_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19788,
        Pin: 284205,
        DispatchCenter: "Mauranipur_Roni_D (Uttar Pradesh)",
        OriginCenter: "Mauranipur_Roni_D (Uttar Pradesh)",
        FacilityCity: "Mauranipur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19789,
        Pin: 813102,
        DispatchCenter: "Banka_Barahat_DPP (Bihar)",
        OriginCenter: "Banka_Barahat_DPP (Bihar)",
        FacilityCity: "Banka",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19790,
        Pin: 483770,
        DispatchCenter: "Barhi_Central_DPP_1 (Madhya Pradesh)",
        OriginCenter: "Barhi_Central_DPP_1 (Madhya Pradesh)",
        FacilityCity: "Barhi",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19791,
        Pin: 521136,
        DispatchCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        OriginCenter: "Pamarru_Sivalayam_D (Andhra Pradesh)",
        FacilityCity: "Pamarru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19792,
        Pin: 110030,
        DispatchCenter: "Delhi_BamnoliVillage_L (Delhi)",
        OriginCenter: "Delhi_BamnoliVillage_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19793,
        Pin: 793016,
        DispatchCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        OriginCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19794,
        Pin: 781351,
        DispatchCenter: "Nalbari_Chokbazar_D (Assam)",
        OriginCenter: "Nalbari_Chokbazar_D (Assam)",
        FacilityCity: "Nalbari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19795,
        Pin: 534280,
        DispatchCenter: "Narsapur_Rustumbada_D (Andhra Pradesh)",
        OriginCenter: "Narsapur_Rustumbada_D (Andhra Pradesh)",
        FacilityCity: "Narsapur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19796,
        Pin: 247343,
        DispatchCenter: "Nanauta_MaharanaPratapColony_DPP (Uttar Pradesh)",
        OriginCenter: "Nanauta_MaharanaPratapColony_DPP (Uttar Pradesh)",
        FacilityCity: "Nanauta",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19797,
        Pin: 363330,
        DispatchCenter: "Halvad_SaraCross_DPP (Gujarat)",
        OriginCenter: "Halvad_SaraCross_DPP (Gujarat)",
        FacilityCity: "Halvad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19798,
        Pin: 362258,
        DispatchCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        OriginCenter: "Veraval_Vidhyutnagar_D (Gujarat)",
        FacilityCity: "Veraval",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19799,
        Pin: 842010,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19800,
        Pin: 473585,
        DispatchCenter: "Chanderi_ShreeKunj_DPP (Madhya Pradesh)",
        OriginCenter: "Chanderi_ShreeKunj_DPP (Madhya Pradesh)",
        FacilityCity: "Chanderi",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19801,
        Pin: 232102,
        DispatchCenter: "Chandauli_ShubsNGR_D (Uttar Pradesh)",
        OriginCenter: "Chandauli_ShubsNGR_D (Uttar Pradesh)",
        FacilityCity: "Chandauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19802,
        Pin: 301420,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19803,
        Pin: 508247,
        DispatchCenter: "Nalgonda_Duppalapally_I (Telangana)",
        OriginCenter: "Nalgonda_Duppalapally_I (Telangana)",
        FacilityCity: "Nalgonda",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19804,
        Pin: 721211,
        DispatchCenter: "Ghatal_KrshDPP_D (West Bengal)",
        OriginCenter: "Ghatal_KrshDPP_D (West Bengal)",
        FacilityCity: "Ghatal",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19805,
        Pin: 495671,
        DispatchCenter: "Champa_Sindhicolony_D (Chhattisgarh)",
        OriginCenter: "Champa_Sindhicolony_D (Chhattisgarh)",
        FacilityCity: "Champa",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19806,
        Pin: 277121,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19807,
        Pin: 754132,
        DispatchCenter: "Jagatsinghpur_Bnbrda_D (Orissa)",
        OriginCenter: "Jagatsinghpur_Bnbrda_D (Orissa)",
        FacilityCity: "Jagatsinghpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19808,
        Pin: 396425,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19809,
        Pin: 392180,
        DispatchCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        OriginCenter: "Jambusar_Aabadnagar_D (Gujarat)",
        FacilityCity: "Jambusar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19810,
        Pin: 695106,
        DispatchCenter: "Attingal_Central_D_2 (Kerala)",
        OriginCenter: "Attingal_Central_D_2 (Kerala)",
        FacilityCity: "Attingal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19811,
        Pin: 173204,
        DispatchCenter: "Dharampur_Kumarhatti_DPP (Himachal Pradesh)",
        OriginCenter: "Dharampur_Kumarhatti_DPP (Himachal Pradesh)",
        FacilityCity: "Dharampur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19812,
        Pin: 786172,
        DispatchCenter: "Margherita_CivilHPL_D (Assam)",
        OriginCenter: "Margherita_CivilHPL_D (Assam)",
        FacilityCity: "Margherita",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19813,
        Pin: 757028,
        DispatchCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        OriginCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        FacilityCity: "Betnoti",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19814,
        Pin: 812001,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19815,
        Pin: 635101,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19816,
        Pin: 518102,
        DispatchCenter: "Srisailam_Sundipenta2_D (Andhra Pradesh)",
        OriginCenter: "Srisailam_Sundipenta2_D (Andhra Pradesh)",
        FacilityCity: "Srisailam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19817,
        Pin: 847426,
        DispatchCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        OriginCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        FacilityCity: "Jhanjharpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19818,
        Pin: 143519,
        DispatchCenter: "Gurdaspur_Civillines_D (Punjab)",
        OriginCenter: "Gurdaspur_Civillines_D (Punjab)",
        FacilityCity: "Gurdaspur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19819,
        Pin: 160055,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19820,
        Pin: 201306,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19821,
        Pin: 614022,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19822,
        Pin: 382260,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19823,
        Pin: 342012,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19824,
        Pin: 680687,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19825,
        Pin: 577435,
        DispatchCenter: "Siddapur_Kargal_D (Karnataka)",
        OriginCenter: "Siddapur_Kargal_D (Karnataka)",
        FacilityCity: "Siddapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19826,
        Pin: 756037,
        DispatchCenter: "Contai_Fatepur_DPP (West Bengal)",
        OriginCenter: "Contai_Fatepur_DPP (West Bengal)",
        FacilityCity: "RamnagarWB",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19827,
        Pin: 508218,
        DispatchCenter: "Nereducharla_GaridepalliRoad_D (Telangana)",
        OriginCenter: "Nereducharla_GaridepalliRoad_D (Telangana)",
        FacilityCity: "Nereducharla",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19828,
        Pin: 414203,
        DispatchCenter: "Ashti_Murshadpur_DPP (Maharashtra)",
        OriginCenter: "Ashti_Murshadpur_DPP (Maharashtra)",
        FacilityCity: "Ashti",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19829,
        Pin: 497446,
        DispatchCenter: "Manendragarh_JKDRoad_D (Chhattisgarh)",
        OriginCenter: "Manendragarh_JKDRoad_D (Chhattisgarh)",
        FacilityCity: "Manendragarh",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19830,
        Pin: 843111,
        DispatchCenter: "Muzaffarpur_Motipur_D (Bihar)",
        OriginCenter: "Muzaffarpur_Motipur_D (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19831,
        Pin: 621003,
        DispatchCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        OriginCenter: "Thuraiyur_Mthrapuri_D (Tamil Nadu)",
        FacilityCity: "Thuraiyur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19832,
        Pin: 248008,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19833,
        Pin: 209125,
        DispatchCenter: "Auraiya_Brahmngr_D (Uttar Pradesh)",
        OriginCenter: "Auraiya_Brahmngr_D (Uttar Pradesh)",
        FacilityCity: "Auraiya",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19834,
        Pin: 731215,
        DispatchCenter: "Nanoor_Central_DPP_1 (West Bengal)",
        OriginCenter: "Nanoor_Central_DPP_1 (West Bengal)",
        FacilityCity: "Nanoor",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19835,
        Pin: 415540,
        DispatchCenter: "Mhaswad_Drgmtatpl_D (Maharashtra)",
        OriginCenter: "Mhaswad_Drgmtatpl_D (Maharashtra)",
        FacilityCity: "Mhaswad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19836,
        Pin: 444807,
        DispatchCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        OriginCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        FacilityCity: "Paratwada",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19837,
        Pin: 606808,
        DispatchCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        OriginCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        FacilityCity: "Tiruvannamalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19838,
        Pin: 643104,
        DispatchCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        OriginCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19839,
        Pin: 625401,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19840,
        Pin: 581331,
        DispatchCenter: "Sirsi_Kasturbanagar_D (Karnataka)",
        OriginCenter: "Sirsi_Kasturbanagar_D (Karnataka)",
        FacilityCity: "Sirsi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19841,
        Pin: 410508,
        DispatchCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        OriginCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        FacilityCity: "Ambegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19842,
        Pin: 841439,
        DispatchCenter: "Malmaliya_Sipah_D (Bihar)",
        OriginCenter: "Malmaliya_Sipah_D (Bihar)",
        FacilityCity: "Malmaliya",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19843,
        Pin: 680691,
        DispatchCenter: "Kodungallur_Methala_D (Kerala)",
        OriginCenter: "Kodungallur_Methala_D (Kerala)",
        FacilityCity: "Kodungallur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19844,
        Pin: 503235,
        DispatchCenter: "Bodhan_VamshiSchl_D (Telangana)",
        OriginCenter: "Bodhan_VamshiSchl_D (Telangana)",
        FacilityCity: "Bodhan",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19845,
        Pin: 202525,
        DispatchCenter: "Aonla_KdidmCLY_D (Uttar Pradesh)",
        OriginCenter: "Aonla_KdidmCLY_D (Uttar Pradesh)",
        FacilityCity: "Aonla",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19846,
        Pin: 211104,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19847,
        Pin: 335025,
        DispatchCenter: "Ganganagar_Ricco_D (Rajasthan)",
        OriginCenter: "Ganganagar_Ricco_D (Rajasthan)",
        FacilityCity: "Ganga Nagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19848,
        Pin: 781319,
        DispatchCenter: "Kalgachia_PoliceStn_DPP (Assam)",
        OriginCenter: "Kalgachia_PoliceStn_DPP (Assam)",
        FacilityCity: "Kalgachia",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19849,
        Pin: 811241,
        DispatchCenter: "Munger_Kaura_D (Bihar)",
        OriginCenter: "Munger_Kaura_D (Bihar)",
        FacilityCity: "Munger",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19850,
        Pin: 562114,
        DispatchCenter: "Bangalore_Koralur_L (Karnataka)",
        OriginCenter: "Bangalore_Soukyaroad_GW (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19851,
        Pin: 604208,
        DispatchCenter: "Chetpet_Savarapoondi_D (Tamil Nadu)",
        OriginCenter: "Chetpet_Savarapoondi_D (Tamil Nadu)",
        FacilityCity: "Chetpet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19852,
        Pin: 470223,
        DispatchCenter: "Jhunku_Deori_DPP (Madhya Pradesh)",
        OriginCenter: "Jhunku_Deori_DPP (Madhya Pradesh)",
        FacilityCity: "Jhunku",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 19853,
        Pin: 242306,
        DispatchCenter: "Shahjahanpur_AvasVikas_D (Uttar Pradesh)",
        OriginCenter: "Shahjahanpur_AvasVikas_D (Uttar Pradesh)",
        FacilityCity: "Shahjahanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19854,
        Pin: 384440,
        DispatchCenter: "Kadi_Jadavpura_L (Gujarat)",
        OriginCenter: "Kadi_Jadavpura_L (Gujarat)",
        FacilityCity: "Kadi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19855,
        Pin: 145001,
        DispatchCenter: "Pathankot_Kahanpur_H (Punjab)",
        OriginCenter: "Pathankot_Kahanpur_H (Punjab)",
        FacilityCity: "Pathankot",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19856,
        Pin: 770041,
        DispatchCenter: "Koira_Bhanjapali_D (Orissa)",
        OriginCenter: "Koira_Bhanjapali_D (Orissa)",
        FacilityCity: "Koira",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19857,
        Pin: 688004,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19858,
        Pin: 785012,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19859,
        Pin: 534243,
        DispatchCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        OriginCenter: "Bhimavaram_Gunupudi_D (Andhra Pradesh)",
        FacilityCity: "Bhimavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19860,
        Pin: 505531,
        DispatchCenter: "KarimNagar_Alugunoor_I (Telangana)",
        OriginCenter: "KarimNagar_Alugunoor_I (Telangana)",
        FacilityCity: "Karim Nagar",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19861,
        Pin: 327031,
        DispatchCenter: "Banswara_Bagidora_D (Rajasthan)",
        OriginCenter: "Banswara_Bagidora_D (Rajasthan)",
        FacilityCity: "Banswara",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19862,
        Pin: 224209,
        DispatchCenter: "Kumarganj_Siddhiganeshpur_D (Uttar Pradesh)",
        OriginCenter: "Kumarganj_Siddhiganeshpur_D (Uttar Pradesh)",
        FacilityCity: "Kumarganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19863,
        Pin: 606802,
        DispatchCenter: "Polur_Kanji_D (Tamil Nadu)",
        OriginCenter: "Polur_Kanji_D (Tamil Nadu)",
        FacilityCity: "Polur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19864,
        Pin: 792120,
        DispatchCenter: "Changlang_BSBColny_D (Arunachal Pradesh)",
        OriginCenter: "Changlang_BSBColny_D (Arunachal Pradesh)",
        FacilityCity: "Changlang",
        FacilityState: "Arunachal Pradesh",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19865,
        Pin: 127043,
        DispatchCenter: "Siwani_Ward12_D (Haryana)",
        OriginCenter: "Siwani_Ward12_D (Haryana)",
        FacilityCity: "Siwani",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19866,
        Pin: 676101,
        DispatchCenter: "Tirur_Talakkad_D (Kerala)",
        OriginCenter: "Tirur_Talakkad_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19867,
        Pin: 768050,
        DispatchCenter: "Nuapada_NilshlaNgr_D (Orissa)",
        OriginCenter: "Nuapada_NilshlaNgr_D (Orissa)",
        FacilityCity: "Nuapada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19868,
        Pin: 110049,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19869,
        Pin: 586105,
        DispatchCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        OriginCenter: "Bijapur_Mahalbhagayat_I (Karnataka)",
        FacilityCity: "Bijapur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19870,
        Pin: 416630,
        DispatchCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        OriginCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        FacilityCity: "Malvan",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19871,
        Pin: 523346,
        DispatchCenter: "CumbumAP_BhagyaNagar_D (Andhra Pradesh)",
        OriginCenter: "CumbumAP_BhagyaNagar_D (Andhra Pradesh)",
        FacilityCity: "CumbumAP",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19872,
        Pin: 410200,
        DispatchCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        OriginCenter: "Mumbai_MahapeMIDC_L (Maharashtra)",
        FacilityCity: "Navi mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19873,
        Pin: 821112,
        DispatchCenter: "Kochas_Parsiya_D (Bihar)",
        OriginCenter: "Kochas_Parsiya_D (Bihar)",
        FacilityCity: "Kochas",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19874,
        Pin: 571102,
        DispatchCenter: "Piriyapatna_Ward2_D (Karnataka)",
        OriginCenter: "Piriyapatna_Ward2_D (Karnataka)",
        FacilityCity: "Piriyapatna",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19875,
        Pin: 152124,
        DispatchCenter: "Fazilka_Malkana_D (Punjab)",
        OriginCenter: "Fazilka_Malkana_D (Punjab)",
        FacilityCity: "Fazilka",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19876,
        Pin: 394115,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 19877,
        Pin: 222135,
        DispatchCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        OriginCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        FacilityCity: "Mungra Badshahpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19878,
        Pin: 670002,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19879,
        Pin: 387220,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19880,
        Pin: 124104,
        DispatchCenter: "Jhajjar_Policeline_I (Haryana)",
        OriginCenter: "Jhajjar_Policeline_I (Haryana)",
        FacilityCity: "Jhajjar",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19881,
        Pin: 782475,
        DispatchCenter: "Golaghat_Silonijan_D (Assam)",
        OriginCenter: "Golaghat_Silonijan_D (Assam)",
        FacilityCity: "Golaghat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19882,
        Pin: 143514,
        DispatchCenter: "SriHargobindpur_Talwara_D (Punjab)",
        OriginCenter: "SriHargobindpur_Talwara_D (Punjab)",
        FacilityCity: "Sri Hargobindpur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19883,
        Pin: 635307,
        DispatchCenter: "Uthangarai_Mathurpathi_D (Tamil Nadu)",
        OriginCenter: "Uthangarai_Mathurpathi_D (Tamil Nadu)",
        FacilityCity: "Uthangarai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19884,
        Pin: 610005,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19885,
        Pin: 758041,
        DispatchCenter: "Champua_Sirapur_DPP (Orissa)",
        OriginCenter: "Champua_Sirapur_DPP (Orissa)",
        FacilityCity: "Champua",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19886,
        Pin: 835235,
        DispatchCenter: "Simdega_KhutiTola_D (Jharkhand)",
        OriginCenter: "Simdega_KhutiTola_D (Jharkhand)",
        FacilityCity: "Simdega",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19887,
        Pin: 805132,
        DispatchCenter: "Pakribarwan_Sarauni_DPP (Bihar)",
        OriginCenter: "Pakribarwan_Sarauni_DPP (Bihar)",
        FacilityCity: "Pakribarwan",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19888,
        Pin: 416216,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19889,
        Pin: 641030,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19890,
        Pin: 431708,
        DispatchCenter: "Loha_Sidharthnagar_D (Maharashtra)",
        OriginCenter: "Loha_Sidharthnagar_D (Maharashtra)",
        FacilityCity: "Loha",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19891,
        Pin: 686671,
        DispatchCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        OriginCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        FacilityCity: "Muvattupuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19892,
        Pin: 627011,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19893,
        Pin: 832105,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19894,
        Pin: 380016,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19895,
        Pin: 782106,
        DispatchCenter: "Morigaon_Nabheti_D (Assam)",
        OriginCenter: "Morigaon_Nabheti_D (Assam)",
        FacilityCity: "Morigaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19896,
        Pin: 764077,
        DispatchCenter: "Umerkote_Chandahandi_DPP (Orissa)",
        OriginCenter: "Umerkote_Chandahandi_DPP (Orissa)",
        FacilityCity: "Umerkote",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19897,
        Pin: 302029,
        DispatchCenter: "Jaipur_RingRoad_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19898,
        Pin: 627659,
        DispatchCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        OriginCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        FacilityCity: "Thisayanvilai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19899,
        Pin: 581204,
        DispatchCenter: "Kalaghatgi_Machapur_DPP (Karnataka)",
        OriginCenter: "Kalaghatgi_Machapur_DPP (Karnataka)",
        FacilityCity: "Kalaghatgi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19900,
        Pin: 533256,
        DispatchCenter: "Draksharamam_Anaipeta_D (Andhra Pradesh)",
        OriginCenter: "Draksharamam_Anaipeta_D (Andhra Pradesh)",
        FacilityCity: "Draksharamam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19901,
        Pin: 389170,
        DispatchCenter: "Jhalod_Marutinandancolony_DPP (Gujarat)",
        OriginCenter: "Jhalod_Marutinandancolony_DPP (Gujarat)",
        FacilityCity: "Jhalod",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19902,
        Pin: 502381,
        DispatchCenter: "Jogipet_Andole_D (Telangana)",
        OriginCenter: "Jogipet_Andole_D (Telangana)",
        FacilityCity: "Jogipet",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19903,
        Pin: 802205,
        DispatchCenter: "Piro_Bachri_D (Bihar)",
        OriginCenter: "Piro_Bachri_D (Bihar)",
        FacilityCity: "Piro",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19904,
        Pin: 143001,
        DispatchCenter: "Amritsar_VerkaBypss_I (Punjab)",
        OriginCenter: "Amritsar_VerkaBypss_I (Punjab)",
        FacilityCity: "Amritsar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19905,
        Pin: 756137,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19906,
        Pin: 422620,
        DispatchCenter: "Alephata_BhrtPetrol_D (Maharashtra)",
        OriginCenter: "Alephata_BhrtPetrol_D (Maharashtra)",
        FacilityCity: "Alephata",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19907,
        Pin: 742407,
        DispatchCenter: "Berhampore_Raninagar_I (West Bengal)",
        OriginCenter: "Berhampore_Raninagar_I (West Bengal)",
        FacilityCity: "Baharampur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19908,
        Pin: 534122,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19909,
        Pin: 686721,
        DispatchCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        OriginCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        FacilityCity: "Muvattupuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19910,
        Pin: 679321,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19911,
        Pin: 227809,
        DispatchCenter: "Jagdishpur_Canbank_D (Uttar Pradesh)",
        OriginCenter: "Jagdishpur_Canbank_D (Uttar Pradesh)",
        FacilityCity: "Jagdishpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19912,
        Pin: 743133,
        DispatchCenter: "Naihati_Jagatdal_DPP (West Bengal)",
        OriginCenter: "Naihati_Jagatdal_DPP (West Bengal)",
        FacilityCity: "Naihati",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19913,
        Pin: 325206,
        DispatchCenter: "Baran_Housingboard_D (Rajasthan)",
        OriginCenter: "Baran_Housingboard_D (Rajasthan)",
        FacilityCity: "Baran",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19914,
        Pin: 305801,
        DispatchCenter: "Kishangarh_AirportRd_D (Rajasthan)",
        OriginCenter: "Kishangarh_AirportRd_D (Rajasthan)",
        FacilityCity: "Kishangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19915,
        Pin: 344701,
        DispatchCenter: "Sheo_Goonga_D (Rajasthan)",
        OriginCenter: "Sheo_Goonga_D (Rajasthan)",
        FacilityCity: "Sheo",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19916,
        Pin: 736175,
        DispatchCenter: "Dinhata_BhdiKhda_DPP (West Bengal)",
        OriginCenter: "Dinhata_BhdiKhda_DPP (West Bengal)",
        FacilityCity: "Dinhata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19917,
        Pin: 508280,
        DispatchCenter: "Tungaturthi_CanaraBank_D (Telangana)",
        OriginCenter: "Tungaturthi_CanaraBank_D (Telangana)",
        FacilityCity: "Tungaturthi",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19918,
        Pin: 742409,
        DispatchCenter: "Domkal_StateBank_D (West Bengal)",
        OriginCenter: "Domkal_StateBank_D (West Bengal)",
        FacilityCity: "Domkal",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19919,
        Pin: 173218,
        DispatchCenter: "Kunihar_Diggal_DPP (Himachal Pradesh)",
        OriginCenter: "Kunihar_Diggal_DPP (Himachal Pradesh)",
        FacilityCity: "Kunihar",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19920,
        Pin: 686672,
        DispatchCenter: "Koothattukulam_Oliyappuram_D (Kerala)",
        OriginCenter: "Koothattukulam_Oliyappuram_D (Kerala)",
        FacilityCity: "Koothattukulam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19921,
        Pin: 301708,
        DispatchCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        OriginCenter: "Neemrana_Kalipahari_D (Rajasthan)",
        FacilityCity: "Neemrana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19922,
        Pin: 625120,
        DispatchCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        OriginCenter: "Melur_MduraiRD_D (Tamil Nadu)",
        FacilityCity: "Melur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19923,
        Pin: 822126,
        DispatchCenter: "Satbarwa_SBIRd_DPP (Jharkhand)",
        OriginCenter: "Satbarwa_SBIRd_DPP (Jharkhand)",
        FacilityCity: "Satbarwa",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19924,
        Pin: 431712,
        DispatchCenter: "Umarkhed_HubeCplx_D (Maharashtra)",
        OriginCenter: "Umarkhed_HubeCplx_D (Maharashtra)",
        FacilityCity: "Umarkhed",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19925,
        Pin: 571802,
        DispatchCenter: "Nagamangala_TBExtension_D (Karnataka)",
        OriginCenter: "Nagamangala_TBExtension_D (Karnataka)",
        FacilityCity: "Nagamangala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19926,
        Pin: 177213,
        DispatchCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        OriginCenter: "Amb_Pratapnagar_D (Himachal Pradesh)",
        FacilityCity: "Amb",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19927,
        Pin: 604403,
        DispatchCenter: "Vandavasi_SaltStreet_D (Tamil Nadu)",
        OriginCenter: "Vandavasi_SaltStreet_D (Tamil Nadu)",
        FacilityCity: "Vandavasi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19928,
        Pin: 678543,
        DispatchCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        OriginCenter: "Anjumoorthy_Vdaknchery_D (Kerala)",
        FacilityCity: "Anjumoorthy",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19929,
        Pin: 273212,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19930,
        Pin: 123507,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19931,
        Pin: 560016,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19932,
        Pin: 686562,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19933,
        Pin: 581306,
        DispatchCenter: "Karwar_Sadashivgad_D (Karnataka)",
        OriginCenter: "Karwar_Sadashivgad_D (Karnataka)",
        FacilityCity: "Karwar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19934,
        Pin: 574239,
        DispatchCenter: "Sullia_Kanthamangala_D (Karnataka)",
        OriginCenter: "Sullia_Kanthamangala_D (Karnataka)",
        FacilityCity: "Sullia",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19935,
        Pin: 110101,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19936,
        Pin: 560091,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19937,
        Pin: 391330,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19938,
        Pin: 811201,
        DispatchCenter: "Munger_Kaura_D (Bihar)",
        OriginCenter: "Munger_Kaura_D (Bihar)",
        FacilityCity: "Munger",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19939,
        Pin: 465333,
        DispatchCenter: "Shujalpur_JNSCollege_D (Madhya Pradesh)",
        OriginCenter: "Shujalpur_JNSCollege_D (Madhya Pradesh)",
        FacilityCity: "Shujalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19940,
        Pin: 441107,
        DispatchCenter: "Savner_Raymondcolony_D (Maharashtra)",
        OriginCenter: "Savner_Raymondcolony_D (Maharashtra)",
        FacilityCity: "Savner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19941,
        Pin: 804421,
        DispatchCenter: "Tekari_BaheliyaBigha_D (Bihar)",
        OriginCenter: "Tekari_BaheliyaBigha_D (Bihar)",
        FacilityCity: "Tekari",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19942,
        Pin: 686546,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19943,
        Pin: 591231,
        DispatchCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        OriginCenter: "Gokak_LaxmiExtension_D (Karnataka)",
        FacilityCity: "Gokak",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19944,
        Pin: 206124,
        DispatchCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        OriginCenter: "Etawah_HNnagar_D (Uttar Pradesh)",
        FacilityCity: "Etawah",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19945,
        Pin: 848118,
        DispatchCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        OriginCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        FacilityCity: "Dalsingh Sarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19946,
        Pin: 784171,
        DispatchCenter: "Behali_PostOfc_D (Assam)",
        OriginCenter: "Behali_PostOfc_D (Assam)",
        FacilityCity: "Behali",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19947,
        Pin: 522003,
        DispatchCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        OriginCenter: "Guntur_Kornepadu_I (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19948,
        Pin: 144512,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19949,
        Pin: 641608,
        DispatchCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        OriginCenter: "Tirupur_RajivNagar_P (Tamil Nadu)",
        FacilityCity: "Tirupur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19950,
        Pin: 332701,
        DispatchCenter: "Ajeetgarh_Jugrajpura_D (Rajasthan)",
        OriginCenter: "Ajeetgarh_Jugrajpura_D (Rajasthan)",
        FacilityCity: "Ajeetgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19951,
        Pin: 574253,
        DispatchCenter: "Vitla_FawzBuldng_DPP (Karnataka)",
        OriginCenter: "Vitla_FawzBuldng_DPP (Karnataka)",
        FacilityCity: "Vitla",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19952,
        Pin: 635130,
        DispatchCenter: "Hosur_SipcotPhase1_L (Tamil Nadu)",
        OriginCenter: "Hosur_SipcotPhase1_L (Tamil Nadu)",
        FacilityCity: "Hosur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19953,
        Pin: 144311,
        DispatchCenter: "Phagwara_PurewalNgr_D (Punjab)",
        OriginCenter: "Phagwara_PurewalNgr_D (Punjab)",
        FacilityCity: "Phagwara",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19954,
        Pin: 642204,
        DispatchCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        OriginCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        FacilityCity: "Udumalaipettai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19955,
        Pin: 516396,
        DispatchCenter: "Pulivendula_Bakarapuram_D (Andhra Pradesh)",
        OriginCenter: "Pulivendula_Bakarapuram_D (Andhra Pradesh)",
        FacilityCity: "Pulivendula",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19956,
        Pin: 496107,
        DispatchCenter: "Raigarh_MadhubanPara_D (Chhattisgarh)",
        OriginCenter: "Raigarh_MadhubanPara_D (Chhattisgarh)",
        FacilityCity: "Raigarh",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19957,
        Pin: 146101,
        DispatchCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        OriginCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19958,
        Pin: 207301,
        DispatchCenter: "Jalesar_MusiyarRoad_D (Uttar Pradesh)",
        OriginCenter: "Jalesar_MusiyarRoad_D (Uttar Pradesh)",
        FacilityCity: "Jalesar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19959,
        Pin: 412410,
        DispatchCenter: "Alephata_BhrtPetrol_D (Maharashtra)",
        OriginCenter: "Alephata_BhrtPetrol_D (Maharashtra)",
        FacilityCity: "Alephata",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 19960,
        Pin: 394270,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19961,
        Pin: 691511,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19962,
        Pin: 630313,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19963,
        Pin: 535522,
        DispatchCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        OriginCenter: "Parvathipuram_RyghdRd_D (Andhra Pradesh)",
        FacilityCity: "Parvathipuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19964,
        Pin: 679587,
        DispatchCenter: "Chiramanangad_Veliankode_D (Kerala)",
        OriginCenter: "Chiramanangad_Veliankode_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19965,
        Pin: 733142,
        DispatchCenter: "Gangarampr_Ramkrishnpaly_DPP (West Bengal)",
        OriginCenter: "Gangarampr_Ramkrishnpaly_DPP (West Bengal)",
        FacilityCity: "Gangarampur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19966,
        Pin: 689664,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 19967,
        Pin: 591221,
        DispatchCenter: "Belgaum_Hukkeri_DPP (Karnataka)",
        OriginCenter: "Belgaum_Hukkeri_DPP (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19968,
        Pin: 248125,
        DispatchCenter: "Herbertpur_Dhkrni_D (Uttarakhand)",
        OriginCenter: "Herbertpur_Dhkrni_D (Uttarakhand)",
        FacilityCity: "Herbertpur",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19969,
        Pin: 321403,
        DispatchCenter: "Rupbas_GangaColony_DPP (Rajasthan)",
        OriginCenter: "Rupbas_GangaColony_DPP (Rajasthan)",
        FacilityCity: "Rupbas",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19970,
        Pin: 757033,
        DispatchCenter: "Rairangpur_Sanchampauda_D (Orissa)",
        OriginCenter: "Rairangpur_Sanchampauda_D (Orissa)",
        FacilityCity: "Rairangpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19971,
        Pin: 637409,
        DispatchCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        OriginCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        FacilityCity: "Namakkal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19972,
        Pin: 380010,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19973,
        Pin: 321407,
        DispatchCenter: "Kherli_Doroli_D (Rajasthan)",
        OriginCenter: "Kherli_Doroli_D (Rajasthan)",
        FacilityCity: "Kherli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 19974,
        Pin: 758019,
        DispatchCenter: "Telkoi_GaneshMarket_DPP (Orissa)",
        OriginCenter: "Telkoi_GaneshMarket_DPP (Orissa)",
        FacilityCity: "Telkoi",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19975,
        Pin: 480553,
        DispatchCenter: "Seoni_Dalsagar_D (Madhya Pradesh)",
        OriginCenter: "Seoni_Dalsagar_D (Madhya Pradesh)",
        FacilityCity: "Seoni",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 19976,
        Pin: 177103,
        DispatchCenter: "DehraGopipur_Central_D_1 (Himachal Pradesh)",
        OriginCenter: "DehraGopipur_Central_D_1 (Himachal Pradesh)",
        FacilityCity: "Dehra Gopipur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 19977,
        Pin: 788728,
        DispatchCenter: "Patharkandi_CntrlSchl_D (Assam)",
        OriginCenter: "Patharkandi_CntrlSchl_D (Assam)",
        FacilityCity: "Patharkandi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19978,
        Pin: 751026,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19979,
        Pin: 175004,
        DispatchCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        OriginCenter: "Jahu_Naltu_D (Himachal Pradesh)",
        FacilityCity: "Jahu",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19980,
        Pin: 769001,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19981,
        Pin: 144526,
        DispatchCenter: "Garhshankar_BangaRoad_D (Punjab)",
        OriginCenter: "Garhshankar_BangaRoad_D (Punjab)",
        FacilityCity: "Garhshanker",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19982,
        Pin: 786147,
        DispatchCenter: "Tinsukia_Nokhroygaon2_D (Assam)",
        OriginCenter: "Tinsukia_Nokhroygaon2_D (Assam)",
        FacilityCity: "Tinsukia",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 19983,
        Pin: 606902,
        DispatchCenter: "Chetpet_Savarapoondi_D (Tamil Nadu)",
        OriginCenter: "Chetpet_Savarapoondi_D (Tamil Nadu)",
        FacilityCity: "Chetpet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19984,
        Pin: 690536,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19985,
        Pin: 848115,
        DispatchCenter: "Dholi_WardNo4_D (Bihar)",
        OriginCenter: "Dholi_WardNo4_D (Bihar)",
        FacilityCity: "Dholi",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 19986,
        Pin: 382325,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19987,
        Pin: 424119,
        DispatchCenter: "Chalisgaon_HarjaiRd_DPP (Maharashtra)",
        OriginCenter: "Chalisgaon_HarjaiRd_DPP (Maharashtra)",
        FacilityCity: "Chalisgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19988,
        Pin: 212661,
        DispatchCenter: "Fatehpur_Gandhinagar_D (Uttar Pradesh)",
        OriginCenter: "Fatehpur_Gandhinagar_D (Uttar Pradesh)",
        FacilityCity: "Fatehpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19989,
        Pin: 391443,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 19990,
        Pin: 627811,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19991,
        Pin: 756133,
        DispatchCenter: "Chandbali_Binataro_D (Orissa)",
        OriginCenter: "Chandbali_Binataro_D (Orissa)",
        FacilityCity: "Chandbali",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 19992,
        Pin: 496225,
        DispatchCenter: "Kunkuri_Dugdugiya_D (Chhattisgarh)",
        OriginCenter: "Kunkuri_Dugdugiya_D (Chhattisgarh)",
        FacilityCity: "Kunkuri",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 19993,
        Pin: 584201,
        DispatchCenter: "Raichur_Wadloor_I (Karnataka)",
        OriginCenter: "Raichur_Wadloor_I (Karnataka)",
        FacilityCity: "Raichur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 19994,
        Pin: 575005,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 19995,
        Pin: 679531,
        DispatchCenter: "Shoranur_Akamala_D (Kerala)",
        OriginCenter: "Shoranur_Akamala_D (Kerala)",
        FacilityCity: "Shoranur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 19996,
        Pin: 176065,
        DispatchCenter: "Palampur_Saulbanehr_DPP (Himachal Pradesh)",
        OriginCenter: "Palampur_Saulbanehr_DPP (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 19997,
        Pin: 415214,
        DispatchCenter: "Mandangad_Bhingaloli_D (Maharashtra)",
        OriginCenter: "Mandangad_Bhingaloli_D (Maharashtra)",
        FacilityCity: "Mandangad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 19998,
        Pin: 243634,
        DispatchCenter: "Budaun_KrishnaPuriRd_D (Uttar Pradesh)",
        OriginCenter: "Budaun_KrishnaPuriRd_D (Uttar Pradesh)",
        FacilityCity: "Budaun",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 19999,
        Pin: 276302,
        DispatchCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20000,
        Pin: 303122,
        DispatchCenter: "Jaipur_RingRoad_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20001,
        Pin: 581175,
        DispatchCenter: "Dharwad_Kamalpur_D (Karnataka)",
        OriginCenter: "Dharwad_Kamalpur_D (Karnataka)",
        FacilityCity: "Dharwad",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20002,
        Pin: 505302,
        DispatchCenter: "Sircilla_Gopalnagar_D (Telangana)",
        OriginCenter: "Sircilla_Gopalnagar_D (Telangana)",
        FacilityCity: "Sircilla",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20003,
        Pin: 246725,
        DispatchCenter: "Chandpur_NoorpurRd_D (Uttar Pradesh)",
        OriginCenter: "Chandpur_NoorpurRd_D (Uttar Pradesh)",
        FacilityCity: "Chandpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20004,
        Pin: 301035,
        DispatchCenter: "Laxmangarh_Lilee_D (Rajasthan)",
        OriginCenter: "Laxmangarh_Lilee_D (Rajasthan)",
        FacilityCity: "Laxmangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20005,
        Pin: 534426,
        DispatchCenter: "DwarakaTirumala_PoliceStn_D (Andhra Pradesh)",
        OriginCenter: "DwarakaTirumala_PoliceStn_D (Andhra Pradesh)",
        FacilityCity: "Dwaraka Tirumala",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20006,
        Pin: 230122,
        DispatchCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        OriginCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        FacilityCity: "Pratapgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20007,
        Pin: 485002,
        DispatchCenter: "Satna_Nazirabad_I (Madhya Pradesh)",
        OriginCenter: "Satna_Nazirabad_I (Madhya Pradesh)",
        FacilityCity: "Satna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20008,
        Pin: 758078,
        DispatchCenter: "Bhadrak_Purusandha_D (Orissa)",
        OriginCenter: "Bhadrak_Purusandha_D (Orissa)",
        FacilityCity: "Agarpada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20009,
        Pin: 627130,
        DispatchCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        OriginCenter: "Thisayanvilai_NavaladyR_D (Tamil Nadu)",
        FacilityCity: "Thisayanvilai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20010,
        Pin: 333020,
        DispatchCenter: "Buhana_Rajota_D (Rajasthan)",
        OriginCenter: "Buhana_Rajota_D (Rajasthan)",
        FacilityCity: "Buhana",
        FacilityState: "Rajasthan",
        Zone : "N1"
      },
      {
        SN: 20011,
        Pin: 635002,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20012,
        Pin: 670694,
        DispatchCenter: "Thalassery_Panoor_D (Kerala)",
        OriginCenter: "Thalassery_Panoor_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20013,
        Pin: 241124,
        DispatchCenter: "Shahabad_Jogipur_D (Uttar Pradesh)",
        OriginCenter: "Shahabad_Jogipur_D (Uttar Pradesh)",
        FacilityCity: "Shahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20014,
        Pin: 770012,
        DispatchCenter: "Sundargarh_Duduka_D (Orissa)",
        OriginCenter: "Sundargarh_Duduka_D (Orissa)",
        FacilityCity: "Sundargarh",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20015,
        Pin: 423111,
        DispatchCenter: "Nashik_Ojhar_DPP (Maharashtra)",
        OriginCenter: "Nashik_Ojhar_DPP (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20016,
        Pin: 518176,
        DispatchCenter: "Betamcherla_ChinnaSch_DPP (Andhra Pradesh)",
        OriginCenter: "Betamcherla_ChinnaSch_DPP (Andhra Pradesh)",
        FacilityCity: "Betamcherla",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20017,
        Pin: 431138,
        DispatchCenter: "Paithan_SantaNGR_D (Maharashtra)",
        OriginCenter: "Paithan_SantaNGR_D (Maharashtra)",
        FacilityCity: "Paithan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20018,
        Pin: 678707,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20019,
        Pin: 421312,
        DispatchCenter: "Kudus_Shele_D (Maharashtra)",
        OriginCenter: "Kudus_Shele_D (Maharashtra)",
        FacilityCity: "Kudus",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20020,
        Pin: 741162,
        DispatchCenter: "Nakashipara_Kanthalbaria_DPP (West Bengal)",
        OriginCenter: "Nakashipara_Kanthalbaria_DPP (West Bengal)",
        FacilityCity: "Nakashipara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20021,
        Pin: 203201,
        DispatchCenter: "Sikandrabad_Khatriwara_D (Uttar Pradesh)",
        OriginCenter: "Sikandrabad_Khatriwara_D (Uttar Pradesh)",
        FacilityCity: "Sikandrabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20022,
        Pin: 202521,
        DispatchCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        OriginCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        FacilityCity: "Chandausi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20023,
        Pin: 626202,
        DispatchCenter: "Sattur_Thendralnagar_DPP (Tamil Nadu)",
        OriginCenter: "Sattur_Thendralnagar_DPP (Tamil Nadu)",
        FacilityCity: "Sattur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20024,
        Pin: 384240,
        DispatchCenter: "Harij_Denabank_DPP (Gujarat)",
        OriginCenter: "Harij_Denabank_DPP (Gujarat)",
        FacilityCity: "Harij",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20025,
        Pin: 583234,
        DispatchCenter: "Hospet_Vijayanagara_D (Karnataka)",
        OriginCenter: "Hospet_Vijayanagara_D (Karnataka)",
        FacilityCity: "Hospet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20026,
        Pin: 131027,
        DispatchCenter: "Sonipat_Sector51_L (Haryana)",
        OriginCenter: "Sonipat_WazidpurSaboli_P (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20027,
        Pin: 402404,
        DispatchCenter: "Shriwardhan_Kherdi_D (Maharashtra)",
        OriginCenter: "Shriwardhan_Kherdi_D (Maharashtra)",
        FacilityCity: "Shriwardhan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20028,
        Pin: 460004,
        DispatchCenter: "Betul_Bheemnagar_D (Madhya Pradesh)",
        OriginCenter: "Betul_Bheemnagar_D (Madhya Pradesh)",
        FacilityCity: "Betul",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20029,
        Pin: 243502,
        DispatchCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        OriginCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        FacilityCity: "Bareilly",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20030,
        Pin: 622304,
        DispatchCenter: "Peravurani_Nattanikkottai_D (Tamil Nadu)",
        OriginCenter: "Peravurani_Nattanikkottai_D (Tamil Nadu)",
        FacilityCity: "Peravurani",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20031,
        Pin: 802128,
        DispatchCenter: "Rajpur_Devdhiya_D (Bihar)",
        OriginCenter: "Rajpur_Devdhiya_D (Bihar)",
        FacilityCity: "Rajpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20032,
        Pin: 125038,
        DispatchCenter: "Jind_SunderNagar_D (Haryana)",
        OriginCenter: "Jind_SunderNagar_D (Haryana)",
        FacilityCity: "Jind",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20033,
        Pin: 457335,
        DispatchCenter: "Jaora_AdityaNagar_D (Madhya Pradesh)",
        OriginCenter: "Jaora_AdityaNagar_D (Madhya Pradesh)",
        FacilityCity: "Jaora",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20034,
        Pin: 683513,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20035,
        Pin: 721438,
        DispatchCenter: "Contai_Egra_D (West Bengal)",
        OriginCenter: "Contai_Egra_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20036,
        Pin: 423501,
        DispatchCenter: "Satana_MitraNagar_D (Maharashtra)",
        OriginCenter: "Satana_MitraNagar_D (Maharashtra)",
        FacilityCity: "Satana",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20037,
        Pin: 144410,
        DispatchCenter: "Phillaur_Parkashnagar_D (Punjab)",
        OriginCenter: "Phillaur_Parkashnagar_D (Punjab)",
        FacilityCity: "Phillaur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20038,
        Pin: 416612,
        DispatchCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        OriginCenter: "Devgad_Satpayri_DPP (Maharashtra)",
        FacilityCity: "Devgad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20039,
        Pin: 843129,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20040,
        Pin: 383210,
        DispatchCenter: "Himmatnagar_Hadiyol_D (Gujarat)",
        OriginCenter: "Himmatnagar_Hadiyol_D (Gujarat)",
        FacilityCity: "Himmatnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20041,
        Pin: 854325,
        DispatchCenter: "Purnia_Jalalgarh_D (Bihar)",
        OriginCenter: "Purnia_Jalalgarh_D (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20042,
        Pin: 783376,
        DispatchCenter: "Kokrajhar_Borobhatarmari_DPP (Assam)",
        OriginCenter: "Kokrajhar_Borobhatarmari_DPP (Assam)",
        FacilityCity: "Kokrajhar",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20043,
        Pin: 607006,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20044,
        Pin: 743135,
        DispatchCenter: "Naihati_Rajendrapur_DPP (West Bengal)",
        OriginCenter: "Naihati_Rajendrapur_DPP (West Bengal)",
        FacilityCity: "Naihati",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20045,
        Pin: 190009,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20046,
        Pin: 480108,
        DispatchCenter: "Sausar_BorgaonAKVN_DPP (Madhya Pradesh)",
        OriginCenter: "Sausar_BorgaonAKVN_DPP (Madhya Pradesh)",
        FacilityCity: "Sausar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20047,
        Pin: 560107,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20048,
        Pin: 475220,
        DispatchCenter: "Dabra_Bhitarwar_D (Madhya Pradesh)",
        OriginCenter: "Dabra_Bhitarwar_D (Madhya Pradesh)",
        FacilityCity: "Dabra",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20049,
        Pin: 767016,
        DispatchCenter: "Sonepur_BrezeDPP_D (Orissa)",
        OriginCenter: "Sonepur_BrezeDPP_D (Orissa)",
        FacilityCity: "Sonepur-OR",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20050,
        Pin: 518380,
        DispatchCenter: "Alur_Pattikonda_D (Andhra Pradesh)",
        OriginCenter: "Alur_Pattikonda_D (Andhra Pradesh)",
        FacilityCity: "Alur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20051,
        Pin: 700068,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20052,
        Pin: 507168,
        DispatchCenter: "Wyra_Thallada_D (Telangana)",
        OriginCenter: "Wyra_Thallada_D (Telangana)",
        FacilityCity: "Wyra",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20053,
        Pin: 500089,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20054,
        Pin: 181104,
        DispatchCenter: "Jammu_GangyalCRPF1_L (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20055,
        Pin: 621211,
        DispatchCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        OriginCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        FacilityCity: "Kulithalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20056,
        Pin: 623519,
        DispatchCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        OriginCenter: "Rameswaram_Thangachimadam_D (Tamil Nadu)",
        FacilityCity: "Rameswaram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20057,
        Pin: 574202,
        DispatchCenter: "Puttur_Darbe_D (Karnataka)",
        OriginCenter: "Puttur_Darbe_D (Karnataka)",
        FacilityCity: "Puttur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20058,
        Pin: 144510,
        DispatchCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        OriginCenter: "Nawanshahr_FrndsClny_D (Punjab)",
        FacilityCity: "Nawanshahr",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20059,
        Pin: 577414,
        DispatchCenter: "Thirthahalli_Bejjavali2_DPP (Karnataka)",
        OriginCenter: "Thirthahalli_Bejjavali2_DPP (Karnataka)",
        FacilityCity: "Thirthahalli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20060,
        Pin: 515212,
        DispatchCenter: "Hindupur_Sadlapalli_D (Andhra Pradesh)",
        OriginCenter: "Hindupur_Sadlapalli_D (Andhra Pradesh)",
        FacilityCity: "Hindupur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20061,
        Pin: 625201,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20062,
        Pin: 209402,
        DispatchCenter: "Ambujnagar_Narwal_D (Uttar Pradesh)",
        OriginCenter: "Ambujnagar_Narwal_D (Uttar Pradesh)",
        FacilityCity: "Ambuj Nagar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20063,
        Pin: 847109,
        DispatchCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        OriginCenter: "Jhanjharpur_LangraChowk_D (Bihar)",
        FacilityCity: "Jhanjharpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20064,
        Pin: 425103,
        DispatchCenter: "Jalgaon_MIDC_I (Maharashtra)",
        OriginCenter: "Jalgaon_MIDC_I (Maharashtra)",
        FacilityCity: "Jalgaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20065,
        Pin: 624218,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20066,
        Pin: 825106,
        DispatchCenter: "Topchanchi_GomhRd_DPP (Jharkhand)",
        OriginCenter: "Topchanchi_GomhRd_DPP (Jharkhand)",
        FacilityCity: "Gomoh",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20067,
        Pin: 414005,
        DispatchCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        OriginCenter: "Ahmednagar_Nagapur_H (Maharashtra)",
        FacilityCity: "Ahmednagar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20068,
        Pin: 711111,
        DispatchCenter: "Howrah_Kamrangu_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20069,
        Pin: 676515,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20070,
        Pin: 757101,
        DispatchCenter: "RajBerhampur_Gohira_DPP (Orissa)",
        OriginCenter: "RajBerhampur_Gohira_DPP (Orissa)",
        FacilityCity: "Raj Berhampur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20071,
        Pin: 485881,
        DispatchCenter: "Amarpatan_Rigra_D (Madhya Pradesh)",
        OriginCenter: "Amarpatan_Rigra_D (Madhya Pradesh)",
        FacilityCity: "Amarpatan",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20072,
        Pin: 574161,
        DispatchCenter: "Ullal_Kallapu_D (Karnataka)",
        OriginCenter: "Ullal_Kallapu_D (Karnataka)",
        FacilityCity: "Ullal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20073,
        Pin: 713213,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20074,
        Pin: 796111,
        DispatchCenter: "Darlawn_Lungverhkawn_D (Mizoram)",
        OriginCenter: "Darlawn_Lungverhkawn_D (Mizoram)",
        FacilityCity: "Darlawn",
        FacilityState: "Mizoram",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20075,
        Pin: 442502,
        DispatchCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        OriginCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        FacilityCity: "Chandrapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20076,
        Pin: 576115,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20077,
        Pin: 301407,
        DispatchCenter: "Khairthal_HarsouliRoad_D (Rajasthan)",
        OriginCenter: "Khairthal_HarsouliRoad_D (Rajasthan)",
        FacilityCity: "Khairthal",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20078,
        Pin: 281203,
        DispatchCenter: "Chhata_Krishnacolony_D (Uttar Pradesh)",
        OriginCenter: "Chhata_Krishnacolony_D (Uttar Pradesh)",
        FacilityCity: "Chhata",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20079,
        Pin: 721171,
        DispatchCenter: "Panskura_Central_D_3 (West Bengal)",
        OriginCenter: "Panskura_Central_D_3 (West Bengal)",
        FacilityCity: "Panskura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20080,
        Pin: 751017,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20081,
        Pin: 580029,
        DispatchCenter: "Hubli_Budarshingi_H (Karnataka)",
        OriginCenter: "Hubli_Budarshingi_H (Karnataka)",
        FacilityCity: "Hubli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20082,
        Pin: 576121,
        DispatchCenter: "Kanjarakatte_Udupi_DPP (Karnataka)",
        OriginCenter: "Kanjarakatte_Udupi_DPP (Karnataka)",
        FacilityCity: "Kanjarakatte",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20083,
        Pin: 841244,
        DispatchCenter: "Maharajganj_Sihauta_D (Bihar)",
        OriginCenter: "Maharajganj_Sihauta_D (Bihar)",
        FacilityCity: "Maharajganj-BH",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20084,
        Pin: 688545,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20085,
        Pin: 227131,
        DispatchCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        OriginCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        FacilityCity: "Haidargarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20086,
        Pin: 243722,
        DispatchCenter: "Babrala_Teacherscolony_D (Uttar Pradesh)",
        OriginCenter: "Babrala_Teacherscolony_D (Uttar Pradesh)",
        FacilityCity: "Babrala",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20087,
        Pin: 755049,
        DispatchCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        OriginCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        FacilityCity: "Chandikhol",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20088,
        Pin: 122001,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20089,
        Pin: 331028,
        DispatchCenter: "Malsisar_BsStdDPP_D (Rajasthan)",
        OriginCenter: "Malsisar_BsStdDPP_D (Rajasthan)",
        FacilityCity: "Malsisar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20090,
        Pin: 522402,
        DispatchCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasaraopet",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20091,
        Pin: 494111,
        DispatchCenter: "Sukma_SamratNagar_D (Chhattisgarh)",
        OriginCenter: "Sukma_SamratNagar_D (Chhattisgarh)",
        FacilityCity: "Sukma",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20092,
        Pin: 389158,
        DispatchCenter: "Dahod_Vanzarwada_I (Gujarat)",
        OriginCenter: "Dahod_Vanzarwada_I (Gujarat)",
        FacilityCity: "Dahod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20093,
        Pin: 686604,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20094,
        Pin: 628101,
        DispatchCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        OriginCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        FacilityCity: "Tuticorin",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20095,
        Pin: 581102,
        DispatchCenter: "Hangal_PalaRd_D (Karnataka)",
        OriginCenter: "Hangal_PalaRd_D (Karnataka)",
        FacilityCity: "Hangal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20096,
        Pin: 577557,
        DispatchCenter: "Channagiri_Holalkere_D (Karnataka)",
        OriginCenter: "Channagiri_Holalkere_D (Karnataka)",
        FacilityCity: "Channagiri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20097,
        Pin: 382405,
        DispatchCenter: "Ahmedabad_Kanera_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20098,
        Pin: 413253,
        DispatchCenter: "Solapur_Kalasenagar_D (Maharashtra)",
        OriginCenter: "Solapur_Kalasenagar_D (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20099,
        Pin: 304502,
        DispatchCenter: "Malpura_SadatMohalla_DPP (Rajasthan)",
        OriginCenter: "Malpura_SadatMohalla_DPP (Rajasthan)",
        FacilityCity: "Malpura",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20100,
        Pin: 506369,
        DispatchCenter: "Gudur_Chandrugudem_DPP (Telangana)",
        OriginCenter: "Gudur_Chandrugudem_DPP (Telangana)",
        FacilityCity: "Gudur-TS",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20101,
        Pin: 591316,
        DispatchCenter: "Ugar_GopiHotel_D (Karnataka)",
        OriginCenter: "Ugar_GopiHotel_D (Karnataka)",
        FacilityCity: "Ugar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20102,
        Pin: 784508,
        DispatchCenter: "Udalguri_Dhansrighat_D (Assam)",
        OriginCenter: "Udalguri_Dhansrighat_D (Assam)",
        FacilityCity: "Udalguri",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20103,
        Pin: 686587,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20104,
        Pin: 410513,
        DispatchCenter: "Rajgurunagar_Chandoli2_D (Maharashtra)",
        OriginCenter: "Rajgurunagar_Chandoli2_D (Maharashtra)",
        FacilityCity: "Rajgurunagar",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20105,
        Pin: 530003,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20106,
        Pin: 131408,
        DispatchCenter: "Sonipat_Adreshcolony_D (Haryana)",
        OriginCenter: "Sonipat_Adreshcolony_D (Haryana)",
        FacilityCity: "Sonipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20107,
        Pin: 465116,
        DispatchCenter: "PolayKalan_Himmatpura_DPP (Madhya Pradesh)",
        OriginCenter: "PolayKalan_Himmatpura_DPP (Madhya Pradesh)",
        FacilityCity: "Polay Kalan",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20108,
        Pin: 676305,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20109,
        Pin: 425202,
        DispatchCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        OriginCenter: "Bhusawal_JalgaonRd_D (Maharashtra)",
        FacilityCity: "Bhusawal",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20110,
        Pin: 228145,
        DispatchCenter: "Dostpur_Bhabbaltola_D (Uttar Pradesh)",
        OriginCenter: "Dostpur_Bhabbaltola_D (Uttar Pradesh)",
        FacilityCity: "Dostpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20111,
        Pin: 444804,
        DispatchCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        OriginCenter: "Paratwada_Hardenagar_D (Maharashtra)",
        FacilityCity: "Paratwada",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20112,
        Pin: 813211,
        DispatchCenter: "Banka_Shahpur_DPP (Bihar)",
        OriginCenter: "Banka_Shahpur_DPP (Bihar)",
        FacilityCity: "Banka",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20113,
        Pin: 391175,
        DispatchCenter: "Nasvadi_Kavant_DPP (Gujarat)",
        OriginCenter: "Nasvadi_Kavant_DPP (Gujarat)",
        FacilityCity: "Nasvadi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20114,
        Pin: 581210,
        DispatchCenter: "Hirekerur_Raghavendracolony_D (Karnataka)",
        OriginCenter: "Hirekerur_Raghavendracolony_D (Karnataka)",
        FacilityCity: "Hirekerur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20115,
        Pin: 781031,
        DispatchCenter: "Guwahati_Kaikchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20116,
        Pin: 524137,
        DispatchCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        OriginCenter: "Nellore_Inamadagudu_I (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20117,
        Pin: 202414,
        DispatchCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        OriginCenter: "Chandausi_Gumtal_D (Uttar Pradesh)",
        FacilityCity: "Chandausi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20118,
        Pin: 625703,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20119,
        Pin: 609802,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20120,
        Pin: 190010,
        DispatchCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Badgam_L (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20121,
        Pin: 521165,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20122,
        Pin: 274205,
        DispatchCenter: "Barhaj_Nandnaward_D (Uttar Pradesh)",
        OriginCenter: "Barhaj_Nandnaward_D (Uttar Pradesh)",
        FacilityCity: "Barhaj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20123,
        Pin: 472001,
        DispatchCenter: "Tikamgarh_Dhonga_D (Madhya Pradesh)",
        OriginCenter: "Tikamgarh_Dhonga_D (Madhya Pradesh)",
        FacilityCity: "Tikamgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20124,
        Pin: 689647,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20125,
        Pin: 627712,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20126,
        Pin: 630301,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20127,
        Pin: 305802,
        DispatchCenter: "Kishangarh_AirportRd_D (Rajasthan)",
        OriginCenter: "Kishangarh_AirportRd_D (Rajasthan)",
        FacilityCity: "Kishangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20128,
        Pin: 743439,
        DispatchCenter: "Hingalganj_Nebukhali_DPP (West Bengal)",
        OriginCenter: "Hingalganj_Nebukhali_DPP (West Bengal)",
        FacilityCity: "Hingalganj",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20129,
        Pin: 829118,
        DispatchCenter: "Patratu_Saundabasti_D (Jharkhand)",
        OriginCenter: "Patratu_Saundabasti_D (Jharkhand)",
        FacilityCity: "Patratu",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20130,
        Pin: 734314,
        DispatchCenter: "Kalimpong_Algarah_DPP (West Bengal)",
        OriginCenter: "Kalimpong_Algarah_DPP (West Bengal)",
        FacilityCity: "Kalimpong",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20131,
        Pin: 388465,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20132,
        Pin: 396115,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20133,
        Pin: 574272,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20134,
        Pin: 203407,
        DispatchCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        OriginCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        FacilityCity: "Siyana",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20135,
        Pin: 641659,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20136,
        Pin: 382022,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20137,
        Pin: 500039,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20138,
        Pin: 731245,
        DispatchCenter: "Khargram_Parbatipur_D (West Bengal)",
        OriginCenter: "Khargram_Parbatipur_D (West Bengal)",
        FacilityCity: "Khargram",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20139,
        Pin: 742160,
        DispatchCenter: "Murshidabad_Jiaganj_D (West Bengal)",
        OriginCenter: "Murshidabad_Jiaganj_D (West Bengal)",
        FacilityCity: "Murshidabad",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20140,
        Pin: 500133,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20141,
        Pin: 160026,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20142,
        Pin: 281404,
        DispatchCenter: "Chhata_Krishnacolony_D (Uttar Pradesh)",
        OriginCenter: "Chhata_Krishnacolony_D (Uttar Pradesh)",
        FacilityCity: "Chhata",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20143,
        Pin: 232332,
        DispatchCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        OriginCenter: "Ghazipur_Arazidhawa_D (Uttar Pradesh)",
        FacilityCity: "Ghazipur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20144,
        Pin: 683580,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20145,
        Pin: 602105,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20146,
        Pin: 603307,
        DispatchCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        OriginCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        FacilityCity: "Tindivanam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20147,
        Pin: 416523,
        DispatchCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        OriginCenter: "Malvan_Kumbharmath_D (Maharashtra)",
        FacilityCity: "Malvan",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20148,
        Pin: 284123,
        DispatchCenter: "Lalitpur_Govindnagar_D (Uttar Pradesh)",
        OriginCenter: "Lalitpur_Govindnagar_D (Uttar Pradesh)",
        FacilityCity: "Lalitpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20149,
        Pin: 457119,
        DispatchCenter: "Alote_PanchamViharColony_D (Madhya Pradesh)",
        OriginCenter: "Alote_PanchamViharColony_D (Madhya Pradesh)",
        FacilityCity: "Alote",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20150,
        Pin: 272128,
        DispatchCenter: "Basti_Maharajganj_D (Uttar Pradesh)",
        OriginCenter: "Basti_Maharajganj_D (Uttar Pradesh)",
        FacilityCity: "Itava",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20151,
        Pin: 389265,
        DispatchCenter: "Lunawada_VrdhriRD_D (Gujarat)",
        OriginCenter: "Lunawada_VrdhriRD_D (Gujarat)",
        FacilityCity: "Lunawada",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20152,
        Pin: 400056,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20153,
        Pin: 689580,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20154,
        Pin: 305405,
        DispatchCenter: "Kekri_LodhaChowk_DPP (Rajasthan)",
        OriginCenter: "Kekri_LodhaChowk_DPP (Rajasthan)",
        FacilityCity: "Kekri",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20155,
        Pin: 743512,
        DispatchCenter: "Barrackpore_Lalkuti_D (West Bengal)",
        OriginCenter: "Barrackpore_Lalkuti_D (West Bengal)",
        FacilityCity: "Barrackpore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20156,
        Pin: 533005,
        DispatchCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        OriginCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        FacilityCity: "Kakinada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20157,
        Pin: 229204,
        DispatchCenter: "Unchahar_Khojanpur_D (Uttar Pradesh)",
        OriginCenter: "Unchahar_Khojanpur_D (Uttar Pradesh)",
        FacilityCity: "Unchahar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20158,
        Pin: 241302,
        DispatchCenter: "Madhoganj_BaghuliRd_D (Uttar Pradesh)",
        OriginCenter: "Madhoganj_BaghuliRd_D (Uttar Pradesh)",
        FacilityCity: "Madhoganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20159,
        Pin: 848516,
        DispatchCenter: "Tajpur_HsptlRd_D (Bihar)",
        OriginCenter: "Tajpur_HsptlRd_D (Bihar)",
        FacilityCity: "Tajpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20160,
        Pin: 415619,
        DispatchCenter: "Ratnagiri_GaneshNagar_D (Maharashtra)",
        OriginCenter: "Ratnagiri_GaneshNagar_D (Maharashtra)",
        FacilityCity: "Ratnagiri",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20161,
        Pin: 205121,
        DispatchCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        OriginCenter: "Mainpuri_Bypassroad_I (Uttar Pradesh)",
        FacilityCity: "Mainpuri",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20162,
        Pin: 799284,
        DispatchCenter: "Dhalai_Narayanpur_DPP (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Dhalai",
        FacilityState: "Tripura",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20163,
        Pin: 741150,
        DispatchCenter: "Plassey_Janakinagar_D (West Bengal)",
        OriginCenter: "Plassey_Janakinagar_D (West Bengal)",
        FacilityCity: "Plassey",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20164,
        Pin: 690542,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20165,
        Pin: 495330,
        DispatchCenter: "Mungeli_PandariaRd_DPP (Chhattisgarh)",
        OriginCenter: "Mungeli_PandariaRd_DPP (Chhattisgarh)",
        FacilityCity: "Mungeli",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20166,
        Pin: 688565,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20167,
        Pin: 827013,
        DispatchCenter: "Bokaro_Chas_D (Jharkhand)",
        OriginCenter: "Bokaro_Chas_D (Jharkhand)",
        FacilityCity: "Bokaro",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20168,
        Pin: 444004,
        DispatchCenter: "Akola_Midcphase3_H (Maharashtra)",
        OriginCenter: "Akola_Midcphase3_H (Maharashtra)",
        FacilityCity: "Akola",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20169,
        Pin: 603106,
        DispatchCenter: "Chengalpattu_Uthiramerur_D (Tamil Nadu)",
        OriginCenter: "Chengalpattu_Uthiramerur_D (Tamil Nadu)",
        FacilityCity: "Chengalpattu",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20170,
        Pin: 631604,
        DispatchCenter: "Chennai_Sriperumbudur_L (Tamil Nadu)",
        OriginCenter: "Chennai_Sriperumbudur_GW (Tamil Nadu)",
        FacilityCity: "Sriperumbudur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20171,
        Pin: 127311,
        DispatchCenter: "Bhiwani_Bhagganpuri_D (Haryana)",
        OriginCenter: "Bhiwani_Bhagganpuri_D (Haryana)",
        FacilityCity: "Bhiwani",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20172,
        Pin: 609201,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20173,
        Pin: 466125,
        DispatchCenter: "Ashta_Saicolony_D (Madhya Pradesh)",
        OriginCenter: "Ashta_Saicolony_D (Madhya Pradesh)",
        FacilityCity: "Ashta",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20174,
        Pin: 501511,
        DispatchCenter: "Hyderabad_Autonagar_L (Telangana)",
        OriginCenter: "Hyderabad_Autonagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20175,
        Pin: 691538,
        DispatchCenter: "Kottarakkara_MythriNagar_D (Kerala)",
        OriginCenter: "Kottarakkara_MythriNagar_D (Kerala)",
        FacilityCity: "Kottarakkara",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20176,
        Pin: 273016,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20177,
        Pin: 711307,
        DispatchCenter: "Howrah_Temp_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20178,
        Pin: 603406,
        DispatchCenter: "Chengalpattu_Uthiramerur_D (Tamil Nadu)",
        OriginCenter: "Chengalpattu_Uthiramerur_D (Tamil Nadu)",
        FacilityCity: "Chengalpattu",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20179,
        Pin: 741251,
        DispatchCenter: "Naihati_Kalyani_DPP (West Bengal)",
        OriginCenter: "Naihati_Kalyani_DPP (West Bengal)",
        FacilityCity: "Kalyani",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20180,
        Pin: 670582,
        DispatchCenter: "Alakode_Thavukunnu_D (Kerala)",
        OriginCenter: "Alakode_Thavukunnu_D (Kerala)",
        FacilityCity: "Alakode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20181,
        Pin: 341501,
        DispatchCenter: "Makrana_Parbatsar_D (Rajasthan)",
        OriginCenter: "Makrana_Parbatsar_D (Rajasthan)",
        FacilityCity: "Makrana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20182,
        Pin: 563133,
        DispatchCenter: "Bangalore_Koralur_L (Karnataka)",
        OriginCenter: "Bangalore_Soukyaroad_GW (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20183,
        Pin: 466001,
        DispatchCenter: "Sehore_Sherpur_D (Madhya Pradesh)",
        OriginCenter: "Sehore_Sherpur_D (Madhya Pradesh)",
        FacilityCity: "Sehore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20184,
        Pin: 670106,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20185,
        Pin: 263146,
        DispatchCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        OriginCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        FacilityCity: "Rudrapur",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20186,
        Pin: 754240,
        DispatchCenter: "Kendrapara_Penthapal_D (Orissa)",
        OriginCenter: "Kendrapara_Penthapal_D (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20187,
        Pin: 387305,
        DispatchCenter: "Kapadvanj_Dholikui_D (Gujarat)",
        OriginCenter: "Kapadvanj_Dholikui_D (Gujarat)",
        FacilityCity: "Kapadvanj",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20188,
        Pin: 583214,
        DispatchCenter: "HoovinaHadagali_Holagundhi_D (Karnataka)",
        OriginCenter: "HoovinaHadagali_Holagundhi_D (Karnataka)",
        FacilityCity: "Hoovina Hadagali",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20189,
        Pin: 590005,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20190,
        Pin: 515211,
        DispatchCenter: "Hindupur_Sadlapalli_D (Andhra Pradesh)",
        OriginCenter: "Hindupur_Sadlapalli_D (Andhra Pradesh)",
        FacilityCity: "Hindupur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20191,
        Pin: 581115,
        DispatchCenter: "Ranebennur_MrthnjyaNgr_D (Karnataka)",
        OriginCenter: "Ranebennur_MrthnjyaNgr_D (Karnataka)",
        FacilityCity: "Ranebennur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20192,
        Pin: 575007,
        DispatchCenter: "Mangalore_Katipalla_H (Karnataka)",
        OriginCenter: "Mangalore_Katipalla_H (Karnataka)",
        FacilityCity: "Mangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20193,
        Pin: 712125,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20194,
        Pin: 852130,
        DispatchCenter: "Supaul_SadrHsptl_D (Bihar)",
        OriginCenter: "Supaul_SadrHsptl_D (Bihar)",
        FacilityCity: "Supaul",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20195,
        Pin: 450112,
        DispatchCenter: "Mundi_Kanud_D (Madhya Pradesh)",
        OriginCenter: "Mundi_Kanud_D (Madhya Pradesh)",
        FacilityCity: "Mundi",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20196,
        Pin: 585220,
        DispatchCenter: "Shorapur_GandhiNagar_D (Karnataka)",
        OriginCenter: "Shorapur_GandhiNagar_D (Karnataka)",
        FacilityCity: "Shorapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20197,
        Pin: 451010,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20198,
        Pin: 263138,
        DispatchCenter: "Almora_Makedi_D (Uttarakhand)",
        OriginCenter: "Almora_Makedi_D (Uttarakhand)",
        FacilityCity: "Almora",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20199,
        Pin: 620602,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20200,
        Pin: 413515,
        DispatchCenter: "Ahmedpur_ITIclg_D (Maharashtra)",
        OriginCenter: "Ahmedpur_ITIclg_D (Maharashtra)",
        FacilityCity: "Ahmadpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20201,
        Pin: 122234,
        DispatchCenter: "Gurgaon_Sector17_L (Haryana)",
        OriginCenter: "Gurgaon_Sector17_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20202,
        Pin: 624108,
        DispatchCenter: "Kodaikanal_Mudliyrpurm_D (Tamil Nadu)",
        OriginCenter: "Kodaikanal_Mudliyrpurm_D (Tamil Nadu)",
        FacilityCity: "Kodaikanal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20203,
        Pin: 793017,
        DispatchCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        OriginCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20204,
        Pin: 832110,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20205,
        Pin: 515842,
        DispatchCenter: "Guntakal_Central_D_2 (Andhra Pradesh)",
        OriginCenter: "Guntakal_Central_D_2 (Andhra Pradesh)",
        FacilityCity: "Guntakal",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20206,
        Pin: 848239,
        DispatchCenter: "Rosera_HanumanTemple_D (Bihar)",
        OriginCenter: "Rosera_HanumanTemple_D (Bihar)",
        FacilityCity: "Rusera",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20207,
        Pin: 770002,
        DispatchCenter: "Sundargarh_BndhaPli_DPP (Orissa)",
        OriginCenter: "Sundargarh_BndhaPli_DPP (Orissa)",
        FacilityCity: "Sundargarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20208,
        Pin: 822123,
        DispatchCenter: "Chhatarpur_Patan_D (Jharkhand)",
        OriginCenter: "Chhatarpur_Patan_D (Jharkhand)",
        FacilityCity: "Bisrampur",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20209,
        Pin: 627133,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20210,
        Pin: 416114,
        DispatchCenter: "Shirala_PostOffice_DPP (Maharashtra)",
        OriginCenter: "Shirala_PostOffice_DPP (Maharashtra)",
        FacilityCity: "Shirala",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20211,
        Pin: 458468,
        DispatchCenter: "Jawad_Vajihiwada_D (Madhya Pradesh)",
        OriginCenter: "Jawad_Vajihiwada_D (Madhya Pradesh)",
        FacilityCity: "Jawad",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20212,
        Pin: 415714,
        DispatchCenter: "Dapoli_Ghimhavane_D (Maharashtra)",
        OriginCenter: "Dapoli_Ghimhavane_D (Maharashtra)",
        FacilityCity: "Dapoli",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20213,
        Pin: 501323,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20214,
        Pin: 737134,
        DispatchCenter: "Rangpo_Khanikhola_I (Sikkim)",
        OriginCenter: "Rangpo_Khanikhola_I (Sikkim)",
        FacilityCity: "Rangpo",
        FacilityState: "Sikkim",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20215,
        Pin: 826005,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20216,
        Pin: 522617,
        DispatchCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasaraopet",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20217,
        Pin: 752077,
        DispatchCenter: "Kalapathar_GoleiChhak_D (Orissa)",
        OriginCenter: "Kalapathar_GoleiChhak_D (Orissa)",
        FacilityCity: "Kalapathar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20218,
        Pin: 110066,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20219,
        Pin: 328031,
        DispatchCenter: "Bari_MaharajBaghColony_D (Rajasthan)",
        OriginCenter: "Bari_MaharajBaghColony_D (Rajasthan)",
        FacilityCity: "Bari",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20220,
        Pin: 226010,
        DispatchCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Barabankimati_L (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20221,
        Pin: 804401,
        DispatchCenter: "Arwal_SipahPnchayt_D (Bihar)",
        OriginCenter: "Arwal_SipahPnchayt_D (Bihar)",
        FacilityCity: "Arwal",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20222,
        Pin: 440034,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Chikhali_L (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20223,
        Pin: 314024,
        DispatchCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        OriginCenter: "Sagwara_PunarwasColony_D (Rajasthan)",
        FacilityCity: "Sagwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20224,
        Pin: 628303,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20225,
        Pin: 175031,
        DispatchCenter: "Nihri_Bahli_DPP (Himachal Pradesh)",
        OriginCenter: "Nihri_Bahli_DPP (Himachal Pradesh)",
        FacilityCity: "Nihri",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20226,
        Pin: 686689,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20227,
        Pin: 345027,
        DispatchCenter: "Sheo_Goonga_D (Rajasthan)",
        OriginCenter: "Sheo_Goonga_D (Rajasthan)",
        FacilityCity: "Sheo",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20228,
        Pin: 624004,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20229,
        Pin: 572107,
        DispatchCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        OriginCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20230,
        Pin: 486002,
        DispatchCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        OriginCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        FacilityCity: "Rewa",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20231,
        Pin: 413211,
        DispatchCenter: "Indapur_ChaitanyaPlaza_D (Maharashtra)",
        OriginCenter: "Indapur_ChaitanyaPlaza_D (Maharashtra)",
        FacilityCity: "Indapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20232,
        Pin: 756001,
        DispatchCenter: "Balasore_Ganeswarpur_D (Orissa)",
        OriginCenter: "Balasore_Ganeswarpur_D (Orissa)",
        FacilityCity: "Balasore",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20233,
        Pin: 722121,
        DispatchCenter: "Khatra_KoralDPP_D (West Bengal)",
        OriginCenter: "Khatra_KoralDPP_D (West Bengal)",
        FacilityCity: "Khatra",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20234,
        Pin: 271902,
        DispatchCenter: "Bahraich_Dularpur_H (Uttar Pradesh)",
        OriginCenter: "Bahraich_Dularpur_H (Uttar Pradesh)",
        FacilityCity: "Bahraich",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20235,
        Pin: 712104,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20236,
        Pin: 211021,
        DispatchCenter: "Soraon_Purabtola_D (Uttar Pradesh)",
        OriginCenter: "Soraon_Purabtola_D (Uttar Pradesh)",
        FacilityCity: "Soraon",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20237,
        Pin: 756111,
        DispatchCenter: "Bhadrak_Mathasahi_I (Orissa)",
        OriginCenter: "Bhadrak_Mathasahi_I (Orissa)",
        FacilityCity: "Bhadrak",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20238,
        Pin: 263653,
        DispatchCenter: "Dwarahat_Kalikholi_DPP (Uttarakhand)",
        OriginCenter: "Dwarahat_Kalikholi_DPP (Uttarakhand)",
        FacilityCity: "Dwarahat",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 20239,
        Pin: 686601,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20240,
        Pin: 854106,
        DispatchCenter: "Purnia_Kasba_H (Bihar)",
        OriginCenter: "Purnia_Kasba_H (Bihar)",
        FacilityCity: "Purnia",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20241,
        Pin: 400101,
        DispatchCenter: "Mumbai_Kandivali_L (Maharashtra)",
        OriginCenter: "Mumbai_Kandivali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20242,
        Pin: 577551,
        DispatchCenter: "Davangere_Santhebennur_D (Karnataka)",
        OriginCenter: "Davangere_Santhebennur_D (Karnataka)",
        FacilityCity: "Davangere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20243,
        Pin: 148027,
        DispatchCenter: "Patran_Kahangarh_D (Punjab)",
        OriginCenter: "Patran_Kahangarh_D (Punjab)",
        FacilityCity: "Patran",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20244,
        Pin: 422009,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20245,
        Pin: 689698,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20246,
        Pin: 132122,
        DispatchCenter: "Panipat_Samalkha_D (Haryana)",
        OriginCenter: "Panipat_Samalkha_D (Haryana)",
        FacilityCity: "Panipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20247,
        Pin: 572106,
        DispatchCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        OriginCenter: "Tumkur_Thimmalapura_H (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20248,
        Pin: 587104,
        DispatchCenter: "Kerur_WardNo2_D (Karnataka)",
        OriginCenter: "Kerur_WardNo2_D (Karnataka)",
        FacilityCity: "Kerur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20249,
        Pin: 221408,
        DispatchCenter: "Bhadohi_Chedibeer_D (Uttar Pradesh)",
        OriginCenter: "Bhadohi_Chedibeer_D (Uttar Pradesh)",
        FacilityCity: "Bhadohi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20250,
        Pin: 631701,
        DispatchCenter: "Cheyyar_NehruNGR_D (Tamil Nadu)",
        OriginCenter: "Cheyyar_NehruNGR_D (Tamil Nadu)",
        FacilityCity: "Cheyyar",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20251,
        Pin: 534452,
        DispatchCenter: "DwarakaTirumala_PoliceStn_D (Andhra Pradesh)",
        OriginCenter: "DwarakaTirumala_PoliceStn_D (Andhra Pradesh)",
        FacilityCity: "Dwaraka Tirumala",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20252,
        Pin: 505454,
        DispatchCenter: "DharmapuriTS_Boyawada_D (Telangana)",
        OriginCenter: "DharmapuriTS_Boyawada_D (Telangana)",
        FacilityCity: "Dharmapuri-TS",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20253,
        Pin: 629175,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20254,
        Pin: 626526,
        DispatchCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        OriginCenter: "Theni_Kottaikalam_D (Tamil Nadu)",
        FacilityCity: "Theni",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20255,
        Pin: 627601,
        DispatchCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        OriginCenter: "Ambasamudram_DRSColony_D (Tamil Nadu)",
        FacilityCity: "Ambasamudram",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20256,
        Pin: 201318,
        DispatchCenter: "Noida_Sector67Bblock_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Noida",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20257,
        Pin: 229503,
        DispatchCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        OriginCenter: "Unnao_KulhaBagha_D (Uttar Pradesh)",
        FacilityCity: "Unnao",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20258,
        Pin: 742226,
        DispatchCenter: "Morgram_Kantagoriya_D (West Bengal)",
        OriginCenter: "Morgram_Kantagoriya_D (West Bengal)",
        FacilityCity: "Morgram",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20259,
        Pin: 160035,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20260,
        Pin: 241123,
        DispatchCenter: "Shahabad_Jogipur_D (Uttar Pradesh)",
        OriginCenter: "Shahabad_Jogipur_D (Uttar Pradesh)",
        FacilityCity: "Shahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20261,
        Pin: 344028,
        DispatchCenter: "Balotra_Ward45_D (Rajasthan)",
        OriginCenter: "Balotra_Ward45_D (Rajasthan)",
        FacilityCity: "Balotra",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20262,
        Pin: 641065,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20263,
        Pin: 481664,
        DispatchCenter: "Ghugri_Ward3_DPP (Madhya Pradesh)",
        OriginCenter: "Ghugri_Ward3_DPP (Madhya Pradesh)",
        FacilityCity: "Ghugri",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20264,
        Pin: 176002,
        DispatchCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        OriginCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        FacilityCity: "Kangra",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 20265,
        Pin: 422302,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20266,
        Pin: 713512,
        DispatchCenter: "Katwa_Panchghara_D (West Bengal)",
        OriginCenter: "Katwa_Panchghara_D (West Bengal)",
        FacilityCity: "Katwa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20267,
        Pin: 685588,
        DispatchCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        OriginCenter: "Thodupuzha_MtSinaiRd_D (Kerala)",
        FacilityCity: "Thodupuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20268,
        Pin: 126114,
        DispatchCenter: "Jind_SunderNagar_D (Haryana)",
        OriginCenter: "Jind_SunderNagar_D (Haryana)",
        FacilityCity: "Jind",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20269,
        Pin: 843133,
        DispatchCenter: "Muzaffarpur_Motipur_D (Bihar)",
        OriginCenter: "Muzaffarpur_Motipur_D (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20270,
        Pin: 721457,
        DispatchCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        OriginCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        FacilityCity: "Dantan",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20271,
        Pin: 823001,
        DispatchCenter: "Gaya_Matihani_H (Bihar)",
        OriginCenter: "Gaya_Matihani_H (Bihar)",
        FacilityCity: "Gaya",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20272,
        Pin: 622315,
        DispatchCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        OriginCenter: "Pudukkottai_Machuvadi_D (Tamil Nadu)",
        FacilityCity: "Pudukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20273,
        Pin: 788113,
        DispatchCenter: "Bhaga_Khashpur_D (Assam)",
        OriginCenter: "Bhaga_Khashpur_D (Assam)",
        FacilityCity: "Bhaga",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20274,
        Pin: 688541,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20275,
        Pin: 680664,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20276,
        Pin: 342901,
        DispatchCenter: "Gotan_Sadarbazar_DPP (Rajasthan)",
        OriginCenter: "Gotan_Sadarbazar_DPP (Rajasthan)",
        FacilityCity: "Gotan",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20277,
        Pin: 788119,
        DispatchCenter: "Sonabarighat_Saidpur_DPP (Assam)",
        OriginCenter: "Sonabarighat_Saidpur_DPP (Assam)",
        FacilityCity: "Sonabarighat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20278,
        Pin: 364365,
        DispatchCenter: "Amreli_Bhojalpara_D (Gujarat)",
        OriginCenter: "Amreli_Bhojalpara_D (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20279,
        Pin: 571448,
        DispatchCenter: "Nagamangala_TBExtension_D (Karnataka)",
        OriginCenter: "Nagamangala_TBExtension_D (Karnataka)",
        FacilityCity: "Nagamangala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20280,
        Pin: 515803,
        DispatchCenter: "Guntakal_Central_D_2 (Andhra Pradesh)",
        OriginCenter: "Guntakal_Central_D_2 (Andhra Pradesh)",
        FacilityCity: "Guntakal",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20281,
        Pin: 305002,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20282,
        Pin: 848160,
        DispatchCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        OriginCenter: "DalsinghSarai_NawadaChowk_D (Bihar)",
        FacilityCity: "Dalsingh Sarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20283,
        Pin: 421502,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20284,
        Pin: 682029,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20285,
        Pin: 591256,
        DispatchCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        OriginCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        FacilityCity: "Athani",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20286,
        Pin: 431113,
        DispatchCenter: "Sillod_ZebaTWR_D (Maharashtra)",
        OriginCenter: "Sillod_ZebaTWR_D (Maharashtra)",
        FacilityCity: "Sillod",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20287,
        Pin: 851135,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20288,
        Pin: 517101,
        DispatchCenter: "Tirupati_Thondavada_D (Andhra Pradesh)",
        OriginCenter: "Tirupati_Thondavada_D (Andhra Pradesh)",
        FacilityCity: "Tirupati",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20289,
        Pin: 396185,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20290,
        Pin: 625010,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20291,
        Pin: 577203,
        DispatchCenter: "Shimoga_Eshwariah_I (Karnataka)",
        OriginCenter: "Shimoga_Eshwariah_I (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20292,
        Pin: 125007,
        DispatchCenter: "Hisar_Dabra_H (Haryana)",
        OriginCenter: "Hisar_Dabra_H (Haryana)",
        FacilityCity: "Hisar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20293,
        Pin: 413006,
        DispatchCenter: "Solapur_Balepeth_I (Maharashtra)",
        OriginCenter: "Solapur_Balepeth_I (Maharashtra)",
        FacilityCity: "Solapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20294,
        Pin: 416435,
        DispatchCenter: "Sangli_Dattanagar_D (Maharashtra)",
        OriginCenter: "Sangli_Dattanagar_D (Maharashtra)",
        FacilityCity: "Sangli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20295,
        Pin: 756086,
        DispatchCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        OriginCenter: "Dantan_Solpatta2_DPP (West Bengal)",
        FacilityCity: "Dantan",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20296,
        Pin: 574314,
        DispatchCenter: "Sullia_Kanthamangala_D (Karnataka)",
        OriginCenter: "Sullia_Kanthamangala_D (Karnataka)",
        FacilityCity: "Sullia",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20297,
        Pin: 623608,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20298,
        Pin: 160056,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20299,
        Pin: 503207,
        DispatchCenter: "Bodhan_VamshiSchl_D (Telangana)",
        OriginCenter: "Bodhan_VamshiSchl_D (Telangana)",
        FacilityCity: "Bodhan",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20300,
        Pin: 180017,
        DispatchCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        OriginCenter: "Jammu_Baribrahmana_H (Jammu & Kashmir)",
        FacilityCity: "Jammu",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20301,
        Pin: 686027,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20302,
        Pin: 516390,
        DispatchCenter: "Pulivendula_Bakarapuram_D (Andhra Pradesh)",
        OriginCenter: "Pulivendula_Bakarapuram_D (Andhra Pradesh)",
        FacilityCity: "Pulivendula",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20303,
        Pin: 533290,
        DispatchCenter: "Gokavaram_MuraliNagar_D (Andhra Pradesh)",
        OriginCenter: "Gokavaram_MuraliNagar_D (Andhra Pradesh)",
        FacilityCity: "Gokavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20304,
        Pin: 723142,
        DispatchCenter: "Raghunathpur_Nutandih_D (West Bengal)",
        OriginCenter: "Raghunathpur_Nutandih_D (West Bengal)",
        FacilityCity: "Raghunathpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20305,
        Pin: 134114,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20306,
        Pin: 394335,
        DispatchCenter: "Bardoli_GopalNagar_D (Gujarat)",
        OriginCenter: "Bardoli_GopalNagar_D (Gujarat)",
        FacilityCity: "Bardoli",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20307,
        Pin: 781008,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20308,
        Pin: 147102,
        DispatchCenter: "Patran_Kahangarh_D (Punjab)",
        OriginCenter: "Patran_Kahangarh_D (Punjab)",
        FacilityCity: "Patran",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20309,
        Pin: 605201,
        DispatchCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        OriginCenter: "Gingee_KVKThtr_D (Tamil Nadu)",
        FacilityCity: "Gingee",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20310,
        Pin: 487114,
        DispatchCenter: "Narsinghpur_Stationganj_D (Madhya Pradesh)",
        OriginCenter: "Narsinghpur_Stationganj_D (Madhya Pradesh)",
        FacilityCity: "Narsinghpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20311,
        Pin: 688508,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20312,
        Pin: 132040,
        DispatchCenter: "Panipat_Risalu_P (Haryana)",
        OriginCenter: "Panipat_Risalu_P (Haryana)",
        FacilityCity: "Panipat",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20313,
        Pin: 713378,
        DispatchCenter: "Dubrajpur_Pandaveswar_D (West Bengal)",
        OriginCenter: "Dubrajpur_Pandaveswar_D (West Bengal)",
        FacilityCity: "Dubrajpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20314,
        Pin: 676307,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20315,
        Pin: 581411,
        DispatchCenter: "Mundgod_BankapuraRd_DPP (Karnataka)",
        OriginCenter: "Mundgod_BankapuraRd_DPP (Karnataka)",
        FacilityCity: "Mundgod",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20316,
        Pin: 224147,
        DispatchCenter: "Rajesultanpur_PadumprMkt_D (Uttar Pradesh)",
        OriginCenter: "Rajesultanpur_PadumprMkt_D (Uttar Pradesh)",
        FacilityCity: "Rajesultanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20317,
        Pin: 263663,
        DispatchCenter: "Ranikhet_KhariBazar_DPP (Uttarakhand)",
        OriginCenter: "Ranikhet_KhariBazar_DPP (Uttarakhand)",
        FacilityCity: "Ranikhet",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 20318,
        Pin: 209739,
        DispatchCenter: "Farrukhabad_RamBanquet_D (Uttar Pradesh)",
        OriginCenter: "Farrukhabad_RamBanquet_D (Uttar Pradesh)",
        FacilityCity: "Farrukhabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20319,
        Pin: 127031,
        DispatchCenter: "Bhiwani_Bhagganpuri_D (Haryana)",
        OriginCenter: "Bhiwani_Bhagganpuri_D (Haryana)",
        FacilityCity: "Bhiwani",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20320,
        Pin: 603203,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20321,
        Pin: 756042,
        DispatchCenter: "Soro_Ashoknagar_D (Orissa)",
        OriginCenter: "Soro_Ashoknagar_D (Orissa)",
        FacilityCity: "Soro",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20322,
        Pin: 643213,
        DispatchCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        OriginCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20323,
        Pin: 415415,
        DispatchCenter: "Patan_Ashram_D (Maharashtra)",
        OriginCenter: "Patan_Ashram_D (Maharashtra)",
        FacilityCity: "Patan-MH",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20324,
        Pin: 363351,
        DispatchCenter: "Halvad_SaraCross_DPP (Gujarat)",
        OriginCenter: "Halvad_SaraCross_DPP (Gujarat)",
        FacilityCity: "Halvad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20325,
        Pin: 811103,
        DispatchCenter: "Barbigha_Khetalpura_D (Bihar)",
        OriginCenter: "Barbigha_Khetalpura_D (Bihar)",
        FacilityCity: "Barbigha",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20326,
        Pin: 723215,
        DispatchCenter: "Bhaddi_Tanashi_DPP (West Bengal)",
        OriginCenter: "Bhaddi_Tanashi_DPP (West Bengal)",
        FacilityCity: "Bhaddi",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20327,
        Pin: 676107,
        DispatchCenter: "Tirur_Talakkad_D (Kerala)",
        OriginCenter: "Tirur_Talakkad_D (Kerala)",
        FacilityCity: "Tirur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20328,
        Pin: 629252,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20329,
        Pin: 587317,
        DispatchCenter: "Mudhol_Maharancol_D (Karnataka)",
        OriginCenter: "Mudhol_Maharancol_D (Karnataka)",
        FacilityCity: "Mudhol",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20330,
        Pin: 522415,
        DispatchCenter: "Piduguralla_Rasoolpet_D (Andhra Pradesh)",
        OriginCenter: "Piduguralla_Rasoolpet_D (Andhra Pradesh)",
        FacilityCity: "Piduguralla",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20331,
        Pin: 757100,
        DispatchCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        OriginCenter: "Betnoti_Bhagabati_DPP (Orissa)",
        FacilityCity: "Betnoti",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20332,
        Pin: 210201,
        DispatchCenter: "Atarra_GangehiRd_D (Uttar Pradesh)",
        OriginCenter: "Atarra_GangehiRd_D (Uttar Pradesh)",
        FacilityCity: "Atarra",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20333,
        Pin: 689676,
        DispatchCenter: "Ranni_Chethakhl_D (Kerala)",
        OriginCenter: "Ranni_Chethakhl_D (Kerala)",
        FacilityCity: "Ranni",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20334,
        Pin: 393021,
        DispatchCenter: "Ankleshwar_Motali_L (Gujarat)",
        OriginCenter: "Ankleshwar_Motali_L (Gujarat)",
        FacilityCity: "Ankleshwar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20335,
        Pin: 450991,
        DispatchCenter: "Mundi_Kanud_D (Madhya Pradesh)",
        OriginCenter: "Mundi_Kanud_D (Madhya Pradesh)",
        FacilityCity: "Mundi",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20336,
        Pin: 140125,
        DispatchCenter: "AnandpurSahib_Dher_D (Punjab)",
        OriginCenter: "AnandpurSahib_Dher_D (Punjab)",
        FacilityCity: "Anandpur Sahib",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 20337,
        Pin: 271904,
        DispatchCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        OriginCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        FacilityCity: "Colonejganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20338,
        Pin: 360004,
        DispatchCenter: "Rajkot_Amargadh_H (Gujarat)",
        OriginCenter: "Rajkot_Amargadh_H (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20339,
        Pin: 577428,
        DispatchCenter: "Shiralakoppa_Shikarpur_DPP (Karnataka)",
        OriginCenter: "Shiralakoppa_Shikarpur_DPP (Karnataka)",
        FacilityCity: "Shiralakoppa",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20340,
        Pin: 627105,
        DispatchCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        OriginCenter: "Vallioor_Ebcolony_D (Tamil Nadu)",
        FacilityCity: "Vallioor",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20341,
        Pin: 761117,
        DispatchCenter: "Kodala_Lunighati_DPP (Orissa)",
        OriginCenter: "Kodala_Lunighati_DPP (Orissa)",
        FacilityCity: "Polasara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20342,
        Pin: 403402,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20343,
        Pin: 206251,
        DispatchCenter: "Bidhuna_BharthnRd_D (Uttar Pradesh)",
        OriginCenter: "Bidhuna_BharthnRd_D (Uttar Pradesh)",
        FacilityCity: "Bidhuna",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20344,
        Pin: 444702,
        DispatchCenter: "Dharni_Bank_D (Maharashtra)",
        OriginCenter: "Dharni_Bank_D (Maharashtra)",
        FacilityCity: "Dharni",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20345,
        Pin: 332603,
        DispatchCenter: "Reengus_TegoreViharColony_D (Rajasthan)",
        OriginCenter: "Reengus_TegoreViharColony_D (Rajasthan)",
        FacilityCity: "Reengus",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20346,
        Pin: 221701,
        DispatchCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        OriginCenter: "Ballia_GorkprRd_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20347,
        Pin: 136042,
        DispatchCenter: "Kaithal_DefenceColony_D (Haryana)",
        OriginCenter: "Kaithal_DefenceColony_D (Haryana)",
        FacilityCity: "Kaithal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20348,
        Pin: 683572,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20349,
        Pin: 811105,
        DispatchCenter: "Lakhisarai_Central_I_2 (Bihar)",
        OriginCenter: "Lakhisarai_Central_I_2 (Bihar)",
        FacilityCity: "Lakhisarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20350,
        Pin: 741156,
        DispatchCenter: "Plassey_Janakinagar_D (West Bengal)",
        OriginCenter: "Plassey_Janakinagar_D (West Bengal)",
        FacilityCity: "Plassey",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20351,
        Pin: 691009,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20352,
        Pin: 803116,
        DispatchCenter: "Rajgir_Naipokhar_D (Bihar)",
        OriginCenter: "Rajgir_Naipokhar_D (Bihar)",
        FacilityCity: "Rajgir",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20353,
        Pin: 384220,
        DispatchCenter: "Patan_Hansapur_D (Gujarat)",
        OriginCenter: "Patan_Hansapur_D (Gujarat)",
        FacilityCity: "Patan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20354,
        Pin: 535260,
        DispatchCenter: "Gajapatinagaram_SaibabaTmpl_DPP (Andhra Pradesh)",
        OriginCenter: "Gajapatinagaram_SaibabaTmpl_DPP (Andhra Pradesh)",
        FacilityCity: "Gajapatinagaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20355,
        Pin: 333303,
        DispatchCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        OriginCenter: "Udaipurwati_DaduMndr_D (Rajasthan)",
        FacilityCity: "Udaipurwati",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20356,
        Pin: 591225,
        DispatchCenter: "Nipani_Sakharwadi_D (Karnataka)",
        OriginCenter: "Nipani_Sakharwadi_D (Karnataka)",
        FacilityCity: "Nipani",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20357,
        Pin: 828403,
        DispatchCenter: "Bokaro_Chas_D (Jharkhand)",
        OriginCenter: "Bokaro_Chas_D (Jharkhand)",
        FacilityCity: "Bokaro",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20358,
        Pin: 521178,
        DispatchCenter: "Jagyapet_Chillakallu_DPP (Andhra Pradesh)",
        OriginCenter: "Jagyapet_Chillakallu_DPP (Andhra Pradesh)",
        FacilityCity: "Jaggayyapet",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20359,
        Pin: 400604,
        DispatchCenter: "Mumbai_Nehrunagar_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20360,
        Pin: 394421,
        DispatchCenter: "Kosamba_Palod_D (Gujarat)",
        OriginCenter: "Kosamba_Palod_D (Gujarat)",
        FacilityCity: "Kosamba",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20361,
        Pin: 486666,
        DispatchCenter: "Madwas_Majhigawan_D (Madhya Pradesh)",
        OriginCenter: "Madwas_Majhigawan_D (Madhya Pradesh)",
        FacilityCity: "Madwas",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20362,
        Pin: 688532,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20363,
        Pin: 500005,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20364,
        Pin: 244224,
        DispatchCenter: "Kanth_ModaPatti_DPP (Uttar Pradesh)",
        OriginCenter: "Kanth_ModaPatti_DPP (Uttar Pradesh)",
        FacilityCity: "Kanth",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20365,
        Pin: 743337,
        DispatchCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        OriginCenter: "DakshinBarasat_Magrahat_D (West Bengal)",
        FacilityCity: "Dakshin Barasat",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20366,
        Pin: 208006,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20367,
        Pin: 670103,
        DispatchCenter: "Thalassery_TempleGt_D (Kerala)",
        OriginCenter: "Thalassery_TempleGt_D (Kerala)",
        FacilityCity: "Thalassery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20368,
        Pin: 743513,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20369,
        Pin: 584116,
        DispatchCenter: "Devadurga_APMC_D (Karnataka)",
        OriginCenter: "Devadurga_APMC_D (Karnataka)",
        FacilityCity: "Devadurga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20370,
        Pin: 228119,
        DispatchCenter: "Sultanpur_Balpur_D (Uttar Pradesh)",
        OriginCenter: "Sultanpur_Balpur_D (Uttar Pradesh)",
        FacilityCity: "Sultanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20371,
        Pin: 770016,
        DispatchCenter: "Sundargarh_BndhaPli_DPP (Orissa)",
        OriginCenter: "Sundargarh_BndhaPli_DPP (Orissa)",
        FacilityCity: "Sundargarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20372,
        Pin: 402113,
        DispatchCenter: "Shriwardhan_Kherdi_D (Maharashtra)",
        OriginCenter: "Shriwardhan_Kherdi_D (Maharashtra)",
        FacilityCity: "Shriwardhan",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20373,
        Pin: 534301,
        DispatchCenter: "Kovvur_Samisragudem_D (Andhra Pradesh)",
        OriginCenter: "Kovvur_Samisragudem_D (Andhra Pradesh)",
        FacilityCity: "Kovvur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20374,
        Pin: 622002,
        DispatchCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        OriginCenter: "Ponnamaravathi_Valayapatti_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20375,
        Pin: 263139,
        DispatchCenter: "Haldwani_Berapokhara_D (Uttarakhand)",
        OriginCenter: "Haldwani_Berapokhara_D (Uttarakhand)",
        FacilityCity: "Haldwani",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20376,
        Pin: 670642,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20377,
        Pin: 401506,
        DispatchCenter: "Palghar_Maan_L (Maharashtra)",
        OriginCenter: "Palghar_Maan_L (Maharashtra)",
        FacilityCity: "Palghar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20378,
        Pin: 572124,
        DispatchCenter: "Madhugiri_SBI_DPP (Karnataka)",
        OriginCenter: "Madhugiri_SBI_DPP (Karnataka)",
        FacilityCity: "Madhugiri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20379,
        Pin: 346003,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20380,
        Pin: 322245,
        DispatchCenter: "Karauli_Subhashnagar_D (Rajasthan)",
        OriginCenter: "Karauli_Subhashnagar_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20381,
        Pin: 571316,
        DispatchCenter: "Chamarajanagar_Galipur_D (Karnataka)",
        OriginCenter: "Chamarajanagar_Galipur_D (Karnataka)",
        FacilityCity: "Chamarajanagar",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20382,
        Pin: 821315,
        DispatchCenter: "Sasaram_Karwandiya_I (Bihar)",
        OriginCenter: "Sasaram_Karwandiya_I (Bihar)",
        FacilityCity: "Sasaram",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20383,
        Pin: 679357,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20384,
        Pin: 845305,
        DispatchCenter: "Raxaul_Nonea_D (Bihar)",
        OriginCenter: "Raxaul_Nonea_D (Bihar)",
        FacilityCity: "Raxaul",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20385,
        Pin: 689108,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20386,
        Pin: 221401,
        DispatchCenter: "Bhadohi_Chedibeer_D (Uttar Pradesh)",
        OriginCenter: "Bhadohi_Chedibeer_D (Uttar Pradesh)",
        FacilityCity: "Bhadohi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20387,
        Pin: 341515,
        DispatchCenter: "Degana_GovtHospital_D (Rajasthan)",
        OriginCenter: "Degana_GovtHospital_D (Rajasthan)",
        FacilityCity: "Degana",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20388,
        Pin: 761200,
        DispatchCenter: "Paralakhemundi_Omcolony_D (Orissa)",
        OriginCenter: "Paralakhemundi_Omcolony_D (Orissa)",
        FacilityCity: "Paralakhemundi",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20389,
        Pin: 509133,
        DispatchCenter: "Gadwal_Venkatramana_D (Telangana)",
        OriginCenter: "Gadwal_Venkatramana_D (Telangana)",
        FacilityCity: "Gadwal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20390,
        Pin: 312021,
        DispatchCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        OriginCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        FacilityCity: "Chittaurgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20391,
        Pin: 363334,
        DispatchCenter: "Halvad_SaraCross_DPP (Gujarat)",
        OriginCenter: "Halvad_SaraCross_DPP (Gujarat)",
        FacilityCity: "Halvad",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20392,
        Pin: 625504,
        DispatchCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        OriginCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        FacilityCity: "Sivakasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20393,
        Pin: 142038,
        DispatchCenter: "Moga_Duneke_I (Punjab)",
        OriginCenter: "Moga_Duneke_I (Punjab)",
        FacilityCity: "Moga",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20394,
        Pin: 590003,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20395,
        Pin: 635108,
        DispatchCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        OriginCenter: "Krishnagiri_Bynapalli_I (Tamil Nadu)",
        FacilityCity: "Krishnagiri",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20396,
        Pin: 431530,
        DispatchCenter: "Parli_JirgeNgr_D (Maharashtra)",
        OriginCenter: "Parli_JirgeNgr_D (Maharashtra)",
        FacilityCity: "Parli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20397,
        Pin: 612402,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20398,
        Pin: 488001,
        DispatchCenter: "Panna_MPEBColony_DPP (Madhya Pradesh)",
        OriginCenter: "Panna_MPEBColony_DPP (Madhya Pradesh)",
        FacilityCity: "Panna",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20399,
        Pin: 365450,
        DispatchCenter: "Amreli_Kunkavav_DPP (Gujarat)",
        OriginCenter: "Amreli_Kunkavav_DPP (Gujarat)",
        FacilityCity: "Amreli",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20400,
        Pin: 713219,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20401,
        Pin: 140802,
        DispatchCenter: "SirhindFatehgarh_DC (Punjab)",
        OriginCenter: "SirhindFatehgarh_DC (Punjab)",
        FacilityCity: "Sirhind-Fategarh",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 20402,
        Pin: 518412,
        DispatchCenter: "Nandikotkur_Nandyalroad_D (Andhra Pradesh)",
        OriginCenter: "Nandikotkur_Nandyalroad_D (Andhra Pradesh)",
        FacilityCity: "Nandikotkur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20403,
        Pin: 380052,
        DispatchCenter: "Ahmedabad_Sanathal_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20404,
        Pin: 457777,
        DispatchCenter: "Petlawad_Unnai_D (Madhya Pradesh)",
        OriginCenter: "Petlawad_Unnai_D (Madhya Pradesh)",
        FacilityCity: "Petlawad",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20405,
        Pin: 591217,
        DispatchCenter: "Raibag_Sainagar_DPP (Karnataka)",
        OriginCenter: "Raibag_Sainagar_DPP (Karnataka)",
        FacilityCity: "Raibag",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20406,
        Pin: 570001,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20407,
        Pin: 382765,
        DispatchCenter: "Patdi_Laxminagar_DPP (Gujarat)",
        OriginCenter: "Patdi_Laxminagar_DPP (Gujarat)",
        FacilityCity: "Patdi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20408,
        Pin: 711309,
        DispatchCenter: "Howrah_Temp_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20409,
        Pin: 284305,
        DispatchCenter: "Chirgaon_RamnagarRoad_D (Uttar Pradesh)",
        OriginCenter: "Chirgaon_RamnagarRoad_D (Uttar Pradesh)",
        FacilityCity: "Chirgaon",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20410,
        Pin: 680007,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20411,
        Pin: 472447,
        DispatchCenter: "Prithvipur_Niwari_D (Madhya Pradesh)",
        OriginCenter: "Prithvipur_Niwari_D (Madhya Pradesh)",
        FacilityCity: "Prithvipur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20412,
        Pin: 226305,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20413,
        Pin: 845430,
        DispatchCenter: "Sheohar_Ward13_D (Bihar)",
        OriginCenter: "Sheohar_Ward13_D (Bihar)",
        FacilityCity: "Sheohar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20414,
        Pin: 757014,
        DispatchCenter: "Baripada_KalikDPP_D (Orissa)",
        OriginCenter: "Baripada_KalikDPP_D (Orissa)",
        FacilityCity: "Baripada",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20415,
        Pin: 415713,
        DispatchCenter: "Dapoli_Ghimhavane_D (Maharashtra)",
        OriginCenter: "Dapoli_Ghimhavane_D (Maharashtra)",
        FacilityCity: "Dapoli",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20416,
        Pin: 453111,
        DispatchCenter: "Indore_BarodaArjun_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20417,
        Pin: 535161,
        DispatchCenter: "SKota_VizainagaramRd_DPP (Andhra Pradesh)",
        OriginCenter: "SKota_VizainagaramRd_DPP (Andhra Pradesh)",
        FacilityCity: "S Kota",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20418,
        Pin: 785676,
        DispatchCenter: "Moranhat_TengaDollong_D (Assam)",
        OriginCenter: "Moranhat_TengaDollong_D (Assam)",
        FacilityCity: "Moranhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20419,
        Pin: 391776,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20420,
        Pin: 396135,
        DispatchCenter: "Vapi_Nahuli_H (Gujarat)",
        OriginCenter: "Vapi_Nahuli_H (Gujarat)",
        FacilityCity: "Vapi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20421,
        Pin: 691008,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20422,
        Pin: 847211,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20423,
        Pin: 735230,
        DispatchCenter: "Jalpaiguri_Lataguri_DPP (West Bengal)",
        OriginCenter: "Jalpaiguri_Lataguri_DPP (West Bengal)",
        FacilityCity: "Jalpaiguri",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20424,
        Pin: 322203,
        DispatchCenter: "Karauli_Agrawalnagar_D (Rajasthan)",
        OriginCenter: "Karauli_Agrawalnagar_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20425,
        Pin: 400068,
        DispatchCenter: "Mumbai_Kandivali_L (Maharashtra)",
        OriginCenter: "Mumbai_Kandivali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20426,
        Pin: 244301,
        DispatchCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        OriginCenter: "Moradabad_Menather_P (Uttar Pradesh)",
        FacilityCity: "Moradabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20427,
        Pin: 380008,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20428,
        Pin: 465680,
        DispatchCenter: "Pachore_Bhojpuriya_D (Madhya Pradesh)",
        OriginCenter: "Pachore_Bhojpuriya_D (Madhya Pradesh)",
        FacilityCity: "Pachore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20429,
        Pin: 500178,
        DispatchCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        OriginCenter: "Hyderabad_Ravindranagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20430,
        Pin: 516216,
        DispatchCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20431,
        Pin: 185101,
        DispatchCenter: "Poonch_Oldcity_D (Jammu & Kashmir)",
        OriginCenter: "Poonch_Oldcity_D (Jammu & Kashmir)",
        FacilityCity: "Poonch",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20432,
        Pin: 600025,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20433,
        Pin: 462018,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20434,
        Pin: 685611,
        DispatchCenter: "Adimali_AdimaliMkt_D (Kerala)",
        OriginCenter: "Adimali_AdimaliMkt_D (Kerala)",
        FacilityCity: "Adimali",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20435,
        Pin: 562157,
        DispatchCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        OriginCenter: "Bangalore_RKHegdeNagar_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20436,
        Pin: 673002,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20437,
        Pin: 571312,
        DispatchCenter: "Nanjangud_Deviramanahalli_D (Karnataka)",
        OriginCenter: "Nanjangud_Deviramanahalli_D (Karnataka)",
        FacilityCity: "Nanjangud",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20438,
        Pin: 382000,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20439,
        Pin: 362221,
        DispatchCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        OriginCenter: "Keshod_GasAgencyRd_D (Gujarat)",
        FacilityCity: "Keshod",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20440,
        Pin: 460661,
        DispatchCenter: "Multai_Birulbzr_D (Madhya Pradesh)",
        OriginCenter: "Multai_Birulbzr_D (Madhya Pradesh)",
        FacilityCity: "Multai",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20441,
        Pin: 686101,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20442,
        Pin: 632603,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20443,
        Pin: 521311,
        DispatchCenter: "Gannavaram_SH298_D (Andhra Pradesh)",
        OriginCenter: "Gannavaram_SH298_D (Andhra Pradesh)",
        FacilityCity: "Gannavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20444,
        Pin: 700101,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20445,
        Pin: 394380,
        DispatchCenter: "Rumkitalav_Kukarmunda1_DPP (Gujarat)",
        OriginCenter: "Rumkitalav_Kukarmunda1_DPP (Gujarat)",
        FacilityCity: "Rumkitalav",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20446,
        Pin: 503114,
        DispatchCenter: "Padmajiwadi_Gandhari_D (Telangana)",
        OriginCenter: "Padmajiwadi_Gandhari_D (Telangana)",
        FacilityCity: "Padmajiwadi",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20447,
        Pin: 151202,
        DispatchCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        OriginCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        FacilityCity: "Bathinda",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20448,
        Pin: 322001,
        DispatchCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        OriginCenter: "SawaiMadhopur_Kherda_D (Rajasthan)",
        FacilityCity: "Sawai Madhopur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20449,
        Pin: 382017,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20450,
        Pin: 591266,
        DispatchCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        OriginCenter: "Athani_Sangmeshnagar_D (Karnataka)",
        FacilityCity: "Athani",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20451,
        Pin: 444904,
        DispatchCenter: "Amravati_Sashinagar_D (Maharashtra)",
        OriginCenter: "Amravati_Sashinagar_D (Maharashtra)",
        FacilityCity: "Amravati",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20452,
        Pin: 123305,
        DispatchCenter: "Rewari_Kosli_D (Haryana)",
        OriginCenter: "Rewari_Kosli_D (Haryana)",
        FacilityCity: "Jhajjar",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20453,
        Pin: 612703,
        DispatchCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        OriginCenter: "Kumbakonam_Melacavery_D (Tamil Nadu)",
        FacilityCity: "Kumbakonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20454,
        Pin: 202139,
        DispatchCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        OriginCenter: "Hathras_NayeeNangla_D (Uttar Pradesh)",
        FacilityCity: "Hathras",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20455,
        Pin: 413532,
        DispatchCenter: "Mukhed_AmbedkarChowk_DPP (Maharashtra)",
        OriginCenter: "Mukhed_AmbedkarChowk_DPP (Maharashtra)",
        FacilityCity: "Mukhed",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20456,
        Pin: 285129,
        DispatchCenter: "Jalaun_Madhogarh_D (Uttar Pradesh)",
        OriginCenter: "Jalaun_Madhogarh_D (Uttar Pradesh)",
        FacilityCity: "Jalaun",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20457,
        Pin: 563114,
        DispatchCenter: "Bangarapet_AMPCClny_D (Karnataka)",
        OriginCenter: "Bangarapet_AMPCClny_D (Karnataka)",
        FacilityCity: "Bangarapet",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20458,
        Pin: 221719,
        DispatchCenter: "Ballia_Sikandarpur_D (Uttar Pradesh)",
        OriginCenter: "Ballia_Sikandarpur_D (Uttar Pradesh)",
        FacilityCity: "Ballia",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20459,
        Pin: 676542,
        DispatchCenter: "Edavanna_MNRd_D (Kerala)",
        OriginCenter: "Edavanna_MNRd_D (Kerala)",
        FacilityCity: "Edavanna",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20460,
        Pin: 441702,
        DispatchCenter: "SadakArjuni_Central_D (Maharashtra)",
        OriginCenter: "SadakArjuni_Central_D (Maharashtra)",
        FacilityCity: "Sadak Arjuni",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20461,
        Pin: 826006,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20462,
        Pin: 423210,
        DispatchCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        OriginCenter: "Malegaon_JyotiNgr_D (Maharashtra)",
        FacilityCity: "Malegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20463,
        Pin: 855101,
        DispatchCenter: "Bahadurganj_KrsnaMndr_D (Bihar)",
        OriginCenter: "Bahadurganj_KrsnaMndr_D (Bihar)",
        FacilityCity: "Bahadurganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20464,
        Pin: 689106,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20465,
        Pin: 506165,
        DispatchCenter: "Tadvai_Akulavarighanpur_D (Telangana)",
        OriginCenter: "Tadvai_Akulavarighanpur_D (Telangana)",
        FacilityCity: "Tadvai",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20466,
        Pin: 521241,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20467,
        Pin: 523368,
        DispatchCenter: "Giddalur_Nandyalroad_D (Andhra Pradesh)",
        OriginCenter: "Giddalur_Nandyalroad_D (Andhra Pradesh)",
        FacilityCity: "Giddalur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20468,
        Pin: 136030,
        DispatchCenter: "Kaithal_DefenceColony_D (Haryana)",
        OriginCenter: "Kaithal_DefenceColony_D (Haryana)",
        FacilityCity: "Kaithal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20469,
        Pin: 841420,
        DispatchCenter: "Mashrakh_Benchapra_D (Bihar)",
        OriginCenter: "Mashrakh_Benchapra_D (Bihar)",
        FacilityCity: "Mashrakh",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20470,
        Pin: 573126,
        DispatchCenter: "Arsikere_JenukalTemple_D (Karnataka)",
        OriginCenter: "Arsikere_JenukalTemple_D (Karnataka)",
        FacilityCity: "Arsikere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20471,
        Pin: 600064,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20472,
        Pin: 799115,
        DispatchCenter: "Udaipur_Sonamura_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Udaipur-TR",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20473,
        Pin: 624613,
        DispatchCenter: "Palani_Sathyanagar_D (Tamil Nadu)",
        OriginCenter: "Palani_Sathyanagar_D (Tamil Nadu)",
        FacilityCity: "Palani",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20474,
        Pin: 600080,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20475,
        Pin: 400110,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20476,
        Pin: 123401,
        DispatchCenter: "Gurgaon_Bhorakalan_L (Haryana)",
        OriginCenter: "Gurgaon_Tauru_GW (Haryana)",
        FacilityCity: "Bilaspur-HR",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20477,
        Pin: 440003,
        DispatchCenter: "Nagpur_Chikhali_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20478,
        Pin: 364330,
        DispatchCenter: "Sihor_InfinityMall_D (Gujarat)",
        OriginCenter: "Sihor_InfinityMall_D (Gujarat)",
        FacilityCity: "Sihor",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20479,
        Pin: 247664,
        DispatchCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        OriginCenter: "Haridwar_Bahadrabad_L (Uttarakhand)",
        FacilityCity: "Haridwar",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20480,
        Pin: 466448,
        DispatchCenter: "Bareli_Wazirganj_DPP (Madhya Pradesh)",
        OriginCenter: "Bareli_Wazirganj_DPP (Madhya Pradesh)",
        FacilityCity: "Bareli",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20481,
        Pin: 422010,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20482,
        Pin: 444102,
        DispatchCenter: "Akola_Midcphase3_H (Maharashtra)",
        OriginCenter: "Akola_Midcphase3_H (Maharashtra)",
        FacilityCity: "Akola",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20483,
        Pin: 332402,
        DispatchCenter: "Bajore_Sheeshyoo_DPP (Rajasthan)",
        OriginCenter: "Bajore_Sheeshyoo_DPP (Rajasthan)",
        FacilityCity: "Bajore",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20484,
        Pin: 691507,
        DispatchCenter: "Kollam_Kilikollur_H (Kerala)",
        OriginCenter: "Kollam_Kilikollur_H (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20485,
        Pin: 301031,
        DispatchCenter: "Alwar_TuleraRoad_I (Rajasthan)",
        OriginCenter: "Alwar_TuleraRoad_I (Rajasthan)",
        FacilityCity: "Alwar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20486,
        Pin: 754120,
        DispatchCenter: "Paradip_Udyabata_D (Orissa)",
        OriginCenter: "Paradip_Udyabata_D (Orissa)",
        FacilityCity: "Paradip",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20487,
        Pin: 673651,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20488,
        Pin: 623517,
        DispatchCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        OriginCenter: "Ramnthpurm_TnhbBlkC_D (Tamil Nadu)",
        FacilityCity: "Ramanathapuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20489,
        Pin: 532190,
        DispatchCenter: "Palakonda_VadaiDPP_D (Andhra Pradesh)",
        OriginCenter: "Palakonda_VadaiDPP_D (Andhra Pradesh)",
        FacilityCity: "Palakonda",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20490,
        Pin: 785609,
        DispatchCenter: "Bokakhat_Kumaraniati_D (Assam)",
        OriginCenter: "Bokakhat_Kumaraniati_D (Assam)",
        FacilityCity: "Bokakhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20491,
        Pin: 723126,
        DispatchCenter: "Para_Dubra_DPP (West Bengal)",
        OriginCenter: "Para_Dubra_DPP (West Bengal)",
        FacilityCity: "Para",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20492,
        Pin: 678615,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20493,
        Pin: 581349,
        DispatchCenter: "Mundgod_BankapuraRd_DPP (Karnataka)",
        OriginCenter: "Mundgod_BankapuraRd_DPP (Karnataka)",
        FacilityCity: "Mundgod",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20494,
        Pin: 585312,
        DispatchCenter: "Chittapur_GlbrgaRD_D (Karnataka)",
        OriginCenter: "Chittapur_GlbrgaRD_D (Karnataka)",
        FacilityCity: "Chittapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20495,
        Pin: 272176,
        DispatchCenter: "Dhanghata_MarhaRaja_DPP (Uttar Pradesh)",
        OriginCenter: "Dhanghata_MarhaRaja_DPP (Uttar Pradesh)",
        FacilityCity: "Dhanghata",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20496,
        Pin: 680552,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20497,
        Pin: 486550,
        DispatchCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        OriginCenter: "Rewa_Khairi_D (Madhya Pradesh)",
        FacilityCity: "Rewa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20498,
        Pin: 110004,
        DispatchCenter: "Delhi_KirtiNagar_L (Delhi)",
        OriginCenter: "Delhi_KirtiNagar_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20499,
        Pin: 743004,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20500,
        Pin: 442917,
        DispatchCenter: "Chandrapur_Gadchandur_DPP (Maharashtra)",
        OriginCenter: "Chandrapur_Gadchandur_DPP (Maharashtra)",
        FacilityCity: "Chandrapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20501,
        Pin: 492003,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20502,
        Pin: 700039,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20503,
        Pin: 262410,
        DispatchCenter: "Nawabganj_Bhairav_D (Uttar Pradesh)",
        OriginCenter: "Nawabganj_Bhairav_D (Uttar Pradesh)",
        FacilityCity: "Nawabganj-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20504,
        Pin: 577211,
        DispatchCenter: "Shimoga_Eshwariah_I (Karnataka)",
        OriginCenter: "Shimoga_Eshwariah_I (Karnataka)",
        FacilityCity: "Shimoga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20505,
        Pin: 757016,
        DispatchCenter: "Morada_Malihata_D (Orissa)",
        OriginCenter: "Morada_Malihata_D (Orissa)",
        FacilityCity: "Morada",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20506,
        Pin: 506317,
        DispatchCenter: "Ghanpur_Palakrty_D (Telangana)",
        OriginCenter: "Ghanpur_Palakrty_D (Telangana)",
        FacilityCity: "Ghanpur",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20507,
        Pin: 691514,
        DispatchCenter: "Kottarakkara_MythriNagar_D (Kerala)",
        OriginCenter: "Kottarakkara_MythriNagar_D (Kerala)",
        FacilityCity: "Kottarakkara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20508,
        Pin: 637104,
        DispatchCenter: "Sankari_Idappadi_D (Tamil Nadu)",
        OriginCenter: "Sankari_Idappadi_D (Tamil Nadu)",
        FacilityCity: "Sankari",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20509,
        Pin: 303315,
        DispatchCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        OriginCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        FacilityCity: "Bandikui",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20510,
        Pin: 629176,
        DispatchCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        OriginCenter: "Marthandam_Knchiracode_D (Tamil Nadu)",
        FacilityCity: "Marthandam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20511,
        Pin: 533289,
        DispatchCenter: "Gokavaram_MuraliNagar_D (Andhra Pradesh)",
        OriginCenter: "Gokavaram_MuraliNagar_D (Andhra Pradesh)",
        FacilityCity: "Gokavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20512,
        Pin: 637406,
        DispatchCenter: "Rasipurm_Knrpati_D (Tamil Nadu)",
        OriginCenter: "Rasipurm_Knrpati_D (Tamil Nadu)",
        FacilityCity: "Rasipuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20513,
        Pin: 361305,
        DispatchCenter: "Khambhalia_Dharampur_D (Gujarat)",
        OriginCenter: "Khambhalia_Dharampur_D (Gujarat)",
        FacilityCity: "Khambhalia",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20514,
        Pin: 505210,
        DispatchCenter: "Mancherial_Nuspur_I (Telangana)",
        OriginCenter: "Mancherial_Nuspur_I (Telangana)",
        FacilityCity: "Mancherial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20515,
        Pin: 122018,
        DispatchCenter: "Gurgaon_Sector52_L (Haryana)",
        OriginCenter: "Gurgaon_Sector52_L (Haryana)",
        FacilityCity: "Gurgaon",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20516,
        Pin: 635302,
        DispatchCenter: "Harur_EBOfc_D (Tamil Nadu)",
        OriginCenter: "Harur_EBOfc_D (Tamil Nadu)",
        FacilityCity: "Harur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20517,
        Pin: 743405,
        DispatchCenter: "Bongaon_SukntDPP_D (West Bengal)",
        OriginCenter: "Bongaon_SukntDPP_D (West Bengal)",
        FacilityCity: "Bongaon",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20518,
        Pin: 486441,
        DispatchCenter: "Raipurkarchulion_Joginhai_D (Madhya Pradesh)",
        OriginCenter: "Raipurkarchulion_Joginhai_D (Madhya Pradesh)",
        FacilityCity: "Raipur Karchulion",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20519,
        Pin: 450445,
        DispatchCenter: "Burhanpur_StRoad_D (Madhya Pradesh)",
        OriginCenter: "Burhanpur_StRoad_D (Madhya Pradesh)",
        FacilityCity: "Burhanpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20520,
        Pin: 335703,
        DispatchCenter: "Srivijaynagar_Ward8_DPP (Rajasthan)",
        OriginCenter: "Srivijaynagar_Ward8_DPP (Rajasthan)",
        FacilityCity: "Srivijaynagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20521,
        Pin: 516339,
        DispatchCenter: "Cuddapah_Peddacheppalle_D (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Peddacheppalle_D (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20522,
        Pin: 825318,
        DispatchCenter: "Domchanch_Koderma_D (Jharkhand)",
        OriginCenter: "Domchanch_Koderma_D (Jharkhand)",
        FacilityCity: "Domchanch",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20523,
        Pin: 767018,
        DispatchCenter: "Birmaharajpur_Sandha_D (Orissa)",
        OriginCenter: "Birmaharajpur_Sandha_D (Orissa)",
        FacilityCity: "Birmaharajpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20524,
        Pin: 721452,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20525,
        Pin: 743250,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20526,
        Pin: 700151,
        DispatchCenter: "Kolkata_Nazirabad_L (West Bengal)",
        OriginCenter: "Kolkata_Nazirabad_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20527,
        Pin: 413303,
        DispatchCenter: "Pandharpur_RajCmplx_D (Maharashtra)",
        OriginCenter: "Pandharpur_RajCmplx_D (Maharashtra)",
        FacilityCity: "Pandharpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20528,
        Pin: 311030,
        DispatchCenter: "Raila_PoliceSt_DPP (Rajasthan)",
        OriginCenter: "Raila_PoliceSt_DPP (Rajasthan)",
        FacilityCity: "Raila",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20529,
        Pin: 852161,
        DispatchCenter: "SonbarsaBH_Pirnagra_DPP (Bihar)",
        OriginCenter: "SonbarsaBH_Pirnagra_DPP (Bihar)",
        FacilityCity: "Sonbarsa-BH",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20530,
        Pin: 506003,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20531,
        Pin: 401208,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20532,
        Pin: 136156,
        DispatchCenter: "Kurukshetra_DDColony_D (Haryana)",
        OriginCenter: "Kurukshetra_DDColony_D (Haryana)",
        FacilityCity: "Kurukshetra",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20533,
        Pin: 754214,
        DispatchCenter: "Kendrapara_Chhagharia_D (Orissa)",
        OriginCenter: "Kendrapara_Chhagharia_D (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20534,
        Pin: 232328,
        DispatchCenter: "Gahmar_KhuduraPthra_DPP (Uttar Pradesh)",
        OriginCenter: "Gahmar_KhuduraPthra_DPP (Uttar Pradesh)",
        FacilityCity: "Gahmar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20535,
        Pin: 331802,
        DispatchCenter: "Ratangarh_Goldencolony_D (Rajasthan)",
        OriginCenter: "Ratangarh_Goldencolony_D (Rajasthan)",
        FacilityCity: "Ratangarh",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20536,
        Pin: 673636,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20537,
        Pin: 841207,
        DispatchCenter: "Dighwara_AmbikaSchl_D (Bihar)",
        OriginCenter: "Dighwara_AmbikaSchl_D (Bihar)",
        FacilityCity: "Dighwara",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20538,
        Pin: 464671,
        DispatchCenter: "Bareli_SourvDPP_D (Madhya Pradesh)",
        OriginCenter: "Bareli_SourvDPP_D (Madhya Pradesh)",
        FacilityCity: "Bareli",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20539,
        Pin: 221006,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20540,
        Pin: 445205,
        DispatchCenter: "Mahagaon_Central_DPP_1 (Maharashtra)",
        OriginCenter: "Mahagaon_Central_DPP_1 (Maharashtra)",
        FacilityCity: "Mahagaon",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20541,
        Pin: 680027,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20542,
        Pin: 680693,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20543,
        Pin: 205141,
        DispatchCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        OriginCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        FacilityCity: "Shikohabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20544,
        Pin: 441301,
        DispatchCenter: "Katol_Laxmingr_D (Maharashtra)",
        OriginCenter: "Katol_Laxmingr_D (Maharashtra)",
        FacilityCity: "Katol",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20545,
        Pin: 271871,
        DispatchCenter: "Bahraich_Dularpur_H (Uttar Pradesh)",
        OriginCenter: "Bahraich_Dularpur_H (Uttar Pradesh)",
        FacilityCity: "Bahraich",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20546,
        Pin: 680501,
        DispatchCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        OriginCenter: "Chiramanangad_ConcordSchl_D (Kerala)",
        FacilityCity: "Chiramanangad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20547,
        Pin: 848220,
        DispatchCenter: "DalsinghSarai_Bachhawara_D (Bihar)",
        OriginCenter: "DalsinghSarai_Bachhawara_D (Bihar)",
        FacilityCity: "Dalsingh Sarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20548,
        Pin: 620010,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20549,
        Pin: 854201,
        DispatchCenter: "Araria_Jamunghat_D (Bihar)",
        OriginCenter: "Araria_Jamunghat_D (Bihar)",
        FacilityCity: "Araria",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20550,
        Pin: 785619,
        DispatchCenter: "Dergaon_Rojabahar_DPP (Assam)",
        OriginCenter: "Dergaon_Rojabahar_DPP (Assam)",
        FacilityCity: "Dergaon",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20551,
        Pin: 441601,
        DispatchCenter: "Gondia_Mundipar_I (Maharashtra)",
        OriginCenter: "Gondia_Mundipar_I (Maharashtra)",
        FacilityCity: "Gondia",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20552,
        Pin: 486881,
        DispatchCenter: "Deosar_Samda_D (Madhya Pradesh)",
        OriginCenter: "Deosar_Samda_D (Madhya Pradesh)",
        FacilityCity: "Deosar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20553,
        Pin: 244255,
        DispatchCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        OriginCenter: "Anupshahar_Nehruganj_D (Uttar Pradesh)",
        FacilityCity: "Anupshahar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20554,
        Pin: 506355,
        DispatchCenter: "Cherial_Vchreni_D (Telangana)",
        OriginCenter: "Cherial_Vchreni_D (Telangana)",
        FacilityCity: "Cherial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20555,
        Pin: 670602,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20556,
        Pin: 591264,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20557,
        Pin: 160034,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 20558,
        Pin: 641025,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20559,
        Pin: 609205,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20560,
        Pin: 486111,
        DispatchCenter: "Raipurkarchulion_Joginhai_D (Madhya Pradesh)",
        OriginCenter: "Raipurkarchulion_Joginhai_D (Madhya Pradesh)",
        FacilityCity: "Raipur Karchulion",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20561,
        Pin: 845103,
        DispatchCenter: "Narkatiaganj_Sonkhar_D (Bihar)",
        OriginCenter: "Narkatiaganj_Sonkhar_D (Bihar)",
        FacilityCity: "Narkatiaganj",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20562,
        Pin: 182315,
        DispatchCenter: "Ramban_Govindpura_D (Jammu & Kashmir)",
        OriginCenter: "Ramban_Govindpura_D (Jammu & Kashmir)",
        FacilityCity: "Ramban",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 20563,
        Pin: 483105,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20564,
        Pin: 324006,
        DispatchCenter: "Kota_Govindnagar_H (Rajasthan)",
        OriginCenter: "Kota_Govindnagar_H (Rajasthan)",
        FacilityCity: "Kota",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20565,
        Pin: 394315,
        DispatchCenter: "Surat_Kadodara_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20566,
        Pin: 485221,
        DispatchCenter: "Jaitwara_Kothi_DPP (Madhya Pradesh)",
        OriginCenter: "Jaitwara_Kothi_DPP (Madhya Pradesh)",
        FacilityCity: "Jaitwara",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20567,
        Pin: 533577,
        DispatchCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        OriginCenter: "Amalapuram_KNFrd_D (Andhra Pradesh)",
        FacilityCity: "Amalapuram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20568,
        Pin: 689578,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20569,
        Pin: 605106,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20570,
        Pin: 627358,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20571,
        Pin: 722157,
        DispatchCenter: "Sonamukhi_Central_DPP_1 (West Bengal)",
        OriginCenter: "Sonamukhi_Central_DPP_1 (West Bengal)",
        FacilityCity: "Sonamukhi",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20572,
        Pin: 441804,
        DispatchCenter: "Sakoli_GaneshWard_D (Maharashtra)",
        OriginCenter: "Sakoli_GaneshWard_D (Maharashtra)",
        FacilityCity: "Sakoli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20573,
        Pin: 229302,
        DispatchCenter: "Raebareli_Ratapur_D (Uttar Pradesh)",
        OriginCenter: "Raebareli_Ratapur_D (Uttar Pradesh)",
        FacilityCity: "Raebareli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20574,
        Pin: 821107,
        DispatchCenter: "Kochas_Parsiya_D (Bihar)",
        OriginCenter: "Kochas_Parsiya_D (Bihar)",
        FacilityCity: "Kochas",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20575,
        Pin: 711206,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20576,
        Pin: 606755,
        DispatchCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        OriginCenter: "Tiruvanamalai_VelNagar_D (Tamil Nadu)",
        FacilityCity: "Tiruvannamalai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20577,
        Pin: 614014,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20578,
        Pin: 688537,
        DispatchCenter: "Cochin_Aroor_D (Kerala)",
        OriginCenter: "Cochin_Aroor_D (Kerala)",
        FacilityCity: "Ezhupunna",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20579,
        Pin: 605111,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20580,
        Pin: 444403,
        DispatchCenter: "Mangrulpir_PostOffice_DPP (Maharashtra)",
        OriginCenter: "Mangrulpir_PostOffice_DPP (Maharashtra)",
        FacilityCity: "Mangrulpir",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20581,
        Pin: 277205,
        DispatchCenter: "Bairia_Bhagwanpur_DPP (Uttar Pradesh)",
        OriginCenter: "Bairia_Bhagwanpur_DPP (Uttar Pradesh)",
        FacilityCity: "Bariya",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20582,
        Pin: 688584,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20583,
        Pin: 611106,
        DispatchCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        OriginCenter: "Nagapattinam_Kadambadi_D (Tamil Nadu)",
        FacilityCity: "Nagapattinam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20584,
        Pin: 422011,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20585,
        Pin: 534312,
        DispatchCenter: "Jangareddygudem_RTC_D (Andhra Pradesh)",
        OriginCenter: "Jangareddygudem_RTC_D (Andhra Pradesh)",
        FacilityCity: "Jangareddygudem",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20586,
        Pin: 574129,
        DispatchCenter: "Karkala_JodursteJ_D (Karnataka)",
        OriginCenter: "Karkala_JodursteJ_D (Karnataka)",
        FacilityCity: "Karkala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20587,
        Pin: 242401,
        DispatchCenter: "Khutar_Narayanpur_D (Uttar Pradesh)",
        OriginCenter: "Khutar_Narayanpur_D (Uttar Pradesh)",
        FacilityCity: "Khutar",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20588,
        Pin: 735212,
        DispatchCenter: "Birpara_Binnaguri_D (West Bengal)",
        OriginCenter: "Birpara_Binnaguri_D (West Bengal)",
        FacilityCity: "Birpara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20589,
        Pin: 686676,
        DispatchCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        OriginCenter: "Muvattupuzha_Anicadu_D (Kerala)",
        FacilityCity: "Muvattupuzha",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20590,
        Pin: 222180,
        DispatchCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        OriginCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        FacilityCity: "Jaunpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20591,
        Pin: 505451,
        DispatchCenter: "KarimNagar_Alugunoor_I (Telangana)",
        OriginCenter: "KarimNagar_Alugunoor_I (Telangana)",
        FacilityCity: "Karim Nagar",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20592,
        Pin: 313021,
        DispatchCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        OriginCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        FacilityCity: "Chittaurgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20593,
        Pin: 689109,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20594,
        Pin: 442202,
        DispatchCenter: "Talegaon_Ramdara_D (Maharashtra)",
        OriginCenter: "Talegaon_Ramdara_D (Maharashtra)",
        FacilityCity: "Talegaon Sp",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20595,
        Pin: 586208,
        DispatchCenter: "Talikoti_GaneshNagar_D (Karnataka)",
        OriginCenter: "Talikoti_GaneshNagar_D (Karnataka)",
        FacilityCity: "Talikoti",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20596,
        Pin: 388320,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20597,
        Pin: 535128,
        DispatchCenter: "Garividi_SriRamNagar_D (Andhra Pradesh)",
        OriginCenter: "Garividi_SriRamNagar_D (Andhra Pradesh)",
        FacilityCity: "Garividi",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20598,
        Pin: 531035,
        DispatchCenter: "Anakapalle_Thumapala_D (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Anakapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20599,
        Pin: 403729,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20600,
        Pin: 638455,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20601,
        Pin: 466112,
        DispatchCenter: "Sehore_Sherpur_D (Madhya Pradesh)",
        OriginCenter: "Sehore_Sherpur_D (Madhya Pradesh)",
        FacilityCity: "Sehore",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20602,
        Pin: 328022,
        DispatchCenter: "Bari_MaharajBaghColony_D (Rajasthan)",
        OriginCenter: "Bari_MaharajBaghColony_D (Rajasthan)",
        FacilityCity: "Bari",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20603,
        Pin: 678502,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20604,
        Pin: 201302,
        DispatchCenter: "Ghaziabad_Indirapuram_L (Uttar Pradesh)",
        OriginCenter: "Noida_Bairangpur_GW (Uttar Pradesh)",
        FacilityCity: "Ghaziabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20605,
        Pin: 851110,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20606,
        Pin: 603209,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20607,
        Pin: 802158,
        DispatchCenter: "Jagdishpur_Ward14_D (Bihar)",
        OriginCenter: "Jagdishpur_Ward14_D (Bihar)",
        FacilityCity: "Jagdishpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20608,
        Pin: 334202,
        DispatchCenter: "Bikaner_Deshnok_D (Rajasthan)",
        OriginCenter: "Bikaner_Deshnok_D (Rajasthan)",
        FacilityCity: "Bikaner",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20609,
        Pin: 636309,
        DispatchCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        OriginCenter: "Salem_Ramalingapuram_H (Tamil Nadu)",
        FacilityCity: "Salem",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20610,
        Pin: 591287,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20611,
        Pin: 713383,
        DispatchCenter: "Meija_School_D (West Bengal)",
        OriginCenter: "Meija_School_D (West Bengal)",
        FacilityCity: "Mejia",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20612,
        Pin: 455002,
        DispatchCenter: "Indore_BarodaArjun_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20613,
        Pin: 626188,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20614,
        Pin: 395001,
        DispatchCenter: "Surat_Kacholi_L (Gujarat)",
        OriginCenter: "Surat_Kacholi_GW (Gujarat)",
        FacilityCity: "Surat",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20615,
        Pin: 302003,
        DispatchCenter: "Jaipur_TransportNgr_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20616,
        Pin: 695009,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20617,
        Pin: 795148,
        DispatchCenter: "Imphal_Wangjing_D (Manipur)",
        OriginCenter: "Imphal_Wangjing_D (Manipur)",
        FacilityCity: "Imphal",
        FacilityState: "Manipur",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20618,
        Pin: 134111,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20619,
        Pin: 690555,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20620,
        Pin: 797110,
        DispatchCenter: "Chumukeidma_Jalukie_D (Nagaland)",
        OriginCenter: "Chumukeidma_Jalukie_D (Nagaland)",
        FacilityCity: "Chumukeidma",
        FacilityState: "Nagaland",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20621,
        Pin: 632107,
        DispatchCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        OriginCenter: "Gudiyattam_KTPRoad_D (Tamil Nadu)",
        FacilityCity: "Gudiyattam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20622,
        Pin: 845502,
        DispatchCenter: "Chakia_Honda_D (Bihar)",
        OriginCenter: "Chakia_Honda_D (Bihar)",
        FacilityCity: "Chakia",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20623,
        Pin: 486445,
        DispatchCenter: "Semariya_Shuklacolony_DPP (Madhya Pradesh)",
        OriginCenter: "Semariya_Shuklacolony_DPP (Madhya Pradesh)",
        FacilityCity: "Semariya",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20624,
        Pin: 335771,
        DispatchCenter: "Gharsana_Jaatcolony_D (Rajasthan)",
        OriginCenter: "Gharsana_Jaatcolony_D (Rajasthan)",
        FacilityCity: "Gharsana",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20625,
        Pin: 676319,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20626,
        Pin: 442904,
        DispatchCenter: "Chimur_KrushiBazar_DPP (Maharashtra)",
        OriginCenter: "Chimur_KrushiBazar_DPP (Maharashtra)",
        FacilityCity: "Chimur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20627,
        Pin: 643232,
        DispatchCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        OriginCenter: "Coonoor_Vannarpet_D (Tamil Nadu)",
        FacilityCity: "Coonoor",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20628,
        Pin: 361010,
        DispatchCenter: "Khambhalia_Dharampur_D (Gujarat)",
        OriginCenter: "Khambhalia_Dharampur_D (Gujarat)",
        FacilityCity: "Khambhalia",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20629,
        Pin: 313205,
        DispatchCenter: "Fatehnagar_ITALI_DPP (Rajasthan)",
        OriginCenter: "Fatehnagar_ITALI_DPP (Rajasthan)",
        FacilityCity: "Fatehnagar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20630,
        Pin: 612602,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20631,
        Pin: 176102,
        DispatchCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        OriginCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        FacilityCity: "Palampur",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20632,
        Pin: 786003,
        DispatchCenter: "Dibrugarh_Mohanbari_H (Assam)",
        OriginCenter: "Dibrugarh_Mohanbari_H (Assam)",
        FacilityCity: "Dibrugarh",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20633,
        Pin: 431102,
        DispatchCenter: "Aurangabad_Hamalwadi_DPP (Maharashtra)",
        OriginCenter: "Aurangabad_Hamalwadi_DPP (Maharashtra)",
        FacilityCity: "Aurangabad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20634,
        Pin: 134118,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20635,
        Pin: 793011,
        DispatchCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        OriginCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20636,
        Pin: 572117,
        DispatchCenter: "Tumkur_Doddapete_DPP (Karnataka)",
        OriginCenter: "Tumkur_Doddapete_DPP (Karnataka)",
        FacilityCity: "Tumkur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20637,
        Pin: 283122,
        DispatchCenter: "Kirauli_AchneraRD_D (Uttar Pradesh)",
        OriginCenter: "Kirauli_AchneraRD_D (Uttar Pradesh)",
        FacilityCity: "Kirauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20638,
        Pin: 465331,
        DispatchCenter: "Shujalpur_JNSCollege_D (Madhya Pradesh)",
        OriginCenter: "Shujalpur_JNSCollege_D (Madhya Pradesh)",
        FacilityCity: "Shujalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20639,
        Pin: 531161,
        DispatchCenter: "Bheemunipatnam_PstOfc_D (Andhra Pradesh)",
        OriginCenter: "Bheemunipatnam_PstOfc_D (Andhra Pradesh)",
        FacilityCity: "Bheemunipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20640,
        Pin: 342303,
        DispatchCenter: "Mathania_Vishvakarmanagar_D (Rajasthan)",
        OriginCenter: "Mathania_Vishvakarmanagar_D (Rajasthan)",
        FacilityCity: "Mathania",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20641,
        Pin: 630562,
        DispatchCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        OriginCenter: "Sivaganga_Wardno3_D (Tamil Nadu)",
        FacilityCity: "Sivaganga",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20642,
        Pin: 605701,
        DispatchCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        OriginCenter: "Thirukoilure_Manampoondi_D (Tamil Nadu)",
        FacilityCity: "Thirukoilure",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20643,
        Pin: 413602,
        DispatchCenter: "Naldurg_RamliaNagar_D (Maharashtra)",
        OriginCenter: "Naldurg_RamliaNagar_D (Maharashtra)",
        FacilityCity: "Naldurg",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20644,
        Pin: 712602,
        DispatchCenter: "Arambag_BalibDPP_D (West Bengal)",
        OriginCenter: "Arambag_BalibDPP_D (West Bengal)",
        FacilityCity: "Arambag",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20645,
        Pin: 690569,
        DispatchCenter: "Kollam_Karunagappally_D (Kerala)",
        OriginCenter: "Kollam_Karunagappally_D (Kerala)",
        FacilityCity: "Kollam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20646,
        Pin: 591201,
        DispatchCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        OriginCenter: "Chikodi_Prabhuwadi_D (Karnataka)",
        FacilityCity: "Chikodi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20647,
        Pin: 335022,
        DispatchCenter: "Ganganagar_Ricco_D (Rajasthan)",
        OriginCenter: "Ganganagar_Ricco_D (Rajasthan)",
        FacilityCity: "Ganga Nagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20648,
        Pin: 476229,
        DispatchCenter: "Sabalgarh_SunherraRd_D (Madhya Pradesh)",
        OriginCenter: "Sabalgarh_SunherraRd_D (Madhya Pradesh)",
        FacilityCity: "Sabalgarh",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20649,
        Pin: 523189,
        DispatchCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        OriginCenter: "Chirala_PeralaRd_D (Andhra Pradesh)",
        FacilityCity: "Chirala",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20650,
        Pin: 722178,
        DispatchCenter: "Bankura_Chhatna_DPP (West Bengal)",
        OriginCenter: "Bankura_Chhatna_DPP (West Bengal)",
        FacilityCity: "Bankura",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20651,
        Pin: 400012,
        DispatchCenter: "Mumbai_Sewri_L (Maharashtra)",
        OriginCenter: "Mumbai_Sewri_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20652,
        Pin: 304021,
        DispatchCenter: "Tonk_Bairwa_D (Rajasthan)",
        OriginCenter: "Tonk_Bairwa_D (Rajasthan)",
        FacilityCity: "Tonk",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20653,
        Pin: 841442,
        DispatchCenter: "Malmaliya_Piarepur_D (Bihar)",
        OriginCenter: "Malmaliya_Piarepur_D (Bihar)",
        FacilityCity: "Malmaliya",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20654,
        Pin: 581120,
        DispatchCenter: "Byadagi_Basaveshwara_D (Karnataka)",
        OriginCenter: "Byadagi_Basaveshwara_D (Karnataka)",
        FacilityCity: "Byadagi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20655,
        Pin: 576224,
        DispatchCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        OriginCenter: "Kundapura_Vaderhobli_D (Karnataka)",
        FacilityCity: "Kundapura",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20656,
        Pin: 845304,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20657,
        Pin: 521133,
        DispatchCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        OriginCenter: "Machilipatnam_Chilakalapudi_D (Andhra Pradesh)",
        FacilityCity: "Machilipatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20658,
        Pin: 533408,
        DispatchCenter: "Kathipudi_Shankhavaram_D (Andhra Pradesh)",
        OriginCenter: "Kathipudi_Shankhavaram_D (Andhra Pradesh)",
        FacilityCity: "Annavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20659,
        Pin: 160020,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20660,
        Pin: 802160,
        DispatchCenter: "Arrah_Sakaddi_D (Bihar)",
        OriginCenter: "Arrah_Sakaddi_D (Bihar)",
        FacilityCity: "Arrah",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20661,
        Pin: 571187,
        DispatchCenter: "Kushalnagar_BapujiExtension_D (Karnataka)",
        OriginCenter: "Kushalnagar_BapujiExtension_D (Karnataka)",
        FacilityCity: "Kushalnagar",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20662,
        Pin: 224151,
        DispatchCenter: "Akbarpur_AmbedkarNgr_D (Uttar Pradesh)",
        OriginCenter: "Akbarpur_AmbedkarNgr_D (Uttar Pradesh)",
        FacilityCity: "Akbarpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20663,
        Pin: 144201,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20664,
        Pin: 534318,
        DispatchCenter: "Polavaram_Collageroad_DPP (Andhra Pradesh)",
        OriginCenter: "Polavaram_Collageroad_DPP (Andhra Pradesh)",
        FacilityCity: "Polavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20665,
        Pin: 533428,
        DispatchCenter: "Addateegala_Thimapuram_D (Andhra Pradesh)",
        OriginCenter: "Addateegala_Thimapuram_D (Andhra Pradesh)",
        FacilityCity: "Addateegala",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20666,
        Pin: 210341,
        DispatchCenter: "HamirpurUP_Maudaha_D (Uttar Pradesh)",
        OriginCenter: "HamirpurUP_Maudaha_D (Uttar Pradesh)",
        FacilityCity: "Hamirpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20667,
        Pin: 639120,
        DispatchCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        OriginCenter: "Kulithalai_CaveryNagar_D (Tamil Nadu)",
        FacilityCity: "Kulithalai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20668,
        Pin: 208015,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20669,
        Pin: 248140,
        DispatchCenter: "Doiwala_BaksarWala_DPP (Uttarakhand)",
        OriginCenter: "Doiwala_BaksarWala_DPP (Uttarakhand)",
        FacilityCity: "Doiwala",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 20670,
        Pin: 671542,
        DispatchCenter: "Kasaragod_Kudlu_D (Kerala)",
        OriginCenter: "Kasaragod_Kudlu_D (Kerala)",
        FacilityCity: "Kasaragod",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20671,
        Pin: 851117,
        DispatchCenter: "Begusarai_Mohammadpur_I (Bihar)",
        OriginCenter: "Begusarai_Mohammadpur_I (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20672,
        Pin: 382265,
        DispatchCenter: "Dholka_Rampur_D (Gujarat)",
        OriginCenter: "Dholka_Rampur_D (Gujarat)",
        FacilityCity: "Dholka",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20673,
        Pin: 781009,
        DispatchCenter: "Guwahati_Betkuchi_L (Assam)",
        OriginCenter: "Guwahati_Kaikchi_GW (Assam)",
        FacilityCity: "Guwahati",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20674,
        Pin: 482011,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20675,
        Pin: 673011,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20676,
        Pin: 140413,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20677,
        Pin: 480334,
        DispatchCenter: "Pandhurna_Shastrinagar_D (Madhya Pradesh)",
        OriginCenter: "Pandhurna_Shastrinagar_D (Madhya Pradesh)",
        FacilityCity: "Pandhurna",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20678,
        Pin: 503206,
        DispatchCenter: "Bodhan_VamshiSchl_D (Telangana)",
        OriginCenter: "Bodhan_VamshiSchl_D (Telangana)",
        FacilityCity: "Bodhan",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20679,
        Pin: 612105,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20680,
        Pin: 388201,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20681,
        Pin: 481335,
        DispatchCenter: "Waraseoni_Lalbarra_D (Madhya Pradesh)",
        OriginCenter: "Waraseoni_Lalbarra_D (Madhya Pradesh)",
        FacilityCity: "Waraseoni",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20682,
        Pin: 415704,
        DispatchCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        OriginCenter: "Shringartali_Patpanhale_DPP (Maharashtra)",
        FacilityCity: "Shringartali",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20683,
        Pin: 844503,
        DispatchCenter: "ManharBazar_NayaGaon_D (Bihar)",
        OriginCenter: "ManharBazar_NayaGaon_D (Bihar)",
        FacilityCity: "Mahnar Bazar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20684,
        Pin: 442504,
        DispatchCenter: "Sironcha_Central_D (Maharashtra)",
        OriginCenter: "Sironcha_Central_D (Maharashtra)",
        FacilityCity: "Sironcha",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20685,
        Pin: 210505,
        DispatchCenter: "HamirpurUP_YamunaBdg_D (Uttar Pradesh)",
        OriginCenter: "HamirpurUP_YamunaBdg_D (Uttar Pradesh)",
        FacilityCity: "Hamirpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20686,
        Pin: 225304,
        DispatchCenter: "FatehpurUP_PatelNagar_D (Uttar Pradesh)",
        OriginCenter: "FatehpurUP_PatelNagar_D (Uttar Pradesh)",
        FacilityCity: "Fatehpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20687,
        Pin: 250003,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20688,
        Pin: 400003,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20689,
        Pin: 563119,
        DispatchCenter: "Bethamangala_Sriramatemp_D (Karnataka)",
        OriginCenter: "Bethamangala_Sriramatemp_D (Karnataka)",
        FacilityCity: "Bethamangala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20690,
        Pin: 431124,
        DispatchCenter: "Ambajogai_Bansilalnagar_D (Maharashtra)",
        OriginCenter: "Ambajogai_Bansilalnagar_D (Maharashtra)",
        FacilityCity: "Ambajogai",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20691,
        Pin: 421005,
        DispatchCenter: "Mumbai_Ambernath_L (Maharashtra)",
        OriginCenter: "Bhiwandi_Lonad_GW (Maharashtra)",
        FacilityCity: "Greater Thane",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20692,
        Pin: 306306,
        DispatchCenter: "Sojat_RendriCircle_DPP (Rajasthan)",
        OriginCenter: "Sojat_RendriCircle_DPP (Rajasthan)",
        FacilityCity: "Sojat",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20693,
        Pin: 416013,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20694,
        Pin: 766012,
        DispatchCenter: "Kesinga_Bogor_D (Orissa)",
        OriginCenter: "Kesinga_Bogor_D (Orissa)",
        FacilityCity: "Kesinga",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20695,
        Pin: 382449,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20696,
        Pin: 413802,
        DispatchCenter: "Daund_Kurkumbh_L (Maharashtra)",
        OriginCenter: "Daund_Kurkumbh_L (Maharashtra)",
        FacilityCity: "Daund",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20697,
        Pin: 768004,
        DispatchCenter: "Sambalpur_Tentelpara_H (Orissa)",
        OriginCenter: "Sambalpur_Tentelpara_H (Orissa)",
        FacilityCity: "Sambalpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20698,
        Pin: 673301,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20699,
        Pin: 754112,
        DispatchCenter: "Cuttack_Bilteruan_L (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Cuttack",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20700,
        Pin: 400202,
        DispatchCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        OriginCenter: "Mumbai_Wakanpada_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20701,
        Pin: 136135,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20702,
        Pin: 531040,
        DispatchCenter: "Pedabayalu_Munchangiputtu_D (Andhra Pradesh)",
        OriginCenter: "Pedabayalu_Munchangiputtu_D (Andhra Pradesh)",
        FacilityCity: "Pedabayalu",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20703,
        Pin: 271202,
        DispatchCenter: "Itiathok_Birmapur_D (Uttar Pradesh)",
        OriginCenter: "Itiathok_Birmapur_D (Uttar Pradesh)",
        FacilityCity: "Itia Thok",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20704,
        Pin: 261505,
        DispatchCenter: "Mohammadi_AdarshNagar_D (Uttar Pradesh)",
        OriginCenter: "Mohammadi_AdarshNagar_D (Uttar Pradesh)",
        FacilityCity: "Mohammadi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20705,
        Pin: 212302,
        DispatchCenter: "Manda_Salia_D (Uttar Pradesh)",
        OriginCenter: "Manda_Salia_D (Uttar Pradesh)",
        FacilityCity: "Manda",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20706,
        Pin: 271506,
        DispatchCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        OriginCenter: "Colonejganj_ParshpurRd_D (Uttar Pradesh)",
        FacilityCity: "Colonejganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20707,
        Pin: 249194,
        DispatchCenter: "Uttarkashi_Kansen_D (Uttarakhand)",
        OriginCenter: "Uttarkashi_Kansen_D (Uttarakhand)",
        FacilityCity: "Uttarkashi",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 20708,
        Pin: 313011,
        DispatchCenter: "Udaipur_Balicha_H (Rajasthan)",
        OriginCenter: "Udaipur_Balicha_H (Rajasthan)",
        FacilityCity: "Udaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20709,
        Pin: 391140,
        DispatchCenter: "Bodeli_SatyamPlaza_DPP (Gujarat)",
        OriginCenter: "Bodeli_SatyamPlaza_DPP (Gujarat)",
        FacilityCity: "Bodeli",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20710,
        Pin: 483001,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20711,
        Pin: 688002,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20712,
        Pin: 307043,
        DispatchCenter: "Sirohi_Vedhaynathcolony_D (Rajasthan)",
        OriginCenter: "Sirohi_Vedhaynathcolony_D (Rajasthan)",
        FacilityCity: "Sirohi",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20713,
        Pin: 689674,
        DispatchCenter: "Ranni_Chethakhl_D (Kerala)",
        OriginCenter: "Ranni_Chethakhl_D (Kerala)",
        FacilityCity: "Ranni",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20714,
        Pin: 442305,
        DispatchCenter: "Hinganghat_DyanshwrWrd_D (Maharashtra)",
        OriginCenter: "Hinganghat_DyanshwrWrd_D (Maharashtra)",
        FacilityCity: "Hinganghat",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20715,
        Pin: 400099,
        DispatchCenter: "Mumbai_Kurla_L (Maharashtra)",
        OriginCenter: "Mumbai_Kurla_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20716,
        Pin: 625701,
        DispatchCenter: "Virudhunagar_Allampatti_DPP (Tamil Nadu)",
        OriginCenter: "Virudhunagar_Allampatti_DPP (Tamil Nadu)",
        FacilityCity: "Virudhunagar",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20717,
        Pin: 560034,
        DispatchCenter: "Bangalore_Temp1_L (Karnataka)",
        OriginCenter: "Bangalore_Temp1_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20718,
        Pin: 638312,
        DispatchCenter: "Anthiyur_Thavittuplym_DPP (Tamil Nadu)",
        OriginCenter: "Anthiyur_Thavittuplym_DPP (Tamil Nadu)",
        FacilityCity: "Anthiyour",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20719,
        Pin: 483990,
        DispatchCenter: "Raipura_Bharwara_D (Madhya Pradesh)",
        OriginCenter: "Raipura_Bharwara_D (Madhya Pradesh)",
        FacilityCity: "Raipura",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20720,
        Pin: 222003,
        DispatchCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        OriginCenter: "Jaunpur_Chachakpur_D (Uttar Pradesh)",
        FacilityCity: "Jaunpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20721,
        Pin: 603107,
        DispatchCenter: "Chengalpattu_Uthiramerur_D (Tamil Nadu)",
        OriginCenter: "Chengalpattu_Uthiramerur_D (Tamil Nadu)",
        FacilityCity: "Chengalpattu",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20722,
        Pin: 462021,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20723,
        Pin: 629804,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20724,
        Pin: 208020,
        DispatchCenter: "Kanpur_Sarimita_L (Uttar Pradesh)",
        OriginCenter: "Kanpur_Kataraghan_H (Uttar Pradesh)",
        FacilityCity: "Kanpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20725,
        Pin: 185155,
        DispatchCenter: "Sunderbani_Naushera_D (Jammu & Kashmir)",
        OriginCenter: "Sunderbani_Naushera_D (Jammu & Kashmir)",
        FacilityCity: "Sunderbani",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20726,
        Pin: 483053,
        DispatchCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        OriginCenter: "Jabalpur_Timri_P (Madhya Pradesh)",
        FacilityCity: "Jabalpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20727,
        Pin: 670597,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20728,
        Pin: 783338,
        DispatchCenter: "Golakganj_SouthTokrerchara_DPP (Assam)",
        OriginCenter: "Golakganj_SouthTokrerchara_DPP (Assam)",
        FacilityCity: "Golakganj",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20729,
        Pin: 711414,
        DispatchCenter: "Howrah_Temp_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20730,
        Pin: 132022,
        DispatchCenter: "Karnal_Kutail_H (Haryana)",
        OriginCenter: "Karnal_Kutail_H (Haryana)",
        FacilityCity: "Karnal",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20731,
        Pin: 476001,
        DispatchCenter: "Morena_Sidhinagar_D (Madhya Pradesh)",
        OriginCenter: "Morena_Sidhinagar_D (Madhya Pradesh)",
        FacilityCity: "Morena",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20732,
        Pin: 177026,
        DispatchCenter: "Bhota_BpassDPP_D (Himachal Pradesh)",
        OriginCenter: "Bhota_BpassDPP_D (Himachal Pradesh)",
        FacilityCity: "Bhota",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20733,
        Pin: 582120,
        DispatchCenter: "Gadag_Laxmeshwar_D (Karnataka)",
        OriginCenter: "Gadag_Laxmeshwar_D (Karnataka)",
        FacilityCity: "Gadag",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20734,
        Pin: 470000,
        DispatchCenter: "Khurai_Ward11_DPP (Madhya Pradesh)",
        OriginCenter: "Khurai_Ward11_DPP (Madhya Pradesh)",
        FacilityCity: "Khurai",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20735,
        Pin: 148024,
        DispatchCenter: "Dhuri_Bhalwanroad_D (Punjab)",
        OriginCenter: "Dhuri_Bhalwanroad_D (Punjab)",
        FacilityCity: "Dhuri",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20736,
        Pin: 625513,
        DispatchCenter: "Theni_Dharmathupatti_D (Tamil Nadu)",
        OriginCenter: "Theni_Dharmathupatti_D (Tamil Nadu)",
        FacilityCity: "Bodinayakanur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20737,
        Pin: 591146,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20738,
        Pin: 125120,
        DispatchCenter: "Fatehabad_Kullanroad_D (Haryana)",
        OriginCenter: "Fatehabad_Kullanroad_D (Haryana)",
        FacilityCity: "Fatehabad",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20739,
        Pin: 486331,
        DispatchCenter: "Deotalab_Mauganj_D (Madhya Pradesh)",
        OriginCenter: "Deotalab_Mauganj_D (Madhya Pradesh)",
        FacilityCity: "Deotalab",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20740,
        Pin: 768215,
        DispatchCenter: "Bamra_Govindpur_D (Orissa)",
        OriginCenter: "Bamra_Govindpur_D (Orissa)",
        FacilityCity: "Bamra",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20741,
        Pin: 422002,
        DispatchCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        OriginCenter: "Nashik_Satpurcolony_H (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20742,
        Pin: 413302,
        DispatchCenter: "Pandharpur_RajCmplx_D (Maharashtra)",
        OriginCenter: "Pandharpur_RajCmplx_D (Maharashtra)",
        FacilityCity: "Pandharpur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20743,
        Pin: 731126,
        DispatchCenter: "Suri_Karidhya_D (West Bengal)",
        OriginCenter: "Suri_Karidhya_D (West Bengal)",
        FacilityCity: "Suri",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20744,
        Pin: 521125,
        DispatchCenter: "Avanigadda_Ramnagar_D (Andhra Pradesh)",
        OriginCenter: "Avanigadda_Ramnagar_D (Andhra Pradesh)",
        FacilityCity: "Avanigadda",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20745,
        Pin: 521326,
        DispatchCenter: "Kaikaluru_Gonepadu_D (Andhra Pradesh)",
        OriginCenter: "Kaikaluru_Gonepadu_D (Andhra Pradesh)",
        FacilityCity: "Kaikaluru",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20746,
        Pin: 229316,
        DispatchCenter: "Raebareli_Ratapur_D (Uttar Pradesh)",
        OriginCenter: "Raebareli_Ratapur_D (Uttar Pradesh)",
        FacilityCity: "Raebareli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20747,
        Pin: 230502,
        DispatchCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        OriginCenter: "Pratapgarh_Katramedniganj_D (Uttar Pradesh)",
        FacilityCity: "Pratapgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20748,
        Pin: 534324,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20749,
        Pin: 363039,
        DispatchCenter: "Surendranagar_GIDC_D (Gujarat)",
        OriginCenter: "Surendranagar_GIDC_D (Gujarat)",
        FacilityCity: "Surendranagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20750,
        Pin: 761019,
        DispatchCenter: "Kodala_Beguniapada_DPP (Orissa)",
        OriginCenter: "Kodala_Beguniapada_DPP (Orissa)",
        FacilityCity: "Kodala",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20751,
        Pin: 783392,
        DispatchCenter: "Bongaigaon_Chapaguri_I (Assam)",
        OriginCenter: "Bongaigaon_Chapaguri_I (Assam)",
        FacilityCity: "Bongaigaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20752,
        Pin: 363415,
        DispatchCenter: "Limbdi_Ranpur_DPP (Gujarat)",
        OriginCenter: "Limbdi_Ranpur_DPP (Gujarat)",
        FacilityCity: "Limbdi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20753,
        Pin: 474011,
        DispatchCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        OriginCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        FacilityCity: "Gwalior",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20754,
        Pin: 695124,
        DispatchCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        OriginCenter: "Thiruvananthapuram_Vallakkadavu_L (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20755,
        Pin: 441920,
        DispatchCenter: "Umred_Narsala_D (Maharashtra)",
        OriginCenter: "Umred_Narsala_D (Maharashtra)",
        FacilityCity: "Umred",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20756,
        Pin: 609607,
        DispatchCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        OriginCenter: "Karaikal_Bharathinagar_D (Pondicherry)",
        FacilityCity: "Karaikal",
        FacilityState: "Pondicherry",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20757,
        Pin: 416212,
        DispatchCenter: "RashiwadeBk_Bhogavati_DPP (Maharashtra)",
        OriginCenter: "RashiwadeBk_Bhogavati_DPP (Maharashtra)",
        FacilityCity: "Rashiwade Bk",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20758,
        Pin: 700160,
        DispatchCenter: "Kolkata_Kadapara_L (West Bengal)",
        OriginCenter: "Kolkata_Kadapara_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20759,
        Pin: 332710,
        DispatchCenter: "Renwal_Wardno4_D (Rajasthan)",
        OriginCenter: "Renwal_Wardno4_D (Rajasthan)",
        FacilityCity: "Renwal",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20760,
        Pin: 824221,
        DispatchCenter: "Imamganj_Bisrampur_D (Bihar)",
        OriginCenter: "Imamganj_Bisrampur_D (Bihar)",
        FacilityCity: "Imamganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20761,
        Pin: 785678,
        DispatchCenter: "Nazira_Amina_D (Assam)",
        OriginCenter: "Nazira_Amina_D (Assam)",
        FacilityCity: "Nazira",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20762,
        Pin: 532203,
        DispatchCenter: "Tekkali_Tolusurapalli_D (Andhra Pradesh)",
        OriginCenter: "Tekkali_Tolusurapalli_D (Andhra Pradesh)",
        FacilityCity: "Tekkali",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20763,
        Pin: 485001,
        DispatchCenter: "Satna_Nazirabad_I (Madhya Pradesh)",
        OriginCenter: "Satna_Nazirabad_I (Madhya Pradesh)",
        FacilityCity: "Satna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20764,
        Pin: 160030,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20765,
        Pin: 465445,
        DispatchCenter: "Nalkheda_Kanad_D (Madhya Pradesh)",
        OriginCenter: "Nalkheda_Kanad_D (Madhya Pradesh)",
        FacilityCity: "Nalkheda",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20766,
        Pin: 635802,
        DispatchCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ambur_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ambur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20767,
        Pin: 444905,
        DispatchCenter: "Morshi_Samarthacolony_D (Maharashtra)",
        OriginCenter: "Morshi_Samarthacolony_D (Maharashtra)",
        FacilityCity: "Morshi",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20768,
        Pin: 185132,
        DispatchCenter: "Rajouri_Mehra_D (Jammu & Kashmir)",
        OriginCenter: "Rajouri_Mehra_D (Jammu & Kashmir)",
        FacilityCity: "Rajouri",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20769,
        Pin: 803122,
        DispatchCenter: "Hilsa_Sryamndr_D (Bihar)",
        OriginCenter: "Hilsa_Sryamndr_D (Bihar)",
        FacilityCity: "Hilsa",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20770,
        Pin: 413107,
        DispatchCenter: "Mhaswad_Drgmtatpl_D (Maharashtra)",
        OriginCenter: "Mhaswad_Drgmtatpl_D (Maharashtra)",
        FacilityCity: "Mhaswad",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20771,
        Pin: 144803,
        DispatchCenter: "Jalandhar_QajiMandi_P (Punjab)",
        OriginCenter: "Jalandhar_QajiMandi_P (Punjab)",
        FacilityCity: "Jalandhar",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 20772,
        Pin: 305627,
        DispatchCenter: "Nasirabad_RamlilaChowk_DPP (Rajasthan)",
        OriginCenter: "Nasirabad_RamlilaChowk_DPP (Rajasthan)",
        FacilityCity: "Nasirabad",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20773,
        Pin: 411047,
        DispatchCenter: "Pune_Bakoriphata_L (Maharashtra)",
        OriginCenter: "Pune_Bakoriphata_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20774,
        Pin: 637210,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20775,
        Pin: 685563,
        DispatchCenter: "Kuthumkal_Matheckal_D (Kerala)",
        OriginCenter: "Kuthumkal_Matheckal_D (Kerala)",
        FacilityCity: "Kuthumkal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20776,
        Pin: 689675,
        DispatchCenter: "Ranni_Chethakhl_D (Kerala)",
        OriginCenter: "Ranni_Chethakhl_D (Kerala)",
        FacilityCity: "Ranni",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20777,
        Pin: 695125,
        DispatchCenter: "Neyatinkra_Kiliyoor_D (Kerala)",
        OriginCenter: "Neyatinkra_Kiliyoor_D (Kerala)",
        FacilityCity: "Neyyattinkara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20778,
        Pin: 828306,
        DispatchCenter: "Topchanchi_GomhRd_DPP (Jharkhand)",
        OriginCenter: "Topchanchi_GomhRd_DPP (Jharkhand)",
        FacilityCity: "Gomoh",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20779,
        Pin: 360026,
        DispatchCenter: "Rajkot_Shapar_D (Gujarat)",
        OriginCenter: "Rajkot_Shapar_D (Gujarat)",
        FacilityCity: "Rajkot",
        FacilityState: "Gujarat",
        Zone : "W1"
      },
      {
        SN: 20780,
        Pin: 688012,
        DispatchCenter: "Alappuzha_Bernard_D (Kerala)",
        OriginCenter: "Alappuzha_Bernard_D (Kerala)",
        FacilityCity: "Alappuzha",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20781,
        Pin: 110023,
        DispatchCenter: "Delhi_SanjayColony_L (Delhi)",
        OriginCenter: "Faridabad_MathuraRoad_GW (Haryana)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20782,
        Pin: 203405,
        DispatchCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        OriginCenter: "Siyana_Khnprstand_D (Uttar Pradesh)",
        FacilityCity: "Siyana",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20783,
        Pin: 733123,
        DispatchCenter: "Raiganj_SubrtDPP_D (West Bengal)",
        OriginCenter: "Raiganj_SubrtDPP_D (West Bengal)",
        FacilityCity: "Raiganj",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20784,
        Pin: 712418,
        DispatchCenter: "Ilipur_Dhanyahana_D (West Bengal)",
        OriginCenter: "Ilipur_Dhanyahana_D (West Bengal)",
        FacilityCity: "Ilipur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20785,
        Pin: 303003,
        DispatchCenter: "Viratnagar_Shyamcolony_D (Rajasthan)",
        OriginCenter: "Viratnagar_Shyamcolony_D (Rajasthan)",
        FacilityCity: "Viratnagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20786,
        Pin: 110061,
        DispatchCenter: "Delhi_BamnoliVillage_L (Delhi)",
        OriginCenter: "Delhi_BamnoliVillage_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20787,
        Pin: 631052,
        DispatchCenter: "Arakkonam_Ranipet_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ranipet_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20788,
        Pin: 416420,
        DispatchCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        OriginCenter: "Miraj_GuruvarPeth_D (Maharashtra)",
        FacilityCity: "Miraj",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20789,
        Pin: 451442,
        DispatchCenter: "Thikri_DrbrVilla_DPP (Madhya Pradesh)",
        OriginCenter: "Thikri_DrbrVilla_DPP (Madhya Pradesh)",
        FacilityCity: "Thikri",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20790,
        Pin: 713346,
        DispatchCenter: "Dubrajpur_Pandaveswar_D (West Bengal)",
        OriginCenter: "Dubrajpur_Pandaveswar_D (West Bengal)",
        FacilityCity: "Dubrajpur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20791,
        Pin: 685514,
        DispatchCenter: "Kattappana_EratayarRoad_D (Kerala)",
        OriginCenter: "Kattappana_EratayarRoad_D (Kerala)",
        FacilityCity: "Kattappana",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20792,
        Pin: 670503,
        DispatchCenter: "Kannur_Aduthila_D (Kerala)",
        OriginCenter: "Kannur_Aduthila_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20793,
        Pin: 829206,
        DispatchCenter: "Latehar_Sasang_DPP (Jharkhand)",
        OriginCenter: "Latehar_Sasang_DPP (Jharkhand)",
        FacilityCity: "Latehar",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20794,
        Pin: 400037,
        DispatchCenter: "Mumbai_Sewri_L (Maharashtra)",
        OriginCenter: "Mumbai_Sewri_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20795,
        Pin: 712412,
        DispatchCenter: "Khanakul_Antngr_D (West Bengal)",
        OriginCenter: "Khanakul_Antngr_D (West Bengal)",
        FacilityCity: "Khanakul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20796,
        Pin: 743318,
        DispatchCenter: "Kolkata_Paharpur_L (West Bengal)",
        OriginCenter: "Kolkata_Paharpur_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20797,
        Pin: 380018,
        DispatchCenter: "Ahmedabad_Odhavestate_L (Gujarat)",
        OriginCenter: "Ahmedabad_Kanera_GW (Gujarat)",
        FacilityCity: "Ahmedabad",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20798,
        Pin: 261405,
        DispatchCenter: "Sitapur_Lachimanpur_D (Uttar Pradesh)",
        OriginCenter: "Sitapur_Lachimanpur_D (Uttar Pradesh)",
        FacilityCity: "Sitapur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20799,
        Pin: 571247,
        DispatchCenter: "Madikeri_Napoklu1_DPP (Karnataka)",
        OriginCenter: "Madikeri_Napoklu1_DPP (Karnataka)",
        FacilityCity: "Madikeri",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20800,
        Pin: 560087,
        DispatchCenter: "Bangalore_Koralur_L (Karnataka)",
        OriginCenter: "Bangalore_Soukyaroad_GW (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20801,
        Pin: 110055,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20802,
        Pin: 400002,
        DispatchCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        OriginCenter: "Mumbai_Kalachowki_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20803,
        Pin: 793010,
        DispatchCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        OriginCenter: "Shillong_Pynthorbah_I (Meghalaya)",
        FacilityCity: "Shillong",
        FacilityState: "Meghalaya",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20804,
        Pin: 456004,
        DispatchCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        OriginCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        FacilityCity: "Ujjain",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20805,
        Pin: 689660,
        DispatchCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        OriginCenter: "Kozhenchery_Cherukalpuzha_D (Kerala)",
        FacilityCity: "Kozhenchery",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20806,
        Pin: 365650,
        DispatchCenter: "Visavadar_Dhari_DPP (Gujarat)",
        OriginCenter: "Visavadar_Dhari_DPP (Gujarat)",
        FacilityCity: "Visavadar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20807,
        Pin: 813214,
        DispatchCenter: "Kahalgaon_Budhuchak_D (Bihar)",
        OriginCenter: "Kahalgaon_Budhuchak_D (Bihar)",
        FacilityCity: "Kahalgaon",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20808,
        Pin: 464258,
        DispatchCenter: "GanjBasoda_Mahavirvihar_DPP (Madhya Pradesh)",
        OriginCenter: "GanjBasoda_Mahavirvihar_DPP (Madhya Pradesh)",
        FacilityCity: "Ganj Basoda",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20809,
        Pin: 600028,
        DispatchCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        OriginCenter: "Chennai_Parivakkam_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20810,
        Pin: 412105,
        DispatchCenter: "Pune_Pimpri_L (Maharashtra)",
        OriginCenter: "Pune_Pimpri_L (Maharashtra)",
        FacilityCity: "Pune",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20811,
        Pin: 110601,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20812,
        Pin: 680515,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20813,
        Pin: 524131,
        DispatchCenter: "Gudur_MalvyaNagar_D (Andhra Pradesh)",
        OriginCenter: "Gudur_MalvyaNagar_D (Andhra Pradesh)",
        FacilityCity: "Gudur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20814,
        Pin: 323302,
        DispatchCenter: "Rawatbhata_HarijanBasti_D (Rajasthan)",
        OriginCenter: "Rawatbhata_HarijanBasti_D (Rajasthan)",
        FacilityCity: "Rawatbhata",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20815,
        Pin: 624710,
        DispatchCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        OriginCenter: "Oddnchtram_Palani_D (Tamil Nadu)",
        FacilityCity: "Oddanchatram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20816,
        Pin: 246721,
        DispatchCenter: "Bijnor_Garhi_D (Uttar Pradesh)",
        OriginCenter: "Bijnor_Garhi_D (Uttar Pradesh)",
        FacilityCity: "Bijnor",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20817,
        Pin: 462037,
        DispatchCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        OriginCenter: "Bhopal_Pipaliya_H (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20818,
        Pin: 605006,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20819,
        Pin: 686640,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20820,
        Pin: 515767,
        DispatchCenter: "Kalyandurg_JayNagar_D (Andhra Pradesh)",
        OriginCenter: "Kalyandurg_JayNagar_D (Andhra Pradesh)",
        FacilityCity: "Kalyandurg",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20821,
        Pin: 403504,
        DispatchCenter: "Goa_Mapusa_L (Goa)",
        OriginCenter: "Goa_Mapusa_L (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20822,
        Pin: 847122,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20823,
        Pin: 574238,
        DispatchCenter: "Panja_SubrmnyRd_DPP (Karnataka)",
        OriginCenter: "Panja_SubrmnyRd_DPP (Karnataka)",
        FacilityCity: "Panja",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20824,
        Pin: 803117,
        DispatchCenter: "Rajgir_Naipokhar_D (Bihar)",
        OriginCenter: "Rajgir_Naipokhar_D (Bihar)",
        FacilityCity: "Rajgir",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20825,
        Pin: 125076,
        DispatchCenter: "Sirsa_Housingboardcol_D (Haryana)",
        OriginCenter: "Sirsa_Housingboardcol_D (Haryana)",
        FacilityCity: "Sirsa",
        FacilityState: "Haryana",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20826,
        Pin: 384012,
        DispatchCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        OriginCenter: "Mehsana_DediysnGIDC_I (Gujarat)",
        FacilityCity: "Mehsana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20827,
        Pin: 680732,
        DispatchCenter: "Aluva_Peedika_H (Kerala)",
        OriginCenter: "Aluva_Peedika_H (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20828,
        Pin: 841237,
        DispatchCenter: "Siwan_Andar_D (Bihar)",
        OriginCenter: "Siwan_Andar_D (Bihar)",
        FacilityCity: "Siwan",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20829,
        Pin: 585323,
        DispatchCenter: "Shahapur_LxmiNgr_D (Karnataka)",
        OriginCenter: "Shahapur_LxmiNgr_D (Karnataka)",
        FacilityCity: "Shahapur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20830,
        Pin: 631005,
        DispatchCenter: "Arakkonam_Ranipet_D (Tamil Nadu)",
        OriginCenter: "Arakkonam_Ranipet_D (Tamil Nadu)",
        FacilityCity: "Arakkonam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20831,
        Pin: 691526,
        DispatchCenter: "Adoor_Pathanapuram_D (Kerala)",
        OriginCenter: "Adoor_Pathanapuram_D (Kerala)",
        FacilityCity: "Adoor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20832,
        Pin: 313015,
        DispatchCenter: "Udaipur_Balicha_H (Rajasthan)",
        OriginCenter: "Udaipur_Balicha_H (Rajasthan)",
        FacilityCity: "Udaipur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20833,
        Pin: 577537,
        DispatchCenter: "Challakere_Hosmane_D (Karnataka)",
        OriginCenter: "Challakere_Hosmane_D (Karnataka)",
        FacilityCity: "Challakere",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20834,
        Pin: 143415,
        DispatchCenter: "Patti_TarnTaran_D (Punjab)",
        OriginCenter: "Patti_TarnTaran_D (Punjab)",
        FacilityCity: "Patti",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20835,
        Pin: 743136,
        DispatchCenter: "Naihati_Rajendrapur_DPP (West Bengal)",
        OriginCenter: "Naihati_Rajendrapur_DPP (West Bengal)",
        FacilityCity: "Naihati",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20836,
        Pin: 391210,
        DispatchCenter: "Karjan_Junabzr_DC (Gujarat)",
        OriginCenter: "Karjan_Junabzr_DC (Gujarat)",
        FacilityCity: "Karjan",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20837,
        Pin: 335001,
        DispatchCenter: "Ganganagar_Ricco_D (Rajasthan)",
        OriginCenter: "Ganganagar_Ricco_D (Rajasthan)",
        FacilityCity: "Ganga Nagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20838,
        Pin: 193121,
        DispatchCenter: "Srinagar_Pattan_DPP (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Pattan_DPP (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20839,
        Pin: 625005,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20840,
        Pin: 515641,
        DispatchCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        OriginCenter: "Anantapur_AyyavaripalliRD_H (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20841,
        Pin: 713330,
        DispatchCenter: "Asansol_Addacolony_D (West Bengal)",
        OriginCenter: "Asansol_Addacolony_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20842,
        Pin: 243122,
        DispatchCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        OriginCenter: "Bareilly_Sathrapur_H (Uttar Pradesh)",
        FacilityCity: "Bareilly",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20843,
        Pin: 587121,
        DispatchCenter: "Mudhol_Maharancol_D (Karnataka)",
        OriginCenter: "Mudhol_Maharancol_D (Karnataka)",
        FacilityCity: "Mudhol",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20844,
        Pin: 609405,
        DispatchCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        OriginCenter: "Mayavaram_Kutrallam_I (Tamil Nadu)",
        FacilityCity: "Mayiladuthurai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20845,
        Pin: 160074,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20846,
        Pin: 224172,
        DispatchCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        OriginCenter: "Ayodhya_Kotsarai_H (Uttar Pradesh)",
        FacilityCity: "Faizabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20847,
        Pin: 515133,
        DispatchCenter: "Puttaprthi_Gokulam_D (Andhra Pradesh)",
        OriginCenter: "Puttaprthi_Gokulam_D (Andhra Pradesh)",
        FacilityCity: "Puttaparthi",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20848,
        Pin: 848302,
        DispatchCenter: "KalyanpurBR_Ladaura_D (Bihar)",
        OriginCenter: "KalyanpurBR_Ladaura_D (Bihar)",
        FacilityCity: "KalyanpurBR",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20849,
        Pin: 679310,
        DispatchCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        OriginCenter: "Sankaramangalam_Pattithara_D (Kerala)",
        FacilityCity: "Sankaramangalam",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20850,
        Pin: 784174,
        DispatchCenter: "Biswanath_CharialiLIC_D (Assam)",
        OriginCenter: "Biswanath_CharialiLIC_D (Assam)",
        FacilityCity: "BiswanathCH",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20851,
        Pin: 326501,
        DispatchCenter: "BhwniMndi_RmngrDPP_D (Rajasthan)",
        OriginCenter: "BhwniMndi_RmngrDPP_D (Rajasthan)",
        FacilityCity: "Bhawani Mandi",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20852,
        Pin: 610102,
        DispatchCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        OriginCenter: "Thiruvarur_VasanNgr_DPP (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20853,
        Pin: 678512,
        DispatchCenter: "Nenmara_Vithanaserry_D (Kerala)",
        OriginCenter: "Nenmara_Vithanaserry_D (Kerala)",
        FacilityCity: "Nenmara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20854,
        Pin: 250101,
        DispatchCenter: "Baghpat_Barout_D (Uttar Pradesh)",
        OriginCenter: "Baghpat_Barout_D (Uttar Pradesh)",
        FacilityCity: "Baghpat",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20855,
        Pin: 110038,
        DispatchCenter: "Delhi_BamnoliVillage_L (Delhi)",
        OriginCenter: "Delhi_BamnoliVillage_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20856,
        Pin: 370675,
        DispatchCenter: "Nakhatrana_Dhormnath_DPP (Gujarat)",
        OriginCenter: "Nakhatrana_Dhormnath_DPP (Gujarat)",
        FacilityCity: "Nakhatrana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20857,
        Pin: 227815,
        DispatchCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        OriginCenter: "Kumarganj_ShivNagar_D (Uttar Pradesh)",
        FacilityCity: "Kumarganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20858,
        Pin: 242221,
        DispatchCenter: "Jalalabad_Bareillyroad_D (Uttar Pradesh)",
        OriginCenter: "Jalalabad_Bareillyroad_D (Uttar Pradesh)",
        FacilityCity: "JalalabadUP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20859,
        Pin: 835219,
        DispatchCenter: "Ormanjhi_Dardag_DPP (Jharkhand)",
        OriginCenter: "Ormanjhi_Dardag_DPP (Jharkhand)",
        FacilityCity: "Ormanjhi",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20860,
        Pin: 847302,
        DispatchCenter: "Pupri_SngihiRD_D (Bihar)",
        OriginCenter: "Pupri_SngihiRD_D (Bihar)",
        FacilityCity: "Pupri",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20861,
        Pin: 502114,
        DispatchCenter: "Siddipet_Vemula_D (Telangana)",
        OriginCenter: "Siddipet_Vemula_D (Telangana)",
        FacilityCity: "Siddipet",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20862,
        Pin: 560039,
        DispatchCenter: "Bangalore_FCILayout_L (Karnataka)",
        OriginCenter: "Bangalore_FCILayout_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20863,
        Pin: 604404,
        DispatchCenter: "Vandavasi_SaltStreet_D (Tamil Nadu)",
        OriginCenter: "Vandavasi_SaltStreet_D (Tamil Nadu)",
        FacilityCity: "Vandavasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20864,
        Pin: 431506,
        DispatchCenter: "Selu_Vidyangr_D (Maharashtra)",
        OriginCenter: "Selu_Vidyangr_D (Maharashtra)",
        FacilityCity: "Selu",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20865,
        Pin: 845415,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20866,
        Pin: 493776,
        DispatchCenter: "Dhamtari_VasiniWard_D (Chhattisgarh)",
        OriginCenter: "Dhamtari_VasiniWard_D (Chhattisgarh)",
        FacilityCity: "Dhamtari",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20867,
        Pin: 281303,
        DispatchCenter: "Mathura_Narsipuramrd_D (Uttar Pradesh)",
        OriginCenter: "Mathura_Narsipuramrd_D (Uttar Pradesh)",
        FacilityCity: "Mathura",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20868,
        Pin: 284128,
        DispatchCenter: "Jhansi_Nandanpura_I (Uttar Pradesh)",
        OriginCenter: "Jhansi_Nandanpura_I (Uttar Pradesh)",
        FacilityCity: "Jhansi",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20869,
        Pin: 490004,
        DispatchCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        OriginCenter: "Durg_KohkaRd_D (Chhattisgarh)",
        FacilityCity: "Durg",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20870,
        Pin: 670511,
        DispatchCenter: "Nileshwar_Olayambadi_D (Kerala)",
        OriginCenter: "Nileshwar_Olayambadi_D (Kerala)",
        FacilityCity: "Nileshwar",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20871,
        Pin: 754136,
        DispatchCenter: "Tirtol_Central_D_2 (Orissa)",
        OriginCenter: "Tirtol_Central_D_2 (Orissa)",
        FacilityCity: "Tirtol",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20872,
        Pin: 134103,
        DispatchCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        OriginCenter: "Chandigarh_Raiprvlg_L (Chandigarh)",
        FacilityCity: "Chandigarh",
        FacilityState: "Chandigarh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20873,
        Pin: 613104,
        DispatchCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        OriginCenter: "Thirukatupali_BudalurRd_D (Tamil Nadu)",
        FacilityCity: "Thirukkattupalli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20874,
        Pin: 766036,
        DispatchCenter: "Kantabanji_Sindhekela_D (Orissa)",
        OriginCenter: "Kantabanji_Sindhekela_D (Orissa)",
        FacilityCity: "Kantabanji",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20875,
        Pin: 461001,
        DispatchCenter: "Hoshangabad_BabaiRd_DPP (Madhya Pradesh)",
        OriginCenter: "Hoshangabad_BabaiRd_DPP (Madhya Pradesh)",
        FacilityCity: "Hoshangabad",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20876,
        Pin: 364515,
        DispatchCenter: "Savarkundla_Manibhai_DPP (Gujarat)",
        OriginCenter: "Savarkundla_Manibhai_DPP (Gujarat)",
        FacilityCity: "Savarkundla",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20877,
        Pin: 231213,
        DispatchCenter: "Robertsganj_Pakari_D (Uttar Pradesh)",
        OriginCenter: "Robertsganj_Pakari_D (Uttar Pradesh)",
        FacilityCity: "Robertsganj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20878,
        Pin: 734214,
        DispatchCenter: "Darjeeling_Mahendragaon_DPP (West Bengal)",
        OriginCenter: "Darjeeling_Mahendragaon_DPP (West Bengal)",
        FacilityCity: "Darjeeling",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20879,
        Pin: 522603,
        DispatchCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        OriginCenter: "Narasaraopet_SBalajiNgr_D (Andhra Pradesh)",
        FacilityCity: "Narasaraopet",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20880,
        Pin: 305206,
        DispatchCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        OriginCenter: "Ajmer_Ratanzila_I (Rajasthan)",
        FacilityCity: "Ajmer",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20881,
        Pin: 711317,
        DispatchCenter: "Howrah_Temp_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Howrah",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20882,
        Pin: 342003,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20883,
        Pin: 506015,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20884,
        Pin: 313708,
        DispatchCenter: "Gogunda_Bypass_DPP (Rajasthan)",
        OriginCenter: "Gogunda_Bypass_DPP (Rajasthan)",
        FacilityCity: "Gogunda",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20885,
        Pin: 641013,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20886,
        Pin: 691557,
        DispatchCenter: "Kottarakkara_MythriNagar_D (Kerala)",
        OriginCenter: "Kottarakkara_MythriNagar_D (Kerala)",
        FacilityCity: "Kottarakkara",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20887,
        Pin: 670346,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20888,
        Pin: 627953,
        DispatchCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        OriginCenter: "Sankaran_Kovil_D (Tamil Nadu)",
        FacilityCity: "Sankarankovil",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20889,
        Pin: 301405,
        DispatchCenter: "Khairthal_HarsouliRoad_D (Rajasthan)",
        OriginCenter: "Khairthal_HarsouliRoad_D (Rajasthan)",
        FacilityCity: "Khairthal",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20890,
        Pin: 250623,
        DispatchCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        OriginCenter: "Baraut_OmNagar_D (Uttar Pradesh)",
        FacilityCity: "Baraut",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20891,
        Pin: 847422,
        DispatchCenter: "Benipur_Karhari_D (Bihar)",
        OriginCenter: "Benipur_Karhari_D (Bihar)",
        FacilityCity: "Benipur",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20892,
        Pin: 412405,
        DispatchCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        OriginCenter: "Ambegaon_Manchar1_D (Maharashtra)",
        FacilityCity: "Ambegaon",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20893,
        Pin: 813209,
        DispatchCenter: "Kahalgaon_Pirpainti_D (Bihar)",
        OriginCenter: "Kahalgaon_Pirpainti_D (Bihar)",
        FacilityCity: "Kahalgaon",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20894,
        Pin: 516247,
        DispatchCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        OriginCenter: "Cuddapah_Aravindnagar_I (Andhra Pradesh)",
        FacilityCity: "Cuddapah",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20895,
        Pin: 142024,
        DispatchCenter: "Jagraon_Grainmkt_D (Punjab)",
        OriginCenter: "Jagraon_Grainmkt_D (Punjab)",
        FacilityCity: "Jagraon",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20896,
        Pin: 504313,
        DispatchCenter: "Jainoor_Powerhousecolony_DPP (Telangana)",
        OriginCenter: "Jainoor_Powerhousecolony_DPP (Telangana)",
        FacilityCity: "Jainoor",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20897,
        Pin: 312001,
        DispatchCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        OriginCenter: "Chittaurgarh_Madhuban_D (Rajasthan)",
        FacilityCity: "Chittaurgarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20898,
        Pin: 770011,
        DispatchCenter: "Sundargarh_BndhaPli_DPP (Orissa)",
        OriginCenter: "Sundargarh_BndhaPli_DPP (Orissa)",
        FacilityCity: "Sundargarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20899,
        Pin: 160043,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20900,
        Pin: 472221,
        DispatchCenter: "Jatara_Baiwarkhas_D (Madhya Pradesh)",
        OriginCenter: "Jatara_Baiwarkhas_D (Madhya Pradesh)",
        FacilityCity: "Jatara",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20901,
        Pin: 494442,
        DispatchCenter: "Jagdalpur_Hatkchora_D (Chhattisgarh)",
        OriginCenter: "Jagdalpur_Hatkchora_D (Chhattisgarh)",
        FacilityCity: "Jagdalpur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20902,
        Pin: 276208,
        DispatchCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        OriginCenter: "Azamgarh_Kotwa_I (Uttar Pradesh)",
        FacilityCity: "Azamgarh",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20903,
        Pin: 160044,
        DispatchCenter: "Mohali_Balongi_L (Punjab)",
        OriginCenter: "Mohali_Balongi_L (Punjab)",
        FacilityCity: "Mohali",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20904,
        Pin: 626131,
        DispatchCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        OriginCenter: "Sivakasi_Thiruthangal_D (Tamil Nadu)",
        FacilityCity: "Sivakasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20905,
        Pin: 815351,
        DispatchCenter: "Jamtara_Chakrakasabad_D (Jharkhand)",
        OriginCenter: "Jamtara_Chakrakasabad_D (Jharkhand)",
        FacilityCity: "Jamtara",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20906,
        Pin: 175134,
        DispatchCenter: "Nagwain_Nalagarh_DPP (Himachal Pradesh)",
        OriginCenter: "Nagwain_Nalagarh_DPP (Himachal Pradesh)",
        FacilityCity: "Nagwain",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20907,
        Pin: 144528,
        DispatchCenter: "Garhshankar_BangaRoad_D (Punjab)",
        OriginCenter: "Garhshankar_BangaRoad_D (Punjab)",
        FacilityCity: "Garhshanker",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20908,
        Pin: 504251,
        DispatchCenter: "Bellampalli_Rlwaycolny_DPP (Telangana)",
        OriginCenter: "Bellampalli_Rlwaycolny_DPP (Telangana)",
        FacilityCity: "Bellampalli",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20909,
        Pin: 394730,
        DispatchCenter: "Vansda_Chikatiya_D (Gujarat)",
        OriginCenter: "Vansda_Chikatiya_D (Gujarat)",
        FacilityCity: "Vansda",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20910,
        Pin: 508266,
        DispatchCenter: "Haliya_VBNagar_D (Telangana)",
        OriginCenter: "Haliya_VBNagar_D (Telangana)",
        FacilityCity: "Haliya",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20911,
        Pin: 783128,
        DispatchCenter: "Fekamari_Hatsingimari_DPP (Assam)",
        OriginCenter: "Fekamari_Hatsingimari_DPP (Assam)",
        FacilityCity: "Fekamari",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20912,
        Pin: 342033,
        DispatchCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        OriginCenter: "Jodhpur_BasniPhase2_P (Rajasthan)",
        FacilityCity: "Jodhpur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20913,
        Pin: 416701,
        DispatchCenter: "Lanja_GondeSakhalRd_D (Maharashtra)",
        OriginCenter: "Lanja_GondeSakhalRd_D (Maharashtra)",
        FacilityCity: "Lanja",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20914,
        Pin: 230301,
        DispatchCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        OriginCenter: "Mungra_Machalishahar_D (Uttar Pradesh)",
        FacilityCity: "Mungra Badshahpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20915,
        Pin: 782481,
        DispatchCenter: "Howraghat_Samaguri_D (Assam)",
        OriginCenter: "Howraghat_Samaguri_D (Assam)",
        FacilityCity: "Howraghat",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20916,
        Pin: 455003,
        DispatchCenter: "Dewas_TriweniNagar_D (Madhya Pradesh)",
        OriginCenter: "Dewas_TriweniNagar_D (Madhya Pradesh)",
        FacilityCity: "Dewas",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20917,
        Pin: 676513,
        DispatchCenter: "Malappuram_Perintalmanna_D (Kerala)",
        OriginCenter: "Malappuram_Perintalmanna_D (Kerala)",
        FacilityCity: "Malappuram",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20918,
        Pin: 713153,
        DispatchCenter: "Panagarh_Kanksa_D (West Bengal)",
        OriginCenter: "Panagarh_Kanksa_D (West Bengal)",
        FacilityCity: "Panagarh",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20919,
        Pin: 757087,
        DispatchCenter: "Udala_Mayurbhanj_DPP (Orissa)",
        OriginCenter: "Udala_Mayurbhanj_DPP (Orissa)",
        FacilityCity: "Udala",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20920,
        Pin: 273202,
        DispatchCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        OriginCenter: "Gorakhpur_Bhauwapar_H (Uttar Pradesh)",
        FacilityCity: "Gorakhpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20921,
        Pin: 503308,
        DispatchCenter: "Metpally_GunjRDPP_D (Telangana)",
        OriginCenter: "Metpally_GunjRDPP_D (Telangana)",
        FacilityCity: "Metpally",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20922,
        Pin: 391120,
        DispatchCenter: "Nasvadi_KNRd_DPP (Gujarat)",
        OriginCenter: "Nasvadi_KNRd_DPP (Gujarat)",
        FacilityCity: "Nasvadi",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20923,
        Pin: 587126,
        DispatchCenter: "Bagalkot_NavaNgr_D (Karnataka)",
        OriginCenter: "Bagalkot_NavaNgr_D (Karnataka)",
        FacilityCity: "Bagalkot",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20924,
        Pin: 313016,
        DispatchCenter: "Udaipur_Balicha_H (Rajasthan)",
        OriginCenter: "Udaipur_Balicha_H (Rajasthan)",
        FacilityCity: "Udaipur",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20925,
        Pin: 410216,
        DispatchCenter: "Mumbai_Ariwali_L (Maharashtra)",
        OriginCenter: "Mumbai_Ariwali_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20926,
        Pin: 136129,
        DispatchCenter: "Pehowa_ModelTown_DPP (Haryana)",
        OriginCenter: "Pehowa_ModelTown_DPP (Haryana)",
        FacilityCity: "Pehowa",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20927,
        Pin: 713147,
        DispatchCenter: "Guskhara_DignagarRd_D (West Bengal)",
        OriginCenter: "Guskhara_DignagarRd_D (West Bengal)",
        FacilityCity: "Guskhara",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20928,
        Pin: 577520,
        DispatchCenter: "Chitradurga_Basaveshwar_D (Karnataka)",
        OriginCenter: "Chitradurga_Basaveshwar_D (Karnataka)",
        FacilityCity: "Chitradurga",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20929,
        Pin: 721444,
        DispatchCenter: "Contai_Bankaberya_D (West Bengal)",
        OriginCenter: "Contai_Bankaberya_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20930,
        Pin: 302042,
        DispatchCenter: "Jaipur_RingRoad_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20931,
        Pin: 517429,
        DispatchCenter: "Chittoor_Bangarupalyam_D (Andhra Pradesh)",
        OriginCenter: "Chittoor_Bangarupalyam_D (Andhra Pradesh)",
        FacilityCity: "Palamaner",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20932,
        Pin: 786010,
        DispatchCenter: "Dibrugarh_Mohanbari_H (Assam)",
        OriginCenter: "Dibrugarh_Mohanbari_H (Assam)",
        FacilityCity: "Dibrugarh",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20933,
        Pin: 639118,
        DispatchCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        OriginCenter: "Karur_Polytechnic_D (Tamil Nadu)",
        FacilityCity: "Karur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20934,
        Pin: 263153,
        DispatchCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        OriginCenter: "Rudrapur_Daanpur_H (Uttarakhand)",
        FacilityCity: "Rudrapur",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20935,
        Pin: 203392,
        DispatchCenter: "Dibai_Galibpur_D (Uttar Pradesh)",
        OriginCenter: "Dibai_Galibpur_D (Uttar Pradesh)",
        FacilityCity: "Dibai",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20936,
        Pin: 312619,
        DispatchCenter: "Ratlam_Sailana_D (Madhya Pradesh)",
        OriginCenter: "Ratlam_Sailana_D (Madhya Pradesh)",
        FacilityCity: "Ratlam",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20937,
        Pin: 560021,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20938,
        Pin: 670314,
        DispatchCenter: "Kannur_Payyanur_D (Kerala)",
        OriginCenter: "Kannur_Payyanur_D (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20939,
        Pin: 302022,
        DispatchCenter: "Jaipur_Ramchandpura_L (Rajasthan)",
        OriginCenter: "Jaipur_Ramchandpura_L (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20940,
        Pin: 845456,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20941,
        Pin: 731122,
        DispatchCenter: "Dubrajpur_Mansayer_D (West Bengal)",
        OriginCenter: "Dubrajpur_Mansayer_D (West Bengal)",
        FacilityCity: "Dubrajpur",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20942,
        Pin: 736204,
        DispatchCenter: "CoochBehar_khagrabari_I (West Bengal)",
        OriginCenter: "CoochBehar_khagrabari_I (West Bengal)",
        FacilityCity: "Cooch Behar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20943,
        Pin: 782123,
        DispatchCenter: "Nagaon_Bangthai_H (Assam)",
        OriginCenter: "Nagaon_Bangthai_H (Assam)",
        FacilityCity: "Nagaon",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20944,
        Pin: 228171,
        DispatchCenter: "Dostpur_Bhabbaltola_D (Uttar Pradesh)",
        OriginCenter: "Dostpur_Bhabbaltola_D (Uttar Pradesh)",
        FacilityCity: "Dostpur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20945,
        Pin: 173221,
        DispatchCenter: "Solan_SayriGhat_DPP (Himachal Pradesh)",
        OriginCenter: "Solan_SayriGhat_DPP (Himachal Pradesh)",
        FacilityCity: "Solan",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20946,
        Pin: 471001,
        DispatchCenter: "Chhatarpur_SagarRd_D (Madhya Pradesh)",
        OriginCenter: "Chhatarpur_SagarRd_D (Madhya Pradesh)",
        FacilityCity: "Chhatarpur",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20947,
        Pin: 517418,
        DispatchCenter: "Madanapalle_Gurramkonda_D (Andhra Pradesh)",
        OriginCenter: "Madanapalle_Gurramkonda_D (Andhra Pradesh)",
        FacilityCity: "Madanapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20948,
        Pin: 643001,
        DispatchCenter: "Ooty_Westmere_D (Tamil Nadu)",
        OriginCenter: "Ooty_Westmere_D (Tamil Nadu)",
        FacilityCity: "Ooty",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20949,
        Pin: 577432,
        DispatchCenter: "Thirthahalli_Melinakuruvali2_DPP (Karnataka)",
        OriginCenter: "Thirthahalli_Melinakuruvali2_DPP (Karnataka)",
        FacilityCity: "Thirthahalli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20950,
        Pin: 422301,
        DispatchCenter: "Nashik_Ojhar_DPP (Maharashtra)",
        OriginCenter: "Nashik_Ojhar_DPP (Maharashtra)",
        FacilityCity: "Nashik",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20951,
        Pin: 502246,
        DispatchCenter: "Zahirabad_Jharasangam_D (Telangana)",
        OriginCenter: "Zahirabad_Jharasangam_D (Telangana)",
        FacilityCity: "Zahirabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20952,
        Pin: 431702,
        DispatchCenter: "Hingoli_Tapdiyanagar_D (Maharashtra)",
        OriginCenter: "Hingoli_Tapdiyanagar_D (Maharashtra)",
        FacilityCity: "Hingoli",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20953,
        Pin: 570005,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20954,
        Pin: 413702,
        DispatchCenter: "Shrigonda_Belwandi_D (Maharashtra)",
        OriginCenter: "Shrigonda_Belwandi_D (Maharashtra)",
        FacilityCity: "Shrigonda",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20955,
        Pin: 624804,
        DispatchCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        OriginCenter: "Dindigul_Kottapatti_D (Tamil Nadu)",
        FacilityCity: "Dindigul",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20956,
        Pin: 490036,
        DispatchCenter: "Raipur_Guma_H (Chhattisgarh)",
        OriginCenter: "Raipur_Guma_H (Chhattisgarh)",
        FacilityCity: "Raipur",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20957,
        Pin: 642132,
        DispatchCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        OriginCenter: "Udumalaipettai_Gangthrn_D (Tamil Nadu)",
        FacilityCity: "Udumalaipettai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20958,
        Pin: 403712,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 20959,
        Pin: 500064,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20960,
        Pin: 630211,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20961,
        Pin: 781302,
        DispatchCenter: "Sarthebari_Tmlichp_D (Assam)",
        OriginCenter: "Sarthebari_Tmlichp_D (Assam)",
        FacilityCity: "Sarthebari",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 20962,
        Pin: 452019,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20963,
        Pin: 500873,
        DispatchCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        OriginCenter: "Hyderabad_BalanagarPhase2_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20964,
        Pin: 680736,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20965,
        Pin: 246764,
        DispatchCenter: "Bijnor_Garhi_D (Uttar Pradesh)",
        OriginCenter: "Bijnor_Garhi_D (Uttar Pradesh)",
        FacilityCity: "Bijnor",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20966,
        Pin: 627252,
        DispatchCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        OriginCenter: "Tirunelveli_Krishnapuram_H (Tamil Nadu)",
        FacilityCity: "Tirunelveli",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20967,
        Pin: 391250,
        DispatchCenter: "Karjan_Junabzr_DC (Gujarat)",
        OriginCenter: "Karjan_Junabzr_DC (Gujarat)",
        FacilityCity: "Karjan",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 20968,
        Pin: 495669,
        DispatchCenter: "Champa_Sindhicolony_D (Chhattisgarh)",
        OriginCenter: "Champa_Sindhicolony_D (Chhattisgarh)",
        FacilityCity: "Champa",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20969,
        Pin: 229203,
        DispatchCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        OriginCenter: "Lalganj_AzadNgrRd_D (Uttar Pradesh)",
        FacilityCity: "Lalganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 20970,
        Pin: 474002,
        DispatchCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        OriginCenter: "Gwalior_PuraniChhawani_P (Madhya Pradesh)",
        FacilityCity: "Gwalior",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 20971,
        Pin: 126111,
        DispatchCenter: "Assandh_Dholchowk_D (Haryana)",
        OriginCenter: "Assandh_Dholchowk_D (Haryana)",
        FacilityCity: "Assandh",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20972,
        Pin: 450771,
        DispatchCenter: "Khandwa_TilakNagar_D (Madhya Pradesh)",
        OriginCenter: "Khandwa_TilakNagar_D (Madhya Pradesh)",
        FacilityCity: "Khandwa",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20973,
        Pin: 700120,
        DispatchCenter: "Kolkata_Dopere_L (West Bengal)",
        OriginCenter: "Kolkata_Dopere_L (West Bengal)",
        FacilityCity: "Kolkata",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20974,
        Pin: 632501,
        DispatchCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        OriginCenter: "Ranipet_Gandhinagar_D (Tamil Nadu)",
        FacilityCity: "Ranipet",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20975,
        Pin: 576001,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20976,
        Pin: 784147,
        DispatchCenter: "Mangaldoi_Patharughat_D (Assam)",
        OriginCenter: "Mangaldoi_Patharughat_D (Assam)",
        FacilityCity: "Mangaldoi",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20977,
        Pin: 534222,
        DispatchCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        OriginCenter: "Tanuku_GunturVriSt_D (Andhra Pradesh)",
        FacilityCity: "Tanuku",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20978,
        Pin: 638462,
        DispatchCenter: "Sathyamangalam_VNSNagar_D (Tamil Nadu)",
        OriginCenter: "Sathyamangalam_VNSNagar_D (Tamil Nadu)",
        FacilityCity: "Sathyamangalam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20979,
        Pin: 144044,
        DispatchCenter: "Nakodar_Goldenavenue_D (Punjab)",
        OriginCenter: "Nakodar_Goldenavenue_D (Punjab)",
        FacilityCity: "Nakodar",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 20980,
        Pin: 607107,
        DispatchCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        OriginCenter: "Viluppuram_GenjiRd_I (Tamil Nadu)",
        FacilityCity: "Viluppuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20981,
        Pin: 713132,
        DispatchCenter: "Nanoor_Central_DPP_1 (West Bengal)",
        OriginCenter: "Nanoor_Central_DPP_1 (West Bengal)",
        FacilityCity: "Nanoor",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 20982,
        Pin: 603105,
        DispatchCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        OriginCenter: "Chennai_Nagalkeni_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20983,
        Pin: 643212,
        DispatchCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        OriginCenter: "Gudalur_1stMile_D (Tamil Nadu)",
        FacilityCity: "Gudalur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20984,
        Pin: 393120,
        DispatchCenter: "Jhagadia_Ranipara_DPP (Gujarat)",
        OriginCenter: "Jhagadia_Ranipara_DPP (Gujarat)",
        FacilityCity: "Jhagadia",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 20985,
        Pin: 624414,
        DispatchCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        OriginCenter: "Madurai_Avaniyapuram_H (Tamil Nadu)",
        FacilityCity: "Madurai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20986,
        Pin: 751001,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20987,
        Pin: 283202,
        DispatchCenter: "Firozabad_MahadeoNagar_D (Uttar Pradesh)",
        OriginCenter: "Firozabad_MahadeoNagar_D (Uttar Pradesh)",
        FacilityCity: "Firozabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20988,
        Pin: 680015,
        DispatchCenter: "Arimbur_Elthuruth_D (Kerala)",
        OriginCenter: "Arimbur_Elthuruth_D (Kerala)",
        FacilityCity: "Arimbur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 20989,
        Pin: 127310,
        DispatchCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        OriginCenter: "CharkhiDadri_Ramnagar_D (Haryana)",
        FacilityCity: "Charkhi Dadri",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 20990,
        Pin: 483225,
        DispatchCenter: "Sihora_Mahavirchk_D (Madhya Pradesh)",
        OriginCenter: "Sihora_Mahavirchk_D (Madhya Pradesh)",
        FacilityCity: "Sihora",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 20991,
        Pin: 788025,
        DispatchCenter: "Silchar_Ambikapur_H (Assam)",
        OriginCenter: "Silchar_Ambikapur_H (Assam)",
        FacilityCity: "Silchar",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 20992,
        Pin: 402107,
        DispatchCenter: "Pen_Chinchpada_D (Maharashtra)",
        OriginCenter: "Pen_Chinchpada_D (Maharashtra)",
        FacilityCity: "Pen",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20993,
        Pin: 431521,
        DispatchCenter: "Parbhani_Gajanannagar_D (Maharashtra)",
        OriginCenter: "Parbhani_Gajanannagar_D (Maharashtra)",
        FacilityCity: "Parbhani",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 20994,
        Pin: 493554,
        DispatchCenter: "Basna_SSM_DPP (Chhattisgarh)",
        OriginCenter: "Basna_SSM_DPP (Chhattisgarh)",
        FacilityCity: "Basna",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20995,
        Pin: 570026,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 20996,
        Pin: 734404,
        DispatchCenter: "Siliguri_Telipara_L (West Bengal)",
        OriginCenter: "Siliguri_Fulbari_H (West Bengal)",
        FacilityCity: "Siliguri",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 20997,
        Pin: 601205,
        DispatchCenter: "Gummudipoondi_Minjur_D (Tamil Nadu)",
        OriginCenter: "Gummudipoondi_Minjur_D (Tamil Nadu)",
        FacilityCity: "Gummudipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 20998,
        Pin: 673643,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 20999,
        Pin: 500112,
        DispatchCenter: "Hyderabad_Shamshbd1_L (Telangana)",
        OriginCenter: "Hyderabad_Shamshbd_H (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21000,
        Pin: 673586,
        DispatchCenter: "Thamarassery_Raoth_D (Kerala)",
        OriginCenter: "Thamarassery_Raoth_D (Kerala)",
        FacilityCity: "Thamarassery",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 21001,
        Pin: 484001,
        DispatchCenter: "Shahdol_Jamui_D (Madhya Pradesh)",
        OriginCenter: "Shahdol_Jamui_D (Madhya Pradesh)",
        FacilityCity: "Shahdol",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 21002,
        Pin: 721626,
        DispatchCenter: "Bajkul_Kismat_D (West Bengal)",
        OriginCenter: "Bajkul_Kismat_D (West Bengal)",
        FacilityCity: "Bajkul",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21003,
        Pin: 847429,
        DispatchCenter: "Darbhanga_Madhpur_I (Bihar)",
        OriginCenter: "Darbhanga_Madhpur_I (Bihar)",
        FacilityCity: "Darbhanga",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21004,
        Pin: 585309,
        DispatchCenter: "Shahapur_LxmiNgr_D (Karnataka)",
        OriginCenter: "Shahapur_LxmiNgr_D (Karnataka)",
        FacilityCity: "Shahapur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21005,
        Pin: 390009,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 21006,
        Pin: 721163,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21007,
        Pin: 683587,
        DispatchCenter: "Aluva_Kalady_D (Kerala)",
        OriginCenter: "Aluva_Kalady_D (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 21008,
        Pin: 396321,
        DispatchCenter: "Bilimora_DC (Gujarat)",
        OriginCenter: "Bilimora_DC (Gujarat)",
        FacilityCity: "Bilimora",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 21009,
        Pin: 524152,
        DispatchCenter: "Nellore_Allur_D (Andhra Pradesh)",
        OriginCenter: "Nellore_Allur_D (Andhra Pradesh)",
        FacilityCity: "Nellore",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21010,
        Pin: 140506,
        DispatchCenter: "Chandigarh_Rajpura_L (Punjab)",
        OriginCenter: "Chandigarh_Rajpura_GW (Punjab)",
        FacilityCity: "Rajpura",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 21011,
        Pin: 583131,
        DispatchCenter: "Harpanahalli_KHBClny_D (Karnataka)",
        OriginCenter: "Harpanahalli_KHBClny_D (Karnataka)",
        FacilityCity: "Harpanahalli",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21012,
        Pin: 689546,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 21013,
        Pin: 721101,
        DispatchCenter: "Midnapore_Harishpur_D (West Bengal)",
        OriginCenter: "Midnapore_Harishpur_D (West Bengal)",
        FacilityCity: "Midnapore",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21014,
        Pin: 249192,
        DispatchCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        OriginCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        FacilityCity: "Rishikesh",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 21015,
        Pin: 360440,
        DispatchCenter: "Dhoraji_StationRd_DPP (Gujarat)",
        OriginCenter: "Dhoraji_StationRd_DPP (Gujarat)",
        FacilityCity: "Dhoraji",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 21016,
        Pin: 756043,
        DispatchCenter: "RajBerhampur_Gohira_DPP (Orissa)",
        OriginCenter: "RajBerhampur_Gohira_DPP (Orissa)",
        FacilityCity: "Raj Berhampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 21017,
        Pin: 275204,
        DispatchCenter: "Dullahpur_Bhurkura_D (Uttar Pradesh)",
        OriginCenter: "Dullahpur_Bhurkura_D (Uttar Pradesh)",
        FacilityCity: "Dullahpur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21018,
        Pin: 613303,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21019,
        Pin: 306023,
        DispatchCenter: "MarwarJunction_Karoliya_DPP (Rajasthan)",
        OriginCenter: "MarwarJunction_Karoliya_DPP (Rajasthan)",
        FacilityCity: "Marwar Junction",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21020,
        Pin: 844509,
        DispatchCenter: "ManharBazar_NayaGaon_D (Bihar)",
        OriginCenter: "ManharBazar_NayaGaon_D (Bihar)",
        FacilityCity: "Mahnar Bazar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21021,
        Pin: 473554,
        DispatchCenter: "Shivpuri_GwliorBypas_D (Madhya Pradesh)",
        OriginCenter: "Shivpuri_GwliorBypas_D (Madhya Pradesh)",
        FacilityCity: "Shivpuri",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 21022,
        Pin: 689615,
        DispatchCenter: "Ranni_Chethakhl_D (Kerala)",
        OriginCenter: "Ranni_Chethakhl_D (Kerala)",
        FacilityCity: "Ranni",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 21023,
        Pin: 682026,
        DispatchCenter: "Cochin_Kalamaserry_L (Kerala)",
        OriginCenter: "Cochin_Kalamaserry_L (Kerala)",
        FacilityCity: "Aluva",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 21024,
        Pin: 242220,
        DispatchCenter: "Jalalabad_Bareillyroad_D (Uttar Pradesh)",
        OriginCenter: "Jalalabad_Bareillyroad_D (Uttar Pradesh)",
        FacilityCity: "JalalabadUP",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21025,
        Pin: 145024,
        DispatchCenter: "Pathankot_Kahanpur_H (Punjab)",
        OriginCenter: "Pathankot_Kahanpur_H (Punjab)",
        FacilityCity: "Pathankot",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 21026,
        Pin: 670010,
        DispatchCenter: "Kannur_Koodali_H (Kerala)",
        OriginCenter: "Kannur_Koodali_H (Kerala)",
        FacilityCity: "Kannur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 21027,
        Pin: 243702,
        DispatchCenter: "Milak_Bhaisoori_D (Uttar Pradesh)",
        OriginCenter: "Milak_Bhaisoori_D (Uttar Pradesh)",
        FacilityCity: "Milak",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21028,
        Pin: 576101,
        DispatchCenter: "Manipal_IndstrlArea_L (Karnataka)",
        OriginCenter: "Manipal_IndstrlArea_L (Karnataka)",
        FacilityCity: "Manipal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21029,
        Pin: 332715,
        DispatchCenter: "Reengus_TegoreViharColony_D (Rajasthan)",
        OriginCenter: "Reengus_TegoreViharColony_D (Rajasthan)",
        FacilityCity: "Reengus",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21030,
        Pin: 175029,
        DispatchCenter: "Chachyot_Chailchowk_DPP (Himachal Pradesh)",
        OriginCenter: "Chachyot_Chailchowk_DPP (Himachal Pradesh)",
        FacilityCity: "Chachyot",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 21031,
        Pin: 250106,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21032,
        Pin: 517330,
        DispatchCenter: "Madanapalle_RNaiduClny_D (Andhra Pradesh)",
        OriginCenter: "Madanapalle_RNaiduClny_D (Andhra Pradesh)",
        FacilityCity: "Madanapalle",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21033,
        Pin: 686122,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 21034,
        Pin: 685619,
        DispatchCenter: "Kuthumkal_Matheckal_D (Kerala)",
        OriginCenter: "Kuthumkal_Matheckal_D (Kerala)",
        FacilityCity: "Kuthumkal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 21035,
        Pin: 387375,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 21036,
        Pin: 560054,
        DispatchCenter: "Bangalore_Nelamangla_L (Karnataka)",
        OriginCenter: "Bangalore_Nelamangla_H (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21037,
        Pin: 504296,
        DispatchCenter: "Kaghaznagar_Bhatpalle_D (Telangana)",
        OriginCenter: "Kaghaznagar_Bhatpalle_D (Telangana)",
        FacilityCity: "Kaghaznagar",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21038,
        Pin: 271872,
        DispatchCenter: "Bahraich_Dularpur_H (Uttar Pradesh)",
        OriginCenter: "Bahraich_Dularpur_H (Uttar Pradesh)",
        FacilityCity: "Bahraich",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21039,
        Pin: 613001,
        DispatchCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        OriginCenter: "Thanjavur_Parvathinagar_D (Tamil Nadu)",
        FacilityCity: "Thanjavur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21040,
        Pin: 363430,
        DispatchCenter: "Limbdi_Sayla_D (Gujarat)",
        OriginCenter: "Limbdi_Sayla_D (Gujarat)",
        FacilityCity: "Limbdi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 21041,
        Pin: 712515,
        DispatchCenter: "Balagarh_Police_D (West Bengal)",
        OriginCenter: "Balagarh_Police_D (West Bengal)",
        FacilityCity: "Balagarh",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21042,
        Pin: 303329,
        DispatchCenter: "Phulera_NarenaRD_D (Rajasthan)",
        OriginCenter: "Phulera_NarenaRD_D (Rajasthan)",
        FacilityCity: "Phulera",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21043,
        Pin: 211019,
        DispatchCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        OriginCenter: "Allahabad_Rasulabad_H (Uttar Pradesh)",
        FacilityCity: "Allahabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21044,
        Pin: 755041,
        DispatchCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        OriginCenter: "Chandikhol_Gandhichhak_D (Orissa)",
        FacilityCity: "Chandikhol",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 21045,
        Pin: 440013,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 21046,
        Pin: 464990,
        DispatchCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        OriginCenter: "Bhopal_Bhairopur_L (Madhya Pradesh)",
        FacilityCity: "Bhopal",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 21047,
        Pin: 821002,
        DispatchCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        OriginCenter: "Bhagalpur_Vikramshila_D (Bihar)",
        FacilityCity: "Bhagalpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21048,
        Pin: 184143,
        DispatchCenter: "Kathua_Kharote_D (Jammu & Kashmir)",
        OriginCenter: "Kathua_Kharote_D (Jammu & Kashmir)",
        FacilityCity: "Kathua",
        FacilityState: "Jammu & Kashmir",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 21049,
        Pin: 756020,
        DispatchCenter: "Balasore_Ganeswarpur_D (Orissa)",
        OriginCenter: "Balasore_Ganeswarpur_D (Orissa)",
        FacilityCity: "Balasore",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 21050,
        Pin: 577122,
        DispatchCenter: "Mudigere_HesglDPP_D (Karnataka)",
        OriginCenter: "Mudigere_HesglDPP_D (Karnataka)",
        FacilityCity: "Mudigere",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21051,
        Pin: 124022,
        DispatchCenter: "Meham_Ward13_D (Haryana)",
        OriginCenter: "Meham_Ward13_D (Haryana)",
        FacilityCity: "Meham",
        FacilityState: "Haryana",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21052,
        Pin: 415110,
        DispatchCenter: "Karad_Malkapur1_D (Maharashtra)",
        OriginCenter: "Karad_Malkapur1_D (Maharashtra)",
        FacilityCity: "Karad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 21053,
        Pin: 591102,
        DispatchCenter: "Bailhongal_Azadnagar_D (Karnataka)",
        OriginCenter: "Bailhongal_Azadnagar_D (Karnataka)",
        FacilityCity: "Bailhongal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21054,
        Pin: 786006,
        DispatchCenter: "Dibrugarh_Mohanbari_H (Assam)",
        OriginCenter: "Dibrugarh_Mohanbari_H (Assam)",
        FacilityCity: "Dibrugarh",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 21055,
        Pin: 382870,
        DispatchCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        OriginCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        FacilityCity: "Vijapur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 21056,
        Pin: 507004,
        DispatchCenter: "Khammam_Jintal_D (Telangana)",
        OriginCenter: "Khammam_Jintal_D (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21057,
        Pin: 176087,
        DispatchCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        OriginCenter: "Palampur_Berachah_D (Himachal Pradesh)",
        FacilityCity: "Palampur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 21058,
        Pin: 172022,
        DispatchCenter: "Rampur_Shingla_D (Himachal Pradesh)",
        OriginCenter: "Rampur_Shingla_D (Himachal Pradesh)",
        FacilityCity: "Rampur Bushahr",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 21059,
        Pin: 388140,
        DispatchCenter: "Anand_Borsad_D (Gujarat)",
        OriginCenter: "Anand_Borsad_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 21060,
        Pin: 515775,
        DispatchCenter: "Gooty_ATPRoad_D (Andhra Pradesh)",
        OriginCenter: "Gooty_ATPRoad_D (Andhra Pradesh)",
        FacilityCity: "Gooty",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21061,
        Pin: 370445,
        DispatchCenter: "Mandvi_Gadhsisa_DPP (Gujarat)",
        OriginCenter: "Mandvi_Gadhsisa_DPP (Gujarat)",
        FacilityCity: "Mandvi",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 21062,
        Pin: 845401,
        DispatchCenter: "Motihari_Chhatauni_H (Bihar)",
        OriginCenter: "Motihari_Chhatauni_H (Bihar)",
        FacilityCity: "Motihari",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21063,
        Pin: 521109,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21064,
        Pin: 734226,
        DispatchCenter: "TistaBazar_TeestaBridge_DPP (West Bengal)",
        OriginCenter: "TistaBazar_TeestaBridge_DPP (West Bengal)",
        FacilityCity: "Tista Bazar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21065,
        Pin: 855108,
        DispatchCenter: "Bahadurganj_Patbhari_D (Bihar)",
        OriginCenter: "Bahadurganj_Patbhari_D (Bihar)",
        FacilityCity: "Bahadurganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21066,
        Pin: 752070,
        DispatchCenter: "Nayagarh_Durgprsd_D (Orissa)",
        OriginCenter: "Nayagarh_Durgprsd_D (Orissa)",
        FacilityCity: "Nayagarh",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 21067,
        Pin: 507165,
        DispatchCenter: "Wyra_Thallada_D (Telangana)",
        OriginCenter: "Wyra_Thallada_D (Telangana)",
        FacilityCity: "Wyra",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21068,
        Pin: 325209,
        DispatchCenter: "Baran_Housingboard_D (Rajasthan)",
        OriginCenter: "Baran_Housingboard_D (Rajasthan)",
        FacilityCity: "Baran",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21069,
        Pin: 590018,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21070,
        Pin: 458228,
        DispatchCenter: "RatangarhMP_Ward12_D (Madhya Pradesh)",
        OriginCenter: "RatangarhMP_Ward12_D (Madhya Pradesh)",
        FacilityCity: "Ratangarh-MP",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 21071,
        Pin: 332318,
        DispatchCenter: "Laxmangarh_Wrd19DPP_D (Rajasthan)",
        OriginCenter: "Laxmangarh_Wrd19DPP_D (Rajasthan)",
        FacilityCity: "Laxmangarh-RJ",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21072,
        Pin: 416609,
        DispatchCenter: "Kankavali_NrdawDPP_D (Maharashtra)",
        OriginCenter: "Kankavali_NrdawDPP_D (Maharashtra)",
        FacilityCity: "Kankavali",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 21073,
        Pin: 632057,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21074,
        Pin: 440007,
        DispatchCenter: "Nagpur_Khapari_L (Maharashtra)",
        OriginCenter: "Nagpur_Khapari_GW (Maharashtra)",
        FacilityCity: "Nagpur",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 21075,
        Pin: 686641,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 21076,
        Pin: 843351,
        DispatchCenter: "Sheohar_Ward13_D (Bihar)",
        OriginCenter: "Sheohar_Ward13_D (Bihar)",
        FacilityCity: "Sheohar",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21077,
        Pin: 673578,
        DispatchCenter: "Mananthavady_Central_D_1 (Kerala)",
        OriginCenter: "Mananthavady_Central_D_1 (Kerala)",
        FacilityCity: "Mananthavadi",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 21078,
        Pin: 843325,
        DispatchCenter: "Sheohar_Ward13_D (Bihar)",
        OriginCenter: "Sheohar_Ward13_D (Bihar)",
        FacilityCity: "Sheohar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21079,
        Pin: 274302,
        DispatchCenter: "Sewarhi_SaragatiaKaran_D (Uttar Pradesh)",
        OriginCenter: "Sewarhi_SaragatiaKaran_D (Uttar Pradesh)",
        FacilityCity: "Sewarhi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21080,
        Pin: 823002,
        DispatchCenter: "Gaya_Matihani_H (Bihar)",
        OriginCenter: "Gaya_Matihani_H (Bihar)",
        FacilityCity: "Gaya",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21081,
        Pin: 425306,
        DispatchCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        OriginCenter: "Raver_BurhanpurRoad_D (Maharashtra)",
        FacilityCity: "Raver",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 21082,
        Pin: 787032,
        DispatchCenter: "NorthLakhimpur_Charaimoriya_D (Assam)",
        OriginCenter: "NorthLakhimpur_Charaimoriya_D (Assam)",
        FacilityCity: "North Lakhimpur",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 21083,
        Pin: 441911,
        DispatchCenter: "Gondia_Tirora_DPP (Maharashtra)",
        OriginCenter: "Gondia_Tirora_DPP (Maharashtra)",
        FacilityCity: "Gondia",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 21084,
        Pin: 505162,
        DispatchCenter: "Peddapalli_Rajivnagar_D (Telangana)",
        OriginCenter: "Peddapalli_Rajivnagar_D (Telangana)",
        FacilityCity: "Peddapalli",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21085,
        Pin: 456001,
        DispatchCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        OriginCenter: "Ujjain_Nehrunagar_D (Madhya Pradesh)",
        FacilityCity: "Ujjain",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 21086,
        Pin: 444407,
        DispatchCenter: "Akola_Midcphase2_D (Maharashtra)",
        OriginCenter: "Akola_Midcphase2_D (Maharashtra)",
        FacilityCity: "Akola",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 21087,
        Pin: 520001,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21088,
        Pin: 629001,
        DispatchCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        OriginCenter: "Nagercoil_Muhilanvilai_D (Tamil Nadu)",
        FacilityCity: "Nagercoil",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21089,
        Pin: 500079,
        DispatchCenter: "Hyderabad_Autonagar_L (Telangana)",
        OriginCenter: "Hyderabad_Autonagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21090,
        Pin: 305815,
        DispatchCenter: "Kishangarh_AirportRd_D (Rajasthan)",
        OriginCenter: "Kishangarh_AirportRd_D (Rajasthan)",
        FacilityCity: "Kishangarh",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21091,
        Pin: 844112,
        DispatchCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        OriginCenter: "Muzaffarpur_Gobarsahi_H (Bihar)",
        FacilityCity: "Muzaffarpur",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21092,
        Pin: 205147,
        DispatchCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        OriginCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        FacilityCity: "Shikohabad",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21093,
        Pin: 362015,
        DispatchCenter: "Junagadh_Dolatpara_I (Gujarat)",
        OriginCenter: "Junagadh_Dolatpara_I (Gujarat)",
        FacilityCity: "Junagadh",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 21094,
        Pin: 507002,
        DispatchCenter: "Khammam_Mudigonda_H (Telangana)",
        OriginCenter: "Khammam_Mudigonda_H (Telangana)",
        FacilityCity: "Khammam",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21095,
        Pin: 401503,
        DispatchCenter: "Palghar_Maan_L (Maharashtra)",
        OriginCenter: "Palghar_Maan_L (Maharashtra)",
        FacilityCity: "Palghar",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 21096,
        Pin: 521180,
        DispatchCenter: "Nandigama_Mukkapatinagar_D (Andhra Pradesh)",
        OriginCenter: "Nandigama_Mukkapatinagar_D (Andhra Pradesh)",
        FacilityCity: "Nandigama",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21097,
        Pin: 520013,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21098,
        Pin: 638058,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21099,
        Pin: 754215,
        DispatchCenter: "Kendrapara_Chhagharia_D (Orissa)",
        OriginCenter: "Kendrapara_Chhagharia_D (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 21100,
        Pin: 680703,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 21101,
        Pin: 721607,
        DispatchCenter: "Haldia_Basudevpur_D (West Bengal)",
        OriginCenter: "Haldia_Basudevpur_D (West Bengal)",
        FacilityCity: "Haldia",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21102,
        Pin: 496197,
        DispatchCenter: "Raigarh_MadhubanPara_D (Chhattisgarh)",
        OriginCenter: "Raigarh_MadhubanPara_D (Chhattisgarh)",
        FacilityCity: "Raigarh",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21103,
        Pin: 530024,
        DispatchCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        OriginCenter: "Visakhapatnam_Duvvada_H (Andhra Pradesh)",
        FacilityCity: "Visakhapatnam",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21104,
        Pin: 735136,
        DispatchCenter: "Mekhliganj_WardNo7_DPP (West Bengal)",
        OriginCenter: "Mekhliganj_WardNo7_DPP (West Bengal)",
        FacilityCity: "Mekhliganj",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21105,
        Pin: 586211,
        DispatchCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        OriginCenter: "Indi_MahalaxmiNagar_D (Karnataka)",
        FacilityCity: "Indi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21106,
        Pin: 243505,
        DispatchCenter: "Milak_Bhaisoori_D (Uttar Pradesh)",
        OriginCenter: "Milak_Bhaisoori_D (Uttar Pradesh)",
        FacilityCity: "Milak",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21107,
        Pin: 828117,
        DispatchCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        OriginCenter: "Dhanbad_Tilabani_H (Jharkhand)",
        FacilityCity: "Dhanbad",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21108,
        Pin: 305901,
        DispatchCenter: "Beawar_DC (Rajasthan)",
        OriginCenter: "Beawar_DC (Rajasthan)",
        FacilityCity: "Beawar",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21109,
        Pin: 757032,
        DispatchCenter: "Bangriposi_Majigan_D (Orissa)",
        OriginCenter: "Bangriposi_Majigan_D (Orissa)",
        FacilityCity: "Bangriposi",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 21110,
        Pin: 516330,
        DispatchCenter: "Vempalli_Kathaluru_D (Andhra Pradesh)",
        OriginCenter: "Vempalli_Kathaluru_D (Andhra Pradesh)",
        FacilityCity: "Vempalli",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21111,
        Pin: 689681,
        DispatchCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        OriginCenter: "Pathanamthitta_Mangaram_D (Kerala)",
        FacilityCity: "Pathanamthitta",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 21112,
        Pin: 151206,
        DispatchCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        OriginCenter: "Bathinda_KrishnaNagar_I (Punjab)",
        FacilityCity: "Bathinda",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 21113,
        Pin: 493559,
        DispatchCenter: "Sarangarh_Prtpgrh_DPP (Chhattisgarh)",
        OriginCenter: "Sarangarh_Prtpgrh_DPP (Chhattisgarh)",
        FacilityCity: "Sarangarh",
        FacilityState: "Chhattisgarh",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21114,
        Pin: 785620,
        DispatchCenter: "Jorhat_Chokihaat_I (Assam)",
        OriginCenter: "Jorhat_Chokihaat_I (Assam)",
        FacilityCity: "Jorhat",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 21115,
        Pin: 786160,
        DispatchCenter: "DoomDooma_Kakopathar_D (Assam)",
        OriginCenter: "DoomDooma_Kakopathar_D (Assam)",
        FacilityCity: "Doom Dooma",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 21116,
        Pin: 680704,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 21117,
        Pin: 770033,
        DispatchCenter: "Birmitrapur_Sailata_D (Orissa)",
        OriginCenter: "Birmitrapur_Sailata_D (Orissa)",
        FacilityCity: "Birmitrapur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 21118,
        Pin: 452009,
        DispatchCenter: "Indore_Palda_L (Madhya Pradesh)",
        OriginCenter: "Indore_BarodaArjun_H (Madhya Pradesh)",
        FacilityCity: "Indore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 21119,
        Pin: 442107,
        DispatchCenter: "Wardha_Alodi_D (Maharashtra)",
        OriginCenter: "Wardha_Alodi_D (Maharashtra)",
        FacilityCity: "Wardha",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 21120,
        Pin: 508004,
        DispatchCenter: "Nalgonda_Duppalapally_I (Telangana)",
        OriginCenter: "Nalgonda_Duppalapally_I (Telangana)",
        FacilityCity: "Nalgonda",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21121,
        Pin: 756138,
        DispatchCenter: "Mangalpur_GayatriColony_D (Orissa)",
        OriginCenter: "Mangalpur_GayatriColony_D (Orissa)",
        FacilityCity: "Mangalpur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 21122,
        Pin: 574267,
        DispatchCenter: "Moodbidri_Central_D (Karnataka)",
        OriginCenter: "Moodbidri_Central_D (Karnataka)",
        FacilityCity: "Moodabidri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21123,
        Pin: 151111,
        DispatchCenter: "RampuraPhul_DC (Punjab)",
        OriginCenter: "RampuraPhul_DC (Punjab)",
        FacilityCity: "Rampura Phul",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 21124,
        Pin: 516105,
        DispatchCenter: "Koduru_Jagadampalli_D (Andhra Pradesh)",
        OriginCenter: "Koduru_Jagadampalli_D (Andhra Pradesh)",
        FacilityCity: "Koduru",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21125,
        Pin: 246121,
        DispatchCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        OriginCenter: "Rishikesh_MalviyaNagar_D (Uttarakhand)",
        FacilityCity: "Rishikesh",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 21126,
        Pin: 581316,
        DispatchCenter: "Ankola_Baleguli_D (Karnataka)",
        OriginCenter: "Ankola_Baleguli_D (Karnataka)",
        FacilityCity: "Ankola",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21127,
        Pin: 623101,
        DispatchCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        OriginCenter: "Karaikudi_Sivaganga_D (Tamil Nadu)",
        FacilityCity: "Karaikudi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21128,
        Pin: 783122,
        DispatchCenter: "Goalpara_Dudhnoi_D (Assam)",
        OriginCenter: "Goalpara_Dudhnoi_D (Assam)",
        FacilityCity: "Goalpara",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 21129,
        Pin: 803303,
        DispatchCenter: "Sheonor_Moldiyar_D (Bihar)",
        OriginCenter: "Sheonor_Moldiyar_D (Bihar)",
        FacilityCity: "Sheonar",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21130,
        Pin: 686571,
        DispatchCenter: "Kottayam_Ettumanoor_H (Kerala)",
        OriginCenter: "Kottayam_Ettumanoor_H (Kerala)",
        FacilityCity: "Kottayam",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 21131,
        Pin: 604406,
        DispatchCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        OriginCenter: "Tindivanam_Edyankulam_D (Tamil Nadu)",
        FacilityCity: "Tindivanam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21132,
        Pin: 311301,
        DispatchCenter: "Badnaur_Asind_D (Rajasthan)",
        OriginCenter: "Badnaur_Asind_D (Rajasthan)",
        FacilityCity: "Asind",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21133,
        Pin: 485114,
        DispatchCenter: "Satna_Nazirabad_I (Madhya Pradesh)",
        OriginCenter: "Satna_Nazirabad_I (Madhya Pradesh)",
        FacilityCity: "Satna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 21134,
        Pin: 605102,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21135,
        Pin: 515404,
        DispatchCenter: "Anantapur_Dharmavaram_D (Andhra Pradesh)",
        OriginCenter: "Anantapur_Dharmavaram_D (Andhra Pradesh)",
        FacilityCity: "Anantapur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21136,
        Pin: 480886,
        DispatchCenter: "Lakhnadon_Ward5_D (Madhya Pradesh)",
        OriginCenter: "Lakhnadon_Ward5_D (Madhya Pradesh)",
        FacilityCity: "Lakhnadon",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 21137,
        Pin: 741102,
        DispatchCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        OriginCenter: "Krishnanagar_MallickPara_I (West Bengal)",
        FacilityCity: "Krishnanagar",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21138,
        Pin: 221112,
        DispatchCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        OriginCenter: "Varanasi_Birbhanpur_H (Uttar Pradesh)",
        FacilityCity: "Varanasi",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21139,
        Pin: 577448,
        DispatchCenter: "Thirthahalli_Melinakuruvali2_DPP (Karnataka)",
        OriginCenter: "Thirthahalli_Melinakuruvali2_DPP (Karnataka)",
        FacilityCity: "Thirthahalli",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21140,
        Pin: 246174,
        DispatchCenter: "SrinagarUK_Sandre_D (Uttarakhand)",
        OriginCenter: "SrinagarUK_Sandre_D (Uttarakhand)",
        FacilityCity: "Srinagar-UK",
        FacilityState: "Uttarakhand",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 21141,
        Pin: 509203,
        DispatchCenter: "Tadoor_Nagarkurnool_D (Telangana)",
        OriginCenter: "Tadoor_Nagarkurnool_D (Telangana)",
        FacilityCity: "Tadoor",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21142,
        Pin: 415208,
        DispatchCenter: "Mandangad_Bhingaloli_D (Maharashtra)",
        OriginCenter: "Mandangad_Bhingaloli_D (Maharashtra)",
        FacilityCity: "Mandangad",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 21143,
        Pin: 673637,
        DispatchCenter: "Kozhikode_Central_H (Kerala)",
        OriginCenter: "Kozhikode_Central_H (Kerala)",
        FacilityCity: "Kozhikode",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 21144,
        Pin: 829210,
        DispatchCenter: "Khalari_NayaDhauda_D (Jharkhand)",
        OriginCenter: "Khalari_NayaDhauda_D (Jharkhand)",
        FacilityCity: "Khalari-JR",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21145,
        Pin: 782460,
        DispatchCenter: "Diphu_ShymColony_D (Assam)",
        OriginCenter: "Diphu_ShymColony_D (Assam)",
        FacilityCity: "Diphu",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 21146,
        Pin: 829123,
        DispatchCenter: "Peterbar_GagiDPP_D (Jharkhand)",
        OriginCenter: "Peterbar_GagiDPP_D (Jharkhand)",
        FacilityCity: "Peterbar",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21147,
        Pin: 390024,
        DispatchCenter: "Vadodara_Dashrath_H (Gujarat)",
        OriginCenter: "Vadodara_Dashrath_H (Gujarat)",
        FacilityCity: "Vadodara",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 21148,
        Pin: 382005,
        DispatchCenter: "Gandhinagar_Shertha_L (Gujarat)",
        OriginCenter: "Gandhinagar_Shertha_L (Gujarat)",
        FacilityCity: "Gandhinagar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 21149,
        Pin: 852219,
        DispatchCenter: "Udakishanganj_ClgChwk_D (Bihar)",
        OriginCenter: "Udakishanganj_ClgChwk_D (Bihar)",
        FacilityCity: "Udakishanganj",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21150,
        Pin: 678534,
        DispatchCenter: "Palakkad_Kodumba_D (Kerala)",
        OriginCenter: "Palakkad_Kodumba_D (Kerala)",
        FacilityCity: "Palakkad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 21151,
        Pin: 781129,
        DispatchCenter: "Boko_Barpara_D (Assam)",
        OriginCenter: "Boko_Barpara_D (Assam)",
        FacilityCity: "Boko",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 21152,
        Pin: 281305,
        DispatchCenter: "Mathura_Narsipuramrd_D (Uttar Pradesh)",
        OriginCenter: "Mathura_Narsipuramrd_D (Uttar Pradesh)",
        FacilityCity: "Mathura",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21153,
        Pin: 203408,
        DispatchCenter: "Hapur_Sanjaycolony_D (Uttar Pradesh)",
        OriginCenter: "Hapur_Sanjaycolony_D (Uttar Pradesh)",
        FacilityCity: "Hapur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21154,
        Pin: 142050,
        DispatchCenter: "Faridkot_JatinderChwk_D (Punjab)",
        OriginCenter: "Faridkot_JatinderChwk_D (Punjab)",
        FacilityCity: "Faridkot",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 21155,
        Pin: 609116,
        DispatchCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        OriginCenter: "Sirkazhi_Muthunagar_D (Tamil Nadu)",
        FacilityCity: "Sirkazhi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21156,
        Pin: 248007,
        DispatchCenter: "Dehradun_Badowala_H (Uttarakhand)",
        OriginCenter: "Dehradun_Badowala_H (Uttarakhand)",
        FacilityCity: "Dehradun",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 21157,
        Pin: 176214,
        DispatchCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        OriginCenter: "Kangra_Nandehr_D (Himachal Pradesh)",
        FacilityCity: "Kangra",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 21158,
        Pin: 630502,
        DispatchCenter: "Ponamarvathi_Singampunari_D (Tamil Nadu)",
        OriginCenter: "Ponamarvathi_Singampunari_D (Tamil Nadu)",
        FacilityCity: "Ponnamaravathi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21159,
        Pin: 743299,
        DispatchCenter: "Swarupnagar_MlangaPra_DPP (West Bengal)",
        OriginCenter: "Swarupnagar_MlangaPra_DPP (West Bengal)",
        FacilityCity: "Swarupnagar",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21160,
        Pin: 146021,
        DispatchCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        OriginCenter: "Hoshiarpur_SaroopNagar_D (Punjab)",
        FacilityCity: "Hoshiarpur",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 21161,
        Pin: 607402,
        DispatchCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        OriginCenter: "Puducherry_Navamalkapper_L (Tamil Nadu)",
        FacilityCity: "Pondicherry",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21162,
        Pin: 637407,
        DispatchCenter: "Rasipurm_Knrpati_D (Tamil Nadu)",
        OriginCenter: "Rasipurm_Knrpati_D (Tamil Nadu)",
        FacilityCity: "Rasipuram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21163,
        Pin: 825316,
        DispatchCenter: "Ramgarh_Marar_D (Jharkhand)",
        OriginCenter: "Ramgarh_Marar_D (Jharkhand)",
        FacilityCity: "Ramgarh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21164,
        Pin: 209870,
        DispatchCenter: "Miyanganj_FurkanMarket_DPP (Uttar Pradesh)",
        OriginCenter: "Miyanganj_FurkanMarket_DPP (Uttar Pradesh)",
        FacilityCity: "Miyanganj",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21165,
        Pin: 509126,
        DispatchCenter: "Gadwal_Aiza_D (Telangana)",
        OriginCenter: "Gadwal_Aiza_D (Telangana)",
        FacilityCity: "Gadwal",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21166,
        Pin: 628304,
        DispatchCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        OriginCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        FacilityCity: "Tuticorin",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21167,
        Pin: 470673,
        DispatchCenter: "Hatta_Gourishankar_D (Madhya Pradesh)",
        OriginCenter: "Hatta_Gourishankar_D (Madhya Pradesh)",
        FacilityCity: "Hatta",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 21168,
        Pin: 752028,
        DispatchCenter: "Balugaon_Oldasianhighwy_D (Orissa)",
        OriginCenter: "Balugaon_Oldasianhighwy_D (Orissa)",
        FacilityCity: "Balugaon",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 21169,
        Pin: 695144,
        DispatchCenter: "Attingal_Central_D_2 (Kerala)",
        OriginCenter: "Attingal_Central_D_2 (Kerala)",
        FacilityCity: "Attingal",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 21170,
        Pin: 383245,
        DispatchCenter: "Bhiloda_NirSagarPlaza_D (Gujarat)",
        OriginCenter: "Bhiloda_NirSagarPlaza_D (Gujarat)",
        FacilityCity: "Bhiloda",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 21171,
        Pin: 754223,
        DispatchCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        OriginCenter: "Kendrapara_Dhansnpri_DPP (Orissa)",
        FacilityCity: "Kendrapara",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 21172,
        Pin: 825103,
        DispatchCenter: "Tandwa_Simariya_D (Jharkhand)",
        OriginCenter: "Tandwa_Simariya_D (Jharkhand)",
        FacilityCity: "Tandwa",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21173,
        Pin: 142044,
        DispatchCenter: "Zira_FriendsEncl_D (Punjab)",
        OriginCenter: "Zira_FriendsEncl_D (Punjab)",
        FacilityCity: "Zira",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 21174,
        Pin: 515408,
        DispatchCenter: "Gooty_ATPRoad_D (Andhra Pradesh)",
        OriginCenter: "Gooty_ATPRoad_D (Andhra Pradesh)",
        FacilityCity: "Gooty",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21175,
        Pin: 176107,
        DispatchCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        OriginCenter: "Sujanpur_Busstandrd_D (Himachal Pradesh)",
        FacilityCity: "Sujanpur",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 21176,
        Pin: 531036,
        DispatchCenter: "Chodavaram_Narasayyapeta_D (Andhra Pradesh)",
        OriginCenter: "Chodavaram_Narasayyapeta_D (Andhra Pradesh)",
        FacilityCity: "Chodavaram",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21177,
        Pin: 384465,
        DispatchCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        OriginCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        FacilityCity: "Vijapur",
        FacilityState: "Gujarat",
        Zone : "W1"
      },
      {
        SN: 21178,
        Pin: 679103,
        DispatchCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        OriginCenter: "Pazhayannur_Kattukulam_D (Kerala)",
        FacilityCity: "Pazhayannur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 21179,
        Pin: 303313,
        DispatchCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        OriginCenter: "Bandikui_Mukarpura_D (Rajasthan)",
        FacilityCity: "Bandikui",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21180,
        Pin: 815354,
        DispatchCenter: "Jamtara_Chakrakasabad_D (Jharkhand)",
        OriginCenter: "Jamtara_Chakrakasabad_D (Jharkhand)",
        FacilityCity: "Jamtara",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21181,
        Pin: 560085,
        DispatchCenter: "Bangalore_FCILayout_L (Karnataka)",
        OriginCenter: "Bangalore_FCILayout_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21182,
        Pin: 591156,
        DispatchCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        OriginCenter: "Belgaum_GandhiNgr_I (Karnataka)",
        FacilityCity: "Belgaum",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21183,
        Pin: 583227,
        DispatchCenter: "Gangavathi_CBSGanj_DPP (Karnataka)",
        OriginCenter: "Gangavathi_CBSGanj_DPP (Karnataka)",
        FacilityCity: "Gangavathi",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21184,
        Pin: 785684,
        DispatchCenter: "Nazira_Amina_D (Assam)",
        OriginCenter: "Nazira_Amina_D (Assam)",
        FacilityCity: "Nazira",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 21185,
        Pin: 784509,
        DispatchCenter: "Udalguri_Ramjanakichowk_D (Assam)",
        OriginCenter: "Udalguri_Ramjanakichowk_D (Assam)",
        FacilityCity: "Udalguri",
        FacilityState: "Assam",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 21186,
        Pin: 841246,
        DispatchCenter: "Siwan_Atarsua_D (Bihar)",
        OriginCenter: "Siwan_Atarsua_D (Bihar)",
        FacilityCity: "Siwan",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21187,
        Pin: 641026,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21188,
        Pin: 250013,
        DispatchCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        OriginCenter: "Meerut_Panchlikhurd_H (Uttar Pradesh)",
        FacilityCity: "Meerut",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21189,
        Pin: 494333,
        DispatchCenter: "Charama_Nakapara_D (Chhattisgarh)",
        OriginCenter: "Charama_Nakapara_D (Chhattisgarh)",
        FacilityCity: "Charama",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21190,
        Pin: 181202,
        DispatchCenter: "Akhnoor_MiltryHosp_D (Jammu & Kashmir)",
        OriginCenter: "Akhnoor_MiltryHosp_D (Jammu & Kashmir)",
        FacilityCity: "Akhnoor",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 21191,
        Pin: 383462,
        DispatchCenter: "Bhiloda_NirSagarPlaza_D (Gujarat)",
        OriginCenter: "Bhiloda_NirSagarPlaza_D (Gujarat)",
        FacilityCity: "Bhiloda",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 21192,
        Pin: 400104,
        DispatchCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        OriginCenter: "Mumbai_JogeshwriWest_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 21193,
        Pin: 635114,
        DispatchCenter: "Hosur_SipcotPhase1_L (Tamil Nadu)",
        OriginCenter: "Hosur_SipcotPhase1_L (Tamil Nadu)",
        FacilityCity: "Hosur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21194,
        Pin: 110032,
        DispatchCenter: "Delhi_JhilmilColony_L (Delhi)",
        OriginCenter: "Delhi_JhilmilColony_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21195,
        Pin: 571249,
        DispatchCenter: "Gonikoppal_Patelnagar_D (Karnataka)",
        OriginCenter: "Gonikoppal_Patelnagar_D (Karnataka)",
        FacilityCity: "Gonikoppal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21196,
        Pin: 274704,
        DispatchCenter: "Salempur_BhatparRani_D (Uttar Pradesh)",
        OriginCenter: "Salempur_BhatparRani_D (Uttar Pradesh)",
        FacilityCity: "Salempur",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21197,
        Pin: 721447,
        DispatchCenter: "Contai_Egra_D (West Bengal)",
        OriginCenter: "Contai_Egra_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21198,
        Pin: 799155,
        DispatchCenter: "Santirbazar_Charakbai_D (Tripura)",
        OriginCenter: "NSZ",
        FacilityCity: "Santirbazar",
        FacilityState: "Tripura",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 21199,
        Pin: 713325,
        DispatchCenter: "Asansol_Gobindapur_D (West Bengal)",
        OriginCenter: "Asansol_Gobindapur_D (West Bengal)",
        FacilityCity: "Asansol",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21200,
        Pin: 712250,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21201,
        Pin: 506331,
        DispatchCenter: "Warangal_Stambampalli_I (Telangana)",
        OriginCenter: "Warangal_Stambampalli_I (Telangana)",
        FacilityCity: "Warangal",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21202,
        Pin: 502205,
        DispatchCenter: "Hyderabad_Patancheru_L (Telangana)",
        OriginCenter: "Hyderabad_Patancheru_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21203,
        Pin: 574324,
        DispatchCenter: "Bantwal_BambilRd_D (Karnataka)",
        OriginCenter: "Bantwal_BambilRd_D (Karnataka)",
        FacilityCity: "Bantwal",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21204,
        Pin: 484334,
        DispatchCenter: "Kotma_BaniyaTola_D (Madhya Pradesh)",
        OriginCenter: "Kotma_BaniyaTola_D (Madhya Pradesh)",
        FacilityCity: "Kotma",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 21205,
        Pin: 416115,
        DispatchCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        OriginCenter: "Kolhapur_HupariRd_H (Maharashtra)",
        FacilityCity: "Kolhapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 21206,
        Pin: 641005,
        DispatchCenter: "Coimbatore_Chinniyampalayam_L (Tamil Nadu)",
        OriginCenter: "Coimbatore_Kaniyur_H (Tamil Nadu)",
        FacilityCity: "Coimbatore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21207,
        Pin: 394430,
        DispatchCenter: "Dediapada_Kevdi_DPP (Gujarat)",
        OriginCenter: "Dediapada_Kevdi_DPP (Gujarat)",
        FacilityCity: "Dediapada",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 21208,
        Pin: 638181,
        DispatchCenter: "Velur_ChakraNgr_DPP (Tamil Nadu)",
        OriginCenter: "Velur_ChakraNgr_DPP (Tamil Nadu)",
        FacilityCity: "Velur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21209,
        Pin: 509301,
        DispatchCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        OriginCenter: "Mahbubnagar_Boyapally_I (Telangana)",
        FacilityCity: "Mahabubnagar",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21210,
        Pin: 577503,
        DispatchCenter: "Chitradurga_Basaveshwar_D (Karnataka)",
        OriginCenter: "Chitradurga_Basaveshwar_D (Karnataka)",
        FacilityCity: "Chitradurga",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21211,
        Pin: 713216,
        DispatchCenter: "Durgapur_Bamunara_I (West Bengal)",
        OriginCenter: "Durgapur_Bamunara_I (West Bengal)",
        FacilityCity: "Durgapur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21212,
        Pin: 841240,
        DispatchCenter: "Siwan_Aranda_D (Bihar)",
        OriginCenter: "Siwan_Aranda_D (Bihar)",
        FacilityCity: "Siwan",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21213,
        Pin: 571130,
        DispatchCenter: "Mysore_Hebbalestate_H (Karnataka)",
        OriginCenter: "Mysore_Hebbalestate_H (Karnataka)",
        FacilityCity: "Mysore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21214,
        Pin: 364055,
        DispatchCenter: "Bhavnagar_Chitra_I (Gujarat)",
        OriginCenter: "Bhavnagar_Chitra_I (Gujarat)",
        FacilityCity: "Bhavnagar",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 21215,
        Pin: 400018,
        DispatchCenter: "Mumbai_Sewri_L (Maharashtra)",
        OriginCenter: "Mumbai_Sewri_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 21216,
        Pin: 301022,
        DispatchCenter: "Viratnagar_Shyamcolony_D (Rajasthan)",
        OriginCenter: "Viratnagar_Shyamcolony_D (Rajasthan)",
        FacilityCity: "Viratnagar",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21217,
        Pin: 601204,
        DispatchCenter: "Gummudipoondi_Minjur_D (Tamil Nadu)",
        OriginCenter: "Gummudipoondi_Minjur_D (Tamil Nadu)",
        FacilityCity: "Gummudipoondi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21218,
        Pin: 400015,
        DispatchCenter: "Mumbai_Sewri_L (Maharashtra)",
        OriginCenter: "Mumbai_Sewri_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 21219,
        Pin: 562104,
        DispatchCenter: "Chikkaballapura_WardNo6_D (Karnataka)",
        OriginCenter: "Chikkaballapura_WardNo6_D (Karnataka)",
        FacilityCity: "Chikkaballapura",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21220,
        Pin: 403520,
        DispatchCenter: "Goa_Hub (Goa)",
        OriginCenter: "Goa_Hub (Goa)",
        FacilityCity: "Goa",
        FacilityState: "Goa",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 21221,
        Pin: 441222,
        DispatchCenter: "Sindewahi_ZPSchlDPP_D (Maharashtra)",
        OriginCenter: "Sindewahi_ZPSchlDPP_D (Maharashtra)",
        FacilityCity: "Sindewahi",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 21222,
        Pin: 632058,
        DispatchCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        OriginCenter: "Vellore_Avrmpalyam_I (Tamil Nadu)",
        FacilityCity: "Vellore",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21223,
        Pin: 626110,
        DispatchCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        OriginCenter: "Rajapalayam_Madathuppatti_D (Tamil Nadu)",
        FacilityCity: "Rajapalayam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21224,
        Pin: 560002,
        DispatchCenter: "Bangalore_FCILayout_L (Karnataka)",
        OriginCenter: "Bangalore_FCILayout_L (Karnataka)",
        FacilityCity: "Bangalore",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21225,
        Pin: 713150,
        DispatchCenter: "Katwa_Panchghara_D (West Bengal)",
        OriginCenter: "Katwa_Panchghara_D (West Bengal)",
        FacilityCity: "Katwa",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21226,
        Pin: 413525,
        DispatchCenter: "Kaij_Kalamb_DPP (Maharashtra)",
        OriginCenter: "Kaij_Kalamb_DPP (Maharashtra)",
        FacilityCity: "Kaij",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 21227,
        Pin: 841239,
        DispatchCenter: "Mairwa_StationRd_D (Bihar)",
        OriginCenter: "Mairwa_StationRd_D (Bihar)",
        FacilityCity: "Mairwa",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21228,
        Pin: 624403,
        DispatchCenter: "Natham_CPCmplx_DPP (Tamil Nadu)",
        OriginCenter: "Natham_CPCmplx_DPP (Tamil Nadu)",
        FacilityCity: "Natham",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21229,
        Pin: 473990,
        DispatchCenter: "Pichhore_Rajivnagar_D (Madhya Pradesh)",
        OriginCenter: "Pichhore_Rajivnagar_D (Madhya Pradesh)",
        FacilityCity: "Pichhore",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 21230,
        Pin: 470003,
        DispatchCenter: "Sagar_Pagara_P (Madhya Pradesh)",
        OriginCenter: "Sagar_Pagara_P (Madhya Pradesh)",
        FacilityCity: "Sagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 21231,
        Pin: 591116,
        DispatchCenter: "Saundatti_Bypassroad_D (Karnataka)",
        OriginCenter: "Saundatti_Bypassroad_D (Karnataka)",
        FacilityCity: "Saundatti",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21232,
        Pin: 587156,
        DispatchCenter: "Kerur_WardNo2_D (Karnataka)",
        OriginCenter: "Kerur_WardNo2_D (Karnataka)",
        FacilityCity: "Kerur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21233,
        Pin: 721429,
        DispatchCenter: "Contai_Egra_D (West Bengal)",
        OriginCenter: "Contai_Egra_D (West Bengal)",
        FacilityCity: "Contai",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21234,
        Pin: 824236,
        DispatchCenter: "Tekari_BaheliyaBigha_D (Bihar)",
        OriginCenter: "Tekari_BaheliyaBigha_D (Bihar)",
        FacilityCity: "Tekari",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21235,
        Pin: 460449,
        DispatchCenter: "Sarni_Ward36_D (Madhya Pradesh)",
        OriginCenter: "Sarni_Ward36_D (Madhya Pradesh)",
        FacilityCity: "Sarni",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 21236,
        Pin: 385526,
        DispatchCenter: "Palanpur_Esbipura1_D (Gujarat)",
        OriginCenter: "Palanpur_Esbipura1_D (Gujarat)",
        FacilityCity: "Palanpur",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 21237,
        Pin: 176301,
        DispatchCenter: "Dalhousie_Banikhet_D (Himachal Pradesh)",
        OriginCenter: "Dalhousie_Banikhet_D (Himachal Pradesh)",
        FacilityCity: "Dalhousie",
        FacilityState: "Himachal Pradesh",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 21238,
        Pin: 442507,
        DispatchCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        OriginCenter: "Chandrapur_Wandhari_I (Maharashtra)",
        FacilityCity: "Chandrapur",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 21239,
        Pin: 691306,
        DispatchCenter: "Ayoor_Alayamon_D (Kerala)",
        OriginCenter: "Ayoor_Alayamon_D (Kerala)",
        FacilityCity: "Ayoor",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 21240,
        Pin: 412102,
        DispatchCenter: "Nira_Central_D (Maharashtra)",
        OriginCenter: "Nira_Central_D (Maharashtra)",
        FacilityCity: "Nira",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 21241,
        Pin: 825411,
        DispatchCenter: "Hazaribagh_Padma_DPP (Jharkhand)",
        OriginCenter: "Hazaribagh_Padma_DPP (Jharkhand)",
        FacilityCity: "hazaribagh",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21242,
        Pin: 485003,
        DispatchCenter: "Satna_Nazirabad_I (Madhya Pradesh)",
        OriginCenter: "Satna_Nazirabad_I (Madhya Pradesh)",
        FacilityCity: "Satna",
        FacilityState: "Madhya Pradesh",
        ODA: "ODA",
        Zone : "Central"
      },
      {
        SN: 21243,
        Pin: 620018,
        DispatchCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        OriginCenter: "Tiruchi_Navalur_H (Tamil Nadu)",
        FacilityCity: "Tiruchi",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21244,
        Pin: 382810,
        DispatchCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        OriginCenter: "Vijapur_Anandpurachokdi_D (Gujarat)",
        FacilityCity: "Vijapur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 21245,
        Pin: 444308,
        DispatchCenter: "Akola_Midcphase2_D (Maharashtra)",
        OriginCenter: "Akola_Midcphase2_D (Maharashtra)",
        FacilityCity: "Akola",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 21246,
        Pin: 342037,
        DispatchCenter: "Mathania_Central_DPP_2 (Rajasthan)",
        OriginCenter: "Mathania_Central_DPP_2 (Rajasthan)",
        FacilityCity: "Mathania",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21247,
        Pin: 415009,
        DispatchCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        OriginCenter: "Satara_Ambedkarnagar_I (Maharashtra)",
        FacilityCity: "Satara",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 21248,
        Pin: 680611,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 21249,
        Pin: 212622,
        DispatchCenter: "Khaga_GTRoad_D (Uttar Pradesh)",
        OriginCenter: "Khaga_GTRoad_D (Uttar Pradesh)",
        FacilityCity: "Khaga",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21250,
        Pin: 413517,
        DispatchCenter: "Udgir_NlgaonRd_D (Maharashtra)",
        OriginCenter: "Udgir_NlgaonRd_D (Maharashtra)",
        FacilityCity: "Udgir",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 21251,
        Pin: 400059,
        DispatchCenter: "Mumbai_Kurla_L (Maharashtra)",
        OriginCenter: "Mumbai_Kurla_L (Maharashtra)",
        FacilityCity: "Mumbai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 21252,
        Pin: 535183,
        DispatchCenter: "Pendurthi_Mudapaka_DPP (Andhra Pradesh)",
        OriginCenter: "Pendurthi_Mudapaka_DPP (Andhra Pradesh)",
        FacilityCity: "Pendurthi",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21253,
        Pin: 608002,
        DispatchCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        OriginCenter: "Chidambaram_Kalyaninagar_D (Tamil Nadu)",
        FacilityCity: "Chidambaram",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21254,
        Pin: 394690,
        DispatchCenter: "Songadh_Vyara_D (Gujarat)",
        OriginCenter: "Songadh_Vyara_D (Gujarat)",
        FacilityCity: "Songadh",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 21255,
        Pin: 628721,
        DispatchCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        OriginCenter: "Kovilpatti_KadershanKoil_D (Tamil Nadu)",
        FacilityCity: "Kovilpatti",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21256,
        Pin: 680602,
        DispatchCenter: "Thrissur_Padavarad_I (Kerala)",
        OriginCenter: "Thrissur_Padavarad_I (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 21257,
        Pin: 493770,
        DispatchCenter: "Dhamtari_VasiniWard_D (Chhattisgarh)",
        OriginCenter: "Dhamtari_VasiniWard_D (Chhattisgarh)",
        FacilityCity: "Dhamtari",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21258,
        Pin: 387520,
        DispatchCenter: "Matar_CityCenter_DPP (Gujarat)",
        OriginCenter: "Matar_CityCenter_DPP (Gujarat)",
        FacilityCity: "Matar",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 21259,
        Pin: 362136,
        DispatchCenter: "Junagadh_Dolatpara_I (Gujarat)",
        OriginCenter: "Junagadh_Dolatpara_I (Gujarat)",
        FacilityCity: "Junagadh",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 21260,
        Pin: 721643,
        DispatchCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        OriginCenter: "Tamluk_Nandakumar_DPP (West Bengal)",
        FacilityCity: "Tamluk",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21261,
        Pin: 306001,
        DispatchCenter: "MarwarJunction_Karoliya_DPP (Rajasthan)",
        OriginCenter: "MarwarJunction_Karoliya_DPP (Rajasthan)",
        FacilityCity: "Marwar Junction",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21262,
        Pin: 835231,
        DispatchCenter: "Gumla_Sikwar_D (Jharkhand)",
        OriginCenter: "Gumla_Sikwar_D (Jharkhand)",
        FacilityCity: "Gumla",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21263,
        Pin: 577135,
        DispatchCenter: "Kadur_UBRoad_D (Karnataka)",
        OriginCenter: "Kadur_UBRoad_D (Karnataka)",
        FacilityCity: "Kadur",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21264,
        Pin: 770018,
        DispatchCenter: "Rajgangpur_Central_D_2 (Orissa)",
        OriginCenter: "Rajgangpur_Central_D_2 (Orissa)",
        FacilityCity: "Rajgangpur",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 21265,
        Pin: 306503,
        DispatchCenter: "Khinwara_Temple_DPP (Rajasthan)",
        OriginCenter: "Khinwara_Temple_DPP (Rajasthan)",
        FacilityCity: "Khinwara",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21266,
        Pin: 621802,
        DispatchCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        OriginCenter: "Jayankondam_Indiranagar_D (Tamil Nadu)",
        FacilityCity: "Jayankondam",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21267,
        Pin: 603313,
        DispatchCenter: "Melmaruvathur_IOBank_D (Tamil Nadu)",
        OriginCenter: "Melmaruvathur_IOBank_D (Tamil Nadu)",
        FacilityCity: "Melmaruvathur",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21268,
        Pin: 465449,
        DispatchCenter: "RajgarhMP_Jirapur_D (Madhya Pradesh)",
        OriginCenter: "RajgarhMP_Jirapur_D (Madhya Pradesh)",
        FacilityCity: "Rajgarh-MP",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 21269,
        Pin: 192121,
        DispatchCenter: "Srinagar_Ladhoo_L (Jammu & Kashmir)",
        OriginCenter: "Srinagar_Ladhoo_H (Jammu & Kashmir)",
        FacilityCity: "Srinagar",
        FacilityState: "Jammu & Kashmir",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 21270,
        Pin: 394250,
        DispatchCenter: "Bardoli_GopalNagar_D (Gujarat)",
        OriginCenter: "Bardoli_GopalNagar_D (Gujarat)",
        FacilityCity: "Bardoli",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 21271,
        Pin: 209733,
        DispatchCenter: "Kannauj_Kazitola_D (Uttar Pradesh)",
        OriginCenter: "Kannauj_Kazitola_D (Uttar Pradesh)",
        FacilityCity: "Kannauj",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21272,
        Pin: 627860,
        DispatchCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        OriginCenter: "Tenkasi_Ilanji_D (Tamil Nadu)",
        FacilityCity: "Tenkasi",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21273,
        Pin: 679515,
        DispatchCenter: "Akaloor_Lakdi_DPP (Kerala)",
        OriginCenter: "Akaloor_Lakdi_DPP (Kerala)",
        FacilityCity: "Akaloor",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 21274,
        Pin: 759117,
        DispatchCenter: "Chhendipada_Lodhabandha_DPP (Orissa)",
        OriginCenter: "Chhendipada_Lodhabandha_DPP (Orissa)",
        FacilityCity: "Chhendipada",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 21275,
        Pin: 600003,
        DispatchCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        OriginCenter: "Chennai_Kathirvedu_L (Tamil Nadu)",
        FacilityCity: "Chennai",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21276,
        Pin: 322201,
        DispatchCenter: "Karauli_Agrawalnagar_D (Rajasthan)",
        OriginCenter: "Karauli_Agrawalnagar_D (Rajasthan)",
        FacilityCity: "Karauli",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21277,
        Pin: 272302,
        DispatchCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        OriginCenter: "Basti_GandhiNagar_D (Uttar Pradesh)",
        FacilityCity: "Basti",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21278,
        Pin: 679574,
        DispatchCenter: "Edappal_Nellissery_D (Kerala)",
        OriginCenter: "Edappal_Nellissery_D (Kerala)",
        FacilityCity: "Edappal",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 21279,
        Pin: 515774,
        DispatchCenter: "Gooty_ATPRoad_D (Andhra Pradesh)",
        OriginCenter: "Gooty_ATPRoad_D (Andhra Pradesh)",
        FacilityCity: "Gooty",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21280,
        Pin: 743251,
        DispatchCenter: "Helencha_ColnyDPP_D (West Bengal)",
        OriginCenter: "Helencha_ColnyDPP_D (West Bengal)",
        FacilityCity: "Helencha",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21281,
        Pin: 222175,
        DispatchCenter: "Badlapur_Barauli_D (Uttar Pradesh)",
        OriginCenter: "Badlapur_Barauli_D (Uttar Pradesh)",
        FacilityCity: "Badlapur",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21282,
        Pin: 443104,
        DispatchCenter: "Jamner_BusStd_D (Maharashtra)",
        OriginCenter: "Jamner_BusStd_D (Maharashtra)",
        FacilityCity: "Jamner",
        FacilityState: "Maharashtra",
        ODA: "ODA",
        Zone : "W2"
      },
      {
        SN: 21283,
        Pin: 224226,
        DispatchCenter: "Lucknow_Natkur_L (Uttar Pradesh)",
        OriginCenter: "Lucknow_Memaura_H (Uttar Pradesh)",
        FacilityCity: "Lucknow",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21284,
        Pin: 221201,
        DispatchCenter: "Babatpur_Pindra_D (Uttar Pradesh)",
        OriginCenter: "Babatpur_Pindra_D (Uttar Pradesh)",
        FacilityCity: "Jalalpur-UP",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21285,
        Pin: 824209,
        DispatchCenter: "Gaya_Matihani_H (Bihar)",
        OriginCenter: "Gaya_Matihani_H (Bihar)",
        FacilityCity: "Gaya",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21286,
        Pin: 831020,
        DispatchCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        OriginCenter: "Jamshedpur_Asanbani_H (Jharkhand)",
        FacilityCity: "Jamshedpur",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21287,
        Pin: 761210,
        DispatchCenter: "Paralakhemundi_Omcolony_D (Orissa)",
        OriginCenter: "Paralakhemundi_Omcolony_D (Orissa)",
        FacilityCity: "Paralakhemundi",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 21288,
        Pin: 628402,
        DispatchCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        OriginCenter: "Tuticorin_Polpettai_D (Tamil Nadu)",
        FacilityCity: "Tuticorin",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21289,
        Pin: 110107,
        DispatchCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        OriginCenter: "Delhi_KirtiNgrRamaRd_L (Delhi)",
        FacilityCity: "Delhi",
        FacilityState: "Delhi",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21290,
        Pin: 639203,
        DispatchCenter: "Gujiliamparai_Palayam_DPP (Tamil Nadu)",
        OriginCenter: "Gujiliamparai_Palayam_DPP (Tamil Nadu)",
        FacilityCity: "Gujiliamparai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21291,
        Pin: 370610,
        DispatchCenter: "Nakhatrana_Dhormnath_DPP (Gujarat)",
        OriginCenter: "Nakhatrana_Dhormnath_DPP (Gujarat)",
        FacilityCity: "Nakhatrana",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 21292,
        Pin: 766019,
        DispatchCenter: "Jaipatna_PradhniChwk_D (Orissa)",
        OriginCenter: "Jaipatna_PradhniChwk_D (Orissa)",
        FacilityCity: "Jaipatna",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 21293,
        Pin: 415516,
        DispatchCenter: "Wai_Bavdhan_DPP (Maharashtra)",
        OriginCenter: "Wai_Bavdhan_DPP (Maharashtra)",
        FacilityCity: "Wai",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 21294,
        Pin: 243725,
        DispatchCenter: "Aonla_KdidmCLY_D (Uttar Pradesh)",
        OriginCenter: "Aonla_KdidmCLY_D (Uttar Pradesh)",
        FacilityCity: "Aonla",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21295,
        Pin: 388325,
        DispatchCenter: "Anand_VitthalUdyog_D (Gujarat)",
        OriginCenter: "Anand_VitthalUdyog_D (Gujarat)",
        FacilityCity: "Anand",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 21296,
        Pin: 212217,
        DispatchCenter: "Sirathu_Ward4_DPP (Uttar Pradesh)",
        OriginCenter: "Sirathu_Ward4_DPP (Uttar Pradesh)",
        FacilityCity: "Sirathu",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21297,
        Pin: 522302,
        DispatchCenter: "Guntur_Amaravati_D (Andhra Pradesh)",
        OriginCenter: "Guntur_Amaravati_D (Andhra Pradesh)",
        FacilityCity: "Guntur",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21298,
        Pin: 500083,
        DispatchCenter: "Hyderabad_HemaNagar_L (Telangana)",
        OriginCenter: "Hyderabad_HemaNagar_L (Telangana)",
        FacilityCity: "Hyderabad",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21299,
        Pin: 584132,
        DispatchCenter: "Sindhanur_JanathaGunj_D (Karnataka)",
        OriginCenter: "Sindhanur_JanathaGunj_D (Karnataka)",
        FacilityCity: "Sindhanur",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21300,
        Pin: 721604,
        DispatchCenter: "Haldia_Basudevpur_D (West Bengal)",
        OriginCenter: "Haldia_Basudevpur_D (West Bengal)",
        FacilityCity: "Haldia",
        FacilityState: "West Bengal",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21301,
        Pin: 788160,
        DispatchCenter: "Hailakandi_Lala_D (Assam)",
        OriginCenter: "Hailakandi_Lala_D (Assam)",
        FacilityCity: "Hailakandi",
        FacilityState: "Assam",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 21302,
        Pin: 224120,
        DispatchCenter: "Rudauli_Ghosaiyana_D (Uttar Pradesh)",
        OriginCenter: "Rudauli_Ghosaiyana_D (Uttar Pradesh)",
        FacilityCity: "Rudauli",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21303,
        Pin: 533004,
        DispatchCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        OriginCenter: "Kakinada_Rayudupalem_D (Andhra Pradesh)",
        FacilityCity: "Kakinada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21304,
        Pin: 246440,
        DispatchCenter: "Gairsain_Salyana_D (Uttarakhand)",
        OriginCenter: "Gairsain_Salyana_D (Uttarakhand)",
        FacilityCity: "Gairsain",
        FacilityState: "Uttarakhand",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 21305,
        Pin: 148031,
        DispatchCenter: "Sunam_Indrabasti_D (Punjab)",
        OriginCenter: "Sunam_Indrabasti_D (Punjab)",
        FacilityCity: "Sunam",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 21306,
        Pin: 845468,
        DispatchCenter: "Bettiah_Manshatola_D (Bihar)",
        OriginCenter: "Bettiah_Manshatola_D (Bihar)",
        FacilityCity: "Bettiah",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21307,
        Pin: 752100,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 21308,
        Pin: 571117,
        DispatchCenter: "Kollegala_IndiraClny_D (Karnataka)",
        OriginCenter: "Kollegala_IndiraClny_D (Karnataka)",
        FacilityCity: "Kollegala",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21309,
        Pin: 689541,
        DispatchCenter: "Tiruvalla_Chennithala_D (Kerala)",
        OriginCenter: "Tiruvalla_Chennithala_D (Kerala)",
        FacilityCity: "Haripad",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 21310,
        Pin: 847401,
        DispatchCenter: "Khutauna_ShastriChwk_D (Bihar)",
        OriginCenter: "Khutauna_ShastriChwk_D (Bihar)",
        FacilityCity: "Khutauna",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21311,
        Pin: 143410,
        DispatchCenter: "Goindwal_Fatehabad_D (Punjab)",
        OriginCenter: "Goindwal_Fatehabad_D (Punjab)",
        FacilityCity: "Goindwal",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 21312,
        Pin: 757079,
        DispatchCenter: "RajBerhampur_Gohira_DPP (Orissa)",
        OriginCenter: "RajBerhampur_Gohira_DPP (Orissa)",
        FacilityCity: "Raj Berhampur",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 21313,
        Pin: 384292,
        DispatchCenter: "Unjha_SatymComplx_D (Gujarat)",
        OriginCenter: "Unjha_SatymComplx_D (Gujarat)",
        FacilityCity: "Unjha",
        FacilityState: "Gujarat",
        ODA: "ODA",
        Zone : "W1"
      },
      {
        SN: 21314,
        Pin: 610114,
        DispatchCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        OriginCenter: "Thiruvarur_Mannargudi_D (Tamil Nadu)",
        FacilityCity: "Thiruvarur",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21315,
        Pin: 284125,
        DispatchCenter: "Talbahat_CityRoad_DPP (Uttar Pradesh)",
        OriginCenter: "Talbahat_CityRoad_DPP (Uttar Pradesh)",
        FacilityCity: "Talbahat",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21316,
        Pin: 335501,
        DispatchCenter: "Bhadra_GanapatiPlaza_D (Rajasthan)",
        OriginCenter: "Bhadra_GanapatiPlaza_D (Rajasthan)",
        FacilityCity: "Bhadra",
        FacilityState: "Rajasthan",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21317,
        Pin: 751007,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 21318,
        Pin: 210432,
        DispatchCenter: "Muskara_KaliMataM_D (Uttar Pradesh)",
        OriginCenter: "Muskara_KaliMataM_D (Uttar Pradesh)",
        FacilityCity: "Muskara",
        FacilityState: "Uttar Pradesh",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21319,
        Pin: 225125,
        DispatchCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        OriginCenter: "Haidargarh_Bhatkhera_D (Uttar Pradesh)",
        FacilityCity: "Haidargarh",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21320,
        Pin: 638055,
        DispatchCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        OriginCenter: "Erode_BharathiNagar_I (Tamil Nadu)",
        FacilityCity: "Erode",
        FacilityState: "Tamil Nadu",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21321,
        Pin: 470001,
        DispatchCenter: "Sagar_Pagara_P (Madhya Pradesh)",
        OriginCenter: "Sagar_Pagara_P (Madhya Pradesh)",
        FacilityCity: "Sagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 21322,
        Pin: 415405,
        DispatchCenter: "Shirala_PostOffice_DPP (Maharashtra)",
        OriginCenter: "Shirala_PostOffice_DPP (Maharashtra)",
        FacilityCity: "Shirala",
        FacilityState: "Maharashtra",
        ODA: "Normal",
        Zone : "W2"
      },
      {
        SN: 21323,
        Pin: 678508,
        DispatchCenter: "Nenmara_Vithanaserry_D (Kerala)",
        OriginCenter: "Nenmara_Vithanaserry_D (Kerala)",
        FacilityCity: "Nenmara",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 21324,
        Pin: 484770,
        DispatchCenter: "Jaisinghnagar_Khusarwah_DPP (Madhya Pradesh)",
        OriginCenter: "Jaisinghnagar_Khusarwah_DPP (Madhya Pradesh)",
        FacilityCity: "Jaisinghnagar",
        FacilityState: "Madhya Pradesh",
        ODA: "Normal",
        Zone : "Central"
      },
      {
        SN: 21325,
        Pin: 591121,
        DispatchCenter: "Bailhongal_Azadnagar_D (Karnataka)",
        OriginCenter: "Bailhongal_Azadnagar_D (Karnataka)",
        FacilityCity: "Bailhongal",
        FacilityState: "Karnataka",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21326,
        Pin: 144415,
        DispatchCenter: "Phillaur_Parkashnagar_D (Punjab)",
        OriginCenter: "Phillaur_Parkashnagar_D (Punjab)",
        FacilityCity: "Phillaur",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 21327,
        Pin: 233307,
        DispatchCenter: "Aunrihar_JawahrNgr_D (Uttar Pradesh)",
        OriginCenter: "Aunrihar_JawahrNgr_D (Uttar Pradesh)",
        FacilityCity: "Aunrihar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21328,
        Pin: 695513,
        DispatchCenter: "Trivandrum_Neyatinkra_D (Kerala)",
        OriginCenter: "Trivandrum_Neyatinkra_D (Kerala)",
        FacilityCity: "Thiruvananthapuram",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 21329,
        Pin: 637015,
        DispatchCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        OriginCenter: "Namakkal_KonguNgr_D (Tamil Nadu)",
        FacilityCity: "Namakkal",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21330,
        Pin: 822129,
        DispatchCenter: "Nagaruntari_Jangipur_DPP (Jharkhand)",
        OriginCenter: "Nagaruntari_Jangipur_DPP (Jharkhand)",
        FacilityCity: "Nagar Untari",
        FacilityState: "Jharkhand",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21331,
        Pin: 143302,
        DispatchCenter: "TarnTaranSahib_BasantAvenue_D (Punjab)",
        OriginCenter: "TarnTaranSahib_BasantAvenue_D (Punjab)",
        FacilityCity: "Tarn Taran Sahib",
        FacilityState: "Punjab",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 21332,
        Pin: 504216,
        DispatchCenter: "Mancherial_Nuspur_I (Telangana)",
        OriginCenter: "Mancherial_Nuspur_I (Telangana)",
        FacilityCity: "Mancherial",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21333,
        Pin: 769006,
        DispatchCenter: "Rourkela_Panposh_I (Orissa)",
        OriginCenter: "Rourkela_Panposh_I (Orissa)",
        FacilityCity: "Rourkela",
        FacilityState: "Orissa",
        ODA: "Normal",
        Zone : "NE"
      },
      {
        SN: 21334,
        Pin: 143111,
        DispatchCenter: "Tarsikka_Beas_D (Punjab)",
        OriginCenter: "Tarsikka_Beas_D (Punjab)",
        FacilityCity: "Beas",
        FacilityState: "Punjab",
        ODA: "ODA",
        Zone : "N2"
      },
      {
        SN: 21335,
        Pin: 283135,
        DispatchCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        OriginCenter: "Shikohabad_COOffice_D (Uttar Pradesh)",
        FacilityCity: "Shikohabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21336,
        Pin: 506344,
        DispatchCenter: "Tadvai_Akulavarighanpur_D (Telangana)",
        OriginCenter: "Tadvai_Akulavarighanpur_D (Telangana)",
        FacilityCity: "Tadvai",
        FacilityState: "Telangana",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21337,
        Pin: 202137,
        DispatchCenter: "Jewar_SJRoad_D (Uttar Pradesh)",
        OriginCenter: "Jewar_SJRoad_D (Uttar Pradesh)",
        FacilityCity: "Jewar",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21338,
        Pin: 587135,
        DispatchCenter: "Jamkhandi_Laxmingr_D (Karnataka)",
        OriginCenter: "Jamkhandi_Laxmingr_D (Karnataka)",
        FacilityCity: "Jamkhandi",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21339,
        Pin: 680520,
        DispatchCenter: "Thrissur_Koonammoochi_D (Kerala)",
        OriginCenter: "Thrissur_Koonammoochi_D (Kerala)",
        FacilityCity: "Thrissur",
        FacilityState: "Kerala",
        ODA: "ODA",
        Zone : "S2"
      },
      {
        SN: 21340,
        Pin: 626112,
        DispatchCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        OriginCenter: "Aruppukkottai_Kanjnykknpti_D (Tamil Nadu)",
        FacilityCity: "Aruppukkottai",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21341,
        Pin: 844505,
        DispatchCenter: "Hazratjandaha_Hirpur_D (Bihar)",
        OriginCenter: "Hazratjandaha_Hirpur_D (Bihar)",
        FacilityCity: "Hazrat Jandaha",
        FacilityState: "Bihar",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21342,
        Pin: 686543,
        DispatchCenter: "Ranni_Chethakhl_D (Kerala)",
        OriginCenter: "Ranni_Chethakhl_D (Kerala)",
        FacilityCity: "Ranni",
        FacilityState: "Kerala",
        ODA: "Normal",
        Zone : "S2"
      },
      {
        SN: 21343,
        Pin: 851127,
        DispatchCenter: "Begusarai_Haibatpur_D (Bihar)",
        OriginCenter: "Begusarai_Haibatpur_D (Bihar)",
        FacilityCity: "Begusarai",
        FacilityState: "Bihar",
        ODA: "Normal",
        Zone : "E"
      },
      {
        SN: 21344,
        Pin: 495689,
        DispatchCenter: "Sakti_Siphaimda_D (Chhattisgarh)",
        OriginCenter: "Sakti_Siphaimda_D (Chhattisgarh)",
        FacilityCity: "Sakti",
        FacilityState: "Chhattisgarh",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21345,
        Pin: 245406,
        DispatchCenter: "Sikandrabad_Khatriwara_D (Uttar Pradesh)",
        OriginCenter: "Sikandrabad_Khatriwara_D (Uttar Pradesh)",
        FacilityCity: "Sikandrabad",
        FacilityState: "Uttar Pradesh",
        ODA: "ODA",
        Zone : "N1"
      },
      {
        SN: 21346,
        Pin: 522435,
        DispatchCenter: "Piduguralla_Rasoolpet_D (Andhra Pradesh)",
        OriginCenter: "Piduguralla_Rasoolpet_D (Andhra Pradesh)",
        FacilityCity: "Piduguralla",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21347,
        Pin: 573136,
        DispatchCenter: "Ramanathapura_Koodalur_D (Karnataka)",
        OriginCenter: "Ramanathapura_Koodalur_D (Karnataka)",
        FacilityCity: "Ramanathapura",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21348,
        Pin: 345025,
        DispatchCenter: "Phalsund_Kajoi_D (Rajasthan)",
        OriginCenter: "Phalsund_Kajoi_D (Rajasthan)",
        FacilityCity: "Phalsund",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21349,
        Pin: 625518,
        DispatchCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        OriginCenter: "Cumbum_BhrthiyarNgr_D (Tamil Nadu)",
        FacilityCity: "Cumbum",
        FacilityState: "Tamil Nadu",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21350,
        Pin: 177041,
        DispatchCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        OriginCenter: "Bangana_Bhaleti_D (Himachal Pradesh)",
        FacilityCity: "Bangana",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 21351,
        Pin: 733158,
        DispatchCenter: "Gangarampr_Ramkrishnpaly_DPP (West Bengal)",
        OriginCenter: "Gangarampr_Ramkrishnpaly_DPP (West Bengal)",
        FacilityCity: "Gangarampur",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21352,
        Pin: 816110,
        DispatchCenter: "Barharwa_SahibDPP_D (Jharkhand)",
        OriginCenter: "Barharwa_SahibDPP_D (Jharkhand)",
        FacilityCity: "Barharwa",
        FacilityState: "Jharkhand",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21353,
        Pin: 522202,
        DispatchCenter: "Tenali_Pandurngpet_D (Andhra Pradesh)",
        OriginCenter: "Tenali_Pandurngpet_D (Andhra Pradesh)",
        FacilityCity: "Tenali",
        FacilityState: "Andhra Pradesh",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21354,
        Pin: 752103,
        DispatchCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        OriginCenter: "Bhubaneswar_Bampakhuda_GW (Orissa)",
        FacilityCity: "Bhubaneshwar",
        FacilityState: "Orissa",
        ODA: "ODA",
        Zone : "NE"
      },
      {
        SN: 21355,
        Pin: 712706,
        DispatchCenter: "Kolkata_Dankunibil_L (West Bengal)",
        OriginCenter: "Kolkata_Dankuni_GW (West Bengal)",
        FacilityCity: "Dankuni",
        FacilityState: "West Bengal",
        ODA: "ODA",
        Zone : "E"
      },
      {
        SN: 21356,
        Pin: 302026,
        DispatchCenter: "Jaipur_RingRoad_L (Rajasthan)",
        OriginCenter: "Jaipur_Sez_GW (Rajasthan)",
        FacilityCity: "Jaipur",
        FacilityState: "Rajasthan",
        ODA: "Normal",
        Zone : "N1"
      },
      {
        SN: 21357,
        Pin: 176058,
        DispatchCenter: "SurajpurJhikla_IndoraMore_DPP (Himachal Pradesh)",
        OriginCenter: "SurajpurJhikla_IndoraMore_DPP (Himachal Pradesh)",
        FacilityCity: "Surajpur jhikla",
        FacilityState: "Himachal Pradesh",
        ODA: "Normal",
        Zone : "N2"
      },
      {
        SN: 21358,
        Pin: 391156,
        DispatchCenter: "ChhotaUdaipur_Panjrapole_D (Gujarat)",
        OriginCenter: "ChhotaUdaipur_Panjrapole_D (Gujarat)",
        FacilityCity: "Chhota Udaipur",
        FacilityState: "Gujarat",
        ODA: "Normal",
        Zone : "W1"
      },
      {
        SN: 21359,
        Pin: 571237,
        DispatchCenter: "Madikeri_Ranipet_D (Karnataka)",
        OriginCenter: "Madikeri_Ranipet_D (Karnataka)",
        FacilityCity: "Madikeri",
        FacilityState: "Karnataka",
        ODA: "ODA",
        Zone : "S1"
      },
      {
        SN: 21360,
        Pin: 520014,
        DispatchCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        OriginCenter: "Vijayawada_Gudavalli_H (Andhra Pradesh)",
        FacilityCity: "Vijayawada",
        FacilityState: "Andhra Pradesh",
        ODA: "Normal",
        Zone : "S1"
      },
      {
        SN: 21361,
        Pin: 503102,
        DispatchCenter: "Kamareddy_KPRcolony_D (Telangana)",
        OriginCenter: "Kamareddy_KPRcolony_D (Telangana)",
        FacilityCity: "Kamareddy",
        FacilityState: "Telangana",
        ODA: "ODA",
        Zone : "S1"
      }
    ]
