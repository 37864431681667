import { Button } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ViewOrderDetails = ({orderDetails,generateForwardingNumber,handleUpdateModal,addPOD,id}) => {
    const { error, loading, isAuthenticated,users,userProfile } = useSelector(
        (state) => state.user
      );
      const [totalBox, setTotalBox] = useState(0)
      console.log(orderDetails)
      
      const getBoxes = () =>{
    
        let totalBoxes = 0
        orderDetails?.dimensions?.map((d)=>{
        totalBoxes = Number(totalBoxes) + Number(d?.count)

        })
        return totalBoxes
        console.log(totalBoxes)
        
        
    }
  
      
        useEffect(() => {
            
            getBoxes()
        }, [])
        
  return (
    <div className='col-span-4 mx-3 py-4' >
    <div className='flex items-center justify-between' >
    {/* <h3 className='text-[1.2rem] text-gray-600 font-[GilroyMedium] ' >{orderDetails?.pickup_location?.pickupName?.companyName}: <span className='font-[GilroyBold] text-black' >{orderDetails?.lrno}</span> </h3> */}
  {userProfile?.role === "admin" && <Link to={`/editorder/${id}`}><Button>Edit Order</Button></Link> }
  <Link to={`/orderDetails/waybill/${id}`}><Button>Waybill</Button></Link>
    </div>
    <div className='grid  grid-cols-5  my-3 gap-6 grid-flow-col ' >
        <div className='col-span-2 w-full py-6 h-[90vh] rounded-lg border-[1px] border-gray-200 px-6 bg-white' >
        <div className='flex items-center justify-between ' >
        <h4 className='font-[GilroyBold] text-[1.5rem]' >Order Details</h4>
        <div className='bg-green-600 capitalize rounded-lg py-2 px-6 text-white' >{orderDetails?.orderStatus}</div>
        </div>
            <div className='my-3' >
                <h3 className='text-[1.2rem] font-[GilroyMedium] '>LR No: {orderDetails?.lrno} </h3>
                <div className='my-3' >
                    <h4 className='font-[GilroyMedium] text-gray-700' >Order ID:</h4>
                    <p className='font-[GilroyBold] text-[1.2rem]' >#{orderDetails?.orderID}</p>
                </div>
                <div className='my-3 flex items-center justify-between' >
                <div>
                    <h4 className='font-[GilroyMedium] text-gray-700' >Forwarding Number:</h4>
                    <p className='font-[GilroyBold] text-[1.2rem]' >{orderDetails?.forwardingNumber ? orderDetails?.forwardingNumber : "-"}</p>
                    </div>
                   {userProfile?.role==="admin" && orderDetails?.orderStatus==="pickedUp" && !orderDetails?.forwardingNumber && <div className='' >
                        <button onClick={generateForwardingNumber} className='outline-none bg-black py-3 px-4 text-[.7rem] text-white rounded-lg' >Generate Forwarding Number</button>
                    </div>}
                </div>
                <div className='my-3 flex items-center justify-between' >
                <div>
                    <h4 className='font-[GilroyMedium] text-gray-700' >Shipment Type:</h4>
                    <p className='font-[GilroyBold] text-[1.2rem]' >{orderDetails?.d_mode}</p>
                    </div>
                    <div>
                    <h4 className='font-[GilroyMedium] text-gray-700' >Chargable Weight:</h4>
                    <p className='font-[GilroyBold] text-[1.2rem]' >{Math.ceil(orderDetails?.totalChargeableWeight
)} kgs</p>
                    </div>
                </div>
                <div className='my-6 flex items-center justify-between' >
                <div>
                    <h4 className='font-[GilroyMedium] text-gray-700' >No of Boxes:</h4>
                    <p className='font-[GilroyBold] text-[1.2rem]' >{getBoxes()}</p>
                    </div>
                    {/* <div>
                    <h4 className='font-[GilroyMedium] text-gray-700' >ROV Type:</h4>
                    <p className='font-[GilroyBold] text-[1.2rem]' >{userProfile?.insuranceType}</p>
                    </div> */}
                </div>
                <div className='my-3' >
                    <h4 className='font-[GilroyMedium] text-gray-700' >Reciever Details:</h4>
                    <p className='font-[GilroyMedium] text-[1rem]' >{orderDetails?.dropoff_location?.consignee}, {orderDetails?.dropoff_location?.address}, {orderDetails?.dropoff_location?.city}, {orderDetails?.dropoff_location?.region}, {orderDetails?.dropoff_location?.zip}</p>
                </div>
            </div>
            {orderDetails?.orderStatus==="delivered"  && userProfile.role==="admin" &&  !orderDetails?.pod  &&  <div>
                <h3 className='font-[GilroyBold] text-[1.5rem] mt-4' >Upload POD</h3>
             <div>
                <form  onSubmit={addPOD} className='form flex items-center justify-center flex-col my-6 '>
        <input  type='file' />
        <Button className='my-6' type='submit' size="md">Upload POD</Button>
      </form>
                </div>
            </div>}
            {orderDetails?.orderStatus==="delivered" && orderDetails?.pod   &&   <div className='flex items-center justify-between my-8' >
                <h3 className='font-[GilroyBold] text-[1.5rem] mt-4' >View POD</h3>
                <a className='bg-black py-3 px-6 font-[GilroyMedium] rounded-lg text-white' href={orderDetails?.pod} download>Check POD</a>
            </div>}
        </div>
        <div className='col-span-3 w-full py-6 rounded-lg border-[1px] border-gray-200 px-3 bg-white'>
            <div className='flex items-center justify-between' >
                <h3 className='font-[GilroyMedium] text-[1.4rem] ' >Status</h3>
             {userProfile?.role==="admin" &&   <div>
                    <button onClick={handleUpdateModal} className='outline-none bg-black py-3 px-4 text-[.7rem] text-white rounded-lg'>Update Status</button>
                </div>}
            </div>
            <div>
                {orderDetails?.orderActivity?.map((activity)=>(
                    <div className='shadow-md rounded-lg my-3 border-[1px] border-gray-300 py-3 px-4 grid grid-cols-3 grid-flow-col' >
                        <div>
                            <h3 className='text-gray-500 text-[.8rem] font-[GilroyMedium] mb-2' >Status</h3>
                            <p className='font-[GilroyBold] capitalize' >{activity?.status}</p>
                        </div>   
                        <div>
                            <h3 className='text-gray-500 text-[.8rem] font-[GilroyMedium] mb-2' >Location</h3>
                            <p className='font-[GilroyBold]' >{activity?.location}</p>
                            <p className='text-[.8rem]' >{activity?.time?.toDate().toString().slice(0,25)}</p>
                        </div>  
                        <div>
                            <h3 className='text-gray-500 text-[.8rem] font-[GilroyMedium] mb-2' >Remark</h3>
                            <p className='font-[GilroyBold]' >{activity?.remark}</p>
                        </div>       
                    </div>
                ))}
            </div>
        </div>
    </div>
    </div>
  )
}

export default ViewOrderDetails