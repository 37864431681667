import React from 'react'
import Topbar from '../components/Layout/Topbar'
import DeliveryOrder from '../components/CreateDeliveryOrder.js/DeliveryOrder'

const CreateDeliveryOrder = () => {
  return (
    <div>
        <Topbar />
        <DeliveryOrder />
    </div>
  )
}

export default CreateDeliveryOrder