import React, { useRef } from 'react'
import ReactToPrint from 'react-to-print';
import QRCode from "react-qr-code";
import { Button } from '@material-tailwind/react';
const ShippingLabel = ({lrNumber,pickupLocation,dropLocation,quantity}) => {
    console.log(quantity)
    console.log(
        quantity,
        Array(quantity),
        Array(quantity).fill(0).map((_, i) => i),
      );
    let componentRef = useRef();
  return (
    <>
    <div className='my-4 mx-2' >
      <ReactToPrint
      className=""
          trigger={() => <Button>Print this out!</Button>}
          content={() => componentRef}
        />
        </div>
        <div ref={(el) => (componentRef = el)}>
  {
    Array.from({ length: quantity }, (_, i) =>  (
        <div key={i} style={{ breakAfter: "page" }}   className='px-6 py-3 border-[1px] border-gray-600' >
      
        <div className='grid grid-cols-2 grid-flow-col justify-between' >
            <div>
                <h3 className='font-[GilroyBold]' >From Location,</h3>
                <p className='text-[0.6rem]' >{pickupLocation?.personName}</p>
                <p className='text-[0.6rem]'>{pickupLocation?.address},{pickupLocation?.city}, {pickupLocation?.state},  India</p>
              {pickupLocation?.phone?.length !== 0 && <p className='text-[0.6rem]'>{pickupLocation?.phone}</p>}
            </div>
            <div >
                <h3 className='font-[GilroyBold]' >To Location,</h3>
                <p className='text-[0.6rem]' >{dropLocation?.name}</p>
                <p className='text-[0.6rem]'>{dropLocation?.address},{dropLocation?.city}, {dropLocation?.region}, India , {dropLocation?.zip}</p>
              {dropLocation?.phone?.length !== 0 && <p className='text-[0.6rem]'>{dropLocation?.phone}</p>}
            </div>
        </div>
        <div className='grid grid-cols-2 mt-3 grid-flow-col justify-between items-center' >
        <div>
            <p>Box no: <span className='font-[GilroyBold]' > {i+1}/{quantity}</span></p>
        </div>
        <div className='flex items-center justify-center flex-col' >
        <QRCode value={lrNumber} size={64} />
        <p className='text-center mt-1 font-[GilroyMedium] text-[0.8rem]' >{lrNumber}</p>
        </div>
        </div>
        
    </div>
    ))
    
      }
      </div>
    </>
  )
}

export default ShippingLabel